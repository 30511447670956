import { SettingsWindowViewSetter } from '@interfaces/ViewSetters';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BaseAction } from '@models/network/actions/BaseAction';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { GameProfileModel } from '@models/GameProfileModel';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BaseSettingsWindowView } from '@views/windows/settings/BaseSettingsWindowView';
import { PaymentMethod } from '@src/types/TransactionTypes';

export class SettingsWindowViewController extends PIXI.utils.EventEmitter implements SettingsWindowViewSetter {
	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	protected readonly networkRequestSender: NetworkRequestSender;
	protected readonly gameProfileModel: GameProfileModel;

	protected readonly windowViewSystem: WindowViewSystem;
	protected readonly windowFactory: WindowViewBaseFactory;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		networkRequestSender: NetworkRequestSender,
		gameProfileModel: GameProfileModel,
	) {
		super();

		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.gameProfileModel = gameProfileModel;
		this.networkRequestSender = networkRequestSender;
	}

	public setSettingsWindowView(view: BaseSettingsWindowView): void {
		view.on(BaseSettingsWindowView.EVENT_CHANGE_LANGUAGE, this.onChangeLanguage, this);
		view.on(BaseSettingsWindowView.EVENT_CHANGE_MUSIC_VOLUME, this.onChangeMusicVolume, this);
		view.on(BaseSettingsWindowView.EVENT_CHANGE_SOUNDS_VOLUME, this.onChangeSoundsVolume, this);
		view.on(BaseSettingsWindowView.EVENT_CHANGE_USERNAME, this.onChangeUsername, this);
		view.on(BaseSettingsWindowView.EVENT_CHANGE_CARDPAY, this.onChangeCardpay, this);
		view.on(BaseSettingsWindowView.EVENT_BUTTON_SUPPORT_CLICK, this.onButtonSupportClick, this);
		view.on(BaseSettingsWindowView.EVENT_BUTTON_REGISTRATION_CLICK, this.onButtonRegistrationClick, this);
	}

	private onButtonSupportClick(): void {
		const window = this.windowFactory.createSupportWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonRegistrationClick(): void {
		const window = this.windowFactory.createRegistrationWindow();
		this.windowViewSystem.showWindow(window);
	}

	protected async onChangeLanguage(language: string): Promise<void> {
		this.emit(SettingsWindowViewController.EVENT_LOCALIZATION_CHANGE, language);
	}

	protected onChangeMusicVolume(value: number): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_music: value }));
	}

	protected onChangeSoundsVolume(value: number): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_sounds: value }));
	}

	protected onChangeUsername(username: string): void {
		this.gameProfileModel.updateUsername(username);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_username: username }));
	}

	protected onChangeCardpay(value: boolean): void {
		this.gameProfileModel.updatePaymentMethod(value ? PaymentMethod.CARDPAY : PaymentMethod.EPOCH);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_cardpay: value }));
	}
}
