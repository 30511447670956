import { AbstractQuest } from '@models/quests/AbstractQuest';
import { BusinessModel } from '@models/BusinessModel';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCustomersTarget extends AbstractQuest {
	private readonly model: BusinessModel;

	private readonly targetAmount: number;
	private currentAmount: number;

	constructor(
		questKey: string,
		model: BusinessModel,
		targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.GET_CUSTOMERS_TARGET,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.model = model;
		this.targetAmount = targetAmount;
		this.currentAmount = this.model.getCustomerCount();

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public getBusinessKey(): string {
		return this.model.getKey();
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.getTargetAmount().toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.getTargetAmount();
	}

	private onNewCustomers(model: BusinessModel): void {
		this.currentAmount = model.getCustomerCount();
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		this.model.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		this.model.off(BusinessModel.EVENT_ACQUIRED, this.onNewCustomers, this);
	}

	protected trackProgressStart(): void {
		this.model.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		this.model.on(BusinessModel.EVENT_ACQUIRED, this.onNewCustomers, this);
	}
}
