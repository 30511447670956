import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export type QuickPhraseViewConfig = {
	arrowPosition: PIXI.Point;
	bgPosition: PIXI.Point;
	arrowRotation?: number;
	invertBg?: boolean;
	bgMinHeight?: number;
};

export class CharacterQuickPhraseView extends PIXI.Container {
	public static readonly EVENT_HIDDEN: symbol = Symbol();

	private textPhrase: PIXI.extras.BitmapText;

	private bg: PIXI.mesh.NineSlicePlane;
	private arrow: PIXI.Sprite;

	private bgMinHeight: number;
	private bgInverted: boolean;

	private bgInitialPosY: number;

	constructor() {
		super();

		this.textPhrase = new PIXI.extras.BitmapText('', {
			font: '20px wendyOne',
			tint: 0x000000,
			align: 'left',
		});
		this.textPhrase.maxWidth = 170;
		this.textPhrase.anchor = 0.5;


		this.arrow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['dialog_bubble_tail']);

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['dialog_bubble'], 0, 4, 0, 4);
		this.bg.pivot.y = 0;

		this.bgInverted = false;

		this.visible = false;

		this.addChild(
			this.arrow,
			this.bg,
			this.textPhrase as PIXI.DisplayObject,
		);
	}

	public setView(config: QuickPhraseViewConfig): void {
		this.arrow.position.set(config.arrowPosition.x, config.arrowPosition.y);
		this.bg.position.set(config.bgPosition.x, config.bgPosition.y);
		if (config.invertBg) {
			this.bg.rotation = Math.PI;
			this.bgInverted = true;
		} else {
			this.bg.rotation = 0;
			this.bgInverted = false;
		}
		if (config.arrowRotation) this.arrow.rotation = config.arrowRotation;
		if (config.bgMinHeight) {
			this.bgMinHeight = config.bgMinHeight;
			this.bg.height = this.bgMinHeight;
			if (!this.bgInverted) this.bg.y -= Math.abs(this.bg.texture.height - this.bgMinHeight);
		}

		this.bgInitialPosY = this.bg.y;
	}

	public show(showTime: number): void {
		this.visible = true;
		const tweenOut = new TWEEN.Tween(this.scale)
			.to({ x: 1, y: 1 }, 110);

		new TWEEN.Tween(this.scale)
			.to({ x: 1.06, y: 1.06 }, 110)
			.easing(TWEEN.Easing.Back.In)
			.chain(tweenOut)
			.start();

		this.hide(showTime);
	}

	public hide(delay: number = 0): void {
		new TWEEN.Tween(this)
			.to({ alpha: 0 }, 100)
			.delay(delay)
			.onComplete(() => {
				this.visible = false;
				this.alpha = 1;

				this.emit(CharacterQuickPhraseView.EVENT_HIDDEN, this);
			})
			.start();
	}

	public setTextPhrase(phrase: string): void {
		this.textPhrase.text = phrase;

		this.bg.height = this.textPhrase.height + 10;
		if (!this.bgInverted) this.bg.y = this.bgInitialPosY - Math.abs(this.bg.texture.height - this.bg.height);

		let [x, y] = [this.bg.position.x, this.bg.position.y];
		if (this.bgInverted) {
			x -= this.bg.width / 2;
			y -= this.bg.height / 2;
		} else {
			x += this.bg.width / 2;
			y += this.bg.height / 2;
		}
		this.textPhrase.position.set(x, y);
	}

	public isShowing(): boolean {
		return this.visible;
	}
}
