import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';
import { FarewellPartyEffectsConfigs } from '@views/windows/farewellParty/FarewellPartyEffectsConfigs';

export class FarewellPartyEffectLightView extends PIXI.Container {
	private readonly animationEmitters: Emitter[];

	constructor() {
		super();

		this.animationEmitters = [];

		const glowEmitter = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['skill_activ_glow1']],
			FarewellPartyEffectsConfigs.GLOW,
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowLightsEmitter = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['party_button_glow4']],
			FarewellPartyEffectsConfigs.GLOW2,
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
