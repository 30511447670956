import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';

export class BankOfferButtonBuyView extends ButtonBaseView {
	private readonly textLabel: PIXI.extras.BitmapText;
	private readonly localizationStorage: LocalizationStorage;

	constructor(
		width: number,
		height: number,
		bankTabElementWithOffer: BankTabElementWithOfferType,
	) {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = width;
		buttonBg.height = height;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super(buttonBg);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.textLabel = new PIXI.extras.BitmapText(this.localizationStorage.getLocalizedString('#offer_button_buy_label'), {
			font: '25px wendyOne',
			tint: 0x1d3d09,
		});
		this.textLabel.anchor = 0.5;
		this.textLabel.y = -30;

		const newPriceCostResource = bankTabElementWithOffer.getButtonPriceResource();
		const newPriceLabel = new SizeableMultiColoredBitmapText(170, {
			font: '52px wendyOneShadowBold',
		});
		newPriceLabel.text = `${newPriceCostResource}${bankTabElementWithOffer.getPrice()}`;
		newPriceLabel.anchor = 0.5;

		this.addChild(
			this.textLabel,
			newPriceLabel,
		);

		if (bankTabElementWithOffer.hasOldPrice()) {
			const oldPriceCostResource = bankTabElementWithOffer.getButtonOldPriceResource();
			const oldPriceLabel = new SizeableMultiColoredBitmapText(170, {
				font: '38px wendyOneShadowBold',
			});
			oldPriceLabel.text = `${oldPriceCostResource}${bankTabElementWithOffer.getOldPrice()}`;
			oldPriceLabel.anchor = 0.5;

			newPriceLabel.position.set(oldPriceLabel.width / 2 + 2, 8);
			oldPriceLabel.position.set(-newPriceLabel.width / 2 - 2, 14);

			const scratchIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['scratch_icon']);

			scratchIcon.width = oldPriceLabel.width + 4;
			scratchIcon.position.set(oldPriceLabel.x, oldPriceLabel.y);


			this.addChild(
				oldPriceLabel,
				scratchIcon as PIXI.DisplayObject,
			);
		} else {
			newPriceLabel.position.set(0, 8);
		}
	}
}
