export type AssetsMetadataElementType = { name: string; path: string };

export class LevelAssetNames {
	public static getCharacterAnimationsMetadata(): Record<string, string> {
		const animations: Record<string, string> = {
			character1Animation: 'animations/characters/character1.json',
			character2Animation: 'animations/characters/character2.json',
			character3Animation: 'animations/characters/character3.json',
			character4Animation: 'animations/characters/character4.json',
			character5Animation: 'animations/characters/character5.json',
			character6Animation: 'animations/characters/character6.json',
			character7Animation: 'animations/characters/character7.json',
			character8Animation: 'animations/characters/character8.json',
			character9Animation: 'animations/characters/character9.json',
			character10Animation: 'animations/characters/character10.json',
			character11Animation: 'animations/characters/character11.json',
			character12Animation: 'animations/characters/character12.json',
			character13Animation: 'animations/characters/character13.json',
			character14Animation: 'animations/characters/character14.json',
			character15Animation: 'animations/characters/character15.json',
			character16Animation: 'animations/characters/character16.json',
			character17Animation: 'animations/characters/character17.json',
			character18Animation: 'animations/characters/character18.json',
			character19Animation: 'animations/characters/character19.json',
			character20Animation: 'animations/characters/character20.json',
			character21Animation: 'animations/characters/character21.json',
			character22Animation: 'animations/characters/character22.json',
			character23Animation: 'animations/characters/character23.json',
			character24Animation: 'animations/characters/character24.json',
			character25Animation: 'animations/characters/character25.json',
			character26Animation: 'animations/characters/character26.json',
			character27Animation: 'animations/characters/character27.json',
			character28Animation: 'animations/characters/character28.json',
			character29Animation: 'animations/characters/character29.json',
			character30Animation: 'animations/characters/character30.json',
			character31Animation: 'animations/characters/character31.json',
			character32Animation: 'animations/characters/character32.json',
			character33Animation: 'animations/characters/character33.json',
			character34Animation: 'animations/characters/character34.json',
			character35Animation: 'animations/characters/character35.json',
			character36Animation: 'animations/characters/character36.json',
		};

		return animations;
	}

	public static getSeasonAtlasMetadata(seasonId: number): { [key: string]: string } {
		switch (seasonId) {
			case 1: {
				return {
					miamiEveningAtlas: 'atlas/level_bg/miami_eveningAtlas.json',
					miamiMorningAtlas: 'atlas/level_bg/miami_morningAtlas.json',
					miamiNightAtlas: 'atlas/level_bg/miami_nightAtlas.json',
				};
			}
			case 2: {
				return {
					canadaDayAtlas: 'atlas/level_bg/canada_dayAtlas.json',
					canadaNightAtlas: 'atlas/level_bg/canada_nightAtlas.json',
				};
			}
			case 3: {
				return {
					italyMorningAtlas: 'atlas/level_bg/italy_morningAtlas.json',
					italyEveningAtlas: 'atlas/level_bg/italy_eveningAtlas.json',
				};
			}
			case 4: {
				return {
					germanyMorningAtlas: 'atlas/level_bg/germany_morningAtlas.json',
					germanyEveningAtlas: 'atlas/level_bg/germany_eveningAtlas.json',
				};
			}
			case 5: {
				return {
					japanNightAtlas: 'atlas/level_bg/japan_nightAtlas.json',
				};
			}
			default:
				throw new Error(`Unsupported season ${seasonId}`);
		}
	}

	public static readonly ATLAS_BUSINESS_BG: AssetsMetadataElementType[] = [
		{ name: 'businessBgAtlas0', path: 'atlas/business/businessBgAtlas-0.json' },
		{ name: 'businessBgAtlas1', path: 'atlas/business/businessBgAtlas-1.json' },
		{ name: 'businessBgAtlas2', path: 'atlas/business/businessBgAtlas-2.json' },
		{ name: 'businessBgAtlas3', path: 'atlas/business/businessBgAtlas-3.json' },
		{ name: 'businessBgAtlas4', path: 'atlas/business/businessBgAtlas-4.json' },
		{ name: 'businessBgAtlas5', path: 'atlas/business/businessBgAtlas-5.json' },
	];

	public static readonly ATLAS_BUSINESS_DECORATION: AssetsMetadataElementType[] = [
		{ name: 'businessDecorationAtlas0', path: 'atlas/business/businessDecorationAtlas-0.json' },
		{ name: 'businessDecorationAtlas1', path: 'atlas/business/businessDecorationAtlas-1.json' },
	];

	public static readonly ATLAS_CHARACTER_CARD: AssetsMetadataElementType[] = [
		{ name: 'characterCardAtlas0', path: 'atlas/characters/characterCardAtlas-0.json' },
		{ name: 'characterCardAtlas1', path: 'atlas/characters/characterCardAtlas-1.json' },
		{ name: 'characterCardAtlas2', path: 'atlas/characters/characterCardAtlas-2.json' },
		{ name: 'characterCardAtlas3', path: 'atlas/characters/characterCardAtlas-3.json' },
		{ name: 'characterCardAtlas4', path: 'atlas/characters/characterCardAtlas-4.json' },
		{ name: 'characterCardAtlas5', path: 'atlas/characters/characterCardAtlas-5.json' },
		{ name: 'characterCardAtlas6', path: 'atlas/characters/characterCardAtlas-6.json' },
		{ name: 'characterCardAtlas7', path: 'atlas/characters/characterCardAtlas-7.json' },
		{ name: 'characterCardAtlas8', path: 'atlas/characters/characterCardAtlas-8.json' },
		{ name: 'characterCardAtlas9', path: 'atlas/characters/characterCardAtlas-9.json' },
		{ name: 'characterCardAtlas10', path: 'atlas/characters/characterCardAtlas-10.json' },
		{ name: 'characterCardAtlas11', path: 'atlas/characters/characterCardAtlas-11.json' },
	];

	public static readonly ATLAS_CHARACTER_ICON: AssetsMetadataElementType[] = [
		{ name: 'characterIconAtlas', path: 'atlas/characters/characterIconAtlas.json' },
	];

	public static readonly ATLAS_MINI_CHARACTERS: AssetsMetadataElementType[] = [
		{ name: 'miniCharactersAtlas', path: 'atlas/miniCharacters/miniCharactersAtlas.json' },
	];

	public static readonly ATLAS_UPGRADE_ICON: AssetsMetadataElementType[] = [
		{ name: 'upgradeIconAtlas0', path: 'atlas/upgrades/upgradeIconAtlas-0.json' },
		{ name: 'upgradeIconAtlas1', path: 'atlas/upgrades/upgradeIconAtlas-1.json' },
		{ name: 'upgradeIconAtlas2', path: 'atlas/upgrades/upgradeIconAtlas-2.json' },
	];

	public static readonly ATLAS_TOTEM_ICON: AssetsMetadataElementType[] = [
		{ name: 'totemIconAtlas', path: 'atlas/totems/totemIconAtlas.json' },
	];

	public static readonly ATLAS_DIALOG: AssetsMetadataElementType[] = [
		{ name: 'dialogAtlas0', path: 'atlas/dialogs/dialogAtlas-0.json' },
		{ name: 'dialogAtlas1', path: 'atlas/dialogs/dialogAtlas-1.json' },
	];

	public static readonly ATLAS_LOOTBOX: AssetsMetadataElementType[] = [
		{ name: 'lootboxAtlas0', path: 'atlas/lootboxes/lootboxAtlas-0.json' },
		{ name: 'lootboxAtlas1', path: 'atlas/lootboxes/lootboxAtlas-1.json' },
		{ name: 'lootboxAtlas2', path: 'atlas/lootboxes/lootboxAtlas-2.json' },
		{ name: 'lootboxAtlas3', path: 'atlas/lootboxes/lootboxAtlas-3.json' },
		{ name: 'lootboxAtlas4', path: 'atlas/lootboxes/lootboxAtlas-4.json' },
	];

	public static readonly ATLAS_NUTAKU_LOOTBOX: AssetsMetadataElementType[] = [
		{ name: 'lootboxAtlas0', path: 'atlas/nutaku/lootboxes/lootboxNutakuAtlas-0.json' },
		{ name: 'lootboxAtlas1', path: 'atlas/nutaku/lootboxes/lootboxNutakuAtlas-1.json' },
		{ name: 'lootboxAtlas2', path: 'atlas/nutaku/lootboxes/lootboxNutakuAtlas-2.json' },
		{ name: 'lootboxAtlas3', path: 'atlas/nutaku/lootboxes/lootboxNutakuAtlas-3.json' },
		{ name: 'lootboxAtlas4', path: 'atlas/nutaku/lootboxes/lootboxNutakuAtlas-4.json' },
	];

	public static readonly ATLAS_UI: AssetsMetadataElementType[] = [
		{ name: 'uiAtlas0', path: 'atlas/ui/uiAtlas-0.json' },
		{ name: 'uiAtlas1', path: 'atlas/ui/uiAtlas-1.json' },
	];

	public static readonly ATLAS_COLLECTION: AssetsMetadataElementType[] = [
		{ name: 'collectionsAtlas0', path: 'atlas/collections/collectionsAtlas-0.json' },
		{ name: 'collectionsAtlas1', path: 'atlas/collections/collectionsAtlas-1.json' },
		{ name: 'collectionsAtlas2', path: 'atlas/collections/collectionsAtlas-2.json' },
		{ name: 'collectionsAtlas3', path: 'atlas/collections/collectionsAtlas-3.json' },
	];

	public static readonly ATLAS_NUTAKU_COLLECTION: AssetsMetadataElementType[] = [
		{ name: 'collectionsAtlas0', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-0.json' },
		{ name: 'collectionsAtlas1', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-1.json' },
		{ name: 'collectionsAtlas2', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-2.json' },
		{ name: 'collectionsAtlas3', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-3.json' },
		{ name: 'collectionsAtlas4', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-4.json' },
		{ name: 'collectionsAtlas5', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-5.json' },
		{ name: 'collectionsAtlas6', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-6.json' },
		{ name: 'collectionsAtlas7', path: 'atlas/nutaku/collections/collectionsNutakuAtlas-7.json' },
	]

	public static readonly ATLAS_FAREWELL_PARTY_UI: AssetsMetadataElementType[] = [
		{ name: 'farewellPartyUiAtlas', path: 'atlas/farewellParty/farewellPartyUiAtlas.json' },
	];

	public static readonly ATLAS_BANK: AssetsMetadataElementType[] = [
		{ name: 'bankAtlas0', path: 'atlas/bank/bankAtlas-0.json' },
	];

	public static readonly ATLAS_MINI_CARDS_BASE: AssetsMetadataElementType[] = [
		{ name: 'miniCardsBaseAtlas', path: 'atlas/miniCards/miniCardsBaseAtlas.json' },
	];

	public static readonly ATLAS_MINI_CARDS_ICONS: AssetsMetadataElementType[] = [
		{ name: 'miniCardsIconsAtlas', path: 'atlas/miniCards/miniCardsIconsAtlas.json' },
	];

	public static readonly ATLAS_FX: AssetsMetadataElementType[] = [
		{ name: 'fxAtlas0', path: 'atlas/fx/fxAtlas-0.json' },
		{ name: 'fxAtlas1', path: 'atlas/fx/fxAtlas-1.json' },
	];

	public static readonly COMMON_MAIN_ASSETS_TO_LOAD: AssetsMetadataElementType[][] = [
		[
			{ name: 'buyBussinesGirl1Animation', path: 'animations/buy_business/business_open.json' },
			{ name: 'buyBussinesGirl2Animation', path: 'animations/buy_business/business_open2.json' },
			{ name: 'buyBussinesGirl4Animation', path: 'animations/buy_business/business_open4.json' },
			{ name: 'incomeMoneyAnimation', path: 'animations/incomeMoney/incomeMoneyAnimation.json' },

			// Farewell Party
			{ name: 'farewellBg', path: 'images/farewellParty/farewell_bg.jpg' },
			{ name: 'farewellParty1Preview', path: 'images/farewellParty/farewellParty1_preview.jpg' },
			{ name: 'farewellParty2Preview', path: 'images/farewellParty/farewellParty2_preview.jpg' },
			{ name: 'farewellParty3Preview', path: 'images/farewellParty/farewellParty3_preview.jpg' },
			{ name: 'farewellParty4Preview', path: 'images/farewellParty/farewellParty4_preview.jpg' },
			{ name: 'farewellParty5Preview', path: 'images/farewellParty/farewellParty5_preview.jpg' },
			{ name: 'farewellParty6Preview', path: 'images/farewellParty/farewellParty6_preview.jpg' },
			...LevelAssetNames.getFarewellPartyAnimationAssetsToLoad(),

			// Tutorial
			{ name: 'tutorialTapAnimation', path: 'animations/tutorialTap/tutorialTapAnimation.json' },

			// Bank
			{ name: 'shopBg', path: 'images/bank/shop_bg.jpg' },
			{ name: 'collectionBg', path: 'images/collections/collection_bg.jpg' },
			{ name: 'summonBg', path: 'images/collections/summon_bg.jpg' },
			{ name: 'dailyBg', path: 'images/collections/daily_bg.jpg' },

			// Fonts
			{ name: 'wendyOneGold', path: 'fonts/wendyOneGold.xml' },
			{ name: 'wendyOneShadowBoldCaps', path: 'fonts/wendyOneShadowBoldCaps.xml' },
			{ name: 'wendyOneTitle', path: 'fonts/wendyOneTitle.xml' },
			{ name: 'SFCompact', path: 'fonts/SFCompact.xml' },
		],
		LevelAssetNames.ATLAS_BUSINESS_BG,
		LevelAssetNames.ATLAS_BUSINESS_DECORATION,
		LevelAssetNames.ATLAS_CHARACTER_CARD,
		LevelAssetNames.ATLAS_CHARACTER_ICON,
		LevelAssetNames.ATLAS_MINI_CHARACTERS,
		LevelAssetNames.ATLAS_UPGRADE_ICON,
		LevelAssetNames.ATLAS_TOTEM_ICON,
		LevelAssetNames.ATLAS_DIALOG,
		LevelAssetNames.ATLAS_UI,
		LevelAssetNames.ATLAS_FAREWELL_PARTY_UI,
		LevelAssetNames.ATLAS_BANK,
		LevelAssetNames.ATLAS_MINI_CARDS_BASE,
		LevelAssetNames.ATLAS_MINI_CARDS_ICONS,
		LevelAssetNames.ATLAS_FX,
	];

	public static readonly MAIN_ASSETS_TO_LOAD: AssetsMetadataElementType[][] = [].concat(
		LevelAssetNames.COMMON_MAIN_ASSETS_TO_LOAD,
		[LevelAssetNames.ATLAS_LOOTBOX, LevelAssetNames.ATLAS_COLLECTION],
	);

	public static readonly MAIN_NUTAKU_ASSETS_TO_LOAD: AssetsMetadataElementType[][] = [].concat(
		LevelAssetNames.COMMON_MAIN_ASSETS_TO_LOAD,
		[LevelAssetNames.ATLAS_NUTAKU_COLLECTION, LevelAssetNames.ATLAS_NUTAKU_LOOTBOX],
	);

	public static readonly SIDE_ASSETS_TO_LOAD: AssetsMetadataElementType[][] = [
		[
			{ name: 'big_offer_bg', path: 'images/bank/recharge_bg/big_offer_bg.png' },
			{ name: 'popup_offer_bg', path: 'images/bank/recharge_bg/popup_offer_bg.png' },
			{ name: 'popup_offer_bg_1', path: 'images/bank/recharge_bg/popup_offer_bg_1.png' },
			{ name: 'popup_offer_bg_2', path: 'images/bank/recharge_bg/popup_offer_bg_2.png' },
			{ name: 'popup_offer_bg_4', path: 'images/bank/recharge_bg/popup_offer_bg_4.png' },
			{ name: 'popup_offer_bg_5', path: 'images/bank/recharge_bg/popup_offer_bg_5.jpg' },
			{ name: 'popup_offer_bg_6', path: 'images/bank/recharge_bg/popup_offer_bg_6.jpg' },
			{ name: 'popup_offer_bg_7', path: 'images/bank/recharge_bg/popup_offer_bg_7.png' },
			{ name: 'popup_offer_bg_8', path: 'images/bank/recharge_bg/popup_offer_bg_8.jpg' },
			{ name: 'popup_offer_bg_9', path: 'images/bank/recharge_bg/popup_offer_bg_9.jpg' },
			{ name: 'popup_offer_bg_10', path: 'images/bank/recharge_bg/popup_offer_bg_10.jpg' },
			{ name: 'popup_offer_bg_11', path: 'images/bank/recharge_bg/popup_offer_bg_11.jpg' },
			{ name: 'popup_offer_bg_12', path: 'images/bank/recharge_bg/popup_offer_bg_12.jpg' },

			{ name: 'character0_offer', path: 'images/bank/recharge_bg/character0_offer.png' },
			{ name: 'character1_offer', path: 'images/bank/recharge_bg/character1_offer.png' },
			{ name: 'character2_offer', path: 'images/bank/recharge_bg/character2_offer.png' },
			{ name: 'character3_offer', path: 'images/bank/recharge_bg/character3_offer.png' },
			{ name: 'character4_offer', path: 'images/bank/recharge_bg/character4_offer.png' },
			{ name: 'character5_offer', path: 'images/bank/recharge_bg/character5_offer.png' },
			{ name: 'character6_offer', path: 'images/bank/recharge_bg/character6_offer.png' },
			{ name: 'character7_offer', path: 'images/bank/recharge_bg/character7_offer.png' },
			{ name: 'character8_offer', path: 'images/bank/recharge_bg/character8_offer.png' },
			{ name: 'character9_offer', path: 'images/bank/recharge_bg/character9_offer.png' },
			{ name: 'character10_offer', path: 'images/bank/recharge_bg/character10_offer.png' },
			{ name: 'character11_offer', path: 'images/bank/recharge_bg/character11_offer.png' },
			{ name: 'character12_offer', path: 'images/bank/recharge_bg/character12_offer.png' },
			{ name: 'character13_offer', path: 'images/bank/recharge_bg/character13_offer.png' },
			{ name: 'character14_offer', path: 'images/bank/recharge_bg/character14_offer.png' },
			{ name: 'character15_offer', path: 'images/bank/recharge_bg/character15_offer.png' },
			{ name: 'character16_offer', path: 'images/bank/recharge_bg/character16_offer.png' },
			{ name: 'character17_offer', path: 'images/bank/recharge_bg/character17_offer.png' },
			{ name: 'character18_offer', path: 'images/bank/recharge_bg/character18_offer.png' },
			{ name: 'character19_offer', path: 'images/bank/recharge_bg/character19_offer.png' },
			{ name: 'character20_offer', path: 'images/bank/recharge_bg/character20_offer.png' },
			{ name: 'character21_offer', path: 'images/bank/recharge_bg/character21_offer.png' },
			{ name: 'character22_offer', path: 'images/bank/recharge_bg/character22_offer.png' },
			{ name: 'character23_offer', path: 'images/bank/recharge_bg/character23_offer.png' },
			{ name: 'character24_offer', path: 'images/bank/recharge_bg/character24_offer.png' },
			{ name: 'character25_offer', path: 'images/bank/recharge_bg/character25_offer.png' },
			{ name: 'character26_offer', path: 'images/bank/recharge_bg/character26_offer.png' },
			{ name: 'character27_offer', path: 'images/bank/recharge_bg/character27_offer.png' },
			{ name: 'character28_offer', path: 'images/bank/recharge_bg/character28_offer.png' },
			{ name: 'character29_offer', path: 'images/bank/recharge_bg/character29_offer.png' },
			{ name: 'character30_offer', path: 'images/bank/recharge_bg/character30_offer.png' },
			{ name: 'character31_offer', path: 'images/bank/recharge_bg/character31_offer.png' },
			{ name: 'character32_offer', path: 'images/bank/recharge_bg/character32_offer.png' },
			{ name: 'character33_offer', path: 'images/bank/recharge_bg/character33_offer.png' },
			{ name: 'character34_offer', path: 'images/bank/recharge_bg/character34_offer.png' },
			{ name: 'character35_offer', path: 'images/bank/recharge_bg/character35_offer.png' },
			{ name: 'character36_offer', path: 'images/bank/recharge_bg/character36_offer.png' },
			{ name: 'character666_offer', path: 'images/bank/recharge_bg/character666_offer.png' },

			{ name: 'character4_promo', path: 'images/promo/character4_promo.png' },
			{ name: 'character7_promo', path: 'images/promo/character7_promo.png' },
			{ name: 'character8_promo', path: 'images/promo/character8_promo.png' },
			{ name: 'character20_promo', path: 'images/promo/character20_promo.png' },
			{ name: 'character22_promo', path: 'images/promo/character22_promo.png' },
			{ name: 'character23_promo', path: 'images/promo/character23_promo.png' },
		],
	];

	private static getFarewellPartyAnimationAssetsToLoad(): AssetsMetadataElementType[] {
		const assets = [
			{ name: 'farewellParty1', path: 'animations/farewellParty/farewellParty1.json' },
			{ name: 'farewellParty2', path: 'animations/farewellParty/farewellParty2.json' },
			{ name: 'farewellParty3', path: 'animations/farewellParty/farewellParty3.json' },
			{ name: 'farewellParty4', path: 'animations/farewellParty/farewellParty4.json' },
			{ name: 'farewellParty5', path: 'animations/farewellParty/farewellParty5.json' },
			{ name: 'farewellParty6', path: 'animations/farewellParty/farewellParty6.json' },
			{ name: 'farewellPartyButton', path: 'animations/farewellPartyButton/farewellPartyButton.json' },
			{ name: 'farewellPartyButtonBubble', path: 'animations/farewellPartyButton/farewellPartyButtonBubble.json' },
		];
		return assets;
	}
}
