import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';

export class GalleryVideoLockView extends PIXI.Container {
	private lockText: SizeableBitmapText;
	private localizationStorage: LocalizationStorage;

	constructor(unlockLevel: number) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_video_card']);
		bg.y = 3;

		const lockStr = this.localizationStorage.getLocalizedString('#video_UnlockLevel')
			.replace('{{level}}', unlockLevel.toString());
		this.lockText = new SizeableBitmapText(lockStr, 320, { font: '28px wendyOneShadowBold', align: 'center', tint: 0x9aaec0 });
		this.lockText.anchor = 0.5;
		this.lockText.y = 110;

		this.addChild(
			bg,
			this.lockText as PIXI.DisplayObject,
		);
	}
}
