import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter251007View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251007View';
import { EventCharacter251008View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251008View';
import { EventCharacter251009View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251009View';

export class EventBusiness251003View extends BusinessBaseView {
	constructor() {
		const eventCharacter251007View = new EventCharacter251007View();
		const eventCharacter251008View = new EventCharacter251008View();
		const eventCharacter251009View = new EventCharacter251009View();

		super('business251003', [eventCharacter251007View, eventCharacter251008View, eventCharacter251009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter251007View,
			eventCharacter251008View as PIXI.DisplayObject,
			eventCharacter251009View,
		);
	}
}
