import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class LevelStartCardsLockIconView extends PIXI.Container {
	private readonly topLockIcon: PIXI.Sprite;
	private readonly bottomLockIcon: PIXI.Sprite;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;
	private readonly lockIconContainer: PIXI.Container;

	constructor() {
		super();

		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);


		this.topLockIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lock_icon_up']);
		this.topLockIcon.y = -46;
		this.bottomLockIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lock_icon_down']);

		this.lockIconContainer = new PIXI.Container();
		this.lockIconContainer.addChild(
			this.bottomLockIcon,
			this.topLockIcon,
		);

		this.addChild(
			this.lockIconContainer,
		);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public startOpenAnimation(delay: number = 0, completeCallback: () => void): void {
		// Shake
		new TWEEN.Tween(this.lockIconContainer, this.tweenGroup)
			.to({ x: this.lockIconContainer.x + 12 }, 40)
			.repeat(5)
			.easing(TWEEN.Easing.Cubic.In)
			.yoyo(true)
			.delay(delay)
			.repeatDelay(0)
			.onComplete(() => {
				this.openLockIcon();
				completeCallback();
			})
			.start();
	}

	private openLockIcon(): void {
		// Top icon slide down
		new TWEEN.Tween(this.topLockIcon, this.tweenGroup)
			.delay(100)
			.easing(TWEEN.Easing.Cubic.Out)
			.to({ y: 30, alpha: 0, rotation: this.topLockIcon.rotation + 0.4 }, 1000)
			.start();

		// Bottom icon slide down
		new TWEEN.Tween(this.bottomLockIcon, this.tweenGroup)
			.delay(100)
			.easing(TWEEN.Easing.Cubic.Out)
			.to({ y: 100, alpha: 0, rotation: this.bottomLockIcon.rotation - 0.4 }, 1000)
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		super.destroy(options);
	}
}
