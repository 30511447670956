import { BusinessConfig } from '@configs/BusinessConfig';
import { BusinessSaveData } from '@src/types/SaveTypes';
import { BusinessModel } from '@models/BusinessModel';
import { BoostModel } from '@models/BoostModel';
import { CharacterModel } from '@models/CharacterModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';

export class BusinessesFactory {
	private readonly businessConfigs: Map<string, BusinessConfig>;
	private readonly savesData: Map<string, BusinessSaveData>;

	constructor(
		businessConfigs: Map<string, BusinessConfig>,
		savesData: Map<string, BusinessSaveData>,
	) {
		this.businessConfigs = businessConfigs;
		this.savesData = savesData;
	}

	public createModels(
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		characterModels: Map<string, CharacterModel>,
		skillModels: Map<string, SkillModel>,
		totemModels: Map<string, TotemModel>,
		upgradeModels: Map<string, UpgradeModel>,
	): Map<string, BusinessModel> {
		const models: Map<string, BusinessModel> = new Map();

		this.businessConfigs.forEach((businessConfig, businessKey) => {
			const businessModel: BusinessModel = new BusinessModel();
			models.set(businessKey, businessModel);

			businessModel.setFromConfig(businessConfig);

			const businessSaveData = this.savesData.get(businessKey);
			if (businessSaveData) {
				businessModel.setFromSaveData(businessSaveData);
			}

			// Set from boost models
			boostModels.forEach((boostModel) => {
				if (boostModel.isActivated()) {
					businessModel.setBoostMultiplierIncome(boostModel.getKey(), boostModel.getBonusValue());
				}
				if (farewellBoostModel.isActivated()) {
					businessModel.setFarwellBoostMultiplierIncome(farewellBoostModel.getBonusValue());
				}
			});
			//

			// Set from character models
			characterModels.forEach((characterModel) => {
				const characterKey = characterModel.getKey();
				if (characterModel.isActivated()) {
					const characterOfThisBusiness = businessModel.hasCharacter(characterKey);
					const affectsAll = characterModel.isAffectsAllBusinesses();

					if (characterOfThisBusiness && characterModel.hasBonusAutomate()) {
						businessModel.setAutomated();
					}

					if (characterOfThisBusiness || affectsAll) {
						characterModel.getBonuses().forEach((bonus, type) => {
							const bonusValue = bonus.getValue();
							// eslint-disable-next-line default-case
							switch (type) {
								case BonusTypes.MULTIPLIER_INCOME: {
									if (affectsAll) {
										businessModel.setCharacterMultiplierIncomeAffectAll(characterKey, bonusValue);
									} else {
										businessModel.setCharacterMultiplierIncome(characterKey, bonusValue);
									}
									break;
								}
								case BonusTypes.MULTIPLIER_TIME: {
									if (affectsAll) {
										businessModel.setCharacterMultiplierTimeAffectAll(characterKey, bonusValue);
									} else {
										businessModel.setCharacterMultiplierTime(characterKey, bonusValue);
									}
									break;
								}
								case BonusTypes.DECREASE_CUSTOMER_COST: {
									if (affectsAll) {
										businessModel.setCharacterMultiplierDecreaseCustomerCostAffectAll(characterKey, bonusValue);
									} else {
										businessModel.setCharacterMultiplierDecreaseCustomerCost(characterKey, bonusValue);
									}
									break;
								}
							}
						});
					}
				}
			});
			//

			// Set from skill models
			skillModels.forEach((skillModel) => {
				if (skillModel.isActive() || (skillModel.getType() === SkillTypes.CONSTANT_PROFIT && skillModel.getMultiplier() > 0)) {
					const skillMultiplier = skillModel.getMultiplier();

					// eslint-disable-next-line default-case
					switch (skillModel.getType()) {
						case SkillTypes.PROFIT_IMPROVE: {
							businessModel.setSkillProfitImproveMultiplier(skillMultiplier);
							break;
						}
						case SkillTypes.TIME_IMPROVE: {
							businessModel.setSkillTimeImproveDivisor(skillMultiplier);
							break;
						}
						case SkillTypes.CONSTANT_PROFIT: {
							businessModel.setSkillConstantProfitMultiplier(skillMultiplier);
							break;
						}
						case SkillTypes.TAP_ADD_MONEY: {
							businessModel.setSkillTapAddMoneyValue(skillMultiplier);
							break;
						}
					}
				}
			});
			//

			// Set from totem models
			totemModels.forEach((totemModel) => {
				const totemOfThisBusiness = totemModel.getAffectTarget() === businessModel.getKey();
				const affectsAll = totemModel.isAllBusinessAffected();
				if (totemModel.isOpened() && (totemOfThisBusiness || affectsAll)) {
					const totemKey = totemModel.getKey();
					totemModel.getBonuses().forEach((bonus, type) => {
						const bonusValue = bonus.getValue();
						// eslint-disable-next-line default-case
						switch (type) {
							case BonusTypes.MULTIPLIER_INCOME:
								businessModel.setTotemMultiplierIncome(totemKey, bonusValue);
								break;

							case BonusTypes.MULTIPLIER_TIME:
								businessModel.setTotemMultiplierTime(totemKey, bonusValue);
								break;

							case BonusTypes.DECREASE_CUSTOMER_COST:
								businessModel.setTotemMultiplierDecreaseCustomerCost(totemKey, bonusValue);
								break;

							case BonusTypes.REDUCE_TIME_INCOME_BY_CLICK:
								businessModel.setTotemReducePercentIncomeByClick(totemKey, bonusValue);
								break;
						}
					});
				}
			});
			//

			// Set from upgrade models
			upgradeModels.forEach((upgradeModel) => {
				if (upgradeModel.isActivated() && businessModel.hasUpgrade(upgradeModel.getKey())) {
					const upgradeKey = upgradeModel.getKey();
					upgradeModel.getBonuses().forEach((bonus, type) => {
						const bonusValue = bonus.getValue();
						// eslint-disable-next-line default-case
						switch (type) {
							case BonusTypes.MULTIPLIER_INCOME: {
								businessModel.setUpgradeMultiplierIncome(upgradeKey, bonusValue);
								break;
							}
							case BonusTypes.MULTIPLIER_TIME: {
								businessModel.setUpgradeMultiplierTime(upgradeKey, bonusValue);
								break;
							}
							case BonusTypes.DECREASE_CUSTOMER_COST: {
								businessModel.setUpgradeMultiplierDecreaseCustomerCost(upgradeKey, bonusValue);
								break;
							}
							case BonusTypes.REDUCE_TIME_INCOME_BY_CLICK: {
								businessModel.setUpgradeReducePercentIncomeByClick(upgradeKey, bonusValue);
								break;
							}
						}
					});
				}
			});
		});
		//

		return models;
	}
}
