import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export class BankOffersFactory {
	public static createModels(
		bankOfferConfigs: Map<string, BankOfferConfig>,
		bankSavesModel: BankSavesModel,
		currentTime: number,
		previousOfferModels?: Map<string, BankOfferModel>,
	): Map<string, BankOfferModel> {
		const models = new Map();
		bankOfferConfigs.forEach((config, key) => {
			const model = new BankOfferModel(
				config,
				currentTime,
				bankSavesModel.getOfferEndTime(config.getKey()),
			);

			model.updateTimeleft(currentTime);

			const previousModel = previousOfferModels?.get(config.getKey());
			if (previousModel) {
				model.setOfferWindowShowed(previousModel.isOfferWindowShowed());
			}

			models.set(key, model);
		});
		return models;
	}
}
