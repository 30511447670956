import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { SkillConstantProfitModel } from '@models/skills/SkillConstantProfitModel';
import { TextUtils } from '@src/utils/TextUtils';
import LocalizationStorage from '@main/LocalizationStorage';
import { NumberUtils } from '@src/utils/NumberUtils';
import { ReplacementMap } from '../upgrades/TextDescriptionHelperUpgrade';

export class TextDescriptionHelperCharacter {
	public static getDescription(
		character: CharacterModel,
		skill?: SkillModel,
	): string {
		const localeController = LocalizationStorage.getInstance();

		let strProfitDesc: string;

		if (skill) {
			const { skillName, skillDesc } = TextDescriptionHelperCharacter.getSkillDescription(skill);

			strProfitDesc = localeController.getLocalizedString('#character_SkillDesc')
				.replace('{{skillName}}', skillName).replace('{{skillDesc}}', skillDesc)
				.replace('{{value}}', `${skill.getMultiplier()}`)
				.replace('{{next_value}}', `${skill.getNextMultiplier()}`);
		} else {
			const replacementMaps = this.getReplacementMaps(character);
			const localizationKey = TextDescriptionHelperCharacter.getTextDescriptionKey(character);
			strProfitDesc = LocalizationStorage.getInstance().getLocalizedString(localizationKey);
			replacementMaps.forEach((replacementMap) => {
				strProfitDesc = TextUtils.replaceValueAndEnding(
					LocalizationStorage.getInstance().getLanguage(),
					replacementMap.replaceString,
					replacementMap.replaceValue,
					strProfitDesc,
				);
			});
		}
		return strProfitDesc;
	}

	public static getDescriptionProfileWindow(
		character: CharacterModel,
		relatedBusinessKey: string,
		skill?: SkillModel,
	): string {
		const localeController = LocalizationStorage.getInstance();

		let strProfitDesc: string;
		if (skill) {
			const { skillName, skillDesc } = TextDescriptionHelperCharacter.getSkillDescriptionProfileWindow(skill);

			strProfitDesc = localeController.getLocalizedString('#character_SkillDesc')
				.replace('{{skillName}}', skillName)
				.replace('{{skillDesc}}', skillDesc)
				.replace('{{value}}', `${skill.getMultiplier()}`)
				.replace('{{next_value}}', `${skill.getNextMultiplier()}`);
		} else {
			const replacementMaps = this.getReplacementMaps(character);
			const localizationKey = TextDescriptionHelperCharacter.getTextDescriptionKeyPromoteWindow(character);
			strProfitDesc = LocalizationStorage.getInstance().getLocalizedString(localizationKey);
			replacementMaps.forEach((replacementMap) => {
				strProfitDesc = TextUtils.replaceValueAndEnding(
					LocalizationStorage.getInstance().getLanguage(),
					replacementMap.replaceString,
					replacementMap.replaceValue,
					strProfitDesc,
				);
			});
		}
		return strProfitDesc;
	}

	private static getSkillDescriptionProfileWindow(skill: SkillModel): { [key: string]: string } {
		const localeController = LocalizationStorage.getInstance();

		const type = skill.getType();
		let skillMultiplier = null;
		let skillNextMultiplier = null;
		switch (skill.getType()) {
			case SkillTypes.CONSTANT_PROFIT:
				skillMultiplier = Math.floor((skill as SkillConstantProfitModel).getBaseMultiplier() * 100);
				skillNextMultiplier = Math.floor((skill as SkillConstantProfitModel).getNextBaseMultiplier() * 100);
				break;
			case SkillTypes.TAP_ADD_MONEY:
			case SkillTypes.REDUCE_RELOAD:
				skillMultiplier = Math.floor(skill.getMultiplier() * 100);
				skillNextMultiplier = Math.floor(skill.getNextMultiplier() * 100);
				break;
			default:
				skillMultiplier = Math.floor(skill.getMultiplier());
				skillNextMultiplier = Math.floor(skill.getNextMultiplier());
		}

		const skillName = localeController.getLocalizedString(`#skill_InfoS${type}Name`);
		let skillDesc = localeController.getLocalizedString(`#Promote_window_skill_InfoS${type}Desc`);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{multiplier}}', skillMultiplier, skillDesc);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{time}}', skill.getActiveTime(), skillDesc);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{next_time}}', skill.getNextActiveTime(), skillDesc);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{next_value}}', skillNextMultiplier, skillDesc);

		return {
			skillName,
			skillDesc,
		};
	}

	public static getSkillDescription(skill: SkillModel): { skillName: string; skillDesc: string } {
		const localeController = LocalizationStorage.getInstance();

		const type = skill.getType();
		let multiplier;
		switch (type) {
			case SkillTypes.CONSTANT_PROFIT:
				multiplier = Math.floor((skill as SkillConstantProfitModel).getBaseMultiplier() * 100);
				break;
			case SkillTypes.TAP_ADD_MONEY:
			case SkillTypes.REDUCE_RELOAD:
				multiplier = Math.floor(skill.getMultiplier() * 100);
				break;
			default:
				multiplier = Math.floor(skill.getMultiplier());
				break;
		}

		const skillName = localeController.getLocalizedString(`#skill_InfoS${type}Name`);
		let skillDesc = localeController.getLocalizedString(`#skill_InfoS${type}Desc`);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{multiplier}}', multiplier, skillDesc);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{time}}', skill.getActiveTime(), skillDesc);
		skillDesc = TextUtils.replaceValueAndEnding(LocalizationStorage.getInstance().getLanguage(), '{{next_time}}', skill.getNextActiveTime(), skillDesc);

		return {
			skillName,
			skillDesc,
		};
	}

	private static getReplacementMaps(model: CharacterModel): ReplacementMap[] {
		const result: ReplacementMap[] = [];
		{
			const replaceString = '{{next_value}}';
			let replaceValue = '';
			if (model.hasBonusDecreaseCustomerCostCoef()) {
				replaceValue = NumberUtils.numberToShortString(Math.floor(model.getNextDecreaseCustomerCostCoef()));
			} else if (model.hasBonusMultiplierIncome()) {
				replaceValue = model.getNextMultiplierIncomeAsSoftMoney().toString();
			} else if (model.hasBonusMultiplierTime()) {
				replaceValue = model.getNextMultiplierTimeAsSoftMoney().toString();
			} else if (model.hasBonusReduceTimeIncomeByClick()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextReduceTimeIncomeByClickValue());
			} else if (model.hasBonusFuckpower()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextMultiplierFuckpower());
			} else if (model.hasBonusFucktime()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextMultiplierFucktime());
			}
			result.push({ replaceString, replaceValue });
		}
		{
			const replaceString = '{{value}}';
			let replaceValue = '';
			if (model.hasBonusDecreaseCustomerCostCoef()) {
				replaceValue = NumberUtils.numberToShortString(Math.floor(model.getDecreaseCustomerCostCoef()));
			} else if (model.hasBonusMultiplierIncome()) {
				replaceValue = model.getMultiplierIncomeAsSoftMoney().toString();
			} else if (model.hasBonusMultiplierTime()) {
				replaceValue = model.getMultiplierTimeAsSoftMoney().toString();
			} else if (model.hasBonusReduceTimeIncomeByClick()) {
				replaceValue = NumberUtils.numberToShortString(model.getReduceTimeIncomeByClickValue());
			} else if (model.hasBonusFuckpower()) {
				replaceValue = NumberUtils.numberToShortString(model.getMultiplierFuckpower());
			} else if (model.hasBonusFucktime()) {
				replaceValue = NumberUtils.numberToShortString(model.getFucktime());
			}
			result.push({ replaceString, replaceValue });
		}
		{
			const replaceString = '{{business_name}}';
			const businessKey = model.getRelatedBusinessKey();
			const replaceValue = LocalizationStorage.getInstance().getLocalizedString(`#${businessKey}_Name`);
			result.push({ replaceString, replaceValue });
		}
		return result;
	}

	private static getTextDescriptionKeyPromoteWindow(model: CharacterModel): string {
		let key;
		if (model.isAffectsAllBusinesses()) {
			key = '#Promote_window_character_Desc_MultiplierIncomeAll';
		} else if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#Promote_window_character_DecreaseCustomerCost_Desc';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#Promote_window_character_Desc_MultiplierIncome';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#Promote_window_character_Desc_MultiplierTime';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#Promote_window_character_ReduceTimeIncomeByClick_Desc';
		} else if (model.hasBonusFuckpower()) {
			key = '#Promote_window_character_Desc_Fuckpower';
		}

		return key;
	}

	private static getTextDescriptionKey(model: CharacterModel): string {
		let key;
		if (model.isAffectsAllBusinesses()) {
			key = '#character_Desc_MultiplierIncomeAll';
		} else if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#character_DecreaseCustomerCost_Desc';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#character_Desc_MultiplierIncome';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#character_Desc_MultiplierTime';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#character_ReduceTimeIncomeByClick_Desc';
		} else if (model.hasBonusFuckpower()) {
			key = '#character_Desc_Fuckpower';
		}
		return key;
	}
}
