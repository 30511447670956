import { BaseAction } from '../BaseAction';

export class FarewellPartyUpdateFuckpowerAction extends BaseAction {
	constructor(minDelay: number, clicks: number, progress: number) {
		super('FarewellPartyUpdate');

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.min_delay = minDelay;
		this.data.clicks = clicks;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.progress = progress;
	}
}
