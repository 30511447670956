import { TimeskipConfig } from '@configs/TimeskipConfig';
import { TimeskipSaveData } from '@src/types/SaveTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class TimeskipModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_ACQUIRED: symbol = Symbol();
	public static readonly EVENT_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_INCOME_UPDATED: symbol = Symbol();

	private key: string;
	private iconKey: string;
	private value: number;

	private activateCount: number;
	private currentActivateCount: number;

	private income: SoftMoneyNumber;

	constructor() {
		super();

		this.activateCount = 0;
		this.currentActivateCount = 0;
		this.income = SoftMoneyNumber.ZERO;
	}

	public setFromConfig(config: TimeskipConfig): void {
		this.key = config.getKey();
		this.iconKey = config.getIconKey();
		this.value = config.getValue();
	}

	public setFromSaveData(saveData: TimeskipSaveData): void {
		this.currentActivateCount = 0;
		this.activateCount = saveData.available;
	}

	public acquire(quantity: number = 1): void {
		this.activateCount += quantity;
		this.emit(TimeskipModel.EVENT_ACQUIRED, this);
	}

	public activate(): void {
		this.currentActivateCount += 1;
		this.emit(TimeskipModel.EVENT_ACTIVATED, this);
	}

	public setIncomePerWithBoostSec(value: SoftMoneyNumber): void {
		this.income = value.multiply(this.value);
		this.emit(TimeskipModel.EVENT_INCOME_UPDATED);
	}

	public getIncome(): SoftMoneyNumber {
		return this.income;
	}

	public getKey(): string {
		return this.key;
	}

	public getValue(): number {
		return this.value;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getCurrentActivateCount(): number {
		return this.currentActivateCount;
	}

	public getActivateCount(): number {
		return this.activateCount;
	}

	public isUnlocked(): boolean {
		return this.income > SoftMoneyNumber.ZERO;
	}

	public canActivate(): boolean {
		return this.currentActivateCount < this.activateCount;
	}
}
