import { BaseAction } from '../BaseAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class ClaimFreeBundleAction extends BaseAction {
	constructor(bundleKey: string, rewards: RewardDescriptionType[]) {
		super('ClaimFreeBundleLot');

		this.data.lot_string_id = bundleKey;
		this.data.rewards = rewards;
	}
}
