import { PresetConfig, PresetLevelValue } from '@configs/PresetConfig';
import { LevelModel } from './level/LevelModel';

export class PresetsModel {
	constructor(
		private readonly presetConfigs: Map<string, PresetConfig>,
		private readonly levelModel: LevelModel,
	) {}

	public parsePresetValue(presetValue: string): number {
		const substr = presetValue.split(':');
		const level = this.levelModel.getCurrentLevel();
		let result;

		if (substr.length === 1) {
			result = Number(substr[0]);
		} else {
			const key = substr[0].trim();
			const mult = Number(substr[1]);
			const preset = this.presetConfigs.get(key);
			result = this.getResultValue(preset, level, mult);
		}

		return result;
	}

	private getResultValue(preset: PresetConfig, level: number, multiplier: number): number {
		const keyValues = preset.getKeyValues();
		const levelsBetween = this.findLevelValue(preset.getLevelsBetween(), level);
		const round = preset.getRound();
		let currentLevel = 0;
		let currentIndex = 0;

		while (currentLevel <= level) {
			currentLevel += levelsBetween;
			currentIndex += 1;
		}

		const k2Index = Math.min(currentIndex, keyValues.length - 1);
		const k2 = keyValues[k2Index];
		const k1 = keyValues[Math.max(k2Index - 1, 0)];

		let result;

		if (levelsBetween === 1) {
			result = k1 * multiplier;
		} else {
			const p1 = this.findLevelValue(preset.getLinearPercent(), level);
			const delta = (k2 - k1) * p1;
			const step = Math.max(level % levelsBetween - 1, 0);

			result = (k1 + (delta / (levelsBetween - 1)) * step) * multiplier;
		}

		const r1Index = Math.min(currentIndex, round.length - 1);
		const r1 = round[r1Index];
		const rounded = Math.round(result / r1) * r1;

		return rounded;
	}

	// eslint-disable-next-line class-methods-use-this
	private findLevelValue(array: PresetLevelValue[], level: number): number {
		const nextIndex = array.findIndex((x) => x.level > level);
		const index = Math.max(nextIndex - 1, array.length - 1);
		return array[index].value;
	}
}
