import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter90009View extends CharacterMainBaseView {
	constructor() {
		super('character90009');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(214, -14),
			bgPosition: new PIXI.Point(290, -17),
			arrowRotation: -Math.PI / 2,
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
