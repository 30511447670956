import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter255004View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255004View';
import { EventCharacter255005View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255005View';
import { EventCharacter255006View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255006View';

export class EventBusiness255002View extends BusinessBaseView {
	constructor() {
		const eventCharacter255004View = new EventCharacter255004View();
		const eventCharacter255005View = new EventCharacter255005View();
		const eventCharacter255006View = new EventCharacter255006View();

		super('business255002', [eventCharacter255004View, eventCharacter255005View, eventCharacter255006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter255006View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter255004View as PIXI.DisplayObject,
			eventCharacter255005View,
		);
	}
}
