import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { BankParticleConfig } from './bank/animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import LocalizationStorage from '@main/LocalizationStorage';
import { EventLevelRewardsHorizontalListView } from '../ui/EventLevelRewardsHorizontalListView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { StickableTimerView } from '../components/StickableTimerView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { TextField } from '@views/components/text/TextField';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { HintDataReward, HintTypes } from '@src/types/HintTypes';

export class GoToEventLevelWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD: symbol = Symbol();
	public static readonly EVENT_BUTTON_GO_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly eventLevelModel: EventLevelModel;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly animationEmitters: Emitter[];

	private readonly titleLabel: SizeableBitmapText;
	private readonly descrLabel: TextField;

	private readonly timerView: StickableTimerView;

	private readonly buttonGo: ButtonWithLabelBaseView;

	constructor(
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
	) {
		super(0.8);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.animationEmitters = [];

		this.eventLevelModel = eventLevelModel;
		this.eventLevelModel.once(EventLevelModel.EVENT_TIMEOUT, this.onEventModelTimeout, this);
		const eventLevelKey: string = this.eventLevelModel.getKey();

		const eventRewardsTitle = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#go_to_event_window_event_rewards'),
			900,
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
		);
		eventRewardsTitle.anchor = 0.5;
		eventRewardsTitle.y = -324;

		const goalRewards = Array
			.from(eventLevelModel.getGoalRewardsMap().values())
			.map(rewards => rewards[0]);
		const goalRewardsView = new EventLevelRewardsHorizontalListView(
			943,
			180,
			goalRewards,
			cardMiniViewFactory,
		);
		goalRewardsView.y = -218;
		goalRewardsView.on(
			EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD,
			(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
				const data: HintDataReward = { rewardDescription, origin };
				this.emit(GoToEventLevelWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
			},
			this,
		);

		const leagueRewardsTitle = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#go_to_event_window_leaderboard_rewards'),
			900,
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
		);
		leagueRewardsTitle.anchor = 0.5;
		leagueRewardsTitle.y = -86;

		const leagueRewards = Array.from(eventLevelModel.getLeagueRewardsMap().values())[0];
		const leagueRewardsView = new EventLevelRewardsHorizontalListView(
			943,
			180,
			leagueRewards,
			cardMiniViewFactory,
		);
		leagueRewardsView.y = 22;
		leagueRewardsView.on(
			EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD,
			(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
				const data: HintDataReward = { rewardDescription, origin };
				this.emit(GoToEventLevelWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
			},
			this,
		);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_3'], 31, 31, 31, 31);
		bg.width = 1006;
		bg.height = 956;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 22;
		bg.interactive = true;

		const bgFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow']);
		bgFade.tint = 0x00000;
		bgFade.scale.set(16);
		bgFade.y = 443;

		const leftDecorGirl = new PIXI.Sprite(AssetsStorage.getResource('character22_promo').texture);
		leftDecorGirl.pivot.set(leftDecorGirl.width / 2, leftDecorGirl.height / 2);
		leftDecorGirl.position.set(-608, 17);
		const rightDecorGirl = new PIXI.Sprite(AssetsStorage.getResource('character23_promo').texture);
		rightDecorGirl.pivot.set(rightDecorGirl.width / 2, rightDecorGirl.height / 2);
		rightDecorGirl.position.set(640, 13);

		const titleBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['title_window_panel'], 5, 0, 5, 5);
		titleBg.width = 998;
		titleBg.pivot.set(titleBg.width / 2, titleBg.height / 2);
		titleBg.y = -409;

		this.titleLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#go_to_event_window_title_${eventLevelKey}`),
			800,
			{ font: '34px wendyOneShadowBold', align: 'center' },
		);
		this.titleLabel.anchor = 0.5;
		this.titleLabel.y = titleBg.y;

		const rewardsFade = new PIXI.Graphics()
			.beginFill(0x19252a)
			.drawRect(0, -122, 946, 564)
			.endFill();
		rewardsFade.pivot.set(rewardsFade.width / 2, rewardsFade.height / 2);

		const rightBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		rightBorderFade.width = 44;
		rightBorderFade.height = 567;
		rightBorderFade.pivot.set(rightBorderFade.width / 2, rightBorderFade.height / 2);
		rightBorderFade.tint = 0x212f36;
		rightBorderFade.position.set(451, -122);
		rightBorderFade.scale.x = -1;
		rightBorderFade.interactive = true;
		const leftBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		leftBorderFade.width = 44;
		leftBorderFade.height = 564;
		leftBorderFade.pivot.set(leftBorderFade.width / 2, leftBorderFade.height / 2);
		leftBorderFade.tint = 0x212f36;
		leftBorderFade.position.set(-451, -122);
		leftBorderFade.interactive = true;

		const descrBg = new PIXI.Graphics()
			.beginFill(0xe8f3f7)
			.drawRect(0, 250, 884, 138)
			.endFill();
		descrBg.pivot.set(descrBg.width / 2, descrBg.height / 2);

		this.descrLabel = new TextField(
			this.localizationStorage.getLocalizedString(`#go_to_event_window_description_${eventLevelKey}`),
			{ font: { size: 26, name: 'wendyOne' }, align: 'center', tint: 0x39536b },
			864,
			120,
		);
		this.descrLabel.anchor = 0.5;
		this.descrLabel.y = 250;

		this.buttonGo = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 248, 76),
			{ font: '38px wendyOneShadowBold' },
			200,
		);
		this.buttonGo.once(ButtonWithLabelBaseView.EVENT_CLICK, () => this.onButtonGoClick(), this);
		this.buttonGo.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#go_to_event_window_join_button'));
		this.buttonGo.y = 391;

		this.timerView = new StickableTimerView(true);
		this.timerView.y = -467;

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.onTimerUpdate, this);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const animationsContainer = new PIXI.Container();
		animationsContainer.y = -409;
		animationsContainer.scale.x = 0.55;
		animationsContainer.scale.y = 0.24;
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.mainContainer.addChild(
			leftDecorGirl,
			rightDecorGirl,
			bgFade,
			animationsContainer,
			this.timerView,
			bg,
			rewardsFade,
			eventRewardsTitle,
			leagueRewardsTitle,
			goalRewardsView,
			leagueRewardsView,
			rightBorderFade,
			leftBorderFade,
			descrBg,
			this.descrLabel,
			titleBg,
			this.titleLabel as PIXI.DisplayObject,
			this.buttonGo,
		);
	}

	private onTimerUpdate(): void {
		const timeFormatted = NumberUtils.secToDHMSColonFormatted(this.eventLevelModel.getTimeleft());
		this.timerView.setTextTimer(timeFormatted);
	}

	private onButtonGoClick(): void {
		this.emit(GoToEventLevelWindowView.EVENT_BUTTON_GO_CLICK);
		this.onClose();
	}

	private onEventModelTimeout(): void {
		this.onClose();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.ticker.remove(this.onTimerUpdate, this);

		this.eventLevelModel.off(EventLevelModel.EVENT_TIMEOUT, this.onEventModelTimeout, this, true);

		super.destroy(options);
	}
}
