import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter252007View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252007View';
import { EventCharacter252008View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252008View';
import { EventCharacter252009View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252009View';

export class EventBusiness252003View extends BusinessBaseView {
	constructor() {
		const eventCharacter252007View = new EventCharacter252007View();
		const eventCharacter252008View = new EventCharacter252008View();
		const eventCharacter252009View = new EventCharacter252009View();

		super('business252003', [eventCharacter252007View, eventCharacter252008View, eventCharacter252009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter252009View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter252007View as PIXI.DisplayObject,
			eventCharacter252008View,
		);
	}
}
