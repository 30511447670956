import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter253001View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253001View';
import { EventCharacter253002View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253002View';
import { EventCharacter253003View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253003View';

export class EventBusiness253001View extends BusinessBaseView {
	constructor() {
		const eventCharacter253001View = new EventCharacter253001View();
		const eventCharacter253002View = new EventCharacter253002View();
		const eventCharacter253003View = new EventCharacter253003View();
		eventCharacter253003View.once(EventCharacter253003View.EVENT_SHOWN, () => eventCharacter253001View.destroy());

		super('business253001', [eventCharacter253001View, eventCharacter253002View, eventCharacter253003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter253001View as PIXI.DisplayObject,
			eventCharacter253002View,
			eventCharacter253003View,
		);
	}
}
