import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';

export class SkillGirlView extends PIXI.Container {
	public static readonly EVENT_ANIMATION_END: symbol = Symbol();

	constructor(girlSpriteName: string, descriptionKey: string, multiplier: number) {
		super();

		const dialogAtlas = AssetsStorage.getAtlas('dialogAtlas');
		const localizationStorage = LocalizationStorage.getInstance();

		const fade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_skill_faid']);
		fade.scale.set(3);
		fade.position.set(-50, 0);
		fade.alpha = 0.9;

		const girlSprite = new PIXI.Sprite(dialogAtlas[girlSpriteName]);
		girlSprite.position.set(-30, 57.5);

		const descriptionFrame = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_skill_text_frame']);
		descriptionFrame.position.set(-50, 340);

		const descriptionText = new TextField(
			'',
			{ font: '50px wendyOneGold', align: 'left' },
			310,
			80,
		);
		descriptionText.text = localizationStorage.getLocalizedString(descriptionKey).replace('{{value}}', multiplier.toString());
		descriptionText.anchor = new PIXI.Point(0, 0.5);
		descriptionText.position.set(-190, 325);

		this.addChild(
			fade,
			girlSprite,
			descriptionFrame,
			descriptionText as PIXI.DisplayObject,
		);

		this.position.set(-this.width / 2, GameConstants.GAME_HEIGHT - this.height / 2);
	}

	public play(): void {
		const fadeOut = new TWEEN.Tween(this)
			.to({ alpha: 0 }, 1000)
			.delay(650)
			.easing(TWEEN.Easing.Quadratic.In)
			.onComplete(() => {
				this.emit(SkillGirlView.EVENT_ANIMATION_END);
			});
		const moveIn = new TWEEN.Tween(this)
			.to({ x: this.width / 2 }, 500)
			.easing(TWEEN.Easing.Cubic.Out)
			.chain(fadeOut);
		moveIn.start();
	}
}
