import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableModel } from '@models/PromotableModel';
import { CardRarity } from '@src/types/CardRarities';
import { BaseDailyCardView } from './BaseDailyCardView';

export class CharacterDailyCardView extends BaseDailyCardView {
	private readonly icon: PIXI.Sprite;

	constructor(
		day: number,
		private readonly model?: PromotableModel,
		private readonly stickerNew?: PIXI.Container,
		rarity?: CardRarity,
	) {
		super(day);

		const charAtlas = AssetsStorage.getAtlas('characterCardAtlas');
		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		this.bgRound.tint = 0xFFFFFF;

		if (this.model != null) {
			const cardRarity = this.model.getCardRarity();
			this.bgRound.texture = uiAtlas[`cards_${cardRarity}_bg_round`];

			this.icon = new PIXI.Sprite(charAtlas[`${this.model.getKey()}_card1`]);
			this.icon.scale.set(0.38);
		} else {
			this.bgRound.texture = uiAtlas[`cards_${rarity}_bg_round`];

			this.icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_character_card2']);
			this.icon.scale.set(1.1);
		}

		this.icon.mask = this.contentMask;

		this.addChild(
			this.contentMask as PIXI.DisplayObject,
			this.icon,
			this.upTint,
			this.dayString,
			this.downTint,
			this.countString,
		);
	}

	public tryAddSticker(): void {
		if (this.stickerNew && this.model && !this.model.isOpened()) {
			this.model.once(PromotableModel.EVENT_OLD, this.removeSticker, this);
			this.addChild(this.stickerNew);
		}
	}

	public removeSticker(): void {
		if (this.stickerNew) {
			this.removeChild(this.stickerNew);
		}
	}

	public setExtraRewardMode(): void {
		super.setExtraRewardMode();

		this.icon.scale.set(0.8);

		this.stickerNew?.scale.set(1.3);
		this.stickerNew?.position.set(
			this.bgRound.width / 2 - BaseDailyCardView.STICKER_OFFSET,
			-this.bgRound.height / 2 + BaseDailyCardView.STICKER_OFFSET,
		);
	}

	public destroy(): void {
		if (this.model && this.model.listeners(PromotableModel.EVENT_OLD).includes(this.removeSticker)) {
			this.model.off(PromotableModel.EVENT_OLD, this.removeSticker, this, true);
		}
		super.destroy();
	}
}
