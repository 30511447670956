import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter60013View extends CharacterMainBaseView {
	constructor() {
		super('character60013');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-200, 31);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-178, -13),
			arrowRotation: Math.PI * 180,
			bgPosition: new PIXI.Point(-172, -5),
		});

		this.initQuickPhrases(this.key);
	}
}
