import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { BaseCardLockView } from '../BaseCardLockView';
import { CharacterModel } from '@models/CharacterModel';

export class CharacterCardLockView extends BaseCardLockView {
	constructor(model: CharacterModel) {
		super(model);

		const rarity = model.getCardRarity();

		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${rarity}_bg_round`], 3, 3, 3, 3);
		background.width = 261;
		background.height = 376;
		background.pivot.set(background.width / 2, background.height / 2);
		background.y = -20;

		const blackOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		blackOutline.width = 261;
		blackOutline.height = 415;
		blackOutline.pivot.set(blackOutline.width / 2, blackOutline.height / 2);
		blackOutline.tint = 0x00000;

		const lockSprite = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_character_card2']);
		lockSprite.setTransform(0, -20, 1.42, 1.43);

		this.lockText.y = 186;

		const rarityBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`rarity_color_${rarity}_panel`]);
		rarityBg.setTransform(0, -202, 0.5, 0.5);

		const rarityLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#hint_rarity_${rarity}`),
			107,
			{ font: '15px wendyOneShadowBold', align: 'center' },
		);
		rarityLabel.anchor = 0.5;
		rarityLabel.y = -204;

		this.addChild(
			blackOutline,
			background,
			lockSprite,
			this.lockText as PIXI.DisplayObject,
			rarityBg,
			rarityLabel,
		);
	}
}
