import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { TabButtonBaseView } from '@views/components/buttons/TabButtonBaseView';

export class SummonButtonView extends TabButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly infoIcon: PIXI.Sprite;

	constructor() {
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['casting_tab_icon']);
		icon.x = -118;

		const inactiveBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_blue_tab2'], 7, 0, 7, 0);
		inactiveBg.width = 240;
		inactiveBg.height = 127;
		inactiveBg.pivot.set(120, 63);

		const activeBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_green_tab2'], 7, 0, 34, 0);
		activeBg.width = 266;
		activeBg.height = 127;
		activeBg.pivot.set(133, 63);
		activeBg.x = 13;

		super(
			inactiveBg,
			activeBg,
			icon,
			LocalizationStorage.getInstance().getLocalizedString('#collection_window_summon_tab'),
		);

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.position.set(100, -60);
		this.infoIcon.visible = false;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.buttonLabel.position.set(28, 0);

		this.addChild(this.infoIcon);
	}

	public setInfoIconVisible(value: boolean): void {
		this.infoIcon.visible = value;
	}

	private updateText(): void {
		this.buttonLabel.text = this.localizationStorage.getLocalizedString('#collection_window_summon_tab');
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
