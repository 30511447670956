import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter254010View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254010View';
import { EventCharacter254011View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254011View';
import { EventCharacter254012View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254012View';

export class EventBusiness254004View extends BusinessBaseView {
	constructor() {
		const eventCharacter254010View = new EventCharacter254010View();
		const eventCharacter254011View = new EventCharacter254011View();
		const eventCharacter254012View = new EventCharacter254012View();

		super('business254004', [eventCharacter254010View, eventCharacter254011View, eventCharacter254012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter254010View as PIXI.DisplayObject,
			eventCharacter254011View,
			eventCharacter254012View,
		);
	}
}
