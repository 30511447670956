import { AbstractQuest } from './AbstractQuest';
import { QuestTypes } from '@src/types/QuestTypes';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { PromotableModel } from '@models/PromotableModel';

export class QuestPromoteTarget<T extends PromotableModel> extends AbstractQuest {
	private readonly target: T;
	private readonly targetLevel: number;

	private currentLevel: number;

	constructor(
		questKey: string,
		questType: QuestTypes.PROMOTE_CHARACTER | QuestTypes.PROMOTE_UPGRADE,
		target: T,
		targetLevel: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.target = target;

		this.currentLevel = this.target.getLevel();
		this.targetLevel = targetLevel;

		if (this.currentLevel >= this.targetLevel) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	private onPromoted(model: T): void {
		this.currentLevel = model.getLevel();

		if (this.currentLevel >= this.targetLevel) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	public getTargetKey(): string {
		return this.target.getKey();
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentLevel = Number(saveData.progress);
		if (this.currentLevel >= this.targetLevel) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentLevel.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetLevel.toString();
	}

	public getTargetAmount(): number {
		return this.targetLevel;
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentLevel / this.targetLevel;
	}

	protected trackProgressStop(): void {
		this.target.off(PromotableModel.EVENT_PROMOTED, this.onPromoted, this, false);
	}

	protected trackProgressStart(): void {
		this.target.on(PromotableModel.EVENT_PROMOTED, this.onPromoted, this);
	}
}
