import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';

export abstract class BaseSettingsWindowView extends PopupWindowBaseView {
	public static readonly EVENT_CHANGE_LANGUAGE: symbol = Symbol();
	public static readonly EVENT_CHANGE_MUSIC_VOLUME: symbol = Symbol();
	public static readonly EVENT_CHANGE_SOUNDS_VOLUME: symbol = Symbol();
	public static readonly EVENT_CHANGE_USERNAME: symbol = Symbol();
	public static readonly EVENT_CHANGE_CARDPAY: symbol = Symbol();

	public static readonly EVENT_BUTTON_COLLECT_REWARD_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_SUPPORT_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_REGISTRATION_CLICK: symbol = Symbol();
}
