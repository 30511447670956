import { NewsItemConfig, NewsContentType } from '@configs/NewsItemConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { ScrollAxis } from '@src/types/ScrollViewTypes';
import { ScrollView } from '@views/components/ScrollView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { TextField } from '@views/components/text/TextField';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { NewsHeaderContentView } from './NewsHeaderContentView';
import { NewsMessageRewardContentView } from './NewsMessageRewardContentView';

export class NewsMessageContentView extends PIXI.Container {
	public static readonly EVENT_CLAIM_REWARD = Symbol();
	public static readonly EVENT_SHOW_HINT_REWARD_CARD = Symbol();

	private readonly rewardContainer: NewsMessageRewardContentView;
	private readonly contentContainer: PIXI.Container;
	private readonly localizationStorage: LocalizationStorage;

	private headerContent: NewsHeaderContentView;
	private readonly bg: PIXI.mesh.NineSlicePlane;
	private readonly contentOffset = 42;

	constructor(
		cardMiniViewFactory: CardMiniViewFactory,
		newsConfig: NewsItemConfig,
		linkUrl: string,
		isNewsOld: boolean,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.headerContent = new NewsHeaderContentView(
			this.localizationStorage.getLocalizedString(newsConfig.getHeaderText()),
			this.localizationStorage.getLocalizedString(newsConfig.getHeaderDate()),
			newsConfig.getHeaderBgKey(),
			linkUrl,
		);

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_5'], 13, 10, 13, 20);
		this.bg.width = 1526;
		this.bg.height = 752;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.bg.y = 494;

		this.rewardContainer = new NewsMessageRewardContentView(cardMiniViewFactory);
		this.rewardContainer.y = this.bg.y + 298;
		this.rewardContainer.on(NewsMessageRewardContentView.EVENT_CLAIM_REWARD, () => {
			this.emit(NewsMessageContentView.EVENT_CLAIM_REWARD);
		});
		this.rewardContainer.on(NewsMessageRewardContentView.EVENT_SHOW_HINT_REWARD_CARD, (reward, card) => {
			this.emit(NewsMessageContentView.EVENT_SHOW_HINT_REWARD_CARD, reward, card);
		});

		const rewards = newsConfig.getRewards();
		let scrollHeight = this.bg.height - this.contentOffset * 2;

		if (!isNewsOld && rewards.length > 0) {
			this.rewardContainer.setRewards(rewards);
			scrollHeight -= this.rewardContainer.height;
		} else {
			this.rewardContainer.visible = false;
		}

		const contentScrollView = new ScrollView(
			this.bg.width - this.contentOffset * 2,
			scrollHeight,
			ScrollAxis.VERTICAL,
			{ begin: 0, end: 10 },
		);

		contentScrollView.x = this.bg.x - this.bg.width / 2 + this.contentOffset;
		contentScrollView.y = this.bg.y - this.bg.height / 2 + this.contentOffset;

		this.contentContainer = new PIXI.Container();

		this.addContent(newsConfig);
		contentScrollView.setContent(this.contentContainer);

		this.addChild(
			this.bg,
			contentScrollView,
			this.rewardContainer as PIXI.DisplayObject,
			this.headerContent,
		);
	}

	public setRewardsClaimed(): void {
		this.rewardContainer.setRewardsClaimed();
	}

	private addContent(newsItem: NewsItemConfig): void {
		const contentItems = newsItem.getContent();
		const contentItemOffset = 30;

		const titleTextField = new TextField(
			this.localizationStorage.getLocalizedString(newsItem.getTitle()),
			{ font: '46px SFCompact', align: 'left' },
			this.bg.width - this.contentOffset * 2,
		);
		this.contentContainer.addChild(titleTextField);

		contentItems.forEach((contentItem) => {
			const itemContainer = new PIXI.Container();

			if (contentItem.type === NewsContentType.TEXT) {
				const textObject = this.createTextField(contentItem.text);

				itemContainer.addChild(textObject);
			} else {
				let atlasKey;

				if (contentItem.type === NewsContentType.CHARACTER) {
					atlasKey = 'characterCardAtlas';
				} else {
					atlasKey = 'upgradeIconAtlas';
				}

				const atlas = AssetsStorage.getAtlas(atlasKey);
				const texture = atlas[`${contentItem.mainSpriteKey}`];
				const mainSprite = this.createMainSprite(texture, contentItem.text);

				itemContainer.addChild(mainSprite);

				if (contentItem.spriteList.length > 0) {
					const spritesOffset = 15;

					const secondarySprites = this.createSecondarySprites(contentItem.spriteList);
					secondarySprites.y += mainSprite.height + spritesOffset;
					itemContainer.addChild(secondarySprites);
				}
			}

			itemContainer.y = this.contentContainer.height + contentItemOffset;

			this.contentContainer.addChild(itemContainer);
		});
	}

	private createTextField(text: string): PIXI.DisplayObject {
		const textField = new MultiColoredTextField(
			{ font: '30px SFCompact', align: 'left' },
			this.bg.width - this.contentOffset * 2,
		);

		textField.text = this.localizationStorage.getLocalizedString(text);

		return textField;
	}

	private createMainSprite(texture: PIXI.Texture, text: string): PIXI.Container {
		const container = new PIXI.Container();
		const spriteOffset = 40;

		const sprite = new PIXI.Sprite(texture);
		const cardScale = 0.4;

		sprite.anchor.set(0, 0);
		sprite.scale.set(cardScale, cardScale);

		const textField = new MultiColoredTextField(
			{ font: '24px wendyOneShadowBold', align: 'left' },
			this.bg.width - this.contentOffset * 2 - sprite.width - spriteOffset,
			sprite.height,
		);

		textField.text = this.localizationStorage.getLocalizedString(text);
		textField.x = sprite.width + spriteOffset;

		container.addChild(sprite, textField as PIXI.DisplayObject);

		return container;
	}

	private createSecondarySprites(sprites: { spriteKey: string, text: string }[]): PIXI.DisplayObject {
		const container = new PIXI.Container();
		const leftXOffset = 100;
		const atlas = AssetsStorage.getAtlas('uiAtlas');

		sprites.forEach((spriteItem, i) => {
			const sprite = new PIXI.Sprite(atlas[spriteItem.spriteKey]);
			const spriteYOffset = 10;
			const spriteXOffset = 12;

			sprite.anchor.set(0, 0);
			sprite.y = i * (sprite.height + spriteYOffset);

			const textField = new MultiColoredTextField(
				{ font: '24px wendyOneShadowBold', align: 'left' },
				this.bg.width - this.contentOffset * 2 - sprite.width - spriteXOffset - leftXOffset,
				sprite.height,
			);

			textField.text = this.localizationStorage.getLocalizedString(spriteItem.text);
			textField.x = sprite.width + spriteXOffset;
			textField.y = sprite.y + sprite.height / 2 - textField.height / 2;

			container.addChild(sprite, textField as PIXI.DisplayObject);
		});

		container.x = leftXOffset;

		return container;
	}
}
