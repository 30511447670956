import { BaseValidator } from './BaseValidator';

export class StringRegExpValidation extends BaseValidator<string> {
	private readonly regExp: RegExp;

	constructor(errorString: string, regExp: RegExp) {
		super(errorString);
		this.regExp = regExp;
	}

	public isValid(value: string): boolean {
		return this.regExp.test(value);
	}
}
