import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter252004View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252004View';
import { EventCharacter252005View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252005View';
import { EventCharacter252006View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252006View';

export class EventBusiness252002View extends BusinessBaseView {
	constructor() {
		const eventCharacter252004View = new EventCharacter252004View();
		const eventCharacter252005View = new EventCharacter252005View();
		const eventCharacter252006View = new EventCharacter252006View();

		super('business252002', [eventCharacter252004View, eventCharacter252005View, eventCharacter252006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter252004View as PIXI.DisplayObject,
			eventCharacter252005View,
			eventCharacter252006View,
		);
	}
}
