import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { BackToGameButtonClaimView } from './BackToGameButtonClaimView';
import { NumberUtils } from '@src/utils/NumberUtils';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class BackToGameWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SOFT_MONEY_CLAIM: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly textOfflineTime: MultiColoredTextField;
	private readonly iconSoftMoneyPos: PIXI.Point;
	private readonly closeButton: ButtonBaseView;
	private readonly canBuyMultipliedReward: boolean;

	private flyTextLabel: FlyBitmapText;
	private buttonClaim: BackToGameButtonClaimView;

	constructor(
		offlineSeconds: number,
		rewardSoftMoney: SoftMoneyNumber,
		maxOfflineTime: number,
		offlineRewardCost: number,
		offlineRewardMultiplier: number,
		hardMoneyModel: HardMoneyModel,
	) {
		super(0.85);
		this.canBuyMultipliedReward = hardMoneyModel.getValue() >= offlineRewardCost;

		this.localizationStorage = LocalizationStorage.getInstance();

		let stringOfflineTime = this.localizationStorage.getLocalizedString('#return_window_offline_time');
		stringOfflineTime = stringOfflineTime.replace('{{value}}', NumberUtils.secToDHMS(offlineSeconds));
		stringOfflineTime = stringOfflineTime.replace('{{max_value}}', NumberUtils.secToDHMS(maxOfflineTime));
		this.textOfflineTime = new MultiColoredTextField({ font: '28px wendyOneShadowBold' });
		this.textOfflineTime.text = stringOfflineTime;
		this.textOfflineTime.anchor = 0.5;
		this.textOfflineTime.y = -327;

		const girl = new PIXI.Sprite(AssetsStorage.getResource('character2_offer').texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.scale.x = -1;
		girl.position.set(597, 50);

		const bgGlow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow']);
		bgGlow.width = 640;
		bgGlow.height = 600;
		bgGlow.tint = 0x8e77c7;
		bgGlow.y = -50;
		bgGlow.blendMode = PIXI.BLEND_MODES.ADD;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['back_to_game_bg'], 0, 29, 0, 4);
		bg.height = 382;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 32;
		bg.interactive = true;

		const bgTint = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		bgTint.width = 568;
		bgTint.height = 142;
		bgTint.tint = 0x000000;
		bgTint.alpha = 0.15;
		bgTint.y = 148;

		const decor1 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor1.scale.set(1.6);
		decor1.position.set(-223, -355);
		decor1.alpha = 0.2;
		const decor2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor2.scale.set(1.6);
		decor2.position.set(72, -355);
		decor2.alpha = 0.6;

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.position.set(27, -352);
		episodeNamePanelFade.width = 873;
		episodeNamePanelFade.height = 105;
		episodeNamePanelFade.tint = 0x000000;

		const glowLine = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine.position.set(-15, -408);

		const iconMoneyBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['money_box']);
		iconMoneyBg.y = -134;

		const textWelcome = new SizeableBitmapText('', 900, { font: '53px wendyOneGold' });
		textWelcome.text = this.localizationStorage.getLocalizedString('#return_window_welcome_back');
		textWelcome.anchor = 0.5;
		textWelcome.y = -370;

		const textReward = new SizeableMultiColoredBitmapText(280, { font: '24px wendyOne', tint: 0x32353f });
		textReward.text = this.localizationStorage.getLocalizedString('#gameUI_reward');
		textReward.anchor = 0.5;
		textReward.y = -2;

		const textSoftMoney = new SizeableBitmapText(rewardSoftMoney.toString() || '', 600, { font: '54px wendyOne', tint: 0xea5d23 });
		textSoftMoney.anchor = 0.5;
		textSoftMoney.position.set(26, 37);

		const iconMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']);
		iconMoney.scale.set(0.5);
		iconMoney.position.set(-textSoftMoney.width / 2 - 6, 37);

		this.buttonClaim = new BackToGameButtonClaimView(offlineRewardCost, offlineRewardMultiplier, rewardSoftMoney.multiply(offlineRewardMultiplier));
		this.buttonClaim.on(BackToGameButtonClaimView.EVENT_CLICK, this.onButtonClaimClick, this);
		this.buttonClaim.y = 148;

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(900, -500);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.iconSoftMoneyPos = this.toLocal(iconMoney.position, this.mainContainer);

		this.mainContainer.addChild(
			girl,
			decor1,
			decor2,
			episodeNamePanelFade,
			glowLine,
			bgGlow,
			bg,
			bgTint,
			iconMoneyBg as PIXI.DisplayObject,
			textReward,
			textWelcome,
			this.textOfflineTime as PIXI.DisplayObject,
			textSoftMoney,
			iconMoney,
			this.buttonClaim,
			this.closeButton,
		);
	}

	private onButtonClaimClick(): void {
		if (this.canBuyMultipliedReward) {
			this.onSoftMoneyClaim(true);
			super.onClose();
		} else {
			const stringLabel = this.localizationStorage.getLocalizedString('#summon_fly_text_not_enough_money');
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '24px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.buttonClaim.x, this.buttonClaim.y - 40);
			this.mainContainer.addChild(this.flyTextLabel);
		}
	}

	private onSoftMoneyClaim(improved: boolean): void {
		const position = this.toGlobal(this.iconSoftMoneyPos);
		this.emit(
			BackToGameWindowView.EVENT_SOFT_MONEY_CLAIM,
			improved,
			position,
		);
	}

	protected onClose(): void {
		// prevent more clicks during the closing animation
		if (!this.closed) {
			this.onSoftMoneyClaim(false);
			super.onClose();
		}
	}
}
