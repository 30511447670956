import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character16View extends CharacterMainBaseView {
	constructor() {
		super('character16');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(252, -11);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(214, -55),
			arrowRotation: Math.PI,
			bgPosition: new PIXI.Point(208, -40),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
