import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter90005View extends CharacterMainBaseView {
	constructor() {
		super('character90005');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(195, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(159, -37),
			bgPosition: new PIXI.Point(155, -21),
			arrowRotation: Math.PI,
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
