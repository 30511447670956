import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCustomersOnSomeConfig extends QuestConfigBase {
	public static readonly FORMAT_PARAMETER: RegExp = /^\s*businesses\s*:\s*(\d+)\s*$/;

	private amount: number;
	private businessesCount: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.businessesCount = Number(config[QuestConfigBase.FIELD_PARAMETERS].match(QuestGetCustomersOnSomeConfig.FORMAT_PARAMETER)[1]);
	}

	public getAmount(): number {
		return this.amount;
	}

	public setParamsOnLevel(params: string): void {
		this.amount = Number(params);
	}

	public getBusinessesCount(): number {
		return this.businessesCount;
	}

	// eslint-disable-next-line class-methods-use-this
	public getType(): QuestType {
		return QuestTypes.GET_CUSTOMERS_ON_SOME;
	}
}
