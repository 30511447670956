import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter251001View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251001View';
import { EventCharacter251002View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251002View';
import { EventCharacter251003View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251003View';

export class EventBusiness251001View extends BusinessBaseView {
	constructor() {
		const eventCharacter251001View = new EventCharacter251001View();
		const eventCharacter251002View = new EventCharacter251002View();
		const eventCharacter251003View = new EventCharacter251003View();
		eventCharacter251003View.once(EventCharacter251003View.EVENT_SHOWN, () => eventCharacter251001View.destroy());

		super('business251001', [eventCharacter251001View, eventCharacter251002View, eventCharacter251003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter251001View as PIXI.DisplayObject,
			eventCharacter251002View,
			eventCharacter251003View,
		);
	}
}
