import { TabSlotView } from './BankTabViewFactory';

export class BankTabBundleSixSlotsTimer extends PIXI.Container {
	private static readonly OFFSET_VERTICAL: number = 462;
	private static readonly OFFSET_HORIZONTAL: number = 280;
	private static readonly ITEMS_IN_ROW: number = 3;

	public init(bundle: PIXI.Container, timer: PIXI.Container, slots: TabSlotView[]): void {
		if (MODE_DEBUG && slots.length !== 6) {
			throw new Error('Invalid input. Slots count must be 6');
		}

		if (this.children.length > 0) {
			this.removeChildren();
		}

		const bundleContainer = new PIXI.Container();
		bundleContainer.addChild(bundle);
		bundleContainer.position.set(60, 10);

		const timerContainer = new PIXI.Container();
		timerContainer.addChild(timer);
		timerContainer.position.set(-100, -520);


		const slotsLayoutElements = new PIXI.Container();
		slots.forEach(slot => {
			const layoutElement = new PIXI.Container();
			const row: number = Math.floor(slot.index / BankTabBundleSixSlotsTimer.ITEMS_IN_ROW);
			const column: number = slot.index % BankTabBundleSixSlotsTimer.ITEMS_IN_ROW;

			layoutElement.position.set(BankTabBundleSixSlotsTimer.OFFSET_HORIZONTAL * column, BankTabBundleSixSlotsTimer.OFFSET_VERTICAL * row);
			layoutElement.addChild(slot.view);
			slotsLayoutElements.addChild(layoutElement);
		});

		slotsLayoutElements.position.set(520, -262);

		this.addChild(
			bundleContainer,
			timerContainer,
			slotsLayoutElements,
		);
	}
}
