export class ViewUtils {
	public static positionLayoutHorizontal(targets: PIXI.Container[], marginX: number): void {
		const marginXHalf = marginX / 2;
		let centerLeft: number;
		let centerRight: number;
		if (targets.length % 2 === 0) {
			centerLeft = targets.length / 2 - 1;
			centerRight = targets.length / 2;
			for (let i = centerLeft; i >= 0; i--) {
				const target = targets[i];
				target.x += marginXHalf + target.width / 2;
			}
			for (let i = centerRight; i < targets.length; i++) {
				const target = targets[i];
				target.x = target.x - marginXHalf - target.width / 2;
			}
		} else {
			centerLeft = Math.floor(targets.length / 2) - 1;
			centerRight = Math.ceil(targets.length / 2);
		}

		let j = 1;
		for (let i = centerLeft; i >= 0; i--) {
			const sprite = targets[i];
			sprite.x = sprite.x - marginX * j - sprite.width * j;
			j += 1;
		}
		j = 1;
		for (let i = centerRight; i < targets.length; i++) {
			const sprite = targets[i];
			sprite.x += marginX * j + sprite.width * j;
			j += 1;
		}
	}
}
