import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { Emitter } from 'pixi-particles';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import { IncomeProgressBarView } from './IncomeProgressBarView';
import { IncomeHighSpeedProgressBarView } from './IncomeHighSpeedProgressBarView';
import * as TWEEN from '@tweenjs/tween.js';
import { BusinessAcquiredUIView } from '../BusinessAcquiredUIView';

export class IncomeView extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly titleFade: PIXI.Sprite;
	private readonly background: PIXI.Graphics;
	private readonly textIncome: SizeableBitmapText;
	private readonly softMoneyIconContainer: PIXI.Container;
	private readonly textIncomeAnimationContainer: PIXI.Container;
	private readonly progressBar: IncomeProgressBarView;
	private readonly progressBarHighSpeed: IncomeHighSpeedProgressBarView;

	private incomeTimeInSec: number;

	private incomeValue: string;
	private incomePerSecValue: string;

	private fxAtlas: PIXI.loaders.TextureDictionary;
	private profitImproveEmitters: Emitter[];
	private textIncomeAnimationTween: TWEEN.Tween;

	private textScaleNewIncomeTween: TWEEN.Tween;
	private textScaleIncomeTween: TWEEN.Tween;

	private automated: boolean;

	constructor(
		automated: boolean,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.automated = automated;

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.titleFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['orgy_icon_fade']);
		this.titleFade.setTransform(-70, -40, 0.7, -0.4);

		this.background = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(-210, 0, 406, 38)
			.endFill();

		this.background.pivot.set(0, this.background.height / 2);

		this.textIncome = new SizeableBitmapText('', 200, { font: '25px wendyOneShadowBold', tint: 0xffee90 });
		this.textIncome.position.set(-138, 0);
		this.textIncome.anchor = new PIXI.Point(0, 0.5);
		this.textIncomeAnimationContainer = new PIXI.Container();
		this.textIncomeAnimationContainer.position.set(this.textIncome.x - this.textIncome.width / 2, this.textIncome.y);

		const softMoneyIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money']);
		softMoneyIcon.x = -18;
		softMoneyIcon.scale.set(0.95);
		softMoneyIcon.rotation = Math.PI / 180 * -5;

		this.softMoneyIconContainer = new PIXI.Container();
		this.softMoneyIconContainer.addChild(softMoneyIcon);
		this.softMoneyIconContainer.position.set(-145, 1);

		this.progressBar = new IncomeProgressBarView(this.background.width, this.background.height);
		this.progressBar.x = -210;

		this.progressBarHighSpeed = new IncomeHighSpeedProgressBarView(this.background.width);

		this.addChild(
			this.titleFade,
			this.background,
			this.progressBar,
			this.progressBarHighSpeed,
			this.textIncomeAnimationContainer,
			this.textIncome as PIXI.DisplayObject,
			this.softMoneyIconContainer,
		);

		this.profitImproveEmitters = [];
	}

	private updateLayout(): void {
		this.textIncomeAnimationContainer.position.set(this.textIncome.x - this.textIncome.width / 2, this.textIncome.y);
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private updateHighSpeedBarVisible(): void {
		const highSpeedBarVisible = this.incomeTimeInSec <= BusinessAcquiredUIView.INCOME_TIME_FOR_HIGH_SPEED_BAR_SEC && this.automated;

		this.progressBar.visible = !highSpeedBarVisible;
		this.progressBarHighSpeed.visible = highSpeedBarVisible;

		const isHighSpeedBarAnimating = this.progressBarHighSpeed.isAnimating();
		if (highSpeedBarVisible) {
			if (!isHighSpeedBarAnimating) {
				this.progressBarHighSpeed.startAnimation();
			}
		} else if (isHighSpeedBarAnimating) {
			this.progressBarHighSpeed.stopAnimation();
		}
	}

	private updateTextIncome(): void {
		if (this.automated && this.incomeTimeInSec <= BusinessAcquiredUIView.INCOME_TIME_FOR_HIGH_SPEED_BAR_SEC) {
			this.textIncome.text = this.localizationStorage.getLocalizedString('#income_in_sec')
				.replace('{{value}}', this.incomePerSecValue);
		} else {
			this.textIncome.text = `+${this.incomeValue}`;
		}
	}

	public setAutomated(): void {
		this.automated = true;

		this.updateTextIncome();
		this.updateHighSpeedBarVisible();
		this.updateLayout();
	}

	public setTextIncome(income: string, incomePerSec: string, animateChange: boolean = true): IncomeView {
		this.incomeValue = income;
		this.incomePerSecValue = incomePerSec;

		this.updateTextIncome();
		this.updateLayout();

		if (!this.textScaleNewIncomeTween && animateChange) {
			if (this.textScaleIncomeTween) {
				this.textScaleIncomeTween.stop();
			}
			this.textScaleNewIncomeTween = new TWEEN.Tween(this.textIncome.scale)
				.to({ x: this.textIncome.scale.x + 0.15, y: this.textIncome.scale.y + 0.15 }, 100)
				.onUpdate(this.updateLayout.bind(this))
				.repeat(1)
				.yoyo(true)
				.onComplete(() => { this.textScaleNewIncomeTween = null; })
				.start();
		}
		return this;
	}

	public setIncomeTimeInterval(timeInSec: number): IncomeView {
		this.incomeTimeInSec = timeInSec;

		this.updateTextIncome();
		this.updateHighSpeedBarVisible();
		this.updateLayout();
		return this;
	}

	public glowBarAnimation(): void {
		this.progressBar.glowBarAnimation();
	}

	public setProgress(value: number, deltaReduce: number): void {
		this.progressBar.setProgress(value, deltaReduce);
	}

	public reset(): void {
		this.progressBar.reset();
	}

	public startSkillAnimation(): void {
		const pointSizeEmitter = new Emitter(
			this.textIncomeAnimationContainer,
			[this.fxAtlas['skill_income_boost']],
			SkillParticleConfig.getPointSize3(),
		);
		this.profitImproveEmitters.push(pointSizeEmitter);
		pointSizeEmitter.playOnceAndDestroy();

		const buttonStarEmitter = new Emitter(
			this.textIncomeAnimationContainer,
			[this.fxAtlas['skill_star']],
			SkillParticleConfig.getButtonStar1(),
		);
		this.profitImproveEmitters.push(buttonStarEmitter);
		buttonStarEmitter.playOnceAndDestroy();

		const pointRotationEmitter = new Emitter(
			this.softMoneyIconContainer,
			[this.fxAtlas['skill_income_boost_glow']],
			SkillParticleConfig.getPointRotation3(),
		);
		pointRotationEmitter.autoUpdate = true;
		this.profitImproveEmitters.push(pointRotationEmitter);

		this.textIncomeAnimationTween = new TWEEN.Tween(this.softMoneyIconContainer.scale)
			.to({ x: 1.07, y: 1.07 }, 500)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.repeat(Infinity)
			.yoyo(true);
		this.textIncomeAnimationTween.start();
		this.textIncome.tint = 0xe4bd42;
	}

	public stopSkillAnimation(): void {
		this.profitImproveEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		if (this.textIncomeAnimationTween) {
			this.textIncomeAnimationTween.stop();
			this.softMoneyIconContainer.scale.set(1);
			this.textIncomeAnimationTween = null;
			this.textIncome.tint = 0xffee90;
		}
	}

	public onZoomIn(): void {
		new TWEEN.Tween(this.titleFade, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
		new TWEEN.Tween(this.background, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
		new TWEEN.Tween(this.textIncome, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
		new TWEEN.Tween(this.softMoneyIconContainer, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();

		this.progressBar.onZoomIn();
		this.progressBarHighSpeed.onZoomIn();
	}

	public onZoomOut(): void {
		new TWEEN.Tween(this.titleFade, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
		new TWEEN.Tween(this.background, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
		new TWEEN.Tween(this.textIncome, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
		new TWEEN.Tween(this.softMoneyIconContainer, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();


		this.progressBar.onZoomOut();
		this.progressBarHighSpeed.onZoomOut();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.updateTweenGroup, this);

		if (this.textScaleNewIncomeTween) {
			this.textScaleNewIncomeTween.stop();
		}
		this.tweenGroup.removeAll();

		this.profitImproveEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		if (this.textIncomeAnimationTween) {
			this.textIncomeAnimationTween.stop();
		}

		super.destroy(options);
	}
}
