import { BaseValidator } from './BaseValidator';

export class StringLengthValidator extends BaseValidator<string> {
	private readonly minLength: number;
	private readonly maxLength: number;

	constructor(errorString: string, minLength: number = 0, maxLength: number = Number.MAX_SAFE_INTEGER) {
		super(errorString);

		this.minLength = minLength;
		this.maxLength = maxLength;
	}

	public isValid(value: string): boolean {
		return this.minLength <= value.length && this.maxLength >= value.length;
	}
}
