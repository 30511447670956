import { BankGemShopItemConfig } from '@configs/bank/BankGemShopItemConfig';
import { ModelWithOffer } from '@interfaces/ModelWithOffer';
import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { NumberUtils } from '@src/utils/NumberUtils';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithCostValueView';

export class BankGemShopItemModel implements ModelWithOffer {
	public static readonly DEFAULT_PURCHASE_HARD_MONEY_IMAGE = 'bank_hard_money_lot3';
	public static readonly DEFAULT_PURCHASE_PRESTIGE_MONEY_IMAGE = 'bank_prestige_money_lot3';

	private readonly viewKey: string;
	private readonly key: string;
	private readonly mostPopularLocaleKey: string;
	private readonly stickerLocaleKey: string;
	private readonly price: number;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly oldPrice: number;
	private readonly oldPriceResource: BankPriceResourceTypes;
	private readonly rewardDescription: RewardDescriptionType;
	private readonly extraRewards: RewardDescriptionType[];
	private readonly offerKey: string;
	private readonly buttonPriceResource: ButtonValueTypes;
	private readonly buttonOldPriceResource: ButtonValueTypes;

	constructor(
		config: BankGemShopItemConfig,
		priceConversionCoeff: number,
		buttonPriceResource: ButtonValueTypes,
		buttonOldPriceResource: ButtonValueTypes,
	) {
		this.key = config.getKey();
		this.mostPopularLocaleKey = config.getMostPopularLocaleKey();
		this.stickerLocaleKey = config.getStickerLocaleKey();
		this.priceResource = config.getPriceResource();
		this.buttonPriceResource = buttonPriceResource;
		this.buttonOldPriceResource = buttonOldPriceResource;
		this.price = NumberUtils.convertPrice(config.getPrice(), this.priceResource, priceConversionCoeff);
		if (config.hasOldPrice()) {
			this.oldPriceResource = config.getOldPriceResource();
			this.oldPrice = NumberUtils.convertPrice(Number(config.getOldPrice()), this.oldPriceResource, priceConversionCoeff);
		}
		this.rewardDescription = config.getRewardDescription();
		this.extraRewards = config.getExtraRewardDescriptions();
		this.offerKey = config.getOfferKey();
		this.viewKey = config.getViewKey();
	}

	public getViewKey(): string {
		return this.viewKey;
	}

	public getKey(): string {
		return this.key;
	}

	public getMostPopularLocaleKey(): string {
		return this.mostPopularLocaleKey;
	}

	public hasSticker(): boolean {
		return this.stickerLocaleKey !== undefined;
	}

	public getStickerLocaleKey(): string {
		return this.stickerLocaleKey;
	}

	public getPrice(): number {
		return this.price;
	}

	public getOldPrice(): number {
		return this.oldPrice;
	}

	public hasOldPrice(): boolean {
		return Boolean(this.oldPrice);
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getOldPriceResource(): BankPriceResourceTypes {
		return this.oldPriceResource;
	}

	public getButtonPriceResource(): ButtonValueTypes {
		return this.buttonPriceResource;
	}

	public getButtonOldPriceResource(): ButtonValueTypes {
		return this.buttonOldPriceResource;
	}

	public getRewardDescription(): RewardDescriptionType {
		return this.rewardDescription;
	}

	public hasExtraRewards(): boolean {
		return this.extraRewards !== undefined;
	}

	public getExtraRewardDescriptions(): RewardDescriptionType[] {
		return this.extraRewards;
	}

	public hasOfferKey(): boolean {
		return Boolean(this.offerKey);
	}

	public getOfferKey(): string {
		return this.offerKey;
	}
}
