import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20018View } from '@views/characters/events/event2/EventCharacter20018View';
import { EventCharacter20017View } from '@views/characters/events/event2/EventCharacter20017View';
import { EventCharacter20016View } from '@views/characters/events/event2/EventCharacter20016View';

export class EventBusiness20006View extends BusinessBaseView {
	constructor() {
		const character20016 = new EventCharacter20016View();
		const character20017 = new EventCharacter20017View();
		const character20018 = new EventCharacter20018View();

		super('business20006', [character20016, character20017, character20018]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character20016 as PIXI.DisplayObject,
			character20017,
			character20018,
		);
	}
}
