import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeQuestCompleteModel extends TutorialStepUnlockTypeBaseModel {
	private readonly questKey: string;
	private readonly questLinesModel: QuestLinesModel;

	constructor(key: string, questKey: string, questLinesModel: QuestLinesModel) {
		super(key, TutorialStepUnlockTypes.QUEST_COMPLETE);

		this.questKey = questKey;
		this.questLinesModel = questLinesModel;
		this.questLinesModel.on(QuestLinesModel.EVENT_QUEST_COMPLETED, this.onSomeQuestCompleted, this);
	}

	private onSomeQuestCompleted(quest: AbstractQuest): void {
		if (quest.getKey() === this.questKey) {
			if (quest.hasLootbox()) {
				this.onUnlocked();
			} else {
				quest.once(AbstractQuest.EVENT_REWARD_RECEIVED, this.onUnlocked, this);
			}
		}
	}

	public tryUnlock(): boolean {
		if (!this.unlocked) {
			const currentQuests = this.questLinesModel.getCurrentQuests();
			if (currentQuests.some(quest => quest.isCompleted() && (this.questKey === quest.getKey()))) {
				this.unlocked = true;
				return true;
			}
		}
		return false;
	}

	protected onUnlocked(): void {
		this.questLinesModel.off(QuestLinesModel.EVENT_QUEST_COMPLETED, this.onSomeQuestCompleted, this);
		super.onUnlocked();
	}

	public destroy(): void {
		if (this.questLinesModel.listeners(QuestLinesModel.EVENT_QUEST_COMPLETED).includes(this.onSomeQuestCompleted)) {
			this.questLinesModel.off(QuestLinesModel.EVENT_QUEST_COMPLETED, this.onSomeQuestCompleted, this);
		}
		super.destroy();
	}
}
