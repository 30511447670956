import { LevelBackground } from './LevelBaseView';
import { Business1View } from '@views/businesses/building1/Business1View';
import { Business2View } from '@views/businesses/building1/Business2View';
import { Business3View } from '@views/businesses/building1/Business3View';
import { Business4View } from '@views/businesses/building2/Business4View';
import { Business5View } from '@views/businesses/building2/Business5View';
import { Business6View } from '@views/businesses/building2/Business6View';
import { Business7View } from '@views/businesses/building3/Business7View';
import { Business8View } from '@views/businesses/building3/Business8View';
import { Business9View } from '@views/businesses/building3/Business9View';
import { Business10View } from '@views/businesses/building4/Business10View';
import { Business11View } from '@views/businesses/building4/Business11View';
import { Business12View } from '@views/businesses/building4/Business12View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@main/AssetsStorage';
import { Business13View } from '@views/businesses/building5/Business13View';
import { Business14View } from '@views/businesses/building5/Business14View';
import { Business15View } from '@views/businesses/building5/Business15View';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { LevelDefaultView } from './LevelDefaultView';
import { LevelSnowAnimationView } from './LevelSnowAnimationView';

export class Season1LevelDefaultView extends LevelDefaultView {
	private static readonly LEVEL_WIDTH: number = 5130;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			case TimesOfDayTypes.NIGHT:
				this.createNightLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			case TimesOfDayTypes.NIGHT:
				return this.createNightBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	private static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();
		const levelBgAtlas = AssetsStorage.getAtlas('miamiMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['miami_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 300);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['miami_morning/road'], Season1LevelDefaultView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(-55, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house4']).setTransform(719, 580),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(191, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(219, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(1158, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(1513, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(1221, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm2']).setTransform(1809, 668),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house2']).setTransform(2197, 579),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(1887, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm2']).setTransform(2509, 668),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(2940, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(2587, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(3450, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house4']).setTransform(4050, 580),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(4520, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(4500, 900),

		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(1019, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(2366, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(3713, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(5060, 576),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('miamiMorningAtlas');

		this.background = Season1LevelDefaultView.createMorningBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season1LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_stairs']).setTransform(1085, 300.5),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right1']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left']).setTransform(2152, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_stairs']).setTransform(2925, 300.5),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right2']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top2']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left2']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/logo_wet_pussy']).setTransform(3030, 500),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right1']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left']).setTransform(3992, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_stairs']).setTransform(4765, 300.5),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season1LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	protected getPrePartyCharacterPosition(): PIXI.Point {
		const randomIndex: number = this.getAcquiredBuildings().sort(() => Math.random() - 0.5).pop() || 0;

		const positions = [
			new PIXI.Point(1100, 853),
			new PIXI.Point(2020, 853),
			new PIXI.Point(2940, 853),
			new PIXI.Point(3860, 853),
			new PIXI.Point(4780, 853),
		];

		return positions[randomIndex];
	}

	private static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('miamiEveningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['miami_evening/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 682);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['miami_evening/road'], Season1LevelDefaultView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(-55, 520),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house4']).setTransform(719, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(191, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(219, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(1158, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(1513, 520),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(1221, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm2']).setTransform(1809, 668),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house2']).setTransform(2197, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(1887, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm2']).setTransform(2509, 668),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(2940, 520, -1),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(2587, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(3450, 528),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house4']).setTransform(4050, 580),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(4520, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(4500, 900),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(759, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(2104, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(3449, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(5060, 576),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('miamiEveningAtlas');

		this.background = Season1LevelDefaultView.createEveningBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season1LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_stairs']).setTransform(1085, 300.5),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right1']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left']).setTransform(2152, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_stairs']).setTransform(2925, 300.5),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right2']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top2']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left2']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/logo_wet_pussy']).setTransform(3030, 500),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right1']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left']).setTransform(3992, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_stairs']).setTransform(4765, 300.5),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season1LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	private static createNightBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('miamiNightAtlas');

		const sky = new PIXI.extras.TilingSprite(levelBgAtlas['miami_night/sky'], Season1LevelDefaultView.LEVEL_WIDTH, 1128);
		sky.anchor.set(0, 1);
		sky.y = GameConstants.GAME_HEIGHT;

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['miami_night/road'], Season1LevelDefaultView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/house1']).setTransform(-55, 518),
			new PIXI.Sprite(levelBgAtlas['miami_night/house4']).setTransform(719, 560),
			new PIXI.Sprite(levelBgAtlas['miami_night/palm1']).setTransform(191, 627),
			new PIXI.Sprite(levelBgAtlas['miami_night/fence']).setTransform(219, 900),
			new PIXI.Sprite(levelBgAtlas['miami_night/palm1']).setTransform(1158, 627),
			new PIXI.Sprite(levelBgAtlas['miami_night/house1']).setTransform(1513, 518),
			new PIXI.Sprite(levelBgAtlas['miami_night/fence']).setTransform(1221, 900),
			new PIXI.Sprite(levelBgAtlas['miami_night/palm2']).setTransform(1809, 668),
			new PIXI.Sprite(levelBgAtlas['miami_night/house2']).setTransform(2197, 569),
			new PIXI.Sprite(levelBgAtlas['miami_night/fence']).setTransform(1887, 900),
			new PIXI.Sprite(levelBgAtlas['miami_night/palm2']).setTransform(2509, 668),
			new PIXI.Sprite(levelBgAtlas['miami_night/house1']).setTransform(2940, 518, -1),
			new PIXI.Sprite(levelBgAtlas['miami_night/fence']).setTransform(2587, 900),
			new PIXI.Sprite(levelBgAtlas['miami_night/house1']).setTransform(3450, 528),
			new PIXI.Sprite(levelBgAtlas['miami_night/house4']).setTransform(4050, 580),
			new PIXI.Sprite(levelBgAtlas['miami_night/palm1']).setTransform(4520, 644),
			new PIXI.Sprite(levelBgAtlas['miami_night/fence']).setTransform(4500, 900),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['miami_night/city']).setTransform(1019, 576),
			new PIXI.Sprite(levelBgAtlas['miami_night/city']).setTransform(2366, 576),
			new PIXI.Sprite(levelBgAtlas['miami_night/city']).setTransform(3713, 576),
			new PIXI.Sprite(levelBgAtlas['miami_night/city']).setTransform(5060, 576),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createNightLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('miamiNightAtlas');

		this.background = Season1LevelDefaultView.createNightBackground();
		this.addChild(this.background);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['miami_night/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_stairs']).setTransform(1085, 300.5),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['miami_night/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['miami_night/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/building_right1']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['miami_night/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_left']).setTransform(2152, 539),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_stairs']).setTransform(2925, 300.5),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/building_right2']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_top2']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['miami_night/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_left2']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['miami_night/logo_wet_pussy']).setTransform(3030, 500),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_night/building_right1']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['miami_night/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_left']).setTransform(3992, 539),
			new PIXI.Sprite(levelBgAtlas['miami_night/building_stairs']).setTransform(4765, 300.5),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season1LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	// eslint-disable-next-line class-methods-use-this
	private createPrepartyStartCharacter(): PrepartyStartCharacterView {
		const view = new PrepartyStartCharacterView();
		view.visible = false;
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	private createSnowAnimation(wight: number): LevelSnowAnimationView {
		const view = new LevelSnowAnimationView(wight);
		return view;
	}
}
