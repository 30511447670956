import { BaseLinksView } from './BaseLinksView';
import { LevelAssetNames } from '@src/types/LevelAssetNames';
import { LevelPreloaderView } from './LevelPreloaderView';
import { NutakuPreloaderLinksView } from './NutakuPreloaderLinksView';

export class NutakuLevelPreloaderView extends LevelPreloaderView {
	protected loadAssets(): void {
		this.mainLoader.addAssetsToLoad(LevelAssetNames.MAIN_NUTAKU_ASSETS_TO_LOAD);
		this.subLoader.addAssetsToLoad(LevelAssetNames.SIDE_ASSETS_TO_LOAD);
	}

	protected onAllResourcesLoaded(): void {
		super.onAllResourcesLoaded();

		this.hideButtonLogin();
	}

	// eslint-disable-next-line class-methods-use-this
	protected createLinksView(): BaseLinksView {
		return new NutakuPreloaderLinksView();
	}
}
