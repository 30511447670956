import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export class QuestSpendMoneyConfig extends QuestConfigBase {
	private amount: number;
	private readonly type: QuestTypes;

	constructor(config: { [key: string]: string }) {
		super(config);

		switch (config[QuestConfigBase.FIELD_SUB_TYPE]) {
			case QuestConfigBase.SUB_TYPE_PRESTIGE: {
				this.type = QuestTypes.SPEND_PRESTIGE_MONEY;
				break;
			}

			case QuestConfigBase.SUB_TYPE_HARD: {
				this.type = QuestTypes.SPEND_HARD_MONEY;
				break;
			}

			default:
				throw new Error(`unknown QuestSpendMoneyConfig type '${config.subType}'`);
		}
	}

	public setParamsOnLevel(params: string): void {
		this.amount = Number(params);
	}

	public getAmount(): number {
		return this.amount;
	}

	// eslint-disable-next-line class-methods-use-this
	public getType(): QuestType {
		return this.type;
	}
}
