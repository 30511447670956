import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character26View extends CharacterMainBaseView {
	constructor() {
		super('character26');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-140, 46);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-180, -18),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-166, -20),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
