import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60001View } from '@views/characters/events/event6/EventCharacter60001View';
import { EventCharacter60002View } from '@views/characters/events/event6/EventCharacter60002View';
import { EventCharacter60003View } from '@views/characters/events/event6/EventCharacter60003View';

export class EventBusiness60001View extends BusinessBaseView {
	constructor() {
		const eventCharacter60001View = new EventCharacter60001View();
		const eventCharacter60002View = new EventCharacter60002View();
		const eventCharacter60003View = new EventCharacter60003View();

		super('business60001', [eventCharacter60001View, eventCharacter60002View, eventCharacter60003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter60001View as PIXI.DisplayObject,
			eventCharacter60002View,
			eventCharacter60003View,
		);
	}
}
