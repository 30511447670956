import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter251004View extends CharacterMainBaseView {
	constructor() {
		super('character251004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(92, -7);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(142, -61),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(326, -43),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
