import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character13View extends CharacterMainBaseView {
	constructor() {
		super('character13');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-204, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-180, -60),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(-175, -50),
			invertBg: false,
		});

		this.initQuickPhrases(this.key);
	}
}
