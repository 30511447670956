
import * as TWEEN from '@tweenjs/tween.js';
import { AssetLoader } from './AssetLoader';

export class FakeLoaderProgress extends PIXI.utils.EventEmitter {
	public static readonly EVENT_PROGRESS: symbol = Symbol();
	public static readonly EVENT_COMPLETE: symbol = Symbol();

	private readonly fakeProgressTime: number;
	private readonly maxFakeProgress: number;
	private tween: TWEEN.Tween;
	private fakeProgress: number;
	private realProgress: number;

	constructor(fakeProgressTime: number, maxFakeProgress: number) {
		super();

		this.fakeProgressTime = fakeProgressTime;
		this.maxFakeProgress = maxFakeProgress;
		this.fakeProgress = 0;
		this.realProgress = 0;
	}

	public setLoader(loader: AssetLoader): void {
		loader
			.on('progress', this.onProgress.bind(this))
			.on('complete', this.onComplete.bind(this))
			.on('start', () => {
				if (this.maxFakeProgress > 0) {
					this.tween = new TWEEN.Tween(this)
						.easing(TWEEN.Easing.Cubic.In)
						.to({ fakeProgress: this.maxFakeProgress }, this.fakeProgressTime * 1000)
						.onUpdate(this.onProgressUpdate.bind(this))
						.start();
				}
			});
	}

	private onProgress(loader: PIXI.loaders.Loader): void {
		this.realProgress = loader.progress;
		this.onProgressUpdate();
	}

	private onComplete(): void {
		if (this.tween) {
			this.tween.stop();
		}
		this.emit(FakeLoaderProgress.EVENT_COMPLETE);
	}

	private onProgressUpdate(): void {
		const realProgressCoefficient: number = (100 - this.maxFakeProgress) / 100;
		const totalProgress: number = this.fakeProgress + this.realProgress * realProgressCoefficient;
		this.emit(FakeLoaderProgress.EVENT_PROGRESS, Math.max(this.realProgress, totalProgress));
	}
}
