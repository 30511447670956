import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70007View } from '@views/characters/events/event7/EventCharacter70007View';
import { EventCharacter70009View } from '@views/characters/events/event7/EventCharacter70009View';
import { EventCharacter70008View } from '@views/characters/events/event7/EventCharacter70008View';

export class EventBusiness70003View extends BusinessBaseView {
	constructor() {
		const eventCharacter70007View = new EventCharacter70007View();
		const eventCharacter70008View = new EventCharacter70008View();
		const eventCharacter70009View = new EventCharacter70009View();


		super('business70003', [eventCharacter70007View, eventCharacter70008View, eventCharacter70009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;
		const decor4 = new PIXI.Sprite(decorationAtlas['b5_decor4']).setTransform(3, 0, 0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
			decor4,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(eventCharacter70007View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter70008View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter70009View, this.getChildIndex(decor4) + 1);
	}
}
