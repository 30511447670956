import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export class SpriteHighlightAnimationView extends PIXI.Container {
	private maskSprite: PIXI.Sprite;
	private glowSprite: PIXI.Sprite;
	private starSprite: PIXI.Sprite;
	private duration: number;
	private withStar: boolean;

	private glowTween: TWEEN.Tween;
	private readonly ticker: PIXI.ticker.Ticker;
	private lastStarTime: number;

	constructor(maskSprite: PIXI.Sprite, duration: number = 1200, withStar: boolean = true) {
		super();
		this.ticker = PIXI.ticker.shared;
		this.maskSprite = maskSprite;
		this.duration = duration;
		this.withStar = withStar;
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.glowSprite = new PIXI.Sprite(fxAtlas['ui_blick_strip']);
		this.glowSprite.position.set(-maskSprite.width / 2, maskSprite.height / 2);
		this.glowSprite.mask = maskSprite;

		this.starSprite = new PIXI.Sprite(fxAtlas['ui_blick_star']);
		this.starSprite.alpha = 0;
		this.starSprite.scale.set(0.3);

		this.addChild(
			this.maskSprite,
			this.glowSprite,
			this.starSprite,
		);
	}

	private update(): void {
		if (this.lastStarTime + 1500 + this.duration <= this.ticker.lastTime) {
			this.lastStarTime = this.ticker.lastTime;
			new TWEEN.Tween(this.starSprite)
				.to({ alpha: 1 }, 300)
				.easing(TWEEN.Easing.Cubic.Out)
				.repeat(1)
				.yoyo(true)
				.start();
			this.calculateStarPosition();
		}
	}

	private calculateStarPosition(): void {
		this.starSprite.position.set(
			Math.random() * this.maskSprite.width * 0.8 - this.maskSprite.width * 0.4,
			Math.random() * this.maskSprite.height * 0.4 - this.maskSprite.height * 0.4,
		);
	}

	public start(): void {
		if (this.withStar) {
			this.ticker.add(this.update, this);
			this.lastStarTime = this.ticker.lastTime;
		}

		this.glowTween = new TWEEN.Tween(this.glowSprite)
			.to({ x: this.maskSprite.width * 1.2 / 2, y: -this.maskSprite.height / 2 }, this.duration)
			.repeatDelay(1500)
			.easing(TWEEN.Easing.Cubic.Out)
			.repeat(Infinity)
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);
		this.glowTween.stop();
		super.destroy(options);
	}
}
