import { DailyRewardConstants } from '@configs/ConstantsConfig';
import { DailyBonusType, DailyRewardConfig } from '@configs/DailyRewardConfig';
import { NumberUtils } from '@src/utils/NumberUtils';

export class DailyRewardsModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_CURRENT_REWARD_UPDATED = Symbol();

	private readonly items: DailyRewardConfig[];
	private readonly leftCharacterKey: string;
	private readonly rightCharacterKey: string;
	private readonly minLevel: number;
	private readonly isEnabled: boolean;
	private currentDay: number;
	private currentBonusType: DailyBonusType;
	private isApplied: boolean;
	private nextRewardTime: number;
	private inited: boolean;

	constructor(
		rewardConfigs: Map<string, DailyRewardConfig>,
		constants: DailyRewardConstants,
	) {
		super();

		this.items = Array.from(rewardConfigs).map(([, item]) => item);

		const leftIdx = Math.floor(Math.random() * constants.leftCharacterKeys.length);
		const rightIdx = Math.floor(Math.random() * constants.rightCharacterKeys.length);
		this.leftCharacterKey = constants.leftCharacterKeys[leftIdx];
		this.rightCharacterKey = constants.rightCharacterKeys[rightIdx];
		this.minLevel = constants.minLevel;
		this.isEnabled = constants.isEnabled;

		this.inited = false;
	}

	public getItems(): DailyRewardConfig[] {
		return this.items.filter((item) => item.getBonusType() === this.currentBonusType);
	}

	public getNextRewardPeriod(): number {
		const currentTime = Math.round(Date.now() / 1000);
		return Math.max(this.nextRewardTime - currentTime, 0);
	}

	public getLeftCharacterKey(): string {
		return this.leftCharacterKey;
	}

	public getRightCharacterKey(): string {
		return this.rightCharacterKey;
	}

	public getMinLevel(): number {
		return this.minLevel;
	}

	public getIsEnabled(): boolean {
		return this.isEnabled;
	}

	public getCurrentDay(): number {
		return this.currentDay;
	}

	public getCurrentBonusType(): DailyBonusType {
		return this.currentBonusType;
	}

	public isRewardClaimable(): boolean {
		return !this.isApplied;
	}

	public updateCurrentRewardData(
		day: number,
		type: string,
		isApplied: boolean,
		nextRewardDate: string,
	): void {
		this.currentDay = day;
		this.currentBonusType = type as DailyBonusType;
		this.isApplied = isApplied;
		this.nextRewardTime = NumberUtils.dailyRewardDateStringToTimestamp(nextRewardDate);
		this.inited = true;

		this.emit(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED);
	}

	public setRewardCollected(): void {
		this.isApplied = true;
	}

	public isInited(): boolean {
		return this.inited;
	}
}
