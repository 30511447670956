import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter252013View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252013View';
import { EventCharacter252014View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252014View';
import { EventCharacter252015View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252015View';

export class EventBusiness252005View extends BusinessBaseView {
	constructor() {
		const eventCharacter252013View = new EventCharacter252013View();
		const eventCharacter252014View = new EventCharacter252014View();
		const eventCharacter252015View = new EventCharacter252015View();

		super('business252005', [eventCharacter252013View, eventCharacter252014View, eventCharacter252015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter252013View as PIXI.DisplayObject,
			eventCharacter252014View,
			eventCharacter252015View,
		);
	}
}
