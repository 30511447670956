import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { DiscordButtonView } from './DiscordButtonView';

export enum NewsHeaderViewType {
	VIEWTYPE_1 = 'mail_header_bg_1',
	VIEWTYPE_2 = 'mail_header_bg_2',
	VIEWTYPE_3 = 'mail_header_bg_3',
	VIEWTYPE_4 = 'mail_header_bg_4',
	VIEWTYPE_5 = 'mail_header_bg_5',
	VIEWTYPE_6 = 'mail_header_bg_6',
	VIEWTYPE_7 = 'mail_header_bg_7',
	VIEWTYPE_8 = 'mail_header_bg_8',
	VIEWTYPE_9 = 'mail_header_bg_9',
	VIEWTYPE_10 = 'mail_header_bg_10',
	VIEWTYPE_11 = 'mail_header_bg_11',
	VIEWTYPE_12 = 'mail_header_bg_12',
	VIEWTYPE_13 = 'mail_header_bg_13',
}

export class NewsHeaderContentView extends PIXI.Container {
	protected readonly dateLabel: PIXI.extras.BitmapText;

	private readonly optionalBg: PIXI.Sprite;
	private readonly title: TextField;
	private readonly titleShadow: TextField;
	private readonly subtitle: TextField;

	constructor(
		titleText: string,
		subtitleText: string,
		bgKey: string,
		private readonly linkUrl: string,
	) {
		super();

		const outline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`${bgKey}_outline`], 15, 10, 15, 15);
		outline.width = 1534;
		outline.height = 228;
		outline.pivot.set(outline.width / 2, outline.height / 2);
		outline.y = 3;

		this.optionalBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[bgKey]);
		this.optionalBg.visible = true;

		this.title = new TextField(
			titleText,
			{ font: '54px wendyOneTitle', align: 'left' },
			950,
			160,
		);
		this.title.anchor = new PIXI.Point(0, 0.5);
		this.title.position.set(-721, -62);

		this.titleShadow = new TextField(
			titleText,
			{ font: '54px wendyOneTitle', align: 'left' },
			950,
			160,
		);
		this.titleShadow.anchor = new PIXI.Point(0, 0.5);
		this.titleShadow.alpha = 0.4;
		this.titleShadow.position.set(this.title.x, this.title.y + 3);
		this.setCurTitleStyle(bgKey);

		this.subtitle = new TextField(
			subtitleText,
			{ font: '26px wendyOneShadowBold', align: 'left' },
			950,
			160,
		);
		this.subtitle.anchor = new PIXI.Point(0, 0.5);
		this.subtitle.position.set(-718, -16);

		const discordButton = new DiscordButtonView();
		discordButton.position.set(-593, 54);
		discordButton.on('click', this.onDiscordButtonClick, this);

		this.addChild(
			this.optionalBg as PIXI.DisplayObject,
			outline,
			this.titleShadow,
			this.title,
			this.subtitle,
			discordButton,
		);
	}

	private setCurTitleStyle(curBg: string): void {
		// eslint-disable-next-line default-case
		switch (curBg) {
			case NewsHeaderViewType.VIEWTYPE_1: {
				this.title.tint = 0x8ccbff;
				this.titleShadow.tint = 0x421f70;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_2: {
				this.title.tint = 0x06d1ef;
				this.titleShadow.tint = 0x784a81;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_3: {
				this.title.font = '54px wendyOneGold';
				this.titleShadow.visible = false;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_4: {
				this.title.font = '54px wendyOneGold';
				this.titleShadow.visible = false;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_5: {
				this.title.tint = 0x06d1ef;
				this.titleShadow.tint = 0x784a81;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_6: {
				this.title.tint = 0xf396ef;
				this.titleShadow.tint = 0x4b063b;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_7: {
				this.title.font = '54px wendyOneGold';
				this.titleShadow.visible = false;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_8: {
				this.title.tint = 0x0f30a3;
				this.titleShadow.tint = 0xa8e5f9;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_9: {
				this.title.font = '54px wendyOneGold';
				this.titleShadow.visible = false;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_10: {
				this.title.tint = 0x91d0ff;
				this.titleShadow.tint = 0x28144f;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_11: {
				this.title.tint = 0xfef880;
				this.titleShadow.tint = 0x391603;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_12: {
				this.title.tint = 0xfff881;
				this.titleShadow.tint = 0x2f1124;
				break;
			}
			case NewsHeaderViewType.VIEWTYPE_13: {
				this.title.tint = 0xfdf1bb;
				this.titleShadow.tint = 0x3a1e33;
				break;
			}
		}
	}

	public setTitleText(text: string): void {
		this.title.text = text;
		this.titleShadow.text = text;
	}

	public setSubtitleText(text: string): void {
		this.subtitle.text = text;
	}

	private onDiscordButtonClick(): void {
		window.open(this.linkUrl, '_blank');
	}
}
