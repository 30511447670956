import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110010View } from '@views/characters/events/event11/EventCharacter110010View';
import { EventCharacter110011View } from '@views/characters/events/event11/EventCharacter110011View';
import { EventCharacter110012View } from '@views/characters/events/event11/EventCharacter110012View';

export class EventBusiness110004View extends BusinessBaseView {
	constructor() {
		const eventCharacter110010View = new EventCharacter110010View();
		const eventCharacter110011View = new EventCharacter110011View();
		const eventCharacter110012View = new EventCharacter110012View();

		super('business110004', [eventCharacter110010View, eventCharacter110011View, eventCharacter110012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter110010View as PIXI.DisplayObject,
			eventCharacter110011View,
			eventCharacter110012View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
