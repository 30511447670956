import { BaseAction } from '../BaseAction';
import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class BuyInternalLotAction extends BaseAction {
	constructor(lotId: string, resource: BankPriceResourceTypes, amount: string, rewards: RewardDescriptionType[]) {
		super('BuyInternalLot');

		this.data.lot_string_id = lotId;
		this.data.resources_spent = {
			[resource]: amount,
		};
		this.data.rewards = rewards;
	}
}
