import { TimeTravel } from '@src/types/TimeTravelTypes';
import { LevelConfig } from '@configs/level/LevelConfig';
import { LevelStartBaseTime } from '@src/types/LevelStartBaseTimeTypes';
import { LevelBaseModel } from './LevelBaseModel';

export class LevelModel extends LevelBaseModel {
	private currentLevel: number;
	private initialLevel: boolean;
	private currentProgress: number;
	private totalProgress: number;
	private currentSeason: number;
	private farewellPartyId: string;
	private timeTravel: TimeTravel;
	private baseTime: LevelStartBaseTime;
	private challengeTimes: number[];
	private key: string;

	public init(
		level: number,
		progress: number,
		levelConfig: LevelConfig,
	): void {
		this.currentLevel = level;
		this.currentProgress = progress;
		this.initialLevel = true;

		this.setFromConfig(levelConfig);
	}

	public changeLevel(
		level: number,
		levelConfig: LevelConfig,
		progress: number = 0,
	): void {
		this.currentLevel = level;
		this.currentProgress = progress;
		this.initialLevel = false;

		this.setFromConfig(levelConfig);
		this.emit(LevelModel.EVENT_LEVEL_CHANGE);
	}

	protected setFromConfig(levelConfig: LevelConfig): void {
		super.setFromConfig(levelConfig);

		this.currentSeason = levelConfig.getSeason();
		this.totalProgress = levelConfig.getQuestCount();
		this.farewellPartyId = levelConfig.getFarewellPartyId();
		this.timeTravel = levelConfig.getTimeTravel();
		this.baseTime = levelConfig.getBaseTime();
		this.challengeTimes = levelConfig.getChallengeTimes();
		this.key = levelConfig.getKey();
	}

	public updateProgress(): void {
		this.currentProgress += 1;

		if (this.isProgressCompleted()) {
			this.emit(LevelModel.EVENT_PROGRESS_COMPLETE, this);
		}

		this.emit(LevelModel.EVENT_PROGRESS);
	}

	public getKey(): string {
		return this.key;
	}

	public isProgressCompleted(): boolean {
		return this.currentProgress >= this.totalProgress;
	}

	public getFarewellPartyId(): string {
		return this.farewellPartyId;
	}

	public getTimeTravel(): TimeTravel {
		return this.timeTravel;
	}

	public isInitialLevel(): boolean {
		return this.initialLevel;
	}

	public getCurrentSeason(): number {
		return this.currentSeason;
	}

	public getCurrentLevel(): number {
		return this.currentLevel;
	}

	public getCurrentProgress(): number {
		return this.currentProgress;
	}

	public getTotalProgress(): number {
		return this.totalProgress;
	}

	public hasChallenges(): boolean {
		return Boolean(this.challengeTimes);
	}

	public getChallengeTimes(): number[] {
		return this.challengeTimes;
	}

	public getBaseTime(): LevelStartBaseTime {
		return this.baseTime;
	}
}
