import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { SkillModel } from '@models/skills/SkillModel';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';

export class QuickPhraseActivateSkillGenerator extends QuickPhaseGeneratorBase {
	constructor(config: CharacterQuickPhraseConstants, skills: SkillModel[]) {
		super(CharacterQuickPhraseTypes.ACTIVATE_SKILL, config);
		skills.forEach(skill => skill.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this));
	}

	private onSkillActivated(skill: SkillModel): void {
		this.tryGeneratePhrase(skill.getCharacterKey());
	}
}
