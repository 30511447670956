import { CharactersFactory } from '../CharactersFactory';
import { SkillsFactory } from '../SkillsFactory';
import { ModelInstanceDataBase, TutorialModelInstanceData, FarewellPartyModelInstanceData } from '@src/types/ModelInstanceDataTypes';
import CharacterConfig from '@configs/CharacterConfig';
import {
	CharacterSaveData,
	SkillSaveData,
	BusinessSaveData,
	BoostSaveData,
	UpgradeSaveData,
	TimeskipSaveData,
	TotemSaveData,
	FarewellBonusSaveData,
	BankSaveData,
} from '@src/types/SaveTypes';
import { SkillConfig } from '@configs/SkillConfig';
import { BoostsFactory } from '../BoostsFactory';
import { UpgradesFactory } from '../UpgradesFactory';
import { BusinessesFactory } from '../BusinessesFactory';
import { TimeskipsFactory } from '../TimeskipsFactory';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { BusinessConfig } from '@configs/BusinessConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { NewCustomersMultiplierFactory } from '../NewCustomersMultiplierFactory';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';
import { RewardFactory } from '../RewardFactory';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { BoostModel } from '@models/BoostModel';
import { LootboxFactory } from '../LootboxFactory';
import { GalleryVideosFactory } from '../GalleryVideosFactory';
import { VideoConfig } from '@configs/VideoConfig';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { EpicQuestsCollectionsFactory } from '../EpicQuestCollectionsFactory';
import { BusinessModel } from '@models/BusinessModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { BankModel } from '@models/bank/BankModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { DialogsFactory } from '../DialogsFactory';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { TotemConfig } from '@configs/TotemConfig';
import { TotemsFactory } from '../TotemsFactory';
import { FarewellPartiesFactory } from '../FarewellPartiesFactory';
import { QuestFactory } from '../QuestFactory';
import { TutorialStepBaseConfig } from '@configs/tutorialSteps/TutorialStepBaseConfig';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { MaintenanceModel } from '@models/MaintenanceModel';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export class ModelsBaseFactory {
	private readonly charactersFactory: CharactersFactory;
	private readonly skillsFactory: SkillsFactory;
	private readonly upgradesFactory: UpgradesFactory;
	private readonly businessesFactory: BusinessesFactory;
	private readonly boostsFactory: BoostsFactory;
	private readonly timeskipsFactory: TimeskipsFactory;
	private readonly newCustomerMultipliersFactory: NewCustomersMultiplierFactory;
	private readonly dialogsFactory: DialogsFactory;
	private readonly galleryVideosFactory?: GalleryVideosFactory;
	private readonly epicQuestCollectionsFactory?: EpicQuestsCollectionsFactory;
	private readonly totemsFactory?: TotemsFactory;
	private readonly farewellPartiesFactory?: FarewellPartiesFactory;
	private readonly tutorialStepsEmitterEnabled?: boolean;

	constructor(
		protected readonly serverTime: ServerTimeModel,
		private readonly characterModelInstanceData: ModelInstanceDataBase<CharacterConfig, CharacterSaveData>,
		private readonly skillModelInstanceData: ModelInstanceDataBase<SkillConfig, SkillSaveData>,
		private readonly upgradeModelInstanceData: ModelInstanceDataBase<UpgradeConfig, UpgradeSaveData>,
		private readonly businessModelInstanceData: ModelInstanceDataBase<BusinessConfig, BusinessSaveData>,
		private readonly boostModelInstanceData: ModelInstanceDataBase<BoostConfig, BoostSaveData>,
		private readonly timeskipModelInstanceData: ModelInstanceDataBase<TimeskipConfig, TimeskipSaveData>,
		protected readonly tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepBaseConfig>,
		private readonly promotePatternsConfig: AbstractPromotePatternsConfig,
		private readonly bankPriceItemsConfig: BankPriceItemsConfig,
		protected readonly constantsConfig: ConstantsConfig,
		private readonly newCustomerMultiplierRewards: LootboxNewCustomerMultiplierConfig[],
		private readonly dialogConfigs: Map<string, DialogConfig>,
		private readonly farewellBoostSaveData: FarewellBonusSaveData | null,
		private readonly bankSaveData: BankSaveData,
		isNutaku: boolean,
		private readonly galleryVideoModelInstanceData?: ModelInstanceDataBase<VideoConfig, string>,
		private readonly epicQuestCollectionsModelInstanceData?: ModelInstanceDataBase<EpicQuestCollectionsConfig, number[]>,
		private readonly totemModelInstanceData?: ModelInstanceDataBase<TotemConfig, TotemSaveData>,
		private readonly farewellPartyModelInstanceData?: FarewellPartyModelInstanceData,
		private readonly nextFarewellPartyKey?: string,
	) {
		this.tutorialStepsEmitterEnabled = tutorialStepModelInstanceData.emitterEnabled;

		this.charactersFactory = new CharactersFactory(
			characterModelInstanceData.configs,
			promotePatternsConfig,
			characterModelInstanceData.savesData,
			businessModelInstanceData.configs,
		);

		this.skillsFactory = new SkillsFactory(
			skillModelInstanceData.configs,
			characterModelInstanceData.configs,
			characterModelInstanceData.savesData,
			skillModelInstanceData.savesData,
			serverTime,
		);

		this.upgradesFactory = new UpgradesFactory(
			upgradeModelInstanceData.configs,
			promotePatternsConfig,
			upgradeModelInstanceData.savesData,
			businessModelInstanceData.configs,
			businessModelInstanceData.savesData,
		);

		this.businessesFactory = new BusinessesFactory(
			businessModelInstanceData.configs,
			businessModelInstanceData.savesData,
		);

		this.boostsFactory = new BoostsFactory(
			boostModelInstanceData.configs,
			boostModelInstanceData.savesData,
			farewellBoostSaveData,
			serverTime,
		);

		this.timeskipsFactory = new TimeskipsFactory(
			timeskipModelInstanceData.configs,
			timeskipModelInstanceData.savesData,
		);

		this.newCustomerMultipliersFactory = new NewCustomersMultiplierFactory(
			newCustomerMultiplierRewards,
			Array.from(businessModelInstanceData.configs.values()).map(config => config.getKey()),
			businessModelInstanceData.savesData,
		);

		if (galleryVideoModelInstanceData) {
			this.galleryVideosFactory = new GalleryVideosFactory(
				galleryVideoModelInstanceData.configs,
				galleryVideoModelInstanceData.savesData,
				isNutaku,
			);
		}

		if (epicQuestCollectionsModelInstanceData) {
			this.epicQuestCollectionsFactory = new EpicQuestsCollectionsFactory(
				epicQuestCollectionsModelInstanceData.configs,
				epicQuestCollectionsModelInstanceData.savesData,
			);
		}

		if (totemModelInstanceData) {
			this.totemsFactory = new TotemsFactory(
				totemModelInstanceData.configs,
				promotePatternsConfig,
				totemModelInstanceData.savesData,
			);
		}

		if (farewellPartyModelInstanceData) {
			this.farewellPartiesFactory = new FarewellPartiesFactory(
				farewellPartyModelInstanceData.configs,
				farewellPartyModelInstanceData.constants,
			);
		}

		this.dialogsFactory = new DialogsFactory(this.dialogConfigs);
	}

	// eslint-disable-next-line class-methods-use-this
	public createSoftMoneyModel(): SoftMoneyModel {
		return new SoftMoneyModel();
	}

	// eslint-disable-next-line class-methods-use-this
	public createPrestigeMoneyModel(): PrestigeMoneyModel {
		return new PrestigeMoneyModel();
	}

	// eslint-disable-next-line class-methods-use-this
	public createHardMoneyModel(): HardMoneyModel {
		return new HardMoneyModel();
	}

	public createMaintenanceModel(): MaintenanceModel {
		return new MaintenanceModel(
			this.constantsConfig.getMaintenanceShowTime(),
		);
	}

	public createBackToGameRewardModel(boostModels: Map<string, BoostModel>): BackToGameRewardModel {
		return new BackToGameRewardModel(
			this.constantsConfig.getMinOfflineTime(),
			this.constantsConfig.getMaxOfflineTime(),
			this.constantsConfig.getOfflineRewardMultiplier(),
			boostModels,
			this.serverTime.getCalculatedISOTime(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createPrepartyStartCharacterModel(farewellModels: Map<string, FarewellPartyModel>): PrepartyStartCharacterModel {
		return new PrepartyStartCharacterModel(Array.from(farewellModels.keys()), this.nextFarewellPartyKey);
	}

	// eslint-disable-next-line class-methods-use-this
	public createQuestFactory(
		businessModels: Map<string, BusinessModel>,
		upgradeModels: Map<string, UpgradeModel>,
		characterModels: Map<string, CharacterModel>,
		totemModels: Map<string, TotemModel>,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		summonModelsPool: SummonModelsPool,
		farewellPartyModels: Map<string, FarewellPartyModel>,
	): QuestFactory {
		return new QuestFactory(
			businessModels,
			upgradeModels,
			characterModels,
			totemModels,
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			summonModelsPool,
			farewellPartyModels,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createRewardFactory(
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		skillModels: Map<string, SkillModel>,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		timeskipModels: Map<string, TimeskipModel>,
		boostModels: Map<string, BoostModel>,
	): RewardFactory {
		return new RewardFactory(
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			characterModels,
			upgradeModels,
			totemModels,
			skillModels,
			galleryVideoModels,
			timeskipModels,
			boostModels,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createLootboxFactory(rewardFactory: RewardFactory): LootboxFactory {
		return new LootboxFactory(rewardFactory);
	}

	// eslint-disable-next-line class-methods-use-this
	public createQuestLinesModel(): QuestLinesModel {
		return new QuestLinesModel();
	}

	// eslint-disable-next-line class-methods-use-this
	public createTimedLinesModel(): TimedQuestLinesModel {
		return new TimedQuestLinesModel();
	}

	public createBankModel(): BankModel {
		return new BankModel(
			this.bankPriceItemsConfig,
		);
	}

	public createBankSavesModel(): BankSavesModel {
		return new BankSavesModel(
			this.bankSaveData,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createLevelChallengeModel(): LevelChallengeModel {
		return new LevelChallengeModel();
	}

	// eslint-disable-next-line class-methods-use-this
	public createSummonModelsPool(): SummonModelsPool {
		return new SummonModelsPool();
	}

	public createCharacterQuickPhraseEmitter(
		skillModels: Map<string, SkillModel>,
		characterModels: Map<string, CharacterModel>,
		businessModels: Map<string, BusinessModel>,
	): CharacterQuickPhraseEmitter {
		return new CharacterQuickPhraseEmitter(
			this.constantsConfig.getCharacterQuickPhraseConstants(),
			Array.from(skillModels.values()),
			Array.from(characterModels.values()),
			businessModels,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createTutorialStepsEmitter(
		tutorialStepModels: Map<string, TutorialStepBaseModel>,
	): TutorialStepsEmitter {
		return new TutorialStepsEmitter(
			tutorialStepModels,
			this.tutorialStepsEmitterEnabled,
		);
	}

	public getCharactersFactory(): CharactersFactory {
		return this.charactersFactory;
	}

	public getSkillsFactory(): SkillsFactory {
		return this.skillsFactory;
	}

	public getUpgradesFactory(): UpgradesFactory {
		return this.upgradesFactory;
	}

	public getBusinessesFactory(): BusinessesFactory {
		return this.businessesFactory;
	}

	public getBoostsFactory(): BoostsFactory {
		return this.boostsFactory;
	}

	public getTimeskipsFactory(): TimeskipsFactory {
		return this.timeskipsFactory;
	}

	public getNewCustomerMultipliersFactory(): NewCustomersMultiplierFactory {
		return this.newCustomerMultipliersFactory;
	}

	public getGalleryVideosFactory(): GalleryVideosFactory | undefined {
		return this.galleryVideosFactory;
	}

	public getEpicQuestCollectionsFactory(): EpicQuestsCollectionsFactory | undefined {
		return this.epicQuestCollectionsFactory;
	}

	public getTotemsFactory(): TotemsFactory | undefined {
		return this.totemsFactory;
	}

	public getFarewellPartiesFactory(): FarewellPartiesFactory | undefined {
		return this.farewellPartiesFactory;
	}

	public getDialogsFactory(): DialogsFactory {
		return this.dialogsFactory;
	}
}
