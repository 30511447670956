import { AbstractPromotePatternsConfig, PromoteDataElement } from '@configs/AbstractPromotePatternsConfig';

export class PromotePatternsConfig extends AbstractPromotePatternsConfig {
	constructor(config: Record<string, string>[]) {
		super();

		this.parseConfig(config);
	}

	// eslint-disable-next-line class-methods-use-this
	protected parseElement(configElement: Record<string, string>): PromoteDataElement {
		const level = Number(configElement[AbstractPromotePatternsConfig.FIELD_LEVEL].trim());
		const rarity = configElement[AbstractPromotePatternsConfig.FIELD_RARITY].trim();
		const promoteCostCards = Number(configElement[AbstractPromotePatternsConfig.FIELD_PROMOTE_COST_CARDS].trim());
		const promoteCostPrestige = Number(configElement[AbstractPromotePatternsConfig.FIELD_PROMOTE_COST_PRESTIGE].trim());

		return {
			promoteCostCards,
			promoteCostPrestige,
			rarity,
			level,
		};
	}
}
