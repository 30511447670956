import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { NumberUtils } from '@src/utils/NumberUtils';
import { BoostModel } from '@models/BoostModel';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { Emitter } from 'pixi-particles';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import * as TWEEN from '@tweenjs/tween.js';
import { BaseBoostModel } from '@models/BaseBoostModel';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';

export enum GameUIBoostViewState {
	DEFAULT,
	ACTIVE,
}

export class GameUIBoostView extends ButtonBaseView {
	private readonly boostModels: BaseBoostModel[];
	private readonly localizationStorage: LocalizationStorage;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly textTitle: PIXI.extras.BitmapText;
	private readonly textMultiplier: PIXI.extras.BitmapText;
	private readonly textTimer: SizeableBitmapText;

	private readonly progressBarMask: PIXI.Graphics;

	private readonly stateDefaultContainer: PIXI.Container;
	private readonly stateActiveContainer: PIXI.Container;

	private readonly progressBarWidth: number;

	private state: GameUIBoostViewState;

	private countdownCurrentTime: number;
	private countdownTotalTime: number;

	private currentMultiplier: number;
	private currentActiveBoosts: Set<BaseBoostModel>;

	private animationEmitters: Emitter[];
	private activeBoostTween: TWEEN.Tween;
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private flyTextLabel: FlyBitmapText;

	constructor(boostModels: BaseBoostModel[]) {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_time_boost_circle']);
		super(bg);

		this.fxAtlas = fxAtlas;
		this.animationEmitters = [];

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.ticker = PIXI.ticker.shared;

		this.countdownCurrentTime = null;
		this.countdownTotalTime = null;

		this.boostModels = boostModels;
		this.boostModels.forEach(m => m.on(BaseBoostModel.EVENT_ACTIVATED, this.onSomeBoostActivated, this));
		this.boostModels.forEach(m => m.on(BaseBoostModel.EVENT_DEACTIVATED, this.onSomeBoostDeactivated, this));

		// Default state
		this.textTitle = new PIXI.extras.BitmapText('', {
			font: '34px wendyOneShadowBold',
		});
		this.textTitle.y = 64;
		this.textTitle.anchor = 0.5;

		this.stateDefaultContainer = new PIXI.Container();
		this.stateDefaultContainer.addChild(
			this.textTitle,
		);

		// Active state
		this.textMultiplier = new PIXI.extras.BitmapText('x2', {
			font: '50px wendyOneGold',
		});
		this.textMultiplier.anchor = 0.5;
		this.textMultiplier.position.set(40, 10);

		this.textTimer = new SizeableBitmapText('', 99, { font: '26px wendyOneShadowBold' });
		this.textTimer.anchor = 0.5;
		this.textTimer.position.set(66, 17);

		const progressBarBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['booster_timer_bg'], 19, 0, 19, 0);
		progressBarBg.width = 133;

		this.progressBarMask = new PIXI.Graphics()
			.beginFill(0x00FFFF)
			.drawRect(0, 0, progressBarBg.width, progressBarBg.height)
			.endFill();
		this.progressBarMask.position.set(progressBarBg.x, progressBarBg.y);

		const progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['booster_timer_bar'], 19, 0, 19, 0);
		progressBar.position.set(3, 3);
		progressBar.width = 127;
		progressBar.mask = this.progressBarMask;
		this.progressBarWidth = progressBar.width;

		const progressBarContainer = new PIXI.Container();
		progressBarContainer.position.set(-63, 40);
		progressBarContainer.addChild(
			this.progressBarMask as PIXI.DisplayObject,
			progressBarBg,
			progressBar,
			this.textTimer,
		);

		this.stateActiveContainer = new PIXI.Container();
		this.stateActiveContainer.addChild(
			progressBarContainer,
			this.textMultiplier,
		);

		this.addChild(
			this.stateActiveContainer,
			this.stateDefaultContainer,
		);

		this.updateText();

		this.init();
	}

	public showLockedFrom(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0x7F7F7F;
		this.textTitle.tint = 0x7F7F7F;

		this.off('pointertap', this.onPointerTap, this);
	}

	public showUnlocked(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0xFFFFFF;
		this.textTitle.tint = 0xFFFFFF;

		if (this.listeners('pointertap').includes(this.onPointerTap)) {
			this.off('pointertap', this.onPointerTap, this);
		}
		this.on('pointertap', this.onPointerTap, this);
	}

	private init(): void {
		this.currentActiveBoosts = new Set();
		this.boostModels.forEach((boost) => {
			if (boost.isActivated()) {
				this.currentActiveBoosts.add(boost);
			}
		});
		this.currentMultiplier = BoostModel.calculateMultiplicator(this.currentActiveBoosts);

		if (this.currentActiveBoosts.size > 0) {
			this.setState(GameUIBoostViewState.ACTIVE);
			this.startCountdown();
			this.updateTextMultiplier();
		} else {
			this.setState(GameUIBoostViewState.DEFAULT);
		}
	}

	private updateText(): void {
		this.textTitle.text = this.localizationStorage.getLocalizedString('#GameUI_BoostsButton');
	}

	public setState(state: GameUIBoostViewState): void {
		switch (state) {
			case GameUIBoostViewState.DEFAULT: {
				this.stateDefaultContainer.visible = true;
				this.stateActiveContainer.visible = false;
				this.stopAnimation();
				break;
			}
			case GameUIBoostViewState.ACTIVE: {
				this.stateDefaultContainer.visible = false;
				this.stateActiveContainer.visible = true;
				this.playAnimation();
				break;
			}
			default:
				throw new Error(`Unsupported boost button state '${state}'`);
		}

		this.state = state;
	}

	private playAnimation(): void {
		const pointSizeEmitter = new Emitter(
			this,
			[this.fxAtlas['skill_activ_glow1']],
			SkillParticleConfig.getPointSize1(),
		);
		pointSizeEmitter.autoUpdate = true;

		this.animationEmitters.push(pointSizeEmitter);

		this.activeBoostTween = new TWEEN.Tween(this.textMultiplier.scale)
			.to({ x: 1.1, y: 1.1 }, 750)
			.easing(TWEEN.Easing.Quadratic.In)
			.yoyo(true)
			.repeat(Infinity)
			.start();
	}

	private stopAnimation(): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.animationEmitters = [];

		if (this.activeBoostTween) {
			this.activeBoostTween.stop();
			this.activeBoostTween = null;
		}
	}

	public showLockedFlyText(): void {
		if (!this.flyTextLabel) {
			const text = this.localizationStorage.getLocalizedString('#fly_text_boost_locked');
			this.flyTextLabel = new FlyBitmapText(text, new PIXI.Point(0.5, 0.5), { font: '28px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.y = -20;
			this.addChild(this.flyTextLabel);
		}
	}

	public setTextTimer(value: number): void {
		this.textTimer.text = NumberUtils.secToDHMS(value);
	}

	public setTimerProgressBar(current: number, total: number): void {
		this.progressBarMask.width = this.progressBarWidth * current / total;
	}

	public updateTextMultiplier(): void {
		this.textMultiplier.text = `x${this.currentMultiplier}`;
	}

	public getCurrentState(): GameUIBoostViewState {
		return this.state;
	}

	private startCountdown(): void {
		if (this.countdownCurrentTime !== null) {
			this.stopCountdown();
		}

		this.updateCurrentCountdownTime();
		const boostSmallestTime = this.getBoostSmallestTime();
		this.countdownTotalTime = boostSmallestTime.getTimeleft();

		this.ticker.add(this.onCountdownUpdate, this);
	}

	private getBoostSmallestTime(): BaseBoostModel {
		let result: BaseBoostModel;
		let resultTime = Infinity;
		this.currentActiveBoosts.forEach((boost) => {
			if (resultTime > boost.getTimeleft()) {
				resultTime = boost.getTimeleft();
				result = boost;
			}
		});
		return result;
	}

	private stopCountdown(): void {
		this.ticker.remove(this.onCountdownUpdate, this);
		this.countdownCurrentTime = null;
	}

	private onCountdownUpdate(): void {
		this.updateCurrentCountdownTime();

		if (this.countdownCurrentTime <= 0) {
			this.stopCountdown();
		} else {
			this.setTextTimer(Math.ceil(this.countdownCurrentTime));
			this.setTimerProgressBar(this.countdownCurrentTime, this.countdownTotalTime);
		}
	}

	private updateCurrentCountdownTime(): void {
		const boostSmallestTime = this.getBoostSmallestTime();
		this.countdownCurrentTime = boostSmallestTime.getTimeleft();
	}

	private onSomeBoostActivated(boost: BoostModel): void {
		if (!this.currentActiveBoosts.has(boost)) {
			this.currentActiveBoosts.add(boost);
			this.currentMultiplier = BaseBoostModel.calculateMultiplicator(this.currentActiveBoosts);
		}

		this.startCountdown();

		if (this.state !== GameUIBoostViewState.ACTIVE) {
			this.setState(GameUIBoostViewState.ACTIVE);
		}
		this.updateTextMultiplier();
	}

	private onSomeBoostDeactivated(boost: BoostModel): void {
		this.currentActiveBoosts.delete(boost);
		this.currentMultiplier = BaseBoostModel.calculateMultiplicator(this.currentActiveBoosts);

		if (this.currentActiveBoosts.size > 0) {
			this.updateTextMultiplier();
			this.startCountdown();
		} else {
			this.stopCountdown();
			this.setState(GameUIBoostViewState.DEFAULT);
		}
	}

	public destroy(): void {
		if (this.countdownCurrentTime !== null) {
			this.stopCountdown();
		}

		this.boostModels.forEach(m => m.off(BaseBoostModel.EVENT_ACTIVATED, this.onSomeBoostActivated, this));
		this.boostModels.forEach(m => m.off(BaseBoostModel.EVENT_DEACTIVATED, this.onSomeBoostDeactivated, this));

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.stopAnimation();
		super.destroy();
	}
}
