import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { CardRarities } from '@src/types/CardRarities';
import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableBaseCardView, PromotableClickData } from './PromotableBaseCardView';
import { Emitter } from 'pixi-particles';
import { CharacterCardAnimationConfig } from './CharacterCardAnimationConfig';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import * as TWEEN from '@tweenjs/tween.js';

export class CharacterCardView extends PromotableBaseCardView<CharacterModel> {
	private readonly skillModel: SkillModel;

	private readonly characterPicture: PIXI.Sprite;
	private readonly characterNewPicture: PIXI.Sprite;
	private readonly characterCardAtlas: PIXI.loaders.TextureDictionary;
	private readonly collectionsAtlas: PIXI.loaders.TextureDictionary;

	private availablePictures: number;

	private fxAtlas: PIXI.loaders.TextureDictionary;
	private animationEmitters: Emitter[];

	private ticker: PIXI.ticker.Ticker;

	private nudeLockLabel: SizeableMultiColoredBitmapText;
	private stagesBg: PIXI.Graphics;
	private alphaPic: TWEEN.Tween;

	constructor(
		model: CharacterModel,
		skillModel?: SkillModel,
		autoUpdateProgressBar?: boolean,
	) {
		super(
			model,
			autoUpdateProgressBar,
		);

		this.ticker = PIXI.ticker.shared;
		this.skillModel = skillModel;
		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		this.characterCardAtlas = AssetsStorage.getAtlas('characterCardAtlas');
		this.collectionsAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		this.availablePictures = 0;

		this.model.on(CharacterModel.EVENT_UPDATE_PICTURE, this.onUpdatePicture, this);

		while (this.characterCardAtlas[`${this.model.getKey()}_card${this.availablePictures + 1}`]) {
			this.availablePictures += 1;
		}

		const cardRarity = model.getCardRarity();
		const rarityColor = CardRarities.getRarityColor(cardRarity);

		const characterPictureMask = new PIXI.Graphics();
		characterPictureMask.beginFill(0x000000);
		characterPictureMask.drawPolygon([
			new PIXI.Point(250, -368),
			new PIXI.Point(250, 368),
			new PIXI.Point(-250, 368),
			new PIXI.Point(-250, -368),
		]);
		characterPictureMask.y = -42;

		this.characterPicture = new PIXI.Sprite();
		this.characterPicture.anchor.set(0.5);
		this.characterPicture.y = characterPictureMask.y;
		this.characterPicture.mask = characterPictureMask;
		this.characterNewPicture = new PIXI.Sprite();
		this.characterNewPicture.anchor.set(0.5);
		this.characterNewPicture.y = characterPictureMask.y;
		this.characterNewPicture.mask = characterPictureMask;

		this.setPicture(this.model.getCurrentPictureId());

		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${cardRarity}_bg_round`], 3, 3, 3, 3);
		background.width = 540;
		background.height = 780;
		background.pivot.set(background.width / 2, background.height / 2);
		background.position.set(0, characterPictureMask.y);

		const blackOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		blackOutline.width = 543;
		blackOutline.height = 783;
		blackOutline.pivot.set(blackOutline.width / 2, blackOutline.height / 2);
		blackOutline.tint = 0x00000;
		blackOutline.position.set(0, characterPictureMask.y);

		this.nudeLockLabel = new SizeableMultiColoredBitmapText(480, { font: '40px wendyOneShadowBold', tint: 0x9da6b9 });
		this.nudeLockLabel.anchor = 0.5;
		this.nudeLockLabel.y = 272;

		this.stagesBg = new PIXI.Graphics();
		this.stagesBg.beginFill(rarityColor);
		this.stagesBg.drawPolygon([
			new PIXI.Point(268, -15),
			new PIXI.Point(268, 15),
			new PIXI.Point(-268, 15),
			new PIXI.Point(-268, -15),
		]);
		this.stagesBg.y = 330;
		this.stagesBg.visible = false;

		this.addChildAt(this.stagesBg, 0);
		this.addChildAt(characterPictureMask, 0);
		this.addChildAt(this.characterNewPicture, 0);
		this.addChildAt(this.characterPicture, 0);
		this.addChildAt(background, 0);
		this.addChildAt(blackOutline, 0);

		this.progressBar.y = 389;
	}

	private updateAnimationEmitters(): void {
		this.animationEmitters.forEach(emitter => emitter.update(this.ticker.deltaTime));
	}

	private onUpdatePicture(): void {
		this.setPicture(this.model.getCurrentPictureId());
	}

	private tryDisableUpdateAnimationEmitters(): void {
		if (this.animationEmitters.length <= 0) {
			this.ticker.remove(this.updateAnimationEmitters, this);
		}
	}

	public playPromoteAnimation(): void {
		this.ticker.add(this.updateAnimationEmitters, this);

		const appearanceEmitter = new Emitter(
			this,
			[this.fxAtlas['business_apperance']],
			CharacterCardAnimationConfig.getAppearance(),
		);
		this.animationEmitters.push(appearanceEmitter);

		appearanceEmitter.playOnce(() => {
			this.animationEmitters.splice(this.animationEmitters.indexOf(appearanceEmitter), 1);
			this.tryDisableUpdateAnimationEmitters();
		});

		const appearance2Emitter = new Emitter(
			this,
			[this.fxAtlas['business_glow4']],
			CharacterCardAnimationConfig.getAppearance2(),
		);
		this.animationEmitters.push(appearance2Emitter);

		appearance2Emitter.playOnce(() => {
			this.animationEmitters.splice(this.animationEmitters.indexOf(appearance2Emitter), 1);
			this.tryDisableUpdateAnimationEmitters();
		});
	}

	protected getOnClickData(): PromotableClickData {
		return {
			model: this.model.getKey(),
			skill: this.skillModel?.getKey(),
		};
	}

	public updateView(value: boolean): void {
		this.stagesBg.visible = value;
	}

	public setPicture(pictureId: number): void {
		if (pictureId <= this.model.getMaxPictureIndex()) {
			this.characterNewPicture.alpha = 1;

			const currentPictureId: number = pictureId > this.availablePictures ? this.availablePictures : pictureId;
			const availablePicture = this.characterCardAtlas[`${this.model.getKey()}_card${currentPictureId}`];
			this.characterPicture.texture = availablePicture;
			this.characterPicture.setTransform(0, -42, 1, 1);
			this.removeChild(this.nudeLockLabel);

			this.alphaPic = new TWEEN.Tween(this.characterNewPicture)
				.to({ alpha: 0 }, 100)
				.onComplete(() => {
					this.characterNewPicture.texture = availablePicture;
					this.characterNewPicture.setTransform(0, -42, 1, 1);
				})
				.start();
		} else {
			this.characterNewPicture.alpha = 1;

			const availablePicture = this.collectionsAtlas['lock_bg_character_card2'];
			this.characterPicture.texture = availablePicture;
			this.characterPicture.setTransform(0, -44, 3, 3);
			const nudeLevel = this.model.getNudeLevel(pictureId);
			const localNudeString = this.localizationStorage.getLocalizedString('#get_nude_level')
				.replace('{value}', nudeLevel.toString());
			this.nudeLockLabel.text = `[lock]${localNudeString}`;
			this.addChild(this.nudeLockLabel);

			this.alphaPic = new TWEEN.Tween(this.characterNewPicture)
				.to({ alpha: 0 }, 100)
				.onComplete(() => {
					this.characterNewPicture.texture = availablePicture;
					this.characterNewPicture.setTransform(0, -44, 3, 3);
				})
				.start();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.alphaPic.stop();
		this.tryDisableUpdateAnimationEmitters();
		this.model.off(CharacterModel.EVENT_UPDATE_PICTURE, this.onUpdatePicture, this);
		this.animationEmitters.forEach(emitter => emitter.destroy());
		super.destroy(options);
	}
}
