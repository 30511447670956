import { SummonModel } from '@models/SummonModel';

export class SummonModelsPool extends PIXI.utils.EventEmitter {
	public static EVENT_MODELS_UPDATED: symbol = Symbol();

	private models: Map<string, SummonModel>;

	constructor() {
		super();
		this.models = new Map<string, SummonModel>();
	}

	public setModels(models: Map<string, SummonModel>): void {
		this.models = models;
		this.emit(SummonModelsPool.EVENT_MODELS_UPDATED, this.models);
	}

	public getModelById(key: string): SummonModel {
		return this.models.get(key);
	}

	public getModelsList(): SummonModel[] {
		return Array.from(this.models.values());
	}

	public isEmpty(): boolean {
		return this.models.size === 0;
	}
}
