export enum CardRarity {
	COMMON = 'common',
	RARE = 'rare',
	EPIC = 'epic',
	LEGENDARY = 'legendary',
}

export class CardRarities {
	public static readonly RARITIES: CardRarity[] = [
		CardRarity.COMMON,
		CardRarity.RARE,
		CardRarity.EPIC,
		CardRarity.LEGENDARY,
	];

	public static getRarityColor(rarity: string): number {
		switch (rarity) {
			case CardRarity.COMMON:
				return 0xE9DAC1;
			case CardRarity.RARE:
				return 0x32ABFE;
			case CardRarity.EPIC:
				return 0xE84FFF;
			case CardRarity.LEGENDARY:
				return 0xFFB400;
			default:
				if (MODE_DEBUG) {
					throw new Error(`Unsupported rarity: ${rarity}`);
				}
				return 0xFFFFFF;
		}
	}

	public static getRarityPriority(rarity: string): number {
		switch (rarity) {
			case CardRarity.COMMON:
				return 1;
			case CardRarity.RARE:
				return 2;
			case CardRarity.EPIC:
				return 3;
			case CardRarity.LEGENDARY:
				return 4;
			default:
				if (MODE_DEBUG) {
					throw new Error(`Unsupported rarity: ${rarity}`);
				}
				return 0;
		}
	}
}
