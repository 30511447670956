import { AssetsStorage } from '@main/AssetsStorage';

export class LevelStartCardBackBaseView extends PIXI.Container {
	constructor(
		spriteName: string,
		scale: number = 1,
		maskHeightMultiplicator: number = 0.9,
	) {
		super();

		const backSprite = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[spriteName]);
		backSprite.scale.set(scale);

		const mask = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(-backSprite.width / 2,
				-backSprite.height / 2,
				backSprite.width,
				backSprite.height * maskHeightMultiplicator)
			.closePath()
			.endFill();

		this.addChild(mask);
		this.mask = mask;

		this.addChild(backSprite);
	}
}
