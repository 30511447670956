import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class IconEntityView extends ButtonBaseView {
	private characterIconAtlas: PIXI.loaders.TextureDictionary;
	private upgradeIconAtlas: PIXI.loaders.TextureDictionary;

	private iconEntity: PIXI.Sprite;

	private ticker: PIXI.ticker.Ticker;

	private tweenGroup: TWEEN.Group;

	private infoIcon: PIXI.Sprite;
	private highlight: PIXI.Sprite;

	private currentIconKey: string;
	private currentBg: string;

	private bg: PIXI.Sprite;

	private isAnimationPlaying: boolean;

	constructor() {
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_business_slot_common']);

		const mask = new PIXI.Graphics()
			.beginFill(0x000000, 1)
			.drawCircle(0, 0, 38)
			.endFill();

		super(bg);

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.characterIconAtlas = AssetsStorage.getAtlas('characterIconAtlas');
		this.upgradeIconAtlas = AssetsStorage.getAtlas('upgradeIconAtlas');

		this.bg = new PIXI.Sprite();
		this.bg.anchor.set(0.5);

		this.iconEntity = new PIXI.Sprite();
		this.iconEntity.anchor.set(0.5);
		this.iconEntity.mask = mask;

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.setTransform(32, -32, 0.65, 0.65);
		this.infoIcon.visible = false;

		this.highlight = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['ui_blick_strip']);
		this.highlight.position.set(-90, 0);
		this.highlight.mask = mask;

		this.addChild(
			this.bg,
			mask as PIXI.DisplayObject,
			this.iconEntity,
			this.highlight,
			this.infoIcon,
		);
	}

	public show(withAnimation: boolean): void {
		this.visible = true;
		if (withAnimation) {
			if (!this.isAnimationPlaying) {
				this.playAnimation();
			}
		} else {
			this.isAnimationPlaying = false;
			this.infoIcon.visible = false;
			this.highlight.visible = false;
			this.tweenGroup.removeAll();
		}
	}

	private playAnimation(): void {
		this.highlight.visible = true;
		this.infoIcon.visible = true;
		this.showInfoIcon();
		this.isAnimationPlaying = true;
		this.highlight.x = -90;

		new TWEEN.Tween(this.highlight, this.tweenGroup)
			.to({ x: 90 }, 350)
			.easing(TWEEN.Easing.Quadratic.In)
			.repeat(Infinity)
			.repeatDelay(3000)
			.start();

		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1.05, y: 1.05 }, 500)
			.easing(TWEEN.Easing.Quadratic.In)
			.repeat(Infinity)
			.yoyo(true)
			.start();
	}

	private showInfoIcon(): void {
		this.infoIcon.scale.set(0);
		const scaleUp = new TWEEN.Tween(this.infoIcon.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
		const scaleDown = new TWEEN.Tween(this.infoIcon.scale, this.tweenGroup)
			.to({ x: 0.65, y: 0.65 }, 420)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
		scaleUp.chain(scaleDown);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	protected onPointerOver(): void {
		this.ticker.remove(this.update, this);
		super.onPointerOver();
	}

	protected onPointerOut(): void {
		this.ticker.add(this.update, this);
		super.onPointerOver();
	}

	public hide(): void {
		this.currentIconKey = null;
		this.currentBg = null;
		this.isAnimationPlaying = false;
		this.tweenGroup.removeAll();
		this.visible = false;
		this.infoIcon.visible = false;
		this.highlight.visible = false;
	}

	public setCharacterIcon(key: string): void {
		this.currentIconKey = key;
		this.iconEntity.texture = this.characterIconAtlas[`${key}Icon`];
		this.iconEntity.scale.set(0.8);
	}

	public setCharacterBg(rarity: string): void {
		this.currentBg = rarity;
		this.bg.texture = (AssetsStorage.getAtlas('uiAtlas')[`u_business_slot_${rarity}`]);
	}

	public setUpgradeIcon(key: string): void {
		this.currentIconKey = key;
		this.iconEntity.texture = this.upgradeIconAtlas[`${key}`];
		this.iconEntity.scale.set(0.35);
	}

	public setUpgradeBg(rarity: string): void {
		this.currentBg = rarity;
		this.bg.texture = (AssetsStorage.getAtlas('uiAtlas')[`u_business_slot_${rarity}`]);
	}

	public destroy(): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);
		super.destroy();
	}
}
