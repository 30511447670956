import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SettingsFooterView } from './SettingsFooterView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { GameProfileModel } from '@models/GameProfileModel';
import { NutakuSettingsPanelView } from './NutakuSettingsPanelView';
import { BaseSettingsWindowView } from './BaseSettingsWindowView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class NutakuSettingsWindowView extends BaseSettingsWindowView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly gameProfileModel: GameProfileModel;
	private readonly thirdPartyLinks: string[];

	private readonly labelTitle: PIXI.extras.BitmapText;
	private readonly panel: NutakuSettingsPanelView;

	constructor(
		redeemCode: string,
		supportLanguageMap: Map<string, string>,
		gameProfileModel: GameProfileModel,
		thirdPartyLinks: string[],
		version: string,
	) {
		super(0.9);

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUpdateUsername, this);

		this.thirdPartyLinks = thirdPartyLinks;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const card = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['setting_card_bg1']);
		card.position.set(-375, -3);
		card.interactive = true;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 837;
		bg.height = 797;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.x = 305;
		bg.interactive = true;

		this.labelTitle = new PIXI.extras.BitmapText(
			'',
			{ font: { size: 40, name: 'wendyOneShadowBold' } },
		);
		this.labelTitle.anchor = 0.5;
		this.labelTitle.position.set(320, -345);

		const textVersion = new PIXI.extras.BitmapText(version, { font: '20px wendyOne' });
		textVersion.alpha = 0.5;
		textVersion.tint = 0x6a91aa;
		textVersion.anchor = new PIXI.Point(1, 0.5);
		textVersion.position.set(680, 420);

		const footer = new SettingsFooterView();
		footer.on(SettingsFooterView.EVENT_BUTTON_LINK_1_CLICK, () => { window.open(this.thirdPartyLinks[0]); });
		footer.position.set(-373, 353);

		this.panel = new NutakuSettingsPanelView(
			supportLanguageMap,
			redeemCode,
		);
		this.panel.position.set(309, 29);
		this.panel.on(
			NutakuSettingsPanelView.EVENT_CHANGE_USERNAME,
			(username: string) => this.emit(NutakuSettingsWindowView.EVENT_CHANGE_USERNAME, username),
			this,
		);
		this.panel.on(
			NutakuSettingsPanelView.EVENT_CHANGE_LANGUAGE,
			(lang: string) => this.emit(NutakuSettingsWindowView.EVENT_CHANGE_LANGUAGE, lang),
			this,
		);
		this.panel.on(
			NutakuSettingsPanelView.EVENT_CHANGE_SOUNDS_VOLUME,
			(value: number) => this.emit(NutakuSettingsWindowView.EVENT_CHANGE_SOUNDS_VOLUME, value),
			this,
		);
		this.panel.on(
			NutakuSettingsPanelView.EVENT_CHANGE_MUSIC_VOLUME,
			(value: number) => this.emit(NutakuSettingsWindowView.EVENT_CHANGE_MUSIC_VOLUME, value),
			this,
		);
		this.panel.on(
			NutakuSettingsPanelView.EVENT_SUPPORT_BUTTON_CLICK,
			() => this.emit(NutakuSettingsWindowView.EVENT_BUTTON_SUPPORT_CLICK),
			this,
		);

		const closeButton = new CloseButtonView();
		closeButton.position.set(672, -345);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.mainContainer.addChild(
			bg as PIXI.DisplayObject,
			card,
			this.labelTitle,
			footer,
			this.panel,
			closeButton,
			textVersion,
		);

		this.updateText();

		if (this.gameProfileModel.hasUsername()) {
			this.panel.setUsername(this.gameProfileModel.getUsername());
		}
	}

	private onUpdateUsername(): void {
		this.panel.setUsername(this.gameProfileModel.getUsername());
	}

	private updateText(): void {
		this.labelTitle.text = this.localizationStorage.getLocalizedString('#settings_window_title');
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUpdateUsername, this);
		super.destroy(options);
	}
}
