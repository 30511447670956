import { BehavioursBaseFactory } from '@src/initializers/behaviours/BehavioursBaseFactory';
import { AbstractModelInstance } from '@main/modelInstance/AbstractModelInstance';
import { BusinessesTicker } from '@src/tickers/BusinessesTicker';
import { SummonTicker } from '@src/tickers/SummonTicker';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { BusinessBaseZoomRequestHandler } from '@src/viewControllers/BusinessBaseZoomRequestHandler';
import { ShowQuestTargetHandler } from '@src/viewControllers/showQuestTarget/ShowQuestTargetHandler';
import {
	BusinessAcquiredUIViewControllerPool,
	BusinessManageUIViewControllerPool,
	BusinessOpenToBuyUIViewControllerPool,
} from '@src/viewControllers/BusinessUIViewControllerPools';
import { SkillBaseViewControllerPool } from '@src/viewControllers/SkillBaseViewControllerPool';
import { ButtonMaxCustomersViewController } from '@src/viewControllers/ButtonMaxCustomersViewController';
import { TutorialStepWindowViewController } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewController';
import { CharacterProfileWindowViewController } from '@src/viewControllers/CharacterProfileWindowViewController';
import { UpgradePromoteWindowViewController } from '@src/viewControllers/UpgradePromoteWindowViewController';
import { RewardResultWindowViewController } from '@src/viewControllers/RewardResultWindowViewController';
import { SupportWindowViewController } from '@src/viewControllers/SupportWindowViewController';
import { GameProfileViewController } from '@src/viewControllers/GameProfileViewController';
import { BaseAction } from '@models/network/actions/BaseAction';
import {
	BusinessAcquiredUIViewSetter,
	BusinessManageUIViewSetter,
	BusinessOpenToBuyUIViewSetter,
	BusinessBaseViewsSetter,
	SkillBaseViewSetter,
	QuestWindowBaseViewSetter,
	CharacterProfileWindowViewSetter,
	UpgradePromoteWindowViewSetter,
	TutorialStepWindowViewSetter,
	ButtonMaxCustomersViewSetter,
	SettingsWindowViewSetter,
	RewardResultWindowViewSetter,
	SupportWindowViewSetter,
	RegistrationWindowViewSetter,
	ViewportViewSetter,
	ViewportUIViewSetter,
	PrepartyStartCharacterViewSetter,
	FarewellPartyWindowViewSetter,
	CheatWindowViewSetter,
	BackToGameWindowViewSetter,
	CharacterCollectionViewSetter,
	GameUIBaseViewSetter,
	TotemCollectionViewSetter,
	UpgradeCollectionViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { SkillsTicker } from '@src/tickers/SkillsTicker';
import { BankTicker } from '@src/tickers/BankTicker';
import { ModelWriterCharacter } from '@src/modelWriters/ModelWriterCharacter';
import { ModelWriterUpgrade } from '@src/modelWriters/ModelWriterUpgrade';
import { ModelWriterSkill } from '@src/modelWriters/ModelWriterSkill';
import { ModelWriterBoost } from '@src/modelWriters/ModelWriterBoost';
import { BackToGameRewardLoader } from '@src/loaders/BackToGameRewardLoader';
import { PrepartyStartCharacterTicker } from '@src/tickers/PrepartyStarCharcterTicker';
import { ModelWriterFarewellBoost } from '@src/modelWriters/ModelWriterFarewellBoost';
import { FarewellPartyWindowViewController } from '@src/viewControllers/FarewellPartyWindowViewController';
import { CollectionCardsViewController } from '@src/viewControllers/CollectionCardsViewController';
import { BackToGameWindowViewController } from '@src/viewControllers/BackToGameWindowViewController';
import { CheatWindowBankViewController } from '@src/viewControllers/cheat/CheatWindowBankViewContoller';
import { CheatWindowBoostsViewController } from '@src/viewControllers/cheat/CheatWindowBoostsViewController';
import { CheatWindowBusinessesViewController } from '@src/viewControllers/cheat/CheatWindowBusinessesViewController';
import { CheatWindowCharactersViewController } from '@src/viewControllers/cheat/CheatWindowCharactersViewController';
import { CheatWindowMediaViewController } from '@src/viewControllers/cheat/CheatWindowMediaViewController';
import { CheatWindowPoupsViewController } from '@src/viewControllers/cheat/CheatWindowPopupsViewController';
import { CheatWindowResourcesViewController } from '@src/viewControllers/cheat/CheatWindowResourcesViewController';
import { CheatWindowSkillsViewController } from '@src/viewControllers/cheat/CheatWindowSkillsViewController';
import { CheatWindowTotemsViewController } from '@src/viewControllers/cheat/CheatWindowTotemsViewController';
import { CheatWindowUpgradesViewController } from '@src/viewControllers/cheat/CheatWindowUpgradesViewController';
import { PrepartyStartCharacterViewController } from '@src/viewControllers/PrepartyStartCharacterViewController';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { BoostsTicker } from '@src/tickers/BoostsTicker';
import { MaintenanceLoader } from '@src/loaders/MaintenanceLoader';
import { MaintenanceTicker } from '@src/tickers/MaintenanceTicker';

export abstract class AbstractBehaviourInstance<T extends AbstractModelInstance> extends PIXI.utils.EventEmitter {
	protected readonly businessesTicker: BusinessesTicker;
	protected readonly summonTicker: SummonTicker;
	protected readonly bankTicker: BankTicker;
	protected readonly skillsTicker: SkillsTicker;
	protected readonly prepartyStartCharacterTicker: PrepartyStartCharacterTicker;
	protected readonly boostsTicker: BoostsTicker;
	protected readonly maintenanceTicker: MaintenanceTicker;

	protected readonly modelWriterCharacter: ModelWriterCharacter;
	protected readonly modelWriterUpgrade: ModelWriterUpgrade;
	protected readonly modelWriterSkill: ModelWriterSkill;
	protected readonly modelWriterBoost: ModelWriterBoost;
	protected readonly modelWriterFarewellBoost: ModelWriterFarewellBoost;

	protected readonly backToGameRewardLoader: BackToGameRewardLoader;
	protected readonly transactionCreator: TransactionCreator;
	protected readonly bankLoader: BankLoader;
	protected readonly maintenanceLoader: MaintenanceLoader;

	protected readonly bankOfferWindowsEmitter: BankOfferWindowsEmitter;

	protected readonly businessZoomRequestHandler: BusinessBaseZoomRequestHandler;
	protected readonly showQuestTargetHandler: ShowQuestTargetHandler;

	protected readonly businessAcquiredUIViewControllerPool: BusinessAcquiredUIViewControllerPool;
	protected readonly businessManageUIViewControllerPool: BusinessManageUIViewControllerPool;
	protected readonly businessOpenToBuyViewUIControllerPool: BusinessOpenToBuyUIViewControllerPool;
	protected readonly skillBaseViewControllerPool: SkillBaseViewControllerPool;
	protected readonly buttonMaxCustomersViewController: ButtonMaxCustomersViewController;

	protected readonly collectionCardsViewController: CollectionCardsViewController;
	protected readonly tutorialStepsWindowViewController: TutorialStepWindowViewController;
	protected readonly characterProfileWindowViewController: CharacterProfileWindowViewController;
	protected readonly upgradePromoteWindowViewController: UpgradePromoteWindowViewController;
	protected readonly rewardResultWindowViewController: RewardResultWindowViewController;
	protected readonly supportWindowViewController: SupportWindowViewController;
	protected readonly gameProfileViewController: GameProfileViewController;
	protected readonly farewellPartyWindowViewController: FarewellPartyWindowViewController;
	protected readonly backToGameWindowViewController: BackToGameWindowViewController;
	protected readonly prepartyStartCharacterViewController: PrepartyStartCharacterViewController;

	protected readonly cheatWindowCharactersViewController: CheatWindowCharactersViewController;
	protected readonly cheatWindowTotemsViewController: CheatWindowTotemsViewController;
	protected readonly cheatWindowBankViewContoller: CheatWindowBankViewController;
	protected readonly cheatWindowBoostsViewController: CheatWindowBoostsViewController;
	protected readonly cheatWindowBusinessesViewController: CheatWindowBusinessesViewController;
	protected readonly cheatWindowMediaViewController: CheatWindowMediaViewController;
	protected readonly cheatWindowPopupsViewController: CheatWindowPoupsViewController;
	protected readonly cheatWindowResourcesViewController: CheatWindowResourcesViewController;
	protected readonly cheatWindowSkillsViewController: CheatWindowSkillsViewController;
	protected readonly cheatWindowUpgradesViewController: CheatWindowUpgradesViewController;

	protected readonly actionEmitters: PIXI.utils.EventEmitter[];

	constructor(
		behavioursFactory: BehavioursBaseFactory<T>,
	) {
		super();

		this.modelWriterCharacter = behavioursFactory.createModelWriterCharacter();
		this.modelWriterUpgrade = behavioursFactory.createModelWriterUpgrade();
		this.modelWriterSkill = behavioursFactory.createModelWriterSkill();
		this.modelWriterBoost = behavioursFactory.createModelWriterBoost();
		this.modelWriterFarewellBoost = behavioursFactory.createModelWriterFarewellBoost();

		this.bankTicker = behavioursFactory.createBankTicker();
		this.skillsTicker = behavioursFactory.createSkillsTicker();
		this.businessesTicker = behavioursFactory.createBusinessesTicker();
		this.summonTicker = behavioursFactory.createSummonTicker();
		this.prepartyStartCharacterTicker = behavioursFactory.createPrepartyCharacterTicker();
		this.boostsTicker = behavioursFactory.createBoostsTicker();
		this.maintenanceTicker = behavioursFactory.createMaintenanceTicker();

		this.backToGameRewardLoader = behavioursFactory.createBackToGameRewardLoader();
		this.maintenanceLoader = behavioursFactory.createMaintenanceLoader();

		this.transactionCreator = behavioursFactory.createTransactionCreator();
		this.bankLoader = behavioursFactory.createBankLoader(this.transactionCreator);

		this.backToGameWindowViewController = behavioursFactory.createBackToGameWindowViewController();

		this.farewellPartyWindowViewController = behavioursFactory.createFarewellPartyWindowViewController();

		this.bankOfferWindowsEmitter = behavioursFactory.createBankOfferWindowsEmitter();

		this.businessZoomRequestHandler = behavioursFactory.createBusinessZoomRequestHandler();

		this.tutorialStepsWindowViewController = behavioursFactory.createTutorialStepsWindowViewController();

		this.showQuestTargetHandler = behavioursFactory.createShowQuestTargetHandler();

		this.businessAcquiredUIViewControllerPool = behavioursFactory.createBusinessAcquiredUIViewControllerPool(
			this.businessesTicker,
		);

		this.businessOpenToBuyViewUIControllerPool = behavioursFactory.createBusinessOpenToBuyViewUIControllerPool();

		this.businessManageUIViewControllerPool = behavioursFactory.createBusinessManageUIViewControllerPool();

		this.skillBaseViewControllerPool = behavioursFactory.createSkillBaseViewControllerPool();

		this.characterProfileWindowViewController = behavioursFactory.createCharacterProfileWindowViewController();

		this.upgradePromoteWindowViewController = behavioursFactory.createUpgradePromoteWindowViewController();

		this.buttonMaxCustomersViewController = behavioursFactory.createButtonMaxCustomersViewController();

		this.rewardResultWindowViewController = behavioursFactory.craeteRewardResultWindowViewController();

		this.supportWindowViewController = behavioursFactory.createSupportWindowViewController();

		this.gameProfileViewController = behavioursFactory.createGameProfileViewController();

		this.collectionCardsViewController = behavioursFactory.createCollectionCardsViewController();

		this.prepartyStartCharacterViewController = behavioursFactory.createPrepartyCharacterViewController();

		this.cheatWindowCharactersViewController = behavioursFactory.createCheatWindowCharactersViewController();
		this.cheatWindowTotemsViewController = behavioursFactory.createCheatWindowTotemsViewController();
		this.cheatWindowBankViewContoller = behavioursFactory.createCheatWindowBankViewContoller();
		this.cheatWindowBoostsViewController = behavioursFactory.createCheatWindowBoostsViewController();
		this.cheatWindowBusinessesViewController = behavioursFactory.createCheatWindowBusinessesViewController();
		this.cheatWindowMediaViewController = behavioursFactory.createCheatWindowMediaViewController();
		this.cheatWindowPopupsViewController = behavioursFactory.createCheatWindowPopupsViewController();
		this.cheatWindowResourcesViewController = behavioursFactory.createCheatWindowResourcesViewController();
		this.cheatWindowSkillsViewController = behavioursFactory.createCheatWindowSkillsViewController();
		this.cheatWindowUpgradesViewController = behavioursFactory.createCheatWindowUpgradesViewController();

		this.actionEmitters = [
			this.businessAcquiredUIViewControllerPool,
			this.businessManageUIViewControllerPool,
			this.businessOpenToBuyViewUIControllerPool,
			this.skillBaseViewControllerPool,
			this.buttonMaxCustomersViewController,
			this.tutorialStepsWindowViewController,
			this.characterProfileWindowViewController,
			this.upgradePromoteWindowViewController,
			this.rewardResultWindowViewController,
			this.backToGameWindowViewController,
			this.prepartyStartCharacterViewController,
			this.businessesTicker,
			this.boostsTicker,
			this.characterProfileWindowViewController,
			this.gameProfileViewController,
			this.modelWriterUpgrade,
		];
	}

	public initBankLoader(): void {
		this.bankLoader.init();
	}

	public setBankTickerEnabled(value: boolean): this {
		this.bankTicker.setEnabled(value);
		return this;
	}

	public setSkillsTickerEnabled(value: boolean): this {
		this.skillsTicker.setEnabled(value);
		return this;
	}

	public setSummonTickerEnabled(value: boolean): this {
		this.summonTicker.setEnabled(value);
		return this;
	}

	public setBusinessesTickerEnabled(value: boolean): this {
		this.businessesTicker.setEnabled(value);
		return this;
	}

	public setPrepartyStartCharacterTickerEnabled(value: boolean): this {
		this.prepartyStartCharacterTicker.setEnabled(value);
		return this;
	}

	public setBoostsTickerEnabled(value: boolean): this {
		this.boostsTicker.setEnabled(value);
		return this;
	}

	public setMaintenanceTickerEnabled(value: boolean): this {
		this.maintenanceTicker.setEnabled(value);
		return this;
	}

	public setBankLoaderEnabled(value: boolean): this {
		this.bankLoader.setUpdatesEnabled(value);
		return this;
	}

	protected init(): void {
		if (!this.backToGameRewardLoader.isInited()) {
			this.backToGameRewardLoader.init();
		}

		this.maintenanceLoader.init();

		this.businessesTicker.init();
		this.summonTicker.init();
	}

	protected subscribeActionEmittersIfAny(): void {
		this.actionEmitters.forEach(emitter => {
			if (!emitter.listeners(BaseAction.EVENT_ACTION_CREATED).includes(this.onSomeActionCreated)) {
				emitter.on(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
			}
		});
	}

	protected unsubcribeActionEmitters(): void {
		this.actionEmitters.forEach(emitter => {
			emitter.off(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
		});
	}

	protected onSomeActionCreated(action: BaseAction): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public getGameUIBaseViewSetters(): GameUIBaseViewSetter[] {
		return [
			this.businessAcquiredUIViewControllerPool,
			this.backToGameWindowViewController,
			this.rewardResultWindowViewController,
			this.characterProfileWindowViewController,
			this.upgradePromoteWindowViewController,
			this.businessManageUIViewControllerPool,
		];
	}

	public getBusinessAcquiredUIViewSetters(): BusinessAcquiredUIViewSetter[] {
		return [
			this.businessAcquiredUIViewControllerPool,
		];
	}

	public getBusinessManageUIViewSetters(): BusinessManageUIViewSetter[] {
		return [
			this.businessManageUIViewControllerPool,
		];
	}

	public getBusinessOpenToBuyUIViewSetters(): BusinessOpenToBuyUIViewSetter[] {
		return [
			this.businessOpenToBuyViewUIControllerPool,
		];
	}

	public getBusinessBaseViewsSetters(): BusinessBaseViewsSetter[] {
		return [
			this.businessZoomRequestHandler,
			this.showQuestTargetHandler,
		];
	}

	public getSkillBaseViewSetters(): SkillBaseViewSetter[] {
		return [
			this.skillBaseViewControllerPool,
		];
	}

	public getQuestWindowBaseViewSetters(): QuestWindowBaseViewSetter[] {
		return [
			this.showQuestTargetHandler,
		];
	}

	public getCharacterProfileWindowViewSetters(): CharacterProfileWindowViewSetter[] {
		return [
			this.characterProfileWindowViewController,
		];
	}

	public getUpgradePromoteWindowViewSetters(): UpgradePromoteWindowViewSetter[] {
		return [
			this.upgradePromoteWindowViewController,
		];
	}

	public getTutorialStepWindowViewSetters(): TutorialStepWindowViewSetter[] {
		return [
			this.tutorialStepsWindowViewController,
		];
	}

	public getButtonMaxCustomersViewSetters(): ButtonMaxCustomersViewSetter[] {
		return [
			this.buttonMaxCustomersViewController,
		];
	}

	public getSettingsWindowViewSetters(): SettingsWindowViewSetter[] {
		return [
			this.gameProfileViewController,
		];
	}

	public getRewardResultWindowViewSetters(): RewardResultWindowViewSetter[] {
		return [
			this.rewardResultWindowViewController,
			this.collectionCardsViewController,
		];
	}

	public getSupportWindowViewSetters(): SupportWindowViewSetter[] {
		return [
			this.supportWindowViewController,
		];
	}

	public getRegistrationWindowViewSetters(): RegistrationWindowViewSetter[] {
		return [
			this.gameProfileViewController,
		];
	}

	public getViewportViewSetters(): ViewportViewSetter[] {
		return [
			this.showQuestTargetHandler,
			this.tutorialStepsWindowViewController,
			this.businessZoomRequestHandler,
			this.bankOfferWindowsEmitter,
		];
	}

	public getViewportUIViewSetters(): ViewportUIViewSetter[] {
		return [
			this.showQuestTargetHandler,
		];
	}

	public getPrepartyStartCharacterViewSetters(): PrepartyStartCharacterViewSetter[] {
		return [
			this.showQuestTargetHandler,
			this.prepartyStartCharacterViewController,
		];
	}

	public getFarewellPartyViewSetters(): FarewellPartyWindowViewSetter[] {
		return [
			this.farewellPartyWindowViewController,
		];
	}

	public getCharacterCollectionViewSetters(): CharacterCollectionViewSetter[] {
		return [
			this.collectionCardsViewController,
		];
	}

	public getUpgradeCollectionViewSetters(): UpgradeCollectionViewSetter[] {
		return [
			this.collectionCardsViewController,
		];
	}

	public getFurnitureCollectionViewSetters(): FurnitureCollectionViewSetter[] {
		return [
			this.collectionCardsViewController,
		];
	}

	public getTotemCollectionViewControllers(): TotemCollectionViewSetter[] {
		return [
			this.collectionCardsViewController,
		];
	}

	public getBackToGameWindowViewSetters(): BackToGameWindowViewSetter[] {
		return [
			this.backToGameWindowViewController,
		];
	}

	public getCheatWindowViewSetters(): CheatWindowViewSetter[] {
		return [
			this.cheatWindowCharactersViewController,
			this.cheatWindowTotemsViewController,
			this.cheatWindowBankViewContoller,
			this.cheatWindowBoostsViewController,
			this.cheatWindowBusinessesViewController,
			this.cheatWindowMediaViewController,
			this.cheatWindowPopupsViewController,
			this.cheatWindowResourcesViewController,
			this.cheatWindowSkillsViewController,
			this.cheatWindowUpgradesViewController,
		];
	}
}
