import { TextInput } from '@views/components/input/TextInput';
import { AssetsStorage } from '@main/AssetsStorage';
import { TextAreaInvalidInputView } from './TextAreaInvalidInputView';
import { BaseValidator } from '@src/utils/validators/BaseValidator';

export interface TextAreaInputParams {
	bgWidth: number;
	bgHeight?: number;
	multiline?: boolean;
	inputBgOffsetX?: number;
	inputBgOffsetY?: number;
	invalidInputOffsetX?: number;
	hideInvalidInputRedStroke?: boolean;
	maxLength?: number;
	visibleBg?: boolean;
}

export class TextAreaInputView extends PIXI.Container {
	private static readonly MAGIC_TEXT_INPUT_OFFSET: number = 35;
	public static readonly EVENT_INPUT: symbol = Symbol();
	public static readonly EVENT_FOCUS: symbol = Symbol();
	public static readonly EVENT_BLUR: symbol = Symbol();

	protected readonly messageInput: TextInput;
	protected readonly redStroke: PIXI.Graphics;
	private readonly params: TextAreaInputParams;
	protected validator: BaseValidator<string>;

	protected readonly invalidInput: TextAreaInvalidInputView;

	constructor(params: TextAreaInputParams, widthOffset: number = TextAreaInputView.MAGIC_TEXT_INPUT_OFFSET) {
		super();

		this.params = params;

		const inputBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['login_input_field_bg'], 13, 31, 12, 31);
		inputBg.width = this.params.bgWidth;
		if (this.params.bgHeight !== undefined) {
			inputBg.height = this.params.bgHeight;
		}
		inputBg.pivot.set(inputBg.width / 2, inputBg.height / 2);
		if (this.params.visibleBg !== undefined) {
			inputBg.visible = this.params.visibleBg;
		}

		this.messageInput = new TextInput({
			input: {
				fontFamily: 'Verdana',
				fontSize: '19px',
				fontWeight: 'bold',
				width: `${inputBg.width - widthOffset}px`,
				color: 'white',
				multiline: (this.params.multiline !== undefined) ? this.params.multiline : false,
				multilineRows: inputBg.height / 22,
			},
		});
		this.messageInput.on('input', () => this.emit(TextAreaInputView.EVENT_INPUT));
		this.messageInput.on('focus', () => this.emit(TextAreaInputView.EVENT_FOCUS));
		this.messageInput.on('blur', () => this.emit(TextAreaInputView.EVENT_BLUR));

		this.messageInput.pivot.set(
			(this.messageInput.width + widthOffset - TextAreaInputView.MAGIC_TEXT_INPUT_OFFSET) / 2,
			this.messageInput.height / 2,
		);

		if (this.params.maxLength !== undefined) {
			this.messageInput.maxLength = this.params.maxLength;
		}

		if (this.params.inputBgOffsetX !== undefined) {
			this.messageInput.x = this.params.inputBgOffsetX;
		}

		this.messageInput.y = this.params.inputBgOffsetY || 0;

		this.redStroke = new PIXI.Graphics();
		this.redStroke.beginFill(0xff0000);
		this.redStroke.drawRoundedRect(0, 0, inputBg.width + 5, inputBg.height + 5, 12);
		this.redStroke.endFill();
		this.redStroke.pivot.set(this.redStroke.width / 2, this.redStroke.height / 2);
		this.redStroke.visible = false;

		this.invalidInput = new TextAreaInvalidInputView();
		this.invalidInput.visible = false;
		this.invalidInput.x = this.params.bgWidth * 0.734;
		if (this.params.invalidInputOffsetX !== undefined) {
			this.invalidInput.x += this.params.invalidInputOffsetX;
		}

		this.addChild(
			this.redStroke,
			inputBg,
			this.messageInput as PIXI.Container,
			this.invalidInput,
		);
	}

	public setValidator(value: BaseValidator<string>): void {
		this.validator = value;
	}

	public isValid(): boolean {
		let result: boolean = true;
		if (this.validator) {
			const message = this.validator.validate(this.getText());
			if (message) {
				this.setTextInvalidInput(message);
				this.onInvalidInput();
			}
			result = message === undefined;
		}
		return result;
	}

	public setPlaceholder(value: string): void {
		this.messageInput.placeholder = value;
	}

	public getPlaceholder(): string {
		return this.messageInput.placeholder;
	}

	public setEnabled(value: boolean): void {
		this.messageInput.disabled = !value;
		if (value === false) {
			this.invalidInput.visible = false;
		}
	}

	public setText(value: string): void {
		this.messageInput.text = value;
	}

	public setTextInvalidInput(value: string): void {
		this.invalidInput.setText(value);
	}

	public setInputStyle(key: string, value: string): void {
		this.messageInput.setInputStyle(key, value);
	}

	public getText(): string {
		return this.messageInput.text;
	}

	public onInvalidInput(): void {
		this.invalidInput.visible = true;
		if (!this.params.hideInvalidInputRedStroke) {
			this.redStroke.visible = true;
		}
		if (!this.listeners(TextAreaInputView.EVENT_FOCUS).includes(this.onInvalidFocus)) {
			this.once('focus', this.onInvalidFocus, this);
		}
	}

	private onInvalidFocus(): void {
		this.invalidInput.visible = false;
		this.redStroke.visible = false;
	}
}
