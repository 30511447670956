import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { PaymentMethod } from '@src/types/TransactionTypes';

export class GameProfileModel extends PIXI.utils.EventEmitter {
	// eslint-disable-next-line max-len
	public static readonly FORMAT_USERNAME: RegExp = /^[a-zA-Z]+([_ -]?[a-zA-Z0-9])*$/;

	public static readonly INPUT_PASSWORD_MAX_LENGTH: number = 36;
	public static readonly INPUT_PASSWORD_MIN_LENGTH: number = 6;
	public static readonly INPUT_USERNAME_MIN_LENGTH: number = 3;
	public static readonly INPUT_USERNAME_MAX_LENGTH: number = 10;

	public static readonly EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER: symbol = Symbol();
	public static readonly EVENT_TIMEOUT_RETRY_CONFIRMATION_TIMER: symbol = Symbol();

	public static readonly EVENT_LOGIN_NOT_FOUND: symbol = Symbol();
	public static readonly EVENT_LOGIN_WRONG_PASSWORD: symbol = Symbol();
	public static readonly EVENT_LOGIN_EMAIL_NOT_CONFIRMED: symbol = Symbol();

	public static readonly EVENT_REGISTER: symbol = Symbol();
	public static readonly EVENT_REGISTER_EMAIL_ALREADY_EXISTS: symbol = Symbol();

	public static readonly EVENT_REWARD_COLLECTED: symbol = Symbol();

	public static readonly EVENT_PASSWORD_RECOVER_LOGIN_NOT_FOUND: symbol = Symbol();
	public static readonly EVENT_PASSWORD_RECOVER: symbol = Symbol();

	public static readonly EVENT_LOGGED_IN: symbol = Symbol();
	public static readonly EVENT_CONFIRMATION: symbol = Symbol();
	public static readonly EVENT_CONFIRMATION_WRONG_CODE: symbol = Symbol();

	public static readonly EVENT_USERNAME_UPDATED: symbol = Symbol();
	public static readonly EVENT_PAYMENT_METHOD_UPDATED: symbol = Symbol();

	private email: string;
	private loggedIn: boolean;
	private username: string;
	private paymentMethod?: PaymentMethod;
	private retrySendConfirmationCodeTime: number;
	private accountLifetime: number;
	private registrationLootboxConfig?: LootboxServerConfig;

	constructor() {
		super();

		this.email = '';
		this.retrySendConfirmationCodeTime = 0;
	}

	public onRewardCollected(): void {
		this.registrationLootboxConfig = undefined;
		this.emit(GameProfileModel.EVENT_REWARD_COLLECTED);
	}

	public onLogin(email: string): void {
		this.email = email;
		this.onLoggedIn();
	}

	public onLoginLoginNotFound(): void {
		this.emit(GameProfileModel.EVENT_LOGIN_NOT_FOUND);
	}

	public onPasswordRecoverLoginNotFound(): void {
		this.emit(GameProfileModel.EVENT_PASSWORD_RECOVER_LOGIN_NOT_FOUND);
	}

	public onLoginWrongPassword(): void {
		this.emit(GameProfileModel.EVENT_LOGIN_WRONG_PASSWORD);
	}

	public onLoginEmailNotConfirmed(email: string): void {
		this.email = email;
		this.emit(GameProfileModel.EVENT_LOGIN_EMAIL_NOT_CONFIRMED);
	}

	public onRegister(email: string): void {
		this.email = email;
		this.emit(GameProfileModel.EVENT_REGISTER);
	}

	public onRegisterEmailAlreadyExists(): void {
		this.emit(GameProfileModel.EVENT_REGISTER_EMAIL_ALREADY_EXISTS);
	}

	public onConfirmation(): void {
		this.onLoggedIn();

		this.emit(GameProfileModel.EVENT_CONFIRMATION);
	}

	private onLoggedIn(): void {
		this.loggedIn = true;
		this.emit(GameProfileModel.EVENT_LOGGED_IN);
	}

	public onConfirmationWrongCode(): void {
		this.emit(GameProfileModel.EVENT_CONFIRMATION_WRONG_CODE);
	}

	public onPasswordRecover(): void {
		this.emit(GameProfileModel.EVENT_PASSWORD_RECOVER);
	}

	public setLoggedIn(
		email?: string,
	): this {
		this.email = email;
		this.loggedIn = true;
		return this;
	}

	public retryEmailConfirmation(seconds: number): void {
		this.retrySendConfirmationCodeTime = seconds;
		this.emit(GameProfileModel.EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER);
	}

	public setRetrySendConfirmationCodeTime(value: number): void {
		this.retrySendConfirmationCodeTime = value;
		if (this.retrySendConfirmationCodeTime <= 0) {
			this.retrySendConfirmationCodeTime = 0;
			this.emit(GameProfileModel.EVENT_TIMEOUT_RETRY_CONFIRMATION_TIMER);
		}
	}

	public setUsername(value: string): void {
		this.username = value;
	}

	public updateUsername(value: string): void {
		this.username = value;
		this.emit(GameProfileModel.EVENT_USERNAME_UPDATED);
	}

	public setAccountLifetime(value: number): void {
		this.accountLifetime = value;
	}

	public getRetrySendConfirmationCodeTime(): number {
		return this.retrySendConfirmationCodeTime;
	}

	public getAccountLifetime(): number {
		return this.accountLifetime;
	}

	public getUsername(): string {
		return this.username;
	}

	public getPaymentMethod(): PaymentMethod | undefined {
		return this.paymentMethod;
	}

	public setPaymentMethod(value: PaymentMethod): void {
		this.paymentMethod = value;
	}

	public updatePaymentMethod(value: PaymentMethod): void {
		this.paymentMethod = value;
		this.emit(GameProfileModel.EVENT_PAYMENT_METHOD_UPDATED);
	}

	public hasUsername(): boolean {
		return Boolean(this.username);
	}

	public getEmail(): string {
		return this.email;
	}

	public setEmail(value: string): void {
		this.email = value;
	}

	public isLoggedIn(): boolean {
		return this.loggedIn;
	}

	public hasPendingReward(): boolean {
		return this.registrationLootboxConfig != null;
	}

	public getRegistrationLootboxConfig(): LootboxServerConfig | undefined {
		return this.registrationLootboxConfig;
	}

	public setRegistrationLootboxConfig(value?: LootboxServerConfig): void {
		this.registrationLootboxConfig = value;
	}
}
