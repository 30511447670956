import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextUtils } from '@src/utils/TextUtils';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';

export class FarewellPartyCompleteReward extends PIXI.Container {
	public static readonly EVENT_ANIMATION_END: symbol = Symbol();

	private readonly bgTint: PIXI.Graphics;
	private readonly content: PIXI.Container;
	private readonly decorContainer: PIXI.Container;

	private animationIn: TWEEN.Tween;
	private animationOut: TWEEN.Tween;

	constructor(
		seconds: number,
		value: number,
	) {
		super();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');
		const localizationStorage = LocalizationStorage.getInstance();

		this.bgTint = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
			.endFill();
		this.bgTint.interactive = true;

		this.content = new PIXI.Container();

		const bgGlow = new PIXI.Sprite(uiAtlas['b_circle_glow']);
		bgGlow.position.set(
			GameConstants.GAME_WIDTH / 2 - 3,
			478,
		);
		bgGlow.tint = 0x5920fa;
		bgGlow.scale.set(5.5);

		const icon = new PIXI.Sprite(uiAtlas['farewell_party_complete_boost_icon']);
		icon.position.set(
			GameConstants.GAME_WIDTH / 2 - 3,
			468,
		);

		// eslint-disable-next-line no-nested-ternary
		let boostTimeString: string;
		let timeValue: number;
		if (seconds === 3600) {
			timeValue = 1;
			boostTimeString = `${timeValue} ${localizationStorage.getLocalizedString('#hour')}`;
		} else if (seconds > 3600) {
			timeValue = seconds / 3600;
			boostTimeString = `${timeValue} ${localizationStorage.getLocalizedString('#hours')}`;
		} else {
			timeValue = seconds / 60;
			boostTimeString = `${timeValue} ${localizationStorage.getLocalizedString('#minutes')}`;
		}
		boostTimeString = TextUtils.getWordEnding(localizationStorage.getLanguage(), timeValue, boostTimeString);

		const titleString: string = localizationStorage.getLocalizedString('#farewell_award_income_boost')
			.replace('{{value}}', boostTimeString.toString());

		const title = new SizeableMultiColoredBitmapText(
			700,
			{ font: '40px wendyOneShadowBold', align: 'center' },
		);
		title.text = titleString;
		title.pivot.set(title.width / 2, title.height / 2);
		title.position.set(
			GameConstants.GAME_WIDTH / 2,
			768,
		);

		const descr = new SizeableBitmapText(
			`x${value}`,
			300,
			{ font: '100px wendyOneGold' },
		);
		descr.pivot.set(descr.width / 2, descr.height / 2);
		descr.position.set(
			icon.x + 20,
			icon.y + 182,
		);

		const button = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 326, 84),
			{ font: { size: 36, name: 'wendyOneShadowBold' } },
			320,
		);
		button.setTextLabel(localizationStorage.getLocalizedString('#bank_close_result_reward_button'));
		button.position.set(GameConstants.GAME_WIDTH / 2, icon.y + 522);
		button.on(ButtonBaseView.EVENT_CLICK, this.playClose, this);

		this.decorContainer = new PIXI.Container();

		const decor1 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor1.scale.set(-2, -2);
		decor1.position.set(icon.x + 110, icon.y + 42);
		decor1.blendMode = PIXI.BLEND_MODES.SCREEN;
		decor1.tint = 0x5920fa;

		const decor2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor2.scale.set(2, -2);
		decor2.position.set(icon.x - 110, icon.y + 42);
		decor2.blendMode = PIXI.BLEND_MODES.SCREEN;
		decor2.tint = 0x5920fa;

		this.content.pivot.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.position.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.scale.set(0);
		this.bgTint.alpha = 0;

		this.decorContainer.addChild(
			decor1,
			decor2,
		);

		this.content.addChild(
			bgGlow,
			this.decorContainer,
			icon as PIXI.DisplayObject,
			title,
			descr,
			button,
		);

		this.addChild(
			this.bgTint,
			this.content,
		);
	}


	public playOpen(): void {
		this.animationIn = new TWEEN.Tween(this.bgTint)
			.to({ alpha: 0.66 }, 400)
			.easing(TWEEN.Easing.Quadratic.In);
		const contentScaleIn = new TWEEN.Tween(this.content.scale)
			.to({ x: 1.1, y: 1.1 }, 150)
			.easing(TWEEN.Easing.Cubic.In);
		const contentScaleInBounce = new TWEEN.Tween(this.content.scale)
			.to({ x: 1, y: 1 }, 50)
			.easing(TWEEN.Easing.Linear.None);

		contentScaleIn.chain(contentScaleInBounce);
		this.animationIn.chain(contentScaleIn);
		this.animationIn.start();
	}

	public playClose(): void {
		this.animationOut = new TWEEN.Tween({ scale: 1 })
			.to({ scale: 0 }, 200)
			.onUpdate((tweenTarget) => {
				this.content.scale.set(tweenTarget.scale);
			})
			.easing(TWEEN.Easing.Cubic.In);
		const bgFadeOut = new TWEEN.Tween(this.bgTint)
			.to({ alpha: 0 }, 400)
			.easing(TWEEN.Easing.Quadratic.In)
			.onComplete(() => {
				this.emit(FarewellPartyCompleteReward.EVENT_ANIMATION_END);
			});
		this.animationOut.chain(bgFadeOut);
		this.animationOut.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.animationIn) {
			this.animationIn.stop();
		}
		if (this.animationOut) {
			this.animationOut.stop();
		}
		super.destroy(options);
	}
}
