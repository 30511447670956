import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80016View } from '@views/characters/events/event8/EventCharacter80016View';
import { EventCharacter80017View } from '@views/characters/events/event8/EventCharacter80017View';
import { EventCharacter80018View } from '@views/characters/events/event8/EventCharacter80018View';

export class EventBusiness80006View extends BusinessBaseView {
	constructor() {
		const eventCharacter80016View = new EventCharacter80016View();
		const eventCharacter80017View = new EventCharacter80017View();
		const eventCharacter80018View = new EventCharacter80018View();


		super('business80006', [eventCharacter80016View, eventCharacter80017View, eventCharacter80018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor1']).setTransform(-286, 60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor2']).setTransform(249, 67, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor3']).setTransform(-265, -30, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter80018View,
			eventCharacter80016View as PIXI.DisplayObject,
			eventCharacter80017View,
		);
	}
}
