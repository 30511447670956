import { BehavioursBaseFactory } from './BehavioursBaseFactory';
import { EventLevelModelInstance } from '@main/modelInstance/EventLevelModelInstance';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { CheatModel } from '@models/CheatModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { EventLevelGameUIViewController } from '@src/viewControllers/mainUI/EventLevelGameUIViewController';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { EventLevelSettingsWindowViewController } from '@src/viewControllers/EventLevelSettingsWindowViewController';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { TutorialStepWindowViewTargetEventLevelDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewEventLevelTargetDispatcher';
import { TutorialStepUnlockTypeEventLevelFactory } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypesEventLevelFactory';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { ModelWriterEventLevelBusinessIncomePerSec } from '@src/modelWriters/ModelWriterEventLevelBusinessIncomePerSec';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { ModelWriterEventLevelSoftMoney } from '@src/modelWriters/ModelWriterEventSoftMoney';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BoostModel } from '@models/BoostModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { EventLevelWindowViewFactory } from '../windows/EventLevelWindowViewFactory';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { EmittersController } from '@src/viewControllers/mainUI/EmittersController';
import { MainGameModels } from '@main/EventLevelInstance';
import { EventLevelHintViewController } from '@src/viewControllers/EventHintViewController';
import { ShowHintDataResolver } from '@views/ui/main/ShowHintDataResolver';
import { TransactionSaveData } from '@src/types/SaveTypes';
import { BankEventLevelConfigsLoader } from '@src/loaders/bank/BankEventLevelConfigsLoader';
import { EventLevelQuestWindowViewController } from '@src/viewControllers/EventLevelQuestWindowViewController';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { BankBundlesFactory } from '../bank/BankBundlesFactory';
import { BankGemShopItemsFactory } from '../bank/BankGemShopItemsFactory';
import { SummonEventLevelConfigsLoader } from '@src/loaders/summon/SummonEventLevelConfigsLoader';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { UnlockValueChecker } from '@src/loaders/UnlockValueChecker';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';
import { ModelWriterEventTutorialSteps } from '@src/modelWriters/ModelWriterEventTutorialSteps';
import { ModelWriterEventSummonSavesModel } from '@src/modelWriters/ModelWriterEventSummonSavesModel';

export class EventLevelBehavioursFactory extends BehavioursBaseFactory<EventLevelModelInstance> {
	private readonly unlockValueChecker: UnlockValueChecker;

	constructor(
		modelInstance: EventLevelModelInstance,
		networkRequestSender: NetworkRequestSender,
		cheatModel: CheatModel,
		private readonly eventLevelModel: EventLevelModel,
		gameProfileModel: GameProfileModel,
		bankPaymentSaveModel: BankPaymentSaveModel,
		transactionsSaveData: TransactionSaveData[],
		serverTime: ServerTimeModel,
		private readonly questConfigs: Map<string, QuestConfigBase>,
		constantsConfig: ConstantsConfig,
		savesConfig: SavesConfig,
		private readonly tutorialStepConfigs: Map<string, TutorialStepEventLevelConfig>,
		businessesLastUpdate: Map<string, number>,
		private readonly eventLevelEverEntered: boolean,
		farewellPartyEverStarted: boolean,
		windowViewSystem: WindowViewSystem,
		private readonly windowFactory: EventLevelWindowViewFactory,
		hintSpawner: ShowHintSpawner,
		private readonly mainGameModels: MainGameModels,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly farewellBoostModel: FarewellBoostModel,
		private readonly compensationLootboxConfigs: LootboxServerConfig[],
		isNutaku: boolean,
		assetUrlsLoader: AssetUrlsLoader,
	) {
		super(
			modelInstance,
			windowViewSystem,
			windowFactory,
			hintSpawner,
			networkRequestSender,
			cheatModel,
			gameProfileModel,
			serverTime,
			constantsConfig,
			savesConfig,
			bankPaymentSaveModel,
			transactionsSaveData,
			businessesLastUpdate,
			farewellPartyEverStarted,
			isNutaku,
			assetUrlsLoader,
		);

		this.unlockValueChecker = new UnlockValueChecker(
			this.serverTimeModel,
			this.modelInstance.getBankSavesModel(),
			this.bankPaymentSaveModel,
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getTutorialStepModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getLevelChallengeModel(),
			this.gameProfileModel,
			this.modelInstance.getSummonSavesModel(),
			this.mainGameModels.levelModel,
			this.eventLevelModel,
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
		);
	}

	public createTutorialStepUnlockTypeFactory(): TutorialStepUnlockTypeEventLevelFactory {
		const tutorialStepUnlockTypeConfigs = Array
			.from(this.tutorialStepConfigs.values())
			.map(config => config.getUnlockTypeConfig());

		return new TutorialStepUnlockTypeEventLevelFactory(
			this.windowViewSystem,
			tutorialStepUnlockTypeConfigs,
			this.modelInstance.getTutorialStepModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getQuestLinesModel(),
			this.eventLevelEverEntered,
			this.eventLevelModel,
			this.modelInstance.getCharacterModels(),
		);
	}

	public createEventLevelHintController(): EventLevelHintViewController {
		const mainGameHintResolver = new ShowHintDataResolver(
			this.mainGameModels.characterModels,
			this.mainGameModels.upgradeModels,
			this.mainGameModels.skillModels,
			this.mainGameModels.totemModels,
		);

		return new EventLevelHintViewController(
			this.windowViewSystem,
			this.windowFactory,
			this.hintSpawner,
			this.hintResolver,
			mainGameHintResolver,
			this.constantsConfig.getShowQuestTargetDuration(),
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getSkillModels(),
		);
	}

	public createSummonLoader(): SummonLoader {
		const configsLoader = new SummonEventLevelConfigsLoader(
			this.networkRequestSender,
		);
		return new SummonLoader(
			this.serverTimeModel,
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getSummonSavesModel(),
			this.unlockValueChecker,
			configsLoader,
		);
	}

	public createBankLoader(
		transactionCreator: TransactionCreator,
	): BankLoader {
		const configsLoader = new BankEventLevelConfigsLoader(
			this.networkRequestSender,
		);
		const bundlesFactory = new BankBundlesFactory(this.isNutaku);
		const gemShopItemsFactory = new BankGemShopItemsFactory(this.isNutaku);

		const bankLoader = new BankLoader(
			configsLoader,
			this.unlockValueChecker,
			this.modelInstance.getBankSavesModel(),
			this.modelInstance.getBankModel(),
			this.networkRequestSender,
			transactionCreator,
			this.serverTimeModel,
			bundlesFactory,
			gemShopItemsFactory,
			this.modelInstance.getRewardFactory(),
			this.transactionsSaveData,
			this.isNutaku ? this.constantsConfig.getNutakuPriceConversionCoeff() : 1,
			this.modelInstance.getTutorialStepsEmitter(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getLevelModel(),
			this.modelInstance.getQuestLinesModel(),
			this.constantsConfig.getBankLoaderConstants(),
		);

		return bankLoader;
	}

	public createEventLevelQuestWindowViewController(
		questsLoader: QuestsLoaderBase,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetEventLevelDispatcher,
	): EventLevelQuestWindowViewController {
		return new EventLevelQuestWindowViewController(
			questsLoader,
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getTutorialStepsEmitter(),
			tutorialStepsViewTargetDispatcher,
			this.windowViewSystem,
			this.windowFactory,
			this.cheatModel,
		);
	}

	public createTutorialStepsViewTargetEventDispatcher(): TutorialStepWindowViewTargetEventLevelDispatcher {
		return new TutorialStepWindowViewTargetEventLevelDispatcher(
			this.windowViewSystem,
			this.hintSpawner,
			this.modelInstance.getSummonModelsPool(),
		);
	}

	public createModelWriterEventLevelSoftMoney(): ModelWriterEventLevelSoftMoney {
		return new ModelWriterEventLevelSoftMoney(
			this.eventLevelModel,
			this.modelInstance.getSoftMoneyModel(),
		);
	}

	public createModelWriterEventLevelBusinessIncomePerSec(): ModelWriterEventLevelBusinessIncomePerSec {
		return new ModelWriterEventLevelBusinessIncomePerSec(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getTimeskipModels(),
			this.eventLevelModel,
		);
	}

	public createModelWriterEventTutorialSteps(): ModelWriterEventTutorialSteps {
		return new ModelWriterEventTutorialSteps(
			this.eventLevelModel,
			this.modelInstance.getEventTutorialStepsModel(),
		);
	}

	public createModelWriterEventSummonSavesModel(): ModelWriterEventSummonSavesModel {
		return new ModelWriterEventSummonSavesModel(
			this.eventLevelModel,
			this.modelInstance.getSummonSavesModel(),
		);
	}

	public createEventGameUIViewController(
		bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetEventLevelDispatcher,
		bankLoader: BankLoader,
		emittersController: EmittersController,
	): EventLevelGameUIViewController {
		return new EventLevelGameUIViewController(
			this.cheatModel,
			this.modelInstance.getDialogsFactory(),
			this.eventLevelModel,
			this.modelInstance.getBackToGameRewardModel(),
			this.modelInstance.getTutorialStepsEmitter(),
			bankOfferWindowsEmitter,
			tutorialStepsViewTargetDispatcher,
			emittersController,
			bankLoader,
			this.eventLevelEverEntered,
			this.windowViewSystem,
			this.windowFactory,
			this.boostModels,
			this.farewellBoostModel,
			this.compensationLootboxConfigs,
			this.modelInstance.getLootboxFactory(),
		);
	}

	public createEventSettingsWindowViewController(): EventLevelSettingsWindowViewController {
		return new EventLevelSettingsWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.networkRequestSender,
			this.gameProfileModel,
		);
	}

	public createQuestsLoaderBase(): QuestsLoaderBase {
		return new QuestsLoaderBase(
			this.questConfigs,
			this.networkRequestSender,
			this.modelInstance.getQuestFactory(),
			this.modelInstance.getLootboxFactory(),
			this.modelInstance.getQuestLinesModel(),
		);
	}
}
