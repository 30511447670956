import { BaseAction } from '../BaseAction';
import { SoftMoneyNumberRaw } from '@src/utils/SoftMoneyNumber';

export class BusinessBuyCustomerAction extends BaseAction {
	constructor(businessId: string, customerCount: number, cost: SoftMoneyNumberRaw, currentSoftMoney: string) {
		super('BusinessBuyCustomer');
		this.data.id = businessId;
		this.data.count = customerCount;

		const resourcesSpent = Object.create(null);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		resourcesSpent.soft_money = cost;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.resources_spent = resourcesSpent;

		const resourcesSync = Object.create(null);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		resourcesSync.soft_money = currentSoftMoney;
		this.sync['resources'] = resourcesSync;
	}
}
