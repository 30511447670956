import { ObjectPool } from '@src/utils/ObjectPool';
import { SkillBaseViewController } from './SkillBaseViewController';
import { SkillBaseViewSetter } from '@interfaces/ViewSetters';
import { SkillBaseView } from '@views/ui/skillsPanel/SkillBaseView';
import { SkillModel } from '@models/skills/SkillModel';
import { BaseAction } from '@models/network/actions/BaseAction';

export class SkillBaseViewControllerPool extends ObjectPool<SkillBaseViewController> implements SkillBaseViewSetter {
	private readonly skillModels: Map<string, SkillModel>;

	constructor(
		skillModels: Map<string, SkillModel>,
	) {
		super();
		this.skillModels = skillModels;
	}

	protected createPoolObject(key: string): SkillBaseViewController {
		const model = this.skillModels.get(key);
		return new SkillBaseViewController(model);
	}

	protected onPoolObjectCreated(poolObject: SkillBaseViewController): void {
		poolObject.on(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
	}

	private onSomeActionCreated(action: BaseAction): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public setSkillView(view: SkillBaseView): void {
		const key = view.getKey();
		this.getPoolObject(key).setSkillView(view);
	}
}
