import { CardRarity } from '@src/types/CardRarities';
import { BonusTypes } from '@src/types/BonusTypes';
import { BonusBaseModel } from '@models/bonuses/BonusBaseModel';
import { AbstractPromotableEntityConfig } from '../configs/AbstractPromotableEntityConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { UnlockInfo, UnlockTypes } from '@src/types/UnlockTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export abstract class PromotableModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_OLD: symbol = Symbol();
	public static readonly EVENT_CARDS_ADDED: symbol = Symbol();
	public static readonly EVENT_OPENED: symbol = Symbol();
	public static readonly EVENT_PROMOTED: symbol = Symbol();

	private readonly unlockInfo: UnlockInfo;

	protected id: string;
	protected level: number;
	protected opened: boolean;
	protected cardsCount: number;
	protected previousCardsCount: number;
	protected prevPromoteCostCards: number;
	protected prevPromoteCostPrestige: number;
	protected rarity: CardRarity;
	protected bonuses: Map<BonusTypes, BonusBaseModel>;
	protected newItem: boolean;

	protected constructor(
		config: AbstractPromotableEntityConfig,
		protected readonly promotePatternsConfig: AbstractPromotePatternsConfig,
	) {
		super();

		this.id = config.getKey();
		this.rarity = config.getRarity();
		this.unlockInfo = config.getUnlockInfo();
		this.promotePatternsConfig = promotePatternsConfig;

		this.level = 1;
		this.opened = false;
		this.cardsCount = 0;
		this.previousCardsCount = 0;
		this.prevPromoteCostCards = 0;
		this.newItem = true;

		this.bonuses = new Map();
		const bonusConfigs = config.getBonuses();
		bonusConfigs.forEach((bonusValues: number[], type: BonusTypes) => {
			switch (type) {
				case BonusTypes.MULTIPLIER_INCOME:
					this.bonuses.set(
						BonusTypes.MULTIPLIER_INCOME,
						new BonusBaseModel(BonusTypes.MULTIPLIER_INCOME, this.level, bonusValues),
					);
					break;

				case BonusTypes.MULTIPLIER_TIME:
					this.bonuses.set(
						BonusTypes.MULTIPLIER_TIME,
						new BonusBaseModel(BonusTypes.MULTIPLIER_TIME, this.level, bonusValues),
					);
					break;

				case BonusTypes.DECREASE_CUSTOMER_COST:
					this.bonuses.set(
						BonusTypes.DECREASE_CUSTOMER_COST,
						new BonusBaseModel(BonusTypes.DECREASE_CUSTOMER_COST, this.level, bonusValues),
					);
					break;

				case BonusTypes.MULTIPLIER_FUCKPOWER:
					this.bonuses.set(
						BonusTypes.MULTIPLIER_FUCKPOWER,
						new BonusBaseModel(BonusTypes.MULTIPLIER_FUCKPOWER, this.level, bonusValues),
					);
					break;

				case BonusTypes.FUCKTIME:
					this.bonuses.set(
						BonusTypes.FUCKTIME,
						new BonusBaseModel(BonusTypes.FUCKTIME, this.level, bonusValues),
					);
					break;

				case BonusTypes.REDUCE_TIME_INCOME_BY_CLICK:
					this.bonuses.set(
						BonusTypes.REDUCE_TIME_INCOME_BY_CLICK,
						new BonusBaseModel(BonusTypes.REDUCE_TIME_INCOME_BY_CLICK, this.level, bonusValues),
					);
					break;

				default:
					throw new Error(`Unsupported bonusType ${type}`);
			}
		});
	}

	public addCards(count: number): void {
		this.previousCardsCount = this.cardsCount;
		this.cardsCount += count;

		this.emit(PromotableModel.EVENT_CARDS_ADDED, this, count);
	}

	public open(): void {
		this.opened = true;
		this.newItem = true;
		this.emit(PromotableModel.EVENT_OPENED, this);
	}

	public setOld(): void {
		this.newItem = false;
		this.emit(PromotableModel.EVENT_OLD, this);
	}

	public getKey(): string {
		return this.id;
	}

	public getCardsCount(): number {
		return this.cardsCount;
	}

	public getPreviousCardsCount(): number {
		return this.previousCardsCount;
	}

	public getCardRarity(): CardRarity {
		return this.rarity;
	}

	public getLevel(): number {
		return this.level;
	}

	public isOpened(): boolean {
		return this.opened;
	}

	public getUnlockInfo(): UnlockInfo {
		return this.unlockInfo;
	}

	public canUnlockInSummon(currentLevel: number): boolean {
		return this.unlockInfo.type === UnlockTypes.MIN_LEVEL && currentLevel >= this.getUnlockLevel();
	}

	public isUnlockableByMinLevel(): boolean {
		return this.unlockInfo.type === UnlockTypes.MIN_LEVEL;
	}

	public getUnlockLevel(): number {
		if (this.unlockInfo.type !== UnlockTypes.MIN_LEVEL) {
			throw new Error(`Can't get unlock level with UnlockType: ${this.unlockInfo.type}`);
		}
		return Number(this.unlockInfo.value);
	}

	public isUnlockableAtLevel(currentLevel: number): boolean {
		return this.unlockInfo.type === UnlockTypes.MIN_LEVEL && currentLevel === this.getUnlockLevel();
	}

	public getPromoteCostCards(): number {
		return this.promotePatternsConfig.getPromoteCostCards(this.level, this.rarity);
	}

	public getPrevPromoteCostCards(): number {
		return this.prevPromoteCostCards;
	}

	public getPrevPromoteCostPrestige(): number {
		return this.prevPromoteCostPrestige;
	}

	public getPromoteCostPrestige(): number {
		return this.promotePatternsConfig.getPromoteCostPrestige(this.level, this.rarity);
	}

	public isEnoughCardsForPromote(): boolean {
		return this.getCardsCount() >= this.getPromoteCostCards();
	}

	public promote(): void {
		this.prevPromoteCostPrestige = this.getPromoteCostPrestige();
		this.prevPromoteCostCards = this.getPromoteCostCards();
		this.cardsCount -= this.prevPromoteCostCards;
		this.level += 1;

		this.recalculateBonuses();

		this.emit(PromotableModel.EVENT_PROMOTED, this, this.prevPromoteCostPrestige, this.prevPromoteCostCards);
	}

	// Bonuses
	protected recalculateBonuses(): void {
		this.bonuses.forEach(bonus => bonus.onOwnerLevelUpdated(this.level));
	}

	public getBonuses(): Map<BonusTypes, BonusBaseModel> {
		return this.bonuses;
	}

	public getFirstBonus(): BonusBaseModel | undefined {
		let result;

		if (this.bonuses.size > 0) {
			const [, bonusModel] = Array.from(this.bonuses)[0];
			result = bonusModel;
		}

		return result;
	}

	public getMultiplierIncomeAsSoftMoney(): SoftMoneyNumber {
		let result = SoftMoneyNumber.ONE;

		if (this.hasBonusMultiplierIncome()) {
			result = this.bonuses.get(BonusTypes.MULTIPLIER_INCOME).getValueAsSoftMoney();
		}

		return result;
	}

	public getNextMultiplierIncomeAsSoftMoney(): SoftMoneyNumber {
		let result = SoftMoneyNumber.ONE;

		if (this.hasBonusMultiplierIncome()) {
			result = this.bonuses.get(BonusTypes.MULTIPLIER_INCOME).getNextValueAsSoftMoney();
		}

		return result;
	}

	public hasBonusMultiplierIncome(): boolean {
		return this.bonuses.has(BonusTypes.MULTIPLIER_INCOME);
	}

	public getMultiplierTimeAsSoftMoney(): SoftMoneyNumber {
		if (this.hasBonusMultiplierTime()) {
			return this.bonuses.get(BonusTypes.MULTIPLIER_TIME).getValueAsSoftMoney();
		}
		return SoftMoneyNumber.ONE;
	}

	public getNextMultiplierTimeAsSoftMoney(): SoftMoneyNumber {
		if (this.hasBonusMultiplierTime()) {
			return this.bonuses.get(BonusTypes.MULTIPLIER_TIME).getNextValueAsSoftMoney();
		}
		return SoftMoneyNumber.ONE;
	}

	public hasBonusMultiplierTime(): boolean {
		return this.bonuses.has(BonusTypes.MULTIPLIER_TIME);
	}

	public getDecreaseCustomerCostCoef(): number {
		if (this.hasBonusDecreaseCustomerCostCoef()) {
			return this.bonuses.get(BonusTypes.DECREASE_CUSTOMER_COST).getValue();
		}
		return 1;
	}

	public getNextDecreaseCustomerCostCoef(): number {
		if (this.hasBonusDecreaseCustomerCostCoef()) {
			return this.bonuses.get(BonusTypes.DECREASE_CUSTOMER_COST).getNextValue();
		}
		return 1;
	}

	public hasBonusDecreaseCustomerCostCoef(): boolean {
		return this.bonuses.has(BonusTypes.DECREASE_CUSTOMER_COST);
	}

	public getMultiplierFuckpower(): number {
		if (this.hasBonusFuckpower()) {
			return this.bonuses.get(BonusTypes.MULTIPLIER_FUCKPOWER).getValue();
		}
		return 1;
	}

	public getNextMultiplierFuckpower(): number {
		if (this.hasBonusFuckpower()) {
			return this.bonuses.get(BonusTypes.MULTIPLIER_FUCKPOWER).getNextValue();
		}
		return 1;
	}

	public getNextMultiplierFucktime(): number {
		if (this.hasBonusFucktime()) {
			return this.bonuses.get(BonusTypes.FUCKTIME).getNextValue();
		}
		return 1;
	}

	public hasBonusFuckpower(): boolean {
		return this.bonuses.has(BonusTypes.MULTIPLIER_FUCKPOWER);
	}

	public getReduceTimeIncomeByClickValue(): number {
		if (this.hasBonusReduceTimeIncomeByClick()) {
			return this.bonuses.get(BonusTypes.REDUCE_TIME_INCOME_BY_CLICK).getValue();
		}
		return 1;
	}

	public getNextReduceTimeIncomeByClickValue(): number {
		if (this.hasBonusReduceTimeIncomeByClick()) {
			return this.bonuses.get(BonusTypes.REDUCE_TIME_INCOME_BY_CLICK).getNextValue();
		}
		return 1;
	}

	public hasBonusReduceTimeIncomeByClick(): boolean {
		return this.bonuses.has(BonusTypes.REDUCE_TIME_INCOME_BY_CLICK);
	}

	public hasBonusFucktime(): boolean {
		return this.bonuses.has(BonusTypes.FUCKTIME);
	}

	public getFucktime(): number {
		if (this.hasBonusFucktime()) {
			return this.bonuses.get(BonusTypes.FUCKTIME).getValue();
		}
		return 0;
	}

	public isNew(): boolean {
		return this.newItem;
	}

	public hasBonusAutomate(): boolean {
		return this.bonuses.has(BonusTypes.AUTOMATE);
	}
}
