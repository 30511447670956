export enum EventLevelConfigNames {
	CONFIG_SEASONS = 'Event_Seasons',
	CONFIG_BUSINESSES = 'Event_Businesses',
	CONFIG_CHARACTERS = 'Event_Characters',
	CONFIG_UPGRADES = 'Event_Upgrades',
	CONFIG_SKILLS = 'Event_Skills',
	CONFIG_QUESTS = 'Event_Quests',
	CONFIG_TUTORIAL_STEPS = 'Event_tutorSteps',
	CONFIG_DIALOGS = 'Event_Dialogs',
	CONFIG_BOOSTS = 'Event_Boosts',
	CONFIG_TIMESKIPS = 'Event_Timeskips',
	CONFIG_BANK_PRICE_ITEMS = 'Event_PriceItems',
	CONFIG_EVENT_DATES = 'Event_Dates',
	CONFIG_LEAGUE_BOTS = 'Event_Bots',
	CONFIG_LEAGUES = 'Event_Leagues',
	CONFIG_FAREWELL_PARTY = 'Event_FarewellParty',
	CONFIG_BANK_BUNDLES = 'Event_Bundles',
	CONFIG_BANK_BUNDLE_GROUPS = 'Event_BundleGroups',
	CONFIG_BANK_GEM_SHOP = 'Event_GemShop',
	CONFIG_BANK_OFFERS = 'Event_Offers',
	CONFIG_BANK_TABS = 'Event_BankTabs',
	CONFIG_SUMMON = 'Event_Summon',
	CONFIG_PROMOTE_PATTERNS = 'Event_PromotePatterns',
}
