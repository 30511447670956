import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class DiscordButtonView extends ButtonBaseView {
	private readonly infoIcon: PIXI.Sprite;
	protected readonly dateLabel: PIXI.extras.BitmapText;

	constructor() {
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_blue'], 11, 11, 11, 11);
		bg.width = 258;
		bg.height = 66;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		super(bg);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['discord_icon']);
		icon.position.set(-88, -1);

		const buttonDecor1 = new PIXI.Graphics()
			.beginFill(0x81a1d3)
			.drawRect(-51, -3, 2, 54)
			.endFill();
		buttonDecor1.pivot.set(buttonDecor1.width / 2, buttonDecor1.height / 2);

		const buttonDecor2 = new PIXI.Graphics()
			.beginFill(0x275694)
			.drawRect(-49, -3, 2, 54)
			.endFill();
		buttonDecor2.pivot.set(buttonDecor2.width / 2, buttonDecor2.height / 2);

		const label = new TextField(
			'DISCORD',
			{ font: '28px wendyOneShadowBold', align: 'center' },
			164,
			bg.height * 0.8,
		);
		label.anchor = 0.5;
		label.position.set(35, 0);

		this.addChild(
			icon,
			buttonDecor1,
			buttonDecor2 as PIXI.DisplayObject,
			label,
		);
	}
}
