import { BankPriceResourceTypes } from '@src/types/BankTypes';
import SoftMoneyNumber from './SoftMoneyNumber';

export class NumberUtils {
	public static readonly acronymSuffixSingle = 'kMBCDEFGHIJKLNOPQRSTUVWXYZ';
	public static readonly acronymSuffixDouble = 'ABCDEFGHIJKLNOPQRSTUVWXYZ';

	private static readonly acronymMinValue = 1000;

	public static getRandomInRange(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	public static secToDHMSColonFormatted(
		seconds: number,
		letterCaps: boolean = false,
	): string {
		const letterD = letterCaps ? 'D' : 'd';
		const letterH = letterCaps ? 'H' : 'h';
		const letterM = letterCaps ? 'M' : 'm';
		const units = NumberUtils.getDisplayDate(seconds);

		// eslint-disable-next-line no-nested-ternary
		if (seconds >= 86400) {
			return `${units.days}${letterD} ${Number(units.hours)}${letterH} ${Number(units.minutes)}${letterM}`;
		}

		if (seconds >= 60) {
			return `${units.hours}:${units.minutes}:${units.seconds}`;
		}

		return `00:00:${units.seconds}`;
	}

	public static secToHM(seconds: number): string {
		const hours: number = seconds / 3600;
		const minutes: number = Math.floor(seconds % 86400 % 3600 / 60);

		if (seconds >= 3600) {
			return `${hours}`;
		}

		if (seconds >= 60) {
			return `${minutes}`;
		}

		return `${seconds}`;
	}

	public static secToDHMS(
		seconds: number,
		letterCaps: boolean = false,
	): string {
		const letterD = letterCaps ? 'D' : 'd';
		const letterH = letterCaps ? 'H' : 'h';
		const letterM = letterCaps ? 'M' : 'm';
		const letterS = letterCaps ? 'S' : 's';

		const displayDate = NumberUtils.getDisplayDate(seconds);

		if (seconds >= 86400) {
			return `${displayDate.days}${letterD} ${Number(displayDate.hours)}${letterH}`;
		}
		if (seconds >= 3600) {
			return `${Number(displayDate.hours)}${letterH} ${Number(displayDate.minutes)}${letterM}`;
		}
		if (seconds >= 60) {
			return `${Number(displayDate.minutes)}${letterM} ${Number(displayDate.seconds)}${letterS}`;
		}
		return `${displayDate.seconds}${letterS}`;
	}

	public static secToHMS(seconds: number): string {
		const units = NumberUtils.getDisplayDate(seconds);
		return `${units.hours}:${units.minutes}:${units.seconds}`;
	}

	/**
	 * @param value Date format: dd.mm.yyyy
	 */
	public static dateStringToTime(value: string): number {
		const parts = value.split('.');
		const isoStr = `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00Z`;
		return Date.parse(isoStr);
	}

	/**
	 * @param value Date format: "yyyy-mm-dd hh:mm:ss"
	 */
	public static dailyRewardDateStringToTimestamp(value: string): number {
		const [date, time] = value.split(' ');
		const isoStr = `${date}T${time}Z`;
		return Math.round(Date.parse(isoStr) / 1000);
	}

	/**
	 * @param value Date format: dd.mm.yyyy hh:mm
	 */
	public static convertDateToISO(value: string): string {
		const re = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2})/;
		const m = value.match(re);
		return `${m[3]}-${m[2]}-${m[1]}T${m[4]}:${m[5]}:00Z`;
	}

	public static numberToShortString(value: number): string {
		return this.numberStringToShortString(value.toString());
	}

	public static numberToFloatShortString(value: number): string {
		let result;

		if (value < NumberUtils.acronymMinValue) {
			result = value.toString();
		} else {
			result = SoftMoneyNumber.createFromNumber(value).toString();
		}

		return result;
	}

	public static numberStringToShortString(
		numberStr: string,
		intPartSize = 3,
		digitsForSuffix = 4,
		integerOnly = false,
	): string {
		const splitedNumber = numberStr.split('.');
		const intPart = splitedNumber[0];

		if (intPart.length < Math.max(intPartSize, digitsForSuffix)) {
			const commaIndex = intPart.length - 3;
			if (commaIndex > 0) {
				return `${intPart.substring(0, commaIndex)},${intPart.substring(commaIndex)}`;
			}
			return numberStr;
		}

		let strLength = intPart.length;
		let suffixIndex = -1;

		while (strLength > intPartSize) {
			strLength -= 3;
			suffixIndex += 1;
		}

		const resultSuffix = suffixIndex > -1 ? NumberUtils.getNumberSuffix(suffixIndex) : '';
		let result = intPart.substr(0, strLength);

		if (!integerOnly) {
			const decPartLength: number = numberStr[strLength + 1] === '0' ? 1 : 2;
			const decPartStr = numberStr.substr(strLength, decPartLength);
			result += (decPartStr === '0') ? '' : `.${decPartStr}`;
		}

		const resultStr = `${result} ${resultSuffix}`;

		return resultStr;
	}

	private static getNumberSuffix(initIndex: number): string {
		let resultSuffix: string;

		if (initIndex < NumberUtils.acronymSuffixSingle.length) {
			resultSuffix = NumberUtils.acronymSuffixSingle[initIndex];
		} else {
			const remainders = [];
			const radix = NumberUtils.acronymSuffixDouble.length;
			let quotient = initIndex - NumberUtils.acronymSuffixSingle.length + NumberUtils.acronymSuffixDouble.length;

			while (quotient >= 0) {
				remainders.push(quotient % radix);
				quotient = Math.floor(quotient / radix) - 1;
			}

			resultSuffix = remainders
				.reverse()
				.map((idx) => NumberUtils.acronymSuffixDouble[idx])
				.join('');
		}

		return resultSuffix;
	}

	public static formatTimeSecToMin(seconds: number): number {
		return Math.round(((seconds / 60) * 100) / 100);
	}

	public static convertPrice(price: number, resourceType: BankPriceResourceTypes, coeff: number): number {
		let result: number;

		if (coeff !== 1 && resourceType === BankPriceResourceTypes.REAL) {
			result = (Math.round(price * coeff * 100 + Number.EPSILON) / 100);
		} else {
			result = price;
		}

		return result;
	}

	public static formatCountdownTime(seconds: number): string {
		const formattedUnits = NumberUtils.getDisplayDate(seconds);
		let result = '';

		if (seconds < 60) {
			// format - s or ss
			result = seconds.toString();
		} else if (seconds < 3600) {
			// format - m:ss or mm:ss
			result = `${Number(formattedUnits.minutes)}:${formattedUnits.seconds}`;
		} else {
			// format - hh:mm:ss
			result = `${formattedUnits.hours}:${formattedUnits.minutes}:${formattedUnits.seconds}`;
		}

		return result;
	}

	private static getDisplayDate(sec: number): {
		days: string;
		hours: string;
		minutes: string;
		seconds: string;
	} {
		const d = Math.floor(sec / 86400);
		const h = Math.floor(sec % 86400 / 3600);
		const m = Math.floor(sec % 86400 % 3600 / 60);
		const s = sec > 1
			? Math.floor(sec % 86400 % 3600 % 60)
			: sec;

		const days = d.toString();
		const hours = h < 10 ? `0${h}` : h.toString();
		const minutes = m < 10 ? `0${m}` : m.toString();
		const seconds = s < 10 ? `0${s}` : s.toString();

		return {
			days,
			hours,
			minutes,
			seconds,
		};
	}
}
