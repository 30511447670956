import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepUnlockTypeEventStartConfig } from '@configs/tutorialSteps/TutorialStepUnlockTypeConfigs';

export class TutorialStepUnlockTypeEventStartModel extends TutorialStepUnlockTypeBaseModel {
	constructor(
		private readonly config: TutorialStepUnlockTypeEventStartConfig,
		private readonly eventEverStarted: boolean,
		private readonly eventKey: string,
	) {
		super(config.getKey(), TutorialStepUnlockTypes.EVENT_START);
	}

	public tryUnlock(): boolean {
		const eventKeys = this.config.getEventKeys();
		const canUnlock = !this.eventEverStarted && eventKeys.includes(this.eventKey);

		if (canUnlock) {
			this.onUnlocked();
		}

		return canUnlock;
	}
}
