import { LevelModelInstance } from '@main/modelInstance/LevelModelInstance';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { ModelInstanceDataBase, TutorialModelInstanceData, FarewellPartyModelInstanceData } from '@src/types/ModelInstanceDataTypes';
import CharacterConfig from '@configs/CharacterConfig';
import {
	CharacterSaveData,
	SkillSaveData,
	BusinessSaveData,
	TotemSaveData,
	BoostSaveData,
	UpgradeSaveData,
	TimeskipSaveData,
} from '@src/types/SaveTypes';
import { SkillConfig } from '@configs/SkillConfig';
import { BusinessConfig } from '@configs/BusinessConfig';
import { TotemConfig } from '@configs/TotemConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { VideoConfig } from '@configs/VideoConfig';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { PromotePatternsConfig } from '@configs/PromotePatternsConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { LevelModelsFactory } from '../models/LevelModelsFactory';
import { DailyRewardConfig } from '@configs/DailyRewardConfig';
import { PresetConfig } from '@configs/PresetConfig';

export class LevelModelInstanceFactory {
	private levelModelInstance: LevelModelInstance;

	public createLevelModelInstance(
		serverTime: ServerTimeModel,
		savesConfig: SavesConfig,
		characterModelInstanceData: ModelInstanceDataBase<CharacterConfig, CharacterSaveData>,
		skillModelInstanceData: ModelInstanceDataBase<SkillConfig, SkillSaveData>,
		businessModelInstanceData: ModelInstanceDataBase<BusinessConfig, BusinessSaveData>,
		totemModelInstanceData: ModelInstanceDataBase<TotemConfig, TotemSaveData>,
		boostModelInstanceData: ModelInstanceDataBase<BoostConfig, BoostSaveData>,
		upgradeModelInstanceData: ModelInstanceDataBase<UpgradeConfig, UpgradeSaveData>,
		timeskipModelInstanceData: ModelInstanceDataBase<TimeskipConfig, TimeskipSaveData>,
		galleryVideoModelInstanceData: ModelInstanceDataBase<VideoConfig, string>,
		tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepLevelConfig>,
		farewellPartyModelInstanceData: FarewellPartyModelInstanceData,
		epicQuestCollectionsModelInstanceData: ModelInstanceDataBase<EpicQuestCollectionsConfig, number[]>,
		dialogConfigs: Map<string, DialogConfig>,
		constantsConfig: ConstantsConfig,
		promotePatternsConfig: PromotePatternsConfig,
		bankPriceItemsConfig: BankPriceItemsConfig,
		dailyRewardConfigs: Map<string, DailyRewardConfig>,
		presetConfigs: Map<string, PresetConfig>,
		isNutaku: boolean,
	): LevelModelInstance {
		const modelsFactory = new LevelModelsFactory(
			serverTime,
			characterModelInstanceData,
			skillModelInstanceData,
			upgradeModelInstanceData,
			businessModelInstanceData,
			boostModelInstanceData,
			timeskipModelInstanceData,
			tutorialStepModelInstanceData,
			promotePatternsConfig,
			bankPriceItemsConfig,
			constantsConfig,
			dialogConfigs,
			savesConfig,
			dailyRewardConfigs,
			presetConfigs,
			isNutaku,
			galleryVideoModelInstanceData,
			epicQuestCollectionsModelInstanceData,
			totemModelInstanceData,
			farewellPartyModelInstanceData,
		);
		this.levelModelInstance = new LevelModelInstance(
			modelsFactory,
		);
		return this.levelModelInstance;
	}

	public getLevelModelInstance(): LevelModelInstance {
		return this.levelModelInstance;
	}
}
