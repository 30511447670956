import { TrackingData } from '@src/types/AnalyticsTypes';
import { AnalyticSourceGameProfileModel } from '../sources/AnalyticSourceGameProfileModel';
import { AnalyticSourceLevelStartWindowView, EventButtonStartClickEventArgs } from '../sources/AnalyticSourceLevelStartWindowView';

export class AnaylticTargetClickadu extends PIXI.utils.EventEmitter {
	public static readonly EVENT_RESPONSE: symbol = Symbol();

	private static readonly UTM_SOURCE: string = 'pan_ca';
	private static readonly BASE_URL = 'https://sancontr.com/conversion/aad1ac49aeb0bea7fc73554bc9035aa8f39dc497';

	private marketingTrackingParam?: string;
	private permitted: boolean;

	constructor(trackingData?: TrackingData) {
		super();

		this.init(trackingData);
	}

	public setGameProfileData(trackingData?: TrackingData): void {
		this.init(trackingData);
	}

	private init(trackingData?: TrackingData): void {
		if (trackingData !== undefined
			&& trackingData.utmSource === AnaylticTargetClickadu.UTM_SOURCE) {
			this.permitted = true;
			this.marketingTrackingParam = trackingData.clickId;
		} else {
			this.permitted = false;
		}
	}

	public addSourceGameProfileModel(source: AnalyticSourceGameProfileModel): void {
		source.on(AnalyticSourceGameProfileModel.EVENT_GAME_PROFILE_CONFIRMATION, async () => {
			if (this.permitted) {
				const url = `${AnaylticTargetClickadu.BASE_URL}/?visitor_id=${this.marketingTrackingParam}&aid=149731`;
				const response = await fetch(url);
				this.emit(AnaylticTargetClickadu.EVENT_RESPONSE, response);
			}
		}, this);
	}

	public addSourceLevelStartWindowView(source: AnalyticSourceLevelStartWindowView): void {
		const tryEmitEventTutorialEnd = async (args: EventButtonStartClickEventArgs): Promise<void> => {
			if (this.permitted && args.currentLevel === 4) {
				const url = `${AnaylticTargetClickadu.BASE_URL}/?visitor_id=${this.marketingTrackingParam}&aid=149731&goal=sale`;
				const response = await fetch(url);
				this.emit(AnaylticTargetClickadu.EVENT_RESPONSE, response);
			} else {
				source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
			}
		};
		source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
	}
}
