import { CollectionWithProgressBaseView } from '@views/windows/collection/CollectionWithProgressBaseView';
import { TotemCollectionsCardView } from '@views/windows/collection/totems/TotemCollectionsCardView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { TotemModel } from '@models/TotemModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export class TotemCollectionView extends CollectionWithProgressBaseView {
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();

	private totemKeyCardMap: Map<string, TotemCollectionsCardView>;

	constructor(
		totemModels: Map<string, TotemModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		questCollectCardsModel?: EpicQuestCollectionsModel,
	) {
		super(
			prestigeMoneyModel,
			hardMoneyModel,
			{ begin: 0, end: 0 },
			questCollectCardsModel,
			'#collection_window_totems_reward_progress',
		);

		this.totemKeyCardMap = new Map();

		this.scrollView.position.set(400, 0);

		let i = 0;
		totemModels.forEach((model) => {
			const x = 292 * (i % 5) + 160;
			const y = 423 * (Math.floor(i / 5)) + 283;
			const card = new TotemCollectionsCardView(
				model,
			);
			card.position.set(x, y);
			card.on(TotemCollectionsCardView.EVENT_CLICK, (data: PromotableClickData) => {
				if (!this.scrollView.getIsScrolling()) {
					this.emit(TotemCollectionView.EVENT_CARD_CLICK, data);
				}
			}, this);
			this.contentContainer.addChild(card);

			this.totemKeyCardMap.set(model.getKey(), card);
			i += 1;
		});
		this.scrollView.updateBounds();
	}

	public scrollTo(key: string): void {
		const card = this.totemKeyCardMap.get(key);
		this.scrollView.jumpTo(-card.y);
	}

	public getOpenedItemContainer(key: string): PIXI.Container | undefined {
		return this.totemKeyCardMap.get(key).getOpenedItemContainer();
	}
}
