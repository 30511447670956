import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { BankInfoHintElement } from './BankInfoHintElement';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { CardRarity } from '@src/types/CardRarities';
import { RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { GameConstants } from '@src/utils/GameConstants';

export type BankInfoHintData = Map<string, SoftMoneyNumber | number>;

export class BankInfoHintView extends PIXI.Container {
	private static readonly ELEMENT_HIGHT: number = 66;
	private static readonly ELEMENTS_SORT_ORDER = {
		[RewardResourceIdTypes.HARD_MONEY]: 0,
		[RewardResourceIdTypes.PRESTIGE_MONEY]: 1,
		[RewardResourceIdTypes.SOFT_MONEY]: 2,
		[CardRarity.COMMON]: 3,
		[CardRarity.RARE]: 4,
		[CardRarity.EPIC]: 5,
		[CardRarity.LEGENDARY]: 6,
		[RewardTypes.BOOST]: 7,
		[RewardTypes.TIMESKIP]: 8,
	}

	private readonly contentContainer: PIXI.Container;
	private readonly parentContainer: PIXI.Container;
	private readonly contentMask: PIXI.Graphics;
	private readonly bg: PIXI.mesh.NineSlicePlane;
	private readonly hintArrowUp: PIXI.Sprite;
	private readonly hintArrowDown: PIXI.Sprite;
	private readonly elementsBg: PIXI.Graphics;

	constructor() {
		super();

		const height = 400;

		this.parentContainer = new PIXI.Container();

		this.contentMask = new PIXI.Graphics();
		this.contentMask.lineStyle();
		this.contentMask.beginFill(0x1f262e, 1);
		this.contentMask.drawRoundedRect(0, 0, 485, height - 94, 16);
		this.contentMask.endFill();
		this.contentMask.pivot.set(this.contentMask.width / 2, this.contentMask.height / 2);

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['bank_info_hint_bg'], 22, 27, 22, 27);
		this.bg.width = 581;
		this.bg.height = height;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.hintArrowUp = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['bank_info_hint_tail_up']);
		this.hintArrowUp.y = this.bg.y - this.bg.height / 2 + 10.5;

		this.hintArrowDown = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['bank_info_hint_tail_dawn']);
		this.hintArrowDown.y = this.bg.y + this.bg.height / 2 - 10.5;

		this.elementsBg = new PIXI.Graphics();
		this.elementsBg.lineStyle();
		this.elementsBg.beginFill(0x1f262e, 1);
		this.elementsBg.drawRoundedRect(0, 0, 485, height - 94, 16);
		this.elementsBg.endFill();
		this.elementsBg.pivot.set(this.elementsBg.width / 2, this.elementsBg.height / 2);

		this.contentContainer = new PIXI.Container();
		this.contentContainer.mask = this.contentMask;

		this.addChild(
			this.parentContainer,
		);
		this.parentContainer.addChild(
			this.bg as PIXI.DisplayObject,
			this.hintArrowUp,
			this.hintArrowDown,
			this.elementsBg,
			this.contentMask,
			this.contentContainer,
		);
	}

	public init(data: BankInfoHintData): void {
		this.contentContainer.removeChildren();
		let y: number = -BankInfoHintView.ELEMENT_HIGHT / 2;
		const arr = Array.from(data.entries())
			.sort((a, b) => {
				const record = BankInfoHintView.ELEMENTS_SORT_ORDER as Record<string, number>;
				return record[a[0]] - record[b[0]];
			});

		arr.forEach(([key, value]) => {
			const elem = new BankInfoHintElement(key, value.toString());
			elem.y = y;
			this.contentContainer.addChild(elem);
			y += BankInfoHintView.ELEMENT_HIGHT;
		});
		this.layoutHint(y);
	}

	private layoutHint(y: number): void {
		const height: number = y + 120;

		if (this.getGlobalPosition().y > GameConstants.GAME_HEIGHT / 2) {
			this.hintArrowUp.visible = false;
			this.hintArrowDown.visible = true;
			this.layoutDown(height);
		} else {
			this.hintArrowDown.visible = false;
			this.hintArrowUp.visible = true;
			this.layoutUp(height);
		}
	}

	private layoutUp(height: number): void {
		this.contentMask
			.clear()
			.beginFill(0)
			.drawRoundedRect(0, 0, 485, height - 94, 16)
			.endFill();

		this.contentMask.pivot.set(this.contentMask.width / 2, this.contentMask.height / 2);
		this.bg.height = height;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);
		this.elementsBg
			.clear()
			.beginFill(0)
			.drawRoundedRect(0, 0, 485, height - 94, 16)
			.endFill();

		this.elementsBg.pivot.set(this.elementsBg.width / 2, this.elementsBg.height / 2);
		this.hintArrowUp.y = this.bg.y - this.bg.height / 2 + 10.5;
		this.parentContainer.y = this.parentContainer.height / 2;
		this.contentContainer.y = -this.contentContainer.height / 2 + BankInfoHintView.ELEMENT_HIGHT;
	}

	private layoutDown(height: number): void {
		this.contentMask
			.clear()
			.beginFill(0)
			.drawRoundedRect(0, 0, 485, height - 94, 16)
			.endFill();

		this.contentMask.pivot.set(this.contentMask.width / 2, this.contentMask.height / 2);
		this.bg.height = height;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);
		this.elementsBg
			.clear()
			.beginFill(0)
			.drawRoundedRect(0, 0, 485, height - 94, 16)
			.endFill();

		this.elementsBg.pivot.set(this.elementsBg.width / 2, this.elementsBg.height / 2);
		this.hintArrowDown.y = this.bg.y + this.bg.height / 2 - 10.5;
		this.parentContainer.y = -this.parentContainer.height / 2;
		this.contentContainer.y = -this.contentContainer.height / 2 + BankInfoHintView.ELEMENT_HIGHT;
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		let scaleStartY: number;
		let scaleToY: string;
		if (this.getGlobalPosition().y > GameConstants.GAME_HEIGHT / 2) {
			scaleStartY = this.y + 40;
			scaleToY = '-40';
		} else {
			scaleStartY = this.y - 40;
			scaleToY = '+40';
		}

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y = scaleStartY;
		new TWEEN.Tween(this)
			.to({ y: scaleToY }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}
}
