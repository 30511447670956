import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20006View extends CharacterMainBaseView {
	constructor() {
		super('character20006');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-152, 63);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(0, -81),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(20, -83),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
