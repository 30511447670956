import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { TutorialStepLevelModel } from '@models/tutorialSteps/TutorialStepLevelModel';

export class TutorialStepsLevelFactory {
	private readonly configs: Map<string, TutorialStepLevelConfig>;
	private readonly tutorialStepsShowed?: string[];

	constructor(
		configs: Map<string, TutorialStepLevelConfig>,
		tutorialStepsShowed?: string[],
	) {
		this.configs = configs;
		this.tutorialStepsShowed = tutorialStepsShowed;
	}

	public createModels(): Map<string, TutorialStepLevelModel> {
		const models: Map<string, TutorialStepLevelModel> = new Map();
		this.configs.forEach((config) => {
			const model = new TutorialStepLevelModel(config);
			if (this.tutorialStepsShowed && this.tutorialStepsShowed.some(key => config.getKey() === key)) {
				model.setCompleted();
			}
			models.set(config.getKey(), model);
		});
		return models;
	}
}
