import { DualStateButtonBaseView } from '@views/components/buttons/DualStateButtonBaseView';
import { SoundController } from '@src/main/SoundController';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { TextField } from '../text/TextField';

export class TabButtonBaseView extends DualStateButtonBaseView {
	protected readonly buttonLabel: PIXI.extras.BitmapText;

	constructor(
		inactiveBg: PIXI.mesh.NineSlicePlane,
		activeBg: PIXI.mesh.NineSlicePlane,
		icon: PIXI.Sprite,
		buttonLabel: string,
		labelWidth?: number,
		labelHeight?: number,
		labelStyle?: PIXI.extras.BitmapTextStyle,
	) {
		super(
			inactiveBg,
			activeBg,
		);

		const style = labelStyle || { font: '32px wendyOneShadowBold', align: 'center' };
		this.buttonLabel = new TextField(buttonLabel, style, labelWidth, labelHeight);
		this.buttonLabel.anchor = 0.5;

		this.addChild(
			this.buttonLabel,
			icon as PIXI.DisplayObject,
		);
	}

	public setActive(active: boolean): void {
		if (active) {
			this.setSecondState();
		} else {
			this.setMainState();
		}

		this.setEnabled(!active);
	}

	public setTextLabel(text: string): void {
		this.buttonLabel.text = text;
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playTabChange();

		this.emit(ButtonBaseView.EVENT_CLICK, this);
	}
}
