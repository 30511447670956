import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { ViewportView } from '@views/components/ViewportView';
import { GameConstants } from '@src/utils/GameConstants';
import { ChainableObject } from '@src/utils/ChainableObject';

export class ShowStepCenterOnBusiness implements ChainableObject {
	constructor(
		private readonly target: PIXI.Container,
		private readonly viewport: ViewportView,
		private readonly duration: number = 0,
	) {
	}

	public start(): Promise<void> {
		const businessX = this.viewport.toLocal(this.target.position, this.target.parent).x - GameConstants.GAME_WIDTH / 2;
		return this.viewport.moveTo(businessX, this.duration);
	}
}

export class ShowStepZoomBusiness implements ChainableObject {
	constructor(private readonly target: BusinessBaseView) {}

	public start(): Promise<void> {
		return new Promise(resolve => {
			this.target.once(BusinessBaseView.EVENT_ZOOM_IN_COMPLETED, () => resolve(), this);
			this.target.requestZoomIn();
		});
	}
}
