import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';

export class ButtonNewsView extends ButtonBaseView {
	private readonly infoIcon: PIXI.Sprite;
	private readonly label: PIXI.extras.BitmapText;
	private readonly localizationStorage: LocalizationStorage;
	private readonly buttonNewsBg: PIXI.Sprite;

	constructor() {
		const clickArea = new PIXI.Graphics();
		clickArea.beginFill(0x000000, 1);
		clickArea.drawCircle(0, 0, 42);
		clickArea.endFill();

		super(clickArea);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.buttonNewsBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['circle_btn']);
		this.buttonNewsBg.interactive = false;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['mail_btn_icon']);

		this.label = new PIXI.extras.BitmapText('', { font: '18px wendyOneShadowBold', align: 'center' });
		this.label.y = 37;
		this.label.anchor = 0.5;
		this.updateText();

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.setTransform(34, -27, 0.5, 0.5);
		this.infoIcon.visible = false;

		this.addChild(
			this.buttonNewsBg,
			icon,
			this.label as PIXI.DisplayObject,
			this.infoIcon,
		);
	}

	public setLocked(value: boolean): void {
		this.interactive = !value;

		if (!value) {
			this.buttonNewsBg.tint = 0xFFFFFF;

			if (!this.listeners('pointertap').includes(this.onPointerTap)) {
				this.on('pointertap', this.onPointerTap, this);
			}
		} else {
			this.buttonNewsBg.tint = 0x7F7F7F;
			this.off('pointertap', this.onPointerTap, this);
		}
	}

	private updateText(): void {
		this.label.text = this.localizationStorage.getLocalizedString('#gameUI_NewsButton');
	}

	public setInfoIconVisible(value: boolean): void {
		this.infoIcon.visible = value;
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
