import { EventLevelModel } from '@models/level/EventLevelModel';
import { CheatModel } from '@models/CheatModel';

export class EventLevelTicker {
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly eventLevelModel: EventLevelModel;
	private readonly noviceEventKey: string;
	private timerTicking: boolean;

	constructor(
		eventLevelModel: EventLevelModel,
		cheatModel: CheatModel,
		noviceEventKey: string,
	) {
		this.ticker = PIXI.ticker.shared;
		this.eventLevelModel = eventLevelModel;
		this.noviceEventKey = noviceEventKey;

		cheatModel.once(CheatModel.EVENT_UPDATE_NOVICE_EVENT_ENABLED, this.onUpdateNoviceEnabled, this);
	}

	private onTimerUpdate(): void {
		const timeleft = this.eventLevelModel.getTimeleft() - this.ticker.elapsedMS / 1000;
		if (timeleft > 0) {
			this.eventLevelModel.setTimeleft(timeleft);
		} else {
			this.stopTimer();
			this.eventLevelModel.timeout();
		}
	}

	public startTimer(): void {
		if (this.timerTicking) {
			this.stopTimer();
		}

		this.timerTicking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	private stopTimer(): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}

	private onUpdateNoviceEnabled(value: boolean): void {
		if (!value && this.eventLevelModel.getKey() === this.noviceEventKey) {
			this.stopTimer();
			this.eventLevelModel.timeout();
		}
	}
}
