import { UpgradeConfig } from '@configs/UpgradeConfig';
import { UpgradeModel } from '@models/UpgradeModel';
import { BusinessConfig } from '@configs/BusinessConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { UpgradeSaveData, BusinessSaveData } from '@src/types/SaveTypes';
import { BusinessModel } from '@models/BusinessModel';

export class UpgradesFactory {
	constructor(
		private readonly configs: Map<string, UpgradeConfig>,
		private readonly promotePatternsConfig: AbstractPromotePatternsConfig,
		private readonly savesData: Map<string, UpgradeSaveData>,
		private readonly businessConfigs: Map<string, BusinessConfig>,
		private readonly businessSavesData: Map<string, BusinessSaveData>,
	) {}

	public createModels(): Map<string, UpgradeModel> {
		const upgradeBusinessKeysMap: Map<string, string> = new Map();
		const businessUpgradesKeysMap: Map<string, string[]> = new Map();
		const models: Map<string, UpgradeModel> = new Map();

		this.businessConfigs.forEach((businessConfig, businessKey) => {
			businessUpgradesKeysMap.set(businessKey, businessConfig.getUpgradeKeys());
			businessConfig.getUpgradeKeys().forEach((upgradeKey) => {
				upgradeBusinessKeysMap.set(upgradeKey, businessKey);
			});
		});

		this.configs.forEach((upgradeConfig, upgradeKey) => {
			// Create model
			const model: UpgradeModel = new UpgradeModel(
				upgradeConfig,
				this.promotePatternsConfig,
			);
			models.set(upgradeKey, model);

			// Set from saves
			const upgradeSaveData = this.savesData.get(upgradeKey);
			if (upgradeSaveData) {
				model.setFromSaveData(upgradeSaveData);
			}

			// Set business key
			const relatedBusinessKey = upgradeBusinessKeysMap.get(upgradeKey);
			model.setRelatedBusinessKey(relatedBusinessKey);

			// Set customer reach
			const relatedBusinessSaveData = this.businessSavesData.get(relatedBusinessKey);

			if (relatedBusinessSaveData && relatedBusinessSaveData.customers >= model.getCustomerCount()
				|| model.getCustomerCount() === BusinessModel.INITIAL_CUSTOMER_COUNT
				|| model.getCustomerCount() === 0) {
				model.setCustomerReached();
			}

			// Set unlocked
			if (model.isOpened() && model.isCustomersReached()) {
				model.setUnlocked();

				if (model.isFreeActivation()) {
					model.activate();
				}
			}
		});

		return models;
	}
}
