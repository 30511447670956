export type CharacterQuickPhraseType = string;

export enum CharacterQuickPhraseTypes {
	SPEEDUP = 'speedup',
	ACTIVATE_SKILL = 'activate_skill',
	ACTIVATE_CHARACTER = 'activate_character',
	ACTIVATE_ANOTHER_CHARACTER = 'activate_another_character',
	NEW_CUSTOMERS_MULTIPLIER = 'new_customers_mult',
	ZOOM = 'zoom',
	IDLE = 'idle',
}
