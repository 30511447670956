import { AssetsStorage } from '@main/AssetsStorage';
import { SummonModel } from '@models/SummonModel';
import { AbstractSummonItemTypeView } from '@views/windows/collection/summon/AbstractSummonItemTypeView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';

export class SummonItemView extends AbstractSummonItemTypeView {
	private static readonly BUTTONS_Y = 342;
	private loadingTextLabel: TextField;

	constructor(
		model: SummonModel,
		hardMoneyModel: HardMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		cardMiniViewFactory: CardMiniViewFactory,
		bg: string,
	) {
		super(
			model,
			hardMoneyModel,
			prestigeMoneyModel,
			cardMiniViewFactory,
		);

		const localizationStorage = LocalizationStorage.getInstance();

		this.bg.texture = AssetsStorage.getAtlas('collectionsAtlas')[bg];

		if (bg === 'SummonType9') {
			this.title.visible = false;
			this.specialTitle.position.set(770, 55);
			this.specialTitle.visible = true;
		} else if (bg === 'SummonType10') {
			this.saleText.position.set(1048, 343);
			this.saleText.rotation = Math.PI / 180 * -50;
			this.saleText.visible = true;
		}

		this.title.position.set(770, 55);

		this.description.position.set(770, 110);

		this.textContent.position.set(770, 178);

		this.contentCards.forEach((contentItem) => {
			contentItem.position.x += 770;
			contentItem.position.y += 243;
		});

		this.loadingTextLabel = new TextField(
			`${localizationStorage.getLocalizedString('#preloader_LoadingLabel')}...`,
			{ font: '28px wendyOneShadowBold', align: 'center' },
		);
		this.loadingTextLabel.position.set(770, SummonItemView.BUTTONS_Y);
		this.loadingTextLabel.anchor = 0.5;
		this.loadingTextLabel.visible = false;

		this.addChild(this.loadingTextLabel);
	}

	public showLoadingLabel(): void {
		this.loadingTextLabel.visible = true;

		if (this.buyButton != null) {
			this.buyButton.visible = false;
		}

		if (this.buy10Button != null) {
			this.buy10Button.visible = false;
		}
	}

	protected setButtonsPosition(): void {
		if (this.summonModel.getPriceValueX10()) {
			this.buyButton.position.set(667, SummonItemView.BUTTONS_Y);
			this.buy10Button.position.set(873, SummonItemView.BUTTONS_Y);
		} else {
			this.buyButton.position.set(770, SummonItemView.BUTTONS_Y);
		}
	}
}
