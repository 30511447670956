import { SkillModel } from '@models/skills/SkillModel';

export class SkillEffectiveImproveModel extends SkillModel {
	public setInfluencedModels(skills: SkillModel[]): void {
		skills.forEach(skill => skill.on(SkillModel.EVENT_SKILL_BEFORE_ACTIVATE, this.onSomeSkillBeforeActivate, this));
	}

	private onSomeSkillBeforeActivate(skill: SkillModel): void {
		if (this.isActive()) {
			skill.setEffectiveImproveMultiplier(this.getMultiplier());
			this.deactivate();
		}
	}
}
