import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export type AimPosition = {
	x: number;
	y: number;
}

export type FarewellAnimationAimPositions = {
	positions: AimPosition[];
}

type FarewellRawConfig = {
	id: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	animation: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	aim_positions: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	unlock_type: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	unlock_value: string;
}

type FarewellConfigKey = keyof FarewellRawConfig;

export class FarewellPartyConfig implements ConfigSetElement {
	private static coordinatesToPoint(pointStr: string): AimPosition {
		const parsedNumbers: number[] = pointStr.split(':').map(parseFloat);

		return { x: parsedNumbers[0], y: parsedNumbers[1] };
	}

	public static readonly FIELD_KEY: FarewellConfigKey = 'id';
	public static readonly FIELD_ANIMATION: FarewellConfigKey = 'animation';
	public static readonly FIELD_AIM_POSITIONS: FarewellConfigKey = 'aim_positions';
	public static readonly FIELD_UNLOCK_TYPE: FarewellConfigKey = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: FarewellConfigKey = 'unlock_value';

	public static readonly FIELDS: string[] = [
		FarewellPartyConfig.FIELD_KEY,
		FarewellPartyConfig.FIELD_AIM_POSITIONS,
		FarewellPartyConfig.FIELD_ANIMATION,
		FarewellPartyConfig.FIELD_UNLOCK_TYPE,
		FarewellPartyConfig.FIELD_UNLOCK_VALUE,
	];

	private readonly id: string;
	private readonly animationKey: string;
	private readonly farewellAnimationsAimPositions: FarewellAnimationAimPositions;
	private readonly unlockTutorialStep: string;

	constructor(config: { [key: string]: string }) {
		this.id = config[FarewellPartyConfig.FIELD_KEY].trim();
		this.animationKey = config[FarewellPartyConfig.FIELD_ANIMATION].trim();

		const positions = config[FarewellPartyConfig.FIELD_AIM_POSITIONS]
			.replace(/(\r\n|\n|\r)/gm, '') // remove line breaks
			.replace(/ /g, '') // remove spaces
			.split(',')
			.map(FarewellPartyConfig.coordinatesToPoint);

		this.unlockTutorialStep = config.unlock_value;
		this.farewellAnimationsAimPositions = { positions };
	}

	public getKey(): string {
		return this.id;
	}

	public getAnimationKey(): string {
		return this.animationKey;
	}

	public getUnlockTutorialStep(): string {
		return this.unlockTutorialStep;
	}

	public getFarewellAnimationsAimPositions(): FarewellAnimationAimPositions {
		return this.farewellAnimationsAimPositions;
	}
}
