import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70006View extends CharacterMainBaseView {
	constructor() {
		super('character70006');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(252, -11);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-210, -81),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-181, -83),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
