import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import LocalizationStorage from '@main/LocalizationStorage';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { BankUIViewHelper } from '../BankUIViewHelper';
import { BankTabElementRechargeView } from './BankTabElementRechargeView';
import { BankTabElementHeaderSummonView } from './BankTabElementHeaderSummonView';
import { BankTabElementBundleHorizontalView, BundleRewardDisplayInfo } from './BankTabElementBundleHorizontalView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { SkillModel } from '@models/skills/SkillModel';
import { BankBundleGroupModel } from '@models/bank/BankBundleGroupModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BoostModel } from '@models/BoostModel';
import { CharacterModel } from '@models/CharacterModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { BankTabElementBundleTimedView } from './BankTabElementBundleTimedView';
import { BankTabElementCompareLeftView } from './BankTabElementCompareLeftView';
import { BankTabElementCompareRightView } from './BankTabElementCompareRightView';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { BankTabElementGemShopBoostView } from './BankTabElementGemShopBoostView';
import { BankTabElementGemShopDefaultView } from './BankTabElementGemShopDefaultView';
import { BankTabElementGemShopTimeskipView } from './BankTabElementGemShopTimeskipView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';

export class BankTabElementViewFactory {
	private readonly bankUIViewHelper: BankUIViewHelper;
	private readonly localizationStorage: LocalizationStorage;

	constructor(
		private readonly bankSavesModel: BankSavesModel,
		private readonly characterModels: Map<string, CharacterModel>,
		private readonly upgradeModels: Map<string, UpgradeModel>,
		private readonly totemModels: Map<string, TotemModel>,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly timeskipModels: Map<string, TimeskipModel>,
		private readonly skillModels: Map<string, SkillModel>,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly bankPriceItemsConfig: BankPriceItemsConfig,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
	) {
		this.localizationStorage = LocalizationStorage.getInstance();

		this.bankUIViewHelper = new BankUIViewHelper(
			this.characterModels,
			this.upgradeModels,
			this.totemModels,
			this.boostModels,
			this.timeskipModels,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createGemShopDefaultView(model: BankGemShopItemModel, viewParameters: string[]): BankTabElementGemShopDefaultView {
		const rewardConfig = model.getRewardDescription();
		const rewardType = rewardConfig.reward_type as RewardTypes;
		const rewardId = rewardConfig.reward_id as RewardResourceIdTypes;

		const view = new BankTabElementGemShopDefaultView(
			this.bankUIViewHelper.resolveRewardIconByReward(rewardType, rewardId, 0.9, 0.63),
			rewardConfig.reward_qty.toString(),
			model.getButtonPriceResource(),
			model.getPrice(),
			viewParameters,
			model.getMostPopularLocaleKey(),
			model.hasSticker() ? this.localizationStorage.getLocalizedString(model.getStickerLocaleKey()) : null,
			model.hasExtraRewards() ? model.getExtraRewardDescriptions()[0] : null,
		);

		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	public createRechargeView(model: BankRechargeModel): BankTabElementRechargeView {
		const rewards = this.bankSavesModel.getRechargeRewards(model.getKey());
		return new BankTabElementRechargeView(
			model,
			this.cardMiniViewFactory,
			rewards,
		);
	}

	// eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
	public createHeaderSummonView(config: BankBundleModel): BankTabElementHeaderSummonView {
		const headerView = new BankTabElementHeaderSummonView(
			config.getAvailableOn(),
			config,
		);

		return headerView;
	}

	public createBundleView(
		model: BankBundleModel,
		offerModel?: BankOfferModel,
	): BankTabElementBundleHorizontalView {
		const rewards = this.bankSavesModel.getBundleRewards(model.getKey());

		const bundleView = new BankTabElementBundleHorizontalView(
			model,
			this.bankUIViewHelper,
			this.cardMiniViewFactory,
			this.bankPriceItemsConfig,
			model.getButtonPriceResource(),
			this.hardMoneyModel,
			this.prestigeMoneyModel,
			rewards,
			offerModel,
		);

		return bundleView;
	}

	public createDailyBundleView(
		model: BankBundleModel,
		offerModel?: BankOfferModel,
		viewParameters?: string,
	): BankTabElementBundleTimedView {
		const rewards = this.bankSavesModel.getBundleRewards(model.getKey());
		const rewardsDisplayInfo = rewards.map((rewardData): BundleRewardDisplayInfo => ({
			icon: this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardData.reward_type as RewardTypes,
				rewardData.reward_id,
				0.6,
				0.6,
				CardMiniViewFactory.createStickerNew(),
			),
			count: Number(rewardData.reward_qty),
		}));

		const dailyBundleView = new BankTabElementBundleTimedView(
			model,
			rewards,
			rewardsDisplayInfo,
			model.getButtonPriceResource(),
			offerModel,
			viewParameters,
		);

		return dailyBundleView;
	}

	public createCompareLeftView(
		model: BankBundleModel,
		groupModel: BankBundleGroupModel,
		offerModel?: BankOfferModel,
	): BankTabElementCompareLeftView {
		const rewards = this.bankSavesModel.getBundleRewards(model.getKey());
		const rewardsDisplayInfo = rewards.map((rewardData): BundleRewardDisplayInfo => ({
			icon: this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardData.reward_type as RewardTypes,
				rewardData.reward_id,
				0.6,
				0.6,
				CardMiniViewFactory.createStickerNew(),
			),
			count: Number(rewardData.reward_qty),
		}));

		const compareLeftView = new BankTabElementCompareLeftView(
			model,
			rewards,
			rewardsDisplayInfo,
			groupModel.getCharacterViewType().characters[0],
			offerModel,
		);
		return compareLeftView;
	}

	public createCompareRightView(model: BankBundleModel): BankTabElementCompareRightView {
		const rewards = this.bankSavesModel.getBundleRewards(model.getKey());
		const rewardsDisplayInfo = rewards.map((rewardData): BundleRewardDisplayInfo => ({
			icon: this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardData.reward_type as RewardTypes,
				rewardData.reward_id,
				0.6,
				0.6,
				CardMiniViewFactory.createStickerNew(),
			),
			count: Number(rewardData.reward_qty),
		}));

		const compareRightView = new BankTabElementCompareRightView(
			model,
			rewards,
			rewardsDisplayInfo,
		);

		return compareRightView;
	}

	// eslint-disable-next-line class-methods-use-this
	public createGemShopBoostView(itemModel: BankGemShopItemModel): BankTabElementGemShopBoostView {
		const boostKey = itemModel.getRewardDescription().reward_id;
		const boostModel = this.boostModels.get(boostKey);
		const view = new BankTabElementGemShopBoostView(
			boostModel,
			itemModel.getButtonPriceResource(),
			itemModel.getPrice(),
			this.hardMoneyModel,
		);
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	public createGemShopTimeskipView(itemModel: BankGemShopItemModel): BankTabElementGemShopTimeskipView {
		const key = itemModel.getRewardDescription().reward_id;
		const model = this.timeskipModels.get(key);
		const view = new BankTabElementGemShopTimeskipView(
			model,
			itemModel.getButtonPriceResource(),
			itemModel.getPrice(),
			this.hardMoneyModel,
		);
		return view;
	}
}
