import {
	GameUIBaseViewSetter,
	BankOfferWindowViewSetter,
	BankWindowViewSetter,
	CharacterCollectionViewSetter,
	TotemCollectionViewSetter,
	UpgradeCollectionViewSetter,
	SummonCollectionViewSetter,
	CollectEventRewardsWindowViewSetter,
	GoToEventLevelWindowViewSetter,
	LevelChallengeWindowViewSetter,
	NewsWindowViewSetter,
	DailyRewardWindowViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { HintTypes } from '@src/types/HintTypes';
import { HintData, ShowHintDataResolver } from '@views/ui/main/ShowHintDataResolver';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { CharacterCollectionView } from '@views/windows/collection/characters/CharacterCollectionView';
import { TotemCollectionView } from '@views/windows/collection/totems/TotemCollectionView';
import { CollectionWithProgressBaseView } from '@views/windows/collection/CollectionWithProgressBaseView';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { CollectionWindowTabs, CollectionWindowOpenConfig, CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { ModelHelper } from '@models/ModelHelper';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { GoToEventLevelWindowView } from '@views/windows/GoToEventLevelWindowView';
import { CollectEventLevelRewardsWindowView } from '@views/windows/CollectEventLevelRewardsWindowView';
import { SkillHintView } from '@views/ui/hints/SkillHintView';
import { LevelChallengeWindowView } from '@views/windows/levelChallenge/LevelChallengeWindowView';
import { BaseNewsWindowView } from '@views/windows/newsWindow/BaseNewsWindowView';
import { DailyWindowBaseView } from '@views/windows/dailyWindow/DailyWindowBaseView';
import { FurnitureCollectionView } from '@views/windows/collection/furniture/FurnitureCollectionView';

export class BaseHintViewController implements GameUIBaseViewSetter, BankOfferWindowViewSetter, BankWindowViewSetter,
CharacterCollectionViewSetter, TotemCollectionViewSetter, UpgradeCollectionViewSetter, FurnitureCollectionViewSetter,
SummonCollectionViewSetter, CollectEventRewardsWindowViewSetter, GoToEventLevelWindowViewSetter, NewsWindowViewSetter,
DailyRewardWindowViewSetter, LevelChallengeWindowViewSetter {
	protected readonly hintSpawner: ShowHintSpawner;
	private readonly hintDataResolver: ShowHintDataResolver;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private readonly summonModelsPool: SummonModelsPool;
	private readonly questTargetDuration: number;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly skillModels: Map<string, SkillModel>;
	protected gameUI: GameUIBaseView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		hintSpawner: ShowHintSpawner,
		hintDataResolver: ShowHintDataResolver,
		questTargetDuration: number,
		summonModelsPool: SummonModelsPool,
		characterModels: Map<string, CharacterModel>,
		skillModels: Map<string, SkillModel>,
	) {
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.questTargetDuration = questTargetDuration;
		this.summonModelsPool = summonModelsPool;
		this.hintDataResolver = hintDataResolver;
		this.characterModels = characterModels;
		this.skillModels = skillModels;
		this.hintSpawner = hintSpawner;
		this.hintSpawner.on(ShowHintSpawner.EVENT_HINT_SKILL_SHOWED, this.onSkillHintShowed, this);
	}

	public init(currentLevel: number): void {
		this.hintDataResolver.init(currentLevel);
	}

	public setGoToEventLevelWindowView(view: GoToEventLevelWindowView): void {
		view.on(GoToEventLevelWindowView.EVENT_SHOW_HINT_REWARD_CARD, this.onShowHint, this);
	}

	public setCollectEventRewardsWindowView(view: CollectEventLevelRewardsWindowView): void {
		view.on(CollectEventLevelRewardsWindowView.EVENT_SHOW_HINT_REWARD_CARD, this.onShowHint, this);
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
		this.gameUI.on(GameUIBaseView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setBankOfferWindowView(view: BankOfferWindowView): void {
		view.on(BankOfferWindowView.EVENT_SHOW_HINT_REWARD_CARD, this.onShowHint, this);
	}

	public setBankWindowView(view: BankWindowView): void {
		view.on(BankWindowView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setCharacterCollectionView(view: CharacterCollectionView): void {
		view.on(CharacterCollectionView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setTotemCollectionViewSetter(view: TotemCollectionView): void {
		view.on(CharacterCollectionView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setUpgradeCollectionViewSetter(view: CollectionWithProgressBaseView): void {
		view.on(CollectionWithProgressBaseView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setFurnitureCollectionView(view: FurnitureCollectionView): void {
		view.on(FurnitureCollectionView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setSummonCollectionView(view: SummonCollectionView): void {
		view.on(SummonCollectionView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setLevelChallengeWindowView(view: LevelChallengeWindowView): void {
		view.on(LevelChallengeWindowView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setNewsWindowView(view: BaseNewsWindowView): void {
		view.on(BaseNewsWindowView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	public setDailyRewardWindowView(view: DailyWindowBaseView): void {
		view.on(DailyWindowBaseView.EVENT_SHOW_HINT, this.onShowHint, this);
	}

	private onShowHint(type: HintTypes, data: HintData): void {
		const args = this.hintDataResolver.resolve(type, data);
		this.hintSpawner.showHint(this.gameUI, args);
	}

	private onSkillHintShowed(hint: SkillHintView): void {
		if (!hint.listeners(SkillHintView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK).includes(this.onButtonCharacterFindInSummonClick)) {
			hint.on(SkillHintView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK, this.onButtonCharacterFindInSummonClick, this);
			hint.on(SkillHintView.EVENT_BUTTON_PROMOTE_CLICK, this.onButtonCharacterPromoteClick, this);
		}
	}

	private onButtonCharacterFindInSummonClick(): void {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());
		let openConfig: CollectionWindowOpenConfig;
		if (model) {
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, (collectionWindow: CollectionWindowView) => {
				collectionWindow.once(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, (summonCollectionView: SummonCollectionView) => {
					const target = summonCollectionView.getButtonSummonContainer(model.getKey());
					const show = new ShowQuestTargetView(this.questTargetDuration, target);
					target.parent.addChild(show);
				});
			});
			openConfig = {
				tab: CollectionWindowTabs.TAB_SUMMON,
				focusKey: model.getKey(),
			};
		} else {
			openConfig = { tab: CollectionWindowTabs.TAB_SUMMON };
		}
		const window = this.windowFactory.createCollectionWindow(openConfig);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonCharacterPromoteClick(data: PromotableClickData): void {
		const window = this.windowFactory.createCharacterProfileWindow(
			this.characterModels.get(data.model),
			this.skillModels.get(data.skill),
		);
		this.windowViewSystem.showWindow(window);
	}
}
