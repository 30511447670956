import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { Emitter } from 'pixi-particles';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';

export class ShowNewBonusView extends PIXI.Container {
	private viewAvailable: boolean;

	private readonly textField: MultiColoredTextField;
	private readonly bonusActivationEmitters: Emitter[];

	private textFieldFadeOutTween: TWEEN.Tween;

	private showTextQueue: string[];
	private showDelayTimerId: number;
	private ticker: PIXI.ticker.Ticker;
	private tweenGroup: TWEEN.Group;

	constructor() {
		super();

		this.visible = false;
		this.viewAvailable = true;
		this.bonusActivationEmitters = [];
		this.showTextQueue = [];

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.tweenGroup = new TWEEN.Group();

		this.textField = new MultiColoredTextField(
			{ font: '30px wendyOneShadowBold', align: 'center' },
			500,
			100,
		);
		this.textField.anchor = 0.5;
		this.addChild(this.textField);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public show(text: string): ShowNewBonusView {
		this.visible = true;

		if (this.viewAvailable) {
			this.viewAvailable = false;
			this.playAnimation(text);
		} else {
			this.showTextQueue.push(text);
		}

		return this;
	}

	private showNext(): void {
		if (this.showTextQueue.length > 0) {
			const text: string = this.showTextQueue.shift();
			this.show(text);
		}
	}

	private showAndAnimateBonus(): void {
		this.showDelayTimerId = null;

		const fadeEmitterLeft = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow2']],
			BusinessAnimationsConfig.getBusinessGlow2(),
		);
		this.bonusActivationEmitters.push(fadeEmitterLeft);
		fadeEmitterLeft.playOnceAndDestroy();
		fadeEmitterLeft.updateOwnerPos(0, -20);

		const fadeEmitterRight = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow2']],
			BusinessAnimationsConfig.getBusinessGlow2(),
		);
		this.bonusActivationEmitters.push(fadeEmitterRight);
		fadeEmitterRight.playOnceAndDestroy();
		fadeEmitterRight.updateOwnerPos(0, 20);

		const bigGlowEmitter = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_big_glow']],
			BusinessAnimationsConfig.getBusinessBigGlow(),
		);
		this.bonusActivationEmitters.push(bigGlowEmitter);
		bigGlowEmitter.playOnceAndDestroy();

		this.textFieldFadeOutTween = new TWEEN.Tween(this.textField, this.tweenGroup)
			.to({ alpha: 0 }, 500)
			.easing(TWEEN.Easing.Quadratic.In)
			.onComplete(() => {
				this.visible = false;
				this.viewAvailable = true;
				this.showNext();
			})
			.delay(1000);

		new TWEEN.Tween(this.textField.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 250)
			.easing(TWEEN.Easing.Cubic.In)
			.chain(this.textFieldFadeOutTween)
			.start();
	}

	private playAnimation(text: string): void {
		this.textField.alpha = 1;
		this.textField.text = text;
		this.textField.scale.set(0);


		const glowEmitterLeft = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow3']],
			BusinessAnimationsConfig.getBusinessGlow3(),
		);
		this.bonusActivationEmitters.push(glowEmitterLeft);
		glowEmitterLeft.playOnceAndDestroy();

		const glowEmitterRight = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow3']],
			BusinessAnimationsConfig.getBusinessGlow4(),
		);
		this.bonusActivationEmitters.push(glowEmitterRight);
		glowEmitterRight.playOnceAndDestroy();

		this.showDelayTimerId = window.setTimeout(() => {
			this.showAndAnimateBonus();
		}, 400);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);
		this.ticker = null;

		this.tweenGroup.removeAll();

		if (this.showDelayTimerId) {
			window.clearTimeout(this.showDelayTimerId);
		}

		this.bonusActivationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
