import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class BackToGameButtonClaimView extends ButtonBaseView {
	constructor(cost: number, multiplier: number, totalIncome: SoftMoneyNumber) {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 308;
		buttonBg.height = 84;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const profitStringLabel: string = multiplier.toString();
		const totalIncomeString: string = totalIncome.toString();
		const priceString: string = cost.toString();

		const iconGem = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_hard_money']);
		iconGem.scale.set(0.3);

		const profitString = LocalizationStorage.getInstance().getLocalizedString('#get_value_for_label')
			.replace('{{value}}', profitStringLabel);
		const profitText = new SizeableBitmapText(profitString, 250, { font: '20px wendyOne', tint: 0x203f0a });
		profitText.anchor = 0.5;


		const priceText = new SizeableBitmapText(priceString, 250, { font: '20px wendyOne', tint: 0x203f0a });
		priceText.anchor = new PIXI.Point(0, 0.5);

		profitText.position.set(-iconGem.width / 2 - priceText.width / 2 - 4, -25);
		iconGem.position.set(profitText.x + profitText.width / 2 + 15, -25);
		priceText.position.set(iconGem.x + 14, -25);

		const iconSoftMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']);
		iconSoftMoney.scale.set(0.44);

		const totalIncomeText = new SizeableBitmapText(totalIncomeString, 250, { font: '40px wendyOneShadowBold' });
		totalIncomeText.anchor = 0.5;

		totalIncomeText.position.set(iconSoftMoney.width / 2, 7);
		iconSoftMoney.position.set(totalIncomeText.x - totalIncomeText.width / 2 - 25, 10);

		super(buttonBg);

		this.addChild(
			profitText,
			iconGem,
			priceText,
			totalIncomeText,
			iconSoftMoney as PIXI.DisplayObject,
		);
	}
}
