import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventTimerView extends PIXI.Container {
	private readonly timerText: PIXI.extras.BitmapText;

	constructor() {
		super();

		const timerLabelBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['blue_panel'], 10, 0, 10, 0);
		timerLabelBg.width = 185;
		timerLabelBg.pivot.set(timerLabelBg.width / 2, timerLabelBg.height / 2);
		timerLabelBg.tint = 0x000000;

		this.timerText = new PIXI.extras.BitmapText('', { font: '22px wendyOneShadowBold' });
		this.timerText.anchor = 0.5;
		this.timerText.position.set(14, -3);

		this.addChild(
			timerLabelBg,
			this.timerText as PIXI.DisplayObject,
		);
	}

	public setTextTimer(value: string): void {
		this.timerText.text = value;
	}
}
