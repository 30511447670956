import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110016View } from '@views/characters/events/event11/EventCharacter110016View';
import { EventCharacter110017View } from '@views/characters/events/event11/EventCharacter110017View';
import { EventCharacter110018View } from '@views/characters/events/event11/EventCharacter110018View';

export class EventBusiness110006View extends BusinessBaseView {
	constructor() {
		const eventCharacter110016View = new EventCharacter110016View();
		const eventCharacter110017View = new EventCharacter110017View();
		const eventCharacter110018View = new EventCharacter110018View();

		super('business110006', [eventCharacter110016View, eventCharacter110017View, eventCharacter110018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter110016View,
			eventCharacter110018View,
			eventCharacter110017View as PIXI.DisplayObject,
		);
	}
}
