import { AbstractPromotableEntityConfig } from '@configs/AbstractPromotableEntityConfig';

export class TotemConfig extends AbstractPromotableEntityConfig {
	public static readonly FIELD_AFFECT: string = 'affect';

	public static readonly FIELDS: string[] = [
		TotemConfig.FIELD_KEY,
		TotemConfig.FIELD_BONUS,
		TotemConfig.FIELD_BONUS_VALUE,
		TotemConfig.FIELD_AFFECT,
		TotemConfig.FIELD_RARITY,
	];

	public static readonly CONFIG_FIELDS_OPTIONAL: string[] = [
	];

	private readonly affect: string;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.affect = config[TotemConfig.FIELD_AFFECT].trim();
	}

	public getAffectTarget(): string {
		return this.affect;
	}
}
