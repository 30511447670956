import { AssetsStorage } from '@main/AssetsStorage';

export class TimeskipMiniCardView extends PIXI.Container {
	constructor(iconKey: string) {
		super();

		const miniCardsBase = AssetsStorage.getAtlas('miniCardsBaseAtlas');

		const bg = new PIXI.Sprite(miniCardsBase['mini_card_boosts_bg']);
		const icon = new PIXI.Sprite(miniCardsBase[`${iconKey}_mini_card_icon`]);
		const interactiveArea = new PIXI.Graphics();
		interactiveArea.beginFill(0xffffff, 0);
		interactiveArea.drawRect(0, 0, 79, 0);
		interactiveArea.endFill();

		this.addChild(
			bg,
			icon,
			interactiveArea as PIXI.DisplayObject,
		);
	}
}
