import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40010View } from '@views/characters/events/event4/EventCharacter40010View';
import { EventCharacter40011View } from '@views/characters/events/event4/EventCharacter40011View';
import { EventCharacter40012View } from '@views/characters/events/event4/EventCharacter40012View';

export class EventBusiness40004View extends BusinessBaseView {
	constructor() {
		const eventCharacter40010View = new EventCharacter40010View();
		const eventCharacter40011View = new EventCharacter40011View();
		const eventCharacter40012View = new EventCharacter40012View();

		super('business40004', [eventCharacter40010View, eventCharacter40011View, eventCharacter40012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor1']).setTransform(-286, 60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor2']).setTransform(249, 67, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor3']).setTransform(-265, -30, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter40012View,
			eventCharacter40010View as PIXI.DisplayObject,
			eventCharacter40011View,
		);
	}
}
