import { BaseAction } from '../BaseAction';

export class BusinessIncomeProgressAction extends BaseAction {
	constructor(
		businessId: string,
		progress: number,
	) {
		super('BusinessUpdateProgress');

		this.data.id = businessId;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.income_progress = progress;
	}
}
