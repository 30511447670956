import { TotemModel } from '@models/TotemModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableBaseCardView } from '@views/ui/cards/PromotableBaseCardView';
import { TotemCardUnlockInfoView } from '@views/ui/cards/TotemCardUnlockInfoView';

export class PrepartyTotemLockCardView extends PromotableBaseCardView<TotemModel> {
	constructor(
		model: TotemModel,
		currentLevel: number,
	) {
		super(
			model,
			false,
		);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_totem_card']);
		bg.position.set(0, 0);
		bg.scale.set(2.35);
		this.addChildAt(bg, 0);

		this.progressBar.visible = false;
		this.stickerNewContainer.visible = false;
		this.nameLabel.visible = false;
		this.rarityBg.visible = false;
		this.rarityLabel.visible = false;

		this.interactive = false;
		this.interactiveChildren = false;

		const unlockInfoView = new TotemCardUnlockInfoView(model, currentLevel);
		unlockInfoView.position.set(0, 386);
		this.addChild(unlockInfoView);
	}
}
