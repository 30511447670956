import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter52007View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52007View';
import { EventCharacter52008View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52008View';
import { EventCharacter52009View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52009View';

export class EventBusiness52003View extends BusinessBaseView {
	constructor() {
		const eventCharacter52007View = new EventCharacter52007View();
		const eventCharacter52008View = new EventCharacter52008View();
		const eventCharacter52009View = new EventCharacter52009View();

		super('business52003', [eventCharacter52007View, eventCharacter52008View, eventCharacter52009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter52009View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter52007View as PIXI.DisplayObject,
			eventCharacter52008View,
		);
	}
}
