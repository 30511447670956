import { DefaultHintViewData, HintDefaultView } from '../hints/HintDefaultView';
import { BankHintViewData, BankHintView } from '@views/windows/bank/BankHintView';
import { SkillHintView, SkillHintViewData } from '@views/ui/hints/SkillHintView';
import {
	EventLevelCharacterSkillAutoBusinessHintView,
	EventLevelCharacterSkillAutoBusinessHintData,
} from '../hints/EventLevelCharacterSkillAutoBusinessHintView';
import { EventLevelCharacterUpgradeDefaultHintView, EventCharacterUpgradeDefaultData } from '../hints/EventLevelCharacterUpgradeDefaultHintView';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { GameConstants } from '@src/utils/GameConstants';
import { BankInfoHintView, BankInfoHintData } from '../hints/BankInfoHintView';

export enum HintViewType {
	DEFAULT = 'default',
	BANK = 'bank',
	SKILL = 'skill',
	EVENT_CHARACTER = 'event_character',
	EVENT_CHARACTER_SKILL_AUTO_BUSINESS = 'event_character_skill_auto_business',
	EVENT_UPGRADE = 'event_upgrade',
	EVENT_TOTEM = 'event_totem',
	BANK_INFO = 'bank_info',
}

export type HintViewDatas =
	DefaultHintViewData |
	BankHintViewData |
	BankInfoHintData |
	SkillHintViewData |
	EventLevelCharacterSkillAutoBusinessHintData |
	EventCharacterUpgradeDefaultData;

export type GameUIShowHintEventArgs = {
	data: HintViewDatas;
	origin: PIXI.DisplayObject;
	arrowPosLocal: PIXI.Point;
	viewType?: HintViewType;
	runInEpisodeAssets?: boolean;
};

export class ShowHintSpawner extends PIXI.utils.EventEmitter {
	public static readonly EVENT_HINT_BUSINESS_SLOT_CHARACTER_SHOWED: symbol = Symbol();
	public static readonly EVENT_HINT_BUSINESS_SLOT_UPGRADE_SHOWED: symbol = Symbol();
	public static readonly EVENT_HINT_SKILL_SHOWED: symbol = Symbol();

	private readonly runInEpisodeAssetsSandbox: SandboxOperation;

	private baseContainer: PIXI.Container;
	private readonly interactionManager: PIXI.interaction.InteractionManager;

	private hintOrigin: PIXI.DisplayObject;
	private readonly hintBlackout: PIXI.Graphics;

	private hintViewDefault: HintDefaultView;
	private hintViewBank: BankHintView;
	private hintViewSkill: SkillHintView;
	private hintViewBankInfo: BankInfoHintView;
	private hintViewEventCharacterSkillAutoBusiness: EventLevelCharacterSkillAutoBusinessHintView;
	private hintViewEventCharacterUpgradeDefault: EventLevelCharacterUpgradeDefaultHintView;

	private currentShownHint: PIXI.Container;

	constructor(
		interactionManager: PIXI.interaction.InteractionManager,
		runInEpisodeAssetsSandbox?: SandboxOperation,
	) {
		super();

		this.interactionManager = interactionManager;
		this.runInEpisodeAssetsSandbox = runInEpisodeAssetsSandbox;

		this.hintBlackout = new PIXI.Graphics();
		this.hintBlackout.interactive = true;
		this.hintBlackout.on('pointertap', this.onHintBlackoutClick, this);

		this.onRemoveCurrentHintOnWheel = this.onRemoveCurrentHintOnWheel.bind(this);
	}

	public getCurrentHint(): PIXI.Container {
		return this.currentShownHint;
	}

	public showHint(container: PIXI.Container, args: GameUIShowHintEventArgs): void {
		if (this.hintOrigin !== args.origin) {
			this.baseContainer = container;
			this.hintOrigin = args.origin;

			if (args.runInEpisodeAssets) {
				this.runInEpisodeAssetsSandbox.start(() => this.initHint(args, true));
			} else {
				this.initHint(args);
			}

			this.hintBlackout.clear();
			this.hintBlackout.beginFill(0);
			this.hintBlackout.alpha = 0;
			this.hintBlackout.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
			this.hintBlackout.endFill();

			this.baseContainer.addChild(this.hintBlackout);
			this.baseContainer.addChild(this.currentShownHint);
		}
	}

	public removeHintIfAny(): void {
		if (this.hintOrigin) {
			this.removeHint();
		}
	}

	private initHint(args: GameUIShowHintEventArgs, dropCache?: boolean): void {
		const viewType = args.viewType || HintViewType.DEFAULT;
		// eslint-disable-next-line default-case
		switch (viewType) {
			case HintViewType.DEFAULT: {
				if (this.hintViewDefault && dropCache) {
					this.hintViewDefault.destroy();
					this.hintViewDefault = null;
				}
				if (!this.hintViewDefault) {
					this.hintViewDefault = new HintDefaultView();
				}

				this.initHintDefault(args.data as DefaultHintViewData, args.arrowPosLocal);
				this.removeCurrentHintOnWheel();
				break;
			}
			case HintViewType.BANK_INFO: {
				if (this.hintViewBankInfo && dropCache) {
					this.hintViewBankInfo.destroy();
					this.hintViewBankInfo = null;
				}
				if (!this.hintViewBankInfo) {
					this.hintViewBankInfo = new BankInfoHintView();
				}
				this.initHintBankInfo(args.data as BankInfoHintData, args.arrowPosLocal);
				this.removeCurrentHintOnWheel();
				break;
			}

			case HintViewType.BANK: {
				if (this.hintViewBank && dropCache) {
					this.hintViewBank.destroy();
					this.hintViewBank = null;
				}
				if (!this.hintViewBank) {
					this.hintViewBank = new BankHintView();
				}

				this.initHintBank(args.data as BankHintViewData, args.arrowPosLocal);
				this.removeCurrentHintOnWheel();
				break;
			}
			case HintViewType.SKILL: {
				if (this.hintViewSkill && dropCache) {
					this.hintViewSkill.destroy();
					this.hintViewSkill = null;
				}
				if (!this.hintViewSkill) {
					this.hintViewSkill = new SkillHintView();
				}

				this.initHintSkill(args.data as SkillHintViewData, args.arrowPosLocal);
				this.removeCurrentHintOnWheel();
				break;
			}
			case HintViewType.EVENT_TOTEM:
			case HintViewType.EVENT_UPGRADE:
			case HintViewType.EVENT_CHARACTER: {
				if (this.hintViewEventCharacterUpgradeDefault && dropCache) {
					this.hintViewEventCharacterUpgradeDefault.destroy();
					this.hintViewEventCharacterUpgradeDefault = null;
				}
				if (!this.hintViewEventCharacterUpgradeDefault) {
					this.hintViewEventCharacterUpgradeDefault = new EventLevelCharacterUpgradeDefaultHintView();
				}

				this.initHintEventCharacterUpgradeDefault(
					args.data as EventCharacterUpgradeDefaultData,
					args.arrowPosLocal,
				);
				this.removeCurrentHintOnWheel();
				break;
			}
			case HintViewType.EVENT_CHARACTER_SKILL_AUTO_BUSINESS: {
				if (this.hintViewEventCharacterSkillAutoBusiness && dropCache) {
					this.hintViewEventCharacterSkillAutoBusiness.destroy();
					this.hintViewEventCharacterSkillAutoBusiness = null;
				}
				if (!this.hintViewEventCharacterSkillAutoBusiness) {
					this.hintViewEventCharacterSkillAutoBusiness = new EventLevelCharacterSkillAutoBusinessHintView();
				}

				this.initHintEventCharacterSkillAutoBusiness(
					args.data as EventLevelCharacterSkillAutoBusinessHintData,
					args.arrowPosLocal,
				);
				this.removeCurrentHintOnWheel();
				break;
			}
		}
	}

	private setHintPosition(localPositionOffset: PIXI.Point): void {
		const hintOriginGlobalPosition = this.hintOrigin.toGlobal(this.baseContainer.position);

		this.currentShownHint.position.set(
			hintOriginGlobalPosition.x + localPositionOffset.x,
			hintOriginGlobalPosition.y + localPositionOffset.y,
		);
	}

	private initHintBankInfo(data: BankInfoHintData, arrowPosLocal: PIXI.Point): void {
		this.currentShownHint = this.hintViewBankInfo;
		this.setHintPosition(arrowPosLocal);
		this.hintViewBankInfo.init(data);
		this.hintViewBankInfo.startOpenAnimation();
	}

	private initHintBank(data: BankHintViewData, arrowPosLocal: PIXI.Point): void {
		this.currentShownHint = this.hintViewBank;

		this.setHintPosition(arrowPosLocal);
		this.hintViewBank.init(data);
		this.hintViewBank.startOpenAnimation();
	}

	private initHintDefault(data: DefaultHintViewData, arrowPosLocal: PIXI.Point): void {
		this.currentShownHint = this.hintViewDefault;

		this.setHintPosition(arrowPosLocal);
		this.hintViewDefault.init(data);
		this.hintViewDefault.startOpenAnimation();
	}

	private initHintSkill(data: SkillHintViewData, arrowPosLocal: PIXI.Point): void {
		this.currentShownHint = this.hintViewSkill;

		this.setHintPosition(arrowPosLocal);
		this.hintViewSkill.init(data);
		this.hintViewSkill.startOpenAnimation();
		if (!this.hintViewSkill.listeners(SkillHintView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK).includes(this.removeHint)) {
			this.hintViewSkill.once(SkillHintView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK, this.removeHint, this);
		}
		if (!this.hintViewSkill.listeners(SkillHintView.EVENT_BUTTON_PROMOTE_CLICK).includes(this.removeHint)) {
			this.hintViewSkill.once(SkillHintView.EVENT_BUTTON_PROMOTE_CLICK, this.removeHint, this);
		}
		this.emit(ShowHintSpawner.EVENT_HINT_SKILL_SHOWED, this.hintViewSkill);
	}

	private initHintEventCharacterSkillAutoBusiness(
		args: EventLevelCharacterSkillAutoBusinessHintData,
		arrowPosLocal: PIXI.Point,
	): void {
		this.currentShownHint = this.hintViewEventCharacterSkillAutoBusiness;

		this.setHintPosition(arrowPosLocal);
		this.hintViewEventCharacterSkillAutoBusiness.init(args);
		this.hintViewEventCharacterSkillAutoBusiness.startOpenAnimation();
	}

	private initHintEventCharacterUpgradeDefault(
		args: EventCharacterUpgradeDefaultData,
		arrowPosLocal: PIXI.Point,
	): void {
		this.currentShownHint = this.hintViewEventCharacterUpgradeDefault;

		this.setHintPosition(arrowPosLocal);
		this.hintViewEventCharacterUpgradeDefault.init(args);
		this.hintViewEventCharacterUpgradeDefault.startOpenAnimation();
	}

	private removeCurrentHintOnWheel(): void {
		window.addEventListener('wheel', this.onRemoveCurrentHintOnWheel);
	}

	private onRemoveCurrentHintOnWheel(): void {
		window.removeEventListener('wheel', this.onRemoveCurrentHintOnWheel);
		this.removeHint();
	}

	private onHintBlackoutClick(event: PIXI.interaction.InteractionEvent): void {
		const found = this.interactionManager.hitTest(event.data.global, this.baseContainer.parent);

		const containsHint = this.currentShownHint.getBounds().contains(event.data.global.x, event.data.global.y);
		const containsOrigin = this.hintOrigin?.getBounds().contains(event.data.global.x, event.data.global.y);

		this.removeHint();

		if (found !== null && !containsHint && !containsOrigin) {
			found.emit('pointertap', event);
		}
	}

	private removeHint(): void {
		this.hintOrigin = null;

		window.removeEventListener('wheel', this.onRemoveCurrentHintOnWheel);

		this.baseContainer.removeChild(this.hintBlackout);
		this.baseContainer.removeChild(this.currentShownHint);
	}

	public destroy(): void {
		window.removeEventListener('wheel', this.onRemoveCurrentHintOnWheel);
	}
}
