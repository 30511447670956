import { AssetsStorage } from '@main/AssetsStorage';
import { SoundController } from '@src/main/SoundController';
import { SettingsPanelItemUsernameView } from './panelItems/SettingsPanelItemUsernameView';
import { SettingsPanelItemWithSliderView } from './panelItems/SettingsPanelItemWithSliderView';
import { SettingsPanelItemLanguageItemView } from './panelItems/SettingsPanelItemLanguageItemView';
import { SettingsPanelItemWithButtonView } from './panelItems/SettingsPanelItemWithButtonView';
import { SettingsPanelItemCardpayView } from './panelItems/SettingsPanelItemCardpayView';
import { Container } from 'pixi.js';

export class SettingsPanelView extends PIXI.Container {
	public static readonly EVENT_CHANGE_LANGUAGE: symbol = Symbol();
	public static readonly EVENT_CHANGE_SOUNDS_VOLUME: symbol = Symbol();
	public static readonly EVENT_CHANGE_MUSIC_VOLUME: symbol = Symbol();
	public static readonly EVENT_CHANGE_USERNAME: symbol = Symbol();
	public static readonly EVENT_CHANGE_CARDPAY: symbol = Symbol();

	public static readonly EVENT_SUPPORT_BUTTON_CLICK: symbol = Symbol();

	private rowUserName: SettingsPanelItemUsernameView;
	private rowCardpay: SettingsPanelItemCardpayView;
	private rowMusic: SettingsPanelItemWithSliderView;
	private rowSound: SettingsPanelItemWithSliderView;
	private rowLanguage: SettingsPanelItemLanguageItemView;
	private rowSupport: SettingsPanelItemWithButtonView;
	private rowBillingSupport: SettingsPanelItemWithButtonView;
	private rowLabelId: Container;
	private rows: PIXI.DisplayObject[];
	private rowsElementsOffsetY: number;

	constructor(
		supportLanuageMap: Map<string, string>,
		redeemCode: string,
	) {
		super();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		bg.width = 782;
		bg.height = 673;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const iconOffsetX = -330;
		const labelOffsetX = -280;
		const buttonOffset = 195;
		this.rowsElementsOffsetY = 84;

		this.rowUserName = new SettingsPanelItemUsernameView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_user_name',
			'#type_your_name_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['user_icon']),
			true,
		);
		this.rowUserName.y = -274;
		this.rowUserName.on(
			SettingsPanelItemUsernameView.EVENT_SUBMIT_INPUT,
			(value: string) => this.emit(SettingsPanelView.EVENT_CHANGE_USERNAME, value),
			this,
		);

		this.rowMusic = new SettingsPanelItemWithSliderView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_music_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['music_icon']),
		);
		this.rowMusic.y = this.rowUserName.y + this.rowsElementsOffsetY;
		this.rowMusic.setToggle(SoundController.getInstance().getMainBgVolume());
		this.rowMusic.on(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG, (value: number) => {
			SoundController.getInstance().setMusicVolume(value);
		}, this);
		this.rowMusic.on(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG_END, (value: number) => {
			this.emit(SettingsPanelView.EVENT_CHANGE_MUSIC_VOLUME, value);
		}, this);

		this.rowSound = new SettingsPanelItemWithSliderView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_sound_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['sound_icon']),
		);
		this.rowSound.y = this.rowMusic.y + this.rowsElementsOffsetY;
		this.rowSound.setToggle(SoundController.getInstance().getSoundsVolume());
		this.rowSound.on(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG, (value: number) => {
			SoundController.getInstance().setSoundsVolume(value);
		}, this);
		this.rowSound.on(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG_END, (value: number) => {
			this.emit(SettingsPanelView.EVENT_CHANGE_SOUNDS_VOLUME, value);
		}, this);

		this.rowLanguage = new SettingsPanelItemLanguageItemView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_language_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['language_icon']),
			supportLanuageMap,
		);
		this.rowLanguage.y = this.rowSound.y + this.rowsElementsOffsetY;
		this.rowLanguage.on(
			SettingsPanelItemLanguageItemView.EVENT_CHANGE_LANGUAGE,
			(lang: string) => this.emit(SettingsPanelView.EVENT_CHANGE_LANGUAGE, lang),
			this,
		);

		this.rowCardpay = new SettingsPanelItemCardpayView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#setting_payment_method_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['cardpay_icon']),
		);
		this.rowCardpay.y = this.rowLanguage.y + this.rowsElementsOffsetY;
		this.rowCardpay.on(SettingsPanelItemCardpayView.EVENT_EPOCH_BUTTON_CLICK, () => {
			this.emit(SettingsPanelView.EVENT_CHANGE_CARDPAY, false);
			this.updateRow(true);
		});
		this.rowCardpay.on(SettingsPanelItemCardpayView.EVENT_CARDPAY_BUTTON_CLICK, () => {
			this.emit(SettingsPanelView.EVENT_CHANGE_CARDPAY, true);
			this.updateRow(false);
		});

		this.rowBillingSupport = new SettingsPanelItemWithButtonView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_billing_support_label',
			'#settings_button_submit_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['support_icon']),
		);
		this.rowBillingSupport.y = this.rowCardpay.y + this.rowsElementsOffsetY;
		this.rowBillingSupport.on(SettingsPanelItemWithButtonView.EVENT_BUTTON_CLICK, () => {
			window.open('https://epoch.com/billing_support/');
		});

		this.rowSupport = new SettingsPanelItemWithButtonView(
			iconOffsetX,
			labelOffsetX,
			buttonOffset,
			'#settings_support_label',
			'#settings_button_support_label',
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['support_icon']),
		);
		this.rowSupport.y = this.rowBillingSupport.y + this.rowsElementsOffsetY;
		this.rowSupport.on(SettingsPanelItemWithButtonView.EVENT_BUTTON_CLICK, () => {
			this.emit(SettingsPanelView.EVENT_SUPPORT_BUTTON_CLICK);
		});

		this.rowLabelId = SettingsPanelView.createLabelId(redeemCode);
		this.rowLabelId.position.set(buttonOffset, this.rowSupport.y + 60);

		this.addChild(
			bg,
			this.rowUserName,
			this.rowMusic,
			this.rowSound,
			this.rowLanguage,
			this.rowCardpay,
			this.rowBillingSupport,
			this.rowSupport,
			this.rowLabelId,
		);
	}

	private static createLabelId(id: string): PIXI.Container {
		const container = new PIXI.Container();
		container.height = 100;
		const idLabel = new PIXI.extras.BitmapText(
			`ID: ${id}`,
			{ font: { size: 28, name: 'wendyOne' } },
		);
		idLabel.anchor = 0.5;
		idLabel.tint = 0x465768;

		container.addChild(
			idLabel,
		);
		return container;
	}

	public updateRow(value: boolean): void {
		if (value) {
			this.addChild(this.rowBillingSupport);
			this.rowSupport.y = this.rowBillingSupport.y + this.rowsElementsOffsetY;
			this.rowLabelId.y = this.rowSupport.y + 60;
		} else {
			this.removeChild(this.rowBillingSupport);
			this.rowSupport.y = this.rowCardpay.y + this.rowsElementsOffsetY;
			this.rowLabelId.y = this.rowSupport.y + 60;
		}
	}

	public setCardpay(value: boolean): void {
		this.rowCardpay.setCardpay(value);
		this.updateRow(!value);
	}

	public setUsername(username: string): void {
		this.rowUserName.setUsername(username);
	}
}
