import { TotemModel } from '@models/TotemModel';
import { TotemCardView } from '@views/ui/cards/TotemCardView';
import { AbstractCollectionItemView } from '@views/windows/collection/AbstractCollectionItemView';
import { TotemCardLockView } from '@views/windows/collection/totems/TotemCardLockView';
import { PromotableBaseCardView } from '@views/ui/cards/PromotableBaseCardView';

export class TotemCollectionsCardView extends AbstractCollectionItemView {
	private readonly model: TotemModel;

	private lockView: TotemCardLockView;
	protected openView: TotemCardView;

	constructor(
		model: TotemModel,
	) {
		super();

		this.model = model;

		if (this.model.isOpened()) {
			this.onOpened();
		} else {
			this.model.once(TotemModel.EVENT_OPENED, this.onOpened, this);
			this.lockView = new TotemCardLockView(model.getCardRarity());
			this.addChild(this.lockView);
		}
	}

	protected showOpened(): void {
		this.openView = new TotemCardView(
			this.model,
		);
		this.openView.scale.set(0.435);
		this.addChildAt(this.openView, 0);

		if (this.lockView) {
			this.removeChild(this.lockView);
			this.lockView = null;
		}

		this.openView.on(PromotableBaseCardView.EVENT_CLICK, this.onOpenCardClick, this);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(TotemModel.EVENT_OPENED, this.showOpened, this, true);
		super.destroy(options);
	}
}
