import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export class PrepartyFuckTimeView extends PIXI.Container {
	private readonly textFucktime: MultiColoredTextField;

	constructor(
	) {
		super();

		const bgMask = new PIXI.Graphics();
		bgMask.beginFill(0xFFFFFF);
		bgMask.drawPolygon([
			new PIXI.Point(-192, -25),
			new PIXI.Point(171, -25),
			new PIXI.Point(163, 25),
			new PIXI.Point(-200, 25),
		]);


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['gold_panel'], 14, 0, 14, 0);
		bg.width = 420;
		bg.height = 52;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.mask = bgMask;

		this.textFucktime = new MultiColoredTextField(
			{ font: '32px wendyOneShadowBold', align: 'center' },
			280,
			40,
		);
		this.textFucktime.anchor = 0.5;
		this.textFucktime.x = 18;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']);
		icon.scale.set(0.66);
		icon.position.set(-148, -6);

		this.addChild(
			bg,
			bgMask,
			icon,
			this.textFucktime as PIXI.DisplayObject,
		);
	}

	public setText(text: string): void {
		this.textFucktime.text = text;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		super.destroy(options);
	}
}
