import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100007View } from '@views/characters/events/event10/EventCharacter100007View';
import { EventCharacter100008View } from '@views/characters/events/event10/EventCharacter100008View';
import { EventCharacter100009View } from '@views/characters/events/event10/EventCharacter100009View';

export class EventBusiness100003View extends BusinessBaseView {
	constructor() {
		const eventCharacter100007View = new EventCharacter100007View();
		const eventCharacter100008View = new EventCharacter100008View();
		const eventCharacter100009View = new EventCharacter100009View();

		super('business100003', [eventCharacter100007View, eventCharacter100008View, eventCharacter100009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter100007View as PIXI.DisplayObject,
			eventCharacter100008View,
			eventCharacter100009View,
		);
	}
}
