import { BaseAction } from '../BaseAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class QuestCompleteAction extends BaseAction {
	constructor(
		questId: string,
		questLine: string,
		questLineProgress: number,
		rewards: RewardDescriptionType[],
	) {
		super('QuestComplete');

		this.data.id = questId;
		this.data.quest_line = questLine;
		this.data.line_number = questLineProgress;
		this.data.rewards = rewards;
	}
}
