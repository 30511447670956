import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';
import { FarewellPartyEffectsConfigs } from '@views/windows/farewellParty/FarewellPartyEffectsConfigs';
import * as TWEEN from '@tweenjs/tween.js';

export class FarewellPartyAimView extends PIXI.Container {
	public static readonly EVENT_TAP: symbol = Symbol();
	public static readonly EVENT_COMPLETED: symbol = Symbol();
	public static readonly SCALE_CLICK_INCREASE: number = 0.1;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly animationEmitters: Emitter[];
	private readonly aimContainer: PIXI.Container;
	private readonly clickTarget: PIXI.Graphics;
	private currentScaleTarget: number;
	private currentTaps: number;
	private readonly maxTaps: number;

	constructor(
		maxTaps: number,
	) {
		super();
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
		this.animationEmitters = [];

		this.maxTaps = maxTaps;
		this.currentScaleTarget = 1;
		this.currentTaps = 0;
		const initialScale = 1;

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.aimContainer = new PIXI.Container();

		const bg = new PIXI.Sprite(fxAtlas['orgy_target']);
		bg.scale.set(0.7);
		bg.blendMode = PIXI.BLEND_MODES.SCREEN;

		this.clickTarget = new PIXI.Graphics();
		this.clickTarget.beginFill(0xe74c3c, 0);
		this.clickTarget.drawCircle(0, 0, 110);
		this.clickTarget.endFill();
		this.clickTarget.on('pointertap', this.onTap, this);

		const sparksEmitter = new Emitter(
			this.aimContainer,
			[fxAtlas['business_glow4']],
			FarewellPartyEffectsConfigs.SPARKS,
		);
		sparksEmitter.autoUpdate = true;
		this.animationEmitters.push(sparksEmitter);

		const lightsContainer = new PIXI.Container();
		lightsContainer.scale.set(0.25);

		const glowLightsEmitter = new Emitter(
			lightsContainer,
			[fxAtlas['party_button_glow4']],
			FarewellPartyEffectsConfigs.GLOW4,
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		this.aimContainer.addChild(
			bg,
			lightsContainer,
			this.clickTarget,
		);

		this.addChild(
			this.aimContainer as PIXI.DisplayObject,
		);

		this.aimContainer.scale.set(0);
		new TWEEN.Tween(this.aimContainer.scale, this.tweenGroup)
			.to({ x: initialScale, y: initialScale }, 300)
			.easing(TWEEN.Easing.Sinusoidal.Out)
			.start();
	}

	public getClickTarget(): PIXI.Container {
		return this.clickTarget;
	}

	private playBurstAnimation(): void {
		const burst1Emitter = new Emitter(
			this.aimContainer,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow1']).texture,
			],
			FarewellPartyEffectsConfigs.AIM_GLOW1,
		);
		burst1Emitter.autoUpdate = true;
		burst1Emitter.playOnceAndDestroy();
		this.animationEmitters.push(burst1Emitter);

		const burst2Emitter = new Emitter(
			this.aimContainer,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['pary_glow_2']).texture,
			],
			FarewellPartyEffectsConfigs.AIM_GLOW2,
		);
		burst2Emitter.autoUpdate = true;
		burst2Emitter.playOnceAndDestroy();
		this.animationEmitters.push(burst2Emitter);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setTapEnabled(value: boolean): void {
		this.clickTarget.interactive = value;
	}

	private onTap(e: PIXI.interaction.InteractionEvent): void {
		this.currentScaleTarget += FarewellPartyAimView.SCALE_CLICK_INCREASE;
		this.addTapEffect();
		this.startGrowAnimation();
		this.emit(FarewellPartyAimView.EVENT_TAP, e);

		this.currentTaps += 1;
		if (this.currentTaps >= this.maxTaps) {
			this.clickTarget.off('pointertap', this.onTap, this);
			this.emit(FarewellPartyAimView.EVENT_COMPLETED);
		}
	}

	private startGrowAnimation(): void {
		this.tweenGroup.removeAll();

		new TWEEN.Tween(this.aimContainer.scale, this.tweenGroup)
			.to({ x: this.currentScaleTarget, y: this.currentScaleTarget }, 300)
			.easing(TWEEN.Easing.Elastic.Out)
			.start();
	}

	public startHideAnimation(): void {
		this.tweenGroup.removeAll();

		const duration = 200;
		const scaleMult = 1.3;

		new TWEEN.Tween(this, this.tweenGroup)
			.to({ alpha: 0 }, duration)
			.easing(TWEEN.Easing.Linear.None)
			.start();

		new TWEEN.Tween(this.aimContainer.scale, this.tweenGroup)
			.to({ x: this.currentScaleTarget * scaleMult, y: this.currentScaleTarget * scaleMult }, duration)
			.easing(TWEEN.Easing.Elastic.Out)
			.start();

		const destroyTimeout = 1000;
		new TWEEN.Tween(this, this.tweenGroup)
			.to({}, destroyTimeout)
			.onComplete(() => {
				this.destroy({ children: true });
			})
			.start();

		this.playBurstAnimation();
	}

	private addTapEffect(): void {
		const sparksEmitter = new Emitter(
			this,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow4']],
			FarewellPartyEffectsConfigs.SPARKS_AIM_CLICK,
		);
		sparksEmitter.autoUpdate = true;
		this.animationEmitters.push(sparksEmitter);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
