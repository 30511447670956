import { DualStateButtonBaseView } from '@views/components/buttons/DualStateButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class ButtonMuteView extends DualStateButtonBaseView {
	constructor() {
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle']);
		bg.scale.set(0.8);
		bg.alpha = 0.7;
		bg.tint = 0x000000;

		super(
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['preloader_volume_on']),
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['preloader_volume_off']),
		);

		this.addChildAt(bg, 0);
	}
}
