export type EnvDataType = {
	url: string;
	name: string;
}

export type EnvType = 'alias' | 'url';

export class EnvNames {
	public static getEnvType(env: string): EnvType {
		if (env.indexOf('http') !== -1) {
			return 'url';
		}
		return 'alias';
	}

	public static getEnvUrlNameMap(): Map<string, string> {
		const a = Object.entries({
			release: 'https://hollywoodclicker.com',
			stage: 'https://stage.hollywoodclicker.com',
			stage2: 'https://stage2.hollywoodclicker.com',
			dev1: 'https://dev1.hollywoodclicker.com',
			dev2: 'https://dev2.hollywoodclicker.com',
			dev3: 'https://dev3.hollywoodclicker.com',
			dev4: 'https://dev4.hollywoodclicker.com',
			local: 'http://127.0.0.1:9000',
		});
		return new Map(a);
	}
}
