import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BonusTypes } from '@src/types/BonusTypes';
import { CardRarity } from '@src/types/CardRarities';
import { UnlockInfo, UnlockTypes } from '@src/types/UnlockTypes';

export abstract class AbstractPromotableEntityConfig implements ConfigSetElement {
	private static readonly promotableBonusTypes: BonusTypes[] = [
		BonusTypes.DECREASE_CUSTOMER_COST,
		BonusTypes.FUCKTIME,
		BonusTypes.MULTIPLIER_FUCKPOWER,
		BonusTypes.MULTIPLIER_INCOME,
		BonusTypes.MULTIPLIER_TIME,
		BonusTypes.REDUCE_TIME_INCOME_BY_CLICK,
	];

	public static readonly FIELD_KEY = 'id';
	public static readonly FIELD_BONUS = 'bonus';
	public static readonly FIELD_BONUS_VALUE = 'bonus_value';
	public static readonly FIELD_RARITY = 'rarity';
	public static readonly FIELD_UNLOCK_TYPE = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE = 'unlock_value';

	protected unlockInfo: UnlockInfo;

	private readonly key: string;
	private readonly bonuses: Map<BonusTypes, number[]>;
	private readonly rarity: CardRarity;

	protected constructor(config: Record<string, string>) {
		this.key = config[AbstractPromotableEntityConfig.FIELD_KEY].trim();
		this.bonuses = AbstractPromotableEntityConfig.parseBonuses(config);
		this.rarity = config[AbstractPromotableEntityConfig.FIELD_RARITY].trim() as CardRarity;
		this.unlockInfo = AbstractPromotableEntityConfig.parseUnlockInfo(config);
	}

	public setUnlockInfo(info: UnlockInfo): void {
		this.unlockInfo = info;
	}

	public getKey(): string {
		return this.key;
	}

	public getBonuses(): Map<BonusTypes, number[]> {
		return this.bonuses;
	}

	public getBonusValues(type: BonusTypes): number[] {
		return this.bonuses.get(type);
	}

	public getRarity(): CardRarity {
		return this.rarity;
	}

	public getUnlockInfo(): UnlockInfo {
		return this.unlockInfo;
	}

	private static parseBonuses(config: Record<string, string>): Map<BonusTypes, number[]> {
		const bonuses = new Map();
		const bonusTypes: BonusTypes[] = config[AbstractPromotableEntityConfig.FIELD_BONUS]
			.trim()
			.split(',')
			.map((v) => v.trim()) as BonusTypes[];

		const bonusValuesUnformatted: string[] = config[AbstractPromotableEntityConfig.FIELD_BONUS_VALUE].trim().split(';');

		bonusTypes.forEach((bonusType, i) => {
			if (AbstractPromotableEntityConfig.promotableBonusTypes.includes(bonusType)) {
				const bonusValues = bonusValuesUnformatted[i].split(',').map(str => Number(str.trim()));
				bonuses.set(bonusType, bonusValues);
			}
		});

		return bonuses;
	}

	private static parseUnlockInfo(config: Record<string, string>): UnlockInfo {
		const unlockTypeRaw = config[AbstractPromotableEntityConfig.FIELD_UNLOCK_TYPE].trim();
		let unlockInfo: UnlockInfo;

		switch (unlockTypeRaw) {
			case UnlockTypes.MIN_LEVEL: {
				const unlockValue = config[AbstractPromotableEntityConfig.FIELD_UNLOCK_VALUE].trim();

				unlockInfo = {
					type: UnlockTypes.MIN_LEVEL,
					value: unlockValue,
				};
				break;
			}
			case UnlockTypes.UNAVAILABLE: {
				unlockInfo = {
					type: UnlockTypes.UNAVAILABLE,
					value: '',
				};
				break;
			}
			default: {
				unlockInfo = {
					type: UnlockTypes.EVENT,
					value: '',
				};
				break;
			}
		}

		return unlockInfo;
	}
}
