import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepDataBase } from './data/TutorialStepDataBase';
import {
	TutorialStepUnlockTypeBaseConfig,
	TutorialStepUnlockTypeEventGoalCompletedConfig,
	TutorialStepUnlockTypeEventStartConfig,
} from './TutorialStepUnlockTypeConfigs';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepBaseConfig } from './TutorialStepBaseConfig';

export class TutorialStepEventLevelConfig extends TutorialStepBaseConfig {
	public static TARGET_TYPE_BUTTON_RANKING_WINDOW = 'buttonEventRankingWindow';

	public static TARGET_CLOSE_WINDOW_PARAM_EVENT_REWARDS = 'eventRewards';

	public static UNLOCK_TYPE_EVENT_START = 'eventStart';
	public static UNLOCK_TYPE_POPUP_APPEAR_EVENT_REWARDS = 'eventRewards';
	public static UNLOCK_TYPE_GOAL_COMPLETED = 'taskCompleted';

	constructor(config: Record<string, string>) {
		super(config);

		this.parseTutorialStepTargetType(config);
		this.parseTutorialStepUnlockTypes(config, this.key);
	}

	protected parseTutorialStepTargetType(config: Record<string, string>): void {
		const target = config[TutorialStepEventLevelConfig.FIELD_TARGET].trim();
		switch (target) {
			case TutorialStepEventLevelConfig.TARGET_TYPE_BUTTON_RANKING_WINDOW: {
				this.target = TutorialStepTargetTypes.EVENT_BUTTON_RANKING_WINDOW;
				this.data = new TutorialStepDataBase(config);
				break;
			}

			case TutorialStepEventLevelConfig.TARGET_TYPE_BUTTON_CLOSE_WINDOW: {
				const parameter = config[TutorialStepEventLevelConfig.FIELD_PARAMETER].trim();
				switch (parameter) {
					case TutorialStepEventLevelConfig.TARGET_CLOSE_WINDOW_PARAM_EVENT_REWARDS: {
						this.target = TutorialStepTargetTypes.EVENT_BUTTON_CLOSE_REWARDS_WINDOW;
						this.data = new TutorialStepDataBase(config);
						break;
					}

					default: {
						super.parseTutorialStepTargetType(config);
						break;
					}
				}
				break;
			}

			default: {
				super.parseTutorialStepTargetType(config);
				break;
			}
		}
	}

	protected parseTutorialStepUnlockTypes(config: Record<string, string>, key: string): void {
		const unlockType = config[TutorialStepEventLevelConfig.FIELD_UNLOCK_TYPE].trim();
		const unlockValue = config[TutorialStepEventLevelConfig.FIELD_UNLOCK_VALUE].trim();

		switch (unlockType) {
			case TutorialStepEventLevelConfig.UNLOCK_TYPE_EVENT_START: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeEventStartConfig(key, unlockValue);
				break;
			}

			case TutorialStepEventLevelConfig.UNLOCK_TYPE_POPUP_APPEAR: {
				switch (unlockValue) {
					case TutorialStepEventLevelConfig.UNLOCK_TYPE_POPUP_APPEAR_EVENT_REWARDS: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_EVENT_REWARDS);
						break;
					}

					default: {
						super.parseTutorialStepUnlockTypes(config, key);
						break;
					}
				}
				break;
			}

			case TutorialStepEventLevelConfig.UNLOCK_TYPE_GOAL_COMPLETED: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeEventGoalCompletedConfig(key, unlockValue);
				break;
			}

			default: {
				super.parseTutorialStepUnlockTypes(config, key);
				break;
			}
		}
	}
}
