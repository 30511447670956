import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter54004View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54004View';
import { EventCharacter54005View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54005View';
import { EventCharacter54006View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54006View';

export class EventBusiness54002View extends BusinessBaseView {
	constructor() {
		const eventCharacter54004View = new EventCharacter54004View();
		const eventCharacter54005View = new EventCharacter54005View();
		const eventCharacter54006View = new EventCharacter54006View();

		super('business54002', [eventCharacter54004View, eventCharacter54005View, eventCharacter54006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter54004View as PIXI.DisplayObject,
			eventCharacter54005View,
			eventCharacter54006View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
