import { AssetsStorage } from '@main/AssetsStorage';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestUIBaseView } from './QuestUIBaseView';
import { TimedQuestUIHelper } from './TimedQuestUIHelper';
import { NumberUtils } from '@src/utils/NumberUtils';

export class TimedQuestUIView<T extends AbstractQuest> extends QuestUIBaseView<T> {
	private static readonly KEY_DEFAULT_BACKGROUND_TEXTURE: string = 'side_quest_icon_bg';

	private readonly tickerTimer: PIXI.ticker.Ticker;

	private readonly timerBg: PIXI.mesh.NineSlicePlane;
	private readonly timerLabel: PIXI.extras.BitmapText;
	private readonly glowTimerBg: PIXI.mesh.NineSlicePlane;

	private isTimerRed: boolean;

	constructor() {
		const background = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')[TimedQuestUIView.KEY_DEFAULT_BACKGROUND_TEXTURE],
			17, 0, 21, 0,
		);
		background.width = 240;
		background.pivot.set(background.width / 2, background.height / 2);
		super(background);

		this.timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['black_panel'], 20, 0, 20, 0);
		this.timerBg.scale.set(0.4);
		this.timerBg.width = 250;
		this.timerBg.pivot.set(this.timerBg.width / 2, this.timerBg.height / 2);
		this.timerBg.y = -50;

		this.timerLabel = new PIXI.extras.BitmapText('', { font: '20px wendyOneShadowBold' });
		this.timerLabel.anchor = 0.5;
		this.timerLabel.y = -50;

		this.glowTimerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow'], 20, 0, 20, 0);
		this.glowTimerBg.width = this.timerBg.width - 90;
		this.glowTimerBg.height = 70;
		this.glowTimerBg.pivot.set(this.glowTimerBg.width / 2, this.glowTimerBg.height / 2);
		this.glowTimerBg.y = -50;

		this.isTimerRed = false;

		this.textDescription.y = -10;

		this.tickerTimer = PIXI.ticker.shared;
		this.tickerTimer.add(this.onUpdateTextTimer, this);

		this.progressValue.y = 33;
		this.progressBar.y = 33;

		this.addChild(
			this.glowTimerBg,
			this.timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getTextDescription(quest: AbstractQuest): string {
		return TimedQuestUIHelper.getQuestDescription(quest);
	}

	protected setBackgroundTexture(): void {
		(this.buttonBg as PIXI.mesh.NineSlicePlane).texture = this.isBossBackgroundTexture()
			? AssetsStorage.getAtlas('uiAtlas')['boss_quest_icon_bg']
			: AssetsStorage.getAtlas('uiAtlas')[TimedQuestUIView.KEY_DEFAULT_BACKGROUND_TEXTURE];
	}

	private onUpdateTextTimer(): void {
		this.timerLabel.text = NumberUtils.secToDHMSColonFormatted(this.quest.getTimeleft());
		this.updateTimerTint();
	}

	protected showCompleted(value: boolean): void {
		this.timerBg.visible = !value;
		this.timerLabel.visible = !value;
		this.glowTimerBg.visible = !value;

		super.showCompleted(value);
	}

	protected updateTimerTint(): void {
		const deltaTimeleft = this.quest.getTimeleft() / this.quest.getTime();
		if (deltaTimeleft <= 0.1 && !this.isTimerRed) {
			this.isTimerRed = true;

			this.glowTimerBg.tint = 0xe9382a;
			this.timerLabel.tint = 0xe9382a;
		} else if (deltaTimeleft >= 0.1 && this.isTimerRed) {
			this.isTimerRed = false;

			this.glowTimerBg.tint = 0xffc900;
			this.timerLabel.tint = 0xffc900;
		}
	}

	public destroy(): void {
		this.tickerTimer.remove(this.onUpdateTextTimer, this);
		super.destroy();
	}
}
