export class TextUtils {
	public static readonly PRESTIGE_SYMBOL: string = 'ऩ';
	public static readonly SOFT_MONEY_SYMBOL: string = 'प';
	public static readonly TIMER_SYMBOL: string = 'फ';
	public static readonly CUSTOMER_SYMBOL: string = 'ब';
	public static readonly LOCK_SYMBOL: string = 'भ';
	public static readonly HARD_MONEY_SYMBOL: string = 'म';
	public static readonly FUCK_POWER_SYMBOL_SYMBOL: string = 'य';
	public static readonly SOFT_MONEY_GRAY_SYMBOL: string = 'ऱ';
	public static readonly ARROW_RIGHT: string = 'र';
	public static readonly NUTAKU_SYMBOL: string = 'ल';
	public static readonly LIKE_SYBOL: string = 'ळ';
	public static readonly TAP_POWER_SYBOL: string = 'ऴ';

	public static readonly VALUE_PLACEHOLDER_START_SYMBOLS: string = '{{';

	public static readonly TAG_TIMER = '[timer]';
	public static readonly TAG_FUCKPOWER = '[fack_power]';
	public static readonly TAG_CUSTOMER = '[customer]';
	public static readonly TAG_MONEY = '[money]';

	public static CUSTOM_SYMBOLS: string[] = [
		TextUtils.PRESTIGE_SYMBOL,
		TextUtils.SOFT_MONEY_SYMBOL,
		TextUtils.TIMER_SYMBOL,
		TextUtils.CUSTOMER_SYMBOL,
		TextUtils.FUCK_POWER_SYMBOL_SYMBOL,
		TextUtils.HARD_MONEY_SYMBOL,
		TextUtils.LOCK_SYMBOL,
		TextUtils.SOFT_MONEY_GRAY_SYMBOL,
		TextUtils.LIKE_SYBOL,
		TextUtils.TAP_POWER_SYBOL,
	];

	public static copyToBuffer(text: string): void {
		const copyFrom = document.createElement('textarea');
		copyFrom.textContent = text;
		const body = document.getElementsByTagName('body')[0];
		body.appendChild(copyFrom);
		copyFrom.select();
		document.execCommand('copy');
		body.removeChild(copyFrom);
	}

	public static replaceSpecSymbols(text: string): string {
		return text.replace(/\[prestige]/g, TextUtils.PRESTIGE_SYMBOL)
			.replace(/\[money]/g, TextUtils.SOFT_MONEY_SYMBOL)
			.replace(/\[timer]/g, TextUtils.TIMER_SYMBOL)
			.replace(/\[customer]/g, TextUtils.CUSTOMER_SYMBOL)
			.replace(/\[fack_power]/g, TextUtils.FUCK_POWER_SYMBOL_SYMBOL)
			.replace(/\[hardmoney]/g, TextUtils.HARD_MONEY_SYMBOL)
			.replace(/\[lock]/g, TextUtils.LOCK_SYMBOL)
			.replace(/\[money_grey]/g, TextUtils.SOFT_MONEY_GRAY_SYMBOL)
			.replace(/\[arrow]/g, TextUtils.ARROW_RIGHT)
			.replace(/\[nutaku]/g, TextUtils.NUTAKU_SYMBOL)
			.replace(/\[like]/g, TextUtils.LIKE_SYBOL)
			.replace(/\[tap_power]/g, TextUtils.TAP_POWER_SYBOL);
	}

	public static replaceValueAndEnding(
		gameLanguage: string,
		placeholder: string,
		value: string | number,
		phrase: string,
	): string {
		let numericValue: number;
		if (typeof value === 'string') {
			numericValue = parseFloat(value);
		} else {
			numericValue = value;
		}

		if (Number.isNaN(numericValue)) {
			return phrase.replace(placeholder, `${value}`);
		}

		const placeholderIndex = phrase.indexOf(placeholder);
		if (placeholderIndex === -1) {
			return phrase;
		}
		const nextPlaceholderIndex = phrase.substring(placeholderIndex + placeholder.length)
			.indexOf(TextUtils.VALUE_PLACEHOLDER_START_SYMBOLS);

		let substringForEndingReplaceEndIndex;
		if (nextPlaceholderIndex === -1) {
			substringForEndingReplaceEndIndex = phrase.length;
		} else {
			substringForEndingReplaceEndIndex = placeholderIndex + placeholder.length + nextPlaceholderIndex;
		}

		const substringForEndingReplace = phrase.substring(placeholderIndex, substringForEndingReplaceEndIndex);
		const substringWithReplacedEnding = TextUtils.getWordEnding(gameLanguage, numericValue, substringForEndingReplace);
		const phraseWithReplacedEnding = phrase.replace(substringForEndingReplace, substringWithReplacedEnding);

		return phraseWithReplacedEnding.replace(placeholder, `${value}`);
	}

	public static getWordEnding(
		gameLanguage: string,
		value: number,
		phrase: string,
	): string {
		const delimiterIndex = phrase.indexOf('|');

		if (delimiterIndex === -1) {
			return phrase;
		}

		const firstPart = phrase.substring(0, delimiterIndex);
		let secondPart = phrase.substring(delimiterIndex + 1);
		let endIndex = secondPart.indexOf(' ');

		if (endIndex === -1) {
			endIndex = secondPart.indexOf('[');
		}

		let endings;
		if (endIndex === -1) {
			endings = secondPart.split(',');
			secondPart = '';
		} else {
			endings = secondPart.substring(0, endIndex).split(',');
			secondPart = secondPart.substring(endIndex);
		}

		let ending = '';
		// eslint-disable-next-line default-case
		switch (gameLanguage) {
			case 'ru':
				ending = this.getEndingsRu(value, endings);
				break;
			case 'en':
				ending = this.getEndingsEn(value, endings);
				break;
		}

		return firstPart + ending + secondPart;
	}

	private static getEndingsRu(value: number, endings: string[]): string {
		if (value > 0 && value < 1) {
			return endings.length > 3 ? endings[3] : endings[1];
		}

		const target = value % 20;
		if (target === 1) {
			return endings[0];
		}

		if (target > 1 && target < 5) {
			return endings[1];
		}

		return endings[2];
	}

	private static getEndingsEn(value: number, endings: string[]): string {
		if (value > 1) {
			return endings[1];
		}

		return endings[0];
	}
}
