import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';

export class EpicQuestsCollectionsFactory {
	private readonly configs: Map<string, EpicQuestCollectionsConfig>;
	private readonly savesData: Map<string, number[]>;

	constructor(
		configs: Map<string, EpicQuestCollectionsConfig>,
		savesData: Map<string, number[]>,
	) {
		this.configs = configs;
		this.savesData = savesData;
	}

	public createModels(
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
	): Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel> {
		const result: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel> = new Map();
		this.configs.forEach((config) => {
			let currentProgress: number;
			const type = config.getType();
			switch (type) {
				case EpicQuestCollectionsTypes.CHARACTERS: {
					currentProgress = Array.from(characterModels.values()).filter(m => m.isOpened()).length;
					break;
				}
				case EpicQuestCollectionsTypes.UPGRADES: {
					currentProgress = Array.from(upgradeModels.values())
						.filter((m) => m.isOpened() && !m.isFreeActivation()).length;
					break;
				}
				case EpicQuestCollectionsTypes.FURNITURE: {
					currentProgress = Array.from(upgradeModels.values())
						.filter((m) => m.isOpened() && m.isFreeActivation()).length;
					break;
				}
				case EpicQuestCollectionsTypes.TOTEMS: {
					currentProgress = Array.from(totemModels.values()).filter(m => m.isOpened()).length;
					break;
				}
				default:
					throw new Error(`Unsupported epic quest collections type ${type}`);
			}

			const model = new EpicQuestCollectionsModel(
				type,
				config.getQuestTargetQuestKeyMap(),
				config.getQuestKeyLootboxRarityMap(),
				currentProgress,
			);

			if (this.savesData.has(type)) {
				model.setFromSaveData(this.savesData.get(type));
			}

			result.set(type, model);
		});
		return result;
	}
}
