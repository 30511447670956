import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness51001View } from '@views/businesses/eventWeekdayBusinesses/eventBuilding1/EventBusiness51001View';
import { EventBusiness51002View } from '@views/businesses/eventWeekdayBusinesses/eventBuilding1/EventBusiness51002View';
import { EventBusiness51003View } from '@views/businesses/eventWeekdayBusinesses/eventBuilding1/EventBusiness51003View';
import { EventBusiness51004View } from '@views/businesses/eventWeekdayBusinesses/eventBuilding2/EventBusiness51004View';
import { EventBusiness51005View } from '@views/businesses/eventWeekdayBusinesses/eventBuilding2/EventBusiness51005View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevelWeekdayView extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();
		const levelBgAtlas = AssetsStorage.getAtlas('miamiMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['miami_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 281);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['miami_morning/road'], EventLevelWeekdayView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(-55, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house4']).setTransform(719, 580),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(191, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(219, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(1158, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(1513, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(1221, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm2']).setTransform(1809, 668),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house2']).setTransform(2197, 579),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(1887, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm2']).setTransform(2509, 668),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(2940, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(2587, 900),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house1']).setTransform(3450, 528),
			new PIXI.Sprite(levelBgAtlas['miami_morning/house4']).setTransform(4050, 580),
			new PIXI.Sprite(levelBgAtlas['miami_morning/palm1']).setTransform(4520, 644),
			new PIXI.Sprite(levelBgAtlas['miami_morning/fence']).setTransform(4500, 900),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(1019, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(2366, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(3713, 576),
			new PIXI.Sprite(levelBgAtlas['miami_morning/city']).setTransform(5060, 576),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('miamiMorningAtlas');

		this.background = EventLevelWeekdayView.createMorningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness51001View(),
			new EventBusiness51002View(),
			new EventBusiness51003View(),
			new EventBusiness51004View(),
			new EventBusiness51005View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_stairs']).setTransform(1085, 300.5),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_right2']).setTransform(1986, 690, 0, 0.67),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_top3']).setTransform(1607, 328),
			new PIXI.Sprite(levelBgAtlas['miami_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_left2']).setTransform(1232, 686, 0, 0.67),
			new PIXI.Sprite(levelBgAtlas['miami_morning/logo_wet_pussy']).setTransform(1190, 500),
			new PIXI.Sprite(levelBgAtlas['miami_morning/building_stairs']).setTransform(1939, 550.5),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
		);

		this.initLevel(EventLevelWeekdayView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}
}
