import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { CharacterModel } from '@models/CharacterModel';

export class TutorialStepUnlockTypeCharacterActivatedModel extends TutorialStepUnlockTypeBaseModel {
	constructor(
		key: string,
		private readonly characterModel: CharacterModel,
	) {
		super(key, TutorialStepUnlockTypes.CHARACTER_ACTIVATED);

		this.characterModel.on(CharacterModel.EVENT_ACTIVATED, this.onUnlocked, this);
	}

	public destroy(): void {
		this.characterModel.off(CharacterModel.EVENT_ACTIVATED, this.onUnlocked, this);
		super.destroy();
	}
}
