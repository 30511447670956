import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30004View } from '@views/characters/events/event3/EventCharacter30004View';
import { EventCharacter30005View } from '@views/characters/events/event3/EventCharacter30005View';
import { EventCharacter30006View } from '@views/characters/events/event3/EventCharacter30006View';

export class EventBusiness30002View extends BusinessBaseView {
	constructor() {
		const eventCharacter30004View = new EventCharacter30004View();
		const eventCharacter30005View = new EventCharacter30005View();
		const eventCharacter30006View = new EventCharacter30006View();

		super('business30002', [eventCharacter30004View, eventCharacter30005View, eventCharacter30006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;
		const decor4 = new PIXI.Sprite(decorationAtlas['b5_decor4']).setTransform(3, 0, 0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
			decor4,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(eventCharacter30004View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter30005View, this.getChildIndex(decor4) + 1);
		this.addChildAt(eventCharacter30006View, this.getChildIndex(decor4) + 1);
	}
}
