import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

abstract class QuestPromoteConfigBase extends QuestConfigBase {
	protected targetAll: boolean;
	protected targetKey: string;
	protected promoteCount: number;
	protected targetLevel: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		const params: string = config[QuestConfigBase.FIELD_PARAMETERS];
		this.targetAll = QuestConfigBase.FORMAT_PARAMETER_ALL.test(params);
		if (!this.targetAll) {
			// eslint-disable-next-line prefer-destructuring
			this.targetKey = params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)[1];
		}
	}

	public setParamsOnLevel(params: string): void {
		const param = Number(params);
		if (this.targetAll) {
			this.promoteCount = param;
		} else {
			this.targetLevel = param;
		}
	}

	public getTargetKey(): string {
		return this.targetKey;
	}

	public getTargetLevel(): number {
		return this.targetLevel;
	}

	public getPromoteCount(): number {
		return this.promoteCount;
	}
}

export class QuestPromoteUpgradesConfig extends QuestPromoteConfigBase {
	private type: QuestType;

	constructor(config: { [key: string]: string }) {
		super(config);

		if (this.targetAll) {
			this.type = QuestTypes.PROMOTE_UPGRADES;
		} else {
			this.type = QuestTypes.PROMOTE_UPGRADE;
		}
	}

	public getType(): QuestType {
		return this.type;
	}
}

export class QuestPromoteCharactersConfig extends QuestPromoteConfigBase {
	private type: QuestType;

	constructor(config: { [key: string]: string }) {
		super(config);

		if (this.targetAll) {
			this.type = QuestTypes.PROMOTE_CHARACTERS;
		} else {
			this.type = QuestTypes.PROMOTE_CHARACTER;
		}
	}

	public getType(): QuestType {
		return this.type;
	}
}

export class QuestPromoteCharactersOrUpgradesConfig extends QuestConfigBase {
	private type: QuestType;
	private promoteCount: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.type = QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES;
	}

	public setParamsOnLevel(params: string): void {
		const param = Number(params);
		this.promoteCount = param;
	}

	public getPromoteCount(): number {
		return this.promoteCount;
	}

	public getType(): QuestType {
		return this.type;
	}
}

export class QuestPromoteAllConfig extends QuestConfigBase {
	private type: QuestType;
	private promoteCount: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.type = QuestTypes.PROMOTE_ALL;
	}

	public setParamsOnLevel(params: string): void {
		const param = Number(params);
		this.promoteCount = param;
	}

	public getPromoteCount(): number {
		return this.promoteCount;
	}

	public getType(): QuestType {
		return this.type;
	}
}
