import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { TutorialStepLevelModel } from '@models/tutorialSteps/TutorialStepLevelModel';
import { WindowBaseView } from '@views/components/WindowBaseView';
import * as TWEEN from '@tweenjs/tween.js';
import { GameConstants } from '@src/utils/GameConstants';
import { TutorialStepGirlPositionTypes } from '@configs/tutorialSteps/TutorialStepBaseConfig';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';

export class TutorialStepView extends WindowBaseView {
	public static readonly EVENT_DIALOG_COMPLETED: symbol = Symbol();

	private localizationStorage: LocalizationStorage;
	private model: TutorialStepBaseModel;
	private currentLocaleKeyId: number;

	private character: PIXI.Sprite;
	private textPhrase: MultiColoredTextField;
	private tutorialBubble: PIXI.mesh.NineSlicePlane;
	private tapAnimation: PIXI.extras.AnimatedSprite;
	private glowAnimation: PIXI.Sprite;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	private target: PIXI.Container;
	private targetShown: boolean;

	private characterDialogContainer: PIXI.Container;

	constructor(model: TutorialStepBaseModel, target?: PIXI.Container) {
		super(0, false, true);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.currentLocaleKeyId = -1;
		this.target = target;

		this.model = model;
		this.model.once(TutorialStepLevelModel.EVENT_COMPLETED, this.onClose, this);

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		const dialogAtlas = AssetsStorage.getAtlas('dialogAtlas');

		this.characterDialogContainer = new PIXI.Container();

		this.character = new PIXI.Sprite(dialogAtlas['characterTutorial']);
		this.character.y = GameConstants.GAME_CENTER_Y;
		this.character.pivot.x = this.character.width / 2;
		this.character.visible = false;

		this.tapAnimation = new PIXI.extras.AnimatedSprite(AssetsStorage.getResource('tutorialTapAnimation')
			.spritesheet
			.animations['tap']);
		this.tapAnimation.loop = true;
		this.tapAnimation.visible = false;
		this.tapAnimation.animationSpeed = 0.4;
		this.tapAnimation.anchor.set(0, 0.2);


		this.glowAnimation = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['tutorial_ring']);
		this.glowAnimation.visible = false;

		this.textPhrase = new MultiColoredTextField({
			font: '25px wendyOne',
			tint: 0x000000,
			align: 'center',
		});
		this.textPhrase.maxWidth = 420;
		this.textPhrase.anchor = 0.5;
		this.textPhrase.tint = 0x484850;

		this.tutorialBubble = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['tutorial_buble'], 0, 10, 0, 60);
		this.tutorialBubble.pivot.set(this.tutorialBubble.width / 2, this.tutorialBubble.height);
		this.tutorialBubble.y = 50;
		this.tutorialBubble.visible = false;

		this.characterDialogContainer.addChild(
			this.character,
			this.tutorialBubble,
			this.textPhrase as PIXI.DisplayObject,
		);

		this.mainContainer.addChild(
			this.glowAnimation,
			this.tapAnimation,
			this.characterDialogContainer,
		);
	}

	public onShown(): void {
		super.onShown();

		if (this.model.hasCharacter()) {
			this.startAppearingAnimation();
		} else if (this.target) {
			this.showTutorialTarget();
		}
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getTarget(): PIXI.Container {
		return this.target;
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);

		if (this.targetShown) {
			this.pointToTarget();
		}
	}

	private startAppearingAnimation(): void {
		const girlPosition = this.model.getGirlPosition();
		let animateOffset: number;
		switch (girlPosition) {
			case TutorialStepGirlPositionTypes.LEFT: {
				this.character.x = -GameConstants.GAME_WIDTH / 2 + 100;
				this.tutorialBubble.scale.x = -1;
				this.tutorialBubble.x = -330;
				animateOffset = 200;
				break;
			}

			case TutorialStepGirlPositionTypes.RIGHT: {
				this.character.x = GameConstants.GAME_WIDTH / 2 + 100;
				this.tutorialBubble.x = 330;
				animateOffset = -400;
				break;
			}

			default:
				throw new Error(`Unsupported girl position type '${girlPosition}`);
		}

		this.character.visible = true;

		if (this.model.isGirlAnimate()) {
			new TWEEN.Tween(this.character.position, this.tweenGroup)
				.to({ x: this.character.position.x + animateOffset }, 200)
				.easing(TWEEN.Easing.Quadratic.Out)
				.onComplete(() => this.onCharacterAppeared())
				.start();
		} else {
			this.character.position.x += animateOffset;
			this.onCharacterAppeared();
		}
	}

	private onCharacterAppeared(): void {
		this.tutorialBubble.visible = true;
		this.moveDialog();
		this.blackout.on('pointertap', this.moveDialog, this);
	}

	private moveDialog(): void {
		if (this.hasNextDialog()) {
			this.currentLocaleKeyId += 1;
			const currentLocaleKey = this.model.getLocaleKeys()[this.currentLocaleKeyId];
			this.setTextPhrase(this.localizationStorage.getLocalizedString(currentLocaleKey));

			if (!this.hasNextDialog() && this.target) {
				this.showTutorialTarget();
			}
		} else {
			this.blackout.off('pointertap', this.moveDialog, this);
			this.onDialogCompleted();
		}
	}

	private setTextPhrase(phrase: string): void {
		this.textPhrase.text = phrase;

		this.tutorialBubble.height = Math.max(180, this.textPhrase.height + 100);
		this.tutorialBubble.pivot.y = this.tutorialBubble.height;

		const [x, y] = [this.tutorialBubble.position.x, this.tutorialBubble.position.y - this.tutorialBubble.height / 2 - 20];
		this.textPhrase.position.set(x, y - 3);
	}

	private hasNextDialog(): boolean {
		return this.currentLocaleKeyId + 1 < this.model.getLocaleKeys().length;
	}

	private showTapAnimation(): void {
		this.tapAnimation.visible = true;
		this.tapAnimation.play();

		if (this.model.isTapSpriteInverted()) {
			this.tapAnimation.scale.x = -this.tapAnimation.scale.x;
		}
	}

	private showGlowAnimation(): void {
		this.glowAnimation.visible = true;
		this.glowAnimation.alpha = 1;
		new TWEEN.Tween(this.glowAnimation, this.tweenGroup)
			.to({ alpha: 0 }, 750)
			.repeat(Infinity)
			.start();

		this.glowAnimation.scale.set(0);
		new TWEEN.Tween(this.glowAnimation.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 750)
			.repeat(Infinity)
			.start();
	}

	private pointToTarget(): void {
		const targetLocalBounds = this.target.getLocalBounds();
		const targetPos = new PIXI.Point(
			targetLocalBounds.x + targetLocalBounds.width / 2,
			targetLocalBounds.y + targetLocalBounds.height / 2,
		);
		const pos = this.mainContainer.toLocal(targetPos, this.target);
		const dx = Math.abs(this.tapAnimation.position.x - pos.x);
		const dy = Math.abs(this.tapAnimation.position.y - pos.y);
		if (dx > 10 || dy > 10) {
			this.tapAnimation.position.set(pos.x, pos.y);
			this.glowAnimation.position.set(pos.x, pos.y);

			const modelData = this.model.getData();
			if (modelData.hasTapOffset()) {
				this.tapAnimation.x += modelData.getTapOffsetX();
				this.tapAnimation.y += modelData.getTapOffsetY();

				this.glowAnimation.x += modelData.getTapOffsetX();
				this.glowAnimation.y += modelData.getTapOffsetY();
			}
		}

		this.drawBlackoutHole();
	}

	private showTutorialTarget(): void {
		this.showTapAnimation();
		this.showGlowAnimation();

		this.drawBlackoutHole();

		this.targetShown = true;
	}

	private drawBlackoutHole(): void {
		this.blackout.clear();
		this.blackout.beginFill(0x000000, 0);
		this.blackout.moveTo(0, 0);
		this.blackout.lineTo(GameConstants.GAME_WIDTH, 0);
		this.blackout.lineTo(GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
		this.blackout.lineTo(0, GameConstants.GAME_HEIGHT);

		let targetBounds: PIXI.Rectangle;

		if (this.target.hitArea instanceof PIXI.Rectangle) {
			targetBounds = this.getBlackoutHoleRecrangleFromHitarea();
		} else {
			targetBounds = this.getBlackoutHoleRectangleFromBounds();
		}

		this.blackout.moveTo(targetBounds.x, targetBounds.y);
		this.blackout.lineTo(targetBounds.x + targetBounds.width, targetBounds.y);
		this.blackout.lineTo(targetBounds.x + targetBounds.width, targetBounds.y + targetBounds.height);
		this.blackout.lineTo(targetBounds.x, targetBounds.y + targetBounds.height);

		this.blackout.addHole();
		this.blackout.closePath();
	}

	private getBlackoutHoleRectangleFromBounds(): PIXI.Rectangle {
		return this.target.getBounds();
	}

	private getBlackoutHoleRecrangleFromHitarea(): PIXI.Rectangle {
		let { x, y } = this.target.getGlobalPosition();
		const scaleX: number = this.target.worldTransform.a;
		const scaleY: number = this.target.worldTransform.d;
		const hitarea = this.target.hitArea as PIXI.Rectangle;
		x += hitarea.x * scaleX;
		y += hitarea.y * scaleY;

		return new PIXI.Rectangle(x, y, hitarea.width * scaleX, hitarea.height * scaleY);
	}

	private onDialogCompleted(): void {
		this.emit(TutorialStepView.EVENT_DIALOG_COMPLETED);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.model.listeners(TutorialStepLevelModel.EVENT_COMPLETED).includes(this.onClose)) {
			this.model.off(TutorialStepLevelModel.EVENT_COMPLETED, this.onClose, this, true);
		}
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);
		super.destroy(options);
	}
}
