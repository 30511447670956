import { BaseTransactionCreator } from './BaseTransactionCreator';
import { BankPrepareTransactionWindow } from '@views/windows/bank/BankPrepareTransactionWindow';

export class ConfirmWindowTransactionCreator extends BaseTransactionCreator {
	protected async openWindow(url: string): Promise<void> {
		const confirmWindow = this.windowFactory.createBankPrepareTransactionWindow();
		this.windowViewSystem.showWindow(confirmWindow);

		const transactionWindow = window.open(url);
		let result: Promise<void>;

		if (transactionWindow != null) {
			result = new Promise((resolve) => {
				confirmWindow.once(BankPrepareTransactionWindow.EVENT_WINDOW_CLOSED, resolve);
			});
		} else {
			result = Promise.reject();
		}

		return result;
	}
}
