/* eslint-disable */

import { TextInputType } from '@src/types/TextInputType';

export interface TextInputStyle {
	multiline?: boolean;
	multilineRows?: number;
	fontFamily?: string;
	fontSize?: string;
	fontWeight?: string;
	padding?: string;
	width?: string;
	color?: string;
	type?: string;
};

export class TextInput extends PIXI.Container {
	private boxGenerator: any;
	private inputStyle: any;
	private multiline: boolean;
	private boxCache: {};
	private previous: {};
	private domAdded: boolean;
	private domvisible: boolean;
	private _placeholder: string;
	private _placeholderColor: number;
	private selection: number[];
	private restrictValue: string;
	private substituted: any;
	private domInput: any;
	private _disabled: any;
	private _max_length: any;
	private _restrict_regex: any;
	private dom_visible: any;
	private _last_renderer: any;
	private state: any;
	private _resolution: any;
	private _canvas_bounds: { top: any; left: any; width: any; height: any };
	private _box: any;
	private _surrogate: any;
	private _surrogate_hitbox: PIXI.Graphics;
	private _surrogate_mask: PIXI.Graphics;
	private inputType: TextInputType;
	private passwordChar: string;

	constructor(styles: { input?: TextInputStyle; }, inputType: TextInputType = TextInputType.TEXT) {
		super();

		this.inputStyle = Object.assign(
			{
				position: 'absolute',
				background: 'none',
				border: 'none',
				outline: 'none',
				transformOrigin: '0 0',
				lineHeight: '1',
			},
			styles.input,
		);
		this.inputType = inputType;
		this.passwordChar = '\u2022';

		if (this.inputStyle.hasOwnProperty('multiline')) {
			this.multiline = !!this.inputStyle.multiline;
			delete this.inputStyle.multiline;
		} else {
			this.multiline = false;
		}

		(this.boxCache as any) = {};
		this.previous = {};
		this.domAdded = false;
		this.domvisible = true;
		this._placeholder = '';
		this._placeholderColor = 0xa9a9a9;
		this.selection = [0, 0];
		this.restrictValue = '';
		this.createDOMInput();
		this.substituteText = true;
		this.setState('DEFAULT');
		this.addListeners();
	}


	// GETTERS & SETTERS

	get substituteText() {
		return this.substituted;
	}

	public setInputType(value: TextInputType): void {
		this.inputType = value;
		this.domInput.type = value;
		this._updateSurrogate();
	}

	public setPasswordChar(value: string): void {
		this.passwordChar = value;
	}

	public getInputType(): TextInputType {
		return this.inputType;
	}

	set substituteText(substitute) {
		if (this.substituted === substitute) {
			return;
		}

		this.substituted = substitute;

		if (substitute) {
			this._createSurrogate();
			this.domvisible = false;
		} else {
			this._destroySurrogate();
			this.domvisible = true;
		}
		this.placeholder = this._placeholder;
		this._update();
	}

	get placeholder() {
		return this._placeholder;
	}

	set placeholder(text) {
		this._placeholder = text;
		if (this.substituted) {
			this._updateSurrogate();
			this.domInput.placeholder = '';
		} else {
			this.domInput.placeholder = text;
		}
	}

	get disabled() {
		return this._disabled;
	}

	set disabled(disabled: boolean) {
		this._disabled = disabled;
		this.domInput.disabled = disabled;
		this.setState(disabled ? 'DISABLED' : 'DEFAULT');
	}

	get maxLength() {
		return this._max_length;
	}

	set maxLength(length: number) {
		this._max_length = length;
		this.domInput.setAttribute('maxlength', length);
	}

	get restrict() {
		return this._restrict_regex;
	}

	set restrict(regex: RegExp | string) {
		if(regex instanceof RegExp) {
			regex = regex.toString().slice(1,-1);

			if (regex.charAt(0) !== '^') {
				regex = '^'+regex;
			}

			if (regex.charAt(regex.length-1) !== '$') {
				regex = regex+'$'
			}

			regex = new RegExp(regex)
		} else {
			regex = new RegExp('^['+regex+']*$');
		}

		this._restrict_regex = regex;
	}

	get text() {
		return this.domInput.value;
	}

	set text(text: string) {
		this.domInput.value = text;
		if (this.substituted) {
			this._updateSurrogate();
		}
	}

	get htmlInput() {
		return this.domInput;
	}

	public focus() {
		if (this.substituted && !this.dom_visible) {
			this._setDOMInputVisible(true);
		}

		this.domInput.focus();
	}

	public blur() {
		this.domInput.blur();
	}

	public select() {
		this.focus();
		this.domInput.select();
	}

	public setInputStyle(key: string, value: any) {
		this.inputStyle[key] = value;
		this.domInput.style[key] = value;

		if (this._last_renderer) {
			this._update();
		}
	}

	// SETUP
	private createDOMInput() {
		if (this.multiline) {
			this.domInput = document.createElement('textarea');
			this.domInput.style.overflow = 'hidden';
			this.domInput.rows = this.inputStyle.multilineRows;
			this.domInput.style.resize = 'none';
		} else {
			this.domInput = document.createElement('input');
		}

		for (let key in this.inputStyle) {
			this.domInput.style[key] = this.inputStyle[key];
		}
	}

	private addListeners(){
		this.on('added',this.onAdded.bind(this));
		this.on('removed',this.onRemoved.bind(this));
		this.domInput.addEventListener('keydown', this.onInputKeyDown.bind(this));
		this.domInput.addEventListener('input', this.onInputInput.bind(this));
		this.domInput.addEventListener('keyup', this.onInputKeyUp.bind(this));
		this.domInput.addEventListener('focus', this.onFocused.bind(this));
		this.domInput.addEventListener('blur', this.onBlurred.bind(this));
	}

	private onInputKeyDown(e: { keyCode: any; }) {
		this.selection = [
			this.domInput.selectionStart,
			this.domInput.selectionEnd
		];

		this.emit('keydown', e.keyCode);
	}

	private onInputInput(e: { keyCode: any; }) {
		if (this._restrict_regex) {
			this._applyRestriction();
		}

		if (this.substituted) {
			this._updateSubstitution();
		}

		this.emit('input', this.text);
	}

	private onInputKeyUp(e: { keyCode: any; }){
		this.emit('keyup',e.keyCode)
	}

	private onFocused(): void {
		this.setState('FOCUSED');
		this.emit('focus');
	}

	private onBlurred(): void {
		this.setState('DEFAULT');
		this.emit('blur');
	}

	private onAdded(): void {
		document.body.appendChild(this.domInput);
		this.domInput.style.display = 'none';
		this.domAdded = true;
	}

	private onRemoved(){
		document.body.removeChild(this.domInput);
		this.domAdded = false;
	}

	private setState(state: string): void {
		this.state = state;
		this._updateBox();
		if (this.substituted) {
			this._updateSubstitution();
		}
	}


	// RENDER & UPDATE

	// for pixi v4
	renderWebGL(renderer: PIXI.WebGLRenderer){
		super.renderWebGL(renderer);
		this._renderInternal(renderer)
	}

	// for pixi v4
	renderCanvas(renderer: PIXI.CanvasRenderer){
		super.renderCanvas(renderer);
		this._renderInternal(renderer)
	}

	_renderInternal(renderer: { resolution: any; }){
		this._resolution = renderer.resolution;
		this._last_renderer = renderer;
		this._canvas_bounds = this._getCanvasBounds();
		if(this._needsUpdate())
			this._update()
	}

	_update(){
		this._updateDOMInput();
		if(this.substituted) this._updateSurrogate();
		this._updateBox()
	}

	_updateBox(){
		if(!this.boxGenerator)
			return;

		if(this._needsNewBoxCache())
			this._buildBoxCache();

		if(this.state==(this.previous as any).state
			&& this._box==((this.boxCache as any) as any)[this.state])
			return;

		if(this._box)
			this.removeChild(this._box);

		this._box = ((this.boxCache as any) as any)[this.state];
		this.addChildAt(this._box,0)
		(this.previous as any).state = this.state
	}

	_updateSubstitution(){
		if(this.state==='FOCUSED'){
			this.domvisible = true;
			this._surrogate.visible = this.text.length===0
		}else{
			this.domvisible = false;
			this._surrogate.visible = true
		}
		this._updateDOMInput();
		this._updateSurrogate()
	}

	_updateDOMInput(){
		if(!this._canvas_bounds)
			return;

		this.domInput.style.top = this._canvas_bounds.top+'px';
		this.domInput.style.left = this._canvas_bounds.left+'px';
		this.domInput.style.transform = this._pixiMatrixToCSS(this._getDOMRelativeWorldTransform());
		this.domInput.style.opacity = this.worldAlpha;
		this._setDOMInputVisible(this.worldVisible && this.domvisible);

		(this.previous as any).canvas_bounds = this._canvas_bounds;
		(this.previous as any).world_transform = this.worldTransform.clone();
		(this.previous as any).world_alpha = this.worldAlpha;
		(this.previous as any).world_visible = this.worldVisible
	}

	_applyRestriction(){
		if(this._restrict_regex.test(this.text)){
			this.restrictValue = this.text
		}else{
			this.text = this.restrictValue;
			this.domInput.setSelectionRange(
				this.selection[0],
				this.selection[1]
			)
		}
	}


	// STATE COMPAIRSON (FOR PERFORMANCE BENEFITS)

	_needsUpdate(){
		return (
			!this._comparePixiMatrices(this.worldTransform,(this.previous as any).world_transform)
			|| !this._compareClientRects(this._canvas_bounds,(this.previous as any).canvas_bounds)
			|| this.worldAlpha != (this.previous as any).world_alpha
			|| this.worldVisible != (this.previous as any).world_visible
		)
	}

	_needsNewBoxCache(){
		let input_bounds = this._getDOMInputBounds();
		return (
			!(this.previous as any).input_bounds
			|| input_bounds.width != (this.previous as any).input_bounds.width
			|| input_bounds.height != (this.previous as any).input_bounds.height
		)
	}


	// INPUT SUBSTITUTION

	_createSurrogate(){
		this._surrogate_hitbox = new PIXI.Graphics();
		this._surrogate_hitbox.alpha = 0;
		this._surrogate_hitbox.interactive = true;
		this._surrogate_hitbox.cursor = 'text';
		this._surrogate_hitbox.on('pointerdown',this._onSurrogateFocus.bind(this));
		this.addChild(this._surrogate_hitbox);

		this._surrogate_mask = new PIXI.Graphics();
		this.addChild(this._surrogate_mask);

		this._surrogate = new PIXI.Text('',{});
		this.addChild(this._surrogate);

		this._surrogate.mask = this._surrogate_mask;

		this._updateSurrogate()
	}

	_updateSurrogate(){
		let padding = this._deriveSurrogatePadding();
		let input_bounds = this._getDOMInputBounds();

		this._surrogate.style = this._deriveSurrogateStyle();
		this._surrogate.style.padding = Math.max.apply(Math,padding);
		this._surrogate.y = this.multiline ? padding[0] : (input_bounds.height-this._surrogate.height)/2;
		this._surrogate.x = padding[3];
		this._surrogate.text = this._deriveSurrogateText();

		this._updateSurrogateHitbox(input_bounds);
		this._updateSurrogateMask(input_bounds,padding)
	}

	_updateSurrogateHitbox(bounds: { width: number; height: number; }){
		this._surrogate_hitbox.clear();
		this._surrogate_hitbox.beginFill(0);
		this._surrogate_hitbox.drawRect(0,0,bounds.width,bounds.height);
		this._surrogate_hitbox.endFill();
		this._surrogate_hitbox.interactive = !this._disabled
	}

	_updateSurrogateMask(bounds: { width: number; height: number; },padding: number[]){
		this._surrogate_mask.clear();
		this._surrogate_mask.beginFill(0);
		this._surrogate_mask.drawRect(padding[3],0,bounds.width-padding[3]-padding[1],bounds.height);
		this._surrogate_mask.endFill()
	}

	_destroySurrogate(){
		if(!this._surrogate) return;

		this.removeChild(this._surrogate);
		this.removeChild(this._surrogate_hitbox);

		this._surrogate.destroy();
		this._surrogate_hitbox.destroy();

		this._surrogate = null;
		this._surrogate_hitbox = null
	}

	_onSurrogateFocus(){
		this._setDOMInputVisible(true);
		//sometimes the input is not being focused by the mouseclick
		setTimeout(this._ensureFocus.bind(this),10)
	}

	_ensureFocus(){
		if(!this._hasFocus())
			this.focus()
	}

	_deriveSurrogateStyle(){
		let style = new PIXI.TextStyle({});

		for(var key in this.inputStyle){
			switch(key){
				case 'color':
					style.fill = this.inputStyle.color;
				break;
				case 'fontFamily':
				case 'fontSize':
				case 'fontWeight':
				case 'fontVariant':
				case 'fontStyle':
					style[key] = this.inputStyle[key];
				break;
				case 'letterSpacing':
					style.letterSpacing = parseFloat(this.inputStyle.letterSpacing);
				break
			}
		}

		if(this.multiline){
			style.lineHeight = parseFloat(style.fontSize as any);
			style.wordWrap = true;
			style.wordWrapWidth = this._getDOMInputBounds().width
		}

		if(this.domInput.value.length === 0)
			style.fill = this._placeholderColor;

		return style
	}

	_deriveSurrogatePadding(){
		let indent = this.inputStyle.textIndent ? parseFloat(this.inputStyle.textIndent) : 0;

		if(this.inputStyle.padding && this.inputStyle.padding.length>0){
			let components = this.inputStyle.padding.trim().split(' ');

			if(components.length==1){
				let padding = parseFloat(components[0]);
				return [padding,padding,padding,padding+indent]
			}else if(components.length==2){
				let paddingV = parseFloat(components[0]);
				let paddingH = parseFloat(components[1]);
				return [paddingV,paddingH,paddingV,paddingH+indent]
			}else if(components.length==4){
				let padding = components.map((component: string) => {
					return parseFloat(component)
				});
				padding[3] += indent;
				return padding
			}
		}

		return [0,0,0,indent]
	}

	_deriveSurrogateText(): string {
		let text: string;
		if (this.domInput.value.length === 0) {
			text = this.placeholder;
		} else if (this.inputType === TextInputType.PASSWORD) {
			text = this.passwordChar.repeat(this.domInput.value.length);
		} else {
			text = this.domInput.value;
		}
		return text;
	}


	// CACHING OF INPUT BOX GRAPHICS

	_buildBoxCache(){
		this._destroyBoxCache();

		let states = ['DEFAULT','FOCUSED','DISABLED'];
		let input_bounds = this._getDOMInputBounds();

		for(let i in states){
			(this.boxCache as any)[states[i]] = this.boxGenerator(
				input_bounds.width,
				input_bounds.height,
				states[i]
			)
		}

		(this.previous as any).input_bounds = input_bounds
	}

	_destroyBoxCache(){
		if(this._box){
			this.removeChild(this._box);
			this._box = null
		}

		for(let i in (this.boxCache as any)){
			(this.boxCache as any)[i].destroy()
			(this.boxCache as any)[i] = null;
			delete (this.boxCache as any)[i]
		}
	}


	// HELPER FUNCTIONS

	_hasFocus(){
		return document.activeElement===this.domInput
	}

	_setDOMInputVisible(visible: boolean){
		this.domInput.style.display = visible ? 'block' : 'none'
	}

	_getCanvasBounds(){
		let rect = this._last_renderer.view.getBoundingClientRect();
		let bounds = {top: rect.top, left: rect.left, width: rect.width, height: rect.height};
		bounds.left += window.scrollX;
		bounds.top += window.scrollY;
		return bounds
	}

	_getDOMInputBounds(){
		let remove_after = false;

		if(!this.domAdded){
			document.body.appendChild(this.domInput);
			remove_after = true
		}

		let org_transform = this.domInput.style.transform;
		let org_display = this.domInput.style.display;
		this.domInput.style.transform = '';
		this.domInput.style.display = 'block';
		let bounds = this.domInput.getBoundingClientRect();
		this.domInput.style.transform = org_transform;
		this.domInput.style.display = org_display;

		if(remove_after)
			document.body.removeChild(this.domInput);

		return bounds
	}

	_getDOMRelativeWorldTransform(){
		let canvas_bounds = this._last_renderer.view.getBoundingClientRect();
		let matrix = this.worldTransform.clone();

		matrix.scale(this._resolution,this._resolution);
		matrix.scale(canvas_bounds.width/this._last_renderer.width,
					 canvas_bounds.height/this._last_renderer.height);
		return matrix
	}

	_pixiMatrixToCSS(m: PIXI.Matrix){
		return 'matrix('+[m.a,m.b,m.c,m.d,m.tx,m.ty].join(',')+')'
	}

	_comparePixiMatrices(m1: PIXI.Matrix,m2: { a: any; b: any; c: any; d: any; tx: any; ty: any; }){
		if(!m1 || !m2) return false;
		return (
			m1.a == m2.a
			&& m1.b == m2.b
			&& m1.c == m2.c
			&& m1.d == m2.d
			&& m1.tx == m2.tx
			&& m1.ty == m2.ty
		)
	}

	_compareClientRects(r1: { top: any; left: any; width: any; height: any; },r2: { left: any; top: any; width: any; height: any; }){
		if(!r1 || !r2) return false;
		return (
			r1.left == r2.left
			&& r1.top == r2.top
			&& r1.width == r2.width
			&& r1.height == r2.height
		)
	}

	public destroy(options: boolean | PIXI.DestroyOptions): void {
		this._destroyBoxCache();
		super.destroy(options);
	}
}

/* eslint-enable */
