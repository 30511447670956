import AbstractMoneyModel from '@models/money/AbstractMoneyModel';

export default class PrestigeMoneyModel extends AbstractMoneyModel {
	private currentValue: number;

	constructor() {
		super();

		this.currentValue = 0;
	}

	public add(value: number): void {
		this.currentValue += value;

		this.emit(PrestigeMoneyModel.EVENT_VALUE_INCREASED, value);
		this.emit(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.currentValue);
	}

	public subtract(value: number): void {
		this.currentValue -= value;

		this.emit(PrestigeMoneyModel.EVENT_VALUE_DECREASED, -value);
		this.emit(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.currentValue);
	}

	public setValue(value: number): void {
		this.currentValue = value;
	}

	public getValue(): number {
		return this.currentValue;
	}
}
