import { AbstractQuest } from './AbstractQuest';
import { QuestType } from '@src/types/QuestTypes';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { QuestRestoreStateData } from '@src/types/SaveTypes';

export class QuestActivateParty extends AbstractQuest {
	private currentAmount: number;
	private targetAmount: number;
	private targets: Map<string, FarewellPartyModel>;

	constructor(
		questKey: string,
		targets: Map<string, FarewellPartyModel>,
		type: QuestType,
		targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(questKey, type, lineId, mainWindowViewParameters, time);

		this.targetAmount = targetAmount;
		this.currentAmount = 0;
		this.targets = targets;

		this.trackProgressStart();
	}

	public restoreSavedState(savedData: QuestRestoreStateData): void {
		this.currentAmount = Number(savedData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	protected trackProgressStart(): void {
		this.targets.forEach(model => model.on(FarewellPartyModel.EVENT_FINISHED, this.onFarewellPatyFinished, this));
	}

	protected trackProgressStop(): void {
		this.targets.forEach(model => model.off(FarewellPartyModel.EVENT_FINISHED, this.onFarewellPatyFinished, this));
	}

	private onFarewellPatyFinished(): void {
		this.currentAmount += 1;
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}
}
