import { TotemModel } from '@models/TotemModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { ReplacementMap } from '../upgrades/TextDescriptionHelperUpgrade';
import { TextUtils } from '@src/utils/TextUtils';
import { NumberUtils } from '@src/utils/NumberUtils';

export class TextDescriptionHelperTotem {
	public static getTextDescription(model: TotemModel): string {
		const localizationKey = TextDescriptionHelperTotem.getTextDescriptionKey(model);
		const replacementMaps = TextDescriptionHelperTotem.getReplacementMaps(model);
		let text = LocalizationStorage.getInstance().getLocalizedString(localizationKey);

		replacementMaps.forEach((replacementMap) => {
			text = TextUtils.replaceValueAndEnding(
				LocalizationStorage.getInstance().getLanguage(),
				replacementMap.replaceString,
				replacementMap.replaceValue,
				text,
			);
		});

		return text;
	}

	public static getTextDescriptionPromoteWindow(model: TotemModel): string {
		const localizationKey = TextDescriptionHelperTotem.getTextDescriptionKeyPromoteWindow(model);
		let text = LocalizationStorage.getInstance().getLocalizedString(localizationKey);

		const replacementMaps = TextDescriptionHelperTotem.getReplacementMaps(model);
		replacementMaps.forEach((replacementMap) => {
			text = TextUtils.replaceValueAndEnding(
				LocalizationStorage.getInstance().getLanguage(),
				replacementMap.replaceString,
				replacementMap.replaceValue,
				text,
			);
		});
		return text;
	}

	public static getBonusShortTemplateString(model: TotemModel): string {
		const localizationStorage = LocalizationStorage.getInstance();
		let template: string;

		if (model.hasBonusFuckpower()) {
			template = `${TextUtils.TAG_FUCKPOWER} +{{value}}% `;
		} else if (model.hasBonusFucktime()) {
			const loc = localizationStorage.getLocalizedString('#business_info_interval_value');
			template = `${TextUtils.TAG_TIMER} +${loc}`;
		} else {
			const icon = model.hasBonusDecreaseCustomerCostCoef() ? TextUtils.TAG_CUSTOMER : TextUtils.TAG_MONEY;
			template = `${icon} +x{{value}}`;
		}

		template = `[ffce45]${template}[-]`;

		const replacementMap = TextDescriptionHelperTotem.getValueReplaceMap(model);

		const str = TextUtils.replaceValueAndEnding(
			localizationStorage.getLanguage(),
			replacementMap.replaceString,
			replacementMap.replaceValue,
			template,
		);

		return str;
	}

	private static getTextDescriptionKeyPromoteWindow(model: TotemModel): string {
		let key;
		if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#Promote_window_totem_Desc_DecreaseCustomerCost';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#Promote_window_totem_Desc_MultiplierIncome';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#Promote_window_totem_Desc_MultiplierTime';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#Promote_window_totem_Desc_ReduceTimeIncomeByClick';
		} else if (model.hasBonusFuckpower()) {
			key = '#Promote_window_totem_Desc_Fuckpower';
		} else if (model.hasBonusFucktime()) {
			key = '#Promote_window_totem_Desc_Fucktime';
		}

		if (model.isAllBusinessAffected()) {
			key = `${key}_all`;
		}
		return key;
	}

	private static getTextDescriptionKey(model: TotemModel): string {
		let key;
		if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#totem_Desc_DecreaseCustomerCost';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#totem_Desc_MultiplierIncome';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#totem_Desc_MultiplierTime';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#totem_Desc_ReduceTimeIncomeByClick';
		} else if (model.hasBonusFuckpower()) {
			key = '#totem_Desc_Fuckpower';
		} else if (model.hasBonusFucktime()) {
			key = '#totem_Desc_Fucktime';
		}

		if (model.isAllBusinessAffected()) {
			key = `${key}_all`;
		}
		return key;
	}

	private static getReplacementMaps(model: TotemModel): ReplacementMap[] {
		const result: ReplacementMap[] = [];
		{
			const replaceString = '{{next_value}}';
			let replaceValue = '';
			if (model.hasBonusDecreaseCustomerCostCoef()) {
				replaceValue = NumberUtils.numberToShortString(Math.floor(model.getNextDecreaseCustomerCostCoef()));
			} else if (model.hasBonusMultiplierIncome()) {
				replaceValue = model.getNextMultiplierIncomeAsSoftMoney().toString();
			} else if (model.hasBonusMultiplierTime()) {
				replaceValue = model.getNextMultiplierTimeAsSoftMoney().toString();
			} else if (model.hasBonusReduceTimeIncomeByClick()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextReduceTimeIncomeByClickValue());
			} else if (model.hasBonusFuckpower()) {
				replaceValue = TextDescriptionHelperTotem.convertToIncreasedProcentText(model.getNextMultiplierFuckpower());
			} else if (model.hasBonusFucktime()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextMultiplierFucktime());
			}
			result.push({ replaceString, replaceValue });
		}

		result.push(TextDescriptionHelperTotem.getValueReplaceMap(model));

		if (!model.isAllBusinessAffected()) {
			const replaceString = '{{business_name}}';
			const businessKey = model.getAffectTarget();
			const replaceValue = LocalizationStorage.getInstance().getLocalizedString(`#${businessKey}_Name`);
			result.push({ replaceString, replaceValue });
		}
		return result;
	}

	private static convertToIncreasedProcentText(value: number): string {
		return Math.round(value * 100).toString();
	}

	private static getValueReplaceMap(model: TotemModel): ReplacementMap {
		const replaceString = '{{value}}';
		let replaceValue = '';

		if (model.hasBonusDecreaseCustomerCostCoef()) {
			replaceValue = NumberUtils.numberToShortString(Math.floor(model.getDecreaseCustomerCostCoef()));
		} else if (model.hasBonusMultiplierIncome()) {
			replaceValue = model.getMultiplierIncomeAsSoftMoney().toString();
		} else if (model.hasBonusMultiplierTime()) {
			replaceValue = model.getMultiplierTimeAsSoftMoney().toString();
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			replaceValue = NumberUtils.numberToShortString(model.getReduceTimeIncomeByClickValue());
		} else if (model.hasBonusFuckpower()) {
			replaceValue = TextDescriptionHelperTotem.convertToIncreasedProcentText(model.getMultiplierFuckpower());
		} else if (model.hasBonusFucktime()) {
			replaceValue = NumberUtils.numberToShortString(model.getFucktime());
		}

		return { replaceString, replaceValue };
	}
}
