import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import * as TWEEN from '@tweenjs/tween.js';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { CardRarity } from '@src/types/CardRarities';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';

type RewardTargetView = {
	textTarget: PIXI.extras.BitmapText;
	rewardBgSprite: PIXI.Sprite;
	rewardSprite: RewardTargetLootboxView;
	checkIcon: PIXI.Sprite;
	position: PIXI.Point;
	progressBarOffsetLeftX: number;
	progressBarOffsetRightX: number;
	canCollectIcon: PIXI.Sprite;
};

class RewardTargetLootboxView extends PIXI.Container {
	private readonly tickerLootbox: PIXI.ticker.Ticker;
	private readonly completedLootboxTweenGroup: TWEEN.Group;

	private completedLootboxShakeInterval: any;
	private hightlightAnimation: SpriteHighlightAnimationView;

	private readonly main: PIXI.Container;

	constructor(rarity: CardRarity) {
		super();

		this.main = new PIXI.Container();

		this.completedLootboxTweenGroup = new TWEEN.Group();

		this.tickerLootbox = PIXI.ticker.shared;
		this.tickerLootbox.add(this.updateLootbox, this);


		const lootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`${rarity}_lootbox_mini_main`]);
		lootboxSprite.scale.set(1.7);
		this.main.addChild(
			lootboxSprite,
		);

		this.addChild(
			this.main,
		);
	}

	public startAnimation(): void {
		this.stopAnimationsIfAny();

		new TWEEN.Tween(this.main.scale, this.completedLootboxTweenGroup)
			.to({ x: 0.82, y: 0.82 }, 750)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.yoyo(true)
			.repeat(Infinity)
			.start();

		this.completedLootboxShakeInterval = setInterval(() => {
			const shakeEnd = new TWEEN.Tween(this.main, this.completedLootboxTweenGroup)
				.to({ rotation: 0 }, 35)
				.easing(TWEEN.Easing.Cubic.Out);
			const shakeMain = new TWEEN.Tween(this.main, this.completedLootboxTweenGroup)
				.to({ rotation: 0.05 }, 70)
				.easing(TWEEN.Easing.Cubic.InOut)
				.yoyo(true)
				.repeat(3)
				.chain(shakeEnd);
			new TWEEN.Tween(this.main, this.completedLootboxTweenGroup)
				.to({ rotation: -0.05 }, 35)
				.easing(TWEEN.Easing.Cubic.In)
				.chain(shakeMain)
				.start();
		}, 3000);

		const hightlightMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main_mask']);
		this.hightlightAnimation = new SpriteHighlightAnimationView(hightlightMask, 1000);
		this.hightlightAnimation.start();

		this.main.addChild(
			this.hightlightAnimation,
		);
	}

	public stopAnimationsIfAny(): void {
		if (this.completedLootboxShakeInterval) {
			clearInterval(this.completedLootboxShakeInterval);
		}
		if (this.completedLootboxTweenGroup) {
			this.completedLootboxTweenGroup.removeAll();
		}
		if (this.hightlightAnimation) {
			this.hightlightAnimation.destroy();
			this.hightlightAnimation = null;
		}
	}

	private updateLootbox(): void {
		this.completedLootboxTweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.stopAnimationsIfAny();
		this.tickerLootbox.remove(this.updateLootbox);
		super.destroy(options);
	}
}

export class EpicQuestCollectionsProgressView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();
	public static readonly EVENT_COLLECT_REWARD_TARGET: symbol = Symbol();

	private static readonly PROGRESS_BAR_WIDTH: number = 1380;

	private static setRewardViewCompleted(view: RewardTargetView): void {
		view.rewardBgSprite.texture = AssetsStorage.getAtlas('collectionsAtlas')['epic_bar_holder_green'];

		view.rewardSprite.startAnimation();

		view.canCollectIcon.visible = true;
	}

	private static setRewardViewCollected(view: RewardTargetView): void {
		view.textTarget.tint = 0x90cc45;

		view.rewardBgSprite.texture = AssetsStorage.getAtlas('collectionsAtlas')['epic_bar_holder_green'];

		view.rewardSprite.alpha = 0.4;
		view.rewardSprite.stopAnimationsIfAny();

		view.checkIcon.visible = true;
		view.canCollectIcon.visible = false;
	}

	private static getRewardTargetPosition(i: number, rewardTargetsCount: number): number {
		const marginX = EpicQuestCollectionsProgressView.PROGRESS_BAR_WIDTH / rewardTargetsCount;
		return -EpicQuestCollectionsProgressView.PROGRESS_BAR_WIDTH / 2 + marginX * i;
	}

	private readonly model: EpicQuestCollectionsModel;
	private readonly totalProgress: number;
	private readonly localizationStorage: LocalizationStorage;

	private readonly progressBar: PIXI.mesh.NineSlicePlane;
	private readonly progressText: SizeableBitmapText;
	private readonly lootboxIconContainer: PIXI.Container;

	private progressTextDefault: string;
	private rewardTargetViewMap: Map<number, RewardTargetView>;

	constructor(model: EpicQuestCollectionsModel) {
		super();

		this.model = model;
		this.model.on(EpicQuestCollectionsModel.EVENT_PROGRESS, this.onProgress, this);
		this.model.on(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.onSomeRewardReached, this);
		this.model.on(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.onSomeRewardTargetCollected, this);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.totalProgress = this.model.getTotalTargetProgress();

		this.progressTextDefault = '';

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const progressBarBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		progressBarBg.width = EpicQuestCollectionsProgressView.PROGRESS_BAR_WIDTH;
		progressBarBg.tint = 0x282828;

		this.progressBar = new PIXI.mesh.NineSlicePlane(collectionAtlas['epic_quest_bar'], 0, 0, 7, 0);
		this.progressBar.x = -EpicQuestCollectionsProgressView.PROGRESS_BAR_WIDTH / 2;
		this.progressBar.pivot.y = this.progressBar.height / 2;

		const rewardTargets = model.getTargetProgresses();

		this.rewardTargetViewMap = new Map();
		const rewardTargetsContainer = new PIXI.Container();
		for (let i = 0; i < rewardTargets.length - 1; i++) {
			const rewardTarget = rewardTargets[i];

			const rewardBgSprite = new PIXI.Sprite(collectionAtlas['epic_bar_holder']);
			rewardBgSprite.on('pointertap', () => this.onRewardTargetClick(rewardTarget), this);
			rewardBgSprite.interactive = true;

			const rarity = this.model.getRewardRarity(rewardTarget);
			const rewardSprite = new RewardTargetLootboxView(rarity);
			rewardSprite.scale.set(0.45, 0.45);

			const checkIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_icon2']);
			checkIcon.visible = false;
			checkIcon.scale.set(0.48, 0.48);

			const canCollectIcon = new PIXI.Sprite(collectionAtlas['can_promote_icon']);
			canCollectIcon.visible = false;
			canCollectIcon.scale.set(0.5, 0.5);
			canCollectIcon.position.set(25, -25);

			const textTarget = new PIXI.extras.BitmapText('', { font: '22px wendyOneShadowBold', tint: 0xbcbcbc });
			textTarget.text = rewardTargets[i].toString();
			textTarget.anchor = 0.5;
			textTarget.y = 33;

			const rewardTargetPosition = EpicQuestCollectionsProgressView.getRewardTargetPosition(i + 1, rewardTargets.length);

			const rewardTargetContainer = new PIXI.Container();
			rewardTargetContainer.addChild(
				rewardBgSprite,
				rewardSprite,
				checkIcon,
				canCollectIcon,
				textTarget as PIXI.DisplayObject,
			);
			rewardTargetContainer.x = rewardTargetPosition;

			rewardTargetsContainer.addChild(
				rewardTargetContainer,
			);

			const rewardTargetView: RewardTargetView = {
				rewardBgSprite,
				rewardSprite,
				textTarget,
				checkIcon,
				canCollectIcon,
				position: new PIXI.Point(rewardTargetPosition, 0),
				progressBarOffsetLeftX: rewardTargetPosition - this.progressBar.x - rewardBgSprite.width / 2,
				progressBarOffsetRightX: rewardTargetPosition - this.progressBar.x + rewardBgSprite.width / 2,
			};
			this.rewardTargetViewMap.set(rewardTargets[i], rewardTargetView);
		}

		const lootboxIconRarity = this.model.getRewardRarity(rewardTargets[rewardTargets.length - 1]);
		const lootboxIcon = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`${lootboxIconRarity}_lootbox_main`]);
		lootboxIcon.interactive = true;
		lootboxIcon.on('pointertap', this.onLootboxIconClick, this);
		lootboxIcon.rotation = -PIXI.DEG_TO_RAD * 15;
		lootboxIcon.scale.set(0.6);

		const lootboxMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main_mask']);
		lootboxMask.rotation = -PIXI.DEG_TO_RAD * 15;
		const hightlightAnimation = new SpriteHighlightAnimationView(lootboxMask);
		hightlightAnimation.position.set(lootboxIcon.x, lootboxIcon.y);
		hightlightAnimation.start();

		this.lootboxIconContainer = new PIXI.Container();
		this.lootboxIconContainer.x = EpicQuestCollectionsProgressView.getRewardTargetPosition(rewardTargets.length, rewardTargets.length);
		this.lootboxIconContainer.addChild(
			lootboxIcon,
			hightlightAnimation as PIXI.DisplayObject,
		);

		this.progressText = new SizeableBitmapText('', 509, { font: '24px wendyOneShadowBold', tint: 0xbcbcbc });
		this.progressText.anchor = new PIXI.Point(0, 0.5);
		this.progressText.position.set(-EpicQuestCollectionsProgressView.PROGRESS_BAR_WIDTH / 2, -40);

		this.addChild(
			progressBarBg,
			this.progressBar,
			rewardTargetsContainer,
			this.lootboxIconContainer as PIXI.DisplayObject,
			this.progressText,
		);

		const questTargetsSorted = this.model.getTargetProgresses();
		questTargetsSorted.forEach((targetProgress) => {
			if (this.model.getCurrentProgress() >= targetProgress) {
				const hasRewardTargetView = this.rewardTargetViewMap.has(targetProgress);
				if (this.model.isRewardCollected(targetProgress)) {
					if (hasRewardTargetView) {
						const rewardView = this.rewardTargetViewMap.get(targetProgress);
						EpicQuestCollectionsProgressView.setRewardViewCollected(rewardView);
					}
				} else if (hasRewardTargetView) {
					const rewardView = this.rewardTargetViewMap.get(targetProgress);
					EpicQuestCollectionsProgressView.setRewardViewCompleted(rewardView);
				}
			}
		});

		this.onProgress();
	}

	public getActiveLootbox(): PIXI.Container {
		const currentProgress: number = this.model.getCurrentProgress();
		const progress: number = Math.max(...this.model.getTargetProgresses().filter(x => x <= currentProgress));
		return this.rewardTargetViewMap.get(progress).rewardBgSprite;
	}

	public getType(): EpicQuestCollectionsTypes {
		return this.model.getType();
	}

	public setDefaultText(text: string): void {
		this.progressTextDefault = text;
		this.updateProgressText();
	}

	private onProgress(): void {
		this.updateProgressText();
		this.updateProgressBar();
	}

	private onSomeRewardReached(targetProgress: number): void {
		if (this.rewardTargetViewMap.has(targetProgress)) {
			const rewardView = this.rewardTargetViewMap.get(targetProgress);
			EpicQuestCollectionsProgressView.setRewardViewCompleted(rewardView);
		}
	}

	private onSomeRewardTargetCollected(lootbox: LootboxModel, targetProgress: number): void {
		if (this.rewardTargetViewMap.has(targetProgress)) {
			const rewardView = this.rewardTargetViewMap.get(targetProgress);
			EpicQuestCollectionsProgressView.setRewardViewCollected(rewardView);
		}
	}

	private updateProgressBar(): void {
		const currentTargetProgress = this.model.getCurrentTargetProgress();
		let currentRewardViewRightX: number;
		if (this.rewardTargetViewMap.has(currentTargetProgress)) {
			const currentRewardView = this.rewardTargetViewMap.get(currentTargetProgress);
			currentRewardViewRightX = currentRewardView.progressBarOffsetRightX;
		} else {
			const rewardTargetPosition = EpicQuestCollectionsProgressView.getRewardTargetPosition(0, this.model.getTargetProgresses().length);
			currentRewardViewRightX = rewardTargetPosition - this.progressBar.x;
		}
		this.progressBar.width = currentRewardViewRightX;

		let nextRewardViewLeftX: number;
		if (this.model.hasNextTargetProgress()) {
			const nextTargetProgress = this.model.getNextTargetProgress();
			if (this.rewardTargetViewMap.has(nextTargetProgress)) {
				const nextRewardView = this.rewardTargetViewMap.get(nextTargetProgress);
				nextRewardViewLeftX = nextRewardView.progressBarOffsetLeftX;
			} else {
				nextRewardViewLeftX = this.lootboxIconContainer.x - this.progressBar.x;
			}

			const deltaRewardViewsX = nextRewardViewLeftX - currentRewardViewRightX;
			const deltaRewardCount = this.model.getNextTargetProgress() - this.model.getCurrentTargetProgress();
			const deltaProgress = deltaRewardViewsX / deltaRewardCount;
			this.progressBar.width += deltaProgress * (this.model.getCurrentProgress() - this.model.getCurrentTargetProgress());
		} else {
			this.progressBar.width += this.lootboxIconContainer.x - this.progressBar.x;
		}
	}

	private updateProgressText(): void {
		this.progressText.text = this.progressTextDefault.replace('{{current}}', this.model.getCurrentProgress().toString())
			.replace('{{total}}', this.totalProgress.toString());
	}

	private onLootboxIconClick(): void {
		const targetProgress = this.model.getTotalTargetProgress();
		if (this.model.getCurrentProgress() >= targetProgress) {
			this.onCollectRewardTarget(targetProgress);
		} else {
			const hintPivot = this.lootboxIconContainer;
			this.showHint(hintPivot.position, 0.75);
		}
	}

	private onRewardTargetClick(targetProgress: number): void {
		if (this.model.getCurrentProgress() >= targetProgress) {
			this.onCollectRewardTarget(targetProgress);
		} else {
			const hintPivot = this.rewardTargetViewMap.get(targetProgress);
			this.showHint(hintPivot.position);
		}
	}

	private onCollectRewardTarget(targetProgress: number): void {
		this.emit(
			EpicQuestCollectionsProgressView.EVENT_COLLECT_REWARD_TARGET,
			this.model.getType(),
			targetProgress,
		);
	}

	private showHint(pivot: PIXI.Point, bodyArrowOffset: number = 0.5): void {
		const arrowPosLocal = new PIXI.Point(pivot.x, pivot.y + 60);
		this.emit(EpicQuestCollectionsProgressView.EVENT_SHOW_HINT, bodyArrowOffset, arrowPosLocal, this);
	}


	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(EpicQuestCollectionsModel.EVENT_PROGRESS, this.onProgress, this);
		this.model.off(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.onSomeRewardReached, this);
		this.model.off(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.onSomeRewardTargetCollected, this);

		super.destroy(options);
	}
}
