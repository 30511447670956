import { TotemModel } from '@models/TotemModel';
import { BusinessModel } from '@models/BusinessModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { FarewellPartyModel } from '@models/FarewellPartyModel';

export class ModelWriterTotem {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly farewellPartyModels: Map<string, FarewellPartyModel>;
	private readonly totemModels: Map<string, TotemModel>;

	constructor(
		totemModels: Map<string, TotemModel>,
		businessModels: Map<string, BusinessModel>,
		farewellPartyModels: Map<string, FarewellPartyModel>,
	) {
		this.businessModels = businessModels;
		this.farewellPartyModels = farewellPartyModels;
		this.totemModels = totemModels;

		this.totemModels.forEach((totem) => {
			totem.on(TotemModel.EVENT_PROMOTED, this.onTotemPromoted, this);
			totem.on(TotemModel.EVENT_OPENED, this.onTotemOpened, this);
		});
	}

	private onTotemPromoted(totem: TotemModel): void {
		this.setBusinessMultipliers(totem);
		this.setFarewellPartyMultipliers(totem);
	}

	private onTotemOpened(totem: TotemModel): void {
		this.setBusinessMultipliers(totem);
		this.setFarewellPartyMultipliers(totem);
	}

	private setFarewellPartyMultipliers(totem: TotemModel): void {
		const totemKey = totem.getKey();
		totem.getBonuses().forEach((bonus, type) => {
			const bonusValue = bonus.getValue();
			// eslint-disable-next-line default-case
			switch (type) {
				case BonusTypes.MULTIPLIER_FUCKPOWER:
					this.farewellPartyModels.forEach(model => model.setMultiplierFuckpowerTotem(totemKey, bonusValue));
					break;

				case BonusTypes.FUCKTIME:
					this.farewellPartyModels.forEach(model => model.setMultiplierFucktimeTotem(totemKey, bonusValue));
					break;
			}
		});
		this.farewellPartyModels.forEach(model => model.setTotemLevel(totemKey, totem.getLevel()));
	}

	private setBusinessMultipliers(totem: TotemModel): void {
		const totemKey = totem.getKey();
		const updateMultipliers = (business: BusinessModel): void => {
			totem.getBonuses().forEach((bonus, type) => {
				const bonusValue = bonus.getValue();
				// eslint-disable-next-line default-case
				switch (type) {
					case BonusTypes.MULTIPLIER_INCOME:
						business.setTotemMultiplierIncome(totemKey, bonusValue);
						break;

					case BonusTypes.MULTIPLIER_TIME:
						business.setTotemMultiplierTime(totemKey, bonusValue);
						break;

					case BonusTypes.DECREASE_CUSTOMER_COST:
						business.setTotemMultiplierDecreaseCustomerCost(totemKey, bonusValue);
						break;

					case BonusTypes.REDUCE_TIME_INCOME_BY_CLICK:
						business.setTotemReducePercentIncomeByClick(totemKey, bonusValue);
						break;
				}
			});
		};

		if (totem.isAllBusinessAffected()) {
			this.businessModels.forEach(model => updateMultipliers(model));
		} else {
			const business = this.businessModels.get(totem.getAffectTarget());
			updateMultipliers(business);
		}
	}
}
