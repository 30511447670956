import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { DialogConfig } from '@configs/DialogConfig';

export class DialogsConfigSetParser extends BaseConfigSetParser<DialogConfig> {
	public parseConfigSet(configSetJSON: any[]): void {
		let currentId = '';
		let currentConfig: DialogConfig;

		configSetJSON.forEach((config: { [key: string]: string }) => {
			const configId = config[this.configSetKeyField];
			if (configId !== currentId) {
				currentConfig = BaseConfigSetParser.createConfig(DialogConfig, config);
				currentId = configId;
				this.configSet.set(configId, currentConfig);
			}
			currentConfig.addDialogDataItem(config);
		});
	}
}
