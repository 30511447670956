import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import * as TWEEN from '@tweenjs/tween.js';
import { LevelChallengeUIHelper } from './LevelChallengeUIHelper';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class LevelChallengeView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly tickerTween: PIXI.ticker.Ticker;
	private readonly tickerTimer: PIXI.ticker.Ticker;
	private timerTicking: boolean;

	private readonly background: PIXI.mesh.NineSlicePlane;
	private readonly textReward: PIXI.extras.BitmapText;
	private readonly timerLabel: PIXI.extras.BitmapText;
	private readonly titleLabel: PIXI.extras.BitmapText;
	private readonly contentInteractiveContainer: PIXI.Container;
	private readonly contentInteractive: ButtonBaseView;
	private readonly rareBg: PIXI.mesh.NineSlicePlane;
	private readonly rareLineDecor: PIXI.Sprite;
	private readonly icon: PIXI.Sprite;

	private levelChallengeModel: LevelChallengeModel;

	private tweenGroup: TWEEN.Group;

	constructor(levelChallengeModel: LevelChallengeModel) {
		super();

		this.levelChallengeModel = levelChallengeModel;
		this.levelChallengeModel.on(LevelChallengeModel.EVENT_TIMEOUT, this.onTimeout, this);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onUpdateText, this);

		this.rareBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['level_time_panel_gold'], 20, 0, 20, 0);
		this.rareBg.width = 321;
		this.rareBg.pivot.set(this.rareBg.width / 2, this.rareBg.height / 2);
		this.rareBg.position.set(-20, -1);

		const girlsDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['level_time_panel_girls']);
		girlsDecor.position.set(-61, -4);

		this.titleLabel = new SizeableBitmapText('', 200, { font: '18px wendyOneShadowBold' });
		this.titleLabel.anchor = 0.5;
		this.titleLabel.position.set(-62, -18);

		this.timerLabel = new PIXI.extras.BitmapText('', { font: '28px wendyOneShadowBold', tint: 0xffc900 });
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(-62, 8);

		this.icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main']);
		this.icon.position.set(90, -8);
		this.icon.rotation = Math.PI / 180 * -8;
		this.icon.scale.set(0.41);

		const glowMask = new PIXI.Graphics()
			.beginFill(0xffffff)
			.drawRect(50, -4, 90, 58)
			.endFill();
		glowMask.pivot.set(glowMask.width / 2, glowMask.height / 2);

		this.rareLineDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_gold_glow']);
		this.rareLineDecor.blendMode = PIXI.BLEND_MODES.SCREEN;
		this.rareLineDecor.mask = glowMask;
		this.rareLineDecor.rotation = Math.PI / 180 * 4;
		this.rareLineDecor.x = 45;
		this.rareLineDecor.scale.set(0.6);

		this.tweenGroup = new TWEEN.Group();

		this.tickerTween = PIXI.ticker.shared;
		this.tickerTimer = PIXI.ticker.shared;

		this.tickerTween.add(this.updateTweenGroup, this);

		this.contentInteractiveContainer = new PIXI.Container();
		this.contentInteractiveContainer.addChild(
			this.rareBg,
			girlsDecor as PIXI.DisplayObject,
			glowMask,
			this.rareLineDecor,
			this.titleLabel,
			this.timerLabel,
			this.icon,
		);
		this.contentInteractive = new ButtonBaseView(
			this.contentInteractiveContainer,
			1.05,
			0.97,
		);
		this.contentInteractive.on(ButtonBaseView.EVENT_CLICK, () => this.emit(LevelChallengeView.EVENT_CLICK), this);

		this.onUpdateText();

		this.addChild(
			this.contentInteractive as PIXI.DisplayObject,
		);
	}

	public init(): void {
		if (!this.levelChallengeModel.hasChallenges()) {
			this.visible = false;
		} else {
			this.visible = true;
			this.updateContents();
			this.onUpdateText();

			if (!this.timerTicking && this.levelChallengeModel.isActive()) {
				this.startUpdateTimer();
			}
		}
	}

	public startCompletedAnimation(): Promise<void> {
		return new Promise((resolve) => {
			this.titleLabel.visible = false;
			this.timerLabel.visible = false;

			const completedSprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['completed_sticker']);
			completedSprite.position.set(-42, -3);
			this.contentInteractiveContainer.addChild(completedSprite);

			completedSprite.scale.set(0.6, 0.6);
			const tweenCompletedBounce = new TWEEN.Tween(completedSprite.scale, this.tweenGroup)
				.to({ x: 0.45, y: 0.45 }, 400)
				.easing(TWEEN.Easing.Elastic.Out);

			completedSprite.alpha = 0;
			const tweenCompletedAlpha = new TWEEN.Tween(completedSprite, this.tweenGroup)
				.to({ alpha: 1 }, 50)
				.start();

			const tweenContentInteractiveHide = new TWEEN.Tween(this.contentInteractive, this.tweenGroup)
				.delay(250)
				.to({ alpha: 0 }, 400)
				.onComplete(() => {
					this.visible = false;
					this.titleLabel.visible = true;
					this.timerLabel.visible = true;

					this.contentInteractive.alpha = 1;
					completedSprite.destroy();

					resolve();
				});

			tweenCompletedBounce
				.chain(tweenContentInteractiveHide)
				.start();
			tweenCompletedAlpha.start();
		});
	}

	private layoutLabels(): void {
		if (this.levelChallengeModel.isActive()) {
			this.timerLabel.visible = true;
			this.titleLabel.visible = true;
			this.timerLabel.position.set(-62, 8);
			this.titleLabel.position.set(-62, -18);
		} else {
			this.timerLabel.visible = false;
			this.titleLabel.position.set(-62, -5);
		}
	}

	private onTimeout(): void {
		this.titleLabel.visible = false;
		this.timerLabel.visible = false;

		const failedSprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['failed_sticker']);
		failedSprite.position.set(-42, -3);
		this.contentInteractiveContainer.addChild(failedSprite);

		failedSprite.scale.set(0.6, 0.6);
		const tweenFailedBounce = new TWEEN.Tween(failedSprite.scale, this.tweenGroup)
			.to({ x: 0.45, y: 0.45 }, 400)
			.easing(TWEEN.Easing.Elastic.Out);

		failedSprite.alpha = 0;
		const tweenFailedAlpha = new TWEEN.Tween(failedSprite, this.tweenGroup)
			.to({ alpha: 1 }, 50)
			.start();

		const tweenInteractiveFadeOut = new TWEEN.Tween(this.contentInteractive, this.tweenGroup)
			.to({ alpha: 0 }, 400)
			.onComplete(() => {
				this.updateContents();

				failedSprite.destroy();
				this.titleLabel.visible = true;
				this.timerLabel.visible = this.levelChallengeModel.isActive();
			});

		const tweenInteractiveFadeIn = new TWEEN.Tween(this.contentInteractive, this.tweenGroup)
			.to({ alpha: 1 }, 400);

		tweenFailedBounce.chain(tweenInteractiveFadeOut);
		tweenInteractiveFadeOut.chain(tweenInteractiveFadeIn);

		tweenFailedBounce.start();
		tweenFailedAlpha.start();
	}

	private updateContents(): void {
		let rareBgTexture: PIXI.Texture;
		let rareLineDecorTexture: PIXI.Texture;
		let rareIconTexture: PIXI.Texture;
		switch (this.levelChallengeModel.getActiveTargetTimeId()) {
			case 0: {
				rareBgTexture = AssetsStorage.getAtlas('uiAtlas')['level_time_panel_gold'];
				rareLineDecorTexture = AssetsStorage.getAtlas('uiAtlas')['timed_gold_glow'];
				rareIconTexture = AssetsStorage.getAtlas('lootboxAtlas')['legendary_lootbox_main'];
				break;
			}
			case 1: {
				rareBgTexture = AssetsStorage.getAtlas('uiAtlas')['level_time_panel_silver'];
				rareLineDecorTexture = AssetsStorage.getAtlas('uiAtlas')['timed_silver_glow'];
				rareIconTexture = AssetsStorage.getAtlas('lootboxAtlas')['epic_lootbox_main'];
				break;
			}
			case 2: {
				rareBgTexture = AssetsStorage.getAtlas('uiAtlas')['level_time_panel_bronze'];
				rareLineDecorTexture = AssetsStorage.getAtlas('uiAtlas')['timed_bronze_glow'];
				rareIconTexture = AssetsStorage.getAtlas('lootboxAtlas')['rare_lootbox_main'];
				break;
			}
			default: {
				rareBgTexture = AssetsStorage.getAtlas('uiAtlas')['level_time_panel_purple'];
				rareLineDecorTexture = AssetsStorage.getAtlas('uiAtlas')['timed_purple_glow'];
				rareIconTexture = AssetsStorage.getAtlas('lootboxAtlas')['common_lootbox_main'];
				break;
			}
		}
		this.rareBg.texture = rareBgTexture;
		this.rareLineDecor.texture = rareLineDecorTexture;
		this.icon.texture = rareIconTexture;

		this.onUpdateText();
		this.layoutLabels();
	}

	private startUpdateTimer(): void {
		this.timerTicking = true;
		this.tickerTimer.add(this.onUpdateTextTimer, this);
	}

	private stopUpdateTimer(): void {
		this.timerTicking = false;
		this.tickerTimer.remove(this.onUpdateTextTimer, this);
	}

	private onUpdateText(): void {
		const localizationKey: string = this.levelChallengeModel.isActive() ? '#label_level_main_quest' : '#label_level_challenges_expired';
		const stringTitle = this.localizationStorage.getLocalizedString(localizationKey);
		this.titleLabel.text = stringTitle;
	}

	private onUpdateTextTimer(): void {
		const timeleft = this.levelChallengeModel.getActiveTargetTimeleft();
		this.timerLabel.text = LevelChallengeUIHelper.getTimeString(timeleft);
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.tickerTimer.lastTime);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.timerTicking) {
			this.stopUpdateTimer();
		}

		this.levelChallengeModel.off(LevelChallengeModel.EVENT_TIMEOUT, this.onTimeout, this);

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onUpdateText, this);

		this.tweenGroup.removeAll();
		this.tickerTween.remove(this.updateTweenGroup, this);

		super.destroy(options);
	}
}
