import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestAcquireBusiness } from '@models/quests/QuestAcquireBusiness';
import { QuestGetCards } from '@models/quests/QuestGetCards';
import { QuestGetSoftMoney } from '@models/quests/QuestGetSoftMoney';
import { QuestGetCustomersMultiplier } from '@models/quests/QuestGetCustomersMultiplier';
import { QuestSpendMoney } from '@models/quests/QuestSpendPrestigeMoney';
import { QuestGetCustomersOnSome } from '@models/quests/QuestGetCustomersOnSome';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { QuestAcquireBusinessConfig, QuestAcquireBusinessesConfig } from '@configs/quests/QuestAcquireBusinessConfig';
import { QuestActivateCharactersConfig, QuestActivateUpgradesConfig, QuestActivatePartyConfig } from '@configs/quests/QuestActivateConfig';
import { QuestGetCardsConfig } from '@configs/quests/QuestGetCardsConfig';
import { QuestGetCustomersConfig } from '@configs/quests/QuestGetCustomersConfig';
import { QuestGetPrestigeMoneyConfig } from '@configs/quests/QuestGetPrestigeMoneyConfig';
import { QuestGetCustomersMultiplierConfig } from '@configs/quests/QuestGetCustomersMultiplierConfig';
import { QuestSpendMoneyConfig } from '@configs/quests/QuestSpendMoneyConfig';
import { QuestGetCustomersOnSomeConfig } from '@configs/quests/QuestGetCustomersOnSomeConfig';
import {
	QuestPromoteCharactersConfig,
	QuestPromoteUpgradesConfig,
	QuestPromoteCharactersOrUpgradesConfig,
	QuestPromoteAllConfig,
} from '@configs/quests/QuestPromoteConfig';
import { QuestTypes } from '@src/types/QuestTypes';
import { PromotableModel } from '@models/PromotableModel';
import { BusinessModel } from '@models/BusinessModel';
import { QuestGetCustomersTarget } from '../models/quests/QuestGetCustomersTarget';
import { QuestGetCustomersAll } from '../models/quests/QuestGetCustomersAll';
import { UpgradeModel } from '@models/UpgradeModel';
import { CharacterModel } from '@models/CharacterModel';
import { TotemModel } from '@models/TotemModel';
import { QuestPromoteTarget } from '../models/quests/QuestPromoteTarget';
import { QuestPromoteTargets } from '../models/quests/QuestPromoteTargets';
import { QuestActivateTarget } from '../models/quests/QuestActivateTarget';
import { QuestActivateTargets } from '../models/quests/QuestActivateTargets';
import { QuestGetSoftMoneyFromBusiness } from '../models/quests/QuestGetSoftMoneyFromBusiness';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { QuestAcquireBusinesses } from '../models/quests/QuestAcquireBusinesses';
import { QuestAutomateBusinessConfig, QuestAutomateBusinessesConfig } from '@configs/quests/QuestAutomateBusinessConfig';
import { QuestAutomateBusiness } from '../models/quests/QuestAutomateBusiness';
import { QuestAutomateBusinesses } from '../models/quests/QuestAutomateBusinesses';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { QuestSummonConfig } from '@configs/quests/QuestSummonConfig';
import { QuestSummon } from '@models/quests/QuestSummon';
import { QuestActivateParty } from '@models/quests/QuestActivateParty';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { QuestGetPrestigeOrHardMoney } from '@models/quests/QuestGetPrestigeMoney';
import { QuestGetSoftMoneyBaseConfig } from '@configs/quests/QuestGetSoftMoneyBaseConfig';

export class QuestFactory {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly summonModelsPool: SummonModelsPool;
	private readonly farewellParyModels: Map<string, FarewellPartyModel>;

	constructor(
		businessModels: Map<string, BusinessModel>,
		upgradeModels: Map<string, UpgradeModel>,
		characterModels: Map<string, CharacterModel>,
		totemModels: Map<string, TotemModel>,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		summonModelsPool: SummonModelsPool,
		farewellPartyModels: Map<string, FarewellPartyModel>,
	) {
		this.businessModels = businessModels;
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.softMoneyModel = softMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.summonModelsPool = summonModelsPool;
		this.farewellParyModels = farewellPartyModels;
		this.hardMoneyModel = hardMoneyModel;
	}

	public createQuest(
		config: QuestConfigBase,
		completeValue: string,
		lineId: number,
		time?: number,
	): AbstractQuest {
		let quest: AbstractQuest;
		switch (config.getType()) {
			case QuestTypes.ACQUIRE_BUSINESS: {
				const configAquireBusiness = config as QuestAcquireBusinessConfig;
				const targetBusiness = this.businessModels.get(configAquireBusiness.getTargetKey());
				quest = new QuestAcquireBusiness(
					configAquireBusiness.getKey(),
					targetBusiness,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACQUIRE_BUSINESSES: {
				const configAquireBusinesses = config as QuestAcquireBusinessesConfig;
				const acquireCount = configAquireBusinesses.getAcquireCount();
				quest = new QuestAcquireBusinesses(
					config.getKey(),
					acquireCount,
					Array.from(this.businessModels.values()),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.AUTOMATE_BUSINESS: {
				const configAutomateBusiness = config as QuestAutomateBusinessConfig;
				const targetBusiness = this.businessModels.get(configAutomateBusiness.getTargetKey());
				quest = new QuestAutomateBusiness(
					configAutomateBusiness.getKey(),
					targetBusiness,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.AUTOMATE_BUSINESSES: {
				const configAutomateBusinesses = config as QuestAutomateBusinessesConfig;
				const automateCount = configAutomateBusinesses.getAutomateCount();
				quest = new QuestAutomateBusinesses(
					config.getKey(),
					Array.from(this.businessModels.values()),
					automateCount,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CARDS: {
				const configGetCards = config as QuestGetCardsConfig;
				const targets: PromotableModel[] = [];
				targets.push(...Array.from(this.upgradeModels.values()));
				targets.push(...Array.from(this.characterModels.values()));
				targets.push(...Array.from(this.totemModels.values()));
				quest = new QuestGetCards(
					configGetCards.getKey(),
					targets,
					configGetCards.getAmount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_TARGET: {
				const configGetCustomersTarget = config as QuestGetCustomersConfig;
				const targetBusiness = this.businessModels.get(configGetCustomersTarget.getBusinessKey());
				let targetAmount: number;
				if (configGetCustomersTarget.isAutoGenerateAmount()) {
					targetAmount = Number(completeValue);
				} else {
					targetAmount = configGetCustomersTarget.getAmount();
				}
				quest = new QuestGetCustomersTarget(
					configGetCustomersTarget.getKey(),
					targetBusiness,
					targetAmount,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_ALL: {
				const configGetCustomersAll = config as QuestGetCustomersConfig;
				const targetBusinesses = Array.from(this.businessModels.values());
				let targetAmount: number;
				if (configGetCustomersAll.isAutoGenerateAmount()) {
					targetAmount = Number(completeValue);
				} else {
					targetAmount = configGetCustomersAll.getAmount();
				}
				quest = new QuestGetCustomersAll(
					configGetCustomersAll.getKey(),
					targetBusinesses,
					targetAmount,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_ON_SOME: {
				const configOnSome = config as QuestGetCustomersOnSomeConfig;
				const targets = Array.from(this.businessModels.values());
				quest = new QuestGetCustomersOnSome(
					configOnSome.getKey(),
					targets,
					configOnSome.getAmount(),
					configOnSome.getBusinessesCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER: {
				const configGetMultiplier = config as QuestGetCustomersMultiplierConfig;
				let targetAmount: number;
				if (configGetMultiplier.isAutoGenerateAmount()) {
					targetAmount = Number(completeValue);
				} else {
					targetAmount = configGetMultiplier.getAmount();
				}
				quest = new QuestGetCustomersMultiplier(
					configGetMultiplier.getKey(),
					Array.from(this.businessModels.values()),
					targetAmount,
					QuestTypes.GET_CUSTOMERS_MULTIPLIER,
					lineId,
					undefined,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO: {
				const configGetMultiplier = config as QuestGetCustomersMultiplierConfig;
				const business = this.businessModels.get(configGetMultiplier.getBusinessKey());
				let targetAmount: number;
				if (configGetMultiplier.isAutoGenerateAmount()) {
					targetAmount = Number(completeValue);
				} else {
					targetAmount = configGetMultiplier.getAmount();
				}
				quest = new QuestGetCustomersMultiplier(
					configGetMultiplier.getKey(),
					[business],
					targetAmount,
					QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO,
					lineId,
					undefined,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT: {
				const configGetMultiplier = config as QuestGetCustomersMultiplierConfig;
				const business = this.businessModels.get(configGetMultiplier.getBusinessKey());
				let targetAmount: number;
				if (configGetMultiplier.isAutoGenerateAmount()) {
					targetAmount = Number(completeValue);
				} else {
					targetAmount = configGetMultiplier.getAmount();
				}
				quest = new QuestGetCustomersMultiplier(
					configGetMultiplier.getKey(),
					[business],
					targetAmount,
					QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT,
					lineId,
					false,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_PRESTIGE_MONEY: {
				const configGetPrestige = config as QuestGetPrestigeMoneyConfig;
				quest = new QuestGetPrestigeOrHardMoney<PrestigeMoneyModel>(
					configGetPrestige.getKey(),
					QuestTypes.GET_PRESTIGE_MONEY,
					this.prestigeMoneyModel,
					configGetPrestige.getAmount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_HARD_MONEY: {
				const configGetPrestige = config as QuestGetPrestigeMoneyConfig;
				quest = new QuestGetPrestigeOrHardMoney<HardMoneyModel>(
					configGetPrestige.getKey(),
					QuestTypes.GET_HARD_MONEY,
					this.hardMoneyModel,
					configGetPrestige.getAmount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.SPEND_HARD_MONEY: {
				const configSpendMoney = config as QuestSpendMoneyConfig;
				quest = new QuestSpendMoney<HardMoneyModel>(
					configSpendMoney.getKey(),
					QuestTypes.SPEND_HARD_MONEY,
					this.hardMoneyModel,
					configSpendMoney.getAmount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.SPEND_PRESTIGE_MONEY: {
				const configSpendMoney = config as QuestSpendMoneyConfig;
				quest = new QuestSpendMoney<PrestigeMoneyModel>(
					configSpendMoney.getKey(),
					QuestTypes.SPEND_PRESTIGE_MONEY,
					this.prestigeMoneyModel,
					configSpendMoney.getAmount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTER: {
				const configPromoteCharacter = config as QuestPromoteCharactersConfig;
				const character = this.characterModels.get(configPromoteCharacter.getTargetKey());
				quest = new QuestPromoteTarget<CharacterModel>(
					configPromoteCharacter.getKey(),
					QuestTypes.PROMOTE_CHARACTER,
					character,
					configPromoteCharacter.getTargetLevel(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTERS: {
				const configPromote = config as QuestPromoteCharactersConfig;
				const targets = Array.from(this.characterModels.values());
				quest = new QuestPromoteTargets<CharacterModel>(
					configPromote.getKey(),
					QuestTypes.PROMOTE_CHARACTERS,
					targets,
					configPromote.getPromoteCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_UPGRADE: {
				const configPromoteUpgrade = config as QuestPromoteUpgradesConfig;
				const upgrade = this.upgradeModels.get(configPromoteUpgrade.getTargetKey());
				quest = new QuestPromoteTarget<UpgradeModel>(
					configPromoteUpgrade.getKey(),
					QuestTypes.PROMOTE_UPGRADE,
					upgrade,
					configPromoteUpgrade.getTargetLevel(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_UPGRADES: {
				const configPromote = config as QuestPromoteUpgradesConfig;
				const targets = Array.from(this.upgradeModels.values());
				quest = new QuestPromoteTargets<UpgradeModel>(
					configPromote.getKey(),
					QuestTypes.PROMOTE_UPGRADES,
					targets,
					configPromote.getPromoteCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES: {
				const configPromote = config as QuestPromoteCharactersOrUpgradesConfig;
				const upgrades: PromotableModel[] = Array.from(this.upgradeModels.values());
				const characters: PromotableModel[] = Array.from(this.characterModels.values());
				quest = new QuestPromoteTargets<PromotableModel>(
					configPromote.getKey(),
					QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES,
					upgrades.concat(characters),
					configPromote.getPromoteCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.PROMOTE_ALL: {
				const configPromote = config as QuestPromoteAllConfig;
				const upgrades: PromotableModel[] = Array.from(this.upgradeModels.values());
				const characters: PromotableModel[] = Array.from(this.characterModels.values());
				const totems: PromotableModel[] = Array.from(this.totemModels.values());
				quest = new QuestPromoteTargets<PromotableModel>(
					configPromote.getKey(),
					QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES,
					upgrades.concat(characters, totems),
					configPromote.getPromoteCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACTIVATE_CHARACTER: {
				const configActivate = config as QuestActivateCharactersConfig;
				const character = this.characterModels.get(configActivate.getTargetKey());
				quest = new QuestActivateTarget<CharacterModel>(
					configActivate.getKey(),
					QuestTypes.ACTIVATE_CHARACTER,
					character,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACTIVATE_CHARACTERS: {
				const configActivate = config as QuestActivateCharactersConfig;
				const targets = Array.from(this.characterModels.values());
				quest = new QuestActivateTargets<CharacterModel>(
					configActivate.getKey(),
					QuestTypes.ACTIVATE_CHARACTERS,
					targets,
					configActivate.getActivateCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACTIVATE_UPGRADE: {
				const configActivate = config as QuestActivateUpgradesConfig;
				const upgrade = this.upgradeModels.get(configActivate.getTargetKey());
				quest = new QuestActivateTarget<UpgradeModel>(
					configActivate.getKey(),
					QuestTypes.ACTIVATE_UPGRADE,
					upgrade,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACTIVATE_UPGRADES: {
				const configActivate = config as QuestActivateUpgradesConfig;
				const targets = Array.from(this.upgradeModels.values());
				quest = new QuestActivateTargets<UpgradeModel>(
					configActivate.getKey(),
					QuestTypes.ACTIVATE_UPGRADES,
					targets,
					configActivate.getActivateCount(),
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.ACTIVATE_PARTY: {
				const configParty = config as QuestActivatePartyConfig;
				const targetAmount: number = Number(completeValue);
				quest = new QuestActivateParty(
					configParty.getKey(),
					this.farewellParyModels,
					QuestTypes.ACTIVATE_PARTY,
					targetAmount,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}
			case QuestTypes.GET_SOFT_MONEY:
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS:
			case QuestTypes.GET_SOFT_MONEY_TUTORIAL:
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS_TUTORIAL: {
				const configGetSoft = config as QuestGetSoftMoneyBaseConfig;
				const businessKey = configGetSoft.getBusinessKey();
				let targetAmount: SoftMoneyNumber;

				if (configGetSoft.isAutoGenerateAmount()) {
					targetAmount = SoftMoneyNumber.createFromString(completeValue);
				} else {
					targetAmount = configGetSoft.getAmount();
				}

				if (businessKey != null) {
					quest = new QuestGetSoftMoneyFromBusiness(
						configGetSoft.getKey(),
						configGetSoft.getType(),
						businessKey,
						this.softMoneyModel,
						lineId,
						config.getMainWindowViewParameters(),
						targetAmount,
						time,
					);
				} else {
					quest = new QuestGetSoftMoney(
						configGetSoft.getKey(),
						this.softMoneyModel,
						configGetSoft.getType(),
						lineId,
						config.getMainWindowViewParameters(),
						targetAmount,
						time,
					);
				}
				break;
			}

			case QuestTypes.SUMMON_HARD:
			case QuestTypes.SUMMON_PRESTIGE:
			case QuestTypes.SUMMON_PAY:
			case QuestTypes.SUMMON_ALL: {
				const configSummonAll = config as QuestSummonConfig;
				const targetAmount: number = Number(completeValue);

				quest = new QuestSummon(
					configSummonAll.getKey(),
					this.summonModelsPool,
					config.getType(),
					targetAmount,
					lineId,
					config.getMainWindowViewParameters(),
					time,
				);
				break;
			}

			default:
				throw new Error(`Failed creating quest! Unsupported quest type ${config.getType()}`);
		}
		return quest;
	}
}
