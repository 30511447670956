import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10001View extends CharacterMainBaseView {
	constructor() {
		super('character10001');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-45, 51);
		this.setAnimation(0, 'animation2', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(110, -28),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(140, -31),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
