import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';
import { AssetsMetadataElementType } from '@src/types/LevelAssetNames';

export class AssetLoader extends PIXI.loaders.Loader {
	constructor(
		assetUrlsLoader: AssetUrlsLoader,
	) {
		super();

		const assetUrls = assetUrlsLoader.getAssetUrls();

		this.pre((resource: PIXI.loaders.Resource, next) => {
			const pathParts = resource.url.split('/');
			const filename = pathParts[pathParts.length - 1];
			const newUrl = assetUrls.get(filename);

			if (newUrl != null) {
				// PIXI api doesn't provide public interface to override read-only url property
				Object.assign(resource, {
					url: newUrl,
				});

				next();
			} else {
				// eslint-disable-next-line no-console
				console.error(`Asset not found: ${resource.url}`);
				next();
			}
		});
	}

	public addAssetsToLoad(
		assetEntries: AssetsMetadataElementType[][],
	): void {
		assetEntries.forEach((assetEntry) => {
			assetEntry.forEach((entryElement) => this.add(
				entryElement.name,
				entryElement.path,
			));
		});
	}
}
