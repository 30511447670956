import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { PromotableModel } from '@models/PromotableModel';
import { UpgradeSaveData } from '@src/types/SaveTypes';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';

export class UpgradeModel extends PromotableModel {
	public static readonly EVENT_CUSTOMERS_REACHED = Symbol();
	public static readonly EVENT_UNLOCKED = Symbol();
	public static readonly EVENT_ACTIVATED = Symbol();

	protected readonly customerCount: number;
	protected readonly activateCost: SoftMoneyNumber;

	protected customersReached: boolean;
	protected locked: boolean;
	protected activated: boolean;
	protected relatedBusinessKey: string;

	constructor(
		config: UpgradeConfig,
		promotePatternsConfig: AbstractPromotePatternsConfig,
	) {
		super(
			config,
			promotePatternsConfig,
		);

		this.customerCount = config.getCustomerCount();
		this.activateCost = config.getActivateCost();

		this.customersReached = false;
		this.locked = true;
		this.activated = false;
	}

	public setFromSaveData(saveData: UpgradeSaveData): void {
		this.opened = true;
		this.activated = saveData.activated;
		this.level = saveData.level;
		this.cardsCount = saveData.cardsCount;
		this.newItem = saveData.isNew;

		this.recalculateBonuses();
	}

	public setCustomerReached(): void {
		this.customersReached = true;
	}

	public resetModel(): void {
		this.activated = false;
		this.locked = true;
		this.customersReached = false;
	}

	public getCustomerCount(): number {
		return this.customerCount;
	}

	public activate(): void {
		this.activated = true;
		this.emit(UpgradeModel.EVENT_ACTIVATED, this);
	}

	public setRelatedBusinessKey(key: string): void {
		this.relatedBusinessKey = key;
	}

	public isActivated(): boolean {
		return this.activated;
	}

	public isLocked(): boolean {
		return this.locked;
	}

	public getRelatedBusinessKey(): string {
		return this.relatedBusinessKey;
	}

	public open(): void {
		super.open();

		if (this.isCustomersReached()) {
			this.unlock();
		}
	}

	/**
	 * Set when upgrade was already unlocked
	 */
	public setUnlocked(): void {
		this.locked = false;
	}

	/**
	 * Set when upgrade was unlocked during the game
	 */
	public unlock(): void {
		this.locked = false;
		this.emit(UpgradeModel.EVENT_UNLOCKED, this);
	}

	public isCustomersReached(): boolean {
		return this.customersReached;
	}

	public setCustomersReached(): void {
		this.customersReached = true;

		if (this.opened && this.locked) {
			this.unlock();
		}

		this.emit(UpgradeModel.EVENT_CUSTOMERS_REACHED, this);
	}

	public getActivateCost(): SoftMoneyNumber {
		return this.activateCost;
	}

	public isFreeActivation(): boolean {
		return this.activateCost.equalTo(SoftMoneyNumber.ZERO);
	}
}
