import * as TWEEN from '@tweenjs/tween.js';
import { GameConstants } from '@src/utils/GameConstants';
import { ScrollView } from './ScrollView';
import { ScrollAxis } from '@src/types/ScrollViewTypes';

export class ViewportView extends ScrollView {
	public static readonly ZOOM_VALUE: number = 2.8;

	private static readonly ZOOM_IN_DURATION: number = 200;
	private static readonly ZOOM_OUT_DURATION: number = 200;

	private static readonly PARALLAX_VALUE: number = 0.1;

	public static readonly EVENT_ZOOM_IN_COMPLETED: symbol = Symbol();
	public static readonly EVENT_ZOOM_IN_STARTED: symbol = Symbol();
	public static readonly EVENT_ZOOM_OUT_COMPLETED: symbol = Symbol();
	public static readonly EVENT_ZOOM_OUT_STARTED: symbol = Symbol();

	private tweenGroup: TWEEN.Group;

	private parallaxEnabled: boolean;
	private parallaxTarget: PIXI.Container;

	private offsetXBeforeZoom: number;
	private offsetYBeforeZoom: number;

	private zoomed: boolean;
	private zooming: boolean;
	private blackout: PIXI.Container;

	constructor() {
		super(GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT, ScrollAxis.HORIZONTAL, { begin: 0, end: 0 }, false, false);

		this.parallaxEnabled = false;
		this.zoomed = false;
		this.zooming = false;
	}

	public setParallax(target: PIXI.Container): this {
		this.parallaxEnabled = true;
		this.parallaxTarget = target;
		return this;
	}

	public setBlackout(target: PIXI.Container | null): this {
		if (target === null) {
			this.content.removeChild(this.blackout);
		} else {
			this.content.addChild(target);
		}
		this.blackout = target;
		return this;
	}

	public zoomIn(target: PIXI.Point): this {
		this.zooming = true;
		this.disable();

		this.emit(ViewportView.EVENT_ZOOM_IN_STARTED);

		const zoomTargetPosGlobal = this.toGlobal(target);

		this.offsetXBeforeZoom = this.content.x;
		this.offsetYBeforeZoom = this.content.y;

		let finalX = -zoomTargetPosGlobal.x * ViewportView.ZOOM_VALUE;
		let finalY = -zoomTargetPosGlobal.y * ViewportView.ZOOM_VALUE;
		finalX += GameConstants.GAME_WIDTH * 0.5;
		finalY += GameConstants.GAME_HEIGHT * 0.5 - 116;

		new TWEEN.Tween(this.content.scale, this.tweenGroup)
			.to({ x: ViewportView.ZOOM_VALUE, y: ViewportView.ZOOM_VALUE }, ViewportView.ZOOM_IN_DURATION)
			.easing(TWEEN.Easing.Linear.None)
			.start();
		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ x: finalX, y: finalY }, ViewportView.ZOOM_IN_DURATION)
			.onComplete(() => this.onZoomInCompleted())
			.easing(TWEEN.Easing.Linear.None)
			.start();
		return this;
	}

	private onZoomInCompleted(): void {
		this.zoomed = true;
		this.zooming = false;

		this.emit(ViewportView.EVENT_ZOOM_IN_COMPLETED);
	}

	private onZoomOutCompleted(enableDrag: boolean): void {
		this.content.position.set(this.offsetXBeforeZoom, this.offsetYBeforeZoom);
		if (enableDrag) {
			this.enable();
		}
		this.zoomed = false;
		this.zooming = false;

		this.emit(ViewportView.EVENT_ZOOM_OUT_COMPLETED);
	}

	public zoomOut(enableDrag: boolean = true): this {
		this.zooming = true;

		this.emit(ViewportView.EVENT_ZOOM_OUT_STARTED);

		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ x: this.offsetXBeforeZoom, y: this.offsetYBeforeZoom }, ViewportView.ZOOM_OUT_DURATION)
			.start();
		new TWEEN.Tween(this.content.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, ViewportView.ZOOM_OUT_DURATION)
			.onComplete(() => this.onZoomOutCompleted(enableDrag))
			.start();
		return this;
	}

	public isZoomed(): boolean {
		return this.zoomed;
	}

	public isZooming(): boolean {
		return this.zooming;
	}

	public moveTo(targetX: number, duration: number, easing: any = TWEEN.Easing.Linear.None): Promise<void> {
		return new Promise(resolve => {
			const tempPoint: PIXI.Point = new PIXI.Point(this.content.x, this.content.y);
			new TWEEN.Tween(tempPoint, this.tweenGroup)
				.to({ x: -targetX }, duration)
				.easing(easing)
				.onUpdate(() => {
					this.updateScrollValue(tempPoint.x - this.content.x, tempPoint.y - this.content.y);
				})
				.onComplete(resolve)
				.start();
		});
	}

	protected updateScrollValue(dx: number, dy: number): void {
		const oldValue = this.content.x;
		super.updateScrollValue(dx, dy);
		if (this.parallaxEnabled && this.content.x !== oldValue) {
			this.parallax(dx);
		}
	}

	private parallax(deltaTranslation: number): void {
		this.parallaxTarget.x += deltaTranslation * ViewportView.PARALLAX_VALUE;
	}

	public getContentPosition(): PIXI.Point {
		return this.content.position;
	}

	public getContentScale(): PIXI.Point {
		return this.content.scale;
	}

	public getRelativePosition(position: PIXI.Point, from?: PIXI.Container): PIXI.Point {
		return this.content.toLocal(position, from);
	}

	public destroy(): void {
		if (this.tweenGroup) {
			this.tweenGroup.removeAll();
		}
		super.destroy();
	}
}
