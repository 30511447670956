import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100013View } from '@views/characters/events/event10/EventCharacter100013View';
import { EventCharacter100014View } from '@views/characters/events/event10/EventCharacter100014View';
import { EventCharacter100015View } from '@views/characters/events/event10/EventCharacter100015View';

export class EventBusiness100005View extends BusinessBaseView {
	constructor() {
		const eventCharacter100013View = new EventCharacter100013View();
		const eventCharacter100014View = new EventCharacter100014View();
		const eventCharacter100015View = new EventCharacter100015View();

		super('business100005', [eventCharacter100013View, eventCharacter100014View, eventCharacter100015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter100013View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter100014View as PIXI.DisplayObject,
			eventCharacter100015View,
		);
	}
}
