import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness10001View } from '@views/businesses/eventBusinesses/eventBuilding1/EventBusiness10001View';
import { EventBusiness10002View } from '@views/businesses/eventBusinesses/eventBuilding1/EventBusiness10002View';
import { EventBusiness10003View } from '@views/businesses/eventBusinesses/eventBuilding1/EventBusiness10003View';
import { EventBusiness10004View } from '@views/businesses/eventBusinesses/eventBuilding2/EventBusiness10004View';
import { EventBusiness10005View } from '@views/businesses/eventBusinesses/eventBuilding2/EventBusiness10005View';
import { EventBusiness10006View } from '@views/businesses/eventBusinesses/eventBuilding2/EventBusiness10006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';

export class EventLevel1View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('germanyMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['germany_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['germany_morning/road'], EventLevel1View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/house3']).setTransform(7, 651),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house1']).setTransform(1031, 596),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house5']).setTransform(2050, 537),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house2']).setTransform(2100, 532),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(735, 641),
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(1802, 641),
			road,
			back,
		);


		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('germanyMorningAtlas');

		this.background = EventLevel1View.createMorningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness10001View(),
			new EventBusiness10002View(),
			new EventBusiness10003View(),
			new EventBusiness10004View(),
			new EventBusiness10005View(),
			new EventBusiness10006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['germany_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['germany_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_left']).setTransform(1232, 539),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(EventLevel1View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	public static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('germanyEveningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['germany_evening/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['germany_evening/road'], EventLevel1View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_evening/house3']).setTransform(7, 651),
			new PIXI.Sprite(levelBgAtlas['germany_evening/house1']).setTransform(1031, 596),
			new PIXI.Sprite(levelBgAtlas['germany_evening/house5']).setTransform(2050, 537),
			new PIXI.Sprite(levelBgAtlas['germany_evening/house2']).setTransform(2100, 532),

		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['germany_evening/city']).setTransform(735, 641),
			new PIXI.Sprite(levelBgAtlas['germany_evening/city']).setTransform(1802, 641),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('germanyEveningAtlas');

		this.background = EventLevel1View.createEveningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness10001View(),
			new EventBusiness10002View(),
			new EventBusiness10003View(),
			new EventBusiness10004View(),
			new EventBusiness10005View(),
			new EventBusiness10006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['germany_evening/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['germany_evening/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['germany_evening/building_left']).setTransform(1232, 539),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		this.initLevel(EventLevel1View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}

	// eslint-disable-next-line class-methods-use-this
	private createPrepartyStartCharacter(): PrepartyStartCharacterView {
		const view = new PrepartyStartCharacterView();
		view.visible = false;
		return view;
	}
}
