import { SummonModel } from '@models/SummonModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { SummonOpenButtonView } from '@views/windows/collection/summon/SummonOpenButtonView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { PromotableModel } from '@models/PromotableModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { ViewUtils } from '@src/utils/ViewUtils';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

type SummonContentCardModel =
	PromotableModel |
	BoostModel |
	TimeskipModel;

export abstract class AbstractSummonItemTypeView extends PIXI.Container {
	private static createStickerNew(): PIXI.Container {
		const result = new PIXI.Container();

		const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerSale.scale.set(0.5, 0.5);

		const saleLabel = new TextField(
			LocalizationStorage.getInstance().getLocalizedString('#video_sticker_new'),
			{ font: '18px wendyOne', align: 'center', tint: 0xfff78f },
			55,
			55,
		);
		saleLabel.anchor = 0.5;
		saleLabel.rotation = 0.47;

		result.addChild(
			stickerSale,
			saleLabel as PIXI.DisplayObject,
		);
		result.position.set(48, -85);

		return result;
	}

	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	protected static readonly CONTENT_MARGIN_X: number = 13;

	private localizationStorage: LocalizationStorage;

	protected readonly summonModel: SummonModel;
	protected readonly bg: PIXI.Sprite;
	protected title: SizeableBitmapText;
	protected specialTitle: MultiColoredTextField;
	protected saleText: MultiColoredTextField;
	protected readonly description: TextField;
	protected buyButton?: ButtonBaseView;
	protected buy10Button?: ButtonBaseView;
	protected freeTimeActive: boolean;
	protected textContent: SizeableMultiColoredBitmapText;
	protected contentCardModelMap: Map<PIXI.Container, SummonContentCardModel>;
	protected contentCards: PIXI.Container[];

	private ticker: PIXI.ticker.Ticker;

	private hardMoneyModel: HardMoneyModel;
	private prestigeMoneyModel: PrestigeMoneyModel;

	private timerIcon: PIXI.Sprite;
	private inText: PIXI.extras.BitmapText;

	private readonly cardMiniViewFactory: CardMiniViewFactory;
	private timerContainer: PIXI.Container;

	constructor(
		summonModel: SummonModel,
		hardMoneyModel: HardMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		cardMiniViewFactory: CardMiniViewFactory,
	) {
		super();

		this.ticker = PIXI.ticker.shared;

		this.summonModel = summonModel;
		this.hardMoneyModel = hardMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.cardMiniViewFactory = cardMiniViewFactory;

		this.bg = new PIXI.Sprite();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		this.title = new SizeableBitmapText(
			'',
			590,
			{ font: '44px wendyOneGold' },
		);
		this.title.letterSpacing = 2;
		this.title.anchor = 0.5;

		this.specialTitle = new MultiColoredTextField(
			{ font: '44px wendyOneShadowBold' },
			590,
		);
		this.specialTitle.letterSpacing = 2;
		this.specialTitle.anchor = 0.5;
		this.specialTitle.visible = false;

		this.saleText = new MultiColoredTextField(
			{ font: '50px wendyOneShadowBold', tint: 0xfef996 },
			180,
		);
		this.saleText.anchor = 0.5;
		this.saleText.text = this.localizationStorage.getLocalizedString('SALE');
		this.saleText.visible = false;

		this.description = new TextField(
			'',
			{ font: '22px wendyOneShadowBold', align: 'center' },
			590,
			85,
		);
		this.description.anchor = 0.5;

		this.textContent = new SizeableMultiColoredBitmapText(300, { font: '20px wendyOneShadowBold' });
		this.textContent.text = this.localizationStorage.getLocalizedString('#summon_chance_of_getting');
		this.textContent.anchor = 0.5;
		this.textContent.alpha = 0.7;

		this.contentCards = this.summonModel
			.getContent()
			.map(item => this.cardMiniViewFactory.createRewardCardByTypeId(
				item.type,
				item.id,
				0.45, 0.45,
				AbstractSummonItemTypeView.createStickerNew(),
			));
		ViewUtils.positionLayoutHorizontal(
			this.contentCards,
			AbstractSummonItemTypeView.CONTENT_MARGIN_X,
		);

		this.addChild(
			this.bg,
			this.title as PIXI.DisplayObject,
			this.specialTitle,
			this.description,
			this.textContent,
			this.saleText,
			...this.contentCards,
		);

		this.onLanguageChange();

		if (this.summonModel.isPrestigePrice()) {
			this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		} else if (this.summonModel.isHardPrice()) {
			this.hardMoneyModel.on(HardMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		}
	}

	public abstract showLoadingLabel(): void;

	protected abstract setButtonsPosition(): void;

	private onMoneyChange(): void {
		if (this.buyButton) {
			(this.buyButton as SummonOpenButtonView).updateAvailability();
		}

		if (this.buy10Button) {
			(this.buy10Button as SummonOpenButtonView).updateAvailability();
		}
	}

	public enableButtons(value: boolean): void {
		this.interactive = value;
		this.interactiveChildren = value;
	}

	private onLanguageChange(): void {
		this.title.text = this.localizationStorage.getLocalizedString(`#${this.summonModel.getViewType()}_title`);
		this.specialTitle.text = this.localizationStorage.getLocalizedString(`#${this.summonModel.getViewType()}_title`);
		this.description.text = this.localizationStorage.getLocalizedString(`#${this.summonModel.getViewType()}_decr`);
		this.createBuyButtons();
	}

	public getBuyButton(): PIXI.Container | undefined {
		return this.buyButton;
	}

	public getBuyButtonAmount(value: number): PIXI.Container {
		let button: PIXI.Container;
		if (value === 10) {
			button = this.buy10Button;
		} else {
			button = this.buyButton;
		}
		return button;
	}

	protected onBuySummonClick(count: number): void {
		this.enableButtons(false);
		this.emit(AbstractSummonItemTypeView.EVENT_BUTTON_BUY_CLICK, count);
	}

	private createBuyButtons(): void {
		if (this.buyButton) {
			this.removeChild(this.buyButton);
			this.buyButton.destroy();
			this.buyButton = null;
		}

		if (this.buy10Button) {
			this.removeChild(this.buy10Button);
			this.buy10Button.destroy();
			this.buy10Button = null;
		}

		this.buyButton = this.summonModel.isFreePrice() ? this.createFreeButton() : this.createBuyButton();
		this.buyButton.on(ButtonBaseView.EVENT_CLICK, () => this.onBuySummonClick(1), this);
		this.addChild(this.buyButton);

		if (this.summonModel.getPriceValueX10()) {
			this.buy10Button = this.createBuy10Button();
			this.buy10Button.on(ButtonBaseView.EVENT_CLICK, () => this.onBuySummonClick(10), this);
			this.addChild(this.buy10Button);
		}

		this.timerContainer = this.createFreeUnvailable();
		this.addChild(this.timerContainer);

		if (this.summonModel.isFreePrice() && !this.summonModel.isFreePriceAvailable()) {
			this.timerContainer.visible = true;
			this.buyButton.visible = false;
			this.startTimer();
		}

		this.setButtonsPosition();
		this.timerContainer.x = this.buyButton.x - 70;
		this.timerContainer.y = this.buyButton.y;
	}

	private createFreeButton(): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11,
		);
		buttonBg.width = 188;
		buttonBg.height = 74;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const buyButton = new ButtonBaseView(buttonBg);
		const label = new PIXI.extras.BitmapText(
			this.localizationStorage.getLocalizedString('#summon_free_button'),
			{ font: '30px wendyOneShadowBold' },
		);
		label.anchor = 0.5;
		buyButton.addChild(label);

		return buyButton;
	}

	private createBuyButton(): ButtonBaseView {
		const button = new SummonOpenButtonView(
			this.summonModel,
			1,
			this.hardMoneyModel,
			this.prestigeMoneyModel,
			'#open_summon_button',
			true,
			undefined,
			Boolean(this.summonModel.getPriceValueX10()),
		);
		button.width = 188;
		return button;
	}

	private createBuy10Button(): ButtonBaseView {
		const buy10Button = new SummonOpenButtonView(
			this.summonModel,
			10,
			this.hardMoneyModel,
			this.prestigeMoneyModel,
			'#open_summon_button',
			true,
			undefined,
			true,
		);
		buy10Button.width = 188;

		const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerSale.position.set(95, -25);
		stickerSale.scale.set(0.5, 0.5);
		const saleLabel = new TextField(
			this.localizationStorage.getLocalizedString('#summon_sticker_discount'),
			{ font: '23px wendyOne', align: 'center', tint: 0xfff78f },
			45,
			45,
		);
		saleLabel.anchor = 0.5;
		saleLabel.rotation = 0.47;
		saleLabel.position.set(95, -25);

		buy10Button.addChild(
			stickerSale,
			saleLabel as PIXI.DisplayObject,
		);

		return buy10Button;
	}

	private createFreeUnvailable(): PIXI.Container {
		const container = new PIXI.Container();

		const availableIn = new SizeableBitmapText(
			'',
			300,
			{ font: '24px wendyOneShadowBold' },
		);
		availableIn.text = this.localizationStorage.getLocalizedString('#summon_free_available_in');
		availableIn.anchor = new PIXI.Point(0, 1);
		availableIn.position.set(-115, 12);

		this.inText = new PIXI.extras.BitmapText('', { font: '30px wendyOneShadowBold', tint: 0xffd129 });
		this.inText.anchor = new PIXI.Point(0, 1);
		this.inText.position.set(availableIn.position.x + availableIn.width + 16, 13);

		this.timerIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']);
		this.timerIcon.y = 13;
		this.timerIcon.scale.set(0.55);

		container.addChild(
			availableIn,
			this.inText,
			this.timerIcon as PIXI.DisplayObject,
		);
		container.visible = false;

		return container;
	}

	private startTimer(): void {
		this.freeTimeActive = true;
		this.ticker.add(this.updateTimer, this);
	}

	private stopTimer(): void {
		this.freeTimeActive = false;
		this.ticker.remove(this.updateTimer, this);
	}

	private updateTimer(): void {
		if (this.summonModel.isFreePriceAvailable()) {
			this.createBuyButtons();
			this.stopTimer();
		} else {
			const availableIn = NumberUtils.secToDHMS(Math.ceil(this.summonModel.getFreePriceAvailableTime()));
			this.inText.text = availableIn;
			this.timerIcon.position.set(this.inText.position.x + this.inText.width + 24, -9);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		if (this.freeTimeActive) {
			this.stopTimer();
		}

		if (this.summonModel.isPrestigePrice()) {
			this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		} else if (this.summonModel.isHardPrice()) {
			this.hardMoneyModel.off(HardMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		}

		super.destroy(options);
	}
}
