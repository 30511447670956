import { EnvNames } from '@src/types/EnvNames';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BusinessIncomeAction } from '@models/network/actions/businesses/BusinessIncomeAction';
import { BusinessIncomeProgressAction } from '@models/network/actions/businesses/BusinessIncomeProgressAction';

export class NetworkUtils {
	public static getURLParameterByName(param: string): string {
		const name = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
		const results = regex.exec(window.location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	public static getURLParameters(): string {
		const results = window.location.search;
		return results;
	}

	public static getCurrentURL(): string {
		return window.location.href;
	}

	public static setCurrentURL(value: string): void {
		window.location.href = value;
	}

	public static getServerUrl(env: string): string {
		let result: string;

		const envType = EnvNames.getEnvType(env);
		if (envType === 'alias') {
			result = EnvNames.getEnvUrlNameMap().get(env);
		} else if (envType === 'url') {
			result = env;
		} else {
			throw new Error(`Unsupported env '${env}'`);
		}
		return result;
	}

	public static getAssetServiceUrl(): string {
		return 'https://assets.playful-fairies.com/clicker/get_assets';
	}

	public static formatActions(actions: BaseAction[]): BaseAction[] {
		const result = [];
		const actionKeyActionMap: Map<string, BusinessIncomeAction> = new Map();
		const actionKeyCountMap: Map<string, number> = new Map();

		const addCompositeActionsIfAny = (): void => {
			Array.from(actionKeyCountMap.keys()).forEach((actionKey) => {
				const actionComposite = actionKeyActionMap.get(actionKey);
				actionComposite.setCount(actionKeyCountMap.get(actionKey));
				result.push(actionComposite);
			});

			actionKeyActionMap.clear();
			actionKeyCountMap.clear();
		};

		for (let i = 0; i < actions.length; i++) {
			const action = actions[i];
			if (action instanceof BusinessIncomeAction) {
				const actionKey = `${action.getBusinessKey()}_income:${action.getIncome()}`;
				actionKeyCountMap.set(
					actionKey,
					(actionKeyCountMap.get(actionKey) || 0) + action.getCount(),
				);
				actionKeyActionMap.set(actionKey, action);
			} else {
				if (!(action instanceof BusinessIncomeProgressAction)) {
					addCompositeActionsIfAny();
				}
				result.push(action);
			}
		}
		addCompositeActionsIfAny();
		return result;
	}
}
