import { TimeskipModel } from '@models/TimeskipModel';
import { BoostModel } from '@models/BoostModel';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { CharacterModel } from '@models/CharacterModel';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { PromotableModel } from '@models/PromotableModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { BankModel } from '@models/bank/BankModel';
import { BankOfferPurchaseSourceTypes } from '@src/types/AnalyticsTypes';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankPurchaseOriginType, BankWindowOriginTypes, BankOfferWindowOriginTypes } from '@src/types/BankTypes';
import { LevelModel } from '@models/level/LevelModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export type EventQuestCompleteEventArgs = {
	key: string;
	questLine: number;
	lineNumber: number;
	levelQuestsCompleted: number;
};

export type EventTimedQuestCompleteEventArgs = {
	key: string;
	timeleft: number;
};

export type EventTimedQuestTimeoutEventArgs = {
	key: string;
};

export type EventUpgradePromoteEventArgs = {
	key: string;
	newLevel: number;
};

export type EventUpgradeReceiveEventArgs = {
	id: string;
	amount: number;
};

export type EventTotemPromoteEventArgs = EventUpgradePromoteEventArgs;
export type EventTotemReceiveEventArgs = EventUpgradeReceiveEventArgs;

export type EventCharacterPromoteEventArgs = EventUpgradePromoteEventArgs;
export type EventCharacterReceiveEventArgs = EventUpgradeReceiveEventArgs;

export type EventBoostReceiveEventArgs = {
	key: string;
};

export type EventBoostActivateEventArgs = {
	key: string;
	remain: number;
};

export type EventTimeskipReceiveEventArgs = EventBoostReceiveEventArgs;
export type EventTimeskipActivateEventArgs = EventBoostActivateEventArgs;

export type EventTutorialStepCompleteEventArgs = {
	key: string;
};

export type EventPrestigeMoneyReceiveEventArgs = {
	amount: number;
};
export type EventPrestigeMoneySpendEventArgs = EventPrestigeMoneyReceiveEventArgs;

export type EventHardMoneyReceiveEventArgs = EventPrestigeMoneyReceiveEventArgs;
export type EventHardMoneySpendEventArgs = EventPrestigeMoneyReceiveEventArgs;

export type EventLevelChallengeCompleteEventArgs = {
	key: number;
};

export type EventBankOfferBuyFromBankWindowEventArgs = {
	key: string;
	transactionId?: string;
	source?: BankOfferPurchaseSourceTypes;
};
export type EventBankOfferBuyFromOfferWindowEventArgs = EventBankOfferBuyFromBankWindowEventArgs;
export type EventBankOfferBuyFromBankAfterOfferEventArgs = EventBankOfferBuyFromBankWindowEventArgs;
export type EventBankOfferBuyFromOfferAfterLinesUIEventArgs = EventBankOfferBuyFromBankWindowEventArgs;

export type EventBankPurchaseSuccessEventArgs = {
	price: string;
	transactionId: string;
};

export type EventBankFreePurchaseSuccessEventArgs = {
	transactionId: string;
};

export type EventEventLevelGoalProgressEventArgs = {
	eventLevelKey: string;
	completedGoalCount: number;
	goalProgressCompleted: boolean;
};

export type AnalyticDataEventLevelModel = {
	key: string;
	playerPlace: number;
	incomePerSec: SoftMoneyNumber;
	currentGoalProgress: SoftMoneyNumber;
	completedGoalCount: number;
}

export class AnalyticSourceModels extends PIXI.utils.EventEmitter {
	public static readonly EVENT_QUEST_COMPLETE: symbol = Symbol();

	public static readonly EVENT_TIMED_QUEST_COMPLETE: symbol = Symbol();
	public static readonly EVENT_TIMED_QUEST_TIMEOUT: symbol = Symbol();

	public static readonly EVENT_UPGRADE_PROMOTE: symbol = Symbol();
	public static readonly EVENT_UPGRADE_RECEIVE: symbol = Symbol();

	public static readonly EVENT_TOTEM_PROMOTE: symbol = Symbol();
	public static readonly EVENT_TOTEM_RECEIVE: symbol = Symbol();

	public static readonly EVENT_CHARACTER_PROMOTE: symbol = Symbol();
	public static readonly EVENT_CHARACTER_RECEIVE: symbol = Symbol();

	public static readonly EVENT_BOOST_RECEIVE: symbol = Symbol();
	public static readonly EVENT_BOOST_ACTIVATE: symbol = Symbol();

	public static readonly EVENT_TIMESKIP_RECEIVE: symbol = Symbol();
	public static readonly EVENT_TIMESKIP_ACTIVATE: symbol = Symbol();

	public static readonly EVENT_TUTORIAL_STEP_COMPLETE: symbol = Symbol();

	public static readonly EVENT_PRESTIGE_MONEY_RECEIVE: symbol = Symbol();
	public static readonly EVENT_PRESTIGE_MONEY_SPEND: symbol = Symbol();

	public static readonly EVENT_HARD_MONEY_RECEIVE: symbol = Symbol();
	public static readonly EVENT_HARD_MONEY_SPEND: symbol = Symbol();

	public static readonly EVENT_LEVEL_CHALLENGE_COMPLETE: symbol = Symbol();

	public static readonly EVENT_BANK_OFFER_BUY_FROM_BANK_WINDOW: symbol = Symbol();
	public static readonly EVENT_BANK_OFFER_BUY_FROM_OFFER_WINDOW: symbol = Symbol();
	public static readonly EVENT_BANK_OFFER_BUY_FROM_BANK_AFTER_OFFER: symbol = Symbol();
	public static readonly EVENT_BANK_OFFER_BUY_FROM_OFFER_AFTER_LINES_UI: symbol = Symbol();
	public static readonly EVENT_BANK_PURCHASE_SUCCESS: symbol = Symbol();
	public static readonly EVENT_BANK_FREE_PURCHASE_SUCCESS: symbol = Symbol();
	public static readonly EVENT_GAME_PROFILE_CONFIRMATION: symbol = Symbol();

	public static readonly EVENT_EVENT_LEVEL_GOAL_PROGRESS_NEW_GOAL: symbol = Symbol();
	public static readonly EVENT_EVENT_LEVEL_GOAL_PROGRESS_COMPLETED: symbol = Symbol();

	constructor(
		private readonly levelModel?: LevelModel,
		private readonly bankSavesModel?: BankSavesModel,
		private readonly bankModel?: BankModel,
		private readonly timeskipModels?: Map<string, TimeskipModel>,
		private readonly boostModels?: Map<string, BoostModel>,
		private readonly tutorialStepModels?: Map<string, TutorialStepBaseModel>,
		private readonly timedQuestLinesModel?: TimedQuestLinesModel,
		private readonly questLinesModel?: QuestLinesModel,
		private readonly characterModels?: Map<string, CharacterModel>,
		private readonly totemModels?: Map<string, TotemModel>,
		private readonly upgradeModels?: Map<string, UpgradeModel>,
		private readonly prestigeMoneyModel?: PrestigeMoneyModel,
		private readonly hardMoneyModel?: HardMoneyModel,
		private readonly levelChallengeModel?: LevelChallengeModel,
		private readonly eventLevelModel?: EventLevelModel,
	) {
		super();
		if (this.eventLevelModel) {
			this.handleEventLevel();
		}

		if (this.bankModel) {
			this.handleBank();
		}

		if (this.timeskipModels) {
			this.handleTimeskips(this.timeskipModels);
		}

		if (this.boostModels) {
			this.handleBoosts(this.boostModels);
		}

		if (this.tutorialStepModels) {
			this.handleTutorialSteps(this.tutorialStepModels);
		}

		if (this.timedQuestLinesModel) {
			this.handleTimedQuests(this.timedQuestLinesModel);
		}

		if (this.questLinesModel) {
			this.handleQuests(this.questLinesModel);
		}

		if (this.characterModels) {
			this.handleCharacters(this.characterModels);
		}

		if (this.totemModels) {
			this.handleTotems(this.totemModels);
		}

		if (this.upgradeModels) {
			this.handleUpgrades(this.upgradeModels);
		}

		if (this.prestigeMoneyModel) {
			this.handlePrestigeMoney(this.prestigeMoneyModel);
		}

		if (this.hardMoneyModel) {
			this.handleHardMoney(this.hardMoneyModel);
		}

		if (this.levelChallengeModel) {
			this.handleLevelChallenges(this.levelChallengeModel);
		}
	}

	private handleEventLevel(): void {
		this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS, () => {
			const args: EventEventLevelGoalProgressEventArgs = {
				completedGoalCount: this.eventLevelModel.getCompletedGoalCount(),
				eventLevelKey: this.eventLevelModel.getKey(),
				goalProgressCompleted: this.eventLevelModel.isGoalProgressCompleted(),
			};
			this.emit(AnalyticSourceModels.EVENT_EVENT_LEVEL_GOAL_PROGRESS_NEW_GOAL, args);
		}, this);
		this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS_COMPLETE, () => {
			const args: EventEventLevelGoalProgressEventArgs = {
				completedGoalCount: this.eventLevelModel.getCompletedGoalCount(),
				eventLevelKey: this.eventLevelModel.getKey(),
				goalProgressCompleted: this.eventLevelModel.isGoalProgressCompleted(),
			};
			this.emit(AnalyticSourceModels.EVENT_EVENT_LEVEL_GOAL_PROGRESS_COMPLETED, args);
		}, this);
	}

	private handleBank(): void {
		this.bankSavesModel.on(BankSavesModel.EVENT_BANK_PURCHASE_OFFER_SUCCESS, (
			bankOfferModel: BankOfferModel,
			purchaseOrigin?: BankPurchaseOriginType,
			transactionId?: string,
		) => {
			// buy directly from bank window
			if (!purchaseOrigin
				|| (!purchaseOrigin.bankOfferWindowOrigin && !purchaseOrigin.bankWindowOrigin)) {
				const args: EventBankOfferBuyFromBankWindowEventArgs = {
					key: bankOfferModel.getKey(),
					transactionId,
					source: BankOfferPurchaseSourceTypes.BANK_WINDOW,
				};
				this.emit(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_WINDOW, args);
			}

			// buy directly from offer window
			if (purchaseOrigin && purchaseOrigin.bankOfferWindowOrigin && !purchaseOrigin.bankWindowOrigin) {
				const args: EventBankOfferBuyFromOfferWindowEventArgs = {
					key: bankOfferModel.getKey(),
					transactionId,
					source: BankOfferPurchaseSourceTypes.OFFER_WINDOW,
				};
				this.emit(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_OFFER_WINDOW, args);

				// buy from offer window after lines ui click
				if (purchaseOrigin.bankOfferWindowOrigin === BankOfferWindowOriginTypes.BANK_OFFER_LINES_UI) {
					const linesUIArgs: EventBankOfferBuyFromOfferAfterLinesUIEventArgs = {
						key: bankOfferModel.getKey(),
					};
					this.emit(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_OFFER_AFTER_LINES_UI, linesUIArgs);
				}
			}

			// buy from bank after bank offer window
			if (purchaseOrigin && purchaseOrigin.bankWindowOrigin === BankWindowOriginTypes.BANK_OFFER_WINDOW) {
				const args: EventBankOfferBuyFromBankAfterOfferEventArgs = {
					key: bankOfferModel.getKey(),
					transactionId,
					source: BankOfferPurchaseSourceTypes.BANK_WINDOW,
				};
				this.emit(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_AFTER_OFFER, args);
			}
		}, this);

		this.bankSavesModel.on(BankSavesModel.EVENT_BANK_PURCHASE_SUCCESS, (price: string, transactionId: string) => {
			const args: EventBankPurchaseSuccessEventArgs = {
				price,
				transactionId,
			};
			this.emit(AnalyticSourceModels.EVENT_BANK_PURCHASE_SUCCESS, args);
		}, this);
		this.bankSavesModel.on(BankSavesModel.EVENT_BANK_FREE_PURCHASE_SUCCESS, (transactionId: string) => {
			const args: EventBankFreePurchaseSuccessEventArgs = {
				transactionId,
			};
			this.emit(AnalyticSourceModels.EVENT_BANK_FREE_PURCHASE_SUCCESS, args);
		}, this);
	}

	private handleQuests(model: QuestLinesModel): void {
		model.on(QuestLinesModel.EVENT_QUEST_COMPLETED, (quest: AbstractQuest) => {
			const args: EventQuestCompleteEventArgs = {
				key: quest.getKey(),
				questLine: quest.getLineId(),
				lineNumber: model.getLineProgress(quest.getLineId()),
				levelQuestsCompleted: this.levelModel?.getCurrentProgress() || -1,
			};
			this.emit(AnalyticSourceModels.EVENT_QUEST_COMPLETE, args);
		}, this);
	}

	private handleTimedQuests(model: TimedQuestLinesModel): void {
		model.on(TimedQuestLinesModel.EVENT_QUEST_COMPLETED, (quest: AbstractQuest) => {
			const args: EventTimedQuestCompleteEventArgs = {
				key: quest.getKey(),
				timeleft: quest.getTimeleft(),
			};
			this.emit(AnalyticSourceModels.EVENT_TIMED_QUEST_COMPLETE, args);
		});
		model.on(TimedQuestLinesModel.EVENT_QUEST_TIMEOUT, (quest: AbstractQuest) => {
			const args: EventTimedQuestTimeoutEventArgs = {
				key: quest.getKey(),
			};
			this.emit(AnalyticSourceModels.EVENT_TIMED_QUEST_TIMEOUT, args);
		}, this);
	}

	private handleUpgrades(models: Map<string, UpgradeModel>): void {
		models.forEach(model => {
			model.on(UpgradeModel.EVENT_PROMOTED, () => {
				const args: EventUpgradePromoteEventArgs = {
					key: model.getKey(),
					newLevel: model.getLevel(),
				};
				this.emit(AnalyticSourceModels.EVENT_UPGRADE_PROMOTE, args);
			}, this);
			model.on(UpgradeModel.EVENT_CARDS_ADDED, (m: PromotableModel, amount: number) => {
				const args: EventUpgradeReceiveEventArgs = {
					id: model.getKey(),
					amount,
				};
				this.emit(AnalyticSourceModels.EVENT_UPGRADE_RECEIVE, args);
			}, this);
		});
	}

	private handleTotems(models: Map<string, TotemModel>): void {
		models.forEach(model => {
			model.on(TotemModel.EVENT_PROMOTED, () => {
				const args: EventTotemPromoteEventArgs = {
					key: model.getKey(),
					newLevel: model.getLevel(),
				};
				this.emit(AnalyticSourceModels.EVENT_TOTEM_PROMOTE, args);
			}, this);
			model.on(TotemModel.EVENT_CARDS_ADDED, (m: PromotableModel, amount: number) => {
				const args: EventTotemReceiveEventArgs = {
					id: model.getKey(),
					amount,
				};
				this.emit(AnalyticSourceModels.EVENT_TOTEM_RECEIVE, args);
			});
		});
	}

	private handleCharacters(models: Map<string, CharacterModel>): void {
		models.forEach(model => {
			model.on(CharacterModel.EVENT_PROMOTED, () => {
				const args: EventCharacterPromoteEventArgs = {
					key: model.getKey(),
					newLevel: model.getLevel(),
				};
				this.emit(AnalyticSourceModels.EVENT_CHARACTER_PROMOTE, args);
			}, this);
			model.on(CharacterModel.EVENT_CARDS_ADDED, (m: PromotableModel, amount: number) => {
				const args: EventCharacterReceiveEventArgs = {
					id: model.getKey(),
					amount,
				};
				this.emit(AnalyticSourceModels.EVENT_CHARACTER_RECEIVE, args);
			}, this);
		});
	}

	private handleBoosts(models: Map<string, BoostModel>): void {
		models.forEach(model => {
			model.on(BoostModel.EVENT_ACQUIRED, () => {
				const args: EventBoostReceiveEventArgs = {
					key: model.getKey(),
				};
				this.emit(AnalyticSourceModels.EVENT_BOOST_RECEIVE, args);
			}, this);
			model.on(BoostModel.EVENT_ACTIVATED, () => {
				const args: EventBoostActivateEventArgs = {
					key: model.getKey(),
					remain: model.getActivateCount() - model.getCurrentActivateCount(),
				};
				this.emit(AnalyticSourceModels.EVENT_BOOST_ACTIVATE, args);
			}, this);
		});
	}

	private handleTimeskips(models: Map<string, TimeskipModel>): void {
		models.forEach(model => {
			model.on(TimeskipModel.EVENT_ACQUIRED, () => {
				const args: EventTimeskipReceiveEventArgs = {
					key: model.getKey(),
				};
				this.emit(AnalyticSourceModels.EVENT_TIMESKIP_RECEIVE, args);
			}, this);
			model.on(TimeskipModel.EVENT_ACTIVATED, () => {
				const args: EventTimeskipActivateEventArgs = {
					key: model.getKey(),
					remain: model.getActivateCount() - model.getCurrentActivateCount(),
				};
				this.emit(AnalyticSourceModels.EVENT_TIMESKIP_ACTIVATE, args);
			}, this);
		});
	}

	private handleTutorialSteps(models: Map<string, TutorialStepBaseModel>): void {
		models.forEach(model => {
			model.on(TutorialStepBaseModel.EVENT_COMPLETED, () => {
				const args: EventTutorialStepCompleteEventArgs = {
					key: model.getKey(),
				};
				this.emit(AnalyticSourceModels.EVENT_TUTORIAL_STEP_COMPLETE, args);
			}, this);
		});
	}

	private handlePrestigeMoney(model: PrestigeMoneyModel): void {
		model.on(PrestigeMoneyModel.EVENT_VALUE_INCREASED, (delta: number) => {
			const args: EventPrestigeMoneyReceiveEventArgs = {
				amount: delta,
			};
			this.emit(AnalyticSourceModels.EVENT_PRESTIGE_MONEY_RECEIVE, args);
		}, this);
		model.on(PrestigeMoneyModel.EVENT_VALUE_DECREASED, (delta: number) => {
			const args: EventPrestigeMoneySpendEventArgs = {
				amount: -delta,
			};
			this.emit(AnalyticSourceModels.EVENT_PRESTIGE_MONEY_SPEND, args);
		}, this);
	}

	private handleHardMoney(model: HardMoneyModel): void {
		model.on(HardMoneyModel.EVENT_VALUE_INCREASED, (delta: number) => {
			const args: EventHardMoneyReceiveEventArgs = {
				amount: delta,
			};
			this.emit(AnalyticSourceModels.EVENT_HARD_MONEY_RECEIVE, args);
		}, this);
		model.on(HardMoneyModel.EVENT_VALUE_DECREASED, (delta: number) => {
			const args: EventHardMoneySpendEventArgs = {
				amount: delta,
			};
			this.emit(AnalyticSourceModels.EVENT_HARD_MONEY_SPEND, args);
		}, this);
	}

	private handleLevelChallenges(model: LevelChallengeModel): void {
		model.on(LevelChallengeModel.EVENT_COMPLETED, () => {
			const args: EventLevelChallengeCompleteEventArgs = {
				key: model.getCompletedTargetTimeId() + 1,
			};
			this.emit(AnalyticSourceModels.EVENT_LEVEL_CHALLENGE_COMPLETE, args);
		}, this);
	}

	public getCurrentLevel(): number | undefined {
		return this.levelModel?.getCurrentLevel();
	}

	public getDataEventLevelModel(): AnalyticDataEventLevelModel {
		const data: AnalyticDataEventLevelModel = {
			key: this.eventLevelModel.getKey(),
			currentGoalProgress: this.eventLevelModel.getCurrentGoalProgress(),
			incomePerSec: this.eventLevelModel.getIncomePerSec(),
			completedGoalCount: this.eventLevelModel.getCompletedGoalCount(),
			playerPlace: this.eventLevelModel.getPlayerPlace(),
		};
		return data;
	}
}
