import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { QuestLootboxModel } from '@models/lootboxes/QuestLootboxModel';
import { QuestType } from '@src/types/QuestTypes';
import { QuestRestoreStateData } from '@src/types/SaveTypes';

export abstract class AbstractQuest extends PIXI.utils.EventEmitter {
	public static readonly EVENT_PROGRESS: symbol = Symbol();
	public static readonly EVENT_COMPLETED: symbol = Symbol();
	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	public static readonly EVENT_REWARD_RECEIVED: symbol = Symbol();

	private readonly lineId: number;

	protected readonly key: string;
	protected readonly type: QuestType;
	protected lootbox: LootboxModel;

	protected completed: boolean;
	protected mainWindowViewParameters: string[];

	private time: number;
	private timeleft: number;

	constructor(
		key: string,
		type: QuestType,
		lineId: number,
		mainWindowViewParameters: string[],
		time: number = Infinity,
	) {
		super();

		this.key = key;
		this.type = type;
		this.lineId = lineId;
		this.time = time;
		this.timeleft = time;
		this.mainWindowViewParameters = mainWindowViewParameters;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
	public restoreSavedState(saveData: QuestRestoreStateData): void {
		throw new Error('Not implemented');
	}

	// eslint-disable-next-line class-methods-use-this
	public canRestoreSavedState(): boolean {
		return true;
	}

	public setLootbox(lootbox: LootboxModel): void {
		this.lootbox = lootbox;

		this.emit(AbstractQuest.EVENT_REWARD_RECEIVED);
	}

	public getLootbox(): QuestLootboxModel {
		return this.lootbox as QuestLootboxModel;
	}

	public getLineId(): number {
		return this.lineId;
	}

	public hasLootbox(): boolean {
		return Boolean(this.lootbox);
	}

	public getKey(): string {
		return this.key;
	}

	public setTimeleft(value: number): void {
		if (this.timeleft > 0 && value <= 0) {
			this.timeleft = 0;

			this.trackProgressStop();
			this.emit(AbstractQuest.EVENT_TIMEOUT, this);
		} else {
			this.timeleft = value;
		}
	}

	public getTimeleft(): number {
		return this.timeleft;
	}

	public isCompleted(): boolean {
		return this.completed;
	}

	public hasTime(): boolean {
		return this.time !== Infinity;
	}

	public getTime(): number {
		return this.time;
	}

	public getType(): QuestType {
		return this.type;
	}

	public getMainWindowViewParameters(): string[] {
		return this.mainWindowViewParameters;
	}

	public complete(): void {
		this.completed = true;
		this.trackProgressStop();

		this.emit(AbstractQuest.EVENT_COMPLETED, this);
	}

	public isProgressComplete(): boolean {
		return this.getProgressRatio() === 1;
	}

	public abstract getProgressRatio(): number;

	public abstract getCurrentProgressValue(): string;

	public abstract getTotalProgressValue(): string;

	protected abstract trackProgressStop(): void;

	protected abstract trackProgressStart(): void;
}
