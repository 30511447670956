import { ViewportUIView, ViewportUIChildType } from '@views/components/ViewportUIView';
import { CharacterQuickPhraseView } from './CharacterQuickPhraseView';
import * as TWEEN from '@tweenjs/tween.js';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { CharacterQuickPhraseListener } from '@interfaces/CharacterQuickPhraseListener';
import { AssetsStorage } from '@main/AssetsStorage';

export abstract class CharacterBaseView extends PIXI.Container implements CharacterQuickPhraseListener {
	public static readonly EVENT_ANIMATION_CLICK: symbol = Symbol();
	public static readonly EVENT_QUICK_PHRASE_SHOWN: symbol = Symbol();
	public static readonly EVENT_QUICK_PHRASE_HIDDEN: symbol = Symbol();

	protected viewportUI: ViewportUIView;
	protected quickPhraseEmitter: CharacterQuickPhraseEmitter;

	protected readonly quickPhraseView: CharacterQuickPhraseView;
	protected quickPhraseKey: string;
	protected quickPhraseEnabled: boolean;

	protected setAnimationHandler: number;

	private animation: PIXI.spine.Spine;
	private animationKey: string;

	private tweenAnimationSpeedUp: TWEEN.Tween;

	protected constructor(animationKey: string) {
		super();

		this.animationKey = animationKey;
		this.quickPhraseEnabled = true;

		this.quickPhraseView = new CharacterQuickPhraseView();
		this.quickPhraseView.on(CharacterQuickPhraseView.EVENT_HIDDEN, this.onQuickPhraseHidden, this);
	}

	public abstract show(): void;

	public setQuickPhraseEnabled(value: boolean, hideCurrentPhraseIfAny: boolean = false): void {
		this.quickPhraseEnabled = value;

		if (hideCurrentPhraseIfAny && this.quickPhraseView.isShowing()) {
			this.quickPhraseView.hide();
		}
	}

	public onZoomedIn(): void {
		if (this.quickPhraseEmitter && this.canShowQuickPhrase()) {
			this.quickPhraseEmitter.onTryEmitZoomPhrase(this.quickPhraseKey);
		}
	}

	protected onInited(): void {
		const positionViewportUI = this.viewportUI.toLocal(this.quickPhraseView.position, this);
		this.quickPhraseView.position.set(positionViewportUI.x, positionViewportUI.y);

		this.viewportUI.addChildByType(this.quickPhraseView, ViewportUIChildType.QUICK_PHRASE);
	}

	protected initQuickPhrases(key: string): void {
		this.quickPhraseEmitter.removeListener(this.quickPhraseKey);
		this.quickPhraseEmitter.unregisterPhrases(this.quickPhraseKey);

		this.quickPhraseKey = key;

		this.quickPhraseEmitter.addListener(key, this);
		this.quickPhraseEmitter.registerPhrases(key);
	}

	private canShowQuickPhrase(): boolean {
		return this.quickPhraseEnabled && !this.quickPhraseView.isShowing();
	}

	public onShowQuickPhrase(phrase: string, showTime: number): void {
		if (this.canShowQuickPhrase()) {
			this.quickPhraseView.setTextPhrase(phrase);
			this.quickPhraseView.show(showTime);

			this.emit(CharacterBaseView.EVENT_QUICK_PHRASE_SHOWN);
		}
	}

	private onQuickPhraseHidden(): void {
		this.emit(CharacterBaseView.EVENT_QUICK_PHRASE_HIDDEN);
	}

	protected setAnimation(trackIndex: number, animationName: string, loop: boolean): void {
		const setAnimationLocal = (): void => {
			this.animation = new PIXI.spine.Spine(AssetsStorage.getCharacterAnimation(this.animationKey));
			this.animation.interactive = true;
			this.animation.on('pointerdown', this.onAnimationClick, this);

			this.addChildAt(this.animation, 0);

			this.animation.state.setAnimation(trackIndex, animationName, loop);
		};

		if (this.animation) {
			this.animation.state.setAnimation(trackIndex, animationName, loop);
		} else if (AssetsStorage.getCharacterAnimation(this.animationKey)) {
			setAnimationLocal();
		} else {
			if (this.setAnimationHandler) {
				window.clearTimeout(this.setAnimationHandler);
				this.setAnimationHandler = null;
			}
			this.setAnimationHandler = window.setTimeout(
				() => this.setAnimation(trackIndex, animationName, loop),
				1000,
			);
		}
	}

	private speedUpAnimation(): void {
		this.animation.state.timeScale += 0.35;
		this.animation.state.timeScale = Math.min(this.animation.state.timeScale, 4);

		if (this.tweenAnimationSpeedUp) {
			this.tweenAnimationSpeedUp.stop();
		}
		this.tweenAnimationSpeedUp = new TWEEN.Tween(this.animation.state)
			.delay(3000)
			.to({ timeScale: 1 }, 1100)
			.start();
	}

	protected onAnimationClick(event: PIXI.interaction.InteractionEvent): void {
		this.speedUpAnimation();
		if (this.quickPhraseEmitter && this.canShowQuickPhrase()) {
			this.quickPhraseEmitter.onTryEmitSpeedUpPhrase(this.quickPhraseKey);
		}
		this.emit(CharacterBaseView.EVENT_ANIMATION_CLICK, event);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.viewportUI) {
			this.viewportUI.removeChildByType(this.quickPhraseView, ViewportUIChildType.QUICK_PHRASE);
		}

		if (this.setAnimationHandler) {
			window.clearTimeout(this.setAnimationHandler);
			this.setAnimationHandler = null;
		}

		if (this.quickPhraseEmitter) {
			this.quickPhraseEmitter.removeListener(this.quickPhraseKey);
			this.quickPhraseEmitter.unregisterPhrases(this.quickPhraseKey);
		}

		super.destroy(options);
	}
}
