import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { RewardResourceIdTypes, RewardDisplayTypes } from '@src/types/RewardTypes';
import { CardRarity } from '@src/types/CardRarities';
import LocalizationStorage from '@main/LocalizationStorage';

export class BankInfoHintElement extends PIXI.Container {
	private localizationStorage: LocalizationStorage;

	protected titleElement: MultiColoredTextField;
	protected countElement: MultiColoredTextField;

	constructor(
		viewParam: string,
		count: string,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		let iconType: string;
		let titleTintType: number;
		let extraBgTint: number;
		let text: string;
		let scale: number;

		switch (viewParam) {
			case RewardDisplayTypes.BOOST: {
				extraBgTint = 0x151a1e;
				iconType = 'booster_icon';
				scale = 0.42;
				text = this.localizationStorage.getLocalizedString('#GameUI_BoostsButton');
				break;
			}
			case RewardDisplayTypes.TIMESKIP: {
				extraBgTint = 0x151a1e;
				iconType = 'time_skip_icon';
				scale = 0.64;
				text = this.localizationStorage.getLocalizedString('#Fast_5m_boost_offer_bundle');
				break;
			}
			case RewardResourceIdTypes.HARD_MONEY: {
				extraBgTint = 0x1f262e;
				iconType = 'i_hard_money';
				scale = 0.64;
				titleTintType = 0x97bee1;
				text = this.localizationStorage.getLocalizedString('#hard_money_name');
				break;
			}
			case RewardResourceIdTypes.PRESTIGE_MONEY: {
				extraBgTint = 0x1f262e;
				iconType = 'i_prestige';
				scale = 0.64;
				titleTintType = 0xffd86d;
				text = this.localizationStorage.getLocalizedString('#prestige_money_name');
				break;
			}
			case CardRarity.COMMON: {
				extraBgTint = 0x151a1e;
				iconType = 'common_card_base';
				titleTintType = 0x869bae;
				text = this.localizationStorage.getLocalizedString('#hint_rarity_common');
				break;
			}
			case CardRarity.RARE: {
				extraBgTint = 0x151a1e;
				iconType = 'rare_card_base';
				titleTintType = 0x3093e6;
				text = this.localizationStorage.getLocalizedString('#hint_rarity_rare');
				break;
			}
			case CardRarity.EPIC: {
				extraBgTint = 0x151a1e;
				iconType = 'epic_card_base';
				titleTintType = 0xd658ff;
				text = this.localizationStorage.getLocalizedString('#hint_rarity_epic');
				break;
			}
			case CardRarity.LEGENDARY: {
				extraBgTint = 0x151a1e;
				iconType = 'legendary_card_base';
				titleTintType = 0xffbb3f;
				text = this.localizationStorage.getLocalizedString('#hint_rarity_legendary');
				break;
			}
			default:
				throw Error(`Unsupported BankInfoHintElement viewParametr: ${viewParam}`);
		}

		const bgElement = new PIXI.Graphics();
		bgElement.beginFill(extraBgTint);
		bgElement.drawRect(0, 0, 485, 66);
		bgElement.endFill();
		bgElement.pivot.set(bgElement.width / 2, bgElement.height / 2);

		const iconElement = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[iconType]);
		iconElement.setTransform(-204, 0, scale, scale);

		this.titleElement = new MultiColoredTextField({
			font: '26px wendyOneShadowBold',
			align: 'center',
		}, 325, 84);
		this.titleElement.anchor = new PIXI.Point(0, 0.5);
		this.titleElement.position.set(-174, 1);
		this.titleElement.tint = titleTintType;
		this.titleElement.text = text;

		this.countElement = new MultiColoredTextField({
			font: '26px wendyOneShadowBold',
			align: 'center',
		}, 150, 40);
		this.countElement.anchor = new PIXI.Point(1, 0.5);
		this.countElement.position.set(213, 1);
		this.countElement.text = count;

		const graphicElement = new PIXI.Graphics();
		graphicElement.beginFill(0x2d3742);
		graphicElement.drawRect(0, -34, 448, 1);
		graphicElement.endFill();
		graphicElement.pivot.set(graphicElement.width / 2, graphicElement.height / 2);


		this.addChild(
			bgElement as PIXI.DisplayObject,
			iconElement,
			graphicElement,
			this.titleElement,
			this.countElement,
		);
	}
}
