import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { PhraseCharacterKey } from '@models/quickPhrases/CharacterQuickPhraseEmitter';

export class QuickPhraseSpeedUpGenerator extends QuickPhaseGeneratorBase {
	private readonly prevPhasesGeneratedId: Map<string, number[]>;

	constructor(type: CharacterQuickPhraseTypes, config: CharacterQuickPhraseConstants) {
		super(type, config);

		this.prevPhasesGeneratedId = new Map();
	}

	public tryGeneratePhrase(characterKey: string): void {
		if (!this.prevPhasesGeneratedId.has(characterKey)) {
			this.prevPhasesGeneratedId.set(characterKey, []);
		}
		super.tryGeneratePhrase(characterKey, 2000);
	}

	protected getPhraseLocaleKeyRandom(key: PhraseCharacterKey): string {
		let phrase = this.phraseLocaleKeyDefault;

		const phrases = this.phraseLocalesMap.get(key);
		if (phrases && phrases.length > 0) {
			let phraseId;
			const generatedPharases: number[] = this.prevPhasesGeneratedId.get(key);
			do {
				phraseId = Math.floor(Math.random() * phrases.length);
			} while (generatedPharases.includes(phraseId));

			if (phrases.length > 1) {
				if (generatedPharases.length + 1 === phrases.length) {
					generatedPharases.length = 0;
				}
				generatedPharases.push(phraseId);
			}

			phrase = phrases[phraseId];
		}

		return phrase;
	}
}
