import { BusinessModel } from '@models/BusinessModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestTypes } from '@src/types/QuestTypes';

export class QuestAcquireBusiness extends AbstractQuest {
	private readonly business: BusinessModel;

	constructor(
		questKey: string,
		business: BusinessModel,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.ACQUIRE_BUSINESS,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.business = business;

		if (business.isAcquired()) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public getTargetKey(): string {
		return this.business.getKey();
	}

	// eslint-disable-next-line class-methods-use-this
	public canRestoreSavedState(): boolean {
		return false;
	}

	public getCurrentProgressValue(): string {
		return this.completed ? '1' : '0';
	}

	// eslint-disable-next-line class-methods-use-this
	public getTotalProgressValue(): string {
		return '1';
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : 0;
	}

	private onSomeBusinessAcquired(model: BusinessModel): void {
		if (model.getKey() === this.business.getKey()) {
			this.complete();
		}
	}

	protected trackProgressStop(): void {
		this.business.off(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this, false);
	}

	protected trackProgressStart(): void {
		this.business.on(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this);
	}
}
