import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { EventLevelButtonPatricleView } from './EventLevelButtonPatricleView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { EventLevelButtonBaseView } from './EventLevelButtonBaseView';

export class EventLevelButtonClaimRewardsView extends ButtonBaseView implements EventLevelButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly title: SizeableBitmapText;
	private readonly buttonIcon: PIXI.Sprite;

	constructor() {
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		super(bg);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const titlebg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_panel_gold'], 8, 0, 8, 0);
		titlebg.width = 170;
		titlebg.pivot.set(titlebg.width / 2, titlebg.height / 2);
		titlebg.position.set(16, 63);

		const titleDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line_horizontal']);
		titleDecor.position.set(16, 76);
		titleDecor.width = 170;
		titleDecor.blendMode = PIXI.BLEND_MODES.SCREEN;

		this.title = new SizeableBitmapText(
			'',
			150,
			{ font: '32px wendyOne', align: 'center', tint: 0x181a1b },
		);
		this.title.anchor = 0.5;
		this.title.position.set(titlebg.x - 1, titlebg.y);

		this.buttonIcon = new PIXI.Sprite();
		this.buttonIcon.anchor.set(0.5);

		const glow = new EventLevelButtonPatricleView();
		glow.position.set(20, 6);

		this.updateText();

		this.addChild(
			glow,
			this.buttonIcon,
			titlebg,
			this.title as PIXI.DisplayObject,
			titleDecor,
		);
	}

	public setEventKeyIcon(eventKey: string): void {
		this.buttonIcon.texture = AssetsStorage.getAtlas('uiAtlas')[`go_to_event${eventKey}_icon`];
	}

	private updateText(): void {
		this.title.text = this.localizationStorage.getLocalizedString('#quest_ButtonClaimMainwindow');
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
