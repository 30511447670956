import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70003View extends CharacterMainBaseView {
	constructor() {
		super('character70003');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-190, 2),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-280, -10),
		});
		this.initQuickPhrases(this.key);
	}
}
