import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class TintButton extends ButtonBaseView {
	constructor(buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane) {
		super(buttonBg);
	}

	public setEnabled(enable: boolean): void {
		this.interactive = enable;
		if (enable) {
			(this.buttonBg as PIXI.Sprite).tint = 0xFFFFFF;
		} else {
			(this.buttonBg as PIXI.Sprite).tint = 0x777777;
		}
	}
}
