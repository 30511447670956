export type BusinessPropertyData = {
	icon: PIXI.Sprite;
	value: string;
};

export class BusinessPropertyView extends PIXI.Container {
	private readonly textValue: PIXI.extras.BitmapText;

	constructor(data: BusinessPropertyData) {
		super();

		this.interactive = true;

		const result = new PIXI.Container();

		this.textValue = new PIXI.extras.BitmapText(data.value, { font: '36px wendyOneShadowBold', tint: 0xffee90 });
		this.textValue.y = 64;
		this.textValue.anchor = 0.5;

		result.addChild(
			data.icon,
			this.textValue as PIXI.DisplayObject,
		);

		this.addChild(result);
	}

	public setText(value: string): void {
		this.textValue.text = value;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		super.destroy(options);
	}
}
