import { BusinessModel } from '@models/BusinessModel';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { AbstractModelInstance } from './AbstractModelInstance';
import { QuestFactory } from '@src/initializers/QuestFactory';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { EventLevelModelsFactory } from '@src/initializers/models/EventLevelModelsFactory';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';
import { SummonSavesModel } from '@models/SummonSavesModel';

export class EventLevelModelInstance extends AbstractModelInstance {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly characterQuickPhraseEmitter: CharacterQuickPhraseEmitter;

	private readonly lootboxFactory: LootboxFactory;
	private readonly questFactory: QuestFactory;
	private readonly rewardFactory: RewardFactory;

	private readonly eventLevelModel: EventLevelModel;

	private readonly tutorialStepModels: Map<string, TutorialStepBaseModel>;
	private readonly tutorialStepsEmitter: TutorialStepsEmitter;
	private readonly prepartyCharacterModel: PrepartyStartCharacterModel;
	private readonly farewellPartyModels: Map<string, FarewellPartyModel>;

	constructor(
		modelsFactory: EventLevelModelsFactory,
		eventLevelModel: EventLevelModel,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly eventTutorialStepsModel: EventTutorialStepsModel,
		private readonly eventSummonSavesModel: SummonSavesModel,
	) {
		super(modelsFactory);

		this.eventLevelModel = eventLevelModel;

		this.businessModels = modelsFactory.getBusinessesFactory().createModels(
			this.boostModels,
			this.farewellBoostModel,
			this.characterModels,
			this.skillModels,
			new Map(),
			this.upgradeModels,
		);

		this.characterQuickPhraseEmitter = modelsFactory.createCharacterQuickPhraseEmitter(
			this.skillModels,
			this.characterModels,
			this.businessModels,
		);

		this.rewardFactory = modelsFactory.createRewardFactory(
			this.softMoneyModel,
			this.prestigeMoneyModel,
			this.hardMoneyModel,
			this.characterModels,
			this.upgradeModels,
			this.totemModels,
			this.skillModels,
			this.galleryVideoModels,
			this.timeskipModels,
			this.boostModels,
		);

		this.lootboxFactory = modelsFactory.createLootboxFactory(this.rewardFactory);

		this.questFactory = modelsFactory.createQuestFactory(
			this.businessModels,
			this.upgradeModels,
			this.characterModels,
			new Map(),
			this.softMoneyModel,
			this.prestigeMoneyModel,
			this.hardMoneyModel,
			this.summonModelsPool,
			this.farewellPartyModels,
		);

		const tutorialStepsFactory = modelsFactory.createTutorialStepsFactory(this.eventTutorialStepsModel);
		this.tutorialStepModels = tutorialStepsFactory.createModels();

		this.tutorialStepsEmitter = modelsFactory.createTutorialStepsEmitter(
			this.tutorialStepModels,
		);

		this.farewellPartyModels = modelsFactory.getFarewellPartiesFactory().createModels(this.getTotemModels());

		this.prepartyCharacterModel = modelsFactory.createPrepartyStartCharacterModel(this.farewellPartyModels);
	}

	public getBusinessModels(): Map<string, BusinessModel> {
		return this.businessModels;
	}

	public getTutorialStepModels(): Map<string, TutorialStepBaseModel> {
		return this.tutorialStepModels;
	}

	public getCharacterQuickPhraseEmitter(): CharacterQuickPhraseEmitter {
		return this.characterQuickPhraseEmitter;
	}

	public getTutorialStepsEmitter(): TutorialStepsEmitter {
		return this.tutorialStepsEmitter;
	}

	// eslint-disable-next-line class-methods-use-this
	public getFarewellPartyModels(): Map<string, FarewellPartyModel> {
		return new Map();
	}

	// eslint-disable-next-line class-methods-use-this
	public getEpicQuestCollectionsModels(): Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel> {
		return new Map();
	}

	// eslint-disable-next-line class-methods-use-this
	public getLevelChallengeModel(): LevelChallengeModel {
		return undefined;
	}

	// eslint-disable-next-line class-methods-use-this
	public getTimedQuestLinesModel(): TimedQuestLinesModel {
		return undefined;
	}

	public getQuestFactory(): QuestFactory {
		return this.questFactory;
	}

	public getLootboxFactory(): LootboxFactory {
		return this.lootboxFactory;
	}

	public getPrepartyStartCharacterModel(): PrepartyStartCharacterModel {
		return this.prepartyCharacterModel;
	}

	public getLevelModel(): EventLevelModel {
		return this.eventLevelModel;
	}

	public getHardMoneyModel(): HardMoneyModel {
		return this.hardMoneyModel;
	}

	public getRewardFactory(): RewardFactory {
		return this.rewardFactory;
	}

	public getEventTutorialStepsModel(): EventTutorialStepsModel {
		return this.eventTutorialStepsModel;
	}

	public getSummonSavesModel(): SummonSavesModel {
		return this.eventSummonSavesModel;
	}
}
