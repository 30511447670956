import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';

export class TimedQuestsTicker {
	private readonly timedQuestLinesModel: TimedQuestLinesModel;
	private readonly ticker: PIXI.ticker.Ticker;

	private currentTargets: Set<AbstractQuest>;
	private ticking: boolean;
	private enabled: boolean;

	constructor(timedQuestLinesModel: TimedQuestLinesModel) {
		this.timedQuestLinesModel = timedQuestLinesModel;
		this.ticker = PIXI.ticker.shared;

		this.currentTargets = new Set();

		if (this.timedQuestLinesModel.hasCurrentActiveQuest()) {
			this.onSomeCurrentTimedQuest(this.timedQuestLinesModel.getCurrentQuest());
		}
		this.timedQuestLinesModel.on(TimedQuestLinesModel.EVENT_QUEST_CURRENT_ADED, this.onSomeCurrentTimedQuest, this);
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (value) {
			this.tryStartTicker();
		} else {
			this.tryStopTicker();
		}
	}

	private tryStartTicker(): void {
		if (!this.ticking && this.enabled && this.currentTargets.size > 0) {
			this.startTicker();
		}
	}

	private tryStopTicker(): void {
		if (this.ticking && this.currentTargets.size === 0) {
			this.stopTicker();
		}
	}

	private startTicker(): void {
		this.ticking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	private stopTicker(): void {
		this.ticking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}

	private stopTickerOnQuest(quest: AbstractQuest): void {
		this.currentTargets.delete(quest);
		this.tryStopTicker();
	}

	private onSomeCurrentTimedQuest(quest: AbstractQuest): void {
		if (!quest.isCompleted()) {
			this.currentTargets.add(quest);

			quest.once(AbstractQuest.EVENT_TIMEOUT, this.stopTickerOnQuest, this);
			quest.once(AbstractQuest.EVENT_COMPLETED, this.stopTickerOnQuest, this);

			this.tryStartTicker();
		}
	}

	private onTimerUpdate(): void {
		this.currentTargets.forEach(quest => {
			const oldTimeleft = quest.getTimeleft();
			const newTimeleft = oldTimeleft - this.ticker.elapsedMS / 1000;
			quest.setTimeleft(newTimeleft);
		});
	}
}
