import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20004View } from '@views/characters/events/event2/EventCharacter20004View';
import { EventCharacter20005View } from '@views/characters/events/event2/EventCharacter20005View';
import { EventCharacter20006View } from '@views/characters/events/event2/EventCharacter20006View';

export class EventBusiness20002View extends BusinessBaseView {
	constructor() {
		const character20004 = new EventCharacter20004View();
		const character20005 = new EventCharacter20005View();
		const character20006 = new EventCharacter20006View();
		super('business20002', [character20004, character20005, character20006]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			character20004,
			character20005,
			character20006 as PIXI.DisplayObject,
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
