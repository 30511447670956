import { CharacterProfileWindowViewSetter } from '@interfaces/ViewSetters';
import { CharacterProfileWindow } from '@views/windows/collection/characters/CharacterProfileWindow';
import { CharacterModel } from '@models/CharacterModel';
import { CharacterPromoteAction } from '@models/network/actions/characters/CharacterPromoteAction';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { OpenPromoteWindowAction } from '@models/network/actions/OpenPromoteWindowAction';
import { BasePromoteWindowViewController } from './BasePromoteWindowViewController';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';

export class CharacterProfileWindowViewController extends BasePromoteWindowViewController implements CharacterProfileWindowViewSetter {
	private readonly characterModels: Map<string, CharacterModel>;
	private view: CharacterProfileWindow;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		characterModels: Map<string, CharacterModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		summonModelsPool: SummonModelsPool,
		questTargetDuration: number,
	) {
		super(windowViewSystem, windowFactory, prestigeMoneyModel, summonModelsPool, questTargetDuration);

		this.characterModels = characterModels;
	}

	public setCharacterProfileWindowView(view: CharacterProfileWindow): void {
		this.view = view;
		view.on(CharacterProfileWindow.EVENT_BUTTON_PROMOTE_CLICK, this.onButtonPromoteClick, this);
		view.on(CharacterProfileWindow.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlus, this);
		view.on(CharacterProfileWindow.EVENT_SELECT_PICTURE, this.onSelectPicture, this);

		const model = this.characterModels.get(view.getKey());
		if (model.isNew()) {
			model.setOld();

			const action = new OpenPromoteWindowAction(model.getKey(), 'character');
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	private onSelectPicture(key: string, pictureId: number): void {
		const model: CharacterModel = this.characterModels.get(key);
		const currentPicture = model.getCurrentPictureId();
		const possiblePicture = Math.min(pictureId, model.getMaxPictureIndex());
		if (currentPicture !== possiblePicture) {
			model.setPictureId(possiblePicture);
			const userDataKey: string = `${model.getKey()}_picture_id`;
			this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({
				[userDataKey]: pictureId,
			}));
		}
	}

	protected createAction(model: CharacterModel): BaseAction {
		return new CharacterPromoteAction(
			model.getKey(),
			model.getPrevPromoteCostPrestige(),
			model.getPrevPromoteCostCards(),
			model.getLevel(),
			this.prestigeMoneyModel.getValue(),
			model.getCardsCount(),
		);
	}

	protected onBankLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_prestige');
	}

	protected onCollectionLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_cards');
	}
}
