import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { CollectionTabButtonView } from './CollectionTabButtonView';

export class FurnitureButtonView extends CollectionTabButtonView {
	private readonly localizationStorage: LocalizationStorage;

	constructor(totalCards: number) {
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['interior_tab_icon']);
		icon.x = -118;
		const label = LocalizationStorage.getInstance().getLocalizedString('#collection_window_furniture_tab');

		super(
			icon,
			label,
			totalCards,
		);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
	}

	private updateText(): void {
		this.buttonLabel.text = this.localizationStorage.getLocalizedString('#collection_window_furniture_tab');
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
