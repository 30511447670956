import { ModelsBaseFactory } from './ModelsBaseFactory';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { ModelInstanceDataBase, TutorialModelInstanceData, FarewellPartyModelInstanceData } from '@src/types/ModelInstanceDataTypes';
import CharacterConfig from '@configs/CharacterConfig';
import {
	CharacterSaveData,
	SkillSaveData,
	BusinessSaveData,
	BoostSaveData,
	UpgradeSaveData,
	TimeskipSaveData,
	TotemSaveData,
	FarewellBonusSaveData,
	BankSaveData,
} from '@src/types/SaveTypes';
import { SkillConfig } from '@configs/SkillConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { BusinessConfig } from '@configs/BusinessConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { VideoConfig } from '@configs/VideoConfig';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { TotemConfig } from '@configs/TotemConfig';
import { TutorialStepsEventLevelFactory } from '../TutorialStepsEventLevelFactory';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { TutorialStepBaseConfig } from '@configs/tutorialSteps/TutorialStepBaseConfig';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';

export class EventLevelModelsFactory extends ModelsBaseFactory {
	constructor(
		serverTime: ServerTimeModel,
		characterModelInstanceData: ModelInstanceDataBase<CharacterConfig, CharacterSaveData>,
		skillModelInstanceData: ModelInstanceDataBase<SkillConfig, SkillSaveData>,
		upgradeModelInstanceData: ModelInstanceDataBase<UpgradeConfig, UpgradeSaveData>,
		businessModelInstanceData: ModelInstanceDataBase<BusinessConfig, BusinessSaveData>,
		boostModelInstanceData: ModelInstanceDataBase<BoostConfig, BoostSaveData>,
		timeskipModelInstanceData: ModelInstanceDataBase<TimeskipConfig, TimeskipSaveData>,
		tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepEventLevelConfig>,
		promotePatternsConfig: AbstractPromotePatternsConfig,
		bankPriceItemsConfig: BankPriceItemsConfig,
		constantsConfig: ConstantsConfig,
		newCustomerMultiplierRewards: LootboxNewCustomerMultiplierConfig[],
		dialogConfigs: Map<string, DialogConfig>,
		farewellBoostSaveData: FarewellBonusSaveData | null,
		bankSaveData: BankSaveData,
		isNutaku: boolean,
		galleryVideoModelInstanceData?: ModelInstanceDataBase<VideoConfig, string>,
		epicQuestCollectionsModelInstanceData?: ModelInstanceDataBase<EpicQuestCollectionsConfig, number[]>,
		totemModelInstanceData?: ModelInstanceDataBase<TotemConfig, TotemSaveData>,
		farewellPartyModelInstanceData?: FarewellPartyModelInstanceData,
	) {
		super(
			serverTime,
			characterModelInstanceData,
			skillModelInstanceData,
			upgradeModelInstanceData,
			businessModelInstanceData,
			boostModelInstanceData,
			timeskipModelInstanceData,
			tutorialStepModelInstanceData as TutorialModelInstanceData<TutorialStepBaseConfig>,
			promotePatternsConfig,
			bankPriceItemsConfig,
			constantsConfig,
			newCustomerMultiplierRewards,
			dialogConfigs,
			farewellBoostSaveData,
			bankSaveData,
			isNutaku,
			galleryVideoModelInstanceData,
			epicQuestCollectionsModelInstanceData,
			totemModelInstanceData,
			farewellPartyModelInstanceData,
		);
	}

	public createTutorialStepsFactory(stepsModel: EventTutorialStepsModel): TutorialStepsEventLevelFactory {
		const data = this.tutorialStepModelInstanceData as TutorialModelInstanceData<TutorialStepEventLevelConfig>;

		return new TutorialStepsEventLevelFactory(
			data.configs,
			stepsModel,
		);
	}
}
