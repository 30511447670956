import LocalizationStorage from '@main/LocalizationStorage';
import { MaintenanceModel } from '@models/MaintenanceModel';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { NumberUtils } from '@src/utils/NumberUtils';
import * as TWEEN from '@tweenjs/tween.js';

export class MaintenanceTimerView extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;

	private readonly titleText: SizeableBitmapText;
	private readonly timerText: SizeableBitmapText;
	private readonly tickerTimer: PIXI.ticker.Ticker;

	private timerTicking: boolean;

	constructor(
		private readonly model: MaintenanceModel,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.model.on(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING, this.onUpcomingMaintenance, this);
		this.model.on(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING_END, this.onUpcomingMaintenanceEnd, this);

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['mt_timer_bg'], 25, 0, 25, 0);
		buttonBg.width = 366;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['mt_timer_icon']);
		icon.position.set(-127, -3);

		this.titleText = new SizeableBitmapText('', 248, { font: '24px wendyOneShadowBold' });
		this.titleText.anchor = 0;
		this.titleText.position.set(-86, -30);
		this.onTranslateText();

		this.timerText = new SizeableBitmapText('', 248, { font: '28px wendyOneShadowBold', tint: 0xffcf30 });
		this.timerText.anchor = 0;
		this.timerText.position.set(-86, -5);

		this.addChild(
			buttonBg,
			this.titleText as PIXI.DisplayObject,
			this.timerText,
			icon,
		);

		this.tickerTimer = PIXI.ticker.shared;
	}

	public startAppearingAnimation(): void {
		this.visible = true;
		this.y -= 180;
		new TWEEN.Tween(this)
			.to({ y: this.y + 180 }, 900)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.start();
	}

	public startHidingAnimation(): void {
		const originalY = this.y;

		new TWEEN.Tween(this)
			.to({ y: this.y - 180 }, 900)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.start()
			.onComplete(() => {
				this.visible = false;
				this.y = originalY;
			});
	}

	private onUpcomingMaintenance(): void {
		if (!this.timerTicking) {
			this.startTimer();
		}
	}

	private onUpcomingMaintenanceEnd(): void {
		if (this.timerTicking) {
			this.stopTimer();
		}
	}

	private startTimer(): void {
		this.timerTicking = false;
		this.tickerTimer.add(this.onUpdateTimeDisplay, this);
	}

	private stopTimer(): void {
		this.timerTicking = true;
		this.tickerTimer.remove(this.onUpdateTimeDisplay, this);
	}

	private onUpdateTimeDisplay(): void {
		this.timerText.text = NumberUtils.secToHMS(this.model.getTimeBeforeMaintenance());
	}

	private onTranslateText(): void {
		const titleStr = this.localizationStorage.getLocalizedString('#gameUI_Maintenance');
		this.titleText.text = titleStr;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.timerTicking) {
			this.stopTimer();
		}

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.model.off(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING, this.onUpcomingMaintenance, this);
		this.model.off(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING_END, this.onUpcomingMaintenanceEnd, this);

		super.destroy(options);
	}
}
