import { ViewportViewSetter } from '@interfaces/ViewSetters';
import { BankModel } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankOfferWindowUnlockTypeBaseModel } from '@models/bank/BankOfferWindowUnlockTypeModels';
import { ModelHelper } from '@models/ModelHelper';
import { ViewportView } from '@views/components/ViewportView';

export class BankOfferWindowsEmitter extends PIXI.utils.EventEmitter
	implements ViewportViewSetter {
	public static readonly EVENT_BANK_OFFER_WINDOW: symbol = Symbol();

	private static readonly MAX_BANK_OFFER_WINDOWS_TO_EMIT: number = 3;

	private readonly unlockTypeModels: BankOfferWindowUnlockTypeBaseModel[];
	private readonly bankModel: BankModel;

	private bankOfferModelsWithPendingWindow: BankOfferModel[];
	private forceEmit: boolean;
	private enabled: boolean;
	private viewport: ViewportView;

	constructor(
		bankModel: BankModel,
		unlockTypeModels: BankOfferWindowUnlockTypeBaseModel[],
	) {
		super();

		this.unlockTypeModels = unlockTypeModels;
		this.bankOfferModelsWithPendingWindow = [];
		this.forceEmit = true;
		this.viewport = null;

		this.bankModel = bankModel;
		this.bankModel.on(BankModel.EVENT_UPDATED, this.updateBankOfferModels, this);
	}

	public setEnabled(value: boolean, forceEmit: boolean = false): void {
		this.enabled = value;

		if (this.enabled) {
			this.forceEmit = forceEmit;
			this.updateBankOfferModels();
		}
	}

	public setViewportView(viewport: ViewportView): void {
		this.viewport = viewport;
	}

	private updateBankOfferModels(): void {
		if (!this.bankModel.isObsolete()) {
			const bankOfferModelsWithActiveBankTabElement = this.bankModel.getBankOfferModelsWithActiveBankTabElement();
			const notExpiredBankOfferModels = ModelHelper.filterBankOfferModelsByTimeleft(bankOfferModelsWithActiveBankTabElement);
			const showOnMainMenuBankOfferModels = notExpiredBankOfferModels.filter(offerModel => offerModel.isShowOnMainWindow());
			const sortedBankOfferModels = ModelHelper.sortBankOffersModelsByPriority(showOnMainMenuBankOfferModels);
			const bankOfferModelsForEmitOfferWindow = sortedBankOfferModels
				.slice(0, Math.min(sortedBankOfferModels.length, BankOfferWindowsEmitter.MAX_BANK_OFFER_WINDOWS_TO_EMIT));

			this.bankOfferModelsWithPendingWindow = bankOfferModelsForEmitOfferWindow
				.filter(offerModel => !offerModel.isOfferWindowShowed());

			if (this.bankOfferModelsWithPendingWindow.length > 0) {
				this.onSomePendingBankOfferWindowsAdded();
			}
		}
	}

	private onSomePendingBankOfferWindowsAdded(): void {
		if (this.enabled) {
			if (this.viewport.isZoomed() || this.viewport.isZooming()) {
				if (!this.viewport.listeners(ViewportView.EVENT_ZOOM_OUT_COMPLETED).includes(this.onZoomOutComplete)) {
					this.viewport.once(ViewportView.EVENT_ZOOM_OUT_COMPLETED, this.onZoomOutComplete, this);
				}
			} else {
				this.unlockTypeModels
					.forEach(model => model.off(BankOfferWindowUnlockTypeBaseModel.EVENT_UNLOCKED, this.onSomeUnlockTypeModelUnlocked, this, true));
				this.unlockTypeModels.forEach(model => model.once(BankOfferWindowUnlockTypeBaseModel.EVENT_UNLOCKED, this.onSomeUnlockTypeModelUnlocked, this));
				this.unlockTypeModels.forEach(model => model.activate());

				if (this.forceEmit) {
					this.forceEmit = false;
					this.emitBankOfferWindows();
				}
			}
		}
	}

	private onSomeUnlockTypeModelUnlocked(): void {
		if (this.enabled && this.bankOfferModelsWithPendingWindow.length !== 0) {
			this.unlockTypeModels.forEach(model => model.deactivate());
			this.emitBankOfferWindows();
		}
	}

	private emitBankOfferWindows(): void {
		this.bankOfferModelsWithPendingWindow
			.filter(model => !model.isOfferWindowShowed())
			.forEach(model => {
				model.setOfferWindowShowed(true);
				this.emit(BankOfferWindowsEmitter.EVENT_BANK_OFFER_WINDOW, model.getKey());
			});
	}

	private onZoomOutComplete(): void {
		if (this.bankOfferModelsWithPendingWindow.length > 0) {
			this.emitBankOfferWindows();
		}
	}
}
