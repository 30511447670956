import { BaseAction } from './BaseAction';

export class OpenPromoteWindowAction extends BaseAction {
	constructor(
		key: string,
		type: string = 'character' || 'upgrade' || 'totem',
	) {
		super('OpenPromoteWindow');

		this.data.id = key;
		this.data.type = type;
	}
}
