import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character9View extends CharacterMainBaseView {
	constructor() {
		super('character9');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(139, 38);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(140, -49),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(220, -52),
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
