import { LevelPanelView } from '@views/ui/levelProgressPanel/LevelPanelView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { BusinessModel } from '@models/BusinessModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { TopUIPanelBaseView } from './TopUIPanelBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class LevelTopUIPanelView extends TopUIPanelBaseView {
	public static readonly EVENT_SHOW_HINT_LEVEL_PANEL_VIEW: symbol = Symbol();

	private readonly levelPanelView: LevelPanelView;
	private readonly addtionalBackground: PIXI.mesh.NineSlicePlane;

	constructor(
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		businessModels: BusinessModel[],
		skillModels: Map<string, SkillModel>,
		gameProfileModel: GameProfileModel,
	) {
		super(
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			businessModels,
			skillModels,
			gameProfileModel,
		);

		this.levelPanelView = new LevelPanelView();
		this.levelPanelView.on(LevelPanelView.EVENT_SHOW_HINT, (arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
			this.emit(LevelTopUIPanelView.EVENT_SHOW_HINT_LEVEL_PANEL_VIEW, arrowPosLocal, origin);
		});
		this.levelPanelView.position.set(73, 44);

		this.addtionalBackground = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_ui_level_time_panel'], 14, 0, 18, 0);
		this.addtionalBackground.width = 368;
		this.addtionalBackground.pivot.set(this.addtionalBackground.width / 2, this.addtionalBackground.height / 2);
		this.addtionalBackground.position.set(170, 120);

		this.addChild(
			this.addtionalBackground,
			this.levelPanelView as PIXI.DisplayObject,
		);
	}

	public init(
		currentLevel: number,
		currentSeason: number,
		currentProgress: number,
		pendingProgress: number,
		totalProgress: number,
	): void {
		this.levelPanelView.setLevel(currentLevel);
		this.levelPanelView.setSeason(currentSeason);
		this.levelPanelView.setTotalProgress(totalProgress);
		this.levelPanelView.setProgress(currentProgress, pendingProgress, false);

		this.updateTextIncomePerSec();
	}

	public setLevelProgress(current: number, pending: number, playProgressAnimation: boolean): void {
		this.levelPanelView.setProgress(current, pending, playProgressAnimation);
	}

	public getLevelPanel(): LevelPanelView {
		return this.levelPanelView;
	}

	public onZoomInStarted(): void {
		super.onZoomInStarted();

		new TWEEN.Tween({ alpha: 1 })
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.addtionalBackground.alpha = tweenTarget.alpha;
				this.levelPanelView.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.addtionalBackground.visible = false;
				this.levelPanelView.visible = false;
			})
			.start();
	}

	public onZoomOutStarted(): void {
		super.onZoomOutStarted();

		this.addtionalBackground.visible = true;
		this.levelPanelView.visible = true;

		new TWEEN.Tween({ alpha: 0 })
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.addtionalBackground.alpha = tweenTarget.alpha;
				this.levelPanelView.alpha = tweenTarget.alpha;
			})
			.start();
	}

	public showAdditionalBackground(): void {
		if (!this.addtionalBackground.visible) {
			this.addtionalBackground.visible = true;
			this.addtionalBackground.scale.set(0, 0);
			new TWEEN.Tween(this.addtionalBackground.scale)
				.easing(TWEEN.Easing.Sinusoidal.InOut)
				.to({ x: 1, y: 1 }, 100)
				.start();
		}
	}

	public hideAdditionalBackground(): void {
		this.addtionalBackground.visible = false;
	}

	public additionalBackgroundVisible(): boolean {
		return this.addtionalBackground.visible;
	}
}
