import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character30View extends CharacterMainBaseView {
	constructor() {
		super('character30');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(45, -44),
			bgPosition: new PIXI.Point(120, -47),
			arrowRotation: -Math.PI / 2,
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
