import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70010View extends CharacterMainBaseView {
	constructor() {
		super('character70010');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-152, 63);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(169, -51),
			bgPosition: new PIXI.Point(165, -35),
			arrowRotation: Math.PI,
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
