import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20001View } from '@views/characters/events/event2/EventCharacter20001View';
import { EventCharacter20002View } from '@views/characters/events/event2/EventCharacter20002View';
import { EventCharacter20003View } from '@views/characters/events/event2/EventCharacter20003View';

export class EventBusiness20001View extends BusinessBaseView {
	constructor() {
		const character20001 = new EventCharacter20001View();
		const character20002 = new EventCharacter20002View();
		const character20003 = new EventCharacter20003View();

		super('business20001', [character20001, character20002, character20003]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor4']).setTransform(3, 0, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character20001 as PIXI.DisplayObject,
			character20002,
			character20003,
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
