import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100001View } from '@views/characters/events/event10/EventCharacter100001View';
import { EventCharacter100002View } from '@views/characters/events/event10/EventCharacter100002View';
import { EventCharacter100003View } from '@views/characters/events/event10/EventCharacter100003View';

export class EventBusiness100001View extends BusinessBaseView {
	constructor() {
		const eventCharacter100001View = new EventCharacter100001View();
		const eventCharacter100002View = new EventCharacter100002View();
		const eventCharacter100003View = new EventCharacter100003View();

		super('business100001', [eventCharacter100001View, eventCharacter100002View, eventCharacter100003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b1_decor5']).setTransform(276, 34, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor1']).setTransform(-225, -17, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor3']).setTransform(-217, -72, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor2']).setTransform(166, -37, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			new PIXI.Sprite(decorationAtlas['b1_decor4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite,
			eventCharacter100001View as PIXI.DisplayObject,
			eventCharacter100002View,
			eventCharacter100003View,
		);
	}
}
