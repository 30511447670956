import * as crypto from 'crypto-js';
import { NetworkProfileLoader } from './NetworkProfileLoader';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { NetworkRequestTransport } from '@src/network/NetworkRequestTransport';
import { GameProfileModel } from '@models/GameProfileModel';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { LocalizationLoader } from './LocalizationLoader';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { DeviceUtils } from '@src/utils/DeviceUtils';

type NutakuProfileData = {
	id: string;
	username: string;
	isGuest: boolean;
}

export class NutakuNetworkProfileLoader extends NetworkProfileLoader {
	private static readonly SALT: string = 'aa1d8c4426a74232bc8e5ef1e63599f7';
	private userId: string;
	private nutakuProfileRequest: Promise<NutakuProfileData> | null;
	constructor(
		networkRequestSender: NetworkRequestSender,
		networkRequestTransport: NetworkRequestTransport,
		gameProfileModel: GameProfileModel,
		serverTime: ServerTimeModel,
		savesConfig: SavesConfig,
		localizationLoader: LocalizationLoader,
		url: string,
	) {
		super(
			networkRequestSender,
			networkRequestTransport,
			gameProfileModel,
			serverTime,
			savesConfig,
			localizationLoader,
			url,
		);

		this.nutakuProfileRequest = this.createNutakuProfileRequest();
	}

	public async loadProfile(): Promise<void> {
		if (this.nutakuProfileRequest !== null) {
			const response: NutakuProfileData = await this.nutakuProfileRequest;
			const isGuest = response == null ? true : response.isGuest;
			this.userId = response.id;

			this.gameProfileModel.setUsername(response.username);

			this.networkRequestTransport
				.setNutakuId(this.userId)
				.setPlayerId(this.createProfileId());

			this.nutakuProfileRequest = null;

			if (!isGuest) {
				this.gameProfileModel.setLoggedIn();
			}
		}

		super.loadProfile();
	}

	protected async sendGetProfileId(): Promise<Record<string, string>> {
		return this.networkRequestSender.sendGetProfileId(this.url, {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			nutaku_id: this.userId,
		});
	}

	private createProfileId(): string {
		const md5 = crypto.MD5(this.userId + NutakuNetworkProfileLoader.SALT);
		return `NUTAKU${md5}`;
	}

	// eslint-disable-next-line class-methods-use-this
	public createNutakuProfileRequest(): Promise<NutakuProfileData> {
		let result;

		if (DeviceUtils.isAndroid() || DeviceUtils.isIos()) {
			result = this.createNutakuProfileRequestSP();
		} else {
			result = this.createNutakuProfileRequestPC();
		}

		return result;
	}

	// eslint-disable-next-line class-methods-use-this
	public createNutakuProfileRequestPC(): Promise<NutakuProfileData> {
		return new Promise((resolve, reject) => {
			const req = opensocial.newDataRequest();
			const params: Record<string, unknown> = {};

			params[opensocial.DataRequest.PeopleRequestFields.PROFILE_DETAILS] = [nutaku.Person.Field.GRADE];
			req.add(req.newFetchPersonRequest(opensocial.IdSpec.PersonId.VIEWER, params), 'viewer');

			req.send((response: opensocial.DataResponse) => {
				if (response.hadError()) {
					reject(response.getErrorMessage());
				} else {
					const item = response.get('viewer');
					if (item.hadError()) {
						reject(item.getErrorMessage());
					} else {
						const viewer = item.getData();
						const id: string = viewer.getId().toString();
						const username: string = viewer.getDisplayName();

						resolve({
							id,
							username,
							isGuest: viewer.getField(nutaku.Person.Field.GRADE) === '0',
						});
					}
				}
			});
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private createNutakuProfileRequestSP(): Promise<NutakuProfileData> {
		return this.networkRequestSender.sendNutakuGetProfile()
			.then((response) => {
				const data = response.entry;

				return {
					username: data.displayName as string,
					id: data.id as string,
					isGuest: data.grade === '0',
				};
			});
	}
}
