import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BonusBaseModel } from '@models/bonuses/BonusBaseModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { BONUS_TYPE_ICON } from '@src/types/BonusTypes';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';

export class RewardResultBonusContainer extends PIXI.Container {
	private readonly bonusTextLabel: SizeableMultiColoredBitmapText;
	private readonly bonusValueLabel: SizeableMultiColoredBitmapText;
	private readonly icon: PIXI.Sprite;
	private readonly localizationStorage: LocalizationStorage;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.Graphics();
		bg.beginFill(0x000000, 1);
		bg.drawPolygon([
			new PIXI.Point(220, -43),
			new PIXI.Point(-205, -43),
			new PIXI.Point(-220, 43),
			new PIXI.Point(205, 43),
		]);

		const graphics = new PIXI.Graphics();
		graphics.beginFill(0x474747);
		graphics.drawRect(-45, -32, 2, 65);
		graphics.endFill();

		const bonusTextLabel = new SizeableMultiColoredBitmapText(
			220,
			{ font: '34px wendyOneShadowBold', align: 'center' },
		);
		bonusTextLabel.anchor = 0.5;
		bonusTextLabel.position.set(86, 0);
		this.bonusTextLabel = bonusTextLabel;

		const bonusValue = new SizeableMultiColoredBitmapText(
			84,
			{ font: '40px wendyOneShadowBold', align: 'center', tint: 0xfff694 },
		);
		bonusValue.anchor = 0.5;
		bonusValue.position.set(-94, 0);
		this.bonusValueLabel = bonusValue;

		this.icon = new PIXI.Sprite();
		this.icon.pivot.set(this.icon.width / 2, this.icon.height / 2);
		this.icon.setTransform(-170, -21, 0.5, 0.5);

		this.addChild(
			bg,
			this.bonusTextLabel as PIXI.DisplayObject,
			this.bonusValueLabel,
			graphics,
			this.icon,
		);
	}

	public setBonus(bonus: BonusBaseModel, charModel: CharacterModel | UpgradeModel): void {
		const businessName = `[8c91a1]${this.localizationStorage.getLocalizedString(`#${charModel.getRelatedBusinessKey()}_Name`)}[-]`;
		const businessDescr = this.localizationStorage
			.getLocalizedString(`#${bonus.getType()}`)
			.replace('{value}', businessName);

		const bonusText = `${businessDescr}`;
		const bonusValue = `x${bonus.getValue()}`;

		this.bonusTextLabel.text = bonusText;
		this.bonusValueLabel.text = bonusValue;

		const iconKey = BONUS_TYPE_ICON[bonus.getType()];
		this.icon.texture = AssetsStorage.getAtlas('uiAtlas')[iconKey];
		this.icon.setTransform(-94 - this.bonusValueLabel.width / 2 - this.icon.width, -this.icon.height / 2, 0.5, 0.5);
	}

	public setSkill(skill: SkillModel, charModel: CharacterModel): void {
		const businessName = this.localizationStorage.getLocalizedString(`#${charModel.getRelatedBusinessKey()}_Name`);
		const bonusText = `[8c91a1]${businessName}[-]`;
		const text = this.localizationStorage.getLocalizedString('#skill');

		this.bonusTextLabel.text = bonusText;
		this.bonusValueLabel.text = text;

		this.icon.texture = AssetsStorage.getAtlas('uiAtlas')[skill.getIconKey()];
		this.icon.setTransform(-94 - this.bonusValueLabel.width / 2 - this.icon.width, -this.icon.height / 2, 0.5, 0.5);
	}
}
