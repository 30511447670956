import { QuestConfigBase } from './QuestConfigBase';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export class QuestGetSoftMoneyBaseConfig extends QuestConfigBase {
	private hours?: number;
	private amount: SoftMoneyNumber;
	private autoGenerateAmount: boolean;
	private isTutorial: boolean;
	private businessKey?: string;

	constructor(config: { [key: string]: string }, isTutorial: boolean) {
		super(config);

		this.isTutorial = isTutorial;

		const params = config[QuestConfigBase.FIELD_PARAMETERS];
		const parsed = params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR);

		if (parsed != null) {
			// eslint-disable-next-line prefer-destructuring
			this.businessKey = parsed[1];
		}
	}

	public getAmount(): SoftMoneyNumber {
		return this.amount;
	}

	public isAutoGenerateAmount(): boolean {
		return this.autoGenerateAmount;
	}

	public getHours(): number {
		return this.hours;
	}

	public setParamsOnLevel(params: string): void {
		if (QuestGetSoftMoneyBaseConfig.FORMAT_HOURS.test(params)) {
			this.autoGenerateAmount = true;
			this.hours = Number(params.match(QuestGetSoftMoneyBaseConfig.FORMAT_HOURS)[1]);
		} else {
			this.autoGenerateAmount = false;
			this.amount = SoftMoneyNumber.createFromAcronym(params);
		}
	}

	public getBusinessKey(): string | undefined {
		return this.businessKey;
	}

	public getType(): QuestType {
		let result: QuestType;

		if (this.isTutorial) {
			if (this.businessKey != null) {
				result = QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS_TUTORIAL;
			} else {
				result = QuestTypes.GET_SOFT_MONEY_TUTORIAL;
			}
		} else if (this.businessKey != null) {
			result = QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS;
		} else {
			result = QuestTypes.GET_SOFT_MONEY;
		}

		return result;
	}
}
