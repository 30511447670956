import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10018View extends CharacterMainBaseView {
	constructor() {
		super('character10018');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-20, -15),
			bgPosition: new PIXI.Point(100, -19),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
