// eslint-disable-next-line import/no-extraneous-dependencies
import 'PIXI';
import 'pixi-particles';
import 'pixi-sound';
import 'pixi-spine';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import * as TWEEN from '@tweenjs/tween.js';
import { GameConstants } from '@src/utils/GameConstants';
import { LevelConfigsParser } from '../configs/configsParsers/LevelConfigsParser';
import { NetworkRequestSender } from '../network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { ErrorWindowView } from '@views/windows/ErrorWindowView';
import { SupportWindowView } from '@views/windows/support/SupportWindowView';
import { NetworkRequestHeaders } from '../network/NetworkRequestHeaders';
import { TextUtils } from '@src/utils/TextUtils';
import { GameProfileModel } from '@models/GameProfileModel';
import { SupportWindowViewController } from '@src/viewControllers/SupportWindowViewController';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { NetworkProfileLoader } from '../loaders/NetworkProfileLoader';
import { AnalyticsSender } from '../network/analytics/AnalyticsSender';
import { AFKTicker } from '@src/tickers/AFKTicker';
import { AutoTapLimiter } from '@src/utils/AutoTapLimiter';
import { LevelInstance } from './LevelInstance';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventLevelPreloaderView } from '@views/preloader/EventLevelPreloaderView';
import { BaseAction } from '@models/network/actions/BaseAction';
import { LocalizationLoader } from '@src/loaders/LocalizationLoader';
import { NetworkRequestTransport } from '@src/network/NetworkRequestTransport';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { EventLevelFirstEnterAction } from '@models/network/actions/events/EventLevelFirstEnterAction';
import { LevelPreloaderInstance } from './LevelPreloaderInstance';
import { EventLevelSystem } from './EventLevelSystem';
import { AnalyticSourceGameProfileModel } from '@src/network/analytics/sources/AnalyticSourceGameProfileModel';
import { NetworkUtils } from '@src/utils/NetworkUtils';
import { ServerErrorCode } from '@src/types/NetworkTypes';
import { DeviceUtils } from '@src/utils/DeviceUtils';
import { EventLevelModelInstanceFactory } from '@src/initializers/instances/EventLevelModelInstanceFactory';
import { LevelModelInstanceFactory } from '@src/initializers/instances/LevelModelInstanceFactory';
import { LevelModelInstance } from './modelInstance/LevelModelInstance';
import { AnalyticSourceModels } from '@src/network/analytics/sources/AnalyticSourceModels';
import Decimal from 'decimal.js';
import { NutakuNetworkProfileLoader } from '@src/loaders/NutakuNetworkProfileLoader';
import { AccountLifetimeTicker } from '@src/tickers/AccountLifetimeTicker';
import { AnalyticsPlatform } from '@src/types/AnalyticsTypes';
import { ResizeController } from './ResizeController';
import { AssetLoader } from '@src/utils/AssetLoader';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';
import { legalLinks } from '@views/preloader/PreloaderLinksView';

class Application {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly networkRequestTransport: NetworkRequestTransport;
	private readonly networkLoader: NetworkProfileLoader;
	private readonly localizationLoader: LocalizationLoader;
	private readonly assetUrlsLoader: AssetUrlsLoader;
	private readonly analyticsSender: AnalyticsSender;
	private readonly levelConfigsParser: LevelConfigsParser;
	private readonly autoTapLimiter: AutoTapLimiter;
	private readonly afkTicker: AFKTicker;
	private readonly accountLifetimeTicker: AccountLifetimeTicker;

	private readonly serverTimeModel: ServerTimeModel;
	private readonly savesConfig: SavesConfig;
	private readonly cheatModel: CheatModel;
	private readonly gameProfileModel: GameProfileModel;
	private readonly eventLevelModelInstanceFactory: EventLevelModelInstanceFactory;
	private readonly levelModelInstanceFactory: LevelModelInstanceFactory;
	private levelModelInstance: LevelModelInstance;
	private readonly env: string;
	private readonly landingModest: boolean;
	private readonly originalUrl: string;
	private readonly app: PIXI.Application;
	private readonly isNutaku: boolean;
	private readonly version: string;
	private readonly isFooterVisible: boolean;

	private errorWindow: ErrorWindowView;

	private preloaderBg: PIXI.Sprite;

	private levelPreloaderInstance: LevelPreloaderInstance;
	private levelInstance: LevelInstance;

	private eventLevelSystem: EventLevelSystem;

	constructor() {
		window.oncontextmenu = () => false;

		Decimal.config({ toExpPos: 10000 });

		this.app = new PIXI.Application({
			width: GameConstants.GAME_WIDTH,
			height: GameConstants.GAME_HEIGHT,
			backgroundColor: 0x000000,
			view: document.getElementById('game') as HTMLCanvasElement,
			antialias: true,
		});

		document.getElementById('game-container').appendChild(this.app.view);

		this.app.ticker.add(() => {
			TWEEN.update(this.app.ticker.lastTime);
		});

		this.isNutaku = NUTAKU;
		this.version = VERSION;

		this.cheatModel = new CheatModel();

		const urlParameterLanding: string = NetworkUtils.getURLParameterByName('landing');
		this.landingModest = urlParameterLanding === 'modest'
			|| this.cheatModel.isLandingModest();

		this.isFooterVisible = !(DeviceUtils.isAndroid() || DeviceUtils.isIos()) && !this.isNutaku && !this.landingModest;

		// eslint-disable-next-line no-new
		new ResizeController(
			this.isNutaku,
			this.app.view,
			this.isFooterVisible,
		);

		this.serverTimeModel = new ServerTimeModel();
		this.gameProfileModel = new GameProfileModel();
		this.levelConfigsParser = new LevelConfigsParser();
		this.savesConfig = new SavesConfig();
		this.levelModelInstanceFactory = new LevelModelInstanceFactory();
		this.eventLevelModelInstanceFactory = new EventLevelModelInstanceFactory();

		this.analyticsSender = new AnalyticsSender(this.serverTimeModel, this.version);

		this.afkTicker = new AFKTicker();
		this.afkTicker.on(AFKTicker.EVENT_TIMEOUT, this.onAFKTickerTimeout, this);

		this.accountLifetimeTicker = new AccountLifetimeTicker(
			this.serverTimeModel,
			this.gameProfileModel,
		);

		this.autoTapLimiter = new AutoTapLimiter(
			this.app.renderer.plugins.interaction,
		);

		this.env = this.cheatModel.getEnv() || SERVER;

		const assetsServer = this.cheatModel.getAssetsServer();
		const assetServiceUrl = assetsServer == null || assetsServer === ''
			? NetworkUtils.getAssetServiceUrl()
			: assetsServer;

		this.networkRequestTransport = new NetworkRequestTransport(
			NetworkUtils.getServerUrl(this.env),
			assetServiceUrl,
			this.env,
			this.serverTimeModel,
			this.isNutaku,
			this.version,
		);
		this.networkRequestTransport.on(NetworkRequestTransport.EVENT_RESPONSE_ERROR, this.onNetworkResponseError, this);
		this.networkRequestTransport.on(NetworkRequestTransport.EVENT_REQUEST_SENT, this.onNetworkRequestSent, this);

		this.networkRequestSender = new NetworkRequestSender();
		this.networkRequestSender.setTransport(this.networkRequestTransport);

		this.localizationLoader = new LocalizationLoader(
			this.networkRequestSender,
		);
		this.localizationLoader.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			this.networkRequestTransport.addAction(action);
		});

		this.originalUrl = NetworkUtils.getCurrentURL();

		if (!this.isNutaku) {
			const urlParameters = NetworkUtils.getURLParameters();
			window.history.pushState(null, null, `/${urlParameters}`);
		}

		this.networkLoader = this.createNetworkLoader();
		this.assetUrlsLoader = new AssetUrlsLoader(this.networkRequestSender);

		if (this.isFooterVisible) {
			this.showFooter();
		}

		this.loadPreloaderAssets();
	}

	private loadPreloaderAssets(): void {
		const bootAssetsLoader = new PIXI.loaders.Loader(
			`${CDN}/assets`,
		);
		bootAssetsLoader.defaultQueryString = `v=${this.version}`;

		// Preloader resources
		bootAssetsLoader
			.add('preloader_bar_bg', 'images/preloader/preloader_bar_bg.png')
			.add('preloader_bg', this.getPreloaderBg())
			.add('preloader_bar', 'images/preloader/preloader_bar.png')
			.add('wendyOne', 'fonts/wendyOne.xml')
			.add('wendyOneShadowBold', 'fonts/wendyOneShadowBold.xml')
			.add('preloader_b_solid', 'images/preloader/preloader_b_solid.png')
			.add('preloader_button_gold', 'images/preloader/preloader_button_gold.png')
			.add('preloader_button_green', 'images/preloader/preloader_button_green.png')
			.add('preloader_error_icon_main', this.getPreloaderErrorIcon())
			.add('preloader_u_window_bg_1', 'images/preloader/preloader_u_window_bg_1.png')
			.add('preloader_u_window_bg_2', 'images/preloader/preloader_u_window_bg_2.png')

			.on('complete', () => {
				bootAssetsLoader.destroy();
				this.onBootAssetsLoaded();
			})

			.use((resource: PIXI.loaders.Resource, next: () => void) => {
				AssetsStorage.addResource(resource.name, resource);
				next();
			})

			.load();
	}

	private onBootAssetsLoaded(): void {
		this.preloaderBg = new PIXI.Sprite(AssetsStorage.getResource('preloader_bg').texture);
		this.setPreloaderLogoVisible(true);
		this.app.stage.addChild(this.preloaderBg);

		this.networkLoader.once(NetworkProfileLoader.EVENT_PROFILE_LOADED, (configsData) => {
			this.assetUrlsLoader.loadUrls();

			this.assetUrlsLoader.once(AssetUrlsLoader.EVENT_ASSET_URLS_LOADED, () => {
				this.onNetworkProfileLoaded(configsData);
			});
		});
		this.networkLoader.loadProfile();
	}

	private createNetworkLoader(): NetworkProfileLoader {
		let loader: NetworkProfileLoader;

		if (this.isNutaku) {
			loader = new NutakuNetworkProfileLoader(
				this.networkRequestSender,
				this.networkRequestTransport,
				this.gameProfileModel,
				this.serverTimeModel,
				this.savesConfig,
				this.localizationLoader,
				this.originalUrl,
			);
		} else {
			loader = new NetworkProfileLoader(
				this.networkRequestSender,
				this.networkRequestTransport,
				this.gameProfileModel,
				this.serverTimeModel,
				this.savesConfig,
				this.localizationLoader,
				this.originalUrl,
			);
		}
		return loader;
	}

	private setPreloaderLogoVisible(value: boolean): void {
		this.preloaderBg.visible = this.cheatModel.isPreloaderLogoEnabled() && value;
	}

	private onNetworkProfileLoaded(configsData: Record<string, unknown>): void {
		this.levelConfigsParser.parseConfigs(configsData);

		const constantsConfig = this.levelConfigsParser.getConstantsConfig();

		this.afkTicker.init(
			constantsConfig.getSessionAfkTime(),
		);

		this.accountLifetimeTicker.init(
			this.savesConfig.getCreatedOn(),
		);

		this.networkRequestTransport.setActionsEmitterTimeoutTime(
			constantsConfig.getActionsTickerTimeoutTime(),
		);

		this.autoTapLimiter.init(
			constantsConfig.getMinTapDelay(),
		);

		const setAnalyticsGameProfileData = (): void => {
			this.analyticsSender.setGameProfileData(
				this.networkRequestSender.getPlayerId(),
				this.networkRequestSender.getSessionId(),
				DeviceUtils.getDeviceId(),
				this.isNutaku ? AnalyticsPlatform.NUTAKU : AnalyticsPlatform.SITE,
				this.savesConfig.getUtmParams(),
			);
		};
		if (this.cheatModel.isAnalyticsEnabled()) {
			setAnalyticsGameProfileData();
			this.analyticsSender.init(
				this.networkRequestTransport.getServerUrl(),
				constantsConfig.isAnalyticsClickhouseEnabled(),
				constantsConfig.isAnalyticsXOffersEnabled(),
				constantsConfig.isAnalyticsPixelEnabled(),
				constantsConfig.isAnalyticsTrafficStarsEnabled(),
				constantsConfig.isAnalyticsPropellerAdsEnabled(),
				constantsConfig.isAnalyticsClickaduEnabled(),
				constantsConfig.getAnalyticsClickhouseSessionPingInterval(),
				constantsConfig.getAnalyticsClickhouseEventProgressInterval(),
			);
		}
		this.networkLoader.on(NetworkProfileLoader.EVENT_PROFILE_LOADED, setAnalyticsGameProfileData, this);

		this.startPreloader();

		if (this.isFooterVisible) {
			this.initAdsIframe();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private showFooter(): void {
		const footer = document.querySelector('.page-footer');

		footer.classList.add('is-visible');
		document.querySelector('#game-container').classList.add('has-page-footer');
		document.body.classList.add('body-background');

		// Add footer text element
		const p = document.createElement('p');
		p.classList.add('footer-text');

		const baseText = 'Brawea LTD Themistokli Dervi, 41 HAWAII TOWER, Flat/Office 208, 1066, Nicosia, Cyprus All rights reserved. 2022';
		const links = legalLinks.map((linkData) => `<a class="footer-link" href="${linkData.link}" target="_blank">${linkData.text}</a>`).join(', ');

		p.innerHTML = `${baseText}. ${links}`;

		footer.appendChild(p);
	}

	private initAdsIframe(): void {
		const playerId = this.networkRequestSender.getPlayerId();
		const iframe = document.querySelector('.page-footer iframe');

		const url = new URL(iframe.getAttribute('src'));
		url.searchParams.append('xuid', playerId);

		iframe.setAttribute('src', url.toString());
	}

	private onNetworkRequestSent(): void {
		this.afkTicker.reset();
	}

	private onNetworkResponseError(url: string, error: string, errorCode?: number): void {
		const errorMsg = `${url}\n${error}`;

		if (!this.errorWindow) {
			if (MODE_DEBUG) {
				if (this.cheatModel.isErrorWindowEnabled()) {
					this.showErrorWindow(errorMsg, errorCode);
				} else {
					// eslint-disable-next-line no-console
					console.error(errorMsg);
				}
			} else {
				this.showErrorWindow(errorMsg, errorCode);
			}
		} else {
			// eslint-disable-next-line no-console
			console.error(errorMsg);
		}

		if (MODE_DEBUG) {
			try {
				TextUtils.copyToBuffer(`${localStorage.getItem(NetworkRequestHeaders.PLAYER_ID_KEY)}\n${errorMsg}`);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(errorMsg);
			}
		}
	}

	private onAFKTickerTimeout(): void {
		if (MODE_DEBUG) {
			if (this.cheatModel.isErrorWindowEnabled()) {
				this.showErrorWindow('', ServerErrorCode.ACCOUNT_NOT_FOUND_SESSION_ID);
			} else {
				// eslint-disable-next-line no-console
				console.error('AFK ticker timeout!');
			}
		} else {
			this.showErrorWindow('', ServerErrorCode.ACCOUNT_NOT_FOUND_SESSION_ID);
		}
	}

	private startPreloader(): void {
		this.levelPreloaderInstance = new LevelPreloaderInstance(
			this.app.stage,
			this.networkRequestSender,
			this.networkRequestTransport,
			this.networkLoader,
			this.gameProfileModel,
			this.cheatModel,
			this.env,
			this.savesConfig,
			this.levelConfigsParser.getConstantsConfig(),
			this.levelConfigsParser.getLevelsConfigs(),
			this.assetUrlsLoader,
			this.landingModest,
			this.isNutaku,
		);
		this.levelPreloaderInstance.once(LevelPreloaderInstance.EVENT_COMPLETED, this.onPreloaderCompleted, this);
		this.levelPreloaderInstance.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			this.networkRequestTransport.addAction(action);
		}, this);
		this.levelPreloaderInstance.on(LevelPreloaderInstance.EVENT_SHOW_CHEAT_WINDOW, (view: CheatWindowView) => {
			view.onShown();
			this.app.stage.addChild(view);
		}, this);

		this.analyticsSender
			.addSourceLevelPreloaderView(this.levelPreloaderInstance.getAnalyticSourceLevelPreloaderView())
			.addSourceGameProfileModel(new AnalyticSourceGameProfileModel(this.gameProfileModel));
	}

	private onPreloaderCompleted(): void {
		this.levelPreloaderInstance.destroy();
		this.levelPreloaderInstance = null;

		this.startInitialLevel();
	}

	private showErrorWindow(msg: string, errorCode?: number): void {
		this.errorWindow = new ErrorWindowView(
			this.savesConfig.getRedeemCode(),
			msg,
			errorCode,
		);
		this.errorWindow.once(
			ErrorWindowView.EVENT_WINDOW_CLOSED,
			() => {
				this.errorWindow = null;
			},
			this,
		);

		const supportWindowViewController = new SupportWindowViewController(this.networkRequestSender);

		this.errorWindow.on(ErrorWindowView.EVENT_BUTTON_SUPPORT_CLICK, () => {
			const supportWindow = new SupportWindowView(
				this.levelConfigsParser.getConstantsConfig().getThirdPartyLinks(),
			);

			supportWindowViewController.setSupportWindowView(supportWindow);

			supportWindow.onShown();
			this.app.stage.addChild(supportWindow);
		});

		this.errorWindow.on(ErrorWindowView.EVENT_BUTTON_RELOAD_CLICK, () => {
			if (errorCode === ServerErrorCode.OUTDATED_VERSION) {
				const urlParams = new URLSearchParams(window.location.search);

				// set arbitrary get parameter to prevent page loading from cache
				urlParams.set('v', Date.now().toString());

				const originalPathname = (new URL(this.originalUrl)).pathname;
				const newUrl = `${window.location.origin}${originalPathname}?${urlParams.toString()}`;

				NetworkUtils.setCurrentURL(newUrl);
			} else {
				window.location.reload();
			}
		});

		this.errorWindow.onShown();
		this.app.stage.addChild(this.errorWindow);
	}

	private startInitialLevel(): void {
		this.levelModelInstance = this.levelModelInstanceFactory.createLevelModelInstance(
			this.serverTimeModel,
			this.savesConfig,
			{
				configs: this.levelConfigsParser.getCharactersConfigs(),
				savesData: this.savesConfig.getCharactersSaveData(),
			},
			{
				configs: this.levelConfigsParser.getSkillsConfigs(),
				savesData: this.savesConfig.getSkillsSaveData(),
			},
			{
				configs: this.levelConfigsParser.getBusinessesConfigs(),
				savesData: this.savesConfig.getBusinessesSaveData(),
			},
			{
				configs: this.levelConfigsParser.getTotemsConfigs(),
				savesData: this.savesConfig.getTotemsSaveData(),
			},
			{
				configs: this.levelConfigsParser.getBoostsConfigs(),
				savesData: this.savesConfig.getBoostsSaveData(),
			},
			{
				configs: this.levelConfigsParser.getUpgradesConfigs(),
				savesData: this.savesConfig.getUpgradesSaveData(),
			},
			{
				configs: this.levelConfigsParser.getTimeskipsConfigs(),
				savesData: this.savesConfig.getTimeskipsSaveData(),
			},
			{
				configs: this.levelConfigsParser.getVideosConfigs(),
				savesData: this.savesConfig.getGalleryVideosSave(),
			},
			{
				configs: this.levelConfigsParser.getTutorialStepsConfigs(),
				savesData: this.savesConfig.getTutorialStepsSaveData(),
				eventSavesData: this.savesConfig.getEventTutorialStepsSaveData(),
				emitterEnabled: this.cheatModel && this.cheatModel.isTutorialEnabled(),
			},
			{
				configs: this.levelConfigsParser.getFarewellPartiesConfigs(),
				constants: this.levelConfigsParser.getConstantsConfig().getFarewellPartyValues(),
			},
			{
				configs: this.levelConfigsParser.getEipcQuestCollectionsConfigs(),
				savesData: this.savesConfig.getEpicQuestCollectionsSave(),
			},
			this.levelConfigsParser.getDialogsConfigs(),
			this.levelConfigsParser.getConstantsConfig(),
			this.levelConfigsParser.getPromotePatternsConfig(),
			this.levelConfigsParser.getBankPriceItemsConfig(),
			this.levelConfigsParser.getDailyRewardConfigs(),
			this.levelConfigsParser.getPresetsConfigs(),
			this.isNutaku,
		);

		this.levelInstance = new LevelInstance(
			this.app.stage,
			this.app.renderer.plugins.interaction,
			this.env,
			this.cheatModel,
			this.networkRequestSender,
			this.serverTimeModel,
			this.levelModelInstance,
			this.gameProfileModel,
			this.savesConfig,
			{
				configs: this.levelConfigsParser.getTutorialStepsConfigs(),
				savesData: this.savesConfig.getTutorialStepsSaveData(),
				emitterEnabled: this.cheatModel && this.cheatModel.isTutorialEnabled(),
			},
			this.levelConfigsParser.getLevelsConfigs(),
			this.levelConfigsParser.getDialogsConfigs(),
			this.levelConfigsParser.getQuestsConfigs(),
			this.levelConfigsParser.getConstantsConfig(),
			this.levelConfigsParser.getGameUIMainWindowConfig(),
			this.levelConfigsParser.getLevelBaseWindowConfig(),
			this.levelConfigsParser.getLocalizationSupportConfig(),
			this.isNutaku,
			this.assetUrlsLoader,
			this.version,
		);
		this.levelInstance.on(LevelInstance.EVENT_LOCALIZATION_CHANGE, (lang: string) => {
			this.localizationLoader.localizationChange(lang);
		}, this);
		this.levelInstance.on(LevelInstance.EVENT_GOTO_EVENT_LEVEL, () => {
			this.onGoToEventLevel();
		}, this);
		this.levelInstance.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			this.networkRequestTransport.addAction(action);
		}, this);
		this.levelInstance.on(LevelInstance.EVENT_TUTORIAL, () => {
			this.networkRequestTransport.setActionsEmitterEnabled(false);
		}, this);
		this.levelInstance.on(LevelInstance.EVENT_TUTORIAL_END, () => {
			this.networkRequestTransport.setActionsEmitterEnabled(true);
		}, this);
		this.levelInstance.once(LevelInstance.EVENT_STARTED, () => {
			this.setPreloaderLogoVisible(false);
		}, this);

		const analyticSourceLevelModels = new AnalyticSourceModels(
			this.levelModelInstance.getLevelModel(),
			this.levelModelInstance.getBankSavesModel(),
			this.levelModelInstance.getBankModel(),
			this.levelModelInstance.getTimeskipModels(),
			this.levelModelInstance.getBoostModels(),
			this.levelModelInstance.getTutorialStepModels(),
			this.levelModelInstance.getTimedQuestLinesModel(),
			this.levelModelInstance.getQuestLinesModel(),
			this.levelModelInstance.getCharacterModels(),
			this.levelModelInstance.getTotemModels(),
			this.levelModelInstance.getUpgradeModels(),
			this.levelModelInstance.getPrestigeMoneyModel(),
			this.levelModelInstance.getHardMoneyModel(),
			this.levelModelInstance.getLevelChallengeModel(),
		);
		this.analyticsSender
			.addSourceLevelModels(analyticSourceLevelModels)
			.addSourceBankViews(
				this.levelInstance.getAnalyticSourceBankViews(),
			)
			.addSourceLevelStartWindowView(
				this.levelInstance.getAnalyticSourceLevelStartWindowView(),
			)
			.addSourceFarewellPartyView(
				this.levelInstance.getAnalyticSourceFarewellPartyView(),
			);

		const constantsConfig = this.levelConfigsParser.getConstantsConfig();
		if (constantsConfig.isEventsEnabled()) {
			const sandboxOp = new SandboxOperation(
				() => {
					AssetsStorage.swapEventAssets(false);
					this.localizationLoader.localizationSwapEventReplace();
				},
				() => {
					AssetsStorage.swapEventAssets(true);
					this.localizationLoader.localizationSwapEventReplace();
				},
			);

			this.eventLevelSystem = new EventLevelSystem(
				this.networkLoader,
				this.networkRequestSender,
				this.app.stage,
				this.app.renderer.plugins.interaction,
				this.env,
				this.savesConfig,
				this.levelConfigsParser.getBankPriceItemsConfig(),
				this.levelConfigsParser.getPromotePatternsConfig(),
				this.levelConfigsParser.getLocalizationSupportConfig(),
				this.levelConfigsParser.getLevelBaseWindowConfig(),
				this.levelConfigsParser.getConstantsConfig(),
				this.eventLevelModelInstanceFactory,
				this.levelModelInstance,
				this.cheatModel,
				this.serverTimeModel,
				this.gameProfileModel,
				sandboxOp,
				this.isNutaku,
				this.assetUrlsLoader,
				this.version,
			);
			this.eventLevelSystem.on(EventLevelSystem.EVENT_LOCALIZATION_CHANGE, (lang: string) => {
				this.localizationLoader.localizationChangeFromEvent(
					lang,
					this.levelModelInstance.getEventLevelModel().getKey(),
				);
			}, this);
			this.eventLevelSystem.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction, withEventLevelId: boolean) => {
				this.networkRequestTransport.addAction(action, withEventLevelId);
			}, this);
			this.eventLevelSystem.on(EventLevelSystem.EVENT_RETURN_TO_LEVEL, () => this.switchToLevel(), this);
			this.eventLevelSystem.on(EventLevelSystem.EVENT_CLOSE, () => this.closeEventLevel(), this);

			this.levelInstance.on(LevelInstance.EVENT_LEVEL_CHANGE, () => {
				this.eventLevelSystem.tryChooseEventLevel();
			}, this);

			this.eventLevelSystem.init();

			this.analyticsSender
				.addSourceBankViews(
					this.eventLevelSystem.getAnalyticSourceBankViews(),
				)
				.addSourceFarewellPartyView(
					this.eventLevelSystem.getAnalyticsSourceFarewellPartyView(),
				);
		}

		this.levelInstance.startLevel();

		this.networkRequestTransport.startActionsTicker();
	}

	private async onGoToEventLevel(): Promise<void> {
		let eventPreloader: EventLevelPreloaderView;

		const preloadEventLevel: () => Promise<void> = () => new Promise((resolve) => {
			const mainloader = new AssetLoader(this.assetUrlsLoader);
			const subloader = new AssetLoader(this.assetUrlsLoader);
			const activatedCharacterKeys: string[] = [];

			[mainloader, subloader].forEach((loader) => loader.on('complete', () => loader.destroy()));

			this.savesConfig.getCharactersSaveData().forEach((save, key) => {
				if (save.activated) {
					activatedCharacterKeys.push(key);
				}
			});

			eventPreloader = new EventLevelPreloaderView(
				mainloader,
				subloader,
				activatedCharacterKeys,
				this.levelModelInstance.getEventLevelModel().getKey(),
				this.cheatModel.isPreloaderLogoEnabled(),
			);

			eventPreloader.once(EventLevelPreloaderView.EVENT_COMPLETED, () => {
				resolve();
			});

			this.app.stage.addChild(eventPreloader);

			eventPreloader.start();
		});

		if (this.eventLevelSystem?.isStarted()) {
			this.switchToEventLevel();
		} else {
			await preloadEventLevel();
			await this.swapAssets(true);
			await this.startEventLevel();
			eventPreloader?.destroy({ children: true });
		}
	}

	private switchToLevel(): Promise<void> {
		this.setPreloaderLogoVisible(true);
		this.eventLevelSystem.setEnabled(false);

		const swapAssetsPromise = this.swapAssets(false);
		const switchToGamePromise = swapAssetsPromise.then(() => {
			this.networkRequestTransport.setEventLevelId(undefined);

			this.levelInstance.setEnabled(true);
			this.setPreloaderLogoVisible(false);
			this.analyticsSender.stopTrackEventRankProgress();
		});
		return switchToGamePromise;
	}

	private switchToEventLevel(): Promise<void> {
		this.setPreloaderLogoVisible(true);
		this.levelInstance.setEnabled(false);

		const swapAssetsPromise = this.swapAssets(true);
		const switchToEventLevelPromise = swapAssetsPromise.then(() => {
			const eventLevelModel = this.levelModelInstance.getEventLevelModel();
			this.networkRequestTransport.setEventLevelId(eventLevelModel.getKey());

			this.eventLevelSystem.setEnabled(true);
			this.setPreloaderLogoVisible(false);
			this.analyticsSender.startTrackEventRankProgress();
		});
		return switchToEventLevelPromise;
	}

	private startEventLevel(): Promise<void> {
		return new Promise((resolve) => {
			this.levelInstance.setEnabled(false);

			const eventLevelModel = this.levelModelInstance.getEventLevelModel();
			this.networkRequestTransport.setEventLevelId(eventLevelModel.getKey());

			this.eventLevelSystem.once(EventLevelSystem.EVENT_STARTED, () => {
				const analyticSourceEventLevelModels = new AnalyticSourceModels(
					undefined,
					this.eventLevelModelInstanceFactory
						.getEventLevelModelInstance().getBankSavesModel(),
					this.eventLevelModelInstanceFactory
						.getEventLevelModelInstance().getBankModel(),
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					eventLevelModel,
				);
				this.analyticsSender
					.addSourceEventLevelModels(analyticSourceEventLevelModels);

				if (!this.eventLevelSystem.isEventLevelEverEntered()) {
					this.networkRequestTransport.addAction(new EventLevelFirstEnterAction(), true);

					this.analyticsSender.sendEventClickhouseEventFirstEnter();
				}
				this.analyticsSender.startTrackEventRankProgress();

				resolve();
			}, this);
			this.eventLevelSystem.startEventLevel();
		});
	}

	private closeEventLevel(): void {
		if (this.eventLevelSystem.isEnabled()) {
			this.switchToLevel();
		}

		this.eventLevelSystem.closeEventLevel();
	}

	private swapAssets(
		swapToEventAssets: boolean,
	): Promise<void> {
		AssetsStorage.swapEventAssets(swapToEventAssets);

		const eventLevelModel = this.levelModelInstance.getEventLevelModel();
		const swapLocalizationPromise = this.localizationLoader.localizationLoadEvent(
			swapToEventAssets,
			eventLevelModel.getKey(),
		);
		return swapLocalizationPromise;
	}

	private getPreloaderBg(): string {
		let preloader: string;
		if (this.landingModest) {
			preloader = 'images/preloader/preloader_bg_no_adult.jpg';
		} else if (NUTAKU) {
			preloader = 'images/preloader/preloader_bg_nutaku.jpg';
		} else {
			preloader = 'images/preloader/preloader_bg.jpg';
		}
		return preloader;
	}

	private getPreloaderErrorIcon(): string {
		let icon: string;
		if (this.landingModest || NUTAKU) {
			icon = 'images/preloader/preloader_error_icon_no_adult.png';
		} else {
			icon = 'images/preloader/preloader_error_icon_main.png';
		}
		return icon;
	}
}

function startGame(): void {
	// eslint-disable-next-line no-new
	new Application();
}

if (NUTAKU) {
	if (DeviceUtils.isAndroid() || DeviceUtils.isIos()) {
		if (window.self === window.top) {
			window.onload = startGame;
		} else {
			// if window is in nutaku's iframe
			const link = document.getElementById('redirect-link') as HTMLAnchorElement;
			link.href = `${CDN}/index.nutaku.html${window.location.search}`;
			link.style.display = 'flex';
		}
	} else {
		window.gadgets.util.registerOnLoadHandler(startGame);
	}
} else {
	window.onload = startGame;
}
