import { BusinessBaseViewSetter } from '@interfaces/ViewSetters';
import { TutorialStepUnlockTypeBusinessAcquiredModel } from './TutorialStepUnlockTypeBusinessAcquiredModel';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';

export class TutorialStepUnlockTypeBusinessAcquiredViewSetter implements BusinessBaseViewSetter {
	private unlockTypeModels: TutorialStepUnlockTypeBusinessAcquiredModel[];

	public setModels(unlockTypeModels: TutorialStepUnlockTypeBusinessAcquiredModel[]): void {
		this.unlockTypeModels = unlockTypeModels;
	}

	public setBusinessBaseView(view: BusinessBaseView): void {
		this.unlockTypeModels
			.filter(unlockTypeModel => unlockTypeModel.getBusinessKey() === view.getKey())
			.forEach(unlockTypeModel => unlockTypeModel.setBusinessBaseView(view));
	}
}
