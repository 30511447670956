import { AssetsStorage } from '@main/AssetsStorage';
import { RankingPlayerInfoView } from './RankingPlayerInfoView';
import { RankingPlayerIncomeInfoView } from './RankingPlayerIncomeInfoView';
import { BLEND_MODES } from 'pixi.js';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { AbstractReward } from '@interfaces/AbstractReward';
import { SandboxOperation } from '@src/utils/SandboxOperation';

export class RankingListItemView extends PIXI.Container {
	private static readonly CONTENT_MARGIN_X: number = 75;

	private static positionLayoutHorizontal(
		targets: PIXI.Container[],
		marginX: number,
	): void {
		let j = 1;
		for (let i = 0; i < targets.length; i++) {
			const sprite = targets[i];
			sprite.x += marginX * j - marginX;
			j += 1;
		}
	}

	private readonly rankingPlayerInfo: RankingPlayerInfoView;
	private readonly rankingPlayerIncomeInfo: RankingPlayerIncomeInfoView;
	private readonly rankedBg: PIXI.mesh.NineSlicePlane;
	private rewardsBlock: PIXI.Container[];

	constructor(
		rewards: AbstractReward[],
		cardMiniFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
	) {
		super();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		this.rankedBg = new PIXI.mesh.NineSlicePlane(uiAtlas['simple_ranking_panel_bg'], 3, 3, 3, 3);
		this.rankedBg.width = 1026;
		this.rankedBg.height = 104;
		this.rankedBg.pivot.set(this.rankedBg.width / 2, this.rankedBg.height / 2);

		const bgDecor = new PIXI.Sprite(uiAtlas['ranking_panel_decor']);
		bgDecor.x = -290;
		bgDecor.scale.set(2);
		bgDecor.alpha = 0.6;
		bgDecor.blendMode = BLEND_MODES.SCREEN;

		this.rankingPlayerInfo = new RankingPlayerInfoView();
		this.rankingPlayerInfo.position.set(-403, 0);
		this.rankingPlayerInfo.scale.set(0.87);

		this.rankingPlayerIncomeInfo = new RankingPlayerIncomeInfoView();
		this.rankingPlayerIncomeInfo.position.set(358, 0);
		this.rankingPlayerIncomeInfo.scale.set(0.8);

		this.addChild(
			this.rankedBg,
			bgDecor,
			this.rankingPlayerInfo as PIXI.DisplayObject,
			this.rankingPlayerIncomeInfo,
		);

		runInEpisodeAssetsSandbox.start(() => {
			this.rewardsBlock = rewards.map(reward => cardMiniFactory.createRewardCardByModel(
				reward,
				true,
				1,
				1,
				CardMiniViewFactory.createStickerNew(),
			));

			if (this.rewardsBlock.length > 0) {
				this.rewardsBlock.forEach(blockItem => {
					blockItem.x = -110;
					blockItem.scale.set(0.45, 0.45);
				});
				RankingListItemView.positionLayoutHorizontal(this.rewardsBlock, RankingListItemView.CONTENT_MARGIN_X);

				this.addChild(
					...this.rewardsBlock,
				);
			}
		});
	}

	public setUsername(value: string): void {
		this.rankingPlayerInfo.setUsername(value);
	}

	public setTextIncome(value: string): void {
		this.rankingPlayerIncomeInfo.setTextIncome(value);
	}

	public setLeaguePlace(place: number): void {
		// eslint-disable-next-line default-case
		switch (place) {
			case 1: {
				this.rankedBg.texture = AssetsStorage.getAtlas('uiAtlas')['gold_ranking_panel_bg'];
				break;
			}
			case 2: {
				this.rankedBg.texture = AssetsStorage.getAtlas('uiAtlas')['silver_ranking_panel_bg'];
				break;
			}
			case 3: {
				this.rankedBg.texture = AssetsStorage.getAtlas('uiAtlas')['bronze_ranking_panel_bg'];
				break;
			}
		}
		this.rankingPlayerInfo.setLeaguePlace(place);
	}

	public setTextLeaguePlace(value: string): void {
		this.rankingPlayerInfo.setTextLeaguePlace(value);
	}
}
