import { TotemModel } from '@models/TotemModel';
import { TotemCardView } from '@views/ui/cards/TotemCardView';
import { AbstractCollectionItemView } from '@views/windows/collection/AbstractCollectionItemView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { PrepartyTotemLockCardView } from '@views/windows/farewellParty/preparty/PrepartyTotemLockCardView';
import LocalizationStorage from '@main/LocalizationStorage';

export class PrepartyTotemGalleryItemView extends AbstractCollectionItemView {
	public static readonly EVENT_BUTTON_FIND_CLICK: symbol = Symbol();

	private readonly model: TotemModel;

	protected openView: TotemCardView;
	private readonly lockView: PrepartyTotemLockCardView;
	private readonly currentLevel: number;

	private buttonGetMore: ButtonBaseView;
	private bgFiller: PIXI.Graphics;
	private buttonFind: ButtonBaseView;
	private localizationStorage: LocalizationStorage;

	constructor(
		model: TotemModel,
		currentLevel: number,
	) {
		super();

		this.currentLevel = currentLevel;
		this.localizationStorage = LocalizationStorage.getInstance();

		this.model = model;

		this.model.on(TotemModel.EVENT_PROMOTED, this.onModelUpdate, this);
		this.model.on(TotemModel.EVENT_CARDS_ADDED, this.onModelUpdate, this);

		if (this.model.isOpened()) {
			this.onOpened();
		} else {
			this.model.once(TotemModel.EVENT_OPENED, this.onOpened, this);

			this.lockView = new PrepartyTotemLockCardView(model, currentLevel);
			this.lockView.scale.set(0.43);
			this.addChild(this.lockView);

			this.onModelUpdate();
		}
	}

	protected showOpened(): void {
		this.openView = new TotemCardView(
			this.model,
			true,
			true,
		);
		this.openView.scale.set(0.43);
		this.openView.on(TotemCardView.EVENT_CLICK, this.onOpenCardClick, this);
		this.addChildAt(this.openView, 0);

		if (this.lockView) {
			this.removeChild(this.lockView);
			this.lockView.destroy({ children: true });
		}

		this.onModelUpdate();
	}

	private createButton(text: string, clickHandler: any): ButtonBaseView {
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		bg.width = 234;
		bg.height = 54;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const button = new ButtonBaseView(bg);
		button.y = 169;

		const label = new SizeableBitmapText(
			text,
			Math.floor(bg.width * 0.95),
			{ font: '28px wendyOneShadowBoldCaps', align: 'center' },
		);
		label.anchor = 0.5;
		label.y = -3;
		button.addChild(label);

		button.on(ButtonBaseView.EVENT_CLICK, clickHandler, this);

		this.addChild(button);

		return button;
	}

	private createButtonFind(): void {
		const text = this.localizationStorage.getLocalizedString('#preparty_button_find');
		this.buttonFind = this.createButton(
			text,
			() => this.emit(PrepartyTotemGalleryItemView.EVENT_BUTTON_FIND_CLICK, this.model.getKey()),
		);
	}

	private showButtonFind(): void {
		if (!this.buttonFind) {
			this.createButtonFind();
		}
		this.buttonFind.visible = true;
	}

	private hideButtonFind(): void {
		if (!this.buttonFind) return;
		this.buttonFind.visible = false;
	}

	private hideButtonGetMore(): void {
		if (!this.buttonGetMore) return;
		this.buttonGetMore.visible = false;
	}

	private createBgFiller(): void {
		// Fill space between button and card to avoid scale tween glitches
		this.calculateBounds();
		const bounds = this.getLocalBounds();

		this.bgFiller = new PIXI.Graphics()
			.beginFill(0xFFFFFF, 0)
			.drawRect(-bounds.width / 2, bounds.y + 40, bounds.width, bounds.height)
			.endFill();

		this.addChildAt(this.bgFiller, 0);
	}

	private showBgFiller(): void {
		if (!this.bgFiller) {
			this.createBgFiller();
		}
		this.bgFiller.visible = true;
	}

	private hideBgFiller(): void {
		if (!this.bgFiller) return;
		this.bgFiller.visible = false;
	}

	private onModelUpdate(): void {
		this.hideBgFiller();
		this.hideButtonGetMore();
		this.hideButtonFind();

		const isOpened = this.model.isOpened();
		let isAvailable = false;

		if (this.model.isUnlockableByMinLevel()) {
			isAvailable = this.currentLevel >= this.model.getUnlockLevel();
		}

		if (!isOpened && !isAvailable) return;

		if (!isOpened) {
			this.showBgFiller();
			this.showButtonFind();
			return;
		}

		const isPromoteAvailable = this.model.isEnoughCardsForPromote();

		if (!isPromoteAvailable) {
			this.showBgFiller();
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(TotemModel.EVENT_OPENED, this.onOpened, this, true);
		this.model.off(TotemModel.EVENT_PROMOTED, this.onModelUpdate, this);
		this.model.off(TotemModel.EVENT_CARDS_ADDED, this.onModelUpdate, this);

		super.destroy(options);
	}
}
