/* eslint-disable camelcase */
import { BaseAction } from './BaseAction';

export class TotemPromoteAction extends BaseAction {
	constructor(
		totemId: string,
		cost: number,
		cardsCost: number,
		totemLevel: number,
		currentPrestige: number,
		currentCardCount: number,
	) {
		super('TotemPromote');

		this.data.totem_id = totemId;
		this.data.card_count = cardsCost;
		const resourcesSpent = Object.create(null);
		resourcesSpent.prestige_money = cost;
		this.data.resources_spent = resourcesSpent;

		const resources = Object.create(null);
		resources.prestige_money = currentPrestige;
		this.sync.resources = resources;

		const totems = Object.create(null);
		totems[totemId] = Object.create(null);
		totems[totemId].cards = currentCardCount;
		totems[totemId].level = totemLevel;
		this.sync.totems = totems;
	}
}
