import { CollectionWithProgressBaseView } from '@views/windows/collection/CollectionWithProgressBaseView';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { UpgradeCollectionsCardView } from './UpgradeCollectionsCardView';
import { UpgradeModel } from '@models/UpgradeModel';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';
import { HintDataPosition, HintTypes } from '@src/types/HintTypes';

export class UpgradeCollectionDefaultView extends CollectionWithProgressBaseView {
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();

	private upgradeKeyCardMap: Map<string, UpgradeCollectionsCardView>;

	constructor(
		upgradeModels: UpgradeModel[],
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		questCollectCardsModel?: EpicQuestCollectionsModel,
	) {
		super(
			prestigeMoneyModel,
			hardMoneyModel,
			{ begin: 0, end: 200 },
			questCollectCardsModel,
			'#collection_window_upgrades_reward_progress',
		);

		this.upgradeKeyCardMap = new Map();
		this.scrollView.position.set(540, 62);
		this.contentContainer.name = 'foo';

		let i = 0;
		upgradeModels.forEach((model) => {
			const x = 312 * (i % 4) + 150;
			const y = 473 * (Math.floor(i / 4)) + 283;
			const card = new UpgradeCollectionsCardView(
				model,
			);
			card.position.set(x, y);
			card.on(UpgradeCollectionsCardView.EVENT_CLICK, (data: PromotableClickData) => {
				this.emit(UpgradeCollectionDefaultView.EVENT_CARD_CLICK, data);
			}, this);
			this.contentContainer.addChild(card);

			this.upgradeKeyCardMap.set(model.getKey(), card);
			i += 1;
		});

		const hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
		hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(UpgradeCollectionDefaultView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, data);
			},
		);
		hardMoneyPanel.position.set(1630, 40);

		const prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel);
		prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(UpgradeCollectionDefaultView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, data);
			},
		);
		prestigeMoneyPanel.position.set(1370, 40);

		prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(UpgradeCollectionDefaultView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);
		hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(UpgradeCollectionDefaultView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);

		this.addChild(
			hardMoneyPanel,
			prestigeMoneyPanel as PIXI.DisplayObject,
		);

		this.scrollView.updateBounds();
	}

	public scrollTo(key: string): void {
		const card = this.upgradeKeyCardMap.get(key);
		this.scrollView.jumpTo(-card.y);
	}

	public getOpenedItemContainer(key: string): PIXI.Container | undefined {
		return this.upgradeKeyCardMap.get(key).getOpenedItemContainer();
	}
}
