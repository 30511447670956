import { CardRarities } from '@src/types/CardRarities';
import { UpgradeModel } from '@models/UpgradeModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { LevelStartCardBaseView } from '@views/windows/levelStartWindow/LevelStartCardBaseView';

export class LevelStartUpgradeCardView extends LevelStartCardBaseView<UpgradeModel> {
	constructor(
		model: UpgradeModel,
	) {
		super(
			model,
		);

		const upgradeKey = model.getKey();

		const cardRarity = model.getCardRarity();
		const rarityColor = CardRarities.getRarityColor(cardRarity);


		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('upgradeIconAtlas')[`big_${upgradeKey}`]);
		this.fadeContainer.addChildAt(icon, 0);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`upgrade_${model.getCardRarity()}_card_bg`]);
		bg.y = -42;
		this.fadeContainer.addChildAt(bg, 0);

		const cardBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['u_rarity_card_bg']);
		cardBg.tint = rarityColor;
		cardBg.position.set(0, -42);

		this.fadeContainer.addChildAt(cardBg, 0);
	}
}
