import { CardRarity } from '@src/types/CardRarities';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter, EmitterConfig } from 'pixi-particles';
import { SummonParticleConfig } from '@views/windows/collection/summon/SummonParticleConfig';
import { SummonFlashAnimationView } from '@views/windows/collection/summon/SummonFlashAnimationView';
import { SoundController } from '@src/main/SoundController';

export class RewardResultCardAnimations {
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private animationEmitters: Emitter[];
	protected soundController: SoundController;

	constructor() {
		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.animationEmitters = [];
		this.soundController = SoundController.getInstance();
	}

	public clear(): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.animationEmitters = [];
		this.tweenGroup.removeAll();
	}

	public animateCard(cardContainer: PIXI.Container, rarity: string, afterCardOpen: () => void, noAnimation?: boolean, speedUpValue?: number): void {
		switch (rarity) {
			case CardRarity.LEGENDARY:
				new TWEEN.Tween(cardContainer.getChildAt(0), this.tweenGroup)
					.to({ alpha: 0.75 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.start();
				new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
					.to({ x: 1.1, y: 1.1 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.chain(
						new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
							.to({ x: 1.06, y: 1.06 }, 600 / speedUpValue)
							.easing(TWEEN.Easing.Linear.None),
					)
					.start();
				this.playLegendaryAnimation(cardContainer, afterCardOpen, noAnimation, speedUpValue);
				break;
			case CardRarity.EPIC:
				new TWEEN.Tween(cardContainer.getChildAt(0), this.tweenGroup)
					.to({ alpha: 0.65 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.start();
				new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
					.to({ x: 1.07, y: 1.07 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.chain(
						new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
							.to({ x: 1.04, y: 1.04 }, 600 / speedUpValue)
							.easing(TWEEN.Easing.Linear.None),
					)
					.start();
				this.playEpicAnimation(cardContainer, afterCardOpen, noAnimation, speedUpValue);
				break;
			case CardRarity.RARE:
				new TWEEN.Tween(cardContainer.getChildAt(0), this.tweenGroup)
					.to({ alpha: 0.5 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.start();
				new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
					.to({ x: 1.06, y: 1.06 }, 600 / speedUpValue)
					.easing(TWEEN.Easing.Linear.None)
					.chain(
						new TWEEN.Tween(cardContainer.getChildAt(0).scale, this.tweenGroup)
							.to({ x: 1.02, y: 1.02 }, 600 / speedUpValue)
							.easing(TWEEN.Easing.Linear.None),
					)
					.start();
				this.playRareAnimation(cardContainer, afterCardOpen, noAnimation, speedUpValue);
				break;
			default:
				this.playCommonAnimation(cardContainer, afterCardOpen, noAnimation);
				break;
		}
	}

	private playCommonAnimation(cardContainer: PIXI.Container, afterCardOpen: () => void, noAnimation: boolean): void {
		if (noAnimation) {
			this.flipCard(cardContainer, CardRarity.COMMON, afterCardOpen, true);
		} else {
			const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

			const emitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['summon_flare1']).texture],
				SummonParticleConfig.getFlare1(),
			);
			this.animationEmitters.push(emitter);
			emitter.playOnceAndDestroy();

			setTimeout(() => {
				this.flipCard(cardContainer, CardRarity.COMMON, afterCardOpen);
			}, 1);
		}
	}

	private playRareAnimation(cardContainer: PIXI.Container, afterCardOpen: () => void, noAnimation: boolean, speedUpValue?: number): void {
		if (noAnimation) {
			this.flipCard(cardContainer, CardRarity.RARE, afterCardOpen, true);
		} else {
			const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

			const glowEmitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['summon_card_glow1']).texture],
				SummonParticleConfig.getGlow1('#5ee2fd'),
			);
			this.animationEmitters.push(glowEmitter);
			glowEmitter.playOnceAndDestroy();

			new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
				.to({ x: 0.8, y: 0.8 }, 300 / speedUpValue)
				.easing(TWEEN.Easing.Linear.None)
				.delay(100)
				.chain(
					new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
						.to({ x: 1, y: 1 }, 200 / speedUpValue)
						.easing(TWEEN.Easing.Quintic.Out),
				)
				.start();

			setTimeout(() => {
				const flareEmitter = new Emitter(
					cardContainer,
					[new PIXI.Sprite(fxAtlas['summon_flare1']).texture],
					SummonParticleConfig.getFlare1('#5ee2fd'),
				);
				this.animationEmitters.push(flareEmitter);
				flareEmitter.playOnceAndDestroy();

				const flash = new SummonFlashAnimationView(2, 0x5ee2fd, 1.6);
				cardContainer.addChild(flash);
				flash.start();
			}, 500);

			setTimeout(() => {
				this.flipCard(cardContainer, CardRarity.RARE, afterCardOpen);
			}, 600);
		}
	}

	private playEpicAnimation(cardContainer: PIXI.Container, afterCardOpen: () => void, noAnimation: boolean, speedUpValue?: number): void {
		if (noAnimation) {
			this.flipCard(cardContainer, CardRarity.EPIC, afterCardOpen, true);
		} else {
			const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

			const glowEmitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['summon_card_glow2']).texture],
				SummonParticleConfig.getGlow2('#ec72d7'),
			);
			this.animationEmitters.push(glowEmitter);
			glowEmitter.playOnceAndDestroy();

			new TWEEN.Tween(cardContainer, this.tweenGroup)
				.to({ rotation: -0.05 }, 40)
				.easing(TWEEN.Easing.Linear.None)
				.chain(
					new TWEEN.Tween(cardContainer, this.tweenGroup)
						.to({ rotation: 0.05 }, 80)
						.easing(TWEEN.Easing.Linear.None)
						.repeat(12)
						.yoyo(true)
						.chain(
							new TWEEN.Tween(cardContainer, this.tweenGroup)
								.to({ rotation: 0 }, 40)
								.easing(TWEEN.Easing.Linear.None)
								.onComplete(() => {
									for (let i = 0; i < 2; i++) {
										const flash1 = new SummonFlashAnimationView(2, 0xec72d7, 1.5, 500);
										flash1.alpha = 3;
										cardContainer.addChild(flash1);
										flash1.start();
									}
									for (let i = 0; i < 2; i++) {
										const flash1 = new SummonFlashAnimationView(3, 0xec72d7, 2, 400);
										flash1.alpha = 3;
										cardContainer.addChild(flash1);
										flash1.start();
									}
								}),
						),
				)
				.start();

			setTimeout(() => {
				this.flipCard(cardContainer, CardRarity.EPIC, () => {
					afterCardOpen();
					this.playFlareAfterOpen(cardContainer, '#ffffff');
				});

				setTimeout(() => {
					const glowEmitter2 = new Emitter(
						cardContainer,
						[new PIXI.Sprite(fxAtlas['summon_flash1']).texture],
						SummonParticleConfig.getGlow3('#ec72d7'),
					);
					this.animationEmitters.push(glowEmitter2);
					glowEmitter2.playOnceAndDestroy();

					const flashPos = Math.floor(Math.random() * 200 - 100);
					for (let i = 0; i < 3; i++) {
						const flash = new SummonFlashAnimationView(4, 0xec72d7, 1, 300);
						flash.alpha = 3;
						flash.position.set(flashPos, flashPos);
						cardContainer.addChild(flash);
						flash.start();
					}
				}, 700);
			}, 1250);
		}
	}

	private playLegendaryAnimation(cardContainer: PIXI.Container, afterCardOpen: () => void, noAnimation: boolean, speedUpValue?: number): void {
		if (noAnimation) {
			this.flipCard(cardContainer, CardRarity.LEGENDARY, afterCardOpen, true);
		} else {
			const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

			const energyEmitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['summon_flare1']).texture],
				SummonParticleConfig.getEnergyFlare1('#f8ba58'),
			);
			const energyStarEmitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['star']).texture],
				SummonParticleConfig.getEnergyFlare1('#ffffff'),
			);
			const ringsEmitter = new Emitter(
				cardContainer,
				[new PIXI.Sprite(fxAtlas['summon_flash2']).texture],
				SummonParticleConfig.getRings('#f8ba58'),
			);
			this.animationEmitters.push(
				energyEmitter,
				energyStarEmitter,
				ringsEmitter,
			);
			energyEmitter.playOnceAndDestroy();
			energyStarEmitter.playOnceAndDestroy();
			ringsEmitter.playOnceAndDestroy();

			new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
				.to({ x: 1.03, y: 1.03 }, 120)
				.easing(TWEEN.Easing.Cubic.Out)
				.chain(
					new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
						.to({ x: 0.97, y: 0.97 }, 240)
						.easing(TWEEN.Easing.Cubic.InOut)
						.repeat(4)
						.yoyo(true)
						.chain(
							new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
								.to({ x: 0.97, y: 0.97 }, 120)
								.easing(TWEEN.Easing.Cubic.Out)
								.chain(
									new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
										.to({ x: 1.2, y: 1.2 }, 350)
										.easing(TWEEN.Easing.Cubic.Out)
										.chain(
											new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
												.to({ x: 1, y: 1 }, 150)
												.delay(100)
												.easing(TWEEN.Easing.Cubic.In),
										),
								),
						),
				)
				.start();

			setTimeout(() => {
				this.flipCard(cardContainer, CardRarity.LEGENDARY, () => {
					this.playFlareAfterOpen(cardContainer, '#ffffff');
				});

				setTimeout(() => {
					const glowEmitter = new Emitter(
						cardContainer,
						[new PIXI.Sprite(fxAtlas['summon_card_glow4']).texture],
						SummonParticleConfig.getGlow4('#ffffff'),
					);
					this.animationEmitters.push(glowEmitter);
					glowEmitter.playOnceAndDestroy();

					this.playFrameGlow(cardContainer, afterCardOpen);
				}, 600);

				for (let i = 0; i < 2; i++) {
					const flash1 = new SummonFlashAnimationView(3, 0xec72d7, 2, 400);
					flash1.alpha = 3;
					cardContainer.addChild(flash1);
					flash1.start();
				}
				for (let i = 0; i < 1; i++) {
					const flash2 = new SummonFlashAnimationView(5, 0xffffff, 2, 400);
					flash2.alpha = 1;
					cardContainer.addChild(flash2);
					flash2.start();
				}
			}, 2100);
		}
	}

	private flipCard(cardContainer: PIXI.Container, rarity: CardRarity, afterCardOpen: () => void, immediately?: boolean): void {
		let speed: number;
		if (immediately) {
			speed = 1;
		} else {
			speed = rarity === CardRarity.LEGENDARY || rarity === CardRarity.EPIC ? 200 : 180;
		}
		new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
			.to({ x: 0 }, speed)
			.easing(TWEEN.Easing.Quintic.In)
			.onComplete(() => {
				cardContainer.getChildAt(1).visible = true;
				cardContainer.getChildAt(2).visible = false;
				new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
					.to({ x: 1 }, speed / 2)
					.easing(TWEEN.Easing.Linear.None)
					.onComplete(() => afterCardOpen())
					.start();
			})
			.start();
		SoundController.getInstance().playCoupCard();
	}

	// eslint-disable-next-line class-methods-use-this
	private playFrameGlow(cardContainer: PIXI.Container, onFinish: () => void): void {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const cardBack = cardContainer.getChildAt(2) as PIXI.Sprite;
		const frameGlowContainer = new PIXI.Container();
		const mask = new PIXI.Graphics()
			.beginFill(0x000000)
			.moveTo(-cardBack.width / 2, -cardBack.height / 2)
			.lineTo(cardBack.width / 2, -cardBack.height / 2)
			.lineTo(cardBack.width / 2, cardBack.height / 2)
			.lineTo(-cardBack.width / 2, cardBack.height / 2)
			.closePath()
			.endFill();
		frameGlowContainer.addChild(mask);
		frameGlowContainer.mask = mask;

		cardContainer.addChild(frameGlowContainer);

		const texture = fxAtlas['summon_frame_glow'];

		const sprite = new PIXI.Sprite(texture);
		sprite.scale.set(0.8);
		sprite.tint = 0xf8ba58;
		sprite.rotation = 1.5708;
		sprite.blendMode = PIXI.BLEND_MODES.ADD;
		sprite.position.set(-cardBack.width / 2, cardBack.height / 2);
		frameGlowContainer.addChild(sprite);

		const sprite1 = new PIXI.Sprite(texture);
		sprite1.scale.set(0.8);
		sprite1.tint = 0xf8ba58;
		sprite1.rotation = 1.5708;
		sprite1.blendMode = PIXI.BLEND_MODES.ADD;
		sprite1.position.set(cardBack.width / 2, -cardBack.height / 2);
		frameGlowContainer.addChild(sprite1);

		new TWEEN.Tween(sprite, this.tweenGroup)
			.to({ y: -cardBack.height / 2 }, 600)
			.chain(
				new TWEEN.Tween(sprite, this.tweenGroup)
					.to({ rotation: 3.1415 }, 200)
					.chain(
						new TWEEN.Tween(sprite, this.tweenGroup)
							.to({ x: cardBack.width / 2 }, 600),
					),
			)
			.start();
		new TWEEN.Tween(sprite1, this.tweenGroup)
			.to({ y: cardBack.height / 2 }, 600)
			.chain(
				new TWEEN.Tween(sprite1, this.tweenGroup)
					.to({ rotation: 3.1415 }, 200)
					.chain(
						new TWEEN.Tween(sprite1, this.tweenGroup)
							.to({ x: -cardBack.width / 2 }, 600)
							.onComplete(() => {
								cardContainer.removeChild(frameGlowContainer);
								onFinish();
							}),
					),
			)
			.start();
	}

	private playFlareAfterOpen(cardContainer: PIXI.Container, color: string): void {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const config: EmitterConfig = SummonParticleConfig.getFlareAfterOpen(color);

		const flareCardEmitter = new Emitter(
			cardContainer,
			[
				new PIXI.Sprite(fxAtlas['star']).texture,
			],
			config,
		);
		flareCardEmitter.autoUpdate = true;
		this.animationEmitters.push(flareCardEmitter);
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public destroy(): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweenGroup, this);
	}
}
