import { BaseAction } from '../BaseAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class ApplyRechargeAction extends BaseAction {
	constructor(id: string, rewards: RewardDescriptionType[]) {
		super('ApplyRecharge');

		this.data.id = id;
		this.data.rewards = rewards;
	}
}
