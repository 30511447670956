import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness20001View } from '@views/businesses/event2Businesses/eventBuilding1/EventBusiness20001View';
import { EventBusiness20002View } from '@views/businesses/event2Businesses/eventBuilding1/EventBusiness20002View';
import { EventBusiness20003View } from '@views/businesses/event2Businesses/eventBuilding1/EventBusiness20003View';
import { EventBusiness20004View } from '@views/businesses/event2Businesses/eventBuilding2/EventBusiness20004View';
import { EventBusiness20005View } from '@views/businesses/event2Businesses/eventBuilding2/EventBusiness20005View';
import { EventBusiness20006View } from '@views/businesses/event2Businesses/eventBuilding2/EventBusiness20006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevel2View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('japanNightAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['japan_night/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['japan_night/road'], EventLevel2View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['japan_night/decor1']).setTransform(25, 370),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor1']).setTransform(131, 280),
			new PIXI.Sprite(levelBgAtlas['japan_night/house1']).setTransform(168, 888),
			new PIXI.Sprite(levelBgAtlas['japan_night/house3']).setTransform(987, 768),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor1']).setTransform(1087, 280),
			new PIXI.Sprite(levelBgAtlas['japan_night/house4']).setTransform(1180, 863),
			new PIXI.Sprite(levelBgAtlas['japan_night/house2']).setTransform(2132, 859),
			new PIXI.Sprite(levelBgAtlas['japan_night/house1']).setTransform(1932, 888),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor1']).setTransform(1887, 280, -1, 1),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor1']).setTransform(2320, 680, -1, 1),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['japan_night/decor4']).setTransform(1752, 737, -2, 2),
			new PIXI.Sprite(levelBgAtlas['japan_night/city1']).setTransform(179, 719),
			new PIXI.Sprite(levelBgAtlas['japan_night/city4']).setTransform(1060, 665),
			new PIXI.Sprite(levelBgAtlas['japan_night/city5']).setTransform(1172, 772),
			road,
			back,
		);


		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('japanNightAtlas');

		this.background = EventLevel2View.createEveningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness20001View(),
			new EventBusiness20002View(),
			new EventBusiness20003View(),
			new EventBusiness20004View(),
			new EventBusiness20005View(),
			new EventBusiness20006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['japan_night/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['japan_night/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['japan_night/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['japan_night/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor5']).setTransform(236, 500, 2, 2),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor2']).setTransform(236, 612),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['japan_night/decor5']).setTransform(2032, 500, -2, 2),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor2']).setTransform(2032, 612, -1, 1),
			new PIXI.Sprite(levelBgAtlas['japan_night/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['japan_night/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['japan_night/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['japan_night/building_left']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor5']).setTransform(1155, 500, 2, 2),
			new PIXI.Sprite(levelBgAtlas['japan_night/decor3']).setTransform(1155, 542),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		this.initLevel(EventLevel2View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}
}
