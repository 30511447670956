import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { ConfigSetElementConstructor } from '@interfaces/ConfigSetElementConstructor';

/**
 * Parses a JSON-formatted config file, passing each outer object
 * to the config constructor of type @type T
 */
export class BaseConfigSetParser<T extends ConfigSetElement> {
	public static createConfig<T extends ConfigSetElement>(
		ConfigConstructor: ConfigSetElementConstructor<T>,
		configJSON: { [key: string]: string },
	): T {
		return new ConfigConstructor(configJSON);
	}

	protected readonly ConfigConstructor: ConfigSetElementConstructor<T>;
	protected readonly configConstructorGetter: (config: { [key: string]: any }) => ConfigSetElementConstructor<T>;
	protected configSetKeyField: string;

	protected configSet: Map<string, T>;

	constructor(
		configSetKeyField: string,
		ConfigConstructor?: ConfigSetElementConstructor<T>,
		configConstructorGetter?: (config: { [key: string]: any }) => ConfigSetElementConstructor<T>,
	) {
		this.configSetKeyField = configSetKeyField;
		this.ConfigConstructor = ConfigConstructor;
		this.configConstructorGetter = configConstructorGetter;
		this.configSet = new Map();
	}

	public parseConfigSet(configSetJSON: { [key: string]: string }[]): void {
		const ConfigConstructor = this.configConstructorGetter || (() => this.ConfigConstructor);

		configSetJSON.forEach((configJSON) => {
			const configId = configJSON[this.configSetKeyField].trim();
			const config = BaseConfigSetParser.createConfig(
				ConfigConstructor(configJSON),
				configJSON,
			);
			this.configSet.set(configId, config);
		});
	}

	public getConfig(key: string): T {
		return this.configSet.get(key);
	}

	public getConfigSet(): Map<string, T> {
		return this.configSet;
	}

	public clear(): void {
		this.configSet.clear();
	}
}
