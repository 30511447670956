import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character3View extends CharacterMainBaseView {
	constructor() {
		super('character3');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-17, 76);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-90, 2),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-180, -10),
		});
		this.initQuickPhrases(this.key);
	}
}
