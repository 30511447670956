import { AssetsStorage } from '@main/AssetsStorage';

export class FarewellPartyTimerView extends PIXI.Container {
	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	private static readonly BAR_WIDTH: number = 802;

	private readonly timeText: PIXI.extras.BitmapText;

	private readonly ticker: PIXI.ticker.Ticker;

	private timerTicking: boolean;
	private currentTimeValue: number;
	private readonly targetTimeValue: number;
	private readonly progressBar: PIXI.mesh.NineSlicePlane;

	constructor(duration: number) {
		super();

		this.ticker = PIXI.ticker.shared;
		this.timerTicking = false;

		this.currentTimeValue = duration;
		this.targetTimeValue = duration;

		const progressBarBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		progressBarBg.tint = 0x0;
		progressBarBg.width = FarewellPartyTimerView.BAR_WIDTH;
		progressBarBg.height = 7;

		const progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('farewellPartyUiAtlas')['orgy_time_bar'], 0, 0, 12, 0);
		progressBar.width = FarewellPartyTimerView.BAR_WIDTH;
		progressBar.height = 7;
		progressBar.pivot.set(progressBar.width / 2, progressBar.height / 2);
		this.progressBar = progressBar;

		const timerIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']);
		timerIcon.scale.set(0.4);
		timerIcon.position.set(FarewellPartyTimerView.BAR_WIDTH / 2 + 12, -2);

		this.timeText = new PIXI.extras.BitmapText('', {
			font: '30px wendyOneShadowBold',
			align: 'left',
			tint: 0x9fd55b,
		});
		this.timeText.anchor = 0.5;
		this.timeText.position.set(FarewellPartyTimerView.BAR_WIDTH / 2 + 53, 1);

		this.addChild(
			progressBarBg,
			progressBar as PIXI.DisplayObject,
			timerIcon,
			this.timeText,
		);

		this.updateTimer();
	}

	private onTimerUpdate(): void {
		this.currentTimeValue = Math.max(0, this.currentTimeValue - this.ticker.elapsedMS / 1000);
		this.updateTimer();

		if (this.currentTimeValue <= 0) {
			this.stop();
			this.emit(FarewellPartyTimerView.EVENT_TIMEOUT);
		}
	}

	private updateTimer(): void {
		this.progressBar.width = FarewellPartyTimerView.BAR_WIDTH * (1 - this.currentTimeValue / this.targetTimeValue);
		const seconds = Math.round(this.currentTimeValue);
		this.timeText.text = `${seconds}s`;
	}

	public getTimeProgress(): number {
		return 1 - this.currentTimeValue / this.targetTimeValue;
	}

	public start(): void {
		if (this.timerTicking) {
			this.stop();
		}

		this.currentTimeValue = this.targetTimeValue;

		this.timerTicking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	public stop(): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.timerTicking) {
			this.stop();
		}
		super.destroy(options);
	}
}
