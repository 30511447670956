import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';

export class FarewellPartyTapAnimation extends PIXI.Container {
	public static readonly EVENT_COMPLETED: symbol = Symbol();

	private static glow1(): any {
		return {
			alpha: {
				start: 1,
				end: 0.46,
			},
			scale: {
				start: 0.7,
				end: 0.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#FF4912',
				end: '#FF4912',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.01,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 100,
			},
		};
	}

	private static glow2(): any {
		return {
			alpha: {
				start: 0.5,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#fff7ad',
				end: '#ffd382',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: 60,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.2,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	private glow1Emitter: Emitter;
	private glow2Emitter: Emitter;

	constructor() {
		super();

		const atlas = AssetsStorage.getAtlas('fxAtlas');

		this.glow1Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(atlas['party_click1']).texture,
			],
			FarewellPartyTapAnimation.glow1(),
		);
		this.glow1Emitter.autoUpdate = true;
		this.glow1Emitter.playOnceAndDestroy(() => {
			this.glow1Emitter = null;
			this.tryComplete();
		});

		this.glow2Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(atlas['party_click2']).texture,
			],
			FarewellPartyTapAnimation.glow2(),
		);
		this.glow2Emitter.autoUpdate = true;
		this.glow2Emitter.playOnceAndDestroy(() => {
			this.glow2Emitter = null;
			this.tryComplete();
		});
	}

	private tryComplete(): void {
		if (!this.glow1Emitter && !this.glow2Emitter) {
			this.emit(FarewellPartyTapAnimation.EVENT_COMPLETED);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.glow1Emitter) {
			this.glow1Emitter.destroy();
		}
		if (this.glow2Emitter) {
			this.glow2Emitter.destroy();
		}
		super.destroy(options);
	}
}
