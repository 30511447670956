import { SummonConfig } from '@configs/SummonConfig';
import { SummonModel } from '@models/SummonModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { UnlockValueChecker } from '../UnlockValueChecker';
import { SummonConfigsLoaderBase } from './SummonConfigsLoaderBase';
import { SummonSavesModel } from '@models/SummonSavesModel';

export class SummonLoader {
	private configsLoaded: boolean;

	constructor(
		private readonly serverTime: ServerTimeModel,
		private readonly summonModelsPool: SummonModelsPool,
		private readonly summonSavesModel: SummonSavesModel,
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly configsLoader: SummonConfigsLoaderBase,
	) {
	}

	public async init(): Promise<void> {
		if (!this.configsLoaded) {
			await this.configsLoader.loadConfigs();
			this.configsLoaded = true;
		}

		const summonConfigs = this.configsLoader.getSummonConfigs();
		const summonConfigsFiltered = this.getFilteredConfigs(summonConfigs);

		const currentTime: number = this.serverTime.getCalculatedISOTime();

		const summonModels: Map<string, SummonModel> = new Map();
		summonConfigsFiltered.forEach((config) => {
			const model = new SummonModel(config);

			let lastApplyTime: number;
			if (model.isFreePrice()) {
				lastApplyTime = this.summonSavesModel.getLastApplyFreePriceTimestamp();
			} else {
				lastApplyTime = this.summonSavesModel.getLastApplyTimestamp(model.getKey());
			}
			const nextSummonOn = lastApplyTime + model.getUnlockTime();
			const freePriceAvailable = model.isFreePrice() && nextSummonOn <= currentTime;
			model.setNextSummonOn(nextSummonOn);
			model.setFreePriceAvailable(freePriceAvailable);

			summonModels.set(config.getKey(), model);
		});

		this.summonModelsPool.setModels(summonModels);
	}

	private getFilteredConfigs(configs: Map<string, SummonConfig>): Map<string, SummonConfig> {
		const configsFiltered = new Map<string, SummonConfig>();
		configs.forEach(config => {
			let buyTimesOK: boolean;
			if (!config.hasBuyTimes() || config.hasBuyTimes() && config.getBuyTimes() === 0) {
				buyTimesOK = true;
			} else if (config.hasBuyTimes()) {
				const applyTimes = this.summonSavesModel.getApplyTimes(config.getKey());
				buyTimesOK = config.getBuyTimes() > applyTimes;
			}

			if (buyTimesOK) {
				if (!config.hasSomeUnlockTypeValue()) {
					configsFiltered.set(config.getKey(), config);
				} else {
					const unlockValues = config.getUnlockValues();
					const unlockTypeValueOK = config
						.getUnlockTypes()
						.every((unlockType, i) => this.unlockValueChecker.check(unlockType, unlockValues[i]));
					if (unlockTypeValueOK) {
						configsFiltered.set(config.getKey(), config);
					}
				}
			}
		});
		return configsFiltered;
	}
}
