export abstract class ObjectPool<T> extends PIXI.utils.EventEmitter {
	protected readonly pool: Map<string, T>;

	constructor() {
		super();

		this.pool = new Map();
	}

	protected onPoolObjectCreated(poolObject: T): void {} // eslint-disable-line

	protected abstract createPoolObject(key: string): T;

	protected getPoolObject(key: string): T {
		if (this.pool.has(key)) {
			return this.pool.get(key);
		}

		const poolObject = this.createPoolObject(key);
		this.onPoolObjectCreated(poolObject);
		this.pool.set(key, poolObject);
		return poolObject;
	}
}
