import { LevelConfigNames } from '@src/types/LevelConfigNames';
import { SummonConfigsLoaderBase } from './SummonConfigsLoaderBase';

export class SummonLevelConfigsLoader extends SummonConfigsLoaderBase {
	public async loadConfigs(): Promise<void> {
		const result = await this.networkRequestSender.sendGetConfigs([
			LevelConfigNames.CONFIG_SUMMON,
		]);

		this.summonConfigsParser.parseConfigSet(
			result[LevelConfigNames.CONFIG_SUMMON],
		);
	}
}
