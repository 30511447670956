import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { BankElementViewDataConfig } from './BankElementViewDataConfig';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class BankGemShopItemConfig
	extends BankElementViewDataConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_MOST_POPULAR: string = 'most_popular';
	public static readonly FIELD_PRICE: string = 'price';
	public static readonly FIELD_OLD_PRICE: string = 'prev_price';
	public static readonly FIELD_STICKER: string = 'sticker';
	public static readonly FIELD_PRICE_RESOURCE: string = 'price_resource';
	public static readonly FIELD_OLD_PRICE_RESOURCE: string = 'prev_price_resource';
	public static readonly FIELD_REWARD_TYPE: string = 'reward_type';
	public static readonly FIELD_REWARD_ID: string = 'reward_id';
	public static readonly FIELD_REWARD_COUNT: string = 'reward_count';
	public static readonly FIELD_EXTRA_REWARD_TYPE: string = 'extra_reward_type';
	public static readonly FIELD_EXTRA_REWARD_ID: string = 'extra_reward_id';
	public static readonly FIELD_EXTRA_REWARD_COUNT: string = 'extra_reward_count';
	public static readonly FIELD_OFFER_ID: string = 'offer_id';
	public static readonly FIELD_BUY_TIMES: string = 'buy_times';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value'

	private readonly key: string;
	private readonly mostPopularLocaleKey: string;
	private readonly price: number;
	private readonly oldPrice: string;
	private readonly stickerLocaleKey?: string;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly oldPriceResource: BankPriceResourceTypes;
	private readonly rewardDescription: RewardDescriptionType;
	private readonly extraRewards?: RewardDescriptionType[];
	private readonly offerKey: string;
	private readonly buyTimes: number;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];

	constructor(config: { [key: string]: string }) {
		super(config[BankGemShopItemConfig.FIELD_VIEW_KEY].trim());

		this.key = config[BankGemShopItemConfig.FIELD_KEY].trim();

		this.price = Number(config[BankGemShopItemConfig.FIELD_PRICE].trim());
		this.oldPrice = config[BankGemShopItemConfig.FIELD_OLD_PRICE]?.trim();

		this.mostPopularLocaleKey = config[BankGemShopItemConfig.FIELD_MOST_POPULAR].trim();

		const stickerLocaleKeyFormatted = config[BankGemShopItemConfig.FIELD_STICKER].trim() as string;
		if (stickerLocaleKeyFormatted && stickerLocaleKeyFormatted.length > 0) {
			this.stickerLocaleKey = stickerLocaleKeyFormatted;
		}

		this.priceResource = config[BankGemShopItemConfig.FIELD_PRICE_RESOURCE].trim() as BankPriceResourceTypes;
		this.oldPriceResource = config[BankGemShopItemConfig.FIELD_OLD_PRICE_RESOURCE]?.trim() as BankPriceResourceTypes;

		this.rewardDescription = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_type: config[BankGemShopItemConfig.FIELD_REWARD_TYPE].trim(),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_id: config[BankGemShopItemConfig.FIELD_REWARD_ID].trim(),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_qty: config[BankGemShopItemConfig.FIELD_REWARD_COUNT].trim(),
		};

		const extraRewardTypeUnformatted = config[BankGemShopItemConfig.FIELD_EXTRA_REWARD_TYPE].trim() as string;
		if (extraRewardTypeUnformatted && extraRewardTypeUnformatted.length > 0) {
			const extraRewardsId = config[BankGemShopItemConfig.FIELD_EXTRA_REWARD_ID].split(',').map((str: string) => str.trim());
			const extraRewardsCount = config[BankGemShopItemConfig.FIELD_EXTRA_REWARD_COUNT].split(',').map((str: string) => str.trim());
			this.extraRewards = extraRewardTypeUnformatted.split(',').map((rewardType, i) => {
				const extraReward = {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					reward_type: rewardType.trim(),
					// eslint-disable-next-line @typescript-eslint/naming-convention
					reward_id: extraRewardsId[i],
					// eslint-disable-next-line @typescript-eslint/naming-convention
					reward_qty: extraRewardsCount[i],
				};
				return extraReward;
			});
		}

		if (config[BankGemShopItemConfig.FIELD_OFFER_ID]) {
			this.offerKey = config[BankGemShopItemConfig.FIELD_OFFER_ID].trim();
		}

		this.buyTimes = Number(config[BankGemShopItemConfig.FIELD_BUY_TIMES]);

		if (config[BankGemShopItemConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[BankGemShopItemConfig.FIELD_UNLOCK_TYPE];
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[BankGemShopItemConfig.FIELD_UNLOCK_VALUE];
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public getBuyTimes(): number {
		return this.buyTimes;
	}

	public getKey(): string {
		return this.key;
	}

	public getMostPopularLocaleKey(): string {
		return this.mostPopularLocaleKey;
	}

	public hasSticker(): boolean {
		return this.stickerLocaleKey !== undefined;
	}

	public getStickerLocaleKey(): string {
		return this.stickerLocaleKey;
	}

	public getPrice(): number {
		return this.price;
	}

	public getOldPrice(): string {
		return this.oldPrice;
	}

	public hasOldPrice(): boolean {
		return Boolean(this.oldPrice);
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getOldPriceResource(): BankPriceResourceTypes {
		return this.oldPriceResource;
	}

	public getRewardDescription(): RewardDescriptionType {
		return this.rewardDescription;
	}

	public hasExtraRewards(): boolean {
		return this.extraRewards !== undefined;
	}

	public getExtraRewardDescriptions(): RewardDescriptionType[] {
		return this.extraRewards;
	}

	public hasOfferKey(): boolean {
		return Boolean(this.offerKey);
	}

	public getOfferKey(): string {
		return this.offerKey;
	}
}
