import { PreloaderViewSetter } from '@interfaces/ViewSetters';
import { LevelPreloaderView } from '@views/preloader/LevelPreloaderView';

export class AnalyticSourceLevelPreloaderView extends PIXI.utils.EventEmitter implements PreloaderViewSetter {
	public static readonly EVENT_PRELOADER_COMPLETED: symbol = Symbol();
	public static readonly EVENT_PRELOADER_PROGRESS: symbol = Symbol();
	public static readonly EVENT_PRELOADER_BUTTON_LOGIN_CLICK: symbol = Symbol();

	public setPreloaderView(view: LevelPreloaderView): void {
		view.once(LevelPreloaderView.EVENT_COMPLETED, () => {
			this.emit(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_COMPLETED);
		}, this);
		view.on(LevelPreloaderView.EVENT_BUTTON_LOGIN_CLICK, () => {
			this.emit(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_BUTTON_LOGIN_CLICK);
		}, this);
		view.on(LevelPreloaderView.EVENT_PROGRESS, (progress: number) => {
			this.emit(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_PROGRESS, progress);
		}, this);
	}
}
