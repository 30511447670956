import CharacterConfig from '@src/configs/CharacterConfig';
import { PromotableModel } from '@models/PromotableModel';
import { CharacterSaveData } from '@src/types/SaveTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BonusTypes } from '@src/types/BonusTypes';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';

export class CharacterModel extends PromotableModel {
	public static readonly EVENT_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_UPDATE_PICTURE: symbol = Symbol();

	private activated: boolean;
	private readonly activateCost: SoftMoneyNumber;
	private readonly activateLevels: number[];

	private activateLevel: number;

	private affectsAllBusinesses: boolean;
	private skillKey: string;

	private automateBonus: boolean;

	private relatedBusinessKey: string;

	private nudeLevels: number[];
	private selectedPictureId: number;

	private readonly soundPack: string;

	constructor(
		config: CharacterConfig,
		promotePatternsConfig: AbstractPromotePatternsConfig,
	) {
		super(
			config,
			promotePatternsConfig,
		);

		this.activated = false;
		this.affectsAllBusinesses = config.isAffectsAllBusinesses();
		this.activateCost = config.getActivateCost();
		this.activateLevels = config.getActivateLevels();

		this.soundPack = config.getSoundPack();

		this.nudeLevels = config.getNudeLevels();
		this.selectedPictureId = 1;

		this.initSpecificBonuses(config.getSpecificBonuses());
	}

	public setFromSaveData(saveData: CharacterSaveData): void {
		this.opened = true;
		this.activated = saveData.activated;
		this.level = saveData.level;
		this.cardsCount = saveData.cardsCount;
		this.newItem = saveData.isNew;
		const maxPictureIndex = this.getMaxPictureIndex();

		if (saveData.pictureId) {
			this.selectedPictureId = Math.min(saveData.pictureId, maxPictureIndex);
		} else {
			this.selectedPictureId = maxPictureIndex;
		}

		this.recalculateBonuses();
	}

	public setPictureId(value: number): void {
		this.selectedPictureId = Math.min(value, this.getMaxPictureIndex());
		this.emit(CharacterModel.EVENT_UPDATE_PICTURE);
	}

	public resetModel(): void {
		this.activated = false;
	}

	public setRelatedBusinessKey(key: string): void {
		this.relatedBusinessKey = key;
	}

	public setActivateLevel(level: number): void {
		const value = this.activateLevels[Math.min(level - 1, this.activateLevels.length - 1)];
		this.activateLevel = value;
	}

	public getRelatedBusinessKey(): string {
		return this.relatedBusinessKey;
	}

	public getActivateCost(): SoftMoneyNumber {
		return this.activateCost;
	}

	public getActivateLevel(): number {
		return this.activateLevel;
	}

	public isAffectsAllBusinesses(): boolean {
		return this.affectsAllBusinesses;
	}

	public isActivated(): boolean {
		return this.activated;
	}

	public isActivateLevelReached(): boolean {
		return this.level >= this.activateLevel;
	}

	public activate(): void {
		this.activated = true;
		this.emit(CharacterModel.EVENT_ACTIVATED, this);
	}

	public hasBonusSkill(): boolean {
		return Boolean(this.skillKey);
	}

	public hasBonusAutomate(): boolean {
		return this.automateBonus;
	}

	public getSkillKey(): string {
		return this.skillKey;
	}

	public getTotalNudeLevels(): number {
		return this.nudeLevels.length;
	}

	public getNudeLevel(pictureId: number): number {
		return this.nudeLevels[pictureId - 1];
	}

	public getCurrentPictureId(): number {
		return this.selectedPictureId;
	}

	public getMaxPictureIndex(): number {
		let i = 0;
		while (i < this.nudeLevels.length && this.level >= this.nudeLevels[i]) {
			i += 1;
		}
		return i;
	}

	protected initSpecificBonuses(bonusConfigs: Map<BonusTypes, number[] | string>): void {
		this.automateBonus = false;

		bonusConfigs.forEach((value: string | number[], type: BonusTypes) => {
			switch (type) {
				case BonusTypes.SKILL: {
					this.skillKey = value as string;
					break;
				}
				case BonusTypes.AUTOMATE: {
					this.automateBonus = true;
					break;
				}
				default:
					throw new Error(`Unsupported bonusType ${type}`);
			}
		});
	}

	public getSoundPackName(): string {
		return this.soundPack;
	}
}
