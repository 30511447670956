import { BankViewTypes, BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';

export class BankTabElementConfig {
	private readonly viewKey: string;
	private readonly dataConfigName: BankTabElementDataConfigNameTypes;
	private readonly viewType: BankViewTypes;
	private readonly viewParameters: string[];

	constructor(
		viewKey: string,
		dataConfigName: BankTabElementDataConfigNameTypes,
		viewType: BankViewTypes,
		viewParameters: string[],
	) {
		this.viewKey = viewKey;
		this.dataConfigName = dataConfigName;
		this.viewType = viewType;
		this.viewParameters = viewParameters;
	}

	public getViewKey(): string {
		return this.viewKey;
	}

	public getDataConfigName(): BankTabElementDataConfigNameTypes {
		return this.dataConfigName;
	}

	public getViewType(): BankViewTypes {
		return this.viewType;
	}

	public getViewParameters(): string[] {
		return this.viewParameters;
	}
}
