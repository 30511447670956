import { BusinessAcquiredUIViewController } from './BusinessAcquiredUIViewController';
import { BusinessModel } from '@models/BusinessModel';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { BusinessManageUIView } from '@views/businesses/ui/acquired/manage/BusinessManageUIView';
import { BusinessManageUIViewController } from './BusinessManageUIViewController';
import { BusinessOpenToBuyUIView } from '@views/businesses/ui/openToBuy/BusinessOpenToBuyUIView';
import { BusinessOpenToBuyUIViewController } from './BusinessOpenToBuyUIViewController';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import {
	BusinessAcquiredUIViewSetter,
	BusinessManageUIViewSetter,
	BusinessOpenToBuyUIViewSetter,
	GameUIBaseViewSetter,
} from '@interfaces/ViewSetters';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ObjectPool } from '@src/utils/ObjectPool';
import { UpgradeModel } from '@models/UpgradeModel';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { BusinessesTicker } from '@src/tickers/BusinessesTicker';
import { LevelBaseModel } from '@models/level/LevelBaseModel';
import { ShowHintDataResolver } from '@views/ui/main/ShowHintDataResolver';

export class BusinessAcquiredUIViewControllerPool
	extends ObjectPool<BusinessAcquiredUIViewController> implements BusinessAcquiredUIViewSetter, GameUIBaseViewSetter {
	private gameUIView: GameUIBaseView;

	constructor(
		private readonly windowSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly businessModels: Map<string, BusinessModel>,
		private readonly newCustomerMultiplierModels: Map<string, NewCustomersMutiplierModel>,
		private readonly upgradeModels: Map<string, UpgradeModel>,
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly lootboxFactory: LootboxFactory,
		private readonly businessesTicker: BusinessesTicker,
		private readonly summonModelsPool: SummonModelsPool,
		private readonly questTargetDuration: number,
		private readonly levelModel: LevelBaseModel,
	) {
		super();
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUIView = view;
		this.pool.forEach(obj => obj.setGameUIBaseView(view));
	}

	public setAcquiredUIView(view: BusinessAcquiredUIView): void {
		const key = view.getKey();
		this.getPoolObject(key).setAcquiredUIView(view);
	}

	protected createPoolObject(key: string): BusinessAcquiredUIViewController {
		const businessModel = this.businessModels.get(key);
		const newCustomerMultiplerModel = this.newCustomerMultiplierModels.get(key);
		const controller = new BusinessAcquiredUIViewController(
			this.windowSystem,
			this.windowFactory,
			this.networkRequestSender,
			this.summonModelsPool,
			businessModel,
			newCustomerMultiplerModel,
			businessModel.getUpgradeKeys().map(upgradeKey => this.upgradeModels.get(upgradeKey)),
			this.softMoneyModel,
			this.prestigeMoneyModel,
			this.lootboxFactory,
			this.businessesTicker,
			this.questTargetDuration,
			this.levelModel,
		);
		controller.setGameUIBaseView(this.gameUIView);
		return controller;
	}

	protected onPoolObjectCreated(poolObject: BusinessAcquiredUIViewController): void {
		poolObject.on(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
	}

	private onSomeActionCreated(action: BaseAction): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}
}

export class BusinessManageUIViewControllerPool
	extends ObjectPool<BusinessManageUIViewController> implements BusinessManageUIViewSetter, GameUIBaseViewSetter {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly hintSpawner: ShowHintSpawner;
	private readonly charterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly skillModels: Map<string, SkillModel>;
	private windowFactory: WindowViewBaseFactory;
	private windowViewSystem: WindowViewSystem;
	private gameUI: GameUIBaseView;

	constructor(
		charterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		skillModels: Map<string, SkillModel>,
		softMoneyModel: SoftMoneyModel,
		businessModels: Map<string, BusinessModel>,
		hintSpawner: ShowHintSpawner,
		private readonly hintResolver: ShowHintDataResolver,
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
	) {
		super();
		this.softMoneyModel = softMoneyModel;
		this.businessModels = businessModels;
		this.hintSpawner = hintSpawner;
		this.charterModels = charterModels;
		this.upgradeModels = upgradeModels;
		this.skillModels = skillModels;
		this.windowFactory = windowFactory;
		this.windowViewSystem = windowViewSystem;
	}

	// eslint-disable-next-line class-methods-use-this
	protected createPoolObject(key: string): BusinessManageUIViewController {
		const model = this.businessModels.get(key);
		return new BusinessManageUIViewController(
			model,
			this.charterModels,
			this.upgradeModels,
			this.skillModels,
			this.softMoneyModel,
			this.hintSpawner,
			this.hintResolver,
			this.windowViewSystem,
			this.windowFactory,
		);
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
		this.pool.forEach(x => x.setGameUIBaseView(view));
	}

	protected onPoolObjectCreated(poolObject: BusinessManageUIViewController): void {
		poolObject.on(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
	}

	private onSomeActionCreated(action: BaseAction): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public setManageUIView(view: BusinessManageUIView): void {
		const key = view.getKey();
		const obj = this.getPoolObject(key);
		if (this.gameUI) {
			obj.setGameUIBaseView(this.gameUI);
		}
		obj.setManageUIView(view);
	}
}

export class BusinessOpenToBuyUIViewControllerPool extends ObjectPool<BusinessOpenToBuyUIViewController> implements BusinessOpenToBuyUIViewSetter {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly softMoneyModel: SoftMoneyModel;

	constructor(
		businessModels: Map<string, BusinessModel>,
		softMoneyModel: SoftMoneyModel,
	) {
		super();
		this.businessModels = businessModels;
		this.softMoneyModel = softMoneyModel;
	}

	// eslint-disable-next-line class-methods-use-this
	protected createPoolObject(key: string): BusinessOpenToBuyUIViewController {
		const model = this.businessModels.get(key);
		return new BusinessOpenToBuyUIViewController(model, this.softMoneyModel);
	}

	protected onPoolObjectCreated(poolObject: BusinessOpenToBuyUIViewController): void {
		poolObject.on(BaseAction.EVENT_ACTION_CREATED, this.onSomeActionCreated, this);
	}

	private onSomeActionCreated(action: BaseAction): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public setOpenToBuyUIView(view: BusinessOpenToBuyUIView): void {
		const key = view.getKey();
		this.getPoolObject(key).setOpenToBuyUIView(view);
	}
}
