import { BankOfferModel } from '@models/bank/BankOfferModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BundleRewardDisplayInfo } from './BankTabElementBundleTimedView';
import { BankTabElementCompareView } from './BankTabElementCompareView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { TextField } from '@views/components/text/TextField';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class BankTabElementCompareLeftView extends BankTabElementCompareView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly timerLabel: PIXI.extras.BitmapText;
	private readonly ticker: PIXI.ticker.Ticker;

	constructor(
		bankBundleModel: BankBundleModel,
		rewards: RewardDescriptionType[],
		rewardsDisplayInfo: BundleRewardDisplayInfo[],
		private readonly character: string,
		private readonly offerModel?: BankOfferModel,
	) {
		super(bankBundleModel, rewards, rewardsDisplayInfo);

		this.ticker = PIXI.ticker.shared;

		this.localizationStorage = LocalizationStorage.getInstance();

		const girl = new PIXI.Sprite(AssetsStorage.getResource(`${character}_offer`).texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.position.set(-504, 55);
		girl.scale.set(0.93);

		const bgLeft = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_bg_perple'], 25, 0, 25, 0);
		bgLeft.pivot.set(bgLeft.width / 2, bgLeft.height / 2);
		bgLeft.scale.set(1.82);
		bgLeft.width = 230;
		bgLeft.y = -27;
		bgLeft.x = -112;
		bgLeft.interactive = true;

		const bgLeftTint = new PIXI.Graphics();
		bgLeftTint.beginFill(0x000000);
		bgLeftTint.drawPolygon([
			new PIXI.Point(-330, 230),
			new PIXI.Point(47, 230),
			new PIXI.Point(57, 62),
			new PIXI.Point(-320, 62),
		]);
		bgLeftTint.alpha = 0.25;

		const titleTextLeft = new MultiColoredTextField(
			{ font: '38px wendyOneShadowBold', align: 'center', tint: 0x9ac5ff },
			350,
			100,
		);
		titleTextLeft.text = this.localizationStorage.getLocalizedString(bankBundleModel.getNameLocale());
		titleTextLeft.anchor = 0.5;
		titleTextLeft.position.set(-124, -190);

		const timerBg = new PIXI.Graphics();
		timerBg.beginFill(0x000000);
		timerBg.drawPolygon([
			new PIXI.Point(220, -23),
			new PIXI.Point(-210, -23),
			new PIXI.Point(-220, 22),
			new PIXI.Point(210, 22),
		]);
		timerBg.alpha = 0.7;

		const timerContainer = new PIXI.Container();
		timerContainer.position.set(198, 343);

		const buyButtonLeft = this.createButton(250, 81);
		buyButtonLeft.position.set(-130, 146);

		this.addChild(
			girl,
			bgLeft,
			bgLeftTint,
			titleTextLeft as PIXI.DisplayObject,
			timerContainer,
			buyButtonLeft,
		);

		if (offerModel) {
			const timerGlow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow'], 0, 0, 0, 0);
			timerGlow.width = 1000;
			timerGlow.height = 250;
			timerGlow.pivot.set(timerGlow.width / 2, timerGlow.height / 2);
			timerGlow.tint = 0xb74a0c;
			timerGlow.alpha = 0.35;

			this.timerLabel = new PIXI.extras.BitmapText('', {
				font: '30px wendyOneShadowBold', tint: 0xffda68,
			});
			this.timerLabel.anchor = 0.5;
			timerContainer.addChild(
				timerGlow,
				timerBg,
				this.timerLabel as PIXI.DisplayObject,
			);
		}
		const rewardsContainerLeft = new PIXI.Container();
		rewardsContainerLeft.position.set(-132, -45);

		rewardsContainerLeft.addChild(...this.getRewardsRow(10, 0.79));
		this.addChild(rewardsContainerLeft);

		if (bankBundleModel.getMostPopularLocaleKey()) {
			const stickerSaleContainer = new PIXI.Container();
			stickerSaleContainer.position.set(-292, -274);
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			const saleLabel = new TextField(
				this.localizationStorage.getLocalizedString(bankBundleModel.getMostPopularLocaleKey()),
				{ font: '26px wendyOne', align: 'center', tint: 0xfff78f },
				80,
				80,
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.47;

			stickerSaleContainer.addChild(
				stickerSale,
				saleLabel as PIXI.DisplayObject,
			);

			this.addChild(stickerSaleContainer);
		}

		this.ticker.add(this.onUpdateTextTimer, this);
	}

	private onUpdateTextTimer(): void {
		if (this.bankBundleModel.getRemainingCount() > 0) {
			const timeRemainText = this.localizationStorage.getLocalizedString('#bank_bundle_time_remain');
			const timeleft = this.offerModel ? this.offerModel.getTimeleft() : this.bankBundleModel.getTimeLeft();
			const timeleftFormatted = NumberUtils.secToDHMSColonFormatted(timeleft);
			this.timerLabel.text = `${timeRemainText} ${timeleftFormatted}`;
		} else {
			this.timerLabel.text = NumberUtils.secToDHMSColonFormatted(this.bankBundleModel.getTimeLeft());
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.onUpdateTextTimer, this);
		super.destroy(options);
	}
}
