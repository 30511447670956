import { SavesConfig } from '@configs/saves/SavesConfig';
import { LevelConfig } from '@configs/level/LevelConfig';
import { LevelPreloaderView } from '@views/preloader/LevelPreloaderView';
import { ConstantsConfig, FakeProgressConstants } from '@configs/ConstantsConfig';
import { GameProfileModel } from '@models/GameProfileModel';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { LoginWindowView } from '@views/windows/account/LoginWindowView';
import { SoundController } from './SoundController';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { NetworkRequestTransport } from '@src/network/NetworkRequestTransport';
import { NetworkProfileLoader } from '@src/loaders/NetworkProfileLoader';
import { GameProfilePreloaderViewController } from '@src/viewControllers/GameProfilePreloaderViewController';
import { AnalyticSourceLevelPreloaderView } from '@src/network/analytics/sources/AnalyticSourceLevelPreloaderView';
import { ResendConfirmationEmailCodeTicker } from '@src/tickers/ResendConfirmationEmailCodeTicker';
import { CheatWindowSystemViewController } from '@src/viewControllers/cheat/CheatWindowSystemViewController';
import { CheatModel } from '@models/CheatModel';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NutakuLevelPreloaderView } from '@views/preloader/NutakuLevelPreloaderView';
import { AssetLoader } from '@src/utils/AssetLoader';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

export class LevelPreloaderInstance extends PIXI.utils.EventEmitter {
	public static readonly EVENT_COMPLETED: symbol = Symbol();
	public static readonly EVENT_SHOW_CHEAT_WINDOW: symbol = Symbol();

	private readonly stage: PIXI.Container;
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly networkRequestTransport: NetworkRequestTransport;
	private readonly networkProfileLoader: NetworkProfileLoader;
	private readonly gameProfileModel: GameProfileModel;
	private readonly cheatModel: CheatModel;
	private readonly savesConfig: SavesConfig;
	private readonly levelConfigs: Map<string, LevelConfig>;

	private readonly gameProfileViewController: GameProfilePreloaderViewController;
	private readonly cheatWindowSystemViewController: CheatWindowSystemViewController;

	private readonly preloaderView: LevelPreloaderView;

	private readonly analyticSourceLevelPreloaderView: AnalyticSourceLevelPreloaderView;
	private readonly resendConfimationEmailCodeTicker: ResendConfirmationEmailCodeTicker;

	constructor(
		stage: PIXI.Container,
		networkRequestSender: NetworkRequestSender,
		networkRequestTransport: NetworkRequestTransport,
		networkProfileLoader: NetworkProfileLoader,
		gameProfileModel: GameProfileModel,
		cheatModel: CheatModel,
		env: string,
		savesConfig: SavesConfig,
		constantsConfig: ConstantsConfig,
		levelConfigs: Map<string, LevelConfig>,
		assetUrlsLoader: AssetUrlsLoader,
		landingModest?: boolean,
		nutaku?: boolean,
	) {
		super();

		this.stage = stage;
		this.networkRequestSender = networkRequestSender;
		this.networkRequestTransport = networkRequestTransport;
		this.networkProfileLoader = networkProfileLoader;
		this.gameProfileModel = gameProfileModel;
		this.cheatModel = cheatModel;
		this.levelConfigs = levelConfigs;
		this.savesConfig = savesConfig;

		this.gameProfileViewController = new GameProfilePreloaderViewController(
			this.networkRequestSender,
			this.networkRequestTransport,
			this.networkProfileLoader,
			this.gameProfileModel,
			this.savesConfig,
			constantsConfig.getResendEmailConfirmationCodeTimeout(),
		);

		this.resendConfimationEmailCodeTicker = new ResendConfirmationEmailCodeTicker(this.gameProfileModel);
		this.cheatWindowSystemViewController = new CheatWindowSystemViewController(
			this.networkRequestSender,
			this.cheatModel,
		);

		const currentLevel = this.savesConfig.getLevel() || 1;
		const levelConfig = this.levelConfigs.get(currentLevel.toString());
		const currentSeason = levelConfig.getSeason();
		const activatedCharacterKeys: string[] = [];
		this.savesConfig.getCharactersSaveData().forEach((save, key) => {
			if (save.activated) {
				activatedCharacterKeys.push(key);
			}
		});
		const levelConfigsArr = Array.from(levelConfigs.values());
		const seasonCount = Math.max(...levelConfigsArr.map(config => config.getSeason()));

		const mainLoader = new AssetLoader(assetUrlsLoader);
		const subLoader = new AssetLoader(assetUrlsLoader);
		const soundLoader = new AssetLoader(assetUrlsLoader);

		[mainLoader, subLoader, soundLoader].forEach(loader => loader.on('complete', () => loader.destroy()));

		this.preloaderView = this.createPreloaderView(
			mainLoader,
			subLoader,
			soundLoader,
			gameProfileModel,
			constantsConfig.getAnalyticsSendPreloaderProgressInterval(),
			env,
			seasonCount,
			currentSeason,
			activatedCharacterKeys,
			constantsConfig.getLoadScreenFakeProgressBarConstants(),
			landingModest,
			nutaku,
		);
		this.preloaderView.once(LevelPreloaderView.EVENT_COMPLETED, this.onPreloaderCompleted, this);
		this.preloaderView.on(LevelPreloaderView.EVENT_TOGGLE_SOUND, this.onPreloaderToggleSound, this);
		this.preloaderView.on(LevelPreloaderView.EVENT_BUTTON_CHEAT_CLICK, () => {
			const cheatWindow = this.createCheatWindow();
			this.cheatWindowSystemViewController.setCheatWindowView(cheatWindow);
			this.emit(LevelPreloaderInstance.EVENT_SHOW_CHEAT_WINDOW, cheatWindow);
		}, this);
		this.preloaderView.on(LevelPreloaderView.EVENT_WINDOW_REGISTRATION_SHOWED, (view: RegistrationWindowView) => {
			this.gameProfileViewController.setRegistrationWindowView(view);
		}, this);
		this.preloaderView.on(LevelPreloaderView.EVENT_WINDOW_LOGIN_SHOWED, (view: LoginWindowView) => {
			this.gameProfileViewController.setLoginWindowView(view);
		}, this);

		this.stage.addChild(this.preloaderView);

		this.analyticSourceLevelPreloaderView = new AnalyticSourceLevelPreloaderView();
		this.analyticSourceLevelPreloaderView.setPreloaderView(this.preloaderView);
	}

	private createPreloaderView(
		mainLoader: AssetLoader,
		subLoader: AssetLoader,
		soundLoader: AssetLoader,
		gameProfileModel: GameProfileModel,
		preloaderProgressEmitInterval: number,
		env: string,
		seasonCount: number,
		currentSeason: number,
		activatedCharacterKeys: string[],
		fakeProgressConstants: FakeProgressConstants,
		landingModest?: boolean,
		isNutaku?: boolean,
	): LevelPreloaderView {
		let view: LevelPreloaderView;

		if (isNutaku) {
			view = new NutakuLevelPreloaderView(
				mainLoader,
				subLoader,
				soundLoader,
				gameProfileModel,
				this.savesConfig.getSettingsSaveData(),
				preloaderProgressEmitInterval,
				env,
				seasonCount,
				currentSeason,
				activatedCharacterKeys,
				this.savesConfig.getRedeemCode(),
				fakeProgressConstants,
				landingModest,
			);
		} else {
			view = new LevelPreloaderView(
				mainLoader,
				subLoader,
				soundLoader,
				gameProfileModel,
				this.savesConfig.getSettingsSaveData(),
				preloaderProgressEmitInterval,
				env,
				seasonCount,
				currentSeason,
				activatedCharacterKeys,
				this.savesConfig.getRedeemCode(),
				fakeProgressConstants,
				landingModest,
			);
		}
		return view;
	}

	private onPreloaderCompleted(): void {
		this.emit(LevelPreloaderInstance.EVENT_COMPLETED);
	}

	private onPreloaderToggleSound(sound: boolean): void {
		const value = sound ? 1 : 0;
		SoundController.getInstance().setMusicVolume(value);
		SoundController.getInstance().setSoundsVolume(value);

		const action = new UpdateUserDataAction({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			settings_sounds: value,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			settings_music: value,
		});
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public getAnalyticSourceLevelPreloaderView(): AnalyticSourceLevelPreloaderView {
		return this.analyticSourceLevelPreloaderView;
	}

	private createCheatWindow(): CheatWindowView {
		return new CheatWindowView(this.cheatModel);
	}

	public destroy(): void {
		this.preloaderView.destroy({ children: true });
		this.resendConfimationEmailCodeTicker.destroy();
	}
}
