import { CharacterModel } from '@models/CharacterModel';
import { CardRarities } from '@src/types/CardRarities';
import { AssetsStorage } from '@main/AssetsStorage';
import { LevelStartCardBaseView } from '@views/windows/levelStartWindow/LevelStartCardBaseView';

export class LevelStartCharacterCardView extends LevelStartCardBaseView<CharacterModel> {
	private readonly characterPicture: PIXI.Sprite;
	private readonly characterCardAtlas: PIXI.loaders.TextureDictionary;

	private readonly availablePictures: number;
	private currentPictureId: number;

	constructor(
		model: CharacterModel,
	) {
		super(
			model,
		);

		this.characterCardAtlas = AssetsStorage.getAtlas('characterCardAtlas');

		this.availablePictures = 0;
		while (this.characterCardAtlas[`${this.model.getKey()}_card${this.availablePictures + 1}`]) {
			this.availablePictures += 1;
		}

		const cardRarity = model.getCardRarity();
		const rarityColor = CardRarities.getRarityColor(cardRarity);

		this.characterPicture = new PIXI.Sprite();
		this.characterPicture.anchor.set(0.5);
		this.characterPicture.y = -42;

		this.setPicture(this.model.getCurrentPictureId());

		const background = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['u_rarity_card_bg']);
		background.tint = rarityColor;
		background.position.set(0, -42);

		this.fadeContainer.addChildAt(background, 0);
		this.fadeContainer.addChildAt(this.characterPicture, 0);
	}

	private setPicture(pictureId: number): void {
		this.currentPictureId = pictureId > this.availablePictures ? this.availablePictures : pictureId;
		const availablePicture = this.characterCardAtlas[`${this.model.getKey()}_card${this.currentPictureId}`];
		this.characterPicture.texture = availablePicture;
	}
}
