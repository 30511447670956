import { SkillModel } from '@models/skills/SkillModel';

export class SkillTimeImproveModel extends SkillModel {
	public getMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel) * this.effectiveImproveMultiplier;
	}

	public getNextMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel + 1) * this.effectiveImproveMultiplier;
	}
}
