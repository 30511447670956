import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter52001View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52001View';
import { EventCharacter52002View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52002View';
import { EventCharacter52003View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52003View';

export class EventBusiness52001View extends BusinessBaseView {
	constructor() {
		const eventCharacter52001View = new EventCharacter52001View();
		const eventCharacter52002View = new EventCharacter52002View();
		const eventCharacter52003View = new EventCharacter52003View();
		eventCharacter52003View.once(EventCharacter52003View.EVENT_SHOWN, () => eventCharacter52001View.destroy());

		super('business52001', [eventCharacter52001View, eventCharacter52002View, eventCharacter52003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter52001View as PIXI.DisplayObject,
			eventCharacter52002View,
			eventCharacter52003View,
		);
	}
}
