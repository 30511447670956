import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';
import AbstractMoneyModel from '@models/money/AbstractMoneyModel';

export class QuestGetPrestigeOrHardMoney<T extends AbstractMoneyModel> extends AbstractQuest {
	private readonly moneyController: T;
	private readonly targetAmount: number;

	private currentAmount: number;

	constructor(
		questKey: string,
		questType: QuestTypes.GET_HARD_MONEY | QuestTypes.GET_PRESTIGE_MONEY,
		moneyController: T,
		targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.moneyController = moneyController;

		this.currentAmount = 0;

		this.targetAmount = targetAmount;

		this.trackProgressStart();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.completed ? this.getTotalProgressValue() : this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	private onMoneyValueIncreased(deltaIncrease: number): void {
		this.currentAmount += deltaIncrease;

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		this.moneyController.off(AbstractMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this, false);
	}

	protected trackProgressStart(): void {
		this.moneyController.on(AbstractMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this);
	}
}
