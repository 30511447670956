export class CardProgressBarParticlesConfigs {
	public static readonly ENERGY = {
		alpha: {
			start: 0.5,
			end: 0,
		},
		scale: {
			start: 1.6,
			end: 0.8,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#FF8B19',
			end: '#FF8B19',
		},
		speed: {
			start: -650,
			end: -650,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: -500,
		startRotation: {
			min: 0,
			max: 0,
		},
		noRotation: true,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.3,
			max: 0.4,
		},
		blendMode: 'add',
		frequency: 0.03,
		emitterLifetime: 0.8,
		maxParticles: 34,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'ring',
		spawnCircle: {
			x: 0,
			y: 0,
			r: 151,
			minR: 150,
		},
	};

	public static readonly SPARK = {
		alpha: {
			start: 1,
			end: 0.0,
		},
		scale: {
			start: 2,
			end: 0.2,
			minimumScaleMultiplier: 0.2,
		},
		color: {
			start: '#FF8B19',
			end: '#FF8B19',
		},
		speed: {
			start: 200,
			end: 50,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 200,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.5,
			max: 0.7,
		},
		blendMode: 'add',
		frequency: 0.001,
		emitterLifetime: 0.1,
		maxParticles: 12,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'circle',
		spawnCircle: {
			x: 0,
			y: 0,
			r: 20,
		},
	};

	public static readonly GLOW = {
		alpha: {
			start: 0.7,
			end: 0.1,
		},
		scale: {
			start: 2.5,
			end: 0.3,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#FF8B19',
			end: '#FF8B19',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 0.001,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.4,
			max: 0.4,
		},
		blendMode: 'add',
		frequency: 0.8,
		emitterLifetime: 1,
		maxParticles: 1,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	}
}
