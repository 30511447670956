import { QuestRewardPreviewView } from './QuestRewardPreviewView';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardTypes } from '@src/types/RewardTypes';
import LocalizationStorage from '@main/LocalizationStorage';
import { AbstractRewardCards } from '@interfaces/AbstractRewardCards';
import { PromotableModel } from '@models/PromotableModel';

export class QuestRewardsPreviewView extends PIXI.Container {
	private static readonly OFFSET: number = 259;

	private readonly localizationStorage: LocalizationStorage;

	private rewards: AbstractReward[];

	private rewardPreviews: QuestRewardPreviewView[];

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
	}

	public init(
		rewards: AbstractReward[],
	): void {
		this.rewards = rewards;

		this.rewardPreviews = this.createRewardPreviews();

		if (this.rewardPreviews.length === 2) {
			this.rewardPreviews[0].x = -QuestRewardsPreviewView.OFFSET / 2;
			this.rewardPreviews[1].x = QuestRewardsPreviewView.OFFSET / 2;
		} else if (this.rewardPreviews.length > 2) {
			this.rewardPreviews.forEach((preview, i) => {
				preview.x = -QuestRewardsPreviewView.OFFSET + QuestRewardsPreviewView.OFFSET * i;
			});
		}

		this.addChild(...this.rewardPreviews);
	}

	private createRewardPreviews(): QuestRewardPreviewView[] {
		const previews: QuestRewardPreviewView[] = [];

		this.rewards.forEach((reward: AbstractReward) => {
			switch (reward.getType()) {
				case RewardTypes.CHARACTER:
				case RewardTypes.UPGRADE: {
					const cardsReward = reward as AbstractRewardCards<PromotableModel>;
					const rarity = cardsReward.getModel().getCardRarity();
					const cardsPreview = new QuestRewardPreviewView(
						reward.getKey(),
						reward.getType(),
						reward.getDisplayValue(),
						rarity,
					);
					previews.push(cardsPreview);
					break;
				}
				case RewardTypes.TOTEM:
				case RewardTypes.BOOST:
				case RewardTypes.TIMESKIP:
				case RewardTypes.RESOURCE: {
					const preview = new QuestRewardPreviewView(reward.getKey(), reward.getType(), reward.getDisplayValue());
					previews.push(preview);
					break;
				}
				case RewardTypes.VIDEO: {
					const displayValue = this.localizationStorage.getLocalizedString('#lootbox_preview_video_desc');
					const videoPreview = new QuestRewardPreviewView(reward.getKey(), reward.getType(), displayValue);
					previews.push(videoPreview);
					break;
				}
				default: {
					if (MODE_DEBUG) {
						throw Error(`Unsupported reward type: ${reward.getType()}`);
					}
				}
			}
		});

		return previews;
	}
}
