import { AbstractReward } from '@interfaces/AbstractReward';
import { BankOfferWindowViewSetter, BankWindowViewSetter, GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankModel, BankTabElementPricedType, BankTabElementWithOfferType } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';
import { BoostModel } from '@models/BoostModel';
import { ModelHelper } from '@models/ModelHelper';
import HardMoneyModel from '@models/money/HardMoneyModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import {
	BankOfferMoveFromOfferWindowType, BankOfferWindowOriginType,
	BankPriceResourceTypes,
	BankPurchaseOriginType,
	BankTabTypes,
} from '@src/types/BankTypes';
import { RewardDescriptionsWithTransactionIdType, RewardDescriptionType, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { GameConstants } from '@src/utils/GameConstants';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BoostActivateAction } from '@models/network/actions/bank/BoostActivateAction';
import { TimeskipActivateAction } from '@models/network/actions/bank/TimeskipActivateAction';
import { BuyInternalLotAction } from '@models/network/actions/bank/BuyInternalLotAction';
import { ClaimFreeBundleAction } from '@models/network/actions/bank/ClaimFreeBundleAction';
import { ApplyPurchaseAction } from '@models/network/actions/bank/ApplyPurchaseAction';
import { ApplyRechargeAction } from '@models/network/actions/bank/ApplyRechargeAction';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { TransactionResult, TransactionOptions } from '@src/types/TransactionTypes';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankUIViewHelper } from '@views/windows/bank/BankUIViewHelper';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { BankBoostConfirmWindowView } from '@views/windows/bank/animations/BankBoostApplyConfirmView';

export enum BankElementIdPrefix {
	BUNDLES = 'B',
	GEM_SHOP = 'X',
	SUBSCRIBE = 'S',
	RECHARGE = 'R',
}

export class BankWindowViewController extends PIXI.utils.EventEmitter
	implements BankWindowViewSetter, BankOfferWindowViewSetter, GameUIBaseViewSetter {
	private readonly localizationStorage: LocalizationStorage;
	private view: BankWindowView;
	private gameUIView: GameUIBaseView;
	private eventKey: string;

	constructor(
		private readonly bankModel: BankModel,
		private readonly bankSavesModel: BankSavesModel,
		private readonly bankPaymentSaveModel: BankPaymentSaveModel,
		private readonly bankLoader: BankLoader,
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		protected readonly networkRequestSender: NetworkRequestSender,
		private readonly transactionCreator: TransactionCreator,
		private readonly rewardFactory: RewardFactory,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly timeskipModels: Map<string, TimeskipModel>,
		private readonly serverTime: ServerTimeModel,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, () => {
			this.bankLoader.init();
			this.bankLoader.setUpdatesEnabled(false);
		});
	}

	public setEventKey(key: string): void {
		this.eventKey = key;
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUIView = view;
	}

	public setBankOfferWindowView(view: BankOfferWindowView): void {
		view.once(BankOfferWindowView.EVENT_BUTTON_BUY_CLICK, this.onButtonBuyBankOfferClick, this);
	}

	public setBankWindowView(view: BankWindowView): void {
		this.view = view;
		this.view.on(BankWindowView.EVENT_BUTTON_BUY_GEM_SHOP_ITEM_CLICK, this.onButtonBuyGemShopItemClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_BUY_RECHARGE_CLICK, this.onButtonBuyRechargeClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_BUY_BUNDLE_CLICK, this.onButtonBuyBundleClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_BUY_SUBSCRIBE_CLICK, this.onButtonBuySubscribeClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_CLAIM_SUBSCRIBE_REWARD_CLICK, this.onButtonClaimSubscribeRewardClick, this);

		this.view.on(BankWindowView.EVENT_BUTTON_BUY_BOOST_CLICK, this.onButtonBuyBoostClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_ACTIVATE_BOOST_CLICK, this.onButtonActivateBoostClick, this);

		this.view.on(BankWindowView.EVENT_BUTTON_BUY_TIMESKIP_CLICK, this.onButtonBuyTimeskipClick, this);
		this.view.on(BankWindowView.EVENT_BUTTON_ACTIVATE_TIMESKIP_CLICK, this.onButtonActivateTimeskipClick, this);

		this.view.on(BankWindowView.EVENT_WINDOW_CLOSED, () => {
			this.bankLoader.setUpdatesEnabled(true);
		});
	}

	protected async makeTransaction(
		data: BankTabElementPricedType,
		options: TransactionOptions,
		origin?: BankPurchaseOriginType,
	): Promise<RewardDescriptionsWithTransactionIdType> {
		let bankElementKeyWithPrefix: string;
		if (data instanceof BankBundleModel) {
			bankElementKeyWithPrefix = BankElementIdPrefix.BUNDLES;
		} else if (data instanceof BankSubscribeModel) {
			bankElementKeyWithPrefix = BankElementIdPrefix.SUBSCRIBE;
		} else if (data instanceof BankGemShopItemModel) {
			bankElementKeyWithPrefix = BankElementIdPrefix.GEM_SHOP;
		} else {
			throw new Error(`Unsupported bank data type '${data}`);
		}
		bankElementKeyWithPrefix += data.getKey();

		return this.transactionCreator.buy(bankElementKeyWithPrefix, data.getPrice(), options)
			.then((result: TransactionResult) => {
				let key: string;
				const bundleGroup = this.bankModel.getBankBundleGroupByBankTabElementKey(data.getViewKey());
				if (bundleGroup !== undefined) {
					key = bundleGroup.getKey();
				} else {
					key = data.getViewKey();
				}
				const bankOfferModel = this.bankModel.getBankOfferModelByBankTabElementKey(key);
				if (bankOfferModel) {
					this.bankSavesModel.setPurchaseBankOfferSuccess(
						bankOfferModel,
						origin,
						result.transactionId,
					);
				}

				this.bankPaymentSaveModel.updatePaymentData(
					data.getPrice(),
					this.serverTime.getCalculatedISOTime(),
				);

				this.bankSavesModel.setAnyPurchaseSuccess(
					data.getPrice(),
					result.transactionId,
					bankElementKeyWithPrefix,
				);

				const action = new ApplyPurchaseAction(result.notCommitedTransactionId, result.rewards);
				this.emit(BaseAction.EVENT_ACTION_CREATED, action);
				return result;
			});
	}

	private applyBankRecharge(id: string, rewards: RewardDescriptionType[]): void {
		const action = new ApplyRechargeAction(id, rewards);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private applyBankFreeBundle(bankElementKeyWithPrefix: string, rewards: RewardDescriptionType[]): void {
		const action = new ClaimFreeBundleAction(bankElementKeyWithPrefix, rewards);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private applyBankIngameResourceBundle(model: BankBundleModel, rewards: RewardDescriptionType[]): void {
		const price = model.getPrice();
		const priceResource = model.getPriceResource();

		if (priceResource === BankPriceResourceTypes.HARD_MONEY) {
			this.hardMoneyModel.subtract(price);
		} else if (priceResource === BankPriceResourceTypes.PRESTIGE_MONEY) {
			this.prestigeMoneyModel.subtract(price);
		} else {
			throw new Error(`Unsupported bundle priceResource ${priceResource}`);
		}

		const action = new BuyInternalLotAction(
			BankElementIdPrefix.BUNDLES + model.getKey(),
			priceResource,
			price.toString(),
			rewards,
		);

		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private async applyBankSubscribeClaim(bankElementKeyWithPrefix: string): Promise<RewardDescriptionType[]> {
		const result: { rewards: RewardDescriptionType[] } = await this.networkRequestSender.sendBankSubscribeRewardClaim(bankElementKeyWithPrefix);
		if (result && result.rewards) {
			return Promise.resolve(ModelHelper.mergeRewardDescriptions(result.rewards));
		}
		return Promise.reject();
	}

	private createRewards(rewardDescriptions: RewardDescriptionType[]): AbstractReward[] {
		const rewards: AbstractReward[] = [];
		rewardDescriptions.forEach((rewardDescription) => {
			const reward = this.rewardFactory.createReward(rewardDescription);
			rewards.push(reward);
		});
		return rewards;
	}

	private onButtonBuyBankOfferClick(
		bankTabElementWithOffer: BankTabElementWithOfferType,
		bankOfferModel: BankOfferModel,
		bankOfferWindowOrigin: BankOfferWindowOriginType,
	): void {
		const moveToWindowType = bankOfferModel.getMoveFromOfferWindowType();
		switch (moveToWindowType) {
			case BankOfferMoveFromOfferWindowType.BANK_WINDOW: {
				if (bankTabElementWithOffer instanceof BankBundleModel) {
					const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.BUNDLES });
					this.windowViewSystem.showWindow(window);
				} else if (bankTabElementWithOffer instanceof BankGemShopItemModel) {
					const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
					this.windowViewSystem.showWindow(window);
				} else {
					throw new Error(`Unsupported bankTabElement instance '${bankTabElementWithOffer}'`);
				}
				break;
			}
			case BankOfferMoveFromOfferWindowType.PURCHASE: {
				if (bankTabElementWithOffer instanceof BankBundleModel) {
					this.onButtonBuyBundleClick(bankTabElementWithOffer, { bankOfferWindowOrigin });
				} else if (bankTabElementWithOffer instanceof BankGemShopItemModel) {
					this.onButtonBuyOfferWithGemShopItemClick(bankTabElementWithOffer, { bankOfferWindowOrigin });
				} else {
					throw new Error(`Unsupported bankTabElementWithOffer instance ${bankTabElementWithOffer}`);
				}
				break;
			}
			default:
				throw new Error(`Unsupported moveToWindow type '${moveToWindowType}'`);
		}
	}

	private showRewardResultWindow(rewards: AbstractReward[]): void {
		const window = this.windowFactory.createRewardResultWindow(
			false,
			undefined,
			RewardResultWindowBgType.SUMMON,
		);
		window.init(rewards);
		this.windowViewSystem.showWindow(window);
	}

	private async onButtonBuyOfferWithGemShopItemClick(
		dataConfig: BankGemShopItemModel,
		purchaseOrigin?: BankPurchaseOriginType,
	): Promise<void> {
		this.bankModel.setObsolete(true);
		const options = this.createBankItemTransactionOptions(dataConfig);

		this.makeTransaction(dataConfig, options, purchaseOrigin)
			.then(async (rewardsAndTransactionId) => {
				const rewards = this.createRewards(rewardsAndTransactionId.rewards);
				if (rewards[0].getKey() === RewardResourceIdTypes.HARD_MONEY) {
					await this.gameUIView.playHardMoneyIncomeAnimation(
						this.gameUIView.toGlobal(new PIXI.Point(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y)),
					);
				} else {
					await this.gameUIView.playPrestigeMoneyIncomeAnimation(
						this.gameUIView.toGlobal(new PIXI.Point(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y)),
					);
				}
				rewards.forEach(reward => reward.collect());
				this.bankLoader.init();
			})
			.catch(() => this.bankModel.setObsolete(false));
	}

	private async onButtonBuyGemShopItemClick(
		dataConfig: BankGemShopItemModel,
		position: PIXI.Point,
		image: string,
		purchaseOrigin?: BankPurchaseOriginType,
	): Promise<void> {
		this.bankModel.setObsolete(true);
		const options = this.createBankItemTransactionOptions(dataConfig, image);

		this.makeTransaction(dataConfig, options, purchaseOrigin)
			.then(async (rewardsAndTransactionId) => {
				const rewards = this.createRewards(rewardsAndTransactionId.rewards);
				if (rewards[0].getKey() === RewardResourceIdTypes.HARD_MONEY) {
					await this.gameUIView.playHardMoneyIncomeAnimation(position, this.view);
				} else {
					await this.gameUIView.playPrestigeMoneyIncomeAnimation(position, this.view);
				}
				rewards.forEach(reward => reward.collect());
				this.bankLoader.init();
			})
			.catch(() => this.bankModel.setObsolete(false));
	}

	private onButtonBuyRechargeClick(rechargeModel: BankRechargeModel): void {
		const rechargeKey = rechargeModel.getKey();
		const rechargeRewards = this.bankSavesModel.getRechargeRewards(rechargeKey);

		this.applyBankRecharge(rechargeKey, rechargeRewards);

		this.bankSavesModel.setPurchaseRechargeSuccess(rechargeKey);

		const rewards = this.createRewards(rechargeRewards);
		this.showRewardResultWindow(rewards);

		this.bankLoader.init();
	}

	private async onButtonBuyBundleClick(model: BankBundleModel, purchaseOrigin?: BankPurchaseOriginType): Promise<void> {
		this.bankModel.setObsolete(true);

		const successHandler = (rewards: RewardDescriptionType[]): void => {
			this.bankSavesModel.updateBundlePurchaseSuccess(model.getKey());

			const rewardsModels = this.createRewards(rewards);
			this.showRewardResultWindow(rewardsModels);

			this.bankLoader.init();
		};

		if (model.isFreePrice()) {
			const rewards = this.bankSavesModel.getBundleRewards(model.getKey());
			this.applyBankFreeBundle(BankElementIdPrefix.BUNDLES + model.getKey(), rewards);
			successHandler(rewards);
		} else if (model.getPriceResource() !== BankPriceResourceTypes.REAL) {
			const rewards = this.bankSavesModel.getBundleRewards(model.getKey());
			this.applyBankIngameResourceBundle(model, rewards);
			successHandler(rewards);
		} else {
			const options: TransactionOptions = this.createBundleTransactionOptions(model);

			this.makeTransaction(model, options, purchaseOrigin)
				.then(rewardsAndTransactionId => successHandler(rewardsAndTransactionId.rewards))
				.catch(() => this.bankModel.setObsolete(false));
		}
	}

	private async onButtonBuySubscribeClick(model: BankSubscribeModel): Promise<void> {
		this.bankModel.setObsolete(true);
		const options: TransactionOptions = this.createBundleTransactionOptions(model);

		this.makeTransaction(model, options)
			.then((rewardsAndTransactionId) => {
				const rewards = this.createRewards(rewardsAndTransactionId.rewards);
				this.showRewardResultWindow(rewards);

				this.bankLoader.init();
			})
			.catch(() => this.bankModel.setObsolete(false));
	}

	private async onButtonClaimSubscribeRewardClick(model: BankSubscribeModel): Promise<void> {
		this.bankModel.setObsolete(true);

		this.applyBankSubscribeClaim(BankElementIdPrefix.SUBSCRIBE + model.getKey())
			.then((rewardDescriptions) => {
				const rewards = this.createRewards(rewardDescriptions);
				this.showRewardResultWindow(rewards);

				this.bankLoader.init();
			})
			.catch(() => this.bankModel.setObsolete(false));
	}

	private onButtonActivateBoostClick(shopItemModel: BankGemShopItemModel): void {
		const view = this.windowFactory.createBankBoostConfirmWindowView(shopItemModel);

		view.on(BankBoostConfirmWindowView.EVENT_BUTTON_ACTIVATE_CLICK, () => {
			this.onButtonActivateBoostClickConfirm(shopItemModel);
		});
		view.initActivateStage();

		this.windowViewSystem.showWindow(view);
	}

	private onButtonActivateBoostClickConfirm(shopItemModel: BankGemShopItemModel): void {
		const boostKey = shopItemModel.getRewardDescription().reward_id;
		const boostModel = this.boostModels.get(boostKey);

		boostModel.setTimeleft(boostModel.getTimeleft() + boostModel.getTime());
		boostModel.activate(this.serverTime.getCalculatedISOTime());

		const action = new BoostActivateAction(boostKey);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private onButtonBuyBoostClick(shopItemModel: BankGemShopItemModel): void {
		const view = this.windowFactory.createBankBoostConfirmWindowView(shopItemModel);

		view.on(BankBoostConfirmWindowView.EVENT_BUTTON_BUY_CLICK, () => {
			this.onButtonBuyBoostClickConfirm(shopItemModel);
		});
		view.initBuyStage(shopItemModel.getButtonPriceResource(), shopItemModel.getPrice());

		this.windowViewSystem.showWindow(view);
	}

	private onButtonBuyBoostClickConfirm(shopItemModel: BankGemShopItemModel): void {
		const boostKey = shopItemModel.getRewardDescription().reward_id;
		const boostModel = this.boostModels.get(boostKey);

		const priceResource = shopItemModel.getPriceResource();
		switch (priceResource) {
			case BankPriceResourceTypes.HARD_MONEY: {
				const price = Number(shopItemModel.getPrice());
				this.hardMoneyModel.subtract(price);
				break;
			}
			default:
				throw new Error(`Unsupported boost priceResource ${priceResource}`);
		}

		boostModel.acquire();
		const rewards: RewardDescriptionType[] = [shopItemModel.getRewardDescription()];
		if (shopItemModel.hasExtraRewards()) {
			rewards.push(...shopItemModel.getExtraRewardDescriptions());
		}
		const action = new BuyInternalLotAction(
			BankElementIdPrefix.GEM_SHOP + shopItemModel.getKey(),
			priceResource,
			shopItemModel.getPrice().toString(),
			rewards,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private async onButtonActivateTimeskipClick(dataConfig: BankGemShopItemModel): Promise<void> {
		const timeskipKey = dataConfig.getRewardDescription().reward_id;
		const timeskipModel = this.timeskipModels.get(timeskipKey);
		const amount = timeskipModel.getIncome();
		this.softMoneyModel.add(amount);
		timeskipModel.activate();
		const action = new TimeskipActivateAction(timeskipModel.getKey(), amount.toRawString());
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private onButtonBuyTimeskipClick(dataConfig: BankGemShopItemModel): void {
		const timeskipKey = dataConfig.getRewardDescription().reward_id;
		const timeskipModel = this.timeskipModels.get(timeskipKey);

		const priceResource = dataConfig.getPriceResource();
		switch (priceResource) {
			case BankPriceResourceTypes.HARD_MONEY: {
				const price = Number(dataConfig.getPrice());
				this.hardMoneyModel.subtract(price);
				break;
			}
			default:
				throw new Error(`Unsupported boost priceResource ${priceResource}`);
		}

		timeskipModel.acquire();
		const rewards: RewardDescriptionType[] = [dataConfig.getRewardDescription()];
		if (dataConfig.hasExtraRewards()) {
			rewards.push(...dataConfig.getExtraRewardDescriptions());
		}
		const action = new BuyInternalLotAction(
			BankElementIdPrefix.GEM_SHOP + dataConfig.getKey(),
			priceResource,
			dataConfig.getPrice().toString(),
			rewards,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private createBankItemTransactionOptions(item: BankGemShopItemModel, image?: string): TransactionOptions {
		const descriptionKey = BankUIViewHelper.resolveBankItemPurchaseDescriptionString(item.getRewardDescription(), this.eventKey);
		let currentImage: string;
		if (image === undefined) {
			currentImage = item.getRewardDescription().reward_id === RewardResourceIdTypes.HARD_MONEY
				? BankGemShopItemModel.DEFAULT_PURCHASE_HARD_MONEY_IMAGE
				: BankGemShopItemModel.DEFAULT_PURCHASE_PRESTIGE_MONEY_IMAGE;
		} else {
			currentImage = image;
		}
		if (item.hasExtraRewards()) {
			currentImage += '_extra';
		}
		const options: TransactionOptions = {
			image: currentImage,
			name: this.localizationStorage.getLocalizedString(descriptionKey),
			description: '',
			eventKey: this.eventKey,
		};

		return options;
	}

	private createBundleTransactionOptions(bundle: BankBundleModel | BankSubscribeModel): TransactionOptions {
		const options: TransactionOptions = {
			image: BankBundleModel.DEFAULT_PURCHASE_IMAGE,
			name: this.localizationStorage.getLocalizedString(bundle.getNameLocale()),
			description: BankBundleModel.DEFAULT_PURCHASE_DESCRIPTION,
			eventKey: this.eventKey,
		};

		return options;
	}
}
