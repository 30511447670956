import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBackgroundType, ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { EmailAreaInputView } from '@views/components/input/EmailAreaInputView';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { TextField } from '@views/components/text/TextField';

export class EnterEmailWindowView extends PopupWindowBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly emailTextArea: EmailAreaInputView;

	constructor() {
		super(0.9);

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 773;
		bg.height = 540;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;
		bg.y = -70;

		const labelTitle = new SizeableMultiColoredBitmapText(
			600,
			{ font: '40px wendyOneShadowBold' },
		);
		labelTitle.text = this.localizationStorage.getLocalizedString('#enter_email_window_title_label');
		labelTitle.anchor = 0.5;
		labelTitle.y = -270;

		const closeButton = new CloseButtonView();
		closeButton.position.set(336, -270);
		closeButton.on(ButtonBaseView.EVENT_CLICK, () => {
			this.emailTextArea.setText('');
			this.onClose();
		});

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 715;
		whiteBg.height = 250;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -75;

		const greyBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		greyBg.tint = 0x000000;
		greyBg.alpha = 0.12;
		greyBg.width = 704;
		greyBg.height = 104;
		greyBg.y = -7;

		this.emailTextArea = new EmailAreaInputView({
			bgWidth: 620,
		});
		this.emailTextArea.y = -127;
		this.emailTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_email_label'));

		const textInfo = new TextField(
			this.localizationStorage.getLocalizedString('#enter_email_window_info_label'),
			{ font: '22px wendyOne', tint: 0x6a7075 },
			550,
			83,
		);
		textInfo.anchor = 0.5;
		textInfo.y = -3;

		const buttonContinue = new ButtonWithLabelBaseView(
			ButtonWithLabelBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 388, 95),
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
			466,
		);
		buttonContinue.setTextLabel(this.localizationStorage.getLocalizedString('#enter_email_window_button_continue_label'));
		buttonContinue.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonContinueClick, this);
		buttonContinue.y = 120;

		this.mainContainer.addChild(
			bg as PIXI.DisplayObject,
			labelTitle,
			closeButton,
			whiteBg,
			greyBg,
			this.emailTextArea,
			textInfo,
			buttonContinue,
		);
	}

	public getEmail(): string {
		return this.emailTextArea.getText();
	}

	private onButtonContinueClick(): void {
		const inputEmailOk = this.emailTextArea.isValid();

		if (!inputEmailOk) {
			this.emailTextArea.onInvalidInput();
		}

		if (inputEmailOk) {
			this.onClose();
		}
	}
}
