import { PromotableModel } from '@models/PromotableModel';
import { CharacterModel } from '@models/CharacterModel';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { LevelStartCharacterCardView } from '@views/windows/levelStartWindow/LevelStartCharacterCardView';
import { LevelStartTotemCardView } from '@views/windows/levelStartWindow/LevelStartTotemCardView';
import { LevelStartUpgradeCardView } from '@views/windows/levelStartWindow/LevelStartUpgradeCardView';
import { LevelStartCardBackBaseView } from '@views/windows/levelStartWindow/LevelStartCardBackBaseView';
import { LevelStartCardBaseView } from '@views/windows/levelStartWindow/LevelStartCardBaseView';

export class LevelStartCardViewFactory extends PIXI.utils.EventEmitter {
	public static createCard(
		model: PromotableModel,
	): LevelStartCardBaseView<any> {
		let cardView;

		if (model instanceof CharacterModel) {
			const characterModel = (model as CharacterModel);
			cardView = new LevelStartCharacterCardView(
				characterModel,
			);
		} else if (model instanceof TotemModel) {
			const totemModel = (model as TotemModel);
			cardView = new LevelStartTotemCardView(
				totemModel,
				false,
			);
		} else if (model instanceof UpgradeModel) {
			const upgradeModel = (model as UpgradeModel);
			cardView = new LevelStartUpgradeCardView(
				upgradeModel,
			);
		} else {
			throw new Error(`Cannot resolve model with key='${model.getKey()}'`);
		}

		return cardView;
	}

	public static createCardBack(
		model: PromotableModel,
	): LevelStartCardBackBaseView {
		let backView: LevelStartCardBackBaseView;

		if (model instanceof CharacterModel) {
			backView = new LevelStartCardBackBaseView(
				'lock_bg_character_card',
				0.945,
				0.89,
			);
		} else if (model instanceof TotemModel) {
			backView = new LevelStartCardBackBaseView(
				'lock_bg_totem_card',
				1.05,
			);
		} else if (model instanceof UpgradeModel) {
			backView = new LevelStartCardBackBaseView(
				'lock_bg_upgrade_card',
				1.05,
			);
		} else {
			throw new Error(`Cannot resolve model with key='${model.getKey()}'`);
		}

		return backView;
	}
}
