import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';

export class SettingsFooterView extends PIXI.Container {
	public static readonly EVENT_BUTTON_LINK_1_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly visitSiteLabel: SizeableMultiColoredBitmapText;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);


		this.visitSiteLabel = new SizeableMultiColoredBitmapText(
			315,
			{ font: { size: 32, name: 'wendyOne' } },
		);
		this.visitSiteLabel.anchor = 0.5;
		this.visitSiteLabel.x = -110;

		const buttonLink3 = SettingsFooterView.createButton(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['discord_icon']));
		buttonLink3.on(ButtonBaseView.EVENT_CLICK, () => this.emit(SettingsFooterView.EVENT_BUTTON_LINK_1_CLICK), this);
		buttonLink3.x = 210;

		const blackout = new PIXI.Graphics();
		blackout.beginFill(0x000000, 0.5);
		blackout.drawRect(0, 0, 526, 70);
		blackout.endFill();
		blackout.pivot.set(blackout.width / 2, blackout.height / 2);

		this.addChild(
			blackout,
			buttonLink3,
			this.visitSiteLabel as PIXI.DisplayObject,
		);

		this.updateText();
	}

	private updateText(): void {
		this.visitSiteLabel.text = this.localizationStorage.getLocalizedString('#settings_footer_label');
	}

	private static createButton(icon: PIXI.Sprite): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_icons_bg'], 11, 0, 11, 0);
		buttonBg.width = 57;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);
		button.addChild(icon);

		return button;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
