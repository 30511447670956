import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { LeagueBotModel } from '@models/LeagueBotModel';
import { RankingListItemView } from './RankingListItemView';
import { AbstractReward } from '@interfaces/AbstractReward';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { ScrollView } from '@views/components/ScrollView';
import { ScrollAxis } from '@src/types/ScrollViewTypes';

export class RankingListView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly contentContainer: PIXI.Container;
	private readonly playerListItem: RankingListItemView;

	constructor(
		width: number,
		height: number,
		username: string,
		incomePerSec: SoftMoneyNumber,
		botsSorted: LeagueBotModel[],
		leagueRewards: AbstractReward[][],
		cardMiniViewFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
		playerListItemId: number,
		eventRunningTime: number,
	) {
		super();

		this.contentContainer = new PIXI.Container();

		const playerLeaguePlaceRewards = leagueRewards?.[playerListItemId] || [];
		this.playerListItem = new RankingListItemView(
			playerLeaguePlaceRewards,
			cardMiniViewFactory,
			runInEpisodeAssetsSandbox,
		);
		this.playerListItem.setTextIncome(incomePerSec.toString());
		this.playerListItem.setUsername(username);
		this.playerListItem.interactive = true;
		this.playerListItem.on(
			'pointertap',
			() => this.emit(RankingListView.EVENT_CLICK),
		);

		let botLeagueRewardsIdOffset = 0;
		const botListItemViews = botsSorted.map((bot, botListItemId) => {
			let botLeaguePlaceRewards;

			if (botListItemId === playerListItemId) {
				botLeaguePlaceRewards = leagueRewards?.[botListItemId + 1] || [];
				botLeagueRewardsIdOffset = 1;
			} else {
				botLeaguePlaceRewards = leagueRewards?.[botListItemId + botLeagueRewardsIdOffset] || [];
			}

			const listItem = new RankingListItemView(
				botLeaguePlaceRewards,
				cardMiniViewFactory,
				runInEpisodeAssetsSandbox,
			);

			listItem.interactive = true;
			listItem.on(
				'pointertap',
				() => this.emit(RankingListView.EVENT_CLICK, bot),
			);
			const score = bot.getScore(eventRunningTime);
			listItem.setTextIncome(score.toString());
			listItem.setUsername(bot.getName());

			return listItem;
		});

		if (playerListItemId === -1) {
			botListItemViews.push(this.playerListItem);
		} else {
			botListItemViews.splice(playerListItemId, 0, this.playerListItem);
		}
		const contentPanelsOffset = 109;

		botListItemViews.forEach((listItemView, i) => {
			const leaguePlace = i + 1;
			listItemView.setTextLeaguePlace(leaguePlace.toString());
			listItemView.setLeaguePlace(leaguePlace);
			listItemView.x = width / 2;
			listItemView.y = contentPanelsOffset * i;
		});

		this.contentContainer.addChild(
			...botListItemViews,
		);

		const scrollView: ScrollView = new ScrollView(
			width, height, ScrollAxis.VERTICAL, { begin: 52, end: 52 },
		).setContent(this.contentContainer);
		scrollView.position.set(-width / 2, -104 / 2);
		scrollView.jumpTo(52);
		this.addChild(scrollView);
	}

	public setPlayerUsername(value: string): void {
		this.playerListItem.setUsername(value);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		super.destroy(options);
	}
}
