import { MaintenanceModel } from '@models/MaintenanceModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';

export class MaintenanceTicker {
	private readonly ticker: PIXI.ticker.Ticker;

	private timerTicking: boolean;
	private enabled: boolean;

	constructor(
		private readonly model: MaintenanceModel,
		private readonly serverTimeModel: ServerTimeModel,
	) {
		this.ticker = PIXI.ticker.shared;
		this.timerTicking = false;

		this.model.on(MaintenanceModel.EVENT_MAINTENANCE_TIME_UPDATE, this.onMaintenanceTimeUpdated, this);
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (this.enabled && this.timerTicking) {
			this.startTimer();
		} else if (!this.enabled && this.timerTicking) {
			this.stopTimer();
		}
	}

	private onMaintenanceTimeUpdated(): void {
		const maintenanceTime = this.model.getCurrentMaintenanceTime();
		const maintenanceShowTime = this.model.getMaintenanceShowTime();
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		const timeBeforeMaintenance = maintenanceTime - currentTime;
		const upcomingMaintenance = maintenanceShowTime >= timeBeforeMaintenance;

		if (upcomingMaintenance && !this.timerTicking) {
			this.timerTicking = true;

			if (this.enabled) {
				this.startTimer();
			}
		} else if (!upcomingMaintenance && this.timerTicking) {
			this.timerTicking = false;
			this.stopTimer();
			this.model.setCurrentTimeBeforeMaintenance(0);
		}
	}

	private onTimerUpdate(): void {
		const maintenanceTime = this.model.getCurrentMaintenanceTime();
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		const timeBeforeMaintenance = maintenanceTime - currentTime;

		this.model.setCurrentTimeBeforeMaintenance(timeBeforeMaintenance);

		if (timeBeforeMaintenance <= 0) {
			this.timerTicking = false;
			this.stopTimer();
		}
	}

	private startTimer(): void {
		this.ticker.add(this.onTimerUpdate, this);
	}

	private stopTimer(): void {
		this.ticker.remove(this.onTimerUpdate, this);
	}
}
