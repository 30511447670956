import { BusinessConfig } from '@configs/BusinessConfig';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BusinessSaveData } from '@src/types/SaveTypes';
import { MaxButtonValueData } from '@configs/ConstantsConfig';
import Decimal from 'decimal.js';

type CustomerProgress = { current: number; total: number };

export type CustomerMultiplierType = {
	customerReach: number;
	multiplier: number;
};

export type IncomeProgressType = {
	elapsed: number;
	elapsedReduced: number;
};

export class BusinessModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_ACQUIRED: symbol = Symbol();
	public static readonly EVENT_AUTOMATED: symbol = Symbol();
	public static readonly EVENT_INCOME_VALUE_CHANGED: symbol = Symbol();
	public static readonly EVENT_INCOME_TIME_INTERVAL_CHANGED: symbol = Symbol();
	public static readonly EVENT_REDUCE_TIME_INCOME_PER_TAP_CHANGED: symbol = Symbol();
	public static readonly EVENT_INCOME_PROGRESS_COMPLETE: symbol = Symbol();

	public static readonly EVENT_NEW_CUSTOMERS: symbol = Symbol();
	public static readonly EVENT_NEW_CUSTOMERS_MULTIPLIER: symbol = Symbol();

	public static readonly INITIAL_CUSTOMER_COUNT = 1;

	private static readonly PRECISION_INCOME: number = 2;

	private customerMultipliers: CustomerMultiplierType[];

	private key: string;
	private acquired: boolean;
	private acquireCost: SoftMoneyNumber;
	private customerCount: number;
	private nextCustomerMultiplierIndex: number;
	private automated: boolean;
	private available: boolean;

	private baseIncome: SoftMoneyNumber;

	private unlocksOnLevel: number;

	private baseTimeIncomeInterval: number;

	private reduceTimeIncomeByClick: number;

	private multipliersIncomeCharacters: Map<string, number>;
	private multipliersIncomeCharactersAffectAll: Map<string, number>;
	private multipliersIncomeUpgrades: Map<string, number>;
	private multipliersIncomeTotems: Map<string, number>;
	private multipliersIncomeBoosts: Map<string, number>;
	private multiplierIncomeCharacters: number;
	private multiplierIncomeCharactersAffectAll: number;
	private multiplierIncomeUpgrades: number;
	private multiplierIncomeTotems: number;
	private multiplierIncomeBoosts: number;
	private multiplierIncomeFarewellBoost: number;

	private multipliersTimeCharacters: Map<string, number>;
	private multipliersTimeCharactersAffectAll: Map<string, number>;
	private multipliersTimeUpgrades: Map<string, number>;
	private multipliersTimeTotems: Map<string, number>;
	private multiplierTimeCharacters: number;
	private multiplierTimeCharactersAffectAll: number;
	private multiplierTimeUpgrades: number;
	private multiplierTimeTotems: number;

	private multipliersDecreaseCustomerCostCharacters: Map<string, number>;
	private multipliersDecreaseCustomerCostCharactersAffectAll: Map<string, number>;
	private multipliersDecreaseCustomerCostUpgrades: Map<string, number>;
	private multipliersDecreaseCustomerCostTotems: Map<string, number>;
	private multiplierDecreaseCustomerCostCharacters: number;
	private multiplierDecreaseCustomerCostCharactersAffectAll: number;
	private multiplierDecreaseCustomerCostUpgrades: number;
	private multiplierDecreaseCustomerCostTotems: number;
	private multiplierDecreaseCustomerCostFull: number;

	private upgradesReduceTimeIncomeByClick: Map<string, number>;
	private totemsReduceTimeIncomeByClick: Map<string, number>;
	private upgradeReduceTimeIncomeByClick: number;
	private totemReduceTimeIncomeByClick: number;

	private customerBaseCost: SoftMoneyNumber;
	private customerCostIncreaseArray: number[];
	private customerCostIncrease: number;

	private characterKeys: string[];

	private upgradeKeys: string[];

	private customerMultiplier: number;

	private skillProfitImproveMultiplier: number;
	private skillTimeImproveDivisor: number;
	private skillConstantProfitMultiplier: number;
	private skillTapAddMoneyValue: number;

	private incomeProgress: number;
	private incomeProgressDeltaReduce: number;
	private incomeProgressCompleted: boolean;

	private oneCustomerCost: SoftMoneyNumber;

	constructor() {
		super();

		this.acquired = false;
		this.customerCount = 0;
		this.nextCustomerMultiplierIndex = 1;
		this.automated = false;

		this.customerMultiplier = 1;
		this.customerCostIncreaseArray = [];
		this.customerCostIncrease = 1;
		this.oneCustomerCost = SoftMoneyNumber.ONE;

		this.available = false;

		this.skillProfitImproveMultiplier = 1;
		this.skillTimeImproveDivisor = 1;
		this.skillConstantProfitMultiplier = 1;
		this.skillTapAddMoneyValue = 0;

		this.multipliersIncomeCharacters = new Map();
		this.multipliersIncomeCharactersAffectAll = new Map<string, number>();
		this.multipliersIncomeUpgrades = new Map();
		this.multipliersIncomeTotems = new Map();
		this.multipliersIncomeBoosts = new Map();
		this.multiplierIncomeCharacters = 1;
		this.multiplierIncomeCharactersAffectAll = 1;
		this.multiplierIncomeUpgrades = 1;
		this.multiplierIncomeBoosts = 1;
		this.multiplierIncomeTotems = 1;
		this.multiplierIncomeFarewellBoost = 1;

		this.multipliersTimeCharacters = new Map();
		this.multipliersTimeCharactersAffectAll = new Map<string, number>();
		this.multipliersTimeUpgrades = new Map();
		this.multipliersTimeTotems = new Map();
		this.multiplierTimeCharacters = 1;
		this.multiplierTimeCharactersAffectAll = 1;
		this.multiplierTimeUpgrades = 1;
		this.multiplierTimeTotems = 1;

		this.multipliersDecreaseCustomerCostCharacters = new Map();
		this.multipliersDecreaseCustomerCostUpgrades = new Map();
		this.multipliersDecreaseCustomerCostTotems = new Map();
		this.multipliersDecreaseCustomerCostCharactersAffectAll = new Map<string, number>();
		this.multiplierDecreaseCustomerCostCharacters = 0;
		this.multiplierDecreaseCustomerCostUpgrades = 0;
		this.multiplierDecreaseCustomerCostTotems = 0;
		this.multiplierDecreaseCustomerCostCharactersAffectAll = 1;
		this.multiplierDecreaseCustomerCostFull = 1;

		this.upgradesReduceTimeIncomeByClick = new Map();
		this.totemsReduceTimeIncomeByClick = new Map();
		this.upgradeReduceTimeIncomeByClick = 1;
		this.totemReduceTimeIncomeByClick = 1;

		this.incomeProgress = 0;
		this.incomeProgressDeltaReduce = 0;
		this.incomeProgressCompleted = false;
	}

	public setFromConfig(configBase: BusinessConfig): void {
		this.key = configBase.getKey();

		this.unlocksOnLevel = configBase.getUnlocksOnLevel();

		this.baseIncome = configBase.getBaseIncome();
		this.baseTimeIncomeInterval = configBase.getBaseTimeIncomeInterval();

		this.customerBaseCost = configBase.getCustomerBaseCost();

		this.acquireCost = configBase.getAcquireCost();

		this.characterKeys = configBase.getCharacterKeys();

		this.upgradeKeys = configBase.getUpgradeKeys();

		this.reduceTimeIncomeByClick = configBase.getReduceTimeIncomeByClick();

		this.customerMultipliers = configBase.getCustomerReaches()
			.map(value => ({ customerReach: value, multiplier: undefined }));
	}

	public setFromSaveData(saveData: BusinessSaveData): void {
		this.acquired = true;
		this.customerCount = saveData.customers;

		this.incomeProgress = saveData.incomeProgress;
		if (this.incomeProgress >= 1) {
			this.incomeProgressCompleted = true;
		}
	}

	public setCustomerCostIncreaseArray(target: number[]): void {
		this.customerCostIncreaseArray = [];
		target.forEach((value, i) => {
			this.customerCostIncreaseArray[i] = value;
		});

		this.updateCustomerCostIncrease();
		this.updateOneCustomerCostSoft();
	}

	public setAvailable(available: boolean = true): BusinessModel {
		this.available = available;
		return this;
	}

	public setCustomerMultipliers(customerMultipliers: number[]): BusinessModel {
		customerMultipliers.forEach((multiplier, i) => { this.customerMultipliers[i].multiplier = multiplier; });
		const leftOverId: number = this.customerMultipliers.length - customerMultipliers.length;
		for (let i = 0; i < leftOverId; i++) {
			this.customerMultipliers[customerMultipliers.length + i].multiplier = customerMultipliers[customerMultipliers.length - 1];
		}
		this.customerMultiplier = this.calculateCustomerMultiplier();

		while (this.nextCustomerMultiplierIndex < this.customerMultipliers.length
			&& this.customerMultipliers[this.nextCustomerMultiplierIndex].customerReach <= this.customerCount) {
			this.nextCustomerMultiplierIndex += 1;
		}

		this.updateCustomerCostIncrease();
		this.updateOneCustomerCostSoft();
		return this;
	}

	public getCharacterKeys(): string[] {
		return this.characterKeys;
	}

	public hasCharacter(requiredCharacterKey: string): boolean {
		return this.characterKeys.some(characterKey => characterKey === requiredCharacterKey);
	}

	public resetModel(): void {
		this.acquired = false;
		this.customerCount = 0;
		this.automated = false;
		this.nextCustomerMultiplierIndex = 1;
		this.customerMultiplier = 1;
		this.customerCostIncrease = 1;
		this.oneCustomerCost = SoftMoneyNumber.ONE;

		this.multipliersIncomeCharacters.clear();
		this.multiplierIncomeCharacters = 1;
		this.multipliersIncomeUpgrades.clear();
		this.multiplierIncomeUpgrades = 1;
		this.multipliersIncomeCharactersAffectAll.clear();
		this.multiplierIncomeCharactersAffectAll = 1;

		this.multipliersTimeCharacters.clear();
		this.multiplierTimeCharacters = 1;
		this.multipliersTimeUpgrades.clear();
		this.multiplierTimeUpgrades = 1;
		this.multipliersTimeCharactersAffectAll.clear();
		this.multiplierTimeCharactersAffectAll = 1;

		this.multipliersDecreaseCustomerCostCharacters.clear();
		this.multiplierDecreaseCustomerCostCharacters = 0;
		this.multipliersDecreaseCustomerCostCharactersAffectAll.clear();
		this.multiplierDecreaseCustomerCostCharactersAffectAll = 1;
		this.multipliersDecreaseCustomerCostUpgrades.clear();
		this.multiplierDecreaseCustomerCostUpgrades = 0;
		this.multipliersDecreaseCustomerCostTotems.clear();
		this.multiplierDecreaseCustomerCostTotems = 0;
		this.multiplierDecreaseCustomerCostFull = 1;

		this.upgradesReduceTimeIncomeByClick.clear();
		this.upgradeReduceTimeIncomeByClick = 1;

		this.skillConstantProfitMultiplier = 1;

		this.incomeProgress = 0;
		this.incomeProgressDeltaReduce = 0;
	}

	public isAffectedByCharacter(characterKey: string): boolean {
		return this.characterKeys.includes(characterKey);
	}

	public setCharacterMultiplierDecreaseCustomerCost(key: string, multiplier: number): void {
		this.multipliersDecreaseCustomerCostCharacters.set(key, multiplier);
		this.multiplierDecreaseCustomerCostCharacters = this.calculateMultiplierDecreaseCustomerCostPart(this.multipliersDecreaseCustomerCostCharacters);
		this.updateMultiplierDecreaseCustomerCostFull();
		this.updateOneCustomerCostSoft();
	}

	public setCharacterMultiplierDecreaseCustomerCostAffectAll(key: string, multiplier: number): void {
		this.multipliersDecreaseCustomerCostCharactersAffectAll.set(key, multiplier);
		this.multiplierDecreaseCustomerCostCharactersAffectAll = this.calculateMultiplierDecreaseCustomerCostPartAffectAll();
		this.updateMultiplierDecreaseCustomerCostFull();
		this.updateOneCustomerCostSoft();
	}

	public setCharacterMultiplierIncome(key: string, multiplier: number): void {
		this.multipliersIncomeCharacters.set(key, multiplier);
		this.multiplierIncomeCharacters = this.calculateMultiplierIncomeCharacters();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public setCharacterMultiplierIncomeAffectAll(key: string, multiplier: number): void {
		this.multipliersIncomeCharactersAffectAll.set(key, multiplier);
		this.multiplierIncomeCharactersAffectAll = this.calculateMultiplierIncomeCharactersAffectAll();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public setCharacterMultiplierTime(key: string, multiplier: number): void {
		this.multipliersTimeCharacters.set(key, multiplier);
		this.multiplierTimeCharacters = this.calculateMultiplierTimeCharacters();

		this.emit(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this);
	}

	public setCharacterMultiplierTimeAffectAll(key: string, multiplier: number): void {
		this.multipliersTimeCharactersAffectAll.set(key, multiplier);
		this.multiplierTimeCharactersAffectAll = this.calculateMultiplierTimeCharactersAffectAll();

		this.emit(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this);
	}

	private calculateMultiplierTimeCharacters(): number {
		let result = 1;
		if (this.multipliersTimeCharacters.size !== 0) {
			result = Array.from(this.multipliersTimeCharacters.values()).reduce((a, b) => a + b);
		}
		return result;
	}

	private calculateMultiplierTimeCharactersAffectAll(): number {
		let result = 1;
		if (this.multipliersTimeCharactersAffectAll.size > 0) {
			result = Array.from(this.multipliersTimeCharactersAffectAll.values()).reduce((a, b) => a * b);
		}
		return result;
	}

	private calculateMultiplierIncomeCharacters(): number {
		let result = 1;
		if (this.multipliersIncomeCharacters.size !== 0) {
			result = Array.from(this.multipliersIncomeCharacters.values()).reduce((a, b) => a + b);
		}
		return result;
	}

	private calculateMultiplierIncomeCharactersAffectAll(): number {
		let result: number = 1;
		if (this.multipliersIncomeCharactersAffectAll.size > 0) {
			result = Array.from(this.multipliersIncomeCharactersAffectAll.values()).reduce((a, b) => a * b);
		}
		return result;
	}

	public getKey(): string {
		return this.key;
	}

	public isAvailable(): boolean {
		return this.available;
	}

	public getUpgradeKeys(): string[] {
		return this.upgradeKeys;
	}

	public getUnlocksOnLevel(): number {
		return this.unlocksOnLevel;
	}

	public isAcquired(): boolean {
		return this.acquired;
	}

	public acquire(): void {
		this.customerCount = BusinessModel.INITIAL_CUSTOMER_COUNT;
		this.acquired = true;

		this.emit(BusinessModel.EVENT_ACQUIRED, this);
	}

	public getAcquireCost(): SoftMoneyNumber {
		return this.acquireCost;
	}

	/* UPGRADES */
	public hasUpgrade(upgradeKey: string): boolean {
		return this.upgradeKeys.some(key => upgradeKey === key);
	}

	public setUpgradeMultiplierIncome(upgradeKey: string, multiplier: number): void {
		this.multipliersIncomeUpgrades.set(upgradeKey, multiplier);
		this.updateMultiplierIncomeUpgrades();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	private updateMultiplierIncomeUpgrades(): void {
		let result = 1;
		if (this.multipliersIncomeUpgrades.size !== 0) {
			result = Array.from(this.multipliersIncomeUpgrades.values()).reduce((a, b) => a + b);
		}
		this.multiplierIncomeUpgrades = result;
	}

	public setUpgradeMultiplierTime(upgradeKey: string, multiplier: number): void {
		this.multipliersTimeUpgrades.set(upgradeKey, multiplier);
		this.updateMultiplierTimeUpgrades();

		this.emit(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this);
	}

	private updateMultiplierTimeUpgrades(): void {
		let result = 1;
		if (this.multipliersTimeUpgrades.size !== 0) {
			result = Array.from(this.multipliersTimeUpgrades.values()).reduce((a, b) => a + b);
		}
		this.multiplierTimeUpgrades = result;
	}

	public setUpgradeMultiplierDecreaseCustomerCost(upgradeKey: string, multiplier: number): void {
		this.multipliersDecreaseCustomerCostUpgrades.set(upgradeKey, multiplier);

		this.multiplierDecreaseCustomerCostUpgrades = this.calculateMultiplierDecreaseCustomerCostPart(this.multipliersDecreaseCustomerCostUpgrades);
		this.updateMultiplierDecreaseCustomerCostFull();
		this.updateOneCustomerCostSoft();
	}

	public setUpgradeReducePercentIncomeByClick(upgradeKey: string, value: number): void {
		this.upgradesReduceTimeIncomeByClick.set(upgradeKey, value);
		this.updateReducePercentIncomeByClickUpgrades();
	}

	private updateReducePercentIncomeByClickUpgrades(): void {
		let result = 1;
		if (this.upgradesReduceTimeIncomeByClick.size !== 0) {
			result = Array.from(this.upgradesReduceTimeIncomeByClick.values()).reduce((a, b) => a + b);
		}
		this.upgradeReduceTimeIncomeByClick = result;

		this.emit(BusinessModel.EVENT_REDUCE_TIME_INCOME_PER_TAP_CHANGED);
	}

	/* TOTEMS */
	public setTotemMultiplierIncome(totemKey: string, multiplier: number): void {
		this.multipliersIncomeTotems.set(totemKey, multiplier);
		this.updateMultiplierIncomeTotems();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	private updateMultiplierIncomeTotems(): void {
		let result = 1;
		if (this.multipliersIncomeTotems.size !== 0) {
			result = Array.from(this.multipliersIncomeTotems.values()).reduce((a, b) => a + b);
		}
		this.multiplierIncomeTotems = result;
	}

	public setTotemMultiplierTime(totemKey: string, multiplier: number): void {
		this.multipliersTimeTotems.set(totemKey, multiplier);
		this.updateMultiplierTimeTotems();

		this.emit(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this);
	}

	private updateMultiplierTimeTotems(): void {
		let result = 1;
		if (this.multipliersTimeTotems.size !== 0) {
			result = Array.from(this.multipliersTimeTotems.values()).reduce((a, b) => a + b);
		}
		this.multiplierTimeTotems = result;
	}

	public setTotemMultiplierDecreaseCustomerCost(totemKey: string, multiplier: number): void {
		this.multipliersDecreaseCustomerCostTotems.set(totemKey, multiplier);

		this.multiplierDecreaseCustomerCostTotems = this.calculateMultiplierDecreaseCustomerCostPart(this.multipliersDecreaseCustomerCostTotems);
		this.updateMultiplierDecreaseCustomerCostFull();
		this.updateOneCustomerCostSoft();
	}

	public setTotemReducePercentIncomeByClick(totemKey: string, value: number): void {
		this.totemsReduceTimeIncomeByClick.set(totemKey, value);
		this.updateReducePercentIncomeByClickTotems();
	}

	private updateReducePercentIncomeByClickTotems(): void {
		let result = 1;
		if (this.totemsReduceTimeIncomeByClick.size !== 0) {
			result = Array.from(this.totemsReduceTimeIncomeByClick.values()).reduce((a, b) => a + b);
		}
		this.totemReduceTimeIncomeByClick = result;

		this.emit(BusinessModel.EVENT_REDUCE_TIME_INCOME_PER_TAP_CHANGED);
	}

	/* FAREWELL BOOST */
	public setFarwellBoostMultiplierIncome(value: number): void {
		this.multiplierIncomeFarewellBoost = value;
		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public clearFarewellBoostMultiplierIncome(): void {
		this.multiplierIncomeFarewellBoost = 1;
		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	/* BOOSTS */
	public setBoostMultiplierIncome(boostKey: string, value: number): void {
		this.multipliersIncomeBoosts.set(boostKey, value);
		this.updateMultiplierIncomeBoosts();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public clearBoostMultiplierIncome(boostKey: string): void {
		this.multipliersIncomeBoosts.delete(boostKey);
		this.updateMultiplierIncomeBoosts();

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	private updateMultiplierIncomeBoosts(): void {
		let result = 1;
		if (this.multipliersIncomeBoosts.size !== 0) {
			result = Array.from(this.multipliersIncomeBoosts.values()).reduce((a, b) => a + b);
		}
		this.multiplierIncomeBoosts = result;
	}

	// eslint-disable-next-line class-methods-use-this
	private calculateMultiplierDecreaseCustomerCostPart(multipliers: Map<string, number>): number {
		let result = 0;
		multipliers.forEach((multiplier) => { result += multiplier; });
		return result;
	}

	private calculateMultiplierDecreaseCustomerCostPartAffectAll(): number {
		let result: number = 1;
		if (this.multipliersDecreaseCustomerCostCharactersAffectAll.size > 0) {
			result = Array.from(this.multipliersDecreaseCustomerCostCharactersAffectAll.values()).reduce((a, b) => a * b);
		}
		return result;
	}

	private updateMultiplierDecreaseCustomerCostFull(): void {
		const sum = (this.multiplierDecreaseCustomerCostUpgrades
			+ this.multiplierDecreaseCustomerCostCharacters
			+ this.multiplierDecreaseCustomerCostTotems) * this.multiplierDecreaseCustomerCostCharactersAffectAll;

		if (sum === 0) {
			this.multiplierDecreaseCustomerCostFull = 1;
		} else {
			this.multiplierDecreaseCustomerCostFull = 1 / sum;
		}
	}

	/* INCOME */
	public getBusinessIncome(
		getPotentialIfNotAcquired: boolean = false,
		noSkills: boolean = false,
		noBoosts: boolean = false,
	): SoftMoneyNumber {
		let { customerCount } = this;
		if (getPotentialIfNotAcquired && !this.isAcquired()) {
			customerCount = 1;
		}

		const incomeNoSkills = this.baseIncome.multiply(
			customerCount
			* this.getMultiplierIncome(noBoosts)
			* this.customerMultiplier,
		);

		if (noSkills) {
			return incomeNoSkills;
		}

		return incomeNoSkills.multiply(
			this.skillProfitImproveMultiplier
			* this.skillConstantProfitMultiplier,
		);
	}

	public getBusinessIncomePerSec(
		getPotentialIfNotAcquired: boolean = false,
		noSkills: boolean = false,
		noBoosts: boolean = false,
	): SoftMoneyNumber {
		const income: SoftMoneyNumber = this.getBusinessIncome(getPotentialIfNotAcquired, noSkills, noBoosts);
		const timeInterval: number = this.getTimeIncomeInterval(noSkills);
		return income.multiply(1 / timeInterval).round();
	}

	/**
	 * @returns {number} time in seconds
	 */
	public getTimeIncomeInterval(noSkills: boolean = false): number {
		const valueNoSkills = this.baseTimeIncomeInterval / this.getMultiplierTime();

		if (noSkills) {
			return valueNoSkills;
		}

		const valueWithSkills = valueNoSkills / this.skillTimeImproveDivisor;

		return valueWithSkills;
	}

	public getReduceTimeIncomePerTap(): number {
		return this.reduceTimeIncomeByClick * this.getReduceTimeIncomePerTapMultiplier();
	}

	public getReduceTimeIncomePerTapMultiplier(): number {
		return this.upgradeReduceTimeIncomeByClick * this.totemReduceTimeIncomeByClick;
	}

	public getAdditionalMoneyPerTap(): SoftMoneyNumber {
		return this.getBusinessIncome().multiply(this.skillTapAddMoneyValue);
	}

	public isAddMoneyPerTap(): boolean {
		return this.skillTapAddMoneyValue > 0;
	}

	public getMultiplierIncome(noBoosts?: boolean): number {
		const resultNoBoosts = this.multiplierIncomeCharacters * this.multiplierIncomeUpgrades * this.multiplierIncomeTotems
			* this.multiplierIncomeCharactersAffectAll;

		if (noBoosts) {
			return Number(resultNoBoosts.toFixed(BusinessModel.PRECISION_INCOME));
		}

		const result = resultNoBoosts * this.multiplierIncomeBoosts * this.multiplierIncomeFarewellBoost;

		return Number(result.toFixed(BusinessModel.PRECISION_INCOME));
	}

	public getMultiplierTime(): number {
		const result = this.multiplierTimeCharacters * this.multiplierTimeUpgrades * this.multiplierTimeTotems
			* this.multiplierTimeCharactersAffectAll;
		return result;
	}

	/* AUTOMATE */
	public setAutomated(): void {
		this.automated = true;
	}

	public automate(): void {
		this.automated = true;

		this.emit(BusinessModel.EVENT_AUTOMATED, this);
	}

	public isAutomated(): boolean {
		return this.automated;
	}

	/* CUSTOMERS */
	public buyNewCustomers(count: number): void {
		this.customerCount += count;
		const { customerReach } = this.customerMultipliers[this.nextCustomerMultiplierIndex];

		let newCustomerMultiplier: boolean = false;
		if (this.customerCount >= customerReach) {
			this.customerMultiplier = this.calculateCustomerMultiplier();

			for (let i = 0; i < this.customerMultipliers.length; i++) {
				this.nextCustomerMultiplierIndex = i;
				if (this.customerMultipliers[i].customerReach > this.customerCount) {
					break;
				}
			}
			newCustomerMultiplier = true;

			this.updateCustomerCostIncrease();
		}

		this.updateOneCustomerCostSoft();

		this.emit(BusinessModel.EVENT_NEW_CUSTOMERS, this, count);

		if (newCustomerMultiplier) {
			this.emit(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this);
		}

		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public getCustomerCount(): number {
		return this.customerCount;
	}

	public isMaxCustomers(): boolean {
		const maxCustomers = this.customerMultipliers[this.customerMultipliers.length - 1].customerReach;
		return this.customerCount >= maxCustomers;
	}

	public getMaxCustomers(): number {
		const maxCustomers = this.customerMultipliers[this.customerMultipliers.length - 1].customerReach;
		return maxCustomers;
	}

	public getCustomersCountForNextMultiplier(): CustomerProgress {
		const total = this.nextCustomerMultiplierIndex > this.customerMultipliers.length - 1
			? this.customerCount
			: this.customerMultipliers[this.nextCustomerMultiplierIndex].customerReach;

		const progress = {
			current: this.customerCount,
			total,
		};

		if (this.nextCustomerMultiplierIndex > 1) {
			progress.current -= this.customerMultipliers[this.nextCustomerMultiplierIndex - 1].customerReach;
			progress.total -= this.customerMultipliers[this.nextCustomerMultiplierIndex - 1].customerReach;
		}
		return progress;
	}

	public getMaxCustomersCountData(param: MaxButtonValueData, currentSoftMoneyValue: SoftMoneyNumber): { cost: SoftMoneyNumber; count: number } {
		const customerCountForNextMultiplier: CustomerProgress = this.getCustomersCountForNextMultiplier();

		let resultCount: number = 1;
		let resultCost: SoftMoneyNumber;
		let targetCount: number;
		if (param.isMax) {
			targetCount = customerCountForNextMultiplier.total - customerCountForNextMultiplier.current;

			const bc = this.customerBaseCost.multiply(this.multiplierDecreaseCustomerCostFull);

			const denom = bc.multiply(this.customerCostIncrease ** this.customerCount);
			const resultCountMax = currentSoftMoneyValue
				.multiply((this.customerCostIncrease - 1))
				.divide(denom)
				.add(1)
				.log(this.customerCostIncrease)
				.round(Decimal.ROUND_FLOOR)
				.toNumber();
			if (resultCountMax > targetCount) {
				resultCount = targetCount;
			} else if (resultCountMax !== 0) {
				resultCount = resultCountMax;
			}

			const a = 1 - this.customerCostIncrease ** (resultCount + this.customerCount);
			const b = 1 - this.customerCostIncrease ** this.customerCount;

			resultCost = bc.multiply(a - b).divide(1 - this.customerCostIncrease);
		} else {
			targetCount = param.value;

			resultCost = this.getOneCustomerCostSoft();

			if (currentSoftMoneyValue.greaterThan(resultCost)) {
				while (resultCount <= targetCount - 1) {
					const newCost = resultCost.add(this.getOneCustomerCostSoft(this.customerCount + resultCount));
					if (currentSoftMoneyValue.greaterThanOrEqualTo(newCost)) {
						resultCost = newCost;
						resultCount += 1;
					} else {
						break;
					}
				}
			}
		}
		return { cost: resultCost, count: resultCount };
	}

	public getOneCustomerCostSoft(customerCount: number = this.customerCount): SoftMoneyNumber {
		if (customerCount === this.customerCount) {
			return this.oneCustomerCost;
		}
		return this.calculateOneCustomerCostSoft(customerCount);
	}

	private updateOneCustomerCostSoft(): void {
		this.oneCustomerCost = this.calculateOneCustomerCostSoft();
	}

	private calculateOneCustomerCostSoft(customerCount: number = this.customerCount): SoftMoneyNumber {
		let result = this.customerBaseCost
			.multiply(this.multiplierDecreaseCustomerCostFull);

		const exponentBase: number = this.customerCostIncrease;
		const chunkSize: number = Math.floor(Math.log(Number.MAX_SAFE_INTEGER) / Math.log(exponentBase));
		const cycles: number = Math.floor(customerCount / chunkSize);
		for (let i: number = 0; i < cycles; i++) {
			const multiplier: number = exponentBase ** chunkSize;
			result = result.multiply(multiplier);
		}
		const tailPart: number = customerCount - cycles * chunkSize;
		if (tailPart > 0) {
			result = result.multiply(exponentBase ** tailPart);
		}
		return result.greaterThan(SoftMoneyNumber.ONE) ? result : SoftMoneyNumber.ONE;
	}

	private updateCustomerCostIncrease(): void {
		this.customerCostIncrease = this.calculateCustomerCostIncrease();
	}

	private calculateCustomerCostIncrease(): number {
		let customerCostIncrease: number;
		if (this.customerCostIncreaseArray.length > 0) {
			const id = Math.min(this.nextCustomerMultiplierIndex - 1, this.customerCostIncreaseArray.length - 1);
			customerCostIncrease = this.customerCostIncreaseArray[id];
		} else {
			customerCostIncrease = 1;
		}
		return customerCostIncrease;
	}

	private calculateCustomerMultiplier(): number {
		let result = 1;

		const { customerMultipliers } = this;
		for (let i = 0; i < customerMultipliers.length; i++) {
			const customerMultiplier = customerMultipliers[i];
			if (customerMultiplier.customerReach <= this.getCustomerCount()) {
				result *= customerMultiplier.multiplier;
			} else {
				break;
			}
		}
		return result;
	}

	public getNextCustomerMultiplierIndex(): number {
		return this.nextCustomerMultiplierIndex;
	}

	public getCustomerMultiplierIndex(): number {
		return this.nextCustomerMultiplierIndex - 1;
	}

	public getCurrentCustomerMultiplier(): number {
		return this.customerMultipliers[this.nextCustomerMultiplierIndex - 1].multiplier;
	}

	public setSkillProfitImproveMultiplier(value: number): void {
		this.skillProfitImproveMultiplier = value;
		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public setSkillTimeImproveDivisor(value: number): void {
		this.skillTimeImproveDivisor = value;
		this.emit(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this);
	}

	public setSkillConstantProfitMultiplier(value: number): void {
		this.skillConstantProfitMultiplier = value;
		this.emit(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this);
	}

	public setSkillTapAddMoneyValue(value: number): void {
		this.skillTapAddMoneyValue = value;
	}

	public setIncomeProgress(value: number): void {
		this.incomeProgress = value;

		if (this.incomeProgress >= 1) {
			if (!this.incomeProgressCompleted) {
				this.incomeProgressDeltaReduce = 0;
				this.incomeProgressCompleted = true;
				this.emit(BusinessModel.EVENT_INCOME_PROGRESS_COMPLETE, Math.floor(this.incomeProgress));
			}
		} else if (this.incomeProgressCompleted) {
			this.incomeProgressCompleted = false;
		}
	}

	public setIncomeProgressDeltaReduced(value: number): void {
		this.incomeProgressDeltaReduce = value;
	}

	public getIncomeProgress(): number {
		return this.incomeProgress;
	}

	public getIncomeProgressDeltaReduced(): number {
		return this.incomeProgressDeltaReduce;
	}

	public isIncomeProgressComplete(): boolean {
		return this.incomeProgressCompleted;
	}
}
