import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter51007View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51007View';
import { EventCharacter51008View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51008View';
import { EventCharacter51009View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51009View';

export class EventBusiness51003View extends BusinessBaseView {
	constructor() {
		const eventCharacter51007View = new EventCharacter51007View();
		const eventCharacter51008View = new EventCharacter51008View();
		const eventCharacter51009View = new EventCharacter51009View();

		super('business51003', [eventCharacter51007View, eventCharacter51008View, eventCharacter51009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter51007View,
			eventCharacter51008View as PIXI.DisplayObject,
			eventCharacter51009View,
		);
	}
}
