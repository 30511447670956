import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20010View } from '@views/characters/events/event2/EventCharacter20010View';
import { EventCharacter20011View } from '@views/characters/events/event2/EventCharacter20011View';
import { EventCharacter20012View } from '@views/characters/events/event2/EventCharacter20012View';

export class EventBusiness20004View extends BusinessBaseView {
	constructor() {
		const character20010 = new EventCharacter20010View();
		const character20011 = new EventCharacter20011View();
		const character20012 = new EventCharacter20012View();

		super('business20004', [character20010, character20011, character20012]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor3']).setTransform(-241, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor2']).setTransform(233, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor5']).setTransform(291, 14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor1']).setTransform(11, 82, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character20010 as PIXI.DisplayObject,
			character20011,
			character20012,
		);
	}
}
