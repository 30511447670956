import * as TWEEN from '@tweenjs/tween.js';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export abstract class AbstractCollectionItemView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();
	public static readonly EVENT_POINTER_DOWN: symbol = Symbol();
	public static readonly EVENT_POINTER_MOVE: symbol = Symbol();

	protected isOpenState: boolean;
	protected openView: PIXI.Container;

	private isPointerOver: boolean;
	private pointerMoveTimer: number;
	private tweenScaleIn: TWEEN.Tween;

	protected constructor() {
		super();

		this.isOpenState = false;
		this.isPointerOver = false;
	}

	protected onOpened(): void {
		this.showOpened();

		this.on('pointerdown', this.onPointerDown, this);
		this.on('pointerup', this.onPointerUp, this);
		this.on('pointerupoutside', this.onPointerUp, this);
		this.on('pointerout', this.onPointerOut, this);
		this.on('pointerover', this.onPointerOver, this);

		this.interactive = true;
		this.isOpenState = true;
	}

	protected abstract showOpened(): void;

	protected onOpenCardClick(clickData: PromotableClickData): void {
		this.emit(AbstractCollectionItemView.EVENT_CLICK, clickData);
	}

	public getOpenedItemContainer(): PIXI.Container {
		return this.openView;
	}

	private onPointerDown(event: PIXI.interaction.InteractionEvent): void {
		this.emit(AbstractCollectionItemView.EVENT_POINTER_DOWN, event.data.global.y, event.data.global.x);
		this.pointerMoveTimer = window.setTimeout(() => this.on('pointermove', this.onPointerMove, this), 20);

		this.getScaleAnimation(0.95, 0.95, TWEEN.Easing.Quadratic.Out).start();
	}

	protected onPointerMove(event: PIXI.interaction.InteractionEvent): void {
		this.emit(AbstractCollectionItemView.EVENT_POINTER_MOVE, event);
	}

	protected onPointerUp(): void {
		window.clearTimeout(this.pointerMoveTimer);
		this.off('pointermove', this.onPointerMove, this);

		this.tweenScaleIn = this.getScaleAnimation(1, 1, TWEEN.Easing.Quadratic.In);
		this.tweenScaleIn
			.onComplete(() => {
				this.tweenScaleIn = null;
				if (this.isPointerOver) {
					this.onPointerOver();
				}
			})
			.start();

		if (this.isOpenState) {
			this.openView.interactive = true;
		}
	}

	protected onPointerOut(): void {
		window.clearTimeout(this.pointerMoveTimer);

		this.isPointerOver = false;
		if (!this.tweenScaleIn) {
			this.getScaleAnimation(1, 1, TWEEN.Easing.Quadratic.In).start();
		}
	}

	protected onPointerOver(): void {
		this.isPointerOver = true;
		this.getScaleAnimation(1.07, 1.07, TWEEN.Easing.Quadratic.In).start();
	}

	private getScaleAnimation(targetX: number, targetY: number, targetEase: (k: number) => any, duration: number = 50): TWEEN.Tween {
		return new TWEEN.Tween(this.scale)
			.to({ x: targetX, y: targetY }, duration)
			.easing(targetEase);
	}
}
