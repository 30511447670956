import { BankTabElementConfig } from '@configs/bank/BankTabElementConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { BankModel, BankTabElementType } from '@models/bank/BankModel';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';
import { BoostModel } from '@models/BoostModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { TimeskipModel } from '@models/TimeskipModel';
import {
	BankOfferWindowOriginType, BankPurchaseOriginType,
	BankTabElementDataConfigNameTypes, BankTabLayoutTypes, BankTabTypes, BankViewTypes,
	BankWindowOriginType
} from '@src/types/BankTypes';
import {
	HintDataBundle, HintDataPosition,
	HintDataReward, HintTypes
} from '@src/types/HintTypes';
import { RewardDescriptionType, RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { ScrollAxis } from '@src/types/ScrollViewTypes';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { ScrollView } from '@views/components/ScrollView';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { BankTimeskipApplyAnimation } from '@views/windows/bank/animations/BankTimeskipApplyAnimation';
import { BankTabButtonView } from '@views/windows/bank/BankTabButtonView';
import { BankTabElementBundleHorizontalView } from '@views/windows/bank/tabElementViews/BankTabElementBundleHorizontalView';
import { BankTabElementGemShopDefaultView } from '@views/windows/bank/tabElementViews/BankTabElementGemShopDefaultView';
import { BankTabElementHeaderSummonView } from '@views/windows/bank/tabElementViews/BankTabElementHeaderSummonView';
import { BankTabElementRechargeView } from '@views/windows/bank/tabElementViews/BankTabElementRechargeView';
import { BankTabElementViewFactory } from '@views/windows/bank/tabElementViews/BankTabElementViewFactory';
import { BankTabScrollSlots } from '@views/windows/bank/tabViews/BankTabScrollSlots';
import { BankTabElementBundleTimedView } from './tabElementViews/BankTabElementBundleTimedView';
import { BankTabElementCompareView } from './tabElementViews/BankTabElementCompareView';
import { BankTabElementGemShopBoostView } from './tabElementViews/BankTabElementGemShopBoostView';
import { BankTabElementGemShopTimeskipView } from './tabElementViews/BankTabElementGemShopTimeskipView';
import { BankTabViewFactory } from './tabViews/BankTabViewFactory';

export enum BankWindowViewState {
	INITED = 'inited',
	PENDING_INIT = 'pendingInit',
}

export type BankWindowOpenConfig = {
	tab: BankTabTypes;
	windowOrigin?: BankWindowOriginType;
	bankOfferWindowOrigin?: BankOfferWindowOriginType;
}

export class BankWindowView extends WindowBaseView {
	public static readonly EVENT_UPDATED: symbol = Symbol();

	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	public static readonly EVENT_BUTTON_BUY_TIMESKIP_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_TIMESKIP_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_BOOST_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_BOOST_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_GEM_SHOP_ITEM_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_RECHARGE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_BUNDLE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_SUBSCRIBE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CLAIM_SUBSCRIBE_REWARD_CLICK: symbol = Symbol();

	private readonly bankTabViewFactory: BankTabViewFactory;

	private readonly purchaseOrigin: BankPurchaseOriginType;

	private tabTypes: BankTabTypes[];
	private tabButtons: BankTabButtonView[];
	private tabViews: PIXI.Container[];
	private tabTypeTabButtonMap: Map<BankTabTypes, BankTabButtonView>;
	private currentTabId: number;
	private currentState: BankWindowViewState;
	private hardMoneyPanel: HardMoneyPanelView;
	private prestigeMoneyPanel: PrestigeMoneyPanelView;

	private tabAppearingTween: TWEEN.Tween;

	private loadingFade: PIXI.Graphics;
	private tabElementViewsMap: Map<string, PIXI.Container>;

	private bankContent: PIXI.Container;
	private bankTabContent: PIXI.Container;

	private bankTabButtonScroll: ScrollView;
	private showTarget: ShowQuestTargetView;
	private bankTabKeys: string[];

	constructor(
		private readonly serverTimeModel: ServerTimeModel,
		private readonly bankModel: BankModel,
		private readonly bankSavesModel: BankSavesModel,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly boostsModels: Map<string, BoostModel>,
		private readonly timeskipsModels: Map<string, TimeskipModel>,
		private readonly bankTabElementViewFactory: BankTabElementViewFactory,
		private readonly showQuestTargetDuration: number,
		private openConfig?: BankWindowOpenConfig,
	) {
		super(0, false);

		this.tabTypeTabButtonMap = new Map();

		this.purchaseOrigin = {
			bankOfferWindowOrigin: this.openConfig?.bankOfferWindowOrigin,
			bankWindowOrigin: this.openConfig?.windowOrigin,
		};

		this.bankModel = bankModel;
		this.bankModel.on(BankModel.EVENT_UPDATED, this.updateBankWindow, this);
		this.bankModel.on(BankModel.EVENT_OBSOLETE, this.onBankModelObsolete, this);

		this.bankSavesModel.on(BankSavesModel.EVENT_BUNDLE_REWARDS, this.updateBundleRewards, this);

		this.bankTabViewFactory = new BankTabViewFactory(
			this.createTabElementView.bind(this),
		);
		this.bankTabViewFactory.setBankTabElementConfigNameDataMapMap(this.bankModel.getTabElementConfigNameDataMap());

		const bg = PIXI.Sprite.fromImage('shopBg');
		bg.interactive = true;

		const leftBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		leftBg.anchor.set(0, 0);
		leftBg.tint = 0x000000;
		leftBg.width = 344;
		leftBg.height = 1080;
		leftBg.alpha = 0.7;

		const closeButton = new CloseButtonView();
		closeButton.position.set(1860, 40);
		closeButton.alpha = 0.7;
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.hardMoneyPanel = new HardMoneyPanelView(this.hardMoneyModel);
		this.hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(BankWindowView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, data);
			},
			this,
		);
		this.hardMoneyPanel.on(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.setActiveTabWithType(BankTabTypes.GEM_SHOP));
		this.hardMoneyPanel.position.set(1630, 40);

		this.prestigeMoneyPanel = new PrestigeMoneyPanelView(this.prestigeMoneyModel);
		this.prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(BankWindowView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, data);
			},
			this,
		);
		this.prestigeMoneyPanel.on(PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.setActiveTabWithType(BankTabTypes.GEM_SHOP));
		this.prestigeMoneyPanel.position.set(1370, 40);

		this.tabButtons = [];
		this.tabViews = [];
		this.tabTypes = [];
		this.bankTabKeys = [];
		this.tabElementViewsMap = new Map();

		this.loadingFade = new PIXI.Graphics()
			.beginFill(0x000000, 0.6)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
			.endFill();
		this.loadingFade.interactive = true;

		const topBlackFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['bank_fade'], 0, 0, 0, 0);
		topBlackFade.width = 1920;
		topBlackFade.height = 100;
		topBlackFade.position.set(0, 0);
		topBlackFade.interactive = true;

		const bottomFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['bank_fade_down'], 0, 0, 0, 0);
		bottomFade.width = 1920;
		bottomFade.height = 35;
		bottomFade.position.set(0, GameConstants.GAME_HEIGHT - bottomFade.height);
		bottomFade.interactive = true;

		this.bankContent = new PIXI.Container();
		this.bankTabContent = new PIXI.Container();

		this.addChild(
			bg,
			this.bankContent,
			topBlackFade,
			bottomFade,
			leftBg as PIXI.DisplayObject,
			closeButton,
			this.hardMoneyPanel,
			this.prestigeMoneyPanel,
		);

		if (this.bankModel.isObsolete()) {
			this.setStatePendingInit();
		} else {
			this.setStateInited();
		}
	}

	public pointAtResource(resource: RewardResourceIdTypes, duration: number): void {
		const addShowQuestTargetToBank = (): void => {
			const gemShopItems = Array.from(this.bankModel
				.getTabElementConfigNameDataMap()
				.get(BankTabElementDataConfigNameTypes.GEM_SHOP).values());
			const gemShopPrestigeItems = gemShopItems.filter((gemShopItem) => {
				const rewardDescription = (gemShopItem as BankGemShopItemModel).getRewardDescription();
				return rewardDescription.reward_type === RewardTypes.RESOURCE && rewardDescription.reward_id === resource;
			});
			if (gemShopPrestigeItems && gemShopPrestigeItems.length - 1 > 0) {
				const key = (gemShopPrestigeItems[gemShopPrestigeItems.length - 1] as BankGemShopItemModel).getViewKey();
				const targetInteractive = this.getTabElementActionButtonContainer(key);
				const show = new ShowQuestTargetView(duration, targetInteractive);
				targetInteractive.parent.addChild(show);
			}
		};
		// eslint-disable-next-line default-case
		switch (this.getCurrentState()) {
			case BankWindowViewState.PENDING_INIT: {
				this.once(BankWindowView.EVENT_UPDATED, () => addShowQuestTargetToBank(), this);
				break;
			}
			case BankWindowViewState.INITED: {
				addShowQuestTargetToBank();
				break;
			}
		}
	}

	public getTabButtonContainer(tab: BankTabTypes): PIXI.Container | undefined {
		return this.tabTypeTabButtonMap.get(tab);
	}

	public getTabElementActionButtonContainer(viewKey: string): PIXI.Container {
		return (this.tabElementViewsMap.get(viewKey) as any).getButtonBottomContainer();
	}

	public getCurrentState(): BankWindowViewState {
		return this.currentState;
	}

	public getCurrentBankTabKey(): string {
		return this.bankTabKeys[this.currentTabId];
	}

	private onBankModelObsolete(value: boolean): void {
		if (value) {
			this.setStatePendingInit();
		} else {
			this.updateBankWindow();
			this.removeChild(this.loadingFade);
		}
	}

	private updateBundleRewards(bundleKey: string): void {
		this.tabElementViewsMap.forEach((view) => {
			if (view instanceof BankTabElementBundleHorizontalView) {
				const rewards = this.bankSavesModel.getBundleRewards(bundleKey);
				view.setRewards(rewards);
			}
		});
	}

	private updateBankWindow(): void {
		this.bankTabViewFactory.setBankTabElementConfigNameDataMapMap(this.bankModel.getTabElementConfigNameDataMap());
		this.setStateInited();

		this.emit(BankWindowView.EVENT_UPDATED);
	}

	private setStateInited(): void {
		this.clearState();

		const bankTabConfigs = this.bankModel.getBankTabConfigs().slice();
		bankTabConfigs.sort((a, b) => a.getPosition() - b.getPosition());

		bankTabConfigs.forEach((bankTabConfig) => {
			// Construct tab view
			let tabView: PIXI.Container;
			// eslint-disable-next-line default-case
			switch (bankTabConfig.getTabLayoutType()) {
				case BankTabLayoutTypes.EIGHT_SLOTS: {
					tabView = this.bankTabViewFactory.createTabEightSlotsView(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.ONE_ITEM: {
					const tabElementConfig = bankTabConfig.getTabElementConfigs()[0];
					tabView = this.bankTabViewFactory.createTabOneItemView(tabElementConfig);
					break;
				}

				case BankTabLayoutTypes.HEADER_SLOTS: {
					tabView = this.bankTabViewFactory.createTabHeaderSlotsView(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.DAILY_BUNDLES: {
					tabView = this.bankTabViewFactory.createTabDailySlotsView(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.SCROLL_SLOTS: {
					tabView = this.bankTabViewFactory.createTabScrollSlots(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.ONE_BUNDLE_SIX_SLOTS_TIMER: {
					tabView = this.bankTabViewFactory.createTabBundleSixSlotsTimer(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.DOUBLE_OFFER: {
					tabView = this.bankTabViewFactory.createTabDoubleOffers(bankTabConfig.getTabElementConfigs());
					break;
				}

				case BankTabLayoutTypes.TIMED_BUNDLES: {
					tabView = this.bankTabViewFactory.createTabTimedBundles(bankTabConfig.getTabElementConfigs());
					break;
				}
			}

			// Construct tab button
			if (tabView !== undefined) {
				tabView.visible = false;

				this.tabViews.push(tabView);
				this.bankContent.addChild(tabView);

				const tabButton = this.createTabButtonView(bankTabConfig.getIconKey(), bankTabConfig.getNameLocaleKey());
				this.tabTypeTabButtonMap.set(bankTabConfig.getType(), tabButton);
				this.bankTabContent.addChild(tabButton);
				this.tabButtons.push(tabButton);
				this.bankTabKeys.push(bankTabConfig.getKey());

				if (bankTabConfig.getType() === BankTabTypes.SUBSCRIBE) {
					tabButton.setInfoIconVisible(this.bankModel.isAnySubsrcibeClaimable(this.serverTimeModel.getCalculatedISOTime()));
				} else if (bankTabConfig.getType() === BankTabTypes.RECHARGE) {
					tabButton.setInfoIconVisible(this.bankModel.isAnyRechargeClaimable());
				} else if (bankTabConfig.getType() === BankTabTypes.BUNDLES) {
					tabButton.setInfoIconVisible(this.bankModel.isFreeBundleClaimable(bankTabConfig.getTabElementConfigs()));
				}

				const tabButtonId = this.tabButtons.length - 1;
				tabButton.on(BankTabButtonView.EVENT_CLICK, () => this.setActiveTabWithButtonId(tabButtonId));

				this.tabTypes.push(bankTabConfig.getType());
			}
		});

		this.bankTabButtonScroll = new ScrollView(430, 1080, ScrollAxis.VERTICAL, { begin: 0, end: 30 });
		this.bankTabButtonScroll.setContent(this.bankTabContent);
		this.addChild(this.bankTabButtonScroll);

		if (this.openConfig != null) {
			this.setActiveTabWithType(this.openConfig.tab);
			this.openConfig = null;
		} else if (this.currentTabId != null && this.currentTabId < this.tabButtons.length) {
			this.setActiveTabWithButtonId(this.currentTabId);
		} else if (this.tabButtons.length > 0) {
			this.setActiveTabWithButtonId(0);
		}

		this.currentState = BankWindowViewState.INITED;
	}

	private setStatePendingInit(): void {
		this.currentState = BankWindowViewState.PENDING_INIT;
		this.addChild(this.loadingFade);
	}

	private setActiveTabWithType(type: BankTabTypes): void {
		const tabId = this.tabTypes.indexOf(type);
		if (tabId > -1) {
			this.setActiveTabWithButtonId(tabId);
		} else if (MODE_DEBUG) {
			// eslint-disable-next-line no-console
			console.error(`Failed showing bank tab '${type}'`);
		}
	}

	private setActiveTabWithButtonId(id: number): void {
		if (this.currentTabId !== undefined) {
			const currentTabButton = this.tabButtons[this.currentTabId];
			currentTabButton.setActive(false);
			this.tabViews[this.currentTabId].visible = false;
		}

		const tabButton = this.tabButtons[id];
		tabButton.setActive(true);

		const newTab = this.currentTabId !== id;

		this.currentTabId = id;
		const tabView = this.tabViews[this.currentTabId];
		tabView.visible = true;

		this.onTabShown(tabView);

		if (newTab) {
			this.startTabAppearingAnimation(tabView);
		}
	}

	private startTabAppearingAnimation(tabView: PIXI.Container): void {
		this.tryResetTabAppearingTween();

		const tabY = tabView.y;
		tabView.y += 113;
		tabView.alpha = 0;
		this.tabAppearingTween = new TWEEN.Tween(tabView)
			.to({ y: tabY, alpha: 1 }, 200)
			.easing(TWEEN.Easing.Quadratic.Out)
			.onComplete(() => {
				this.tabAppearingTween = null;
			})
			.start();
	}

	// eslint-disable-next-line class-methods-use-this
	private onTabShown(tabView: PIXI.Container): void {
		if (tabView instanceof BankTabScrollSlots) {
			tabView.onShown();
		}
	}

	private createTabButtonView(iconKey: string, nameLocaleKey: string): BankTabButtonView {
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')[iconKey]);
		const localizationStorage = LocalizationStorage.getInstance();
		const tabButton = new BankTabButtonView(icon, localizationStorage.getLocalizedString(nameLocaleKey));
		tabButton.position.set(185, 90 + 150 * this.tabButtons.length);
		return tabButton;
	}

	private createTabElementView(
		tabElementConfig: BankTabElementConfig,
		tabElementData: BankTabElementType,
	): PIXI.Container {
		let view = null;
		// eslint-disable-next-line default-case
		switch (tabElementConfig.getViewType()) {
			case BankViewTypes.COMPARE_LEFT: {
				const bundleModel = tabElementData as BankBundleModel;
				const bundleGroup = this.bankModel.getBankBundleGroupByBankTabElementKey(bundleModel.getViewKey());
				const offerModel = this.bankModel.getBankOfferModelByBankTabElementKey(bundleGroup.getKey());
				const compareLeftView = this.bankTabElementViewFactory.createCompareLeftView(bundleModel, bundleGroup, offerModel);
				compareLeftView.on(
					BankTabElementCompareView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyBundleClick(bundleModel),
				);
				compareLeftView.on(
					BankTabElementCompareView.EVENT_SHOW_HINT,
					this.onShowHint,
					this,
				);
				view = compareLeftView;
				break;
			}

			case BankViewTypes.COMPARE_RIGHT: {
				const bundleModel = tabElementData as BankBundleModel;
				const compareRightView = this.bankTabElementViewFactory.createCompareRightView(bundleModel);
				compareRightView.on(
					BankTabElementCompareView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyBundleClick(bundleModel),
				);
				compareRightView.on(
					BankTabElementCompareView.EVENT_SHOW_HINT,
					this.onShowHint,
					this,
				);
				view = compareRightView;
				break;
			}

			case BankViewTypes.GEM_SHOP_DEFAULT: {
				const model = tabElementData as BankGemShopItemModel;
				const gemShopItemView = this.bankTabElementViewFactory.createGemShopDefaultView(model, tabElementConfig.getViewParameters());
				const image: string = tabElementConfig.getViewParameters()[1];
				gemShopItemView.on(BankTabElementGemShopDefaultView.EVENT_BUTTON_BUY_CLICK, () => this.onButtonBuyGemShopItemClick(model, image));
				view = gemShopItemView;
				break;
			}

			case BankViewTypes.GEM_SHOP_BOOST: {
				const dataConfigBoost = tabElementData as BankGemShopItemModel;
				const boostView = this.bankTabElementViewFactory.createGemShopBoostView(dataConfigBoost);
				boostView.on(
					BankTabElementGemShopBoostView.EVENT_BUTTON_ACTIVATE_CLICK,
					() => this.emit(BankWindowView.EVENT_BUTTON_ACTIVATE_BOOST_CLICK, dataConfigBoost),
				);
				boostView.on(
					BankTabElementGemShopBoostView.EVENT_BUTTON_BUY_CLICK,
					() => this.emit(BankWindowView.EVENT_BUTTON_BUY_BOOST_CLICK, dataConfigBoost),
				);
				boostView.on(BankTabElementGemShopBoostView.EVENT_NOT_ENOUGHT_HARD, () => {
					this.onNotEnoughResources(RewardResourceIdTypes.HARD_MONEY);
				});
				view = boostView;
				break;
			}

			case BankViewTypes.GEM_SHOP_TIMESKIP: {
				const dataConfigTimeskip = tabElementData as BankGemShopItemModel;
				const timeskipView = this.bankTabElementViewFactory.createGemShopTimeskipView(dataConfigTimeskip);
				timeskipView.on(
					BankTabElementGemShopTimeskipView.EVENT_BUTTON_ACTIVATE_CLICK,
					() => this.onButtonActivateTimeskipClick(dataConfigTimeskip),
				);
				timeskipView.on(
					BankTabElementGemShopTimeskipView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyTimeskipClick(dataConfigTimeskip),
				);
				timeskipView.on(BankTabElementGemShopTimeskipView.EVENT_NOT_ENOUGH_HARD, () => {
					this.onNotEnoughResources(RewardResourceIdTypes.HARD_MONEY);
				});
				view = timeskipView;
				break;
			}

			case BankViewTypes.RECHARGE_1: {
				const rechargeModel = tabElementData as BankRechargeModel;
				const rechargeView = this.bankTabElementViewFactory.createRechargeView(rechargeModel);
				rechargeView.on(BankTabElementRechargeView.EVENT_BUTTON_RECHARGE_CLICK, () => this.onButtonBuyRechargeClick(rechargeModel));
				view = rechargeView;
				break;
			}

			case BankViewTypes.HEADER_SUMMON: {
				const headerBundleModel = tabElementData as BankBundleModel;
				const headerView = this.bankTabElementViewFactory.createHeaderSummonView(
					headerBundleModel,
				);
				headerView.on(
					BankTabElementHeaderSummonView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyBundleClick(headerBundleModel),
				);
				view = headerView;
				break;
			}

			case BankViewTypes.BUNDLE_PREFAB: {
				const bundleModel = tabElementData as BankBundleModel;
				const offerModel = this.bankModel.getBankOfferModelByBankTabElementKey(bundleModel.getViewKey());
				const bundleView = this.bankTabElementViewFactory.createBundleView(
					bundleModel,
					offerModel,
				);
				bundleView.on(
					BankTabElementBundleHorizontalView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyBundleClick(bundleModel),
				);
				bundleView.on(
					BankTabElementBundleHorizontalView.EVENT_SHOW_HINT,
					this.onShowHint,
					this,
				);
				bundleView.on(BankTabElementBundleHorizontalView.EVENT_NOT_ENOUGH_HARD, () => {
					this.onNotEnoughResources(RewardResourceIdTypes.HARD_MONEY);
				});
				bundleView.on(BankTabElementBundleHorizontalView.EVENT_NOT_ENOUGH_PRESTIGE, () => {
					this.onNotEnoughResources(RewardResourceIdTypes.PRESTIGE_MONEY);
				});
				view = bundleView;
				break;
			}

			case BankViewTypes.DAILY_BUNDLE_PREFAB:
			case BankViewTypes.WEEKLY_BUNDLE_PREFAB:
			case BankViewTypes.MONTHLY_BUNDLE_PREFAB: {
				const dailyBundleModel = tabElementData as BankBundleModel;
				const offerModel = this.bankModel.getBankOfferModelByBankTabElementKey(dailyBundleModel.getViewKey());
				const dailyBundleView = this.bankTabElementViewFactory.createDailyBundleView(
					dailyBundleModel,
					offerModel,
					tabElementConfig.getViewType(),
				);
				dailyBundleView.on(
					BankTabElementBundleTimedView.EVENT_BUTTON_BUY_CLICK,
					() => this.onButtonBuyBundleClick(dailyBundleModel),
				);
				dailyBundleView.on(BankTabElementBundleTimedView.EVENT_BUTTON_INFO_CLICK,
					(rewards: RewardDescriptionType[], origin: PIXI.DisplayObject) => {
						const data: HintDataBundle = { rewards, origin };
						this.emit(BankWindowView.EVENT_SHOW_HINT, HintTypes.BUNDLE_INFO, data);
					});
				dailyBundleView.on(
					BankTabElementBundleTimedView.EVENT_SHOW_HINT,
					this.onShowHint,
					this,
				);
				view = dailyBundleView;
				break;
			}
		}

		this.tabElementViewsMap.set(tabElementConfig.getViewKey(), view);
		return view || new PIXI.Container();
	}

	private onNotEnoughResources(resourceType: RewardResourceIdTypes): void {
		if (this.showTarget) {
			this.showTarget.destroy();
		}

		const gemShopTabId = this.tabTypes.indexOf(BankTabTypes.GEM_SHOP);

		if (gemShopTabId) {
			this.setActiveTabWithButtonId(gemShopTabId);

			const gemShopTab = this.tabViews[gemShopTabId];
			const gemShopItems = Array.from(this.bankModel
				.getTabElementConfigNameDataMap()
				.get(BankTabElementDataConfigNameTypes.GEM_SHOP).values());

			const gemShopItemsFiltered = gemShopItems.filter((gemShopItem) => {
				const rewardDescription = (gemShopItem as BankGemShopItemModel).getRewardDescription();
				return rewardDescription.reward_type === RewardTypes.RESOURCE && rewardDescription.reward_id === resourceType;
			});

			if (gemShopItemsFiltered && gemShopItemsFiltered.length - 1 > 0) {
				const key = (gemShopItemsFiltered[gemShopItemsFiltered.length - 1] as BankGemShopItemModel).getViewKey();
				const targetInteractive = this.getTabElementActionButtonContainer(key);
				this.showTarget = new ShowQuestTargetView(this.showQuestTargetDuration, targetInteractive);
				gemShopTab.addChild(this.showTarget);
			}
		}
	}

	private clearState(): void {
		if (this.tabButtons.length > 0 || this.tabViews.length > 0) {
			this.tabButtons.forEach(button => button.destroy());
			this.tabViews.forEach(tabView => tabView.destroy({ children: true }));

			this.tabButtons = [];
			this.tabViews = [];
			this.tabTypes = [];
			this.bankTabKeys = [];
			this.tabElementViewsMap.clear();
			this.tabTypeTabButtonMap.clear();
		}
		if (this.loadingFade?.parent) {
			this.removeChild(this.loadingFade);
		}
		this.tryResetTabAppearingTween();
	}

	private onShowHint(reward: RewardDescriptionType, origin: PIXI.Container): void {
		const data: HintDataReward = { rewardDescription: reward, origin };
		this.emit(BankWindowView.EVENT_SHOW_HINT, HintTypes.REWARD_CARD, data);
	}

	private onButtonBuyGemShopItemClick(model: BankGemShopItemModel, image: string): void {
		const view = this.tabElementViewsMap.get(model.getViewKey());
		const position = view.toGlobal(new PIXI.Point(0, 0));
		this.emit(
			BankWindowView.EVENT_BUTTON_BUY_GEM_SHOP_ITEM_CLICK,
			model,
			position,
			image,
			this.purchaseOrigin,
		);
	}

	private onButtonBuyRechargeClick(model: BankRechargeModel): void {
		if (model.isClaimable()) {
			this.emit(BankWindowView.EVENT_BUTTON_BUY_RECHARGE_CLICK, model);
		} else {
			this.setActiveTabWithType(BankTabTypes.BUNDLES);
		}
	}

	private onButtonBuyBundleClick(model: BankBundleModel): void {
		this.emit(BankWindowView.EVENT_BUTTON_BUY_BUNDLE_CLICK, model, this.purchaseOrigin);
	}

	private onButtonBuySubscribeClick(model: BankSubscribeModel): void {
		this.emit(BankWindowView.EVENT_BUTTON_BUY_SUBSCRIBE_CLICK, model);
	}

	private onButtonClaimSubscribeRewardClick(model: BankSubscribeModel): void {
		this.emit(BankWindowView.EVENT_BUTTON_CLAIM_SUBSCRIBE_REWARD_CLICK, model);
	}

	private onButtonActivateTimeskipClick(dataConfig: BankGemShopItemModel): void {
		const model = this.timeskipsModels.get(dataConfig.getRewardDescription().reward_id);
		const view = new BankTimeskipApplyAnimation(model.getValue(), model.getIncome(), model.getIconKey());
		view.on(BankTimeskipApplyAnimation.EVENT_BUTTON_ACTIVATE_CLICK, () => {
			this.emit(BankWindowView.EVENT_BUTTON_ACTIVATE_TIMESKIP_CLICK, dataConfig);
		}, this);
		this.addChild(view);
		view.showActivateStage();
	}

	private onButtonBuyTimeskipClick(dataConfig: BankGemShopItemModel): void {
		const localmodel = this.timeskipsModels.get(dataConfig.getRewardDescription().reward_id);
		const view = new BankTimeskipApplyAnimation(localmodel.getValue(), localmodel.getIncome(), localmodel.getIconKey());
		view.on(BankTimeskipApplyAnimation.EVENT_BUTTON_BUY_CLICK, () => {
			this.emit(BankWindowView.EVENT_BUTTON_BUY_TIMESKIP_CLICK, dataConfig);
		}, this);
		this.addChild(view);
		view.showBuyStage(dataConfig.getButtonPriceResource(), dataConfig.getPrice());
	}

	private tryResetTabAppearingTween(): void {
		if (this.tabAppearingTween) {
			this.tabAppearingTween.stop();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tryResetTabAppearingTween();

		this.bankModel.off(BankModel.EVENT_UPDATED, this.updateBankWindow, this);
		this.bankModel.off(BankModel.EVENT_OBSOLETE, this.onBankModelObsolete, this);

		this.bankSavesModel.off(BankSavesModel.EVENT_BUNDLE_REWARDS, this.updateBundleRewards, this);

		super.destroy(options);
	}
}
