import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class LevelProgressView extends PIXI.Container {
	private static readonly PROGRESS_BAR_HEIGHT: number = 21;

	private readonly progressBarCompletedMask: PIXI.Graphics;
	private readonly progressBarCompleted: PIXI.mesh.NineSlicePlane;

	private readonly progressBarPendingMask: PIXI.Graphics;
	private readonly progressBarPending: PIXI.mesh.NineSlicePlane;

	private progressSeparatorWidth: number;
	private readonly progressSeparators: PIXI.Container;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	constructor() {
		super();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['lvl_bar_bg'], 4, 0, 8, 0);
		bg.width = 344;

		this.progressBarCompleted = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['lvl_progress_bar'], 0, 0, 8, 0);
		this.progressBarCompleted.width = 339;
		this.progressBarCompleted.position.set(3, 2.5);

		this.progressBarPending = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['lvl_completed_bar'], 0, 0, 8, 0);
		this.progressBarPending.width = 339;
		this.progressBarPending.position.set(3, 2.5);

		this.progressSeparators = new PIXI.Container();

		this.progressBarCompletedMask = new PIXI.Graphics();
		this.progressBarCompletedMask.position.set(1, 2.5);
		this.progressBarCompleted.mask = this.progressBarCompletedMask;

		this.progressBarPendingMask = new PIXI.Graphics();
		this.progressBarPendingMask.position.set(1, 2.5);
		this.progressBarPending.mask = this.progressBarPendingMask;

		this.addChild(
			bg,
			this.progressBarPending,
			this.progressBarPendingMask,
			this.progressBarCompleted,
			this.progressBarCompletedMask,
			this.progressSeparators,
		);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setTotalProgress(value: number): void {
		this.progressSeparators.removeChildren();

		this.progressSeparatorWidth = this.progressBarCompleted.width / value;
		for (let i = 1; i < value; i++) {
			const progressSeparator = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lvl_ended_element']);
			progressSeparator.position.set(this.progressSeparatorWidth * i, 11);
			this.progressSeparators.addChild(progressSeparator);
		}
	}

	private playAnimation(): void {
		const sprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['progres_bar_main_ui']);
		sprite.tint = 0xFFBE00;
		sprite.blendMode = PIXI.BLEND_MODES.ADD;
		sprite.anchor.set(1, 0.5);
		sprite.position.set(this.progressBarPending.x, this.progressBarPending.x + this.progressBarPending.height / 2);
		this.addChild(sprite);

		new TWEEN.Tween(sprite.position, this.tweenGroup)
			.to({ x: this.progressBarPending.x + this.progressBarPending.width + 20 }, 500)
			.easing(TWEEN.Easing.Quadratic.In)
			.chain(
				new TWEEN.Tween(sprite, this.tweenGroup)
					.to({ alpha: 0 }, 200)
					.onComplete(() => sprite.destroy())
					.easing(TWEEN.Easing.Cubic.In),
			)
			.start();
	}

	public setProgress(current: number, pending: number, playProgressAnimation: boolean): void {
		this.progressBarCompletedMask.clear();
		this.progressBarCompletedMask.beginFill(0);
		this.progressBarCompletedMask.drawPolygon([
			new PIXI.Point(0, 0),
			new PIXI.Point(current * this.progressSeparatorWidth + 2, 0),
			new PIXI.Point(current * this.progressSeparatorWidth - 3, LevelProgressView.PROGRESS_BAR_HEIGHT),
			new PIXI.Point(0, LevelProgressView.PROGRESS_BAR_HEIGHT),
		]);
		this.progressBarCompletedMask.endFill();

		this.progressBarPendingMask.clear();
		this.progressBarPendingMask.beginFill(0x00FFFF);
		this.progressBarPendingMask.drawPolygon([
			new PIXI.Point(0, 0),
			new PIXI.Point((current + pending) * this.progressSeparatorWidth + 2, 0),
			new PIXI.Point((current + pending) * this.progressSeparatorWidth - 3, LevelProgressView.PROGRESS_BAR_HEIGHT),
			new PIXI.Point(0, LevelProgressView.PROGRESS_BAR_HEIGHT),
		]);
		this.progressBarPendingMask.endFill();

		if (playProgressAnimation) {
			this.playAnimation();
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
