import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60004View } from '@views/characters/events/event6/EventCharacter60004View';
import { EventCharacter60005View } from '@views/characters/events/event6/EventCharacter60005View';
import { EventCharacter60006View } from '@views/characters/events/event6/EventCharacter60006View';

export class EventBusiness60002View extends BusinessBaseView {
	constructor() {
		const eventCharacter60004View = new EventCharacter60004View();
		const eventCharacter60005View = new EventCharacter60005View();
		const eventCharacter60006View = new EventCharacter60006View();

		super('business60002', [eventCharacter60004View, eventCharacter60005View, eventCharacter60006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;
		const decor4 = new PIXI.Sprite(decorationAtlas['b5_decor4']).setTransform(3, 0, 0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
			decor4,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(eventCharacter60004View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter60005View, this.getChildIndex(decor4) + 1);
		this.addChildAt(eventCharacter60006View, this.getChildIndex(decor4) + 1);
	}
}
