import { CollectionBaseView } from '../CollectionBaseView';
import { GalleryVideoView } from '@views/windows/collection/gallery/GalleryVideoView';
import { GalleryVideoModel } from '@models/GalleryVideoModel';

export class GalleryCollectionView extends CollectionBaseView {
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();

	constructor(
		galleryVideoModels: Map<string, GalleryVideoModel>,
	) {
		super({ begin: 0, end: 200 });

		this.scrollView.position.set(410, 260);

		let i = 0;
		galleryVideoModels.forEach((model) => {
			const x = 455 * (i % 3) + 270;
			const y = 305 * (Math.floor(i / 3)) + 200;
			const item = new GalleryVideoView(model);
			item.position.set(x, y);
			item.on(GalleryVideoView.EVENT_CLICK, (data) => {
				if (!this.scrollView.getIsScrolling()) {
					this.emit(GalleryCollectionView.EVENT_CARD_CLICK, data);
					this.disableScroll();
				}
			});
			this.contentContainer.addChild(item);

			i += 1;
		});
		this.scrollView.updateBounds();
	}
}
