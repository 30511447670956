import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AbstractPromotableEntityConfig } from '@configs/AbstractPromotableEntityConfig';

export class UpgradeConfig extends AbstractPromotableEntityConfig {
	public static readonly FIELD_ACTIVATE_COST: string = 'activate_cost';

	public static readonly FIELDS: string[] = [
		UpgradeConfig.FIELD_KEY,
		UpgradeConfig.FIELD_BONUS,
		UpgradeConfig.FIELD_BONUS_VALUE,
		UpgradeConfig.FIELD_RARITY,
		UpgradeConfig.FIELD_ACTIVATE_COST,
	];

	public static readonly FIELDS_OPTIONAL: string[] = [
	];

	private customerCount: number;
	private readonly activateCost: SoftMoneyNumber;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.activateCost = SoftMoneyNumber.createFromAcronym((config[UpgradeConfig.FIELD_ACTIVATE_COST] as string).trim());
		this.customerCount = 0;
	}

	public getCustomerCount(): number {
		return this.customerCount;
	}

	public setCustomerCount(value: number): void {
		this.customerCount = value;
	}

	public getActivateCost(): SoftMoneyNumber {
		return this.activateCost;
	}
}
