import { AssetsStorage } from '@main/AssetsStorage';
import { AbstractReward } from '@interfaces/AbstractReward';
import { CardMiniViewFactory } from '../cardsMini/CardMiniViewFactory';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { Emitter } from 'pixi-particles';
import * as TWEEN from '@tweenjs/tween.js';
import { SandboxOperation } from '@src/utils/SandboxOperation';

export class EventGoalRewardIconView extends ButtonBaseView {
	private static particlesConfig(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.4,
				end: 0,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffef42',
				end: '#fff695',
			},
			speed: {
				start: 200,
				end: 0,
				minimumSpeedMultiplier: 0.05,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			noRotation: true,
			lifetime: {
				min: 0.2,
				max: 0.9,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 100,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 0,
		};
	}

	private readonly cardMiniViewFactory: CardMiniViewFactory;

	private readonly mainContainer: PIXI.Container;
	private readonly runInEpisodeAssetsSandbox: SandboxOperation;

	private particleContainer: PIXI.Container;
	private particleEmitter: Emitter;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	private bounce: TWEEN.Tween;
	private bounceBack: TWEEN.Tween;
	private tweenOpen: TWEEN.Tween;
	private tweenClose: TWEEN.Tween;
	private shakeTween: TWEEN.Tween;

	private card: PIXI.Container;

	constructor(
		cardMiniViewFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
	) {
		const mainContainer = new PIXI.Container();
		super(mainContainer);

		this.cardMiniViewFactory = cardMiniViewFactory;
		this.runInEpisodeAssetsSandbox = runInEpisodeAssetsSandbox;
		this.mainContainer = mainContainer;

		this.tweenGroup = new TWEEN.Group();

		const arrow = new PIXI.Graphics();
		arrow.beginFill(0x15151d);
		arrow.drawPolygon([
			new PIXI.Point(0, 0),
			new PIXI.Point(20, 10),
			new PIXI.Point(20, -10),
		]);
		arrow.pivot.set(arrow.width / 2, arrow.height / 2);
		arrow.position.set(-30, 15);

		const glow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 15, 15, 15, 15);
		glow.width = 65;
		glow.height = 85;
		glow.pivot.set(glow.width / 2, glow.height / 2);
		glow.tint = 0x8deaeb;

		this.particleContainer = new PIXI.Container();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.mainContainer.addChild(
			this.particleContainer,
			glow,
			arrow as PIXI.DisplayObject,
		);

		this.addChild(
			this.mainContainer,
		);
	}

	public setReward(reward: AbstractReward): void {
		this.updateGoalRewardIcon(reward);
	}

	public setNewRewardWithAnimation(
		reward: AbstractReward,
	): Promise<void> {
		return new Promise(resolve => {
			this.bounce = new TWEEN.Tween(this.mainContainer.scale, this.tweenGroup)
				.to({ x: 1, y: 1 }, 100)
				.onComplete(() => {
					this.updateGoalRewardIcon(reward);
					this.playParticles().then(() => {
						resolve();
					});
				});

			this.tweenOpen = new TWEEN.Tween(this.mainContainer.scale, this.tweenGroup)
				.to({ x: 1.1, y: 1.1 }, 300);

			this.bounceBack = new TWEEN.Tween(this.mainContainer.scale, this.tweenGroup)
				.to({ x: 0, y: 0 }, 100);

			this.tweenClose = new TWEEN.Tween(this.mainContainer.scale, this.tweenGroup)
				.to({ x: 1.1, y: 1.1 }, 300);

			this.shakeTween = new TWEEN.Tween(this.mainContainer, this.tweenGroup)
				.to({ x: this.mainContainer.x + 6 }, 40)
				.repeat(5)
				.easing(TWEEN.Easing.Cubic.In)
				.yoyo(true);

			this.shakeTween
				.chain(this.tweenClose)
				.chain(this.bounceBack)
				.chain(this.tweenOpen)
				.chain(this.bounce)
				.start();
		});
	}

	private updateGoalRewardIcon(reward: AbstractReward): void {
		// eslint-disable-next-line no-unused-expressions
		this.card?.destroy({ children: true });

		this.runInEpisodeAssetsSandbox.start(() => {
			this.card = this.cardMiniViewFactory.createRewardCardByModel(
				reward,
				false, 0.35, 0.35,
			);
		});

		this.mainContainer.addChild(
			this.card,
		);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private playParticles(): Promise<void> {
		return new Promise((resolve) => {
			this.particleEmitter = new Emitter(
				this.particleContainer,
				[new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['summon_card_glow2']).texture],
				EventGoalRewardIconView.particlesConfig(),
			);
			this.particleEmitter.playOnceAndDestroy(() => {
				this.particleEmitter = null;
				resolve();
			});
		});
	}

	public destroy(): void {
		this.tweenGroup.removeAll();

		if (this.particleEmitter) {
			this.particleEmitter.destroy();
		}

		this.ticker.remove(this.update, this);

		super.destroy();
	}
}
