import { SkillBaseView } from '@views/ui/skillsPanel/SkillBaseView';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillActivateAction } from '@models/network/actions/SkillActivateAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { SkillBaseViewSetter } from '@interfaces/ViewSetters';
import { SoundController } from '@src/main/SoundController';

export class SkillBaseViewController extends PIXI.utils.EventEmitter implements SkillBaseViewSetter {
	private readonly model: SkillModel;

	private view: SkillBaseView;

	constructor(
		model: SkillModel,
	) {
		super();

		this.model = model;
		this.model.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this);
	}

	public setSkillView(view: SkillBaseView): void {
		this.view = view;
		this.view.on(SkillBaseView.EVENT_CLICK, this.onClick, this);
	}

	private onClick(): void {
		const canActivateSkill = this.model.isOpened() && !this.model.isActive() && !this.model.isReloading();
		if (canActivateSkill) {
			this.model.activate();

			SoundController.getInstance().playSkillActivate();
		}
	}

	private onSkillActivated(): void {
		const action = new SkillActivateAction(this.model.getKey());
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}
}
