import { SettingsPanelItemBaseView } from './SettingsPanelItemBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class SettingsPanelItemWithButtonView extends SettingsPanelItemBaseView {
	public static readonly EVENT_BUTTON_CLICK: symbol = Symbol();

	private readonly localeKeyLabelButton: string;
	private readonly labelButton: SizeableBitmapText;

	constructor(
		iconOffset: number,
		titleLabelOffset: number,
		buttonOffset: number,
		localeKeyLabelTitle: string,
		localeKeyLabelButton: string,
		icon: PIXI.Sprite,
	) {
		super(
			iconOffset,
			titleLabelOffset,
			localeKeyLabelTitle,
			icon,
		);

		this.localeKeyLabelButton = localeKeyLabelButton;

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 319;
		buttonBg.height = 71;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);
		button.x = buttonOffset;
		button.on(ButtonBaseView.EVENT_CLICK, () => { this.emit(SettingsPanelItemWithButtonView.EVENT_BUTTON_CLICK); });

		this.labelButton = new SizeableBitmapText(
			'',
			button.width - 20,
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
		);
		this.labelButton.anchor = 0.5;
		this.labelButton.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelButton);

		button.addChild(this.labelButton);

		this.addChild(
			button,
		);
	}

	protected updateText(): void {
		this.labelButton.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelButton);
		super.updateText();
	}
}
