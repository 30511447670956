import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter53013View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53013View';
import { EventCharacter53014View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53014View';
import { EventCharacter53015View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53015View';

export class EventBusiness53005View extends BusinessBaseView {
	constructor() {
		const eventCharacter53013View = new EventCharacter53013View();
		const eventCharacter53014View = new EventCharacter53014View();
		const eventCharacter53015View = new EventCharacter53015View();

		super('business53005', [eventCharacter53013View, eventCharacter53014View, eventCharacter53015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter53013View as PIXI.DisplayObject,
			eventCharacter53014View,
			eventCharacter53015View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
