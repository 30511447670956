import { GameConstants } from '@src/utils/GameConstants';

export class WindowBaseView extends PIXI.Container {
	public static readonly EVENT_WINDOW_CLOSED: symbol = Symbol();

	protected blackout: PIXI.Graphics;
	protected mainContainer: PIXI.Container;

	protected destroyOnClose: boolean;

	protected blurred: boolean;

	private closeOnBlackoutClick: boolean;

	constructor(
		blackoutAlpha: number,
		closeOnBlackoutClick: boolean = true,
		destroyOnClose: boolean = true,
	) {
		super();

		this.blackout = new PIXI.Graphics();
		this.blackout.interactive = true;
		this.blackout.beginFill(0x000000, blackoutAlpha);
		this.blackout.moveTo(0, 0);
		this.blackout.lineTo(GameConstants.GAME_WIDTH, 0);
		this.blackout.lineTo(GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
		this.blackout.lineTo(0, GameConstants.GAME_HEIGHT);
		this.blackout.closePath();

		this.blurred = false;

		this.destroyOnClose = destroyOnClose;
		this.closeOnBlackoutClick = closeOnBlackoutClick;
		if (this.closeOnBlackoutClick) {
			this.blackout.on('pointertap', this.onClose, this);
		}

		this.mainContainer = new PIXI.Container();
		this.mainContainer.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y);

		this.visible = false;

		this.addChild(
			this.blackout,
			this.mainContainer,
		);
	}

	protected setCloseOnBlackoutClick(value: boolean): void {
		if (!this.closeOnBlackoutClick && value) {
			this.blackout.on('pointertap', this.onClose, this);
		}
		if (!value) {
			this.blackout.off('pointertap', this.onClose, this);
		}
		this.closeOnBlackoutClick = value;
	}

	public onShown(): void {
		this.visible = true;
	}

	public onFocus(): void {
		this.blurred = false;
	}

	public onBlur(): void {
		this.blurred = true;
	}

	protected onClose(): void {
		this.blurred = false;
		this.emit(WindowBaseView.EVENT_WINDOW_CLOSED);

		if (this.destroyOnClose) {
			this.destroy({ children: true });
		}
	}

	public isBlurred(): boolean {
		return this.blurred;
	}
}
