import LocalizationStorage from '@main/LocalizationStorage';
import { BusinessModel } from '@models/BusinessModel';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export class BusinessNotAvailableUIView extends PIXI.Container {
	public static readonly EVENT_BACKGROUND_CLICK: symbol = Symbol();

	private localizationStorage: LocalizationStorage;
	private model: BusinessModel;

	private ui: PIXI.Container;

	private unlocksOnLevel: number;

	private textName: PIXI.extras.BitmapText;
	private textAvailable: PIXI.extras.BitmapText;

	constructor(
		model: BusinessModel,
	) {
		super();

		this.model = model;
		this.unlocksOnLevel = this.model.getUnlocksOnLevel();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		const bgLocked = new PIXI.Sprite(AssetsStorage.getAtlas('businessBgAtlas')[`${this.model.getKey()}_lock`]);
		bgLocked.scale.set(0.5);
		bgLocked.interactive = true;
		bgLocked.on('pointertap', () => this.emit(BusinessNotAvailableUIView.EVENT_BACKGROUND_CLICK));

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['locked_fade']);
		bg.width = bgLocked.width;
		bg.y += 25;

		this.textName = new PIXI.extras.BitmapText('', { font: '28px wendyOneShadowBold' });
		this.textName.anchor = 0.5;
		this.textName.y = -16;

		this.textAvailable = new MultiColoredTextField({ font: '22px wendyOneShadowBold', tint: 0xb5becb });
		this.textAvailable.anchor = 0.5;
		this.textAvailable.y = 18;

		this.ui = new PIXI.Container();
		this.ui.addChild(
			this.textAvailable,
			this.textName,
		);

		this.onTranslateText();

		this.addChild(
			bgLocked,
			bg,
			this.ui,
		);
	}

	public onOtherViewZoomedIn(): void {
		this.startMainUIHidingAnimation();
	}

	public onOtherViewZoomedOut(): void {
		this.startMainUIAppearingAnimation();
	}

	public startMainUIHidingAnimation(duration: number = 100): void {
		new TWEEN.Tween(this.ui)
			.to({ alpha: 0 }, duration)
			.onComplete(() => {
				this.ui.visible = false;
			})
			.start();
	}

	public startMainUIAppearingAnimation(duration: number = 100): void {
		this.ui.alpha = 0;
		this.ui.visible = true;
		new TWEEN.Tween(this.ui)
			.to({ alpha: 1 }, duration)
			.start();
	}

	private onTranslateText(): void {
		this.textName.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);

		let availableString = LocalizationStorage.getInstance().getLocalizedString('#business_UnlocksOnLevel');
		availableString = availableString.replace('{{value}}', this.unlocksOnLevel.toString());
		this.textAvailable.text = `[lock]${availableString}`;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		super.destroy(options);
	}
}
