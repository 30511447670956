import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40004View } from '@views/characters/events/event4/EventCharacter40004View';
import { EventCharacter40005View } from '@views/characters/events/event4/EventCharacter40005View';
import { EventCharacter40006View } from '@views/characters/events/event4/EventCharacter40006View';

export class EventBusiness40002View extends BusinessBaseView {
	constructor() {
		const eventCharacter40004View = new EventCharacter40004View();
		const eventCharacter40005View = new EventCharacter40005View();
		const eventCharacter40006View = new EventCharacter40006View();

		super('business40002', [eventCharacter40004View, eventCharacter40005View, eventCharacter40006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor1']).setTransform(-102, 63, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter40006View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter40004View as PIXI.DisplayObject,
			eventCharacter40005View,
		);
	}
}
