import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter54013View extends CharacterMainBaseView {
	constructor() {
		super('character54013');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-140, 2);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(90, -36),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(190, -40),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
