import { TutorialStepUnlockTypeBaseModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeBaseModel';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepUnlockTypeLevelStartModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeLevelStartModel';
import { TutorialStepUnlockTypeGetQuestModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeGetQuestModel';
import { TutorialStepUnlockTypeQuestCompleteModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeQuestCompleteModel';
import { TutorialStepUnlockTypeEventStartModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeEventStartModel';

export class TutorialStepsEmitter extends PIXI.utils.EventEmitter {
	public static EVENT_BASE_STEP_UNLOCKED: symbol = Symbol();

	private tutorialStepModels: Map<string, TutorialStepBaseModel>;
	private unlockTypeBaseModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>;

	private readonly completedTutorialStepKeys: Set<string>;

	private unlockTypeLevelStartModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>;
	private unlockTypeEventStartModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>;
	private unlockTypeGetQuestModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>;
	private unlockTypeQuestCompleteModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>;

	private enabled: boolean;

	constructor(
		tutorialStepModels: Map<string, TutorialStepBaseModel>,
		enabled: boolean = true,
	) {
		super();

		this.enabled = enabled;

		this.tutorialStepModels = tutorialStepModels;

		this.completedTutorialStepKeys = new Set();

		this.unlockTypeLevelStartModelKeyMap = new Map();
		this.unlockTypeEventStartModelKeyMap = new Map();
		this.unlockTypeGetQuestModelKeyMap = new Map();
		this.unlockTypeQuestCompleteModelKeyMap = new Map();
		this.unlockTypeBaseModelKeyMap = new Map();
	}

	public setModels(unlockTypeModelKeyMap: Map<string, TutorialStepUnlockTypeBaseModel>): void {
		unlockTypeModelKeyMap.forEach((unlockTypeModel, key) => {
			if (!unlockTypeModel.isUnlocked()) {
				const type = unlockTypeModel.getType();
				switch (type) {
					case TutorialStepUnlockTypes.LEVEL_START: {
						this.unlockTypeLevelStartModelKeyMap.set(key, unlockTypeModel);
						break;
					}
					case TutorialStepUnlockTypes.EVENT_START: {
						this.unlockTypeEventStartModelKeyMap.set(key, unlockTypeModel);
						break;
					}
					case TutorialStepUnlockTypes.GET_QUEST: {
						this.unlockTypeGetQuestModelKeyMap.set(key, unlockTypeModel);
						break;
					}
					default: {
						if (type === TutorialStepUnlockTypes.QUEST_COMPLETE) {
							this.unlockTypeQuestCompleteModelKeyMap.set(key, unlockTypeModel);
						}
						this.unlockTypeBaseModelKeyMap.set(key, unlockTypeModel);
						unlockTypeModel.once(TutorialStepUnlockTypeBaseModel.EVENT_UNLOCKED, this.onSomeBaseUnlockTypeUnlocked, this);
						break;
					}
				}
			}
		});
	}

	public reset(): void {
		this.unlockTypeBaseModelKeyMap.forEach((unlockTypeModel) => {
			if (unlockTypeModel.listeners(TutorialStepUnlockTypeBaseModel.EVENT_UNLOCKED).includes(this.onSomeBaseUnlockTypeUnlocked)) {
				unlockTypeModel.off(TutorialStepUnlockTypeBaseModel.EVENT_UNLOCKED, this.onSomeBaseUnlockTypeUnlocked, this, true);
			}
		});

		this.unlockTypeBaseModelKeyMap.clear();

		this.unlockTypeEventStartModelKeyMap.clear();
		this.unlockTypeLevelStartModelKeyMap.clear();
		this.unlockTypeGetQuestModelKeyMap.clear();
		this.unlockTypeQuestCompleteModelKeyMap.clear();
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;
	}

	public tryEmitTutorialStepEventStart(): TutorialStepBaseModel | undefined {
		let targetTutorialStepModel: TutorialStepBaseModel;
		let targetUnlockTypeModelKey = '';
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, unlockTypeModel] of this.unlockTypeEventStartModelKeyMap) {
			if ((unlockTypeModel as TutorialStepUnlockTypeEventStartModel).tryUnlock()) {
				targetUnlockTypeModelKey = key;
				break;
			}
		}

		if (targetUnlockTypeModelKey) {
			targetTutorialStepModel = this.tutorialStepModels.get(targetUnlockTypeModelKey);
			this.unlockTypeEventStartModelKeyMap.delete(targetUnlockTypeModelKey);
		}
		return this.enabled ? targetTutorialStepModel : undefined;
	}

	public tryEmitTutorialStepLevelStart(): TutorialStepBaseModel | undefined {
		let targetTutorialStepModel: TutorialStepBaseModel;
		let targetUnlockTypeModelKey = '';
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, unlockTypeModel] of this.unlockTypeLevelStartModelKeyMap) {
			if ((unlockTypeModel as TutorialStepUnlockTypeLevelStartModel).tryUnlock()) {
				targetUnlockTypeModelKey = key;
				break;
			}
		}

		if (targetUnlockTypeModelKey) {
			targetTutorialStepModel = this.tutorialStepModels.get(targetUnlockTypeModelKey);
			this.unlockTypeLevelStartModelKeyMap.delete(targetUnlockTypeModelKey);
		}
		return this.enabled ? targetTutorialStepModel : undefined;
	}

	public tryEmitTutorialStepGetQuest(): TutorialStepBaseModel | undefined {
		let targetTutorialStepModel: TutorialStepBaseModel;
		let targetUnlockTypeModelKey = '';
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, unlockTypeModel] of this.unlockTypeGetQuestModelKeyMap) {
			if ((unlockTypeModel as TutorialStepUnlockTypeGetQuestModel).tryUnlock()) {
				targetUnlockTypeModelKey = key;
				break;
			}
		}

		if (targetUnlockTypeModelKey) {
			targetTutorialStepModel = this.tutorialStepModels.get(targetUnlockTypeModelKey);
			this.unlockTypeGetQuestModelKeyMap.delete(targetUnlockTypeModelKey);
		}

		return this.enabled ? targetTutorialStepModel : undefined;
	}

	public tryEmitTutorialStepQuestComplete(): TutorialStepBaseModel | undefined {
		let targetTutorialStepModel: TutorialStepBaseModel;
		let targetUnlockTypeModelKey = '';
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, unlockTypeModel] of this.unlockTypeQuestCompleteModelKeyMap) {
			if ((unlockTypeModel as TutorialStepUnlockTypeQuestCompleteModel).tryUnlock()) {
				targetUnlockTypeModelKey = key;
				break;
			}
		}

		if (targetUnlockTypeModelKey) {
			targetTutorialStepModel = this.tutorialStepModels.get(targetUnlockTypeModelKey);
			this.unlockTypeQuestCompleteModelKeyMap.delete(targetUnlockTypeModelKey);
		}

		return this.enabled ? targetTutorialStepModel : undefined;
	}

	private onSomeBaseUnlockTypeUnlocked(key: string): void {
		const tutorialStepModel = this.tutorialStepModels.get(key);

		this.completedTutorialStepKeys.add(key);

		if (this.enabled) {
			this.emit(TutorialStepsEmitter.EVENT_BASE_STEP_UNLOCKED, tutorialStepModel);
		}
	}
}
