/* eslint-disable no-console */
import { CharacterModel } from '@models/CharacterModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowCharactersViewController implements CheatWindowViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly characterModels: Map<string, CharacterModel>;

	constructor(
		networkRequestSender: NetworkRequestSender,
		characterModels: Map<string, CharacterModel>,
	) {
		this.networkRequestSender = networkRequestSender;
		this.characterModels = characterModels;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_CHARACTER_ACTIVATE, this.onCharacterActivate, this);
		view.on(CheatWindowView.EVENT_CHARACTER_ACTIVATE_ALL, this.onCharacterActivateAll, this);
		view.on(CheatWindowView.EVENT_CHARACTER_OPEN, this.onCharacterOpen, this);
		view.on(CheatWindowView.EVENT_CHARACTER_OPEN_ALL, this.onCharacterOpenAll, this);
		view.on(CheatWindowView.EVENT_CHARACTER_ADD_CARDS_TO_OPENED, this.onCharacterAddCardsToOpened, this);
	}

	private onCharacterOpen(key: string): void {
		const model = this.characterModels.get(key);
		model.open();

		console.log(`Opening '${key}' server...`);
		this.networkRequestSender.sendCheatOpen(key, 'characters').then(() => {
			console.log(`Opening '${key}' server OK`);
		});
	}

	private onCharacterOpenAll(): void {
		this.characterModels
			.forEach((model) => {
				if (!model.isOpened()) {
					model.open();
				}
			});
		console.log('Opening characters server...');
		this.networkRequestSender.sendCheatOpenAll('characters').then(() => {
			console.log('Opening characters server OK');
		});
	}

	private onCharacterAddCardsToOpened(value: string): void {
		const amount = Number(value);
		this.characterModels.forEach(model => {
			if (model.isOpened()) {
				model.addCards(amount);
			}
		});

		console.log('Adding cards to opened chars server...');
		this.networkRequestSender.sendCheatAddCardsToOpened(amount, 'characters').then(() => {
			console.log('Adding cards to opened chars server OK');
		});
	}

	private onCharacterActivate(key: string): void {
		this.characterModels.get(key).activate();
	}

	private onCharacterActivateAll(): void {
		this.characterModels.forEach(x => x.activate());
	}
}
