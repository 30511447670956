import { SkillModel } from '@models/skills/SkillModel';
import { SkillConfig } from '@configs/SkillConfig';

export class SkillConstantProfitModel extends SkillModel {
	private totalMultiplier: number;

	constructor(config: SkillConfig) {
		super(config);

		this.totalMultiplier = 0;
	}

	public setTotalMultiplier(value: number): void {
		this.totalMultiplier = value;
	}

	public activate(): void {
		this.emit(SkillModel.EVENT_SKILL_BEFORE_ACTIVATE, this);
		this.totalMultiplier += this.getAdditionalPart();
		this.active = true;
		this.emit(SkillModel.EVENT_SKILL_ACTIVATED, this);
	}

	public deactivate(): void {
		this.totalMultiplier = 0;
		super.deactivate();
	}

	public getMultiplier(): number {
		return 1 + this.totalMultiplier;
	}

	public getAdditionalPart(): number {
		return this.getBaseMultiplier() * this.effectiveImproveMultiplier;
	}

	public getBaseMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel);
	}

	public getNextBaseMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel + 1);
	}
}
