import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AssetsStorage } from '@main/AssetsStorage';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BundleRewardDisplayInfo } from './BankTabElementBundleTimedView';
import { ViewUtils } from '@src/utils/ViewUtils';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonWithOldCostValueSettings, ButtonWithOldCostValue } from '@views/components/buttons/ButtonWithOldCostValueView';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export abstract class BankTabElementCompareView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	constructor(
		protected readonly bankBundleModel: BankBundleModel,
		private readonly rewards: RewardDescriptionType[],
		private readonly rewardsDisplayInfo: BundleRewardDisplayInfo[],
	) {
		super();
	}

	protected getRewardsRow(miniCardsMarginX: number, cardScale: number = 1): PIXI.Container[] {
		const rewardMiniCardsArray: PIXI.Container[] = [];
		this.rewardsDisplayInfo.forEach((info: BundleRewardDisplayInfo, index: number) => {
			const miniCard = info.icon;
			miniCard.interactive = true;
			miniCard.on('pointerdown', () => {
				this.emit(BankTabElementCompareView.EVENT_SHOW_HINT, this.rewards[index], miniCard);
			});

			let labelCountString: string;
			if (typeof info.count === 'number') {
				labelCountString = `x${SoftMoneyNumber.createFromNumber(info.count).toString(3, 5)}`;
			} else if (typeof info.count === 'string') {
				labelCountString = `x${SoftMoneyNumber.createFromString(info.count).toString(3, 5)}`;
			}

			const labelCount = new SizeableBitmapText(
				labelCountString,
				miniCard.width + 40,
				{ font: '39 wendyOneShadowBold', tint: 0xffd86d },
			);
			labelCount.anchor = 0.5;
			labelCount.y = 70;

			const labelCountFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow_half']);
			labelCountFade.tint = 0x00000;
			labelCountFade.alpha = 0.7;
			labelCountFade.scale.set(1.6);
			labelCountFade.y = labelCount.y - 13;

			miniCard.scale.set(cardScale, cardScale);

			miniCard.addChild(
				labelCountFade as PIXI.DisplayObject,
				labelCount,
			);

			rewardMiniCardsArray.push(miniCard);
		});

		ViewUtils.positionLayoutHorizontal(
			rewardMiniCardsArray,
			miniCardsMarginX,
		);

		return rewardMiniCardsArray;
	}

	protected createButton(width: number, height: number): PIXI.Container {
		const buyButton: ButtonWithOldCostValue = new ButtonWithOldCostValue(this.createButtonConfig());
		buyButton.once(
			ButtonBaseView.EVENT_CLICK,
			this.onButtonBuyClick.bind(this),
			this,
		);

		return buyButton;
	}

	private createButtonConfig(): ButtonWithOldCostValueSettings {
		const config: ButtonWithOldCostValueSettings = {
			price: String(this.bankBundleModel.getPrice()),
			buttonPriceResource: this.bankBundleModel.getButtonPriceResource(),
			priceStyle: {
				font: {
					name: 'wendyOneShadowBold',
					size: 44,
				},
			},
			maxWidth: 300,
			minWidth: 380,
		};

		if (this.bankBundleModel.hasOldPrice()) {
			config.oldPrice = String(this.bankBundleModel.getOldPrice());
			config.buttonOldPriceResource = this.bankBundleModel.getButtonOldPriceResource();
			config.oldPriceStyle = {
				font: {
					name: 'wendyOneShadowBold',
					size: 32,
				},
			};
		}

		return config;
	}

	protected onButtonBuyClick(): void {
		this.emit(BankTabElementCompareView.EVENT_BUTTON_BUY_CLICK);
	}
}
