import { BusinessBaseViewsSetter } from '@interfaces/ViewSetters';
import { LevelModel } from '@models/level/LevelModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import {
	TimedQuestUnlockTypeBaseModel,

	TimedQuestUnlockTypeZoomOutBusinessModel
} from '@models/quests/timed/TImedQuestUnlockTypeModels';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';

export class TimedQuestsEmitter extends PIXI.utils.EventEmitter
	implements BusinessBaseViewsSetter {
	public static readonly EVENT_TIMED_QUEST: symbol = Symbol();

	constructor(
		private readonly timedQuestLinesModel: TimedQuestLinesModel,
		private readonly levelModel: LevelModel,
		private readonly unlockTypeModels: TimedQuestUnlockTypeBaseModel[],
		private readonly timedQuestsMinLevel: number,
	) {
		super();

		if (this.timedQuestLinesModel.hasPendingQuest()) {
			this.onSomePendingQuestAdded();
		}

		this.timedQuestLinesModel.on(TimedQuestLinesModel.EVENT_QUEST_PENDING_ADDED, this.onSomePendingQuestAdded, this);
	}

	public setBusinessBaseViews(views: BusinessBaseView[]): void {
		this.unlockTypeModels
			.filter(m => m instanceof TimedQuestUnlockTypeZoomOutBusinessModel)
			.forEach(m => (m as TimedQuestUnlockTypeZoomOutBusinessModel).setBusinessBaseViews(views));
	}

	private onSomePendingQuestAdded(): void {
		this.unlockTypeModels.forEach(model => model.once(TimedQuestUnlockTypeBaseModel.EVENT_UNLOCKED, this.onSomeUnlockTypeModelUnlocked, this));
		this.unlockTypeModels.forEach(model => model.activate());
	}

	private canEmitTimedQuest(): boolean {
		return !this.levelModel.isProgressCompleted() && this.levelModel.getCurrentLevel() >= this.timedQuestsMinLevel;
	}

	private onSomeUnlockTypeModelUnlocked(): void {
		if (this.canEmitTimedQuest()) {
			this.unlockTypeModels.forEach(model => model.deactivate());
			this.emit(TimedQuestsEmitter.EVENT_TIMED_QUEST);
		}
	}
}
