import { BusinessModel } from '@models/BusinessModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { ViewportUIView, ViewportUIChildType } from '@views/components/ViewportUIView';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBuyBusinessView } from './ButtonBuyBusinessView';
import { BusinessAcquireAnimation } from './BusinessAcquireAnimation';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { ViewportView } from '@views/components/ViewportView';
import { GameConstants } from '@src/utils/GameConstants';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';

export class BusinessOpenToBuyUIView extends PIXI.Container {
	public static readonly EVENT_SHOW_BUSINESS_INFO_WINDOW: symbol = Symbol();
	public static readonly EVENT_OPEN_ANIMATION_COMPLETED: symbol = Symbol();
	public static readonly EVENT_BACKGROUND_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	private readonly model: BusinessModel;
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly viewportUI: ViewportUIView;

	private readonly localizationStorage: LocalizationStorage;

	private readonly viewport: ViewportView;

	private readonly textName: PIXI.extras.BitmapText;
	private readonly buttonBuyBusiness: ButtonBuyBusinessView;
	private readonly bgLocked: PIXI.Sprite;

	private acquireAnimation: BusinessAcquireAnimation;

	private flyTextLabel: FlyBitmapText;

	constructor(
		model: BusinessModel,
		softMoneyModel: SoftMoneyModel,
		viewportUI: ViewportUIView,
		viewport: ViewportView,
	) {
		super();

		this.model = model;
		this.softMoneyModel = softMoneyModel;

		this.viewport = viewport;

		this.viewportUI = viewportUI;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.bgLocked = new PIXI.Sprite(AssetsStorage.getAtlas('businessBgAtlas')[`${this.model.getKey()}_lock`]);
		this.bgLocked.scale.set(0.5);
		this.bgLocked.interactive = true;
		this.bgLocked.on('pointertap', this.onBgPointerTap, this);

		const bgFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['locked_fade']);
		bgFade.width = this.bgLocked.width;
		bgFade.y += 25;

		this.textName = new PIXI.extras.BitmapText('', { font: '28px wendyOneShadowBold' });
		this.textName.anchor = 0.5;
		this.textName.y = -12;

		this.buttonBuyBusiness = new ButtonBuyBusinessView();
		this.buttonBuyBusiness.position.set(0, 40);
		this.buttonBuyBusiness.on(ButtonBuyBusinessView.EVENT_CLICK, this.onBuyButtonClick, this);
		this.buttonBuyBusiness.setCost(this.model.getAcquireCost().toString());

		this.buttonBuyBusiness.setCostValueEnabled(this.isEnoughSoftMoneyForBuyBusiness());
		this.toggleButtonBuyBusinessAnimation();

		this.onTranslateText();

		this.addChild(
			this.bgLocked,
			bgFade,
			this.textName as PIXI.DisplayObject,
			this.buttonBuyBusiness,
		);

		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyValueChanged, this);
		this.model.once(BusinessModel.EVENT_ACQUIRED, this.startBusinessAcquireAnimation, this);
	}

	public getKey(): string {
		return this.model.getKey();
	}

	private isEnoughSoftMoneyForBuyBusiness(): boolean {
		return this.softMoneyModel.getValue().greaterThanOrEqualTo(this.model.getAcquireCost());
	}

	private toggleButtonBuyBusinessAnimation(): void {
		if (this.isEnoughSoftMoneyForBuyBusiness()) {
			if (!this.buttonBuyBusiness.isPulsing()) {
				this.buttonBuyBusiness.startPulseAnimation();
			}
		} else {
			this.buttonBuyBusiness.stopPulseAnimation();
		}
	}

	private onBuyButtonClick(): void {
		if (this.softMoneyModel.getValue().greaterThanOrEqualTo(this.model.getAcquireCost())) {
			this.emit(BusinessOpenToBuyUIView.EVENT_BUTTON_BUY_CLICK);
		} else {
			this.showNotEnoughtMoneyFlyText();
		}
	}

	private showNotEnoughtMoneyFlyText(): void {
		if (!this.flyTextLabel) {
			const text = this.localizationStorage.getLocalizedString('#fly_text_not_enough_money');
			this.flyTextLabel = new FlyBitmapText(text, new PIXI.Point(0.5, 0.5), { font: '28px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.y = this.buttonBuyBusiness.y - 20;
			this.addChild(this.flyTextLabel);
		}
	}

	private onSoftMoneyValueChanged(): void {
		this.buttonBuyBusiness.setCostValueEnabled(this.isEnoughSoftMoneyForBuyBusiness());
		this.toggleButtonBuyBusinessAnimation();
	}

	private startBusinessAcquireAnimation(): void {
		if (!this.viewport.isZoomed()) {
			const posViewport = this.viewport.getRelativePosition(this.position, this);
			this.viewport.moveTo(posViewport.x - GameConstants.GAME_WIDTH / 2, 300);
		}

		this.bgLocked.off('pointertap', this.onBgPointerTap, this);

		this.buttonBuyBusiness.visible = false;
		this.textName.visible = false;

		this.acquireAnimation = new BusinessAcquireAnimation();
		this.acquireAnimation.on(
			BusinessAcquireAnimation.EVENT_ANIMATION_COMPLETED,
			() => {
				this.emit(BusinessOpenToBuyUIView.EVENT_OPEN_ANIMATION_COMPLETED);
				this.viewportUI.removeChildByType(this.acquireAnimation, ViewportUIChildType.BUSINESS_OPEN_ANIMATION);
			},
		);
		this.acquireAnimation.start();
		this.acquireAnimation.position = this.viewportUI.toLocal(this.acquireAnimation.position, this);
		this.viewportUI.addChildByType(this.acquireAnimation, ViewportUIChildType.BUSINESS_OPEN_ANIMATION);
	}

	public startMainUIHidingAnimation(duration: number = 100): void {
		new TWEEN.Tween(this.textName).to({ alpha: 0 }, duration).start();

		new TWEEN.Tween(this.buttonBuyBusiness)
			.to({ alpha: 0 }, duration)
			.start();
	}

	public startMainUIAppearingAnimation(duration: number = 100): void {
		new TWEEN.Tween(this.textName).to({ alpha: 1 }, duration).start();

		new TWEEN.Tween(this.buttonBuyBusiness)
			.to({ alpha: 1 }, duration)
			.start();
	}

	public onOtherViewZoomedIn(): void {
		new TWEEN.Tween(this.textName).to({ alpha: 0 }, 50).start();

		new TWEEN.Tween(this.buttonBuyBusiness)
			.to({ alpha: 0 }, 50)
			.onComplete(() => { this.buttonBuyBusiness.visible = false; })
			.start();
	}

	public onOtherViewZoomedOut(): void {
		this.buttonBuyBusiness.visible = true;
		new TWEEN.Tween(this.buttonBuyBusiness).to({ alpha: 1 }, 50).start();

		new TWEEN.Tween(this.textName).to({ alpha: 1 }, 50).start();
	}

	private onBgPointerTap(): void {
		this.emit(BusinessOpenToBuyUIView.EVENT_BACKGROUND_CLICK);
	}

	private onTranslateText(): void {
		this.textName.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);
	}

	public getButtonBuyBusiness(): PIXI.Container {
		return this.buttonBuyBusiness;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.acquireAnimation) {
			this.acquireAnimation.destroy({ children: true });
		}

		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyValueChanged, this);
		this.model.off(BusinessModel.EVENT_ACQUIRED, this.startBusinessAcquireAnimation, this, true);
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		super.destroy(options);
	}
}
