import { SoundController } from '@src/main/SoundController';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class DualStateButtonBaseView extends ButtonBaseView {
	private static readonly MAIN_STATE: number = 0;
	private static readonly SECOND_STATE: number = 1;

	protected secondButtonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container;
	protected currentState: number;

	constructor(
		buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container,
		secondButtonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container,
	) {
		super(buttonBg);

		this.secondButtonBg = secondButtonBg;
		this.secondButtonBg.visible = false;

		this.currentState = DualStateButtonBaseView.MAIN_STATE;

		this.addChild(secondButtonBg);
	}

	public setMainState(): void {
		this.setState(DualStateButtonBaseView.MAIN_STATE);
	}

	public setSecondState(): void {
		this.setState(DualStateButtonBaseView.SECOND_STATE);
	}

	public isMainState(): boolean {
		return this.currentState === DualStateButtonBaseView.MAIN_STATE;
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playButtonClick();

		this.toggleButtonState();

		this.emit(ButtonBaseView.EVENT_CLICK, this.isMainState());
	}

	protected toggleButtonState(): void {
		if (this.currentState === DualStateButtonBaseView.MAIN_STATE) {
			this.setState(DualStateButtonBaseView.SECOND_STATE);
		} else {
			this.setState(DualStateButtonBaseView.MAIN_STATE);
		}
	}

	protected setState(newState: number): void {
		if (this.currentState === newState) {
			return;
		}

		const isMain = newState === DualStateButtonBaseView.MAIN_STATE;
		this.buttonBg.visible = isMain;
		this.secondButtonBg.visible = !isMain;

		this.currentState = newState;
	}
}
