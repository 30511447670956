import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardTypes } from '@src/types/RewardTypes';
import { TimeskipModel } from '@models/TimeskipModel';

export class RewardTimeskip extends AbstractReward {
	private readonly timeskipModel: TimeskipModel;
	private readonly quantity: number;

	constructor(
		timeskipModel: TimeskipModel,
		quantity: number,
	) {
		super(RewardTypes.TIMESKIP, timeskipModel.getKey());

		this.timeskipModel = timeskipModel;
		this.quantity = quantity;
	}

	public collect(): void {
		this.timeskipModel.acquire(this.quantity);
	}

	public getQuantity(): number {
		return this.quantity;
	}

	public getModel(): TimeskipModel {
		return this.timeskipModel;
	}
}
