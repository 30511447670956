import { LevelBaseModel } from './LevelBaseModel';
import { EventLevelConfig } from '@configs/level/EventLevelConfig';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AbstractReward } from '@interfaces/AbstractReward';
import { LeagueBotModel } from '@models/LeagueBotModel';
import { EventLevelDateConfig } from '@configs/EventLevelDateConfig';

export enum EventLevelStateType {
	DEFAULT = 'default',
	REWARD_COLLECT = 'rewardCollect',
	EVENT_AVAILABLE = 'eventAvailable',
	SHOW_PROMO = 'showPromo',
}

export class EventLevelModel extends LevelBaseModel {
	public static readonly EVENT_PROGRESS_INCOME = Symbol();
	public static readonly EVENT_INITED = Symbol();
	public static readonly EVENT_TIMEOUT = Symbol();
	public static readonly EVENT_REQUEST_REWARD_COLLECT = Symbol();

	private key: string;
	private duration: number;
	private timeleft: number;
	private startDate: number;

	private leagueKeys: string[];

	private goals: SoftMoneyNumber[];
	private currentGoalProgress: SoftMoneyNumber;
	private completedGoalsCount: number;
	private incomePerSec: SoftMoneyNumber;

	private state: EventLevelStateType;

	private goalRewardsMap: Map<string, AbstractReward[]>;
	private leagueRewardsMap: Map<string, AbstractReward[]>;

	private leagueBotModels: LeagueBotModel[];

	constructor() {
		super();

		this.goals = [];
		this.timeleft = 0;
		this.startDate = 0;
		this.completedGoalsCount = 0;
		this.currentGoalProgress = SoftMoneyNumber.ZERO;
		this.state = EventLevelStateType.DEFAULT;
		this.goalRewardsMap = new Map();
		this.leagueRewardsMap = new Map();
		this.leagueBotModels = [];
		this.duration = 0;
		this.incomePerSec = SoftMoneyNumber.ZERO;
	}

	public setFromConfig(
		levelConfig: EventLevelConfig,
		dateConfig?: EventLevelDateConfig,
		currentTime: number = 0,
	): void {
		this.reset();

		super.setFromConfig(levelConfig);

		this.key = levelConfig.getKey();
		this.goals = levelConfig.getGoals();
		this.duration = levelConfig.getDuration();
		this.leagueKeys = levelConfig.getLeagueKeys();

		let timeSinceStart: number = 0;
		if (dateConfig) {
			this.startDate = dateConfig.getStartDate();
			timeSinceStart = currentTime - this.startDate;
		}
		this.timeleft = levelConfig.getDuration() - timeSinceStart;
	}

	public setFromSaveData(
		goalRewards: Map<string, AbstractReward[]>,
		leagueRewards: Map<string, AbstractReward[]>,
		leagueBotModels: LeagueBotModel[],
		timeleft?: number,
		currentGoalProgress?: SoftMoneyNumber,
	): void {
		this.state = EventLevelStateType.EVENT_AVAILABLE;
		this.goalRewardsMap = goalRewards;
		this.leagueRewardsMap = leagueRewards;
		this.leagueBotModels = leagueBotModels;
		if (timeleft !== undefined && timeleft !== null) {
			this.timeleft = timeleft;
		}
		if (currentGoalProgress) {
			this.currentGoalProgress = currentGoalProgress;
			this.completedGoalsCount = this.calculateCompletedGoalsCount();
		}
	}

	public initWithStateEventAvailable(): void {
		this.state = EventLevelStateType.EVENT_AVAILABLE;
		this.emit(EventLevelModel.EVENT_INITED);
	}

	public initWithStateRewardCollect(): void {
		this.state = EventLevelStateType.REWARD_COLLECT;
		this.emit(EventLevelModel.EVENT_INITED);
	}

	public initWithStateDefault(): void {
		this.state = EventLevelStateType.DEFAULT;
		this.emit(EventLevelModel.EVENT_INITED);
	}

	public initWithStateShowPromo(): void {
		this.state = EventLevelStateType.SHOW_PROMO;
		this.emit(EventLevelModel.EVENT_INITED);
	}

	public requestRewardCollect(): void {
		const goalRewards = this.getCompletedGoalRewards();
		const leagueRewards = this.getCompletedLeagueRewards();
		const rewards = goalRewards.concat(leagueRewards);
		this.emit(
			EventLevelModel.EVENT_REQUEST_REWARD_COLLECT,
			rewards,
		);
	}

	public setTimeleft(value: number): void {
		this.timeleft = value;
	}

	public setIncomePerSec(value: SoftMoneyNumber): void {
		this.incomePerSec = value;
	}

	public timeout(): void {
		this.timeleft = 0;
		this.emit(EventLevelModel.EVENT_TIMEOUT);
	}

	public getRunningTime(): number {
		return this.duration - this.timeleft;
	}

	public updateGoalProgress(deltaProgress: SoftMoneyNumber): void {
		const previousCompletedGoalsCount = this.completedGoalsCount;

		this.currentGoalProgress = this.currentGoalProgress.add(deltaProgress);
		this.completedGoalsCount = this.calculateCompletedGoalsCount();

		if (this.completedGoalsCount >= this.goals.length) {
			this.emit(EventLevelModel.EVENT_PROGRESS_COMPLETE, this);
		} else if (this.completedGoalsCount !== previousCompletedGoalsCount) {
			this.emit(EventLevelModel.EVENT_PROGRESS, this);
		}

		this.emit(EventLevelModel.EVENT_PROGRESS_INCOME, this);
	}

	public getCurrentGoalProgress(): SoftMoneyNumber {
		return this.currentGoalProgress;
	}

	public getLeagueBotModels(): LeagueBotModel[] {
		return this.leagueBotModels;
	}

	public getTargetGoalProgress(): SoftMoneyNumber {
		const targetGoalIndex = Math.min(this.completedGoalsCount, this.goals.length - 1);
		return this.goals[targetGoalIndex];
	}

	public getCompletedGoalCount(): number {
		return this.completedGoalsCount;
	}

	public isGoalProgressCompleted(): boolean {
		return this.completedGoalsCount >= this.goals.length;
	}

	private getCompletedGoalProgresses(): SoftMoneyNumber[] {
		return this.goals.filter(goal => this.currentGoalProgress.greaterThanOrEqualTo(goal));
	}

	private calculateCompletedGoalsCount(): number {
		let completedGoalsCount = 0;

		let targetProgress = this.goals[0];
		while (this.currentGoalProgress.greaterThanOrEqualTo(targetProgress)) {
			completedGoalsCount += 1;
			if (completedGoalsCount >= this.goals.length) {
				break;
			} else {
				targetProgress = this.goals[completedGoalsCount];
			}
		}
		return completedGoalsCount;
	}

	public getCompletedGoalRewards(): AbstractReward[] {
		const completedGoalProgresses = this.getCompletedGoalProgresses();
		return completedGoalProgresses
			.map(progress => this.goalRewardsMap.get(progress.toRawString()))
			.flat();
	}

	public getCompletedLeagueRewards(): AbstractReward[] {
		const runningTime = this.getRunningTime();
		const botsSorted = this.leagueBotModels.sort((a, b) => {
			const scoreA = a.getScore(runningTime);
			const scoreB = b.getScore(runningTime);
			return scoreB.subtract(scoreA).toNumber();
		});

		let playerListItemId = botsSorted.findIndex(bot => bot.getScore(runningTime).lessThanOrEqualTo(this.incomePerSec));
		if (playerListItemId === -1) {
			playerListItemId = botsSorted.length;
		}
		const playerListItemIdStr = playerListItemId.toString();

		return this.leagueRewardsMap.has(playerListItemIdStr)
			? this.leagueRewardsMap.get(playerListItemIdStr)
			: [];
	}

	public getPlayerPlace(): number {
		const runningTime = this.getRunningTime();
		const botsSorted = this.leagueBotModels.sort((a, b) => {
			const scoreA = a.getScore(runningTime);
			const scoreB = b.getScore(runningTime);
			return scoreB.subtract(scoreA).toNumber();
		});

		let playerListItemId = botsSorted.findIndex(bot => bot.getScore(runningTime).lessThanOrEqualTo(this.incomePerSec));
		if (playerListItemId === -1) {
			playerListItemId = botsSorted.length;
		}
		return playerListItemId;
	}

	public getKey(): string {
		return this.key;
	}

	public getTargetGoalRewards(): AbstractReward[] {
		const progress = this.getTargetGoalProgress().toRawString();
		return this.goalRewardsMap.get(progress);
	}

	public hasRewards(): boolean {
		return this.getCompletedGoalProgresses().length > 0 || this.getCompletedLeagueRewards().length > 0;
	}

	public getGoalRewardsMap(): Map<string, AbstractReward[]> {
		return this.goalRewardsMap;
	}

	public getLeagueRewardsMap(): Map<string, AbstractReward[]> {
		return this.leagueRewardsMap;
	}

	public getIncomePerSec(): SoftMoneyNumber {
		return this.incomePerSec;
	}

	public getLeagueKeys(): string[] {
		return this.leagueKeys;
	}

	public getTimeleft(): number {
		return this.timeleft;
	}

	public getDuration(): number {
		return this.duration;
	}

	public getGoals(): SoftMoneyNumber[] {
		return this.goals;
	}

	public getState(): EventLevelStateType {
		return this.state;
	}

	public getStartDate(): number {
		return this.startDate;
	}

	private reset(): void {
		this.goalRewardsMap.clear();
		this.leagueRewardsMap.clear();
		this.currentGoalProgress = SoftMoneyNumber.ZERO;
		this.completedGoalsCount = 0;
		this.timeleft = 0;
		this.startDate = 0;
		this.goals = [];
		this.leagueBotModels = [];
		this.incomePerSec = SoftMoneyNumber.ZERO;
	}
}
