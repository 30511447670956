import { CharacterCollectionsCardView } from './CharacterCollectionsCardView';
import { CollectionWithProgressBaseView } from '@views/windows/collection/CollectionWithProgressBaseView';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';
import { HintDataPosition, HintTypes } from '@src/types/HintTypes';

export class CharacterCollectionView extends CollectionWithProgressBaseView {
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();

	private characterKeyCardMap: Map<string, CharacterCollectionsCardView>;

	constructor(
		characterModels: CharacterModel[],
		skillModels: Map<string, SkillModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		withMoneyPanels?: boolean,
		questCollectCardsModel?: EpicQuestCollectionsModel,
	) {
		super(
			prestigeMoneyModel,
			hardMoneyModel,
			{ begin: 0, end: 200 },
			questCollectCardsModel,
			'#collection_window_girls_reward_progress',
		);

		this.characterKeyCardMap = new Map();

		let i = 0;
		characterModels.forEach((model) => {
			const x = 312 * (i % 4) + 150;
			const y = 473 * (Math.floor(i / 4)) + 283;
			const card = new CharacterCollectionsCardView(
				model,
				skillModels.get(model.getSkillKey()),
			);
			card.position.set(x, y);
			card.on(CharacterCollectionsCardView.EVENT_CLICK, (data: PromotableClickData) => {
				if (!this.scrollView.getIsScrolling()) {
					this.emit(CharacterCollectionView.EVENT_CARD_CLICK, data);
				}
			}, this);
			this.contentContainer.addChild(card);

			this.characterKeyCardMap.set(model.getKey(), card);
			i += 1;
		});

		this.scrollView.position.set(536, 62);

		if (withMoneyPanels) {
			const hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
			hardMoneyPanel.on(
				HardMoneyPanelView.EVENT_SHOW_HINT,
				(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
					const data: HintDataPosition = { arrowPosLocal, origin };
					this.emit(CharacterCollectionView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, data);
				},
			);
			hardMoneyPanel.position.set(1630, 40);

			const prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel);
			prestigeMoneyPanel.on(
				PrestigeMoneyPanelView.EVENT_SHOW_HINT,
				(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
					const data: HintDataPosition = { arrowPosLocal, origin };
					this.emit(CharacterCollectionView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, data);
				},
			);
			prestigeMoneyPanel.position.set(1370, 40);

			prestigeMoneyPanel.on(
				PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
				() => this.emit(CharacterCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK),
				this,
			);
			hardMoneyPanel.on(
				HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
				() => this.emit(CharacterCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK),
				this,
			);

			this.addChild(
				hardMoneyPanel,
				prestigeMoneyPanel as PIXI.DisplayObject,
			);
		}

		this.scrollView.updateBounds();
	}

	public scrollTo(key: string): void {
		const card = this.characterKeyCardMap.get(key);
		this.scrollView.jumpTo(-card.y + card.height / 2);
	}

	public getOpenedItemContainer(key: string): PIXI.Container | undefined {
		return this.characterKeyCardMap.get(key).getOpenedItemContainer();
	}
}
