import { EventLevelModel } from '@models/level/EventLevelModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class ModelWriterEventLevelSoftMoney {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly eventLevelModel: EventLevelModel;

	constructor(
		eventLevelModel: EventLevelModel,
		softMoneyModel: SoftMoneyModel,
	) {
		this.softMoneyModel = softMoneyModel;
		this.eventLevelModel = eventLevelModel;

		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_INCREASED, this.onSoftMoneyValueIncreased, this);
	}

	private onSoftMoneyValueIncreased(deltaIncrease: SoftMoneyNumber): void {
		if (!this.eventLevelModel.isGoalProgressCompleted()) {
			this.eventLevelModel.updateGoalProgress(deltaIncrease);
		}
	}
}
