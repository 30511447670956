export class SummonParticleConfig {
	public static getFlare1(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.8,
				end: 0.01,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: color,
				end: color,
			},
			speed: {
				start: 100,
				end: 100,
				minimumSpeedMultiplier: 99,
			},
			acceleration: {
				x: 0,
				y: 0.2,
			},
			maxSpeed: 100,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 10,
			},
		};
	}

	public static getFlare2(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0.1,
			},
			scale: {
				start: 10,
				end: 0.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: color,
				end: color,
			},
			speed: {
				start: 8,
				end: 11,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 1,
			maxParticles: 3,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 10,
			},
		};
	}

	public static getGlow1(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1,
				end: 0.5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: color,
				end: color,
			},
			speed: {
				start: -300,
				end: -130,
				minimumSpeedMultiplier: 0,
			},
			startRotation: {
				min: 90,
				max: 90,
			},
			acceleration: {
				x: 0,
				y: -40,
			},
			maxSpeed: 40,
			noRotation: true,
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.15,
			emitterLifetime: 0.8,
			maxParticles: 5,
			pos: {
				x: -110,
				y: -150,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 220,
				h: 300,
			},
		};
	}

	public static getGlow2(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 2,
				end: 1,
			},
			scale: {
				start: 3,
				end: 1,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#000000',
				end: color,
			},
			noRotation: true,
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 1,
			maxParticles: 6,
			pos: {
				x: -100,
				y: -100,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 200,
				h: 200,
			},
		};
	}

	public static getGlow3(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0.01,
			},
			scale: {
				start: 1,
				end: 0.7,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#000000',
				end: color,
			},
			noRotation: true,
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.5,
			maxParticles: 5,
			pos: {
				x: -90,
				y: -100,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 180,
				h: 200,
			},
		};
	}

	public static getEnergyFlare1(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1,
				end: 0.01,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: color,
				end: color,
			},
			speed: {
				start: -580,
				end: -580,
				minimumSpeedMultiplier: 1,
			},
			maxSpeed: 400,
			lifetime: {
				min: 0.2,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.08,
			emitterLifetime: 2,
			maxParticles: 60,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'ring',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 220,
				minR: 160,
			},
		};
	}

	public static getGlow4(color: string = '#e4f9ff'): any {
		return {
			alpha: {
				start: 1,
				end: 0.001,
			},
			scale: {
				start: 0.2,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: color,
				end: color,
			},
			noRotation: true,
			lifetime: {
				min: 2,
				max: 2,
			},
			blendMode: 'screen',
			frequency: 0.3,
			emitterLifetime: 2,
			maxParticles: 5,
			pos: {
				x: -100,
				y: -100,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 200,
				h: 200,
			},
		};
	}

	public static getFlareAfterOpen(color: string): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.7,
				end: 0,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#fffffff',
				end: color,
			},
			acceleration: {
				x: 0,
				y: -10,
			},
			lifetime: {
				min: 1,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.8,
			emitterLifetime: -1,
			maxParticles: 3,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -100,
				y: -150,
				w: 200,
				h: 300,
			},
		};
	}

	public static getRings(color: string): any {
		return {
			alpha: {
				start: 1,
				end: 0.1,
			},
			scale: {
				start: 1.5,
				end: 0,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#000000',
				end: color,
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 2,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 0,
			},
		};
	}
}
