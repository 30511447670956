import { BaseAction } from './BaseAction';

export class BackToGameRewardAction extends BaseAction {
	constructor(
		improved: boolean,
		offlineSeconds: number,
		amountTable: { [key: string]: string },
	) {
		super('BackToGameRewards');

		this.data.improved = improved;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.offline_seconds = offlineSeconds;
		this.data.amount = amountTable;
	}
}
