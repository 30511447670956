import { LevelStartWindowView } from '@views/windows/levelStartWindow/LevelStartWindowView';
import { LevelStartWindowViewSetter } from '@interfaces/ViewSetters';
import { LevelModel } from '@models/level/LevelModel';

export type EventButtonStartClickEventArgs = {
	currentLevel: number;
};

export class AnalyticSourceLevelStartWindowView extends PIXI.utils.EventEmitter implements LevelStartWindowViewSetter {
	public static readonly EVENT_BUTTON_START_CLICK: symbol = Symbol();

	private levelModel: LevelModel;

	constructor(levelModel: LevelModel) {
		super();

		this.levelModel = levelModel;
	}

	public setLevelStartWindowView(view: LevelStartWindowView): void {
		view.once(LevelStartWindowView.EVENT_BUTTON_START_CLICK, () => {
			const args: EventButtonStartClickEventArgs = {
				currentLevel: this.levelModel.getCurrentLevel(),
			};
			this.emit(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, args);
		}, this);
	}
}
