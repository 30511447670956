import { CollectionWithProgressBaseView } from '@views/windows/collection/CollectionWithProgressBaseView';
import { UpgradeModel } from '@models/UpgradeModel';
import { UpgradeGroupCollectionView } from '@views/windows/collection/upgrades/UpgradeGroupCollectionView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export class FurnitureCollectionView extends CollectionWithProgressBaseView {
	public static readonly EVENT_CARD_CLICK = Symbol();

	private upgradeKeyGroupMap: Map<string, UpgradeGroupCollectionView>;

	constructor(
		upgradesGroupedByBusiness: Map<string, UpgradeModel[]>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		questCollectCardsModel?: EpicQuestCollectionsModel,
	) {
		super(
			prestigeMoneyModel,
			hardMoneyModel,
			{ begin: 0, end: 150 },
			questCollectCardsModel,
			'#collection_window_furniture_reward_progress',
		);

		this.upgradeKeyGroupMap = new Map();

		this.scrollView.position.set(420, 120);

		let i = 0;
		upgradesGroupedByBusiness.forEach((upgrades, businessKey) => {
			const groupView = new UpgradeGroupCollectionView(
				businessKey,
				upgrades,
				55,
			);
			groupView.y = i * 477;
			groupView.on(UpgradeGroupCollectionView.EVENT_CARD_CLICK, (data: PromotableClickData) => {
				if (!this.scrollView.getIsScrolling()) {
					this.emit(FurnitureCollectionView.EVENT_CARD_CLICK, data);
				}
			}, this);
			this.contentContainer.addChild(groupView);

			upgrades.forEach(upgrade => this.upgradeKeyGroupMap.set(upgrade.getKey(), groupView));
			i += 1;
		});

		this.scrollView.updateBounds();
	}

	public scrollTo(key: string): void {
		const card = this.upgradeKeyGroupMap.get(key);
		this.scrollView.jumpTo(-card.y);
	}

	public getOpenedItemContainer(key: string): PIXI.Container | undefined {
		return this.upgradeKeyGroupMap.get(key).getOpenedItemContainer(key);
	}
}
