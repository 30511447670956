import { NetworkRequestSender } from '../NetworkRequestSender';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { TransactionOptions, TransactionResult } from '@src/types/TransactionTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { ModelHelper } from '@models/ModelHelper';
import { TransactionSaveData } from '@src/types/SaveTypes';
import { DeviceUtils } from '@src/utils/DeviceUtils';
import { NetworkUtils } from '@src/utils/NetworkUtils';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

export class NutakuTransactionCreator extends PIXI.utils.EventEmitter implements TransactionCreator {
	private static readonly ITEM_FIELD_ID = 'itemId';
	private static readonly ITEM_FIELD_UNIT_PRICE = 'unitPrice';
	private static readonly ITEM_FIELD_QUANTITY = 'quantity';
	private static readonly ITEM_FIELD_DESCRIPTION = 'description';
	private static readonly ITEM_FIELD_ITEM_NAME = 'itemName';
	private static readonly ITEM_FIELD_IMAGE_URL = 'imageUrl';
	private static readonly FINISH_PAGE_URL = `${CDN}/index.nutaku.html`;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly assetUrlsLoader: AssetUrlsLoader,
	) {
		super();
	}

	public async buy(
		bankElementKeyWithPrefix: string,
		price: number,
		options: TransactionOptions,
	): Promise<TransactionResult> {
		const transactionId: string | undefined = await this.createNutakuPaymentRequest(bankElementKeyWithPrefix, price, options);

		if (typeof transactionId === 'string') {
			return this.sendCheckPurchase(transactionId);
		}

		return Promise.reject(transactionId);
	}

	public check(saveData: TransactionSaveData): Promise<TransactionResult> {
		return this.sendCheckPurchase(saveData.paymentId);
	}

	private async sendCheckPurchase(transactionId: string): Promise<TransactionResult> {
		const result: {
			rewards: RewardDescriptionType[];
			// eslint-disable-next-line @typescript-eslint/naming-convention
			transaction_id: string;
			nctxnid: number;
		} = await this.networkRequestSender.sendBankCheckNutakuPurchase(transactionId);
		if (result?.rewards) {
			return {
				rewards: ModelHelper.mergeRewardDescriptions(result.rewards),
				transactionId,
				notCommitedTransactionId: result.nctxnid,
			};
		}
		return Promise.reject();
	}

	private createItem(key: string, price: number, options: TransactionOptions): opensocial.BillingItem {
		const params: Record<string, unknown> = {};

		params[opensocial.BillingItem.Field.SKU_ID] = `${key}:${options.eventKey}`;
		params[opensocial.BillingItem.Field.PRICE] = price;
		params[opensocial.BillingItem.Field.COUNT] = 1;
		params[opensocial.BillingItem.Field.DESCRIPTION] = options.description;
		params[nutaku.BillingItem.Field.NAME] = options.name;
		params[nutaku.BillingItem.Field.IMAGE_URL] = this.createImageUrl(options.image, options.eventKey);

		return opensocial.newBillingItem(params);
	}

	private createItemSP(key: string, price: number, options: TransactionOptions): Record<string, unknown> {
		const params: Record<string, unknown> = {};

		params[NutakuTransactionCreator.ITEM_FIELD_ID] = `${key}:${options.eventKey}`;
		params[NutakuTransactionCreator.ITEM_FIELD_UNIT_PRICE] = price;
		params[NutakuTransactionCreator.ITEM_FIELD_QUANTITY] = 1;
		params[NutakuTransactionCreator.ITEM_FIELD_DESCRIPTION] = options.description;
		params[NutakuTransactionCreator.ITEM_FIELD_ITEM_NAME] = options.name;
		params[NutakuTransactionCreator.ITEM_FIELD_IMAGE_URL] = this.createImageUrl(options.image, options.eventKey);

		return params;
	}

	// eslint-disable-next-line class-methods-use-this
	private createPayment(item: opensocial.BillingItem): opensocial.Payment {
		const params: Record<string, unknown> = {};
		params[opensocial.Payment.Field.ITEMS] = [item];
		params[opensocial.Payment.Field.PAYMENT_TYPE] = opensocial.Payment.PaymentType.PAYMENT;
		return opensocial.newPayment(params);
	}

	private async createNutakuPaymentRequest(key: string, price: number, options: TransactionOptions): Promise<string | undefined> {
		let result;

		if (DeviceUtils.isAndroid() || DeviceUtils.isIos()) {
			const item = this.createItemSP(key, price, options);
			await this.createNutakuPaymentRequestSP([item]);
		} else {
			const item = this.createItem(key, price, options);
			const payment = this.createPayment(item);
			result = this.createNutakuPaymentRequestPC(payment);
		}

		return result;
	}

	// eslint-disable-next-line class-methods-use-this
	private createNutakuPaymentRequestPC(payment: opensocial.Payment): Promise<string> {
		return new Promise((resolve, reject) => {
			opensocial.requestPayment(payment, (response: opensocial.ResponseItem) => {
				if (response.hadError()) {
					reject(response.getErrorMessage());
				} else {
					const result: opensocial.ResponseObject = response.getData();
					const responseCode = result.getField(opensocial.Payment.Field.RESPONSE_CODE);
					if (responseCode !== opensocial.Payment.ResponseCode.OK) {
						reject(responseCode);
					} else {
						resolve(result.getField(nutaku.Payment.Field.PAYMENT_ID));
					}
				}
			});
		});
	}

	private async createNutakuPaymentRequestSP(paymentItems: unknown[]): Promise<void> {
		const response = await this.networkRequestSender.sendNutakuCreatePayment(
			paymentItems,
			NutakuTransactionCreator.FINISH_PAGE_URL,
		);

		const data = response.entry;
		const url = data.transactionUrl as string;

		return new Promise(() => {
			NetworkUtils.setCurrentURL(url);
		});
	}

	private createImageUrl(image: string, event: string): string {
		const urls = this.assetUrlsLoader.getAssetUrls();
		let filename: string;

		if (event === 'season') {
			filename = `nutaku_${image}.png`;
		} else {
			filename = `nutaku${event}_${image}.png`;
		}

		return urls.get(filename);
	}
}
