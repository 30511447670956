import {
	TutorialStepUnlockTypeBaseConfig, TutorialStepUnlockTypeEventGoalCompletedConfig, TutorialStepUnlockTypeEventStartConfig,
} from '@configs/tutorialSteps/TutorialStepUnlockTypeConfigs';
import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { BusinessModel } from '@models/BusinessModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepUnlockTypeBaseFactory } from './TutorialStepUnlockTypesBaseFactory';
import { TutorialStepBaseModel } from '../TutorialStepBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepUnlockTypeEventStartModel } from './TutorialStepUnlockTypeEventStartModel';
import { TutorialStepUnlockTypeEventRewardsWindowAppearModel } from './TutorialStepUnlockTypeWindowAppearModels';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { TutorialStepUnlockTypeEventGoalCompletedModel } from './TutorialStepUnlockTypeEventGoalCompletedModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { CharacterModel } from '@models/CharacterModel';

export class TutorialStepUnlockTypeEventLevelFactory extends TutorialStepUnlockTypeBaseFactory {
	constructor(
		windowViewSystem: WindowViewSystem,
		private readonly tutorialStepUnlockTypeConfigs: TutorialStepUnlockTypeBaseConfig[],
		tutorialStepModels: Map<string, TutorialStepBaseModel>,
		businessModels: Map<string, BusinessModel>,
		questLinesModel: QuestLinesModel,
		private readonly eventLevelEverEntered: boolean,
		private readonly eventLevelModel: EventLevelModel,
		characterModels: Map<string, CharacterModel>,
	) {
		super(
			windowViewSystem,
			tutorialStepModels,
			businessModels,
			questLinesModel,
			characterModels,
		);
	}

	public createModels(): Map<string, TutorialStepUnlockTypeBaseModel> {
		const result: Map<string, TutorialStepUnlockTypeBaseModel> = new Map();

		this.tutorialStepUnlockTypeConfigs.forEach((unlockTypeConfig) => {
			const key = unlockTypeConfig.getKey();
			const stepModel = this.tutorialStepModels.get(key);
			if (!stepModel.isCompleted()) {
				const model = this.createModel(unlockTypeConfig);
				result.set(unlockTypeConfig.getKey(), model);
			}
		});

		return result;
	}

	protected createModel(config: TutorialStepUnlockTypeBaseConfig): TutorialStepUnlockTypeBaseModel {
		switch (config.getType()) {
			case TutorialStepUnlockTypes.EVENT_START: {
				const eventStartConfig = config as TutorialStepUnlockTypeEventStartConfig;

				return new TutorialStepUnlockTypeEventStartModel(
					eventStartConfig,
					this.eventLevelEverEntered,
					this.eventLevelModel.getKey(),
				);
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_EVENT_REWARDS: {
				return new TutorialStepUnlockTypeEventRewardsWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.GOAL_COMPLETED: {
				const goalCompletedConfig = config as TutorialStepUnlockTypeEventGoalCompletedConfig;

				return new TutorialStepUnlockTypeEventGoalCompletedModel(
					goalCompletedConfig,
					this.eventLevelModel,
				);
			}

			default:
				return super.createModel(config);
		}
	}
}
