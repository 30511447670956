import { AbstractQuest } from '../AbstractQuest';

export type PendingTimedQuestData = {
	key: string;
	completeValue: string;
	time: number;
	dialogKey: string;
}

export class TimedQuestLinesModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_QUEST_CURRENT_ADED: symbol = Symbol();
	public static readonly EVENT_QUEST_PENDING_ADDED: symbol = Symbol();

	public static readonly EVENT_QUEST_COMPLETED: symbol = Symbol();
	public static readonly EVENT_QUEST_TIMEOUT: symbol = Symbol();

	private current?: AbstractQuest;
	private currentQuestFinished: boolean;
	private pending: boolean;
	private pendingQuestData: PendingTimedQuestData;

	public setPending(data?: PendingTimedQuestData): void {
		this.pending = true;
		this.pendingQuestData = data;

		this.emit(TimedQuestLinesModel.EVENT_QUEST_PENDING_ADDED);
	}

	public finishCurrentQuest(): void {
		this.setCurrentQuestFinished();
	}

	public setCurrentQuest(quest: AbstractQuest): void {
		this.pending = false;
		this.currentQuestFinished = false;

		this.current = quest;
		this.current.once(AbstractQuest.EVENT_COMPLETED, this.onCurrentQuestCompleted, this);
		this.current.once(AbstractQuest.EVENT_TIMEOUT, this.onCurrentQuestTimeout, this);

		this.emit(TimedQuestLinesModel.EVENT_QUEST_CURRENT_ADED, quest);
	}

	private onCurrentQuestTimeout(): void {
		this.setCurrentQuestFinished();
		this.emit(TimedQuestLinesModel.EVENT_QUEST_TIMEOUT, this.current);
	}

	private onCurrentQuestCompleted(): void {
		this.setCurrentQuestFinished();
		this.emit(TimedQuestLinesModel.EVENT_QUEST_COMPLETED, this.current);
	}

	private setCurrentQuestFinished(): void {
		this.currentQuestFinished = true;
		if (this.current?.listeners(AbstractQuest.EVENT_COMPLETED).includes(this.onCurrentQuestCompleted)) {
			this.current.off(AbstractQuest.EVENT_COMPLETED, this.onCurrentQuestCompleted, this, true);
		}
	}

	public hasPendingQuest(): boolean {
		return this.pending;
	}

	public getPendingQuestData(): PendingTimedQuestData {
		return this.pendingQuestData;
	}

	public hasCurrentActiveQuest(): boolean {
		return Boolean(this.current && !this.currentQuestFinished);
	}

	public getCurrentQuest(): AbstractQuest | undefined {
		return this.current;
	}
}
