import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { BoostModel } from '@models/BoostModel';

export class BoostCardView extends PIXI.Container {
	constructor(
		model: BoostModel,
	) {
		super();

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const localizationStorage = LocalizationStorage.getInstance();

		const icon = new PIXI.Sprite(collectionAtlas[model.getIconKey()]);
		icon.scale.set(0.6);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['booster_card']);

		const title = new TextField(
			localizationStorage.getLocalizedString('#bank_income_boost'),
			{ font: '38px wendyOneShadowBold', align: 'center' },
			200,
			100,
		);
		title.anchor = 0.5;
		title.y = -229;

		const textQuantity = new PIXI.extras.BitmapText(`x${model.getBonusValue()}`, { font: '100px wendyOneGold', align: 'center' });
		textQuantity.position.set(50, 100);
		textQuantity.anchor = 0.5;

		this.addChild(
			bg,
			icon,
			title as PIXI.DisplayObject,
			textQuantity,
		);
	}
}
