import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20012View extends CharacterMainBaseView {
	constructor() {
		super('character20012');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(92, -7);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(140, -49),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(220, -52),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
