export default class LocalizationStorage extends PIXI.utils.EventEmitter {
	public static readonly EVENT_NEW_LANGUAGE = Symbol();

	public static getLocalizationKeyPrefixEvent(eventKey: string): string {
		return `event${eventKey}_`;
	}

	private static instance: LocalizationStorage;

	public static getInstance(): LocalizationStorage {
		if (!LocalizationStorage.instance) {
			LocalizationStorage.instance = new LocalizationStorage();
		}

		return LocalizationStorage.instance;
	}

	public static getFormattedLanguageName(language?: string): string {
		let languageUnformatted: string;

		if (language) {
			languageUnformatted = language;
		} else {
			languageUnformatted = window.navigator.language;
		}

		const languageFormatted = languageUnformatted.substring(0, 2).toLowerCase();

		return languageFormatted;
	}

	private translates: Record<string, string>[];
	private language: string;
	private showLocalizationKeys: boolean;

	private constructor() {
		super();

		this.translates = [];
	}

	public setLocalization(
		language: string,
		data: Record<string, string>[],
	): void {
		this.translates = data;
		this.language = language;
	}

	public replaceLocalizationKey(key: string, value: string): void {
		const translate = this.translates.filter((t) => t[key] !== undefined)?.[0];

		if (translate) {
			translate[key] = value;
		} else if (MODE_DEBUG) {
			// eslint-disable-next-line no-console
			console.error(`Failed replacing localization key '${key}'`);
		}
	}

	public addToLocalization(data: Record<string, string>): void {
		this.translates.push(data);
	}

	public updateLocalization(): void {
		this.emit(LocalizationStorage.EVENT_NEW_LANGUAGE);
	}

	public toggleShowLocalizationKeys(): void {
		this.showLocalizationKeys = !this.showLocalizationKeys;
		this.emit(LocalizationStorage.EVENT_NEW_LANGUAGE);
	}

	public hasLocalizedString(key: string): boolean {
		return this.translates.some((translate) => translate[key] !== undefined);
	}

	public getLanguage(): string {
		return this.language;
	}

	public getLocalizedString(key: string): string {
		if (MODE_DEBUG && !this.hasLocalizedString(key)) {
			// eslint-disable-next-line no-console
			console.warn(`Undefined localization key: ${key}`);
		}

		let result: string;

		if (this.showLocalizationKeys) {
			result = key;
		} else {
			result = this.translates.find((translate) => translate[key] !== undefined)?.[key]
				|| key;
		}

		return result;
	}
}
