import { BaseLinksView, PreloaderButtonDataType } from './BaseLinksView';


export class NutakuPreloaderLinksView extends BaseLinksView {
	// eslint-disable-next-line class-methods-use-this
	protected getButtonsData(): PreloaderButtonDataType[] {
		return [
			{
				text: 'Contact Us',
				link: 'mailto:info@cuntempire.com',
			},
			{
				text: '2257 Exempt',
				link: `${CDN}/docs/2257.html`,
			},
			{
				text: 'Privacy Policy',
				link: `${CDN}/docs/privacy_policy.html`,
			},
			{
				text: 'Terms of Use',
				link: `${CDN}/docs/terms_of_use.html`,
			},
		];
	}
}
