import { TutorialStepDataBase } from './TutorialStepDataBase';
import { TutorialStepBaseConfig } from '../TutorialStepBaseConfig';

export class TutorialStepCharacterAnimationData extends TutorialStepDataBase {
	private readonly tapCount: number;
	private readonly characterKey: string;
	private readonly businessKey: string;

	constructor(config: { [key: string]: string }) {
		super(config);

		const param = config[TutorialStepBaseConfig.FIELD_PARAMETER].split(',').map((str: string) => str.trim());
		[this.businessKey, this.characterKey, this.tapCount] = [param[0], param[1], Number(param[2])];
	}

	public getCharacterKey(): string {
		return this.characterKey;
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public getTapCount(): number {
		return this.tapCount;
	}
}
