import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30007View extends CharacterMainBaseView {
	constructor() {
		super('character30007');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(118, 34);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(172, -39),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(130, -50),
			invertBg: false,
		});

		this.initQuickPhrases(this.key);
	}
}
