import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100004View } from '@views/characters/events/event10/EventCharacter100004View';
import { EventCharacter100005View } from '@views/characters/events/event10/EventCharacter100005View';
import { EventCharacter100006View } from '@views/characters/events/event10/EventCharacter100006View';

export class EventBusiness100002View extends BusinessBaseView {
	constructor() {
		const eventCharacter100004View = new EventCharacter100004View();
		const eventCharacter100005View = new EventCharacter100005View();
		const eventCharacter100006View = new EventCharacter100006View();

		super('business100002', [eventCharacter100004View, eventCharacter100005View, eventCharacter100006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor3']).setTransform(-241, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor2']).setTransform(233, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor5']).setTransform(291, 14, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b3_decor1']).setTransform(11, 82, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter100004View as PIXI.DisplayObject,
			eventCharacter100005View,
			eventCharacter100006View,
		);
	}
}
