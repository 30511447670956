import { AbstractReward } from '@interfaces/AbstractReward';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { ScrollView } from '@views/components/ScrollView';
import { ScrollAxis } from '@src/types/ScrollViewTypes';

export class EventLevelRewardsHorizontalListView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD: symbol = Symbol();

	private readonly cardMiniViewFactory: CardMiniViewFactory;

	private readonly contentContainer: PIXI.Container;
	private scrollView: ScrollView;

	constructor(
		width: number,
		height: number,
		rewards: AbstractReward[],
		cardMiniViewFactory: CardMiniViewFactory,
		rewardCardCreatedHandler?: (card: PIXI.Container, i: number) => void,
	) {
		super();

		this.cardMiniViewFactory = cardMiniViewFactory;

		this.contentContainer = new PIXI.Container();

		const contentRewards: PIXI.Container[] = rewards.map((reward, i) => {
			const card = this.cardMiniViewFactory.createRewardCardByModel(
				reward,
				true,
				1,
				1,
				CardMiniViewFactory.createStickerNew(),
			);
			card.interactive = true;
			card.on(
				'pointertap',
				() => {
					if (!this.scrollView.getIsScrolling()) {
						const rewardDescription = reward.getRewardDescription();
						this.emit(EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD, rewardDescription, card);
					}
				},
			);
			// eslint-disable-next-line no-unused-expressions
			rewardCardCreatedHandler?.(card, i);
			return card;
		});

		let contentRewardsOffset: number = 45;
		const totalRewardsWidth: number = (contentRewards.length - 1) * 135;
		if (totalRewardsWidth + contentRewardsOffset * 2 < width) {
			contentRewardsOffset = (width - totalRewardsWidth) / 2;
		}
		contentRewards.forEach((contentReward, i) => {
			contentReward.scale.set(0.85, 0.85);
			contentReward.x += contentRewardsOffset + 135 * i;
			contentReward.y = height / 2;
		});

		this.contentContainer.addChild(
			...contentRewards,
		);

		this.scrollView = new ScrollView(width, height, ScrollAxis.HORIZONTAL, { begin: 45, end: 0 })
			.setContent(this.contentContainer)
			.setWheelScrollDirection(-1);
		if (totalRewardsWidth + 90 >= width) {
			this.scrollView.jumpTo(45);
		}
		this.scrollView.position.set(-width / 2, -height / 2);

		this.addChild(this.scrollView);
	}
}
