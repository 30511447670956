import { LevelGameUIViewSetter } from '@interfaces/ViewSetters';
import { CheatModel } from '@models/CheatModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { LevelModel } from '@models/level/LevelModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { DialogsFactory } from '@src/initializers/DialogsFactory';
import { LevelWindowViewFactory } from '@src/initializers/windows/LevelWindowViewFactory';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { TimedQuestsLoader } from '@src/loaders/TimedQuestsLoader';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { QuestWindowViewMode } from '@views/windows/quests/QuestWindowBaseView';
import { RewardResultWindowRewardStringType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BaseQuestWindowViewController } from './BaseQuestWindowViewController';
import {
	ShowLevelProgressDialogWindowChainable,
	ShowAccountNotCreatedWindowChainable,
	ShowTutorialStepGetQuestWindowChainable,
	ShowQuestLootboxWindowChainable,
} from './mainUI/ShowWindowChainables';
import { TutorialStepWindowViewTargetLevelDispatcher } from './tutorialSteps/TutorialStepWindowViewLevelTargetDispatcher';

export class LevelQuestWindowViewController extends BaseQuestWindowViewController implements LevelGameUIViewSetter {
	private gameUI: LevelGameUIView;

	constructor(
		private readonly cheatModel: CheatModel,
		private readonly levelModel: LevelModel,
		private readonly timedQuestsLoader: TimedQuestsLoader,
		questsLoader: QuestsLoaderBase,
		private readonly gameProfileModel: GameProfileModel,
		private readonly dialogsFactory: DialogsFactory,
		private readonly questLinesModel: QuestLinesModel,
		private readonly tutorialStepsEmitter: TutorialStepsEmitter,
		private readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetLevelDispatcher,
		windowViewSystem: WindowViewSystem,
		windowFactory: LevelWindowViewFactory,
	) {
		super(questsLoader, windowViewSystem, windowFactory);
	}

	public setLevelGameUIView(view: LevelGameUIView): void {
		this.gameUI = view;
	}

	protected onButtonCollectClick(quest: AbstractQuest): void {
		if (this.cheatModel.getQuestWindowMode() === QuestWindowViewMode.ALWAYS_COLLECT
			&& !quest.isCompleted()) {
			quest.complete();
		}

		if (quest.hasTime()) {
			const showQuestLootboxWindowChainable = new ShowQuestLootboxWindowChainable(
				this.windowViewSystem,
				this.windowFactory,
				quest.getLootbox(),
				RewardResultWindowRewardStringType.REWARD_SUMMARY,
			);

			this.questWindow.close();

			this.timedQuestsLoader.finishQuest(quest);

			showQuestLootboxWindowChainable
				.start()
				.then(() => {
					this.gameUI.hideTimedQuestLine();
					this.timedQuestsLoader.tryCreateNextQuest();
				});
		} else {
			const showLevelProgressDialogWindow = new ShowLevelProgressDialogWindowChainable(
				this.windowViewSystem,
				this.windowFactory,
				this.dialogsFactory,
				this.levelModel,
				this.cheatModel.isEmitterDialogWindowsEnabled(),
			);

			this.onFinishQuest(quest)
				.then(() => showLevelProgressDialogWindow.start())
				.then(() => {
					this.updateLevelProgress();

					this.gameUI.updateQuestLines(this.questLinesModel.getCurrentQuests());

					const progressCompleted = this.levelModel.isProgressCompleted();
					if (progressCompleted) {
						this.gameUI.onProgressCompleted();
					} else {
						const showAccountNotCreatedWindowView = new ShowAccountNotCreatedWindowChainable(
							this.windowViewSystem,
							this.windowFactory,
							this.gameProfileModel,
							this.levelModel,
						);
						const showTutorialStepGetQuestWindow = new ShowTutorialStepGetQuestWindowChainable(
							this.windowViewSystem,
							this.windowFactory,
							this.tutorialStepsEmitter,
							this.tutorialStepsViewTargetDispatcher,
						);

						showAccountNotCreatedWindowView
							.start()
							.then(() => showTutorialStepGetQuestWindow.start());
					}
				});
		}
	}

	private updateLevelProgress(): void {
		this.gameUI.setLevelProgress(
			this.levelModel.getCurrentProgress(),
			this.questLinesModel.getPendingProgress(),
			true,
		);
	}
}
