import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10013View } from '@views/characters/events/event1/EventCharacter10013View';
import { EventCharacter10014View } from '@views/characters/events/event1/EventCharacter10014View';
import { EventCharacter10015View } from '@views/characters/events/event1/EventCharacter10015View';


export class EventBusiness10005View extends BusinessBaseView {
	constructor() {
		const character10013 = new EventCharacter10013View();
		const character10014 = new EventCharacter10014View();
		const character10015 = new EventCharacter10015View();

		super('business10005', [character10013, character10014, character10015]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character10013 as PIXI.DisplayObject,
			character10014,
			character10015,
		);
	}
}
