import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { ButtonBackgroundType, ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { TextField } from '@views/components/text/TextField';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';

export class NewsMessageRewardContentView extends PIXI.Container {
	public static readonly EVENT_CLAIM_REWARD = Symbol();
	public static readonly EVENT_SHOW_HINT_REWARD_CARD = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly bg: PIXI.Graphics;
	private readonly claimButton: ButtonWithLabelBaseView;

	private claimedLabel: TextField;
	private icon: PIXI.Sprite;

	constructor(
		private readonly cardMiniViewFactory: CardMiniViewFactory,
	) {
		super();
		this.localizationStorage = LocalizationStorage.getInstance();

		this.bg = new PIXI.Graphics()
			.beginFill(0x172738, 0.5)
			.drawRect(0, 0, 1518, 128)
			.endFill();
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.claimButton = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 294, 75),
			{ font: { size: 35, name: 'wendyOneShadowBold' } },
			310,
		);
		this.claimButton.setTextLabel(this.localizationStorage.getLocalizedString('#quest_ButtonClaim'));
		this.claimButton.on(ButtonBaseView.EVENT_CLICK, this.onClaimButtonClick, this);
		this.claimButton.position.set(580, 1);

		this.addChild(
			this.bg,
			this.claimButton as PIXI.DisplayObject,
		);
	}

	public setRewards(rewards: RewardDescriptionType[]): void {
		if (rewards.length === 0) {
			this.bg.removeChildren();
		} else {
			const scale = 0.5;

			const rewardCards = rewards.map((reward, i) => {
				const card = this.cardMiniViewFactory.createRewardCardByTypeId(
					reward.reward_type as RewardTypes,
					reward.reward_id,
					scale,
					scale,
					undefined,
					reward.reward_qty,
				);

				card.x = card.width / 2 + card.width * i + 39;
				card.y = card.height / 2 + 14;

				card.interactive = true;
				card.on('pointertap', () => {
					this.emit(NewsMessageRewardContentView.EVENT_SHOW_HINT_REWARD_CARD, reward, card);
				});

				return card;
			});

			this.bg.addChild(...rewardCards);
		}
	}

	public setRewardsClaimed(): void {
		if (this.claimedLabel == null) {
			const tint = new PIXI.Graphics()
				.beginFill(0x172738, 0.5)
				.drawRect(0, 0, 1518, 128)
				.endFill();
			tint.pivot.set(tint.width / 2, tint.height / 2);

			this.icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_check']);
			this.icon.position.set(515, 1);

			this.claimedLabel = new TextField(
				this.localizationStorage.getLocalizedString('#reward_claimed'),
				{ font: '28px wendyOneShadowBold' },
				200,
				200,
			);
			this.claimedLabel.anchor = new PIXI.Point(0, 0.5);
			this.claimedLabel.position.set(this.icon.x + 35, 1);

			this.removeChild(this.claimButton);
			this.addChild(
				tint,
				this.claimedLabel as PIXI.DisplayObject,
				this.icon,
			);
		}
	}

	private onClaimButtonClick(): void {
		this.setRewardsClaimed();
		this.emit(NewsMessageRewardContentView.EVENT_CLAIM_REWARD);
	}
}
