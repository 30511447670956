import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';

export type ProgressBarItem = {
	maxWidth: number;
	displayItem: PIXI.mesh.NineSlicePlane;
};

export class VideoPlayerProgressBar extends PIXI.Container {
	public static readonly EVENT_SEEK_VIDEO: symbol = Symbol();

	private progressBar: PIXI.mesh.NineSlicePlane;
	private currentTime: number;
	private duration: number;
	private isRunnerMoving: boolean;
	private readonly progressRunner: PIXI.Sprite;

	private progressTween: TWEEN.Tween;
	private paused: boolean;

	constructor() {
		super();

		this.currentTime = 0;
		this.paused = true;
		this.isRunnerMoving = false;


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['video_progress_bar'], 1, 0, 1, 0);
		bg.width = GameConstants.GAME_WIDTH;
		bg.height = 8;
		bg.alpha = 0.5;
		bg.interactive = true;
		bg.hitArea = new PIXI.Rectangle(0, -8, GameConstants.GAME_WIDTH, 24);
		bg.on('pointerdown', this.onBackgroundPointerDown, this);

		const progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['video_progress_bar'], 1, 0, 1, 0);
		progressBar.width = 0;
		progressBar.height = 8;
		this.progressBar = progressBar;

		this.progressRunner = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['runner_icon']);
		this.progressRunner.y = 4;
		this.progressRunner.interactive = true;
		this.progressRunner.on('pointerdown', this.onRunnerPointerDown, this);
		this.progressRunner.on('pointerup', this.onRunnerPointerUp, this);
		this.progressRunner.on('pointerupoutside', this.onRunnerPointerUp, this);

		this.addChild(
			bg,
			progressBar,
			this.progressRunner as PIXI.DisplayObject,
		);
	}

	public setDuration(duration: number): void {
		this.duration = duration;

		this.play();
	}

	public play(replay: boolean = false): void {
		this.paused = false;

		if (replay && this.currentTime === this.duration) {
			this.progressBar.width = 0;
			this.currentTime = 0;
		}

		this.progressTween = new TWEEN.Tween(this.progressBar)
			.to({ width: GameConstants.GAME_WIDTH }, (this.duration - this.currentTime) * 1000)
			.easing(TWEEN.Easing.Linear.None)
			.onUpdate(() => { this.progressRunner.x = this.progressBar.width; })
			.onComplete(() => { this.paused = true; })
			.start();
	}

	public stop(): void {
		this.stopProgressTween();

		this.progressBar.width = 0;
		this.progressRunner.x = 0;
		this.currentTime = 0;
		this.paused = false;
	}

	public pause(): void {
		this.stopProgressTween();

		this.paused = true;
	}

	public setCurrentTime(current: number): void {
		if (this.isRunnerMoving || this.currentTime === current || this.paused) {
			return;
		}

		this.currentTime = current;

		if (!this.progressTween) {
			this.play();
		}
	}

	private onBackgroundPointerDown(event: PIXI.interaction.InteractionEvent): void {
		this.seekVideo(event.data.global.x);
	}

	private seekVideo(targetX: number): void {
		this.stopProgressTween();

		const seekingTime = targetX / GameConstants.GAME_WIDTH * this.duration;
		this.currentTime = seekingTime;
		this.progressBar.width = targetX;
		this.progressRunner.x = this.progressBar.width;
		this.emit(VideoPlayerProgressBar.EVENT_SEEK_VIDEO, seekingTime);
	}

	private onRunnerPointerDown(): void {
		this.isRunnerMoving = true;
		this.progressRunner.on('pointermove', this.onRunnerPointerMove, this);
	}

	private onRunnerPointerUp(): void {
		this.progressRunner.off('pointermove', this.onRunnerPointerMove, this);

		this.currentTime = this.progressBar.width / GameConstants.GAME_WIDTH * this.duration;
		this.emit(VideoPlayerProgressBar.EVENT_SEEK_VIDEO, this.currentTime);

		this.isRunnerMoving = false;
	}

	private onRunnerPointerMove(event: PIXI.interaction.InteractionEvent): void {
		this.stopProgressTween();

		let progressWidth = event.data.global.x;
		if (progressWidth < 0) {
			progressWidth = 0;
		} else if (progressWidth > GameConstants.GAME_WIDTH) {
			progressWidth = GameConstants.GAME_WIDTH;
		}
		this.progressBar.width = progressWidth;
		this.progressRunner.x = this.progressBar.width;
	}

	private stopProgressTween(): void {
		if (this.progressTween) {
			this.progressTween.stop();
		}
		this.progressTween = null;
	}

	public destroy(options?: PIXI.DestroyOptions): void {
		this.stopProgressTween();

		super.destroy(options);
	}
}
