import { BaseIncomeMoneyAnimationView, IncomeMoneySprite } from '@views/ui/incomeMoney/BaseIncomeMoneyAnimationView';
import { AssetsStorage } from '@main/AssetsStorage';

export class SoftMoneyIncomeAnimationView extends BaseIncomeMoneyAnimationView {
	// eslint-disable-next-line class-methods-use-this
	protected createSprite(): IncomeMoneySprite {
		const res = AssetsStorage.getResource('incomeMoneyAnimation');
		const sprite = new PIXI.extras.AnimatedSprite(res.spritesheet.animations['i_money_anim']);

		(sprite as PIXI.extras.AnimatedSprite).animationSpeed = 0.2;
		sprite.scale.set(0.6);
		return sprite;
	}
}
