import LocalizationStorage from '@main/LocalizationStorage';
import { BusinessModel } from '@models/BusinessModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { MoneyPanelBaseView } from './MoneyPanelBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { Emitter } from 'pixi-particles';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import { SoftMoneyIncomeAnimationView } from '../incomeMoney/SoftMoneyIncomeAnimationView';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';

export class SoftMoneyPanelView extends MoneyPanelBaseView<SoftMoneyNumber> {
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	private readonly textIncomePerSec: SizeableBitmapText;

	private readonly localizationStorage: LocalizationStorage;
	private readonly businessModels: BusinessModel[];
	private constantProfitEmitters: Emitter[];
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly skillModels: Map<string, SkillModel>;

	private moneyFade: PIXI.Sprite;
	private bgFade: PIXI.mesh.NineSlicePlane;

	constructor(
		softMoneyModel: SoftMoneyModel,
		businessModels: BusinessModel[],
		skillModels: Map<string, SkillModel>,
		visibleBg?: boolean,
	) {
		const textMoney = new SizeableBitmapText(
			'',
			200, { font: '48px wendyOneShadowBold', align: 'center' },
		);
		textMoney.anchor = 0.5;
		textMoney.position.set(-18, -14);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const iconMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']);
		iconMoney.alpha = 0;
		iconMoney.x = -18;

		super(textMoney, iconMoney, softMoneyModel);

		this.skillModels = skillModels;
		this.fxAtlas = fxAtlas;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.businessModels = businessModels;
		this.businessModels.forEach((model) => {
			model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.onSomeBusinessIncomeValueChanged, this);
			model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.onSomeBusinessIncomeValueChanged, this);
			model.on(BusinessModel.EVENT_AUTOMATED, this.updateTextIncomePerSec, this);
		});

		this.textIncomePerSec = new SizeableBitmapText(
			'',
			180,
			{ font: '23px wendyOneShadowBold', align: 'center', tint: 0xa5e245 },
		);
		this.textIncomePerSec.anchor = 0.5;
		this.textIncomePerSec.position.set(-18, 21);
		this.addChild(this.textIncomePerSec);

		this.updateTextIncomePerSec();

		const viewMask = new PIXI.Graphics();
		viewMask.beginFill(0x000000);
		viewMask.drawPolygon([
			new PIXI.Point(600, -40),
			new PIXI.Point(-600, -40),
			new PIXI.Point(-600, 38),
			new PIXI.Point(600, 38),
		]);
		this.addChildAt(viewMask, 0);

		const moneyFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['rect_glow']);
		moneyFade.width = 337;
		moneyFade.height = 180;
		moneyFade.tint = 0x171a21;
		moneyFade.mask = viewMask;
		moneyFade.position.set(-18, -20);
		moneyFade.interactive = true;
		moneyFade.on('pointertap', this.showHint, this);
		this.addChildAt(moneyFade, 0);

		this.moneyFade = moneyFade;

		const moneyDecorLeft = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_money']);
		moneyDecorLeft.position.set(-178, 1);
		moneyDecorLeft.mask = viewMask;
		this.addChildAt(moneyDecorLeft, 0);

		const moneyDecorRight = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_money']);
		moneyDecorRight.position.set(138, -1);
		moneyDecorRight.mask = viewMask;
		this.addChildAt(moneyDecorRight, 0);

		this.bgFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_ui_panel_fade'], 27, 0, 27, 0);
		this.bgFade.width = 340;
		this.bgFade.pivot.set(this.bgFade.width / 2, this.bgFade.height / 2);
		this.bgFade.position.set(-33, -5);
		this.bgFade.visible = visibleBg;

		this.addChildAt(this.bgFade, 0);

		this.constantProfitEmitters = [];
		skillModels.forEach((skillModel) => {
			if (skillModel.getType() === SkillTypes.CONSTANT_PROFIT) {
				skillModel.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onConstantProfitSkillActivated, this);
			}
		});
	}

	public updateTextIncomePerSec(): void {
		const targetValues: SoftMoneyNumber[] = this.businessModels
			.filter(model => model.isAutomated())
			.map(model => model.getBusinessIncomePerSec(true));
		const targetValue = (targetValues.length === 0) ? undefined : targetValues.reduce((a, b) => a.add(b));

		if (targetValue) {
			let stringIncome = this.localizationStorage.getLocalizedString('#income_in_sec');
			stringIncome = stringIncome.replace('{{value}}', targetValue.toString());
			this.textIncomePerSec.text = `+${stringIncome}`;
			this.textIncomePerSec.visible = true;
		} else {
			this.textIncomePerSec.visible = false;
		}
	}

	public onZoomInStarted(): void {
		this.bgFade.visible = false;
		this.setTransform(0, -127, 0.4, 0.4);
		this.alpha = 0;
		const tweenOpaque = new TWEEN.Tween(this)
			.to({ alpha: 1 }, 150);
		tweenOpaque.start();
	}

	public onZoomOutStarted(): void {
		this.bgFade.visible = true;

		const tweenTransparent = new TWEEN.Tween(this)
			.to({ alpha: 0 }, 100)
			.onComplete(() => { this.setTransform(GameConstants.GAME_CENTER_X, 40, 0, 0); });
		const tweenOpaque = new TWEEN.Tween(this)
			.to({ alpha: 1 }, 100);

		tweenTransparent.chain(tweenOpaque).start();
	}

	public playSoftMoneyIncomeAnimation(
		position: PIXI.Point,
		parent: PIXI.Container,
		animationParent: PIXI.Container,
	): Promise<void> {
		return new Promise((resolve) => {
			const iconSoftMoneyPosLocal = parent.toLocal(this.getIconMoneyPosition(), this);
			const incomeMoneyAnimation = new SoftMoneyIncomeAnimationView(iconSoftMoneyPosLocal);
			incomeMoneyAnimation.on(SoftMoneyIncomeAnimationView.EVENT_ANIMATION_COMPLETED, () => {
				this.setCanUpdate(true);
				this.pulse();
				resolve();
			}, this);

			animationParent.addChild(incomeMoneyAnimation);
			this.setCanUpdate(false);
			incomeMoneyAnimation.playAnimation(position);
		});
	}

	public getMoneyContainer(): PIXI.Container {
		return this.moneyFade;
	}

	private showHint(): void {
		const arrowPosLocal = new PIXI.Point(this.iconMoney.x, this.iconMoney.y + 50);
		this.emit(SoftMoneyPanelView.EVENT_SHOW_HINT, arrowPosLocal, this);
	}

	private onSomeBusinessIncomeValueChanged(model: BusinessModel): void {
		if (model.isAutomated()) {
			this.updateTextIncomePerSec();
		}
	}

	private onConstantProfitSkillActivated(): void {
		if (this.textIncomePerSec.visible) {
			const topGlowEmitter = new Emitter(
				this.textIncomePerSec,
				[this.fxAtlas['skill_top_glow1']],
				SkillParticleConfig.getTopGlow1(),
			);
			this.constantProfitEmitters.push(topGlowEmitter);
			topGlowEmitter.playOnceAndDestroy();

			const topGlowEmitter2 = new Emitter(
				this.textIncomePerSec,
				[this.fxAtlas['skill_top_glow2']],
				SkillParticleConfig.getTopGlow2(),
			);
			this.constantProfitEmitters.push(topGlowEmitter2);
			topGlowEmitter2.playOnceAndDestroy();

			const topGlowEmitter3 = new Emitter(
				this.textIncomePerSec,
				[this.fxAtlas['skill_top_glow3']],
				SkillParticleConfig.getTopGlow3(),
			);
			this.constantProfitEmitters.push(topGlowEmitter3);
			topGlowEmitter3.playOnceAndDestroy();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.businessModels.forEach((model) => {
			model.off(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.onSomeBusinessIncomeValueChanged, this);
			model.off(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.onSomeBusinessIncomeValueChanged, this);
			model.off(BusinessModel.EVENT_AUTOMATED, this.updateTextIncomePerSec, this);
		});

		this.constantProfitEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.skillModels.forEach((skillModel) => {
			if (skillModel.getType() === SkillTypes.CONSTANT_PROFIT) {
				skillModel.off(SkillModel.EVENT_SKILL_ACTIVATED, this.onConstantProfitSkillActivated, this);
			}
		});
		super.destroy(options);
	}
}
