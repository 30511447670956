import { AssetsStorage } from '@src/main/AssetsStorage';

export class StickableTimerView extends PIXI.Container {
	private readonly timerLabel: PIXI.extras.BitmapText;
	private readonly timerBg: PIXI.mesh.NineSlicePlane;

	constructor(
		invertStick: boolean,
	) {
		super();

		this.timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_ui_level_time_panel'], 14, 0, 18, 0);
		this.timerBg.tint = 0x000000;
		this.timerBg.width = 296;
		this.timerBg.height = 52;
		this.timerBg.pivot.set(this.timerBg.width / 2, this.timerBg.height / 2);

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '34px wendyOneShadowBold', tint: 0xffc900,
		});
		this.timerLabel.anchor = 0.5;

		this.addChild(
			this.timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);

		if (invertStick) {
			this.timerBg.scale.y = -1;
		} else {
			this.timerBg.scale.y = 1;
		}
	}

	public setTextTimer(value: string): void {
		this.timerLabel.text = value;
	}
}
