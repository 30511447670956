import { BankBundleGroupConfig } from '@configs/bank/BankBundleGroupConfig';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankBundleGroupModel } from '@models/bank/BankBundleGroupModel';
import { BankBundleConfig } from '@configs/bank/BankBundleConfig';

export class BankBundleGroupsFactory {
	public static createModels(
		bankBundleGroupsConfigs: Map<string, BankBundleGroupConfig>,
		bankBundleConfigs: Map<string, BankBundleConfig>,
		bankBundles: Map<string, BankBundleModel>,
	): Map<string, BankBundleGroupModel> {
		const bundleGroupModels = new Map();
		bankBundleGroupsConfigs.forEach((bunleGroupConfig, key) => {
			const bundleGroupModel = new BankBundleGroupModel(bunleGroupConfig);
			bankBundleConfigs.forEach(bundleConfig => {
				if (bundleConfig.hasBundleGroupKey() && bundleConfig.getBundleGroupKey() === bundleGroupModel.getKey()) {
					bundleGroupModel.addBundle(bankBundles.get(bundleConfig.getKey()));
				}
			});
			bundleGroupModels.set(key, bundleGroupModel);
		});

		return bundleGroupModels;
	}
}
