/* eslint-disable camelcase */
import { BaseAction } from '../BaseAction';
import { SoftMoneyNumberRaw } from '@src/utils/SoftMoneyNumber';

export class CharacterAcquireAction extends BaseAction {
	constructor(characterId: string, businessId: string, cost: SoftMoneyNumberRaw, currentSoftMoney: SoftMoneyNumberRaw) {
		super('CharacterAcquire');
		this.data.character_id = characterId;
		this.data.business_id = businessId;

		const resourcesSpent = Object.create(null);
		resourcesSpent.soft_money = cost;
		this.data.resources_spent = resourcesSpent;

		const resources = Object.create(null);
		resources.soft_money = currentSoftMoney;
		this.sync.resources = resources;
	}
}
