import { NetworkRequestTransport } from './NetworkRequestTransport';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

type NutakuResponse<T> = {
	entry: T;
}

export class NetworkRequestSender {
	private transport: NetworkRequestTransport;

	public setTransport(transport: NetworkRequestTransport): void {
		this.transport = transport;
	}

	public hasPlayerId(): boolean {
		return this.transport.hasPlayerId();
	}

	public getPlayerId(): string {
		return this.transport.getPlayerId();
	}

	public getSessionId(): string {
		return this.transport.getSessionId();
	}

	public isActionsEmitterEnabled(): boolean {
		return this.transport.isActionsEmitterEnabled();
	}

	public async sendGetProfileId(url: string, params: Record<string, string> = {}): Promise<Record<string, any>> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/get_profile_id',
			Object.assign(params, {
				url,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				profile_id: this.getPlayerId(),
			}),
			false,
			false,
		);
		return Promise.resolve(result);
	}

	public async sendGetConfigs(configNames: string[]): Promise<Record<string, any>> {
		const result = await this.transport.sendRequest(
			'/gs_api/configs/get_data',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ config_names: configNames },
			false,
			false,
		);
		return Promise.resolve(result);
	}

	public async sendProfileLoad(): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/load',
			undefined,
		);
		return Promise.resolve(result);
	}

	public async sendGetMaintenanceStartTime(): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/news/get_maintenance_start_time',
			undefined,
			true,
			false,
		);
		return Promise.resolve(result);
	}

	public async sendGetNewCustomerMultiplierRewards(businessKey: string, customers: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/businesses/get_business_prestige',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ business_id: businessKey, customers },
			undefined,
			false,
		);
		return Promise.resolve(result);
	}

	public async sendGetTimedQuestRewards(): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/quests/get_side_quest_rewards',
		);
		return Promise.resolve(result);
	}

	public async sendGetQuestsRewards(data: { [key: string]: string }): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/quests/get_quest_rewards',
			{ quests: data },
		);
		return Promise.resolve(result);
	}

	public async sendGetEventRewards(eventKey: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/events/get_event_rewards',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ event_id: eventKey },
		);
		return Promise.resolve(result);
	}

	public async sendGetEventLeagueBotNames(eventKey: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/events/get_event_opponents',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ event_id: eventKey },
		);
		return Promise.resolve(result);
	}

	public async sendGetTimedQuest(timestamp: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/quests/get_side_quest',
			{ timestamp },
		);
		return Promise.resolve(result);
	}

	public async sendGetQuest(questLine: string, questLineProgress: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/quests/get_quest',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ quest_line: questLine, line_number: questLineProgress },
		);
		return Promise.resolve(result);
	}

	public async sendFarewellPartyStart(): Promise<any> {
		const result = await this.transport.sendRequest('/gs_api/farewell_party/start');
		return Promise.resolve(result);
	}

	public async sendFarewellPartyLevelChange(): Promise<any> {
		const result = await this.transport.sendRequest('/gs_api/farewell_party/level_change');
		return Promise.resolve(result);
	}

	public async sendGetEpicQuestRewards(questId: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/quests/get_epic_quest_rewards',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ quest_id: questId },
		);
		return Promise.resolve(result);
	}

	public async sendApplySummon(id: string, count: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/summon/apply',
			{ id, x: count },
		);
		return result;
	}

	public async sendSupportMail(
		subjectEng: string,
		msg: string,
		deviceInfo: string,
		email: string,
	): Promise<any> {
		const data = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			message_text: msg,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			contact_info: email,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			device_info: deviceInfo,
			subject: subjectEng,
		};
		const result = await this.transport.sendRequest(
			'/gs_api/support/new_ticket',
			data,
		);
		return Promise.resolve(result);
	}

	public async sendAccountChangePassword(email: string): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/reset_password',
			{ email },
		);
		return Promise.resolve(result);
	}

	public async sendAccountClaimReward(lootboxId: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/claim_registration_reward',
			{ id: lootboxId },
		);
		return Promise.resolve(result);
	}

	public async sendAccountEmailConfirm(email: string, code: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/email_confirmation',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ email, confirmation_code: code },
		);
		return Promise.resolve(result);
	}

	public async sendAccountNewConfirmationCode(email: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/send_new_confirmation_code',
			{ email },
		);
		return Promise.resolve(result);
	}

	public async sendAccountEmailRegister(email: string, password: string, subscribe: boolean): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/email_register',
			{ email, password, subscribe },
		);
		return Promise.resolve(result);
	}

	public async sendAccountEmailLogin(email: string, password: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/profiles/email_login',
			{ email, password },
		);
		return Promise.resolve(result);
	}

	public async sendBankGetData(lotStringIds: string[], timestamp: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/bank/get_data',
			{ lot_string_ids: lotStringIds, timestamp },
		);
		return result;
	}

	public async sendBankInitTransactionEpoch(bankElementKey: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/payments/epoch_init_txn',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ lot_string_id: bankElementKey },
		);
		return Promise.resolve(result);
	}

	public async sendBankInitTestTransactionEpoch(bankElementKey: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/payments/init_txn',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ lot_string_id: bankElementKey },
		);
		return Promise.resolve(result);
	}

	public async sendBankInitTransactionCardpay(bankElementKey: string, email: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/payments/cardpay_init_txn',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ lot_string_id: bankElementKey, email },
		);
		return Promise.resolve(result);
	}

	public async sendBankCheckPurchase(nonCommittedTransactionId: number): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/payments/check_purchase',
			{ nctxnid: nonCommittedTransactionId },
		);
		return Promise.resolve(result);
	}

	public async sendBankCheckNutakuPurchase(nutakuTransactionId: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/payments/check_nutaku_purchase',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ transaction_id: nutakuTransactionId },
		);
		return Promise.resolve(result);
	}

	public async sendBankRefreshSpecial(): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const result = await this.transport.sendRequest(
			'/gs_api/bank/manually_refresh_special',
		);
		return Promise.resolve(result);
	}

	public async sendBankSubscribeRewardClaim(lotStringId: string): Promise<any> {
		const result = await this.transport.sendRequest(
			'/gs_api/bank/claim_subscription_rewards',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ lot_string_id: lotStringId },
		);
		return result;
	}

	public async sendGetLevelRewards(currentLevel: number): Promise<any> {
		const result = await this.transport.sendRequest('/gs_api/profiles/get_level_rewards', {
			cur_level: currentLevel,
		});
		return result;
	}

	public async sendGetNextFerewellPartyTime(): Promise<any> {
		const result = await this.transport.sendRequest('/gs_api/farewell_party/get_next_party_time');
		return result;
	}

	public async sendGetDailyRewardsInfo(timestamp: number): Promise<Record<string, any>> {
		const result = await this.transport.sendRequest(
			'/gs_api/daily_bonus/get_daily_rewards_info',
			{ timestamp },
		);
		return result;
	}

	public async sendGetDailyRewards(day: number, type: string): Promise<RewardDescriptionType> {
		const result = await this.transport.sendRequest(
			'/gs_api/daily_bonus/get_daily_bonus_rewards ',
			{ day, type },
		);
		return result;
	}

	// cheats
	public async sendCheatUnlinkEmail(): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		await this.transport.sendRequest(
			'/gs_api/profiles/del_email_login',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatSetPresetProfile(level: number): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/set_preset_profile',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ level_id: level },
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatBankResetBoosts(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_boosts',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatBankResetSubscriptions(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_subscriptions',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatOpenAll(
		targetType: 'characters' | 'upgrades' | 'totems' | 'businesses' | 'resources',
	): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/profiles/${this.transport.getPlayerId()}/${targetType}/add_all`,
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatOpen(
		targetKey: string,
		targetType: 'characters' | 'upgrades' | 'totems',
	): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/profiles/${this.transport.getPlayerId()}/${targetType}/${targetKey}/open`,
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatAddCardsToOpened(
		amount: number,
		targetType: 'characters' | 'upgrades' | 'totems',
	): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/profiles/${this.transport.getPlayerId()}/${targetType}/add_cards`,
			{ cards: amount },
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatResetCards(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_cards',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatBankResetBank(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_bank',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatResetResources(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_resources',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatGotoParty(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/go_to_party',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatResetSkills(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_skills',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatResetSkillsCd(): Promise<void> {
		await this.transport.sendRequest(
			'/gs_api/profiles/reset_skill_cooldowns',
			undefined,
			true,
			false,
		);
		return Promise.resolve();
	}

	public async sendCheatSetLevelStartTime(seconds: number): Promise<void> {
		this.transport.addAction(new UpdateUserDataAction({
			level_started_ts: seconds,
		}));
	}

	public async sendCheatResetFarewellParty(): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/farewell_party/${this.transport.getPlayerId()}/reset`,
			undefined,
			true,
			false,
		);
	}

	public async sendCheatSkipNoviceEvent(): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/events/${this.transport.getPlayerId()}/skip_novice`,
			undefined,
			true,
			false,
		);
	}

	public async sendCheatRefreshFreeSummon(): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/summon/${this.transport.getPlayerId()}/refresh`,
			undefined,
			true,
			false,
		);
	}

	public async sendCheatRefreshDailyReward(): Promise<void> {
		await this.transport.sendRequest(
			`/gs_api/daily_bonus/${this.transport.getPlayerId()}/refresh`,
			undefined,
			true,
			false,
		);
	}

	public async sendNutakuGetProfile(): Promise<NutakuResponse<Record<string, unknown>>> {
		return this.transport.sendRequest(
			'/nutaku_api/get_profile',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ fields: 'id,displayName,grade' },
		);
	}

	public async sendNutakuCreatePayment(paymentItems: unknown[], finishUrl: string): Promise<NutakuResponse<Record<string, unknown>>> {
		return this.transport.sendRequest(
			'/gs_api/payments/nutaku_init_txn',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			{ payment_items: paymentItems, finish_page_url: finishUrl },
		);
	}

	public async sendGetAssets(assets: string[]): Promise<Record<string, any>> {
		const url = this.transport.getAssetsServiceUrl();
		const state = this.transport.getServerState();
		const body = {
			state,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			static_type: 'webgl_bundles',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			asset_names: assets,
		};

		return this.transport.createAssetsRequest(url, body);
	}

	public async sendGetAssetsBackup(assets: string[]): Promise<Record<string, any>> {
		const backupBody = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			static_type: 'webgl_bundles',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			asset_names: assets,
		};

		return this.transport.sendRequest(
			'/gs_api/assets/get_assets',
			backupBody,
			true,
			false,
		);
	}
}
