import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class IncomeProgressBarView extends PIXI.Container {
	private readonly progressBarReducedTime: PIXI.mesh.NineSlicePlane;
	private readonly progressBarReducedTimeMask: PIXI.Graphics;
	private readonly progressBar: PIXI.Graphics;
	private readonly progressBarMask: PIXI.Graphics;

	private readonly maskWidth: number;
	private readonly maskHeight: number;

	private readonly barGlow: PIXI.Sprite;
	private readonly barGlowMask: PIXI.Graphics;

	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly content: PIXI.Container;

	constructor(width: number, height: number) {
		super();

		this.maskWidth = width;
		this.maskHeight = height;


		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.progressBar = new PIXI.Graphics()
			.beginFill(0xafed31)
			.drawRect(0, 0, this.maskWidth, this.maskHeight)
			.endFill();
		this.progressBar.pivot.set(0, this.progressBar.height / 2);

		this.progressBarMask = new PIXI.Graphics()
			.beginFill(0xafed31)
			.drawRect(0, 0, this.maskWidth, this.maskHeight)
			.endFill();
		this.progressBarMask.width = 0;
		this.progressBarMask.pivot.set(0, this.progressBarMask.height / 2);
		this.progressBar.mask = this.progressBarMask;

		this.progressBarReducedTime = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')['u_bar_green'], 0, 0, 0, 0,
		);
		this.progressBarReducedTime.width = this.maskWidth;
		this.progressBarReducedTime.height = this.maskHeight;
		this.progressBarReducedTime.pivot.set(0, this.progressBarReducedTime.height / 2);

		this.progressBarReducedTimeMask = new PIXI.Graphics()
			.beginFill(0xafed31)
			.drawRect(0, 0, this.maskWidth, this.maskHeight)
			.endFill();
		this.progressBarReducedTimeMask.width = 0;
		this.progressBarReducedTimeMask.pivot.set(0, this.progressBarReducedTimeMask.height / 2);
		this.progressBarReducedTime.mask = this.progressBarReducedTimeMask;

		this.barGlowMask = new PIXI.Graphics()
			.beginFill(0xafed31)
			.drawRect(0, 0, this.maskWidth + 4, this.maskHeight + 4)
			.endFill();
		this.barGlowMask.pivot.set(0, this.barGlowMask.height / 2);

		this.barGlow = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['income_bar_glow']);
		this.barGlow.pivot = new PIXI.Point(45, 0);
		this.barGlow.alpha = 0;
		this.barGlow.mask = this.barGlowMask;

		this.content = new PIXI.Container();
		this.content.addChild(
			this.progressBar,
			this.progressBarMask as PIXI.DisplayObject,
			this.progressBarReducedTime,
			this.progressBarReducedTimeMask,
			this.barGlow,
			this.barGlowMask,
		);

		this.addChild(
			this.content,
		);
	}

	public onZoomIn(): void {
		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
	}

	public onZoomOut(): void {
		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setProgress(value: number, deltaReduce: number): void {
		this.progressBarMask.width = value * this.maskWidth;
		this.progressBarReducedTimeMask.width = (value - deltaReduce) * this.maskWidth;

		this.barGlow.x = this.progressBarMask.width;
	}

	public glowBarAnimation(): void {
		const alphaUp = new TWEEN.Tween(this.barGlow, this.tweenGroup)
			.to({ alpha: 1 }, 1)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
		const alphaDown = new TWEEN.Tween(this.barGlow, this.tweenGroup)
			.to({ alpha: 0 }, 300)
			.start();
		alphaUp.chain(alphaDown);
	}

	public reset(): void {
		this.progressBarReducedTimeMask.width = 0;
		this.progressBarMask.width = 0;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweenGroup, this);

		super.destroy(options);
	}
}
