import { BusinessConfig } from '@configs/BusinessConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';

export abstract class ConfigsParserBase {
	// eslint-disable-next-line class-methods-use-this
	protected onConfigsParsed(
		businessesConfigs: Map<string, BusinessConfig>,
		upgradesConfigs: Map<string, UpgradeConfig>,
		reduceTimeIncomeByClick: number,
	): void {
		businessesConfigs.forEach((businessConfig) => {
			businessConfig.setReduceTimeIncomeByClick(reduceTimeIncomeByClick);

			const upgradeMinCustomers = businessConfig.getUpgradeMinCustomers();

			businessConfig.getUpgradeKeys().forEach((upgradeKey, i) => {
				const upgradeConfig = upgradesConfigs.get(upgradeKey);

				if (upgradeConfig && upgradeMinCustomers[i]) {
					upgradeConfig.setCustomerCount(upgradeMinCustomers[i]);
				}
			});
		});
	}
}
