import { RewardDescriptionType } from '@src/types/RewardTypes';

export class LevelChallengeModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_TIMEOUT: symbol = Symbol();
	public static readonly EVENT_COMPLETED: symbol = Symbol();
	public static readonly EVENT_REWARDS_CHANGED = Symbol();

	private targetTimes: number[];
	private targetTimeId: number;

	private active: boolean;
	private activeTargetTimeId: number;

	private startedOn: number;
	private currentTime: number;

	private completed: boolean;
	private completedTargetTimeId: number;
	private inited: boolean;
	private rewards?: RewardDescriptionType[][];

	constructor() {
		super();
		this.active = false;
		this.completed = false;
		this.inited = false;
	}

	public init(
		targetTimes: number[],
		currentTime: number,
		levelStartedTime?: number,
		levelFinishedTime?: number,
	): void {
		this.targetTimes = targetTimes;
		this.currentTime = currentTime;
		this.inited = true;

		if (levelFinishedTime) {
			const levelTime = levelFinishedTime - levelStartedTime;
			this.completedTargetTimeId = this.targetTimes.length;
			for (let i = 0; i < this.targetTimes.length; i++) {
				if (levelTime <= this.targetTimes[i]) {
					this.completed = true;
					this.completedTargetTimeId = i;
					break;
				}
			}
			this.active = false;
		} else if (levelStartedTime) {
			this.startedOn = levelStartedTime;
			this.activeTargetTimeId = this.targetTimes.length;
			this.active = false;
			for (let i = 0; i < this.targetTimes.length; i++) {
				const curentLevelTime = currentTime - this.startedOn;
				if (curentLevelTime <= this.targetTimes[i]) {
					this.active = true;
					this.activeTargetTimeId = i;
					break;
				}
			}
			if (!this.active) {
				this.completedTargetTimeId = this.targetTimes.length;
			}
		} else {
			this.startedOn = currentTime;
			this.active = true;
			this.activeTargetTimeId = 0;
		}
	}

	public getRewards(): RewardDescriptionType[][] | undefined {
		return this.rewards;
	}

	public setRewards(value: RewardDescriptionType[][]): void {
		this.rewards = value;
		this.emit(LevelChallengeModel.EVENT_REWARDS_CHANGED, this.rewards);
	}

	public complete(): void {
		this.completed = true;
		this.active = false;
		this.completedTargetTimeId = this.activeTargetTimeId;

		this.emit(LevelChallengeModel.EVENT_COMPLETED, this);
	}

	public hasChallenges(): boolean {
		return this.inited;
	}

	public reset(): void {
		this.active = false;
		this.completed = false;
		this.activeTargetTimeId = 0;
	}

	public update(currentTime: number): void {
		this.currentTime = currentTime;

		const targetTime = this.targetTimes[this.activeTargetTimeId];
		const currentLevelTime = this.currentTime - this.startedOn;
		if (currentLevelTime >= targetTime) {
			this.activeTargetTimeId += 1;
			if (this.activeTargetTimeId >= this.targetTimes.length) {
				this.completedTargetTimeId = this.activeTargetTimeId;
				this.active = false;
			}
			this.emit(LevelChallengeModel.EVENT_TIMEOUT, this);
		}
	}

	public getActiveTargetTimeleft(): number {
		const targetTime = this.targetTimes[this.activeTargetTimeId];
		const currentLevelTime = this.currentTime - this.startedOn;
		return targetTime - currentLevelTime;
	}

	public getTargetTimeId(): number {
		return this.targetTimeId;
	}

	public getTargetTimes(): number[] {
		return this.targetTimes;
	}

	public getCompletedTargetTimeId(): number {
		return this.completedTargetTimeId;
	}

	public getActiveTargetTimeId(): number {
		return this.activeTargetTimeId;
	}

	public isActive(): boolean {
		return this.active;
	}

	public isCompleted(): boolean {
		return this.completed;
	}
}
