import { EventLevelConfig } from '@configs/level/EventLevelConfig';
import { EventLevelDateConfig } from '@configs/EventLevelDateConfig';
import { ServerTimeModel } from '@models/network/ServerTimeModel';

export enum EventLevelChoosenState {
	CHOSEN_PROMO,
	CHOSEN_AVAILABLE,
}

export type EventLevelChooserEventArgs = {
	levelConfig: EventLevelConfig;
	dateConfig?: EventLevelDateConfig;
	state: EventLevelChoosenState;
};

export class EventLevelChooser extends PIXI.utils.EventEmitter {
	public static readonly EVENT_CHOSEN: symbol = Symbol();

	private static readonly MIN_TIMELEFT: number = 60;

	private readonly eventLevelConfigs: Map<string, EventLevelConfig>;
	private readonly eventLevelDatesConfigs: EventLevelDateConfig[];
	private readonly serverTimeModel: ServerTimeModel;

	private readonly noviceEventMinLevel: number;
	private readonly noviceEventKey: string;
	private readonly mainEventDelay: number;

	private noviceEventCompletedOn: number;
	private noviceEventCompleted: boolean;
	private currentLevel: number;

	private showPromoScheduleHandler?: number;
	private eventLevelChosenScheduleHandler?: number;

	constructor(
		serverTime: ServerTimeModel,
		eventLevelConfigs: Map<string, EventLevelConfig>,
		eventLevelDatesConfigs: Map<string, EventLevelDateConfig>,
		currentLevel: number,
		noviceEventKey: string,
		noviceEventMinLevel: number,
		mainEventDelay: number,
		noviceEventCompletedOn?: number,
	) {
		super();

		this.serverTimeModel = serverTime;
		this.eventLevelConfigs = eventLevelConfigs;
		this.currentLevel = currentLevel;
		this.noviceEventKey = noviceEventKey;
		this.noviceEventCompleted = noviceEventCompletedOn !== undefined && noviceEventCompletedOn !== null;
		this.noviceEventCompletedOn = noviceEventCompletedOn;
		this.noviceEventMinLevel = noviceEventMinLevel;
		this.mainEventDelay = mainEventDelay;

		this.eventLevelDatesConfigs = Array
			.from(eventLevelDatesConfigs.values())
			.sort((a, b) => a.getStartDate() - b.getStartDate());
	}

	public choose(): void {
		if (!this.noviceEventCompleted && this.currentLevel >= this.noviceEventMinLevel) {
			const noviceEventConfig = this.eventLevelConfigs.get(this.noviceEventKey);
			const args: EventLevelChooserEventArgs = {
				levelConfig: noviceEventConfig,
				state: EventLevelChoosenState.CHOSEN_AVAILABLE,
			};
			this.emit(EventLevelChooser.EVENT_CHOSEN, args);
		} else if (this.noviceEventCompleted) {
			this.resetTimeoutHandlersIfAny();

			const targetDateConfig = this.chooseDateConfig(this.mainEventDelay);

			if (targetDateConfig !== undefined) {
				const targetLevelConfig = this.eventLevelConfigs.get(targetDateConfig.getEventLevelKey());
				this.onTargetDateConfigChosen(targetLevelConfig, targetDateConfig);
			}
		}
	}

	public setCurrentLevel(level: number): void {
		this.currentLevel = level;
	}

	public setNoviceEventCompleted(): void {
		this.noviceEventCompleted = true;
		this.noviceEventCompletedOn = this.serverTimeModel.getCalculatedISOTime();
	}

	private onTargetDateConfigChosen(levelConfig: EventLevelConfig, dateConfig: EventLevelDateConfig): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		const startDate = dateConfig.getStartDate();
		const elapsedFromStart = currentTime - startDate;
		if (elapsedFromStart >= 0) {
			const args: EventLevelChooserEventArgs = {
				levelConfig,
				dateConfig,
				state: EventLevelChoosenState.CHOSEN_AVAILABLE,
			};
			this.emit(EventLevelChooser.EVENT_CHOSEN, args);
		} else {
			this.scheduleEventLevel(levelConfig, dateConfig);
		}
	}

	private scheduleEventLevel(levelConfig: EventLevelConfig, dateConfig: EventLevelDateConfig): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		const showPromoTime = dateConfig.getShowPromo();
		const timeBeforeEventStart = dateConfig.getStartDate() - currentTime;

		if (showPromoTime !== undefined && showPromoTime !== null) {
			const emitEventChosenWithPromo = (): void => {
				const args: EventLevelChooserEventArgs = {
					levelConfig,
					dateConfig,
					state: EventLevelChoosenState.CHOSEN_PROMO,
				};
				this.emit(EventLevelChooser.EVENT_CHOSEN, args);
			};

			if (timeBeforeEventStart <= showPromoTime) {
				emitEventChosenWithPromo();
			} else {
				const timeBeforeShowPromo = timeBeforeEventStart - showPromoTime;
				this.showPromoScheduleHandler = window.setTimeout(() => {
					this.showPromoScheduleHandler = null;

					emitEventChosenWithPromo();
				}, timeBeforeShowPromo * 1000);
			}
		}

		this.eventLevelChosenScheduleHandler = window.setTimeout(() => {
			this.eventLevelChosenScheduleHandler = null;

			const args: EventLevelChooserEventArgs = {
				levelConfig,
				dateConfig,
				state: EventLevelChoosenState.CHOSEN_AVAILABLE,
			};
			this.emit(EventLevelChooser.EVENT_CHOSEN, args);
		}, timeBeforeEventStart * 1000);
	}

	private chooseDateConfig(mainEventDelay: number): EventLevelDateConfig | undefined {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();

		let minStartDateConfig: EventLevelDateConfig;
		for (let i = 0; i < this.eventLevelDatesConfigs.length; i++) {
			const dateConfig = this.eventLevelDatesConfigs[i];
			const levelConfig = this.eventLevelConfigs.get(dateConfig.getEventLevelKey());
			const startDate = dateConfig.getStartDate();
			const elapsedFromNoviceStart = this.noviceEventCompletedOn - startDate;
			const elapsedFromStart = currentTime - startDate;
			const timeleft = Math.max(0, levelConfig.getDuration() - elapsedFromStart);

			const isOkFromNoviceEvent = (elapsedFromNoviceStart > 0
				&& timeleft >= mainEventDelay
				&& timeleft >= EventLevelChooser.MIN_TIMELEFT) || elapsedFromNoviceStart <= 0;

			const isOkFromMainEvent = elapsedFromStart <= 0
				|| (elapsedFromStart > 0 && timeleft >= EventLevelChooser.MIN_TIMELEFT);

			if (isOkFromMainEvent && isOkFromNoviceEvent) {
				minStartDateConfig = dateConfig;
				break;
			}
		}
		return minStartDateConfig;
	}

	private resetTimeoutHandlersIfAny(): void {
		if (this.showPromoScheduleHandler !== null && this.showPromoScheduleHandler !== undefined) {
			window.clearTimeout(this.showPromoScheduleHandler);
		}
		if (this.eventLevelChosenScheduleHandler !== null && this.eventLevelChosenScheduleHandler !== undefined) {
			window.clearTimeout(this.eventLevelChosenScheduleHandler);
		}
	}
}
