import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90001View } from '@views/characters/events/event9/EventCharacter90001View';
import { EventCharacter90002View } from '@views/characters/events/event9/EventCharacter90002View';
import { EventCharacter90003View } from '@views/characters/events/event9/EventCharacter90003View';

export class EventBusiness90001View extends BusinessBaseView {
	constructor() {
		const eventCharacter90001View = new EventCharacter90001View();
		const eventCharacter90002View = new EventCharacter90002View();
		const eventCharacter90003View = new EventCharacter90003View();

		super('business90001', [eventCharacter90001View, eventCharacter90002View, eventCharacter90003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter90001View,
			eventCharacter90002View,
			eventCharacter90003View as PIXI.DisplayObject,
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
