import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BankBoostConfirmWindowView } from '@views/windows/bank/animations/BankBoostApplyConfirmView';

export class TutorialStepUnlockTypePrepartyWindowAppearModel
	extends TutorialStepUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	constructor(
		windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_PREPARTY);

		this.windowViewSystem = windowViewSystem;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem && this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED).includes(this.onUnlocked)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypePartyWindowAppearModel
	extends TutorialStepUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	constructor(
		windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_PARTY);

		this.windowViewSystem = windowViewSystem;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED, this.onFarewellPartyShowed, this);
	}

	private onFarewellPartyShowed(): void {
		this.windowViewSystem
			.getFarewellPartyWindow()
			.once(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED).includes(this.onFarewellPartyShowed)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED, this.onFarewellPartyShowed, this);

			const farewellPartyWindow = this.windowViewSystem.getFarewellPartyWindow();
			if (farewellPartyWindow && farewellPartyWindow.listeners(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED).includes(this.onUnlocked)) {
				farewellPartyWindow.off(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED, this.onUnlocked, this);
			}
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypeQuestWindowAppearModel extends TutorialStepUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	constructor(
		windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_QUEST);

		this.windowViewSystem = windowViewSystem;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED).includes(this.onUnlocked)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypeCollectionsWindowAppearModel extends TutorialStepUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	constructor(
		windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_COLLECTIONS);

		this.windowViewSystem = windowViewSystem;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED).includes(this.onUnlocked)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypeEventRewardsWindowAppearModel extends TutorialStepUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	constructor(
		windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_EVENT_REWARDS);

		this.windowViewSystem = windowViewSystem;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED).includes(this.onUnlocked)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypeBankBoostConfirmWindowAppearModel extends TutorialStepUnlockTypeBaseModel {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		key: string,
	) {
		super(key, TutorialStepUnlockTypes.WINDOW_APPEAR_BANK_BOOST_CONFIRM);

		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_BOOST_CONFIRM_SHOWED, this.onWindowShowed, this);
	}

	private onWindowShowed(window: BankBoostConfirmWindowView): void {
		window.once(BankBoostConfirmWindowView.EVENT_SHOW_ANIMATION_COMPLETED, this.onUnlocked, this);
	}

	public destroy(): void {
		if (this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_BANK_BOOST_CONFIRM_SHOWED).includes(this.onWindowShowed)) {
			this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_BANK_BOOST_CONFIRM_SHOWED, this.onWindowShowed, this);
		}
		super.destroy();
	}
}
