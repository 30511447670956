import { AssetsMetadataElementType } from '@src/types/LevelAssetNames';

export type EventLevelAssetsMetadataElementsType = {
	eventKey: string;
	metadata: AssetsMetadataElementType[];
}

export class EventLevelAssetNames {
	public static getEventPreloaderBgMetadata(eventKey: string, isNutaku: boolean): AssetsMetadataElementType {
		let preloader: string;

		if (isNutaku) {
			preloader = `images/preloader/nutaku/nutaku_preloader_bg_event${eventKey}.jpg`;
		} else {
			preloader = `images/preloader/preloader_bg_event${eventKey}.jpg`;
		}

		return { name: `preloader_bg_event${eventKey}`, path: preloader };
	}

	public static getEventCharacterAnimationsMetadata(eventKey: string): { [key: string]: string } {
		switch (eventKey) {
			case '51':
				return EventLevelAssetNames.getEvent51CharacterAnimationsMetadata();
			case '52':
				return EventLevelAssetNames.getEvent52CharacterAnimationsMetadata();
			case '53':
				return EventLevelAssetNames.getEvent53CharacterAnimationsMetadata();
			case '54':
				return EventLevelAssetNames.getEvent54CharacterAnimationsMetadata();
			case '55':
				return EventLevelAssetNames.getEvent55CharacterAnimationsMetadata();
			case '251':
				return EventLevelAssetNames.getEvent251CharacterAnimationsMetadata();
			case '252':
				return EventLevelAssetNames.getEvent252CharacterAnimationsMetadata();
			case '253':
				return EventLevelAssetNames.getEvent253CharacterAnimationsMetadata();
			case '254':
				return EventLevelAssetNames.getEvent254CharacterAnimationsMetadata();
			case '255':
				return EventLevelAssetNames.getEvent255CharacterAnimationsMetadata();
			case '1':
				return EventLevelAssetNames.getEvent1CharacterAnimationsMetadata();
			case '2':
				return EventLevelAssetNames.getEvent2CharacterAnimationsMetadata();
			case '3':
				return EventLevelAssetNames.getEvent3CharacterAnimationsMetadata();
			case '4':
				return EventLevelAssetNames.getEvent4CharacterAnimationsMetadata();
			case '6':
				return EventLevelAssetNames.getEvent6CharacterAnimationsMetadata();
			case '7':
				return EventLevelAssetNames.getEvent7CharacterAnimationsMetadata();
			case '8':
				return EventLevelAssetNames.getEvent8CharacterAnimationsMetadata();
			case '9':
				return EventLevelAssetNames.getEvent9CharacterAnimationsMetadata();
			case '10':
				return EventLevelAssetNames.getEvent10CharacterAnimationsMetadata();
			case '11':
				return EventLevelAssetNames.getEvent11CharacterAnimationsMetadata();
			case '12':
				return EventLevelAssetNames.getEvent12CharacterAnimationsMetadata();
			default:
				throw new Error(`Cannot resolve character animations for eventKey=${eventKey}`);
		}
	}

	public static getEventCharacterCards(key: string): AssetsMetadataElementType[] {
		let currentPages = [];
		if (key === '1') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '2') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '3') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '4') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '6') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '7') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '8') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '9') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else if (key === '10') {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
			];
		} else {
			currentPages = [
				{ name: 'characterCardAtlasEvent0', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-0.json` },
				{ name: 'characterCardAtlasEvent1', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-1.json` },
				{ name: 'characterCardAtlasEvent2', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-2.json` },
				{ name: 'characterCardAtlasEvent3', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-3.json` },
				{ name: 'characterCardAtlasEvent4', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-4.json` },
				{ name: 'characterCardAtlasEvent5', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-5.json` },
				{ name: 'characterCardAtlasEvent6', path: `atlas/events/event${key}/characters/event${key}_characterCardAtlas-6.json` },
			];
		}
		return currentPages;
	}

	public static getEventCharacterIcons(key: string): AssetsMetadataElementType[] {
		return [
			{ name: 'characterIconAtlasEvent', path: `atlas/events/event${key}/characters/event${key}_characterIconAtlas.json` },
		];
	}

	public static getEventMiniCharacters(key: string): AssetsMetadataElementType[] {
		return [
			{ name: 'miniCharactersAtlasEvent', path: `atlas/events/event${key}/miniCharacters/event${key}_miniCharactersAtlas.json` },
		];
	}

	public static getEventUpgradeIcons(key: string): AssetsMetadataElementType[] {
		return [
			{ name: 'upgradeIconAtlasEvent', path: `atlas/events/event${key}/upgrades/event${key}_upgradeIconAtlas-0.json` },
		];
	}

	public static getEventDialogs(key: string): AssetsMetadataElementType[] {
		return [
			{ name: 'dialogAtlasEvent', path: `atlas/events/event${key}/dialogs/event${key}_dialogsAtlas.json` },
		];
	}

	public static getEventMiniCardIcons(key: string): AssetsMetadataElementType[] {
		return [
			{ name: 'miniCardsIconsAtlasEvent', path: `atlas/events/event${key}/miniCardsIcons/event${key}_miniCardsIconsAtlas.json` },
		];
	}

	// Event 1 pack START
	private static getEvent1CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character10001Animation: 'animations/events/event1/characters/eventCharacter10001.json',
			character10002Animation: 'animations/events/event1/characters/eventCharacter10002.json',
			character10003Animation: 'animations/events/event1/characters/eventCharacter10003.json',
			character10004Animation: 'animations/events/event1/characters/eventCharacter10004.json',
			character10005Animation: 'animations/events/event1/characters/eventCharacter10005.json',
			character10006Animation: 'animations/events/event1/characters/eventCharacter10006.json',
			character10007Animation: 'animations/events/event1/characters/eventCharacter10007.json',
			character10008Animation: 'animations/events/event1/characters/eventCharacter10008.json',
			character10009Animation: 'animations/events/event1/characters/eventCharacter10009.json',
			character10010Animation: 'animations/events/event1/characters/eventCharacter10010.json',
			character10011Animation: 'animations/events/event1/characters/eventCharacter10011.json',
			character10012Animation: 'animations/events/event1/characters/eventCharacter10012.json',
			character10013Animation: 'animations/events/event1/characters/eventCharacter10013.json',
			character10014Animation: 'animations/events/event1/characters/eventCharacter10014.json',
			character10015Animation: 'animations/events/event1/characters/eventCharacter10015.json',
			character10016Animation: 'animations/events/event1/characters/eventCharacter10016.json',
			character10017Animation: 'animations/events/event1/characters/eventCharacter10017.json',
			character10018Animation: 'animations/events/event1/characters/eventCharacter10018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT1_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event1/businessBg/event1_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event1/businessBg/event1_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event1/businessBg/event1_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT1_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event1/bank/event1_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT1_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event1/lootboxes/event1_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT1_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event1/ui/event1_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT1_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event1/miniCardsBase/event1_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT1_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '1',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT1: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT1_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT1_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT1_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT1_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT1_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT1_INCOME_MONEY_ANIMATION,
	];
	// Event 1 pack END

	// Event 2 pack START
	private static getEvent2CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character20001Animation: 'animations/events/event2/characters/eventCharacter20001.json',
			character20002Animation: 'animations/events/event2/characters/eventCharacter20002.json',
			character20003Animation: 'animations/events/event2/characters/eventCharacter20003.json',
			character20004Animation: 'animations/events/event2/characters/eventCharacter20004.json',
			character20005Animation: 'animations/events/event2/characters/eventCharacter20005.json',
			character20006Animation: 'animations/events/event2/characters/eventCharacter20006.json',
			character20007Animation: 'animations/events/event2/characters/eventCharacter20007.json',
			character20008Animation: 'animations/events/event2/characters/eventCharacter20008.json',
			character20009Animation: 'animations/events/event2/characters/eventCharacter20009.json',
			character20010Animation: 'animations/events/event2/characters/eventCharacter20010.json',
			character20011Animation: 'animations/events/event2/characters/eventCharacter20011.json',
			character20012Animation: 'animations/events/event2/characters/eventCharacter20012.json',
			character20013Animation: 'animations/events/event2/characters/eventCharacter20013.json',
			character20014Animation: 'animations/events/event2/characters/eventCharacter20014.json',
			character20015Animation: 'animations/events/event2/characters/eventCharacter20015.json',
			character20016Animation: 'animations/events/event2/characters/eventCharacter20016.json',
			character20017Animation: 'animations/events/event2/characters/eventCharacter20017.json',
			character20018Animation: 'animations/events/event2/characters/eventCharacter20018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT2_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event2/businessBg/event2_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event2/businessBg/event2_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event2/businessBg/event2_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT2_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event2/bank/event2_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT2_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event2/lootboxes/event2_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT2_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event2/ui/event2_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT2_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event2/miniCardsBase/event2_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT2_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '2',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT2: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT2_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT2_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT2_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT2_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT2_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT2_INCOME_MONEY_ANIMATION,
	];
	// Event 2 pack END

	// Event 3 pack START
	private static getEvent3CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character30001Animation: 'animations/events/event3/characters/eventCharacter30001.json',
			character30002Animation: 'animations/events/event3/characters/eventCharacter30002.json',
			character30003Animation: 'animations/events/event3/characters/eventCharacter30003.json',
			character30004Animation: 'animations/events/event3/characters/eventCharacter30004.json',
			character30005Animation: 'animations/events/event3/characters/eventCharacter30005.json',
			character30006Animation: 'animations/events/event3/characters/eventCharacter30006.json',
			character30007Animation: 'animations/events/event3/characters/eventCharacter30007.json',
			character30008Animation: 'animations/events/event3/characters/eventCharacter30008.json',
			character30009Animation: 'animations/events/event3/characters/eventCharacter30009.json',
			character30010Animation: 'animations/events/event3/characters/eventCharacter30010.json',
			character30011Animation: 'animations/events/event3/characters/eventCharacter30011.json',
			character30012Animation: 'animations/events/event3/characters/eventCharacter30012.json',
			character30013Animation: 'animations/events/event3/characters/eventCharacter30013.json',
			character30014Animation: 'animations/events/event3/characters/eventCharacter30014.json',
			character30015Animation: 'animations/events/event3/characters/eventCharacter30015.json',
			character30016Animation: 'animations/events/event3/characters/eventCharacter30016.json',
			character30017Animation: 'animations/events/event3/characters/eventCharacter30017.json',
			character30018Animation: 'animations/events/event3/characters/eventCharacter30018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT3_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event3/businessBg/event3_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event3/businessBg/event3_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event3/businessBg/event3_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT3_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event3/bank/event3_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT3_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event3/lootboxes/event3_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT3_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event3/ui/event3_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT3_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event3/miniCardsBase/event3_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT3_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '3',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT3: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT3_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT3_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT3_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT3_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT3_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT3_INCOME_MONEY_ANIMATION,
	];
	// Event 3 pack END

	// Event 4 pack START
	private static getEvent4CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character40001Animation: 'animations/events/event4/characters/eventCharacter40001.json',
			character40002Animation: 'animations/events/event4/characters/eventCharacter40002.json',
			character40003Animation: 'animations/events/event4/characters/eventCharacter40003.json',
			character40004Animation: 'animations/events/event4/characters/eventCharacter40004.json',
			character40005Animation: 'animations/events/event4/characters/eventCharacter40005.json',
			character40006Animation: 'animations/events/event4/characters/eventCharacter40006.json',
			character40007Animation: 'animations/events/event4/characters/eventCharacter40007.json',
			character40008Animation: 'animations/events/event4/characters/eventCharacter40008.json',
			character40009Animation: 'animations/events/event4/characters/eventCharacter40009.json',
			character40010Animation: 'animations/events/event4/characters/eventCharacter40010.json',
			character40011Animation: 'animations/events/event4/characters/eventCharacter40011.json',
			character40012Animation: 'animations/events/event4/characters/eventCharacter40012.json',
			character40013Animation: 'animations/events/event4/characters/eventCharacter40013.json',
			character40014Animation: 'animations/events/event4/characters/eventCharacter40014.json',
			character40015Animation: 'animations/events/event4/characters/eventCharacter40015.json',
			character40016Animation: 'animations/events/event4/characters/eventCharacter40016.json',
			character40017Animation: 'animations/events/event4/characters/eventCharacter40017.json',
			character40018Animation: 'animations/events/event4/characters/eventCharacter40018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT4_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event4/businessBg/event4_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event4/businessBg/event4_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event4/businessBg/event4_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT4_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event4/bank/event4_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT4_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event4/lootboxes/event4_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT4_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event4/ui/event4_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT4_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event4/miniCardsBase/event4_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT4_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '4',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT4: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT4_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT4_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT4_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT4_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT4_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT4_INCOME_MONEY_ANIMATION,
	];
	// Event 4 pack END

	// Event 51 pack START
	private static getEvent51CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character51001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character51002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character51003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character51004Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character51005Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character51007Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character51008Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character51010Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character51011Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character51013Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character51014Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character51006Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character51009Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character51012Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character51015Animation: 'animations/events/event51/characters/eventCharacter51012.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT51_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event51/businessBg/event51_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event51/businessBg/event51_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT51_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event51/bank/event51_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT51_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event51/lootboxes/event51_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT51_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event51/ui/event51_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT51_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event51/miniCardsBase/event51_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT51_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '51',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT51: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT51_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT51_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT51_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT51_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT51_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT51_INCOME_MONEY_ANIMATION,
	];
	// Event 51 pack END

	// Event 52 pack START
	private static getEvent52CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character52001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character52002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character52003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character52004Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character52005Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character52007Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character52008Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character52010Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character52011Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character52013Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character52014Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character52006Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character52009Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character52012Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character52015Animation: 'animations/events/event51/characters/eventCharacter51015.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT52_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event52/businessBg/event52_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event52/businessBg/event52_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT52_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT52_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT52_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT52_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT52_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '52',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT52: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT52_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT52_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT52_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT52_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT52_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT52_INCOME_MONEY_ANIMATION,
	];
	// Event 52 pack END

	// Event 53 pack START
	private static getEvent53CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character53001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character53002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character53003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character53004Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character53005Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character53007Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character53008Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character53010Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character53011Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character53013Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character53014Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character53006Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character53009Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character53012Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character53015Animation: 'animations/events/event51/characters/eventCharacter51013.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT53_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event53/businessBg/event53_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event53/businessBg/event53_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT53_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event53/bank/event53_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT53_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT53_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT53_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT53_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '53',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT53: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT53_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT53_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT53_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT53_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT53_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT53_INCOME_MONEY_ANIMATION,
	];
	// Event 53 pack END

	// Event 54 pack START
	private static getEvent54CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character54001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character54002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character54003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character54004Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character54005Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character54007Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character54008Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character54010Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character54011Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character54013Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character54014Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character54006Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character54009Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character54012Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character54015Animation: 'animations/events/event51/characters/eventCharacter51012.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT54_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event54/businessBg/event54_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event54/businessBg/event54_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT54_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT54_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT54_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT54_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT54_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '54',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT54: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT54_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT54_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT54_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT54_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT54_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT54_INCOME_MONEY_ANIMATION,
	];
	// Event 54 pack END

	// Event 55 pack START
	private static getEvent55CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character55001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character55002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character55003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character55004Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character55005Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character55007Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character55008Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character55010Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character55011Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character55013Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character55014Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character55006Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character55009Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character55012Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character55015Animation: 'animations/events/event51/characters/eventCharacter51013.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT55_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event55/businessBg/event55_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event55/businessBg/event55_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT55_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT55_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT55_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT55_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT55_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '55',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT55: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT55_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT55_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT55_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT55_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT55_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT55_INCOME_MONEY_ANIMATION,
	];
	// Event 55 pack END

	// Event 251 pack START
	private static getEvent251CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character251001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character251002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character251003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character251004Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character251005Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character251007Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character251008Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character251010Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character251011Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character251013Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character251014Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character251006Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character251009Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character251012Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character251015Animation: 'animations/events/event51/characters/eventCharacter51012.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT251_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event251/businessBg/event251_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event251/businessBg/event251_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT251_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event51/bank/event51_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT251_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event51/lootboxes/event51_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT251_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event51/ui/event51_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT251_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event51/miniCardsBase/event51_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT251_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '251',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT251: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT251_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT251_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT251_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT251_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT251_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT251_INCOME_MONEY_ANIMATION,
	];
	// Event 251 pack END

	// Event 252 pack START
	private static getEvent252CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character252001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character252002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character252003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character252004Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character252005Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character252007Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character252008Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character252010Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character252011Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character252013Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character252014Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character252006Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character252009Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character252012Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character252015Animation: 'animations/events/event51/characters/eventCharacter51015.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT252_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event252/businessBg/event252_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event252/businessBg/event252_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT252_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT252_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT252_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT252_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT252_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '252',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT252: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT252_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT252_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT252_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT252_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT252_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT252_INCOME_MONEY_ANIMATION,
	];
	// Event 252 pack END

	// Event 253 pack START
	private static getEvent253CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character253001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character253002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character253003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character253004Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character253005Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character253007Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character253008Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character253010Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character253011Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character253013Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character253014Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character253006Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character253009Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character253012Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character253015Animation: 'animations/events/event51/characters/eventCharacter51013.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT253_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event253/businessBg/event253_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event253/businessBg/event253_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT253_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT253_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT253_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT253_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT253_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '253',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT253: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT253_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT253_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT253_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT253_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT253_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT253_INCOME_MONEY_ANIMATION,
	];
	// Event 253 pack END

	// Event 254 pack START
	private static getEvent254CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character254001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character254002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character254003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character254004Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character254005Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character254007Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character254008Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character254010Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character254011Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character254013Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character254014Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character254006Animation: 'animations/events/event51/characters/eventCharacter51013.json',
			character254009Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character254012Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character254015Animation: 'animations/events/event51/characters/eventCharacter51012.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT254_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event254/businessBg/event254_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event254/businessBg/event254_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT254_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT254_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT254_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT254_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT254_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '254',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT254: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT254_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT254_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT254_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT254_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT254_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT254_INCOME_MONEY_ANIMATION,
	];
	// Event 254 pack END

	// Event 255 pack START
	private static getEvent255CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character255001Animation: 'animations/events/event51/characters/eventCharacter51001.json',
			character255002Animation: 'animations/events/event51/characters/eventCharacter51002.json',
			character255003Animation: 'animations/events/event51/characters/eventCharacter51003.json',
			character255004Animation: 'animations/events/event51/characters/eventCharacter51008.json',
			character255005Animation: 'animations/events/event51/characters/eventCharacter51009.json',
			character255007Animation: 'animations/events/event51/characters/eventCharacter51006.json',
			character255008Animation: 'animations/events/event51/characters/eventCharacter51007.json',
			character255010Animation: 'animations/events/event51/characters/eventCharacter51010.json',
			character255011Animation: 'animations/events/event51/characters/eventCharacter51011.json',
			character255013Animation: 'animations/events/event51/characters/eventCharacter51004.json',
			character255014Animation: 'animations/events/event51/characters/eventCharacter51005.json',
			character255006Animation: 'animations/events/event51/characters/eventCharacter51014.json',
			character255009Animation: 'animations/events/event51/characters/eventCharacter51015.json',
			character255012Animation: 'animations/events/event51/characters/eventCharacter51012.json',
			character255015Animation: 'animations/events/event51/characters/eventCharacter51013.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT255_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event255/businessBg/event255_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event255/businessBg/event255_businessBgAtlas-1.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT255_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event52/bank/event52_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT255_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event52/lootboxes/event52_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT255_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event52/ui/event52_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT255_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event52/miniCardsBase/event52_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT255_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '255',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT255: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT255_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT255_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT255_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT255_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT255_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT255_INCOME_MONEY_ANIMATION,
	];
	// Event 255 pack END

	// Event 6 pack START
	private static getEvent6CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character60001Animation: 'animations/events/event6/characters/eventCharacter60001.json',
			character60002Animation: 'animations/events/event6/characters/eventCharacter60002.json',
			character60003Animation: 'animations/events/event6/characters/eventCharacter60003.json',
			character60004Animation: 'animations/events/event6/characters/eventCharacter60004.json',
			character60005Animation: 'animations/events/event6/characters/eventCharacter60005.json',
			character60006Animation: 'animations/events/event6/characters/eventCharacter60006.json',
			character60007Animation: 'animations/events/event6/characters/eventCharacter60007.json',
			character60008Animation: 'animations/events/event6/characters/eventCharacter60008.json',
			character60009Animation: 'animations/events/event6/characters/eventCharacter60009.json',
			character60010Animation: 'animations/events/event6/characters/eventCharacter60010.json',
			character60011Animation: 'animations/events/event6/characters/eventCharacter60011.json',
			character60012Animation: 'animations/events/event6/characters/eventCharacter60012.json',
			character60013Animation: 'animations/events/event6/characters/eventCharacter60013.json',
			character60014Animation: 'animations/events/event6/characters/eventCharacter60014.json',
			character60015Animation: 'animations/events/event6/characters/eventCharacter60015.json',
			character60016Animation: 'animations/events/event6/characters/eventCharacter60016.json',
			character60017Animation: 'animations/events/event6/characters/eventCharacter60017.json',
			character60018Animation: 'animations/events/event6/characters/eventCharacter60018.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT6_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event6/businessBg/event6_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event6/businessBg/event6_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event6/businessBg/event6_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT6_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event6/bank/event6_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT6_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event6/lootboxes/event6_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT6_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event6/ui/event6_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT6_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event6/miniCardsBase/event6_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT6_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '6',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT6: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT6_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT6_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT6_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT6_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT6_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT6_INCOME_MONEY_ANIMATION,
	];
	// Event 6 pack END

	// Event 7 pack START
	private static getEvent7CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character70001Animation: 'animations/events/event7/characters/eventCharacter70001.json',
			character70002Animation: 'animations/events/event7/characters/eventCharacter70002.json',
			character70003Animation: 'animations/events/event7/characters/eventCharacter70003.json',
			character70004Animation: 'animations/events/event7/characters/eventCharacter70004.json',
			character70005Animation: 'animations/events/event7/characters/eventCharacter70005.json',
			character70006Animation: 'animations/events/event7/characters/eventCharacter70006.json',
			character70007Animation: 'animations/events/event7/characters/eventCharacter70007.json',
			character70008Animation: 'animations/events/event7/characters/eventCharacter70008.json',
			character70009Animation: 'animations/events/event7/characters/eventCharacter70009.json',
			character70010Animation: 'animations/events/event7/characters/eventCharacter70010.json',
			character70011Animation: 'animations/events/event7/characters/eventCharacter70011.json',
			character70012Animation: 'animations/events/event7/characters/eventCharacter70012.json',
			character70013Animation: 'animations/events/event7/characters/eventCharacter70013.json',
			character70014Animation: 'animations/events/event7/characters/eventCharacter70014.json',
			character70015Animation: 'animations/events/event7/characters/eventCharacter70015.json',
			character70016Animation: 'animations/events/event7/characters/eventCharacter70016.json',
			character70017Animation: 'animations/events/event7/characters/eventCharacter70017.json',
			character70018Animation: 'animations/events/event7/characters/eventCharacter70018.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT7_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event7/businessBg/event7_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event7/businessBg/event7_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event7/businessBg/event7_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT7_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event7/bank/event7_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT7_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event7/lootboxes/event7_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT7_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event7/ui/event7_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT7_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event7/miniCardsBase/event7_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT7_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '7',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT7: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT7_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT7_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT7_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT7_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT7_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT7_INCOME_MONEY_ANIMATION,
	];
	// Event 7 pack END

	// Event 8 pack START
	private static getEvent8CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character80001Animation: 'animations/events/event8/characters/eventCharacter80001.json',
			character80002Animation: 'animations/events/event8/characters/eventCharacter80002.json',
			character80003Animation: 'animations/events/event8/characters/eventCharacter80003.json',
			character80004Animation: 'animations/events/event8/characters/eventCharacter80004.json',
			character80005Animation: 'animations/events/event8/characters/eventCharacter80005.json',
			character80006Animation: 'animations/events/event8/characters/eventCharacter80006.json',
			character80007Animation: 'animations/events/event8/characters/eventCharacter80007.json',
			character80008Animation: 'animations/events/event8/characters/eventCharacter80008.json',
			character80009Animation: 'animations/events/event8/characters/eventCharacter80009.json',
			character80010Animation: 'animations/events/event8/characters/eventCharacter80010.json',
			character80011Animation: 'animations/events/event8/characters/eventCharacter80011.json',
			character80012Animation: 'animations/events/event8/characters/eventCharacter80012.json',
			character80013Animation: 'animations/events/event8/characters/eventCharacter80013.json',
			character80014Animation: 'animations/events/event8/characters/eventCharacter80014.json',
			character80015Animation: 'animations/events/event8/characters/eventCharacter80015.json',
			character80016Animation: 'animations/events/event8/characters/eventCharacter80016.json',
			character80017Animation: 'animations/events/event8/characters/eventCharacter80017.json',
			character80018Animation: 'animations/events/event8/characters/eventCharacter80018.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT8_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event8/businessBg/event8_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event8/businessBg/event8_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event8/businessBg/event8_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT8_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event8/bank/event8_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT8_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event8/lootboxes/event8_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT8_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event8/ui/event8_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT8_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event8/miniCardsBase/event8_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT8_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '8',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT8: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT8_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT8_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT8_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT8_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT8_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT8_INCOME_MONEY_ANIMATION,
	];
	// Event 8 pack END

	// Event 9 pack START
	private static getEvent9CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character90001Animation: 'animations/events/event9/characters/eventCharacter90001.json',
			character90002Animation: 'animations/events/event9/characters/eventCharacter90002.json',
			character90003Animation: 'animations/events/event9/characters/eventCharacter90003.json',
			character90004Animation: 'animations/events/event9/characters/eventCharacter90004.json',
			character90005Animation: 'animations/events/event9/characters/eventCharacter90005.json',
			character90006Animation: 'animations/events/event9/characters/eventCharacter90006.json',
			character90007Animation: 'animations/events/event9/characters/eventCharacter90007.json',
			character90008Animation: 'animations/events/event9/characters/eventCharacter90008.json',
			character90009Animation: 'animations/events/event9/characters/eventCharacter90009.json',
			character90010Animation: 'animations/events/event9/characters/eventCharacter90010.json',
			character90011Animation: 'animations/events/event9/characters/eventCharacter90011.json',
			character90012Animation: 'animations/events/event9/characters/eventCharacter90012.json',
			character90013Animation: 'animations/events/event9/characters/eventCharacter90013.json',
			character90014Animation: 'animations/events/event9/characters/eventCharacter90014.json',
			character90015Animation: 'animations/events/event9/characters/eventCharacter90015.json',
			character90016Animation: 'animations/events/event9/characters/eventCharacter90016.json',
			character90017Animation: 'animations/events/event9/characters/eventCharacter90017.json',
			character90018Animation: 'animations/events/event9/characters/eventCharacter90018.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT9_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event9/businessBg/event9_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event9/businessBg/event9_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event9/businessBg/event9_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT9_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event9/bank/event9_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT9_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event9/lootboxes/event9_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT9_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event9/ui/event9_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT9_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event9/miniCardsBase/event9_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT9_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '9',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT9: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT9_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT9_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT9_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT9_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT9_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT9_INCOME_MONEY_ANIMATION,
	];
	// Event 9 pack END

	// Event 10 pack START
	private static getEvent10CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character100001Animation: 'animations/events/event10/characters/eventCharacter100001.json',
			character100002Animation: 'animations/events/event10/characters/eventCharacter100002.json',
			character100003Animation: 'animations/events/event10/characters/eventCharacter100003.json',
			character100004Animation: 'animations/events/event10/characters/eventCharacter100004.json',
			character100005Animation: 'animations/events/event10/characters/eventCharacter100005.json',
			character100006Animation: 'animations/events/event10/characters/eventCharacter100006.json',
			character100007Animation: 'animations/events/event10/characters/eventCharacter100007.json',
			character100008Animation: 'animations/events/event10/characters/eventCharacter100008.json',
			character100009Animation: 'animations/events/event10/characters/eventCharacter100009.json',
			character100010Animation: 'animations/events/event10/characters/eventCharacter100010.json',
			character100011Animation: 'animations/events/event10/characters/eventCharacter100011.json',
			character100012Animation: 'animations/events/event10/characters/eventCharacter100012.json',
			character100013Animation: 'animations/events/event10/characters/eventCharacter100013.json',
			character100014Animation: 'animations/events/event10/characters/eventCharacter100014.json',
			character100015Animation: 'animations/events/event10/characters/eventCharacter100015.json',
			character100016Animation: 'animations/events/event10/characters/eventCharacter100016.json',
			character100017Animation: 'animations/events/event10/characters/eventCharacter100017.json',
			character100018Animation: 'animations/events/event10/characters/eventCharacter100018.json',

		};
	}

	public static readonly ATLAS_EVENT_EVENT10_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event10/businessBg/event10_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event10/businessBg/event10_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event10/businessBg/event10_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT10_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event10/bank/event10_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT10_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event10/lootboxes/event10_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT10_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event10/ui/event10_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT10_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event10/miniCardsBase/event10_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT10_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '10',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT10: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT10_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT10_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT10_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT10_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT10_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT10_INCOME_MONEY_ANIMATION,
	];
	// Event 10 pack END

	// Event 11 pack START
	private static getEvent11CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character110001Animation: 'animations/events/event11/characters/eventCharacter110001.json',
			character110002Animation: 'animations/events/event11/characters/eventCharacter110002.json',
			character110003Animation: 'animations/events/event11/characters/eventCharacter110003.json',
			character110004Animation: 'animations/events/event11/characters/eventCharacter110004.json',
			character110005Animation: 'animations/events/event11/characters/eventCharacter110005.json',
			character110006Animation: 'animations/events/event11/characters/eventCharacter110006.json',
			character110007Animation: 'animations/events/event11/characters/eventCharacter110007.json',
			character110008Animation: 'animations/events/event11/characters/eventCharacter110008.json',
			character110009Animation: 'animations/events/event11/characters/eventCharacter110009.json',
			character110010Animation: 'animations/events/event11/characters/eventCharacter110010.json',
			character110011Animation: 'animations/events/event11/characters/eventCharacter110011.json',
			character110012Animation: 'animations/events/event11/characters/eventCharacter110012.json',
			character110013Animation: 'animations/events/event11/characters/eventCharacter110013.json',
			character110014Animation: 'animations/events/event11/characters/eventCharacter110014.json',
			character110015Animation: 'animations/events/event11/characters/eventCharacter110015.json',
			character110016Animation: 'animations/events/event11/characters/eventCharacter110016.json',
			character110017Animation: 'animations/events/event11/characters/eventCharacter110017.json',
			character110018Animation: 'animations/events/event11/characters/eventCharacter110018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT11_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event11/businessBg/event11_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event11/businessBg/event11_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event11/businessBg/event11_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT11_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event11/bank/event11_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT11_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event11/lootboxes/event11_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT11_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event11/ui/event11_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT11_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event11/miniCardsBase/event11_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT11_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '11',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT11: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT11_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT11_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT11_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT11_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT11_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT11_INCOME_MONEY_ANIMATION,
	];
	// Event 11 pack END

	// Event 12 pack START
	private static getEvent12CharacterAnimationsMetadata(): { [key: string]: string } {
		return {
			character120001Animation: 'animations/events/event12/characters/eventCharacter120001.json',
			character120002Animation: 'animations/events/event12/characters/eventCharacter120002.json',
			character120003Animation: 'animations/events/event12/characters/eventCharacter120003.json',
			character120004Animation: 'animations/events/event12/characters/eventCharacter120004.json',
			character120005Animation: 'animations/events/event12/characters/eventCharacter120005.json',
			character120006Animation: 'animations/events/event12/characters/eventCharacter120006.json',
			character120007Animation: 'animations/events/event12/characters/eventCharacter120007.json',
			character120008Animation: 'animations/events/event12/characters/eventCharacter120008.json',
			character120009Animation: 'animations/events/event12/characters/eventCharacter120009.json',
			character120010Animation: 'animations/events/event12/characters/eventCharacter120010.json',
			character120012Animation: 'animations/events/event12/characters/eventCharacter120012.json',
			character120011Animation: 'animations/events/event12/characters/eventCharacter120011.json',
			character120013Animation: 'animations/events/event12/characters/eventCharacter120013.json',
			character120014Animation: 'animations/events/event12/characters/eventCharacter120014.json',
			character120015Animation: 'animations/events/event12/characters/eventCharacter120015.json',
			character120016Animation: 'animations/events/event12/characters/eventCharacter120016.json',
			character120017Animation: 'animations/events/event12/characters/eventCharacter120017.json',
			character120018Animation: 'animations/events/event12/characters/eventCharacter120018.json',
		};
	}

	public static readonly ATLAS_EVENT_EVENT12_BUSINESS_BG: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'businessBgAtlasEvent0', path: 'atlas/events/event12/businessBg/event12_businessBgAtlas-0.json' },
			{ name: 'businessBgAtlasEvent1', path: 'atlas/events/event12/businessBg/event12_businessBgAtlas-1.json' },
			{ name: 'businessBgAtlasEvent2', path: 'atlas/events/event12/businessBg/event12_businessBgAtlas-2.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT12_BANK: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'event_bankAtlas', path: 'atlas/events/event12/bank/event12_bankAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT12_LOOTBOXES: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'event_lootboxAtlas', path: 'atlas/events/event12/lootboxes/event12_lootboxAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT12_UI: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'event_uiAtlas', path: 'atlas/events/event12/ui/event12_uiAtlas.json' },
		],
	};

	public static readonly ATLAS_EVENT_EVENT12_MINI_CARDS_BASE: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'event_miniCardsBaseAtlas', path: 'atlas/events/event12/miniCardsBase/event12_miniCardsBaseAtlas.json' },
		],
	};

	public static readonly ASSET_EVENT_EVENT12_INCOME_MONEY_ANIMATION: EventLevelAssetsMetadataElementsType = {
		eventKey: '12',
		metadata: [
			{ name: 'event_incomeMoneyAnimation', path: 'animations/incomeEventMoney/event_incomeMoneyAnimation.json' },
		],
	};

	public static readonly ASSETS_EVENT12: EventLevelAssetsMetadataElementsType[] = [
		EventLevelAssetNames.ATLAS_EVENT_EVENT12_BANK,
		EventLevelAssetNames.ATLAS_EVENT_EVENT12_LOOTBOXES,
		EventLevelAssetNames.ATLAS_EVENT_EVENT12_UI,
		EventLevelAssetNames.ATLAS_EVENT_EVENT12_MINI_CARDS_BASE,
		EventLevelAssetNames.ATLAS_EVENT_EVENT12_BUSINESS_BG,
		EventLevelAssetNames.ASSET_EVENT_EVENT12_INCOME_MONEY_ANIMATION,
	];
	// Event 12 pack END

	public static readonly ASSETS_EVENTS: EventLevelAssetsMetadataElementsType[] = [
		...EventLevelAssetNames.ASSETS_EVENT1,
		...EventLevelAssetNames.ASSETS_EVENT2,
		...EventLevelAssetNames.ASSETS_EVENT3,
		...EventLevelAssetNames.ASSETS_EVENT4,
		...EventLevelAssetNames.ASSETS_EVENT51,
		...EventLevelAssetNames.ASSETS_EVENT52,
		...EventLevelAssetNames.ASSETS_EVENT53,
		...EventLevelAssetNames.ASSETS_EVENT54,
		...EventLevelAssetNames.ASSETS_EVENT55,
		...EventLevelAssetNames.ASSETS_EVENT251,
		...EventLevelAssetNames.ASSETS_EVENT252,
		...EventLevelAssetNames.ASSETS_EVENT253,
		...EventLevelAssetNames.ASSETS_EVENT254,
		...EventLevelAssetNames.ASSETS_EVENT255,
		...EventLevelAssetNames.ASSETS_EVENT6,
		...EventLevelAssetNames.ASSETS_EVENT7,
		...EventLevelAssetNames.ASSETS_EVENT8,
		...EventLevelAssetNames.ASSETS_EVENT9,
		...EventLevelAssetNames.ASSETS_EVENT10,
		...EventLevelAssetNames.ASSETS_EVENT11,
		...EventLevelAssetNames.ASSETS_EVENT12,
	];

	public static readonly EVENT_ASSET_NAMES_TO_SWAP: string[] = [
		'event_bankAtlas',
		'event_lootboxAtlas',
		'event_uiAtlas',
		'event_miniCardsBaseAtlas',
		'event_incomeMoneyAnimation',
	];

	public static readonly EVENT_FONT_NAMES_TO_SWAP: string[] = [
		'event_wendyOneShadowBold',
		'event_wendyOneGold',
		'event_wendyOneTitle',
		'event_SFCompact',
	];

	public static getMainAssetsToLoad(eventKey: string): AssetsMetadataElementType[][] {
		const eventAssets = EventLevelAssetNames.ASSETS_EVENTS
			.filter(data => data.eventKey === eventKey)
			.map(data => data.metadata);
		const eventFonts = [
			{ name: 'event_wendyOneShadowBold', path: `fonts/events/event${eventKey}_wendyOneShadowBold.xml` },
			{ name: 'event_wendyOneGold', path: `fonts/events/event${eventKey}_wendyOneGold.xml` },
			{ name: 'event_wendyOneTitle', path: 'fonts/wendyOneTitle.xml' },
			{ name: 'event_SFCompact', path: 'fonts/SFCompact.xml' },

		];
		return [
			...eventAssets,
			eventFonts,
			EventLevelAssetNames.getEventCharacterCards(eventKey),
			EventLevelAssetNames.getEventCharacterIcons(eventKey),
			EventLevelAssetNames.getEventMiniCharacters(eventKey),
			EventLevelAssetNames.getEventUpgradeIcons(eventKey),
			EventLevelAssetNames.getEventDialogs(eventKey),
			EventLevelAssetNames.getEventMiniCardIcons(eventKey),
		];
	}
}
