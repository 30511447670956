import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter52004View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52004View';
import { EventCharacter52005View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52005View';
import { EventCharacter52006View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52006View';

export class EventBusiness52002View extends BusinessBaseView {
	constructor() {
		const eventCharacter52004View = new EventCharacter52004View();
		const eventCharacter52005View = new EventCharacter52005View();
		const eventCharacter52006View = new EventCharacter52006View();

		super('business52002', [eventCharacter52004View, eventCharacter52005View, eventCharacter52006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter52004View as PIXI.DisplayObject,
			eventCharacter52005View,
			eventCharacter52006View,
		);
	}
}
