import { ReturnFromEventLevelConfirmationWindowView } from '@views/windows/ReturnFromEventLevelConfirmationWindowView';
import { WindowViewBaseFactory } from './WindowBaseFactory';
import { EventLevelRankingWindowView } from '@views/windows/rankingEventWindow/EventLevelRankingWindowView';
import { EventLevelRewardsWindowView } from '@views/windows/EventLevelRewardsWindowView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AbstractModelInstance } from '@main/modelInstance/AbstractModelInstance';
import { GameProfileModel } from '@models/GameProfileModel';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { MainGameModels } from '@main/EventLevelInstance';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';

export class EventLevelWindowViewFactory extends WindowViewBaseFactory {
	private readonly eventLevelModel: EventLevelModel;
	private readonly runInEpisodeAssetsSandbox: SandboxOperation;

	private readonly mainGameCardMiniViewFactory: CardMiniViewFactory;

	constructor(
		eventLevelModel: EventLevelModel,
		serverTime: ServerTimeModel,
		modelInstance: AbstractModelInstance,
		mainGameModels: MainGameModels,
		gameProfileModel: GameProfileModel,
		dialogConfigs: Map<string, DialogConfig>,
		constantsConfig: ConstantsConfig,
		localizationSupportConfig: LocalizationSupportConfig,
		networkRequestSender: NetworkRequestSender,
		redeemCode: string,
		cheatModel: CheatModel,
		runInEpisodeAssetsSandbox: SandboxOperation,
		groupUpgradesByBusinesses: boolean,
		collectionWithMoneyPanels: boolean,
		isNutaku: boolean,
		version: string,
	) {
		super(
			serverTime,
			modelInstance,
			gameProfileModel,
			dialogConfigs,
			constantsConfig,
			localizationSupportConfig,
			networkRequestSender,
			redeemCode,
			cheatModel,
			groupUpgradesByBusinesses,
			collectionWithMoneyPanels,
			isNutaku,
			version,
		);

		this.eventLevelModel = eventLevelModel;
		this.runInEpisodeAssetsSandbox = runInEpisodeAssetsSandbox;

		this.mainGameCardMiniViewFactory = new CardMiniViewFactory(
			mainGameModels.characterModels,
			mainGameModels.upgradeModels,
			mainGameModels.totemModels,
			mainGameModels.boostModels,
			mainGameModels.timeskipModels,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createReturnFromEventConfirmationWindowView(): ReturnFromEventLevelConfirmationWindowView {
		return new ReturnFromEventLevelConfirmationWindowView();
	}

	// eslint-disable-next-line class-methods-use-this
	public createEventRankingWindowView(): EventLevelRankingWindowView {
		return new EventLevelRankingWindowView(
			this.gameProfileModel,
			this.eventLevelModel,
			this.mainGameCardMiniViewFactory,
			this.runInEpisodeAssetsSandbox,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createEventRewardsWindow(): EventLevelRewardsWindowView {
		let view: EventLevelRewardsWindowView;
		this.runInEpisodeAssetsSandbox.start(() => {
			view = new EventLevelRewardsWindowView(
				this.eventLevelModel,
				this.mainGameCardMiniViewFactory,
			);
		});
		return view;
	}
}
