import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferWindowPackBodyView } from './BankOfferWindowPackBodyView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export enum BodyViewType {
	VIEW_TYPE_1 = 'character2',
	VIEW_TYPE_2 = 'character4',
	VIEW_TYPE_3 = 'character10',
	VIEW_TYPE_4 = 'character27',
	VIEW_TYPE_5 = 'character30',
}

export class BankOfferWindowPackBodyMainView extends BankOfferWindowPackBodyView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly timerLabel: PIXI.extras.BitmapText;
	private readonly titleText: TextField;
	private readonly frame: PIXI.mesh.NineSlicePlane;
	private readonly optionalBg: PIXI.Sprite;

	constructor(
		bankOfferModel: BankOfferModel,
		bankTabElementWithOffer: BankTabElementWithOfferType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super(bankTabElementWithOffer);

		this.localizationStorage = LocalizationStorage.getInstance();
		const localizationKeys = bankOfferModel.getLocalizationKeys();

		this.frame = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['offer_frame_2'], 10, 10, 10, 10);
		this.frame.width = 1744;
		this.frame.height = 943;
		this.frame.pivot.set(this.frame.width / 2, this.frame.height / 2);

		this.optionalBg = new PIXI.Sprite(AssetsStorage.getResource('popup_offer_bg_10').texture);
		this.optionalBg.pivot.set(this.optionalBg.width / 2, this.optionalBg.height / 2);

		this.titleText = new TextField(
			'',
			{ font: '68px wendyOneTitle', align: 'center' },
			700,
			200,
		);
		this.titleText.anchor = 0.5;
		this.titleText.letterSpacing = 2;
		this.titleText.text = this.localizationStorage.getLocalizedString(localizationKeys.title);
		this.titleText.position.set(296, -340);

		const titleTextShadow = new TextField(
			'',
			{ font: '68px wendyOneTitle', align: 'center' },
			700,
			200,
		);
		titleTextShadow.anchor = 0.5;
		titleTextShadow.letterSpacing = 2;
		titleTextShadow.alpha = 0.5;
		titleTextShadow.text = this.localizationStorage.getLocalizedString(localizationKeys.title);
		titleTextShadow.position.set(296, -338);

		this.getCurrentBodyView(bankOfferModel.getCharacterLeftKey());

		this.timerLabel = new MultiColoredTextField({
			font: '32px wendyOneShadowBold',
		});
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(296, 410);

		const specialLabel = new PIXI.extras.BitmapText(
			this.localizationStorage.getLocalizedString('#offer_special_descr_label'),
			{ font: '34px wendyOneShadowBold' },
		);
		specialLabel.anchor = 0.5;
		specialLabel.position.set(296, 241);

		const buyButton = getBuyButtonHandler(
			354,
			92,
			bankTabElementWithOffer,
		);
		buyButton.position.set(296, 323);

		const rewardsContainer = getRewardMiniCardsContainerHandler(12, bankTabElementWithOffer, 1.1, true);
		rewardsContainer.position.set(296, -31);

		const bubble = new PIXI.Graphics();
		bubble.beginFill(0xffffff);
		bubble.drawRoundedRect(-467, -378, 740, 114, 8);
		bubble.endFill();
		bubble.pivot.set(bubble.width / 2, bubble.height / 2);

		const bubblesTale = new PIXI.Graphics();
		bubblesTale.beginFill(0xffffff);
		bubblesTale.moveTo(-692, -323);
		bubblesTale.lineTo(-649, -323);
		bubblesTale.lineTo(-596, -247);
		bubblesTale.closePath();
		bubblesTale.endFill();

		const bubblesFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 0, 0, 0, 0);
		bubblesFade.width = 800;
		bubblesFade.height = 164;
		bubblesFade.pivot.set(bubblesFade.width / 2, bubblesFade.height / 2);
		bubblesFade.tint = 0x000000;
		bubblesFade.position.set(-467, -378);

		const bubblesLabel = new MultiColoredTextField(
			{ font: '32px wendyOne' },
			700,
		);
		bubblesLabel.anchor = 0.5;
		bubblesLabel.text = this.localizationStorage.getLocalizedString(localizationKeys.subtitle1);
		bubblesLabel.position.set(-467, -378);

		this.addChild(
			this.optionalBg,
			this.frame,
			titleTextShadow,
			this.titleText as PIXI.DisplayObject,
			this.timerLabel as PIXI.DisplayObject,
			specialLabel,
			buyButton,
			rewardsContainer,
			bubblesFade,
			bubblesTale,
			bubble,
			bubblesLabel,
		);

		if (bankTabElementWithOffer.getMostPopularLocaleKey()) {
			const stickerPopular = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopular.anchor.set(0.5);
			stickerPopular.position.set(760, -358);
			stickerPopular.scale.set(1.45);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getMostPopularLocaleKey()),
				{ font: '30px wendyOne', align: 'center', tint: 0xfff78f },
				130,
				80,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = 0.47;
			stickerPopularText.position.set(stickerPopular.x, stickerPopular.y);

			const stickerPopularMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopularMask.anchor.set(0.5);
			stickerPopularMask.scale.set(1.45);
			stickerPopularMask.tint = 0xffffff;
			const stickerPopularHightlight = new SpriteHighlightAnimationView(stickerPopularMask, 500, false);
			stickerPopularHightlight.position.set(stickerPopular.x, stickerPopular.y);

			this.addChild(
				stickerPopular,
				stickerPopularHightlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHightlight.start();
		}

		if (bankTabElementWithOffer.getStickerLocaleKey()) {
			const offerStickerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_sticker'], 26, 0, 26, 0);
			offerStickerBg.width = 300;
			offerStickerBg.setTransform(296, -458, 1.3, 1.3);
			offerStickerBg.pivot.set(offerStickerBg.width / 2, offerStickerBg.height / 2);

			const offerStickerText = new MultiColoredTextField(
				{ font: '34px wendyOne', align: 'center' },
				270,
				40,
			);
			offerStickerText.letterSpacing = 2;
			offerStickerText.text = this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getStickerLocaleKey());
			offerStickerText.anchor = 0.5;
			offerStickerText.position.set(offerStickerBg.x, offerStickerBg.y - 4);

			this.addChild(
				offerStickerBg,
				offerStickerText as PIXI.DisplayObject,
			);
		}
	}

	private getCurrentBodyView(viewParam: string): void {
		switch (viewParam) {
			case BodyViewType.VIEW_TYPE_1: {
				this.frame.texture = AssetsStorage.getAtlas('bankAtlas')['offer_frame_2'];
				this.optionalBg.texture = AssetsStorage.getResource('popup_offer_bg_12').texture;
				this.titleText.tint = 0x48292d;
				break;
			}
			case BodyViewType.VIEW_TYPE_2: {
				this.frame.texture = AssetsStorage.getAtlas('bankAtlas')['offer_frame_1'];
				this.optionalBg.texture = AssetsStorage.getResource('popup_offer_bg_8').texture;
				this.titleText.tint = 0x0e2340;
				break;
			}
			case BodyViewType.VIEW_TYPE_3: {
				this.frame.texture = AssetsStorage.getAtlas('bankAtlas')['offer_frame_4'];
				this.optionalBg.texture = AssetsStorage.getResource('popup_offer_bg_9').texture;
				this.titleText.tint = 0x2a0e53;
				break;
			}
			case BodyViewType.VIEW_TYPE_4: {
				this.frame.texture = AssetsStorage.getAtlas('bankAtlas')['offer_frame_1'];
				this.optionalBg.texture = AssetsStorage.getResource('popup_offer_bg_11').texture;
				this.titleText.tint = 0x0e2340;
				break;
			}
			case BodyViewType.VIEW_TYPE_5: {
				this.frame.texture = AssetsStorage.getAtlas('bankAtlas')['offer_frame_3'];
				this.optionalBg.texture = AssetsStorage.getResource('popup_offer_bg_10').texture;
				this.titleText.tint = 0x4a0b16;
				break;
			}
			default:
				throw Error(`Unsupported viewParametr: ${viewParam}`);
		}
	}

	public setTimeLabelText(text: string): void {
		const timeText = this.localizationStorage.getLocalizedString('#offer_timer_label_with_value')
			.replace('{value}', text.toString());
		this.timerLabel.text = timeText;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		super.destroy(options);
	}
}
