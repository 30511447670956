import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';

export class ButtonWatchVideoView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();


		const buttonWatchBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_gold'], 11, 11, 11, 11);
		buttonWatchBg.width = 433;
		buttonWatchBg.height = 110;
		buttonWatchBg.pivot.set(buttonWatchBg.width / 2, buttonWatchBg.height / 2);

		const stringWatch = this.localizationStorage.getLocalizedString('#label_watch_video');
		const labelWatch = new PIXI.extras.BitmapText(stringWatch, { font: '40px wendyOneShadowBold' });
		labelWatch.anchor = 0.5;

		this.hitArea = new PIXI.Rectangle(
			-buttonWatchBg.width / 2,
			-buttonWatchBg.height / 2 - 50,
			buttonWatchBg.width,
			buttonWatchBg.height + 50,
		);

		this.addChild(
			buttonWatchBg,
			labelWatch as PIXI.DisplayObject,
		);
	}
}
