import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter90014View extends CharacterMainBaseView {
	constructor() {
		super('character90014');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-17, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-80, -15),
			bgPosition: new PIXI.Point(40, -19),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
