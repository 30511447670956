import { TotemPromoteWindowViewSetter } from '@interfaces/ViewSetters';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { TotemPromoteWindowView } from '@views/windows/collection/totems/TotemPromoteWindowView';
import { TotemModel } from '@models/TotemModel';
import { TotemPromoteAction } from '@models/network/actions/TotemPromoteAction';
import { OpenPromoteWindowAction } from '@models/network/actions/OpenPromoteWindowAction';
import { BasePromoteWindowViewController } from './BasePromoteWindowViewController';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { SummonModelsPool } from '@models/SummonModelsPool';

export class TotemPromoteWindowViewController extends BasePromoteWindowViewController implements TotemPromoteWindowViewSetter {
	private readonly totemModels: Map<string, TotemModel>;
	private view: TotemPromoteWindowView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		totemModels: Map<string, TotemModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		summonModelsPool: SummonModelsPool,
		questTargetDuration: number,
	) {
		super(windowViewSystem, windowFactory, prestigeMoneyModel, summonModelsPool, questTargetDuration);

		this.totemModels = totemModels;
	}

	public setTotemPromoteWindowView(view: TotemPromoteWindowView): void {
		this.view = view;
		view.on(TotemPromoteWindowView.EVENT_BUTTON_PROMOTE, this.onButtonPromoteClick, this);
		view.on(TotemPromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlus, this);

		const model = this.totemModels.get(view.getKey());
		if (model.isNew()) {
			model.setOld();

			const action = new OpenPromoteWindowAction(model.getKey(), 'totem');
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	protected createAction(model: TotemModel): BaseAction {
		return new TotemPromoteAction(
			model.getKey(),
			model.getPrevPromoteCostPrestige(),
			model.getPrevPromoteCostCards(),
			model.getLevel(),
			this.prestigeMoneyModel.getValue(),
			model.getCardsCount(),
		);
	}

	protected onBankLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_prestige');
	}

	protected onCollectionLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_cards');
	}
}
