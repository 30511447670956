import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { Emitter } from 'pixi-particles';
import { CardProgressBarParticlesConfigs } from '@views/ui/cards/progressBar/CardProgressBarParticlesConfigs';

export class CardProgressBarView extends PIXI.Container {
	private static readonly PROGRESS_BAR_WIDTH: number = 539;

	private readonly progressBar: PIXI.Sprite;
	private readonly progressBarFull: PIXI.Sprite;
	private readonly progressBarBg: PIXI.mesh.NineSlicePlane;
	private readonly levelBg: PIXI.Sprite;
	private readonly newLevelBg: PIXI.Sprite;
	private readonly newLevelBgArrow: PIXI.Sprite;
	private readonly progressValue: PIXI.extras.BitmapText;
	private readonly progressLevel: PIXI.extras.BitmapText;
	private totalProgress: number;
	private currentProgress: number;

	// promote animation
	private readonly sparkParticles: PIXI.Container;
	private progressBarTween: TWEEN.Tween;
	private particleSpark: Emitter;
	private particleGlow: Emitter;

	private newLevelAnimation: TWEEN.Tween;
	private newLevelBgTween: TWEEN.Tween;

	private animateProgressTween: TWEEN.Tween;

	private ticker: PIXI.ticker.Ticker;
	private tweenGroup: TWEEN.Group;

	constructor() {
		super();

		const progressBarHeight = 79;

		const progressBar = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['blue_card_progress_bar']);
		progressBar.width = 0;
		progressBar.height = progressBarHeight;
		progressBar.position.set(-268, 0);
		progressBar.pivot.set(0, progressBarHeight * 0.5);
		this.progressBar = progressBar;

		const progressBarFull = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['green_card_progress_bar']);
		progressBarFull.width = CardProgressBarView.PROGRESS_BAR_WIDTH - 3;
		progressBarFull.height = progressBarHeight;
		progressBarFull.position.set(-268, 0);
		progressBarFull.pivot.set(0, progressBarHeight * 0.5);
		this.progressBarFull = progressBarFull;

		const progressBarBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_rounded_rect'], 5, 5, 5, 5);
		progressBarBg.width = CardProgressBarView.PROGRESS_BAR_WIDTH;
		progressBarBg.height = progressBarHeight + 8;
		progressBarBg.position.set(-267, -4);
		progressBarBg.pivot.set(0, progressBarHeight * 0.5);
		progressBarBg.tint = 0x00000;
		this.progressBarBg = progressBarBg;

		const progressValue = new PIXI.extras.BitmapText('', { font: { size: 54, name: 'wendyOneShadowBold' }, align: 'center' });
		progressValue.anchor = 0.5;
		this.progressValue = progressValue;

		const progressLevel = new SizeableBitmapText('', 70, { font: { size: 72, name: 'wendyOneShadowBold' }, align: 'center' });
		progressLevel.x = -233;
		progressLevel.anchor = 0.5;
		this.progressLevel = progressLevel;

		this.levelBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['level_bg']);
		this.levelBg.position.set(-230, 3);

		this.newLevelBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['new_level_icon']);
		this.newLevelBg.position.set(-233, 0);
		this.newLevelBg.anchor.set(0.5);
		this.newLevelBg.visible = false;

		this.newLevelBgArrow = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['new_level_icon_arrow']);
		this.newLevelBgArrow.anchor.set(0.5, 1);
		this.newLevelBgArrow.position.set(-233, -10);
		this.newLevelBgArrow.visible = false;
		this.newLevelBgArrow.scale.set(1, 0.8);

		this.sparkParticles = new PIXI.Container();
		this.sparkParticles.position.set(-233, -10);
		this.sparkParticles.visible = false;

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.addChild(
			progressBarBg,
			progressBar,
			progressBarFull,
			this.levelBg as PIXI.DisplayObject,
			this.sparkParticles,
			this.newLevelBg,
			this.newLevelBgArrow,
			progressValue,
			progressLevel,
		);
	}

	public setLevel(level: number): void {
		this.progressLevel.text = level.toString();
	}

	public setProgressAnimated(from: number, to: number, total: number): void {
		this.currentProgress = to;
		this.totalProgress = total;

		const widthFrom = Math.min(CardProgressBarView.PROGRESS_BAR_WIDTH * (from / total), CardProgressBarView.PROGRESS_BAR_WIDTH);
		const widthTo = Math.min(CardProgressBarView.PROGRESS_BAR_WIDTH * (to / total), CardProgressBarView.PROGRESS_BAR_WIDTH);

		this.progressBar.width = widthFrom;

		const tweenTargets = {
			percent: 0,
			width: this.progressBar.width,
		};
		this.animateProgressTween = new TWEEN.Tween(tweenTargets, this.tweenGroup)
			.to({ width: widthTo, percent: 1 }, 300)
			.onUpdate(() => {
				this.progressBar.width = tweenTargets.width;

				const currentProgressAnimated = Math.floor((from + (to - from) * tweenTargets.percent));
				if (currentProgressAnimated >= total) {
					this.progressBarFull.visible = true;
					this.newLevelBg.visible = true;
					this.newLevelBgArrow.visible = true;
					this.progressBar.visible = false;
					this.levelBg.visible = false;
				} else {
					this.progressBarFull.visible = false;
					this.newLevelBg.visible = false;
					this.newLevelBgArrow.visible = false;
					this.progressBar.visible = true;
					this.levelBg.visible = true;
				}

				this.setProgressText(currentProgressAnimated, total);
			})
			.onComplete(() => { this.animateProgressTween = null; })
			.start();
	}

	public setProgress(current: number, total: number): void {
		this.currentProgress = current;
		this.totalProgress = total;

		if (current >= total) {
			this.progressBarFull.visible = true;
			this.newLevelBg.visible = true;
			this.newLevelBgArrow.visible = true;
			this.progressBar.visible = false;
			this.levelBg.visible = false;

			if (!this.newLevelAnimation) {
				this.newLevelAnimation = new TWEEN.Tween(this.newLevelBgArrow.scale, this.tweenGroup)
					.to({ y: 1.2 }, 900)
					.easing(TWEEN.Easing.Cubic.Out)
					.yoyo(true)
					.repeat(Infinity)
					.start();
			}
		} else {
			this.cleanNewLevelAnimation();
			this.progressBarFull.visible = false;
			this.newLevelBg.visible = false;
			this.newLevelBgArrow.visible = false;
			this.progressBar.visible = true;
			this.levelBg.visible = true;

			const width = CardProgressBarView.PROGRESS_BAR_WIDTH * (current / total);
			this.progressBar.width = Math.min(width, CardProgressBarView.PROGRESS_BAR_WIDTH);
		}

		this.setProgressText(current, total);
	}

	private setProgressText(current: number, total: number): void {
		this.progressValue.text = `${current}/${total}`;
	}

	public startPromoteAnimationNextPromoteAvailable(
		cardCount: number,
		cardsCost: number,
		level: number,
	): void {
		const glowSprite = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['bar_glow_fx']);
		glowSprite.position.set(
			this.progressBarFull.x + this.progressBarFull.width - 70,
			this.progressBarFull.y,
		);
		new TWEEN.Tween(glowSprite.position, this.tweenGroup)
			.to({ x: this.progressBarFull.x + 40 }, 150)
			.start();
		new TWEEN.Tween(glowSprite, this.tweenGroup)
			.to({ alpha: 0 }, 150)
			.start();

		this.addChild(glowSprite);

		this.newLevelBgTween = new TWEEN.Tween({ scale: 1 }, this.tweenGroup)
			.to({ scale: 0 }, 150)
			.onUpdate((tweenTarget) => {
				this.newLevelBg.scale.x = tweenTarget.scale;
				this.newLevelBgArrow.scale.x = tweenTarget.scale;
				this.progressLevel.scale.x = tweenTarget.scale;
			})
			.onComplete(() => {
				this.setLevel(level);
			});

		const levelBgTween = new TWEEN.Tween({ scale: 0 }, this.tweenGroup)
			.to({ scale: -1 }, 150)
			.onUpdate((tweenTarget) => {
				this.newLevelBg.scale.x = tweenTarget.scale;
				this.newLevelBgArrow.scale.x = tweenTarget.scale;
				this.progressLevel.scale.x = Math.abs(tweenTarget.scale);
			})
			.onComplete(() => {
				this.showSparkParticles().then(() => {
					this.clearPromoteAnimation();
					this.setProgress(cardCount, cardsCost);
				});
			});

		this.newLevelBgTween
			.chain(levelBgTween)
			.start();
	}

	// promote animation
	public startPromoteAnimation(
		cardCount: number,
		prevCardsCost: number,
		cardsCost: number,
		level: number,
	): void {
		const tweenTargets = {
			percent: 0,
			width: CardProgressBarView.PROGRESS_BAR_WIDTH,
		};
		this.progressBarTween = new TWEEN.Tween(tweenTargets, this.tweenGroup)
			.to({ width: 0, percent: 1 }, 120)
			.onUpdate(() => {
				this.progressBarFull.width = tweenTargets.width;

				const currentProgressAnimated = Math.floor((cardCount + prevCardsCost) - prevCardsCost * tweenTargets.percent);
				this.setProgressText(currentProgressAnimated, cardsCost);
			})
			.onComplete(() => {
				this.progressBarFull.visible = false;
				this.progressBarFull.width = CardProgressBarView.PROGRESS_BAR_WIDTH;
			})
			.start();

		this.newLevelBgTween = new TWEEN.Tween({ scale: 1 }, this.tweenGroup)
			.to({ scale: 0 }, 150)
			.onUpdate((tweenTarget) => {
				this.newLevelBg.scale.x = tweenTarget.scale;
				this.newLevelBgArrow.scale.x = tweenTarget.scale;
				this.progressLevel.scale.x = tweenTarget.scale;
			})
			.onComplete(() => {
				this.newLevelBg.visible = false;
				this.newLevelBg.scale.set(1);
				this.newLevelBgArrow.visible = false;
				this.newLevelBgArrow.scale.set(1, 0.8);

				this.levelBg.visible = true;
				this.setLevel(level);
			});

		const levelBgTween = new TWEEN.Tween({ scale: 0 }, this.tweenGroup)
			.to({ scale: -1 }, 150)
			.onUpdate((tweenTarget) => {
				this.levelBg.scale.x = tweenTarget.scale;
				this.levelBg.scale.x = tweenTarget.scale;
				this.progressLevel.scale.x = Math.abs(tweenTarget.scale);
			})
			.onComplete(() => {
				this.showSparkParticles().then(() => {
					this.clearPromoteAnimation();
					this.setProgressAnimated(0, cardCount, cardsCost);
				});
			});

		this.newLevelBgTween
			.chain(levelBgTween)
			.start();
	}

	private showSparkParticles(): Promise<void> {
		return new Promise((resolve) => {
			this.sparkParticles.visible = true;

			this.particleSpark = this.createParticle(CardProgressBarParticlesConfigs.SPARK, 'promote_glow3');
			this.particleSpark.playOnceAndDestroy(() => {
				this.sparkParticles.visible = false;

				resolve();
			});

			this.particleGlow = this.createParticle(CardProgressBarParticlesConfigs.GLOW, 'promote_glow4');
			this.particleGlow.playOnceAndDestroy();
		});
	}

	private createParticle(config: any, textureKey: string): Emitter {
		const emitter = new Emitter(
			this.sparkParticles,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')[textureKey]).texture,
			],
			config,
		);

		return emitter;
	}

	private clearPromoteAnimation(): void {
		if (this.progressBarTween) {
			this.progressBarTween.stop();
			this.progressBarTween = null;
		}

		if (this.newLevelBgTween) {
			this.newLevelBgTween.stop();
			this.newLevelBgTween = null;
		}

		if (this.particleSpark) {
			this.particleSpark.destroy();
			this.particleSpark = null;
		}

		if (this.particleGlow) {
			this.particleGlow.destroy();
			this.particleGlow = null;
		}
	}

	private cleanNewLevelAnimation(): void {
		if (this.newLevelAnimation) {
			this.newLevelAnimation.stop();
			this.newLevelAnimation = null;
		}
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.animateProgressTween) {
			this.animateProgressTween.stop();
			this.animateProgressTween = null;
		}
		this.cleanNewLevelAnimation();
		this.clearPromoteAnimation();

		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweenGroup, this);

		super.destroy(options);
	}
}
