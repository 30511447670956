import { TutorialStepBaseConfig } from './TutorialStepBaseConfig';
import { TutorialStepUnlockTypeLevelStartConfig, TutorialStepUnlockTypeBaseConfig } from './TutorialStepUnlockTypeConfigs';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepLevelConfig extends TutorialStepBaseConfig {
	public static FIELD_LEVEL: string = 'level';

	public static UNLOCK_TYPE_LEVEL_START = 'levelStart';
	public static UNLOCK_TYPE_POPUP_APPEAR_PREPARTY_WINDOW = 'goToPartyWindow';
	public static UNLOCK_TYPE_BUTTON_APPEAR = 'buttonAppear';
	public static UNLOCK_TYPE_BUTTON_APPEAR_GO_TO_NEXT_LEVEL = 'buttonAppearGoToNextLevel';
	public static readonly UNLOCK_TYPE_BUTTON_PREPARTY_START_CHARACTER = 'buttonAppearPrepartyStartCharacter';

	private readonly level: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.level = Number(config[TutorialStepLevelConfig.FIELD_LEVEL].trim());

		this.parseTutorialStepTargetType(config);

		this.parseTutorialStepUnlockTypes(config, this.key);
	}

	protected parseTutorialStepUnlockTypes(config: { [key: string]: string }, key: string): void {
		const unlockType = config[TutorialStepBaseConfig.FIELD_UNLOCK_TYPE].trim();
		const unlockValue = config[TutorialStepBaseConfig.FIELD_UNLOCK_VALUE].trim();

		// eslint-disable-next-line default-case
		switch (unlockType) {
			case TutorialStepLevelConfig.UNLOCK_TYPE_LEVEL_START: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeLevelStartConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR: {
				switch (unlockValue) {
					case TutorialStepLevelConfig.UNLOCK_TYPE_POPUP_APPEAR_PREPARTY_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_PREPARTY);
						break;
					}
					default: {
						super.parseTutorialStepUnlockTypes(config, key);
						break;
					}
				}
				break;
			}

			case TutorialStepLevelConfig.UNLOCK_TYPE_BUTTON_APPEAR: {
				switch (unlockValue) {
					case TutorialStepLevelConfig.UNLOCK_TYPE_BUTTON_APPEAR_GO_TO_NEXT_LEVEL: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.BUTTON_APPEAR_GO_TO_NEXT_LEVEL);
						break;
					}
					case TutorialStepLevelConfig.UNLOCK_TYPE_BUTTON_PREPARTY_START_CHARACTER: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.BUTTON_APPEAR_PREPARTY_START_CHARACTER);
						break;
					}
					default:
						throw new Error(`Unsupported buttonAppear unlock value '${unlockType}'`);
				}
				break;
			}

			default: {
				super.parseTutorialStepUnlockTypes(config, key);
				break;
			}
		}
	}

	public getLevel(): number {
		return this.level;
	}
}
