import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70012View extends CharacterMainBaseView {
	constructor() {
		super('character70012');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-200, 31);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-220, -49),
			arrowRotation: Math.PI,
			bgPosition: new PIXI.Point(-226, -22),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
