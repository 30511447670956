import { LevelGameUIViewSetter, LevelViewSetter } from '@interfaces/ViewSetters';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { LevelModel } from '@models/level/LevelModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import {
	ShowLevelStartWindowChainable,
	ShowLevelStartDialogWindowChainable,
	ShowBackToGameWindowChainable,
	ShowTutorialStepLevelStartWindowChainable,
	ShowTutorialStepGetQuestWindowChainable,
	ShowTutorialStepQuestCompleteWindowChainable,
	ShowNotCompletedTransactionsRewardsWindowChainable,
	ShowTimedQuestDialogWindowChainable, ShowBankOfferWindowChainable, ShowRewardsWindowChainable, ShowCompensationRewardWindowChainable,
} from '@src/viewControllers/mainUI/ShowWindowChainables';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { RewardResultWindowRewardStringType, RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { DialogsFactory } from '@src/initializers/DialogsFactory';
import { TimedQuestsEmitter } from '@src/emitters/TimedQuestsEmitter';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { BankOfferWindowOriginTypes } from '@src/types/BankTypes';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { AbstractReward } from '@interfaces/AbstractReward';
import { TutorialStepWindowViewTargetLevelDispatcher } from '../tutorialSteps/TutorialStepWindowViewLevelTargetDispatcher';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';
import { GoToEventLevelWindowEmitter } from '@src/emitters/GoToEventLevelWindowEmitter';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { LevelChangeAction } from '@models/network/actions/LevelChangeAction';
import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { ClaimCompensationRewardAction } from '@models/network/actions/ClaimCompensationRewardAction';
import { CheatModel } from '@models/CheatModel';
import { LevelWindowViewFactory } from '@src/initializers/windows/LevelWindowViewFactory';
import { GameUIBaseViewController } from '@src/viewControllers/mainUI/GameUIBaseViewController';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BoostModel } from '@models/BoostModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { EmittersController } from './EmittersController';
import { GoToEventLevelWindowView } from '@views/windows/GoToEventLevelWindowView';
import { LevelBaseView } from '@views/levels/LevelBaseView';
import { LevelEndWindowView } from '@views/windows/LevelEndWindowView';
import { BusinessModel } from '@models/BusinessModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TimedQuestsLoader } from '@src/loaders/TimedQuestsLoader';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { NewsEmitter } from '@src/emitters/NewsEmitter';
import { QuestsLoader } from '@src/loaders/QuestsLoader';
import { DailyRewardEmitter } from '@src/emitters/DailyRewardEmitter';

export class LevelGameUIViewController
	extends GameUIBaseViewController<LevelGameUIView, LevelWindowViewFactory> implements LevelGameUIViewSetter, LevelViewSetter {
	public static readonly EVENT_LEVEL_CHANGE: symbol = Symbol();
	public static readonly EVENT_GOTO_EVENT_LEVEL: symbol = Symbol();

	private compensationLootboxModels: LootboxModel[];

	private levelView: LevelBaseView;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly cheatModel: CheatModel,
		private readonly levelModel: LevelModel,
		private readonly levelChallengeModel: LevelChallengeModel,
		private readonly questsLoader: QuestsLoader,
		private readonly questLinesModel: QuestLinesModel,
		private readonly timedQuestsLoader: TimedQuestsLoader,
		private readonly timedQuestLinesModel: TimedQuestLinesModel,
		private readonly businessModels: Map<string, BusinessModel>,
		private readonly dialogsFactory: DialogsFactory,
		private readonly eventLevelModel: EventLevelModel,
		lootboxFactory: LootboxFactory,
		private readonly backToGameRewardModel: BackToGameRewardModel,
		private readonly timedQuestsEmitter: TimedQuestsEmitter,
		private readonly tutorialStepsEmitter: TutorialStepsEmitter,
		private readonly bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		private readonly goToEventLevelWindowEmitter: GoToEventLevelWindowEmitter,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetLevelDispatcher,
		private readonly emittersController: EmittersController,
		private readonly bankLoader: BankLoader,
		private dialogLevelStartShowed: boolean,
		private readonly rewardFactory: RewardFactory,
		compensationLootboxConfigs: LootboxServerConfig[],
		windowViewSystem: WindowViewSystem,
		windowFactory: LevelWindowViewFactory,
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		private readonly newsEmitter: NewsEmitter,
		private readonly dailyRewardEmitter: DailyRewardEmitter,
	) {
		super(
			windowViewSystem,
			windowFactory,
			boostModels,
			farewellBoostModel,
			tutorialStepsViewTargetDispatcher,
		);

		if (compensationLootboxConfigs.length > 0) {
			this.compensationLootboxModels = compensationLootboxConfigs.map(config => lootboxFactory.createLootbox(config));
		} else {
			this.compensationLootboxModels = [];
		}

		this.questLinesModel.on(QuestLinesModel.EVENT_QUEST_COMPLETED, this.updateLevelProgress, this);

		this.eventLevelModel.on(EventLevelModel.EVENT_REQUEST_REWARD_COLLECT, this.onEventRewardCollected, this);

		this.backToGameRewardModel.on(BackToGameRewardModel.EVENT_UPDATED, this.onBackToGameRewardsUpdated, this);

		this.tutorialStepsEmitter.on(TutorialStepsEmitter.EVENT_BASE_STEP_UNLOCKED, this.onBaseTutorialStepEmitted, this);
		this.timedQuestsEmitter.on(TimedQuestsEmitter.EVENT_TIMED_QUEST, this.onTimedQuestEmitted, this);
		this.bankOfferWindowsEmitter.on(BankOfferWindowsEmitter.EVENT_BANK_OFFER_WINDOW, this.onBankOfferWindowEmitted, this);
		this.goToEventLevelWindowEmitter.on(GoToEventLevelWindowEmitter.EVENT_GOTO_EVENT_LEVEL_WINDOW, this.onGoToEventLevelWindowEmitted, this);
	}

	public setLevelView(view: LevelBaseView): void {
		this.levelView = view;
	}

	public setLevelGameUIView(view: LevelGameUIView): void {
		super.setGameUIView(view);

		this.view.on(LevelGameUIView.EVENT_INIT, this.onInit, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_CLICK, this.onGoToNextLevel, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_LEVEL_CHALLENGE_CLICK, this.onButtonLevelChallengeClick, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_EVENT_LEVEL_GOTO_CLICK, this.onButtonEventLevelGoToClick, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_EVENT_LEVEL_COLLECT_REWARDS_CLICK, this.onButtonEventLevelCollectRewardsClick, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_NEWS_CLICK, this.onButtonNewsClick, this);
		this.view.on(LevelGameUIView.EVENT_BUTTON_DAILY_CLICK, this.onButtonDailyClick, this);

		this.newsEmitter.on(NewsEmitter.EVENT_NEW_PUSHING_NEWS_ITEM, this.tryPushingNewsWindow, this);
		this.dailyRewardEmitter.on(DailyRewardEmitter.EVENT_DAILY_REWARD_AVAILABLE, this.tryPushingDailyRewardWindow, this);
		this.timedQuestLinesModel.on(TimedQuestLinesModel.EVENT_QUEST_CURRENT_ADED, this.onTimedQuestAdded, this);
	}

	private onTimedQuestAdded(): void {
		const quest = this.timedQuestLinesModel.getCurrentQuest();

		if (quest.isProgressComplete()) {
			this.view.updateTimedQuestLine(quest);
		}
	}

	private onButtonLevelChallengeClick(): void {
		const window = this.windowFactory.createLevelChallengeWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonEventLevelGoToClick(): void {
		const window = this.windowFactory.createGoToEventWindow();
		window.once(GoToEventLevelWindowView.EVENT_BUTTON_GO_CLICK, () => {
			this.emit(LevelGameUIViewController.EVENT_GOTO_EVENT_LEVEL);
		}, this);

		this.windowViewSystem.showWindow(window);
	}

	private onButtonEventLevelCollectRewardsClick(): void {
		const window = this.windowFactory.createCollectEventRewardsWindowView();
		this.windowViewSystem.showWindow(window);
	}

	private async onGoToNextLevel(): Promise<void> {
		this.emittersController.setWindowChainOn(true);

		const levelChallengeTargetTimeId = this.levelChallengeModel.getCompletedTargetTimeId();
		const questRewards = this.collectQuestRewards();
		const levelRewardDescriptions = await this.getLevelRewards(levelChallengeTargetTimeId);
		const levelRewards = levelRewardDescriptions.map((x) => this.rewardFactory.createReward(x));
		const rewards = questRewards.concat(levelRewards);

		if (rewards.length > 0) {
			const rewardResultWindow = this.windowFactory.createRewardResultWindow(
				false,
				undefined,
				RewardResultWindowBgType.FADE,
			);

			rewardResultWindow.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
				this.emitChangeLevelUpdates(levelChallengeTargetTimeId || 0, levelRewardDescriptions);
				this.changeLevel();
				this.levelChallengeModel.setRewards(undefined);
			});
			rewardResultWindow.init(rewards);
			this.windowViewSystem.showWindow(rewardResultWindow);
		} else {
			this.emitChangeLevelUpdates(0, []);
			this.changeLevel();
		}
	}

	private emitChangeLevelUpdates(place: number, rewards: RewardDescriptionType[]): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ start_dialog_showed: false }));
		this.emit(BaseAction.EVENT_ACTION_CREATED, new LevelChangeAction(this.levelModel.getCurrentLevel() + 1, place, rewards));
	}

	private changeLevel(): void {
		const levelEndWindow = this.windowFactory.createLevelEndWindow();
		levelEndWindow.once(LevelEndWindowView.EVENT_APPEARING_ANIMATION_COMPLETED, () => {
			const openedBusinesses = [];
			this.businessModels.forEach(b => {
				if (b.isAcquired()) {
					openedBusinesses.push(b);
				}
			});
			const timerDuration = Math.max(1000, openedBusinesses.length * 150);
			this.levelView.startCloseBusinessesAnimation(timerDuration);
			this.levelView.tryStartNextLevelBackgroundAnimation(timerDuration);
		}, this);
		levelEndWindow.once(LevelEndWindowView.EVENT_WINDOW_CLOSED, () => {
			this.emit(LevelGameUIViewController.EVENT_LEVEL_CHANGE);
		}, this);

		this.windowViewSystem.addWindowPending(levelEndWindow);

		const hideUIDuration = 700;
		this.levelView.startMoveToFirstBusinessAnimation(0);
		this.levelView.startHideUIAnimation(hideUIDuration);
		this.view.hideMainUI(hideUIDuration);
		window.setTimeout(() => {
			levelEndWindow.startAppearingAnimation();
		}, hideUIDuration);
	}

	private onTimedQuestEmitted(): void {
		const showTimedQuestDialog = new ShowTimedQuestDialogWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.dialogsFactory,
			this.timedQuestLinesModel,
		);

		showTimedQuestDialog
			.start()
			.then(() => {
				this.timedQuestsLoader.createPendingQuest();
				this.view.updateTimedQuestLine(this.timedQuestLinesModel.getCurrentQuest());
			});
	}

	private onBankOfferWindowEmitted(bankOfferModelKey: string): void {
		const showBankOfferWindow = new ShowBankOfferWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			bankOfferModelKey,
			BankOfferWindowOriginTypes.BANK_OFFER_WINDOWS_EMITTER,
		);
		showBankOfferWindow.start();
	}

	private onGoToEventLevelWindowEmitted(): void {
		const window = this.windowFactory.createGoToEventWindow();
		window.once(GoToEventLevelWindowView.EVENT_BUTTON_GO_CLICK, () => {
			this.emit(LevelGameUIViewController.EVENT_GOTO_EVENT_LEVEL);
		}, this);
		this.windowViewSystem.addWindowPending(window);
	}

	private onBackToGameRewardsUpdated(): void {
		if (this.backToGameRewardModel.hasRewardSoftMoney()) {
			const backToGameWindow = this.windowFactory.createBackToGameWindow();
			this.windowViewSystem.addWindowPending(backToGameWindow);
		}
	}

	private async onInit(): Promise<void> {
		this.dialogLevelStartShowed = this.levelModel.isInitialLevel()
			? this.dialogLevelStartShowed
			: false;

		const notCompletedTransactionsData = await this.bankLoader.checkNotCompletedTransactions();
		const showNotCompletedTransactionsRewardsWindow = new ShowNotCompletedTransactionsRewardsWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			notCompletedTransactionsData,
		);

		const showLevelStartWindow = new ShowLevelStartWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.dialogLevelStartShowed,
			this.levelModel.getCurrentLevel(),
			() => {
				this.levelView.startHideUIAnimation(0);
				this.view.hideMainUI(0);
			},
		);

		const showLevelStartDialogWindow = new ShowLevelStartDialogWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.dialogLevelStartShowed,
			this.cheatModel.isEmitterDialogWindowsEnabled(),
			this.dialogsFactory,
			() => {
				this.dialogLevelStartShowed = true;
				// eslint-disable-next-line @typescript-eslint/naming-convention
				const action = new UpdateUserDataAction({ start_dialog_showed: true });
				this.emit(BaseAction.EVENT_ACTION_CREATED, action);
			},
		);

		const showBackToGameWindow = new ShowBackToGameWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.backToGameRewardModel.hasRewardSoftMoney(),
		);

		const showTutorialStepLevelStartWindow = new ShowTutorialStepLevelStartWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.tutorialStepsEmitter,
			this.tutorialStepsViewTargetDispatcher,
		);
		const showTutorialStepGetQuestWindow = new ShowTutorialStepGetQuestWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.tutorialStepsEmitter,
			this.tutorialStepsViewTargetDispatcher,
		);
		const showTutorialStepQuestCompleteWindow = new ShowTutorialStepQuestCompleteWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.tutorialStepsEmitter,
			this.tutorialStepsViewTargetDispatcher,
		);

		const showCompensationLootboxWindow = new ShowCompensationRewardWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.compensationLootboxModels,
			RewardResultWindowRewardStringType.REWARD_COMPENSATION,
		);

		this.emittersController.setWindowChainOn(true);
		showLevelStartWindow
			.start()
			.then(() => showNotCompletedTransactionsRewardsWindow.start())
			.then(() => showLevelStartDialogWindow.start())
			.then(() => {
				this.levelView.startShowUIAnimation(250);
				this.view.showMainUI(250);
			})
			.then(() => showBackToGameWindow.start())
			.then(() => {
				if (this.compensationLootboxModels.length > 0) {
					const action = new ClaimCompensationRewardAction(
						this.compensationLootboxModels.map(model => model.getKey()),
					);
					this.emit(BaseAction.EVENT_ACTION_CREATED, action);
				}
				return showCompensationLootboxWindow.start();
			})
			.then(() => {
				if (this.compensationLootboxModels.length > 0) {
					this.compensationLootboxModels = [];
				}
			})
			.then(() => showTutorialStepLevelStartWindow.start())
			.then(() => showTutorialStepGetQuestWindow.start())
			.then(() => showTutorialStepQuestCompleteWindow.start())
			.then(() => {
				this.emittersController.setWindowChainOn(false, true);
			});
	}

	private onEventRewardCollected(rewards: AbstractReward[]): void {
		const showRewardsWindow = new ShowRewardsWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			rewards,
		);
		showRewardsWindow.start();
	}

	private updateLevelProgress(): void {
		this.view.setLevelProgress(
			this.levelModel.getCurrentProgress(),
			this.questLinesModel.getPendingProgress(),
			true,
		);
	}

	private onButtonNewsClick(): void {
		const window = this.windowFactory.createNewsWindow();
		this.windowViewSystem.showWindow(window);
		window.setActiveTab();
	}

	private onButtonDailyClick(): void {
		const window = this.windowFactory.createDailyWindow();
		this.windowViewSystem.showWindow(window);
	}

	private tryPushingNewsWindow(newsKey: string): void {
		if (!this.windowViewSystem.isNewsWindowShown()) {
			const window = this.windowFactory.createNewsWindow();

			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_NEWS_SHOWED, () => {
				window.setActiveTab(newsKey);
			});

			this.windowViewSystem.addWindowPending(window);
		}
	}

	private tryPushingDailyRewardWindow(): void {
		const window = this.windowFactory.createDailyWindow();
		this.windowViewSystem.addWindowPending(window);
	}

	private async getLevelRewards(levelChallengeTargetTimeId?: number): Promise<RewardDescriptionType[]> {
		let rewards: Promise<RewardDescriptionType[]> = Promise.resolve([]);

		if (levelChallengeTargetTimeId != null) {
			const allRewards = this.levelChallengeModel.getRewards();

			if (allRewards != null) {
				rewards = Promise.resolve(allRewards[levelChallengeTargetTimeId]);
			} else {
				rewards = new Promise((resolve) => {
					const onRewardsChanged = (data: RewardDescriptionType[][]): void => {
						resolve(data[levelChallengeTargetTimeId]);
						this.levelChallengeModel.off(LevelChallengeModel.EVENT_REWARDS_CHANGED, onRewardsChanged);
					};
					this.levelChallengeModel.on(LevelChallengeModel.EVENT_REWARDS_CHANGED, onRewardsChanged);
				});
			}
		}

		return rewards;
	}

	private collectQuestRewards(): AbstractReward[] {
		const rewards = this.questLinesModel.getCurrentQuests()
			.filter((quest) => quest.isCompleted())
			.flatMap((quest) => {
				this.questsLoader.finishQuest(quest);
				return quest.getLootbox().getRewards();
			});

		const timedQuest = this.timedQuestLinesModel.getCurrentQuest();

		if (timedQuest != null && timedQuest.isCompleted()) {
			this.timedQuestsLoader.finishQuest(timedQuest);

			const timedQuestRewards = timedQuest.getLootbox().getRewards();
			rewards.push(...timedQuestRewards);
		}

		return rewards;
	}
}
