export enum AnalyticsTypes {
	LEVEL_CHALLENGE_COMPLETE = 'levelChallengeComplete',

	QUEST_COMPLETE = 'questComplete',

	TIMED_QUEST_COMPLETE = 'timedQuestComplete',
	TIMED_QUEST_TIMEOUT = 'timedQuestTimeout',

	PROMOTE_UPGRADE = 'upgradePromote',
	PROMOTE_TOTEM = 'totemPromote',
	PROMOTE_CHARACTER = 'characterPromote',

	RECEIVE_UPGRADE = 'upgradeReceive',
	RECEIVE_TOTEM = 'totemReceive',
	RECEIVE_CHARACTER = 'characterReceive',
	RECEIVE_BOOST = 'boostReceive',
	RECEIVE_TIMESKIP = 'timeskipReceive',
	RECEIVE_PRESTIGE_MONEY = 'prestigeMoneyReceive',
	RECEIVE_HARD_MONEY = 'hardMoneyReceive',

	SPEND_HARD_MONEY = 'hardMoneySpend',
	SPEND_PRESTIGE_MONEY = 'prestigeMoneySpend',

	ACTIVATE_BOOST = 'boostActivate',
	ACTIVATE_TIMESKIP = 'timeskipActivate',

	TUTORIAL_STEP_COMPLETE = 'tutorialStepComplete',

	NEW_USER = 'newUser',
	REGISTRATION = 'registration',
	BUTTON_LOGIN_CLICK = 'buttonLoginClick',

	LEVEL_START = 'levelStart',
	LOAD_PROGRESS = 'loadProgress',

	DIALOG_TAP = 'dialogTap',

	BANK_BUY_OFFER_FROM_OFFER_WINDOW = 'bankBuyOfferFromOfferWindow',
	BANK_BUY_OFFER_FROM_OFFER_WINDOW_AFTER_LINES_UI_CLICK = 'bankBuyOfferFromOfferWindowAfterLinesUIClick',
	BANK_BUY_OFFER_FROM_BANK_WINDOW = 'bankBuyOfferFromBankWindow',
	BANK_BUY_OFFER_FROM_BANK_WINDOW_AFTER_OFFER_WINDOW = 'bankBuyOfferFromBankWindowAfterOfferWindow',
	BANK_BUY_OFFER_FROM_BANK_WINDOW_AFTER_OFFER_LINES_UI_CLICK = 'bankBuyOfferFromBankWindowAfterOfferLinesUIClick',
	BANK_SHOW_OFFER_WINDOW = 'bankShowOfferWindow',
	BANK_OFFER_LINES_UI_CLICK = 'bankOfferLinesUIClick',
	BANK_GO_TO_BANK_WINDOW_FROM_BANK_OFFER_WINDOW = 'bankGoToBankWindowFromBankOfferWindow',
	BANK_GO_TO_BANK_WINDOW_AFTER_OFFER_LINES_UI_CLICK = 'bankGoToBankWindowAfterOfferLinesUIClick',
}

export enum BankOfferPurchaseSourceTypes {
	OFFER_WINDOW = 'offerWindow',
	BANK_WINDOW = 'bankWindow',
}

export enum PixelEventTypes {
	LOAD_GAME = 'load_game',
	SIGN_UP = 'signup',
	TUTORIAL_END = 'tutor',
	PAY = 'pay',
}

export enum AnalyticsType {
	PIXEL,
	OTHER,
}

export type TrackingData = {
	utmSource: string
	analytics: AnalyticsType,
	pixelId?: string;
	clickId?: string;
	affSub?: string;
	ts?: string;
};

export enum AnalyticsPlatform {
	SITE = 'site',
	NUTAKU = 'nutaku',
}

export enum AnalyticsTargetServerLogKeys {
	TARGET_TRAFFIC_STARS_KEY = 'traffic_stars',
	TARGET_CLICKADU_KEY = 'clickadu',
	TARGET_PROPELLER_ADS_KEY = 'propeller_ads',
}
