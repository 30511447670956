export enum LevelConfigNames {
	CONFIG_CONSTANTS = 'Constants',
	CONFIG_BUSINESSES = 'Businesses',
	CONFIG_CHARACTERS = 'Characters',
	CONFIG_LOOTBOXES = 'Rewards',
	CONFIG_SEASONS = 'Seasons',
	CONFIG_SKILLS = 'Skills',
	CONFIG_UPGRADES = 'Upgrades',
	CONFIG_QUESTS = 'Quests',
	CONFIG_DIALOGS = 'Dialogs_New',
	CONFIG_FAREWELL_PARTY = 'FarewellParty',
	CONFIG_TOTEMS = 'Totems',
	CONFIG_VIDEO = 'Video',
	CONFIG_EPIC_QUESTS_COLLECTIONS = 'Epic_quests_collections',
	CONFIG_TUTORIAL_STEPS = 'TutorSteps',
	CONFIG_BANK_PRICE_ITEMS = 'PriceItems',
	CONFIG_BOOSTS = 'Boosts',
	CONFIG_TIMESKIPS = 'Timeskips',
	CONFIG_PROMOTE_PATTERNS = 'PromotePatterns',
	CONFIG_MAIN_WINDOW = 'MainWindow',
	CONFIG_LOCALIZATON_SUPPORT = 'Localization_support',
	CONFIG_BANK_BUNDLES = 'Bundles',
	CONFIG_BANK_BUNDLE_GROUPS = 'BundleGroups',
	CONFIG_BANK_GEM_SHOP = 'GemShop',
	CONFIG_BANK_OFFERS = 'Offers',
	CONFIG_BANK_RECHARGE = 'Recharge',
	CONFIG_BANK_SUBSCRIBE = 'Subscribe',
	CONFIG_BANK_TABS = 'BankTabs',
	CONFIG_SUMMON = 'Summon',
	CONFIG_NEWS = 'News',
	CONFIG_DAILY_REWARDS = 'DailyBonus',
	CONFIG_PRESETS = 'Presets',
}
