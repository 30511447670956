import { LevelBaseView } from '@views/levels/LevelBaseView';
import {
	BusinessAcquiredUIViewSetter,
	BusinessBaseViewsSetter,
	BusinessOpenToBuyUIViewSetter,
	BusinessManageUIViewSetter,
	SkillBaseViewSetter,
	QuestWindowBaseViewSetter,
	CharacterProfileWindowViewSetter,
	UpgradePromoteWindowViewSetter,
	SummonCollectionViewSetter,
	SummonResultWindowViewSetter,
	BankWindowViewSetter,
	TotemPromoteWindowViewSetter,
	TutorialStepWindowViewSetter,
	BusinessBaseViewSetter,
	ButtonMaxCustomersViewSetter,
	SettingsWindowViewSetter,
	RewardResultWindowViewSetter,
	SupportWindowViewSetter,
	EpicQuestCollectionsProgressViewSetter,
	RegistrationWindowViewSetter,
	DialogWindowViewSetter,
	ViewportViewSetter,
	ViewportUIViewSetter,
	BankOfferWindowViewSetter,
	BankOfferLinesUIViewSetter,
	BackToGameWindowViewSetter,
	PrepartyStartCharacterViewSetter,
	FarewellPartyWindowViewSetter,
	CheatWindowViewSetter,
	CharacterCollectionViewSetter,
	GameUIBaseViewSetter,
	LevelViewSetter,
	PrepartyWindowViewSetter,
	TotemCollectionViewSetter,
	UpgradeCollectionViewSetter,
	NewsWindowViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { BusinessOpenToBuyUIView } from '@views/businesses/ui/openToBuy/BusinessOpenToBuyUIView';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { CharacterProfileWindow } from '@views/windows/collection/characters/CharacterProfileWindow';
import { UpgradePromoteWindowView } from '@views/windows/collection/upgrades/UpgradePromoteWindowView';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { TotemPromoteWindowView } from '@views/windows/collection/totems/TotemPromoteWindowView';
import { TutorialStepView } from '@views/windows/TutorialStepWindowView';
import { RewardResultWindowView } from '@views/windows/rewardResultWindow/RewardResultWindowView';
import { SupportWindowView } from '@views/windows/support/SupportWindowView';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { EpicQuestCollectionsProgressView } from '@views/windows/collection/EpicQuestCollectionsProgressView';
import { DialogWindowView } from '@views/windows/dialog/DialogWindowView';
import { ViewportView } from '@views/components/ViewportView';
import { ViewportUIView } from '@views/components/ViewportUIView';
import { QuestWindowBaseView } from '@views/windows/quests/QuestWindowBaseView';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BackToGameWindowView } from '@views/windows/backToGame/BackToGameWindowView';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { CharacterCollectionView } from '@views/windows/collection/characters/CharacterCollectionView';
import { TotemCollectionView } from '@views/windows/collection/totems/TotemCollectionView';
import { UpgradeCollectionDefaultView } from '@views/windows/collection/upgrades/UpgradeCollectionDefaultView';
import { UpgradeCollectionGroupByBusinessesView } from '@views/windows/collection/upgrades/UpgradeCollectionGroupByBusinessesView';
import { PrepartyWindowView } from '@views/windows/farewellParty/preparty/PrepartyWindowView';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { BaseSettingsWindowView } from '@views/windows/settings/BaseSettingsWindowView';
import { BaseNewsWindowView } from '@views/windows/newsWindow/BaseNewsWindowView';
import { FurnitureCollectionView } from '@views/windows/collection/furniture/FurnitureCollectionView';

export type ViewDispatcherTargetType<T extends any> = {
	dispatcherTarget: T;
	dispatcherView: any;
};

export interface ViewDispatcherTargets {
	levelViewSetters?: LevelViewSetter[];
	viewportViewSetters?: ViewportViewSetter[];
	viewportUIViewSetters?: ViewportUIViewSetter[];
	gameUIBaseViewSetters?: GameUIBaseViewSetter[];
	businessBaseViewsSetters?: BusinessBaseViewsSetter[];
	businessAcquiredUIViewSetters?: BusinessAcquiredUIViewSetter[];
	businessManageUIViewSetters?: BusinessManageUIViewSetter[];
	businessOpenToBuyUIViewSetters?: BusinessOpenToBuyUIViewSetter[];
	skillBaseViewSetter?: SkillBaseViewSetter[];
	backToGameWindowViewSetters?: BackToGameWindowViewSetter[];
	questWindowViewSetters?: QuestWindowBaseViewSetter[];
	characterProfileViewSetters?: CharacterProfileWindowViewSetter[];
	upgradePromoteViewSetters?: UpgradePromoteWindowViewSetter[];
	totemPromoteViewSetters?: TotemPromoteWindowViewSetter[];
	summonCollectionViewSetters?: SummonCollectionViewSetter[];
	summonResultViewSetters?: SummonResultWindowViewSetter[];
	bankWindowViewSetters?: BankWindowViewSetter[];
	bankOfferWindowViewSetters?: BankOfferWindowViewSetter[];
	bankOfferLinesUIViewSetters?: BankOfferLinesUIViewSetter[];
	tutorialStepViewSetters?: TutorialStepWindowViewSetter[];
	businessBaseViewSetters?: BusinessBaseViewSetter[];
	buttonMaxCustomersViewSetters?: ButtonMaxCustomersViewSetter[];
	settingsWindowViewSetters?: SettingsWindowViewSetter[];
	rewardResultWindowViewSetters?: RewardResultWindowViewSetter[];
	supportWindowViewSetters?: SupportWindowViewSetter[];
	registrationWindowViewSetters?: RegistrationWindowViewSetter[];
	collectionCardsProgressViewSetters?: EpicQuestCollectionsProgressViewSetter[];
	dialogWindowViewSetters?: DialogWindowViewSetter[];
	prepartyStartCharacterViewSetters?: PrepartyStartCharacterViewSetter[];
	farewellPartyViewSetters?: FarewellPartyWindowViewSetter[];
	characterCollectionViewSetters?: CharacterCollectionViewSetter[];
	totemCollectionViewSetters?: TotemCollectionViewSetter[];
	upgradeCollectionViewSetters?: UpgradeCollectionViewSetter[];
	furnitureCollectionViewSetters?: FurnitureCollectionViewSetter[];
	prepartyWidowViewSetters?: PrepartyWindowViewSetter[];
	cheatWindowViewSetters?: CheatWindowViewSetter[];
	newsWindowViewSetters?: NewsWindowViewSetter[];
}

export class BaseViewDispatcher {
	protected static getUpdatedTargets<T extends any>(
		setters: T[],
		targets?: ViewDispatcherTargetType<T>[],
	): ViewDispatcherTargetType<T>[] {
		const newTargets: ViewDispatcherTargetType<T>[] = setters
			.map(setter => ({ dispatcherView: undefined, dispatcherTarget: setter }));
		if (targets) {
			const newUniqueTargets = newTargets
				.filter(newTarget => targets.findIndex(target => target.dispatcherTarget === newTarget.dispatcherTarget) === -1);
			return [...newUniqueTargets, ...targets];
		}
		return newTargets;
	}

	private viewportViewTargets?: ViewDispatcherTargetType<ViewportViewSetter>[];
	private viewportUIViewTargets?: ViewDispatcherTargetType<ViewportUIViewSetter>[];
	private gameUIBaseViewTargets?: ViewDispatcherTargetType<GameUIBaseViewSetter>[];
	private levelViewTargets?: ViewDispatcherTargetType<LevelViewSetter>[];

	private businessBaseViewsTargets?: ViewDispatcherTargetType<BusinessBaseViewsSetter>[];
	private businessAcquiredUIViewTargets?: ViewDispatcherTargetType<BusinessAcquiredUIViewSetter>[];
	private businessOpenToBuyUIViewTargets?: ViewDispatcherTargetType<BusinessOpenToBuyUIViewSetter>[];
	private businessManageUIViewTargets?: ViewDispatcherTargetType<BusinessManageUIViewSetter>[];
	private skillBaseViewTargets?: ViewDispatcherTargetType<SkillBaseViewSetter>[];
	private summonCollectionViewTargets?: ViewDispatcherTargetType<SummonCollectionViewSetter>[];
	private characterCollectionViewTargets?: ViewDispatcherTargetType<CharacterCollectionViewSetter>[];
	private totemCollectionViewTargets?: ViewDispatcherTargetType<TotemCollectionViewSetter>[];
	private upgradeCollectionViewTargets?: ViewDispatcherTargetType<UpgradeCollectionViewSetter>[];
	private furnitureCollectionViewTargets?: ViewDispatcherTargetType<FurnitureCollectionViewSetter>[];
	private bankOfferLinesUIViewTargets?: ViewDispatcherTargetType<BankOfferLinesUIViewSetter>[];
	private businessBaseViewTargets?: ViewDispatcherTargetType<BusinessBaseViewSetter>[];
	private buttonMaxCustomersViewTargets?: ViewDispatcherTargetType<ButtonMaxCustomersViewSetter>[];
	private collectionCardsProgressViewTargets?: ViewDispatcherTargetType<EpicQuestCollectionsProgressViewSetter>[];

	private backToGameWindowViewTargets?: ViewDispatcherTargetType<BackToGameWindowViewSetter>[];
	private summonResultWindowViewTargets?: ViewDispatcherTargetType<SummonResultWindowViewSetter>[];
	private questWindowBaseViewTargets?: ViewDispatcherTargetType<QuestWindowBaseViewSetter>[];
	private characterProfileWindowViewTargets?: ViewDispatcherTargetType<CharacterProfileWindowViewSetter>[];
	private upgradePromoteWindowViewTargets?: ViewDispatcherTargetType<UpgradePromoteWindowViewSetter>[];
	private totemPromoteWindowViewTargets?: ViewDispatcherTargetType<TotemPromoteWindowViewSetter>[];
	private bankWindowViewTargets?: ViewDispatcherTargetType<BankWindowViewSetter>[];
	private bankOfferWindowViewTargets?: ViewDispatcherTargetType<BankOfferWindowViewSetter>[];
	private tutorialStepWindowViewTargets?: ViewDispatcherTargetType<TutorialStepWindowViewSetter>[];
	private settingsWindowViewTargets?: ViewDispatcherTargetType<SettingsWindowViewSetter>[];
	private rewardResultWindowViewTargets?: ViewDispatcherTargetType<RewardResultWindowViewSetter>[];
	private supportWindowViewTargets?: ViewDispatcherTargetType<SupportWindowViewSetter>[];
	private registrationWindowViewTargets?: ViewDispatcherTargetType<RegistrationWindowViewSetter>[];
	private dialogWindowViewTargets?: ViewDispatcherTargetType<DialogWindowViewSetter>[];
	private prepartyStartCharacterViewTarget?: ViewDispatcherTargetType<PrepartyStartCharacterViewSetter>[];
	private farewellPartyWindowViewTargets?: ViewDispatcherTargetType<FarewellPartyWindowViewSetter>[];
	private prepartyWindowViewTargets?: ViewDispatcherTargetType<PrepartyWindowViewSetter>[];
	private cheatWindowViewTargets?: ViewDispatcherTargetType<CheatWindowViewSetter>[];
	private newsWindowViewTargets?: ViewDispatcherTargetType<NewsWindowViewSetter>[];

	protected levelView: LevelBaseView;
	protected gameUIView: GameUIBaseView;
	protected windowViewSystem: WindowViewSystem;
	protected viewportView: ViewportView;
	protected viewportUIView: ViewportUIView;

	public updateTargets(args: ViewDispatcherTargets): void {
		if (args.levelViewSetters) {
			this.levelViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.levelViewSetters,
				this.levelViewTargets,
			);
		}
		if (args.viewportViewSetters) {
			this.viewportViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.viewportViewSetters,
				this.viewportViewTargets,
			);
		}
		if (args.viewportUIViewSetters) {
			this.viewportUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.viewportUIViewSetters,
				this.viewportUIViewTargets,
			);
		}
		if (args.businessBaseViewsSetters) {
			this.businessBaseViewsTargets = BaseViewDispatcher.getUpdatedTargets(
				args.businessBaseViewsSetters,
				this.businessBaseViewsTargets,
			);
		}
		if (args.gameUIBaseViewSetters) {
			this.gameUIBaseViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.gameUIBaseViewSetters,
				this.gameUIBaseViewTargets,
			);
		}
		if (args.businessAcquiredUIViewSetters) {
			this.businessAcquiredUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.businessAcquiredUIViewSetters,
				this.businessAcquiredUIViewTargets,
			);
		}
		if (args.businessManageUIViewSetters) {
			this.businessManageUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.businessManageUIViewSetters,
				this.businessManageUIViewTargets,
			);
		}
		if (args.businessOpenToBuyUIViewSetters) {
			this.businessOpenToBuyUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.businessOpenToBuyUIViewSetters,
				this.businessOpenToBuyUIViewTargets,
			);
		}
		if (args.skillBaseViewSetter) {
			this.skillBaseViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.skillBaseViewSetter,
				this.skillBaseViewTargets,
			);
		}
		if (args.backToGameWindowViewSetters) {
			this.backToGameWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.backToGameWindowViewSetters,
				this.backToGameWindowViewTargets,
			);
		}
		if (args.questWindowViewSetters) {
			this.questWindowBaseViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.questWindowViewSetters,
				this.questWindowBaseViewTargets,
			);
		}
		if (args.characterProfileViewSetters) {
			this.characterProfileWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.characterProfileViewSetters,
				this.characterProfileWindowViewTargets,
			);
		}
		if (args.upgradePromoteViewSetters) {
			this.upgradePromoteWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.upgradePromoteViewSetters,
				this.upgradePromoteWindowViewTargets,
			);
		}
		if (args.totemPromoteViewSetters) {
			this.totemPromoteWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.totemPromoteViewSetters,
				this.totemPromoteWindowViewTargets,
			);
		}
		if (args.summonCollectionViewSetters) {
			this.summonCollectionViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.summonCollectionViewSetters,
				this.summonCollectionViewTargets,
			);
		}
		if (args.characterCollectionViewSetters) {
			this.characterCollectionViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.characterCollectionViewSetters,
				this.characterCollectionViewTargets,
			);
		}
		if (args.totemCollectionViewSetters) {
			this.totemCollectionViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.totemCollectionViewSetters,
				this.totemCollectionViewTargets,
			);
		}
		if (args.upgradeCollectionViewSetters) {
			this.upgradeCollectionViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.upgradeCollectionViewSetters,
				this.upgradeCollectionViewTargets,
			);
		}
		if (args.furnitureCollectionViewSetters) {
			this.furnitureCollectionViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.furnitureCollectionViewSetters,
				this.furnitureCollectionViewTargets,
			);
		}
		if (args.summonResultViewSetters) {
			this.summonResultWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.summonResultViewSetters,
				this.summonResultWindowViewTargets,
			);
		}
		if (args.bankWindowViewSetters) {
			this.bankWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.bankWindowViewSetters,
				this.bankWindowViewTargets,
			);
		}
		if (args.bankOfferWindowViewSetters) {
			this.bankOfferWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.bankOfferWindowViewSetters,
				this.bankOfferWindowViewTargets,
			);
		}
		if (args.bankOfferLinesUIViewSetters) {
			this.bankOfferLinesUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.bankOfferLinesUIViewSetters,
				this.bankOfferLinesUIViewTargets,
			);
		}
		if (args.tutorialStepViewSetters) {
			this.tutorialStepWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.tutorialStepViewSetters,
				this.tutorialStepWindowViewTargets,
			);
		}
		if (args.businessBaseViewSetters) {
			this.businessBaseViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.businessBaseViewSetters,
				this.businessBaseViewTargets,
			);
		}
		if (args.buttonMaxCustomersViewSetters) {
			this.buttonMaxCustomersViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.buttonMaxCustomersViewSetters,
				this.buttonMaxCustomersViewTargets,
			);
		}
		if (args.settingsWindowViewSetters) {
			this.settingsWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.settingsWindowViewSetters,
				this.settingsWindowViewTargets,
			);
		}
		if (args.rewardResultWindowViewSetters) {
			this.rewardResultWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.rewardResultWindowViewSetters,
				this.rewardResultWindowViewTargets,
			);
		}
		if (args.supportWindowViewSetters) {
			this.supportWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.supportWindowViewSetters,
				this.supportWindowViewTargets,
			);
		}
		if (args.registrationWindowViewSetters) {
			this.registrationWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.registrationWindowViewSetters,
				this.registrationWindowViewTargets,
			);
		}
		if (args.collectionCardsProgressViewSetters) {
			this.collectionCardsProgressViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.collectionCardsProgressViewSetters,
				this.collectionCardsProgressViewTargets,
			);
		}
		if (args.dialogWindowViewSetters) {
			this.dialogWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.dialogWindowViewSetters,
				this.dialogWindowViewTargets,
			);
		}
		if (args.prepartyStartCharacterViewSetters) {
			this.prepartyStartCharacterViewTarget = BaseViewDispatcher.getUpdatedTargets(
				args.prepartyStartCharacterViewSetters,
				this.prepartyStartCharacterViewTarget,
			);
		}
		if (args.farewellPartyViewSetters) {
			this.farewellPartyWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.farewellPartyViewSetters,
				this.farewellPartyWindowViewTargets,
			);
		}
		if (args.prepartyWidowViewSetters) {
			this.prepartyWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.prepartyWidowViewSetters,
				this.prepartyWindowViewTargets,
			);
		}
		if (args.cheatWindowViewSetters) {
			this.cheatWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.cheatWindowViewSetters,
				this.cheatWindowViewTargets,
			);
		}

		if (args.newsWindowViewSetters) {
			this.newsWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.newsWindowViewSetters,
				this.newsWindowViewTargets,
			);
		}
	}

	public setViewportView(viewport: ViewportView): BaseViewDispatcher {
		this.viewportView = viewport;
		return this;
	}

	public setViewportUIView(viewportUI: ViewportUIView): BaseViewDispatcher {
		this.viewportUIView = viewportUI;
		return this;
	}

	public setLevelView(levelView: LevelBaseView): BaseViewDispatcher {
		this.levelView = levelView;
		return this;
	}

	public setGameUIView(gameUI: GameUIBaseView): BaseViewDispatcher {
		this.gameUIView = gameUI;
		return this;
	}

	public setWindowViewSystem(windowViewSystem: WindowViewSystem): BaseViewDispatcher {
		this.windowViewSystem = windowViewSystem;
		return this;
	}

	public dispatch(): void {
		this.dispatchGameUIBaseView();
		this.dispatchLevelView();
		this.dispatchViewportUIView();
		this.dispatchViewportView();
		this.dispatchWindowViews();
	}

	private dispatchLevelView(): void {
		if (this.levelViewTargets) {
			this.levelViewTargets
				.filter(target => target.dispatcherView !== this.levelView)
				.forEach(target => {
					target.dispatcherView = this.levelView;
					target.dispatcherTarget.setLevelView(this.levelView);
				});
		}

		const levelBusinesses = this.levelView.getLevelBusinesses();

		if (this.businessBaseViewsTargets) {
			this.businessBaseViewsTargets
				.filter(target => target.dispatcherView !== levelBusinesses)
				.forEach(target => {
					target.dispatcherView = levelBusinesses;
					target.dispatcherTarget.setBusinessBaseViews(levelBusinesses);
				});
		}

		if (this.businessBaseViewTargets) {
			levelBusinesses.forEach((levelBusiness) => {
				this.businessBaseViewTargets
					.filter(target => target.dispatcherView !== levelBusiness)
					.forEach(target => {
						target.dispatcherView = levelBusiness;
						target.dispatcherTarget.setBusinessBaseView(levelBusiness);
					});
			});
		}

		levelBusinesses.forEach(levelBusiness => this.dispatchBusinessUIView(levelBusiness));
		levelBusinesses.forEach((levelBusiness) => {
			levelBusiness.on(BusinessBaseView.EVENT_NEW_UI_VIEW, () => this.dispatchBusinessUIView(levelBusiness));
		});

		const prepartyStartCharacterView: PrepartyStartCharacterView | undefined = this.levelView.getPrepartyStartCharacterView();
		if (this.prepartyStartCharacterViewTarget) {
			this.prepartyStartCharacterViewTarget
				.filter(target => target.dispatcherView !== prepartyStartCharacterView)
				.forEach(target => {
					target.dispatcherView = prepartyStartCharacterView;
					target.dispatcherTarget.setPrepartyStartChracterView(prepartyStartCharacterView);
				});
		}
	}

	private dispatchBusinessUIView(levelBusiness: BusinessBaseView): void {
		const businessUIView = levelBusiness.getCurrentUIView();
		if (businessUIView instanceof BusinessOpenToBuyUIView) {
			if (this.businessOpenToBuyUIViewTargets) {
				this.businessOpenToBuyUIViewTargets
					.filter(target => target.dispatcherView !== businessUIView)
					.forEach(target => {
						target.dispatcherView = businessUIView;
						target.dispatcherTarget.setOpenToBuyUIView(businessUIView);
					});
			}
		} else if (businessUIView instanceof BusinessAcquiredUIView) {
			if (this.businessAcquiredUIViewTargets) {
				this.businessAcquiredUIViewTargets
					.filter(target => target.dispatcherView !== businessUIView)
					.forEach(target => {
						target.dispatcherView = businessUIView;
						target.dispatcherTarget.setAcquiredUIView(businessUIView);
					});
			}
			if (this.businessManageUIViewTargets) {
				const businessManageUIView = businessUIView.getManageUIView();
				this.businessManageUIViewTargets
					.filter(target => target.dispatcherView !== businessManageUIView)
					.forEach(target => {
						target.dispatcherView = businessManageUIView;
						target.dispatcherTarget.setManageUIView(businessManageUIView);
					});
			}
		}
	}

	private dispatchViewportView(): void {
		if (this.viewportViewTargets) {
			this.viewportViewTargets
				.filter(target => target.dispatcherView !== this.viewportView)
				.forEach(target => {
					target.dispatcherView = this.viewportView;
					target.dispatcherTarget.setViewportView(this.viewportView);
				});
		}
	}

	private dispatchViewportUIView(): void {
		if (this.viewportUIViewTargets) {
			this.viewportUIViewTargets
				.filter(target => target.dispatcherView !== this.viewportUIView)
				.forEach(target => {
					target.dispatcherView = this.viewportUIView;
					target.dispatcherTarget.setViewportUIView(this.viewportUIView);
				});
		}
	}

	private dispatchGameUIBaseView(): void {
		if (this.gameUIBaseViewTargets) {
			this.gameUIBaseViewTargets
				.filter(target => target.dispatcherView !== this.gameUIView)
				.forEach(target => {
					target.dispatcherView = this.gameUIView;
					target.dispatcherTarget.setGameUIBaseView(this.gameUIView);
				});
		}

		if (this.skillBaseViewTargets) {
			const skillBaseViews = this.gameUIView.getSkillsPanelView().getSkillViews();
			skillBaseViews.forEach((skillBaseView) => {
				this.skillBaseViewTargets
					.filter(target => target.dispatcherView !== skillBaseView)
					.forEach(target => {
						target.dispatcherView = skillBaseView;
						target.dispatcherTarget.setSkillView(skillBaseView);
					});
			});
		}

		if (this.bankOfferLinesUIViewTargets) {
			const bankOfferLinesUIView = this.gameUIView.getBankOfferLinesUIView();
			this.bankOfferLinesUIViewTargets
				.filter(target => target.dispatcherView !== bankOfferLinesUIView)
				.forEach(target => {
					target.dispatcherView = bankOfferLinesUIView;
					target.dispatcherTarget.setBankOfferLinesUIView(bankOfferLinesUIView);
				});
		}

		if (this.buttonMaxCustomersViewTargets) {
			const buttonMaxCustomersView = this.gameUIView.getButtonMaxCustomersView();
			this.buttonMaxCustomersViewTargets
				.filter(target => target.dispatcherView !== buttonMaxCustomersView)
				.forEach(target => {
					target.dispatcherView = buttonMaxCustomersView;
					target.dispatcherTarget.setButtonMaxCustomersView(buttonMaxCustomersView);
				});
		}
	}

	private dispatchWindowViews(): void {
		if (this.cheatWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_CHEAT_SHOWED).includes(this.setCheatWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_CHEAT_SHOWED, this.setCheatWindowView, this);
			}
		}

		if (this.questWindowBaseViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED).includes(this.setQuestWindowBaseView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED, this.setQuestWindowBaseView, this);
			}
		}

		if (this.backToGameWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_BACK_TO_GAME_SHOWED).includes(this.setBackToGameWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_BACK_TO_GAME_SHOWED, this.setBackToGameWindowView, this);
			}
		}

		if (this.characterProfileWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_CHARACTER_PROFILE_SHOWED).includes(this.setCharacterProfileWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_CHARACTER_PROFILE_SHOWED, this.setCharacterProfileWindowView, this);
			}
		}

		if (this.upgradePromoteWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_UPGRADE_PROMOTE_SHOWED).includes(this.setUpgradePromoteWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_UPGRADE_PROMOTE_SHOWED, this.setUpgradePromoteWindowView, this);
			}
		}

		if (this.totemPromoteWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_TOTEM_PROMOTE_SHOWED).includes(this.setTotemPromoteWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_TOTEM_PROMOTE_SHOWED, this.setTotemPromoteWindowView, this);
			}
		}

		if (this.summonCollectionViewTargets || this.collectionCardsProgressViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED).includes(this.setCollectionWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.setCollectionWindowView, this);
			}
		}

		if (this.summonResultWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_SUMMON_RESULT_SHOWED).includes(this.setSummonResultWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_SUMMON_RESULT_SHOWED, this.setSummonResultWindowView, this);
			}
		}

		if (this.bankWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED).includes(this.setBankWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, this.setBankWindowView, this);
			}
		}

		if (this.bankOfferWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_BANK_OFFER_SHOWED).includes(this.setBankOfferWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_BANK_OFFER_SHOWED, this.setBankOfferWindowView, this);
			}
		}

		if (this.tutorialStepWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_TUTORIAL_SHOWED).includes(this.setTutorialStepWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_TUTORIAL_SHOWED, this.setTutorialStepWindowView, this);
			}
		}

		if (this.settingsWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_SETTINGS_SHOWED).includes(this.setSettingsWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_SETTINGS_SHOWED, this.setSettingsWindowView, this);
			}
		}

		if (this.rewardResultWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_REWARD_RESULT_SHOWED).includes(this.setRewardResultWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_REWARD_RESULT_SHOWED, this.setRewardResultWindowView, this);
			}
		}

		if (this.supportWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_SUPPORT_SHOWED).includes(this.setSupportWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_SUPPORT_SHOWED, this.setSupportWindowView, this);
			}
		}

		if (this.registrationWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_REGISTRATION_SHOWED).includes(this.setRegistrationWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_REGISTRATION_SHOWED, this.setRegistrationWindowView, this);
			}
		}

		if (this.dialogWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_DIALOG_SHOWED).includes(this.setDialogWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_DIALOG_SHOWED, this.setDialogWindowView, this);
			}
		}

		if (this.farewellPartyWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED).includes(this.setFarewellPartyWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED, this.setFarewellPartyWindowView, this);
			}
		}

		if (this.prepartyWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED).includes(this.setPrepartyWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED, this.setPrepartyWindowView, this);
			}
		}

		if (this.newsWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_NEWS_SHOWED).includes(this.setNewsWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_NEWS_SHOWED, this.setNewsWindowView, this);
			}
		}
	}

	private setPrepartyWindowView(view: PrepartyWindowView): void {
		this.prepartyWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setPrepartyWindowView(view));
	}

	private setFarewellPartyWindowView(view: FarewellPartyWindowView): void {
		this.farewellPartyWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setFarewellPartyWindowView(view));
	}

	private setBackToGameWindowView(view: BackToGameWindowView): void {
		this.backToGameWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setBackToGameWindowView(view));
	}

	private setQuestWindowBaseView(view: QuestWindowBaseView): void {
		this.questWindowBaseViewTargets?.forEach(setter => setter.dispatcherTarget.setQuestWindowBaseView(view));
	}

	private setCharacterProfileWindowView(view: CharacterProfileWindow): void {
		this.characterProfileWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setCharacterProfileWindowView(view));
	}

	private setUpgradePromoteWindowView(view: UpgradePromoteWindowView): void {
		this.upgradePromoteWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setUpgradePromoteWindowView(view));
	}

	private setTotemPromoteWindowView(view: TotemPromoteWindowView): void {
		this.totemPromoteWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setTotemPromoteWindowView(view));
	}

	private setSummonResultWindowView(view: SummonResultWindowView): void {
		this.summonResultWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setSummonResultWindowView(view));
	}

	private setBankWindowView(view: BankWindowView): void {
		this.bankWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setBankWindowView(view));
	}

	private setBankOfferWindowView(view: BankOfferWindowView): void {
		this.bankOfferWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setBankOfferWindowView(view));
	}

	private setTutorialStepWindowView(view: TutorialStepView): void {
		this.tutorialStepWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setTutorialStepWindowView(view));
	}

	private setSettingsWindowView(view: BaseSettingsWindowView): void {
		this.settingsWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setSettingsWindowView(view));
	}

	private setRewardResultWindowView(view: RewardResultWindowView): void {
		// eslint-disable-next-line no-unused-expressions
		this.rewardResultWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setRewardResultWindowView(view));
	}

	private setSupportWindowView(view: SupportWindowView): void {
		this.supportWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setSupportWindowView(view));
	}

	private setRegistrationWindowView(view: RegistrationWindowView): void {
		this.registrationWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setRegistrationWindowView(view));
	}

	protected setCollectionWindowView(view: CollectionWindowView): void {
		if (this.collectionCardsProgressViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_CARDS_PROGRESS_VIEW_SHOWED, (v: EpicQuestCollectionsProgressView) => {
				this.collectionCardsProgressViewTargets.forEach(setter => setter.dispatcherTarget.setEpicQuestCollectionsProgressView(v));
			}, this);
		}
		if (this.summonCollectionViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, (v: SummonCollectionView) => {
				this.summonCollectionViewTargets.forEach(setter => setter.dispatcherTarget.setSummonCollectionView(v));
			}, this);
		}
		if (this.characterCollectionViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_CHARACTER_VIEW_SHOWED, (v: CharacterCollectionView) => {
				this.characterCollectionViewTargets.forEach(setter => setter.dispatcherTarget.setCharacterCollectionView(v));
			}, this);
		}
		if (this.totemCollectionViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_TOTEM_VIEW_SHOWED, (v: TotemCollectionView) => {
				this.totemCollectionViewTargets.forEach(s => s.dispatcherTarget.setTotemCollectionViewSetter(v));
			}, this);
		}
		if (this.upgradeCollectionViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_UPGRADE_VIEW_SHOWED, (v: UpgradeCollectionDefaultView | UpgradeCollectionGroupByBusinessesView) => {
				this.upgradeCollectionViewTargets.forEach(s => s.dispatcherTarget.setUpgradeCollectionViewSetter(v));
			}, this);
		}
		if (this.furnitureCollectionViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_FURNITURE_VIEW_SHOWED, (v: FurnitureCollectionView) => {
				this.furnitureCollectionViewTargets.forEach(s => s.dispatcherTarget.setFurnitureCollectionView(v));
			}, this);
		}
	}

	private setDialogWindowView(view: DialogWindowView): void {
		this.dialogWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setDialogWindowView(view));
	}

	private setCheatWindowView(view: CheatWindowView): void {
		this.cheatWindowViewTargets.forEach(setter => setter.dispatcherTarget.setCheatWindowView(view));
	}

	private setNewsWindowView(view: BaseNewsWindowView): void {
		this.newsWindowViewTargets.forEach((setter) => setter.dispatcherTarget.setNewsWindowView(view));
	}
}
