import { SkillModel } from '@models/skills/SkillModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { SummonModel } from '@models/SummonModel';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { GameConstants } from '@src/utils/GameConstants';
import { SummonOpenButtonView } from '@views/windows/collection/summon/SummonOpenButtonView';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import {
	RewardGroups,
	RewardResultWindowBaseView,
	RewardResultWindowBgType,
	RewardResultWindowRewardStringType,
} from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { AbstractReward } from '@interfaces/AbstractReward';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';

export class SummonResultWindowView extends RewardResultWindowBaseView {
	public static readonly EVENT_ALL_CARDS_OPENED: symbol = Symbol();
	public static readonly EVENT_OPEN_AGAIN_CLICK: symbol = Symbol();

	private readonly backButton: ButtonWithLabelBaseView;
	private openNextButton: ButtonBaseView;
	private openAllButton: ButtonBaseView;
	private openAgainButton: SummonOpenButtonView;
	private bottomButtonsContainer: PIXI.Container;

	private model: SummonModel;
	private hardMoneyModel: HardMoneyModel;
	private prestigeMoneyModel: PrestigeMoneyModel;

	private isX10BtnPressed: boolean;

	constructor(
		summonModel: SummonModel,
		count: number,
		skillModels: Map<string, SkillModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		rewardString?: RewardResultWindowRewardStringType,
		moneyPanelsButtonPlusInvisible?: boolean,
		hideMoneyPanels?: boolean,
	) {
		super(
			skillModels,
			prestigeMoneyModel,
			hardMoneyModel,
			RewardResultWindowBgType.SUMMON,
			rewardString,
			moneyPanelsButtonPlusInvisible,
			hideMoneyPanels,
		);

		this.model = summonModel;
		this.hardMoneyModel = hardMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;

		this.bg.interactive = true;
		this.bg.on('pointertap', () => {
			if (this.cardsToOpen === 0) {
				if (Object.keys(this.groups).length - 1 === this.currentGroup
					&& this.bottomButtonsContainer.visible) {
					this.onBackButtonClick();
				} else if (this.openNextButton.visible) {
					this.openNextClick();
				}
			}
		}, this);

		this.bottomButtonsContainer = new PIXI.Container();
		const localizationStorage = LocalizationStorage.getInstance();

		if (summonModel.isFreePrice()) {
			this.backButton = new ButtonWithLabelBaseView(
				ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 300, 90),
				{ font: '36px wendyOneShadowBold' },
			);
			this.backButton.position.set(
				GameConstants.GAME_CENTER_X,
				GameConstants.GAME_HEIGHT - this.backButton.height,
			);
		} else {
			this.backButton = new ButtonWithLabelBaseView(
				ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 300, 90),
				{ font: '36px wendyOneShadowBold' },
			);
			this.backButton.position.set(
				GameConstants.GAME_CENTER_X - this.backButton.width / 2 - 10,
				GameConstants.GAME_HEIGHT - this.backButton.height,
			);

			this.openAgainButton = new SummonOpenButtonView(
				summonModel,
				count,
				hardMoneyModel,
				prestigeMoneyModel,
				'#open_again_summon_button',
				false,
				{ font: '38px wendyOneShadowBold', align: 'center' },
			);
			this.openAgainButton.position.set(
				GameConstants.GAME_CENTER_X + this.openAgainButton.width / 2 + 10,
				GameConstants.GAME_HEIGHT - this.openAgainButton.height,
			);
			this.openAgainButton.on(SummonOpenButtonView.EVENT_CLICK, () => { this.openAgainClick(summonModel.getKey(), count); });
			this.bottomButtonsContainer.addChild(
				this.openAgainButton,
			);
		}
		this.backButton.setTextLabel(localizationStorage.getLocalizedString('#close_summon_button'));
		this.backButton.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onBackButtonClick, this);

		this.bottomButtonsContainer.addChild(
			this.backButton,
		);
		this.bottomButtonsContainer.visible = false;
		this.addChild(
			this.bottomButtonsContainer,
		);

		const buttonOpenNextBg = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11,
		);
		buttonOpenNextBg.width = 300;
		buttonOpenNextBg.height = 90;
		buttonOpenNextBg.pivot.set(150, 45);

		const buttonOpenAllBg = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11,
		);
		buttonOpenAllBg.width = 300;
		buttonOpenAllBg.height = 90;
		buttonOpenAllBg.pivot.set(150, 45);

		this.openNextButton = new ButtonBaseView(buttonOpenNextBg);
		const openText = localizationStorage.getLocalizedString('#open_next_summon_pack');
		const buttonLabel = new PIXI.extras.BitmapText(openText, { font: '36px wendyOneShadowBold' });
		buttonLabel.anchor = 0.5;
		this.openNextButton.addChild(buttonLabel);
		this.openNextButton.position.set(
			GameConstants.GAME_CENTER_X - (this.openNextButton.width / 2 + 10),
			GameConstants.GAME_HEIGHT - this.openNextButton.height,
		);
		this.openNextButton.visible = false;
		this.openNextButton.on(ButtonBaseView.EVENT_CLICK, this.openNextClick.bind(this));
		this.addChild(this.openNextButton);

		this.openAllButton = new ButtonBaseView(buttonOpenAllBg);
		const openText1 = localizationStorage.getLocalizedString('#open_all_summon_pack');
		const buttonLabel1 = new PIXI.extras.BitmapText(openText1, { font: '36px wendyOneShadowBold' });
		buttonLabel1.anchor = 0.5;
		this.openAllButton.addChild(buttonLabel1);
		this.openAllButton.position.set(
			GameConstants.GAME_CENTER_X + (this.openAllButton.width / 2 + 10),
			GameConstants.GAME_HEIGHT - this.openAllButton.height,
		);
		this.openAllButton.visible = false;
		this.openAllButton.on(ButtonBaseView.EVENT_CLICK, this.openX10NextClick.bind(this));
		this.addChild(this.openAllButton);

		if (this.model.isPrestigePrice()) {
			this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		} else if (this.model.isHardPrice()) {
			this.hardMoneyModel.on(HardMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		}
	}

	public init(cards: AbstractReward[], groups?: RewardGroups): void {
		this.bottomButtonsContainer.visible = false;

		if (this.openAgainButton) {
			this.openAgainButton.updateAvailability();
		}
		super.init(cards, groups);
	}

	private onMoneyChange(): void {
		this.openAgainButton.updateAvailability();
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getBackButtonContainer(): PIXI.Container {
		return this.backButton;
	}

	private onBackButtonClick(): void {
		this.onClose();
	}

	private openAgainClick(id: string, count: number): void {
		const price = count === 10 ? this.model.getPriceValueX10() : this.model.getPriceValue();
		const isEnoughResources = (this.model.isPrestigePrice() && this.prestigeMoneyModel.getValue() >= price)
			|| (this.model.isHardPrice() && this.hardMoneyModel.getValue() >= price)
			|| this.model.isFreePrice();

		if (isEnoughResources) {
			this.bottomButtonsContainer.visible = false;

			new TWEEN.Tween(this.cardsContainer)
				.to({ alpha: 0 }, 500)
				.onComplete(() => {
					this.cardsContainer.visible = false;
					this.emit(SummonResultWindowView.EVENT_OPEN_AGAIN_CLICK, id, count);
					this.cardAnimations.clear();
				})
				.start();
		} else {
			this.emit(SummonResultWindowView.EVENT_OPEN_AGAIN_CLICK, id, count);
		}
	}

	private openNextClick(): void {
		this.isX10BtnPressed = false;
		this.openAllButton.visible = false;
		this.openNextButton.visible = false;

		new TWEEN.Tween(this.cardsContainer)
			.to({ alpha: 0 }, 500)
			.onComplete(() => {
				this.cardsContainer.visible = false;
				this.cardAnimations.clear();
				this.currentGroup += 1;
				this.redraw();
			})
			.start();
	}

	private openX10NextClick(): void {
		this.isX10BtnPressed = true;

		const speedUpValue = 2.5;

		this.openNextButton.visible = false;
		this.openAllButton.visible = false;
		new TWEEN.Tween(this.cardsContainer)
			.to({ alpha: 0 }, 250)
			.onComplete(() => {
				this.cardsContainer.visible = false;
				this.cardAnimations.clear();
				this.currentGroup += 1;
				this.redraw(false, speedUpValue);
			})
			.start();
	}

	protected afterAllCardsOpened(): void {
		if (Object.keys(this.groups).length - 1 === this.currentGroup) {
			this.bottomButtonsContainer.visible = true;
		} else if (Object.keys(this.groups).length > 1 && this.currentGroup >= 1 && this.isX10BtnPressed) {
			setTimeout(() => {
				this.openX10NextClick();
			}, 500);
		} else {
			this.openNextButton.visible = true;
			this.openAllButton.visible = true;
		}
		this.emit(SummonResultWindowView.EVENT_ALL_CARDS_OPENED);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.model.isPrestigePrice()) {
			this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		} else if (this.model.isHardPrice()) {
			this.hardMoneyModel.off(HardMoneyModel.EVENT_VALUE_CHANGED, this.onMoneyChange, this);
		}
		super.destroy(options);
	}
}
