import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter53010View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53010View';
import { EventCharacter53011View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53011View';
import { EventCharacter53012View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53012View';

export class EventBusiness53004View extends BusinessBaseView {
	constructor() {
		const eventCharacter53010View = new EventCharacter53010View();
		const eventCharacter53011View = new EventCharacter53011View();
		const eventCharacter53012View = new EventCharacter53012View();

		super('business53004', [eventCharacter53010View, eventCharacter53011View, eventCharacter53012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter53012View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter53010View as PIXI.DisplayObject,
			eventCharacter53011View,
		);
	}
}
