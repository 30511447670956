import { UpgradeModel } from '@models/UpgradeModel';
import { BusinessModel } from '@models/BusinessModel';
import * as TWEEN from '@tweenjs/tween.js';
import { BusinessSlotsView } from './BusinessSlotsView';
import { CharacterSkillPairType } from '@src/types/CharacterSkillPairType';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { BusinessTopUiInfo } from './BusinessTopUiInfo';
import { CharacterModel } from '@models/CharacterModel';
import { DefaultHintViewData } from '@views/ui/hints/HintDefaultView';

export class BusinessManageUIView extends PIXI.Container {
	public static readonly EVENT_SHOW_PROMOTE_UPGRADE: symbol = Symbol();
	public static readonly EVENT_SHOW_PROMOTE_CHARACTER: symbol = Symbol();
	public static readonly EVENT_BUTTON_BACK_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_PROPERTY_CLICK = Symbol();
	public static readonly EVENT_SHOW = Symbol();
	public static readonly EVENT_HIDE = Symbol();

	private model: BusinessModel;

	private slotsView: BusinessSlotsView;

	private upgradeSlotsBg: PIXI.mesh.NineSlicePlane;
	private characterSlotsBg: PIXI.mesh.NineSlicePlane;

	private topBusinessUi: BusinessTopUiInfo;

	constructor(
		model: BusinessModel,
		characterSkillPairs: CharacterSkillPairType[],
		upgrades: UpgradeModel[],
		character: CharacterModel[],
		softMoneyModel: SoftMoneyModel,
		currentLevel?: number,
	) {
		super();

		this.model = model;

		const scaleSet = 0.36;

		this.topBusinessUi = new BusinessTopUiInfo(
			model,
			character,
			upgrades,
		);
		this.topBusinessUi.on(BusinessTopUiInfo.EVENT_BUTTON_BACK_CLICK, this.onBackIconClick, this);
		this.topBusinessUi.on(BusinessTopUiInfo.EVENT_PROPERTY_CLICK, this.onPropertyClick, this);
		this.topBusinessUi.y = -143;

		this.upgradeSlotsBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['business_item_panel'], 4, 4, 4, 4);
		this.upgradeSlotsBg.width = 1920;
		this.upgradeSlotsBg.height = 322;
		this.upgradeSlotsBg.pivot.set(this.upgradeSlotsBg.width / 2, this.upgradeSlotsBg.height / 2);
		this.upgradeSlotsBg.position.set(0, 179);
		this.upgradeSlotsBg.scale.set(scaleSet, scaleSet);
		this.upgradeSlotsBg.interactive = true;

		this.characterSlotsBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['business_item_panel2'], 4, 4, 4, 4);
		this.characterSlotsBg.width = 554;
		this.characterSlotsBg.height = 322;
		this.characterSlotsBg.pivot.set(0, this.characterSlotsBg.height / 2);
		this.characterSlotsBg.position.set(-345, 179);
		this.characterSlotsBg.scale.set(scaleSet, scaleSet);
		this.characterSlotsBg.interactive = true;

		this.slotsView = new BusinessSlotsView(
			characterSkillPairs,
			upgrades,
			softMoneyModel,
			currentLevel,
		);
		this.slotsView.position.set(-274, 201);
		this.slotsView.on(
			BusinessSlotsView.EVENT_SHOW_PROMOTE_UPGRADE,
			(slotView, upgradeKey: string) => {
				this.emit(BusinessManageUIView.EVENT_SHOW_PROMOTE_UPGRADE, slotView, upgradeKey);
			},
			this,
		);
		this.slotsView.on(
			BusinessSlotsView.EVENT_SHOW_PROMOTE_CHARACTER,
			(slotView, characterKey: string, skillKey?: string) => {
				this.emit(BusinessManageUIView.EVENT_SHOW_PROMOTE_CHARACTER, slotView, characterKey, skillKey);
			},
			this,
		);
		this.slotsView.on(
			BusinessSlotsView.EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK,
			(upgrade: UpgradeModel) => this.emit(BusinessManageUIView.EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK, upgrade),
			this,
		);
		this.slotsView.on(
			BusinessSlotsView.EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK,
			(character: CharacterModel) => this.emit(BusinessManageUIView.EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK, character),
			this,
		);

		this.addChild(
			this.topBusinessUi,
			this.upgradeSlotsBg as PIXI.DisplayObject,
			this.characterSlotsBg,
			this.slotsView,
		);
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getBusinessSlots(): BusinessSlotsView {
		return this.slotsView;
	}

	private onBackIconClick(): void {
		this.emit(BusinessManageUIView.EVENT_BUTTON_BACK_CLICK);
	}

	private onPropertyClick(data: DefaultHintViewData, origin: PIXI.Container): void {
		this.emit(BusinessManageUIView.EVENT_PROPERTY_CLICK, data, origin);
	}

	public show(): void {
		this.topBusinessUi.alpha = 0;
		new TWEEN.Tween(this.topBusinessUi)
			.to({ alpha: 1 }, 300)
			.start();

		this.slotsView.alpha = 0;
		new TWEEN.Tween(this.slotsView)
			.to({ alpha: 1 }, 200)
			.start();

		this.upgradeSlotsBg.alpha = 0;
		new TWEEN.Tween(this.upgradeSlotsBg)
			.to({ alpha: 1 }, 200)
			.start();

		this.characterSlotsBg.alpha = 0;
		new TWEEN.Tween(this.characterSlotsBg)
			.to({ alpha: 1 }, 200)
			.start();

		this.slotsView.startAppearingAnimation();
		this.emit(BusinessManageUIView.EVENT_SHOW);
	}

	public hide(): void {
		this.alpha = 1;
		this.emit(BusinessManageUIView.EVENT_HIDE);
	}
}
