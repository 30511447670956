import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10010View } from '@views/characters/events/event1/EventCharacter10010View';
import { EventCharacter10011View } from '@views/characters/events/event1/EventCharacter10011View';
import { EventCharacter10012View } from '@views/characters/events/event1/EventCharacter10012View';

export class EventBusiness10004View extends BusinessBaseView {
	constructor() {
		const character10010 = new EventCharacter10010View();
		const character10011 = new EventCharacter10011View();
		const character10012 = new EventCharacter10012View();

		super('business10004', [character10010, character10011, character10012]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			character10010 as PIXI.DisplayObject,
			character10011,
			character10012,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
