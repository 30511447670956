import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithOldCostValueView';
import { ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { TextUtils } from '@src/utils/TextUtils';

export class BankTimeskipApplyAnimation extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_CLICK: symbol = Symbol();

	private readonly bg: PIXI.Graphics;
	private readonly content: PIXI.Container;
	private readonly titleContent: PIXI.Container;
	private readonly iconContent: PIXI.Container;

	private buttonBuy: ButtonBaseView;
	private buttonActivate: ButtonBaseView;

	private animationIn: TWEEN.Tween;
	private animationOut: TWEEN.Tween;

	private moneyText: SizeableBitmapText;

	private static createButtonSkip(): ButtonBaseView {
		const localizationStorage = LocalizationStorage.getInstance();

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 292;
		buttonBg.height = 78;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);

		const stringActivate = localizationStorage.getLocalizedString('#bank_timeskip_skip');
		const labelActivate = new PIXI.extras.BitmapText(stringActivate, {
			font: '26px wendyOneShadowBold',
		});
		labelActivate.anchor = 0.5;
		labelActivate.y = -2;

		button.addChild(
			labelActivate,
		);

		return button;
	}

	private static createButtonBuy(costResource: ButtonValueTypes, costValue: string): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 292;
		buttonBg.height = 78;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonWithCostValueView({
			buttonBg,
			fontStyle: { font: '32px wendyOneShadowBold' },
			type: costResource,
		});
		button.setCost(costValue);

		return button;
	}

	constructor(
		value: number,
		income: SoftMoneyNumber,
		iconKey: string,
	) {
		super();

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const localizationStorage = LocalizationStorage.getInstance();

		this.content = new PIXI.Container();
		this.titleContent = new PIXI.Container();
		this.iconContent = new PIXI.Container();

		this.bg = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
			.endFill();
		this.bg.interactive = true;

		const titleWindow = new SizeableBitmapText(
			localizationStorage.getLocalizedString('#confirm_purchase_title'),
			700,
			{ font: '44px wendyOne', align: 'center', tint: 0xf9de7e },
		);
		titleWindow.pivot.set(titleWindow.width / 2, titleWindow.height / 2);
		titleWindow.position.set(
			GameConstants.GAME_WIDTH / 2,
			152,
		);
		titleWindow.scale.x = 1.1;

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.position.set(
			GameConstants.GAME_WIDTH / 2,
			145,
		);
		episodeNamePanelFade.width = 873;
		episodeNamePanelFade.height = 105;
		episodeNamePanelFade.tint = 0x000000;

		const glowLine = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine.position.set(GameConstants.GAME_WIDTH / 2, 103);
		const glowLine2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine2.position.set(GameConstants.GAME_WIDTH / 2, 197);

		const glow = new PIXI.Sprite(fxAtlas['time_skip_activated_glow']);
		glow.setTransform(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
			2,
			2,
		);

		const moneyIcon = new PIXI.Sprite(collectionAtlas[iconKey]);
		moneyIcon.setTransform(
			GameConstants.GAME_WIDTH / 2 - 20,
			550,
			1.4,
			1.4,
		);

		const descr = new SizeableBitmapText(
			`${value / 3600}h`,
			300,
			{ font: '90px wendyOneGold' },
		);
		descr.pivot.set(descr.width / 2, descr.height / 2);
		descr.position.set(
			GameConstants.GAME_WIDTH / 2 + 110,
			646,
		);

		const girl = new PIXI.Sprite(AssetsStorage.getResource('character30_offer').texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.setTransform(1311, 141, 0.85, 0.85);

		const girlsLegs = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['legs_decor']);
		girlsLegs.setTransform(1580, 1032, 1.7, 1.7);

		const closeButton = new CloseButtonView();
		closeButton.position.set(1860, 40);
		closeButton.alpha = 0.7;
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.moneyText = new SizeableBitmapText(
			'',
			700,
			{ font: '45px wendyOneShadowBold' },
		);
		this.moneyText.text = `${TextUtils.TAG_MONEY}+${income.toString()}`;
		this.moneyText.pivot.set(
			this.moneyText.width / 2,
			this.moneyText.height / 2,
		);
		this.moneyText.position.set(
			GameConstants.GAME_WIDTH / 2,
			690,
		);

		this.content.pivot.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.position.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.scale.set(0);
		this.moneyText.scale.set(0);
		this.bg.alpha = 0;

		this.titleContent.addChild(
			episodeNamePanelFade as PIXI.DisplayObject,
			titleWindow,
			glowLine,
			glowLine2,
		);

		this.iconContent.addChild(
			glow as PIXI.DisplayObject,
			moneyIcon,
			descr,
		);

		this.content.addChild(
			girl as PIXI.DisplayObject,
			this.iconContent,
			girlsLegs,
			closeButton,
		);

		this.addChild(
			this.bg,
			this.content,
			this.moneyText,
		);
	}

	private onButtonBuyClick(): void {
		this.emit(BankTimeskipApplyAnimation.EVENT_BUTTON_BUY_CLICK);
		this.onClose();
	}

	private onButtonActivateClick(): void {
		this.emit(BankTimeskipApplyAnimation.EVENT_BUTTON_ACTIVATE_CLICK);
		this.onClose();
	}

	public showActivateStage(): void {
		this.buttonActivate = BankTimeskipApplyAnimation.createButtonSkip();
		this.buttonActivate.on(ButtonBaseView.EVENT_CLICK, this.onButtonActivateClick, this);
		this.buttonActivate.position.set(
			GameConstants.GAME_WIDTH / 2,
			982,
		);

		this.content.removeChild(this.buttonBuy);
		this.content.removeChild(this.titleContent);
		this.content.addChild(this.buttonActivate);
		this.iconContent.y = -65;
		this.moneyText.y = 690;

		this.animationIn = new TWEEN.Tween(this.bg)
			.to({ alpha: 0.92 }, 300)
			.easing(TWEEN.Easing.Quadratic.In);
		const contentScaleIn = new TWEEN.Tween(this.content.scale)
			.to({ x: 1.1, y: 1.1 }, 150)
			.easing(TWEEN.Easing.Cubic.In);
		const contentScaleInBounce = new TWEEN.Tween(this.content.scale)
			.to({ x: 1, y: 1 }, 50)
			.easing(TWEEN.Easing.Linear.None);
		const moneyTextScaleIn = new TWEEN.Tween(this.moneyText.scale)
			.to({ x: 1, y: 1 }, 200)
			.easing(TWEEN.Easing.Cubic.In);

		this.animationIn.chain(contentScaleIn);
		contentScaleIn.chain(contentScaleInBounce);
		contentScaleInBounce.chain(moneyTextScaleIn);
		this.animationIn.start();
	}

	public showBuyStage(costResource: ButtonValueTypes, costValue: number): void {
		this.buttonBuy = BankTimeskipApplyAnimation.createButtonBuy(costResource, String(costValue));
		this.buttonBuy.on(ButtonBaseView.EVENT_CLICK, this.onButtonBuyClick, this);
		this.buttonBuy.position.set(
			GameConstants.GAME_WIDTH / 2,
			982,
		);

		this.content.removeChild(this.buttonActivate);
		this.content.addChild(this.buttonBuy, this.titleContent);
		this.iconContent.y = 0;

		this.animationIn = new TWEEN.Tween(this.bg)
			.to({ alpha: 0.92 }, 300)
			.easing(TWEEN.Easing.Quadratic.In);
		const contentScaleIn = new TWEEN.Tween(this.content.scale)
			.to({ x: 1.1, y: 1.1 }, 150)
			.easing(TWEEN.Easing.Cubic.In);
		const contentScaleInBounce = new TWEEN.Tween(this.content.scale)
			.to({ x: 1, y: 1 }, 50)
			.easing(TWEEN.Easing.Linear.None);

		this.animationIn.chain(contentScaleIn);
		contentScaleIn.chain(contentScaleInBounce);
		this.animationIn.start();
	}

	private onClose(): void {
		this.animationIn = new TWEEN.Tween(this)
			.to({ alpha: 0 }, 100)
			.easing(TWEEN.Easing.Quadratic.In)
			.onComplete(() => {
				this.destroy();
			})
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.animationIn) {
			this.animationIn.stop();
		}
		if (this.animationOut) {
			this.animationOut.stop();
		}
		super.destroy(options);
	}
}
