import { BaseAction } from '@models/network/actions/BaseAction';

export class NetworkActionQueue extends PIXI.utils.EventEmitter {
	public static readonly EVENT_ACTIONS_EMITTED: symbol = Symbol();

	private actions: BaseAction[];
	private sendActionTicker: number;
	private emitterEnabled: boolean;

	private emitterTimeoutTime: number;

	constructor() {
		super();

		this.actions = [];
		this.emitterEnabled = true;
		this.sendActionTicker = null;
		this.emitterTimeoutTime = 1000;
	}

	public addAction(...action: BaseAction[]): void {
		this.actions.push(...action);
	}

	public hasActions(): boolean {
		return this.actions.length > 0;
	}

	public getActions(): BaseAction[] {
		return this.actions;
	}

	public clearActions(): void {
		this.actions = [];
	}

	public startTicker(): void {
		if (this.sendActionTicker !== null) {
			window.clearTimeout(this.sendActionTicker);
		}

		this.sendActionTicker = window.setTimeout(
			() => {
				this.onEmitActions();
				this.sendActionTicker = null;
			},
			this.emitterTimeoutTime,
		);
	}

	public setEmitterTimeoutTime(value: number): void {
		this.emitterTimeoutTime = value;
	}

	public setEmitterEnabled(value: boolean): void {
		this.emitterEnabled = value;
	}

	public isEmitterEnabled(): boolean {
		return this.emitterEnabled;
	}

	private onEmitActions(): void {
		if (this.actions.length > 0 && this.emitterEnabled) {
			this.emit(NetworkActionQueue.EVENT_ACTIONS_EMITTED);
		} else {
			this.startTicker();
		}
	}
}
