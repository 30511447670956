import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter120003View extends CharacterMainBaseView {
	constructor() {
		super('character120003');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-208, -4);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(0, -61),
			arrowRotation: Math.PI * 180,
			bgPosition: new PIXI.Point(6, -53),
		});
		this.initQuickPhrases(this.key);
	}
}
