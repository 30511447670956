import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithCostValueView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { TextField } from '@views/components/text/TextField';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import LocalizationStorage from '@main/LocalizationStorage';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { NumberUtils } from '@src/utils/NumberUtils';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { BankViewTypes } from '@src/types/BankTypes';
import { BankBundlesParticleAnimation } from '../animations/BankBundlesParticleAnimation';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export type BundleRewardDisplayInfo = {
	icon: PIXI.Container;
	count: number;
};

export class BankTabElementBundleTimedView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_INFO_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	private static readonly REWARD_ITEM_WIDTH: number = 96;

	private readonly localizationStorage: LocalizationStorage;

	private readonly girl: PIXI.Sprite;
	private infoButton: ButtonBaseView;
	private ticker: PIXI.ticker.Ticker;
	private timeRemainLabel: TextField;
	private timeLabel: TextField;
	private buyButton: ButtonBaseView;
	private tweenGroup: TWEEN.Group;
	private failed: boolean;
	private bgContent: PIXI.Container;
	private content: PIXI.Container;
	private rewardsRow: PIXI.Container[];

	constructor(
		private readonly model: BankBundleModel,
		private readonly rewards: RewardDescriptionType[],
		private readonly rewardsDisplayInfo: BundleRewardDisplayInfo[],
		private readonly costResource: ButtonValueTypes,
		private readonly offerModel?: BankOfferModel,
		private readonly viewParam?: string,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;
		this.costResource = costResource;
		this.offerModel = offerModel;
		this.viewParam = viewParam;
		this.model = model;

		this.bgContent = new PIXI.Container();
		this.content = new PIXI.Container();
		this.addChild(this.content);

		const bgRound = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')[`bundle_${this.model.getBgKey()}`], 19.5, 0, 19.5, 0);
		bgRound.width = 1316;
		bgRound.pivot.set(bgRound.width / 2, bgRound.height / 2);

		const optionalbgMask = new PIXI.Graphics();
		optionalbgMask.beginFill(0xffffff);
		optionalbgMask.drawPolygon([
			new PIXI.Point(-647, -122),
			new PIXI.Point(647, -122),
			new PIXI.Point(638, 116),
			new PIXI.Point(-638, 116),
		]);
		this.bgContent.mask = optionalbgMask;

		const optionalBg = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')[`optional_bundle_${this.model.getBgKey()}`]);
		optionalBg.scale.set(2.1);
		optionalBg.mask = optionalbgMask;

		const girlsMask: PIXI.Graphics = new PIXI.Graphics();
		girlsMask.beginFill(0xffffff);
		girlsMask.drawRect(-430, -42, 440, 317);
		girlsMask.endFill();
		girlsMask.pivot.set(girlsMask.width / 2, girlsMask.height / 2);

		this.girl = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')[`girl_bundle_${model.getCharacterLeftKey()}`]);
		this.girl.position.set(-453, -21);
		this.girl.mask = girlsMask;

		const nameText = this.localizationStorage.getLocalizedString(this.model.getNameLocale());

		const availableText = this.localizationStorage.getLocalizedString('#bank_available')
			.replace('{{value}}', model.getRemainingCount().toString())
			.replace('{{total}}', model.getTotalBuyTimes().toString());

		this.content.addChild(
			bgRound,
			optionalbgMask,
			optionalBg as PIXI.DisplayObject,
			this.bgContent,
			girlsMask,
			this.girl,
		);

		if (this.model.isFreePrice()) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['bundle_sticker_green']);
			stickerSale.position.set(-598, -76);
			const saleLabel = new SizeableBitmapText(
				'',
				90,
				{ font: '20px wendyOne', align: 'center' },
			);
			saleLabel.text = this.localizationStorage.getLocalizedString('#summon_free_button');
			saleLabel.anchor = 0.5;
			saleLabel.rotation = Math.PI / 180 * -45;
			saleLabel.position.set(stickerSale.x - 13, stickerSale.y - 13);

			const stickerSaleMask = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['bundle_sticker_green']);
			const stickerSaleHightlight = new SpriteHighlightAnimationView(stickerSaleMask, 800, false);
			stickerSaleHightlight.position.set(stickerSale.x, stickerSale.y);

			this.content.addChild(
				stickerSale,
				stickerSaleHightlight,
				saleLabel as PIXI.DisplayObject,
			);
			stickerSaleHightlight.start();
		}

		if (model.getStickerLocaleKey()) {
			const offerStickerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_sticker'], 26, 0, 26, 0);
			offerStickerBg.width = 360;
			offerStickerBg.pivot.set(offerStickerBg.width / 2, offerStickerBg.height / 2);
			offerStickerBg.scale.set(0.85);
			offerStickerBg.position.set(-2, -120);

			const offerStickerLabel = new SizeableBitmapText(
				'',
				260,
				{ font: '18px wendyOne', align: 'center' },
			);
			offerStickerLabel.text = this.localizationStorage.getLocalizedString(model.getStickerLocaleKey());
			offerStickerLabel.anchor = 0.5;
			offerStickerLabel.position.set(offerStickerBg.x, offerStickerBg.y - 2);

			this.content.addChild(
				offerStickerBg,
				offerStickerLabel as PIXI.DisplayObject,
			);
		}

		if (model.getMostPopularLocaleKey()) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSale.setTransform(640, -100, 0.95, 0.95);
			const saleLabel = new SizeableBitmapText(
				this.localizationStorage.getLocalizedString(model.getMostPopularLocaleKey()),
				75,
				{ font: '38px wendyOne', align: 'center', tint: 0xfff78f },
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.47;
			saleLabel.position.set(stickerSale.x, stickerSale.y);

			const stickerSaleMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			const stickerSaleHighlight = new SpriteHighlightAnimationView(stickerSaleMask, 500, false);
			stickerSaleHighlight.setTransform(stickerSale.x, stickerSale.y, 0.95, 0.95);

			this.content.addChild(
				stickerSale,
				stickerSaleHighlight,
				saleLabel as PIXI.DisplayObject,
			);
			stickerSaleHighlight.start();
		}

		this.createBuyButton();
		this.createTimer();
		this.createInfoButton();

		this.rewardsRow = this.getRewardsRow(rewardsDisplayInfo);
		if (this.rewardsRow.length > 1) {
			this.content.addChild(...this.rewardsRow);

			this.rewards.forEach((x: RewardDescriptionType, index: number) => {
				this.rewardsRow[index].interactive = true;
				this.rewardsRow[index].on('pointerdown', (event: PIXI.interaction.InteractionEvent) => {
					event.stopPropagation();
					this.emit(BankTabElementBundleTimedView.EVENT_SHOW_HINT, x, this.rewardsRow[index]);
				});
			});
		}

		if (this.model.getRemainingCount() <= 0) {
			this.setSoldOut();
		}

		if (this.viewParam === BankViewTypes.MONTHLY_BUNDLE_PREFAB) {
			const animationContainer = new BankBundlesParticleAnimation(this.model.getBgKey());

			const name = new MultiColoredTextField(
				{ font: '31px wendyOneGold', align: 'center' },
				307,
				147,
			);
			name.text = nameText;
			name.anchor = 0.5;
			name.position.set(425, -64);

			const available = new MultiColoredTextField(
				{ font: '26px wendyOne', align: 'center', tint: 0xffffff },
				307,
				147,
			);
			available.anchor = 0.5;
			available.text = availableText;
			available.alpha = 0.6;
			available.position.set(425, -8);

			this.bgContent.addChild(
				animationContainer as PIXI.DisplayObject,
				name,
				available,
			);
		} else {
			const name = new MultiColoredTextField(
				{ font: '31px wendyOneShadowBold', align: 'center' },
				307,
				147,
			);
			name.text = nameText;
			name.anchor = 0.5;
			name.position.set(425, -64);

			const available = new MultiColoredTextField(
				{ font: '26px wendyOne', align: 'center', tint: 0x000000 },
				307,
				147,
			);
			available.anchor = 0.5;
			available.text = availableText;
			available.alpha = 0.6;
			available.position.set(425, -8);

			this.bgContent.addChild(
				name,
				available,
			);
		}

		this.model.once(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this);
		this.offerModel?.once(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this);
	}

	// eslint-disable-next-line class-methods-use-this
	private getRewardsRow(rewardsDisplayInfo: BundleRewardDisplayInfo[]): PIXI.Container[] {
		const rewardsRow: PIXI.Container[] = [];
		const rowWidth = (BankTabElementBundleTimedView.REWARD_ITEM_WIDTH * rewardsDisplayInfo.length) + 5 * (rewardsDisplayInfo.length - 1);
		rewardsDisplayInfo.forEach((rewardDisplayInfo, index): void => {
			const rewardIcon = rewardDisplayInfo.icon;
			const rewardContainer = new PIXI.Container();
			const rewardCount = new TextField(
				`x${SoftMoneyNumber.createFromNumber(rewardDisplayInfo.count).toString()}`,
				{ font: '26px wendyOneShadowBold' },
				BankTabElementBundleTimedView.REWARD_ITEM_WIDTH - 10,
				35,
			);
			rewardCount.anchor = 0.5;
			rewardCount.tint = 0xffd86d;
			rewardCount.position.set(0, 44);
			rewardContainer.addChild(
				rewardIcon,
				rewardCount as PIXI.DisplayObject,
			);
			rewardContainer.position.set(
				(-rowWidth / 2)
				+ (index * BankTabElementBundleTimedView.REWARD_ITEM_WIDTH)
				+ (index * 5) + BankTabElementBundleTimedView.REWARD_ITEM_WIDTH / 2
				- 2,
				-20,
			);
			rewardsRow.push(rewardContainer);
		});
		return rewardsRow;
	}

	private createTimer(): void {
		const bgTimer = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_circle'], 40, 0, 40, 0);
		bgTimer.scale.set(0.45);
		bgTimer.width = 620;
		bgTimer.pivot.set(bgTimer.width / 2, bgTimer.height / 2);
		bgTimer.tint = 0x000000;
		bgTimer.alpha = 0.6;
		bgTimer.position.set(-2, 79);

		this.timeRemainLabel = new TextField(
			'',
			{ font: '19px wendyOne', tint: 0xd7d9da },
			260,
			40,
		);
		this.timeRemainLabel.anchor = new PIXI.Point(0.5, 0.5);

		this.timeLabel = new TextField(
			'',
			{ font: '21px wendyOneShadowBold', tint: 0xffd96e },
			260,
			40,
		);
		this.timeLabel.anchor = new PIXI.Point(0, 0.5);

		this.content.addChild(
			bgTimer,
			this.timeRemainLabel as PIXI.DisplayObject,
			this.timeLabel,
		);

		this.ticker.add(this.update, this);
	}

	private createInfoButton(): void {
		const bgInfo = new PIXI.Graphics();
		bgInfo.beginFill(0x000000);
		bgInfo.drawPolygon([
			new PIXI.Point(-23, -18),
			new PIXI.Point(17, -18),
			new PIXI.Point(27, 18),
			new PIXI.Point(-22, 18),
		]);
		bgInfo.alpha = 0.7;
		bgInfo.position.set(-617, 98);

		this.infoButton = new ButtonBaseView(new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['i_info']));
		this.infoButton.position.set(bgInfo.x, bgInfo.y);
		this.infoButton.on(ButtonBaseView.EVENT_CLICK, this.onInfoButtonClick, this);

		this.content.addChild(
			bgInfo as PIXI.DisplayObject,
			this.infoButton,
		);
	}

	private createBuyButton(): void {
		if (this.buyButton) {
			this.buyButton.destroy();
		}
		const buyButtonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buyButtonBg.width = 258;
		buyButtonBg.height = 68;
		buyButtonBg.pivot.set(buyButtonBg.width / 2, buyButtonBg.height / 2);

		this.buyButton = new ButtonBaseView(buyButtonBg);
		this.buyButton.on(ButtonBaseView.EVENT_CLICK, this.onBuyButtonClick, this);
		this.buyButton.interactive = true;
		this.buyButton.position.set(425, 55);

		const price = new MultiColoredTextField(
			{ font: '31px wendyOneShadowBold' },
			108,
			41,
		);
		price.anchor = 0.5;
		if (this.model.isFreePrice()) {
			price.text = this.localizationStorage.getLocalizedString('#summon_free_button');
		} else {
			price.text = `${this.costResource}${this.model.getPrice()}`;
		}
		price.y = 8;

		this.buyButton.addChild(price);
		this.content.addChild(this.buyButton);

		if (this.model.hasOldPrice()) {
			const oldPrice = new MultiColoredTextField(
				{ font: '22px wendyOne', tint: 0x2e5614 },
				108,
				41,
			);
			oldPrice.anchor = 0.5;
			oldPrice.text = `${this.costResource}${this.model.getOldPrice()}`;
			oldPrice.y = -18;

			const scratchIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['scratch_icon']);
			scratchIcon.width = oldPrice.width;
			scratchIcon.height = oldPrice.height - 6;
			scratchIcon.position.set(oldPrice.x, oldPrice.y);

			this.buyButton.addChild(
				oldPrice,
				scratchIcon as PIXI.DisplayObject,
			);
		} else {
			price.y = -4;
		}
	}

	public isSoldOut(): boolean {
		return this.failed;
	}

	public setSoldOut(): void {
		this.failed = true;

		const blackoutFilter = new PIXI.filters.ColorMatrixFilter();
		blackoutFilter.saturate(-0.65);
		blackoutFilter.brightness(0.45);
		this.content.filters = [blackoutFilter];

		const failedContainer = new PIXI.Container();
		failedContainer.position.set(-2, -28);

		const failedIcon = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['sold_out_sticker']);

		failedContainer.addChild(
			failedIcon as PIXI.DisplayObject,
		);

		this.addChild(failedContainer);

		this.interactiveChildren = false;
	}

	private onBuyButtonClick(): void {
		this.emit(BankTabElementBundleTimedView.EVENT_BUTTON_BUY_CLICK);
	}

	private onInfoButtonClick(): void {
		this.emit(
			BankTabElementBundleTimedView.EVENT_BUTTON_INFO_CLICK,
			this.rewards,
			this.infoButton,
		);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
		const timeleft = this.offerModel ? this.offerModel.getTimeleft() : this.model.getTimeLeft();
		const timeleftFormatted = NumberUtils.secToDHMS(timeleft);
		if (timeleft > 0) {
			this.timeRemainLabel.text = this.localizationStorage.getLocalizedString('#bank_bundle_time_remain');
			this.timeLabel.text = timeleftFormatted;
			this.timeRemainLabel.position.set(-2 - this.timeLabel.width / 2, 79);
			this.timeLabel.position.set(this.timeRemainLabel.x + this.timeRemainLabel.width / 2 + 3, 79);
		}
	}

	private onTimeout(): void {
		this.buyButton.setEnabled(false);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this, true);
		this.offerModel?.off(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this, true);

		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
