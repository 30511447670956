import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BankElementViewDataConfig } from './BankElementViewDataConfig';

export class BankRechargeConfig
	extends BankElementViewDataConfig implements ConfigSetElement {
	public static readonly FIELD_KEY = 'id';
	public static readonly FIELD_BACKGROUND = 'background';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';

	private readonly key: string;
	private readonly background: string;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];

	constructor(config: { [key: string]: string }) {
		super(config[BankRechargeConfig.FIELD_VIEW_KEY.trim()]);

		this.key = config[BankRechargeConfig.FIELD_KEY].trim();
		this.background = config[BankRechargeConfig.FIELD_BACKGROUND].trim();

		if (config[BankRechargeConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[BankRechargeConfig.FIELD_UNLOCK_TYPE] as string;
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[BankRechargeConfig.FIELD_UNLOCK_VALUE] as string;
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}
	}

	public getKey(): string {
		return this.key;
	}

	public getBackground(): string {
		return this.background;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}
}
