import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30008View extends CharacterMainBaseView {
	constructor() {
		super('character30008');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-140, 46);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-160, -38),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-146, -40),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
