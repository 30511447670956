import { GameUIShowHintEventArgs, HintViewType, HintViewDatas } from './ShowHintSpawner';
import { DefaultHintViewData } from '../hints/HintDefaultView';
import { SkillModel } from '@models/skills/SkillModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillHintViewData } from '../hints/SkillHintView';
import { RewardDescriptionType, RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { EventCharacterUpgradeDefaultData } from '../hints/EventLevelCharacterUpgradeDefaultHintView';
import { EventLevelCharacterSkillAutoBusinessHintData } from '../hints/EventLevelCharacterSkillAutoBusinessHintView';
import { UpgradeModel } from '@models/UpgradeModel';
import { AbstractReward } from '@interfaces/AbstractReward';
import { TotemModel } from '@models/TotemModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { PromotableModel } from '@models/PromotableModel';
import { BankInfoHintData } from '../hints/BankInfoHintView';
import { HintTypes, HintDataPosition, HintDataBundle, HintDataReward, HintDataEpicQuiest, HintDataSkill, HintDataBusinessCard } from '@src/types/HintTypes';

export type HintData = HintDataPosition | HintDataBundle | HintDataReward | HintDataBusinessCard | HintDataEpicQuiest | HintDataSkill;

export class ShowHintDataResolver {
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly skillModels: Map<string, SkillModel>;
	private readonly totemModels: Map<string, TotemModel>;

	private currentLevel: number;

	constructor(
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		skillModels: Map<string, SkillModel>,
		totemModels: Map<string, TotemModel>,
	) {
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.skillModels = skillModels;
		this.totemModels = totemModels;

		this.currentLevel = 0;
	}

	public init(currentLevel: number): void {
		this.currentLevel = currentLevel;
	}

	public resolve(type: HintTypes, data: HintData, runInEpisodeAssets?: boolean): GameUIShowHintEventArgs {
		let args: GameUIShowHintEventArgs;
		switch (type) {
			case HintTypes.SOFT_MONEY_PANEL: {
				args = this.resolveSoftMoneyPanelHintData((data as HintDataPosition).arrowPosLocal, data.origin);
				break;
			}
			case HintTypes.HARD_MONEY_PANEL: {
				args = this.resolveHardMoneyPanelHintData((data as HintDataPosition).arrowPosLocal, (data as HintDataPosition).origin);
				break;
			}
			case HintTypes.PRESTIGE_MONEY_PANEL: {
				args = this.resolvePrestigeMoneyPanelHintData((data as HintDataPosition).arrowPosLocal, (data as HintDataPosition).origin);
				break;
			}
			case HintTypes.BUNDLE_INFO: {
				args = this.rewardsDescriptionBundleData((data as HintDataBundle).rewards, (data as HintDataBundle).origin, runInEpisodeAssets);
				break;
			}
			case HintTypes.LEVEL_PANEL_HINT: {
				args = this.resolveLevelPanelHintData((data as HintDataPosition).arrowPosLocal, (data as HintDataPosition).origin);
				break;
			}
			case HintTypes.REWARD_CARD: {
				args = this.resolveRewardCardHintData((data as HintDataReward).rewardDescription, (data as HintDataReward).origin, runInEpisodeAssets);
				break;
			}
			case HintTypes.BUSINESS_CARD: {
				args = this.resolveBusinessCardHintData(data as HintDataBusinessCard);
				break;
			}
			case HintTypes.EPIC_QUEST: {
				args = this.resolveEpicQuestCollectionsHintData(
					(data as HintDataEpicQuiest).bodyOffset,
					(data as HintDataEpicQuiest).arrowPosLocal,
					(data as HintDataEpicQuiest).origin,
				);
				break;
			}
			case HintTypes.SKILL_HINT: {
				args = this.resolveSkillHintData(
					(data as HintDataSkill).skill,
					(data as HintDataSkill).character,
					(data as HintDataSkill).arrowPosLocal,
					(data as HintDataSkill).origin,
				);
				break;
			}
			default:
				throw new Error(`Unknown hint type '${type}`);
		}

		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	public resolveSoftMoneyPanelHintData(
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: DefaultHintViewData = {
			title: '#hint_soft_money_title',
			description: '#hint_soft_money_descr',
			bodyArrowOffset: 0.5,
		};
		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	public resolvePrestigeMoneyPanelHintData(
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: DefaultHintViewData = {
			title: '#hint_prestige_money_title',
			description: '#hint_prestige_money_descr',
			bodyArrowOffset: 0.5,
		};
		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	public resolveHardMoneyPanelHintData(
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: DefaultHintViewData = {
			title: '#hint_hard_money_title',
			description: '#hint_hard_money_descr',
			bodyArrowOffset: 0.5,
		};
		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	public resolveSkillHintData(
		skillKey: string,
		characterKey: string,
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: SkillHintViewData = {
			bodyArrowOffset: 0.65,
			currentLevel: this.currentLevel,
			skillModel: this.skillModels.get(skillKey),
			characterModel: this.characterModels.get(characterKey),
		};
		const args: GameUIShowHintEventArgs = {
			viewType: HintViewType.SKILL,
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	public resolveLevelPanelHintData(
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: DefaultHintViewData = {
			title: '#hint_level_progress_title',
			description: '#hint_level_progress_descr',
			bodyArrowOffset: 0.3,
		};
		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	public resolveEpicQuestCollectionsHintData(
		bodyArrowOffset: number,
		arrowPosLocal: PIXI.Point,
		origin: PIXI.DisplayObject,
	): GameUIShowHintEventArgs {
		const data: DefaultHintViewData = {
			title: '#hint_epic_quest_title',
			description: '#hint_epic_quest_descr',
			bodyArrowOffset,
		};
		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
		};
		return args;
	}

	public resolveRewardHintDataByRewardModel(
		reward: AbstractReward,
		origin: PIXI.DisplayObject,
		runInEpisodeAssets?: boolean,
	): GameUIShowHintEventArgs | undefined {
		const rewardDescription = reward.getRewardDescription();
		return this.resolveRewardCardHintData(rewardDescription, origin, runInEpisodeAssets);
	}

	public resolveRewardCardHintData(
		rewardDescription: RewardDescriptionType,
		origin: PIXI.DisplayObject,
		runInEpisodeAssets?: boolean,
	): GameUIShowHintEventArgs | undefined {
		const arrowPosLocal = new PIXI.Point(0, 0);
		let viewType: HintViewType;
		let data: HintViewDatas;

		switch (rewardDescription.reward_type) {
			case RewardTypes.TOTEM: {
				const model = this.totemModels.get(rewardDescription.reward_id);

				if (model != null) {
					data = {
						model,
					} as EventCharacterUpgradeDefaultData;
					viewType = HintViewType.EVENT_TOTEM;
				} else {
					data = {
						title: '#hint_random_totem_title',
						description: '#hint_random_totem_descr',
						bodyArrowOffset: 0.5,
					};
					viewType = HintViewType.DEFAULT;
				}
				break;
			}
			case RewardTypes.RESOURCE: {
				data = this.getResourceHintData(rewardDescription);
				viewType = HintViewType.DEFAULT;
				break;
			}
			case RewardTypes.BOOST: {
				data = {
					title: '#hint_boost_title',
					description: '#hint_boost_descr',
					bodyArrowOffset: 0.5,
				};
				viewType = HintViewType.DEFAULT;
				break;
			}
			case RewardTypes.TIMESKIP: {
				data = {
					title: '#hint_timeskip_title',
					description: '#hint_timeskip_descr',
					bodyArrowOffset: 0.5,
				};
				viewType = HintViewType.DEFAULT;
				break;
			}
			case RewardTypes.UPGRADE: {
				const model = this.upgradeModels.get(rewardDescription.reward_id);

				if (model != null) {
					data = {
						model,
					} as EventCharacterUpgradeDefaultData;
					viewType = HintViewType.EVENT_UPGRADE;
				} else {
					data = {
						title: '#hint_random_upgrade_title',
						description: '#hint_random_upgrade_descr',
						bodyArrowOffset: 0.5,
					};
					viewType = HintViewType.DEFAULT;
				}
				break;
			}
			case RewardTypes.CHARACTER: {
				const model = this.characterModels.get(rewardDescription.reward_id);

				if (model != null) {
					const skill = model.hasBonusSkill() ? this.skillModels.get(model.getSkillKey()) : undefined;

					if (skill || model.hasBonusAutomate()) {
						data = {
							character: model,
							skill,
						} as EventLevelCharacterSkillAutoBusinessHintData;
						viewType = HintViewType.EVENT_CHARACTER_SKILL_AUTO_BUSINESS;
					} else {
						data = {
							model: this.characterModels.get(rewardDescription.reward_id),
						} as EventCharacterUpgradeDefaultData;
						viewType = HintViewType.EVENT_CHARACTER;
					}
				} else {
					data = {
						title: '#hint_random_character_title',
						description: '#hint_random_character_descr',
						bodyArrowOffset: 0.5,
					};
					viewType = HintViewType.DEFAULT;
				}
				break;
			}
			default:
				throw new Error(`Unknown reward type: ${rewardDescription.reward_type}`);
		}

		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin,
			viewType,
			runInEpisodeAssets,
		};

		return data ? args : undefined;
	}

	public resolveBusinessCardHintData(hintData: HintDataBusinessCard): GameUIShowHintEventArgs {
		const arrowPosLocal = new PIXI.Point(0, -150);
		let viewType: HintViewType;
		let data: HintViewDatas;

		if (hintData.model instanceof UpgradeModel) {
			data = {
				model: hintData.model,
				bodyArrowOffset: 0.5,
			} as EventCharacterUpgradeDefaultData;
			viewType = HintViewType.EVENT_UPGRADE;
		} else {
			const skill = hintData.model.hasBonusSkill() ? this.skillModels.get(hintData.model.getSkillKey()) : undefined;

			if (skill || hintData.model.hasBonusAutomate()) {
				data = {
					character: hintData.model,
					skill,
					bodyArrowOffset: 0.2,
				} as EventLevelCharacterSkillAutoBusinessHintData;
				viewType = HintViewType.EVENT_CHARACTER_SKILL_AUTO_BUSINESS;
			} else {
				data = {
					model: hintData.model,
					bodyArrowOffset: 0.2,
				} as EventCharacterUpgradeDefaultData;
				viewType = HintViewType.EVENT_CHARACTER;
			}
		}

		const args: GameUIShowHintEventArgs = {
			arrowPosLocal,
			data,
			origin: hintData.origin,
			viewType,
		};

		return args;
	}

	public rewardsDescriptionBundleData(
		rewardDescriptions: RewardDescriptionType[],
		origin: PIXI.DisplayObject,
		runInEpisodeAssets?: boolean,
	): GameUIShowHintEventArgs | undefined {
		const arrowPosLocal = new PIXI.Point(0, 0);

		const data: BankInfoHintData = rewardDescriptions.reduce<Map<string, number | SoftMoneyNumber>>((acc: Map<string, any>, x: RewardDescriptionType) => {
			switch (x.reward_type) {
				case RewardTypes.RESOURCE: {
					let value: SoftMoneyNumber;
					if (typeof x.reward_qty === 'string') {
						value = SoftMoneyNumber.createFromString(x.reward_qty);
					} else {
						value = SoftMoneyNumber.createFromNumber(x.reward_qty);
					}
					if (acc.has(x.reward_id)) {
						value = value.add(acc.get(x.reward_id));
					}
					acc.set(x.reward_id, value);
					break;
				}

				case RewardTypes.UPGRADE: {
					this.updateMapForPromotableModel(acc, this.upgradeModels.get(x.reward_id), x.reward_qty as number);
					break;
				}
				case RewardTypes.CHARACTER: {
					this.updateMapForPromotableModel(acc, this.characterModels.get(x.reward_id), x.reward_qty as number);
					break;
				}
				case RewardTypes.TOTEM: {
					this.updateMapForPromotableModel(acc, this.totemModels.get(x.reward_id), x.reward_qty as number);
					break;
				}

				case RewardTypes.TIMESKIP:
				case RewardTypes.BOOST: {
					let value: number = x.reward_qty as number;
					if (acc.has(x.reward_type)) {
						value += acc.get(x.reward_type);
					}
					acc.set(x.reward_type, value);
					break;
				}

				default:
					throw new Error(`Unsupported RewardType '${x.reward_type}'`);
			}
			return acc;
		}, new Map());

		let args: GameUIShowHintEventArgs;
		if (data.size > 0) {
			args = {
				arrowPosLocal,
				data,
				origin,
				viewType: HintViewType.BANK_INFO,
				runInEpisodeAssets,
			};
		}
		return args;
	}

	// eslint-disable-next-line class-methods-use-this
	private updateMapForPromotableModel(acc: Map<string, number>, model: PromotableModel, value: number): void {
		const rarity = model.getCardRarity();
		let amount = value;
		if (acc.has(rarity)) {
			amount += acc.get(rarity);
		}
		acc.set(rarity, amount);
	}

	// eslint-disable-next-line class-methods-use-this
	private getResourceHintData(rewardDescription: RewardDescriptionType): DefaultHintViewData {
		let title: string;
		let description: string;
		// eslint-disable-next-line default-case
		switch (rewardDescription.reward_id) {
			case RewardResourceIdTypes.HARD_MONEY: {
				title = '#hint_hard_money_title';
				description = '#hint_hard_money_descr';
				break;
			}
			case RewardResourceIdTypes.PRESTIGE_MONEY: {
				title = '#hint_prestige_money_title';
				description = '#hint_prestige_money_descr';
				break;
			}
			case RewardResourceIdTypes.SOFT_MONEY: {
				title = '#hint_soft_money_title';
				description = '#hint_soft_money_descr';
				break;
			}
		}
		return {
			description,
			title,
			bodyArrowOffset: 0.5,
		};
	}
}
