import { ModelsBaseFactory } from './ModelsBaseFactory';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { ModelInstanceDataBase, TutorialModelInstanceData, FarewellPartyModelInstanceData } from '@src/types/ModelInstanceDataTypes';
import CharacterConfig from '@configs/CharacterConfig';
import {
	CharacterSaveData,
	SkillSaveData,
	BusinessSaveData,
	BoostSaveData,
	UpgradeSaveData,
	TimeskipSaveData,
	TotemSaveData,
} from '@src/types/SaveTypes';
import { SkillConfig } from '@configs/SkillConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { BusinessConfig } from '@configs/BusinessConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { PromotePatternsConfig } from '@configs/PromotePatternsConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { VideoConfig } from '@configs/VideoConfig';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { TotemConfig } from '@configs/TotemConfig';
import { TutorialStepsLevelFactory } from '../TutorialStepsLevelFactory';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { NewsModel } from '@models/NewsModel';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { DailyRewardConfig } from '@configs/DailyRewardConfig';
import { PresetConfig } from '@configs/PresetConfig';
import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { PresetsModel } from '@models/PresetsModel';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';
import { SummonSavesModel } from '@models/SummonSavesModel';

export class LevelModelsFactory extends ModelsBaseFactory {
	private readonly tutorialStepsFactory: TutorialStepsLevelFactory;

	constructor(
		serverTime: ServerTimeModel,
		characterModelInstanceData: ModelInstanceDataBase<CharacterConfig, CharacterSaveData>,
		skillModelInstanceData: ModelInstanceDataBase<SkillConfig, SkillSaveData>,
		upgradeModelInstanceData: ModelInstanceDataBase<UpgradeConfig, UpgradeSaveData>,
		businessModelInstanceData: ModelInstanceDataBase<BusinessConfig, BusinessSaveData>,
		boostModelInstanceData: ModelInstanceDataBase<BoostConfig, BoostSaveData>,
		timeskipModelInstanceData: ModelInstanceDataBase<TimeskipConfig, TimeskipSaveData>,
		tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepLevelConfig>,
		promotePatternsConfig: PromotePatternsConfig,
		bankPriceItemsConfig: BankPriceItemsConfig,
		constantsConfig: ConstantsConfig,
		dialogConfigs: Map<string, DialogConfig>,
		private readonly savesConfig: SavesConfig,
		private readonly dailyRewardConfigs: Map<string, DailyRewardConfig>,
		private readonly presetConfigs: Map<string, PresetConfig>,
		isNutaku: boolean,
		galleryVideoModelInstanceData?: ModelInstanceDataBase<VideoConfig, string>,
		epicQuestCollectionsModelInstanceData?: ModelInstanceDataBase<EpicQuestCollectionsConfig, number[]>,
		totemModelInstanceData?: ModelInstanceDataBase<TotemConfig, TotemSaveData>,
		farewellPartyModelInstanceData?: FarewellPartyModelInstanceData,
	) {
		super(
			serverTime,
			characterModelInstanceData,
			skillModelInstanceData,
			upgradeModelInstanceData,
			businessModelInstanceData,
			boostModelInstanceData,
			timeskipModelInstanceData,
			tutorialStepModelInstanceData,
			promotePatternsConfig,
			bankPriceItemsConfig,
			constantsConfig,
			savesConfig.getNewCustomersMultiplierRewards(),
			dialogConfigs,
			savesConfig.getLevelFarewellBoost(),
			savesConfig.getLevelBankSaveData(),
			isNutaku,
			galleryVideoModelInstanceData,
			epicQuestCollectionsModelInstanceData,
			totemModelInstanceData,
			farewellPartyModelInstanceData,
			savesConfig.getLevelNextFarellPartyKey(),
		);

		this.tutorialStepsFactory = new TutorialStepsLevelFactory(
			tutorialStepModelInstanceData.configs,
			tutorialStepModelInstanceData.savesData.completed,
		);
	}

	public getTutorialStepsFactory(): TutorialStepsLevelFactory {
		return this.tutorialStepsFactory;
	}

	// eslint-disable-next-line class-methods-use-this
	public createEventLevelModel(): EventLevelModel {
		return new EventLevelModel();
	}

	// eslint-disable-next-line class-methods-use-this
	public createLevelModel(): LevelModel {
		return new LevelModel();
	}

	public createBankPaymentSaveModel(): BankPaymentSaveModel {
		return new BankPaymentSaveModel(this.savesConfig.getPaymentSaveData());
	}

	public createNewsModel(): NewsModel {
		return new NewsModel(
			this.savesConfig.getNewsData(),
			this.savesConfig.getCreatedOn(),
			this.serverTime,
			this.constantsConfig.getNewsMaxAmount(),
		);
	}

	public createDailyRewardsModel(): DailyRewardsModel {
		return new DailyRewardsModel(
			this.dailyRewardConfigs,
			this.constantsConfig.getDailyRewardConstants(),
		);
	}

	public createPresetsModel(levelModel: LevelModel): PresetsModel {
		return new PresetsModel(
			this.presetConfigs,
			levelModel,
		);
	}

	public createEventTutorialStepsModel(): EventTutorialStepsModel {
		return new EventTutorialStepsModel(
			this.tutorialStepModelInstanceData.eventSavesData,
		);
	}

	public createSummonSavesModel(): SummonSavesModel {
		return new SummonSavesModel(
			this.savesConfig.getLevelSummonSaveData(),
		);
	}

	public createEventSummonSavesModel(): SummonSavesModel {
		return new SummonSavesModel(
			this.savesConfig.getEventLevelSummonSaveData(),
		);
	}
}
