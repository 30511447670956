import { BankTabsConfigSetParser } from '@configs/configsParsers/configSets/bank/BankTabsConfigSetParser';
import { BankBundlesConfigSetParser } from '@configs/configsParsers/configSets/bank/BankBundlesConfigSetParser';
import { BankSubscribesConfigSetParser } from '@configs/configsParsers/configSets/bank/BankSubscribesConfigSetParser';
import { BankOffersConfigSetParser } from '@configs/configsParsers/configSets/bank/BankOffersConfigSetParser';
import { BankRechargeConfig } from '../bank/BankRechargeConfig';
import { BankTabConfig } from '../bank/BankTabConfig';
import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { BankGemShopItemConfig } from '@configs/bank/BankGemShopItemConfig';
import { BankSubscribeConfig } from '@configs/bank/BankSubscribeConfig';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';
import { BankBundleGroupConfig } from '@configs/bank/BankBundleGroupConfig';
import { BankBundleGroupsConfigSetParser } from './configSets/bank/BankBundleGroupsConfigSetParser';
import { BankRechargeConfigSetParser } from './configSets/bank/BankRechargeConfigSetParser';
import { BankGemShopConfigSetParser } from './configSets/bank/BankGemShopConfigSetParser';

export class BankConfigsParser {
	private readonly tabsConfigSetParser: BankTabsConfigSetParser;
	private readonly gemShopConfigSetParser: BankGemShopConfigSetParser;
	private readonly bundlesConfigSetParser: BankBundlesConfigSetParser;
	private readonly subscribesConfigSetParser: BankSubscribesConfigSetParser;
	private readonly offersConfigSetParser: BankOffersConfigSetParser;
	private readonly bundleGroupConfigSetParser: BankBundleGroupsConfigSetParser;
	private readonly rechargeConfigSetParser: BankRechargeConfigSetParser;

	constructor() {
		this.tabsConfigSetParser = new BankTabsConfigSetParser(BankTabConfig.FIELD_KEY, BankTabConfig);
		this.offersConfigSetParser = new BaseConfigSetParser(BankOfferConfig.FIELD_KEY, BankOfferConfig);
		this.bundleGroupConfigSetParser = new BaseConfigSetParser(BankBundleGroupConfig.FIELD_KEY, BankBundleGroupConfig);
		this.gemShopConfigSetParser = new BaseConfigSetParser(BankGemShopItemConfig.FIELD_KEY, BankGemShopItemConfig);
		this.subscribesConfigSetParser = new BaseConfigSetParser(BankSubscribeConfig.FIELD_KEY, BankSubscribeConfig);
		this.bundlesConfigSetParser = new BaseConfigSetParser(BankBundleConfig.FIELD_KEY, BankBundleConfig);
		this.rechargeConfigSetParser = new BaseConfigSetParser(BankRechargeConfig.FIELD_KEY, BankRechargeConfig);
	}

	public parseBankConfigs(
		bankTabsConfigs: { [key: string]: string }[],
		gemShopConfigs: { [key: string]: string }[],
		rechargeConfigs: { [key: string]: string }[],
		bundleConfigs: { [key: string]: string }[],
		subscribeConfigs: { [key: string]: string }[],
		offersConfigs: { [key: string]: string }[],
		bundleGroupsConfigs: { [key: string]: string }[],
	): void {
		this.tabsConfigSetParser.clear();
		this.gemShopConfigSetParser.clear();
		this.bundlesConfigSetParser.clear();
		this.subscribesConfigSetParser.clear();
		this.offersConfigSetParser.clear();
		this.bundleGroupConfigSetParser.clear();
		this.rechargeConfigSetParser.clear();

		this.tabsConfigSetParser.parseConfigSet(bankTabsConfigs);

		// Bank items
		this.gemShopConfigSetParser.parseConfigSet(gemShopConfigs);

		// Recharge
		this.rechargeConfigSetParser.parseConfigSet(rechargeConfigs);

		// Bundles
		this.bundlesConfigSetParser.parseConfigSet(bundleConfigs);

		// Subscribes
		this.subscribesConfigSetParser.parseConfigSet(subscribeConfigs);

		// Offers
		this.offersConfigSetParser.parseConfigSet(offersConfigs);

		// Bundle Groups
		this.bundleGroupConfigSetParser.parseConfigSet(bundleGroupsConfigs);
	}

	public getSubscribesConfigs(): Map<string, BankSubscribeConfig> {
		return this.subscribesConfigSetParser.getConfigSet();
	}

	public getGemShopConfigs(): Map<string, BankGemShopItemConfig> {
		return this.gemShopConfigSetParser.getConfigSet();
	}

	public getBundlesConfigs(): Map<string, BankBundleConfig> {
		return this.bundlesConfigSetParser.getConfigSet();
	}

	public getBankTabsConfigs(): Map<string, BankTabConfig> {
		return this.tabsConfigSetParser.getConfigSet();
	}

	public getOffersConfigs(): Map<string, BankOfferConfig> {
		return this.offersConfigSetParser.getConfigSet();
	}

	public getBundleGroupsConfigs(): Map<string, BankBundleGroupConfig> {
		return this.bundleGroupConfigSetParser.getConfigSet();
	}

	public getRechargeConfigs(): Map<string, BankRechargeConfig> {
		return this.rechargeConfigSetParser.getConfigSet();
	}
}
