import { BehavioursBaseFactory } from './BehavioursBaseFactory';
import { LevelModelInstance } from '@main/modelInstance/LevelModelInstance';
import { ModelWriterTotem } from '@src/modelWriters/ModelWriterTotem';
import { ModelWriterPromotable } from '@src/modelWriters/ModelWriterPromotable';
import { ModelWriterLevel } from '@src/modelWriters/ModelWriterLevel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { LevelChallengeTicker } from '@src/tickers/LevelChallengeTicker';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { TimedQuestsTicker } from '@src/tickers/TimedQuestsTicker';
import { TimedQuestsLoader } from '@src/loaders/TimedQuestsLoader';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { QuestsLoader } from '@src/loaders/QuestsLoader';
import { TimedQuestsEmitter } from '@src/emitters/TimedQuestsEmitter';
import { TimedQuestUnlockTypeCollectionWindowClosedModel, TimedQuestUnlockTypeZoomOutBusinessModel } from '@models/quests/timed/TImedQuestUnlockTypeModels';
import { LevelGameUIViewController } from '@src/viewControllers/mainUI/LevelGameUIViewController';
import { TotemPromoteWindowViewController } from '@src/viewControllers/TotemPromoteWindowViewController';
import { EpicQuestCollectionsProgressViewController } from '@src/viewControllers/EpicQuestCollectionsProgressViewController';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { GameProfileModel } from '@models/GameProfileModel';
import { CheatModel } from '@models/CheatModel';
import { TutorialStepUnlockTypeLevelFactory } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypesLevelFactory';
import { TutorialStepWindowViewTargetLevelDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewLevelTargetDispatcher';
import { SettingsWindowViewController } from '@src/viewControllers/SettingsWindowViewController';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { CollectEventRewardsWindowViewController } from '@src/viewControllers/CollectEventRewardsWindowViewController';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { GoToEventLevelWindowEmitter } from '@src/emitters/GoToEventLevelWindowEmitter';
import { TimedQuestInitData, TransactionSaveData } from '@src/types/SaveTypes';
import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { ResendConfirmationEmailCodeTicker } from '@src/tickers/ResendConfirmationEmailCodeTicker';
import { LevelWindowViewFactory } from '../windows/LevelWindowViewFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BoostModel } from '@models/BoostModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { EmittersController } from '@src/viewControllers/mainUI/EmittersController';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { BaseHintViewController } from '@src/viewControllers/BaseHintViewController';
import { BankLevelConfigsLoader } from '@src/loaders/bank/BankLevelConfigsLoader';
import { ModelWriterBusinessIncomePerSec } from '@src/modelWriters/ModelWriterBusinessIncomePerSec';
import { LevelQuestWindowViewController } from '@src/viewControllers/LevelQuestWindowViewController';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { BankGemShopItemsFactory } from '../bank/BankGemShopItemsFactory';
import { BankBundlesFactory } from '../bank/BankBundlesFactory';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { SummonLevelConfigsLoader } from '@src/loaders/summon/SummonLevelConfigsLoader';
import { UnlockValueChecker } from '@src/loaders/UnlockValueChecker';
import { NewsEmitter } from '@src/emitters/NewsEmitter';
import { NewsLoader } from '@src/loaders/NewsLoader';
import { BaseNewsWindowViewController } from '@src/viewControllers/BaseNewsWindowViewController';
import { LevelRewardsLoader } from '@src/loaders/LevelRewardsLoader';
import { DailyRewardWindowViewController } from '@src/viewControllers/DailyRewardWindowViewController';
import { DailyRewardEmitter } from '@src/emitters/DailyRewardEmitter';
import { DailyRewardLoader } from '@src/loaders/DailyRewardLoader';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

export class LevelBehavioursFactory extends BehavioursBaseFactory<LevelModelInstance> {
	private readonly unlockValueChecker: UnlockValueChecker;

	constructor(
		modelInstance: LevelModelInstance,
		networkRequestSender: NetworkRequestSender,
		cheatModel: CheatModel,
		gameProfileModel: GameProfileModel,
		bankPaymentSaveModel: BankPaymentSaveModel,
		private readonly eventLevelModel: EventLevelModel,
		private readonly levelStartDialogShowed: boolean,
		private readonly timedQuestInitData: TimedQuestInitData,
		transactionsSaveData: TransactionSaveData[],
		serverTime: ServerTimeModel,
		constantsConfig: ConstantsConfig,
		savesConfig: SavesConfig,
		private readonly questConfigs: Map<string, QuestConfigBase>,
		private readonly tutorialStepConfigs: Map<string, TutorialStepLevelConfig>,
		businessesLastUpdate: Map<string, number>,
		private readonly compensationLootboxConfigs: LootboxServerConfig[],
		windowViewSystem: WindowViewSystem,
		private readonly windowFactory: LevelWindowViewFactory,
		hintSpawner: ShowHintSpawner,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly farewellBoostModel: FarewellBoostModel,
		farewellPartyEverStarted: boolean,
		isNutaku: boolean,
		assetUrlsLoader: AssetUrlsLoader,
	) {
		super(
			modelInstance,
			windowViewSystem,
			windowFactory,
			hintSpawner,
			networkRequestSender,
			cheatModel,
			gameProfileModel,
			serverTime,
			constantsConfig,
			savesConfig,
			bankPaymentSaveModel,
			transactionsSaveData,
			businessesLastUpdate,
			farewellPartyEverStarted,
			isNutaku,
			assetUrlsLoader,
		);

		this.unlockValueChecker = new UnlockValueChecker(
			this.serverTimeModel,
			this.modelInstance.getBankSavesModel(),
			this.bankPaymentSaveModel,
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getTutorialStepModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getLevelChallengeModel(),
			this.gameProfileModel,
			this.modelInstance.getSummonSavesModel(),
			this.modelInstance.getLevelModel(),
			this.eventLevelModel,
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
		);
	}

	public createModelWriterBusinessIncomePerSec(): ModelWriterBusinessIncomePerSec {
		return new ModelWriterBusinessIncomePerSec(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getTimeskipModels(),
		);
	}

	public createBaseHintViewController(): BaseHintViewController {
		return new BaseHintViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.hintSpawner,
			this.hintResolver,
			this.constantsConfig.getShowQuestTargetDuration(),
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getSkillModels(),
		);
	}

	public createTutorialStepsViewTargetGameDispatcher(): TutorialStepWindowViewTargetLevelDispatcher {
		return new TutorialStepWindowViewTargetLevelDispatcher(
			this.windowViewSystem,
			this.hintSpawner,
			this.modelInstance.getSummonModelsPool(),
		);
	}

	public createTutorialStepUnlockTypeFactory(): TutorialStepUnlockTypeLevelFactory {
		const tutorialStepUnlockTypeConfigs = Array
			.from(this.tutorialStepConfigs.values())
			.map(config => config.getUnlockTypeConfig());

		return new TutorialStepUnlockTypeLevelFactory(
			this.windowViewSystem,
			tutorialStepUnlockTypeConfigs,
			this.modelInstance.getLevelModel(),
			this.modelInstance.getTutorialStepModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getCharacterModels(),
		);
	}

	public createModelWriterTotem(): ModelWriterTotem {
		return new ModelWriterTotem(
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getFarewellPartyModels(),
		);
	}

	public createModelWriterPromotable(): ModelWriterPromotable {
		return new ModelWriterPromotable(
			this.modelInstance.getEpicQuestCollectionsModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
		);
	}

	public createModelWriterLevel(): ModelWriterLevel {
		return new ModelWriterLevel(
			this.modelInstance.getLevelModel(),
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getLevelChallengeModel(),
		);
	}

	public createResendEmailConfirmationCodeTicker(): ResendConfirmationEmailCodeTicker {
		return new ResendConfirmationEmailCodeTicker(
			this.gameProfileModel,
		);
	}

	public createLevelChallengeTicker(): LevelChallengeTicker {
		return new LevelChallengeTicker(
			this.modelInstance.getLevelChallengeModel(),
			this.serverTimeModel,
		);
	}

	public createTimedQuestsTicker(): TimedQuestsTicker {
		return new TimedQuestsTicker(
			this.modelInstance.getTimedQuestLinesModel(),
		);
	}

	public createTimedQuestsLoader(): TimedQuestsLoader {
		return new TimedQuestsLoader(
			this.questConfigs,
			this.networkRequestSender,
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getQuestFactory(),
			this.modelInstance.getLootboxFactory(),
			this.modelInstance.getLevelModel(),
			this.serverTimeModel,
			this.constantsConfig.getTimedQuestsMinLevel(),
			this.timedQuestInitData,
		);
	}

	public createQuestsLoader(): QuestsLoader {
		return new QuestsLoader(
			this.questConfigs,
			this.networkRequestSender,
			this.modelInstance.getQuestFactory(),
			this.modelInstance.getLootboxFactory(),
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getLevelModel(),
		);
	}

	public createLevelRewardsLoader(): LevelRewardsLoader {
		return new LevelRewardsLoader(
			this.networkRequestSender,
			this.modelInstance.getLevelChallengeModel(),
		);
	}

	public createSummonLoader(): SummonLoader {
		const configsLoader = new SummonLevelConfigsLoader(
			this.networkRequestSender,
		);

		return new SummonLoader(
			this.serverTimeModel,
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getSummonSavesModel(),
			this.unlockValueChecker,
			configsLoader,
		);
	}

	public createBankLoader(
		transactionCreator: TransactionCreator,
	): BankLoader {
		const configsLoader = new BankLevelConfigsLoader(
			this.networkRequestSender,
		);
		const bundlesFactory = new BankBundlesFactory(this.isNutaku);
		const gemShopItemsFactory = new BankGemShopItemsFactory(this.isNutaku);

		const bankLoader = new BankLoader(
			configsLoader,
			this.unlockValueChecker,
			this.modelInstance.getBankSavesModel(),
			this.modelInstance.getBankModel(),
			this.networkRequestSender,
			transactionCreator,
			this.serverTimeModel,
			bundlesFactory,
			gemShopItemsFactory,
			this.modelInstance.getRewardFactory(),
			this.transactionsSaveData,
			this.isNutaku ? this.constantsConfig.getNutakuPriceConversionCoeff() : 1,
			this.modelInstance.getTutorialStepsEmitter(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getLevelModel(),
			this.modelInstance.getQuestLinesModel(),
			this.constantsConfig.getBankLoaderConstants(),
		);

		return bankLoader;
	}

	public createTimedQuestsEmitter(): TimedQuestsEmitter {
		return new TimedQuestsEmitter(
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getLevelModel(),
			[
				new TimedQuestUnlockTypeCollectionWindowClosedModel(this.windowViewSystem),
				new TimedQuestUnlockTypeZoomOutBusinessModel(),
			],
			this.constantsConfig.getTimedQuestsMinLevel(),
		);
	}

	public createGoToEventLevelWindowEmitter(): GoToEventLevelWindowEmitter {
		return new GoToEventLevelWindowEmitter(
			this.eventLevelModel,
		);
	}

	public createLevelQuestWindowViewController(
		questsLoader: QuestsLoaderBase,
		timedQuestsLoader: TimedQuestsLoader,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetLevelDispatcher,
	): LevelQuestWindowViewController {
		return new LevelQuestWindowViewController(
			this.cheatModel,
			this.modelInstance.getLevelModel(),
			timedQuestsLoader,
			questsLoader,
			this.gameProfileModel,
			this.modelInstance.getDialogsFactory(),
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getTutorialStepsEmitter(),
			tutorialStepsViewTargetDispatcher,
			this.windowViewSystem,
			this.windowFactory,
		);
	}

	public createGameUIViewController(
		timedQuestsEmitter: TimedQuestsEmitter,
		bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		goToEventLevelWindowEmitter: GoToEventLevelWindowEmitter,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetLevelDispatcher,
		emittersController: EmittersController,
		questsLoader: QuestsLoader,
		timedQuestsLoader: TimedQuestsLoader,
		bankLoader: BankLoader,
		newsEmitter: NewsEmitter,
		dailyRewardEmitter: DailyRewardEmitter,
	): LevelGameUIViewController {
		return new LevelGameUIViewController(
			this.networkRequestSender,
			this.cheatModel,
			this.modelInstance.getLevelModel(),
			this.modelInstance.getLevelChallengeModel(),
			questsLoader,
			this.modelInstance.getQuestLinesModel(),
			timedQuestsLoader,
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getDialogsFactory(),
			this.eventLevelModel,
			this.modelInstance.getLootboxFactory(),
			this.modelInstance.getBackToGameRewardModel(),
			timedQuestsEmitter,
			this.modelInstance.getTutorialStepsEmitter(),
			bankOfferWindowsEmitter,
			goToEventLevelWindowEmitter,
			tutorialStepsViewTargetDispatcher,
			emittersController,
			bankLoader,
			this.levelStartDialogShowed,
			this.modelInstance.getRewardFactory(),
			this.compensationLootboxConfigs,
			this.windowViewSystem,
			this.windowViewFactory as LevelWindowViewFactory,
			this.boostModels,
			this.farewellBoostModel,
			newsEmitter,
			dailyRewardEmitter,
		);
	}

	public createTotemPromoteWindowViewController(): TotemPromoteWindowViewController {
		return new TotemPromoteWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getTotemModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getSummonModelsPool(),
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	public createEpicQuestCollectionsProgressViewController(): EpicQuestCollectionsProgressViewController {
		return new EpicQuestCollectionsProgressViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.networkRequestSender,
			this.modelInstance.getLootboxFactory(),
			this.modelInstance.getEpicQuestCollectionsModels(),
		);
	}

	public createSettingsWindowViewController(): SettingsWindowViewController {
		return new SettingsWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.networkRequestSender,
			this.gameProfileModel,
		);
	}

	public createCollectEventRewardsWindowViewController(): CollectEventRewardsWindowViewController {
		return new CollectEventRewardsWindowViewController(
			this.eventLevelModel,
		);
	}

	public createNewsLoader(): NewsLoader {
		return new NewsLoader(
			this.modelInstance.getNewsModel(),
			this.networkRequestSender,
			this.constantsConfig.getNewsUpdateInterval(),
			this.constantsConfig.getNewsEnabled(),
		);
	}

	public createNewsEmitter(): NewsEmitter {
		return new NewsEmitter(
			this.modelInstance.getNewsModel(),
			this.modelInstance.getLevelModel(),
			this.constantsConfig.getNewsPushingStartLevel(),
		);
	}

	public createDailyRewardEmitter(): DailyRewardEmitter {
		return new DailyRewardEmitter(
			this.modelInstance.getDailyRewardsModel(),
			this.modelInstance.getLevelModel(),
		);
	}

	public createDailyRewardLoader(): DailyRewardLoader {
		return new DailyRewardLoader(
			this.networkRequestSender,
			this.modelInstance.getDailyRewardsModel(),
			this.serverTimeModel,
		);
	}

	public createBaseNewsWindowViewController(): BaseNewsWindowViewController {
		return new BaseNewsWindowViewController(
			this.windowViewFactory,
			this.windowViewSystem,
			this.modelInstance.getNewsModel(),
			this.modelInstance.getRewardFactory(),
		);
	}

	public createDailyRewardWindowViewController(): DailyRewardWindowViewController {
		return new DailyRewardWindowViewController(
			this.windowViewFactory,
			this.windowViewSystem,
			this.modelInstance.getRewardFactory(),
			this.networkRequestSender,
			this.modelInstance.getPresetsModel(),
			this.modelInstance.getDailyRewardsModel(),
		);
	}
}
