import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { BankOfferLinesUIView } from '@views/ui/bankOffers/BankOfferLinesUIView';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { BankOfferWindowOriginType, BankOfferMoveFromOfferWindowType, BankOfferWindowOriginTypes } from '@src/types/BankTypes';

export type EventBankOfferLinesUIClickEventArgs = {
	offerKey: string;
};

export type EventBankOfferWindowShowEventArgs = {
	offerKey: string;
	origin: string;
};

export type EventBankGoToBankFromOfferEventArgs = {
	offerKey: string;
};

export type EventBankGoToBankAfterLinesUIEventArgs = {
	offerKey: string;
};

export class AnalyticSourceBankViews extends PIXI.utils.EventEmitter {
	public static readonly EVENT_BANK_OFFER_LINES_UI_CLICK: symbol = Symbol();
	public static readonly EVENT_BANK_OFFER_WINDOW_SHOW: symbol = Symbol();

	public static readonly EVENT_BANK_GO_TO_BANK_FROM_OFFER: symbol = Symbol();
	public static readonly EVENT_BANK_GO_TO_BANK_AFTER_LINES_UI: symbol = Symbol();

	public setBankOfferLinesUIView(view: BankOfferLinesUIView): void {
		view.on(BankOfferLinesUIView.EVENT_CLICK, this.onBankOfferInBankLinesUIClick, this);
	}

	public setBankOfferWindowView(view: BankOfferWindowView): void {
		view.once(BankOfferWindowView.EVENT_BUTTON_BUY_CLICK, (
			bankTabElementWithOffer: BankTabElementWithOfferType,
			bankOfferModel: BankOfferModel,
			bankOfferWindowOrigin: BankOfferWindowOriginType,
		) => {
			if (bankOfferModel.getMoveFromOfferWindowType() === BankOfferMoveFromOfferWindowType.BANK_WINDOW) {
				const args: EventBankGoToBankFromOfferEventArgs = {
					offerKey: bankOfferModel.getKey(),
				};
				this.emit(AnalyticSourceBankViews.EVENT_BANK_GO_TO_BANK_FROM_OFFER, args);

				if (bankOfferWindowOrigin === BankOfferWindowOriginTypes.BANK_OFFER_LINES_UI) {
					const linesUIArgs: EventBankGoToBankAfterLinesUIEventArgs = {
						offerKey: bankOfferModel.getKey(),
					};
					this.emit(AnalyticSourceBankViews.EVENT_BANK_GO_TO_BANK_AFTER_LINES_UI, linesUIArgs);
				}
			}
		}, this);

		const args: EventBankOfferWindowShowEventArgs = {
			offerKey: view.getBankOfferKey(),
			origin: view.getBankOfferWindowOrigin(),
		};
		this.emit(AnalyticSourceBankViews.EVENT_BANK_OFFER_WINDOW_SHOW, args);
	}

	private onBankOfferInBankLinesUIClick(bankOfferModel: BankOfferModel): void {
		const offerKey: string = bankOfferModel.getKey();

		const args: EventBankOfferLinesUIClickEventArgs = {
			offerKey,
		};
		this.emit(AnalyticSourceBankViews.EVENT_BANK_OFFER_LINES_UI_CLICK, args);
	}
}
