import { ScrollOffset, ScrollAxis } from '@src/types/ScrollViewTypes';
import * as TWEEN from '@tweenjs/tween.js';

export class ScrollView extends PIXI.Container {
	private viewportWidth: number;
	private viewportHeight: number;
	protected content: PIXI.Container;
	private prevPosition!: PIXI.Point;
	private movementThreshold: number;
	private axis: ScrollAxis;
	private maxScrollPosition: number;
	protected ticker: PIXI.ticker.Ticker;
	private isScrolling: boolean;
	private readonly scrollOffset: ScrollOffset;
	private readonly enableOverShoot: boolean;
	private readonly mouseWheelBind: (ev: WheelEvent) => void;
	private clampValue: PIXI.Point;
	private wheelScrollDirection: number;
	private movementDelta: number;
	private mouseScrollValue: number;
	private tween: TWEEN.Tween;
	private scrollTime: number;
	private scrollSpeed: number;
	private maxScrollTime: number;
	private scrollSpeedFactor: number;

	constructor(
		viewportWidth: number,
		viewportHeight: number,
		axis: ScrollAxis,
		scrollOffset: ScrollOffset,
		enableMask: boolean = true,
		enableOverShoot: boolean = true,
	) {
		super();

		this.viewportWidth = viewportWidth;
		this.viewportHeight = viewportHeight;
		this.axis = axis;
		this.scrollOffset = scrollOffset;

		this.prevPosition = new PIXI.Point();

		if (enableMask) {
			this.mask = this.createMask();
			this.addChild(this.mask);
		}

		this.isScrolling = false;
		this.mouseWheelBind = this.onMouseWheel.bind(this);

		this.ticker = PIXI.ticker.shared;
		this.interactive = true;
		this.enableOverShoot = enableOverShoot;
		this.wheelScrollDirection = -1;
		this.movementThreshold = 0;
		this.mouseScrollValue = 0;
		this.scrollTime = 0;
		this.maxScrollTime = 80;
		this.scrollSpeed = 30;

		this.animateScroll = this.animateScroll.bind(this);
	}

	// Public

	public setWheelScrollDirection(direction: number): this {
		this.wheelScrollDirection = direction;
		return this;
	}

	public setScrollSpeed(value: number): this {
		this.scrollSpeed = value;
		return this;
	}

	public setMaxScrollTime(value: number): this {
		this.maxScrollTime = value;
		return this;
	}

	public setMovementThreshold(value: number): this {
		this.movementThreshold = value;
		return this;
	}

	public hasContent(): boolean {
		return Boolean(this.content);
	}

	public getIsScrolling(): boolean {
		return this.isScrolling;
	}

	public jumpTo(value: number): void {
		const jumpValue: number = Math.max(Math.min(this.scrollOffset.begin, value), -Math.max(this.maxScrollPosition, 0));
		switch (this.axis) {
			case ScrollAxis.HORIZONTAL: {
				this.content.position.set(jumpValue, 0);
				break;
			}

			case ScrollAxis.VERTICAL: {
				this.content.position.set(0, jumpValue);
				break;
			}

			default:
				break;
		}
	}

	public enable(): void {
		this.interactive = true;
	}

	public disable(): void {
		this.unsubscribe();
		this.interactive = false;
		this.interactiveChildren = true;
		this.isScrolling = false;
		this.tween?.stop();
		this.mouseScrollValue = 0;
		this.scrollTime = 0;
	}

	public updateBounds(): void {
		this.maxScrollPosition = this.calculateMaxScrollValue(this.scrollOffset);
		this.updateListeners();
	}

	public resetScrollValue(): void {
		if (this.content) {
			this.jumpTo(this.scrollOffset.begin);
		}
	}

	public setContent(content: PIXI.Container): this {
		if (this.content) {
			this.removeChild(this.content);
		}
		this.content = content;
		if (!this.clampValue) {
			this.maxScrollPosition = this.calculateMaxScrollValue(this.scrollOffset);
		}
		this.addChild(this.content);
		this.updateListeners();
		return this;
	}

	public removeContent(): void {
		this.removeChild(this.content);
		this.content = null;
	}

	public setClamp(begin: number, end: number): this {
		this.clampValue = new PIXI.Point(begin, end);
		this.maxScrollPosition = end - this.viewportWidth;
		this.scrollOffset.begin = -begin;
		return this;
	}

	public isDragEnabled(): boolean {
		return this.interactive;
	}

	public setDragEnabled(value: boolean): this {
		if (value) {
			this.enable();
		} else {
			this.disable();
		}
		return this;
	}

	// Events

	protected onPointerOver(): void {
		document.addEventListener('wheel', this.mouseWheelBind, { passive: false });
	}

	protected onPointerDown(ev: PIXI.interaction.InteractionEvent): void {
		this.prevPosition = ev.data.global.clone();
		this.movementDelta = 0;
		this.tween?.stop();
		this.scrollTime = 0;

		this.on('pointermove', this.onPointerMove, this);
		this.once('pointerup', this.onPointerUp, this);
	}

	protected onPointerMove(ev: PIXI.interaction.InteractionEvent): void {
		const point: PIXI.Point = ev.data.global;
		const dx: number = point.x - this.prevPosition.x;
		const dy: number = point.y - this.prevPosition.y;

		if (Math.abs(this.movementDelta) >= this.movementThreshold) {
			if (!this.isScrolling) {
				this.isScrolling = true;
			}
			this.updateScrollValue(dx, dy);
		} else {
			this.movementDelta += this.axis === ScrollAxis.HORIZONTAL ? dx : dy;
		}

		this.prevPosition.copy(point);
	}

	protected onPointerOut(): void {
		document.removeEventListener('wheel', this.mouseWheelBind);
		this.cleanUpPointerMove();
	}

	protected onPointerUp(): void {
		this.cleanUpPointerMove();

		if (!this.tween?.isPlaying() && this.movementDelta != null
			&& Math.abs(this.movementDelta) >= this.movementThreshold) {
			// Inertia on tap should have fixed short time
			this.addScrollInertia(this.movementDelta, 80);
		}
	}

	private cleanUpPointerMove(): void {
		this.removeListener('pointermove', this.onPointerMove, this);
		this.removeListener('pointerup', this.onPointerMove, this);
		this.removeListener('pointerout', this.onPointerMove, this);
		this.createAfterMovement();
	}

	protected onMouseWheel(ev: WheelEvent): void {
		if (this.interactive) {
			if (this.tween?.isPlaying()) {
				this.tween.stop();
			}
			// eslint-disable-next-line no-bitwise
			this.addScrollInertia(this.wheelScrollDirection * (ev.deltaY ^ ev.deltaX), this.maxScrollTime);
			ev.preventDefault();
		}
	}

	private addScrollInertia(scrollValue: number, maxScrollTime: number): void {
		const scrollTimeDelta = 40;
		const defaultSpeedFactor = 0.3;
		const speedFactorDelta = 0.2;
		const maxSpeedFactor = 1;

		this.isScrolling = true;
		this.mouseScrollValue = scrollValue;

		if (this.scrollTime === 0) {
			this.scrollTime = maxScrollTime;
			this.scrollSpeedFactor = defaultSpeedFactor;
			requestAnimationFrame(this.animateScroll);
		} else {
			this.scrollTime = Math.min(maxScrollTime, this.scrollTime + scrollTimeDelta);
			this.scrollSpeedFactor = Math.min(maxSpeedFactor, this.scrollSpeedFactor + speedFactorDelta);
		}
	}

	private animateScroll(): void {
		const scrollDelta = this.scrollSpeed / 7;

		// TODO: this.content.transform null check is a temporary fix
		// research why it's null in certain conditions
		if (!this.tween?.isPlaying() && this.isScrolling && this.content.transform != null) {
			if (this.scrollTime > 0) {
				this.scrollTime = Math.max(0, this.scrollTime - scrollDelta);

				const delta = Math.sign(this.mouseScrollValue) * this.scrollSpeed * this.scrollSpeedFactor;

				this.updateScrollValue(delta, delta);
				requestAnimationFrame(this.animateScroll);
			} else {
				this.createAfterMovement();
			}
		}
	}

	// Scrolling

	protected updateScrollValue(dx: number, dy: number): void {
		switch (this.axis) {
			case ScrollAxis.VERTICAL: {
				this.content.y = this.enableOverShoot
					? this.applyScrollOvershootTenshion(this.content.y, dy, this.viewportHeight * 0.15)
					: this.applyScroll(this.content.y + dy, this.maxScrollPosition);
				break;
			}

			case ScrollAxis.HORIZONTAL: {
				this.content.x = this.enableOverShoot
					? this.applyScrollOvershootTenshion(this.content.x, dx, this.viewportWidth * 0.15)
					: this.applyScroll(this.content.x + dx, this.maxScrollPosition);
				break;
			}

			default:
				break;
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private applyScroll(value: number, max: number): number {
		return -Math.max(Math.min(max, -value), 0);
	}

	private applyScrollOvershootTenshion(value: number, delta: number, maxValue: number): number {
		const overshoot: number = Math.abs(this.getOvershoot(value));
		let scrollValue = value;
		if (overshoot !== 0) {
			const k = 1 - overshoot / maxValue;
			scrollValue += delta * k * k / 3;
		} else {
			scrollValue += delta;
		}
		return scrollValue;
	}

	private tryCreateReturnTween(): void {
		let overshoot: number;
		switch (this.axis) {
			case ScrollAxis.HORIZONTAL: {
				overshoot = this.getOvershoot(this.content.x);
				if (overshoot !== 0) {
					const x: number = overshoot > 0 ? this.scrollOffset.begin : -this.maxScrollPosition;
					this.tween = new TWEEN.Tween(this.content)
						.to({ x }, 200)
						.easing(TWEEN.Easing.Back.Out)
						.onComplete(() => {
							this.isScrolling = false;
						})
						.start();
				} else {
					this.isScrolling = false;
				}
				break;
			}

			case ScrollAxis.VERTICAL: {
				overshoot = this.getOvershoot(this.content.y);
				if (overshoot !== 0) {
					const y: number = overshoot > 0 ? this.scrollOffset.begin : -this.maxScrollPosition;
					this.tween = new TWEEN.Tween(this.content)
						.to({ y }, 200)
						.easing(TWEEN.Easing.Back.Out)
						.onComplete(() => {
							this.isScrolling = false;
						})
						.start();
				} else {
					this.isScrolling = false;
				}
				break;
			}

			default:
				break;
		}
	}

	private createAfterMovement(): void {
		this.tween?.stop();
		this.scrollTime = 0;

		if (this.enableOverShoot) {
			this.tryCreateReturnTween();
		} else {
			this.isScrolling = false;
		}
	}

	// Helpers Methods

	private updateListeners(): void {
		if (this.maxScrollPosition > 0) {
			if (!this.listeners('pointerdown').includes(this.onPointerDown)) {
				this.on('pointerdown', this.onPointerDown, this);
			}
			if (!this.listeners('pointerover').includes(this.onPointerOver)) {
				this.on('pointerover', this.onPointerOver, this);
			}
			if (!this.listeners('pointerout').includes(this.onPointerOut)) {
				this.on('pointerout', this.onPointerOut, this);
			}
		} else {
			this.off('pointerdown', this.onPointerDown, this);
			this.off('pointerover', this.onPointerOver, this);
			this.off('pointerout', this.onPointerOut, this);
		}
	}

	private unsubscribe(): void {
		this.off('pointermove', this.onPointerMove, this);
		this.off('pointerup', this.onPointerUp, this);
	}

	private createMask(): PIXI.Graphics {
		return new PIXI.Graphics()
			.beginFill(0x00FF00)
			.drawRect(0, 0, this.viewportWidth, this.viewportHeight)
			.endFill();
	}

	private calculateMaxScrollValue(offset: ScrollOffset): number {
		let value: number;

		switch (this.axis) {
			case ScrollAxis.HORIZONTAL: {
				value = this.content.width - this.viewportWidth + offset.end;
				break;
			}

			case ScrollAxis.VERTICAL: {
				value = this.content.height - this.viewportHeight + offset.end;
				break;
			}

			default:
				break;
		}
		return value;
	}

	private getOvershoot(scrollValue: number): number {
		let overshoot: number = 0;

		if (this.enableOverShoot && scrollValue > this.scrollOffset.begin) {
			overshoot = scrollValue - this.scrollOffset.begin;
		} else if (this.enableOverShoot && scrollValue < -this.maxScrollPosition) {
			overshoot = scrollValue + this.maxScrollPosition;
		}

		if (Math.abs(overshoot) < 1) {
			overshoot = 0;
		}

		return overshoot;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		document.removeEventListener('wheel', this.mouseWheelBind);
		this.tween?.stop();
		this.removeAllListeners();

		super.destroy(options);
	}
}
