import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class LevelRewardsLoader {
	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly levelChallengeModel: LevelChallengeModel,
	) {}

	public async init(currentLevel: number): Promise<void> {
		const result: Record<string, Record<string, RewardDescriptionType>> = await this.networkRequestSender.sendGetLevelRewards(currentLevel);
		const rewards = Object.values(result).map((obj) => Object.values(obj));

		this.levelChallengeModel.setRewards(rewards);
	}
}
