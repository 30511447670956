export class QuestLineModel extends PIXI.utils.EventEmitter {
	private readonly id: number;
	private readonly levelParameters: string[];
	private readonly targetProgress: number;

	private currentProgress: number;

	constructor(id: number, levelParameters: string[]) {
		super();

		this.id = id;
		this.currentProgress = -1;
		this.targetProgress = levelParameters.length;
		this.levelParameters = levelParameters;
	}

	public getId(): number {
		return this.id;
	}

	public getProgress(): number {
		return this.currentProgress;
	}

	public getCurrentQuestLevelParameters(): string {
		return this.levelParameters[this.currentProgress];
	}

	public hasCurrentQuestLevelParameters(): boolean {
		return Boolean(this.levelParameters[this.currentProgress]);
	}

	public moveLine(targetProgress?: number): void {
		if (targetProgress !== undefined) {
			this.currentProgress = targetProgress;
		} else {
			this.currentProgress += 1;
		}
	}

	public isLineCompleted(): boolean {
		return this.currentProgress + 1 >= this.targetProgress;
	}
}
