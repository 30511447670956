import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { QuestUIHelper } from '../../ui/quests/QuestUIHelper';
import * as TWEEN from '@tweenjs/tween.js';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestWindowBaseView, QuestWindowViewMode } from './QuestWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';

export class QuestWindowView extends QuestWindowBaseView {
	private readonly tweenLootboxFloat: TWEEN.Tween;
	private loadingTextLabel: TextField;

	constructor(
		quest: AbstractQuest,
		mode?: QuestWindowViewMode,
	) {
		super(quest, mode);

		const atlas = AssetsStorage.getAtlas('lootboxAtlas');
		const localizationStorage = LocalizationStorage.getInstance();

		const whiteBg = new PIXI.mesh.NineSlicePlane(atlas['white_bg'], 13, 22, 13, 22);
		whiteBg.width = 969;
		whiteBg.height = 610;
		whiteBg.y = 155;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);

		const infoRareBg = new PIXI.Sprite(atlas['info_rare_bg_picture']);
		infoRareBg.position.set(1, -239);

		const infoRarePanel = new PIXI.Sprite(atlas['info_rare_panel']);
		infoRarePanel.position.set(0, -131);

		const bgFade = new PIXI.mesh.NineSlicePlane(atlas['lootbox_bg_fade'], 2, 2, 2, 2);
		bgFade.width = 941;
		bgFade.height = 142;
		bgFade.position.set(1, 372);
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

		const textDescription = new SizeableMultiColoredBitmapText(940, { font: '41px wendyOneShadowBold' });
		textDescription.text = QuestUIHelper.getQuestDescription(this.quest, true);
		textDescription.y = -134;
		textDescription.anchor = 0.5;

		this.rewardsPreview.y = 107;
		this.lootboxSprite.setTransform(0, -327, 0.55, 0.55);

		this.tweenLootboxFloat = new TWEEN.Tween(this.lootboxSprite)
			.to({ y: this.lootboxSprite.y + 10 }, 1200)
			.repeat(Infinity)
			.yoyo(true)
			.start();

		this.loadingTextLabel = new TextField(
			`${localizationStorage.getLocalizedString('#preloader_LoadingLabel')}...`,
			{ font: '28px wendyOneShadowBold', align: 'center' },
		);
		this.loadingTextLabel.y = 373;
		this.loadingTextLabel.anchor = 0.5;
		this.loadingTextLabel.visible = false;

		this.mainContainer.interactive = true;
		this.mainContainer.addChild(
			whiteBg as PIXI.DisplayObject,
			infoRareBg,
			infoRarePanel,
			bgFade,
			textDescription,
			this.lootboxSprite,
			this.buttonAction,
			this.loadingTextLabel,
			this.rewardsPreview,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	protected createActionButtonGo(): ButtonBaseView {
		const buttonGo = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 290, 99),
			{ font: '50px wendyOneShadowBold' },
			200,
		);
		buttonGo.y = 373;
		buttonGo.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#quest_ButtonGo'));
		return buttonGo;
	}

	// eslint-disable-next-line class-methods-use-this
	protected createActionButtonClaim(): ButtonBaseView {
		const buttonCollect = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 290, 99),
			{ font: '50px wendyOneShadowBold' },
			200,
		);
		buttonCollect.y = 373;
		buttonCollect.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#quest_ButtonClaim'));

		if (this.quest instanceof AbstractQuest && !this.quest.hasLootbox()) {
			buttonCollect.setEnabled(false);
		}
		return buttonCollect;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tweenLootboxFloat.stop();
		super.destroy(options);
	}

	protected onButtonCollectClick(): void {
		super.onButtonCollectClick();
		this.buttonAction.visible = false;
		this.loadingTextLabel.visible = true;
	}
}
