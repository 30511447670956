import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { Emitter } from 'pixi-particles';
import { QuestParticleConfig } from '@views/ui/quests/QuestParticleConfig';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { NumberUtils } from '@src/utils/NumberUtils';

export class BankOfferView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private model: BankOfferModel;
	private readonly tickerTimer: PIXI.ticker.Ticker;

	private readonly timerLabel: SizeableBitmapText;
	private readonly animationEmitters: Emitter[];
	private readonly contentInteractive: ButtonBaseView;
	private readonly contentInteractiveContainer: PIXI.Container;
	private readonly offerIconLeft: PIXI.Sprite;

	private readonly animColorStart: string;
	private readonly animColorEnd: string;

	constructor(model: BankOfferModel) {
		super();

		this.model = model;

		this.animationEmitters = [];
		// this.animColorStart = '#003cff';
		// this.animColorEnd = '#00ffe1';
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const bankAtlas = AssetsStorage.getAtlas('bankAtlas');
		const miniCharacterAtlas = AssetsStorage.getAtlas('miniCharactersAtlas');

		this.timerLabel = new SizeableBitmapText(
			'',
			120,
			{ font: '20px wendyOneShadowBold', tint: 0xffc900 },
		);
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(-6, 40);

		const animationsContainer = new PIXI.Container();
		animationsContainer.scale.y = 0.15;
		animationsContainer.scale.x = 0.2;
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

		const timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
		timerBg.scale.set(0.65);
		timerBg.width = 230;
		timerBg.pivot.set(timerBg.width / 2, timerBg.height / 2);
		timerBg.tint = 0.000000;
		timerBg.position.set(-6, 40);

		const offerIconsContainer = new PIXI.Container();

		if (model.hasCharacterRightKey()) {
			const offerIconRight = new PIXI.Sprite(miniCharacterAtlas[model.getCharacterRightKey()]);
			offerIconRight.position.set(30, -25);

			this.offerIconLeft = new PIXI.Sprite(miniCharacterAtlas[model.getCharacterLeftKey()]);
			this.offerIconLeft.position.set(-30, -25);

			offerIconsContainer.addChild(
				offerIconRight as PIXI.DisplayObject,
			);
		} else if (model.getCharacterLeftKey()) {
			this.offerIconLeft = new PIXI.Sprite(miniCharacterAtlas[model.getCharacterLeftKey()]);
			this.offerIconLeft.position.set(0, -25);
		} else {
			this.offerIconLeft = new PIXI.Sprite(bankAtlas[model.getIconKey()]);
			this.offerIconLeft.scale.set(0.7);
			this.offerIconLeft.position.set(0, -10);

			this.animColorStart = '#003cff';
			this.animColorEnd = '#00ffe1';
		}

		offerIconsContainer.addChild(this.offerIconLeft);

		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			QuestParticleConfig.getQuestGlow(this.animColorStart, this.animColorEnd),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.contentInteractiveContainer = new PIXI.Container();
		this.contentInteractiveContainer.addChild(
			animationsContainer,
			offerIconsContainer,
			timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);

		if (this.model.hasMainWindowStickerDiscountKey()) {
			const stickerIcon = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
			stickerIcon.scale.set(0.44);
			stickerIcon.width = 190;
			stickerIcon.pivot.set(stickerIcon.width / 2, stickerIcon.height / 2);
			stickerIcon.position.set(33, 12);

			const saleLabel = new SizeableBitmapText(
				`-${model.getMainWindowStickerDiscountKey()}%`,
				120,
				{ font: '22px wendyOne', tint: 0xffc900 },
			);
			saleLabel.anchor = 0.5;
			saleLabel.position.set(33, 12);

			this.contentInteractiveContainer.addChild(
				stickerIcon,
				saleLabel as PIXI.DisplayObject,
			);
		}

		this.contentInteractive = new ButtonBaseView(
			this.contentInteractiveContainer,
			1.05,
			0.97,
		);
		this.contentInteractive.on(
			ButtonBaseView.EVENT_CLICK,
			() => this.emit(BankOfferView.EVENT_CLICK, this.model),
			this,
		);

		this.tickerTimer = PIXI.ticker.shared;
		this.tickerTimer.add(this.onUpdateTextTimer, this);

		this.addChild(
			this.contentInteractive as PIXI.DisplayObject,
		);
	}

	private onUpdateTextTimer(): void {
		this.timerLabel.text = NumberUtils.secToDHMSColonFormatted(this.model.getTimeleft());
	}

	public setModel(model: BankOfferModel): void {
		this.model = model;
	}

	public destroy(): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.tickerTimer.remove(this.onUpdateTextTimer, this);
		super.destroy();
	}
}
