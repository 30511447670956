import { OldEmitterConfig } from "pixi-particles";

export class RewardResultWindowAnimations {
	public static getTitleOpenConfig(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1.3,
				end: 0.2,
				minimumScaleMultiplier: 0.2,
			},
			color: {
				start: '#FF8B19',
				end: '#FF8B19',
			},
			speed: {
				start: 150,
				end: 10,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: -100,
				y: -100,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.8,
				max: 0.8,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -200,
				y: 0,
				w: 400,
				h: 0,
			},
		};
	}

	public static getLootboxOpenConfig(): OldEmitterConfig {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 2,
				end: 0.2,
				minimumScaleMultiplier: 0.2,
			},
			color: {
				start: '#FF8B19',
				end: '#FF8B19',
			},
			speed: {
				start: 150,
				end: 10,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: -100,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 0.8,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 30,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -250,
				y: -150,
				w: 500,
				h: 300,
			},
		};
	}

	public static getLootboxOpenFlareConfig(): OldEmitterConfig {
		return {
			alpha: {
				start: 0,
				end: 0.7,
			},
			scale: {
				start: 16,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ff8800',
				end: '#ffffb2',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: -0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: -360,
				max: 360,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 20,
			pos: {
				x: 50,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -1,
				y: -1,
				w: 2,
				h: 2,
			},
		};
	}

	public static getLootboxOpenSparkConfig(): OldEmitterConfig {
		return {
			alpha: {
				start: 0.7,
				end: 0,
			},
			scale: {
				start: 16,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ff8800',
				end: '#ffffb2',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: -0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: -100,
				max: 100,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 2,
			pos: {
				x: 50,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -1,
				y: -1,
				w: 2,
				h: 2,
			},
		};
	}
}
