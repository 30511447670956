import CharacterConfig from '@configs/CharacterConfig';
import { CharacterSaveData } from '@src/types/SaveTypes';
import { CharacterModel } from '@models/CharacterModel';
import { BusinessConfig } from '@configs/BusinessConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';

export class CharactersFactory {
	private readonly configs: Map<string, CharacterConfig>;
	private readonly savesData: Map<string, CharacterSaveData>;
	private readonly businessConfigs: Map<string, BusinessConfig>;

	constructor(
		configs: Map<string, CharacterConfig>,
		private readonly promotePatternsConfig: AbstractPromotePatternsConfig,
		savesData: Map<string, CharacterSaveData>,
		businessConfigs: Map<string, BusinessConfig>,
	) {
		this.configs = configs;
		this.promotePatternsConfig = promotePatternsConfig;
		this.savesData = savesData;
		this.businessConfigs = businessConfigs;
	}

	public createModels(): Map<string, CharacterModel> {
		const characterBusinessKeysMap: Map<string, string> = new Map();
		this.businessConfigs.forEach((businessConfig, businessKey) => {
			businessConfig.getCharacterKeys().forEach(characterKey => characterBusinessKeysMap.set(characterKey, businessKey));
		});

		const models: Map<string, CharacterModel> = new Map();

		this.configs.forEach((config, key) => {
			const model: CharacterModel = new CharacterModel(
				config,
				this.promotePatternsConfig,
			);
			models.set(key, model);

			const characterSaveData: CharacterSaveData = this.savesData.get(config.getKey());
			if (characterSaveData) {
				model.setFromSaveData(characterSaveData);
			}

			if (characterBusinessKeysMap.has(key)) {
				model.setRelatedBusinessKey(characterBusinessKeysMap.get(key));
			}
		});

		return models;
	}
}
