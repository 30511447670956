import { TutorialStepGirlPositionTypes, TutorialStepBaseConfig, TutorialBlockType } from '@configs/tutorialSteps/TutorialStepBaseConfig';
import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepDataBase } from '@configs/tutorialSteps/data/TutorialStepDataBase';

export class TutorialStepBaseModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_COMPLETED: symbol = Symbol();

	private readonly localeKeys: string[];
	private readonly girlPosition: TutorialStepGirlPositionTypes;
	private readonly girlAnimate: boolean;

	private readonly target: TutorialStepTargetTypes;
	private readonly data: TutorialStepDataBase;
	private readonly key: string;
	private readonly tapSpriteInverted: boolean;
	private readonly block: TutorialBlockType;

	private completed: boolean;

	constructor(config: TutorialStepBaseConfig) {
		super();

		this.key = config.getKey();
		this.localeKeys = config.getLocaleKeys();
		this.target = config.getTarget();
		this.data = config.getData();
		this.tapSpriteInverted = config.isTapSpriteInverted();
		this.block = config.getBlock();
		if (config.hasGirlPosition()) {
			this.girlPosition = config.getGirlPosition();
			this.girlAnimate = config.isGirlAnimate();
		}
	}

	public setCompleted(): void {
		this.completed = true;
	}

	public complete(): void {
		this.setCompleted();
		this.emit(TutorialStepBaseModel.EVENT_COMPLETED);
	}

	public isCompleted(): boolean {
		return this.completed;
	}

	public hasCharacter(): boolean {
		return this.localeKeys.length > 0 && (this.girlPosition !== undefined);
	}

	public getLocaleKeys(): string[] {
		return this.localeKeys;
	}

	public getGirlPosition(): TutorialStepGirlPositionTypes {
		return this.girlPosition;
	}

	public isGirlAnimate(): boolean {
		return this.girlAnimate;
	}

	public getTargetType(): TutorialStepTargetTypes {
		return this.target;
	}

	public getData(): TutorialStepDataBase {
		return this.data;
	}

	public isTapSpriteInverted(): boolean {
		return this.tapSpriteInverted;
	}

	public getKey(): string {
		return this.key;
	}

	public hasBlock(): boolean {
		return this.block !== undefined;
	}

	public getBlock(): TutorialBlockType {
		return this.block;
	}
}
