import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter51015View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51015View';
import { EventCharacter51014View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51014View';
import { EventCharacter51013View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51013View';

export class EventBusiness51005View extends BusinessBaseView {
	constructor() {
		const eventCharacter51013View = new EventCharacter51013View();
		const eventCharacter51014View = new EventCharacter51014View();
		const eventCharacter51015View = new EventCharacter51015View();

		super('business51005', [eventCharacter51013View, eventCharacter51014View, eventCharacter51015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter51013View as PIXI.DisplayObject,
			eventCharacter51014View,
			eventCharacter51015View,
		);
	}
}
