import { BankOfferMoveFromOfferWindowType, BankOfferWindowViewType, BankOfferConfigRaw } from '@src/types/BankTypes';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { NumberUtils } from '@src/utils/NumberUtils';

export type BankOfferLocalizationKeysType = {
	title: string;
	subtitle1: string;
	subtitle2: string;
}

type BankOfferConfigKey = keyof BankOfferConfigRaw;
export class BankOfferConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: BankOfferConfigKey = 'id';
	public static readonly FIELD_MAIN_WINDOW_STICKER_DISCOUNT_KEY: BankOfferConfigKey = 'discount';
	public static readonly FIELD_WINDOW_VIEW_TYPE: BankOfferConfigKey = 'offer_window_prefab_type';
	public static readonly FIELD_MOVE_TO_WINDOW: BankOfferConfigKey = 'move_to_window';
	public static readonly FIELD_SHOW_ON_MAIN_WINDOW: BankOfferConfigKey = 'show_on_mainwindow';
	public static readonly FIELD_LOCALIZATION_KEYS: BankOfferConfigKey = 'localization_keys';
	public static readonly FIELD_VIEW_PARAMETERS: BankOfferConfigKey = 'view_parameters';
	public static readonly FIELD_PRIORITY_VIEW: BankOfferConfigKey = 'priority_view';
	public static readonly FIELD_UNLOCK_TYPE: BankOfferConfigKey = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: BankOfferConfigKey = 'unlock_value';
	public static readonly FIELD_ACTIVATE_TIMES: BankOfferConfigKey = 'activate_times';
	public static readonly FIELD_START_DATE: BankOfferConfigKey = 'start_date';
	public static readonly FIELD_ENDS_ON_LOCKED: BankOfferConfigKey = 'ends_on_locked';
	public static readonly FIELD_DURATION: BankOfferConfigKey = 'duration';
	public static readonly FIELD_END_DATE: BankOfferConfigKey = 'end_date';

	private readonly key: string;
	private readonly mainWindowStickerDiscountKey: string;
	private readonly windowViewType: BankOfferWindowViewType;
	private readonly moveFromOfferWindow: BankOfferMoveFromOfferWindowType;
	private readonly showOnMainWindow: boolean;
	private readonly localizationKeys: BankOfferLocalizationKeysType;

	private readonly characterLeftKey: string;
	private readonly characterRightKey: string;
	private readonly iconKey: string;
	private readonly priority: number;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];

	private readonly duration?: number;
	private readonly startTime?: number;
	private readonly endTime?: number;
	private readonly endsOnLocked: boolean;
	private readonly activateTimes: number;

	constructor(config: BankOfferConfigRaw | { [key: string]: string }) {
		this.key = config[BankOfferConfig.FIELD_KEY];

		this.mainWindowStickerDiscountKey = config[BankOfferConfig.FIELD_MAIN_WINDOW_STICKER_DISCOUNT_KEY]?.trim();
		this.windowViewType = config[BankOfferConfig.FIELD_WINDOW_VIEW_TYPE].trim() as BankOfferWindowViewType;
		this.moveFromOfferWindow = config[BankOfferConfig.FIELD_MOVE_TO_WINDOW].trim() as BankOfferMoveFromOfferWindowType;

		const showOnMainWindowRaw = config[BankOfferConfig.FIELD_SHOW_ON_MAIN_WINDOW].trim();
		this.showOnMainWindow = showOnMainWindowRaw === '1';

		const viewParametersRaw = config[BankOfferConfig.FIELD_VIEW_PARAMETERS].trim();
		const viewParameterColonIndex = viewParametersRaw.indexOf(':') + 1;

		if (viewParametersRaw.indexOf('characters:') >= 0) {
			const charactersRaw = viewParametersRaw
				.substr(viewParameterColonIndex)
				.split(',')
				.map(str => str.trim());
			[this.characterLeftKey, this.characterRightKey] = [charactersRaw[0], charactersRaw[1]];
		} else if (viewParametersRaw.indexOf('icon:') >= 0) {
			this.iconKey = viewParametersRaw.substr(viewParameterColonIndex);
		} else {
			throw new Error(`Unsupported view param '${viewParametersRaw}'`);
		}

		const localizationKeysRaw = config[BankOfferConfig.FIELD_LOCALIZATION_KEYS].split(',').map((s: string) => s.trim());
		this.localizationKeys = {
			title: localizationKeysRaw.length > 0 ? localizationKeysRaw[0] : '',
			subtitle1: localizationKeysRaw.length > 1 ? localizationKeysRaw[1] : '',
			subtitle2: localizationKeysRaw.length > 2 ? localizationKeysRaw[2] : '',
		};

		if (config[BankOfferConfig.FIELD_PRIORITY_VIEW]) {
			this.priority = parseFloat(config[BankOfferConfig.FIELD_PRIORITY_VIEW]);
		} else {
			this.priority = 0;
		}

		if (config[BankOfferConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[BankOfferConfig.FIELD_UNLOCK_TYPE];
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[BankOfferConfig.FIELD_UNLOCK_VALUE];
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}

		this.activateTimes = Number(config[BankOfferConfig.FIELD_ACTIVATE_TIMES]);
		this.endsOnLocked = config[BankOfferConfig.FIELD_ENDS_ON_LOCKED].trim() === '1';

		if (config[BankOfferConfig.FIELD_DURATION]) {
			this.duration = Number(config[BankOfferConfig.FIELD_DURATION].trim());
		}

		if (config[BankOfferConfig.FIELD_START_DATE]) {
			const isoStart = NumberUtils.convertDateToISO(config[BankOfferConfig.FIELD_START_DATE].trim());
			const isoEnd = NumberUtils.convertDateToISO(config[BankOfferConfig.FIELD_END_DATE].trim());

			this.startTime = Math.floor(Date.parse(isoStart) / 1000);
			this.endTime = Math.floor(Date.parse(isoEnd) / 1000);
		}
	}

	public hasDates(): boolean {
		return Boolean(this.startTime);
	}

	public getEndTimestamp(): number {
		return this.endTime;
	}

	public getStartTimestamp(): number {
		return this.startTime;
	}

	public getEndsLocked(): boolean {
		return this.endsOnLocked;
	}

	public getActivateTimes(): number {
		return this.activateTimes;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public getDuration(): number {
		return this.duration;
	}

	public getPriority(): number {
		return this.priority;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public hasCharacterRightKey(): boolean {
		return Boolean(this.characterRightKey);
	}

	public hasMainWindowStickerDiscountKey(): boolean {
		return Boolean(this.mainWindowStickerDiscountKey);
	}

	public getKey(): string {
		return this.key;
	}

	public getMainWindowStickerDiscountKey(): string {
		return this.mainWindowStickerDiscountKey;
	}

	public getMoveFromOfferWindowType(): BankOfferMoveFromOfferWindowType {
		return this.moveFromOfferWindow;
	}

	public getWindowViewType(): BankOfferWindowViewType {
		return this.windowViewType;
	}

	public isShowOnMainWindow(): boolean {
		return this.showOnMainWindow;
	}

	public getLocalizationKeys(): BankOfferLocalizationKeysType {
		return this.localizationKeys;
	}

	public getCharacterLeftKey(): string {
		return this.characterLeftKey;
	}

	public getCharacterRightKey(): string {
		return this.characterRightKey;
	}
}
