import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter54001View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54001View';
import { EventCharacter54002View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54002View';
import { EventCharacter54003View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54003View';

export class EventBusiness54001View extends BusinessBaseView {
	constructor() {
		const eventCharacter54001View = new EventCharacter54001View();
		const eventCharacter54002View = new EventCharacter54002View();
		const eventCharacter54003View = new EventCharacter54003View();
		eventCharacter54003View.once(EventCharacter54003View.EVENT_SHOWN, () => eventCharacter54001View.destroy());

		super('business54001', [eventCharacter54001View, eventCharacter54002View, eventCharacter54003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter54001View as PIXI.DisplayObject,
			eventCharacter54002View,
			eventCharacter54003View,
		);
	}
}
