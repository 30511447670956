import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestUIBaseView } from './QuestUIBaseView';
import { TimedQuestUIView } from './TimedQuestUIView';
import { QuestUIView } from './QuestUIView';
import * as TWEEN from '@tweenjs/tween.js';

type QuestLineData = {
	view: QuestUIBaseView<AbstractQuest>;
	quest: AbstractQuest;
};

export class QuestLinesUIView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private static readonly QUEST_LINES_OFFSET: number = 115;

	private questLines: QuestLineData[];
	private timedQuestView: QuestUIBaseView<AbstractQuest>;

	private timedQuest: AbstractQuest;

	private tweenCloseTimedQuestView: TWEEN.Tween;

	public init(quests: AbstractQuest[]): void {
		this.reset();

		const questsWithNoTime = quests.filter(quest => !quest.hasTime());
		questsWithNoTime.forEach((quest, i) => {
			const view = this.createQuestView();
			view.position.set(0, QuestLinesUIView.QUEST_LINES_OFFSET * i);
			view.setQuest(quest);

			this.questLines.push({ view, quest });
		});

		const questWithTime = quests.filter(quest => quest.hasTime())?.[0];
		if (questWithTime) {
			this.timedQuestView = this.createTimedQuestView();
			this.timedQuestView.setQuest(questWithTime);
			this.updateTimedQuestViewPosition();

			this.timedQuest = questWithTime;
			this.timedQuest.once(AbstractQuest.EVENT_TIMEOUT, this.onSomeTimedQuestTimeout, this);
		}
	}

	public hideTimedQuest(): void {
		this.startTimedQuestHidingAnimation();
	}

	public setTimedQuest(quest: AbstractQuest): void {
		if (!this.timedQuestView) {
			this.timedQuestView = this.createTimedQuestView();
		}
		this.updateTimedQuestViewPosition();

		this.tryResetCurrentTimedQuest();

		this.timedQuest = quest;
		this.timedQuest.once(AbstractQuest.EVENT_TIMEOUT, this.onSomeTimedQuestTimeout, this);

		this.timedQuestView.visible = true;
		this.timedQuestView.setQuest(quest);
		this.timedQuestView.startAppearingAnimation();
	}

	public setLineQuest(quest: AbstractQuest): void {
		const targetQuestLine = this.questLines.filter(questLine => questLine.quest.getLineId() === quest.getLineId())[0];
		targetQuestLine.quest = quest;

		const questView = targetQuestLine.view;
		questView.setQuest(quest);
		questView.startAppearingAnimation();
	}

	public setLineCompleted(lineId: number): void {
		const questLineIndex = this.questLines.findIndex(questLine => questLine.quest.getLineId() === lineId);
		const questView = this.questLines[questLineIndex].view;

		questView.visible = false;
		for (let i = this.questLines.length - 1; i > questLineIndex; i--) {
			const currentLine = this.questLines[i].view;
			const prevLine = this.questLines[i - 1].view;

			currentLine.y = prevLine.y;
		}

		this.questLines.splice(questLineIndex, 1);

		if (this.timedQuestView) {
			this.updateTimedQuestViewPosition();
		}
	}

	public setLineInteractive(lineId: number, value: boolean): void {
		const questLineIndex = this.questLines.findIndex(questLine => questLine.quest.getLineId() === lineId);
		const questView = this.questLines[questLineIndex].view;
		questView.interactive = value;
	}

	public getLineView(id: number): PIXI.Container {
		return this.questLines[id].view;
	}

	public getCurrentQuests(includeTimed: boolean = false): AbstractQuest[] {
		const currentQuests = this.questLines.map(line => line.quest);
		return includeTimed ? currentQuests : currentQuests.filter(quest => !quest.hasTime());
	}

	private onSomeTimedQuestTimeout(): void {
		this.timedQuest = null;
		this.startTimedQuestHidingAnimation();
	}

	private startTimedQuestHidingAnimation(): void {
		if (this.tweenCloseTimedQuestView) {
			this.tweenCloseTimedQuestView.stop();
		}
		const timedQuestSavedX = this.timedQuestView.x;
		this.tweenCloseTimedQuestView = new TWEEN.Tween(this.timedQuestView)
			.to({ x: -270 }, 150)
			.onComplete(() => {
				this.timedQuestView.visible = false;
				this.timedQuestView.x = timedQuestSavedX;

				this.tweenCloseTimedQuestView = null;
			})
			.start();
	}

	private tryResetCurrentTimedQuest(): void {
		if (this.timedQuest) {
			this.timedQuest.off(AbstractQuest.EVENT_TIMEOUT, this.onSomeTimedQuestTimeout, this, true);
		}
		if (this.tweenCloseTimedQuestView) {
			this.tweenCloseTimedQuestView.stop();
		}
	}

	private createTimedQuestView(): QuestUIBaseView<AbstractQuest> {
		const view = new TimedQuestUIView();
		view.interactive = true;
		view.on(QuestUIBaseView.EVENT_CLICK, this.onQuestViewClick, this);
		this.addChild(view);
		return view;
	}

	private createQuestView(): QuestUIBaseView<AbstractQuest> {
		const view = new QuestUIView();
		view.interactive = true;
		view.on(QuestUIBaseView.EVENT_CLICK, this.onQuestViewClick, this);
		this.addChild(view);
		return view;
	}

	private updateTimedQuestViewPosition(): void {
		const lastQuestLineView = Math.max(this.questLines.length, 0);
		this.timedQuestView.position.set(0, lastQuestLineView * QuestLinesUIView.QUEST_LINES_OFFSET + 20);
	}

	private reset(): void {
		this.questLines = [];
		this.removeChildren();
		this.tryResetCurrentTimedQuest();
	}

	private onQuestViewClick(quest: AbstractQuest): void {
		this.emit(QuestLinesUIView.EVENT_CLICK, quest);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tryResetCurrentTimedQuest();
		super.destroy(options);
	}
}
