import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SummonModelsPool } from '@models/SummonModelsPool';

export class SummonTicker {
	private readonly serverTimeModel: ServerTimeModel;
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly summonModelsPool: SummonModelsPool;

	private timerTicking: boolean;
	private enabled: boolean;

	constructor(
		serverTime: ServerTimeModel,
		summonModelsPool: SummonModelsPool,
	) {
		this.serverTimeModel = serverTime;

		this.summonModelsPool = summonModelsPool;
		this.summonModelsPool.on(SummonModelsPool.EVENT_MODELS_UPDATED, this.onSummonModelsUpdated, this);

		this.ticker = PIXI.ticker.shared;
	}

	public init(): void {
		this.tryStartTimer();
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (value) {
			this.tryStartTimer();
		} else {
			this.tryStopTimer();
		}
	}

	private onSummonModelsUpdated(): void {
		this.tryStartTimer();
	}

	private onTimerUpdate(): void {
		const someSummonModelAvailable = this.summonModelsPool
			.getModelsList()
			.filter(model => model.isFreePrice() && !model.isFreePriceAvailable())
			.some((model) => {
				const availableTime = Math.max(0, model.getNextSummonOn() - this.serverTimeModel.getCalculatedISOTime());
				model.setFreePriceAvailableTime(availableTime);

				const available = availableTime <= 0;
				if (available) {
					model.setFreePriceAvailable(true);
				}
				return available;
			});

		if (someSummonModelAvailable) {
			this.tryStopTimer();
		}
	}

	private tryStartTimer(): void {
		const summonModels = this.summonModelsPool.getModelsList();
		if (!this.timerTicking && this.enabled && summonModels.filter(model => !model.isFreePriceAvailable()).length !== 0) {
			this.startTimer();
		}
	}

	private tryStopTimer(): void {
		const summonModels = this.summonModelsPool.getModelsList();
		if (this.timerTicking && summonModels.filter(model => !model.isFreePriceAvailable()).length === 0) {
			this.stopTimer();
		}
	}

	private startTimer(): void {
		this.timerTicking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	private stopTimer(): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}
}
