import { EventLevelModelInstance } from '@main/modelInstance/EventLevelModelInstance';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { EventLevelBehavioursFactory } from '@src/initializers/behaviours/EventLevelBehavioursFactory';
import { AbstractBehaviourInstance } from './AbstractBehaviourInstance';
import {
	GameUIBaseViewSetter,
	EventGameUIViewSetter,
	SettingsWindowViewSetter,
	BusinessBaseViewsSetter,
	EventRankingWindowViewSetter,
	PrepartyWindowViewSetter,
	EventLevelRewardsWindowViewSetter,
	SummonCollectionViewSetter,
	BankWindowViewSetter,
	BankOfferWindowViewSetter,
	CharacterCollectionViewSetter,
	UpgradeCollectionViewSetter,
	TotemCollectionViewSetter,
	QuestWindowBaseViewSetter,
	SummonResultWindowViewSetter,
	CheatWindowViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { EventLevelGameUIViewController } from '@src/viewControllers/mainUI/EventLevelGameUIViewController';
import { QuestInitData } from '@src/types/SaveTypes';
import { EventLevelSettingsWindowViewController } from '@src/viewControllers/EventLevelSettingsWindowViewController';
import { TutorialStepWindowViewTargetEventLevelDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewEventLevelTargetDispatcher';
import { ModelWriterEventLevelBusinessIncomePerSec } from '@src/modelWriters/ModelWriterEventLevelBusinessIncomePerSec';
import { PrepartyWindowViewController } from '@src/viewControllers/PrepartyWindowViewController';
import { EventLevelHintViewController } from '@src/viewControllers/EventHintViewController';
import { BankWindowViewController } from '@src/viewControllers/BankWindowViewController';
import { EventLevelQuestWindowViewController } from '@src/viewControllers/EventLevelQuestWindowViewController';
import { SummonWindowViewController } from '@src/viewControllers/SummonWindowViewController';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { CheatWindowSystemViewController } from '@src/viewControllers/cheat/CheatWindowSystemViewController';

export class EventLevelBehaviourInstance extends AbstractBehaviourInstance<EventLevelModelInstance> {
	public static readonly EVENT_RETURN_TO_LEVEL: symbol = Symbol();
	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	private readonly questsLoader: QuestsLoaderBase;
	private readonly summonLoader: SummonLoader;

	private readonly eventLevelModelInstance: EventLevelModelInstance;

	private readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetEventLevelDispatcher;
	private readonly eventGameUIViewController: EventLevelGameUIViewController;
	private readonly eventSettingsWindowViewController: EventLevelSettingsWindowViewController;
	private readonly prepartyWindowViewController: PrepartyWindowViewController;
	private readonly bankWindowViewController: BankWindowViewController;
	private readonly questWindowViewController: EventLevelQuestWindowViewController;
	private readonly summonWindowViewController: SummonWindowViewController;
	private readonly cheatWindowSystemViewController: CheatWindowSystemViewController;

	private readonly hintViewController: EventLevelHintViewController;
	private readonly modelWriterIncome: ModelWriterEventLevelBusinessIncomePerSec;

	private readonly questsInitData: QuestInitData[];

	constructor(
		behavioursFactory: EventLevelBehavioursFactory,
		eventLevelModelInstance: EventLevelModelInstance,
		questsInitData: QuestInitData[],
	) {
		super(
			behavioursFactory,
		);
		this.questsInitData = questsInitData;
		this.eventLevelModelInstance = eventLevelModelInstance;

		behavioursFactory.createModelWriterEventLevelSoftMoney();
		this.modelWriterIncome = behavioursFactory.createModelWriterEventLevelBusinessIncomePerSec();
		behavioursFactory.createModelWriterEventTutorialSteps();
		behavioursFactory.createModelWriterEventSummonSavesModel();

		this.questsLoader = behavioursFactory.createQuestsLoaderBase();
		this.summonLoader = behavioursFactory.createSummonLoader();

		this.tutorialStepsViewTargetDispatcher = behavioursFactory.createTutorialStepsViewTargetEventDispatcher();

		this.questWindowViewController = behavioursFactory.createEventLevelQuestWindowViewController(
			this.questsLoader,
			this.tutorialStepsViewTargetDispatcher,
		);

		const emittersController = behavioursFactory.createEmittersController(this.bankOfferWindowsEmitter);
		this.hintViewController = behavioursFactory.createEventLevelHintController();

		this.summonWindowViewController = behavioursFactory.createSummonWindowViewController(
			this.summonLoader,
		);

		this.bankWindowViewController = behavioursFactory.createBankWindowViewController(
			this.bankLoader,
			this.transactionCreator,
		);

		this.eventGameUIViewController = behavioursFactory.createEventGameUIViewController(
			this.bankOfferWindowsEmitter,
			this.tutorialStepsViewTargetDispatcher,
			this.bankLoader,
			emittersController,
		);
		this.eventGameUIViewController.on(
			EventLevelGameUIViewController.EVENT_RETURN_TO_LEVEL,
			() => this.emit(EventLevelBehaviourInstance.EVENT_RETURN_TO_LEVEL),
			this,
		);

		this.eventSettingsWindowViewController = behavioursFactory.createEventSettingsWindowViewController();
		this.eventSettingsWindowViewController.on(
			EventLevelSettingsWindowViewController.EVENT_LOCALIZATION_EVENT_CHANGE,
			(lang: string) => this.emit(EventLevelBehaviourInstance.EVENT_LOCALIZATION_CHANGE, lang),
		);

		this.prepartyWindowViewController = behavioursFactory.createPrepartyWindowViewController(
			emittersController,
		);

		this.cheatWindowSystemViewController = behavioursFactory.createCheatWindowSystemViewController();

		this.actionEmitters.push(
			this.questsLoader,
			this.questWindowViewController,
			this.eventSettingsWindowViewController,
			this.bankWindowViewController,
			this.bankLoader,
			this.eventGameUIViewController,
			this.transactionCreator,
		);
		this.subscribeActionEmittersIfAny();

		const tutorialStepUnlockTypeFactory = behavioursFactory.createTutorialStepUnlockTypeFactory();
		const tutorialStepUnlockTypeModels = tutorialStepUnlockTypeFactory.createModels();

		// TODO move?
		eventLevelModelInstance.getTutorialStepsEmitter().setModels(tutorialStepUnlockTypeModels);
	}

	public init(): void {
		super.init();

		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();
		this.questsLoader.init(
			eventLevelModel.getQuestLines(),
			this.questsInitData,
		);
		this.bankWindowViewController.setEventKey(eventLevelModel.getKey());
		this.modelWriterIncome.init();

		this.summonLoader.init();
	}

	public getPrepartyWindowViewSetters(): PrepartyWindowViewSetter[] {
		return [
			this.prepartyWindowViewController,
		];
	}

	public getEventGameUIViewSetters(): EventGameUIViewSetter[] {
		return [
			this.eventGameUIViewController,
			this.tutorialStepsViewTargetDispatcher,
		];
	}

	public getQuestWindowBaseViewSetters(): QuestWindowBaseViewSetter[] {
		return super.getQuestWindowBaseViewSetters().concat([
			this.questWindowViewController,
		]);
	}

	public getGameUIBaseViewSetters(): GameUIBaseViewSetter[] {
		return super.getGameUIBaseViewSetters().concat([
			this.bankWindowViewController,
			this.tutorialStepsViewTargetDispatcher,
			this.questWindowViewController,
			this.hintViewController,
		]);
	}

	public getSummonResultViewSetters(): SummonResultWindowViewSetter[] {
		return [
			this.summonWindowViewController,
		];
	}

	public getSummonCollectionViewSetters(): SummonCollectionViewSetter[] {
		return [
			this.summonWindowViewController,
			this.hintViewController,
		];
	}

	public getBankWindowViewSetters(): BankWindowViewSetter[] {
		return [
			this.hintViewController,
			this.bankWindowViewController,
		];
	}

	public getBankOfferWindowViewSetters(): BankOfferWindowViewSetter[] {
		return [
			this.bankWindowViewController,
			this.hintViewController,
		];
	}

	public getCharacterCollectionViewSetters(): CharacterCollectionViewSetter[] {
		return super.getCharacterCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getUpgradeCollectionViewSetters(): UpgradeCollectionViewSetter[] {
		return super.getUpgradeCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getFurnitureCollectionViewSetters(): FurnitureCollectionViewSetter[] {
		return super.getFurnitureCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getTotemCollectionViewControllers(): TotemCollectionViewSetter[] {
		return super.getTotemCollectionViewControllers().concat([
			this.hintViewController,
		]);
	}

	public getBusinessBaseViewsSetters(): BusinessBaseViewsSetter[] {
		return super.getBusinessBaseViewsSetters().concat([
			this.tutorialStepsViewTargetDispatcher,
		]);
	}

	public getSettingsWindowViewSetters(): SettingsWindowViewSetter[] {
		return super.getSettingsWindowViewSetters().concat([
			this.eventSettingsWindowViewController,
		]);
	}

	public getEventRankingWindowViewSetters(): EventRankingWindowViewSetter[] {
		return [
			this.eventSettingsWindowViewController,
		];
	}

	public getEventLevelRewardsWindowViewSetters(): EventLevelRewardsWindowViewSetter[] {
		return [
			this.hintViewController,
		];
	}

	public getCheatWindowViewSetters(): CheatWindowViewSetter[] {
		const setters = super.getCheatWindowViewSetters();

		return [
			...setters,
			this.cheatWindowSystemViewController,
		];
	}

	public destroy(): void {
		this.eventGameUIViewController.destroy();
	}
}
