import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70001View } from '@views/characters/events/event7/EventCharacter70001View';
import { EventCharacter70002View } from '@views/characters/events/event7/EventCharacter70002View';
import { EventCharacter70003View } from '@views/characters/events/event7/EventCharacter70003View';

export class EventBusiness70001View extends BusinessBaseView {
	constructor() {
		const eventCharacter70001View = new EventCharacter70001View();
		const eventCharacter70002View = new EventCharacter70002View();
		const eventCharacter70003View = new EventCharacter70003View();

		super('business70001', [eventCharacter70001View, eventCharacter70002View, eventCharacter70003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b1_decor5']).setTransform(276, 34, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor1']).setTransform(-225, -17, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor3']).setTransform(-217, -72, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor2']).setTransform(166, -37, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			new PIXI.Sprite(decorationAtlas['b1_decor4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite,
			eventCharacter70001View as PIXI.DisplayObject,
			eventCharacter70002View,
			eventCharacter70003View,
		);
	}
}
