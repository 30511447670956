import { AssetsStorage } from '@main/AssetsStorage';
import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character22View } from '@views/characters/main/Character22View';
import { Character23View } from '@views/characters/main/Character23View';
import { Character24View } from '@views/characters/main/Character24View';

export class Business8View extends BusinessBaseView {
	constructor() {
		const character22 = new Character22View();
		const character23 = new Character23View();
		const character24 = new Character24View();

		super('business8', [character22, character23, character24]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character22 as PIXI.DisplayObject,
			character23,
			character24,
		);
	}
}
