import { AbstractQuest } from './AbstractQuest';
import { UpgradeModel } from '@models/UpgradeModel';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';
import { CharacterModel } from '@models/CharacterModel';

export type QuestActivateTargetType = CharacterModel | UpgradeModel;

export class QuestActivateTargets<T extends QuestActivateTargetType> extends AbstractQuest {
	private readonly targets: T[];
	private readonly targetCount: number;
	private currentCount: number;

	constructor(
		questKey: string,
		questType: QuestTypes.ACTIVATE_CHARACTERS | QuestTypes.ACTIVATE_UPGRADES,
		targets: T[],
		activateCount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.targets = targets;

		this.targetCount = activateCount;
		this.currentCount = targets.filter(target => target.isActivated()).length;

		if (this.currentCount >= this.targetCount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public getActivateCount(): number {
		return this.targetCount;
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentCount = Number(saveData.progress);
	}

	public getCurrentProgressValue(): string {
		return this.currentCount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetCount.toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentCount / this.targetCount;
	}

	private onSomeTargetActivated(): void {
		this.currentCount += 1;
		if (this.currentCount >= this.targetCount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		if (this.type === QuestTypes.ACTIVATE_UPGRADES) {
			this.targets.forEach(target => target.off(UpgradeModel.EVENT_ACTIVATED, this.onSomeTargetActivated, this));
		} else if (this.type === QuestTypes.ACTIVATE_CHARACTERS) {
			this.targets.forEach(target => target.off(CharacterModel.EVENT_ACTIVATED, this.onSomeTargetActivated, this));
		}
	}

	protected trackProgressStart(): void {
		if (this.type === QuestTypes.ACTIVATE_UPGRADES) {
			this.targets.forEach(target => target.on(UpgradeModel.EVENT_ACTIVATED, this.onSomeTargetActivated, this));
		} else if (this.type === QuestTypes.ACTIVATE_CHARACTERS) {
			this.targets.forEach(target => target.on(CharacterModel.EVENT_ACTIVATED, this.onSomeTargetActivated, this));
		}
	}
}
