import LocalizationStorage from '@main/LocalizationStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export class PreloaderHintView extends PIXI.Container {
	private readonly ticker: PIXI.ticker.Ticker;

	private static readonly TEXT_CHANGE_TIME: number = 3;
	private static readonly ELLIPSIS_ANIMATION_TIME: number = 0.2;

	private readonly localizationStorage: LocalizationStorage;

	private readonly textHint: MultiColoredTextField;
	private readonly textEllipsis: PIXI.extras.BitmapText;
	private readonly landingModest: boolean;

	private timeToChangeHint: number;
	private timeToAnimateEllipsis: number;

	private hints: string[];
	private nextHintId: number;

	constructor(
		landingModest: boolean = false,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.landingModest = landingModest;

		this.timeToChangeHint = PreloaderHintView.TEXT_CHANGE_TIME;
		this.timeToAnimateEllipsis = PreloaderHintView.ELLIPSIS_ANIMATION_TIME;

		this.ticker = PIXI.ticker.shared;

		this.textHint = new MultiColoredTextField({ font: '28px wendyOneShadowBold' });
		this.textHint.anchor = 0.5;

		this.textEllipsis = new PIXI.extras.BitmapText('', { font: '28px wendyOneShadowBold' });
		this.textEllipsis.anchor = new PIXI.Point(0, 0.5);

		this.init();

		this.addChild(
			this.textHint,
			this.textEllipsis,
		);
	}

	private init(): void {
		this.nextHintId = 0;
		this.hints = [];

		// Populate hints array
		let hintId = 1;
		let string: string;
		if (this.landingModest) {
			string = '#loading_screen_non_adult_hint_';
		} else {
			string = '#loading_screen_hint_';
		}

		while (this.localizationStorage.hasLocalizedString(`${string}${hintId}`)) {
			this.hints.push(this.localizationStorage.getLocalizedString(`${string}${hintId}`));
			hintId += 1;
		}

		// Shuffle hints array
		for (let i = this.hints.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const x = this.hints[i];
			this.hints[i] = this.hints[j];
			this.hints[j] = x;
		}

		// Start changing hints
		if (this.hints.length > 0) {
			this.updateHintText();
			this.ticker.add(this.update, this);
		}
	}

	private update(): void {
		this.timeToChangeHint -= this.ticker.elapsedMS / 1000;
		if (this.timeToChangeHint <= 0) {
			this.updateHintText();
			this.timeToChangeHint = PreloaderHintView.TEXT_CHANGE_TIME;
		}
	}

	private updateEllipsis(): void {
		this.timeToAnimateEllipsis -= this.ticker.elapsedMS / 1000;
		if (this.timeToAnimateEllipsis <= 0) {
			if (this.textEllipsis.text.length === 3) {
				this.textEllipsis.text = '';
			} else {
				this.textEllipsis.text = `${this.textEllipsis.text}.`.trim();
			}
			this.timeToAnimateEllipsis = PreloaderHintView.ELLIPSIS_ANIMATION_TIME;
		}
	}

	private startEllipsisAnimation(ellipsisIndex: number): void {
		this.textHint.text = this.textHint.text.substring(0, ellipsisIndex);
		this.textEllipsis.position.set(this.textHint.x + this.textHint.width / 2, this.textHint.y);

		this.timeToAnimateEllipsis = PreloaderHintView.ELLIPSIS_ANIMATION_TIME;

		this.ticker.add(this.updateEllipsis, this);
	}

	private updateHintText(): void {
		this.textHint.text = this.hints[this.nextHintId];

		if (this.nextHintId >= this.hints.length - 1) {
			this.nextHintId = 0;
		} else {
			this.nextHintId += 1;
		}

		this.textEllipsis.text = '';
		this.ticker.remove(this.updateEllipsis, this);
		const ellipsesIndex = this.textHint.text.search(/(\.\.\.)$/);
		if (ellipsesIndex > 0) {
			this.startEllipsisAnimation(ellipsesIndex);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.ticker.remove(this.update, this);
		this.ticker.remove(this.updateEllipsis, this);
		super.destroy(options);
	}
}
