import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30006View extends CharacterMainBaseView {
	constructor() {
		super('character30006');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-208, -4);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-20, -10),
			bgPosition: new PIXI.Point(100, -14),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
