import { AbstractQuest } from './AbstractQuest';
import { UpgradeModel } from '@models/UpgradeModel';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { CharacterModel } from '@models/CharacterModel';
import { QuestTypes } from '@src/types/QuestTypes';

export type QuestActivateTargetType = CharacterModel | UpgradeModel;

export class QuestActivateTarget<T extends QuestActivateTargetType> extends AbstractQuest {
	private readonly target: T;
	private readonly targetCount: number;
	private currentCount: number;

	constructor(
		questKey: string,
		questType: QuestTypes.ACTIVATE_CHARACTER | QuestTypes.ACTIVATE_UPGRADE,
		target: T,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.target = target;

		this.targetCount = 1;
		this.currentCount = target.isActivated() ? 1 : 0;

		if (this.targetCount === this.currentCount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public getTargetKey(): string {
		return this.target.getKey();
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentCount = Number(saveData.progress);

		if (this.targetCount === this.currentCount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentCount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetCount.toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentCount / this.targetCount;
	}

	public getTargetBusinessKey(): string {
		return this.target.getRelatedBusinessKey();
	}

	protected trackProgressStop(): void {
		if (this.target instanceof UpgradeModel) {
			this.target.off(UpgradeModel.EVENT_ACTIVATED, this.complete, this);
		} else if (this.target instanceof CharacterModel) {
			this.target.off(CharacterModel.EVENT_ACTIVATED, this.complete, this);
		}
	}

	protected trackProgressStart(): void {
		if (this.target instanceof UpgradeModel
			&& !this.target.isFreeActivation()) {
			this.target.on(UpgradeModel.EVENT_ACTIVATED, this.complete, this);
		} else if (this.target instanceof CharacterModel) {
			this.target.on(CharacterModel.EVENT_ACTIVATED, this.complete, this);
		}
	}
}
