import { BoostModel } from '@models/BoostModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { BusinessModel } from '@models/BusinessModel';
import { BaseBoostModel } from '@models/BaseBoostModel';

export class ModelWriterFarewellBoost {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly boostModel: BaseBoostModel;

	constructor(
		businessModels: Map<string, BusinessModel>,
		boostModel: BaseBoostModel,
	) {
		this.businessModels = businessModels;
		this.boostModel = boostModel;

		this.boostModel.on(BaseBoostModel.EVENT_ACTIVATED, this.onBoostActivated, this);
		this.boostModel.on(BaseBoostModel.EVENT_DEACTIVATED, this.onBoostDeactivated, this);
	}

	private onBoostActivated(boost: BoostModel): void {
		const bonusType = boost.getBonusType();
		switch (bonusType) {
			case BonusTypes.MULTIPLIER_INCOME: {
				this.businessModels.forEach(model => model.setFarwellBoostMultiplierIncome(boost.getBonusValue()));
				break;
			}
			default:
				throw new Error(`Unsupported boost bonus type '${bonusType}'`);
		}
	}

	private onBoostDeactivated(boost: BoostModel): void {
		const bonusType = boost.getBonusType();
		switch (bonusType) {
			case BonusTypes.MULTIPLIER_INCOME: {
				this.businessModels.forEach(model => model.clearFarewellBoostMultiplierIncome());
				break;
			}
			default:
				throw new Error(`Unsupported boost bonus type '${bonusType}'`);
		}
	}
}
