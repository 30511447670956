import * as TWEEN from '@tweenjs/tween.js';

type TweenData = {
	x: number;
	y: number;
	duration: number;
};

export type IncomeMoneySprite =
	PIXI.Sprite |
	PIXI.extras.AnimatedSprite;

export abstract class BaseIncomeMoneyAnimationView extends PIXI.Container {
	public static readonly EVENT_ANIMATION_COMPLETED: symbol = Symbol();

	protected sprite: PIXI.Sprite | PIXI.extras.AnimatedSprite;

	private createAnimationSprites(): IncomeMoneySprite[] {
		const result: IncomeMoneySprite[] = [];
		for (let i = 0; i < 6; i++) {
			result.push(this.createSprite());
		}
		return result;
	}

	protected abstract createSprite(): IncomeMoneySprite;

	private static random(min: number, max: number): number {
		const delta = max - min;
		return min + Math.ceil(Math.random() * delta);
	}

	private static generateLinearTweenData(): TweenData[] {
		return [
			// Sprite 1
			{
				x: -90,
				y: BaseIncomeMoneyAnimationView.random(-40, -60),
				duration: BaseIncomeMoneyAnimationView.random(130, 150),
			},
			// Sprite 2
			{
				x: -60,
				y: BaseIncomeMoneyAnimationView.random(-40, -20),
				duration: BaseIncomeMoneyAnimationView.random(130, 150),
			},
			// Sprite 3
			{
				x: -40,
				y: 0,
				duration: BaseIncomeMoneyAnimationView.random(150, 200),
			},
			// Sprite 4
			{
				x: 20,
				y: -40,
				duration: BaseIncomeMoneyAnimationView.random(130, 180),
			},
			// Sprite 5
			{
				x: 50,
				y: -50,
				duration: BaseIncomeMoneyAnimationView.random(130, 190),
			},
			// Sprite 6
			{
				x: 80,
				y: -40,
				duration: BaseIncomeMoneyAnimationView.random(140, 160),
			},
		];
	}

	private static generateBezierTweenData(targetX: number): TweenData[] {
		// Left to right ordering of sprites
		return [
			// Sprite 1
			{
				x: targetX - BaseIncomeMoneyAnimationView.random(100, 300),
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(5, 7),
			},
			// Sprite 2
			{
				x: targetX - BaseIncomeMoneyAnimationView.random(100, 150),
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(6, 9),
			},
			// Sprite 3
			{
				x: targetX,
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(7, 10),
			},
			// Sprite 4
			{
				x: targetX - BaseIncomeMoneyAnimationView.random(-200, 100),
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(8, 9),
			},
			// Sprite 5
			{
				x: targetX + BaseIncomeMoneyAnimationView.random(-50, 150),
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(9, 10),
			},
			// Sprite 6
			{
				x: targetX + BaseIncomeMoneyAnimationView.random(180, 260),
				y: 100,
				duration: BaseIncomeMoneyAnimationView.random(6, 10),
			},
		];
	}

	private targetPosition: PIXI.Point;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	constructor(animationTargetPosition: PIXI.Point) {
		super();

		this.targetPosition = animationTargetPosition;

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public playAnimation(startPosLocal: PIXI.Point): void {
		const linearTweenData: TweenData[] = BaseIncomeMoneyAnimationView.generateLinearTweenData();
		const bezierTweenData: TweenData[] = BaseIncomeMoneyAnimationView.generateBezierTweenData(this.targetPosition.x);
		const startPos = this.parent.toLocal(startPosLocal);

		const sprites = this.createAnimationSprites();
		sprites.forEach(sprite => sprite.position.set(startPos.x, startPos.y));
		this.addChild(...sprites);

		const spriteAnimationData: { endedCount: number } = {
			endedCount: 0,
		};
		sprites.forEach((sprite, i) => {
			if (sprite instanceof PIXI.extras.AnimatedSprite) {
				sprite.gotoAndPlay(BaseIncomeMoneyAnimationView.random(0, 5));
			}

			const x = sprite.position.x + linearTweenData[i].x;
			const y = sprite.position.y + linearTweenData[i].y;

			new TWEEN.Tween(sprite.position, this.tweenGroup)
				.to({ x, y }, linearTweenData[i].duration)
				.easing(TWEEN.Easing.Circular.In)
				.onComplete(() => this.onOneSpriteLinearTweenCompleted(sprite, spriteAnimationData, bezierTweenData[i]))
				.start();
		});
	}

	private onOneSpriteLinearTweenCompleted(target: PIXI.DisplayObject, animationData: any, tweenData: TweenData): void {
		new TWEEN.Tween(target.position, this.tweenGroup)
			.to({ x: [tweenData.x, this.targetPosition.x], y: [tweenData.y, this.targetPosition.y] }, tweenData.duration * 70)
			.interpolation(TWEEN.Interpolation.Bezier)
			.easing((k) => {
				if (k > 0.7) {
					return (k + 0.05 > 1) ? 1 : k + 0.05;
				}
				return k;
			})

			.onComplete(() => {
				animationData.endedCount += 1;
				this.onOneSpriteAnimationCompleted(target, animationData.endedCount);
			})
			.start();
	}

	/**
	 * Called when one money animation has been completed
	 */
	private onOneSpriteAnimationCompleted(target: PIXI.DisplayObject, spriteAnimationEndedCount: number): void {
		target.destroy();
		if (spriteAnimationEndedCount === 6) {
			this.onAnimationCompleted();
		}
	}

	/**
	 * Called when the whole animation has been completed
	 */
	private onAnimationCompleted(): void {
		this.emit(BaseIncomeMoneyAnimationView.EVENT_ANIMATION_COMPLETED);
		this.destroy();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);
		super.destroy(options);
	}
}
