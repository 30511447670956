import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '../text/TextField';

export class TextAreaInvalidInputView extends PIXI.Container {
	private readonly textMessage: PIXI.extras.BitmapText;

	constructor() {
		super();


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		bg.width = 280;
		bg.height = 64;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const arrow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2_arrow']);
		arrow.x = -bg.width / 2 - 10;

		const iconAttention = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['attention_icon']);
		iconAttention.x = -bg.width / 2 + 30;

		this.textMessage = new TextField('', { font: '25px wendyOne', tint: 0x7a7a7c, align: 'left' }, 210, 55);
		this.textMessage.anchor = new PIXI.Point(0, 0.5);
		this.textMessage.position.x = -82;

		this.addChild(
			bg,
			arrow as PIXI.DisplayObject,
			iconAttention,
			this.textMessage as PIXI.DisplayObject,
		);
	}

	public setText(text: string): void {
		this.textMessage.text = text;
	}
}
