import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithOldCostValueView';

export class BankOfferSpecialButtonBuyView extends ButtonBaseView {
	constructor(
		width: number,
		height: number,
		bankTabElementWithOffer: BankTabElementWithOfferType,
	) {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = width;
		buttonBg.height = height;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super(buttonBg);

		const newPriceCostResource = bankTabElementWithOffer.getButtonPriceResource();
		const newPriceLabel = new MultiColoredTextField({
			font: '43px wendyOneShadowBold',
		});
		newPriceLabel.text = `${newPriceCostResource}${bankTabElementWithOffer.getPrice()}`;
		newPriceLabel.anchor = 0.5;

		this.addChild(
			newPriceLabel,
		);

		if (bankTabElementWithOffer.hasOldPrice()) {
			const oldPriceCostResource = bankTabElementWithOffer.getButtonOldPriceResource();
			const oldPriceLabel = new MultiColoredTextField({
				font: '26px wendyOne',
			});
			if (oldPriceCostResource === ButtonValueTypes.DOLLAR) {
				oldPriceLabel.text = `[325514]${oldPriceCostResource}${bankTabElementWithOffer.getOldPrice()}[-]`;
			} else {
				oldPriceLabel.text = `${oldPriceCostResource}[325514]${bankTabElementWithOffer.getOldPrice()}[-]`;
			}
			oldPriceLabel.anchor = 0.5;

			newPriceLabel.position.set(0, 8);
			oldPriceLabel.position.set(0, -25);

			const scratchIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['scratch_icon']);

			scratchIcon.width = oldPriceLabel.width + 18;
			scratchIcon.position.set(oldPriceLabel.x, oldPriceLabel.y);


			this.addChild(
				oldPriceLabel,
				scratchIcon as PIXI.DisplayObject,
			);
		} else {
			newPriceLabel.position.set(0, 8);
		}
	}
}
