import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { ResourceMiniCardView } from './ResourceMiniCardView';
import { PromotableBaseMiniCardView } from './PromotableBaseMiniCardView';
import { BoostMiniCardView } from './BoostMiniCardView';
import { TimeskipMiniCardView } from './TimeskipMiniCardView';
import { AssetsStorage } from '@main/AssetsStorage';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';

export class CardMiniViewFactory {
	public static createStickerNew(): PIXI.Container {
		const result = new PIXI.Container();

		const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerSale.scale.set(0.5, 0.5);

		const saleLabel = new TextField(
			LocalizationStorage.getInstance().getLocalizedString('#video_sticker_new'),
			{ font: '18px wendyOne', align: 'center', tint: 0xfff78f },
			55,
			55,
		);
		saleLabel.anchor = 0.5;
		saleLabel.rotation = 0.47;

		result.addChild(
			stickerSale,
			saleLabel as PIXI.DisplayObject,
		);
		result.position.set(52, -78);

		return result;
	}

	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly boostModels: Map<string, BoostModel>;
	private readonly timeskipModels: Map<string, TimeskipModel>;

	constructor(
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		timeskipModels: Map<string, TimeskipModel>,
	) {
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.boostModels = boostModels;
		this.timeskipModels = timeskipModels;
	}

	public createRewardCardByTypeId(
		rewardType: RewardTypes,
		rewardId: string | RewardResourceIdTypes,
		scaleCard: number = 1,
		scaleResource: number = 1,
		stickerNew?: PIXI.Container,
		quantity?: number | string,
	): PIXI.Container {
		const card = this.resolveRewardCardByTypeId(
			rewardType,
			rewardId,
			scaleCard,
			scaleResource,
			stickerNew,
		);

		if (quantity != null) {
			this.addQuantity(card, quantity);
		}
		return card;
	}

	public createRewardCardByModel(
		reward: AbstractReward,
		addQuantityMark?: boolean,
		scaleCard: number = 1,
		scaleResource: number = 1,
		stickerNew?: PIXI.Container,
	): PIXI.Container {
		const card = this.resolveRewardCardByTypeId(
			reward.getType(),
			reward.getKey(),
			scaleCard,
			scaleResource,
			stickerNew,
		);

		if (addQuantityMark) {
			this.addQuantity(card, reward.getQuantityString());
		}
		return card;
	}

	// eslint-disable-next-line class-methods-use-this
	private addQuantity(card: PIXI.Container, quantity: number | string): void {
		const rewardCount = new PIXI.extras.BitmapText(`x${quantity}`, {
			font: '42px wendyOneShadowBold', tint: 0xffd86d,
		});
		rewardCount.anchor = 0.5;
		rewardCount.y = 72;

		const rewardCountFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow_half']);
		rewardCountFade.tint = 0x00000;
		rewardCountFade.alpha = 0.7;
		rewardCountFade.scale.set(1.6);
		rewardCountFade.y = rewardCount.y - 16;

		card.addChild(
			rewardCountFade,
			rewardCount as PIXI.DisplayObject,
		);
	}

	private resolveRewardCardByTypeId(
		rewardType: RewardTypes,
		rewardId: string | RewardResourceIdTypes,
		scaleCard: number,
		scaleResource: number,
		stickerNew?: PIXI.Container,
	): PIXI.Container {
		let card: PIXI.Container;
		let model: any;
		switch (rewardType) {
			case RewardTypes.RESOURCE: {
				card = new ResourceMiniCardView(rewardId as RewardResourceIdTypes);
				card.scale.set(scaleResource, scaleResource);
				break;
			}
			case RewardTypes.CHARACTER: {
				model = this.characterModels.get(rewardId);
				card = new PromotableBaseMiniCardView(model, stickerNew);
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.UPGRADE: {
				model = this.upgradeModels.get(rewardId);
				card = new PromotableBaseMiniCardView(model, stickerNew);
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.TOTEM: {
				model = this.totemModels.get(rewardId);
				card = new PromotableBaseMiniCardView(model, stickerNew);
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.BOOST: {
				model = this.boostModels.get(rewardId);
				card = new BoostMiniCardView(model.getIconKey());
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.TIMESKIP: {
				model = this.timeskipModels.get(rewardId);
				card = new TimeskipMiniCardView(model.getIconKey());
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			default:
				card = new PIXI.Container();
				break;
		}
		return card;
	}
}
