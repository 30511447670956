import { QuestWindowBaseViewSetter } from '@interfaces/ViewSetters';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { QuestWindowBaseView } from '@views/windows/quests/QuestWindowBaseView';
import { QuestRewardResultWindow } from '@views/windows/rewardResultWindow/QuestRewardResultWindow';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export abstract class BaseQuestWindowViewController extends PIXI.utils.EventEmitter implements QuestWindowBaseViewSetter {
	protected questWindow: QuestWindowBaseView;

	constructor(
		protected readonly questsLoader: QuestsLoaderBase,
		protected readonly windowViewSystem: WindowViewSystem,
		protected readonly windowFactory: WindowViewBaseFactory,
	) {
		super();
	}

	protected abstract onButtonCollectClick(quest: AbstractQuest): void;

	public setQuestWindowBaseView(view: QuestWindowBaseView): void {
		this.questWindow = view;
		this.questWindow.once(QuestWindowBaseView.EVENT_BUTTON_COLLECT_CLICK, this.onButtonCollectClick, this);
	}

	protected onFinishQuest(quest: AbstractQuest): Promise<void> {
		const rewardWindow = this.windowFactory.createQuestRewardResultWindow();
		const lootbox = quest.getLootbox();

		rewardWindow.init(lootbox.getRewards(), undefined, lootbox.getCardRarity());

		this.questsLoader.finishQuest(quest);
		this.questWindow.close();

		this.windowViewSystem.showWindow(rewardWindow);

		const closePromise = new Promise((resolve) => {
			rewardWindow.once(QuestRewardResultWindow.EVENT_CONTINUE_BUTTON_CLICK, resolve, this);
		});

		return closePromise
			// Next quest should be loaded only after 'Continue' button is pressed
			// to prevent new quest progress from previous quest rewards
			.then(() => this.questsLoader.createNextQuest(quest))
			.then(() => {
				// Closing window only when next quest is loaded
				// to prevent deadlocks during the tutorial
				rewardWindow.close();
			});
	}
}
