import { TextAreaInputParams, TextAreaInputView } from './TextAreaInputView';
import { TextInputType } from '@src/types/TextInputType';
import { DeviceUtils, DeviceId } from '@src/utils/DeviceUtils';
import { AssetsStorage } from '@main/AssetsStorage';
import { DualStateButtonBaseView } from '../buttons/DualStateButtonBaseView';
import { StringLengthValidator } from '@src/utils/validators/StringLengthValidator';
import { StringRegExpValidation } from '@src/utils/validators/StringRegExpValidation';
import { GameProfileModel } from '@models/GameProfileModel';

export class PasswordInputView extends TextAreaInputView {
	private static readonly PASSWORD_REG_EXP: RegExp = /^[a-zA-Z0-9]+$/;
	private static readonly FIREFOX_PASSWORD_CHAR: string = '\u25CF';
	private static readonly CHROME_AND_OTHERS_PASSWORD_CHARS: string = '\u2022';

	private togglePasswordVisibilityIcon: DualStateButtonBaseView;

	constructor(params: TextAreaInputParams) {
		super(params, 90);

		this.messageInput.setInputType(TextInputType.PASSWORD);
		const passwordChar = DeviceUtils.getDeviceId() === DeviceId.FIREFOX
			? PasswordInputView.FIREFOX_PASSWORD_CHAR : PasswordInputView.CHROME_AND_OTHERS_PASSWORD_CHARS;
		this.messageInput.setPasswordChar(passwordChar);

		this.togglePasswordVisibilityIcon = this.createToggleIcon();
		this.togglePasswordVisibilityIcon.position.set(270, 0);
		this.addChild(this.togglePasswordVisibilityIcon);

		const validator = new StringLengthValidator(
			'#error_password_length_label',
			GameProfileModel.INPUT_PASSWORD_MIN_LENGTH,
			GameProfileModel.INPUT_PASSWORD_MAX_LENGTH,
		);
		validator.setNext(new StringRegExpValidation('#error_invalid_password', PasswordInputView.PASSWORD_REG_EXP));
		this.setValidator(validator);
	}

	private onTogglePassword(): void {
		const currentInputType = this.messageInput.getInputType();
		if (currentInputType === TextInputType.PASSWORD) {
			this.messageInput.setInputType(TextInputType.TEXT);
		} else {
			this.messageInput.setInputType(TextInputType.PASSWORD);
		}
	}

	private createToggleIcon(): DualStateButtonBaseView {
		const icon = new DualStateButtonBaseView(
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['show_icon']),
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['hide_icon']),
		);
		icon.on('pointerdown', this.onTogglePassword, this);

		return icon;
	}
}
