export class BusinessAnimationsConfig {
	public static getMoneyFX(): any {
		return {
			alpha: {
				start: 1,
				end: 0.001,
			},
			scale: {
				start: 0.5,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffa600',
				end: '#ffd900',
			},
			speed: {
				start: 300,
				end: 10,
				minimumSpeedMultiplier: 0.5,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 100,
			startRotation: {
				min: 360,
				max: -360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 50,
				max: 50,
			},
			lifetime: {
				min: 0.1,
				max: 0.4,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 15,
			pos: {
				x: -30,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getBusinessBigGlow(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.1,
				end: 3,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffae00',
				end: '#ffa600',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.8,
				max: 0.8,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.2,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			angleStart: 0,
		};
	}

	public static getBusinessGlow2(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.1,
				end: 1.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffae00',
				end: '#ffa600',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.6,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.2,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			angleStart: 0,
		};
	}

	public static getBusinessGlow3(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 0.5,
				end: 0.5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffd37a',
				end: '#fab225',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 2500,
				y: 0,
			},
			maxSpeed: 2500,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.4,
				max: 0.4,
			},
			blendMode: 'add',
			frequency: 0.9,
			emitterLifetime: 1,
			maxParticles: 2,
			pos: {
				x: -200,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getBusinessGlow4(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 0.5,
				end: 0.5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffd37a',
				end: '#fab225',
			},
			speed: {
				start: -50,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: -2500,
				y: 0,
			},
			maxSpeed: 2500,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.4,
				max: 0.4,
			},
			blendMode: 'add',
			frequency: 0.9,
			emitterLifetime: 1,
			maxParticles: 2,
			pos: {
				x: 200,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getBusinessGlow5(): any {
		return {
			alpha: {
				start: 0.6,
				end: 0.1,
			},
			scale: {
				start: 0.001,
				end: 1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffae00',
				end: '#ffa600',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.2,
			maxParticles: 10,
			pos: {
				x: -30,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			angleStart: 0,
		};
	}

	public static getAppearance(): any {
		return {
			alpha: {
				start: 0.8,
				end: 0,
			},
			scale: {
				start: 0.01,
				end: 0.6,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffa600',
				end: '#ff99c2',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.3,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 50,
			},
		};
	}

	public static getAppearance2(): any {
		return {
			alpha: {
				start: 0.001,
				end: 1,
			},
			scale: {
				start: 0.5,
				end: 0.001,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#ffffff',
				end: '#ff3888',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 30,
				y: -50,
			},
			maxSpeed: 100,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.3,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 70,
			},
		};
	}

	public static getCharacterClosing(): any {
		return {
			alpha: {
				start: 0.001,
				end: 1,
			},
			scale: {
				start: 0.5,
				end: 0.001,
				minimumScaleMultiplier: 2,
			},
			color: {
				start: '#ffffff',
				end: '#ff3888',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: -50,
			},
			maxSpeed: 100,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 70,
			},
		};
	}
}
