import { UpgradeModel } from '@models/UpgradeModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { BusinessModel } from '@models/BusinessModel';
import { LevelBaseModel } from '@models/level/LevelBaseModel';
import { LevelModel } from '@models/level/LevelModel';
import { UpgradeAcquireAction } from '@models/network/actions/upgrades/UpgradeAcquireAction';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BaseAction } from '@models/network/actions/BaseAction';

export class ModelWriterUpgrade extends PIXI.utils.EventEmitter {
	private readonly upgradeBusinessMap: Map<string, BusinessModel>;

	constructor(
		private readonly upgradeModels: Map<string, UpgradeModel>,
		private readonly businessModels: Map<string, BusinessModel>,
		private readonly softMoneyModel: SoftMoneyModel,
		levelModel: LevelBaseModel,
	) {
		super();

		this.upgradeBusinessMap = new Map();

		this.upgradeModels.forEach((upgrade) => {
			upgrade.on(UpgradeModel.EVENT_ACTIVATED, this.onUpgradeActivated, this);
			upgrade.on(UpgradeModel.EVENT_PROMOTED, this.onUpgradePromoted, this);
		});

		levelModel.on(LevelModel.EVENT_LEVEL_CHANGE, () => {
			this.upgradeModels.forEach((upgrade) => {
				if (upgrade.isOpened() && upgrade.isFreeActivation()) {
					upgrade.activate();
				}
			});
		});

		Array.from(this.upgradeModels.values())
			.filter((upgrade) => upgrade.isFreeActivation() && upgrade.isLocked())
			.forEach((upgrade) => {
				upgrade.once(UpgradeModel.EVENT_UNLOCKED, this.onFurnitureUnlocked, this);
			});
	}

	private onUpgradeActivated(upgrade: UpgradeModel): void {
		this.setBusinessMultipliers(upgrade);
	}

	private onUpgradePromoted(upgrade: UpgradeModel): void {
		if (upgrade.isActivated()) {
			this.setBusinessMultipliers(upgrade);
		}
	}

	private setBusinessMultipliers(upgrade: UpgradeModel): void {
		const upgradeKey = upgrade.getKey();

		upgrade.getBonuses().forEach((bonus, type) => {
			const bonusValue = bonus.getValue();
			// eslint-disable-next-line default-case
			switch (type) {
				case BonusTypes.MULTIPLIER_INCOME: {
					const businessModel = this.getBusinessWithUpgrade(upgradeKey);
					businessModel.setUpgradeMultiplierIncome(upgradeKey, bonusValue);
					break;
				}
				case BonusTypes.MULTIPLIER_TIME: {
					const businessModel = this.getBusinessWithUpgrade(upgradeKey);
					businessModel.setUpgradeMultiplierTime(upgradeKey, bonusValue);
					break;
				}
				case BonusTypes.DECREASE_CUSTOMER_COST: {
					const businessModel = this.getBusinessWithUpgrade(upgradeKey);
					businessModel.setUpgradeMultiplierDecreaseCustomerCost(upgradeKey, bonusValue);
					break;
				}
				case BonusTypes.REDUCE_TIME_INCOME_BY_CLICK: {
					const businessModel = this.getBusinessWithUpgrade(upgradeKey);
					businessModel.setUpgradeReducePercentIncomeByClick(upgradeKey, bonusValue);
					break;
				}
			}
		});
	}

	private getBusinessWithUpgrade(upgradeKey: string): BusinessModel {
		if (this.upgradeBusinessMap.has(upgradeKey)) {
			return this.upgradeBusinessMap.get(upgradeKey);
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const model of this.businessModels.values()) {
			if (model.hasUpgrade(upgradeKey)) {
				this.upgradeBusinessMap.set(upgradeKey, model);
				return model;
			}
		}

		return null;
	}

	private onFurnitureUnlocked(upgrade: UpgradeModel): void {
		upgrade.activate();

		const action = new UpgradeAcquireAction(
			upgrade.getKey(),
			upgrade.getRelatedBusinessKey(),
			upgrade.getActivateCost().toRawString(),
			this.softMoneyModel.getValue().toRawString(),
		);

		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}
}
