import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class EventLevelDateConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_START_DATE: string = 'start_date';
	public static readonly FIELD_EVENT_ID: string = 'event_id';
	public static readonly FIELD_SHOW_PROMO: string = 'show_promo';

	private readonly key: string;
	private readonly eventLevelKey: string;
	private readonly showPromo: number;
	private readonly startDate: number;

	constructor(config: { [key: string]: string }) {
		this.key = config[EventLevelDateConfig.FIELD_KEY];

		this.eventLevelKey = config[EventLevelDateConfig.FIELD_EVENT_ID].trim();
		this.showPromo = Number(config[EventLevelDateConfig.FIELD_SHOW_PROMO].trim());

		const startDateRaw: string = config[EventLevelDateConfig.FIELD_START_DATE].trim();
		const startDateFormatted = startDateRaw.split(/\s+/);
		const [day, month, year] = startDateFormatted[0].split('.').map(value => value.trim());
		const [hours, minutes] = startDateFormatted[1].split(':').map(value => value.trim());
		const dateString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
		this.startDate = Math.round(new Date(dateString).getTime() / 1000);
	}

	public getKey(): string {
		return this.key;
	}

	public getEventLevelKey(): string {
		return this.eventLevelKey;
	}

	public getShowPromo(): number {
		return this.showPromo;
	}

	public getStartDate(): number {
		return this.startDate;
	}
}
