import { DialogConfig } from '@src/configs/DialogConfig';
import { DialogDataItem } from '@src/types/DialogTypes';

export class DialogModel extends PIXI.utils.EventEmitter {
	private readonly levelStartDialog: boolean;
	private readonly replaceCharacterMap: Map<string, string>;
	private readonly dialogData: DialogDataItem[];
	private readonly key: string;

	private currentDialogEntityId: number;

	constructor(
		config: DialogConfig,
		levelStartDialog: boolean = false,
	) {
		super();

		this.key = config.getKey();
		this.dialogData = config.getDialogData();
		this.currentDialogEntityId = -1;
		this.levelStartDialog = levelStartDialog;
		this.replaceCharacterMap = new Map();
	}

	public addReplaceCharacter(replaceCharacter: string, replaceValue: string): DialogModel {
		this.replaceCharacterMap.set(replaceCharacter, replaceValue);
		return this;
	}

	public getReplaceCharacterValue(replaceTarget: string): string {
		return this.replaceCharacterMap.get(replaceTarget);
	}

	public hasReplaceCharacters(): boolean {
		return this.replaceCharacterMap.size > 0;
	}

	public getReplaceCharacters(): string[] {
		return Array.from(this.replaceCharacterMap.keys());
	}

	public moveDialog(): void {
		this.currentDialogEntityId += 1;
	}

	public getCurrentDialogDataItem(): DialogDataItem {
		return this.dialogData[this.currentDialogEntityId];
	}

	public getCurrentDialogEntityId(): number {
		return this.currentDialogEntityId;
	}

	public isDialogCompleted(): boolean {
		return this.currentDialogEntityId >= this.dialogData.length;
	}

	public getKey(): string {
		return this.key;
	}

	public isLevelStartDialog(): boolean {
		return this.levelStartDialog;
	}

	public isLastPhrase(): boolean {
		return this.currentDialogEntityId === this.dialogData.length - 1;
	}
}
