import { AssetsStorage } from '@src/main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class EventIncomeProgressBarView extends PIXI.Container {
	private static readonly PROGRESS_BAR_WIDTH: number = 307;
	private static readonly PROGRESS_THRESHOLD_DELTA: number = 0.01;
	private static readonly PROGRESS_THRESHOLD_VALUE: number = 0.04;

	private readonly progressBar: PIXI.mesh.NineSlicePlane;
	private readonly incomeProgressText: PIXI.extras.BitmapText;
	private readonly iconMoney: PIXI.Sprite;

	private static deltaInterpolation(value: number): number {
		if (value < this.PROGRESS_THRESHOLD_DELTA) {
			return value * this.PROGRESS_THRESHOLD_VALUE / this.PROGRESS_THRESHOLD_DELTA;
		}
		const k: number = (1 - this.PROGRESS_THRESHOLD_VALUE) / (1 - this.PROGRESS_THRESHOLD_DELTA);
		return (value - this.PROGRESS_THRESHOLD_DELTA) * k + this.PROGRESS_THRESHOLD_VALUE;
	}

	constructor() {
		super();

		const incomeProgressBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_progress_bar_bg'], 4, 0, 11, 0);
		incomeProgressBg.width = 315;
		incomeProgressBg.pivot.set(incomeProgressBg.width / 2, incomeProgressBg.height / 2);

		this.progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_progress_bar'], 0, 0, 8, 0);
		this.progressBar.pivot.set(0, this.progressBar.height / 2);
		this.progressBar.position.set(-incomeProgressBg.width / 2 + 4, 1);

		this.incomeProgressText = new SizeableBitmapText('', 280, { font: '22px wendyOneShadowBold' });
		this.incomeProgressText.anchor = 0.5;
		this.incomeProgressText.position.set(incomeProgressBg.x + 15, incomeProgressBg.y);

		this.iconMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money']);

		this.interactive = true;

		this.addChild(
			incomeProgressBg,
			this.progressBar,
			this.incomeProgressText as PIXI.DisplayObject,
			this.iconMoney,
		);
	}

	public setProgress(
		current: string,
		total: string,
		delta: number,
	): void {
		this.setTextProgress(current, total);

		const interpolatedDelta: number = EventIncomeProgressBarView.deltaInterpolation(delta);
		this.setProgressBar(interpolatedDelta);
	}

	private setProgressBar(delta: number): void {
		this.progressBar.width = EventIncomeProgressBarView.PROGRESS_BAR_WIDTH * delta;

		this.updateIconMoneyPosition();
	}

	private setTextProgress(current: string, total: string): void {
		this.incomeProgressText.text = `${current}/${total}`;
	}

	private updateIconMoneyPosition(): void {
		this.iconMoney.position.set(
			this.incomeProgressText.x - this.incomeProgressText.width / 2 - 20,
			this.incomeProgressText.y,
		);
	}
}
