import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';

export abstract class BankOfferWindowUnlockTypeBaseModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_UNLOCKED: symbol = Symbol();

	protected active: boolean;

	protected onUnlocked(): void {
		this.emit(BankOfferWindowUnlockTypeBaseModel.EVENT_UNLOCKED, this);
	}

	public activate(): void {
		this.active = true;
		this.onActivate();
	}

	public deactivate(): void {
		this.active = false;
		this.onDeactivate();
	}

	protected abstract onActivate(): void;
	protected abstract onDeactivate(): void;

	public isActive(): boolean {
		return this.active;
	}
}

export class BankOfferWindowUnlockTypeCollectionWindowClosedModel extends BankOfferWindowUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	private collectionWindow: CollectionWindowView;

	constructor(
		windowViewSystem: WindowViewSystem,
	) {
		super();
		this.windowViewSystem = windowViewSystem;
	}

	protected onActivate(): void {
		this.active = true;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onCollectionWindowShowed, this);
	}

	protected onDeactivate(): void {
		this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onCollectionWindowShowed, this);
		this.collectionWindow?.off(CollectionWindowView.EVENT_WINDOW_CLOSED, this.onCollectionWindowClosed, this);
	}

	private onCollectionWindowShowed(window: CollectionWindowView): void {
		this.collectionWindow = window;
		this.collectionWindow.once(CollectionWindowView.EVENT_WINDOW_CLOSED, this.onCollectionWindowClosed, this);
	}

	private onCollectionWindowClosed(): void {
		this.onUnlocked();
	}
}

export class BankOfferWindowUnlockTypeBankWindowClosedModel extends BankOfferWindowUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;
	private bankWindow: BankWindowView;

	constructor(
		windowViewSystem: WindowViewSystem,
	) {
		super();
		this.windowViewSystem = windowViewSystem;
	}

	protected onActivate(): void {
		this.active = true;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, this.onBankWindowShowed, this);
	}

	protected onDeactivate(): void {
		this.windowViewSystem.off(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, this.onBankWindowShowed, this);
		this.bankWindow?.off(BankWindowView.EVENT_WINDOW_CLOSED, this.onBankWindowClosed, this);
	}

	private onBankWindowShowed(window: BankWindowView): void {
		this.bankWindow = window;
		this.bankWindow.once(BankWindowView.EVENT_WINDOW_CLOSED, this.onBankWindowClosed, this);
	}

	private onBankWindowClosed(): void {
		this.onUnlocked();
	}
}
