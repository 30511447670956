import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export class ShowPrestigeBonusView extends PIXI.Container {
	private readonly iconPrestige: PIXI.Sprite;
	private readonly textDropCount: PIXI.extras.BitmapText;

	private glow: PIXI.Sprite;

	private amount: number;

	private tweenPulse: TWEEN.Tween;
	private tweenFade: TWEEN.Tween;
	private tweenBounce: TWEEN.Tween;
	private tweenGlow: TWEEN.Tween;

	constructor() {
		super();

		this.amount = 0;


		this.iconPrestige = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_prestige']);

		this.textDropCount = new PIXI.extras.BitmapText('', { font: '30px wendyOneShadowBold' });
		this.textDropCount.anchor = 0.5;
		this.textDropCount.position.set(0, 28);

		this.addChild(
			this.iconPrestige,
			this.textDropCount as PIXI.DisplayObject,
		);

		this.on('pointerdown', this.onPointerDown, this);
	}

	public setAmount(value: number): void {
		this.amount = value;
		this.textDropCount.text = `${this.amount}`;
	}

	private stopPulse(): void {
		if (this.tweenPulse) {
			this.tweenPulse.stop();
			this.tweenPulse = null;
		}
	}

	public startAppearingAnimation(): void {
		this.glow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['prestige_customer_bonus']);
		this.glow.alpha = 0.8;
		this.addChildAt(this.glow, 0);

		this.tweenGlow = new TWEEN.Tween(this.glow)
			.to({ alpha: 0.4 }, 500)
			.yoyo(true)
			.repeat(Infinity)
			.start();

		this.alpha = 0;
		this.tweenFade = new TWEEN.Tween(this)
			.to({ alpha: 1 }, 500)
			.start();

		this.scale.set(1);
		this.tweenBounce = new TWEEN.Tween(this.scale)
			.to({ x: 1.2, y: 1.2 }, 500)
			.easing(TWEEN.Easing.Back.Out)
			.repeat(1)
			.yoyo(true)
			.onComplete(() => this.startPulse())
			.start();
	}

	private stopAppearingAnimation(): void {
		if (this.tweenFade) {
			this.tweenFade.stop();
			this.tweenFade = null;
		}

		if (this.tweenBounce) {
			this.tweenBounce.stop();
			this.tweenBounce = null;
		}

		if (this.tweenGlow) {
			this.tweenGlow.stop();
			this.tweenGlow = null;
		}

		this.alpha = 1;
		this.scale.set(1);
	}

	private startPulse(): void {
		this.tweenPulse = new TWEEN.Tween(this.scale)
			.to({ x: this.scale.x + 0.1, y: this.scale.y + 0.1 }, 500)
			.repeat(Infinity)
			.yoyo(true)
			.start();
	}

	// eslint-disable-next-line class-methods-use-this
	public onPointerDown(e: PIXI.interaction.InteractionEvent): void {
		e.stopPropagation();
	}

	public onZoomIn(): void {
		this.visible = false;
	}

	public onZoomOut(): void {
		this.visible = true;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.stopAppearingAnimation();
		this.stopPulse();
		super.destroy(options);
	}
}
