import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { CardRarities, CardRarity } from '@src/types/CardRarities';
import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableModel } from '@models/PromotableModel';
import { LevelStartCardViewFactory } from '@views/windows/levelStartWindow/LevelStartCardViewFactory';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { LevelStartCardsLockIconView } from '@views/windows/levelStartWindow/LevelStartCardsLockIconView';

export class LevelStartCardsGalleryView extends PIXI.Container {
	public static readonly EVENT_ALL_CARDS_OPENED: symbol = Symbol();

	private static readonly MARGIN_X: number = 70;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly localizationStorage: LocalizationStorage;

	private readonly cardsContainer: PIXI.Container;
	private readonly textDescription: MultiColoredTextField;

	private readonly allCards: PromotableModel[];
	private cardsToOpen: number;

	private timeoutIds: number[];

	constructor(
		unlockableModels: PromotableModel[],
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.timeoutIds = [];

		this.allCards = unlockableModels;
		this.cardsToOpen = 0;
		this.cardsContainer = new PIXI.Container();
		this.cardsContainer.position.set(GameConstants.GAME_CENTER_X, 640);
		this.cardsContainer.scale.set(0.76);

		this.textDescription = new MultiColoredTextField({
			font: '26px wendyOneShadowBold',
			align: 'center',
		});
		this.textDescription.anchor = 0.5;
		this.textDescription.position.set(GameConstants.GAME_CENTER_X, 450);
		this.textDescription.text = this.localizationStorage.getLocalizedString('#level_start_cards_gallery_descr');

		this.addChild(
			this.textDescription,
			this.cardsContainer,
		);
	}

	public startAppearingAnimation(delay: number): void {
		this.clearTimeouts();

		const timeoutId = window.setTimeout(() => this.startCardsAppearingAnimation(), delay);
		this.timeoutIds.push(timeoutId);
	}

	private startCardsAppearingAnimation(): void {
		const cardsGroup = this.allCards.slice(0);
		this.cardsToOpen = cardsGroup.length;


		// Calculate rows size
		const firstRowCount = cardsGroup.length;
		// Default card width
		const cardWidth = 234;
		// Calculate rows width
		const firstRowWidth = (firstRowCount * cardWidth + ((firstRowCount - 1) * LevelStartCardsGalleryView.MARGIN_X));

		this.cardsContainer.removeChildren();
		this.cardsContainer.visible = true;
		this.cardsContainer.alpha = 1;

		cardsGroup.forEach((model, index) => {
			const cardContainer = new PIXI.Container();

			const cardObj = LevelStartCardViewFactory.createCard(model);

			cardObj.scale.set(0.43);
			const cardRarity = model.getCardRarity();

			const rarityBgSprite = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['summon_rarity_bg']);
			rarityBgSprite.tint = cardRarity
				? CardRarities.getRarityColor(cardRarity)
				: CardRarities.getRarityColor(CardRarity.COMMON);
			rarityBgSprite.alpha = 0;
			rarityBgSprite.blendMode = PIXI.BLEND_MODES.ADD;

			const lockIcon = new LevelStartCardsLockIconView();
			lockIcon.scale.set(0.8);
			lockIcon.y = -30;

			cardContainer.addChild(
				rarityBgSprite,
				cardObj as PIXI.DisplayObject,
				lockIcon,
			);

			const cardShowAnimationDelay = index * 400;
			const lockIconOpenAnimationDelay = 600 + cardShowAnimationDelay;
			lockIcon.startOpenAnimation(lockIconOpenAnimationDelay, this.afterCardOpen.bind(this));

			cardContainer.x = -(firstRowWidth / 2)
				+ (index * cardWidth)
				+ (index * LevelStartCardsGalleryView.MARGIN_X)
				+ (cardWidth / 2);

			cardContainer.alpha = 0;

			// Alpha tween
			new TWEEN.Tween(cardContainer, this.tweenGroup)
				.to({ alpha: 1 }, 300)
				.delay(cardShowAnimationDelay)
				.start();

			const scaleUpTween = new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
				.to({ x: 1.2, y: 1.2 }, 400)
				.delay(cardShowAnimationDelay)
				.easing(TWEEN.Easing.Sinusoidal.Out);

			const scaleDownTween = new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
				.to({ x: 1, y: 1 }, 100)
				.easing(TWEEN.Easing.Sinusoidal.Out);

			scaleUpTween.chain(scaleDownTween);
			scaleUpTween.start();

			this.cardsContainer.addChild(cardContainer);
		});
	}

	protected afterCardOpen(): void {
		this.cardsToOpen -= 1;
		if (this.cardsToOpen === 0) {
			this.afterAllCardsOpened();
		}
	}

	protected afterAllCardsOpened(): void {
		this.emit(LevelStartCardsGalleryView.EVENT_ALL_CARDS_OPENED);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private clearTimeouts(): void {
		while (this.timeoutIds.length > 0) {
			const id = this.timeoutIds.pop();
			window.clearTimeout(id);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.clearTimeouts();

		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		super.destroy(options);
	}
}
