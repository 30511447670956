export class QuestParticleConfig {
	public static getQuestFlare(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.7,
				end: 0.01,
				minimumScaleMultiplier: 0.01,
			},
			color: {
				start: '#fff700',
				end: '#ff2b00',
			},
			speed: {
				start: 200,
				end: 1,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 360,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 5,
				max: 0.1,
			},
			blendMode: 'add',
			frequency: 0.006,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -400,
				y: 0,
				w: 800,
				h: 0,
			},
		};
	}

	public static getQuestGlow(colorStart: string = '#ff7e00', colorEnd: string = '#fff000'): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 8,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: colorStart,
				end: colorEnd,
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -5,
				max: 5,
			},
			lifetime: {
				min: 5,
				max: 8,
			},
			blendMode: 'add',
			frequency: 0.3,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}
}
