import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { ConfigSetElementConstructor } from '@interfaces/ConfigSetElementConstructor';
import { QuestAcquireBusinessConfig, QuestAcquireBusinessesConfig } from '@configs/quests/QuestAcquireBusinessConfig';
import { QuestGetPrestigeMoneyConfig } from '@configs/quests/QuestGetPrestigeMoneyConfig';
import { QuestGetCustomersConfig } from '@configs/quests/QuestGetCustomersConfig';
import { QuestGetCustomersMultiplierConfig } from '@configs/quests/QuestGetCustomersMultiplierConfig';
import { QuestGetCustomersOnSomeConfig } from '@configs/quests/QuestGetCustomersOnSomeConfig';
import { QuestGetCardsConfig } from '@configs/quests/QuestGetCardsConfig';
import {
	QuestPromoteCharactersConfig,
	QuestPromoteUpgradesConfig,
	QuestPromoteCharactersOrUpgradesConfig,
	QuestPromoteAllConfig,
} from '@configs/quests/QuestPromoteConfig';
import { QuestActivateCharactersConfig, QuestActivateUpgradesConfig, QuestActivatePartyConfig } from '@configs/quests/QuestActivateConfig';
import { QuestAutomateBusinessConfig, QuestAutomateBusinessesConfig } from '@configs/quests/QuestAutomateBusinessConfig';
import { QuestSummonConfig } from '@configs/quests/QuestSummonConfig';
import { QuestGetHardMoneyConfig } from '@configs/quests/QuestGetHardMoneyConfig';
import { QuestSpendMoneyConfig } from '@configs/quests/QuestSpendMoneyConfig';
import { QuestGetSoftMoneyTutorialConfig } from '@configs/quests/QuestGetSoftMoneyTutorialConfig';
import { QuestGetSoftMoneyConfig } from '@configs/quests/QuestGetSoftMoneyConfig';

export class QuestsConfigSetParser extends BaseConfigSetParser<QuestConfigBase> {
	constructor() {
		super(
			QuestConfigBase.FIELD_KEY,
			undefined,
			QuestsConfigSetParser.getConfigSetElementConstructor,
		);
	}

	private static getConfigSetElementConstructor(configSetElementJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const questType = configSetElementJSON[QuestConfigBase.FIELD_TYPE];
		switch (questType) {
			case QuestConfigBase.TYPE_ACQUIRE:
				return QuestsConfigSetParser.resolveQuestTypeAcquire(configSetElementJSON);
			case QuestConfigBase.TYPE_GET:
				return QuestsConfigSetParser.resolveQuestTypeGet(configSetElementJSON);
			case QuestConfigBase.TYPE_FIND_CARD:
				return QuestsConfigSetParser.resolveQuestTypeFindCard(configSetElementJSON);
			case QuestConfigBase.TYPE_PROMOTE:
				return QuestsConfigSetParser.resolveQuestTypePromote(configSetElementJSON);
			case QuestConfigBase.TYPE_ACTIVATE:
				return QuestsConfigSetParser.resolveQuestTypeActivate(configSetElementJSON);
			case QuestConfigBase.TYPE_SPEND:
				return QuestsConfigSetParser.resolveQuestTypeSpend(configSetElementJSON);
			case QuestConfigBase.TYPE_AUTOMATE:
				return QuestsConfigSetParser.resolveQuestTypeAutomate(configSetElementJSON);
			case QuestConfigBase.TYPE_SUMMON:
				return QuestsConfigSetParser.resolveQuestTypeSummon(configSetElementJSON);
			default:
				throw new Error(`Unsupported quest config type '${questType}'`);
		}
	}

	private static resolveQuestTypeAcquire(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_BUSINESS: {
				const params = configJSON[QuestConfigBase.FIELD_PARAMETERS] as string;
				if (params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)) {
					return QuestAcquireBusinessConfig;
				}
				return QuestAcquireBusinessesConfig;
			}

			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeAutomate(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_BUSINESS: {
				const params = configJSON[QuestConfigBase.FIELD_PARAMETERS] as string;
				if (params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)) {
					return QuestAutomateBusinessConfig;
				}
				return QuestAutomateBusinessesConfig;
			}

			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeGet(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_SOFT:
			case QuestConfigBase.SUB_TYPE_SOFT_AUTO:
				return QuestGetSoftMoneyConfig;
			case QuestConfigBase.SUB_TYPE_SOFT_TUTORIAL:
				return QuestGetSoftMoneyTutorialConfig;
			case QuestConfigBase.SUB_TYPE_PRESTIGE:
				return QuestGetPrestigeMoneyConfig;
			case QuestConfigBase.SUB_TYPE_CUSTOMERS:
				return QuestGetCustomersConfig;
			case QuestConfigBase.SUB_TYPE_CUSTOMERS_MULTIPLIER:
				return QuestGetCustomersMultiplierConfig;
			case QuestConfigBase.SUB_TYPE_CUSTOMERS_ON_SOME:
				return QuestGetCustomersOnSomeConfig;
			case QuestConfigBase.SUB_TYPE_HARD:
				return QuestGetHardMoneyConfig;
			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeFindCard(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_ALL:
				return QuestGetCardsConfig;
			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypePromote(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_GIRLS:
				return QuestPromoteCharactersConfig;
			case QuestConfigBase.SUB_TYPE_UPGRADE:
				return QuestPromoteUpgradesConfig;
			case QuestConfigBase.SUB_TYPE_GIRLS_UPGRADES:
				return QuestPromoteCharactersOrUpgradesConfig;
			case QuestConfigBase.SUB_TYPE_ALL:
				return QuestPromoteAllConfig;
			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeActivate(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_GIRLS:
				return QuestActivateCharactersConfig;
			case QuestConfigBase.SUB_TYPE_UPGRADE:
				return QuestActivateUpgradesConfig;
			case QuestConfigBase.SUB_TYPE_PARTY:
				return QuestActivatePartyConfig;
			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeSpend(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_PRESTIGE:
			case QuestConfigBase.SUB_TYPE_HARD:
				return QuestSpendMoneyConfig;
			default:
				throw new Error(`Unsupported quest subtype '${subType}'`);
		}
	}

	private static resolveQuestTypeSummon(configJSON: any): ConfigSetElementConstructor<QuestConfigBase> {
		const subType: string = configJSON[QuestConfigBase.FIELD_SUB_TYPE];
		switch (subType) {
			case QuestConfigBase.SUB_TYPE_ALL:
			case QuestConfigBase.SUB_TYPE_PRESTIGE:
			case QuestConfigBase.SUB_TYPE_PAY:
			case QuestConfigBase.SUB_TYPE_HARD:
				return QuestSummonConfig;

			default:
				throw new Error(`Unsupported summon quest subtype '${subType}`);
		}
	}
}
