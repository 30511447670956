import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class DailyRewardLoader {
	private isInited: boolean;
	private timeoutHandle?: number;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly dailyRewardsModel: DailyRewardsModel,
		private readonly serverTimeModel: ServerTimeModel,
	) {}

	public async init(): Promise<void> {
		if (!this.isInited) {
			this.isInited = true;
			await this.updateDailyReward();
		}
	}

	public async updateDailyReward(): Promise<void> {
		if (this.timeoutHandle != null) {
			window.clearTimeout(this.timeoutHandle);
		}

		const timestamp = this.serverTimeModel.getCalculatedISOTime();
		const data = await this.networkRequestSender.sendGetDailyRewardsInfo(timestamp);

		// daily_bonuses can be null if feature is disabled
		if (data != null) {
			this.dailyRewardsModel.updateCurrentRewardData(
				data.day,
				data.type,
				data.applied,
				data.next_update,
			);

			// schedule next update
			this.timeoutHandle = window.setTimeout(
				this.updateDailyReward.bind(this),
				this.dailyRewardsModel.getNextRewardPeriod() * 1000,
			);
		}
	}
}
