import { CharacterModel } from '@models/CharacterModel';
import { BusinessModel } from '@models/BusinessModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { SkillModel } from '@models/skills/SkillModel';

export class ModelWriterCharacter {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly skillModels: Map<string, SkillModel>;
	private readonly characterModels: Map<string, CharacterModel>;

	constructor(
		characterModels: Map<string, CharacterModel>,
		businessModels: Map<string, BusinessModel>,
		skillModels: Map<string, SkillModel>,
	) {
		this.businessModels = businessModels;
		this.characterModels = characterModels;
		this.skillModels = skillModels;

		this.characterModels.forEach((character) => {
			character.on(CharacterModel.EVENT_ACTIVATED, this.onCharacterActivated, this);
			character.on(CharacterModel.EVENT_PROMOTED, this.onCharacterPromoted, this);
			character.on(CharacterModel.EVENT_OPENED, this.onCharacterOpened, this);
		});
	}

	private onCharacterActivated(character: CharacterModel): void {
		this.setBusinessMultipliers(character);

		const business = this.businessModels.get(character.getRelatedBusinessKey());
		if (!business.isAutomated() && character.hasBonusAutomate()) {
			business.automate();
		}
	}

	private onCharacterPromoted(character: CharacterModel): void {
		if (character.isActivated()) {
			this.setBusinessMultipliers(character);
		}

		if (character.hasBonusSkill()) {
			const skill = this.getCharacterSkill(character);
			skill.setCharacterLevel(character.getLevel());
		}
	}

	private onCharacterOpened(character: CharacterModel): void {
		if (character.hasBonusSkill()) {
			const skill = this.getCharacterSkill(character);
			skill.setCharacterLevel(character.getLevel());
			skill.open();
		}
	}

	private getCharacterSkill(character: CharacterModel): SkillModel {
		const characterSkillKey = character.getSkillKey();
		return this.skillModels.get(characterSkillKey);
	}

	private setBusinessMultipliers(character: CharacterModel): void {
		const updateMultipliers = (business: BusinessModel): void => {
			const characterKey = character.getKey();
			const affectsAll: boolean = character.isAffectsAllBusinesses();
			character.getBonuses().forEach((bonus, type) => {
				const bonusValue = bonus.getValue();
				// eslint-disable-next-line default-case
				switch (type) {
					case BonusTypes.MULTIPLIER_INCOME: {
						if (affectsAll) {
							business.setCharacterMultiplierIncomeAffectAll(characterKey, bonusValue);
						} else {
							business.setCharacterMultiplierIncome(characterKey, bonusValue);
						}
						break;
					}
					case BonusTypes.MULTIPLIER_TIME: {
						if (affectsAll) {
							business.setCharacterMultiplierTimeAffectAll(characterKey, bonusValue);
						} else {
							business.setCharacterMultiplierTime(characterKey, bonusValue);
						}
						break;
					}
					case BonusTypes.DECREASE_CUSTOMER_COST: {
						if (affectsAll) {
							business.setCharacterMultiplierDecreaseCustomerCostAffectAll(characterKey, bonusValue);
						} else {
							business.setCharacterMultiplierDecreaseCustomerCost(characterKey, bonusValue);
						}
						break;
					}
				}
			});
		};

		if (character.isAffectsAllBusinesses()) {
			this.businessModels.forEach(updateMultipliers);
		} else {
			const business = this.businessModels.get(character.getRelatedBusinessKey());
			updateMultipliers(business);
		}
	}
}
