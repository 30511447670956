import { RewardDescriptionType } from './RewardTypes';

export type TransactionOptions = {
	eventKey: string;
	image: string;
	description: string;
	name: string;
}

export type TransactionResult = {
	rewards: RewardDescriptionType[];
	transactionId: string;
	notCommitedTransactionId: number;
}

export enum PaymentMethod {
	EPOCH = 'epoch',
	CARDPAY = 'cardPay',
}
