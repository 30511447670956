import LocalizationStorage from '@main/LocalizationStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { ButtonBackgroundType, ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { Emitter } from 'pixi-particles';
import { LevelStartWindowParticlesConfigs } from '@views/windows/levelStartWindow/LevelStartWindowParticlesConfigs';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { LevelStartCardsGalleryView } from '@views/windows/levelStartWindow/LevelStartCardsGalleryView';
import { PromotableModel } from '@models/PromotableModel';

export class LevelStartWindowView extends WindowBaseView {
	public static readonly EVENT_BUTTON_START_CLICK: symbol = Symbol();

	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly animationEmitters: Emitter[];

	private readonly buttonStart: ButtonWithLabelBaseView;
	private readonly descriptionContainer: PIXI.Container;
	private readonly cardsGallery: LevelStartCardsGalleryView;
	private readonly unlockableModels: PromotableModel[];

	constructor(
		season: number,
		level: number,
		unlockableModels: PromotableModel[],
	) {
		super(1, false);

		this.unlockableModels = unlockableModels;

		const localizationStorage = LocalizationStorage.getInstance();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
		this.animationEmitters = [];

		this.descriptionContainer = new PIXI.Container();
		this.descriptionContainer.position.set(GameConstants.GAME_CENTER_X, 506);

		const decor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor.scale.set(2, 2);
		decor.position.set(73, 22);

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.position.set(27, 0);
		episodeNamePanelFade.scale.set(2);

		const levelDescPanel = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel'], 37, 0, 37, 0);
		levelDescPanel.width = 873;
		levelDescPanel.height = 125;
		levelDescPanel.pivot.set(levelDescPanel.width / 2, levelDescPanel.height / 2);
		levelDescPanel.position.set(0, 0);

		const glowLine = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine.position.set(-15, -62);

		const levelNumberPanel = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
		levelNumberPanel.width = 239;
		levelNumberPanel.height = 40;
		levelNumberPanel.pivot.set(levelNumberPanel.width / 2, levelNumberPanel.height / 2);
		levelNumberPanel.position.set(0, -60);

		const levelNameStr = localizationStorage.getLocalizedString('#main_window_level_progress')
			.replace('{{season}}', season.toString())
			.replace('{{value}}', level.toString());

		const levelNumberText = new PIXI.extras.BitmapText(levelNameStr, {
			font: '24px wendyOne',
		});
		levelNumberText.anchor = 0.5;
		levelNumberText.position.set(0, -60);

		const levelDescStr = localizationStorage.getLocalizedString(`#level${season}${level}_desc`);
		const levelDesc = new SizeableBitmapText(levelDescStr, 873, { font: '62px wendyOneGold' });
		levelDesc.anchor = 0.5;
		levelDesc.position.set(0, 5);

		this.buttonStart = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 235, 84),
			{ font: '34px wendyOneShadowBold' },
		);
		this.buttonStart.position.set(GameConstants.GAME_CENTER_X, 640);
		this.buttonStart.alpha = 0;
		this.buttonStart.setTextLabel(localizationStorage.getLocalizedString('#level_start_button'));
		this.buttonStart.setEnabled(false);
		this.buttonStart.once(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonStartClick, this);

		const effectsContainer = this.createEffects();

		this.addChild(
			this.descriptionContainer,
			this.buttonStart,
		);

		this.descriptionContainer.addChild(
			decor,
			effectsContainer,
			episodeNamePanelFade,
			levelDescPanel,
			glowLine,
			levelNumberPanel,
			levelNumberText as PIXI.DisplayObject,
			levelDesc,
		);

		if (this.unlockableModels.length > 0) {
			this.cardsGallery = new LevelStartCardsGalleryView(this.unlockableModels);
			this.cardsGallery.once(LevelStartCardsGalleryView.EVENT_ALL_CARDS_OPENED, this.onAllCardsOpened, this);
			this.addChild(this.cardsGallery);

			this.buttonStart.y = 841;
			this.descriptionContainer.y = 293;
		}

		this.startAppearingAnimation();
	}

	private createEffects(): PIXI.Container {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const effectsContainer = new PIXI.Container();
		effectsContainer.scale.set(0.75, 0.35);
		effectsContainer.position.set(0, -20);

		const glowEmitter = new Emitter(
			effectsContainer,
			[fxAtlas['skill_activ_glow1']],
			LevelStartWindowParticlesConfigs.GLOW,
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowLightsEmitter = new Emitter(
			effectsContainer,
			[fxAtlas['party_button_glow4']],
			LevelStartWindowParticlesConfigs.GLOW2,
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		return effectsContainer;
	}

	private onButtonStartClick(): void {
		this.emit(LevelStartWindowView.EVENT_BUTTON_START_CLICK);
		this.onClose();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private startAppearingAnimation(): void {
		new TWEEN.Tween(this.blackout, this.tweenGroup)
			.to({ alpha: 0.6 }, 300)
			.start();

		if (this.cardsGallery) {
			this.cardsGallery.startAppearingAnimation(350);
		} else {
			this.showStartButton(350, 400);
		}
	}

	private showStartButton(showDelay: number, showDuration: number): void {
		const buttonShowDelay = showDelay + 50;
		new TWEEN.Tween(this.buttonStart, this.tweenGroup)
			.to({ alpha: 1 }, showDuration)
			.easing(TWEEN.Easing.Sinusoidal.Out)
			.delay(buttonShowDelay)
			.start();

		this.buttonStart.scale.set(0);
		new TWEEN.Tween(this.buttonStart.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, showDuration)
			.easing(TWEEN.Easing.Sinusoidal.Out)
			.delay(buttonShowDelay)
			.onComplete(() => {
				this.buttonStart.setEnabled(true);
			})
			.start();
	}

	private onAllCardsOpened(): void {
		this.showStartButton(100, 400);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.cardsGallery) {
			(this.cardsGallery as PIXI.utils.EventEmitter).off(LevelStartCardsGalleryView.EVENT_ALL_CARDS_OPENED, this.onAllCardsOpened, this, true);
		}

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		super.destroy(options);
	}
}
