import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';

export class AccountNotCreatedWarningWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_GUEST_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_REGISTER_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private animationEmitters: Emitter[];

	constructor() {
		super(0.7);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.animationEmitters = [];

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 722;
		bg.height = 405;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 84;
		bg.interactive = true;

		const bgWhite = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		bgWhite.width = 645;
		bgWhite.height = 134;
		bgWhite.pivot.set(bgWhite.width / 2, bgWhite.height / 2);
		bgWhite.y = 51;

		const bgGirls = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['go_to_party_window_girls']);
		bgGirls.y = -270;

		const bgTexture = new PIXI.Sprite(this.fxAtlas['texture_decor']);
		bgTexture.y = -110;
		bgTexture.blendMode = PIXI.BLEND_MODES.ADD;
		bgTexture.scale.set(4.5);
		bgTexture.tint = 0xffffff;

		const stringTitle = this.localizationStorage.getLocalizedString('#connect_account_title');
		const textTitle = new SizeableBitmapText(stringTitle, 750, { font: '38px wendyOneShadowBold' });
		textTitle.anchor = 0.5;
		textTitle.y = -66;

		const textBody = new TextField(
			this.localizationStorage.getLocalizedString('#connect_account_body'),
			{ font: '26px wendyOne', tint: 0x465768, align: 'center' },
			630,
			105,
		);
		textBody.y = 50;
		textBody.anchor = 0.5;

		const buttonGuest = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 313, 95),
			{ font: '32px wendyOneShadowBold' },
			300,
		);
		buttonGuest.setTextLabel(this.localizationStorage.getLocalizedString('#connect_account_guest'));
		buttonGuest.on(ButtonWithLabelBaseView.EVENT_CLICK, () => {
			this.emit(AccountNotCreatedWarningWindowView.EVENT_BUTTON_GUEST_CLICK);
			this.onClose();
		});
		buttonGuest.position.set(-166, 193);

		const buttonRegister = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 313, 95),
			{ font: '32px wendyOneShadowBold' },
			300,
		);
		buttonRegister.setTextLabel(this.localizationStorage.getLocalizedString('#connect_account_register'));
		buttonRegister.once(ButtonWithLabelBaseView.EVENT_CLICK, () => {
			this.emit(AccountNotCreatedWarningWindowView.EVENT_BUTTON_REGISTER_CLICK);
			this.onClose();
		});
		buttonRegister.position.set(166, 193);

		const animationsContainer = new PIXI.Container();
		animationsContainer.y = -100;
		animationsContainer.scale.set(0.8);
		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.mainContainer.addChild(
			animationsContainer,
			bgTexture,
			bgGirls,
			bg,
			bgWhite,
			textTitle as PIXI.DisplayObject,
			textBody,
			buttonGuest,
			buttonRegister,
		);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
