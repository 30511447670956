import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20016View extends CharacterMainBaseView {
	constructor() {
		super('character20016');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-32, 21);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(152, -81),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(336, -63),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
