import { BankPurchaseOriginType } from '@src/types/BankTypes';
import {
	BankSaveData,
	BankBundleSaveData,
	OffersSaveData,
	RechargeSaveData,
} from '@src/types/SaveTypes';
import { BankOfferModel } from './BankOfferModel';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class BankSavesModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_BUNDLE_REWARDS: symbol = Symbol();

	public static readonly EVENT_BANK_PURCHASE_OFFER_SUCCESS: symbol = Symbol();
	public static readonly EVENT_BANK_PURCHASE_SUCCESS: symbol = Symbol();
	public static readonly EVENT_BANK_FREE_PURCHASE_SUCCESS: symbol = Symbol();

	private purchases: Map<string, number>;
	private recharges: RechargeSaveData;
	private bundles: Map<string, BankBundleSaveData>;
	private offers: OffersSaveData;

	constructor(
		bankSavesData: BankSaveData,
	) {
		super();

		this.purchases = new Map();
		this.bundles = new Map();
		bankSavesData.bundles.forEach((value: BankBundleSaveData, key: string) => {
			this.bundles.set(key, {
				acceptedBuyTimes: value.acceptedBuyTimes,
				rewards: value.rewards.splice(0),
				expiresOn: value.expiresOn,
			});
		});
		this.offers = {
			activeEndTimes: {},
			activations: {},
		};
		Object.keys(bankSavesData.offersSaves.activations || []).forEach(key => {
			this.offers.activations[key.toString()] = bankSavesData.offersSaves.activations[key];
		});
		Object.keys(bankSavesData.offersSaves.activeEndTimes || []).forEach(key => {
			this.offers.activeEndTimes[key.toString()] = bankSavesData.offersSaves.activeEndTimes[key];
		});
		Object.keys(bankSavesData.purchases || []).forEach(key => {
			const value = bankSavesData.purchases[key];
			this.purchases.set(key, value);
		});

		this.recharges = {
			applyTimes: [] as any,
			rewards: [] as any,
		};
		Object.keys(bankSavesData?.recharges?.applyTimes || []).forEach(key => {
			const value = bankSavesData.recharges.applyTimes[key];
			this.recharges.applyTimes[key] = value;
		});
		Object.keys(bankSavesData?.recharges?.rewards || []).forEach(key => {
			const value = bankSavesData.recharges.rewards[key];
			this.recharges.rewards[key] = value;
		});
	}

	public setPurchaseRechargeSuccess(
		key: string,
	): void {
		this.recharges.applyTimes[key] = (this.recharges.applyTimes[key] || 0) + 1;
	}

	public setPurchaseBankOfferSuccess(
		bankOfferModel: BankOfferModel,
		purchaseOrigin?: BankPurchaseOriginType,
		transactionId?: string,
	): void {
		this.emit(
			BankSavesModel.EVENT_BANK_PURCHASE_OFFER_SUCCESS,
			bankOfferModel,
			purchaseOrigin,
			transactionId,
		);
	}

	public setAnyFreePurchaseSuccess(
		transactionId: string,
	): void {
		this.emit(
			BankSavesModel.EVENT_BANK_FREE_PURCHASE_SUCCESS,
			transactionId,
		);
	}

	public setAnyPurchaseSuccess(
		price: number,
		transactionId: string,
		purchasedElementKey: string,
	): void {
		this.purchases.set(
			purchasedElementKey,
			(this.purchases.get(purchasedElementKey) || 0) + 1,
		);

		this.emit(
			BankSavesModel.EVENT_BANK_PURCHASE_SUCCESS,
			price,
			transactionId,
		);
	}

	public updateBundlePurchaseSuccess(key: string): void {
		this.bundles.get(key).acceptedBuyTimes += 1;
	}

	public updateBundleSaveData(key: string, newSaveData: BankBundleSaveData): void {
		this.bundles.set(key, newSaveData);
		this.emit(BankSavesModel.EVENT_BUNDLE_REWARDS, key);
	}

	public updateRechargeRewards(key: string, rewards: RewardDescriptionType[]): void {
		this.recharges.rewards[key] = rewards;
	}

	public updateOfferData(key: string, endTime: number): void {
		this.offers.activations[key] = (this.offers.activations[key] || 0) + 1;
		this.offers.activeEndTimes[key] = endTime;
	}

	public isLotBought(key: string): boolean {
		return this.purchases.has(key);
	}

	public isRechargeClaimed(key: string): boolean {
		return this.recharges.applyTimes[key] > 0;
	}

	public hasBundleRewards(key: string, currentTime: number): boolean {
		const bundleSaveData = this.bundles.get(key);
		return bundleSaveData?.expiresOn > currentTime && bundleSaveData?.rewards?.length > 0;
	}

	public hasRechargeRewards(key: string): boolean {
		return this.recharges.rewards[key]?.length > 0;
	}

	public getOfferActivateCount(key: string): number {
		return this.offers.activations[key] || 0;
	}

	public getOfferEndTime(key: string): number {
		return this.offers.activeEndTimes[key] || 0;
	}

	public getPurchaseCount(key: string): number {
		return this.purchases?.get(key) || 0;
	}

	public getBundleAcceptedBuyTimes(key: string, currentTime: number): number {
		const bundleSaveData: BankBundleSaveData = this.bundles.get(key);
		let value: number;
		if (bundleSaveData !== undefined) {
			value = bundleSaveData.expiresOn > currentTime ? bundleSaveData.acceptedBuyTimes : 0;
		} else {
			value = 0;
		}
		return value;
	}

	public getBundlesSaveData(): Map<string, BankBundleSaveData> {
		return this.bundles;
	}

	public getRechargeRewards(key: string): RewardDescriptionType[] | undefined {
		return this.recharges.rewards[key];
	}

	public getBundleRewards(key: string): RewardDescriptionType[] | undefined {
		return this.bundles.get(key)?.rewards;
	}
}
