import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness60001View } from '@views/businesses/event6Businesses/eventBuilding1/EventBusiness60001View';
import { EventBusiness60002View } from '@views/businesses/event6Businesses/eventBuilding1/EventBusiness60002View';
import { EventBusiness60003View } from '@views/businesses/event6Businesses/eventBuilding1/EventBusiness60003View';
import { EventBusiness60004View } from '@views/businesses/event6Businesses/eventBuilding2/EventBusiness60004View';
import { EventBusiness60005View } from '@views/businesses/event6Businesses/eventBuilding2/EventBusiness60005View';
import { EventBusiness60006View } from '@views/businesses/event6Businesses/eventBuilding2/EventBusiness60006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';

export class EventLevel6View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('miamiEveningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['miami_evening/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['miami_evening/road'], EventLevel6View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(-55, 520),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house4']).setTransform(719, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(191, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(219, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(1158, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(1513, 520),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(1221, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm2']).setTransform(1809, 668),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house2']).setTransform(2197, 570),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(1887, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm2']).setTransform(2509, 668),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(2940, 520, -1),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(2587, 900),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house1']).setTransform(3450, 528),
			new PIXI.Sprite(levelBgAtlas['miami_evening/house4']).setTransform(4050, 580),
			new PIXI.Sprite(levelBgAtlas['miami_evening/palm1']).setTransform(4520, 644),
			new PIXI.Sprite(levelBgAtlas['miami_evening/fence']).setTransform(4500, 900),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(735, 641),
			new PIXI.Sprite(levelBgAtlas['miami_evening/city']).setTransform(1802, 641),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('miamiEveningAtlas');

		this.background = EventLevel6View.createEveningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness60001View(),
			new EventBusiness60002View(),
			new EventBusiness60003View(),
			new EventBusiness60004View(),
			new EventBusiness60005View(),
			new EventBusiness60006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['miami_evening/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['miami_evening/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['miami_evening/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(EventLevel6View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}

	// eslint-disable-next-line class-methods-use-this
	private createPrepartyStartCharacter(): PrepartyStartCharacterView {
		const view = new PrepartyStartCharacterView();
		view.visible = false;
		return view;
	}
}
