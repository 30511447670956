import { ViewportView } from '@views/components/ViewportView';
import * as TWEEN from '@tweenjs/tween.js';

export enum ViewportUIChildType {
	INCOME_MONEY,
	QUICK_PHRASE,
	BUSINESS_OPEN_ANIMATION,
	BUSINESS_HINT,
	BUSINESS_MANAGE_UI,
}

export class ViewportUIView extends PIXI.Container {
	private viewport: ViewportView;

	private readonly contentQuickPhrases: PIXI.Container;
	private readonly contentIncomeMoney: PIXI.Container;
	private readonly contentBusinessOpenAnimation: PIXI.Container;
	private readonly contentBusinessHint: PIXI.Container;
	private readonly contentBusinessManageUI: PIXI.Container;

	private ticker: PIXI.ticker.Ticker;
	private tweenHide: TWEEN.Tween;

	constructor(viewport: ViewportView) {
		super();

		this.contentQuickPhrases = new PIXI.Container();
		this.contentIncomeMoney = new PIXI.Container();
		this.contentBusinessOpenAnimation = new PIXI.Container();
		this.contentBusinessHint = new PIXI.Container();
		this.contentBusinessManageUI = new PIXI.Container();

		this.viewport = viewport;

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.addChild(
			this.contentIncomeMoney,
			this.contentBusinessOpenAnimation,
			this.contentBusinessManageUI,
			this.contentBusinessHint,
			this.contentQuickPhrases,
		);
	}

	private update(): void {
		if (this.viewport.hasContent()) {
			const position = this.viewport.getContentPosition();
			const scale = this.viewport.getContentScale();

			this.position.set(position.x, position.y);
			this.scale.set(scale.x, scale.y);
		}
	}

	public startHidingAnimation(duration: number = 100): void {
		this.tweenHide = new TWEEN.Tween(this)
			.to({ alpha: 0 }, duration)
			.onComplete(() => {
				this.tweenHide = null;
			})
			.start();
	}

	public startAppearingAnimation(duration: number = 100): void {
		this.tweenHide = new TWEEN.Tween(this)
			.to({ alpha: 1 }, duration)
			.onComplete(() => {
				this.tweenHide = null;
			})
			.start();
	}

	public addChildByType(target: PIXI.DisplayObject, type: ViewportUIChildType): void {
		switch (type) {
			case ViewportUIChildType.INCOME_MONEY:
				this.contentIncomeMoney.addChild(target);
				break;
			case ViewportUIChildType.QUICK_PHRASE:
				this.contentQuickPhrases.addChild(target);
				break;
			case ViewportUIChildType.BUSINESS_OPEN_ANIMATION:
				this.contentBusinessOpenAnimation.addChild(target);
				break;
			case ViewportUIChildType.BUSINESS_HINT:
				this.contentBusinessHint.addChild(target);
				break;
			case ViewportUIChildType.BUSINESS_MANAGE_UI:
				this.contentBusinessManageUI.addChild(target);
				break;
			default:
				throw new Error(`Unsupported child type '${type}'`);
		}
	}

	public removeChildByType(target: PIXI.DisplayObject, type: ViewportUIChildType): void {
		switch (type) {
			case ViewportUIChildType.INCOME_MONEY:
				this.contentIncomeMoney.removeChild(target);
				break;
			case ViewportUIChildType.QUICK_PHRASE:
				this.contentQuickPhrases.removeChild(target);
				break;
			case ViewportUIChildType.BUSINESS_OPEN_ANIMATION:
				this.contentBusinessOpenAnimation.removeChild(target);
				break;
			case ViewportUIChildType.BUSINESS_HINT:
				this.contentBusinessHint.removeChild(target);
				break;
			case ViewportUIChildType.BUSINESS_MANAGE_UI:
				this.contentBusinessManageUI.removeChild(target);
				break;
			default:
				throw new Error(`Unsupported child type '${type}'`);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenHide) {
			this.tweenHide.stop();
		}
		this.ticker.remove(this.update, this);
		super.destroy(options);
	}
}
