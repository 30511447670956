import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { TextField } from '@views/components/text/TextField';

export class GalleryVideoOpenView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly model: GalleryVideoModel;

	private readonly playButton: PIXI.Sprite;
	private readonly stickerNewBg: PIXI.mesh.NineSlicePlane;
	private readonly title: SizeableBitmapText;
	private readonly stickerNewContainer: PIXI.Container;
	private readonly stickerNewText: TextField;
	private flyTextLabel: FlyBitmapText;

	constructor(
		model: GalleryVideoModel,
	) {
		super();

		this.model = model;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		const modelKey = model.getKey();


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['bg_video_screen'], 15, 15, 15, 15);
		bg.width = 430;
		bg.height = 282;
		bg.pivot.set(215, 141);
		bg.y = 3;

		this.interactive = true;
		this.on('pointertap', this.onPointerTap, this);

		const preview = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`collection_preview_${modelKey}`]);

		this.playButton = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['play_collection_btn']);
		this.playButton.visible = false;

		const titleBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		titleBg.anchor.set(0, 0);
		titleBg.tint = 0x000000;
		titleBg.width = 396;
		titleBg.height = 54;
		titleBg.alpha = 0.7;
		titleBg.x = -198;
		titleBg.y = -124;

		this.title = new SizeableBitmapText('',
			290,
			{
				font: '26px wendyOneShadowBold',
			});
		this.title.anchor = new PIXI.Point(0, 0.5);
		this.title.position.set(-180, -97);

		this.stickerNewContainer = new PIXI.Container();
		this.stickerNewContainer.scale.set(0.6);
		this.stickerNewContainer.position.set(147, 110);

		this.stickerNewBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['new_sticker_icon'], 22, 0, 6, 0);
		this.stickerNewBg.width = 195;
		this.stickerNewBg.pivot.set(this.stickerNewBg.width / 2, this.stickerNewBg.height / 2);

		this.stickerNewText = new TextField('',
			{ font: '38px wendyOne', align: 'center', tint: 0xfff78f },
			60,
			45);
		this.stickerNewText.anchor = 0.5;
		this.stickerNewText.y = 4;
		this.stickerNewText.x = 4;

		this.stickerNewContainer.addChild(
			this.stickerNewBg,
			this.stickerNewText as PIXI.DisplayObject,
		);

		this.addChild(
			bg,
			preview as PIXI.DisplayObject,
			this.playButton,
			titleBg,
			this.title,
			this.stickerNewContainer,
		);

		this.onLanguageChange();
		this.updateStickerNewContainerVisibility();

		if (this.model.isOpened()) {
			this.onVideoOpened();
		}
	}

	private onLanguageChange(): void {
		this.title.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_name`);
		this.stickerNewText.text = this.localizationStorage.getLocalizedString('#video_sticker_new');
	}

	private onVideoOpened(): void {
		this.playButton.visible = true;
	}

	private updateStickerNewContainerVisibility(): void {
		this.stickerNewContainer.visible = this.model.isNew() && this.model.isOpened();
	}

	private onPointerTap(): void {
		if (this.model.isOpened()) {
			this.model.setOld();
			this.stickerNewContainer.visible = false;
			this.emit(GalleryVideoOpenView.EVENT_CLICK, { model: this.model.getKey() });
		} else {
			this.tryShowFlyTextLabel();
		}
	}

	private tryShowFlyTextLabel(): void {
		if (!this.flyTextLabel) {
			const stringLabel = this.localizationStorage.getLocalizedString('#fly_text_collect_all_video_pieces');
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '24px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.playButton.x, this.playButton.y - 40);
			this.addChild(this.flyTextLabel);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		super.destroy(options);
	}
}
