import { BoostModel } from '@models/BoostModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { BusinessModel } from '@models/BusinessModel';
import { BaseBoostModel } from '@models/BaseBoostModel';

export class ModelWriterBoost {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly boostModels: Map<string, BoostModel>;

	constructor(
		businessModels: Map<string, BusinessModel>,
		boostModels: Map<string, BoostModel>,
	) {
		this.businessModels = businessModels;
		this.boostModels = boostModels;

		this.boostModels.forEach((model) => {
			model.on(BaseBoostModel.EVENT_ACTIVATED, this.onBoostActivated, this);
			model.on(BaseBoostModel.EVENT_DEACTIVATED, this.onBoostDeactivated, this);
		});
	}

	private onBoostActivated(boost: BoostModel): void {
		const bonusType = boost.getBonusType();
		switch (bonusType) {
			case BonusTypes.MULTIPLIER_INCOME: {
				this.businessModels.forEach(model => model.setBoostMultiplierIncome(boost.getKey(), boost.getBonusValue()));
				break;
			}
			default:
				throw new Error(`Unsupported boost bonus type '${bonusType}'`);
		}
	}

	private onBoostDeactivated(boost: BoostModel): void {
		const bonusType = boost.getBonusType();
		switch (bonusType) {
			case BonusTypes.MULTIPLIER_INCOME: {
				this.businessModels.forEach(model => model.clearBoostMultiplierIncome(boost.getKey()));
				break;
			}
			default:
				throw new Error(`Unsupported boost bonus type '${bonusType}'`);
		}
	}
}
