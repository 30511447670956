import { ButtonBaseView } from './ButtonBaseView';
import { SizeableBitmapText } from '../text/SizeableBitmapText';
import { TextField } from '../text/TextField';
import { MultiColoredTextField } from '../text/MultiColoredTextField';
import { SizeableMultiColoredBitmapText } from '../text/SizeableMultiColoredBitmapText';

export class ButtonWithLabelBaseView extends ButtonBaseView {
	protected textLabel: PIXI.extras.BitmapText;

	constructor(
		buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container,
		textLabelStyle: PIXI.extras.BitmapTextStyle,
		maxWidth?: number,
		maxHeight?: number,
		multicolor: boolean = false,
	) {
		super(buttonBg);

		if (maxHeight) {
			if (multicolor) {
				this.textLabel = new MultiColoredTextField(
					textLabelStyle,
					maxWidth,
					maxHeight,
				);
			} else {
				this.textLabel = new TextField(
					'',
					textLabelStyle,
					maxWidth,
					maxHeight,
				);
			}
		} else if (maxWidth) {
			if (multicolor) {
				this.textLabel = new SizeableMultiColoredBitmapText(
					maxWidth,
					textLabelStyle,
				);
			} else {
				this.textLabel = new SizeableBitmapText(
					'',
					maxWidth,
					textLabelStyle,
				);
			}
		} else {
			this.textLabel = new PIXI.extras.BitmapText(
				'',
				textLabelStyle,
			);
		}
		this.textLabel.anchor = 0.5;
		this.textLabel.y = -2;

		this.addChild(
			this.textLabel,
		);
	}

	public setTextLabel(text: string): void {
		this.textLabel.text = text;
	}
}
