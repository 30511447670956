import { MoneyPanelBaseView } from '@views/ui/moneyPanel/MoneyPanelBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import AbstractMoneyModel from '@models/money/AbstractMoneyModel';

export class HardMoneyPanelView extends MoneyPanelBaseView<number> {
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();
	public static readonly EVENT_BUTTON_PLUS_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly bg: PIXI.mesh.NineSlicePlane;
	private readonly buttonPlus: ButtonBaseView;

	private hardMoneyIconPosition: PIXI.Point;

	constructor(
		moneyModel: AbstractMoneyModel,
		buttonPlusVisible: boolean = true,
	) {
		const iconMoneyContainer = new PIXI.Container();
		const iconMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_hard_money']);
		iconMoney.setTransform(-86, 0);

		const iconMoneyMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_hard_money_mask']);
		const highlightAnimation = new SpriteHighlightAnimationView(iconMoneyMask);
		highlightAnimation.position.set(iconMoney.x, iconMoney.y);
		iconMoneyContainer.addChild(
			iconMoney,
			highlightAnimation as PIXI.DisplayObject,
		);
		highlightAnimation.start();

		const textMoney = new SizeableBitmapText('', 110, {
			font: '36px wendyOneShadowBold',
			align: 'center',
		});
		textMoney.anchor = new PIXI.Point(1, 0.5);

		super(
			textMoney,
			iconMoneyContainer,
			moneyModel,
		);

		this.hardMoneyIconPosition = iconMoneyContainer.toLocal(new PIXI.Point(iconMoney.x, iconMoney.y));

		this.localizationStorage = LocalizationStorage.getInstance();

		this.buttonPlus = new ButtonBaseView(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['btn_Plus']));
		this.buttonPlus.position.set(90, 0);
		this.buttonPlus.interactive = true;
		this.buttonPlus.on(ButtonBaseView.EVENT_CLICK, () => { this.emit(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK); });

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_rounded_rect'], 5, 5, 5, 5);
		this.bg.width = 205;
		this.bg.height = 42;
		this.bg.alpha = 0.6;
		this.bg.tint = 0x000000;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.bg.interactive = true;
		this.bg.on('pointertap', this.showHint, this);

		this.addChildAt(this.buttonPlus, 0);
		this.addChildAt(this.bg, 0);

		this.setButtonPlusVisible(buttonPlusVisible);
	}

	public getIconMoneyPosition(): PIXI.Point {
		return this.hardMoneyIconPosition;
	}

	public setButtonPlusVisible(value: boolean): void {
		this.buttonPlus.visible = value;
		if (value) {
			this.textMoney.x = this.buttonPlus.x - this.buttonPlus.width / 2;
		} else {
			this.textMoney.x = this.buttonPlus.x;
		}
		this.textMoney.x -= 10;
	}

	public isButtonPlusVisible(): boolean {
		return this.buttonPlus.visible;
	}

	private showHint(): void {
		const arrowPosLocal = new PIXI.Point(this.iconMoney.x - 85, this.iconMoney.y + 50);
		this.emit(HardMoneyPanelView.EVENT_SHOW_HINT, arrowPosLocal, this);
	}
}
