import { PrepartyStartCharacterViewSetter } from '@interfaces/ViewSetters';
import { BaseAction } from '@models/network/actions/BaseAction';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export class PrepartyStartCharacterViewController
	extends PIXI.utils.EventEmitter implements PrepartyStartCharacterViewSetter {
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private readonly model: PrepartyStartCharacterModel;

	private prepartCharacterView: PrepartyStartCharacterView;

	constructor(
		model: PrepartyStartCharacterModel,
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
	) {
		super();

		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;

		this.model = model;
		this.model.on(PrepartyStartCharacterModel.EVENT_NEXT_FAREWELL_PARTY_CHOOSEN, this.onNextFarewellPartyChosen, this);
	}

	public setPrepartyStartChracterView(view: PrepartyStartCharacterView): void {
		this.prepartCharacterView = view;
		this.prepartCharacterView.on(PrepartyStartCharacterView.EVENT_CLICK, this.onPrePartyCharacterClick, this);
	}

	private onPrePartyCharacterClick(): void {
		const window = this.windowFactory.createPrepartyWindow(this.model.getFarewellPartyKey());
		this.windowViewSystem.showWindow(window);
	}

	private onNextFarewellPartyChosen(key: string): void {
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({
			next_farewell_party: key,
		}));
	}
}
