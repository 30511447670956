import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40016View } from '@views/characters/events/event4/EventCharacter40016View';
import { EventCharacter40017View } from '@views/characters/events/event4/EventCharacter40017View';
import { EventCharacter40018View } from '@views/characters/events/event4/EventCharacter40018View';

export class EventBusiness40006View extends BusinessBaseView {
	constructor() {
		const eventCharacter40016View = new EventCharacter40016View();
		const eventCharacter40017View = new EventCharacter40017View();
		const eventCharacter40018View = new EventCharacter40018View();

		super('business40006', [eventCharacter40016View, eventCharacter40017View, eventCharacter40018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter40016View as PIXI.DisplayObject,
			eventCharacter40017View,
			eventCharacter40018View,
		);
	}
}
