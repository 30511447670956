import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10001View } from '@views/characters/events/event1/EventCharacter10001View';
import { EventCharacter10002View } from '@views/characters/events/event1/EventCharacter10002View';
import { EventCharacter10003View } from '@views/characters/events/event1/EventCharacter10003View';

export class EventBusiness10001View extends BusinessBaseView {
	constructor() {
		const character10001 = new EventCharacter10001View();
		const character10002 = new EventCharacter10002View();
		const character10003 = new EventCharacter10003View();

		super('business10001', [character10001, character10002, character10003]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b1_decor5']).setTransform(276, 34, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor1']).setTransform(-225, -17, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor3']).setTransform(-217, -72, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor2']).setTransform(166, -37, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			new PIXI.Sprite(decorationAtlas['b1_decor4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite,
			character10001 as PIXI.DisplayObject,
			character10002,
			character10003,
		);
	}
}
