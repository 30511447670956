import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { NewsModel } from '@models/NewsModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { HintTypes } from '@src/types/HintTypes';
import { ScrollAxis } from '@src/types/ScrollViewTypes';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ScrollView } from '@views/components/ScrollView';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import { NewsMessageContentView } from './NewsMessageContentView';
import { NewsTabButtonView } from './NewsTabButtonView';
import * as TWEEN from '@tweenjs/tween.js';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class BaseNewsWindowView extends WindowBaseView {
	public static readonly EVENT_CHANGE_TAB = Symbol();
	public static readonly EVENT_CLAIM_REWARD = Symbol();
	public static readonly EVENT_BUTTON_PLUS_CLICK = Symbol();
	public static readonly EVENT_SHOW_HINT = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly prestigeMoneyPanel: PrestigeMoneyPanelView;
	private readonly hardMoneyPanel: HardMoneyPanelView;

	private tabContent: PIXI.Container;
	private tabScrollView: ScrollView;
	private newsContentContainer: PIXI.Container;
	private activeTabNewsKey?: string;

	constructor(
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		private readonly newsModel: NewsModel,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
		private readonly linkUrl: string,
		areMoneyButtonsVisible: boolean,
	) {
		super(0.7);

		const yTopOffset = 40;
		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = PIXI.Sprite.fromImage('collectionBg');
		bg.interactive = true;

		const leftBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		leftBg.anchor.set(0, 0);
		leftBg.tint = 0x000000;
		leftBg.width = 347;
		leftBg.height = 1080;
		leftBg.alpha = 0.4;

		const closeButton = new CloseButtonView();
		closeButton.position.set(1875, yTopOffset);
		closeButton.alpha = 0.7;
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel, areMoneyButtonsVisible);
		this.prestigeMoneyPanel.on(PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => {
			this.emit(BaseNewsWindowView.EVENT_BUTTON_PLUS_CLICK);
		});
		this.prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(BaseNewsWindowView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, { arrowPosLocal, origin });
			},
		);
		this.prestigeMoneyPanel.position.set(1370, yTopOffset);

		this.hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel, areMoneyButtonsVisible);
		this.hardMoneyPanel.on(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => {
			this.emit(BaseNewsWindowView.EVENT_BUTTON_PLUS_CLICK);
		});
		this.hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(BaseNewsWindowView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, { arrowPosLocal, origin });
			},
		);
		this.hardMoneyPanel.position.set(1630, yTopOffset);

		this.tabContent = new PIXI.Container();

		this.tabScrollView = new ScrollView(
			leftBg.width + 16,
			leftBg.height,
			ScrollAxis.VERTICAL,
			{ begin: 0, end: NewsTabButtonView.BG_HEIGHT },
		);
		this.tabScrollView
			.setMovementThreshold(50)
			.setContent(this.tabContent);

		this.addTabs();

		this.newsContentContainer = new PIXI.Container();
		this.newsContentContainer.x = 1134;
		this.newsContentContainer.y = 206;

		this.addNewsContent();

		this.addChild(
			bg as PIXI.DisplayObject,
			closeButton,
			leftBg,
			this.prestigeMoneyPanel,
			this.hardMoneyPanel,
			this.tabScrollView,
			this.newsContentContainer,
		);

		this.newsModel.on(NewsModel.EVENT_CHANGED, this.onNewsChanged, this);
	}

	public setActiveTab(newsKey?: string): void {
		const newsItems = this.newsModel.getItems();
		const targetKey = newsKey || newsItems[0].getKey();

		this.activeTabNewsKey = targetKey;

		newsItems.forEach((newsItem, i) => {
			const tabButton = this.tabContent.getChildAt(i) as NewsTabButtonView;
			const newsContent = this.newsContentContainer.getChildAt(i) as NewsMessageContentView;

			if (newsItem.getKey() === targetKey) {
				tabButton.setActive(true);

				if (newsItem.getRewards().length === 0) {
					tabButton.setInfoIconVisible(false);
				}

				newsContent.visible = true;
			} else {
				tabButton.setActive(false);
				newsContent.visible = false;
			}
		});

		this.emit(BaseNewsWindowView.EVENT_CHANGE_TAB, targetKey);
		this.startOpenAnimation();
	}

	private addTabs(): void {
		const newsItems = this.newsModel.getItems();

		this.tabContent.removeChildren();

		newsItems.forEach((newsItem, i) => {
			const title = this.localizationStorage.getLocalizedString(newsItem.getTabTitle());
			const tabButton = new NewsTabButtonView(
				new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')[newsItem.getIconKey()]),
				title,
				newsItem.getDate(),
			);

			const yTopOffset = 96;
			const yButtonsSpacing = tabButton.height - 3;
			const yPos = yTopOffset + i * yButtonsSpacing;

			tabButton.position.set(183, yPos);
			tabButton.setInfoIconVisible(this.newsModel.isNewsItemNew(newsItem));

			tabButton.on(NewsTabButtonView.EVENT_CLICK, () => {
				this.setActiveTab(newsItem.getKey());
			});

			this.tabContent.addChild(tabButton);

			if (this.activeTabNewsKey === newsItem.getKey()) {
				tabButton.setActive(true);
			}
		});

		this.tabScrollView.updateBounds();
	}

	private addNewsContent(): void {
		const newsItems = this.newsModel.getItems();

		this.newsContentContainer.removeChildren();

		newsItems.forEach((newsItem, i) => {
			const newsContent = new NewsMessageContentView(
				this.cardMiniViewFactory,
				newsItem,
				this.linkUrl,
				this.newsModel.isCreatedBeforeRegistration(newsItem),
			);

			newsContent.visible = this.activeTabNewsKey === newsItem.getKey();

			if (!this.newsModel.isRewardClaimable(newsItem)) {
				newsContent.setRewardsClaimed();
			} else {
				newsContent.on(NewsMessageContentView.EVENT_CLAIM_REWARD, () => {
					this.onClaimReward(i, newsItem.getKey());
				});
			}

			newsContent.on(NewsMessageContentView.EVENT_SHOW_HINT_REWARD_CARD, (rewardDescription, origin) => {
				this.emit(BaseNewsWindowView.EVENT_SHOW_HINT, HintTypes.REWARD_CARD, { rewardDescription, origin });
			});

			this.newsContentContainer.addChild(newsContent);
		});
	}

	private onNewsChanged(): void {
		this.addTabs();
		this.addNewsContent();

		const allKeys = this.newsModel.getItems().map((item) => item.getKey());

		if (this.activeTabNewsKey != null && !allKeys.includes(this.activeTabNewsKey)) {
			this.activeTabNewsKey = undefined;
			this.setActiveTab();
		}
	}

	private onClaimReward(tabIndex: number, newsKey: string): void {
		const tabButton = this.tabContent.getChildAt(tabIndex) as NewsTabButtonView;
		tabButton.setInfoIconVisible(false);

		this.emit(BaseNewsWindowView.EVENT_CLAIM_REWARD, newsKey);
	}

	private startOpenAnimation(): void {
		this.newsContentContainer.y += 70;
		this.newsContentContainer.alpha = 0;

		new TWEEN.Tween(this.newsContentContainer)
			.to({ y: 206, alpha: 1 }, 200)
			.easing(TWEEN.Easing.Quadratic.Out)
			.start();
	}

	public destroy(): void {
		this.newsModel.off(NewsModel.EVENT_CHANGED, this.onNewsChanged, this);
		super.destroy();
	}
}
