import { FarewellAnimationAimPositions, FarewellPartyConfig } from '@configs/FarewellPartyConfig';
import { FarewellPartyConstants } from '@configs/ConstantsConfig';

export type FarewellPartyProgress = {
	current: number;
	total: number;
	rewards: number;
	nextRewardReached: boolean;
};

type FarewellTotemLevels = { [key: string]: number };

export class FarewellPartyModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_PROGRESS: symbol = Symbol();
	public static readonly EVENT_NEXT_REWARD_REACHED: symbol = Symbol();
	public static readonly EVENT_FINISHED: symbol = Symbol();

	private readonly fuckpowerValues: number[];
	private readonly animationKey: string;

	private readonly fuckpowerBase: number;
	private readonly fucktimeBase: number;

	private readonly aimFuckpowerMultiplier: number;
	private readonly tapsForAimDestroy: number;
	private readonly tapMinDelay: number;
	private readonly rewardBoostTime: number[];
	private readonly rewardBoostMultipliers: number[];

	private currentProgressId: number;
	private currentProgress: number;
	private totalProgress: number;

	private readonly multiplierFuckpowerTotemsMap: Map<string, number>;
	private readonly fuckTimeTotemsMap: Map<string, number>;
	private readonly totemLevels: Map<string, number>;

	private multiplierFuckpowerTotems: number;

	private fuckTimeTotems: number;
	private fuckTimeBonus: number;
	private fuckpower: number;
	private fucktime: number;
	private startedOn: number;

	private readonly farewellAnimationsAimPositions: FarewellAnimationAimPositions;
	private readonly key: string;

	constructor(
		partyConstants: FarewellPartyConstants,
		config: FarewellPartyConfig,
	) {
		super();

		this.fuckpowerBase = partyConstants.baseFuckpower;
		this.fucktimeBase = partyConstants.baseFucktime;
		this.aimFuckpowerMultiplier = partyConstants.aimFuckpowerMultiplier;
		this.tapsForAimDestroy = partyConstants.tapsForAimDestroy;
		this.tapMinDelay = partyConstants.tapMinDelay;

		this.multiplierFuckpowerTotems = 1;
		this.multiplierFuckpowerTotemsMap = new Map();

		this.fuckTimeTotems = 0;
		this.fuckTimeBonus = 0;
		this.fuckTimeTotemsMap = new Map();
		this.totemLevels = new Map();

		this.calculateFuckpower();
		this.calculateFucktime();

		this.key = config.getKey();
		this.animationKey = config.getAnimationKey();
		this.fuckpowerValues = partyConstants.fuckPowers;
		this.rewardBoostMultipliers = partyConstants.bonusValues;
		this.rewardBoostTime = partyConstants.bonusTimes;

		this.farewellAnimationsAimPositions = config.getFarewellAnimationsAimPositions();

		this.currentProgress = 0;
		this.totalProgress = 0;
		this.currentProgressId = 0;
	}

	public getKey(): string {
		return this.key;
	}

	public finish(): void {
		this.emit(FarewellPartyModel.EVENT_FINISHED);
	}

	public start(time: number): void {
		this.startedOn = time;
	}

	public getStartedOn(): number {
		return this.startedOn;
	}

	public getFuckpower(): number {
		return this.fuckpower;
	}

	/**
	 * @returns {number} party time in seconds
	 */
	public getFucktime(): number {
		return this.fucktime;
	}

	public getСurrentRewardBoostTime(): number {
		return this.rewardBoostTime[this.currentProgressId];
	}

	public getCurrentRewardBoostMultiplier(): number {
		return this.rewardBoostMultipliers[this.currentProgressId];
	}

	public getNextRewardBoostTime(): number {
		return this.rewardBoostTime[this.currentProgressId + 1];
	}

	public getNextRewardBoostMultiplier(): number | undefined {
		return this.rewardBoostMultipliers[this.currentProgressId + 1];
	}

	public getTapMinDelay(): number {
		return this.tapMinDelay;
	}

	public getTapsForAimDestroy(): number {
		return this.tapsForAimDestroy;
	}

	public getAimFuckpowerMultiplier(): number {
		return this.aimFuckpowerMultiplier;
	}

	public resetModel(): void {
		this.currentProgress = 0;
		this.totalProgress = 0;
		this.currentProgressId = 0;

		this.fuckTimeBonus = 0;
		this.calculateFucktime();
	}

	public addAimProgress(): boolean {
		const progressToAdd = this.fuckpower * this.aimFuckpowerMultiplier;
		return this.updatePartyProgress(progressToAdd);
	}

	public addTapProgress(): boolean {
		const progressToAdd = this.fuckpower;
		return this.updatePartyProgress(progressToAdd);
	}

	public addFucktimeBonus(value: number): void {
		this.fuckTimeBonus += value;
		this.calculateFucktime();
	}

	private updatePartyProgress(progressDelta: number): boolean {
		this.currentProgress += progressDelta;

		const nextRewardReached = this.currentProgressId < this.fuckpowerValues.length
			&& this.totalProgress >= this.fuckpowerValues[this.currentProgressId + 1];

		if (nextRewardReached) {
			this.currentProgressId += 1;
			this.currentProgress = this.totalProgress - this.fuckpowerValues[this.currentProgressId];

			this.emit(FarewellPartyModel.EVENT_NEXT_REWARD_REACHED);
		}

		this.totalProgress += progressDelta;

		this.emit(FarewellPartyModel.EVENT_PROGRESS);

		return nextRewardReached;
	}

	public getCurrentProgressPercent(): number {
		const targetProgress: number | undefined = this.getTargetProgress();
		let progress: number;
		if (targetProgress !== undefined) {
			progress = this.currentProgress / (this.getTargetProgress() - this.getPreviousTargetProgress());
		} else {
			progress = 1;
		}
		return progress;
	}

	public getCurrentProgress(): number {
		return this.currentProgress;
	}

	public getTotalProgress(): number {
		return this.totalProgress;
	}

	public getTargetProgress(): number | undefined {
		return this.fuckpowerValues[this.currentProgressId + 1];
	}

	public getPreviousTargetProgress(): number {
		return this.fuckpowerValues[this.currentProgressId];
	}

	public getCurrentProgressId(): number {
		return this.currentProgressId;
	}

	public getCurrentAnimationKey(): string {
		return this.animationKey;
	}

	public setMultiplierFuckpowerTotem(totemKey: string, value: number): void {
		this.multiplierFuckpowerTotemsMap.set(totemKey, value);
		this.calculateFuckpowerMultiplierTotems();
		this.calculateFuckpower();
	}

	public setMultiplierFucktimeTotem(totemKey: string, value: number): void {
		this.fuckTimeTotemsMap.set(totemKey, value);
		this.calculateFucktimeTotems();
		this.calculateFucktime();
	}

	public setTotemLevel(totemKey: string, level: number): void {
		this.totemLevels.set(totemKey, level);
	}

	private calculateFuckpowerMultiplierTotems(): void {
		this.multiplierFuckpowerTotems = 1;
		this.multiplierFuckpowerTotemsMap.forEach((multiplier) => {
			this.multiplierFuckpowerTotems += multiplier;
		});
	}

	private calculateFuckpower(): void {
		this.fuckpower = Math.floor(this.fuckpowerBase * this.multiplierFuckpowerTotems);
	}

	private calculateFucktimeTotems(): void {
		this.fuckTimeTotems = 0;
		this.fuckTimeTotemsMap.forEach((value) => {
			this.fuckTimeTotems += value;
		});
	}

	private calculateFucktime(): void {
		this.fucktime = this.fucktimeBase + this.fuckTimeTotems + this.fuckTimeBonus;
	}

	public getFuckTimeBonus(): number {
		return this.fuckTimeBonus;
	}

	public getFucktimeTotems(): number {
		return this.fuckTimeTotems;
	}

	public getMultiplierFuckpowerTotems(): number {
		return this.multiplierFuckpowerTotems;
	}

	public getTotemLevels(): FarewellTotemLevels {
		return Object.fromEntries(this.totemLevels);
	}

	public getFarewellAnimationsAimPositions(): FarewellAnimationAimPositions {
		return this.farewellAnimationsAimPositions;
	}
}
