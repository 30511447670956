import { NewsItemConfig } from '@configs/NewsItemConfig';
import { NewsData } from '@src/types/SaveTypes';
import { NumberUtils } from '@src/utils/NumberUtils';
import { ServerTimeModel } from './network/ServerTimeModel';

export class NewsModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_CHANGED = Symbol();
	public static readonly EVENT_NEWS_CLAIMED = Symbol();
	public static readonly EVENT_NEWS_SEEN = Symbol();

	private items: NewsItemConfig[];
	private claimedNewsKeys: string[];
	private seenNewsKeys: string[];
	private deletedNewsKeys: string[];

	constructor(
		newsData: NewsData,
		private readonly accountCreatedOn: number,
		private readonly serverTimeModel: ServerTimeModel,
		private readonly newsMaxAmount: number,
	) {
		super();
		this.items = [];
		this.claimedNewsKeys = newsData.claimedRewardsNews;
		this.seenNewsKeys = newsData.seenNews;
	}

	public setNewsItemConfigs(configs: Map<string, NewsItemConfig>): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTimeMS();
		const configsArray = Array.from(configs).map(([, config]) => config);

		this.items = configsArray
			.sort((item1, item2) => {
				const time1 = NumberUtils.dateStringToTime(item1.getDate());
				const time2 = NumberUtils.dateStringToTime(item2.getDate());
				let sortResult = Math.sign(time2 - time1);

				if (sortResult === 0) {
					sortResult = Math.sign(Number(item2.getKey()) - Number(item1.getKey()));
				}

				return sortResult;
			})
			.filter((newsItem) => NumberUtils.dateStringToTime(newsItem.getDate()) <= currentTime)
			.slice(0, this.newsMaxAmount);

		const configsKeys = configsArray.map((config) => config.getKey());
		this.deletedNewsKeys = this.seenNewsKeys.filter((key) => !configsKeys.includes(key));

		this.emit(NewsModel.EVENT_CHANGED);
	}

	public getItems(): NewsItemConfig[] {
		return this.items;
	}

	public getPushedNewsKey(): string {
		const result = this.items
			.filter((item) => !this.seenNewsKeys.includes(item.getKey()) && !this.isCreatedBeforeRegistration(item))
			.find((config) => config.hasPush());

		return result?.getKey();
	}

	public getDeletedNewsKeys(): string[] {
		return this.deletedNewsKeys;
	}

	public clearDeletedKeys(): void {
		this.deletedNewsKeys.forEach((key) => {
			const seenIdx = this.seenNewsKeys.indexOf(key);

			if (seenIdx !== -1) {
				this.seenNewsKeys.splice(seenIdx, 1);
			}

			const claimedIdx = this.claimedNewsKeys.indexOf(key);

			if (claimedIdx !== -1) {
				this.claimedNewsKeys.splice(claimedIdx, 1);
			}
		});

		this.deletedNewsKeys = [];
	}

	public markAsSeen(key: string): void {
		this.seenNewsKeys.push(key);
		this.emit(NewsModel.EVENT_NEWS_SEEN);
	}

	public claimReward(key: string): void {
		this.claimedNewsKeys.push(key);
		this.emit(NewsModel.EVENT_NEWS_CLAIMED);
	}

	public isNewsItemNew(newsItem: NewsItemConfig): boolean {
		let isNew;

		if (newsItem.getRewards().length > 0) {
			isNew = !this.claimedNewsKeys.includes(newsItem.getKey());
		} else {
			isNew = !this.seenNewsKeys.includes(newsItem.getKey());
		}

		return isNew && !this.isCreatedBeforeRegistration(newsItem);
	}

	public hasNewNewsItems(): boolean {
		return this.items.some((config) => this.isNewsItemNew(config));
	}

	public isRewardClaimable(newsItem: NewsItemConfig): boolean {
		const result = !this.isCreatedBeforeRegistration(newsItem)
			&& newsItem.getRewards().length > 0
			&& !this.claimedNewsKeys.includes(newsItem.getKey());
		return result;
	}

	public canMarkAsSeen(newsItem: NewsItemConfig): boolean {
		return !this.isCreatedBeforeRegistration(newsItem) && !this.seenNewsKeys.includes(newsItem.getKey());
	}

	public isCreatedBeforeRegistration(newsItem: NewsItemConfig): boolean {
		const newsTime = NumberUtils.dateStringToTime(newsItem.getDate());
		const createdDate = new Date(this.accountCreatedOn * 1000);
		createdDate.setHours(0, 0, 0, 0);

		return newsTime < createdDate.getTime();
	}
}
