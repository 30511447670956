import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class VideoConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_LEVEL_ID: string = 'level_id';
	public static readonly FIELD_DURATION: string = 'duration';
	public static readonly FIELD_URL: string = 'url';

	private readonly key: string;
	private readonly levelId: number;
	private readonly videoUrl: string;
	private readonly duration: number;

	constructor(config: Record<string, string>) {
		this.key = config[VideoConfig.FIELD_KEY].trim();
		this.levelId = Number(config[VideoConfig.FIELD_LEVEL_ID]);
		this.duration = Number(config[VideoConfig.FIELD_DURATION].trim());
		this.videoUrl = config[VideoConfig.FIELD_URL].trim();
	}

	public getKey(): string {
		return this.key;
	}

	public getLevelId(): number {
		return this.levelId;
	}

	public getVideoUrl(): string {
		return this.videoUrl;
	}

	public getDuration(): number {
		return this.duration;
	}
}
