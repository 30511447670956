import { TextField } from '@views/components/text/TextField';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { TextUtils } from '@src/utils/TextUtils';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferWindowPackBodyView } from './BankOfferWindowPackBodyView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';

export class BankOfferWindowPackBody3View extends BankOfferWindowPackBodyView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly timerLabel: PIXI.extras.BitmapText;

	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;

	constructor(
		bankOfferModel: BankOfferModel,
		bankTabElementWithOffer: BankTabElementWithOfferType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super(bankTabElementWithOffer);

		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		const localizationKeys = bankOfferModel.getLocalizationKeys();

		const decor1 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor1.scale.set(1.8, -2);
		decor1.y = -300;
		decor1.x = -155;
		decor1.blendMode = PIXI.BLEND_MODES.SCREEN;
		decor1.tint = 0xf16f18;

		const decor2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_level_decor']);
		decor2.scale.set(-1.8, -2);
		decor2.y = -300;
		decor2.x = 155;
		decor2.blendMode = PIXI.BLEND_MODES.SCREEN;
		decor2.tint = 0xf16f18;

		const gradientBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['gradient_decor'], 0, 0, 0, 0);
		gradientBg.width = 1292;
		gradientBg.height = 640;
		gradientBg.pivot.set(gradientBg.width / 2, gradientBg.height / 2);
		gradientBg.interactive = true;
		gradientBg.position.set(0, 69);

		const girl1 = new PIXI.Sprite(AssetsStorage.getResource(`${bankOfferModel.getCharacterLeftKey()}_offer`).texture);
		girl1.pivot.set(girl1.width / 2, girl1.height / 2);
		girl1.position.set(-604, 70);

		const girl2 = new PIXI.Sprite(AssetsStorage.getResource(`${bankOfferModel.getCharacterRightKey()}_offer`).texture);
		girl2.pivot.set(girl2.width / 2, girl2.height / 2);
		girl2.position.set(604, 70);
		girl2.scale.x = -1;

		const titleText = new TextField(
			this.localizationStorage.getLocalizedString(localizationKeys.title),
			{ font: '84px wendyOneGold', align: 'center' },
			850,
			200,
		);
		titleText.anchor = 0.5;
		titleText.position.set(0, -375);

		const fadeBg = new PIXI.Graphics();
		fadeBg.beginFill(0x000000);
		fadeBg.drawRect(0, 285, 1292, 208);
		fadeBg.endFill();
		fadeBg.pivot.set(fadeBg.width / 2, fadeBg.height / 2);
		fadeBg.alpha = 0.55;

		const timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['timer_offer_bg'], 27, 0, 27, 0);
		timerBg.width = 393;
		timerBg.pivot.set(timerBg.width / 2, timerBg.height / 2);

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '42px wendyOneShadowBold',
		});
		this.timerLabel.anchor = 0.5;
		this.timerLabel.y = -6;

		const timerContainer = new PIXI.Container();
		timerContainer.position.set(0, -235);

		const buyButton = getBuyButtonHandler(
			454,
			106,
			bankTabElementWithOffer,
		);
		buyButton.position.set(0, 282);

		const rewardsContainer = getRewardMiniCardsContainerHandler(25, bankTabElementWithOffer);

		let rewardCount: number = 0;
		let rewardId: string = '';
		if (bankTabElementWithOffer instanceof BankBundleModel) {
			rewardCount = bankTabElementWithOffer.getExtraRewardCount();
			rewardId = bankTabElementWithOffer.getExtraRewardId();
		}

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(0, -150);
		animationsContainer.scale.y = 0.5;
		animationsContainer.scale.x = 1;
		animationsContainer.alpha = 0.6;

		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		timerContainer.addChild(
			timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);

		this.addChild(
			animationsContainer,
			decor1,
			decor2,
			gradientBg,
			fadeBg,
			girl1,
			girl2,
			titleText as PIXI.DisplayObject,
			buyButton,
			timerContainer,
			rewardsContainer,
		);

		if (rewardCount > 0) {
			const specialBonusContainer: PIXI.Container = this.createSpecialBonus(rewardCount, rewardId);
			this.addChild(specialBonusContainer);
			rewardsContainer.position.set(0, -60);
		} else {
			rewardsContainer.position.set(0, -41);
		}

		if (bankTabElementWithOffer.getMostPopularLocaleKey()) {
			const stickerPopular = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopular.anchor.set(0.5);
			stickerPopular.position.set(420, -230);
			stickerPopular.scale.set(1.3);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getMostPopularLocaleKey()),
				{ font: '28px wendyOne', align: 'center', tint: 0xfff78f },
				120,
				80,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = 0.47;
			stickerPopularText.position.set(stickerPopular.x + 1, stickerPopular.y + 5);

			const stickerPopularMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopularMask.anchor.set(0.5);
			stickerPopularMask.scale.set(1.3);
			stickerPopularMask.tint = 0xffffff;
			const stickerPopularHightlight = new SpriteHighlightAnimationView(stickerPopularMask, 500, false);
			stickerPopularHightlight.position.set(stickerPopular.x, stickerPopular.y);

			this.addChild(
				stickerPopular,
				stickerPopularHightlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHightlight.start();
		}
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = text;
	}

	private createSpecialBonus(rewardCount: number, rewardId: string): PIXI.Container {
		const specialBonusContainer = new PIXI.Container();
		specialBonusContainer.position.set(0, 100);

		const specialBonusBg = new PIXI.Graphics();
		specialBonusBg.beginFill(0x000000);
		specialBonusBg.drawPolygon([
			new PIXI.Point(-368, -33),
			new PIXI.Point(383, -33),
			new PIXI.Point(368, 33),
			new PIXI.Point(-383, 33),
		]);
		specialBonusBg.alpha = 0.4;

		const specialBonusIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[`i_${rewardId}_big`]);
		specialBonusIcon.x = -357;
		specialBonusIcon.scale.set(0.8);

		let specialBonusString: string = this.localizationStorage.getLocalizedString(this.getSpecialBonusLocalizationKey())
			.replace('{{count}}', rewardCount.toString());

		specialBonusString = TextUtils.getWordEnding(this.localizationStorage.getLanguage(), rewardCount, specialBonusString);

		const specialBonusText = new TextField(
			`+${specialBonusString}`,
			{ font: '39px wendyOneShadowBold', tint: 0xa4ff17 },
			630,
			50,
		);
		specialBonusText.anchor = 0.5;
		specialBonusText.x = -9;

		specialBonusContainer.addChild(
			specialBonusBg,
			specialBonusIcon as PIXI.DisplayObject,
			specialBonusText,
		);

		return specialBonusContainer;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
