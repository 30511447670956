import { BankModel, BankTabElementWithOfferType, BankTabGeneralObjectWithOfferType } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankOfferWindowOriginType, BankOfferMoveFromOfferWindowType } from '@src/types/BankTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { NumberUtils } from '@src/utils/NumberUtils';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { BankOfferWindowBodyView } from '@views/windows/bank/offerWindow/BankOfferWindowBodyView';
import { HintTypes, HintDataReward } from '@src/types/HintTypes';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export class BankOfferWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	private bankOfferModel: BankOfferModel;
	private bankTabGeneralObjectWithOfferType: BankTabGeneralObjectWithOfferType;

	private readonly tickerTimer: PIXI.ticker.Ticker;

	private readonly body: BankOfferWindowBodyView;

	constructor(
		private readonly bankModel: BankModel,
		private readonly bankSavesModel: BankSavesModel,
		private readonly bankOfferModelKey: string,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
		private readonly bankOfferWindowOrigin: BankOfferWindowOriginType,
	) {
		super(0.8);

		this.bankOfferModel = this.bankModel.getBankOfferModelByKey(bankOfferModelKey);

		this.bankTabGeneralObjectWithOfferType = bankModel.getBankTabElementByOfferKey(bankOfferModelKey);

		this.body = new BankOfferWindowBodyView(
			this.bankOfferModel,
			this.bankSavesModel,
			this.bankTabGeneralObjectWithOfferType,
			cardMiniViewFactory,
		);
		this.body.on(BankOfferWindowBodyView.EVENT_BUTTON_BUY_CLICK, this.onButtonBuyClick, this);
		this.body.on(
			BankOfferWindowBodyView.EVENT_SHOW_HINT_REWARD_MINI_CARD,
			(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
				const data: HintDataReward = { rewardDescription, origin };
				this.emit(BankOfferWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
			},
			this,
		);

		this.mainContainer.addChild(this.body);

		this.tickerTimer = PIXI.ticker.shared;
	}

	public onShown(): void {
		this.tickerTimer.add(this.onUpdateTextTimer, this);

		this.bankOfferModel.once(BankOfferModel.EVENT_TIMEOUT, this.onBankOfferTimeout, this);
		this.bankSavesModel.once(BankSavesModel.EVENT_BANK_PURCHASE_OFFER_SUCCESS, this.onClose, this);
		this.bankModel.on(BankModel.EVENT_OBSOLETE, this.onBankModelObsolete, this);
		this.bankModel.on(BankModel.EVENT_UPDATED, this.onBankModelUpdated, this);

		super.onShown();
	}

	private async onButtonBuyClick(bankTabElementWithOffer: BankTabElementWithOfferType): Promise<void> {
		this.emit(
			BankOfferWindowView.EVENT_BUTTON_BUY_CLICK,
			bankTabElementWithOffer,
			this.bankOfferModel,
			this.bankOfferWindowOrigin,
		);
		if (this.bankOfferModel.getMoveFromOfferWindowType() === BankOfferMoveFromOfferWindowType.PURCHASE) {
			this.setCloseOnBlackoutClick(false);
			await this.startCloseAnimation();
		}
	}

	private onBankModelObsolete(value: boolean): void {
		this.body.setButtonBuyEnabled(value);
		if (!value) {
			this.onClose(false);
		}
	}

	private onBankModelUpdated(): void {
		this.removeBankOfferListenersIfAny();

		if (!this.isBankOfferActive()) {
			if (!this.closed) {
				this.onClose(true);
			}
			return;
		}

		this.bankOfferModel = this.bankModel.getBankOfferModelByKey(this.bankOfferModelKey);
		this.bankOfferModel.once(BankOfferModel.EVENT_TIMEOUT, this.onBankOfferTimeout, this);
		this.bankTabGeneralObjectWithOfferType = this.bankModel.getBankTabElementByOfferKey(this.bankOfferModelKey);
	}

	private onBankOfferTimeout(): void {
		this.onClose();
	}

	private isBankOfferActive(): boolean {
		return this.bankModel.getBankOfferModelsWithActiveBankTabElement()
			.find(offerModel => offerModel.getKey() === this.bankOfferModelKey && offerModel.getTimeleft() > 0) !== undefined;
	}

	private onUpdateTextTimer(): void {
		this.body.setTimeLabelText(NumberUtils.secToDHMSColonFormatted(this.bankOfferModel.getTimeleft()));
	}

	private removeBankOfferListenersIfAny(): void {
		if (this.bankOfferModel.listeners(BankOfferModel.EVENT_TIMEOUT).includes(this.onBankOfferTimeout)) {
			this.bankOfferModel.off(BankOfferModel.EVENT_TIMEOUT, this.onBankOfferTimeout, this, true);
		}
	}

	public getBankOfferKey(): string {
		return this.bankOfferModelKey;
	}

	public getBankOfferWindowOrigin(): BankOfferWindowOriginType {
		return this.bankOfferWindowOrigin;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.tickerTimer.remove(this.onUpdateTextTimer, this);

		this.removeBankOfferListenersIfAny();

		this.bankModel.off(BankModel.EVENT_OBSOLETE, this.onBankModelObsolete, this);
		this.bankModel.off(BankModel.EVENT_UPDATED, this.onBankModelUpdated, this);

		this.bankSavesModel.off(BankSavesModel.EVENT_BANK_PURCHASE_OFFER_SUCCESS, this.onClose, this, true);

		super.destroy(options);
	}
}
