import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import {
	RewardResultWindowViewSetter,
	CharacterCollectionViewSetter,
	TotemCollectionViewSetter,
	UpgradeCollectionViewSetter,
	GalleryCollectionViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { SkillModel } from '@models/skills/SkillModel';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';
import { RewardResultWindowView } from '@views/windows/rewardResultWindow/RewardResultWindowView';
import { UpgradeCollectionDefaultView } from '@views/windows/collection/upgrades/UpgradeCollectionDefaultView';
import { UpgradeCollectionGroupByBusinessesView } from '@views/windows/collection/upgrades/UpgradeCollectionGroupByBusinessesView';
import { TotemCollectionView } from '@views/windows/collection/totems/TotemCollectionView';
import { CharacterCollectionView } from '@views/windows/collection/characters/CharacterCollectionView';
import { GalleryCollectionView } from '@views/windows/collection/gallery/GalleryCollectionView';
import { BankTabTypes } from '@src/types/BankTypes';
import { FurnitureCollectionView } from '@views/windows/collection/furniture/FurnitureCollectionView';

export class CollectionCardsViewController
implements RewardResultWindowViewSetter, CharacterCollectionViewSetter,
	TotemCollectionViewSetter, UpgradeCollectionViewSetter, FurnitureCollectionViewSetter, GalleryCollectionViewSetter {
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly galleryVideoModels: Map<string, GalleryVideoModel>;
	private readonly skillModels: Map<string, SkillModel>;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		skillModels: Map<string, SkillModel>,
	) {
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.galleryVideoModels = galleryVideoModels;
		this.skillModels = skillModels;
	}

	public setRewardResultWindowView(view: RewardResultWindowView): void {
		if (!view.listeners(RewardResultWindowView.EVENT_CARD_CLICK_CHARACTER).includes(this.onCardClickCharacter)) {
			view.on(RewardResultWindowView.EVENT_CARD_CLICK_CHARACTER, this.onCardClickCharacter, this);
			view.on(RewardResultWindowView.EVENT_CARD_CLICK_UPGRADE, this.onCardClickUpgrade, this);
			view.on(RewardResultWindowView.EVENT_CARD_CLICK_TOTEM, this.onCardClickTotem, this);
			view.on(RewardResultWindowView.EVENT_CARD_CLICK_GALLERY_VIDEO, this.onCardClickVideo, this);
		}
	}

	public setCharacterCollectionView(view: CharacterCollectionView): void {
		if (!view.listeners(CharacterCollectionView.EVENT_CARD_CLICK).includes(this.onCardClickCharacter)) {
			view.on(CharacterCollectionView.EVENT_CARD_CLICK, this.onCardClickCharacter, this);
		}
		if (!view.listeners(CharacterCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK).includes(this.onButtonMoneyPlusClick)) {
			view.on(CharacterCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
		}
	}

	public setUpgradeCollectionViewSetter(view: UpgradeCollectionDefaultView | UpgradeCollectionGroupByBusinessesView): void {
		if (view instanceof UpgradeCollectionDefaultView) {
			if (!view.listeners(UpgradeCollectionDefaultView.EVENT_CARD_CLICK).includes(this.onCardClickUpgrade)) {
				view.on(UpgradeCollectionDefaultView.EVENT_CARD_CLICK, this.onCardClickUpgrade, this);
			}
			if (!view.listeners(UpgradeCollectionDefaultView.EVENT_BUTTON_MONEY_PLUS_CLICK).includes(this.onButtonMoneyPlusClick)) {
				view.on(UpgradeCollectionDefaultView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
			}
		} else if (!view.listeners(UpgradeCollectionGroupByBusinessesView.EVENT_CARD_CLICK).includes(this.onCardClickUpgrade)) {
			view.on(UpgradeCollectionGroupByBusinessesView.EVENT_CARD_CLICK, this.onCardClickUpgrade, this);
		}
	}

	public setFurnitureCollectionView(view: FurnitureCollectionView): void {
		if (!view.listeners(FurnitureCollectionView.EVENT_CARD_CLICK).includes(this.onCardClickUpgrade)) {
			view.on(FurnitureCollectionView.EVENT_CARD_CLICK, this.onCardClickUpgrade, this);
		}
	}

	public setTotemCollectionViewSetter(view: TotemCollectionView): void {
		if (!view.listeners(TotemCollectionView.EVENT_CARD_CLICK).includes(this.onCardClickTotem)) {
			view.on(TotemCollectionView.EVENT_CARD_CLICK, this.onCardClickTotem, this);
		}
	}

	public setGalleryCollectionViewSetter(view: GalleryCollectionView): void {
		view.on(GalleryCollectionView.EVENT_CARD_CLICK, this.onCardClickVideo, this);
	}

	private onCardClickCharacter(data: PromotableClickData): void {
		const character = this.characterModels.get(data.model);
		const skill = this.skillModels.get(data.skill);
		const window = this.windowFactory.createCharacterProfileWindow(character, skill);
		this.windowViewSystem.showWindow(window);
	}

	private onCardClickTotem(data: PromotableClickData): void {
		const totem = this.totemModels.get(data.model);
		const window = this.windowFactory.createTotemPromoteWindow(totem);
		this.windowViewSystem.showWindow(window);
	}

	private onCardClickUpgrade(data: PromotableClickData): void {
		const upgrade = this.upgradeModels.get(data.model);
		const window = this.windowFactory.createUpgradePromoteWindow(upgrade);
		this.windowViewSystem.showWindow(window);
	}

	private onCardClickVideo(data: PromotableClickData): void {
		this.galleryVideoModels.get(data.model).play();
	}

	private onButtonMoneyPlusClick(): void {
		const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(window);
	}
}
