import { BaseAction } from '../BaseAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class TimedQuestCompleteAction extends BaseAction {
	constructor(questId: string, rewards: RewardDescriptionType[]) {
		super('SideQuestComplete');

		this.data.id = questId;
		this.data.rewards = rewards;
	}
}
