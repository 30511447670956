import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { BankSubscribeConfig, CountByRariries } from '@configs/bank/BankSubscribeConfig';
import { NumberUtils } from '@src/utils/NumberUtils';

export class BankSubscribeModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_TIMEOUT = Symbol();

	private readonly viewKey: string;
	private readonly key: string;
	private readonly nameLocale: string;
	private readonly descriptionLocale: string;
	private readonly price: number;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly extraReward: RewardDescriptionType;
	private readonly duration: string;
	private readonly isActive: boolean;
	private readonly claimableOn: number;
	private readonly timeRemain: number;
	private readonly rewardType: RewardTypes;
	private readonly countByRariry: CountByRariries;
	private expireOn: number;
	private timeLeft: number;
	private nextClaimIn: number;
	private rewardCountPerDay: string;

	constructor(config: BankSubscribeConfig, priceConversionCoeff: number) {
		super();
		this.viewKey = config.getViewKey();
		this.key = config.getKey();
		this.nameLocale = config.getNameLocale();
		this.descriptionLocale = config.getDescriptionLocale();
		this.price = NumberUtils.convertPrice(config.getPrice(), config.getPriceResource(), priceConversionCoeff);
		this.priceResource = config.getPriceResource();
		this.extraReward = config.getExtraReward();
		this.duration = config.getDuration();
		this.isActive = config.getIsActive();
		this.claimableOn = config.getClaimableOn();
		this.timeRemain = config.getTimeRemain();
		this.rewardType = config.getRewardType();
		this.countByRariry = config.getCountByRarity();
		this.expireOn = 0;
		this.timeLeft = 0;
		this.nextClaimIn = 0;
		this.rewardCountPerDay = config.getRewardCountPerDay();
	}

	public getViewKey(): string {
		return this.viewKey;
	}

	public getKey(): string {
		return this.key;
	}

	public getNameLocale(): string {
		return this.nameLocale;
	}

	public getDescriptionLocale(): string {
		return this.descriptionLocale;
	}

	public getPrice(): number {
		return this.price;
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getExtraReward(): RewardDescriptionType {
		return this.extraReward;
	}

	public getDuration(): string {
		return this.duration;
	}

	public getIsActive(): boolean {
		return this.isActive;
	}

	public getClaimableOn(): number {
		return this.claimableOn;
	}

	public getTimeRemain(): number {
		return this.timeRemain;
	}

	public getRewardType(): RewardTypes {
		return this.rewardType;
	}

	public getTotalRewardCount(): number {
		return Object.keys(this.countByRariry).reduce((total, currentRarity) => total + this.countByRariry[currentRarity], 0);
	}

	public getCountByRarity(): CountByRariries {
		return this.countByRariry;
	}

	public getTimeLeft(): number {
		return this.timeLeft;
	}

	public setTimeLeft(time: number): void {
		this.timeLeft = time;
	}

	public getNextClaimIn(): number {
		return this.nextClaimIn;
	}

	public setNextClaimIn(time: number): void {
		this.nextClaimIn = time;
	}

	public getExpireOn(): number {
		return this.expireOn;
	}

	public setExpireOn(time: number): void {
		this.expireOn = time;
	}

	public timeout(): void {
		this.emit(BankSubscribeModel.EVENT_TIMEOUT);
	}

	public getRewardCountPerDay(): string {
		return this.rewardCountPerDay;
	}
}
