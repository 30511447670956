import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { BankBundleGroupConfig } from '@configs/bank/BankBundleGroupConfig';
import { BankGemShopItemConfig } from '@configs/bank/BankGemShopItemConfig';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';
import { BankRechargeConfig } from '@configs/bank/BankRechargeConfig';
import { BankSubscribeConfig } from '@configs/bank/BankSubscribeConfig';
import { BankTabConfig } from '@configs/bank/BankTabConfig';
import { BankConfigsParser } from '@configs/configsParsers/BankConfigsParser';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export abstract class BankConfigsLoaderBase {
	protected readonly bankConfigsParser: BankConfigsParser;

	constructor(
		protected readonly networkRequestSender: NetworkRequestSender,
	) {
		this.bankConfigsParser = new BankConfigsParser();
	}

	public abstract loadConfigs(): Promise<void>;

	public getSubscribesConfigs(): Map<string, BankSubscribeConfig> {
		return this.bankConfigsParser.getSubscribesConfigs();
	}

	public getGemShopConfigs(): Map<string, BankGemShopItemConfig> {
		return this.bankConfigsParser.getGemShopConfigs();
	}

	public getBundlesConfigs(): Map<string, BankBundleConfig> {
		return this.bankConfigsParser.getBundlesConfigs();
	}

	public getBankTabsConfigs(): Map<string, BankTabConfig> {
		return this.bankConfigsParser.getBankTabsConfigs();
	}

	public getOffersConfigs(): Map<string, BankOfferConfig> {
		return this.bankConfigsParser.getOffersConfigs();
	}

	public getBundleGroupsConfigs(): Map<string, BankBundleGroupConfig> {
		return this.bankConfigsParser.getBundleGroupsConfigs();
	}

	public getRechargeConfigs(): Map<string, BankRechargeConfig> {
		return this.bankConfigsParser.getRechargeConfigs();
	}
}
