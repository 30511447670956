export class BankElementViewDataConfig {
	public static readonly FIELD_VIEW_KEY: string = 'prefab_key';

	protected readonly viewKey: string;

	constructor(viewKey: string) {
		this.viewKey = viewKey;
	}

	public getViewKey(): string {
		return this.viewKey;
	}
}
