import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { BankBundleSaveData } from '@src/types/SaveTypes';
import { BankUIViewHelper } from '@views/windows/bank/BankUIViewHelper';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export class BankBundlesFactory {
	constructor(
		private readonly isNutaku: boolean,
	) {}

	public createModels(
		bundlesConfigs: Map<string, BankBundleConfig>,
		bankSavesModel: BankSavesModel,
		currentTime: number,
		priceConversionCoeff: number,
	): Map<string, BankBundleModel> {
		const bundleModels = new Map();
		const bundlesSaveData = bankSavesModel.getBundlesSaveData();

		bundlesConfigs.forEach((config, key) => {
			const saveData: BankBundleSaveData | undefined = bundlesSaveData.get(key);
			const acceptedBuyTimes = bankSavesModel.getBundleAcceptedBuyTimes(key, currentTime);
			const buttonPriceResource = BankUIViewHelper.resolveButtonValueTypeByPriceResource(
				config.getPriceResource(),
				this.isNutaku,
			);
			const buttonOldPriceResource = BankUIViewHelper.resolveButtonValueTypeByPriceResource(
				config.getOldPriceResource(),
				this.isNutaku,
			);
			const model = new BankBundleModel(
				config,
				buttonPriceResource,
				buttonOldPriceResource,
				priceConversionCoeff,
				acceptedBuyTimes,
				saveData?.expiresOn,
			);

			if (model.getAvailableOn() > currentTime) {
				model.setTimeLeft(model.getAvailableOn() - currentTime);
			}

			bundleModels.set(key, model);
		});
		return bundleModels;
	}
}
