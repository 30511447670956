import { BaseViewDispatcher, ViewDispatcherTargets, ViewDispatcherTargetType } from './BaseViewDispatcher';
import { EventGameUIViewSetter, EventRankingWindowViewSetter, EventLevelRewardsWindowViewSetter } from '@interfaces/ViewSetters';
import { EventLevelGameUIView } from '@views/ui/main/EventLevelGameUIView';
import { EventLevelRankingWindowView } from '@views/windows/rankingEventWindow/EventLevelRankingWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { EventLevelRewardsWindowView } from '@views/windows/EventLevelRewardsWindowView';

export interface EventViewDispatcherTargets extends ViewDispatcherTargets {
	eventGameUIViewSetters?: EventGameUIViewSetter[];
	eventRankingViewSetters?: EventRankingWindowViewSetter[];
	eventLevelRewardsWindowViewSetters?: EventLevelRewardsWindowViewSetter[];
}

export class EventLevelViewDispatcher extends BaseViewDispatcher {
	private eventGameUIViewTargets?: ViewDispatcherTargetType<EventGameUIViewSetter>[];
	private eventRankingWindowViewTargets?: ViewDispatcherTargetType<EventRankingWindowViewSetter>[];
	private eventLevelRewardsWindowViewTargets?: ViewDispatcherTargetType<EventLevelRewardsWindowViewSetter>[];

	public updateTargets(args: EventViewDispatcherTargets): void {
		super.updateTargets(args);

		if (args.eventGameUIViewSetters) {
			this.eventGameUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.eventGameUIViewSetters,
				this.eventGameUIViewTargets,
			);
		}
		if (args.eventRankingViewSetters) {
			this.eventRankingWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.eventRankingViewSetters,
				this.eventRankingWindowViewTargets,
			);
		}

		if (args.eventLevelRewardsWindowViewSetters) {
			this.eventLevelRewardsWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.eventLevelRewardsWindowViewSetters,
				this.eventLevelRewardsWindowViewTargets,
			);
		}
	}

	public dispatch(): void {
		super.dispatch();

		this.dispatchEventGameUIView();
		this.dispatchEventWindowViews();
	}

	private dispatchEventGameUIView(): void {
		if (this.eventGameUIViewTargets) {
			this.eventGameUIViewTargets
				.filter(target => target.dispatcherView !== this.gameUIView)
				.forEach(target => {
					target.dispatcherView = this.gameUIView;
					target.dispatcherTarget.setEventGameUIView(this.gameUIView as EventLevelGameUIView);
				});
		}
	}

	private dispatchEventWindowViews(): void {
		if (this.eventRankingWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_EVENT_RANKING_SHOWED).includes(this.setEventRankingWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_EVENT_RANKING_SHOWED, this.setEventRankingWindowView, this);
			}
		}
		if (this.eventLevelRewardsWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED).includes(this.setEventLevelRewardsWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED, this.setEventLevelRewardsWindowView, this);
			}
		}
	}

	private setEventRankingWindowView(view: EventLevelRankingWindowView): void {
		this.eventRankingWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setEventRankingWindowView(view));
	}

	private setEventLevelRewardsWindowView(view: EventLevelRewardsWindowView): void {
		this.eventLevelRewardsWindowViewTargets?.forEach(s => s.dispatcherTarget.setEventLevelRewardsWindowView(view));
	}
}
