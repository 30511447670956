import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BonusTypes } from '@src/types/BonusTypes';

export class BoostConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_TIME: string = 'time';
	public static readonly FIELD_ICON: string = 'icon';
	public static readonly FIELD_BONUS: string = 'bonus';
	public static readonly FIELD_BONUS_VALUE: string = 'bonus_value';

	private readonly key: string;
	private readonly time: number;
	private readonly iconKey: string;

	private readonly bonusType: BonusTypes;
	private readonly bonusValue: number;

	constructor(config: { [key: string]: string }) {
		this.key = config[BoostConfig.FIELD_KEY].trim();
		this.time = Number(config[BoostConfig.FIELD_TIME].trim());
		this.iconKey = config[BoostConfig.FIELD_ICON].trim();

		this.bonusType = config[BoostConfig.FIELD_BONUS].trim() as BonusTypes;
		this.bonusValue = Number(config[BoostConfig.FIELD_BONUS_VALUE].trim());
	}

	public getKey(): string {
		return this.key;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getTime(): number {
		return this.time;
	}

	public getBonusType(): BonusTypes {
		return this.bonusType;
	}

	public getBonusValue(): number {
		return this.bonusValue;
	}
}
