import { ViewportView } from '@views/components/ViewportView';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { CharacterProfileWindow } from '@views/windows/collection/characters/CharacterProfileWindow';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { TotemPromoteWindowView } from '@views/windows/collection/totems/TotemPromoteWindowView';
import { UpgradePromoteWindowView } from '@views/windows/collection/upgrades/UpgradePromoteWindowView';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { PrepartyWindowView } from '@views/windows/farewellParty/preparty/PrepartyWindowView';
import { QuestWindowBaseView } from '@views/windows/quests/QuestWindowBaseView';
import { EventLevelRewardsWindowView } from '@views/windows/EventLevelRewardsWindowView';
import { BackToGameWindowView } from '@views/windows/backToGame/BackToGameWindowView';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { TutorialStepView } from '@views/windows/TutorialStepWindowView';
import { SupportWindowView } from '@views/windows/support/SupportWindowView';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { DialogWindowView } from '@views/windows/dialog/DialogWindowView';
import { LevelStartWindowView } from '@views/windows/levelStartWindow/LevelStartWindowView';
import { CollectEventLevelRewardsWindowView } from '@views/windows/CollectEventLevelRewardsWindowView';
import { EventLevelRankingWindowView } from '@views/windows/rankingEventWindow/EventLevelRankingWindowView';
import { RewardResultWindowBaseView } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { GoToEventLevelWindowView } from '@views/windows/GoToEventLevelWindowView';
import { CheatWindowView } from './cheatWindow/CheatWindowView';
import { BaseSettingsWindowView } from './settings/BaseSettingsWindowView';
import { BaseNewsWindowView } from './newsWindow/BaseNewsWindowView';
import { LevelChallengeWindowView } from './levelChallenge/LevelChallengeWindowView';
import { BankBoostConfirmWindowView } from './bank/animations/BankBoostApplyConfirmView';
import { DailyWindowBaseView } from './dailyWindow/DailyWindowBaseView';

export type PromoteWindowType =
	CharacterProfileWindow |
	TotemPromoteWindowView |
	UpgradePromoteWindowView;

export class WindowViewSystem extends PIXI.utils.EventEmitter {
	public static readonly EVENT_WINDOW_TOTEM_PROMOTE_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_UPGRADE_PROMOTE_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_CHARACTER_PROFILE_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_FAREWELL_PARTY_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_DIALOG_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_QUEST_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_TUTORIAL_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_SUMMON_RESULT_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_BANK_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_BANK_OFFER_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_COLLECTIONS_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_SETTINGS_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_REWARD_RESULT_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_SUPPORT_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_REGISTRATION_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_LOGIN_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_BACK_TO_GAME_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_PREPARTY_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_LEVEL_START_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_COLLECT_EVENT_REWARDS_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_EVENT_RANKING_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_EVENT_REWARDS_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_GO_TO_EVENT_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_CHEAT_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_NEWS_SHOWED = Symbol();
	public static readonly EVENT_WINDOW_LEVEL_CHALLENGE_SHOWED = Symbol();
	public static readonly EVENT_WINDOW_BANK_BOOST_CONFIRM_SHOWED = Symbol();
	public static readonly EVENT_WINDOW_DAILY_REWARD_SHOWED = Symbol();

	private currentWindows: WindowBaseView[];
	private pendingWindows: WindowBaseView[];

	private collectionWindow: CollectionWindowView;
	private bankWindow: BankWindowView;
	private questWindow: QuestWindowBaseView;
	private summonResultWindow: SummonResultWindowView;
	private prepartyWindow: PrepartyWindowView;
	private farewellPartyWindow: FarewellPartyWindowView;
	private promoteWindow: PromoteWindowType;
	private eventLevelRewardsWindow: EventLevelRewardsWindowView;
	private bankBoostConfirmWindow: BankBoostConfirmWindowView;

	private viewport: ViewportView;
	private windowsContainer: PIXI.Container;

	constructor() {
		super();

		this.currentWindows = [];
		this.pendingWindows = [];
	}

	public setViewport(viewport: ViewportView): void {
		this.viewport = viewport;
	}

	public setWindowsContainer(target: PIXI.Container): void {
		this.windowsContainer = target;
	}

	public addWindowPending(
		window: WindowBaseView,
	): void {
		if (this.currentWindows.length === 0) {
			this.showWindow(window);
		} else {
			this.pendingWindows.push(window);
		}
	}

	public showWindow(
		window: WindowBaseView,
	): void {
		window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => this.onWindowClosed(window), this);

		if (this.viewport.isDragEnabled()) {
			this.viewport.setDragEnabled(false);
		}

		const lastWindowId = this.currentWindows.length - 1;
		if (lastWindowId >= 0) {
			const lastWindow = this.currentWindows[lastWindowId];
			if (!lastWindow.isBlurred()) {
				lastWindow.onBlur();
			}
		}

		this.currentWindows.push(window);
		this.windowsContainer.addChild(window);

		if (window instanceof FarewellPartyWindowView) {
			this.farewellPartyWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_FAREWELL_PARTY_SHOWED, window);
		} else if (window instanceof LevelStartWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_LEVEL_START_SHOWED, window);
		} else if (window instanceof BackToGameWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_BACK_TO_GAME_SHOWED, window);
		} else if (window instanceof QuestWindowBaseView) {
			this.questWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_QUEST_SHOWED, window);
		} else if (window instanceof CollectionWindowView) {
			this.collectionWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, window);
		} else if (window instanceof CharacterProfileWindow) {
			this.promoteWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_CHARACTER_PROFILE_SHOWED, window);
		} else if (window instanceof UpgradePromoteWindowView) {
			this.promoteWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_UPGRADE_PROMOTE_SHOWED, window);
		} else if (window instanceof TotemPromoteWindowView) {
			this.promoteWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_TOTEM_PROMOTE_SHOWED, window);
		} else if (window instanceof BankWindowView) {
			this.bankWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, window);
		} else if (window instanceof BankOfferWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_BANK_OFFER_SHOWED, window);
		} else if (window instanceof TutorialStepView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_TUTORIAL_SHOWED, window);
		} else if (window instanceof BaseSettingsWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_SETTINGS_SHOWED, window);
		} else if (window instanceof RewardResultWindowBaseView) {
			if (window instanceof SummonResultWindowView) {
				this.summonResultWindow = window;
				this.emit(WindowViewSystem.EVENT_WINDOW_SUMMON_RESULT_SHOWED, window);
			}
			this.emit(WindowViewSystem.EVENT_WINDOW_REWARD_RESULT_SHOWED, window);
		} else if (window instanceof SupportWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_SUPPORT_SHOWED, window);
		} else if (window instanceof RegistrationWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_REGISTRATION_SHOWED, window);
		} else if (window instanceof DialogWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_DIALOG_SHOWED, window);
		} else if (window instanceof CollectEventLevelRewardsWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_COLLECT_EVENT_REWARDS_SHOWED, window);
		} else if (window instanceof EventLevelRankingWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_EVENT_RANKING_SHOWED, window);
		} else if (window instanceof EventLevelRewardsWindowView) {
			this.eventLevelRewardsWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_EVENT_REWARDS_SHOWED, window);
		} else if (window instanceof PrepartyWindowView) {
			this.prepartyWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_PREPARTY_SHOWED, window);
		} else if (window instanceof GoToEventLevelWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_GO_TO_EVENT_SHOWED, window);
		} else if (window instanceof CheatWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_CHEAT_SHOWED, window);
		} else if (window instanceof BaseNewsWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_NEWS_SHOWED, window);
		} else if (window instanceof LevelChallengeWindowView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_LEVEL_CHALLENGE_SHOWED, window);
		} else if (window instanceof BankBoostConfirmWindowView) {
			this.bankBoostConfirmWindow = window;
			this.emit(WindowViewSystem.EVENT_WINDOW_BANK_BOOST_CONFIRM_SHOWED, window);
		} else if (window instanceof DailyWindowBaseView) {
			this.emit(WindowViewSystem.EVENT_WINDOW_DAILY_REWARD_SHOWED, window);
		}

		window.onShown();
	}

	private tryShowPendingWindow(): boolean {
		if (this.currentWindows.length === 0 && this.pendingWindows.length > 0) {
			const window = this.pendingWindows.shift();
			this.showWindow(
				window,
			);
			return true;
		}
		return false;
	}

	private onWindowClosed(window: WindowBaseView): void {
		const windowQueued = this.currentWindows.filter(w => w === window)[0];

		this.currentWindows.splice(this.currentWindows.indexOf(windowQueued), 1);

		const pendingWindowShowed = this.tryShowPendingWindow();
		if (!pendingWindowShowed && this.currentWindows.length > 0) {
			const lastWindow = this.currentWindows[this.currentWindows.length - 1];
			if (lastWindow.isBlurred()) {
				lastWindow.onFocus();
			}
		}

		if (this.currentWindows.length === 0 && !this.viewport.isZoomed() && !this.viewport.isZooming()) {
			this.viewport.setDragEnabled(true);
		}
	}

	public getPromoteWindow(): PromoteWindowType {
		return this.promoteWindow;
	}

	public getPrepartyWindow(): PrepartyWindowView {
		return this.prepartyWindow;
	}

	public getFarewellPartyWindow(): FarewellPartyWindowView {
		return this.farewellPartyWindow;
	}

	public getQuestWindow(): QuestWindowBaseView {
		return this.questWindow;
	}

	public getCollectionWindow(): CollectionWindowView {
		return this.collectionWindow;
	}

	public getBankWindow(): BankWindowView {
		return this.bankWindow;
	}

	public getSummonResultWindowView(): SummonResultWindowView {
		return this.summonResultWindow;
	}

	public getEventRewardsWindow(): EventLevelRewardsWindowView {
		return this.eventLevelRewardsWindow;
	}

	public getBankBoostConfirmWindow(): BankBoostConfirmWindowView {
		return this.bankBoostConfirmWindow;
	}

	public isNewsWindowShown(): boolean {
		return this.currentWindows.find((window) => window instanceof BaseNewsWindowView) != null;
	}
}
