import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character16View } from '@views/characters/main/Character16View';
import { Character17View } from '@views/characters/main/Character17View';
import { Character18View } from '@views/characters/main/Character18View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business6View extends BusinessBaseView {
	constructor() {
		const character16 = new Character16View();
		const character17 = new Character17View();
		const character18 = new Character18View();

		super('business6', [character16, character17, character18]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b6_decor2']).setTransform(-2, 0, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(-229, -20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(229, -20, -0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b6_decor3']).setTransform(252, -37, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChild(
			character16 as PIXI.DisplayObject,
			character17,
			character18,
			...this.decorations,
		);
	}
}
