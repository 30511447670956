import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90013View } from '@views/characters/events/event9/EventCharacter90013View';
import { EventCharacter90014View } from '@views/characters/events/event9/EventCharacter90014View';
import { EventCharacter90015View } from '@views/characters/events/event9/EventCharacter90015View';

export class EventBusiness90005View extends BusinessBaseView {
	constructor() {
		const eventCharacter90013View = new EventCharacter90013View();
		const eventCharacter90014View = new EventCharacter90014View();
		const eventCharacter90015View = new EventCharacter90015View();

		super('business90005', [eventCharacter90013View, eventCharacter90014View, eventCharacter90015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter90013View,
			eventCharacter90015View,
			eventCharacter90014View as PIXI.DisplayObject,
		);
	}
}
