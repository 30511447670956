import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter55009View extends CharacterMainBaseView {
	constructor() {
		super('character55009');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-174, -44),
			bgPosition: new PIXI.Point(-80, -47),
			arrowRotation: -Math.PI / 2,
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
