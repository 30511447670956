import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { BoostModel } from '@models/BoostModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';

export class CheatWindowBoostsViewController implements CheatWindowViewSetter {
	constructor(
		private readonly boostModels: Map<string, BoostModel>,
		private readonly serverTime: ServerTimeModel,
	) {}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_BOOST_ACQUIRE, this.onBoostAcquire, this);
		view.on(CheatWindowView.EVENT_BOOST_ACQUIRE_ALL, this.onBoostAcquireAll, this);
		view.on(CheatWindowView.EVENT_BOOST_ACTIVATE, this.onBoostActivate, this);
		view.on(CheatWindowView.EVENT_BOOSY_ACTIVATE_ALL, this.onBoostActivateAll, this);
	}

	private onBoostAcquire(key: string): void {
		this.boostModels.get(key).acquire();
	}

	private onBoostAcquireAll(): void {
		this.boostModels.forEach(x => x.acquire());
	}

	private onBoostActivate(key: string): void {
		this.boostModels.get(key).activate(this.serverTime.getCalculatedISOTime());
	}

	private onBoostActivateAll(): void {
		this.boostModels.forEach(x => x.activate(this.serverTime.getCalculatedISOTime()));
	}
}
