import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30004View extends CharacterMainBaseView {
	constructor() {
		super('character30004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-204, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-157, -65),
			arrowRotation: -Math.PI / 180,
			bgPosition: new PIXI.Point(26, -46),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
