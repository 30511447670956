import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter40007View extends CharacterMainBaseView {
	constructor() {
		super('character40007');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-200, 31);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-158, -13),
			arrowRotation: -Math.PI / 180,
			bgPosition: new PIXI.Point(24, 5),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
