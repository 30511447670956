import { CharacterModel } from '@models/CharacterModel';
import { TextField } from '@views/components/text/TextField';
import { ItemPromoteButtonView } from '../ItemPromoteButtonView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { SkillModel } from '@models/skills/SkillModel';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { TextDescriptionHelperCharacter } from '@views/windows/collection/characters/TextDescriptionHelperCharacter';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { SoundController } from '@src/main/SoundController';
import { PromoteAnimationProvider } from '@views/ui/cards/PromoteAnimationProvider';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { CharacterCardView } from '@views/ui/cards/CharacterCardView';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class CharacterProfileWindow extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_PROMOTE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();
	public static readonly EVENT_SELECT_PICTURE: symbol = Symbol();
	private static SEPARATOR_OFFSET = 29;

	private readonly localizationStorage: LocalizationStorage;
	private readonly model: CharacterModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly relatedBusinessKey: string;
	private readonly skillModel: SkillModel;

	private readonly closeButton: ButtonBaseView;
	private card: CharacterCardView;
	private profitDesc: MultiColoredTextField;
	private buttonPromote: ItemPromoteButtonView;
	private flyTextLabel: FlyBitmapText;

	private promoteAnimation: PromoteAnimationProvider;

	private separator: PIXI.Sprite;
	private content: PIXI.Container;
	private customisationConteiner: PIXI.Container;

	private leftArrow: ButtonBaseView;
	private rightArrow: ButtonBaseView;

	private nudeLevelLenght: number;
	private nudeLevelId: number;
	private maxAvailableNudeLevel: number;

	constructor(
		model: CharacterModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		relatedBusinessKey: string,
		moneyPanelsButtonPlusInvisible?: boolean,
		skillModel?: SkillModel,
	) {
		super(0.8, true);

		this.prestigeMoneyModel = prestigeMoneyModel;
		this.relatedBusinessKey = relatedBusinessKey;
		this.skillModel = skillModel;

		this.nudeLevelLenght = model.getTotalNudeLevels();
		this.nudeLevelId = model.getCurrentPictureId() - 1;
		this.maxAvailableNudeLevel = model.getMaxPictureIndex();

		this.model = model;
		this.model.on(CharacterModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.model.on(CharacterModel.EVENT_PROMOTED, this.startPromoteAnimation, this);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['upgrades_info_bg'], 43, 0, 43, 0);
		bg.width = 1470;
		bg.height = 886;
		bg.pivot.set(735, 443);
		bg.interactive = true;

		const profitBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['rarity_info_panel'], 22, 23, 22, 23);
		profitBg.width = 680;
		profitBg.height = 200;
		profitBg.pivot.set(340, 100);
		profitBg.position.set(300, 140);

		const cardView = new CharacterCardView(
			model,
			skillModel,
			false,
		);
		cardView.position.set(-384, -36);
		cardView.interactive = true;
		this.card = cardView;

		this.localizationStorage = LocalizationStorage.getInstance();

		const characterKey = model.getKey();

		const descText = new TextField(
			this.localizationStorage.getLocalizedString(`#${characterKey}_Desc`),
			{ font: { size: 30, name: 'wendyOne' }, tint: 0xbee1fe },
			730,
			130,
		);
		descText.position.set(-40, -340);
		descText.anchor = new PIXI.Point(0, 0.5);

		const fromLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#info_window_from'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		fromLabel.position.set(-40, -260);

		const fromValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${characterKey}_From`),
			{ font: { size: 28, name: 'wendyOne' } },
			500,
			60,
		);
		fromValue.position.set(135, -260);

		const braSizeLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#info_window_brasize'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		braSizeLabel.position.set(-40, -190);

		const braSizeValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${characterKey}_BraSize`),
			{ font: { size: 28, name: 'wendyOne' } },
			500,
			60,
		);
		braSizeValue.position.set(135, -190);

		const ageLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#info_window_age'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		ageLabel.position.set(-40, -120);

		const ageValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${characterKey}_Age`),
			{ font: { size: 28, name: 'wendyOne' } },
			500,
			60,
		);
		ageValue.position.set(135, -120);

		const hobbyLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#info_window_hobby'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		hobbyLabel.position.set(-40, -50);

		const hobbyValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${characterKey}_Hobby`),
			{ font: { size: 28, name: 'wendyOne' } },
			500,
			60,
		);
		hobbyValue.position.set(135, -50);

		const profitDesc = new MultiColoredTextField(
			{ font: { size: 40, name: 'wendyOneShadowBold' }, align: 'center' },
			650,
			160,
		);
		profitDesc.position.set(300, 140);
		profitDesc.anchor = 0.5;
		this.profitDesc = profitDesc;
		this.updateProfitDesc();

		const buttonPromote = new ItemPromoteButtonView(
			model.isEnoughCardsForPromote(),
			this.prestigeMoneyModel.getValue() >= model.getPromoteCostPrestige(),
		);
		buttonPromote.position.set(310, 330);
		buttonPromote.setCost(model.getPromoteCostPrestige().toString());
		buttonPromote.on(ItemPromoteButtonView.EVENT_CLICK, this.onPromoteClicked, this);
		this.buttonPromote = buttonPromote;

		const promoteParticlesContainer = new PIXI.Container();
		promoteParticlesContainer.position.set(300, 140);
		promoteParticlesContainer.visible = false;

		const promoteGlow = new PIXI.Sprite(fxAtlas['promote_glow1']);
		promoteGlow.visible = false;
		promoteGlow.alpha = 0.8;
		promoteGlow.position.set(300, 140);
		promoteGlow.blendMode = PIXI.BLEND_MODES.ADD;
		promoteGlow.scale.set(0);

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(900, -500);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.promoteAnimation = new PromoteAnimationProvider(
			profitDesc,
			promoteParticlesContainer,
			promoteGlow,
		);
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_PROFIT_SCALE_DOWN_COMPLETED, () => { this.updateProfitDesc(); });
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_ANIMATION_COMPLETED, this.onPromoteAnimationCompleted, this);

		const hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
		hardMoneyPanel.position.set(670, -500);

		const prestigeMoneyPanel = new PrestigeMoneyPanelView(this.prestigeMoneyModel);
		prestigeMoneyPanel.position.set(410, -500);

		if (moneyPanelsButtonPlusInvisible) {
			prestigeMoneyPanel.setButtonPlusVisible(false);
			hardMoneyPanel.setButtonPlusVisible(false);
		}

		prestigeMoneyPanel.on(PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.emit(CharacterProfileWindow.EVENT_BUTTON_MONEY_PLUS_CLICK), this);
		hardMoneyPanel.on(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.emit(CharacterProfileWindow.EVENT_BUTTON_MONEY_PLUS_CLICK), this);

		this.customisationConteiner = new PIXI.Container();
		this.customisationConteiner.position.set(-384, 295);

		this.leftArrow = CharacterProfileWindow.createArrowButton('nude_arrow_left');
		this.leftArrow.on(
			'pointerdown',
			this.onLeftArrowButton,
			this,
		);
		this.leftArrow.position.set(-300, -300);

		this.rightArrow = CharacterProfileWindow.createArrowButton('nude_arrow_right');
		this.rightArrow.on(
			'pointerdown',
			this.onRightArrowButton,
			this,
		);
		this.rightArrow.position.set(300, -300);

		this.updateSeparatorRow();

		this.customisationConteiner.addChild(
			this.leftArrow as PIXI.DisplayObject,
			this.rightArrow,
		);

		this.mainContainer.addChild(
			bg,
			profitBg,
			descText,
			fromLabel,
			braSizeLabel,
			ageLabel,
			hobbyLabel,
			fromValue,
			braSizeValue,
			ageValue,
			hobbyValue,
			promoteParticlesContainer,
			promoteGlow,
			profitDesc,
			buttonPromote,
			cardView as PIXI.DisplayObject,
			this.closeButton,
			hardMoneyPanel,
			prestigeMoneyPanel,
			this.customisationConteiner,
		);

		this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this);
		this.updateButtonPromoteCostValueEnabled();
	}

	public static createArrowButton(sprite: string): ButtonBaseView {
		const arrow = new ButtonBaseView(new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[sprite]));
		arrow.alpha = 0.7;
		arrow.on('pointerover', () => { arrow.alpha = 1; }, this);
		arrow.on('pointerout', () => { arrow.alpha = 0.7; }, this);

		return arrow;
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getPromoteButton(): PIXI.Container {
		return this.buttonPromote;
	}

	public getCloseButtonContainer(): PIXI.Container {
		return this.closeButton;
	}

	public showFlyTextOnPromoteButton(message: string): void {
		if (!this.flyTextLabel) {
			const stringLabel = this.localizationStorage.getLocalizedString(message);
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.buttonPromote.x, this.buttonPromote.y - 90);
			this.mainContainer.addChild(this.flyTextLabel);
		}
	}

	private createSeparatorRow(lenght: number, value: number): void {
		this.content = new PIXI.Container();

		for (let i = 0; i < lenght; i++) {
			this.separator = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['nude_stage']);
			this.separator.x = (i % lenght) * CharacterProfileWindow.SEPARATOR_OFFSET;
			this.separator.interactive = true;
			this.separator.on('pointerdown', this.onSeparatorPresed.bind(this, i));

			this.content.addChild(this.separator);
			this.content.pivot.x = this.content.width / 2 - this.separator.width / 2;

			if (i === value) {
				this.separator.texture = AssetsStorage.getAtlas('collectionsAtlas')['nude_stage_on'];
			} else {
				this.separator.texture = AssetsStorage.getAtlas('collectionsAtlas')['nude_stage'];
			}
		}

		this.card.updateView(true);
		this.customisationConteiner.addChild(this.content);
	}

	private onSeparatorPresed(value: number): void {
		this.nudeLevelId = value;
		this.updatePicture();
		this.updatePicture();
	}

	private onLeftArrowButton(): void {
		if (this.nudeLevelId > 0) {
			this.nudeLevelId -= 1;
			this.updatePicture();
		}
	}

	private onRightArrowButton(): void {
		if (this.nudeLevelId < this.nudeLevelLenght - 1) {
			this.nudeLevelId += 1;
			this.updatePicture();
		}
	}

	private updatePicture(): void {
		this.card.setPicture(this.nudeLevelId + 1);
		this.updateSeparatorRow();
		this.emit(CharacterProfileWindow.EVENT_SELECT_PICTURE, this.model.getKey(), this.nudeLevelId + 1);
	}

	private startPromoteAnimation(): void {
		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
		this.buttonPromote.setCost(this.model.getPromoteCostPrestige().toString());
		this.buttonPromote.setEnabled(false);
		this.buttonPromote.alpha = 0.5;

		this.card.startPromoteAnimation();

		this.promoteAnimation.start();

		SoundController.getInstance().playEntityPromoted();

		if (this.model.getMaxPictureIndex() > this.maxAvailableNudeLevel) {
			this.maxAvailableNudeLevel = this.model.getMaxPictureIndex();
			this.nudeLevelId = this.maxAvailableNudeLevel - 1;
			this.card.playPromoteAnimation();
			this.updatePicture();
		}
		this.updateButtonPromoteCostValueEnabled();
	}

	private onPromoteAnimationCompleted(): void {
		this.buttonPromote.setEnabled(true);
		this.updateButtonPromoteCostValueEnabled();
		this.buttonPromote.alpha = 1;
	}

	private onCardsAdded(): void {
		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
	}

	private onPromoteClicked(): void {
		this.emit(CharacterProfileWindow.EVENT_BUTTON_PROMOTE_CLICK, this.model);
	}

	private updateSeparatorRow(): void {
		if (this.content) {
			this.customisationConteiner.removeChild(this.content);
		}

		this.leftArrow.visible = this.nudeLevelId !== 0;
		this.rightArrow.visible = this.nudeLevelId !== this.nudeLevelLenght - 1;
		if (!this.leftArrow.visible) {
			this.leftArrow.alpha = 0.7;
		}
		if (!this.rightArrow.visible) {
			this.rightArrow.alpha = 0.7;
		}
		this.createSeparatorRow(this.nudeLevelLenght, this.nudeLevelId);
	}

	private updateProfitDesc(): void {
		this.profitDesc.text = TextDescriptionHelperCharacter.getDescriptionProfileWindow(
			this.model,
			this.relatedBusinessKey,
			this.skillModel,
		);
	}

	private updateButtonPromoteCostValueEnabled(): void {
		const isEnoughPrestigeMoneyForPromote = this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige();
		const inEnoughCardsForPromote = this.model.isEnoughCardsForPromote();
		this.buttonPromote.setCostValueEnabled(isEnoughPrestigeMoneyForPromote);
		this.buttonPromote.setButtonBgEnabled(isEnoughPrestigeMoneyForPromote && inEnoughCardsForPromote);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this, false);

		this.model.off(CharacterModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.model.off(CharacterModel.EVENT_PROMOTED, this.startPromoteAnimation, this);

		this.promoteAnimation.destroy();

		super.destroy(options);
	}
}
