import { BankPaymentSaveData } from '@src/types/SaveTypes';

export class BankPaymentSaveModel {
	private totalPayment: number;
	private paymentCount: number;
	private averagePayment: number;
	private lastPaymentTime: number;

	constructor(saveData: BankPaymentSaveData) {
		this.totalPayment = saveData.totalPayment;
		this.averagePayment = saveData.averagePayment;
		this.paymentCount = saveData.paymentCount;
		this.lastPaymentTime = saveData.lastPaymentTime;
	}

	public updatePaymentData(
		price: number,
		timestamp: number,
	): void {
		this.totalPayment += price;
		this.paymentCount += 1;
		this.averagePayment = this.totalPayment / this.paymentCount;
		this.lastPaymentTime = timestamp;
	}

	public hasPayment(): boolean {
		return this.paymentCount > 0;
	}

	public getPaymentCount(): number {
		return this.paymentCount;
	}

	public getTotalPayment(): number {
		return this.totalPayment;
	}

	public getAveragePayment(): number {
		return this.averagePayment;
	}

	public getLastPaymentTime(): number {
		return this.lastPaymentTime;
	}
}
