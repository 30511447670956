import { AbstractPromotePatternsConfig, PromoteDataElement } from '@configs/AbstractPromotePatternsConfig';

export class EventPromotePatternsConfig extends AbstractPromotePatternsConfig {
	public static readonly FIELD_EVENT: string = 'event_id';

	private readonly eventKey: string;

	constructor(config: Record<string, string>[], eventKey: string) {
		super();

		this.eventKey = eventKey;

		this.parseConfig(config);
	}

	protected parseElement(configElement: Record<string, string>): PromoteDataElement {
		const level = Number(configElement[AbstractPromotePatternsConfig.FIELD_LEVEL].trim());
		const rarity = configElement[AbstractPromotePatternsConfig.FIELD_RARITY].trim();
		const promoteCostsCards = configElement[AbstractPromotePatternsConfig.FIELD_PROMOTE_COST_CARDS].trim().split(',');
		const promoteCostsPrestige = configElement[AbstractPromotePatternsConfig.FIELD_PROMOTE_COST_PRESTIGE].trim().split(',');
		const eventsKeys = configElement[EventPromotePatternsConfig.FIELD_EVENT].trim().split(',');
		const eventIndex = eventsKeys.indexOf(this.eventKey);

		return {
			promoteCostCards: Number(promoteCostsCards[eventIndex]),
			promoteCostPrestige: Number(promoteCostsPrestige[eventIndex]),
			rarity,
			level,
		};
	}
}
