import { BankRechargeConfig } from '@configs/bank/BankRechargeConfig';

export class BankRechargeModel extends PIXI.utils.EventEmitter {
	private readonly key: string;
	private readonly background: string;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];
	private readonly viewKey: string;

	private claimed: boolean;
	private claimable: boolean;

	constructor(config: BankRechargeConfig) {
		super();

		this.key = config.getKey();
		this.background = config.getBackground();
		this.viewKey = config.getViewKey();

		if (config.hasSomeUnlockTypeValue()) {
			this.unlockTypes = config.getUnlockTypes();
			this.unlockValues = config.getUnlockValues();
		}
	}

	public setClaimed(value: boolean): void {
		this.claimed = value;
	}

	public setClaimable(value: boolean): void {
		this.claimable = value;
	}

	public isClaimable(): boolean {
		return this.claimable;
	}

	public isClaimed(): boolean {
		return this.claimed;
	}

	public getKey(): string {
		return this.key;
	}

	public getBackground(): string {
		return this.background;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public getViewKey(): string {
		return this.viewKey;
	}
}
