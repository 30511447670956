import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter51007View extends CharacterMainBaseView {
	constructor() {
		super('character51007');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(132, -7);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(152, -81),
			arrowRotation: Math.PI / 180 * 180,
			bgPosition: new PIXI.Point(146, -66),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
