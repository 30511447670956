import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeBaseConfig {
	protected readonly type: TutorialStepUnlockTypes;
	protected readonly key: string;

	constructor(key: string, type: TutorialStepUnlockTypes) {
		this.type = type;
		this.key = key;
	}

	public getType(): TutorialStepUnlockTypes {
		return this.type;
	}

	public getKey(): string {
		return this.key;
	}
}

export class TutorialStepUnlockTypeLevelStartConfig extends TutorialStepUnlockTypeBaseConfig {
	private level: number;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.LEVEL_START);

		this.level = Number(unlockValue.trim());
	}

	public getLevel(): number {
		return this.level;
	}
}

export class TutorialStepUnlockTypeGetQuestConfig extends TutorialStepUnlockTypeBaseConfig {
	private questKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.GET_QUEST);

		this.questKey = unlockValue.trim();
	}

	public getQuestKey(): string {
		return this.questKey;
	}
}

export class TutorialStepUnlockTypeQuestCompleteConfig extends TutorialStepUnlockTypeBaseConfig {
	private questKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.QUEST_COMPLETE);

		this.questKey = unlockValue.trim();
	}

	public getQuestKey(): string {
		return this.questKey;
	}
}

export class TutorialStepUnlockTypeTutorialStepConfig extends TutorialStepUnlockTypeBaseConfig {
	private tutorialStepKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.TUTORIAL_STEP);

		this.tutorialStepKey = unlockValue.trim();
	}

	public getTutorialStepKey(): string {
		return this.tutorialStepKey;
	}
}

export class TutorialStepUnlockTypeIncomeMoneyAppearConfig extends TutorialStepUnlockTypeBaseConfig {
	private businessKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.INCOME_MONEY_APPEAR);

		this.businessKey = unlockValue.trim();
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}
}

export class TutorialStepUnlockTypePrestigeBonusConfig extends TutorialStepUnlockTypeBaseConfig {
	private businessKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.PRESTIGE_BONUS);

		this.businessKey = unlockValue.trim();
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}
}

export class TutorialStepUnlockTypeBusinessAcquiredConfig extends TutorialStepUnlockTypeBaseConfig {
	private businessKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.BUSINESS_ACQUIRED);

		this.businessKey = unlockValue.trim();
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}
}

export class TutorialStepUnlockTypeCustomersOnBusinessConfig extends TutorialStepUnlockTypeBaseConfig {
	private businessKey: string;
	private claimCount: number;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.CUSTOMERS_ON_BUSINESS);

		const unlockValueSplitted: string[] = unlockValue.split(',').map(str => str.trim());
		[this.businessKey, this.claimCount] = [unlockValueSplitted[0], Number(unlockValueSplitted[1])];
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public getClaimCount(): number {
		return this.claimCount;
	}
}

export class TutorialStepUnlockTypeSummonResultCardsAppearConfig extends TutorialStepUnlockTypeBaseConfig {
	private summonKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_APPEARED);

		this.summonKey = unlockValue.trim();
	}

	public getSummonKey(): string {
		return this.summonKey;
	}
}

export class TutorialStepUnlockTypeSummonResultCardsOpenedConfig extends TutorialStepUnlockTypeBaseConfig {
	private summonKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_OPENED);

		this.summonKey = unlockValue.trim();
	}

	public getSummonKey(): string {
		return this.summonKey;
	}
}

export class TutorialStepUnlockTypeEventGoalCompletedConfig extends TutorialStepUnlockTypeBaseConfig {
	private readonly eventKey: string;
	private readonly goalCount: number;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.GOAL_COMPLETED);

		const [eventKey, goalCount] = unlockValue.split(',');

		this.eventKey = eventKey.trim();
		this.goalCount = Number(goalCount);
	}

	public getEventKey(): string {
		return this.eventKey;
	}

	public getGoalCount(): number {
		return this.goalCount;
	}
}

export class TutorialStepUnlockTypeCharacterActivatedConfig extends TutorialStepUnlockTypeBaseConfig {
	private characterKey: string;

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.CHARACTER_ACTIVATED);

		this.characterKey = unlockValue.trim();
	}

	public getCharacterKey(): string {
		return this.characterKey;
	}
}

export class TutorialStepUnlockTypeEventStartConfig extends TutorialStepUnlockTypeBaseConfig {
	private eventKeys: string[];

	constructor(key: string, unlockValue: string) {
		super(key, TutorialStepUnlockTypes.EVENT_START);

		this.eventKeys = unlockValue.split(',').map((key) => key.trim());
	}

	public getEventKeys(): string[] {
		return this.eventKeys;
	}
}
