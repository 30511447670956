import { BusinessBaseViewsSetter } from '@interfaces/ViewSetters';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export abstract class TimedQuestUnlockTypeBaseModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_UNLOCKED: symbol = Symbol();

	protected active: boolean;

	protected onUnlocked(): void {
		this.emit(TimedQuestUnlockTypeBaseModel.EVENT_UNLOCKED, this);
	}

	public activate(): void {
		this.active = true;
		this.onActivate();
	}

	public deactivate(): void {
		this.active = false;
		this.onDeactivate();
	}

	protected abstract onActivate(): void;
	protected abstract onDeactivate(): void;

	public isActive(): boolean {
		return this.active;
	}
}

export class TimedQuestUnlockTypeCollectionWindowClosedModel extends TimedQuestUnlockTypeBaseModel {
	private readonly windowViewSystem: WindowViewSystem;

	private collectionWindow: CollectionWindowView;

	constructor(
		windowViewSystem: WindowViewSystem,
	) {
		super();

		this.windowViewSystem = windowViewSystem;
	}

	protected onActivate(): void {
		this.active = true;
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onCollectionWindowShowed, this);
	}

	protected onDeactivate(): void {
		// eslint-disable-next-line no-unused-expressions
		this.windowViewSystem?.off(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, this.onCollectionWindowShowed, this);
		// eslint-disable-next-line no-unused-expressions
		this.collectionWindow?.off(CollectionWindowView.EVENT_WINDOW_CLOSED, this.onCollectionWindowClosed, this);
	}

	private onCollectionWindowShowed(window: CollectionWindowView): void {
		this.collectionWindow = window;
		this.collectionWindow.once(CollectionWindowView.EVENT_WINDOW_CLOSED, this.onCollectionWindowClosed, this);
	}

	private onCollectionWindowClosed(): void {
		this.onUnlocked();
	}
}

export class TimedQuestUnlockTypeZoomOutBusinessModel extends TimedQuestUnlockTypeBaseModel implements BusinessBaseViewsSetter {
	private views: BusinessBaseView[];

	private pendingActivation: boolean;

	public setBusinessBaseViews(views: BusinessBaseView[]): void {
		this.views = views;
		if (this.pendingActivation) {
			this.onActivate();
		}
	}

	protected onActivate(): void {
		if (this.views) {
			this.pendingActivation = false;
			this.active = true;
			this.views.forEach(view => view.once(BusinessBaseView.EVENT_ZOOM_OUT_COMPLETED, this.onSomeBusinessZoomedOut, this));
		} else {
			this.pendingActivation = true;
		}
	}

	protected onDeactivate(): void {
		// eslint-disable-next-line no-unused-expressions
		this.views?.forEach(view => view.off(BusinessBaseView.EVENT_ZOOM_OUT_COMPLETED, this.onSomeBusinessZoomedOut, this));
	}

	private onSomeBusinessZoomedOut(): void {
		this.onUnlocked();
	}
}
