import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { PrepartyFuckPowerView } from '@views/windows/farewellParty/preparty/PrepartyFuckPowerView';
import { PrepartyFuckTimeView } from '@views/windows/farewellParty/preparty/PrepartyFuckTimeView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class PrepartyTopPanelView extends PIXI.Container {
	public static readonly EVENT_BUTTON_CLOSE_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly tweenGroup: TWEEN.Group;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly fuckPowerView: PrepartyFuckPowerView;
	private readonly fuckTimeView: PrepartyFuckTimeView;

	private tweenMove: TWEEN.Tween;

	constructor(
		previewBgKey: string,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();

		this.createCloseHelp();
		this.createButtonClose();

		const bgMask = new PIXI.Graphics();
		bgMask.beginFill(0xFFFFFF);
		bgMask.drawPolygon([
			new PIXI.Point(-766, -154),
			new PIXI.Point(800, -154),
			new PIXI.Point(766, 154),
			new PIXI.Point(-800, 154),
		]);
		bgMask.y = 123;

		const bg = new PIXI.Sprite(AssetsStorage.getResource(`${previewBgKey}Preview`).texture);
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 123;
		bg.mask = bgMask;

		const bgOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['party_preview_outline'], 22, 0, 22, 0);
		bgOutline.scale.set(2);
		bgOutline.width = 807;
		bgOutline.pivot.set(bgOutline.width / 2, bgOutline.height / 2);
		bgOutline.y = 123;

		const bgOutlineFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 6, 0, 6, 0);
		bgOutlineFade.width = 740;
		bgOutlineFade.height = 74;
		bgOutlineFade.pivot.set(bgOutlineFade.width / 2, bgOutlineFade.height / 2);
		bgOutlineFade.tint = 0x000000;
		bgOutlineFade.position.set(-10, 277);

		this.fuckPowerView = new PrepartyFuckPowerView();
		this.fuckPowerView.position.set(-196, 277);

		this.fuckTimeView = new PrepartyFuckTimeView();
		this.fuckTimeView.position.set(196, 277);

		this.addChild(
			bgOutline as PIXI.DisplayObject,
			bg,
			bgMask,
			bgOutlineFade,
			this.fuckTimeView,
			this.fuckPowerView,
		);

		this.createTitle();
		this.createCenterHelp();
	}

	private createTitle(): void {
		const title = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#preparty_title'),
			1850,
			{ font: '64px wendyOneGold', align: 'center' },
		);
		title.anchor = 0.5;
		title.position.set(0, -82);

		this.addChild(title);
	}

	private createCenterHelp(): void {
		const parentCont = new PIXI.Container();

		const label = new MultiColoredTextField(
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0xf6da66 },
			1500,
		);
		label.anchor = 0.5;
		label.text = this.localizationStorage.getLocalizedString('#preparty_tap_help');

		const bg = new PIXI.Graphics();
		bg.beginFill(0x000000);
		bg.drawPolygon([
			new PIXI.Point(-label.width / 2 - 15, -23),
			new PIXI.Point(label.width / 2 + 20, -23),
			new PIXI.Point(label.width / 2 + 15, 23),
			new PIXI.Point(-label.width / 2 - 20, 23),
		]);
		bg.alpha = 0.77;

		parentCont.addChild(
			bg,
			label as PIXI.DisplayObject,
		);

		parentCont.position.set(-25, -8);

		this.addChild(
			parentCont,
		);
	}

	private createButtonClose(): void {
		const closeButton = new ButtonBaseView(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['back_icon']));
		closeButton.position.set(-928, -83);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onButtonCloseClick, this);

		this.addChild(
			closeButton,
		);
	}

	private onButtonCloseClick(): void {
		this.emit(PrepartyTopPanelView.EVENT_BUTTON_CLOSE_CLICK, this);
	}

	private createCloseHelp(): void {
		const helpText = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#preparty_close_help'),
			400,
			{ font: '25px wendyOneShadowBold', align: 'center', tint: 0x72787b },
		);
		helpText.anchor = new PIXI.Point(0, 0.5);
		helpText.position.set(-892, -84);
		this.addChild(helpText);
	}

	public showAppearingAnimation(): void {
		const duration = 200;

		this.scale.set(1.1);
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, duration)
			.easing(TWEEN.Easing.Sinusoidal.Out)
			.onComplete(() => {
				this.tweenMove = null;
			})
			.start();

		this.showBlinks(200);
	}

	private showBlinks(delay: number): void {
		const deltaX = 500;
		this.createBlink(-800, 279, deltaX, delay);
		this.createBlink(800, -31, -deltaX, delay);
	}

	private createBlink(x: number, y: number, deltaX: number, delay: number): PIXI.Sprite {
		const blink = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['business_big_glow']);
		blink.blendMode = PIXI.BLEND_MODES.ADD;
		blink.anchor.set(0.5);
		blink.position.set(x, y);
		blink.tint = 0xFFCE3D;
		this.addChildAt(blink, 1);

		const duration = 1200;
		blink.alpha = 0;
		new TWEEN.Tween(blink, this.tweenGroup)
			.to({ alpha: [1, 0] }, duration)
			.easing(TWEEN.Easing.Linear.None)
			.delay(delay)
			.start();

		new TWEEN.Tween(blink, this.tweenGroup)
			.to({ x: x + deltaX }, duration)
			.easing(TWEEN.Easing.Sinusoidal.In)
			.delay(delay)
			.start();

		return blink;
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setFuckPower(text: string): void {
		this.fuckPowerView.setText(text);
	}

	public setFuckTime(text: string): void {
		this.fuckTimeView.setText(text);
	}

	public getFuckPowerContainer(): PIXI.Container {
		return this.fuckPowerView;
	}

	public getFuckTimeContainer(): PIXI.Container {
		return this.fuckTimeView;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		super.destroy(options);
	}
}
