import { BankTabElementConfig } from '@configs/bank/BankTabElementConfig';
import { BankTabEightSlotsView } from './BankTabEightSlotsView';
import { BankTabScrollSlots } from './BankTabScrollSlots';
import { BankTabBundleSixSlotsTimer } from './BankTabBundleSixSlotsTimer';
import { BankTabElementConfigNameDataMapType, BankTabElementType } from '@models/bank/BankModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankViewTypes, BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankTabBaseScrollView } from './BankTabBaseScrollView';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';

export type TabElementViewCreatorType = (tabElementConfig: BankTabElementConfig, tabElementDataConfig: BankTabElementType) => PIXI.Container;
export type TabSlotView = {
	view: PIXI.Container;
	index: number;
};

export class BankTabViewFactory {
	private static throwErrorMsgInvalidView(key: string): void {
		// eslint-disable-next-line no-console
		console.error(`Failed creating bank view '${key}'`);
	}

	private readonly tabElementViewCreator: TabElementViewCreatorType;

	private tabElementConfigNameDataMap?: BankTabElementConfigNameDataMapType;

	constructor(
		tabElementViewCreator: TabElementViewCreatorType,
	) {
		this.tabElementViewCreator = tabElementViewCreator;
	}

	public setBankTabElementConfigNameDataMapMap(tabElementConfigNameDataMap: BankTabElementConfigNameDataMapType): void {
		this.tabElementConfigNameDataMap = tabElementConfigNameDataMap;
	}

	public createTabOneItemView(tabElementConfig: BankTabElementConfig): PIXI.Container {
		const dataConfigs = this.tabElementConfigNameDataMap.get(tabElementConfig.getDataConfigName());
		if (dataConfigs && dataConfigs.has(tabElementConfig.getViewKey())) {
			const tabElementData = dataConfigs.get(tabElementConfig.getViewKey());

			const canCreateTab = (tabElementData instanceof BankRechargeModel && !tabElementData.isClaimed())
				|| !(tabElementData instanceof BankRechargeModel);

			if (canCreateTab) {
				const tabView = new PIXI.Container();
				tabView.position.set(1140, 570);

				const tabElementView = this.tabElementViewCreator(tabElementConfig, tabElementData);
				tabView.addChild(tabElementView);

				return tabView;
			}
		}
		return undefined;
	}

	public createTabEightSlotsView(tabElementConfigs: BankTabElementConfig[]): BankTabEightSlotsView | undefined {
		const slotViews: TabSlotView[] = this.createViews(tabElementConfigs);
		let tabView: BankTabEightSlotsView | undefined;
		if (slotViews.length > 0) {
			tabView = new BankTabEightSlotsView();
			tabView.position.set(610, 350);
			tabView.init(slotViews);
		}
		return tabView;
	}

	public createTabHeaderSlotsView(tabElementConfigs: BankTabElementConfig[]): PIXI.Container | undefined {
		const slotViews: TabSlotView[] = [];
		const headerDataConfigs = this.tabElementConfigNameDataMap.get(tabElementConfigs[0].getDataConfigName());

		let tabView: BankTabBaseScrollView | undefined;
		let headerView: PIXI.Container;
		let isHeaderAvailable: boolean = false;
		if (headerDataConfigs && headerDataConfigs.has(tabElementConfigs[0].getViewKey())) {
			const headerViewConfig = headerDataConfigs.get(tabElementConfigs[0].getViewKey()) as BankBundleModel;
			isHeaderAvailable = headerViewConfig.getAvailableOn() <= Math.floor(Date.now() / 1000);
			headerView = this.tabElementViewCreator(tabElementConfigs[0], headerViewConfig);
			slotViews.push({ view: headerView, index: -1 });
		} else if (MODE_DEBUG) {
			BankTabViewFactory.throwErrorMsgInvalidView(tabElementConfigs[0].getViewKey());
		}

		slotViews.push(...this.createTimedViews(tabElementConfigs, 1));

		if (slotViews.length > 0 || isHeaderAvailable) {
			tabView = new BankTabBaseScrollView(175, 315, { begin: 50, end: 50 });
			tabView.position.set(380, 80);

			tabView.init(
				slotViews,
			);
		}
		return tabView;
	}

	public createTabDailySlotsView(tabElementConfigs: BankTabElementConfig[]): PIXI.Container | undefined {
		let tabView: BankTabBaseScrollView | undefined;

		const slotViews = this.createViews(tabElementConfigs, 0, true);

		if (slotViews.length > 0) {
			tabView = new BankTabBaseScrollView(150, 285, { begin: 30, end: 0 });
			tabView.position.set(380, 60);

			tabView.init(
				slotViews,
			);
		}
		return tabView;
	}

	public createTabScrollSlots(tabElementConfigs: BankTabElementConfig[]): PIXI.Container | undefined {
		const slotViews: TabSlotView[] = this.createViews(tabElementConfigs);
		let tabView: BankTabScrollSlots | undefined;

		if (slotViews.length > 0) {
			tabView = new BankTabScrollSlots();
			tabView.position.set(360, 100);
			tabView.init(slotViews);
		}
		return tabView;
	}

	public createTabBundleSixSlotsTimer(tabElementConfigs: BankTabElementConfig[]): PIXI.Container | undefined {
		// Create six slots
		const slotViews: TabSlotView[] = this.createViews(tabElementConfigs, 2);

		const tabView = new BankTabBundleSixSlotsTimer();
		tabView.position.set(640, 580);

		// Create timer
		let timerView = new PIXI.Container();
		if (tabElementConfigs.length > 0) {
			const tabElementTimerConfig = tabElementConfigs[0];
			const dataConfigTimer = this.tabElementConfigNameDataMap.get(tabElementTimerConfig.getDataConfigName());
			if (dataConfigTimer && dataConfigTimer.has(tabElementTimerConfig.getViewKey())) {
				const tabElementDataConfig = dataConfigTimer.get(tabElementTimerConfig.getViewKey());
				timerView = this.tabElementViewCreator(tabElementTimerConfig, tabElementDataConfig);
			} else {
				timerView = new PIXI.Container();
				if (MODE_DEBUG) {
					BankTabViewFactory.throwErrorMsgInvalidView(tabElementConfigs[0].getViewKey());
				}
			}
		}

		// Create bundle
		let bundleView: PIXI.Container;
		if (tabElementConfigs.length > 1) {
			const tabElementBundleConfig = tabElementConfigs[1];
			const dataConfigsBundle = this.tabElementConfigNameDataMap.get(tabElementBundleConfig.getDataConfigName());
			if (dataConfigsBundle && dataConfigsBundle.has(tabElementBundleConfig.getViewKey())) {
				const tabElementDataConfig = dataConfigsBundle.get(tabElementBundleConfig.getViewKey());
				bundleView = this.tabElementViewCreator(tabElementBundleConfig, tabElementDataConfig);
			} else {
				bundleView = new PIXI.Container();
				if (MODE_DEBUG) {
					BankTabViewFactory.throwErrorMsgInvalidView(tabElementConfigs[1].getViewKey());
				}
			}
		}

		tabView.init(bundleView, timerView, slotViews);

		return tabView;
	}

	public createTabDoubleOffers(tabElementConfigs: BankTabElementConfig[]): PIXI.Container | undefined {
		if (tabElementConfigs[0].getViewType() === BankViewTypes.COMPARE_LEFT) {
			tabElementConfigs.reverse();
		}

		const slotViews: TabSlotView[] = this.createViews(tabElementConfigs);
		let tabView: PIXI.Container | undefined;

		if (slotViews.length === 2) {
			tabView = new PIXI.Container();
			tabView.position.set(1150, 565);
			slotViews.forEach(x => tabView.addChild(x.view));
		}
		return tabView;
	}

	public createTabTimedBundles(tabElementConfig: BankTabElementConfig[]): PIXI.Container | undefined {
		const views = this.createTimedViews(tabElementConfig);
		let tabView: BankTabBaseScrollView;

		if (views.length > 0) {
			tabView = new BankTabBaseScrollView(175, 300, { begin: 50, end: 0 });
			tabView.position.set(380, 80);

			tabView.init(views);
		}

		return tabView;
	}

	private createTimedViews(tabElementConfigs: BankTabElementConfig[], start: number = 0, allowSoldOut: boolean = false): TabSlotView[] {
		const offers = this.tabElementConfigNameDataMap.get(BankTabElementDataConfigNameTypes.OFFERS) as Map<string, BankOfferModel>;
		const bundlesAndTablElements: Map<string, [BankBundleModel, BankTabElementConfig]> = new Map();
		for (let i: number = start; i < tabElementConfigs.length; i++) {
			const tabElement = tabElementConfigs[i];
			const dataConfigs = this.tabElementConfigNameDataMap.get(tabElement.getDataConfigName()) as Map<string, BankBundleModel>;
			const values: BankBundleModel[] = Array.from(dataConfigs.values());

			values.forEach((x: BankBundleModel) => {
				const regex: RegExp = new RegExp(tabElement.getViewKey());
				if (regex.test(x.getViewKey()) && (x.getRemainingCount() > 0 || allowSoldOut)) {
					bundlesAndTablElements.set(x.getViewKey(), [x, tabElement]);
				}
			});
		}

		const sortedData = Array.from(bundlesAndTablElements.values()).sort(([a], [b]) => {
			let result: number;
			if (a.hasOfferKey() && b.hasOfferKey()) {
				result = offers.get(b.getOfferKey()).getPriority() - offers.get(a.getOfferKey()).getPriority();
			} else if (a.hasOfferKey() && !b.hasOfferKey()) {
				result = -1;
			} else if (!a.hasOfferKey() && b.hasOfferKey()) {
				result = 1;
			} else {
				result = b.getPriority() - a.getPriority();
			}
			return result;
		});

		return sortedData.map((x: [BankBundleModel, BankTabElementConfig], index: number) => ({
			view: this.tabElementViewCreator(x[1], x[0]),
			index: index + start,
		}));
	}

	private createViews(tabElementConfigs: BankTabElementConfig[], start: number = 0, allowSoldOut: boolean = false): TabSlotView[] {
		const views: TabSlotView[] = [];
		let tabElementConfig: BankTabElementConfig;
		let index: number;
		let souldOutCounter: number = 0;
		for (let i: number = start; i < tabElementConfigs.length; i++) {
			tabElementConfig = tabElementConfigs[i];

			let tabElementView: PIXI.Container;
			const dataConfigs = this.tabElementConfigNameDataMap.get(tabElementConfig.getDataConfigName());
			const tabElementDataConfig = dataConfigs.get(tabElementConfig.getViewKey());

			if (tabElementDataConfig instanceof BankBundleModel && (tabElementDataConfig.getRemainingCount() > 0 || allowSoldOut)) {
				tabElementView = this.tabElementViewCreator(tabElementConfig, tabElementDataConfig);
				if (tabElementDataConfig.getRemainingCount() > 0) {
					index = i - start - souldOutCounter;
				} else {
					index = tabElementConfigs.length - souldOutCounter - 1;
					souldOutCounter += 1;
				}
			} else if (tabElementDataConfig !== undefined && !(tabElementDataConfig instanceof BankBundleModel)) {
				tabElementView = this.tabElementViewCreator(tabElementConfig, tabElementDataConfig);
				index = i - start - souldOutCounter;
			} else if (MODE_DEBUG) {
				BankTabViewFactory.throwErrorMsgInvalidView(tabElementConfig.getViewKey());
			}
			if (tabElementView) {
				views.push({ view: tabElementView, index });
			}
		}
		return views;
	}
}
