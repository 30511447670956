import { NetworkActionQueue } from './NetworkActionQueue';
import { BaseAction } from '@models/network/actions/BaseAction';
import { NetworkRequestHeaders } from './NetworkRequestHeaders';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { ServerErrorCode } from '@src/types/NetworkTypes';
import { NetworkUtils } from '@src/utils/NetworkUtils';

export class NetworkRequestTransport extends PIXI.utils.EventEmitter {
	public static readonly EVENT_REQUEST_SENT: symbol = Symbol();
	public static readonly EVENT_RESPONSE_ERROR: symbol = Symbol();
	private static readonly HEADER_KEY_SERVER_TIME = 'X-ServerTime';

	private readonly actionQueue: NetworkActionQueue;
	private readonly requestHeaders: NetworkRequestHeaders;

	private requestChain: Promise<any> = Promise.resolve();

	private updateRevision: number;
	private eventLevelId?: string;

	constructor(
		private readonly serverUrl: string,
		private readonly assetsServiceUrl: string,
		private readonly serverState: string,
		private readonly serverTimeModel: ServerTimeModel,
		isNutaku: boolean,
		version: string,
	) {
		super();

		this.requestHeaders = new NetworkRequestHeaders(isNutaku, version);

		this.actionQueue = new NetworkActionQueue();
		this.actionQueue.on(NetworkActionQueue.EVENT_ACTIONS_EMITTED, this.onActionsEmitted, this);
	}

	public setActionsEmitterTimeoutTime(value: number): void {
		this.actionQueue.setEmitterTimeoutTime(value);
	}

	public startActionsTicker(): void {
		this.actionQueue.startTicker();
	}

	public addAction(
		action: BaseAction,
		withEventLevelId?: boolean,
	): void {
		action.setTimestamp(this.serverTimeModel.getCalculatedISOTime());
		if (withEventLevelId) {
			action.setEventLevelId(this.eventLevelId);
		}

		this.actionQueue.addAction(action);
	}

	public setEventLevelId(eventLevelId: string | undefined): NetworkRequestTransport {
		this.eventLevelId = eventLevelId;
		this.requestHeaders.setEventLevelId(eventLevelId);
		return this;
	}

	public setActionsEmitterEnabled(value: boolean): NetworkRequestTransport {
		this.actionQueue.setEmitterEnabled(value);
		return this;
	}

	public setUpdatesRevision(value: number): NetworkRequestTransport {
		this.updateRevision = value;
		return this;
	}

	public setNutakuId(value: string): this {
		this.requestHeaders.setNutakuId(value);
		return this;
	}

	public setPlayerId(value: string): NetworkRequestTransport {
		this.requestHeaders.setPlayerId(value);
		return this;
	}

	public setSessionId(value: string): NetworkRequestTransport {
		this.requestHeaders.setSessionId(value);
		return this;
	}

	public resetPlayerId(): void {
		this.requestHeaders.resetPlayerId();
	}

	public hasPlayerId(): boolean {
		return this.requestHeaders.hasPlayerId();
	}

	public getPlayerId(): string {
		return this.requestHeaders.getPlayerId();
	}

	public getSessionId(): string {
		return this.requestHeaders.getSessionId();
	}

	public isActionsEmitterEnabled(): boolean {
		return this.actionQueue.isEmitterEnabled();
	}

	public getServerUrl(): string {
		return this.serverUrl;
	}

	public getAssetsServiceUrl(): string {
		return this.assetsServiceUrl;
	}

	public getServerState(): string {
		return this.serverState;
	}

	public async sendRequest(
		url: string,
		data: { [key: string]: any } = {},
		useSessionId: boolean = true,
		sendActions: boolean = true,
	): Promise<any> {
		const headers = this.requestHeaders.getHeaders(useSessionId);

		if (sendActions && this.actionQueue.hasActions()) {
			this.sendActions(headers);
		}

		this.requestChain = this.requestChain.then(() => this.createRequest(
			url,
			data,
			headers,
		));

		const result = await this.requestChain;
		return result;
	}

	public async createAssetsRequest(
		url: string,
		data: Record<string, unknown>,
	): Promise<Record<string, any>> {
		const result: Record<string, any> = { error: false, data: undefined };

		try {
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
			});

			if (response.status !== 200) {
				result.error = true;
			} else {
				try {
					const responseData = await response.json();
					const hasError = Object.keys(responseData.error).length > 0;

					if (hasError) {
						this.onSomeResponseError(response.url, response.statusText);
						return responseData;
					}

					result.data = responseData.result;
				} catch (error) {
					if (response.status === ServerErrorCode.MAINTENANCE
						|| response.status === ServerErrorCode.OUTDATED_VERSION) {
						this.onSomeResponseError(response.url, response.statusText, response.status);
					}
				}
			}
		} catch (error) {
			result.error = true;
		}

		return result;
	}

	private async createRequest(
		url: string,
		data: { [key: string]: any } = {},
		headers: Record<string, string>,
	): Promise<any> {
		let result: any;

		try {
			const response = await fetch(this.serverUrl + url, {
				method: 'POST',
				body: JSON.stringify(data),
				headers,
			});

			this.emit(NetworkRequestTransport.EVENT_REQUEST_SENT, this);

			try {
				const responseData = await response.json();
				const responseError = responseData.error && typeof responseData.error === 'number';
				const errorCode = responseError ? Number(responseData.error) : undefined;

				if (!response.ok) {
					this.onSomeResponseError(response.url, response.statusText, errorCode);
					return responseData;
				}

				if (responseError) {
					switch (responseData.error) {
						case ServerErrorCode.ACCOUNT_EMAIL_LOGIN_NOT_FOUND:
						case ServerErrorCode.ACCOUNT_WRONG_PASSWORD:
						case ServerErrorCode.ACCOUNT_EMAIL_NOT_CONFIRMED:
						case ServerErrorCode.ACCOUNT_WRONG_CONFIRMATION_CODE:
						case ServerErrorCode.ACCOUNT_EMAIL_ALREADY_EXISTS:
							if (response.headers.has(NetworkRequestTransport.HEADER_KEY_SERVER_TIME)) {
								const serverTime = parseInt(response.headers.get(NetworkRequestTransport.HEADER_KEY_SERVER_TIME), 10);
								this.serverTimeModel.setServerTime(serverTime);
							}
							result = responseData;
							break;
						default:
							this.onSomeResponseError(response.url, responseData.error, errorCode);
							return responseData;
					}
				} else {
					result = responseData.result;
				}

				if (response.headers.has(NetworkRequestTransport.HEADER_KEY_SERVER_TIME)) {
					const serverTime = parseInt(response.headers.get(NetworkRequestTransport.HEADER_KEY_SERVER_TIME), 10);
					this.serverTimeModel.setServerTime(serverTime);
				}
			} catch (error) {
				if (response.status === ServerErrorCode.MAINTENANCE) {
					this.onSomeResponseError(response.url, response.statusText, response.status);
				}
			}
		} catch (error) {
			this.onSomeResponseError(url, error);
		}

		return result;
	}

	private async sendActions(headers: Record<string, string>): Promise<void> {
		const actions = this.actionQueue.getActions();
		const actionsFormatted = NetworkUtils.formatActions(actions);

		this.actionQueue.clearActions();

		this.requestChain = this.requestChain
			.then(() => ({
				update: actionsFormatted,
				revision: this.updateRevision + 1,
			}))
			.then(data => this.createRequest('/gs_api/profiles/update', data, headers))
			.then(result => {
				if (result?.error) {
					throw new Error(`Update failed: '${result.error}'`);
				} else {
					this.updateRevision = result['revision'];
				}
			});
		await this.requestChain;
	}

	private async onActionsEmitted(): Promise<void> {
		await this.sendActions(this.requestHeaders.getHeaders(true));
		this.actionQueue.startTicker();
	}

	private onSomeResponseError(url: string, error: string, errorCode?: number): void {
		this.emit(NetworkRequestTransport.EVENT_RESPONSE_ERROR, url, error, errorCode);
	}
}
