import { BankPriceResourceTypes, BankBundleConfigRaw } from '@src/types/BankTypes';
import { BankElementViewDataConfig } from './BankElementViewDataConfig';
import { RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export type BundleViewParameters = {
	bg: string;
	girls: string[];
}


type BundleConfigKey = keyof BankBundleConfigRaw;
export class BankBundleConfig
	extends BankElementViewDataConfig implements ConfigSetElement {
	private static readonly DEFAULT_REFRESH_PERIOD: string = '@daily';
	private static readonly PRICE_FREE: string = 'free';

	public static readonly FIELD_KEY: BundleConfigKey = 'id';
	public static readonly FIELD_NAME_LOCALE: BundleConfigKey = 'name';
	public static readonly FIELD_MOST_POPULAR: BundleConfigKey = 'most_popular';
	public static readonly FIELD_STICKER: BundleConfigKey = 'sticker';
	public static readonly FIELD_PRICE: BundleConfigKey = 'price';
	public static readonly FIELD_OLD_PRICE: BundleConfigKey = 'prev_price';
	public static readonly FIELD_PRICE_RESOURCE: BundleConfigKey = 'price_resource';
	public static readonly FIELD_OLD_PRICE_RESOURCE: BundleConfigKey = 'prev_price_resource';
	public static readonly FIELD_TITLE: BundleConfigKey = 'bundle_title';
	public static readonly FIELD_DESCRIPTION: BundleConfigKey = 'bundle_desc';
	public static readonly FIELD_REWARD_TYPE: BundleConfigKey = 'reward_type';
	public static readonly FIELD_REWARD_ID: BundleConfigKey = 'reward_id';
	public static readonly FIELD_REWARD_COUNT: BundleConfigKey = 'reward_count';
	public static readonly FIELD_VIEW_PARAMETERS: BundleConfigKey = 'view_parameters';
	public static readonly FIELD_OFFER_ID: BundleConfigKey = 'offer_id';
	public static readonly FIELD_BUNLE_GROUP: BundleConfigKey = 'bundle_group';
	public static readonly FIELD_PRIORITY_VIEW: BundleConfigKey = 'priority_view';
	public static readonly FIELD_BUY_TIMES: BundleConfigKey = 'buy_times';
	public static readonly FIELD_UNLOCK_VALUE: BundleConfigKey = 'unlock_value';
	public static readonly FIELD_UNLOCK_TYPE: BundleConfigKey = 'unlock_type';
	public static readonly FIELD_REFRESH_PERIOD: BundleConfigKey = 'refresh_period';

	private readonly key: string;
	private readonly nameLocale: string;
	private readonly mostPopular: string;
	private readonly stickerLocaleKey?: string;
	private readonly price: number;
	private readonly oldPrice: string;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly oldPriceResource: BankPriceResourceTypes;
	private readonly extraRewardType: RewardTypes;
	private readonly extraRewardId: RewardResourceIdTypes | string;
	private readonly extraRewardCount: number;
	private readonly titleLocaleKey: string;
	private readonly descriptionLocaleKey: string;
	private readonly viewParameters: BundleViewParameters;
	private readonly offerKey: string;
	private readonly bundleGroupKey: string;
	private readonly characterLeftKey: string;
	private readonly characterRightKey: string;
	private readonly bgKey: string;
	private readonly priority: number;
	private readonly totalBuyTimes: number;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];
	private readonly refreshPeriod: string;

	constructor(config: BankBundleConfigRaw | { [key: string]: string }) {
		super(config[BankBundleConfig.FIELD_VIEW_KEY as BundleConfigKey].trim());

		this.key = config[BankBundleConfig.FIELD_KEY].trim();

		this.nameLocale = config[BankBundleConfig.FIELD_NAME_LOCALE].trim();

		this.mostPopular = config[BankBundleConfig.FIELD_MOST_POPULAR].trim();

		const stickerLocaleKeyFormatted = config[BankBundleConfig.FIELD_STICKER].trim() as string;
		if (stickerLocaleKeyFormatted && stickerLocaleKeyFormatted.length > 0) {
			this.stickerLocaleKey = stickerLocaleKeyFormatted;
		}

		const priceUnformatted = config[BankBundleConfig.FIELD_PRICE].trim();
		if (priceUnformatted !== BankBundleConfig.PRICE_FREE) {
			this.price = Number(priceUnformatted);
		}

		this.oldPrice = config[BankBundleConfig.FIELD_OLD_PRICE]?.trim();

		this.priceResource = config[BankBundleConfig.FIELD_PRICE_RESOURCE].trim() as BankPriceResourceTypes;
		this.oldPriceResource = config[BankBundleConfig.FIELD_OLD_PRICE_RESOURCE]?.trim() as BankPriceResourceTypes;

		this.titleLocaleKey = config[BankBundleConfig.FIELD_TITLE].trim();
		this.descriptionLocaleKey = config[BankBundleConfig.FIELD_DESCRIPTION].trim();

		this.extraRewardType = config[BankBundleConfig.FIELD_REWARD_TYPE].trim() as RewardTypes;
		const rewardIdRawValue = config[BankBundleConfig.FIELD_REWARD_ID].trim();
		this.extraRewardId = this.extraRewardType === RewardTypes.RESOURCE
			? rewardIdRawValue as RewardResourceIdTypes
			: rewardIdRawValue;
		this.extraRewardCount = Number(config[BankBundleConfig.FIELD_REWARD_COUNT].trim());

		const viewParametersRaw = (config[BankBundleConfig.FIELD_VIEW_PARAMETERS] as string);
		// eslint-disable-next-line prefer-destructuring
		this.bgKey = viewParametersRaw.split(';')[0];
		const viewParameterColonIndex = viewParametersRaw.indexOf(';') + 1;
		const charactersRaw = viewParametersRaw
			.substr(viewParameterColonIndex)
			.split(',')
			.map(str => str.trim());
		[this.characterLeftKey, this.characterRightKey] = [charactersRaw[0], charactersRaw[1]];

		if (config[BankBundleConfig.FIELD_OFFER_ID]) {
			this.offerKey = config[BankBundleConfig.FIELD_OFFER_ID].trim();
		}

		if (config[BankBundleConfig.FIELD_BUNLE_GROUP]) {
			this.bundleGroupKey = config[BankBundleConfig.FIELD_BUNLE_GROUP].trim();
		}

		if (config[BankBundleConfig.FIELD_PRIORITY_VIEW]) {
			this.priority = parseFloat(config[BankBundleConfig.FIELD_PRIORITY_VIEW].trim());
		}

		this.totalBuyTimes = parseFloat(config[BankBundleConfig.FIELD_BUY_TIMES].trim());

		if (config[BankBundleConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[BankBundleConfig.FIELD_UNLOCK_TYPE] as string;
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[BankBundleConfig.FIELD_UNLOCK_VALUE] as string;
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}

		if (config[BankBundleConfig.FIELD_REFRESH_PERIOD]) {
			this.refreshPeriod = config[BankBundleConfig.FIELD_REFRESH_PERIOD];
		} else {
			this.refreshPeriod = BankBundleConfig.DEFAULT_REFRESH_PERIOD;
		}
	}

	public getRefreshPeriod(): string {
		return this.refreshPeriod;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public hasPriority(): boolean {
		return Boolean(this.priority);
	}

	public getTotalBuyTimes(): number {
		return this.totalBuyTimes;
	}

	public getPriority(): number {
		return this.priority;
	}

	public getBgKey(): string {
		return this.bgKey;
	}

	public hasCharacterRightKey(): boolean {
		return Boolean(this.characterRightKey);
	}

	public getCharacterLeftKey(): string {
		return this.characterLeftKey;
	}

	public getCharacterRightKey(): string {
		return this.characterRightKey;
	}

	public getKey(): string {
		return this.key;
	}

	public getNameLocale(): string {
		return this.nameLocale;
	}

	public getMostPopular(): string {
		return this.mostPopular;
	}

	public hasSticker(): boolean {
		return Boolean(this.stickerLocaleKey);
	}

	public getStickerLocaleKey(): string {
		return this.stickerLocaleKey;
	}

	public getPrice(): number | undefined {
		return this.price;
	}

	public isFreePrice(): boolean {
		return !this.price;
	}

	public getOldPrice(): string {
		return this.oldPrice;
	}

	public hasOldPrice(): boolean {
		return Boolean(this.oldPrice);
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getOldPriceResource(): BankPriceResourceTypes {
		return this.oldPriceResource;
	}

	public getTitleLocaleKey(): string {
		return this.titleLocaleKey;
	}

	public getDescriptionLocaleKey(): string {
		return this.descriptionLocaleKey;
	}

	public getExtraRewardType(): RewardTypes {
		return this.extraRewardType;
	}

	public getExtraRewardId(): RewardResourceIdTypes | string {
		return this.extraRewardId;
	}

	public getExtraRewardCount(): number {
		return this.extraRewardCount;
	}

	public getViewParameters(): BundleViewParameters {
		return this.viewParameters;
	}

	public hasOfferKey(): boolean {
		return Boolean(this.offerKey);
	}

	public getOfferKey(): string {
		return this.offerKey;
	}

	public hasBundleGroupKey(): boolean {
		return Boolean(this.bundleGroupKey);
	}

	public getBundleGroupKey(): string {
		return this.bundleGroupKey;
	}
}
