import { GameUIBaseView } from '../../views/ui/main/GameUIBaseView';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '../../views/windows/WindowViewSystem';
import { BankTabTypes, BankOfferWindowOriginTypes } from '@src/types/BankTypes';
import { CollectionWindowTabs } from '@views/windows/collection/CollectionWindowView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BoostModel } from '@models/BoostModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { TutorialStepLevelModel } from '@models/tutorialSteps/TutorialStepLevelModel';
import { MAIN_WINDOW_TARGET_TYPES } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepWindowViewTargetBaseDispatcher } from '../tutorialSteps/TutorialStepWindowViewTargetBaseDispatcher';

export class GameUIBaseViewController<
	GameUIViewType extends GameUIBaseView,
	WindowFactoryType extends WindowViewBaseFactory> extends PIXI.utils.EventEmitter {
	protected view: GameUIViewType;

	constructor(
		protected readonly windowViewSystem: WindowViewSystem,
		protected readonly windowFactory: WindowFactoryType,
		protected readonly boostModels: Map<string, BoostModel>,
		protected readonly farewellBoostModel: FarewellBoostModel,
		protected readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetBaseDispatcher,
	) {
		super();
	}

	public setGameUIView(view: GameUIViewType): void {
		this.view = view;
		this.view.on(GameUIBaseView.EVENT_BUTTON_SETTINGS_CLICK, this.onButtonSettingsClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_COLLECTION_CLICK, this.onButtonCollectionClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_BANK_CLICK, this.onButtonBankClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_CHEAT_CLICK, this.onButtonCheatClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_QUEST_LINE_CLICK, this.onButtonQuestLineClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_BANK_OFFER_LINE_CLICK, this.onButtonBankOfferLineClick, this);
		this.view.on(GameUIBaseView.EVENT_BUTTON_BOOST_CLICK, this.onButtonBoostClick, this);
	}

	protected onBaseTutorialStepEmitted(model: TutorialStepLevelModel): void {
		let target: PIXI.Container;
		try {
			target = this.tutorialStepsViewTargetDispatcher.dispatchViewTarget(model);
			const tutorialStepWindow = this.windowFactory.createTutorialStepWindow(
				model,
				target,
			);

			const targetType = model.getTargetType();

			if (MAIN_WINDOW_TARGET_TYPES.includes(targetType)) {
				this.windowViewSystem.addWindowPending(tutorialStepWindow);
			} else {
				this.windowViewSystem.showWindow(tutorialStepWindow);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(`Tutorial step ${model.getKey()} failed: ${e.message}`);
		}
	}

	private onButtonSettingsClick(): void {
		const window = this.windowFactory.createSettingsWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonMoneyPlusClick(): void {
		const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(window);
	}

	private onButtonCollectionClick(): void {
		const window = this.windowFactory.createCollectionWindow(
			{ tab: CollectionWindowTabs.TAB_SUMMON },
		);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonBankClick(): void {
		const window = this.windowFactory.createBankWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonCheatClick(): void {
		const window = this.windowFactory.createCheatWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonQuestLineClick(quest: AbstractQuest): void {
		let window: WindowBaseView;
		if (quest.hasTime()) {
			window = this.windowFactory.createTimedQuestWindow(quest);
		} else {
			window = this.windowFactory.createQuestWindow(quest);
		}
		this.windowViewSystem.showWindow(window);
	}

	private onButtonBankOfferLineClick(model: BankOfferModel): void {
		const window = this.windowFactory.createBankOfferWindow(
			model.getKey(),
			BankOfferWindowOriginTypes.BANK_OFFER_LINES_UI,
		);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonBoostClick(): void {
		if (this.view.isBankButtonVisible()) {
			const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.BOOSTERS });
			this.windowViewSystem.showWindow(window);
		} else if (!(this.farewellBoostModel.isActivated() || Array.from(this.boostModels.values()).some(x => x.isActivated()))) {
			this.view.showBoostLockedFlyText();
		}
	}
}
