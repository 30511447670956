import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { GameConstants } from '@src/utils/GameConstants';

export type DefaultHintViewData = {
	title: string;
	description: string;
	bodyArrowOffset: number;
}

export class HintDefaultView extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;
	private readonly arrowUp: PIXI.Sprite;
	private readonly arrowDown: PIXI.Sprite;

	private textTitle: MultiColoredTextField;
	private textDesc: MultiColoredTextField;

	private bg: PIXI.mesh.NineSlicePlane;
	private hintfade: PIXI.mesh.NineSlicePlane;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.arrowUp = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_hint_tail_up']);
		this.arrowDown = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_hint_tail_down']);
		this.arrowDown.y = 223;

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		this.bg.width = 530;
		this.bg.height = 212;
		this.bg.y = 111;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);


		this.hintfade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 0, 0, 0, 0);
		this.hintfade.width = this.bg.width + 120;
		this.hintfade.height = this.bg.height + 70;
		this.hintfade.tint = 0x000000;
		this.hintfade.y = this.bg.y;
		this.hintfade.pivot.set(this.hintfade.width / 2, this.hintfade.height / 2);

		this.textTitle = new MultiColoredTextField({ font: '36px wendyOneShadowBold' }, 340, 45);
		this.textTitle.anchor = 0.5;
		this.textTitle.position.set(this.bg.width / 2, 43);

		this.textDesc = new MultiColoredTextField({ font: '24px wendyOneShadowBold', align: 'center' }, 470, 94);
		this.textDesc.anchor = 0.5;
		this.textDesc.position.set(this.bg.width / 2, 142);

		const fade = new PIXI.Graphics();
		fade.beginFill(0x15151d);
		fade.drawRect(0, 0, 516, 120);
		fade.endFill();
		fade.pivot.set(fade.width / 2, fade.height / 2);
		fade.position.set(this.bg.width / 2, 144);

		this.bg.addChild(
			this.textTitle,
			fade as PIXI.DisplayObject,
			this.textDesc,
		);

		this.addChild(
			this.hintfade,
			this.bg,
			this.arrowUp as PIXI.DisplayObject,
			this.arrowDown,
		);
	}

	public init(data: DefaultHintViewData): void {
		this.textTitle.text = this.localizationStorage.getLocalizedString(data.title);
		this.textDesc.text = this.localizationStorage.getLocalizedString(data.description);

		this.setBgArrowOffset(data.bodyArrowOffset);
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		const scaleStartY = this.y - 40;

		let scaleToY: string;
		if (this.getGlobalPosition().y > GameConstants.GAME_HEIGHT / 2) {
			scaleToY = '-185';
			this.arrowUp.visible = false;
			this.arrowDown.visible = true;
		} else {
			scaleToY = '+40';
			this.arrowUp.visible = true;
			this.arrowDown.visible = false;
		}

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y = scaleStartY;
		new TWEEN.Tween(this)
			.to({ y: scaleToY }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}

	/**
	 * 0 offsets the bg to the right, 1 to the left
	 * @param value - number from 0 to 1
	 */
	private setBgArrowOffset(value: number): void {
		this.bg.x = this.bg.width / 2 - value * this.bg.width;
		this.hintfade.x = this.bg.x;
	}
}
