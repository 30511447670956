import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70004View extends CharacterMainBaseView {
	constructor() {
		super('character70004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-204, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-30, -18),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-10, -21),
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
