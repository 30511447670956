import { BaseIncomeMoneyAnimationView, IncomeMoneySprite } from '@views/ui/incomeMoney/BaseIncomeMoneyAnimationView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SoundController } from '@src/main/SoundController';

export class HardMoneyIncomeAnimationView extends BaseIncomeMoneyAnimationView {
	// eslint-disable-next-line class-methods-use-this
	protected createSprite(): IncomeMoneySprite {
		const sprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_hard_money']);
		sprite.scale.set(0.8);
		return sprite;
	}

	public playAnimation(startPosLocal: PIXI.Point): void {
		super.playAnimation(startPosLocal);
		SoundController.getInstance().playGemsFly();
	}
}
