import { GameProfileModel } from '@models/GameProfileModel';

export class AnalyticSourceGameProfileModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_GAME_PROFILE_CONFIRMATION: symbol = Symbol();

	constructor(gameProfileModel: GameProfileModel) {
		super();

		gameProfileModel.on(GameProfileModel.EVENT_CONFIRMATION, () => {
			this.emit(AnalyticSourceGameProfileModel.EVENT_GAME_PROFILE_CONFIRMATION);
		}, this);
	}
}
