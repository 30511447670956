import LocalizationStorage from '@main/LocalizationStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { SoundController } from '@src/main/SoundController';
import { PreloaderBarView } from './PreloaderBarView';
import { PreloaderHintView } from './PreloaderHintView';
import { PreloaderLinksView } from './PreloaderLinksView';
import { ButtonCheatView } from '@views/windows/cheatWindow/ButtonCheatView';
import * as TWEEN from '@tweenjs/tween.js';
import { DualStateButtonBaseView } from '@views/components/buttons/DualStateButtonBaseView';
import { SettingsSaveData } from '@src/types/SaveTypes';
import { ButtonMuteView } from './ButtonMuteView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { LoginWindowView } from '@views/windows/account/LoginWindowView';
import { GameProfileModel } from '@models/GameProfileModel';
import { LevelAssetNames } from '@src/types/LevelAssetNames';
import { AssetLoaderUtils } from '@src/utils/AssetLoaderUtils';
import { FakeProgressConstants } from '@configs/ConstantsConfig';
import { FakeLoaderProgress } from '@src/utils/FakeLoaderProgress';
import { BaseLinksView } from './BaseLinksView';
import { AssetLoader } from '@src/utils/AssetLoader';

export class LevelPreloaderView extends PIXI.Container {
	public static readonly EVENT_WINDOW_LOGIN_SHOWED: symbol = Symbol();
	public static readonly EVENT_WINDOW_REGISTRATION_SHOWED: symbol = Symbol();

	public static readonly EVENT_PROGRESS: symbol = Symbol();
	public static readonly EVENT_COMPLETED: symbol = Symbol();

	public static readonly EVENT_BUTTON_LOGIN_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CHEAT_CLICK: symbol = Symbol();
	public static readonly EVENT_TOGGLE_SOUND: symbol = Symbol();

	private static createLabelId(id: string): PIXI.Container {
		const container = new PIXI.Container();
		const idLabel = new PIXI.extras.BitmapText(
			`ID: ${id}`,
			{ font: { size: 24, name: 'wendyOne' } },
		);
		idLabel.anchor = new PIXI.Point(1, 0.5);
		idLabel.tint = 0x8698a1;

		container.addChild(
			idLabel,
		);
		return container;
	}

	private readonly localizationStorage: LocalizationStorage;
	private readonly gameProfileModel: GameProfileModel;
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly settingsSaveData: SettingsSaveData;
	private readonly loadingBar: PreloaderBarView;
	private readonly loadingHint: PreloaderHintView;
	private readonly links: PIXI.Container;
	private readonly scrollable: PIXI.Container;

	private readonly preloaderProgressEmitInterval: number;

	private buttonCheat: ButtonCheatView;
	private buttonPlay: ButtonWithLabelBaseView;
	private buttonLogin: ButtonWithLabelBaseView;
	private buttonMute: ButtonMuteView;

	private soundsLoaded: boolean;
	private loaderFinished: boolean;

	private completed: boolean;

	private preloaderProgress: number;
	private timeToEmitPreloaderProgress: number;

	constructor(
		protected mainLoader: AssetLoader,
		protected subLoader: AssetLoader,
		protected soundLoader: AssetLoader,
		gameProfileModel: GameProfileModel,
		settingsSaveData: SettingsSaveData,
		preloaderProgressEmitInterval: number,
		env: string,
		seasonCount: number,
		currentSeason: number,
		activatedCharacterKeys: string[],
		redeemCode: string,
		fakeProgressConstants: FakeProgressConstants,
		landingModest?: boolean,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.settingsSaveData = settingsSaveData;
		this.gameProfileModel = gameProfileModel;
		this.completed = false;

		this.preloaderProgressEmitInterval = preloaderProgressEmitInterval;
		this.timeToEmitPreloaderProgress = preloaderProgressEmitInterval;
		this.preloaderProgress = 0;

		this.loadAssets();

		AssetLoaderUtils.addCharacterAnimationsToLoad(
			LevelAssetNames.getCharacterAnimationsMetadata(),
			activatedCharacterKeys,
			this.mainLoader,
			this.subLoader,
		);

		AssetLoaderUtils.addSeasonsToLoad(
			LevelAssetNames.getSeasonAtlasMetadata,
			seasonCount,
			currentSeason,
			this.mainLoader,
			this.subLoader,
		);

		AssetLoaderUtils.addAssetsStorageCacheToLoaders(
			this.mainLoader,
			this.subLoader,
		);

		this.loadingBar = new PreloaderBarView();
		this.loadingBar.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_HEIGHT - this.loadingBar.height / 2);

		const fakeProgress: FakeLoaderProgress = new FakeLoaderProgress(fakeProgressConstants.timer, fakeProgressConstants.progress);
		fakeProgress.setLoader(this.mainLoader);

		this.mainLoader.load();
		this.subLoader.load();

		fakeProgress.on(FakeLoaderProgress.EVENT_PROGRESS, this.onLoadProgress, this);
		fakeProgress.once(FakeLoaderProgress.EVENT_COMPLETE, this.onMainloaderFinished, this);

		this.tweenGroup = new TWEEN.Group();

		const soundController = SoundController.getInstance();
		soundController.once(SoundController.EVENT_SOUNDS_LOADED, this.onSoundsLoaded, this);

		soundController.loadSounds(soundLoader);

		this.loadingHint = new PreloaderHintView(landingModest);
		this.loadingHint.position.set(GameConstants.GAME_CENTER_X, 1033);

		const labelId = LevelPreloaderView.createLabelId(redeemCode);
		labelId.position.set(1870, 1033);

		this.links = this.createLinksView();
		this.links.position.set(0, GameConstants.GAME_HEIGHT - BaseLinksView.BAR_OFFSET);

		this.buttonCheat = new ButtonCheatView(env, MODE_DEBUG);
		this.buttonCheat.position.set(1260, 38);
		this.buttonCheat.on(ButtonCheatView.EVENT_CLICK, () => this.emit(LevelPreloaderView.EVENT_BUTTON_CHEAT_CLICK));

		this.scrollable = new PIXI.Container();
		this.scrollable.addChild(
			this.loadingBar as PIXI.DisplayObject,
			this.loadingHint,
			this.links,
			labelId,
		);

		this.addChild(
			this.scrollable,
		);

		if (MODE_DEBUG) {
			this.addChild(
				this.buttonCheat,
			);
		}

		this.onWheel = this.onWheel.bind(this);
		window.addEventListener('wheel', this.onWheel);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);
		this.ticker.add(this.updateEmitPreloaderProgress, this);
	}

	// eslint-disable-next-line class-methods-use-this
	protected createLinksView(): BaseLinksView {
		return new PreloaderLinksView();
	}

	protected loadAssets(): void {
		this.mainLoader.addAssetsToLoad(LevelAssetNames.MAIN_ASSETS_TO_LOAD);
		this.subLoader.addAssetsToLoad(LevelAssetNames.SIDE_ASSETS_TO_LOAD);
	}

	private showRegistrationWindow(): void {
		const registrationWindow = new RegistrationWindowView(
			this.gameProfileModel,
		);
		registrationWindow.onShown();
		this.addChild(registrationWindow);

		this.emit(LevelPreloaderView.EVENT_WINDOW_REGISTRATION_SHOWED, registrationWindow);
	}

	private showLoginWindow(): void {
		const loginWindow = new LoginWindowView(
			this.gameProfileModel,
		);
		loginWindow.on(LoginWindowView.EVENT_BUTTON_REGISTER_CLICK, () => {
			loginWindow.destroy();
			this.showRegistrationWindow();
		}, this);
		loginWindow.onShown();
		this.addChild(loginWindow);

		this.emit(LevelPreloaderView.EVENT_WINDOW_LOGIN_SHOWED, loginWindow);
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private updateEmitPreloaderProgress(): void {
		this.timeToEmitPreloaderProgress -= this.ticker.elapsedMS / 1000;
		if (this.timeToEmitPreloaderProgress <= 0) {
			this.timeToEmitPreloaderProgress = this.preloaderProgressEmitInterval;
			this.emit(LevelPreloaderView.EVENT_PROGRESS, this.preloaderProgress);
		}
	}

	private onWheel(event: WheelEvent): void {
		if (this.scrollable.y - event.deltaY > 0) {
			this.scrollable.y = 0;
		} else if (this.scrollable.y - event.deltaY < -(BaseLinksView.BAR_HEIGHT - BaseLinksView.BAR_OFFSET)) {
			this.scrollable.y = -(BaseLinksView.BAR_HEIGHT - BaseLinksView.BAR_OFFSET);
		} else {
			this.scrollable.y -= event.deltaY;
		}
	}

	private onSoundsLoaded(): void {
		this.soundsLoaded = true;
		this.tryCompletePreloader();
	}

	private onMainloaderFinished(): void {
		this.loaderFinished = true;
		this.tryCompletePreloader();
	}

	private onLoadProgress(progress: number): void {
		this.preloaderProgress = progress / 100;
		this.loadingBar.setProgress(this.preloaderProgress);
	}

	private tryCompletePreloader(): void {
		if (this.soundsLoaded && this.loaderFinished) {
			this.ticker.remove(this.updateEmitPreloaderProgress, this);

			this.onAllResourcesLoaded();
		}
	}

	private updateText(): void {
		if (this.buttonPlay) {
			this.buttonPlay.setTextLabel(this.localizationStorage.getLocalizedString('#play_button'));
		}
		if (this.buttonLogin) {
			this.buttonLogin.setTextLabel(this.localizationStorage.getLocalizedString('#login_button'));
		}
	}

	protected onAllResourcesLoaded(): void {
		this.loadingBar.visible = false;
		this.loadingHint.visible = false;

		this.buttonMute = new ButtonMuteView();
		this.buttonMute.position.set(1870, 50);
		this.buttonMute.on(DualStateButtonBaseView.EVENT_CLICK, () => {
			this.emit(LevelPreloaderView.EVENT_TOGGLE_SOUND, this.buttonMute.isMainState());
		});
		if (this.settingsSaveData.music === 0 && this.settingsSaveData.sound === 0) {
			this.buttonMute.setSecondState();
		}

		this.buttonPlay = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 345, 117),
			{ font: '40px wendyOneShadowBold' },
		);
		this.buttonPlay.position.set(GameConstants.GAME_CENTER_X, 843);
		this.buttonPlay.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonPlayClick, this);

		this.buttonLogin = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.BLUE, 345, 83),
			{ font: '40px wendyOneShadowBold' },
		);
		this.buttonLogin.position.set(GameConstants.GAME_CENTER_X, 959);
		this.buttonLogin.on(ButtonWithLabelBaseView.EVENT_CLICK, () => {
			this.emit(LevelPreloaderView.EVENT_BUTTON_LOGIN_CLICK);
			this.showLoginWindow();
		}, this);

		this.addChild(this.buttonMute);

		this.scrollable.addChild(
			this.buttonPlay,
			this.buttonLogin,
		);

		this.updateText();

		this.buttonPlay.scale.set(0);
		this.buttonMute.scale.set(0);
		this.buttonLogin.scale.set(0);
		const scaleBack = new TWEEN.Tween({ scale: 1.1 }, this.tweenGroup)
			.to({ scale: 1 }, 200)
			.onUpdate((tweenTarget) => {
				this.buttonPlay.scale.set(tweenTarget.scale);
				this.buttonMute.scale.set(tweenTarget.scale);
				this.buttonLogin.scale.set(tweenTarget.scale);
			});

		new TWEEN.Tween({ scale: 0 }, this.tweenGroup)
			.to({ scale: 1.1 }, 200)
			.easing(TWEEN.Easing.Cubic.In)
			.onUpdate((tweenTarget) => {
				this.buttonPlay.scale.set(tweenTarget.scale);
				this.buttonMute.scale.set(tweenTarget.scale);
				this.buttonLogin.scale.set(tweenTarget.scale);
			})
			.chain(scaleBack)
			.start();

		if (this.gameProfileModel.isLoggedIn()) {
			this.hideButtonLogin();
		} else {
			this.gameProfileModel.once(GameProfileModel.EVENT_LOGGED_IN, this.hideButtonLogin, this);
		}
	}

	private onButtonPlayClick(): void {
		this.completePreloader();
	}

	protected hideButtonLogin(): void {
		this.buttonLogin.visible = false;
		this.buttonPlay.y = 910;
	}

	private completePreloader(): void {
		this.completed = true;
		this.emit(LevelPreloaderView.EVENT_COMPLETED);
	}

	public isCompleted(): boolean {
		return this.completed;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		if (this.gameProfileModel.listeners(GameProfileModel.EVENT_LOGGED_IN).includes(this.hideButtonLogin)) {
			this.gameProfileModel.off(GameProfileModel.EVENT_LOGGED_IN, this.hideButtonLogin, this, true);
		}

		window.removeEventListener('wheel', this.onWheel);

		this.tweenGroup.removeAll();

		this.ticker.remove(this.updateTweenGroup, this);
		this.ticker.remove(this.updateEmitPreloaderProgress, this);

		super.destroy(options);
	}
}
