import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBackgroundType, ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';

export class PrepartyBottomPanelView extends PIXI.Container {
	public static readonly EVENT_BUTTON_START_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private tweenMove: TWEEN.Tween;
	private buttonStart: ButtonWithLabelBaseView;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.createButtonStart();
	}

	private createButtonStart(): void {
		this.buttonStart = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.RED, 364, 104),
			{ font: '40px wendyOneGold', align: 'center' },
			380,
		);

		this.buttonStart.setTextLabel(this.localizationStorage.getLocalizedString('#preparty_button_start'));

		this.buttonStart.position.set(0, 445);
		this.buttonStart.on(ButtonBaseView.EVENT_CLICK, this.onButtonStartClick, this);
		this.addChild(this.buttonStart);
	}

	private onButtonStartClick(): void {
		this.emit(PrepartyBottomPanelView.EVENT_BUTTON_START_CLICK, this);
	}

	public getButtonStart(): PIXI.Container {
		return this.buttonStart;
	}

	public showAppearingAnimation(delay: number = 0): void {
		const duration = 200;

		this.y += 30;
		this.tweenMove = new TWEEN.Tween(this)
			.to({ y: 0 }, duration)
			.easing(TWEEN.Easing.Quadratic.Out)
			.onComplete(() => {
				this.tweenMove = null;
			})
			.delay(delay)
			.start();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenMove) {
			this.tweenMove.stop();
		}

		super.destroy(options);
	}
}
