import { BoostModel } from '@models/BoostModel';
import { BoostConfig } from '@configs/BoostConfig';
import { BoostSaveData, FarewellBonusSaveData } from '@src/types/SaveTypes';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';

export class BoostsFactory {
	private readonly configs: Map<string, BoostConfig>;
	private readonly savesData: Map<string, BoostSaveData>;
	private readonly serverTimeModel: ServerTimeModel;
	private readonly farewellBoostSaveData: FarewellBonusSaveData | null;

	constructor(
		configs: Map<string, BoostConfig>,
		savesData: Map<string, BoostSaveData>,
		farewellBoostSaveData: FarewellBonusSaveData | null,
		serverTime: ServerTimeModel,
	) {
		this.configs = configs;
		this.savesData = savesData;
		this.serverTimeModel = serverTime;
		this.farewellBoostSaveData = farewellBoostSaveData;
	}

	public createModels(): Map<string, BoostModel> {
		const models: Map<string, BoostModel> = new Map();
		this.configs.forEach((config, key) => {
			const model = new BoostModel();
			model.setFromConfig(config);

			const boostSaveData = this.savesData.get(config.getKey());
			if (boostSaveData) {
				model.setFromSaveData(boostSaveData);

				const { deactivateTime } = boostSaveData;
				if (deactivateTime !== undefined) {
					const currentTime = this.serverTimeModel.getCalculatedISOTime();
					model.setTimeleft(deactivateTime - currentTime);
				}
			}

			models.set(key, model);
		});
		return models;
	}

	public createFarewellBoostModel(): FarewellBoostModel {
		const model = new FarewellBoostModel();
		if (this.farewellBoostSaveData !== null) {
			const currentTime = this.serverTimeModel.getCalculatedISOTime();
			const active: boolean = this.farewellBoostSaveData.bonusValue > 1 && this.farewellBoostSaveData.bonusEndTime > currentTime;
			model.init(this.farewellBoostSaveData.bonusValue, this.farewellBoostSaveData.bonusEndTime, active);
			model.setTimeleft(this.farewellBoostSaveData.bonusEndTime - currentTime);
		}
		return model;
	}
}
