import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { RewardSoftMoney } from '@models/rewards/RewardSoftMoney';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardPrestigeMoney } from '@models/rewards/RewardPrestigeMoney';
import { RewardHardMoney } from '@models/rewards/RewardHardMoney';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { RewardCharacterCards } from '@models/rewards/RewardCharacterCards';
import { RewardUpgradeCards } from '@models/rewards/RewardUpgradeCards';
import { RewardTotemCards } from '@models/rewards/RewardTotemCards';
import {
	RewardTypes,
	RewardDescriptionType,
	RewardResourceIdTypes,
} from '@src/types/RewardTypes';
import { RewardVideo } from '@models/rewards/RewardVideo';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { RewardTimeskip } from '@models/rewards/RewardTimeskip';
import { RewardBoost } from '@models/rewards/RewardBoost';
import { BoostModel } from '@models/BoostModel';

export class RewardFactory {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly skillModels: Map<string, SkillModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly galleryVideoModels: Map<string, GalleryVideoModel>;
	private readonly timeskipModels: Map<string, TimeskipModel>;
	private readonly boostModels: Map<string, BoostModel>;

	constructor(
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		skillModels: Map<string, SkillModel>,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		timeskipModels: Map<string, TimeskipModel>,
		boostModels: Map<string, BoostModel>,
	) {
		this.softMoneyModel = softMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.hardMoneyModel = hardMoneyModel;
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.skillModels = skillModels;
		this.galleryVideoModels = galleryVideoModels;
		this.timeskipModels = timeskipModels;
		this.boostModels = boostModels;
	}

	public createReward(rewardConfig: RewardDescriptionType): AbstractReward {
		switch (rewardConfig.reward_type) {
			case RewardTypes.RESOURCE:
				return this.createResourceReward(rewardConfig.reward_id, rewardConfig.reward_qty);
			case RewardTypes.CHARACTER: {
				const character = this.characterModels.get(rewardConfig.reward_id);

				if (character == null) {
					throw new Error(`Character not found: ${rewardConfig.reward_id}`);
				}

				if (character.hasBonusSkill()) {
					const characterSkill = this.skillModels.get(character.getSkillKey());
					return new RewardCharacterCards(character, Number(rewardConfig.reward_qty), characterSkill);
				}
				return new RewardCharacterCards(character, Number(rewardConfig.reward_qty));
			}
			case RewardTypes.UPGRADE:
				return new RewardUpgradeCards(this.upgradeModels.get(rewardConfig.reward_id), Number(rewardConfig.reward_qty));
			case RewardTypes.TOTEM:
				return new RewardTotemCards(this.totemModels.get(rewardConfig.reward_id), Number(rewardConfig.reward_qty));
			case RewardTypes.TIMESKIP:
				return new RewardTimeskip(this.timeskipModels.get(rewardConfig.reward_id), Number(rewardConfig.reward_qty));
			case RewardTypes.BOOST:
				return new RewardBoost(this.boostModels.get(rewardConfig.reward_id), Number(rewardConfig.reward_qty));
			case RewardTypes.VIDEO:
				return new RewardVideo(this.galleryVideoModels.get(rewardConfig.reward_id));
			default:
				throw new Error(`Unsupported reward type: ${rewardConfig.reward_type}`);
		}
	}

	private createResourceReward(rewardId: string, quantity: number | string): AbstractReward {
		switch (rewardId) {
			case RewardResourceIdTypes.SOFT_MONEY:
				return new RewardSoftMoney(this.softMoneyModel, quantity.toString());
			case RewardResourceIdTypes.PRESTIGE_MONEY:
				return new RewardPrestigeMoney(this.prestigeMoneyModel, Number(quantity));
			case RewardResourceIdTypes.HARD_MONEY:
				return new RewardHardMoney(this.hardMoneyModel, Number(quantity));
			default:
				throw new Error(`Unsupported resource id: ${rewardId}`);
		}
	}
}
