import { RewardDescriptionType } from '@src/types/RewardTypes';
import { BaseAction } from '../BaseAction';

export class ClaimNewsRewardsAction extends BaseAction {
	constructor(newsKey: string, rewards: RewardDescriptionType[]) {
		super('ClaimNewsRewards');

		this.data['news_id'] = Number(newsKey);
		this.data['rewards'] = rewards;
	}
}
