export type UnlockType = string;
export type UnlockValue = string;

export enum UnlockTypes {
	MIN_LEVEL = 'min_level',
	EVENT = 'event',
	UNAVAILABLE = 'unavailable',
}

export type UnlockInfo = {
	type: UnlockType;
	value: UnlockValue;
}
