export class AutoTapLimiter {
	private static preventEvent(e: Event): void {
		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();
	}

	private readonly interactionManager: PIXI.interaction.InteractionManager;
	private minTapDelay: number;
	private lastTapTime: number;

	constructor(interactionManager: PIXI.interaction.InteractionManager) {
		this.interactionManager = interactionManager;
		this.lastTapTime = 0;
	}

	public init(minTapDelay: number): void {
		this.minTapDelay = minTapDelay;

		if (this.interactionManager.supportsPointerEvents) {
			window.addEventListener('pointerdown', this.onPointerDown.bind(this), true);
		} else {
			window.addEventListener('mousedown', this.onPointerDown.bind(this), true);
		}

		if (this.interactionManager.supportsTouchEvents) {
			window.addEventListener('touchstart', this.onPointerDown.bind(this), true);
		}
	}

	private onPointerDown(e: Event): void {
		if (this.interactionManager.supportsTouchEvents
			&& e instanceof PointerEvent
			&& (e as PointerEvent).pointerType === 'touch'
		) {
			AutoTapLimiter.preventEvent(e);
			return;
		}

		if (Date.now() - this.lastTapTime < this.minTapDelay) {
			AutoTapLimiter.preventEvent(e);
			return;
		}

		this.lastTapTime = Date.now();
	}
}
