import { UpgradeModel } from '@models/UpgradeModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextUtils } from '@src/utils/TextUtils';
import { NumberUtils } from '@src/utils/NumberUtils';

export type ReplacementMap = {
	replaceString: string;
	replaceValue: string;
};

export class TextDescriptionHelperUpgrade {
	public static getTextDescription(model: UpgradeModel): string {
		const localizationKey = TextDescriptionHelperUpgrade.getTextDescriptionKey(model);
		let text = LocalizationStorage.getInstance().getLocalizedString(localizationKey);

		const replacementMaps = TextDescriptionHelperUpgrade.getReplacementMaps(model);
		replacementMaps.forEach((replacementMap) => {
			text = TextUtils.replaceValueAndEnding(
				LocalizationStorage.getInstance().getLanguage(),
				replacementMap.replaceString,
				replacementMap.replaceValue,
				text,
			);
		});
		return text;
	}

	public static getTextDescriptionPromoteWindow(model: UpgradeModel): string {
		const localizationKey = TextDescriptionHelperUpgrade.getTextDescriptionKeyPromoteWindow(model);
		let text = LocalizationStorage.getInstance().getLocalizedString(localizationKey);

		const replacementMaps = TextDescriptionHelperUpgrade.getReplacementMaps(model);
		replacementMaps.forEach((replacementMap) => {
			text = TextUtils.replaceValueAndEnding(
				LocalizationStorage.getInstance().getLanguage(),
				replacementMap.replaceString,
				replacementMap.replaceValue,
				text,
			);
		});
		return text;
	}

	private static getReplacementMaps(model: UpgradeModel): ReplacementMap[] {
		const result: ReplacementMap[] = [];
		{
			const replaceString = '{{next_value}}';
			let replaceValue = '';
			if (model.hasBonusDecreaseCustomerCostCoef()) {
				replaceValue = NumberUtils.numberToShortString(Math.floor((model.getNextDecreaseCustomerCostCoef())));
			} else if (model.hasBonusMultiplierIncome()) {
				replaceValue = model.getNextMultiplierIncomeAsSoftMoney().toString();
			} else if (model.hasBonusMultiplierTime()) {
				replaceValue = model.getNextMultiplierTimeAsSoftMoney().toString();
			} else if (model.hasBonusReduceTimeIncomeByClick()) {
				replaceValue = NumberUtils.numberToShortString(model.getNextReduceTimeIncomeByClickValue());
			}
			result.push({ replaceString, replaceValue });
		}
		{
			const replaceString = '{{value}}';
			let replaceValue = '';
			if (model.hasBonusDecreaseCustomerCostCoef()) {
				replaceValue = NumberUtils.numberToShortString(Math.floor((model.getDecreaseCustomerCostCoef())));
			} else if (model.hasBonusMultiplierIncome()) {
				replaceValue = model.getMultiplierIncomeAsSoftMoney().toString();
			} else if (model.hasBonusMultiplierTime()) {
				replaceValue = model.getMultiplierTimeAsSoftMoney().toString();
			} else if (model.hasBonusReduceTimeIncomeByClick()) {
				replaceValue = NumberUtils.numberToShortString(model.getReduceTimeIncomeByClickValue());
			}
			result.push({ replaceString, replaceValue });
		}
		{
			const replaceString = '{{business_name}}';
			const businessKey = model.getRelatedBusinessKey();
			const replaceValue = LocalizationStorage.getInstance().getLocalizedString(`#${businessKey}_Name`);
			result.push({ replaceString, replaceValue });
		}
		return result;
	}

	private static getTextDescriptionKey(model: UpgradeModel): string {
		let key: string;
		if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#upgrade_DecreaseCustomerCost_Desc';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#upgrade_MultiplierIncome_Desc';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#upgrade_MultiplierTime_Desc';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#upgrade_ReduceTimeIncomeByClick_Desc';
		}
		return key;
	}

	private static getTextDescriptionKeyPromoteWindow(model: UpgradeModel): string {
		let key: string;
		if (model.hasBonusDecreaseCustomerCostCoef()) {
			key = '#Promote_window_upgrade_DecreaseCustomerCost_Desc';
		} else if (model.hasBonusMultiplierIncome()) {
			key = '#Promote_window_upgrade_MultiplierIncome_Desc';
		} else if (model.hasBonusMultiplierTime()) {
			key = '#Promote_window_upgrade_MultiplierTime_Desc';
		} else if (model.hasBonusReduceTimeIncomeByClick()) {
			key = '#Promote_window_upgrade_ReduceTimeIncomeByClick_Desc';
		}
		return key;
	}
}
