import { RewardDescriptionType } from '@src/types/RewardTypes';
import { BaseAction } from './BaseAction';

export class ClaimDailyBonusRewardAction extends BaseAction {
	constructor(day: number, type: string, rewards: RewardDescriptionType[]) {
		super('ClaimDailyBonusReward');

		this.data.day = day;
		this.data.type = type;
		this.data.rewards = rewards;
	}
}
