import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { Emitter } from 'pixi-particles';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { EventLevelRewardsHorizontalListView } from '@views/ui/EventLevelRewardsHorizontalListView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { BankParticleConfig } from './bank/animations/BankParticleConfig';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { HintDataReward, HintTypes } from '@src/types/HintTypes';

export class CollectEventLevelRewardsWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD: symbol = Symbol();
	public static readonly EVENT_BUTTON_COLLECT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly eventLevelModel: EventLevelModel;

	private readonly animationEmitters: Emitter[];

	private readonly titleLabel: SizeableBitmapText;

	constructor(
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
	) {
		super(0.8);

		this.eventLevelModel = eventLevelModel;

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_3'], 31, 31, 31, 31);
		bg.width = 1006;
		bg.height = 748;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 47;
		bg.interactive = true;

		const bgFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		bgFade.width = 946;
		bgFade.height = 545;
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);
		bgFade.y = -42;
		bgFade.tint = 0x19252a;

		const bgGirlFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow']);
		bgGirlFade.tint = 0x00000;
		bgGirlFade.scale.set(16);
		bgGirlFade.y = 443;

		const leftDecorGirl = new PIXI.Sprite(AssetsStorage.getResource('character8_promo').texture);
		leftDecorGirl.pivot.set(leftDecorGirl.width / 2, leftDecorGirl.height / 2);
		leftDecorGirl.position.set(-654, 27);
		const rightDecorGirl = new PIXI.Sprite(AssetsStorage.getResource('character7_promo').texture);
		rightDecorGirl.pivot.set(rightDecorGirl.width / 2, rightDecorGirl.height / 2);
		rightDecorGirl.position.set(680, 42);


		const titleBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['title_window_panel'], 5, 0, 5, 5);
		titleBg.width = 984;
		titleBg.pivot.set(titleBg.width / 2, titleBg.height / 2);
		titleBg.y = -335;

		this.titleLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#event_rewards_window_title'),
			400,
			{ font: '40px wendyOneShadowBold', align: 'center' },
		);
		this.titleLabel.anchor = 0.5;
		this.titleLabel.y = -335;

		const rightBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		rightBorderFade.width = 44;
		rightBorderFade.height = 545;
		rightBorderFade.pivot.set(rightBorderFade.width / 2, rightBorderFade.height / 2);
		rightBorderFade.tint = 0x212f36;
		rightBorderFade.position.set(451, -42);
		rightBorderFade.scale.x = -1;
		rightBorderFade.interactive = true;

		const leftBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		leftBorderFade.width = 44;
		leftBorderFade.height = 545;
		leftBorderFade.pivot.set(leftBorderFade.width / 2, leftBorderFade.height / 2);
		leftBorderFade.tint = 0x212f36;
		leftBorderFade.position.set(-451, -42);
		leftBorderFade.interactive = true;

		const rewardsContainer = new PIXI.Container();

		const goalRewards = this.eventLevelModel.getCompletedGoalRewards();
		const leagueRewards = this.eventLevelModel.getCompletedLeagueRewards();

		const goalRewardsTitle = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#event_rewards_window_rewards'),
			800,
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
		);
		goalRewardsTitle.anchor = 0.5;
		goalRewardsTitle.y = -253;
		rewardsContainer.addChild(goalRewardsTitle);

		if (goalRewards.length > 0) {
			const goalRewardsView = new EventLevelRewardsHorizontalListView(
				943,
				180,
				goalRewards,
				cardMiniViewFactory,
			);
			goalRewardsView.y = -146;
			goalRewardsView.on(
				EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD,
				(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
					const data: HintDataReward = { rewardDescription, origin };
					this.emit(CollectEventLevelRewardsWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
				},
				this,
			);

			rewardsContainer.addChild(goalRewardsView);
		} else {
			const noGoalRewardsText: SizeableBitmapText = new SizeableBitmapText(
				this.localizationStorage.getLocalizedString('#event_window_no_rewards'),
				800,
				{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
			);
			noGoalRewardsText.anchor = 0.5;
			noGoalRewardsText.y = -140;
			rewardsContainer.addChild(noGoalRewardsText);
		}

		const playerPlace: number = this.eventLevelModel.getPlayerPlace() + 1;
		const leagueRewardsSting: string = this.localizationStorage.getLocalizedString('#event_rewards_window_rating_reward')
			.replace('{{value}}', playerPlace.toString());
		const leagueRewardsTitle = new SizeableMultiColoredBitmapText(
			800,
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
		);
		leagueRewardsTitle.text = leagueRewardsSting;
		leagueRewardsTitle.anchor = 0.5;
		leagueRewardsTitle.y = -13;
		rewardsContainer.addChild(leagueRewardsTitle);

		if (leagueRewards.length > 0) {
			const leagueRewardsView = new EventLevelRewardsHorizontalListView(
				943,
				180,
				leagueRewards,
				cardMiniViewFactory,
			);
			leagueRewardsView.y = 94;
			leagueRewardsView.on(
				EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD,
				(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
					const data: HintDataReward = {
						rewardDescription,
						origin,
					};
					this.emit(CollectEventLevelRewardsWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
				},
				this,
			);

			rewardsContainer.addChild(
				leagueRewardsView as PIXI.DisplayObject,
			);
		} else {
			const noLeagueRewardsText: SizeableBitmapText = new SizeableBitmapText(
				this.localizationStorage.getLocalizedString('#event_window_no_rewards'),
				800,
				{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
			);
			noLeagueRewardsText.anchor = 0.5;
			noLeagueRewardsText.y = 100;
			rewardsContainer.addChild(noLeagueRewardsText);
		}

		const buttonClaim = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 300, 76),
			{ font: '34px wendyOneShadowBold' },
			200,
		);
		buttonClaim.y = 311;
		buttonClaim.once(ButtonWithLabelBaseView.EVENT_CLICK, this.onCollectButtonClick, this);
		if (leagueRewards.length > 0 || goalRewards.length > 0) {
			buttonClaim.setTextLabel(this.localizationStorage.getLocalizedString('#quest_ButtonClaim'));
		} else {
			buttonClaim.setTextLabel(this.localizationStorage.getLocalizedString('#bank_close_result_reward_button'));
		}

		this.animationEmitters = [];
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const animationsContainer = new PIXI.Container();
		animationsContainer.y = -308;
		animationsContainer.scale.x = 1;
		animationsContainer.scale.y = 0.24;
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.mainContainer.addChild(
			leftDecorGirl,
			rightDecorGirl,
			bgGirlFade,
			animationsContainer,
			bg,
			bgFade,
			buttonClaim,
			rewardsContainer,
			rightBorderFade,
			leftBorderFade,
			titleBg,
			this.titleLabel as PIXI.DisplayObject,
		);

		// View without rewards
		if (leagueRewards.length < 1 && goalRewards.length < 1) {
			bg.width = 756;
			bg.height = 548;
			bg.pivot.set(bg.width / 2, bg.height / 2);

			bgGirlFade.scale.set(12);

			leftDecorGirl.position.set(-454, 27);
			rightDecorGirl.position.set(480, 42);

			titleBg.width = 734;
			titleBg.pivot.set(titleBg.width / 2, titleBg.height / 2);
			titleBg.y = -235;

			this.titleLabel.y = -235;

			rightBorderFade.height = 345;
			rightBorderFade.pivot.set(rightBorderFade.width / 2, rightBorderFade.height / 2);
			rightBorderFade.position.set(327, -42);
			leftBorderFade.height = 345;
			leftBorderFade.pivot.set(leftBorderFade.width / 2, leftBorderFade.height / 2);
			leftBorderFade.position.set(-327, -42);

			buttonClaim.y = 211;

			animationsContainer.y = -208;

			bgFade.width = 696;
			bgFade.height = 345;
			bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

			const noRewardLabel = new MultiColoredTextField(
				{ font: '32px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
				580,
				250,
			);
			noRewardLabel.anchor = 0.5;
			noRewardLabel.y = -30;
			noRewardLabel.text = this.localizationStorage.getLocalizedString('#event_window_no_any_rewards');
			this.mainContainer.addChild(noRewardLabel);

			this.mainContainer.removeChild(rewardsContainer);
		}
	}

	private onCollectButtonClick(): void {
		this.onClose();
		this.emit(CollectEventLevelRewardsWindowView.EVENT_BUTTON_COLLECT_CLICK);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
