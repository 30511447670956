import { SlotView } from '@views/components/SlotView';
import { UpgradeModel } from '@models/UpgradeModel';
import { AssetsStorage } from '@main/AssetsStorage';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { UnlockTypes } from '@src/types/UnlockTypes';
import { ModelHelper } from '@models/ModelHelper';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class UpgradeSlotView extends SlotView<UpgradeModel> {
	public static readonly EVENT_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_ON_UPGRADE_CART_CLICK: symbol = Symbol();

	private static getSlotIcon(model: UpgradeModel): PIXI.Texture {
		return AssetsStorage.getAtlas('upgradeIconAtlas')[`big_${model.getKey()}`];
	}

	protected static getSlotSpecialIcon(model: UpgradeModel): PIXI.Texture {
		return AssetsStorage.getAtlas('collectionsAtlas')[`upgrade_${model.getCardRarity()}_card_bg`];
	}

	constructor(
		model: UpgradeModel,
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly currentLevel?: number,
	) {
		super(
			model,
			UpgradeSlotView.getSlotIcon(model),
			0.11,
			UpgradeSlotView.getSlotSpecialIcon(model),
			0.11,
		);

		this.cardContainer.on(ButtonBaseView.EVENT_CLICK, () => this.emit(UpgradeSlotView.EVENT_ON_UPGRADE_CART_CLICK), this);

		this.model.on(UpgradeModel.EVENT_PROMOTED, this.onUpgradePromoted, this);

		if (this.model.isActivated()) {
			this.addCheck();
			this.removeActionButton();
		} else {
			this.model.once(UpgradeModel.EVENT_ACTIVATED, this.onUpgradeActivated, this);

			if (!this.model.isOpened()) {
				const unlockInfo = this.model.getUnlockInfo();

				this.model.once(UpgradeModel.EVENT_OPENED, this.open, this);

				this.addFade();
				this.addLock();

				if ((unlockInfo.type === UnlockTypes.MIN_LEVEL && !this.model.isUnlockableAtLevel(this.currentLevel))
					|| unlockInfo.type !== UnlockTypes.MIN_LEVEL) {
					this.icon.visible = false;
					this.cardContainer.interactive = false;

					const bgKey = this.model.isFreeActivation() ? 'lock_bg_interior_card2' : 'lock_bg_upgrade_card2';
					this.addUnknownItem(AssetsStorage.getAtlas('collectionsAtlas')[bgKey], 0.32, 0.5);
				}
			} else {
				this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);

				if (!this.model.isCustomersReached()) {
					this.addFade();
					this.addLock();
					this.showInteractiveAreaWithFlyText('#fly_text_update_upgrade_card');

					this.model.once(UpgradeModel.EVENT_CUSTOMERS_REACHED, this.onCustomersReached, this);
				} else if (ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel)) {
					this.addGlow(this.model.getCardRarity());
					this.addCurrentButton();
				} else {
					this.addFade();
					this.addCurrentButton();
				}
			}
		}

		this.updateCardInfo();
	}

	private open(): void {
		this.icon.visible = true;
		this.cardContainer.interactive = true;
		this.removeUnknownItem();
		this.removeLock();

		if (this.model.isCustomersReached()) {
			this.removeFade();
			this.addCurrentButton();
		} else {
			this.addLock();
			this.model.once(UpgradeModel.EVENT_CUSTOMERS_REACHED, this.onCustomersReached, this);
		}

		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);
		this.onSoftMoneyChanged();
	}

	private onCustomersReached(): void {
		if (ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel) && this.model.isOpened()) {
			this.removeFade().removeLock().addGlow(this.model.getCardRarity());
			this.addCurrentButton();
		}
	}

	private onSoftMoneyChanged(): void {
		const isEnoughSoftMoneyForActivate = ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel);
		if (!this.glow && isEnoughSoftMoneyForActivate && this.model.isCustomersReached() && this.model.isOpened()) {
			this.removeFade();
			this.addGlow(this.model.getCardRarity());
			this.addCurrentButton();
			this.removeLock();
		} else if (this.glow && !ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel)) {
			this.removeGlow();
			this.addFade();
			this.addCurrentButton();
		}
	}

	private addCurrentButton(): void {
		this.addBuyButton(
			this.model.getActivateCost().toString(),
			ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel),
		);
	}

	private onUpgradeActivated(): void {
		this.model.off(UpgradeModel.EVENT_CUSTOMERS_REACHED, this.onCustomersReached, this, true);
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);
		this.emit(UpgradeSlotView.EVENT_ACTIVATED);

		this.removeGlow().addCheck().removeActionButton();
	}

	private onUpgradePromoted(): void {
		this.updateCardInfo();
		this.updatePromote();
	}

	protected getDefaultLockText(): string {
		return this.localizationStorage
			.getLocalizedString('#lvl_up_business_lock')
			.replace('{value}', this.model.getCustomerCount().toString());
	}

	public destroy(): void {
		this.model.off(UpgradeModel.EVENT_ACTIVATED, this.onUpgradeActivated, this, true);
		this.model.off(UpgradeModel.EVENT_UNLOCKED, this.open, this, true);
		this.model.off(UpgradeModel.EVENT_CUSTOMERS_REACHED, this.onCustomersReached, this, true);
		this.model.off(UpgradeModel.EVENT_PROMOTED, this.onUpgradePromoted, this);
		this.model.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);

		super.destroy();
	}
}
