import { MoneyType } from '@src/types/MoneyType';

export default abstract class AbstractMoneyModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_VALUE_INCREASED: symbol = Symbol();
	public static readonly EVENT_VALUE_CHANGED: symbol = Symbol();
	public static readonly EVENT_VALUE_DECREASED: symbol = Symbol();

	abstract add(value: MoneyType): void;
	abstract subtract(value: MoneyType): void;
	abstract getValue(): MoneyType;
	abstract setValue(value: MoneyType): void;
}
