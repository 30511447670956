import { AbstractQuest } from '@models/quests/AbstractQuest';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { QuestRewardsPreviewView } from './QuestRewardsPreviewView';
import { AssetsStorage } from '@main/AssetsStorage';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';

export enum QuestWindowViewMode {
	DEFAULT = 'default',
	ALWAYS_COLLECT = 'always_collect'
}

export abstract class QuestWindowBaseView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_COLLECT_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_GO_CLICK: symbol = Symbol();

	protected readonly quest: AbstractQuest;
	protected readonly mode: QuestWindowViewMode;

	protected readonly rewardsPreview: QuestRewardsPreviewView;
	protected readonly lootboxSprite: PIXI.Sprite;
	protected buttonAction: ButtonBaseView;
	private flyTextLabel: FlyBitmapText;

	protected abstract createActionButtonClaim(): ButtonBaseView;
	protected abstract createActionButtonGo(): ButtonBaseView;

	protected constructor(
		quest: AbstractQuest,
		mode: QuestWindowViewMode = QuestWindowViewMode.DEFAULT,
	) {
		super(0.9);

		this.quest = quest;
		this.mode = mode;

		this.lootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['lootbox']);

		if (quest.isCompleted() || mode === QuestWindowViewMode.ALWAYS_COLLECT) {
			this.buttonAction = this.createActionButtonClaim();
			this.buttonAction.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonCollectClick, this);
		} else {
			this.buttonAction = this.createActionButtonGo();
			this.buttonAction.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonGoClick, this);

			this.quest.once(AbstractQuest.EVENT_COMPLETED, this.onQuestCompleted, this);
		}

		this.rewardsPreview = new QuestRewardsPreviewView();

		if (this.quest.hasLootbox()) {
			this.initRewardPreviews();
			this.setLootboxSpriteRarityTexture();
		} else if (!this.quest.listeners(AbstractQuest.EVENT_REWARD_RECEIVED).includes(this.onQuestRewardReceived)) {
			this.quest.once(AbstractQuest.EVENT_REWARD_RECEIVED, this.onQuestRewardReceived, this);
		}
	}

	protected onQuestCompleted(): void {
		this.buttonAction.destroy();

		this.buttonAction = this.createActionButtonClaim();
		this.buttonAction.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonCollectClick, this);

		this.mainContainer.addChild(this.buttonAction);
	}

	protected onButtonCollectClick(): void {
		this.setCloseOnBlackoutClick(false);
		this.buttonAction.interactive = false;

		this.emit(QuestWindowBaseView.EVENT_BUTTON_COLLECT_CLICK, this.quest);
	}

	private onQuestRewardReceived(): void {
		if (this.quest.isCompleted() || this.mode === QuestWindowViewMode.ALWAYS_COLLECT) {
			this.buttonAction.setEnabled(true);
		}

		this.initRewardPreviews();
		this.setLootboxSpriteRarityTexture();
	}

	private initRewardPreviews(): void {
		const lootbox: LootboxModel = this.quest.getLootbox();
		this.rewardsPreview.init(lootbox.getRewards());
	}

	private setLootboxSpriteRarityTexture(): void {
		const lootbox = this.quest.getLootbox();
		this.lootboxSprite.texture = AssetsStorage.getAtlas('lootboxAtlas')[`${lootbox.getCardRarity()}_lootbox`];
	}

	private onButtonGoClick(): void {
		this.emit(QuestWindowBaseView.EVENT_BUTTON_GO_CLICK, this.quest);
	}

	public getActionButton(): PIXI.Container {
		return this.buttonAction;
	}

	public close(): void {
		this.onClose();
	}

	public showGoButtonError(message: string): void {
		const stringLabel: string = LocalizationStorage.getInstance().getLocalizedString(message);

		this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
		this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
			this.flyTextLabel = null;
		});
		this.flyTextLabel.y = this.buttonAction.y - 20;
		this.mainContainer.addChild(this.flyTextLabel);
	}

	protected onClose(): void {
		new TWEEN.Tween(this)
			.to({ alpha: 0 }, 100)
			.onComplete(() => super.onClose(false))
			.start();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.quest.listeners(AbstractQuest.EVENT_REWARD_RECEIVED).includes(this.onQuestRewardReceived)) {
			this.quest.off(AbstractQuest.EVENT_REWARD_RECEIVED, this.onQuestRewardReceived, this, true);
		}
		this.quest.off(AbstractQuest.EVENT_COMPLETED, this.onQuestCompleted, this, true);

		super.destroy(options);
	}
}
