import { QuestLineItemLevelConfig } from '@configs/level/LevelBaseConfig';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { BaseAction } from '@models/network/actions/BaseAction';
import { QuestCompleteAction } from '@models/network/actions/quests/QuestCompleteAction';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestLineModel } from '@models/quests/QuestLineModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { QuestFactory } from '@src/initializers/QuestFactory';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { QuestInitData } from '@src/types/SaveTypes';

interface QuestLineItemConfig {
	params?: string;
	config: QuestConfigBase;
	lineConfigColumnKey: string;
}

export class QuestsLoaderBase extends PIXI.utils.EventEmitter {
	protected lineIdModelMap: Map<number, QuestLineModel>;
	protected lineIdQuestMap: Map<number, AbstractQuest>;
	protected lineIdLineConfigColumnKey: Map<number, string>;
	protected questLineConfigs: Map<number, QuestLineItemConfig[]>;

	constructor(
		protected readonly configs: Map<string, QuestConfigBase>,
		protected readonly networkRequestSender: NetworkRequestSender,
		protected readonly questFactory: QuestFactory,
		protected readonly lootboxFactory: LootboxFactory,
		protected readonly questLinesModel: QuestLinesModel,
	) {
		super();

		this.questLineConfigs = new Map();
		this.lineIdModelMap = new Map();
		this.lineIdQuestMap = new Map();
		this.lineIdLineConfigColumnKey = new Map();
	}

	public async init(
		levelQuestLines: QuestLineItemLevelConfig[][],
		savesData: QuestInitData[],
	): Promise<void> {
		await Promise.all(levelQuestLines.map(async (levelQuestLine, lineId) => {
			const lineConfigs: QuestLineItemConfig[] = levelQuestLine.map(({ params, key, lineConfigColumnKey }) => {
				const config = this.configs.get(key);
				return { config, params, lineConfigColumnKey };
			});
			this.questLineConfigs.set(lineId, lineConfigs);

			const saveData = savesData?.[lineId];
			const levelParameters = lineConfigs.map(lineConfig => {
				if (saveData?.questKey === lineConfig.config.getKey()) {
					return saveData.info.paramsOnLevel;
				}
				return lineConfig.params;
			});
			const line = new QuestLineModel(lineId, levelParameters);
			line.moveLine(saveData?.lineProgress);

			let isLineComplete: boolean = !this.canCreateNextQuest(line);
			if (savesData?.length > 0) {
				isLineComplete &&= saveData.completed;
			}

			if (!isLineComplete) {
				this.lineIdModelMap.set(line.getId(), line);
				this.lineIdLineConfigColumnKey.set(lineId, lineConfigs[0].lineConfigColumnKey);

				if (saveData) {
					if (saveData.completed) {
						line.moveLine();

						const quest = await this.createNextQuestServer(line);
						this.lineIdQuestMap.set(line.getId(), quest);
					} else {
						const lineQuestConfig = this.configs.get(saveData.questKey);
						const quest = this.createQuest(
							line,
							lineQuestConfig,
							saveData.completeValue,
							saveData,
						);
						this.lineIdQuestMap.set(line.getId(), quest);
					}
				} else {
					const quest = await this.createNextQuestServer(line);
					this.lineIdQuestMap.set(line.getId(), quest);
				}
			}
		}));

		if (this.lineIdQuestMap.size > 0) {
			this.setQuestsRewardsServer(Array.from(this.lineIdQuestMap.values()));
		}

		this.onInited();
	}

	public finishQuest(quest: AbstractQuest): void {
		const questLootbox = quest.getLootbox();
		const questRewards = questLootbox.getRewards().map((reward) => reward.getRewardDescription());

		if (questLootbox.hasRewardVideo()) {
			const videoReward = questLootbox.getRewardVideo();
			questRewards.push(videoReward.getRewardDescription());
		}

		const line = this.lineIdModelMap.get(quest.getLineId());
		const action = new QuestCompleteAction(
			quest.getKey(),
			this.lineIdLineConfigColumnKey.get(line.getId()),
			line.getProgress(),
			questRewards,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	public async createNextQuest(quest: AbstractQuest): Promise<void> {
		const line = this.lineIdModelMap.get(quest.getLineId());

		if (this.canCreateNextQuest(line)) {
			line.moveLine();

			const lineQuest = await this.createNextQuestServer(line);
			this.lineIdQuestMap.set(line.getId(), lineQuest);

			await this.setQuestsRewardsServer([lineQuest]);
			this.questLinesModel.updateProgress(lineQuest);
		} else {
			this.questLinesModel.setLineCompleted(line.getId());
			this.questLinesModel.updateProgress();
		}
	}

	public reset(): void {
		this.lineIdModelMap.clear();
		this.lineIdQuestMap.clear();
		this.lineIdLineConfigColumnKey.clear();
	}

	// eslint-disable-next-line class-methods-use-this
	protected canCreateNextQuest(line: QuestLineModel): boolean {
		return !line.isLineCompleted();
	}

	private onInited(): void {
		const lineQuestMap: Map<QuestLineModel, AbstractQuest> = new Map();
		this.lineIdModelMap.forEach((line) => {
			const lineQuest = this.lineIdQuestMap.get(line.getId());
			lineQuestMap.set(line, lineQuest);
		});
		this.questLinesModel.init(lineQuestMap);
	}

	private async createNextQuestServer(line: QuestLineModel): Promise<AbstractQuest> {
		const progress: number = line.getProgress();
		const lineId: number = line.getId();
		const lineConfigs = this.questLineConfigs.get(lineId);

		const lineQuestServer = await this.networkRequestSender.sendGetQuest(
			this.lineIdLineConfigColumnKey.get(lineId),
			progress,
		);

		return this.createQuest(
			line,
			lineConfigs[progress].config,
			lineQuestServer.complete_value,
		);
	}

	private async setQuestsRewardsServer(quests: AbstractQuest[]): Promise<void> {
		const data: { [key: string]: string } = {};
		quests.forEach(quest => {
			data[`${quest.getLineId()}`] = quest.getKey();
		});
		const rewardsConfig = await this.networkRequestSender.sendGetQuestsRewards(data);

		quests.forEach((quest) => {
			const questId = quest.getKey();
			const lineId = quest.getLineId();
			const lootbox = this.lootboxFactory.createQuestLootbox(rewardsConfig[lineId][questId]);
			quest.setLootbox(lootbox);
		});
	}

	private createQuest(
		line: QuestLineModel,
		config: QuestConfigBase,
		completeValue?: string,
		saveData?: QuestInitData,
	): AbstractQuest {
		if (config.canSetParamsOnLevel() && line.hasCurrentQuestLevelParameters()) {
			config.setParamsOnLevel(line.getCurrentQuestLevelParameters());
		}

		const quest = this.questFactory.createQuest(config, completeValue, line.getId());
		if (quest.canRestoreSavedState() && saveData) {
			quest.restoreSavedState(saveData);
		}

		return quest;
	}
}
