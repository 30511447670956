import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';

export class BusinessInfoBarView extends PIXI.Container {
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private textIncome: SizeableMultiColoredBitmapText;
	private incomePerSec: string;
	private animationsContainer: PIXI.Container;
	private animationEmitters: Emitter[];

	private localizationStorage: LocalizationStorage;

	private iconInfo: ButtonBaseView;

	constructor() {
		super();

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.animationEmitters = [];
		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.animationsContainer = new PIXI.Container();

		const incomeBg = new PIXI.Graphics();
		incomeBg.beginFill(0x343643);
		incomeBg.drawRoundedRect(0, 0, 93, 12, 2);
		incomeBg.endFill();
		incomeBg.pivot.set(0, incomeBg.height / 2);

		const iconMoney = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']);
		iconMoney.scale.set(0.2);
		iconMoney.x = 1;
		iconMoney.y = 1;

		this.textIncome = new SizeableMultiColoredBitmapText(60, {
			font: '10px wendyOneShadowBold',
		});
		this.textIncome.anchor = new PIXI.Point(0, 0.5);
		this.textIncome.x = 20;

		this.addChild(
			incomeBg,
			iconMoney,
			this.animationsContainer,
			this.textIncome as PIXI.DisplayObject,
		);
	}

	private onTranslateText(): void {
		const stringIncome = this.localizationStorage.getLocalizedString('#income_in_sec');
		this.textIncome.text = `[ffef91]+ ${stringIncome.replace('{{value}}', this.incomePerSec)}[-]`;
		this.animationsContainer.position.set(
			85,
			0,
		);
	}

	public setIncomePerSec(value: string): void {
		this.incomePerSec = value;
		this.onTranslateText();

		const moneyFxEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_glow4']],
			BusinessAnimationsConfig.getMoneyFX(),
		);
		this.animationEmitters.push(moneyFxEmitter);
		moneyFxEmitter.playOnceAndDestroy();

		const glowEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_big_glow']],
			BusinessAnimationsConfig.getBusinessGlow5(),
		);
		this.animationEmitters.push(glowEmitter);
		glowEmitter.playOnceAndDestroy();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
