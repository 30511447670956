export class ServerTimeModel {
	private lastServerTime: number;
	private responseClientTime: number;

	constructor() {
		this.lastServerTime = 0;
	}

	public setServerTime(time: number): void {
		this.lastServerTime = time > this.lastServerTime ? time : this.lastServerTime;
		this.responseClientTime = Date.now();
	}

	public getCalculatedISOTime(): number {
		return this.lastServerTime + Math.round((Date.now() - this.responseClientTime) / 1000);
	}

	public getCalculatedISOTimeMS(): number {
		return this.lastServerTime * 1000 + (Date.now() - this.responseClientTime);
	}
}
