import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AbstractPromotableEntityConfig } from '@configs/AbstractPromotableEntityConfig';
import { BonusTypes } from '@src/types/BonusTypes';

export default class CharacterConfig extends AbstractPromotableEntityConfig {
	private static readonly specificBonusTypes: BonusTypes[] = [
		BonusTypes.AUTOMATE,
		BonusTypes.SKILL,
	];

	public static readonly FIELD_AFFECT_ALL: string = 'affect_all';
	public static readonly FIELD_ACTIVATE_COST: string = 'activate_cost';
	public static readonly FIELD_ACTIVATE_LEVEL: string = 'activate_level';
	public static readonly FIELD_SOUND_PACK: string = 'sound_pack';
	public static readonly FIELD_NUDE_LEVEL: string = 'nude_level';

	private readonly affectsAllBusinesses: boolean;
	private readonly activateCost: SoftMoneyNumber;
	private readonly activateLevels: number[];
	private readonly soundPack: string;
	private readonly nudeLevels: number[];

	private readonly specificBonuses: Map<BonusTypes, number[] | string>;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.affectsAllBusinesses = (config[CharacterConfig.FIELD_AFFECT_ALL].trim().toLowerCase() === 'true');

		this.activateCost = SoftMoneyNumber.createFromAcronym((config[CharacterConfig.FIELD_ACTIVATE_COST] as string).trim());

		this.activateLevels = (config[CharacterConfig.FIELD_ACTIVATE_LEVEL] as string).split(',').map(str => Number(str.trim()));

		this.soundPack = (config[CharacterConfig.FIELD_SOUND_PACK] as string).trim();

		this.nudeLevels = (config[CharacterConfig.FIELD_NUDE_LEVEL] as string).split(',').map(str => Number(str.trim()));

		this.specificBonuses = new Map();

		const bonusTypes: BonusTypes[] = config[AbstractPromotableEntityConfig.FIELD_BONUS]
			.trim().split(',').map((v: string) => v.trim()) as BonusTypes[];
		const bonusValuesUnformatted: string[] = config[AbstractPromotableEntityConfig.FIELD_BONUS_VALUE].trim().split(';');
		bonusTypes.forEach((bonusType, i) => {
			if (CharacterConfig.specificBonusTypes.includes(bonusType)) {
				switch (bonusType) {
					case BonusTypes.AUTOMATE: {
						this.specificBonuses.set(bonusType, '');
						break;
					}
					case BonusTypes.SKILL: {
						this.specificBonuses.set(bonusType, bonusValuesUnformatted[i].trim());
						break;
					}
					default:
						throw new Error(`Unsupported BonusTypes ${BonusTypes}`);
				}
			}
		});
	}

	public getActivateCost(): SoftMoneyNumber {
		return this.activateCost;
	}

	public isAffectsAllBusinesses(): boolean {
		return this.affectsAllBusinesses;
	}

	public getActivateLevels(): number[] {
		return this.activateLevels;
	}

	public getSoundPack(): string {
		return this.soundPack;
	}

	public getNudeLevels(): number[] {
		return this.nudeLevels;
	}

	public hasSkillBonus(): boolean {
		return this.specificBonuses.has(BonusTypes.SKILL);
	}

	public getSkillKey(): string {
		return this.specificBonuses.get(BonusTypes.SKILL) as string;
	}

	public getSpecificBonuses(): Map<BonusTypes, number[] | string> {
		return this.specificBonuses;
	}
}
