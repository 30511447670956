import { BackToGameWindowViewSetter, GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { BackToGameWindowView } from '@views/windows/backToGame/BackToGameWindowView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BackToGameRewardAction } from '@models/network/actions/BackToGameRewardAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';

export class BackToGameWindowViewController
	extends PIXI.utils.EventEmitter implements BackToGameWindowViewSetter, GameUIBaseViewSetter {
	private view: BackToGameWindowView;
	private gameUIView: GameUIBaseView;

	constructor(
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly offlineRewardCost: number,
		private readonly backToGameRewardModel: BackToGameRewardModel,
	) {
		super();
	}

	public setBackToGameWindowView(view: BackToGameWindowView): void {
		this.view = view;
		this.view.on(BackToGameWindowView.EVENT_SOFT_MONEY_CLAIM, this.onSoftMoneyClaim, this);
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUIView = view;
	}

	private onSoftMoneyClaim(
		improved: boolean,
		position: PIXI.Point,
	): void {
		this.gameUIView.playSoftMoneyIncomeAnimation(position);

		const rewardSoftMoneyByBusinesses = this.backToGameRewardModel.getRewardSoftMoneyByBusinesses(improved);
		rewardSoftMoneyByBusinesses.forEach((rewardSoftMoney, businessKey) => {
			this.softMoneyModel.add(rewardSoftMoney, businessKey);
		});

		if (improved) {
			this.hardMoneyModel.subtract(this.offlineRewardCost);
		}

		const amountTable: { [key: string]: string } = {};
		rewardSoftMoneyByBusinesses.forEach((amount, key) => { amountTable[key] = amount.toRawString(); });
		const action = new BackToGameRewardAction(
			improved,
			this.backToGameRewardModel.getOfflineSeconds(),
			amountTable,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);

		this.backToGameRewardModel.resetBusinessesLastUpdate();
	}
}
