import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { LevelModel } from '@models/level/LevelModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';

export class ModelWriterLevel {
	private readonly levelModel: LevelModel;
	private readonly levelChallengeModel: LevelChallengeModel;
	private readonly questLinesModel: QuestLinesModel;

	constructor(
		levelModel: LevelModel,
		questLinesModel: QuestLinesModel,
		levelChallengeModel: LevelChallengeModel,
	) {
		this.levelChallengeModel = levelChallengeModel;
		this.questLinesModel = questLinesModel;

		this.levelModel = levelModel;
		this.levelModel.on(LevelModel.EVENT_PROGRESS_COMPLETE, this.onQuestLinesProgressComplete, this);
	}

	private onQuestLinesProgressComplete(): void {
		if (this.levelChallengeModel.hasChallenges() && this.levelChallengeModel.isActive()) {
			this.levelChallengeModel.complete();
		}

		this.removeRewardVideoFromCurrentQuestsLootboxes();
	}

	private removeRewardVideoFromCurrentQuestsLootboxes(): void {
		this.questLinesModel.getCurrentQuests().forEach(currentQuest => {
			const lootbox = currentQuest.getLootbox();
			if (lootbox.hasRewardVideo()) {
				lootbox.removeRewardVideo();
			}
		});
	}
}
