import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';

export class EventLevelButtonPatricleView extends PIXI.Container {
	private static getEventLevelGlow(): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 8,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#c33d29',
				end: '#fac41a',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -5,
				max: 5,
			},
			lifetime: {
				min: 5,
				max: 8,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	private readonly animationEmitters: Emitter[];

	constructor() {
		super();

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.animationEmitters = [];

		const animationsContainer = new PIXI.Container();
		animationsContainer.scale.x = 0.26;
		animationsContainer.scale.y = 0.18;
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			EventLevelButtonPatricleView.getEventLevelGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const decorFx = new PIXI.Sprite(fxAtlas['texture_decor']);
		decorFx.scale.x = 2;
		decorFx.scale.y = 2;
		decorFx.alpha = 0.5;
		decorFx.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

		this.addChild(
			animationsContainer,
			decorFx,
		);
	}

	public destroy(): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy();
	}
}
