import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export type PresetLevelValue = {
	level: number;
	value: number;
}

export class PresetConfig implements ConfigSetElement {
	public static readonly FIELD_KEY = 'id';
	public static readonly FIELD_KEY_VALUES = 'key_values';
	public static readonly FIELD_LEVELS_BETWEEN = 'levels_between';
	public static readonly FIELD_LINEAR_PERCENT = 'linear_percent';
	public static readonly FIELD_ROUND = 'round';

	private readonly key: string;
	private readonly keyValues: number[];
	private readonly levelsBetween: PresetLevelValue[];
	private readonly linearPercent: PresetLevelValue[];
	private readonly round: number[];

	constructor(config: Record<string, string>) {
		this.key = config[PresetConfig.FIELD_KEY].trim();
		this.keyValues = config[PresetConfig.FIELD_KEY_VALUES].split(',').map((x) => Number(x));
		this.levelsBetween = PresetConfig.parseLevelsBetween(config[PresetConfig.FIELD_LEVELS_BETWEEN]);
		this.linearPercent = PresetConfig.parseLinearPercent(config[PresetConfig.FIELD_LINEAR_PERCENT]);
		this.round = config[PresetConfig.FIELD_ROUND].split(',').map((x) => Number(x));
	}

	public getKey(): string {
		return this.key;
	}

	public getKeyValues(): number[] {
		return this.keyValues;
	}

	public getLevelsBetween(): PresetLevelValue[] {
		return this.levelsBetween;
	}

	public getLinearPercent(): PresetLevelValue[] {
		return this.linearPercent;
	}

	public getRound(): number[] {
		return this.round;
	}

	private static parseLevelsBetween(value: string): PresetLevelValue[] {
		return value.split(',')
			.map((x) => {
				const [level, value] = x.split(':');
				let result;

				if (value === undefined) {
					result = { level: 1, value: Number(level) };
				} else {
					result = {
						level: Number(level),
						value: Number(value),
					};
				}

				return result;
			});
	}

	private static parseLinearPercent(value: string): PresetLevelValue[] {
		return value.split(',')
			.map((x) => {
				const [level, value] = x.split(':');
				let result;

				if (value === undefined) {
					result = { level: 1, value: Number(level.replace('%', '')) / 100 };
				} else {
					result = {
						level: Number(level),
						value: Number(value.replace('%', '')) / 100,
					};
				}

				return result;
			});
	}
}
