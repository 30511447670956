export class BankParticleConfig {
	public static getRotateGlow(): any {
		return {
			alpha: {
				start: 0.3,
				end: 0.3,
			},
			scale: {
				start: 1.2,
				end: 1.2,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -30,
				max: 30,
			},
			lifetime: {
				min: 999999,
				max: 999999,
			},
			blendMode: 'add',
			frequency: 0.0001,
			emitterLifetime: -1,
			maxParticles: 3,
			pos: {
				x: 0,
				y: -50,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getCardFlare(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 1.0,
				end: 0.0,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.01,
			},
			acceleration: {
				x: 0,
				y: -10,
			},
			maxSpeed: 100,
			startRotation: {
				min: -90,
				max: -90,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1.1,
				max: 1.2,
			},
			blendMode: 'add',
			frequency: 0.8,
			spawnChance: 0.35,
			emitterLifetime: -1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -100,
				y: -180,
				w: 100,
				h: 100,
			},
		};
	}

	public static getCardFlare2(): any {
		return {
			alpha: {
				start: 1,
				end: 0.2,
			},
			scale: {
				start: 0.8,
				end: 0.3,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffdd19',
				end: '#fa7d08',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.01,
			},
			acceleration: {
				x: 0,
				y: -40,
			},
			maxSpeed: 100,
			startRotation: {
				min: -90,
				max: -90,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 2.5,
				max: 2.5,
			},
			blendMode: 'add',
			frequency: 0.1,
			spawnChance: 0.90,
			emitterLifetime: -1,
			maxParticles: 200,
			pos: {
				x: -100,
				y: 110,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -100,
				y: -180,
				w: 400,
				h: 10,
			},
		};
	}

	public static getRecharcgeFlare(): any {
		return {
			alpha: {
				start: 0.73,
				end: 0.94,
			},
			scale: {
				start: 0.8,
				end: 0.01,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ff0000',
				end: '#ffff00',
			},
			speed: {
				start: 1000,
				end: 500,
				minimumSpeedMultiplier: 0.4,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 20,
				max: -200,
			},
			noRotation: false,
			rotationSpeed: {
				min: 1,
				max: 333,
			},
			lifetime: {
				min: 1,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.09,
			spawnChance: 1,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 20,
			},
		};
	}

	public static getRecharcgeGlow(): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 8,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#ff5a00',
				end: '#fff7a2',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -5,
				max: 5,
			},
			lifetime: {
				min: 5,
				max: 8,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getRecharcgePurpleGlow(): any {
		return {
			alpha: {
				start: 0,
				end: 1,
			},
			scale: {
				start: 4,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#ff3ace',
				end: '#ff3ace',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -25,
				max: 25,
			},
			lifetime: {
				min: 1,
				max: 4,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getPurpleGlow(rotate: number, frequencyValue: number): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 2,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#ff39ce',
				end: '#ffffff',
			},
			speed: {
				start: rotate * 2,
				end: 0,
				minimumSpeedMultiplier: 0.1,
			},
			acceleration: {
				x: rotate,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 3,
			},
			blendMode: 'add',
			frequency: frequencyValue,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: -250,
				w: 50,
				h: 500,
			},
		};
	}

	public static getRecharcgeGlowBlue(): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 8,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#0024ff',
				end: '#66e8ff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -5,
				max: 5,
			},
			lifetime: {
				min: 5,
				max: 8,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getRecharcgeGlow2(): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 9,
				end: 0.001,
				minimumScaleMultiplier: 0.85,
			},
			color: {
				start: '#ffff00',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -30,
				max: 30,
			},
			lifetime: {
				min: 3,
				max: 6,
			},
			blendMode: 'add',
			frequency: 2,
			emitterLifetime: -1,
			maxParticles: 2000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getHorizontalGlow(): any {
		return {
			alpha: {
				start: 0.1,
				end: 0,
			},
			scale: {
				start: 0.1,
				end: 2.5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffdd19',
				end: '#fa7d08',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 180,
				max: 180,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 5,
				max: 10,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: -180,
				y: -90,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 360,
				h: 1,
			},
		};
	}

	public static getMonthlyBundlePurpleGlow(): any {
		return {
			alpha: {
				start: 0.9,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 8,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#742aae',
				end: '#742aae',
			},
			speed: {
				start: 10,
				end: 30,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 20,
				max: 10,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -650,
				y: -125,
				w: 1300,
				h: 250,
			},
		};
	}

	public static getMonthlyBundlePurpleFlash(): any {
		return {
			alpha: {
				start: 0.3,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 8,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#742aae',
				end: '#742aae',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: 50,
			},
			lifetime: {
				min: 20,
				max: 10,
			},
			blendMode: 'add',
			frequency: 2,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -650,
				y: -125,
				w: 1300,
				h: 250,
			},
		};
	}

	public static getMonthlyBundleBlueGlow(): any {
		return {
			alpha: {
				start: 0.3,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 4,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#204398',
				end: '#204398',
			},
			speed: {
				start: 10,
				end: 30,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 10,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -650,
				y: -125,
				w: 1300,
				h: 250,
			},
		};
	}

	public static getMonthlyBundleBlueCircle(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0,
				end: 1,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#355096',
				end: '#1b2d8f',
			},
			speed: {
				start: 10,
				end: 30,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.08,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -520,
				y: -125,
				w: 250,
				h: 250,
			},
		};
	}

	public static getMonthlyBundleBlueFlash(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0,
				end: 1,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#3f8fd3',
				end: '#fcb222',
			},
			speed: {
				start: 60,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 100,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 300,
				max: -300,
			},
			lifetime: {
				min: 3,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: -440,
				y: 80,
				r: 160,
			},
		};
	}

	public static getMonthlyBundleRedGlow(): any {
		return {
			alpha: {
				start: 0,
				end: 1,
			},
			scale: {
				start: 1.3,
				end: 0.001,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#ee8027',
				end: '#ffffff',
			},
			speed: {
				start: 100,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 100,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -400,
				max: 400,
			},
			lifetime: {
				min: 3,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -650,
				y: -50,
				w: 350,
				h: 300,
			},
		};
	}

	public static getMonthlyBundleRedFlash(): any {
		return {
			alpha: {
				start: 0.9,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 3,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#d31515',
				end: '#d31515',
			},
			speed: {
				start: 40,
				end: 60,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -30,
				max: 30,
			},
			lifetime: {
				min: 10,
				max: 5,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -650,
				y: -125,
				w: 1300,
				h: 250,
			},
		};
	}

	public static getMonthlyBundleGoldGlow(): any {
		return {
			alpha: {
				start: 0.8,
				end: 1,
			},
			scale: {
				start: 0.4,
				end: 0.001,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#e2c477',
				end: '#d9892a',
			},
			speed: {
				start: 100,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.008,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: -440,
				y: 20,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getMonthlyBundleGoldFlash(): any {
		return {
			alpha: {
				start: 0.6,
				end: 0.6,
			},
			scale: {
				start: 0.2,
				end: 0,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#e2c477',
				end: '#d9892a',
			},
			speed: {
				start: 10,
				end: 100,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -180,
				max: -360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 2,
				max: 6,
			},
			blendMode: 'add',
			frequency: 0.008,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -400,
				y: -150,
				w: 1100,
				h: 0,
			},
		};
	}

	public static getMonthlyBundleGoldCircle(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0,
				end: 2.4,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#e2c477',
				end: '#d9892a',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: -100,
				max: 100,
			},
			lifetime: {
				min: 1,
				max: 9,
			},
			blendMode: 'add',
			frequency: 0.3,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: -440,
				y: 20,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}
}
