import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class ButtonCheatView extends ButtonBaseView {
	private readonly registerClickOnKeyPress?: boolean;

	constructor(env: string, registerClickOnKeyPress?: boolean) {
		const textCheat = new PIXI.extras.BitmapText('[cheat]', {
			font: '30px wendyOneShadowBold',
		});
		textCheat.alpha = 0.5;
		textCheat.anchor = 0.5;

		const bg = new PIXI.Sprite();
		bg.hitArea = textCheat.getLocalBounds();

		const textEnv = new PIXI.extras.BitmapText(env, {
			font: '30px wendyOneShadowBold',
		});
		textEnv.alpha = 0.5;
		textEnv.anchor = 0.5;
		textEnv.x = -100;

		super(
			bg,
			undefined,
			undefined,
			false,
		);

		this.registerClickOnKeyPress = registerClickOnKeyPress;
		if (registerClickOnKeyPress) {
			this.onSomeKeyDown = this.onSomeKeyDown.bind(this);
			window.addEventListener('keydown', this.onSomeKeyDown);
		}

		this.addChild(textCheat, textEnv);
	}

	private onSomeKeyDown(e: any): void {
		if (e.keyCode === 17 && this.interactive && this.worldVisible) {
			this.emit(ButtonCheatView.EVENT_CLICK);
		}
	}

	public destroy(): void {
		if (this.registerClickOnKeyPress) {
			window.removeEventListener('keydown', this.onSomeKeyDown);
		}
		super.destroy();
	}
}
