import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter90004View extends CharacterMainBaseView {
	constructor() {
		super('character90004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-200, 31);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-180, -28),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-166, -30),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
