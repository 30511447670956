import { BoostModel } from '@models/BoostModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { ButtonWithCostValueView, ButtonValueTypes } from '@views/components/buttons/ButtonWithCostValueView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { NumberUtils } from '@src/utils/NumberUtils';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';
import { BaseBoostModel } from '@models/BaseBoostModel';

export class BankTabElementGemShopBoostView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_NOT_ENOUGHT_HARD: symbol = Symbol();

	private static formatTime(seconds: number): string {
		return String(Math.round(((seconds / 60) * 100) / 100));
	}

	private animationsContainer: PIXI.Container;

	private static createButtonActivate(duration: string, durationLocaleKey: string): ButtonBaseView {
		const localizationStorage = LocalizationStorage.getInstance();

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 231;
		buttonBg.height = 85;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);

		const stringActivate = localizationStorage.getLocalizedString('#bank_boost_activate');
		const labelActivate = new PIXI.extras.BitmapText(stringActivate, {
			font: '22px wendyOne',
			tint: 0x1d3d09,
		});
		labelActivate.anchor = 0.5;
		labelActivate.y = -16;

		const stringDuration = localizationStorage.getLocalizedString(durationLocaleKey).replace('{{value}}', duration);
		const labelDuration = new PIXI.extras.BitmapText(stringDuration, {
			font: '27px wendyOneShadowBold',
		});
		labelDuration.anchor = 0.5;
		labelDuration.y = 15;

		button.addChild(
			labelActivate,
			labelDuration,
		);

		return button;
	}

	private static createButtonBuy(costResource: ButtonValueTypes, costValue: number): ButtonWithCostValueView {
		const localizationStorage = LocalizationStorage.getInstance();

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 231;
		buttonBg.height = 85;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonWithCostValueView({
			buttonBg,
			fontStyle: { font: '31px wendyOneShadowBold' },
			type: costResource,
			costTextOffset: new PIXI.Point(0, 15),
		});
		button.setCost(String(costValue));

		const stringBuy = localizationStorage.getLocalizedString('#bank_buy_label');
		const labelBuy = new PIXI.extras.BitmapText(stringBuy, {
			font: '22px wendyOne',
			tint: 0x1d3d09,
		});
		labelBuy.anchor = 0.5;
		labelBuy.y = -16;
		labelBuy.x = 3;
		button.addChild(labelBuy);

		return button;
	}

	private readonly model: BoostModel;
	private readonly localizationStorage: LocalizationStorage;
	private readonly hardMoneyModel: HardMoneyModel;

	private readonly ticker: PIXI.ticker.Ticker;

	private readonly textTitle: TextField;
	private readonly textTitleDuration: PIXI.extras.BitmapText;

	private readonly titleContainer: PIXI.Container;
	private readonly timerContainer: PIXI.Container;

	private readonly textMultiplier: PIXI.extras.BitmapText;
	private readonly textBoostsLeft: MultiColoredTextField;

	private readonly textTimer: SizeableBitmapText;

	private readonly buttonActivate: ButtonBaseView;
	private readonly buttonBuy: ButtonWithCostValueView;

	private timerTicking: boolean;

	private price: number;

	private boostAcquiredAnimationEmitters: Emitter[];
	private flyTextLabel: FlyBitmapText;
	private fxAtlas: PIXI.loaders.TextureDictionary;

	constructor(
		model: BoostModel,
		costResource: ButtonValueTypes,
		cost: number,
		hardMoneyModel?: HardMoneyModel,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		if (costResource === ButtonValueTypes.HARD_MONEY) {
			this.hardMoneyModel = hardMoneyModel;
			this.hardMoneyModel.on(HardMoneyModel.EVENT_VALUE_CHANGED, this.onHardMoneyValueChanged, this);

			this.price = Number(cost);
		} else {
			throw new Error(`Unsupported cost resource ${costResource}`);
		}

		this.boostAcquiredAnimationEmitters = [];
		this.ticker = PIXI.ticker.shared;
		this.timerTicking = false;

		this.model = model;
		this.model.on(BaseBoostModel.EVENT_ACQUIRED, this.onBoostAcquired, this);
		this.model.on(BaseBoostModel.EVENT_ACTIVATED, this.onBoostActivated, this);
		this.model.on(BaseBoostModel.EVENT_DEACTIVATED, this.onBoostDeactivated, this);

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const stringTitle = this.localizationStorage.getLocalizedString('#bank_boost_multiplierIncome_title');
		this.textTitle = new TextField(stringTitle, { font: '25px wendyOneShadowBold', align: 'center' });
		this.textTitle.anchor = 0.5;
		this.textTitle.y = -190;

		this.animationsContainer = new PIXI.Container();
		this.animationsContainer.y = 90;
		this.animationsContainer.x = 30;

		const durationLocaleKey = '#bank_boost_duration_minutes';
		let stringDuration = this.localizationStorage.getLocalizedString(durationLocaleKey);
		stringDuration = stringDuration.replace('{{value}}', BankTabElementGemShopBoostView.formatTime(model.getTime()));
		this.textTitleDuration = new TextField(stringDuration, { font: '25px wendyOneShadowBold' });
		this.textTitleDuration.anchor = 0.5;
		this.textTitleDuration.y = -165;

		this.titleContainer = new PIXI.Container();
		this.titleContainer.addChild(
			this.textTitle,
			this.textTitleDuration,
		);

		this.textMultiplier = new PIXI.extras.BitmapText(`x${this.model.getBonusValue()}`, { font: '56px wendyOneGold' });
		this.textMultiplier.anchor = 0.5;
		this.textMultiplier.x = 30;

		this.textBoostsLeft = new MultiColoredTextField({ font: '23px wendyOneShadowBold' });
		this.textBoostsLeft.anchor = 0.5;
		this.textBoostsLeft.y = 90;

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['booster_card_bg']);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[model.getIconKey()]);
		icon.y = -50;
		icon.scale.set(0.35);

		this.buttonBuy = BankTabElementGemShopBoostView.createButtonBuy(costResource, cost);
		this.buttonBuy.on(ButtonBaseView.EVENT_CLICK, this.onButtonBuyClick, this);
		this.buttonBuy.y = 160;
		this.buttonBuy.setEnabled(true);

		this.buttonActivate = BankTabElementGemShopBoostView.createButtonActivate(
			BankTabElementGemShopBoostView.formatTime(this.model.getTime()),
			durationLocaleKey,
		);
		this.buttonActivate.on(ButtonBaseView.EVENT_CLICK, this.onButtonActivateClick, this);
		this.buttonActivate.y = 160;

		const timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['rarity_info_panel'], 22, 23, 22, 23);
		timerBg.scale.set(0.67);
		timerBg.width = 160;
		timerBg.pivot.set(timerBg.width / 2, timerBg.height / 2);
		timerBg.y = -180;

		const timerIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']);
		timerIcon.scale.set(0.45);
		timerIcon.position.set(-55, -183);

		this.textTimer = new SizeableBitmapText('', 80, { font: '22px wendyOneShadowBold' });
		this.textTimer.anchor = 0.5;
		this.textTimer.y = -180;

		this.timerContainer = new PIXI.Container();
		this.timerContainer.addChild(
			timerBg,
			timerIcon as PIXI.DisplayObject,
			this.textTimer,
		);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const glowContainer = new PIXI.Container();
		const glowEmitter = new Emitter(
			glowContainer,
			[fxAtlas['bundles_rotate_glow']],
			BankParticleConfig.getRotateGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.boostAcquiredAnimationEmitters.push(glowEmitter);

		this.addChild(
			bg,
			glowContainer,
			this.animationsContainer,
			icon,
			this.titleContainer,
			this.timerContainer,
			this.textMultiplier,
			this.textBoostsLeft,
			this.buttonBuy,
			this.buttonActivate,
		);

		const starEmitter = new Emitter(
			this,
			[fxAtlas['bundles_small_star']],
			BankParticleConfig.getCardFlare(),
		);
		starEmitter.autoUpdate = true;
		this.boostAcquiredAnimationEmitters.push(starEmitter);

		this.updateTextBoostsLeft();
		this.updateButtonsVisible();
		this.updateButtonBuyEnabled();

		if (this.model.isActivated()) {
			this.setTimerVisible(true);
			this.startTimer();
		} else {
			this.setTimerVisible(false);
		}
	}

	public getButtonBottomContainer(): PIXI.Container {
		return this.buttonBuy.visible ? this.buttonBuy : this.buttonActivate;
	}

	private setTimerVisible(value: boolean): void {
		this.timerContainer.visible = value;
		this.titleContainer.visible = !value;
	}

	private onBoostAcquired(): void {
		this.updateTextBoostsLeft();
		this.updateButtonsVisible();

		if (!this.flyTextLabel) {
			this.flyTextLabel = new FlyBitmapText(
				'+1',
				new PIXI.Point(0.5, 0.5),
				{ font: '24px wendyOneShadowBold', tint: 0xffe03a },
			);
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.textBoostsLeft.x + 30, this.textBoostsLeft.y - 15);
			this.addChild(this.flyTextLabel);
		}

		const boostFxEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_glow4']],
			BusinessAnimationsConfig.getMoneyFX(),
		);
		this.boostAcquiredAnimationEmitters.push(boostFxEmitter);
		boostFxEmitter.playOnceAndDestroy();

		const glowEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_big_glow']],
			BusinessAnimationsConfig.getBusinessGlow5(),
		);
		this.boostAcquiredAnimationEmitters.push(glowEmitter);
		glowEmitter.playOnceAndDestroy();
	}

	private onBoostActivated(): void {
		this.updateTextBoostsLeft();
		this.updateButtonsVisible();

		if (!this.timerTicking) {
			this.setTimerVisible(true);
			this.startTimer();
		}
	}

	private onBoostDeactivated(): void {
		this.updateTextBoostsLeft();
		this.updateButtonsVisible();

		this.stopTimer();
		this.setTimerVisible(false);
	}

	private updateButtonsVisible(): void {
		if (this.model.canActivate()) {
			this.buttonActivate.visible = true;
			this.buttonBuy.visible = false;
		} else {
			this.buttonActivate.visible = false;
			this.buttonBuy.visible = true;
		}
	}

	private startTimer(): void {
		this.ticker.add(this.onTimerUpdate, this);
		this.timerTicking = true;
	}

	private stopTimer(): void {
		this.ticker.remove(this.onTimerUpdate, this);
		this.timerTicking = false;
	}

	private onTimerUpdate(): void {
		this.textTimer.text = NumberUtils.secToDHMS(this.model.getTimeleft());
		this.textTimer.dirty = true;
	}

	private updateTextBoostsLeft(): void {
		const boostsLeftCount = this.model.getActivateCount() - this.model.getCurrentActivateCount();
		let stringBoostsLeft = this.localizationStorage.getLocalizedString('#bank_label_you_have');
		stringBoostsLeft = stringBoostsLeft.replace('{{value}}', String(boostsLeftCount));
		this.textBoostsLeft.text = stringBoostsLeft;
	}

	private onButtonBuyClick(): void {
		if (this.hardMoneyModel.getValue() >= this.price) {
			this.emit(BankTabElementGemShopBoostView.EVENT_BUTTON_BUY_CLICK);
		} else {
			this.emit(BankTabElementGemShopBoostView.EVENT_NOT_ENOUGHT_HARD);
		}
	}

	private onButtonActivateClick(): void {
		this.emit(BankTabElementGemShopBoostView.EVENT_BUTTON_ACTIVATE_CLICK);
	}

	private onHardMoneyValueChanged(): void {
		this.updateButtonBuyEnabled();
	}

	private updateButtonBuyEnabled(): void {
		if (this.hardMoneyModel) {
			const isEnoughHardMoneyForBuy = this.hardMoneyModel.getValue() >= this.price;
			this.buttonBuy.setCostValueEnabled(isEnoughHardMoneyForBuy);
			this.buttonBuy.setButtonBgEnabled(isEnoughHardMoneyForBuy);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.model.off(BaseBoostModel.EVENT_ACQUIRED, this.onBoostAcquired, this);
		this.model.off(BaseBoostModel.EVENT_ACTIVATED, this.onBoostActivated, this);
		this.model.off(BaseBoostModel.EVENT_DEACTIVATED, this.onBoostDeactivated, this);

		if (this.hardMoneyModel) {
			this.hardMoneyModel.off(HardMoneyModel.EVENT_VALUE_CHANGED, this.onHardMoneyValueChanged, this);
		}

		if (this.timerTicking) {
			this.stopTimer();
		}

		this.boostAcquiredAnimationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
