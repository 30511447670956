import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { PromotableModel } from '@models/PromotableModel';

export class ModelWriterPromotable {
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;

	private readonly epicQuestCollectionsModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>;

	constructor(
		epicQuestCollectionsModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
	) {
		this.epicQuestCollectionsModels = epicQuestCollectionsModels;

		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;

		Array
			.from(this.characterModels.values())
			.filter(m => !m.isOpened())
			.forEach(m => m.once(PromotableModel.EVENT_OPENED, this.onSomeCharacterOpened, this));

		Array
			.from(this.upgradeModels.values())
			.filter(m => !m.isOpened())
			.forEach(m => m.once(PromotableModel.EVENT_OPENED, this.onSomeUpgradeOpened, this));

		Array
			.from(this.totemModels.values())
			.filter(m => !m.isOpened())
			.forEach(m => m.once(PromotableModel.EVENT_OPENED, this.onSomeTotemOpened, this));
	}

	private onSomeCharacterOpened(): void {
		if (this.epicQuestCollectionsModels.has(EpicQuestCollectionsTypes.CHARACTERS)) {
			const epicQuestModel = this.epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.CHARACTERS);
			epicQuestModel.updateProgress();
		}
	}

	private onSomeUpgradeOpened(upgrade: UpgradeModel): void {
		if (upgrade.isFreeActivation()) {
			if (this.epicQuestCollectionsModels.has(EpicQuestCollectionsTypes.FURNITURE)) {
				const epicQuestModel = this.epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.FURNITURE);
				epicQuestModel.updateProgress();
			}
		} else if (this.epicQuestCollectionsModels.has(EpicQuestCollectionsTypes.UPGRADES)) {
			const epicQuestModel = this.epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.UPGRADES);
			epicQuestModel.updateProgress();
		}
	}

	private onSomeTotemOpened(): void {
		if (this.epicQuestCollectionsModels.has(EpicQuestCollectionsTypes.TOTEMS)) {
			const epicQuestModel = this.epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.TOTEMS);
			epicQuestModel.updateProgress();
		}
	}
}
