import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { UpgradeModel } from '@models/UpgradeModel';
import { CharacterModel } from '@models/CharacterModel';
import * as TWEEN from '@tweenjs/tween.js';
import { CardRarities } from '@src/types/CardRarities';
import { TextDescriptionHelperCharacter } from '@views/windows/collection/characters/TextDescriptionHelperCharacter';
import { TextDescriptionHelperUpgrade } from '@views/windows/collection/upgrades/TextDescriptionHelperUpgrade';
import { TotemModel } from '@models/TotemModel';
import { TextDescriptionHelperTotem } from '@views/windows/collection/totems/TextDescriptionHelperTotem';
import { GameConstants } from '@src/utils/GameConstants';
import { BaseEventLevelCharacterHintView } from './BaseEventLevelCharacterHintView';

export type EventCharacterUpgradeDefaultData = {
	model: UpgradeModel | CharacterModel | TotemModel;
	bodyArrowOffset?: number;
};

export class EventLevelCharacterUpgradeDefaultHintView extends BaseEventLevelCharacterHintView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly textTitle: MultiColoredTextField;
	private readonly textRarity: MultiColoredTextField;
	private readonly subTitle: MultiColoredTextField;
	private readonly textDescr: MultiColoredTextField;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.arrowDown.y = 288;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 573;
		bg.height = 278;
		bg.y = 144;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const fade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 0, 0, 0, 0);
		fade.width = bg.width + 120;
		fade.height = bg.height + 120;
		fade.y = bg.y;
		fade.tint = 0x000000;
		fade.pivot.set(fade.width / 2, fade.height / 2);

		this.textTitle = new MultiColoredTextField({ font: '37px wendyOneShadowBold' }, 340, 60);
		this.textTitle.anchor = 0.5;
		this.textTitle.position.set(0, 60);

		this.textRarity = new MultiColoredTextField({ font: '25px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textRarity.anchor = 0.5;
		this.textRarity.position.set(0, 92);

		this.subTitle = new MultiColoredTextField({ font: '24px wendyOneShadowBold', align: 'center', tint: 0xb4ccda }, 470, 70);
		this.subTitle.anchor = 0.5;
		this.subTitle.position.set(0, 142);

		this.textDescr = new MultiColoredTextField({ font: '22px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textDescr.anchor = 0.5;
		this.textDescr.position.set(0, 202);

		const textBusinessFade = new PIXI.Graphics();
		textBusinessFade.beginFill(0x30444d);
		textBusinessFade.drawPolygon([
			new PIXI.Point(-122, 20),
			new PIXI.Point(117, 20),
			new PIXI.Point(122, -20),
			new PIXI.Point(-117, -20),
		]);
		textBusinessFade.pivot.set(textBusinessFade.width / 2 - 122, textBusinessFade.height / 2);
		textBusinessFade.position.set(0, 161);

		const textDescrFade = new PIXI.Graphics();
		textDescrFade.beginFill(0x15151d);
		textDescrFade.drawPolygon([
			new PIXI.Point(-250, 41),
			new PIXI.Point(240, 41),
			new PIXI.Point(250, -41),
			new PIXI.Point(-250, -41),
		]);
		textDescrFade.pivot.set(textDescrFade.width / 2 - 250, textDescrFade.height / 2);
		textDescrFade.position.set(0, 243);

		this.addChild(
			fade,
			bg,
			this.arrowUp as PIXI.DisplayObject,
			this.arrowDown,
			textBusinessFade,
			textDescrFade,
			this.textTitle,
			this.textRarity,
			this.subTitle,
			this.textDescr,
		);
	}

	public init(data: EventCharacterUpgradeDefaultData): void {
		const { model } = data;
		this.textTitle.text = this.localizationStorage.getLocalizedString(`#${model.getKey()}_Name`);

		const rarity = model.getCardRarity();
		this.textRarity.tint = CardRarities.getRarityColor(rarity);
		this.textRarity.text = this.localizationStorage.getLocalizedString(`#hint_rarity_${rarity}`);

		if (model instanceof CharacterModel) {
			this.textDescr.text = TextDescriptionHelperCharacter.getDescription(model);
			this.subTitle.text = this.localizationStorage.getLocalizedString(`#${model.getRelatedBusinessKey()}_Name`);
		} else if (model instanceof UpgradeModel) {
			this.textDescr.text = TextDescriptionHelperUpgrade.getTextDescription(model);
			this.subTitle.text = this.localizationStorage.getLocalizedString(`#${model.getRelatedBusinessKey()}_Name`);
		} else if (model instanceof TotemModel) {
			this.textDescr.text = TextDescriptionHelperTotem.getTextDescription(model);
			this.subTitle.text = this.localizationStorage.getLocalizedString('#totem_hint_destination');
		}

		if (data.bodyArrowOffset != null) {
			this.setBgArrowOffset(data.bodyArrowOffset);
		}
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		const scaleStartY = this.y - 40;

		let scaleToY: string;
		if (this.getGlobalPosition().y > GameConstants.GAME_HEIGHT / 2) {
			scaleToY = '-250';
			this.arrowUp.visible = false;
			this.arrowDown.visible = true;
		} else {
			scaleToY = '+40';
			this.arrowUp.visible = true;
			this.arrowDown.visible = false;
		}

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y = scaleStartY;
		new TWEEN.Tween(this)
			.to({ y: scaleToY }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}
}
