export enum MainWindowTarget {
	BANK_BUTTON = 'bank_button',
	COLLECTION_BUTTON = 'collection_button',
	MAX_BUTTON = 'max_button',
	SKILLS_PANEL = 'skills_panel',
	BOOST_BUTTON = 'boost_button',
	PREPARTY_CHARACTER = 'preparty_character',
	DAILY_REWARD_BUTTON = 'daily_reward_button',
	NEWS_BUTTON = 'news_button',
}

export enum MainWindowUnlockType {
	EPISODE_PROGRESS = 'episode_progress',
	CUSTOMERS_ON_BUSINESS = 'episode_progress,customers_on_business',
	EPISODE_TASKS_COMPLETED = 'episode_progress,tasks_completed',
}

export interface UnlockTypeEpisodeProgress {
	level: number;
}

export interface UnlockTypeTasksCompleted {
	level: number,
	tasksProgress: number,
}

export interface UnlockTypeEpisodeProgressCustomersOnBusiness {
	businessKey: string;
	customerCount: number;
	level: number;
}

export interface MainWindowElementConfig {
	target: MainWindowTarget;
	unlockType: MainWindowUnlockType;
	unlockValue: UnlockTypeEpisodeProgress | UnlockTypeEpisodeProgressCustomersOnBusiness | UnlockTypeTasksCompleted;
	showLockFrom?: number;
}

export class MainWindowConfig {
	public static readonly LEVEL_BASE_TARGETS: MainWindowTarget[] = [MainWindowTarget.PREPARTY_CHARACTER];
	public static readonly FIELD_TARGET: string = 'target';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static readonly FIELD_SHOW_LOCK_FROM: string = 'show_lock_from';

	private mainWindowElementsMap: Map<MainWindowTarget, MainWindowElementConfig>;

	constructor(config: { [key: string]: string }[]) {
		this.mainWindowElementsMap = new Map();

		config.forEach((configElement: any) => {
			const target = configElement[MainWindowConfig.FIELD_TARGET].trim() as MainWindowTarget;
			const unlockType = configElement[MainWindowConfig.FIELD_UNLOCK_TYPE].trim() as MainWindowUnlockType;
			const unlockValueString = configElement[MainWindowConfig.FIELD_UNLOCK_VALUE].trim();

			let showLockFrom;
			if (configElement[MainWindowConfig.FIELD_SHOW_LOCK_FROM]) {
				showLockFrom = Number(configElement[MainWindowConfig.FIELD_SHOW_LOCK_FROM].trim());
			}

			let mainWindowElement: MainWindowElementConfig;
			switch (unlockType) {
				case MainWindowUnlockType.EPISODE_PROGRESS: {
					mainWindowElement = {
						target,
						unlockType,
						unlockValue: { level: Number(unlockValueString) },
						showLockFrom,
					};
					break;
				}
				case MainWindowUnlockType.CUSTOMERS_ON_BUSINESS: {
					const [level, businessKey, customerCount] = unlockValueString.split(',').map((str: string) => str.trim());
					mainWindowElement = {
						target,
						unlockType,
						unlockValue: {
							level: Number(level),
							businessKey,
							customerCount: Number(customerCount),
						},
						showLockFrom,
					};
					break;
				}
				case MainWindowUnlockType.EPISODE_TASKS_COMPLETED: {
					const [level, tasksProgress] = unlockValueString.split(',').map(parseFloat);
					mainWindowElement = {
						target,
						unlockType,
						unlockValue: {
							level,
							tasksProgress,
						},
						showLockFrom,
					};
					break;
				}
				default:
					throw new Error(`Unsupported unlockType '${unlockType}'`);
			}

			this.mainWindowElementsMap.set(target, mainWindowElement);
		});
	}

	public getMainWindowElementsMap(): Map<MainWindowTarget, MainWindowElementConfig> {
		return this.mainWindowElementsMap;
	}
}
