import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20009View extends CharacterMainBaseView {
	constructor() {
		super('character20009');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-145, 27);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-90, -48),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-180, -60),
		});

		this.initQuickPhrases(this.key);
	}
}
