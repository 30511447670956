import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120004View } from '@views/characters/events/event12/EventCharacter120004View';
import { EventCharacter120005View } from '@views/characters/events/event12/EventCharacter120005View';
import { EventCharacter120006View } from '@views/characters/events/event12/EventCharacter120006View';

export class EventBusiness120002View extends BusinessBaseView {
	constructor() {
		const eventCharacter120004View = new EventCharacter120004View();
		const eventCharacter120005View = new EventCharacter120005View();
		const eventCharacter120006View = new EventCharacter120006View();

		super('business120002', [eventCharacter120004View, eventCharacter120005View, eventCharacter120006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;
		const decor4 = new PIXI.Sprite(decorationAtlas['b5_decor4']).setTransform(3, 0, 0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
			decor4,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(eventCharacter120004View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter120005View, this.getChildIndex(decor4) + 1);
		this.addChildAt(eventCharacter120006View, this.getChildIndex(decor4) + 1);
	}
}
