import {
	TutorialStepUnlockTypeBaseConfig,
	TutorialStepUnlockTypeLevelStartConfig,
} from '@configs/tutorialSteps/TutorialStepUnlockTypeConfigs';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypeLevelStartModel } from './TutorialStepUnlockTypeLevelStartModel';
import { TutorialStepLevelModel } from '../TutorialStepLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { BusinessModel } from '@models/BusinessModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepUnlockTypeBaseFactory } from './TutorialStepUnlockTypesBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { CharacterModel } from '@models/CharacterModel';

export class TutorialStepUnlockTypeLevelFactory extends TutorialStepUnlockTypeBaseFactory {
	private readonly levelModel: LevelModel;
	private readonly tutorialStepUnlockTypeConfigs: TutorialStepUnlockTypeBaseConfig[];

	constructor(
		windowViewSystem: WindowViewSystem,
		tutorialStepUnlockTypeConfigs: TutorialStepUnlockTypeBaseConfig[],
		levelModel: LevelModel,
		tutorialStepModels: Map<string, TutorialStepLevelModel>,
		businessModels: Map<string, BusinessModel>,
		questLinesModel: QuestLinesModel,
		characterModels: Map<string, CharacterModel>,
	) {
		super(
			windowViewSystem,
			tutorialStepModels,
			businessModels,
			questLinesModel,
			characterModels,
		);

		this.tutorialStepUnlockTypeConfigs = tutorialStepUnlockTypeConfigs;
		this.levelModel = levelModel;
	}

	public createModels(level: number): Map<string, TutorialStepUnlockTypeBaseModel> {
		const result: Map<string, TutorialStepUnlockTypeBaseModel> = new Map();

		const unlockTypeConfigsOfLevel = this.tutorialStepUnlockTypeConfigs.filter((unlockTypeConfig) => {
			const key = unlockTypeConfig.getKey();
			const model = this.tutorialStepModels.get(key);
			return (model as TutorialStepLevelModel).getLevel() === level;
		});
		unlockTypeConfigsOfLevel.forEach((unlockTypeConfig) => {
			const key = unlockTypeConfig.getKey();
			const stepModel = this.tutorialStepModels.get(key);
			if (!stepModel.isCompleted()) {
				const model = this.createModel(unlockTypeConfig);
				result.set(unlockTypeConfig.getKey(), model);
			}
		});

		return result;
	}

	protected createModel(config: TutorialStepUnlockTypeBaseConfig): TutorialStepUnlockTypeBaseModel {
		switch (config.getType()) {
			case TutorialStepUnlockTypes.LEVEL_START: {
				const configLevelStart = config as TutorialStepUnlockTypeLevelStartConfig;
				return new TutorialStepUnlockTypeLevelStartModel(config.getKey(), configLevelStart.getLevel(), this.levelModel);
			}
			default:
				return super.createModel(config);
		}
	}
}
