import * as TWEEN from '@tweenjs/tween.js';
import { WindowBaseView } from './WindowBaseView';
import { SoundController } from '@src/main/SoundController';

export class PopupWindowBaseView extends WindowBaseView {
	public static readonly EVENT_OPEN_ANIMATION_COMPLETE: symbol = Symbol();

	protected closed: boolean;

	private tweenScaleOpen: TWEEN.Tween;
	private tweenAlphaOpen: TWEEN.Tween;
	private tweenScaleClose: TWEEN.Tween;
	private tweenAlphaClose: TWEEN.Tween;

	private withSound: boolean;

	constructor(
		blackoutAlpha: number,
		closeOnBlackoutClick: boolean = true,
		destroyOnClose: boolean = true,
		withSound: boolean = true,
	) {
		super(blackoutAlpha, closeOnBlackoutClick, destroyOnClose);

		this.withSound = withSound;
		this.closed = false;
	}

	public onShown(): void {
		super.onShown();

		this.startOpenAnimation();
	}

	protected onClose(animateClose: boolean = true): void {
		this.stopTweensIfAny();

		if (animateClose) {
			this.startCloseAnimation().then(() => {
				this.visible = false;
				super.onClose();
			});
		} else {
			this.visible = false;
			super.onClose();
		}

		if (this.withSound) {
			SoundController.getInstance().playCloseWindow();
		}
	}

	protected startCloseAnimation(): Promise<void> {
		return new Promise(resolve => {
			this.closed = true;
			this.stopTweensIfAny();

			this.tweenAlphaClose = new TWEEN.Tween(this.mainContainer)
				.to({ alpha: 0 }, 100)
				.start();

			this.tweenScaleClose = new TWEEN.Tween(this.mainContainer.scale)
				.to({ x: 0.8, y: 0.8 }, 100)
				.onComplete(() => {
					this.mainContainer.scale.set(1);
					this.mainContainer.visible = false;

					resolve();
				})
				.start();
		});
	}

	protected startOpenAnimation(): void {
		this.closed = false;
		this.stopTweensIfAny();

		this.visible = true;
		this.mainContainer.visible = true;
		this.mainContainer.scale.set(0.8);
		this.mainContainer.alpha = 0;

		const bounceBack = new TWEEN.Tween(this.mainContainer.scale)
			.to({ x: 1, y: 1 }, 100)
			.onComplete(() => { this.emit(PopupWindowBaseView.EVENT_OPEN_ANIMATION_COMPLETE); });
		this.tweenScaleOpen = new TWEEN.Tween(this.mainContainer.scale)
			.to({ x: 1.1, y: 1.1 }, 100)
			.chain(bounceBack)
			.start();
		this.tweenAlphaOpen = new TWEEN.Tween(this.mainContainer)
			.to({ alpha: 1 }, 50)
			.chain(bounceBack)
			.start();

		if (this.withSound) {
			SoundController.getInstance().playOpenWindow();
		}
	}

	private stopTweensIfAny(): void {
		if (this.tweenAlphaClose) {
			this.tweenAlphaClose.stop();
			this.tweenAlphaClose = null;
		}

		if (this.tweenAlphaOpen) {
			this.tweenAlphaOpen.stop();
			this.tweenAlphaOpen = null;
		}

		if (this.tweenScaleClose) {
			this.tweenScaleClose.stop();
			this.tweenScaleClose = null;
		}

		if (this.tweenScaleOpen) {
			this.tweenScaleOpen.stop();
			this.tweenScaleOpen = null;
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.stopTweensIfAny();
		super.destroy(options);
	}
}
