import LocalizationStorage from '@main/LocalizationStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@main/AssetsStorage';

export class PreloaderBarView extends PIXI.Container {
	private static readonly LOADING_BAR_BG_WIDTH: number = 1920;
	private static readonly LOADING_BAR_BG_HEIGHT: number = 18;

	private readonly loadingBarMask: PIXI.Graphics;
	private readonly localizationStorage: LocalizationStorage;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		const loadingBarBg = new PIXI.Graphics()
			.beginFill(0)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, PreloaderBarView.LOADING_BAR_BG_HEIGHT)
			.endFill();
		loadingBarBg.pivot.set(loadingBarBg.width / 2, loadingBarBg.height / 2);

		const loadingBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getResource('preloader_bar').texture, 0, 0, 0, 0);
		loadingBar.width = PreloaderBarView.LOADING_BAR_BG_WIDTH;
		loadingBar.pivot.set(loadingBar.width / 2, loadingBar.height / 2);

		this.loadingBarMask = new PIXI.Graphics();
		loadingBar.mask = this.loadingBarMask;

		const textLoading = new PIXI.extras.BitmapText('', { font: '28px wendyOneShadowBold' });
		textLoading.anchor = 0.5;
		textLoading.text = this.localizationStorage.getLocalizedString('#preloader_LoadingLabel');

		this.addChild(
			loadingBarBg,
			loadingBar as PIXI.DisplayObject,
			this.loadingBarMask,
			// textLoading,
		);
	}

	public setProgress(value: number): void {
		const width = value * PreloaderBarView.LOADING_BAR_BG_WIDTH;
		this.loadingBarMask.clear();
		this.loadingBarMask.beginFill(0);
		this.loadingBarMask.drawRect(
			-PreloaderBarView.LOADING_BAR_BG_WIDTH / 2,
			-this.height / 2,
			width,
			this.height,
		);
		this.loadingBarMask.endFill();
	}
}
