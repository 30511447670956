export enum BonusTypes {
	DECREASE_CUSTOMER_COST = 'decreaseCustomerCost',
	MULTIPLIER_FUCKPOWER = 'fuckpower',
	MULTIPLIER_INCOME = 'multiplierIncome',
	MULTIPLIER_TIME = 'multiplierTime',
	REDUCE_TIME_INCOME_BY_CLICK = 'reduceTimeIncomeByClick',
	SKILL = 'skill',
	AUTOMATE = 'automate',
	FUCKTIME = 'fucktime',
}

export const BONUS_TYPE_ICON: Record<string, string> = {
	[BonusTypes.MULTIPLIER_INCOME]: 'i_money_big',
	[BonusTypes.MULTIPLIER_TIME]: 'timer_icon',
	[BonusTypes.REDUCE_TIME_INCOME_BY_CLICK]: 'tap_power_icon',
	[BonusTypes.DECREASE_CUSTOMER_COST]: 'icon_customer',
};