import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { ConfigSetElementConstructor } from '@interfaces/ConfigSetElementConstructor';
import { BaseConfigSetParser } from './BaseConfigSetParser';

export class EventLevelBaseConfigSetParser<T extends ConfigSetElement> {
	public static createConfig<T extends ConfigSetElement>(
		ConfigConstructor: ConfigSetElementConstructor<T>,
		configJSON: { [key: string]: string },
	): T {
		return new ConfigConstructor(configJSON);
	}

	protected readonly ConfigConstructor: ConfigSetElementConstructor<T>;
	protected readonly configConstructorGetter: (config: { [key: string]: string }) => ConfigSetElementConstructor<T>;
	protected configSetKeyField: string;
	protected fieldEventLevelKey: string;

	protected configSet: Map<string, Map<string, T>>;
	protected configSetOfAllEventLevels: Map<string, T>;

	constructor(
		configSetKeyField: string,
		ConfigConstructor?: ConfigSetElementConstructor<T>,
		configConstructorGetter?: (config: { [key: string]: string }) => ConfigSetElementConstructor<T>,
		fieldEventLevelKey?: string,
	) {
		this.configSetKeyField = configSetKeyField;
		this.ConfigConstructor = ConfigConstructor;
		this.configConstructorGetter = configConstructorGetter;
		this.fieldEventLevelKey = fieldEventLevelKey || 'event';

		this.configSet = new Map();
		this.configSetOfAllEventLevels = new Map();
	}

	public parseConfigSet(configSetJSON: any[]): void {
		const ConfigConstructor = this.configConstructorGetter || (() => this.ConfigConstructor);

		configSetJSON.forEach((configJSON: any) => {
			const configId = configJSON[this.configSetKeyField].trim();
			const config = BaseConfigSetParser.createConfig(
				ConfigConstructor(configJSON),
				configJSON,
			);

			const configEventLevelId = configJSON[this.fieldEventLevelKey].trim();
			if (this.configSet.has(configEventLevelId)) {
				const configsOfThisEventLevelId = this.configSet.get(configEventLevelId);
				configsOfThisEventLevelId.set(configId, config);
			} else {
				const configsOfThisEventLevelId = new Map();
				configsOfThisEventLevelId.set(configId, config);
				this.configSet.set(configEventLevelId, configsOfThisEventLevelId);
			}

			this.configSetOfAllEventLevels.set(configId, config);
		});
	}

	public getConfig(eventLevelKey: string, key: string): T {
		return this.configSet.get(eventLevelKey).get(key);
	}

	public getConfigSet(eventLevelKey: string): Map<string, T> {
		return this.configSet.get(eventLevelKey);
	}

	public getConfigSetOfAllEventLevels(): Map<string, T> {
		return this.configSetOfAllEventLevels;
	}

	public clear(): void {
		this.configSet.clear();
		this.configSetOfAllEventLevels.clear();
	}
}
