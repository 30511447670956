import { AssetsStorage } from '@main/AssetsStorage';

export class ButtonFadeView extends PIXI.Container {
	constructor(
	) {
		super();
		const fade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow_half']);
		fade.tint = 0x000000;
		fade.alpha = 0.8;
		fade.setTransform(0, 0, 8, 10);

		this.addChild(fade);
	}
}
