import * as TWEEN from '@tweenjs/tween.js';

export class BeginningCountdownView extends PIXI.Container {
	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	private readonly values: PIXI.extras.BitmapText[];

	constructor() {
		super();

		this.values = [
			new PIXI.extras.BitmapText('LET\'S FUCK!', { font: '140px wendyOneGold' }),
			new PIXI.extras.BitmapText('1', { font: '140px wendyOneGold' }),
			new PIXI.extras.BitmapText('2', { font: '140px wendyOneGold' }),
			new PIXI.extras.BitmapText('3', { font: '140px wendyOneGold' }),
		];
		this.values.forEach((value) => {
			value.anchor = 0.5;
			value.visible = false;
		});

		this.addChild(
			...this.values,
		);
	}

	public start(): void {
		this.showAnimation(this.values.pop());
	}

	private showAnimation(target: PIXI.extras.BitmapText, prevTarget?: PIXI.extras.BitmapText): void {
		if (prevTarget) {
			prevTarget.visible = false;
		}

		target.scale.set(0);
		target.visible = true;

		new TWEEN.Tween(target.scale)
			.to({ x: 1, y: 1 }, 500)
			.easing(TWEEN.Easing.Quartic.In)
			.onComplete(() => {
				if (this.values.length > 0) {
					setTimeout(() => { this.showAnimation(this.values.pop(), target); }, 300);
				} else {
					setTimeout(() => { this.complete(); }, 300);
				}
			})
			.start();
	}

	private complete(): void {
		this.emit(BeginningCountdownView.EVENT_TIMEOUT);

		this.destroy({ children: true });
	}
}
