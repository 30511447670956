import { EventIncomeProgressBarView } from './EventIncomeProgressBarView';
import { EventGoalProgressBarView } from './EventGoalProgressBarView';
import { EventTimerView } from './EventTimerView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { EventButtonRankingWindowView } from './EventButtonRankingWindowView';
import { EventGoalRewardIconView } from './EventGoalRewardIconView';
import { CardMiniViewFactory } from '../cardsMini/CardMiniViewFactory';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { AbstractReward } from '@interfaces/AbstractReward';

export class EventLevelPanelView extends PIXI.Container {
	public static readonly EVENT_GOAL_REWARD_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CUP_CLICK: symbol = Symbol();

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly eventLevelModel: EventLevelModel;

	private readonly eventIncomeProgressView: EventIncomeProgressBarView;
	private readonly eventGoalsProgressBar: EventGoalProgressBarView;
	private readonly eventTimerView: EventTimerView;
	private readonly buttonRankingWindow: ButtonBaseView;
	private readonly eventGoalRewardIconView: EventGoalRewardIconView;

	constructor(
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
	) {
		super();

		this.eventLevelModel = eventLevelModel;
		this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS_INCOME, this.updateIncomeProgress, this);
		this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS_COMPLETE, this.updateGoalProgress, this);

		this.ticker = PIXI.ticker.shared;

		this.buttonRankingWindow = new EventButtonRankingWindowView();
		this.buttonRankingWindow.on(
			EventButtonRankingWindowView.EVENT_CLICK,
			() => this.emit(EventLevelPanelView.EVENT_BUTTON_CUP_CLICK, this),
			this,
		);

		this.eventTimerView = new EventTimerView();
		this.eventTimerView.position.set(83, -25);

		this.eventIncomeProgressView = new EventIncomeProgressBarView();
		this.eventIncomeProgressView.position.set(189, -1);

		this.eventGoalsProgressBar = new EventGoalProgressBarView();
		this.eventGoalsProgressBar.position.set(185, 21);
		this.eventGoalsProgressBar.setTotalProgress(this.eventLevelModel.getGoals().length);

		this.eventGoalRewardIconView = new EventGoalRewardIconView(
			cardMiniViewFactory,
			runInEpisodeAssetsSandbox,
		);
		this.eventGoalRewardIconView.setReward(this.eventLevelModel.getTargetGoalRewards()[0]);
		this.eventGoalRewardIconView.position.set(400, -5);
		this.eventGoalRewardIconView.on(
			EventGoalRewardIconView.EVENT_CLICK,
			() => this.emit(EventLevelPanelView.EVENT_GOAL_REWARD_CLICK),
			this,
		);

		this.ticker.add(this.onTimerUpdate, this);

		this.updateIncomeProgress();
		this.updateGoalProgress(false);

		this.addChild(
			this.eventTimerView as PIXI.DisplayObject,
			this.eventIncomeProgressView,
			this.eventGoalsProgressBar,
			this.buttonRankingWindow,
			this.eventGoalRewardIconView,
		);
	}

	public setNewGoalRewardWithAnimation(
		reward: AbstractReward,
	): Promise<void> {
		this.updateGoalProgress();

		const newGoalRewardAnimationPromise = this.eventGoalRewardIconView.setNewRewardWithAnimation(
			reward,
		);
		return newGoalRewardAnimationPromise;
	}

	private onTimerUpdate(): void {
		const timeleftFormatted = NumberUtils.secToDHMSColonFormatted(this.eventLevelModel.getTimeleft());
		this.eventTimerView.setTextTimer(timeleftFormatted);
	}

	private updateIncomeProgress(): void {
		const currentProgress = this.eventLevelModel.getCurrentGoalProgress();
		const totalProgress = this.eventLevelModel.getTargetGoalProgress();
		const deltaProgress = Number(currentProgress.divide(totalProgress).toRawString(2));
		this.eventIncomeProgressView.setProgress(
			currentProgress.toString(),
			totalProgress.toString(),
			Math.min(1, deltaProgress),
		);
	}

	private updateGoalProgress(playProgressAnimation: boolean = true): void {
		this.eventGoalsProgressBar.setProgress(
			this.eventLevelModel.getCompletedGoalCount(),
			playProgressAnimation,
		);
	}

	public getEventGoalRewardIconContainer(): PIXI.Container {
		return this.eventGoalRewardIconView;
	}

	public getEventIncomeProgressBarContainer(): PIXI.Container {
		return this.eventIncomeProgressView;
	}

	public getButtonRankingWindowContainer(): PIXI.Container {
		return this.buttonRankingWindow;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.onTimerUpdate, this);

		this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS_INCOME, this.updateIncomeProgress, this);
		this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS_COMPLETE, this.updateGoalProgress, this);

		super.destroy(options);
	}
}
