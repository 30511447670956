import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter253001View extends CharacterMainBaseView {
	constructor() {
		super('character253001');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-152, 63);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-120, -18),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-100, -21),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
