import { TutorialStepBaseModel } from './TutorialStepBaseModel';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';

export class TutorialStepLevelModel extends TutorialStepBaseModel {
	private readonly level: number;

	constructor(config: TutorialStepLevelConfig) {
		super(config);

		this.level = config.getLevel();
	}

	public getLevel(): number {
		return this.level;
	}
}
