import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter254001View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254001View';
import { EventCharacter254002View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254002View';
import { EventCharacter254003View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254003View';

export class EventBusiness254001View extends BusinessBaseView {
	constructor() {
		const eventCharacter254001View = new EventCharacter254001View();
		const eventCharacter254002View = new EventCharacter254002View();
		const eventCharacter254003View = new EventCharacter254003View();
		eventCharacter254003View.once(EventCharacter254003View.EVENT_SHOWN, () => eventCharacter254001View.destroy());

		super('business254001', [eventCharacter254001View, eventCharacter254002View, eventCharacter254003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter254001View as PIXI.DisplayObject,
			eventCharacter254002View,
			eventCharacter254003View,
		);
	}
}
