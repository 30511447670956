import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character7View } from '@views/characters/main/Character7View';
import { Character8View } from '@views/characters/main/Character8View';
import { Character9View } from '@views/characters/main/Character9View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business3View extends BusinessBaseView {
	constructor() {
		const character7 = new Character7View();
		const character8 = new Character8View();
		const character9 = new Character9View();
		super('business3', [character7, character8, character9]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character7 as PIXI.DisplayObject,
			character8,
			character9,
		);
	}
}
