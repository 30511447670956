import { BusinessModel } from '@models/BusinessModel';
import { BusinessPropertyView, BusinessPropertyData } from './BusinessPropertyView';
import { UpgradeModel } from '@models/UpgradeModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { CharacterModel } from '@models/CharacterModel';
import { DefaultHintViewData } from '@views/ui/hints/HintDefaultView';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class BusinessPropertiesView extends PIXI.Container {
	public static readonly EVENT_PROPERTY_CLICK = Symbol();

	constructor(
		iconsOffset: number,
		private readonly model: BusinessModel,
		private readonly upgrades: UpgradeModel[],
		private readonly characters: CharacterModel[],
	) {
		super();

		const data = this.getPropertiesData();
		const hintData: DefaultHintViewData[] = [
			{ title: '#hint_multiplier_income_title', description: '#hint_multiplier_income_descr', bodyArrowOffset: 0.3 },
			{ title: '#hint_multiplier_time_title', description: '#hint_multiplier_time_descr', bodyArrowOffset: 0.49 },
			{ title: '#hint_multiplier_tap_power_title', description: '#hint_multiplier_tap_power_descr', bodyArrowOffset: 0.68 },
			{ title: '#hint_customer_cost_title', description: '#hint_customer_cost_descr', bodyArrowOffset: 0.87 },
		];

		data.forEach((dataItem, i) => {
			const infoElement = new BusinessPropertyView(dataItem);
			infoElement.setTransform(-340 + iconsOffset * i, 0);

			infoElement.on('pointertap', () => {
				this.emit(BusinessPropertiesView.EVENT_PROPERTY_CLICK, hintData[i], infoElement);
			});

			this.addChild(infoElement);
		});

		this.model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.updateProperties, this);
		this.model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.updateProperties, this);
		this.model.on(BusinessModel.EVENT_REDUCE_TIME_INCOME_PER_TAP_CHANGED, this.updateProperties, this);

		this.upgrades.forEach((upgrade) => {
			upgrade.on(UpgradeModel.EVENT_ACTIVATED, this.updateProperties, this);
			upgrade.on(UpgradeModel.EVENT_PROMOTED, this.updateProperties, this);
		});

		this.characters.forEach((character) => {
			character.on(CharacterModel.EVENT_ACTIVATED, this.updateProperties, this);
			character.on(CharacterModel.EVENT_PROMOTED, this.updateProperties, this);
		});
	}

	private updateProperties(): void {
		const data = this.getPropertiesData();

		data.forEach((dataItem, i) => {
			(this.children[i] as BusinessPropertyView).setText(dataItem.value);
		});
	}

	private getPropertiesData(): BusinessPropertyData[] {
		const multiplierIncome = SoftMoneyNumber.createFromNumber(this.model.getMultiplierIncome()).toString();
		const multiplierTime = SoftMoneyNumber.createFromNumber(this.model.getMultiplierTime()).toString();

		return [
			{
				icon: new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']).setTransform(0, 0, 0.8, 0.8) as PIXI.Sprite,
				value: `x${multiplierIncome}`,
			},
			{
				icon: new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']).setTransform(0, 0, 1, 1) as PIXI.Sprite,
				value: `x${multiplierTime}`,
			},
			{
				icon: new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['tap_power_icon']).setTransform(0, 0, 0.9, 0.9) as PIXI.Sprite,
				value: `x${this.model.getReduceTimeIncomePerTapMultiplier()}`,
			},
			{
				icon: new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['icon_customer']).setTransform(0, 0, 0.8, 0.8) as PIXI.Sprite,
				value: `x${this.getCustomerDiscount()}`,
			},
		];
	}

	private getCustomerDiscount(): number {
		let customerDiscount = 0;
		const upgradesDecreaseCustomerCost = this.upgrades
			.filter((upgrade) => upgrade.hasBonusDecreaseCustomerCostCoef() && upgrade.isActivated())
			.map((upgrade) => upgrade.getDecreaseCustomerCostCoef());
		const charactersDecreaseCustomerCost = this.characters
			.filter((character) => character.hasBonusDecreaseCustomerCostCoef() && character.isActivated())
			.map((character) => character.getDecreaseCustomerCostCoef());

		if (upgradesDecreaseCustomerCost.length > 0) {
			customerDiscount += upgradesDecreaseCustomerCost.reduce((a, b) => a + b);
		}

		if (charactersDecreaseCustomerCost.length > 0) {
			customerDiscount += charactersDecreaseCustomerCost.reduce((a, b) => a + b);
		}

		return Math.max(1, customerDiscount);
	}
}
