import { DailyBonusType, DailyRewardConfig } from '@configs/DailyRewardConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { PresetsModel } from '@models/PresetsModel';
import { HintDataReward, HintTypes } from '@src/types/HintTypes';
import { ScrollAxis } from '@src/types/ScrollViewTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { NumberUtils } from '@src/utils/NumberUtils';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { ViewUtils } from '@src/utils/ViewUtils';
import * as TWEEN from '@tweenjs/tween.js';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ScrollView } from '@views/components/ScrollView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { BaseDailyCardView } from '@views/ui/cardsDaily/BaseDailyCardView';
import { CardDailyViewFactory } from '@views/ui/cardsDaily/CardDailyViewFactory';
import { CharacterDailyCardView } from '@views/ui/cardsDaily/CharacterDailyCardView';
import { PromotableBaseDailyCardView } from '@views/ui/cardsDaily/PromotableBaseDailyCardView';

export class DailyWindowBaseView extends WindowBaseView {
	public static readonly EVENT_SHOW_HINT = Symbol();
	public static readonly EVENT_REWARD_COLLECTED = Symbol();

	private static readonly CARDS_PER_ROW = 5;

	private readonly localizationStorage: LocalizationStorage;
	private readonly timerString: MultiColoredTextField;
	private readonly timerLocString: string;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;
	private hintContent: PIXI.Container;
	private closeButton: ButtonBaseView;
	private rewardsGrid: PIXI.Container;
	private titleString: SizeableBitmapText;

	constructor(
		private readonly cardDailyViewFactory: CardDailyViewFactory,
		private readonly dailyRewardsModel: DailyRewardsModel,
		private readonly presetsModel: PresetsModel,
	) {
		super(0.7, false);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.timerLocString = this.localizationStorage.getLocalizedString('#daily_reward_timer_label');

		this.tweenGroup = new TWEEN.Group();
		this.hintContent = new PIXI.Container();

		const bg = PIXI.Sprite.fromImage('dailyBg');
		bg.interactive = true;

		bg.on('pointertap', this.onBgTap, this);

		this.titleString = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#daily_reward_title_label'),
			700,
			{ font: '42px wendyOne', align: 'center', tint: 0xf9de7e },
		);
		this.titleString.pivot.set(this.titleString.width / 2, this.titleString.height / 2);
		this.titleString.position.set(
			GameConstants.GAME_WIDTH / 2,
			150,
		);
		this.titleString.scale.x = 1.1;

		this.timerString = new MultiColoredTextField(
			{ font: '30px wendyOne', align: 'center' },
			700,
		);
		this.timerString.position.set(
			GameConstants.GAME_WIDTH / 2,
			174,
		);
		this.timerString.anchor = 0.5;
		this.timerString.scale.set(0);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.updateTimerString();
		this.timerString.pivot.set(this.timerString.width / 2, this.timerString.height / 2);

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.position.set(
			GameConstants.GAME_WIDTH / 2,
			145,
		);
		episodeNamePanelFade.width = 873;
		episodeNamePanelFade.height = 105;
		episodeNamePanelFade.tint = 0x000000;

		const glowLine = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine.position.set(GameConstants.GAME_WIDTH / 2, 103);
		const glowLine2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine2.position.set(GameConstants.GAME_WIDTH / 2, 197);

		this.closeButton = new ButtonBaseView(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['back_icon2']));
		this.closeButton.position.set(55, 55);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.updateBackBtnVisible(!this.dailyRewardsModel.isRewardClaimable());

		const girl1 = new PIXI.Sprite(AssetsStorage.getResource(`${this.dailyRewardsModel.getLeftCharacterKey()}_offer`).texture);
		girl1.pivot.set(girl1.width / 2, girl1.height / 2);
		girl1.position.set(132, 666);
		girl1.scale.set(0.9);
		new TWEEN.Tween(girl1, this.tweenGroup)
			.to({ x: '+100' }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();

		const girl2 = new PIXI.Sprite(AssetsStorage.getResource(`${this.dailyRewardsModel.getRightCharacterKey()}_offer`).texture);
		girl2.pivot.set(girl2.width / 2, girl2.height / 2);
		girl2.position.set(1848, 666);
		girl2.scale.set(0.9, 0.9);
		new TWEEN.Tween(girl2, this.tweenGroup)
			.to({ x: '-100' }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();

		this.addChild(
			bg as PIXI.DisplayObject,
			this.closeButton,
			episodeNamePanelFade,
			glowLine,
			glowLine2,
			this.titleString,
			this.timerString,
			girl1,
			girl2,
			this.hintContent,
		);

		this.addRewardsGrid();
		this.dailyRewardsModel.on(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED, this.addRewardsGrid, this);
	}

	private showGridAnim(content: PIXI.Container): void {
		content.y += 200;
		content.alpha = 0;
		new TWEEN.Tween(content, this.tweenGroup)
			.to({ y: '-200' }, 200)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();

		new TWEEN.Tween(content, this.tweenGroup)
			.to({ alpha: 1 }, 300)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	private show30DaysFadesPanel(): void {
		const scrollUpFade = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['daily_up_fade']);
		scrollUpFade.width = 1214;
		scrollUpFade.position.set(GameConstants.GAME_CENTER_X, 230);
		const scrollDawnFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h']);
		scrollDawnFade.rotation = Math.PI / 180 * -90;
		scrollDawnFade.width = 80;
		scrollDawnFade.height = GameConstants.GAME_WIDTH;
		scrollDawnFade.tint = 0x000000;
		scrollDawnFade.position.set(GameConstants.GAME_CENTER_X, 1050);

		this.hintContent.addChild(
			scrollUpFade,
			scrollDawnFade as PIXI.DisplayObject,
		);
	}

	private show7DaysHintPanel(): void {
		const panelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		panelFade.position.set(
			GameConstants.GAME_WIDTH / 2,
			987,
		);
		panelFade.width = 1560;
		panelFade.height = 89;
		panelFade.tint = 0x000000;
		panelFade.alpha = 0.45;

		const hintStr = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#daily_reward_click_label'),
			700,
			{ font: '38px wendyOneShadowBold', align: 'center', tint: 0xf9de7e },
		);
		hintStr.pivot.set(hintStr.width / 2, hintStr.height / 2);
		hintStr.position.set(
			GameConstants.GAME_WIDTH / 2,
			995,
		);
		hintStr.scale.x = 1.1;

		this.hintContent.addChild(
			panelFade,
			hintStr as PIXI.DisplayObject,
		);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
		this.updateTimerString();
	}

	private updateTimerString(): void {
		const time = this.dailyRewardsModel.getNextRewardPeriod();
		const timeStr = NumberUtils.formatCountdownTime(time);

		this.timerString.text = `${this.timerLocString}: [f9de7e]${timeStr}[-]`;
		this.timerString.visible = !this.dailyRewardsModel.isRewardClaimable();

		this.updateTitleStr(this.timerString.visible);
	}

	private updateTitleStr(withTimer: boolean): void {
		if (withTimer) {
			new TWEEN.Tween(this.timerString.scale, this.tweenGroup)
				.to({ x: 1, y: 1 }, 100)
				.easing(TWEEN.Easing.Cubic.Out)
				.start();

			new TWEEN.Tween(this.titleString, this.tweenGroup)
				.to({ y: 135 }, 100)
				.easing(TWEEN.Easing.Cubic.Out)
				.start();
		} else {
			new TWEEN.Tween(this.titleString, this.tweenGroup)
				.to({ y: 150 }, 100)
				.easing(TWEEN.Easing.Cubic.Out)
				.start();
		}
	}

	private createRewardsGrid(): PIXI.Container {
		const rewardMargin = 26;
		const newLabelOffset = 40;
		const rewards = this.dailyRewardsModel.getItems();
		const bonusType = this.dailyRewardsModel.getCurrentBonusType();
		let result: PIXI.Container;

		if (bonusType === DailyBonusType.MAIN) {
			const grid = this.create30DaysGrid(rewards, rewardMargin);

			this.show30DaysFadesPanel();

			const beginOffset = BaseDailyCardView.DEFAULT_HEIGHT / 2 + newLabelOffset;

			const gridScroll = new ScrollView(
				grid.width + newLabelOffset,
				BaseDailyCardView.DEFAULT_HEIGHT * 3,
				ScrollAxis.VERTICAL,
				{
					begin: beginOffset,
					end: 0,
				},
			);
			gridScroll.setContent(grid);

			const currentRow = Math.floor((this.dailyRewardsModel.getCurrentDay() - 1) / DailyWindowBaseView.CARDS_PER_ROW);
			gridScroll.jumpTo(beginOffset - (BaseDailyCardView.DEFAULT_HEIGHT + rewardMargin) * currentRow);

			gridScroll.position.set(
				GameConstants.GAME_CENTER_X - grid.width / 2 + newLabelOffset / 2,
				GameConstants.GAME_CENTER_Y - BaseDailyCardView.DEFAULT_HEIGHT,
			);

			result = gridScroll;
		} else if (bonusType === DailyBonusType.NOVICE
			|| bonusType === DailyBonusType.REENGAGEMENT) {
			const grid = this.create7DaysGrid(rewards, rewardMargin);

			grid.position.set(
				GameConstants.GAME_CENTER_X + BaseDailyCardView.DEFAULT_WIDTH / 2 + newLabelOffset / 2,
				GameConstants.GAME_CENTER_Y - BaseDailyCardView.DEFAULT_HEIGHT / 2,
			);

			if (this.dailyRewardsModel.isRewardClaimable()) {
				this.show7DaysHintPanel();
			}

			result = grid;
		} else {
			throw new Error(`Unknown daily reward bonus type: ${bonusType}`);
		}

		return result;
	}

	private create30DaysGrid(
		rewards: DailyRewardConfig[],
		rewardMargin: number,
	): PIXI.Container {
		const grid = new PIXI.Container();
		const rows: PIXI.Container[][] = [];

		rewards.forEach((reward, rewardIndex) => {
			const rowIndex = Math.floor(rewardIndex / DailyWindowBaseView.CARDS_PER_ROW);
			const card = this.createDailyCard(reward);

			if (rows[rowIndex] == null) {
				rows[rowIndex] = [];
			}

			card.x += (BaseDailyCardView.DEFAULT_WIDTH + rewardMargin) * DailyWindowBaseView.CARDS_PER_ROW / 2;
			card.y = rowIndex * (card.height + Math.min(rowIndex, 1) * rewardMargin + 5);

			rows[rowIndex].push(card);
		});

		rows.forEach((cards) => {
			ViewUtils.positionLayoutHorizontal(
				cards,
				rewardMargin,
			);

			this.updateCardsStickers(cards);

			grid.addChild(...cards);
		});

		return grid;
	}

	private create7DaysGrid(
		rewards: DailyRewardConfig[],
		rewardMargin: number,
	): PIXI.Container {
		const grid = new PIXI.Container();

		const cards = rewards.map((reward, index) => this.createDailyCard(reward, index === 6));

		const row1Cards = cards.slice(0, 3);
		const row2Cards = cards.slice(3, 6);
		const lastCard = cards[6];

		ViewUtils.positionLayoutHorizontal(
			row1Cards,
			rewardMargin,
		);

		ViewUtils.positionLayoutHorizontal(
			row2Cards,
			rewardMargin,
		);

		row2Cards.forEach((card) => {
			card.y = BaseDailyCardView.DEFAULT_HEIGHT + rewardMargin + 5;
		});

		const rowsContainer = new PIXI.Container();

		rowsContainer.addChild(
			...row1Cards,
			...row2Cards,
		);

		lastCard.y = BaseDailyCardView.DEFAULT_HEIGHT / 2 + 15;

		ViewUtils.positionLayoutHorizontal(
			[rowsContainer, lastCard],
			rewardMargin,
		);

		this.updateCardsStickers(row1Cards.concat(row2Cards).concat([lastCard]));

		grid.addChild(
			rowsContainer,
			lastCard,
		);

		return grid;
	}

	private createDailyCard(
		rewardModel: DailyRewardConfig,
		isExtra = false,
	): BaseDailyCardView {
		const cardScale = 1;

		const count = rewardModel.getRewardCount();
		const rewardDescription = {
			/* eslint-disable @typescript-eslint/naming-convention */
			reward_type: rewardModel.getRewardType(),
			reward_id: rewardModel.getRewardId(),
			reward_qty: this.presetsModel.parsePresetValue(count),
			/* eslint-enable @typescript-eslint/naming-convention */
		};

		const card = this.cardDailyViewFactory.createRewardCard(
			rewardModel,
			cardScale,
			cardScale,
			CardDailyViewFactory.createStickerNew(),
		);

		if (isExtra) {
			card.setExtraRewardMode();
		}

		if (rewardModel.getDay() === this.dailyRewardsModel.getCurrentDay()) {
			if (this.dailyRewardsModel.isRewardClaimable()) {
				card.setCanCollect();
			} else {
				card.setCollected();
			}
		} else if (rewardModel.getDay() < this.dailyRewardsModel.getCurrentDay()) {
			card.setCollected();
		}

		card.on('pointertap', () => {
			const isCurrent = rewardModel.getDay() === this.dailyRewardsModel.getCurrentDay();

			if (isCurrent && this.dailyRewardsModel.isRewardClaimable()) {
				card.setCollected();
				this.emit(DailyWindowBaseView.EVENT_REWARD_COLLECTED, rewardModel);
				this.updateBackBtnVisible(true);
				this.hintContent.visible = false;

				if (card instanceof PromotableBaseDailyCardView
					|| card instanceof CharacterDailyCardView) {
					(card as PromotableBaseDailyCardView | CharacterDailyCardView).removeSticker();
				}
			} else {
				const hintData: HintDataReward = {
					rewardDescription,
					origin: card,
				};
				this.emit(DailyWindowBaseView.EVENT_SHOW_HINT, HintTypes.REWARD_CARD, hintData);
			}
		});

		let labelCountString: string;

		if (typeof rewardDescription.reward_qty === 'number') {
			labelCountString = `x${SoftMoneyNumber.createFromNumber(rewardDescription.reward_qty).toString(3, 5)}`;
		} else if (typeof rewardDescription.reward_qty === 'string') {
			labelCountString = `x${SoftMoneyNumber.createFromString(rewardDescription.reward_qty).toString(3, 5)}`;
		}

		card.updateCountString(labelCountString);
		card.scale.set(cardScale, cardScale);

		return card;
	}

	// eslint-disable-next-line class-methods-use-this
	private updateCardsStickers(cards: PIXI.Container[]): void {
		cards.forEach((card) => {
			if (card instanceof CharacterDailyCardView
				|| card instanceof PromotableBaseDailyCardView) {
				(card as (CharacterDailyCardView | PromotableBaseDailyCardView)).tryAddSticker();
			}
		});
	}

	private updateBackBtnVisible(value: boolean): void {
		this.closeButton.visible = value;
	}

	private onBgTap(): void {
		if (!this.dailyRewardsModel.isRewardClaimable()) {
			this.onClose();
		}
	}

	private addRewardsGrid(): void {
		if (this.rewardsGrid != null) {
			this.removeChild(this.rewardsGrid);
		}

		this.rewardsGrid = this.createRewardsGrid();
		this.addChild(this.rewardsGrid);
		this.showGridAnim(this.rewardsGrid);
	}

	public destroy(): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		this.dailyRewardsModel.off(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED, this.addRewardsGrid, this);

		super.destroy();
	}
}
