export class AFKTicker extends PIXI.utils.EventEmitter {
	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	private readonly timeoutDate: Date;

	private afkTimeMS: number;
	private intervalId: number;

	constructor() {
		super();

		this.afkTimeMS = 0;
		this.timeoutDate = new Date();
	}

	public init(
		afkTimeSec: number,
	): void {
		this.afkTimeMS = afkTimeSec * 1000;
		this.resetTimeoutDate();

		this.intervalId = window.setInterval(this.onTickerUpdate.bind(this), 1000);
	}

	public reset(): void {
		this.resetTimeoutDate();
	}

	private resetTimeoutDate(): void {
		const currentDate = new Date();
		this.timeoutDate.setTime(currentDate.getTime() + this.afkTimeMS);
	}

	private onTickerUpdate(): void {
		const currentDate = new Date();

		if (currentDate.getTime() > this.timeoutDate.getTime()) {
			this.stopIfAny();
			this.emit(AFKTicker.EVENT_TIMEOUT, this);
		}
	}

	private stopIfAny(): void {
		if (this.intervalId) {
			window.clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}
}
