import { BaseAction } from './BaseAction';

export class ActivateOfferAction extends BaseAction {
	constructor(
		offerKey: string,
		endTime: number,
	) {
		super('OfferActivate');

		this.data.offer_id = offerKey;
		this.data.end_ts = endTime;
	}
}
