export class FarewellPartyEffectsConfigs {
	public static readonly GLOW = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 8,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ff9d00',
			end: '#ffffff',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -5,
			max: 5,
		},
		lifetime: {
			min: 5,
			max: 8,
		},
		blendMode: 'add',
		frequency: 0.3,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly GLOW2 = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 9,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ffff00',
			end: '#ffff00',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -30,
			max: 30,
		},
		lifetime: {
			min: 3,
			max: 6,
		},
		blendMode: 'add',
		frequency: 2,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly GLOW3 = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 8,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ff9d00',
			end: '#ffffff',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -5,
			max: 5,
		},
		lifetime: {
			min: 5,
			max: 8,
		},
		blendMode: 'add',
		frequency: 0.3,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly GLOW4 = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 9,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ffffff',
			end: '#ffffff',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -30,
			max: 30,
		},
		lifetime: {
			min: 3,
			max: 6,
		},
		blendMode: 'add',
		frequency: 2,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly SPARKS = {
		alpha: {
			start: 0.55,
			end: 0,
		},
		scale: {
			start: 0.2,
			end: 0.001,
			minimumScaleMultiplier: 5,
		},
		color: {
			start: '#ffc814',
			end: '#ffffff',
		},
		speed: {
			start: 100,
			end: 50,
			minimumSpeedMultiplier: 2,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 2,
			max: 3,
		},
		blendMode: 'add',
		frequency: 0.1,
		emitterLifetime: -1,
		maxParticles: 30,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'circle',
		spawnCircle: {
			x: 0,
			y: 0,
			r: 20,
		},
	};

	public static readonly SPARKS_AIM_CLICK = {
		alpha: {
			start: 1,
			end: 0,
		},
		scale: {
			start: 0.2,
			end: 0.01,
			minimumScaleMultiplier: 7,
		},
		color: {
			start: '#ffb514',
			end: '#ffffff',
		},
		speed: {
			start: 300,
			end: 0,
			minimumSpeedMultiplier: 2.5,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.6,
			max: 1,
		},
		blendMode: 'add',
		frequency: 0.002,
		emitterLifetime: 0.02,
		maxParticles: 100,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'burst',
		particlesPerWave: 1,
		particleSpacing: 0,
		angleStart: 0,
	};

	public static readonly AIM_TRAIL = {
		autoUpdate: true,
		alpha: {
			start: 0.8,
			end: 0.01,
		},
		scale: {
			start: 0.2,
			end: 0.01,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#e3f9ff',
			end: '#2196F3',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 0,
		},
		noRotation: true,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.6,
			max: 0.6,
		},
		blendMode: 'add',
		frequency: 0.0008,
		emitterLifetime: -1,
		maxParticles: 5000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly AIM_GLOW1 = {
		alpha: {
			start: 1,
			end: 0.46,
		},
		scale: {
			start: 0.5,
			end: 0.1,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#ffe88c',
			end: '#ffc70f',
		},
		speed: {
			start: 200,
			end: 50,
			minimumSpeedMultiplier: 0.001,
		},
		acceleration: {
			x: -50,
			y: 10,
		},
		maxSpeed: 200,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.5,
			max: 1,
		},
		blendMode: 'add',
		frequency: 0.0005,
		emitterLifetime: 0.01,
		maxParticles: 20,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'circle',
		spawnCircle: {
			x: 0,
			y: 0,
			r: 10,
		},
	};

	public static readonly AIM_GLOW2 = {
		alpha: {
			start: 0.5,
			end: 0,
		},
		scale: {
			start: 0.3,
			end: 1,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#fff7ad',
			end: '#ffd382',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 0,
		},
		noRotation: false,
		rotationSpeed: {
			min: 30,
			max: 60,
		},
		lifetime: {
			min: 0.3,
			max: 0.3,
		},
		blendMode: 'add',
		frequency: 0.1,
		emitterLifetime: 0.2,
		maxParticles: 10,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'burst',
		particlesPerWave: 1,
		particleSpacing: 45,
		angleStart: 0,
	};
}
