import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class LeagueBotConfig implements ConfigSetElement {
	public static readonly FIELD_KEY = 'id';
	public static readonly FIELD_A = 'a';
	public static readonly FIELD_C = 'c';
	public static readonly FIELD_D = 'd';
	public static readonly FIELD_MIN_SCORE = 'min_score';

	private readonly key: string;
	private readonly coefA: number;
	private readonly coefC: number;
	private readonly coefD: number;
	private readonly minScore: SoftMoneyNumber;

	constructor(config: { [key: string]: string }) {
		this.key = config[LeagueBotConfig.FIELD_KEY].trim();

		this.coefA = Number(config[LeagueBotConfig.FIELD_A].trim());
		this.coefC = Number(config[LeagueBotConfig.FIELD_C].trim());
		this.coefD = Number(config[LeagueBotConfig.FIELD_D].trim());
		this.minScore = SoftMoneyNumber.createFromAcronym(config[LeagueBotConfig.FIELD_MIN_SCORE].trim());
	}

	public getKey(): string {
		return this.key;
	}

	public getCoefA(): number {
		return this.coefA;
	}

	public getCoefC(): number {
		return this.coefC;
	}

	public getCoefD(): number {
		return this.coefD;
	}

	public getMinScore(): SoftMoneyNumber {
		return this.minScore;
	}
}
