import { LevelModel } from '@models/level/LevelModel';
import { LevelConfig } from '@configs/level/LevelConfig';
import { LevelModelInstance } from '@main/modelInstance/LevelModelInstance';
import { LevelBehaviourInstance } from './behaviourInstance/LevelBehaviourInstance';
import { ViewportUIView } from '@views/components/ViewportUIView';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { LevelBaseView } from '@views/levels/LevelBaseView';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BusinessModel } from '@models/BusinessModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { LevelWindowViewFactory } from '@src/initializers/windows/LevelWindowViewFactory';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { GetLevelViewById } from '@views/levels/LevelMap';
import {
	TutorialModelInstanceData,
} from '@src/types/ModelInstanceDataTypes';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { MainWindowTarget, MainWindowElementConfig } from '@configs/MainWindowConfig';
import { ViewportView } from '@views/components/ViewportView';
import { PlaylistItem } from '@src/types/PlaylistItem';
import { VideoPlayer } from '@views/windows/collection/gallery/videoPlayer/VideoPlayer';
import { SoundController } from './SoundController';
import { SkillTypes } from '@src/types/SkillTypes';
import { LevelBehavioursFactory } from '@src/initializers/behaviours/LevelBehavioursFactory';
import { LevelViewDispatcher } from './viewDispatcher/LevelViewDispatcher';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { AnalyticSourceLevelStartWindowView } from '@src/network/analytics/sources/AnalyticSourceLevelStartWindowView';
import { AnalyticSourceBankViews } from '@src/network/analytics/sources/AnalyticSourceBankViews';
import { AnalyticSourceDialogWindowView } from '@src/network/analytics/sources/AnalyticSourceDialogWindowView';
import { AnalyticSourceFarewellPartyWindowView } from '@src/network/analytics/sources/AnalyticSourceFarewellPartyWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

export class LevelInstance extends PIXI.utils.EventEmitter {
	public static readonly EVENT_STARTED: symbol = Symbol();
	public static readonly EVENT_LEVEL_CHANGE: symbol = Symbol();
	public static readonly EVENT_GOTO_EVENT_LEVEL: symbol = Symbol();
	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	public static readonly EVENT_TUTORIAL: symbol = Symbol();
	public static readonly EVENT_TUTORIAL_END: symbol = Symbol();

	private readonly levelConfigs: Map<string, LevelConfig>;
	private readonly savesConfig: SavesConfig;
	private readonly modelInstance: LevelModelInstance;
	private readonly serverTimeModel: ServerTimeModel;
	private readonly gameProfileModel: GameProfileModel;
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly cheatModel: CheatModel;
	private readonly stage: PIXI.Container;
	private readonly behaviourInstance: LevelBehaviourInstance;
	private readonly viewDispatcher: LevelViewDispatcher;
	private readonly levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>;

	private readonly analyticSourceLevelStartWindowView: AnalyticSourceLevelStartWindowView;
	private readonly analyticSourceBankViews: AnalyticSourceBankViews;
	private readonly analyticSourceDialogWindowView: AnalyticSourceDialogWindowView;
	private readonly analyticSourceFarewellPartyWindowView: AnalyticSourceFarewellPartyWindowView;

	private levelModel: LevelModel;
	private nextLevelConfig: LevelConfig;

	private readonly windowViewFactory: LevelWindowViewFactory;
	private readonly windowViewSystem: WindowViewSystem;

	private readonly viewportView: ViewportView;
	private readonly viewportUIView: ViewportUIView;
	private readonly gameUIView: LevelGameUIView;
	private currentLevelView: LevelBaseView;

	private enabled: boolean;

	private lastDisableTime: number;

	constructor(
		stage: PIXI.Container,
		interactionManager: PIXI.interaction.InteractionManager,
		env: string,
		cheatModel: CheatModel,
		networkRequestSender: NetworkRequestSender,
		serverTime: ServerTimeModel,
		modelInstance: LevelModelInstance,
		gameProfileModel: GameProfileModel,
		savesConfig: SavesConfig,
		tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepLevelConfig>,
		levelConfigs: Map<string, LevelConfig>,
		dialogConfigs: Map<string, DialogConfig>,
		questConfigs: Map<string, QuestConfigBase>,
		constantsConfig: ConstantsConfig,
		mainWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>,
		levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>,
		localizationSupportConfig: LocalizationSupportConfig,
		isNutaku: boolean,
		assetUrlsLoader: AssetUrlsLoader,
		version: string,
	) {
		super();

		this.cheatModel = cheatModel;
		this.modelInstance = modelInstance;
		this.levelConfigs = levelConfigs;
		this.gameProfileModel = gameProfileModel;
		this.serverTimeModel = serverTime;
		this.networkRequestSender = networkRequestSender;
		this.stage = stage;
		this.savesConfig = savesConfig;
		this.lastDisableTime = 0;
		this.levelBaseWindowConfig = levelBaseWindowConfig;

		const currentProgress = this.savesConfig.getQuestsProgress();
		const level = this.savesConfig.getLevel() || 1;
		const levelConfig = this.levelConfigs.get(level.toString());
		this.levelModel = this.modelInstance.getLevelModel();
		this.levelModel.init(
			level,
			currentProgress,
			levelConfig,
		);

		this.nextLevelConfig = this.levelConfigs.get((this.savesConfig.getLevel() + 1).toString());

		const businessesLastUpdate = new Map();
		this.savesConfig.getBusinessesSaveData().forEach((data, key) => {
			businessesLastUpdate.set(key, data.updated);
		});

		const farewellPartyEverStarted = this.savesConfig.getFarewellPartyEverStarted()
			|| this.levelModel.getCurrentLevel() > this.levelBaseWindowConfig.get(MainWindowTarget.PREPARTY_CHARACTER).unlockValue.level;

		const eventLevelModel = this.modelInstance.getEventLevelModel();

		this.windowViewSystem = new WindowViewSystem();
		this.windowViewFactory = new LevelWindowViewFactory(
			this.cheatModel,
			this.modelInstance,
			this.networkRequestSender,
			eventLevelModel,
			this.levelModel,
			this.serverTimeModel,
			this.gameProfileModel,
			dialogConfigs,
			constantsConfig,
			localizationSupportConfig,
			this.savesConfig.getRedeemCode(),
			true,
			false,
			isNutaku,
			version,
		);

		const hintSpawner = new ShowHintSpawner(
			interactionManager,
		);

		const behavioursFactory = new LevelBehavioursFactory(
			this.modelInstance,
			this.networkRequestSender,
			this.cheatModel,
			this.gameProfileModel,
			this.modelInstance.getBankPaymentSaveModel(),
			eventLevelModel,
			this.savesConfig.isLevelStartDialogShowed(),
			this.savesConfig.getTimedQuestInitData(),
			this.savesConfig.getTransactionsSaveData(),
			this.serverTimeModel,
			constantsConfig,
			savesConfig,
			questConfigs,
			tutorialStepModelInstanceData.configs,
			businessesLastUpdate,
			this.savesConfig.getCompensationLootboxConfigs(),
			this.windowViewSystem,
			this.windowViewFactory,
			hintSpawner,
			this.modelInstance.getBoostModels(),
			this.modelInstance.getFarewellBoostModel(),
			farewellPartyEverStarted,
			isNutaku,
			assetUrlsLoader,
		);
		this.behaviourInstance = new LevelBehaviourInstance(
			this.cheatModel,
			behavioursFactory,
			this.modelInstance,
			this.levelModel,
			this.savesConfig.getQuestsInitData(),
			this.savesConfig.hasLevelStartedTime(),
		);

		this.behaviourInstance.initBankLoader();
		this.setBehaviourInstanceEnabled(true);
		this.behaviourInstance.setBoostsTickerEnabled(true);
		this.behaviourInstance.setPrepartyStartCharacterTickerEnabled(true);

		this.behaviourInstance.on(LevelBehaviourInstance.EVENT_LOCALIZATION_CHANGE, (lang: string) => {
			this.emit(LevelInstance.EVENT_LOCALIZATION_CHANGE, lang);
		}, this);
		this.behaviourInstance.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}, this);
		this.behaviourInstance.on(LevelBehaviourInstance.EVENT_TUTORIAL, () => {
			this.emit(LevelInstance.EVENT_TUTORIAL);
		}, this);
		this.behaviourInstance.on(LevelBehaviourInstance.EVENT_LEVEL_CHANGE, () => {
			this.changeLevel();
		}, this);
		this.behaviourInstance.on(LevelBehaviourInstance.EVENT_GOTO_EVENT_LEVEL, () => {
			this.emit(LevelInstance.EVENT_GOTO_EVENT_LEVEL);
		}, this);
		this.behaviourInstance.on(LevelBehaviourInstance.EVENT_TUTORIAL_END, () => {
			this.emit(LevelInstance.EVENT_TUTORIAL_END);
		}, this);

		this.viewportView = new ViewportView();

		this.gameUIView = new LevelGameUIView(
			this.levelModel,
			eventLevelModel,
			this.viewportView,
			this.serverTimeModel,
			this.gameProfileModel,
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBoostModels(),
			this.modelInstance.getFarewellBoostModel(),
			this.modelInstance.getSkillModels(),
			this.modelInstance.getGalleryVideoModels(),
			this.modelInstance.getBankModel(),
			this.modelInstance.getQuestLinesModel(),
			this.modelInstance.getTimedQuestLinesModel(),
			this.modelInstance.getLevelChallengeModel(),
			this.modelInstance.getEpicQuestCollectionsModels(),
			this.modelInstance.getSummonModelsPool(),
			this.cheatModel,
			interactionManager,
			constantsConfig.getMaxButtonValues(),
			mainWindowConfig,
			env,
			constantsConfig.getShowQuestTargetDuration(),
			this.modelInstance.getNewsModel(),
			constantsConfig.getNewsEnabled(),
			this.modelInstance.getDailyRewardsModel(),
			this.modelInstance.getMaintenanceModel(),
			constantsConfig.getMaxAllowedLevel(),
			this.savesConfig.getButtonMaxCustomersCurrentValueId(),
		);
		this.gameUIView.visible = false;
		this.gameUIView.on(
			LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_CLICK,
			this.onGoToNextLevelButtonPressed,
			this,
		);

		this.viewportUIView = new ViewportUIView(this.viewportView);
		this.viewportUIView.visible = false;

		this.windowViewSystem.setViewport(this.viewportView);
		this.windowViewSystem.setWindowsContainer(this.gameUIView);

		this.windowViewFactory.setGameUIView(this.gameUIView);

		this.stage.addChild(this.viewportView);
		this.stage.addChild(this.viewportUIView);
		this.stage.addChild(this.gameUIView);

		this.viewDispatcher = new LevelViewDispatcher();
		this.viewDispatcher
			.setViewportView(this.viewportView)
			.setViewportUIView(this.viewportUIView)
			.setGameUIView(this.gameUIView)
			.setWindowViewSystem(this.windowViewSystem);

		this.modelInstance.getPrestigeMoneyModel().setValue(this.savesConfig.getPrestigeMoney());
		this.modelInstance.getHardMoneyModel().setValue(this.savesConfig.getHardMoney());
		this.modelInstance.getGalleryVideoModels().forEach(x => x.on(GalleryVideoModel.EVENT_PLAY, this.showGalleryVideo, this));

		this.analyticSourceLevelStartWindowView = new AnalyticSourceLevelStartWindowView(
			this.levelModel,
		);
		this.analyticSourceFarewellPartyWindowView = new AnalyticSourceFarewellPartyWindowView();
		this.analyticSourceBankViews = new AnalyticSourceBankViews();
		this.analyticSourceDialogWindowView = new AnalyticSourceDialogWindowView();
	}

	public startLevel(): void {
		const currentLevel = this.levelModel.getCurrentLevel();
		const levelConfig = this.levelConfigs.get(currentLevel.toString());

		this.modelInstance.getSoftMoneyModel().setValue(
			this.levelModel.isInitialLevel() ? this.savesConfig.getSoftMoney() : this.levelModel.getSoftMoneyStart(),
		);

		levelConfig.getBusinessesLevelConfig().forEach((businessLevelConfig, businessKey) => {
			const model: BusinessModel = this.modelInstance.getBusinessModels().get(businessKey);
			model.setAvailable();
			model.setCustomerMultipliers(businessLevelConfig.customerMultipliers);
			model.setCustomerCostIncreaseArray(businessLevelConfig.cciArray);
		});

		this.modelInstance.getDialogsFactory().init(
			this.levelModel.getCurrentLevel().toString(),
		);

		this.modelInstance.getCharacterModels().forEach((characterModel) => {
			characterModel.setActivateLevel(currentLevel);
		});

		if (this.levelModel.isInitialLevel()) {
			this.modelInstance.getPrepartyStartCharacterModel().respawn(
				this.savesConfig.getLevelNextFarewellPartyTime() - this.serverTimeModel.getCalculatedISOTime(),
			);
		}

		const levelChallengeModel = this.modelInstance.getLevelChallengeModel();
		if (levelConfig.hasChallenges()) {
			levelChallengeModel.init(
				levelConfig.getChallengeTimes(),
				this.serverTimeModel.getCalculatedISOTime(),
				this.levelModel.isInitialLevel() ? this.savesConfig.getLevelStartedTime() : null,
				this.levelModel.isInitialLevel() ? this.savesConfig.getLevelFinishedTime() : null,
			);
		}

		this.viewportView.resetScrollValue();
		if (this.viewportView.isZoomed()) {
			this.viewportView.zoomOut();
		}

		this.behaviourInstance.init();

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const LevelView = GetLevelViewById(this.levelModel.getCurrentSeason());

		let nextLevelBackground = null;
		if (this.nextLevelConfig) {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const NextLevel = GetLevelViewById(this.nextLevelConfig.getSeason());
			nextLevelBackground = NextLevel.createBackground(this.nextLevelConfig.getBackground());
		}

		this.currentLevelView = new LevelView({
			cheatModel: this.cheatModel,
			levelModel: this.levelModel,
			questLinesModel: this.modelInstance.getQuestLinesModel(),
			businessModels: this.modelInstance.getBusinessModels(),
			newCustomerMultiplierModels: this.modelInstance.getNewCustomerMultiplierModels(),
			characterModels: this.modelInstance.getCharacterModels(),
			skillModels: this.modelInstance.getSkillModels(),
			upgradeModels: this.modelInstance.getUpgradeModels(),
			softMoneyModel: this.modelInstance.getSoftMoneyModel(),
			prestigeMoneyModel: this.modelInstance.getPrestigeMoneyModel(),
			prepartyStartCharacterModel: this.modelInstance.getPrepartyStartCharacterModel(),
			characterQuickPhraseEmitter: this.modelInstance.getCharacterQuickPhraseEmitter(),
			background: this.levelModel.getBackground(),
			currentLevel: this.levelModel.getCurrentLevel(),
			buttonMaxCustomersView: this.gameUIView.getButtonMaxCustomersView(),
			viewportUI: this.viewportUIView,
			viewport: this.viewportView,
			nextLevelBackground,
			levelBaseWindowConfig: this.levelBaseWindowConfig,
		});
		this.currentLevelView.visible = false;

		this.viewportView.setContent(this.currentLevelView);

		this.viewDispatcher.setLevelView(this.currentLevelView);
		this.updateViewDispatcher();
		this.viewDispatcher.dispatch();

		const startLevelViews = (): void => {
			this.gameUIView.init();
			this.gameUIView.visible = true;

			this.currentLevelView.visible = true;
			this.viewportUIView.visible = true;

			this.emit(LevelInstance.EVENT_STARTED);
		};

		const questLinesModel = this.modelInstance.getQuestLinesModel();
		if (questLinesModel.isInited()) {
			startLevelViews();
		} else {
			questLinesModel.once(QuestLinesModel.EVENT_INITED, startLevelViews, this);
		}
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (value && this.lastDisableTime > 0) {
			const disableTime = this.serverTimeModel.getCalculatedISOTime() - this.lastDisableTime;
			const backToGameRewardModel = this.modelInstance.getBackToGameRewardModel();
			backToGameRewardModel.setOfflineSeconds(
				disableTime,
				this.lastDisableTime,
			);
		} else if (!value) {
			this.lastDisableTime = this.serverTimeModel.getCalculatedISOTime();
		}

		if (value) {
			this.behaviourInstance.initBankLoader();
		}

		this.setBehaviourInstanceEnabled(value);

		this.gameUIView.visible = value;
		this.viewportView.visible = value;
		this.viewportUIView.visible = value;
	}

	public isEnabled(): boolean {
		return this.enabled;
	}

	private onGoToNextLevelButtonPressed(): void {
		this.setBehaviourInstanceEnabled(false);
		this.behaviourInstance.setBoostsTickerEnabled(false);
		this.behaviourInstance.setPrepartyStartCharacterTickerEnabled(false);
	}

	private changeLevel(level?: number): void {
		const lootboxFactory = this.modelInstance.getLootboxFactory();
		this.modelInstance.getNewCustomerMultiplierModels().forEach(model => {
			if (model.hasLootboxData()) {
				const lootboxes = model.getLootboxData();
				lootboxes.forEach(reward => {
					const lootbox = lootboxFactory.createLootbox(reward as any);
					lootbox.collect();
				});
			}
		});

		this.behaviourInstance.reset();

		this.modelInstance.getBankModel().reset();
		this.modelInstance.getTutorialStepsEmitter().reset();
		this.modelInstance.getQuestLinesModel().reset();
		this.modelInstance.getLevelChallengeModel().reset();
		this.modelInstance.getBackToGameRewardModel().reset();

		Array.from(this.modelInstance.getCharacterModels().values())
			.filter((model) => model.isOpened())
			.forEach((model) => model.resetModel());

		Array.from(this.modelInstance.getUpgradeModels().values())
			.filter((model) => model.isOpened())
			.forEach((model) => model.resetModel());

		Array.from(this.modelInstance.getBusinessModels().values())
			.forEach((model) => model.resetModel());

		Array.from(this.modelInstance.getNewCustomerMultiplierModels().values())
			.forEach((model) => model.resetModel());

		const constantProfitSkillModels = Array
			.from(this.modelInstance.getSkillModels().values())
			.filter((skill) => skill.getType() === SkillTypes.CONSTANT_PROFIT);

		constantProfitSkillModels.forEach(model => {
			if (model.isOpened()) {
				model.deactivate();
			}
		});
		this.viewportView.removeContent();
		this.currentLevelView.destroy({ children: true });

		const currentLevel = this.levelModel.getCurrentLevel();
		const targetLevel = level || currentLevel + 1;

		const levelConfig = this.levelConfigs.get(targetLevel.toString());
		this.levelModel.changeLevel(
			targetLevel,
			levelConfig,
		);

		this.nextLevelConfig = this.levelConfigs.get((targetLevel + 1).toString());

		this.startLevel();
		this.behaviourInstance.initBankLoader();
		this.setBehaviourInstanceEnabled(true);
		this.behaviourInstance.setBoostsTickerEnabled(true);
		this.behaviourInstance.setPrepartyStartCharacterTickerEnabled(true);

		this.emit(LevelInstance.EVENT_LEVEL_CHANGE);
	}

	private updateViewDispatcher(): void {
		this.viewDispatcher.updateTargets({
			viewportViewSetters: this.behaviourInstance.getViewportViewSetters(),
			viewportUIViewSetters: this.behaviourInstance.getViewportUIViewSetters(),
			gameUIBaseViewSetters: [...this.behaviourInstance.getGameUIBaseViewSetters()],
			backToGameWindowViewSetters: this.behaviourInstance.getBackToGameWindowViewSetters(),
			farewellPartyViewSetters: [...this.behaviourInstance.getFarewellPartyViewSetters(), this.analyticSourceFarewellPartyWindowView],
			gameUIViewSetters: [...this.behaviourInstance.getGameUIViewSetters()],
			levelStartWindowViewSetters: [
				this.analyticSourceLevelStartWindowView,
			],
			businessBaseViewSetters: this.behaviourInstance.getBusinessBaseViewSetters(),
			businessBaseViewsSetters: this.behaviourInstance.getBusinessBaseViewsSetters(),
			businessAcquiredUIViewSetters: this.behaviourInstance.getBusinessAcquiredUIViewSetters(),
			businessManageUIViewSetters: this.behaviourInstance.getBusinessManageUIViewSetters(),
			businessOpenToBuyUIViewSetters: this.behaviourInstance.getBusinessOpenToBuyUIViewSetters(),
			skillBaseViewSetter: this.behaviourInstance.getSkillBaseViewSetters(),
			questWindowViewSetters: this.behaviourInstance.getQuestWindowBaseViewSetters(),
			characterProfileViewSetters: this.behaviourInstance.getCharacterProfileWindowViewSetters(),
			upgradePromoteViewSetters: this.behaviourInstance.getUpgradePromoteWindowViewSetters(),
			totemPromoteViewSetters: this.behaviourInstance.getTotemPromoteWindowViewSetters(),
			summonCollectionViewSetters: this.behaviourInstance.getSummonCollectionViewSetters(),
			summonResultViewSetters: this.behaviourInstance.getSummonResultViewSetters(),
			bankWindowViewSetters: this.behaviourInstance.getBankWindowViewSetters(),
			bankOfferWindowViewSetters: [...this.behaviourInstance.getBankOfferWindowViewSetters(), this.analyticSourceBankViews],
			bankOfferLinesUIViewSetters: [this.analyticSourceBankViews],
			tutorialStepViewSetters: this.behaviourInstance.getTutorialStepWindowViewSetters(),
			buttonMaxCustomersViewSetters: this.behaviourInstance.getButtonMaxCustomersViewSetters(),
			settingsWindowViewSetters: this.behaviourInstance.getSettingsWindowViewSetters(),
			rewardResultWindowViewSetters: this.behaviourInstance.getRewardResultWindowViewSetters(),
			supportWindowViewSetters: this.behaviourInstance.getSupportWindowViewSetters(),
			registrationWindowViewSetters: this.behaviourInstance.getRegistrationWindowViewSetters(),
			dialogWindowViewSetters: [this.analyticSourceDialogWindowView],
			collectionCardsProgressViewSetters: this.behaviourInstance.getCollectionCardsProgressViewSetters(),
			collectEventRewardsWindowViewSetters: this.behaviourInstance.getCollectEventRewardsWindowViewSetters(),
			prepartyStartCharacterViewSetters: this.behaviourInstance.getPrepartyStartCharacterViewSetters(),
			levelViewSetters: this.behaviourInstance.getLevelViewSetters(),
			characterCollectionViewSetters: this.behaviourInstance.getCharacterCollectionViewSetters(),
			totemCollectionViewSetters: this.behaviourInstance.getTotemCollectionViewControllers(),
			upgradeCollectionViewSetters: this.behaviourInstance.getUpgradeCollectionViewSetters(),
			furnitureCollectionViewSetters: this.behaviourInstance.getFurnitureCollectionViewSetters(),
			prepartyWidowViewSetters: this.behaviourInstance.getPrepartyWindowViewSetters(),
			galleryCollectionWindowViewSetters: this.behaviourInstance.getGalleryCollectionViewSetters(),
			goToEventWindowViewSetters: this.behaviourInstance.getGoToEventLevelWindowViewSetters(),
			cheatWindowViewSetters: this.behaviourInstance.getCheatWindowViewSetters(),
			newsWindowViewSetters: this.behaviourInstance.getNewsWindowViewSetters(),
			levelChallengeWindowViewSetters: this.behaviourInstance.getLevelChallengeWindowViewSetters(),
			dailyRewardWindowViewSetters: this.behaviourInstance.getDailyRewardWindowViewSetters(),
		});
	}

	private getVideoPlaylist(): PlaylistItem[] {
		const playlist: PlaylistItem[] = [];

		const galleryVideoModels = Array.from(this.modelInstance.getGalleryVideoModels().values());
		galleryVideoModels.filter((m) => m.isOpened()).forEach((model) => {
			playlist.push({
				key: model.getKey(),
				url: model.getVideoUrl(),
				duration: model.getDuration(),
			});
		});

		return playlist;
	}

	private showGalleryVideo(videoKey: string): void {
		const playlist: PlaylistItem[] = this.getVideoPlaylist();

		const videoView = new VideoPlayer(playlist, videoKey);
		videoView.on(VideoPlayer.EVENT_PLAYER_CLOSED, () => {
			this.onVideoPlayerClosed(videoKey);
		});
		this.stage.addChild(videoView);

		this.viewportView.visible = false;
		this.viewportUIView.visible = false;
		this.gameUIView.visible = false;

		SoundController.getInstance().pauseBackgroundSoundsIfAny();
	}

	private onVideoPlayerClosed(videoKey: string): void {
		const videoModel = this.modelInstance.getGalleryVideoModels().get(videoKey);
		videoModel.close();

		this.viewportView.visible = true;
		this.viewportUIView.visible = true;
		this.gameUIView.visible = true;

		SoundController.getInstance().resumeBackgroundSoundIfAny();
	}

	public getAnalyticSourceLevelStartWindowView(): AnalyticSourceLevelStartWindowView {
		return this.analyticSourceLevelStartWindowView;
	}

	public getAnalyticSourceBankViews(): AnalyticSourceBankViews {
		return this.analyticSourceBankViews;
	}

	public getAnalyticSourceDialogWindowView(): AnalyticSourceDialogWindowView {
		return this.analyticSourceDialogWindowView;
	}

	public getAnalyticSourceFarewellPartyView(): AnalyticSourceFarewellPartyWindowView {
		return this.analyticSourceFarewellPartyWindowView;
	}

	private setBehaviourInstanceEnabled(value: boolean): void {
		this.behaviourInstance
			.setBankTickerEnabled(value)
			.setSkillsTickerEnabled(value)
			.setSummonTickerEnabled(value)
			.setBusinessesTickerEnabled(value)
			.setTimedQuestsTickerEnabled(value)
			.setLevelChallengeTickerEnabled(value)
			.setTimedQuestsLoaderEnabled(value)
			.setMaintenanceTickerEnabled(value)
			.setBankLoaderEnabled(value);
	}
}
