import { AbstractQuest } from '@models/quests/AbstractQuest';
import LocalizationStorage from '@main/LocalizationStorage';
import { QuestTypes } from '@src/types/QuestTypes';
import { TextUtils } from '@src/utils/TextUtils';
import { QuestPromoteTargets } from '@models/quests/QuestPromoteTargets';
import { QuestUIHelper } from './QuestUIHelper';
import { QuestSpendMoney } from '@models/quests/QuestSpendPrestigeMoney';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { QuestGetPrestigeOrHardMoney } from '@models/quests/QuestGetPrestigeMoney';

export class TimedQuestUIHelper {
	public static getQuestDescription(quest: AbstractQuest, alternateLocaleKey: boolean = false): string {
		const localizationStorage = LocalizationStorage.getInstance();
		let textQuest = '';
		switch (quest.getType()) {
			case QuestTypes.GET_PRESTIGE_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetPrestigeMoney')
					: localizationStorage.getLocalizedString('#side_quest_GetPrestigeMoney');
				const targetValue = (quest as QuestGetPrestigeOrHardMoney<PrestigeMoneyModel>).getTargetAmount();
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_HARD_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetHardMoney')
					: localizationStorage.getLocalizedString('#side_quest_GetHardMoney');
				const targetValue = (quest as QuestGetPrestigeOrHardMoney<HardMoneyModel>).getTargetAmount();
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_SOFT_MONEY_TUTORIAL:
			case QuestTypes.GET_SOFT_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetSoftMoney')
					: localizationStorage.getLocalizedString('#side_quest_GetSoftMoney');
				textQuest = textQuest.replace('{{value}}', quest.getTotalProgressValue());
				break;
			}
			case QuestTypes.PROMOTE_ALL: {
				const questPromote = quest as QuestPromoteTargets<any>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteAll')
					: localizationStorage.getLocalizedString('#side_quest_PromoteAll');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTERS: {
				const questPromote = quest as QuestPromoteTargets<any>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteCharacters')
					: localizationStorage.getLocalizedString('#side_quest_PromoteCharacters');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.PROMOTE_UPGRADES: {
				const questPromote = quest as QuestPromoteTargets<any>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteUpgrades')
					: localizationStorage.getLocalizedString('#side_quest_PromoteUpgrades');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.SPEND_PRESTIGE_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_SpendPrestigeMoney')
					: localizationStorage.getLocalizedString('#side_quest_SpendPrestigeMoney');
				const targetValue = (quest as QuestSpendMoney<PrestigeMoneyModel>).getTargetAmount();
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.SPEND_HARD_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_SpendHardMoney')
					: localizationStorage.getLocalizedString('#side_quest_SpendHardMoney');
				const targetValue = (quest as QuestSpendMoney<HardMoneyModel>).getTargetAmount();
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			default:
				textQuest = QuestUIHelper.getQuestDescription(quest, alternateLocaleKey);
		}
		return textQuest;
	}
}
