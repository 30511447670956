import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter40013View extends CharacterMainBaseView {
	constructor() {
		super('character40013');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(92, -47);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(152, 41),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(336, 53),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
