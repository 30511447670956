import {
	BusinessSaveData,
	CharacterSaveData,
	BoostSaveData,
	TimeskipSaveData,
	QuestInitData,
	UpgradeSaveData,
	TotemSaveData,
	TutorialStepsSaveData,
	SkillSaveData,
	TimedQuestInitData,
	UserDataSaveData,
	EventLevelInitData,
	FarewellBonusSaveData,
	TransactionSaveData,
	BankSaveData,
	BankPaymentSaveData,
	BankBundleSaveData, RechargeSaveData, SummonSaveData, NewsData
} from '@src/types/SaveTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { AnalyticsType, TrackingData } from '@src/types/AnalyticsTypes';

export class SavesConfigParser {
	public static parseQuests(
		data: any[],
		parseQuestLineIndexHandler: (questLineKey: string) => number,
	): QuestInitData[] {
		const initData: QuestInitData[] = [];
		data.forEach((questData) => {
			const questLineIndex = parseQuestLineIndexHandler(questData.quest_line);
			if (questLineIndex !== -1) {
				initData[questLineIndex - 1] = {
					questKey: questData.id,
					progress: questData.progress,
					info: {
						paramsOnLevel: questData.info.params_on_level,
					},
					lineProgress: questData.line_number,
					completed: questData.completed,
					completeValue: questData.complete_value,
				};
			}
		});
		return initData;
	}

	public static parseUserData(data: { [key: string]: any }): UserDataSaveData {
		return {
			buttonMaxCustomersCurrentValueId: data.button_max_customers_current_value_id,
			startDialogShowed: data.start_dialog_showed,
			settingsLanguage: data.settings_language,
			settingsSound: data.settings_sounds,
			settingsMusic: data.settings_music,
			settingsCardpay: data.settings_cardpay,
			levelStartedTime: data.level_started_ts,
			levelFinishedTime: data.level_finished_ts,
			username: data.settings_username,
			nextFarewellPartyKey: data.next_farewell_party,
			eventNextFarewellPartyKey: data.event_next_farewell_party,
			farewellPartyEverStarted: Boolean(data.farewell_party_ever_started),
			cardpayEmail: data.cardpay_email,
		};
	}

	public static parseTutorialSteps(data: Record<string, unknown>[]): TutorialStepsSaveData {
		const completed: string[] = [];
		let pending = '';

		data.forEach((tutorialStepData) => {
			const key = tutorialStepData.id as string;
			const isCompleted = Boolean(tutorialStepData.completed);

			if (isCompleted) {
				completed.push(key);
			} else {
				pending = key;
			}
		});

		return { completed, pending };
	}

	public static parseBusinesses(data: any[]): Map<string, BusinessSaveData> {
		const businessesSave: Map<string, BusinessSaveData> = new Map();
		data.forEach((businessData) => {
			businessesSave.set(businessData.id, {
				customers: businessData.customers,
				incomeProgress: businessData.income_progress,
				updated: businessData.updated,
			});
		});
		return businessesSave;
	}

	public static parseCharacters(data: any[], userDataRaw: { [key: string]: any }): Map<string, CharacterSaveData> {
		const charactersSave: Map<string, CharacterSaveData> = new Map();
		data.forEach((characterData) => {
			const key: string = characterData.id;
			const pictureIdKey: string = `${key}_picture_id`;
			charactersSave.set(key, {
				level: characterData.level,
				activated: characterData.acquired,
				cardsCount: characterData.cards,
				skillActivateTime: characterData.last_skill_activate_datetime,
				isNew: characterData.is_new,
				pictureId: userDataRaw[pictureIdKey],
			});
		});
		return charactersSave;
	}

	public static parseTimedQuests(data: { [key: string]: any }): TimedQuestInitData {
		if (data) {
			return {
				questKey: data.id,
				progress: data.progress,
				startedOn: data.started_on,
				info: {
					paramsOnLevel: data.info.params_on_level,
					eventDuration: data.info.event_duration,
					questDuration: data.info.quest_duration,
				},
				completed: data.completed,
				completeValue: data.complete_value,
			};
		}
		return null;
	}

	public static parseUpgrades(data: any[]): Map<string, UpgradeSaveData> {
		const upgradesSave = new Map();
		data.forEach((upgradeData) => {
			upgradesSave.set(upgradeData.id, {
				level: upgradeData.level,
				activated: upgradeData.acquired,
				cardsCount: upgradeData.cards,
				isNew: upgradeData.is_new,
			});
		});
		return upgradesSave;
	}

	public static parseTotems(data: any[]): Map<string, TotemSaveData> {
		const totemsSave = new Map();
		data.forEach((totemData) => {
			totemsSave.set(totemData.id, {
				level: totemData.level,
				cardsCount: totemData.cards,
				isNew: totemData.is_new,
			});
		});
		return totemsSave;
	}

	public static parseGallery(data: any[]): Map<string, string> {
		const galleryVideosSave = new Map();
		data.forEach((videoData) => {
			galleryVideosSave.set(videoData.id, videoData.count);
		});
		return galleryVideosSave;
	}

	public static parsePayment(data: { [key: string]: any }): BankPaymentSaveData {
		return {
			totalPayment: Number(data['total_payment'] || 0),
			averagePayment: Number(data['avg_payment'] || 0),
			paymentCount: Number(data['payment_count'] || 0),
			lastPaymentTime: Number(data['last_payment_ts'] || 0),
		};
	}

	public static parseSummon(data: { [key: string]: any }): SummonSaveData {
		const summonSaves: SummonSaveData = {
			applyTimes: data?.['apply_times'] || {},
			lastApplyTimes: data?.['last_apply'] || {},
			lastApplyFreePriceTime: data?.['last_free_apply'],
		};
		return summonSaves;
	}

	public static parseLevelBank(data: { [key: string]: any }): BankSaveData {
		const bundles: Map<string, BankBundleSaveData> = new Map();
		Object.keys(data['bundle_rewards'] || []).forEach(key => {
			const acceptedBuyTimes: number = data['bundle_rewards'][key]['accepted_times'] as number;
			const rewards: RewardDescriptionType[] = Object.values(data['bundle_rewards'][key]['rewards']);
			const expiresOn: number = data['bundle_rewards'][key]['expires_on'] as number;
			bundles.set(key, {
				acceptedBuyTimes,
				rewards,
				expiresOn,
			});
		});

		const recharges: RechargeSaveData = {
			applyTimes: [] as any,
			rewards: [] as any,
		};
		Object.keys(data['recharge'] || []).forEach(key => {
			const applyTimes: number = data['recharge'][key];
			recharges.applyTimes[key] = applyTimes;
		});
		Object.keys(data['recharge_rewards'] || []).forEach(key => {
			const rewards: RewardDescriptionType[] = Object.values(data['recharge_rewards'][key]['rewards']);
			recharges.rewards[key] = rewards;
		});

		const bankSaveData: BankSaveData = {
			offersSaves: {
				activations: data['offers_data']?.['activations'] || {},
				activeEndTimes: data['offers_data']?.['data'] || {},
			},
			purchases: data['purchases'] || {},
			recharges,
			bundles,
		};
		return bankSaveData;
	}

	public static parseEventLevelBank(data: { [key: string]: any }): BankSaveData {
		const bundles: Map<string, BankBundleSaveData> = new Map();
		Object.keys(data['bundle_rewards'] || []).forEach(key => {
			const acceptedBuyTimes: number = data['bundle_rewards'][key]['accepted_times'] as number;
			const rewards: RewardDescriptionType[] = Object.values(data['bundle_rewards'][key]['rewards']);
			const expiresOn: number = data['bundle_rewards'][key]['expires_on'] as number;
			bundles.set(key, {
				acceptedBuyTimes,
				rewards,
				expiresOn,
			});
		});
		const bankSaveData: BankSaveData = {
			offersSaves: {
				activations: data['event_offers_data']?.['activations'] || {},
				activeEndTimes: data['event_offers_data']?.['data'] || {},
			},
			purchases: data['event_purchases'] || {},
			bundles,
		};
		return bankSaveData;
	}


	public static parseBoosts(data: { [key: string]: any }): Map<string, BoostSaveData> {
		const boostsSave = new Map();

		const availableData = data?.available;
		const activeData = data?.active;
		if (availableData !== undefined) {
			Object.keys(availableData).forEach((boostKey) => {
				const availableCount = availableData[boostKey];
				let deactivateTime: number;
				if (activeData !== undefined && activeData[boostKey] !== undefined) {
					deactivateTime = activeData[boostKey];
				}
				const saveData: BoostSaveData = {
					available: availableCount,
					deactivateTime,
				};
				boostsSave.set(boostKey, saveData);
			});
		}
		return boostsSave;
	}

	public static parseTimeskips(data: { [key: string]: any }): Map<string, TimeskipSaveData> {
		const timeskipSave = new Map();
		if (data) {
			Object.keys(data).forEach((timeskipKey) => {
				const saveData: TimeskipSaveData = {
					available: Number(data[timeskipKey]),
				};
				timeskipSave.set(timeskipKey, saveData);
			});
		}
		return timeskipSave;
	}

	public static parseSkills(data: { [key: string]: any }): Map<string, SkillSaveData> {
		const skillsSave = new Map();
		if (data) {
			Object.keys(data).forEach((skillKey) => {
				const saveData: SkillSaveData = {
					activatedOn: Number(data[skillKey].activated_on),
					improveMultiplier: data[skillKey].improve_multiplier ? Number(data[skillKey].improve_multiplier) : 1,
					reduceReloadMultiplier: data[skillKey].reload_multiplier ? Number(data[skillKey].reload_multiplier) : 0,
					multiplier: data[skillKey].multiplier ? Number(data[skillKey].multiplier) : 0,
					activeTime: data[skillKey].active_time !== undefined ? data[skillKey].active_time : 0,
				};
				skillsSave.set(skillKey, saveData);
			});
		}
		return skillsSave;
	}


	public static parseEpicQuestCollectionsSave(data: { [key: string]: any }): Map<string, number[]> {
		const epicQuestCollectionsSave = new Map();
		Object.keys(data).forEach((key) => {
			epicQuestCollectionsSave.set(key, data[key]);
		});
		return epicQuestCollectionsSave;
	}

	public static parseEventData(data: { [key: string]: any }): EventLevelInitData {
		if (data) {
			const leagueBotNames = Object.keys(data.data.opponents).map(key => data.data.opponents[key] as string);
			return {
				eventKey: data.event_id,
				leagueKey: data.league_id,
				progress: data.progress,
				startedOn: data.started_on,
				started: data.started,
				data: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					firstEnter: data.data?.first_enter,
					leagueBotNames,
				},
			};
		}
		return null;
	}

	public static parseNoviceEventCompletedOn(data: any): number {
		if (data && data !== 0) {
			return parseInt(data, 10);
		}
		return undefined;
	}

	public static parseFarewellBoost(data: { [key: string]: any }): FarewellBonusSaveData | null {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		if (data?.bonus_value) {
			return { bonusValue: data.bonus_value, bonusEndTime: data.bonus_ends_on };
		}
		return null;
	}

	public static parseNextFarewellPartyTime(data: { [key: string]: any }): number {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		return data?.next_on || 0;
	}

	public static parseTransactions(data: { [key: string]: any }[]): TransactionSaveData[] {
		return data.map(x => ({
			notCommitedTransactionId: x.uid,
			paymentId: x['payment_id'],
		}));
	}

	public static parseUtmParams(data: Record<string, string>): TrackingData | undefined {
		let result: TrackingData;

		if (Object.keys(data).length > 0) {
			let analytics;

			if ('pixel_id' in data) {
				analytics = AnalyticsType.PIXEL;
			} else {
				analytics = AnalyticsType.OTHER;
			}

			result = {
				utmSource: data['utm_source'],
				analytics,
				pixelId: data['pixel_id'],
				clickId: data['click_id'],
				affSub: data['aff_sub'],
				ts: data['ts'],
			};
		}

		return result;
	}

	public static parseNewsData(data: Record<string, unknown>): NewsData {
		return {
			claimedRewardsNews: (data['claimed_rewards_news'] as number[]).map((id) => id.toString()),
			seenNews: (data['seen_news'] as number[]).map((id) => id.toString()),
		};
	}
}
