export class AndroidFullScreenController {
	private readonly element: HTMLElement
	private readonly fullscreenChangeCallback: () => void;
	private readonly onUserInteractionCallback: () => void;
	private fullscreenAvailHeight?: number;

	constructor() {
		this.element = document.body;
		this.onUserInteractionCallback = this.onUserInteraction.bind(this);
		this.fullscreenChangeCallback = this.onFullScreenChange.bind(this);
		this.element.addEventListener('fullscreenchange', this.fullscreenChangeCallback);
		this.subsribeOnUserInteraction();
	}

	public getFullscreenAvailHeight(): number | undefined {
		return this.fullscreenAvailHeight;
	}

	private requestFullScreen(): void {
		this.element.requestFullscreen()
			.then(() => window.screen.orientation.lock('landscape-primary'))
			.then(() => {
				this.fullscreenAvailHeight = Math.min(window.screen.availHeight, window.screen.availWidth);
			});
	}

	private onFullScreenChange(): void {
		if (document.fullscreenElement) {
			this.unsubscribeFromUserInteraction();
		} else {
			this.subsribeOnUserInteraction();
		}
	}

	private onUserInteraction(): void {
		this.requestFullScreen();
	}

	private subsribeOnUserInteraction(): void {
		document.body.addEventListener('pointerup', this.onUserInteractionCallback, true);
	}

	private unsubscribeFromUserInteraction(): void {
		document.body.removeEventListener('pointerup', this.onUserInteractionCallback, true);
	}
}
