import { FarewellPartyWindowViewSetter } from '@interfaces/ViewSetters';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { FarewellPartyModel } from '@models/FarewellPartyModel';

export type EventFarewellPartyProgressEventArgs = {
	startedOn: number;
	fuckpower: number;
	fucktime: number;
	rewardBonusTime: number;
	rewardBonusMultiplier: number;
	barsCompleted: number;
	totems: string;
}

export class AnalyticSourceFarewellPartyWindowView extends PIXI.utils.EventEmitter implements FarewellPartyWindowViewSetter {
	public static readonly EVENT_FAREWELL_PARTY_PROGRESS: symbol = Symbol();

	public setFarewellPartyWindowView(view: FarewellPartyWindowView): void {
		view.once(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED, this.emitData, this);
		view.on(FarewellPartyWindowView.EVENT_BAR_FILLED, this.emitData, this);
	}

	private emitData(model: FarewellPartyModel): void {
		const args: EventFarewellPartyProgressEventArgs = {
			startedOn: model.getStartedOn(),
			fuckpower: model.getFuckpower(),
			fucktime: model.getFucktime(),
			rewardBonusMultiplier: model.getCurrentRewardBoostMultiplier(),
			rewardBonusTime: model.getСurrentRewardBoostTime(),
			barsCompleted: model.getCurrentProgressId(),
			totems: JSON.stringify(model.getTotemLevels()),
		};

		this.emit(AnalyticSourceFarewellPartyWindowView.EVENT_FAREWELL_PARTY_PROGRESS, args);
	}
}
