import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter90008View extends CharacterMainBaseView {
	constructor() {
		super('character90008');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-80, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-105, -57),
			bgPosition: new PIXI.Point(-40, -61),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
