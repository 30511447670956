import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30013View } from '@views/characters/events/event3/EventCharacter30013View';
import { EventCharacter30014View } from '@views/characters/events/event3/EventCharacter30014View';
import { EventCharacter30015View } from '@views/characters/events/event3/EventCharacter30015View';

export class EventBusiness30005View extends BusinessBaseView {
	constructor() {
		const eventCharacter30013View = new EventCharacter30013View();
		const eventCharacter30014View = new EventCharacter30014View();
		const eventCharacter30015View = new EventCharacter30015View();

		super('business30005', [eventCharacter30013View, eventCharacter30014View, eventCharacter30015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b1_decor5']).setTransform(276, 34, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor1']).setTransform(-225, -17, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor3']).setTransform(-217, -72, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor2']).setTransform(166, -37, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			new PIXI.Sprite(decorationAtlas['b1_decor4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite,
			eventCharacter30013View as PIXI.DisplayObject,
			eventCharacter30014View,
			eventCharacter30015View,
		);
	}
}
