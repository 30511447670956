import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60013View } from '@views/characters/events/event6/EventCharacter60013View';
import { EventCharacter60014View } from '@views/characters/events/event6/EventCharacter60014View';
import { EventCharacter60015View } from '@views/characters/events/event6/EventCharacter60015View';

export class EventBusiness60005View extends BusinessBaseView {
	constructor() {
		const eventCharacter60013View = new EventCharacter60013View();
		const eventCharacter60014View = new EventCharacter60014View();
		const eventCharacter60015View = new EventCharacter60015View();

		super('business60005', [eventCharacter60013View, eventCharacter60014View, eventCharacter60015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter60013View as PIXI.DisplayObject,
			eventCharacter60014View,
			eventCharacter60015View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
