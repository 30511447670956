import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { PrepartyBottomPanelView } from '@views/windows/farewellParty/preparty/PrepartyBottomPanelView';
import { TotemModel } from '@models/TotemModel';
import { PrepartyTotemsGalleryView } from '@views/windows/farewellParty/preparty/PrepartyTotemsGalleryView';
import { GameConstants } from '@src/utils/GameConstants';
import { PrepartyTopPanelView } from '@views/windows/farewellParty/preparty/PrepartyTopPanelView';
import { Emitter } from 'pixi-particles';
import { PrepartyWindowEffectsConfigs } from '@views/windows/farewellParty/preparty/PrepartyWindowEffectsConfigs';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { PromotableModel } from '@models/PromotableModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export class PrepartyWindowView extends WindowBaseView {
	public static readonly EVENT_BUTTON_GOTO_PARTY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_FIND_CLICK: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();

	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly bottomPanel: PrepartyBottomPanelView;
	private readonly totemGallery: PrepartyTotemsGalleryView;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly farewellPartyModel: FarewellPartyModel;
	private readonly topPanel: PrepartyTopPanelView;
	private readonly animationEmitters: Emitter[];

	private readonly localizationStorage: LocalizationStorage;

	constructor(
		totemModels: Map<string, TotemModel>,
		farewellPartyModel: FarewellPartyModel,
		currentLevel?: number,
	) {
		super(0.7, false);
		this.farewellPartyModel = farewellPartyModel;
		this.totemModels = totemModels;

		this.localizationStorage = LocalizationStorage.getInstance();

		this.animationEmitters = [];
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();

		const bg = new PIXI.Sprite(AssetsStorage.getResource('farewellBg').texture);
		bg.interactive = true;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		this.bottomPanel = new PrepartyBottomPanelView();
		this.bottomPanel.on(PrepartyBottomPanelView.EVENT_BUTTON_START_CLICK, this.onButtonStartClick, this);

		const previewBgKey = farewellPartyModel.getCurrentAnimationKey();
		this.topPanel = new PrepartyTopPanelView(previewBgKey);
		this.topPanel.on(PrepartyTopPanelView.EVENT_BUTTON_CLOSE_CLICK, this.onClose, this);
		this.topPanel.position.set(16, -414);

		// TOTEMS
		const totemGalleryBg = new PIXI.Graphics();
		totemGalleryBg.beginFill(0x000000, 0.4);
		totemGalleryBg.drawRect(0, 134, 1922, 444);
		totemGalleryBg.endFill();
		totemGalleryBg.pivot.set(totemGalleryBg.width / 2, totemGalleryBg.height / 2);

		this.totemGallery = new PrepartyTotemsGalleryView(
			totemModels,
			currentLevel,
		);
		this.totemGallery.on(
			PrepartyTotemsGalleryView.EVENT_BUTTON_FIND_CLICK,
			() => this.emit(PrepartyWindowView.EVENT_BUTTON_FIND_CLICK),
			this,
		);
		this.totemGallery.on(
			PrepartyTotemsGalleryView.EVENT_CARD_CLICK,
			(data: PromotableClickData) => this.emit(PrepartyWindowView.EVENT_CARD_CLICK, data),
			this,
		);
		this.totemGallery.position.set(-GameConstants.GAME_CENTER_X, -110);

		totemModels.forEach((model) => {
			model.on(PromotableModel.EVENT_CARDS_ADDED, this.onTotemModelUpdate, this);
			model.on(PromotableModel.EVENT_PROMOTED, this.onTotemModelUpdate, this);
			model.on(PromotableModel.EVENT_OPENED, this.onTotemModelUpdate, this);
		});

		this.updateFuckPowerText();
		this.updateFuckTimeText();

		const effectsContainer = this.createEffects();

		this.mainContainer.interactive = true;
		this.mainContainer.addChild(
			bg,
			totemGalleryBg,
			effectsContainer,
			this.totemGallery,
			this.bottomPanel as PIXI.Container,
			this.topPanel as PIXI.Container,
		);

		this.showAppearingAnimation();
	}

	private createEffects(): PIXI.Container {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const effectsContainer = new PIXI.Container();
		effectsContainer.position.set(this.topPanel.x, this.topPanel.y);

		const lightsContainer = new PIXI.Container();
		lightsContainer.scale.set(1, 0.6);
		effectsContainer.addChild(lightsContainer);

		const glowEmitter = new Emitter(
			lightsContainer,
			[fxAtlas['skill_activ_glow1']],
			PrepartyWindowEffectsConfigs.GLOW,
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowLightsEmitter = new Emitter(
			lightsContainer,
			[fxAtlas['party_button_glow4']],
			PrepartyWindowEffectsConfigs.GLOW2,
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const sparksContainer = new PIXI.Container();
		sparksContainer.scale.set(2);
		effectsContainer.addChild(sparksContainer);

		const sparksRightEmitter = new Emitter(
			sparksContainer,
			[fxAtlas['business_glow4']],
			PrepartyWindowEffectsConfigs.SPARKS_RIGHT,
		);
		sparksRightEmitter.autoUpdate = true;
		this.animationEmitters.push(sparksRightEmitter);

		const sparksLeftEmitter = new Emitter(
			sparksContainer,
			[fxAtlas['business_glow4']],
			PrepartyWindowEffectsConfigs.SPARKS_LEFT,
		);
		sparksLeftEmitter.autoUpdate = true;
		this.animationEmitters.push(sparksLeftEmitter);

		return effectsContainer;
	}

	public getButtonStart(): PIXI.Container {
		return this.bottomPanel.getButtonStart();
	}

	public getFuckPowerContainer(): PIXI.Container {
		return this.topPanel.getFuckPowerContainer();
	}

	public getFuckTimeContainer(): PIXI.Container {
		return this.topPanel.getFuckTimeContainer();
	}

	private onButtonStartClick(): void {
		this.emit(
			PrepartyWindowView.EVENT_BUTTON_GOTO_PARTY_CLICK,
		);
		this.onClose();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private showAppearingAnimation(): void {
		this.bottomPanel.showAppearingAnimation();
		this.topPanel.showAppearingAnimation();

		this.totemGallery.resetScrollValue();
		this.totemGallery.startOpenAnimation();
	}

	public onShown(): void {
		this.totemGallery.enableScroll();
		super.onShown();
	}

	public onFocus(): void {
		this.totemGallery.enableScroll();
		super.onFocus();
	}

	public onBlur(): void {
		this.totemGallery.disableScroll();
		super.onBlur();
	}

	private onTotemModelUpdate(): void {
		this.updateFuckPowerText();
		this.updateFuckTimeText();
	}

	private updateFuckPowerText(): void {
		const currentFuckpower = this.farewellPartyModel.getMultiplierFuckpowerTotems() - 1;

		const value = `${Math.round(currentFuckpower * 1000) / 10}`;

		const text = this.localizationStorage.getLocalizedString('#preparty_current_fuck_skill')
			.replace('{{value}}', value);

		this.topPanel.setFuckPower(text);
	}

	private updateFuckTimeText(): void {
		const value = this.farewellPartyModel.getFucktimeTotems().toString();
		const text = this.localizationStorage.getLocalizedString('#preparty_current_fuck_time')
			.replace('{{value}}', value);

		this.topPanel.setFuckTime(text);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.totemModels.forEach((model) => {
			model.off(PromotableModel.EVENT_CARDS_ADDED, this.onTotemModelUpdate, this);
			model.off(PromotableModel.EVENT_PROMOTED, this.onTotemModelUpdate, this);
			model.off(PromotableModel.EVENT_OPENED, this.onTotemModelUpdate, this);
		});

		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
