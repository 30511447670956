import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30016View extends CharacterMainBaseView {
	constructor() {
		super('character30016');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(92, -7);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(106, -66),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(290, -48),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
