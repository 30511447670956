import { CharacterCollectionView } from '@views/windows/collection/characters/CharacterCollectionView';
import { CharactersButtonView } from './buttons/CharactersButtonView';
import { UpgradesButtonView } from './buttons/UpgradesButtonView';
import { TotemsButtonView } from './buttons/TotemsButtonView';
import { GalleryButtonView } from './buttons/GalleryButtonView';
import { SummonButtonView } from './buttons/SummonButtonView';
import { UpgradeCollectionGroupByBusinessesView } from '@views/windows/collection/upgrades/UpgradeCollectionGroupByBusinessesView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { TotemCollectionView } from '@views/windows/collection/totems/TotemCollectionView';
import { AssetsStorage } from '@main/AssetsStorage';
import { GalleryCollectionView } from './gallery/GalleryCollectionView';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { CollectionBaseView } from './CollectionBaseView';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { CollectionWithProgressBaseView } from './CollectionWithProgressBaseView';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { PromotableModel } from '@models/PromotableModel';
import { ModelHelper } from '@models/ModelHelper';
import { TabButtonBaseView } from '@views/components/buttons/TabButtonBaseView';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { UpgradeCollectionDefaultView } from './upgrades/UpgradeCollectionDefaultView';
import { SummonModel } from '@models/SummonModel';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { FurnitureButtonView } from './buttons/FurnitureButtonView';
import { FurnitureCollectionView } from './furniture/FurnitureCollectionView';

export enum CollectionWindowTabs {
	TAB_CHARACTERS,
	TAB_UPGRADES,
	TAB_FURNITURE,
	TAB_TOTEMS,
	TAB_GALLERY,
	TAB_SUMMON,
}

export enum CollectionWindowTabKeys {
	CHARACTERS = 'characters',
	UPGRADES = 'upgrades',
	TOTEMS = 'totems',
}

export type CollectionWindowOpenConfig = {
	tab: CollectionWindowTabs;
	focusKey?: string;
}

export class CollectionWindowView extends WindowBaseView {
	public static readonly EVENT_COLLECTION_CARDS_PROGRESS_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_SUMMON_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_CHARACTER_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_TOTEM_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_GALLERY_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_UPGRADE_VIEW_SHOWED = Symbol();
	public static readonly EVENT_COLLECTION_FURNITURE_VIEW_SHOWED = Symbol();

	private static readonly COLLECTION_TAB_BUTTON_X_OFFSET = 215;
	private static readonly COLLECTION_TAB_BUTTON_Y_OFFSET = 160;

	private readonly characterModels: CharacterModel[];
	private readonly upgradeModels: UpgradeModel[];
	private readonly totemModels: TotemModel[];
	private readonly epicQuestCollectionsModels: EpicQuestCollectionsModel[];

	private readonly closeButton: ButtonBaseView;
	private readonly charactersButton: CharactersButtonView;
	private readonly characterCollection: CharacterCollectionView;
	private readonly upgradesButton: UpgradesButtonView;
	private readonly upgradeCollection: UpgradeCollectionGroupByBusinessesView | UpgradeCollectionDefaultView;
	private readonly furnitureButton: FurnitureButtonView;
	private readonly furnitureCollection: FurnitureCollectionView;
	private readonly totemsButton: TotemsButtonView;
	private readonly totemCollection: TotemCollectionView;
	private readonly galleryButton: GalleryButtonView;
	private readonly galleryCollection: GalleryCollectionView;
	private readonly summonButton: SummonButtonView;
	private readonly summonCollection: SummonCollectionView;
	private currentActiveButton: TabButtonBaseView;

	private readonly promotableModels: PromotableModel[];
	private readonly galleryVideoModels: GalleryVideoModel[];

	private openedCharactersCount: number;
	private openedUpgradesCount: number;
	private openedFurnitureCount: number;
	private openedTotemsCount: number;
	private openedGalleryVideosCount: number;

	private collectionViewButtonMap: Map<TabButtonBaseView, CollectionBaseView>;
	private currentActiveTab: CollectionWindowTabs;
	private readonly collections: PIXI.Container;
	private collectionTabButtonY: number;

	constructor(
		config: CollectionWindowOpenConfig,
		characterModels: Map<string, CharacterModel>,
		skillModels: Map<string, SkillModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		timeskipModels: Map<string, TimeskipModel>,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		epicQuestCollectionsModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>,
		private readonly summonModelsPool: SummonModelsPool,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		groupUpgradesByBusinesses: boolean,
		withMoneyPanels: boolean,
		moneyPanelsButtonPlusInvisible?: boolean,
	) {
		super(0, false);
		this.collectionViewButtonMap = new Map();

		this.characterModels = Array.from(characterModels.values());
		this.upgradeModels = Array.from(upgradeModels.values());
		this.totemModels = Array.from(totemModels.values());
		this.galleryVideoModels = Array.from(galleryVideoModels.values());
		this.epicQuestCollectionsModels = Array.from(epicQuestCollectionsModels.values());

		this.openedCharactersCount = this.characterModels.filter(model => model.isOpened()).length;
		this.openedTotemsCount = this.totemModels.filter(model => model.isOpened()).length;
		this.openedGalleryVideosCount = this.galleryVideoModels.filter(model => model.isOpened()).length;

		this.promotableModels = [
			...this.characterModels,
			...this.upgradeModels,
			...this.totemModels,
		];

		this.promotableModels.forEach((model) => {
			model.on(PromotableModel.EVENT_OPENED, this.onSomePromotableModelOpened, this);
		});

		this.galleryVideoModels.forEach((model) => {
			model.on(GalleryVideoModel.EVENT_OPENED, this.onGalleryVideoOpened, this);
			model.on(GalleryVideoModel.EVENT_OLD, this.onUpdateTabButtonInfoIconGalleryVideo, this);
			model.on(GalleryVideoModel.EVENT_CLOSE, this.onGalleryVideoClose, this);
		});

		this.epicQuestCollectionsModels.forEach((model) => {
			model.on(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.onSomeEpicQuestCollectionModelRewardReached, this);
			model.on(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.onSomeEpicQuestCollectionModelRewardCollected, this);
		});

		this.summonModelsPool.on(SummonModelsPool.EVENT_MODELS_UPDATED, this.onSummonModelPoolUpdate, this);

		this.subscribeToSummonModels();

		const bg = PIXI.Sprite.fromImage('collectionBg');
		bg.interactive = true;

		const leftBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		leftBg.anchor.set(0, 0);
		leftBg.tint = 0x000000;
		leftBg.width = 343;
		leftBg.height = 1080;
		leftBg.alpha = 0.7;

		this.collections = new PIXI.Container();
		this.collectionTabButtonY = 90;

		// SUMMON

		if (!this.summonModelsPool.isEmpty()) {
			this.summonCollection = new SummonCollectionView(
				summonModelsPool,
				prestigeMoneyModel,
				hardMoneyModel,
				characterModels,
				upgradeModels,
				totemModels,
				boostModels,
				timeskipModels,
				moneyPanelsButtonPlusInvisible,
			);
			this.summonButton = new SummonButtonView();

			this.addTab(this.summonButton, this.summonCollection, CollectionWindowTabs.TAB_SUMMON);
		}

		// CHARACTERS

		if (characterModels.size > 0) {
			this.characterCollection = new CharacterCollectionView(
				Array.from(characterModels.values()),
				skillModels,
				prestigeMoneyModel,
				hardMoneyModel,
				withMoneyPanels,
				epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.CHARACTERS),
			);
			this.charactersButton = new CharactersButtonView(this.characterModels.length);

			this.charactersButton.setProgress(this.openedCharactersCount);

			this.addTab(this.charactersButton, this.characterCollection, CollectionWindowTabs.TAB_CHARACTERS);
		}

		// UPGRADES

		const toysModels = this.upgradeModels.filter((upgrade) => !upgrade.isFreeActivation());
		this.openedUpgradesCount = toysModels.filter((model) => model.isOpened()).length;

		if (toysModels.length > 0) {
			const upgradeModelsGroupedByBusiness = new Map<string, UpgradeModel[]>();

			toysModels.forEach((upgradeModel) => {
				const relatedBusinessKey = upgradeModel.getRelatedBusinessKey();

				if (!upgradeModelsGroupedByBusiness.has(relatedBusinessKey)) {
					upgradeModelsGroupedByBusiness.set(relatedBusinessKey, []);
				}

				upgradeModelsGroupedByBusiness.get(relatedBusinessKey).push(upgradeModel);
			});

			if (groupUpgradesByBusinesses) {
				this.upgradeCollection = new UpgradeCollectionGroupByBusinessesView(
					upgradeModelsGroupedByBusiness,
					prestigeMoneyModel,
					hardMoneyModel,
					epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.UPGRADES),
				);
			} else {
				this.upgradeCollection = new UpgradeCollectionDefaultView(
					Array.from(this.upgradeModels.values()),
					prestigeMoneyModel,
					hardMoneyModel,
					epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.UPGRADES),
				);
			}

			this.upgradesButton = new UpgradesButtonView(toysModels.length);

			this.upgradesButton.setProgress(this.openedUpgradesCount);

			this.addTab(this.upgradesButton, this.upgradeCollection, CollectionWindowTabs.TAB_UPGRADES);
		}

		// FURNITURE

		const furnitureModels = this.upgradeModels.filter((upgrade) => upgrade.isFreeActivation());
		this.openedFurnitureCount = furnitureModels.filter((model) => model.isOpened()).length;

		if (furnitureModels.length > 0) {
			const furnitureModelsGroupedByBusiness = new Map<string, UpgradeModel[]>();

			furnitureModels.forEach((upgradeModel) => {
				const relatedBusinessKey = upgradeModel.getRelatedBusinessKey();

				if (!furnitureModelsGroupedByBusiness.has(relatedBusinessKey)) {
					furnitureModelsGroupedByBusiness.set(relatedBusinessKey, []);
				}

				furnitureModelsGroupedByBusiness.get(relatedBusinessKey).push(upgradeModel);
			});

			this.furnitureCollection = new FurnitureCollectionView(
				furnitureModelsGroupedByBusiness,
				prestigeMoneyModel,
				hardMoneyModel,
				epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.FURNITURE),
			);
			this.furnitureButton = new FurnitureButtonView(furnitureModels.length);

			this.furnitureButton.setProgress(this.openedFurnitureCount);

			this.addTab(this.furnitureButton, this.furnitureCollection, CollectionWindowTabs.TAB_FURNITURE);
		}

		// TOTEMS

		if (this.totemModels.length > 0) {
			this.totemCollection = new TotemCollectionView(
				totemModels,
				prestigeMoneyModel,
				hardMoneyModel,
				epicQuestCollectionsModels.get(EpicQuestCollectionsTypes.TOTEMS),
			);
			this.totemsButton = new TotemsButtonView(this.totemModels.length);

			this.totemsButton.setProgress(this.openedTotemsCount);

			this.addTab(this.totemsButton, this.totemCollection, CollectionWindowTabs.TAB_TOTEMS);
		}

		// GALLERY

		if (this.galleryVideoModels.length > 0) {
			this.galleryCollection = new GalleryCollectionView(
				galleryVideoModels,
			);
			this.galleryButton = new GalleryButtonView(this.galleryVideoModels.length);

			this.galleryButton.setProgress(this.openedGalleryVideosCount);

			this.addTab(this.galleryButton, this.galleryCollection, CollectionWindowTabs.TAB_GALLERY);
		}

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(1860, 40);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.addChild(
			bg,
			leftBg,
			this.collections,
			this.closeButton,
		);

		this.open(config);
	}

	private onSomePromotableModelOpened(model: PromotableModel): void {
		if (model instanceof CharacterModel) {
			this.openedCharactersCount += 1;
			this.charactersButton.setProgress(this.openedCharactersCount);
		} else if (model instanceof UpgradeModel) {
			if (model.isFreeActivation()) {
				this.openedFurnitureCount += 1;
				this.furnitureButton.setProgress(this.openedUpgradesCount);
			} else {
				this.openedUpgradesCount += 1;
				this.upgradesButton.setProgress(this.openedUpgradesCount);
			}
		} else if (model instanceof TotemModel) {
			this.openedTotemsCount += 1;
			this.totemsButton.setProgress(this.openedTotemsCount);
		}
	}

	private onSomeEpicQuestCollectionModelRewardReached(
		progress: number,
		model: EpicQuestCollectionsModel,
	): void {
		// eslint-disable-next-line default-case
		switch (model.getType()) {
			case EpicQuestCollectionsTypes.CHARACTERS: {
				this.charactersButton.setInfoIconVisible(true);
				break;
			}
			case EpicQuestCollectionsTypes.TOTEMS: {
				this.totemsButton.setInfoIconVisible(true);
				break;
			}
			case EpicQuestCollectionsTypes.UPGRADES: {
				this.upgradesButton.setInfoIconVisible(true);
				break;
			}
			case EpicQuestCollectionsTypes.FURNITURE: {
				this.furnitureButton.setInfoIconVisible(true);
				break;
			}
		}
	}

	private onSomeEpicQuestCollectionModelRewardCollected(
		lootbox: LootboxModel,
		progress: number,
		model: EpicQuestCollectionsModel,
	): void {
		// eslint-disable-next-line default-case
		switch (model.getType()) {
			case EpicQuestCollectionsTypes.CHARACTERS: {
				this.updateTabButtonInfoIconVisible(CollectionWindowTabs.TAB_CHARACTERS);
				break;
			}
			case EpicQuestCollectionsTypes.TOTEMS: {
				this.updateTabButtonInfoIconVisible(CollectionWindowTabs.TAB_TOTEMS);
				break;
			}
			case EpicQuestCollectionsTypes.UPGRADES: {
				this.updateTabButtonInfoIconVisible(CollectionWindowTabs.TAB_UPGRADES);
				break;
			}
			case EpicQuestCollectionsTypes.FURNITURE: {
				this.updateTabButtonInfoIconVisible(CollectionWindowTabs.TAB_FURNITURE);
				break;
			}
		}
	}

	private onGalleryVideoOpened(): void {
		this.openedGalleryVideosCount += 1;

		this.galleryButton.setInfoIconVisible(true);
		this.galleryButton.setProgress(this.openedGalleryVideosCount);
	}

	private onGalleryVideoClose(): void {
		this.galleryCollection.enableScroll();
	}

	private updateTabButtonInfoIconVisible(tab: CollectionWindowTabs): void {
		// eslint-disable-next-line default-case
		switch (tab) {
			case CollectionWindowTabs.TAB_SUMMON: {
				this.summonButton.setInfoIconVisible(
					ModelHelper.canShowCollectionTabInfoIconSummon(this.summonModelsPool.getModelsList()),
				);
				break;
			}
			case CollectionWindowTabs.TAB_CHARACTERS: {
				const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconEpicQuests(
					this.epicQuestCollectionsModels.filter(model => model.getType() === EpicQuestCollectionsTypes.CHARACTERS),
				);
				this.charactersButton.setInfoIconVisible(infoIconVisible);
				break;
			}
			case CollectionWindowTabs.TAB_TOTEMS: {
				const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconEpicQuests(
					this.epicQuestCollectionsModels.filter(model => model.getType() === EpicQuestCollectionsTypes.TOTEMS),
				);
				this.totemsButton.setInfoIconVisible(infoIconVisible);
				break;
			}
			case CollectionWindowTabs.TAB_UPGRADES: {
				const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconEpicQuests(
					this.epicQuestCollectionsModels.filter((model) => model.getType() === EpicQuestCollectionsTypes.UPGRADES),
				);
				this.upgradesButton.setInfoIconVisible(infoIconVisible);
				break;
			}
			case CollectionWindowTabs.TAB_FURNITURE: {
				const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconEpicQuests(
					this.epicQuestCollectionsModels.filter((model) => model.getType() === EpicQuestCollectionsTypes.FURNITURE),
				);
				this.furnitureButton.setInfoIconVisible(infoIconVisible);
				break;
			}
			case CollectionWindowTabs.TAB_GALLERY: {
				this.galleryButton.setInfoIconVisible(ModelHelper.canShowCollectionTabInfoIconGallery(this.galleryVideoModels));
				break;
			}
		}
	}

	private updateTabButtonInfoIconSummon(): void {
		const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconSummon(
			this.summonModelsPool.getModelsList(),
		);
		this.summonButton.setInfoIconVisible(infoIconVisible);
	}

	private onUpdateTabButtonInfoIconGalleryVideo(): void {
		const infoIconVisible = ModelHelper.canShowCollectionTabInfoIconGallery(this.galleryVideoModels);
		this.galleryButton.setInfoIconVisible(infoIconVisible);
	}

	private onSummonModelPoolUpdate(): void {
		this.subscribeToSummonModels();
		this.updateTabButtonInfoIconSummon();
	}

	private subscribeToSummonModels(): void {
		this.summonModelsPool.getModelsList().forEach((model: SummonModel) => {
			if (model.isFreePrice() && !model.listeners(SummonModel.EVENT_SUMMON_AVAILABLE).includes(this.updateTabButtonInfoIconSummon)) {
				model.on(SummonModel.EVENT_SUMMON_AVAILABLE, this.updateTabButtonInfoIconSummon, this);
			}
		});
	}

	private open(config: CollectionWindowOpenConfig): void {
		if (this.currentActiveButton) {
			this.currentActiveButton.setActive(false);

			const currentCollection = this.collectionViewButtonMap.get(this.currentActiveButton);
			currentCollection.visible = false;
		}

		this.currentActiveTab = config.tab;

		// eslint-disable-next-line default-case
		switch (config.tab) {
			case CollectionWindowTabs.TAB_CHARACTERS: {
				this.currentActiveButton = this.charactersButton;
				break;
			}
			case CollectionWindowTabs.TAB_GALLERY: {
				this.currentActiveButton = this.galleryButton;
				break;
			}
			case CollectionWindowTabs.TAB_SUMMON: {
				this.currentActiveButton = this.summonButton;
				break;
			}
			case CollectionWindowTabs.TAB_TOTEMS: {
				this.currentActiveButton = this.totemsButton;
				break;
			}
			case CollectionWindowTabs.TAB_UPGRADES: {
				this.currentActiveButton = this.upgradesButton;
				break;
			}
			case CollectionWindowTabs.TAB_FURNITURE: {
				this.currentActiveButton = this.furnitureButton;
				break;
			}
		}

		if (this.currentActiveButton) {
			this.currentActiveButton.setActive(true);

			const newCollection = this.collectionViewButtonMap.get(this.currentActiveButton);
			newCollection.visible = true;
			newCollection.resetScrollValue();

			if (config.focusKey) {
				// eslint-disable-next-line default-case
				switch (config.tab) {
					case CollectionWindowTabs.TAB_CHARACTERS:
					case CollectionWindowTabs.TAB_SUMMON:
					case CollectionWindowTabs.TAB_UPGRADES: {
						(newCollection as (
							CharacterCollectionView |
							UpgradeCollectionGroupByBusinessesView |
							SummonCollectionView)).scrollTo(config.focusKey);
						break;
					}
				}
			} else {
				newCollection.startOpenAnimation();
			}
		} else {
			// eslint-disable-next-line no-console
			console.error(`Failed opening collection tab ${config.tab}`);
		}
	}

	private onNewCollectionShown(): void {
		switch (this.currentActiveTab) {
			case CollectionWindowTabs.TAB_SUMMON: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, this.summonCollection);
				break;
			}
			case CollectionWindowTabs.TAB_CHARACTERS: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_CHARACTER_VIEW_SHOWED, this.characterCollection);
				break;
			}
			case CollectionWindowTabs.TAB_UPGRADES: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_UPGRADE_VIEW_SHOWED, this.upgradeCollection);
				break;
			}
			case CollectionWindowTabs.TAB_FURNITURE: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_FURNITURE_VIEW_SHOWED, this.furnitureCollection);
				break;
			}
			case CollectionWindowTabs.TAB_TOTEMS: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_TOTEM_VIEW_SHOWED, this.totemCollection);
				break;
			}
			case CollectionWindowTabs.TAB_GALLERY: {
				this.emit(CollectionWindowView.EVENT_COLLECTION_GALLERY_VIEW_SHOWED, this.galleryCollection);
				break;
			}
			default: {
				if (MODE_DEBUG) {
					throw new Error(`Unsupported collection tab: ${this.currentActiveTab}`);
				}
				break;
			}
		}

		const newCollection = this.collectionViewButtonMap.get(this.currentActiveButton);

		if (newCollection instanceof CollectionWithProgressBaseView) {
			this.emit(CollectionWindowView.EVENT_COLLECTION_CARDS_PROGRESS_VIEW_SHOWED, newCollection.getCollectionCardsProgressView());
		}
	}

	public getEpicQuestLooboxButton(tabKey: CollectionWindowTabKeys): PIXI.Container {
		switch (tabKey) {
			case CollectionWindowTabKeys.CHARACTERS: {
				return this.characterCollection.getEpicQuestLootbox();
			}
			case CollectionWindowTabKeys.TOTEMS: {
				return this.totemCollection.getEpicQuestLootbox();
			}
			case CollectionWindowTabKeys.UPGRADES: {
				return this.upgradeCollection.getEpicQuestLootbox();
			}
			default: {
				throw new Error(`Unsupported collection tab key: ${tabKey}`);
			}
		}
	}

	public getButtonCloseContainer(): PIXI.Container {
		return this.closeButton;
	}

	public getSummonCollectionView(): SummonCollectionView {
		return this.summonCollection;
	}

	public getOpenenItemCharacterContainer(key: string): PIXI.Container {
		return this.characterCollection.getOpenedItemContainer(key);
	}

	public getOpenedItemUpgradeContainer(key: string): PIXI.Container {
		return this.upgradeCollection.getOpenedItemContainer(key);
	}

	public getOpenedItemTotemContainer(key: string): PIXI.Container {
		return this.totemCollection.getOpenedItemContainer(key);
	}

	public onShown(): void {
		this.setScrollEnabled(true);
		this.onNewCollectionShown();
		super.onShown();
	}

	public onFocus(): void {
		this.setScrollEnabled(true);
		super.onFocus();
	}

	public onBlur(): void {
		this.setScrollEnabled(false);
		super.onBlur();
	}

	public getTabButtonContainer(tab: CollectionWindowTabs): PIXI.Container {
		switch (tab) {
			case CollectionWindowTabs.TAB_CHARACTERS: {
				return this.charactersButton;
			}
			case CollectionWindowTabs.TAB_GALLERY: {
				return this.galleryButton;
			}
			case CollectionWindowTabs.TAB_SUMMON: {
				return this.summonButton;
			}
			case CollectionWindowTabs.TAB_TOTEMS: {
				return this.totemsButton;
			}
			case CollectionWindowTabs.TAB_UPGRADES: {
				return this.upgradesButton;
			}
			default: {
				throw new Error(`Unsupported tab type ${tab}`);
			}
		}
	}

	private setScrollEnabled(value: boolean): void {
		if (value) {
			Array.from(this.collectionViewButtonMap.values()).forEach(view => view.enableScroll());
		} else {
			Array.from(this.collectionViewButtonMap.values()).forEach(view => view.disableScroll());
		}
	}

	private addTab(
		button: TabButtonBaseView,
		content: CollectionBaseView,
		tabType: CollectionWindowTabs,
	): void {
		button.on(TabButtonBaseView.EVENT_CLICK, () => {
			this.open({ tab: tabType });
			this.onNewCollectionShown();
		});

		this.updateTabButtonInfoIconVisible(tabType);

		button.position.set(CollectionWindowView.COLLECTION_TAB_BUTTON_X_OFFSET, this.collectionTabButtonY);

		this.collectionViewButtonMap.set(button, content);

		this.collectionTabButtonY += CollectionWindowView.COLLECTION_TAB_BUTTON_Y_OFFSET;

		this.collections.addChild(
			content as PIXI.DisplayObject,
			button,
		);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.promotableModels.forEach(model => {
			model.off(PromotableModel.EVENT_OPENED, this.onSomePromotableModelOpened, this);
		});

		this.galleryVideoModels.forEach((model) => {
			model.off(GalleryVideoModel.EVENT_OPENED, this.onGalleryVideoOpened, this);
			model.off(GalleryVideoModel.EVENT_OLD, this.onUpdateTabButtonInfoIconGalleryVideo, this);
			model.off(GalleryVideoModel.EVENT_CLOSE, this.onGalleryVideoClose, this);
		});

		this.epicQuestCollectionsModels.forEach((model) => {
			model.off(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.onSomeEpicQuestCollectionModelRewardReached, this);
			model.off(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.onSomeEpicQuestCollectionModelRewardCollected, this);
		});

		this.summonModelsPool.off(SummonModelsPool.EVENT_MODELS_UPDATED, this.onSummonModelPoolUpdate, this);
		this.summonModelsPool.getModelsList().forEach((model) => model.off(SummonModel.EVENT_SUMMON_AVAILABLE, this.updateTabButtonInfoIconSummon, this));

		super.destroy(options);
	}
}
