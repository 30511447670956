import { RewardDescriptionType } from '@src/types/RewardTypes';

export enum NewsContentType {
	TEXT = 'Text',
	UPGRADE = 'Upgrade',
	CHARACTER = 'Character',
}

export type NewsContent = {
	type: string;
	text?: string;
	mainSpriteKey?: string;
	spriteList?: { spriteKey: string, text: string }[],
}

export class NewsItemConfig {
	public static readonly FIELD_KEY = 'id';
	public static readonly FIELD_PUSH = 'push';
	public static readonly FIELD_ICON = 'icon';
	public static readonly FIELD_DATE = 'start_date';
	public static readonly FIELD_TAB_TITLE = 'tab_text';
	public static readonly FIELD_HEADER_BG = 'header_background';
	public static readonly FIELD_HEADER_TEXT = 'header_text';
	public static readonly FIELD_HEADER_DATE = 'header_date';
	public static readonly FIELD_CONTENT_TYPE = 'content_type';
	public static readonly FIELD_TITLE = 'title_news_text'
	public static readonly FIELD_CONTENT = 'news_text';
	public static readonly FIELD_REWARD_TYPE = 'reward_type';
	public static readonly FIELD_REWARD_ID = 'reward_id';
	public static readonly FIELD_REWARD_COUNT = 'reward_qty';

	private readonly key: string;
	private readonly push: number;
	private readonly iconKey: string;
	private readonly date: string;
	private readonly tabTitle: string;
	private readonly headerBgKey: string;
	private readonly headerText: string;
	private readonly headerDate: string;
	private readonly title: string;
	private readonly content: NewsContent[];
	private readonly rewards: RewardDescriptionType[];

	constructor(config: Record<string, string>) {
		this.key = config[NewsItemConfig.FIELD_KEY].trim();
		this.push = Number(config[NewsItemConfig.FIELD_PUSH].trim());
		this.iconKey = config[NewsItemConfig.FIELD_ICON].trim();
		this.date = config[NewsItemConfig.FIELD_DATE].trim();
		this.tabTitle = config[NewsItemConfig.FIELD_TAB_TITLE].trim();
		this.headerBgKey = config[NewsItemConfig.FIELD_HEADER_BG].trim();
		this.headerText = config[NewsItemConfig.FIELD_HEADER_TEXT].trim();
		this.headerDate = config[NewsItemConfig.FIELD_HEADER_DATE].trim();
		this.title = config[NewsItemConfig.FIELD_TITLE].trim();
		this.content = NewsItemConfig.parseContent(config);
		this.rewards = NewsItemConfig.parseRewards(config);
	}

	public getKey(): string {
		return this.key;
	}

	public hasPush(): boolean {
		return this.push === 1;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getDate(): string {
		return this.date;
	}

	public getTabTitle(): string {
		return this.tabTitle;
	}

	public getHeaderBgKey(): string {
		return this.headerBgKey;
	}

	public getHeaderText(): string {
		return this.headerText;
	}

	public getHeaderDate(): string {
		return this.headerDate;
	}

	public getTitle(): string {
		return this.title;
	}

	public getContent(): NewsContent[] {
		return this.content;
	}

	public getRewards(): RewardDescriptionType[] {
		return this.rewards;
	}

	private static parseContent(config: Record<string, string>): NewsContent[] {
		const contentTypes = config[NewsItemConfig.FIELD_CONTENT_TYPE].trim().split(',');
		const contentItems = config[NewsItemConfig.FIELD_CONTENT].trim().split('/');

		return contentTypes.map((rawContentType, i) => {
			const contentType = rawContentType.trim();
			const contentConfig: NewsContent = { type: contentType };
			const contentItem = contentItems[i].trim();

			if (contentType === NewsContentType.TEXT) {
				contentConfig.text = contentItem;
			} else if (contentType === NewsContentType.UPGRADE || contentType === NewsContentType.CHARACTER) {
				const [mainSprite, mainText, ...spriteList] = contentItem.split(',').map((part) => part.trim());

				contentConfig.mainSpriteKey = mainSprite;
				contentConfig.text = mainText;
				contentConfig.spriteList = spriteList.map((spritePart) => {
					const [spriteKey, text] = spritePart.split('=').map((x) => x.trim());
					return { spriteKey, text };
				});
			} else {
				throw new Error(`Unsupported news ${NewsItemConfig.FIELD_CONTENT_TYPE}: ${contentType}`);
			}

			return contentConfig;
		});
	}

	private static parseRewards(config: Record<string, string>): RewardDescriptionType[] {
		let rewards: RewardDescriptionType[] = [];
		const rewardsTypes = config[NewsItemConfig.FIELD_REWARD_TYPE].trim();

		if (rewardsTypes !== '') {
			const rewardsIds = config[NewsItemConfig.FIELD_REWARD_ID].trim().split(',');
			const rewardsCount = config[NewsItemConfig.FIELD_REWARD_COUNT].trim().split(',');

			rewards = rewardsTypes.split(',').map((rewardType, i) => ({
				/* eslint-disable @typescript-eslint/naming-convention */
				reward_type: rewardType.trim(),
				reward_id: rewardsIds[i].trim(),
				reward_qty: rewardsCount[i].trim(),
				/* eslint-enable @typescript-eslint/naming-convention */
			}));
		}

		return rewards;
	}
}
