import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';

export class BusinessClickAnimation extends PIXI.Container {
	private static readonly GLOW_PARTICLES_CONFIG = {
		alpha: {
			start: 1,
			end: 0,
		},
		scale: {
			start: 0.1,
			end: 0.6,
			minimumScaleMultiplier: 1,
		},
		color: {
			start: '#ffffff',
			end: '#ffffff',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 0,
		},
		noRotation: true,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.2,
			max: 0.2,
		},
		blendMode: 'normal',
		frequency: 0.1,
		emitterLifetime: 0.2,
		maxParticles: 1,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	private static readonly HEART_PARTICLES_CONFIG = {
		alpha: {
			start: 1,
			end: 0,
		},
		scale: {
			start: 0.3,
			end: 0.4,
			minimumScaleMultiplier: 0.9,
		},
		color: {
			start: '#ffffff',
			end: '#ffffff',
		},
		speed: {
			start: 50,
			end: 150,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: -90,
			max: -90,
		},
		noRotation: true,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 0.5,
			max: 0.7,
		},
		blendMode: 'normal',
		frequency: 0.24,
		emitterLifetime: 0.3,
		maxParticles: 3,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'circle',
		spawnCircle: {
			x: 0,
			y: 0,
			r: 40,
		},
	};

	private heartCompleted: boolean;
	private glowCompleted: boolean;

	constructor() {
		super();

		this.heartCompleted = false;
		this.glowCompleted = false;


		const glowParticleEmitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_ring_click']).texture,
			],
			BusinessClickAnimation.GLOW_PARTICLES_CONFIG,
		);
		glowParticleEmitter.playOnceAndDestroy(() => {
			this.heartCompleted = true;
			this.tryDestroy();
		});

		const heartParticleEmitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['heart_click']).texture,
			],
			BusinessClickAnimation.HEART_PARTICLES_CONFIG,
		);
		heartParticleEmitter.playOnceAndDestroy(() => {
			this.glowCompleted = true;
			this.tryDestroy();
		});
	}

	private tryDestroy(): void {
		if (this.glowCompleted && this.heartCompleted) {
			this.destroy({ children: true });
		}
	}
}
