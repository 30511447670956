import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../bank/animations/BankParticleConfig';
import { BaseChallengeListItemView } from './BaseChallengeListItemView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class ChallengeListItemView extends BaseChallengeListItemView {
	constructor(
		panelBg: PIXI.mesh.NineSlicePlane,
		girls: PIXI.Sprite,
		text: string,
		glowSprite: string,
		lootBoxIcon: string,
		cardsMiniViewFactory: CardMiniViewFactory,
		rewards?: RewardDescriptionType[],
	) {
		super(
			panelBg,
			glowSprite,
			text,
			girls,
			cardsMiniViewFactory,
			rewards,
		);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[lootBoxIcon]);
		icon.position.set(410, -3);
		icon.scale.set(0.95);

		const animationsContainer = new PIXI.Container();
		animationsContainer.scale.set(0.2, 0.17);
		animationsContainer.mask = this.glowMask;
		animationsContainer.x = 404;

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['summon_flash3']],
			BankParticleConfig.getRecharcgePurpleGlow(),
		);
		glowEmitter.autoUpdate = true;


		const glowEmitter2 = new Emitter(
			animationsContainer,
			[fxAtlas['promote_glow3']],
			BankParticleConfig.getPurpleGlow(100, 0.1),
		);
		glowEmitter2.autoUpdate = true;

		const glowEmitter3 = new Emitter(
			animationsContainer,
			[fxAtlas['promote_glow3']],
			BankParticleConfig.getPurpleGlow(-100, 0.1),
		);
		glowEmitter3.autoUpdate = true;
		this.animationEmitters.push(glowEmitter, glowEmitter2, glowEmitter3);

		this.mainContainer.addChild(
			animationsContainer,
			icon,
			this.cardsContainer,
		);
	}
}
