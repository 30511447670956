import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter252001View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252001View';
import { EventCharacter252002View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252002View';
import { EventCharacter252003View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252003View';

export class EventBusiness252001View extends BusinessBaseView {
	constructor() {
		const eventCharacter252001View = new EventCharacter252001View();
		const eventCharacter252002View = new EventCharacter252002View();
		const eventCharacter252003View = new EventCharacter252003View();
		eventCharacter252003View.once(EventCharacter252003View.EVENT_SHOWN, () => eventCharacter252001View.destroy());

		super('business252001', [eventCharacter252001View, eventCharacter252002View, eventCharacter252003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter252001View as PIXI.DisplayObject,
			eventCharacter252002View,
			eventCharacter252003View,
		);
	}
}
