import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';
import { FarewellPartyEffectsConfigs } from '@views/windows/farewellParty/FarewellPartyEffectsConfigs';
import * as TWEEN from '@tweenjs/tween.js';

export class FarewellPartyEffectTrailsView extends PIXI.Container {
	public static readonly EVENT_COMPLETED: symbol = Symbol();

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly animationEmitters: Emitter[];

	constructor(
		startPos: PIXI.Point,
		targetRect: PIXI.Rectangle,
		callback: () => void,
	) {
		super();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
		this.animationEmitters = [];

		this.playTrailAnimation(startPos, FarewellPartyEffectTrailsView.getRandomPointInRect(targetRect), callback);
		this.playTrailAnimation(startPos, FarewellPartyEffectTrailsView.getRandomPointInRect(targetRect));
		this.playTrailAnimation(startPos, FarewellPartyEffectTrailsView.getRandomPointInRect(targetRect));
		this.playTrailAnimation(startPos, FarewellPartyEffectTrailsView.getRandomPointInRect(targetRect));
		this.playTrailAnimation(startPos, FarewellPartyEffectTrailsView.getRandomPointInRect(targetRect));
	}

	private static getRandomPointInRect(rect: PIXI.Rectangle): PIXI.Point {
		return new PIXI.Point(rect.x + Math.random() * rect.width, rect.y + rect.height / 2);
	}

	private playTrailAnimation(startPos: PIXI.Point, endPos: PIXI.Point, callback?: () => void): void {
		const trailEmitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow1']).texture,
			],
			FarewellPartyEffectsConfigs.AIM_TRAIL,
		);
		trailEmitter.updateOwnerPos(startPos.x, startPos.y);
		trailEmitter.autoUpdate = true;
		this.animationEmitters.push(trailEmitter);

		const tweenAlpha = new TWEEN.Tween(this, this.tweenGroup)
			.to({ alpha: 0 }, 300)
			.onComplete(() => {
				trailEmitter.destroy();
			});

		const deltaX = endPos.x - startPos.x;
		const deltaY = endPos.y - startPos.y;

		new TWEEN.Tween(new PIXI.Point(startPos.x, startPos.y), this.tweenGroup)
			.to({ x: [endPos.x + deltaX * 0.3, endPos.x], y: [startPos.y + deltaY * 0.2, endPos.y] }, 1000)
			.interpolation(TWEEN.Interpolation.Bezier)
			.onUpdate((tweenTarget) => {
				trailEmitter.updateOwnerPos(tweenTarget.x, tweenTarget.y);
			})
			.chain(tweenAlpha)
			.onComplete(() => {
				if (callback) {
					callback();
				}
			})
			.start();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
