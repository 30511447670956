import { TabButtonBaseView } from '@views/components/buttons/TabButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';

export class NewsTabButtonView extends TabButtonBaseView {
	public static readonly BG_HEIGHT = 123;
	private readonly infoIcon: PIXI.Sprite;
	protected readonly dateLabel: PIXI.extras.BitmapText;

	constructor(
		icon: PIXI.Sprite,
		buttonLabel: string,
		dateLabel: string,
	) {
		const inactiveBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_blue_tab2'], 7, 0, 7, 0);
		inactiveBg.width = 286;
		inactiveBg.height = NewsTabButtonView.BG_HEIGHT;
		inactiveBg.pivot.set(inactiveBg.width / 2, inactiveBg.height / 2);

		const activeBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_green_tab2'], 7, 0, 34, 0);
		activeBg.width = 318;
		activeBg.height = NewsTabButtonView.BG_HEIGHT;
		activeBg.pivot.set(activeBg.width / 2, activeBg.height / 2);
		activeBg.x = 13;

		icon.setTransform(-114, -10, 0.9, 0.9);
		icon.rotation = Math.PI / 180 * -4;

		super(
			inactiveBg,
			activeBg,
			icon,
			buttonLabel,
			190,
			inactiveBg.height * 0.8,
			{ font: '24px wendyOneShadowBold', align: 'left', tint: 0xd6e5ea },
		);

		this.buttonLabel.anchor = new PIXI.Point(0, 0.5);
		this.buttonLabel.letterSpacing = 2;
		this.buttonLabel.position.set(-64, -14);

		this.dateLabel = new TextField(
			dateLabel,
			{ font: '23px wendyOneShadowBold', align: 'left', tint: 0xffeb8b },
			190,
			inactiveBg.height * 0.8,
		);
		this.dateLabel.anchor = 0;
		this.dateLabel.position.set(-64, 13);

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.visible = true;
		this.infoIcon.setTransform(137, -52, 0.7, 0.7);

		this.addChild(
			this.dateLabel,
			this.infoIcon as PIXI.DisplayObject,
		);
	}

	public setActive(active: boolean): void {
		if (active) {
			this.buttonLabel.tint = 0xffffff;
			this.dateLabel.position.set(-50, 13);
			this.buttonLabel.position.set(-50, -14);
		} else {
			this.buttonLabel.tint = 0xd6e5ea;
			this.dateLabel.position.set(-64, 13);
			this.buttonLabel.position.set(-64, -14);
		}
		super.setActive(active);
	}

	public setInfoIconVisible(isVisible: boolean): void {
		this.infoIcon.visible = isVisible;
	}
}
