import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { BankModel } from '@models/bank/BankModel';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { ServerTimeModel } from '@models/network/ServerTimeModel';

export class ButtonBankView extends ButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly bankModel: BankModel;
	private readonly serverTimeModel: ServerTimeModel;

	private readonly label: PIXI.extras.BitmapText;
	private readonly infoIcon: PIXI.Sprite;

	private flyTextLabel: FlyBitmapText;

	private unlocked: boolean;
	private unlocksOnLevel: number;

	constructor(
		bankModel: BankModel,
		serverTime: ServerTimeModel,
	) {
		const buttonBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['shop_collection_button']);
		super(buttonBg);

		this.bankModel = bankModel;
		this.bankModel.on(BankModel.EVENT_UPDATED, this.updateInfoIconVisible, this);

		this.serverTimeModel = serverTime;

		this.unlocked = true;

		const buttonIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_shop']);
		buttonIcon.position.set(-7, 3);

		const buttonMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle']);
		buttonMask.scale.set(2.8);
		buttonMask.position.set(2, 15);

		const highlightAnimation = new SpriteHighlightAnimationView(buttonMask);
		highlightAnimation.position.set(buttonBg.x, buttonBg.y);
		highlightAnimation.scale.set(1.2);
		highlightAnimation.alpha = 0.6;
		highlightAnimation.start();

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		this.infoIcon = new PIXI.Sprite(collectionAtlas['can_promote_icon']);
		this.infoIcon.position.set(89, -70);
		this.infoIcon.visible = false;
		this.infoIcon.scale.set(0.7);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.label = new PIXI.extras.BitmapText('', { font: '36px wendyOneShadowBold', align: 'center' });
		this.label.y = 112;
		this.label.anchor = 0.5;
		this.addChild(
			buttonMask,
			highlightAnimation as PIXI.DisplayObject,
			buttonIcon,
			this.label,
			this.infoIcon as PIXI.DisplayObject,
		);

		this.updateText();
		this.updateInfoIconVisible();
	}

	public showLocked(unlocksOnLevel: number): void {
		this.unlocksOnLevel = unlocksOnLevel;

		(this.buttonBg as PIXI.Sprite).tint = 0x7F7F7F;
		this.label.tint = 0x7F7F7F;

		this.infoIcon.visible = false;

		this.on('pointerdown', this.tryShowFlyTextUnlocksOnLevel, this);
		this.off('pointertap', this.onPointerTap, this);
	}

	public showUnlocked(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0xFFFFFF;
		this.label.tint = 0xFFFFFF;

		if (!this.listeners('pointertap').includes(this.onPointerTap)) {
			this.on('pointertap', this.onPointerTap, this);
		}

		if (this.listeners('pointerdown').includes(this.tryShowFlyTextUnlocksOnLevel)) {
			this.off('pointerdown', this.tryShowFlyTextUnlocksOnLevel, this);
		}

		this.updateInfoIconVisible();
	}

	public setLocked(value: boolean): void {
		this.unlocked = !value;
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	private updateText(): void {
		this.label.text = this.localizationStorage.getLocalizedString('#gameUI_ShopButton');
	}

	private updateInfoIconVisible(): void {
		const isSubscribeClaimable = this.bankModel.isAnySubsrcibeClaimable(this.serverTimeModel.getCalculatedISOTime());
		const isRechargeClaimable = this.bankModel.isAnyRechargeClaimable();
		const isFreeBundleClaimable = this.bankModel.isAnyFreeBundleClaimable();

		this.infoIcon.visible = this.unlocked && (isSubscribeClaimable || isRechargeClaimable || isFreeBundleClaimable);
	}

	private tryShowFlyTextUnlocksOnLevel(): void {
		if (this.unlocksOnLevel !== undefined && !this.unlocked && !this.flyTextLabel) {
			let stringLabel = this.localizationStorage.getLocalizedString('#label_unlocks_on_level');
			stringLabel = stringLabel.replace('{{value}}', this.unlocksOnLevel.toString());

			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.y = -20;
			this.addChild(this.flyTextLabel);
		}
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		this.bankModel.off(BankModel.EVENT_UPDATED, this.updateInfoIconVisible, this);
		super.destroy();
	}
}
