import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character7View extends CharacterMainBaseView {
	constructor() {
		super('character7');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(202, 68);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(265, 58),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(340, 55),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
