import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeGetQuestModel extends TutorialStepUnlockTypeBaseModel {
	private readonly questLinesModel: QuestLinesModel;
	private readonly questKey: string;

	constructor(
		key: string,
		questKey: string,
		questLinesModel: QuestLinesModel,
	) {
		super(key, TutorialStepUnlockTypes.GET_QUEST);

		this.questKey = questKey;
		this.questLinesModel = questLinesModel;
	}

	public tryUnlock(): boolean {
		if (!this.unlocked) {
			const currentQuests = this.questLinesModel.getCurrentQuests();
			if (currentQuests.some(quest => quest.getKey() === this.questKey)) {
				this.unlocked = true;
				return true;
			}
		}
		return false;
	}
}
