import { LevelBackground } from './LevelBaseView';
import { Business1View } from '@views/businesses/building1/Business1View';
import { Business2View } from '@views/businesses/building1/Business2View';
import { Business3View } from '@views/businesses/building1/Business3View';
import { Business4View } from '@views/businesses/building2/Business4View';
import { Business5View } from '@views/businesses/building2/Business5View';
import { Business6View } from '@views/businesses/building2/Business6View';
import { Business7View } from '@views/businesses/building3/Business7View';
import { Business8View } from '@views/businesses/building3/Business8View';
import { Business9View } from '@views/businesses/building3/Business9View';
import { Business10View } from '@views/businesses/building4/Business10View';
import { Business11View } from '@views/businesses/building4/Business11View';
import { Business12View } from '@views/businesses/building4/Business12View';
import { Business13View } from '@views/businesses/building5/Business13View';
import { Business14View } from '@views/businesses/building5/Business14View';
import { Business15View } from '@views/businesses/building5/Business15View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { AssetsStorage } from '@main/AssetsStorage';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { LevelDefaultView } from './LevelDefaultView';
import { LevelSnowAnimationView } from './LevelSnowAnimationView';
import { GameConstants } from '@src/utils/GameConstants';

export class Season2LevelDefaultView extends LevelDefaultView {
	private static readonly LEVEL_WIDTH: number = 5130;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.DAY:
				this.createDayLevel();
				break;
			case TimesOfDayTypes.NIGHT:
				this.createNightLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.DAY:
				return this.createDayBackground();
			case TimesOfDayTypes.NIGHT:
				return this.createNightBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	private static createDayBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('canadaDayAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['canada_day/sky']).setTransform(2535, 512, 2600);

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/house2']).setTransform(132, 621),
			new PIXI.Sprite(levelBgAtlas['canada_day/house5']).setTransform(459, 601),
			new PIXI.Sprite(levelBgAtlas['canada_day/house4']).setTransform(604, 687),
			new PIXI.Sprite(levelBgAtlas['canada_day/house6']).setTransform(951, 436),
			new PIXI.Sprite(levelBgAtlas['canada_day/house3']).setTransform(1279, 713),
			new PIXI.Sprite(levelBgAtlas['canada_day/house1']).setTransform(1652, 744),
			new PIXI.Sprite(levelBgAtlas['canada_day/house2']).setTransform(2184, 621),
			new PIXI.Sprite(levelBgAtlas['canada_day/house4']).setTransform(2384, 687),
			new PIXI.Sprite(levelBgAtlas['canada_day/house3']).setTransform(2754, 713),
		);

		const roadSnow = new PIXI.extras.TilingSprite(levelBgAtlas['canada_day/snow_road'], Season2LevelDefaultView.LEVEL_WIDTH, 155);
		roadSnow.anchor.set(0, 1);
		roadSnow.scale.x = 10;
		roadSnow.y = GameConstants.GAME_HEIGHT + 15;

		backgroundContainer.addChild(
			sky,
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_day/road'], Season2LevelDefaultView.LEVEL_WIDTH, 217)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 1051),
			new PIXI.Sprite(levelBgAtlas['canada_day/city']).setTransform(845, 546),
			new PIXI.Sprite(levelBgAtlas['canada_day/city']).setTransform(2535, 546),
			new PIXI.Sprite(levelBgAtlas['canada_day/city']).setTransform(3380, 546),
			back,
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_day/river'], Season2LevelDefaultView.LEVEL_WIDTH, 109)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 894, 1, 0.9),
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_day/decor'], Season2LevelDefaultView.LEVEL_WIDTH, 55)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 928, 1, 0.9),
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createDayLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('canadaDayAtlas');

		this.background = Season2LevelDefaultView.createDayBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season2LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['canada_day/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_stairs']).setTransform(1085, 300.5),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['canada_day/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['canada_day/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/building_right1']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['canada_day/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_left']).setTransform(2152, 539),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_stairs']).setTransform(2925, 300.5),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/building_right2']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_top2']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['canada_day/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_left2']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['canada_day/logo_wet_pussy']).setTransform(3030, 500),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_day/building_right1']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['canada_day/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_left']).setTransform(3992, 539),
			new PIXI.Sprite(levelBgAtlas['canada_day/building_stairs']).setTransform(4765, 300.5),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season2LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	private static createNightBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('canadaNightAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['canada_night/sky']).setTransform(2535, 512, 2600);

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/house2']).setTransform(132, 621),
			new PIXI.Sprite(levelBgAtlas['canada_night/house5']).setTransform(459, 601),
			new PIXI.Sprite(levelBgAtlas['canada_night/house4']).setTransform(604, 687),
			new PIXI.Sprite(levelBgAtlas['canada_night/house6']).setTransform(951, 436),
			new PIXI.Sprite(levelBgAtlas['canada_night/house3']).setTransform(1279, 713),
			new PIXI.Sprite(levelBgAtlas['canada_night/house1']).setTransform(1652, 744),
			new PIXI.Sprite(levelBgAtlas['canada_night/house2']).setTransform(2184, 621),
			new PIXI.Sprite(levelBgAtlas['canada_night/house4']).setTransform(2384, 687),
			new PIXI.Sprite(levelBgAtlas['canada_night/house3']).setTransform(2754, 713),
		);

		const roadSnow = new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/snow_road'], Season2LevelDefaultView.LEVEL_WIDTH, 155);
		roadSnow.anchor.set(0, 1);
		roadSnow.scale.x = 10;
		roadSnow.y = GameConstants.GAME_HEIGHT + 15;

		backgroundContainer.addChild(
			sky,

			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/road'], Season2LevelDefaultView.LEVEL_WIDTH, 217)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 1051),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(845, 546),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(2535, 546),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(3380, 546),
			back,
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/river'], Season2LevelDefaultView.LEVEL_WIDTH, 109)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 894, 1, 0.9),
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/decor'], Season2LevelDefaultView.LEVEL_WIDTH, 55)
				.setTransform(Season2LevelDefaultView.LEVEL_WIDTH * 0.5, 928, 1, 0.9),
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createNightLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('canadaNightAtlas');

		this.background = Season2LevelDefaultView.createNightBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season2LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_stairs']).setTransform(1085, 300.5),

			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right1']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left']).setTransform(2152, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_stairs']).setTransform(2925, 300.5),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right2']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top2']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left2']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/logo_wet_pussy']).setTransform(3030, 500),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right1']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left']).setTransform(3992, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_stairs']).setTransform(4765, 300.5),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season2LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	protected getPrePartyCharacterPosition(): PIXI.Point {
		const randomIndex: number = this.getAcquiredBuildings().sort(() => Math.random() - 0.5).pop() || 0;

		const positions = [
			new PIXI.Point(1100, 853),
			new PIXI.Point(2020, 853),
			new PIXI.Point(2940, 853),
			new PIXI.Point(3860, 853),
			new PIXI.Point(4780, 853),
		];

		return positions[randomIndex];
	}

	// eslint-disable-next-line class-methods-use-this
	private createPrepartyStartCharacter(): PrepartyStartCharacterView {
		const view = new PrepartyStartCharacterView();
		view.visible = false;
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	private createSnowAnimation(wight: number): LevelSnowAnimationView {
		const view = new LevelSnowAnimationView(wight);
		return view;
	}
}
