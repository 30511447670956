import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { NumberUtils } from '@src/utils/NumberUtils';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import * as TWEEN from '@tweenjs/tween.js';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankParticleConfig } from '../animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';

export class BankTabElementHeaderSummonView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	private readonly girlLeft: PIXI.Sprite;

	private lootboxAvailableOn: number;
	private stickerLabel: TextField;
	private ticker: PIXI.ticker.Ticker;
	private tickerActive: boolean;
	private lootbox: PIXI.Container;
	private lootboxShakeInterval: any;
	private lootboxShakeAnimationGroup: TWEEN.Group;
	private lootoxHighlightAnimation: SpriteHighlightAnimationView;

	private lootboxAvailableOnLabel: TextField;
	private lootboxAvailableOnContainer: PIXI.Container;
	private buttonClaim: ButtonWithLabelBaseView;

	private tweenGroup: TWEEN.Group;

	private model: BankBundleModel;

	private animationEmitters: Emitter[];
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private animationsContainer: PIXI.Container;


	constructor(
		lootboxAvailableOn: number,
		model: BankBundleModel,
	) {
		super();

		this.model = model;
		this.model.once(BankBundleModel.EVENT_TIMEOUT, this.onBundleTimeout, this);

		this.ticker = PIXI.ticker.shared;
		this.lootboxAvailableOn = lootboxAvailableOn;
		this.tickerActive = false;
		this.lootboxShakeAnimationGroup = new TWEEN.Group();
		this.tweenGroup = new TWEEN.Group();
		this.animationEmitters = [];
		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const localizationStorage = LocalizationStorage.getInstance();


		const bgMask = new PIXI.Graphics();
		bgMask.beginFill(0x000000);
		bgMask.drawPolygon([
			new PIXI.Point(-692, -198),
			new PIXI.Point(0, -198),
			new PIXI.Point(0, -119),
			new PIXI.Point(692, -117),
			new PIXI.Point(677, 113),
			new PIXI.Point(-680, 115),
		]);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')[this.model.getBgKey()], 24, 0, 26, 0);
		bg.width = 1404;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const girlsContainer = new PIXI.Container();
		girlsContainer.position.set(-475, 0);

		if (model.hasCharacterRightKey()) {
			const girlRight = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterRightKey()}Dialog`]);
			girlRight.setTransform(95, 8, 0.82, 0.82);

			this.girlLeft = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterLeftKey()}Dialog`]);
			this.girlLeft.setTransform(-95, 8, 0.82, 0.82);

			girlsContainer.addChild(
				girlRight as PIXI.DisplayObject,
			);
		} else if (model.getCharacterLeftKey()) {
			this.girlLeft = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterLeftKey()}Dialog`]);
			this.girlLeft.scale.set(0.82);
		}

		girlsContainer.addChild(
			this.girlLeft,
		);
		girlsContainer.mask = bgMask;

		const title = new TextField(
			localizationStorage.getLocalizedString('#bank_header_summon_title'),
			{ font: '28px wendyOneShadowBold' },
			620,
			65,
		);
		title.anchor = 0.5;
		title.position.set(34, -51);

		const subtitle = new TextField(
			localizationStorage.getLocalizedString('#bank_header_summon_subtitle'),
			{ font: '66px wendyOneGold' },
			620,
			70,
		);
		subtitle.anchor = 0.5;
		subtitle.position.set(34, -6);

		const description = new TextField(
			localizationStorage.getLocalizedString('#bank_header_summon_description'),
			{ font: '26px wendyOneShadowBold', tint: 0xc4abc2 },
			620,
			40,
		);
		description.anchor = 0.5;
		description.position.set(34, 69);

		this.lootbox = new PIXI.Container();
		this.lootbox.position.set(521, -34);

		this.buttonClaim = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 228, 67),
			{ font: '24px wendyOneShadowBold' },
			220,
		);
		this.buttonClaim.setTextLabel(localizationStorage.getLocalizedString('#bank_subscribe_claim'));
		this.buttonClaim.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onLootboxClick, this);
		this.buttonClaim.position.set(516, 69);

		const stickerDaily = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerDaily.position.set(598, -62);
		stickerDaily.scale.set(0.68);
		this.stickerLabel = new TextField(
			localizationStorage.getLocalizedString('#label_daily_gift'),
			{ font: '18px wendyOne', tint: 0xfef996, align: 'center' },
			760,
			58,
		);
		this.stickerLabel.anchor = 0.5;
		this.stickerLabel.position.set(598, -62);
		this.stickerLabel.rotation = 0.47;

		const lootboxAvailableBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['big_offer_descr_fade'], 11, 0, 11, 0);
		lootboxAvailableBg.width = 236;
		lootboxAvailableBg.pivot.set(
			lootboxAvailableBg.width / 2,
			lootboxAvailableBg.height / 2,
		);
		this.lootboxAvailableOnLabel = new TextField(
			'',
			{ font: '30px wendyOneShadowBold' },
			150,
			26,
		);
		this.lootboxAvailableOnLabel.anchor = 0.5;

		this.lootboxAvailableOnContainer = new PIXI.Container();
		this.lootboxAvailableOnContainer.addChild(
			lootboxAvailableBg,
			this.lootboxAvailableOnLabel as PIXI.DisplayObject,
		);
		this.lootboxAvailableOnContainer.position.set(560, 87);

		this.animationsContainer = new PIXI.Container();
		this.animationsContainer.setTransform(517, -34, 0.23, 0.23);
		this.animationsContainer.alpha = 0.3;
		this.animationsContainer.mask = bgMask;

		const bgDecor = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['texture_decor']);
		bgDecor.setTransform(517, -4, 2.6, 2.6);
		bgDecor.blendMode = PIXI.BLEND_MODES.ADD;
		bgDecor.alpha = 0.3;
		bgDecor.mask = bgMask;

		const glowEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowLoghtsEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLoghtsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLoghtsEmitter);

		this.addChild(
			bg,
			bgMask,
			bgDecor,
			this.animationsContainer,
			girlsContainer,
			title as PIXI.DisplayObject,
			subtitle,
			description,
			this.lootbox,
			this.lootboxAvailableOnContainer,
			stickerDaily,
			this.stickerLabel,
			this.buttonClaim,
		);
		this.checkLootboxAvailable();
		this.ticker.add(this.updateLootbox, this);
		this.ticker.add(this.update, this);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private updateTimer(): void {
		this.checkLootboxAvailable();
	}

	private updateLootbox(): void {
		this.lootboxShakeAnimationGroup.update(this.ticker.lastTime);
	}

	private onBundleTimeout(): void {
		this.buttonClaim.setEnabled(false);
	}

	private checkLootboxAvailable(): void {
		const time = Date.now();
		const isAvailable = this.model.getRemainingCount() > 0;

		this.buttonClaim.visible = isAvailable;
		this.lootboxAvailableOnContainer.visible = !isAvailable;
		this.animationsContainer.visible = isAvailable;

		if (isAvailable) {
			this.lootbox.removeChildren();
			const lootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['legendary_lootbox_main']);
			lootboxSprite.scale.set(0.8);
			const hightlightMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main_mask']);
			hightlightMask.scale.set(1.4);
			this.lootoxHighlightAnimation = new SpriteHighlightAnimationView(hightlightMask, 1000);
			this.lootoxHighlightAnimation.start();
			this.lootbox.addChild(
				lootboxSprite,
				this.lootoxHighlightAnimation as PIXI.DisplayObject,
			);
			this.lootboxShakeInterval = setInterval(() => {
				const shakeEnd = new TWEEN.Tween(this.lootbox, this.lootboxShakeAnimationGroup)
					.to({ rotation: 0 }, 35)
					.easing(TWEEN.Easing.Cubic.Out);
				const shakeMain = new TWEEN.Tween(this.lootbox, this.lootboxShakeAnimationGroup)
					.to({ rotation: 0.05 }, 70)
					.easing(TWEEN.Easing.Cubic.InOut)
					.yoyo(true)
					.repeat(3)
					.chain(shakeEnd);
				new TWEEN.Tween(this.lootbox, this.lootboxShakeAnimationGroup)
					.to({ rotation: -0.05 }, 35)
					.easing(TWEEN.Easing.Cubic.In)
					.chain(shakeMain)
					.start();
			}, 3000);

			if (this.tickerActive) {
				this.ticker.remove(this.updateTimer, this);
				this.tickerActive = false;
			}
		} else {
			this.lootboxAvailableOnLabel.text = NumberUtils.secToDHMS(Math.max(0, Math.ceil(this.lootboxAvailableOn - time / 1000)));
			if (!this.tickerActive) {
				this.lootbox.removeChildren();
				const lootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main']);
				lootboxSprite.scale.set(0.8);
				this.lootbox.addChild(lootboxSprite);
				this.lootbox.interactive = false;
				this.lootbox.off('pointerdown', this.onLootboxClick, this);

				clearInterval(this.lootboxShakeInterval);
				this.lootboxShakeAnimationGroup.removeAll();

				this.ticker.add(this.updateTimer, this);
				this.tickerActive = true;
			}
		}
	}

	private onLootboxClick(): void {
		this.emit(BankTabElementHeaderSummonView.EVENT_BUTTON_BUY_CLICK);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(BankBundleModel.EVENT_TIMEOUT, this.onBundleTimeout, this, true);
		if (this.tickerActive) {
			this.ticker.remove(this.updateTimer, this);
		} else {
			clearInterval(this.lootboxShakeInterval);
			this.lootboxShakeAnimationGroup.removeAll();
		}
		this.tweenGroup.removeAll();
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.ticker.remove(this.update);
		this.ticker.remove(this.updateLootbox, this);
		super.destroy(options);
	}
}
