import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SkillModel } from '@models/skills/SkillModel';
import { CharacterModel } from '@models/CharacterModel';
import * as TWEEN from '@tweenjs/tween.js';
import { CardRarities } from '@src/types/CardRarities';
import { TextDescriptionHelperCharacter } from '@views/windows/collection/characters/TextDescriptionHelperCharacter';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { GameConstants } from '@src/utils/GameConstants';
import { BaseEventLevelCharacterHintView } from './BaseEventLevelCharacterHintView';

export type EventLevelCharacterSkillAutoBusinessHintData = {
	character: CharacterModel;
	skill?: SkillModel;
	bodyArrowOffset?: number;
};

export class EventLevelCharacterSkillAutoBusinessHintView extends BaseEventLevelCharacterHintView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly bg: PIXI.mesh.NineSlicePlane;
	private readonly fade: PIXI.mesh.NineSlicePlane;

	private readonly textBusinessName: MultiColoredTextField;

	private readonly textTitle: MultiColoredTextField;
	private readonly textRarity: MultiColoredTextField;
	private readonly textDescr: MultiColoredTextField;

	private readonly skillContainer: PIXI.Container;
	private readonly textSkillTitle: MultiColoredTextField;
	private readonly textSkillDescr: MultiColoredTextField;
	private readonly textAutomate: MultiColoredTextField;
	private readonly textSkillSticker: SizeableBitmapText;
	private readonly skillActiveSticker: PIXI.Container;
	private readonly skillIcon: PIXI.Sprite;

	private readonly automateContainer: PIXI.Container;

	private readonly descriptionContainer: PIXI.Container;

	private scaleToY: string;

	constructor() {
		super();
		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		this.bg.width = 573;
		this.bg.height = 463;
		this.bg.y = 237;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.fade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 0, 0, 0, 0);
		this.fade.width = this.bg.width + 120;
		this.fade.height = this.bg.height + 120;
		this.fade.y = this.bg.y;
		this.fade.tint = 0x000000;
		this.fade.pivot.set(this.fade.width / 2, this.fade.height / 2);

		this.textTitle = new MultiColoredTextField({ font: '37px wendyOneShadowBold' }, 340, 60);
		this.textTitle.anchor = 0.5;
		this.textTitle.position.set(0, 60);

		this.textRarity = new MultiColoredTextField({ font: '25px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textRarity.anchor = 0.5;
		this.textRarity.position.set(0, 92);

		this.textBusinessName = new MultiColoredTextField({ font: '24px wendyOneShadowBold', align: 'center', tint: 0xb4ccda }, 470, 70);
		this.textBusinessName.anchor = 0.5;
		this.textBusinessName.position.set(0, 142);

		const textBusinessFade = new PIXI.Graphics();
		textBusinessFade.beginFill(0x30444d);
		textBusinessFade.drawPolygon([
			new PIXI.Point(-122, 20),
			new PIXI.Point(117, 20),
			new PIXI.Point(122, -20),
			new PIXI.Point(-117, -20),
		]);
		textBusinessFade.pivot.set(textBusinessFade.width / 2 - 120, textBusinessFade.height / 2);
		textBusinessFade.position.set(this.textBusinessName.x, 161);

		const textDescrFade = new PIXI.Graphics();
		textDescrFade.beginFill(0x15151d);
		textDescrFade.drawPolygon([
			new PIXI.Point(-250, 41),
			new PIXI.Point(243, 41),
			new PIXI.Point(250, -41),
			new PIXI.Point(-250, -41),
		]);
		textDescrFade.pivot.set(textDescrFade.width / 2, textDescrFade.height / 2);
		textDescrFade.position.set(250, 427);

		this.textDescr = new MultiColoredTextField({ font: '22px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textDescr.anchor = 0.5;
		this.textDescr.position.set(0, 388);

		const autoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['auto_icon']);
		autoIcon.position.set(-195, 319);

		const autoPanel = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['auto_panel'], 0, 0, 8, 0);
		autoPanel.width = 506;
		autoPanel.y = 319;
		autoPanel.x = 3;
		autoPanel.pivot.set(autoPanel.width / 2, autoPanel.height / 2);

		this.textAutomate = new MultiColoredTextField({ font: '24px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textAutomate.anchor = 0.5;
		this.textAutomate.position.set(20, 319);
		this.textAutomate.text = this.localizationStorage.getLocalizedString('#business_automatization_label');

		this.automateContainer = new PIXI.Container();
		this.automateContainer.addChild(
			autoPanel,
			this.textAutomate as PIXI.DisplayObject,
			autoIcon,
		);

		this.descriptionContainer = new PIXI.Container();
		this.descriptionContainer.addChild(
			textDescrFade as PIXI.DisplayObject,
			this.textDescr,
		);

		const skillFade = new PIXI.Graphics();
		skillFade.beginFill(0x15151d);
		skillFade.drawPolygon([
			new PIXI.Point(-250, 50),
			new PIXI.Point(237, 50),
			new PIXI.Point(250, -50),
			new PIXI.Point(-250, -50),
		]);
		skillFade.pivot.set(skillFade.width / 2, skillFade.height / 2);
		skillFade.position.set(250, 226);

		this.textSkillDescr = new MultiColoredTextField({ font: '22px wendyOneShadowBold', align: 'left' }, 390, 54);
		this.textSkillDescr.anchor = new PIXI.Point(0, 0.5);
		this.textSkillDescr.position.set(-152, 195);

		this.skillActiveSticker = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['orange_panel'], 10, 0, 10, 0);
		this.skillActiveSticker.width = 104;
		this.skillActiveSticker.pivot.set(
			this.skillActiveSticker.width / 2,
			this.skillActiveSticker.height / 2,
		);
		this.skillActiveSticker.position.set(205, 130);

		this.textSkillSticker = new SizeableBitmapText(
			'',
			87,
			{ font: '20px wendyOneShadowBold', align: 'center' },
		);
		this.textSkillSticker.position.set(52, 15);
		this.textSkillSticker.anchor = 0.5;
		this.textSkillSticker.text = this.localizationStorage.getLocalizedString('#skill_hint_active');
		this.skillActiveSticker.addChild(this.textSkillSticker);

		this.textSkillTitle = new MultiColoredTextField({ font: '25px wendyOneShadowBold', align: 'center' }, 470, 70);
		this.textSkillTitle.anchor = new PIXI.Point(0, 0.5);
		this.textSkillTitle.position.set(-152, 157);

		this.skillIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['skill_icon7']);
		this.skillIcon.position.set(-203, 175);
		this.skillIcon.scale.set(0.8);

		this.skillContainer = new PIXI.Container();
		this.skillContainer.addChild(
			skillFade,
			this.textSkillTitle as PIXI.DisplayObject,
			this.textSkillDescr,
			this.skillActiveSticker,
			this.skillIcon,
		);

		this.addChild(
			this.fade,
			this.bg,
			this.arrowUp as PIXI.DisplayObject,
			this.arrowDown,
			textBusinessFade,
			this.textTitle,
			this.textRarity,
			this.descriptionContainer,
			this.skillContainer,
			this.automateContainer,
			this.textBusinessName,
		);
	}

	public init(data: EventLevelCharacterSkillAutoBusinessHintData): void {
		const { character, skill } = data;
		this.textTitle.text = this.localizationStorage.getLocalizedString(`#${character.getKey()}_Name`);

		const rarity = character.getCardRarity();
		this.textRarity.tint = CardRarities.getRarityColor(rarity);
		this.textRarity.text = this.localizationStorage.getLocalizedString(`#hint_rarity_${rarity}`);

		this.textDescr.text = TextDescriptionHelperCharacter.getDescription(character, skill);

		this.textBusinessName.text = this.localizationStorage.getLocalizedString(`#${character.getRelatedBusinessKey()}_Name`);

		if (character.hasBonusAutomate() && skill) {
			const skillDescData = TextDescriptionHelperCharacter.getSkillDescription(skill);
			this.textSkillTitle.text = skillDescData.skillName;
			this.textSkillDescr.text = skillDescData.skillDesc;
			this.skillIcon.texture = AssetsStorage.getAtlas('uiAtlas')[skill.getIconKey()];
			this.automateContainer.visible = true;
			this.skillContainer.visible = true;
			this.descriptionContainer.visible = false;

			this.automateContainer.y = -25;
			this.skillContainer.y = 34;

			this.bg.height = 355;
			this.fade.height = this.bg.height + 120;

			this.arrowDown.y = 365.5;
			this.scaleToY = '-328';
		} else if (character.hasBonusAutomate()) {
			this.automateContainer.visible = true;
			this.skillContainer.visible = false;
			this.descriptionContainer.visible = true;


			this.automateContainer.y = -133;
			this.descriptionContainer.y = -125;

			this.bg.height = 327;
			this.fade.height = this.bg.height + 120;

			this.arrowDown.y = 337.5;
			this.scaleToY = '-300';
		} else if (skill) {
			const skillDescData = TextDescriptionHelperCharacter.getSkillDescription(skill);
			this.textSkillTitle.text = skillDescData.skillName;
			this.textSkillDescr.text = skillDescData.skillDesc;
			this.skillIcon.texture = AssetsStorage.getAtlas('uiAtlas')[skill.getIconKey()];

			this.automateContainer.visible = false;
			this.descriptionContainer.visible = false;
			this.skillContainer.visible = true;

			this.skillContainer.y = 30;

			this.bg.height = 290;
			this.fade.height = this.bg.height + 120;

			this.arrowDown.y = 301;
			this.scaleToY = '-262';
		}

		if (data.bodyArrowOffset != null) {
			this.setBgArrowOffset(data.bodyArrowOffset);
		}
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		const scaleStartY = this.y - 40;
		if (this.getGlobalPosition().y > GameConstants.GAME_HEIGHT / 2) {
			this.arrowUp.visible = false;
			this.arrowDown.visible = true;
		} else {
			this.scaleToY = '+40';
			this.arrowUp.visible = true;
			this.arrowDown.visible = false;
		}

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y = scaleStartY;
		new TWEEN.Tween(this)
			.to({ y: this.scaleToY }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}

	private updateText(): void {
		this.textSkillSticker.text = this.localizationStorage.getLocalizedString('#skill_hint_active');
		this.textAutomate.text = this.localizationStorage.getLocalizedString('#business_automatization_label');
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
