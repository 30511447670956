import { EventLevelModel, EventLevelStateType } from '@models/level/EventLevelModel';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';

export class ModelWriterEventTutorialSteps {
	constructor(
		private readonly eventLevelModel: EventLevelModel,
		private readonly eventTutorialStepsModel: EventTutorialStepsModel,
	) {
		this.eventLevelModel.on(EventLevelModel.EVENT_INITED, this.onEventInited, this);
	}

	private onEventInited(): void {
		// Reset local save data for the next event
		if (this.eventLevelModel.getState() === EventLevelStateType.DEFAULT) {
			this.eventTutorialStepsModel.resetCompletedSteps();
		}
	}
}
