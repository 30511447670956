import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { NumberUtils } from '@src/utils/NumberUtils';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import * as TWEEN from '@tweenjs/tween.js';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { BankUIViewHelper } from '../BankUIViewHelper';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { BankPriceResourceTypes } from '@src/types/BankTypes';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';

export type BundleRewardDisplayInfo = {
	icon: PIXI.Container;
	count: number;
};

export class BankTabElementBundleHorizontalView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();
	public static readonly EVENT_NOT_ENOUGH_HARD = Symbol();
	public static readonly EVENT_NOT_ENOUGH_PRESTIGE = Symbol();

	private static readonly REWARD_ITEM_WIDTH: number = 108;

	private readonly localizationStorage: LocalizationStorage;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly girlLeft: PIXI.Sprite;
	private readonly lockedButtonTimeLabel: SizeableBitmapText;
	private readonly timeRemainLabel: TextField;
	private readonly tweenGroup: TWEEN.Group;
	private readonly bundleWorth: MultiColoredTextField;
	private buyButton: ButtonWithCostValueView;

	constructor(
		private readonly model: BankBundleModel,
		private readonly bankUIViewHelper: BankUIViewHelper,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
		private readonly bankPriceItemsConfig: BankPriceItemsConfig,
		private readonly costResource: ButtonValueTypes,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private rewards?: RewardDescriptionType[],
		private readonly offerModel?: BankOfferModel,
	) {
		super();

		this.ticker = PIXI.ticker.shared;
		this.tweenGroup = new TWEEN.Group();
		this.localizationStorage = LocalizationStorage.getInstance();

		const bgMask = new PIXI.Graphics();
		bgMask.beginFill(0x000000);
		bgMask.drawPolygon([
			new PIXI.Point(-692, -135),
			new PIXI.Point(692, -133),
			new PIXI.Point(679, 128),
			new PIXI.Point(-680, 129),
		]);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('bankAtlas')['time_bundle_bg'], 23, 0, 25, 0);
		bg.width = 1404;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const optionalBg = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')[`optional_bundle_${this.model.getBgKey()}`]);
		optionalBg.setTransform(-180, -2, 2.05, 2.05);
		optionalBg.mask = bgMask;

		const valueBg = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['time_bundle_value_bg']);
		valueBg.setTransform(520, -2, 2.05, 2.05);
		valueBg.mask = bgMask;

		const glowDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowDecor.setTransform(350, -2, 0, 0, Math.PI / 180 * 94);
		glowDecor.blendMode = PIXI.BLEND_MODES.SCREEN;
		glowDecor.mask = bgMask;

		const girlsMask = new PIXI.Graphics();
		girlsMask.beginFill(0x000000);
		girlsMask.drawPolygon([
			new PIXI.Point(-696, -185),
			new PIXI.Point(692, -183),
			new PIXI.Point(679, 128),
			new PIXI.Point(-680, 129),
		]);
		girlsMask.endFill();

		const girlsContainer = new PIXI.Container();
		girlsContainer.position.set(-475, 0);
		girlsContainer.mask = girlsMask;

		if (model.hasCharacterRightKey()) {
			const girlRight = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterRightKey()}Dialog`]);
			girlRight.setTransform(95, 8, 0.82, 0.82);

			this.girlLeft = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterLeftKey()}Dialog`]);
			this.girlLeft.setTransform(-95, 8, 0.82, 0.82);

			girlsContainer.addChild(
				girlRight as PIXI.DisplayObject,
			);
		} else if (model.getCharacterLeftKey()) {
			this.girlLeft = new PIXI.Sprite(AssetsStorage.getAtlas('dialogAtlas')[`${model.getCharacterLeftKey()}Dialog`]);
			this.girlLeft.scale.set(0.82);
		}

		girlsContainer.addChild(
			this.girlLeft,
		);

		const nameText = this.localizationStorage.getLocalizedString(this.model.getNameLocale());
		const name = new MultiColoredTextField(
			{ font: '42px wendyOneGold', tint: 0xfff78f, align: 'center' },
			307,
			147,
		);
		name.text = nameText;
		name.anchor = 0.5;
		name.position.set(520, -51);

		this.bundleWorth = new MultiColoredTextField(
			{ font: '28px wendyOneShadowBold' },
			620,
			65,
		);
		this.bundleWorth.anchor = 0.5;
		this.bundleWorth.position.set(32, -83);

		this.addChild(
			bg,
			optionalBg as PIXI.DisplayObject,
			valueBg,
			glowDecor,
			bgMask,
			girlsContainer,
			girlsMask,
			name,
			this.bundleWorth,
		);

		if (this.model.getRemainingCount() === 0) {
			const lockedBundleButtonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
			lockedBundleButtonBg.tint = 0x000000;
			lockedBundleButtonBg.alpha = 0.25;
			lockedBundleButtonBg.width = 241;
			lockedBundleButtonBg.height = 74;
			lockedBundleButtonBg.pivot.set(lockedBundleButtonBg.width / 2, lockedBundleButtonBg.height / 2);
			this.buyButton = new ButtonWithCostValueView({
				type: this.costResource,
				buttonBg: lockedBundleButtonBg,
				maxWidth: 108,
				fontStyle: { font: { size: 36, name: 'wendyOneShadowBold' } },
				costTextOffset: new PIXI.Point(0, -4),
			});
			this.buyButton.interactive = true;
			this.buyButton.position.set(-5.5, 235);

			const lockedButtonLabel = new SizeableBitmapText(
				this.localizationStorage.getLocalizedString('#locked_bundle_button'),
				200,
				{ font: '19px wendyOne', tint: 0x445566 },
			);
			lockedButtonLabel.anchor = 0.5;
			lockedButtonLabel.position.set(0, -15);

			this.lockedButtonTimeLabel = new SizeableBitmapText(
				'',
				200,
				{ font: '26px wendyOneShadowBold' },
			);
			this.lockedButtonTimeLabel.anchor = 0.5;
			this.lockedButtonTimeLabel.position.set(
				0,
				10,
			);
			this.buyButton.addChild(
				lockedButtonLabel,
				this.lockedButtonTimeLabel,
			);
			this.addChild(this.buyButton);
		} else {
			this.createBuyButton();

			const bgTimer = new PIXI.Graphics();
			bgTimer.beginFill(0x000000);
			bgTimer.drawPolygon([
				new PIXI.Point(-80, -18),
				new PIXI.Point(72, -18),
				new PIXI.Point(80, 18),
				new PIXI.Point(-79, 18),
			]);
			bgTimer.alpha = 0.7;
			bgTimer.position.set(-603, 111);

			this.timeRemainLabel = new TextField(
				'',
				{ font: '30px wendyOneShadowBold', tint: 0xffda68 },
				280,
				40,
			);
			this.timeRemainLabel.pivot.set(this.timeRemainLabel.width / 2, this.timeRemainLabel.height / 2);
			this.timeRemainLabel.position.set(-595, 117);
			this.addChild(
				bgTimer,
				this.timeRemainLabel as PIXI.DisplayObject,
			);
			this.ticker.add(this.update, this);
		}

		if (this.rewards !== undefined && this.rewards !== null) {
			this.onUpdateRewards();
		}

		if (model.getMostPopularLocaleKey()) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSale.position.set(682.5, -111.5);
			const saleLabel = new TextField(
				this.localizationStorage.getLocalizedString(model.getMostPopularLocaleKey()),
				{ font: '20px wendyOne', align: 'center', tint: 0xfff78f },
				55,
				55,
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.46;
			saleLabel.position.set(stickerSale.x, stickerSale.y);

			const stickerSaleMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			const stickerSaleHightlight = new SpriteHighlightAnimationView(stickerSaleMask, 800, false);
			stickerSaleHightlight.position.set(stickerSale.x, stickerSale.y);

			this.addChild(
				stickerSale,
				stickerSaleHightlight,
				saleLabel as PIXI.DisplayObject,
			);
			stickerSaleHightlight.start();
		}

		this.model.once(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this);
		this.offerModel?.once(BankOfferModel.EVENT_TIMEOUT, this.onTimeout, this);
	}

	public setRewards(rewards: RewardDescriptionType[]): void {
		this.rewards = rewards;
		this.onUpdateRewards();
	}

	// eslint-disable-next-line class-methods-use-this
	private getRewardsRow(rewardsDisplayInfo: BundleRewardDisplayInfo[]): PIXI.Container[] {
		const rewardsRow: PIXI.Container[] = [];
		const rowWidth = (BankTabElementBundleHorizontalView.REWARD_ITEM_WIDTH * rewardsDisplayInfo.length) + 5 * (rewardsDisplayInfo.length - 1);
		rewardsDisplayInfo.forEach((rewardDisplayInfo, index): void => {
			const rewardIcon = rewardDisplayInfo.icon;
			const rewardContainer = new PIXI.Container();
			const rewardCount = new TextField(
				`x${SoftMoneyNumber.createFromNumber(rewardDisplayInfo.count).toString()}`,
				{ font: '30px wendyOneShadowBold' },
				BankTabElementBundleHorizontalView.REWARD_ITEM_WIDTH - 10,
				35,
			);
			rewardCount.anchor = 0.5;
			rewardCount.tint = 0xffd86d;
			rewardCount.position.set(0, 49);
			rewardContainer.addChild(
				rewardIcon,
				rewardCount as PIXI.DisplayObject,
			);
			rewardContainer.position.set(
				(-rowWidth / 2)
				+ (index * BankTabElementBundleHorizontalView.REWARD_ITEM_WIDTH)
				+ (index * 5) + BankTabElementBundleHorizontalView.REWARD_ITEM_WIDTH / 2
				+ 32,
				17,
			);
			rewardsRow.push(rewardContainer);
		});
		return rewardsRow;
	}

	private onUpdateRewards(): void {
		const rewardsDisplayInfo = this.rewards.map((rewardData: RewardDescriptionType): BundleRewardDisplayInfo => {
			const icon = this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardData.reward_type as RewardTypes,
				rewardData.reward_id,
				0.7,
				0.7,
				CardMiniViewFactory.createStickerNew(),
			);
			icon.interactive = true;
			icon.on('pointerdown', (event: PIXI.interaction.InteractionEvent) => {
				event.stopPropagation();
				this.emit(BankTabElementBundleHorizontalView.EVENT_SHOW_HINT, rewardData, icon);
			});
			return { icon, count: Number(rewardData.reward_qty) };
		});

		const rewardsRow = this.getRewardsRow(rewardsDisplayInfo);
		this.addChild(...rewardsRow);

		const bundleValue = this.bankUIViewHelper.resolveBankBundleDescriptionString(
			this.bankPriceItemsConfig,
			this.rewards,
		);

		const descriptionText = this.localizationStorage.getLocalizedString(this.model.getDescriptionLocaleKey())
			.replace('{{value}}', bundleValue);
		this.bundleWorth.text = descriptionText;
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
		const timeleft = this.offerModel ? this.offerModel.getTimeleft() : this.model.getTimeLeft();
		const timeleftFormatted = NumberUtils.secToDHMS(timeleft);
		if (timeleft > 0) {
			this.timeRemainLabel.text = `${timeleftFormatted}`;
			this.timeRemainLabel.pivot.set(
				this.timeRemainLabel.width / 2,
				this.timeRemainLabel.height / 2,
			);
		}
	}

	private onTimeout(): void {
		this.buyButton.setEnabled(false);
	}

	private createBuyButton(): void {
		if (this.buyButton) {
			this.buyButton.destroy();
		}
		const buyButtonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buyButtonBg.width = 248;
		buyButtonBg.height = 72;
		buyButtonBg.pivot.set(buyButtonBg.width / 2, buyButtonBg.height / 2);

		this.buyButton = new ButtonWithCostValueView({
			type: this.costResource,
			buttonBg: buyButtonBg,
			maxWidth: 108,
			fontStyle: { font: { size: 36, name: 'wendyOneShadowBold' } },
			costTextOffset: new PIXI.Point(0, -4),
		});
		this.buyButton.on(ButtonBaseView.EVENT_CLICK, this.onBuyButtonClick, this);
		this.buyButton.interactive = true;
		this.buyButton.setCost(String(this.model.getPrice()));
		this.buyButton.setCostValueEnabled(this.isEnoughMoneyForBuy());
		this.buyButton.position.set(520, 50);

		this.addChild(this.buyButton);

		if (this.model.hasOldPrice()) {
			this.buyButton.setOldPrice(this.costResource, this.model.getOldPrice());
		}
	}

	private isEnoughMoneyForBuy(): boolean {
		let isEnable: boolean;
		if (this.model.getPriceResource() === BankPriceResourceTypes.HARD_MONEY
			&& this.hardMoneyModel.getValue() < this.model.getPrice()) {
			isEnable = false;
		} else if (this.model.getPriceResource() === BankPriceResourceTypes.PRESTIGE_MONEY
			&& this.prestigeMoneyModel.getValue() < this.model.getPrice()) {
			isEnable = false;
		} else {
			isEnable = true;
		}
		return isEnable;
	}

	private onBuyButtonClick(): void {
		if (this.model.getPriceResource() === BankPriceResourceTypes.HARD_MONEY
			&& this.hardMoneyModel.getValue() < this.model.getPrice()) {
			this.emit(BankTabElementBundleHorizontalView.EVENT_NOT_ENOUGH_HARD);
		} else if (this.model.getPriceResource() === BankPriceResourceTypes.PRESTIGE_MONEY
			&& this.prestigeMoneyModel.getValue() < this.model.getPrice()) {
			this.emit(BankTabElementBundleHorizontalView.EVENT_NOT_ENOUGH_PRESTIGE);
		} else {
			this.emit(BankTabElementBundleHorizontalView.EVENT_BUTTON_BUY_CLICK);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this, true);
		this.offerModel?.off(BankBundleModel.EVENT_TIMEOUT, this.onTimeout, this, true);
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
