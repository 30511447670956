import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { TextField } from '@views/components/text/TextField';
import { BankInfoIconView } from '@views/windows/bank/BankInfoIconView';
import LocalizationStorage from '@main/LocalizationStorage';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import * as TWEEN from '@tweenjs/tween.js';

export class BankTabElementGemShopDefaultView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	private infoIcon: BankInfoIconView;
	private localizationStorage: LocalizationStorage;
	private resourcePackSprite: PIXI.Sprite;
	private animationEmitters: Emitter[];

	private buyButton: ButtonWithCostValueView;
	private mouseOver: boolean;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	constructor(
		rewardIcon: PIXI.Sprite,
		rewardCount: string,
		costResource: ButtonValueTypes,
		cost: number,
		viewParameters: string[],
		mostPopularLocaleKey: string,
		saleText: string,
		extraReward: RewardDescriptionType,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.animationEmitters = [];
		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;

		const shopAtlas = AssetsStorage.getAtlas('bankAtlas');
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.Sprite(shopAtlas[viewParameters[0]]);

		const rewardContainer = new PIXI.Container();
		const rewardLabel = new SizeableBitmapText(
			rewardCount.toString(),
			250,
			{ font: '40px wendyOneShadowBold', align: 'left' },
		);
		rewardIcon.anchor.set(1, 0.5);
		rewardLabel.anchor = new PIXI.Point(0, 0.5);
		rewardContainer.addChild(
			rewardIcon,
			rewardLabel as PIXI.DisplayObject,
		);

		if (extraReward) {
			const extraRewardLabel = new SizeableBitmapText(
				`+${extraReward.reward_qty}`,
				250,
				{ font: '24px wendyOneShadowBold', tint: 0x4fe200, align: 'left' },
			);
			const freeLabel = new PIXI.extras.BitmapText(
				this.localizationStorage.getLocalizedString('#bank_free_gems'),
				{ font: '18px wendyOneShadowBold', tint: 0x4fe200, align: 'left' },
			);

			rewardIcon.position.set(rewardLabel.x + 58, 0);
			rewardLabel.position.set(72, -15);
			extraRewardLabel.anchor = new PIXI.Point(0, 0.5);
			extraRewardLabel.position.set(63, 17);
			freeLabel.anchor = new PIXI.Point(0, 0.5);
			freeLabel.position.set(extraRewardLabel.x + extraRewardLabel.width + 2, 19);
			rewardContainer.addChild(
				extraRewardLabel,
				freeLabel,
			);
		} else {
			rewardIcon.position.set(rewardLabel.x + 40, 0);
			rewardLabel.position.set(54, 0);
		}
		rewardContainer.pivot.set(
			rewardContainer.width / 2,
			rewardContainer.height / 2,
		);
		rewardContainer.position.set(0, 85);

		this.resourcePackSprite = new PIXI.Sprite(shopAtlas[viewParameters[1]]);
		this.resourcePackSprite.position.set(0, -58);

		const buttonBuyBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBuyBg.width = 188;
		buttonBuyBg.height = 58;
		buttonBuyBg.pivot.set(buttonBuyBg.width / 2, buttonBuyBg.height / 2);
		this.buyButton = new ButtonWithCostValueView({
			type: costResource,
			buttonBg: buttonBuyBg,
			maxWidth: 188,
			fontStyle: { font: { size: 30, name: 'wendyOneShadowBold' } },
		});
		this.buyButton.interactive = false;
		this.buyButton.position.set(0, 155);
		this.buyButton.setCost(String(cost));
		this.buyButton.on(ButtonWithCostValueView.EVENT_CLICK, this.onBuyButtonClick, this);

		// this.infoIcon = new BankInfoIconView(1.3);
		// this.infoIcon.position.set(110, -180);
		// this.infoIcon.on(BankInfoIconView.EVENT_CLICK, this.onBankInfoIconClick, this);

		const glowContainer = new PIXI.Container();
		glowContainer.alpha = 0.4;
		glowContainer.scale.set(1.3);
		const glowEmitter = new Emitter(
			glowContainer,
			[fxAtlas['bundles_rotate_glow']],
			BankParticleConfig.getRotateGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const clickMask = new PIXI.Graphics()
			.beginFill(0, 0)
			.drawRect(-bg.width / 2, -bg.height / 2, bg.width, bg.height)
			.endFill();
		clickMask.interactive = true;
		clickMask.on('pointertap', this.onBuyButtonClick, this);
		clickMask.on('pointerover', this.onMouseOver, this);
		clickMask.on('pointerout', this.onMouseOut, this);

		this.addChild(
			bg,
			glowContainer,
			this.resourcePackSprite,
			clickMask,
			this.buyButton as PIXI.DisplayObject,
			// this.infoIcon,
			rewardContainer,
		);

		if (mostPopularLocaleKey) {
			const stickerPopular = new PIXI.Sprite(shopAtlas['first_purchase']);
			stickerPopular.position.set(-67, -147);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(mostPopularLocaleKey),
				{ font: '28px wendyOne', align: 'center', tint: 0xfff78f },
				100,
				50,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = -0.73;
			stickerPopularText.position.set(-92, -162);

			const stickerPopularMask = new PIXI.Sprite(shopAtlas['first_purchase']);
			const stickerPopularHighlight = new SpriteHighlightAnimationView(stickerPopularMask, 650, false);
			stickerPopularHighlight.position.set(-67, -147);

			this.addChild(
				stickerPopular,
				stickerPopularHighlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHighlight.start();
		}

		if (saleText) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSale.position.set(66, -122);
			stickerSale.scale.set(0.75);
			const saleLabel = new TextField(
				saleText,
				{ font: '38px wendyOne', align: 'center', tint: 0xfff78f },
				40,
				40,
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.47;
			saleLabel.position.set(66, -122);

			const stickerSaleMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSaleMask.scale.set(0.75);
			const stickerSaleHighlight = new SpriteHighlightAnimationView(stickerSaleMask, 500, false);
			stickerSaleHighlight.position.set(66, -122);

			this.addChild(
				stickerSale,
				stickerSaleHighlight,
				saleLabel as PIXI.DisplayObject,
			);
			stickerSaleHighlight.start();
		}

		const starEmitter = new Emitter(
			this,
			[fxAtlas['bundles_small_star']],
			BankParticleConfig.getCardFlare(),
		);
		starEmitter.autoUpdate = true;
		this.animationEmitters.push(starEmitter);

		this.ticker.add(this.update, this);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public getButtonBottomContainer(): PIXI.Container {
		return this.buyButton;
	}

	private onBuyButtonClick(): void {
		this.emit(BankTabElementGemShopDefaultView.EVENT_BUTTON_BUY_CLICK);
	}

	private onMouseOver(): void {
		if (this.mouseOver) {
			return;
		}
		this.mouseOver = true;
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1.05, y: 1.05 }, 100)
			.easing(TWEEN.Easing.Cubic.In)
			.start();
	}

	private onMouseOut(): void {
		if (!this.mouseOver) {
			return;
		}
		this.mouseOver = false;
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
