import { AnalyticsType, PixelEventTypes, TrackingData } from '@src/types/AnalyticsTypes';
import { AnalyticSourceLevelPreloaderView } from '../sources/AnalyticSourceLevelPreloaderView';
import { AnalyticSourceModels, EventBankPurchaseSuccessEventArgs } from '../sources/AnalyticSourceModels';
import { AnalyticSourceLevelStartWindowView, EventButtonStartClickEventArgs } from '../sources/AnalyticSourceLevelStartWindowView';
import { AnalyticSourceGameProfileModel } from '../sources/AnalyticSourceGameProfileModel';

export class AnalyticTargetPixel {
	private static readonly UTM_SOURCE = 'hooligan';

	private static addUrlParams(
		url: string,
		eventType: PixelEventTypes,
		payValue?: string,
		marketingTrackingParam?: string,
	): string {
		const params = [
			`act=${encodeURIComponent(eventType as string)}`,
		];

		if (payValue !== null && payValue !== undefined) {
			params.push(`purchase_value=${encodeURIComponent(payValue)}`);
		}

		if (marketingTrackingParam !== null && marketingTrackingParam !== undefined) {
			params.push(`pixel_id=${encodeURIComponent(marketingTrackingParam)}`);
		}

		return url + (url.indexOf('?') === -1 ? '?' : '&') + params.join('&');
	}

	private static onEventPixel(
		eventType: PixelEventTypes,
		marketingTrackingParam?: string,
		payValue?: string,
	): void {
		let url: string;
		switch (eventType) {
			case PixelEventTypes.LOAD_GAME: {
				url = 'https://pixrealm.com/iframe/5e8369dcc9b26?iframe';
				break;
			}
			case PixelEventTypes.PAY: {
				url = 'https://pixrealm.com/iframe/5e836a196a57f?iframe';
				break;
			}
			case PixelEventTypes.SIGN_UP: {
				url = 'https://pixrealm.com/iframe/5e836a06ce60a?iframe';
				break;
			}
			case PixelEventTypes.TUTORIAL_END: {
				url = 'https://pixrealm.com/iframe/5e8369f08506c?iframe';
				break;
			}
			default:
				throw new Error(`Unsupported pixel event type '${eventType}'`);
		}

		url = AnalyticTargetPixel.addUrlParams(
			url,
			eventType,
			payValue,
			marketingTrackingParam,
		);

		const iframe = document.createElement('iframe');
		iframe.src = url;
		iframe.width = '0';
		iframe.height = '0';
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
	}

	private marketingTrackingParam?: string;

	constructor(trackingData?: TrackingData) {
		this.init(trackingData);
	}

	public setGameProfileData(trackingData?: TrackingData): void {
		this.init(trackingData);
	}

	public addSourceLevelPreloaderView(source: AnalyticSourceLevelPreloaderView): void {
		source.once(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_COMPLETED, () => {
			AnalyticTargetPixel.onEventPixel(
				PixelEventTypes.LOAD_GAME,
				this.marketingTrackingParam,
			);
		}, this);
	}

	public addSourceModels(source: AnalyticSourceModels): void {
		source.on(AnalyticSourceModels.EVENT_BANK_PURCHASE_SUCCESS, (args: EventBankPurchaseSuccessEventArgs) => {
			AnalyticTargetPixel.onEventPixel(
				PixelEventTypes.PAY,
				this.marketingTrackingParam,
				args.price,
			);
		}, this);
	}

	public addSourceGameProfileModel(source: AnalyticSourceGameProfileModel): void {
		source.on(AnalyticSourceGameProfileModel.EVENT_GAME_PROFILE_CONFIRMATION, () => {
			AnalyticTargetPixel.onEventPixel(
				PixelEventTypes.SIGN_UP,
				this.marketingTrackingParam,
			);
		}, this);
	}

	public addSourceLevelStartWindowView(source: AnalyticSourceLevelStartWindowView): void {
		const tryEmitEventTutorialEnd = (args: EventButtonStartClickEventArgs): void => {
			if (args.currentLevel === 4) {
				AnalyticTargetPixel.onEventPixel(
					PixelEventTypes.TUTORIAL_END,
					this.marketingTrackingParam,
				);
			} else {
				source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
			}
		};
		source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
	}

	private init(trackingData?: TrackingData): void {
		if (trackingData !== undefined
			&& trackingData.utmSource === AnalyticTargetPixel.UTM_SOURCE
			&& trackingData.analytics === AnalyticsType.PIXEL) {
			this.marketingTrackingParam = trackingData.pixelId;
		}
	}
}
