import { CardRarities } from '@src/types/CardRarities';

export type PromoteDataElement = {
	rarity: CardRarities;
	level: number;
	promoteCostCards: number;
	promoteCostPrestige: number;
};

export abstract class AbstractPromotePatternsConfig {
	public static readonly FIELD_LEVEL: string = 'current_level';
	public static readonly FIELD_RARITY: string = 'id';
	public static readonly FIELD_PROMOTE_COST_CARDS: string = 'promote_souls_value';
	public static readonly FIELD_PROMOTE_COST_PRESTIGE: string = 'promote_resource_value';

	protected promoteData: Map<number, PromoteDataElement[]>;

	constructor() {
		this.promoteData = new Map();
	}

	public getPromoteCostPrestige(level: number, rarity: CardRarities): number {
		return this.promoteData.get(level)
			.find((dataElement) => dataElement.rarity === rarity)
			.promoteCostPrestige;
	}

	public getPromoteCostCards(level: number, rarity: CardRarities): number {
		return this.promoteData.get(level)
			.find((dataElement) => dataElement.rarity === rarity)
			.promoteCostCards;
	}

	protected parseConfig(config: Record<string, string>[]): void {
		config.forEach((configElement) => {
			const element = this.parseElement(configElement);
			let promoteDataElements: PromoteDataElement[] = [];

			if (this.promoteData.has(element.level)) {
				promoteDataElements = this.promoteData.get(element.level);
			} else {
				this.promoteData.set(element.level, promoteDataElements);
			}

			promoteDataElements.push(element);
		});
	}

	protected abstract parseElement(configElement: Record<string, string>): PromoteDataElement;
}
