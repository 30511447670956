import { BankSubscribeConfig } from '@configs/bank/BankSubscribeConfig';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';

export class BankSubscribesFactory {
	public static createModels(
		bankSubscribeConfig: Map<string, BankSubscribeConfig>,
		currentTime: number,
		priceConversionCoeff: number,
	): Map<string, BankSubscribeModel> {
		const subscribeModels = new Map();
		bankSubscribeConfig.forEach((subscribeConfig, key) => {
			const subscribeModel = new BankSubscribeModel(subscribeConfig, priceConversionCoeff);
			if (subscribeModel.getIsActive()) {
				subscribeModel.setExpireOn(currentTime + subscribeModel.getTimeRemain());
				subscribeModel.setTimeLeft(subscribeModel.getTimeRemain());
				if (subscribeModel.getClaimableOn() > currentTime) {
					subscribeModel.setNextClaimIn(subscribeModel.getClaimableOn() - currentTime);
				}
			}
			subscribeModels.set(key, subscribeModel);
		});
		return subscribeModels;
	}
}
