import LocalizationStorage from '@main/LocalizationStorage';
import { BankTabElementWithOfferType, BankTabGeneralObjectWithOfferType } from '@models/bank/BankModel';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ViewUtils } from '@src/utils/ViewUtils';
import { BankOfferWindowViewType } from '@src/types/BankTypes';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankOfferWindowPackBody1View } from './bodyViews/BankOfferWindowPackBody1View';
import { BankOfferWindowPackBody2View } from './bodyViews/BankOfferWindowPackBody2View';
import { BankOfferWindowPackBody3View } from './bodyViews/BankOfferWindowPackBody3View';
import { BankOfferWindowPackBody4View } from './bodyViews/BankOfferWindowPackBody4View';
import { BankOfferWindowDiscountBody1View } from './bodyViews/BankOfferWindowDiscountBody1View';
import { BankOfferWindowDiscountBody2View } from './bodyViews/BankOfferWindowDiscountBody2View';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { AssetsStorage } from '@main/AssetsStorage';
import { BankOfferButtonBuyView } from './BankOfferButtonBuyView';
import { BankOfferWindowDoublePackBodyView } from './bodyViews/BankOfferWindowDoublePackBodyView';
import { BankBundleGroupModel } from '@models/bank/BankBundleGroupModel';
import { ButtonWithOldCostValue, ButtonWithOldCostValueSettings } from '@views/components/buttons/ButtonWithOldCostValueView';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { BankOfferSpecialButtonBuyView } from './BankOfferSpecialButtonBuyView';
import { BankOfferWindowDiscountBody3View } from './bodyViews/BankOfferWindowDiscountBody3View';
import { BankOfferWindowPackBodyMainView } from './bodyViews/BankOfferWindowPackBodyMainView';

export type BankOfferButtonType = (width: number, height: number, bankTabElementWithOffer: BankTabElementWithOfferType) => PIXI.Container;
export type BankOfferRewardsContainerType =
	(miniCardsMarginX: number, bankTabElementWithOffer: BankTabElementWithOfferType, cardScale?: number, splitRow?: boolean) => PIXI.Container;

export class BankOfferWindowBodyView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT_REWARD_MINI_CARD: symbol = Symbol();
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly content: PIXI.Container;

	private buyButtons: ButtonBaseView[];

	constructor(
		private readonly bankOfferModel: BankOfferModel,
		private readonly bankSavesModel: BankSavesModel,
		private readonly bankTabGeneralObjectWithOfferType: BankTabGeneralObjectWithOfferType,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();

		this.buyButtons = [];

		const windowViewType = bankOfferModel.getWindowViewType();
		const getButtonFunction: BankOfferButtonType = this.createBankOfferBuyButton.bind(this);
		const getRewardsContainer: BankOfferRewardsContainerType = this.getRewardMiniCardsContainer.bind(this);

		switch (windowViewType) {
			case BankOfferWindowViewType.MAIN_PACK:
				this.content = new BankOfferWindowPackBodyMainView(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					this.createBankOfferSpecialBuyButton.bind(this),
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.PACK_1:
				this.content = new BankOfferWindowPackBody1View(
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					this.bankOfferModel.getLocalizationKeys(),
					getButtonFunction,
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.PACK_2:
				this.content = new BankOfferWindowPackBody2View(
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					this.bankOfferModel.getLocalizationKeys(),
					getButtonFunction,
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.PACK_3:
				this.content = new BankOfferWindowPackBody3View(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					getButtonFunction,
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.PACK_4:
				this.content = new BankOfferWindowPackBody4View(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					getButtonFunction,
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.DISCOUNT_1:
				this.content = new BankOfferWindowDiscountBody1View(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					getButtonFunction,
				);
				break;
			case BankOfferWindowViewType.DISCOUNT_2:
				this.content = new BankOfferWindowDiscountBody2View(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					getButtonFunction,
				);
				break;
			case BankOfferWindowViewType.DISCOUNT_3:
				this.content = new BankOfferWindowDiscountBody3View(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankTabElementWithOfferType,
					getButtonFunction,
					getRewardsContainer,
				);
				break;
			case BankOfferWindowViewType.COMPARE:
				this.content = new BankOfferWindowDoublePackBodyView(
					bankOfferModel,
					bankTabGeneralObjectWithOfferType as BankBundleGroupModel,
					this.createDoubleOfferBuyButton.bind(this),
					getRewardsContainer,
				);
				break;
			default:
				throw new Error(`Unsupported bankOfferModel windowViewType '${windowViewType}'`);
		}

		this.addChild(this.content);
	}

	public setTimeLabelText(text: string): void {
		(this.content as any).setTimeLabelText(text);
	}

	public setButtonBuyEnabled(value: boolean): void {
		this.buyButtons.forEach(button => {
			button.interactive = value;
			button.alpha = value ? 1 : 0.5;
		});
	}

	private createBankOfferBuyButton(width: number, height: number, bankTabElementWithOffer: BankTabElementWithOfferType): BankOfferButtonBuyView {
		// todo: replace with old cost value button
		const buyButton: BankOfferButtonBuyView = new BankOfferButtonBuyView(width, height, bankTabElementWithOffer);
		buyButton.once(
			ButtonBaseView.EVENT_CLICK,
			() => this.onButtonBuyClick(bankTabElementWithOffer),
			this,
		);
		this.buyButtons.push(buyButton);
		return buyButton;
	}

	private createDoubleOfferBuyButton(config: ButtonWithOldCostValueSettings, bankTabElementWithOffer: BankTabElementWithOfferType): ButtonWithOldCostValue {
		const buyButton: ButtonWithOldCostValue = new ButtonWithOldCostValue(config);
		buyButton.once(
			ButtonBaseView.EVENT_CLICK,
			() => this.onButtonBuyClick(bankTabElementWithOffer),
			this,
		);
		this.buyButtons.push(buyButton);
		return buyButton;
	}

	private createBankOfferSpecialBuyButton(
		width: number,
		height: number,
		bankTabElementWithOffer:BankTabElementWithOfferType,
	): BankOfferSpecialButtonBuyView {
		const buyButton: BankOfferSpecialButtonBuyView = new BankOfferSpecialButtonBuyView(width, height, bankTabElementWithOffer);
		buyButton.once(
			ButtonBaseView.EVENT_CLICK,
			() => this.onButtonBuyClick(bankTabElementWithOffer),
			this,
		);
		this.buyButtons.push(buyButton);
		return buyButton;
	}

	private onButtonBuyClick(bankTabElementWithOffer: BankTabElementWithOfferType): void {
		this.emit(BankOfferWindowBodyView.EVENT_BUTTON_BUY_CLICK, bankTabElementWithOffer);
	}

	private getRewardMiniCardsContainer(
		miniCardsMargin: number,
		bankTabElementWithOffer: BankTabElementWithOfferType,
		cardScale = 1,
		splitRow = false,
	): PIXI.Container {
		const rewardMiniCardsArray: PIXI.Container[] = [];
		const rewardsContainer = new PIXI.Container();
		let rewards: RewardDescriptionType[];

		if (bankTabElementWithOffer instanceof BankBundleModel) {
			rewards = this.bankSavesModel.getBundleRewards(bankTabElementWithOffer.getKey());
		} else if (bankTabElementWithOffer instanceof BankGemShopItemModel) {
			rewards = [
				bankTabElementWithOffer.getRewardDescription(),
				...(bankTabElementWithOffer.getExtraRewardDescriptions() || []),
			];
		}

		if (splitRow && rewards.length >= 5) {
			const upperRowSize = Math.ceil(rewards.length / 2);
			const upperCards = this.createMiniCards(rewards.slice(0, upperRowSize), cardScale, miniCardsMargin);
			const lowerCards = this.createMiniCards(rewards.slice(upperRowSize), cardScale, miniCardsMargin);

			upperCards.forEach((card) => {
				card.y -= card.height / 2 + miniCardsMargin / 2;
			});

			lowerCards.forEach((card) => {
				card.y += card.height / 2 + miniCardsMargin / 2;
			});

			rewardMiniCardsArray.push(...upperCards, ...lowerCards);
		} else {
			const cards = this.createMiniCards(rewards, cardScale, miniCardsMargin);
			rewardMiniCardsArray.push(...cards);
		}

		rewardsContainer.addChild(...rewardMiniCardsArray);

		return rewardsContainer;
	}

	private createMiniCards(
		rewards: RewardDescriptionType[],
		cardScale: number,
		miniCardsMarginX: number,
	): PIXI.Container[] {
		const miniCards = rewards.map((rewardDescription) => {
			const miniCard = this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardDescription.reward_type as RewardTypes,
				rewardDescription.reward_id,
				1,
				1,
				CardMiniViewFactory.createStickerNew(),
			);

			miniCard.interactive = true;
			miniCard.on('pointertap', () => {
				this.emit(BankOfferWindowBodyView.EVENT_SHOW_HINT_REWARD_MINI_CARD, rewardDescription, miniCard);
			}, this);

			let labelCountString: string;

			if (typeof rewardDescription.reward_qty === 'number') {
				labelCountString = `x${SoftMoneyNumber.createFromNumber(rewardDescription.reward_qty).toString(3, 5)}`;
			} else if (typeof rewardDescription.reward_qty === 'string') {
				labelCountString = `x${SoftMoneyNumber.createFromString(rewardDescription.reward_qty).toString(3, 5)}`;
			}

			const labelCount = new SizeableBitmapText(
				labelCountString,
				miniCard.width - 20,
				{ font: '39 wendyOneShadowBold', tint: 0xffd86d },
			);
			labelCount.anchor = 0.5;
			labelCount.y = 72;

			const labelCountFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow_half']);
			labelCountFade.tint = 0x00000;
			labelCountFade.alpha = 0.7;
			labelCountFade.scale.set(1.6);
			labelCountFade.y = 56;

			miniCard.scale.set(cardScale, cardScale);

			miniCard.addChild(
				labelCountFade as PIXI.DisplayObject,
				labelCount,
			);

			return miniCard;
		});

		ViewUtils.positionLayoutHorizontal(
			miniCards,
			miniCardsMarginX,
		);

		return miniCards;
	}
}
