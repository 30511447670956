import { CharacterModel } from '@models/CharacterModel';
import { SoundController } from '@src/main/SoundController';
import { UpgradeModel } from '@models/UpgradeModel';
import { BusinessManageUIView } from '@views/businesses/ui/acquired/manage/BusinessManageUIView';
import { BusinessManageUIViewSetter, GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { CharacterAcquireAction } from '@models/network/actions/characters/CharacterAcquireAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { UpgradeAcquireAction } from '@models/network/actions/upgrades/UpgradeAcquireAction';
import { BusinessModel } from '@models/BusinessModel';
import { ModelHelper } from '@models/ModelHelper';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { SkillModel } from '@models/skills/SkillModel';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { DefaultHintViewData } from '@views/ui/hints/HintDefaultView';
import { HintDataBusinessCard, HintTypes } from '@src/types/HintTypes';
import { ShowHintDataResolver } from '@views/ui/main/ShowHintDataResolver';
import { CharacterSlotView } from '@views/businesses/ui/acquired/manage/CharacterSlotView';
import { UpgradeSlotView } from '@views/businesses/ui/acquired/manage/UpgradeSlotView';

export class BusinessManageUIViewController extends PIXI.utils.EventEmitter implements BusinessManageUIViewSetter, GameUIBaseViewSetter {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly hintSpawner: ShowHintSpawner;
	private readonly model: BusinessModel;
	private readonly charterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly skillModels: Map<string, SkillModel>;

	private view: BusinessManageUIView;
	private windowFactory: WindowViewBaseFactory;
	private windowViewSystem: WindowViewSystem;
	private gameUI: GameUIBaseView;

	constructor(
		model: BusinessModel,
		charterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		skillModels: Map<string, SkillModel>,
		softMoneyModel: SoftMoneyModel,
		hintSpawner: ShowHintSpawner,
		private readonly hintResolver: ShowHintDataResolver,
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
	) {
		super();
		this.model = model;
		this.softMoneyModel = softMoneyModel;
		this.hintSpawner = hintSpawner;
		this.windowFactory = windowFactory;
		this.windowViewSystem = windowViewSystem;
		this.charterModels = charterModels;
		this.upgradeModels = upgradeModels;
		this.skillModels = skillModels;
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
	}

	public setManageUIView(view: BusinessManageUIView): void {
		this.view = view;

		this.view.on(BusinessManageUIView.EVENT_SHOW_PROMOTE_UPGRADE, this.onShowPromoteUpgrade, this);
		this.view.on(BusinessManageUIView.EVENT_SHOW_PROMOTE_CHARACTER, this.onShowPromoteCharacter, this);

		this.view.on(BusinessManageUIView.EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK, this.activateUpgrade, this);
		this.view.on(BusinessManageUIView.EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK, this.onButtonCharacterActivateClick, this);

		this.view.on(BusinessManageUIView.EVENT_PROPERTY_CLICK, this.onShowPropertyHint, this);

		this.view.on(BusinessManageUIView.EVENT_SHOW, () => {
			this.view.addChild(this.gameUI.getSoftMoneyPanel());
		});
		this.view.on(BusinessManageUIView.EVENT_HIDE, () => {
			this.view.removeChild(this.gameUI.getSoftMoneyPanel());
		});
	}

	private onShowPromoteUpgrade(slotView: UpgradeSlotView, upgradeKey: string): void {
		const model = this.upgradeModels.get(upgradeKey);

		if (model.isOpened()) {
			const window = this.windowFactory.createUpgradePromoteWindow(model);
			this.windowViewSystem.showWindow(window);
		} else {
			this.showSlotHint({ model, origin: slotView });
		}
	}

	private onShowPromoteCharacter(slotView: CharacterSlotView, characterKey: string, skillKey?: string): void {
		const model = this.charterModels.get(characterKey);

		if (model.isOpened()) {
			const window = this.windowFactory.createCharacterProfileWindow(model, this.skillModels.get(skillKey));
			this.windowViewSystem.showWindow(window);
		} else {
			this.showSlotHint({ model, origin: slotView });
		}
	}

	private showSlotHint(data: HintDataBusinessCard): void {
		const args = this.hintResolver.resolve(HintTypes.BUSINESS_CARD, data);
		this.hintSpawner.showHint(this.gameUI, args);
	}

	private onButtonCharacterActivateClick(character: CharacterModel): void {
		if (ModelHelper.isEnoughSoftMoneyForActivate(character, this.softMoneyModel)) {
			this.softMoneyModel.subtract(character.getActivateCost());
			character.activate();

			const action = new CharacterAcquireAction(
				character.getKey(),
				this.model.getKey(),
				character.getActivateCost().toRawString(),
				this.softMoneyModel.getValue().toRawString(),
			);
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);

			SoundController.getInstance().playCharacterActivate();
		}
	}

	private activateUpgrade(upgrade: UpgradeModel): void {
		if (ModelHelper.isEnoughSoftMoneyForActivate(upgrade, this.softMoneyModel)) {
			this.softMoneyModel.subtract(upgrade.getActivateCost());
			upgrade.activate();

			const action = new UpgradeAcquireAction(
				upgrade.getKey(),
				this.model.getKey(),
				upgrade.getActivateCost().toRawString(),
				this.softMoneyModel.getValue().toRawString(),
			);
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);

			SoundController.getInstance().playUpgradeActivate();
		}
	}

	private onShowPropertyHint(data: DefaultHintViewData, origin: PIXI.DisplayObject): void {
		const topYOffset = 90;
		const arrowPosLocal = new PIXI.Point(0, topYOffset);

		this.hintSpawner.showHint(this.gameUI, {
			data,
			origin,
			arrowPosLocal,
		});
	}
}
