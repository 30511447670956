import { RewardFactory } from './RewardFactory';
import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { QuestLootboxModel } from '@models/lootboxes/QuestLootboxModel';
import { FarewellPartyLootboxModel } from '@models/lootboxes/FarewellPartyLootboxModel';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { ModelHelper } from '@models/ModelHelper';
import {
	RewardTypes,
	RewardDisplayTypes,
	RewardResourceIdTypes,
} from '@src/types/RewardTypes';
import { AbstractReward } from '@interfaces/AbstractReward';

type RewardDisplayData = {
	defaultDeltaSet: number[];
	labeledDeltaSets: Map<string, number[]>;
}

export class LootboxFactory {
	private static parseDeltaSet = (str: string): number[] => str.split(',').map((s) => Number(s));

	private static parseDeltaConfig(delta: string): RewardDisplayData {
		const labeledDeltaSets = new Map<string, number[]>();
		const deltaSets = delta.split(';');
		const defaultDeltaSet = LootboxFactory.parseDeltaSet(deltaSets[0]);

		deltaSets.slice(1).forEach((labeledDeltaSet) => {
			const [displayType, deltaSet] = labeledDeltaSet.trim().split(':');
			labeledDeltaSets.set(displayType, LootboxFactory.parseDeltaSet(deltaSet));
		});

		return {
			defaultDeltaSet,
			labeledDeltaSets,
		};
	}

	private static extractRewardDisplayDeltaSet(
		reward: AbstractReward,
		rewardDisplayData: RewardDisplayData,
	): number[] {
		let deltaSetKey: string;
		let deltaSet: number[];

		// eslint-disable-next-line default-case
		switch (reward.getType()) {
			case RewardTypes.CHARACTER:
			case RewardTypes.TOTEM:
			case RewardTypes.UPGRADE: {
				deltaSetKey = RewardDisplayTypes.CARDS;
				break;
			}
			case RewardTypes.BOOST: {
				deltaSetKey = RewardDisplayTypes.BOOST;
				break;
			}
			case RewardTypes.TIMESKIP: {
				deltaSetKey = RewardDisplayTypes.TIMESKIP;
				break;
			}
			case RewardTypes.RESOURCE: {
				// eslint-disable-next-line default-case
				switch (reward.getKey()) {
					case RewardResourceIdTypes.SOFT_MONEY: {
						deltaSetKey = RewardDisplayTypes.SOFT_MONEY;
						break;
					}
					case RewardResourceIdTypes.PRESTIGE_MONEY: {
						deltaSetKey = RewardDisplayTypes.PRESTIGE_MONEY;
						break;
					}
					case RewardResourceIdTypes.HARD_MONEY: {
						deltaSetKey = RewardDisplayTypes.HARD_MONEY;
						break;
					}
				}
				break;
			}
			case RewardTypes.VIDEO: {
				deltaSetKey = RewardDisplayTypes.VIDEO;
				break;
			}
		}

		if (deltaSetKey != null && rewardDisplayData.labeledDeltaSets.has(deltaSetKey)) {
			deltaSet = rewardDisplayData.labeledDeltaSets.get(deltaSetKey);
		} else {
			deltaSet = rewardDisplayData.defaultDeltaSet;
		}

		return deltaSet;
	}

	private static getDisplayValueFromDelta(reward: AbstractReward, deltaSet: number[]): string {
		const realValue = reward.getQuantity();
		const idx = Math.floor(Math.random() * deltaSet.length);
		const delta = deltaSet[idx];
		let displayValue: string;

		if (delta === 0) {
			displayValue = reward.getQuantityString();
		} else {
			const valuePosition = Math.floor(Math.random() * (delta + 1));
			let lowerBound = realValue - valuePosition;
			let upperBound = realValue + (delta - valuePosition);

			if (lowerBound <= 0) {
				upperBound += 1 + Math.abs(lowerBound);
				lowerBound = 1;
			}

			displayValue = `${lowerBound}-${upperBound}`;
		}

		return displayValue;
	}

	private readonly rewardFactory: RewardFactory;

	constructor(rewardFactory: RewardFactory) {
		this.rewardFactory = rewardFactory;
	}

	public createLootbox(config: LootboxServerConfig): LootboxModel {
		const mergedRewardDestiprions = ModelHelper.mergeRewardDescriptions(Object.values(config.rewards));
		const rewards = mergedRewardDestiprions.map(rewardDescr => this.rewardFactory.createReward(rewardDescr));

		const model = new LootboxModel(
			config.id,
			config.extra.rarity,
			rewards,
		);
		return model;
	}

	public createQuestLootbox(config: LootboxServerConfig): QuestLootboxModel {
		const mergedRewardDestiprions = ModelHelper.mergeRewardDescriptions(Object.values(config.rewards));
		const rewards = mergedRewardDestiprions.map(rewardDescr => this.rewardFactory.createReward(rewardDescr));
		const defaultDelta = '0';
		const rewardDisplayData = LootboxFactory.parseDeltaConfig(config.extra.config.delta || defaultDelta);

		rewards.forEach((reward) => {
			const deltaSet = LootboxFactory.extractRewardDisplayDeltaSet(reward, rewardDisplayData);
			const displayValue = LootboxFactory.getDisplayValueFromDelta(reward, deltaSet);
			reward.setDisplayValue(displayValue);
		});

		const model = new QuestLootboxModel(
			config.id,
			config.extra.rarity,
			rewards,
		);
		return model;
	}

	public createFarewellPartyLootbox(config: LootboxServerConfig): FarewellPartyLootboxModel {
		const rewardsConfigKeys = Object.keys(config.rewards);
		const rewards = rewardsConfigKeys.map((key: string) => this.rewardFactory.createReward(config.rewards[key]));

		const model = new FarewellPartyLootboxModel(
			config.id,
			config.extra.rarity,
			rewards,
			Object.values(config.rewards),
			this.rewardFactory,
		);
		return model;
	}
}
