import { SummonConfig, SummonContentItemType } from '@configs/SummonConfig';

export class SummonModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_SUMMON_BUY: symbol = Symbol();
	public static readonly EVENT_SUMMON_AVAILABLE: symbol = Symbol();

	private readonly key: string;
	private readonly priceResource: string;
	private readonly price: number;
	private readonly viewType: string;
	private readonly priceX10: number;
	private readonly content: SummonContentItemType[];
	private readonly unlockTime: number;
	private readonly buyTimes: number;

	private nextSummonOn: number;
	private freePrice: boolean;
	private freePriceAvailable: boolean;
	private freePriceAvailableTime: number;

	constructor(config: SummonConfig) {
		super();
		this.key = config.getKey();
		this.viewType = config.getViewType();
		this.priceResource = config.getPriceResource();
		this.price = config.getPrice();
		this.unlockTime = config.getUnlockTime();
		this.priceX10 = config.getPriceX10();
		this.content = config.getContent();
		this.buyTimes = config.getBuyTimes();
		this.freePrice = this.priceResource === 'free';
		this.freePriceAvailable = false;
	}

	public setNextSummonOn(value: number): void {
		this.nextSummonOn = value;
	}

	public setFreePriceAvailable(value: boolean): void {
		this.freePriceAvailable = value;

		if (this.freePriceAvailable) {
			this.emit(SummonModel.EVENT_SUMMON_AVAILABLE);
		}
	}

	public setFreePriceAvailableTime(value: number): void {
		this.freePriceAvailableTime = value;
	}

	public getFreePriceAvailableTime(): number {
		return this.freePriceAvailableTime;
	}

	public buy(amount: number): void {
		this.emit(SummonModel.EVENT_SUMMON_BUY, this, amount);
	}

	public getKey(): string {
		return this.key;
	}

	public getViewType(): string {
		return this.viewType;
	}

	public isFreePrice(): boolean {
		return this.freePrice;
	}

	public isPrestigePrice(): boolean {
		return this.priceResource === 'prestige_money';
	}

	public isHardPrice(): boolean {
		return this.priceResource === 'hard_money';
	}

	public isFreePriceAvailable(): boolean {
		return this.freePriceAvailable;
	}

	public getPriceValue(): number {
		return this.price;
	}

	public getNextSummonOn(): number {
		return this.nextSummonOn;
	}

	public getPriceValueX10(): number {
		return this.priceX10;
	}

	public getUnlockTime(): number {
		return this.unlockTime;
	}

	public getContent(): SummonContentItemType[] {
		return this.content;
	}

	public hasBuyTimes(): boolean {
		return Boolean(this.buyTimes);
	}

	public getBuyTimes(): number {
		return this.buyTimes;
	}
}
