import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter100017View extends CharacterMainBaseView {
	constructor() {
		super('character100017');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-90, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-25, -28),
			bgPosition: new PIXI.Point(32, -32),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
