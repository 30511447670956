import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { TimesOfDayType } from '@src/types/TimesOfDayTypes';

// key = business key, value = business multipliers for CustomerMultipliers
export type BusinessLevelConfig = {
	customerMultipliers: number[];
	cciArray: number[];
};

export type BusinessesLevelConfig = Map<string, BusinessLevelConfig>;

export type QuestLineItemLevelConfig = {
	key: string;
	params: string;
	lineConfigColumnKey: string;
};

export abstract class LevelBaseConfig {
	public static readonly FORMAT_QUEST_LINE: RegExp = /^\s*quest_line\s*[1-9]+\s*$/;
	public static readonly FORMAT_QUEST: RegExp = /^[\n\r]*(\w+)(?:\s*:\s*([\w.;]+))?\s*$/;

	public static readonly FIELD_SOFT_MONEY_START: string = 'soft_money_start';
	public static readonly FIELD_BUSINESSES: string = 'businesses';
	public static readonly FIELD_BUSINESSES_MULTIPLIERS: string = 'businesses_multiplicator';
	public static readonly FIELD_CUSTOMER_COST_INCREASE: string = 'customer_cost_increase';
	public static readonly FIELD_QUEST_COUNT: string = 'quest_count';
	public static readonly FIELD_BACKGROUND: string = 'background';
	public static readonly FIELD_SOUND_THEME: string = 'sound_theme';

	private readonly softMoneyStart: SoftMoneyNumber;
	private readonly questCount: number;
	private readonly businesses: BusinessesLevelConfig;
	private readonly questLines: QuestLineItemLevelConfig[][];
	private readonly background: TimesOfDayType;
	private readonly soundTheme: string;

	protected static parseQuestLineIndex(
		questLineKeyPrefix: string,
		questLineKey: string,
	): number {
		const questLinePrefixId = questLineKey.indexOf(questLineKeyPrefix);
		if (questLinePrefixId === 0) {
			return Number(questLineKey.substring(questLineKeyPrefix.length));
		}
		return -1;
	}

	constructor(config: { [key: string]: string }) {
		this.softMoneyStart = SoftMoneyNumber.createFromAcronym(config[LevelBaseConfig.FIELD_SOFT_MONEY_START]);

		this.questCount = parseInt(config[LevelBaseConfig.FIELD_QUEST_COUNT], 10);

		this.businesses = new Map();
		const businessKeys = (config[LevelBaseConfig.FIELD_BUSINESSES] as string).split(',').map(value => value.trim());
		// ["1,2,3,4...", "6,7,8,9..."]
		const businessesMultipliers: string[] = (config[LevelBaseConfig.FIELD_BUSINESSES_MULTIPLIERS] as string).split(';').map(value => value.trim());
		const busienssesCci: string[] = (config[LevelBaseConfig.FIELD_CUSTOMER_COST_INCREASE] as string).split(';').map(value => value.trim());
		businessKeys.forEach((businessKey, i) => {
			const customerMultipliers: number[] = businessesMultipliers[i].split(',').map(value => Number(value.trim()));
			const cciArray: number[] = busienssesCci[Math.min(i, busienssesCci.length - 1)].split(',').map(value => Number(value.trim()));
			const businessLevelConfig: BusinessLevelConfig = {
				customerMultipliers,
				cciArray,
			};
			this.businesses.set(businessKey, businessLevelConfig);
		});

		this.questLines = [];

		this.background = config[LevelBaseConfig.FIELD_BACKGROUND].trim();

		this.soundTheme = config[LevelBaseConfig.FIELD_SOUND_THEME].trim();
	}

	protected abstract getKeyQuestLine(i: number): string;

	protected parseQuestLines(
		config: { [key: string]: string },
	): void {
		for (let lineConfig = '', i = 1; ; i++) {
			const lineConfigColumnKey = this.getKeyQuestLine(i);
			lineConfig = config[lineConfigColumnKey];
			if (lineConfig) {
				const questStrings: string[] = lineConfig.split(',');
				const questLine: QuestLineItemLevelConfig[] = questStrings.map((questString) => {
					const regexResult = questString.match(LevelBaseConfig.FORMAT_QUEST);
					const key = regexResult && regexResult[1];
					const params = regexResult && regexResult[2];
					return { key, params, lineConfigColumnKey };
				});
				this.questLines.push(questLine);
			} else {
				break;
			}
		}
	}

	public getSoftMoneyStart(): SoftMoneyNumber {
		return this.softMoneyStart;
	}

	public getQuestCount(): number {
		return this.questCount;
	}

	public getBusinessesLevelConfig(): BusinessesLevelConfig {
		return this.businesses;
	}

	public getQuestLines(): QuestLineItemLevelConfig[][] {
		return this.questLines;
	}

	public getBackground(): TimesOfDayType {
		return this.background;
	}

	public getSoundTheme(): string {
		return this.soundTheme;
	}
}
