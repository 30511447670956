import { BaseAction } from '../BaseAction';

export class CharacterPromoteAction extends BaseAction {
	constructor(
		characterId: string,
		cost: number,
		cardsCost: number,
		characterLevel: number,
		currentPrestige: number,
		currentCardCount: number,
	) {
		super('CharacterPromote');

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.character_id = characterId;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.card_count = cardsCost;
		const resourcesSpent = Object.create(null);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		resourcesSpent.prestige_money = cost;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.resources_spent = resourcesSpent;

		const resources = Object.create(null);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		resources.prestige_money = currentPrestige;
		this.sync.resources = resources;

		const characters = Object.create(null);
		characters[characterId] = Object.create(null);
		characters[characterId].cards = currentCardCount;
		characters[characterId].level = characterLevel;
		this.sync.characters = characters;
	}
}
