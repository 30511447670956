import { AssetsStorage } from '@main/AssetsStorage';
import { PreloaderBarView } from './PreloaderBarView';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetLoaderUtils } from '@src/utils/AssetLoaderUtils';
import { EventLevelAssetNames } from '@src/types/EventLevelAssetNames';
import { AssetLoader } from '@src/utils/AssetLoader';

export class EventLevelPreloaderView extends PIXI.Container {
	public static readonly EVENT_COMPLETED: symbol = Symbol();

	private readonly loadingBar: PreloaderBarView;
	private preloaderProgress?: number;

	constructor(
		private readonly mainloader: AssetLoader,
		private readonly subloader: AssetLoader,
		activatedCharacterKeys: string[],
		private readonly eventKey: string,
		preloaderLogoVisible: boolean = true,
	) {
		super();

		const bg = new PIXI.Sprite(AssetsStorage.getResource(`preloader_bg_event${eventKey}`).texture);
		bg.visible = preloaderLogoVisible;
		bg.interactive = true;

		this.loadingBar = new PreloaderBarView();
		this.loadingBar.position.set(
			GameConstants.GAME_CENTER_X,
			GameConstants.GAME_HEIGHT - this.loadingBar.height / 2,
		);

		this.mainloader.addAssetsToLoad(
			EventLevelAssetNames.getMainAssetsToLoad(this.eventKey),
		);

		AssetLoaderUtils.addCharacterAnimationsToLoad(
			EventLevelAssetNames.getEventCharacterAnimationsMetadata(this.eventKey),
			activatedCharacterKeys,
			this.mainloader,
			this.subloader,
		);

		AssetLoaderUtils.addAssetsStorageCacheToLoaders(
			this.mainloader,
			this.subloader,
		);

		this.mainloader
			.on('progress', this.onLoadProgress.bind(this))
			.on('complete', this.onMainloaderFinished.bind(this));

		this.addChild(
			bg,
			this.loadingBar as PIXI.DisplayObject,
		);
	}

	public start(): void {
		this.mainloader.load();
		this.subloader.load();
	}

	private onMainloaderFinished(): void {
		this.emit(EventLevelPreloaderView.EVENT_COMPLETED);
	}

	private onLoadProgress(loader: PIXI.loaders.Loader): void {
		this.preloaderProgress = loader.progress / 100;
		this.loadingBar.setProgress(this.preloaderProgress);
	}
}
