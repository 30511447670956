import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';

export class TutorialStepsEventLevelFactory {
	constructor(
		private readonly configs: Map<string, TutorialStepEventLevelConfig>,
		private readonly stepsModel: EventTutorialStepsModel,
	) {}

	public createModels(): Map<string, TutorialStepBaseModel> {
		const models: Map<string, TutorialStepBaseModel> = new Map();
		const tutorialStepsShowed = this.stepsModel.getCompletedSteps();

		this.configs.forEach((config) => {
			const model = new TutorialStepBaseModel(config);

			if (tutorialStepsShowed && tutorialStepsShowed.some((key) => config.getKey() === key)) {
				model.setCompleted();
			}

			models.set(config.getKey(), model);
		});

		return models;
	}
}
