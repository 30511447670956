import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';

export class DailyParticleAnimationConfig {
	public static canCollectFlare(localX: number, localY: number, localWight: number, localHeight: number, rat: number = 0): any {
		return {
			alpha: {
				start: 0,
				end: 0.5,
			},
			scale: {
				start: 0.9,
				end: 0.01,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#fff700',
				end: '#ff2b00',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: rat,
				max: rat,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0.0001,
				max: 0.0001,
			},
			lifetime: {
				min: 1,
				max: 5,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: -1,
			maxParticles: 500,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: localX,
				y: localY,
				w: localWight,
				h: localHeight,
			},
		};
	}
}

export class DailyParticleAnimationView extends PIXI.Container {
	private readonly animationEmitters: Emitter[];
	private readonly uDanimationsContainer: PIXI.Container;
	private readonly lRnimationsContainer: PIXI.Container;
	private readonly mainAnimationsContainer: PIXI.Container;

	constructor(
		wight: number,
		height: number,
	) {
		super();
		this.animationEmitters = [];

		this.uDanimationsContainer = new PIXI.Container();
		this.lRnimationsContainer = new PIXI.Container();

		this.mainAnimationsContainer = new PIXI.Container();

		const verticalOffset = 60;
		const horizontalOffset = verticalOffset / 2;

		const leftEmitter = new Emitter(
			this.lRnimationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['promote_glow1']],
			DailyParticleAnimationConfig.canCollectFlare(-wight / 2, height / 2 - horizontalOffset, 0, -height + verticalOffset, 90),
		);
		leftEmitter.autoUpdate = true;

		const rightEmitter = new Emitter(
			this.lRnimationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['promote_glow1']],
			DailyParticleAnimationConfig.canCollectFlare(wight / 2, height / 2 - horizontalOffset, 0, -height + verticalOffset, 90),
		);
		rightEmitter.autoUpdate = true;

		const upEmitter = new Emitter(
			this.uDanimationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['promote_glow1']],
			DailyParticleAnimationConfig.canCollectFlare(wight / 2 - horizontalOffset, height / 2, -wight + verticalOffset, 0),
		);
		upEmitter.autoUpdate = true;

		const downEmitter = new Emitter(
			this.uDanimationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['promote_glow1']],
			DailyParticleAnimationConfig.canCollectFlare(wight / 2 - horizontalOffset, -height / 2, -wight + verticalOffset, 0),
		);
		downEmitter.autoUpdate = true;

		this.animationEmitters.push(upEmitter, downEmitter, leftEmitter, rightEmitter);

		this.mainAnimationsContainer.addChild(
			this.uDanimationsContainer,
			this.lRnimationsContainer,
		);

		this.addChild(
			this.mainAnimationsContainer,
		);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
