import { BaseViewDispatcher, ViewDispatcherTargets, ViewDispatcherTargetType } from './BaseViewDispatcher';
import {
	LevelGameUIViewSetter,
	LevelStartWindowViewSetter,
	CollectEventRewardsWindowViewSetter,
	GalleryCollectionViewSetter,
	GoToEventLevelWindowViewSetter,
	LevelChallengeWindowViewSetter,
	DailyRewardWindowViewSetter,
} from '@interfaces/ViewSetters';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { LevelStartWindowView } from '@views/windows/levelStartWindow/LevelStartWindowView';
import { CollectEventLevelRewardsWindowView } from '@views/windows/CollectEventLevelRewardsWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { GalleryCollectionView } from '@views/windows/collection/gallery/GalleryCollectionView';
import { GoToEventLevelWindowView } from '@views/windows/GoToEventLevelWindowView';
import { LevelChallengeWindowView } from '@views/windows/levelChallenge/LevelChallengeWindowView';
import { DailyWindowBaseView } from '@views/windows/dailyWindow/DailyWindowBaseView';

export interface GameViewDispatcherTargets extends ViewDispatcherTargets {
	gameUIViewSetters?: LevelGameUIViewSetter[];
	levelStartWindowViewSetters?: LevelStartWindowViewSetter[];
	collectEventRewardsWindowViewSetters?: CollectEventRewardsWindowViewSetter[];
	galleryCollectionWindowViewSetters?: GalleryCollectionViewSetter[];
	goToEventWindowViewSetters?: GoToEventLevelWindowViewSetter[];
	levelChallengeWindowViewSetters?: LevelChallengeWindowViewSetter[];
	dailyRewardWindowViewSetters?: DailyRewardWindowViewSetter[];
}

export class LevelViewDispatcher extends BaseViewDispatcher {
	private gameUIViewTargets?: ViewDispatcherTargetType<LevelGameUIViewSetter>[];
	private levelStartWindowViewTargets?: ViewDispatcherTargetType<LevelStartWindowViewSetter>[];
	private collectEventRewardsWindowViewTargets?: ViewDispatcherTargetType<CollectEventRewardsWindowViewSetter>[];
	private galleryCollectionWindowViewTargets?: ViewDispatcherTargetType<GalleryCollectionViewSetter>[];
	private goToEventWindowViewTargets?: ViewDispatcherTargetType<GoToEventLevelWindowViewSetter>[];
	private levelChallengeWindowViewTargets?: ViewDispatcherTargetType<LevelChallengeWindowViewSetter>[];
	private dailyRewardWindowViewSetters?: ViewDispatcherTargetType<DailyRewardWindowViewSetter>[];

	public updateTargets(args: GameViewDispatcherTargets): void {
		super.updateTargets(args);

		if (args.gameUIViewSetters) {
			this.gameUIViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.gameUIViewSetters,
				this.gameUIViewTargets,
			);
		}
		if (args.levelStartWindowViewSetters) {
			this.levelStartWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.levelStartWindowViewSetters,
				this.levelStartWindowViewTargets,
			);
		}
		if (args.collectEventRewardsWindowViewSetters) {
			this.collectEventRewardsWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.collectEventRewardsWindowViewSetters,
				this.collectEventRewardsWindowViewTargets,
			);
		}

		if (args.galleryCollectionWindowViewSetters) {
			this.galleryCollectionWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.galleryCollectionWindowViewSetters,
				this.galleryCollectionWindowViewTargets,
			);
		}

		if (args.goToEventWindowViewSetters) {
			this.goToEventWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.goToEventWindowViewSetters,
				this.goToEventWindowViewTargets,
			);
		}

		if (args.levelChallengeWindowViewSetters) {
			this.levelChallengeWindowViewTargets = BaseViewDispatcher.getUpdatedTargets(
				args.levelChallengeWindowViewSetters,
				this.levelChallengeWindowViewTargets,
			);
		}

		if (args.dailyRewardWindowViewSetters) {
			this.dailyRewardWindowViewSetters = BaseViewDispatcher.getUpdatedTargets(
				args.dailyRewardWindowViewSetters,
				this.dailyRewardWindowViewSetters,
			);
		}
	}

	public dispatch(): void {
		super.dispatch();

		this.dispatchGameUIView();
		this.dispatchLevelWindowViews();
	}

	private dispatchGameUIView(): void {
		if (this.gameUIViewTargets) {
			this.gameUIViewTargets
				.filter(target => target.dispatcherView !== this.gameUIView)
				.forEach(target => {
					target.dispatcherView = this.gameUIView;
					target.dispatcherTarget.setLevelGameUIView(this.gameUIView as LevelGameUIView);
				});
		}
	}

	private dispatchLevelWindowViews(): void {
		if (this.levelStartWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_LEVEL_START_SHOWED).includes(this.setLevelStartWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_LEVEL_START_SHOWED, this.setLevelStartWindowView, this);
			}
		}

		if (this.collectEventRewardsWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_COLLECT_EVENT_REWARDS_SHOWED).includes(this.setCollectEventRewardsWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_COLLECT_EVENT_REWARDS_SHOWED, this.setCollectEventRewardsWindowView, this);
			}
		}

		if (this.goToEventWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_GO_TO_EVENT_SHOWED).includes(this.setGoToEventLevelWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_GO_TO_EVENT_SHOWED, this.setGoToEventLevelWindowView, this);
			}
		}

		if (this.levelChallengeWindowViewTargets) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_LEVEL_CHALLENGE_SHOWED).includes(this.setLevelChallengeWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_LEVEL_CHALLENGE_SHOWED, this.setLevelChallengeWindowView, this);
			}
		}

		if (this.dailyRewardWindowViewSetters) {
			if (!this.windowViewSystem.listeners(WindowViewSystem.EVENT_WINDOW_DAILY_REWARD_SHOWED).includes(this.setDailyRewardWindowView)) {
				this.windowViewSystem.on(WindowViewSystem.EVENT_WINDOW_DAILY_REWARD_SHOWED, this.setDailyRewardWindowView, this);
			}
		}
	}

	private setLevelStartWindowView(view: LevelStartWindowView): void {
		this.levelStartWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setLevelStartWindowView(view));
	}

	private setCollectEventRewardsWindowView(view: CollectEventLevelRewardsWindowView): void {
		this.collectEventRewardsWindowViewTargets?.forEach(setter => setter.dispatcherTarget.setCollectEventRewardsWindowView(view));
	}

	private setGoToEventLevelWindowView(view: GoToEventLevelWindowView): void {
		this.goToEventWindowViewTargets?.forEach(s => s.dispatcherTarget.setGoToEventLevelWindowView(view));
	}

	private setLevelChallengeWindowView(view: LevelChallengeWindowView): void {
		this.levelChallengeWindowViewTargets?.forEach((target) => target.dispatcherTarget.setLevelChallengeWindowView(view));
	}

	private setDailyRewardWindowView(view: DailyWindowBaseView): void {
		this.dailyRewardWindowViewSetters?.forEach(s => s.dispatcherTarget.setDailyRewardWindowView(view));
	}

	protected setCollectionWindowView(view: CollectionWindowView): void {
		super.setCollectionWindowView(view);

		if (this.galleryCollectionWindowViewTargets) {
			view.on(CollectionWindowView.EVENT_COLLECTION_GALLERY_VIEW_SHOWED, (v: GalleryCollectionView) => {
				this.galleryCollectionWindowViewTargets.forEach(s => s.dispatcherTarget.setGalleryCollectionViewSetter(v));
			}, this);
		}
	}
}
