import { QuestConfigBase } from '@src/configs/quests/QuestConfigBase';
import { QuestTypes, QuestType } from '@src/types/QuestTypes';

export class QuestGetPrestigeMoneyConfig extends QuestConfigBase {
	private amount: number;

	public setParamsOnLevel(params: string): void {
		this.amount = Number(params);
	}

	public getAmount(): number {
		return this.amount;
	}

	// eslint-disable-next-line class-methods-use-this
	public getType(): QuestType {
		return QuestTypes.GET_PRESTIGE_MONEY;
	}
}
