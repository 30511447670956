import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { ViewportView } from '@views/components/ViewportView';
import { ViewportUIView } from '@views/components/ViewportUIView';
import { LevelBaseView } from '@views/levels/LevelBaseView';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { EventLevelBehaviourInstance } from './behaviourInstance/EventLevelBehaviourInstance';
import { EventLevelGameUIView } from '@views/ui/main/EventLevelGameUIView';
import { EventLevelBehavioursFactory } from '@src/initializers/behaviours/EventLevelBehavioursFactory';
import { DialogConfig } from '@configs/DialogConfig';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { EventLevelWindowViewFactory } from '@src/initializers/windows/EventLevelWindowViewFactory';
import { EventLevelViewDispatcher } from './viewDispatcher/EventLevelViewDispatcher';
import { TotemModel } from '@models/TotemModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import SoftMoneyNumber, { SoftMoneyNumberRaw } from '@src/utils/SoftMoneyNumber';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { LeagueBotModel } from '@models/LeagueBotModel';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { LeagueBotConfig } from '@configs/LeagueBotConfig';
import { EventLevelLeagueConfig } from '@configs/EventLevelLeagueConfig';
import { EventLevelTicker } from '@src/tickers/EventLevelTicker';
import { GetEventLevelViewByKey } from '@views/eventLevels/EventLevelMap';
import { AnalyticSourceBankViews } from '@src/network/analytics/sources/AnalyticSourceBankViews';
import { AnalyticSourceDialogWindowView } from '@src/network/analytics/sources/AnalyticSourceDialogWindowView';
import { AnalyticSourceFarewellPartyWindowView } from '@src/network/analytics/sources/AnalyticSourceFarewellPartyWindowView';
import { LevelModelInstance } from './modelInstance/LevelModelInstance';
import { EventLevelModelInstance } from './modelInstance/EventLevelModelInstance';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { MainWindowTarget, MainWindowElementConfig } from '@configs/MainWindowConfig';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { LevelModel } from '@models/level/LevelModel';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

type EventRewardsType = {
	goalRewardDescriptionsMap: Map<SoftMoneyNumberRaw, RewardDescriptionType[]>;
	leagueRewardDescriptionsMap: Map<SoftMoneyNumberRaw, RewardDescriptionType[]>;
}

export type MainGameModels = {
	levelModel: LevelModel;
	characterModels: Map<string, CharacterModel>;
	upgradeModels: Map<string, UpgradeModel>;
	totemModels: Map<string, TotemModel>;
	boostModels: Map<string, BoostModel>;
	timeskipModels: Map<string, TimeskipModel>;
	skillModels: Map<string, SkillModel>;
};

export class EventLevelInstance extends PIXI.utils.EventEmitter {
	public static readonly EVENT_STARTED: symbol = Symbol();
	public static readonly EVENT_DESTROY: symbol = Symbol();
	public static readonly EVENT_CLOSE: symbol = Symbol();
	public static readonly EVENT_RETURN_TO_LEVEL: symbol = Symbol();
	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	private readonly mainGameModels: MainGameModels;

	private readonly windowViewFactory: EventLevelWindowViewFactory;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly viewDispatcher: EventLevelViewDispatcher;

	private readonly eventLevelTicker: EventLevelTicker;
	private readonly behaviourInstance: EventLevelBehaviourInstance;

	private viewportView: ViewportView;
	private viewportUIView: ViewportUIView;
	private gameUIView: EventLevelGameUIView;
	private currentLevelView: LevelBaseView;

	private enabled: boolean;
	private started: boolean;

	private lastDisableTime: number;

	constructor(
		private readonly stage: PIXI.Container,
		private readonly interactionManager: PIXI.interaction.InteractionManager,
		private readonly env: string,
		private readonly analyticSourceFarewellPartyView: AnalyticSourceFarewellPartyWindowView,
		private readonly analyticSourceBankViews: AnalyticSourceBankViews,
		private readonly analyticSourceDialogWindowView: AnalyticSourceDialogWindowView,
		private readonly eventLevelModelInstance: EventLevelModelInstance,
		private readonly levelModelInstance: LevelModelInstance,
		private readonly cheatModel: CheatModel,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly serverTimeModel: ServerTimeModel,
		private readonly gameProfileModel: GameProfileModel,
		private readonly levelRewardFactory: RewardFactory,
		tutorialStepConfigs: Map<string, TutorialStepEventLevelConfig>,
		private readonly leagueConfigs: Map<string, EventLevelLeagueConfig>,
		private readonly leagueBotConfigs: Map<string, LeagueBotConfig>,
		private readonly dialogConfigs: Map<string, DialogConfig>,
		private readonly questConfigs: Map<string, QuestConfigBase>,
		private readonly constantsConfig: ConstantsConfig,
		private readonly localizationSupportConfig: LocalizationSupportConfig,
		private readonly savesConfig: SavesConfig,
		private readonly levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>,
		private readonly runInEpisodeAssetsSandbox: SandboxOperation,
		private readonly eventLevelEverEntered: boolean,
		private readonly eventLevelEverInited: boolean,
		private readonly isNutaku: boolean,
		assetUrlsLoader: AssetUrlsLoader,
		version: string,
	) {
		super();

		this.lastDisableTime = 0;
		this.enabled = false;
		this.mainGameModels = {
			boostModels: this.levelModelInstance.getBoostModels(),
			characterModels: this.levelModelInstance.getCharacterModels(),
			skillModels: this.levelModelInstance.getSkillModels(),
			timeskipModels: this.levelModelInstance.getTimeskipModels(),
			totemModels: this.levelModelInstance.getTotemModels(),
			upgradeModels: this.levelModelInstance.getUpgradeModels(),
			levelModel: this.levelModelInstance.getLevelModel(),
		};

		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();
		this.eventLevelTicker = new EventLevelTicker(
			eventLevelModel,
			this.cheatModel,
			this.constantsConfig.getNoviceEventKey(),
		);

		eventLevelModel.on(EventLevelModel.EVENT_TIMEOUT, this.onEventLevelTimeout, this);
		eventLevelModel.on(EventLevelModel.EVENT_REQUEST_REWARD_COLLECT, this.onEventRewardCollected, this);

		const businessesLastUpdate = new Map();
		const businessesSaveData = eventLevelEverEntered ? this.savesConfig.getBusinessesSaveData() : new Map();
		businessesSaveData.forEach((data, key) => {
			businessesLastUpdate.set(key, data.updated);
		});

		let eventLevelPrestigeMoneyValue: number;
		const eventTransactionsSaveData = eventLevelEverEntered ? this.savesConfig.getEventTransactionsSaveData() : [];
		const eventLevelQuestsInitData = eventLevelEverEntered ? this.savesConfig.getEventLevelQuestsInitData() : [];
		let eventLevelSoftMoneyValue: SoftMoneyNumber | undefined;
		const eventLevelSaveData = eventLevelEverInited ? this.savesConfig.getEventLevelInitData() : undefined;

		if (eventLevelEverEntered && this.savesConfig.hasEventLevelSoftMoney()) {
			eventLevelSoftMoneyValue = SoftMoneyNumber.createFromString(this.savesConfig.getEventLevelSoftMoney());
		}
		if (eventLevelEverEntered && this.savesConfig.hasEventLevelPrestigeMoney()) {
			eventLevelPrestigeMoneyValue = parseInt(this.savesConfig.getEventLevelPrestigeMoney(), 10);
		} else {
			eventLevelPrestigeMoneyValue = 0;
		}

		const levelModel = this.levelModelInstance.getLevelModel();
		const farewellPartyEverStarted = this.savesConfig.getFarewellPartyEverStarted()
			|| levelModel.getCurrentLevel() > this.levelBaseWindowConfig.get(MainWindowTarget.PREPARTY_CHARACTER).unlockValue.level;

		this.windowViewSystem = new WindowViewSystem();
		this.windowViewFactory = new EventLevelWindowViewFactory(
			eventLevelModel,
			this.serverTimeModel,
			this.eventLevelModelInstance,
			this.mainGameModels,
			this.gameProfileModel,
			this.dialogConfigs,
			this.constantsConfig,
			this.localizationSupportConfig,
			this.networkRequestSender,
			this.savesConfig.getRedeemCode(),
			this.cheatModel,
			this.runInEpisodeAssetsSandbox,
			false,
			true,
			this.isNutaku,
			version,
		);

		const hintSpawner = new ShowHintSpawner(
			this.interactionManager,
			this.runInEpisodeAssetsSandbox,
		);

		const behavioursFactory = new EventLevelBehavioursFactory(
			this.eventLevelModelInstance,
			this.networkRequestSender,
			this.cheatModel,
			eventLevelModel,
			this.gameProfileModel,
			this.levelModelInstance.getBankPaymentSaveModel(),
			eventTransactionsSaveData,
			this.serverTimeModel,
			questConfigs,
			constantsConfig,
			savesConfig,
			tutorialStepConfigs,
			businessesLastUpdate,
			eventLevelEverEntered,
			farewellPartyEverStarted,
			this.windowViewSystem,
			this.windowViewFactory,
			hintSpawner,
			this.mainGameModels,
			this.eventLevelModelInstance.getBoostModels(),
			this.eventLevelModelInstance.getFarewellBoostModel(),
			this.savesConfig.getEventCompensationLootboxConfigs(),
			this.isNutaku,
			assetUrlsLoader,
		);
		this.behaviourInstance = new EventLevelBehaviourInstance(
			behavioursFactory,
			this.eventLevelModelInstance,
			eventLevelQuestsInitData,
		);
		this.behaviourInstance.on(
			EventLevelBehaviourInstance.EVENT_RETURN_TO_LEVEL,
			() => this.emit(EventLevelInstance.EVENT_RETURN_TO_LEVEL),
			this,
		);
		this.behaviourInstance.on(EventLevelBehaviourInstance.EVENT_LOCALIZATION_CHANGE, (lang: string) => {
			this.emit(EventLevelInstance.EVENT_LOCALIZATION_CHANGE, lang);
		}, this);
		this.behaviourInstance.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}, this);

		this.viewDispatcher = new EventLevelViewDispatcher();

		this.eventLevelModelInstance.getPrestigeMoneyModel().setValue(eventLevelPrestigeMoneyValue);
		this.eventLevelModelInstance.getSoftMoneyModel().setValue(
			eventLevelSoftMoneyValue || eventLevelModel.getSoftMoneyStart(),
		);

		this.eventLevelModelInstance.getDialogsFactory().init(
			eventLevelModel.getKey().toString(),
		);

		this.eventLevelModelInstance.getCharacterModels().forEach((characterModel) => {
			characterModel.setActivateLevel(1);
		});

		this.eventLevelModelInstance.getPrepartyStartCharacterModel()
			.respawn(this.savesConfig.getEventNextFarewellPartyTime() - this.serverTimeModel.getCalculatedISOTime());

		eventLevelModel.getBusinessesLevelConfig().forEach((businessLevelConfig, businessKey) => {
			const model: BusinessModel = this.eventLevelModelInstance.getBusinessModels().get(businessKey);
			model.setAvailable();
			model.setCustomerMultipliers(businessLevelConfig.customerMultipliers);
			model.setCustomerCostIncreaseArray(businessLevelConfig.cciArray);
		});

		const getBusinessIncomePerSec = (): SoftMoneyNumber => {
			const businessModels = Array.from(this.eventLevelModelInstance.getBusinessModels().values());
			const busienssModelsAutomated = businessModels.filter(model => model.isAutomated());
			let incomePerSec: SoftMoneyNumber;
			if (busienssModelsAutomated.length > 0) {
				incomePerSec = busienssModelsAutomated
					.map(model => model.getBusinessIncomePerSec(false, true, true))
					.reduce((prev, next) => prev.add(next));
			} else {
				incomePerSec = SoftMoneyNumber.ZERO;
			}
			return incomePerSec;
		};
		eventLevelModel.setIncomePerSec(getBusinessIncomePerSec());

		this.sendGetEventRewardsFormatted(eventLevelModel.getKey()).then((rewards) => {
			const leagueRewardModelsMap = this.createEventRewardModels(rewards.leagueRewardDescriptionsMap);
			const goalRewardModelsMap = this.createEventRewardModels(rewards.goalRewardDescriptionsMap);

			// Event ever started
			if (eventLevelSaveData) {
				const leagueBotModels = this.createLeagueBotModels(
					eventLevelSaveData.leagueKey,
					eventLevelSaveData.data.leagueBotNames,
				);

				const goalProgress = SoftMoneyNumber.createFromString(eventLevelSaveData.progress);

				const currentTime = this.serverTimeModel.getCalculatedISOTime();
				let elapsedTime;

				if (eventLevelModel.getStartDate() !== 0) {
					elapsedTime = currentTime - eventLevelModel.getStartDate();
				} else {
					elapsedTime = currentTime - eventLevelSaveData.startedOn;
				}

				const timeleft = Math.max(0, eventLevelModel.getDuration() - elapsedTime);

				eventLevelModel.setFromSaveData(
					goalRewardModelsMap,
					leagueRewardModelsMap,
					leagueBotModels,
					timeleft,
					goalProgress,
				);

				// Timeout
				if (timeleft <= 0 && eventLevelModel.hasRewards()) {
					eventLevelModel.initWithStateRewardCollect();
					// Event running
				} else {
					eventLevelModel.initWithStateEventAvailable();
					this.eventLevelTicker.startTimer();
				}
				// Event hasn't started
			} else {
				this.sendGetLeagueBotNamesFormatted(eventLevelModel.getKey()).then((leagueBotNames) => {
					const leagueBotModels = this.createLeagueBotModels(
						eventLevelModel.getLeagueKeys()[0],
						leagueBotNames,
					);

					eventLevelModel.setFromSaveData(
						goalRewardModelsMap,
						leagueRewardModelsMap,
						leagueBotModels,
					);

					eventLevelModel.initWithStateEventAvailable();
					this.eventLevelTicker.startTimer();
				});
			}
		});
	}

	public startEventLevel(): void {
		this.behaviourInstance.initBankLoader();
		this.setBehaviourInstanceEnabled(true);
		this.behaviourInstance.setBoostsTickerEnabled(true);
		this.behaviourInstance.setPrepartyStartCharacterTickerEnabled(true);
		this.behaviourInstance.init();

		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();

		this.viewportView = new ViewportView();
		this.viewportUIView = new ViewportUIView(this.viewportView);

		this.gameUIView = new EventLevelGameUIView(
			this.viewportView,
			this.serverTimeModel,
			eventLevelModel,
			this.gameProfileModel,
			this.eventLevelModelInstance.getQuestLinesModel(),
			this.eventLevelModelInstance.getSoftMoneyModel(),
			this.eventLevelModelInstance.getHardMoneyModel(),
			this.eventLevelModelInstance.getPrestigeMoneyModel(),
			this.eventLevelModelInstance.getSummonModelsPool(),
			this.eventLevelModelInstance.getBusinessModels(),
			this.eventLevelModelInstance.getCharacterModels(),
			this.eventLevelModelInstance.getUpgradeModels(),
			this.eventLevelModelInstance.getSkillModels(),
			this.eventLevelModelInstance.getBoostModels(),
			this.eventLevelModelInstance.getFarewellBoostModel(),
			this.eventLevelModelInstance.getBankModel(),
			this.mainGameModels,
			this.interactionManager,
			this.constantsConfig.getShowQuestTargetDuration(),
			this.constantsConfig.getMaxButtonValues(),
			this.env,
			this.runInEpisodeAssetsSandbox,
			this.eventLevelModelInstance.getMaintenanceModel(),
			this.savesConfig.getButtonMaxCustomersCurrentValueId(),
		);

		const EventLevelView = GetEventLevelViewByKey(eventLevelModel.getKey());
		this.currentLevelView = new EventLevelView({
			cheatModel: this.cheatModel,
			businessModels: this.eventLevelModelInstance.getBusinessModels(),
			newCustomerMultiplierModels: this.eventLevelModelInstance.getNewCustomerMultiplierModels(),
			characterModels: this.eventLevelModelInstance.getCharacterModels(),
			skillModels: this.eventLevelModelInstance.getSkillModels(),
			upgradeModels: this.eventLevelModelInstance.getUpgradeModels(),
			softMoneyModel: this.eventLevelModelInstance.getSoftMoneyModel(),
			prestigeMoneyModel: this.eventLevelModelInstance.getPrestigeMoneyModel(),
			prepartyStartCharacterModel: this.eventLevelModelInstance.getPrepartyStartCharacterModel(),
			characterQuickPhraseEmitter: this.eventLevelModelInstance.getCharacterQuickPhraseEmitter(),
			background: eventLevelModel.getBackground(),
			buttonMaxCustomersView: this.gameUIView.getButtonMaxCustomersView(),
			viewportUI: this.viewportUIView,
			viewport: this.viewportView,
		});
		this.viewportView.setContent(this.currentLevelView);

		this.windowViewFactory.setGameUIView(this.gameUIView);

		this.windowViewSystem.setViewport(this.viewportView);
		this.windowViewSystem.setWindowsContainer(this.gameUIView);

		this.currentLevelView.visible = false;
		this.viewportUIView.visible = false;
		this.viewportView.visible = false;
		this.gameUIView.visible = false;

		this.stage.addChild(this.viewportView);
		this.stage.addChild(this.viewportUIView);
		this.stage.addChild(this.gameUIView);

		this.viewDispatcher
			.setViewportView(this.viewportView)
			.setViewportUIView(this.viewportUIView)
			.setLevelView(this.currentLevelView)
			.setGameUIView(this.gameUIView)
			.setWindowViewSystem(this.windowViewSystem);

		this.updateViewDispatcher();
		this.viewDispatcher.dispatch();

		const startLevelViews = (): void => {
			this.gameUIView.init();

			this.gameUIView.visible = true;
			this.currentLevelView.visible = true;
			this.viewportView.visible = true;
			this.viewportUIView.visible = true;

			this.started = true;
			this.enabled = true;
			this.emit(EventLevelInstance.EVENT_STARTED);
		};

		const questLinesModel = this.eventLevelModelInstance.getQuestLinesModel();
		if (questLinesModel.isInited()) {
			startLevelViews();
		} else {
			questLinesModel.once(QuestLinesModel.EVENT_INITED, startLevelViews, this);
		}
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (value && this.lastDisableTime > 0) {
			const disableTime = this.serverTimeModel.getCalculatedISOTime() - this.lastDisableTime;
			const backToGameRewardModel = this.eventLevelModelInstance.getBackToGameRewardModel();
			backToGameRewardModel.setOfflineSeconds(
				disableTime,
				this.lastDisableTime,
			);
		} else if (!value) {
			this.lastDisableTime = this.serverTimeModel.getCalculatedISOTime();
		}

		if (value) {
			this.behaviourInstance.initBankLoader();
		}

		this.setBehaviourInstanceEnabled(value);

		this.gameUIView.visible = value;
		this.viewportView.visible = value;
		this.viewportUIView.visible = value;
	}

	public isEnabled(): boolean {
		return this.enabled;
	}

	public isStarted(): boolean {
		return this.started;
	}

	private onEventLevelTimeout(): void {
		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();

		if (eventLevelModel.hasRewards()) {
			eventLevelModel.initWithStateRewardCollect();

			this.emit(EventLevelInstance.EVENT_CLOSE);
		} else {
			eventLevelModel.initWithStateDefault();

			this.emit(EventLevelInstance.EVENT_CLOSE);
			this.emit(EventLevelInstance.EVENT_DESTROY);
		}
	}

	private onEventRewardCollected(): void {
		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();
		eventLevelModel.initWithStateDefault();

		this.emit(EventLevelInstance.EVENT_CLOSE);
		this.emit(EventLevelInstance.EVENT_DESTROY);
	}

	private async sendGetLeagueBotNamesFormatted(eventKey: string): Promise<string[]> {
		const result = await this.networkRequestSender.sendGetEventLeagueBotNames(eventKey);
		if (result.error) {
			return Promise.reject(result);
		}
		const resultFormatted = Object.keys(result).map(key => result[key] as string);
		return Promise.resolve(resultFormatted);
	}

	private async sendGetEventRewardsFormatted(eventKey: string): Promise<EventRewardsType> {
		const rewards = await this.networkRequestSender.sendGetEventRewards(eventKey);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const eventGoalsData = rewards?.event_goals_reward;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const leagueGoalsData = rewards?.leaderboard_rewards;
		if (eventGoalsData && leagueGoalsData) {
			const resultUnformatted: {
				goalRewards: { [progressSoftMoneyAcronym: string]: { [id: string]: RewardDescriptionType } };
				leagueRewards: { [progressSoftMoneyAcronym: string]: { [id: string]: RewardDescriptionType } };
			} = {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				goalRewards: rewards?.event_goals_reward?.rewards,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				leagueRewards: rewards?.leaderboard_rewards?.rewards,
			};

			const goalRewardDescriptionsMap: Map<SoftMoneyNumberRaw, RewardDescriptionType[]> = new Map();
			Object.keys(resultUnformatted.goalRewards || {}).forEach(progressSoftMoneyAcronym => {
				const goalRewardDescriptionsObjectArray: { [id: string]: RewardDescriptionType } = resultUnformatted.goalRewards[progressSoftMoneyAcronym];
				const goalRewardDescriptionsArray: RewardDescriptionType[] = Object
					.keys(goalRewardDescriptionsObjectArray)
					.map(id => goalRewardDescriptionsObjectArray[id]);
				const progressSoftMoneyRaw = SoftMoneyNumber.createFromAcronym(progressSoftMoneyAcronym).toRawString();
				goalRewardDescriptionsMap.set(progressSoftMoneyRaw, goalRewardDescriptionsArray || []);
			});

			const leagueRewardDescriptionsMap: Map<string, RewardDescriptionType[]> = new Map();
			Object.keys(resultUnformatted.leagueRewards || {}).forEach(key => {
				const leagueRewardsUnformatted = resultUnformatted.leagueRewards[key];
				const leagueRewardDescriptionArray = Object
					.keys(leagueRewardsUnformatted)
					.map(goalRewardKey => leagueRewardsUnformatted[goalRewardKey]);
				leagueRewardDescriptionsMap.set(SoftMoneyNumber.createFromAcronym(key).toRawString(), leagueRewardDescriptionArray || []);
			});
			return Promise.resolve({
				goalRewardDescriptionsMap,
				leagueRewardDescriptionsMap,
			});
		}
		return Promise.reject();
	}

	private createLeagueBotModels(
		leagueKey: string,
		leagueBotNames: string[],
	): LeagueBotModel[] {
		const currentLeagueConfig = this.leagueConfigs.get(leagueKey);
		return currentLeagueConfig
			.getBotData()
			.map((data, i) => new LeagueBotModel(
				this.leagueBotConfigs.get(data.botKey),
				leagueBotNames?.[i] || '',
			));
	}

	private createEventRewardModels(data: Map<SoftMoneyNumberRaw, RewardDescriptionType[]>): Map<SoftMoneyNumberRaw, AbstractReward[]> {
		const result: Map<string, AbstractReward[]> = new Map();
		data.forEach((rewardDescriptions, targetProgress) => {
			const rewardArray = rewardDescriptions.map(reward => this.levelRewardFactory.createReward(reward));
			result.set(targetProgress, rewardArray);
		});
		return result;
	}

	private updateViewDispatcher(): void {
		this.viewDispatcher.updateTargets({
			viewportViewSetters: this.behaviourInstance.getViewportViewSetters(),
			viewportUIViewSetters: this.behaviourInstance.getViewportUIViewSetters(),
			gameUIBaseViewSetters: [...this.behaviourInstance.getGameUIBaseViewSetters()],
			backToGameWindowViewSetters: this.behaviourInstance.getBackToGameWindowViewSetters(),
			eventGameUIViewSetters: [...this.behaviourInstance.getEventGameUIViewSetters()],
			businessBaseViewsSetters: this.behaviourInstance.getBusinessBaseViewsSetters(),
			businessAcquiredUIViewSetters: this.behaviourInstance.getBusinessAcquiredUIViewSetters(),
			businessManageUIViewSetters: this.behaviourInstance.getBusinessManageUIViewSetters(),
			businessOpenToBuyUIViewSetters: this.behaviourInstance.getBusinessOpenToBuyUIViewSetters(),
			skillBaseViewSetter: this.behaviourInstance.getSkillBaseViewSetters(),
			questWindowViewSetters: this.behaviourInstance.getQuestWindowBaseViewSetters(),
			characterProfileViewSetters: this.behaviourInstance.getCharacterProfileWindowViewSetters(),
			upgradePromoteViewSetters: this.behaviourInstance.getUpgradePromoteWindowViewSetters(),
			summonCollectionViewSetters: this.behaviourInstance.getSummonCollectionViewSetters(),
			summonResultViewSetters: this.behaviourInstance.getSummonResultViewSetters(),
			bankWindowViewSetters: this.behaviourInstance.getBankWindowViewSetters(),
			bankOfferWindowViewSetters: [...this.behaviourInstance.getBankOfferWindowViewSetters(), this.analyticSourceBankViews],
			tutorialStepViewSetters: this.behaviourInstance.getTutorialStepWindowViewSetters(),
			buttonMaxCustomersViewSetters: this.behaviourInstance.getButtonMaxCustomersViewSetters(),
			settingsWindowViewSetters: this.behaviourInstance.getSettingsWindowViewSetters(),
			rewardResultWindowViewSetters: this.behaviourInstance.getRewardResultWindowViewSetters(),
			supportWindowViewSetters: this.behaviourInstance.getSupportWindowViewSetters(),
			registrationWindowViewSetters: this.behaviourInstance.getRegistrationWindowViewSetters(),
			eventRankingViewSetters: this.behaviourInstance.getEventRankingWindowViewSetters(),
			eventLevelRewardsWindowViewSetters: this.behaviourInstance.getEventLevelRewardsWindowViewSetters(),
			bankOfferLinesUIViewSetters: [this.analyticSourceBankViews],
			dialogWindowViewSetters: [this.analyticSourceDialogWindowView],
			prepartyStartCharacterViewSetters: this.behaviourInstance.getPrepartyStartCharacterViewSetters(),
			farewellPartyViewSetters: [...this.behaviourInstance.getFarewellPartyViewSetters(), this.analyticSourceFarewellPartyView],
			characterCollectionViewSetters: this.behaviourInstance.getCharacterCollectionViewSetters(),
			totemCollectionViewSetters: this.behaviourInstance.getTotemCollectionViewControllers(),
			upgradeCollectionViewSetters: this.behaviourInstance.getUpgradeCollectionViewSetters(),
			furnitureCollectionViewSetters: this.behaviourInstance.getFurnitureCollectionViewSetters(),
			prepartyWidowViewSetters: this.behaviourInstance.getPrepartyWindowViewSetters(),
			cheatWindowViewSetters: this.behaviourInstance.getCheatWindowViewSetters(),
		});
	}

	public destroy(): void {
		// eslint-disable-next-line no-unused-expressions
		this.gameUIView?.destroy({ children: true });
		// eslint-disable-next-line no-unused-expressions
		this.viewportView?.destroy();
		// eslint-disable-next-line no-unused-expressions
		this.viewportUIView?.destroy({ children: true });
		// eslint-disable-next-line no-unused-expressions
		this.currentLevelView?.destroy({ children: true });

		const eventLevelModel = this.eventLevelModelInstance.getLevelModel();
		eventLevelModel.off(EventLevelModel.EVENT_TIMEOUT, this.onEventLevelTimeout, this);
		eventLevelModel.off(EventLevelModel.EVENT_REQUEST_REWARD_COLLECT, this.onEventRewardCollected, this);

		this.behaviourInstance.destroy();
	}

	private setBehaviourInstanceEnabled(value: boolean): void {
		this.behaviourInstance.setBankTickerEnabled(value)
			.setSkillsTickerEnabled(value)
			.setSummonTickerEnabled(value)
			.setBusinessesTickerEnabled(value)
			.setMaintenanceTickerEnabled(value)
			.setBankLoaderEnabled(value);
	}
}
