import { GameConstants } from '@src/utils/GameConstants';
import { BaseLinksView, PreloaderButtonDataType } from './BaseLinksView';

export const legalLinks = [
	{
		text: 'Privacy Policy',
		link: '/docs/privacy_policy.html',
	},
	{
		text: 'Terms of Use',
		link: '/docs/terms_of_use.html',
	},
];

export class PreloaderLinksView extends BaseLinksView {
	constructor() {
		super();

		const epoch = new PIXI.extras.BitmapText('Please visit EPOCH.COM, our authorized sales agent.', {
			font: '26px wendyOne',
			tint: 0x939393,
		});
		epoch.interactive = true;
		epoch.on('pointerdown', () => window.open('http://epoch.com/'), epoch);

		epoch.y = this.buttonsContainer.height + 15;
		if (this.buttonsContainer.width > epoch.width) {
			epoch.x = (this.buttonsContainer.width - epoch.width) / 2;
		} else {
			this.buttonsContainer.x = (epoch.width - this.buttonsContainer.width) / 2;
		}
		this.linksContainer.addChild(epoch);

		this.linksContainer.position.set(
			GameConstants.GAME_CENTER_X - (this.linksContainer.width / 2),
			(PreloaderLinksView.BAR_HEIGHT - PreloaderLinksView.BAR_OFFSET) / 2 - this.linksContainer.height / 2,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getButtonsData(): PreloaderButtonDataType[] {
		return [
			{
				text: 'File a Complaint',
				link: 'mailto:info@cuntempire.com',
			},
			{
				text: 'Billing Support',
				link: 'https://epoch.com/billing_support/',
			},
			{
				text: 'Contact Us',
				link: 'mailto:info@cuntempire.com',
			},
			{
				text: '2257 Exempt',
				link: '/docs/2257.html',
			},
			...legalLinks,
		];
	}
}
