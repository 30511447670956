
import { BoostConfig } from '@configs/BoostConfig';
import { BoostSaveData } from '@src/types/SaveTypes';
import { BaseBoostModel } from './BaseBoostModel';
import { CalculationType } from '@src/types/CalculationType';

export class BoostModel extends BaseBoostModel {
	private iconKey: string;
	private deactivateTime?: number;

	constructor() {
		super();

		this.calculationType = CalculationType.SUM;
	}

	public activate(serverTime: number): void {
		this.deactivateTime = serverTime + this.time;
		super.onActivate();
	}

	public setFromConfig(config: BoostConfig): void {
		this.key = config.getKey();
		this.time = config.getTime();
		this.iconKey = config.getIconKey();
		this.bonusType = config.getBonusType();
		this.bonusValue = config.getBonusValue();
	}

	public setFromSaveData(data: BoostSaveData): void {
		this.activateCount = data.available;
		this.deactivateTime = data.deactivateTime;

		if (this.deactivateTime !== undefined) {
			this.currentActivateCount = 1;
			this.activateCount += 1;
			this.activated = true;
		}
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getDeactivateTime(): number | undefined {
		return this.deactivateTime;
	}

	public setDeactivateTime(value: number): void {
		this.deactivateTime = value;
	}
}
