import { LevelBackground } from './LevelBaseView';
import { Business1View } from '@views/businesses/building1/Business1View';
import { Business2View } from '@views/businesses/building1/Business2View';
import { Business3View } from '@views/businesses/building1/Business3View';
import { Business4View } from '@views/businesses/building2/Business4View';
import { Business5View } from '@views/businesses/building2/Business5View';
import { Business6View } from '@views/businesses/building2/Business6View';
import { Business7View } from '@views/businesses/building3/Business7View';
import { Business8View } from '@views/businesses/building3/Business8View';
import { Business9View } from '@views/businesses/building3/Business9View';
import { Business10View } from '@views/businesses/building4/Business10View';
import { Business11View } from '@views/businesses/building4/Business11View';
import { Business12View } from '@views/businesses/building4/Business12View';
import { Business13View } from '@views/businesses/building5/Business13View';
import { Business14View } from '@views/businesses/building5/Business14View';
import { Business15View } from '@views/businesses/building5/Business15View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@main/AssetsStorage';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { LevelDefaultView } from './LevelDefaultView';
import { LevelSnowAnimationView } from './LevelSnowAnimationView';

export class Season3LevelDefaultView extends LevelDefaultView {
	private static readonly LEVEL_WIDTH: number = 5130;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	private static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('italyMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['italy_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 2600);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['italy_morning/road'], Season3LevelDefaultView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/house5']).setTransform(-55, 656),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house4']).setTransform(919, 660),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(191, 764),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(1258, 764),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house1']).setTransform(1513, 618),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house2']).setTransform(2197, 622),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(1899, 768),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(2421, 768),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house1']).setTransform(2740, 618),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house3']).setTransform(3450, 642),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house4']).setTransform(4050, 660),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(4400, 754),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house1']).setTransform(4400, 618),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(619, 666),
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(2642, 666),
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(3713, 666),
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(4060, 666),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('italyMorningAtlas');

		this.background = Season3LevelDefaultView.createMorningBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season3LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor4']).setTransform(1200, 400),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor3']).setTransform(1190, 545),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor2']).setTransform(1143, 945),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(2152, 539),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor4']).setTransform(3040, 400),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor3']).setTransform(3030, 545),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor2']).setTransform(2983, 945),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(3992, 539),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season3LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	private static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('italyEveningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['italy_evening/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 2600);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['italy_evening/road'], Season3LevelDefaultView.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/house5']).setTransform(-55, 656),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house4']).setTransform(919, 660),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(191, 764),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(1258, 764),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house1']).setTransform(1513, 618),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house2']).setTransform(2197, 622),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(1899, 768),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(2421, 768),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house1']).setTransform(2740, 618),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house3']).setTransform(3450, 642),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house4']).setTransform(4050, 660),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(4400, 754),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house1']).setTransform(4400, 618),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(619, 666),
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(2642, 666),
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(3713, 666),
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(4060, 666),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('italyEveningAtlas');

		this.background = Season3LevelDefaultView.createEveningBackground();
		this.addChild(this.background);

		const levelSnowAnimationView: LevelSnowAnimationView = this.createSnowAnimation(Season3LevelDefaultView.LEVEL_WIDTH);
		this.addChild(levelSnowAnimationView);

		const businesses = [
			new Business1View(),
			new Business2View(),
			new Business3View(),
			new Business4View(),
			new Business5View(),
			new Business6View(),
			new Business7View(),
			new Business8View(),
			new Business9View(),
			new Business10View(),
			new Business11View(),
			new Business12View(),
			new Business13View(),
			new Business14View(),
			new Business15View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor4']).setTransform(1200, 400),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor3']).setTransform(1190, 545),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor2']).setTransform(1143, 945),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		// BUILDING 3
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(2906, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(2527, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(2097, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(2152, 539),
			businesses[6].setTransform(2540, 807),
			businesses[7].setTransform(2540, 522),
			businesses[8].setTransform(2540, 237),
		);

		// BUILDING 4
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(3826, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(3447, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(3017, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(3072, 539),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor4']).setTransform(3040, 400),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor3']).setTransform(3030, 545),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor2']).setTransform(2983, 945),
			businesses[9].setTransform(3460, 807),
			businesses[10].setTransform(3460, 522),
			businesses[11].setTransform(3460, 237),
		);

		// BUILDING 5
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(4746, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(4367, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(3937, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(3992, 539),
			businesses[12].setTransform(4380, 807),
			businesses[13].setTransform(4380, 522),
			businesses[14].setTransform(4380, 237),
		);

		const prepartyStartCharacterView: PrepartyStartCharacterView = this.createPrepartyStartCharacter();
		this.addChild(prepartyStartCharacterView);

		this.initLevel(Season3LevelDefaultView.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses, prepartyStartCharacterView);
	}

	protected getPrePartyCharacterPosition(): PIXI.Point {
		const randomIndex: number = this.getAcquiredBuildings().sort(() => Math.random() - 0.5).pop() || 0;

		const positions = [
			new PIXI.Point(1100, 853),
			new PIXI.Point(2020, 853),
			new PIXI.Point(2940, 853),
			new PIXI.Point(3860, 853),
			new PIXI.Point(4780, 853),
		];

		return positions[randomIndex];
	}

	// eslint-disable-next-line class-methods-use-this
	private createPrepartyStartCharacter(): PrepartyStartCharacterView {
		const view = new PrepartyStartCharacterView();
		view.visible = false;
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	private createSnowAnimation(wight: number): LevelSnowAnimationView {
		const view = new LevelSnowAnimationView(wight);
		return view;
	}
}
