import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90007View } from '@views/characters/events/event9/EventCharacter90007View';
import { EventCharacter90009View } from '@views/characters/events/event9/EventCharacter90009View';
import { EventCharacter90008View } from '@views/characters/events/event9/EventCharacter90008View';

export class EventBusiness90003View extends BusinessBaseView {
	constructor() {
		const eventCharacter90007View = new EventCharacter90007View();
		const eventCharacter90008View = new EventCharacter90008View();
		const eventCharacter90009View = new EventCharacter90009View();

		super('business90003', [eventCharacter90007View, eventCharacter90008View, eventCharacter90009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter90007View as PIXI.DisplayObject,
			eventCharacter90008View,
			eventCharacter90009View,
		);
	}
}
