import { CharacterMainBaseView } from '@views/characters/CharacterMainBaseView';
import { CharacterBaseView } from '@views/characters/CharacterBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { ViewportView } from '@views/components/ViewportView';
import { BusinessBounds, BusinessAcquiredUIView } from './acquired/BusinessAcquiredUIView';
import { BusinessModel } from '@models/BusinessModel';
import * as TWEEN from '@tweenjs/tween.js';
import { SoundController } from '@src/main/SoundController';
import { BusinessOpenToBuyUIView } from './openToBuy/BusinessOpenToBuyUIView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BusinessNotAvailableUIView } from './notAvailable/BusinessNotAvailableUIView';
import { UpgradeModel } from '@models/UpgradeModel';
import { CharacterSkillPairType } from '@src/types/CharacterSkillPairType';
import { ButtonMaxCustomersView } from '@views/ui/ButtonMaxCustomersView';
import { ViewportUIView } from '@views/components/ViewportUIView';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';
import { Emitter } from 'pixi-particles';
import { BusinessCloseUIView } from '@views/businesses/ui/closed/BusinessCloseUIView';
import { CheatModel } from '@models/CheatModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';

export enum BusinessUIViewTypes {
	NOT_AVAILABLE,
	OPEN_TO_BUY,
	ACQUIRED,
	CLOSED,
}

type BusinessUIWithOtherZoomViewType =
	BusinessOpenToBuyUIView |
	BusinessNotAvailableUIView |
	BusinessAcquiredUIView;

export type BusinessUIViewType =
	BusinessOpenToBuyUIView |
	BusinessNotAvailableUIView |
	BusinessCloseUIView |
	BusinessAcquiredUIView;

export class BusinessBaseView extends PIXI.Container {
	public static readonly EVENT_ZOOM_IN_REQUESTED = Symbol();
	public static readonly EVENT_ZOOM_OUT_REQUESTED = Symbol();
	public static readonly EVENT_ZOOM_IN_COMPLETED = Symbol();
	public static readonly EVENT_ZOOM_IN_STARTED = Symbol();
	public static readonly EVENT_ZOOM_OUT_COMPLETED = Symbol();
	public static readonly EVENT_ZOOM_OUT_STARTED = Symbol();
	public static readonly EVENT_NEW_UI_VIEW = Symbol();

	protected readonly furniture: Map<string, PIXI.Sprite>;
	protected readonly furnitureContainer: PIXI.Container;
	protected decorations: PIXI.Sprite[];

	protected background: PIXI.Sprite;
	protected backgroundUI: PIXI.Sprite;

	private cheatModel: CheatModel;
	private model: BusinessModel;
	private softMoneyModel: SoftMoneyModel;
	private prestigeMoneyModel: PrestigeMoneyModel;
	private skillModels: Map<string, SkillModel>;
	private upgrades: UpgradeModel[];
	private charactersModel: CharacterModel[];
	private characterSkillPairs: CharacterSkillPairType[];
	private buttonMaxCustomers: ButtonMaxCustomersView;
	private viewport: ViewportView;
	private viewportUI: ViewportUIView;

	private animationEmitters: Emitter[];

	private boundsViewport: BusinessBounds;

	private tweenBlackoutAppear: TWEEN.Tween;
	private tweenBlackoutHide: TWEEN.Tween;

	private localBounds: PIXI.Rectangle;

	private currentUIViewType: BusinessUIViewTypes;
	private currentUIView: BusinessUIViewType;

	private currentLevel: number;

	private newCustomersMultiplierModel: NewCustomersMutiplierModel;

	private someCharacterQuickPhraseShowing: boolean;
	private otherViewZoomed: boolean;

	private closeAnimationTimeout: number;
	private blackoutValue: PIXI.Container;

	private get blackout(): PIXI.Container {
		if (!this.blackoutValue) {
			this.blackoutValue = this.createBusinessBlackout();
		}
		return this.blackoutValue;
	}

	constructor(
		private readonly key: string,
		private readonly characters: CharacterMainBaseView[] = [],
	) {
		super();

		this.animationEmitters = [];

		this.characters.forEach((view) => {
			view.on(CharacterBaseView.EVENT_ANIMATION_CLICK, this.onCharacterClick, this);
			view.on(CharacterBaseView.EVENT_QUICK_PHRASE_SHOWN, this.onCharacterQuickPhraseShown, this);
			view.on(CharacterBaseView.EVENT_QUICK_PHRASE_HIDDEN, this.onCharacterQuickPhraseHidden, this);
		});

		const businessBgAtlas = AssetsStorage.getAtlas('businessBgAtlas');

		this.background = new PIXI.Sprite(businessBgAtlas[this.key]);
		this.background.scale.set(0.5);

		const businessNumber = Number(this.key.match(/\d+$/)[0]);
		this.backgroundUI = new PIXI.Sprite(businessBgAtlas[`ui_panel_b_${businessNumber}`]);
		this.backgroundUI.scale.x = 1.04;
		this.backgroundUI.y = 147;

		this.background.interactive = true;
		this.background.on('pointerdown', this.onBackgroundClick, this);

		this.furniture = new Map();
		this.furnitureContainer = new PIXI.Container();

		this.addChild(
			this.background,
			this.backgroundUI,
			this.furnitureContainer,
		);
	}

	public getKey(): string {
		return this.key;
	}

	public getCharacter(key: string): PIXI.Container {
		return this.characters.filter(character => key === character.getKey())[0];
	}

	public init(
		model: BusinessModel,
		cheatModel: CheatModel,
		newCustomersMultiplierModel: NewCustomersMutiplierModel,
		skillModels: Map<string, SkillModel>,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		upgrades: UpgradeModel[],
		characters: CharacterModel[],
		characterSkillPairs: CharacterSkillPairType[],
		buttonMaxCustomers: ButtonMaxCustomersView,
		viewportUI: ViewportUIView,
		viewport: ViewportView,
		quickPhraseEmitter: CharacterQuickPhraseEmitter,
		currentLevel?: number,
	): void {
		this.model = model;
		this.softMoneyModel = softMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.cheatModel = cheatModel;
		this.skillModels = skillModels;
		this.upgrades = upgrades;
		this.charactersModel = characters;
		this.characterSkillPairs = characterSkillPairs;
		this.buttonMaxCustomers = buttonMaxCustomers;
		this.viewportUI = viewportUI;
		this.viewport = viewport;
		this.newCustomersMultiplierModel = newCustomersMultiplierModel;
		this.otherViewZoomed = false;
		this.someCharacterQuickPhraseShowing = false;
		this.currentLevel = currentLevel;

		this.initBusinessBoundsViewport();

		const characterModelKeyMap = new Map();
		characterSkillPairs.forEach(pair => characterModelKeyMap.set(pair.character.getKey(), pair.character));
		this.characters.forEach((character) => {
			const characterModel: CharacterModel = characterModelKeyMap.get(character.getKey());
			character.init(viewportUI, quickPhraseEmitter, characterModel.getSoundPackName());
		});

		if (model.isAcquired()) {
			this.setUIView(BusinessUIViewTypes.ACQUIRED);
		} else if (!this.model.isAvailable()) {
			this.setUIView(BusinessUIViewTypes.NOT_AVAILABLE);
		} else {
			this.setUIView(BusinessUIViewTypes.OPEN_TO_BUY);
		}
	}

	private setUIView(type: BusinessUIViewTypes): void {
		if (this.currentUIView) {
			this.currentUIView.destroy({ children: true });
			this.currentUIView = null;
		}

		switch (type) {
			case BusinessUIViewTypes.NOT_AVAILABLE: {
				const notAvailableState = new BusinessNotAvailableUIView(
					this.model,
				);
				this.currentUIView = notAvailableState;
				break;
			}
			case BusinessUIViewTypes.OPEN_TO_BUY: {
				const openToBuyState = new BusinessOpenToBuyUIView(
					this.model,
					this.softMoneyModel,
					this.viewportUI,
					this.viewport,
				);
				openToBuyState.on(BusinessOpenToBuyUIView.EVENT_OPEN_ANIMATION_COMPLETED, this.onBusinessOpenAnimationCompleted, this);
				this.currentUIView = openToBuyState;
				break;
			}
			case BusinessUIViewTypes.ACQUIRED: {
				const acquiredState = new BusinessAcquiredUIView(
					this.cheatModel,
					this.model,
					this.newCustomersMultiplierModel,
					this.skillModels,
					this.upgrades,
					this.charactersModel,
					this.characterSkillPairs,
					this.softMoneyModel,
					this.prestigeMoneyModel,
					this.buttonMaxCustomers,
					this.viewportUI,
					this.viewport,
					this.localBounds,
					this.currentLevel,
				);
				acquiredState.on(BusinessAcquiredUIView.EVENT_ZOOM_IN_REQUESTED, this.requestZoomIn, this);
				acquiredState.on(BusinessAcquiredUIView.EVENT_ZOOM_OUT_REQUESTED, this.requestZoomOut, this);
				this.currentUIView = acquiredState;

				this.characterSkillPairs.forEach((pair) => {
					const { character } = pair;
					const characterView = this.characters.find(c => character.getKey() === c.getKey());
					if (character.isActivated()) {
						characterView.show();
					} else {
						character.once(CharacterModel.EVENT_ACTIVATED, this.onSomeCharacterActivated, this);
					}
				});

				this.upgrades.forEach((upgrade: UpgradeModel) => {
					if (upgrade.getRelatedBusinessKey() === this.model.getKey() && upgrade.isFreeActivation()) {
						if (upgrade.isActivated()) {
							this.addFurniture(upgrade);
						} else {
							upgrade.once(UpgradeModel.EVENT_ACTIVATED, this.onFurnitureActivated, this);
						}
					}
				});

				break;
			}
			case BusinessUIViewTypes.CLOSED: {
				const closeState = new BusinessCloseUIView(
					this.model,
				);
				this.currentUIView = closeState;
				break;
			}
			default: {
				throw new Error(`Unsupported business ui type: ${type}`);
			}
		}

		this.currentUIViewType = type;
		this.addChild(this.currentUIView);
	}

	private onSomeCharacterActivated(target: CharacterModel): void {
		const view = this.characters.find(character => character.getKey() === target.getKey());
		view.activate();
	}

	private onBusinessOpenAnimationCompleted(): void {
		this.setUIView(BusinessUIViewTypes.ACQUIRED);
		this.emit(BusinessBaseView.EVENT_NEW_UI_VIEW, this.currentUIView);
	}

	private initBusinessBoundsViewport(): void {
		this.localBounds = new PIXI.Rectangle(
			-this.background.width * 0.5,
			-this.background.height * 0.5,
			this.background.width,
			this.background.height,
		);

		let topLeft = new PIXI.Point(-this.background.width / 2, -this.background.height / 2 + 8);
		let topRight = new PIXI.Point(this.background.width / 2, -this.background.height / 2 + 8);
		let bottomLeft = new PIXI.Point(-this.background.width / 2, this.background.height / 2);
		let bottomRight = new PIXI.Point(this.background.width / 2, this.background.height / 2);

		if (this.backgroundUI) {
			bottomLeft.y += this.backgroundUI.height;
			bottomRight.y += this.backgroundUI.height;
		}

		topLeft = this.viewport.toLocal(topLeft, this);
		topRight = this.viewport.toLocal(topRight, this);
		bottomLeft = this.viewport.toLocal(bottomLeft, this);
		bottomRight = this.viewport.toLocal(bottomRight, this);

		this.boundsViewport = {
			topLeft,
			topRight,
			bottomLeft,
			bottomRight,
		};
	}

	private createBusinessBlackout(): PIXI.Container {
		const levelBottomLeft = new PIXI.Point(0, this.viewport.height);
		const levelBottomRight = new PIXI.Point(this.viewport.width, 0);
		const levelTopLeft = new PIXI.Point(0, 0);

		const levelWidth = levelBottomRight.x - levelBottomLeft.x;
		const levelHeight = levelBottomLeft.y - levelTopLeft.y;

		const viewportBounds = this.viewport.getLocalBounds();

		// Bottom
		const bottom: PIXI.Graphics = new PIXI.Graphics();
		bottom.beginFill(1);
		bottom.drawRect(levelBottomLeft.x, this.boundsViewport.bottomLeft.y, levelWidth, this.viewport.height);
		bottom.endFill();

		// Left
		const left: PIXI.Graphics = new PIXI.Graphics();
		left.beginFill(1);
		left.drawRect(
			levelTopLeft.x,
			levelTopLeft.y + viewportBounds.y,
			this.boundsViewport.bottomLeft.x,
			this.boundsViewport.bottomLeft.y - viewportBounds.y,
		);
		left.endFill();

		// Top
		const top: PIXI.Graphics = new PIXI.Graphics();
		top.beginFill(1);
		top.drawRect(
			levelTopLeft.x + this.boundsViewport.bottomLeft.x,
			levelTopLeft.y + viewportBounds.y,
			levelWidth,
			this.boundsViewport.topLeft.y - viewportBounds.y,
		);
		top.endFill();

		// Right
		const right: PIXI.Graphics = new PIXI.Graphics();
		right.beginFill(1);
		right.drawRect(
			this.boundsViewport.topRight.x,
			this.boundsViewport.topRight.y,
			levelWidth,
			levelHeight - this.boundsViewport.topRight.y - (levelHeight - this.boundsViewport.bottomRight.y),
		);
		right.endFill();

		const blackout = new PIXI.Container();
		blackout.on('pointertap', this.requestZoomOut, this);
		blackout.interactive = true;
		blackout.addChild(
			left,
			top,
			right,
			bottom,
		);
		return blackout;
	}

	public requestZoomIn(): void {
		this.emit(BusinessBaseView.EVENT_ZOOM_IN_REQUESTED, this);
	}

	private requestZoomOut(): void {
		this.emit(BusinessBaseView.EVENT_ZOOM_OUT_REQUESTED, this);
	}

	public onThisViewZoomInStarted(): void {
		this.startBlackoutAppearingAnimation();

		(this.currentUIView as BusinessAcquiredUIView).onThisViewZoomedIn();

		this.emit(BusinessBaseView.EVENT_ZOOM_IN_STARTED, this);
	}

	public onThisViewZoomInCompleted(): void {
		this.characters.forEach(character => character.onZoomedIn());

		this.emit(BusinessBaseView.EVENT_ZOOM_IN_COMPLETED, this);
	}

	public onThisViewZoomOutStarted(): void {
		this.startBlackoutHidingAnimation();

		(this.currentUIView as BusinessAcquiredUIView).onThisViewZoomedOut();

		this.emit(BusinessBaseView.EVENT_ZOOM_OUT_STARTED, this);
	}

	public onThisViewZoomOutCompleted(): void {
		this.emit(BusinessBaseView.EVENT_ZOOM_OUT_COMPLETED, this);
	}

	public onOtherViewZoomedIn(): void {
		this.otherViewZoomed = true;
		this.characters.forEach(character => character.setQuickPhraseEnabled(false, true));
		(this.currentUIView as BusinessUIWithOtherZoomViewType).onOtherViewZoomedIn();
	}

	public onOtherViewZoomedOut(): void {
		this.otherViewZoomed = false;
		this.trySetQuickPhrasesEnabled();
		(this.currentUIView as BusinessUIWithOtherZoomViewType).onOtherViewZoomedOut();
	}

	private onCharacterQuickPhraseShown(): void {
		this.someCharacterQuickPhraseShowing = true;
		this.characters.forEach(character => character.setQuickPhraseEnabled(false));
	}

	private onCharacterQuickPhraseHidden(): void {
		this.someCharacterQuickPhraseShowing = false;
		this.trySetQuickPhrasesEnabled();
	}

	private trySetQuickPhrasesEnabled(): void {
		if (!this.otherViewZoomed && !this.someCharacterQuickPhraseShowing) {
			this.characters.forEach(character => character.setQuickPhraseEnabled(true));
		}
	}

	private startBlackoutAppearingAnimation(): void {
		this.destroyBlackoutAnimations();

		this.blackout.alpha = 0;
		this.tweenBlackoutAppear = new TWEEN.Tween(this.blackout)
			.to({ alpha: 0.8 }, 200)
			.onComplete(() => {
				this.tweenBlackoutAppear = null;
			})
			.start();
		this.viewport.setBlackout(this.blackout);
	}

	private destroyBlackoutAnimations(): void {
		if (this.tweenBlackoutHide) {
			this.tweenBlackoutHide.stop();
			this.tweenBlackoutHide = null;
		}
		if (this.tweenBlackoutAppear) {
			this.tweenBlackoutAppear.stop();
			this.tweenBlackoutAppear = null;
		}
	}

	private startBlackoutHidingAnimation(): void {
		this.destroyBlackoutAnimations();

		this.tweenBlackoutHide = new TWEEN.Tween(this.blackout)
			.to({ alpha: 0 }, 400)
			.onComplete(() => {
				this.viewport.setBlackout(null);
				this.tweenBlackoutHide = null;
			})
			.start();
	}

	public getCharacters(): CharacterBaseView[] {
		return this.characters;
	}

	public getCurrentUIView(): BusinessUIViewType {
		return this.currentUIView;
	}

	public getCurrentUIViewType(): BusinessUIViewTypes {
		return this.currentUIViewType;
	}

	private onCharacterClick(event: PIXI.interaction.InteractionEvent): void {
		if (this.currentUIView instanceof BusinessAcquiredUIView) {
			this.currentUIView.onCharacterClick(this.toLocal(event.data.global));
		}
	}

	private onBackgroundClick(event: PIXI.interaction.InteractionEvent): void {
		if (this.currentUIView instanceof BusinessAcquiredUIView) {
			this.currentUIView.onBackgroundClick(this.toLocal(event.data.global));
		}
	}

	public startCloseAnimation(duration: number, delay: number): void {
		if (this.currentUIView instanceof BusinessAcquiredUIView) {
			this.closeAnimationTimeout = window.setTimeout(() => {
				this.setUIView(BusinessUIViewTypes.CLOSED);
				(this.currentUIView as BusinessCloseUIView).startShowAnimation(duration);

				this.hideCharacters();
				this.closeAnimationTimeout = null;
			}, delay);
		}
	}

	private hideCharacters(): void {
		const characterModelKeyMap = new Map();
		this.characterSkillPairs.forEach(pair => characterModelKeyMap.set(pair.character.getKey(), pair.character));

		const activatedCharacters = this.characters.filter((character) => {
			const characterModel: CharacterModel = characterModelKeyMap.get(character.getKey());
			return characterModel.isActivated();
		});

		activatedCharacters.forEach((character) => {
			if (!character.parent) return;

			const characterClosingEmitter = new Emitter(
				this,
				[AssetsStorage.getAtlas('fxAtlas')['business_glow4']],
				BusinessAnimationsConfig.getCharacterClosing(),
			);
			this.animationEmitters.push(characterClosingEmitter);
			characterClosingEmitter.spawnPos = character.getAnimationPosition();
			characterClosingEmitter.playOnceAndDestroy();
		});
	}

	public hideUI(duration: number): void {
		if (this.currentUIView instanceof BusinessAcquiredUIView
			|| this.currentUIView instanceof BusinessOpenToBuyUIView
			|| this.currentUIView instanceof BusinessNotAvailableUIView
		) {
			this.currentUIView.startMainUIHidingAnimation(duration);
		}
	}

	public showUI(duration: number): void {
		if (this.currentUIView instanceof BusinessAcquiredUIView
			|| this.currentUIView instanceof BusinessOpenToBuyUIView
			|| this.currentUIView instanceof BusinessNotAvailableUIView
		) {
			this.currentUIView.startMainUIAppearingAnimation(duration);
		}
	}

	private onFurnitureActivated(upgrade: UpgradeModel): void {
		const furniture = this.addFurniture(upgrade);

		if (furniture != null) {
			this.showAddFurnitureAnimation(furniture);
		}
	}

	private addFurniture(upgrade: UpgradeModel): PIXI.Sprite | undefined {
		const furniture = this.furniture.get(upgrade.getKey());

		if (furniture != null) {
			this.furnitureContainer.addChild(furniture);
		}

		return furniture;
	}

	private showAddFurnitureAnimation(target: PIXI.Sprite): void {
		target.alpha = 0;

		new TWEEN.Tween(target).to({ alpha: 1 }, 400).start();

		const targetBlended = new PIXI.Sprite(target.texture);
		targetBlended.transform = target.transform;
		targetBlended.blendMode = PIXI.BLEND_MODES.ADD;
		targetBlended.alpha = 0;

		this.addChildAt(targetBlended, this.furnitureContainer.getChildIndex(target) + 1);

		new TWEEN.Tween(targetBlended)
			.to({ alpha: 0.85 }, 1000)
			.repeat(1)
			.yoyo(true)
			.onComplete(() => this.furnitureContainer.removeChild(targetBlended))
			.start();

		SoundController.getInstance().playDecorationShow();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		if (this.closeAnimationTimeout) {
			window.clearTimeout(this.closeAnimationTimeout);
		}

		if (this.currentUIView instanceof BusinessOpenToBuyUIView) {
			this.currentUIView.off(BusinessOpenToBuyUIView.EVENT_OPEN_ANIMATION_COMPLETED, this.onBusinessOpenAnimationCompleted, this);
		}

		this.characters.forEach((view) => {
			view.off(CharacterBaseView.EVENT_ANIMATION_CLICK, this.onCharacterClick, this);
			view.off(CharacterBaseView.EVENT_QUICK_PHRASE_SHOWN, this.onCharacterQuickPhraseShown, this);
			view.off(CharacterBaseView.EVENT_QUICK_PHRASE_HIDDEN, this.onCharacterQuickPhraseHidden, this);
		});

		this.characterSkillPairs.forEach((pair) => {
			pair.character.off(CharacterModel.EVENT_ACTIVATED, this.onSomeCharacterActivated, this, true);
		});

		this.upgrades.forEach((upgrade: UpgradeModel) => {
			upgrade.off(UpgradeModel.EVENT_ACTIVATED, this.onFurnitureActivated, this, true);
		});

		super.destroy(options);
	}
}
