import { TabSlotView } from './BankTabViewFactory';

export class BankTabEightSlotsView extends PIXI.Container {
	private static readonly OFFSET_VERTICAL: number = 450;
	private static readonly OFFSET_HORIZONTAL: number = 350;
	private static readonly ITEMS_IN_ROW: number = 4;

	public init(slots: TabSlotView[]): void {
		if (this.children.length > 0) {
			this.removeChildren();
		}

		slots.forEach(slot => {
			const layoutElement = new PIXI.Container();
			const row: number = Math.floor(slot.index / BankTabEightSlotsView.ITEMS_IN_ROW);
			const column: number = slot.index % BankTabEightSlotsView.ITEMS_IN_ROW;

			layoutElement.position.set(BankTabEightSlotsView.OFFSET_HORIZONTAL * column, BankTabEightSlotsView.OFFSET_VERTICAL * row);
			layoutElement.addChild(slot.view);
			this.addChild(layoutElement);
		});
	}
}
