import AbstractMoneyController from '@models/money/AbstractMoneyModel';
import { MoneyType } from '@src/types/MoneyType';
import * as TWEEN from '@tweenjs/tween.js';

export class MoneyPanelBaseView<T extends MoneyType> extends PIXI.Container {
	protected textMoney: PIXI.extras.BitmapText;
	protected iconMoney: PIXI.Sprite | PIXI.Container;

	private pulseTextMoneyTween: TWEEN.Tween;
	private pulseIconMoneyTween: TWEEN.Tween;

	private canUpdate: boolean;

	private moneyController: AbstractMoneyController;

	constructor(
		textMoney: PIXI.extras.BitmapText,
		iconMoney: PIXI.Sprite | PIXI.Container,
		moneyController: AbstractMoneyController,
	) {
		super();

		this.iconMoney = iconMoney;
		this.textMoney = textMoney;
		this.canUpdate = true;

		this.moneyController = moneyController;
		this.moneyController.on(AbstractMoneyController.EVENT_VALUE_CHANGED, this.onMoneyValueChanged, this);

		this.setValue(this.moneyController.getValue().toString());

		this.addChild(
			iconMoney,
			this.textMoney as PIXI.DisplayObject,
		);
	}

	public init(): void {
		this.setValue(this.moneyController.getValue().toString());
	}

	public pulse(): void {
		if (!this.pulseTextMoneyTween) {
			this.pulseTextMoneyTween = new TWEEN.Tween(this.textMoney.scale)
				.to({ x: 1.15, y: 1.15 }, 100)
				.repeat(1)
				.yoyo(true)
				.onComplete(() => { this.pulseTextMoneyTween = null; })
				.start();
		}

		if (!this.pulseIconMoneyTween) {
			this.pulseIconMoneyTween = new TWEEN.Tween(this.iconMoney.scale)
				.to({ x: this.iconMoney.scale.x + 0.15, y: this.iconMoney.scale.y + 0.15 }, 100)
				.repeat(1)
				.yoyo(true)
				.onComplete(() => { this.pulseIconMoneyTween = null; })
				.start();
		}
	}

	public getIconMoneyPosition(): PIXI.Point {
		return this.iconMoney.position;
	}

	public setCanUpdate(value: boolean): void {
		this.canUpdate = value;
		this.moneyController.off(AbstractMoneyController.EVENT_VALUE_CHANGED, this.onMoneyValueChanged, this);

		if (this.canUpdate) {
			this.moneyController.on(AbstractMoneyController.EVENT_VALUE_CHANGED, this.onMoneyValueChanged, this);
			this.setValue(this.moneyController.getValue().toString());
		}
	}

	public setValue(value: string): void {
		this.textMoney.text = value;
	}

	private onMoneyValueChanged(value: T): void {
		this.setValue(value.toString());
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.moneyController.off(AbstractMoneyController.EVENT_VALUE_CHANGED, this.onMoneyValueChanged, this);
		super.destroy(options);
	}
}
