import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { CardRarities, CardRarity } from '@src/types/CardRarities';
import { SizeableBitmapText } from './text/SizeableBitmapText';
import { SizeableMultiColoredBitmapText } from './text/SizeableMultiColoredBitmapText';
import { FlyBitmapText } from './text/FlyBitmapText';
import { ButtonBaseView } from './buttons/ButtonBaseView';
import { ButtonWithCostValueView } from './buttons/ButtonWithCostValueView';
import { ButtonValueTypes } from './buttons/ButtonWithOldCostValueView';
import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableModel } from '@models/PromotableModel';
import { SkillModel } from '@models/skills/SkillModel';
import { BONUS_TYPE_ICON } from '@src/types/BonusTypes';
import { UnlockTypes } from '@src/types/UnlockTypes';
import { NumberUtils } from '@src/utils/NumberUtils';

export abstract class SlotView<T extends PromotableModel> extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK = Symbol();
	public static readonly LOCK_B = Symbol();

	protected lockString: SizeableMultiColoredBitmapText;
	protected clickArea: PIXI.Graphics;
	protected glow: PIXI.mesh.NineSlicePlane;
	protected background: PIXI.mesh.NineSlicePlane;
	protected bgUpgrade: PIXI.Sprite;
	protected icon: PIXI.Sprite;
	protected unknownItem: PIXI.Sprite;
	protected contentMask: PIXI.Graphics;
	protected button: ButtonWithCostValueView;
	protected cardContainer: ButtonBaseView;
	protected cardInfoContainer: PIXI.Container;
	protected localizationStorage: LocalizationStorage;
	protected cardInfoText: SizeableMultiColoredBitmapText;

	private fadeBg: PIXI.Graphics;
	private glowTween: TWEEN.Tween;
	private levelUpTween: TWEEN.Tween;
	private flyTextLabel: FlyBitmapText;
	private mouseOver: boolean;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;
	private promoteContainer?: PIXI.Container;

	constructor(
		protected readonly model: T,
		icon: PIXI.Texture,
		iconScale: number,
		bgUpgrade?: PIXI.Texture,
		bgUpgradeScale?: number,
	) {
		super();

		this.mouseOver = false;
		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;

		const slotBg = new PIXI.Graphics();
		slotBg.beginFill(0x000000, 0.3);
		slotBg.drawRoundedRect(0, 27, 162, 280, 7);
		slotBg.endFill();
		slotBg.pivot.set(slotBg.width / 2, slotBg.height / 2);
		slotBg.scale.set(0.36);

		const cardRarity = model.getCardRarity();
		const cardBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${cardRarity}_bg_round`], 3, 3, 3, 3);
		cardBg.width = 164;
		cardBg.height = 232;
		cardBg.pivot.set(cardBg.width / 2, cardBg.height / 2);
		cardBg.scale.set(0.36);

		this.cardContainer = new ButtonBaseView(cardBg);
		this.cardContainer.on('pointerover', this.onMouseOver, this);
		this.cardContainer.on('pointerout', this.onMouseOut, this);

		this.contentMask = new PIXI.Graphics();
		this.contentMask.beginFill(0x1295df);
		this.contentMask.drawRect(0, 0, 148, 217);
		this.contentMask.endFill();
		this.contentMask.pivot.set(this.contentMask.width / 2, this.contentMask.height / 2);
		this.contentMask.y = -0.5;
		this.contentMask.scale.set(0.36);

		this.bgUpgrade = new PIXI.Sprite(bgUpgrade);
		this.bgUpgrade.scale.set(bgUpgradeScale);
		this.bgUpgrade.mask = this.contentMask;

		this.icon = new PIXI.Sprite(icon);
		this.icon.scale.set(iconScale);
		this.icon.mask = this.contentMask;

		this.cardInfoContainer = new PIXI.Container();
		this.cardInfoContainer.mask = this.contentMask;

		this.lockString = new SizeableMultiColoredBitmapText(50, { font: { size: 8, name: 'wendyOne' }, align: 'center' });
		this.lockString.anchor = 0.5;
		this.lockString.y = 50;

		this.addChild(
			slotBg,
			this.cardContainer as PIXI.DisplayObject,
		);

		this.cardContainer.addChild(
			cardBg,
			this.contentMask,
			this.bgUpgrade,
			this.icon as PIXI.DisplayObject,
			this.cardInfoContainer,
		);

		this.ticker.add(this.update, this);
		this.localizationStorage = LocalizationStorage.getInstance();

		if (this.model.hasBonusAutomate()) {
			this.addAuto();
		}

		this.updatePromote();
		this.model.on(PromotableModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);
	}

	public updatePromote(): void {
		const isEnoughCards = this.model.isEnoughCardsForPromote();

		this.removePromote();

		if (isEnoughCards && this.model.isOpened()) {
			this.addPromote(this.model.getLevel());
		}
	}

	private onMouseOver(): void {
		if (this.mouseOver) {
			return;
		}
		this.mouseOver = true;
		new TWEEN.Tween(this.cardContainer, this.tweenGroup)
			.to({ y: -5 }, 130)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	private onMouseOut(): void {
		if (!this.mouseOver) {
			return;
		}
		this.mouseOver = false;
		new TWEEN.Tween(this.cardContainer, this.tweenGroup)
			.to({ y: 0 }, 130)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public addAuto(): SlotView<T> {
		const iconScale = 0.36;
		const iconAuto = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['business_card_auto']);
		iconAuto.setTransform(0, -40, iconScale, iconScale);

		this.cardContainer.addChildAt(iconAuto, this.cardContainer.getChildIndex(this.cardInfoContainer) + 1);

		return this;
	}

	private addCardInfo(text: string, icon: PIXI.Sprite, iconScale = 0.16, iconY = 30): void {
		const cardInfoIcon = icon;
		const fade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_black_h'], 0, 0, 0, 0);
		this.cardInfoText = new SizeableMultiColoredBitmapText(32, { font: { size: 10, name: 'wendyOne' }, align: 'center', tint: 0xffee90 });

		fade.width = 50;
		fade.height = 147;
		fade.pivot.set(fade.width / 2, fade.height / 2);
		fade.scale.set(0.36);
		fade.rotation = Math.PI / 180 * -90;
		fade.alpha = 0.6;
		fade.y = 30;

		this.cardInfoText.anchor = 0.5;
		this.cardInfoText.text = text;
		cardInfoIcon.setTransform(-this.cardInfoText.width / 2, iconY, iconScale, iconScale);
		this.cardInfoText.position.set(cardInfoIcon.width / 2 + 2, 32);

		this.cardInfoContainer.addChild(
			fade,
			cardInfoIcon,
			this.cardInfoText as PIXI.DisplayObject,
		);
	}

	public updateCardInfo(skill?: SkillModel): void {
		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');
		const bonus = this.model.getFirstBonus();

		this.cardInfoContainer.removeChildren();

		if (skill !== undefined) {
			const text = this.localizationStorage.getLocalizedString('#skill');

			this.addCardInfo(
				text,
				new PIXI.Sprite(uiAtlas[skill.getIconKey()]),
			);
		} else if (bonus !== undefined) {
			const bonusIcon = BONUS_TYPE_ICON[bonus.getType()];
			const text = NumberUtils.numberToFloatShortString(bonus.getValue());

			this.addCardInfo(
				`x${text}`,
				new PIXI.Sprite(uiAtlas[bonusIcon]),
				0.12,
				32,
			);
		}
	}

	public addFade(): SlotView<T> {
		if (!this.fadeBg) {
			this.fadeBg = new PIXI.Graphics();
			this.fadeBg.beginFill(0x000000, 0);
			this.fadeBg.drawRect(0, 0, 164, 232);
			this.fadeBg.endFill();
			this.fadeBg.pivot.set(this.fadeBg.width / 2, this.fadeBg.height / 2);
			this.fadeBg.scale.set(0.36);
		}

		this.cardContainer.addChildAt(this.fadeBg, this.cardContainer.getChildIndex(this.cardInfoContainer) + 1);

		return this;
	}

	protected addUnknownItem(icon: PIXI.Texture, iconScale: number, anchor: number): void {
		this.unknownItem = new PIXI.Sprite(icon);
		this.unknownItem.scale.set(iconScale);
		this.unknownItem.anchor.set(anchor);
		this.unknownItem.mask = this.contentMask;

		this.cardContainer.addChildAt(this.unknownItem, this.cardContainer.getChildIndex(this.cardInfoContainer) + 2);
	}

	protected abstract getDefaultLockText(): string;

	protected addLock(): void {
		this.lockString.text = this.getLockText();
		this.addChild(this.lockString);
	}

	private getLockText(): string {
		const unlockInfo = this.model.getUnlockInfo();
		let rawText = '';

		if (!this.model.isOpened()) {
			if (unlockInfo.type === UnlockTypes.EVENT) {
				rawText = this.localizationStorage.getLocalizedString('#available_in_event_lock');
			} else if (unlockInfo.type === UnlockTypes.UNAVAILABLE) {
				rawText = this.localizationStorage.getLocalizedString('#unavailable_lock');
			} else {
				rawText = this.localizationStorage.getLocalizedString('#available_in_casting_lock');
			}
		} else {
			rawText = this.getDefaultLockText();
		}

		return `[lock][808a9d]${rawText}[-]`;
	}

	protected onTranslateText(): void {
		this.lockString.text = this.getLockText();
	}

	protected addPromote(currentLevel: number): SlotView<T> {
		this.promoteContainer = new PIXI.Container();

		const iconScale = 0.12;
		const newLevelBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['new_level_icon']);
		newLevelBg.anchor.set(0.5);
		newLevelBg.position.set(24, -37);
		newLevelBg.scale.set(iconScale, iconScale);

		const newLevelBgArrow = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['new_level_icon_arrow']);
		newLevelBgArrow.anchor.set(0.5, 1);
		newLevelBgArrow.position.set(newLevelBg.x, newLevelBg.y);
		newLevelBgArrow.scale.set(iconScale, iconScale);

		const progressLevel = new SizeableBitmapText('', 10, { font: { size: 10, name: 'wendyOneShadowBold' }, align: 'center' });
		progressLevel.position.set(newLevelBg.x, newLevelBg.y);
		progressLevel.anchor = 0.5;
		progressLevel.text = currentLevel.toString();

		this.levelUpTween = new TWEEN.Tween(newLevelBgArrow.scale, this.tweenGroup)
			.to({ y: 0.14 }, 900)
			.easing(TWEEN.Easing.Cubic.Out)
			.yoyo(true)
			.repeat(Infinity)
			.start();

		this.promoteContainer.addChild(
			newLevelBg,
			newLevelBgArrow,
			progressLevel as PIXI.DisplayObject,
		);

		this.cardContainer.addChild(
			this.promoteContainer,
		);
		return this;
	}

	public addGlow(rarity: CardRarity): SlotView<T> {
		this.glow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 20, 20, 20, 20);
		this.glow.width = 182;
		this.glow.height = 250;
		this.glow.scale.set(0.36);
		this.glow.pivot.set(this.glow.width / 2, this.glow.height / 2);
		this.glow.tint = CardRarities.getRarityColor(rarity);
		this.glow.alpha = 0.9;

		this.glowTween = new TWEEN.Tween(this.glow, this.tweenGroup)
			.to({ alpha: 0.1 }, 650)
			.yoyo(true)
			.repeat(Infinity)
			.start();

		this.cardContainer.addChildAt(this.glow, 0);
		return this;
	}

	public addBuyButton(cost: string, enabled: boolean): SlotView<T> {
		if (this.button) {
			this.button.destroy();
		}

		let textureSprite: PIXI.Texture;
		if (enabled) {
			textureSprite = AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_green'];
		} else {
			textureSprite = AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_yellow'];
		}

		const buyButtonBg = new PIXI.mesh.NineSlicePlane(textureSprite, 6, 6, 6, 6);
		buyButtonBg.width = 164;
		buyButtonBg.height = 52;
		buyButtonBg.scale.set(0.36);
		buyButtonBg.pivot.set(buyButtonBg.width / 2, buyButtonBg.height / 2);

		this.button = new ButtonWithCostValueView({
			type: ButtonValueTypes.SOFT_MONEY,
			buttonBg: buyButtonBg,
			maxWidth: 164 * 0.36,
			fontStyle: { font: { size: 10, name: 'wendyOneShadowBold' } },
		});
		this.button.setCost(cost);
		this.button.setCostValueEnabled(enabled);
		this.button.y = 51;
		this.button.on(ButtonBaseView.EVENT_CLICK, this.onButtonBuyClick, this);

		this.addChild(this.button);

		return this;
	}

	public addCheck(): SlotView<T> {
		this.removeLock();
		this.removeFade();

		const iconCheck = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_check']);
		const iconScale = 0.36;
		iconCheck.setTransform(0, 51, iconScale, iconScale);
		this.addChild(iconCheck);

		iconCheck.scale.set(0);
		const scaleIn = new TWEEN.Tween(iconCheck.scale, this.tweenGroup)
			.to({ x: iconScale + 0.07, y: iconScale + 0.07 }, 100);
		const scaleOut = new TWEEN.Tween(iconCheck.scale, this.tweenGroup)
			.to({ x: iconScale, y: iconScale }, 100);
		scaleIn.chain(scaleOut);
		scaleIn.start();

		return this;
	}

	protected removeFade(): SlotView<T> {
		if (this.fadeBg && this.fadeBg.parent) {
			this.fadeBg.parent.removeChild(this.fadeBg);
		}

		return this;
	}

	protected removeUnknownItem(): void {
		if (this.unknownItem != null) {
			this.cardContainer.removeChild(this.unknownItem);
			this.unknownItem = null;
		}
	}

	public removeActionButton(): SlotView<T> {
		if (this.button) {
			this.button.destroy();
		}

		return this;
	}

	public getBuyButton(): ButtonWithCostValueView {
		return this.button;
	}

	public getLockString(): PIXI.Container {
		return this.lockString;
	}

	public removeLock(): SlotView<T> {
		if (this.lockString && this.lockString.parent) {
			this.lockString.parent.removeChild(this.lockString);
			this.removeChild(this.clickArea);
		}

		return this;
	}

	public removePromote(): void {
		if (this.promoteContainer !== undefined) {
			this.cardContainer.removeChild(this.promoteContainer);
			this.promoteContainer = undefined;
		}
	}

	public removeGlow(): SlotView<T> {
		if (this.glow) {
			this.cardContainer.removeChild(this.glow);
			this.glow = null;
		}

		if (this.glowTween) {
			this.glowTween.stop();
			this.glowTween = null;
		}

		return this;
	}

	public showNotEnoughtMoneyFlyText(): void {
		if (!this.flyTextLabel) {
			const text = this.localizationStorage.getLocalizedString('#fly_text_not_enough_money');
			this.flyTextLabel = new FlyBitmapText(text, new PIXI.Point(0.5, 0.5), { font: '32px wendyOneShadowBold', align: 'center' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = undefined;
			});
			this.flyTextLabel.y = this.button.y - 60;

			const scale = 0.25;
			this.flyTextLabel.scale = new PIXI.Point(scale, scale);

			this.button.addChild(this.flyTextLabel);
		}
	}

	public showInteractiveAreaWithFlyText(label: string): void {
		this.clickArea = new PIXI.Graphics();
		this.clickArea.beginFill(0xFFFFFF, 0);
		this.clickArea.drawRect(0, 0, 55, 20);
		this.clickArea.endFill();
		this.clickArea.pivot.set(this.clickArea.width / 2, this.clickArea.height / 2);
		this.clickArea.y = 51;
		this.clickArea.interactive = true;
		this.clickArea.on('pointerdown', () => {
			this.showLockedFlyText(label);
		});

		this.addChild(
			this.clickArea,
		);
	}

	private showLockedFlyText(label: string): void {
		if (!this.flyTextLabel) {
			const text = this.localizationStorage.getLocalizedString(label);
			this.flyTextLabel = new FlyBitmapText(text, new PIXI.Point(0.5, 0.5), { font: '32px wendyOneShadowBold', align: 'center' }, 250);
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = undefined;
			});
			this.flyTextLabel.y = 41;

			const scale = 0.25;
			this.flyTextLabel.scale = new PIXI.Point(scale, scale);

			this.addChild(
				this.flyTextLabel,
			);
		}
	}

	private onButtonBuyClick(): void {
		this.emit(SlotView.EVENT_BUTTON_BUY_CLICK);
	}

	private onCardsAdded(): void {
		this.updatePromote();
	}

	public destroy(): void {
		if (this.glowTween) {
			this.glowTween.stop();
			this.glowTween = null;
		}

		if (this.levelUpTween) {
			this.levelUpTween.stop();
			this.levelUpTween = null;
		}

		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		this.model.off(PromotableModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		super.destroy();
	}
}
