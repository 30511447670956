import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { TextField } from '@views/components/text/TextField';
import { RegistrationWindowCheckboxView } from './RegistrationWindowCheckboxView';
import { GameProfileModel } from '@models/GameProfileModel';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { EmailAreaInputView } from '@views/components/input/EmailAreaInputView';
import { PasswordInputView } from '@views/components/input/PasswordInputView';
import { EmailConfirmationWindowView } from './EmailConfirmationWindowView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class RegistrationWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_REGISTER_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly emailTextArea: EmailAreaInputView;
	private readonly passwordTextArea: PasswordInputView;
	private readonly gameProfileModel: GameProfileModel;

	private buttonRegister: ButtonBaseView;

	private checkbox: RegistrationWindowCheckboxView;

	private emailConfirmationWindow: EmailConfirmationWindowView;

	constructor(
		gameProfileModel: GameProfileModel,
	) {
		super(0.9);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_REGISTER_EMAIL_ALREADY_EXISTS, this.onEmailAlreadyExists, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_REGISTER, this.onRegister, this);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 773;
		bg.height = 669;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const stringTitle = this.localizationStorage.getLocalizedString('#registration_window_title_label');
		const labelTitle = new PIXI.extras.BitmapText(
			stringTitle,
			{ font: '40px wendyOneShadowBold' },
		);
		labelTitle.anchor = 0.5;
		labelTitle.y = -280;

		const closeButton = new CloseButtonView();
		closeButton.position.set(336, -280);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 715;
		whiteBg.height = 315;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -75;

		const greyBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		greyBg.tint = 0x000000;
		greyBg.alpha = 0.12;
		greyBg.width = 704;
		greyBg.height = 104;
		greyBg.y = 25;

		const buttonRegister = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 488, 95),
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
			466,
		);
		buttonRegister.setTextLabel(this.localizationStorage.getLocalizedString('#registration_window_button_submit_label'));
		buttonRegister.on(ButtonBaseView.EVENT_CLICK, this.onButtonRegisterClick, this);
		buttonRegister.y = 170;
		this.buttonRegister = buttonRegister;

		const stringFooter = this.localizationStorage.getLocalizedString('#registration_window_footer_label');
		const textFooter = new TextField(stringFooter, { font: '22px wendyOneShadowBold', tint: 0xd6e4ec, align: 'center' }, 670, 75);
		textFooter.anchor = 0.5;
		textFooter.y = 275;

		const stringAgreement = this.localizationStorage.getLocalizedString('#registration_window_agreement_label');
		const textAgreement = new TextField(stringAgreement, { font: '22px wendyOne', tint: 0x6a7075 }, 550, 83);
		textAgreement.anchor = 0.5;
		textAgreement.position.set(40, 25);

		this.emailTextArea = new EmailAreaInputView({
			bgWidth: 620,
		});
		this.emailTextArea.y = -165;
		this.emailTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_email_label'));

		this.passwordTextArea = new PasswordInputView({
			bgWidth: 620,
			maxLength: GameProfileModel.INPUT_PASSWORD_MAX_LENGTH,
		});
		this.passwordTextArea.y = -90;
		this.passwordTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#registration_window_your_password_label'));

		this.checkbox = new RegistrationWindowCheckboxView();
		this.checkbox.position.set(-285, 25);

		this.mainContainer.addChild(
			bg,
			labelTitle as PIXI.DisplayObject,
			closeButton,
			whiteBg,
			buttonRegister,
			greyBg,
			textFooter,
			textAgreement,
			this.emailTextArea,
			this.passwordTextArea,
			this.checkbox,
		);
	}

	private onButtonRegisterClick(): void {
		const inputEmailOk = this.emailTextArea.isValid();
		const inputPasswordOk = this.passwordTextArea.isValid();

		if (!inputEmailOk) {
			this.emailTextArea.onInvalidInput();
		}

		if (!inputPasswordOk) {
			this.passwordTextArea.onInvalidInput();
		}

		if (inputEmailOk && inputPasswordOk) {
			this.emit(
				RegistrationWindowView.EVENT_BUTTON_REGISTER_CLICK,
				this.emailTextArea.getText(),
				this.passwordTextArea.getText(),
				this.checkbox.isChecked(),
			);
		}
	}

	private setRegisterButtonEnabled(value: boolean): void {
		this.buttonRegister.interactive = value;
		this.buttonRegister.alpha = value ? 1 : 0.5;
	}

	private onRegister(): void {
		this.startCloseAnimation();

		this.emailConfirmationWindow = new EmailConfirmationWindowView(
			this.gameProfileModel,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_BUTTON_CONFIRM_CLICK,
			(value: string) => this.emit(RegistrationWindowView.EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK, value),
			this,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_BUTTON_RESEND_CLICK,
			() => this.emit(RegistrationWindowView.EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK),
			this,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_WINDOW_CLOSED,
			() => this.onEmailConfirmationWindowClosed(),
			this,
		);
		this.emailConfirmationWindow.onShown();

		this.addChild(this.emailConfirmationWindow);
	}

	private onEmailConfirmationWindowClosed(): void {
		if (this.gameProfileModel.isLoggedIn()) {
			this.onClose(false);
		} else {
			this.setRegisterButtonEnabled(true);
			this.startOpenAnimation();
		}
	}

	private onEmailAlreadyExists(): void {
		this.emailTextArea.setTextInvalidInput(this.localizationStorage.getLocalizedString('#error_email_already_exists'));
		this.emailTextArea.onInvalidInput();

		this.setRegisterButtonEnabled(true);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_REGISTER_EMAIL_ALREADY_EXISTS, this.onEmailAlreadyExists, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_REGISTER, this.onRegister, this);
		super.destroy(options);
	}
}
