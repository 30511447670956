import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30016View } from '@views/characters/events/event3/EventCharacter30016View';
import { EventCharacter30017View } from '@views/characters/events/event3/EventCharacter30017View';
import { EventCharacter30018View } from '@views/characters/events/event3/EventCharacter30018View';

export class EventBusiness30006View extends BusinessBaseView {
	constructor() {
		const eventCharacter30016View = new EventCharacter30016View();
		const eventCharacter30017View = new EventCharacter30017View();
		const eventCharacter30018View = new EventCharacter30018View();

		super('business30006', [eventCharacter30016View, eventCharacter30017View, eventCharacter30018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter30016View as PIXI.DisplayObject,
			eventCharacter30017View,
			eventCharacter30018View,

		);
	}
}
