export class LevelChallengeUIHelper {
	public static getTimeString(seconds: number): string {
		/* eslint-disable no-mixed-operators */
		const d: number = Math.floor(seconds / 86400);
		const h: number = Math.floor(seconds % 86400 / 3600);
		const m: number = Math.floor(seconds % 86400 % 3600 / 60);
		const s: number = seconds > 1
			? Math.floor(seconds % 86400 % 3600 % 60)
			: 0;
		/* eslint-enable no-mixed-operators */

		const daysFormatted: string = d < 10
			? `0${d}`
			: d.toString();
		const hoursFormatted: string = h < 10
			? `0${h}`
			: h.toString();
		const minutesFormatted: string = m < 10
			? `0${m}`
			: m.toString();
		const secondsFormatted: string = s < 10
			? `0${s}`
			: s.toString();
		// eslint-disable-next-line no-nested-ternary

		if (seconds > 86400) {
			return `${daysFormatted}:${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
		}
		if (seconds > 3600) {
			return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
		}
		if (seconds > 60) {
			return `${minutesFormatted}:${secondsFormatted}`;
		}
		return `00:${secondsFormatted}`;
	}
}
