import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableModel } from '@models/PromotableModel';
import { UnlockTypes } from '@src/types/UnlockTypes';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class BaseCardLockView extends PIXI.Container {
	protected readonly localizationStorage: LocalizationStorage;
	protected readonly lockText: SizeableBitmapText;

	constructor(
		private readonly model: PromotableModel,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const str = this.getUnlockText();
		this.lockText = new SizeableBitmapText(str, 220, { font: '26px wendyOneShadowBold', align: 'center', tint: 0x9aaec0 });
		this.lockText.anchor = 0.5;
	}

	private getUnlockText(): string {
		const unlockInfo = this.model.getUnlockInfo();
		let text: string;

		if (unlockInfo.type === UnlockTypes.EVENT) {
			text = `[lock]${this.localizationStorage.getLocalizedString('#collection_available_in_event_lock')}`;
		} else if (unlockInfo.type === UnlockTypes.UNAVAILABLE) {
			text = `[lock]${this.localizationStorage.getLocalizedString('#unavailable_lock')}`;
		} else {
			text = `[lock]${this.localizationStorage.getLocalizedString('#collection_available_in_casting_lock')}`;
		}

		return text;
	}

	private updateText(): void {
		this.lockText.text = this.getUnlockText();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		super.destroy(options);
	}
}
