import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30010View } from '@views/characters/events/event3/EventCharacter30010View';
import { EventCharacter30011View } from '@views/characters/events/event3/EventCharacter30011View';
import { EventCharacter30012View } from '@views/characters/events/event3/EventCharacter30012View';

export class EventBusiness30004View extends BusinessBaseView {
	constructor() {
		const eventCharacter30010View = new EventCharacter30010View();
		const eventCharacter30011View = new EventCharacter30011View();
		const eventCharacter30012View = new EventCharacter30012View();

		super('business30004', [eventCharacter30010View, eventCharacter30011View, eventCharacter30012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter30010View,
			eventCharacter30011View as PIXI.DisplayObject,
			eventCharacter30012View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
