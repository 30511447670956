import { ButtonBaseView } from './ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { MultiColoredTextField } from '../text/MultiColoredTextField';

export enum ButtonValueTypes {
	SOFT_MONEY = '[money]',
	SOFT_MONEY_GRAY = '[money_gray]',
	PRESTIGE_MONEY = '[prestige]',
	HARD_MONEY = '[hardmoney]',
	NUTAKU = '[nutaku]',
	DOLLAR = '$',
}

export type ButtonWithOldCostValueSettings = {
	title?: string;
	price: string;
	oldPrice?: string;
	buttonPriceResource: ButtonValueTypes;
	buttonOldPriceResource?: ButtonValueTypes;
	titleStyle?: PIXI.extras.BitmapTextStyle;
	priceStyle: PIXI.extras.BitmapTextStyle;
	oldPriceStyle?: PIXI.extras.BitmapTextStyle;
	maxWidth?: number;
	maxHeight?: number;
	minWidth?: number;
	minHeight?: number;
}

export class ButtonWithOldCostValue extends ButtonBaseView {
	private static readonly PADDING: number = 10;
	private readonly textLabel: PIXI.extras.BitmapText;
	private readonly currentPriceLabel: PIXI.Container;
	private readonly oldPriceLabel: PIXI.Container;
	private readonly localizationStorage: LocalizationStorage;
	private priceContainer: PIXI.Container;
	private calculatedWidth: number;
	private calculatedHeight: number;
	private textLabelSize: number;
	private maxWidth: number;
	private minWidth: number;

	constructor(config: ButtonWithOldCostValueSettings) {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super(buttonBg);

		this.priceContainer = new PIXI.Container();
		this.localizationStorage = LocalizationStorage.getInstance();
		this.calculatedWidth = 0;
		this.calculatedHeight = 0;

		this.maxWidth = Number.MAX_SAFE_INTEGER;
		this.minWidth = 0;

		if (config.maxWidth) {
			this.maxWidth = config.maxWidth;
		}

		if (config.minWidth) {
			this.minWidth = config.minWidth;
		}

		if (config.title) {
			this.textLabel = this.createTitleLabel(config);
			this.addChild(this.textLabel);
			this.calculatedHeight += Math.round(this.textLabelSize * 1.5);
		}

		this.currentPriceLabel = this.createCurrentPriceLabel(config);
		this.priceContainer.addChild(this.currentPriceLabel);

		if (config.oldPrice) {
			this.oldPriceLabel = this.createOldPriceLabel(config);
			this.calculatedWidth += this.oldPriceLabel.width + 16;
			this.priceContainer.addChild(this.oldPriceLabel);
			this.calculatedHeight += Math.max(this.currentPriceLabel.height, this.oldPriceLabel.height);
		} else {
			this.calculatedHeight += this.currentPriceLabel.height;
		}
		this.calculatedWidth += this.currentPriceLabel.width;
		this.addChild(this.priceContainer);
		this.layout();

		this.name = 'oldcost';
	}

	private layout(): void {
		this.buttonBg.width = Math.min(Math.max(this.calculatedWidth + ButtonWithOldCostValue.PADDING * 2, this.minWidth), this.maxWidth);
		this.buttonBg.height = this.calculatedHeight + ButtonWithOldCostValue.PADDING;
		this.buttonBg.pivot.set(this.buttonBg.width / 2, this.buttonBg.height / 2);

		if (this.textLabel) {
			this.textLabel.y = -this.textLabelSize * 1.2;
			this.priceContainer.y = Math.round(this.textLabelSize * 0.5);
		}

		if (this.oldPriceLabel) {
			this.currentPriceLabel.x = this.oldPriceLabel.width + 16;
			this.oldPriceLabel.y = 5;
		}

		this.priceContainer.x = ButtonWithOldCostValue.PADDING - this.calculatedWidth / 2;
	}

	// eslint-disable-next-line class-methods-use-this
	private createCurrentPriceLabel(config: ButtonWithOldCostValueSettings): PIXI.Container {
		const newPriceLabel = new MultiColoredTextField(config.priceStyle);
		newPriceLabel.text = `${config.buttonPriceResource}${config.price}`;
		newPriceLabel.anchor = new PIXI.Point(0, 0.5);
		return newPriceLabel;
	}

	private createTitleLabel(config: ButtonWithOldCostValueSettings): PIXI.extras.BitmapText {
		const textLabel = new PIXI.extras.BitmapText(this.localizationStorage.getLocalizedString(config.title), config.titleStyle);
		textLabel.anchor = 0.5;
		this.textLabelSize = (config.titleStyle.font as any).size;
		return textLabel;
	}

	// eslint-disable-next-line class-methods-use-this
	private createOldPriceLabel(config: ButtonWithOldCostValueSettings): PIXI.Container {
		const oldPriceLabel = new MultiColoredTextField(config.oldPriceStyle);
		oldPriceLabel.text = `${config.buttonOldPriceResource}${config.oldPrice}`;
		const scratchIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['scratch_icon']);
		scratchIcon.width = oldPriceLabel.width + 4;
		oldPriceLabel.addChild(scratchIcon);
		scratchIcon.x = -2;
		scratchIcon.anchor.set(0, 0.5);
		oldPriceLabel.anchor = new PIXI.Point(0, 0.5);
		return oldPriceLabel;
	}
}
