import { BusinessModel } from '@models/BusinessModel';
import { QuestTypes } from '@src/types/QuestTypes';
import { AbstractQuest } from './AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';

export class QuestAutomateBusinesses extends AbstractQuest {
	private readonly businesses: BusinessModel[];
	private readonly targetAmount: number;
	private currentAmount: number;

	constructor(
		questKey: string,
		targets: BusinessModel[],
		automateCount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.AUTOMATE_BUSINESSES,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.businesses = targets;

		this.currentAmount = 0;
		this.targetAmount = automateCount;

		this.trackProgressStart();
	}

	private onSomeBusinessAutomated(): void {
		this.currentAmount += 1;

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getBusinessKeyToAcquireNext(): string | undefined {
		let key: string;
		const business = this.businesses.find(model => !model.isAutomated());
		if (business) {
			key = business.getKey();
		}
		return key;
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	protected trackProgressStop(): void {
		this.businesses.forEach(target => target.off(BusinessModel.EVENT_AUTOMATED, this.onSomeBusinessAutomated, this, true));
	}

	protected trackProgressStart(): void {
		this.businesses.forEach(target => target.once(BusinessModel.EVENT_AUTOMATED, this.onSomeBusinessAutomated, this));
	}
}
