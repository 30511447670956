import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { SkillModel } from '@models/skills/SkillModel';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { SkillTypes } from '@src/types/SkillTypes';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBackgroundType, ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { CharacterModel } from '@models/CharacterModel';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { TextUtils } from '@src/utils/TextUtils';
import { TextDescriptionHelperCharacter } from '@views/windows/collection/characters/TextDescriptionHelperCharacter';
import { PromotableClickData } from '../cards/PromotableBaseCardView';

export type SkillHintViewData = {
	bodyArrowOffset: number;
	skillModel: SkillModel;
	characterModel: CharacterModel;
	currentLevel: number;
}

export class SkillHintView extends PIXI.Container {
	public static readonly EVENT_BUTTON_PROMOTE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_FIND_IN_SUMMON_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly bg: PIXI.mesh.NineSlicePlane;
	private readonly arrow: PIXI.Sprite;

	private readonly content: PIXI.Container;
	private readonly textFindInSummon: SizeableMultiColoredBitmapText;
	private skillActive: PIXI.Container;
	private buttonPromote: ButtonWithLabelBaseView;
	private buttonFindInSummon: ButtonWithLabelBaseView;
	private textActive: MultiColoredTextField;

	private model: SkillModel;
	private characterModel: CharacterModel;
	private currentLevel: number;

	constructor(
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_skill_hint'], 10, 19, 10, 11);

		this.arrow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_skill_hint_tail']);
		this.arrow.anchor.set(0.5, 1);

		this.createButtonPromote();
		this.createButtonFindInSummon();

		this.textFindInSummon = new SizeableMultiColoredBitmapText(300, { font: '20px wendyOne', tint: 0x2a445b });
		this.textFindInSummon.anchor = 0.5;
		this.textFindInSummon.position.set(80, -131);

		this.createSkillActive();

		this.content = new PIXI.Container();
		this.content.y = -7;

		this.addChild(this.content);
	}

	private createButtonFindInSummon(): void {
		this.buttonFindInSummon = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 180, 60),
			{ font: '24px wendyOneShadowBold', align: 'center' },
			201,
		);
		this.buttonFindInSummon.x = 80;
		this.buttonFindInSummon.y = -85;
		this.buttonFindInSummon.on(ButtonBaseView.EVENT_CLICK, this.onButtonFindInSummonClick, this);
	}

	private createButtonPromote(): void {
		this.buttonPromote = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 180, 60),
			{ font: '24px wendyOneShadowBold', align: 'center' },
			201,
		);
		this.buttonPromote.x = 80;
		this.buttonPromote.y = -85;
		this.buttonPromote.on(ButtonBaseView.EVENT_CLICK, this.onButtonPromoteClick, this);
	}

	public init(data: SkillHintViewData): void {
		this.model = data.skillModel;
		this.characterModel = data.characterModel;
		this.currentLevel = data.currentLevel;

		this.content.removeChildren();
		this.once('removed', this.onRemovedFromParent, this);

		const dialogAtlas = AssetsStorage.getAtlas('dialogAtlas');

		this.setBgSize(545, 247);
		this.setArrowOffset(data.bodyArrowOffset);

		const character = new PIXI.Sprite(dialogAtlas[this.model.getGirlPrefabName()]);
		character.anchor.set(1, 1);
		character.scale.set(0.6);

		const characterPrefabOffset = this.model.getGirlPrefabHintOffset();
		character.position.set(
			-53 + characterPrefabOffset.x,
			-37 + characterPrefabOffset.y,
		);

		const skillName = this.createSkillName();
		const skillLvl = this.createSkillLevel();
		const skillDescription = this.createSkillDescription();
		const descriptionBounds = skillDescription.getLocalBounds();

		this.content.addChild(
			this.bg,
			this.arrow,
			skillDescription,
			character,
			skillName as PIXI.DisplayObject,
			skillLvl,
		);

		let additionalHeight = 0;

		if (!this.model.isOpened()) {
			if (this.characterModel.isUnlockableByMinLevel() && this.characterModel.getUnlockLevel() <= this.currentLevel) {
				additionalHeight = 92;
				this.content.addChild(this.buttonFindInSummon);
				this.content.addChild(this.textFindInSummon);
			} else {
				additionalHeight = 25;
				const skillUnlock = this.createSkillUnlock();
				this.content.addChild(skillUnlock);
			}
		} else {
			additionalHeight = 63;
			this.content.addChild(this.buttonPromote);
		}

		skillName.position.set(80, -126 - descriptionBounds.height - additionalHeight);
		skillLvl.position.set(80, -98 - descriptionBounds.height - additionalHeight);
		skillDescription.position.set(33, -70 - descriptionBounds.height / 2 - additionalHeight);

		const bgHeight = 149 + descriptionBounds.height + additionalHeight;
		this.setBgSize(545, bgHeight);

		// Update skill active visibility
		if (this.model.isActive()) {
			this.skillActive.visible = true;
			this.skillActive.x = descriptionBounds.width / 2 - this.skillActive.width / 2;
			this.skillActive.y = -descriptionBounds.height / 2;
			skillDescription.addChild(this.skillActive);

			this.model.once(SkillModel.EVENT_SKILL_DEACTIVATED, this.onSkillDeactivated, this);
		} else {
			this.skillActive.visible = false;
		}

		this.onTranslateText();
	}

	private onRemovedFromParent(): void {
		this.model.off(SkillModel.EVENT_SKILL_DEACTIVATED, this.onSkillDeactivated, this, true);
	}

	private onSkillDeactivated(): void {
		this.skillActive.visible = false;
	}

	private onButtonPromoteClick(): void {
		const data: PromotableClickData = {
			model: this.characterModel.getKey(),
			skill: this.model.getKey(),
		};
		this.emit(SkillHintView.EVENT_BUTTON_PROMOTE_CLICK, data);
	}

	private onButtonFindInSummonClick(): void {
		this.emit(SkillHintView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK, this);
	}

	private hasConstantProfit(): boolean {
		return this.model.isOpened() && this.model.getType() === SkillTypes.CONSTANT_PROFIT;
	}

	private createSkillName(): MultiColoredTextField {
		const skillNameStr = TextDescriptionHelperCharacter.getSkillDescription(this.model).skillName;
		const skillName = new MultiColoredTextField(
			{ font: '30px wendyOneShadowBold' },
			330,
			40,
		);
		skillName.text = skillNameStr;
		skillName.anchor = 0.5;

		return skillName;
	}

	private createSkillLevel(): SizeableBitmapText {
		const skillLevelStr = this.localizationStorage.getLocalizedString('#skill_lvl')
			.replace('{{value}}', this.model.getCharacterLevel().toString());
		const skillLevel = new SizeableBitmapText(
			skillLevelStr,
			200,
			{ font: '24px wendyOneShadowBoldCaps', tint: 0xffce45 },
		);
		skillLevel.anchor = 0.5;

		return skillLevel;
	}

	private createSkillDescription(): PIXI.Container {
		const container = new PIXI.Container();

		let descriptionStr = TextDescriptionHelperCharacter.getSkillDescription(this.model).skillDesc;

		let descriptionMaxHeight = 40;

		// Add more height for long descriptions
		if (descriptionStr.length > 110) {
			descriptionMaxHeight += 20;
		}

		if (this.hasConstantProfit()) {
			const constantProfitTotal = Math.round((this.model.getMultiplier() - 1) * 100);
			let constantProfitText = this.localizationStorage.getLocalizedString('#skill_constant_profit_total');
			constantProfitText = TextUtils.replaceValueAndEnding(
				LocalizationStorage.getInstance().getLanguage(),
				'{{value}}',
				constantProfitTotal,
				constantProfitText,
			);

			descriptionStr += `\n${constantProfitText}`;
			descriptionMaxHeight += 30;
		}

		const description = new MultiColoredTextField(
			{ font: '18px wendyOneShadowBold', align: 'left' },
			282,
			descriptionMaxHeight,
		);
		description.text = descriptionStr;
		description.anchor = new PIXI.Point(0, 0.5);
		description.x = -101;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_skill_hint_descr'], 0, 0, 12, 0);
		bg.width = 420;
		bg.height = descriptionMaxHeight + 38;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		container.addChild(
			bg,
			description as PIXI.DisplayObject,
		);

		return container;
	}

	private createSkillActive(): void {
		this.skillActive = new PIXI.Container();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_skill_hint_red'], 0, 0, 4, 0);
		bg.width = 103;
		bg.height = 25;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		this.textActive = new MultiColoredTextField(
			{ font: '16px wendyOneShadowBold', align: 'center' },
			103,
			25,
		);
		this.textActive.anchor = 0.5;

		this.skillActive.addChild(
			bg,
			this.textActive as PIXI.DisplayObject,
		);
	}

	private createSkillUnlock(): MultiColoredTextField {
		const characterName = this.localizationStorage.getLocalizedString(`#${this.model.getCharacterKey()}_Name`);

		const unlockStr = this.localizationStorage.getLocalizedString('#skill_hint_unlock_by')
			.replace('{{value}}', characterName);

		const skillUnlock = new MultiColoredTextField(
			{ font: '20px wendyOne', align: 'center', tint: 0x303843 },
			300,
			35,
		);
		skillUnlock.text = unlockStr;
		skillUnlock.anchor = 0.5;
		skillUnlock.position.set(80, -66);

		return skillUnlock;
	}

	private setBgSize(width: number, height: number): void {
		this.bg.width = width;
		this.bg.height = height;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height);
		this.bg.position.y = this.arrow.y - this.arrow.height + 14;
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y += 40;
		new TWEEN.Tween(this)
			.to({ y: '-40' }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}

	private onTranslateText(): void {
		this.buttonPromote.setTextLabel(this.localizationStorage.getLocalizedString('#info_window_upgrade_button'));
		this.buttonFindInSummon.setTextLabel(this.localizationStorage.getLocalizedString('#preparty_button_find'));
		this.textFindInSummon.text = this.localizationStorage.getLocalizedString('#hint_find_in_summon');
		this.textActive.text = this.localizationStorage.getLocalizedString('#skill_hint_active');
	}

	private setArrowOffset(value: number): void {
		this.arrow.x = -this.bg.width / 2 + this.bg.width * value;
		this.content.x = -this.arrow.x;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);
		super.destroy(options);
	}
}
