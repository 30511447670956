import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { MaxButtonValueData } from '@configs/ConstantsConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class ButtonMaxCustomersView extends ButtonBaseView {
	private localizationStorage: LocalizationStorage;

	private readonly text: SizeableBitmapText;

	private maxValues: MaxButtonValueData[];
	private currentValueId: number;

	private unlocked: boolean;

	constructor(values: MaxButtonValueData[], currentValueId?: number) {
		const buttonBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['btn_max']);
		super(buttonBg);

		this.unlocked = true;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.maxValues = values;

		if (currentValueId) {
			this.currentValueId = Math.min(values.length - 1, currentValueId);
		} else {
			this.currentValueId = 0;
		}

		this.text = new SizeableBitmapText('', 120, { font: '32px wendyOneShadowBold' });
		this.text.maxWidth = 100;
		this.text.anchor = 0.5;
		this.text.y = -2;
		this.updateText();

		this.addChild(this.text);
	}

	public showLocked(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0x7F7F7F;

		this.off('pointertap', this.onPointerTap, this);
	}

	public showUnlocked(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0xFFFFFF;

		if (this.listeners('pointertap').includes(this.onPointerTap)) {
			this.off('pointertap', this.onPointerTap, this);
		}
		this.on('pointertap', this.onPointerTap, this);
	}

	public setLocked(value: boolean): void {
		this.unlocked = !value;
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	private toggleState(): void {
		this.currentValueId += 1;
		if (this.currentValueId >= this.maxValues.length) {
			this.currentValueId = 0;
		}
		this.updateText();
	}

	public getCurrentValue(): MaxButtonValueData {
		return this.maxValues[this.currentValueId];
	}

	public getCurrentValueId(): number {
		return this.currentValueId;
	}

	protected onPointerTap(): void {
		this.toggleState();
		super.onPointerTap();
	}

	private updateText(): void {
		const currentValue = this.maxValues[this.currentValueId];
		if (currentValue.isMax) {
			this.text.text = this.localizationStorage.getLocalizedString('#gameUI_BtnMaxLabel');
		} else {
			this.text.text = `x${currentValue.value.toString()}`;
		}
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText);
		super.destroy();
	}
}
