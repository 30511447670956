import { RewardTypes } from '@src/types/RewardTypes';
import { SummonViewTypes } from '@src/types/SummonViewTypes';

export type SummonContentItemType = {
	type: RewardTypes;
	id: string;
};

export class SummonConfig {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_VIEW_TYPE: string = 'view_type';
	public static readonly FIELD_PRICE: string = 'price';
	public static readonly FIELD_PRICE_X10: string = 'price_x10';
	public static readonly FIELD_PRICE_RESOURCE: string = 'price_resource';
	public static readonly FIELD_REWARD_10: string = 'reward_10';
	public static readonly FIELD_CONTENT_TYPE: string = 'content_type';
	public static readonly FIELD_CONTENT_VALUE: string = 'content_value';
	public static readonly FIELD_UNLOCK_TIME: string = 'unlock_time';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';
	public static readonly FIELD_BUY_TIMES: string = 'buy_times';

	private readonly key: string;
	private readonly viewType: SummonViewTypes;
	private readonly price: number;
	private readonly priceX10: number;
	private readonly unlockTime: number;
	private readonly buyTimes: number;
	private readonly priceResource: string;
	private readonly reward10: string;
	private readonly content: SummonContentItemType[];
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];

	constructor(config: { [key: string]: string | number }) {
		this.key = config[SummonConfig.FIELD_KEY] as string;
		this.viewType = config[SummonConfig.FIELD_VIEW_TYPE] as SummonViewTypes;
		this.price = Number(config[SummonConfig.FIELD_PRICE]);
		this.priceX10 = Number(config[SummonConfig.FIELD_PRICE_X10]);
		this.priceResource = config[SummonConfig.FIELD_PRICE_RESOURCE] as string;
		this.reward10 = config[SummonConfig.FIELD_REWARD_10] as string;
		this.unlockTime = Number(config[SummonConfig.FIELD_UNLOCK_TIME]);

		if (config[SummonConfig.FIELD_BUY_TIMES]) {
			this.buyTimes = Number(config[SummonConfig.FIELD_BUY_TIMES]);
		}

		if (config[SummonConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[SummonConfig.FIELD_UNLOCK_TYPE] as string;
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[SummonConfig.FIELD_UNLOCK_VALUE] as string;
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}

		const contentValues = (config[SummonConfig.FIELD_CONTENT_VALUE] as string)
			.split(',').map(str => str.trim());
		this.content = (config[SummonConfig.FIELD_CONTENT_TYPE] as string)
			.split(',')
			.map((type, i) => {
				const contentItem: SummonContentItemType = {
					id: contentValues[i].trim(),
					type: type.trim() as RewardTypes,
				};
				return contentItem;
			});
	}

	public getKey(): string {
		return this.key;
	}

	public getViewType(): string {
		return this.viewType;
	}

	public getPrice(): number {
		return this.price;
	}

	public getPriceX10(): number {
		return this.priceX10;
	}

	public getPriceResource(): string {
		return this.priceResource;
	}

	public getReward10(): string {
		return this.reward10;
	}

	public getContent(): SummonContentItemType[] {
		return this.content;
	}

	public getUnlockTime(): number {
		return this.unlockTime;
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public hasBuyTimes(): boolean {
		return this.buyTimes !== undefined;
	}

	public getBuyTimes(): number {
		return this.buyTimes;
	}
}
