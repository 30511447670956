import LocalizationStorage from '@main/LocalizationStorage';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GameConstants } from '@src/utils/GameConstants';
import { TextField } from '@views/components/text/TextField';
import { RewardResultLootboxWindowView } from './RewardResultLootboxWindowView';
import { RewardResultWindowBgType, RewardResultWindowRewardStringType } from './RewardResultWindowBaseView';

export class QuestRewardResultWindow extends RewardResultLootboxWindowView {
	public static readonly EVENT_CONTINUE_BUTTON_CLICK = Symbol();

	private readonly loadingTextLabel: TextField;

	constructor(
		skillModels: Map<string, SkillModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		bgType: RewardResultWindowBgType = RewardResultWindowBgType.FADE,
		rewardString?: RewardResultWindowRewardStringType,
		moneyPanelsButtonPlusInvisible?: boolean,
		hideMoneyPanels?: boolean,
	) {
		super(
			skillModels,
			prestigeMoneyModel,
			hardMoneyModel,
			bgType,
			rewardString,
			moneyPanelsButtonPlusInvisible,
			hideMoneyPanels,
		);

		const localizationStorage = LocalizationStorage.getInstance();

		this.loadingTextLabel = new TextField(
			`${localizationStorage.getLocalizedString('#preloader_LoadingLabel')}...`,
			{ font: '28px wendyOneShadowBold', align: 'center' },
		);
		this.loadingTextLabel.position.set(
			GameConstants.GAME_CENTER_X,
			GameConstants.GAME_HEIGHT - this.closeButton.height,
		);
		this.loadingTextLabel.anchor = 0.5;
		this.loadingTextLabel.visible = false;

		this.addChild(this.loadingTextLabel);
	}

	public close(): void {
		this.onClose();
	}

	protected onBackButtonClick(): void {
		this.emit(QuestRewardResultWindow.EVENT_CONTINUE_BUTTON_CLICK);
		this.closeButton.visible = false;
		this.loadingTextLabel.visible = true;
	}
}
