import {
	RewardResultWindowBgType,
	RewardResultWindowBaseView,
	RewardResultWindowRewardStringType,
} from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { SkillModel } from '@models/skills/SkillModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { GameConstants } from '@src/utils/GameConstants';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import * as TWEEN from '@tweenjs/tween.js';

export class RewardResultWindowView extends RewardResultWindowBaseView {
	protected readonly closeButton: ButtonWithLabelBaseView;
	private readonly openNextButton: ButtonWithLabelBaseView;

	constructor(
		skillModels: Map<string, SkillModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		bgType: RewardResultWindowBgType = RewardResultWindowBgType.FADE,
		rewardString?: RewardResultWindowRewardStringType,
		moneyPanelsButtonPlusInvisible?: boolean,
		hideMoneyPanels?: boolean,
	) {
		super(
			skillModels,
			prestigeMoneyModel,
			hardMoneyModel,
			bgType,
			rewardString,
			moneyPanelsButtonPlusInvisible,
			hideMoneyPanels,
		);

		this.bg.interactive = true;
		this.bg.on('pointertap', () => {
			if (this.cardsToOpen === 0) {
				if (Object.keys(this.groups).length - 1 === this.currentGroup) {
					this.onBackButtonClick();
				} else if (this.openNextButton.visible) {
					this.openNextClick();
				}
			}
		}, this);

		this.closeButton = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 300, 90),
			{ font: '36px wendyOneShadowBold' },
		);
		this.closeButton.setTextLabel(this.localizationStorage.getLocalizedString('#bank_close_result_reward_button'));
		this.closeButton.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onBackButtonClick, this);

		this.closeButton.position.set(
			GameConstants.GAME_CENTER_X,
			GameConstants.GAME_HEIGHT - this.closeButton.height,
		);

		this.closeButton.visible = false;

		this.openNextButton = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 300, 90),
			{ font: '36px wendyOneShadowBold' },
		);
		this.openNextButton.setTextLabel(this.localizationStorage.getLocalizedString('#open_next_summon_pack'));
		this.openNextButton.on(ButtonBaseView.EVENT_CLICK, this.openNextClick, this);
		this.openNextButton.position.set(
			GameConstants.GAME_CENTER_X,
			GameConstants.GAME_HEIGHT - this.openNextButton.height,
		);

		this.openNextButton.visible = false;

		this.addChild(
			this.closeButton,
			this.openNextButton,
		);
	}

	protected onBackButtonClick(): void {
		this.onClose();
	}

	private openNextClick(): void {
		this.openNextButton.visible = false;
		new TWEEN.Tween(this.cardsContainer)
			.to({ alpha: 0 }, 500)
			.onComplete(() => {
				this.cardsContainer.visible = false;
				this.cardAnimations.clear();
				this.currentGroup += 1;
				this.redraw();
			})
			.start();
	}

	protected afterAllCardsOpened(): void {
		if (Object.keys(this.groups).length - 1 === this.currentGroup) {
			this.closeButton.visible = true;
		} else {
			this.openNextButton.visible = true;
		}
	}
}
