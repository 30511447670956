import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20013View } from '@views/characters/events/event2/EventCharacter20013View';
import { EventCharacter20014View } from '@views/characters/events/event2/EventCharacter20014View';
import { EventCharacter20015View } from '@views/characters/events/event2/EventCharacter20015View';

export class EventBusiness20005View extends BusinessBaseView {
	constructor() {
		const character20013 = new EventCharacter20013View();
		const character20014 = new EventCharacter20014View();
		const character20015 = new EventCharacter20015View();

		super('business20005', [character20013, character20014, character20015]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character20013 as PIXI.DisplayObject,
			character20014,
			character20015,
		);
	}
}
