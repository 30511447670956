import { TabButtonBaseView } from '@views/components/buttons/TabButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class BankTabButtonView extends TabButtonBaseView {
	private readonly infoIcon: PIXI.Sprite;

	constructor(
		icon: PIXI.Sprite,
		buttonLabel: string,
	) {
		const inactiveBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_blue_tab2'], 7, 0, 7, 0);
		inactiveBg.width = 289;
		inactiveBg.height = 127;
		inactiveBg.pivot.set(inactiveBg.width / 2, inactiveBg.height / 2);

		const activeBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_green_tab2'], 7, 0, 34, 0);
		activeBg.width = 320;
		activeBg.height = 127;
		activeBg.pivot.set(activeBg.width / 2, activeBg.height / 2);
		activeBg.x = 13;

		icon.position.set(-100, -3);

		super(
			inactiveBg,
			activeBg,
			icon,
			buttonLabel,
			190,
			inactiveBg.height * 0.8,
			{ font: '24px wendyOneShadowBold', align: 'center', tint: 0xd6e5ea },
		);

		this.buttonLabel.position.set(40, 0);

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		this.infoIcon = new PIXI.Sprite(collectionAtlas['can_promote_icon']);
		this.infoIcon.visible = false;
		this.infoIcon.position.set(125, -52);

		this.addChild(this.infoIcon);
	}

	public setActive(active: boolean): void {
		if (active) {
			this.buttonLabel.tint = 0xffffff;
		} else {
			this.buttonLabel.tint = 0xd6e5ea;
		}
		super.setActive(active);
	}

	public setInfoIconVisible(isVisible: boolean): void {
		this.infoIcon.visible = isVisible;
	}
}
