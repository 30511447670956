import { SoundController } from '@src/main/SoundController';
import { AssetsStorage } from '@main/AssetsStorage';

export class BusinessAcquireAnimation extends PIXI.Container {
	public static readonly EVENT_ANIMATION_COMPLETED: symbol = Symbol();

	private girlLeft: PIXI.spine.Spine;
	private girlRight: PIXI.spine.Spine;
	private smokeMiddle: PIXI.spine.Spine;
	private ticker: PIXI.ticker.Ticker;

	constructor() {
		super();

		this.smokeMiddle = new PIXI.spine.Spine(AssetsStorage.getResource('buyBussinesGirl4Animation').spineData);
		this.smokeMiddle.autoUpdate = false;
		this.smokeMiddle.update(0);

		this.girlLeft = new PIXI.spine.Spine(AssetsStorage.getResource('buyBussinesGirl2Animation').spineData);
		this.girlLeft.autoUpdate = false;
		this.girlLeft.update(0);

		this.girlRight = new PIXI.spine.Spine(AssetsStorage.getResource('buyBussinesGirl1Animation').spineData);
		this.girlRight.autoUpdate = false;
		this.girlLeft.update(0);

		this.addChild(
			this.smokeMiddle,
			this.girlLeft,
			this.girlRight,
		);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.animateSpine, this);
	}

	public start(): void {
		this.girlLeft.state.setAnimation(0, 'animation', false);
		this.girlLeft.state.tracks[0].listener = {
			complete: () => {
				this.ticker.remove(this.animateSpine, this);
				this.visible = false;

				this.emit(BusinessAcquireAnimation.EVENT_ANIMATION_COMPLETED);
			},
		};

		this.smokeMiddle.state.setAnimation(0, 'animation', false);
		this.girlRight.state.setAnimation(0, 'animation', false);

		SoundController.getInstance().playBusinessOpen();
	}

	private animateSpine(): void {
		const dt = this.ticker.elapsedMS / 1000;

		if (this.smokeMiddle) {
			this.smokeMiddle.update(dt);
		}

		if (this.girlLeft) {
			this.girlLeft.update(dt);
		}

		if (this.girlRight) {
			this.girlRight.update(dt);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.animateSpine, this);
		this.ticker = null;

		super.destroy(options);

		this.smokeMiddle = null;
		this.girlLeft = null;
		this.girlRight = null;
	}
}
