import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';

export class ReturnFromEventButtonView extends ButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly titleLabel: SizeableBitmapText;
	private readonly textDescr: SizeableBitmapText;

	constructor() {
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['back_to_game_button']);
		super(bg);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const titlebg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_panel_gold'], 8, 0, 8, 0);
		titlebg.width = 180;
		titlebg.pivot.set(titlebg.width / 2, titlebg.height / 2);
		titlebg.position.set(6, 53);

		const titleDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line_horizontal']);
		titleDecor.position.set(16, 66);
		titleDecor.width = 200;
		titleDecor.blendMode = PIXI.BLEND_MODES.SCREEN;

		this.titleLabel = new SizeableBitmapText(
			'',
			130,
			{ font: '28px wendyOne', align: 'center', tint: 0x181a1b },
		);
		this.titleLabel.anchor = 0.5;
		this.titleLabel.position.set(titlebg.x - 7, titlebg.y - 1);

		const timerbg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['purple_panel'], 10, 0, 10, 0);
		timerbg.width = 170;
		timerbg.height = 39;
		timerbg.tint = 0x00000;
		timerbg.alpha = 0.7;
		timerbg.pivot.set(timerbg.width / 2, timerbg.height / 2);
		timerbg.position.set(20, 29);

		this.textDescr = new SizeableBitmapText(
			'',
			130,
			{ font: '18px wendyOneShadowBold', align: 'center', tint: 0x9968aa },
		);
		this.textDescr.anchor = 0.5;
		this.textDescr.position.set(timerbg.x - 10, timerbg.y - 8);

		this.updateText();

		this.addChild(
			timerbg,
			titlebg,
			this.titleLabel,
			this.textDescr as PIXI.DisplayObject,
			titleDecor,
		);
	}

	private updateText(): void {
		this.titleLabel.text = this.localizationStorage.getLocalizedString('#back_to_game_from_event_subtitle');
		this.textDescr.text = this.localizationStorage.getLocalizedString('#back_to_game_from_event');
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
