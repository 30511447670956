import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { LevelModel } from '@models/level/LevelModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { LevelModelInstance } from '@main/modelInstance/LevelModelInstance';
import { WindowViewBaseFactory } from './WindowBaseFactory';
import { LevelEndWindowView } from '@views/windows/LevelEndWindowView';
import { LevelStartWindowView } from '@views/windows/levelStartWindow/LevelStartWindowView';
import { PromotableModel } from '@models/PromotableModel';
import { GoToEventLevelWindowView } from '@views/windows/GoToEventLevelWindowView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { CollectEventLevelRewardsWindowView } from '@views/windows/CollectEventLevelRewardsWindowView';
import { BaseNewsWindowView } from '@views/windows/newsWindow/BaseNewsWindowView';
import { DailyWindowBaseView } from '@views/windows/dailyWindow/DailyWindowBaseView';
import { CardDailyViewFactory } from '@views/ui/cardsDaily/CardDailyViewFactory';

export class LevelWindowViewFactory extends WindowViewBaseFactory {
	private readonly levelModel: LevelModel;
	private readonly levelModelInstance: LevelModelInstance;
	private readonly eventLevelModel: EventLevelModel;
	private readonly dailyRewardCardsFactory: CardDailyViewFactory;

	constructor(
		cheatModel: CheatModel,
		modelInstance: LevelModelInstance,
		networkRequestSender: NetworkRequestSender,
		eventLevelModel: EventLevelModel,
		levelModel: LevelModel,
		serverTime: ServerTimeModel,
		gameProfileModel: GameProfileModel,
		dialogConfigs: Map<string, DialogConfig>,
		constantsConfig: ConstantsConfig,
		localizationSupportConfig: LocalizationSupportConfig,
		redeemCode: string,
		groupUpgradesByBusinesses: boolean,
		collectionWithMoneyPanels: boolean,
		isNutaku: boolean,
		version: string,
	) {
		super(
			serverTime,
			modelInstance,
			gameProfileModel,
			dialogConfigs,
			constantsConfig,
			localizationSupportConfig,
			networkRequestSender,
			redeemCode,
			cheatModel,
			groupUpgradesByBusinesses,
			collectionWithMoneyPanels,
			isNutaku,
			version,
		);

		this.levelModelInstance = modelInstance;
		this.levelModel = levelModel;
		this.eventLevelModel = eventLevelModel;

		this.dailyRewardCardsFactory = new CardDailyViewFactory(
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBoostModels(),
			this.modelInstance.getTimeskipModels(),
		);
	}

	public createLevelEndWindow(): LevelEndWindowView {
		const openedBusinesses = [];
		this.modelInstance.getBusinessModels().forEach(b => {
			if (b.isAcquired()) {
				openedBusinesses.push(b);
			}
		});

		const view = new LevelEndWindowView(
			openedBusinesses.length,
			this.levelModel.getCurrentSeason(),
			this.levelModel.getCurrentLevel(),
			this.levelModel.getTimeTravel(),
			this.levelModel.getBaseTime(),
		);

		return view;
	}

	public createLevelStartWindow(): LevelStartWindowView {
		const currentLevel = this.levelModel.getCurrentLevel();
		const unlockableAtThisLevelModels: PromotableModel[] = [];

		this.modelInstance.getCharacterModels().forEach(c => {
			if (c.isUnlockableAtLevel(currentLevel)) {
				unlockableAtThisLevelModels.push(c);
			}
		});

		this.modelInstance.getTotemModels().forEach(t => {
			if (t.isUnlockableAtLevel(currentLevel)) {
				unlockableAtThisLevelModels.push(t);
			}
		});

		this.modelInstance.getUpgradeModels().forEach(u => {
			if (u.isUnlockableAtLevel(currentLevel)) {
				unlockableAtThisLevelModels.push(u);
			}
		});

		const view = new LevelStartWindowView(
			this.levelModel.getCurrentSeason(),
			currentLevel,
			unlockableAtThisLevelModels,
		);
		return view;
	}

	public createCollectEventRewardsWindowView(): CollectEventLevelRewardsWindowView {
		const view = new CollectEventLevelRewardsWindowView(
			this.eventLevelModel,
			this.cardMiniViewFactory,
		);
		return view;
	}

	public createGoToEventWindow(): GoToEventLevelWindowView {
		const view = new GoToEventLevelWindowView(
			this.eventLevelModel,
			this.cardMiniViewFactory,
		);
		return view;
	}

	public createNewsWindow(): BaseNewsWindowView {
		return new BaseNewsWindowView(
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			this.levelModelInstance.getNewsModel(),
			this.cardMiniViewFactory,
			this.constantsConfig.getNewsUrl(),
			this.gameUI.isButtonMoneyPanelsPlusVisible(),
		);
	}

	public createDailyWindow(): DailyWindowBaseView {
		return new DailyWindowBaseView(
			this.dailyRewardCardsFactory,
			this.levelModelInstance.getDailyRewardsModel(),
			this.levelModelInstance.getPresetsModel(),
		);
	}
}
