import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter251004View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251004View';
import { EventCharacter251005View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251005View';
import { EventCharacter251006View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251006View';

export class EventBusiness251002View extends BusinessBaseView {
	constructor() {
		const eventCharacter251004View = new EventCharacter251004View();
		const eventCharacter251005View = new EventCharacter251005View();
		const eventCharacter251006View = new EventCharacter251006View();

		super('business251002', [eventCharacter251004View, eventCharacter251005View, eventCharacter251006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter251004View as PIXI.DisplayObject,
			eventCharacter251005View,
			eventCharacter251006View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
