export const SoundList: { [key: string]: string } = {
	BUTTON_CLICK_DEFAULT: 'click',
	BUTTON_POINTER_OVER: 'btn_sel',
	BUTTON_CLICK_NEW_CUSTOMERS: 'buy_custom',
	// Level
	QUEST_COMPLETE: 'quest_complete',
	QUEST_NEW: 'new_quest_',
	LEVEL_COMPLETE: 'level_complete',
	// Business
	BUSINESS_OPEN: 'room_open',
	BUSINESS_ZOOM: 'zoom_room',
	COLLECT_INCOME: 'get_money',
	INCOME_APPEAR: 'money_appear',
	COLLECT_PRESTIGE_BONUS: 'violet_credit',
	ACTIVATE_CHARACTER: 'activate_skill',
	ACTIVATE_UPGRADE: 'activate_girl',
	ACTIVATE_SKILL: 'activate_skill',
	DECORATION_SHOW: 'room_object_appear',
	CHARACTER_CLICK_1: 'slap_1',
	CHARACTER_CLICK_2: 'slap_2',
	CHARACTER_CLICK_3: 'slap_3',
	BUSINESS_BG_CLICK: 'click_business',
	// Collection
	PROMOTE_BUTTON: 'promote',
	ENTITY_PROMOTED: 'upgrade',
	COLLECTION_TAB_CHANGE: 'change_panel',
	// Dialog
	DIALOG_NEXT_PHRASE: 'dialog_switch',
	DIALOG_TEXT_APPEAR: 'letter_appear_1',
	// Lootbox
	LOOTBOX_OPEN: 'golden_case',
	LOOTBOX_CARD_DROP: 'reward_single',
	LOOTBOX_REWARD_SUMMARY: 'reward_sum',
	LOOTBOX_FLIGHT_CARD: 'flight_card',
	LOOTBOX_COUP_CARD: 'coup_card',
	// Window
	WINDOW_OPEN: 'window_open',
	WINDOW_CLOSE: 'window_close',
	// Farewell party
	FAREWELL_HEART_CLICK: 'heart_tap',
	FAREWELL_HEART_POP: 'heart_pop',
	// UI
	GEMS_FLY: 'gems_fly',
};

const CharacterClickPackAdult1: string[] = [
	'character_adult1_1',
	'character_adult1_2',
	'character_adult1_3',
	'character_adult1_4',
	'character_adult1_5',
	'character_adult1_6',
	'character_adult1_7',
	'character_adult1_8',
	'character_adult1_9',
	'character_adult1_10',
	'character_adult1_11',
];

const CharacterClickPackAdult2: string[] = [
	'character_adult2_1',
	'character_adult2_2',
	'character_adult2_3',
	'character_adult2_4',
	'character_adult2_5',
	'character_adult2_6',
];

const CharacterClickPackAdult3: string[] = [
	'character_adult3_1',
	'character_adult3_2',
	'character_adult3_3',
	'character_adult3_4',
	'character_adult3_5',
];

const CharacterClickPackAdult4: string[] = [
	'character_adult4_1',
	'character_adult4_2',
	'character_adult4_3',
	'character_adult4_4',
	'character_adult4_5',
	'character_adult4_6',
	'character_adult4_7',
];

const CharacterClickPackAdult5: string[] = [
	'character_adult5_1',
	'character_adult5_2',
	'character_adult5_3',
	'character_adult5_4',
	'character_adult5_5',
	'character_adult5_6',
];

const CharacterClickPackBdsm1: string[] = [
	'character_bdsm1_1',
	'character_bdsm1_2',
	'character_bdsm1_3',
	'character_bdsm1_4',
	'character_bdsm1_5',
	'character_bdsm1_6',
	'character_bdsm1_7',
	'character_bdsm1_8',
];

const CharacterClickPackBdsm2: string[] = [
	'character_bdsm2_1',
	'character_bdsm2_2',
	'character_bdsm2_3',
	'character_bdsm2_4',
	'character_bdsm2_5',
	'character_bdsm2_6',
	'character_bdsm2_7',
	'character_bdsm2_8',
	'character_bdsm2_9',
	'character_bdsm2_10',
	'character_bdsm2_11',
];

const CharacterClickPackBdsm3: string[] = [
	'character_bdsm3_1',
	'character_bdsm3_2',
	'character_bdsm3_3',
	'character_bdsm3_4',
	'character_bdsm3_5',
];

const CharacterClickPackMilf1: string[] = [
	'character_milf1_1',
	'character_milf1_2',
	'character_milf1_3',
	'character_milf1_4',
	'character_milf1_5',
	'character_milf1_6',
	'character_milf1_7',
	'character_milf1_8',
	'character_milf1_9',
];

const CharacterClickPackMilf2: string[] = [
	'character_milf2_1',
	'character_milf2_2',
	'character_milf2_3',
	'character_milf2_4',
	'character_milf2_5',
	'character_milf2_6',
	'character_milf2_7',
	'character_milf2_8',
	'character_milf2_9',
];

const CharacterClickPackMilf3: string[] = [
	'character_milf3_1',
	'character_milf3_2',
	'character_milf3_3',
	'character_milf3_4',
	'character_milf3_5',
	'character_milf3_6',
	'character_milf3_7',
	'character_milf3_8',
	'character_milf3_9',
];

const CharacterClickPackYoung1: string[] = [
	'character_young1_1',
	'character_young1_2',
	'character_young1_3',
	'character_young1_4',
	'character_young1_5',
	'character_young1_6',
];

const CharacterClickPackYoung2: string[] = [
	'character_young2_1',
	'character_young2_2',
	'character_young2_3',
	'character_young2_4',
	'character_young2_5',
];

export const CharacterClickSoundList: Map<string, string[]> = new Map([
	['adult1', CharacterClickPackAdult1],
	['adult2', CharacterClickPackAdult2],
	['adult3', CharacterClickPackAdult3],
	['adult4', CharacterClickPackAdult4],
	['adult5', CharacterClickPackAdult5],
	['bdsm1', CharacterClickPackBdsm1],
	['bdsm2', CharacterClickPackBdsm2],
	['bdsm3', CharacterClickPackBdsm3],
	['milf1', CharacterClickPackMilf1],
	['milf2', CharacterClickPackMilf2],
	['milf3', CharacterClickPackMilf3],
	['young1', CharacterClickPackYoung1],
	['young2', CharacterClickPackYoung2],
]);

export const FarewellPartyHeartClickSoundList = [
	'orgy_1',
	'orgy_2',
	'orgy_3',
	'orgy_4',
	'orgy_5',
	'orgy_6',
	'orgy_7',
	'orgy_8',
	'orgy_9',
	'orgy_10',
	'orgy_11',
	'orgy_12',
	'orgy_13',
	'orgy_14',
	'orgy_15',
	'orgy_16',
	'orgy_17',
	'orgy_18',
	'orgy_19',
	'orgy_20',
];
