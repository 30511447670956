import { RewardTypes, RewardResourceIdTypes, RewardDescriptionType } from '@src/types/RewardTypes';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithCostValueView';
import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';

export class BankUIViewHelper {
	public static resolveButtonValueTypeByReward(rewardType: RewardTypes, rewardId: RewardResourceIdTypes | string): ButtonValueTypes {
		switch (rewardType) {
			case RewardTypes.RESOURCE: {
				switch (rewardId) {
					case RewardResourceIdTypes.HARD_MONEY:
						return ButtonValueTypes.HARD_MONEY;

					case RewardResourceIdTypes.PRESTIGE_MONEY:
						return ButtonValueTypes.PRESTIGE_MONEY;

					case RewardResourceIdTypes.SOFT_MONEY:
						return ButtonValueTypes.SOFT_MONEY;

					default:
						throw new Error(`Unsupported reward id type: ${rewardId}`);
				}
			}
			default:
				throw new Error(`Unsupported reward type: ${rewardType}`);
		}
	}

	public static resolveButtonValueTypeByPriceResource(
		bankPriceResource: BankPriceResourceTypes,
		isNutaku: boolean,
	): ButtonValueTypes {
		switch (bankPriceResource) {
			case BankPriceResourceTypes.HARD_MONEY:
				return ButtonValueTypes.HARD_MONEY;

			case BankPriceResourceTypes.PRESTIGE_MONEY:
				return ButtonValueTypes.PRESTIGE_MONEY;

			case BankPriceResourceTypes.REAL:
				return isNutaku ? ButtonValueTypes.NUTAKU : ButtonValueTypes.DOLLAR;

			default:
				throw new Error(`Unsupported bank price resource: ${bankPriceResource}`);
		}
	}

	public static resolveBankItemPurchaseDescriptionString(reward: RewardDescriptionType, eventKey: string): string {
		switch (reward.reward_type) {
			case RewardTypes.RESOURCE: {
				switch (reward.reward_id) {
					case RewardResourceIdTypes.HARD_MONEY:
						return '#purchase_hard_money';

					case RewardResourceIdTypes.PRESTIGE_MONEY:
						return `#purchase_prestige_money_${eventKey}`;

					default:
						throw new Error(`Unsupported reward id type: ${reward.reward_id}`);
				}
			}
			default:
				throw new Error(`Unsupported reward type: ${reward.reward_type}`);
		}
	}

	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly boostModels: Map<string, BoostModel>;
	private readonly timeskipModels: Map<string, TimeskipModel>;

	private readonly cardMiniViewFactory: CardMiniViewFactory;

	constructor(
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		timeskipModels: Map<string, TimeskipModel>,
	) {
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.boostModels = boostModels;
		this.timeskipModels = timeskipModels;

		this.cardMiniViewFactory = new CardMiniViewFactory(
			this.characterModels,
			this.upgradeModels,
			this.totemModels,
			this.boostModels,
			this.timeskipModels,
		);
	}

	public resolveBankBundleDescriptionString(
		bankPriceItemsConfig: BankPriceItemsConfig,
		bundleRewards: RewardDescriptionType[],
	): string {
		let priceItemResult = 0;
		bundleRewards.forEach((bundleReward) => {
			const bundleRewardType = bundleReward.reward_type;
			const bundleRewardId = bundleReward.reward_id;
			let priceForItem = 0;
			switch (bundleRewardType) {
				case RewardTypes.UPGRADE: {
					const rarity = this.upgradeModels.get(bundleRewardId).getCardRarity();
					priceForItem += bankPriceItemsConfig.getPriceItemUpgrade(rarity);
					break;
				}
				case RewardTypes.TOTEM: {
					const rarity = this.totemModels.get(bundleRewardId).getCardRarity();
					priceForItem += bankPriceItemsConfig.getPriceItemTotem(rarity);
					break;
				}
				case RewardTypes.CHARACTER: {
					const rarity = this.characterModels.get(bundleRewardId).getCardRarity();
					priceForItem += bankPriceItemsConfig.getPriceItemCharacter(rarity);
					break;
				}
				case RewardTypes.BOOST: {
					const key = this.boostModels.get(bundleRewardId).getKey();
					priceForItem += bankPriceItemsConfig.getPriceItemBoost(key);
					break;
				}
				case RewardTypes.TIMESKIP: {
					const key = this.timeskipModels.get(bundleRewardId).getKey();
					priceForItem += bankPriceItemsConfig.getPriceItemTimeskip(key);
					break;
				}
				case RewardTypes.RESOURCE: {
					priceForItem += bankPriceItemsConfig.getPriceItemResource(bundleRewardId as RewardResourceIdTypes);
					break;
				}
				default:
					throw new Error(`Unsupported bundle reward type '${bundleRewardType}'`);
			}
			priceItemResult += Math.round(priceForItem * Number(bundleReward.reward_qty));
		});
		return priceItemResult.toString();
	}

	public resolveRewardMiniCardByReward(
		rewardType: RewardTypes,
		rewardId: string | RewardResourceIdTypes,
		scaleCard: number = 1,
		scaleResource: number = 1,
	): PIXI.Container {
		return this.cardMiniViewFactory.createRewardCardByTypeId(
			rewardType,
			rewardId,
			scaleCard,
			scaleResource,
		);
	}

	public resolveRewardIconByReward(
		rewardType: string,
		rewardId: string,
		scaleCard: number = 1,
		scaleResource: number = 1,
	): PIXI.Sprite {
		const miniCardsBaseAtlas = AssetsStorage.getAtlas('miniCardsBaseAtlas');
		const miniCardsIconsAtlas = AssetsStorage.getAtlas('miniCardsIconsAtlas');

		let icon = null;
		switch (rewardType) {
			case RewardTypes.RESOURCE: {
				switch (rewardId) {
					case RewardResourceIdTypes.HARD_MONEY:
						icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_hard_money']);
						icon.scale.set(scaleResource);
						break;
					case RewardResourceIdTypes.PRESTIGE_MONEY:
						icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_prestige']);
						icon.scale.set(scaleResource);
						break;
					case RewardResourceIdTypes.SOFT_MONEY:
						icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money']);
						icon.scale.set(scaleResource);
						break;
					default:
						throw new Error(`Not found icon for resource ${rewardId}`);
				}
				break;
			}
			case RewardTypes.BOOST: {
				const model = this.boostModels.get(rewardId);
				icon = new PIXI.Sprite(miniCardsBaseAtlas[`${model.getIconKey()}_mini_card_icon`]);
				icon.scale.set(scaleCard);
				break;
			}
			case RewardTypes.TIMESKIP: {
				const model = this.timeskipModels.get(rewardId);
				icon = new PIXI.Sprite(miniCardsBaseAtlas[`${model.getIconKey()}_mini_card_icon`]);
				icon.scale.set(scaleCard);
				break;
			}
			default:
				icon = miniCardsIconsAtlas[`${rewardId}_mini_card_icon`]
					? new PIXI.Sprite(miniCardsIconsAtlas[`${rewardId}_mini_card_icon`])
					: new PIXI.Sprite(miniCardsBaseAtlas['lock_mini_card_icon']);
				icon.scale.set(scaleCard);
		}
		return icon;
	}
}
