import { BankRechargeConfig } from '@configs/bank/BankRechargeConfig';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { UnlockValueChecker } from '../../UnlockValueChecker';

export class BankRechargeConfigsFilter {
	constructor(
		private readonly bankSavesModel: BankSavesModel,
		private readonly unlockValueChecker: UnlockValueChecker,
	) {}

	public filter(source: Map<string, BankRechargeConfig>): Map<string, BankRechargeConfig> {
		const filteredMap: Map<string, BankRechargeConfig> = new Map();

		source.forEach((config, key) => {
			let result: boolean = !this.bankSavesModel.isRechargeClaimed(config.getKey());

			if (result) {
				if (config.hasSomeUnlockTypeValue()) {
					const unlockTypes = config.getUnlockTypes();
					const unlockValues = config.getUnlockValues();
					for (let i = 0; i < unlockTypes.length && result; i++) {
						const unlockType = unlockTypes[i];
						const unlockValue = unlockValues[i];
						result = this.unlockValueChecker.check(unlockType, unlockValue);
					}
				}
			}

			if (result) {
				filteredMap.set(key, config);
			}
		});
		return filteredMap;
	}
}
