import LocalizationStorage from '@main/LocalizationStorage';

export abstract class BaseValidator<T> {
	protected errorString: string;
	protected localizationStorage: LocalizationStorage;

	private next: BaseValidator<T>;

	constructor(errorString: string) {
		this.errorString = errorString;
		this.localizationStorage = LocalizationStorage.getInstance();
	}

	public validate(value: T): string | undefined {
		let result: string;
		if (this.isValid(value)) {
			result = this.next?.validate(value);
		} else {
			result = this.getErrorMessage();
		}
		return result;
	}

	public setNext(next: BaseValidator<T>): BaseValidator<T> {
		this.next = next;
		return next;
	}

	protected abstract isValid(value: T): boolean;

	protected getErrorMessage(): string {
		return this.localizationStorage.getLocalizedString(this.errorString);
	}
}
