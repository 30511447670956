import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter110006View extends CharacterMainBaseView {
	constructor() {
		super('character110006');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(139, 38);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(60, 45),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(160, 40),
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
