import { FarewellPartyWindowViewSetter } from '@interfaces/ViewSetters';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BaseAction } from '@models/network/actions/BaseAction';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { FarewellPartyUpdateFuckpowerAction } from '@models/network/actions/farewellParty/FarewellPartyUpdateFuckpowerAction';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { FarewellPartyStartAction } from '@models/network/actions/farewellParty/FarewellPartyStartAction';

export class FarewellPartyWindowViewController extends PIXI.utils.EventEmitter implements FarewellPartyWindowViewSetter {
	private model: FarewellPartyModel;

	private lastClickTime: number;
	private minClickDeltaTime: number;
	private clicksCounter: number;

	private view: FarewellPartyWindowView;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly farewellpartyBoostModel: FarewellBoostModel,
		private readonly prepartyModel: PrepartyStartCharacterModel,
		private readonly serverTimeModel: ServerTimeModel,
		private farewellPartyEverStarted: boolean,
	) {
		super();
	}

	public setFarewellPartyWindowView(view: FarewellPartyWindowView): void {
		this.view = view;
		this.view.once(FarewellPartyWindowView.EVENT_STARTED, this.onStarted, this);
		this.view.once(FarewellPartyWindowView.EVENT_COMPLETED, this.onCompleted, this);
		this.view.once(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED, this.onStartTimerCompleted, this);
		this.view.once(WindowBaseView.EVENT_WINDOW_CLOSED, this.onWindowClose, this);
		this.view.on(FarewellPartyWindowView.EVENT_TAP, this.onTap, this);
		this.view.on(FarewellPartyWindowView.EVENT_AIM_TRAILS_ANIMATION_COMPLETED, this.onAimTrailsAnimationCompleted, this);
		this.view.on(WindowBaseView.EVENT_WINDOW_CLOSED, this.onClose, this);
	}

	private onWindowClose(): void {
		this.model.finish();
	}

	private async onStarted(model: FarewellPartyModel): Promise<void> {
		this.model = model;
		this.minClickDeltaTime = Number.MAX_SAFE_INTEGER;
		this.lastClickTime = Date.now();
		this.clicksCounter = 0;
		if (!this.farewellPartyEverStarted) {
			this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				farewell_party_ever_started: true,
			}));
			this.farewellPartyEverStarted = true;
		}
	}

	private onAimTrailsAnimationCompleted(): void {
		const nextRewardReached = this.model.addAimProgress();
		if (nextRewardReached) {
			this.onProgressAction();
		}
	}

	private onTap(): void {
		const nextRewardReached = this.model.addTapProgress();
		if (nextRewardReached) {
			this.onProgressAction();
		}

		this.countClicks();
	}

	private countClicks(): void {
		this.clicksCounter += 1;
		const currentTime: number = Date.now();
		this.minClickDeltaTime = Math.min(this.minClickDeltaTime, currentTime - this.lastClickTime);
		this.lastClickTime = currentTime;
	}

	private onCompleted(): void {
		this.view.off(FarewellPartyWindowView.EVENT_TAP, this.onTap, this);
		this.view.off(FarewellPartyWindowView.EVENT_AIM_TRAILS_ANIMATION_COMPLETED, this.onAimTrailsAnimationCompleted, this);

		const boostExpireTime: number = this.serverTimeModel.getCalculatedISOTime() + this.model.getСurrentRewardBoostTime();
		this.farewellpartyBoostModel.init(this.model.getCurrentRewardBoostMultiplier(), boostExpireTime, true);
		this.farewellpartyBoostModel.setTimeleft(this.model.getСurrentRewardBoostTime());
		this.farewellpartyBoostModel.activate();
	}

	private onStartTimerCompleted(): void {
		this.lastClickTime = Date.now();
		this.model.start(this.serverTimeModel.getCalculatedISOTime());
		this.emit(BaseAction.EVENT_ACTION_CREATED, new FarewellPartyStartAction());
	}

	private onProgressAction(): void {
		const progressAction = new FarewellPartyUpdateFuckpowerAction(
			this.minClickDeltaTime,
			this.clicksCounter,
			Math.floor(this.model.getTotalProgress()),
		);

		this.emit(BaseAction.EVENT_ACTION_CREATED, progressAction);
	}

	private async onClose(): Promise<void> {
		const nextPrepartyShow = await this.networkRequestSender.sendGetNextFerewellPartyTime();
		const secondsToRespawn = nextPrepartyShow.next_on - this.serverTimeModel.getCalculatedISOTime();

		this.model.resetModel();
		this.prepartyModel.respawn(secondsToRespawn);
	}
}
