import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70010View } from '@views/characters/events/event7/EventCharacter70010View';
import { EventCharacter70011View } from '@views/characters/events/event7/EventCharacter70011View';
import { EventCharacter70012View } from '@views/characters/events/event7/EventCharacter70012View';

export class EventBusiness70004View extends BusinessBaseView {
	constructor() {
		const eventCharacter70010View = new EventCharacter70010View();
		const eventCharacter70011View = new EventCharacter70011View();
		const eventCharacter70012View = new EventCharacter70012View();


		super('business70004', [eventCharacter70010View, eventCharacter70011View, eventCharacter70012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b7_decor4']).setTransform(-17, -60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor2']).setTransform(-18, 15, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor1']).setTransform(-17, -93.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor5']).setTransform(-14, -99, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter70010View as PIXI.DisplayObject,
			eventCharacter70011View,
			eventCharacter70012View,
		);
	}
}
