import { EventLevelAssetNames } from '@src/types/EventLevelAssetNames';

type AssetStorageResourceType = PIXI.loaders.Resource | PIXI.loaders.TextureDictionary;
type FontResourceType = PIXI.loaders.Resource & { bitmapFont: string };

export class AssetsStorage {
	private static resources: Record<string, AssetStorageResourceType> = {};
	private static EVENT_PREFIX_RE = /event[0-9]*_/;

	public static swapEventAssets(
		swapToEventAssets: boolean,
	): void {
		// Swap textures
		EventLevelAssetNames.EVENT_ASSET_NAMES_TO_SWAP.forEach((eventAssetName) => {
			const targetAssetName = eventAssetName.replace(AssetsStorage.EVENT_PREFIX_RE, '');
			const eventResource = AssetsStorage.getResource(eventAssetName);

			if (eventResource instanceof PIXI.loaders.Resource) {
				AssetsStorage.swapResources(targetAssetName, eventAssetName);
			} else {
				Object.keys(eventResource).forEach((eventResourceEntry) => {
					const targetAssetEntryName = eventResourceEntry.replace(AssetsStorage.EVENT_PREFIX_RE, '');

					AssetsStorage.swapAtlasResources(
						targetAssetEntryName,
						targetAssetName,
						eventResourceEntry,
						eventAssetName,
					);
				});
			}
		});

		// Swap bitmap fonts
		const fontNamesToSwap: string[] = [];
		const fontsToSwap: FontResourceType[] = [];

		if (swapToEventAssets) {
			EventLevelAssetNames.EVENT_FONT_NAMES_TO_SWAP.forEach(eventFontName => {
				fontsToSwap.push(AssetsStorage.getResource(eventFontName) as FontResourceType);

				const targetFontName = eventFontName.replace(AssetsStorage.EVENT_PREFIX_RE, '');
				fontNamesToSwap.push(targetFontName);
			});
		} else {
			EventLevelAssetNames.EVENT_FONT_NAMES_TO_SWAP.forEach(eventFontName => {
				const targetFontName = eventFontName.replace(AssetsStorage.EVENT_PREFIX_RE, '');
				fontsToSwap.push(AssetsStorage.getResource(targetFontName) as FontResourceType);
				fontNamesToSwap.push(targetFontName);
			});
		}

		fontNamesToSwap.forEach((fontName, i) => {
			PIXI.extras.BitmapText.fonts[fontName] = fontsToSwap[i].bitmapFont;
		});
	}

	public static addResource(key: string, entry: AssetStorageResourceType): void {
		const resource = AssetsStorage.resources[key];
		if (resource) {
			AssetsStorage.resources[key] = Object.assign(
				resource,
				entry,
			);
		} else {
			AssetsStorage.resources[key] = entry;
		}
	}

	public static getAtlas(key: string): PIXI.loaders.TextureDictionary {
		return AssetsStorage.resources[key] as PIXI.loaders.TextureDictionary;
	}

	public static getResource(key: string): PIXI.loaders.Resource {
		return AssetsStorage.resources[key] as PIXI.loaders.Resource;
	}

	public static getCharacterAnimation(key: string): PIXI.spine.core.SkeletonData | undefined {
		return AssetsStorage.getResource(key)?.spineData;
	}

	private static swapAtlasResources(
		resource1Key: string,
		atlas1Key: string,
		resource2Key: string,
		atlas2Key: string,
	): void {
		const atlas1 = AssetsStorage.getAtlas(atlas1Key);
		const atlas2 = AssetsStorage.getAtlas(atlas2Key);
		const temp = atlas1[resource1Key];
		atlas1[resource1Key] = atlas2[resource2Key];
		atlas2[resource2Key] = temp;
	}

	private static swapResources(
		resource1Key: string,
		resource2Key: string,
	): void {
		const temp = AssetsStorage.resources[resource1Key];
		AssetsStorage.resources[resource1Key] = AssetsStorage.resources[resource2Key];
		AssetsStorage.resources[resource2Key] = temp;
	}
}
