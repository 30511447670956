import { CharacterQuickPhraseType } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import LocalizationStorage from '@main/LocalizationStorage';
import { PhraseCharacterKey } from './CharacterQuickPhraseEmitter';

export class QuickPhaseGeneratorBase extends PIXI.utils.EventEmitter {
	public static readonly EVENT_PHRASE_GENERATED: symbol = Symbol();

	private type: CharacterQuickPhraseType;

	protected localizationStorage: LocalizationStorage;

	protected phraseLocalesMap: Map<PhraseCharacterKey, string[]>;
	protected phraseLocaleKeyDefault: string;

	protected config: CharacterQuickPhraseConstants;
	protected phraseCharacterKeys: PhraseCharacterKey[];

	private static getPhrasesLocaleKeys(type: CharacterQuickPhraseType, key: PhraseCharacterKey): string[] {
		const localeKeys: string[] = [];

		const localeKeyBase = `#quick_phrase_${key}_${type}_`;
		for (let i = 1; ; i++) {
			const localeKey = `${localeKeyBase}${i}`;
			if (LocalizationStorage.getInstance().hasLocalizedString(localeKey)) {
				localeKeys.push(localeKey);
			} else {
				break;
			}
		}
		return localeKeys;
	}

	constructor(
		type: CharacterQuickPhraseType,
		config: CharacterQuickPhraseConstants,
	) {
		super();

		this.type = type;
		this.config = config;
		this.phraseLocalesMap = new Map();
		this.phraseCharacterKeys = [];
		this.phraseLocaleKeyDefault = '#quick_phrase_default';
		this.localizationStorage = LocalizationStorage.getInstance();
	}

	public registerPhrases(key: PhraseCharacterKey): void {
		this.phraseCharacterKeys.push(key);

		const phrases = QuickPhaseGeneratorBase.getPhrasesLocaleKeys(this.type, key);
		this.phraseLocalesMap.set(key, phrases);
	}

	public unregisterPhrases(key: PhraseCharacterKey): void {
		this.phraseCharacterKeys.splice(this.phraseCharacterKeys.indexOf(key), 1);
		this.phraseLocalesMap.delete(key);
	}

	public tryGeneratePhrase(key: PhraseCharacterKey, showTime: number = 3000): void {
		const chance: number = this.config.createChances.get(this.type);
		if (chance > Math.random()) {
			const localeKey = this.getPhraseLocaleKeyRandom(key);
			const phrase = this.localizationStorage.getLocalizedString(localeKey).trim();
			this.emit(QuickPhaseGeneratorBase.EVENT_PHRASE_GENERATED, key, phrase, showTime);
		}
	}

	protected getPhraseLocaleKeyRandom(key: PhraseCharacterKey): string {
		const phrases: string[] = this.phraseLocalesMap.get(key);
		let phrase: string;
		if (phrases && phrases.length > 0) {
			const phraseId: number = Math.floor(Math.random() * phrases.length);
			phrase = phrases[phraseId];
		} else {
			phrase = this.phraseLocaleKeyDefault;
		}
		return phrase;
	}
}
