import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { CardRarity } from '@src/types/CardRarities';

export class EpicQuestCollectionsConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_TYPE: string = 'type';
	public static readonly FIELD_REWARD_PROGRESS: string = 'reward_progress';
	public static readonly FIELD_LOOTBOX: string = 'lootbox';

	public static readonly FIELDS: string[] = [
		EpicQuestCollectionsConfig.FIELD_TYPE,
		EpicQuestCollectionsConfig.FIELD_KEY,
		EpicQuestCollectionsConfig.FIELD_REWARD_PROGRESS,
		EpicQuestCollectionsConfig.FIELD_LOOTBOX,
	];

	private readonly key: string;
	private readonly type: EpicQuestCollectionsTypes;
	private readonly questTargetQuestKeyMap: Map<number, string>;
	private readonly questKeyLootboxKeyMap: Map<string, string>;
	private readonly questKeyLootboxRarityMap: Map<string, CardRarity>;

	constructor(config: { [key: string]: string }) {
		this.key = config[EpicQuestCollectionsConfig.FIELD_KEY].trim();

		this.questTargetQuestKeyMap = new Map();
		this.questKeyLootboxRarityMap = new Map();
		this.questKeyLootboxKeyMap = new Map();

		this.type = config[EpicQuestCollectionsConfig.FIELD_TYPE].trim() as EpicQuestCollectionsTypes;
	}

	public addQuestTarget(config: { [key: string]: string }): void {
		this.questTargetQuestKeyMap.set(
			parseInt(config[EpicQuestCollectionsConfig.FIELD_REWARD_PROGRESS], 10),
			config[EpicQuestCollectionsConfig.FIELD_KEY].trim(),
		);
		this.questKeyLootboxKeyMap.set(
			config[EpicQuestCollectionsConfig.FIELD_KEY],
			config[EpicQuestCollectionsConfig.FIELD_LOOTBOX].trim(),
		);
	}

	public setQuestLootboxRarity(questKey: string, rarity: CardRarity): void {
		this.questKeyLootboxRarityMap.set(questKey, rarity);
	}

	public getKey(): string {
		return this.key;
	}

	public getType(): EpicQuestCollectionsTypes {
		return this.type;
	}

	public getQuestTargetQuestKeyMap(): Map<number, string> {
		return this.questTargetQuestKeyMap;
	}

	public getQuestKeyLootboxKeyMap(): Map<string, string> {
		return this.questKeyLootboxKeyMap;
	}

	public getQuestKeyLootboxRarityMap(): Map<string, CardRarity> {
		return this.questKeyLootboxRarityMap;
	}
}
