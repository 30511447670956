import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character4View } from '@views/characters/main/Character4View';
import { Character5View } from '@views/characters/main/Character5View';
import { Character6View } from '@views/characters/main/Character6View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business2View extends BusinessBaseView {
	constructor() {
		const character4 = new Character4View();
		const character5 = new Character5View();
		const character6 = new Character6View();
		character6.once(Character6View.EVENT_SHOWN, () => character4.destroy());

		super('business2', [character4, character5, character6]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.furniture.set('furniture5', new PIXI.Sprite(decorationAtlas['b2_furniture5']).setTransform(-78, 4, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture6', new PIXI.Sprite(decorationAtlas['b2_furniture6']).setTransform(193, -6, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture7', new PIXI.Sprite(decorationAtlas['b2_furniture7']).setTransform(-250, 0, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture8', new PIXI.Sprite(decorationAtlas['b2_furniture8']).setTransform(-288, 37, 0.5, 0.5) as PIXI.Sprite);

		this.addChild(
			character4 as PIXI.DisplayObject,
			character5,
			character6,
		);
	}
}
