import { AbstractReward } from '@interfaces/AbstractReward';
import { CardRarity } from '@src/types/CardRarities';
import { PromotableModel } from '@models/PromotableModel';
import { RewardTypes } from '@src/types/RewardTypes';

export abstract class AbstractRewardCards<T extends PromotableModel> extends AbstractReward {
	protected readonly model: T;
	protected readonly quantity: number;

	protected constructor(model: T, type: RewardTypes, quantity: number) {
		super(type, model.getKey());

		this.model = model;
		this.newReward = !model.isOpened();
		this.quantity = quantity;
	}

	public collect(): void {
		if (!this.model.isOpened()) {
			this.model.open();
		}

		this.model.addCards(this.quantity);
	}

	public getModel(): T {
		return this.model;
	}

	public getCardRarity(): CardRarity {
		return this.model.getCardRarity();
	}

	public getQuantity(): number {
		return this.quantity;
	}
}
