import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BLEND_MODES } from 'pixi.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';
import { BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';
import { BankBundleGroupModel } from '@models/bank/BankBundleGroupModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { ButtonWithOldCostValueSettings, ButtonWithOldCostValue } from '@views/components/buttons/ButtonWithOldCostValueView';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class BankOfferWindowDoublePackBodyView extends PIXI.Container {
	private static createButtonConfig(model: BankBundleModel): ButtonWithOldCostValueSettings {
		const config: ButtonWithOldCostValueSettings = {
			price: String(model.getPrice()),
			buttonPriceResource: model.getButtonPriceResource(),
			priceStyle: {
				font: {
					name: 'wendyOneShadowBold',
					size: 46,
				},
			},
			minWidth: 300,
			maxWidth: 380,
		};

		if (model.hasOldPrice()) {
			config.oldPrice = String(model.getOldPrice());
			config.buttonOldPriceResource = model.getButtonOldPriceResource();
			config.oldPriceStyle = {
				font: {
					name: 'wendyOneShadowBold',
					size: 32,
				},
			};
		}

		return config;
	}

	private static readonly LEFT_KEY: string = 'compare_left';
	private static readonly RIGHT_KEY: string = 'compare_right';

	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly timerLabel: PIXI.extras.BitmapText;

	private readonly buttonBuyLeft: ButtonBaseView;
	private readonly buttonBuyRight: ButtonBaseView;

	constructor(
		bankOfferModel: BankOfferModel,
		bankTabElementWithOffer: BankBundleGroupModel,
		getBuyButtonHandler: (config: ButtonWithOldCostValueSettings, bankTabElementWithOffer: BankTabElementWithOfferType) => ButtonWithOldCostValue,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super();

		this.animationEmitters = [];
		const offerElements: BankBundleModel[] = bankTabElementWithOffer.getBundles();
		const itemLeft: BankBundleModel = offerElements.find(x => x.getViewKey().includes(BankOfferWindowDoublePackBodyView.LEFT_KEY));
		const itemRight: BankBundleModel = offerElements.find(x => x.getViewKey().includes(BankOfferWindowDoublePackBodyView.RIGHT_KEY));

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		const girl = new PIXI.Sprite(AssetsStorage.getResource(`${bankTabElementWithOffer.getCharacterViewType().characters[0]}_offer`).texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.position.set(-576, 50);

		const bgLeft = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['offer_bg_perple']);
		bgLeft.scale.set(2);
		bgLeft.y = 7;
		bgLeft.x = -112;
		bgLeft.interactive = true;

		const bgLeftTint = new PIXI.Graphics();
		bgLeftTint.beginFill(0x000000);
		bgLeftTint.drawPolygon([
			new PIXI.Point(-350, 290),
			new PIXI.Point(93, 290),
			new PIXI.Point(103, 103),
			new PIXI.Point(-340, 103),
		]);
		bgLeftTint.alpha = 0.25;

		const bgRight = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['offer_bg_gold']);
		bgRight.scale.set(2);
		bgRight.y = 7;
		bgRight.x = 503;
		bgRight.interactive = true;

		const bgRightTint = new PIXI.Graphics();
		bgRightTint.beginFill(0x000000);
		bgRightTint.drawPolygon([
			new PIXI.Point(125, 290),
			new PIXI.Point(846, 290),
			new PIXI.Point(858, 103),
			new PIXI.Point(136, 103),
		]);
		bgRightTint.alpha = 0.25;

		const titleTextLeft = new MultiColoredTextField(
			{ font: '41px wendyOneShadowBold', align: 'center', tint: 0x9ac5ff },
			330,
			100,
		);
		titleTextLeft.text = this.localizationStorage.getLocalizedString(itemLeft.getNameLocale());
		titleTextLeft.anchor = 0.5;
		titleTextLeft.position.set(-112, -176);

		const titleTextRight = new MultiColoredTextField(
			{ font: '52px wendyOneGold', align: 'center' },
			570,
			120,
		);
		titleTextRight.text = this.localizationStorage.getLocalizedString(itemRight.getNameLocale());
		titleTextRight.anchor = 0.5;
		titleTextRight.position.set(503, -176);

		const offerStickerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_sticker'], 26, 0, 26, 0);
		offerStickerBg.width = 320;
		offerStickerBg.pivot.set(offerStickerBg.width / 2, offerStickerBg.height / 2);
		offerStickerBg.position.set(513, -272);

		const offerStickerText = new MultiColoredTextField(
			{ font: '25px wendyOne', align: 'center' },
			270,
			40,
		);
		offerStickerText.text = this.localizationStorage.getLocalizedString('#sticker_best_offer_label');
		offerStickerText.anchor = 0.5;
		offerStickerText.position.set(offerStickerBg.x, offerStickerBg.y - 4);

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '32px wendyOneShadowBold', tint: 0xffda68,
		});
		this.timerLabel.anchor = 0.5;
		this.timerLabel.y = -2;

		const timerBg = new PIXI.Graphics();
		timerBg.beginFill(0x000000);
		timerBg.drawPolygon([
			new PIXI.Point(310, -25),
			new PIXI.Point(-300, -25),
			new PIXI.Point(-310, 25),
			new PIXI.Point(300, 25),
		]);
		timerBg.alpha = 0.7;

		const timerGlow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_circle_glow'], 0, 0, 0, 0);
		timerGlow.width = 1300;
		timerGlow.height = 250;
		timerGlow.pivot.set(timerGlow.width / 2, timerGlow.height / 2);
		timerGlow.tint = 0xb74a0c;
		timerGlow.alpha = 0.37;

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(503, -100);
		animationsContainer.scale.y = 0.6;
		animationsContainer.scale.x = 0.8;

		const glowLightsEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const timerContainer = new PIXI.Container();
		timerContainer.position.set(258, 411);

		const glowDecor = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		glowDecor.width = 500;
		glowDecor.pivot.set(glowDecor.width / 2, glowDecor.height / 2);
		glowDecor.position.set(373, 288);
		glowDecor.blendMode = BLEND_MODES.ADD;
		glowDecor.alpha = 0.8;

		const glowDecor2 = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line_horizontal'], 0, 0, 0, 0);
		glowDecor2.width = 700;
		glowDecor2.pivot.set(glowDecor2.width / 2, glowDecor2.height / 2);
		glowDecor2.position.set(503, -283);
		glowDecor2.blendMode = BLEND_MODES.ADD;
		glowDecor2.scale.x = -1;

		const rewardsContainerLeft = getRewardMiniCardsContainerHandler(10, itemLeft, 0.86);
		rewardsContainerLeft.position.set(-112, -16);

		const rewardsContainerRight = getRewardMiniCardsContainerHandler(10, itemRight, 0.86);
		rewardsContainerRight.position.set(503, -16);

		this.buttonBuyLeft = getBuyButtonHandler(BankOfferWindowDoublePackBodyView.createButtonConfig(itemLeft), itemLeft);
		this.buttonBuyLeft.position.set(-112, 198);

		this.buttonBuyRight = getBuyButtonHandler(BankOfferWindowDoublePackBodyView.createButtonConfig(itemRight), itemRight);
		this.buttonBuyRight.position.set(503, 198);

		timerContainer.addChild(
			timerGlow,
			timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);

		this.addChild(
			animationsContainer,
			girl,
			bgLeft,
			bgLeftTint,
			bgRight,
			bgRightTint,
			titleTextLeft as PIXI.DisplayObject,
			titleTextRight,
			timerContainer,
			rewardsContainerLeft,
			rewardsContainerRight,
			glowDecor,
			glowDecor2,
			offerStickerBg,
			offerStickerText,
			this.buttonBuyLeft,
			this.buttonBuyRight,
		);

		if (itemRight.getMostPopularLocaleKey()) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSale.position.set(174.5, -84.5);
			const saleLabel = new TextField(
				this.localizationStorage.getLocalizedString(itemRight.getMostPopularLocaleKey()),
				{ font: '26px wendyOne', align: 'center', tint: 0xfff78f },
				55,
				55,
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.47;
			saleLabel.position.set(174.5, -84.5);

			const stickerSaleContainer = new PIXI.Container();
			stickerSaleContainer.position.set(685, -165);
			stickerSaleContainer.addChild(
				stickerSale,
				saleLabel as PIXI.DisplayObject,
			);
			this.addChild(stickerSaleContainer);
		}

		if (itemLeft.getMostPopularLocaleKey()) {
			const stickerSaleLeft = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerSaleLeft.position.set(174.5, -84.5);
			const saleLabelLeft = new TextField(
				this.localizationStorage.getLocalizedString(itemLeft.getMostPopularLocaleKey()),
				{ font: '26px wendyOne', align: 'center', tint: 0xfff78f },
				55,
				55,
			);
			saleLabelLeft.anchor = 0.5;
			saleLabelLeft.rotation = 0.47;
			saleLabelLeft.position.set(174.5, -84.5);

			const stickerSaleLeftContainer = new PIXI.Container();
			stickerSaleLeftContainer.position.set(-490, -165);
			stickerSaleLeftContainer.addChild(
				stickerSaleLeft,
				saleLabelLeft as PIXI.DisplayObject,
			);
			this.addChild(stickerSaleLeftContainer);
		}
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = `${this.localizationStorage.getLocalizedString('#bank_bundle_time_remain')} ${text}`;
	}

	public setButtonBuyEnabled(value: boolean): void {
		this.buttonBuyLeft.interactive = value;
		this.buttonBuyLeft.alpha = value ? 1 : 0.5;

		this.buttonBuyRight.interactive = value;
		this.buttonBuyRight.alpha = value ? 1 : 0.5;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
