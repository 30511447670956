/* eslint-disable no-console */
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowSkillsViewController implements CheatWindowViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;

	constructor(
		networkRequestSender: NetworkRequestSender,
	) {
		this.networkRequestSender = networkRequestSender;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_SKILL_RESET, this.onSkillResetServer, this);
		view.on(CheatWindowView.EVENT_SKILL_RESET_COOLDOWN, this.onSkillResetCooldownServer, this);
	}

	private onSkillResetServer(): void {
		console.log('Resetting skills server...');
		this.networkRequestSender.sendCheatResetSkills().then(() => {
			console.log('Resetting skills server OK');
		});
	}

	private onSkillResetCooldownServer(): void {
		console.log('Resetting skill cooldowns server...');
		this.networkRequestSender.sendCheatResetSkillsCd().then(() => {
			console.log('Resetting skill cooldowns server OK');
		});
	}
}
