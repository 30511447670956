import { TopUIPanelBaseView } from './TopUIPanelBaseView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { BusinessModel } from '@models/BusinessModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { EventLevelPanelView } from '../eventProgressPanel/EventLevelPanelView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { CardMiniViewFactory } from '../cardsMini/CardMiniViewFactory';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { AbstractReward } from '@interfaces/AbstractReward';
import * as TWEEN from '@tweenjs/tween.js';

export class EventLevelTopUIPanelView extends TopUIPanelBaseView {
	public static readonly EVENT_BUTTON_GOAL_REWARD_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CUP_CLICK: symbol = Symbol();

	private eventLevelPanelView: EventLevelPanelView;

	constructor(
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		businessModels: BusinessModel[],
		skillModels: Map<string, SkillModel>,
		gameProfileModel: GameProfileModel,
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
	) {
		super(
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			businessModels,
			skillModels,
			gameProfileModel,
		);

		this.eventLevelPanelView = new EventLevelPanelView(
			eventLevelModel,
			cardMiniViewFactory,
			runInEpisodeAssetsSandbox,
		);
		this.eventLevelPanelView.position.set(73, 44);
		this.eventLevelPanelView.on(
			EventLevelPanelView.EVENT_BUTTON_CUP_CLICK,
			() => this.emit(EventLevelTopUIPanelView.EVENT_BUTTON_CUP_CLICK),
			this,
		);
		this.eventLevelPanelView.on(
			EventLevelPanelView.EVENT_GOAL_REWARD_CLICK,
			() => this.emit(EventLevelTopUIPanelView.EVENT_BUTTON_GOAL_REWARD_CLICK),
			this,
		);

		this.addChild(
			this.eventLevelPanelView,
		);
	}

	public setNewGoalRewardWithAnimation(
		reward: AbstractReward,
	): Promise<void> {
		return this.eventLevelPanelView.setNewGoalRewardWithAnimation(
			reward,
		);
	}

	public onZoomInStarted(): void {
		super.onZoomInStarted();

		new TWEEN.Tween({ alpha: 1 })
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.eventLevelPanelView.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.eventLevelPanelView.visible = false;
			})
			.start();
	}

	public onZoomOutStarted(): void {
		super.onZoomOutStarted();

		this.eventLevelPanelView.visible = true;

		new TWEEN.Tween({ alpha: 0 })
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.eventLevelPanelView.alpha = tweenTarget.alpha;
			})
			.start();
	}

	public getEventGoalRewardIconContainer(): PIXI.Container {
		return this.eventLevelPanelView.getEventGoalRewardIconContainer();
	}

	public getEventIncomeProgressBarContainer(): PIXI.Container {
		return this.eventLevelPanelView.getEventIncomeProgressBarContainer();
	}

	public getButtonRankingWindowContainer(): PIXI.Container {
		return this.eventLevelPanelView.getButtonRankingWindowContainer();
	}
}
