export class LocalizationSupportConfig {
	public static readonly FIELD_LANUAGE = 'Lang';
	public static readonly FIELD_LAUNAGE_ISO = 'tag';

	private readonly supportLanguageMap: Map<string, string>;

	constructor(config: Record<string, string>[]) {
		this.supportLanguageMap = new Map();

		config.forEach((configElement) => {
			const language = configElement[LocalizationSupportConfig.FIELD_LANUAGE].trim();
			const languageIso = configElement[LocalizationSupportConfig.FIELD_LAUNAGE_ISO].trim();
			this.supportLanguageMap.set(languageIso, language);
		});
	}

	public getSupportLanuageMap(): Map<string, string> {
		return this.supportLanguageMap;
	}

	public isLanguageSupported(languageIso: string): boolean {
		return this.supportLanguageMap.has(languageIso);
	}
}
