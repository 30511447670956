import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';

export class RankingPlayerInfoView extends PIXI.Container {
	private readonly playerIconBg: PIXI.Sprite;
	private readonly playerIconCup: PIXI.Sprite;
	private readonly textUsername: SizeableBitmapText;
	private readonly textLeaguePlace: SizeableBitmapText;

	constructor() {
		super();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		this.playerIconBg = new PIXI.Sprite(uiAtlas['simple_circle_ranked']);
		this.playerIconCup = new PIXI.Sprite(uiAtlas['simple_cup_icon']);

		this.textUsername = new SizeableBitmapText(
			(''),
			600,
			{ font: '32px wendyOneShadowBold', align: 'left', tint: 0xffdb6d },
		);
		this.textUsername.anchor = new PIXI.Point(0, 0.5);
		this.textUsername.x = 64;

		const playerRankedBg = new PIXI.mesh.NineSlicePlane(uiAtlas['red_panel'], 15, 0, 15, 0);
		playerRankedBg.tint = 0x000000;
		playerRankedBg.width = 130;
		playerRankedBg.pivot.set(playerRankedBg.width / 2, playerRankedBg.height / 2);
		playerRankedBg.x = -40;

		this.textLeaguePlace = new SizeableBitmapText(
			'',
			600,
			{ font: '32px wendyOneShadowBold' },
		);
		this.textLeaguePlace.anchor = 0.5;
		this.textLeaguePlace.x = -70;
		this.textLeaguePlace.tint = 0xbfccd6;

		this.addChild(
			playerRankedBg,
			this.textUsername as PIXI.DisplayObject,
			this.textLeaguePlace,
			this.playerIconBg,
			this.playerIconCup,
		);
	}

	public setLeaguePlace(place: number): void {
		// eslint-disable-next-line default-case
		switch (place) {
			case 1: {
				this.playerIconBg.texture = AssetsStorage.getAtlas('uiAtlas')['gold_circle_ranked'];
				this.playerIconCup.texture = AssetsStorage.getAtlas('uiAtlas')['gold_cup_icon'];
				this.textLeaguePlace.tint = 0x0f8c744;
				break;
			}
			case 2: {
				this.playerIconBg.texture = AssetsStorage.getAtlas('uiAtlas')['silver_circle_ranked'];
				this.playerIconCup.texture = AssetsStorage.getAtlas('uiAtlas')['silver_cup_icon'];
				this.textLeaguePlace.tint = 0xabb4c7;
				break;
			}
			case 3: {
				this.playerIconBg.texture = AssetsStorage.getAtlas('uiAtlas')['bronze_circle_ranked'];
				this.playerIconCup.texture = AssetsStorage.getAtlas('uiAtlas')['bronze_cup_icon'];
				this.textLeaguePlace.tint = 0xffae53;
				break;
			}
		}
	}

	public setTextLeaguePlace(value: string): void {
		this.textLeaguePlace.text = value;
	}

	public setUsername(value: string): void {
		this.textUsername.text = value;
	}
}
