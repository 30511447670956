import { TotemConfig } from '@configs/TotemConfig';
import { PromotableModel } from '@models/PromotableModel';
import { TotemSaveData } from '@src/types/SaveTypes';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';

export class TotemModel extends PromotableModel {
	private readonly affect: string;

	constructor(
		config: TotemConfig,
		promotePatternsConfig: AbstractPromotePatternsConfig,
	) {
		super(
			config,
			promotePatternsConfig,
		);

		this.affect = config.getAffectTarget();
	}

	public setFromSaveData(saveData: TotemSaveData): void {
		this.opened = true;
		this.level = saveData.level;
		this.cardsCount = saveData.cardsCount;
		this.newItem = saveData.isNew;

		this.recalculateBonuses();
	}

	public getAffectTarget(): string {
		return this.affect;
	}

	public isAllBusinessAffected(): boolean {
		return this.affect === 'all';
	}
}
