import { UpgradeModel } from '@models/UpgradeModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableBaseCardView, PromotableClickData } from './PromotableBaseCardView';

export class UpgradeCardView extends PromotableBaseCardView<UpgradeModel> {
	constructor(
		model: UpgradeModel,
		autoUpdateProgressBar?: boolean,
	) {
		super(
			model,
			autoUpdateProgressBar,
		);

		const upgradeKey = model.getKey();
		const cardRarity = model.getCardRarity();

		const upgradePictureMask = new PIXI.Graphics();
		upgradePictureMask.beginFill(0x000000);
		upgradePictureMask.drawPolygon([
			new PIXI.Point(250, -368),
			new PIXI.Point(250, 368),
			new PIXI.Point(-250, 368),
			new PIXI.Point(-250, -368),
		]);
		upgradePictureMask.y = -42;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('upgradeIconAtlas')[`big_${upgradeKey}`]);
		icon.mask = upgradePictureMask;
		this.addChildAt(icon, 0);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`upgrade_${model.getCardRarity()}_card_bg`]);
		bg.y = -42;
		bg.mask = upgradePictureMask;
		this.addChildAt(bg, 0);

		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${cardRarity}_bg_round`], 3, 3, 3, 3);
		background.width = 540;
		background.height = 780;
		background.pivot.set(background.width / 2, background.height / 2);
		background.position.set(0, upgradePictureMask.y);

		const blackOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		blackOutline.width = 544;
		blackOutline.height = 783;
		blackOutline.pivot.set(blackOutline.width / 2, blackOutline.height / 2);
		blackOutline.tint = 0x00000;
		blackOutline.position.set(0, upgradePictureMask.y);

		this.addChildAt(upgradePictureMask, 0);
		this.addChildAt(background, 0);
		this.addChildAt(blackOutline, 0);

		this.progressBar.y = 389;
	}

	public getOnClickData(): PromotableClickData {
		return { model: this.model.getKey() };
	}
}
