import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { Emitter } from 'pixi-particles';

export class FarewellPartyProgressView extends PIXI.Container {
	public static readonly EVENT_TRAIL_ANIMATION_COMPLETED: symbol = Symbol();
	private static readonly BAR_WIDTH: number = 801;
	private progressBar: PIXI.mesh.NineSlicePlane;
	private lightProgressBar: PIXI.mesh.NineSlicePlane;
	private tweenProgressBar: TWEEN.Tween;
	private previousProgress: number;

	private static glow1(): any {
		return {
			alpha: {
				start: 1,
				end: 0.46,
			},
			scale: {
				start: 0.5,
				end: 0.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe88c',
				end: '#ffc70f',
			},
			speed: {
				start: 200,
				end: 50,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: -50,
				y: 10,
			},
			maxSpeed: 200,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.0005,
			emitterLifetime: 0.01,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 10,
			},
		};
	}

	private static glow2(): any {
		return {
			alpha: {
				start: 0.5,
				end: 0,
			},
			scale: {
				start: 0.3,
				end: 1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#fff7ad',
				end: '#ffd382',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: 60,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.2,
			maxParticles: 10,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	private static trail(): any {
		return {
			autoUpdate: true,
			alpha: {
				start: 0.8,
				end: 0.01,
			},
			scale: {
				start: 0.2,
				end: 0.01,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#e3f9ff',
				end: '#2196F3',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.6,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.0008,
			emitterLifetime: -1,
			maxParticles: 5000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;
	private readonly animationEmitters: Emitter[];
	private readonly multiplierText: PIXI.extras.BitmapText;

	constructor() {
		super();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
		this.animationEmitters = [];
		this.previousProgress = 0;

		const progressBarBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('farewellPartyUiAtlas')['orgy_progress_bar_bg'], 4, 3, 4, 3);
		progressBarBg.width = FarewellPartyProgressView.BAR_WIDTH;
		progressBarBg.height = 33;
		progressBarBg.pivot.set(progressBarBg.width / 2, progressBarBg.height / 2);

		const progressBarMask: PIXI.Graphics = new PIXI.Graphics();
		progressBarMask.beginFill(1);
		progressBarMask.drawRect(0, 0, progressBarBg.width - 6, progressBarBg.height - 4);
		progressBarMask.endFill();
		progressBarMask.pivot.set(progressBarMask.width / 2, progressBarMask.height / 2);

		const lightProgressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('farewellPartyUiAtlas')['orgy_progress_bar_light'], 0, 0, 39, 0);
		lightProgressBar.width = 0;
		lightProgressBar.height = 27;
		lightProgressBar.pivot.set(FarewellPartyProgressView.BAR_WIDTH / 2, lightProgressBar.height / 2);
		lightProgressBar.mask = progressBarMask;
		this.lightProgressBar = lightProgressBar;

		const progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('farewellPartyUiAtlas')['orgy_progress_bar'], 0, 0, 39, 0);
		progressBar.width = 0;
		progressBar.height = lightProgressBar.height;
		progressBar.pivot.copy(lightProgressBar.pivot);
		progressBar.mask = progressBarMask;
		this.progressBar = progressBar;

		this.multiplierText = new PIXI.extras.BitmapText('', { font: '42px wendyOneGold' });
		this.multiplierText.anchor = new PIXI.Point(0, 0.5);
		this.multiplierText.position.set(409, -1);

		this.addChild(
			progressBarBg,
			lightProgressBar,
			progressBar,
			this.multiplierText as PIXI.DisplayObject,
			progressBarMask,
		);
	}

	public setNextMultiplier(value: number): void {
		this.multiplierText.text = `x${value}`;
	}

	public playCompleteAnimation(): void {
		this.playBurstAnimation();
		this.playTrailAnimation();
	}

	private playTrailAnimation(): void {
		const trailEmitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow1']).texture,
			],
			FarewellPartyProgressView.trail(),
		);
		trailEmitter.autoUpdate = true;
		this.animationEmitters.push(trailEmitter);

		const tweenDestroyTrail = new TWEEN.Tween(this, this.tweenGroup)
			.to({}, 200)
			.onComplete(() => {
				trailEmitter.destroy();
			});

		new TWEEN.Tween(new PIXI.Point(this.multiplierText.x, this.multiplierText.y), this.tweenGroup)
			.to({ x: [500, 832], y: [200, 30] }, 1000)
			.interpolation(TWEEN.Interpolation.Bezier)
			.onUpdate((tweenTarget) => {
				trailEmitter.updateOwnerPos(tweenTarget.x, tweenTarget.y);
			})
			.onComplete(() => {
				this.emit(FarewellPartyProgressView.EVENT_TRAIL_ANIMATION_COMPLETED);
			})
			.chain(tweenDestroyTrail)
			.start();
	}

	private playBurstAnimation(): void {
		const burst1Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow1']).texture,
			],
			FarewellPartyProgressView.glow1(),
		);
		burst1Emitter.autoUpdate = true;
		burst1Emitter.playOnceAndDestroy();
		burst1Emitter.updateSpawnPos(this.multiplierText.x, this.multiplierText.y);
		this.animationEmitters.push(burst1Emitter);

		const burst2Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['pary_glow_2']).texture,
			],
			FarewellPartyProgressView.glow2(),
		);
		burst2Emitter.autoUpdate = true;
		burst2Emitter.updateSpawnPos(this.multiplierText.x, this.multiplierText.y);
		burst2Emitter.playOnceAndDestroy();
		this.animationEmitters.push(burst2Emitter);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setProgress(progress: number): void {
		this.removeProgressAnimation();

		const targetWidth = progress * FarewellPartyProgressView.BAR_WIDTH;
		this.progressBar.width = targetWidth;
		this.lightProgressBar.width = targetWidth;
	}

	public startProgressAnimation(progress: number): void {
		this.removeProgressAnimation();

		if (this.previousProgress > progress) {
			this.progressBar.width = 0;
			this.lightProgressBar.width = 0;
		}

		this.previousProgress = progress;


		const targetWidth = progress * FarewellPartyProgressView.BAR_WIDTH;
		const progressBarWidthDeltaPercent = targetWidth > 0 ? this.progressBar.width / targetWidth : 1;

		const duration = 300 * progressBarWidthDeltaPercent;
		this.tweenProgressBar = new TWEEN.Tween(this.progressBar, this.tweenGroup)
			.to({ width: targetWidth }, duration)
			// .easing(TWEEN.Easing.Sinusoidal.Out)
			.onComplete(() => {
				this.tweenProgressBar = null;
			})
			.start();

		this.lightProgressBar.width = targetWidth;
	}

	private removeProgressAnimation(): void {
		if (this.tweenProgressBar) {
			this.tweenGroup.remove(this.tweenProgressBar);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
