import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';

export class RankingPlayerIncomeInfoView extends PIXI.Container {
	private readonly incomeLabel: SizeableBitmapText;

	constructor() {
		super();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		const incomeBg = new PIXI.mesh.NineSlicePlane(uiAtlas['red_panel'], 15, 0, 15, 0);
		incomeBg.tint = 0x000000;
		incomeBg.alpha = 0.4;
		incomeBg.width = 300;
		incomeBg.pivot.set(incomeBg.width / 2, incomeBg.height / 2);

		const incomeIcon = new PIXI.Sprite(uiAtlas['i_money_big']);
		incomeIcon.x = incomeBg.x - incomeBg.width / 2 + 10;
		incomeIcon.scale.set(0.71);

		this.incomeLabel = new SizeableBitmapText(
			'',
			600,
			{ font: '34px wendyOneShadowBold', align: 'left', tint: 0xffdb6d },
		);
		this.incomeLabel.anchor = 0.5;
		this.incomeLabel.position.set(12, -2);

		this.addChild(
			incomeBg as PIXI.DisplayObject,
			incomeIcon,
			this.incomeLabel,
		);
	}

	public setTextIncome(value: string): void {
		this.incomeLabel.text = `${value}/sec`;
	}
}
