import { AbstractRewardCards } from '@interfaces/AbstractRewardCards';
import { TotemModel } from '@models/TotemModel';
import { RewardTypes } from '@src/types/RewardTypes';

export class RewardTotemCards extends AbstractRewardCards<TotemModel> {
	constructor(
		totem: TotemModel,
		quantity: number,
	) {
		super(totem, RewardTypes.TOTEM, quantity);
	}

	public collect(): void {
		if (!this.model.isOpened()) {
			this.model.open();
		}

		this.model.addCards(this.quantity);
	}
}
