import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40007View } from '@views/characters/events/event4/EventCharacter40007View';
import { EventCharacter40008View } from '@views/characters/events/event4/EventCharacter40008View';
import { EventCharacter40009View } from '@views/characters/events/event4/EventCharacter40009View';

export class EventBusiness40003View extends BusinessBaseView {
	constructor() {
		const eventCharacter40007View = new EventCharacter40007View();
		const eventCharacter40008View = new EventCharacter40008View();
		const eventCharacter40009View = new EventCharacter40009View();

		super('business40003', [eventCharacter40007View, eventCharacter40008View, eventCharacter40009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter40007View as PIXI.DisplayObject,
			eventCharacter40008View,
			eventCharacter40009View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
