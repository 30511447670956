import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { RewardResourceIdTypes } from '@src/types/RewardTypes';
import { BankBundleModel } from '@models/bank/BankBundleModel';

export abstract class BankOfferWindowPackBodyView extends PIXI.Container {
	private bankTabElementWithOffer: BankTabElementWithOfferType;

	constructor(
		bankTabElementWithOffer: BankTabElementWithOfferType,
	) {
		super();

		this.bankTabElementWithOffer = bankTabElementWithOffer;
	}

	protected getSpecialBonusLocalizationKey(): string {
		if (this.bankTabElementWithOffer instanceof BankBundleModel) {
			switch (this.bankTabElementWithOffer.getExtraRewardId()) {
				case RewardResourceIdTypes.HARD_MONEY:
					return '#special_gem_bonus_value_label';

				case RewardResourceIdTypes.PRESTIGE_MONEY:
					return '#special_prestige_bonus_value_label';

				default:
					throw new Error(`Unknow reward '${this.bankTabElementWithOffer.getExtraRewardId()}'`);
			}
		} else {
			throw new Error(`Unsupported bankTabElementWithOffer instance '${this.bankTabElementWithOffer}'`);
		}
	}
}
