import { QuestUIBaseView } from './QuestUIBaseView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { AssetsStorage } from '@main/AssetsStorage';
import { QuestUIHelper } from './QuestUIHelper';

export class QuestUIView extends QuestUIBaseView<AbstractQuest> {
	private static readonly KEY_DEFAULT_BACKGROUND_TEXTURE: string = 'quest_icon_bg';

	constructor() {
		const background = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')[QuestUIView.KEY_DEFAULT_BACKGROUND_TEXTURE],
			17, 0, 21, 0,
		);
		background.width = 240;
		background.pivot.set(background.width / 2, background.height / 2);

		super(background);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getTextDescription(quest: AbstractQuest): string {
		return QuestUIHelper.getQuestDescription(quest);
	}

	protected setBackgroundTexture(): void {
		(this.buttonBg as PIXI.mesh.NineSlicePlane).texture = this.isBossBackgroundTexture()
			? AssetsStorage.getAtlas('uiAtlas')['boss_quest_icon_bg']
			: AssetsStorage.getAtlas('uiAtlas')[QuestUIView.KEY_DEFAULT_BACKGROUND_TEXTURE];
	}
}
