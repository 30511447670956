import { SlotView } from '@views/components/SlotView';
import { CharacterModel } from '@models/CharacterModel';
import { AssetsStorage } from '@main/AssetsStorage';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { UnlockTypes } from '@src/types/UnlockTypes';
import { ModelHelper } from '@models/ModelHelper';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SkillModel } from '@models/skills/SkillModel';

export class CharacterSlotView extends SlotView<CharacterModel> {
	public static readonly EVENT_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_ON_CHARACTER_CART_CLICK: symbol = Symbol();

	private static getSlotIcon(character: CharacterModel): PIXI.Texture {
		const key = character.getKey();
		const pictureId = character.getCurrentPictureId();
		return AssetsStorage.getAtlas('characterCardAtlas')[`${key}_card${pictureId}`];
	}

	constructor(
		character: CharacterModel,
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly currentLevel?: number,
		private readonly skillModel?: SkillModel,
	) {
		super(
			character,
			CharacterSlotView.getSlotIcon(character),
			0.12,
		);

		this.cardContainer.on(ButtonBaseView.EVENT_CLICK, () => this.emit(CharacterSlotView.EVENT_ON_CHARACTER_CART_CLICK), this);
		this.model.on(CharacterModel.EVENT_UPDATE_PICTURE, this.onPictureUpdate, this);
		this.model.on(CharacterModel.EVENT_PROMOTED, this.onCharacterPromoted, this);

		if (this.model.isActivated()) {
			this.addCheck();
			this.removeActionButton();
		} else {
			this.model.once(CharacterModel.EVENT_ACTIVATED, this.onCharacterActivated, this);

			if (!this.model.isOpened()) {
				const unlockInfo = this.model.getUnlockInfo();

				this.model.once(CharacterModel.EVENT_OPENED, this.open, this);

				this.addFade();
				this.addLock();

				if ((unlockInfo.type === UnlockTypes.MIN_LEVEL && !this.model.isUnlockableAtLevel(this.currentLevel))
					|| unlockInfo.type !== UnlockTypes.MIN_LEVEL) {
					this.icon.visible = false;
					this.cardContainer.interactive = false;

					this.addUnknownItem(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_character_card2'], 0.32, 0.5);
				}
			} else {
				this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);

				if (!this.model.isActivateLevelReached()) {
					this.addFade();
					this.addLock();
					this.showInteractiveAreaWithFlyText('#fly_text_update_character_card');

					this.model.on(CharacterModel.EVENT_PROMOTED, this.updateActivateLevelReachedDisplay, this);
				} else if (ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel)) {
					this.addGlow(this.model.getCardRarity());
					this.addCurrentButton();
				} else {
					this.addFade();
					this.addCurrentButton();
				}
			}
		}

		this.updateCardInfo(skillModel);
	}

	private onPictureUpdate(): void {
		const newTexture = CharacterSlotView.getSlotIcon(this.model);
		this.icon.texture = newTexture;
	}

	private open(): void {
		this.icon.visible = true;
		this.cardContainer.interactive = true;
		this.removeUnknownItem();
		this.removeLock();

		if (this.model.isActivateLevelReached()) {
			this.removeFade();
			this.addCurrentButton();
		} else {
			this.addLock();
			this.model.on(CharacterModel.EVENT_PROMOTED, this.updateActivateLevelReachedDisplay, this);
		}

		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);
		this.onSoftMoneyChanged();
	}

	private updateActivateLevelReachedDisplay(): void {
		if (this.model.isActivateLevelReached()) {
			this.removeFade().removeLock().addGlow(this.model.getCardRarity());
			this.addCurrentButton();
			this.model.off(CharacterModel.EVENT_PROMOTED, this.updateActivateLevelReachedDisplay, this);
		}
	}

	private onCharacterPromoted(): void {
		this.updateCardInfo(this.skillModel);
		this.updatePromote();
	}

	private onSoftMoneyChanged(): void {
		if (!this.glow && this.model.isActivateLevelReached() && ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel)) {
			this.removeFade();
			this.addGlow(this.model.getCardRarity());
			this.addCurrentButton();
			this.removeLock();
		} else if (this.glow && !ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel)) {
			this.removeGlow();
			this.addFade();
			this.addCurrentButton();
		}
	}

	private addCurrentButton(): void {
		this.addBuyButton(
			this.model.getActivateCost().toString(),
			ModelHelper.isEnoughSoftMoneyForActivate(this.model, this.softMoneyModel),
		);
	}

	private onCharacterActivated(): void {
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyChanged, this);
		this.model.off(CharacterModel.EVENT_PROMOTED, this.updateActivateLevelReachedDisplay, this);
		this.removeGlow().addCheck().removeActionButton();
		this.emit(CharacterSlotView.EVENT_ACTIVATED);
	}

	protected getDefaultLockText(): string {
		return this.localizationStorage
			.getLocalizedString('#lvl_up_lock')
			.replace('{value}', this.model.getActivateLevel().toString());
	}

	protected onTranslateText(): void {
		super.onTranslateText();

		if (this.skillModel !== undefined) {
			this.cardInfoText.text = this.localizationStorage.getLocalizedString('#skill');
		}
	}

	public destroy(): void {
		this.model.off(CharacterModel.EVENT_OPENED, this.open, this, true);
		this.model.off(CharacterModel.EVENT_PROMOTED, this.updateActivateLevelReachedDisplay, this);
		this.model.off(CharacterModel.EVENT_ACTIVATED, this.onCharacterActivated, this, true);
		this.model.off(CharacterModel.EVENT_UPDATE_PICTURE, this.onPictureUpdate, this);
		this.model.off(CharacterModel.EVENT_PROMOTED, this.onCharacterPromoted, this);

		super.destroy();
	}
}
