import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import {
	TutorialStepUnlockTypeBaseConfig,
	TutorialStepUnlockTypeGetQuestConfig,
	TutorialStepUnlockTypeQuestCompleteConfig,
	TutorialStepUnlockTypeTutorialStepConfig,
	TutorialStepUnlockTypeIncomeMoneyAppearConfig,
	TutorialStepUnlockTypePrestigeBonusConfig,
	TutorialStepUnlockTypeBusinessAcquiredConfig,
	TutorialStepUnlockTypeCustomersOnBusinessConfig,
	TutorialStepUnlockTypeSummonResultCardsAppearConfig,
	TutorialStepUnlockTypeSummonResultCardsOpenedConfig,
	TutorialStepUnlockTypeCharacterActivatedConfig,
} from './TutorialStepUnlockTypeConfigs';
import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepButtonBuyCustomerData } from './data/TutorialStepButtonBuyCustomerData';
import { TutorialStepBusinessSlotButtonData } from './data/TutorialStepBusinessSlotButtonData';
import { TutorialStepCharacterAnimationData } from './data/TutorialStepCharacterAnimationData';
import { TutorialStepDataBase } from './data/TutorialStepDataBase';
import { TutorialStepButtonQuestData } from './data/TutorialStepButtonQuestData';

export enum TutorialStepGirlPositionTypes {
	RIGHT = 'right',
	LEFT = 'left',
}

export enum TutorialBlockType {
	START = 'start',
	END = 'end',
}

export abstract class TutorialStepBaseConfig implements ConfigSetElement {
	public static FIELD_KEY: string = 'step_id';
	public static FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static FIELD_UNLOCK_TYPE: string = 'unlock_type';
	public static FIELD_LOCALE_KEYS: string = 'locale_keys';
	public static FIELD_GIRL_POSITION: string = 'girl_position';
	public static FIELD_TARGET: string = 'target';
	public static FIELD_SAVE: string = 'save';
	public static FIELD_PARAMETER: string = 'parameter';

	public static TARGET_TYPE_RESOURCE_PANEL = 'resourcePanel';
	public static TARGET_RESOURCE_PANEL_PARAM_PRESTIGE = 'prestige';
	public static TARGET_RESOURCE_PANEL_PARAM_SOFT = 'soft';

	public static TARGET_TYPE_NO_TARGET = '';

	public static TARGET_TYPE_BUTTON_CLOSE_WINDOW = 'closeWindowButton';
	public static TARGET_CLOSE_WINDOW_PARAM_COLLECTIONS = 'collections';
	public static TARGET_CLOSE_WINDOW_PARAM_PROMOTE_WINDOW = 'promoteWindow';

	public static TARGET_TYPE_BUTTON_BANK_OFFER = 'buttonBankOffer';

	public static UNLOCK_TYPE_GET_QUEST = 'getQuest';
	public static UNLOCK_TYPE_QUEST_COMPLETE = 'questComplete';
	public static UNLOCK_TYPE_TUTORIAL_STEP = 'tutorialStep';
	public static UNLOCK_TYPE_PRESTIGE_BONUS = 'prestigeBonus';
	public static UNLOCK_TYPE_INCOME_MONEY_APPEAR = 'incomeMoneyAppear';
	public static UNLOCK_TYPE_POPUP_APPEAR = 'popupAppear';
	public static UNLOCK_TYPE_POPUP_APPEAR_QUEST_WINDOW = 'questWindow';
	public static UNLOCK_TYPE_POPUP_APPEAR_COLLECTIONS_WINDOW = 'collectionsWindow';
	public static UNLOCK_TYPE_POPUP_APPEAR_PARTY_WINDOW = 'partyWindow';
	public static UNLOCK_TYPE_POPUP_APPEAR_BANK_BOOST_CONFIRM_WINDOW = 'bankBoostConfirmWindow';
	public static UNLOCK_TYPE_POPUP_APPEAR_GO_TO_PARTY_WINDOW = 'gotoPartyWindowAppear';
	public static UNLOCK_TYPE_BUSINESS_ACQUIRED = 'businessAcquired';
	public static UNLOCK_TYPE_SUMMON_RESULT_CARDS_APPEARED = 'summonResultCardsAppeared';
	public static UNLOCK_TYPE_SUMMON_RESULT_CARDS_OPENED = 'summonResultCardsOpened';
	public static UNLOCK_TYPE_CUSTOMERS_ON_BUSINESS = 'customersOnBusiness';
	public static UNLOCK_TYPE_CHARACTER_ACTIVATED = 'hireGirl';

	protected readonly key: string;
	protected readonly localeKeys: string[];
	protected readonly girlAnimate: boolean;
	protected readonly girlPosition: TutorialStepGirlPositionTypes;
	protected readonly block: TutorialBlockType;

	protected tapSpriteInverted: boolean;
	protected unlockTypeConfig: TutorialStepUnlockTypeBaseConfig;
	protected target: TutorialStepTargetTypes;
	protected data: TutorialStepDataBase;

	constructor(config: { [key: string]: string }) {
		this.key = config[TutorialStepBaseConfig.FIELD_KEY].trim();

		this.tapSpriteInverted = false;

		if (config[TutorialStepBaseConfig.FIELD_LOCALE_KEYS]) {
			this.localeKeys = config[TutorialStepBaseConfig.FIELD_LOCALE_KEYS]
				.split(',')
				.map((localeKey: string) => localeKey.trim());
		} else {
			this.localeKeys = [];
		}

		if (config[TutorialStepBaseConfig.FIELD_SAVE]) {
			this.block = config[TutorialStepBaseConfig.FIELD_SAVE].trim() as TutorialBlockType;
		}

		if (config[TutorialStepBaseConfig.FIELD_GIRL_POSITION]) {
			const girlPositionFormatted = config[TutorialStepBaseConfig.FIELD_GIRL_POSITION].trim().split(',') as string[];
			this.girlPosition = girlPositionFormatted[0] as TutorialStepGirlPositionTypes;
			this.girlAnimate = girlPositionFormatted.length < 2;
		}
	}

	protected parseTutorialStepUnlockTypes(config: { [key: string]: string }, key: string): void {
		const unlockType = config[TutorialStepBaseConfig.FIELD_UNLOCK_TYPE].trim();
		const unlockValue = config[TutorialStepBaseConfig.FIELD_UNLOCK_VALUE].trim();

		// eslint-disable-next-line default-case
		switch (unlockType) {
			case TutorialStepBaseConfig.UNLOCK_TYPE_GET_QUEST: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeGetQuestConfig(key, unlockValue);
				break;
			}
			case TutorialStepBaseConfig.UNLOCK_TYPE_QUEST_COMPLETE: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeQuestCompleteConfig(key, unlockValue);
				break;
			}
			case TutorialStepBaseConfig.UNLOCK_TYPE_TUTORIAL_STEP: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeTutorialStepConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_PRESTIGE_BONUS: {
				this.unlockTypeConfig = new TutorialStepUnlockTypePrestigeBonusConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_INCOME_MONEY_APPEAR: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeIncomeMoneyAppearConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR: {
				switch (unlockValue) {
					case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR_QUEST_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_QUEST);
						break;
					}
					case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR_COLLECTIONS_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_COLLECTIONS);
						break;
					}
					case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR_PARTY_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_PARTY);
						break;
					}
					case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR_GO_TO_PARTY_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_PREPARTY);
						break;
					}
					case TutorialStepBaseConfig.UNLOCK_TYPE_POPUP_APPEAR_BANK_BOOST_CONFIRM_WINDOW: {
						this.unlockTypeConfig = new TutorialStepUnlockTypeBaseConfig(key, TutorialStepUnlockTypes.WINDOW_APPEAR_BANK_BOOST_CONFIRM);
						break;
					}
					default:
						throw new Error(`Unsupported popupAppear unlock value '${unlockType}'`);
				}
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_BUSINESS_ACQUIRED: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeBusinessAcquiredConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_SUMMON_RESULT_CARDS_APPEARED: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeSummonResultCardsAppearConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_SUMMON_RESULT_CARDS_OPENED: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeSummonResultCardsOpenedConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_CUSTOMERS_ON_BUSINESS: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeCustomersOnBusinessConfig(key, unlockValue);
				break;
			}

			case TutorialStepBaseConfig.UNLOCK_TYPE_CHARACTER_ACTIVATED: {
				this.unlockTypeConfig = new TutorialStepUnlockTypeCharacterActivatedConfig(key, unlockValue);
				break;
			}

			default:
				throw new Error(`Unsupported unlock type '${unlockType}'`);
		}
	}

	protected parseTutorialStepTargetType(config: { [key: string]: string }): void {
		const target = config[TutorialStepBaseConfig.FIELD_TARGET].trim();
		switch (target) {
			case TutorialStepTargetTypes.BUTTON_BUSINESS_SLOT: {
				this.target = TutorialStepTargetTypes.BUTTON_BUSINESS_SLOT;
				this.data = new TutorialStepBusinessSlotButtonData(config);
				break;
			}
			case TutorialStepTargetTypes.BUTTON_EPIC_QUEST_LOOTBOX:
			case TutorialStepTargetTypes.BUTTON_EVENT:
			case TutorialStepTargetTypes.BUTTON_BUY_BUSINESS:
			case TutorialStepTargetTypes.BUTTON_START_FAREWELL_PARTY:
			case TutorialStepTargetTypes.BUTTON_ZOOM_IN_BUSINESS:
			case TutorialStepTargetTypes.BUTTON_PROMOTE:
			case TutorialStepTargetTypes.BUTTON_SUMMON:
			case TutorialStepTargetTypes.BUTTON_ON_QUEST_WINDOW:
			case TutorialStepTargetTypes.PRESTIGE_BONUS:
			case TutorialStepTargetTypes.INCOME_MONEY:
			case TutorialStepTargetTypes.BUTTON_COLLECTION:
			case TutorialStepTargetTypes.BUTTON_BANK:
			case TutorialStepTargetTypes.BUTTON_BOOST:
			case TutorialStepTargetTypes.BUTTON_GO_TO_NEXT_LEVEL:
			case TutorialStepTargetTypes.BUTTON_SKILL:
			case TutorialStepTargetTypes.BUTTON_BANK_OFFER:
			case TutorialStepTargetTypes.COLLECTION_UPGRADES_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_CHARACTERS_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_ITEM_CHARACTER:
			case TutorialStepTargetTypes.COLLECTION_ITEM_UPGRADE:
			case TutorialStepTargetTypes.SUMMON_BACK_BUTTON:
			case TutorialStepTargetTypes.SUMMON_CARD_BACK:
			case TutorialStepTargetTypes.BANK_TAB_BUTTON:
			case TutorialStepTargetTypes.BANK_TAB_ELEMENT_ACTION_BUTTON:
			case TutorialStepTargetTypes.BANK_BOOST_CONFIRM_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_SUMMON_BUTTON:
			case TutorialStepTargetTypes.LEVEL_PROGRESS_BAR:
			case TutorialStepTargetTypes.EVENT_INCOME_PROGRESS_BAR:
			case TutorialStepTargetTypes.PREPARTY_START_CHARACTER:
			case TutorialStepTargetTypes.PREPARTY_FUCK_POWER:
			case TutorialStepTargetTypes.PREPARTY_FUCK_TIME:
			case TutorialStepTargetTypes.EVENT_GOAL_REWARD_ICON: {
				this.target = target;
				this.data = new TutorialStepDataBase(config);
				break;
			}

			case TutorialStepTargetTypes.CHARACTER_ANIMATION: {
				this.target = TutorialStepTargetTypes.CHARACTER_ANIMATION;
				this.data = new TutorialStepCharacterAnimationData(config);
				break;
			}

			case TutorialStepTargetTypes.BUTTON_BUY_CUSTOMERS: {
				this.target = TutorialStepTargetTypes.BUTTON_BUY_CUSTOMERS;
				this.data = new TutorialStepButtonBuyCustomerData(config);
				break;
			}

			case TutorialStepTargetTypes.BUTTON_QUEST_LINE: {
				this.target = TutorialStepTargetTypes.BUTTON_QUEST_LINE;
				this.data = new TutorialStepButtonQuestData(config);
				break;
			}

			case TutorialStepBaseConfig.TARGET_TYPE_RESOURCE_PANEL: {
				const parameter = config[TutorialStepBaseConfig.FIELD_PARAMETER].trim();
				switch (parameter) {
					case TutorialStepBaseConfig.TARGET_RESOURCE_PANEL_PARAM_PRESTIGE: {
						this.target = TutorialStepTargetTypes.PRESTIGE_MONEY_PANEL;
						this.data = new TutorialStepDataBase(config);
						break;
					}
					case TutorialStepBaseConfig.TARGET_RESOURCE_PANEL_PARAM_SOFT: {
						this.target = TutorialStepTargetTypes.SOFT_MONEY_PANEL;
						this.data = new TutorialStepDataBase(config);
						break;
					}
					default:
						throw new Error(`Unsupported resourcePanel param ${parameter}`);
				}
				break;
			}

			case TutorialStepBaseConfig.TARGET_TYPE_BUTTON_CLOSE_WINDOW: {
				const parameter = config[TutorialStepBaseConfig.FIELD_PARAMETER].trim();
				switch (parameter) {
					case TutorialStepBaseConfig.TARGET_CLOSE_WINDOW_PARAM_COLLECTIONS: {
						this.target = TutorialStepTargetTypes.BUTTON_CLOSE_COLLECTION;
						this.data = new TutorialStepDataBase(config);
						this.tapSpriteInverted = true;
						break;
					}
					case TutorialStepBaseConfig.TARGET_CLOSE_WINDOW_PARAM_PROMOTE_WINDOW: {
						this.target = TutorialStepTargetTypes.BUTTON_CLOSE_PROMOTE_WINDOW;
						this.data = new TutorialStepDataBase(config);
						break;
					}
					default:
						throw new Error(`Unsupported target closeWindowButton param ${parameter}`);
				}
				break;
			}

			case TutorialStepBaseConfig.TARGET_TYPE_NO_TARGET: {
				this.target = TutorialStepTargetTypes.NO_TARGET;
				break;
			}

			case TutorialStepTargetTypes.BUTTON_MAX_CUSTOMERS: {
				this.target = target;
				this.data = new TutorialStepDataBase(config);
				this.tapSpriteInverted = true;
				break;
			}

			default:
				// throw new Error(`Unsupported tutorial target type '${target}'`);
		}
	}

	public getTarget(): TutorialStepTargetTypes {
		return this.target;
	}

	public getData(): TutorialStepDataBase {
		return this.data;
	}

	public isTapSpriteInverted(): boolean {
		return this.tapSpriteInverted;
	}

	public getKey(): string {
		return this.key;
	}

	public getLocaleKeys(): string[] {
		return this.localeKeys;
	}

	public hasGirlPosition(): boolean {
		return this.girlPosition !== undefined;
	}

	public getGirlPosition(): TutorialStepGirlPositionTypes {
		return this.girlPosition;
	}

	public isGirlAnimate(): boolean {
		return this.girlAnimate;
	}

	public getUnlockTypeConfig(): TutorialStepUnlockTypeBaseConfig {
		return this.unlockTypeConfig;
	}

	public hasBlock(): boolean {
		return this.block !== undefined;
	}

	public getBlock(): TutorialBlockType {
		return this.block;
	}
}
