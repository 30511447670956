import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { BusinessOpenToBuyUIView } from '@views/businesses/ui/openToBuy/BusinessOpenToBuyUIView';
import { TutorialStepButtonBuyCustomerData } from '@configs/tutorialSteps/data/TutorialStepButtonBuyCustomerData';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { TutorialStepBusinessSlotButtonData } from '@configs/tutorialSteps/data/TutorialStepBusinessSlotButtonData';
import { TutorialStepCharacterAnimationData } from '@configs/tutorialSteps/data/TutorialStepCharacterAnimationData';
import { TutorialStepButtonQuestData } from '@configs/tutorialSteps/data/TutorialStepButtonQuestData';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { BusinessBaseViewsSetter, GameUIBaseViewSetter, PrepartyStartCharacterViewSetter } from '@interfaces/ViewSetters';
import { CollectionWindowTabs, CollectionWindowTabKeys } from '@views/windows/collection/CollectionWindowView';
import { TutorialStepDataBase } from '@configs/tutorialSteps/data/TutorialStepDataBase';
import { BankTabTypes } from '@src/types/BankTypes';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';

export class TutorialStepWindowViewTargetBaseDispatcher implements BusinessBaseViewsSetter, GameUIBaseViewSetter, PrepartyStartCharacterViewSetter {
	private static readonly FREE_SUMMON_KEY: string = 'free';

	protected levelBusinesses: Map<string, BusinessBaseView>;
	protected gameUIBase: GameUIBaseView;
	protected prepartyStartCharacterView: PrepartyStartCharacterView;
	protected summonModelsPool: SummonModelsPool;
	protected windowViewSystem: WindowViewSystem;
	protected hintSpawner: ShowHintSpawner;

	constructor(
		windowViewSystem: WindowViewSystem,
		hintSpawner: ShowHintSpawner,
		summonModelsPool: SummonModelsPool,
	) {
		this.windowViewSystem = windowViewSystem;
		this.levelBusinesses = new Map();
		this.summonModelsPool = summonModelsPool;
		this.hintSpawner = hintSpawner;
	}

	public setBusinessBaseViews(levelBusinesses: BusinessBaseView[]): void {
		levelBusinesses.forEach(levelBusiness => this.levelBusinesses.set(levelBusiness.getKey(), levelBusiness));
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUIBase = view;
	}

	public setPrepartyStartChracterView(view: PrepartyStartCharacterView): void {
		this.prepartyStartCharacterView = view;
	}

	public dispatchViewTarget(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const targetType = tutorialStep.getTargetType();
		switch (targetType) {
			case TutorialStepTargetTypes.NO_TARGET: {
				return null;
			}
			case TutorialStepTargetTypes.BUTTON_EPIC_QUEST_LOOTBOX: {
				return this.dispatchTargetButtonEpicQuestLootbox(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_BUY_BUSINESS: {
				return this.dispatchTargetButtonBuyBusiness(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_BUY_CUSTOMERS: {
				return this.dispatchTargetButtonBuyCustomers(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_BUSINESS_SLOT: {
				return this.dispatchTargetButtonBusinessSlot(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_ZOOM_IN_BUSINESS: {
				return this.dispatchTargetButtonZoomInBusiness(tutorialStep);
			}
			case TutorialStepTargetTypes.CHARACTER_ANIMATION: {
				return this.dispatchTargetCharacterAnimation(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_QUEST_LINE: {
				return this.dispatchTargetButtonQuest(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_ON_QUEST_WINDOW: {
				return this.dispatchTargetButtonOnQuestWindow();
			}
			case TutorialStepTargetTypes.BUTTON_START_FAREWELL_PARTY: {
				return this.dispatchTargetButtonStartFarewellParty();
			}
			case TutorialStepTargetTypes.PREPARTY_FUCK_POWER: {
				return this.dispatchTargetPrepartyFuckPower();
			}
			case TutorialStepTargetTypes.PREPARTY_FUCK_TIME: {
				return this.dispatchTargetPrepartyFuckTime();
			}
			case TutorialStepTargetTypes.BUTTON_BANK_OFFER: {
				return this.dispatchTargetButtonBankOffer(tutorialStep);
			}
			case TutorialStepTargetTypes.SOFT_MONEY_PANEL: {
				return this.dispatchTargetSoftMoneyPanel();
			}
			case TutorialStepTargetTypes.PRESTIGE_MONEY_PANEL: {
				return this.dispatchTargetPrestigeMoneyPanel();
			}
			case TutorialStepTargetTypes.BUTTON_CLOSE_COLLECTION: {
				return this.dispatchTargetButtonCloseCollection();
			}
			case TutorialStepTargetTypes.BUTTON_CLOSE_PROMOTE_WINDOW: {
				return this.dispatchTargetButtonClosePromoteWindow();
			}
			case TutorialStepTargetTypes.BUTTON_SUMMON: {
				return this.dispatchTargetButtonSummon(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_PROMOTE: {
				return this.dispatchTargetButtonPromote();
			}
			case TutorialStepTargetTypes.INCOME_MONEY: {
				return this.dispatchTargetIncomeMoney(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_MAX_CUSTOMERS: {
				return this.dispatchTargetButtonMaxCustomers();
			}
			case TutorialStepTargetTypes.BUTTON_COLLECTION: {
				return this.dispatchTargetButtonCollection();
			}
			case TutorialStepTargetTypes.BUTTON_BANK: {
				return this.dispatchTargetButtonBank();
			}
			case TutorialStepTargetTypes.BUTTON_BOOST: {
				return this.dispatchTargetButtonBoost();
			}
			case TutorialStepTargetTypes.PRESTIGE_BONUS: {
				return this.dispatchTargetPrestigeBonus(tutorialStep);
			}
			case TutorialStepTargetTypes.BUTTON_SKILL: {
				return this.dispatchTargetButtonSkill(tutorialStep);
			}
			case TutorialStepTargetTypes.COLLECTION_SUMMON_BUTTON: {
				return this.dispatchTargetButtonCollectionCasting();
			}
			case TutorialStepTargetTypes.COLLECTION_CHARACTERS_BUTTON: {
				return this.dispatchTargetButtonCollectionGirls();
			}
			case TutorialStepTargetTypes.COLLECTION_UPGRADES_BUTTON: {
				return this.dispatchTargetButtonCollectionToys();
			}
			case TutorialStepTargetTypes.COLLECTION_ITEM_UPGRADE: {
				return this.dispatchTargetButtonCollectionItemUpgrade(tutorialStep);
			}
			case TutorialStepTargetTypes.COLLECTION_ITEM_CHARACTER: {
				return this.dispatchTargetButtonCollectionItemCharacter(tutorialStep);
			}
			case TutorialStepTargetTypes.SUMMON_BACK_BUTTON: {
				return this.dispatchTargetSummonBackButton();
			}
			case TutorialStepTargetTypes.SUMMON_CARD_BACK: {
				return this.dispatchTargetSummonCardBack();
			}
			case TutorialStepTargetTypes.BANK_TAB_BUTTON: {
				return this.dispatchTargetBankTabButton(tutorialStep);
			}
			case TutorialStepTargetTypes.BANK_TAB_ELEMENT_ACTION_BUTTON: {
				return this.dispatchTargetBankTabElementActionButton(tutorialStep);
			}
			case TutorialStepTargetTypes.BANK_BOOST_CONFIRM_BUTTON: {
				return this.dispatchTargetBankBoostConfirmButton();
			}
			case TutorialStepTargetTypes.PREPARTY_START_CHARACTER: {
				return this.dispatchTargetPrepartyStartCharacter();
			}

			default:
				throw new Error(`Unsupported target type '${targetType}'`);
		}
	}

	private dispatchTargetButtonEpicQuestLootbox(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const target = this.windowViewSystem.getCollectionWindow().getEpicQuestLooboxButton(data.getStringParameter() as CollectionWindowTabKeys);

		return target;
	}

	private dispatchTargetButtonBuyBusiness(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const targetBusiness = this.levelBusinesses.get(data.getStringParameter());
		const targetButton = (targetBusiness.getCurrentUIView() as BusinessOpenToBuyUIView).getButtonBuyBusiness();

		return targetButton;
	}

	private dispatchTargetButtonBuyCustomers(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepButtonBuyCustomerData;
		const targetBusiness = this.levelBusinesses.get(data.getBusinessKey());
		const targetButton = (targetBusiness.getCurrentUIView() as BusinessAcquiredUIView).getButtonBuyCustomersContainer();

		return targetButton;
	}

	private dispatchTargetButtonBusinessSlot(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepBusinessSlotButtonData;
		const targetBusiness = this.levelBusinesses.get(data.getBusinessKey());
		const slot = (targetBusiness.getCurrentUIView() as BusinessAcquiredUIView)
			.getManageUIView()
			.getBusinessSlots()
			.getSlot(data.getTargetKey());

		return slot.getBuyButton();
	}

	private dispatchTargetButtonZoomInBusiness(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const targetBusiness = this.levelBusinesses.get(data.getStringParameter());
		const targetButton = (targetBusiness.getCurrentUIView() as BusinessAcquiredUIView).getBusinessIconContainer();

		return targetButton;
	}

	private dispatchTargetCharacterAnimation(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepCharacterAnimationData;
		const targetBusiness = this.levelBusinesses.get(data.getBusinessKey());
		const targetCharacter = targetBusiness.getCharacter(data.getCharacterKey());

		return targetCharacter;
	}

	private dispatchTargetButtonQuest(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepButtonQuestData;
		const lineId = data.getQuestLineId();
		const targetButton = this.gameUIBase.getQuestLines().getLineView(lineId);

		return targetButton;
	}

	private dispatchTargetButtonOnQuestWindow(): PIXI.Container {
		const targetButton = this.windowViewSystem.getQuestWindow().getActionButton();
		return targetButton;
	}

	private dispatchTargetButtonStartFarewellParty(): PIXI.Container {
		const targetButton = this.windowViewSystem.getPrepartyWindow().getButtonStart();
		return targetButton;
	}

	private dispatchTargetPrepartyFuckPower(): PIXI.Container {
		const target = this.windowViewSystem.getPrepartyWindow().getFuckPowerContainer();
		return target;
	}

	private dispatchTargetPrepartyFuckTime(): PIXI.Container {
		const target = this.windowViewSystem.getPrepartyWindow().getFuckTimeContainer();
		return target;
	}

	private dispatchTargetPrestigeMoneyPanel(): PIXI.Container {
		const targetButton = this.gameUIBase.getPrestigeMoneyPanel().getBackgroundContainer();
		return targetButton;
	}

	private dispatchTargetSoftMoneyPanel(): PIXI.Container {
		const targetButton = this.gameUIBase.getSoftMoneyPanel().getMoneyContainer();
		return targetButton;
	}

	private dispatchTargetButtonCloseCollection(): PIXI.Container {
		const targetButton = this.windowViewSystem.getCollectionWindow().getButtonCloseContainer();
		return targetButton;
	}

	private dispatchTargetButtonSummon(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		let summonKey: string = data.getStringParameter();
		if (summonKey === TutorialStepWindowViewTargetBaseDispatcher.FREE_SUMMON_KEY) {
			const model = this.summonModelsPool.getModelsList().find(x => x.isFreePrice());
			summonKey = model.getKey();
		}
		const targetButton = this.windowViewSystem.getCollectionWindow().getSummonCollectionView().getButtonSummonContainer(summonKey);
		return targetButton;
	}

	private dispatchTargetButtonPromote(): PIXI.Container {
		const targetButton = this.windowViewSystem.getPromoteWindow().getPromoteButton();
		return targetButton;
	}

	private dispatchTargetIncomeMoney(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const targetBusiness = this.levelBusinesses.get(data.getStringParameter());
		const target = (targetBusiness.getCurrentUIView() as BusinessAcquiredUIView).getIncomeMoneyContainer();
		return target;
	}

	private dispatchTargetButtonMaxCustomers(): PIXI.Container {
		const targetButton = this.gameUIBase.getButtonMaxCustomersView();
		return targetButton;
	}

	private dispatchTargetButtonCollection(): PIXI.Container {
		const targetButton = this.gameUIBase.getButtonCollectionContainer();
		return targetButton;
	}

	private dispatchTargetButtonBank(): PIXI.Container {
		const targetButton = this.gameUIBase.getButtonBankContainer();
		return targetButton;
	}

	private dispatchTargetButtonBoost(): PIXI.Container {
		const targetButton = this.gameUIBase.getButtonBoostContainer();
		return targetButton;
	}

	private dispatchTargetPrestigeBonus(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const targetBusiness = this.levelBusinesses.get(data.getStringParameter());
		const targetButton = (targetBusiness.getCurrentUIView() as BusinessAcquiredUIView).getPrestigeBonusContainer();

		return targetButton;
	}

	private dispatchTargetButtonSkill(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const targetButton = this.gameUIBase.getSkillsPanelView().getSkillView(data.getStringParameter());

		return targetButton;
	}

	private dispatchTargetButtonCollectionToys(): PIXI.Container {
		const collectionWindow = this.windowViewSystem.getCollectionWindow();
		const targetButton = collectionWindow.getTabButtonContainer(CollectionWindowTabs.TAB_UPGRADES);

		return targetButton;
	}

	private dispatchTargetButtonCollectionGirls(): PIXI.Container {
		const collectionWindow = this.windowViewSystem.getCollectionWindow();
		const targetButton = collectionWindow.getTabButtonContainer(CollectionWindowTabs.TAB_CHARACTERS);

		return targetButton;
	}

	private dispatchTargetButtonCollectionCasting(): PIXI.Container {
		const collectionWindow = this.windowViewSystem.getCollectionWindow();
		const targetButton = collectionWindow.getTabButtonContainer(CollectionWindowTabs.TAB_SUMMON);

		return targetButton;
	}

	private dispatchTargetButtonCollectionItemCharacter(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;

		const collectionWindow = this.windowViewSystem.getCollectionWindow();
		const targetButton = collectionWindow.getOpenenItemCharacterContainer(data.getStringParameter());

		return targetButton;
	}

	private dispatchTargetButtonCollectionItemUpgrade(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;

		const collectionWindow = this.windowViewSystem.getCollectionWindow();
		const targetButton = collectionWindow.getOpenedItemUpgradeContainer(data.getStringParameter());

		return targetButton;
	}

	private dispatchTargetSummonBackButton(): PIXI.Container {
		const summonResultWindow = this.windowViewSystem.getSummonResultWindowView();
		const targetButton = summonResultWindow.getBackButtonContainer();

		return targetButton;
	}

	private dispatchTargetSummonCardBack(): PIXI.Container {
		const summonResultWindow = this.windowViewSystem.getSummonResultWindowView();
		const targetButton = summonResultWindow.getFirstCardBackContainer();

		return targetButton;
	}

	private dispatchTargetBankTabButton(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;

		const bankWindow = this.windowViewSystem.getBankWindow();
		const targetButton = bankWindow.getTabButtonContainer(data.getStringParameter() as BankTabTypes);

		return targetButton;
	}

	private dispatchTargetPrepartyStartCharacter(): PIXI.Container {
		return this.prepartyStartCharacterView.getInteractiveObject();
	}

	private dispatchTargetBankTabElementActionButton(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;

		const bankWindow = this.windowViewSystem.getBankWindow();
		const targetButton = bankWindow.getTabElementActionButtonContainer(data.getStringParameter());

		return targetButton;
	}

	private dispatchTargetBankBoostConfirmButton(): PIXI.Container {
		const targetButton = this.windowViewSystem
			.getBankBoostConfirmWindow()
			.getActiveButton();

		return targetButton;
	}

	private dispatchTargetButtonClosePromoteWindow(): PIXI.Container {
		const promoteWindow = this.windowViewSystem.getPromoteWindow();
		const targetButton = promoteWindow.getCloseButtonContainer();
		return targetButton;
	}

	private dispatchTargetButtonBankOffer(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const data = tutorialStep.getData() as TutorialStepDataBase;
		const offerLinesUI = this.gameUIBase.getBankOfferLinesUIView();
		return offerLinesUI.getBankOfferContainerByKey(data.getStringParameter());
	}
}
