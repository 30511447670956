import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80010View } from '@views/characters/events/event8/EventCharacter80010View';
import { EventCharacter80011View } from '@views/characters/events/event8/EventCharacter80011View';
import { EventCharacter80012View } from '@views/characters/events/event8/EventCharacter80012View';

export class EventBusiness80004View extends BusinessBaseView {
	constructor() {
		const eventCharacter80010View = new EventCharacter80010View();
		const eventCharacter80011View = new EventCharacter80011View();
		const eventCharacter80012View = new EventCharacter80012View();


		super('business80004', [eventCharacter80010View, eventCharacter80011View, eventCharacter80012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(308, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter80010View as PIXI.DisplayObject,
			eventCharacter80011View,
			eventCharacter80012View,
		);
	}
}
