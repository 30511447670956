import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter51001View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51001View';
import { EventCharacter51002View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51002View';
import { EventCharacter51003View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51003View';

export class EventBusiness51001View extends BusinessBaseView {
	constructor() {
		const eventCharacter51001View = new EventCharacter51001View();
		const eventCharacter51002View = new EventCharacter51002View();
		const eventCharacter51003View = new EventCharacter51003View();
		eventCharacter51003View.once(EventCharacter51003View.EVENT_SHOWN, () => eventCharacter51001View.destroy());

		super('business51001', [eventCharacter51001View, eventCharacter51002View, eventCharacter51003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter51001View as PIXI.DisplayObject,
			eventCharacter51002View,
			eventCharacter51003View,
		);
	}
}
