import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10012View extends CharacterMainBaseView {
	constructor() {
		super('character10012');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(241, 0);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(9, 4),
			bgPosition: new PIXI.Point(5, 20),
			arrowRotation: Math.PI,
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
