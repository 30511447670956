import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter54010View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54010View';
import { EventCharacter54011View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54011View';
import { EventCharacter54012View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54012View';

export class EventBusiness54004View extends BusinessBaseView {
	constructor() {
		const eventCharacter54010View = new EventCharacter54010View();
		const eventCharacter54011View = new EventCharacter54011View();
		const eventCharacter54012View = new EventCharacter54012View();

		super('business54004', [eventCharacter54010View, eventCharacter54011View, eventCharacter54012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter54010View as PIXI.DisplayObject,
			eventCharacter54011View,
			eventCharacter54012View,
		);
	}
}
