import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20011View extends CharacterMainBaseView {
	constructor() {
		super('character20011');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-80, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-175, -50),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-90, -53),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
