import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../bank/animations/BankParticleConfig';
import { TextField } from '@views/components/text/TextField';
import { BaseChallengeListItemView } from './BaseChallengeListItemView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class ChallengeSpecialListItemView extends BaseChallengeListItemView {
	constructor(
		panelBg: PIXI.mesh.NineSlicePlane,
		girls: PIXI.Sprite,
		text: string,
		glowSprite: string,
		lootBoxIcon: string,
		cardsMiniViewFactory: CardMiniViewFactory,
		rewards?: RewardDescriptionType[],
	) {
		super(
			panelBg,
			glowSprite,
			text,
			girls,
			cardsMiniViewFactory,
			rewards,
		);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[lootBoxIcon]);
		icon.position.set(413, -22);
		icon.scale.set(1.1);
		icon.rotation = Math.PI / 180 * -8;

		const animationsContainer = new PIXI.Container();
		animationsContainer.scale.set(0.21, 0.17);
		animationsContainer.mask = this.glowMask;
		animationsContainer.position.set(405, -5);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;


		const glowEmitter2 = new Emitter(
			animationsContainer,
			[fxAtlas['promote_glow3']],
			BankParticleConfig.getPurpleGlow(100, 0.04),
		);
		glowEmitter2.autoUpdate = true;

		const glowEmitter3 = new Emitter(
			animationsContainer,
			[fxAtlas['promote_glow3']],
			BankParticleConfig.getPurpleGlow(-100, 0.04),
		);
		glowEmitter3.autoUpdate = true;
		this.animationEmitters.push(glowEmitter, glowEmitter2, glowEmitter3);

		const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerSale.position.set(526, -80);
		stickerSale.scale.set(0.8);
		const saleLabel = new TextField(
			'SUPER REWARD',
			{ font: '16px wendyOne', align: 'center', tint: 0xfff78f },
			100,
			100,
		);
		saleLabel.anchor = 0.5;
		saleLabel.rotation = 0.47;
		saleLabel.position.set(526, -80);

		this.mainContainer.addChild(
			animationsContainer,
			icon,
			stickerSale,
			saleLabel,
			this.cardsContainer,
		);
	}
}
