import { BaseAction } from '../BaseAction';
import { SoftMoneyNumberRaw } from '@src/utils/SoftMoneyNumber';

export class BusinessAcquireAction extends BaseAction {
	constructor(businessId: string, resourceType: string, resourceValue: string | SoftMoneyNumberRaw, currentResourceValue: string) {
		super('BusinessAcquire');

		this.data.id = businessId;

		const resourcesSpent = Object.create(null);
		resourcesSpent[resourceType] = resourceValue;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.resources_spent = resourcesSpent;

		const resourcesSync = Object.create(null);
		resourcesSync[resourceType] = currentResourceValue;
		this.sync['resources'] = resourcesSync;
	}
}
