import { BusinessModel } from '@models/BusinessModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCustomersOnSome extends AbstractQuest {
	private readonly businesses: BusinessModel[];

	private readonly completedBusinesses: Set<string>;

	private readonly targetAmountInEach: number;
	private readonly targetBusinessesCount: number;

	private currentBusinessesCount: number;

	constructor(
		questKey: string,
		businesses: BusinessModel[],
		amount: number,
		businessesCount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.GET_CUSTOMERS_ON_SOME,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.businesses = businesses;

		this.completedBusinesses = new Set();

		this.targetAmountInEach = amount;
		this.targetBusinessesCount = businessesCount;

		this.currentBusinessesCount = 0;

		this.trackProgressStart();

		businesses.forEach((business) => {
			if (business.isAcquired()) {
				this.updateProgress(business);
			} else {
				business.once(BusinessModel.EVENT_ACQUIRED, this.updateProgress, this);
			}
		});
	}

	// eslint-disable-next-line class-methods-use-this
	public canRestoreSavedState(): boolean {
		return false;
	}

	public getTargetBusinessesCount(): number {
		return this.targetBusinessesCount;
	}

	public getTargetCustomersCount(): number {
		return this.targetAmountInEach;
	}

	public getCompletedBusinessKeys(): Set<string> {
		return this.completedBusinesses;
	}

	public getCurrentProgressValue(): string {
		return this.isCompleted() ? this.getTotalProgressValue() : this.currentBusinessesCount.toString();
	}

	public getTotalProgressValue(): string {
		return this.getTargetBusinessesCount().toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentBusinessesCount / this.getTargetBusinessesCount();
	}

	public updateProgress(model: BusinessModel): void {
		const businessKey = model.getKey();
		if (this.completedBusinesses.has(businessKey)) {
			return;
		}

		if (model.getCustomerCount() >= this.targetAmountInEach) {
			this.currentBusinessesCount += 1;
			this.completedBusinesses.add(businessKey);

			if (this.currentBusinessesCount >= this.targetBusinessesCount) {
				this.complete();
			} else {
				this.emit(AbstractQuest.EVENT_PROGRESS, this);
			}
		}
	}

	public complete(): void {
		this.completedBusinesses.clear();
		super.complete();
	}

	protected trackProgressStop(): void {
		this.businesses.forEach(business => business.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this));
	}

	protected trackProgressStart(): void {
		this.businesses.forEach(business => business.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this));
	}

	private onNewCustomers(model: BusinessModel): void {
		this.updateProgress(model);
	}
}
