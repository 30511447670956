import { AbstractQuest } from './AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';
import { PromotableModel } from '@models/PromotableModel';

export class QuestPromoteTargets<T extends PromotableModel> extends AbstractQuest {
	private readonly targets: T[];
	private readonly targetAmount: number;
	private currentAmount: number;

	constructor(
		questKey: string,
		questType: QuestTypes.PROMOTE_CHARACTERS | QuestTypes.PROMOTE_UPGRADES | QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES,
		targets: T[],
		promoteCount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.targets = targets;

		this.currentAmount = 0;
		this.targetAmount = promoteCount;

		this.trackProgressStart();
	}

	private onSomeTargetPromoted(): void {
		this.currentAmount += 1;

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	protected trackProgressStop(): void {
		this.targets.forEach(target => target.off(PromotableModel.EVENT_PROMOTED, this.onSomeTargetPromoted, this));
	}

	protected trackProgressStart(): void {
		this.targets.forEach(target => target.on(PromotableModel.EVENT_PROMOTED, this.onSomeTargetPromoted, this));
	}
}
