
import { BaseBoostModel } from '@models/BaseBoostModel';

export class BoostsTicker extends PIXI.utils.EventEmitter {
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly boostModels: Map<string, BaseBoostModel>;

	private currentActiveBoosts: Set<BaseBoostModel>;
	private timerTicking: boolean;

	private enabled: boolean;

	constructor(
		boostModels: Map<string, BaseBoostModel>,
		farewellBoostModel: BaseBoostModel,
	) {
		super();

		this.ticker = PIXI.ticker.shared;

		this.boostModels = new Map(boostModels);
		this.boostModels.set(farewellBoostModel.getKey(), farewellBoostModel);
		this.timerTicking = false;

		this.boostModels.forEach(m => m.on(BaseBoostModel.EVENT_ACTIVATED, this.onSomeBoostActivated, this));

		this.currentActiveBoosts = new Set(Array.from(this.boostModels.values()).filter(m => m.isActivated()));

		if (this.currentActiveBoosts.size > 0) {
			this.startTimer();
		}
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;
		if (this.enabled) {
			this.tryStartTimer();
		} else if (this.timerTicking) {
			this.stopTimer();
		}
	}

	private startTimer(): void {
		this.timerTicking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	private stopTimer(): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}

	private tryStartTimer(): void {
		if (!this.timerTicking && this.enabled && this.currentActiveBoosts.size > 0) {
			this.startTimer();
		}
	}

	private onTimerUpdate(): void {
		const currentActiveBoostTimeoutIds: BaseBoostModel[] = [];
		this.currentActiveBoosts.forEach((boost) => {
			const newTimeleft = boost.getTimeleft() - this.ticker.elapsedMS / 1000;
			boost.setTimeleft(Math.max(0, newTimeleft));
			if (newTimeleft <= 0) {
				currentActiveBoostTimeoutIds.push(boost);
			}
		});

		if (currentActiveBoostTimeoutIds.length > 0) {
			currentActiveBoostTimeoutIds.forEach((boost) => {
				this.onSomeBoostTimeout(boost);
			});
		}

		if (this.currentActiveBoosts.size < 0) {
			this.stopTimer();
		}
	}

	private onSomeBoostTimeout(boost: BaseBoostModel): void {
		boost.deactivate();
		this.currentActiveBoosts.delete(boost);
	}

	private onSomeBoostActivated(boost: BaseBoostModel): void {
		if (!this.currentActiveBoosts.has(boost)) {
			this.currentActiveBoosts.add(boost);
		}
		this.tryStartTimer();
	}
}
