import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export type LeagueBotData = {
	botKey: string;
	timeStart: number;
	timeEnd: number;
}

export class EventLevelLeagueConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_NAME: string = 'name';
	public static readonly FIELD_PASS_COST: string = 'pass_cost';
	public static readonly FIELD_BOT_ID: string = 'bot_id';
	public static readonly FIELD_TIME_START: string = 't_start';
	public static readonly FIELD_TIME_END: string = 't_start';

	private readonly key: string;
	private readonly passCost: SoftMoneyNumber;
	private readonly botData: LeagueBotData[];
	private readonly name: string;

	constructor(config: { [key: string]: string }) {
		this.key = config[EventLevelLeagueConfig.FIELD_KEY].trim();

		this.name = config[EventLevelLeagueConfig.FIELD_NAME].trim();

		const passCostRaw = config[EventLevelLeagueConfig.FIELD_PASS_COST]?.trim();
		if (passCostRaw) {
			this.passCost = SoftMoneyNumber.createFromAcronym(passCostRaw);
		}

		const timeStartRaw = config[EventLevelLeagueConfig.FIELD_TIME_START].split(',').map((str: string) => Number(str.trim()));
		const timeEndRaw = config[EventLevelLeagueConfig.FIELD_TIME_END].split(',').map((str: string) => Number(str.trim()));
		this.botData = config[EventLevelLeagueConfig.FIELD_BOT_ID]
			.split(',').map((botKey: string, i: number) => ({
				botKey: botKey.trim(),
				timeStart: timeStartRaw[i],
				timeEnd: timeEndRaw[i],
			}));
	}

	public getKey(): string {
		return this.key;
	}

	public getName(): string {
		return this.name;
	}

	public hasPassCost(): boolean {
		return this.passCost !== undefined;
	}

	public getPassCost(): SoftMoneyNumber {
		return this.passCost;
	}

	public getBotData(): LeagueBotData[] {
		return this.botData;
	}
}
