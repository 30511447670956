import {
	QuestWindowBaseViewSetter,
	BusinessBaseViewsSetter,
	ViewportViewSetter,
	ViewportUIViewSetter,
	PrepartyStartCharacterViewSetter,
} from '@interfaces/ViewSetters';
import { PromotableModel } from '@models/PromotableModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { BusinessModel } from '@models/BusinessModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BusinessBaseView, BusinessUIViewTypes } from '@views/businesses/ui/BusinessBaseView';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { BankModel } from '@models/bank/BankModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { ViewportUIView } from '@views/components/ViewportUIView';
import { ViewportView } from '@views/components/ViewportView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestTypes } from '@src/types/QuestTypes';
import { QuestAcquireBusiness } from '@models/quests/QuestAcquireBusiness';
import { QuestAcquireBusinesses } from '@models/quests/QuestAcquireBusinesses';
import { QuestAutomateBusiness } from '@models/quests/QuestAutomateBusiness';
import { QuestAutomateBusinesses } from '@models/quests/QuestAutomateBusinesses';
import { QuestGetCustomersTarget } from '@models/quests/QuestGetCustomersTarget';
import { QuestGetCustomersMultiplier } from '@models/quests/QuestGetCustomersMultiplier';
import { QuestGetCustomersOnSome } from '@models/quests/QuestGetCustomersOnSome';
import { QuestActivateTarget, QuestActivateTargetType } from '@models/quests/QuestActivateTarget';
import { QuestPromoteTarget } from '@models/quests/QuestPromoteTarget';
import { QuestPromoteTargets } from '@models/quests/QuestPromoteTargets';
import { ShowStepCenterOnBusiness, ShowStepZoomBusiness } from './QuestTargetShowSteps';
import { CollectionWindowTabs } from '@views/windows/collection/CollectionWindowView';
import { CharacterProfileWindow } from '@views/windows/collection/characters/CharacterProfileWindow';
import { UpgradePromoteWindowView } from '@views/windows/collection/upgrades/UpgradePromoteWindowView';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { ModelHelper } from '@models/ModelHelper';
import { BankWindowView, BankWindowViewState } from '@views/windows/bank/BankWindowView';
import { BankTabElementDataConfigNameTypes, BankTabTypes } from '@src/types/BankTypes';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { BusinessOpenToBuyUIView } from '@views/businesses/ui/openToBuy/BusinessOpenToBuyUIView';
import { QuestWindowBaseView } from '@views/windows/quests/QuestWindowBaseView';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { SummonModel } from '@models/SummonModel';
import { QuestGetSoftMoneyFromBusiness } from '@models/quests/QuestGetSoftMoneyFromBusiness';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';

enum ShowQuestTargetParentType {
	VIEWPORT,
	GAMEUI,
}

type ShowQuestTargetViewParams = {
	parentType: ShowQuestTargetParentType;
	targetInteractive: PIXI.Container;
};

type ShowQuestTargetResult = {
	status: 'ok' | 'error',
	message?: string;
}

export class ShowQuestTargetHandler implements
	QuestWindowBaseViewSetter,
	BusinessBaseViewsSetter,
	ViewportViewSetter,
	ViewportUIViewSetter,
	PrepartyStartCharacterViewSetter {
	private static getPromotableWithMaxPromoteCost(
		targets: PromotableModel[],
		prestigeMoneyModel: PrestigeMoneyModel,
	): PromotableModel {
		let maxEnabledCost: number = 0;
		let maxEnabled: PromotableModel;
		let maxCost: number = 0;
		let max: PromotableModel;

		targets.forEach((model) => {
			const cost = model.getPromoteCostPrestige();
			const isEnoughPrestigeMoneyForPromote = prestigeMoneyModel.getValue() >= model.getPromoteCostPrestige();
			if (isEnoughPrestigeMoneyForPromote && model.isEnoughCardsForPromote() && model.isOpened() && cost > maxEnabledCost) {
				maxEnabledCost = cost;
				maxEnabled = model;
			}
			if (model.isOpened() && cost > maxCost) {
				maxCost = cost;
				max = model;
			}
		});
		return maxEnabled || max || targets?.[0];
	}

	private static getBusinessWithMaxAcquireCost(
		targets: BusinessModel[],
		softMoneyValue: SoftMoneyNumber,
	): BusinessModel | undefined {
		let maxEnabledCost: SoftMoneyNumber = SoftMoneyNumber.ZERO;
		let maxEnabled: BusinessModel = targets[0];
		let minCost = targets[0].getAcquireCost();
		let min: BusinessModel = targets[0];

		targets.forEach((model) => {
			const cost = model.getAcquireCost();
			if (softMoneyValue.greaterThanOrEqualTo(cost) && cost.greaterThan(maxEnabledCost)) {
				maxEnabledCost = cost;
				maxEnabled = model;
			}
			if (cost.lessThan(minCost)) {
				minCost = cost;
				min = model;
			}
		});
		return maxEnabled || min || targets?.[0];
	}

	private readonly businessModels: Map<string, BusinessModel>;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly bankModel: BankModel;
	private readonly summonModelsPool: SummonModelsPool;
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly showQuestTargetDuration: number;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;

	private readonly businessViews: Map<string, BusinessBaseView>;

	private prepartyStartCharacterView: PrepartyStartCharacterView;
	private questWindowView: QuestWindowBaseView;
	private viewportUIView: ViewportUIView;
	private viewportView: ViewportView;

	private showQuestTargetView: ShowQuestTargetView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		businessModels: Map<string, BusinessModel>,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		bankModel: BankModel,
		summonModelsPool: SummonModelsPool,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		showQuestTargetDuration: number,
	) {
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.businessModels = businessModels;
		this.characterModels = characterModels;
		this.totemModels = totemModels;
		this.upgradeModels = upgradeModels;
		this.bankModel = bankModel;
		this.showQuestTargetDuration = showQuestTargetDuration;
		this.summonModelsPool = summonModelsPool;
		this.softMoneyModel = softMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;

		this.businessViews = new Map();
	}

	public setViewportView(viewport: ViewportView): void {
		this.viewportView = viewport;
	}

	public setViewportUIView(viewportUI: ViewportUIView): void {
		this.viewportUIView = viewportUI;
	}

	public setBusinessBaseViews(businessViews: BusinessBaseView[]): void {
		this.businessViews.clear();
		businessViews.forEach(businessView => this.businessViews.set(businessView.getKey(), businessView));
	}

	public setPrepartyStartChracterView(view: PrepartyStartCharacterView): void {
		this.prepartyStartCharacterView = view;
	}

	public setQuestWindowBaseView(view: QuestWindowBaseView): void {
		this.questWindowView = view;
		this.questWindowView.on(QuestWindowBaseView.EVENT_BUTTON_GO_CLICK, this.onQuestWindowGoClick, this);
	}

	private onQuestWindowGoClick(quest: AbstractQuest): void {
		const result = this.showQuestTarget(quest);
		switch (result.status) {
			case 'ok': {
				this.questWindowView.close();
				break;
			}
			case 'error': {
				this.questWindowView.showGoButtonError(result.message);
				break;
			}
			default:
				break;
		}
	}

	private addShowQuestTargetView(target: PIXI.Container, parentType: ShowQuestTargetParentType): void {
		if (this.showQuestTargetView) {
			this.showQuestTargetView.destroy();
		}

		this.showQuestTargetView = new ShowQuestTargetView(this.showQuestTargetDuration, target);

		if (parentType === ShowQuestTargetParentType.VIEWPORT) {
			this.viewportView.once(ViewportView.EVENT_ZOOM_IN_STARTED, this.tryDestroyShowQuestTargetView, this);
			this.viewportView.once(ViewportView.EVENT_ZOOM_OUT_STARTED, this.tryDestroyShowQuestTargetView, this);
			this.viewportUIView.addChild(this.showQuestTargetView);
			if (this.viewportView.isZoomed()) {
				this.showQuestTargetView.scale.set(1 / ViewportView.ZOOM_VALUE);
			}
		} else if (parentType === ShowQuestTargetParentType.GAMEUI) {
			target.parent.addChild(this.showQuestTargetView);
		}

		if (target.interactive) {
			target.once('pointertap', this.tryDestroyShowQuestTargetView, this);
		}
	}

	private tryDestroyShowQuestTargetView(): void {
		if (this.showQuestTargetView) {
			this.showQuestTargetView.destroy({ children: true });
			this.showQuestTargetView = null;
		}
	}

	private showQuestTarget(quest: AbstractQuest): ShowQuestTargetResult {
		// eslint-disable-next-line default-case
		switch (quest.getType()) {
			case QuestTypes.ACQUIRE_BUSINESS:
				return this.handleQuestAcquireBusiness(quest as QuestAcquireBusiness);

			case QuestTypes.ACQUIRE_BUSINESSES:
				return this.handleQuestAcquireBusinesses(quest as QuestAcquireBusinesses);

			case QuestTypes.AUTOMATE_BUSINESS:
				return this.handleQuestAutomateBusiness(quest as QuestAutomateBusiness);

			case QuestTypes.AUTOMATE_BUSINESSES:
				return this.handleQuestAutomateBusinesses(quest as QuestAutomateBusinesses);

			case QuestTypes.GET_CUSTOMERS_TARGET:
				return this.handleQuestGetCustomersTarget(quest as QuestGetCustomersTarget);

			case QuestTypes.GET_CUSTOMERS_ALL:
				return this.handleQuestGetCustomersAll();

			case QuestTypes.GET_CUSTOMERS_MULTIPLIER:
				return this.handleQuestGetCustomersMultiplier();

			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT:
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO:
				return this.handleQuestGetCustomerMultiplierTarget(quest as QuestGetCustomersMultiplier);

			case QuestTypes.GET_CUSTOMERS_ON_SOME:
				return this.handleQuestGetCustomersOnSome(quest as QuestGetCustomersOnSome);

			case QuestTypes.ACTIVATE_UPGRADE:
			case QuestTypes.ACTIVATE_CHARACTER:
				return this.handleQuestActivateTarget(quest as QuestActivateTarget<QuestActivateTargetType>);

			case QuestTypes.ACTIVATE_UPGRADES:
				return this.handleQuestActivateUpgrades();

			case QuestTypes.ACTIVATE_CHARACTERS:
				return this.handleQuestActivateCharacters();

			case QuestTypes.ACTIVATE_PARTY:
				return this.handleQuestActivateParty();

			case QuestTypes.PROMOTE_CHARACTER:
			case QuestTypes.PROMOTE_UPGRADE:
				return this.handleQuestPromoteTarget(quest as QuestPromoteTarget<PromotableModel>);

			case QuestTypes.PROMOTE_ALL:
			case QuestTypes.PROMOTE_CHARACTERS:
			case QuestTypes.PROMOTE_UPGRADES:
			case QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES:
				return this.handleQuestPromoteAll(quest as QuestPromoteTargets<PromotableModel>);

			case QuestTypes.GET_PRESTIGE_MONEY:
				return this.handleQuestGetMoney(RewardResourceIdTypes.PRESTIGE_MONEY);
			case QuestTypes.SPEND_PRESTIGE_MONEY:
				return this.handleQuestSpendPrestigeMoney();

			case QuestTypes.GET_HARD_MONEY:
				return this.handleQuestGetMoney(RewardResourceIdTypes.HARD_MONEY);
			case QuestTypes.SPEND_HARD_MONEY:
				return this.handleQuestSpendHardMoney();

			case QuestTypes.GET_CARDS:
				return this.handleQuestGetCards();

			case QuestTypes.GET_SOFT_MONEY:
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS:
				return this.handleQuestGetSoftMoney();

			case QuestTypes.GET_SOFT_MONEY_TUTORIAL:
				return this.handleQuestGetSoftMoneyTutorial();
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS_TUTORIAL:
				return this.handleQuestGetSoftMoneyTutorial(quest as QuestGetSoftMoneyFromBusiness);

			case QuestTypes.SUMMON_ALL:
			case QuestTypes.SUMMON_PAY:
			case QuestTypes.SUMMON_HARD:
				return this.handleQuestSummon();
			case QuestTypes.SUMMON_PRESTIGE:
				return this.handleQuestPrestigeSummon();
		}

		return { status: 'error' };
	}

	private handleQuestActivateParty(): ShowQuestTargetResult {
		let result: ShowQuestTargetResult;

		if (this.prepartyStartCharacterView.visible) {
			this.addShowQuestTargetView(this.prepartyStartCharacterView.getInteractiveObject(), ShowQuestTargetParentType.GAMEUI);
			result = { status: 'ok' };
		} else {
			result = { status: 'error', message: '#orgy_not_started' };
		}
		return result;
	}

	private handleQuestAutomateBusiness(quest: QuestAutomateBusiness): ShowQuestTargetResult {
		this.handleQuestAutomateByTarget(quest.getTargetKey());

		return { status: 'ok' };
	}

	private handleQuestAutomateBusinesses(quest: QuestAutomateBusinesses): ShowQuestTargetResult {
		const targetKey = quest.getBusinessKeyToAcquireNext();

		if (targetKey) {
			this.handleQuestAutomateByTarget(targetKey);
		}

		return { status: 'ok' };
	}

	private handleQuestAutomateByTarget(target: string): void {
		const business = this.businessViews.get(target);
		const characterKeys = this.businessModels.get(target).getCharacterKeys();
		const automateCharacter = characterKeys.map(key => this.characterModels.get(key)).filter(m => m.hasBonusAutomate())[0];
		const centerOnBusiness = new ShowStepCenterOnBusiness(business, this.viewportView);
		const zoomBusiness = new ShowStepZoomBusiness(business);

		centerOnBusiness.start()
			.then(() => zoomBusiness.start())
			.then(() => {
				const params = this.dispatchTargetButtonBusinessSlot(business.getKey(), automateCharacter.getKey());
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
	}

	private handleQuestPromoteAll(quest: QuestPromoteTargets<PromotableModel>): ShowQuestTargetResult {
		let tab: CollectionWindowTabs;
		let targets: PromotableModel[];

		switch (quest.getType()) {
			case QuestTypes.PROMOTE_CHARACTERS: {
				targets = Array.from(this.characterModels.values());
				break;
			}

			case QuestTypes.PROMOTE_UPGRADES: {
				targets = Array.from(this.upgradeModels.values());
				break;
			}

			case QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES: {
				targets = [].concat(
					Array.from(this.characterModels.values()),
					Array.from(this.upgradeModels.values()),
				);
				break;
			}

			case QuestTypes.PROMOTE_ALL: {
				targets = [].concat(
					Array.from(this.characterModels.values()),
					Array.from(this.upgradeModels.values()),
					Array.from(this.totemModels.values()),
				);
				break;
			}

			default:
				throw new Error(`Unsupported quest type '${quest.getType()}`);
		}

		const model: PromotableModel = ShowQuestTargetHandler.getPromotableWithMaxPromoteCost(
			targets,
			this.prestigeMoneyModel,
		);

		if (model instanceof CharacterModel) {
			tab = CollectionWindowTabs.TAB_CHARACTERS;
		} else if (model instanceof UpgradeModel) {
			tab = CollectionWindowTabs.TAB_UPGRADES;
		} else if (model instanceof TotemModel) {
			tab = CollectionWindowTabs.TAB_TOTEMS;
		}

		const window = this.windowFactory.createCollectionWindow(
			{ tab, focusKey: model.getKey() },
		);
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, () => {
			let target: PIXI.Container;

			switch (tab) {
				case CollectionWindowTabs.TAB_CHARACTERS: {
					target = window.getOpenenItemCharacterContainer(model.getKey());
					break;
				}
				case CollectionWindowTabs.TAB_UPGRADES: {
					target = window.getOpenedItemUpgradeContainer(model.getKey());
					break;
				}
				case CollectionWindowTabs.TAB_TOTEMS: {
					target = window.getOpenedItemTotemContainer(model.getKey());
					break;
				}

				default:
					throw new Error(`Unsupported tab ${tab}`);
			}
			this.addShowQuestTargetView(target, ShowQuestTargetParentType.GAMEUI);
		});
		this.windowViewSystem.showWindow(window);

		return { status: 'ok' };
	}

	private handleQuestPromoteTarget(quest: QuestPromoteTarget<PromotableModel>): ShowQuestTargetResult {
		let tab: CollectionWindowTabs;
		if (quest.getType() === QuestTypes.PROMOTE_CHARACTER) {
			const model = this.characterModels.get(quest.getTargetKey());
			tab = CollectionWindowTabs.TAB_CHARACTERS;

			const collectionsWindow = this.windowFactory.createCollectionWindow({ tab });
			const characterProfileWindow = this.windowFactory.createCharacterProfileWindow(model, undefined);
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_CHARACTER_PROFILE_SHOWED, () => {
				characterProfileWindow.once(CharacterProfileWindow.EVENT_OPEN_ANIMATION_COMPLETE, () => {
					const target = characterProfileWindow.getPromoteButton();
					this.addShowQuestTargetView(target, ShowQuestTargetParentType.GAMEUI);
				});
			});
			this.windowViewSystem.showWindow(collectionsWindow);
			this.windowViewSystem.showWindow(characterProfileWindow);
		} else if (quest.getType() === QuestTypes.PROMOTE_UPGRADE) {
			const model = this.upgradeModels.get(quest.getTargetKey());
			tab = CollectionWindowTabs.TAB_UPGRADES;

			const collectionsWindow = this.windowFactory.createCollectionWindow({ tab });
			const upgradePromoteWindow = this.windowFactory.createUpgradePromoteWindow(model);
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_UPGRADE_PROMOTE_SHOWED, () => {
				upgradePromoteWindow.once(UpgradePromoteWindowView.EVENT_OPEN_ANIMATION_COMPLETE, () => {
					const target = upgradePromoteWindow.getPromoteButton();
					this.addShowQuestTargetView(target, ShowQuestTargetParentType.GAMEUI);
				});
			});
			this.windowViewSystem.showWindow(collectionsWindow);
			this.windowViewSystem.showWindow(upgradePromoteWindow);
		}

		return { status: 'ok' };
	}

	private handleQuestSpendPrestigeMoney(): ShowQuestTargetResult {
		let targets: PromotableModel[] = Array.from(this.characterModels.values());
		targets = targets.concat(Array.from(this.upgradeModels.values()), Array.from(this.totemModels.values()));

		const model = ShowQuestTargetHandler.getPromotableWithMaxPromoteCost(targets, this.prestigeMoneyModel);
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, () => {
			let target: PIXI.Container;
			if (model instanceof CharacterModel) {
				target = this.windowViewSystem.getCollectionWindow().getOpenenItemCharacterContainer(model.getKey());
			} else if (model instanceof UpgradeModel) {
				target = this.windowViewSystem.getCollectionWindow().getOpenedItemUpgradeContainer(model.getKey());
			} else if (model instanceof TotemModel) {
				target = this.windowViewSystem.getCollectionWindow().getOpenedItemTotemContainer(model.getKey());
			}
			this.addShowQuestTargetView(target, ShowQuestTargetParentType.GAMEUI);
		});
		let tab: CollectionWindowTabs;
		if (model instanceof CharacterModel) {
			tab = CollectionWindowTabs.TAB_CHARACTERS;
		} else if (model instanceof UpgradeModel) {
			tab = CollectionWindowTabs.TAB_UPGRADES;
		} else if (model instanceof TotemModel) {
			tab = CollectionWindowTabs.TAB_TOTEMS;
		}
		const collectionWindow = this.windowFactory.createCollectionWindow({
			tab,
			focusKey: model.getKey(),
		});
		this.windowViewSystem.showWindow(collectionWindow);

		return { status: 'ok' };
	}

	private handleQuestSpendHardMoney(): ShowQuestTargetResult {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());

		if (model) {
			this.pointAtMaxCostSummon(model);
		}

		return { status: 'ok' };
	}

	private handleQuestAcquireBusiness(quest: QuestAcquireBusiness): ShowQuestTargetResult {
		this.handleAcquireBusinessTarget(quest.getTargetKey());

		return { status: 'ok' };
	}

	private handleQuestAcquireBusinesses(quest: QuestAcquireBusinesses): ShowQuestTargetResult {
		const targetKey = quest.getBusinessKeyToAcquireNext();

		if (targetKey) {
			this.handleAcquireBusinessTarget(targetKey);
		}

		return { status: 'ok' };
	}

	private handleAcquireBusinessTarget(targetKey: string): void {
		const businessView = this.businessViews.get(targetKey);
		const centerOnBusiness = new ShowStepCenterOnBusiness(businessView, this.viewportView);

		centerOnBusiness
			.start()
			.then(() => {
				const params = this.dispatchTargetButtonBuyBusiness(targetKey);
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
	}

	private handleQuestGetCustomersTarget(quest: QuestGetCustomersTarget): ShowQuestTargetResult {
		const businessView = this.businessViews.get(quest.getBusinessKey());
		const centerOnBusiness = new ShowStepCenterOnBusiness(businessView, this.viewportView);
		centerOnBusiness
			.start()
			.then(() => {
				const params = this.dispatchTargetButtonBuyCustomers(quest.getBusinessKey());
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
		return { status: 'ok' };
	}

	private handleQuestGetCustomerMultiplierTarget(quest: QuestGetCustomersMultiplier): ShowQuestTargetResult {
		const targetKey = quest.getTargetKeys()[0];
		const businessView = this.businessViews.get(targetKey);
		const centerOnBusiness = new ShowStepCenterOnBusiness(businessView, this.viewportView);
		centerOnBusiness
			.start()
			.then(() => {
				const params = this.dispatchTargetButtonBuyCustomers(targetKey);
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
		return { status: 'ok' };
	}

	private handleQuestGetCustomersMultiplier(): ShowQuestTargetResult {
		return this.handleQuestGetCustomersAll();
	}

	private handleQuestGetCustomersOnSome(quest: QuestGetCustomersOnSome): ShowQuestTargetResult {
		const completedBusienssKeys = quest.getCompletedBusinessKeys();
		const acquiredBusinessViews = Array.from(this.businessViews.values())
			.filter(view => view.getCurrentUIViewType() === BusinessUIViewTypes.ACQUIRED && !completedBusienssKeys.has(view.getKey()));

		const acquiredBusinessViewsWithEnabledButtons = acquiredBusinessViews
			.filter(view => (view.getCurrentUIView() as BusinessAcquiredUIView).isButtonBuyCustomersEnabled());

		let businessKey: string;
		if (acquiredBusinessViewsWithEnabledButtons.length > 0) {
			businessKey = this.getBusinessWithMaxCustomerCost(acquiredBusinessViewsWithEnabledButtons);
		} else if (acquiredBusinessViews.length > 0) {
			businessKey = this.getBusinessWithMaxCustomerCost(acquiredBusinessViews);
		} else {
			businessKey = ShowQuestTargetHandler.getBusinessWithMaxAcquireCost(
				Array
					.from(this.businessModels.values())
					.filter(model => model.isAvailable() && this.softMoneyModel.getValue().greaterThanOrEqualTo(model.getAcquireCost())),
				this.softMoneyModel.getValue(),
			).getKey();
		}
		const params = this.dispatchTargetButtonBuyCustomers(businessKey);

		const centerOnBusiness = new ShowStepCenterOnBusiness(this.businessViews.get(businessKey), this.viewportView, 400);
		centerOnBusiness
			.start()
			.then(() => {
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
		return { status: 'ok' };
	}

	private handleQuestGetCustomersAll(): ShowQuestTargetResult {
		const acquiredBusinessViews = Array.from(this.businessViews.values()).filter(view => view.getCurrentUIViewType() === BusinessUIViewTypes.ACQUIRED);
		const acquiredBusinessViewsWithEnabledButtons = acquiredBusinessViews
			.filter(view => (view.getCurrentUIView() as BusinessAcquiredUIView).isButtonBuyCustomersEnabled());

		let businessKey: string;
		if (acquiredBusinessViewsWithEnabledButtons.length > 0) {
			businessKey = this.getBusinessWithMaxCustomerCost(acquiredBusinessViewsWithEnabledButtons);
		} else if (acquiredBusinessViews.length > 0) {
			businessKey = this.getBusinessWithMaxCustomerCost(acquiredBusinessViews);
		} else {
			businessKey = ShowQuestTargetHandler.getBusinessWithMaxAcquireCost(
				Array
					.from(this.businessModels.values())
					.filter(model => model.isAvailable() && this.softMoneyModel.getValue().greaterThanOrEqualTo(model.getAcquireCost())),
				this.softMoneyModel.getValue(),
			).getKey();
		}
		const params = this.dispatchTargetButtonBuyCustomers(businessKey);

		const centerOnBusiness = new ShowStepCenterOnBusiness(this.businessViews.get(businessKey), this.viewportView, 400);
		centerOnBusiness
			.start()
			.then(() => {
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
		return { status: 'ok' };
	}

	private handleQuestActivateTarget(quest: QuestActivateTarget<QuestActivateTargetType>): ShowQuestTargetResult {
		const business = this.businessViews.get(quest.getTargetBusinessKey());
		const centerOnBusiness = new ShowStepCenterOnBusiness(business, this.viewportView);
		const zoomBusiness = new ShowStepZoomBusiness(business);
		centerOnBusiness
			.start()
			.then(() => zoomBusiness.start())
			.then(() => {
				const params = this.dispatchTargetButtonBusinessSlot(business.getKey(), quest.getTargetKey());
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			});
		return { status: 'ok' };
	}

	private handleQuestGetCards(): ShowQuestTargetResult {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());

		if (model) {
			this.pointAtMaxCostSummon(model);
		}

		return { status: 'ok' };
	}

	private handleQuestSummon(): ShowQuestTargetResult {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());

		if (model) {
			this.pointAtMaxCostSummon(model);
		}

		return { status: 'ok' };
	}

	private handleQuestPrestigeSummon(): ShowQuestTargetResult {
		const model = ModelHelper.getSummonModelWithMaxPrestigeCost(this.summonModelsPool.getModelsList());

		if (model) {
			this.pointAtMaxCostSummon(model);
		}

		return { status: 'ok' };
	}

	private pointAtMaxCostSummon(model: SummonModel): void {
		const collectionsWindow = this.windowFactory.createCollectionWindow({
			tab: CollectionWindowTabs.TAB_SUMMON,
			focusKey: model.getKey(),
		});
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, () => {
			const summonCollectionWindow = collectionsWindow.getSummonCollectionView();

			summonCollectionWindow.on(SummonCollectionView.EVENT_INITED, () => {
				const target = summonCollectionWindow.getButtonSummonContainer(model.getKey());
				this.addShowQuestTargetView(target, ShowQuestTargetParentType.GAMEUI);
			});
		});
		this.windowViewSystem.showWindow(collectionsWindow);
	}

	private handleQuestGetSoftMoney(): ShowQuestTargetResult {
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, (bankWindow: BankWindowView) => {
			const addShowQuestTargetToBank = (): void => {
				const gemShopItems = Array.from(this.bankModel
					.getTabElementConfigNameDataMap()
					.get(BankTabElementDataConfigNameTypes.GEM_SHOP).values());
				const timeskips = gemShopItems
					.filter(item => (item as BankGemShopItemModel).getRewardDescription().reward_type === RewardTypes.TIMESKIP);
				if (timeskips && timeskips.length - 1 > 0) {
					const key = (timeskips[timeskips.length - 1] as BankGemShopItemModel).getViewKey();
					const targetInteractive = bankWindow.getTabElementActionButtonContainer(key);
					this.addShowQuestTargetView(targetInteractive, ShowQuestTargetParentType.GAMEUI);
				}
			};
			// eslint-disable-next-line default-case
			switch (bankWindow.getCurrentState()) {
				case BankWindowViewState.PENDING_INIT: {
					bankWindow.once(BankWindowView.EVENT_UPDATED, () => addShowQuestTargetToBank(), this);
					break;
				}
				case BankWindowViewState.INITED: {
					addShowQuestTargetToBank();
					break;
				}
			}
		});
		const bankWindow = this.windowFactory.createBankWindow({ tab: BankTabTypes.BOOSTERS });
		this.windowViewSystem.showWindow(bankWindow);

		return { status: 'ok' };
	}

	private handleQuestGetSoftMoneyTutorial(quest?: QuestGetSoftMoneyFromBusiness): ShowQuestTargetResult {
		const businessKey = quest != null ? quest.getBusinessKey() : 'business1';
		const business = this.businessModels.get(businessKey);

		if (business) {
			const activatedCharacters = business
				.getCharacterKeys()
				.map(key => this.characterModels.get(key))
				.filter(character => character.isActivated());

			if (activatedCharacters && activatedCharacters.length > 0) {
				const params = this.dispatchTargetCharacterAnimation(businessKey, activatedCharacters[0].getKey());
				this.addShowQuestTargetView(params.targetInteractive, params.parentType);
			}
		}

		return { status: 'ok' };
	}

	private handleQuestActivateUpgrades(): ShowQuestTargetResult {
		const upgradeModels = Array
			.from(this.upgradeModels.values())
			.filter((m) => {
				const relatedBusinessAquired = this.businessModels.get(m.getRelatedBusinessKey())?.isAcquired();
				const isEnoughSoftMoneyForActivate = ModelHelper.isEnoughSoftMoneyForActivate(m, this.softMoneyModel);
				const activateAvailable = !m.isActivated() && !m.isLocked() && isEnoughSoftMoneyForActivate;
				return relatedBusinessAquired && activateAvailable;
			})
			.sort((a, b) => b.getActivateCost().getBigNumber().comparedTo(a.getActivateCost().getBigNumber()));
		return this.handleQuestActivateTargets(upgradeModels);
	}

	private handleQuestActivateCharacters(): ShowQuestTargetResult {
		const characterModels = Array.from(this.characterModels.values())
			.filter((m) => {
				const relatedBusinessAquired = this.businessModels.get(m.getRelatedBusinessKey())?.isAcquired();
				const activateAvailable = !m.isActivated() && m.isOpened();

				return relatedBusinessAquired && activateAvailable;
			});

		characterModels.sort((a, b) => b.getActivateCost().getBigNumber().comparedTo(a.getActivateCost().getBigNumber()));

		this.handleQuestActivateTargets(characterModels);

		return { status: 'ok' };
	}

	private handleQuestActivateTargets(targets: UpgradeModel[] | CharacterModel[]): ShowQuestTargetResult {
		if (targets.length > 0) {
			const target = targets[0];
			const businessView = this.businessViews.get(target.getRelatedBusinessKey());
			const zoomBusiness = new ShowStepZoomBusiness(businessView);
			zoomBusiness
				.start()
				.then(() => {
					const params = this.dispatchTargetButtonBusinessSlot(businessView.getKey(), target.getKey());
					this.addShowQuestTargetView(params.targetInteractive, params.parentType);
				});
		} else {
			const businesses = Array.from(this.businessModels.values());
			const business = businesses.find((model) => !model.isAcquired());

			this.handleAcquireBusinessTarget(business.getKey());
		}

		return { status: 'ok' };
	}

	private handleQuestGetMoney(resource: RewardResourceIdTypes): ShowQuestTargetResult {
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, (bankWindow: BankWindowView) => {
			const addShowQuestTargetToBank = (): void => {
				const gemShopItems = Array.from(this.bankModel
					.getTabElementConfigNameDataMap()
					.get(BankTabElementDataConfigNameTypes.GEM_SHOP).values());
				const gemShopPrestigeItems = gemShopItems.filter((gemShopItem) => {
					const rewardDescription = (gemShopItem as BankGemShopItemModel).getRewardDescription();
					return rewardDescription.reward_type === RewardTypes.RESOURCE && rewardDescription.reward_id === resource;
				});
				if (gemShopPrestigeItems && gemShopPrestigeItems.length - 1 > 0) {
					const key = (gemShopPrestigeItems[gemShopPrestigeItems.length - 1] as BankGemShopItemModel).getViewKey();
					const targetInteractive = this.windowViewSystem.getBankWindow().getTabElementActionButtonContainer(key);
					this.addShowQuestTargetView(targetInteractive, ShowQuestTargetParentType.GAMEUI);
				}
			};
			// eslint-disable-next-line default-case
			switch (bankWindow.getCurrentState()) {
				case BankWindowViewState.PENDING_INIT: {
					bankWindow.once(BankWindowView.EVENT_UPDATED, () => addShowQuestTargetToBank(), this);
					break;
				}
				case BankWindowViewState.INITED: {
					addShowQuestTargetToBank();
					break;
				}
			}
		});
		const bankWindow = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(bankWindow);

		return { status: 'ok' };
	}

	private dispatchTargetButtonBuyBusiness(businessKey: string): ShowQuestTargetViewParams {
		const businessView = this.businessViews.get(businessKey);
		const targetInteractive = (businessView.getCurrentUIView() as BusinessOpenToBuyUIView).getButtonBuyBusiness();
		return {
			parentType: ShowQuestTargetParentType.VIEWPORT,
			targetInteractive,
		};
	}

	private dispatchTargetButtonBuyCustomers(businessKey: string): ShowQuestTargetViewParams {
		let targetInteractive: PIXI.Container;

		const businessView = this.businessViews.get(businessKey);
		const currentUIView = businessView.getCurrentUIView();
		if (currentUIView instanceof BusinessAcquiredUIView) {
			targetInteractive = currentUIView.getButtonBuyCustomersContainer();
		} else if (currentUIView instanceof BusinessOpenToBuyUIView) {
			targetInteractive = currentUIView.getButtonBuyBusiness();
		} else {
			throw new Error('Invalid business ui state');
		}
		return {
			parentType: ShowQuestTargetParentType.VIEWPORT,
			targetInteractive,
		};
	}

	private dispatchTargetButtonBusinessSlot(businessKey: string, slotKey: string): ShowQuestTargetViewParams {
		const businessView = this.businessViews.get(businessKey);
		const currentUIView = businessView.getCurrentUIView();
		let targetInteractive;

		if (businessView.getCurrentUIView() instanceof BusinessAcquiredUIView) {
			const slot = (businessView.getCurrentUIView() as BusinessAcquiredUIView)
				.getManageUIView()
				.getBusinessSlots()
				.getSlot(slotKey);

			targetInteractive = slot.getBuyButton() || slot.getLockString();
		} else if (currentUIView instanceof BusinessOpenToBuyUIView) {
			targetInteractive = currentUIView.getButtonBuyBusiness();
		} else {
			throw new Error('Invalid business UI state');
		}

		return {
			parentType: ShowQuestTargetParentType.VIEWPORT,
			targetInteractive,
		};
	}

	private dispatchTargetCharacterAnimation(businessKey: string, characterKey: string): ShowQuestTargetViewParams {
		let targetInteractive;

		const businessView = this.businessViews.get(businessKey);
		const currentUIView = businessView.getCurrentUIView();
		if (businessView.getCurrentUIView() instanceof BusinessAcquiredUIView) {
			targetInteractive = this.businessViews.get(businessKey).getCharacter(characterKey);
		} else if (currentUIView instanceof BusinessOpenToBuyUIView) {
			targetInteractive = currentUIView.getButtonBuyBusiness();
		} else {
			throw new Error('Invalid business ui state');
		}

		return {
			parentType: ShowQuestTargetParentType.VIEWPORT,
			targetInteractive,
		};
	}

	private getBusinessWithMaxCustomerCost(targetBusinesses: BusinessBaseView[]): string {
		let targetBusinessKey = '';
		let currentMaxCustomerCost: SoftMoneyNumber = SoftMoneyNumber.ZERO;
		targetBusinesses.forEach((view) => {
			const key = view.getKey();
			const customerCost = this.businessModels.get(key).getOneCustomerCostSoft();
			if (customerCost.greaterThan(currentMaxCustomerCost)) {
				targetBusinessKey = key;
				currentMaxCustomerCost = customerCost;
			}
		});
		return targetBusinessKey;
	}
}
