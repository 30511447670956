import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30007View } from '@views/characters/events/event3/EventCharacter30007View';
import { EventCharacter30008View } from '@views/characters/events/event3/EventCharacter30008View';
import { EventCharacter30009View } from '@views/characters/events/event3/EventCharacter30009View';

export class EventBusiness30003View extends BusinessBaseView {
	constructor() {
		const eventCharacter30007View = new EventCharacter30007View();
		const eventCharacter30008View = new EventCharacter30008View();
		const eventCharacter30009View = new EventCharacter30009View();

		super('business30003', [eventCharacter30007View, eventCharacter30008View, eventCharacter30009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b9_decor1']).setTransform(71.5, -31, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor2']).setTransform(-78.5, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor3']).setTransform(137, 66.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor5']).setTransform(0, -74, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter30009View,
			eventCharacter30007View as PIXI.DisplayObject,
			eventCharacter30008View,
		);
	}
}
