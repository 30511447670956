import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter80004View extends CharacterMainBaseView {
	constructor() {
		super('character80004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(166, 5);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(169, -51),
			bgPosition: new PIXI.Point(165, -35),
			arrowRotation: Math.PI,
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
