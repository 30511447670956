import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BusinessConfig } from '@configs/BusinessConfig';
import CharacterConfig from '@src/configs/CharacterConfig';
import { SkillConfig } from '@src/configs/SkillConfig';
import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { ContentUnlockLevelConfig, LevelConfig } from '@configs/level/LevelConfig';
import { CharactersConfigSetParser } from '@configs/configsParsers/configSets/CharactersConfigSetParser';
import { LevelsConfigSetParser } from '@configs/configsParsers/configSets/LevelsConfigSetParser';
import { SkillsConfigSetParser } from '@configs/configsParsers/configSets/SkillsConfigSetParser';
import { DialogsConfigSetParser } from '@configs/configsParsers/configSets/DialogsConfigSetParser';
import { QuestsConfigSetParser } from '@configs/configsParsers/configSets/QuestsConfigSetParser';
import { FarewellPartyConfigSetParser } from '@configs/configsParsers/configSets/FarewellPartyConfigSetParser';
import { FarewellPartyConfig } from '@configs/FarewellPartyConfig';
import { TotemsConfigSetParser } from '@configs/configsParsers/configSets/TotemsConfigSetParser';
import { VideoConfigSetParser } from '@configs/configsParsers/configSets/VideoConfigSetParser';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { TotemConfig } from '@configs/TotemConfig';
import { EpicQuestCollectionsConfigSetParser } from '@configs/configsParsers/configSets/EpicQuestCollectionsConfigSetParser';
import { TutorialStepLevelConfig } from '@configs/tutorialSteps/TutorialStepLevelConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { BoostsConfigSetParser } from '@configs/configsParsers/configSets/BoostsConfigSetParser';
import { BoostConfig } from '@configs/BoostConfig';
import { TimeskipsConfigSetParser } from '@configs/configsParsers/configSets/TimeskipsConfigSetParser';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { PromotePatternsConfig } from '@configs/PromotePatternsConfig';
import { MainWindowConfig, MainWindowTarget, MainWindowElementConfig } from '@configs/MainWindowConfig';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { LevelConfigNames } from '@src/types/LevelConfigNames';
import { LootboxesConfigSetParser } from '@configs/configsParsers/configSets/LootboxesConfigSetParser';
import { LootboxConfig } from '@configs/LootboxConfig';
import { UnlockTypes } from '@src/types/UnlockTypes';
import { ContentUnlockLevelTypes } from '@src/types/ContentUnlockLevelTypes';
import { AbstractPromotableEntityConfig } from '../AbstractPromotableEntityConfig';
import { ConfigsParserBase } from './ConfigsParserBase';
import { DialogConfig } from '@configs/DialogConfig';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { VideoConfig } from '@configs/VideoConfig';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';
import { TutorialStepsLevelConfigSetParser } from './configSets/TutorialStepsLevelConfigSetParser';
import { BusinessesConfigSetParser } from './configSets/BusinessesConfigSetParser';
import { UpgradesConfigSetParser } from './configSets/UpgradesConfigSetParser';
import { DailyRewardConfig } from '@configs/DailyRewardConfig';
import { DailyRewardConfigSetParser } from './configSets/DailyRewardConfigSetParser';
import { PresetConfig } from '@configs/PresetConfig';
import { PresetsConfigSetParser } from './configSets/PresetsConfigSetParser';

export class LevelConfigsParser extends ConfigsParserBase {
	private readonly baseConfigSetMetadata: {
		controller: BaseConfigSetParser<ConfigSetElement>;
		name: string;
	}[];

	private readonly businessesConfigSetParser: BusinessesConfigSetParser;
	private readonly upgradesConfigSetParser: UpgradesConfigSetParser;
	private readonly levelsConfigSetParser: LevelsConfigSetParser;
	private readonly skillsConfigSetParser: SkillsConfigSetParser;
	private readonly dialogsConfigSetParser: DialogsConfigSetParser;
	private readonly questsConfigSetParser: QuestsConfigSetParser;
	private readonly farewellPartyConfigSetParser: FarewellPartyConfigSetParser;
	private readonly totemsConfigSetParser: TotemsConfigSetParser;
	private readonly videoConfigSetParser: VideoConfigSetParser;
	private readonly epicQuestCollectionsSetController: EpicQuestCollectionsConfigSetParser;
	private readonly tutorialStepsConfigSetParser: TutorialStepsLevelConfigSetParser;
	private readonly boostsConfigSetParser: BoostsConfigSetParser;
	private readonly timeskipsConfigSetParser: TimeskipsConfigSetParser;
	private readonly lootboxesConfigSetParser: LootboxesConfigSetParser;
	private readonly charactersConfigSetParser: CharactersConfigSetParser;
	private readonly dailyRewardConfigSetParser: DailyRewardConfigSetParser;
	private readonly presetsConfigSetParser: PresetsConfigSetParser;

	private constantsConfig: ConstantsConfig;

	private mainWindowConfig: MainWindowConfig;
	private gameUIMainWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>;
	private levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>;

	private promotePatternsConfig: PromotePatternsConfig;

	private bankPriceItemsConfig: BankPriceItemsConfig;

	private localizationSupportConfig: LocalizationSupportConfig;

	constructor() {
		super();

		this.businessesConfigSetParser = new BaseConfigSetParser(BusinessConfig.FIELD_KEY, BusinessConfig);
		this.charactersConfigSetParser = new BaseConfigSetParser(CharacterConfig.FIELD_KEY, CharacterConfig);
		this.farewellPartyConfigSetParser = new BaseConfigSetParser(FarewellPartyConfig.FIELD_KEY, FarewellPartyConfig);
		this.skillsConfigSetParser = new BaseConfigSetParser(SkillConfig.FIELD_KEY, SkillConfig);
		this.levelsConfigSetParser = new BaseConfigSetParser(LevelConfig.FIELD_LEVEL_ID, LevelConfig);
		this.dialogsConfigSetParser = new DialogsConfigSetParser(DialogConfig.FIELD_KEY, DialogConfig);
		this.upgradesConfigSetParser = new BaseConfigSetParser(UpgradeConfig.FIELD_KEY, UpgradeConfig);
		this.questsConfigSetParser = new QuestsConfigSetParser();
		this.totemsConfigSetParser = new BaseConfigSetParser(TotemConfig.FIELD_KEY, TotemConfig);
		this.videoConfigSetParser = new BaseConfigSetParser(VideoConfig.FIELD_KEY, VideoConfig);
		this.epicQuestCollectionsSetController = new EpicQuestCollectionsConfigSetParser();
		this.tutorialStepsConfigSetParser = new BaseConfigSetParser(TutorialStepLevelConfig.FIELD_KEY, TutorialStepLevelConfig);
		this.boostsConfigSetParser = new BaseConfigSetParser(BoostConfig.FIELD_KEY, BoostConfig);
		this.timeskipsConfigSetParser = new BaseConfigSetParser(TimeskipConfig.FIELD_KEY, TimeskipConfig);
		this.lootboxesConfigSetParser = new BaseConfigSetParser(LootboxConfig.FIELD_KEY, LootboxConfig);
		this.dailyRewardConfigSetParser = new DailyRewardConfigSetParser();
		this.presetsConfigSetParser = new BaseConfigSetParser(PresetConfig.FIELD_KEY, PresetConfig);

		this.baseConfigSetMetadata = [
			{ controller: this.businessesConfigSetParser, name: LevelConfigNames.CONFIG_BUSINESSES },
			{ controller: this.skillsConfigSetParser, name: LevelConfigNames.CONFIG_SKILLS },
			{ controller: this.charactersConfigSetParser, name: LevelConfigNames.CONFIG_CHARACTERS },
			{ controller: this.dialogsConfigSetParser, name: LevelConfigNames.CONFIG_DIALOGS },
			{ controller: this.upgradesConfigSetParser, name: LevelConfigNames.CONFIG_UPGRADES },
			{ controller: this.questsConfigSetParser, name: LevelConfigNames.CONFIG_QUESTS },
			{ controller: this.levelsConfigSetParser, name: LevelConfigNames.CONFIG_SEASONS },
			{ controller: this.farewellPartyConfigSetParser, name: LevelConfigNames.CONFIG_FAREWELL_PARTY },
			{ controller: this.totemsConfigSetParser, name: LevelConfigNames.CONFIG_TOTEMS },
			{ controller: this.videoConfigSetParser, name: LevelConfigNames.CONFIG_VIDEO },
			{ controller: this.epicQuestCollectionsSetController, name: LevelConfigNames.CONFIG_EPIC_QUESTS_COLLECTIONS },
			{ controller: this.tutorialStepsConfigSetParser, name: LevelConfigNames.CONFIG_TUTORIAL_STEPS },
			{ controller: this.boostsConfigSetParser, name: LevelConfigNames.CONFIG_BOOSTS },
			{ controller: this.timeskipsConfigSetParser, name: LevelConfigNames.CONFIG_TIMESKIPS },
			{ controller: this.lootboxesConfigSetParser, name: LevelConfigNames.CONFIG_LOOTBOXES },
			{ controller: this.dailyRewardConfigSetParser, name: LevelConfigNames.CONFIG_DAILY_REWARDS },
			{ controller: this.presetsConfigSetParser, name: LevelConfigNames.CONFIG_PRESETS },
		];
	}

	public parseConfigs(data: { [key: string]: any }): void {
		this.parseConstantsConfig(data);
		this.parseBaseConfigs(data);
		this.parseBankPriceItemsConfig(data);
		this.parsePromotePatternsConfig(data);
		this.parseMainWindowConfig(data);
		this.parseLocalizationSupportConfig(data);

		this.onConfigsParsed();
	}

	public getBusinessesConfigs(): Map<string, BusinessConfig> {
		return this.businessesConfigSetParser.getConfigSet();
	}

	public getUpgradesConfigs(): Map<string, UpgradeConfig> {
		return this.upgradesConfigSetParser.getConfigSet();
	}

	public getFarewellPartiesConfigs(): Map<string, FarewellPartyConfig> {
		return this.farewellPartyConfigSetParser.getConfigSet();
	}

	public getCharactersConfigs(): Map<string, CharacterConfig> {
		return this.charactersConfigSetParser.getConfigSet();
	}

	public getLevelsConfigs(): Map<string, LevelConfig> {
		return this.levelsConfigSetParser.getConfigSet();
	}

	public getSkillsConfigs(): Map<string, SkillConfig> {
		return this.skillsConfigSetParser.getConfigSet();
	}

	public getDialogsConfigs(): Map<string, DialogConfig> {
		return this.dialogsConfigSetParser.getConfigSet();
	}

	public getQuestsConfigs(): Map<string, QuestConfigBase> {
		return this.questsConfigSetParser.getConfigSet();
	}

	public getTotemsConfigs(): Map<string, TotemConfig> {
		return this.totemsConfigSetParser.getConfigSet();
	}

	public getVideosConfigs(): Map<string, VideoConfig> {
		return this.videoConfigSetParser.getConfigSet();
	}

	public getEipcQuestCollectionsConfigs(): Map<string, EpicQuestCollectionsConfig> {
		return this.epicQuestCollectionsSetController.getConfigSet();
	}

	public getTutorialStepsConfigs(): Map<string, TutorialStepLevelConfig> {
		return this.tutorialStepsConfigSetParser.getConfigSet();
	}

	public getBoostsConfigs(): Map<string, BoostConfig> {
		return this.boostsConfigSetParser.getConfigSet();
	}

	public getTimeskipsConfigs(): Map<string, TimeskipConfig> {
		return this.timeskipsConfigSetParser.getConfigSet();
	}

	public getDailyRewardConfigs(): Map<string, DailyRewardConfig> {
		return this.dailyRewardConfigSetParser.getConfigSet();
	}

	public getPresetsConfigs(): Map<string, PresetConfig> {
		return this.presetsConfigSetParser.getConfigSet();
	}

	public getConstantsConfig(): ConstantsConfig {
		return this.constantsConfig;
	}

	public getPromotePatternsConfig(): PromotePatternsConfig {
		return this.promotePatternsConfig;
	}

	public getGameUIMainWindowConfig(): Map<MainWindowTarget, MainWindowElementConfig> {
		return this.gameUIMainWindowConfig;
	}

	public getLevelBaseWindowConfig(): Map<MainWindowTarget, MainWindowElementConfig> {
		return this.levelBaseWindowConfig;
	}

	public getLocalizationSupportConfig(): LocalizationSupportConfig {
		return this.localizationSupportConfig;
	}

	public getBankPriceItemsConfig(): BankPriceItemsConfig {
		return this.bankPriceItemsConfig;
	}

	protected onConfigsParsed(): void {
		super.onConfigsParsed(
			this.businessesConfigSetParser.getConfigSet(),
			this.upgradesConfigSetParser.getConfigSet(),
			this.constantsConfig?.getReduceTimeIncomeByClick(),
		);

		this.epicQuestCollectionsSetController.getConfigSet().forEach((epicQuestConfig) => {
			epicQuestConfig.getQuestKeyLootboxKeyMap().forEach((lootboxKey, questKey) => {
				const lootboxConfig = this.lootboxesConfigSetParser.getConfig(lootboxKey);
				epicQuestConfig.setQuestLootboxRarity(questKey, lootboxConfig.getCardRarity());
			});
		});

		this.levelsConfigSetParser.getConfigSet().forEach((levelConfig) => {
			const unlockConfigs = levelConfig.getContentUnlockConfigs();
			unlockConfigs.forEach(config => {
				this.setUnlockInfo(config, this.totemsConfigSetParser, levelConfig);
				this.setUnlockInfo(config, this.upgradesConfigSetParser, levelConfig);
				this.setUnlockInfo(config, this.charactersConfigSetParser, levelConfig);
			});
		});
	}

	private parseConstantsConfig(data: any): void {
		this.constantsConfig = new ConstantsConfig(data[LevelConfigNames.CONFIG_CONSTANTS]);
	}

	private parseMainWindowConfig(data: any): void {
		this.mainWindowConfig = new MainWindowConfig(data[LevelConfigNames.CONFIG_MAIN_WINDOW]);
		this.gameUIMainWindowConfig = new Map();
		this.levelBaseWindowConfig = new Map();
		this.mainWindowConfig.getMainWindowElementsMap().forEach((elementConfig: MainWindowElementConfig, target: MainWindowTarget) => {
			if (MainWindowConfig.LEVEL_BASE_TARGETS.includes(target)) {
				this.levelBaseWindowConfig.set(target, elementConfig);
			} else {
				this.gameUIMainWindowConfig.set(target, elementConfig);
			}
		});
	}

	private parsePromotePatternsConfig(data: any): void {
		this.promotePatternsConfig = new PromotePatternsConfig(data[LevelConfigNames.CONFIG_PROMOTE_PATTERNS]);
	}

	private parseBankPriceItemsConfig(data: any): void {
		this.bankPriceItemsConfig = new BankPriceItemsConfig(data[LevelConfigNames.CONFIG_BANK_PRICE_ITEMS]);
	}

	private parseLocalizationSupportConfig(data: any): void {
		this.localizationSupportConfig = new LocalizationSupportConfig(data[LevelConfigNames.CONFIG_LOCALIZATON_SUPPORT]);
	}

	private parseBaseConfigs(data: Record<string, any>): void {
		this.baseConfigSetMetadata.forEach((configSetMetadata) => {
			try {
				configSetMetadata.controller.parseConfigSet(data[configSetMetadata.name]);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(`Cannot parse config ${configSetMetadata.name}: ${e}`);
			}
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private setUnlockInfo(
		contentUnlockConfig: ContentUnlockLevelConfig,
		configSetController: BaseConfigSetParser<AbstractPromotableEntityConfig>,
		levelConfig: LevelConfig,
	): void {
		configSetController.getConfigSet().forEach((config) => {
			if (contentUnlockConfig.type === ContentUnlockLevelTypes.ID
				&& contentUnlockConfig.value === config.getKey()) {
				config.setUnlockInfo({ type: UnlockTypes.MIN_LEVEL, value: levelConfig.getKey() });
			}
		});
	}
}
