import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter40012View extends CharacterMainBaseView {
	constructor() {
		super('character40012');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-90, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(80, -28),
			bgPosition: new PIXI.Point(202, -32),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
