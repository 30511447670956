import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { MultiColoredTextField } from '../text/MultiColoredTextField';
import { ButtonBaseView } from './ButtonBaseView';

export enum ButtonValueTypes {
	SOFT_MONEY = '[money]',
	SOFT_MONEY_GRAY = '[money_grey]',
	PRESTIGE_MONEY = '[prestige]',
	HARD_MONEY = '[hardmoney]',
	DOLLAR = '$',
	NUTAKU = '[nutaku]',
}

interface ButtonWithCostValueSettings {
	type: ButtonValueTypes;
	buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane;
	maxWidth?: number;
	fontStyle?: PIXI.extras.BitmapTextStyle;
	costTextOffset?: PIXI.Point;
	costTextStyle?: PIXI.extras.BitmapTextStyle;
}

export class ButtonWithCostValueView extends ButtonBaseView {
	protected costText: SizeableMultiColoredBitmapText;
	protected type: string;

	protected value: string;

	protected enabled: boolean;
	protected buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane;

	constructor({
		type,
		buttonBg,
		maxWidth,
		fontStyle = { font: '26px wendyOneShadowBold', align: 'center' },
		costTextStyle,
		costTextOffset,
	}: ButtonWithCostValueSettings) {
		super(buttonBg);

		this.type = type;
		this.buttonBg = buttonBg;

		this.costText = new SizeableMultiColoredBitmapText(
			(maxWidth !== undefined) ? maxWidth : buttonBg.width,
			costTextStyle || fontStyle,
		);
		this.costText.anchor = 0.5;
		this.addChild(this.costText);

		if (costTextOffset) {
			this.costText.x += costTextOffset.x;
			this.costText.y += costTextOffset.y;
		}

		this.enabled = true;
	}

	public setCost(value: string): void {
		if (this.value === value) {
			return;
		}

		this.value = value;
		this.setCostValueEnabled(this.enabled);
	}

	public setCostValueEnabled(enabled: boolean): void {
		this.costText.text = enabled
			? `${this.type}[FFFFFF]${this.value}[-]`
			: `${this.type}[F85A4C]${this.value}[-]`;
	}

	public setButtonBgEnabled(enabled: boolean): void {
		this.buttonBg.texture = enabled
			? AssetsStorage.getAtlas('uiAtlas')['button_green']
			: AssetsStorage.getAtlas('uiAtlas')['button_gold'];
	}

	public setOldPrice(oldResoursValue: string, oldPriceValue: number): void {
		const oldPrice = new MultiColoredTextField(
			{ font: '26px wendyOneShadowBold' },
			108,
			41,
		);
		oldPrice.anchor = 0.5;
		oldPrice.text = `${oldResoursValue}${oldPriceValue}`;

		this.costText.position.set(oldPrice.width / 2 + 2, -4);
		oldPrice.position.set(-this.costText.width / 2 - 2, 0);

		const scratchIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['scratch_icon']);
		scratchIcon.width = oldPrice.width;
		scratchIcon.position.set(oldPrice.x, oldPrice.y);

		this.addChild(
			oldPrice,
			scratchIcon as PIXI.DisplayObject,
		);
	}

	public setEnabled(enable: boolean): void {
		if (this.enabled === enable) {
			return;
		}

		if (enable) {
			this.on('pointertap', this.onPointerTap, this);
		} else {
			this.off('pointertap', this.onPointerTap, this);
		}
		this.enabled = enable;

		this.setCostValueEnabled(enable);
	}

	public isEnabled(): boolean {
		return this.enabled;
	}

	public destroy(): void {
		this.costText.destroy();
		this.costText = null;

		super.destroy();
	}
}
