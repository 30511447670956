import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter53001View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53001View';
import { EventCharacter53002View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53002View';
import { EventCharacter53003View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53003View';

export class EventBusiness53001View extends BusinessBaseView {
	constructor() {
		const eventCharacter53001View = new EventCharacter53001View();
		const eventCharacter53002View = new EventCharacter53002View();
		const eventCharacter53003View = new EventCharacter53003View();
		eventCharacter53003View.once(EventCharacter53003View.EVENT_SHOWN, () => eventCharacter53001View.destroy());

		super('business53001', [eventCharacter53001View, eventCharacter53002View, eventCharacter53003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter53001View as PIXI.DisplayObject,
			eventCharacter53002View,
			eventCharacter53003View,
		);
	}
}
