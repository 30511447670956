import { NetworkRequestSender } from '../NetworkRequestSender';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { PaymentMethod, TransactionOptions, TransactionResult } from '@src/types/TransactionTypes';
import { GameProfileModel } from '@models/GameProfileModel';
import { CheatModel } from '@models/CheatModel';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { ModelHelper } from '@models/ModelHelper';
import { TransactionSaveData } from '@src/types/SaveTypes';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { EnterEmailWindowView } from '@views/windows/account/EnterEmailWindowView';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ServerErrorCode } from '@src/types/NetworkTypes';

enum TransactionMode {
	EPOCH,
	CARDPAY,
	TEST,
}

type TransactionInfo = { url: string; nctxnid: number, error?: number };

export class BaseTransactionCreator extends PIXI.utils.EventEmitter implements TransactionCreator {
	constructor(
		protected readonly windowViewSystem: WindowViewSystem,
		protected readonly windowFactory: WindowViewBaseFactory,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly gameProfileModel: GameProfileModel,
		private readonly cheatModel: CheatModel,
		private readonly defaultPaymentMethod: PaymentMethod,
	) {
		super();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async buy(bankElementKeyWithPrefix: string, price: number, options: TransactionOptions): Promise<TransactionResult> {
		const transactionMode = this.getTransactionMode();
		let transactionInfo: TransactionInfo | undefined;

		switch (transactionMode) {
			case TransactionMode.TEST: {
				transactionInfo = await this.networkRequestSender.sendBankInitTestTransactionEpoch(bankElementKeyWithPrefix);
				break;
			}
			case TransactionMode.CARDPAY: {
				transactionInfo = await this.onBuyCardpay(bankElementKeyWithPrefix);
				break;
			}
			case TransactionMode.EPOCH: {
				transactionInfo = await this.networkRequestSender.sendBankInitTransactionEpoch(bankElementKeyWithPrefix);
				break;
			}
			default:
				throw new Error(`Unsupported transaction mode '${transactionMode}'`);
		}

		if (transactionInfo?.url) {
			await this.openWindow(transactionInfo.url);
			return this.sendCheckPurchase(transactionInfo.nctxnid);
		}

		return Promise.reject();
	}

	// eslint-disable-next-line class-methods-use-this
	protected openWindow(url: string): Promise<void> {
		const transactionWindow = window.open(url);
		let result: Promise<void>;
		if (transactionWindow) {
			result = new Promise((resolve) => {
				const timer = setInterval(async () => {
					if (transactionWindow.closed) {
						clearInterval(timer);
						resolve();
					}
				}, 500);
			});
		} else {
			result = Promise.reject();
		}
		return result;
	}

	public async check(saveData: TransactionSaveData): Promise<TransactionResult> {
		return this.sendCheckPurchase(saveData.notCommitedTransactionId);
	}

	private async sendCheckPurchase(notCommitedTransactionId: number): Promise<TransactionResult> {
		const result: {
			rewards: RewardDescriptionType[];
			// eslint-disable-next-line @typescript-eslint/naming-convention
			transaction_id: string;
		} = await this.networkRequestSender.sendBankCheckPurchase(notCommitedTransactionId);

		if (result?.rewards) {
			return {
				rewards: ModelHelper.mergeRewardDescriptions(result.rewards),
				transactionId: result.transaction_id,
				notCommitedTransactionId,
			};
		}
		return Promise.reject();
	}

	private getTransactionMode(): TransactionMode {
		let result: TransactionMode;

		if (this.cheatModel.isDummyTransactionsEnabled()) {
			result = TransactionMode.TEST;
		} else {
			const paymentMethod = this.gameProfileModel.getPaymentMethod();

			if (paymentMethod != null) {
				result = paymentMethod === PaymentMethod.CARDPAY
					? TransactionMode.CARDPAY
					: TransactionMode.EPOCH;
			} else {
				result = this.defaultPaymentMethod === PaymentMethod.CARDPAY
					? TransactionMode.CARDPAY
					: TransactionMode.EPOCH;
			}
		}

		return result;
	}

	private async onBuyCardpay(bankElementKeyWithPrefix: string): Promise<TransactionInfo | undefined> {
		let transactionInfo: TransactionInfo | undefined;
		let email = this.gameProfileModel.getEmail();

		if (email != null && email !== '') {
			transactionInfo = await this.networkRequestSender.sendBankInitTransactionCardpay(bankElementKeyWithPrefix, email);

			if (transactionInfo.error === ServerErrorCode.BANK_INVALID_EMAIL_CARDPAY) {
				this.updateEmail(null);
			}
		} else {
			email = await this.openEnterEmailWindow();

			if (email != null && email !== '') {
				transactionInfo = await this.networkRequestSender.sendBankInitTransactionCardpay(bankElementKeyWithPrefix, email);

				if (transactionInfo.error !== ServerErrorCode.BANK_INVALID_EMAIL_CARDPAY) {
					this.updateEmail(email);
				}
			}
		}

		return transactionInfo;
	}

	private updateEmail(email: string): void {
		this.gameProfileModel.setEmail(email);

		const action = new UpdateUserDataAction({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			cardpay_email: email,
		});
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private openEnterEmailWindow(): Promise<string> {
		const window = this.windowFactory.createEnterEmailWindow();
		this.windowViewSystem.showWindow(window);

		return new Promise((resolve) => {
			window.once(EnterEmailWindowView.EVENT_WINDOW_CLOSED, () => {
				const email = window.getEmail();
				resolve(email);
			});
		});
	}
}
