import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter120014View extends CharacterMainBaseView {
	constructor() {
		super('character120014');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(195, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(222, -21),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(406, -10),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
