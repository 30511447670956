import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110004View } from '@views/characters/events/event11/EventCharacter110004View';
import { EventCharacter110005View } from '@views/characters/events/event11/EventCharacter110005View';
import { EventCharacter110006View } from '@views/characters/events/event11/EventCharacter110006View';

export class EventBusiness110002View extends BusinessBaseView {
	constructor() {
		const eventCharacter110004View = new EventCharacter110004View();
		const eventCharacter110005View = new EventCharacter110005View();
		const eventCharacter110006View = new EventCharacter110006View();

		super('business110002', [eventCharacter110004View, eventCharacter110005View, eventCharacter110006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor3']).setTransform(-241, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor2']).setTransform(233, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor5']).setTransform(291, 14, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b3_decor1']).setTransform(11, 82, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter110004View as PIXI.DisplayObject,
			eventCharacter110005View,
			eventCharacter110006View,
		);
	}
}
