import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { VideoConfig } from '@configs/VideoConfig';

export class GalleryVideosFactory {
	private static readonly NUTAKU_VIDEO_PATH = 'assets/video/nutaku';

	constructor(
		private readonly configs: Map<string, VideoConfig>,
		private readonly openedVideos: Map<string, string>,
		private readonly isNutaku: boolean,
	) { }

	public createModels(): Map<string, GalleryVideoModel> {
		const models = new Map();

		this.configs.forEach((config, key) => {
			const model = new GalleryVideoModel(config);
			const videoUrl = this.isNutaku
				? GalleryVideosFactory.getNutakuUrl(model.getVideoUrl())
				: model.getVideoUrl();

			model.setVideoUrl(`${CDN}/${videoUrl}`);

			if (this.openedVideos.has(model.getKey())) {
				if (!model.isOpened()) {
					model.setOpened(true);
				}
			}

			models.set(key, model);
		});

		return models;
	}

	private static getNutakuUrl(configVideoUrl: string): string {
		const urlParts = configVideoUrl.split('/');
		const filename = urlParts[urlParts.length - 1];
		return [GalleryVideosFactory.NUTAKU_VIDEO_PATH, filename].join('/');
	}
}
