import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BundleRewardDisplayInfo } from './BankTabElementBundleTimedView';
import { BankParticleConfig } from '../animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import { BLEND_MODES } from 'pixi.js';
import { TextField } from '@views/components/text/TextField';
import { BankTabElementCompareView } from './BankTabElementCompareView';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class BankTabElementCompareRightView extends BankTabElementCompareView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];

	constructor(
		bankBundleModel: BankBundleModel,
		rewards: RewardDescriptionType[],
		rewardsDisplayInfo: BundleRewardDisplayInfo[],
	) {
		super(bankBundleModel, rewards, rewardsDisplayInfo);

		this.animationEmitters = [];

		this.localizationStorage = LocalizationStorage.getInstance();

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const bgRight = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_bg_gold'], 25, 0, 25, 0);
		bgRight.pivot.set(bgRight.width / 2, bgRight.height / 2);
		bgRight.scale.set(1.82);
		bgRight.width = 368;
		bgRight.y = -27;
		bgRight.x = 418;
		bgRight.interactive = true;

		const bgRightTint = new PIXI.Graphics();
		bgRightTint.beginFill(0x000000);
		bgRightTint.drawPolygon([
			new PIXI.Point(74, 230),
			new PIXI.Point(703, 230),
			new PIXI.Point(714, 62),
			new PIXI.Point(84, 62),
		]);
		bgRightTint.alpha = 0.25;

		const titleTextRight = new MultiColoredTextField(
			{ font: '47px wendyOneGold', align: 'center' },
			500,
			120,
		);
		titleTextRight.text = this.localizationStorage.getLocalizedString(bankBundleModel.getNameLocale());
		titleTextRight.anchor = 0.5;
		titleTextRight.position.set(410, -190);

		const offerStickerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_sticker'], 26, 0, 26, 0);
		offerStickerBg.width = 310;
		offerStickerBg.pivot.set(offerStickerBg.width / 2, offerStickerBg.height / 2);
		offerStickerBg.position.set(408, -280);

		const offerStickerText = new MultiColoredTextField(
			{ font: '25px wendyOne', align: 'center' },
			270,
			40,
		);
		offerStickerText.text = this.localizationStorage.getLocalizedString('#sticker_best_offer_label');
		offerStickerText.anchor = 0.5;
		offerStickerText.position.set(offerStickerBg.x, offerStickerBg.y - 4);

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(418, -160);
		animationsContainer.scale.y = 0.5;
		animationsContainer.scale.x = 0.8;

		const glowLightsEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowDecor2 = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line_horizontal'], 0, 0, 0, 0);
		glowDecor2.width = 650;
		glowDecor2.pivot.set(glowDecor2.width / 2, glowDecor2.height / 2);
		glowDecor2.position.set(420, -290);
		glowDecor2.blendMode = BLEND_MODES.ADD;
		glowDecor2.scale.x = -1;
		glowDecor2.alpha = 0.6;

		const glowDecor = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		glowDecor.width = 400;
		glowDecor.pivot.set(glowDecor.width / 2, glowDecor.height / 2);
		glowDecor.position.set(300, 229);
		glowDecor.blendMode = BLEND_MODES.ADD;
		glowDecor.alpha = 0.6;

		const buyButtonRight = this.createButton(314, 81);
		buyButtonRight.position.set(405, 146);

		const stickerSaleContainer = new PIXI.Container();
		stickerSaleContainer.position.set(702, -251);

		if (bankBundleModel.getMostPopularLocaleKey()) {
			const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			const saleLabel = new TextField(
				this.localizationStorage.getLocalizedString(bankBundleModel.getMostPopularLocaleKey()),
				{ font: '26px wendyOne', align: 'center', tint: 0xfff78f },
				80,
				80,
			);
			saleLabel.anchor = 0.5;
			saleLabel.rotation = 0.47;

			stickerSaleContainer.addChild(
				stickerSale,
				saleLabel as PIXI.DisplayObject,
			);
		}

		this.addChild(
			animationsContainer,
			bgRight,
			bgRightTint,
			titleTextRight,
			glowDecor,
			glowDecor2,
			offerStickerBg,
			offerStickerText,
			buyButtonRight,
			stickerSaleContainer,
		);

		const rewardsContainerRight = new PIXI.Container();
		rewardsContainerRight.position.set(407, -45);

		rewardsContainerRight.addChild(...this.getRewardsRow(10, 0.79));
		this.addChild(rewardsContainerRight);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
