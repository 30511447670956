import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter254004View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254004View';
import { EventCharacter254005View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254005View';
import { EventCharacter254006View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254006View';

export class EventBusiness254002View extends BusinessBaseView {
	constructor() {
		const eventCharacter254004View = new EventCharacter254004View();
		const eventCharacter254005View = new EventCharacter254005View();
		const eventCharacter254006View = new EventCharacter254006View();

		super('business254002', [eventCharacter254004View, eventCharacter254005View, eventCharacter254006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter254004View as PIXI.DisplayObject,
			eventCharacter254005View,
			eventCharacter254006View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
