import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { GameProfileModel } from '@models/GameProfileModel';
import { GameProfileBaseViewController } from './GameProfileBaseViewController';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { SettingsWindowViewSetter } from '@interfaces/ViewSetters';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ClaimRegistrationRewardAction } from '@models/network/actions/ClaimRegistrationRewardAction';
import { BaseSettingsWindowView } from '@views/windows/settings/BaseSettingsWindowView';

export class GameProfileViewController extends GameProfileBaseViewController implements SettingsWindowViewSetter {
	private settingsWindow?: BaseSettingsWindowView;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly lootboxFactory: LootboxFactory,
		networkRequestSender: NetworkRequestSender,
		gameProfileModel: GameProfileModel,
		savesConfig: SavesConfig,
		resendEmailConfirmationTimeout: number,
	) {
		super(
			networkRequestSender,
			gameProfileModel,
			savesConfig,
			resendEmailConfirmationTimeout,
		);
	}

	public setSettingsWindowView(view: BaseSettingsWindowView): void {
		this.settingsWindow = view;
		this.settingsWindow.on(BaseSettingsWindowView.EVENT_BUTTON_COLLECT_REWARD_CLICK, this.onButtonCollectRewardClick, this);
	}

	private async onButtonCollectRewardClick(): Promise<void> {
		const lootboxConfig = this.gameProfileModel.getRegistrationLootboxConfig();
		const lootbox = this.lootboxFactory.createLootbox(lootboxConfig);
		const action = new ClaimRegistrationRewardAction(lootbox.getKey());

		this.emit(BaseAction.EVENT_ACTION_CREATED, action);

		const window = this.windowFactory.createRewardResultLootboxWindow(
			false,
			undefined,
			RewardResultWindowBgType.FADE,
		);

		window.init(
			lootbox.getRewards(),
			undefined,
			lootbox.getCardRarity(),
		);

		this.windowViewSystem.showWindow(window);
		this.gameProfileModel.onRewardCollected();

		this.settingsWindow.once(BaseSettingsWindowView.EVENT_BUTTON_COLLECT_REWARD_CLICK, this.onButtonCollectRewardClick, this);
	}
}
