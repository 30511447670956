import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { BaseAction } from '@models/network/actions/BaseAction';

export class LevelChallengeTicker extends PIXI.utils.EventEmitter {
	private readonly serverTimeModel: ServerTimeModel;
	private readonly levelChallengeModel: LevelChallengeModel;

	private readonly ticker: PIXI.ticker.Ticker;

	private timerTicking: boolean;

	private enabled: boolean;

	private inited: boolean;

	constructor(
		levelChallengeModel: LevelChallengeModel,
		serverTime: ServerTimeModel,
	) {
		super();

		this.serverTimeModel = serverTime;

		this.levelChallengeModel = levelChallengeModel;
		this.levelChallengeModel.on(LevelChallengeModel.EVENT_COMPLETED, () => this.stopTimer(true), this);
		this.levelChallengeModel.on(LevelChallengeModel.EVENT_TIMEOUT, this.onLevelChallengeTimeout, this);

		this.ticker = PIXI.ticker.shared;
	}

	public init(withStartedAction: boolean): void {
		this.inited = true;

		this.tryStartTimer();

		if (withStartedAction) {
			const action = new UpdateUserDataAction({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				level_started_ts: this.serverTimeModel.getCalculatedISOTime(),
			});
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (value) {
			this.tryStartTimer();
		} else {
			this.tryStopTimer();
		}
	}

	private onLevelChallengeTimeout(): void {
		if (!this.levelChallengeModel.isActive()) {
			this.tryStopTimer();
		}
	}

	private tryStartTimer(): void {
		if (!this.timerTicking && this.enabled && this.inited && this.levelChallengeModel.isActive()) {
			this.startTimer();
		}
	}

	private startTimer(): void {
		this.timerTicking = true;
		this.ticker.add(this.onTimerUpdate, this);
	}

	private tryStopTimer(withFinishedAction: boolean = false): void {
		if (this.timerTicking) {
			this.stopTimer(withFinishedAction);
		}
	}

	private stopTimer(withFinishedAction: boolean = false): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);

		if (withFinishedAction) {
			this.onFinishedAction();
		}
	}

	private onTimerUpdate(): void {
		this.levelChallengeModel.update(this.serverTimeModel.getCalculatedISOTime());
	}

	private onFinishedAction(): void {
		const action = new UpdateUserDataAction({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			level_finished_ts: this.serverTimeModel.getCalculatedISOTime(),
		});
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}
}
