import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';

export enum SettingsCharacterDialogViewMode {
	SHOW_BUTTON_COLLECT,
	SHOW_EMAIL,
	SHOW_BUTTON_LEAVE_EMAIL,
}

export type SettingsCharacterDialogRegisteredArgs = {
	email: string;
};

export type SettingsCharacterDialogViewArgs = {
	mode: SettingsCharacterDialogViewMode;
	data?: SettingsCharacterDialogRegisteredArgs;
};

export class SettingsCharacterDialogView extends PIXI.Container {
	public static readonly EVENT_BUTTON_LEAVE_EMAIL_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_COLLECT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private buttonLeaveEmailLabel: ButtonWithLabelBaseView;
	private textLeaveEmail: TextField;
	private textEmail: TextField;

	private buttonCollect: ButtonWithLabelBaseView;
	private textCollect: TextField;

	private leaveEmailContainer: PIXI.Container;
	private showEmailContainer: PIXI.Container;
	private buttonCollectContainer: PIXI.Container;

	private mode: SettingsCharacterDialogViewMode;
	private email: string;
	private fxAtlas: PIXI.loaders.TextureDictionary;

	private animationEmitters: Emitter[];

	private rewardCollected: boolean;


	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.email = '';
		this.rewardCollected = false;

		this.animationEmitters = [];
		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		// leaveEmailContainer
		this.leaveEmailContainer = new PIXI.Container();
		{
			const bubble = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_bubble'], 17, 0, 17, 0);
			bubble.width = 356;
			bubble.scale.x = -1;
			bubble.x = 351;

			const bubbleTail = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_bubble_tail']);
			bubbleTail.position.set(386, 160);

			this.buttonLeaveEmailLabel = new ButtonWithLabelBaseView(
				ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 288, 86),
				{ font: { size: 33, name: 'wendyOneShadowBold' } },
				275,
			);
			this.buttonLeaveEmailLabel.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonLeaveEmailClick, this);
			this.buttonLeaveEmailLabel.position.set(bubble.width / 2, bubble.height / 2 + 50);

			this.textLeaveEmail = new TextField(
				'',
				{ font: { size: 30, name: 'wendyOne' }, tint: 0x465768, align: 'center' },
				300,
				100,
			);
			this.textLeaveEmail.position.set(bubble.width / 2, bubble.height / 3);
			this.textLeaveEmail.anchor = 0.5;

			const animationsContainer = new PIXI.Container();
			animationsContainer.position.set(bubble.width / 2, 20);
			animationsContainer.scale.set(0.25);

			const glowEmitter = new Emitter(
				animationsContainer,
				[this.fxAtlas['skill_activ_glow1']],
				BankParticleConfig.getRecharcgeGlow(),
			);
			glowEmitter.autoUpdate = true;
			this.animationEmitters.push(glowEmitter);

			const glowLoghtsEmitter = new Emitter(
				animationsContainer,
				[this.fxAtlas['party_button_glow4']],
				BankParticleConfig.getRecharcgeGlow2(),
			);
			glowLoghtsEmitter.autoUpdate = true;
			this.animationEmitters.push(glowLoghtsEmitter);

			const lootboxIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['present_icon']);
			lootboxIcon.position.set(bubble.width / 2, -10);

			this.leaveEmailContainer.addChild(
				animationsContainer,
				bubble,
				bubbleTail,
				this.buttonLeaveEmailLabel as PIXI.DisplayObject,
				this.textLeaveEmail,
				lootboxIcon,
			);
		}

		// buttonCollectContainer
		this.buttonCollectContainer = new PIXI.Container();
		{
			const bubble = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_bubble'], 17, 0, 17, 0);
			bubble.width = 356;
			bubble.scale.x = -1;
			bubble.x = 351;

			const bubbleTail = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_bubble_tail']);
			bubbleTail.position.set(386, 160);

			this.buttonCollect = new ButtonWithLabelBaseView(
				ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 288, 86),
				{ font: '24px wendyOneShadowBold' },
				180,
			);
			this.buttonCollect.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonCollectClick, this);
			this.buttonCollect.position.set(bubble.width / 2, bubble.height / 2 + 50);

			this.textCollect = new TextField(
				'',
				{ font: { size: 30, name: 'wendyOne' }, tint: 0x465768, align: 'center' },
				300,
				100,
			);
			this.textCollect.position.set(bubble.width / 2, bubble.height / 3);
			this.textCollect.anchor = 0.5;

			const lootboxIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['present_icon']);
			lootboxIcon.position.set(bubble.width / 2, -10);

			const animationsContainer = new PIXI.Container();
			animationsContainer.position.set(bubble.width / 2, 20);
			animationsContainer.scale.set(0.25);

			animationsContainer.position.set(bubble.width / 2, -10);
			const glowEmitter = new Emitter(
				animationsContainer,
				[this.fxAtlas['skill_activ_glow1']],
				BankParticleConfig.getRecharcgeGlow(),
			);
			glowEmitter.autoUpdate = true;
			this.animationEmitters.push(glowEmitter);

			const glowLoghtsEmitter = new Emitter(
				animationsContainer,
				[this.fxAtlas['party_button_glow4']],
				BankParticleConfig.getRecharcgeGlow2(),
			);
			glowLoghtsEmitter.autoUpdate = true;
			this.animationEmitters.push(glowLoghtsEmitter);

			this.buttonCollectContainer.addChild(
				animationsContainer,
				bubble,
				bubbleTail,
				this.buttonCollect as PIXI.DisplayObject,
				this.textCollect,
				lootboxIcon,
			);
		}

		// showEmalContainer
		this.showEmailContainer = new PIXI.Container();
		{
			const bubble = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_bubble'], 17, 0, 17, 0);
			bubble.position.set(350, 90);
			bubble.scale.y = 0.4;
			bubble.scale.x = -1;
			bubble.width = 300;

			const bubbleTail = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_bubble_tail']);
			bubbleTail.position.set(386, 160);

			this.textEmail = new MultiColoredTextField(
				{ font: { size: 30, name: 'wendyOne' }, tint: 0x465768, align: 'center' },
				275,
				50,
			);
			this.textEmail.position.set(206, 136);
			this.textEmail.anchor = 0.5;

			this.showEmailContainer.addChild(
				bubble,
				bubbleTail as PIXI.DisplayObject,
				this.textEmail,
			);
		}

		this.addChild(
			this.buttonCollectContainer,
			this.showEmailContainer,
			this.leaveEmailContainer,
		);

		this.updateText();
	}

	public setMode(args: SettingsCharacterDialogViewArgs): void {
		this.mode = args.mode;
		// eslint-disable-next-line default-case
		switch (this.mode) {
			case SettingsCharacterDialogViewMode.SHOW_BUTTON_COLLECT: {
				this.buttonCollectContainer.visible = true;
				this.leaveEmailContainer.visible = false;
				this.showEmailContainer.visible = false;
				this.rewardCollected = false;
				break;
			}
			case SettingsCharacterDialogViewMode.SHOW_EMAIL: {
				this.email = args.data.email;
				this.updateTextEmail();

				this.buttonCollectContainer.visible = false;
				this.leaveEmailContainer.visible = false;
				this.showEmailContainer.visible = true;
				this.rewardCollected = true;
				break;
			}
			case SettingsCharacterDialogViewMode.SHOW_BUTTON_LEAVE_EMAIL: {
				this.buttonCollectContainer.visible = false;
				this.leaveEmailContainer.visible = true;
				this.showEmailContainer.visible = false;
				this.rewardCollected = false;
				break;
			}
		}
	}

	private updateTextEmail(): void {
		let stringEmail = this.localizationStorage.getLocalizedString('#settings_window_your_email');
		stringEmail = stringEmail.replace('{{value}}', this.email);
		this.textEmail.text = stringEmail;
	}

	private updateText(): void {
		this.buttonLeaveEmailLabel.setTextLabel(this.localizationStorage.getLocalizedString('#settings_window_leave_email_button_label'));
		this.buttonCollect.setTextLabel(this.localizationStorage.getLocalizedString('#bank_subscribe_claim'));
		this.textLeaveEmail.text = this.localizationStorage.getLocalizedString('#settings_window_dialog_title');
		this.textCollect.text = this.localizationStorage.getLocalizedString('#settings_window_dialog_collect_reward_title');

		this.updateTextEmail();
	}

	private onButtonLeaveEmailClick(): void {
		this.emit(SettingsCharacterDialogView.EVENT_BUTTON_LEAVE_EMAIL_CLICK);
	}

	private onButtonCollectClick(): void {
		this.emit(SettingsCharacterDialogView.EVENT_BUTTON_COLLECT_CLICK);
	}

	public isLogInRewardCollected(): boolean {
		return this.rewardCollected;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
