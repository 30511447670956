import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { BusinessModel } from '@models/BusinessModel';

export class QuickPhraseNewCustomersMultiplierGenerator extends QuickPhaseGeneratorBase {
	constructor(
		config: CharacterQuickPhraseConstants,
		businessModels: Map<string, BusinessModel>,
	) {
		super(CharacterQuickPhraseTypes.NEW_CUSTOMERS_MULTIPLIER, config);
		businessModels.forEach((model, key) => model.on(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.onNewCustomersMultiplier, this));
	}

	private onNewCustomersMultiplier(model: BusinessModel): void {
		model.getCharacterKeys().forEach(characterKey => this.tryGeneratePhrase(characterKey));
	}
}
