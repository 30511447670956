import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export abstract class QuestActivateConfigBase extends QuestConfigBase {
	protected targetAll: boolean;
	protected targetKey: string;
	protected activateCount: number;

	protected constructor(config: { [key: string]: string }) {
		super(config);

		const params: string = config[QuestConfigBase.FIELD_PARAMETERS];
		this.targetAll = QuestConfigBase.FORMAT_PARAMETER_ALL.test(params);
		if (!this.targetAll && config[QuestConfigBase.FIELD_SUB_TYPE] !== QuestConfigBase.SUB_TYPE_PARTY) {
			// eslint-disable-next-line prefer-destructuring
			this.targetKey = params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)[1];
		}
	}

	public setParamsOnLevel(params: string): void {
		this.activateCount = Number(params);
	}

	public getTargetKey(): string {
		return this.targetKey;
	}

	public getActivateCount(): number {
		return this.activateCount;
	}
}

export class QuestActivateCharactersConfig extends QuestActivateConfigBase {
	private readonly type: QuestType;

	constructor(config: { [key: string]: string }) {
		super(config);

		if (this.targetAll) {
			this.type = QuestTypes.ACTIVATE_CHARACTERS;
		} else {
			this.type = QuestTypes.ACTIVATE_CHARACTER;
		}
	}

	public getType(): QuestType {
		return this.type;
	}
}

export class QuestActivateUpgradesConfig extends QuestActivateConfigBase {
	private readonly type: QuestType;

	constructor(config: { [key: string]: string }) {
		super(config);

		if (this.targetAll) {
			this.type = QuestTypes.ACTIVATE_UPGRADES;
		} else {
			this.type = QuestTypes.ACTIVATE_UPGRADE;
		}
	}

	public getType(): QuestType {
		return this.type;
	}
}

export class QuestActivatePartyConfig extends QuestActivateConfigBase {
	private readonly type: QuestType;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.type = QuestTypes.ACTIVATE_PARTY;
	}

	public getType(): QuestType {
		return this.type;
	}
}
