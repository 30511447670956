import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';

export class QuickPhraseIdleGenerator extends QuickPhaseGeneratorBase {
	constructor(config: CharacterQuickPhraseConstants) {
		super(CharacterQuickPhraseTypes.IDLE, config);
		this.config = config;
	}

	public registerPhrases(characterKey: string): void {
		super.registerPhrases(characterKey);
		this.startGenerationCountdown(characterKey);
	}

	private startGenerationCountdown(characterKey: string): void {
		const time = this.getGenerationTime() * 500;
		setTimeout(() => this.onGenerationTime(characterKey), time);
	}

	private onGenerationTime(characterKey: string): void {
		this.tryGeneratePhrase(characterKey);
		this.startGenerationCountdown(characterKey);
	}

	private getGenerationTime(): number {
		const { min } = this.config.idlePhraseCreateTime;
		const { max } = this.config.idlePhraseCreateTime;

		return min + Math.ceil(Math.random() * (max - min));
	}
}
