import { TrackingData } from '@src/types/AnalyticsTypes';
import { AnalyticSourceLevelStartWindowView, EventButtonStartClickEventArgs } from '../sources/AnalyticSourceLevelStartWindowView';
import { AnalyticSourceModels, EventBankPurchaseSuccessEventArgs } from '../sources/AnalyticSourceModels';

export class AnalyticTargetTrafficStars extends PIXI.utils.EventEmitter {
	public static readonly EVENT_RESPONSE: symbol = Symbol();

	private static readonly UTM_SOURCE: string = 'ts';

	private static readonly BASE_URL: string = 'https://tsyndicate.com/api/v1/cpa/action';
	private static readonly PURCHASE_KEY_VALUE: string = '84zmFQPNDfV9fmnpUMRfvaj0BJUb5pg3jFfP';

	private marketingTrackingParam?: string;
	private permitted: boolean;

	constructor(trackingData?: TrackingData) {
		super();

		this.init(trackingData);
	}

	public setGameProfileData(trackingData?: TrackingData): void {
		this.init(trackingData);
	}

	private init(trackingData?: TrackingData): void {
		if (trackingData !== undefined
			&& trackingData.utmSource === AnalyticTargetTrafficStars.UTM_SOURCE) {
			this.permitted = true;
			this.marketingTrackingParam = trackingData.ts;
		} else {
			this.permitted = false;
		}
	}

	public addSourceLevelStartWindowView(source: AnalyticSourceLevelStartWindowView): void {
		const tryEmitEventTutorialEnd = async (args: EventButtonStartClickEventArgs): Promise<void> => {
			if (this.permitted) {
				if (args.currentLevel === 4) {
					const url = `${AnalyticTargetTrafficStars.BASE_URL}/${this.marketingTrackingParam}?value=0`;
					const response = await fetch(url);
					this.emit(AnalyticTargetTrafficStars.EVENT_RESPONSE, response);
				} else {
					source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
				}
			}
		};
		source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
	}

	public addSourceModels(source: AnalyticSourceModels): void {
		source.on(AnalyticSourceModels.EVENT_BANK_PURCHASE_SUCCESS, async (args: EventBankPurchaseSuccessEventArgs) => {
			if (this.permitted) {
				const price = encodeURIComponent(args.price);
				const value = `value=${price}`;
				const leadCode = `lead_code=${Date.now() + Math.floor(Math.random() * 0xFFFFFF)}${args.transactionId}`;
				const key = `key=${AnalyticTargetTrafficStars.PURCHASE_KEY_VALUE}`;

				const url: string = `${AnalyticTargetTrafficStars.BASE_URL}/${this.marketingTrackingParam}?${value}&${leadCode}&${key}`;

				const response = await fetch(url);
				this.emit(AnalyticTargetTrafficStars.EVENT_RESPONSE, response);
			}
		}, this);
	}
}
