import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { SupportWindowViewSetter } from '@interfaces/ViewSetters';
import { SupportWindowView } from '@views/windows/support/SupportWindowView';

export class SupportWindowViewController implements SupportWindowViewSetter {
	private static tryGetWebGLInfo(): { vendor: string | undefined; renderer: string | undefined } {
		let vendor;
		let renderer;

		try {
			const canvas = document.createElement('canvas');
			const gl: any = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

			const debugInfo = gl?.getExtension('WEBGL_debug_renderer_info');
			vendor = gl?.getParameter(debugInfo?.UNMASKED_VENDOR_WEBGL);
			renderer = gl?.getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL);
		} catch (e) {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error('Can\'t get webgl renderer info');
			}
		}

		return { vendor, renderer };
	}

	private readonly networkRequestSender: NetworkRequestSender;

	constructor(networkRequestSender: NetworkRequestSender) {
		this.networkRequestSender = networkRequestSender;
	}

	public setSupportWindowView(view: SupportWindowView): void {
		view.on(SupportWindowView.EVENT_SUBMIT_SUPPORT_MESSAGE, this.onSubmitSupportMessage, this);
	}

	private onSubmitSupportMessage(email: string, subject: string, msg: string): void {
		const webGlInfo = SupportWindowViewController.tryGetWebGLInfo();

		let deviceInfo = `Browser: ${window.navigator?.appVersion}`;
		deviceInfo += `\nRAM: ${(window.navigator as any)?.deviceMemory}`;
		deviceInfo += `\nGPU vendor: ${webGlInfo.vendor}`;
		deviceInfo += `\nGPU info: ${webGlInfo.renderer}`;

		this.networkRequestSender.sendSupportMail(subject, msg, deviceInfo, email);
	}
}
