import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { FarewellPartyConstants } from '@configs/ConstantsConfig';
import { FarewellPartyConfig } from '@configs/FarewellPartyConfig';
import { TotemModel } from '@models/TotemModel';
import { BonusTypes } from '@src/types/BonusTypes';

export class FarewellPartiesFactory {
	private readonly configs: Map<string, FarewellPartyConfig>;
	private readonly partyConstants: FarewellPartyConstants;

	constructor(
		configs: Map<string, FarewellPartyConfig>,
		partyConstants: FarewellPartyConstants,
	) {
		this.configs = configs;
		this.partyConstants = partyConstants;
	}

	public createModels(
		totemModels: Map<string, TotemModel>,
	): Map<string, FarewellPartyModel> {
		const models: Map<string, FarewellPartyModel> = new Map();

		this.configs.forEach((config, key) => {
			const model = new FarewellPartyModel(this.partyConstants, config);
			models.set(key, model);

			Array.from(totemModels.values()).filter(m => m.isOpened()).forEach(totemModel => {
				const totemKey = totemModel.getKey();
				totemModel.getBonuses().forEach((bonus, type) => {
					const bonusValue = bonus.getValue();
					// eslint-disable-next-line default-case
					switch (type) {
						case BonusTypes.MULTIPLIER_FUCKPOWER:
							model.setMultiplierFuckpowerTotem(totemKey, bonusValue);
							break;

						case BonusTypes.FUCKTIME:
							model.setMultiplierFucktimeTotem(totemKey, bonusValue);
							break;
					}
				});
				model.setTotemLevel(totemKey, totemModel.getLevel());
			});
		});
		return models;
	}
}
