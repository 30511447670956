import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SoundController } from '@src/main/SoundController';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';

export class ButtonBuyBusinessView extends ButtonWithCostValueView {
	private textLabel: string;
	private pulseTween: TWEEN.Tween;
	private pulsing: boolean;
	private pulseEnabled: boolean;
	private localizationStorage: LocalizationStorage;
	private isCostValueEnabled: boolean;

	protected buttonBg: PIXI.mesh.NineSlicePlane;

	constructor() {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_green'], 11, 11, 11, 11);
		buttonBg.width = 243;
		buttonBg.height = 60;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super({
			type: ButtonValueTypes.SOFT_MONEY,
			buttonBg,
			maxWidth: buttonBg.width - 10,
			fontStyle: { font: '23px wendyOneShadowBold', align: 'center' },
		});

		this.buttonBg = buttonBg;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.costText.y = -3;

		this.pulseEnabled = false;

		this.onTranslateText();
	}

	private onTranslateText(): void {
		this.textLabel = this.localizationStorage.getLocalizedString('#buy_business_button');
		this.updateCostText(this.enabled && this.isCostValueEnabled);
	}

	public setCostValueEnabled(value: boolean): void {
		this.isCostValueEnabled = value;
		this.updateCostText(value);
	}

	private updateCostText(enable: boolean): void {
		if (enable) {
			this.costText.text = `[FFFFFF]${this.textLabel}[-] ${this.type}[FFFFFF]${this.value}[-]`;
			this.buttonBg.texture = AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_green'];
		} else {
			this.costText.text = `[F85A4C]${this.textLabel}[-] ${this.type}[F85A4C]${this.value}[-]`;
			this.buttonBg.texture = AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_yellow'];
		}
	}

	public startPulseAnimation(): void {
		this.interruptPulseAnimation();
		this.scale.set(1);
		this.pulseTween = new TWEEN.Tween(this.scale)
			.to({ x: 1.1, y: 1.1 }, 600)
			.yoyo(true)
			.repeat(Infinity)
			.start();
		this.pulsing = true;
		this.pulseEnabled = true;
	}

	public isPulsing(): boolean {
		return this.pulsing;
	}

	public stopPulseAnimation(): void {
		this.interruptPulseAnimation();
		this.pulsing = false;
		this.pulseEnabled = false;
		this.scale.set(1);
	}

	private interruptPulseAnimation(): void {
		if (this.pulseTween) {
			this.pulseTween.stop();
			this.pulseTween = null;
		}
	}

	protected onPointerUp(): void {
		if (!this.pulsing) {
			super.onPointerUp();
			if (this.pulseEnabled) {
				this.startPulseAnimation();
			}
		}
	}

	protected onPointerOut(): void {
		if (!this.pulsing) {
			super.onPointerOut();
			if (this.pulseEnabled) {
				this.startPulseAnimation();
			}
		}
	}

	protected onPointerDown(e: PIXI.interaction.InteractionEvent): void {
		this.interruptPulseAnimation();
		this.pulsing = false;
		super.onPointerDown(e);
	}

	protected onPointerOver(): void {
		if (!this.pulsing) {
			super.onPointerOver();
		}

		SoundController.getInstance().playButtonPointerOver();
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);
		super.destroy();
	}
}
