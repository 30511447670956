import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { BusinessModel } from '@models/BusinessModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { PrestigeMoneyPanelView } from '../moneyPanel/PrestigeMoneyPanelView';
import { HardMoneyPanelView } from '../moneyPanel/HardMoneyPanelView';
import { LevelTopUIPanelView } from './LevelTopUIPanelView';
import { ButtonSettingsView } from '../ButtonSettingsView';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@main/AssetsStorage';
import { SoftMoneyPanelView } from '../moneyPanel/SoftMoneyPanelView';
import * as TWEEN from '@tweenjs/tween.js';

export class TopUIPanelBaseView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT_SOFT_MONEY_PANEL_VIEW: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT_PRESTIGE_MONEY_PANEL_VIEW: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT_HARD_MONEY_PANEL_VIEW: symbol = Symbol();

	public static readonly EVENT_BUTTON_SETTINGS_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_PLUS_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CHEAT_CLICK: symbol = Symbol();

	protected readonly gameProfileModel: GameProfileModel;

	private readonly softMoneyPanelView: SoftMoneyPanelView;
	private readonly prestigeMoneyPanel: PrestigeMoneyPanelView;
	private readonly hardMoneyPanel: HardMoneyPanelView;
	private readonly buttonSettings: ButtonSettingsView;
	private readonly background: PIXI.mesh.NineSlicePlane;
	private readonly bgLeft: PIXI.mesh.NineSlicePlane;
	private readonly bgRight: PIXI.mesh.NineSlicePlane;

	constructor(
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		businessModels: BusinessModel[],
		skillModels: Map<string, SkillModel>,
		gameProfileModel: GameProfileModel,
	) {
		super();

		this.bgLeft = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_ui_panel'], 2, 0, 2, 0);
		this.bgLeft.width = GameConstants.GAME_WIDTH;
		this.bgLeft.height = 92;
		this.bgLeft.y = -10;

		this.bgRight = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['main_ui_panel'], 2, 0, 2, 0);
		this.bgRight.width = GameConstants.GAME_WIDTH / 2;
		this.bgRight.height = 92;
		this.bgRight.x = GameConstants.GAME_WIDTH;
		this.bgRight.rotation = Math.PI;
		this.bgRight.y = 78;

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.once(GameProfileModel.EVENT_REWARD_COLLECTED, this.updateButtonSettingsInfoIconVisible, this);

		this.softMoneyPanelView = new SoftMoneyPanelView(
			softMoneyModel,
			businessModels,
			skillModels,
			true,
		);
		this.softMoneyPanelView.on(
			SoftMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(LevelTopUIPanelView.EVENT_SHOW_HINT_SOFT_MONEY_PANEL_VIEW, arrowPosLocal, origin);
			},
		);
		this.softMoneyPanelView.position.set(GameConstants.GAME_CENTER_X, 40);

		this.prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel);
		this.prestigeMoneyPanel.on(PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => {
			this.emit(LevelTopUIPanelView.EVENT_BUTTON_PLUS_CLICK);
		});
		this.prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(LevelTopUIPanelView.EVENT_SHOW_HINT_PRESTIGE_MONEY_PANEL_VIEW, arrowPosLocal, origin);
			},
		);
		this.prestigeMoneyPanel.position.set(1370, 40);

		this.hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
		this.hardMoneyPanel.on(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => {
			this.emit(LevelTopUIPanelView.EVENT_BUTTON_PLUS_CLICK);
		});
		this.hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(LevelTopUIPanelView.EVENT_SHOW_HINT_HARD_MONEY_PANEL_VIEW, arrowPosLocal, origin);
			},
		);
		this.hardMoneyPanel.position.set(1630, 40);

		this.buttonSettings = new ButtonSettingsView();
		this.buttonSettings.on(ButtonSettingsView.EVENT_CLICK, () => this.emit(LevelTopUIPanelView.EVENT_BUTTON_SETTINGS_CLICK), this);
		this.buttonSettings.position.set(GameConstants.GAME_WIDTH - 42, 39);

		this.updateButtonSettingsInfoIconVisible();

		this.addChild(
			this.bgLeft,
			this.bgRight,
			this.softMoneyPanelView as PIXI.DisplayObject,
			this.prestigeMoneyPanel,
			this.hardMoneyPanel,
			this.buttonSettings as PIXI.DisplayObject,
		);
	}

	public initMoneyPanels(): void {
		this.hardMoneyPanel.init();
		this.softMoneyPanelView.init();
		this.prestigeMoneyPanel.init();
	}

	protected updateTextIncomePerSec(): void {
		this.softMoneyPanelView.updateTextIncomePerSec();
	}

	private updateButtonSettingsInfoIconVisible(): void {
		const visible = !this.gameProfileModel.isLoggedIn() || this.gameProfileModel.hasPendingReward();
		this.buttonSettings.setInfoIconVisible(visible);
	}

	public onZoomInStarted(): void {
		this.softMoneyPanelView.onZoomInStarted();
		this.removeChild(this.softMoneyPanelView);

		new TWEEN.Tween({ alpha: 1 })
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.bgRight.alpha = tweenTarget.alpha;
				this.bgLeft.alpha = tweenTarget.alpha;
				this.prestigeMoneyPanel.alpha = tweenTarget.alpha;
				this.hardMoneyPanel.alpha = tweenTarget.alpha;
				this.buttonSettings.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.bgRight.visible = false;
				this.bgLeft.visible = false;
				this.prestigeMoneyPanel.visible = false;
				this.hardMoneyPanel.visible = false;
				this.buttonSettings.visible = false;
			})
			.start();
	}

	public onZoomOutStarted(): void {
		this.softMoneyPanelView.onZoomOutStarted();
		this.addChild(this.softMoneyPanelView);

		this.bgRight.visible = true;
		this.bgLeft.visible = true;
		this.prestigeMoneyPanel.visible = true;
		this.hardMoneyPanel.visible = true;
		this.buttonSettings.visible = true;

		new TWEEN.Tween({ alpha: 0 })
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.bgRight.alpha = tweenTarget.alpha;
				this.bgLeft.alpha = tweenTarget.alpha;
				this.prestigeMoneyPanel.alpha = tweenTarget.alpha;
				this.hardMoneyPanel.alpha = tweenTarget.alpha;
				this.buttonSettings.alpha = tweenTarget.alpha;
			})
			.start();
	}

	public getSoftMoneyPanel(): SoftMoneyPanelView {
		return this.softMoneyPanelView;
	}

	public getPrestigeMoneyPanel(): PrestigeMoneyPanelView {
		return this.prestigeMoneyPanel;
	}

	public getHardMoneyPanel(): HardMoneyPanelView {
		return this.hardMoneyPanel;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_REWARD_COLLECTED, this.updateButtonSettingsInfoIconVisible, this, true);

		super.destroy(options);
	}
}
