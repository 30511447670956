import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class BankPrepareTransactionWindow extends PopupWindowBaseView {
	private readonly tapToContinueText: PIXI.extras.BitmapText;
	private readonly text: MultiColoredTextField;

	private tapToContinue: TWEEN.Tween;

	constructor() {
		super(0.5, true, true, false);

		const localizationStorage = LocalizationStorage.getInstance();
		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		const girl = new PIXI.Sprite(AssetsStorage.getResource('character2_offer').texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.position.set(610, 80);
		girl.scale.x = -1;

		const bubbleTail = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['bubble_tail_main']);
		bubbleTail.position.set(371, -138);

		const bubbleBg = new PIXI.mesh.NineSlicePlane(uiAtlas['bubble_main'], 5, 10, 5, 6);
		bubbleBg.width = 720;
		bubbleBg.height = 415;
		bubbleBg.pivot.set(bubbleBg.width / 2, bubbleBg.height / 2);
		bubbleBg.position.set(-10, -25);

		const bgFade = new PIXI.Graphics()
			.beginFill(0xcaced2)
			.drawRect(-10, 135, 712, 121)
			.endFill();
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

		this.text = new MultiColoredTextField(
			{ font: '30px wendyOne', align: 'center', tint: 0x2e374b },
			600,
			300,
		);
		this.text.anchor = 0.5;
		this.text.y = -65;
		this.text.text = localizationStorage.getLocalizedString('#bank_open_transaction_title');

		this.tapToContinueText = new PIXI.extras.BitmapText('', {
			font: '25px wendyOne',
			tint: 0x7e8a98,
		});
		this.tapToContinueText.anchor = 0.5;
		this.tapToContinueText.y = 135;
		this.tapToContinueText.text = localizationStorage.getLocalizedString('#tutorial_label_tap_to_continue');

		this.mainContainer.addChild(
			girl,
			bubbleBg as PIXI.DisplayObject,
			bubbleTail,
			bgFade,
			this.text,
			this.tapToContinueText,
		);

		this.createTween();
	}

	public onClose(): void {
		super.onClose(false);
	}

	private createTween(): void {
		this.tapToContinue = new TWEEN.Tween(this.tapToContinueText.scale)
			.to({ y: 1.05, x: 1.05 }, 400)
			.easing(TWEEN.Easing.Bounce.InOut)
			.repeat(Infinity)
			.yoyo(true)
			.start();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tapToContinue.stop();
		super.destroy(options);
	}
}
