import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';

export type PreloaderButtonDataType = {
	text: string;
	link: string;
};

export abstract class BaseLinksView extends PIXI.Container {
	public static BAR_HEIGHT = 150;
	public static BAR_OFFSET = 0;
	protected readonly buttonsContainer: PIXI.Container;
	protected readonly linksContainer: PIXI.Container;

	constructor() {
		super();

		const bg = new PIXI.Graphics()
			.beginFill(0x000000, 0.4)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, BaseLinksView.BAR_HEIGHT)
			.endFill();

		this.buttonsContainer = new PIXI.Container();

		let offset = 0;
		const buttonsData = this.getButtonsData();
		buttonsData.forEach((buttonElem) => {
			const button = new PIXI.extras.BitmapText(buttonElem.text, {
				font: '30px wendyOne',
				tint: 0xc4ab64,
			});
			button.anchor = 0.5;
			button.interactive = true;
			button.position.set(button.width / 2 + offset, button.height / 2);
			button.on('pointerover', () => {
				BaseLinksView.scaleButton(button, 1.07, 1.07);
			}, button);
			button.on('pointerout', () => {
				BaseLinksView.scaleButton(button, 1, 1);
			}, button);
			button.on('pointerdown', () => window.open(buttonElem.link), button);

			const line = new PIXI.Graphics()
				.lineStyle(3, 0xc4ab64)
				.moveTo(-button.width / 2, 15)
				.lineTo(button.width / 2, 15)
				.closePath();
			button.addChild(line);

			this.buttonsContainer.addChild(button);

			offset += (button.width + 30);
		});

		this.linksContainer = new PIXI.Container();
		this.linksContainer.addChild(
			this.buttonsContainer,
		);

		this.linksContainer.position.set(
			GameConstants.GAME_CENTER_X - (this.linksContainer.width / 2),
			(BaseLinksView.BAR_HEIGHT - BaseLinksView.BAR_OFFSET) / 2 - this.linksContainer.height / 2,
		);

		this.addChild(
			bg as PIXI.DisplayObject,
			this.linksContainer,
		);
	}

	protected abstract getButtonsData(): PreloaderButtonDataType[];

	private static scaleButton(elem: PIXI.Container, targetX: number, targetY: number): void {
		new TWEEN.Tween(elem.scale)
			.to({ x: targetX, y: targetY }, 50)
			.easing(TWEEN.Easing.Quadratic.In)
			.start();
	}
}
