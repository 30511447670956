/* eslint-disable camelcase */
export type BankBundleConfigRaw = {
	bundle_desc: string;
	bundle_group: string;
	bundle_title: string;
	buy_times: string;
	id: string;
	invisible: string;
	lock_desc: string;
	lootbox: string;
	lot_id: string;
	most_popular: string;
	name: string;
	offer_id: string;
	prefab_key: string;
	prev_count: string;
	prev_price: string;
	prev_price_resource: string;
	price: string;
	price_resource: string;
	priority_view: string;
	refresh_period: string;
	reward_count: string;
	reward_id: string;
	reward_type: string;
	sticker: string;
	time: string;
	unlock_type: string;
	unlock_value: string;
	view_parameters: string;
	vip_activation_time: string;
}

export type BankBundleGroupConfigRaw = {
	offer_id: string;
	id: string;
	buy_times: string;
	character_view_type: string;
	hard_lock_type: string;
	hard_lock_value: string;
	unlock_type: string;
	unlock_value: string;
}

export type BankOfferConfigRaw = {
	activate_times: string;
	active_on_event_cooldown: string;
	chance: string;
	discount: string;
	duration: string;
	end_date: string;
	ends_on_locked: string;
	event_id: string;
	hard_lock_type: string;
	hard_lock_value: string;
	id: string;
	localization_keys: string;
	move_to_window: string;
	offer_window_prefab_type: string;
	show_on_mainwindow: string;
	start_date: string;
	type: string;
	unlock_type: string;
	unlock_value: string;
	view_parameters: string;
	priority_view: string;
}

export enum BankViewTypes {
	GEM_SHOP_DEFAULT = 'bank_item_prefab',
	GEM_SHOP_TIMESKIP = 'timeskip_prefab',
	GEM_SHOP_BOOST = 'boost_prefab',
	RECHARGE_1 = 'recharge_1',
	BUNDLE_PREFAB = 'bundle_prefab',
	SUBSCRIBE = 'subscribe',
	HEADER_SUMMON = 'header_summon',
	COMPARE_LEFT = 'compare_left',
	COMPARE_RIGHT = 'compare_right',
	DAILY_BUNDLE_PREFAB = 'daily_bundle_prefab',
	WEEKLY_BUNDLE_PREFAB = 'weekly_bundle_prefab',
	MONTHLY_BUNDLE_PREFAB = 'monthly_bundle_prefab',
}

export enum BankTabLayoutTypes {
	ONE_ITEM = 'one_item',
	HEADER_SLOTS = 'header_slots',
	EIGHT_SLOTS = '8_slots',
	SCROLL_SLOTS = 'scroll_slots',
	ONE_BUNDLE_SIX_SLOTS_TIMER = 'one_bundle_6_slots_timer',
	DOUBLE_OFFER = 'doubleoffers',
	TIMED_BUNDLES = 'timed_bundles',
	DAILY_BUNDLES = 'daily_slots',
}

export enum BankTabTypes {
	BUNDLES = 'bundles',
	RECHARGE = 'recharge',
	GEM_SHOP = 'gem_shop',
	SUBSCRIBE = 'subscribe',
	BOOSTERS = 'boosters',
	DOUBLE_OFFERS = 'doubleoffers',
}

export enum BankPriceResourceTypes {
	REAL = 'real',
	HARD_MONEY = 'hard_money',
	PRESTIGE_MONEY = 'prestige_money'
}

export enum BankTabElementDataConfigNameTypes {
	TABS = 'BankTabs',
	RECHARGE = 'Recharge',
	BUNDLES = 'Bundles',
	GEM_SHOP = 'GemShop',
	SUBSCRIBE = 'Subscribe',
	OFFERS = 'Offers',
	BUNDLE_GROUPS = 'BundleGroups',
}

export enum BankOfferMoveFromOfferWindowType {
	BANK_WINDOW = 'BankWindow',
	PURCHASE = 'PurchaseWindow',
}

export enum BankOfferWindowViewType {
	DISCOUNT_1 = 'Discount2',
	DISCOUNT_2 = 'Discount1',
	DISCOUNT_3 = 'Discount3',
	MAIN_PACK = 'MainPack',
	PACK_1 = 'Pack1',
	PACK_2 = 'Pack2',
	PACK_3 = 'Pack3',
	PACK_4 = 'Pack4',
	COMPARE = 'compare'
}

export type BankOfferWindowOriginType = string;

export enum BankOfferWindowOriginTypes {
	BANK_OFFER_LINES_UI = 'bankOfferLinesUI',
	BANK_OFFER_WINDOWS_EMITTER = 'bankOfferWindowsEmitter',
}

export type BankWindowOriginType = string;

export enum BankWindowOriginTypes {
	BANK_OFFER_WINDOW = 'bankOfferWindow',
}

export type BankPurchaseOriginType = {
	bankOfferWindowOrigin?: BankOfferWindowOriginType;
	bankWindowOrigin?: BankWindowOriginType;
}