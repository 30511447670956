import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter55007View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55007View';
import { EventCharacter55008View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55008View';
import { EventCharacter55009View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55009View';

export class EventBusiness55003View extends BusinessBaseView {
	constructor() {
		const eventCharacter55007View = new EventCharacter55007View();
		const eventCharacter55008View = new EventCharacter55008View();
		const eventCharacter55009View = new EventCharacter55009View();

		super('business55003', [eventCharacter55007View, eventCharacter55008View, eventCharacter55009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter55007View as PIXI.DisplayObject,
			eventCharacter55008View,
			eventCharacter55009View,
		);
	}
}
