import { EventLevelModel, EventLevelStateType } from '@models/level/EventLevelModel';

export class GoToEventLevelWindowEmitter extends PIXI.utils.EventEmitter {
	public static readonly EVENT_GOTO_EVENT_LEVEL_WINDOW: symbol = Symbol();

	private enabled: boolean;
	private pendingWindow: boolean;

	constructor(
		private readonly eventLevelModel: EventLevelModel,
	) {
		super();

		this.eventLevelModel.on(EventLevelModel.EVENT_INITED, this.onEventLevelModelInited, this);
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		if (this.enabled && this.pendingWindow) {
			this.pendingWindow = false;
			this.emit(GoToEventLevelWindowEmitter.EVENT_GOTO_EVENT_LEVEL_WINDOW);
		}
	}

	private onEventLevelModelInited(): void {
		if (this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE) {
			if (this.enabled) {
				this.emit(GoToEventLevelWindowEmitter.EVENT_GOTO_EVENT_LEVEL_WINDOW);
			} else {
				this.pendingWindow = true;
			}
		}
	}
}
