import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export class IncomeHighSpeedProgressBarView extends PIXI.Container {
	private readonly highSpeedBarMask: PIXI.Graphics;
	private readonly barContainer: PIXI.Container;

	private readonly progressBar: PIXI.mesh.NineSlicePlane;
	private readonly uiAtlas: PIXI.loaders.TextureDictionary;
	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly content: PIXI.Container;

	private automaticIncomeTween: TWEEN.Tween;

	constructor(barWidth: number) {
		super();


		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.progressBar = new PIXI.mesh.NineSlicePlane(
			AssetsStorage.getAtlas('uiAtlas')['u_bar_green'], 0, 0, 0, 0,
		);
		this.progressBar.width = barWidth;
		this.progressBar.height = 38;
		this.progressBar.pivot.set(0, this.progressBar.height / 2);
		this.progressBar.position.set(-210, 0);

		this.barContainer = new PIXI.Container();
		const itemsCount = (this.progressBar.width * 1.2) / (new PIXI.Sprite().width * 1.5);
		for (let i = 0; i < itemsCount; i++) {
			const item = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_bar_green_automatic']);
			item.alpha = 0.6;
			item.position.set((-this.progressBar.width / 2 - this.progressBar.width * 0.2) + (item.width * 1.5 * i), 0);
			this.barContainer.addChild(item);
		}

		this.highSpeedBarMask = new PIXI.Graphics()
			.beginFill(0x00FFFF)
			.drawRoundedRect(-210, 0, this.progressBar.width, this.progressBar.height, this.progressBar.rightWidth)
			.endFill();
		this.highSpeedBarMask.pivot.set(0, this.highSpeedBarMask.height / 2);
		this.barContainer.mask = this.highSpeedBarMask;

		this.content = new PIXI.Container();

		this.content.addChild(
			this.progressBar,
			this.barContainer,
			this.highSpeedBarMask,
		);

		this.addChild(
			this.content,
		);
	}

	public onZoomIn(): void {
		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
	}

	public onZoomOut(): void {
		new TWEEN.Tween(this.content, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
	}

	public startAnimation(): void {
		this.automaticIncomeTween = new TWEEN.Tween(this.barContainer, this.tweenGroup)
			.to({ x: this.progressBar.width * 0.2 - new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_bar_green_automatic']).width / 2 + 12 }, 800)
			.repeat(Infinity)
			.start();
	}

	public isAnimating(): boolean {
		return this.automaticIncomeTween !== null && this.automaticIncomeTween !== undefined;
	}

	public stopAnimation(): void {
		this.automaticIncomeTween.stop();
		this.automaticIncomeTween = null;
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.automaticIncomeTween) {
			this.automaticIncomeTween.stop();
		}

		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweenGroup, this);

		super.destroy(options);
	}
}
