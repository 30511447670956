import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter53004View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53004View';
import { EventCharacter53005View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53005View';
import { EventCharacter53006View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53006View';

export class EventBusiness53002View extends BusinessBaseView {
	constructor() {
		const eventCharacter53004View = new EventCharacter53004View();
		const eventCharacter53005View = new EventCharacter53005View();
		const eventCharacter53006View = new EventCharacter53006View();

		super('business53002', [eventCharacter53004View, eventCharacter53005View, eventCharacter53006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter53004View as PIXI.DisplayObject,
			eventCharacter53005View,
			eventCharacter53006View,
		);
	}
}
