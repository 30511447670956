import { EventLevelInstance } from './EventLevelInstance';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { CheatModel } from '@models/CheatModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { EventLevelConfig } from '@configs/level/EventLevelConfig';
import { EventLevelChooser, EventLevelChooserEventArgs, EventLevelChoosenState } from './EventLevelChooser';
import { EventLevelInitData } from '@src/types/SaveTypes';
import { LeagueBotModel } from '@models/LeagueBotModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BaseAction } from '@models/network/actions/BaseAction';
import { EventLevelStartedAction } from '@models/network/actions/events/EventLevelStartedAction';
import { EventLevelConfigsParser } from '@configs/configsParsers/EventLevelConfigsParser';
import { NetworkProfileLoader } from '@src/loaders/NetworkProfileLoader';
import { EventLevelDateConfig } from '@configs/EventLevelDateConfig';
import { EventLevelCompletedAction } from '@models/network/actions/events/EventLevelCompletedAction';
import { AssetLoaderUtils } from '@src/utils/AssetLoaderUtils';
import { EventLevelAssetNames } from '@src/types/EventLevelAssetNames';
import { AnalyticSourceBankViews } from '@src/network/analytics/sources/AnalyticSourceBankViews';
import { AnalyticSourceDialogWindowView } from '@src/network/analytics/sources/AnalyticSourceDialogWindowView';
import { AnalyticSourceFarewellPartyWindowView } from '@src/network/analytics/sources/AnalyticSourceFarewellPartyWindowView';
import { LevelModelInstance } from './modelInstance/LevelModelInstance';
import { EventLevelModelInstanceFactory } from '@src/initializers/instances/EventLevelModelInstanceFactory';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { MainWindowTarget, MainWindowElementConfig } from '@configs/MainWindowConfig';
import { AssetLoader } from '@src/utils/AssetLoader';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';

export class EventLevelSystem extends PIXI.utils.EventEmitter {
	public static readonly EVENT_STARTED: symbol = Symbol();
	public static readonly EVENT_CLOSE: symbol = Symbol();
	public static readonly EVENT_RETURN_TO_LEVEL: symbol = Symbol();
	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	private static getPlayerLeaguePlaceId(
		leagueBotModels: LeagueBotModel[],
		eventRunningTime: number,
		incomePerSec: SoftMoneyNumber,
	): number {
		const botsSorted = leagueBotModels.sort((a, b) => {
			const scoreA = a.getScore(eventRunningTime);
			const scoreB = b.getScore(eventRunningTime);
			return scoreB.subtract(scoreA).toNumber();
		});

		let playerLeaguePlaceId = botsSorted
			.findIndex(bot => bot.getScore(eventRunningTime).lessThanOrEqualTo(incomePerSec));
		if (playerLeaguePlaceId === -1) {
			playerLeaguePlaceId = botsSorted.length;
		}
		return playerLeaguePlaceId;
	}

	private readonly levelRewardFactory: RewardFactory;

	private readonly runInEpisodeAssetsSandbox: SandboxOperation;
	private readonly stage: PIXI.Container;
	private readonly interactionManager: PIXI.interaction.InteractionManager;
	private readonly env: string;
	private readonly savesConfig: SavesConfig;
	private readonly cheatModel: CheatModel;
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly serverTimeModel: ServerTimeModel;
	private readonly gameProfileModel: GameProfileModel;
	private readonly constantsConfig: ConstantsConfig;
	private readonly localizationSupportConfig: LocalizationSupportConfig;
	private readonly networkLoader: NetworkProfileLoader;
	private readonly noviceEventCompletedOn: number;
	private readonly bankPriceItemsConfig: BankPriceItemsConfig;
	private readonly levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>;

	private readonly eventLevelConfigsParser: EventLevelConfigsParser;

	private readonly analyticSourceBankViews: AnalyticSourceBankViews;
	private readonly analyticSourceDialogWindowView: AnalyticSourceDialogWindowView;
	private readonly analyticSourceFarewellPartyWindowView: AnalyticSourceFarewellPartyWindowView;

	private readonly eventLevelModelInstanceFactory: EventLevelModelInstanceFactory;
	private readonly levelModelInstance: LevelModelInstance;

	private eventLevelChooser: EventLevelChooser;
	private eventLevelInstance: EventLevelInstance;

	private eventLevelEverEntered: boolean;
	private eventLevelEverInited: boolean;

	private noviceEventCompleted: boolean;
	private eventSaveData?: EventLevelInitData;

	constructor(
		networkLoader: NetworkProfileLoader,
		networkRequestSender: NetworkRequestSender,
		stage: PIXI.Container,
		interactionManager: PIXI.interaction.InteractionManager,
		env: string,
		savesConfig: SavesConfig,
		bankPriceItemsConfig: BankPriceItemsConfig,
		private promotePatternsConfig: AbstractPromotePatternsConfig,
		localizationSupportConfig: LocalizationSupportConfig,
		levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>,
		constantsConfig: ConstantsConfig,
		eventLevelModelInstanceFactory: EventLevelModelInstanceFactory,
		levelModelInstance: LevelModelInstance,
		cheatModel: CheatModel,
		serverTime: ServerTimeModel,
		gameProfileModel: GameProfileModel,
		runInEpisodeAssetsSandbox: SandboxOperation,
		private readonly isNutaku: boolean,
		private readonly assetUrlsLoader: AssetUrlsLoader,
		private readonly version: string,
	) {
		super();

		this.eventLevelModelInstanceFactory = eventLevelModelInstanceFactory;
		this.levelModelInstance = levelModelInstance;
		this.networkLoader = networkLoader;
		this.stage = stage;
		this.interactionManager = interactionManager;
		this.env = env;
		this.cheatModel = cheatModel;
		this.networkRequestSender = networkRequestSender;
		this.bankPriceItemsConfig = bankPriceItemsConfig;
		this.serverTimeModel = serverTime;
		this.gameProfileModel = gameProfileModel;
		this.levelRewardFactory = levelModelInstance.getRewardFactory();
		this.runInEpisodeAssetsSandbox = runInEpisodeAssetsSandbox;
		this.savesConfig = savesConfig;
		this.constantsConfig = constantsConfig;
		this.localizationSupportConfig = localizationSupportConfig;
		this.levelBaseWindowConfig = levelBaseWindowConfig;

		this.noviceEventCompleted = this.savesConfig.isEventLevelNoviceCompleted();
		this.noviceEventCompletedOn = this.savesConfig.getEventLevelNoviceCompletedOn();
		this.eventSaveData = this.savesConfig.getEventLevelInitData();
		this.eventLevelEverEntered = this.savesConfig.isEventLevelEverEntered();
		this.eventLevelEverInited = this.savesConfig.isEventLevelEverInited();

		this.eventLevelConfigsParser = new EventLevelConfigsParser();

		this.analyticSourceBankViews = new AnalyticSourceBankViews();
		this.analyticSourceDialogWindowView = new AnalyticSourceDialogWindowView();
		this.analyticSourceFarewellPartyWindowView = new AnalyticSourceFarewellPartyWindowView();
	}

	public init(): void {
		this.networkLoader.once(NetworkProfileLoader.EVENT_EVENT_CONFIGS_LOADED, (data: Record<string, unknown>) => {
			this.eventLevelConfigsParser.parseConfigs(
				data,
			);

			if (this.eventSaveData != null) {
				this.eventLevelConfigsParser.onLevelChosen(data, this.eventSaveData.eventKey);
			}

			const currentLevel = this.levelModelInstance.getLevelModel().getCurrentLevel();
			this.eventLevelChooser = new EventLevelChooser(
				this.serverTimeModel,
				this.eventLevelConfigsParser.getEventLevelsConfigs(),
				this.eventLevelConfigsParser.getEventDatesConfigs(),
				currentLevel,
				this.constantsConfig.getNoviceEventKey(),
				this.constantsConfig.getNoviceEventMinLevel(),
				this.constantsConfig.getMainEventDelay(),
				this.cheatModel.isNoviceEventEnabled() ? this.noviceEventCompletedOn : 0,
			);
			this.eventLevelChooser.on(EventLevelChooser.EVENT_CHOSEN, (args: EventLevelChooserEventArgs) => {
				this.onEventLevelChosen(args);
				this.eventLevelConfigsParser.onLevelChosen(data, args.levelConfig.getKey());
			});

			this.initEventLevelChooser();
		}, this);
		this.networkLoader.loadEventConfigs();
	}

	public tryChooseEventLevel(): void {
		if (!this.eventLevelInstance) {
			this.initEventLevelChooser();
		}
	}

	public startEventLevel(): void {
		this.eventLevelInstance.startEventLevel();
	}

	public closeEventLevel(): void {
		this.closeCurrentEventLevel();
	}

	public setEnabled(value: boolean): void {
		this.eventLevelInstance.setEnabled(value);
	}

	public isEnabled(): boolean {
		return this.eventLevelInstance?.isEnabled();
	}

	public isStarted(): boolean {
		return this.eventLevelInstance?.isStarted();
	}

	public isEventLevelEverEntered(): boolean {
		return this.eventLevelEverEntered;
	}

	private initEventLevelChooser(): void {
		if (this.eventSaveData) {
			const eventLevelConfigs = this.eventLevelConfigsParser.getEventLevelsConfigs();
			const levelConfig = eventLevelConfigs.get(this.eventSaveData.eventKey);
			const dateConfigs = Array.from(this.eventLevelConfigsParser.getEventDatesConfigs().values());

			const currentTime = this.serverTimeModel.getCalculatedISOTime();
			const dateConfig = dateConfigs
				.find((config) => config.getEventLevelKey() === levelConfig.getKey()
					&& config.getStartDate() < currentTime
					&& config.getStartDate() + levelConfig.getDuration() >= currentTime);

			this.onEventLevelAvailable(levelConfig, dateConfig);
		} else {
			this.eventLevelChooser.setCurrentLevel(
				this.levelModelInstance.getLevelModel().getCurrentLevel(),
			);
			this.eventLevelChooser.choose();
		}
	}

	private onEventLevelChosen(args: EventLevelChooserEventArgs): void {
		const eventLevelModel = this.levelModelInstance.getEventLevelModel();
		if (args.state === EventLevelChoosenState.CHOSEN_AVAILABLE) {
			this.onEventLevelAvailable(args.levelConfig, args.dateConfig);
		} else if (args.state === EventLevelChoosenState.CHOSEN_PROMO) {
			eventLevelModel.setFromConfig(args.levelConfig, args.dateConfig, this.serverTimeModel.getCalculatedISOTime());
			eventLevelModel.initWithStateShowPromo();
		}
	}

	private onEventLevelAvailable(
		levelConfig: EventLevelConfig,
		dateConfig?: EventLevelDateConfig,
	): void {
		const bootAssetsLoader = new AssetLoader(this.assetUrlsLoader);

		bootAssetsLoader.on('complete', () => {
			bootAssetsLoader.destroy();
			this.onBootAssetsLoaded(levelConfig, dateConfig);
		});

		bootAssetsLoader.addAssetsToLoad(
			[[EventLevelAssetNames.getEventPreloaderBgMetadata(levelConfig.getKey(), this.isNutaku)]],
		);
		AssetLoaderUtils.addAssetsStorageCacheToLoaders(bootAssetsLoader);

		bootAssetsLoader.load();
	}

	private onBootAssetsLoaded(
		levelConfig: EventLevelConfig,
		dateConfig?: EventLevelDateConfig,
	): void {
		if (!this.eventSaveData) {
			this.emitEventStartedAction(levelConfig.getKey());
		}

		const eventLevelModel = this.levelModelInstance.getEventLevelModel();
		eventLevelModel.setFromConfig(levelConfig, dateConfig, this.serverTimeModel.getCalculatedISOTime());

		this.eventLevelInstance = this.createEventLevelInstance();
		this.eventLevelInstance.on(BaseAction.EVENT_ACTION_CREATED, (action: BaseAction) => {
			const withEventLevelId = true;
			this.emit(BaseAction.EVENT_ACTION_CREATED, action, withEventLevelId);
		});
		this.eventLevelInstance.on(EventLevelInstance.EVENT_STARTED, () => {
			this.emit(EventLevelSystem.EVENT_STARTED);
		});
		this.eventLevelInstance.on(EventLevelInstance.EVENT_CLOSE, () => {
			this.emit(EventLevelSystem.EVENT_CLOSE);
		});
		this.eventLevelInstance.on(EventLevelInstance.EVENT_DESTROY, () => {
			this.destroyCurrentEventLevel();
		});
		this.eventLevelInstance.on(EventLevelInstance.EVENT_RETURN_TO_LEVEL, () => {
			this.emit(EventLevelSystem.EVENT_RETURN_TO_LEVEL);
		}, this);
		this.eventLevelInstance.on(EventLevelInstance.EVENT_LOCALIZATION_CHANGE, (lang: string) => {
			this.emit(EventLevelSystem.EVENT_LOCALIZATION_CHANGE, lang);
		}, this);
	}

	private closeCurrentEventLevel(): void {
		this.eventSaveData = null;

		if (!this.noviceEventCompleted) {
			this.noviceEventCompleted = true;
			this.eventLevelChooser.setNoviceEventCompleted();
		}

		this.eventLevelEverEntered = false;
		this.eventLevelEverInited = false;
	}

	private destroyCurrentEventLevel(): void {
		this.emitEventCompletedAction();

		this.eventLevelInstance.destroy();
		this.eventLevelInstance = null;

		this.eventLevelChooser.choose();
	}

	private emitEventCompletedAction(): void {
		const eventLevelModel = this.levelModelInstance.getEventLevelModel();
		const playerLeaguePlaceId = EventLevelSystem.getPlayerLeaguePlaceId(
			eventLevelModel.getLeagueBotModels(),
			eventLevelModel.getRunningTime(),
			eventLevelModel.getIncomePerSec(),
		);
		const action = new EventLevelCompletedAction(
			eventLevelModel.getKey(),
			playerLeaguePlaceId,
		);

		const withEventLevelId = false;
		this.emit(BaseAction.EVENT_ACTION_CREATED, action, withEventLevelId);
	}

	private emitEventStartedAction(key: string): void {
		const action = new EventLevelStartedAction(key);

		const withEventLevelId = true;
		this.emit(BaseAction.EVENT_ACTION_CREATED, action, withEventLevelId);
	}

	private createEventLevelInstance(): EventLevelInstance {
		const eventLevelKey = this.levelModelInstance.getEventLevelModel().getKey();

		const eventLevelModelInstance = this.eventLevelModelInstanceFactory.createEventLevelModelInstance(
			this.levelModelInstance,
			this.serverTimeModel,
			{
				configs: this.eventLevelConfigsParser.getCharactersConfigs(eventLevelKey),
				savesData: this.savesConfig.getCharactersSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getSkillsConfigs(eventLevelKey),
				savesData: this.savesConfig.getEventLevelSkillsSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getUpgradesConfigs(eventLevelKey),
				savesData: this.savesConfig.getUpgradesSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getBusinessesConfigs(eventLevelKey),
				savesData: this.savesConfig.getBusinessesSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getBoostsConfigs(eventLevelKey),
				savesData: this.savesConfig.getEventLevelBoostsSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getTimeskipsConfigs(eventLevelKey),
				savesData: this.savesConfig.getEventLevelTimeskipsSaveData(),
			},
			{
				configs: this.eventLevelConfigsParser.getTutorialStepConfigs(),
				savesData: this.savesConfig.getEventTutorialStepsSaveData(),
				emitterEnabled: this.cheatModel && this.cheatModel.isTutorialEnabled(),
			},
			{
				configs: this.eventLevelConfigsParser.getFarewellPartiesConfigs(),
				constants: this.constantsConfig.getFarewellPartyValues(),
			},
			this.eventLevelConfigsParser.getDialogsConfigs(),
			this.constantsConfig,
			this.eventLevelConfigsParser.getPromotePatternsConfig(),
			this.bankPriceItemsConfig,
			this.eventLevelEverEntered ? this.savesConfig.getEventLevelNewCustomersMultiplierRewards() : [],
			this.savesConfig.getEventLevelBankSaveData(),
			this.savesConfig.getEventFarewellBoost(),
			this.isNutaku,
		);

		return new EventLevelInstance(
			this.stage,
			this.interactionManager,
			this.env,
			this.analyticSourceFarewellPartyWindowView,
			this.analyticSourceBankViews,
			this.analyticSourceDialogWindowView,
			eventLevelModelInstance,
			this.levelModelInstance,
			this.cheatModel,
			this.networkRequestSender,
			this.serverTimeModel,
			this.gameProfileModel,
			this.levelRewardFactory,
			this.eventLevelConfigsParser.getTutorialStepConfigs(),
			this.eventLevelConfigsParser.getLeaguesConfigs(),
			this.eventLevelConfigsParser.getLeagueBotsConfigs(),
			this.eventLevelConfigsParser.getDialogsConfigs(),
			this.eventLevelConfigsParser.getQuestsConfigs(),
			this.constantsConfig,
			this.localizationSupportConfig,
			this.savesConfig,
			this.levelBaseWindowConfig,
			this.runInEpisodeAssetsSandbox,
			this.eventLevelEverEntered,
			this.eventLevelEverInited,
			this.isNutaku,
			this.assetUrlsLoader,
			this.version,
		);
	}

	public getAnalyticSourceBankViews(): AnalyticSourceBankViews {
		return this.analyticSourceBankViews;
	}

	public getAnalyticSourceDialogWindowView(): AnalyticSourceDialogWindowView {
		return this.analyticSourceDialogWindowView;
	}

	public getAnalyticsSourceFarewellPartyView(): AnalyticSourceFarewellPartyWindowView {
		return this.analyticSourceFarewellPartyWindowView;
	}
}
