import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BusinessConfig } from '@configs/BusinessConfig';
import CharacterConfig from '@configs/CharacterConfig';
import { SkillConfig } from '@configs/SkillConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { QuestsConfigSetParser } from '@configs/configsParsers/configSets/QuestsConfigSetParser';
import { ConfigsParserBase } from './ConfigsParserBase';
import { EventLevelsConfigSetParser } from '@configs/configsParsers/configSets/EventLevelsConfigSetParser';
import { EventLevelConfig } from '@configs/level/EventLevelConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { EventLevelDatesConfigSetParser } from '@configs/configsParsers/configSets/EventLevelDatesConfigSetParser';
import { EventLevelDateConfig } from '@configs/EventLevelDateConfig';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { DialogsConfigSetParser } from './configSets/DialogsConfigSetParser';
import { DialogConfig } from '@configs/DialogConfig';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { TutorialStepsEventConfigSetParser } from './configSets/TutorialStepsEventConfigSetParser';
import { EventLevelConfigNames } from '@src/types/EventLevelConfigNames';
import { LeagueBotsConfigSetParser } from './configSets/LeagueBotsConfigSetParser';
import { LeagueBotConfig } from '@configs/LeagueBotConfig';
import { LeaguesConfigSetParser } from './configSets/LeaguesConfigSetParser';
import { EventLevelLeagueConfig } from '@configs/EventLevelLeagueConfig';
import { EventLevelSkillsConfigSetParser } from './configSets/events/EventLevelSkillsConfigSetParser';
import { EventLevelBoostsConfigSetParser } from './configSets/events/EventLevelBoostsConfigSetParser';
import { EventLevelTimeskipsConfigSetParser } from './configSets/events/EventLevelTimeskipsConfigSetParser';
import { EventLevelCharactersConfigSetParser } from './configSets/events/EventLevelCharactersConfigSetParser';
import { EventLevelBaseConfigSetParser } from './configSets/EventLevelBaseConfigSetParser';
import { EventLevelUpgradesConfigSetParser } from './configSets/events/EventLevelUpgradesConfigSetParser';
import { EventLevelBusinessesConfigSetParser } from './configSets/events/EventLevelBusinessesConfigSetParser';
import { FarewellPartyConfig } from '@configs/FarewellPartyConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { EventPromotePatternsConfig } from '@configs/EventPromotePatternsConfig';
import { FarewellPartyConfigSetParser } from './configSets/FarewellPartyConfigSetParser';

export class EventLevelConfigsParser extends ConfigsParserBase {
	private readonly baseConfigSetMetadata: {
		controller: BaseConfigSetParser<ConfigSetElement> | EventLevelBaseConfigSetParser<ConfigSetElement>;
		name: string;
	}[];

	private readonly businessesConfigSetParser: EventLevelBusinessesConfigSetParser;
	private readonly upgradesConfigSetParser: EventLevelUpgradesConfigSetParser;
	private readonly levelsConfigSetParser: EventLevelsConfigSetParser;
	private readonly skillsConfigSetParser: EventLevelSkillsConfigSetParser;
	private readonly questsConfigSetParser: QuestsConfigSetParser;
	private readonly tutorialStepsConfigSetParser: TutorialStepsEventConfigSetParser;
	private readonly boostsConfigSetParser: EventLevelBoostsConfigSetParser;
	private readonly timeskipsConfigSetParser: EventLevelTimeskipsConfigSetParser;
	private readonly eventDatesConfigSetParser: EventLevelDatesConfigSetParser;
	private readonly charactersConfigSetParser: EventLevelCharactersConfigSetParser;
	private readonly dialogsConfigSetParser: DialogsConfigSetParser;
	private readonly leagueBotsConfigSetParser: LeagueBotsConfigSetParser;
	private readonly leaguesConfigSetParser: LeaguesConfigSetParser;
	private readonly farewellPartyConfigSetParser: FarewellPartyConfigSetParser;
	private bankPriceItemsConfig: BankPriceItemsConfig;
	private promotePatternsConfig?: AbstractPromotePatternsConfig;

	constructor() {
		super();

		this.businessesConfigSetParser = new EventLevelBaseConfigSetParser(BusinessConfig.FIELD_KEY, BusinessConfig);
		this.charactersConfigSetParser = new EventLevelBaseConfigSetParser(CharacterConfig.FIELD_KEY, CharacterConfig);
		this.farewellPartyConfigSetParser = new BaseConfigSetParser(FarewellPartyConfig.FIELD_KEY, FarewellPartyConfig);
		this.skillsConfigSetParser = new EventLevelBaseConfigSetParser(SkillConfig.FIELD_KEY, SkillConfig);
		this.levelsConfigSetParser = new BaseConfigSetParser(EventLevelConfig.FIELD_EVENT_ID, EventLevelConfig);
		this.dialogsConfigSetParser = new DialogsConfigSetParser(DialogConfig.FIELD_KEY, DialogConfig);
		this.upgradesConfigSetParser = new EventLevelBaseConfigSetParser(UpgradeConfig.FIELD_KEY, UpgradeConfig);
		this.questsConfigSetParser = new QuestsConfigSetParser();
		this.boostsConfigSetParser = new EventLevelBaseConfigSetParser(BoostConfig.FIELD_KEY, BoostConfig);
		this.timeskipsConfigSetParser = new EventLevelBaseConfigSetParser(TimeskipConfig.FIELD_KEY, TimeskipConfig);
		this.tutorialStepsConfigSetParser = new BaseConfigSetParser(TutorialStepEventLevelConfig.FIELD_KEY, TutorialStepEventLevelConfig);
		this.eventDatesConfigSetParser = new BaseConfigSetParser(EventLevelDateConfig.FIELD_KEY, EventLevelDateConfig);
		this.leagueBotsConfigSetParser = new BaseConfigSetParser(LeagueBotConfig.FIELD_KEY, LeagueBotConfig);
		this.leaguesConfigSetParser = new BaseConfigSetParser(EventLevelLeagueConfig.FIELD_KEY, EventLevelLeagueConfig);

		this.baseConfigSetMetadata = [
			{ controller: this.businessesConfigSetParser, name: EventLevelConfigNames.CONFIG_BUSINESSES },
			{ controller: this.skillsConfigSetParser, name: EventLevelConfigNames.CONFIG_SKILLS },
			{ controller: this.charactersConfigSetParser, name: EventLevelConfigNames.CONFIG_CHARACTERS },
			{ controller: this.upgradesConfigSetParser, name: EventLevelConfigNames.CONFIG_UPGRADES },
			{ controller: this.questsConfigSetParser, name: EventLevelConfigNames.CONFIG_QUESTS },
			{ controller: this.levelsConfigSetParser, name: EventLevelConfigNames.CONFIG_SEASONS },
			{ controller: this.tutorialStepsConfigSetParser, name: EventLevelConfigNames.CONFIG_TUTORIAL_STEPS },
			{ controller: this.dialogsConfigSetParser, name: EventLevelConfigNames.CONFIG_DIALOGS },
			{ controller: this.boostsConfigSetParser, name: EventLevelConfigNames.CONFIG_BOOSTS },
			{ controller: this.timeskipsConfigSetParser, name: EventLevelConfigNames.CONFIG_TIMESKIPS },
			{ controller: this.eventDatesConfigSetParser, name: EventLevelConfigNames.CONFIG_EVENT_DATES },
			{ controller: this.leagueBotsConfigSetParser, name: EventLevelConfigNames.CONFIG_LEAGUE_BOTS },
			{ controller: this.leaguesConfigSetParser, name: EventLevelConfigNames.CONFIG_LEAGUES },
			{ controller: this.farewellPartyConfigSetParser, name: EventLevelConfigNames.CONFIG_FAREWELL_PARTY },
		];
	}

	public parseConfigs(
		data: { [key: string]: any },
	): void {
		this.parseBaseConfigs(data);
		this.parseBankPriceItemsConfig(data);
	}

	public onLevelChosen(data: Record<string, unknown>, eventKey: string): void {
		this.parsePromotePatternsConfig(data, eventKey);

		const eventConfig = this.getEventLevelsConfigs().get(eventKey);

		this.onConfigsParsed(
			this.businessesConfigSetParser.getConfigSetOfAllEventLevels(),
			this.upgradesConfigSetParser.getConfigSetOfAllEventLevels(),
			eventConfig.getReduceTimeIncomeByClick(),
		);
	}

	public getFarewellPartiesConfigs(): Map<string, FarewellPartyConfig> {
		return this.farewellPartyConfigSetParser.getConfigSet();
	}

	public getBusinessesConfigs(eventLevelKey: string): Map<string, BusinessConfig> {
		return this.businessesConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getUpgradesConfigs(eventLevelKey: string): Map<string, UpgradeConfig> {
		return this.upgradesConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getCharactersConfigs(eventLevelKey: string): Map<string, CharacterConfig> {
		return this.charactersConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getEventLevelsConfigs(): Map<string, EventLevelConfig> {
		return this.levelsConfigSetParser.getConfigSet();
	}

	public getSkillsConfigs(eventLevelKey: string): Map<string, SkillConfig> {
		return this.skillsConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getQuestsConfigs(): Map<string, QuestConfigBase> {
		return this.questsConfigSetParser.getConfigSet();
	}

	public getTutorialStepConfigs(): Map<string, TutorialStepEventLevelConfig> {
		return this.tutorialStepsConfigSetParser.getConfigSet();
	}

	public getDialogsConfigs(): Map<string, DialogConfig> {
		return this.dialogsConfigSetParser.getConfigSet();
	}

	public getBoostsConfigs(eventLevelKey: string): Map<string, BoostConfig> {
		return this.boostsConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getTimeskipsConfigs(eventLevelKey: string): Map<string, TimeskipConfig> {
		return this.timeskipsConfigSetParser.getConfigSet(eventLevelKey);
	}

	public getEventDatesConfigs(): Map<string, EventLevelDateConfig> {
		return this.eventDatesConfigSetParser.getConfigSet();
	}

	public getLeagueBotsConfigs(): Map<string, LeagueBotConfig> {
		return this.leagueBotsConfigSetParser.getConfigSet();
	}

	public getLeaguesConfigs(): Map<string, EventLevelLeagueConfig> {
		return this.leaguesConfigSetParser.getConfigSet();
	}

	public getBankPriceItemsConfig(): BankPriceItemsConfig {
		return this.bankPriceItemsConfig;
	}

	public getPromotePatternsConfig(): AbstractPromotePatternsConfig | undefined {
		return this.promotePatternsConfig;
	}

	private parseBaseConfigs(data: any): void {
		this.baseConfigSetMetadata.forEach((configSetMetadata) => {
			configSetMetadata.controller.parseConfigSet(data[configSetMetadata.name]);
		});
	}

	private parseBankPriceItemsConfig(data: any): void {
		this.bankPriceItemsConfig = new BankPriceItemsConfig(data[EventLevelConfigNames.CONFIG_BANK_PRICE_ITEMS]);
	}

	private parsePromotePatternsConfig(data: Record<string, any>, eventKey: string): void {
		this.promotePatternsConfig = new EventPromotePatternsConfig(
			data[EventLevelConfigNames.CONFIG_PROMOTE_PATTERNS],
			eventKey,
		);
	}
}
