import { MinMaxInterval } from '@src/types/MinMaxInterval';
import { CharacterQuickPhraseType } from '@src/types/CharacterQuickPhraseTypes';
import { PaymentMethod } from '@src/types/TransactionTypes';

export type MaxButtonValueData = {
	isMax: boolean;
	value?: number;
};

export type FarewellPartyConstants = {
	baseFucktime: number;
	baseFuckpower: number;
	aimFuckpowerMultiplier: number;
	tapsForAimDestroy: number;
	tapMinDelay: number;
	barFillsForAnimationChangeNum: number;
	bonusTimes: number[];
	bonusValues: number[];
	fuckPowers: number[];
};

export type CharacterQuickPhraseConstants = {
	idlePhraseCreateTime: MinMaxInterval<number>;
	createChances: Map<CharacterQuickPhraseType, number>;
};

export type FakeProgressConstants = {
	timer: number;
	progress: number;
};

export type DailyRewardConstants = {
	leftCharacterKeys: string[],
	rightCharacterKeys: string[],
	minLevel: number,
	isEnabled: boolean,
}

export type BankLoaderConstants = {
	updateInterval: number;
	updateEnabled: boolean;
}

export class ConstantsConfig {
	private static constructThirdPartyLinksConstants(constantsConfig: any): string[] {
		const result: string[] = [];
		const link: string = ConstantsConfig.ITEM_SETTINGS_LINK_BASE;
		for (let i = 1; ; i++) {
			const currentLink = `${link}${i}`;
			if (constantsConfig[currentLink]) {
				const linkRaw = constantsConfig[currentLink][ConstantsConfig.FIELD_VALUE].trim();
				result.push(linkRaw);
			} else {
				break;
			}
		}
		return result;
	}

	private static constructCharacterQuickPhraseConstants(constantsConfig: any): CharacterQuickPhraseConstants {
		const idlePhraseCreateTimeRaw: string[] = constantsConfig[ConstantsConfig.ITEM_CHARACTER_QUICK_PHRASE_IDLE_CREATE_TIME][ConstantsConfig.FIELD_VALUE]
			.trim()
			.split('-');
		const idlePhraseCreateTime: MinMaxInterval<number> = {
			min: Number(idlePhraseCreateTimeRaw[0].trim()),
			max: Number(idlePhraseCreateTimeRaw[1].trim()),
		};

		const createChances: Map<CharacterQuickPhraseType, number> = new Map();
		const createChancesDataRaw: string[] = constantsConfig[ConstantsConfig.ITEM_CHARACTER_QUICK_PHRASE_CHANCES][ConstantsConfig.FIELD_VALUE]
			.trim()
			.split(';');
		createChancesDataRaw.forEach((createChanceDataRaw: string) => {
			const createChanceDataFormatted: string[] = createChanceDataRaw.split(':');
			const type: CharacterQuickPhraseType = createChanceDataFormatted[0].trim();
			const chance: number = Number(createChanceDataFormatted[1].trim()) / 100;
			createChances.set(type, chance);
		});

		return {
			idlePhraseCreateTime,
			createChances,
		};
	}

	public static readonly FIELD_NAME: string = 'name';
	public static readonly FIELD_VALUE: string = 'value';

	public static readonly FIELDS: string[] = [
		ConstantsConfig.FIELD_NAME,
		ConstantsConfig.FIELD_VALUE,
	];

	public static readonly ITEM_MAX_BUTTON_VALUES: string = 'max_button_values';

	public static readonly ITEM_REDUCE_TIME_INCOME_BY_CLICK: string = 'reduce_time_income_by_click';
	public static readonly ITEM_BASE_HARD_MONEY: string = 'base_hard_money';
	public static readonly ITEM_BASE_PRESTIGE_MONEY: string = 'base_prestige_money';
	public static readonly ITEM_CARD_UPGRADE_SUFFIX: string = '_card_upgrade';
	public static readonly ITEM_PRESTIGE_UPGRADE_SUFFIX: string = '_prestige_upgrade';

	public static readonly ITEM_CUSTOMER_MULTIPLIER_PRESTIGE_AMOUNT: string = 'customer_multiplier_prestige_amount';

	public static readonly ITEM_CHARACTER_CARD_CHANGE: string = 'character_card_change';

	public static readonly ITEM_BASE_FUCKPOWER: string = 'base_fuckpower_value';
	public static readonly ITEM_BASE_FUCKTIME: string = 'base_fucktime_value';

	public static readonly ITEM_CHARACTER_QUICK_PHRASE_CHANCES: string = 'character_quick_phrase_chances';
	public static readonly ITEM_CHARACTER_QUICK_PHRASE_IDLE_CREATE_TIME: string = 'character_quick_phrase_idle_create_time';

	public static readonly ITEM_BASE_MAX_OFFLINE_TIME: string = 'base_offline_time';
	public static readonly ITEM_OFFLINE_REWARD_IMPROVE_MULT: string = 'offline_reward_improve_mult';
	public static readonly ITEM_OFFLINE_REWARD_IMPROVE_COST: string = 'offline_reward_improve_cost';

	public static readonly ITEM_FREE_SUMMON_RECHARGE: string = 'free_summon_recharge';

	public static readonly ITEM_QUEST_GO_HAND_LIFETIME: string = 'quest_go_hand_lifetime';

	public static readonly ITEM_CARDS_REWARD_PREVIEW_DELTA: string = 'lottbox_reward_cards_delta';
	public static readonly ITEM_PRESTIGE_REWARD_PREVIEW_DELTA: string = 'lottbox_reward_prestige_delta';

	public static readonly ITEM_SETTINGS_LINK_BASE: string = 'settings_link';

	public static readonly ITEM_ORGY_AIM_FUCKPOWER_MULTIPLIER: string = 'orgy_blast_multiplier';
	public static readonly ITEM_ORGY_TAPS_FOR_AIM_DESTROY: string = 'farewell_party_taps_for_destroy_heart';
	public static readonly ITEM_ORGY_TAP_MIN_DELAY: string = 'farewell_party_min_delay_ms';
	public static readonly ITEM_ORGY_BAR_FILLS_FOR_ANIMATION_CHANGE_NUM: string = 'orgy_animation_change';

	public static readonly ITEM_FAREWELL_BONUS_TIME: string = 'farewell_bonus_time';
	public static readonly ITEM_FAREWELL_BONUS_VALUE: string = 'farewell_bonus_value';
	public static readonly ITEM_FAREWELL_FUCKPOWER: string = 'farewell_fuckpower';

	public static readonly ITEM_ANALYTICS_CLICKHOUSE_ENABLED: string = 'analytics_clickhouse_enabled';
	public static readonly ITEM_ANALYTICS_XOFFERS_ENABLED: string = 'analytics_xoffers_enabled';
	public static readonly ITEM_ANALYTICS_PIXEL_ENABLED: string = 'analytics_pixel_enabled';
	public static readonly ITEM_ANALYTICS_TRAFFIC_STARS_ENABLED: string = 'analytics_traffic_stars_enabled';
	public static readonly ITEM_ANALYTICS_PROPELLER_ADS_ENABLED: string = 'analytics_propeller_ads_enabled';
	public static readonly ITEM_ANALYTICS_CLICKADU_ENABLED: string = 'analytics_clickadu_enabled';
	public static readonly ITEM_ANALYTICS_SEND_PRELOADER_PROGRESS_INTERVAL: string = 'analytics_send_preloader_progress_interval';
	public static readonly ITEM_ANALYTICS_CLICKHOUSE_SESSION_PING_INTERVAL: string = 'analytics_clickhouse_session_ping_interval';
	public static readonly ITEM_ANALYTICS_SEND_EVENTS_RAINTING_STATISTIC: string = 'send_events_raiting_statistic';

	public static readonly ITEM_SESSION_AFK_TIME: string = 'session_ttl';

	public static readonly ITEM_MIN_TAP_DELAY: string = 'min_tap_delay';

	public static readonly ITEM_NOVICE_EVENT_MIN_LEVEL: string = 'novice_event_min_level';
	public static readonly ITEM_NOVICE_EVENT_KEY: string = 'novice_event_id';

	public static readonly ITEM_EVENTS_ENABLED: string = 'events_enabled';
	public static readonly ITEM_MAIN_EVENT_DELAY: string = 'main_event_delay';

	public static readonly ITEM_MAX_ALLOWERD_LEVEL: string = 'max_allowed_level';
	public static readonly ITEM_ENV_DATA: string = 'cheat_env_data';

	public static readonly ITEM_FAREWELL_PARTY_ENABLED: string = 'farewell_party_enabled';
	public static readonly ITEM_ACTIONS_TICKER_TIMEOUT: string = 'actions_ticker_timeout';

	public static readonly ITEM_MAINTENANCE_PING_INTERVAL: string = 'maintenance_ping_interval';
	public static readonly ITEM_MAINTENANCE_SHOW_TIME: string = 'maintenance_show_time';

	public static readonly ITEM_LOAD_SCREEN_FAKE_TIMER: string = 'load_screen_fake_timer';
	public static readonly ITEM_LOAD_SCREEN_FAKE_PROGRESS: string = 'load_screen_fake_progress';

	public static readonly ITEM_RESEND_EMAIL_CONFIRMATION_CODE_TIMEOUT: string = 'resend_email_confirmation_code_timeout';
	public static readonly ITEM_NUTAKU_PRICE_CONVERSION: string = 'nutaku_price_convertion'
	public static readonly ITEM_BROWSERS_WITH_ADDITIONAL_WINDOW_FOR_PURCHASE: string = 'browsers_with_additional_window_for_purchase';
	public static readonly ITEM_NEWS_UPDATE_INTERVAL = 'mail_update_interval';
	public static readonly ITEM_NEWS_MAX_AMOUNT = 'mail_max_amount';
	public static readonly ITEM_NEWS_URL = 'mail_url';
	public static readonly ITEM_NEWS_PUSHING_START_LEVEL = 'mail_pushing_start_level';
	public static readonly ITEM_NEWS_ENABLED = 'mail_enabled';
	public static readonly ITEM_DEFAULT_PAYMENT_METHOD = 'default_payment_method';
	public static readonly ITEM_DAILY_REWARD_LEFT_CHARACTER_KEYS = 'daily_reward_left_girls';
	public static readonly ITEM_DAILY_REWARD_RIGHT_CHARACTER_KEYS = 'daily_reward_right_girls';
	public static readonly ITEM_DAILY_REWARD_MIN_LEVEL = 'daily_reward_min_level';
	public static readonly ITEM_DAILY_REWARD_ENABLED = 'daily_reward_enabled';
	public static readonly ITEM_TIMED_QUESTS_MIN_LEVEL = 'side_quests_min_level';
	public static readonly ITEM_BANK_UPDATE_INTERVAL = 'bank_update_interval';
	public static readonly ITEM_BANK_UPDATE_ENABLED = 'bank_update_enabled';

	private readonly baseHardMoney: number;
	private readonly basePrestigeMoney: number;
	private readonly reduceTimeIncomeByClick: number;

	private readonly maxButtonValues: MaxButtonValueData[];

	private readonly farewellPartyValues: FarewellPartyConstants;

	private readonly characterQuickPhraseConstants: CharacterQuickPhraseConstants;

	private readonly minOfflineTime: number;
	private readonly maxOfflineTime: number;
	private readonly offlineRewardMultiplier: number;
	private readonly offlineRewardCost: number;

	private readonly showQuestTargetDuration: number;

	private readonly thirdPartyLinks: string[];

	private readonly analyticsClickhouseEnabled: boolean;
	private readonly analyticsXOffersEnabled: boolean;
	private readonly analyticsPixelEnabled: boolean;
	private readonly analyticsTrafficStarsEnabled: boolean;
	private readonly analyticsPropellerAdsEnabled: boolean;
	private readonly analyticsClickaduEnabled: boolean;

	private readonly analyticsSendPreloaderProgressInterval: number;
	private readonly analyticsClickhouseSessionPingInterval: number;
	private readonly analyticsClickhouseEventProgressInterval: number;

	private readonly sessionAfkTime: number;

	private readonly minTapDelay: number;

	private readonly noviceEventMinLevel: number;
	private readonly noviceEventKey: string;
	private readonly eventsEnabled: boolean;
	private readonly mainEventDelay: number;
	private readonly maxAllowedLevel: number;
	private readonly farewellPartyEnabled: boolean;

	private readonly actionsTickerTimeoutTime: number;

	private readonly maintenancePingInterval: number;
	private readonly maintenanceShowTime: number;

	private readonly resendEmailConfirmationCodeTimeout: number;

	private readonly loadScreenFakeConstants: FakeProgressConstants;
	private readonly nutakuPriceConversionCoeff: number;
	private readonly browsersWithAdditionalWindowForPurchase: string[];
	private readonly newsUpdateInterval: number;
	private readonly newsMaxAmount: number;
	private readonly newsUrl: string;
	private readonly newsPushingStartLevel: number;
	private readonly newsEnabled: boolean;
	private readonly defaultPaymentMethod: PaymentMethod;
	private readonly dailyRewardConstants: DailyRewardConstants;
	private readonly timedQuestsMinLevel: number;
	private readonly bankLoaderConstants: BankLoaderConstants;

	constructor(config: { [key: string]: string }[]) {
		const configFormatted: any = {};
		config.forEach((configElement: any) => {
			configFormatted[configElement[ConstantsConfig.FIELD_NAME]] = configElement;
		});

		this.baseHardMoney = Number(configFormatted[ConstantsConfig.ITEM_BASE_HARD_MONEY][ConstantsConfig.FIELD_VALUE].trim());
		this.basePrestigeMoney = Number(configFormatted[ConstantsConfig.ITEM_BASE_PRESTIGE_MONEY][ConstantsConfig.FIELD_VALUE].trim());
		this.reduceTimeIncomeByClick = Number(configFormatted[ConstantsConfig.ITEM_REDUCE_TIME_INCOME_BY_CLICK][ConstantsConfig.FIELD_VALUE].trim());

		this.maxButtonValues = configFormatted[ConstantsConfig.ITEM_MAX_BUTTON_VALUES][ConstantsConfig.FIELD_VALUE]
			.split(',')
			.map((valueStr: string) => {
				const value = Number(valueStr.trim());
				const isMax: boolean = value === -1;
				return { value, isMax };
			});

		this.farewellPartyValues = {
			baseFuckpower: Number(configFormatted[ConstantsConfig.ITEM_BASE_FUCKPOWER][ConstantsConfig.FIELD_VALUE].trim()),
			baseFucktime: Number(configFormatted[ConstantsConfig.ITEM_BASE_FUCKTIME][ConstantsConfig.FIELD_VALUE].trim()),
			aimFuckpowerMultiplier: Number(configFormatted[ConstantsConfig.ITEM_ORGY_AIM_FUCKPOWER_MULTIPLIER][ConstantsConfig.FIELD_VALUE].trim()),
			tapsForAimDestroy: Number(configFormatted[ConstantsConfig.ITEM_ORGY_TAPS_FOR_AIM_DESTROY][ConstantsConfig.FIELD_VALUE].trim()),
			tapMinDelay: Number(configFormatted[ConstantsConfig.ITEM_ORGY_TAP_MIN_DELAY][ConstantsConfig.FIELD_VALUE].trim()),
			fuckPowers: configFormatted[ConstantsConfig.ITEM_FAREWELL_FUCKPOWER][ConstantsConfig.FIELD_VALUE].trim().split(',').map(parseFloat),
			bonusTimes: configFormatted[ConstantsConfig.ITEM_FAREWELL_BONUS_TIME][ConstantsConfig.FIELD_VALUE].trim().split(',').map(parseFloat),
			bonusValues: configFormatted[ConstantsConfig.ITEM_FAREWELL_BONUS_VALUE][ConstantsConfig.FIELD_VALUE].trim().split(',').map(parseFloat),
			// eslint-disable-next-line max-len
			barFillsForAnimationChangeNum: Number(configFormatted[ConstantsConfig.ITEM_ORGY_BAR_FILLS_FOR_ANIMATION_CHANGE_NUM][ConstantsConfig.FIELD_VALUE].trim()),
		};

		this.characterQuickPhraseConstants = ConstantsConfig.constructCharacterQuickPhraseConstants(configFormatted);

		this.showQuestTargetDuration = parseFloat(configFormatted[ConstantsConfig.ITEM_QUEST_GO_HAND_LIFETIME][ConstantsConfig.FIELD_VALUE].trim());

		const baseMaxOfflineTimeRaw = configFormatted[ConstantsConfig.ITEM_BASE_MAX_OFFLINE_TIME][ConstantsConfig.FIELD_VALUE].trim();
		[this.minOfflineTime, this.maxOfflineTime] = baseMaxOfflineTimeRaw.split(',');

		this.offlineRewardCost = Number(configFormatted[ConstantsConfig.ITEM_OFFLINE_REWARD_IMPROVE_COST][ConstantsConfig.FIELD_VALUE].trim());
		this.offlineRewardMultiplier = Number(configFormatted[ConstantsConfig.ITEM_OFFLINE_REWARD_IMPROVE_MULT][ConstantsConfig.FIELD_VALUE].trim());

		this.thirdPartyLinks = ConstantsConfig.constructThirdPartyLinksConstants(configFormatted);

		const analyticsSendPreloaderProgressIntervalRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_SEND_PRELOADER_PROGRESS_INTERVAL];
		this.analyticsSendPreloaderProgressInterval = Number(analyticsSendPreloaderProgressIntervalRaw[ConstantsConfig.FIELD_VALUE].trim());

		const sessionAfkTimeRaw = configFormatted[ConstantsConfig.ITEM_SESSION_AFK_TIME];
		this.sessionAfkTime = Number(sessionAfkTimeRaw[ConstantsConfig.FIELD_VALUE]);

		const minTapDelayRaw = configFormatted[ConstantsConfig.ITEM_MIN_TAP_DELAY];
		this.minTapDelay = Number(minTapDelayRaw[ConstantsConfig.FIELD_VALUE]);

		const analyticsClickHouseSessionPingIntervalRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_CLICKHOUSE_SESSION_PING_INTERVAL];
		this.analyticsClickhouseSessionPingInterval = Number(analyticsClickHouseSessionPingIntervalRaw[ConstantsConfig.FIELD_VALUE]);

		const analyticsClickHouseEventProgressIntervalRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_SEND_EVENTS_RAINTING_STATISTIC];
		this.analyticsClickhouseEventProgressInterval = Number(analyticsClickHouseEventProgressIntervalRaw[ConstantsConfig.FIELD_VALUE]);

		const analyticsClickhouseEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_CLICKHOUSE_ENABLED];
		this.analyticsClickhouseEnabled = analyticsClickhouseEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const analyticsXOffersEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_XOFFERS_ENABLED];
		this.analyticsXOffersEnabled = analyticsXOffersEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const analyticsPixelEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_PIXEL_ENABLED];
		this.analyticsPixelEnabled = analyticsPixelEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const analyticsTrafficStarsEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_TRAFFIC_STARS_ENABLED];
		this.analyticsTrafficStarsEnabled = analyticsTrafficStarsEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const analyticsPropellerAdsEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_PROPELLER_ADS_ENABLED];
		this.analyticsPropellerAdsEnabled = analyticsPropellerAdsEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const analyticsClickaduEnabledRaw = configFormatted[ConstantsConfig.ITEM_ANALYTICS_CLICKADU_ENABLED];
		this.analyticsClickaduEnabled = analyticsClickaduEnabledRaw[ConstantsConfig.FIELD_VALUE] === '1';

		const noviceEventMinLevelRaw = configFormatted[ConstantsConfig.ITEM_NOVICE_EVENT_MIN_LEVEL];
		this.noviceEventMinLevel = Number(noviceEventMinLevelRaw[ConstantsConfig.FIELD_VALUE]);

		this.noviceEventKey = configFormatted[ConstantsConfig.ITEM_NOVICE_EVENT_KEY][ConstantsConfig.FIELD_VALUE];

		const eventsEnabledRaw = configFormatted[ConstantsConfig.ITEM_EVENTS_ENABLED][ConstantsConfig.FIELD_VALUE];
		this.eventsEnabled = eventsEnabledRaw === 'true';

		const mainEventDelayRaw = configFormatted[ConstantsConfig.ITEM_MAIN_EVENT_DELAY];
		this.mainEventDelay = Number(mainEventDelayRaw[ConstantsConfig.FIELD_VALUE]);

		const maxAllowedLevelRaw = configFormatted[ConstantsConfig.ITEM_MAX_ALLOWERD_LEVEL];
		this.maxAllowedLevel = Number(maxAllowedLevelRaw[ConstantsConfig.FIELD_VALUE]);

		const farewellPartyEnabled = configFormatted[ConstantsConfig.ITEM_FAREWELL_PARTY_ENABLED][ConstantsConfig.FIELD_VALUE];
		this.farewellPartyEnabled = farewellPartyEnabled === 'true';
		const actionsTickerTimeoutTimeRaw = configFormatted[ConstantsConfig.ITEM_ACTIONS_TICKER_TIMEOUT];
		this.actionsTickerTimeoutTime = Number(actionsTickerTimeoutTimeRaw[ConstantsConfig.FIELD_VALUE]) * 1000;

		const maintenancePingIntervalRaw = configFormatted[ConstantsConfig.ITEM_MAINTENANCE_PING_INTERVAL];
		this.maintenancePingInterval = Number(maintenancePingIntervalRaw[ConstantsConfig.FIELD_VALUE]);

		const maintenanceShowTimeRaw = configFormatted[ConstantsConfig.ITEM_MAINTENANCE_SHOW_TIME];
		this.maintenanceShowTime = Number(maintenanceShowTimeRaw[ConstantsConfig.FIELD_VALUE]);

		this.loadScreenFakeConstants = {
			timer: Number(configFormatted[ConstantsConfig.ITEM_LOAD_SCREEN_FAKE_TIMER][ConstantsConfig.FIELD_VALUE]),
			progress: Number(configFormatted[ConstantsConfig.ITEM_LOAD_SCREEN_FAKE_PROGRESS][ConstantsConfig.FIELD_VALUE]),
		};

		const resendConfirmationCodeRaw: string = configFormatted[ConstantsConfig.ITEM_RESEND_EMAIL_CONFIRMATION_CODE_TIMEOUT][ConstantsConfig.FIELD_VALUE];
		this.resendEmailConfirmationCodeTimeout = Number(resendConfirmationCodeRaw);

		const nutakuPriceConversionRaw: string = configFormatted[ConstantsConfig.ITEM_NUTAKU_PRICE_CONVERSION][ConstantsConfig.FIELD_VALUE];
		this.nutakuPriceConversionCoeff = Number(nutakuPriceConversionRaw);

		// eslint-disable-next-line max-len
		const browsersWithAdditionalWindow: string = configFormatted[ConstantsConfig.ITEM_BROWSERS_WITH_ADDITIONAL_WINDOW_FOR_PURCHASE][ConstantsConfig.FIELD_VALUE];
		this.browsersWithAdditionalWindowForPurchase = browsersWithAdditionalWindow.split(',').map(x => x.trim());

		this.newsUpdateInterval = Number(configFormatted[ConstantsConfig.ITEM_NEWS_UPDATE_INTERVAL][ConstantsConfig.FIELD_VALUE]);
		this.newsMaxAmount = Number(configFormatted[ConstantsConfig.ITEM_NEWS_MAX_AMOUNT][ConstantsConfig.FIELD_VALUE]);
		this.newsUrl = configFormatted[ConstantsConfig.ITEM_NEWS_URL][ConstantsConfig.FIELD_VALUE];
		this.newsPushingStartLevel = Number(configFormatted[ConstantsConfig.ITEM_NEWS_PUSHING_START_LEVEL][ConstantsConfig.FIELD_VALUE]);
		this.newsEnabled = configFormatted[ConstantsConfig.ITEM_NEWS_ENABLED][ConstantsConfig.FIELD_VALUE] === 'true';

		this.defaultPaymentMethod = configFormatted[ConstantsConfig.ITEM_DEFAULT_PAYMENT_METHOD][ConstantsConfig.FIELD_VALUE];

		this.dailyRewardConstants = {
			leftCharacterKeys: configFormatted[ConstantsConfig.ITEM_DAILY_REWARD_LEFT_CHARACTER_KEYS][ConstantsConfig.FIELD_VALUE]
				.split(',').map((key: string) => key.trim()),
			rightCharacterKeys: configFormatted[ConstantsConfig.ITEM_DAILY_REWARD_RIGHT_CHARACTER_KEYS][ConstantsConfig.FIELD_VALUE]
				.split(',').map((key: string) => key.trim()),
			minLevel: Number(configFormatted[ConstantsConfig.ITEM_DAILY_REWARD_MIN_LEVEL][ConstantsConfig.FIELD_VALUE]),
			isEnabled: configFormatted[ConstantsConfig.ITEM_DAILY_REWARD_ENABLED][ConstantsConfig.FIELD_VALUE] === 'true',
		};

		this.timedQuestsMinLevel = Number(configFormatted[ConstantsConfig.ITEM_TIMED_QUESTS_MIN_LEVEL][ConstantsConfig.FIELD_VALUE]);

		this.bankLoaderConstants = {
			updateInterval: Number(configFormatted[ConstantsConfig.ITEM_BANK_UPDATE_INTERVAL][ConstantsConfig.FIELD_VALUE]),
			updateEnabled: configFormatted[ConstantsConfig.ITEM_BANK_UPDATE_ENABLED][ConstantsConfig.FIELD_VALUE] === 'true',
		};
	}

	public getBrowsersWithAdditionalWindowForPurchase(): string[] {
		return this.browsersWithAdditionalWindowForPurchase;
	}

	public getNutakuPriceConversionCoeff(): number {
		return this.nutakuPriceConversionCoeff;
	}

	public getResendEmailConfirmationCodeTimeout(): number {
		return this.resendEmailConfirmationCodeTimeout;
	}

	public getLoadScreenFakeProgressBarConstants(): FakeProgressConstants {
		return this.loadScreenFakeConstants;
	}

	public getMaxButtonValues(): MaxButtonValueData[] {
		return this.maxButtonValues;
	}

	public getBaseHardMoney(): number {
		return this.baseHardMoney;
	}

	public getBasePrestigeMoney(): number {
		return this.basePrestigeMoney;
	}

	public getReduceTimeIncomeByClick(): number {
		return this.reduceTimeIncomeByClick;
	}

	public getFarewellPartyValues(): FarewellPartyConstants {
		return this.farewellPartyValues;
	}

	public getCharacterQuickPhraseConstants(): CharacterQuickPhraseConstants {
		return this.characterQuickPhraseConstants;
	}

	public getMinOfflineTime(): number {
		return this.minOfflineTime;
	}

	public getMaxOfflineTime(): number {
		return this.maxOfflineTime;
	}

	public getOfflineRewardCost(): number {
		return this.offlineRewardCost;
	}

	public getOfflineRewardMultiplier(): number {
		return this.offlineRewardMultiplier;
	}

	public getShowQuestTargetDuration(): number {
		return this.showQuestTargetDuration;
	}

	public getThirdPartyLinks(): string[] {
		return this.thirdPartyLinks;
	}

	public getAnalyticsSendPreloaderProgressInterval(): number {
		return this.analyticsSendPreloaderProgressInterval;
	}

	public getSessionAfkTime(): number {
		return this.sessionAfkTime;
	}

	public getAnalyticsClickhouseSessionPingInterval(): number {
		return this.analyticsClickhouseSessionPingInterval;
	}

	public getAnalyticsClickhouseEventProgressInterval(): number {
		return this.analyticsClickhouseEventProgressInterval;
	}

	public isAnalyticsClickhouseEnabled(): boolean {
		return this.analyticsClickhouseEnabled;
	}

	public isAnalyticsXOffersEnabled(): boolean {
		return this.analyticsXOffersEnabled;
	}

	public isAnalyticsPixelEnabled(): boolean {
		return this.analyticsPixelEnabled;
	}

	public isAnalyticsTrafficStarsEnabled(): boolean {
		return this.analyticsTrafficStarsEnabled;
	}

	public isAnalyticsPropellerAdsEnabled(): boolean {
		return this.analyticsPropellerAdsEnabled;
	}

	public isAnalyticsClickaduEnabled(): boolean {
		return this.analyticsClickaduEnabled;
	}

	public getMinTapDelay(): number {
		return this.minTapDelay;
	}

	public getNoviceEventMinLevel(): number {
		return this.noviceEventMinLevel;
	}

	public getNoviceEventKey(): string {
		return this.noviceEventKey;
	}

	public isEventsEnabled(): boolean {
		return this.eventsEnabled;
	}

	public getMainEventDelay(): number {
		return this.mainEventDelay;
	}

	public getMaxAllowedLevel(): number {
		return this.maxAllowedLevel;
	}

	public getFarewellPartyEnabled(): boolean {
		return this.farewellPartyEnabled;
	}

	public getActionsTickerTimeoutTime(): number {
		return this.actionsTickerTimeoutTime;
	}

	// как часто пинговать сервер про тех работы, в секундах
	public getMaintenancePingInterval(): number {
		return this.maintenancePingInterval;
	}

	// за сколько времени выводить плашку мейнтаненса, в секундах
	public getMaintenanceShowTime(): number {
		return this.maintenanceShowTime;
	}

	public getNewsUpdateInterval(): number {
		return this.newsUpdateInterval;
	}

	public getNewsMaxAmount(): number {
		return this.newsMaxAmount;
	}

	public getNewsUrl(): string {
		return this.newsUrl;
	}

	public getNewsPushingStartLevel(): number {
		return this.newsPushingStartLevel;
	}

	public getNewsEnabled(): boolean {
		return this.newsEnabled;
	}

	public getDefaultPaymentMethod(): PaymentMethod {
		return this.defaultPaymentMethod;
	}

	public getDailyRewardConstants(): DailyRewardConstants {
		return this.dailyRewardConstants;
	}

	public getTimedQuestsMinLevel(): number {
		return this.timedQuestsMinLevel;
	}

	public getBankLoaderConstants(): BankLoaderConstants {
		return this.bankLoaderConstants;
	}
}
