import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class TimeskipConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_TIME: string = 'time';
	public static readonly FIELD_ICON: string = 'icon';
	public static readonly FIELD_VALUE: string = 'value';

	private readonly key: string;
	private readonly iconKey: string;
	private readonly value: number;

	constructor(config: { [key: string]: string }) {
		this.key = config[TimeskipConfig.FIELD_KEY].trim();
		this.iconKey = config[TimeskipConfig.FIELD_ICON].trim();
		this.value = Number(config[TimeskipConfig.FIELD_VALUE].trim());
	}

	public getKey(): string {
		return this.key;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getValue(): number {
		return this.value;
	}
}
