import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80004View } from '@views/characters/events/event8/EventCharacter80004View';
import { EventCharacter80005View } from '@views/characters/events/event8/EventCharacter80005View';
import { EventCharacter80006View } from '@views/characters/events/event8/EventCharacter80006View';

export class EventBusiness80002View extends BusinessBaseView {
	constructor() {
		const eventCharacter80004View = new EventCharacter80004View();
		const eventCharacter80005View = new EventCharacter80005View();
		const eventCharacter80006View = new EventCharacter80006View();

		super('business80002', [eventCharacter80004View, eventCharacter80005View, eventCharacter80006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b7_decor4']).setTransform(-17, -60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor2']).setTransform(-18, 15, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor1']).setTransform(-17, -93.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor5']).setTransform(-14, -99, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter80004View as PIXI.DisplayObject,
			eventCharacter80005View,
			eventCharacter80006View,
		);
	}
}
