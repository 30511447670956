export enum DeviceId {
	OPERA = 'Opera',
	EDGE = 'Edge',
	CHROME = 'Chrome',
	SAFARI = 'Safari',
	FIREFOX = 'Firefox',
	IE = 'IE',
	WEBGL = 'WebGL'
}

export class DeviceUtils {
	private static deviceId: DeviceId;
	private static android: boolean;
	private static androidWebview: boolean;
	private static ios: boolean;

	public static getUserAgent(): string {
		return window.navigator.userAgent;
	}

	public static getDeviceId(): DeviceId {
		if (this.deviceId === undefined) {
			this.detectDevice();
		}
		return this.deviceId;
	}

	public static isAndroid(): boolean {
		if (this.android === undefined) {
			this.detectDevice();
		}
		return this.android;
	}

	public static isIos(): boolean {
		if (this.ios === undefined) {
			this.detectDevice();
		}
		return this.ios;
	}

	public static isAndroidWebView(): boolean {
		if (this.androidWebview === undefined) {
			this.detectDevice();
		}
		return this.androidWebview;
	}

	private static detectDevice(): void {
		const userAgent = this.getUserAgent().toLowerCase();
		this.detectDeviceId(userAgent);

		this.android = /android/.test(userAgent);
		this.androidWebview = /wv/.test(userAgent);
		this.ios = /ip[ao]d|iphone/i.test(userAgent);
	}

	private static detectDeviceId(userAgent: string): DeviceId {
		this.deviceId = DeviceId.WEBGL;
		if (userAgent.includes('opera') || userAgent.includes('opr')) {
			this.deviceId = DeviceId.OPERA;
		} else if (userAgent.includes('edge')) {
			this.deviceId = DeviceId.EDGE;
		} else if (userAgent.includes('chrome')) {
			this.deviceId = DeviceId.CHROME;
		} else if (userAgent.includes('safari')) {
			if (userAgent.includes('crios')) {
				this.deviceId = DeviceId.CHROME;
			} else {
				this.deviceId = DeviceId.SAFARI;
			}
		} else if (userAgent.includes('firefox')) {
			this.deviceId = DeviceId.FIREFOX;
		} else if (userAgent.includes('trident')) {
			this.deviceId = DeviceId.IE;
		}

		return this.deviceId;
	}
}
