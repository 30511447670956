import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { SupportWindowTopicListView } from './SupportWindowTopicListView';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import { SupportTopic } from './SupportTopic';
import { NotificationWindowView } from '../NotificationWindowView';
import { EmailAreaInputView } from '@views/components/input/EmailAreaInputView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class SupportWindowView extends PopupWindowBaseView {
	private static createSupportTopicList(): SupportTopic[] {
		const localeKeys = [
			'#support_topic_1_PaymentIssues',
			'#support_topic_2_AccountIssues',
			'#support_topic_3_GameIssues',
			'#support_topic_4_Language',
			'#support_topic_5_Crushes',
			'#support_topic_6_Propositions',
		];
		return localeKeys.map(key => new SupportTopic(key));
	}

	private static createSubmitButton(): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 318;
		buttonBg.height = 95;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);

		const labelButton = new SizeableBitmapText(
			'',
			button.width - 20,
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
		);
		labelButton.anchor = 0.5;
		labelButton.text = LocalizationStorage.getInstance().getLocalizedString('#support_button_submit_label');
		button.addChild(labelButton);

		return button;
	}

	private static createSocialNetworkButton(icon: PIXI.Sprite): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_icons_bg'], 11, 0, 11, 0);
		buttonBg.width = 57;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);
		button.addChild(icon);

		return button;
	}

	public static readonly EVENT_SUBMIT_SUPPORT_MESSAGE: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly emailTextArea: EmailAreaInputView;
	private readonly messageTextArea: TextAreaInputView;
	private readonly topicListView: SupportWindowTopicListView;

	private readonly topicList: SupportTopic[];
	private readonly thirdPartyLinks: string[];

	constructor(
		thirdPartyLinks: string[],
	) {
		super(0.5);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.thirdPartyLinks = thirdPartyLinks;


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 773;
		bg.height = 723;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const stringTitle = this.localizationStorage.getLocalizedString('#support_window_title');
		const labelTitle = new PIXI.extras.BitmapText(
			stringTitle,
			{ font: { size: 40, name: 'wendyOneShadowBold' } },
		);
		labelTitle.anchor = 0.5;
		labelTitle.y = -310;

		const closeButton = new CloseButtonView();
		closeButton.position.set(335, -310);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		const submitButton = SupportWindowView.createSubmitButton();
		submitButton.y = 260;
		submitButton.on(ButtonBaseView.EVENT_CLICK, this.onSubmitButtonClick, this);

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 729;
		whiteBg.height = 450;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -45;

		this.topicList = SupportWindowView.createSupportTopicList();
		this.topicListView = new SupportWindowTopicListView(
			this.topicList.map(topic => this.localizationStorage.getLocalizedString(topic.getLocaleKey())),
		);
		this.topicListView.y = -195;

		this.emailTextArea = new EmailAreaInputView({
			bgWidth: 620,
		});
		this.emailTextArea.y = -115;
		this.emailTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_email_label'));

		this.messageTextArea = new TextAreaInputView({
			bgWidth: 620,
			bgHeight: 202,
			multiline: true,
		});
		this.messageTextArea.y = 40;
		this.messageTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_message_label'));
		this.messageTextArea.setTextInvalidInput(this.localizationStorage.getLocalizedString('#support_window_incorrect_message_label'));
		this.messageTextArea.on(TextAreaInputView.EVENT_FOCUS, () => {
			if (this.topicListView.isOpened()) {
				this.topicListView.setOpened(false);
			}
		});

		const stringFooter = this.localizationStorage.getLocalizedString('#support_window_footer_label');
		const textFooter = new SizeableBitmapText(
			stringFooter,
			494,
			{ font: '26px wendyOneShadowBold', tint: 0x8c9199 },
		);
		textFooter.anchor = new PIXI.Point(0, 0.5);
		textFooter.position.set(-350, 400);

		const bgFade = new PIXI.Graphics();
		bgFade.beginFill(0x000000, 1);
		bgFade.drawRect(0, 0, 765, 130);
		bgFade.endFill();
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);
		bgFade.y = 380;

		const buttonDiscord = SupportWindowView.createSocialNetworkButton(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['discord_icon']));
		buttonDiscord.on(ButtonBaseView.EVENT_CLICK, () => { window.open(this.thirdPartyLinks[0]); }, this);
		buttonDiscord.position.set(330, 400);

		this.mainContainer.addChild(
			bgFade,
			bg,
			labelTitle as PIXI.DisplayObject,
			closeButton,
			submitButton,
			whiteBg,
			this.emailTextArea,
			this.messageTextArea,
			this.topicListView,
			textFooter,
			buttonDiscord,
		);
	}

	private submitSupportMessage(): void {
		this.startCloseAnimation();

		const notification = new NotificationWindowView(
			this.localizationStorage.getLocalizedString('#support_message_sent_title'),
			this.localizationStorage.getLocalizedString('#support_message_sent_body'),
			this.localizationStorage.getLocalizedString('#label_OK'),
		);
		notification.onShown();
		notification.once(NotificationWindowView.EVENT_WINDOW_CLOSED, () => this.onClose(false), this);
		this.addChild(notification);

		const subject = this.topicList[this.topicListView.getCurrentTextTopicId()].getTitleEng();
		const message = this.messageTextArea.getText().trim();
		const email = this.emailTextArea.getText().trim();
		this.emit(
			SupportWindowView.EVENT_SUBMIT_SUPPORT_MESSAGE,
			email,
			subject,
			message,
		);
	}

	private onSubmitButtonClick(): void {
		const inputEmailOk = this.emailTextArea.isValid();
		const inputMessageOk = this.validateInputMessage();

		if (!inputEmailOk) {
			this.emailTextArea.onInvalidInput();
		}

		if (!inputMessageOk) {
			this.messageTextArea.onInvalidInput();
		}

		if (inputEmailOk && inputMessageOk) {
			this.submitSupportMessage();
		}
	}

	private validateInputMessage(): boolean {
		return this.messageTextArea.getText().trim().length > 0;
	}
}
