import { SkillModel } from '@models/skills/SkillModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { NumberUtils } from '@src/utils/NumberUtils';
import { Emitter } from 'pixi-particles';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import * as TWEEN from '@tweenjs/tween.js';

export class SkillBaseView extends ButtonBaseView {
	private readonly timer: SizeableBitmapText;
	private readonly skillIcon: PIXI.Sprite;
	private readonly ticker: PIXI.ticker.Ticker;
	protected model: SkillModel;
	private activeSkillEmitters: Emitter[];
	private activationSkillEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly cooldownFade: PIXI.Graphics;
	private readonly cooldownFadeMask: PIXI.Graphics;
	private readonly lockedSkillIcon: PIXI.Sprite;

	private readonly tweenGroup: TWEEN.Group;
	private readonly animationsContainer: PIXI.Container;

	constructor(model: SkillModel) {
		const buttonBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_business_upgrade_btndfdf']);
		buttonBg.tint = 0x71635f;
		buttonBg.scale.set(0.6);

		super(buttonBg);

		this.model = model;
		this.ticker = PIXI.ticker.shared;
		this.activeSkillEmitters = [];
		this.activationSkillEmitters = [];

		this.tweenGroup = new TWEEN.Group();

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.skillIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[model.getIconKey()]);
		this.skillIcon.anchor.set(0.5);
		this.skillIcon.scale.set(0.85);

		this.animationsContainer = new PIXI.Container();
		this.animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

		this.cooldownFade = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawCircle(0, 0, this.skillIcon.width / 2 - 4)
			.endFill();
		this.cooldownFade.alpha = 0.6;
		this.cooldownFade.visible = false;

		this.cooldownFadeMask = new PIXI.Graphics();
		this.cooldownFade.mask = this.cooldownFadeMask;

		this.timer = new SizeableBitmapText(
			'',
			86,
			{ font: '24px wendyOneShadowBold' },
		);
		this.timer.y = 30;
		this.timer.anchor = 0.5;
		this.timer.visible = false;

		this.lockedSkillIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lock_icon']);
		this.lockedSkillIcon.visible = false;

		const bgFade = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawCircle(0, 0, this.skillIcon.width / 2 - 4)
			.endFill();
		bgFade.alpha = 0.6;
		this.lockedSkillIcon.addChildAt(bgFade, 0);

		this.addChild(
			this.animationsContainer,
			this.skillIcon,
			this.cooldownFadeMask,
			this.cooldownFade,
			this.lockedSkillIcon,
			this.timer as PIXI.DisplayObject,
		);

		model.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onActivated, this);
		model.on(SkillModel.EVENT_SKILL_DEACTIVATED, this.onDeactivated, this);
		model.on(SkillModel.EVENT_SKILL_RELOADED, this.onReloaded, this);

		if (model.isOpened()) {
			if (model.isActive()) {
				this.onActivated();
			} else if (model.isReloading()) {
				this.onDeactivated();
			} else {
				this.showActive();
				this.timer.visible = false;
			}
		} else {
			this.lockedSkillIcon.visible = true;
			model.once(SkillModel.EVENT_SKILL_OPENED, this.showActive, this);
			this.showClosed();
		}
		this.ticker.add(this.updateTweenGroup, this);
	}

	public getKey(): string {
		return this.model.getKey();
	}

	protected onActivated(): void {
		this.showActive();
		this.timer.visible = true;
		this.timer.tint = 0x9cff8e;

		this.playOnActivationAnimations();

		this.ticker.remove(this.updateSkillProgress, this);
		this.ticker.add(this.updateSkillProgress, this);
	}

	protected onDeactivated(): void {
		this.activeSkillEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.showClosed();
		this.timer.visible = true;
		this.timer.tint = 0xf85a4c;

		this.ticker.remove(this.updateSkillProgress, this);
		this.ticker.add(this.updateSkillProgress, this);
	}

	private onReloaded(): void {
		this.showActive();
		this.timer.visible = false;
		this.ticker.remove(this.updateSkillProgress, this);

		const scaleToNormal = new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 200);
		const scaleIn = new TWEEN.Tween(this.scale, this.tweenGroup)
			.easing(TWEEN.Easing.Cubic.In)
			.to({ x: 1.2, y: 1.2 }, 250)
			.chain(scaleToNormal)
			.onComplete(() => {
				const starSprite = new PIXI.Sprite(this.fxAtlas['skill_star']);
				starSprite.blendMode = PIXI.BLEND_MODES.ADD;
				starSprite.position.set(20, -20);
				this.addChild(starSprite);
				starSprite.alpha = 0;
				const startFadeOut = new TWEEN.Tween(starSprite, this.tweenGroup)
					.to({ alpha: 0 }, 600)
					.onComplete(() => {
						this.removeChild(starSprite);
					});
				new TWEEN.Tween(starSprite, this.tweenGroup)
					.to({ alpha: 1 }, 500)
					.easing(TWEEN.Easing.Quadratic.In)
					.chain(startFadeOut)
					.start();
			});

		// scaleOut
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.easing(TWEEN.Easing.Cubic.In)
			.to({ x: 0.8, y: 0.8 }, 400)
			.chain(scaleIn)
			.start();
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private updateSkillProgress(): void {
		this.timer.text = NumberUtils.secToDHMS(Math.ceil(this.model.getTimeLeftMS() / 1000));

		SkillBaseView.applyProgressMask(
			this.cooldownFade,
			this.cooldownFadeMask,
			this.model.getTimeLeftMS(),
			this.model.getFullReloadTime() * 1000,
		);
	}

	private static applyProgressMask(progressBar: PIXI.Graphics, progressBarMask: PIXI.Graphics, current: number, total: number): void {
		const deg = Math.min(360, (360 * current) / total);

		progressBarMask.clear();

		progressBarMask.beginFill(0);
		progressBarMask.lineStyle(2);
		progressBarMask.arc(
			progressBar.x,
			progressBar.y,
			progressBar.width * 0.5,
			-0.0174533 * (deg + 90),
			-0.0174533 * 90,
		);

		if (deg !== 0) {
			progressBarMask.lineTo(0, 0);
		}

		progressBarMask.endFill();
	}

	protected showActive(): void {
		this.cooldownFade.visible = false;
		this.lockedSkillIcon.visible = false;
	}

	protected showClosed(): void {
		this.cooldownFade.visible = true;
	}

	protected playOnActivationAnimations(): void {
		this.playActiveSkillAnimations();

		const pointAlphaEmitter = new Emitter(
			this,
			[this.fxAtlas['skill_button_activation']],
			SkillParticleConfig.getPointAlpha3(),
		);
		this.activationSkillEmitters.push(pointAlphaEmitter);
		pointAlphaEmitter.playOnceAndDestroy();

		const buttonStarEmitter = new Emitter(
			this,
			[this.fxAtlas['skill_star']],
			SkillParticleConfig.getButtonStar2(),
		);
		this.activationSkillEmitters.push(buttonStarEmitter);
		buttonStarEmitter.playOnceAndDestroy();
	}

	protected playActiveSkillAnimations(): void {
		const pointSizeEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			SkillParticleConfig.getPointSize2(),
		);
		pointSizeEmitter.autoUpdate = true;
		this.activeSkillEmitters.push(pointSizeEmitter);

		const pointAlphaEmitter = new Emitter(
			this.skillIcon,
			[this.fxAtlas['skill_activ_glow3']],
			SkillParticleConfig.getPointAlpha1(),
		);
		pointAlphaEmitter.autoUpdate = true;
		this.activeSkillEmitters.push(pointAlphaEmitter);
	}

	public destroy(): void {
		this.model.off(SkillModel.EVENT_SKILL_ACTIVATED, this.onActivated, this, false);
		this.model.off(SkillModel.EVENT_SKILL_DEACTIVATED, this.onDeactivated, this, false);
		this.model.off(SkillModel.EVENT_SKILL_RELOADED, this.onReloaded, this, false);
		this.model.off(SkillModel.EVENT_SKILL_OPENED, this.showActive, this, true);
		this.model = null;

		this.activeSkillEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.activationSkillEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.ticker.remove(this.updateTweenGroup, this);
		this.ticker.remove(this.updateSkillProgress, this);

		this.tweenGroup.removeAll();

		super.destroy();
	}
}
