import { QuestConfigBase } from './QuestConfigBase';
import { QuestTypes, QuestType } from '@src/types/QuestTypes';

export class QuestGetCardsConfig extends QuestConfigBase {
	private amount: number;

	public getAmount(): number {
		return this.amount;
	}

	public setParamsOnLevel(params: string): void {
		this.amount = Number(params);
	}

	// eslint-disable-next-line class-methods-use-this
	public getType(): QuestType {
		return QuestTypes.GET_CARDS;
	}
}
