import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { GameProfileModel } from '@models/GameProfileModel';
import {
	RegistrationWindowViewSetter,
} from '@interfaces/ViewSetters';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { ServerErrorCode } from '@src/types/NetworkTypes';
import { SavesConfig } from '@configs/saves/SavesConfig';

export abstract class GameProfileBaseViewController extends PIXI.utils.EventEmitter implements RegistrationWindowViewSetter {
	private registrationWindow: RegistrationWindowView;

	constructor(
		protected readonly networkRequestSender: NetworkRequestSender,
		protected readonly gameProfileModel: GameProfileModel,
		protected readonly savesConfig: SavesConfig,
		private readonly resendEmailConfirmationTimeout: number,
	) {
		super();
	}

	public setRegistrationWindowView(view: RegistrationWindowView): void {
		this.registrationWindow = view;
		this.registrationWindow.on(RegistrationWindowView.EVENT_BUTTON_REGISTER_CLICK, this.onRegisterButtonClick, this);
		this.registrationWindow.on(RegistrationWindowView.EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK, this.onButtonEmailResendCodeClick, this);
		this.registrationWindow.on(RegistrationWindowView.EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK, this.onButtonEmailConfirmClick, this);
	}

	protected onButtonEmailResendCodeClick(): void {
		this.gameProfileModel.retryEmailConfirmation(this.resendEmailConfirmationTimeout);
		this.networkRequestSender.sendAccountNewConfirmationCode(this.gameProfileModel.getEmail());
	}

	protected async onButtonEmailConfirmClick(code: string): Promise<void> {
		const result = await this.networkRequestSender.sendAccountEmailConfirm(this.gameProfileModel.getEmail(), code);

		if (result.error) {
			if (result.error === ServerErrorCode.ACCOUNT_WRONG_CONFIRMATION_CODE) {
				this.gameProfileModel.onConfirmationWrongCode();
			}
		} else {
			const lootboxConfig = result.login_reward;
			this.gameProfileModel.setRegistrationLootboxConfig(lootboxConfig);
			this.gameProfileModel.onConfirmation();
		}
	}

	private async onRegisterButtonClick(email: string, password: string, subscribe: boolean): Promise<void> {
		const result = await this.networkRequestSender.sendAccountEmailRegister(email, password, subscribe);

		if (result.error) {
			if (result.error === ServerErrorCode.ACCOUNT_EMAIL_ALREADY_EXISTS) {
				this.gameProfileModel.onRegisterEmailAlreadyExists();
			}
		} else {
			this.gameProfileModel.onRegister(email);
		}
	}
}
