import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { CardRarity } from '@src/types/CardRarities';

export class BankPriceItemsConfig {
	public static readonly FIELD_REWARD_TYPE: string = 'reward_type';
	public static readonly FIELD_REWARD_ID: string = 'reward_id';
	public static readonly FIELD_PRICE: string = 'price';

	private static readonly FORMAT_RARITY: RegExp = /^\s*rarity\s*:\s*(\w*)$/;

	private priceItemCharacters: Map<CardRarity, number>;
	private priceItemUpgrade: Map<CardRarity, number>;
	private priceItemTotem: Map<CardRarity, number>;
	private priceItemResource: Map<RewardResourceIdTypes, number>;

	private priceItemBoosts: Map<string, number>;
	private priceItemTimeskips: Map<string, number>;

	constructor(config: { [key: string]: string }) {
		this.priceItemCharacters = new Map();
		this.priceItemUpgrade = new Map();
		this.priceItemTotem = new Map();
		this.priceItemResource = new Map();
		this.priceItemBoosts = new Map();
		this.priceItemTimeskips = new Map();

		Object.keys(config).forEach((configElementKey) => {
			const configElement: any = config[configElementKey];
			const rewardType = (configElement[BankPriceItemsConfig.FIELD_REWARD_TYPE].trim()) as RewardTypes;
			const rewardId = configElement[BankPriceItemsConfig.FIELD_REWARD_ID].trim() as string;
			const price = Number(configElement[BankPriceItemsConfig.FIELD_PRICE].trim());
			switch (rewardType) {
				case RewardTypes.CHARACTER: {
					const rarity = rewardId.match(BankPriceItemsConfig.FORMAT_RARITY)[1] as CardRarity;
					this.priceItemCharacters.set(rarity, price);
					break;
				}
				case RewardTypes.UPGRADE: {
					const rarity = rewardId.match(BankPriceItemsConfig.FORMAT_RARITY)[1] as CardRarity;
					this.priceItemUpgrade.set(rarity, price);
					break;
				}
				case RewardTypes.TOTEM: {
					const rarity = rewardId.match(BankPriceItemsConfig.FORMAT_RARITY)[1] as CardRarity;
					this.priceItemTotem.set(rarity, price);
					break;
				}
				case RewardTypes.RESOURCE: {
					this.priceItemResource.set(rewardId as RewardResourceIdTypes, price);
					break;
				}
				case RewardTypes.BOOST: {
					this.priceItemBoosts.set(rewardId as RewardResourceIdTypes, price);
					break;
				}
				case RewardTypes.TIMESKIP: {
					this.priceItemTimeskips.set(rewardId as RewardResourceIdTypes, price);
					break;
				}
				default:
					throw new Error(`Unsupported reward type ${rewardType}`);
			}
		});
	}

	public getPriceItemCharacter(rarity: CardRarity): number {
		return this.priceItemCharacters.get(rarity);
	}

	public getPriceItemUpgrade(rarity: CardRarity): number {
		return this.priceItemUpgrade.get(rarity);
	}

	public getPriceItemTotem(rarity: CardRarity): number {
		return this.priceItemTotem.get(rarity);
	}

	public getPriceItemBoost(key: string): number {
		return this.priceItemBoosts.get(key);
	}

	public getPriceItemTimeskip(key: string): number {
		return this.priceItemTimeskips.get(key);
	}

	public getPriceItemResource(resourceIdType: RewardResourceIdTypes): number {
		return this.priceItemResource.get(resourceIdType);
	}
}
