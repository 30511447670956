import { BankRechargeConfig } from '@configs/bank/BankRechargeConfig';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';

export class BankRechargesFactory {
	public static createModels(
		rechargeConfigs: Map<string, BankRechargeConfig>,
		claimableRechargeKeys?: string[],
	): Map<string, BankRechargeModel> {
		const models: Map<string, BankRechargeModel> = new Map();
		rechargeConfigs.forEach((config, key) => {
			const model = new BankRechargeModel(config);
			models.set(key, model);
		});

		claimableRechargeKeys?.forEach(key => models.get(key).setClaimable(true));
		return models;
	}
}
