import { SkillBaseView } from '@views/ui/skillsPanel/SkillBaseView';

export class SkillConstantProfitView extends SkillBaseView {
	// eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
	protected playActiveSkillAnimations(): void {}

	protected onActivated(): void {
		this.onDeactivated();
		this.playOnActivationAnimations();
	}
}
