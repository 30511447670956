import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character34View extends CharacterMainBaseView {
	constructor() {
		super('character34');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(6, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(35, -18),
			bgPosition: new PIXI.Point(100, -22),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
