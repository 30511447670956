import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { ChallengeListItemView } from './ChallengeListItemView';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextUtils } from '@src/utils/TextUtils';
import { ChallengeSpecialListItemView } from './ChallengeSpecialListItemView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { BaseChallengeListItemView } from './BaseChallengeListItemView';
import { HintTypes } from '@src/types/HintTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class LevelChallengeWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SHOW_HINT = Symbol();
	public static readonly PANELS_WIDHT = 1077;

	private readonly localizationStorage: LocalizationStorage;
	private readonly targetTimesInHours: number[];
	private readonly challengesContainer: PIXI.Container;

	private challengeList: BaseChallengeListItemView[];

	constructor(
		private readonly levelChallengeModel: LevelChallengeModel,
		private readonly cardsMiniViewFactory: CardMiniViewFactory,
	) {
		super(0.8);

		this.challengeList = [];

		this.levelChallengeModel.on(LevelChallengeModel.EVENT_TIMEOUT, this.onLevelChallengeTimeout, this);

		this.localizationStorage = LocalizationStorage.getInstance();

		const targetTimes = this.levelChallengeModel.getTargetTimes();
		this.targetTimesInHours = targetTimes.map(time => Math.round(time));

		const titlePanelBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade'], 0, 0, 0, 0);
		titlePanelBg.width = 920;
		titlePanelBg.height = 90;
		titlePanelBg.pivot.set(titlePanelBg.width / 2, titlePanelBg.height / 2);
		titlePanelBg.tint = 0x000000;
		titlePanelBg.alpha = 0.66;
		titlePanelBg.y = -430;

		const textTitle = new PIXI.extras.BitmapText(
			this.localizationStorage.getLocalizedString('#challenges_window_title'),
			{ font: '44px wendyOneGold' },
		);
		textTitle.y = -436;
		textTitle.anchor = 0.5;

		const titleLIneDecor = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		titleLIneDecor.width = 920;
		titleLIneDecor.pivot.set(titleLIneDecor.width / 2, titleLIneDecor.height / 2);
		titleLIneDecor.y = -475;

		this.challengesContainer = new PIXI.Container();
		this.challengesContainer.x = 6;

		this.levelChallengeModel.on(LevelChallengeModel.EVENT_REWARDS_CHANGED, this.initChallenges, this);

		const rewards = this.levelChallengeModel.getRewards();
		this.initChallenges(rewards || []);

		this.mainContainer.addChild(
			titlePanelBg,
			titleLIneDecor,
			textTitle,
			this.challengesContainer,
		);
	}

	private initChallenges(rewards: RewardDescriptionType[][]): void {
		this.challengesContainer.removeChildren();

		// Gold

		const challenge1Bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['timed_gold_panel'], 17, 0, 20, 0);
		challenge1Bg.width = LevelChallengeWindowView.PANELS_WIDHT;
		challenge1Bg.pivot.set(challenge1Bg.width / 2, challenge1Bg.height / 2);

		const girlsPanelDecor1 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_panel_girls1']);
		girlsPanelDecor1.setTransform(-370, -13, 0.95, 0.95);

		const textChallengeGold = this.getCurrentTimeString(this.targetTimesInHours[0]);

		const challengeGold = new ChallengeSpecialListItemView(
			challenge1Bg,
			girlsPanelDecor1,
			textChallengeGold,
			'timed_gold_glow',
			'legendary_lootbox_main',
			this.cardsMiniViewFactory,
			rewards[0],
		);
		challengeGold.y = -275;

		// Silver

		const challenge2Bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['timed_silver_panel'], 18, 0, 20, 0);
		challenge2Bg.width = LevelChallengeWindowView.PANELS_WIDHT - 2;
		challenge2Bg.pivot.set(challenge2Bg.width / 2, challenge2Bg.height / 2);

		const girlsPanelDecor2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_panel_girls2']);
		girlsPanelDecor2.setTransform(-370, -13, 0.95, 0.95);

		const textChallengeSilver = this.getCurrentTimeString(this.targetTimesInHours[1]);

		const challengeSilver = new ChallengeListItemView(
			challenge2Bg,
			girlsPanelDecor2,
			textChallengeSilver,
			'timed_silver_glow',
			'epic_lootbox_main',
			this.cardsMiniViewFactory,
			rewards[1],
		);
		challengeSilver.y = -63;

		// Bronze

		const challenge3Bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['timed_bronze_panel'], 17, 0, 20, 0);
		challenge3Bg.width = LevelChallengeWindowView.PANELS_WIDHT - 2;
		challenge3Bg.pivot.set(challenge3Bg.width / 2, challenge3Bg.height / 2);

		const girlsPanelDecor3 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_panel_girls3']);
		girlsPanelDecor3.setTransform(-370, -13, 0.95, 0.95);

		const textChallengeBronze = this.getCurrentTimeString(this.targetTimesInHours[2]);

		const challengeBronze = new ChallengeListItemView(
			challenge3Bg,
			girlsPanelDecor3,
			textChallengeBronze,
			'timed_bronze_glow',
			'rare_lootbox_main',
			this.cardsMiniViewFactory,
			rewards[2],
		);
		challengeBronze.y = 151;

		// Purple

		const challenge4Bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['timed_purple_panel'], 18, 0, 20, 0);
		challenge4Bg.width = LevelChallengeWindowView.PANELS_WIDHT;
		challenge4Bg.pivot.set(challenge4Bg.width / 2, challenge4Bg.height / 2);

		const girlsPanelDecor4 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_panel_girls4']);
		girlsPanelDecor4.setTransform(-370, -13, 0.95, 0.95);

		const textChallengePurple = this.localizationStorage.getLocalizedString('#challenge_purple_title');

		const challengePurple = new ChallengeListItemView(
			challenge4Bg,
			girlsPanelDecor4,
			textChallengePurple,
			'timed_purple_glow',
			'common_lootbox_main',
			this.cardsMiniViewFactory,
			rewards[3],
		);
		challengePurple.y = 365;

		// Challenges display

		this.challengeList = [
			challengeGold,
			challengeSilver,
			challengeBronze,
			challengePurple,
		];

		this.challengeList.forEach((challenge) => {
			challenge.on(BaseChallengeListItemView.EVENT_SHOW_HINT_REWARD_CARD, (reward, card) => {
				this.emit(LevelChallengeWindowView.EVENT_SHOW_HINT, HintTypes.REWARD_CARD, {
					rewardDescription: reward,
					origin: card,
				});
			});
		});

		this.challengesContainer.addChild(...this.challengeList);

		this.updateFailedChallenges();
	}

	private getCurrentTimeString(time: number): string {
		let textTimerChallenge: string;
		if (time >= 3600) {
			textTimerChallenge = this.localizationStorage.getLocalizedString('#challenge_title_hours');
		} else {
			textTimerChallenge = this.localizationStorage.getLocalizedString('#challenge_title_minutes');
		}
		textTimerChallenge = TextUtils.replaceValueAndEnding(
			this.localizationStorage.getLanguage(),
			'{{value}}',
			NumberUtils.secToHM(time),
			textTimerChallenge,
		);
		return textTimerChallenge;
	}

	private onLevelChallengeTimeout(): void {
		this.updateFailedChallenges();
	}

	private updateFailedChallenges(): void {
		this.challengeList
			.slice(0, this.levelChallengeModel.getActiveTargetTimeId())
			.filter(challengeView => !challengeView.isFailed())
			.forEach(challengeView => challengeView.setFailed());
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.levelChallengeModel.off(LevelChallengeModel.EVENT_REWARDS_CHANGED, this.initChallenges, this);
		super.destroy(options);
	}
}
