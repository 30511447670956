import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { ItemPromoteButtonView } from '../ItemPromoteButtonView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { TotemModel } from '@models/TotemModel';
import { TextDescriptionHelperTotem } from './TextDescriptionHelperTotem';
import { TotemCardView } from '@views/ui/cards/TotemCardView';
import { SoundController } from '@src/main/SoundController';
import { PromoteAnimationProvider } from '@views/ui/cards/PromoteAnimationProvider';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import LocalizationStorage from '@main/LocalizationStorage';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class TotemPromoteWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_PROMOTE: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();

	private readonly closeButton: ButtonBaseView;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly model: TotemModel;
	private readonly profitDesc: MultiColoredTextField;
	private readonly buttonPromote: ItemPromoteButtonView;
	private readonly card: TotemCardView;
	private readonly promoteAnimation: PromoteAnimationProvider;
	private readonly localizationStorage: LocalizationStorage;
	private flyTextLabel: FlyBitmapText;

	constructor(
		model: TotemModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		moneyPanelsButtonPlusInvisible: boolean,
	) {
		super(0.8, true);

		this.model = model;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.hardMoneyModel = hardMoneyModel;

		this.localizationStorage = LocalizationStorage.getInstance();

		const modelKey = model.getKey();
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['upgrades_info_bg'], 43, 0, 43, 0);
		bg.width = 1286;
		bg.height = 886;
		bg.pivot.set(643, 443);
		bg.interactive = true;

		const descText = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_Desc`),
			{ font: { size: 30, name: 'wendyOne' }, tint: 0xbee1fe },
			600,
			130,
		);
		descText.position.set(15, -340);
		descText.anchor = new PIXI.Point(0, 0.5);

		const sexualityLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#totem_desrc_sexuality'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		sexualityLabel.position.set(15, -270);

		const sexualityValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_Sex`),
			{ font: { size: 28, name: 'wendyOne' } },
			380,
			100,
		);
		sexualityValue.position.set(200, -270);

		const param1Name = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_1_name`),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		param1Name.position.set(15, -160);

		const param1Value = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_1_value`),
			{ font: { size: 28, name: 'wendyOne' } },
			380,
			100,
		);
		param1Value.position.set(200, -160);

		const marcLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#totem_desrc_marc'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		marcLabel.position.set(15, -60);

		const marcValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_Marc`),
			{ font: { size: 28, name: 'wendyOne' } },
			380,
			100,
		);
		marcValue.position.set(200, -60);

		const profitDescStr = TextDescriptionHelperTotem.getTextDescriptionPromoteWindow(model);
		this.card = new TotemCardView(model, undefined, false);
		this.card.position.set(-304, -36);

		const profitBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['rarity_info_panel'], 22, 23, 22, 23);
		profitBg.width = 580;
		profitBg.height = 170;
		profitBg.pivot.set(290, 85);
		profitBg.position.set(300, 140);

		const profitDesc = new MultiColoredTextField({ font: '40px wendyOneShadowBold', align: 'center' }, 540, 140);
		profitDesc.text = profitDescStr;
		profitDesc.anchor = 0.5;
		profitDesc.position.set(300, 140);
		this.profitDesc = profitDesc;

		const promoteParticlesContainer = new PIXI.Container();
		promoteParticlesContainer.position.set(300, 140);
		promoteParticlesContainer.visible = false;

		const promoteGlow = new PIXI.Sprite(fxAtlas['promote_glow1']);
		promoteGlow.visible = false;
		promoteGlow.alpha = 0.8;
		promoteGlow.position.set(300, 140);
		promoteGlow.blendMode = PIXI.BLEND_MODES.ADD;
		promoteGlow.scale.set(0);

		this.promoteAnimation = new PromoteAnimationProvider(
			profitDesc,
			promoteParticlesContainer,
			promoteGlow,
		);
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_PROFIT_SCALE_DOWN_COMPLETED, () => { this.updateProfitDesc(); });
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_ANIMATION_COMPLETED, this.onPromoteAnimationCompleted, this);

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(900, -500);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.buttonPromote = new ItemPromoteButtonView(
			model.isEnoughCardsForPromote(),
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
		);
		this.buttonPromote.on(ItemPromoteButtonView.EVENT_CLICK, this.onPromoteButtonClick, this);
		this.buttonPromote.position.set(285, 328);
		this.updateCost();
		this.updateButtonPromoteCostValueEnabled();

		const hardMoneyPanel = new HardMoneyPanelView(this.hardMoneyModel);
		hardMoneyPanel.position.set(670, -500);

		const prestigeMoneyPanel = new PrestigeMoneyPanelView(this.prestigeMoneyModel);
		prestigeMoneyPanel.position.set(410, -500);

		if (moneyPanelsButtonPlusInvisible) {
			prestigeMoneyPanel.setButtonPlusVisible(false);
			hardMoneyPanel.setButtonPlusVisible(false);
		}

		prestigeMoneyPanel.on(PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.emit(TotemPromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK), this);
		hardMoneyPanel.on(HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK, () => this.emit(TotemPromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK), this);

		this.model.on(TotemModel.EVENT_PROMOTED, this.startPromoteAnimation, this);
		this.model.on(TotemModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);

		this.mainContainer.addChild(
			bg,
			profitBg,
			sexualityLabel,
			sexualityValue,
			param1Name,
			param1Value,
			marcLabel,
			marcValue,
			descText as PIXI.DisplayObject,
			promoteParticlesContainer,
			promoteGlow,
			profitDesc,
			this.card,
			this.buttonPromote,
			this.closeButton,
			hardMoneyPanel,
			prestigeMoneyPanel,
		);

		this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this);
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getPromoteButton(): PIXI.Container {
		return this.buttonPromote;
	}

	public getCloseButtonContainer(): PIXI.Container {
		return this.closeButton;
	}

	public showFlyTextOnPromoteButton(message: string): void {
		if (!this.flyTextLabel) {
			const stringLabel = this.localizationStorage.getLocalizedString(message);
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.buttonPromote.x, this.buttonPromote.y - 90);
			this.mainContainer.addChild(this.flyTextLabel);
		}
	}

	private startPromoteAnimation(): void {
		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
		this.buttonPromote.setCost(this.model.getPromoteCostPrestige().toString());
		this.buttonPromote.setEnabled(false);
		this.buttonPromote.alpha = 0.5;

		this.card.startPromoteAnimation();

		this.promoteAnimation.start();

		SoundController.getInstance().playEntityPromoted();
		this.updateButtonPromoteCostValueEnabled();
	}

	private onPromoteButtonClick(): void {
		this.emit(TotemPromoteWindowView.EVENT_BUTTON_PROMOTE, this.model);
	}

	private updateCost(): void {
		this.buttonPromote.setCost(this.model.getPromoteCostPrestige().toString());
	}

	private updateButtonPromoteCostValueEnabled(): void {
		const isEnoughPrestigeMoneyForPromote = this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige();
		const inEnoughCardsForPromote = this.model.isEnoughCardsForPromote();
		this.buttonPromote.setCostValueEnabled(isEnoughPrestigeMoneyForPromote);
		this.buttonPromote.setButtonBgEnabled(inEnoughCardsForPromote && isEnoughPrestigeMoneyForPromote);
	}

	private updateProfitDesc(): void {
		this.profitDesc.text = TextDescriptionHelperTotem.getTextDescriptionPromoteWindow(this.model);
	}

	private onPromoteAnimationCompleted(): void {
		this.buttonPromote.setEnabled(true);
		this.updateButtonPromoteCostValueEnabled();
		this.buttonPromote.alpha = 1;
	}

	private onCardsAdded(): void {
		this.card.updateProgressBar();

		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this);

		this.model.off(TotemModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.model.off(TotemModel.EVENT_PROMOTED, this.startPromoteAnimation, this);

		this.promoteAnimation.destroy();

		super.destroy(options);
	}
}
