import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { SoundController } from '@src/main/SoundController';
import * as TWEEN from '@tweenjs/tween.js';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { QuestGetSoftMoney } from '@models/quests/QuestGetSoftMoney';
import { QuestGetSoftMoneyFromBusiness } from '@models/quests/QuestGetSoftMoneyFromBusiness';

export abstract class QuestUIBaseView<T extends AbstractQuest> extends ButtonBaseView {
	private static readonly PROGRESS_BAR_WIDTH = 222;

	private readonly completedBackground: PIXI.mesh.NineSlicePlane;

	private tickerLootbox: PIXI.ticker.Ticker;
	private completedLootboxTweenGroup: TWEEN.Group;
	private completedLootboxShakeInterval: any;

	private completedLootbox: PIXI.Container;
	private completedLootboxSprite: PIXI.Sprite;

	private tweenAnimateProgressValue: TWEEN.Tween;

	protected readonly localizationStorage: LocalizationStorage;

	protected readonly progressValue: PIXI.extras.BitmapText;
	protected readonly progressBar: PIXI.mesh.NineSlicePlane;
	protected readonly completedLabel: PIXI.extras.BitmapText;
	protected readonly textDescription: MultiColoredTextField;

	protected quest: T;

	protected constructor(background: PIXI.mesh.NineSlicePlane) {
		super(background);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);


		this.completedBackground = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['quest_completed_bg'], 21, 0, 25, 0);
		this.completedBackground.width = 240;
		this.completedBackground.pivot.set(this.completedBackground.width / 2, this.completedBackground.height / 2);
		this.completedBackground.visible = false;

		this.completedLabel = new PIXI.extras.BitmapText('', { font: '20px wendyOneShadowBold', align: 'center' });
		this.completedLabel.y = 30;
		this.completedLabel.anchor = 0.5;

		this.textDescription = new MultiColoredTextField({ font: '20px wendyOneShadowBold', align: 'center' }, 205, 55);
		this.textDescription.y = -15;
		this.textDescription.anchor = 0.5;

		this.progressValue = new SizeableBitmapText('', 200, { font: '20px wendyOneShadowBold' });
		this.progressValue.anchor = 0.5;
		this.progressValue.y = 30;

		this.progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['quest_bar'], 5, 0, 5, 0);
		this.progressBar.position.set(-QuestUIBaseView.PROGRESS_BAR_WIDTH / 2 - 6, 29);
		this.progressBar.pivot.y = this.progressBar.height / 2;

		this.addChild(
			this.progressBar,
			this.progressValue as PIXI.DisplayObject,
		);

		this.completedLootboxTweenGroup = new TWEEN.Group();
		this.tickerLootbox = PIXI.ticker.shared;
		this.tickerLootbox.add(this.updateLootbox, this);

		this.addChild(
			this.completedBackground,
			this.completedLabel,
			this.textDescription as PIXI.DisplayObject,
		);
	}

	public getKey(): string {
		return this.quest.getKey();
	}

	public isCompleted(): boolean {
		return this.quest.isCompleted();
	}

	public setQuest(quest: T): void {
		this.tryResetPreviousQuest();

		this.quest = quest;

		if (quest.isCompleted()) {
			this.onQuestCompleted();
		} else {
			this.updateProgressValues();
			this.showCompleted(false);
			this.setBackgroundTexture();

			this.quest.on(AbstractQuest.EVENT_PROGRESS, this.updateProgressValues, this);
			this.quest.on(AbstractQuest.EVENT_COMPLETED, this.onQuestCompleted, this);
		}

		this.onTranslateText();

		this.visible = true;
	}

	protected abstract getTextDescription(quest: AbstractQuest): string;
	protected abstract setBackgroundTexture(): void;

	protected isBossBackgroundTexture(): boolean {
		return this.quest.getMainWindowViewParameters()?.[0] === 'boss';
	}

	protected onTranslateText(): void {
		this.textDescription.text = this.getTextDescription(this.quest);
		this.completedLabel.text = this.localizationStorage.getLocalizedString('#quest_ButtonClaimMainwindow');
	}

	protected tryResetPreviousQuest(): void {
		if (this.quest !== undefined && this.quest !== null) {
			this.quest.off(AbstractQuest.EVENT_PROGRESS, this.updateProgressValues, this, false);

			this.quest.off(AbstractQuest.EVENT_COMPLETED, this.onQuestCompleted, this, false);
			if (this.quest.listeners(AbstractQuest.EVENT_REWARD_RECEIVED).includes(this.onSomeQuestRewardReceived)) {
				this.quest.off(AbstractQuest.EVENT_REWARD_RECEIVED, this.onSomeQuestRewardReceived, this, true);
			}

			this.quest = null;
		}
	}

	private onQuestCompleted(): void {
		this.showCompleted(true);

		SoundController.getInstance().playQuestComplete();
	}

	protected showCompleted(value: boolean): void {
		this.buttonBg.visible = !value;
		this.completedBackground.visible = value;
		this.completedLabel.visible = value;
		this.textDescription.visible = !value;
		this.progressValue.visible = !value;
		this.progressBar.visible = !value;

		this.stopGiftAnimation();
		if (value) {
			this.startGiftAnimation();
		}
	}

	private stopGiftAnimation(): void {
		if (this.completedLootbox) {
			this.cleanAnimations();
			this.completedLootbox.destroy();
			this.completedLootbox = null;

			if (this.quest.listeners(AbstractQuest.EVENT_REWARD_RECEIVED).includes(this.onSomeQuestRewardReceived)) {
				this.quest.off(AbstractQuest.EVENT_REWARD_RECEIVED, this.onSomeQuestRewardReceived, this);
			}
		}
	}

	private cleanAnimations(): void {
		if (this.completedLootboxShakeInterval) {
			clearInterval(this.completedLootboxShakeInterval);
		}
		if (this.completedLootboxTweenGroup) {
			this.completedLootboxTweenGroup.removeAll();
		}
	}

	private startGiftAnimation(): void {
		this.completedLootbox = new PIXI.Container();
		this.completedLootbox.y = 43;
		this.completedLootbox.x = (this.completedBackground.width / 2);
		this.completedLootbox.scale.set(0.75);

		if (this.quest.hasLootbox()) {
			const lootbox = this.quest.getLootbox();
			this.completedLootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`${lootbox.getCardRarity()}_lootbox_main`]);
		} else {
			this.completedLootboxSprite = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['legendary_lootbox_main']);
			this.quest.once(AbstractQuest.EVENT_REWARD_RECEIVED, this.onSomeQuestRewardReceived, this);
		}
		this.completedLootboxSprite.scale.set(0.6);

		const hightlightMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['lootbox_main_mask']);
		const hightlightAnimation = new SpriteHighlightAnimationView(hightlightMask, 1000);
		hightlightAnimation.start();

		this.completedLootbox.addChild(
			this.completedLootboxSprite,
			hightlightAnimation as PIXI.DisplayObject,
		);

		this.completedBackground.addChild(this.completedLootbox);

		this.completedLootboxTweenGroup = new TWEEN.Group();

		new TWEEN.Tween(this.completedLootbox.scale, this.completedLootboxTweenGroup)
			.to({ x: 0.82, y: 0.82 }, 750)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.yoyo(true)
			.repeat(Infinity)
			.start();

		this.completedLootboxShakeInterval = setInterval(() => {
			const shakeEnd = new TWEEN.Tween(this.completedLootbox, this.completedLootboxTweenGroup)
				.to({ rotation: 0 }, 35)
				.easing(TWEEN.Easing.Cubic.Out);
			const shakeMain = new TWEEN.Tween(this.completedLootbox, this.completedLootboxTweenGroup)
				.to({ rotation: 0.05 }, 70)
				.easing(TWEEN.Easing.Cubic.InOut)
				.yoyo(true)
				.repeat(3)
				.chain(shakeEnd);
			new TWEEN.Tween(this.completedLootbox, this.completedLootboxTweenGroup)
				.to({ rotation: -0.05 }, 35)
				.easing(TWEEN.Easing.Cubic.In)
				.chain(shakeMain)
				.start();
		}, 3000);
	}

	public startAppearingAnimation(): void {
		this.x -= 270;
		new TWEEN.Tween(this)
			.to({ x: '+270' }, 500)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		SoundController.getInstance().playNewQuest();
	}

	private onSomeQuestRewardReceived(): void {
		const lootbox = this.quest.getLootbox();
		this.completedLootboxSprite.texture = AssetsStorage.getAtlas('lootboxAtlas')[`${lootbox.getCardRarity()}_lootbox_main`];
	}

	private updateProgressValues(): void {
		this.progressValue.text = `${this.quest.getCurrentProgressValue()} / ${this.quest.getTotalProgressValue()}`;
		this.animateProgressValue();

		let deltaProgress: number;
		if (this.quest instanceof QuestGetSoftMoney || this.quest instanceof QuestGetSoftMoneyFromBusiness) {
			deltaProgress = this.quest.getCurrentProgressValueAsSoftMoney()
				.divide(this.quest.getTargetAmount())
				.toNumber(2);
		} else {
			deltaProgress = Number(this.quest.getCurrentProgressValue()) / Number(this.quest.getTotalProgressValue());
		}

		this.progressBar.width = deltaProgress * QuestUIBaseView.PROGRESS_BAR_WIDTH;
	}

	private animateProgressValue(): void {
		if (!this.tweenAnimateProgressValue) {
			this.tweenAnimateProgressValue = new TWEEN.Tween(this.progressValue.scale)
				.to({ x: this.progressValue.scale.x + 0.15, y: this.progressValue.scale.y + 0.15 }, 100)
				.repeat(1)
				.yoyo(true)
				.onComplete(() => { this.tweenAnimateProgressValue = null; })
				.start();
		}
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playButtonClick();

		this.emit(QuestUIBaseView.EVENT_CLICK, this.quest);
	}

	private updateLootbox(): void {
		this.completedLootboxTweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.cleanAnimations();
		this.tickerLootbox.remove(this.updateLootbox);

		if (this.tweenAnimateProgressValue) {
			this.tweenAnimateProgressValue.stop();
		}

		super.destroy();
	}
}
