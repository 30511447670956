import { AssetsStorage } from '@main/AssetsStorage';
import { DailyParticleAnimationView } from '@views/windows/dailyWindow/DailyParticleAnimationView';
import * as TWEEN from '@tweenjs/tween.js';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';

export abstract class BaseDailyCardView extends PIXI.Container {
	public static readonly DEFAULT_HEIGHT = 285;
	public static readonly DEFAULT_WIDTH = 201;
	public static readonly STICKER_OFFSET = 15;

	protected readonly bgRound: PIXI.mesh.NineSlicePlane;
	protected readonly contentMask: PIXI.Graphics;
	protected readonly upTint: PIXI.Graphics;
	protected readonly downTint: PIXI.Sprite;
	protected readonly dayString: TextField;
	protected readonly countString: TextField;

	private readonly localizationStorage: LocalizationStorage;

	private dailyParticleAnim: DailyParticleAnimationView;
	private glow: PIXI.mesh.NineSlicePlane;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	private spesialParticlesOffsetY: number;
	private spesialParticlesOffsetX: number;

	constructor(day: number) {
		super();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.spesialParticlesOffsetX = 0;
		this.spesialParticlesOffsetY = 0;

		this.bgRound = new PIXI.mesh.NineSlicePlane(uiAtlas['simple_ranking_panel_bg'], 3, 3, 3, 3);
		this.bgRound.width = BaseDailyCardView.DEFAULT_WIDTH;
		this.bgRound.height = BaseDailyCardView.DEFAULT_HEIGHT;
		this.bgRound.pivot.set(this.bgRound.width / 2, this.bgRound.height / 2);
		this.bgRound.tint = 0x8C79FF;

		this.contentMask = new PIXI.Graphics();
		this.contentMask.beginFill(0x000000);
		this.contentMask.drawRect(0, 0, 183, 268);
		this.contentMask.endFill();
		this.contentMask.pivot.set(this.contentMask.width / 2, this.contentMask.height / 2);

		this.upTint = new PIXI.Graphics();
		this.upTint.beginFill(0x000000, 0.5);
		this.upTint.drawRect(0, 0, 183, 48);
		this.upTint.endFill();
		this.upTint.pivot.set(this.upTint.width / 2, this.upTint.height / 2);
		this.upTint.y = -110;

		const localizationStorage = LocalizationStorage.getInstance();

		const dayLocStr = localizationStorage.getLocalizedString('#daily_reward_day');

		this.dayString = new TextField(
			`${dayLocStr} ${day}`,
			{ font: '30px wendyOneShadowBold', align: 'center' },
			175,
		);
		this.dayString.anchor = 0.5;
		this.dayString.y = -110;

		this.countString = new TextField(
			'',
			{ font: '34px wendyOneShadowBold', align: 'center', tint: 0xffee90 },
			175,
		);
		this.countString.anchor = 0.5;
		this.countString.y = 113;

		this.downTint = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h']);
		this.downTint.rotation = Math.PI / 180 * -90;
		this.downTint.tint = 0x000000;
		this.downTint.alpha = 0.5;
		this.downTint.height = 184;
		this.downTint.width = 50;
		this.downTint.y = this.countString.y - 4;
		this.downTint.mask = this.contentMask;

		this.interactive = true;

		this.addChild(
			this.bgRound as PIXI.DisplayObject,
		);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setExtraRewardMode(): void {
		this.bgRound.width = 424;
		this.bgRound.height = 602;
		this.bgRound.pivot.set(this.bgRound.width / 2, this.bgRound.height / 2);

		this.contentMask.width = 406;
		this.contentMask.height = 584;

		this.upTint.width = 408;
		this.upTint.height = 108;
		this.upTint.y = -238;

		this.dayString.font = '52px wendyOneShadowBold';
		this.dayString.y = -238;

		this.countString.font = '48px wendyOneShadowBold';
		this.countString.y = 252;

		this.downTint.height = 411;
		this.downTint.width = 65;
		this.downTint.y = 260;

		this.spesialParticlesOffsetX = 10;
		this.spesialParticlesOffsetY = 12;
	}

	public updateCountString(text: string): void {
		this.countString.text = text;
	}

	public setCollected(): void {
		const collectedFade = new PIXI.Graphics();
		collectedFade.beginFill(0x000000, 0.55);
		collectedFade.drawRect(0, 0, this.bgRound.width, this.bgRound.height);
		collectedFade.endFill();
		collectedFade.pivot.set(collectedFade.width / 2, collectedFade.height / 2);

		const collectedIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_icon4']);
		collectedIcon.scale.set(1.3);
		collectedIcon.alpha = 0;
		new TWEEN.Tween(collectedIcon.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 300)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();

		new TWEEN.Tween(collectedIcon, this.tweenGroup)
			.to({ alpha: 1 }, 300)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();

		this.addChild(
			collectedFade as PIXI.DisplayObject,
			collectedIcon,
		);

		this.dayString.tint = 0xFFFFFF;

		this.removeChild(this.dailyParticleAnim);
		this.removeChild(this.glow);
	}

	public setCanCollect(): void {
		this.dailyParticleAnim = new DailyParticleAnimationView(this.contentMask.width, this.contentMask.height);
		this.dailyParticleAnim.interactiveChildren = false;

		this.dayString.text = this.localizationStorage.getLocalizedString('#daily_reward_today');
		this.dayString.tint = 0xfffc9b;

		this.glow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 15, 15, 15, 15);
		this.glow.width = this.bgRound.width - this.spesialParticlesOffsetX;
		this.glow.height = this.bgRound.height - this.spesialParticlesOffsetY;
		this.glow.pivot.set(this.glow.width / 2, this.glow.height / 2);
		this.glow.tint = 0xf0b80e;
		this.glow.alpha = 0.7;

		new TWEEN.Tween(this.glow.scale)
			.to({ x: 1.08, y: 1.06 }, 600)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.start();

		this.addChildAt(this.dailyParticleAnim, 0);
		this.addChildAt(this.glow, 0);
	}

	public destroy(): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		super.destroy();
	}
}
