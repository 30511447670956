import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120001View } from '@views/characters/events/event12/EventCharacter120001View';
import { EventCharacter120002View } from '@views/characters/events/event12/EventCharacter120002View';
import { EventCharacter120003View } from '@views/characters/events/event12/EventCharacter120003View';

export class EventBusiness120001View extends BusinessBaseView {
	constructor() {
		const eventCharacter120001View = new EventCharacter120001View();
		const eventCharacter120002View = new EventCharacter120002View();
		const eventCharacter120003View = new EventCharacter120003View();

		super('business120001', [eventCharacter120001View, eventCharacter120002View, eventCharacter120003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter120001View,
			eventCharacter120002View,
			eventCharacter120003View as PIXI.DisplayObject,
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
