import { SummonResultWindowViewSetter } from '@interfaces/ViewSetters';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeSummonResultCardsAppearedModel extends TutorialStepUnlockTypeBaseModel implements SummonResultWindowViewSetter {
	private readonly summonKey: string;

	private view: SummonResultWindowView;

	constructor(key: string, summonKey: string) {
		super(key, TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_APPEARED);

		this.summonKey = summonKey;
	}

	public setSummonResultWindowView(view: SummonResultWindowView): void {
		this.view = view;
		if (this.summonKey === view.getKey()) {
			this.view.once(SummonResultWindowView.EVENT_REWARD_CARDS_APPEARED, this.onUnlocked, this);
		}
	}

	public destroy(): void {
		if (this.view && this.view.listeners(SummonResultWindowView.EVENT_ALL_CARDS_OPENED).includes(this.onUnlocked)) {
			this.view.off(SummonResultWindowView.EVENT_REWARD_CARDS_APPEARED, this.onUnlocked, this);
		}
		super.destroy();
	}
}
