import { AssetsStorage } from '@main/AssetsStorage';

export class RegistrationWindowCheckboxView extends PIXI.Container {
	private readonly iconCheck: PIXI.Sprite;

	constructor() {
		super();


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_rounded_rect'], 5, 5, 5, 5);
		bg.width = 45;
		bg.height = 45;
		bg.tint = 0x000000;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		this.iconCheck = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_icon']);
		this.iconCheck.position.set(3, -8);
		this.iconCheck.visible = false;

		bg.interactive = true;
		bg.on('pointertap', this.onBackgroundClick, this);

		this.addChild(
			bg,
			this.iconCheck as PIXI.DisplayObject,
		);
	}

	private onBackgroundClick(): void {
		this.iconCheck.visible = !this.iconCheck.visible;
	}

	public isChecked(): boolean {
		return this.iconCheck.visible;
	}
}
