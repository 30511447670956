import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter120009View extends CharacterMainBaseView {
	constructor() {
		super('character120009');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-187, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-125, -49),
			arrowRotation: Math.PI * 180,
			bgPosition: new PIXI.Point(-119, -45),
		});

		this.initQuickPhrases(this.key);
	}
}
