import { TutorialStepsSaveData } from '@src/types/SaveTypes';

export class EventTutorialStepsModel {
	private completedSteps?: string[];

	constructor(saveData: TutorialStepsSaveData) {
		this.completedSteps = saveData.completed;
	}

	public getCompletedSteps(): string[] | undefined {
		return this.completedSteps;
	}

	public resetCompletedSteps(): void {
		this.completedSteps = [];
	}
}
