import { BaseAction } from '../BaseAction';

export class BusinessNewCustomerMultiplierAction extends BaseAction {
	public static readonly TYPE_RESOURCE: string = 'resource';
	public static readonly TYPE_UPGRADE: string = 'upgrade';

	constructor(businessId: string, lootboxIds: string[], syncData: { [key: string]: any }) {
		super('BusinessNewCustomerMultiplier');

		this.data.id = businessId;
		this.data.lootboxes = lootboxIds;

		this.sync = syncData;
	}
}
