import { BusinessAcquiredUIViewSetter } from '@interfaces/ViewSetters';
import { TutorialStepUnlockTypeIncomeMoneyAppearModel } from './TutorialStepUnlockTypeIncomeMoneyAppearModel';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';

export class TutorialStepUnlockTypeIncomeMoneyAppearViewSetter implements BusinessAcquiredUIViewSetter {
	private unlockTypeModels: TutorialStepUnlockTypeIncomeMoneyAppearModel[];

	public setModels(unlockTypeModels: TutorialStepUnlockTypeIncomeMoneyAppearModel[]): void {
		this.unlockTypeModels = unlockTypeModels;
	}

	public setAcquiredUIView(view: BusinessAcquiredUIView): void {
		this.unlockTypeModels
			.filter(unlockTypeModel => unlockTypeModel.getBusinessKey() === view.getKey())
			.forEach(unlockTypeModel => unlockTypeModel.setAcquiredUIView(view));
	}
}
