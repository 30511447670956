import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';

export class LevelSnowAnimationConfig {
	public static getSnow(wight: number): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 1,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#d9f7ff',
			},
			speed: {
				start: 50,
				end: 90,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 90,
				max: 180,
			},
			noRotation: false,
			rotationSpeed: {
				min: -200,
				max: 200,
			},
			lifetime: {
				min: 5,
				max: 10,
			},
			blendMode: 'add',
			frequency: 0.0001,
			emitterLifetime: -1,
			maxParticles: 1000,
			pos: {
				x: 0,
				y: -50,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: wight,
				h: 500,
			},
		};
	}

	public static getGlow(wight: number): any {
		return {
			alpha: {
				start: 0,
				end: 0.8,
			},
			scale: {
				start: 0.5,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#91e8ff',
				end: '#d9f7ff',
			},
			speed: {
				start: 100,
				end: 180,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 90,
				max: 180,
			},
			noRotation: false,
			rotationSpeed: {
				min: -200,
				max: 200,
			},
			lifetime: {
				min: 2.5,
				max: 5,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: -1,
			maxParticles: 1000,
			pos: {
				x: 0,
				y: -50,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: wight,
				h: 500,
			},
		};
	}
}

export class LevelSnowAnimationView extends PIXI.Container {
	private readonly animationEmitters: Emitter[];
	private readonly animationsContainer: PIXI.Container;

	constructor(
		wight: number,
	) {
		super();
		this.animationEmitters = [];
		this.animationsContainer = new PIXI.Container();
		const snowEmitter = new Emitter(
			this.animationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['snow']],
			LevelSnowAnimationConfig.getSnow(wight),
		);
		snowEmitter.autoUpdate = true;

		const flashEmitter = new Emitter(
			this.animationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['party_glow1']],
			LevelSnowAnimationConfig.getGlow(wight),
		);
		flashEmitter.autoUpdate = true;

		this.animationEmitters.push(snowEmitter, flashEmitter);

		this.addChild(
			this.animationsContainer,
		);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}


