import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class PrepartyStartCharacterView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOWED: symbol = Symbol();

	private character: PIXI.spine.Spine;
	private buble: PIXI.spine.Spine;
	private tween: TWEEN.Tween;
	private unlocked: boolean;

	constructor() {
		super();

		this.character = new PIXI.spine.Spine(AssetsStorage.getResource('farewellPartyButton').spineData);
		this.character.autoUpdate = false;
		this.character.state.addListener({
			complete: () => this.emit(PrepartyStartCharacterView.EVENT_SHOWED),
		});

		this.buble = new PIXI.spine.Spine(AssetsStorage.getResource('farewellPartyButtonBubble').spineData);
		this.buble.setTransform(99, -145, 0.7, 0.7);
		this.buble.autoUpdate = false;

		this.addChild(this.character, this.buble);

		this.interactive = true;
		this.interactiveChildren = true;
		this.on('pointertap', this.onPointerDown);
	}

	public show(): void {
		this.visible = true;
		this.tween = new TWEEN.Tween(this)
			.to({ alpha: 1 }, 40)
			.repeat(1)
			.easing(TWEEN.Easing.Cubic.Out)
			.onComplete(() => {
				this.startBubleAnimation();
			})
			.start();
		this.startCharacterAnimnation();
	}

	public setLocked(value: boolean): void {
		this.unlocked = !value;
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	public hide(): void {
		if (this.tween?.isPlaying()) {
			this.tween.stop();
			this.tween = null;
		}

		this.visible = false;
		this.alpha = 0;

		this.stopCharacterAnimation();
		this.stopBubleAnimation();
	}

	private onPointerDown(): void {
		this.emit(PrepartyStartCharacterView.EVENT_CLICK);
	}

	private startCharacterAnimnation(): void {
		this.character.state.setAnimation(0, 'animation', true);
		this.character.autoUpdate = true;
	}

	private startBubleAnimation(): void {
		this.buble.state.setAnimation(0, 'start', false);
		this.buble.state.addAnimation(0, 'idle', true, 0);
		this.buble.autoUpdate = true;
	}

	private stopCharacterAnimation(): void {
		this.character.state.setEmptyAnimation(0, 0);
		this.character.autoUpdate = false;
	}

	private stopBubleAnimation(): void {
		this.buble.state.setEmptyAnimation(0, 0);
		this.buble.autoUpdate = false;
	}

	public getInteractiveObject(): PIXI.Container {
		return this.character;
	}
}
