import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { TextField } from '@views/components/text/TextField';
import { GameProfileModel } from '@models/GameProfileModel';
import { NotificationWindowView } from '../NotificationWindowView';
import { EmailAreaInputView } from '@views/components/input/EmailAreaInputView';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class PasswordRecoveryWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_RECOVER_CLICK: symbol = Symbol();

	private readonly gameProfileModel: GameProfileModel;
	private readonly localizationStorage: LocalizationStorage;

	private readonly emailTextArea: TextAreaInputView;

	constructor(gameProfileModel: GameProfileModel) {
		super(0.6);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_PASSWORD_RECOVER, this.onPasswordRecover, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_PASSWORD_RECOVER_LOGIN_NOT_FOUND, this.onLoginNotFound, this);

		const stringTitle = this.localizationStorage.getLocalizedString('#password_recovery_window_title');
		const labelTitle = new PIXI.extras.BitmapText(
			stringTitle,
			{ font: '40px wendyOneShadowBold' },
		);
		labelTitle.anchor = 0.5;
		labelTitle.y = -194;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 717;
		bg.height = 476;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const closeButton = new CloseButtonView();
		closeButton.position.set(310, -194);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 657;
		whiteBg.height = 210;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -40;

		const buttonRecover = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 388, 95),
			{ font: { size: 32, name: 'wendyOneShadowBold' } },
			370,
		);
		buttonRecover.setTextLabel(this.localizationStorage.getLocalizedString('#password_recovery_recover_label'));
		buttonRecover.on(ButtonBaseView.EVENT_CLICK, this.onButtonRecoverClick, this);
		buttonRecover.y = 153;

		const textBody = new TextField(
			this.localizationStorage.getLocalizedString('#password_recovery_window_body'),
			{ font: '22px wendyOne', tint: 0x465768, align: 'center' },
			551,
			63,
		);
		textBody.position.y = -95;
		textBody.anchor = 0.5;

		this.emailTextArea = new EmailAreaInputView({ bgWidth: 550 });
		this.emailTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_email_label'));
		this.emailTextArea.y = -5;

		this.mainContainer.addChild(
			bg as PIXI.DisplayObject,
			whiteBg,
			buttonRecover,
			textBody,
			labelTitle,
			closeButton,
			this.emailTextArea,
		);
	}

	private onButtonRecoverClick(): void {
		const textEmail = this.emailTextArea.getText();
		if (this.emailTextArea.isValid()) {
			this.emit(PasswordRecoveryWindowView.EVENT_BUTTON_RECOVER_CLICK, textEmail);
		} else {
			this.emailTextArea.onInvalidInput();
		}
	}

	private onPasswordRecover(): void {
		this.startCloseAnimation();

		const loggedInNotification = new NotificationWindowView(
			this.localizationStorage.getLocalizedString('#password_recovery_window_title'),
			this.localizationStorage.getLocalizedString('#label_password_recover_send_body'),
			this.localizationStorage.getLocalizedString('#login_window_button_login'),
		);
		loggedInNotification.onShown();
		loggedInNotification.once(NotificationWindowView.EVENT_WINDOW_CLOSED, () => this.onClose(false), this);
		this.addChild(loggedInNotification);
	}

	private onLoginNotFound(): void {
		this.emailTextArea.setTextInvalidInput(this.localizationStorage.getLocalizedString('#error_login_not_found'));
		this.emailTextArea.onInvalidInput();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_PASSWORD_RECOVER, this.onPasswordRecover, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_PASSWORD_RECOVER_LOGIN_NOT_FOUND, this.onLoginNotFound, this);
		super.destroy(options);
	}
}
