import LocalizationStorage from '@main/LocalizationStorage';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { TimeTravel } from '@src/types/TimeTravelTypes';
import { LevelStartBaseTime } from '@src/types/LevelStartBaseTimeTypes';

export class LevelEndWindowView extends WindowBaseView {
	public static readonly EVENT_APPEARING_ANIMATION_COMPLETED: symbol = Symbol();

	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly textTime: PIXI.extras.BitmapText;
	private readonly timerBg: PIXI.Sprite;
	private readonly openedBusinesses: number;
	private readonly timeTravel: TimeTravel;
	private readonly levelStartBaseTime: LevelStartBaseTime;
	private readonly timerContainer: PIXI.Container;
	private readonly textDate: PIXI.extras.BitmapText;
	private readonly localizationStorage: LocalizationStorage;
	private readonly hideUITimeout: number;
	private readonly showTimerTimeout: number;
	private readonly levelChangeBlackout: PIXI.Graphics;

	private startDate: Date;
	private endDate: Date;
	private cachedMonth: number;
	private cachedMonthText: string;

	constructor(
		openedBusinesses: number,
		season: number,
		level: number,
		timeTravel: TimeTravel,
		levelStartBaseTime: LevelStartBaseTime,
	) {
		super(0, false);
		this.openedBusinesses = openedBusinesses;
		this.timeTravel = timeTravel;
		this.levelStartBaseTime = levelStartBaseTime;

		this.localizationStorage = LocalizationStorage.getInstance();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();

		this.levelChangeBlackout = new PIXI.Graphics();
		this.levelChangeBlackout.interactive = true;
		this.levelChangeBlackout.beginFill(0x000000, 1);
		this.levelChangeBlackout.moveTo(0, 0);
		this.levelChangeBlackout.lineTo(GameConstants.GAME_WIDTH, 0);
		this.levelChangeBlackout.lineTo(GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
		this.levelChangeBlackout.lineTo(0, GameConstants.GAME_HEIGHT);
		this.levelChangeBlackout.closePath();

		const timerBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['orgy_icon_fade']);
		timerBg.anchor.set(0, 0);
		timerBg.scale.set(2, -2);
		timerBg.position.set(-22, GameConstants.GAME_HEIGHT);
		timerBg.alpha = 0;
		this.timerBg = timerBg;

		this.timerContainer = new PIXI.Container();
		this.timerContainer.position.set(408, 988);

		// const locationStr = 'Toronto, Canada';
		const locationStr = this.localizationStorage.getLocalizedString(`#season_${season}_location_desc`);
		const textLocationDesc = new PIXI.extras.BitmapText(locationStr, { font: '35px wendyOne', align: 'left' });
		textLocationDesc.anchor = new PIXI.Point(0, 0.5);
		textLocationDesc.position.set(-350, 37);

		const textTime = new PIXI.extras.BitmapText('19:42', {
			font: '69px wendyOne',
			tint: 0xeac86d,
		});
		textTime.anchor = new PIXI.Point(0, 0.5);
		textTime.position.set(-350, -25);
		this.textTime = textTime;

		const textDate = new PIXI.extras.BitmapText('October 23, 2019', {
			font: '69px wendyOne',
			tint: 0xeac86d,
		});
		textDate.anchor = new PIXI.Point(0, 0.5);
		textDate.position.set(-150, -25);
		this.textDate = textDate;

		this.timerContainer.addChild(
			textLocationDesc,
			textTime,
			textDate,
		);

		this.initDate();
		this.updateDateText(this.endDate);
	}

	private initDate(): void {
		const bt = this.levelStartBaseTime;
		this.startDate = new Date(bt.year, bt.month - 1, bt.days, bt.hours, bt.minutes);

		const endDate = new Date(this.startDate);

		const daysToAdd = this.timeTravel.additionalDays;
		endDate.setDate(this.startDate.getDate() + daysToAdd);

		const { hours, minutes } = this.timeTravel;
		endDate.setHours(hours, minutes);
		this.endDate = endDate;
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public startAppearingAnimation(): void {
		const showDuration = 300;

		this.timerBg.alpha = 0;
		new TWEEN.Tween(this.timerBg, this.tweenGroup)
			.to({ alpha: 1 }, showDuration)
			.start();

		this.timerContainer.scale.set(0.2);
		const tweenTimerShow = new TWEEN.Tween(this.timerContainer.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, showDuration)
			.easing(TWEEN.Easing.Sinusoidal.Out);

		const startTimerDelay = 400;
		const tweenStartAnimationDelay = new TWEEN.Tween({}, this.tweenGroup)
			.to({}, startTimerDelay)
			.onComplete(() => {
				this.emit(LevelEndWindowView.EVENT_APPEARING_ANIMATION_COMPLETED);
				this.startTimer();
			});

		tweenTimerShow.chain(tweenStartAnimationDelay);
		tweenTimerShow.start();

		this.addChild(
			this.timerBg,
			this.timerContainer,
		);
	}

	private startTimer(): void {
		const timerDuration = Math.max(1000, this.openedBusinesses * 150);

		this.createTimerTween(timerDuration);

		const showBlackoutDelay = 1000;
		this.startBlackoutAnimation(timerDuration + showBlackoutDelay);
	}

	private createTimerTween(duration: number): void {
		const tweenDate = new Date(this.endDate.getTime());
		new TWEEN.Tween({ milliseconds: this.endDate.getTime() }, this.tweenGroup)
			.to({ milliseconds: this.startDate.getTime() }, duration)
			.easing(TWEEN.Easing.Sinusoidal.InOut)
			.onUpdate((target) => {
				tweenDate.setTime(target.milliseconds);
				this.updateDateText(tweenDate);
			})
			.start();
	}

	private updateDateText(date: Date): void {
		const hours = date.getHours();
		const hoursDisplay: string = hours < 10
			? `0${hours}`
			: hours.toString();

		const minutes = date.getMinutes();
		const minutesDisplay: string = minutes < 10
			? `0${minutes}`
			: minutes.toString();

		this.textTime.text = `${hoursDisplay}:${minutesDisplay}`;

		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();

		let monthDisplay: string;

		if (this.cachedMonth === month) {
			monthDisplay = this.cachedMonthText;
		} else {
			monthDisplay = this.getMonthName(month + 1);
			this.cachedMonth = month;
			this.cachedMonthText = monthDisplay;
		}

		this.textDate.text = `${monthDisplay} ${day}, ${year}`;
	}

	private getMonthName(month: number): string {
		return this.localizationStorage.getLocalizedString(`#month_${month}`);
	}

	private startBlackoutAnimation(delay: number): void {
		this.addChild(this.levelChangeBlackout);
		this.levelChangeBlackout.alpha = 0;
		new TWEEN.Tween(this.levelChangeBlackout, this.tweenGroup)
			.to({ alpha: 1 }, 400)
			.delay(delay)
			.onComplete(() => {
				this.onClose();
			})
			.start();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.hideUITimeout) {
			window.clearTimeout(this.hideUITimeout);
		}

		if (this.showTimerTimeout) {
			window.clearTimeout(this.showTimerTimeout);
		}

		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		super.destroy(options);
	}
}
