import { RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { AssetsStorage } from '@main/AssetsStorage';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';
import { ResourceDailyCardView } from './ResourceDailyCardView';
import { PromotableBaseDailyCardView } from './PromotableBaseDailyCardView';
import { BoostDailyCardView } from './BoostDailyCardView';
import { TimeskipDailyCardView } from './TimeskipDailyCardView';
import { CharacterDailyCardView } from './CharacterDailyCardView';
import { BaseDailyCardView } from './BaseDailyCardView';
import { DailyRewardConfig } from '@configs/DailyRewardConfig';

export class CardDailyViewFactory {
	public static createStickerNew(): PIXI.Container {
		const result = new PIXI.Container();

		const stickerSale = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
		stickerSale.scale.set(0.5, 0.5);

		const saleLabel = new TextField(
			LocalizationStorage.getInstance().getLocalizedString('#video_sticker_new'),
			{ font: '18px wendyOne', align: 'center', tint: 0xfff78f },
			55,
			55,
		);
		saleLabel.anchor = 0.5;
		saleLabel.rotation = 0.47;

		result.addChild(
			stickerSale,
			saleLabel as PIXI.DisplayObject,
		);
		result.position.set(
			BaseDailyCardView.DEFAULT_WIDTH / 2 - BaseDailyCardView.STICKER_OFFSET,
			-BaseDailyCardView.DEFAULT_HEIGHT / 2 + BaseDailyCardView.STICKER_OFFSET,
		);

		return result;
	}

	constructor(
		private readonly characterModels: Map<string, CharacterModel>,
		private readonly upgradeModels: Map<string, UpgradeModel>,
		private readonly totemModels: Map<string, TotemModel>,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly timeskipModels: Map<string, TimeskipModel>,
	) {}

	public createRewardCard(
		dailyRewardConfig: DailyRewardConfig,
		scaleCard = 1,
		scaleResource = 1,
		stickerNew?: PIXI.Container,
	): BaseDailyCardView {
		const rewardType = dailyRewardConfig.getRewardType();
		const rewardId = dailyRewardConfig.getRewardId();
		const day = dailyRewardConfig.getDay();
		let card: BaseDailyCardView;
		let model: any;

		switch (rewardType) {
			case RewardTypes.RESOURCE: {
				card = new ResourceDailyCardView(rewardId as RewardResourceIdTypes, day);
				card.scale.set(scaleResource, scaleResource);
				break;
			}
			case RewardTypes.CHARACTER: {
				model = this.characterModels.get(rewardId);
				card = new CharacterDailyCardView(day, model, stickerNew, dailyRewardConfig.getRarity());
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.UPGRADE: {
				model = this.upgradeModels.get(rewardId);
				card = new PromotableBaseDailyCardView(day, model, stickerNew, dailyRewardConfig.getRarity());
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.TOTEM: {
				model = this.totemModels.get(rewardId);
				card = new PromotableBaseDailyCardView(day, model, stickerNew, dailyRewardConfig.getRarity());
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.BOOST: {
				model = this.boostModels.get(rewardId);
				card = new BoostDailyCardView(model.getIconKey(), day);
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			case RewardTypes.TIMESKIP: {
				model = this.timeskipModels.get(rewardId);
				card = new TimeskipDailyCardView(model.getIconKey(), day);
				card.scale.set(scaleCard, scaleCard);
				break;
			}
			default:
				throw new Error(`Unknown daily reward type: ${rewardType}`);
		}

		return card;
	}
}
