import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestType } from '@src/types/QuestTypes';

export class QuestGetSoftMoney extends AbstractQuest {
	private readonly softMoneyModel: SoftMoneyModel;

	private targetAmount: SoftMoneyNumber;
	private currentAmount: SoftMoneyNumber;

	constructor(
		questKey: string,
		softMoneyModel: SoftMoneyModel,
		questType: QuestType,
		lineId: number,
		mainWindowViewParameters: string[],
		amount?: SoftMoneyNumber,
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.softMoneyModel = softMoneyModel;

		this.currentAmount = SoftMoneyNumber.ZERO;

		this.targetAmount = amount;

		this.trackProgressStart();
	}

	public getTargetAmount(): SoftMoneyNumber {
		return this.targetAmount;
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = SoftMoneyNumber.createFromString(saveData.progress.toString());

		if (saveData && saveData.completeValue !== undefined) {
			this.targetAmount = SoftMoneyNumber.createFromString(saveData.completeValue);
		}

		if (this.currentAmount.greaterThanOrEqualTo(this.targetAmount)) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.completed ? this.getTotalProgressValue() : this.currentAmount.toString();
	}

	public getCurrentProgressValueAsSoftMoney(): SoftMoneyNumber {
		return this.completed ? this.targetAmount : this.currentAmount;
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : Number(this.currentAmount.divide(this.targetAmount).toRawString(2));
	}

	private onMoneyValueIncreased(deltaIncrease: SoftMoneyNumber): void {
		this.currentAmount = this.currentAmount.add(deltaIncrease);

		if (this.currentAmount.greaterThanOrEqualTo(this.targetAmount)) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this, false);
	}

	protected trackProgressStart(): void {
		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this);
	}
}
