import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character34View } from '@views/characters/main/Character34View';
import { Character35View } from '@views/characters/main/Character35View';
import { AssetsStorage } from '@main/AssetsStorage';
import { Character36View } from '@views/characters/main/Character36View';

export class Business12View extends BusinessBaseView {
	constructor() {
		const character34 = new Character34View();
		const character35 = new Character35View();
		const character36 = new Character36View();

		super('business12', [character34, character35, character36]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character36,
			character34 as PIXI.DisplayObject,
			character35,
		);
	}
}
