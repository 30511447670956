import LocalizationStorage from '@main/LocalizationStorage';
import { TotemModel } from '@models/TotemModel';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { UnlockTypes } from '@src/types/UnlockTypes';

export class TotemCardUnlockInfoView extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;
	private readonly lockText: PIXI.extras.BitmapText;
	private readonly model: TotemModel;
	private readonly currentLevel: number;

	constructor(
		model: TotemModel,
		currentLevel: number,
	) {
		super();

		this.model = model;
		this.currentLevel = currentLevel;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.lockText = new MultiColoredTextField({
			font: '40px wendyOneShadowBold',
			align: 'center',
			tint: 0x9aaec0,
		});

		this.lockText.anchor = 0.5;
		this.lockText.position.set(0, 0);

		this.updateText();

		this.addChild(
			this.lockText as PIXI.DisplayObject,
		);
	}

	private updateText(): void {
		this.lockText.text = this.getUnlockText();
	}

	private getUnlockText(): string {
		const unlockInfo = this.model.getUnlockInfo();

		if (unlockInfo.type !== UnlockTypes.MIN_LEVEL) {
			return this.localizationStorage.getLocalizedString('#totem_NotOpenedInfo_event');
		}

		if (this.currentLevel < this.model.getUnlockLevel()) {
			return this.localizationStorage.getLocalizedString('#character_UnlockLevel')
				.replace('{{level}}', unlockInfo.value);
		}

		return this.localizationStorage.getLocalizedString('#preparty_totem_available_label');
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
