import { RewardDescriptionType, RewardResourceIdTypes } from '@src/types/RewardTypes';
import SoftMoneyNumber from '../utils/SoftMoneyNumber';
import { SummonModel } from '@models/SummonModel';
import { PromotableModel } from '@models/PromotableModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { CharacterModel } from './CharacterModel';
import SoftMoneyModel from './money/SoftMoneyModel';
import { UpgradeModel } from './UpgradeModel';

export class ModelHelper {
	public static mergeRewardDescriptions(rewardDescriptions: RewardDescriptionType[]): RewardDescriptionType[] {
		const result: RewardDescriptionType[] = [];
		rewardDescriptions.forEach((rewardDescription: RewardDescriptionType) => {
			const sameRewardDescr = result.find(item => item.reward_id === rewardDescription.reward_id);
			if (sameRewardDescr) {
				if (sameRewardDescr.reward_id === RewardResourceIdTypes.SOFT_MONEY) {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					sameRewardDescr.reward_qty = SoftMoneyNumber.createFromString(sameRewardDescr.reward_qty as string)
						.add(SoftMoneyNumber.createFromString(rewardDescription.reward_qty as string))
						.toRawString();
				} else {
					const oldQty = Number(sameRewardDescr.reward_qty);
					// eslint-disable-next-line @typescript-eslint/naming-convention
					sameRewardDescr.reward_qty = oldQty + Number(rewardDescription.reward_qty);
				}
			} else {
				result.push({ ...rewardDescription });
			}
		});
		return result;
	}

	public static getSummonModelWithMaxCost(models: SummonModel[]): SummonModel | undefined {
		let maxCost = 0;
		let result = models[0];

		models.forEach((model) => {
			const cost = model.getPriceValue();

			if (cost > maxCost) {
				maxCost = cost;
				result = model;
			}
		});

		return result;
	}

	public static getSummonModelWithMaxPrestigeCost(models: SummonModel[]): SummonModel | undefined {
		let maxCost = 0;
		let result = models[0];

		models
			.filter((model) => model.isPrestigePrice())
			.forEach((model) => {
				const cost = model.getPriceValue();

				if (cost > maxCost) {
					maxCost = cost;
					result = model;
				}
			});

		return result;
	}

	public static canShowCollectionTabInfoIconEpicQuests(models: EpicQuestCollectionsModel[]): boolean {
		return models.some(model => model.hasAnyCollectableRewards());
	}

	public static canShowCollectionTabInfoIconPromotable(models: PromotableModel[]): boolean {
		return models.some(model => model.isOpened() && (model.isNew() || model.isEnoughCardsForPromote()));
	}

	public static canShowCollectionTabInfoIconGallery(models: GalleryVideoModel[]): boolean {
		return models.some(model => model.isOpened() && model.isNew());
	}

	public static canShowCollectionTabInfoIconSummon(
		summonModels: SummonModel[],
	): boolean {
		return summonModels
			.some(model => model.isFreePriceAvailable());
	}

	public static sortBankOffersModelsByPriority(models: BankOfferModel[]): BankOfferModel[] {
		const sort = (a: BankOfferModel, b: BankOfferModel): number => {
			let result: number;
			if (a.getPriority() !== b.getPriority()) {
				result = b.getPriority() - a.getPriority();
			} else {
				result = a.getTimeleft() - b.getTimeleft();
			}

			return result;
		};

		return models.slice(0).sort(sort);
	}

	public static filterBankOfferModelsByTimeleft(models: BankOfferModel[]): BankOfferModel[] {
		return models.filter(offerModel => offerModel.getTimeleft() > 0);
	}

	public static isEnoughSoftMoneyForActivate(
		model: CharacterModel | UpgradeModel,
		softMoneyModel: SoftMoneyModel,
	): boolean {
		return softMoneyModel.getValue().greaterThanOrEqualTo(model.getActivateCost());
	}
}
