import { TotemModel } from '@models/TotemModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableBaseCardView } from './PromotableBaseCardView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { TextDescriptionHelperTotem } from '@views/windows/collection/totems/TextDescriptionHelperTotem';

export class TotemCardView extends PromotableBaseCardView<TotemModel> {
	private readonly fuckPowerLabel: MultiColoredTextField;

	constructor(
		model: TotemModel,
		autoUpdateProgressBar?: boolean,
		showFuckpowerLabel?: boolean,
	) {
		super(
			model,
			autoUpdateProgressBar,
		);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('totemIconAtlas')[`big_${model.getKey()}`]);
		this.addChildAt(icon, 0);

		if (showFuckpowerLabel) {
			icon.y = -35;
		}

		const totemPictureMask = new PIXI.Graphics();
		totemPictureMask.beginFill(0x000000);
		totemPictureMask.drawPolygon([
			new PIXI.Point(250, -368),
			new PIXI.Point(250, 368),
			new PIXI.Point(-250, 368),
			new PIXI.Point(-250, -368),
		]);
		totemPictureMask.y = -42;

		const extraLine = new PIXI.Graphics();
		extraLine.lineStyle(10, 0x000000, 1);
		extraLine.beginFill(0x000000, 0);
		extraLine.drawRect(0, 0, 490, 726);
		extraLine.pivot.set(extraLine.width / 2, extraLine.height / 2);
		extraLine.position.set(5, -37);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`upgrade_${model.getCardRarity()}_card_bg`]);
		bg.position.set(0, -42);
		bg.mask = totemPictureMask;
		this.addChildAt(bg, 0);

		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${model.getCardRarity()}_bg_round`], 3, 3, 3, 3);
		background.width = 540;
		background.height = 780;
		background.pivot.set(background.width / 2, background.height / 2);
		background.position.set(0, totemPictureMask.y);

		const blackOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		blackOutline.width = 544;
		blackOutline.height = 783;
		blackOutline.pivot.set(blackOutline.width / 2, blackOutline.height / 2);
		blackOutline.tint = 0x00000;
		blackOutline.position.set(0, totemPictureMask.y);

		this.addChildAt(totemPictureMask, 0);
		this.addChildAt(extraLine, this.getChildIndex(bg) + 1);
		this.addChildAt(background, 0);
		this.addChildAt(blackOutline, 0);

		if (showFuckpowerLabel) {
			this.fuckPowerLabel = new MultiColoredTextField({
				font: '70px wendyOneShadowBold',
				align: 'center',
			}, 540, 140);
			this.fuckPowerLabel.anchor = 0.5;
			this.fuckPowerLabel.position.set(0, 250);
			this.addChild(this.fuckPowerLabel);
		}

		this.model.on(TotemModel.EVENT_PROMOTED, this.updateFuckPowerText, this);
		this.model.on(TotemModel.EVENT_CARDS_ADDED, this.updateFuckPowerText, this);

		this.progressBar.y = 390;

		this.onLanguageChange();
	}

	protected onLanguageChange(): void {
		this.updateFuckPowerText();
		super.onLanguageChange();
	}

	private updateFuckPowerText(): void {
		if (this.fuckPowerLabel) {
			const text = TextDescriptionHelperTotem.getBonusShortTemplateString(this.model);
			this.fuckPowerLabel.text = text;
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(TotemModel.EVENT_PROMOTED, this.updateFuckPowerText, this);
		this.model.off(TotemModel.EVENT_CARDS_ADDED, this.updateFuckPowerText, this);

		super.destroy(options);
	}
}
