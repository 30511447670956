import LocalizationStorage from '@main/LocalizationStorage';
import { DialogModel } from '@models/DialogModel';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { DialogBitmapText } from './DialogBitmapText';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SoundController } from '@src/main/SoundController';
import { TextUtils } from '@src/utils/TextUtils';
import { DialogCharacterPlacements, DialogDataItem } from '@src/types/DialogTypes';

export class DialogWindowView extends WindowBaseView {
	public static readonly EVENT_MOVE: symbol = Symbol();

	private static readonly TEXT_PHRASE_MAX_WIDTH: number = 850;

	private static readonly POS_DIALOG_BG_LEFT: PIXI.Point = new PIXI.Point(-255, 527);
	private static readonly POS_DIALOG_BG_RIGHT: PIXI.Point = new PIXI.Point(255, 527);

	private static readonly POS_TEXT_TITLE_LEFT: PIXI.Point = new PIXI.Point(-435, 307);
	private static readonly POS_TEXT_TITLE_RIGHT: PIXI.Point = new PIXI.Point(440, 307);

	private static readonly POS_TRIANGLE_LEFT: PIXI.Point = new PIXI.Point(372, 510);
	private static readonly POS_TRIANGLE_RIGHT: PIXI.Point = new PIXI.Point(425, 510);

	private static readonly POS_TEXT_PHRASE_LEFT: PIXI.Point = new PIXI.Point(-435, 419);
	private static readonly POS_TEXT_PHRASE_RIGHT: PIXI.Point = new PIXI.Point(-400, 419);

	private currentCharacterImage: string;

	private currentSprite: PIXI.Sprite;
	private readonly right1Sprite: PIXI.Sprite;
	private readonly right2Sprite: PIXI.Sprite;
	private readonly left1Sprite: PIXI.Sprite;
	private readonly left2Sprite: PIXI.Sprite;

	private readonly dialogBg: PIXI.mesh.NineSlicePlane;
	private readonly textTitle: PIXI.extras.BitmapText;
	private readonly textPhrase: DialogBitmapText;
	private readonly tapToContinueText: PIXI.extras.BitmapText;

	private readonly dialogAtlas: PIXI.loaders.TextureDictionary;
	private readonly dialogTriangle: PIXI.Sprite;

	private localizationStorage: LocalizationStorage;
	private dialog: DialogModel;

	private tweenGroup: TWEEN.Group;

	private previousDialogPlacement: DialogCharacterPlacements;

	private tweenMainContainer: TWEEN.Tween;
	private tweenDialogTriangle: TWEEN.Tween;

	private ticker: PIXI.ticker.Ticker;

	constructor(model: DialogModel) {
		super(0.8, false);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.dialog = model;


		this.dialogAtlas = AssetsStorage.getAtlas('dialogAtlas');

		this.ticker = PIXI.ticker.shared;

		this.tweenGroup = new TWEEN.Group();

		this.dialogTriangle = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['arrow_blue']);

		this.tapToContinueText = new PIXI.extras.BitmapText('', {
			font: '25px wendyOne',
			align: 'left',
			tint: 0x7e8a98,
		});
		this.tapToContinueText.text = this.localizationStorage.getLocalizedString('#tutorial_label_tap_to_continue');
		this.tapToContinueText.anchor = new PIXI.Point(1, 0.5);
		this.tapToContinueText.position.set(-30, -2);
		this.dialogTriangle.addChild(this.tapToContinueText);

		// Right1
		this.right1Sprite = new PIXI.Sprite();
		this.right1Sprite.position.set(GameConstants.GAME_WIDTH - 163, 833);
		this.right1Sprite.anchor.set(0.5, 0.5);
		this.right1Sprite.scale.x *= -1;

		// Right2
		this.right2Sprite = new PIXI.Sprite();
		this.right2Sprite.position.set(GameConstants.GAME_WIDTH - 318, 833);
		this.right2Sprite.anchor.set(0.5, 0.5);
		this.right2Sprite.scale.x *= -1;

		// Left1
		this.left1Sprite = new PIXI.Sprite();
		this.left1Sprite.position.set(163, 833);
		this.left1Sprite.anchor.set(0.5, 0.5);

		// Left2
		this.left2Sprite = new PIXI.Sprite();
		this.left2Sprite.position.set(317, 833);
		this.left2Sprite.anchor.set(0.5, 0.5);

		this.dialogBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['TextBlockSlice'], 1, 0, 18, 0);
		this.dialogBg.width = 1405;
		this.dialogBg.pivot.set(this.dialogBg.width / 2, this.dialogBg.height);

		this.textTitle = new PIXI.extras.BitmapText('sadsadsda', { font: '42px wendyOneShadowBold' });
		this.textTitle.anchor = new PIXI.Point(0, 0.5);

		this.textPhrase = new DialogBitmapText('', {
			font: '34px wendyOne',
			align: 'left',
			tint: 0x172733,
		});
		this.textPhrase.anchor = new PIXI.Point(0, 0.5);
		this.textPhrase.maxWidth = DialogWindowView.TEXT_PHRASE_MAX_WIDTH;

		this.mainContainer.addChild(
			this.dialogBg,
			this.textTitle as PIXI.DisplayObject,
			this.textPhrase,
			this.dialogTriangle,
		);

		this.addChild(
			this.left1Sprite as PIXI.DisplayObject,
			this.right1Sprite,
			this.left2Sprite,
			this.right2Sprite,
		);

		this.textPhrase.on(DialogBitmapText.EVENT_TEXT_SHOWN_ALL, this.onTextShownAll, this);

		this.on('pointertap', this.onClick, this);
	}

	public onShown(): void {
		super.onShown();
		this.ticker.add(this.update, this);
		this.moveDialog();
		this.interactive = true;
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private resetMoveDialogAnimation(): void {
		if (this.tweenMainContainer) {
			this.tweenMainContainer.stop();
			this.tweenMainContainer = null;
		}
	}

	private moveDialog(): void {
		this.dialog.moveDialog();
		if (this.dialog.isDialogCompleted()) {
			this.onClose();
			return;
		}

		if (this.tweenDialogTriangle) {
			this.tweenDialogTriangle.stop();
			this.tweenDialogTriangle = null;
		}

		const dialogDataItem: DialogDataItem = this.dialog.getCurrentDialogDataItem();
		const characterImage = this.dialogAtlas[`${dialogDataItem.characterImage}Dialog`];
		const { charactersVisible } = dialogDataItem;
		const characterName = this.localizationStorage.getLocalizedString(`${dialogDataItem.characterName}_Name`);
		const previousCharacterName = this.textTitle.text;

		this.textTitle.text = characterName;
		this.dialogTriangle.visible = false;

		let textPhrase = this.localizationStorage.getLocalizedString(dialogDataItem.phrase);
		if (this.dialog.hasReplaceCharacters()) {
			const replaceCharacters = this.dialog.getReplaceCharacters();
			replaceCharacters.forEach(replaceCharacter => {
				if (textPhrase.includes(replaceCharacter)) {
					const replaceValue = this.dialog.getReplaceCharacterValue(replaceCharacter);
					textPhrase = TextUtils.replaceValueAndEnding(
						this.localizationStorage.getLanguage(),
						replaceCharacter,
						replaceValue,
						textPhrase,
					);
				}
			});
		}

		this.textPhrase.text = textPhrase;

		[this.left1Sprite, this.left2Sprite, this.right1Sprite, this.right2Sprite].forEach((sprite) => {
			sprite.visible = false;
		});

		charactersVisible.forEach((characterVisible) => {
			const characterVisibleImage = this.dialogAtlas[`${characterVisible.characterImage}Dialog`];
			let resetSprite: PIXI.Sprite;
			// eslint-disable-next-line default-case
			switch (characterVisible.placement) {
				case DialogCharacterPlacements.LEFT1:
					resetSprite = this.left1Sprite;
					break;
				case DialogCharacterPlacements.LEFT2:
					resetSprite = this.left2Sprite;
					break;
				case DialogCharacterPlacements.RIGHT1:
					resetSprite = this.right1Sprite;
					break;
				case DialogCharacterPlacements.RIGHT2:
					resetSprite = this.right2Sprite;
					break;
			}
			resetSprite.texture = characterVisibleImage;
			resetSprite.visible = true;
			resetSprite.tint = 0x7E7E7E;
		});

		if (Boolean(this.currentCharacterImage) && this.currentCharacterImage !== dialogDataItem.characterImage) {
			const finalX = this.currentSprite.scale.x < 0 ? -1 : 1;
			const finalY = this.currentSprite.scale.y < 0 ? -1 : 1;
			new TWEEN.Tween(this.currentSprite.scale, this.tweenGroup)
				.to({ x: finalX, y: finalY }, 100)
				.start();
		}

		// eslint-disable-next-line default-case
		switch (dialogDataItem.placement) {
			case DialogCharacterPlacements.LEFT1: {
				this.currentSprite = this.left1Sprite;

				this.dialogBg.scale.set(1, 1);
				this.dialogBg.position = DialogWindowView.POS_DIALOG_BG_LEFT;
				this.textPhrase.position = DialogWindowView.POS_TEXT_PHRASE_LEFT;
				this.textPhrase.align = 'left';
				this.textPhrase.anchor = new PIXI.Point(0, 0.5);
				this.textTitle.position = DialogWindowView.POS_TEXT_TITLE_LEFT;
				this.textTitle.anchor = new PIXI.Point(0, 0.5);
				this.dialogTriangle.position = new PIXI.Point(
					DialogWindowView.POS_TRIANGLE_LEFT.x,
					DialogWindowView.POS_TRIANGLE_LEFT.y,
				);
				break;
			}
			case DialogCharacterPlacements.LEFT2: {
				this.currentSprite = this.left2Sprite;

				this.dialogBg.scale.set(1, 1);
				this.dialogBg.position = DialogWindowView.POS_DIALOG_BG_LEFT;
				this.textPhrase.position = DialogWindowView.POS_TEXT_PHRASE_LEFT;
				this.textTitle.position = DialogWindowView.POS_TEXT_TITLE_LEFT;
				this.textPhrase.anchor = new PIXI.Point(0, 0.5);
				this.textTitle.anchor = new PIXI.Point(0, 0.5);
				this.textPhrase.align = 'left';
				this.dialogTriangle.position = new PIXI.Point(
					DialogWindowView.POS_TRIANGLE_LEFT.x,
					DialogWindowView.POS_TRIANGLE_LEFT.y,
				);
				break;
			}
			case DialogCharacterPlacements.RIGHT1: {
				this.currentSprite = this.right1Sprite;

				this.dialogBg.scale.set(-1, 1);
				this.dialogBg.position = DialogWindowView.POS_DIALOG_BG_RIGHT;
				this.textPhrase.position = DialogWindowView.POS_TEXT_PHRASE_RIGHT;
				this.textPhrase.anchor = new PIXI.Point(0, 0.5);
				this.textPhrase.align = 'left';
				this.textTitle.anchor = new PIXI.Point(1, 0.5);
				this.textTitle.position = DialogWindowView.POS_TEXT_TITLE_RIGHT;
				this.dialogTriangle.position = new PIXI.Point(
					DialogWindowView.POS_TRIANGLE_RIGHT.x,
					DialogWindowView.POS_TRIANGLE_RIGHT.y,
				);
				break;
			}
			case DialogCharacterPlacements.RIGHT2: {
				this.currentSprite = this.right2Sprite;

				this.dialogBg.scale.set(-1, 1);
				this.dialogBg.position = DialogWindowView.POS_DIALOG_BG_RIGHT;
				this.textPhrase.position = DialogWindowView.POS_TEXT_PHRASE_RIGHT;
				this.textPhrase.anchor = new PIXI.Point(0, 0.5);
				this.textTitle.anchor = new PIXI.Point(1, 0.5);
				this.textPhrase.align = 'left';
				this.textTitle.position = DialogWindowView.POS_TEXT_TITLE_RIGHT;
				this.dialogTriangle.position = new PIXI.Point(
					DialogWindowView.POS_TRIANGLE_RIGHT.x,
					DialogWindowView.POS_TRIANGLE_RIGHT.y,
				);
				break;
			}
		}

		const newCharacterImage = dialogDataItem.characterImage;
		this.currentSprite.texture = characterImage;
		this.currentSprite.visible = true;
		this.currentSprite.tint = 0xFFFFFF;
		this.setChildIndex(this.currentSprite, this.children.length - 1);

		if (this.currentCharacterImage !== newCharacterImage) {
			const finalX = this.currentSprite.scale.x < 0 ? -1.1 : 1.1;
			const finalY = this.currentSprite.scale.y < 0 ? -1.1 : 1.1;
			new TWEEN.Tween(this.currentSprite.scale, this.tweenGroup)
				.to({ x: finalX, y: finalY }, 100)
				.start();
		}
		this.currentCharacterImage = newCharacterImage;

		if (this.textTitle.text !== previousCharacterName) {
			SoundController.getInstance().playDialogSwitch();
			this.resetMoveDialogAnimation();

			switch (dialogDataItem.placement) {
				case DialogCharacterPlacements.LEFT1:
				case DialogCharacterPlacements.LEFT2: {
					if (this.previousDialogPlacement
						&& ((this.previousDialogPlacement === DialogCharacterPlacements.LEFT1)
							|| (this.previousDialogPlacement === DialogCharacterPlacements.LEFT2))) {
						this.mainContainer.x = GameConstants.GAME_CENTER_X;
					} else {
						this.mainContainer.x = 820;
						this.tweenMainContainer = new TWEEN.Tween(this.mainContainer, this.tweenGroup)
							.to({ x: GameConstants.GAME_CENTER_X }, 200)
							.easing(TWEEN.Easing.Quadratic.Out)
							.onComplete(() => {
								this.tweenMainContainer = null;
							})
							.start();
					}
					break;
				}
				case DialogCharacterPlacements.RIGHT1:
				case DialogCharacterPlacements.RIGHT2: {
					if (this.previousDialogPlacement
						&& ((this.previousDialogPlacement === DialogCharacterPlacements.RIGHT1)
							|| (this.previousDialogPlacement === DialogCharacterPlacements.RIGHT2))) {
						this.mainContainer.x = GameConstants.GAME_CENTER_X;
					} else {
						this.mainContainer.x = 1100;
						this.tweenMainContainer = new TWEEN.Tween(this.mainContainer, this.tweenGroup)
							.to({ x: GameConstants.GAME_CENTER_X }, 200)
							.easing(TWEEN.Easing.Quadratic.Out)
							.onComplete(() => {
								this.tweenMainContainer = null;
							})
							.start();
					}
					break;
				}
				default:
					throw new Error(`Unsupported character placement '${dialogDataItem.placement}'`);
			}
		}
		this.previousDialogPlacement = dialogDataItem.placement;

		this.emit(DialogWindowView.EVENT_MOVE, this.dialog);
	}

	private onTextShownAll(): void {
		this.dialogTriangle.visible = !this.dialog.isDialogCompleted();
		this.tweenDialogTriangle = new TWEEN.Tween(this.dialogTriangle.position, this.tweenGroup)
			.to({ y: this.dialogTriangle.position.y - 4 }, 250)
			.easing(TWEEN.Easing.Bounce.InOut)
			.repeat(Infinity)
			.yoyo(true)
			.start();
	}

	private onClick(): void {
		if (this.textPhrase.isShowing()) {
			this.textPhrase.showTextAll();
			this.resetMoveDialogAnimation();
		} else {
			this.moveDialog();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
