import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from './ButtonBaseView';

export class CloseButtonView extends ButtonBaseView {
	constructor() {
		const sprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['button_close']);
		super(sprite);

		const hitAreaOffset = 5;
		this.hitArea = new PIXI.Circle(0, 0, this.width + hitAreaOffset);
	}
}
