import { AbstractQuest } from './AbstractQuest';
import { BusinessModel } from '@models/BusinessModel';
import { QuestTypes } from '@src/types/QuestTypes';
import { QuestRestoreStateData } from '@src/types/SaveTypes';

export class QuestAcquireBusinesses extends AbstractQuest {
	private readonly businesses: BusinessModel[];

	private currentCount: number;
	private targetCount: number;

	constructor(
		questKey: string,
		acquireCount: number,
		businesses: BusinessModel[],
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.ACQUIRE_BUSINESSES,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.businesses = businesses;

		this.targetCount = acquireCount;

		this.currentCount = businesses.filter(m => m.isAcquired()).length;
		if (this.currentCount >= this.targetCount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	private onSomeBusinessAcquired(): void {
		this.currentCount += 1;

		if (this.currentCount >= this.targetCount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentCount = Number(saveData.progress);
		if (this.currentCount >= this.targetCount) {
			this.complete();
		}
	}

	public getBusinessKeyToAcquireNext(): string | undefined {
		let key: string;
		const business = this.businesses.find(model => !model.isAcquired());
		if (business) {
			key = business.getKey();
		}
		return key;
	}

	public getCurrentProgressValue(): string {
		return this.currentCount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetCount.toString();
	}

	public getTargetCount(): number {
		return this.targetCount;
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentCount / this.targetCount;
	}

	protected trackProgressStop(): void {
		this.businesses.forEach(target => target.off(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this, true));
	}

	protected trackProgressStart(): void {
		this.businesses.forEach(target => target.once(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this));
	}
}
