export class CharacterClickSoundModel {
	private readonly soundList: string[];
	private lastPlayedSoundIndex: number;
	private soundPlaying: boolean;

	constructor(soundList: string[]) {
		this.soundList = soundList;
		this.lastPlayedSoundIndex = -1;
	}

	public getNextSound(): string {
		let newSoundIndex;
		do {
			newSoundIndex = Math.floor(Math.random() * this.soundList.length);
		} while (this.lastPlayedSoundIndex === newSoundIndex);
		this.lastPlayedSoundIndex = newSoundIndex;

		return this.soundList[newSoundIndex];
	}

	public isPlaying(): boolean {
		return this.soundPlaying;
	}

	public setPlaying(value: boolean): void {
		this.soundPlaying = value;
	}
}
