import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { RewardDescriptionType } from './RewardTypes';

export enum HintTypes {
	SOFT_MONEY_PANEL,
	PRESTIGE_MONEY_PANEL,
	HARD_MONEY_PANEL,
	SKILL_HINT,
	LEVEL_PANEL_HINT,
	EPIC_QUEST,
	REWARD_CARD,
	BUSINESS_CARD,
	BUNDLE_INFO,
}

export type HintDataPosition = {
	arrowPosLocal: PIXI.Point;
	origin: PIXI.DisplayObject;
}
export type HintDataBundle = {
	rewards: RewardDescriptionType[];
	origin: PIXI.DisplayObject;
}
export type HintDataReward = {
	rewardDescription: RewardDescriptionType;
	origin: PIXI.DisplayObject;
}
export type HintDataBusinessCard = {
	model: UpgradeModel | CharacterModel;
	origin: PIXI.DisplayObject;
}
export type HintDataEpicQuiest = {
	bodyOffset: number;
	arrowPosLocal: PIXI.Point;
	origin: PIXI.DisplayObject;
}
export type HintDataSkill = {
	skill: string;
	character: string;
	arrowPosLocal: PIXI.Point;
	origin: PIXI.DisplayObject;
}
