import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter51004View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51004View';
import { EventCharacter51005View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51005View';
import { EventCharacter51006View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51006View';

export class EventBusiness51002View extends BusinessBaseView {
	constructor() {
		const eventCharacter51004View = new EventCharacter51004View();
		const eventCharacter51005View = new EventCharacter51005View();
		const eventCharacter51006View = new EventCharacter51006View();

		super('business51002', [eventCharacter51004View, eventCharacter51005View, eventCharacter51006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter51004View as PIXI.DisplayObject,
			eventCharacter51005View,
			eventCharacter51006View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
