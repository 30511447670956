import { FarewellPartyHeartClickSoundList } from '@src/types/SoundList';

export class FarewellPartyHeartClickSoundModel {
	private readonly soundList: string[];
	private lastPlayedSoundIndex: number;
	private soundPlaying: boolean;

	constructor() {
		this.soundList = FarewellPartyHeartClickSoundList;
		this.lastPlayedSoundIndex = -1;
	}

	public getNextSound(): string {
		this.lastPlayedSoundIndex += 1;
		if (this.lastPlayedSoundIndex === this.soundList.length) {
			this.lastPlayedSoundIndex = 0;
		}

		return this.soundList[this.lastPlayedSoundIndex];
	}

	public isPlaying(): boolean {
		return this.soundPlaying;
	}

	public setPlaying(value: boolean): void {
		this.soundPlaying = value;
	}
}
