import { AssetsStorage } from '@main/AssetsStorage';
import { BaseDailyCardView } from './BaseDailyCardView';

export class TimeskipDailyCardView extends BaseDailyCardView {
	private readonly bg: PIXI.Sprite;
	private readonly icon: PIXI.Sprite;

	constructor(iconKey: string, day: number) {
		super(day);

		const miniCardsBase = AssetsStorage.getAtlas('miniCardsBaseAtlas');

		this.bg = new PIXI.Sprite(miniCardsBase['mini_card_boosts_bg']);
		this.bg.scale.set(1.45);
		this.bg.mask = this.contentMask;

		this.icon = new PIXI.Sprite(miniCardsBase[`${iconKey}_mini_card_icon`]);

		this.addChild(
			this.bg as PIXI.DisplayObject,
			this.contentMask,
			this.upTint,
			this.icon,
			this.dayString,
			this.downTint,
			this.countString,
		);
	}

	public setExtraRewardMode(): void {
		super.setExtraRewardMode();

		this.bg.scale.set(3.5);
	}
}
