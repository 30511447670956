import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { LevelModel } from '@models/level/LevelModel';

export class DailyRewardEmitter extends PIXI.utils.EventEmitter {
	public static readonly EVENT_DAILY_REWARD_AVAILABLE = Symbol();
	private isEnabled: boolean;
	private isPending: boolean;

	constructor(
		private readonly dailyRewardsModel: DailyRewardsModel,
		levelModel: LevelModel,
	) {
		super();

		this.isEnabled = false;
		this.isPending = false;

		if (this.dailyRewardsModel.getIsEnabled()
			&& levelModel.getCurrentLevel() >= this.dailyRewardsModel.getMinLevel()) {
			this.dailyRewardsModel.once(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED, this.emitDailyRewardAvailable, this);
		}
	}

	public setEnabled(value: boolean): void {
		this.isEnabled = value;

		if (this.isEnabled && this.isPending) {
			this.emitDailyRewardAvailable();
		}
	}

	private emitDailyRewardAvailable(): void {
		if (this.isEnabled && this.dailyRewardsModel.isRewardClaimable()) {
			this.emit(DailyRewardEmitter.EVENT_DAILY_REWARD_AVAILABLE);
			this.isPending = false;
		} else if (!this.isEnabled) {
			this.isPending = true;
		}
	}
}
