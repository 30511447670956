import { AbstractReward } from '@interfaces/AbstractReward';
import { ResourceCardView } from './ResourceCardView';
import { RewardCharacterCards } from '@models/rewards/RewardCharacterCards';
import { CharacterCardView } from './CharacterCardView';
import { RewardUpgradeCards } from '@models/rewards/RewardUpgradeCards';
import { UpgradeCardView } from './UpgradeCardView';
import { RewardTotemCards } from '@models/rewards/RewardTotemCards';
import { TotemCardView } from './TotemCardView';
import { VideoCardView } from './VideoCardView';
import { RewardVideo } from '@models/rewards/RewardVideo';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { PromotableBaseCardView, PromotableClickData } from './PromotableBaseCardView';
import { SkillModel } from '@models/skills/SkillModel';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { BoostCardView } from './BoostCardView';
import { TimeskipCardView } from './TimeskipCardView';
import { RewardBoost } from '@models/rewards/RewardBoost';
import { RewardTimeskip } from '@models/rewards/RewardTimeskip';

export class CardViewFactory extends PIXI.utils.EventEmitter {
	public static readonly EVENT_CLICK_CARD_VIDEO: symbol = Symbol();
	public static readonly EVENT_CLICK_CARD_CHARACTER: symbol = Symbol();
	public static readonly EVENT_CLICK_CARD_TOTEM: symbol = Symbol();
	public static readonly EVENT_CLICK_CARD_UPGRADE: symbol = Symbol();

	public static trySetPreviousCardProgress(card: PIXI.Container): void {
		if (card instanceof PromotableBaseCardView) {
			card.setPreviousProgressValues();
		}
	}

	public static tryAnimateCardProgress(card: PIXI.Container): void {
		if (card instanceof PromotableBaseCardView) {
			card.animateProgressBar();
		}
	}

	public static tryAnimateCardProgressWithCount(card: PIXI.Container, count: number): void {
		if (card instanceof PromotableBaseCardView) {
			card.animateProgressBarWithCount(count);
		}
	}

	private readonly skillModels?: Map<string, SkillModel>;

	constructor(
		skillModels?: Map<string, SkillModel>,
	) {
		super();

		this.skillModels = skillModels;
	}

	public createRewardCard(
		reward: AbstractReward,
		customQuantity?: string,
	): PIXI.Container {
		const quantity = customQuantity || reward.getQuantityString();
		const rewardType = reward.getType();

		let cardView: PIXI.Container;
		switch (reward.getType()) {
			case RewardTypes.RESOURCE: {
				const resourceId = reward.getKey();
				switch (resourceId) {
					case RewardResourceIdTypes.HARD_MONEY:
					case RewardResourceIdTypes.PRESTIGE_MONEY:
					case RewardResourceIdTypes.SOFT_MONEY: {
						cardView = new ResourceCardView(resourceId as RewardResourceIdTypes, quantity);
						break;
					}
					default:
						throw new Error(`Cannot resolve resourceId='${resourceId}'`);
				}
				break;
			}
			case RewardTypes.CHARACTER: {
				const character = (reward as RewardCharacterCards).getModel();
				const skill = this.skillModels && this.skillModels.get(character.getSkillKey());
				cardView = new CharacterCardView(
					character,
					skill,
				);
				cardView.on(CharacterCardView.EVENT_CLICK, this.onCharacterCardClick, this);
				const quantityMark = new PIXI.extras.BitmapText(`+${quantity}`, { font: '80px wendyOneShadowBold' });
				quantityMark.anchor = 0.5;
				quantityMark.y = 275;
				cardView.addChild(quantityMark);
				break;
			}
			case RewardTypes.UPGRADE: {
				const rewardUpgrade = (reward as RewardUpgradeCards).getModel();
				cardView = new UpgradeCardView(
					rewardUpgrade,
				);
				cardView.on(UpgradeCardView.EVENT_CLICK, this.onUpgradeCardClick, this);
				const quantityMark = new PIXI.extras.BitmapText(`+${quantity}`, { font: '80px wendyOneShadowBold' });
				quantityMark.anchor = 0.5;
				quantityMark.y = 275;
				cardView.addChild(quantityMark);
				break;
			}
			case RewardTypes.TOTEM: {
				const rewardTotem = (reward as RewardTotemCards).getModel();
				cardView = new TotemCardView(
					rewardTotem,
				);
				cardView.on(TotemCardView.EVENT_CLICK, this.onTotemCardClick, this);
				const quantityMark = new PIXI.extras.BitmapText(`+${quantity}`, { font: '80px wendyOneShadowBold' });
				quantityMark.anchor = 0.5;
				quantityMark.y = 275;
				cardView.addChild(quantityMark);
				break;
			}
			case RewardTypes.BOOST: {
				const rewardBoost = (reward as RewardBoost).getModel();
				cardView = new BoostCardView(rewardBoost);

				const quantityMark = new PIXI.extras.BitmapText(`+${quantity}`, { font: '55px wendyOneShadowBold' });
				quantityMark.anchor = 0.5;
				quantityMark.y = 229;
				cardView.addChild(quantityMark);
				break;
			}
			case RewardTypes.TIMESKIP: {
				const rewardTimeskip = (reward as RewardTimeskip).getModel();
				cardView = new TimeskipCardView(rewardTimeskip);

				const quantityMark = new PIXI.extras.BitmapText(`+${quantity}`, { font: '55px wendyOneShadowBold' });
				quantityMark.anchor = 0.5;
				quantityMark.y = 229;
				cardView.addChild(quantityMark);
				break;
			}
			case RewardTypes.VIDEO: {
				const rewardVideo = reward as RewardVideo;
				cardView = new VideoCardView(rewardVideo.getModel());
				cardView.on(VideoCardView.EVENT_CLICK, this.onVideoCardClick, this);
				break;
			}
			default:
				throw new Error(`Cannot resolve reward type='${rewardType}'`);
		}
		return cardView;
	}

	private onVideoCardClick(model: GalleryVideoModel): void {
		this.emit(CardViewFactory.EVENT_CLICK_CARD_VIDEO, model);
	}

	private onCharacterCardClick(data: PromotableClickData): void {
		this.emit(CardViewFactory.EVENT_CLICK_CARD_CHARACTER, data);
	}

	private onUpgradeCardClick(data: PromotableClickData): void {
		this.emit(CardViewFactory.EVENT_CLICK_CARD_UPGRADE, data);
	}

	private onTotemCardClick(data: PromotableClickData): void {
		this.emit(CardViewFactory.EVENT_CLICK_CARD_TOTEM, data);
	}
}
