/* eslint-disable no-console */
import { BusinessModel } from '@models/BusinessModel';
import { CheatModel } from '@models/CheatModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowBusinessesViewController implements CheatWindowViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly cheatModel: CheatModel;
	private readonly businessModels: Map<string, BusinessModel>;

	constructor(
		networkRequestSender: NetworkRequestSender,
		cheatModel: CheatModel,
		businessModels: Map<string, BusinessModel>,
	) {
		this.networkRequestSender = networkRequestSender;
		this.cheatModel = cheatModel;
		this.businessModels = businessModels;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_BUSINESS_GENERATE_CLICK, this.onBusinessGenerateClick, this);
		view.on(CheatWindowView.EVENT_BUSINESS_GENERATE_CLICK_ALL, this.onBusinessGenerateClickAll, this);
		view.on(CheatWindowView.EVENT_BUSINESS_ACQUIRE_ALL, this.onBusinessAcquireAll, this);
	}

	private onBusinessGenerateClick(key: string): void {
		const currentlyEnabled = this.cheatModel.isGenerateBusinessClickEnabled(key);
		this.cheatModel.setGenerateBusinessClickEnabled(key, !currentlyEnabled);
	}

	private onBusinessGenerateClickAll(): void {
		const businesses = Array
			.from(this.businessModels.values())
			.filter(m => m.isAvailable() && !m.isAutomated());
		businesses.forEach(business => {
			const currentlyEnabled = this.cheatModel.isGenerateBusinessClickEnabled(business.getKey());
			this.cheatModel.setGenerateBusinessClickEnabled(business.getKey(), !currentlyEnabled);
		});
	}

	private onBusinessAcquireAll(): void {
		this.businessModels.forEach(m => m.acquire());
		console.log('Acquiring businesses server...');
		this.networkRequestSender.sendCheatOpenAll('businesses').then(() => {
			console.log('Acquiring businesses server OK...');
		});
	}
}
