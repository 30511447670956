import { LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';

export class NewCustomersMutiplierModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_REWARD_ADDED: symbol = Symbol();

	private readonly businessKey: string;

	private lootboxData: LootboxNewCustomerMultiplierConfig[];

	constructor(businessKey: string) {
		super();

		this.businessKey = businessKey;
		this.lootboxData = [];
	}

	public setFromSaveData(lootboxData: LootboxNewCustomerMultiplierConfig[]): void {
		this.lootboxData = lootboxData;
	}

	public addLootboxData(data: LootboxNewCustomerMultiplierConfig): void {
		this.lootboxData.push(data);

		this.emit(NewCustomersMutiplierModel.EVENT_REWARD_ADDED);
	}

	public getLootboxData(): LootboxNewCustomerMultiplierConfig[] {
		return this.lootboxData;
	}

	public resetModel(): void {
		this.lootboxData = [];
	}

	public getRewardPrestigeAmount(): number {
		const rewardDescriptions = this.lootboxData
			.map(lootbox => Object.keys(lootbox.rewards).map(key => lootbox.rewards[key]))
			.flat()
			.map(rewardDesc => Number(rewardDesc.reward_qty));

		return rewardDescriptions.length > 0
			? rewardDescriptions.reduce((a, b) => a + b)
			: 0;
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public hasLootboxData(): boolean {
		return this.lootboxData.length > 0;
	}
}
