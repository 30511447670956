import { ModelWriterCharacter } from '@src/modelWriters/ModelWriterCharacter';
import { AbstractModelInstance } from '@main/modelInstance/AbstractModelInstance';
import { ModelWriterUpgrade } from '@src/modelWriters/ModelWriterUpgrade';
import { ModelWriterSkill } from '@src/modelWriters/ModelWriterSkill';
import { ModelWriterBoost } from '@src/modelWriters/ModelWriterBoost';
import { BankTicker } from '@src/tickers/BankTicker';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SkillsTicker } from '@src/tickers/SkillsTicker';
import { BoostsTicker } from '@src/tickers/BoostsTicker';
import { BusinessesTicker } from '@src/tickers/BusinessesTicker';
import { SummonTicker } from '@src/tickers/SummonTicker';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import {
	BankOfferWindowUnlockTypeCollectionWindowClosedModel,
	BankOfferWindowUnlockTypeBankWindowClosedModel,
} from '@models/bank/BankOfferWindowUnlockTypeModels';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import {
	BusinessAcquiredUIViewControllerPool,
	BusinessOpenToBuyUIViewControllerPool,
	BusinessManageUIViewControllerPool,
} from '@src/viewControllers/BusinessUIViewControllerPools';
import { SkillBaseViewControllerPool } from '@src/viewControllers/SkillBaseViewControllerPool';
import { ShowQuestTargetHandler } from '@src/viewControllers/showQuestTarget/ShowQuestTargetHandler';
import { BusinessBaseZoomRequestHandler } from '@src/viewControllers/BusinessBaseZoomRequestHandler';
import { TutorialStepWindowViewTargetBaseDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewTargetBaseDispatcher';
import { TutorialStepWindowViewController } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewController';
import { SummonWindowViewController } from '@src/viewControllers/SummonWindowViewController';
import { CharacterProfileWindowViewController } from '@src/viewControllers/CharacterProfileWindowViewController';
import { UpgradePromoteWindowViewController } from '@src/viewControllers/UpgradePromoteWindowViewController';
import { ButtonMaxCustomersViewController } from '@src/viewControllers/ButtonMaxCustomersViewController';
import { RewardResultWindowViewController } from '@src/viewControllers/RewardResultWindowViewController';
import { SupportWindowViewController } from '@src/viewControllers/SupportWindowViewController';
import { BankWindowViewController } from '@src/viewControllers/BankWindowViewController';
import { CheatModel } from '@models/CheatModel';
import { GameProfileViewController } from '@src/viewControllers/GameProfileViewController';
import { GameProfileModel } from '@models/GameProfileModel';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { BackToGameWindowViewController } from '@src/viewControllers/BackToGameWindowViewController';
import { BackToGameRewardLoader } from '@src/loaders/BackToGameRewardLoader';
import { PrepartyStartCharacterTicker } from '@src/tickers/PrepartyStarCharcterTicker';
import { PrepartyStartCharacterViewController } from '@src/viewControllers/PrepartyStartCharacterViewController';
import { ModelWriterFarewellBoost } from '@src/modelWriters/ModelWriterFarewellBoost';
import { FarewellPartyWindowViewController } from '@src/viewControllers/FarewellPartyWindowViewController';
import { CheatWindowResourcesViewController } from '@src/viewControllers/cheat/CheatWindowResourcesViewController';
import { CheatWindowBusinessesViewController } from '@src/viewControllers/cheat/CheatWindowBusinessesViewController';
import { CheatWindowUpgradesViewController } from '@src/viewControllers/cheat/CheatWindowUpgradesViewController';
import { CheatWindowBankViewController } from '@src/viewControllers/cheat/CheatWindowBankViewContoller';
import { CheatWindowBoostsViewController } from '@src/viewControllers/cheat/CheatWindowBoostsViewController';
import { CheatWindowMediaViewController } from '@src/viewControllers/cheat/CheatWindowMediaViewController';
import { CheatWindowPoupsViewController } from '@src/viewControllers/cheat/CheatWindowPopupsViewController';
import { CheatWindowSkillsViewController } from '@src/viewControllers/cheat/CheatWindowSkillsViewController';
import { CheatWindowSystemViewController } from '@src/viewControllers/cheat/CheatWindowSystemViewController';
import { CheatWindowTotemsViewController } from '@src/viewControllers/cheat/CheatWindowTotemsViewController';
import { CheatWindowCharactersViewController } from '@src/viewControllers/cheat/CheatWindowCharactersViewController';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { GoToEventLevelWindowEmitter } from '@src/emitters/GoToEventLevelWindowEmitter';
import { CollectionCardsViewController } from '@src/viewControllers/CollectionCardsViewController';
import { EmittersController } from '@src/viewControllers/mainUI/EmittersController';
import { PrepartyWindowViewController } from '@src/viewControllers/PrepartyWindowViewController';
import { ShowHintDataResolver } from '@views/ui/main/ShowHintDataResolver';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '../windows/WindowBaseFactory';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { NutakuTransactionCreator } from '@src/network/transactions/NutakuTransactionCreator';
import { BaseTransactionCreator } from '@src/network/transactions/BaseTransactionCreator';
import { TransactionSaveData } from '@src/types/SaveTypes';
import { NutakuBankWindowViewController } from '@src/viewControllers/NutakuBankWindowViewController';
import { WindowSpawnerTransactionCreator } from '@src/network/transactions/WindowSpawnerTransactionCreator';
import { DeviceUtils } from '@src/utils/DeviceUtils';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { NewsEmitter } from '@src/emitters/NewsEmitter';
import { ConfirmWindowTransactionCreator } from '@src/network/transactions/ConfirmWindowTransactionCreator';
import { DailyRewardEmitter } from '@src/emitters/DailyRewardEmitter';
import { DailyRewardLoader } from '@src/loaders/DailyRewardLoader';
import { AssetUrlsLoader } from '@src/loaders/AssetUrlsLoader';
import { MaintenanceLoader } from '@src/loaders/MaintenanceLoader';
import { MaintenanceTicker } from '@src/tickers/MaintenanceTicker';

export abstract class BehavioursBaseFactory<T extends AbstractModelInstance> {
	protected readonly hintResolver: ShowHintDataResolver;

	constructor(
		protected readonly modelInstance: T,
		protected readonly windowViewSystem: WindowViewSystem,
		protected readonly windowViewFactory: WindowViewBaseFactory,
		protected readonly hintSpawner: ShowHintSpawner,
		protected readonly networkRequestSender: NetworkRequestSender,
		protected readonly cheatModel: CheatModel,
		protected readonly gameProfileModel: GameProfileModel,
		protected readonly serverTimeModel: ServerTimeModel,
		protected readonly constantsConfig: ConstantsConfig,
		protected readonly savesConfig: SavesConfig,
		protected readonly bankPaymentSaveModel: BankPaymentSaveModel,
		protected readonly transactionsSaveData: TransactionSaveData[],
		protected readonly businessesLastUpdate: Map<string, number>,
		protected readonly farewellPartyEverStarted: boolean,
		protected readonly isNutaku: boolean,
		protected readonly assetUrlsLoader: AssetUrlsLoader,
	) {
		this.hintResolver = new ShowHintDataResolver(
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getSkillModels(),
			this.modelInstance.getTotemModels(),
		);
	}

	public abstract createBankLoader(transactionCreator: TransactionCreator): BankLoader;

	public createModelWriterCharacter(): ModelWriterCharacter {
		return new ModelWriterCharacter(
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getSkillModels(),
		);
	}

	public createModelWriterUpgrade(): ModelWriterUpgrade {
		return new ModelWriterUpgrade(
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getLevelModel(),
		);
	}

	public createModelWriterSkill(): ModelWriterSkill {
		return new ModelWriterSkill(
			this.modelInstance.getSkillModels(),
			this.modelInstance.getBusinessModels(),
		);
	}

	public createModelWriterBoost(): ModelWriterBoost {
		return new ModelWriterBoost(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getBoostModels(),
		);
	}

	public createModelWriterFarewellBoost(): ModelWriterFarewellBoost {
		return new ModelWriterFarewellBoost(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getFarewellBoostModel(),
		);
	}

	public createBankTicker(): BankTicker {
		return new BankTicker(
			this.modelInstance.getBankModel(),
			this.serverTimeModel,
		);
	}

	public createSkillsTicker(): SkillsTicker {
		return new SkillsTicker(
			this.modelInstance.getSkillModels(),
			this.serverTimeModel,
		);
	}

	public createPrepartyCharacterTicker(): PrepartyStartCharacterTicker {
		return new PrepartyStartCharacterTicker(
			this.modelInstance.getPrepartyStartCharacterModel(),
			this.constantsConfig.getFarewellPartyEnabled(),
		);
	}

	public createBoostsTicker(): BoostsTicker {
		return new BoostsTicker(
			this.modelInstance.getBoostModels(),
			this.modelInstance.getFarewellBoostModel(),
		);
	}

	public createBusinessesTicker(): BusinessesTicker {
		return new BusinessesTicker(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getLevelModel(),
		);
	}

	public createSummonTicker(): SummonTicker {
		return new SummonTicker(
			this.serverTimeModel,
			this.modelInstance.getSummonModelsPool(),
		);
	}

	public createBackToGameRewardLoader(): BackToGameRewardLoader {
		return new BackToGameRewardLoader(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getBackToGameRewardModel(),
			this.businessesLastUpdate,
		);
	}

	public createBankOfferWindowsEmitter(): BankOfferWindowsEmitter {
		return new BankOfferWindowsEmitter(
			this.modelInstance.getBankModel(),
			[
				new BankOfferWindowUnlockTypeCollectionWindowClosedModel(this.windowViewSystem),
				new BankOfferWindowUnlockTypeBankWindowClosedModel(this.windowViewSystem),
			],
		);
	}

	public createBusinessOpenToBuyViewUIControllerPool(): BusinessOpenToBuyUIViewControllerPool {
		return new BusinessOpenToBuyUIViewControllerPool(
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getSoftMoneyModel(),
		);
	}

	public createBusinessManageUIViewControllerPool(): BusinessManageUIViewControllerPool {
		return new BusinessManageUIViewControllerPool(
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getSkillModels(),
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getBusinessModels(),
			this.hintSpawner,
			this.hintResolver,
			this.windowViewSystem,
			this.windowViewFactory,
		);
	}

	public createSkillBaseViewControllerPool(): SkillBaseViewControllerPool {
		return new SkillBaseViewControllerPool(
			this.modelInstance.getSkillModels(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createBusinessZoomRequestHandler(): BusinessBaseZoomRequestHandler {
		return new BusinessBaseZoomRequestHandler();
	}

	// eslint-disable-next-line class-methods-use-this
	public createTutorialStepsViewTargetBaseDispatcher(): TutorialStepWindowViewTargetBaseDispatcher {
		return new TutorialStepWindowViewTargetBaseDispatcher(
			this.windowViewSystem,
			this.hintSpawner,
			this.modelInstance.getSummonModelsPool(),
		);
	}

	public createTutorialStepsWindowViewController(): TutorialStepWindowViewController {
		return new TutorialStepWindowViewController(
			this.modelInstance.getTutorialStepModels(),
			this.modelInstance.getCharacterQuickPhraseEmitter(),
			this.modelInstance.getFarewellPartyModels(),
		);
	}

	public createCharacterProfileWindowViewController(): CharacterProfileWindowViewController {
		return new CharacterProfileWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getSummonModelsPool(),
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	public createUpgradePromoteWindowViewController(): UpgradePromoteWindowViewController {
		return new UpgradePromoteWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getSummonModelsPool(),
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createButtonMaxCustomersViewController(): ButtonMaxCustomersViewController {
		return new ButtonMaxCustomersViewController();
	}

	// eslint-disable-next-line class-methods-use-this
	public craeteRewardResultWindowViewController(): RewardResultWindowViewController {
		return new RewardResultWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
		);
	}

	public createSupportWindowViewController(): SupportWindowViewController {
		return new SupportWindowViewController(
			this.networkRequestSender,
		);
	}

	public createSummonWindowViewController(
		summonLoader: SummonLoader,
	): SummonWindowViewController {
		return new SummonWindowViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.networkRequestSender,
			this.modelInstance.getRewardFactory(),
			this.modelInstance.getHardMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.serverTimeModel,
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getSummonSavesModel(),
			summonLoader,
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	public createCollectionCardsViewController(): CollectionCardsViewController {
		return new CollectionCardsViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getGalleryVideoModels(),
			this.modelInstance.getSkillModels(),
		);
	}

	public createEmittersController(
		bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		newsEmitter?: NewsEmitter,
		goToEventLevelWindowEmitter?: GoToEventLevelWindowEmitter,
		dailyRewardEmitter?: DailyRewardEmitter,
	): EmittersController {
		return new EmittersController(
			this.cheatModel,
			bankOfferWindowsEmitter,
			newsEmitter,
			goToEventLevelWindowEmitter,
			dailyRewardEmitter,
		);
	}

	public createPrepartyCharacterViewController(): PrepartyStartCharacterViewController {
		return new PrepartyStartCharacterViewController(
			this.modelInstance.getPrepartyStartCharacterModel(),
			this.windowViewSystem,
			this.windowViewFactory,
		);
	}

	public createPrepartyWindowViewController(
		emittersController: EmittersController,
	): PrepartyWindowViewController {
		return new PrepartyWindowViewController(
			this.modelInstance.getPrepartyStartCharacterModel(),
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getFarewellPartyModels(),
			this.modelInstance.getTotemModels(),
			emittersController,
			this.farewellPartyEverStarted,
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	public createBankWindowViewController(
		bankLoader: BankLoader,
		transactionCreator: TransactionCreator,
	): BankWindowViewController {
		let controller: BankWindowViewController;
		if (this.isNutaku) {
			controller = new NutakuBankWindowViewController(
				this.gameProfileModel,
				this.modelInstance.getBankModel(),
				this.modelInstance.getBankSavesModel(),
				this.bankPaymentSaveModel,
				bankLoader,
				this.windowViewSystem,
				this.windowViewFactory,
				this.networkRequestSender,
				transactionCreator,
				this.modelInstance.getRewardFactory(),
				this.modelInstance.getHardMoneyModel(),
				this.modelInstance.getSoftMoneyModel(),
				this.modelInstance.getPrestigeMoneyModel(),
				this.modelInstance.getBoostModels(),
				this.modelInstance.getTimeskipModels(),
				this.serverTimeModel,
			);
		} else {
			controller = new BankWindowViewController(
				this.modelInstance.getBankModel(),
				this.modelInstance.getBankSavesModel(),
				this.bankPaymentSaveModel,
				bankLoader,
				this.windowViewSystem,
				this.windowViewFactory,
				this.networkRequestSender,
				transactionCreator,
				this.modelInstance.getRewardFactory(),
				this.modelInstance.getHardMoneyModel(),
				this.modelInstance.getSoftMoneyModel(),
				this.modelInstance.getPrestigeMoneyModel(),
				this.modelInstance.getBoostModels(),
				this.modelInstance.getTimeskipModels(),
				this.serverTimeModel,
			);
		}
		return controller;
	}

	public createBusinessAcquiredUIViewControllerPool(businessesTicker: BusinessesTicker): BusinessAcquiredUIViewControllerPool {
		return new BusinessAcquiredUIViewControllerPool(
			this.windowViewSystem,
			this.windowViewFactory,
			this.networkRequestSender,
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getNewCustomerMultiplierModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getLootboxFactory(),
			businessesTicker,
			this.modelInstance.getSummonModelsPool(),
			this.constantsConfig.getShowQuestTargetDuration(),
			this.modelInstance.getLevelModel(),
		);
	}

	public createShowQuestTargetHandler(): ShowQuestTargetHandler {
		return new ShowQuestTargetHandler(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getBusinessModels(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBankModel(),
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.constantsConfig.getShowQuestTargetDuration(),
		);
	}

	public createGameProfileViewController(): GameProfileViewController {
		return new GameProfileViewController(
			this.windowViewSystem,
			this.windowViewFactory,
			this.modelInstance.getLootboxFactory(),
			this.networkRequestSender,
			this.gameProfileModel,
			this.savesConfig,
			this.constantsConfig.getResendEmailConfirmationCodeTimeout(),
		);
	}

	public createBackToGameWindowViewController(): BackToGameWindowViewController {
		return new BackToGameWindowViewController(
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			this.constantsConfig.getOfflineRewardCost(),
			this.modelInstance.getBackToGameRewardModel(),
		);
	}

	public createFarewellPartyWindowViewController(): FarewellPartyWindowViewController {
		return new FarewellPartyWindowViewController(
			this.networkRequestSender,
			this.modelInstance.getFarewellBoostModel(),
			this.modelInstance.getPrepartyStartCharacterModel(),
			this.serverTimeModel,
			this.farewellPartyEverStarted,
		);
	}

	public createCheatWindowCharactersViewController(): CheatWindowCharactersViewController {
		return new CheatWindowCharactersViewController(
			this.networkRequestSender,
			this.modelInstance.getCharacterModels(),
		);
	}

	public createCheatWindowTotemsViewController(): CheatWindowTotemsViewController {
		return new CheatWindowTotemsViewController(
			this.networkRequestSender,
			this.modelInstance.getTotemModels(),
		);
	}

	public createCheatWindowBankViewContoller(): CheatWindowBankViewController {
		return new CheatWindowBankViewController(
			this.networkRequestSender,
			this.cheatModel,
			this.modelInstance.getBankModel(),
		);
	}

	public createCheatWindowBoostsViewController(): CheatWindowBoostsViewController {
		return new CheatWindowBoostsViewController(
			this.modelInstance.getBoostModels(),
			this.serverTimeModel,
		);
	}

	public createCheatWindowBusinessesViewController(): CheatWindowBusinessesViewController {
		return new CheatWindowBusinessesViewController(
			this.networkRequestSender,
			this.cheatModel,
			this.modelInstance.getBusinessModels(),
		);
	}

	public createCheatWindowMediaViewController(): CheatWindowMediaViewController {
		return new CheatWindowMediaViewController(
			this.modelInstance.getDialogsFactory(),
			this.modelInstance.getGalleryVideoModels(),
		);
	}

	public createCheatWindowPopupsViewController(): CheatWindowPoupsViewController {
		return new CheatWindowPoupsViewController(
			this.cheatModel,
		);
	}

	public createCheatWindowResourcesViewController(): CheatWindowResourcesViewController {
		return new CheatWindowResourcesViewController(
			this.networkRequestSender,
			this.modelInstance.getSoftMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
		);
	}

	public createCheatWindowSkillsViewController(): CheatWindowSkillsViewController {
		return new CheatWindowSkillsViewController(
			this.networkRequestSender,
		);
	}

	public createCheatWindowSystemViewController(
		dailyRewardLoader?: DailyRewardLoader,
	): CheatWindowSystemViewController {
		return new CheatWindowSystemViewController(
			this.networkRequestSender,
			this.cheatModel,
			this.modelInstance.getSummonModelsPool(),
			dailyRewardLoader,
		);
	}

	public createCheatWindowUpgradesViewController(): CheatWindowUpgradesViewController {
		return new CheatWindowUpgradesViewController(
			this.networkRequestSender,
			this.modelInstance.getUpgradeModels(),
		);
	}

	public createTransactionCreator(): TransactionCreator {
		let creator: TransactionCreator;
		if (this.isNutaku) {
			creator = new NutakuTransactionCreator(
				this.networkRequestSender,
				this.assetUrlsLoader,
			);
		} else {
			const browsers = this.constantsConfig.getBrowsersWithAdditionalWindowForPurchase();

			if (browsers.includes(DeviceUtils.getDeviceId())) {
				creator = new WindowSpawnerTransactionCreator(
					this.windowViewSystem,
					this.windowViewFactory,
					this.networkRequestSender,
					this.gameProfileModel,
					this.cheatModel,
					this.constantsConfig.getDefaultPaymentMethod(),
				);
			} else if (DeviceUtils.isAndroidWebView()) {
				creator = new ConfirmWindowTransactionCreator(
					this.windowViewSystem,
					this.windowViewFactory,
					this.networkRequestSender,
					this.gameProfileModel,
					this.cheatModel,
					this.constantsConfig.getDefaultPaymentMethod(),
				);
			} else {
				creator = new BaseTransactionCreator(
					this.windowViewSystem,
					this.windowViewFactory,
					this.networkRequestSender,
					this.gameProfileModel,
					this.cheatModel,
					this.constantsConfig.getDefaultPaymentMethod(),
				);
			}
		}

		return creator;
	}

	public createMaintenanceLoader(): MaintenanceLoader {
		return new MaintenanceLoader(
			this.modelInstance.getMaintenanceModel(),
			this.networkRequestSender,
			this.constantsConfig.getMaintenancePingInterval(),
		);
	}

	public createMaintenanceTicker(): MaintenanceTicker {
		return new MaintenanceTicker(
			this.modelInstance.getMaintenanceModel(),
			this.serverTimeModel,
		);
	}
}
