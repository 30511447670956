import AbstractMoneyModel from '@models/money/AbstractMoneyModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export default class SoftMoneyModel extends AbstractMoneyModel {
	private currentValue: SoftMoneyNumber;

	constructor() {
		super();

		this.currentValue = SoftMoneyNumber.ZERO;
	}

	public add(value: SoftMoneyNumber, originKey?: string): void {
		this.currentValue = this.currentValue.add(value);

		this.emit(SoftMoneyModel.EVENT_VALUE_INCREASED, value, originKey);
		this.emit(SoftMoneyModel.EVENT_VALUE_CHANGED, this.currentValue);
	}

	public subtract(value: SoftMoneyNumber): void {
		this.currentValue = this.currentValue.subtract(value);

		this.emit(SoftMoneyModel.EVENT_VALUE_CHANGED, this.currentValue);
	}

	public setValue(value: SoftMoneyNumber): void {
		this.currentValue = value;
	}

	public getValue(): SoftMoneyNumber {
		return this.currentValue;
	}
}
