import { BusinessModel } from '@models/BusinessModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BusinessOpenToBuyUIView } from '@views/businesses/ui/openToBuy/BusinessOpenToBuyUIView';
import { BusinessOpenToBuyUIViewSetter } from '@interfaces/ViewSetters';
import { BusinessAcquireAction } from '@models/network/actions/businesses/BusinessAcquireAction';
import { BaseAction } from '@models/network/actions/BaseAction';

export class BusinessOpenToBuyUIViewController extends PIXI.utils.EventEmitter implements BusinessOpenToBuyUIViewSetter {
	private readonly model: BusinessModel;
	private readonly softMoneyModel: SoftMoneyModel;

	private view: BusinessOpenToBuyUIView;

	constructor(
		model: BusinessModel,
		softMoneyModel: SoftMoneyModel,
	) {
		super();

		this.model = model;
		this.softMoneyModel = softMoneyModel;
	}

	public setOpenToBuyUIView(view: BusinessOpenToBuyUIView): void {
		this.view = view;
		this.view.on(BusinessOpenToBuyUIView.EVENT_BUTTON_BUY_CLICK, this.onBuyBusinessClicked, this);
	}

	private onBuyBusinessClicked(): void {
		const isEnoughSoftMoneyForBuyBusiness = this.softMoneyModel.getValue().greaterThanOrEqualTo(this.model.getAcquireCost());
		if (isEnoughSoftMoneyForBuyBusiness) {
			this.softMoneyModel.subtract(this.model.getAcquireCost());

			this.model.acquire();

			const acquireAction = new BusinessAcquireAction(
				this.model.getKey(),
				'soft_money',
				this.model.getAcquireCost().toRawString(),
				this.softMoneyModel.getValue().toRawString(),
			);
			this.emit(BaseAction.EVENT_ACTION_CREATED, acquireAction);
		}
	}
}
