import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90016View } from '@views/characters/events/event9/EventCharacter90016View';
import { EventCharacter90017View } from '@views/characters/events/event9/EventCharacter90017View';
import { EventCharacter90018View } from '@views/characters/events/event9/EventCharacter90018View';

export class EventBusiness90006View extends BusinessBaseView {
	constructor() {
		const eventCharacter90016View = new EventCharacter90016View();
		const eventCharacter90017View = new EventCharacter90017View();
		const eventCharacter90018View = new EventCharacter90018View();

		super('business90006', [eventCharacter90016View, eventCharacter90017View, eventCharacter90018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(eventCharacter90016View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter90017View, this.getChildIndex(decor2) + 1);
		this.addChildAt(eventCharacter90018View, this.getChildIndex(decor2) + 1);
	}
}
