import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { SummonResultWindowViewSetter } from '@interfaces/ViewSetters';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeSummonCardsOpenedModel extends TutorialStepUnlockTypeBaseModel implements SummonResultWindowViewSetter {
	private readonly summonKey: string;

	private view: SummonResultWindowView;

	constructor(key: string, summonKey: string) {
		super(key, TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_OPENED);

		this.summonKey = summonKey;
	}

	public setSummonResultWindowView(view: SummonResultWindowView): void {
		this.view = view;
		if (view.getKey() === this.summonKey) {
			this.view.once(SummonResultWindowView.EVENT_ALL_CARDS_OPENED, this.onUnlocked, this);
		}
	}

	public destroy(): void {
		if (this.view && this.view.listeners(SummonResultWindowView.EVENT_ALL_CARDS_OPENED).includes(this.onUnlocked)) {
			this.view.off(SummonResultWindowView.EVENT_ALL_CARDS_OPENED, this.onUnlocked, this);
		}
		super.destroy();
	}
}
