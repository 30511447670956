import { GameProfileModel } from '@models/GameProfileModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';

export class AccountLifetimeTicker {
	private static readonly ACCOUNT_LIFETIME_UPDATE_RATE_HOURS: number = 12;

	private tickerHandle: any;
	private createdOn: number;

	constructor(
		private readonly serverTime: ServerTimeModel,
		private readonly gameProfileModel: GameProfileModel,
	) {}

	public init(
		createdOn: number,
	): void {
		this.createdOn = createdOn;

		this.stopTickerIfAny();
		this.tickerHandle = window.setInterval(
			this.onTickerUpdate.bind(this),
			AccountLifetimeTicker.ACCOUNT_LIFETIME_UPDATE_RATE_HOURS * 3600000,
		);
	}

	private onTickerUpdate(): void {
		this.gameProfileModel.setAccountLifetime(
			this.serverTime.getCalculatedISOTime() - this.createdOn,
		);
	}

	private stopTickerIfAny(): void {
		if (this.tickerHandle) {
			window.clearInterval(this.tickerHandle);
			this.tickerHandle = null;
		}
	}
}
