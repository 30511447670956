import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepBaseModel } from '../TutorialStepBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeTutorialStepModel extends TutorialStepUnlockTypeBaseModel {
	constructor(key: string, targetTutorialStep: TutorialStepBaseModel) {
		super(key, TutorialStepUnlockTypes.TUTORIAL_STEP);
		targetTutorialStep.once(TutorialStepBaseModel.EVENT_COMPLETED, this.onTargetTutorialStepCompleted, this);
	}

	private onTargetTutorialStepCompleted(): void {
		this.onUnlocked();
	}
}
