import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40001View } from '@views/characters/events/event4/EventCharacter40001View';
import { EventCharacter40002View } from '@views/characters/events/event4/EventCharacter40002View';
import { EventCharacter40003View } from '@views/characters/events/event4/EventCharacter40003View';

export class EventBusiness40001View extends BusinessBaseView {
	constructor() {
		const eventCharacter40001View = new EventCharacter40001View();
		const eventCharacter40002View = new EventCharacter40002View();
		const eventCharacter40003View = new EventCharacter40003View();

		super('business40001', [eventCharacter40001View, eventCharacter40002View, eventCharacter40003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter40001View as PIXI.DisplayObject,
			eventCharacter40002View,
			eventCharacter40003View,
		);
	}
}
