import { CollectionBaseView } from '@views/windows/collection/CollectionBaseView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { AbstractSummonItemTypeView } from './AbstractSummonItemTypeView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { HintTypes, HintDataPosition } from '@src/types/HintTypes';
import { SummonItemView } from './SummonItemView';

export class SummonCollectionView extends CollectionBaseView {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();
	public static readonly EVENT_INITED = Symbol();

	private static readonly CONTENT_CONTAINER_Y = 98;

	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly localizationStorage: LocalizationStorage;

	private readonly cardMiniViewFactory: CardMiniViewFactory;

	private summonModelsPool: SummonModelsPool;
	private summonViewKeyMap: Map<string, AbstractSummonItemTypeView>;
	private flyTextLabel: FlyBitmapText;

	private moneyPanelsButtonPlusInvisible?: boolean;

	constructor(
		summonModelsPool: SummonModelsPool,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		timeskipModels: Map<string, TimeskipModel>,
		moneyPanelsButtonPlusInvisible?: boolean,
	) {
		super({ begin: 0, end: 150 });

		this.prestigeMoneyModel = prestigeMoneyModel;
		this.hardMoneyModel = hardMoneyModel;
		this.localizationStorage = LocalizationStorage.getInstance();
		this.moneyPanelsButtonPlusInvisible = moneyPanelsButtonPlusInvisible;

		this.hardMoneyModel = hardMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;

		this.cardMiniViewFactory = new CardMiniViewFactory(
			characterModels,
			upgradeModels,
			totemModels,
			boostModels,
			timeskipModels,
		);

		this.summonModelsPool = summonModelsPool;
		this.summonModelsPool.on(SummonModelsPool.EVENT_MODELS_UPDATED, this.init, this);
		this.scrollView.position.set(576, SummonCollectionView.CONTENT_CONTAINER_Y);

		this.summonViewKeyMap = new Map();

		const hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
		hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(SummonCollectionView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, data);
			},
		);
		hardMoneyPanel.position.set(1630, 40);

		const prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel);
		prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_SHOW_HINT,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataPosition = { arrowPosLocal, origin };
				this.emit(SummonCollectionView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, data);
			},
		);
		prestigeMoneyPanel.position.set(1370, 40);

		if (moneyPanelsButtonPlusInvisible) {
			prestigeMoneyPanel.setButtonPlusVisible(false);
			hardMoneyPanel.setButtonPlusVisible(false);
		}

		prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(SummonCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);
		hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(SummonCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);

		this.addChild(
			hardMoneyPanel,
			prestigeMoneyPanel as PIXI.DisplayObject,
		);

		this.init();
	}

	private init(): void {
		this.summonViewKeyMap.clear();
		this.contentContainer.removeChildren();

		let i = 0;
		this.summonModelsPool.getModelsList().forEach((model) => {
			const view = new SummonItemView(
				model,
				this.hardMoneyModel,
				this.prestigeMoneyModel,
				this.cardMiniViewFactory,
				model.getViewType(),
			);
			view.on(
				AbstractSummonItemTypeView.EVENT_BUTTON_BUY_CLICK,
				(count) => this.onSomeButtonBuyClick(model.getKey(), count),
			);
			view.y = i * 446;
			this.contentContainer.addChild(view);

			i += 1;

			this.summonViewKeyMap.set(model.getKey(), view);
		});
		this.scrollView.updateBounds();
		this.emit(SummonCollectionView.EVENT_INITED);
	}

	public scrollTo(key: string): void {
		const card = this.summonViewKeyMap.get(key);
		this.scrollView.jumpTo(-card.y);
	}

	public getButtonSummonContainer(key: string): PIXI.Container {
		return this.summonViewKeyMap.get(key).getBuyButton();
	}

	private onSomeButtonBuyClick(key: string, count: number): void {
		this.emit(SummonCollectionView.EVENT_BUTTON_BUY_CLICK, key, count);
	}

	public showFlyTextNotEnoughMoney(key: string, amount: number): void {
		if (!this.flyTextLabel) {
			const view = this.summonViewKeyMap.get(key);
			const button = view.getBuyButtonAmount(amount);
			const stringLabel = this.localizationStorage.getLocalizedString('#summon_fly_text_not_enough_money');
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '24px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(button.x, button.y - 40);
			view.addChild(this.flyTextLabel);
		}
	}

	public enableButtons(value: boolean): void {
		this.summonViewKeyMap.forEach(x => x.enableButtons(value));
	}

	public getMoneyPanelsButtonPlusInvisible(): boolean {
		return this.moneyPanelsButtonPlusInvisible;
	}

	public showLoadingLabel(modelKey: string): void {
		this.summonViewKeyMap.get(modelKey).showLoadingLabel();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.summonModelsPool.off(SummonModelsPool.EVENT_MODELS_UPDATED, this.init, this);
		super.destroy(options);
	}
}
