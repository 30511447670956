import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10007View } from '@views/characters/events/event1/EventCharacter10007View';
import { EventCharacter10008View } from '@views/characters/events/event1/EventCharacter10008View';
import { EventCharacter10009View } from '@views/characters/events/event1/EventCharacter10009View';

export class EventBusiness10003View extends BusinessBaseView {
	constructor() {
		const character10008 = new EventCharacter10008View();
		const character10007 = new EventCharacter10007View();
		const character10009 = new EventCharacter10009View();
		super('business10003', [character10007, character10008, character10009]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor3']).setTransform(-241, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor2']).setTransform(233, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor5']).setTransform(291, 14, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b3_decor1']).setTransform(11, 82, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			character10007 as PIXI.DisplayObject,
			character10008,
			character10009,
		);
	}
}
