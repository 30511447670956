import { BaseBoostModel } from './BaseBoostModel';
import { BonusTypes } from '@src/types/BonusTypes';
import { CalculationType } from '@src/types/CalculationType';

export class FarewellBoostModel extends BaseBoostModel {
	private static readonly DEFAULT_KEY: string = 'FAREWELL_BOOST_MODEL';

	constructor() {
		super();
		this.key = FarewellBoostModel.DEFAULT_KEY;
		this.bonusType = BonusTypes.MULTIPLIER_INCOME;
		this.calculationType = CalculationType.MULTIPLICATION;
	}

	public init(value: number, time: number, active: boolean): void {
		this.bonusValue = value;
		this.time = time;
		this.activated = active;
	}

	public activate(): void {
		super.onActivate();
	}
}
