import * as TWEEN from '@tweenjs/tween.js';
import { SoundController } from '@src/main/SoundController';
import { AssetsStorage } from '@main/AssetsStorage';

export enum ButtonBackgroundType {
	GREEN = 'button_green',
	YELLOW = 'button_gold',
	BLUE = 'button_blue',
	RED = 'button_red',
	BLACK = 'button_black',
}

export class ButtonBaseView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	public static createButtonBackground(
		type: ButtonBackgroundType,
		width: number,
		height: number,
	): PIXI.Container {
		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[type], 11, 11, 11, 11);
		background.width = width;
		background.height = height;
		background.pivot.set(width / 2, height / 2);
		return background;
	}

	protected buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container | PIXI.Graphics;

	private tweenPointerUp: TWEEN.Tween;

	private isPointerOver: boolean;
	private readonly pointerUpScale: number;
	private readonly pointerDownScale: number;

	private readonly withSound: boolean;

	protected readonly buttonBaseTweenGroup: TWEEN.Group;
	private readonly buttonBaseTweenTicker: PIXI.ticker.Ticker;

	constructor(
		buttonBg: PIXI.Sprite | PIXI.mesh.NineSlicePlane | PIXI.Container,
		pointerUpScale: number = 1.07,
		pointerDownScale: number = 0.95,
		withSound: boolean = true,
	) {
		super();

		this.pointerUpScale = pointerUpScale;
		this.pointerDownScale = pointerDownScale;
		this.withSound = withSound;

		this.buttonBg = buttonBg;
		this.addChild(buttonBg);

		this.interactive = true;
		this.on('pointerout', this.onPointerOut, this);
		this.on('pointerover', this.onPointerOver, this);
		this.on('pointerdown', this.onPointerDown, this);
		this.on('pointerup', this.onPointerUp, this);
		this.on('pointertap', this.onPointerTap, this);

		this.isPointerOver = false;

		this.buttonBaseTweenGroup = new TWEEN.Group();

		this.buttonBaseTweenTicker = PIXI.ticker.shared;
		this.buttonBaseTweenTicker.add(this.updateButtonBaseTweenGroup, this);
	}

	public setEnabled(enable: boolean): void {
		this.interactive = enable;
	}

	protected onPointerDown(e: PIXI.interaction.InteractionEvent): void {
		this.interruptPointerUpAnimation();

		this.getScaleAnimation(
			this.pointerDownScale,
			this.pointerDownScale,
			TWEEN.Easing.Quadratic.Out,
		).start();
		e.stopPropagation();
	}

	protected onPointerUp(): void {
		this.tweenPointerUp = this.getScaleAnimation(
			this.pointerUpScale,
			this.pointerUpScale,
			TWEEN.Easing.Quadratic.In,
		);
		const bounceBack = this.getScaleAnimation(1, 1, TWEEN.Easing.Quadratic.In, 70);

		this.tweenPointerUp
			.chain(bounceBack)
			.onComplete(() => {
				this.tweenPointerUp = null;
				if (this.isPointerOver && this.interactive) {
					this.onPointerOver();
				}
			})
			.start();
	}

	protected onPointerOut(): void {
		this.isPointerOver = false;
		if (!this.tweenPointerUp) {
			this.getScaleAnimation(1, 1, TWEEN.Easing.Quadratic.In).start();
		}
	}

	protected onPointerOver(): void {
		if (!this.isPointerOver && this.withSound) {
			SoundController.getInstance().playButtonPointerOver();
		}

		this.isPointerOver = true;
		this.getScaleAnimation(
			this.pointerUpScale,
			this.pointerUpScale,
			TWEEN.Easing.Quadratic.In,
		).start();
	}

	protected onPointerTap(): void {
		if (this.withSound) {
			SoundController.getInstance().playButtonClick();
		}

		this.emit(ButtonBaseView.EVENT_CLICK, this);
	}

	private updateButtonBaseTweenGroup(): void {
		this.buttonBaseTweenGroup.update(this.buttonBaseTweenTicker.lastTime);
	}

	private getScaleAnimation(targetX: number, targetY: number, targetEase: (k: number) => any, duration: number = 50): TWEEN.Tween {
		return new TWEEN.Tween(this.scale, this.buttonBaseTweenGroup)
			.to({ x: targetX, y: targetY }, duration)
			.easing(targetEase);
	}

	protected interruptPointerUpAnimation(): void {
		if (this.tweenPointerUp) {
			this.tweenPointerUp.stop();
			this.tweenPointerUp = null;
		}
	}

	public destroy(): void {
		this.buttonBaseTweenTicker.remove(this.updateButtonBaseTweenGroup, this);
		this.buttonBaseTweenGroup.removeAll();

		super.destroy({ children: true });
	}
}
