import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export class PrepartyFuckPowerView extends PIXI.Container {
	private readonly textFuckpower: MultiColoredTextField;

	constructor(
	) {
		super();

		const bgMask = new PIXI.Graphics();
		bgMask.beginFill(0xFFFFFF);
		bgMask.drawPolygon([
			new PIXI.Point(-182, -25),
			new PIXI.Point(202, -25),
			new PIXI.Point(194, 25),
			new PIXI.Point(-190, 25),
		]);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
		bg.width = 420;
		bg.height = 52;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.mask = bgMask;

		this.textFuckpower = new MultiColoredTextField(
			{ font: '32px wendyOneShadowBold', align: 'center' },
			bg.width - 20,
		);
		this.textFuckpower.anchor = 0.5;
		this.textFuckpower.x = 28;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['fuck_power_icon']);
		icon.position.set(-140, -9);

		this.addChild(
			bg,
			bgMask,
			icon,
			this.textFuckpower as PIXI.DisplayObject,
		);
	}

	public setText(text: string): void {
		this.textFuckpower.text = text;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		super.destroy(options);
	}
}
