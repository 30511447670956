import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import { Emitter } from 'pixi-particles';
import { FarewellPartyEffectLightView } from '@views/windows/farewellParty/FarewellPartyEffectLightView';

export class FarewellPartyLootboxView extends PIXI.Container {
	private static glow1(): any {
		return {
			alpha: {
				start: 1,
				end: 0.46,
			},
			scale: {
				start: 0.5,
				end: 0.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe88c',
				end: '#ffc70f',
			},
			speed: {
				start: 200,
				end: 50,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: -50,
				y: 10,
			},
			maxSpeed: 200,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.0009,
			emitterLifetime: 0.01,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 10,
			},
		};
	}

	private static glow2(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.001,
				end: 0.8,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#fff7ad',
				end: '#ffd382',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: 60,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.2,
			maxParticles: 2,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	private readonly rewardsCount: PIXI.extras.BitmapText;

	private tweenPulse: TWEEN.Tween;

	private burst1Emitter: Emitter;
	private burst2Emitter: Emitter;

	constructor() {
		super();

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['arrows_boost_icon']);
		icon.position.set(-102, -9);
		// icon.scale.set(0.65);

		this.rewardsCount = new SizeableBitmapText('0', 138, { font: '72px wendyOneGold', align: 'center' });
		this.rewardsCount.anchor = 0.5;

		const lightEffect = new FarewellPartyEffectLightView();
		lightEffect.scale.set(0.25);

		this.addChild(
			icon,
			this.rewardsCount as PIXI.DisplayObject,
		);
	}

	public playUpdateAnimation(): void {
		this.playPulseAnimation();
		this.playBurstAnimation();
	}

	private playPulseAnimation(): void {
		if (!this.tweenPulse) {
			this.tweenPulse = new TWEEN.Tween(this.scale)
				.to({ x: this.scale.x + 0.2, y: this.scale.y + 0.2 }, 100)
				.repeat(1)
				.yoyo(true)
				.onComplete(() => {
					this.tweenPulse = null;
				})
				.start();
		}
	}

	private playBurstAnimation(): void {
		this.burst1Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow1']).texture,
			],
			FarewellPartyLootboxView.glow1(),
		);
		this.burst1Emitter.autoUpdate = true;
		this.burst1Emitter.playOnceAndDestroy(() => {
			this.burst1Emitter = null;
		});

		this.burst2Emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_glow_2']).texture,
			],
			FarewellPartyLootboxView.glow2(),
		);
		this.burst2Emitter.autoUpdate = true;
		this.burst2Emitter.playOnceAndDestroy(() => {
			this.burst2Emitter = null;
		});
	}

	public setRewardsCount(value: number): void {
		this.rewardsCount.text = `x${value.toString()}`;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenPulse) {
			this.tweenPulse.stop();
		}
		if (this.burst1Emitter) {
			this.burst1Emitter.destroy();
		}
		if (this.burst2Emitter) {
			this.burst2Emitter.destroy();
		}
		super.destroy(options);
	}
}
