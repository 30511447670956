import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character17View extends CharacterMainBaseView {
	constructor() {
		super('character17');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-245, 5);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-250, -65),
			bgPosition: new PIXI.Point(-100, -70),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
