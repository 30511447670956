import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100016View } from '@views/characters/events/event10/EventCharacter100016View';
import { EventCharacter100017View } from '@views/characters/events/event10/EventCharacter100017View';
import { EventCharacter100018View } from '@views/characters/events/event10/EventCharacter100018View';

export class EventBusiness100006View extends BusinessBaseView {
	constructor() {
		const eventCharacter100016View = new EventCharacter100016View();
		const eventCharacter100017View = new EventCharacter100017View();
		const eventCharacter100018View = new EventCharacter100018View();

		super('business100006', [eventCharacter100016View, eventCharacter100017View, eventCharacter100018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter100016View,
			eventCharacter100017View as PIXI.DisplayObject,
			eventCharacter100018View,
		);
	}
}
