import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter53007View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53007View';
import { EventCharacter53009View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53009View';
import { EventCharacter53008View } from '@views/characters/events/eventLevelWeekDay3/EventCharacter53008View';

export class EventBusiness53003View extends BusinessBaseView {
	constructor() {
		const eventCharacter53007View = new EventCharacter53007View();
		const eventCharacter53008View = new EventCharacter53008View();
		const eventCharacter53009View = new EventCharacter53009View();

		super('business53003', [eventCharacter53007View, eventCharacter53008View, eventCharacter53009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter53007View as PIXI.DisplayObject,
			eventCharacter53008View,
			eventCharacter53009View,
		);
	}
}
