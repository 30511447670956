import { BusinessModel } from '@models/BusinessModel';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { BusinessAcquiredUIViewSetter, GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { UpgradeModel } from '@models/UpgradeModel';
import { BusinessIncomeAction } from '@models/network/actions/businesses/BusinessIncomeAction';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BusinessBuyCustomerAction } from '@models/network/actions/businesses/BusinessBuyCustomerAction';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import * as TWEEN from '@tweenjs/tween.js';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { BusinessNewCustomerMultiplierAction } from '@models/network/actions/businesses/BusinessNewCustomerMultiplierAction';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { CollectionWindowTabs, CollectionWindowOpenConfig, CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { SkillModel } from '@models/skills/SkillModel';
import { CharacterModel } from '@models/CharacterModel';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { ModelHelper } from '@models/ModelHelper';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { BusinessesTicker } from '@src/tickers/BusinessesTicker';
import { LevelBaseModel } from '@models/level/LevelBaseModel';

export class BusinessAcquiredUIViewController
	extends PIXI.utils.EventEmitter implements BusinessAcquiredUIViewSetter, GameUIBaseViewSetter {
	private gameUIView: GameUIBaseView;
	private view: BusinessAcquiredUIView;

	private reduceTimeIncomeClickCount: number;
	private reduceTimeIncomeClickCountCharacter: number;

	private tweenReduceIncome: TWEEN.Tween;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly summonModelsPool: SummonModelsPool,
		private readonly model: BusinessModel,
		private readonly newCustomersMultiplierModel: NewCustomersMutiplierModel,
		private readonly upgrades: UpgradeModel[],
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly lootboxFactory: LootboxFactory,
		private readonly businessesTicker: BusinessesTicker,
		private readonly questTargetDuration: number,
		private readonly levelModel: LevelBaseModel,
	) {
		super();

		this.model = model;
		this.model.on(BusinessModel.EVENT_INCOME_PROGRESS_COMPLETE, this.onIncomeProgressComplete, this);
		this.model.on(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.onNewCustomersMultiplier, this);
		this.model.on(BusinessModel.EVENT_AUTOMATED, this.onAutomated, this);

		this.levelModel.on(LevelBaseModel.EVENT_LEVEL_CHANGE, this.onLevelChanged, this);
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUIView = view;
	}

	public setAcquiredUIView(view: BusinessAcquiredUIView): void {
		this.reduceTimeIncomeClickCount = 0;
		this.reduceTimeIncomeClickCountCharacter = 0;

		this.view = view;
		this.view.on(BusinessAcquiredUIView.EVENT_BACKGROUND_CLICK, this.onBackgroundClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_CHARACTER_CLICK, this.onCharacterClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_BUTTON_BUY_CUSTOMERS_CLICK, this.onButtonBuyCustomersClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_PRESTIGE_BONUS_CLICK, this.onPrestigeBonusClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_BUTTON_FIND_IN_SUMMON_CLICK, this.onButtonFindInSummonClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_BUTTON_UPGRADE_PROMOTE_CLICK, this.onButtonUpgradePromoteClick, this);
		this.view.on(BusinessAcquiredUIView.EVENT_BUTTON_CHARACTER_PROMOTE_CLICK, this.onButtonCharacterPromoteClick, this);
	}

	private onButtonCharacterPromoteClick(character: CharacterModel, skill?: SkillModel): void {
		const window = this.windowFactory.createCharacterProfileWindow(character, skill);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonUpgradePromoteClick(upgrade: UpgradeModel): void {
		const window = this.windowFactory.createUpgradePromoteWindow(upgrade);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonFindInSummonClick(): void {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());
		let openConfig: CollectionWindowOpenConfig;
		if (model) {
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, (collectionWindow: CollectionWindowView) => {
				collectionWindow.once(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, (summonCollectionView: SummonCollectionView) => {
					const target = summonCollectionView.getButtonSummonContainer(model.getKey());
					const show = new ShowQuestTargetView(this.questTargetDuration, target);
					target.parent.addChild(show);
				});
			});
			openConfig = {
				tab: CollectionWindowTabs.TAB_SUMMON,
				focusKey: model.getKey(),
			};
		} else {
			openConfig = { tab: CollectionWindowTabs.TAB_SUMMON };
		}
		const window = this.windowFactory.createCollectionWindow(openConfig);
		this.windowViewSystem.showWindow(window);
	}

	private async onNewCustomersMultiplier(): Promise<void> {
		const result = await this.networkRequestSender.sendGetNewCustomerMultiplierRewards(
			this.model.getKey(),
			this.model.getCustomerCount(),
		);
		if (result && result.rewards) {
			this.newCustomersMultiplierModel.addLootboxData(result.rewards);
		}
	}

	private onPrestigeBonusClick(point: PIXI.Point): void {
		this.gameUIView.playPrestigeMoneyIncomeAnimation(point);
		this.collectLootboxes();
	}

	private collectLootboxes(): void {
		if (this.newCustomersMultiplierModel.hasLootboxData()) {
			const lootboxes = this.newCustomersMultiplierModel.getLootboxData();
			lootboxes.forEach(reward => {
				const lootbox = this.lootboxFactory.createLootbox(reward as any);
				lootbox.collect();
			});

			this.newCustomersMultiplierModel.resetModel();

			const action = new BusinessNewCustomerMultiplierAction(
				this.model.getKey(),
				lootboxes.map(lootbox => lootbox.id),
				{
					resources: {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						prestige_money: this.prestigeMoneyModel.getValue(),
					},
				},
			);
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	private onIncomeProgressComplete(progress: number): void {
		if (this.tweenReduceIncome) {
			this.tweenReduceIncome.stop();
			this.tweenReduceIncome = null;
		}

		if (this.model.isAutomated()) {
			this.collectIncome(progress);
		}
	}

	private onAutomated(): void {
		if (this.model.isIncomeProgressComplete()) {
			this.collectIncome();
		}
	}

	private onCharacterClick(): void {
		if (this.model.isIncomeProgressComplete()) {
			if (!this.model.isAutomated()) {
				this.collectIncome();
			}
		} else {
			const reduceIncomeValue = this.model.getReduceTimeIncomePerTap();
			this.reduceTimeIncome(reduceIncomeValue);

			this.reduceTimeIncomeClickCountCharacter += 1;
		}
		this.tryUpdateSoftMoneyFromSkills();
	}

	private onBackgroundClick(): void {
		if (this.model.isIncomeProgressComplete()) {
			if (!this.model.isAutomated()) {
				this.collectIncome();
			}
		} else {
			const reduceIncomeValue = this.model.getReduceTimeIncomePerTap() / 2;
			this.reduceTimeIncome(reduceIncomeValue);

			this.reduceTimeIncomeClickCount += 1;
		}
		this.tryUpdateSoftMoneyFromSkills();
	}

	private reduceTimeIncome(value: number): void {
		const totalProgress = this.model.getTimeIncomeInterval();
		const reducePercent = value / totalProgress;
		const elapsedReduced = this.model.getIncomeProgress() + reducePercent;

		this.model.setIncomeProgress(elapsedReduced);

		if (this.tweenReduceIncome) {
			this.tweenReduceIncome.stop();
		}
		this.tweenReduceIncome = new TWEEN.Tween({ value: reducePercent })
			.to({ value: 0 }, 500)
			.onUpdate((tweenTarget) => {
				this.model.setIncomeProgressDeltaReduced(tweenTarget.value);
			})
			.onComplete(() => { this.tweenReduceIncome = null; })
			.start();
	}

	private tryUpdateSoftMoneyFromSkills(): void {
		if (this.model.isAddMoneyPerTap()) {
			this.softMoneyModel.add(this.model.getAdditionalMoneyPerTap());
		}
	}

	private collectIncome(progress: number = 1): void {
		if (!this.model.isAutomated()) {
			this.view.playIncomeCollectedAnimation();

			if (this.view.isBusinessZoomedIn()) {
				const softMoneyPanel = this.gameUIView.getSoftMoneyPanel();
				const animationParent = this.gameUIView.getIncomeAnimationContainer();

				softMoneyPanel.playSoftMoneyIncomeAnimation(this.view.getGlobalPosition(), this.gameUIView, animationParent);
			} else {
				this.gameUIView.playSoftMoneyIncomeAnimation(this.view.getGlobalPosition());
			}
		}

		const baseIncome: SoftMoneyNumber = this.model.getBusinessIncome();
		let incomeValue: SoftMoneyNumber = baseIncome;
		if (progress > 1) {
			incomeValue = incomeValue.multiply(progress);
		}

		this.softMoneyModel.add(incomeValue, this.model.getKey());

		const action = new BusinessIncomeAction(
			this.model.getKey(),
			baseIncome.toRawString(),
			this.reduceTimeIncomeClickCount,
			this.reduceTimeIncomeClickCountCharacter,
			this.softMoneyModel.getValue().toRawString(),
			progress,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);

		this.reduceTimeIncomeClickCountCharacter = 0;
		this.reduceTimeIncomeClickCount = 0;

		this.businessesTicker.resetAndAddTicker(this.model);
	}

	private onButtonBuyCustomersClick(customerCount: number): void {
		let cost: SoftMoneyNumber = SoftMoneyNumber.ZERO;
		for (let i = 1; i <= customerCount; i++) {
			cost = cost.add(this.model.getOneCustomerCostSoft(this.model.getCustomerCount() + i - 1));
		}
		this.softMoneyModel.subtract(cost);

		this.model.buyNewCustomers(customerCount);

		const buyCustomersAction = new BusinessBuyCustomerAction(
			this.model.getKey(),
			customerCount,
			cost.toRawString(),
			this.softMoneyModel.getValue().toRawString(),
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, buyCustomersAction);

		this.upgrades.forEach((upgradeModel) => {
			if (!upgradeModel.isCustomersReached()
				&& this.model.getCustomerCount() >= upgradeModel.getCustomerCount()) {
				upgradeModel.setCustomersReached();
			}
		});
	}

	private onLevelChanged(): void {
		this.collectLootboxes();
	}
}
