import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export class QuestSummonConfig extends QuestConfigBase {
	protected type: QuestType;
	private amount: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		switch (config[QuestConfigBase.FIELD_SUB_TYPE]) {
			case QuestConfigBase.SUB_TYPE_ALL: {
				this.type = QuestTypes.SUMMON_ALL;
				break;
			}

			case QuestConfigBase.SUB_TYPE_HARD: {
				this.type = QuestTypes.SUMMON_HARD;
				break;
			}

			case QuestConfigBase.SUB_TYPE_PRESTIGE: {
				this.type = QuestTypes.SUMMON_PRESTIGE;
				break;
			}

			case QuestConfigBase.SUB_TYPE_PAY: {
				this.type = QuestTypes.SUMMON_PAY;
				break;
			}

			default:
				throw new Error(`unknown QuestSummonConfig type '${config.subType}'`);
		}
	}

	public setParamsOnLevel(params: string): void {
		this.amount = Number(params);
	}

	public getAmount(): number {
		return this.amount;
	}

	public getType(): QuestType {
		return this.type;
	}
}
