import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { BusinessIncomeProgressAction } from '@models/network/actions/businesses/BusinessIncomeProgressAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { LevelBaseModel } from '@models/level/LevelBaseModel';

export class BusinessesTicker extends PIXI.utils.EventEmitter {
	public static readonly MIN_ACTIONABLE_INCOME_TIME_SEC: number = 5;
	public static readonly INCOME_ACTION_INTERVAL_MS: number = 3000;

	private readonly ticker: PIXI.ticker.Ticker;

	private incomeProgressUpdateKeys: Set<string>;
	private incomeProgressActionableKeys: Set<string>;

	private timeToIncomeAction: number;

	constructor(
		private readonly models: Map<string, BusinessModel>,
		private readonly characterModels: Map<string, CharacterModel>,
		levelModel: LevelBaseModel,
	) {
		super();

		this.incomeProgressUpdateKeys = new Set();
		this.incomeProgressActionableKeys = new Set();

		this.timeToIncomeAction = BusinessesTicker.INCOME_ACTION_INTERVAL_MS;

		this.ticker = PIXI.ticker.shared;

		this.models.forEach((model) => {
			model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.onSomeIncomeTimeChanged, this);
		});

		levelModel.on(LevelBaseModel.EVENT_LEVEL_CHANGE, () => {
			this.incomeProgressActionableKeys.clear();
		});
	}

	public init(): void {
		this.models.forEach(model => {
			this.unsubscribeOnSomeCharacterActivated(model);

			if (this.isSomeRelatedCharacterActivated(model)) {
				this.addTicker(model);
			} else {
				this.subscribeOnSomeCharacterActivated(model);
			}
		});
	}

	public setEnabled(value: boolean): void {
		if (value) {
			this.ticker.add(this.onTimerUpdate, this);
		} else {
			this.ticker.remove(this.onTimerUpdate, this);
		}
	}

	public resetAndAddTicker(model: BusinessModel): void {
		model.setIncomeProgress(0);

		this.addTicker(model);
	}

	private isSomeRelatedCharacterActivated(model: BusinessModel): boolean {
		const characters = model.getCharacterKeys().map(key => this.characterModels.get(key));
		return characters.some(character => character.isActivated());
	}

	private subscribeOnSomeCharacterActivated(model: BusinessModel): void {
		const characters = model.getCharacterKeys().map(key => this.characterModels.get(key));
		characters.forEach(character => character.on(CharacterModel.EVENT_ACTIVATED, this.onSomeRelatedCharacterActivated, this));
	}

	private unsubscribeOnSomeCharacterActivated(model: BusinessModel): void {
		const characters = model.getCharacterKeys().map(key => this.characterModels.get(key));
		characters.forEach(character => character.off(CharacterModel.EVENT_ACTIVATED, this.onSomeRelatedCharacterActivated, this));
	}

	private onSomeRelatedCharacterActivated(character: CharacterModel): void {
		const business = Array
			.from(this.models.values())
			.find(m => m.getCharacterKeys().some(key => character.getKey() === key));

		this.addTicker(business);
		this.unsubscribeOnSomeCharacterActivated(business);
	}

	private onSomeIncomeTimeChanged(model: BusinessModel): void {
		const key = model.getKey();
		const incomeTime = model.getTimeIncomeInterval();

		if (this.incomeProgressActionableKeys.has(key)) {
			if (incomeTime < BusinessesTicker.MIN_ACTIONABLE_INCOME_TIME_SEC) {
				this.incomeProgressActionableKeys.delete(key);
			}
		} else if (this.incomeProgressUpdateKeys.has(key) && incomeTime >= BusinessesTicker.MIN_ACTIONABLE_INCOME_TIME_SEC) {
			this.incomeProgressActionableKeys.add(key);
		}
	}

	private addTicker(model: BusinessModel): void {
		this.incomeProgressUpdateKeys.add(model.getKey());

		if (model.getTimeIncomeInterval() >= BusinessesTicker.MIN_ACTIONABLE_INCOME_TIME_SEC) {
			this.incomeProgressActionableKeys.add(model.getKey());
		}
	}

	private onTimerUpdate(): void {
		this.timeToIncomeAction -= this.ticker.elapsedMS;

		this.incomeProgressUpdateKeys.forEach(key => {
			const model = this.models.get(key);
			const currentProgress = model.getIncomeProgress();
			const timeIncomeInterval = model.getTimeIncomeInterval();
			const deltaProgress = currentProgress * timeIncomeInterval;

			model.setIncomeProgress((deltaProgress + this.ticker.elapsedMS / 1000) / timeIncomeInterval);

			if (model.isIncomeProgressComplete()) {
				this.incomeProgressUpdateKeys.delete(key);
				this.incomeProgressActionableKeys.delete(key);
			}
		});

		if (this.timeToIncomeAction <= 0) {
			this.timeToIncomeAction = BusinessesTicker.INCOME_ACTION_INTERVAL_MS;

			this.incomeProgressActionableKeys.forEach(key => {
				this.onBusinessIncomeProgressAction(key);
			});
		}
	}

	private onBusinessIncomeProgressAction(key: string): void {
		const model = this.models.get(key);
		const action = new BusinessIncomeProgressAction(
			model.getKey(),
			model.getIncomeProgress(),
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}
}
