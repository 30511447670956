import { BankOfferView } from './BankOfferView';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import * as TWEEN from '@tweenjs/tween.js';
import { ModelHelper } from '@models/ModelHelper';

type BankOfferLineData = {
	view: BankOfferView;
	model: BankOfferModel;
	hidingAnimationTween?: TWEEN.Tween;
};

export class BankOfferLinesUIView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private static readonly LINES_OFFSET: number = 160;
	private static readonly MAX_LINES: number = 3;

	private readonly tweenTicker: PIXI.ticker.Ticker;
	private readonly tweenGroup: TWEEN.Group;

	private readonly offerLines: BankOfferLineData[];

	private runningAnimations: number;

	private offerModels: BankOfferModel[];

	constructor() {
		super();

		this.runningAnimations = 0;

		this.offerLines = [];
		for (let i = 0; i < BankOfferLinesUIView.MAX_LINES; i++) {
			this.offerLines.push(null);
		}

		this.tweenGroup = new TWEEN.Group();

		this.tweenTicker = PIXI.ticker.shared;
		this.tweenTicker.add(this.updateTweenGroup, this);
	}

	public updateBankOfferLines(models: BankOfferModel[]): void {
		this.tryResetCurrentBankOffers();

		this.offerModels = models;

		if (this.runningAnimations === 0) {
			this.onBankOfferLinesUpdate(models);
		}
	}

	public getBankOfferContainerByKey(key: string): PIXI.Container {
		return this.offerLines.filter(line => line.model.getKey() === key)[0].view;
	}

	private onBankOfferLinesUpdate(models: BankOfferModel[]): void {
		// sort offers
		const sortedBankOfferModels = ModelHelper.sortBankOffersModelsByPriority(models);
		// slice sorted offers
		const offerModelsForLines = sortedBankOfferModels.slice(0, Math.min(models.length, BankOfferLinesUIView.MAX_LINES));

		// update models in active offer views
		this.offerLines
			.filter(lineData => lineData !== null && lineData !== undefined)
			.forEach(lineData => {
				const updatedOfferModel = offerModelsForLines.find(model => model.getKey() === lineData.model.getKey());
				if (updatedOfferModel) {
					lineData.view.setModel(updatedOfferModel);
					updatedOfferModel.once(BankOfferModel.EVENT_TIMEOUT, this.onSomeBankOfferTimeout, this);
				}
			});

		// find obsolete offer lines
		const offerLinesToClear = this.offerLines.filter(lineData => lineData
			&& offerModelsForLines.find(model => model.getKey() === lineData.model.getKey()) === undefined);

		offerLinesToClear.forEach(lineData => {
			this.hideOfferAndClearOfferLine(lineData.model);
		});

		// wait for hiding animation end
		if (offerLinesToClear.length === 0) {
			this.onOfferLinesCleared(offerModelsForLines);
		}
	}

	private onOfferLinesCleared(offerModelsForLines: BankOfferModel[]): void {
		// find new offers, Skip offers with timeleft < 3
		const newOfferModels = offerModelsForLines.filter(model => this.offerLines.find(lineData => lineData
			&& lineData.model.getKey() === model.getKey()
			&& lineData.model.getTimeleft() > 3) === undefined);

		// loop through lines and create offer views at free lines
		for (let i = 0; i < BankOfferLinesUIView.MAX_LINES; i++) {
			if (!this.offerLines[i] && newOfferModels.length > 0) {
				const model = newOfferModels.shift();
				model.once(BankOfferModel.EVENT_TIMEOUT, this.onSomeBankOfferTimeout, this);

				this.createBankOfferView(model, i);
			}
		}

		// move up exist bank offer views
		for (let i = 0; i < BankOfferLinesUIView.MAX_LINES - 1; i++) {
			if (!this.offerLines[i]) {
				const existOfferLineIndex = this.offerLines.findIndex((lineData, index) => lineData && index > i);
				if (existOfferLineIndex !== -1) {
					const existOfferLine = this.offerLines[existOfferLineIndex];
					this.offerLines[i] = existOfferLine;
					this.offerLines[existOfferLineIndex] = null;
					const targetY = i * BankOfferLinesUIView.LINES_OFFSET;

					this.startBankOfferMoveUpAnimation(existOfferLine.view, targetY).then(() => {
						this.runningAnimations -= 1;
						if (this.runningAnimations === 0) {
							this.updateBankOfferLines(this.offerModels);
						}
					});
				}
			}
		}
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.tweenTicker.lastTime);
	}

	private createBankOfferView(model: BankOfferModel, lineIndex: number): void {
		const view = new BankOfferView(model);
		view.on(BankOfferView.EVENT_CLICK, this.onBankOfferViewClick, this);
		view.y = lineIndex * BankOfferLinesUIView.LINES_OFFSET;

		this.startBankOfferShowAnimation(view).then(() => {
			this.runningAnimations -= 1;
			if (this.runningAnimations === 0) {
				this.updateBankOfferLines(this.offerModels);
			}
		});

		this.offerLines[lineIndex] = { view, model };
		this.addChild(view);
	}

	private startBankOfferMoveUpAnimation(view: BankOfferView, targetY: number): Promise<void> {
		return new Promise((resolve) => {
			this.runningAnimations += 1;

			new TWEEN.Tween(view, this.tweenGroup)
				.to({ y: targetY }, 300)
				.onComplete(() => resolve())
				.start();
		});
	}

	private startBankOfferShowAnimation(view: BankOfferView): Promise<void> {
		return new Promise((resolve) => {
			this.runningAnimations += 1;

			const offset = 300;
			view.x += offset;
			new TWEEN.Tween(view, this.tweenGroup)
				.to({ x: `-${offset}` }, 300)
				.onComplete(() => resolve())
				.start();
		});
	}

	private onSomeBankOfferTimeout(model: BankOfferModel): void {
		this.hideOfferAndClearOfferLine(model);
	}

	private hideOfferAndClearOfferLine(model: BankOfferModel): void {
		const targetLineDataId = this.offerLines.findIndex(lineData => lineData && lineData.model.getKey() === model.getKey());
		const targetLineData = this.offerLines[targetLineDataId];

		if (!targetLineData.hidingAnimationTween) {
			this.startBankOfferHidingAnimation(targetLineData).then(() => {
				this.offerLines[targetLineDataId] = null;
				targetLineData.view.destroy();

				this.runningAnimations -= 1;
				if (this.runningAnimations === 0) {
					this.updateBankOfferLines(this.offerModels);
				}
			});
		}
	}

	private startBankOfferHidingAnimation(
		bankOfferlineData: BankOfferLineData,
	): Promise<void> {
		return new Promise((resolve) => {
			this.runningAnimations += 1;
			bankOfferlineData.hidingAnimationTween = new TWEEN.Tween(bankOfferlineData.view, this.tweenGroup)
				.to({ x: '+300' }, 300)
				.onComplete(() => resolve())
				.start();
		});
	}

	private onBankOfferViewClick(model: BankOfferModel): void {
		this.emit(BankOfferLinesUIView.EVENT_CLICK, model);
	}

	private tryResetCurrentBankOffers(): void {
		this.offerLines.forEach(lineData => {
			// eslint-disable-next-line no-unused-expressions
			lineData?.model.off(BankOfferModel.EVENT_TIMEOUT, this.onSomeBankOfferTimeout, this, true);
		});
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tryResetCurrentBankOffers();

		this.tweenTicker.remove(this.updateTweenGroup, this);
		this.tweenGroup.removeAll();

		super.destroy(options);
	}
}
