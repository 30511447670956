import { AssetsStorage } from '@main/AssetsStorage';
import { ModelHelper } from '@models/ModelHelper';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { Emitter } from 'pixi-particles';

export class BaseChallengeListItemView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD = Symbol();

	protected readonly animationEmitters: Emitter[];
	protected readonly cardsContainer: PIXI.Container;
	protected readonly mainContainer: PIXI.Container;
	protected readonly glowMask: PIXI.Graphics;
	private failed: boolean;

	constructor(
		panelBg: PIXI.mesh.NineSlicePlane,
		glowSprite: string,
		text: string,
		girls: PIXI.Sprite,
		cardsMiniViewFactory: CardMiniViewFactory,
		rewards: RewardDescriptionType[] = [],
	) {
		super();

		this.animationEmitters = [];

		const textPanelTitle = new SizeableMultiColoredBitmapText(
			370,
			{ font: '24px wendyOneShadowBold', align: 'center' },
		);
		textPanelTitle.text = text;
		textPanelTitle.position.set(11, -64);
		textPanelTitle.anchor = 0.5;

		this.cardsContainer = new PIXI.Container();

		const mergedRewards = ModelHelper.mergeRewardDescriptions(rewards);

		mergedRewards.filter((reward) => reward.reward_type !== RewardTypes.VIDEO)
			.forEach((reward) => {
				const card = cardsMiniViewFactory.createRewardCardByTypeId(
					reward.reward_type as RewardTypes,
					reward.reward_id,
					0.6,
					0.6,
					undefined,
					reward.reward_qty,
				);
				card.x = this.cardsContainer.width;

				card.interactive = true;
				card.on('pointertap', () => {
					this.emit(BaseChallengeListItemView.EVENT_SHOW_HINT_REWARD_CARD, reward, card);
				});

				this.cardsContainer.addChild(card);
			});

		this.cardsContainer.x = 56 - this.cardsContainer.width / 2;
		this.cardsContainer.y = 19;

		this.glowMask = new PIXI.Graphics();
		this.glowMask.beginFill(0xFFFFFF, 1);
		this.glowMask.drawPolygon([
			new PIXI.Point(530, -94),
			new PIXI.Point(286, -94),
			new PIXI.Point(273, 89),
			new PIXI.Point(517, 89),
		]);
		this.glowMask.endFill();

		const titleLineDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[glowSprite]);
		titleLineDecor.blendMode = PIXI.BLEND_MODES.SCREEN;
		titleLineDecor.mask = this.glowMask;
		titleLineDecor.setTransform(283, -2, 1, 1.45);

		const valuePanelBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timed_value_panel']);
		valuePanelBg.setTransform(401, -2, 2, 2);
		valuePanelBg.mask = this.glowMask;

		this.mainContainer = new PIXI.Container();

		this.mainContainer.addChild(
			panelBg as PIXI.DisplayObject,
			valuePanelBg,
			textPanelTitle,
			this.glowMask,
			titleLineDecor,
			girls,
		);

		this.addChild(
			this.mainContainer,
		);
	}

	public isFailed(): boolean {
		return this.failed;
	}

	public setFailed(): void {
		this.failed = true;

		const blackoutFilter = new PIXI.filters.ColorMatrixFilter();
		blackoutFilter.saturate(-0.65);
		blackoutFilter.brightness(0.45);
		this.mainContainer.filters = [blackoutFilter];

		const failedContainer = new PIXI.Container();
		failedContainer.x = -10;

		const failedIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['failed_sticker']);

		failedContainer.addChild(
			failedIcon as PIXI.DisplayObject,
		);

		this.addChild(failedContainer);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
