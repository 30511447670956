import { TextField } from '@views/components/text/TextField';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';

export class BankOfferWindowDiscountBody3View extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;
	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;

	private readonly timerLabel: PIXI.extras.BitmapText;

	constructor(
		bankOfferModel: BankOfferModel,
		bankTabElementWithOffer: BankTabElementWithOfferType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super();

		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		const localizationKeys = bankOfferModel.getLocalizationKeys();

		const bg = new PIXI.Sprite(AssetsStorage.getResource('popup_offer_bg_7').texture);
		bg.interactive = true;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 100;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['bank_special_discount_icon']);
		icon.y = -180;

		const titleString = this.localizationStorage.getLocalizedString(localizationKeys.title);
		const titleText = new TextField(
			titleString,
			{ font: '74px wendyOneGold', align: 'center' },
			820,
			80,
		);
		titleText.anchor = 0.5;
		titleText.y = 40;

		const timerBgMask = new PIXI.Graphics();
		timerBgMask.beginFill(0xFF0011);
		timerBgMask.drawPolygon([
			new PIXI.Point(108, -28),
			new PIXI.Point(372, -28),
			new PIXI.Point(364, 28),
			new PIXI.Point(100, 28),
		]);
		timerBgMask.y = -513;

		const timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
		timerBg.width = 900;
		timerBg.height = 56;
		timerBg.pivot.set(timerBg.width / 2, timerBg.height / 2);
		timerBg.position.set(0, -513);
		timerBg.mask = timerBgMask;

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '36px wendyOneGold',
		});
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(238, -513);

		const subtitleBgMask = new PIXI.Graphics();
		subtitleBgMask.beginFill(0xFFFFFF);
		subtitleBgMask.drawPolygon([
			new PIXI.Point(-372, -28),
			new PIXI.Point(108, -28),
			new PIXI.Point(100, 28),
			new PIXI.Point(-364, 28),
		]);
		subtitleBgMask.y = -513;

		const subtitleBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_panel_gold'], 8, 0, 8, 0);
		subtitleBg.width = 900;
		subtitleBg.height = 56;
		subtitleBg.pivot.set(subtitleBg.width / 2, subtitleBg.height / 2);
		subtitleBg.position.set(0, -513);
		subtitleBg.mask = subtitleBgMask;

		const subtitleText = new MultiColoredTextField(
			{ font: '36px wendyOne', align: 'center', tint: 0x20021a },
			800,
			40,
		);
		subtitleText.text = this.localizationStorage.getLocalizedString(localizationKeys.subtitle1);
		subtitleText.anchor = 0.5;
		subtitleText.position.set(-129, -513);

		const animationsContainerGold = new PIXI.Container();
		animationsContainerGold.position.set(0, 90);
		animationsContainerGold.scale.y = 0.7;

		const glowEmitterGold = new Emitter(
			animationsContainerGold,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitterGold.autoUpdate = true;
		this.animationEmitters.push(glowEmitterGold);

		const buyButton = getBuyButtonHandler(
			340,
			95,
			bankTabElementWithOffer,
		);
		buyButton.position.set(0, 280);

		const rewardsContainer = getRewardMiniCardsContainerHandler(20, bankTabElementWithOffer, 0.7);
		rewardsContainer.position.set(0, 150);

		this.addChild(
			animationsContainerGold,
			timerBgMask,
			timerBg,
			this.timerLabel,
			subtitleBgMask,
			subtitleBg,
			subtitleText,
			bg,
			icon,
			titleText as PIXI.DisplayObject,
			buyButton,
			rewardsContainer,
		);

		if (bankTabElementWithOffer.getMostPopularLocaleKey()) {
			const stickerPopular = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopular.anchor.set(0.5);
			stickerPopular.position.set(380, -135);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getMostPopularLocaleKey()),
				{ font: '26px wendyOne', align: 'center', tint: 0xfff78f },
				100,
				50,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = Math.PI / 180 * 30;
			stickerPopularText.position.set(stickerPopular.x, stickerPopular.y);

			const stickerPopularMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopularMask.anchor.set(0.5);
			stickerPopularMask.tint = 0xffffff;
			const stickerPopularHightlight = new SpriteHighlightAnimationView(stickerPopularMask, 500, false);
			stickerPopularHightlight.position.set(stickerPopular.x, stickerPopular.y);

			this.addChild(
				stickerPopular,
				stickerPopularHightlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHightlight.start();
		}
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = text;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
