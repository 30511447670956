import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { GoToEventLevelWindowEmitter } from '@src/emitters/GoToEventLevelWindowEmitter';
import { CheatModel } from '@models/CheatModel';
import { NewsEmitter } from '@src/emitters/NewsEmitter';
import { DailyRewardEmitter } from '@src/emitters/DailyRewardEmitter';

export class EmittersController {
	private farewellPartyOn: boolean;
	private windowChainOn: boolean;

	constructor(
		private readonly cheatModel: CheatModel,
		private readonly bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		private readonly newsEmitter?: NewsEmitter,
		private readonly goToEventLevelWindowEmitter?: GoToEventLevelWindowEmitter,
		private readonly dailyRewardEmitter?: DailyRewardEmitter,
	) {
		this.cheatModel.on(CheatModel.EVENT_UPDATE_EMITTER_BANK_OFFER_WINDOWS_ENABLED, this.updateEmittersEnabled, this);
		this.cheatModel.on(CheatModel.EVENT_UPDATE_EMITTER_GOTO_EVENT_WINDOWS_ENABLED, this.updateEmittersEnabled, this);
		this.cheatModel.on(CheatModel.EVENT_UPDATE_NEWS_PUSHING_ENABLED, this.updateEmittersEnabled, this);
		this.cheatModel.on(CheatModel.EVENT_UPDATE_DAILY_REWARD_PUSHING_ENABLED, this.updateEmittersEnabled, this);
	}

	public setFarewellPartyOn(value: boolean): void {
		this.farewellPartyOn = value;
		this.updateEmittersEnabled();
	}

	public setWindowChainOn(value: boolean, force: boolean = false): void {
		this.windowChainOn = value;
		this.updateEmittersEnabled(force);
	}

	private updateEmittersEnabled(force: boolean = false): void {
		const enabled: boolean = !this.farewellPartyOn && !this.windowChainOn;

		this.bankOfferWindowsEmitter.setEnabled(
			this.cheatModel.isEmitterBankOfferWindowsEnabled() && enabled,
			force,
		);
		this.newsEmitter?.setEnabled(
			this.cheatModel.isNewsPushingEnabled() && enabled,
		);
		this.dailyRewardEmitter?.setEnabled(
			this.cheatModel.isDailyRewardPushingEnabled() && enabled,
		);
		this.goToEventLevelWindowEmitter?.setEnabled(
			this.cheatModel.isEmitterGoToEventWindowsEnabled() && enabled,
		);
	}
}
