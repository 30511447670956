import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { BusinessModel } from '@models/BusinessModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeCustomersOnBusinessModel extends TutorialStepUnlockTypeBaseModel {
	private readonly targetCount: number;

	private business: BusinessModel;

	constructor(key: string, business: BusinessModel, targetCount: number) {
		super(key, TutorialStepUnlockTypes.CUSTOMERS_ON_BUSINESS);

		this.targetCount = targetCount;

		this.business = business;
		if (this.business.getCustomerCount() >= this.targetCount) {
			this.unlocked = true;
		} else {
			this.business.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		}
	}

	private onNewCustomers(): void {
		const currentCount = this.business.getCustomerCount();
		if (currentCount >= this.targetCount) {
			this.business.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
			this.onUnlocked();
		}
	}

	public destroy(): void {
		if (this.business.listeners(BusinessModel.EVENT_NEW_CUSTOMERS).includes(this.onNewCustomers)) {
			this.business.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		}
		super.destroy();
	}
}
