import { NetworkRequestSender } from '../network/NetworkRequestSender';
import { LevelConfigNames } from '@src/types/LevelConfigNames';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { SoundController } from '@src/main/SoundController';
import { GameProfileModel } from '@models/GameProfileModel';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { LocalizationLoader } from './LocalizationLoader';
import { NetworkRequestTransport } from '@src/network/NetworkRequestTransport';
import { EventLevelConfigNames } from '@src/types/EventLevelConfigNames';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { PaymentMethod } from '@src/types/TransactionTypes';

export class NetworkProfileLoader extends PIXI.utils.EventEmitter {
	private static get MAIN_CONFIGS_TO_LOAD(): string[] {
		return [
			LevelConfigNames.CONFIG_SEASONS,
			LevelConfigNames.CONFIG_CONSTANTS,
			LevelConfigNames.CONFIG_BUSINESSES,
			LevelConfigNames.CONFIG_CHARACTERS,
			LevelConfigNames.CONFIG_SKILLS,
			LevelConfigNames.CONFIG_UPGRADES,
			LevelConfigNames.CONFIG_QUESTS,
			LevelConfigNames.CONFIG_DIALOGS,
			LevelConfigNames.CONFIG_FAREWELL_PARTY,
			LevelConfigNames.CONFIG_TOTEMS,
			LevelConfigNames.CONFIG_VIDEO,
			LevelConfigNames.CONFIG_EPIC_QUESTS_COLLECTIONS,
			LevelConfigNames.CONFIG_TUTORIAL_STEPS,
			LevelConfigNames.CONFIG_BANK_PRICE_ITEMS,
			LevelConfigNames.CONFIG_BOOSTS,
			LevelConfigNames.CONFIG_TIMESKIPS,
			LevelConfigNames.CONFIG_PROMOTE_PATTERNS,
			LevelConfigNames.CONFIG_MAIN_WINDOW,
			LevelConfigNames.CONFIG_LOCALIZATON_SUPPORT,
			LevelConfigNames.CONFIG_LOOTBOXES,
			LevelConfigNames.CONFIG_DAILY_REWARDS,
			LevelConfigNames.CONFIG_PRESETS,
		];
	}

	private static get EVENT_CONFIGS_TO_LOAD(): string[] {
		return [
			EventLevelConfigNames.CONFIG_SEASONS,
			EventLevelConfigNames.CONFIG_BUSINESSES,
			EventLevelConfigNames.CONFIG_CHARACTERS,
			EventLevelConfigNames.CONFIG_SKILLS,
			EventLevelConfigNames.CONFIG_UPGRADES,
			EventLevelConfigNames.CONFIG_QUESTS,
			EventLevelConfigNames.CONFIG_TUTORIAL_STEPS,
			EventLevelConfigNames.CONFIG_DIALOGS,
			EventLevelConfigNames.CONFIG_BANK_PRICE_ITEMS,
			EventLevelConfigNames.CONFIG_BOOSTS,
			EventLevelConfigNames.CONFIG_TIMESKIPS,
			EventLevelConfigNames.CONFIG_EVENT_DATES,
			EventLevelConfigNames.CONFIG_LEAGUE_BOTS,
			EventLevelConfigNames.CONFIG_LEAGUES,
			EventLevelConfigNames.CONFIG_FAREWELL_PARTY,
			EventLevelConfigNames.CONFIG_PROMOTE_PATTERNS,
		];
	}

	public static readonly EVENT_PROFILE_LOADED: symbol = Symbol();
	public static readonly EVENT_EVENT_CONFIGS_LOADED: symbol = Symbol();

	private readonly soundController: SoundController;

	constructor(
		protected readonly networkRequestSender: NetworkRequestSender,
		protected readonly networkRequestTransport: NetworkRequestTransport,
		protected readonly gameProfileModel: GameProfileModel,
		private readonly serverTime: ServerTimeModel,
		private readonly savesConfig: SavesConfig,
		private readonly localizationLoader: LocalizationLoader,
		protected readonly url: string,
	) {
		super();

		this.networkRequestTransport = networkRequestTransport;
		this.networkRequestSender = networkRequestSender;
		this.gameProfileModel = gameProfileModel;
		this.savesConfig = savesConfig;
		this.soundController = SoundController.getInstance();
		this.localizationLoader = localizationLoader;
		this.url = url;
	}

	public async loadProfile(): Promise<void> {
		const playerData = await this.sendGetProfileId();

		this.networkRequestTransport.setPlayerId(playerData['profile_id']);
		this.networkRequestTransport.setSessionId(playerData['session_id']);

		const configsData = await this.networkRequestSender.sendGetConfigs([
			...NetworkProfileLoader.MAIN_CONFIGS_TO_LOAD,
		]);

		const savesData = await this.networkRequestSender.sendProfileLoad();

		this.savesConfig.init(savesData);

		const loginReward = savesData['login_reward'];

		if (loginReward != null && Object.keys(loginReward).length > 0) {
			this.gameProfileModel.setRegistrationLootboxConfig(loginReward);
		}

		await this.setProfileFromSaveData(
			configsData[LevelConfigNames.CONFIG_LOCALIZATON_SUPPORT],
		);
		this.emit(NetworkProfileLoader.EVENT_PROFILE_LOADED, configsData);
	}

	public async loadEventConfigs(): Promise<void> {
		const configsData = await this.networkRequestSender.sendGetConfigs([
			...NetworkProfileLoader.EVENT_CONFIGS_TO_LOAD,
		]);

		this.emit(
			NetworkProfileLoader.EVENT_EVENT_CONFIGS_LOADED,
			configsData,
		);
	}

	protected sendGetProfileId(): Promise<Record<string, string>> {
		return this.networkRequestSender.sendGetProfileId(this.url);
	}

	private async setProfileFromSaveData(
		localizationSupportConfigData: Record<string, string>[],
	): Promise<void> {
		const settingsSaveData = this.savesConfig.getSettingsSaveData();

		if (settingsSaveData.music !== undefined) {
			this.soundController.setMusicVolume(settingsSaveData.music);
		}

		if (settingsSaveData.sound !== undefined) {
			this.soundController.setSoundsVolume(settingsSaveData.sound);
		}

		if (settingsSaveData.cardpay !== undefined) {
			const paymentMethod = settingsSaveData.cardpay ? PaymentMethod.CARDPAY : PaymentMethod.EPOCH;
			this.gameProfileModel.setPaymentMethod(paymentMethod);
		}

		this.networkRequestTransport.setUpdatesRevision(this.savesConfig.getUpdatesRevision());

		if (this.savesConfig.hasEmail()) {
			this.gameProfileModel.setLoggedIn(this.savesConfig.getEmail());
		} else {
			this.gameProfileModel.setEmail(this.savesConfig.getCardPayEmail());
		}

		if (this.savesConfig.hasUsername()) {
			this.gameProfileModel.setUsername(this.savesConfig.getUsername());
		}

		this.gameProfileModel.setAccountLifetime(
			this.serverTime.getCalculatedISOTime() - this.savesConfig.getCreatedOn(),
		);

		const localizationSupportConfig = new LocalizationSupportConfig(localizationSupportConfigData);

		await this.localizationLoader.load(
			localizationSupportConfig,
			settingsSaveData.language,
		);
	}
}
