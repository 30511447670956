import { TabSlotView } from "./BankTabViewFactory";
import { ScrollView } from "@views/components/ScrollView";
import { ScrollAxis, ScrollOffset } from "@src/types/ScrollViewTypes";

export class BankTabBaseScrollView extends PIXI.Container {
	private readonly offsetTop: number;
	private readonly spaceBetweenSlots: number;
	private readonly scrollOffset: ScrollOffset;

	constructor(offsetTop: number, spaceBetweenSlots: number, scrollOffset: ScrollOffset) {
		super();

		this.offsetTop = offsetTop;
		this.spaceBetweenSlots = spaceBetweenSlots;
		this.scrollOffset = scrollOffset;
	}

	public init(slots: TabSlotView[]): void {
		if (this.children.length > 0) {
			this.removeChildren();
		}

		const content = new PIXI.Container();

		const n: number = slots.length;
		if (n > 0) {
			let view: PIXI.Container;
			slots.sort((a, b) => a.index - b.index);
			for (let i: number = 0; i < n; i++) {
				view = slots[i].view;

				view.position.set(756, this.offsetTop + this.spaceBetweenSlots * i);
				content.addChild(view);
			}
		}

		const scrollView: ScrollView = new ScrollView(
			1500,
			1000,
			ScrollAxis.VERTICAL,
			this.scrollOffset,
		);
		scrollView
			.setContent(content)
			.setScrollSpeed(40)
			.setMovementThreshold(20);

		scrollView.jumpTo(this.scrollOffset.begin);

		this.addChild(scrollView);
	}
}
