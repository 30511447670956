import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter100014View extends CharacterMainBaseView {
	constructor() {
		super('character100014');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(166, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(171, -12),
			bgPosition: new PIXI.Point(165, 0),
			arrowRotation: Math.PI,
			bgMinHeight: 30,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
