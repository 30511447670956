import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter251010View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251010View';
import { EventCharacter251011View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251011View';
import { EventCharacter251012View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251012View';

export class EventBusiness251004View extends BusinessBaseView {
	constructor() {
		const eventCharacter251010View = new EventCharacter251010View();
		const eventCharacter251011View = new EventCharacter251011View();
		const eventCharacter251012View = new EventCharacter251012View();

		super('business251004', [eventCharacter251010View, eventCharacter251011View, eventCharacter251012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter251012View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter251010View as PIXI.DisplayObject,
			eventCharacter251011View,
		);
	}
}
