import { BankElementViewDataConfig } from '@configs/bank/BankElementViewDataConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { BankTabConfig } from '@configs/bank/BankTabConfig';
import { BankTabElementConfig } from '@configs/bank/BankTabElementConfig';
import { ModelWithOffer } from '@interfaces/ModelWithOffer';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';
import { BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';
import { BankBundleGroupModel } from './BankBundleGroupModel';
import { BankGemShopItemModel } from './BankGemShopItemModel';
import { BankOfferModel } from './BankOfferModel';
import { BankRechargeModel } from './BankRechargeModel';

export type BankTabElementType =
	BankElementViewDataConfig |
	BankGemShopItemModel |
	BankBundleModel |
	BankSubscribeModel |
	BankBundleGroupModel |
	BankRechargeModel |
	BankOfferModel;

export type BankTabElementWithOfferType =
	BankBundleModel |
	BankGemShopItemModel;

export type BankTabElementPricedType =
	BankBundleModel |
	BankSubscribeModel |
	BankGemShopItemModel;

export type BankTabGeneralObjectWithOfferType = BankTabElementWithOfferType | BankBundleGroupModel;

export type BankTabElementConfigNameDataMapType = Map<BankTabElementDataConfigNameTypes, Map<string, BankTabElementType>>;

type BankItemConfig = { getViewKey(): string; getKey(): string };

export class BankModel extends PIXI.utils.EventEmitter {
	private static getModelWithOfferKey<T extends ModelWithOffer>(
		offerKey: string,
		configs: Map<string, T>,
	): T | undefined {
		const configsArray = Array.from(configs.values());
		return configsArray.find(x => x.hasOfferKey() && x.getOfferKey() === offerKey);
	}

	private static getConfigByKeyAndHasOffer<T extends BankBundleGroupModel | BankBundleModel | BankGemShopItemModel>(
		key: string,
		configs: Map<string, T>,
	): T | undefined {
		const configsArray = Array.from(configs.values());
		return configsArray.find(x => {
			let hasOfferKey: boolean;
			let hasKey: boolean;
			if (x instanceof BankBundleGroupModel) {
				hasOfferKey = x.hasOfferKey();
				hasKey = x.getKey() === key;
			} else if (x instanceof BankBundleModel || x instanceof BankGemShopItemModel) {
				hasOfferKey = x.hasOfferKey();
				hasKey = x.getViewKey() === key;
			}
			return hasOfferKey && hasKey;
		});
	}

	public static readonly EVENT_UPDATED: symbol = Symbol();
	public static readonly EVENT_OBSOLETE: symbol = Symbol();

	private readonly bankTabElementConfigNameDataMap: BankTabElementConfigNameDataMapType;

	private bankTabConfigs: BankTabConfig[];

	private gemShopModels: Map<string, BankGemShopItemModel>;
	private rechargeModels: Map<string, BankRechargeModel>;
	private bundleModels: Map<string, BankBundleModel>;
	private subscribeModels: Map<string, BankSubscribeModel>;
	private offerModels: Map<string, BankOfferModel>;
	private bundleGroupModels: Map<string, BankBundleGroupModel>;

	private obsolete: boolean;

	constructor(
		private readonly bankPriceItemsConfig: BankPriceItemsConfig,
	) {
		super();

		this.bankTabElementConfigNameDataMap = new Map();

		this.obsolete = true;
	}

	public init(
		tabConfigs: BankTabConfig[],
		gemShopItemModels: Map<string, BankGemShopItemModel>,
		bundleModels: Map<string, BankBundleModel>,
		subscribeModels: Map<string, BankSubscribeModel>,
		offerModels: Map<string, BankOfferModel>,
		bundleGroupModels: Map<string, BankBundleGroupModel>,
		rechargeModels: Map<string, BankRechargeModel>,
	): void {
		if (this.bankTabElementConfigNameDataMap.size > 0) {
			this.bankTabElementConfigNameDataMap.clear();
		}

		this.bankTabConfigs = tabConfigs;

		this.gemShopModels = new Map();
		BankModel.parseViewKeyMap(this.gemShopModels, gemShopItemModels);

		this.rechargeModels = new Map();
		BankModel.parseViewKeyMap(this.rechargeModels, rechargeModels);

		this.bundleModels = new Map();
		BankModel.parseViewKeyMap(this.bundleModels, bundleModels);

		this.subscribeModels = new Map();
		BankModel.parseViewKeyMap(this.subscribeModels, subscribeModels);

		this.offerModels = offerModels;
		this.bundleGroupModels = bundleGroupModels;

		// Bank items
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.GEM_SHOP,
			this.gemShopModels,
		);

		// Recharge
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.RECHARGE,
			this.rechargeModels,
		);

		// Bundles
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.BUNDLES,
			this.bundleModels,
		);

		// Subscribes
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.SUBSCRIBE,
			this.subscribeModels,
		);

		// Offers
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.OFFERS,
			this.offerModels,
		);

		// BunleGroups
		this.bankTabElementConfigNameDataMap.set(
			BankTabElementDataConfigNameTypes.BUNDLE_GROUPS,
			this.bundleGroupModels,
		);

		this.obsolete = false;

		this.emit(BankModel.EVENT_UPDATED, this);
	}

	public reset(): void {
		this.offerModels?.forEach(model => model.setOfferWindowShowed(false));
	}

	public setObsolete(value: boolean): void {
		this.obsolete = value;
		this.emit(BankModel.EVENT_OBSOLETE, value);
	}

	public isAnySubsrcibeClaimable(currentTime: number): boolean {
		if (this.subscribeModels && this.subscribeModels.size > 0) {
			let haveClaimableSubscribe = false;
			this.subscribeModels.forEach((config) => {
				if (config.getIsActive() && config.getClaimableOn() < currentTime) {
					haveClaimableSubscribe = true;
				}
			});
			return haveClaimableSubscribe;
		}
		return false;
	}

	public isAnyRechargeClaimable(): boolean {
		return Array.from(this.rechargeModels?.values() || []).some(model => model.isClaimable());
	}

	public isFreeBundleClaimable(tabElements: BankTabElementConfig[]): boolean {
		let result = false;

		for (let i = 0; i < tabElements.length; i++) {
			const key = tabElements[i].getViewKey();
			const bundle = this.bundleModels.get(key);

			if (bundle != null && bundle.isFreePrice() && bundle.getRemainingCount() > 0) {
				result = true;
				break;
			}
		}

		return result;
	}

	public isAnyFreeBundleClaimable(): boolean {
		let result = false;

		if (this.bundleModels != null) {
			this.bundleModels.forEach((bundle) => {
				if (bundle.isFreePrice() && bundle.getRemainingCount() > 0) {
					result = true;
				}
			});
		} else {
			result = false;
		}

		return result;
	}

	public isObsolete(): boolean {
		return this.obsolete;
	}

	public getBankTabElementByOfferKey(key: string): BankTabGeneralObjectWithOfferType | undefined {
		let element: BankTabGeneralObjectWithOfferType | undefined;
		const bundleModel = BankModel.getModelWithOfferKey(key, this.bundleModels);

		if (bundleModel?.getRemainingCount() > 0) {
			element = bundleModel;
		}

		if (element === undefined) {
			const bankGemItemModel = BankModel.getModelWithOfferKey(key, this.gemShopModels);
			if (bankGemItemModel !== undefined) {
				element = bankGemItemModel;
			} else {
				const bundleGroupModel = BankModel.getModelWithOfferKey(key, this.bundleGroupModels);
				if (bundleGroupModel?.getBundles().length > 0) {
					element = bundleGroupModel;
				}
			}
		}

		return element;
	}

	public getBankOfferModelByBankTabElementKey(key: string): BankOfferModel | undefined {
		let model: BankOfferModel | undefined;
		const bundle = BankModel.getConfigByKeyAndHasOffer(key, this.bundleModels);

		if (bundle !== undefined) {
			model = this.offerModels?.get(bundle.getOfferKey());
		}

		if (model === undefined) {
			const gemShopItem = BankModel.getConfigByKeyAndHasOffer(key, this.gemShopModels);
			if (gemShopItem !== undefined) {
				model = this.offerModels?.get(gemShopItem.getOfferKey());
			} else {
				const bundleGroup = BankModel.getConfigByKeyAndHasOffer(key, this.bundleGroupModels);
				if (bundleGroup !== undefined) {
					model = this.offerModels?.get(bundleGroup.getOfferKey());
				}
			}
		}

		return model;
	}

	public getBankBundleGroupByBankTabElementKey(key: string): BankBundleGroupModel {
		const bundles: BankBundleModel[] = Array.from(this.bundleModels.values());
		const bundle = bundles.find(x => x.hasBundleGroupKey() && x.getViewKey() === key);
		return this.bundleGroupModels.get(bundle?.getBundleGroupKey());
	}

	public getBankOfferModelsWithActiveBankTabElement(): BankOfferModel[] {
		const bankOfferModelsArray = Array.from(this.offerModels.values());
		return bankOfferModelsArray.filter((bankOfferModel) => this.getBankTabElementByOfferKey(bankOfferModel.getKey()) !== undefined);
	}

	public getBankOfferModelByKey(key: string): BankOfferModel {
		return this.offerModels.get(key);
	}

	public getBankTabConfigs(): BankTabConfig[] {
		return this.bankTabConfigs;
	}

	public getBankPriceItemsConfig(): BankPriceItemsConfig {
		return this.bankPriceItemsConfig;
	}

	public getTabElementConfigNameDataMap(): BankTabElementConfigNameDataMapType {
		return this.bankTabElementConfigNameDataMap;
	}

	private static parseViewKeyMap(
		sourceMap: Map<string, BankItemConfig>,
		targetMap: Map<string, BankItemConfig>,
	): void {
		targetMap.forEach((value) => {
			const viewKey = value.getViewKey();

			if (MODE_DEBUG && sourceMap.has(viewKey)) {
				const oldKey = sourceMap.get(viewKey).getKey();
				// eslint-disable-next-line no-console
				console.warn(`Overriding prefab_key ${viewKey}: ${oldKey} -> ${value.getKey()}`);
			}

			sourceMap.set(viewKey, value);
		});
	}
}
