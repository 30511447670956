import { ViewportView } from '@views/components/ViewportView';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { BusinessBaseView, BusinessUIViewTypes } from '@views/businesses/ui/BusinessBaseView';
import { CharacterSkillPairType } from '@src/types/CharacterSkillPairType';
import { ViewportUIView } from '@views/components/ViewportUIView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { ButtonMaxCustomersView } from '@views/ui/ButtonMaxCustomersView';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { CheatModel } from '@models/CheatModel';
import { TimesOfDayType } from '@src/types/TimesOfDayTypes';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';

export class LevelBackground extends PIXI.Container {
	private parallaxTarget: PIXI.Container;

	public setParallaxTarget(target: PIXI.Container): void {
		this.parallaxTarget = target;
	}

	public getParallaxTarget(): PIXI.Container {
		return this.parallaxTarget;
	}
}

export interface LevelBaseViewArgs {
	cheatModel: CheatModel,
	businessModels: Map<string, BusinessModel>,
	newCustomerMultiplierModels: Map<string, NewCustomersMutiplierModel>,
	characterModels: Map<string, CharacterModel>,
	skillModels: Map<string, SkillModel>,
	upgradeModels: Map<string, UpgradeModel>,
	softMoneyModel: SoftMoneyModel,
	prestigeMoneyModel: PrestigeMoneyModel,
	prepartyStartCharacterModel: PrepartyStartCharacterModel,
	characterQuickPhraseEmitter: CharacterQuickPhraseEmitter,
	background: TimesOfDayType,
	buttonMaxCustomersView: ButtonMaxCustomersView,
	viewportUI: ViewportUIView,
	viewport: ViewportView,
	currentLevel?: number,
	nextLevelBackground?: LevelBackground,
}

export abstract class LevelBaseView extends PIXI.Container {
	private readonly viewport: ViewportView;
	private readonly viewportUI: ViewportUIView;
	private readonly cheatModel: CheatModel;
	private readonly newCustomerMultiplierModels: Map<string, NewCustomersMutiplierModel>;
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly characterModels: Map<string, CharacterModel>;
	private readonly skillModels: Map<string, SkillModel>;
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	protected readonly prepartyStartCharacterModel: PrepartyStartCharacterModel;
	private readonly characterQuickPhraseEmitter: CharacterQuickPhraseEmitter;
	private readonly buttonMaxCustomersView: ButtonMaxCustomersView;

	private readonly nextLevelBackground: LevelBackground;
	private nextLevelBackgroundTween: TWEEN.Tween;
	private levelBusinesses: BusinessBaseView[];
	protected prepartyCharacterView: PrepartyStartCharacterView;

	protected currentLevel: number;
	protected background: LevelBackground;

	constructor(args: LevelBaseViewArgs) {
		super();

		this.cheatModel = args.cheatModel;
		this.businessModels = args.businessModels;
		this.newCustomerMultiplierModels = args.newCustomerMultiplierModels;
		this.characterModels = args.characterModels;
		this.skillModels = args.skillModels;
		this.upgradeModels = args.upgradeModels;
		this.softMoneyModel = args.softMoneyModel;
		this.prestigeMoneyModel = args.prestigeMoneyModel;
		this.prepartyStartCharacterModel = args.prepartyStartCharacterModel;
		this.characterQuickPhraseEmitter = args.characterQuickPhraseEmitter;
		this.currentLevel = args.currentLevel;

		this.buttonMaxCustomersView = args.buttonMaxCustomersView;

		this.viewport = args.viewport;
		this.viewportUI = args.viewportUI;
		this.nextLevelBackground = args.nextLevelBackground;

		this.prepartyStartCharacterModel.on(
			PrepartyStartCharacterModel.EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY,
			this.onChangePrepartyCharacterVisbility,
			this,
		);
		this.create(args.background);
	}

	protected abstract create(background: string): void;

	protected abstract getPrePartyCharacterPosition(): PIXI.Point;

	public initLevel(
		levelWidth: number,
		parallaxTarget: PIXI.Container,
		businesses: BusinessBaseView[],
		prepartyCharacterView?: PrepartyStartCharacterView,
	): void {
		this.levelBusinesses = businesses;

		this.viewport
			.setClamp(0, levelWidth)
			.setParallax(parallaxTarget)
			.setDragEnabled(true)
			.setWheelScrollDirection(-1)
			.setScrollSpeed(45)
			.setMaxScrollTime(130)
			.setMovementThreshold(100);

		this.levelBusinesses.forEach(((levelBusiness: BusinessBaseView) => {
			const businessModel = this.businessModels.get(levelBusiness.getKey());
			const newCustomerMultiplierModel = this.newCustomerMultiplierModels.get(levelBusiness.getKey());

			const characterModels: Map<string, CharacterModel> = new Map();
			businessModel.getCharacterKeys().forEach((characterKey) => {
				characterModels.set(characterKey, this.characterModels.get(characterKey));
			});
			const upgradeModels: Map<string, UpgradeModel> = new Map();
			businessModel.getUpgradeKeys().forEach((upgradeKey) => {
				upgradeModels.set(upgradeKey, this.upgradeModels.get(upgradeKey));
			});
			const skillModels: Map<string, SkillModel> = new Map();
			Array.from(characterModels.values()).forEach((characterModel) => {
				if (characterModel.hasBonusSkill()) {
					const skillKey = characterModel.getSkillKey();
					skillModels.set(skillKey, this.skillModels.get(skillKey));
				}
			});
			const characterSkillPairs: CharacterSkillPairType[] = Array.from(characterModels.values(), (character) => {
				if (character.hasBonusSkill()) {
					return { character, skill: skillModels.get(character.getSkillKey()) };
				}
				return { character };
			});
			levelBusiness.init(
				businessModel,
				this.cheatModel,
				newCustomerMultiplierModel,
				this.skillModels,
				this.softMoneyModel,
				this.prestigeMoneyModel,
				Array.from(upgradeModels.values()),
				Array.from(characterModels.values()),
				characterSkillPairs,
				this.buttonMaxCustomersView,
				this.viewportUI,
				this.viewport,
				this.characterQuickPhraseEmitter,
				this.currentLevel,
			);
		}));

		if (prepartyCharacterView) {
			this.prepartyCharacterView = prepartyCharacterView;
			this.setPrepartyStartCharacterPosition();
		}
	}

	public startHideUIAnimation(hideUIDuration: number): void {
		this.viewportUI.startHidingAnimation(hideUIDuration);

		this.levelBusinesses.forEach(((levelBusiness: BusinessBaseView) => {
			levelBusiness.hideUI(hideUIDuration);
		}));

		if (this.prepartyCharacterView?.visible) {
			this.prepartyCharacterView.hide();
		}
	}

	public startShowUIAnimation(showUIDuration: number): void {
		this.viewportUI.startAppearingAnimation(showUIDuration);

		this.levelBusinesses.forEach(((levelBusiness: BusinessBaseView) => {
			levelBusiness.showUI(showUIDuration);
		}));

		this.showPrePartyCharacter();
	}

	public tryStartNextLevelBackgroundAnimation(changeDuration: number): void {
		if (this.nextLevelBackground) {
			this.startNextLevelBackgroundAnimation(changeDuration);
		}
	}

	private startNextLevelBackgroundAnimation(changeDuration: number): void {
		const backgroundIndex = this.getChildIndex(this.background);

		this.addChildAt(this.nextLevelBackground, backgroundIndex + 1);

		this.nextLevelBackground.alpha = 0;
		this.nextLevelBackgroundTween = new TWEEN.Tween(this.nextLevelBackground)
			.to({ alpha: 1 }, changeDuration)
			.start();
	}

	public startCloseBusinessesAnimation(hideBusinessesDuration: number): void {
		const openedBusinesses = this.levelBusinesses.filter((levelBusiness) => {
			const businessModel = this.businessModels.get(levelBusiness.getKey());
			return businessModel.isAcquired();
		});

		if (openedBusinesses.length !== 0) {
			const hideOneBusinessDuration = hideBusinessesDuration / openedBusinesses.length;
			const delay = hideBusinessesDuration / openedBusinesses.length;

			openedBusinesses.reverse();
			openedBusinesses.forEach(((levelBusiness: BusinessBaseView, i: number) => {
				levelBusiness.startCloseAnimation(hideOneBusinessDuration, i * delay);
			}));
		}
	}

	public startMoveToFirstBusinessAnimation(duration: number): void {
		if (this.levelBusinesses.length > 0) {
			const firstBusiness = this.levelBusinesses[0];

			const posViewport = this.viewport.toLocal(firstBusiness.position, this);
			this.viewport.moveTo(
				posViewport.x - GameConstants.GAME_WIDTH / 2,
				duration,
				TWEEN.Easing.Cubic.In,
			);
		}
	}

	public getLevelBusinesses(): BusinessBaseView[] {
		return this.levelBusinesses;
	}

	public getPrepartyStartCharacterView(): PrepartyStartCharacterView {
		return this.prepartyCharacterView;
	}

	private onChangePrepartyCharacterVisbility(visible: boolean): void {
		if (visible) {
			this.showPrePartyCharacter();
		} else {
			this.prepartyCharacterView?.hide();
		}
	}

	protected showPrePartyCharacter(): void {
		if (this.prepartyStartCharacterModel.canShowView() && this.prepartyCharacterView?.isUnlocked()) {
			this.setPrepartyStartCharacterPosition();
			this.prepartyCharacterView.show();
		}
	}

	private setPrepartyStartCharacterPosition(): void {
		const position: PIXI.Point = this.getPrePartyCharacterPosition();
		this.prepartyCharacterView.position = position;
	}

	protected getAcquiredBuildings(): number[] {
		const result: number[] = [];
		const n: number = this.levelBusinesses.length;

		for (let i: number = 0; i < n; i += 3) {
			let gotBuilding: boolean = false;
			for (let j: number = i; j < i + 3 && j < n; j++) {
				gotBuilding = gotBuilding || this.levelBusinesses[i].getCurrentUIViewType() === BusinessUIViewTypes.ACQUIRED;
			}
			if (gotBuilding) {
				result.push(Math.floor(i / 3));
			}
		}
		return result;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.nextLevelBackgroundTween) {
			this.nextLevelBackgroundTween.stop();
			this.nextLevelBackgroundTween = null;
		}

		if (this.nextLevelBackground && !this.nextLevelBackground.parent) {
			this.nextLevelBackground.destroy({ children: true });
		}

		this.prepartyStartCharacterModel.off(
			PrepartyStartCharacterModel.EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY,
			this.onChangePrepartyCharacterVisbility,
			this,
		);

		super.destroy(options);
	}
}
