import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter52013View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52013View';
import { EventCharacter52014View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52014View';
import { EventCharacter52015View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52015View';

export class EventBusiness52005View extends BusinessBaseView {
	constructor() {
		const eventCharacter52013View = new EventCharacter52013View();
		const eventCharacter52014View = new EventCharacter52014View();
		const eventCharacter52015View = new EventCharacter52015View();

		super('business52005', [eventCharacter52013View, eventCharacter52014View, eventCharacter52015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter52013View as PIXI.DisplayObject,
			eventCharacter52014View,
			eventCharacter52015View,
		);
	}
}
