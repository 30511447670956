import { AssetsStorage } from '@main/AssetsStorage';
import { TabButtonBaseView } from '@views/components/buttons/TabButtonBaseView';

export class CollectionTabButtonView extends TabButtonBaseView {
	private readonly totalCards: number;
	private currentCards: number;

	protected infoIcon: PIXI.Sprite;
	protected progressValue: PIXI.extras.BitmapText;

	constructor(
		icon: PIXI.Sprite,
		buttonLabel: string,
		totalCards: number,
	) {
		const inactiveBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_blue_tab'], 7, 0, 7, 0);
		inactiveBg.width = 240;
		inactiveBg.height = 127;
		inactiveBg.pivot.set(120, 63);

		const activeBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['u_collection_green_tab'], 9, 0, 36, 0);
		activeBg.width = 272;
		activeBg.height = 127;
		activeBg.pivot.set(136, 63);
		activeBg.x = 13;

		super(
			inactiveBg,
			activeBg,
			icon,
			buttonLabel,
		);
		this.buttonLabel.anchor = 0.5;
		this.buttonLabel.position.set(28, -18);

		this.currentCards = 0;
		this.totalCards = totalCards;

		this.progressValue = new PIXI.extras.BitmapText(
			`${this.currentCards}/${this.totalCards}`,
			{ font: '25px wendyOne', align: 'center', tint: 0xffe383 },
		);
		this.progressValue.anchor = 0.5;
		this.progressValue.position.set(28, 40);

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.position.set(100, -60);
		this.infoIcon.visible = false;

		this.addChild(
			this.infoIcon,
			this.progressValue as PIXI.DisplayObject,
		);
	}

	public setInfoIconVisible(value: boolean): void {
		this.infoIcon.visible = value;
	}

	public setProgress(current: number): void {
		this.currentCards = current;
		this.progressValue.text = `${this.currentCards}/${this.totalCards}`;
	}

	public isInfoIconVisible(): boolean {
		return this.infoIcon.visible;
	}
}
