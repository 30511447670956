import { SoundList, CharacterClickSoundList } from '@src/types/SoundList';
import { CharacterClickSoundModel } from '@models/sound/CharacterClickSoundModel';
import { FarewellPartyHeartClickSoundModel } from '@models/sound/FarewellPartyHeartClickSoundModel';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetLoader } from '@src/utils/AssetLoader';

export class SoundController extends PIXI.utils.EventEmitter {
	public static readonly EVENT_SOUNDS_LOADED: symbol = Symbol();

	private static instance: SoundController;

	public static getInstance(): SoundController {
		if (!SoundController.instance) {
			SoundController.instance = new SoundController();
		}
		return SoundController.instance;
	}

	private sounds: PIXI.sound.Sound;
	private seasonMusic: PIXI.sound.Sound;
	private farewellPartyBgSound: PIXI.sound.Sound;
	private characterClickSounds: Map<string, CharacterClickSoundModel>;
	private farewellPartyHeartClickSounds: FarewellPartyHeartClickSoundModel;

	private soundsVolume: number;
	private musicVolume: number;

	private tweenMainBgVolme: TWEEN.Tween;

	private seasonNumberTheme: number;

	private season1Music: PIXI.sound.Sound;
	private season2Music: PIXI.sound.Sound;
	private season3Music: PIXI.sound.Sound;
	private season4Music: PIXI.sound.Sound;

	private currentBackgroundSound: 'farewell' | 'main';

	private constructor() {
		super();

		this.characterClickSounds = new Map();
		this.farewellPartyHeartClickSounds = new FarewellPartyHeartClickSoundModel();

		this.soundsVolume = 0.25;
		this.musicVolume = 0.1;

		document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
	}

	private onVisibilityChange(): void {
		if (document.hidden) {
			this.pauseBackgroundSoundsIfAny();
			if (this.sounds) {
				this.sounds.volume = 0;
			}
		} else {
			this.resumeBackgroundSoundIfAny();
			if (this.sounds) {
				this.sounds.volume = this.soundsVolume;
			}
		}
	}

	public async loadSounds(soundLoader: AssetLoader): Promise<void> {
		soundLoader
			.add('sounds.json', 'sounds.json')
			.add('sounds.mp3', 'sounds.mp3')
			.add('main_theme_1.wav', 'main_theme_1.wav')
			.add('main_theme_2.wav', 'main_theme_2.wav')
			.add('main_theme_3.wav', 'main_theme_3.wav')
			.add('main_theme_4.wav', 'main_theme_4.wav')
			.add('orgy_main_theme.mp3', 'orgy_main_theme.mp3')
			.load();

		soundLoader.on('complete', (loader, resources) => {
			this.emit(SoundController.EVENT_SOUNDS_LOADED);

			this.sounds = resources['sounds.mp3'].sound;
			this.sounds.addSprites(resources['sounds.json'].data);
			this.sounds.volume = this.soundsVolume;

			this.season1Music = resources['main_theme_1.wav'].sound;
			this.season1Music.volume = this.musicVolume;

			this.season2Music = resources['main_theme_2.wav'].sound;
			this.season2Music.volume = this.musicVolume;

			this.season3Music = resources['main_theme_3.wav'].sound;
			this.season3Music.volume = this.musicVolume;

			this.season4Music = resources['main_theme_4.wav'].sound;
			this.season4Music.volume = this.musicVolume;

			this.farewellPartyBgSound = resources['orgy_main_theme.mp3'].sound;
			this.farewellPartyBgSound.volume = this.musicVolume;
		});
	}

	private getCurSeasonMusic(currentSeason: number): PIXI.sound.Sound {
		// eslint-disable-next-line default-case
		switch (currentSeason) {
			case 1:
				return this.season1Music;
			case 2:
				return this.season2Music;
			case 3:
				return this.season3Music;
			case 4:
				return this.season4Music;
		}
		return this.season1Music;
	}

	public setSeasonNumberTheme(value: number): void {
		this.seasonNumberTheme = value;
	}

	public playMainBg(): void {
		this.pauseBackgroundSoundsIfAny();
		this.stopTweenMainBgVolumeIfAny();

		this.seasonMusic = this.getCurSeasonMusic(this.seasonNumberTheme);
		this.seasonMusic.loop = true;
		this.seasonMusic.volume = 0;
		this.tweenMainBgVolme = new TWEEN.Tween(this.seasonMusic)
			.to({ volume: this.musicVolume }, 1000)
			.onComplete(() => { this.tweenMainBgVolme = null; })
			.start();

		this.seasonMusic.play();
		this.currentBackgroundSound = 'main';
	}

	public playFarewellPartyBg(): void {
		this.pauseBackgroundSoundsIfAny();

		this.farewellPartyBgSound.loop = true;

		this.farewellPartyBgSound.play();
		this.currentBackgroundSound = 'farewell';
	}

	public pauseBackgroundSoundsIfAny(): void {
		if (this.seasonMusic?.isPlaying) {
			this.seasonMusic.pause();
		}
		if (this.farewellPartyBgSound?.isPlaying) {
			this.farewellPartyBgSound.pause();
		}
	}

	public resumeBackgroundSoundIfAny(): void {
		// eslint-disable-next-line default-case
		switch (this.currentBackgroundSound) {
			case 'farewell': {
				this.playFarewellPartyBg();
				break;
			}
			case 'main': {
				this.playMainBg();
				break;
			}
		}
	}

	private stopTweenMainBgVolumeIfAny(): void {
		if (this.tweenMainBgVolme) {
			this.tweenMainBgVolme.stop();
		}
	}

	public setMusicVolume(value: number): void {
		this.stopTweenMainBgVolumeIfAny();

		this.musicVolume = value;

		if (this.seasonMusic) {
			this.seasonMusic.volume = value;
		}
		if (this.farewellPartyBgSound) {
			this.farewellPartyBgSound.volume = value;
		}
	}

	public setSoundsVolume(value: number): void {
		this.soundsVolume = value;
		if (this.sounds) {
			this.sounds.volume = value;
		}
	}

	public getMainBgVolume(): number {
		return this.musicVolume;
	}

	public getSoundsVolume(): number {
		return this.soundsVolume;
	}

	// Buttons
	public playButtonClick(): void {
		this.play(SoundList.BUTTON_CLICK_DEFAULT);
	}

	public playButtonPointerOver(): void {
		this.play(SoundList.BUTTON_POINTER_OVER);
	}

	public playButtonBuyNewCustomers(): void {
		this.play(SoundList.BUTTON_CLICK_NEW_CUSTOMERS);
	}

	// Level
	public playNewQuest(): void {
		this.play(SoundList.QUEST_NEW);
	}

	public playQuestComplete(): void {
		this.play(SoundList.QUEST_COMPLETE);
	}

	public playLevelComplete(): void {
		this.play(SoundList.LEVEL_COMPLETE);
	}

	// Business
	public playBusinessOpen(): void {
		this.play(SoundList.BUSINESS_OPEN);
	}

	public playBusinessZoom(): void {
		this.play(SoundList.BUSINESS_ZOOM);
	}

	public playCharacterActivate(): void {
		this.play(SoundList.ACTIVATE_CHARACTER);
	}

	public playUpgradeActivate(): void {
		this.play(SoundList.ACTIVATE_UPGRADE);
	}

	public playSkillActivate(): void {
		this.play(SoundList.ACTIVATE_SKILL);
	}

	public playIncomeAppear(): void {
		this.play(SoundList.INCOME_APPEAR);
	}

	public playCollectIncome(): void {
		this.play(SoundList.COLLECT_INCOME);
	}

	public playCollectPrestigeBonus(): void {
		this.play(SoundList.COLLECT_PRESTIGE_BONUS);
	}

	public playDecorationShow(): void {
		this.play(SoundList.DECORATION_SHOW);
	}

	public playCharacterClick(characterKey: string, soundPackName: string): void {
		if (!this.characterClickSounds.has(characterKey)) {
			const soundsList = CharacterClickSoundList.get(soundPackName);

			this.characterClickSounds.set(characterKey, new CharacterClickSoundModel(soundsList));
		}

		const soundModel = this.characterClickSounds.get(characterKey);
		if (!soundModel.isPlaying()) {
			soundModel.setPlaying(true);

			this.play(
				soundModel.getNextSound(),
				() => {
					soundModel.setPlaying(false);
				},
			);
		}
	}

	public playBusinessClick(): void {
		this.play(SoundList.BUSINESS_BG_CLICK);
	}

	public playGemsFly(): void {
		this.play(SoundList.GEMS_FLY);
	}

	// Collection
	public playPromoteButton(): void {
		this.play(SoundList.PROMOTE_BUTTON);
	}

	public playEntityPromoted(): void {
		this.play(SoundList.ENTITY_PROMOTED);
	}

	public playTabChange(): void {
		this.play(SoundList.COLLECTION_TAB_CHANGE);
	}

	// Dialog
	public playDialogSwitch(): void {
		this.play(SoundList.DIALOG_NEXT_PHRASE);
	}

	public playDialogTextAppear(): void {
		this.play(SoundList.DIALOG_TEXT_APPEAR);
	}

	// Lootbox
	public playLootboxOpen(): void {
		this.play(SoundList.LOOTBOX_OPEN);
	}

	public playLootboxCardDrop(): void {
		this.play(SoundList.LOOTBOX_CARD_DROP);
	}

	public playLootboxRewardSummary(): void {
		this.play(SoundList.LOOTBOX_REWARD_SUMMARY);
	}

	// Cards
	public playCoupCard(): void {
		this.play(SoundList.LOOTBOX_COUP_CARD);
	}

	public playFlightCard(): void {
		this.play(SoundList.LOOTBOX_FLIGHT_CARD);
	}

	// Window
	public playOpenWindow(): void {
		this.play(SoundList.WINDOW_OPEN);
	}

	public playCloseWindow(): void {
		this.play(SoundList.WINDOW_CLOSE);
	}

	// Farewell party
	public playFarewellPartyHeartClick(): void {
		if (this.farewellPartyHeartClickSounds.isPlaying()) {
			return;
		}

		this.farewellPartyHeartClickSounds.setPlaying(true);
		this.play(
			this.farewellPartyHeartClickSounds.getNextSound(),
			() => { this.farewellPartyHeartClickSounds.setPlaying(false); },
		);
	}

	public playFarewellPartyHeartPop(): void {
		this.play(SoundList.FAREWELL_HEART_POP);
	}

	private play(key: string, callback?: PIXI.sound.CompleteCallback): PIXI.sound.IMediaInstance {
		if (key === undefined) {
			if (MODE_DEBUG) {
				throw new Error('Try play undefined sound.');
			}
			return null;
		}

		return this.sounds.play(key, callback) as PIXI.sound.IMediaInstance;
	}
}
