import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';
import { BusinessSaveData } from '@src/types/SaveTypes';

export class NewCustomersMultiplierFactory {
	private readonly saveData: LootboxNewCustomerMultiplierConfig[];
	private readonly businessesSaveData: Map<string, BusinessSaveData>;
	private readonly businessKeys: string[];

	constructor(
		saveData: LootboxNewCustomerMultiplierConfig[],
		businessKeys: string[],
		businessesSaveData: Map<string, BusinessSaveData>,
	) {
		this.saveData = saveData;
		this.businessesSaveData = businessesSaveData;
		this.businessKeys = businessKeys;
	}

	public createModels(): Map<string, NewCustomersMutiplierModel> {
		const result = new Map();
		this.businessKeys.forEach(key => {
			const model = new NewCustomersMutiplierModel(key);

			if (this.businessesSaveData.has(key)) {
				const businessSaveData = this.businessesSaveData.get(key);
				const rewards = this.saveData
					.filter(config => config.extra.source_id === key && config.extra.customers <= businessSaveData.customers);
				if (rewards.length > 0) {
					model.setFromSaveData(rewards);
				}
			}

			result.set(key, model);
		});
		return result;
	}
}
