import { LevelBaseConfig } from './LevelBaseConfig';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class EventLevelConfig extends LevelBaseConfig implements ConfigSetElement {
	public static readonly FIELD_QUEST_LINE_BASE_PREFIX: string = 'event_quest_line';
	public static readonly FIELD_EVENT_ID: string = 'event_id';
	public static readonly FIELD_DURATION: string = 'duration';
	public static readonly FIELD_LEAGUES: string = 'leagues';
	public static readonly FIELD_GOALS: string = 'goals';
	public static readonly FIELD_REDUCE_TIME_INCOME_BY_CLICK = 'reduce_time_income_by_click';

	private readonly key: string;
	private readonly duration: number;
	private readonly leagueKeys: string[];
	private readonly goals: SoftMoneyNumber[];
	private readonly reduceTimeIncomeByClick: number;

	public static parseQuestLineIndex(questLineKey: string): number {
		return LevelBaseConfig.parseQuestLineIndex(
			EventLevelConfig.FIELD_QUEST_LINE_BASE_PREFIX,
			questLineKey,
		);
	}

	constructor(config: { [key: string]: string }) {
		super(config);

		this.key = config[EventLevelConfig.FIELD_EVENT_ID].trim();

		this.goals = config[EventLevelConfig.FIELD_GOALS]
			.split(',')
			.map((str: string) => SoftMoneyNumber.createFromAcronym(str.trim()));

		this.duration = Number(config[EventLevelConfig.FIELD_DURATION].trim());
		this.leagueKeys = config[EventLevelConfig.FIELD_LEAGUES].split(',').map((str: string) => str.trim());
		this.reduceTimeIncomeByClick = Number(config[EventLevelConfig.FIELD_REDUCE_TIME_INCOME_BY_CLICK].trim());

		this.parseQuestLines(config);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getKeyQuestLine(i: number): string {
		return `${EventLevelConfig.FIELD_QUEST_LINE_BASE_PREFIX}${i}`;
	}

	public getKey(): string {
		return this.key;
	}

	public getDuration(): number {
		return this.duration;
	}

	public getLeagueKeys(): string[] {
		return this.leagueKeys;
	}

	public getGoals(): SoftMoneyNumber[] {
		return this.goals;
	}

	public getReduceTimeIncomeByClick(): number {
		return this.reduceTimeIncomeByClick;
	}
}
