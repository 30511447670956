import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SoundController } from '@src/main/SoundController';
import LocalizationStorage from '@main/LocalizationStorage';

export class SupportWindowTopicListView extends PIXI.Container {
	private static readonly LIST_ITEM_OFFSET: number = 50;

	private readonly localizationStorage: LocalizationStorage;

	private readonly soundController: SoundController;

	private readonly buttonArrow: ButtonBaseView;

	private readonly listContainer: PIXI.Container;
	private readonly currentTextTopic: PIXI.Text;

	private currentTextTopicId: number;

	constructor(topics: string[]) {
		super();

		this.soundController = SoundController.getInstance();
		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['login_input_field_bg'], 13, 0, 13, 0);
		bg.width = 620;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;
		bg.on('pointertap', () => {
			this.onButtonArrowClick();
			this.soundController.playButtonClick();
		}, this);

		const buttonArrowBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_arrow']);
		buttonArrowBg.rotation = -Math.PI / 2;

		this.buttonArrow = new ButtonBaseView(buttonArrowBg);
		this.buttonArrow.on(ButtonBaseView.EVENT_CLICK, this.onButtonArrowClick, this);
		this.buttonArrow.x = 280;

		this.listContainer = new PIXI.Container();

		const listBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['topics_dropdown_bg'], 22, 23, 22, 23);
		listBg.interactive = true;
		listBg.width = 644;
		listBg.pivot.set(listBg.width / 2, 0);

		this.currentTextTopic = new PIXI.Text('', {
			fontFamily: 'Verdana',
			fontSize: '19px',
			fontWeight: 'bold',
			fill: 0xFFFFFF,
		});
		this.currentTextTopic.x = -294;
		this.currentTextTopic.anchor.set(0, 0.5);

		// eslint-disable-next-line prefer-destructuring
		this.currentTextTopicId = 0;
		this.currentTextTopic.text = this.localizationStorage.getLocalizedString('#support_window_choose_topic');

		const buttonTextTopics: ButtonBaseView[] = [];
		topics.forEach((topic, i) => {
			const textTopic = new PIXI.Text(topic, {
				fontFamily: 'Verdana',
				fontSize: '19px',
				fontWeight: 'bold',
				fill: 0xFFFFFF,
			});
			textTopic.anchor.set(0, 0.5);

			const textTopicBg = new PIXI.Sprite();
			textTopicBg.hitArea = textTopic.getLocalBounds();

			const butttonTextTopic = new ButtonBaseView(textTopicBg);
			butttonTextTopic.addChild(textTopic);
			butttonTextTopic.on(ButtonBaseView.EVENT_CLICK, () => this.onTextTopicClick(topic, i), this);
			butttonTextTopic.position.set(-294, 70 + SupportWindowTopicListView.LIST_ITEM_OFFSET * i);

			buttonTextTopics.push(butttonTextTopic);
		});

		listBg.height = buttonTextTopics.length * (buttonTextTopics[0].height + 10) + 170;

		this.listContainer.addChild(
			listBg,
			...buttonTextTopics as PIXI.DisplayObject[],
		);
		this.listContainer.visible = false;

		this.addChild(
			this.listContainer,
			bg,
			this.buttonArrow as PIXI.DisplayObject,
			this.currentTextTopic,
		);
	}

	private onTextTopicClick(topic: string, i: number): void {
		this.listContainer.visible = false;
		this.currentTextTopic.text = topic;
		this.currentTextTopicId = i;
	}

	private onButtonArrowClick(): void {
		this.listContainer.visible = !this.listContainer.visible;
	}

	public setOpened(value: boolean): void {
		this.listContainer.visible = value;
	}

	public isOpened(): boolean {
		return this.listContainer.visible;
	}

	public getCurrentTextTopicId(): number {
		return this.currentTextTopicId;
	}
}
