import { BaseAction } from '../BaseAction'

export class TimeskipActivateAction extends BaseAction {
	constructor(key: string, amount: string) {
		super('TimeskipActivate');

		this.data.id = key;
		this.data.amount = amount;
	}
}
