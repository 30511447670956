import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { GameConstants } from '@src/utils/GameConstants';
import { BeginningCountdownView } from './BeginningCountdownView';
import { FarewellPartyProgressView } from './FarewellPartyProgressView';
import { FarewellPartyTimerView } from '@views/windows/farewellParty/FarewellPartyTimerView';
import * as TWEEN from '@tweenjs/tween.js';
import { WindowBaseView } from '@views/components/WindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { FarewellPartyLootboxView } from './FarewellPartyLootboxView';
import { FarewellPartyTapAnimation } from './FarewellPartyTapAnimation';
import { FarewellPartyLabelPerfectView } from './FarewellPartyLabelPerfectView';
import { AssetsStorage } from '@main/AssetsStorage';
import { FarewellPartyAimView } from '@views/windows/farewellParty/FarewellPartyAimView';
import { FarewellPartyEffectTrailsView } from '@views/windows/farewellParty/FarewellPartyEffectTrailsView';
import { AimPosition } from '@configs/FarewellPartyConfig';
import { SoundController } from '@src/main/SoundController';
import { FarewellPartyCompleteReward } from './FarewellPartyCompleteReward';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { LevelModel } from '@models/level/LevelModel';

enum AnimationSpeed {
	STATE_DEFAULT,
	STATE_1,
	STATE_2,
}

export class FarewellPartyWindowView extends WindowBaseView {
	public static readonly EVENT_TAP: symbol = Symbol();
	public static readonly EVENT_AIM_TRAILS_ANIMATION_COMPLETED: symbol = Symbol();
	public static readonly EVENT_STARTED: symbol = Symbol();
	public static readonly EVENT_COMPLETED: symbol = Symbol();
	public static readonly EVENT_START_TIMER_COMPLETED: symbol = Symbol();
	public static readonly EVENT_BAR_FILLED: symbol = Symbol();

	private static readonly TAP_DELAY_MS: number = 25;
	private static readonly TARGET_SHOW_AMOUNT: number = 3;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly localizationStorage: LocalizationStorage;
	private readonly model: FarewellPartyModel;

	private readonly tweenGroup: TWEEN.Group;

	private readonly lootboxIcon: FarewellPartyLootboxView;
	private readonly progressBar: FarewellPartyProgressView;
	private readonly partyTimer: FarewellPartyTimerView;
	private animation: PIXI.spine.Spine;
	private readonly blackoutFade: PIXI.Graphics;

	private readonly labelPerfect: FarewellPartyLabelPerfectView;

	private readonly animationListener: PIXI.spine.core.AnimationStateListener2;
	private currentAnimationSpeed: AnimationSpeed;
	private currentTapDelay: number;

	private lastTapMs: number;
	private isIdle: boolean;

	private aim: FarewellPartyAimView;
	private currentAimPosition: PIXI.Point;
	private showRewardsTimeoutId: number;
	private oldAnimation: PIXI.spine.Spine;
	private animationAimPositions: AimPosition[];
	private readonly farewellPartyEverStarted: boolean;
	private readonly questTargetDuration: number;
	private showTargetCounter: number;
	private showTarget: ShowQuestTargetView | null;
	private levelModel: LevelModel;

	constructor(
		model: FarewellPartyModel,
		questTargetDuration: number,
		farewellPartyEverStarted: boolean,
	) {
		super(0, false);

		this.ticker = PIXI.ticker.shared;
		this.localizationStorage = LocalizationStorage.getInstance();

		this.currentAnimationSpeed = AnimationSpeed.STATE_DEFAULT;
		this.currentTapDelay = 0;
		this.lastTapMs = 0;
		this.currentAimPosition = new PIXI.Point(0, 0);
		this.showTarget = null;
		this.showTargetCounter = FarewellPartyWindowView.TARGET_SHOW_AMOUNT;

		this.farewellPartyEverStarted = farewellPartyEverStarted;
		this.questTargetDuration = questTargetDuration;

		this.tweenGroup = new TWEEN.Group();
		this.ticker.add(this.updateTweens, this);

		this.model = model;
		this.model.on(FarewellPartyModel.EVENT_NEXT_REWARD_REACHED, this.onNextRewardReached, this);
		this.model.on(FarewellPartyModel.EVENT_PROGRESS, this.onProgress, this);

		this.animationListener = {
			complete: this.onAnimationLoopComplete.bind(this),
		};

		this.isIdle = true;
		this.animation = new PIXI.spine.Spine(AssetsStorage.getResource(this.model.getCurrentAnimationKey()).spineData);
		this.animation.state.setAnimation(0, 'idle', true);
		this.animation.state.addListener(this.animationListener);
		this.animation.position.set(GameConstants.GAME_CENTER_X + 40, GameConstants.GAME_CENTER_Y + 70);
		this.animation.on('pointertap', this.onTap, this);

		const shadow = new PIXI.Sprite(AssetsStorage.getAtlas('farewellPartyUiAtlas')['orgy_shadow']);
		shadow.anchor.set(0, 0);
		shadow.width = GameConstants.GAME_WIDTH;
		shadow.height = 202;

		this.labelPerfect = new FarewellPartyLabelPerfectView();
		this.labelPerfect.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y);
		this.labelPerfect.visible = false;

		this.partyTimer = new FarewellPartyTimerView(this.model.getFucktime());
		this.partyTimer.position.set(918, 35);
		this.partyTimer.once(FarewellPartyTimerView.EVENT_TIMEOUT, this.onPartyTimeout, this);

		this.lootboxIcon = new FarewellPartyLootboxView();
		this.lootboxIcon.position.set(1828, 79);

		this.progressBar = new FarewellPartyProgressView();
		this.progressBar.on(FarewellPartyProgressView.EVENT_TRAIL_ANIMATION_COMPLETED, this.onProgressBarTrailAnimationCompleted, this);
		this.progressBar.position.set(914, 91);

		this.progressBar.setNextMultiplier(this.model.getNextRewardBoostMultiplier());

		this.blackoutFade = new PIXI.Graphics();
		this.blackoutFade.beginFill(0x000000);
		this.blackoutFade.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
		this.blackoutFade.endFill();
		this.blackoutFade.alpha = 0;

		this.lootboxIcon.setRewardsCount(this.model.getCurrentRewardBoostMultiplier());

		this.addChild(
			this.animation,
			shadow,
			this.labelPerfect,
			this.blackoutFade,
			this.partyTimer,
			this.lootboxIcon,
			this.progressBar as PIXI.DisplayObject,
		);

		this.fillAnimationAimPositions();
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public onShown(): void {
		super.onShown();

		SoundController.getInstance().playFarewellPartyBg();
		this.startBeginningCountdown();

		this.emit(FarewellPartyWindowView.EVENT_STARTED, this.model);
	}

	protected onClose(): void {
		SoundController.getInstance().playMainBg();

		super.onClose();
	}

	private updateTweens(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private setTapEnabled(value: boolean): void {
		this.animation.interactive = value;
		if (this.aim) {
			this.aim.setTapEnabled(value);
		}
	}

	private onAnimationLoopComplete(): void {
		if (!this.isIdle && this.ticker.lastTime - this.lastTapMs > 1000) {
			this.animation.state.setAnimation(0, 'idle', true);
			this.animation.state.timeScale = 1;
			this.currentAnimationSpeed = AnimationSpeed.STATE_1;
			this.isIdle = true;
		}
	}

	private fillAnimationAimPositions(): void {
		this.animationAimPositions = this.model.getFarewellAnimationsAimPositions().positions.slice(0);
		do {
			this.animationAimPositions.sort(() => Math.random() - 0.5);
		} while (this.currentAimPosition.x === this.animationAimPositions[0].x && this.currentAimPosition.x === this.animationAimPositions[0].y);
	}

	private onTap(e: PIXI.interaction.InteractionEvent): void {
		this.setTapEnabled(false);

		this.playTapAnimation(e.data.global.x, e.data.global.y);

		this.currentTapDelay = FarewellPartyWindowView.TAP_DELAY_MS;
		this.ticker.add(this.onDelayTimerUpdate, this);
		this.lastTapMs = this.ticker.lastTime;

		if (this.isIdle) {
			this.animation.state.setAnimation(0, 'action', true);
			this.isIdle = false;
		}

		this.emit(FarewellPartyWindowView.EVENT_TAP);
	}

	private onAimTap(e: PIXI.interaction.InteractionEvent): void {
		this.removeAimTarget();
		this.onTap(e);
	}

	private onDelayTimerUpdate(): void {
		this.currentTapDelay -= this.ticker.elapsedMS;
		if (this.currentTapDelay <= 0) {
			this.ticker.remove(this.onDelayTimerUpdate, this);
			this.setTapEnabled(true);
		}
	}

	private onNextRewardReached(): void {
		this.progressBar.playCompleteAnimation();
		this.emit(FarewellPartyWindowView.EVENT_BAR_FILLED, this.model);
	}

	private onProgressBarTrailAnimationCompleted(): void {
		this.lootboxIcon.playUpdateAnimation();
		this.lootboxIcon.setRewardsCount(this.model.getCurrentRewardBoostMultiplier());
		const nextMultiplier: number | undefined = this.model.getNextRewardBoostMultiplier();
		if (nextMultiplier !== undefined) {
			this.progressBar.setNextMultiplier(nextMultiplier);
		}
	}

	private onProgress(): void {
		this.progressBar.startProgressAnimation(this.model.getCurrentProgressPercent());

		const progress = this.partyTimer.getTimeProgress();
		if (progress >= 0.66) {
			if (this.currentAnimationSpeed !== AnimationSpeed.STATE_2) {
				this.currentAnimationSpeed = AnimationSpeed.STATE_2;
				this.animation.state.timeScale = 3;
			}
		} else if (progress >= 0.33) {
			if (this.currentAnimationSpeed !== AnimationSpeed.STATE_1) {
				this.currentAnimationSpeed = AnimationSpeed.STATE_1;
				this.animation.state.timeScale = 2;
			}
		}
	}

	private startBeginningCountdown(): void {
		const countdown = new BeginningCountdownView();
		countdown.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y);
		countdown.on(BeginningCountdownView.EVENT_TIMEOUT, this.onBeginingCountdownTimeout, this);
		this.addChild(countdown);
		countdown.start();
	}

	private onBeginingCountdownTimeout(): void {
		this.partyTimer.start();
		this.createAim();
		this.setTapEnabled(true);
		this.emit(FarewellPartyWindowView.EVENT_START_TIMER_COMPLETED, this.model);
	}

	private onAimCompleted(): void {
		if (!this.aim) return;
		this.removeAim();
		this.createAimTrailsEffect(this.currentAimPosition.clone());
		this.createAim();
	}

	private removeAim(): void {
		this.aim.off(FarewellPartyAimView.EVENT_TAP, this.onAimTap, this);
		this.aim.off(FarewellPartyAimView.EVENT_COMPLETED, this.onAimCompleted, this);
		this.aim.startHideAnimation();
		this.aim = null;

		this.removeAimTarget();
	}

	private removeAimTarget(): void {
		if (this.showTarget !== null) {
			this.showTarget.destroy();
			this.showTarget = null;
		}
	}

	private createAimTrailsEffect(startPosition: PIXI.Point): void {
		const targetRect = this.progressBar.getBounds();
		targetRect.width *= 0.8;

		const trailEffects = new FarewellPartyEffectTrailsView(startPosition, targetRect, () => {
			this.emit(FarewellPartyWindowView.EVENT_AIM_TRAILS_ANIMATION_COMPLETED, this);
		});

		this.addChild(trailEffects);
	}

	private createAim(): void {
		const maxTaps = this.model.getTapsForAimDestroy();
		this.aim = new FarewellPartyAimView(maxTaps);

		const aimPosition = this.getNewAimPosition();
		this.aim.position.copy(aimPosition);
		this.addChild(this.aim);
		this.currentAimPosition = aimPosition;

		if (!this.farewellPartyEverStarted && this.showTargetCounter > 0) {
			this.showTarget = new ShowQuestTargetView(this.questTargetDuration, this.aim);
			this.addChild(this.showTarget);
			this.showTargetCounter -= 1;
		}

		this.aim.on(FarewellPartyAimView.EVENT_TAP, this.onAimTap, this);
		this.aim.on(FarewellPartyAimView.EVENT_COMPLETED, this.onAimCompleted, this);
	}

	private getNewAimPosition(): PIXI.Point {
		if (this.animationAimPositions.length === 0) {
			this.fillAnimationAimPositions();
		}
		const position = this.animationAimPositions.pop();
		return new PIXI.Point(position.x, position.y);
	}

	private onPartyTimeout(): void {
		if (this.currentTapDelay > 0) {
			this.ticker.remove(this.onDelayTimerUpdate, this);
		}

		this.setTapEnabled(false);
		this.removeAim();

		this.animation.state.removeListener(this.animationListener);

		if (!this.isIdle) {
			this.animation.state.timeScale = 1;
		}

		this.labelPerfect.visible = true;
		this.labelPerfect.playAppearingAnimation(250, 150);

		this.emit(FarewellPartyWindowView.EVENT_COMPLETED);

		this.showRewardsTimeoutId = window.setTimeout(this.showRewards.bind(this), 2000);
	}

	private showRewards(): void {
		this.showRewardsTimeoutId = null;
		this.labelPerfect.visible = false;

		const award = new FarewellPartyCompleteReward(this.model.getСurrentRewardBoostTime(), this.model.getCurrentRewardBoostMultiplier());
		award.on(FarewellPartyCompleteReward.EVENT_ANIMATION_END, () => {
			this.onClose();
		}, this);
		award.playOpen();
		this.addChild(award);
	}

	private playTapAnimation(globalX: number, globalY: number): void {
		const tapAnimation = new FarewellPartyTapAnimation();
		tapAnimation.once(FarewellPartyTapAnimation.EVENT_COMPLETED, () => {
			tapAnimation.destroy({ children: true });
		});
		tapAnimation.position.set(globalX, globalY);
		this.addChild(tapAnimation);
	}

	public getAimContainer(): PIXI.Container {
		return this.aim.getClickTarget();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.oldAnimation) {
			this.oldAnimation.destroy({ children: true });
			this.oldAnimation = null;
		}

		if (this.showRewardsTimeoutId) {
			window.clearTimeout(this.showRewardsTimeoutId);
			this.showRewardsTimeoutId = null;
		}

		if (this.aim) {
			this.aim.off(FarewellPartyAimView.EVENT_TAP, this.onAimTap, this);
			this.aim.off(FarewellPartyAimView.EVENT_COMPLETED, this.onAimCompleted, this);
		}

		if (this.currentTapDelay > 0) {
			this.ticker.remove(this.onDelayTimerUpdate, this);
		}

		this.model.off(FarewellPartyModel.EVENT_NEXT_REWARD_REACHED, this.onNextRewardReached, this);
		this.model.off(FarewellPartyModel.EVENT_PROGRESS, this.onProgress, this);

		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweens, this);

		super.destroy(options);
	}
}
