import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { TutorialStepUnlockTypeEventGoalCompletedConfig } from '@configs/tutorialSteps/TutorialStepUnlockTypeConfigs';

export class TutorialStepUnlockTypeEventGoalCompletedModel extends TutorialStepUnlockTypeBaseModel {
	constructor(
		private readonly config: TutorialStepUnlockTypeEventGoalCompletedConfig,
		private readonly eventLevelModel: EventLevelModel,
	) {
		super(config.getKey(), TutorialStepUnlockTypes.GOAL_COMPLETED);

		if (config.getEventKey() === eventLevelModel.getKey()) {
			this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS, this.tryUnlock, this);
		}
	}

	private tryUnlock(): void {
		const goalCount = this.config.getGoalCount();

		if (this.eventLevelModel.getCompletedGoalCount() >= goalCount) {
			this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS, this.tryUnlock, this);
			this.onUnlocked();
		}
	}

	public destroy(): void {
		this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS, this.tryUnlock, this);
		super.destroy();
	}
}
