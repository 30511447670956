import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ServerErrorCode } from '@src/types/NetworkTypes';

export class ErrorWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_SUPPORT_CLICK = Symbol();
	public static readonly EVENT_BUTTON_RELOAD_CLICK = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly blocking: boolean;

	private buttonSupportBg: PIXI.mesh.NineSlicePlane;
	private buttonActionBg: PIXI.mesh.NineSlicePlane;
	private errorCode?: number;

	constructor(
		redeemCode: string,
		defaultMsg: string,
		errorCode?: number,
	) {
		const blocking = errorCode !== ServerErrorCode.BANK_INVALID_TRANSACTION
			&& errorCode !== ServerErrorCode.BANK_INVALID_TRANSACTION_CARDPAY_1
			&& errorCode !== ServerErrorCode.BANK_INVALID_TRANSACTION_CARDPAY_2;

		super(0.9, !blocking, undefined, false);

		this.errorCode = errorCode;

		const languageUnformatted = window?.navigator?.language || 'en';
		const language: 'en' | 'ru' = languageUnformatted === 'ru' ? 'ru' : 'en';

		let msgFormatted: string;
		let stringTitle: string;

		switch (this.errorCode) {
			case ServerErrorCode.BANK_INVALID_TRANSACTION:
				msgFormatted = LocalizationStorage.getInstance().getLocalizedString('#error_purchase');
				break;
			case ServerErrorCode.ACCOUNT_WRONG_SESSION_ID:
				msgFormatted = LocalizationStorage.getInstance().getLocalizedString('#error_window_label_wrong_session');
				break;
			case ServerErrorCode.ACCOUNT_INVALID_SESSION:
				msgFormatted = LocalizationStorage.getInstance().getLocalizedString('#error_window_label_invalid_session');
				break;
			case ServerErrorCode.ACCOUNT_NOT_FOUND_SESSION_ID:
				msgFormatted = LocalizationStorage.getInstance().getLocalizedString('#error_window_label_not_found_session');
				stringTitle = LocalizationStorage.getInstance().getLocalizedString('#errorWindow_title_return');
				break;
			case ServerErrorCode.MAINTENANCE:
				msgFormatted = language === 'en'
					? 'SORRY, WE HAVE A MAINTENANCE BREAK\nPLEASE TRY AGAIN LATER'
					: 'ИЗВИНИТЕ, У НАС ТЕХРАБОТЫ\nПОЖАЛУЙСТА, ЗАЙДИТЕ ПОЗЖЕ';
				stringTitle = language === 'en'
					? 'MAINTENANCE'
					: 'ТЕХРАБОТЫ';
				break;
			case ServerErrorCode.OUTDATED_VERSION:
				msgFormatted = language === 'en'
					? 'You have an outdated version - restart the game'
					: 'У вас старая версия - перезагрузите игру';
				stringTitle = language === 'en'
					? 'Outdated version'
					: 'Старая версия';
				break;
			default:
				msgFormatted = defaultMsg;
				break;
		}

		const showSupportButton = this.errorCode !== ServerErrorCode.ACCOUNT_NOT_FOUND_SESSION_ID
			&& this.errorCode !== ServerErrorCode.ACCOUNT_INVALID_SESSION
			&& this.errorCode !== ServerErrorCode.ACCOUNT_WRONG_SESSION_ID
			&& this.errorCode !== ServerErrorCode.MAINTENANCE
			&& this.errorCode !== ServerErrorCode.OUTDATED_VERSION;

		this.blocking = blocking;
		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getResource('preloader_u_window_bg_1').texture, 13, 13, 13, 13);
		bg.width = 781;
		bg.height = 679;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const bgWhite = new PIXI.mesh.NineSlicePlane(AssetsStorage.getResource('preloader_u_window_bg_2').texture, 4, 4, 4, 4);
		bgWhite.width = 655;
		bgWhite.height = 355;
		bgWhite.pivot.set(bgWhite.width / 2, bgWhite.height / 2);
		bgWhite.y = -50;

		const bgGray = new PIXI.Sprite(AssetsStorage.getResource('preloader_b_solid').texture);
		bgGray.pivot.set(bgGray.width / 2, bgGray.height / 2);
		bgGray.width = 640;
		bgGray.height = 121;
		bgGray.tint = 0xf0f0f0;
		bgGray.y = 59;

		let stringTextTitle: string;
		if (stringTitle) {
			stringTextTitle = stringTitle;
		} else {
			stringTextTitle = this.localizationStorage.getLocalizedString('#errorWindow_title');
		}
		const textTitle = new SizeableBitmapText(stringTextTitle, 750, { font: '40px wendyOneShadowBold' });
		textTitle.anchor = 0.5;
		textTitle.y = -285;

		const textRedeemCode = new PIXI.extras.BitmapText(
			`ID: ${redeemCode}`,
			{ font: '34px wendyOneShadowBold' },
		);
		textRedeemCode.anchor = 0.5;
		textRedeemCode.position.set(-850, -505);

		const textRedeemCodeBg = new PIXI.Graphics();
		textRedeemCodeBg.beginFill(0x000000);
		textRedeemCodeBg.drawPolygon([
			new PIXI.Point(0, 0),
			new PIXI.Point(textRedeemCode.width + 25, 0),
			new PIXI.Point(textRedeemCode.width + 15 + 25, -textRedeemCode.height - 30),
			new PIXI.Point(0, -textRedeemCode.height - 30),
		]);
		textRedeemCodeBg.endFill();
		textRedeemCodeBg.pivot.set(textRedeemCodeBg.width / 2, 0);
		textRedeemCodeBg.position.set(-850, -470);

		const textError = new TextField(
			msgFormatted,
			{ font: '24px wendyOne', tint: 0x465768, align: 'center' },
			600,
			100,
		);
		textError.y = 60;
		textError.anchor = 0.5;

		const icon = new PIXI.Sprite(AssetsStorage.getResource('preloader_error_icon_main').texture);
		icon.pivot.set(icon.width / 2, icon.height / 2);
		icon.y = -115;

		this.mainContainer.addChild(
			bg,
			bgWhite,
			bgGray,
			textTitle as PIXI.DisplayObject,
			textError,
			icon,
		);

		let buttonActionLabel: string;
		let buttonSupportLabel: string;

		if (!this.localizationStorage.hasLocalizedString('#errorWindow_restart_label')) {
			buttonActionLabel = language === 'en'
				? 'RESTART'
				: 'ПЕРЕЗАГРУЗИТЬ';
		}

		if (!this.localizationStorage.hasLocalizedString('#errorWindow_support_label')) {
			buttonSupportLabel = language === 'en'
				? 'SUPPORT'
				: 'ТЕХ. ПОДДЕРЖКА';
		}

		if (![ServerErrorCode.MAINTENANCE, ServerErrorCode.OUTDATED_VERSION].includes(this.errorCode)) {
			this.mainContainer.addChild(
				textRedeemCode,
				textRedeemCodeBg as PIXI.DisplayObject,
			);
		}

		const buttonAction = this.createButtonAction(buttonActionLabel);
		this.mainContainer.addChild(buttonAction);

		if (showSupportButton) {
			const buttonSupport = this.createButtonSupport(buttonSupportLabel);
			buttonSupport.position.set(-171, 230);
			buttonAction.position.set(171, 230);

			this.mainContainer.addChild(buttonSupport);
		} else {
			buttonAction.position.set(0, 230);
		}
	}

	private createButtonSupport(textLabel?: string): ButtonBaseView {
		this.buttonSupportBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getResource('preloader_button_gold').texture, 11, 0, 11, 0);
		this.buttonSupportBg.width = 313;
		this.buttonSupportBg.height = 95;
		this.buttonSupportBg.pivot.set(this.buttonSupportBg.width / 2, this.buttonSupportBg.height / 2);

		const supportLabel = new PIXI.extras.BitmapText(
			textLabel || this.localizationStorage.getLocalizedString('#errorWindow_support_label'),
			{ font: '32px wendyOneShadowBold' },
		);
		supportLabel.anchor = 0.5;

		const buttonSupport = new ButtonBaseView(
			this.buttonSupportBg,
			undefined,
			undefined,
			false,
		);
		buttonSupport.addChild(supportLabel);
		buttonSupport.on(ButtonWithLabelBaseView.EVENT_CLICK, () => {
			this.emit(ErrorWindowView.EVENT_BUTTON_SUPPORT_CLICK);
		});

		return buttonSupport;
	}

	private createButtonAction(textLabel?: string): ButtonBaseView {
		this.buttonActionBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getResource('preloader_button_green').texture, 11, 0, 11, 0);
		this.buttonActionBg.width = 313;
		this.buttonActionBg.height = 95;
		this.buttonActionBg.pivot.set(this.buttonActionBg.width / 2, this.buttonActionBg.height / 2);

		const actionLabel = new PIXI.extras.BitmapText(
			textLabel || this.localizationStorage.getLocalizedString('#errorWindow_support_label'),
			{ font: '32px wendyOneShadowBold' },
		);
		actionLabel.anchor = 0.5;

		const buttonAction = new ButtonBaseView(
			this.buttonActionBg,
			undefined,
			undefined,
			false,
		);
		buttonAction.addChild(actionLabel);

		if (this.blocking) {
			const stringLabel = textLabel || this.localizationStorage.getLocalizedString('#errorWindow_restart_label');

			actionLabel.text = stringLabel;

			buttonAction.once(ButtonWithLabelBaseView.EVENT_CLICK, () => {
				this.emit(ErrorWindowView.EVENT_BUTTON_RELOAD_CLICK);
			});
		} else if (ServerErrorCode.BANK_INVALID_TRANSACTION) {
			const stringLabel = textLabel || this.localizationStorage.getLocalizedString('#errorWindow_change_label');
			actionLabel.text = stringLabel;
			buttonAction.once(ButtonWithLabelBaseView.EVENT_CLICK, this.onClose, this);
		} else {
			const stringLabel = textLabel || this.localizationStorage.getLocalizedString('#bank_close_result_reward_button');
			actionLabel.text = stringLabel;
			buttonAction.once(ButtonWithLabelBaseView.EVENT_CLICK, this.onClose, this);
		}

		return buttonAction;
	}
}
