import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { GalleryVideoOpenView } from '@views/windows/collection/gallery/GalleryVideoOpenView';
import { GalleryVideoLockView } from '@views/windows/collection/gallery/GalleryVideoLockView';
import { AbstractCollectionItemView } from '@views/windows/collection/AbstractCollectionItemView';

export class GalleryVideoView extends AbstractCollectionItemView {
	private readonly model: GalleryVideoModel;

	private lockView: GalleryVideoLockView;
	protected openView: GalleryVideoOpenView;

	constructor(model: GalleryVideoModel) {
		super();

		this.model = model;

		if (model.isOpened()) {
			this.onOpened();
		} else {
			this.model.once(GalleryVideoModel.EVENT_OPENED, this.onOpened, this);
			this.lockView = new GalleryVideoLockView(this.model.getLevelId());
			this.addChild(this.lockView);
		}
	}

	protected showOpened(): void {
		this.openView = new GalleryVideoOpenView(this.model);
		this.addChild(this.openView);

		if (this.lockView) {
			this.removeChild(this.lockView);
			this.lockView = null;
		}

		this.openView.on(GalleryVideoOpenView.EVENT_CLICK, this.onOpenCardClick, this);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.model.listeners(GalleryVideoModel.EVENT_OPENED).includes(this.onOpened)) {
			this.model.off(GalleryVideoModel.EVENT_OPENED, this.onOpened, this, true);
		}
		super.destroy(options);
	}
}
