import { BaseIncomeMoneyAnimationView, IncomeMoneySprite } from '@views/ui/incomeMoney/BaseIncomeMoneyAnimationView';
import { AssetsStorage } from '@main/AssetsStorage';

export class PrestigeMoneyIncomeAnimationView extends BaseIncomeMoneyAnimationView {
	// eslint-disable-next-line class-methods-use-this
	protected createSprite(): IncomeMoneySprite {
		const sprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_prestige']);
		sprite.scale.set(0.8);
		return sprite;
	}
}
