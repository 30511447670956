/* eslint-disable no-console */
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestHeaders } from '@src/network/NetworkRequestHeaders';
import { CheatModel } from '@models/CheatModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import LocalizationStorage from '@main/LocalizationStorage';
import { QuestWindowViewMode } from '@views/windows/quests/QuestWindowBaseView';
import { DailyRewardLoader } from '@src/loaders/DailyRewardLoader';

export class CheatWindowSystemViewController implements CheatWindowViewSetter {
	private readonly localizationStorage: LocalizationStorage;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly cheatModel: CheatModel,
		private readonly summonModelsPool?: SummonModelsPool,
		private readonly dailyRewardLoader?: DailyRewardLoader,
	) {
		this.localizationStorage = LocalizationStorage.getInstance();
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_SYSTEM_GO_TO_PARTY, this.onSystemGoToParty, this);
		view.on(CheatWindowView.EVENT_SYSTEM_PRESET_PROFILE, this.onSystemPresetProfileServer, this);
		view.on(CheatWindowView.EVENT_SYSTEM_SET_LEVEL_START_TIME, this.onSystemSetLevelStartTime, this);
		view.on(CheatWindowView.EVENT_SYSTEM_RESET_FAREWELL_PARTY, this.onSystemResetFarewellParty, this);
		view.on(CheatWindowView.EVENT_SYSTEM_CLEAR_LOCAL_STORAGE, this.onSystemClearLocalStorage, this);
		view.on(CheatWindowView.EVENT_SYSTEM_CLEAR_LOGIN_ID, this.onSystemClearLoginId, this);
		view.on(CheatWindowView.EVENT_SYSTEM_UNLINK_EMAIL, this.onSystemUnlinkEmail, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_MAIN_WINDOW_CONFIG, this.onSystemToggleMainWindowConfig, this);
		view.on(CheatWindowView.EVENT_SYSTEM_SET_ENV, this.onSystemSetEnv, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_LOCALE_KEYS, this.onSystemToggleLocaleKeys, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_QUEST_ALWAYS_COLLECT, this.onSystemToggleQuestAlwaysCollect, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_ANALYTICS, this.onSystemToggleAnalytics, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_PRELOADER_LOGO, this.onSystemTogglePreloaderLogo, this);
		view.on(CheatWindowView.EVENT_SYSTEM_TOGGLE_MODEST_LANDING, this.onSystemToggleModestLanding, this);
		view.on(CheatWindowView.EVENT_SYSTEM_RESET_CARDS, this.onSystemResetCardsServer, this);
		view.on(CheatWindowView.EVENT_SYSTEM_SKIP_NOVICE_EVENT, this.onSystemSkipNoviceEvent, this);
		view.on(CheatWindowView.EVENT_SYSTEM_REFRESH_FREE_SUMMON, this.onSystemRefreshFreeSummon, this);
		view.on(CheatWindowView.EVENT_SYSTEM_REFRESH_DAILY_REWARD, this.onSystemRefreshDailyReward, this);
	}

	private onSystemGoToParty(): void {
		console.log('Going to party server...');
		this.networkRequestSender.sendCheatGotoParty().then(() => {
			console.log('Going to party server OK');
		});
	}

	private onSystemPresetProfileServer(value: string): void {
		console.log('Setting preset profile server...');
		const level = Number(value);
		this.networkRequestSender.sendCheatSetPresetProfile(level).then(() => {
			console.log('Setting preset profile server OK');
		});
	}

	private onSystemSetLevelStartTime(value: string): void {
		console.log('Setting level start time...');
		const levelStartTime: number = Number(value);
		this.networkRequestSender.sendCheatSetLevelStartTime(levelStartTime).then(() => {
			console.log('Setting level start time OK');
		});
	}

	private onSystemResetFarewellParty(): void {
		console.log('Reset farewell party...');
		this.networkRequestSender.sendCheatResetFarewellParty().then(() => {
			console.log('Reseting farewell party OK');
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private onSystemClearLocalStorage(): void {
		localStorage.clear();
		window.location.reload();
	}

	// eslint-disable-next-line class-methods-use-this
	private onSystemClearLoginId(): void {
		localStorage.removeItem(NetworkRequestHeaders.PLAYER_ID_KEY);
	}

	private onSystemUnlinkEmail(): void {
		console.log('Unlinking email server...');
		this.networkRequestSender.sendCheatUnlinkEmail().then(() => {
			console.log('Unlinking email server OK');
		});
	}

	private onSystemToggleMainWindowConfig(): void {
		this.cheatModel.setMainWindowConfigEnabled(!this.cheatModel.isMainWindowConfigEnabled());
	}

	private onSystemSetEnv(value: string): void {
		this.cheatModel.setEnv(value);
	}

	private onSystemToggleLocaleKeys(): void {
		this.localizationStorage.toggleShowLocalizationKeys();
	}

	private onSystemToggleQuestAlwaysCollect(): void {
		const mode = this.cheatModel.getQuestWindowMode() === QuestWindowViewMode.ALWAYS_COLLECT
			? QuestWindowViewMode.DEFAULT : QuestWindowViewMode.ALWAYS_COLLECT;
		this.cheatModel.setQuestWindowMode(mode);
	}

	private onSystemToggleAnalytics(): void {
		this.cheatModel.setAnalyticsEnabled(!this.cheatModel.isAnalyticsEnabled());
		window.location.reload();
	}

	private onSystemTogglePreloaderLogo(): void {
		this.cheatModel.setPreloaderLogoVisible(!this.cheatModel.isPreloaderLogoEnabled());
	}

	private onSystemToggleModestLanding(): void {
		this.cheatModel.setLandingModest(!this.cheatModel.isLandingModest());
	}

	private onSystemResetCardsServer(): void {
		console.log('Resetting cards server...');
		this.networkRequestSender.sendCheatResetCards().then(() => {
			console.log('Resetting cards server OK');
		});
	}

	private onSystemSkipNoviceEvent(): void {
		console.log('Skipping novice event...');
		this.networkRequestSender.sendCheatSkipNoviceEvent().then(() => {
			console.log('Skipping novice event OK');
			this.cheatModel.setNoviceEventEnabled(!this.cheatModel.isNoviceEventEnabled());
		});
	}

	private async onSystemRefreshFreeSummon(): Promise<void> {
		console.log('Refreshing free summon...');

		await this.networkRequestSender.sendCheatRefreshFreeSummon();

		this.summonModelsPool?.getModelsList()
			.filter((model) => model.isFreePrice())
			.forEach((model) => {
				model.setFreePriceAvailableTime(0);
				model.setFreePriceAvailable(true);
			});

		console.log('Refreshing free summon OK');
	}

	private async onSystemRefreshDailyReward(): Promise<void> {
		console.log('Refreshing daily reward...');

		await this.networkRequestSender.sendCheatRefreshDailyReward();
		await this.dailyRewardLoader?.updateDailyReward();

		console.log('Refreshing daily reward OK');
	}
}
