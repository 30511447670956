/* eslint-disable no-console */
import { TotemModel } from '@models/TotemModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowTotemsViewController implements CheatWindowViewSetter {
	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly totemModels: Map<string, TotemModel>,
	) {}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_TOTEM_OPEN, this.onTotemOpen, this);
		view.on(CheatWindowView.EVENT_TOTEM_OPEN_ALL, this.onTotemOpenAll, this);
		view.on(CheatWindowView.EVENT_TOTEM_ADD_CARDS_TO_OPENED, this.onTotemAddCardsToOpened, this);
	}

	private onTotemOpen(key: string): void {
		const model = this.totemModels.get(key);
		model.open();

		console.log(`Opening '${key}' server...`);
		this.networkRequestSender.sendCheatOpen(key, 'totems').then(() => {
			console.log(`Opening '${key}' server OK`);
		});
	}

	private onTotemOpenAll(): void {
		Array.from(this.totemModels.values())
			.filter(m => !m.isOpened())
			.forEach(m => m.open());

		console.log('Opening totems server...');
		this.networkRequestSender.sendCheatOpenAll('totems').then(() => {
			console.log('Opening totems server OK');
		});
	}

	private onTotemAddCardsToOpened(value: string): void {
		const amount = Number(value);
		this.totemModels.forEach(model => {
			if (model.isOpened()) {
				model.addCards(amount);
			}
		});

		console.log('Adding cards to opened totems server...');
		this.networkRequestSender.sendCheatAddCardsToOpened(amount, 'totems').then(() => {
			console.log('Adding cards to opened totems server OK');
		});
	}
}
