import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter255001View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255001View';
import { EventCharacter255002View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255002View';
import { EventCharacter255003View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255003View';

export class EventBusiness255001View extends BusinessBaseView {
	constructor() {
		const eventCharacter255001View = new EventCharacter255001View();
		const eventCharacter255002View = new EventCharacter255002View();
		const eventCharacter255003View = new EventCharacter255003View();
		eventCharacter255003View.once(EventCharacter255003View.EVENT_SHOWN, () => eventCharacter255001View.destroy());

		super('business255001', [eventCharacter255001View, eventCharacter255002View, eventCharacter255003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter255001View as PIXI.DisplayObject,
			eventCharacter255002View,
			eventCharacter255003View,
		);
	}
}
