import { AssetsStorage } from '@src/main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class EventGoalProgressBarView extends PIXI.Container {
	private static readonly PROGRESS_BAR_WIDTH: number = 303;

	private readonly progressBar: PIXI.mesh.NineSlicePlane;
	private readonly progressSeparators: PIXI.Container;

	private progressSeparatorWidth: number;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	constructor() {
		super();

		const incomeProgressBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_step_bar_bg'], 2, 0, 4, 0);
		incomeProgressBg.width = 309;
		incomeProgressBg.pivot.set(incomeProgressBg.width / 2, incomeProgressBg.height / 2);

		this.progressBar = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_green_step_bar'], 0, 0, 3, 0);
		this.progressBar.pivot.set(0, this.progressBar.height / 2);
		this.progressBar.x = -incomeProgressBg.width / 2 + 3;

		this.progressSeparators = new PIXI.Container();
		this.progressSeparators.x = this.progressBar.x;

		this.addChild(
			incomeProgressBg,
			this.progressBar,
			this.progressSeparators,
		);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setTotalProgress(value: number): void {
		this.progressSeparators.removeChildren();

		this.progressSeparatorWidth = EventGoalProgressBarView.PROGRESS_BAR_WIDTH / value;
		for (let i = 1; i < value; i++) {
			const progressSeparator = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['event_lvl_ended_element']);
			progressSeparator.x = this.progressSeparatorWidth * i;
			this.progressSeparators.addChild(progressSeparator);
		}
	}

	public setProgress(current: number, playProgressAnimation: boolean): void {
		this.progressBar.width = this.progressSeparatorWidth * current;

		if (playProgressAnimation) {
			this.playAnimation();
		}
	}

	private playAnimation(): void {
		const sprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['progres_bar_main_ui']);
		sprite.scale.set(0.5);
		sprite.tint = 0xFFBE00;
		sprite.blendMode = PIXI.BLEND_MODES.ADD;
		sprite.anchor.set(1, 0.5);
		// sprite.position.set(this.progressBar.x, this.progressBar.x + this.progressBar.height / 2);
		this.addChild(sprite);

		new TWEEN.Tween(sprite.position, this.tweenGroup)
			.to({ x: this.progressBar.x + this.progressBar.width + 10 }, 500)
			.easing(TWEEN.Easing.Quadratic.Out)
			.chain(
				new TWEEN.Tween(sprite, this.tweenGroup)
					.to({ alpha: 0 }, 300)
					.easing(TWEEN.Easing.Cubic.Out),
			)
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		super.destroy(options);
	}
}
