import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { LootboxServerConfig, LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';
import {
	BusinessSaveData,
	CharacterSaveData,
	BoostSaveData,
	TimeskipSaveData,
	QuestInitData,
	UpgradeSaveData,
	TotemSaveData,
	TutorialStepsSaveData,
	SkillSaveData,
	SettingsSaveData,
	TimedQuestInitData,
	UserDataSaveData,
	EventLevelInitData,
	FarewellBonusSaveData,
	TransactionSaveData,
	BankSaveData,
	BankPaymentSaveData,
	SummonSaveData,
	NewsData,
} from '@src/types/SaveTypes';
import { LevelConfig } from '@configs/level/LevelConfig';
import { EventLevelConfig } from '@configs/level/EventLevelConfig';
import { SavesConfigParser } from '../configsParsers/SavesConfigParser';
import { TrackingData } from '@src/types/AnalyticsTypes';

export class SavesConfig {
	private level: number;

	private questsProgress: number;

	private softMoney: SoftMoneyNumber;
	private prestigeMoney: number;
	private hardMoney: number;

	private businessesSave: Map<string, BusinessSaveData>;
	private charactersSave: Map<string, CharacterSaveData>;
	private boostsSave: Map<string, BoostSaveData>;
	private timeskipSave: Map<string, TimeskipSaveData>;
	private gameQuestsInitData: QuestInitData[];
	private timedQuestInitData: TimedQuestInitData;
	private upgradesSave: Map<string, UpgradeSaveData>;
	private totemsSave: Map<string, TotemSaveData>;
	private galleryVideosSave: Map<string, string>;
	private tutorialStepsSave: TutorialStepsSaveData;
	private eventTutorialStepsSave: TutorialStepsSaveData;
	private skillsSave: Map<string, SkillSaveData>;
	private levelBankSaves: BankSaveData;
	private levelSummonSaves: SummonSaveData;
	private eventLevelSummonSaves: SummonSaveData;
	private paymentSave: BankPaymentSaveData;
	private eventLevelBankSaves: BankSaveData;
	private epicQuestCollectionsSave: Map<string, number[]>;
	private transactionsSaveData: TransactionSaveData[];
	private newCustomersMultiplierRewards: LootboxNewCustomerMultiplierConfig[];
	private compensationLootboxConfigs: LootboxServerConfig[];

	private levelFarewellBoost: FarewellBonusSaveData | null;
	private levelNextFarewellPartyTime: number;
	private eventNextFarewellPartyTime: number;
	private userData: UserDataSaveData;

	private noviceEventLevelCompletedOn: number;
	private eventFarewellBoost: FarewellBonusSaveData | null;
	private eventLevelSaveData: EventLevelInitData;
	private eventLevelSoftMoney: string;
	private eventLevelPrestigeMoney: string;
	private eventLevelQuestsInitData: QuestInitData[];
	private eventLevelQuestsProgress: number;
	private eventLevelSkillsSave: Map<string, SkillSaveData>;
	private eventLevelBoostSave: Map<string, BoostSaveData>;
	private eventLevelTimeskipSave: Map<string, TimeskipSaveData>;
	private eventLevelNewCustomersMultiplierRewards: LootboxNewCustomerMultiplierConfig[];
	private eventTransactionsSaveData: TransactionSaveData[];
	private eventCompensationLootboxConfigs: LootboxServerConfig[];

	private createdOn: number;

	private updatesRevision: number;
	private redeemCode: string;
	private email: string;
	private utmParams?: TrackingData;
	private newsData: NewsData;

	public init(savesData: { [key: string]: any }): void {
		if (MODE_DEBUG) {
			// eslint-disable-next-line no-console
			console.dir(savesData);
		}

		this.createdOn = Number(savesData['created']);

		this.updatesRevision = savesData['revision'];

		this.level = savesData['cur_level'];

		this.questsProgress = savesData['level_quests_completed'];

		this.redeemCode = savesData['redeem_code'];
		this.email = savesData['email'];

		// eslint-disable-next-line prefer-destructuring
		const resources = savesData['resources'];
		this.softMoney = SoftMoneyNumber.createFromString(resources['soft_money']);
		this.prestigeMoney = parseInt(resources['prestige_money'], 10);
		this.hardMoney = parseInt(resources['hard_money'], 10);
		this.newCustomersMultiplierRewards = savesData['new_customer_multiplier_rewards'];

		this.tutorialStepsSave = SavesConfigParser.parseTutorialSteps(savesData['tutorial_steps']);
		this.eventTutorialStepsSave = SavesConfigParser.parseTutorialSteps(savesData['event_tutorial_steps']);

		this.businessesSave = SavesConfigParser.parseBusinesses(savesData['businesses']);
		this.charactersSave = SavesConfigParser.parseCharacters(savesData['characters'], savesData['user_data']);
		this.userData = SavesConfigParser.parseUserData(savesData['user_data']);
		this.gameQuestsInitData = SavesConfigParser.parseQuests(savesData['quests'], LevelConfig.parseQuestLineIndex);
		this.eventLevelQuestsInitData = SavesConfigParser.parseQuests(savesData['quests'], EventLevelConfig.parseQuestLineIndex);
		this.timedQuestInitData = SavesConfigParser.parseTimedQuests(savesData['side_quest']);
		this.upgradesSave = SavesConfigParser.parseUpgrades(savesData['upgrades']);
		this.totemsSave = SavesConfigParser.parseTotems(savesData['totems']);
		this.galleryVideosSave = SavesConfigParser.parseGallery(savesData['gallery_items']);
		this.boostsSave = SavesConfigParser.parseBoosts(savesData['boosts_data']);
		this.timeskipSave = SavesConfigParser.parseTimeskips(savesData['timeskips_data']);
		this.skillsSave = SavesConfigParser.parseSkills(savesData['skills_data']);
		this.epicQuestCollectionsSave = SavesConfigParser.parseEpicQuestCollectionsSave(savesData['epic_quests']);

		this.levelBankSaves = SavesConfigParser.parseLevelBank(savesData['bank_data']);
		this.eventLevelBankSaves = SavesConfigParser.parseEventLevelBank(savesData['bank_data']);

		this.levelSummonSaves = SavesConfigParser.parseSummon(savesData['bank_data']?.['summon_data']);
		this.eventLevelSummonSaves = SavesConfigParser.parseSummon(savesData['bank_data']?.['event_summon_data']);

		this.paymentSave = SavesConfigParser.parsePayment(savesData['bank_data']);

		this.levelFarewellBoost = SavesConfigParser.parseFarewellBoost(savesData['farewell_data']);
		this.eventFarewellBoost = SavesConfigParser.parseFarewellBoost(savesData['event_farewell_data']);
		this.levelNextFarewellPartyTime = SavesConfigParser.parseNextFarewellPartyTime(savesData['farewell_data']);
		this.eventNextFarewellPartyTime = SavesConfigParser.parseNextFarewellPartyTime(savesData['event_farewell_data']);
		this.transactionsSaveData = SavesConfigParser.parseTransactions(savesData['transactions']);
		this.compensationLootboxConfigs = savesData['compensation'];

		this.eventLevelSoftMoney = resources['event_soft_money'];
		this.eventLevelPrestigeMoney = resources['event_prestige_money'];
		this.eventLevelQuestsProgress = savesData['event_quests_completed'];
		this.noviceEventLevelCompletedOn = SavesConfigParser.parseNoviceEventCompletedOn(savesData['novice_event_completed_on']);
		this.eventLevelBoostSave = SavesConfigParser.parseBoosts(savesData['event_boosts_data']);
		this.eventLevelSkillsSave = SavesConfigParser.parseSkills(savesData['event_skills_data']);
		this.eventLevelTimeskipSave = SavesConfigParser.parseTimeskips(savesData['event_timeskips_data']);
		this.eventLevelSaveData = SavesConfigParser.parseEventData(savesData['event_data']);
		this.eventTransactionsSaveData = SavesConfigParser.parseTransactions(savesData['event_transactions']);
		this.eventLevelNewCustomersMultiplierRewards = savesData['event_new_customer_multiplier_rewards'];
		this.eventCompensationLootboxConfigs = savesData['event_compensation'];

		this.utmParams = SavesConfigParser.parseUtmParams(savesData['utm_params']);
		this.newsData = SavesConfigParser.parseNewsData(savesData['news_data']);
	}

	public getCreatedOn(): number {
		return this.createdOn;
	}

	public getLevel(): number {
		return this.level;
	}

	public getQuestsProgress(): number {
		return this.questsProgress;
	}

	public getQuestsInitData(): QuestInitData[] {
		return this.gameQuestsInitData;
	}

	public hasTimedQuestInitData(): boolean {
		return Boolean(this.timedQuestInitData);
	}

	public getTimedQuestInitData(): TimedQuestInitData | null {
		return this.timedQuestInitData;
	}

	public getBusinessesSaveData(): Map<string, BusinessSaveData> {
		return this.businessesSave;
	}

	public getCharactersSaveData(): Map<string, CharacterSaveData> {
		return this.charactersSave;
	}

	public getUpgradesSaveData(): Map<string, UpgradeSaveData> {
		return this.upgradesSave;
	}

	public getTotemsSaveData(): Map<string, TotemSaveData> {
		return this.totemsSave;
	}

	public getGalleryVideosSave(): Map<string, string> {
		return this.galleryVideosSave;
	}

	public getBoostsSaveData(): Map<string, BoostSaveData> {
		return this.boostsSave;
	}

	public getTimeskipsSaveData(): Map<string, TimeskipSaveData> {
		return this.timeskipSave;
	}

	public getSkillsSaveData(): Map<string, SkillSaveData> {
		return this.skillsSave;
	}

	public getPaymentSaveData(): BankPaymentSaveData {
		return this.paymentSave;
	}

	public getLevelBankSaveData(): BankSaveData {
		return this.levelBankSaves;
	}

	public getEventLevelBankSaveData(): BankSaveData {
		return this.eventLevelBankSaves;
	}

	public getLevelSummonSaveData(): SummonSaveData {
		return this.levelSummonSaves;
	}

	public getEventLevelSummonSaveData(): SummonSaveData {
		return this.eventLevelSummonSaves;
	}

	public getUpdatesRevision(): number {
		return this.updatesRevision;
	}

	public getSettingsSaveData(): SettingsSaveData {
		return {
			language: this.userData.settingsLanguage,
			sound: this.userData.settingsSound,
			music: this.userData.settingsMusic,
			cardpay: this.userData.settingsCardpay,
		};
	}

	public getEpicQuestCollectionsSave(): Map<string, number[]> {
		return this.epicQuestCollectionsSave;
	}

	public getTutorialStepsSaveData(): TutorialStepsSaveData {
		return this.tutorialStepsSave;
	}

	public getEventTutorialStepsSaveData(): TutorialStepsSaveData {
		return this.eventTutorialStepsSave;
	}

	public getSoftMoney(): SoftMoneyNumber {
		return this.softMoney;
	}

	public getPrestigeMoney(): number {
		return this.prestigeMoney;
	}

	public getHardMoney(): number {
		return this.hardMoney;
	}

	public getRedeemCode(): string {
		return this.redeemCode;
	}

	public getTransactionsSaveData(): TransactionSaveData[] {
		return this.transactionsSaveData;
	}

	public getButtonMaxCustomersCurrentValueId(): number {
		return this.userData.buttonMaxCustomersCurrentValueId;
	}

	public isLevelStartDialogShowed(): boolean {
		return this.userData.startDialogShowed;
	}

	public hasEmail(): boolean {
		return Boolean(this.email);
	}

	public getEmail(): string {
		return this.email;
	}

	public getLevelStartedTime(): number {
		return this.userData.levelStartedTime;
	}

	public hasLevelStartedTime(): boolean {
		return this.userData.levelStartedTime !== null && this.userData.levelStartedTime !== undefined;
	}

	public hasUsername(): boolean {
		return Boolean(this.userData.username);
	}

	public getUsername(): string {
		return this.userData.username;
	}

	public getLevelFinishedTime(): number | null {
		return this.userData.levelFinishedTime;
	}

	public getNewCustomersMultiplierRewards(): LootboxNewCustomerMultiplierConfig[] {
		return this.newCustomersMultiplierRewards;
	}

	public getCompensationLootboxConfigs(): LootboxServerConfig[] {
		return this.compensationLootboxConfigs;
	}

	public getEventLevelQuestsInitData(): QuestInitData[] {
		return this.eventLevelQuestsInitData;
	}

	public getEventLevelQuestsProgress(): number {
		return this.eventLevelQuestsProgress;
	}

	public getEventLevelSkillsSaveData(): Map<string, SkillSaveData> {
		return this.eventLevelSkillsSave;
	}

	public getEventLevelBoostsSaveData(): Map<string, BoostSaveData> {
		return this.eventLevelBoostSave;
	}

	public getEventLevelTimeskipsSaveData(): Map<string, TimeskipSaveData> {
		return this.eventLevelTimeskipSave;
	}

	public getEventLevelInitData(): EventLevelInitData | null {
		return this.eventLevelSaveData;
	}

	public getEventLevelSoftMoney(): string | undefined {
		return this.eventLevelSoftMoney;
	}

	public hasEventLevelSoftMoney(): boolean {
		return Boolean(this.eventLevelSoftMoney);
	}

	public getEventLevelPrestigeMoney(): string | undefined {
		return this.eventLevelPrestigeMoney;
	}

	public hasEventLevelPrestigeMoney(): boolean {
		return Boolean(this.eventLevelPrestigeMoney);
	}

	public isEventLevelEverEntered(): boolean {
		return this.eventLevelSaveData?.data?.firstEnter !== undefined && this.eventLevelSaveData?.data?.firstEnter !== null;
	}

	public isEventLevelEverInited(): boolean {
		return Boolean(this.eventLevelSaveData);
	}

	public isEventLevelNoviceCompleted(): boolean {
		return this.noviceEventLevelCompletedOn !== undefined && this.noviceEventLevelCompletedOn !== null;
	}

	public getEventLevelNoviceCompletedOn(): number {
		return this.noviceEventLevelCompletedOn;
	}

	public getEventTransactionsSaveData(): TransactionSaveData[] {
		return this.eventTransactionsSaveData;
	}

	public getEventLevelNewCustomersMultiplierRewards(): LootboxNewCustomerMultiplierConfig[] {
		return this.eventLevelNewCustomersMultiplierRewards;
	}

	public getEventCompensationLootboxConfigs(): LootboxServerConfig[] {
		return this.eventCompensationLootboxConfigs;
	}

	public getLevelFarewellBoost(): FarewellBonusSaveData | null {
		return this.levelFarewellBoost;
	}

	public getEventFarewellBoost(): FarewellBonusSaveData | null {
		return this.eventFarewellBoost;
	}

	public getLevelNextFarewellPartyTime(): number {
		return this.levelNextFarewellPartyTime;
	}

	public getEventNextFarewellPartyTime(): number {
		return this.eventNextFarewellPartyTime;
	}

	public getLevelNextFarellPartyKey(): string | undefined {
		return this.userData.nextFarewellPartyKey;
	}

	public getEventNextFarewellPartyKey(): string | undefined {
		return this.userData.eventNextFarewellPartyKey;
	}

	public getFarewellPartyEverStarted(): boolean {
		return this.userData.farewellPartyEverStarted;
	}

	public getCardPayEmail(): string | undefined {
		return this.userData.cardpayEmail;
	}

	public getUtmParams(): TrackingData | undefined {
		return this.utmParams;
	}

	public getNewsData(): NewsData {
		return this.newsData;
	}
}
