import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { BankParticleConfig } from '../bank/animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { RankingPlayerInfoView } from './RankingPlayerInfoView';
import { RankingPlayerIncomeInfoView } from './RankingPlayerIncomeInfoView';
import { StickableTimerView } from '@views/components/StickableTimerView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { NumberUtils } from '@src/utils/NumberUtils';
import { GameProfileModel } from '@models/GameProfileModel';
import { EnterUsernamePopupView } from './EnterUsernamePopupView';
import { RankingListView } from './RankingListView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { SandboxOperation } from '@src/utils/SandboxOperation';

export class EventLevelRankingWindowView extends PopupWindowBaseView {
	public static readonly EVENT_CHANGE_USERNAME: symbol = Symbol();

	private readonly eventLevelModel: EventLevelModel;
	private readonly gameProfileModel: GameProfileModel;
	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];
	private readonly tickerTimer: PIXI.ticker.Ticker;
	private readonly titleLabel: SizeableBitmapText;
	private readonly eventsRankingPanel: RankingListView;
	private readonly rankingPlayerInfo: RankingPlayerInfoView;
	private readonly rankingPlayerIncomeInfo: RankingPlayerIncomeInfoView;
	private readonly timerView: StickableTimerView;

	constructor(
		gameProfileModel: GameProfileModel,
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
		runInEpisodeAssetsSandbox: SandboxOperation,
	) {
		super(0.8);

		this.eventLevelModel = eventLevelModel;

		this.localizationStorage = LocalizationStorage.getInstance();

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUsernameUpdated, this);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		const bgWindow = new PIXI.mesh.NineSlicePlane(uiAtlas['u_window_bg_3'], 31, 31, 31, 31);
		bgWindow.width = 1144;
		bgWindow.height = 970;
		bgWindow.pivot.set(bgWindow.width / 2, bgWindow.height / 2);
		bgWindow.y = 31;
		bgWindow.interactive = true;

		const bgFade = new PIXI.Sprite(uiAtlas['b_circle_glow']);
		bgFade.tint = 0x000000;
		bgFade.scale.x = 18;
		bgFade.scale.y = 13;
		bgFade.y = 400;

		const girlsDecor1 = new PIXI.Sprite(AssetsStorage.getResource('character4_promo').texture);
		girlsDecor1.pivot.set(girlsDecor1.width / 2, girlsDecor1.height / 2);
		girlsDecor1.position.set(-706, 15);

		const girlsDecor2 = new PIXI.Sprite(AssetsStorage.getResource('character20_promo').texture);
		girlsDecor2.pivot.set(girlsDecor2.width / 2, girlsDecor2.height / 2);
		girlsDecor2.position.set(712, 40);

		const titleLabelBg = new PIXI.mesh.NineSlicePlane(uiAtlas['title_window_panel'], 5, 0, 5, 5);
		titleLabelBg.width = 1120;
		titleLabelBg.pivot.set(titleLabelBg.width / 2, titleLabelBg.height / 2);
		titleLabelBg.y = -431;

		this.titleLabel = new SizeableBitmapText(
			'',
			400,
			{ font: '38px wendyOneShadowBold', align: 'center' },
		);
		this.titleLabel.anchor = 0.5;
		this.titleLabel.y = -431;

		const windowFade = new PIXI.mesh.NineSlicePlane(uiAtlas['b_gradient_black_h'], 0, 0, 0, 0);
		windowFade.alpha = 0.7;
		windowFade.height = 1090;
		windowFade.width = 140;
		windowFade.pivot.set(windowFade.width / 2, windowFade.height / 2);
		windowFade.y = 406;
		windowFade.rotation = -90 * Math.PI / 180;

		const playerBlock = new PIXI.Container();
		playerBlock.y = 477;

		const playerPanelBg = new PIXI.mesh.NineSlicePlane(uiAtlas['u_window_bg_4'], 30, 29, 30, 32);
		playerPanelBg.height = 180;
		playerPanelBg.width = 1256;
		playerPanelBg.pivot.set(playerPanelBg.width / 2, playerPanelBg.height / 2);

		const botsSorted = this.eventLevelModel.getLeagueBotModels().sort((a, b) => {
			const scoreA = a.getScore(this.eventLevelModel.getRunningTime());
			const scoreB = b.getScore(this.eventLevelModel.getRunningTime());
			return scoreB.subtract(scoreA).toNumber();
		});

		const incomePerSec = this.eventLevelModel.getIncomePerSec();

		const playerListItemId = botsSorted.findIndex(bot => bot.getScore(this.eventLevelModel.getRunningTime()).lessThanOrEqualTo(incomePerSec));
		let playerLeaguePlace: number;
		if (playerListItemId === -1) {
			playerLeaguePlace = botsSorted.length + 1;
		} else {
			playerLeaguePlace = playerListItemId + 1;
		}

		this.rankingPlayerInfo = new RankingPlayerInfoView();
		this.rankingPlayerInfo.x = -480;
		this.rankingPlayerInfo.setTextLeaguePlace(playerLeaguePlace.toString());
		this.rankingPlayerInfo.setLeaguePlace(playerLeaguePlace);

		this.rankingPlayerIncomeInfo = new RankingPlayerIncomeInfoView();
		this.rankingPlayerIncomeInfo.x = 438;
		this.rankingPlayerIncomeInfo.setTextIncome(
			incomePerSec.toString(),
		);

		this.timerView = new StickableTimerView(true);
		this.timerView.y = -490;

		const leagueRewards = Array.from(this.eventLevelModel.getLeagueRewardsMap().values());

		this.eventsRankingPanel = new RankingListView(
			1080,
			888,
			this.gameProfileModel.hasUsername() ? this.gameProfileModel.getUsername() : '',
			incomePerSec,
			botsSorted,
			leagueRewards,
			cardMiniViewFactory,
			runInEpisodeAssetsSandbox,
			playerListItemId,
			this.eventLevelModel.getRunningTime(),
		);
		this.eventsRankingPanel.y = -321;

		this.animationEmitters = [];

		const animationsContainer = new PIXI.Container();
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
		animationsContainer.y = -150;
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		playerBlock.addChild(
			playerPanelBg,
			this.rankingPlayerInfo as PIXI.DisplayObject,
			this.rankingPlayerIncomeInfo,
		);

		this.updateText();

		this.tickerTimer = PIXI.ticker.shared;
		this.tickerTimer.add(this.onUpdateTextTimer, this);

		this.mainContainer.addChild(
			animationsContainer,
			this.timerView,
			girlsDecor1,
			girlsDecor2,
			bgFade,
			bgWindow,
			this.eventsRankingPanel,
			windowFade,
			titleLabelBg,
			this.titleLabel as PIXI.DisplayObject,
			playerBlock,
		);

		if (this.gameProfileModel.hasUsername()) {
			this.onUsernameUpdated();
		} else {
			this.mainContainer.filters = [new PIXI.filters.BlurFilter()];

			const enterUsernamePopup = new EnterUsernamePopupView();
			enterUsernamePopup.once(EnterUsernamePopupView.EVENT_WINDOW_CLOSED, this.onClose, this);

			enterUsernamePopup.once(EnterUsernamePopupView.EVENT_SUBMIT_USERNAME, (val: string) => {
				this.mainContainer.filters = [];

				enterUsernamePopup.off(EnterUsernamePopupView.EVENT_WINDOW_CLOSED, this.onClose, this);
				enterUsernamePopup.destroy({ children: true });

				this.onSubmitUsername(val);
			}, this);

			enterUsernamePopup.onShown();

			this.addChild(enterUsernamePopup);
		}
	}

	private onSubmitUsername(username: string): void {
		this.emit(EventLevelRankingWindowView.EVENT_CHANGE_USERNAME, username);
	}

	private onUpdateTextTimer(): void {
		const timeleft = NumberUtils.secToDHMSColonFormatted(this.eventLevelModel.getTimeleft());
		this.timerView.setTextTimer(timeleft);
	}

	private updateText(): void {
		this.titleLabel.text = this.localizationStorage.getLocalizedString('#event_ranking_window_title');
	}

	private onUsernameUpdated(): void {
		this.rankingPlayerInfo.setUsername(this.gameProfileModel.getUsername());
		this.eventsRankingPanel.setPlayerUsername(this.gameProfileModel.getUsername());
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.tickerTimer.remove(this.onUpdateTextTimer, this);

		this.gameProfileModel.off(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUsernameUpdated, this);

		super.destroy(options);
	}
}
