import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter55013View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55013View';
import { EventCharacter55014View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55014View';
import { EventCharacter55015View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55015View';

export class EventBusiness55005View extends BusinessBaseView {
	constructor() {
		const eventCharacter55013View = new EventCharacter55013View();
		const eventCharacter55014View = new EventCharacter55014View();
		const eventCharacter55015View = new EventCharacter55015View();

		super('business55005', [eventCharacter55013View, eventCharacter55014View, eventCharacter55015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter55013View as PIXI.DisplayObject,
			eventCharacter55014View,
			eventCharacter55015View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
