import { BusinessBaseView } from '../ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business14View extends BusinessBaseView {
	constructor() {
		super('business14');

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor1']).setTransform(-286, 60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor2']).setTransform(249, 67, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor3']).setTransform(-265, -30, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
		);
	}
}
