import { BankPrepareTransactionWindow } from '@views/windows/bank/BankPrepareTransactionWindow';
import { BaseTransactionCreator } from './BaseTransactionCreator';

export class WindowSpawnerTransactionCreator extends BaseTransactionCreator {
	protected async openWindow(url: string): Promise<void> {
		const bankPrepareTransactionWindow: BankPrepareTransactionWindow = this.windowFactory.createBankPrepareTransactionWindow();
		this.windowViewSystem.showWindow(bankPrepareTransactionWindow);
		return new Promise((resolve, reject) => {
			bankPrepareTransactionWindow.once(BankPrepareTransactionWindow.EVENT_WINDOW_CLOSED, () => {
				super.openWindow(url)
					.then(resolve)
					.catch(reject);
			});
		});
	}
}
