import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { SummonConfigSetParser } from '@configs/configsParsers/configSets/SummonConfigSetParser';
import { SummonConfig } from '@configs/SummonConfig';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export abstract class SummonConfigsLoaderBase {
	protected readonly summonConfigsParser: SummonConfigSetParser;

	constructor(
		protected readonly networkRequestSender: NetworkRequestSender,
	) {
		this.summonConfigsParser = new BaseConfigSetParser(SummonConfig.FIELD_KEY, SummonConfig);
	}

	public abstract loadConfigs(): Promise<void>;

	public getSummonConfigs(): Map<string, SummonConfig> {
		return this.summonConfigsParser.getConfigSet();
	}
}
