import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class SettingsPanelItemCardpayView extends PIXI.Container {
	public static readonly EVENT_EPOCH_BUTTON_CLICK: symbol = Symbol();
	public static readonly EVENT_CARDPAY_BUTTON_CLICK: symbol = Symbol();

	protected readonly localizationStorage: LocalizationStorage;
	protected readonly localeKeyLabelTitle: string;

	protected labelTitle: SizeableBitmapText;
	protected labelDescr: SizeableBitmapText;

	protected epochButton: ButtonBaseView;
	protected cardpayButton: ButtonBaseView;

	constructor(
		iconOffset: number,
		labelOffset: number,
		buttonOffset: number,
		labelLocaleKey: string,
		icon: PIXI.Sprite,
	) {
		super();

		this.localeKeyLabelTitle = labelLocaleKey;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		icon.x = iconOffset;

		this.labelTitle = new SizeableBitmapText('', 275, { font: '34px wendyOne', tint: 0x465768 });
		this.labelTitle.anchor = new PIXI.Point(0, 0.5);
		this.labelTitle.position.set(labelOffset, -10);
		this.labelTitle.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelTitle);

		this.labelDescr = new SizeableBitmapText('', 305, { font: '34px wendyOne', tint: 0x949ca5 });
		this.labelDescr.anchor = new PIXI.Point(0, 0.5);
		this.labelDescr.position.set(labelOffset, 27);
		this.labelDescr.text = this.localizationStorage.getLocalizedString('#setting_payment_method_enabled_descr_label');

		const epochSprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['epoch_button']);
		this.epochButton = new ButtonBaseView(epochSprite);
		this.epochButton.x = -83;
		this.epochButton.on(ButtonBaseView.EVENT_CLICK, () => {
			this.emit(SettingsPanelItemCardpayView.EVENT_EPOCH_BUTTON_CLICK);
		});

		const cardpaySprite = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['cardpay_button']);
		this.cardpayButton = new ButtonBaseView(cardpaySprite);
		this.cardpayButton.x = 83;
		this.cardpayButton.on(ButtonBaseView.EVENT_CLICK, () => {
			this.emit(SettingsPanelItemCardpayView.EVENT_CARDPAY_BUTTON_CLICK);
		});

		const buttons = new PIXI.Container();
		buttons.x = buttonOffset;

		buttons.addChild(
			this.epochButton,
			this.cardpayButton,
		);

		this.addChild(
			icon,
			this.labelTitle as PIXI.DisplayObject,
			this.labelDescr,
			buttons,
		);
	}

	public setCardpay(value: boolean): void {
		if (value) {
			this.epochButton.alpha = 0.2;
			this.cardpayButton.alpha = 1;
			this.labelTitle.y = -10;
			this.labelDescr.visible = true;
		} else {
			this.epochButton.alpha = 1;
			this.cardpayButton.alpha = 0.2;
			this.labelTitle.y = 0;
			this.labelDescr.visible = false;
		}

		this.cardpayButton.interactive = !value;
		this.epochButton.interactive = value;

		this.updateText();
	}

	protected updateText(): void {
		this.labelTitle.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelTitle);
		this.labelDescr.text = this.localizationStorage.getLocalizedString('#setting_payment_method_enabled_descr_label');
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
