import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter40013View } from '@views/characters/events/event4/EventCharacter40013View';
import { EventCharacter40014View } from '@views/characters/events/event4/EventCharacter40014View';
import { EventCharacter40015View } from '@views/characters/events/event4/EventCharacter40015View';

export class EventBusiness40005View extends BusinessBaseView {
	constructor() {
		const eventCharacter40013View = new EventCharacter40013View();
		const eventCharacter40014View = new EventCharacter40014View();
		const eventCharacter40015View = new EventCharacter40015View();

		super('business40005', [eventCharacter40013View, eventCharacter40014View, eventCharacter40015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter40014View,
			eventCharacter40013View as PIXI.DisplayObject,
			eventCharacter40015View,
		);
	}
}
