import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class ButtonCheatWindowView extends ButtonBaseView {
	private currentToggleState?: boolean;

	private readonly textLabel: PIXI.Text;

	constructor(label: string) {
		const buttonBg = new PIXI.Graphics();

		super(buttonBg, undefined, undefined, false);

		this.drawButton(0x68bc0f);

		this.textLabel = new PIXI.Text(label, {
			fontFamily: 'Arial',
			fontSize: 25,
			wordWrapWidth: this.buttonBg.width,
			wordWrap: true,
		});
		this.textLabel.anchor.set(0.5, 0.5);

		while (this.textLabel.height > this.buttonBg.height) {
			this.textLabel.scale.set(this.textLabel.scale.x - 0.1, this.textLabel.scale.y - 0.1);
		}

		this.addChild(
			this.textLabel,
		);
	}

	public setToggleMode(currentState: boolean): void {
		this.currentToggleState = currentState;

		this.updateButton();

		this.on(ButtonBaseView.EVENT_CLICK, () => {
			this.currentToggleState = !this.currentToggleState;
			this.updateButton();
		});
	}

	private drawButton(tint: number): void {
		const buttonBg = this.buttonBg as PIXI.Graphics;
		buttonBg.clear();
		buttonBg.lineStyle(2, 0x000000, 1);
		buttonBg.beginFill(tint);
		buttonBg.drawRoundedRect(0, 0, 170, 60, 8);
		buttonBg.endFill();
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);
	}

	private updateButton(): void {
		let { text } = this.textLabel;
		const semicolonIndex = text.indexOf(':');
		if (semicolonIndex > 0) {
			text = text.slice(0, semicolonIndex);
		}

		if (this.currentToggleState) {
			this.drawButton(0x68bc0f);
			text += ':ON';
		} else {
			this.drawButton(0xf9bf15);
			text += ':OFF';
		}

		this.textLabel.text = text;
	}
}
