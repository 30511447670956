import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { BusinessModel } from '@models/BusinessModel';

export class ModelWriterSkill {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly skillModels: Map<string, SkillModel>;

	constructor(
		skillModels: Map<string, SkillModel>,
		businessModels: Map<string, BusinessModel>,
	) {
		this.businessModels = businessModels;
		this.skillModels = skillModels;

		this.skillModels.forEach((model) => {
			model.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this);
			model.on(SkillModel.EVENT_SKILL_DEACTIVATED, this.onSkillDeactivated, this);
		});
	}

	private onSkillActivated(skill: SkillModel): void {
		const skillMultiplier = skill.getMultiplier();

		// eslint-disable-next-line default-case
		switch (skill.getType()) {
			case SkillTypes.PROFIT_IMPROVE: {
				this.businessModels.forEach(model => model.setSkillProfitImproveMultiplier(skillMultiplier));
				break;
			}
			case SkillTypes.TIME_IMPROVE: {
				this.businessModels.forEach(model => model.setSkillTimeImproveDivisor(skillMultiplier));
				break;
			}
			case SkillTypes.CONSTANT_PROFIT: {
				this.businessModels.forEach(model => model.setSkillConstantProfitMultiplier(skillMultiplier));
				break;
			}
			case SkillTypes.TAP_ADD_MONEY: {
				this.businessModels.forEach(model => model.setSkillTapAddMoneyValue(skillMultiplier));
				break;
			}
		}
	}

	private onSkillDeactivated(skill: SkillModel): void {
		// eslint-disable-next-line default-case
		switch (skill.getType()) {
			case SkillTypes.PROFIT_IMPROVE: {
				this.businessModels.forEach(model => model.setSkillProfitImproveMultiplier(1));
				break;
			}
			case SkillTypes.TIME_IMPROVE: {
				this.businessModels.forEach(model => model.setSkillTimeImproveDivisor(1));
				break;
			}
			case SkillTypes.CONSTANT_PROFIT: {
				this.businessModels.forEach(model => model.setSkillConstantProfitMultiplier(1));
				break;
			}
			case SkillTypes.TAP_ADD_MONEY: {
				this.businessModels.forEach(model => model.setSkillTapAddMoneyValue(0));
				break;
			}
		}
	}
}
