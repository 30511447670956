import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { BankParticleConfig } from './bank/animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import LocalizationStorage from '@main/LocalizationStorage';
import { StickableTimerView } from '../components/StickableTimerView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { EventLevelRewardsHorizontalListView } from '../ui/EventLevelRewardsHorizontalListView';
import { TextField } from '@views/components/text/TextField';
import * as TWEEN from '@tweenjs/tween.js';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { HintTypes, HintDataReward } from '@src/types/HintTypes';

export class EventLevelRewardsWindowView extends PopupWindowBaseView {
	public static readonly EVENT_SHOW_HINT_REWARD_CARD: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly eventLevelModel: EventLevelModel;

	private readonly ticker: PIXI.ticker.Ticker;
	private readonly animationEmitters: Emitter[];

	private readonly titleLabel: SizeableBitmapText;
	private readonly descrLabel: TextField;
	private readonly timerView: StickableTimerView;

	private glowTween: TWEEN.Tween;

	private readonly buttonClose: ButtonWithLabelBaseView;

	constructor(
		eventLevelModel: EventLevelModel,
		cardMiniViewFactory: CardMiniViewFactory,
	) {
		super(0.8);

		this.eventLevelModel = eventLevelModel;
		const eventKey: string = this.eventLevelModel.getKey();

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_3'], 31, 31, 31, 31);
		bg.width = 1006;
		bg.height = 678;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.y = 47;

		const titleBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['title_window_panel'], 5, 0, 5, 5);
		titleBg.width = 984;
		titleBg.pivot.set(titleBg.width / 2, titleBg.height / 2);
		titleBg.y = -308;

		this.titleLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#event_rewards_window_title'),
			400,
			{ font: '40px wendyOneShadowBold', align: 'center' },
		);
		this.titleLabel.anchor = 0.5;
		this.titleLabel.y = -308;

		const bgFade = new PIXI.Graphics()
			.beginFill(0x19252a)
			.drawRect(0, -122, 946, 320)
			.endFill();
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

		const rightBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		rightBorderFade.width = 44;
		rightBorderFade.height = 320;
		rightBorderFade.pivot.set(rightBorderFade.width / 2, rightBorderFade.height / 2);
		rightBorderFade.tint = 0x212f36;
		rightBorderFade.position.set(451, -122);
		rightBorderFade.scale.x = -1;
		rightBorderFade.interactive = true;

		const leftBorderFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_white_h'], 0, 0, 0, 0);
		leftBorderFade.width = 44;
		leftBorderFade.height = 320;
		leftBorderFade.pivot.set(leftBorderFade.width / 2, leftBorderFade.height / 2);
		leftBorderFade.tint = 0x212f36;
		leftBorderFade.position.set(-451, -122);
		leftBorderFade.interactive = true;

		const eventRewardsTitle = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#go_to_event_window_event_rewards'),
			600,
			{ font: '30px wendyOneShadowBold', align: 'center', tint: 0x7d9bac },
		);
		eventRewardsTitle.anchor = 0.5;
		eventRewardsTitle.y = -220;

		const goalRewards = Array
			.from(eventLevelModel.getGoalRewardsMap().values())
			.map(rewards => rewards[0]);
		const completedGoalsCount = this.eventLevelModel.getCompletedGoalCount();

		const rewardCardCreatedHandler = (card: PIXI.Container, i: number): void => {
			if (i > completedGoalsCount) {
				const blackoutFilter = new PIXI.filters.ColorMatrixFilter();
				blackoutFilter.brightness(0.4);
				card.filters = [blackoutFilter];
			} else if (i < completedGoalsCount) {
				const claimedIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_icon3']);
				claimedIcon.tint = 0xff7c05;
				claimedIcon.scale.set(1.1);

				const blackoutFilter = new PIXI.filters.ColorMatrixFilter();
				blackoutFilter.brightness(0.6);
				card.filters = [blackoutFilter];
				card.addChild(claimedIcon);
			} else {
				const glow = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['rect_glow'], 15, 15, 15, 15);
				glow.width = 165;
				glow.height = 219;
				glow.pivot.set(glow.width / 2, glow.height / 2);
				glow.tint = 0x79abc9;

				this.glowTween = new TWEEN.Tween(glow.scale)
					.to({ x: 0.9, y: 0.9 }, 900)
					.easing(TWEEN.Easing.Quadratic.InOut)
					.yoyo(true)
					.repeat(Infinity)
					.start();

				card.addChildAt(glow, 0);
			}
		};
		const eventRewardsView = new EventLevelRewardsHorizontalListView(
			943,
			280,
			goalRewards,
			cardMiniViewFactory,
			rewardCardCreatedHandler.bind(this),
		);
		eventRewardsView.y = -106;
		eventRewardsView.on(
			EventLevelRewardsHorizontalListView.EVENT_SHOW_HINT_REWARD_CARD,
			(rewardDescription: RewardDescriptionType, origin: PIXI.DisplayObject) => {
				const data: HintDataReward = {
					rewardDescription,
					origin,
				};
				this.emit(EventLevelRewardsWindowView.EVENT_SHOW_HINT_REWARD_CARD, HintTypes.REWARD_CARD, data);
			},
			this,
		);

		const descrBg = new PIXI.Graphics()
			.beginFill(0xe8f3f7)
			.drawRect(0, 134, 890, 150)
			.endFill();
		descrBg.pivot.set(descrBg.width / 2, descrBg.height / 2);

		this.descrLabel = new TextField(
			this.localizationStorage.getLocalizedString(`#event_rewards_window_descr_${eventKey}`),
			{ font: { size: 24, name: 'wendyOne' }, align: 'center', tint: 0x39536b },
			800,
			130,
		);
		this.descrLabel.anchor = 0.5;
		this.descrLabel.y = 134;

		this.buttonClose = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 248, 76),
			{ font: '34px wendyOneShadowBold' },
			200,
		);
		this.buttonClose.y = 280;
		this.buttonClose.on(ButtonWithLabelBaseView.EVENT_CLICK, () => this.onClose(), this);
		this.buttonClose.setTextLabel(this.localizationStorage.getLocalizedString('#label_OK'));

		this.timerView = new StickableTimerView(true);
		this.timerView.y = -370;

		this.animationEmitters = [];
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const animationsContainer = new PIXI.Container();
		animationsContainer.y = -308;
		animationsContainer.scale.x = 0.55;
		animationsContainer.scale.y = 0.24;
		animationsContainer.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.onTimerUpdate, this);

		this.mainContainer.interactive = true;
		this.mainContainer.addChild(
			animationsContainer,
			this.timerView,
			bg,
			bgFade,
			descrBg,
			this.descrLabel,
			this.buttonClose,
			eventRewardsTitle,
			eventRewardsView,
			rightBorderFade,
			leftBorderFade,
			titleBg,
			this.titleLabel as PIXI.DisplayObject,
		);
	}

	private onTimerUpdate(): void {
		const timeFormatted = NumberUtils.secToDHMSColonFormatted(this.eventLevelModel.getTimeleft());
		this.timerView.setTextTimer(timeFormatted);
	}

	public getButtonCloseContainer(): PIXI.Container {
		return this.buttonClose;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.ticker.remove(this.onTimerUpdate, this);

		if (this.glowTween) {
			this.glowTween.stop();
		}

		super.destroy(options);
	}
}
