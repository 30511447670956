import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { FarewellPartyLootboxModel } from '@models/lootboxes/FarewellPartyLootboxModel';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { AbstractModelInstance } from './AbstractModelInstance';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { QuestFactory } from '@src/initializers/QuestFactory';
import { LevelModelsFactory } from '@src/initializers/models/LevelModelsFactory';
import { TutorialStepLevelModel } from '@models/tutorialSteps/TutorialStepLevelModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { NewsModel } from '@models/NewsModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { PresetsModel } from '@models/PresetsModel';
import { EventTutorialStepsModel } from '@models/tutorialSteps/EventTutorialStepsModel';
import { SummonSavesModel } from '@models/SummonSavesModel';

export class LevelModelInstance extends AbstractModelInstance {
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly characterQuickPhraseEmitter: CharacterQuickPhraseEmitter;
	private readonly farewellPartyModels: Map<string, FarewellPartyModel>;
	private readonly epicQuestCollectionsModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly timedQuestLinesModel: TimedQuestLinesModel;
	private readonly levelChallengeModel: LevelChallengeModel;
	private readonly tutorialStepModels: Map<string, TutorialStepLevelModel>;
	private readonly tutorialStepsEmitter: TutorialStepsEmitter;
	private readonly prepartyStartCharacterModel: PrepartyStartCharacterModel;
	private readonly eventLevelModel: EventLevelModel;
	private readonly levelModel: LevelModel;
	private readonly bankPaymentSaveModel: BankPaymentSaveModel;
	private readonly newsModel: NewsModel;
	protected readonly dailyRewardsModel: DailyRewardsModel;
	private readonly presetsModel: PresetsModel;
	private readonly eventTutorialStepsModel: EventTutorialStepsModel;

	private readonly lootboxFactory: LootboxFactory;
	private readonly questFactory: QuestFactory;
	private readonly rewardFactory: RewardFactory;
	private readonly summonSavesModel: SummonSavesModel;
	private readonly eventSummonSavesModel: SummonSavesModel;

	private readonly pendingFarewellPartyLootbox: FarewellPartyLootboxModel;

	constructor(
		modelsFactory: LevelModelsFactory,
	) {
		super(modelsFactory);

		this.hardMoneyModel = modelsFactory.createHardMoneyModel();

		this.timedQuestLinesModel = modelsFactory.createTimedLinesModel();

		this.tutorialStepModels = modelsFactory.getTutorialStepsFactory().createModels();

		this.farewellPartyModels = modelsFactory.getFarewellPartiesFactory().createModels(this.totemModels);

		this.prepartyStartCharacterModel = modelsFactory.createPrepartyStartCharacterModel(this.farewellPartyModels);

		this.businessModels = modelsFactory.getBusinessesFactory().createModels(
			this.boostModels,
			this.farewellBoostModel,
			this.characterModels,
			this.skillModels,
			this.totemModels,
			this.upgradeModels,
		);

		this.characterQuickPhraseEmitter = modelsFactory.createCharacterQuickPhraseEmitter(
			this.skillModels,
			this.characterModels,
			this.businessModels,
		);

		this.tutorialStepsEmitter = modelsFactory.createTutorialStepsEmitter(
			this.tutorialStepModels,
		);

		this.epicQuestCollectionsModels = modelsFactory.getEpicQuestCollectionsFactory().createModels(
			this.characterModels,
			this.upgradeModels,
			this.totemModels,
		);

		this.levelChallengeModel = modelsFactory.createLevelChallengeModel();

		this.rewardFactory = modelsFactory.createRewardFactory(
			this.softMoneyModel,
			this.prestigeMoneyModel,
			this.hardMoneyModel,
			this.characterModels,
			this.upgradeModels,
			this.totemModels,
			this.skillModels,
			this.galleryVideoModels,
			this.timeskipModels,
			this.boostModels,
		);

		this.lootboxFactory = modelsFactory.createLootboxFactory(this.rewardFactory);

		this.questFactory = modelsFactory.createQuestFactory(
			this.businessModels,
			this.upgradeModels,
			this.characterModels,
			this.totemModels,
			this.softMoneyModel,
			this.prestigeMoneyModel,
			this.hardMoneyModel,
			this.summonModelsPool,
			this.farewellPartyModels,
		);

		this.eventLevelModel = modelsFactory.createEventLevelModel();
		this.bankPaymentSaveModel = modelsFactory.createBankPaymentSaveModel();

		this.levelModel = modelsFactory.createLevelModel();

		this.newsModel = modelsFactory.createNewsModel();

		this.dailyRewardsModel = modelsFactory.createDailyRewardsModel();
		this.presetsModel = modelsFactory.createPresetsModel(this.levelModel);

		this.eventTutorialStepsModel = modelsFactory.createEventTutorialStepsModel();

		this.summonSavesModel = modelsFactory.createSummonSavesModel();
		this.eventSummonSavesModel = modelsFactory.createEventSummonSavesModel();
	}

	public getLootboxFactory(): LootboxFactory {
		return this.lootboxFactory;
	}

	public getQuestFactory(): QuestFactory {
		return this.questFactory;
	}

	public getBusinessModels(): Map<string, BusinessModel> {
		return this.businessModels;
	}

	public getCharacterQuickPhraseEmitter(): CharacterQuickPhraseEmitter {
		return this.characterQuickPhraseEmitter;
	}

	public getTutorialStepModels(): Map<string, TutorialStepLevelModel> {
		return this.tutorialStepModels;
	}

	public getTutorialStepsEmitter(): TutorialStepsEmitter {
		return this.tutorialStepsEmitter;
	}

	public getFarewellPartyModels(): Map<string, FarewellPartyModel> {
		return this.farewellPartyModels;
	}

	public getEpicQuestCollectionsModels(): Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel> {
		return this.epicQuestCollectionsModels;
	}

	public getTimedQuestLinesModel(): TimedQuestLinesModel {
		return this.timedQuestLinesModel;
	}

	public getLevelChallengeModel(): LevelChallengeModel {
		return this.levelChallengeModel;
	}

	public getPendingFarewellPartyLootbox(): FarewellPartyLootboxModel | null {
		return this.pendingFarewellPartyLootbox;
	}

	public getPrepartyStartCharacterModel(): PrepartyStartCharacterModel {
		return this.prepartyStartCharacterModel;
	}

	public getEventLevelModel(): EventLevelModel {
		return this.eventLevelModel;
	}

	public getLevelModel(): LevelModel {
		return this.levelModel;
	}

	public getBankPaymentSaveModel(): BankPaymentSaveModel {
		return this.bankPaymentSaveModel;
	}

	public getHardMoneyModel(): HardMoneyModel {
		return this.hardMoneyModel;
	}

	public getRewardFactory(): RewardFactory {
		return this.rewardFactory;
	}

	public getNewsModel(): NewsModel {
		return this.newsModel;
	}

	public getDailyRewardsModel(): DailyRewardsModel {
		return this.dailyRewardsModel;
	}

	public getPresetsModel(): PresetsModel {
		return this.presetsModel;
	}

	public getEventTutorialStepsModel(): EventTutorialStepsModel {
		return this.eventTutorialStepsModel;
	}

	public getSummonSavesModel(): SummonSavesModel {
		return this.summonSavesModel;
	}

	public getEventSummonSavesModel(): SummonSavesModel {
		return this.eventSummonSavesModel;
	}
}
