import { SettingsPanelItemBaseView } from './SettingsPanelItemBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';

export class SettingsPanelItemLanguageItemView extends SettingsPanelItemBaseView {
	public static readonly EVENT_CHANGE_LANGUAGE: symbol = Symbol();

	private readonly supportLanguageMap: Map<string, string>;
	private readonly labelCurrentLanguage: PIXI.extras.BitmapText;

	constructor(
		iconOffset: number,
		titleLabelOffset: number,
		buttonOffset: number,
		localeKeyLabelTitle: string,
		icon: PIXI.Sprite,
		supportLanguageMap: Map<string, string>,
	) {
		super(
			iconOffset,
			titleLabelOffset,
			localeKeyLabelTitle,
			icon,
		);

		this.supportLanguageMap = supportLanguageMap;


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_switch_language_bg'], 13, 0, 13, 0);
		bg.width = 319;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.x = buttonOffset;

		const arrowLeftBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_arrow']);

		const arrowLeftBounds = arrowLeftBg.getBounds();
		const arrowLeftButton = new ButtonBaseView(arrowLeftBg);
		arrowLeftButton.x = 65;
		arrowLeftButton.on(ButtonBaseView.EVENT_CLICK, this.onLeftArrowClick, this);
		arrowLeftButton.hitArea = new PIXI.Rectangle(
			arrowLeftBounds.x - 25,
			arrowLeftBounds.y - 25,
			arrowLeftBounds.width + 50,
			arrowLeftBounds.height + 50,
		);

		const arrowRightBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_arrow']);
		arrowRightBg.scale.x = -1;

		const arrowRightBounds = arrowRightBg.getBounds();
		const arrowRightButton = new ButtonBaseView(arrowRightBg);
		arrowRightButton.x = 324;
		arrowRightButton.on(ButtonBaseView.EVENT_CLICK, this.onRightArrowClick, this);
		arrowRightButton.hitArea = new PIXI.Rectangle(
			arrowRightBounds.x - 25,
			arrowRightBounds.y - 25,
			arrowRightBounds.width + 50,
			arrowRightBounds.height + 50,
		);

		const stringCurrentLanguage = this.supportLanguageMap.get(LocalizationStorage.getInstance().getLanguage());
		this.labelCurrentLanguage = new PIXI.extras.BitmapText(stringCurrentLanguage, { font: '34px wendyOneShadowBold' });
		this.labelCurrentLanguage.anchor = 0.5;
		this.labelCurrentLanguage.x = bg.width / 2 + 30;

		this.addChild(
			bg,
			arrowLeftButton as PIXI.DisplayObject,
			arrowRightButton,
			this.labelCurrentLanguage,
		);
	}

	private onLeftArrowClick(): void {
		const supportLanguages: string[] = Array.from(this.supportLanguageMap.keys());
		const currentSupportLanguageId = supportLanguages.indexOf(LocalizationStorage.getInstance().getLanguage());

		let targetSupportLanguageId = supportLanguages.length - 1;
		if (currentSupportLanguageId - 1 >= 0) {
			targetSupportLanguageId = currentSupportLanguageId - 1;
		}

		this.emit(SettingsPanelItemLanguageItemView.EVENT_CHANGE_LANGUAGE, supportLanguages[targetSupportLanguageId]);
	}

	private onRightArrowClick(): void {
		const supportLanguages: string[] = Array.from(this.supportLanguageMap.keys());
		const currentSupportLanguageId = supportLanguages.indexOf(LocalizationStorage.getInstance().getLanguage());

		let targetSupportLanguageId = 0;
		if (currentSupportLanguageId + 1 < supportLanguages.length) {
			targetSupportLanguageId = currentSupportLanguageId + 1;
		}

		this.emit(SettingsPanelItemLanguageItemView.EVENT_CHANGE_LANGUAGE, supportLanguages[targetSupportLanguageId]);
	}

	protected updateText(): void {
		const stringCurrentLanguage = this.supportLanguageMap.get(LocalizationStorage.getInstance().getLanguage());
		this.labelCurrentLanguage.text = stringCurrentLanguage;

		super.updateText();
	}
}
