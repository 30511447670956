import { CollectEventRewardsWindowViewSetter } from '@interfaces/ViewSetters';
import { CollectEventLevelRewardsWindowView } from '@views/windows/CollectEventLevelRewardsWindowView';
import { EventLevelModel } from '@models/level/EventLevelModel';

export class CollectEventRewardsWindowViewController implements CollectEventRewardsWindowViewSetter {
	private readonly eventLevelModel: EventLevelModel;

	constructor(eventLevelModel: EventLevelModel) {
		this.eventLevelModel = eventLevelModel;
	}

	public setCollectEventRewardsWindowView(view: CollectEventLevelRewardsWindowView): void {
		view.on(CollectEventLevelRewardsWindowView.EVENT_BUTTON_COLLECT_CLICK, this.onCollectButtonClick, this);
	}

	private onCollectButtonClick(): void {
		this.eventLevelModel.requestRewardCollect();
	}
}
