import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90010View } from '@views/characters/events/event9/EventCharacter90010View';
import { EventCharacter90011View } from '@views/characters/events/event9/EventCharacter90011View';
import { EventCharacter90012View } from '@views/characters/events/event9/EventCharacter90012View';

export class EventBusiness90004View extends BusinessBaseView {
	constructor() {
		const eventCharacter90010View = new EventCharacter90010View();
		const eventCharacter90011View = new EventCharacter90011View();
		const eventCharacter90012View = new EventCharacter90012View();

		super('business90004', [eventCharacter90010View, eventCharacter90011View, eventCharacter90012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b12_decor4']).setTransform(-10, -85, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b12_decor5']).setTransform(0, 30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter90012View,
			eventCharacter90010View,
			eventCharacter90011View as PIXI.DisplayObject,
		);
	}
}
