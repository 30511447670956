import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter52010View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52010View';
import { EventCharacter52011View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52011View';
import { EventCharacter52012View } from '@views/characters/events/eventLevelWeekDay2/EventCharacter52012View';

export class EventBusiness52004View extends BusinessBaseView {
	constructor() {
		const eventCharacter52010View = new EventCharacter52010View();
		const eventCharacter52011View = new EventCharacter52011View();
		const eventCharacter52012View = new EventCharacter52012View();

		super('business52004', [eventCharacter52010View, eventCharacter52011View, eventCharacter52012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter52010View as PIXI.DisplayObject,
			eventCharacter52011View,
			eventCharacter52012View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
