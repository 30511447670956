import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { BankElementViewDataConfig } from './BankElementViewDataConfig';
import { RewardDescriptionType, RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { NumberUtils } from '@src/utils/NumberUtils';

export type CountByRariries = {
	[rarity: string]: number;
}

export class BankSubscribeConfig
	extends BankElementViewDataConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_NAME: string = 'name';
	public static readonly FIELD_DESCRIPTION: string = 'desc';
	public static readonly FIELD_PRICE: string = 'price';
	public static readonly FIELD_PRICE_RESOURCE: string = 'price_resource';
	public static readonly FIELD_EXTRA_REWARD_TYPE: string = 'extra_reward_type';
	public static readonly FIELD_EXTRA_REWARD_ID: string = 'extra_reward_id';
	public static readonly FIELD_EXTRA_REWARD_COUNT: string = 'extra_reward_value';
	public static readonly FIELD_DURATION: string = 'duration';
	public static readonly FIELD_IS_ACTIVE: string = 'is_active';
	public static readonly FIELD_CLAIMABLE_ON: string = 'claimable_on';
	public static readonly FIELD_TIME_REMAIN: string = 'time_remain';
	public static readonly FIELD_REWARD_TYPE: string = 'reward_type';
	public static readonly FIELD_COUNT_BY_RARITY: string = 'count_by_rarity';
	public static readonly FIELD_REWARD_COUNT_PER_DAY: string = 'reward_count_per_day';

	private readonly key: string;
	private readonly nameLocale: string;
	private readonly descriptionLocale: string;
	private readonly price: number;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly extraReward: RewardDescriptionType;
	private readonly duration: string;
	private readonly isActive: boolean;
	private readonly claimableOn: number;
	private readonly timeRemain: number;
	private readonly rewardType: RewardTypes;
	private readonly countByRariry: CountByRariries;
	private readonly countPerDay: string;
	private priceConvertionCoefficient: number;

	constructor(config: { [key: string]: any }) {
		super(config[BankSubscribeConfig.FIELD_VIEW_KEY].trim());

		this.key = config[BankSubscribeConfig.FIELD_KEY].trim();

		const nameLocaleFormatted = config[BankSubscribeConfig.FIELD_NAME].trim() as string;
		if (nameLocaleFormatted && nameLocaleFormatted.length > 0) {
			this.nameLocale = nameLocaleFormatted;
		}

		const descrLocale = config[BankSubscribeConfig.FIELD_DESCRIPTION].trim() as string;
		if (descrLocale && descrLocale.length > 0) {
			this.descriptionLocale = descrLocale;
		}

		this.price = Number(config[BankSubscribeConfig.FIELD_PRICE].trim());
		this.priceResource = config[BankSubscribeConfig.FIELD_PRICE_RESOURCE].trim() as BankPriceResourceTypes;

		this.extraReward = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_type: config[BankSubscribeConfig.FIELD_EXTRA_REWARD_TYPE].trim() as RewardTypes,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_id: config[BankSubscribeConfig.FIELD_EXTRA_REWARD_ID].trim() === RewardTypes.RESOURCE
				? config[BankSubscribeConfig.FIELD_EXTRA_REWARD_ID].trim() as RewardResourceIdTypes
				: config[BankSubscribeConfig.FIELD_EXTRA_REWARD_ID].trim(),
			// eslint-disable-next-line @typescript-eslint/naming-convention
			reward_qty: Number(config[BankSubscribeConfig.FIELD_EXTRA_REWARD_COUNT].trim()),
		};

		this.duration = config[BankSubscribeConfig.FIELD_DURATION].trim();

		this.isActive = config[BankSubscribeConfig.FIELD_IS_ACTIVE];

		this.claimableOn = Number(config[BankSubscribeConfig.FIELD_CLAIMABLE_ON]);
		this.timeRemain = Number(config[BankSubscribeConfig.FIELD_TIME_REMAIN]);

		this.rewardType = config[BankSubscribeConfig.FIELD_REWARD_TYPE].trim() as RewardTypes;

		this.countByRariry = config[BankSubscribeConfig.FIELD_COUNT_BY_RARITY] as CountByRariries;
		this.countPerDay = config[BankSubscribeConfig.FIELD_REWARD_COUNT_PER_DAY].trim();
		this.priceConvertionCoefficient = 1;
	}

	public setPriceConvertionCoefficient(value: number): void {
		this.priceConvertionCoefficient = value;
	}

	public getKey(): string {
		return this.key;
	}

	public getNameLocale(): string {
		return this.nameLocale;
	}

	public getDescriptionLocale(): string {
		return this.descriptionLocale;
	}

	public getPrice(): number {
		return this.price;
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getExtraReward(): RewardDescriptionType {
		return this.extraReward;
	}

	public getDuration(): string {
		return this.duration;
	}

	public getIsActive(): boolean {
		return this.isActive;
	}

	public getClaimableOn(): number {
		return this.claimableOn;
	}

	public getTimeRemain(): number {
		return this.timeRemain;
	}

	public getRewardType(): RewardTypes {
		return this.rewardType;
	}

	public getTotalRewardCount(): number {
		return Object.keys(this.countByRariry).reduce((total, currentRarity) => total + this.countByRariry[currentRarity], 0);
	}

	public getCountByRarity(): CountByRariries {
		return this.countByRariry;
	}

	public getRewardCountPerDay(): string {
		return this.countPerDay;
	}
}
