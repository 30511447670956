import { RewardDescriptionType } from '@src/types/RewardTypes';
import { BaseAction } from './BaseAction';

export class LevelChangeAction extends BaseAction {
	constructor(nextLevel: number, currentLevelRewardPlace: number, rewards: RewardDescriptionType[]) {
		super('LevelChange');

		this.data.next_level = nextLevel;
		this.data.rewards = rewards;
		this.data.cur_stage = currentLevelRewardPlace;
	}
}
