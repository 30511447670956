import { AbstractReward } from '@interfaces/AbstractReward';
import { CardRarity } from '@src/types/CardRarities';

export class LootboxModel {
	protected readonly key: string;
	protected rewards: AbstractReward[];
	protected rarity: CardRarity;

	constructor(
		key: string,
		rarity: CardRarity,
		rewards: AbstractReward[],
	) {
		this.key = key;
		this.rarity = rarity;
		this.rewards = rewards;
	}

	public getKey(): string {
		return this.key;
	}

	public collect(): void {
		this.rewards.forEach(reward => reward.collect());
	}

	public getRewards(): AbstractReward[] {
		return this.rewards;
	}

	public getRewardCount(): number {
		return this.rewards.length;
	}

	public getCardRarity(): CardRarity {
		return this.rarity;
	}
}
