export class SupportTopic {
	private readonly titleEng: string;
	private readonly localeKey: string;

	constructor(localeKey: string) {
		this.localeKey = localeKey;
		this.titleEng = localeKey.substr(localeKey.lastIndexOf('_') + 1);
	}

	public getTitleEng(): string {
		return this.titleEng;
	}

	public getLocaleKey(): string {
		return this.localeKey;
	}
}
