import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80007View } from '@views/characters/events/event8/EventCharacter80007View';
import { EventCharacter80009View } from '@views/characters/events/event8/EventCharacter80009View';
import { EventCharacter80008View } from '@views/characters/events/event8/EventCharacter80008View';

export class EventBusiness80003View extends BusinessBaseView {
	constructor() {
		const eventCharacter80007View = new EventCharacter80007View();
		const eventCharacter80008View = new EventCharacter80008View();
		const eventCharacter80009View = new EventCharacter80009View();


		super('business80003', [eventCharacter80007View, eventCharacter80008View, eventCharacter80009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter80007View as PIXI.DisplayObject,
			eventCharacter80008View,
			eventCharacter80009View,
		);
	}
}
