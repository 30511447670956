import { QuestType, QuestTypes } from '@src/types/QuestTypes';
import { QuestConfigBase } from './QuestConfigBase';

export class QuestAcquireBusinessConfig extends QuestConfigBase {
	private readonly targetKey: string;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.targetKey = QuestConfigBase.parseParameterIdKeyPair(config);
	}

	public getTargetKey(): string {
		return this.targetKey;
	}

	// eslint-disable-next-line class-methods-use-this
	public canSetParamsOnLevel(): boolean {
		return false;
	}

	// eslint-disable-next-line class-methods-use-this
	public getType(): QuestType {
		return QuestTypes.ACQUIRE_BUSINESS;
	}
}

export class QuestAcquireBusinessesConfig extends QuestConfigBase {
	private type: QuestType;
	private acquireCount: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		this.type = QuestTypes.ACQUIRE_BUSINESSES;
	}

	public setParamsOnLevel(params: string): void {
		const param = Number(params);
		this.acquireCount = param;
	}

	public getAcquireCount(): number {
		return this.acquireCount;
	}

	public getType(): QuestType {
		return this.type;
	}
}
