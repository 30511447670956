import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { BankTabConfig } from '@configs/bank/BankTabConfig';

export class BankTabsConfigSetParser extends BaseConfigSetParser<BankTabConfig> {
	public parseConfigSet(configSetJSON: Record<string, string>[]): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const ConfigConstructor = this.configConstructorGetter || (() => this.ConfigConstructor);

		configSetJSON.forEach((configJSON) => {
			const configId = configJSON[this.configSetKeyField].trim();

			if (!this.configSet.has(configId)) {
				const config = BaseConfigSetParser.createConfig(
					ConfigConstructor(configJSON),
					configJSON,
				);
				this.configSet.set(configId, config);
			}
		});
	}
}
