import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter253010View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253010View';
import { EventCharacter253011View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253011View';
import { EventCharacter253012View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253012View';

export class EventBusiness253004View extends BusinessBaseView {
	constructor() {
		const eventCharacter253010View = new EventCharacter253010View();
		const eventCharacter253011View = new EventCharacter253011View();
		const eventCharacter253012View = new EventCharacter253012View();

		super('business253004', [eventCharacter253010View, eventCharacter253011View, eventCharacter253012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter253012View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter253010View as PIXI.DisplayObject,
			eventCharacter253011View,
		);
	}
}
