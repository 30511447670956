import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter255013View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255013View';
import { EventCharacter255014View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255014View';
import { EventCharacter255015View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255015View';

export class EventBusiness255005View extends BusinessBaseView {
	constructor() {
		const eventCharacter255013View = new EventCharacter255013View();
		const eventCharacter255014View = new EventCharacter255014View();
		const eventCharacter255015View = new EventCharacter255015View();

		super('business255005', [eventCharacter255013View, eventCharacter255014View, eventCharacter255015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter255013View as PIXI.DisplayObject,
			eventCharacter255014View,
			eventCharacter255015View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
