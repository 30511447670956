import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10008View extends CharacterMainBaseView {
	constructor() {
		super('character10008');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-145, 27);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-175, -21),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-80, -25),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
