export type DialogUnlockType = string;

export type UnlockTypeLevelProgressData = {
	level: string;
	progress: string;
};

export type UnlockTypeEventProgressData = {
	eventKey: string;
	progress: string;
};

export type UnlockTypeCharacterOpenedData = {
	key: string;
};

export enum DialogUnlockTypes {
	EVENT_PROGRESS = 'eventProgress',
	LEVEL_PROGRESS = 'levelProgress',
	CHARACTER_OPENED = 'characterOpened',
}

export type DialogUnlockData = {
	type: DialogUnlockType;
	data: any;
};

export type DialogCharacterPlacement = string;

export class DialogCharacterPlacements {
	public static readonly LEFT1: string = 'left1';
	public static readonly LEFT2: string = 'left2';
	public static readonly RIGHT1: string = 'right1';
	public static readonly RIGHT2: string = 'right2';
}

export type DialogDataItem = {
	characterImage: string;
	characterName: string;
	phrase: string;
	placement: DialogCharacterPlacement;
	charactersVisible: {
		placement: DialogCharacterPlacement;
		characterImage: string;
	}[];
};
