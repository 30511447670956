import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { NewsItemConfigSetParser } from '@configs/configsParsers/configSets/NewsItemConfigSetParser';
import { NewsItemConfig } from '@configs/NewsItemConfig';
import { NewsModel } from '@models/NewsModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { RemoveNewsAction } from '@models/network/actions/news/RemoveNewsAction';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LevelConfigNames } from '@src/types/LevelConfigNames';

export class NewsLoader extends PIXI.utils.EventEmitter {
	private readonly newsItemConfigSetParser: NewsItemConfigSetParser;
	private intervalHandle?: number;

	constructor(
		private readonly newsModel: NewsModel,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly updateInterval: number,
		private readonly isNewsEnabled: boolean,
	) {
		super();

		this.newsItemConfigSetParser = new BaseConfigSetParser(NewsItemConfig.FIELD_KEY, NewsItemConfig);
		this.newsModel.on(NewsModel.EVENT_CHANGED, this.onNewsChanged, this);
	}

	public async init(): Promise<void> {
		if (this.intervalHandle == null && this.isNewsEnabled) {
			await this.load();
			this.startUpdates();
		}
	}

	private startUpdates(): void {
		if (this.intervalHandle != null) {
			window.clearInterval(this.intervalHandle);
		}

		this.intervalHandle = window.setInterval(this.load.bind(this), this.updateInterval * 1000);
	}

	private async load(): Promise<void> {
		const configs = await this.networkRequestSender.sendGetConfigs([
			LevelConfigNames.CONFIG_NEWS,
		]);

		this.newsItemConfigSetParser.clear();
		this.newsItemConfigSetParser.parseConfigSet(configs[LevelConfigNames.CONFIG_NEWS]);

		this.newsModel.setNewsItemConfigs(this.newsItemConfigSetParser.getConfigSet());
	}

	private onNewsChanged(): void {
		const deletedKeys = this.newsModel.getDeletedNewsKeys();

		deletedKeys.forEach((key) => {
			const action = new RemoveNewsAction(key);
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);

			this.newsModel.clearDeletedKeys();
		});
	}
}
