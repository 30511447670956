import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character31View } from '@views/characters/main/Character31View';
import { Character32View } from '@views/characters/main/Character32View';
import { AssetsStorage } from '@main/AssetsStorage';
import { Character33View } from '@views/characters/main/Character33View';

export class Business11View extends BusinessBaseView {
	constructor() {
		const character31 = new Character31View();
		const character32 = new Character32View();
		const character33 = new Character33View();

		super('business11', [character31, character32, character33]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character33,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			character31 as PIXI.DisplayObject,
			character32,
		);
	}
}
