import { AssetsStorage } from '@main/AssetsStorage';
import { PromotableModel } from '@models/PromotableModel';

export class PromotableBaseMiniCardView extends PIXI.Container {
	private readonly model: PromotableModel;
	private readonly stickerNew: PIXI.Container;

	constructor(
		model: PromotableModel,
		stickerNew?: PIXI.Container,
	) {
		super();

		this.model = model;

		const miniCardsBase = AssetsStorage.getAtlas('miniCardsBaseAtlas');
		const miniCardsIcons = AssetsStorage.getAtlas('miniCardsIconsAtlas');

		const bg = new PIXI.Sprite(miniCardsBase[`mini_card_${model.getCardRarity()}_bg`]);
		const icon = new PIXI.Sprite(miniCardsIcons[`${model.getKey()}_mini_card_icon`]);

		this.addChild(
			bg,
			icon,
		);

		if (stickerNew && !model.isOpened()) {
			this.stickerNew = stickerNew;
			this.model.once(PromotableModel.EVENT_OLD, this.onModelOld, this);

			this.addChild(this.stickerNew);
		}
	}

	private onModelOld(): void {
		this.removeChild(this.stickerNew);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.model.listeners(PromotableModel.EVENT_OLD).includes(this.onModelOld)) {
			this.model.off(PromotableModel.EVENT_OLD, this.onModelOld, this, true);
		}
		super.destroy(options);
	}
}
