import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { Emitter } from 'pixi-particles';
import { QuestParticleConfig } from '../../ui/quests/QuestParticleConfig';
import { QuestWindowBaseView, QuestWindowViewMode } from './QuestWindowBaseView';
import { TimedQuestUIHelper } from '../../ui/quests/TimedQuestUIHelper';
import { NumberUtils } from '@src/utils/NumberUtils';

export class TimedQuestWindowView extends QuestWindowBaseView {
	private readonly tickerTimer: PIXI.ticker.Ticker;

	private tweenLootboxFloat: TWEEN.Tween;

	private readonly timerIcon: PIXI.Sprite;
	private readonly timerContainer: PIXI.Container;
	private readonly timerLabel: PIXI.extras.BitmapText;

	private animationEmitters: Emitter[];

	constructor(
		quest: AbstractQuest,
		mode?: QuestWindowViewMode,
	) {
		super(quest, mode);

		this.quest.once(AbstractQuest.EVENT_TIMEOUT, this.onClose, this);

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.animationEmitters = [];

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('lootboxAtlas')['white_bg'], 13, 22, 13, 22);
		whiteBg.width = 870;
		whiteBg.height = 580;
		whiteBg.y = 133;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);

		const headerBg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['side_quest_bg_header']);
		headerBg.position.set(0, -295);

		const infoPanel = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['side_quest_unfo_panel']);
		infoPanel.position.set(0, -194);

		const bgFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('lootboxAtlas')['lootbox_bg_fade'], 2, 2, 2, 2);
		bgFade.width = 844;
		bgFade.height = 132;
		bgFade.position.set(0, 340);
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

		const textDescription = new SizeableMultiColoredBitmapText(940, { font: '41px wendyOneShadowBold' });
		textDescription.text = TimedQuestUIHelper.getQuestDescription(this.quest, true);
		textDescription.y = -192;
		textDescription.anchor = 0.5;

		const timePanel = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel'], 37, 0, 37, 0);
		timePanel.width = 348;
		timePanel.height = 125;
		timePanel.pivot.set(timePanel.width / 2, timePanel.height / 2);
		timePanel.position.set(0, -150);

		this.timerLabel = new PIXI.extras.BitmapText('', { font: '42px wendyOneShadowBold', tint: 0xffe03a });
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(20, -118);

		this.timerIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['timer_icon']);
		this.timerIcon.scale.set(0.5);

		const animationsContainer = new PIXI.Container();
		const glowEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['skill_activ_glow1']],
			QuestParticleConfig.getQuestGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.timerContainer = new PIXI.Container();
		this.timerContainer.addChild(
			timePanel,
			this.timerLabel as PIXI.DisplayObject,
			this.timerIcon,
		);

		const dotEmitter = new Emitter(
			animationsContainer,
			[fxAtlas['party_glow1']],
			QuestParticleConfig.getQuestFlare(),
		);
		dotEmitter.autoUpdate = true;
		this.animationEmitters.push(dotEmitter);

		animationsContainer.scale.y = 0.4;
		animationsContainer.scale.x = 0.4;
		animationsContainer.y = -320;

		this.lootboxSprite.setTransform(0, -360, 0.4, 0.4);

		this.tweenLootboxFloat = new TWEEN.Tween(this.lootboxSprite)
			.to({ y: this.lootboxSprite.y + 10 }, 1200)
			.repeat(Infinity)
			.yoyo(true)
			.start();

		this.tickerTimer = PIXI.ticker.shared;
		this.tickerTimer.add(this.onUpdateTextTimer, this);

		this.updateTimerContainerVisible();

		this.mainContainer.interactive = true;
		this.mainContainer.addChild(
			animationsContainer,
			whiteBg,
			headerBg as PIXI.DisplayObject,
			this.timerContainer,
			this.lootboxSprite,
			infoPanel,
			bgFade,
			textDescription,
			this.buttonAction,
			this.rewardsPreview,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	protected createActionButtonGo(): ButtonBaseView {
		const buttonGo = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 290, 99),
			{ font: '50px wendyOneShadowBold' },
			200,
		);
		buttonGo.y = 340;
		buttonGo.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#quest_ButtonGo'));
		return buttonGo;
	}

	protected createActionButtonClaim(): ButtonBaseView {
		const buttonCollect = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 290, 99),
			{ font: '50px wendyOneShadowBold' },
			200,
		);
		buttonCollect.y = 340;
		buttonCollect.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#quest_ButtonClaim'));

		if (this.quest instanceof AbstractQuest && !this.quest.hasLootbox()) {
			buttonCollect.setEnabled(false);
		}
		return buttonCollect;
	}

	private onUpdateTextTimer(): void {
		this.timerLabel.text = NumberUtils.secToDHMSColonFormatted(this.quest.getTimeleft());
		this.timerIcon.position.set(this.timerLabel.x - this.timerLabel.width / 2 - 20, -118);
	}

	protected onQuestCompleted(): void {
		super.onQuestCompleted();
		this.updateTimerContainerVisible();
	}

	private updateTimerContainerVisible(): void {
		this.timerContainer.visible = !this.quest.isCompleted();
		this.rewardsPreview.y = this.quest.isCompleted() ? 65 : 95;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tweenLootboxFloat.stop();

		this.quest.off(AbstractQuest.EVENT_TIMEOUT, this.onClose, this, true);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.tickerTimer.remove(this.onUpdateTextTimer, this);

		super.destroy(options);
	}
}
