import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';

export class NotificationWindowView extends PopupWindowBaseView {
	constructor(
		title: string,
		body: string,
		buttonLabel: string,
	) {
		super(0);


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 555;
		bg.height = 331;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const bgWhite = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		bgWhite.width = 512;
		bgWhite.height = 128;
		bgWhite.pivot.set(bgWhite.width / 2, bgWhite.height / 2);
		bgWhite.y = -17;

		const bgGray = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		bgGray.tint = 0x000000;
		bgGray.width = 125;
		bgGray.height = 120;
		bgGray.tint = 0xf0f0f0;
		bgGray.position.set(-190, -17);

		const textTitle = new SizeableBitmapText(title, 750, { font: '34px wendyOneShadowBold' });
		textTitle.anchor = 0.5;
		textTitle.y = -120;

		const textBody = new TextField(
			body,
			{ font: '22px wendyOne', tint: 0x465768, align: 'left' },
			275,
			95,
		);
		textBody.position.set(37, -17);
		textBody.anchor = 0.5;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_icon2']);
		icon.position.set(-190, -17);

		const buttonOk = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 196, 69),
			{ font: '32px wendyOneShadowBold' },
			300,
		);
		buttonOk.setTextLabel(buttonLabel);
		buttonOk.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);
		buttonOk.position.y = 100;

		this.mainContainer.addChild(
			bg,
			bgWhite,
			bgGray,
			textTitle as PIXI.DisplayObject,
			textBody,
			icon,
			buttonOk,
		);
	}
}
