import { DialogModel } from '@models/DialogModel';
import { DialogConfig } from '@configs/DialogConfig';
import { DialogUnlockTypes, UnlockTypeLevelProgressData, UnlockTypeEventProgressData } from '@src/types/DialogTypes';

export class DialogsFactory {
	private configs: Map<string, DialogConfig>;
	private configsOnLevel: Map<string, DialogConfig>;

	constructor(configs: Map<string, DialogConfig>) {
		this.configs = configs;
		this.configsOnLevel = new Map();
	}

	public init(levelKey: string): void {
		this.configsOnLevel.clear();

		this.configs.forEach((config) => {
			const unlockData = config.getUnlockData();
			if (unlockData) {
				if (unlockData.type === DialogUnlockTypes.LEVEL_PROGRESS) {
					const data = unlockData.data as UnlockTypeLevelProgressData;
					if (data.level === levelKey) {
						this.configsOnLevel.set(data.progress, config);
					}
				} else if (unlockData.type === DialogUnlockTypes.EVENT_PROGRESS) {
					const data = unlockData.data as UnlockTypeEventProgressData;
					if (data.eventKey === levelKey) {
						this.configsOnLevel.set(data.progress, config);
					}
				}
			}
		});
	}

	public hasStartDialog(): boolean {
		return this.configsOnLevel.has('0');
	}

	public hasEndDialog(): boolean {
		return this.configsOnLevel.has(DialogConfig.UNLOCK_VALUE_END);
	}

	public hasProgressDialog(progress: number): boolean {
		return this.configsOnLevel.has(progress.toString());
	}

	public isDialogCreatable(key: string): boolean {
		return this.configs.has(key);
	}

	public createStartDialog(): DialogModel {
		return this.createProgressDialogLocal('0', true);
	}

	public createProgressDialog(levelProgress: number): DialogModel {
		return this.createProgressDialogLocal(levelProgress.toString());
	}

	public createEndDialog(): DialogModel {
		return this.createProgressDialogLocal(DialogConfig.UNLOCK_VALUE_END);
	}

	public createDialog(key: string): DialogModel {
		const dialog = new DialogModel(this.configs.get(key));
		return dialog;
	}

	private createProgressDialogLocal(
		unlockValue: string,
		levelStartDialog?: boolean,
	): DialogModel {
		const dialog = new DialogModel(this.configsOnLevel.get(unlockValue), levelStartDialog);
		return dialog;
	}
}
