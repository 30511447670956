import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { PromotableModel } from '@models/PromotableModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { CollectionWindowOpenConfig, CollectionWindowTabs, CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { BankTabTypes } from '@src/types/BankTypes';
import { ModelHelper } from '@models/ModelHelper';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { RewardResourceIdTypes } from '@src/types/RewardTypes';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { GameUIBaseViewSetter } from '@interfaces/ViewSetters';

export abstract class BasePromoteWindowViewController extends PIXI.utils.EventEmitter implements GameUIBaseViewSetter {
	protected readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private readonly summonModelsPool: SummonModelsPool;
	private readonly questTargetDuration: number;
	protected gameUI: GameUIBaseView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		prestigeMoneyModel: PrestigeMoneyModel,
		summonModelsPool: SummonModelsPool,
		questTargetDuration: number,
	) {
		super();
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.summonModelsPool = summonModelsPool;
		this.questTargetDuration = questTargetDuration;
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
	}

	protected onButtonPromoteClick(model: PromotableModel): void {
		const isEnoughCards = model.isEnoughCardsForPromote();
		const isEnoughPrestigeMoney = this.prestigeMoneyModel.getValue() >= model.getPromoteCostPrestige();
		if (!isEnoughCards) {
			if (this.gameUI.isCollectionUnlocked()) {
				this.showCollectionWindow();
			} else {
				this.onCollectionLocked();
			}
		} else if (!isEnoughPrestigeMoney) {
			if (this.gameUI.isBankUnlocked()) {
				this.showBankWindowAndPoint();
			} else {
				this.onBankLocked();
			}
		} else {
			this.prestigeMoneyModel.subtract(model.getPromoteCostPrestige());
			model.promote();

			const action = this.createAction(model);
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	protected onButtonMoneyPlus(): void {
		if (this.gameUI.isBankUnlocked()) {
			this.showBankWindow();
		} else {
			this.onBankLocked();
		}
	}

	private showCollectionWindow(): void {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());
		let openConfig: CollectionWindowOpenConfig;
		if (model) {
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, (collectionWindow: CollectionWindowView) => {
				collectionWindow.once(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, (summonCollectionView: SummonCollectionView) => {
					const target = summonCollectionView.getButtonSummonContainer(model.getKey());
					const show = new ShowQuestTargetView(this.questTargetDuration, target);
					target.parent.addChild(show);
				});
			});
			openConfig = {
				tab: CollectionWindowTabs.TAB_SUMMON,
				focusKey: model.getKey(),
			};
		} else {
			openConfig = { tab: CollectionWindowTabs.TAB_SUMMON };
		}
		const window = this.windowFactory.createCollectionWindow(openConfig);
		this.windowViewSystem.showWindow(window);
	}

	private showBankWindowAndPoint(): void {
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, (bankWindow: BankWindowView) => {
			bankWindow.pointAtResource(RewardResourceIdTypes.PRESTIGE_MONEY, this.questTargetDuration);
		});
		this.showBankWindow();
	}

	private showBankWindow(): void {
		const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(window);
	}

	protected abstract createAction(model: PromotableModel): BaseAction;
	protected abstract onCollectionLocked(): void;
	protected abstract onBankLocked(): void;
}
