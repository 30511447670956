import { UnlockValueChecker } from '../../UnlockValueChecker';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';
import { BankSavesModel } from '@models/bank/BankSavesModel';

export class BankOfferConfigsFilter {
	constructor(
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly bankSavesModel: BankSavesModel,
	) {}

	public filter(source: Map<string, BankOfferConfig>, currentTime: number): Map<string, BankOfferConfig> {
		const filteredMap: Map<string, BankOfferConfig> = new Map();

		source.forEach((config: BankOfferConfig) => {
			const activationCount = this.bankSavesModel.getOfferActivateCount(config.getKey());
			const savedEndTime = this.bankSavesModel.getOfferEndTime(config.getKey());
			const isAvailable = config.getActivateTimes() > activationCount
				// for bundles with refresh
				|| config.getActivateTimes() === activationCount && savedEndTime > currentTime;

			if (isAvailable) {
				const isUnlocked = this.isUnlocked(config);
				let isTimeValid = true;

				if (config.hasDates()) {
					const startTime = config.getStartTimestamp();
					const endTime = config.getEndTimestamp();

					isTimeValid = startTime <= currentTime && endTime > currentTime;
				}

				if (isTimeValid && (isUnlocked || (!isUnlocked && !config.getEndsLocked() && savedEndTime > currentTime))) {
					filteredMap.set(config.getKey(), config);
				}
			}
		});

		return filteredMap;
	}

	private isUnlocked(config: BankOfferConfig): boolean {
		let isUnlocked = true;

		if (config.hasSomeUnlockTypeValue()) {
			const unlockTypes = config.getUnlockTypes();
			const unlockValues = config.getUnlockValues();

			for (let i = 0; i < unlockTypes.length && isUnlocked; i++) {
				const unlockType = unlockTypes[i];
				const unlockValue = unlockValues[i];
				isUnlocked = this.unlockValueChecker.check(unlockType, unlockValue);
			}
		}

		return isUnlocked;
	}
}
