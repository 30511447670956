import { UpgradeCardLockView } from '@views/windows/collection/upgrades/UpgradeCardLockView';
import { UpgradeModel } from '@models/UpgradeModel';
import { UpgradeCardView } from '@views/ui/cards/UpgradeCardView';
import { AbstractCollectionItemView } from '@views/windows/collection/AbstractCollectionItemView';
import { PromotableBaseCardView } from '@views/ui/cards/PromotableBaseCardView';

export class UpgradeCollectionsCardView extends AbstractCollectionItemView {
	private readonly model: UpgradeModel;

	private lockView: UpgradeCardLockView;
	protected openView: UpgradeCardView;

	constructor(
		model: UpgradeModel,
	) {
		super();

		this.model = model;

		if (this.model.isOpened()) {
			this.onOpened();
		} else {
			this.model.once(UpgradeModel.EVENT_OPENED, this.onOpened, this);

			this.lockView = new UpgradeCardLockView(this.model);
			this.addChild(this.lockView);
		}
	}

	protected showOpened(): void {
		this.openView = new UpgradeCardView(
			this.model,
		);
		this.openView.scale.set(0.435);
		this.addChildAt(this.openView, 0);

		if (this.lockView) {
			this.removeChild(this.lockView);
			this.lockView = null;
		}

		this.openView.on(PromotableBaseCardView.EVENT_CLICK, this.onOpenCardClick, this);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.model.listeners(UpgradeModel.EVENT_OPENED).includes(this.showOpened)) {
			this.model.off(UpgradeModel.EVENT_OPENED, this.showOpened, this, true);
		}

		super.destroy(options);
	}
}
