import { AbstractQuest } from '@models/quests/AbstractQuest';
import LocalizationStorage from '@main/LocalizationStorage';
import { QuestTypes } from '@src/types/QuestTypes';
import { QuestAcquireBusiness } from '@models/quests/QuestAcquireBusiness';
import { TextUtils } from '@src/utils/TextUtils';
import { QuestGetCards } from '@models/quests/QuestGetCards';
import { QuestGetCustomersAll } from '@models/quests/QuestGetCustomersAll';
import { QuestGetCustomersMultiplier } from '@models/quests/QuestGetCustomersMultiplier';
import { QuestGetCustomersOnSome } from '@models/quests/QuestGetCustomersOnSome';
import { QuestGetCustomersTarget } from '@models/quests/QuestGetCustomersTarget';
import { QuestActivateTarget } from '@models/quests/QuestActivateTarget';
import { QuestActivateTargets } from '@models/quests/QuestActivateTargets';
import { QuestPromoteTarget } from '@models/quests/QuestPromoteTarget';
import { QuestPromoteTargets } from '@models/quests/QuestPromoteTargets';
import { QuestGetSoftMoneyFromBusiness } from '@models/quests/QuestGetSoftMoneyFromBusiness';
import { QuestAcquireBusinesses } from '@models/quests/QuestAcquireBusinesses';
import { QuestAutomateBusiness } from '@models/quests/QuestAutomateBusiness';
import { QuestAutomateBusinesses } from '@models/quests/QuestAutomateBusinesses';
import { QuestSummon } from '@models/quests/QuestSummon';
import { QuestActivateParty } from '@models/quests/QuestActivateParty';
import { UpgradeModel } from '@models/UpgradeModel';
import { CharacterModel } from '@models/CharacterModel';

export class QuestUIHelper {
	public static getQuestDescription(quest: AbstractQuest, alternateLocaleKey: boolean = false): string {
		const localizationStorage = LocalizationStorage.getInstance();
		let textQuest = '';

		const getSummonQuestText = (popupKey: string, defaultKey: string): string => {
			let text = alternateLocaleKey
				? localizationStorage.getLocalizedString(popupKey)
				: localizationStorage.getLocalizedString(defaultKey);

			const value = (quest as QuestSummon).getTargetAmount();

			text = text.replace('{{value}}', value.toString());

			return TextUtils.getWordEnding(localizationStorage.getLanguage(), value, text);
		};

		switch (quest.getType()) {
			case QuestTypes.ACQUIRE_BUSINESS: {
				const targetKey = (quest as QuestAcquireBusiness).getTargetKey();
				const businessName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_AcquireBusinessTarget')
					: localizationStorage.getLocalizedString('#quest_AcquireBusinessTarget');
				textQuest = textQuest.replace('{{targetKey}}', businessName);
				break;
			}
			case QuestTypes.ACQUIRE_BUSINESSES: {
				const targetCount = (quest as QuestAcquireBusinesses).getTargetCount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_AcquireBusinesses')
					: localizationStorage.getLocalizedString('#quest_AcquireBusinesses');
				textQuest = textQuest.replace('{{value}}', targetCount.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetCount, textQuest);
				break;
			}
			case QuestTypes.AUTOMATE_BUSINESS: {
				const targetKey = (quest as QuestAutomateBusiness).getTargetKey();
				const businessName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_AutomateBusinessTarget')
					: localizationStorage.getLocalizedString('#quest_AutomateBusinessTarget');
				textQuest = textQuest.replace('{{targetKey}}', businessName);
				break;
			}
			case QuestTypes.AUTOMATE_BUSINESSES: {
				const targetCount = (quest as QuestAutomateBusinesses).getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_AutomateBusinesses')
					: localizationStorage.getLocalizedString('#quest_AutomateBusinesses');
				textQuest = textQuest.replace('{{value}}', targetCount.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetCount, textQuest);
				break;
			}
			case QuestTypes.ACTIVATE_CHARACTER: {
				const questActivate = quest as QuestActivateTarget<CharacterModel>;
				const targetKey = questActivate.getTargetKey();
				const relatedBusinessName = localizationStorage.getLocalizedString(`#${questActivate.getTargetBusinessKey()}_Name`);
				const name = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_ActivateGirlsTarget')
					: localizationStorage.getLocalizedString('#quest_ActivateGirlsTarget');
				textQuest = textQuest.replace('{{targetKey}}', name)
					.replace('{{business_name}}', relatedBusinessName);
				break;
			}
			case QuestTypes.ACTIVATE_CHARACTERS: {
				const questActivate = quest as QuestActivateTargets<CharacterModel>;
				const targetValue = questActivate.getActivateCount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_ActivateGirls')
					: localizationStorage.getLocalizedString('#quest_ActivateGirls');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.ACTIVATE_UPGRADE: {
				const questActivate = quest as QuestActivateTarget<UpgradeModel>;
				const upgradeName = localizationStorage.getLocalizedString(`#${questActivate.getTargetKey()}_Name`);
				const businessName = localizationStorage.getLocalizedString(`#${questActivate.getTargetBusinessKey()}_Name`);
				textQuest = (alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_ActivateUpgradeTarget')
					: localizationStorage.getLocalizedString('#quest_ActivateUpgradeTarget'))
					.replace('{{targetKey}}', upgradeName)
					.replace('{{business_name}}', businessName);
				break;
			}
			case QuestTypes.ACTIVATE_UPGRADES: {
				const questActivate = quest as QuestActivateTargets<UpgradeModel>;
				const targetValue = questActivate.getActivateCount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_ActivateUpgrade')
					: localizationStorage.getLocalizedString('#quest_ActivateUpgrade');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.ACTIVATE_PARTY: {
				const questActivate = quest as QuestActivateParty;
				const targetValue = questActivate.getTargetAmount();

				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_ActivateParty')
					: localizationStorage.getLocalizedString('#quest_ActivateParty');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CARDS: {
				const targetValue = (quest as QuestGetCards).getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_FindCardsAll')
					: localizationStorage.getLocalizedString('#quest_FindCardsAll');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_ALL: {
				const targetValue = (quest as QuestGetCustomersAll).getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomers')
					: localizationStorage.getLocalizedString('#quest_GetCustomers');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_TARGET: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomersTarget')
					: localizationStorage.getLocalizedString('#quest_GetCustomersTarget');
				const targetKey = (quest as QuestGetCustomersTarget).getBusinessKey();
				const businessName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				const targetValue = (quest as QuestGetCustomersTarget).getTargetAmount();
				textQuest = textQuest
					.replace('{{value}}', targetValue.toString())
					.replace('{{targetKey}}', businessName);
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_PRESTIGE_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetPrestigeMoney')
					: localizationStorage.getLocalizedString('#quest_GetPrestigeMoney');
				textQuest = textQuest.replace('{{value}}', quest.getTotalProgressValue());
				break;
			}
			case QuestTypes.GET_SOFT_MONEY_TUTORIAL:
			case QuestTypes.GET_SOFT_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetSoftMoney')
					: localizationStorage.getLocalizedString('#quest_GetSoftMoney');
				textQuest = textQuest.replace('{{value}}', quest.getTotalProgressValue());
				break;
			}
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS_TUTORIAL:
			case QuestTypes.GET_SOFT_MONEY_FROM_BUSINESS: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetSoftMoneyFromBusiness')
					: localizationStorage.getLocalizedString('#quest_GetSoftMoneyFromBusiness');
				const businessKey = (quest as QuestGetSoftMoneyFromBusiness).getBusinessKey();
				const businessName = localizationStorage.getLocalizedString(`#${businessKey}_Name`);
				textQuest = textQuest
					.replace('{{value}}', quest.getTotalProgressValue())
					.replace('{{targetKey}}', businessName);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTER: {
				const questPromote = quest as QuestPromoteTarget<CharacterModel>;
				const targetKey = questPromote.getTargetKey();
				const characterName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteCharacterTarget')
					: localizationStorage.getLocalizedString('#quest_PromoteCharacterTarget');
				textQuest = textQuest
					.replace('{{targetKey}}', characterName)
					.replace('{{value}}', questPromote.getTargetAmount().toString());
				break;
			}
			case QuestTypes.PROMOTE_CHARACTERS: {
				const questPromote = quest as QuestPromoteTargets<CharacterModel>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteCharacters')
					: localizationStorage.getLocalizedString('#quest_PromoteCharacters');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.PROMOTE_UPGRADE: {
				const questPromote = quest as QuestPromoteTarget<UpgradeModel>;
				const targetKey = questPromote.getTargetKey();
				const characterName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteUpgradeTarget')
					: localizationStorage.getLocalizedString('#quest_PromoteUpgradeTarget');
				textQuest = textQuest
					.replace('{{targetKey}}', characterName)
					.replace('{{value}}', questPromote.getTotalProgressValue());
				break;
			}
			case QuestTypes.PROMOTE_UPGRADES: {
				const questPromote = quest as QuestPromoteTargets<UpgradeModel>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteUpgrades')
					: localizationStorage.getLocalizedString('#quest_PromoteUpgrades');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.PROMOTE_CHARACTERS_OR_UPGRADES: {
				const questPromote = quest as QuestPromoteTargets<UpgradeModel | CharacterModel>;
				const targetValue = questPromote.getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_PromoteCharactersOrUpgrades')
					: localizationStorage.getLocalizedString('#quest_PromoteCharactersOrUpgrades');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER: {
				const targetValue = (quest as QuestGetCustomersMultiplier).getTargetAmount();
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomersMultiplier')
					: localizationStorage.getLocalizedString('#quest_GetCustomersMultiplier');
				textQuest = textQuest.replace('{{value}}', targetValue.toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT: {
				const targetValue = (quest as QuestGetCustomersMultiplier).getTargetAmount();
				const targetKey = (quest as QuestGetCustomersMultiplier).getTargetKeys()[0];
				const businessName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomersMultiplierTargetFromCurrent')
					: localizationStorage.getLocalizedString('#quest_GetCustomersMultiplierTargetFromCurrent');
				textQuest = textQuest
					.replace('{{value}}', targetValue.toString())
					.replace('{{targetKey}}', businessName);
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO: {
				const targetValue = (quest as QuestGetCustomersMultiplier).getTargetAmount();
				const targetKey = (quest as QuestGetCustomersMultiplier).getTargetKeys()[0];
				const businessName = localizationStorage.getLocalizedString(`#${targetKey}_Name`);
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomersMultiplierTargetFromZero')
					: localizationStorage.getLocalizedString('#quest_GetCustomersMultiplierTargetFromZero');
				textQuest = textQuest
					.replace('{{value}}', targetValue.toString())
					.replace('{{targetKey}}', businessName);
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.SPEND_PRESTIGE_MONEY: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_SpendPrestigeMoney')
					: localizationStorage.getLocalizedString('#quest_SpendPrestigeMoney');
				textQuest = textQuest.replace('{{value}}', quest.getTotalProgressValue());
				break;
			}
			case QuestTypes.GET_CUSTOMERS_ON_SOME: {
				textQuest = alternateLocaleKey
					? localizationStorage.getLocalizedString('#quest_popup_GetCustomersOnSome')
					: localizationStorage.getLocalizedString('#quest_GetCustomersOnSome');
				const targetValue = (quest as QuestGetCustomersOnSome).getTargetCustomersCount();
				textQuest = textQuest
					.replace('{{value}}', targetValue.toString())
					.replace('{{count}}', (quest as QuestGetCustomersOnSome).getTargetBusinessesCount().toString());
				textQuest = TextUtils.getWordEnding(localizationStorage.getLanguage(), targetValue, textQuest);
				break;
			}
			case QuestTypes.SUMMON_ALL: {
				textQuest = getSummonQuestText('#quest_popup_OpenSummon', '#quest_OpenSummon');
				break;
			}
			case QuestTypes.SUMMON_HARD: {
				textQuest = getSummonQuestText('#quest_popup_OpenSummon_hard', '#quest_OpenSummon_hard');
				break;
			}
			case QuestTypes.SUMMON_PRESTIGE: {
				textQuest = getSummonQuestText('#quest_popup_OpenSummon_prestige', '#quest_OpenSummon_prestige');
				break;
			}
			case QuestTypes.SUMMON_PAY: {
				textQuest = getSummonQuestText('#quest_popup_OpenSummon_pay', '#quest_OpenSummon_pay');
				break;
			}
			default:
				throw new Error(`Cannot resolve text for quest type: ${quest.getType()}`);
		}

		return textQuest;
	}
}
