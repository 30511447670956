import { BaseValidator } from "../BaseValidator";

export class EmailNamePartBadSymbolsValidator extends BaseValidator<string> {
	private static readonly REG_EXP = /[^a-z0-9.!#$%&'*+\/=?^_`{|}~-]/;

	private matchResult: RegExpMatchArray;

	public isValid(value: string): boolean {
		const name = value.split('@')[0];
		this.matchResult = name.match(EmailNamePartBadSymbolsValidator.REG_EXP);
		return this.matchResult === null;
	}

	public getErrorMessage(): string {
		let badSymbol: string = this.matchResult[0];
		if (badSymbol === ' ') {
			badSymbol = this.localizationStorage.getLocalizedString('#space');
		}
		return super.getErrorMessage()
			.replace('{value}', badSymbol);
	}
}
