import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60010View } from '@views/characters/events/event6/EventCharacter60010View';
import { EventCharacter60011View } from '@views/characters/events/event6/EventCharacter60011View';
import { EventCharacter60012View } from '@views/characters/events/event6/EventCharacter60012View';

export class EventBusiness60004View extends BusinessBaseView {
	constructor() {
		const eventCharacter60010View = new EventCharacter60010View();
		const eventCharacter60011View = new EventCharacter60011View();
		const eventCharacter60012View = new EventCharacter60012View();

		super('business60004', [eventCharacter60010View, eventCharacter60011View, eventCharacter60012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter60012View,
			eventCharacter60010View as PIXI.DisplayObject,
			eventCharacter60011View,
		);
	}
}
