import { BonusTypes } from '@src/types/BonusTypes';
import { CalculationType } from '@src/types/CalculationType';

export abstract class BaseBoostModel extends PIXI.utils.EventEmitter {
	public static calculateMultiplicator(models: Set<BaseBoostModel> | BaseBoostModel[]): number {
		let sum: number = 0;
		let mult: number = 1;

		models.forEach((model: BaseBoostModel) => {
			switch (model.getCalculationType()) {
				case CalculationType.MULTIPLICATION: {
					mult *= model.getBonusValue();
					break;
				}

				case CalculationType.SUM: {
					sum += model.getBonusValue();
					break;
				}

				default:
					throw new Error(`unknown multiplication type for model '${model.getKey()}`);
			}
		});

		return Math.max(1, sum) * mult;
	}

	public static readonly EVENT_ACQUIRED: symbol = Symbol();
	public static readonly EVENT_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_DEACTIVATED: symbol = Symbol();

	protected activated: boolean;

	protected activateCount: number;
	protected currentActivateCount: number;
	protected timeleft: number;
	protected time: number;
	protected key: string;
	protected bonusType: BonusTypes;
	protected bonusValue: number;
	protected calculationType: CalculationType;

	constructor() {
		super();

		this.activated = false;
		this.timeleft = 0;
		this.activateCount = 0;
		this.currentActivateCount = 0;
	}

	public deactivate(): void {
		this.activated = false;
		this.activateCount -= this.currentActivateCount;
		this.currentActivateCount = 0;
		this.emit(BaseBoostModel.EVENT_DEACTIVATED, this);
	}

	public acquire(quantity: number = 1): void {
		this.activateCount += quantity;
		this.emit(BaseBoostModel.EVENT_ACQUIRED, this);
	}

	public setTimeleft(value: number): void {
		this.timeleft = value;
	}

	public getKey(): string {
		return this.key;
	}

	public getTime(): number {
		return this.time;
	}

	public getTimeleft(): number {
		return this.timeleft;
	}

	public getBonusType(): BonusTypes {
		return this.bonusType;
	}

	public getBonusValue(): number {
		return this.bonusValue;
	}

	public isActivated(): boolean {
		return this.activated;
	}

	public getCurrentActivateCount(): number {
		return this.currentActivateCount;
	}

	public getActivateCount(): number {
		return this.activateCount;
	}

	public canActivate(): boolean {
		return this.currentActivateCount < this.activateCount;
	}

	public getCalculationType(): CalculationType {
		return this.calculationType;
	}

	protected onActivate(): void {
		this.activated = true;
		this.currentActivateCount += 1;
		this.emit(BaseBoostModel.EVENT_ACTIVATED, this);
	}
}
