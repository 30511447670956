import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { GirlPrefabHintOffset } from '@src/types/SkillTypes';

export class SkillConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_ORDER: string = 'order';
	public static readonly FIELD_TYPE: string = 'type';
	public static readonly FIELD_MULTIPLIER: string = 'multiplier';
	public static readonly FIELD_RELOAD_TIME: string = 'reload_time';
	public static readonly FIELD_ACTIVE_TIME: string = 'active_time';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';

	public static readonly FIELD_ICON: string = 'icon';
	public static readonly FIELD_GIRL_PREFAB: string = 'prefab_girl';
	public static readonly FIELD_GIRL_PREFAB_HINT_OFFSET: string = 'prefab_girl_hint_offset';

	public static readonly FIELDS: string[] = [
		SkillConfig.FIELD_KEY,
		SkillConfig.FIELD_ORDER,
		SkillConfig.FIELD_TYPE,
		SkillConfig.FIELD_MULTIPLIER,
		SkillConfig.FIELD_ACTIVE_TIME,
		SkillConfig.FIELD_RELOAD_TIME,
		SkillConfig.FIELD_UNLOCK_TYPE,
		SkillConfig.FIELD_UNLOCK_VALUE,
		SkillConfig.FIELD_GIRL_PREFAB_HINT_OFFSET,
	];

	public static readonly OPTIONAL_FIELDS: string[] = [
		SkillConfig.FIELD_ACTIVE_TIME,
		SkillConfig.FIELD_UNLOCK_TYPE,
		SkillConfig.FIELD_UNLOCK_VALUE,
	];

	private readonly key: string;
	private readonly order: number;
	private readonly type: string;
	private readonly reloadTime: number;
	private readonly multipliers: number[];
	private readonly activeTimes: number[];
	private readonly girlPrefab: string;
	private readonly girlPrefabHintOffset: GirlPrefabHintOffset;
	private readonly iconKey: string;

	constructor(config: { [key: string]: string }) {
		this.key = config[SkillConfig.FIELD_KEY].trim();

		this.order = Number(config[SkillConfig.FIELD_ORDER].trim());
		this.type = config[SkillConfig.FIELD_TYPE].trim();

		const multipliersRaw = config[SkillConfig.FIELD_MULTIPLIER].trim().split(',');
		this.multipliers = multipliersRaw.map((m: string) => Number(m.trim()));

		const activeTimeRaw = config[SkillConfig.FIELD_ACTIVE_TIME];
		if (activeTimeRaw) {
			this.activeTimes = activeTimeRaw
				.trim()
				.split(',')
				.map((m: string) => Number(m.trim()));
		}

		this.reloadTime = Number(config[SkillConfig.FIELD_RELOAD_TIME].trim());
		this.girlPrefab = config[SkillConfig.FIELD_GIRL_PREFAB].trim();
		this.iconKey = config[SkillConfig.FIELD_ICON].trim();

		const girlPrefabHintOffsetsRaw = config[SkillConfig.FIELD_GIRL_PREFAB_HINT_OFFSET].trim().split(',');
		this.girlPrefabHintOffset = {
			x: Number(girlPrefabHintOffsetsRaw[0].trim()),
			y: Number(girlPrefabHintOffsetsRaw[1].trim()),
		};
	}

	public getKey(): string {
		return this.key;
	}

	public getOrder(): number {
		return this.order;
	}

	public getReloadTime(): number {
		return this.reloadTime;
	}

	public getActiveTimes(): number[] {
		return this.activeTimes;
	}

	public hasActivateTimes(): boolean {
		return Boolean(this.activeTimes);
	}

	public getMultipliers(): number[] {
		return this.multipliers;
	}

	public getType(): string {
		return this.type;
	}

	public getGirlPrefab(): string {
		return this.girlPrefab;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getGirlPrefabHintOffset(): GirlPrefabHintOffset {
		return this.girlPrefabHintOffset;
	}
}
