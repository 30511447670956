import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AnalyticTargetClickhouse } from './targets/AnalyticTargetClickhouse';
import { AnalyticTargetXOffers } from './targets/AnalyticTargetXOffers';
import { AnalyticSourceBankViews } from './sources/AnalyticSourceBankViews';
import { AnalyticSourceLevelPreloaderView } from './sources/AnalyticSourceLevelPreloaderView';
import { AnalyticSourceLevelStartWindowView } from './sources/AnalyticSourceLevelStartWindowView';
import { AnalyticSourceModels } from './sources/AnalyticSourceModels';
import { AnalyticTargetPixel } from './targets/AnalyticTargetPixel';
import { AnalyticSourceFarewellPartyWindowView } from './sources/AnalyticSourceFarewellPartyWindowView';
import { AnalyticTargetTrafficStars } from './targets/AnalyticTargetTrafficStars';
import { AnalyticSourceGameProfileModel } from './sources/AnalyticSourceGameProfileModel';
import { AnalyticTargetPropellerAds } from './targets/AnalyticTargetPropellerAds';
import { AnaylticTargetClickadu } from './targets/AnalyticTargetClickadu';
import { AnalyticsPlatform, TrackingData } from '@src/types/AnalyticsTypes';

export class AnalyticsSender {
	private static readonly TARGET_TRAFFIC_STARS_KEY: string = 'traffic_stars';
	private static readonly TARGET_CLICKADU_KEY: string = 'clickadu';
	private static readonly TARGET_PROPELLER_ADS_KEY: string = 'propeller_ads';

	private targetClickhouse: AnalyticTargetClickhouse;
	private targetXOffers: AnalyticTargetXOffers;
	private targetPixel: AnalyticTargetPixel;
	private targetTrafficStars: AnalyticTargetTrafficStars;
	private targetPropellerAds: AnalyticTargetPropellerAds;
	private targetClickadu: AnaylticTargetClickadu;

	private playerId: string;
	private sessionId: string;
	private deviceId: string;
	private platform: AnalyticsPlatform;
	private trackingData?: TrackingData;

	constructor(
		private readonly serverTimeModel: ServerTimeModel,
		private readonly version: string,
	) {}

	public init(
		serverUrl: string,
		targetClickhouseEnabled: boolean,
		targetXOffersEnabled: boolean,
		targetPixelEnabled: boolean,
		targetTrafficStarsEnabled: boolean,
		targetPropellerAdsEnabled: boolean,
		targetClickaduEnabled: boolean,
		clickHouseSessionPingInterval: number,
		eventRankProgressUpdateInterval: number,
	): void {
		if (targetClickhouseEnabled) {
			this.targetClickhouse = new AnalyticTargetClickhouse(
				this.serverTimeModel,
				this.sessionId,
				this.playerId,
				this.deviceId,
				this.platform,
				clickHouseSessionPingInterval,
				eventRankProgressUpdateInterval,
				this.version,
			);
		}

		if (targetXOffersEnabled && typeof XOffers !== 'undefined') {
			this.targetXOffers = new AnalyticTargetXOffers(
				this.sessionId,
				this.playerId,
				this.deviceId,
				serverUrl,
				this.version,
				this.trackingData,
			);
		}

		if (targetPixelEnabled) {
			this.targetPixel = new AnalyticTargetPixel(this.trackingData);
		}

		if (targetTrafficStarsEnabled) {
			this.targetTrafficStars = new AnalyticTargetTrafficStars(this.trackingData);
			this.targetTrafficStars.on(
				AnalyticTargetTrafficStars.EVENT_RESPONSE,
				(response) => this.onSomeResponseReceived(response, AnalyticsSender.TARGET_TRAFFIC_STARS_KEY),
				this,
			);
		}

		if (targetPropellerAdsEnabled) {
			this.targetPropellerAds = new AnalyticTargetPropellerAds(this.trackingData);
			this.targetPropellerAds.on(
				AnalyticTargetPropellerAds.EVENT_RESPONSE,
				(response) => this.onSomeResponseReceived(response, AnalyticsSender.TARGET_PROPELLER_ADS_KEY),
				this,
			);
		}

		if (targetClickaduEnabled) {
			this.targetClickadu = new AnaylticTargetClickadu(this.trackingData);
			this.targetClickadu.on(
				AnaylticTargetClickadu.EVENT_RESPONSE,
				(response) => this.onSomeResponseReceived(response, AnalyticsSender.TARGET_CLICKADU_KEY),
				this,
			);
		}
	}

	public setGameProfileData(
		playerId: string,
		sessionId: string,
		deviceId: string,
		platform: AnalyticsPlatform,
		trackingData?: TrackingData,
	): void {
		this.playerId = playerId;
		this.sessionId = sessionId;
		this.deviceId = deviceId;
		this.platform = platform;
		this.trackingData = trackingData;

		this.targetClickhouse?.setGameProfileData(
			this.sessionId,
			this.playerId,
			this.deviceId,
			this.platform,
		);
		this.targetXOffers?.setGameProfileData(
			this.sessionId,
			this.playerId,
			this.deviceId,
			this.trackingData,
		);
		this.targetPixel?.setGameProfileData(this.trackingData);
		this.targetTrafficStars?.setGameProfileData(this.trackingData);
		this.targetPropellerAds?.setGameProfileData(this.trackingData);
		this.targetClickadu?.setGameProfileData(this.trackingData);
	}

	public sendEventClickhouseEventFirstEnter(): void {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.sendEventClickhouseEventFirstEnter();
	}

	public startTrackEventRankProgress(): void {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.startTrackEventRankProgress();
	}

	public stopTrackEventRankProgress(): void {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.stopTrackEventRankProgress();
	}

	public addSourceGameProfileModel(source: AnalyticSourceGameProfileModel): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetPixel?.addSourceGameProfileModel(source);
		// eslint-disable-next-line no-unused-expressions
		// eslint-disable-next-line no-unused-expressions
		this.targetClickadu?.addSourceGameProfileModel(source);
		return this;
	}

	public addSourceEventLevelModels(source: AnalyticSourceModels): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.addSourceEventLevelModels(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetPixel?.addSourceModels(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetTrafficStars?.addSourceModels(source);
		return this;
	}

	public addSourceLevelModels(source: AnalyticSourceModels): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.addSourceLevelModels(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetPixel?.addSourceModels(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetTrafficStars?.addSourceModels(source);
		return this;
	}

	public addSourceBankViews(source: AnalyticSourceBankViews): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.addSourceBankViews(source);
		return this;
	}

	public addSourceFarewellPartyView(source: AnalyticSourceFarewellPartyWindowView): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.addSourceFarewellPartyView(source);
		return this;
	}

	public addSourceLevelPreloaderView(source: AnalyticSourceLevelPreloaderView): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetClickhouse?.addSourceLevelPreloaderView(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetPixel?.addSourceLevelPreloaderView(source);
		return this;
	}

	public addSourceLevelStartWindowView(source: AnalyticSourceLevelStartWindowView): AnalyticsSender {
		// eslint-disable-next-line no-unused-expressions
		this.targetPixel?.addSourceLevelStartWindowView(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetTrafficStars?.addSourceLevelStartWindowView(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetPropellerAds?.addSourceLevelStartWindowView(source);
		// eslint-disable-next-line no-unused-expressions
		this.targetClickadu?.addSourceLevelStartWindowView(source);
		return this;
	}

	private onSomeResponseReceived(
		response: Response,
		targetKey: string,
	): void {
		fetch(`${AnalyticTargetClickhouse.CLICKHOUSE_SERVER_URL}actions/clicker/marketing_stat_log`, {
			method: 'POST',
			body: JSON.stringify({
				statistics: [{
					/* eslint-disable @typescript-eslint/naming-convention */
					profile_id: this.playerId,
					status_code: response.status.toString(),
					log: response.statusText,
					timestamp: this.serverTimeModel.getCalculatedISOTime().toString(),
					campaign: targetKey,
					/* eslint-enable @typescript-eslint/naming-convention */
				}],
			}),
			headers: {
				/* eslint-disable @typescript-eslint/naming-convention */
				'content-type': 'application/json',
				'pnk-env': 'site',
				'pnk-platform': this.platform,
				'pnk-session-id': this.sessionId,
				'pnk-player-id': this.playerId,
				'pnk-device-id': this.deviceId,
				'pnk-version': this.version,
				/* eslint-enable @typescript-eslint/naming-convention */
			},
		});
	}
}
