import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character25View extends CharacterMainBaseView {
	constructor() {
		super('character25');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(118, 34);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(192, -19),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(150, -30),
			invertBg: false,
		});

		this.initQuickPhrases(this.key);
	}
}
