import { LevelProgressView } from '@views/ui/levelProgressPanel/LevelProgressView';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class LevelPanelView extends PIXI.Container {
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	private readonly levelText: PIXI.extras.BitmapText;
	private readonly levelSeasonText: PIXI.extras.BitmapText;
	private readonly levelProgress: LevelProgressView;
	private readonly interactiveArea: PIXI.Graphics;

	private localizationStorage: LocalizationStorage;

	private currentLevel: number;
	private currentSeason: number;

	constructor() {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.currentLevel = -1;

		this.levelText = new PIXI.extras.BitmapText('', { font: '32px wendyOneShadowBoldCaps' });
		this.levelText.anchor = new PIXI.Point(0, 0.5);
		this.levelText.position.set(-55, -18);

		this.levelSeasonText = new SizeableBitmapText('', 500, { font: '32px wendyOneShadowBoldCaps' });
		this.levelSeasonText.tint = 0xffee90;
		this.levelSeasonText.anchor = new PIXI.Point(0, 0.8);

		this.levelProgress = new LevelProgressView();
		this.levelProgress.position.set(-56, 2);

		const [x, y, width, height] = [-40, -44, 460, 90];
		this.interactiveArea = new PIXI.Graphics();
		this.interactiveArea.beginFill(0x000000, 0);
		this.interactiveArea.moveTo(x, y);
		this.interactiveArea.lineTo(x + width, y);
		this.interactiveArea.lineTo(x + width, y + height);
		this.interactiveArea.lineTo(x, y + height);
		this.interactiveArea.closePath();

		this.interactive = true;
		this.on('pointertap', this.showHint, this);

		this.addChild(
			this.interactiveArea,
			this.levelProgress as PIXI.DisplayObject,
			this.levelText,
			this.levelSeasonText,
		);
	}

	public setTotalProgress(value: number): void {
		this.levelProgress.setTotalProgress(value);
	}

	public setProgress(
		current: number,
		pending: number,
		playProgressAnimation: boolean,
	): void {
		this.levelProgress.setProgress(current, pending, playProgressAnimation);
	}

	public setLevel(level: number): void {
		this.currentLevel = level;
		this.setTextLevel();
	}

	public setSeason(currentSeason: number): void {
		this.currentSeason = currentSeason;
		this.setTextSeason();
	}

	private setTextLevel(): void {
		const textLevel: string = this.localizationStorage.getLocalizedString('#gameUI_LevelLabel');
		this.levelText.text = textLevel.replace('{{value}}', this.currentLevel.toString());

		this.updateSeasonTextPosition();
	}

	private setTextSeason(): void {
		const textSeason = this.localizationStorage.getLocalizedString(`#level${this.currentSeason}${this.currentLevel}_desc`);
		this.levelSeasonText.text = textSeason;
	}

	private updateText(): void {
		this.setTextLevel();
		this.setTextSeason();
	}

	private updateSeasonTextPosition(): void {
		this.levelSeasonText.position.set(this.levelText.x + this.levelText.width, -8);
	}

	private showHint(): void {
		const arrowPosLocal = new PIXI.Point(this.levelProgress.x + 150, this.levelProgress.y + 50);
		this.emit(LevelPanelView.EVENT_SHOW_HINT, arrowPosLocal, this);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
