import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10003View extends CharacterMainBaseView {
	constructor() {
		super('character10003');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-17, 76);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-110, -10),
			arrowRotation: Math.PI / 180 * 360,
			bgPosition: new PIXI.Point(-103, 0),
		});
		this.initQuickPhrases(this.key);
	}
}
