import { NewsWindowViewSetter } from '@interfaces/ViewSetters';
import { NewsModel } from '@models/NewsModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ClaimNewsRewardsAction } from '@models/network/actions/news/ClaimNewsRewardsAction';
import { SeenNewsAction } from '@models/network/actions/news/SeenNewsAction';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { BankTabTypes } from '@src/types/BankTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { BaseNewsWindowView } from '@views/windows/newsWindow/BaseNewsWindowView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export class BaseNewsWindowViewController extends PIXI.utils.EventEmitter implements NewsWindowViewSetter {
	constructor(
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly windowViewSystem: WindowViewSystem,
		private readonly newsModel: NewsModel,
		private readonly rewardFactory: RewardFactory,
	) {
		super();
	}

	public setNewsWindowView(view: BaseNewsWindowView): void {
		view.on(BaseNewsWindowView.EVENT_CHANGE_TAB, this.onChangeTab, this);
		view.on(BaseNewsWindowView.EVENT_CLAIM_REWARD, this.onClaimReward, this);
		view.on(BaseNewsWindowView.EVENT_BUTTON_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
	}

	private onChangeTab(newsKey: string): void {
		const newsItem = this.newsModel.getItems().find((item) => item.getKey() === newsKey);

		if (this.newsModel.canMarkAsSeen(newsItem)) {
			this.newsModel.markAsSeen(newsItem.getKey());

			const action = new SeenNewsAction(newsItem.getKey());
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	private onClaimReward(newsKey: string): void {
		const newsItem = this.newsModel.getItems().find((item) => item.getKey() === newsKey);
		const action = new ClaimNewsRewardsAction(newsItem.getKey(), newsItem.getRewards());

		this.newsModel.claimReward(newsItem.getKey());
		this.showRewardResultWindow(newsItem.getRewards());
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private showRewardResultWindow(rewards: RewardDescriptionType[]): void {
		const rewardsModels = rewards
			.map((reward) => this.rewardFactory.createReward(reward));

		const window = this.windowFactory.createRewardResultWindow();
		window.init(rewardsModels);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonMoneyPlusClick(): void {
		const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(window);
	}
}
