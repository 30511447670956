import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import LocalizationStorage from '@main/LocalizationStorage';
import { BaseAction } from '@models/network/actions/BaseAction';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';

type LocalizationReplaceMetadata = {
	language: string;
	eventKey: string;
	data: Record<string, string | undefined>;
};

export class LocalizationLoader extends PIXI.utils.EventEmitter {
	public static readonly DEFAULT_LANGUAGE = 'en';

	private static getLocalizationConfigName(language: string): string {
		return `Localization_${language}`;
	}

	private static getCustomLocalizationConfigName(language: string): string {
		return `Localization_Custom_${language}`;
	}

	private static getEventLocalizationConfigName(language: string): string {
		return `Localization_Event_${language}`;
	}

	private static getCustomEventLocalizationConfigName(language: string): string {
		return `Localization_Event_Custom_${language}`;
	}

	private static getEventReplaceLocalizationConfigName(language: string): string {
		return `Localization_Event_Replace_${language}`;
	}

	private readonly localizationStorage: LocalizationStorage;
	private localizationSupportConfig: LocalizationSupportConfig;
	private eventLocalizationReplaceData: LocalizationReplaceMetadata;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
	}

	public async load(
		localizationSupportConfig: LocalizationSupportConfig,
		language?: string,
	): Promise<void> {
		this.localizationSupportConfig = localizationSupportConfig;

		let languageName = LocalizationStorage.getFormattedLanguageName(language);

		if (!this.localizationSupportConfig.isLanguageSupported(languageName)) {
			languageName = LocalizationLoader.DEFAULT_LANGUAGE;
		}

		await this.loadLocalization(languageName);
	}

	public async localizationChange(language: string): Promise<void> {
		const languageName = LocalizationStorage.getFormattedLanguageName(language);

		await this.loadLocalization(languageName);

		this.localizationStorage.updateLocalization();

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_language: language }));
	}

	public async localizationChangeFromEvent(language: string, eventKey: string): Promise<void> {
		const languageName = LocalizationStorage.getFormattedLanguageName(language);
		const locConfigName = LocalizationLoader.getLocalizationConfigName(languageName);
		const customLocConfigName = LocalizationLoader.getCustomLocalizationConfigName(languageName);
		const eventLocConfigName = LocalizationLoader.getEventLocalizationConfigName(languageName);
		const customEventLocConfigName = LocalizationLoader.getCustomEventLocalizationConfigName(languageName);
		const eventReplaceLocConfigName = LocalizationLoader.getEventReplaceLocalizationConfigName(languageName);

		const localizationDataRaw = await this.networkRequestSender.sendGetConfigs([
			locConfigName,
			customLocConfigName,
			eventLocConfigName,
			customEventLocConfigName,
			eventReplaceLocConfigName,
		]);

		const locBaseData = localizationDataRaw[locConfigName];
		const locEventData = localizationDataRaw[eventLocConfigName];
		const locReplaceData = localizationDataRaw[eventReplaceLocConfigName];
		const localizationReplaceDataFormatted: Record<string, string | undefined> = {};

		Object.keys(locReplaceData).forEach((key) => {
			const eventReplacePrefix = LocalizationStorage.getLocalizationKeyPrefixEvent(eventKey);
			const replaceBaseKey = key.replace(eventReplacePrefix, '');
			const oldLocValue = locBaseData[replaceBaseKey];

			locBaseData[replaceBaseKey] = locReplaceData[key];
			localizationReplaceDataFormatted[replaceBaseKey] = oldLocValue;
		});

		const mergedBaseLocalization = Object.assign(locBaseData, localizationDataRaw[customLocConfigName]);
		const mergedEventLocalization = Object.assign(locEventData, localizationDataRaw[customEventLocConfigName]);

		this.eventLocalizationReplaceData = {
			data: localizationReplaceDataFormatted,
			language,
			eventKey,
		};

		this.localizationStorage.setLocalization(languageName, [mergedBaseLocalization, mergedEventLocalization]);
		this.localizationStorage.updateLocalization();

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ settings_language: language }));
	}

	public async localizationLoadEvent(
		swapToEventLocalization: boolean,
		eventKey: string,
	): Promise<void> {
		const languageName = this.localizationStorage.getLanguage();
		const obsoleteEventLocalization = this.eventLocalizationReplaceData?.language !== languageName
			|| eventKey !== this.eventLocalizationReplaceData?.eventKey;

		if (swapToEventLocalization && obsoleteEventLocalization) {
			const supportedLanguage = this.localizationSupportConfig.isLanguageSupported(languageName)
				? languageName
				: LocalizationLoader.DEFAULT_LANGUAGE;

			const eventLocConfigName = LocalizationLoader.getEventLocalizationConfigName(supportedLanguage);
			const customEventLocConfigName = LocalizationLoader.getCustomEventLocalizationConfigName(supportedLanguage);
			const eventReplaceLocConfigName = LocalizationLoader.getEventReplaceLocalizationConfigName(supportedLanguage);

			const result = await this.networkRequestSender.sendGetConfigs([
				eventLocConfigName,
				customEventLocConfigName,
				eventReplaceLocConfigName,
			]);

			const localizationReplaceData = result[eventReplaceLocConfigName];
			const localizationReplaceDataFormatted: Record<string, string | undefined> = {};

			Object.keys(localizationReplaceData)
				.filter((eventReplaceKey) => eventReplaceKey.indexOf(LocalizationStorage.getLocalizationKeyPrefixEvent(eventKey)) >= 0)
				.forEach((eventReplaceKey) => {
					const eventReplacePrefix = LocalizationStorage.getLocalizationKeyPrefixEvent(eventKey);
					const eventReplaceKeyFormatted = eventReplaceKey.replace(eventReplacePrefix, '');
					localizationReplaceDataFormatted[eventReplaceKeyFormatted] = localizationReplaceData[eventReplaceKey];
				});

			this.eventLocalizationReplaceData = {
				language: languageName,
				data: localizationReplaceDataFormatted,
				eventKey,
			};

			const localizationEventData = Object.assign(
				result[eventLocConfigName],
				result[customEventLocConfigName],
			);

			this.localizationStorage.addToLocalization(localizationEventData);
		}

		this.localizationSwapEventReplace();
	}

	public localizationSwapEventReplace(): void {
		if (this.eventLocalizationReplaceData?.data != null) {
			Object.keys(this.eventLocalizationReplaceData.data).forEach(replaceKey => {
				const valueTemp = this.localizationStorage.getLocalizedString(replaceKey);
				const replaceValue = this.eventLocalizationReplaceData.data[replaceKey];

				this.localizationStorage.replaceLocalizationKey(replaceKey, replaceValue);
				this.eventLocalizationReplaceData.data[replaceKey] = valueTemp;
			});

			this.localizationStorage.updateLocalization();
		}
	}

	private async loadLocalization(languageName: string): Promise<void> {
		const locConfigName = LocalizationLoader.getLocalizationConfigName(languageName);
		const customLocConfigName = LocalizationLoader.getCustomLocalizationConfigName(languageName);

		const configsData: Record<string, Record<string, string>> = await this.networkRequestSender.sendGetConfigs([
			locConfigName,
			customLocConfigName,
		]);

		const mergedLocalization = Object.assign(
			configsData[locConfigName],
			configsData[customLocConfigName],
		);

		this.localizationStorage.setLocalization(languageName, [mergedLocalization]);
	}
}
