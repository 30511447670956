import * as TWEEN from '@tweenjs/tween.js';
import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';

export class FarewellPartyLabelPerfectView extends PIXI.Container {
	private static textStar(): any {
		return {
			alpha: {
				start: 0.8,
				end: 0,
			},
			scale: {
				start: 0.1,
				end: 2,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffef94',
				end: '#ffa929',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.6,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.15,
			emitterLifetime: 1,
			maxParticles: 5,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: -200,
				y: 0,
				w: 400,
				h: 100,
			},
		};
	}

	private readonly labelPerfect: PIXI.extras.BitmapText;

	private scaleInLabelPerfect: TWEEN.Tween;
	private emitter: Emitter;

	constructor() {
		super();


		this.labelPerfect = new PIXI.extras.BitmapText('PERFECT', { font: '140px wendyOneGold' });
		this.labelPerfect.anchor = 0.5;

		this.addChild(
			this.labelPerfect,
		);
	}

	public playAppearingAnimation(duration: number, delayMS: number = 0): void {
		this.labelPerfect.scale.set(0);
		this.scaleInLabelPerfect = new TWEEN.Tween(this.labelPerfect.scale)
			.to({ x: 1, y: 1 }, duration)
			.onComplete(() => {
				this.playParticlesAnimation();
				this.scaleInLabelPerfect = null;
			})
			.delay(delayMS)
			.start();
	}

	private playParticlesAnimation(): void {
		this.emitter = new Emitter(
			this,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['orgy_star']).texture,
			],
			FarewellPartyLabelPerfectView.textStar(),
		);
		this.emitter.autoUpdate = true;
		this.emitter.playOnceAndDestroy(() => {
			this.emitter = null;
		});
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.scaleInLabelPerfect) {
			this.scaleInLabelPerfect.stop();
		}
		if (this.emitter) {
			this.emitter.destroy();
		}
		super.destroy(options);
	}
}
