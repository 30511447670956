import { CardRarity } from '@src/types/CardRarities';
import { RewardTypes } from '@src/types/RewardTypes';

export enum DailyBonusType {
	NOVICE = 'novice',
	MAIN = 'main',
	REENGAGEMENT = 'reengagement',
}

export class DailyRewardConfig {
	public static FIELD_BONUS_TYPE = 'bonus_type';
	public static FIELD_DAY = 'day';
	public static FIELD_REWARD_TYPE = 'reward_type';
	public static FIELD_REWARD_ID = 'reward_id';
	public static FIELD_REWARD_COUNT = 'reward_count';

	private readonly bonusType: DailyBonusType;
	private readonly day: number;
	private readonly rewardType: RewardTypes;
	private readonly rewardId: string;
	private readonly rewardCount: string;
	private readonly rarity?: CardRarity;

	constructor(config: Record<string, string>) {
		this.bonusType = config[DailyRewardConfig.FIELD_BONUS_TYPE].trim() as DailyBonusType;
		this.day = Number(config[DailyRewardConfig.FIELD_DAY]);
		this.rewardType = config[DailyRewardConfig.FIELD_REWARD_TYPE].trim() as RewardTypes;
		this.rewardId = config[DailyRewardConfig.FIELD_REWARD_ID].trim();
		this.rewardCount = config[DailyRewardConfig.FIELD_REWARD_COUNT].trim();
		this.rarity = DailyRewardConfig.tryParseRarity(this.rewardId);
	}

	public getKey(): string {
		return `${this.bonusType}${this.day}`;
	}

	public getBonusType(): DailyBonusType {
		return this.bonusType;
	}

	public getDay(): number {
		return this.day;
	}

	public getRewardType(): RewardTypes {
		return this.rewardType;
	}

	public getRewardId(): string {
		return this.rewardId;
	}

	public getRewardCount(): string {
		return this.rewardCount;
	}

	public getRarity(): CardRarity | undefined {
		return this.rarity;
	}

	private static tryParseRarity(value: string): CardRarity | undefined {
		const splitStr = value.split(':');
		let rarity;

		if (splitStr.length > 1) {
			rarity = splitStr[1].trim() as CardRarity;
		}

		return rarity;
	}
}
