import { RewardDescriptionType, RewardDisplayValueType, RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';

export abstract class AbstractReward {
	protected newReward: boolean;
	protected type: RewardTypes;
	protected key: RewardResourceIdTypes | string;

	private displayValue: RewardDisplayValueType;

	protected constructor(type: RewardTypes, id: RewardResourceIdTypes | string) {
		this.type = type;
		this.key = id;
	}

	public abstract collect(): void;

	public abstract getQuantity(): number;

	public getType(): RewardTypes {
		return this.type;
	}

	public getKey(): RewardResourceIdTypes | string {
		return this.key;
	}

	public isRewardNew(): boolean {
		return this.newReward;
	}

	public setDisplayValue(displayValue: RewardDisplayValueType): void {
		this.displayValue = displayValue;
	}

	public getDisplayValue(): RewardDisplayValueType {
		return this.displayValue;
	}

	public getQuantityString(): string {
		return this.getQuantity().toString();
	}

	public getRewardDescription(): RewardDescriptionType {
		return {
			/* eslint-disable @typescript-eslint/naming-convention */
			reward_type: this.getType(),
			reward_id: this.getKey(),
			reward_qty: this.getQuantity(),
			/* eslint-enable @typescript-eslint/naming-convention */
		};
	}
}
