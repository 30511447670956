import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120010View } from '@views/characters/events/event12/EventCharacter120010View';
import { EventCharacter120011View } from '@views/characters/events/event12/EventCharacter120011View';
import { EventCharacter120012View } from '@views/characters/events/event12/EventCharacter120012View';

export class EventBusiness120004View extends BusinessBaseView {
	constructor() {
		const eventCharacter120010View = new EventCharacter120010View();
		const eventCharacter120011View = new EventCharacter120011View();
		const eventCharacter120012View = new EventCharacter120012View();

		super('business120004', [eventCharacter120010View, eventCharacter120011View, eventCharacter120012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b8_decor1']).setTransform(-262, 13, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor2']).setTransform(278, -9, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor3']).setTransform(-248, -8.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor4']).setTransform(-134, 56, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b8_decor5']).setTransform(-33, -23.5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter120011View,
			eventCharacter120010View as PIXI.DisplayObject,
			eventCharacter120012View,
		);
	}
}
