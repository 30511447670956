import { DialogWindowView } from '@views/windows/dialog/DialogWindowView';
import { DialogModel } from '@models/DialogModel';
import { DialogWindowViewSetter } from '@interfaces/ViewSetters';

export type EventDialogMoveEventArgs = {
	key: string;
	dialogStep: number;
};

export class AnalyticSourceDialogWindowView
	extends PIXI.utils.EventEmitter implements DialogWindowViewSetter {
	public static readonly EVENT_DIALOG_MOVE: symbol = Symbol();

	public setDialogWindowView(view: DialogWindowView): void {
		view.on(DialogWindowView.EVENT_MOVE, (model: DialogModel) => {
			const args: EventDialogMoveEventArgs = {
				key: model.getKey(),
				dialogStep: model.getCurrentDialogEntityId(),
			};
			this.emit(AnalyticSourceDialogWindowView.EVENT_DIALOG_MOVE, args);
		});
	}
}
