import { PromotableModel } from '@models/PromotableModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCards extends AbstractQuest {
	private currentAmount: number;
	private targetAmount: number;

	private targets: PromotableModel[];

	constructor(
		questKey: string,
		targets: PromotableModel[],
		targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.GET_CARDS,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.targetAmount = targetAmount;
		this.currentAmount = 0;

		this.targets = targets;

		this.trackProgressStart();
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.getTargetAmount().toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.getTargetAmount();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	private onCardsAdded(target: PromotableModel, amount: number): void {
		this.currentAmount += amount;
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		this.targets.forEach(target => target.off(PromotableModel.EVENT_CARDS_ADDED, this.onCardsAdded, this));
	}

	protected trackProgressStart(): void {
		this.targets.forEach(target => target.on(PromotableModel.EVENT_CARDS_ADDED, this.onCardsAdded, this));
	}
}
