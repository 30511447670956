import { AssetsStorage } from '@main/AssetsStorage';
import { DualStateButtonBaseView } from '@views/components/buttons/DualStateButtonBaseView';
import { GameConstants } from '@src/utils/GameConstants';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { VideoPlayerProgressBar } from './VideoPlayerProgressBar';
import LocalizationStorage from '@main/LocalizationStorage';
import { TintButton } from './TintButton';

export class VideoPlayerControls extends PIXI.Container {
	public static readonly EVENT_PLAY_VIDEO: symbol = Symbol();
	public static readonly EVENT_PAUSE_VIDEO: symbol = Symbol();
	public static readonly EVENT_SOUND_ON: symbol = Symbol();
	public static readonly EVENT_SOUND_OFF: symbol = Symbol();
	public static readonly EVENT_SEEK_VIDEO: symbol = Symbol();
	public static readonly EVENT_PLAY_NEXT_VIDEO: symbol = Symbol();
	public static readonly EVENT_PLAY_PREV_VIDEO: symbol = Symbol();

	private readonly videoProgress: VideoPlayerProgressBar;
	private readonly playButton: DualStateButtonBaseView;
	private readonly videoName: SizeableBitmapText;
	private videoEnded: boolean;

	constructor(modelKey: string) {
		super();

		this.videoEnded = false;


		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['video_window_fade']);
		bg.scale.x = 160;
		bg.anchor.set(0, 0);

		this.videoProgress = new VideoPlayerProgressBar();
		this.videoProgress.y = 108;
		this.videoProgress.on(
			VideoPlayerProgressBar.EVENT_SEEK_VIDEO,
			(seekingTime: number) => this.emit(VideoPlayerControls.EVENT_SEEK_VIDEO, seekingTime),
		);

		this.playButton = new DualStateButtonBaseView(
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['pause_btn']),
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['play_btn']),
		);
		this.playButton.position.set(GameConstants.GAME_CENTER_X, 167);
		this.playButton.on(ButtonBaseView.EVENT_CLICK, (isMainState: boolean) => {
			if (isMainState) {
				this.emit(VideoPlayerControls.EVENT_PLAY_VIDEO);
				this.videoProgress.play(this.videoEnded);
				this.videoEnded = false;
			} else {
				this.emit(VideoPlayerControls.EVENT_PAUSE_VIDEO);
				this.videoProgress.pause();
			}
		});

		const soundButton = new DualStateButtonBaseView(
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['volume_video_icon_on']),
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['volume_video_icon_off']),
		);
		soundButton.position.set(1862, 167);
		soundButton.on(ButtonBaseView.EVENT_CLICK, (isMainState: boolean) => {
			if (isMainState) {
				this.emit(VideoPlayerControls.EVENT_SOUND_ON);
			} else {
				this.emit(VideoPlayerControls.EVENT_SOUND_OFF);
			}
		});

		const nextButton = new TintButton(new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_video_arrow']));
		nextButton.position.set(1051.5, 167);
		nextButton.interactive = true;
		nextButton.on(ButtonBaseView.EVENT_CLICK, () => {
			this.videoProgress.stop();
			this.playButton.setMainState();
			this.emit(VideoPlayerControls.EVENT_PLAY_NEXT_VIDEO);
		});

		const prev = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['next_video_arrow']);
		prev.scale.x = -1;

		const prevButton = new TintButton(prev);
		prevButton.position.set(868.5, 167);
		prevButton.interactive = true;
		prevButton.on(ButtonBaseView.EVENT_CLICK, () => {
			this.videoProgress.stop();
			this.playButton.setMainState();
			this.emit(VideoPlayerControls.EVENT_PLAY_PREV_VIDEO);
		});

		this.videoName = new SizeableBitmapText(
			LocalizationStorage.getInstance().getLocalizedString(`#${modelKey}_name`),
			810,
			{ font: '45px wendyOneGold' },
		);
		this.videoName.anchor = new PIXI.Point(0, 0.5);
		this.videoName.position.set(34, 167);
		this.videoName.letterSpacing = 2;

		this.addChild(
			bg,
			this.playButton as PIXI.DisplayObject,
			soundButton,
			nextButton,
			prevButton,
			this.videoName,
			this.videoProgress,
		);
	}

	public setVideoDuration(duration: number): void {
		this.videoProgress.setDuration(duration);
	}

	public updateCurrentTime(current: number): void {
		this.videoProgress.setCurrentTime(current);
	}

	public onVideoEnded(): void {
		this.playButton.setSecondState();
		this.videoEnded = true;
	}

	public updateName(modelKey: string): void {
		this.videoName.text = LocalizationStorage.getInstance().getLocalizedString(`#${modelKey}_name`);
	}
}
