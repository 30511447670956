import { AnalyticsType, TrackingData } from '@src/types/AnalyticsTypes';

export class AnalyticTargetXOffers {
	private static readonly UTM_SOURCE = 'hooligan';
	private static readonly TRACKING_PARAM = 'xorigin';

	constructor(
		private sessionId: string,
		private playerId: string,
		private deviceId: string,
		private serverUrl: string,
		private readonly version: string,
		private trackingData?: TrackingData,
	) {
		this.tryInitXOffers();
	}

	public setGameProfileData(
		sessionId: string,
		playerId: string,
		deviceId: string,
		trackingData?: TrackingData,
	): void {
		this.sessionId = sessionId;
		this.playerId = playerId;
		this.deviceId = deviceId;
		this.trackingData = trackingData;

		this.tryInitXOffers();
	}

	private async tryInitXOffers(): Promise<void> {
		if (this.trackingData !== undefined
			&& this.trackingData.utmSource === AnalyticTargetXOffers.UTM_SOURCE
			&& this.trackingData.analytics !== AnalyticsType.PIXEL) {
			const urlParams = new URLSearchParams(window.location.search);
			let xorigin = urlParams.get(AnalyticTargetXOffers.TRACKING_PARAM);

			if (xorigin != null) {
				xorigin = encodeURI(xorigin);
			}

			this.initXOffers(xorigin);
		}
	}

	private async initXOffers(xorigin?: string): Promise<void> {
		const headers = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Content-Type': 'application/json',
			'pnk-env': 'site',
			'pnk-session-id': this.sessionId,
			'pnk-player-id': this.playerId,
			'pnk-device-id': this.deviceId,
			'pnk-version': this.version,
		};
		const requestGenerateSecretKey = await fetch(`${this.serverUrl}/gs_api/profiles/generate_hooligapps_secret`, {
			method: 'POST',
			headers,
		});
		const responseGenerateSecretKey = await requestGenerateSecretKey.json();
		const secret = responseGenerateSecretKey.result?.secret;

		XOffers.init({
			app: 'cuntempire',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			user_id: this.playerId,
			secret,
		}, xorigin);
	}
}
