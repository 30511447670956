import { MoneyType } from '@src/types/MoneyType';
import Decimal from 'decimal.js';
import { NumberUtils } from './NumberUtils';

export type SoftMoneyNumberAcronym = string;
export type SoftMoneyNumberRaw = string;

export default class SoftMoneyNumber {
	public static readonly REGEX_FORMAT: RegExp = /^\s*\d+(?:.\d+)?\w*\s*$/;

	public static readonly ZERO: SoftMoneyNumber = SoftMoneyNumber.createFromNumber(0);
	public static readonly ONE: SoftMoneyNumber = SoftMoneyNumber.createFromNumber(1);

	private static baseOp<T>(moneyNumber: MoneyType, op: (y: Decimal.Value) => T): T {
		let result: T;

		if (moneyNumber instanceof SoftMoneyNumber) {
			result = op(moneyNumber.getBigNumber());
		} else {
			result = op(moneyNumber);
		}
		return result;
	}

	public static createFromNumber(value: number): SoftMoneyNumber {
		return new SoftMoneyNumber(value);
	}

	public static createFromString(value: SoftMoneyNumberRaw): SoftMoneyNumber {
		return new SoftMoneyNumber(value);
	}

	public static createFromAcronym(value: SoftMoneyNumberAcronym): SoftMoneyNumber {
		// for cases like: "1,500" or "15,000" etc.
		const valueFormatted = value.replace(',', '');

		const regexResult = /k|[A-Z]+/.exec(valueFormatted);
		const acronym = (regexResult !== null) ? regexResult[0] : '';

		let intPartStr = valueFormatted.substr(0, regexResult?.index || valueFormatted.length);
		const decParseData: string[] = intPartStr.split('.');
		const decPartStr = (decParseData.length > 1) ? decParseData[1] : '';

		if (decPartStr !== '') {
			// eslint-disable-next-line prefer-destructuring
			intPartStr = intPartStr.split('.')[0];
		}

		let index: number;

		if (acronym === '') {
			index = 0;
		} else if (acronym.length === 1) {
			index = NumberUtils.acronymSuffixSingle.indexOf(acronym[0]) + 1;
		} else {
			index = NumberUtils.acronymSuffixSingle.length - NumberUtils.acronymSuffixDouble.length;

			acronym.split('').forEach((char, i) => {
				index += (NumberUtils.acronymSuffixDouble.indexOf(char) + 1)
					* NumberUtils.acronymSuffixDouble.length ** (acronym.length - 1 - i);
			});
		}

		const initialZerosCount = index * 3;
		const totalZerosCount = Math.max(initialZerosCount - decPartStr.length + 1, 0);
		const zeros = Array(totalZerosCount).join('0');

		return new SoftMoneyNumber(intPartStr + decPartStr.slice(0, initialZerosCount) + zeros);
	}

	private number: Decimal;

	private constructor(value: Decimal.Value) {
		this.number = new Decimal(value);
	}

	public static randomRange(a: SoftMoneyNumber, b: SoftMoneyNumber): SoftMoneyNumber {
		const aRaw: Decimal = a.getBigNumber();
		const bRaw: Decimal = b.getBigNumber();

		const rand = Decimal.random(2);

		const result: Decimal = aRaw.plus(bRaw.minus(aRaw).mul(rand));

		return new SoftMoneyNumber(result);
	}

	public exp(moneyNumber: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(moneyNumber, this.number.exp.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public add(moneyNumber: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(moneyNumber, this.number.plus.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public multiply(moneyNumber: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(moneyNumber, this.number.mul.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public divide(moneyNumber: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(moneyNumber, this.number.dividedBy.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public subtract(moneyNumber: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(moneyNumber, this.number.minus.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public log(base: MoneyType): SoftMoneyNumber {
		const result: Decimal = SoftMoneyNumber.baseOp(base, this.number.logarithm.bind(this.number));
		return new SoftMoneyNumber(result);
	}

	public round(roundingMode: Decimal.Rounding = Decimal.ROUND_CEIL): SoftMoneyNumber {
		this.number = this.number.toSignificantDigits(Decimal.precision, roundingMode);
		return this;
	}

	public equalTo(moneyNumber: MoneyType): boolean {
		return SoftMoneyNumber.baseOp(moneyNumber, this.number.equals.bind(this.number));
	}

	public lessThanOrEqualTo(moneyNumber: MoneyType): boolean {
		return SoftMoneyNumber.baseOp(moneyNumber, this.number.lessThanOrEqualTo.bind(this.number));
	}

	public lessThan(moneyNumber: MoneyType): boolean {
		return SoftMoneyNumber.baseOp(moneyNumber, this.number.lessThan.bind(this.number));
	}

	public greaterThanOrEqualTo(moneyNumber: MoneyType): boolean {
		return SoftMoneyNumber.baseOp(moneyNumber, this.number.greaterThanOrEqualTo.bind(this.number));
	}

	public greaterThan(moneyNumber: MoneyType): boolean {
		return SoftMoneyNumber.baseOp(moneyNumber, this.number.greaterThan.bind(this.number));
	}

	public getBigNumber(): Decimal {
		return this.number;
	}

	public toRawString(decimalPlaces: number = 0): SoftMoneyNumberRaw {
		return this.number.toFixed(decimalPlaces, Decimal.ROUND_FLOOR);
	}

	public toNumber(decimalPlaces: number = 0): number {
		return parseFloat(this.toRawString(decimalPlaces));
	}

	public toString(intPartSize: number = 3, digitsForSuffix: number = 4, integerOnly: boolean = false): SoftMoneyNumberAcronym {
		const numberStr = this.number.toFixed(0, Decimal.ROUND_FLOOR);
		return NumberUtils.numberStringToShortString(numberStr, intPartSize, digitsForSuffix, integerOnly);
	}
}
