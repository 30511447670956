import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SettingsCharacterDialogView, SettingsCharacterDialogViewMode } from './SettingsCharacterDialogView';
import { SettingsFooterView } from './SettingsFooterView';
import { SettingsPanelView } from './SettingsPanelView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { GameProfileModel } from '@models/GameProfileModel';
import { BaseSettingsWindowView } from './BaseSettingsWindowView';
import { PaymentMethod } from '@src/types/TransactionTypes';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class SettingsWindowView extends BaseSettingsWindowView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly gameProfileModel: GameProfileModel;
	private readonly thirdPartyLinks: string[];

	private readonly labelTitle: PIXI.extras.BitmapText;
	private readonly dialog: SettingsCharacterDialogView;
	private readonly panel: SettingsPanelView;
	private readonly card: PIXI.Sprite;

	constructor(
		redeemCode: string,
		supportLanguageMap: Map<string, string>,
		gameProfileModel: GameProfileModel,
		thirdPartyLinks: string[],
		defaultPaymentMethod: PaymentMethod,
		version: string,
	) {
		super(0.9);

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_LOGGED_IN, this.onLoggedIn, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_REWARD_COLLECTED, this.onRewardCollected, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUpdateUsername, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_PAYMENT_METHOD_UPDATED, this.onUpdateCardpay, this);

		this.thirdPartyLinks = thirdPartyLinks;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.card = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['setting_card_bg']);
		this.card.position.set(-375, -3);
		this.card.interactive = true;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 837;
		bg.height = 797;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.x = 305;
		bg.interactive = true;

		this.labelTitle = new PIXI.extras.BitmapText(
			'',
			{ font: { size: 40, name: 'wendyOneShadowBold' } },
		);
		this.labelTitle.anchor = 0.5;
		this.labelTitle.position.set(320, -345);

		this.dialog = new SettingsCharacterDialogView();
		this.dialog.on(
			SettingsCharacterDialogView.EVENT_BUTTON_COLLECT_CLICK,
			() => this.emit(SettingsWindowView.EVENT_BUTTON_COLLECT_REWARD_CLICK),
			this,
		);
		this.dialog.on(
			SettingsCharacterDialogView.EVENT_BUTTON_LEAVE_EMAIL_CLICK,
			() => this.emit(SettingsWindowView.EVENT_BUTTON_REGISTRATION_CLICK),
			this,
		);
		this.dialog.position.set(-830, -420);
		this.setCardTextureMode();

		const textVersion = new PIXI.extras.BitmapText(version, { font: '20px wendyOne' });
		textVersion.alpha = 0.5;
		textVersion.tint = 0x6a91aa;
		textVersion.anchor = new PIXI.Point(1, 0.5);
		textVersion.position.set(680, 420);

		const footer = new SettingsFooterView();
		footer.on(SettingsFooterView.EVENT_BUTTON_LINK_1_CLICK, () => { window.open(this.thirdPartyLinks[0]); });
		footer.position.set(-373, 353);

		this.panel = new SettingsPanelView(
			supportLanguageMap,
			redeemCode,
		);
		this.panel.position.set(309, 29);
		this.panel.on(
			SettingsPanelView.EVENT_CHANGE_USERNAME,
			(username: string) => this.emit(SettingsWindowView.EVENT_CHANGE_USERNAME, username),
			this,
		);
		this.panel.on(
			SettingsPanelView.EVENT_CHANGE_LANGUAGE,
			(lang: string) => this.emit(SettingsWindowView.EVENT_CHANGE_LANGUAGE, lang),
			this,
		);
		this.panel.on(
			SettingsPanelView.EVENT_CHANGE_SOUNDS_VOLUME,
			(value: number) => this.emit(SettingsWindowView.EVENT_CHANGE_SOUNDS_VOLUME, value),
			this,
		);
		this.panel.on(
			SettingsPanelView.EVENT_CHANGE_MUSIC_VOLUME,
			(value: number) => this.emit(SettingsWindowView.EVENT_CHANGE_MUSIC_VOLUME, value),
			this,
		);
		this.panel.on(
			SettingsPanelView.EVENT_SUPPORT_BUTTON_CLICK,
			() => this.emit(SettingsWindowView.EVENT_BUTTON_SUPPORT_CLICK),
			this,
		);
		this.panel.on(
			SettingsPanelView.EVENT_CHANGE_CARDPAY,
			(value: boolean) => this.emit(SettingsWindowView.EVENT_CHANGE_CARDPAY, value),
			this,
		);

		const closeButton = new CloseButtonView();
		closeButton.position.set(672, -345);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.mainContainer.addChild(
			bg as PIXI.DisplayObject,
			this.card,
			this.labelTitle,
			this.dialog,
			footer,
			this.panel,
			closeButton,
			textVersion,
		);

		this.updateText();

		if (this.gameProfileModel.hasUsername()) {
			this.panel.setUsername(this.gameProfileModel.getUsername());
		}

		if (this.gameProfileModel.hasPendingReward()) {
			this.onLoggedIn();
		} else if (this.gameProfileModel.isLoggedIn()) {
			this.setModeShowEmail();
		} else {
			this.dialog.setMode({ mode: SettingsCharacterDialogViewMode.SHOW_BUTTON_LEAVE_EMAIL });
		}

		const paymentMethod = this.gameProfileModel.getPaymentMethod();

		if (paymentMethod != null) {
			this.panel.setCardpay(paymentMethod === PaymentMethod.CARDPAY);
		} else {
			this.panel.setCardpay(defaultPaymentMethod === PaymentMethod.CARDPAY);
		}
	}

	private setCardTextureMode(): void {
		if (this.dialog.isLogInRewardCollected()) {
			this.card.texture = AssetsStorage.getAtlas('lootboxAtlas')['setting_card_bg1'];
		} else {
			this.card.texture = AssetsStorage.getAtlas('lootboxAtlas')['setting_card_bg'];
		}
	}

	private setModeShowEmail(): void {
		this.dialog.setMode({
			mode: SettingsCharacterDialogViewMode.SHOW_EMAIL,
			data: { email: this.gameProfileModel.getEmail() },
		});
		this.setCardTextureMode();
	}

	private onLoggedIn(): void {
		this.dialog.setMode({ mode: SettingsCharacterDialogViewMode.SHOW_BUTTON_COLLECT });
	}

	private onUpdateUsername(): void {
		this.panel.setUsername(this.gameProfileModel.getUsername());
	}

	private onUpdateCardpay(): void {
		this.panel.setCardpay(this.gameProfileModel.getPaymentMethod() === PaymentMethod.CARDPAY);
	}

	private updateText(): void {
		this.labelTitle.text = this.localizationStorage.getLocalizedString('#settings_window_title');
	}

	private onRewardCollected(): void {
		this.setModeShowEmail();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.gameProfileModel.off(GameProfileModel.EVENT_LOGGED_IN, this.onLoggedIn, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_REWARD_COLLECTED, this.onRewardCollected, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_USERNAME_UPDATED, this.onUpdateUsername, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_PAYMENT_METHOD_UPDATED, this.onUpdateCardpay, this);
		super.destroy(options);
	}
}
