import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { VideoCardPlayButtonView } from '@views/ui/cards/progressBar/VideoCardPlayButtonView';
import { SoundController } from '@src/main/SoundController';
import { TextField } from '@views/components/text/TextField';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { ButtonWatchVideoView } from './ButtonWatchVideoView';
import * as TWEEN from '@tweenjs/tween.js';

export class VideoCardView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly model: GalleryVideoModel;
	private readonly stickerNew: PIXI.mesh.NineSlicePlane;
	private readonly previewPicture: PIXI.Sprite;
	private readonly playButton: VideoCardPlayButtonView;
	private readonly buttonWatch: ButtonWatchVideoView;

	private tweenButtonWatch: TWEEN.Tween;

	private flyTextLabel: FlyBitmapText;

	constructor(model: GalleryVideoModel) {
		super();

		this.model = model;

		this.localizationStorage = LocalizationStorage.getInstance();


		const nameStr = this.localizationStorage.getLocalizedString('#label_new_episode');
		const name = new PIXI.extras.BitmapText(nameStr, { font: '40px wendyOneShadowBold' });
		name.anchor = 0.5;
		name.y = -290;

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['u_rarity_card_bg']);
		bg.scale.set(0.8);
		bg.position.set(0, -34);

		this.previewPicture = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`card_preview_${model.getKey()}`]);
		this.previewPicture.y = -34;

		this.playButton = new VideoCardPlayButtonView();
		this.playButton.y = -34;
		this.playButton.visible = this.model.isOpened();

		this.stickerNew = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['new_sticker_icon'], 22, 0, 6, 0);
		this.stickerNew.width = 195;
		this.stickerNew.pivot.set(this.stickerNew.width / 2, this.stickerNew.height / 2);
		this.stickerNew.position.set(140, 251);
		this.stickerNew.scale.set(0.8);

		const stickerNewText = new TextField(
			this.localizationStorage.getLocalizedString('#video_sticker_new'),
			{ font: '34px wendyOne', align: 'center' },
			50,
			35,
		);
		stickerNewText.anchor = 0.5;
		stickerNewText.position.set(140, 256);

		this.buttonWatch = new ButtonWatchVideoView();
		this.buttonWatch.on(ButtonWatchVideoView.EVENT_CLICK, this.onClick, this);
		this.buttonWatch.y = 334;

		this.on('pointertap', this.onClick, this);
		this.interactive = true;

		this.addChild(
			bg,
			this.previewPicture,
			this.playButton,
			name as PIXI.DisplayObject,
			this.stickerNew,
			stickerNewText,
			this.buttonWatch,
		);
	}

	private onClick(): void {
		this.playButton.interruptAnimations();
		SoundController.getInstance().playButtonClick();

		this.emit(VideoCardView.EVENT_CLICK, { model: this.model.getKey() });
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenButtonWatch) {
			this.tweenButtonWatch.stop();
			this.tweenButtonWatch = null;
		}

		super.destroy(options);
	}
}
