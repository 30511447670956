import { BonusTypes } from '@src/types/BonusTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { BoostModel } from './BoostModel';

type BoostPeriodItem = { time: number, type: string, multiplier: number };
type PeriodItem = { start: number, multiplier: number };

export class BackToGameRewardModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_UPDATED: symbol = Symbol();

	private readonly businessesIncomePerSec: Map<string, SoftMoneyNumber>;
	private readonly businessesLastUpdate: Map<string, number>;

	private offlineSeconds: number;
	private lastUpdate: number;

	constructor(
		private readonly minOfflineTime: number,
		private readonly maxOfflineTime: number,
		private readonly offlineRewardMultiplier: number,
		private readonly boostModels: Map<string, BoostModel>,
		private readonly serverTime: number,
	) {
		super();

		this.businessesIncomePerSec = new Map();
		this.businessesLastUpdate = new Map();
	}

	public setBusinessLastUpdate(key: string, value: number): void {
		this.businessesLastUpdate.set(key, value);

		this.lastUpdate = this.getMaxLastUpdate();
		this.offlineSeconds = this.serverTime - this.lastUpdate;
	}

	public setBusinessIncomePerSec(key: string, value: SoftMoneyNumber): void {
		this.businessesIncomePerSec.set(key, value);
	}

	public setOfflineSeconds(value: number, lastUpdate: number): void {
		this.offlineSeconds = value;
		this.lastUpdate = lastUpdate;
		this.emit(BackToGameRewardModel.EVENT_UPDATED);
	}

	public reset(): void {
		this.offlineSeconds = 0;

		this.resetBusinessesLastUpdate();

		this.businessesIncomePerSec.clear();
	}

	public resetBusinessesLastUpdate(): void {
		this.businessesLastUpdate.clear();
	}

	public getOfflineSeconds(): number {
		return this.offlineSeconds;
	}

	public getRewardSoftMoney(improved = false): SoftMoneyNumber {
		let rewardSoftMoney = SoftMoneyNumber.ZERO;

		this.getRewardSoftMoneyByBusinesses(improved).forEach((businessIncome) => {
			rewardSoftMoney = rewardSoftMoney.add(businessIncome);
		});

		return rewardSoftMoney;
	}

	public getRewardSoftMoneyByBusinesses(improved = false): Map<string, SoftMoneyNumber> {
		const result = new Map();
		const rewardMultiplier: number = improved ? this.offlineRewardMultiplier : 1;

		this.businessesIncomePerSec.forEach((value, key) => {
			const offlineSeconds = this.businessesLastUpdate.has(key)
				? this.serverTime - this.businessesLastUpdate.get(key)
				: this.offlineSeconds;

			const lastUpdate = this.businessesLastUpdate.has(key)
				? this.businessesLastUpdate.get(key)
				: this.lastUpdate;

			if (offlineSeconds >= this.minOfflineTime) {
				const offlineSecondsClamped = Math.min(offlineSeconds, this.maxOfflineTime);
				const boostsData = this.getBusinessBoostsData(lastUpdate, offlineSecondsClamped);

				if (boostsData.length > 0 || false) {
					let rewardSoftMoney = SoftMoneyNumber.ZERO;

					boostsData.slice(0, -1).forEach((boostData, idx) => {
						const duration = boostsData[idx + 1].start - boostsData[idx].start;
						const delta = value.multiply(duration * boostData.multiplier * rewardMultiplier);

						rewardSoftMoney = rewardSoftMoney.add(delta);
					});

					result.set(key, rewardSoftMoney);
				} else {
					result.set(key, value.multiply(offlineSecondsClamped * rewardMultiplier));
				}
			}
		});

		return result;
	}

	public hasRewardSoftMoney(): boolean {
		return this.getRewardSoftMoney().greaterThan(SoftMoneyNumber.ZERO);
	}

	private getBusinessBoostsData(lastUpdate: number, offlineSeconds: number): PeriodItem[] {
		const boostsPeriods: BoostPeriodItem[] = [
			{ time: lastUpdate, type: 'start', multiplier: 1 },
			{ time: lastUpdate + offlineSeconds, type: 'end', multiplier: 1 },
		];
		const result: PeriodItem[] = [];
		let currentMultiplier = 1;
		let lastTime: number;

		this.boostModels.forEach((boostModel) => {
			const boostDeactivateTime = boostModel.getDeactivateTime();

			if (boostDeactivateTime != null && boostDeactivateTime >= lastUpdate
				&& boostModel.getBonusType() === BonusTypes.MULTIPLIER_INCOME) {
				const activateTime = Math.max(boostDeactivateTime - boostModel.getTime(), lastUpdate);
				const deactivateTime = Math.min(boostDeactivateTime, lastUpdate + offlineSeconds);

				boostsPeriods.push({
					time: activateTime,
					type: 'start',
					multiplier: boostModel.getBonusValue(),
				});

				boostsPeriods.push({
					time: deactivateTime,
					type: 'end',
					multiplier: boostModel.getBonusValue(),
				});
			}
		});

		boostsPeriods.sort((a, b) => a.time - b.time);

		boostsPeriods.forEach((period) => {
			if (period.type === 'start') {
				currentMultiplier *= period.multiplier;
			} else if (period.type === 'end') {
				currentMultiplier /= period.multiplier;
			}

			if (lastTime !== period.time) {
				result.push({ start: period.time, multiplier: currentMultiplier });
			} else {
				result[result.length - 1].multiplier = currentMultiplier;
			}

			lastTime = period.time;
		});

		return result;
	}

	private getMaxLastUpdate(): number {
		let result = -Infinity;

		this.businessesLastUpdate.forEach((lastUpdate) => {
			if (lastUpdate > result) {
				result = lastUpdate;
			}
		});

		return result;
	}
}
