import { BankWindowViewController } from './BankWindowViewController';
import { GameProfileModel } from '@models/GameProfileModel';
import { BankModel, BankTabElementPricedType } from '@models/bank/BankModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { RewardFactory } from '@src/initializers/RewardFactory';
import HardMoneyModel from '@models/money/HardMoneyModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { TransactionOptions } from '@src/types/TransactionTypes';
import { BankPurchaseOriginType } from '@src/types/BankTypes';
import { RewardDescriptionsWithTransactionIdType } from '@src/types/RewardTypes';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { DeviceUtils } from '@src/utils/DeviceUtils';

export class NutakuBankWindowViewController extends BankWindowViewController {
	constructor(
		private readonly gameProfileModel: GameProfileModel,
		bankModel: BankModel,
		bankSavesModel: BankSavesModel,
		bankPaymentSaveModel: BankPaymentSaveModel,
		bankLoader: BankLoader,
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		networkRequestSender: NetworkRequestSender,
		transactionCreator: TransactionCreator,
		rewardFactory: RewardFactory,
		hardMoneyModel: HardMoneyModel,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		boostModels: Map<string, BoostModel>,
		timeskipModels: Map<string, TimeskipModel>,
		serverTime: ServerTimeModel,
	) {
		super(
			bankModel,
			bankSavesModel,
			bankPaymentSaveModel,
			bankLoader,
			windowViewSystem,
			windowFactory,
			networkRequestSender,
			transactionCreator,
			rewardFactory,
			hardMoneyModel,
			softMoneyModel,
			prestigeMoneyModel,
			boostModels,
			timeskipModels,
			serverTime,
		);
	}

	protected async makeTransaction(
		data: BankTabElementPricedType,
		options: TransactionOptions,
		origin?: BankPurchaseOriginType,
	): Promise<RewardDescriptionsWithTransactionIdType> {
		if (this.gameProfileModel.isLoggedIn()) {
			return super.makeTransaction(data, options, origin);
		}
		return this.requestGuestLogin()
			.then(() => {
				this.gameProfileModel.setLoggedIn();
				return super.makeTransaction(data, options, origin);
			});
	}

	private requestGuestLogin(): Promise<boolean> {
		let result;

		if (DeviceUtils.isAndroid() || DeviceUtils.isIos()) {
			result = Promise.resolve(false);
		} else {
			result = this.requestGuestLoginPC();
		}

		return result;
	}

	// eslint-disable-next-line class-methods-use-this
	private requestGuestLoginPC(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			const guest: nutaku.Guest = opensocial.newGuestSignUp();
			opensocial.requestGuestSignUp(guest, (response) => {
				if (!response.hadError()) {
					const respCode = guest.getField(nutaku.Guest.Field.RESPONSE_CODE);
					if (respCode === nutaku.Guest.ResponseCode.SIGNUP) {
						resolve(true);
					} else {
						reject();
					}
				} else {
					reject();
				}
			});
		});
	}
}
