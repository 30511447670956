import { SkillConfig } from '@configs/SkillConfig';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { SkillEffectiveImproveModel } from '@models/skills/SkillEffectiveImproveModel';
import { SkillConstantProfitModel } from '@models/skills/SkillConstantProfitModel';
import { SkillReduceReloadModel } from '@models/skills/SkillReduceReloadModel';
import CharacterConfig from '@configs/CharacterConfig';
import { SkillTimeImproveModel } from '@models/skills/SkillTimeImproveModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SkillAutoTapModel } from '@models/skills/SkillAutoTapModel';
import { CharacterSaveData, SkillSaveData } from '@src/types/SaveTypes';

export class SkillsFactory {
	private static createModel(config: SkillConfig): SkillModel {
		const type = config.getType();
		let model: SkillModel;

		switch (type) {
			case SkillTypes.TIME_IMPROVE:
				model = new SkillTimeImproveModel(config);
				break;
			case SkillTypes.EFFECTIVE_IMPROVE:
				model = new SkillEffectiveImproveModel(config);
				break;
			case SkillTypes.CONSTANT_PROFIT:
				model = new SkillConstantProfitModel(config);
				break;
			case SkillTypes.REDUCE_RELOAD:
				model = new SkillReduceReloadModel(config);
				break;
			case SkillTypes.AUTO_TAP:
				model = new SkillAutoTapModel(config);
				break;
			case SkillTypes.PROFIT_IMPROVE:
			case SkillTypes.TAP_ADD_MONEY:
			default:
				model = new SkillModel(config);
		}
		return model;
	}

	private readonly configs: Map<string, SkillConfig>;
	private readonly characterConfigs: Map<string, CharacterConfig>;
	private readonly characterSavesData: Map<string, CharacterSaveData>;
	private readonly skillSavesData: Map<string, SkillSaveData>;
	private readonly serverTimeModel: ServerTimeModel;

	constructor(
		configs: Map<string, SkillConfig>,
		characterConfigs: Map<string, CharacterConfig>,
		characterSavesData: Map<string, CharacterSaveData>,
		skillSavesData: Map<string, SkillSaveData>,
		serverTime: ServerTimeModel,
	) {
		this.configs = configs;
		this.characterConfigs = characterConfigs;
		this.characterSavesData = characterSavesData;
		this.skillSavesData = skillSavesData;
		this.serverTimeModel = serverTime;
	}

	public createModels(): Map<string, SkillModel> {
		const models: Map<string, SkillModel> = new Map();

		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		this.configs.forEach((skillConfig, key) => {
			const model: SkillModel = SkillsFactory.createModel(skillConfig);
			models.set(key, model);
			if (this.skillSavesData.has(skillConfig.getKey())) {
				const skillSaveData = this.skillSavesData.get(skillConfig.getKey());
				model.setActivatedOn(skillSaveData.activatedOn);
				model.setReduceReloadMultiplier(skillSaveData.reduceReloadMultiplier);
				if (model.getType() === SkillTypes.CONSTANT_PROFIT) {
					(model as SkillConstantProfitModel).setTotalMultiplier(skillSaveData.multiplier);
				}

				if (model.getType() === SkillTypes.EFFECTIVE_IMPROVE && skillSaveData.activeTime === null) {
					model.setIsActive(true);
				} else if (model.getActivatedOn() + model.getActiveTime() >= currentTime) {
					model.setIsActive(true);
					model.setEffectiveImproveMultiplier(skillSaveData.improveMultiplier);
				} else if (model.getActivatedOn() + model.getReloadTime() >= currentTime) {
					model.setIsReloading(true);
				} else {
					model.setReduceReloadMultiplier(0);
				}
			}
		});

		// Set based on related character
		this.characterConfigs.forEach((characterConfig, characterKey) => {
			if (characterConfig.hasSkillBonus()) {
				const relatedSkill = models.get(characterConfig.getSkillKey());
				relatedSkill.setCharacterKey(characterKey);
				const characterSaveData = this.characterSavesData.get(characterKey);
				if (characterSaveData) {
					relatedSkill.setOpened();
					relatedSkill.setCharacterLevel(characterSaveData.level);
				}
			}
		});

		// Init influencing skills
		const modelsArray = Array.from(models.values());
		modelsArray.forEach((model) => {
			if (model.getType() === SkillTypes.REDUCE_RELOAD) {
				const modelAsReduceReload = model as SkillReduceReloadModel;
				modelAsReduceReload.setInfluencedModels(modelsArray.filter(m => {
					const type: string = m.getType();
					return type !== SkillTypes.REDUCE_RELOAD && type !== SkillTypes.EFFECTIVE_IMPROVE;
				}));
			} else if (model.getType() === SkillTypes.EFFECTIVE_IMPROVE) {
				const modelAsEffectiveImprove = model as SkillEffectiveImproveModel;
				modelAsEffectiveImprove.setInfluencedModels(modelsArray.filter(m => m.getType() !== SkillTypes.EFFECTIVE_IMPROVE));
			}
		});

		return models;
	}
}
