import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter20007View } from '@views/characters/events/event2/EventCharacter20007View';
import { EventCharacter20008View } from '@views/characters/events/event2/EventCharacter20008View';
import { EventCharacter20009View } from '@views/characters/events/event2/EventCharacter20009View';

export class EventBusiness20003View extends BusinessBaseView {
	constructor() {
		const character20007 = new EventCharacter20007View();
		const character20008 = new EventCharacter20008View();
		const character20009 = new EventCharacter20009View();

		super('business20003', [character20007, character20008, character20009]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b1_decor5']).setTransform(276, 34, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor1']).setTransform(-225, -17, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor3']).setTransform(-217, -72, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor2']).setTransform(166, -37, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b1_decor4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character20007,
			character20008,
			character20009 as PIXI.DisplayObject,
		);
	}
}
