export class SandboxOperation {
	private readonly preStart: () => void;
	private readonly postStart: () => void;

	constructor(
		preStart: () => void,
		postStart: () => void,
	) {
		this.preStart = preStart;
		this.postStart = postStart;
	}

	public start(operation: () => void): void {
		this.preStart();

		operation();

		this.postStart();
	}
}
