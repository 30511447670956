import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import { GameProfileModel } from '@models/GameProfileModel';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { NotificationWindowView } from '../NotificationWindowView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class EmailConfirmationWindowView extends PopupWindowBaseView {
	private readonly localizationStorage: LocalizationStorage;

	public static readonly EVENT_BUTTON_CONFIRM_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_RESEND_CLICK: symbol = Symbol();

	private inputConfirmationCode: TextAreaInputView;
	private readonly resendButton: ButtonBaseView;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly gameProfileModel: GameProfileModel;

	private resendButtonBg: PIXI.mesh.NineSlicePlane;
	private resendButtonLabel: SizeableBitmapText;
	private resendButtonTimer: SizeableBitmapText;

	constructor(
		gameProfileModel: GameProfileModel,
	) {
		super(0.5, false);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.on(GameProfileModel.EVENT_CONFIRMATION_WRONG_CODE, this.onWrongCode, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_LOGGED_IN, this.onLoggedIn, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER, this.onActiveateRetry, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_TIMEOUT_RETRY_CONFIRMATION_TIMER, this.onTimeoutRetry, this);

		this.ticker = PIXI.ticker.shared;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 728;
		bg.height = 543;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const stringTitle = this.localizationStorage.getLocalizedString('#email_confirmation_window_title_label');
		const labelTitle = new PIXI.extras.BitmapText(
			stringTitle,
			{ font: '40px wendyOneShadowBold' },
		);
		labelTitle.anchor = 0.5;
		labelTitle.y = -220;

		const closeButton = new CloseButtonView();
		closeButton.position.set(320, -220);
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 680;
		whiteBg.height = 268;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -41;

		const stringEmailInfo = this.localizationStorage.getLocalizedString('#email_confirmation_window_email_info_label');
		const labelEmailInfo = new SizeableBitmapText(
			stringEmailInfo,
			whiteBg.width - 30,
			{ font: '24px wendyOne', tint: 0x6a7075 },
		);
		labelEmailInfo.anchor = 0.5;
		labelEmailInfo.y = -130;

		const labelEmail = new SizeableBitmapText(
			this.gameProfileModel.getEmail(),
			whiteBg.width - 30,
			{ font: '24px wendyOne', tint: 0x587cbf },
		);
		labelEmail.anchor = 0.5;
		labelEmail.y = -104;

		const greyBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		greyBg.tint = 0x000000;
		greyBg.alpha = 0.12;
		greyBg.width = 671;
		greyBg.height = 70;
		greyBg.y = 53;

		const stringCheckSpam = this.localizationStorage.getLocalizedString('#email_confirmation_window_check_spam_label');
		const labelCheckSpam = new SizeableBitmapText(
			stringCheckSpam,
			greyBg.width - 30,
			{ font: '22px wendyOne', tint: 0x6a7075 },
		);
		labelCheckSpam.anchor = 0.5;
		labelCheckSpam.y = 55;

		this.inputConfirmationCode = new TextAreaInputView({
			bgWidth: 380,
			maxLength: 32,
		});
		this.inputConfirmationCode.y = -46;
		const stringInvalidCode = this.localizationStorage.getLocalizedString('#email_confirmation_window_wrong_code_label');
		this.inputConfirmationCode.setTextInvalidInput(stringInvalidCode);

		this.resendButtonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_gold'], 11, 11, 11, 11);
		this.resendButtonBg.width = 325;
		this.resendButtonBg.height = 92;
		this.resendButtonBg.pivot.set(this.resendButtonBg.width / 2, this.resendButtonBg.height / 2);
		this.resendButton = new ButtonBaseView(this.resendButtonBg);
		this.resendButton.on(ButtonBaseView.EVENT_CLICK, this.onResendButtonClick, this);
		this.resendButton.position.set(-177, 178);

		this.resendButtonLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#email_confirmation_window_resend_label'),
			310,
			{ font: '34px wendyOneShadowBold' },
		);
		this.resendButtonLabel.anchor = 0.5;

		const confirmButton = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 325, 92),
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
			310,
		);
		confirmButton.setTextLabel(this.localizationStorage.getLocalizedString('#email_confirmation_window_confirm_label'));
		confirmButton.on(ButtonBaseView.EVENT_CLICK, this.onConfirmButtonClick, this);
		confirmButton.position.set(177, 178);

		this.resendButton.addChild(this.resendButtonLabel);

		this.mainContainer.addChild(
			bg,
			labelTitle as PIXI.DisplayObject,
			closeButton,
			whiteBg,
			greyBg,
			this.resendButton,
			confirmButton,
			labelCheckSpam,
			labelEmailInfo,
			labelEmail,
			this.inputConfirmationCode,
		);

		this.resendButtonTimer = new SizeableBitmapText('', 310, { font: '30px wendyOneShadowBold', tint: 0xffe597 });
		this.resendButtonTimer.anchor = 0.5;
		this.resendButtonTimer.y = 17;
		this.resendButtonTimer.visible = false;
		this.resendButton.addChild(this.resendButtonTimer);

		if (this.gameProfileModel.getRetrySendConfirmationCodeTime() > 0) {
			this.showTimerOnResendButton();
			this.onActiveateRetry();
		}
	}

	private onConfirmButtonClick(): void {
		if (this.inputConfirmationCode.getText().length > 0) {
			this.emit(EmailConfirmationWindowView.EVENT_BUTTON_CONFIRM_CLICK, this.inputConfirmationCode.getText());
		} else {
			this.inputConfirmationCode.onInvalidInput();
		}
	}

	private onResendButtonClick(): void {
		this.emit(EmailConfirmationWindowView.EVENT_BUTTON_RESEND_CLICK);
		this.showTimerOnResendButton();
	}

	private showTimerOnResendButton(): void {
		this.resendButtonTimer.visible = true;
		this.resendButtonLabel.y = -16;
		this.resendButtonBg.texture = AssetsStorage.getAtlas('uiAtlas')['button_gray'];

		this.resendButton.interactive = false;
		this.resendButton.alpha = 0.6;
	}

	private onUpdateTicker(): void {
		this.resendButtonTimer.text = NumberUtils.secToDHMS(Math.round(this.gameProfileModel.getRetrySendConfirmationCodeTime()));
	}

	private onWrongCode(): void {
		this.inputConfirmationCode.onInvalidInput();
	}

	private onLoggedIn(): void {
		this.startCloseAnimation();

		const loggedInNotification = new NotificationWindowView(
			this.localizationStorage.getLocalizedString('#label_email_confirmed_title'),
			this.localizationStorage.getLocalizedString('#label_email_confirmed_body'),
			this.localizationStorage.getLocalizedString('#login_window_button_login'),
		);
		loggedInNotification.onShown();
		loggedInNotification.once(NotificationWindowView.EVENT_WINDOW_CLOSED, () => this.onClose(false), this);
		this.addChild(loggedInNotification);
	}

	private onActiveateRetry(): void {
		this.ticker.add(this.onUpdateTicker, this);
	}

	private onTimeoutRetry(): void {
		this.ticker.remove(this.onUpdateTicker, this);

		this.resendButtonLabel.y = 0;
		this.resendButtonBg.texture = AssetsStorage.getAtlas('uiAtlas')['button_gold'];

		this.resendButton.interactive = true;
		this.resendButton.alpha = 1;
		this.resendButtonTimer.visible = false;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_CONFIRMATION_WRONG_CODE, this.onWrongCode, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGGED_IN, this.onLoggedIn, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER, this.onActiveateRetry, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_TIMEOUT_RETRY_CONFIRMATION_TIMER, this.onTimeoutRetry, this);
		this.ticker.remove(this.onUpdateTicker, this);

		super.destroy(options);
	}
}
