import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120016View } from '@views/characters/events/event12/EventCharacter120016View';
import { EventCharacter120017View } from '@views/characters/events/event12/EventCharacter120017View';
import { EventCharacter120018View } from '@views/characters/events/event12/EventCharacter120018View';

export class EventBusiness120006View extends BusinessBaseView {
	constructor() {
		const eventCharacter120016View = new EventCharacter120016View();
		const eventCharacter120017View = new EventCharacter120017View();
		const eventCharacter120018View = new EventCharacter120018View();

		super('business120006', [eventCharacter120016View, eventCharacter120017View, eventCharacter120018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter120016View as PIXI.DisplayObject,
			eventCharacter120017View,
			eventCharacter120018View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
