import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter30001View } from '@views/characters/events/event3/EventCharacter30001View';
import { EventCharacter30002View } from '@views/characters/events/event3/EventCharacter30002View';
import { EventCharacter30003View } from '@views/characters/events/event3/EventCharacter30003View';

export class EventBusiness30001View extends BusinessBaseView {
	constructor() {
		const eventCharacter30001View = new EventCharacter30001View();
		const eventCharacter30002View = new EventCharacter30002View();
		const eventCharacter30003View = new EventCharacter30003View();

		super('business30001', [eventCharacter30001View, eventCharacter30002View, eventCharacter30003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b7_decor4']).setTransform(-17, -60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor2']).setTransform(-18, 15, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor1']).setTransform(-17, -93.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor5']).setTransform(-14, -99, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter30001View as PIXI.DisplayObject,
			eventCharacter30002View,
			eventCharacter30003View,
		);
	}
}
