import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter100003View extends CharacterMainBaseView {
	constructor() {
		super('character100003');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-17, 76);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-200, -60),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-290, -70),
		});
		this.initQuickPhrases(this.key);
	}
}
