import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness90001View } from '@views/businesses/event9Businesses/eventBuilding1/EventBusiness90001View';
import { EventBusiness90002View } from '@views/businesses/event9Businesses/eventBuilding1/EventBusiness90002View';
import { EventBusiness90003View } from '@views/businesses/event9Businesses/eventBuilding1/EventBusiness90003View';
import { EventBusiness90004View } from '@views/businesses/event9Businesses/eventBuilding2/EventBusiness90004View';
import { EventBusiness90005View } from '@views/businesses/event9Businesses/eventBuilding2/EventBusiness90005View';
import { EventBusiness90006View } from '@views/businesses/event9Businesses/eventBuilding2/EventBusiness90006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevel9View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('italyMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['italy_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['italy_morning/road'], EventLevel9View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/house5']).setTransform(-55, 656),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house4']).setTransform(919, 660),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(191, 764),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house1']).setTransform(1513, 618),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house2']).setTransform(2197, 622),
			new PIXI.Sprite(levelBgAtlas['italy_morning/house1']).setTransform(2740, 618),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor1']).setTransform(2080, 764),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(512, 666),
			new PIXI.Sprite(levelBgAtlas['italy_morning/city1']).setTransform(1112, 666),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('italyMorningAtlas');

		this.background = EventLevel9View.createMorningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness90001View(),
			new EventBusiness90002View(),
			new EventBusiness90003View(),
			new EventBusiness90004View(),
			new EventBusiness90005View(),
			new EventBusiness90006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['italy_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['italy_morning/building_left']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor4']).setTransform(1200, 400),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor3']).setTransform(1190, 545),
			new PIXI.Sprite(levelBgAtlas['italy_morning/decor2']).setTransform(1143, 945),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);
		this.initLevel(EventLevel9View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}
}
