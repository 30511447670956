import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter110005View extends CharacterMainBaseView {
	constructor() {
		super('character110005');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-145, 27);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-145, -45),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-90, -48),
			bgMinHeight: 30,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
