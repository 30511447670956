import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter54013View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54013View';
import { EventCharacter54014View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54014View';
import { EventCharacter54015View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54015View';

export class EventBusiness54005View extends BusinessBaseView {
	constructor() {
		const eventCharacter54013View = new EventCharacter54013View();
		const eventCharacter54014View = new EventCharacter54014View();
		const eventCharacter54015View = new EventCharacter54015View();

		super('business54005', [eventCharacter54013View, eventCharacter54014View, eventCharacter54015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter54013View as PIXI.DisplayObject,
			eventCharacter54014View,
			eventCharacter54015View,
		);
	}
}
