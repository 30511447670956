import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { SoundController } from '@src/main/SoundController';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import * as TWEEN from '@tweenjs/tween.js';

export class ItemPromoteButtonView extends ButtonWithCostValueView {
	private localizationStorage: LocalizationStorage;

	private readonly buttonLabel: PIXI.extras.BitmapText;
	private scaleAnimation: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;
	private enoughCards: boolean;
	private enoughPrestige: boolean;

	private mouseOver: boolean;

	constructor(enoughCards: boolean, enoughPrestige: boolean) {
		const buttonBg = enoughCards && enoughPrestige
			? new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11)
			: new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_gold'], 11, 11, 11, 11);
		const buttonWidth = 400;
		const buttonHeight = 130;
		buttonBg.width = buttonWidth;
		buttonBg.height = buttonHeight;
		buttonBg.pivot.set(buttonWidth / 2, buttonHeight / 2);

		super({
			type: ButtonValueTypes.PRESTIGE_MONEY,
			buttonBg,
			fontStyle: { font: { size: 48, name: 'wendyOneShadowBold' }, align: 'center' },
		});

		this.enoughCards = enoughCards;
		this.enoughPrestige = enoughPrestige;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.costText.y = 20;

		this.buttonLabel = new PIXI.extras.BitmapText('', { font: { size: 33, name: 'wendyOne' } });
		this.buttonLabel.anchor = 0.5;
		this.buttonLabel.position.set(0, -30);
		this.buttonLabel.tint = 0x1d3d09;

		this.scaleAnimation = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.off('pointerout', this.onPointerOut, this);

		this.updateText();

		this.addChild(this.buttonLabel);

		this.on('pointerover', this.onMouseOver, this);
		this.on('pointerout', this.onMouseOut, this);
		this.tryStartAnimation();
	}

	private onMouseOver(): void {
		this.stopAnimation();
		if (!this.mouseOver) {
			SoundController.getInstance().playButtonPointerOver();
		}
		this.mouseOver = true;
	}

	private onMouseOut(): void {
		this.tryStartAnimation();
		this.mouseOver = false;
	}

	private update(): void {
		this.scaleAnimation.update(PIXI.ticker.shared.lastTime);
	}

	private updateText(): void {
		this.buttonLabel.text = this.localizationStorage.getLocalizedString('#info_window_upgrade_button');
	}

	public updateEnoughResources(enoughPrestige: boolean, enoughCards: boolean): void {
		this.enoughPrestige = enoughPrestige;
		this.enoughCards = enoughCards;
		if (enoughPrestige && enoughCards) {
			this.tryStartAnimation();
		} else {
			this.stopAnimation();
		}
		(this.buttonBg as PIXI.Sprite).texture = enoughCards
			? AssetsStorage.getAtlas('uiAtlas')['button_green']
			: AssetsStorage.getAtlas('uiAtlas')['button_gold'];
	}

	private tryStartAnimation(): void {
		if (this.isEnabled() && this.enoughCards && this.enoughPrestige) {
			if (this.scale.x > 1) {
				new TWEEN.Tween(this.scale, this.scaleAnimation)
					.to({ x: 1, y: 1 }, 600)
					.chain(
						new TWEEN.Tween(this.scale, this.scaleAnimation)
							.to({ x: 1.05, y: 1.05 }, 600)
							.yoyo(true)
							.repeat(Infinity),
					)
					.start();
			} else {
				new TWEEN.Tween(this.scale, this.scaleAnimation)
					.to({ x: 1.05, y: 1.05 }, 600)
					.yoyo(true)
					.repeat(Infinity)
					.start();
			}
		} else {
			new TWEEN.Tween(this.scale, this.scaleAnimation)
				.to({ x: 1, y: 1 }, 50)
				.easing(TWEEN.Easing.Quadratic.In)
				.start();
		}
	}

	private stopAnimation(): void {
		this.scaleAnimation.removeAll();
	}

	public setEnabled(enable: boolean): void {
		super.setEnabled(enable);
		if (enable) {
			this.tryStartAnimation();
		} else {
			this.stopAnimation();
		}
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playPromoteButton();

		this.emit(ItemPromoteButtonView.EVENT_CLICK, this);
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		this.stopAnimation();
		this.ticker.remove(this.update);
		super.destroy();
	}
}
