import { BusinessModel } from '@models/BusinessModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBuyCustomersView } from './ButtonBuyCustomersView';
import { CustomersProgressView } from './CustomersProgressView';
import { ButtonMaxCustomersView } from '@views/ui/ButtonMaxCustomersView';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { BusinessIconView } from './businessIcon/BusinessIconView';
import { UpgradeModel } from '@models/UpgradeModel';
import { CharacterSkillPairType } from '@src/types/CharacterSkillPairType';
import { SoundController } from '@src/main/SoundController';
import { ShowNewCustomersView } from './ShowNewCustomersView';
import { IncomeMoneyView } from './IncomeMoneyView';
import { ViewportUIView, ViewportUIChildType } from '@views/components/ViewportUIView';
import { ViewportView } from '@views/components/ViewportView';
import * as TWEEN from '@tweenjs/tween.js';
import { BusinessClickAnimation } from './BusinessClickAnimation';
import { BusinessManageUIView } from './manage/BusinessManageUIView';
import { ShowPrestigeBonusView } from './ShowPrestigeBonusView';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { Emitter } from 'pixi-particles';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import { BonusTypes } from '@src/types/BonusTypes';
import { PromotableModel } from '@models/PromotableModel';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { ShowNewBonusView } from '@views/businesses/ui/acquired/ShowNewBonusView';
import { IncomeView } from './income/IncomeView';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { SkillAutoTapModel } from '@models/skills/SkillAutoTapModel';
import { CheatModel } from '@models/CheatModel';
import { NumberUtils } from '@src/utils/NumberUtils';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';

export interface BusinessBounds {
	topLeft: PIXI.Point;
	topRight: PIXI.Point;
	bottomLeft: PIXI.Point;
	bottomRight: PIXI.Point;
}

export class BusinessAcquiredUIView extends PIXI.Container {
	public static readonly INCOME_TIME_FOR_HIGH_SPEED_BAR_SEC: number = 1;

	public static readonly EVENT_ZOOM_IN_REQUESTED: symbol = Symbol();
	public static readonly EVENT_ZOOM_OUT_REQUESTED: symbol = Symbol();

	public static readonly EVENT_PRESTIGE_BONUS_APPEAR: symbol = Symbol();
	public static readonly EVENT_PRESTIGE_BONUS_CLICK: symbol = Symbol();

	public static readonly EVENT_BACKGROUND_CLICK: symbol = Symbol();
	public static readonly EVENT_CHARACTER_CLICK: symbol = Symbol();

	public static readonly EVENT_INCOME_MONEY_APPEAR: symbol = Symbol();

	public static readonly EVENT_BUTTON_BUY_CUSTOMERS_CLICK: symbol = Symbol();

	public static readonly EVENT_BUTTON_FIND_IN_SUMMON_CLICK: symbol = Symbol();

	public static readonly EVENT_BUTTON_CHARACTER_PROMOTE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_UPGRADE_PROMOTE_CLICK: symbol = Symbol();

	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly cheatModel: CheatModel;
	private readonly model: BusinessModel;
	private readonly newCustomerMultiplierModel: NewCustomersMutiplierModel;
	private readonly skillModels: Map<string, SkillModel>;
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly localizationStorage: LocalizationStorage;
	private readonly viewport: ViewportView;
	private readonly viewportUI: ViewportUIView;
	private readonly upgrades: UpgradeModel[];

	private readonly buttonMaxCustomers: ButtonMaxCustomersView;

	private readonly mainUI: PIXI.Container;

	private readonly textName: PIXI.extras.BitmapText;
	private readonly buttonBuyCustomers: ButtonBuyCustomersView;
	private readonly customersProgressView: CustomersProgressView;
	private readonly incomeView: IncomeView;
	private readonly businessIconView: BusinessIconView;
	private readonly showNewCustomersView: ShowNewCustomersView;
	private readonly showNewBonusView: ShowNewBonusView;
	private readonly manageUI: BusinessManageUIView;

	private businessClickHandle: number;

	private showPrestigeBonusView: ShowPrestigeBonusView;

	private incomeMoneyView: IncomeMoneyView;
	private characterSkillPairs: CharacterSkillPairType[];

	private readonly localBounds: PIXI.Rectangle;

	private relatedCharacterActivated: boolean;

	private readonly fxAtlas: PIXI.loaders.TextureDictionary;

	private timeImproveEmitters: Emitter[];
	private bonusActivationEmitters: Emitter[];

	private businessZoomedIn: boolean;

	private flyTextLabel: FlyBitmapText;

	constructor(
		cheatModel: CheatModel,
		model: BusinessModel,
		newCustomerMultiplierModel: NewCustomersMutiplierModel,
		skillModels: Map<string, SkillModel>,
		upgrades: UpgradeModel[],
		characters: CharacterModel[],
		characterSkillPairs: CharacterSkillPairType[],
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		buttonMaxCustomers: ButtonMaxCustomersView,
		viewportUI: ViewportUIView,
		viewport: ViewportView,
		localBounds: PIXI.Rectangle,
		currentLevel?: number,
	) {
		super();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.tweenGroup = new TWEEN.Group();

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.cheatModel = cheatModel;
		this.model = model;
		this.newCustomerMultiplierModel = newCustomerMultiplierModel;
		this.skillModels = skillModels;
		this.softMoneyModel = softMoneyModel;
		this.buttonMaxCustomers = buttonMaxCustomers;
		this.viewportUI = viewportUI;
		this.characterSkillPairs = characterSkillPairs;
		this.localBounds = localBounds;
		this.viewport = viewport;
		this.businessZoomedIn = false;
		this.upgrades = upgrades;
		this.bonusActivationEmitters = [];

		this.relatedCharacterActivated = characterSkillPairs.some(pair => pair.character.isActivated());

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.textName = new PIXI.extras.BitmapText('', { font: '24px wendyOneShadowBold' });
		this.textName.anchor = new PIXI.Point(0, 0.5);
		this.textName.position.set(-272, 110);

		this.buttonBuyCustomers = new ButtonBuyCustomersView();
		this.buttonBuyCustomers.position.set(226, 146);
		this.buttonBuyCustomers.interactive = true;
		this.buttonBuyCustomers.on(ButtonBuyCustomersView.EVENT_CLICK, this.onButtonBuyCustomersClick, this);

		this.incomeView = new IncomeView(this.model.isAutomated());
		this.incomeView.position.set(-90, 146);
		this.incomeView.setIncomeTimeInterval(this.model.getTimeIncomeInterval());

		this.customersProgressView = new CustomersProgressView(this.relatedCharacterActivated);
		this.customersProgressView.setCustomerCount(this.model.getCustomerCount(), false);
		this.customersProgressView.position.set(325, 131);

		// Prevent buttonBuyCustomers click event, when clicking on customersProgressView
		this.customersProgressView.interactive = true;
		this.customersProgressView.hitArea = new PIXI.Circle(0, 0, this.customersProgressView.width / 2);

		this.businessIconView = new BusinessIconView(
			characterSkillPairs.map(pair => pair.character),
			upgrades,
			softMoneyModel,
			prestigeMoneyModel,
		);
		this.businessIconView.position.set(-323, 130);
		this.businessIconView.on(BusinessIconView.EVENT_CLICK, this.onBusinessIconClick, this);

		this.showNewCustomersView = new ShowNewCustomersView();
		this.showNewCustomersView.position.set(this.buttonBuyCustomers.position.x + 10, this.buttonBuyCustomers.position.y);
		this.showNewCustomersView.interactive = true;

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateIncomeProgressBar, this);

		this.manageUI = new BusinessManageUIView(
			this.model,
			characterSkillPairs,
			upgrades,
			characters,
			softMoneyModel,
			currentLevel,
		);

		this.showNewBonusView = new ShowNewBonusView();
		this.showNewBonusView.position.set(0, -30);

		this.mainUI = new PIXI.Container();
		this.mainUI.addChild(
			this.showNewCustomersView,
			this.buttonBuyCustomers as PIXI.DisplayObject,
			this.customersProgressView,
			this.incomeView,
			this.businessIconView,
			this.textName,
			this.showNewBonusView,
		);

		this.addChild(this.mainUI);

		this.on('added', () => {
			this.initIncomeMoneyView();

			this.manageUI.visible = false;

			this.viewportUI.addChildByType(this.manageUI, ViewportUIChildType.BUSINESS_MANAGE_UI);

			if (this.viewport.isZoomed()) {
				this.onOtherViewZoomedIn();
			}
		});

		this.model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.onIncomeValueChanged, this);
		this.model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.onIncomeTimeIntervalChanged, this);
		this.model.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		this.model.on(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.onNewCustomersMultiplier, this);
		this.model.on(BusinessModel.EVENT_AUTOMATED, this.onBusinessAutomated, this);

		if (!this.model.isAutomated()) {
			this.model.on(BusinessModel.EVENT_INCOME_PROGRESS_COMPLETE, this.onIncomeProgressCompleteNotAutomated, this);
		}

		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyValueChanged, this);
		this.buttonMaxCustomers.on(ButtonMaxCustomersView.EVENT_CLICK, this.onMaxCustomersButtonClick, this);
		this.newCustomerMultiplierModel.on(NewCustomersMutiplierModel.EVENT_REWARD_ADDED, this.onNewCustomerMultplierReward, this);

		this.onTranslateText();

		if (!this.relatedCharacterActivated) {
			characterSkillPairs.forEach((pair) => {
				pair.character.once(CharacterModel.EVENT_ACTIVATED, this.onSomeRelatedCharacterActivated, this);
			});
		}

		const customerCountData = this.model.getMaxCustomersCountData(
			this.buttonMaxCustomers.getCurrentValue(),
			this.softMoneyModel.getValue(),
		);
		this.updateButtonBuyCustomers(customerCountData);
		this.updateCustomersProgressBar(customerCountData);

		this.updateTextIncome(false);

		if (this.newCustomerMultiplierModel.hasLootboxData()) {
			this.onNewCustomerMultplierReward();
		}

		this.timeImproveEmitters = [];
		this.skillModels.forEach((skillModel) => {
			// eslint-disable-next-line default-case
			switch (skillModel.getType()) {
				case SkillTypes.TIME_IMPROVE: {
					skillModel.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onTimeImproveSkillActivated, this);
					skillModel.on(SkillModel.EVENT_SKILL_DEACTIVATED, this.onTimeImproveSkillDeactivated, this);
					break;
				}
				case SkillTypes.PROFIT_IMPROVE: {
					skillModel.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onProfitImproveSkillActivated, this);
					skillModel.on(SkillModel.EVENT_SKILL_DEACTIVATED, this.onProfitImproveSkillDeactivated, this);
					break;
				}
				case SkillTypes.AUTO_TAP: {
					skillModel.on(SkillAutoTapModel.EVENT_GENERATE_TAP, this.generateClick, this);
					break;
				}
			}
		});

		this.upgrades.forEach((upgrade: UpgradeModel) => {
			upgrade.once(UpgradeModel.EVENT_ACTIVATED, this.playBonusAnimation, this);
		});
		this.characterSkillPairs.forEach((pair) => {
			pair.character.once(CharacterModel.EVENT_ACTIVATED, this.playBonusAnimation, this);
		});

		this.cheatModel.on(CheatModel.EVENT_UPDATE_GENERATE_BUSINESS_CLICK, this.onCheatGenerateBusinessClickUpdate, this);
	}

	public startMainUIHidingAnimation(duration: number = 100): void {
		new TWEEN.Tween(this.mainUI, this.tweenGroup)
			.to({ alpha: 0 }, duration)
			.onComplete(() => {
				this.mainUI.visible = false;

				if (this.model.isIncomeProgressComplete() && !this.model.isAutomated() && this.relatedCharacterActivated) {
					this.incomeMoneyView.showZoomed();
				}
			})
			.start();
	}

	public startMainUIAppearingAnimation(duration: number = 100): void {
		this.mainUI.visible = true;
		new TWEEN.Tween(this.mainUI, this.tweenGroup)
			.to({ alpha: 1 }, duration)
			.start();

		if (this.model.isIncomeProgressComplete() && !this.model.isAutomated() && this.relatedCharacterActivated) {
			this.incomeMoneyView.show();
		}
	}

	public onCharacterClick(position: PIXI.Point): void {
		if (this.relatedCharacterActivated) {
			this.startBusinessClickAnimation(position);
			SoundController.getInstance().playBusinessClick();
			this.emit(BusinessAcquiredUIView.EVENT_CHARACTER_CLICK);
		}
	}

	public onBackgroundClick(position: PIXI.Point): void {
		if (this.relatedCharacterActivated) {
			this.startBusinessClickAnimation(position);
			SoundController.getInstance().playBusinessClick();
			this.emit(BusinessAcquiredUIView.EVENT_BACKGROUND_CLICK);
		}
	}

	public onThisViewZoomedIn(): void {
		this.businessZoomedIn = true;
		this.textName.visible = false;
		this.buttonBuyCustomers.onZoomIn();
		this.incomeView.onZoomIn();
		this.customersProgressView.onZoomIn();
		if (this.showPrestigeBonusView) {
			this.showPrestigeBonusView.onZoomIn();
		}
		this.businessIconView.y = 148;
		this.businessIconView.onZoomIn();
		this.businessIconView.on(BusinessIconView.EVENT_CLICK, this.onBusinessZoomOutClick, this);
		this.manageUI.on(BusinessManageUIView.EVENT_BUTTON_BACK_CLICK, this.onBusinessZoomOutClick, this);
		if (this.incomeMoneyView && this.incomeMoneyView.visible) {
			this.incomeMoneyView.onZoomIn();
		}
		SoundController.getInstance().playBusinessZoom();
		this.startManageUIAppearingAnimation();
	}

	public onOtherViewZoomedIn(): void {
		this.incomeMoneyView.hide();
		this.startMainUIHidingAnimation();
	}

	public onThisViewZoomedOut(): void {
		this.businessZoomedIn = false;
		this.textName.visible = true;
		this.buttonBuyCustomers.onZoomOut();
		this.incomeView.onZoomOut();
		this.customersProgressView.onZoomOut();
		if (this.showPrestigeBonusView) {
			this.showPrestigeBonusView.onZoomOut();
		}
		this.businessIconView.y = 140;
		this.businessIconView.onZoomOut();
		this.businessIconView.off(BusinessIconView.EVENT_CLICK, this.onBusinessZoomOutClick, this);
		this.manageUI.off(BusinessManageUIView.EVENT_BUTTON_BACK_CLICK, this.onBusinessZoomOutClick, this);
		if (this.incomeMoneyView && this.incomeMoneyView.visible) {
			this.incomeMoneyView.onZoomOut();
		}
		SoundController.getInstance().playBusinessZoom();
		this.startManageUIHidingAnimation();
	}

	public onOtherViewZoomedOut(): void {
		this.incomeMoneyView.hide();
		this.startMainUIAppearingAnimation();
		this.startManageUIHidingAnimation();
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public hasPrestigeBonus(): boolean {
		return this.showPrestigeBonusView !== undefined;
	}

	public hasIncomeMoney(): boolean {
		return this.incomeMoneyView.visible;
	}

	public getBusinessIconContainer(): PIXI.Container {
		return this.businessIconView;
	}

	public getButtonBuyCustomersContainer(): PIXI.Container {
		return this.buttonBuyCustomers;
	}

	public isButtonBuyCustomersEnabled(): boolean {
		return this.buttonBuyCustomers.isEnabled();
	}

	public getIncomeMoneyContainer(): PIXI.Container {
		return this.incomeMoneyView;
	}

	public getManageUIView(): BusinessManageUIView {
		return this.manageUI;
	}

	public getMainUIContainer(): PIXI.Container {
		return this.mainUI;
	}

	public getPrestigeBonusContainer(): PIXI.Container {
		return this.showPrestigeBonusView;
	}

	public playIncomeCollectedAnimation(): void {
		SoundController.getInstance().playCollectIncome();

		this.incomeView.reset();
		this.incomeMoneyView.hide();
	}

	public isBusinessZoomedIn(): boolean {
		return this.businessZoomedIn;
	}

	private setManageUIPosition(): void {
		const manageUIPosViewport = this.viewport.getRelativePosition(this.position, this);
		this.manageUI.position.set(manageUIPosViewport.x, manageUIPosViewport.y);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private onTranslateText(): void {
		this.textName.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);
	}

	private onCheatGenerateBusinessClickUpdate(key: string, value: boolean): void {
		if (this.model.getKey() === key) {
			window.clearTimeout(this.businessClickHandle);
			this.businessClickHandle = null;
			if (value) {
				this.businessClickHandle = window.setInterval(() => this.generateClick(), 500);
			}
		}
	}

	private playBonusAnimation(model: PromotableModel): void {
		model.getBonuses().forEach((bonusModel) => {
			if (bonusModel.getType() !== BonusTypes.AUTOMATE) {
				const bonusValue = NumberUtils.numberToFloatShortString(bonusModel.getValue());
				const text = this.localizationStorage
					.getLocalizedString(`#bonus_short_descr_${bonusModel.getType()}`)
					.replace('{{value}}', bonusValue);

				this.showNewBonusView.show(text);
			}
		});
	}

	private playNewMultiplierAnimation(multiplier: number): void {
		const text = this.localizationStorage.getLocalizedString(`#bonus_short_descr_${BonusTypes.MULTIPLIER_INCOME}`)
			.replace('{{value}}', multiplier.toString());

		this.showNewBonusView.show(text);
		this.customersProgressView.glowNewMultiplierAnimation();
	}

	private onSomeRelatedCharacterActivated(): void {
		this.relatedCharacterActivated = true;
		this.characterSkillPairs.forEach(pair => pair.character.off(CharacterModel.EVENT_ACTIVATED, this.onSomeRelatedCharacterActivated, this, true));
		this.updateButtonBuyCustomerEnabled();
		this.customersProgressView.setCharacterActivated(this.relatedCharacterActivated);
		const customerCountData = this.model.getMaxCustomersCountData(
			this.buttonMaxCustomers.getCurrentValue(),
			this.softMoneyModel.getValue(),
		);
		this.updateCustomersProgressBar(customerCountData);
	}

	private updateIncomeProgressBar(): void {
		this.incomeView.setProgress(this.model.getIncomeProgress(), this.model.getIncomeProgressDeltaReduced());
	}

	private initIncomeMoneyView(): void {
		this.incomeMoneyView = new IncomeMoneyView();
		this.incomeMoneyView.interactive = true;
		this.incomeMoneyView.on('pointertap', () => {
			this.onBackgroundClick(this.position);
		});
		const positionViewportUI = this.viewportUI.toLocal(new PIXI.Point(0, 0), this);
		this.incomeMoneyView.position.set(positionViewportUI.x, positionViewportUI.y);

		this.viewportUI.addChildByType(this.incomeMoneyView, ViewportUIChildType.INCOME_MONEY);
	}

	private onNewCustomerMultplierReward(): void {
		if (!this.showPrestigeBonusView) {
			this.initPrestigeBonusView();
		}
		this.showPrestigeBonusView.setAmount(this.newCustomerMultiplierModel.getRewardPrestigeAmount());
	}

	private initPrestigeBonusView(): void {
		this.showPrestigeBonusView = new ShowPrestigeBonusView();
		this.showPrestigeBonusView.position.set(this.customersProgressView.x, this.customersProgressView.y - 90);
		this.mainUI.addChild(this.showPrestigeBonusView);
		this.showPrestigeBonusView.once('pointertap', this.onPrestigeBonusViewClick, this);
		this.showPrestigeBonusView.interactive = true;

		this.showPrestigeBonusView.startAppearingAnimation();

		if (this.businessZoomedIn) {
			this.showPrestigeBonusView.onZoomIn();
		}

		this.emit(BusinessAcquiredUIView.EVENT_PRESTIGE_BONUS_APPEAR);
	}

	private onPrestigeBonusViewClick(): void {
		SoundController.getInstance().playCollectPrestigeBonus();

		const pos = this.toGlobal(this.showPrestigeBonusView.position);

		this.showPrestigeBonusView.destroy({ children: true });
		this.showPrestigeBonusView = null;

		this.emit(BusinessAcquiredUIView.EVENT_PRESTIGE_BONUS_CLICK, pos);
	}

	private onButtonBuyCustomersClick(): void {
		if (!this.relatedCharacterActivated) {
			if (!this.flyTextLabel) {
				const stringLabel = this.localizationStorage.getLocalizedString('#fly_text_hire_girl');
				this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '24px wendyOneShadowBold' });
				this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
					this.flyTextLabel = null;
				});
				this.flyTextLabel.position.set(this.buttonBuyCustomers.x - 15, this.buttonBuyCustomers.y - 40);
				this.addChild(this.flyTextLabel);
			}
		} else if (!this.softMoneyModel.getValue().greaterThanOrEqualTo(this.model.getOneCustomerCostSoft())) {
			if (!this.flyTextLabel) {
				const stringLabel = this.localizationStorage.getLocalizedString('#fly_text_not_enough_money');
				this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '24px wendyOneShadowBold' });
				this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
					this.flyTextLabel = null;
				});
				this.flyTextLabel.position.set(this.buttonBuyCustomers.x - 15, this.buttonBuyCustomers.y - 40);
				this.addChild(this.flyTextLabel);
			}
		} else if (!this.model.isMaxCustomers()) {
			const customerCount = this.customersProgressView.getCustomerCount();
			this.startNewCustomersAnimation(customerCount);
			this.startProgressBarAnimation();

			this.emit(BusinessAcquiredUIView.EVENT_BUTTON_BUY_CUSTOMERS_CLICK, customerCount);
		}
	}

	private startNewCustomersAnimation(customerCount: number): void {
		this.showNewCustomersView.show(customerCount);
	}

	private startProgressBarAnimation(): void {
		this.customersProgressView.glowBarAnimation();
	}

	private onMaxCustomersButtonClick(): void {
		const customerCountData = this.model.getMaxCustomersCountData(
			this.buttonMaxCustomers.getCurrentValue(),
			this.softMoneyModel.getValue(),
		);
		this.updateCustomersProgressBar(customerCountData);
		this.updateButtonBuyCustomers(customerCountData);
	}

	private updateButtonBuyCustomerEnabled(): void {
		const enabled = this.relatedCharacterActivated && this.softMoneyModel.getValue().greaterThanOrEqualTo(this.model.getOneCustomerCostSoft());
		this.buttonBuyCustomers.setEnabled(enabled);
		this.customersProgressView.setSpecialBarVisible(enabled);
	}

	private updateButtonBuyCustomers(
		customerCountData: {
			cost: SoftMoneyNumber;
			count: number;
		},
	): void {
		if (this.model.isMaxCustomers()) {
			this.buttonBuyCustomers.setMaxMode(this.model.getMaxCustomers());
			this.customersProgressView.setMaxMode();
			this.customersProgressView.interactive = false;
		} else {
			this.updateButtonBuyCustomerEnabled();

			this.buttonBuyCustomers.setCost(customerCountData.cost.toString());
			this.customersProgressView.setAddCustomerCount(customerCountData.count);
		}
	}

	private updateCustomersProgressBar(
		customerCountData: {
			cost: SoftMoneyNumber;
			count: number;
		},
	): void {
		const customers = this.model.getCustomersCountForNextMultiplier();
		const nextCustomers = customers.current + customerCountData.count;
		this.customersProgressView.setProgress(customers.current, nextCustomers, customers.total);
	}

	private onSoftMoneyValueChanged(): void {
		if (!this.model.isMaxCustomers()) {
			const customerCountData = this.model.getMaxCustomersCountData(
				this.buttonMaxCustomers.getCurrentValue(),
				this.softMoneyModel.getValue(),
			);
			this.updateButtonBuyCustomers(customerCountData);
			this.updateCustomersProgressBar(customerCountData);
		}
	}

	private onNewCustomers(): void {
		this.customersProgressView.setCustomerCount(this.model.getCustomerCount());

		const customerCountData = this.model.getMaxCustomersCountData(
			this.buttonMaxCustomers.getCurrentValue(),
			this.softMoneyModel.getValue(),
		);
		this.updateButtonBuyCustomers(customerCountData);
		this.updateCustomersProgressBar(customerCountData);
	}

	private onBusinessAutomated(): void {
		this.unsubscribeFromEventProgressCompleteIfAny();

		this.incomeView.setAutomated();

		if (this.incomeMoneyView.visible) {
			this.incomeMoneyView.hide();
		}
	}

	private onIncomeProgressCompleteNotAutomated(): void {
		SoundController.getInstance().playIncomeAppear();

		if (this.businessZoomedIn) {
			this.incomeMoneyView.showZoomed();
		} else {
			this.incomeMoneyView.show();
		}

		this.emit(BusinessAcquiredUIView.EVENT_INCOME_MONEY_APPEAR);
	}

	private onIncomeValueChanged(): void {
		this.updateTextIncome();
	}

	private updateTextIncome(animateChange: boolean = true): void {
		const income = this.model.getBusinessIncome().toString();
		const incomePerSec = this.model.getBusinessIncomePerSec().toString();
		this.incomeView.setTextIncome(income, incomePerSec, animateChange);
	}

	private onIncomeTimeIntervalChanged(): void {
		this.incomeView.setIncomeTimeInterval(this.model.getTimeIncomeInterval());

		this.updateTextIncome();
	}

	private startBusinessClickAnimation(position: PIXI.Point): void {
		const clickAnimation = new BusinessClickAnimation();
		clickAnimation.position.set(position.x, position.y);
		if (this.viewport.isZoomed()) {
			clickAnimation.scale.set(0.7);
		}
		this.addChild(clickAnimation);
		this.incomeView.glowBarAnimation();
	}

	private onBusinessIconClick(): void {
		SoundController.getInstance().playButtonClick();
		this.emit(BusinessAcquiredUIView.EVENT_ZOOM_IN_REQUESTED);
	}

	private onNewCustomersMultiplier(): void {
		const newMultiplier = this.model.getCurrentCustomerMultiplier();
		this.playNewMultiplierAnimation(newMultiplier);
	}

	private onBusinessZoomOutClick(): void {
		this.emit(BusinessAcquiredUIView.EVENT_ZOOM_OUT_REQUESTED);
	}

	private generateClick(): void {
		const tapPoint = new PIXI.Point(
			NumberUtils.getRandomInRange(this.localBounds.left, this.localBounds.right),
			NumberUtils.getRandomInRange(this.localBounds.top, this.localBounds.bottom),
		);
		this.onBackgroundClick(tapPoint);
	}

	private startManageUIAppearingAnimation(): void {
		this.setManageUIPosition();
		this.manageUI.visible = true;
		this.manageUI.show();
	}

	private startManageUIHidingAnimation(): void {
		new TWEEN.Tween(this.manageUI, this.tweenGroup)
			.to({ alpha: 0 }, 100)
			.onComplete(() => {
				this.manageUI.visible = false;
				this.manageUI.hide();
			})
			.start();
	}

	private onTimeImproveSkillActivated(): void {
		const pointAlphaEmitter = new Emitter(
			this.incomeView,
			[this.fxAtlas['skill_bar_fx1']],
			SkillParticleConfig.getPointAlpha2(),
		);
		pointAlphaEmitter.autoUpdate = true;
		this.timeImproveEmitters.push(pointAlphaEmitter);

		const barFxEmitter = new Emitter(
			this.incomeView,
			[this.fxAtlas['skill_bar_fx2']],
			SkillParticleConfig.getBarFX(),
		);
		barFxEmitter.autoUpdate = true;
		this.timeImproveEmitters.push(barFxEmitter);
	}

	private onProfitImproveSkillActivated(): void {
		this.incomeView.startSkillAnimation();
	}

	private onProfitImproveSkillDeactivated(): void {
		this.incomeView.stopSkillAnimation();
	}

	private onTimeImproveSkillDeactivated(): void {
		this.timeImproveEmitters.forEach((emitter) => {
			emitter.destroy();
		});
	}

	private unsubscribeFromEventProgressCompleteIfAny(): void {
		if (this.model.listeners(BusinessModel.EVENT_INCOME_PROGRESS_COMPLETE).includes(this.onIncomeProgressCompleteNotAutomated)) {
			this.model.off(BusinessModel.EVENT_INCOME_PROGRESS_COMPLETE, this.onIncomeProgressCompleteNotAutomated, this);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.buttonMaxCustomers.off(ButtonMaxCustomersView.EVENT_CLICK, this.onMaxCustomersButtonClick, this);
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.onSoftMoneyValueChanged, this, false);

		this.ticker.remove(this.updateIncomeProgressBar, this);

		this.characterSkillPairs.forEach(pair => pair.character.off(CharacterModel.EVENT_ACTIVATED, this.onSomeRelatedCharacterActivated, this, true));

		this.newCustomerMultiplierModel.off(NewCustomersMutiplierModel.EVENT_REWARD_ADDED, this.onNewCustomerMultplierReward, this);

		this.model.off(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.onIncomeValueChanged, this);
		this.model.off(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.onIncomeTimeIntervalChanged, this);
		this.model.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this);
		this.model.off(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.onNewCustomersMultiplier, this);
		this.model.off(BusinessModel.EVENT_AUTOMATED, this.onBusinessAutomated, this);
		this.unsubscribeFromEventProgressCompleteIfAny();

		this.viewportUI.removeChildByType(this.incomeMoneyView, ViewportUIChildType.INCOME_MONEY);
		this.viewportUI.removeChildByType(this.manageUI, ViewportUIChildType.BUSINESS_MANAGE_UI);

		this.timeImproveEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		this.bonusActivationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.upgrades.forEach((upgrade: UpgradeModel) => {
			upgrade.off(UpgradeModel.EVENT_ACTIVATED, this.playBonusAnimation, this, true);
		});
		this.characterSkillPairs.forEach((pair) => {
			pair.character.off(CharacterModel.EVENT_ACTIVATED, this.playBonusAnimation, this, true);
		});

		this.cheatModel.off(CheatModel.EVENT_UPDATE_GENERATE_BUSINESS_CLICK, this.onCheatGenerateBusinessClickUpdate, this);

		if (this.businessClickHandle !== null || this.businessClickHandle !== undefined) {
			window.clearTimeout(this.businessClickHandle);
		}

		this.skillModels.forEach((skillModel) => {
			// eslint-disable-next-line default-case
			switch (skillModel.getType()) {
				case SkillTypes.TIME_IMPROVE: {
					skillModel.off(SkillModel.EVENT_SKILL_ACTIVATED, this.onTimeImproveSkillActivated, this);
					skillModel.off(SkillModel.EVENT_SKILL_DEACTIVATED, this.onTimeImproveSkillDeactivated, this);
					break;
				}
				case SkillTypes.PROFIT_IMPROVE: {
					skillModel.off(SkillModel.EVENT_SKILL_ACTIVATED, this.onProfitImproveSkillActivated, this);
					skillModel.off(SkillModel.EVENT_SKILL_DEACTIVATED, this.onProfitImproveSkillDeactivated, this);
					break;
				}
				case SkillTypes.AUTO_TAP: {
					skillModel.off(SkillAutoTapModel.EVENT_GENERATE_TAP, this.generateClick, this);
					break;
				}
			}
		});

		super.destroy(options);
	}
}
