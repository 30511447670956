import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { LeagueBotConfig } from '@configs/LeagueBotConfig';

export class LeagueBotModel {
	private readonly key: string;

	private readonly coefA: number;
	private readonly coefC: number;
	private readonly coefD: number;

	private readonly coefASoftMoney: SoftMoneyNumber;
	private readonly coefCSoftMoney: SoftMoneyNumber
	private readonly coefDSoftMoney: SoftMoneyNumber;

	private readonly minScore: SoftMoneyNumber;
	private readonly name: string;

	constructor(
		config: LeagueBotConfig,
		name: string,
	) {
		this.key = config.getKey();
		this.coefA = config.getCoefA();
		this.coefC = config.getCoefC();
		this.coefD = config.getCoefD();
		this.minScore = config.getMinScore();
		this.name = name;

		this.coefASoftMoney = SoftMoneyNumber.createFromNumber(this.coefA);
		this.coefCSoftMoney = SoftMoneyNumber.createFromNumber(this.coefC);
		this.coefDSoftMoney = SoftMoneyNumber.createFromNumber(this.coefD);
	}

	public getKey(): string {
		return this.key;
	}

	public getName(): string {
		return this.name;
	}

	public getScore(time: number): SoftMoneyNumber {
		const timeRounded = Math.round(time);

		const a = this.coefCSoftMoney.multiply(this.coefA ** timeRounded);
		const b = this.coefDSoftMoney.multiply(timeRounded);
		const score = a.add(b);

		return score.greaterThan(this.minScore)
			? score
			: this.minScore;
	}
}
