import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { NumberUtils } from '@src/utils/NumberUtils';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { TextUtils } from '@src/utils/TextUtils';

export class BankBoostConfirmWindowView extends WindowBaseView {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_ANIMATION_COMPLETED = Symbol();

	private readonly bg: PIXI.Graphics;
	private readonly content: PIXI.Container;
	private readonly titleContent: PIXI.Container;
	private readonly iconContent: PIXI.Container;

	private buttonBuy: ButtonBaseView;
	private buttonActivate: ButtonBaseView;

	private animationIn: TWEEN.Tween;
	private animationOut: TWEEN.Tween;

	private hoursText: SizeableBitmapText;

	private static createButtonSkip(): ButtonBaseView {
		const localizationStorage = LocalizationStorage.getInstance();

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 292;
		buttonBg.height = 78;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);

		const stringActivate = localizationStorage.getLocalizedString('#bank_boost_activate');
		const labelActivate = new PIXI.extras.BitmapText(stringActivate, {
			font: '26px wendyOneShadowBold',
		});
		labelActivate.anchor = 0.5;
		labelActivate.y = -2;

		button.addChild(
			labelActivate,
		);

		return button;
	}

	private static createButtonBuy(costResource: ButtonValueTypes, costValue: string): ButtonBaseView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 292;
		buttonBg.height = 78;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonWithCostValueView({
			buttonBg,
			fontStyle: { font: '32px wendyOneShadowBold' },
			type: costResource,
		});
		button.setCost(costValue);

		return button;
	}

	constructor(
		seconds: number,
		power: number,
		iconKey: string,
	) {
		super(0, false);

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const localizationStorage = LocalizationStorage.getInstance();

		const durationLocaleKey = '#bank_boost_duration_minutes';

		const convertedDurationTime = NumberUtils
			.formatTimeSecToMin(seconds)
			.toString();

		let stringDuration = localizationStorage.getLocalizedString(durationLocaleKey);
		stringDuration = stringDuration.replace('{{value}}', convertedDurationTime);

		this.content = new PIXI.Container();
		this.titleContent = new PIXI.Container();
		this.iconContent = new PIXI.Container();

		this.bg = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
			.endFill();
		this.bg.interactive = true;

		const titleWindow = new SizeableBitmapText(
			localizationStorage.getLocalizedString('#confirm_purchase_title'),
			700,
			{ font: '44px wendyOne', align: 'center', tint: 0xf9de7e },
		);
		titleWindow.pivot.set(titleWindow.width / 2, titleWindow.height / 2);
		titleWindow.position.set(
			GameConstants.GAME_WIDTH / 2,
			152,
		);
		titleWindow.scale.x = 1.1;

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.position.set(
			GameConstants.GAME_WIDTH / 2,
			145,
		);
		episodeNamePanelFade.width = 873;
		episodeNamePanelFade.height = 105;
		episodeNamePanelFade.tint = 0x000000;

		const glowLine = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine.position.set(GameConstants.GAME_WIDTH / 2, 103);
		const glowLine2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLine2.position.set(GameConstants.GAME_WIDTH / 2, 197);

		const glow = new PIXI.Sprite(fxAtlas['boost_activated_glow']);
		glow.setTransform(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
			2,
			2,
		);

		const moneyIcon = new PIXI.Sprite(collectionAtlas[iconKey]);
		moneyIcon.setTransform(
			GameConstants.GAME_WIDTH / 2 - 5,
			525,
			0.66,
			0.66,
		);

		const descr = new SizeableBitmapText(
			`x${power}`,
			300,
			{ font: '84px wendyOneGold' },
		);
		descr.pivot.set(descr.width / 2, descr.height / 2);
		descr.position.set(
			GameConstants.GAME_WIDTH / 2 + 80,
			642,
		);

		const girl = new PIXI.Sprite(AssetsStorage.getResource('character30_offer').texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.setTransform(1311, 141, 0.85, 0.85);

		const girlsLegs = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['legs_decor']);
		girlsLegs.setTransform(1580, 1032, 1.7, 1.7);

		const closeButton = new CloseButtonView();
		closeButton.position.set(1860, 40);
		closeButton.alpha = 0.7;
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.hoursText = new SizeableBitmapText(
			'',
			700,
			{ font: '45px wendyOneShadowBold' },
		);
		this.hoursText.text = `${TextUtils.TAG_TIMER}+${stringDuration}`;
		this.hoursText.pivot.set(
			this.hoursText.width / 2,
			this.hoursText.height / 2,
		);
		this.hoursText.position.set(
			GameConstants.GAME_WIDTH / 2,
			690,
		);

		this.content.pivot.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.position.set(
			GameConstants.GAME_WIDTH / 2,
			GameConstants.GAME_HEIGHT / 2,
		);
		this.content.scale.set(0);
		this.hoursText.scale.set(0);
		this.bg.alpha = 0;

		this.titleContent.addChild(
			episodeNamePanelFade as PIXI.DisplayObject,
			titleWindow,
			glowLine,
			glowLine2,
		);

		this.iconContent.addChild(
			glow as PIXI.DisplayObject,
			moneyIcon,
			descr,
		);

		this.content.addChild(
			girl as PIXI.DisplayObject,
			this.iconContent,
			girlsLegs,
			closeButton,
		);

		this.addChild(
			this.bg,
			this.content,
			this.hoursText,
		);
	}

	private onButtonBuyClick(): void {
		this.emit(BankBoostConfirmWindowView.EVENT_BUTTON_BUY_CLICK);
		this.onClose();
	}

	private onButtonActivateClick(): void {
		this.emit(BankBoostConfirmWindowView.EVENT_BUTTON_ACTIVATE_CLICK);
		this.onClose();
	}

	public initActivateStage(): void {
		this.buttonActivate = BankBoostConfirmWindowView.createButtonSkip();
		this.buttonActivate.on(ButtonBaseView.EVENT_CLICK, this.onButtonActivateClick, this);
		this.buttonActivate.position.set(
			GameConstants.GAME_WIDTH / 2,
			982,
		);

		this.content.removeChild(this.buttonBuy);
		this.content.removeChild(this.titleContent);
		this.content.addChild(this.buttonActivate);
		this.iconContent.y = -65;
		this.hoursText.y = 690;

		this.animationIn = new TWEEN.Tween(this.bg)
			.to({ alpha: 0.92 }, 300)
			.easing(TWEEN.Easing.Quadratic.In);
		const contentScaleIn = new TWEEN.Tween(this.content.scale)
			.to({ x: 1.1, y: 1.1 }, 150)
			.easing(TWEEN.Easing.Cubic.In);
		const contentScaleInBounce = new TWEEN.Tween(this.content.scale)
			.to({ x: 1, y: 1 }, 50)
			.easing(TWEEN.Easing.Linear.None);
		const hoursTextScaleIn = new TWEEN.Tween(this.hoursText.scale)
			.to({ x: 1, y: 1 }, 200)
			.easing(TWEEN.Easing.Cubic.In);

		this.animationIn.chain(contentScaleIn);
		contentScaleIn.chain(contentScaleInBounce);
		contentScaleInBounce.chain(hoursTextScaleIn);
	}

	public initBuyStage(costResource: ButtonValueTypes, costValue: number): void {
		this.buttonBuy = BankBoostConfirmWindowView.createButtonBuy(costResource, String(costValue));
		this.buttonBuy.on(ButtonBaseView.EVENT_CLICK, this.onButtonBuyClick, this);
		this.buttonBuy.position.set(
			GameConstants.GAME_WIDTH / 2,
			982,
		);

		this.content.removeChild(this.buttonActivate);
		this.content.addChild(this.buttonBuy, this.titleContent);
		this.iconContent.y = 0;

		this.animationIn = new TWEEN.Tween(this.bg)
			.to({ alpha: 0.92 }, 300)
			.easing(TWEEN.Easing.Quadratic.In);
		const contentScaleIn = new TWEEN.Tween(this.content.scale)
			.to({ x: 1.1, y: 1.1 }, 150)
			.easing(TWEEN.Easing.Cubic.In);
		const contentScaleInBounce = new TWEEN.Tween(this.content.scale)
			.to({ x: 1, y: 1 }, 50)
			.easing(TWEEN.Easing.Linear.None);

		this.animationIn.chain(contentScaleIn);
		contentScaleIn.chain(contentScaleInBounce);
	}

	public onShown(): void {
		super.onShown();

		this.animationIn
			.start()
			.onComplete(() => {
				this.emit(BankBoostConfirmWindowView.EVENT_SHOW_ANIMATION_COMPLETED);
			});
	}

	public getActiveButton(): PIXI.Container {
		return this.buttonActivate || this.buttonBuy;
	}

	protected onClose(): void {
		this.animationIn = new TWEEN.Tween(this)
			.to({ alpha: 0 }, 100)
			.easing(TWEEN.Easing.Quadratic.In)
			.onComplete(() => {
				super.onClose();
				this.destroy();
			})
			.start();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.animationIn) {
			this.animationIn.stop();
		}
		if (this.animationOut) {
			this.animationOut.stop();
		}
		super.destroy(options);
	}
}
