import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110001View } from '@views/characters/events/event11/EventCharacter110001View';
import { EventCharacter110002View } from '@views/characters/events/event11/EventCharacter110002View';
import { EventCharacter110003View } from '@views/characters/events/event11/EventCharacter110003View';

export class EventBusiness110001View extends BusinessBaseView {
	constructor() {
		const eventCharacter110001View = new EventCharacter110001View();
		const eventCharacter110002View = new EventCharacter110002View();
		const eventCharacter110003View = new EventCharacter110003View();

		super('business110001', [eventCharacter110001View, eventCharacter110002View, eventCharacter110003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter110001View,
			eventCharacter110002View,
			eventCharacter110003View as PIXI.DisplayObject,
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
