import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter60009View extends CharacterMainBaseView {
	constructor() {
		super('character60009');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-1, 38);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-30, -35),
			bgPosition: new PIXI.Point(70, -39),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
