import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { CharacterQuickPhraseListener } from '@interfaces/CharacterQuickPhraseListener';
import { CharacterModel } from '../CharacterModel';
import { SkillModel } from '../skills/SkillModel';
import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { QuickPhraseActivateSkillGenerator } from './QuickPhraseActivateSkillGenerator';
import { QuickPhraseActivateCharacterGenerator } from './QuickPhraseActivateCharacterGenerator';
import { QuickPhraseActivateAnotherCharacterGenerator } from './QuickPhraseActivateAnotherCharacterGenerator';
import { QuickPhraseNewCustomersMultiplierGenerator } from './QuickPhraseNewCustomersMultiplierGenerator';
import { QuickPhraseIdleGenerator } from './QuickPhraseIdleGenerator';
import { QuickPhraseSpeedUpGenerator } from './QuickPhraseSpeedUpGenerator';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { BusinessModel } from '@models/BusinessModel';

export type PhraseCharacterKey = string;

export class CharacterQuickPhraseEmitter {
	private listeners: Map<PhraseCharacterKey, CharacterQuickPhraseListener>;
	private generators: QuickPhaseGeneratorBase[];
	private generatorSpeedUp: QuickPhraseSpeedUpGenerator;
	private generatorZoom: QuickPhaseGeneratorBase;

	private enabled: boolean;

	constructor(
		config: CharacterQuickPhraseConstants,
		skills: SkillModel[],
		characters: CharacterModel[],
		businessModels: Map<string, BusinessModel>,
	) {
		this.enabled = true;
		this.listeners = new Map();
		this.generators = [
			new QuickPhraseActivateSkillGenerator(config, skills),
			new QuickPhraseActivateCharacterGenerator(config, characters),
			new QuickPhraseActivateAnotherCharacterGenerator(config, characters, businessModels),
			new QuickPhraseNewCustomersMultiplierGenerator(config, businessModels),
			(this.generatorZoom = new QuickPhaseGeneratorBase(CharacterQuickPhraseTypes.ZOOM, config)),
			new QuickPhraseIdleGenerator(config),
			(this.generatorSpeedUp = new QuickPhraseSpeedUpGenerator(CharacterQuickPhraseTypes.SPEEDUP, config)),
		];

		this.generators.forEach((generator) => {
			generator.on(QuickPhaseGeneratorBase.EVENT_PHRASE_GENERATED, this.onSomePhraseGenerated, this);
		});
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;
	}

	public registerPhrases(key: PhraseCharacterKey): void {
		this.generators.forEach(generator => generator.registerPhrases(key));
	}

	public unregisterPhrases(key: PhraseCharacterKey): void {
		this.generators.forEach(generator => generator.unregisterPhrases(key));
	}

	public addListener(key: PhraseCharacterKey, listener: CharacterQuickPhraseListener): void {
		this.listeners.set(key, listener);
	}

	public removeListener(key: PhraseCharacterKey): void {
		this.listeners.delete(key);
	}

	public onTryEmitZoomPhrase(key: PhraseCharacterKey): void {
		if (this.enabled) {
			this.generatorZoom.tryGeneratePhrase(key);
		}
	}

	public onTryEmitSpeedUpPhrase(key: PhraseCharacterKey): void {
		if (this.enabled) {
			this.generatorSpeedUp.tryGeneratePhrase(key);
		}
	}

	private onSomePhraseGenerated(key: PhraseCharacterKey, phrase: string, showTime: number): void {
		if (this.enabled && this.listeners.has(key)) {
			this.listeners.get(key).onShowQuickPhrase(phrase, showTime);
		}
	}
}
