import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character10View } from '@views/characters/main/Character10View';
import { Character11View } from '@views/characters/main/Character11View';
import { Character12View } from '@views/characters/main/Character12View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business4View extends BusinessBaseView {
	constructor() {
		const character10 = new Character10View();
		const character11 = new Character11View();
		const character12 = new Character12View();

		super('business4', [character10, character11, character12]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character10 as PIXI.DisplayObject,
			character11,
			character12,
		);
	}
}
