import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80001View } from '@views/characters/events/event8/EventCharacter80001View';
import { EventCharacter80002View } from '@views/characters/events/event8/EventCharacter80002View';
import { EventCharacter80003View } from '@views/characters/events/event8/EventCharacter80003View';

export class EventBusiness80001View extends BusinessBaseView {
	constructor() {
		const eventCharacter80001View = new EventCharacter80001View();
		const eventCharacter80002View = new EventCharacter80002View();
		const eventCharacter80003View = new EventCharacter80003View();

		super('business80001', [eventCharacter80001View, eventCharacter80002View, eventCharacter80003View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b3_decor6']).setTransform(-14, -61, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(-148, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor4']).setTransform(115, -86, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor3']).setTransform(-241, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor2']).setTransform(233, -69, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b3_decor5']).setTransform(291, 14, 0.5, 0.5) as PIXI.Sprite,

		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b3_decor1']).setTransform(11, 82, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter80001View as PIXI.DisplayObject,
			eventCharacter80002View,
			eventCharacter80003View,
		);
	}
}
