import { AbstractReward } from '@interfaces/AbstractReward';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class RewardPrestigeMoney extends AbstractReward {
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly quantity: number;

	constructor(prestigeMoneyModel: PrestigeMoneyModel, quantity: number) {
		super(RewardTypes.RESOURCE, RewardResourceIdTypes.PRESTIGE_MONEY);

		this.prestigeMoneyModel = prestigeMoneyModel;
		this.quantity = quantity;
	}

	public collect(): void {
		this.prestigeMoneyModel.add(this.quantity);
	}

	public getQuantity(): number {
		return this.quantity;
	}

	public getQuantityString(): string {
		return SoftMoneyNumber.createFromNumber(this.quantity).toString();
	}
}
