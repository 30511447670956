import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { RewardVideo } from '@models/rewards/RewardVideo';

export class QuestLootboxModel extends LootboxModel {
	private rewardVideo: RewardVideo;

	public addRewardVideo(reward: RewardVideo): void {
		this.rewardVideo = reward;
		this.rewards.push(reward);
	}

	public removeRewardVideo(): void {
		const index = this.rewards.indexOf(this.rewardVideo);
		this.rewards.splice(index, 1);

		this.rewardVideo = null;
	}

	public updateRewardVideo(newReward: RewardVideo): void {
		const index = this.rewards.indexOf(this.rewardVideo);
		this.rewards[index] = newReward;
		this.rewardVideo = newReward;
	}

	public hasRewardVideo(): boolean {
		return Boolean(this.rewardVideo);
	}

	public getRewardVideo(): RewardVideo {
		return this.rewardVideo;
	}
}
