import { AbstractModelInstance } from '@main/modelInstance/AbstractModelInstance';
import { GameProfileModel } from '@models/GameProfileModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { TotemModel } from '@models/TotemModel';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { SkillModel } from '@models/skills/SkillModel';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { LocalizationSupportConfig } from '@configs/LocalizationSupportConfig';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatModel } from '@models/CheatModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SettingsWindowView } from '@views/windows/settings/SettingsWindowView';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { CollectionWindowOpenConfig, CollectionWindowView } from '@views/windows/collection/CollectionWindowView';
import { BankWindowOpenConfig, BankWindowView } from '@views/windows/bank/BankWindowView';
import { BankOfferWindowOriginType } from '@src/types/BankTypes';
import { BankOfferWindowView } from '@views/windows/bank/offerWindow/BankOfferWindowView';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { TimedQuestWindowView } from '@views/windows/quests/TimedQuestWindowView';
import { QuestWindowView } from '@views/windows/quests/QuestWindowView';
import { TutorialStepView } from '@views/windows/TutorialStepWindowView';
import { DialogModel } from '@models/DialogModel';
import { DialogWindowView } from '@views/windows/dialog/DialogWindowView';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { SummonModel } from '@models/SummonModel';
import { RewardResultWindowRewardStringType, RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { RewardResultLootboxWindowView } from '@views/windows/rewardResultWindow/RewardResultLootboxWindowView';
import { RewardResultWindowView } from '@views/windows/rewardResultWindow/RewardResultWindowView';
import { UpgradePromoteWindowView } from '@views/windows/collection/upgrades/UpgradePromoteWindowView';
import { TotemPromoteWindowView } from '@views/windows/collection/totems/TotemPromoteWindowView';
import { CharacterProfileWindow } from '@views/windows/collection/characters/CharacterProfileWindow';
import { SupportWindowView } from '@views/windows/support/SupportWindowView';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { LoginWindowView } from '@views/windows/account/LoginWindowView';
import { AccountNotCreatedWarningWindowView } from '@views/windows/account/AccountNotCreatedWarningWindowView';
import { PasswordRecoveryWindowView } from '@views/windows/account/PasswordRecoveryWindowView';
import { LevelChallengeWindowView } from '@views/windows/levelChallenge/LevelChallengeWindowView';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { BackToGameWindowView } from '@views/windows/backToGame/BackToGameWindowView';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';
import { PrepartyWindowView } from '@views/windows/farewellParty/preparty/PrepartyWindowView';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { BankPrepareTransactionWindow } from '@views/windows/bank/BankPrepareTransactionWindow';
import { BaseSettingsWindowView } from '@views/windows/settings/BaseSettingsWindowView';
import { NutakuSettingsWindowView } from '@views/windows/settings/NutakuSettingsWindowView';
import { BankTabElementViewFactory } from '@views/windows/bank/tabElementViews/BankTabElementViewFactory';
import { BankBoostConfirmWindowView } from '@views/windows/bank/animations/BankBoostApplyConfirmView';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { EnterEmailWindowView } from '@views/windows/account/EnterEmailWindowView';
import { QuestRewardResultWindow } from '@views/windows/rewardResultWindow/QuestRewardResultWindow';

export class WindowViewBaseFactory {
	protected readonly cardMiniViewFactory: CardMiniViewFactory;
	protected gameUI: GameUIBaseView;

	constructor(
		protected readonly serverTimeModel: ServerTimeModel,
		protected readonly modelInstance: AbstractModelInstance,
		protected readonly gameProfileModel: GameProfileModel,
		protected readonly dialogConfigs: Map<string, DialogConfig>,
		protected readonly constantsConfig: ConstantsConfig,
		protected readonly localizationSupportConfig: LocalizationSupportConfig,
		protected readonly networkRequestSender: NetworkRequestSender,
		protected readonly redeemCode: string,
		protected readonly cheatModel: CheatModel,
		protected readonly groupUpgradesByBusinesses: boolean,
		protected readonly collectionWithMoneyPanels: boolean,
		private readonly isNutaku: boolean,
		private readonly version: string,
	) {
		this.cardMiniViewFactory = new CardMiniViewFactory(
			modelInstance.getCharacterModels(),
			modelInstance.getUpgradeModels(),
			modelInstance.getTotemModels(),
			modelInstance.getBoostModels(),
			modelInstance.getTimeskipModels(),
		);
	}

	public setGameUIView(view: GameUIBaseView): void {
		this.gameUI = view;
	}

	public createSettingsWindow(): BaseSettingsWindowView {
		let view: BaseSettingsWindowView;
		if (this.isNutaku) {
			view = new NutakuSettingsWindowView(
				this.redeemCode,
				this.localizationSupportConfig.getSupportLanuageMap(),
				this.gameProfileModel,
				this.constantsConfig.getThirdPartyLinks(),
				this.version,
			);
		} else {
			view = new SettingsWindowView(
				this.redeemCode,
				this.localizationSupportConfig.getSupportLanuageMap(),
				this.gameProfileModel,
				this.constantsConfig.getThirdPartyLinks(),
				this.constantsConfig.getDefaultPaymentMethod(),
				this.version,
			);
		}
		return view;
	}

	public createCheatWindow(): CheatWindowView {
		return new CheatWindowView(
			this.cheatModel,
		);
	}

	public createCollectionWindow(
		config: CollectionWindowOpenConfig,
	): CollectionWindowView {
		return new CollectionWindowView(
			config,
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getSkillModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBoostModels(),
			this.modelInstance.getTimeskipModels(),
			this.modelInstance.getGalleryVideoModels(),
			this.modelInstance.getEpicQuestCollectionsModels(),
			this.modelInstance.getSummonModelsPool(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			this.groupUpgradesByBusinesses,
			this.collectionWithMoneyPanels,
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createBankPrepareTransactionWindow(): BankPrepareTransactionWindow {
		return new BankPrepareTransactionWindow();
	}

	public createBankWindow(
		config?: BankWindowOpenConfig,
	): BankWindowView {
		const bankTabElementViewFactory = new BankTabElementViewFactory(
			this.modelInstance.getBankSavesModel(),
			this.modelInstance.getCharacterModels(),
			this.modelInstance.getUpgradeModels(),
			this.modelInstance.getTotemModels(),
			this.modelInstance.getBoostModels(),
			this.modelInstance.getTimeskipModels(),
			this.modelInstance.getSkillModels(),
			this.modelInstance.getHardMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getBankModel().getBankPriceItemsConfig(),
			this.cardMiniViewFactory,
		);
		const view = new BankWindowView(
			this.serverTimeModel,
			this.modelInstance.getBankModel(),
			this.modelInstance.getBankSavesModel(),
			this.modelInstance.getHardMoneyModel(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getBoostModels(),
			this.modelInstance.getTimeskipModels(),
			bankTabElementViewFactory,
			this.constantsConfig.getShowQuestTargetDuration(),
			config,
		);
		return view;
	}

	public createBankOfferWindow(
		bankOfferModelKey: string,
		bankOfferWindowOrigin: BankOfferWindowOriginType,
	): BankOfferWindowView {
		const view = new BankOfferWindowView(
			this.modelInstance.getBankModel(),
			this.modelInstance.getBankSavesModel(),
			bankOfferModelKey,
			this.cardMiniViewFactory,
			bankOfferWindowOrigin,
		);
		return view;
	}

	public createTimedQuestWindow(quest: AbstractQuest): TimedQuestWindowView {
		const view = new TimedQuestWindowView(
			quest,
			this.cheatModel.getQuestWindowMode(),
		);
		return view;
	}

	public createQuestWindow(quest: AbstractQuest): QuestWindowView {
		return new QuestWindowView(
			quest,
			this.cheatModel.getQuestWindowMode(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createTutorialStepWindow(
		model: TutorialStepBaseModel,
		target?: PIXI.Container,
	): TutorialStepView {
		return new TutorialStepView(model, target);
	}

	// eslint-disable-next-line class-methods-use-this
	public createDialogWindow(model: DialogModel): DialogWindowView {
		const view = new DialogWindowView(model);
		return view;
	}

	public createPrepartyWindow(
		farewellPartyModelKey: string,
		level?: number,
	): PrepartyWindowView {
		const model = this.modelInstance.getFarewellPartyModels()
			.get(farewellPartyModelKey);
		const view = new PrepartyWindowView(
			this.modelInstance.getTotemModels(),
			model,
			level,
		);

		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	public createFarewellPartyWindow(
		model: FarewellPartyModel,
		farewellPartyEverStarted: boolean,
	): FarewellPartyWindowView {
		const view = new FarewellPartyWindowView(
			model,
			this.constantsConfig.getShowQuestTargetDuration(),
			farewellPartyEverStarted,
		);
		return view;
	}

	public createSummonResultWindow(
		model: SummonModel,
		count: number,
		rewardString?: RewardResultWindowRewardStringType,
		hideMoneyPanels?: boolean,
	): SummonResultWindowView {
		const view = new SummonResultWindowView(
			model,
			count,
			this.modelInstance.getSkillModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			rewardString,
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
			hideMoneyPanels,
		);
		return view;
	}

	public createRewardResultLootboxWindow(
		hideMoneyPanels?: boolean,
		rewardString?: RewardResultWindowRewardStringType,
		bgType?: RewardResultWindowBgType,
	): RewardResultLootboxWindowView {
		const view = new RewardResultLootboxWindowView(
			this.modelInstance.getSkillModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			bgType,
			rewardString,
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
			hideMoneyPanels,
		);
		return view;
	}

	public createQuestRewardResultWindow(): QuestRewardResultWindow {
		const view = new QuestRewardResultWindow(
			this.modelInstance.getSkillModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			RewardResultWindowBgType.FADE,
			RewardResultWindowRewardStringType.REWARD_SUMMARY,
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
			false,
		);
		return view;
	}

	public createRewardResultWindow(
		hideMoneyPanels?: boolean,
		rewardString?: RewardResultWindowRewardStringType,
		bgType?: RewardResultWindowBgType,
	): RewardResultWindowView {
		const view = new RewardResultWindowView(
			this.modelInstance.getSkillModels(),
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			bgType,
			rewardString,
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
			hideMoneyPanels,
		);
		return view;
	}

	public createUpgradePromoteWindow(
		model: UpgradeModel,
	): UpgradePromoteWindowView {
		const view = new UpgradePromoteWindowView(
			model,
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
		);
		return view;
	}

	public createTotemPromoteWindow(
		model: TotemModel,
	): TotemPromoteWindowView {
		const view = new TotemPromoteWindowView(
			model,
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
		);
		return view;
	}

	public createCharacterProfileWindow(
		model: CharacterModel,
		skillModel?: SkillModel,
	): CharacterProfileWindow {
		const view = new CharacterProfileWindow(
			model,
			this.modelInstance.getPrestigeMoneyModel(),
			this.modelInstance.getHardMoneyModel(),
			model.getRelatedBusinessKey(),
			!this.gameUI.isButtonMoneyPanelsPlusVisible(),
			skillModel,
		);
		return view;
	}

	public createSupportWindow(): SupportWindowView {
		const view = new SupportWindowView(
			this.constantsConfig.getThirdPartyLinks(),
		);
		return view;
	}

	public createRegistrationWindow(): RegistrationWindowView {
		const view = new RegistrationWindowView(
			this.gameProfileModel,
		);
		return view;
	}

	public createLoginWindow(): LoginWindowView {
		const view = new LoginWindowView(
			this.gameProfileModel,
		);
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	public createAccountNotCreatedWarningWindow(): AccountNotCreatedWarningWindowView {
		return new AccountNotCreatedWarningWindowView();
	}

	public createPasswordRecoveryWindow(): PasswordRecoveryWindowView {
		const view = new PasswordRecoveryWindowView(this.gameProfileModel);
		return view;
	}

	public createLevelChallengeWindow(): LevelChallengeWindowView {
		const view = new LevelChallengeWindowView(
			this.modelInstance.getLevelChallengeModel(),
			this.cardMiniViewFactory,
		);
		return view;
	}

	// eslint-disable-next-line class-methods-use-this
	public createBackToGameWindow(): BackToGameWindowView {
		const backToGameRewardModel = this.modelInstance.getBackToGameRewardModel();
		const maxOfflineTime = this.constantsConfig.getMaxOfflineTime();
		const offlineRewardMultiplier: number = this.constantsConfig.getOfflineRewardMultiplier();
		const offlineRewardCost: number = this.constantsConfig.getOfflineRewardCost();
		const offlineSeconds = backToGameRewardModel.getOfflineSeconds();

		const backToGameWindow = new BackToGameWindowView(
			offlineSeconds,
			backToGameRewardModel.getRewardSoftMoney(),
			maxOfflineTime,
			offlineRewardCost,
			offlineRewardMultiplier,
			this.modelInstance.getHardMoneyModel(),
		);
		return backToGameWindow;
	}

	public createBankBoostConfirmWindowView(shopItemModel: BankGemShopItemModel): BankBoostConfirmWindowView {
		const boostModels = this.modelInstance.getBoostModels();
		const boostModel = boostModels.get(shopItemModel.getRewardDescription().reward_id);

		return new BankBoostConfirmWindowView(
			boostModel.getTime(),
			boostModel.getBonusValue(),
			boostModel.getIconKey(),
		);
	}

	// eslint-disable-next-line class-methods-use-this
	public createEnterEmailWindow(): EnterEmailWindowView {
		return new EnterEmailWindowView();
	}
}
