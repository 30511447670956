import { TimeskipConfig } from '@configs/TimeskipConfig';
import { TimeskipModel } from '@models/TimeskipModel';
import { TimeskipSaveData } from '@src/types/SaveTypes';

export class TimeskipsFactory {
	private readonly configs: Map<string, TimeskipConfig>;
	private readonly savesData?: Map<string, TimeskipSaveData>;
	constructor(
		configs: Map<string, TimeskipConfig>,
		savesData?: Map<string, TimeskipSaveData>,
	) {
		this.configs = configs;
		this.savesData = savesData;
	}

	public createModels(): Map<string, TimeskipModel> {
		const models: Map<string, TimeskipModel> = new Map();
		this.configs.forEach((config) => {
			const model = new TimeskipModel();
			model.setFromConfig(config);

			if (this.savesData && this.savesData.has(config.getKey())) {
				const saveData = this.savesData.get(config.getKey());
				model.setFromSaveData(saveData);
			}

			models.set(model.getKey(), model);
		});
		return models;
	}
}
