import {
	ShowEventProgressDialogWindowChainable,
	ShowLevelStartDialogWindowChainable,
	ShowTutorialStepEventStartWindowChainable,
	ShowBackToGameWindowChainable,
	ShowNotCompletedTransactionsRewardsWindowChainable,
	ShowTutorialStepGetQuestWindowChainable,
	ShowBankOfferWindowChainable,
	ShowCompensationRewardWindowChainable,
} from '@src/viewControllers/mainUI/ShowWindowChainables';
import { EventLevelGameUIView } from '@views/ui/main/EventLevelGameUIView';
import { EventGameUIViewSetter } from '@interfaces/ViewSetters';
import { DialogsFactory } from '@src/initializers/DialogsFactory';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { TutorialStepWindowViewTargetEventLevelDispatcher } from '../tutorialSteps/TutorialStepWindowViewEventLevelTargetDispatcher';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';
import { BankLoader } from '@src/loaders/bank/BankLoader';
import { BankOfferWindowsEmitter } from '@src/emitters/BankOfferWindowsEmitter';
import { BankOfferWindowOriginTypes } from '@src/types/BankTypes';
import { CheatModel } from '@models/CheatModel';
import { EventLevelWindowViewFactory } from '@src/initializers/windows/EventLevelWindowViewFactory';
import { GameUIBaseViewController } from '@src/viewControllers/mainUI/GameUIBaseViewController';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { RewardResultWindowRewardStringType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { BoostModel } from '@models/BoostModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { ClaimCompensationRewardAction } from '@models/network/actions/ClaimCompensationRewardAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ReturnFromEventLevelConfirmationWindowView } from '@views/windows/ReturnFromEventLevelConfirmationWindowView';
import { LootboxServerConfig } from '@configs/LootboxServerConfig';
import { EmittersController } from './EmittersController';

export class EventLevelGameUIViewController
	extends GameUIBaseViewController<EventLevelGameUIView, EventLevelWindowViewFactory> implements EventGameUIViewSetter {
	public static readonly EVENT_RETURN_TO_LEVEL: symbol = Symbol();

	private readonly dialogsFactory: DialogsFactory;
	private readonly eventLevelModel: EventLevelModel;
	private readonly backToGameRewardModel: BackToGameRewardModel;
	private readonly eventEverStarted: boolean;
	private readonly bankLoader: BankLoader;
	private readonly cheatModel: CheatModel;
	private readonly emittersController: EmittersController;

	private readonly bankOfferWindowsEmitter: BankOfferWindowsEmitter;
	private readonly tutorialStepsEmitter: TutorialStepsEmitter;
	private compensationLootboxModels: LootboxModel[];

	constructor(
		cheatModel: CheatModel,
		dialogsFactory: DialogsFactory,
		eventLevelModel: EventLevelModel,
		backToGameRewardModel: BackToGameRewardModel,
		tutorialStepsEmitter: TutorialStepsEmitter,
		bankOfferWindowsEmitter: BankOfferWindowsEmitter,
		tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetEventLevelDispatcher,
		emittersController: EmittersController,
		bankLoader: BankLoader,
		eventEverStarted: boolean,
		windowViewSystem: WindowViewSystem,
		windowFactory: EventLevelWindowViewFactory,
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		compensationLootboxConfigs: LootboxServerConfig[],
		lootboxFactory: LootboxFactory,
	) {
		super(
			windowViewSystem,
			windowFactory,
			boostModels,
			farewellBoostModel,
			tutorialStepsViewTargetDispatcher,
		);
		this.cheatModel = cheatModel;
		this.dialogsFactory = dialogsFactory;
		this.eventEverStarted = eventEverStarted;
		this.eventLevelModel = eventLevelModel;
		this.backToGameRewardModel = backToGameRewardModel;
		this.tutorialStepsEmitter = tutorialStepsEmitter;
		this.bankOfferWindowsEmitter = bankOfferWindowsEmitter;
		this.emittersController = emittersController;
		this.bankLoader = bankLoader;

		if (compensationLootboxConfigs.length > 0) {
			this.compensationLootboxModels = compensationLootboxConfigs
				.map((config) => lootboxFactory.createLootbox(config));
		} else {
			this.compensationLootboxModels = [];
		}

		this.eventLevelModel.once(EventLevelModel.EVENT_PROGRESS_COMPLETE, this.onGoalCompleted, this);
		this.eventLevelModel.on(EventLevelModel.EVENT_PROGRESS, this.onNewGoalReached, this);

		this.tutorialStepsEmitter.on(TutorialStepsEmitter.EVENT_BASE_STEP_UNLOCKED, this.onBaseTutorialStepEmitted, this);
		this.bankOfferWindowsEmitter.on(BankOfferWindowsEmitter.EVENT_BANK_OFFER_WINDOW, this.onBankOfferWindowEmitted, this);

		this.backToGameRewardModel.on(BackToGameRewardModel.EVENT_UPDATED, this.onBackToGameRewardsUpdated, this);
	}

	public setEventGameUIView(view: EventLevelGameUIView): void {
		super.setGameUIView(view);

		this.view.on(EventLevelGameUIView.EVENT_INIT, this.onInit, this);
		this.view.on(EventLevelGameUIView.EVENT_BUTTON_CUP_CLICK, this.onButtonCupClick, this);
		this.view.on(EventLevelGameUIView.EVENT_BUTTON_GOAL_REWARD_CLICK, this.onButtonGoalRewardClick, this);
		this.view.on(EventLevelGameUIView.EVENT_BUTTON_RETURN_CLICK, this.onButtonReturnToLevelClick, this);
	}

	private onButtonReturnToLevelClick(): void {
		const window = this.windowFactory.createReturnFromEventConfirmationWindowView();
		window.once(ReturnFromEventLevelConfirmationWindowView.EVENT_BUTTON_CONFIRM_CLICK, () => {
			this.emit(EventLevelGameUIViewController.EVENT_RETURN_TO_LEVEL);
		}, this);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonCupClick(): void {
		const window = this.windowFactory.createEventRankingWindowView();
		this.windowViewSystem.showWindow(window);
	}

	private onButtonGoalRewardClick(): void {
		const window = this.windowFactory.createEventRewardsWindow();
		this.windowViewSystem.showWindow(window);
	}

	private onBankOfferWindowEmitted(bankOfferModelKey: string): void {
		const showBankOfferWindow = new ShowBankOfferWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			bankOfferModelKey,
			BankOfferWindowOriginTypes.BANK_OFFER_WINDOWS_EMITTER,
		);
		showBankOfferWindow.start();
	}

	private onBackToGameRewardsUpdated(): void {
		if (this.backToGameRewardModel.hasRewardSoftMoney()) {
			const backToGameWindow = this.windowFactory.createBackToGameWindow();
			this.windowViewSystem.addWindowPending(backToGameWindow);
		}
	}

	private async onInit(): Promise<void> {
		const notCompletedTransactionsData = await this.bankLoader.checkNotCompletedTransactions();
		const showNotCompletedTransactionsRewardsWindow = new ShowNotCompletedTransactionsRewardsWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			notCompletedTransactionsData,
		);

		const showLevelStartDialogWindow = new ShowLevelStartDialogWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.eventEverStarted,
			this.cheatModel.isEmitterDialogWindowsEnabled(),
			this.dialogsFactory,
		);

		const showBackToGameWindow = new ShowBackToGameWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.backToGameRewardModel.hasRewardSoftMoney(),
		);

		const showTutorialStepLevelStartWindow = new ShowTutorialStepEventStartWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.tutorialStepsEmitter,
			this.tutorialStepsViewTargetDispatcher,
		);

		const showTutorialStepGetQuestWindow = new ShowTutorialStepGetQuestWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.tutorialStepsEmitter,
			this.tutorialStepsViewTargetDispatcher,
		);

		const showCompensationLootboxWindow = new ShowCompensationRewardWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.compensationLootboxModels,
			RewardResultWindowRewardStringType.REWARD_COMPENSATION,
		);

		this.emittersController.setWindowChainOn(true);
		showLevelStartDialogWindow
			.start()
			.then(() => showNotCompletedTransactionsRewardsWindow.start())
			.then(() => showBackToGameWindow.start())
			.then(() => {
				if (this.compensationLootboxModels.length > 0) {
					const action = new ClaimCompensationRewardAction(
						this.compensationLootboxModels.map((model) => model.getKey()),
					);
					this.emit(BaseAction.EVENT_ACTION_CREATED, action);
				}
				return showCompensationLootboxWindow.start();
			})
			.then(() => {
				this.compensationLootboxModels = [];
			})
			.then(() => showTutorialStepLevelStartWindow.start())
			.then(() => showTutorialStepGetQuestWindow.start())
			.then(() => {
				this.emittersController.setWindowChainOn(false, true);
			});
	}

	private onNewGoalReached(): void {
		const showEventProgressDialogWindow = this.createEventProgressDialogWindow();

		this.view.setNewGoalRewardWithAnimation(
			this.eventLevelModel.getTargetGoalRewards()[0],
		).then(() => {
			showEventProgressDialogWindow.start();
		});
	}

	private onGoalCompleted(): void {
		const showEventProgressDialogWindow = this.createEventProgressDialogWindow();
		showEventProgressDialogWindow.start();
	}

	private createEventProgressDialogWindow(): ShowEventProgressDialogWindowChainable {
		return new ShowEventProgressDialogWindowChainable(
			this.windowViewSystem,
			this.windowFactory,
			this.dialogsFactory,
			this.eventLevelModel,
			this.cheatModel.isEmitterDialogWindowsEnabled(),
		);
	}

	public destroy(): void {
		this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS_COMPLETE, this.onGoalCompleted, this, true);
		this.eventLevelModel.off(EventLevelModel.EVENT_PROGRESS, this.onNewGoalReached, this);

		this.tutorialStepsEmitter.off(TutorialStepsEmitter.EVENT_BASE_STEP_UNLOCKED, this.onBaseTutorialStepEmitted, this);
		this.bankOfferWindowsEmitter.off(BankOfferWindowsEmitter.EVENT_BANK_OFFER_WINDOW, this.onBankOfferWindowEmitted, this);

		this.backToGameRewardModel.off(BackToGameRewardModel.EVENT_UPDATED, this.onBackToGameRewardsUpdated, this);
	}
}
