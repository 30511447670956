import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardTypes } from '@src/types/RewardTypes';
import { GalleryVideoModel } from '@models/GalleryVideoModel';

export class RewardVideo extends AbstractReward {
	private readonly galleryVideoModel: GalleryVideoModel;

	constructor(galleryVideoModel: GalleryVideoModel) {
		super(RewardTypes.VIDEO, galleryVideoModel.getKey());

		this.galleryVideoModel = galleryVideoModel;
	}

	public collect(): void {
		this.galleryVideoModel.open();
	}

	// eslint-disable-next-line class-methods-use-this
	public getQuantity(): number {
		return 1;
	}

	// eslint-disable-next-line class-methods-use-this
	public getQuantityString(): string {
		return '';
	}

	public getModel(): GalleryVideoModel {
		return this.galleryVideoModel;
	}
}
