import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character36View extends CharacterMainBaseView {
	constructor() {
		super('character36');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-90, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(60, -28),
			bgPosition: new PIXI.Point(182, -32),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
