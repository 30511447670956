import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120007View } from '@views/characters/events/event12/EventCharacter120007View';
import { EventCharacter120008View } from '@views/characters/events/event12/EventCharacter120008View';
import { EventCharacter120009View } from '@views/characters/events/event12/EventCharacter120009View';

export class EventBusiness120003View extends BusinessBaseView {
	constructor() {
		const eventCharacter120007View = new EventCharacter120007View();
		const eventCharacter120008View = new EventCharacter120008View();
		const eventCharacter120009View = new EventCharacter120009View();

		super('business120003', [eventCharacter120007View, eventCharacter120008View, eventCharacter120009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b7_decor4']).setTransform(-17, -60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor2']).setTransform(-18, 15, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor1']).setTransform(-17, -93.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor5']).setTransform(-14, -99, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter120007View as PIXI.DisplayObject,
			eventCharacter120008View,
			eventCharacter120009View,
		);
	}
}
