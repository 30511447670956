import { AbstractQuest } from './AbstractQuest';
import { SummonModel } from '@models/SummonModel';
import { QuestTypes, QuestType } from '@src/types/QuestTypes';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { SummonModelsPool } from '@models/SummonModelsPool';

export class QuestSummon extends AbstractQuest {
	private currentAmount: number;

	constructor(
		questKey: string,
		private targets: SummonModelsPool,
		type: QuestType,
		private targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(questKey, type, lineId, mainWindowViewParameters, time);

		this.currentAmount = 0;

		this.targets.on(SummonModelsPool.EVENT_MODELS_UPDATED, () => {
			this.clearSummonListeners();
			this.trackProgressStart();
		});

		this.trackProgressStart();
	}

	public restoreSavedState(savedData: QuestRestoreStateData): void {
		this.currentAmount = Number(savedData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	protected trackProgressStop(): void {
		this.clearSummonListeners();
		this.targets.removeListener(SummonModelsPool.EVENT_MODELS_UPDATED, this.trackProgressStart, this);
	}

	protected trackProgressStart(): void {
		this.targets.getModelsList().forEach((target) => {
			if ((this.type === QuestTypes.SUMMON_ALL || !target.isFreePrice())) {
				target.on(SummonModel.EVENT_SUMMON_BUY, this.onSummonBuy, this);
			}
		});
	}

	private clearSummonListeners(): void {
		this.targets.getModelsList().forEach((target) => {
			if (this.type === QuestTypes.SUMMON_ALL || !target.isFreePrice()) {
				target.off(SummonModel.EVENT_SUMMON_BUY, this.onSummonBuy, this);
			}
		});
	}

	private onSummonBuy(target: SummonModel, amount: number): void {
		if (this.type === QuestTypes.SUMMON_HARD && target.isHardPrice()
			|| this.type === QuestTypes.SUMMON_PRESTIGE && target.isPrestigePrice()
			|| this.type === QuestTypes.SUMMON_PAY && !target.isFreePrice()
			|| this.type === QuestTypes.SUMMON_ALL) {
			this.currentAmount += amount;

			if (this.currentAmount >= this.targetAmount) {
				this.complete();
			} else {
				this.emit(AbstractQuest.EVENT_PROGRESS, this);
			}
		}
	}
}
