import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { LevelGameUIViewSetter, PrepartyStartCharacterViewSetter } from '@interfaces/ViewSetters';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { PrepartyStartCharacterView } from '@views/characters/PrepartyStartCharacterView';

export class TutorialStepUnlockTypeGoToNextLevelButtonAppearModel
	extends TutorialStepUnlockTypeBaseModel implements LevelGameUIViewSetter {
	private gameUI: LevelGameUIView;

	constructor(key: string) {
		super(key, TutorialStepUnlockTypes.BUTTON_APPEAR_GO_TO_NEXT_LEVEL);
	}

	public setLevelGameUIView(gameUI: LevelGameUIView): void {
		this.gameUI = gameUI;

		if (!this.unlocked) {
			this.gameUI.once(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_SHOWED, this.onUnlocked, this);
		}
	}

	public destroy(): void {
		if (this.gameUI && this.gameUI.listeners(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_SHOWED).includes(this.onUnlocked)) {
			this.gameUI.off(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}

export class TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel
	extends TutorialStepUnlockTypeBaseModel implements PrepartyStartCharacterViewSetter {
	private view: PrepartyStartCharacterView;

	constructor(key: string) {
		super(key, TutorialStepUnlockTypes.BUTTON_APPEAR_PREPARTY_START_CHARACTER);
	}

	public setPrepartyStartChracterView(view: PrepartyStartCharacterView): void {
		this.view = view;
		if (!this.unlocked) {
			view.once(PrepartyStartCharacterView.EVENT_SHOWED, this.onUnlocked, this);
		}
	}

	public destroy(): void {
		if (this.view && this.view.listeners(PrepartyStartCharacterView.EVENT_SHOWED).includes(this.onUnlocked)) {
			this.view.off(PrepartyStartCharacterView.EVENT_SHOWED, this.onUnlocked, this);
		}
		super.destroy();
	}
}
