import { CharacterModel } from '@models/CharacterModel';
import { CharacterCardLockView } from './CharacterCardLockView';
import { SkillModel } from '@models/skills/SkillModel';
import { AbstractCollectionItemView } from '@views/windows/collection/AbstractCollectionItemView';
import { CharacterCardView } from '@views/ui/cards/CharacterCardView';
import { PromotableBaseCardView } from '@views/ui/cards/PromotableBaseCardView';

export class CharacterCollectionsCardView extends AbstractCollectionItemView {
	private readonly model: CharacterModel;
	private readonly skillModel: SkillModel;
	private lockView: CharacterCardLockView;
	protected openView: CharacterCardView;

	constructor(
		model: CharacterModel,
		skillModel: SkillModel,
	) {
		super();

		this.model = model;
		this.skillModel = skillModel;

		if (model.isOpened()) {
			this.onOpened();
		} else {
			model.once(CharacterModel.EVENT_OPENED, this.onOpened, this);
			this.lockView = new CharacterCardLockView(model);
			this.addChild(this.lockView);
		}
	}

	protected showOpened(): void {
		this.openView = new CharacterCardView(
			this.model,
			this.skillModel,
		);
		this.openView.scale.set(0.48);
		this.addChildAt(this.openView, 0);

		if (this.lockView) {
			this.removeChild(this.lockView);
			this.lockView = null;
		}

		this.openView.on(PromotableBaseCardView.EVENT_CLICK, this.onOpenCardClick, this);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(CharacterModel.EVENT_OPENED, this.showOpened, this, true);
		super.destroy(options);
	}
}
