import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter120004View extends CharacterMainBaseView {
	constructor() {
		super('character120004');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-245, 5);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-150, -65),
			arrowRotation: -Math.PI / 180,
			bgPosition: new PIXI.Point(-146, -60),
		});

		this.initQuickPhrases(this.key);
	}
}
