import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import {
	DialogUnlockData,
	DialogUnlockTypes,
	UnlockTypeLevelProgressData,
	UnlockTypeCharacterOpenedData,
	UnlockTypeEventProgressData,
	DialogCharacterPlacement,
	DialogDataItem,
} from '@src/types/DialogTypes';

export class DialogConfig implements ConfigSetElement {
	public static readonly FORMAT_CHARACTER_DATA: RegExp = /^\s*(\w+)\s*:\s*(\w+)\s*$/;

	public static readonly FORMAT_UNLOCK_TYPE_EVENT_PROGRESS: RegExp = /^\s*event_id\s*,\s*progress\s*$/;
	public static readonly FORMAT_UNLOCK_TYPE_LEVEL_PROGRESS: RegExp = /^\s*level\s*,\s*progress\s*$/;
	public static readonly FORMAT_UNLOCK_VALUE_PROGRESS: RegExp = /\s*(\d+)\s*,\s*(start|end|\d+)\s*$/;

	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_STEP: string = 'step';
	public static readonly FIELD_CHARACTER_DATA: string = 'character_data';
	public static readonly FIELD_CHARACTER_NAME: string = 'character_name';
	public static readonly FIELD_CHARACTERS_VISIBLE: string = 'characters_visible';
	public static readonly FIELD_UNLOCK_TYPE: string = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: string = 'unlock_value';
	public static readonly FIELD_PHRASE: string = 'phrase';

	public static readonly UNLOCK_TYPE_CHARACTER_OPENED: string = 'girl_opened';
	public static readonly UNLOCK_TYPE_EVENT: string = 'event_id';
	public static readonly UNLOCK_TYPE_LEVEL: string = 'level';
	public static readonly UNLOCK_TYPE_PROGRESS: string = 'progress';

	public static readonly UNLOCK_VALUE_END: string = 'end';

	public static readonly FIELDS: string[] = [
		DialogConfig.FIELD_KEY,
		DialogConfig.FIELD_STEP,
		DialogConfig.FIELD_CHARACTER_DATA,
		DialogConfig.FIELD_CHARACTER_NAME,
		DialogConfig.FIELD_UNLOCK_TYPE,
		DialogConfig.FIELD_UNLOCK_VALUE,
		DialogConfig.FIELD_PHRASE,
	];

	public static readonly FIELDS_OPTIONAL: string[] = [
		DialogConfig.FIELD_UNLOCK_TYPE,
		DialogConfig.FIELD_UNLOCK_VALUE,
	];

	private readonly key: string;
	private readonly dialogData: DialogDataItem[];
	private readonly unlockData: DialogUnlockData;

	constructor(config: { [key: string]: string }) {
		this.key = config[DialogConfig.FIELD_KEY].trim();
		this.dialogData = [];

		const unlockType = config[DialogConfig.FIELD_UNLOCK_TYPE];
		if (unlockType) {
			const unlockValue = config[DialogConfig.FIELD_UNLOCK_VALUE].trim();

			if (DialogConfig.FORMAT_UNLOCK_TYPE_LEVEL_PROGRESS.test(unlockType)) {
				const type = DialogUnlockTypes.LEVEL_PROGRESS;
				const regexResult = unlockValue.match(DialogConfig.FORMAT_UNLOCK_VALUE_PROGRESS);
				const data: UnlockTypeLevelProgressData = {
					level: regexResult[1],
					progress: regexResult[2],
				};
				this.unlockData = { type, data };
			} else if (DialogConfig.FORMAT_UNLOCK_TYPE_EVENT_PROGRESS.test(unlockType)) {
				const type = DialogUnlockTypes.EVENT_PROGRESS;
				const regexResult = unlockValue.match(DialogConfig.FORMAT_UNLOCK_VALUE_PROGRESS);
				const data: UnlockTypeEventProgressData = {
					eventKey: regexResult[1],
					progress: regexResult[2],
				};
				this.unlockData = { type, data };
			} else if (unlockType === DialogConfig.UNLOCK_TYPE_CHARACTER_OPENED) {
				const type = DialogUnlockTypes.CHARACTER_OPENED;
				const data: UnlockTypeCharacterOpenedData = {
					key: unlockValue,
				};
				this.unlockData = { type, data };
			}
		}
	}

	public getKey(): string {
		return this.key;
	}

	public addDialogDataItem(config: { [key: string]: string }): void {
		const regexResultCharacterData = config[DialogConfig.FIELD_CHARACTER_DATA].match(DialogConfig.FORMAT_CHARACTER_DATA);
		const characterImage: string = regexResultCharacterData[1];
		const placement: DialogCharacterPlacement = regexResultCharacterData[2];

		const characterName: string = config[DialogConfig.FIELD_CHARACTER_NAME];
		const phrase: string = config[DialogConfig.FIELD_PHRASE];

		const charactersVisibleFormatted = (config[DialogConfig.FIELD_CHARACTERS_VISIBLE] as string).split(',').map(str => str.trim());
		const charactersVisible = charactersVisibleFormatted.map((characterVisible: string) => {
			const data = characterVisible.match(DialogConfig.FORMAT_CHARACTER_DATA);
			return {
				characterImage: data[1],
				placement: data[2],
			};
		});

		const item: DialogDataItem = {
			characterName,
			characterImage,
			phrase,
			placement,
			charactersVisible,
		};
		this.dialogData.push(item);
	}

	public getDialogData(): DialogDataItem[] {
		return this.dialogData;
	}

	public getUnlockData(): DialogUnlockData {
		return this.unlockData;
	}
}
