import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70013View } from '@views/characters/events/event7/EventCharacter70013View';
import { EventCharacter70014View } from '@views/characters/events/event7/EventCharacter70014View';
import { EventCharacter70015View } from '@views/characters/events/event7/EventCharacter70015View';

export class EventBusiness70005View extends BusinessBaseView {
	constructor() {
		const eventCharacter70013View = new EventCharacter70013View();
		const eventCharacter70014View = new EventCharacter70014View();
		const eventCharacter70015View = new EventCharacter70015View();


		super('business70005', [eventCharacter70013View, eventCharacter70014View, eventCharacter70015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b9_decor1']).setTransform(71.5, -31, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor2']).setTransform(-78.5, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor3']).setTransform(137, 66.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor5']).setTransform(0, -74, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter70015View,
			eventCharacter70013View as PIXI.DisplayObject,
			eventCharacter70014View,
		);
	}
}
