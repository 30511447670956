import { TotemModel } from '@models/TotemModel';
import { AssetsStorage } from '@main/AssetsStorage';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { LevelStartCardBaseView } from '@views/windows/levelStartWindow/LevelStartCardBaseView';

export class LevelStartTotemCardView extends LevelStartCardBaseView<TotemModel> {
	private readonly fuckPowerLabel: MultiColoredTextField;

	constructor(
		model: TotemModel,
		showFuckpowerLabel?: boolean,
	) {
		super(
			model,
		);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('totemIconAtlas')[`big_${model.getKey()}`]);
		this.fadeContainer.addChildAt(icon, 0);

		if (showFuckpowerLabel) {
			icon.y = -35;
		}

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`totem_card_bg_${model.getCardRarity()}`]);
		bg.position.set(0, -42);
		this.fadeContainer.addChildAt(bg, 0);

		if (showFuckpowerLabel) {
			this.fuckPowerLabel = new MultiColoredTextField({
				font: '70px wendyOneShadowBold',
				align: 'center',
			}, 540, 140);
			this.fuckPowerLabel.anchor = 0.5;
			this.fuckPowerLabel.position.set(0, 250);
			this.addChild(this.fuckPowerLabel);
		}

		this.onLanguageChange();
	}

	protected onLanguageChange(): void {
		this.updateFuckPowerText();
		super.onLanguageChange();
	}

	private updateFuckPowerText(): void {
		if (this.fuckPowerLabel) {
			const fuckpower = this.model.getMultiplierFuckpower();
			const fuckpowerText = Math.round((fuckpower - 1) * 100).toString();

			this.fuckPowerLabel.text = this.localizationStorage.getLocalizedString('#totem_Desc_Fuckpower')
				.replace('{{value}}', fuckpowerText);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model.off(TotemModel.EVENT_PROMOTED, this.updateFuckPowerText, this);
		this.model.off(TotemModel.EVENT_CARDS_ADDED, this.updateFuckPowerText, this);

		super.destroy(options);
	}
}
