import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class EventButtonRankingWindowView extends ButtonBaseView {
	constructor() {
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['gold_cup_icon']);
		icon.scale.set(0.8);
		const iconBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['event_player_bg']);

		const iconContainer = new PIXI.Container();
		iconContainer.addChild(
			iconBg,
			icon,
		);
		super(iconContainer);
	}
}
