import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter252011View extends CharacterMainBaseView {
	constructor() {
		super('character252011');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-92, -7);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-70, -45),
			bgPosition: new PIXI.Point(40, -49),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
