import { BusinessModel } from '@models/BusinessModel';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export class BusinessCloseUIView extends PIXI.Container {
	private model: BusinessModel;
	private tweenHide: TWEEN.Tween;

	constructor(
		model: BusinessModel,
	) {
		super();

		this.model = model;

		const bgLocked = new PIXI.Sprite(AssetsStorage.getAtlas('businessBgAtlas')[`${this.model.getKey()}_lock`]);
		bgLocked.scale.set(0.5);
		bgLocked.interactive = true;

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['locked_fade']);
		bg.width = bgLocked.width;
		bg.y += 25;

		this.addChild(
			bgLocked,
			bg,
		);
	}

	public startShowAnimation(duration: number): void {
		this.alpha = 0;
		this.tweenHide = new TWEEN.Tween(this)
			.to({ alpha: 1 }, duration)
			.onComplete(() => {
				this.tweenHide = null;
			})
			.start();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenHide) {
			this.tweenHide.stop();
			this.tweenHide = null;
		}

		super.destroy(options);
	}
}
