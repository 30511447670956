import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter51003View extends CharacterMainBaseView {
	constructor() {
		super('character51003');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-208, -4);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-180, -71),
			arrowRotation: Math.PI * 180,
			bgPosition: new PIXI.Point(-174, -63),
		});
		this.initQuickPhrases(this.key);
	}
}
