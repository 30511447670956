import { TextUtils } from '../../../utils/TextUtils';

export class TextField extends PIXI.extras.BitmapText {
	public maxHeight: number;

	protected readonly originalSize: number;

	constructor(
		text: string,
		style?: PIXI.extras.BitmapTextStyle,
		maxWidth?: number,
		maxHeight?: number,
	) {
		super(text, style);

		this.maxWidth = maxWidth;
		this.maxHeight = maxHeight ? Math.ceil(maxHeight) : undefined;

		this._text = TextUtils.replaceSpecSymbols(this._text); // eslint-disable-line no-underscore-dangle
		this.originalSize = (this.font as any).size;

		if (this.maxHeight !== undefined) {
			this.fitText();
		}
	}

	// eslint-disable-next-line
	// @ts-ignore
	public set text(value: string) {
		// eslint-disable-next-line no-param-reassign
		value = value.toString() || ' ';
		if (this._text === value) { // eslint-disable-line no-underscore-dangle
			return;
		}

		this._text = TextUtils.replaceSpecSymbols(value); // eslint-disable-line no-underscore-dangle

		if (this.maxHeight !== undefined) {
			this.fitText();
		}
		this.dirty = true;
	}

	public get text(): string {
		return this._text; // eslint-disable-line no-underscore-dangle
	}

	protected fitText(): void {
		(this.font as any).size = this.originalSize;
		this.updateText();
		while (this.textHeight > this.maxHeight) {
			(this.font as any).size -= 1;
			this.updateText();
		}
	}
}
