import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';
import { ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { BankInfoIconView } from '../BankInfoIconView';
import * as TWEEN from '@tweenjs/tween.js';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';
import { Emitter } from 'pixi-particles';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { CardMiniViewFactory } from '@views/ui/cardsMini/CardMiniViewFactory';

export type RechargeRewardDisplayInfo = {
	icon: PIXI.Container;
	count: number;
};

export class BankTabElementRechargeView extends PIXI.Container {
	public static readonly EVENT_BUTTON_RECHARGE_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	private static readonly REWARD_ITEM_WIDTH: number = 118;

	private localizationStorage: LocalizationStorage;
	private infoIcon: BankInfoIconView;
	private mouseOver: boolean;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;
	private animationEmitters: Emitter[];
	private fxAtlas: PIXI.loaders.TextureDictionary;
	private rewardsContent: PIXI.Container;

	constructor(
		private readonly model: BankRechargeModel,
		private readonly cardMiniViewFactory: CardMiniViewFactory,
		private rewards?: RewardDescriptionType[],
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.mouseOver = false;
		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;
		this.animationEmitters = [];
		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.Sprite(
			AssetsStorage.getResource(model.getBackground()).texture,
		);
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.position.set(-16, -3);

		const rechargeButtonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		rechargeButtonBg.width = 420;
		rechargeButtonBg.height = 110;
		rechargeButtonBg.pivot.set(rechargeButtonBg.width / 2, rechargeButtonBg.height / 2);
		const rechargeButton = new ButtonBaseView(rechargeButtonBg);
		rechargeButton.interactive = false;
		rechargeButton.position.set(169, 297);

		const rechargeButtonTextStr = this.model.isClaimable()
			? this.localizationStorage.getLocalizedString('#quest_ButtonClaimMainwindow')
			: this.localizationStorage.getLocalizedString('#bank_recharge_button');
		const rechargeButtonText = new PIXI.extras.BitmapText(
			rechargeButtonTextStr,
			{ font: '42px wendyOneShadowBold' },
		);
		rechargeButtonText.anchor = 0.5;
		rechargeButton.addChild(rechargeButtonText);
		rechargeButton.on(ButtonWithCostValueView.EVENT_CLICK, this.onRechargeButtonClick, this);

		const titleText = new TextField(
			this.localizationStorage.getLocalizedString('#bank_recharge_first_time_title'),
			{ font: '130px wendyOneGold' },
			974,
			150,
		);
		titleText.anchor = 0.5;
		titleText.position.set(169, -327);

		const subtitleText = new MultiColoredTextField(
			{ font: '50px wendyOneShadowBold', align: 'center' },
			622,
			79,
		);
		subtitleText.text = this.localizationStorage.getLocalizedString('#bank_recharge_first_time_desc');
		subtitleText.anchor = 0.5;
		subtitleText.position.set(169, -166);

		// this.infoIcon = new BankInfoIconView(2);
		// this.infoIcon.position.set(677, -393);
		// this.infoIcon.interactive = true;
		// this.infoIcon.on(BankInfoIconView.EVENT_CLICK, this.onBankInfoButtonClick, this);

		const clickMask = new PIXI.Graphics()
			.beginFill(0, 0)
			.drawRect(-bg.width / 2, -bg.height / 2, bg.width, bg.height)
			.endFill();
		clickMask.interactive = true;
		clickMask.on('pointertap', this.onRechargeButtonClick, this);
		clickMask.on('pointerover', this.onMouseOver, this);
		clickMask.on('pointerout', this.onMouseOut, this);

		const animationsContainer = new PIXI.Container();
		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const starEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_flare']],
			BankParticleConfig.getRecharcgeFlare(),
		);
		starEmitter.autoUpdate = true;
		this.animationEmitters.push(starEmitter);

		const glowLoghtsEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLoghtsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLoghtsEmitter);

		animationsContainer.position.set(50, -110);
		animationsContainer.scale.y = 0.6;
		animationsContainer.scale.x = 1.4;

		this.rewardsContent = new PIXI.Container();
		this.rewardsContent.position.set(138, 42);

		this.addChild(
			animationsContainer,
			bg,
			titleText as PIXI.DisplayObject,
			subtitleText,
			clickMask,
			rechargeButton,
			// this.infoIcon,
			this.rewardsContent,
		);

		if (this.model.isClaimable()) {
			const animationsContainerButton = new PIXI.Container();
			const glowEmitterButton = new Emitter(
				animationsContainerButton,
				[this.fxAtlas['skill_activ_glow1']],
				BankParticleConfig.getRecharcgeGlow(),
			);
			glowEmitterButton.autoUpdate = true;
			this.animationEmitters.push(glowEmitterButton);
			animationsContainerButton.position.set(169, 297);
			animationsContainerButton.scale.y = 0.2;
			animationsContainerButton.scale.x = 0.7;

			this.addChildAt(animationsContainerButton, this.getChildIndex(rechargeButton));
		}

		this.ticker.add(this.update, this);

		this.createRewardsRow();
	}

	// eslint-disable-next-line class-methods-use-this
	private getRewardsRow(rewardsDisplayInfo: RechargeRewardDisplayInfo[]): PIXI.Container[] {
		const rewardsRow: PIXI.Container[] = [];
		const rowWidth = (BankTabElementRechargeView.REWARD_ITEM_WIDTH * rewardsDisplayInfo.length) + 5 * (rewardsDisplayInfo.length - 1);
		rewardsDisplayInfo.forEach((rewardDisplayInfo, index): void => {
			const rewardIcon = rewardDisplayInfo.icon;
			const rewardContainer = new PIXI.Container();
			const rewardCount = new TextField(
				`x${SoftMoneyNumber.createFromNumber(rewardDisplayInfo.count).toString()}`,
				{ font: '36px wendyOneShadowBold' },
				BankTabElementRechargeView.REWARD_ITEM_WIDTH - 10,
				35,
			);
			rewardCount.anchor = 0.5;
			rewardCount.position.set(0, 100);
			rewardContainer.addChild(
				rewardIcon,
				rewardCount as PIXI.DisplayObject,
			);
			rewardContainer.position.set(
				(-rowWidth / 2)
				+ (index * BankTabElementRechargeView.REWARD_ITEM_WIDTH)
				+ (index * 5) + BankTabElementRechargeView.REWARD_ITEM_WIDTH / 2
				+ 32,
				17,
			);
			rewardsRow.push(rewardContainer);
		});
		return rewardsRow;
	}

	private createRewardsRow(): void {
		const rewardsDisplayInfo = this.rewards.map((rewardData: RewardDescriptionType): RechargeRewardDisplayInfo => {
			const icon = this.cardMiniViewFactory.createRewardCardByTypeId(
				rewardData.reward_type as RewardTypes,
				rewardData.reward_id,
				0.78,
				0.78,
				CardMiniViewFactory.createStickerNew(),
			);
			icon.interactive = false;
			icon.on('pointerdown', (event: PIXI.interaction.InteractionEvent) => {
				event.stopPropagation();
				this.emit(BankTabElementRechargeView.EVENT_SHOW_HINT, rewardData, icon);
			});
			return { icon, count: Number(rewardData.reward_qty) };
		});

		const rewardsRow = this.getRewardsRow(rewardsDisplayInfo);
		this.rewardsContent.addChild(...rewardsRow);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private onMouseOver(): void {
		if (this.mouseOver) {
			return;
		}
		this.mouseOver = true;
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1.03, y: 1.03 }, 100)
			.easing(TWEEN.Easing.Cubic.In)
			.start();
	}

	private onMouseOut(): void {
		if (!this.mouseOver) {
			return;
		}
		this.mouseOver = false;
		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	private onRechargeButtonClick(): void {
		this.emit(BankTabElementRechargeView.EVENT_BUTTON_RECHARGE_CLICK);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);
		this.tweenGroup.removeAll();
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
