import { BusinessModel } from '@models/BusinessModel';
import { QuestTypes } from '@src/types/QuestTypes';
import { AbstractQuest } from './AbstractQuest';

export class QuestAutomateBusiness extends AbstractQuest {
	private readonly business: BusinessModel;

	constructor(
		questKey: string,
		business: BusinessModel,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.AUTOMATE_BUSINESS,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.business = business;

		if (business.isAutomated()) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	public canRestoreSavedState(): boolean {
		return false;
	}

	public getTargetKey(): string {
		return this.business.getKey();
	}

	public getCurrentProgressValue(): string {
		return this.completed ? '1' : '0';
	}

	// eslint-disable-next-line class-methods-use-this
	public getTotalProgressValue(): string {
		return '1';
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : 0;
	}

	protected trackProgressStop(): void {
		this.business.off(BusinessModel.EVENT_AUTOMATED, this.complete, this, true);
	}

	protected trackProgressStart(): void {
		this.business.once(BusinessModel.EVENT_AUTOMATED, this.complete, this);
	}
}
