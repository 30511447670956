/* eslint-disable no-console */
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { CheatModel } from '@models/CheatModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowResourcesViewController implements CheatWindowViewSetter {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly cheatModel: CheatModel;
	private readonly networkRequestSender: NetworkRequestSender;

	constructor(
		networkRequestSender: NetworkRequestSender,
		softMoneyModel: SoftMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
	) {
		this.networkRequestSender = networkRequestSender;
		this.softMoneyModel = softMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.hardMoneyModel = hardMoneyModel;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_RESOURCE_ADD_SOFT_MONEY, this.onAddSoftMoney, this);
		view.on(CheatWindowView.EVENT_RESOURCE_ADD_HARD_MONEY, this.onAddHardMoney, this);
		view.on(CheatWindowView.EVENT_RESOURCE_ADD_PRESTIGE_MONEY, this.onAddPrestigeMoney, this);
		view.on(CheatWindowView.EVENT_RESOURCE_ADD_ALL, this.onAddAllResources, this);
		view.on(CheatWindowView.EVENT_RESOURCE_RESET, this.onResetResources, this);
	}

	private onAddSoftMoney(value: string): void {
		this.softMoneyModel.add(SoftMoneyNumber.createFromAcronym(value));
	}

	private onAddHardMoney(value: string): void {
		this.hardMoneyModel.add(Number(value));
	}

	private onAddPrestigeMoney(value: string): void {
		this.prestigeMoneyModel.add(Number(value));
	}

	private onResetResources(): void {
		console.log('Resetting resources server...');
		this.networkRequestSender.sendCheatResetResources().then(() => {
			console.log('Resetting resources server OK');
		});
	}

	private onAddAllResources(): void {
		this.prestigeMoneyModel.add(10 ** 5);
		this.hardMoneyModel.add(10 ** 5);
		this.softMoneyModel.add(SoftMoneyNumber.createFromAcronym('99Z'));

		console.log('Opening resources server...');
		this.networkRequestSender.sendCheatOpenAll('resources').then(() => {
			console.log('Opening resources server OK');
		});
	}
}
