import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter55008View extends CharacterMainBaseView {
	constructor() {
		super('character55008');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-80, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-20, -45),
			bgPosition: new PIXI.Point(100, -49),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
