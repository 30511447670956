import { ButtonMaxCustomersViewSetter } from '@interfaces/ViewSetters';
import { ButtonMaxCustomersView } from '@views/ui/ButtonMaxCustomersView';
import { UpdateUserDataAction } from '@models/network/actions/UpdateUserDataAction';
import { BaseAction } from '@models/network/actions/BaseAction';

export class ButtonMaxCustomersViewController extends PIXI.utils.EventEmitter implements ButtonMaxCustomersViewSetter {
	private view: ButtonMaxCustomersView;

	public setButtonMaxCustomersView(view: ButtonMaxCustomersView): void {
		this.view = view;
		view.on(ButtonMaxCustomersView.EVENT_CLICK, this.onClick, this);
	}

	private onClick(): void {
		const currentValueId = this.view.getCurrentValueId();
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.emit(BaseAction.EVENT_ACTION_CREATED, new UpdateUserDataAction({ button_max_customers_current_value_id: currentValueId }));
	}
}
