import { QuestConfigBase } from './QuestConfigBase';
import { QuestType, QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCustomersConfig extends QuestConfigBase {
	private amount: number;
	private businessKey: string;

	private type: QuestType;

	private hours?: number;
	private autoGenerateAmount: boolean;

	constructor(config: { [key: string]: string }) {
		super(config);

		const params: string = config[QuestConfigBase.FIELD_PARAMETERS];

		if (QuestConfigBase.FORMAT_PARAMETER_ALL.test(params)) {
			this.type = QuestTypes.GET_CUSTOMERS_ALL;
		} else {
			this.type = QuestTypes.GET_CUSTOMERS_TARGET;
			// eslint-disable-next-line prefer-destructuring
			this.businessKey = params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)[1];
		}
	}

	public setParamsOnLevel(params: string): void {
		if (QuestGetCustomersConfig.FORMAT_HOURS.test(params)) {
			this.autoGenerateAmount = true;
			this.hours = Number(params.match(QuestGetCustomersConfig.FORMAT_HOURS)[1]);
		} else {
			this.autoGenerateAmount = false;
			this.amount = Number(params);
		}
	}

	public getAmount(): number {
		return this.amount;
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public getType(): QuestType {
		return this.type;
	}

	public isAutoGenerateAmount(): boolean {
		return this.autoGenerateAmount;
	}

	public getHours(): number {
		return this.hours;
	}
}
