import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter30013View extends CharacterMainBaseView {
	constructor() {
		super('character30013');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(45, 51);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(5, -18),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(110, -21),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
