import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { ScrollView } from '@views/components/ScrollView';
import { ScrollAxis, ScrollOffset } from '@src/types/ScrollViewTypes';

// TODO refactor scroll with AbstractCollectionItemView?
export abstract class CollectionBaseView extends PIXI.Container {
	protected contentContainer: PIXI.Container;
	protected scrollView: ScrollView;

	protected constructor(
		scrollOffset: ScrollOffset,
	) {
		super();

		const contentContainer = new PIXI.Container();
		this.contentContainer = contentContainer;

		const topBlackFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['collection_fade'], 1, 0, 0, 1);
		topBlackFade.width = 1570;
		topBlackFade.height = 127;
		topBlackFade.position.set(351, 0);

		const bottomFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['collection_fade_down'], 1, 0, 0, 1);
		bottomFade.width = 1570;
		bottomFade.height = 47;
		bottomFade.position.set(351, GameConstants.GAME_HEIGHT - bottomFade.height);

		this.scrollView = new ScrollView(
			1500,
			1080,
			ScrollAxis.VERTICAL,
			scrollOffset,
		);
		this.scrollView
			.setContent(this.contentContainer)
			.setScrollSpeed(40)
			.setMovementThreshold(50);

		this.addChild(this.scrollView);
		this.addChild(
			topBlackFade,
			bottomFade,
		);

		this.visible = false;
	}

	public enableScroll(): void {
		this.scrollView.enable();
	}

	public disableScroll(): void {
		this.scrollView.disable();
	}

	public startOpenAnimation(): void {
		this.scrollView.y += 113;
		this.scrollView.alpha = 0;

		new TWEEN.Tween(this.scrollView)
			.to({ y: 98, alpha: 1 }, 200)
			.easing(TWEEN.Easing.Quadratic.Out)
			.start();
	}

	public resetScrollValue(): void {
		this.scrollView.y = 98;
		this.scrollView.resetScrollValue();
	}


	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.disableScroll();

		super.destroy(options);
	}
}
