import * as TWEEN from '@tweenjs/tween.js';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { CardProgressBarParticlesConfigs } from '@views/ui/cards/progressBar/CardProgressBarParticlesConfigs';
import { Emitter } from 'pixi-particles';
import { AssetsStorage } from '@main/AssetsStorage';

export class PromoteAnimationProvider extends PIXI.utils.EventEmitter {
	public static readonly EVENT_PROFIT_ANIMATION_COMPLETED: symbol = Symbol();
	public static readonly EVENT_PROFIT_SCALE_DOWN_COMPLETED: symbol = Symbol();
	public static readonly EVENT_ANIMATION_COMPLETED: symbol = Symbol();

	private readonly profitDesc: MultiColoredTextField;
	private readonly particlesContainer: PIXI.Container;
	private readonly glow: PIXI.Sprite;

	private initPromoteTween: TWEEN.Tween;
	private profitScaleNormalTween: TWEEN.Tween;
	private profitScaleDownTween: TWEEN.Tween;
	private profitScaleUpTween: TWEEN.Tween;
	private glowAlphaTween: TWEEN.Tween;
	private glowScaleTween: TWEEN.Tween;
	private particleEnergy: Emitter;
	private particleSpark: Emitter;
	private particleGlow: Emitter;

	constructor(
		profitDesc: MultiColoredTextField,
		particlesContainer: PIXI.Container,
		promoteGlow: PIXI.Sprite,
	) {
		super();

		this.profitDesc = profitDesc;
		this.particlesContainer = particlesContainer;
		this.glow = promoteGlow;
	}

	public start(): void {
		this.initPromoteTween = new TWEEN.Tween(this.profitDesc.scale)
			.to({ x: 1.1, y: 1.1 }, 400)
			.onStart(this.onPromoteAnimationStarted)
			.delay(800)
			.onComplete(this.startScaleDownAnimation)
			.start();
	}

	private onPromoteAnimationStarted = (): void => {
		this.particlesContainer.visible = true;
		this.particleEnergy = this.createParticle(CardProgressBarParticlesConfigs.ENERGY, 'promote_glow2');
		this.particleEnergy.playOnceAndDestroy();

		this.glow.visible = true;

		this.glowAlphaTween = new TWEEN.Tween(this.glow)
			.to({ alpha: 0 }, 200);

		this.glowScaleTween = new TWEEN.Tween(this.glow.scale)
			.to({ x: 1, y: 2 }, 800)
			.chain(this.glowAlphaTween)
			.start();
	};

	private startScaleDownAnimation = (): void => {
		this.profitScaleNormalTween = new TWEEN.Tween(this.profitDesc.scale)
			.to({ x: 1, y: 1 }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.onComplete(() => {
				this.emit(PromoteAnimationProvider.EVENT_PROFIT_ANIMATION_COMPLETED);
			})
			.start();

		this.profitScaleDownTween = new TWEEN.Tween(this.profitDesc.scale)
			.to({ x: 0, y: 0 }, 200)
			.onComplete(this.onScaleDownAnimationCompleted)
			.chain(this.profitScaleNormalTween);

		this.profitScaleUpTween = new TWEEN.Tween(this.profitDesc.scale)
			.to({ x: 1.5, y: 1.5 }, 100)
			.chain(this.profitScaleDownTween)
			.start();
	};

	private onScaleDownAnimationCompleted = (): void => {
		this.particleSpark = this.createParticle(CardProgressBarParticlesConfigs.SPARK, 'promote_glow3');
		this.particleSpark.playOnceAndDestroy(this.onPromoteAnimationCompleted);

		this.particleGlow = this.createParticle(CardProgressBarParticlesConfigs.GLOW, 'promote_glow4');
		this.particleGlow.playOnceAndDestroy();

		this.emit(PromoteAnimationProvider.EVENT_PROFIT_SCALE_DOWN_COMPLETED);
	};

	private onPromoteAnimationCompleted = (): void => {
		this.clearPromoteAnimation();

		this.emit(PromoteAnimationProvider.EVENT_ANIMATION_COMPLETED);
	};

	private clearPromoteAnimation(): void {
		this.particlesContainer.visible = false;

		this.glow.alpha = 0.8;
		this.glow.scale.set(0);
		this.glow.visible = false;

		if (this.initPromoteTween) {
			this.initPromoteTween.stop();
			this.initPromoteTween = null;
		}

		if (this.profitScaleNormalTween) {
			this.profitScaleNormalTween.stop();
			this.profitScaleNormalTween = null;
		}

		if (this.profitScaleDownTween) {
			this.profitScaleDownTween.stop();
			this.profitScaleDownTween = null;
		}

		if (this.profitScaleUpTween) {
			this.profitScaleUpTween.stop();
			this.profitScaleUpTween = null;
		}

		if (this.glowAlphaTween) {
			this.glowAlphaTween.stop();
			this.glowAlphaTween = null;
		}

		if (this.glowScaleTween) {
			this.glowScaleTween.stop();
			this.glowScaleTween = null;
		}

		if (this.particleEnergy) {
			this.particleEnergy.destroy();
			this.particleEnergy = null;
		}

		if (this.particleSpark) {
			this.particleSpark.destroy();
			this.particleSpark = null;
		}

		if (this.particleGlow) {
			this.particleGlow.destroy();
			this.particleGlow = null;
		}
	}

	private createParticle(config: any, textureKey: string): Emitter {
		const emitter = new Emitter(
			this.particlesContainer,
			[
				new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')[textureKey]).texture,
			],
			config,
		);
		emitter.autoUpdate = true;

		return emitter;
	}

	public destroy(): void {
		this.clearPromoteAnimation();
	}
}
