import { BaseAction } from '../BaseAction';

export class EventLevelCompletedAction extends BaseAction {
	constructor(
		eventKey: string,
		playerLeaguePlace: number,
	) {
		super('EventComplete');

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.event_id = eventKey;
		this.data.place = playerLeaguePlace;
	}
}
