import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class SettingsPanelItemBaseView extends PIXI.Container {
	protected readonly localizationStorage: LocalizationStorage;
	protected readonly localeKeyLabelTitle: string;

	protected labelTitle: SizeableBitmapText;

	constructor(
		iconOffset: number,
		labelOffset: number,
		labelLocaleKey: string,
		icon: PIXI.Sprite,
	) {
		super();

		this.localeKeyLabelTitle = labelLocaleKey;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		icon.x = iconOffset;

		this.labelTitle = new SizeableBitmapText('', 275, { font: '34px wendyOne', tint: 0x465768 });
		this.labelTitle.anchor = new PIXI.Point(0, 0.5);
		this.labelTitle.x = labelOffset;
		this.labelTitle.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelTitle);

		this.addChild(
			icon,
			this.labelTitle as PIXI.DisplayObject,
		);
	}

	protected updateText(): void {
		this.labelTitle.text = this.localizationStorage.getLocalizedString(this.localeKeyLabelTitle);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
