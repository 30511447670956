import { ViewportView } from '@views/components/ViewportView';
import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { BusinessBaseViewsSetter, ViewportViewSetter } from '@interfaces/ViewSetters';

export class BusinessBaseZoomRequestHandler extends PIXI.utils.EventEmitter
	implements BusinessBaseViewsSetter, ViewportViewSetter {
	private viewport: ViewportView;

	private views: BusinessBaseView[];
	private currentZoomedView: BusinessBaseView;

	public setViewportView(viewport: ViewportView): void {
		if (this.viewport) {
			this.viewport.off(ViewportView.EVENT_ZOOM_IN_STARTED, this.onZoomInStarted, this);
			this.viewport.off(ViewportView.EVENT_ZOOM_IN_COMPLETED, this.onZoomInCompleted, this);
			this.viewport.off(ViewportView.EVENT_ZOOM_OUT_STARTED, this.onZoomOutStarted, this);
			this.viewport.off(ViewportView.EVENT_ZOOM_OUT_COMPLETED, this.onZoomOutCompleted, this);
		}

		this.viewport = viewport;

		this.viewport.on(ViewportView.EVENT_ZOOM_IN_STARTED, this.onZoomInStarted, this);
		this.viewport.on(ViewportView.EVENT_ZOOM_IN_COMPLETED, this.onZoomInCompleted, this);
		this.viewport.on(ViewportView.EVENT_ZOOM_OUT_STARTED, this.onZoomOutStarted, this);
		this.viewport.on(ViewportView.EVENT_ZOOM_OUT_COMPLETED, this.onZoomOutCompleted, this);
	}

	public setBusinessBaseViews(views: BusinessBaseView[]): void {
		this.views = views;

		this.views.forEach((view) => {
			view.on(BusinessBaseView.EVENT_ZOOM_IN_REQUESTED, this.onZoomInRequested, this);
			view.on(BusinessBaseView.EVENT_ZOOM_OUT_REQUESTED, this.onZoomOutRequested, this);
		});
	}

	private onZoomInRequested(view: BusinessBaseView): void {
		if (!this.viewport.isZooming() && !this.viewport.isZoomed()) {
			this.currentZoomedView = view;
			this.viewport.zoomIn(view.position);
		}
	}

	private onZoomOutRequested(): void {
		if (!this.viewport.isZooming() && this.viewport.isZoomed()) {
			this.viewport.zoomOut();
		}
	}

	private onZoomInStarted(): void {
		this.views.forEach((view) => {
			const viewIsTarget = this.currentZoomedView.getKey() === view.getKey();
			if (viewIsTarget) {
				view.onThisViewZoomInStarted();
			} else {
				view.onOtherViewZoomedIn();
			}
		});
	}

	private onZoomInCompleted(): void {
		this.views
			.filter(view => view === this.currentZoomedView)
			.forEach(view => view.onThisViewZoomInCompleted());
	}

	private onZoomOutStarted(): void {
		this.views.forEach((view) => {
			const viewIsTarget = this.currentZoomedView.getKey() === view.getKey();
			if (viewIsTarget) {
				view.onThisViewZoomOutStarted();
			} else {
				view.onOtherViewZoomedOut();
			}
		});
	}

	private onZoomOutCompleted(): void {
		this.views
			.filter(view => view === this.currentZoomedView)
			.forEach(view => view.onThisViewZoomOutCompleted());

		this.currentZoomedView = null;
	}
}
