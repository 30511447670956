import { AssetsStorage } from '@main/AssetsStorage';

export class BaseEventLevelCharacterHintView extends PIXI.Container {
	protected readonly arrowUp: PIXI.Sprite;
	protected readonly arrowDown: PIXI.Sprite;

	constructor() {
		super();

		this.arrowUp = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_hint_tail_up']);
		this.arrowDown = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['main_ui_hint_tail_down']);
	}

	protected setBgArrowOffset(value: number): void {
		const newX = this.width / 2 - value * this.width;

		this.children.forEach((child) => {
			if (child !== this.arrowDown && child !== this.arrowUp) {
				child.x = newX;
			}
		});
	}
}
