export enum TutorialStepTargetTypes {
	BUTTON_EPIC_QUEST_LOOTBOX = 'epicquestLootboxButton',
	BUTTON_BUY_BUSINESS = 'openBusinessButton',
	BUTTON_BUY_CUSTOMERS = 'customersButton',
	BUTTON_QUEST_LINE = 'questLineButton',
	CHARACTER_ANIMATION = 'characterAnimation',
	BUTTON_ZOOM_IN_BUSINESS = 'zoomInBusinessButton',
	BUTTON_BUSINESS_SLOT = 'businessHintAppearButton',
	BUTTON_START_FAREWELL_PARTY = 'goToPartyWindowButton',
	BUTTON_BANK_OFFER = 'buttonBankOffer',
	SOFT_MONEY_PANEL = 'softPanel',
	PRESTIGE_MONEY_PANEL = 'prestigePanel',
	BUTTON_ON_QUEST_WINDOW = 'questWindowButton',
	BUTTON_SUMMON = 'summonButton',
	BUTTON_PROMOTE = 'promoteWindowButton',
	BUTTON_EVENT = 'eventButton',
	NO_TARGET = 'noTarget',
	BUTTON_CLOSE_COLLECTION = 'buttonCloseCollection',
	BUTTON_CLOSE_PROMOTE_WINDOW = 'buttonClosePromoteWindow',
	INCOME_MONEY = 'incomeMoney',
	BUTTON_MAX_CUSTOMERS = 'maxCustomersButton',
	BUTTON_COLLECTION = 'collectionButton',
	BUTTON_BANK = 'bankButton',
	BUTTON_BOOST = 'boostButton',
	BUTTON_GO_TO_NEXT_LEVEL = 'goToNextLevelButton',
	PRESTIGE_BONUS = 'prestigeBonus',
	BUTTON_SKILL = 'skillButton',
	COLLECTION_SUMMON_BUTTON = 'collectionCastingButton',
	COLLECTION_CHARACTERS_BUTTON = 'collectionGirlsButton',
	COLLECTION_UPGRADES_BUTTON = 'collectionToysButton',
	COLLECTION_ITEM_UPGRADE = 'collectionItemUpgrade',
	COLLECTION_ITEM_CHARACTER = 'collectionItemCharacter',
	SUMMON_BACK_BUTTON = 'summonBackButton',
	SUMMON_CARD_BACK = 'summonCardBack',
	BANK_TAB_BUTTON = 'bankTabButton',
	BANK_TAB_ELEMENT_ACTION_BUTTON = 'bankTabElementActionButton',
	BANK_BOOST_CONFIRM_BUTTON = 'bankBoostConfirmButton',
	LEVEL_PROGRESS_BAR = 'levelProgressBar',
	EVENT_INCOME_PROGRESS_BAR = 'eventProgressBar',
	EVENT_GOAL_REWARD_ICON = 'eventGoalRewardIcon',
	EVENT_BUTTON_CLOSE_REWARDS_WINDOW = 'buttonCloseEventRewardsWindow',
	EVENT_BUTTON_RANKING_WINDOW = 'buttonEventRankingWindow',
	PREPARTY_START_CHARACTER = 'prepartyStartCharacter',
	PREPARTY_FUCK_POWER = 'prepartyFuckPower',
	PREPARTY_FUCK_TIME = 'prepartyFuckTime',
}

export const MAIN_WINDOW_TARGET_TYPES = [
	TutorialStepTargetTypes.BUTTON_BUY_BUSINESS,
	TutorialStepTargetTypes.BUTTON_BUY_CUSTOMERS,
	TutorialStepTargetTypes.BUTTON_QUEST_LINE,
	TutorialStepTargetTypes.CHARACTER_ANIMATION,
	TutorialStepTargetTypes.BUTTON_ZOOM_IN_BUSINESS,
	TutorialStepTargetTypes.BUTTON_BANK_OFFER,
	TutorialStepTargetTypes.SOFT_MONEY_PANEL,
	TutorialStepTargetTypes.PRESTIGE_MONEY_PANEL,
	TutorialStepTargetTypes.BUTTON_EVENT,
	TutorialStepTargetTypes.BUTTON_MAX_CUSTOMERS,
	TutorialStepTargetTypes.BUTTON_COLLECTION,
	TutorialStepTargetTypes.BUTTON_BANK,
	TutorialStepTargetTypes.BUTTON_BOOST,
	TutorialStepTargetTypes.BUTTON_GO_TO_NEXT_LEVEL,
	TutorialStepTargetTypes.BUTTON_SKILL,
	TutorialStepTargetTypes.LEVEL_PROGRESS_BAR,
	TutorialStepTargetTypes.EVENT_INCOME_PROGRESS_BAR,
	TutorialStepTargetTypes.EVENT_GOAL_REWARD_ICON,
	TutorialStepTargetTypes.EVENT_BUTTON_RANKING_WINDOW,
	TutorialStepTargetTypes.PREPARTY_START_CHARACTER,
];
