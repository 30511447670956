import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { EventLevelButtonPatricleView } from './EventLevelButtonPatricleView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { NumberUtils } from '@src/utils/NumberUtils';
import { EventLevelButtonBaseView } from './EventLevelButtonBaseView';

export class EventLevelButtonGoToEventView extends ButtonBaseView implements EventLevelButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly eventLevelModel: EventLevelModel;

	private readonly title: SizeableBitmapText;
	private readonly textTimer: SizeableBitmapText;
	private readonly buttonIcon: PIXI.Sprite;

	constructor(eventLevelModel: EventLevelModel) {
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_solid']);
		super(bg);

		this.eventLevelModel = eventLevelModel;
		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const titlebg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['event_panel_gold'], 8, 0, 8, 0);
		titlebg.width = 170;
		titlebg.pivot.set(titlebg.width / 2, titlebg.height / 2);
		titlebg.position.set(16, 63);

		const titleDecor = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line_horizontal']);
		titleDecor.position.set(16, 76);
		titleDecor.width = 170;
		titleDecor.blendMode = PIXI.BLEND_MODES.SCREEN;

		this.title = new SizeableBitmapText(
			'',
			130,
			{ font: '32px wendyOne', align: 'center', tint: 0x181a1b },
		);
		this.title.anchor = 0.5;
		this.title.position.set(titlebg.x - 1, titlebg.y - 1);

		const timerbg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['purple_panel'], 10, 0, 10, 0);
		timerbg.width = 200;
		timerbg.height = 39;
		timerbg.tint = 0x00000;
		timerbg.alpha = 0.7;
		timerbg.pivot.set(timerbg.width / 2, timerbg.height / 2);
		timerbg.position.set(55, 37);

		this.textTimer = new SizeableBitmapText(
			'',
			130,
			{ font: '20px wendyOneShadowBold', align: 'center' },
		);
		this.textTimer.anchor = 0.5;
		this.textTimer.position.set(timerbg.x - 25, timerbg.y - 6);

		this.buttonIcon = new PIXI.Sprite();
		this.buttonIcon.anchor.set(0.5);

		const glow = new EventLevelButtonPatricleView();
		glow.position.set(20, 6);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.onTimerUpdate, this);

		this.updateText();

		this.addChild(
			glow,
			this.buttonIcon,
			timerbg,
			this.textTimer,
			titlebg,
			this.title as PIXI.DisplayObject,
			titleDecor,
		);
	}

	public setEventKeyIcon(eventKey: string): void {
		this.buttonIcon.texture = AssetsStorage.getAtlas('uiAtlas')[`go_to_event${eventKey}_icon`];
	}

	public getInteractiveArea(): PIXI.Container {
		return this.buttonIcon;
	}

	private onTimerUpdate(): void {
		const timeFormatted = NumberUtils.secToDHMSColonFormatted(this.eventLevelModel.getTimeleft(), true);
		this.textTimer.text = timeFormatted;
	}

	private updateText(): void {
		this.title.text = this.localizationStorage.getLocalizedString('#title_event');
	}

	public destroy(): void {
		this.ticker.remove(this.onTimerUpdate, this);
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
