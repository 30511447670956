import { BaseConfigSetParser } from '@configs/configsParsers/configSets/BaseConfigSetParser';
import { EpicQuestCollectionsConfig } from '@configs/quests/epic/EpicQuestCollectionsConfig';

export class EpicQuestCollectionsConfigSetParser extends BaseConfigSetParser<EpicQuestCollectionsConfig> {
	constructor() {
		super(
			EpicQuestCollectionsConfig.FIELD_TYPE,
			EpicQuestCollectionsConfig,
		);
	}

	public parseConfigSet(configSetJSON: any[]): void {
		let currentTarget = '';
		let currentConfig: EpicQuestCollectionsConfig;

		configSetJSON.forEach((config: { [key: string]: string }) => {
			const configTarget = config[this.configSetKeyField];
			if (configTarget !== currentTarget) {
				currentConfig = BaseConfigSetParser.createConfig(EpicQuestCollectionsConfig, config);
				currentTarget = configTarget;
				this.configSet.set(configTarget, currentConfig);
			}
			currentConfig.addQuestTarget(config);
		});
	}
}
