import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepView } from '@views/windows/TutorialStepWindowView';
import { TutorialStepButtonBuyCustomerData } from '@configs/tutorialSteps/data/TutorialStepButtonBuyCustomerData';
import { ViewportView } from '@views/components/ViewportView';
import { GameConstants } from '@src/utils/GameConstants';
import { TutorialStepCharacterAnimationData } from '@configs/tutorialSteps/data/TutorialStepCharacterAnimationData';
import { CharacterBaseView } from '@views/characters/CharacterBaseView';
import { TutorialStepCompleteAction } from '@models/network/actions/TutorialStepCompleteAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { TutorialStepWindowViewSetter, ViewportViewSetter } from '@interfaces/ViewSetters';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { TutorialBlockType } from '@configs/tutorialSteps/TutorialStepBaseConfig';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { FarewellPartyModel } from '@models/FarewellPartyModel';

export class TutorialStepWindowViewController extends PIXI.utils.EventEmitter
	implements TutorialStepWindowViewSetter, ViewportViewSetter {
	public static readonly EVENT_START: symbol = Symbol();
	public static readonly EVENT_END: symbol = Symbol();

	private readonly tutorialStepModels: Map<string, TutorialStepBaseModel>;
	private readonly characterQuickPhraseEmitter: CharacterQuickPhraseEmitter;
	private readonly farewellPartyModels: Map<string, FarewellPartyModel>;

	private view: TutorialStepView;

	private viewport: ViewportView;

	constructor(
		tutorialStepModels: Map<string, TutorialStepBaseModel>,
		characterQuickPhraseEmitter?: CharacterQuickPhraseEmitter,
		farewelllPartyModels?: Map<string, FarewellPartyModel>,
	) {
		super();
		this.characterQuickPhraseEmitter = characterQuickPhraseEmitter;
		this.tutorialStepModels = tutorialStepModels;
		this.farewellPartyModels = farewelllPartyModels;
	}

	public setViewportView(viewport: ViewportView): void {
		this.viewport = viewport;
	}

	public setTutorialStepWindowView(view: TutorialStepView): void {
		this.view = view;

		const model = this.tutorialStepModels.get(view.getKey());
		if (model.hasBlock() && model.getBlock() === TutorialBlockType.START) {
			this.emit(TutorialStepWindowViewController.EVENT_START);
		}

		this.handleTutorialStep(model);
	}

	private handleTutorialStep(tutorialStep: TutorialStepBaseModel): void {
		this.characterQuickPhraseEmitter.setEnabled(false);

		const targetType = tutorialStep.getTargetType();
		switch (targetType) {
			case TutorialStepTargetTypes.NO_TARGET: {
				this.handleTargetNoTarget(tutorialStep);
				break;
			}
			case TutorialStepTargetTypes.BUTTON_BUY_CUSTOMERS: {
				this.handleTargetButtonBuyCustomers(tutorialStep);
				break;
			}
			case TutorialStepTargetTypes.CHARACTER_ANIMATION: {
				this.handleTargetCharacterAnimation(tutorialStep);
				break;
			}
			case TutorialStepTargetTypes.PRESTIGE_BONUS: {
				this.handleTargetButton(tutorialStep, true);
				break;
			}
			case TutorialStepTargetTypes.PREPARTY_START_CHARACTER:
			case TutorialStepTargetTypes.INCOME_MONEY:
			case TutorialStepTargetTypes.BUTTON_ZOOM_IN_BUSINESS:
			case TutorialStepTargetTypes.BUTTON_BUY_BUSINESS: {
				this.handleTargetButton(tutorialStep, true, true);
				break;
			}
			case TutorialStepTargetTypes.BUTTON_QUEST_LINE:
			case TutorialStepTargetTypes.BUTTON_MAX_CUSTOMERS:
			case TutorialStepTargetTypes.BUTTON_COLLECTION:
			case TutorialStepTargetTypes.BUTTON_BOOST:
			case TutorialStepTargetTypes.BUTTON_SKILL: {
				this.handleTargetButton(tutorialStep, false, true);
				break;
			}
			case TutorialStepTargetTypes.BUTTON_BUSINESS_SLOT: {
				this.handleTargetButton(tutorialStep, undefined, undefined, true);
				break;
			}
			case TutorialStepTargetTypes.BUTTON_EPIC_QUEST_LOOTBOX:
			case TutorialStepTargetTypes.BUTTON_EVENT:
			case TutorialStepTargetTypes.BUTTON_START_FAREWELL_PARTY:
			case TutorialStepTargetTypes.PRESTIGE_MONEY_PANEL:
			case TutorialStepTargetTypes.SOFT_MONEY_PANEL:
			case TutorialStepTargetTypes.BUTTON_CLOSE_COLLECTION:
			case TutorialStepTargetTypes.BUTTON_CLOSE_PROMOTE_WINDOW:
			case TutorialStepTargetTypes.BUTTON_ON_QUEST_WINDOW:
			case TutorialStepTargetTypes.BUTTON_SUMMON:
			case TutorialStepTargetTypes.BUTTON_PROMOTE:
			case TutorialStepTargetTypes.BUTTON_BANK:
			case TutorialStepTargetTypes.BUTTON_BANK_OFFER:
			case TutorialStepTargetTypes.BUTTON_GO_TO_NEXT_LEVEL:
			case TutorialStepTargetTypes.COLLECTION_SUMMON_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_UPGRADES_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_CHARACTERS_BUTTON:
			case TutorialStepTargetTypes.SUMMON_BACK_BUTTON:
			case TutorialStepTargetTypes.SUMMON_CARD_BACK:
			case TutorialStepTargetTypes.BANK_TAB_BUTTON:
			case TutorialStepTargetTypes.BANK_TAB_ELEMENT_ACTION_BUTTON:
			case TutorialStepTargetTypes.BANK_BOOST_CONFIRM_BUTTON:
			case TutorialStepTargetTypes.COLLECTION_ITEM_CHARACTER:
			case TutorialStepTargetTypes.COLLECTION_ITEM_UPGRADE:
			case TutorialStepTargetTypes.LEVEL_PROGRESS_BAR:
			case TutorialStepTargetTypes.EVENT_INCOME_PROGRESS_BAR:
			case TutorialStepTargetTypes.EVENT_GOAL_REWARD_ICON:
			case TutorialStepTargetTypes.EVENT_BUTTON_CLOSE_REWARDS_WINDOW:
			case TutorialStepTargetTypes.EVENT_BUTTON_RANKING_WINDOW:
			case TutorialStepTargetTypes.PREPARTY_FUCK_POWER:
			case TutorialStepTargetTypes.PREPARTY_FUCK_TIME:
				this.handleTargetButton(tutorialStep);
				break;

			default:
				throw new Error(`Unsupported tutorial target '${targetType}'`);
		}
	}

	private handleTargetNoTarget(tutorialStep: TutorialStepBaseModel): void {
		this.view.once(TutorialStepView.EVENT_DIALOG_COMPLETED, () => this.completeTutorialStep(tutorialStep));
	}

	private handleTargetButtonBuyCustomers(tutorialStep: TutorialStepBaseModel): void {
		const targetButton = this.view.getTarget();
		const data = tutorialStep.getData() as TutorialStepButtonBuyCustomerData;

		const businessX = this.viewport
			.toLocal(targetButton.position, targetButton.parent).x
			- GameConstants.GAME_WIDTH / 2 / this.viewport.scale.x;
		this.viewport.moveTo(businessX, 0);

		let currentTapCount = 0;

		const onButtonClick = (): void => {
			currentTapCount += 1;
			if (currentTapCount >= data.getTapCount()) {
				targetButton.off('pointertap', onButtonClick, this);
				this.completeTutorialStep(tutorialStep);
			}
		};

		targetButton.on('pointertap', onButtonClick, this);
	}

	private handleTargetCharacterAnimation(tutorialStep: TutorialStepBaseModel): void {
		const data = tutorialStep.getData() as TutorialStepCharacterAnimationData;
		const target = this.view.getTarget();

		let currentTapCount = 0;

		if (this.viewport.isZoomed()) {
			this.viewport.zoomOut(false);
		}
		const businessX = this.viewport.toLocal(target.position, target.parent).x - GameConstants.GAME_WIDTH / 2 / this.viewport.scale.x;
		this.viewport.moveTo(businessX, 0);

		const onCharacterClick = (): void => {
			currentTapCount += 1;
			if (currentTapCount >= data.getTapCount()) {
				target.off(CharacterBaseView.EVENT_ANIMATION_CLICK, onCharacterClick, this);
				this.completeTutorialStep(tutorialStep);
			}
		};

		target.on(CharacterBaseView.EVENT_ANIMATION_CLICK, onCharacterClick, this);
	}

	private handleTargetButton(
		tutorialStep: TutorialStepBaseModel,
		moveViewportToTarget?: boolean,
		zoomViewportOutOnStart?: boolean,
		zoomViewportOutOnComplete?: boolean,
	): void {
		const targetButton = this.view.getTarget();
		const savedInteractive = targetButton.interactive;
		targetButton.once('pointertap', () => {
			this.completeTutorialStep(tutorialStep);
			targetButton.interactive = savedInteractive;

			if (zoomViewportOutOnComplete && this.viewport.isZoomed()) {
				this.viewport.zoomOut(false);
			}
		}, this);
		targetButton.interactive = true;

		if (moveViewportToTarget) {
			const targetX = this.viewport
				.toLocal(targetButton.position, targetButton.parent).x
				- GameConstants.GAME_WIDTH / 2 / this.viewport.scale.x;
			this.viewport.moveTo(targetX, 0);
		}

		if (zoomViewportOutOnStart && this.viewport.isZoomed()) {
			this.viewport.zoomOut(false);
		}
	}

	private completeTutorialStep(model: TutorialStepBaseModel): void {
		model.complete();

		this.characterQuickPhraseEmitter.setEnabled(true);

		const tutorialStepCompleteAction = new TutorialStepCompleteAction(model.getKey());
		this.emit(BaseAction.EVENT_ACTION_CREATED, tutorialStepCompleteAction);

		if (model.hasBlock() && model.getBlock() === TutorialBlockType.END) {
			this.emit(TutorialStepWindowViewController.EVENT_END);
		}
	}
}
