import { BaseHintViewController } from './BaseHintViewController';
import { ShowHintDataResolver, HintData } from '@views/ui/main/ShowHintDataResolver';
import { HintTypes } from '@src/types/HintTypes';
import { EventLevelRewardsWindowView } from '@views/windows/EventLevelRewardsWindowView';
import { EventLevelRewardsWindowViewSetter } from '@interfaces/ViewSetters';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { ShowHintSpawner } from '@views/ui/main/ShowHintSpawner';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export class EventLevelHintViewController
	extends BaseHintViewController implements EventLevelRewardsWindowViewSetter {
	private readonly mainGameHintDataResolver: ShowHintDataResolver;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		hintSpawner: ShowHintSpawner,
		hintDataResolver: ShowHintDataResolver,
		mainGameHintDataResolver: ShowHintDataResolver,
		questTargetDuration: number,
		summonModelsPool: SummonModelsPool,
		characterModels: Map<string, CharacterModel>,
		skillModels: Map<string, SkillModel>,
	) {
		super(
			windowViewSystem,
			windowFactory,
			hintSpawner,
			hintDataResolver,
			questTargetDuration,
			summonModelsPool,
			characterModels,
			skillModels,
		);

		this.mainGameHintDataResolver = mainGameHintDataResolver;
	}

	public setEventLevelRewardsWindowView(view: EventLevelRewardsWindowView): void {
		view.on(EventLevelRewardsWindowView.EVENT_SHOW_HINT_REWARD_CARD, this.onShowEventLevelRewardsHint, this);
	}

	private onShowEventLevelRewardsHint(type: HintTypes, data: HintData): void {
		const args = this.mainGameHintDataResolver.resolve(type, data, true);
		this.hintSpawner.showHint(this.gameUI, args);
	}
}
