import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter254007View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254007View';
import { EventCharacter254008View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254008View';
import { EventCharacter254009View } from '@views/characters/events/eventLevelWeekDay9/EventCharacter254009View';

export class EventBusiness254003View extends BusinessBaseView {
	constructor() {
		const eventCharacter254007View = new EventCharacter254007View();
		const eventCharacter254008View = new EventCharacter254008View();
		const eventCharacter254009View = new EventCharacter254009View();

		super('business254003', [eventCharacter254007View, eventCharacter254008View, eventCharacter254009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter254009View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter254007View as PIXI.DisplayObject,
			eventCharacter254008View,
		);
	}
}
