import { MaintenanceModel } from '@models/MaintenanceModel';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class MaintenanceLoader {
	private pingIntervalTimeoutHandle: number;
	private inited: boolean;

	constructor(
		private readonly model: MaintenanceModel,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly pingIntervalSec: number,
	) {}

	public init(): void {
		if (!this.inited) {
			this.inited = true;
			this.onPingIntervalTimeout();
		}
	}

	private async onPingIntervalTimeout(): Promise<void> {
		const result = await this.networkRequestSender.sendGetMaintenanceStartTime();
		const maintenanceTime = result.maintenance_time;

		if (this.model.getCurrentMaintenanceTime() !== maintenanceTime) {
			this.model.setCurrentMaintenanceTime(maintenanceTime);
		}

		this.setPingTimeout();
	}

	private setPingTimeout(): void {
		if (this.pingIntervalTimeoutHandle) {
			window.clearTimeout(this.pingIntervalTimeoutHandle);
		}

		this.pingIntervalTimeoutHandle = window.setTimeout(
			() => {
				this.pingIntervalTimeoutHandle = null;
				this.onPingIntervalTimeout();
			},
			this.pingIntervalSec * 1000,
		);
	}
}
