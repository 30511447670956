import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character19View } from '@views/characters/main/Character19View';
import { Character20View } from '@views/characters/main/Character20View';
import { Character21View } from '@views/characters/main/Character21View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business7View extends BusinessBaseView {
	constructor() {
		const character19 = new Character19View();
		const character20 = new Character20View();
		const character21 = new Character21View();

		super('business7', [character19, character20, character21]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b7_decor4']).setTransform(-17, -60, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor2']).setTransform(-18, 15, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor1']).setTransform(-17, -93.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b7_decor5']).setTransform(-14, -99, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character19 as PIXI.DisplayObject,
			character20,
			character21,
		);
	}
}
