import { RewardResultWindowViewSetter, GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { RewardResultWindowView } from '@views/windows/rewardResultWindow/RewardResultWindowView';
import { AbstractReward } from '@interfaces/AbstractReward';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { BankTabTypes } from '@src/types/BankTypes';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { RewardResourceIdTypes } from '@src/types/RewardTypes';

export class RewardResultWindowViewController extends PIXI.utils.EventEmitter
	implements RewardResultWindowViewSetter, GameUIBaseViewSetter {
	private rewardResultWindowView: RewardResultWindowView;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private gameUI: GameUIBaseView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
	) {
		super();

		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
	}

	public setRewardResultWindowView(view: RewardResultWindowView): void {
		if (this.rewardResultWindowView) {
			this.unsubscribe(this.rewardResultWindowView);
		}

		this.rewardResultWindowView = view;
		this.subscribe(view);
	}

	protected subscribe(view: RewardResultWindowView): void {
		view.on(RewardResultWindowView.EVENT_REWARD_OPENED, this.rewardOpened, this);
		view.on(RewardResultWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
		view.on(RewardResultWindowView.EVENT_PLAY_RESOURCE_ANIMATION, this.onPlayResourceAnimation, this);
	}

	protected unsubscribe(view: RewardResultWindowView): void {
		view.off(RewardResultWindowView.EVENT_REWARD_OPENED, this.rewardOpened, this);
		view.off(RewardResultWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
		view.off(RewardResultWindowView.EVENT_PLAY_RESOURCE_ANIMATION, this.onPlayResourceAnimation, this);
	}

	protected async onPlayResourceAnimation(reward: AbstractReward, index: number, position: PIXI.Point): Promise<void> {
		switch (reward.getKey()) {
			case RewardResourceIdTypes.PRESTIGE_MONEY: {
				await this.gameUI.playPrestigeMoneyIncomeAnimation(position, this.rewardResultWindowView);
				break;
			}
			case RewardResourceIdTypes.HARD_MONEY: {
				await this.gameUI.playHardMoneyIncomeAnimation(position, this.rewardResultWindowView);
				break;
			}
			case RewardResourceIdTypes.SOFT_MONEY: {
				await this.gameUI.playSoftMoneyIncomeAnimation(position, this.rewardResultWindowView);
				break;
			}
			default:
				throw new Error(`Unknown resource id ${reward.getKey()}`);
		}
		reward.collect();
		this.rewardResultWindowView.afterResourceAnimation(reward, index);
	}

	// eslint-disable-next-line class-methods-use-this
	protected rewardOpened(reward: AbstractReward): void {
		reward.collect();
	}

	protected onButtonMoneyPlusClick(): void {
		const bankWindow = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(bankWindow);
	}
}
