import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter120013View } from '@views/characters/events/event12/EventCharacter120013View';
import { EventCharacter120014View } from '@views/characters/events/event12/EventCharacter120014View';
import { EventCharacter120015View } from '@views/characters/events/event12/EventCharacter120015View';

export class EventBusiness120005View extends BusinessBaseView {
	constructor() {
		const eventCharacter120013View = new EventCharacter120013View();
		const eventCharacter120014View = new EventCharacter120014View();
		const eventCharacter120015View = new EventCharacter120015View();

		super('business120005', [eventCharacter120013View, eventCharacter120014View, eventCharacter120015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b9_decor3']).setTransform(137, 66.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor5']).setTransform(0, -74, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter120013View,
			eventCharacter120014View as PIXI.DisplayObject,
			eventCharacter120015View,
		);
	}
}
