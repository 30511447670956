import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter120006View extends CharacterMainBaseView {
	constructor() {
		super('character120006');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-17, 44);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-20, -15),
			bgPosition: new PIXI.Point(100, -19),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
