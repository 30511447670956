export class CharacterCardAnimationConfig {
	public static getAppearance(): any {
		return {
			alpha: {
				start: 0.8,
				end: 0,
			},
			scale: {
				start: 0,
				end: 2.5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffa600',
				end: '#ff99c2',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 20,
				max: 25,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.5,
			maxParticles: 15,
			pos: {
				x: 0,
				y: -20,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 170,
			},
		};
	}

	public static getAppearance2(): any {
		return {
			alpha: {
				start: 0.001,
				end: 2,
			},
			scale: {
				start: 2,
				end: 0.001,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#ffffff',
				end: '#ff3888',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 3,
				y: -3,
			},
			maxSpeed: 3,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 25,
				max: 40,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 1,
			maxParticles: 20,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 200,
			},
		};
	}
}
