import { BaseAction } from '../BaseAction';
import { SoftMoneyNumberRaw } from '@src/utils/SoftMoneyNumber';

export class BusinessIncomeAction extends BaseAction {
	constructor(
		businessId: string,
		incomeValue: SoftMoneyNumberRaw,
		clickCount: number,
		clickCountCharacter: number,
		currentSoftMoney: string,
		count: number,
	) {
		super('BusinessIncome');

		this.data.id = businessId;
		this.data.value = incomeValue;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.click_count = clickCount;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.click_count_character = clickCountCharacter;

		this.info.count = count;

		const resourcesSync = Object.create(null);
		// eslint-disable-next-line @typescript-eslint/naming-convention
		resourcesSync.soft_money = currentSoftMoney;
		this.sync['resources'] = resourcesSync;
	}

	public setCount(value: number): void {
		this.info.count = value;
	}

	public getCount(): number {
		return this.info.count;
	}

	public getBusinessKey(): string {
		return this.data.id;
	}

	public getIncome(): string {
		return this.data.value;
	}
}
