import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness80001View } from '@views/businesses/event8Businesses/eventBuilding1/EventBusiness80001View';
import { EventBusiness80002View } from '@views/businesses/event8Businesses/eventBuilding1/EventBusiness80002View';
import { EventBusiness80003View } from '@views/businesses/event8Businesses/eventBuilding1/EventBusiness80003View';
import { EventBusiness80004View } from '@views/businesses/event8Businesses/eventBuilding2/EventBusiness80004View';
import { EventBusiness80005View } from '@views/businesses/event8Businesses/eventBuilding2/EventBusiness80005View';
import { EventBusiness80006View } from '@views/businesses/event8Businesses/eventBuilding2/EventBusiness80006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevel8View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				this.createMorningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.MORNING:
				return this.createMorningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createMorningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();
		const levelBgAtlas = AssetsStorage.getAtlas('germanyMorningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['germany_morning/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 2525);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['germany_morning/road'], EventLevel8View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/house3']).setTransform(7, 651),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house1']).setTransform(1091, 596),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house5']).setTransform(2293, 537),
			new PIXI.Sprite(levelBgAtlas['germany_morning/house2']).setTransform(1844, 532),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(735, 641),
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(1802, 641),
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(2869, 641),
			new PIXI.Sprite(levelBgAtlas['germany_morning/city']).setTransform(3936, 641),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createMorningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('germanyMorningAtlas');

		this.background = EventLevel8View.createMorningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness80001View(),
			new EventBusiness80002View(),
			new EventBusiness80003View(),
			new EventBusiness80004View(),
			new EventBusiness80005View(),
			new EventBusiness80006View(),
		];
		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['germany_morning/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['germany_morning/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['germany_morning/building_left']).setTransform(1232, 539),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		this.initLevel(EventLevel8View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(0, 0);
	}
}
