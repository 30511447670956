import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextField } from '@views/components/text/TextField';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export class ReturnFromEventLevelConfirmationWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_CONFIRM_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;
	private readonly titleLabel: SizeableBitmapText;
	private readonly descrLabel: TextField;

	constructor(
	) {
		super(0.8);

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_3'], 31, 31, 31, 31);
		bg.width = 758;
		bg.height = 428;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const titleBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['title_window_panel'], 5, 0, 5, 5);
		titleBg.width = 736;
		titleBg.pivot.set(titleBg.width / 2, titleBg.height / 2);
		titleBg.y = -200;

		this.titleLabel = new SizeableMultiColoredBitmapText(
			400,
			{ font: '40px wendyOneShadowBold', align: 'center' },
		);
		this.titleLabel.text = this.localizationStorage.getLocalizedString('#return_to_classic_game_title');
		this.titleLabel.anchor = 0.5;
		this.titleLabel.y = titleBg.y;

		const descrBg = new PIXI.Graphics()
			.beginFill(0xe8f3f7)
			.drawRect(0, 0, 646, 156)
			.endFill();
		descrBg.pivot.set(descrBg.width / 2, descrBg.height / 2);
		descrBg.y = -47;

		this.descrLabel = new MultiColoredTextField(
			{ font: '26px wendyOne', align: 'center', tint: 0x39536b },
			600,
			80,
		);
		this.descrLabel.text = this.localizationStorage.getLocalizedString('#return_to_classic_game_description');
		this.descrLabel.anchor = 0.5;
		this.descrLabel.y = descrBg.y;

		const buttonStay = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 314, 76),
			{ font: '38px wendyOneShadowBold' },
			200,
		);
		buttonStay.y = 104;
		buttonStay.x = 168;
		buttonStay.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#stay_button_label'));
		buttonStay.once(ButtonWithLabelBaseView.EVENT_CLICK, () => this.onClose(), this);

		const buttonConfirm = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 314, 76),
			{ font: '38px wendyOneShadowBold' },
			200,
		);
		buttonConfirm.y = 104;
		buttonConfirm.x = -168;
		buttonConfirm.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#return_button_label'));
		buttonConfirm.once(ButtonWithLabelBaseView.EVENT_CLICK, () => this.onConfirmButtonClick(), this);

		this.mainContainer.interactive = true;
		this.mainContainer.addChild(
			bg,
			descrBg,
			this.descrLabel,
			buttonStay,
			buttonConfirm,
			titleBg,
			this.titleLabel as PIXI.DisplayObject,
		);
	}

	private onConfirmButtonClick(): void {
		this.emit(ReturnFromEventLevelConfirmationWindowView.EVENT_BUTTON_CONFIRM_CLICK);
		this.onClose();
	}
}
