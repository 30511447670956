import { LevelBaseConfig, QuestLineItemLevelConfig, BusinessesLevelConfig } from '@configs/level/LevelBaseConfig';
import { TimesOfDayType } from '@src/types/TimesOfDayTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class LevelBaseModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_LEVEL_CHANGE = Symbol();
	public static readonly EVENT_PROGRESS = Symbol();
	public static readonly EVENT_PROGRESS_COMPLETE = Symbol();

	private questLines: QuestLineItemLevelConfig[][];
	private background: TimesOfDayType;
	private softMoneyStart: SoftMoneyNumber;
	private businesses: BusinessesLevelConfig;

	public getBackground(): string {
		return this.background;
	}

	public getQuestLines(): QuestLineItemLevelConfig[][] {
		return this.questLines;
	}

	public getSoftMoneyStart(): SoftMoneyNumber {
		return this.softMoneyStart;
	}

	public getBusinessesLevelConfig(): BusinessesLevelConfig {
		return this.businesses;
	}

	protected setFromConfig(levelConfig: LevelBaseConfig): void {
		this.background = levelConfig.getBackground();
		this.questLines = levelConfig.getQuestLines();
		this.softMoneyStart = levelConfig.getSoftMoneyStart();
		this.businesses = levelConfig.getBusinessesLevelConfig();
	}
}
