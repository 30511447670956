import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { DialogWindowView } from '@views/windows/dialog/DialogWindowView';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { DialogsFactory } from '@src/initializers/DialogsFactory';

export class CheatWindowMediaViewController implements CheatWindowViewSetter {
	private readonly dialogFactory: DialogsFactory;
	private readonly galleryVideoModels: Map<string, GalleryVideoModel>;
	private view: CheatWindowView;

	constructor(
		dialogFactory: DialogsFactory,
		galleryVideoModels: Map<string, GalleryVideoModel>,
	) {
		this.dialogFactory = dialogFactory;
		this.galleryVideoModels = galleryVideoModels;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		this.view = view;
		view.on(CheatWindowView.EVENT_MEDIA_START_DIALOG, this.onMediaStartDialog, this);
		view.on(CheatWindowView.EVENT_MEDIA_OPEN_ALL_VIDEOS, this.onMediaOpenViedeos, this);
	}

	private onMediaStartDialog(key: string): void {
		const model = this.dialogFactory.createDialog(key);
		const dialogWindow = new DialogWindowView(model);
		dialogWindow.onShown();
		this.view.parent.addChild(dialogWindow);
	}

	public onMediaOpenViedeos(): void {
		this.galleryVideoModels.forEach(m => {
			m.setOpened(true);
		});
	}
}
