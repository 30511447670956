import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter40014View extends CharacterMainBaseView {
	constructor() {
		super('character40014');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-32, 21);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-79, -46),
			bgPosition: new PIXI.Point(-85, -30),
			arrowRotation: Math.PI,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
