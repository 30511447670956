import { RewardResultWindowView } from '@views/windows/rewardResultWindow/RewardResultWindowView';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardGroups } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { SoundController } from '@src/main/SoundController';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';
import { GameConstants } from '@src/utils/GameConstants';
import { CardRarity } from '@src/types/CardRarities';
import { TextField } from '@views/components/text/TextField';
import { RewardCharacterCards } from '@models/rewards/RewardCharacterCards';
import { RewardUpgradeCards } from '@models/rewards/RewardUpgradeCards';
import { RewardTypes } from '@src/types/RewardTypes';
import { LevelStartWindowParticlesConfigs } from '../levelStartWindow/LevelStartWindowParticlesConfigs';
import { RewardResultWindowAnimations } from './RewardResultWindowAnimations';
import { CardViewFactory } from '@views/ui/cards/CardViewFactory';
import { RewardResultBonusContainer } from './RewardResultBonusContainer';

export class RewardResultLootboxWindowView extends RewardResultWindowView {
	private animationEmitters: Emitter[];
	private float: TWEEN.Tween;
	private lootboxFront: PIXI.Sprite;
	private lootboxBack: PIXI.Sprite;
	private particleLootboxContainer: PIXI.Container;
	private particleTitleContainer: PIXI.Container;
	private lootboxContainer: PIXI.Container;
	private titleContainer: PIXI.Container;
	private bonusContainer: RewardResultBonusContainer;
	private remainingTextLabel: TextField;
	private tapText: PIXI.extras.BitmapText;
	private lootboxMask: PIXI.Graphics;
	private interectiveArea: PIXI.Graphics;
	private initCards: AbstractReward[];
	private initGroups: RewardGroups;
	private lootboxRarity?: CardRarity;

	public init(
		cards: AbstractReward[],
		groups?: RewardGroups,
		lootboxRarity?: CardRarity,
	): void {
		this.ticker = PIXI.ticker.shared;
		this.animationEmitters = [];

		this.lootboxRarity = lootboxRarity;

		this.initCards = cards;
		this.initGroups = groups;

		if (this.textTitle != null) {
			this.textTitle.visible = false;
			this.glow.visible = false;
		}

		this.particleTitleContainer = new PIXI.Container();

		this.lootboxContainer = new PIXI.Container();
		this.lootboxContainer.position.set(GameConstants.GAME_CENTER_X - 16, GameConstants.GAME_CENTER_Y);

		this.particleLootboxContainer = new PIXI.Container();
		this.particleLootboxContainer.y = 150;

		this.titleContainer = this.createTitleContainer();
		this.titleContainer.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y - 300);
		this.titleContainer.alpha = 0;
		this.titleContainer.scale.set(0.7);

		this.lootboxMask = new PIXI.Graphics()
			.beginFill(0, 0)
			.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
			.endFill();
		this.lootboxMask.pivot.set(this.lootboxMask.width / 2, this.lootboxMask.height / 2);
		this.lootboxMask.interactive = true;

		if (this.lootboxRarity) {
			this.lootboxFront = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`${this.lootboxRarity}_lootbox`]);
		} else {
			this.lootboxFront = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['lootbox']);
		}
		this.lootboxFront.setTransform(17, -327, 0.55, 0.55);

		if (this.lootboxRarity) {
			this.lootboxBack = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`${this.lootboxRarity}_lootbox_back`]);
		} else {
			this.lootboxBack = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['lootbox_back']);
		}
		this.lootboxBack.visible = false;

		this.remainingTextLabel = new TextField(
			'',
			{ font: '32px wendyOneShadowBold', align: 'left' },
			200,
			40,
		);
		this.remainingTextLabel.anchor = 0.5;
		this.remainingTextLabel.tint = 0x7c7e84;
		this.remainingTextLabel.position.set(GameConstants.GAME_WIDTH - 270, GameConstants.GAME_HEIGHT - 160);
		this.remainingTextLabel.visible = false;

		this.lootboxContainer.addChild(
			this.lootboxMask,
			this.lootboxBack,
			this.lootboxFront as PIXI.DisplayObject,
			this.particleLootboxContainer,
		);

		this.addChild(
			this.remainingTextLabel,
			this.titleContainer,
		);
		this.addChildAt(
			this.lootboxContainer,
			this.getChildIndex(this.cardsContainer) + 1,
		);

		this.start();
	}

	private start(): void {
		setTimeout(() => {
			this.playTitleParticles();
		}, 500);

		this.tapText = this.createTapToContinue();
		this.addChildAt(this.tapText, this.getChildIndex(this.bg) + 1);

		const scaleIn = new TWEEN.Tween(this.lootboxFront.scale)
			.to({ x: 0.9, y: 0.9 }, 500)
			.easing(TWEEN.Easing.Cubic.Out);
		const slideDown = new TWEEN.Tween(this.lootboxFront)
			.to({ y: 125 }, 450)
			.easing(TWEEN.Easing.Cubic.Out);
		const slideUp = new TWEEN.Tween(this.lootboxFront)
			.to({ y: '-20' }, 100);
		const scaleX = new TWEEN.Tween(this.lootboxFront.scale)
			.to({ x: '-0.1' }, 100);
		this.float = new TWEEN.Tween(this.lootboxFront)
			.to({ y: 115 }, 1300)
			.repeat(Infinity)
			.yoyo(true);

		const titleAlphaIn = new TWEEN.Tween(this.titleContainer)
			.to({ alpha: 1 }, 400);
		const titleScaleBounceIn = new TWEEN.Tween(this.titleContainer.scale)
			.to({ x: 1.1, y: 1.1 }, 400)
			.easing(TWEEN.Easing.Cubic.In);
		const titleScaleBounseOut = new TWEEN.Tween(this.titleContainer.scale)
			.to({ x: 1, y: 1 }, 100);

		const tapTextAlphaIn = new TWEEN.Tween(this.tapText)
			.to({ alpha: 1 }, 400);
		const tapTextScaleIn = new TWEEN.Tween(this.tapText.scale)
			.to({ x: 1, y: 1 }, 400)
			.easing(TWEEN.Easing.Cubic.In);

		scaleX.chain(scaleIn, slideDown, titleAlphaIn, titleScaleBounceIn);
		titleScaleBounceIn.chain(titleScaleBounseOut);
		slideDown.chain(this.float, tapTextAlphaIn, tapTextScaleIn);
		slideDown.onComplete(() => {
			this.interectiveArea = new PIXI.Graphics()
				.beginFill(0, 0)
				.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
				.endFill();
			this.addChild(this.interectiveArea);
			this.interectiveArea.interactive = true;
			this.interectiveArea.on('pointertap', this.openLootbox, this);
		});

		slideUp.start();
		scaleX.start();
	}

	private openLootbox(): void {
		this.removeChild(this.interectiveArea);
		this.float.stop();

		let animationsCount = 2;
		const onAnimationEnd = (): void => {
			animationsCount -= 1;
			if (animationsCount === 0) {
				this.lootboxContainer.removeChild(this.lootboxMask);
			}
		};

		const glow = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['gtp_scene_glow']);
		glow.setTransform(0, 0, 2, 2);
		glow.alpha = 0;
		glow.blendMode = PIXI.BLEND_MODES.SCREEN;
		this.particleLootboxContainer.addChild(glow);

		const tapTextAlphaOut = new TWEEN.Tween(this.tapText)
			.to({ alpha: 0 }, 200);
		const tapTextScaleOut = new TWEEN.Tween(this.tapText.scale)
			.to({ x: 0.7, y: 0.7 }, 200)
			.easing(TWEEN.Easing.Cubic.In);
		const titleAlphaOut = new TWEEN.Tween(this.titleContainer)
			.to({ alpha: 0 }, 400);
		const titleScaleOut = new TWEEN.Tween(this.titleContainer.scale)
			.to({ x: 0.7, y: 0.7 }, 400)
			.easing(TWEEN.Easing.Cubic.In);
		const inGlow = new TWEEN.Tween(glow)
			.to({ alpha: 0.8 }, 100);
		const outGlow = new TWEEN.Tween(glow)
			.to({ alpha: 0 }, 1000)
			.easing(TWEEN.Easing.Cubic.Out)
			.onComplete(() => {
				this.particleLootboxContainer.removeChild(glow);
			});
		const shakeAndTransform = new TWEEN.Tween(this.lootboxFront)
			.to({ rotation: this.lootboxFront.rotation + 0.1 }, 40)
			.repeat(7)
			.yoyo(true);
		const alphaOutFront = new TWEEN.Tween(this.lootboxFront)
			.delay(200)
			.to({ alpha: 0 }, 1000)
			.easing(TWEEN.Easing.Cubic.InOut)
			.onComplete(() => {
				this.lootboxFront.destroy({ children: true });
				onAnimationEnd();
			});
		const alphaOutBack = new TWEEN.Tween(this.lootboxBack)
			.delay(200)
			.to({ alpha: 0 }, 1000)
			.easing(TWEEN.Easing.Cubic.InOut)
			.onComplete(() => {
				this.lootboxBack.destroy({ children: true });
				onAnimationEnd();
			});

		shakeAndTransform.chain(alphaOutFront, alphaOutBack);
		shakeAndTransform.onComplete(() => {
			this.playLootboxParticles();

			inGlow.chain(outGlow);
			inGlow.start();
			titleAlphaOut.start();
			titleScaleOut.start();

			if (this.lootboxRarity) {
				this.lootboxFront.texture = AssetsStorage.getAtlas('lootboxAtlas')[`${this.lootboxRarity}_lootbox_front`];
			} else {
				this.lootboxFront.texture = AssetsStorage.getAtlas('lootboxAtlas')['lootbox_front'];
			}
			this.lootboxBack.position.set(this.lootboxFront.x, this.lootboxFront.y);
			this.lootboxBack.visible = true;
		});
		tapTextAlphaOut.start();
		tapTextScaleOut.start();
		shakeAndTransform.start();

		setTimeout(() => {
			this.remainingTextLabel.visible = true;
			this.showRewards(this.initCards);
		}, 1200);

		SoundController.getInstance().playLootboxOpen();
	}

	private showRewards(rewards: AbstractReward[]): void {
		this.bonusContainer = new RewardResultBonusContainer();
		this.bonusContainer.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y + 260);
		this.bonusContainer.alpha = 0;
		this.addChild(this.bonusContainer);

		const [curReward, ...rest] = rewards;
		const cardObj = this.showSingleCard(curReward);
		this.addChild(cardObj);

		this.remainingTextLabel.alpha = 0;

		const curScaleX = cardObj.scale.x;
		const curScaleY = cardObj.scale.y;

		cardObj.alpha = 0;
		cardObj.scale.set(0.3);

		new TWEEN.Tween(this.bonusContainer, this.tweenGroup)
			.to({ alpha: 1 }, 400)
			.easing(TWEEN.Easing.Cubic.In)
			.start();

		new TWEEN.Tween(cardObj, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.easing(TWEEN.Easing.Cubic.In)
			.start();
		new TWEEN.Tween(cardObj.scale, this.tweenGroup)
			.to({ x: curScaleX, y: curScaleY }, 200)
			.easing(TWEEN.Easing.Cubic.In)
			.start();
		new TWEEN.Tween(this.remainingTextLabel, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();

		const remainingText = this.localizationStorage.getLocalizedString('#remaining_label');
		this.remainingTextLabel.text = `${remainingText}: ${rest.length}`;

		const handler = (): void => {
			new TWEEN.Tween(this.bonusContainer, this.tweenGroup)
				.to({ alpha: 0 }, 200)
				.easing(TWEEN.Easing.Cubic.In)
				.start();

			new TWEEN.Tween(cardObj, this.tweenGroup)
				.to({ alpha: 0 }, 100)
				.onComplete(() => {
					this.removeChild(cardObj);
					this.removeChild(this.bonusContainer);

					if (rest.length > 0) {
						this.showRewards(rest);
					} else {
						setTimeout(() => {
							this.playTitleParticles();
						}, 500);

						this.remainingTextLabel.visible = false;
						this.bonusContainer.visible = false;

						if (this.initCards.length > 3) {
							this.titleContainer.visible = false;
						}

						const titleAlphaIn = new TWEEN.Tween(this.titleContainer)
							.to({ alpha: 1 }, 400);
						const titleScaleBounceIn = new TWEEN.Tween(this.titleContainer.scale)
							.to({ x: 1.1, y: 1.1 }, 400)
							.easing(TWEEN.Easing.Cubic.In);
						const titleScaleBounseOut = new TWEEN.Tween(this.titleContainer.scale)
							.to({ x: 1, y: 1 }, 100);

						titleScaleBounceIn.chain(titleScaleBounseOut);
						titleAlphaIn.start();
						titleScaleBounceIn.start();

						super.init(this.initCards, this.initGroups, null, true);
					}
				})
				.start();

			this.bg.off('pointertap', handler);
		};

		this.bg.once('pointertap', handler);
	}

	private showSingleCard(reward: AbstractReward): PIXI.Container {
		const card = this.cardViewFactory.createRewardCard(reward);
		const rewardType = reward.getType();

		if (rewardType === RewardTypes.RESOURCE) {
			card.scale.set(0.88);
		} else if (rewardType === RewardTypes.BOOST || rewardType === RewardTypes.TIMESKIP) {
			card.scale.set(0.89);
		} else if (rewardType === RewardTypes.VIDEO) {
			card.scale.set(0.84);
		} else if (
			rewardType === RewardTypes.CHARACTER
			|| rewardType === RewardTypes.UPGRADE
			|| rewardType === RewardTypes.TOTEM) {
			card.scale.set(0.58);
		}
		card.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y - 66);
		card.interactive = false;

		if (reward instanceof RewardCharacterCards) {
			const model = (reward as RewardCharacterCards).getModel();
			const bonus = model.getFirstBonus();
			const skillKey = model.getSkillKey();

			if (bonus != null) {
				this.bonusContainer.visible = true;
				this.bonusContainer.setBonus(bonus, model);
			} else if (skillKey != null) {
				this.bonusContainer.setSkill(this.skillModels.get(skillKey), model);
			}

			const count = model.getCardsCount() + reward.getQuantity();
			CardViewFactory.tryAnimateCardProgressWithCount(card, count);
		} else if (reward instanceof RewardUpgradeCards) {
			const model = (reward as RewardUpgradeCards).getModel();
			const bonus = model.getFirstBonus();

			if (bonus != null) {
				this.bonusContainer.visible = true;
				this.bonusContainer.setBonus(bonus, model);
			}

			const count = model.getCardsCount() + reward.getQuantity();
			CardViewFactory.tryAnimateCardProgressWithCount(card, count);
		} else {
			this.bonusContainer.visible = false;
		}

		return card;
	}

	private createTitleContainer(): PIXI.Container {
		const content = new PIXI.Container();

		const title = new PIXI.extras.BitmapText(
			this.localizationStorage.getLocalizedString('#lootbox_Label_Title'),
			{ font: '52px wendyOneGold' },
		);
		title.anchor = 0.5;

		const episodeNamePanelFade = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['episode_name_panel_fade']);
		episodeNamePanelFade.tint = 0x0e0b1e;
		episodeNamePanelFade.scale.set(4, 1.5);

		const glowLineUp = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLineUp.y = -49;

		const glowLineDown = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['glow_line']);
		glowLineDown.y = 46;

		const effectsContainer = this.createEffects();
		effectsContainer.y = -2;

		content.addChild(
			effectsContainer,
			episodeNamePanelFade,
			glowLineUp,
			glowLineDown,
			title as PIXI.DisplayObject,
			this.particleTitleContainer,
		);

		return content;
	}

	private createTapToContinue(): PIXI.extras.BitmapText {
		const label = new PIXI.extras.BitmapText(
			this.localizationStorage.getLocalizedString('#lootbox_Label_TapToContinue'),
			{ font: '26px wendyOneShadowBold' },
		);
		label.anchor = 0.5;
		label.tint = 0x7c7e84;
		label.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y + 412);
		label.alpha = 0;

		return label;
	}

	private createEffects(): PIXI.Container {
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const effectsContainer = new PIXI.Container();
		effectsContainer.scale.set(0.75, 0.3);

		const glowEmitter = new Emitter(
			effectsContainer,
			[fxAtlas['skill_activ_glow1']],
			LevelStartWindowParticlesConfigs.GLOW,
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowLightsEmitter = new Emitter(
			effectsContainer,
			[fxAtlas['party_button_glow4']],
			LevelStartWindowParticlesConfigs.GLOW2,
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		return effectsContainer;
	}

	private playLootboxParticles(): void {
		const lootboxFlareEmitter = new Emitter(
			this.particleLootboxContainer,
			[new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['promote_glow1']).texture],
			RewardResultWindowAnimations.getLootboxOpenFlareConfig(),
		);
		this.animationEmitters.push(lootboxFlareEmitter);
		lootboxFlareEmitter.playOnceAndDestroy();

		const lootboxSparkEmitter = new Emitter(
			this.particleLootboxContainer,
			[new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_button_glow4']).texture],
			RewardResultWindowAnimations.getLootboxOpenSparkConfig(),
		);
		this.animationEmitters.push(lootboxSparkEmitter);
		lootboxSparkEmitter.playOnceAndDestroy();

		setTimeout(() => {
			const lootboxEmitter = new Emitter(
				this.particleLootboxContainer,
				[new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['business_glow4']).texture],
				RewardResultWindowAnimations.getLootboxOpenConfig(),
			);
			this.animationEmitters.push(lootboxEmitter);
			lootboxEmitter.playOnceAndDestroy();
		}, 300);
	}

	private playTitleParticles(): void {
		const glow = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['summon_frame_glow']);
		glow.setTransform(0, -10, 3, 2);
		glow.alpha = 0;
		glow.blendMode = PIXI.BLEND_MODES.SCREEN;
		this.titleContainer.addChild(glow);

		const inGlow = new TWEEN.Tween(glow)
			.to({ alpha: 0.8 }, 100);
		const outGlow = new TWEEN.Tween(glow)
			.to({ alpha: 0 }, 600)
			.easing(TWEEN.Easing.Cubic.Out)
			.onComplete(() => {
				this.titleContainer.removeChild(glow);
			});

		inGlow.chain(outGlow);
		inGlow.start();

		const titleEmitter = new Emitter(
			this.particleTitleContainer,
			[new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['party_button_flare']).texture],
			RewardResultWindowAnimations.getTitleOpenConfig(),
		);
		this.animationEmitters.push(titleEmitter);
		titleEmitter.playOnceAndDestroy();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
