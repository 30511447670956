import { GameConstants } from '@src/utils/GameConstants';
import { VideoPlayerControls } from './VideoPlayerControls';
import { PlaylistItem } from '@src/types/PlaylistItem';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';

export class VideoPlayer extends PIXI.Container {
	public static readonly EVENT_PLAYER_CLOSED: symbol = Symbol();

	private readonly video: PIXI.Sprite;
	private videoTexture: PIXI.Texture;
	private readonly controls: VideoPlayerControls;
	private readonly playlist: PlaylistItem[];
	private indexInPlaylist: number;

	constructor(playlist: PlaylistItem[], currentKey: string) {
		super();

		this.playlist = playlist;

		this.indexInPlaylist = 0;
		for (let i = 0; i < playlist.length; i++) {
			if (playlist[i].key === currentKey) {
				this.indexInPlaylist = i;
				break;
			}
		}

		this.controls = new VideoPlayerControls(currentKey);
		this.controls.y = 852;
		this.controls.on(VideoPlayerControls.EVENT_PLAY_VIDEO, this.playVideo, this);
		this.controls.on(VideoPlayerControls.EVENT_PAUSE_VIDEO, this.pauseVideo, this);
		this.controls.on(VideoPlayerControls.EVENT_SOUND_ON, this.setSoundOn, this);
		this.controls.on(VideoPlayerControls.EVENT_SOUND_OFF, this.setSoundOff, this);
		this.controls.on(VideoPlayerControls.EVENT_SEEK_VIDEO, this.seekVideo, this);
		this.controls.on(VideoPlayerControls.EVENT_PLAY_NEXT_VIDEO, this.playNextVideo, this);
		this.controls.on(VideoPlayerControls.EVENT_PLAY_PREV_VIDEO, this.playPrevVideo, this);

		const closeButton = new CloseButtonView();
		closeButton.position.set(1880, 40);
		closeButton.alpha = 0.4;
		closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.video = new PIXI.Sprite();
		this.video.position.set(GameConstants.GAME_CENTER_X, 0);
		this.video.scale.set(1.5);
		this.video.anchor.set(0.5, 0);

		this.setVideo(playlist[this.indexInPlaylist].url);

		this.addChild(
			this.video as PIXI.DisplayObject,
			this.controls,
			closeButton,
		);
	}

	private playVideo(): void {
		(this.videoTexture.baseTexture.source as HTMLVideoElement).play();
	}

	private pauseVideo(): void {
		(this.videoTexture.baseTexture.source as HTMLVideoElement).pause();
	}

	private stopVideo(): void {
		const htmlVideo = this.videoTexture.baseTexture.source as HTMLVideoElement;
		htmlVideo.pause();
		htmlVideo.onloadedmetadata = null;
		htmlVideo.ontimeupdate = null;
		htmlVideo.onended = null;

		this.videoTexture.baseTexture.dispose();
		this.videoTexture.destroy();
		this.videoTexture = null;

		htmlVideo.setAttribute('src', '');
	}

	private setSoundOn(): void {
		(this.videoTexture.baseTexture.source as HTMLVideoElement).muted = false;
	}

	private setSoundOff(): void {
		(this.videoTexture.baseTexture.source as HTMLVideoElement).muted = true;
	}

	private seekVideo(seekingTime: number): void {
		(this.videoTexture.baseTexture.source as HTMLVideoElement).currentTime = seekingTime;
	}

	private playNextVideo(): void {
		this.stopVideo();

		if (this.indexInPlaylist < this.playlist.length - 1) {
			this.indexInPlaylist += 1;
		} else {
			this.indexInPlaylist = 0;
		}

		this.controls.updateName(this.playlist[this.indexInPlaylist].key);
		this.setVideo(this.playlist[this.indexInPlaylist].url);
	}

	private playPrevVideo(): void {
		this.stopVideo();

		if (this.indexInPlaylist > 0) {
			this.indexInPlaylist -= 1;
		} else {
			this.indexInPlaylist = this.playlist.length - 1;
		}

		this.controls.updateName(this.playlist[this.indexInPlaylist].key);
		this.setVideo(this.playlist[this.indexInPlaylist].url);
	}

	private setVideo(url: string): void {
		this.videoTexture = PIXI.Texture.fromVideoUrl(url);

		const htmlVideo = this.videoTexture.baseTexture.source as HTMLVideoElement;
		htmlVideo.autoplay = true;
		htmlVideo.onloadedmetadata = () => this.controls.setVideoDuration(htmlVideo.duration);
		htmlVideo.ontimeupdate = () => this.controls.updateCurrentTime(htmlVideo.currentTime);
		htmlVideo.onended = () => this.controls.onVideoEnded();

		this.video.texture = this.videoTexture;
	}

	private onClose(): void {
		this.emit(VideoPlayer.EVENT_PLAYER_CLOSED);

		this.destroy({ children: true });
	}

	public destroy(options?: PIXI.DestroyOptions): void {
		this.stopVideo();

		super.destroy(options);
	}
}
