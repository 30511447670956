/* eslint-disable no-console */
import { UpgradeModel } from '@models/UpgradeModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class CheatWindowUpgradesViewController implements CheatWindowViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly upgradeModels: Map<string, UpgradeModel>;

	constructor(
		networkRequestSender: NetworkRequestSender,
		upgradeModels: Map<string, UpgradeModel>,
	) {
		this.networkRequestSender = networkRequestSender;
		this.upgradeModels = upgradeModels;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_UPGRADE_OPEN, this.onUpgradeOpen, this);
		view.on(CheatWindowView.EVENT_UPGRADE_OPEN_ALL, this.onUpgradeOpenAll, this);
		view.on(CheatWindowView.EVENT_UPGRADE_ADD_CARDS_TO_OPENED, this.onUpgradeAddCardsToOpened, this);
		view.on(CheatWindowView.EVENT_UPGRADE_ACTIVATE, this.onUpgradeActivate, this);
		view.on(CheatWindowView.EVENT_UPGRADE_ACTIVATE_ALL, this.onUpgradeActivateAll, this);
	}

	private onUpgradeOpen(key: string): void {
		this.upgradeModels.get(key).open();

		console.log(`Opening '${key}' server...`);
		this.networkRequestSender.sendCheatOpen(key, 'upgrades').then(() => {
			console.log(`Opening '${key}' server OK`);
		});
	}

	private onUpgradeOpenAll(): void {
		this.upgradeModels
			.forEach((model) => {
				if (!model.isOpened()) {
					model.open();
				}
			});
		console.log('Opening upgrades server...');
		this.networkRequestSender.sendCheatOpenAll('upgrades').then(() => {
			console.log('Opening upgrades server OK');
		});
	}

	private onUpgradeAddCardsToOpened(value: string): void {
		const amount = Number(value);
		this.upgradeModels.forEach(model => {
			if (model.isOpened()) {
				model.addCards(amount);
			}
		});

		console.log('Adding cards to opened upgrades server...');
		this.networkRequestSender.sendCheatAddCardsToOpened(amount, 'upgrades').then(() => {
			console.log('Adding cards to opened upgrades server OK');
		});
	}

	private onUpgradeActivate(key: string): void {
		this.upgradeModels.get(key).activate();
	}

	private onUpgradeActivateAll(): void {
		this.upgradeModels.forEach(x => x.activate());
	}
}
