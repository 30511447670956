export class FarewellPartyParticlesConfig {
	public static getFlare(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1,
				end: 0.001,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#FAB225',
				end: '#FAB225',
			},
			speed: {
				start: 200,
				end: 190,
				minimumSpeedMultiplier: 0.1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 360,
				max: -360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 6,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getStars(): any {
		return {
			alpha: {
				start: 1,
				end: 0.2,
			},
			scale: {
				start: 2,
				end: 0.1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#FAB225',
				end: '#FAB225',
			},
			speed: {
				start: 200,
				end: 190,
				minimumSpeedMultiplier: 0.1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 360,
				max: -360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.1,
			maxParticles: 6,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}
}
