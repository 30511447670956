import { LevelBaseView, LevelBaseViewArgs } from './LevelBaseView';
import {
	MainWindowTarget,
	MainWindowElementConfig,
	MainWindowUnlockType,
	UnlockTypeTasksCompleted,
} from '@configs/MainWindowConfig';
import { LevelModel } from '@models/level/LevelModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';

export interface LevelDefaultViewArgs extends LevelBaseViewArgs {
	levelModel: LevelModel,
	questLinesModel: QuestLinesModel,
	levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>,
}

export abstract class LevelDefaultView extends LevelBaseView {
	private readonly levelBaseWindowConfig: Map<MainWindowTarget, MainWindowElementConfig>;
	private readonly levelModel: LevelModel;
	private readonly questLinesModel: QuestLinesModel;

	constructor(
		args: LevelDefaultViewArgs,
	) {
		super(args);

		this.levelBaseWindowConfig = args.levelBaseWindowConfig;
		this.levelModel = args.levelModel;
		this.questLinesModel = args.questLinesModel;
		this.updateMainWindowElementsVisible();
	}

	private onQuestComplete(): void {
		this.updatePrepartyCharacterElementsVisible();
	}

	private updateMainWindowElementsVisible(): void {
		const level: number = this.levelModel.getCurrentLevel();
		const progress: number = this.levelModel.getCurrentProgress();
		this.levelBaseWindowConfig.forEach((elementConfig) => {
			this.updateMainWindowTarget(elementConfig.target);

			if (elementConfig.unlockType === MainWindowUnlockType.EPISODE_TASKS_COMPLETED) {
				const unlockValue = elementConfig.unlockValue as UnlockTypeTasksCompleted;
				if (unlockValue.level === level && unlockValue.tasksProgress > progress) {
					if (!this.questLinesModel.listeners(QuestLinesModel.EVENT_LINE_PROGRESS).includes(this.onQuestComplete)) {
						this.questLinesModel.on(QuestLinesModel.EVENT_LINE_PROGRESS, this.onQuestComplete, this);
					}
				}
			}
		});
	}

	private updateMainWindowTarget(target: MainWindowTarget): void {
		// eslint-disable-next-line default-case
		switch (target) {
			case MainWindowTarget.PREPARTY_CHARACTER: {
				this.updatePrepartyCharacterElementsVisible();
				break;
			}
		}
	}

	private updatePrepartyCharacterElementsVisible(): void {
		const showHiddenHandler = (): void => {
			this.prepartyCharacterView.setLocked(true);
			this.prepartyCharacterView.visible = false;
		};

		const showUnlockedHandler = (): void => {
			this.prepartyCharacterView.setLocked(false);
			this.showPrePartyCharacter();
		};

		this.updateElementVisible(
			showHiddenHandler,
			showUnlockedHandler,
			showHiddenHandler,
			this.levelBaseWindowConfig.get(MainWindowTarget.PREPARTY_CHARACTER),
		);
	}

	private updateElementVisible(
		showLockedHandler?: (showLockFrom: number) => void,
		showUnlockedHandler?: () => void,
		showHiddenHandler?: () => void,
		config?: MainWindowElementConfig,
	): void {
		switch (config.unlockType) {
			case MainWindowUnlockType.EPISODE_TASKS_COMPLETED: {
				const unlockValue = config.unlockValue as UnlockTypeTasksCompleted;
				const level: number = this.levelModel.getCurrentLevel();
				const progress: number = this.levelModel.getCurrentProgress();
				if (unlockValue.level < level) {
					showUnlockedHandler();
				} else if (unlockValue.level === level) {
					if (progress >= unlockValue.tasksProgress) {
						showUnlockedHandler();
					} else if (config.showLockFrom !== undefined) {
						showLockedHandler(config.showLockFrom);
					} else {
						showHiddenHandler();
					}
				} else if (config.showLockFrom !== undefined) {
					showLockedHandler(config.showLockFrom);
				} else {
					showHiddenHandler();
				}
				break;
			}

			default:
				throw new Error(`Unknow unlock type ${config.unlockType}`);
		}
	}
}
