import { AbstractReward } from '@interfaces/AbstractReward';
import { GameProfileModel } from '@models/GameProfileModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { DialogsFactory } from '@src/initializers/DialogsFactory';
import { BankOfferWindowOriginType } from '@src/types/BankTypes';
import { TutorialStepWindowViewTargetBaseDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewTargetBaseDispatcher';
import {
	RewardGroups,
	RewardResultWindowBgType,
	RewardResultWindowRewardStringType
} from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { AccountNotCreatedWarningWindowView } from '@views/windows/account/AccountNotCreatedWarningWindowView';
import { WindowBaseView } from '@views/components/WindowBaseView';
import { LevelWindowViewFactory } from '@src/initializers/windows/LevelWindowViewFactory';
import { RegistrationWindowView } from '@views/windows/account/RegistrationWindowView';
import { ChainableObject } from '@src/utils/ChainableObject';
import { MAIN_WINDOW_TARGET_TYPES } from '@src/types/TutorialStepTargetTypes';

export class ShowAccountNotCreatedWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly gameProfileModel: GameProfileModel,
		private readonly levelModel: LevelModel,
	) {
	}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (
			this.levelModel.getCurrentLevel() === 2
			&& !this.gameProfileModel.isLoggedIn()
			&& this.levelModel.getCurrentProgress() + 1 === this.levelModel.getTotalProgress()
		) {
			result = new Promise(resolve => {
				const accountNotCreatedWindow = this.windowFactory.createAccountNotCreatedWarningWindow();
				accountNotCreatedWindow.once(AccountNotCreatedWarningWindowView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				accountNotCreatedWindow.once(AccountNotCreatedWarningWindowView.EVENT_BUTTON_REGISTER_CLICK, () => {
					const registrationWindow = this.windowFactory.createRegistrationWindow();
					registrationWindow.once(RegistrationWindowView.EVENT_WINDOW_CLOSED, () => {
						resolve();
					}, this);
					this.windowViewSystem.showWindow(registrationWindow);
				}, this);

				this.windowViewSystem.showWindow(accountNotCreatedWindow);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowCompensationRewardWindowChainable implements ChainableObject {
	private models: LootboxModel[];
	private rewardString?: RewardResultWindowRewardStringType;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		models: LootboxModel[],
		rewardString?: RewardResultWindowRewardStringType,
	) {
		this.models = models;
		this.rewardString = rewardString;
	}

	public start(): Promise<void> {
		const createRewardWindowPromise = (model: LootboxModel): Promise<void> => new Promise(resolve => {
			const window = this.windowFactory.createRewardResultLootboxWindow(
				false,
				this.rewardString,
				RewardResultWindowBgType.FADE,
			);
			window.init(model.getRewards(), undefined, model.getCardRarity());
			window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
				resolve();
			}, this);

			this.windowViewSystem.addWindowPending(window);
		});
		let result: Promise<void>;
		if (this.models.length > 0) {
			result = createRewardWindowPromise(this.models[0]);
			for (let i = 1; i < this.models.length; i++) {
				result = result.then(() => createRewardWindowPromise(this.models[i]));
			}
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowQuestLootboxWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly model: LootboxModel,
		private readonly rewardString?: RewardResultWindowRewardStringType,
	) {}

	public start(): Promise<void> {
		return new Promise((resolve) => {
			const currentWindow = this.windowFactory.createRewardResultLootboxWindow(
				false,
				this.rewardString,
				RewardResultWindowBgType.FADE,
			);
			currentWindow.init(this.model.getRewards(), undefined, this.model.getCardRarity());
			currentWindow.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
				resolve();
			}, this);

			this.windowViewSystem.showWindow(currentWindow);
		});
	}
}

export class ShowRewardsWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly rewards: AbstractReward[],
	) {
	}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (this.rewards.length > 0) {
			result = new Promise(resolve => {
				const window = this.windowFactory.createRewardResultWindow(
					false,
					undefined,
					RewardResultWindowBgType.FADE,
				);
				window.init(this.rewards);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);

				this.windowViewSystem.showWindow(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowNotCompletedTransactionsRewardsWindowChainable implements ChainableObject {
	private rewards: AbstractReward[];
	private groups: RewardGroups;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		rewardsData: {
			rewards?: AbstractReward[];
			groups?: RewardGroups;
		},
	) {
		this.rewards = rewardsData?.rewards || [];
		this.groups = rewardsData?.groups || [];
	}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (this.rewards.length > 0) {
			result = new Promise(resolve => {
				const window = this.windowFactory.createRewardResultWindow(
					false,
					undefined,
					RewardResultWindowBgType.SUMMON,
				);
				window.init(this.rewards, this.groups);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);

				this.windowViewSystem.showWindow(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowLevelStartWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: LevelWindowViewFactory,
		private readonly levelStartDialogShowed: boolean,
		private readonly currentLevel: number,
		private readonly onShowedCallback: () => void,
	) {}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (!this.levelStartDialogShowed && this.currentLevel !== 1) {
			result = new Promise(resolve => {
				const window = this.windowFactory.createLevelStartWindow();
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.showWindow(window);
				this.onShowedCallback();
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowTimedQuestDialogWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly dialogsFactory: DialogsFactory,
		private readonly timedQuestLinesModel: TimedQuestLinesModel,
	) {}

	public start(): Promise<void> {
		const timedQuestData = this.timedQuestLinesModel.getPendingQuestData();
		const { dialogKey } = timedQuestData;

		let result: Promise<void>;
		if (this.dialogsFactory.isDialogCreatable(dialogKey)) {
			result = new Promise(resolve => {
				const model = this.dialogsFactory.createDialog(dialogKey);
				const questTimeFormatted = Math.round(timedQuestData.time / 3600 * 10) / 10;
				model.addReplaceCharacter('{{time}}', String(questTimeFormatted))
					.addReplaceCharacter('{{value}}', timedQuestData.completeValue);

				const window = this.windowFactory.createDialogWindow(model);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error(`Failed creating timed quest dialog with ID='${dialogKey}'`);
			}
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowLevelStartDialogWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly levelStartDialogShowed: boolean,
		private readonly dialogsEnabled: boolean,
		private readonly dialogsFactory: DialogsFactory,
		private readonly onFinishedCallback?: () => void,
	) {}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (this.dialogsEnabled && !this.levelStartDialogShowed && this.dialogsFactory.hasStartDialog()) {
			result = new Promise(resolve => {
				const dialogModel = this.dialogsFactory.createStartDialog();

				const window = this.windowFactory.createDialogWindow(dialogModel);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					this.onFinishedCallback?.();
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowEventProgressDialogWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly dialogsFactory: DialogsFactory,
		private readonly eventLevelModel: EventLevelModel,
		private readonly dialogsEnabled: boolean,
	) {}

	public start(): Promise<void> {
		const currentProgress = this.eventLevelModel.getCompletedGoalCount();
		let result: Promise<void>;
		if (this.dialogsEnabled && this.dialogsFactory.hasProgressDialog(currentProgress)) {
			result = new Promise(resolve => {
				const dialogModel = this.dialogsFactory.createProgressDialog(currentProgress);

				const window = this.windowFactory.createDialogWindow(dialogModel);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowLevelProgressDialogWindowChainable implements ChainableObject {
	private readonly dialogsFactory: DialogsFactory;
	private readonly levelModel: LevelModel;
	private readonly dialogsEnabled: boolean;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		dialogsFactory: DialogsFactory,
		levelModel: LevelModel,
		dialogsEnabled: boolean,
	) {
		this.dialogsEnabled = dialogsEnabled;
		this.dialogsFactory = dialogsFactory;
		this.levelModel = levelModel;
	}

	public start(): Promise<void> {
		const currentProgress = this.levelModel.getCurrentProgress();
		let result: Promise<void>;
		if (this.dialogsEnabled && this.dialogsFactory.hasProgressDialog(currentProgress)) {
			result = new Promise(resolve => {
				const dialogModel = this.dialogsFactory.createProgressDialog(currentProgress);

				const window = this.windowFactory.createDialogWindow(dialogModel);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowLevelEndDialogWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly dialogsFactory: DialogsFactory,
	) {}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (this.dialogsFactory.hasEndDialog()) {
			result = new Promise(resolve => {
				const dialogModel = this.dialogsFactory.createEndDialog();

				const window = this.windowFactory.createDialogWindow(dialogModel);
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowBankOfferWindowChainable implements ChainableObject {
	private readonly bankOfferModelKey: string;
	private readonly bankOfferWindowOrigin: BankOfferWindowOriginType;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		bankOfferModelKey: string,
		bankOfferWindowOrigin: BankOfferWindowOriginType,
	) {
		this.bankOfferModelKey = bankOfferModelKey;
		this.bankOfferWindowOrigin = bankOfferWindowOrigin;
	}

	public start(): Promise<void> {
		return new Promise(resolve => {
			const window = this.windowFactory.createBankOfferWindow(
				this.bankOfferModelKey,
				this.bankOfferWindowOrigin,
			);
			window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
				resolve();
			}, this);
			this.windowViewSystem.addWindowPending(window);
		});
	}
}

export class ShowBackToGameWindowChainable implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly hasBackToGameReward: boolean,
	) {}

	public start(): Promise<void> {
		let result: Promise<void>;
		if (this.hasBackToGameReward) {
			result = new Promise(resolve => {
				const window = this.windowFactory.createBackToGameWindow();
				window.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
					resolve();
				}, this);
				this.windowViewSystem.addWindowPending(window);
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

abstract class ShowTutorialStepWindowChainableBase implements ChainableObject {
	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		protected readonly tutorialStepsEmitter: TutorialStepsEmitter,
		protected readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetBaseDispatcher,
	) {}

	protected abstract getEmitTutorialStep(): TutorialStepBaseModel | undefined;

	public start(): Promise<void> {
		const model = this.getEmitTutorialStep();
		let result: Promise<void>;
		if (model) {
			result = new Promise(resolve => {
				try {
					const target = this.tutorialStepsViewTargetDispatcher.dispatchViewTarget(model);

					const tutorialStepWindow = this.windowFactory.createTutorialStepWindow(
						model,
						target,
					);
					tutorialStepWindow.once(WindowBaseView.EVENT_WINDOW_CLOSED, () => {
						resolve();
					}, this);

					const targetType = model.getTargetType();

					if (MAIN_WINDOW_TARGET_TYPES.includes(targetType)) {
						this.windowViewSystem.addWindowPending(tutorialStepWindow);
					} else {
						this.windowViewSystem.showWindow(tutorialStepWindow);
					}
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(`Tutorial step ${model.getKey()} failed: ${e.message}`);

					resolve();
				}
			});
		} else {
			result = Promise.resolve();
		}
		return result;
	}
}

export class ShowTutorialStepEventStartWindowChainable extends ShowTutorialStepWindowChainableBase {
	protected getEmitTutorialStep(): TutorialStepBaseModel | undefined {
		return this.tutorialStepsEmitter.tryEmitTutorialStepEventStart();
	}
}

export class ShowTutorialStepLevelStartWindowChainable extends ShowTutorialStepWindowChainableBase {
	protected getEmitTutorialStep(): TutorialStepBaseModel | undefined {
		return this.tutorialStepsEmitter.tryEmitTutorialStepLevelStart();
	}
}

export class ShowTutorialStepGetQuestWindowChainable extends ShowTutorialStepWindowChainableBase {
	protected getEmitTutorialStep(): TutorialStepBaseModel | undefined {
		return this.tutorialStepsEmitter.tryEmitTutorialStepGetQuest();
	}
}

export class ShowTutorialStepQuestCompleteWindowChainable extends ShowTutorialStepWindowChainableBase {
	protected getEmitTutorialStep(): TutorialStepBaseModel | undefined {
		return this.tutorialStepsEmitter.tryEmitTutorialStepQuestComplete();
	}
}
