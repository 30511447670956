import { AssetsStorage } from '@main/AssetsStorage';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from './BankParticleConfig';

export enum BandleParticlesType {
	PURPLE = 'monthly_bg1',
	BLUE = 'monthly_bg2',
	RED = 'monthly_bg3',
	GOLD = 'monthly_bg4',
}

export class BankBundlesParticleAnimation extends PIXI.Container {
	private readonly animationEmitters: Emitter[];
	private readonly animationsContainer: PIXI.Container;

	constructor(
		viewParam: string,
	) {
		super();
		this.animationEmitters = [];
		this.animationsContainer = new PIXI.Container();
		switch (viewParam) {
			case BandleParticlesType.PURPLE: {
				const glowEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['business_glow4']],
					BankParticleConfig.getMonthlyBundlePurpleGlow(),
				);
				glowEmitter.autoUpdate = true;

				const flashEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['summon_flash4']],
					BankParticleConfig.getMonthlyBundlePurpleFlash(),
				);
				flashEmitter.autoUpdate = true;

				this.animationEmitters.push(glowEmitter, flashEmitter);

				this.addChild(
					this.animationsContainer,
				);
				break;
			}
			case BandleParticlesType.BLUE: {
				const glowEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['business_apperance']],
					BankParticleConfig.getMonthlyBundleBlueGlow(),
				);
				glowEmitter.autoUpdate = true;

				const flashEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['party_button_star']],
					BankParticleConfig.getMonthlyBundleBlueFlash(),
				);
				flashEmitter.autoUpdate = true;

				const circleEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['business_apperance']],
					BankParticleConfig.getMonthlyBundleBlueCircle(),
				);
				circleEmitter.autoUpdate = true;

				this.animationEmitters.push(glowEmitter, flashEmitter, circleEmitter);

				this.addChild(
					this.animationsContainer,
				);
				break;
			}
			case BandleParticlesType.RED: {
				const glowEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['red_dot_glow']],
					BankParticleConfig.getMonthlyBundleRedGlow(),
				);
				glowEmitter.autoUpdate = true;

				const flashEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['summon_card_glow2']],
					BankParticleConfig.getMonthlyBundleRedFlash(),
				);
				flashEmitter.autoUpdate = true;

				this.animationEmitters.push(glowEmitter, flashEmitter);

				this.addChild(
					this.animationsContainer,
				);
				break;
			}
			case BandleParticlesType.GOLD: {
				const glowEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['summon_flare1']],
					BankParticleConfig.getMonthlyBundleGoldGlow(),
				);
				glowEmitter.autoUpdate = true;

				const flashEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['summon_flare1']],
					BankParticleConfig.getMonthlyBundleGoldFlash(),
				);
				flashEmitter.autoUpdate = true;

				const circleEmitter = new Emitter(
					this.animationsContainer,
					[AssetsStorage.getAtlas('fxAtlas')['summon_flash4']],
					BankParticleConfig.getMonthlyBundleGoldCircle(),
				);
				circleEmitter.autoUpdate = true;

				this.animationEmitters.push(glowEmitter, flashEmitter, circleEmitter);

				this.addChild(
					this.animationsContainer,
				);
				break;
			}
			default:
				throw Error(`Unsupported viewParametr: ${viewParam}`);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
