import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { NumberUtils } from '@src/utils/NumberUtils';
import { DailyRewardsModel } from '@models/DailyRewardsModel';

export class ButtonDailyView extends ButtonBaseView {
	private readonly infoIcon: PIXI.Sprite;
	private readonly timer: PIXI.extras.BitmapText;
	private readonly timerPanel: PIXI.Graphics;
	private readonly dailyRewardsModel: DailyRewardsModel;
	private readonly tickerTimer: PIXI.ticker.Ticker;
	private readonly buttonNewsBg: PIXI.Sprite;
	private unlocked: boolean;

	constructor(
		dailyRewardsModel: DailyRewardsModel,
	) {
		const clickArea = new PIXI.Graphics();
		clickArea.beginFill(0x000000, 1);
		clickArea.drawCircle(0, 0, 42);
		clickArea.endFill();

		super(clickArea);

		this.dailyRewardsModel = dailyRewardsModel;

		this.unlocked = true;

		this.buttonNewsBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['circle_btn']);
		this.buttonNewsBg.interactive = false;

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['daily_btn_icon']);

		this.timerPanel = new PIXI.Graphics();
		this.timerPanel.beginFill(0x000000);
		this.timerPanel.drawPolygon([
			new PIXI.Point(46, -11),
			new PIXI.Point(41, 11),
			new PIXI.Point(-46, 11),
			new PIXI.Point(-41, -11),
		]);
		this.timerPanel.y = 37;

		this.timer = new PIXI.extras.BitmapText('', { font: '18px wendyOneShadowBold', align: 'center', tint: 0xffe16c });
		this.timer.y = 37;
		this.timer.anchor = 0.5;

		this.tickerTimer = PIXI.ticker.shared;
		this.tickerTimer.add(this.updateTimer, this);

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.setTransform(34, -27, 0.5, 0.5);

		this.addChild(
			this.buttonNewsBg as PIXI.DisplayObject,
			icon,
			this.timerPanel,
			this.timer,
			this.infoIcon,
		);
	}

	public setLocked(value: boolean): void {
		this.unlocked = !value;
		this.interactive = !value;

		if (this.unlocked) {
			this.buttonNewsBg.tint = 0xFFFFFF;

			if (!this.listeners('pointertap').includes(this.onPointerTap)) {
				this.on('pointertap', this.onPointerTap, this);
			}
		} else {
			this.buttonNewsBg.tint = 0x7F7F7F;
			this.off('pointertap', this.onPointerTap, this);

			this.timer.visible = false;
			this.timerPanel.visible = false;
			this.infoIcon.visible = false;
		}
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	private updateTimer(): void {
		const time = this.dailyRewardsModel.getNextRewardPeriod();
		this.timer.text = NumberUtils.formatCountdownTime(time);

		if (this.unlocked) {
			this.setRewardAvailable(this.dailyRewardsModel.isRewardClaimable());
		}
	}

	private setRewardAvailable(value: boolean): void {
		this.infoIcon.visible = value;
		this.timer.visible = !value;
		this.timerPanel.visible = !value;
	}

	public destroy(): void {
		this.tickerTimer.remove(this.updateTimer, this);
		super.destroy();
	}
}
