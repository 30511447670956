import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter54007View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54007View';
import { EventCharacter54008View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54008View';
import { EventCharacter54009View } from '@views/characters/events/eventLevelWeekDay4/EventCharacter54009View';

export class EventBusiness54003View extends BusinessBaseView {
	constructor() {
		const eventCharacter54007View = new EventCharacter54007View();
		const eventCharacter54008View = new EventCharacter54008View();
		const eventCharacter54009View = new EventCharacter54009View();

		super('business54003', [eventCharacter54007View, eventCharacter54008View, eventCharacter54009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter54009View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter54007View as PIXI.DisplayObject,
			eventCharacter54008View,
		);
	}
}
