export class MaintenanceModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_MAINTENANCE_TIME_UPDATE: symbol = Symbol();
	public static readonly EVENT_MAINTENANCE_UPCOMING: symbol = Symbol();
	public static readonly EVENT_MAINTENANCE_UPCOMING_END: symbol = Symbol();

	private currentMaintenanceTime: number;
	private timeBeforeMaintenance: number;
	private upcomingMaintenance: boolean;

	constructor(
		private readonly maintenanceShowTime: number,
	) {
		super();

		this.currentMaintenanceTime = Infinity;
		this.upcomingMaintenance = false;
	}

	public setCurrentMaintenanceTime(value: number): void {
		this.currentMaintenanceTime = value;

		this.emit(MaintenanceModel.EVENT_MAINTENANCE_TIME_UPDATE);
	}

	public setCurrentTimeBeforeMaintenance(value: number): void {
		this.timeBeforeMaintenance = value;

		if (this.timeBeforeMaintenance > 0 && !this.upcomingMaintenance) {
			this.upcomingMaintenance = true;
			this.emit(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING);
		} else if (this.timeBeforeMaintenance <= 0 && this.upcomingMaintenance) {
			this.upcomingMaintenance = false;
			this.emit(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING_END);
		}
	}

	public isUpcomingMaintenance(): boolean {
		return this.upcomingMaintenance;
	}

	public getTimeBeforeMaintenance(): number {
		return this.timeBeforeMaintenance;
	}

	public getCurrentMaintenanceTime(): number {
		return this.currentMaintenanceTime;
	}

	public getMaintenanceShowTime(): number {
		return this.maintenanceShowTime;
	}
}
