import { TimeTravel } from '@src/types/TimeTravelTypes';
import { LevelStartBaseTime } from '@src/types/LevelStartBaseTimeTypes';
import { LevelBaseConfig } from './LevelBaseConfig';
import { ContentUnlockLevelType, ContentUnlockLevelValue } from '@src/types/ContentUnlockLevelTypes';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export type ContentUnlockLevelConfig = {
	type: ContentUnlockLevelType;
	value: ContentUnlockLevelValue;
};

export class LevelConfig extends LevelBaseConfig implements ConfigSetElement {
	public static readonly FIELD_QUEST_LINE_BASE_PREFIX: string = 'quest_line';
	public static readonly FIELD_SEASON_ID: string = 'season_id';
	public static readonly FIELD_LEVEL_ID: string = 'level_id';
	public static readonly FIELD_PARTY_ID: string = 'party_id';
	public static readonly FIELD_CONTENT_UNLOCK: string = 'content_unlock';
	public static readonly FIELD_TIME_TRAVEL: string = 'time_travel';
	public static readonly FIELD_LEVEL_CHALLENGE_TIME: string = 'level_challenge_time';
	public static readonly FIELD_TIME_BASE: string = 'time_base';

	private readonly key: string;
	private readonly seasonId: number;
	private readonly farewellPartyId: string;
	private readonly timeTravel: TimeTravel;
	private readonly challengeTimes: number[];
	private readonly baseTime: LevelStartBaseTime;
	private readonly contentUnlockConfigs: ContentUnlockLevelConfig[];

	public static parseQuestLineIndex(questLineKey: string): number {
		return LevelBaseConfig.parseQuestLineIndex(
			LevelConfig.FIELD_QUEST_LINE_BASE_PREFIX,
			questLineKey,
		);
	}

	private static constructBaseTime(config: { [key: string]: string }): LevelStartBaseTime {
		const raw: string = config[LevelConfig.FIELD_TIME_BASE].trim();

		const date = raw.split(';')[0].split('.');
		const days = Number(date[0].trim());
		const month = Number(date[1].trim());
		const year = Number(date[2].trim());

		const time = raw.split(';')[1].split(':');
		const hours = Number(time[0].trim());
		const minutes = Number(time[1].trim());

		return {
			days,
			month,
			year,
			hours,
			minutes,
		};
	}

	constructor(config: { [key: string]: string }) {
		super(config);

		this.key = config[LevelConfig.FIELD_LEVEL_ID].trim();

		this.seasonId = parseInt(config[LevelConfig.FIELD_SEASON_ID], 10);

		this.farewellPartyId = config[LevelConfig.FIELD_PARTY_ID].trim();

		this.contentUnlockConfigs = [];
		const unlockPairs: string[] = config[LevelConfig.FIELD_CONTENT_UNLOCK]
			.trim()
			.split(',')
			.map((s: string) => s.trim())
			.filter((s: string) => s.length > 0);

		unlockPairs.forEach((pair) => {
			const unlockType = pair.split(':')[0].trim();
			const unlockValue = pair.split(':')[1].trim();

			this.contentUnlockConfigs.push({ type: unlockType, value: unlockValue });
		});

		const timeTravelSplit = config[LevelConfig.FIELD_TIME_TRAVEL].split(';');
		const additionalDays = Number(timeTravelSplit[0].trim());
		const hours = Number(timeTravelSplit[1].split(':')[0].trim());
		const minutes = Number(timeTravelSplit[1].split(':')[1].trim());

		this.timeTravel = { additionalDays, hours, minutes };

		const levelChallengeTimesString = config[LevelConfig.FIELD_LEVEL_CHALLENGE_TIME]?.trim();
		if (levelChallengeTimesString) {
			this.challengeTimes = levelChallengeTimesString.split(',').map((str: string) => Number(str));
		}

		this.baseTime = LevelConfig.constructBaseTime(config);

		this.parseQuestLines(config);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getKeyQuestLine(i: number): string {
		return `${LevelConfig.FIELD_QUEST_LINE_BASE_PREFIX}${i}`;
	}

	public getKey(): string {
		return this.key;
	}

	public getSeason(): number {
		return this.seasonId;
	}

	public getFarewellPartyId(): string {
		return this.farewellPartyId;
	}

	public getContentUnlockConfigs(): ContentUnlockLevelConfig[] {
		return this.contentUnlockConfigs;
	}

	public getTimeTravel(): TimeTravel {
		return this.timeTravel;
	}

	public hasChallenges(): boolean {
		return Boolean(this.challengeTimes);
	}

	public getChallengeTimes(): number[] {
		return this.challengeTimes;
	}

	public getBaseTime(): LevelStartBaseTime {
		return this.baseTime;
	}
}
