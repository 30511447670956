import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { ModelHelper } from '@models/ModelHelper';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { SummonModel } from '@models/SummonModel';

export class ButtonCollectionView extends ButtonBaseView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly galleryVideoModels: GalleryVideoModel[];
	private readonly summonModelsPool: SummonModelsPool;
	private readonly epicQuestCollectionsModels: EpicQuestCollectionsModel[];

	private readonly label: PIXI.extras.BitmapText;
	private readonly infoIcon: PIXI.Sprite;

	private flyTextLabel: FlyBitmapText;
	private unlocked: boolean;
	private unlocksOnLevel: number;

	constructor(
		galleryVideoModels: GalleryVideoModel[],
		epicQuestCollectionsModels: EpicQuestCollectionsModel[],
		summonModelsPool: SummonModelsPool,
	) {
		const buttonBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['shop_collection_button']);
		super(buttonBg);

		const buttonIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_collection']);
		buttonIcon.position.set(-2, -2);

		this.galleryVideoModels = galleryVideoModels;

		this.galleryVideoModels.forEach((model) => {
			model.on(GalleryVideoModel.EVENT_OPENED, this.updateInfoIconVisible, this);
			model.on(GalleryVideoModel.EVENT_OLD, this.updateInfoIconVisible, this);
		});

		this.epicQuestCollectionsModels = epicQuestCollectionsModels;
		this.epicQuestCollectionsModels.forEach((model) => {
			model.on(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.updateInfoIconVisible, this);
			model.on(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.updateInfoIconVisible, this);
		});

		this.summonModelsPool = summonModelsPool;
		this.summonModelsPool.on(SummonModelsPool.EVENT_MODELS_UPDATED, this.onSummonPoolUpdate, this);

		this.unlocked = true;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		this.infoIcon = new PIXI.Sprite(collectionAtlas['can_promote_icon']);
		this.infoIcon.position.set(97, -68);
		this.infoIcon.visible = false;
		this.infoIcon.scale.set(0.7);

		this.label = new PIXI.extras.BitmapText('', { font: '36px wendyOneShadowBold', align: 'center' });
		this.label.y = 112;
		this.label.anchor = 0.5;

		this.addChild(
			buttonIcon,
			this.label,
			this.infoIcon as PIXI.DisplayObject,
		);

		this.updateText();
		this.updateInfoIconVisible();
	}

	public showLocked(unlocksOnLevel: number): void {
		this.unlocksOnLevel = unlocksOnLevel;

		(this.buttonBg as PIXI.Sprite).tint = 0x7F7F7F;
		this.label.tint = 0x7F7F7F;

		this.off('pointertap', this.onPointerTap, this);
	}

	public showUnlocked(): void {
		(this.buttonBg as PIXI.Sprite).tint = 0xFFFFFF;
		this.label.tint = 0xFFFFFF;

		if (this.listeners('pointertap').includes(this.onPointerTap)) {
			this.off('pointertap', this.onPointerTap, this);
		}
		this.on('pointertap', this.onPointerTap, this);
	}

	public setLocked(value: boolean): void {
		this.unlocked = !value;
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	protected onPointerDown(e: PIXI.interaction.InteractionEvent): void {
		super.onPointerDown(e);

		if (!this.unlocked && !this.flyTextLabel) {
			let stringLabel = this.localizationStorage.getLocalizedString('#label_unlocks_on_level');
			stringLabel = stringLabel.replace('{{value}}', this.unlocksOnLevel.toString());

			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.y = -20;
			this.addChild(this.flyTextLabel);
		}
	}

	private updateText(): void {
		this.label.text = this.localizationStorage.getLocalizedString('#gameUI_GirlsButton');
	}

	private updateInfoIconVisible(): void {
		this.infoIcon.visible = ModelHelper.canShowCollectionTabInfoIconGallery(this.galleryVideoModels)
			|| ModelHelper.canShowCollectionTabInfoIconEpicQuests(this.epicQuestCollectionsModels)
			|| ModelHelper.canShowCollectionTabInfoIconSummon(this.summonModelsPool.getModelsList());
	}

	private onSummonPoolUpdate(): void {
		this.summonModelsPool.getModelsList().forEach((model: SummonModel) => {
			if (model.isFreePrice() && !model.listeners(SummonModel.EVENT_SUMMON_AVAILABLE).includes(this.updateInfoIconVisible)) {
				model.on(SummonModel.EVENT_SUMMON_AVAILABLE, this.updateInfoIconVisible, this);
			}
		});

		this.updateInfoIconVisible();
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.galleryVideoModels.forEach((model) => {
			model.off(GalleryVideoModel.EVENT_OPENED, this.updateInfoIconVisible, this);
			model.off(GalleryVideoModel.EVENT_OLD, this.updateInfoIconVisible, this);
		});

		this.epicQuestCollectionsModels.forEach((model) => {
			model.off(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, this.updateInfoIconVisible, this);
			model.off(EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED, this.updateInfoIconVisible, this);
		});

		this.summonModelsPool.off(SummonModelsPool.EVENT_MODELS_UPDATED, this.updateInfoIconVisible, this);
		this.summonModelsPool.getModelsList().forEach(x => x.off(SummonModel.EVENT_SUMMON_AVAILABLE, this.updateInfoIconVisible, this));
		super.destroy();
	}
}
