import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';
import { Emitter } from 'pixi-particles';
import { FarewellPartyParticlesConfig } from '@views/windows/farewellParty/FarewellPartyParticlesConfig';

export class GotoNextLevelButtonView extends ButtonBaseView {
	private static readonly LOOTBOX_TEXTURE_FOR_PLACE: string[] = ['legendary_lootbox_main', 'epic_lootbox_main', 'rare_lootbox_main', 'common_lootbox_main'];
	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly tweenGroup: TWEEN.Group;
	private readonly ticker: PIXI.ticker.Ticker;
	private delayAnimationTimeout: NodeJS.Timeout;

	private readonly textLabel: SizeableBitmapText;
	private readonly lockedTextLabel: SizeableBitmapText;
	private readonly interactiveArea: PIXI.Graphics;
	private readonly icon: PIXI.Sprite;

	constructor() {
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		bg.width = 312;
		bg.height = 64;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		super(bg);

		this.icon = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['legendary_lootbox_main']);
		this.icon.position.set(112, -9);
		this.icon.rotation = Math.PI / 180 * -8;
		this.icon.scale.set(0.41);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		this.animationEmitters = [];
		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.textLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#goto_next_level_button'),
			210,
			{ font: '26px wendyOneShadowBold', align: 'center' },
		);
		this.textLabel.anchor = 0.5;
		this.textLabel.position.set(-32, -4);

		this.lockedTextLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#go_next_level_locked'),
			270,
			{ font: '26px wendyOneShadowBold', align: 'center' },
		);
		this.lockedTextLabel.anchor = 0.5;
		this.lockedTextLabel.position.set(0, -4);
		this.lockedTextLabel.visible = false;

		const hitArea = new PIXI.Rectangle(
			-bg.width / 2,
			-bg.height / 2,
			bg.width,
			bg.height,
		);
		this.hitArea = hitArea;

		// Create interactive area for tutorial blackout
		this.interactiveArea = new PIXI.Graphics();
		this.interactiveArea.beginFill(0, 0);
		this.interactiveArea.drawRect(hitArea.x, hitArea.y, hitArea.width, hitArea.height);
		this.interactiveArea.endFill();

		this.addChild(
			this.interactiveArea,
			this.textLabel,
			this.lockedTextLabel,
			this.icon as PIXI.DisplayObject,
		);
	}

	private update(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	private updateText(): void {
		this.lockedTextLabel.text = this.localizationStorage.getLocalizedString('#go_next_level_locked');
		this.textLabel.text = this.localizationStorage.getLocalizedString('#goto_next_level_button');
	}

	public show(place?: number): void {
		this.updateIcon(place);

		this.visible = true;
		this.alpha = 0;
		this.scale.set(0);

		new TWEEN.Tween(this, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.easing(TWEEN.Easing.Back.In)
			.start();

		const buttonScaleBack = new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 150)
			.easing(TWEEN.Easing.Linear.None);

		new TWEEN.Tween(this.scale, this.tweenGroup)
			.to({ x: 1.1, y: 1.1 }, 350)
			.easing(TWEEN.Easing.Cubic.In)
			.chain(buttonScaleBack)
			.start();

		const flareEmitter = new Emitter(
			this,
			[this.fxAtlas['party_button_flare']],
			FarewellPartyParticlesConfig.getFlare(),
		);
		this.animationEmitters.push(flareEmitter);
		flareEmitter.playOnceAndDestroy();

		const starsEmitter = new Emitter(
			this,
			[this.fxAtlas['party_button_star']],
			FarewellPartyParticlesConfig.getStars(),
		);
		this.animationEmitters.push(starsEmitter);
		starsEmitter.playOnceAndDestroy();

		const glowSprite = new PIXI.Sprite(this.fxAtlas['party_button_glow4']);
		this.addChild(glowSprite);
		glowSprite.blendMode = PIXI.BLEND_MODES.ADD;
		glowSprite.scale.set(0);
		glowSprite.alpha = 0;

		const glowSprite2 = new PIXI.Sprite(this.fxAtlas['party_button_glow3']);
		this.addChild(glowSprite2);
		glowSprite2.blendMode = PIXI.BLEND_MODES.ADD;
		glowSprite2.scale.set(0);
		glowSprite2.alpha = 0;

		const glowFadeOut = new TWEEN.Tween({ alpha: 1 }, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.onUpdate((tweenTarget) => {
				glowSprite.alpha = tweenTarget.alpha;
				glowSprite2.alpha = tweenTarget.alpha;
			})
			.easing(TWEEN.Easing.Cubic.Out);

		new TWEEN.Tween({ alpha: 0 }, this.tweenGroup)
			.to({ alpha: 1 }, 500)
			.onUpdate((tweenTarget) => {
				glowSprite.alpha = tweenTarget.alpha;
				glowSprite2.alpha = tweenTarget.alpha;
			})
			.easing(TWEEN.Easing.Cubic.In)
			.chain(glowFadeOut)
			.start();

		new TWEEN.Tween({ scale: 1 }, this.tweenGroup)
			.to({ scale: 1.7 }, 500)
			.onUpdate((tweenTarget) => {
				glowSprite.scale.set(tweenTarget.scale);
				glowSprite2.scale.set(tweenTarget.scale);
			})
			.easing(TWEEN.Easing.Cubic.In)
			.start();

		this.delayAnimationTimeout = setTimeout(() => {
			new TWEEN.Tween(this.scale, this.tweenGroup)
				.to({ x: 1.05, y: 1.05 }, 350)
				.easing(TWEEN.Easing.Sinusoidal.InOut)
				.repeat(3)
				.yoyo(true)
				.start();

			const movingGlow = new PIXI.Sprite(this.fxAtlas['party_button_glow1']);
			movingGlow.scale.set(0.2);
			movingGlow.alpha = 0.48;
			movingGlow.blendMode = PIXI.BLEND_MODES.ADD;
			movingGlow.position.set(-133, -3);
			this.addChildAt(movingGlow, 1);
			new TWEEN.Tween(movingGlow, this.tweenGroup)
				.to({ x: 133 }, 800)
				.easing(TWEEN.Easing.Sinusoidal.Out)
				.onComplete(() => {
					this.removeChild(movingGlow);
				})
				.start();
		}, 3000);
	}

	private updateIcon(place: number = 0): void {
		const key: string = GotoNextLevelButtonView.LOOTBOX_TEXTURE_FOR_PLACE[place];
		this.icon.texture = AssetsStorage.getAtlas('lootboxAtlas')[key];
	}

	public setLocked(): void {
		this.interactive = false;
		this.icon.visible = false;
		this.textLabel.visible = false;
		this.lockedTextLabel.visible = true;
	}

	public getInteractiveArea(): PIXI.Container {
		return this.interactiveArea;
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		this.ticker.remove(this.update, this);

		this.tweenGroup.removeAll();

		if (this.delayAnimationTimeout) {
			clearTimeout(this.delayAnimationTimeout);
		}

		super.destroy();
	}
}
