import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { CardRarity } from '@src/types/CardRarities';

export class LootboxConfig implements ConfigSetElement {
	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_RARITY: string = 'rarity';

	private readonly key: string;
	private readonly rarity: CardRarity;

	constructor(config: { [key: string]: string }) {
		this.key = config[LootboxConfig.FIELD_KEY].trim();
		this.rarity = config[LootboxConfig.FIELD_RARITY].trim() as CardRarity;
	}

	public getKey(): string {
		return this.key;
	}

	public getCardRarity(): CardRarity {
		return this.rarity;
	}
}
