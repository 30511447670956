import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { GameProfileModel } from '@models/GameProfileModel';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import LocalizationStorage from '@main/LocalizationStorage';
import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { EmailConfirmationWindowView } from './EmailConfirmationWindowView';
import { PasswordRecoveryWindowView } from './PasswordRecoveryWindowView';
import { EmailAreaInputView } from '@views/components/input/EmailAreaInputView';
import { PasswordInputView } from '@views/components/input/PasswordInputView';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class LoginWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_LOGIN_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_REGISTER_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_RECOVER_CLICK: symbol = Symbol();

	public static readonly EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK: symbol = Symbol();

	private readonly gameProfileModel: GameProfileModel;
	private readonly localizationStorage: LocalizationStorage;

	private readonly emailTextArea: TextAreaInputView;
	private readonly passwordTextArea: TextAreaInputView;
	private readonly buttonLogin: ButtonWithLabelBaseView;
	private readonly closeButton: ButtonBaseView;

	private emailConfirmationWindow: EmailConfirmationWindowView;
	private passwordRecoveryWindow: PasswordRecoveryWindowView;

	constructor(
		gameProfileModel: GameProfileModel,
	) {
		super(0.8);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.gameProfileModel = gameProfileModel;
		this.gameProfileModel.once(GameProfileModel.EVENT_LOGGED_IN, this.onClose, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_LOGIN_EMAIL_NOT_CONFIRMED, this.onLoginEmailNotConfirmed, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_LOGIN_NOT_FOUND, this.onLoginLoginNotFound, this);
		this.gameProfileModel.on(GameProfileModel.EVENT_LOGIN_WRONG_PASSWORD, this.onLoginWrongPassword, this);

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 776;
		bg.height = 505;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(340, -200);
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		const whiteBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_2'], 4, 4, 4, 4);
		whiteBg.width = 720;
		whiteBg.height = 215;
		whiteBg.pivot.set(whiteBg.width / 2, whiteBg.height / 2);
		whiteBg.y = -44;

		const fadeBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_rounded_rect'], 5, 5, 5, 5);
		fadeBg.width = 774;
		fadeBg.height = 100;
		fadeBg.pivot.set(fadeBg.width / 2, fadeBg.height / 2);
		fadeBg.position.y = 283;
		fadeBg.alpha = 0.8;
		fadeBg.interactive = true;

		const stringTitle = this.localizationStorage.getLocalizedString('#login_window_title');
		const labelTitle = new PIXI.extras.BitmapText(
			stringTitle,
			{ font: '40px wendyOneShadowBold' },
		);
		labelTitle.anchor = 0.5;
		labelTitle.y = -202;

		const buttonGotoRegister = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.YELLOW, 345, 95),
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
			466,
		);
		buttonGotoRegister.setTextLabel(this.localizationStorage.getLocalizedString('#login_window_button_registration'));
		buttonGotoRegister.position.set(-187, 153);
		buttonGotoRegister.on(ButtonBaseView.EVENT_CLICK, this.onButtonGotoRegisterClick, this);

		this.buttonLogin = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 345, 95),
			{ font: { size: 34, name: 'wendyOneShadowBold' } },
			466,
		);
		this.buttonLogin.setTextLabel(this.localizationStorage.getLocalizedString('#login_window_button_login'));
		this.buttonLogin.on(ButtonBaseView.EVENT_CLICK, this.onButtonLoginClick, this);
		this.buttonLogin.position.set(187, 153);

		this.emailTextArea = new EmailAreaInputView({
			bgWidth: 620,
		});
		this.emailTextArea.y = -80;
		this.emailTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#support_window_your_email_label'));

		this.passwordTextArea = new PasswordInputView({
			bgWidth: 620,
			maxLength: GameProfileModel.INPUT_PASSWORD_MAX_LENGTH,
		});
		this.passwordTextArea.y = -10;
		this.passwordTextArea.setPlaceholder(this.localizationStorage.getLocalizedString('#registration_window_your_password_label'));

		const stringForgotPassword = this.localizationStorage.getLocalizedString('#forgot_password_label');
		const labelRecoverPassword = new PIXI.extras.BitmapText(
			stringForgotPassword,
			{ font: '24px wendyOneShadowBold', tint: 0x7c8a93 },
		);
		labelRecoverPassword.anchor = 0.5;
		labelRecoverPassword.y = 284;
		labelRecoverPassword.interactive = true;
		labelRecoverPassword.on('pointertap', this.onRecoverPasswordClick, this);

		labelRecoverPassword.hitArea = new PIXI.Rectangle(
			-labelRecoverPassword.width / 2 - 10,
			-labelRecoverPassword.height / 2 - 10,
			labelRecoverPassword.width + 20,
			labelRecoverPassword.height + 20,
		);

		const line = new PIXI.Graphics()
			.lineStyle(3, 0x7c8a93)
			.moveTo(-labelRecoverPassword.width / 2, 15)
			.lineTo(labelRecoverPassword.width / 2, 15)
			.closePath();
		labelRecoverPassword.addChild(line);

		this.mainContainer.addChild(
			fadeBg,
			bg,
			whiteBg,
			this.closeButton as PIXI.DisplayObject,
			labelTitle,
			buttonGotoRegister,
			this.buttonLogin,
			labelRecoverPassword,
			this.emailTextArea,
			this.passwordTextArea,
		);
	}

	private setEnabled(value: boolean): void {
		this.setCloseOnBlackoutClick(value);

		this.buttonLogin.interactive = value;
		this.buttonLogin.alpha = value ? 1 : 0.5;
		this.closeButton.interactive = value;
	}

	private onButtonGotoRegisterClick(): void {
		this.emit(LoginWindowView.EVENT_BUTTON_REGISTER_CLICK);
	}

	private onLoginEmailNotConfirmed(): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGGED_IN, this.onClose, this, true);

		this.startCloseAnimation();

		this.emailConfirmationWindow = new EmailConfirmationWindowView(
			this.gameProfileModel,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_BUTTON_CONFIRM_CLICK,
			(value: string) => this.emit(LoginWindowView.EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK, value),
			this,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_BUTTON_RESEND_CLICK,
			() => this.emit(LoginWindowView.EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK),
			this,
		);
		this.emailConfirmationWindow.on(
			EmailConfirmationWindowView.EVENT_WINDOW_CLOSED,
			() => this.onEmailConfirmationWindowClosed(),
			this,
		);
		this.emailConfirmationWindow.onShown();

		this.addChild(this.emailConfirmationWindow);
	}

	private onEmailConfirmationWindowClosed(): void {
		if (this.gameProfileModel.isLoggedIn()) {
			this.onClose(false);
		} else {
			this.setEnabled(true);
			this.startOpenAnimation();
		}
	}

	private onLoginLoginNotFound(): void {
		this.emailTextArea.setTextInvalidInput(this.localizationStorage.getLocalizedString('#error_login_not_found'));
		this.emailTextArea.onInvalidInput();
		this.setEnabled(true);
	}

	private onLoginWrongPassword(): void {
		this.passwordTextArea.setTextInvalidInput(this.localizationStorage.getLocalizedString('#error_wrong_password'));
		this.passwordTextArea.onInvalidInput();
		this.setEnabled(true);
	}

	private onButtonLoginClick(): void {
		const textEmail = this.emailTextArea.getText();
		const inputEmailOk = this.emailTextArea.isValid();

		const textPassword = this.passwordTextArea.getText().trim();
		const inputPasswordOk = this.passwordTextArea.isValid();

		if (!inputEmailOk) {
			this.emailTextArea.onInvalidInput();
		}

		if (inputEmailOk && inputPasswordOk) {
			this.setEnabled(false);
			this.emit(
				LoginWindowView.EVENT_BUTTON_LOGIN_CLICK,
				textEmail,
				textPassword,
			);
		}
	}

	private onRecoverPasswordClick(): void {
		this.startCloseAnimation();

		this.passwordRecoveryWindow = new PasswordRecoveryWindowView(
			this.gameProfileModel,
		);
		this.passwordRecoveryWindow.on(
			PasswordRecoveryWindowView.EVENT_BUTTON_RECOVER_CLICK,
			(email: string) => this.emit(LoginWindowView.EVENT_BUTTON_RECOVER_CLICK, email),
			this,
		);
		this.passwordRecoveryWindow.on(
			PasswordRecoveryWindowView.EVENT_WINDOW_CLOSED,
			() => { this.startOpenAnimation(); },
			this,
		);
		this.passwordRecoveryWindow.onShown();

		this.addChild(this.passwordRecoveryWindow);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGGED_IN, this.onClose, this, true);
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGIN_EMAIL_NOT_CONFIRMED, this.onLoginEmailNotConfirmed, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGIN_NOT_FOUND, this.onLoginLoginNotFound, this);
		this.gameProfileModel.off(GameProfileModel.EVENT_LOGIN_WRONG_PASSWORD, this.onLoginWrongPassword, this);
		super.destroy(options);
	}
}
