import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter253004View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253004View';
import { EventCharacter253005View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253005View';
import { EventCharacter253006View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253006View';

export class EventBusiness253002View extends BusinessBaseView {
	constructor() {
		const eventCharacter253004View = new EventCharacter253004View();
		const eventCharacter253005View = new EventCharacter253005View();
		const eventCharacter253006View = new EventCharacter253006View();

		super('business253002', [eventCharacter253004View, eventCharacter253005View, eventCharacter253006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter253004View as PIXI.DisplayObject,
			eventCharacter253005View,
			eventCharacter253006View,
		);
	}
}
