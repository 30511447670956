import { PromotableModel } from '@models/PromotableModel';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { CardRarities } from '@src/types/CardRarities';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';

export abstract class LevelStartCardBaseView<T extends PromotableModel> extends PIXI.Container {
	protected model: T;

	protected readonly localizationStorage: LocalizationStorage;

	private readonly nameLabel: SizeableBitmapText;
	private readonly rarityLabel: SizeableBitmapText;
	protected readonly stickerNewContainer: PIXI.Container;

	private tweenGlowRay: TWEEN.Tween;
	protected readonly fadeContainer: PIXI.Container;

	protected constructor(
		model: T,
	) {
		super();

		this.model = model;

		this.fadeContainer = new PIXI.Container();

		const colorMatrix = new PIXI.filters.ColorMatrixFilter();
		this.fadeContainer.filters = [colorMatrix];
		colorMatrix.brightness(0.7);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);


		this.stickerNewContainer = new PIXI.Container();
		this.stickerNewContainer.position.set(172, 327);

		const stickerNewBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['level_start_new_icon'], 6, 0, 0, 0);
		stickerNewBg.width = 200;
		stickerNewBg.height = 55;
		stickerNewBg.pivot.set(stickerNewBg.width / 2, stickerNewBg.height / 2);

		const textStickerNew = new MultiColoredTextField(
			{ font: '40px wendyOne', align: 'center' },
			200,
			55,
		);
		textStickerNew.anchor = 0.5;
		textStickerNew.text = this.localizationStorage.getLocalizedString('#video_sticker_new');

		this.stickerNewContainer.addChild(
			stickerNewBg,
			textStickerNew as PIXI.DisplayObject,
		);

		const cardRarity = model.getCardRarity();
		const rarityColor = CardRarities.getRarityColor(cardRarity);

		this.nameLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#${model.getKey()}_Name`),
			480,
			{ font: '50px wendyOneShadowBold', align: 'center', tint: rarityColor },
		);
		this.nameLabel.anchor = 0.5;
		this.nameLabel.y = -352;

		const rarityBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`rarity_color_${model.getCardRarity()}_panel`]);
		rarityBg.y = -426;

		this.rarityLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#hint_rarity_${cardRarity}`),
			210,
			{ font: '33px wendyOneShadowBold', align: 'center' },
		);
		this.rarityLabel.anchor = 0.5;
		this.rarityLabel.y = -426;

		this.fadeContainer.addChild(
			this.nameLabel,
			rarityBg as PIXI.DisplayObject,
			this.rarityLabel,
		);

		this.addChild(
			this.fadeContainer,
			this.stickerNewContainer,
		);
	}

	protected onLanguageChange(): void {
		this.nameLabel.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);
		this.rarityLabel.text = this.localizationStorage.getLocalizedString(`#hint_rarity_${this.model.getCardRarity()}`);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.model = null;

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		if (this.tweenGlowRay) {
			this.tweenGlowRay.stop();
			this.tweenGlowRay = null;
		}

		super.destroy(options);
	}
}
