import * as TWEEN from '@tweenjs/tween.js';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';

export class ShowNewCustomersView extends PIXI.Container {
	private static readonly SLIDE_UP_DURATION: number = 400;

	public show(amount: number): void {
		const newCustomersView = ShowNewCustomersView.createNewCustomersView(amount);

		const slideUpTween = new TWEEN.Tween(newCustomersView.position)
			.to({ x: newCustomersView.position.x, y: '-60' }, ShowNewCustomersView.SLIDE_UP_DURATION)
			.easing(TWEEN.Easing.Circular.Out)
			.start();

		const fadeOutTween = new TWEEN.Tween(newCustomersView)
			.to({ alpha: 0 }, 200)
			.delay(ShowNewCustomersView.SLIDE_UP_DURATION * 0.8)
			.onComplete(() => newCustomersView.destroy())
			.start();

		this.addChild(newCustomersView);
	}

	private static createNewCustomersView(amount: number): PIXI.DisplayObject {
		const result = new PIXI.Container();

		const textAmount = new SizeableBitmapText('', 200, { font: '35px wendyOneShadowBold', tint: 0xd1ff6d });
		textAmount.text = `+${amount.toString()}`;
		textAmount.anchor = 0.5;
		textAmount.x = -46;

		const textLevel = new SizeableBitmapText('', 200, { font: '18px wendyOneShadowBold', tint: 0xd1ff6d });
		textLevel.text = 'lvl';
		textLevel.anchor = new PIXI.Point(0, 0.5);
		textLevel.x = textAmount.x + textAmount.width / 2;
		textLevel.y = 5;

		result.addChild(
			textAmount as PIXI.DisplayObject,
			textLevel,
		);
		return result;
	}
}
