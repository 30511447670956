import { BaseValidator } from '../BaseValidator';

export class EmailDomainPartBadSymbolsValidator extends BaseValidator<string> {
	private static readonly REG_EXP = /[^a-z0-9-.]/;
	private matchResult: RegExpMatchArray;

	protected isValid(value: string): boolean {
		const domain = value.split('@')[1];
		this.matchResult = domain.match(EmailDomainPartBadSymbolsValidator.REG_EXP);
		return this.matchResult === null;
	}

	protected getErrorMessage(): string {
		let badSymbol: string = this.matchResult[0];
		if (badSymbol === ' ') {
			badSymbol = this.localizationStorage.getLocalizedString('#space');
		}
		return super.getErrorMessage()
			.replace('{value}', badSymbol);
	}
}
