import { GameProfileModel } from "@models/GameProfileModel";

export class ResendConfirmationEmailCodeTicker extends PIXI.utils.EventEmitter {
	private readonly model: GameProfileModel;
	private readonly ticker: PIXI.ticker.Ticker;

	constructor(model: GameProfileModel) {
		super();

		this.ticker = PIXI.ticker.shared;
		this.model = model;
		this.model.on(GameProfileModel.EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER, this.startTimer, this);
	}

	private startTimer(): void {
		this.ticker.add(this.onTimerUpdate, this);
	}

	private onTimerUpdate(): void {
		const newTime: number = this.model.getRetrySendConfirmationCodeTime() - this.ticker.elapsedMS / 1000;
		if (newTime <= 0) {
			this.removeTimer();
		}
		this.model.setRetrySendConfirmationCodeTime(newTime);
	}

	private removeTimer(): void {
		this.ticker.remove(this.onTimerUpdate, this);
	}

	public destroy(): void {
		this.ticker.remove(this.onTimerUpdate, this);
		this.model.off(GameProfileModel.EVENT_ACTIVATE_RETRY_CONFIRMATION_TIMER, this.startTimer, this);
	}
}
