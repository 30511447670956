import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { ConfigSetElement } from '@interfaces/ConfigSetElement';

export class BusinessConfig implements ConfigSetElement {
	public static readonly FIELD_UPGRADE_MIN_CUSTOMERS = 'upgrade_min_customers';

	public static FIELD_KEY = 'id';
	public static FIELD_BASE_INCOME = 'base_income';
	public static FIELD_BASE_TIME = 'base_time';
	public static FIELD_CUSTOMER_BASE_COST = 'customer_base_cost';
	public static FIELD_ACQUIRE_COST = 'acquire_cost';
	public static FIELD_CHARACTERS = 'characters';
	public static FIELD_UPGRADES = 'upgrades';
	public static FIELD_FURNITURE = 'furniture';
	public static FIELD_CUSTOMERS_MULT_INTERVAL = 'customers_mult_interval';
	public static FIELD_UNLOCK_VALUE = 'unlock_value';
	public static FIELD_UNLOCK_TYPE = 'unlock_type';

	private readonly key: string;
	private readonly unlocksOnLevel: number;
	private readonly baseIncome: SoftMoneyNumber;
	private readonly baseTimeIncomeInterval: number;
	private readonly customerBaseCost: SoftMoneyNumber;
	private readonly acquireCost: SoftMoneyNumber;
	private readonly characterKeys: string[];
	private readonly upgradeKeys: string[];
	private readonly upgradeMinCustomers: number[];

	private customerReaches: number[];
	private reduceTimeIncomeByClick: number;

	constructor(config: Record<string, string>) {
		this.key = config[BusinessConfig.FIELD_KEY].trim();
		this.unlocksOnLevel = Number(config[BusinessConfig.FIELD_UNLOCK_VALUE].trim());
		this.baseIncome = SoftMoneyNumber.createFromAcronym(config[BusinessConfig.FIELD_BASE_INCOME].trim());
		this.baseTimeIncomeInterval = Number(config[BusinessConfig.FIELD_BASE_TIME].trim());
		this.customerBaseCost = SoftMoneyNumber.createFromAcronym(config[BusinessConfig.FIELD_CUSTOMER_BASE_COST].trim());
		this.acquireCost = SoftMoneyNumber.createFromAcronym(config[BusinessConfig.FIELD_ACQUIRE_COST].trim());
		this.characterKeys = config[BusinessConfig.FIELD_CHARACTERS].split(',').map(value => value.trim());
		this.upgradeKeys = BusinessConfig.parseUpgradeKeys(config);

		this.customerReaches = config[BusinessConfig.FIELD_CUSTOMERS_MULT_INTERVAL]
			.split(',')
			.map((value: string) => Number(value.trim()));

		this.upgradeMinCustomers = config[BusinessConfig.FIELD_UPGRADE_MIN_CUSTOMERS]
			.split(',')
			.map((str: string) => Number(str.trim()));
	}

	public getCustomerReaches(): number[] {
		return this.customerReaches;
	}

	public setReduceTimeIncomeByClick(value: number): void {
		this.reduceTimeIncomeByClick = value;
	}

	public getKey(): string {
		return this.key;
	}

	public getUnlocksOnLevel(): number {
		return this.unlocksOnLevel;
	}

	public getBaseIncome(): SoftMoneyNumber {
		return this.baseIncome;
	}

	public getUpgradeKeys(): string[] {
		return this.upgradeKeys;
	}

	public getBaseTimeIncomeInterval(): number {
		return this.baseTimeIncomeInterval;
	}

	public getCustomerBaseCost(): SoftMoneyNumber {
		return this.customerBaseCost;
	}

	public getAcquireCost(): SoftMoneyNumber {
		return this.acquireCost;
	}

	public getReduceTimeIncomeByClick(): number {
		return this.reduceTimeIncomeByClick;
	}

	public getCharacterKeys(): string[] {
		return this.characterKeys;
	}

	public getUpgradeMinCustomers(): number[] {
		return this.upgradeMinCustomers;
	}

	private static parseUpgradeKeys(config: Record<string, string>): string[] {
		const upgradeKeys = config[BusinessConfig.FIELD_UPGRADES].split(',');
		const furnitureValue = config[BusinessConfig.FIELD_FURNITURE].trim();
		const furnitureKeys = furnitureValue === '' ? [] : furnitureValue.split(',');

		return upgradeKeys.concat(furnitureKeys).map((value) => value.trim());
	}
}
