import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';

export class PrepartyStartCharacterTicker extends PIXI.utils.EventEmitter {
	private ticker: PIXI.ticker.Ticker;
	private enabled: boolean = false;
	private timerTicking: boolean = false;

	constructor(
		private readonly model: PrepartyStartCharacterModel,
		private readonly farewellPartyEnabled: boolean,
	) {
		super();

		this.ticker = PIXI.ticker.shared;

		this.model.on(PrepartyStartCharacterModel.EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY, this.onChangePrepartyCharacterVisbility, this);
		this.model.on(PrepartyStartCharacterModel.EVENT_ACTIVATE, this.tryStartTicker, this);
	}

	public setEnabled(value: boolean): void {
		this.enabled = value && this.farewellPartyEnabled;

		if (value) {
			this.tryStartTicker();
		} else {
			this.stopTicker();
		}
	}

	private startTicker(): void {
		this.ticker.add(this.onTimerUpdate, this);
		this.timerTicking = true;
	}

	private tryStartTicker(): void {
		if (this.enabled && !this.timerTicking && this.model.canActivateTimer()) {
			this.startTicker();
		}
	}

	private stopTicker(): void {
		this.timerTicking = false;
		this.ticker.remove(this.onTimerUpdate, this);
	}

	private onTimerUpdate(): void {
		this.model.setTimeLeft(this.model.getTimeLeft() - this.ticker.elapsedMS / 1000);
	}

	private onChangePrepartyCharacterVisbility(visibile: boolean): void {
		if (visibile) {
			this.stopTicker();
		}
	}
}
