import { LevelModel } from '@models/level/LevelModel';
import { NewsModel } from '@models/NewsModel';

export class NewsEmitter extends PIXI.utils.EventEmitter {
	public static readonly EVENT_NEW_PUSHING_NEWS_ITEM = Symbol();
	private isEnabled: boolean;
	private isPending: boolean;

	constructor(
		private readonly newsModel: NewsModel,
		private readonly levelModel: LevelModel,
		private readonly newsPushingStartLevel: number,
	) {
		super();

		this.isEnabled = false;
		this.isPending = true;

		this.newsModel.on(NewsModel.EVENT_CHANGED, this.emitNewPushingNewsItem, this);
	}

	public setEnabled(value: boolean): void {
		this.isEnabled = value;

		if (this.isEnabled && this.isPending) {
			this.emitNewPushingNewsItem();
		}
	}

	private emitNewPushingNewsItem(): void {
		if (this.isEnabled && this.levelModel.getCurrentLevel() >= this.newsPushingStartLevel) {
			const key = this.newsModel.getPushedNewsKey();

			if (key != null) {
				this.emit(NewsEmitter.EVENT_NEW_PUSHING_NEWS_ITEM, key);
			}

			this.isPending = false;
		} else {
			this.isPending = true;
		}
	}
}
