import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterModel } from '@models/CharacterModel';

export class QuickPhraseActivateCharacterGenerator extends QuickPhaseGeneratorBase {
	constructor(config: CharacterQuickPhraseConstants, characters: CharacterModel[]) {
		super(CharacterQuickPhraseTypes.ACTIVATE_CHARACTER, config);
		characters.forEach(character => character.on(CharacterModel.EVENT_ACTIVATED, this.onCharacterActivated, this));
	}

	private onCharacterActivated(model: CharacterModel): void {
		this.tryGeneratePhrase(model.getKey());
	}
}
