import { AbstractQuest } from './AbstractQuest';
import { QuestLineModel } from './QuestLineModel';

export class QuestLinesModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_LINE_PROGRESS: symbol = Symbol();
	public static readonly EVENT_INITED: symbol = Symbol();
	public static readonly EVENT_QUEST_COMPLETED: symbol = Symbol();

	private lines: QuestLineModel[];
	private data: Map<QuestLineModel, AbstractQuest>;

	private inited: boolean;

	constructor() {
		super();

		this.data = new Map();
		this.lines = [];
	}

	public init(data: Map<QuestLineModel, AbstractQuest>): void {
		this.inited = true;
		this.data = data;
		this.lines = Array.from(data.keys());

		this.data.forEach((quest) => {
			quest.once(AbstractQuest.EVENT_COMPLETED, this.onSomeQuestCompleted, this);
		});

		this.emit(QuestLinesModel.EVENT_INITED);
	}

	public setLineCompleted(id: number): void {
		const lineIndex = this.lines.findIndex(line => line.getId() === id);
		this.lines.splice(lineIndex, 1);
	}

	public reset(): void {
		this.data.forEach((quest) => {
			if (quest.listeners(AbstractQuest.EVENT_COMPLETED).includes(this.onSomeQuestCompleted)) {
				quest.off(AbstractQuest.EVENT_COMPLETED, this.onSomeQuestCompleted, this, true);
			}
		});

		this.data.clear();
		this.lines = [];
		this.inited = false;
	}

	private onSomeQuestCompleted(quest: AbstractQuest): void {
		this.emit(QuestLinesModel.EVENT_QUEST_COMPLETED, quest);
	}

	public updateProgress(newQuest?: AbstractQuest): void {
		if (newQuest) {
			const line = this.lines.find(l => l.getId() === newQuest.getLineId());
			this.data.set(line, newQuest);
			newQuest.once(AbstractQuest.EVENT_COMPLETED, this.onSomeQuestCompleted, this);
		}

		this.emit(QuestLinesModel.EVENT_LINE_PROGRESS);
	}

	public getPendingProgress(): number {
		return this.lines
			.map(line => this.data.get(line))
			.filter(quest => quest.isCompleted()).length;
	}

	public getLineProgress(lineId: number): number {
		const line = this.lines.find(l => l.getId() === lineId);
		if (line) {
			return line.getProgress();
		}
		return -1;
	}

	public getCurrentQuests(): AbstractQuest[] {
		return this.lines
			.map(line => this.data.get(line));
	}

	public isInited(): boolean {
		return this.inited;
	}
}
