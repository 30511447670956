import { LevelModel } from '@models/level/LevelModel';
import { TutorialStepWindowViewController } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewController';
import {
	LevelGameUIViewSetter,
	BusinessAcquiredUIViewSetter,
	BusinessBaseViewsSetter,
	SummonResultWindowViewSetter,
	TotemPromoteWindowViewSetter,
	BusinessBaseViewSetter,
	EpicQuestCollectionsProgressViewSetter,
	GameUIBaseViewSetter,
	SettingsWindowViewSetter,
	CollectEventRewardsWindowViewSetter,
	PrepartyStartCharacterViewSetter,
	LevelViewSetter,
	PrepartyWindowViewSetter,
	GalleryCollectionViewSetter,
	GoToEventLevelWindowViewSetter,
	SummonCollectionViewSetter,
	BankWindowViewSetter,
	BankOfferWindowViewSetter,
	CharacterCollectionViewSetter,
	UpgradeCollectionViewSetter,
	TotemCollectionViewSetter,
	QuestWindowBaseViewSetter,
	NewsWindowViewSetter,
	LevelChallengeWindowViewSetter,
	DailyRewardWindowViewSetter,
	CheatWindowViewSetter,
	FurnitureCollectionViewSetter,
} from '@interfaces/ViewSetters';
import { TutorialStepUnlockTypeIncomeMoneyAppearModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeIncomeMoneyAppearModel';
import { LevelGameUIViewController } from '@src/viewControllers/mainUI/LevelGameUIViewController';
import { TutorialStepUnlockTypeIncomeMoneyAppearViewSetter } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeIncomeMoneyAppearViewSetter';
import { TotemPromoteWindowViewController } from '@src/viewControllers/TotemPromoteWindowViewController';
import { CheatModel } from '@models/CheatModel';
import { TutorialStepUnlockTypePrestigeBonusModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypePrestigeBonusModel';
import { TutorialStepUnlockTypePrestigeBonusViewSetter } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypePrestigeBonusViewSetter';
import { TutorialStepUnlockTypeBusinessAcquiredViewSetter } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeBusinessAcquiredViewSetter';
import { TutorialStepUnlockTypeBusinessAcquiredModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeBusinessAcquiredModel';
import { TutorialStepUnlockTypeSummonResultCardsAppearedModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeSummonResultCardsAppeared';
import { TutorialStepUnlockTypeSummonCardsOpenedModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeSummonCardsOpened';
import { LevelModelInstance } from '../modelInstance/LevelModelInstance';
import { TutorialStepUnlockTypeBaseModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeBaseModel';
import { EpicQuestCollectionsProgressViewController } from '@src/viewControllers/EpicQuestCollectionsProgressViewController';
import {
	TutorialStepUnlockTypeGoToNextLevelButtonAppearModel,
	TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel,
} from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeButtonAppearModels';
import { QuestInitData } from '@src/types/SaveTypes';
import { QuestsLoader } from '@src/loaders/QuestsLoader';
import { TimedQuestsLoader } from '@src/loaders/TimedQuestsLoader';
import { TimedQuestsEmitter } from '@src/emitters/TimedQuestsEmitter';
import { LevelChallengeTicker } from '@src/tickers/LevelChallengeTicker';
import { LevelBehavioursFactory } from '@src/initializers/behaviours/LevelBehavioursFactory';
import { AbstractBehaviourInstance } from './AbstractBehaviourInstance';
import { TutorialStepUnlockTypeLevelFactory } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypesLevelFactory';
import { TutorialStepWindowViewTargetLevelDispatcher } from '@src/viewControllers/tutorialSteps/TutorialStepWindowViewLevelTargetDispatcher';
import { TimedQuestsTicker } from '@src/tickers/TimedQuestsTicker';
import { SettingsWindowViewController } from '@src/viewControllers/SettingsWindowViewController';
import { CollectEventRewardsWindowViewController } from '@src/viewControllers/CollectEventRewardsWindowViewController';
import { GoToEventLevelWindowEmitter } from '@src/emitters/GoToEventLevelWindowEmitter';
import { ResendConfirmationEmailCodeTicker } from '@src/tickers/ResendConfirmationEmailCodeTicker';
import { PrepartyWindowViewController } from '@src/viewControllers/PrepartyWindowViewController';
import { TutorialStepUnlockTypePartyWindowAppearModel } from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeWindowAppearModels';
import { BaseHintViewController } from '@src/viewControllers/BaseHintViewController';
import { BankWindowViewController } from '@src/viewControllers/BankWindowViewController';
import { ModelWriterBusinessIncomePerSec } from '@src/modelWriters/ModelWriterBusinessIncomePerSec';
import { LevelQuestWindowViewController } from '@src/viewControllers/LevelQuestWindowViewController';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { SummonWindowViewController } from '@src/viewControllers/SummonWindowViewController';
import { NewsLoader } from '@src/loaders/NewsLoader';
import { NewsEmitter } from '@src/emitters/NewsEmitter';
import { BaseNewsWindowViewController } from '@src/viewControllers/BaseNewsWindowViewController';
import { LevelRewardsLoader } from '@src/loaders/LevelRewardsLoader';
import { DailyRewardWindowViewController } from '@src/viewControllers/DailyRewardWindowViewController';
import { DailyRewardEmitter } from '@src/emitters/DailyRewardEmitter';
import { DailyRewardLoader } from '@src/loaders/DailyRewardLoader';
import { CheatWindowSystemViewController } from '@src/viewControllers/cheat/CheatWindowSystemViewController';

export class LevelBehaviourInstance extends AbstractBehaviourInstance<LevelModelInstance> {
	public static readonly EVENT_TUTORIAL: symbol = Symbol();
	public static readonly EVENT_TUTORIAL_END: symbol = Symbol();
	public static readonly EVENT_GOTO_EVENT_LEVEL: symbol = Symbol();
	public static readonly EVENT_LEVEL_CHANGE: symbol = Symbol();

	public static readonly EVENT_LOCALIZATION_CHANGE: symbol = Symbol();

	private readonly levelModelInstance: LevelModelInstance;
	private readonly levelModel: LevelModel;

	private readonly questsInitData: QuestInitData[];

	private readonly timedQuestsLoader: TimedQuestsLoader;
	private readonly questsLoader: QuestsLoader;
	private readonly summonLoader: SummonLoader;
	private readonly newsLoader: NewsLoader;
	private readonly levelRewardsLoader: LevelRewardsLoader;
	private readonly dailyRewardLoader: DailyRewardLoader;

	private readonly gameUIViewController: LevelGameUIViewController;
	private readonly totemPromoteWindowViewController: TotemPromoteWindowViewController;
	private readonly epicQuestCollectionsProgressViewController: EpicQuestCollectionsProgressViewController;
	private readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetLevelDispatcher;
	private readonly settingsWindowViewController: SettingsWindowViewController;
	private readonly collectEventRewardsWindowViewController: CollectEventRewardsWindowViewController;
	private readonly prepartyWindowViewController: PrepartyWindowViewController;
	private readonly hintViewController: BaseHintViewController;
	private readonly bankWindowViewController: BankWindowViewController;
	private readonly questWindowViewController: LevelQuestWindowViewController;
	private readonly summonWindowViewController: SummonWindowViewController;
	private readonly baseNewsWindowViewController: BaseNewsWindowViewController;
	private readonly dailyRewardWindowViewController: DailyRewardWindowViewController;
	private readonly cheatWindowSystemViewController: CheatWindowSystemViewController;

	private readonly tutorialStepUnlockTypeFactory: TutorialStepUnlockTypeLevelFactory;
	private tutorialStepUnlockTypeModels: Map<string, TutorialStepUnlockTypeBaseModel>;
	private tutorialStepUnlockTypeIncomeMoneyAppearViewSetter: TutorialStepUnlockTypeIncomeMoneyAppearViewSetter;
	private tutorialStepUnlockTypePrestigeBonusViewSetter: TutorialStepUnlockTypePrestigeBonusViewSetter;
	private tutorialStepUnlockTypeBusinessAcquiredViewSetter: TutorialStepUnlockTypeBusinessAcquiredViewSetter;
	private tutorialStepUnlockTypeSummonResultCardsAppearedModels: TutorialStepUnlockTypeSummonResultCardsAppearedModel[];
	private tutorialStepUnlockTypeSummonCardsOpenedModels: TutorialStepUnlockTypeSummonCardsOpenedModel[];
	private tutorialStepUnlockTypeGoToNextLevelButtonAppearModels: TutorialStepUnlockTypeGoToNextLevelButtonAppearModel[];
	private tutorialStepUnlockTypePartyWindowModels: TutorialStepUnlockTypePartyWindowAppearModel[];
	private tutorialStepUnlockTypePrepartyStartCharacterAppearModels: TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel[];

	private readonly levelChallengeTicker: LevelChallengeTicker;
	private readonly timedQuestsTicker: TimedQuestsTicker;
	private readonly resendEmailConfirmationCodeTicker: ResendConfirmationEmailCodeTicker;

	private readonly timedQuestsEmitter: TimedQuestsEmitter;
	private readonly goToEventLevelWindowEmitter: GoToEventLevelWindowEmitter;
	protected readonly newsEmitter: NewsEmitter;
	protected readonly dailyRewardEmitter: DailyRewardEmitter;

	private readonly hasLevelStartedTime: boolean;
	private readonly modelWriterIncome: ModelWriterBusinessIncomePerSec;

	constructor(
		cheatModel: CheatModel,
		behavioursFactory: LevelBehavioursFactory,
		levelModelInstance: LevelModelInstance,
		levelModel: LevelModel,
		questsInitData: QuestInitData[],
		hasLevelStartedTime: boolean,
	) {
		super(
			behavioursFactory,
		);

		this.levelModel = levelModel;
		this.levelModelInstance = levelModelInstance;
		this.questsInitData = questsInitData;
		this.hasLevelStartedTime = hasLevelStartedTime;

		behavioursFactory.createModelWriterTotem();
		behavioursFactory.createModelWriterPromotable();
		behavioursFactory.createModelWriterLevel();
		this.modelWriterIncome = behavioursFactory.createModelWriterBusinessIncomePerSec();

		this.timedQuestsTicker = behavioursFactory.createTimedQuestsTicker();
		this.levelChallengeTicker = behavioursFactory.createLevelChallengeTicker();
		this.resendEmailConfirmationCodeTicker = behavioursFactory.createResendEmailConfirmationCodeTicker();

		this.questsLoader = behavioursFactory.createQuestsLoader();
		this.timedQuestsLoader = behavioursFactory.createTimedQuestsLoader();
		this.summonLoader = behavioursFactory.createSummonLoader();
		this.newsLoader = behavioursFactory.createNewsLoader();
		this.levelRewardsLoader = behavioursFactory.createLevelRewardsLoader();
		this.dailyRewardLoader = behavioursFactory.createDailyRewardLoader();

		this.tutorialStepsViewTargetDispatcher = behavioursFactory.createTutorialStepsViewTargetGameDispatcher();

		this.timedQuestsEmitter = behavioursFactory.createTimedQuestsEmitter();
		this.goToEventLevelWindowEmitter = behavioursFactory.createGoToEventLevelWindowEmitter();
		this.newsEmitter = behavioursFactory.createNewsEmitter();
		this.dailyRewardEmitter = behavioursFactory.createDailyRewardEmitter();

		this.hintViewController = behavioursFactory.createBaseHintViewController();

		this.tutorialStepUnlockTypeModels = new Map();
		this.tutorialStepUnlockTypeSummonResultCardsAppearedModels = [];
		this.tutorialStepUnlockTypeSummonCardsOpenedModels = [];
		this.tutorialStepUnlockTypeGoToNextLevelButtonAppearModels = [];
		this.tutorialStepUnlockTypePartyWindowModels = [];
		this.tutorialStepUnlockTypePrepartyStartCharacterAppearModels = [];
		this.tutorialStepUnlockTypeIncomeMoneyAppearViewSetter = new TutorialStepUnlockTypeIncomeMoneyAppearViewSetter();
		this.tutorialStepUnlockTypeBusinessAcquiredViewSetter = new TutorialStepUnlockTypeBusinessAcquiredViewSetter();
		this.tutorialStepUnlockTypePrestigeBonusViewSetter = new TutorialStepUnlockTypePrestigeBonusViewSetter();

		this.tutorialStepsWindowViewController.on(TutorialStepWindowViewController.EVENT_START, () => {
			this.emit(LevelBehaviourInstance.EVENT_TUTORIAL);
		});
		this.tutorialStepsWindowViewController.on(TutorialStepWindowViewController.EVENT_END, () => {
			this.emit(LevelBehaviourInstance.EVENT_TUTORIAL_END);
		});

		this.questWindowViewController = behavioursFactory.createLevelQuestWindowViewController(
			this.questsLoader,
			this.timedQuestsLoader,
			this.tutorialStepsViewTargetDispatcher,
		);

		const emittersController = behavioursFactory.createEmittersController(
			this.bankOfferWindowsEmitter,
			this.newsEmitter,
			this.goToEventLevelWindowEmitter,
			this.dailyRewardEmitter,
		);

		this.summonWindowViewController = behavioursFactory.createSummonWindowViewController(
			this.summonLoader,
		);

		this.bankWindowViewController = behavioursFactory.createBankWindowViewController(
			this.bankLoader,
			this.transactionCreator,
		);

		this.prepartyWindowViewController = behavioursFactory.createPrepartyWindowViewController(
			emittersController,
		);

		this.gameUIViewController = behavioursFactory.createGameUIViewController(
			this.timedQuestsEmitter,
			this.bankOfferWindowsEmitter,
			this.goToEventLevelWindowEmitter,
			this.tutorialStepsViewTargetDispatcher,
			emittersController,
			this.questsLoader,
			this.timedQuestsLoader,
			this.bankLoader,
			this.newsEmitter,
			this.dailyRewardEmitter,
		);
		this.gameUIViewController.on(
			LevelGameUIViewController.EVENT_GOTO_EVENT_LEVEL,
			() => this.emit(LevelBehaviourInstance.EVENT_GOTO_EVENT_LEVEL),
			this,
		);
		this.gameUIViewController.on(
			LevelGameUIViewController.EVENT_LEVEL_CHANGE,
			() => this.emit(LevelBehaviourInstance.EVENT_LEVEL_CHANGE),
		);

		this.totemPromoteWindowViewController = behavioursFactory.createTotemPromoteWindowViewController();

		this.epicQuestCollectionsProgressViewController = behavioursFactory.createEpicQuestCollectionsProgressViewController();

		this.tutorialStepUnlockTypeFactory = behavioursFactory.createTutorialStepUnlockTypeFactory();

		this.settingsWindowViewController = behavioursFactory.createSettingsWindowViewController();
		this.settingsWindowViewController.on(
			SettingsWindowViewController.EVENT_LOCALIZATION_CHANGE,
			(lang: string) => this.emit(LevelBehaviourInstance.EVENT_LOCALIZATION_CHANGE, lang),
		);

		this.collectEventRewardsWindowViewController = behavioursFactory.createCollectEventRewardsWindowViewController();

		this.baseNewsWindowViewController = behavioursFactory.createBaseNewsWindowViewController();

		this.dailyRewardWindowViewController = behavioursFactory.createDailyRewardWindowViewController();

		this.cheatWindowSystemViewController = behavioursFactory.createCheatWindowSystemViewController(
			this.dailyRewardLoader,
		);

		cheatModel.on(
			CheatModel.EVENT_UPDATE_TUTORIAL_ENABLED,
			(value: boolean) => {
				levelModelInstance.getTutorialStepsEmitter().setEnabled(value);
			},
			this,
		);

		this.actionEmitters.push(
			this.questWindowViewController,
			this.farewellPartyWindowViewController,
			this.totemPromoteWindowViewController,
			this.levelChallengeTicker,
			this.questsLoader,
			this.timedQuestsLoader,
			this.settingsWindowViewController,
			this.gameUIViewController,
			this.bankWindowViewController,
			this.bankLoader,
			this.gameProfileViewController,
			this.baseNewsWindowViewController,
			this.newsLoader,
			this.transactionCreator,
			this.dailyRewardWindowViewController,
		);
	}

	public setTimedQuestsTickerEnabled(value: boolean): this {
		this.timedQuestsTicker.setEnabled(value);
		return this;
	}

	public setLevelChallengeTickerEnabled(value: boolean): this {
		this.levelChallengeTicker.setEnabled(value);
		return this;
	}

	public setTimedQuestsLoaderEnabled(value: boolean): this {
		this.timedQuestsLoader.setEnabled(value);
		return this;
	}

	public reset(): void {
		this.tutorialStepUnlockTypeModels.forEach(model => model.destroy());
		this.tutorialStepUnlockTypeModels.clear();
		this.questsLoader.reset();
	}

	public init(): void {
		this.subscribeActionEmittersIfAny();
		this.bankWindowViewController.setEventKey('season');

		super.init();

		this.tutorialStepUnlockTypeModels = this.tutorialStepUnlockTypeFactory.createModels(this.levelModel.getCurrentLevel());

		this.tutorialStepUnlockTypeSummonCardsOpenedModels = [];
		this.tutorialStepUnlockTypeSummonResultCardsAppearedModels = [];
		this.tutorialStepUnlockTypeGoToNextLevelButtonAppearModels = [];
		const unlockTypeIncomeMoneyAppearModels: TutorialStepUnlockTypeIncomeMoneyAppearModel[] = [];
		const unlockTypePrestigeBonusModels: TutorialStepUnlockTypePrestigeBonusModel[] = [];
		const unlockTypeBusinessAcquiredModels: TutorialStepUnlockTypeBusinessAcquiredModel[] = [];
		this.tutorialStepUnlockTypeModels.forEach((model) => {
			if (model instanceof TutorialStepUnlockTypeIncomeMoneyAppearModel) {
				unlockTypeIncomeMoneyAppearModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypePrestigeBonusModel) {
				unlockTypePrestigeBonusModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypeBusinessAcquiredModel) {
				unlockTypeBusinessAcquiredModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypeSummonResultCardsAppearedModel) {
				this.tutorialStepUnlockTypeSummonResultCardsAppearedModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypeSummonCardsOpenedModel) {
				this.tutorialStepUnlockTypeSummonCardsOpenedModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypeGoToNextLevelButtonAppearModel) {
				this.tutorialStepUnlockTypeGoToNextLevelButtonAppearModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypePartyWindowAppearModel) {
				this.tutorialStepUnlockTypePartyWindowModels.push(model);
			} else if (model instanceof TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel) {
				this.tutorialStepUnlockTypePrepartyStartCharacterAppearModels.push(model);
			}
		});
		this.tutorialStepUnlockTypePrestigeBonusViewSetter.setModels(unlockTypePrestigeBonusModels);
		this.tutorialStepUnlockTypeIncomeMoneyAppearViewSetter.setModels(unlockTypeIncomeMoneyAppearModels);
		this.tutorialStepUnlockTypeBusinessAcquiredViewSetter.setModels(unlockTypeBusinessAcquiredModels);

		this.questsLoader.init(
			this.levelModel.getQuestLines(),
			this.levelModel.isInitialLevel() ? this.questsInitData : null,
		);

		if (!this.timedQuestsLoader.isInited()) {
			this.timedQuestsLoader.tryInit();
		}

		this.summonLoader.init();
		this.newsLoader.init();
		this.dailyRewardLoader.init();

		const initialLevel = this.levelModel.isInitialLevel();
		this.levelChallengeTicker.init(initialLevel && !this.hasLevelStartedTime || !initialLevel);

		this.hintViewController.init(this.levelModel.getCurrentLevel());

		if (initialLevel) {
			this.modelWriterIncome.init();
		}

		this.levelRewardsLoader.init(this.levelModel.getCurrentLevel());

		// TODO move?
		this.levelModelInstance.getTutorialStepsEmitter().setModels(this.tutorialStepUnlockTypeModels);
	}

	public getPrepartyWindowViewSetters(): PrepartyWindowViewSetter[] {
		return [
			this.prepartyWindowViewController,
		];
	}

	public getLevelViewSetters(): LevelViewSetter[] {
		return [
			this.gameUIViewController,
		];
	}

	public getQuestWindowBaseViewSetters(): QuestWindowBaseViewSetter[] {
		return super.getQuestWindowBaseViewSetters().concat([
			this.questWindowViewController,
		]);
	}

	public getGameUIViewSetters(): LevelGameUIViewSetter[] {
		return [
			...this.tutorialStepUnlockTypeGoToNextLevelButtonAppearModels,
			this.gameUIViewController,
			this.questWindowViewController,
			this.tutorialStepsViewTargetDispatcher,
		];
	}

	public getGameUIBaseViewSetters(): GameUIBaseViewSetter[] {
		return super.getGameUIBaseViewSetters().concat([
			this.tutorialStepsViewTargetDispatcher,
			this.totemPromoteWindowViewController,
			this.bankWindowViewController,
			this.hintViewController,
		]);
	}

	public getSummonCollectionViewSetters(): SummonCollectionViewSetter[] {
		return [
			this.hintViewController,
			this.summonWindowViewController,
		];
	}

	public getBankWindowViewSetters(): BankWindowViewSetter[] {
		return [
			this.hintViewController,
			this.bankWindowViewController,
		];
	}

	public getBankOfferWindowViewSetters(): BankOfferWindowViewSetter[] {
		return [
			this.bankWindowViewController,
			this.hintViewController,
		];
	}

	public getCharacterCollectionViewSetters(): CharacterCollectionViewSetter[] {
		return super.getCharacterCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getUpgradeCollectionViewSetters(): UpgradeCollectionViewSetter[] {
		return super.getUpgradeCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getFurnitureCollectionViewSetters(): FurnitureCollectionViewSetter[] {
		return super.getFurnitureCollectionViewSetters().concat([
			this.hintViewController,
		]);
	}

	public getTotemCollectionViewControllers(): TotemCollectionViewSetter[] {
		return super.getTotemCollectionViewControllers().concat([
			this.hintViewController,
		]);
	}

	public getBusinessAcquiredUIViewSetters(): BusinessAcquiredUIViewSetter[] {
		return super.getBusinessAcquiredUIViewSetters().concat([
			this.tutorialStepUnlockTypeIncomeMoneyAppearViewSetter,
			this.tutorialStepUnlockTypePrestigeBonusViewSetter,
		]);
	}

	public getBusinessBaseViewsSetters(): BusinessBaseViewsSetter[] {
		return super.getBusinessBaseViewsSetters().concat([
			this.tutorialStepsViewTargetDispatcher,
			this.timedQuestsEmitter,
		]);
	}

	public getTotemPromoteWindowViewSetters(): TotemPromoteWindowViewSetter[] {
		return [
			this.totemPromoteWindowViewController,
		];
	}

	public getSummonResultViewSetters(): SummonResultWindowViewSetter[] {
		return [
			this.summonWindowViewController,
			...this.tutorialStepUnlockTypeSummonResultCardsAppearedModels,
			...this.tutorialStepUnlockTypeSummonCardsOpenedModels,
		];
	}

	public getBusinessBaseViewSetters(): BusinessBaseViewSetter[] {
		return [
			this.tutorialStepUnlockTypeBusinessAcquiredViewSetter,
		];
	}

	public getCollectionCardsProgressViewSetters(): EpicQuestCollectionsProgressViewSetter[] {
		return [
			this.epicQuestCollectionsProgressViewController,
		];
	}

	public getSettingsWindowViewSetters(): SettingsWindowViewSetter[] {
		return super.getSettingsWindowViewSetters().concat([
			this.settingsWindowViewController,
		]);
	}

	public getCollectEventRewardsWindowViewSetters(): CollectEventRewardsWindowViewSetter[] {
		return [
			this.collectEventRewardsWindowViewController,
			this.hintViewController,
		];
	}

	public getPrepartyStartCharacterViewSetters(): PrepartyStartCharacterViewSetter[] {
		return super.getPrepartyStartCharacterViewSetters().concat([
			this.tutorialStepsViewTargetDispatcher,
			...this.tutorialStepUnlockTypePrepartyStartCharacterAppearModels,
		]);
	}

	public getGalleryCollectionViewSetters(): GalleryCollectionViewSetter[] {
		return [
			this.collectionCardsViewController,
		];
	}

	public getGoToEventLevelWindowViewSetters(): GoToEventLevelWindowViewSetter[] {
		return [
			this.hintViewController,
		];
	}

	public getNewsWindowViewSetters(): NewsWindowViewSetter[] {
		return [
			this.baseNewsWindowViewController,
			this.hintViewController,
		];
	}

	public getDailyRewardWindowViewSetters(): DailyRewardWindowViewSetter[] {
		return [
			this.dailyRewardWindowViewController,
			this.hintViewController,
		];
	}

	public getCheatWindowViewSetters(): CheatWindowViewSetter[] {
		const setters = super.getCheatWindowViewSetters();

		return [
			...setters,
			this.cheatWindowSystemViewController,
		];
	}

	public getLevelChallengeWindowViewSetters(): LevelChallengeWindowViewSetter[] {
		return [
			this.hintViewController,
		];
	}
}
