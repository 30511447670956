import { LevelModelInstance } from '@main/modelInstance/LevelModelInstance';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { ModelInstanceDataBase, TutorialModelInstanceData, FarewellPartyModelInstanceData } from '@src/types/ModelInstanceDataTypes';
import CharacterConfig from '@configs/CharacterConfig';
import {
	CharacterSaveData,
	SkillSaveData,
	BusinessSaveData,
	BoostSaveData,
	UpgradeSaveData,
	TimeskipSaveData,
	FarewellBonusSaveData,
	BankSaveData,
} from '@src/types/SaveTypes';
import { SkillConfig } from '@configs/SkillConfig';
import { BusinessConfig } from '@configs/BusinessConfig';
import { BoostConfig } from '@configs/BoostConfig';
import { UpgradeConfig } from '@configs/UpgradeConfig';
import { TimeskipConfig } from '@configs/TimeskipConfig';
import { DialogConfig } from '@configs/DialogConfig';
import { ConstantsConfig } from '@configs/ConstantsConfig';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';
import { BankPriceItemsConfig } from '@configs/bank/BankPriceItemsConfig';
import { EventLevelModelInstance } from '@main/modelInstance/EventLevelModelInstance';
import { EventLevelModelsFactory } from '../models/EventLevelModelsFactory';
import { TutorialStepEventLevelConfig } from '@configs/tutorialSteps/TutorialStepEventLevelConfig';
import { LootboxNewCustomerMultiplierConfig } from '@configs/LootboxServerConfig';

export class EventLevelModelInstanceFactory {
	private eventLevelModelInstance: EventLevelModelInstance;

	public createEventLevelModelInstance(
		levelModelInstance: LevelModelInstance,
		serverTime: ServerTimeModel,
		characterModelInstanceData: ModelInstanceDataBase<CharacterConfig, CharacterSaveData>,
		skillModelInstanceData: ModelInstanceDataBase<SkillConfig, SkillSaveData>,
		upgradeModelInstanceData: ModelInstanceDataBase<UpgradeConfig, UpgradeSaveData>,
		businessModelInstanceData: ModelInstanceDataBase<BusinessConfig, BusinessSaveData>,
		boostModelInstanceData: ModelInstanceDataBase<BoostConfig, BoostSaveData>,
		timeskipModelInstanceData: ModelInstanceDataBase<TimeskipConfig, TimeskipSaveData>,
		tutorialStepModelInstanceData: TutorialModelInstanceData<TutorialStepEventLevelConfig>,
		farewellPartyModelInstanceData: FarewellPartyModelInstanceData,
		dialogConfigs: Map<string, DialogConfig>,
		constantsConfig: ConstantsConfig,
		promotePatternsConfig: AbstractPromotePatternsConfig,
		bankPriceItemsConfig: BankPriceItemsConfig,
		eventLevelNewCustomersMultiplierRewards: LootboxNewCustomerMultiplierConfig[],
		bankSaveData: BankSaveData,
		farewellBoostSaveData: FarewellBonusSaveData | null,
		isNutaku: boolean,
	): EventLevelModelInstance {
		const modelsFactory = new EventLevelModelsFactory(
			serverTime,
			characterModelInstanceData,
			skillModelInstanceData,
			upgradeModelInstanceData,
			businessModelInstanceData,
			boostModelInstanceData,
			timeskipModelInstanceData,
			tutorialStepModelInstanceData,
			promotePatternsConfig,
			bankPriceItemsConfig,
			constantsConfig,
			eventLevelNewCustomersMultiplierRewards,
			dialogConfigs,
			farewellBoostSaveData,
			bankSaveData,
			isNutaku,
			undefined,
			undefined,
			undefined,
			farewellPartyModelInstanceData,
		);

		this.eventLevelModelInstance = new EventLevelModelInstance(
			modelsFactory,
			levelModelInstance.getEventLevelModel(),
			levelModelInstance.getHardMoneyModel(),
			levelModelInstance.getEventTutorialStepsModel(),
			levelModelInstance.getEventSummonSavesModel(),
		);
		return this.eventLevelModelInstance;
	}

	public getEventLevelModelInstance(): EventLevelModelInstance {
		return this.eventLevelModelInstance;
	}
}
