import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { BusinessAcquiredUIViewSetter } from '@interfaces/ViewSetters';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeIncomeMoneyAppearModel extends TutorialStepUnlockTypeBaseModel implements BusinessAcquiredUIViewSetter {
	private view: BusinessAcquiredUIView;

	private readonly businessKey: string;

	constructor(key: string, businessKey: string) {
		super(key, TutorialStepUnlockTypes.INCOME_MONEY_APPEAR);
		this.businessKey = businessKey;
	}

	public setAcquiredUIView(view: BusinessAcquiredUIView): void {
		this.view = view;

		if (!this.unlocked) {
			if (this.view.hasIncomeMoney()) {
				this.onUnlocked();
			} else {
				this.view.once(BusinessAcquiredUIView.EVENT_INCOME_MONEY_APPEAR, this.onUnlocked, this);
			}
		}
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public destroy(): void {
		if (this.view && this.view.listeners(BusinessAcquiredUIView.EVENT_INCOME_MONEY_APPEAR).includes(this.onUnlocked)) {
			this.view.off(BusinessAcquiredUIView.EVENT_INCOME_MONEY_APPEAR, this.onUnlocked, this);
		}
		super.destroy();
	}
}
