import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import LocalizationStorage from '@main/LocalizationStorage';
import { RewardTypes, RewardResourceIdTypes, RewardDisplayValueType } from '@src/types/RewardTypes';
import { CardRarities, CardRarity } from '@src/types/CardRarities';

type RewardPreviewData = {
	textColor: number;
	title: string;
	bg: PIXI.Container;
	bgResours?: PIXI.Sprite;
	icon?: PIXI.Sprite;
};

export enum RarityTextStyleType {
	COMMON = 'common',
	RARE = 'rare',
	EPIC = 'epic',
	LEGENDARY = 'legendary',
}

export class QuestRewardPreviewView extends PIXI.Container {
	private readonly localizationStorage: LocalizationStorage;
	private readonly textDescription: PIXI.extras.BitmapText;
	private readonly textTitle: SizeableBitmapText;
	private readonly textRarity: SizeableBitmapText;
	private readonly rarityPanel: PIXI.Sprite;
	private readonly iconsCont: PIXI.Container;
	private readonly rarePanelCont: PIXI.Container;

	constructor(
		rewardId: RewardResourceIdTypes | string,
		rewardType: RewardTypes,
		rewardDisplayValue: RewardDisplayValueType,
		cardRarity?: CardRarity,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		let previewData: RewardPreviewData;

		switch (rewardType) {
			case RewardTypes.RESOURCE: {
				const resourceId = rewardId;
				switch (resourceId) {
					case RewardResourceIdTypes.HARD_MONEY:
						previewData = this.getHardMoneyPreviewData();
						break;
					case RewardResourceIdTypes.PRESTIGE_MONEY:
						previewData = this.getPrestigeMoneyPreviewData();
						break;
					case RewardResourceIdTypes.SOFT_MONEY:
						previewData = this.getSoftMoneyPreviewData();
						break;
					default:
						if (MODE_DEBUG) {
							throw new Error(`Unsupported resource reward type: ${resourceId}`);
						}
						break;
				}
				break;
			}
			case RewardTypes.VIDEO:
				previewData = this.getVideoPreviewData(rewardId);
				break;
			case RewardTypes.CHARACTER:
				previewData = this.getCharacterPreviewData(cardRarity);
				break;
			case RewardTypes.UPGRADE:
				previewData = this.getUpgradePreviewData(cardRarity);
				break;
			case RewardTypes.TOTEM:
				previewData = this.getTotalCardsPreviewData();
				break;
			case RewardTypes.BOOST:
				previewData = this.getBoostPreviewData();
				break;
			case RewardTypes.TIMESKIP:
				previewData = this.getTimeskipPreviewData();
				break;
			default:
				if (MODE_DEBUG) {
					throw new Error(`Unsupported reward type: ${rewardType}`);
				}
				break;
		}

		this.textTitle = new SizeableBitmapText(previewData.title, 200, {
			font: '25px wendyOneShadowBold', align: 'center', tint: previewData.textColor,
		});
		this.textTitle.y = -134;
		this.textTitle.anchor = new PIXI.Point(0.5, 0);

		this.textDescription = new SizeableBitmapText('', 170, { font: '34px wendyOneShadowBold', tint: 0xffee90 });
		this.textDescription.anchor = 0.5;
		this.textDescription.y = 125;
		this.textDescription.text = rewardDisplayValue;

		this.textRarity = new SizeableBitmapText('', 105, {
			font: '15px wendyOneShadowBold', align: 'center',
		});
		this.textRarity.position.set(0, -152);
		this.textRarity.anchor = 0.5;

		this.rarityPanel = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`rarity_color_${cardRarity}_panel`]);
		this.rarityPanel.setTransform(0, -152, 0.5, 0.4);

		if (cardRarity != null) {
			this.textRarity.text = this.localizationStorage.getLocalizedString(`#hint_rarity_${cardRarity}`);
		} else {
			this.textRarity.visible = false;
			this.rarityPanel.visible = false;
		}

		const iconsContMask: PIXI.Graphics = new PIXI.Graphics();
		iconsContMask.beginFill(0x9aee10, 1);
		iconsContMask.drawRect(0, 0, 206, 300);
		iconsContMask.endFill();
		iconsContMask.pivot.set(iconsContMask.width / 2, iconsContMask.height / 2);

		this.iconsCont = new PIXI.Container();
		this.iconsCont.mask = iconsContMask;
		if (previewData.icon && previewData.bgResours) {
			this.iconsCont.addChild(
				previewData.bgResours,
				previewData.icon,
			);
		} else if (previewData.icon) {
			this.iconsCont.addChild(previewData.icon);
		}

		const fade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_gradient_black_h'], 0, 0, 0, 0);
		fade.width = 50;
		fade.height = 204;
		fade.pivot.set(fade.width / 2, fade.height / 2);
		fade.rotation = Math.PI / 180 * -90;
		fade.alpha = 0.6;
		fade.y = 124;
		fade.mask = iconsContMask;

		this.addChild(
			previewData.bg,
			this.iconsCont,
			fade,
			iconsContMask,
			this.textTitle as PIXI.DisplayObject,
			this.textDescription,
			this.rarityPanel,
			this.textRarity,
		);
	}

	public getCharacterPreviewData(rare: string): RewardPreviewData {
		const textColor = CardRarities.getRarityColor(rare);
		const title = this.localizationStorage.getLocalizedString('#character_name');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${rare}_bg_round`], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_character_card2']);
		icon.scale.set(1.25);

		return {
			bg,
			textColor,
			title,
			icon,
		};
	}

	public getUpgradePreviewData(rare: string): RewardPreviewData {
		const textColor = CardRarities.getRarityColor(rare);
		const title = this.localizationStorage.getLocalizedString('#upgrade_name');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${rare}_bg_round`], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['lock_bg_upgrade_card2']);
		icon.scale.set(1.25);

		return {
			bg,
			textColor,
			title,
			icon,
		};
	}

	public getBoostPreviewData(): RewardPreviewData {
		const textColor = 0xFFFFFF;
		const title = this.localizationStorage.getLocalizedString('#bank_income_boost');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['cards_hard_money_bg_round'], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const bgResours = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['mini_card_hard_money_bg']);
		bgResours.scale.set(1.62);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['i_booster_big']);
		icon.scale.set(0.3);
		return {
			bg,
			textColor,
			title,
			bgResours,
			icon,
		};
	}

	public getTimeskipPreviewData(): RewardPreviewData {
		const textColor = 0xFFFFFF;
		const title = this.localizationStorage.getLocalizedString('#bank_time_skip');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['cards_prestige_money_bg_round'], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const bgResours = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['mini_card_prestige_money_bg']);
		bgResours.scale.set(1.62);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['i_time_skip_big']);
		icon.scale.set(0.5);
		return {
			bg,
			textColor,
			title,
			bgResours,
			icon,
		};
	}

	private getHardMoneyPreviewData(): RewardPreviewData {
		const textColor = 0x2a9aff;
		const title = this.localizationStorage.getLocalizedString('#hard_money_name');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['cards_hard_money_bg_round'], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const bgResours = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['mini_card_hard_money_bg']);
		bgResours.scale.set(1.62);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['hard_money_mini_card_icon']);
		return {
			bg,
			textColor,
			title,
			bgResours,
			icon,
		};
	}

	private getSoftMoneyPreviewData(): RewardPreviewData {
		const textColor = 0xcf75ff;
		const title = this.localizationStorage.getLocalizedString('#soft_money_name');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['cards_soft_money_bg_round'], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const bgResours = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['mini_card_soft_money_bg']);
		bgResours.scale.set(1.62);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['soft_money_mini_card_icon']);
		return {
			bg,
			textColor,
			title,
			bgResours,
			icon,
		};
	}

	private getPrestigeMoneyPreviewData(): RewardPreviewData {
		const textColor = 0xab8ff8;
		const title = this.localizationStorage.getLocalizedString('#prestige_money_name');
		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['cards_prestige_money_bg_round'], 3, 3, 3, 3);
		bg.width = 224;
		bg.height = 316;
		bg.pivot.set(bg.width / 2, bg.height / 2);

		const bgResours = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['mini_card_prestige_money_bg']);
		bgResours.scale.set(1.62);
		const icon = new PIXI.Sprite(AssetsStorage.getAtlas('miniCardsBaseAtlas')['prestige_money_mini_card_icon']);
		return {
			bg,
			textColor,
			title,
			bgResours,
			icon,
		};
	}

	private getVideoPreviewData(id: string): RewardPreviewData {
		const textColor = 0xFFFFFF;
		const title = this.localizationStorage.getLocalizedString('#label_new_episode');
		const bg = new PIXI.Container();
		bg.addChild(
			new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')[`card_preview_${id}`])
				.setTransform(0, 0, 0.53, 0.53),
			new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['blue_drop_card']),
		);

		return { bg, textColor, title };
	}

	private getTotalCardsPreviewData(): RewardPreviewData {
		const textColor = 0xFFFFFF;
		const title = this.localizationStorage.getLocalizedString('#label_total_cards');
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['gold_drop_card']);

		return { bg, textColor, title };
	}
}
