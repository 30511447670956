import LocalizationStorage from '@main/LocalizationStorage';
import { SoundController } from '@src/main/SoundController';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithCostValueView';
import { SummonModel } from '@models/SummonModel';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { AssetsStorage } from '@main/AssetsStorage';

export class SummonOpenButtonView extends ButtonBaseView {
	private localizationStorage: LocalizationStorage;

	private readonly buttonLabel: PIXI.extras.BitmapText;
	private readonly openCount: number;
	private readonly labelLocaleKey: string;
	private readonly showLabelSummonPriceValue: boolean;
	private readonly summonModel: SummonModel;

	private costText: SizeableMultiColoredBitmapText;
	private hardMoneyModel: HardMoneyModel;
	private prestigeMoneyModel: PrestigeMoneyModel;

	protected buttonBg: PIXI.mesh.NineSlicePlane;

	constructor(
		summonModel: SummonModel,
		openCount: number,
		hardMoneyModel: HardMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		labelLocaleKey: string,
		showLabelSummonPriceValue: boolean,
		style?: PIXI.extras.BitmapTextStyle,
		isShort: boolean = false,
	) {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = isShort ? 188 : 300;
		buttonBg.height = isShort ? 74 : 90;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super(buttonBg);

		this.labelLocaleKey = labelLocaleKey;
		this.openCount = openCount;
		this.summonModel = summonModel;
		this.hardMoneyModel = hardMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.showLabelSummonPriceValue = showLabelSummonPriceValue;
		this.buttonBg = buttonBg;

		this.costText = new SizeableMultiColoredBitmapText(
			buttonBg.width,
			style || { font: '28px wendyOneShadowBold', align: 'center' },
		);
		this.costText.anchor = 0.5;
		this.costText.y = 12;
		this.addChild(this.costText);

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.buttonLabel = new PIXI.extras.BitmapText('', { font: { size: 20, name: 'wendyOne' } });
		this.buttonLabel.anchor = 0.5;
		this.buttonLabel.position.set(0, -18);
		this.buttonLabel.tint = 0x1d3d09;

		this.addChild(this.buttonLabel);

		this.updateAvailability();
		this.updateText();
	}

	public updateAvailability(): void {
		if (this.summonModel.isFreePrice()) {
			this.costText.text = this.localizationStorage.getLocalizedString('#summon_free_price');
		} else {
			const price = this.openCount === 10 ? this.summonModel.getPriceValueX10() : this.summonModel.getPriceValue();
			let buttonInfo: { valueType: ButtonValueTypes; enoughMoney: boolean };

			if (this.summonModel.isPrestigePrice()) {
				buttonInfo = {
					valueType: ButtonValueTypes.PRESTIGE_MONEY,
					enoughMoney: this.prestigeMoneyModel.getValue() >= price,
				};
			} else if (this.summonModel.isHardPrice()) {
				buttonInfo = {
					valueType: ButtonValueTypes.HARD_MONEY,
					enoughMoney: this.hardMoneyModel.getValue() >= price,
				};
			}

			const valueColor = buttonInfo.enoughMoney
				? '[FFFFFF]'
				: '[f85a4c]';

			const bgColor = buttonInfo.enoughMoney
				? AssetsStorage.getAtlas('uiAtlas')['button_green']
				: AssetsStorage.getAtlas('uiAtlas')['button_gold'];

			this.costText.text = `${buttonInfo.valueType}${valueColor}${price}[-]`;
			this.buttonBg.texture = bgColor;
		}
	}

	protected updateText(): void {
		const openText = this.localizationStorage.getLocalizedString(this.labelLocaleKey);
		if (this.showLabelSummonPriceValue) {
			this.buttonLabel.text = `${openText} x${this.openCount}`;
		} else {
			this.buttonLabel.text = openText;
		}
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playPromoteButton();

		this.emit(SummonOpenButtonView.EVENT_CLICK, this);
	}

	public destroy(): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy();
	}
}
