import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10004View } from '@views/characters/events/event1/EventCharacter10004View';
import { EventCharacter10005View } from '@views/characters/events/event1/EventCharacter10005View';
import { EventCharacter10006View } from '@views/characters/events/event1/EventCharacter10006View';

export class EventBusiness10002View extends BusinessBaseView {
	constructor() {
		const character10004 = new EventCharacter10004View();
		const character10005 = new EventCharacter10005View();
		const character10006 = new EventCharacter10006View();

		super('business10002', [character10004, character10005, character10006]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			character10006 as PIXI.DisplayObject,
			character10004,
			character10005,
		);
	}
}
