import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { TimeskipModel } from '@models/TimeskipModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '@views/windows/bank/animations/BankParticleConfig';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';

export class BankTabElementGemShopTimeskipView extends PIXI.Container {
	public static readonly EVENT_BUTTON_BUY_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_NOT_ENOUGH_HARD: symbol = Symbol();

	private animationsContainer: PIXI.Container;

	private static createButtonSkip(duration: string, durationLocaleKey: string): ButtonBaseView {
		const localizationStorage = LocalizationStorage.getInstance();

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 231;
		buttonBg.height = 85;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonBaseView(buttonBg);

		const stringActivate = localizationStorage.getLocalizedString('#bank_timeskip_skip');
		const labelActivate = new PIXI.extras.BitmapText(stringActivate, {
			font: '22px wendyOne',
			tint: 0x1d3d09,
		});
		labelActivate.anchor = 0.5;
		labelActivate.y = -16;

		let stringDuration = localizationStorage.getLocalizedString(durationLocaleKey);
		stringDuration = stringDuration.replace('{{value}}', duration);
		const labelDuration = new PIXI.extras.BitmapText(stringDuration, {
			font: '27px wendyOneShadowBold',
		});
		labelDuration.anchor = 0.5;
		labelDuration.y = 15;

		button.addChild(
			labelActivate,
			labelDuration,
		);

		return button;
	}

	private static createButtonBuy(costResource: ButtonValueTypes, costValue: string): ButtonWithCostValueView {
		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonBg.width = 231;
		buttonBg.height = 85;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		const button = new ButtonWithCostValueView({
			buttonBg,
			fontStyle: { font: '31px wendyOneShadowBold' },
			type: costResource,
			costTextOffset: new PIXI.Point(0, 15),
		});
		button.setCost(costValue);

		const localizationStorage = LocalizationStorage.getInstance();

		const stringBuy = localizationStorage.getLocalizedString('#bank_buy_label');
		const labelBuy = new PIXI.extras.BitmapText(stringBuy, {
			font: '22px wendyOne',
			tint: 0x1d3d09,
		});
		labelBuy.anchor = 0.5;
		labelBuy.y = -16;
		labelBuy.x = 3;
		button.addChild(labelBuy);

		return button;
	}

	private readonly model: TimeskipModel;
	private readonly localizationStorage: LocalizationStorage;
	private readonly hardMoneyModel: HardMoneyModel;

	private readonly textTitle: MultiColoredTextField;

	private readonly textHours: PIXI.extras.BitmapText;
	private readonly textTimeskipsLeft: MultiColoredTextField;

	private readonly buttonSkip: ButtonBaseView;
	private readonly buttonBuy: ButtonWithCostValueView;

	private price: number;

	private animationEmitters: Emitter[];
	private flyTextLabel: FlyBitmapText;
	private fxAtlas: PIXI.loaders.TextureDictionary;

	constructor(
		model: TimeskipModel,
		costResource: ButtonValueTypes,
		cost: number,
		hardMoneyModel?: HardMoneyModel,
	) {
		super();

		this.animationEmitters = [];
		this.localizationStorage = LocalizationStorage.getInstance();

		this.model = model;
		this.model.on(TimeskipModel.EVENT_ACQUIRED, this.onTimeskipAcquired, this);
		this.model.on(TimeskipModel.EVENT_ACTIVATED, this.onTimeskipActivated, this);
		this.model.on(TimeskipModel.EVENT_INCOME_UPDATED, this.onTimeskipIncomeUpdated, this);

		if (costResource === ButtonValueTypes.HARD_MONEY) {
			this.price = Number(cost);

			this.hardMoneyModel = hardMoneyModel;
			this.hardMoneyModel.on(HardMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonBuyEnabled, this);
		} else {
			throw new Error(`Unsupported cost resource ${costResource}`);
		}

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.textTitle = new MultiColoredTextField({ font: '25px wendyOneShadowBold', align: 'center' });
		this.textTitle.anchor = 0.5;
		this.textTitle.y = -175;

		this.animationsContainer = new PIXI.Container();
		this.animationsContainer.y = 90;
		this.animationsContainer.x = 30;

		const hours = Math.floor(this.model.getValue() / 3600);
		this.textHours = new PIXI.extras.BitmapText(`${hours}h`, { font: '56px wendyOneGold' });
		this.textHours.anchor = 0.5;
		this.textHours.x = 30;

		this.textTimeskipsLeft = new MultiColoredTextField({ font: '23px wendyOneShadowBold' });
		this.textTimeskipsLeft.anchor = 0.5;
		this.textTimeskipsLeft.y = 90;

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('bankAtlas')['time_scip_card_bg']);
		const icon = new PIXI.Sprite(collectionAtlas[model.getIconKey()]);
		icon.y = -50;
		icon.scale.set(0.6);

		this.buttonBuy = BankTabElementGemShopTimeskipView.createButtonBuy(costResource, String(cost));
		this.buttonBuy.on(ButtonBaseView.EVENT_CLICK, this.onButtonBuyClick, this);
		this.buttonBuy.y = 160;
		this.buttonBuy.setEnabled(true);

		this.buttonSkip = BankTabElementGemShopTimeskipView.createButtonSkip(
			String(Math.round(this.model.getValue() / 3600 * 100) / 100),
			'#bank_boost_duration_hours',
		);
		if (this.model.isUnlocked()) {
			this.buttonSkip.on(ButtonBaseView.EVENT_CLICK, this.onButtonActivateClick, this);
		} else {
			this.buttonSkip.on(ButtonBaseView.EVENT_CLICK, this.onButtonLockedClick, this);
		}
		this.buttonSkip.y = 160;

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const glowContainer = new PIXI.Container();
		const glowEmitter = new Emitter(
			glowContainer,
			[fxAtlas['bundles_rotate_glow']],
			BankParticleConfig.getRotateGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		this.addChild(
			bg,
			glowContainer,
			this.animationsContainer,
			icon,
			this.textTitle,
			this.textHours as PIXI.DisplayObject,
			this.textTimeskipsLeft,
			this.buttonBuy,
			this.buttonSkip,
		);

		const starEmitter = new Emitter(
			this,
			[fxAtlas['bundles_small_star']],
			BankParticleConfig.getCardFlare(),
		);
		starEmitter.autoUpdate = true;
		this.animationEmitters.push(starEmitter);

		this.updateTextTimeskipsLeft();
		this.updateButtonsVisible();
		this.updateButtonBuyEnabled();
		this.updateText();
	}

	public getButtonBottomContainer(): PIXI.Container {
		if (this.buttonBuy.visible) {
			return this.buttonBuy;
		}
		return this.buttonSkip;
	}

	private onTimeskipIncomeUpdated(): void {
		this.updateText();
	}

	private updateText(): void {
		let stringTitle = this.localizationStorage.getLocalizedString('#bank_timeskip_title');
		stringTitle = stringTitle.replace('{{value}}', `${ButtonValueTypes.SOFT_MONEY}${this.model.getIncome().toString()}`);
		this.textTitle.text = stringTitle;
	}

	private updateButtonsVisible(): void {
		if (this.model.canActivate()) {
			this.buttonSkip.visible = true;
			this.buttonBuy.visible = false;
		} else {
			this.buttonSkip.visible = false;
			this.buttonBuy.visible = true;
		}
	}

	private updateButtonBuyEnabled(): void {
		if (this.hardMoneyModel) {
			const isEnoughHardMoneyForBuy = this.hardMoneyModel.getValue() >= this.price;
			this.buttonBuy.setCostValueEnabled(isEnoughHardMoneyForBuy);
			this.buttonBuy.setButtonBgEnabled(isEnoughHardMoneyForBuy);
		}
	}

	private updateTextTimeskipsLeft(): void {
		const boostsLeftCount = this.model.getActivateCount() - this.model.getCurrentActivateCount();
		let stringBoostsLeft = this.localizationStorage.getLocalizedString('#bank_label_you_have');
		stringBoostsLeft = stringBoostsLeft.replace('{{value}}', String(boostsLeftCount));
		this.textTimeskipsLeft.text = stringBoostsLeft;
	}

	private onButtonBuyClick(): void {
		if (this.hardMoneyModel.getValue() >= this.price) {
			this.emit(BankTabElementGemShopTimeskipView.EVENT_BUTTON_BUY_CLICK);
		} else {
			this.emit(BankTabElementGemShopTimeskipView.EVENT_NOT_ENOUGH_HARD);
		}
	}

	private onButtonActivateClick(): void {
		this.emit(BankTabElementGemShopTimeskipView.EVENT_BUTTON_ACTIVATE_CLICK);
	}

	private onTimeskipAcquired(): void {
		this.updateTextTimeskipsLeft();
		this.updateButtonsVisible();

		if (!this.flyTextLabel) {
			this.flyTextLabel = new FlyBitmapText(
				'+1',
				new PIXI.Point(0.5, 0.5),
				{ font: '24px wendyOneShadowBold', tint: 0xffe03a },
			);
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.textTimeskipsLeft.x + 30, this.textTimeskipsLeft.y - 15);
			this.addChild(this.flyTextLabel);
		}

		const boostFxEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_glow4']],
			BusinessAnimationsConfig.getMoneyFX(),
		);
		this.animationEmitters.push(boostFxEmitter);
		boostFxEmitter.playOnceAndDestroy();

		const glowEmitter = new Emitter(
			this.animationsContainer,
			[this.fxAtlas['business_big_glow']],
			BusinessAnimationsConfig.getBusinessGlow5(),
		);
		this.animationEmitters.push(glowEmitter);
		glowEmitter.playOnceAndDestroy();
	}

	private onTimeskipActivated(): void {
		this.updateTextTimeskipsLeft();
		this.updateButtonsVisible();
	}

	private onButtonLockedClick(): void {
		if (!this.flyTextLabel) {
			this.flyTextLabel = new FlyBitmapText(
				this.localizationStorage.getLocalizedString('#automate_your_business_label'),
				new PIXI.Point(0.5, 0.5),
				{ font: '30px wendyOneShadowBold', tint: 0xffe03a },
			);
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(0, this.textTimeskipsLeft.y - 15);
			this.addChild(this.flyTextLabel);
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.hardMoneyModel) {
			this.hardMoneyModel.off(HardMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonBuyEnabled, this);
		}

		this.model.off(TimeskipModel.EVENT_ACQUIRED, this.onTimeskipAcquired, this);
		this.model.off(TimeskipModel.EVENT_ACTIVATED, this.onTimeskipActivated, this);
		this.model.off(TimeskipModel.EVENT_INCOME_UPDATED, this.onTimeskipIncomeUpdated, this);

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
