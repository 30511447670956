import { UpgradePromoteWindowViewSetter } from '@interfaces/ViewSetters';
import { UpgradePromoteWindowView } from '@views/windows/collection/upgrades/UpgradePromoteWindowView';
import { UpgradeModel } from '@models/UpgradeModel';
import { UpgradePromoteAction } from '@models/network/actions/upgrades/UpgradePromoteAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { OpenPromoteWindowAction } from '@models/network/actions/OpenPromoteWindowAction';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BasePromoteWindowViewController } from './BasePromoteWindowViewController';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { SummonModelsPool } from '@models/SummonModelsPool';

export class UpgradePromoteWindowViewController extends BasePromoteWindowViewController implements UpgradePromoteWindowViewSetter {
	private readonly upgradeModels: Map<string, UpgradeModel>;
	private view: UpgradePromoteWindowView;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		upgradeModels: Map<string, UpgradeModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		summonModelsPool: SummonModelsPool,
		questTargetDuration: number,
	) {
		super(windowViewSystem, windowFactory, prestigeMoneyModel, summonModelsPool, questTargetDuration);
		this.upgradeModels = upgradeModels;
	}

	public setUpgradePromoteWindowView(view: UpgradePromoteWindowView): void {
		this.view = view;
		view.on(UpgradePromoteWindowView.EVENT_BUTTON_PROMOTE, this.onButtonPromoteClick, this);
		view.on(UpgradePromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlus, this);

		const model = this.upgradeModels.get(view.getKey());
		if (model.isNew()) {
			model.setOld();

			const action = new OpenPromoteWindowAction(model.getKey(), 'upgrade');
			this.emit(BaseAction.EVENT_ACTION_CREATED, action);
		}
	}

	protected createAction(model: UpgradeModel): BaseAction {
		return new UpgradePromoteAction(
			model.getKey(),
			model.getPrevPromoteCostPrestige(),
			model.getPrevPromoteCostCards(),
			model.getLevel(),
			this.prestigeMoneyModel.getValue(),
			model.getCardsCount(),
		);
	}

	protected onBankLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_prestige');
	}

	protected onCollectionLocked(): void {
		this.view.showFlyTextOnPromoteButton('#not_enough_cards');
	}

	protected onButtonPromoteClick(model: UpgradeModel): void {
		if (!model.isEnoughCardsForPromote() && model.isFreeActivation()) {
			this.view.showFlyTextOnPromoteButton('#available_in_event_lock');
		} else {
			super.onButtonPromoteClick(model);
		}
	}
}
