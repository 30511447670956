import { GameUIBaseViewSetter } from '@interfaces/ViewSetters';
import { CheatModel } from '@models/CheatModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { EventLevelWindowViewFactory } from '@src/initializers/windows/EventLevelWindowViewFactory';
import { QuestsLoaderBase } from '@src/loaders/QuestsLoaderBase';
import { GameUIBaseView } from '@views/ui/main/GameUIBaseView';
import { QuestWindowViewMode } from '@views/windows/quests/QuestWindowBaseView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BaseQuestWindowViewController } from './BaseQuestWindowViewController';
import { ShowTutorialStepGetQuestWindowChainable } from './mainUI/ShowWindowChainables';
import { TutorialStepWindowViewTargetEventLevelDispatcher } from './tutorialSteps/TutorialStepWindowViewEventLevelTargetDispatcher';

export class EventLevelQuestWindowViewController extends BaseQuestWindowViewController
	implements GameUIBaseViewSetter {
	private gameUI: GameUIBaseView;

	constructor(
		questsLoader: QuestsLoaderBase,
		private readonly questLinesModel: QuestLinesModel,
		private readonly tutorialStepsEmitter: TutorialStepsEmitter,
		private readonly tutorialStepsViewTargetDispatcher: TutorialStepWindowViewTargetEventLevelDispatcher,
		windowViewSystem: WindowViewSystem,
		windowFactory: EventLevelWindowViewFactory,
		private readonly cheatModel: CheatModel,
	) {
		super(questsLoader, windowViewSystem, windowFactory);
	}

	public setGameUIBaseView(view: GameUIBaseView): void {
		this.gameUI = view;
	}

	protected onButtonCollectClick(quest: AbstractQuest): void {
		if (!quest.hasTime()) {
			const showTutorialStepGetQuestWindow = new ShowTutorialStepGetQuestWindowChainable(
				this.windowViewSystem,
				this.windowFactory,
				this.tutorialStepsEmitter,
				this.tutorialStepsViewTargetDispatcher,
			);

			if (this.cheatModel.getQuestWindowMode() === QuestWindowViewMode.ALWAYS_COLLECT
				&& !quest.isCompleted()) {
				quest.complete();
			}

			this.onFinishQuest(quest)
				.then(() => {
					this.gameUI.updateQuestLines(this.questLinesModel.getCurrentQuests());
				})
				.then(() => showTutorialStepGetQuestWindow.start());
		}
	}
}
