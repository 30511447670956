/* eslint-disable no-console */
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';
import { BankModel } from '@models/bank/BankModel';
import { BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';
import { CheatModel } from '@models/CheatModel';

export class CheatWindowBankViewController implements CheatWindowViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly cheatModel: CheatModel;
	private readonly bankModel: BankModel;

	constructor(
		networkRequestSender: NetworkRequestSender,
		cheatModel: CheatModel,
		bankModel: BankModel,
	) {
		this.networkRequestSender = networkRequestSender;
		this.cheatModel = cheatModel;
		this.bankModel = bankModel;
	}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_BANK_TOGGLE_DUMMY_TRANSACTION, this.onBankToggleDummyTransation, this);
		view.on(CheatWindowView.EVENT_BANK_RESET_BOOSTS, this.onBankBoostResetServer, this);
		view.on(CheatWindowView.EVENT_BANK_RESET_SUBSCRIPTIONS, this.onBankResetSubscription, this);
		view.on(CheatWindowView.EVENT_BANK_RESET, this.onBankResetServer, this);
		view.on(CheatWindowView.EVENT_BANK_PRINT_OFFERS, this.onBankPrintOffers, this);
		view.on(CheatWindowView.EVENT_BANK_PRINT_OFFERS_WITH_ACTIVE_ELEMENT, this.onBankPrintOffertsWithActiveBankElement, this);
	}

	private onBankToggleDummyTransation(): void {
		this.cheatModel.setDummyTransactionsEnabled(!this.cheatModel.isDummyTransactionsEnabled());
	}

	private onBankBoostResetServer(): void {
		console.log('Resetting boosts server...');
		this.networkRequestSender.sendCheatBankResetBoosts().then(() => {
			console.log('Resetting boosts server OK');
		});
	}

	private onBankResetSubscription(): void {
		console.log('Resetting subscriptions server...');
		this.networkRequestSender.sendCheatBankResetSubscriptions().then(() => {
			console.log('Resetting subscriptions server OK');
		});
	}

	private onBankResetServer(): void {
		console.log('Resetting bank server...');
		this.networkRequestSender.sendCheatBankResetBank().then(() => {
			console.log('Resetting bank server OK');
		});
	}

	private onBankPrintOffers(): void {
		const offerModels = this.bankModel.getTabElementConfigNameDataMap().get(BankTabElementDataConfigNameTypes.OFFERS);
		if (offerModels) {
			console.log(offerModels);
		} else {
			console.log('Print offers: nothing found');
		}
	}

	private onBankPrintOffertsWithActiveBankElement(): void {
		const offerModels = this.bankModel.getBankOfferModelsWithActiveBankTabElement();
		if (offerModels.length > 0) {
			offerModels.forEach(model => {
				console.log('Offer:');
				console.log(model);
				console.log('Bank element:');
				console.log(this.bankModel.getBankTabElementByOfferKey(model.getKey()));
			});
		} else {
			console.log('Print offers w active bank elmnt: nothing found');
		}
	}
}
