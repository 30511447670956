import { AssetsStorage } from '@main/AssetsStorage';
import { ButtonWithLabelBaseView } from '@views/components/buttons/ButtonWithLabelBaseView';
import { ButtonBaseView, ButtonBackgroundType } from '@views/components/buttons/ButtonBaseView';
import LocalizationStorage from '@main/LocalizationStorage';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import { GameProfileModel } from '@models/GameProfileModel';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { StringLengthValidator } from '@src/utils/validators/StringLengthValidator';
import { StringRegExpValidation } from '@src/utils/validators/StringRegExpValidation';

export class EnterUsernamePopupView extends PopupWindowBaseView {
	public static readonly EVENT_SUBMIT_USERNAME: symbol = Symbol();

	private readonly inputUsername: TextAreaInputView;
	private readonly localizationStorage: LocalizationStorage;

	constructor(
	) {
		super(0.5);

		this.localizationStorage = LocalizationStorage.getInstance();

		const uiAtlas = AssetsStorage.getAtlas('uiAtlas');

		const girl = new PIXI.Sprite(AssetsStorage.getResource('character2_offer').texture);
		girl.pivot.set(girl.width / 2, girl.height / 2);
		girl.position.set(610, 80);
		girl.scale.x = -1;

		const bubbleTail = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['bubble_tail_main']);
		bubbleTail.position.set(371, -138);

		const bubbleBg = new PIXI.mesh.NineSlicePlane(uiAtlas['bubble_main'], 5, 10, 5, 6);
		bubbleBg.width = 720;
		bubbleBg.height = 415;
		bubbleBg.pivot.set(bubbleBg.width / 2, bubbleBg.height / 2);
		bubbleBg.position.set(-10, -25);
		bubbleBg.interactive = true;

		const bgFade = new PIXI.Graphics()
			.beginFill(0xcaced2)
			.drawRect(-10, 100, 712, 156)
			.endFill();
		bgFade.pivot.set(bgFade.width / 2, bgFade.height / 2);

		const textDescription = new MultiColoredTextField(
			{ font: '26px wendyOne', align: 'center', tint: 0x2e374b },
			600,
			300,
		);
		textDescription.anchor = 0.5;
		textDescription.position.set(-10, -150);
		textDescription.text = this.localizationStorage.getLocalizedString('#enter_username_window_desc');

		this.inputUsername = new TextAreaInputView({
			bgWidth: 582,
			invalidInputOffsetX: 25,
			maxLength: 10,
		});
		this.inputUsername.position.set(-10, -50);
		this.inputUsername.setPlaceholder(this.localizationStorage.getLocalizedString('#type_your_name_label'));
		const validator = new StringLengthValidator(
			'#settings_invalid_lenght_username',
			GameProfileModel.INPUT_USERNAME_MIN_LENGTH,
			GameProfileModel.INPUT_USERNAME_MAX_LENGTH,
		);
		validator.setNext(new StringRegExpValidation(
			'#settings_invalid_format_username',
			GameProfileModel.FORMAT_USERNAME,
		));
		this.inputUsername.setValidator(validator);

		const buttonSubmit = new ButtonWithLabelBaseView(
			ButtonBaseView.createButtonBackground(ButtonBackgroundType.GREEN, 297, 75),
			{ font: '34px wendyOneShadowBold' },
			200,
		);
		buttonSubmit.position.set(-10, 103);
		buttonSubmit.setTextLabel(LocalizationStorage.getInstance().getLocalizedString('#label_Ok'));
		buttonSubmit.on(ButtonWithLabelBaseView.EVENT_CLICK, this.onButtonSubmitClick, this);

		this.mainContainer.addChild(
			girl,
			bubbleBg as PIXI.DisplayObject,
			bubbleTail,
			bgFade,
			textDescription,
			buttonSubmit,
			this.inputUsername,
		);
	}

	private onButtonSubmitClick(): void {
		const textUsername = this.inputUsername.getText().trim();
		if (this.inputUsername.isValid()) {
			this.emit(EnterUsernamePopupView.EVENT_SUBMIT_USERNAME, textUsername);
		}
	}
}
