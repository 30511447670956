import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';

export class IncomeMoneyView extends PIXI.Container {
	private moneyIcon: PIXI.Sprite;
	private moneyTween: TWEEN.Tween;
	private moneyAppearTween: TWEEN.Tween;

	constructor() {
		super();

		this.moneyIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['i_money_big']);

		this.visible = false;

		this.addChild(this.moneyIcon);
	}

	public show(): void {
		this.destroyAppearingAnimation();
		this.moneyIcon.scale.set(0);
		this.startAppearingAnimation(1);
		this.visible = true;
	}

	public showZoomed(): void {
		this.destroyAppearingAnimation();
		this.moneyIcon.scale.set(0);
		this.startAppearingAnimation(0.65);
		this.visible = true;
	}

	public hide(): void {
		this.destroyAppearingAnimation();
		this.visible = false;
	}

	public onZoomIn(): void {
		this.destroyAppearingAnimation();
		this.startAppearingAnimation(0.65);
	}

	public onZoomOut(): void {
		this.destroyAppearingAnimation();
		this.startAppearingAnimation(1);
	}

	private startAppearingAnimation(initialScale: number): void {
		this.moneyAppearTween = new TWEEN.Tween(this.moneyIcon.scale)
			.to({ x: initialScale, y: initialScale }, 400)
			.easing(TWEEN.Easing.Elastic.Out)
			.onComplete(() => {
				this.moneyTween = new TWEEN.Tween(this.moneyIcon.scale)
					.to({ x: initialScale - 0.1, y: initialScale - 0.1 }, 300)
					.yoyo(true)
					.repeat(Infinity)
					.start();
			})
			.start();
	}

	private destroyAppearingAnimation(): void {
		if (this.moneyTween) {
			this.moneyTween.stop();
		}
		if (this.moneyAppearTween) {
			this.moneyAppearTween.stop();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.destroyAppearingAnimation();
		super.destroy(options);
	}
}
