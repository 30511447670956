import { RewardResourceIdTypes } from '@src/types/RewardTypes';
import { AssetsStorage } from '@main/AssetsStorage';
import { BaseDailyCardView } from './BaseDailyCardView';

export enum ResourceDailyBgCardTypes {
	SOFT = 'soft_money',
	HARD = 'hard_money',
	PRESTIGE = 'prestige_money',
}

export class ResourceDailyCardView extends BaseDailyCardView {
	private readonly bg: PIXI.Sprite;
	private icon: PIXI.Sprite;
	private resourceId: RewardResourceIdTypes;

	constructor(resourceId: RewardResourceIdTypes, day: number) {
		super(day);

		this.resourceId = resourceId;

		const miniCardsBase = AssetsStorage.getAtlas('miniCardsBaseAtlas');

		this.bg = new PIXI.Sprite(miniCardsBase[`mini_card_${this.resourceId}_bg`]);
		this.bg.scale.set(1.45);
		this.bgRound.tint = this.getCurBgColor(this.resourceId);
		this.bg.mask = this.contentMask;

		this.icon = new PIXI.Sprite(miniCardsBase[`${this.resourceId}_mini_card_icon`]);

		this.addChild(
			this.bg as PIXI.DisplayObject,
			this.contentMask,
			this.upTint,
			this.icon,
			this.dayString,
			this.downTint,
			this.countString,
		);
	}

	// eslint-disable-next-line class-methods-use-this
	private getCurBgColor(rarity: string): number {
		switch (rarity) {
			case ResourceDailyBgCardTypes.SOFT:
				return 0xc466f0;
			case ResourceDailyBgCardTypes.HARD:
				return 0x228bf4;
			case ResourceDailyBgCardTypes.PRESTIGE:
				return 0x8c79ff;
			default:
				if (MODE_DEBUG) {
					throw new Error(`Unsupported rarity: ${rarity}`);
				}
				return 0xFFFFFF;
		}
	}

	public setExtraRewardMode(): void {
		super.setExtraRewardMode();

		this.icon.texture = AssetsStorage.getAtlas('miniCardsBaseAtlas')[`${this.resourceId}_big_card_icon`];
		this.bg.scale.set(3.5);
	}
}
