import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character28View } from '@views/characters/main/Character28View';
import { Character29View } from '@views/characters/main/Character29View';
import { Character30View } from '@views/characters/main/Character30View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business10View extends BusinessBaseView {
	constructor() {
		const character28 = new Character28View();
		const character29 = new Character29View();
		const character30 = new Character30View();

		super('business10', [character28, character29, character30]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			character28 as PIXI.DisplayObject,
			character29,
			character30,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
