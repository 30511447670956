import { TextField } from '@views/components/text/TextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankOfferLocalizationKeysType } from '@configs/bank/BankOfferConfig';
import { TextUtils } from '@src/utils/TextUtils';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferWindowPackBodyView } from './BankOfferWindowPackBodyView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';

export class BankOfferWindowPackBody2View extends BankOfferWindowPackBodyView {
	private readonly localizationStorage: LocalizationStorage;
	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;

	private readonly timerLabel: PIXI.extras.BitmapText;

	constructor(
		bankTabElementWithOffer: BankTabElementWithOfferType,
		localizationKeys: BankOfferLocalizationKeysType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super(bankTabElementWithOffer);

		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.Sprite(AssetsStorage.getResource('popup_offer_bg_2').texture);
		bg.interactive = true;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.position.set(0, -6);

		const titleText = new TextField(
			this.localizationStorage.getLocalizedString(localizationKeys.title),
			{ font: '68px wendyOneGold', align: 'center' },
			770,
			85,
		);
		titleText.anchor = 0.5;
		titleText.position.set(-22, -294);

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '40px wendyOne', tint: 0x1b0624,
		});
		this.timerLabel.anchor = 0.5;
		this.timerLabel.position.set(-22, -212);

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(0, -180);
		animationsContainer.scale.y = 0.6;
		animationsContainer.scale.x = 1.8;

		const glowLightsEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const rewardsContainer = getRewardMiniCardsContainerHandler(8, bankTabElementWithOffer, 1);

		const buttonDescrFade = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['button_green'], 11, 11, 11, 11);
		buttonDescrFade.tint = 0x000000;
		buttonDescrFade.alpha = 0.5;
		buttonDescrFade.width = 234;
		buttonDescrFade.height = 60;
		buttonDescrFade.pivot.set(buttonDescrFade.width / 2, buttonDescrFade.height / 2);
		buttonDescrFade.position.set(-22, 258);


		const priceCostResource = bankTabElementWithOffer.getButtonPriceResource();
		const priceLabel = new PIXI.extras.BitmapText('', {
			font: '36px wendyOneShadowBold', tint: 0xbeed63,
		});
		priceLabel.text = `${priceCostResource}${bankTabElementWithOffer.getPrice()}`;
		priceLabel.anchor = 0.5;

		const buyButton = getBuyButtonHandler(
			434,
			105,
			bankTabElementWithOffer,
		);
		buyButton.position.set(-22, 256);

		let rewardCount: number = 0;
		let rewardId: string = '';
		if (bankTabElementWithOffer instanceof BankBundleModel) {
			rewardCount = bankTabElementWithOffer.getExtraRewardCount();
			rewardId = bankTabElementWithOffer.getExtraRewardId();
		}

		this.addChild(
			animationsContainer,
			bg,
			titleText as PIXI.DisplayObject,
			buttonDescrFade,
			buyButton,
			this.timerLabel,
			rewardsContainer,
		);

		if (rewardCount > 0) {
			const specialBonusContainer: PIXI.Container = this.createSpecialBonus(rewardCount, rewardId);
			this.addChild(specialBonusContainer);
			rewardsContainer.position.set(-22, -24);
		} else {
			rewardsContainer.position.set(-22, 21);
		}

		if (bankTabElementWithOffer.getMostPopularLocaleKey()) {
			const stickerPopular = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopular.anchor.set(0.5);
			stickerPopular.position.set(730, -325);
			stickerPopular.scale.set(1.4);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getMostPopularLocaleKey()),
				{ font: '36px wendyOne', align: 'center', tint: 0xfff78f },
				120,
				80,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = 0.47;
			stickerPopularText.position.set(stickerPopular.x, stickerPopular.y + 3);

			const stickerPopularMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopularMask.anchor.set(0.5);
			stickerPopularMask.scale.set(1.4);
			stickerPopularMask.tint = 0xffffff;
			const stickerPopularHightlight = new SpriteHighlightAnimationView(stickerPopularMask, 500, false);
			stickerPopularHightlight.position.set(stickerPopular.x, stickerPopular.y);

			this.addChild(
				stickerPopular,
				stickerPopularHightlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHightlight.start();
		}
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = text;
	}

	private createSpecialBonus(rewardCount: number, rewardId: string): PIXI.Container {
		const specialBonusContainer = new PIXI.Container();
		specialBonusContainer.position.set(0, 129);

		const specialBonusBg = new PIXI.Graphics();
		specialBonusBg.beginFill(0x000000);
		specialBonusBg.drawPolygon([
			new PIXI.Point(-293, -32),
			new PIXI.Point(308, -32),
			new PIXI.Point(293, 32),
			new PIXI.Point(-308, 32),
		]);
		specialBonusBg.alpha = 0.4;

		const specialBonusIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[`i_${rewardId}_big`]);
		specialBonusIcon.x = -296;
		specialBonusIcon.scale.set(0.8);

		let specialBonusString: string = this.localizationStorage.getLocalizedString(this.getSpecialBonusLocalizationKey())
			.replace('{{count}}', rewardCount.toString());
		specialBonusString = TextUtils.getWordEnding(this.localizationStorage.getLanguage(), rewardCount, specialBonusString);

		const specialBonusText = new TextField(
			`+${specialBonusString}`,
			{ font: '39px wendyOneShadowBold', tint: 0xa4ff17 },
			500,
			45,
		);
		specialBonusText.anchor = 0.5;
		specialBonusText.x = -9;

		specialBonusContainer.addChild(
			specialBonusBg,
			specialBonusIcon as PIXI.DisplayObject,
			specialBonusText,
		);

		return specialBonusContainer;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
