import { ViewportUIView } from '@views/components/ViewportUIView';
import { SoundController } from '@src/main/SoundController';
import { CharacterBaseView } from './CharacterBaseView';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { Emitter } from 'pixi-particles';
import { BusinessAnimationsConfig } from '@views/businesses/ui/BusinessAnimationsConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export abstract class CharacterMainBaseView extends CharacterBaseView {
	public static readonly EVENT_SHOWN: symbol = Symbol();
	public static readonly EVENT_SIDE_QUEST_BUBBLE_CLICK: symbol = Symbol();

	protected readonly key: string;

	private animationEmitters: Emitter[];
	protected animationsContainer: PIXI.Container;

	private soundPackName: string;
	private tweenHide: TWEEN.Tween;

	protected constructor(key: string) {
		super(`${key}Animation`);

		this.key = key;
		this.animationsContainer = new PIXI.Container();
		this.addChild(this.animationsContainer);
	}

	public init(
		viewportUI: ViewportUIView,
		quickPhraseEmitter: CharacterQuickPhraseEmitter,
		soundPackName: string,
	): void {
		this.viewportUI = viewportUI;
		this.quickPhraseEmitter = quickPhraseEmitter;
		this.animationEmitters = [];
		this.soundPackName = soundPackName;

		this.onInited();
	}

	// eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
	protected onShow(): void {}

	public show(): void {
		this.onShow();
		this.emit(CharacterMainBaseView.EVENT_SHOWN);
	}

	public activate(): void {
		this.show();

		const appearanceEmitter = new Emitter(
			this.animationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['business_apperance']],
			BusinessAnimationsConfig.getAppearance(),
		);
		this.animationEmitters.push(appearanceEmitter);
		appearanceEmitter.playOnceAndDestroy();

		const appearance2Emitter = new Emitter(
			this.animationsContainer,
			[AssetsStorage.getAtlas('fxAtlas')['business_glow4']],
			BusinessAnimationsConfig.getAppearance2(),
		);
		this.animationEmitters.push(appearance2Emitter);
		appearance2Emitter.playOnceAndDestroy();
	}

	public hide(duration: number): void {
		this.setQuickPhraseEnabled(false, true);

		this.tweenHide = new TWEEN.Tween(this)
			.to({ alpha: 0 }, duration)
			.onComplete(() => {
				this.tweenHide = null;
			})
			.start();
	}

	public getKey(): string {
		return this.key;
	}

	protected onAnimationClick(event: PIXI.interaction.InteractionEvent): void {
		SoundController.getInstance().playCharacterClick(this.key, this.soundPackName);
		super.onAnimationClick(event);
	}

	public getAnimationPosition(): PIXI.Point {
		return this.animationsContainer.position.clone();
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.tweenHide) {
			this.tweenHide.stop();
			this.tweenHide = null;
		}

		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});
		super.destroy(options);
	}
}
