import { BaseAction } from '../BaseAction';

export class UpgradePromoteAction extends BaseAction {
	constructor(
		upgradeId: string,
		cost: number,
		cardsCost: number,
		upgradeLevel: number,
		currentPrestige: number,
		currentCardCount: number,
	) {
		super('UpgradePromote');

		this.data.upgrade_id = upgradeId;
		this.data.card_count = cardsCost;
		const resourcesSpent = Object.create(null);
		resourcesSpent.prestige_money = cost;
		this.data.resources_spent = resourcesSpent;

		const resources = Object.create(null);
		resources.prestige_money = currentPrestige;
		this.sync.resources = resources;

		const upgrades = Object.create(null);
		upgrades[upgradeId] = Object.create(null);
		upgrades[upgradeId].cards = currentCardCount;
		upgrades[upgradeId].level = upgradeLevel;
		this.sync.upgrades = upgrades;
	}
}
