import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { LevelModel } from '@models/level/LevelModel';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeLevelStartModel extends TutorialStepUnlockTypeBaseModel {
	private readonly targetLevel: number;
	private readonly levelModel: LevelModel;

	constructor(key: string, targetLevel: number, levelModel: LevelModel) {
		super(key, TutorialStepUnlockTypes.LEVEL_START);

		this.targetLevel = targetLevel;
		this.levelModel = levelModel;
	}

	public tryUnlock(): boolean {
		if (this.levelModel.getCurrentLevel() === this.targetLevel) {
			this.onUnlocked();
			return true;
		}
		return false;
	}
}
