import { EpicQuestCollectionsProgressViewSetter } from '@interfaces/ViewSetters';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { EpicQuestCollectionsProgressView } from '@views/windows/collection/EpicQuestCollectionsProgressView';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';

export class EpicQuestCollectionsProgressViewController implements EpicQuestCollectionsProgressViewSetter {
	private readonly networkRequestSender: NetworkRequestSender;
	private readonly lootboxFactory: LootboxFactory;
	private readonly epicQuestCollectionModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>;
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;

	private readonly views: Map<string, EpicQuestCollectionsProgressView>;

	constructor(
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		networkRequestSender: NetworkRequestSender,
		lootboxFactory: LootboxFactory,
		epicQuestCollectionModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>,
	) {
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.networkRequestSender = networkRequestSender;
		this.lootboxFactory = lootboxFactory;
		this.epicQuestCollectionModels = epicQuestCollectionModels;

		this.views = new Map();
	}

	public setEpicQuestCollectionsProgressView(view: EpicQuestCollectionsProgressView): void {
		if (!view.listeners(EpicQuestCollectionsProgressView.EVENT_COLLECT_REWARD_TARGET).includes(this.onButtonCollectClick)) {
			view.once(EpicQuestCollectionsProgressView.EVENT_COLLECT_REWARD_TARGET, this.onButtonCollectClick, this);
		}

		this.views.set(view.getType(), view);
	}

	private async onButtonCollectClick(type: EpicQuestCollectionsTypes, targetProgress: number): Promise<void> {
		await this.onCollectRewardTarget(type, targetProgress);

		if (this.views.has(type)) {
			const view = this.views.get(type);
			view.once(EpicQuestCollectionsProgressView.EVENT_COLLECT_REWARD_TARGET, this.onButtonCollectClick, this);
		}
	}

	private async onCollectRewardTarget(type: EpicQuestCollectionsTypes, targetProgress: number): Promise<void> {
		const model = this.epicQuestCollectionModels.get(type);
		const rewardKey = model.getQuestKey(targetProgress);
		const rewardConfig = await this.networkRequestSender.sendGetEpicQuestRewards(rewardKey);
		if (rewardConfig && rewardConfig[rewardKey]) {
			const lootbox = this.lootboxFactory.createLootbox(rewardConfig[rewardKey]);
			model.onSomeRewardTargetCollected(lootbox, targetProgress);

			const window = this.windowFactory.createRewardResultLootboxWindow(
				undefined,
				undefined,
				RewardResultWindowBgType.FADE,
			);
			window.init(lootbox.getRewards(), undefined, lootbox.getCardRarity());

			this.windowViewSystem.showWindow(window);
		}
	}
}
