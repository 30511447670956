import { DailyRewardConfig } from '@configs/DailyRewardConfig';
import { DailyRewardWindowViewSetter } from '@interfaces/ViewSetters';
import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { BaseAction } from '@models/network/actions/BaseAction';
import { ClaimDailyBonusRewardAction } from '@models/network/actions/ClaimDailyBonusRewardAction';
import { PresetsModel } from '@models/PresetsModel';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { DailyWindowBaseView } from '@views/windows/dailyWindow/DailyWindowBaseView';
import { RewardResultWindowBgType } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';

export class DailyRewardWindowViewController extends PIXI.utils.EventEmitter implements DailyRewardWindowViewSetter {
	constructor(
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly windowSystem: WindowViewSystem,
		private readonly rewardFactory: RewardFactory,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly presetsModel: PresetsModel,
		private readonly dailyRewardsModel: DailyRewardsModel,
	) {
		super();
	}

	public setDailyRewardWindowView(view: DailyWindowBaseView): void {
		view.on(DailyWindowBaseView.EVENT_REWARD_COLLECTED, this.onRewardCollected, this);
	}

	private async onRewardCollected(dailyReward: DailyRewardConfig): Promise<void> {
		this.dailyRewardsModel.setRewardCollected();

		let rewardDescription;

		if (dailyReward.getRarity() != null) {
			rewardDescription = await this.networkRequestSender
				.sendGetDailyRewards(dailyReward.getDay(), dailyReward.getBonusType());
		} else {
			const count = dailyReward.getRewardCount();

			rewardDescription = {
				/* eslint-disable @typescript-eslint/naming-convention */
				reward_type: dailyReward.getRewardType(),
				reward_id: dailyReward.getRewardId(),
				reward_qty: this.presetsModel.parsePresetValue(count),
				/* eslint-enable @typescript-eslint/naming-convention */
			};
		}

		const rewards = [rewardDescription];

		this.showRewardsWindow(rewards);

		const action = new ClaimDailyBonusRewardAction(
			dailyReward.getDay(),
			dailyReward.getBonusType(),
			rewards,
		);

		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private showRewardsWindow(rewards: RewardDescriptionType[]): void {
		const rewardModels = rewards.map((reward) => this.rewardFactory.createReward(reward));
		const rewardResultWindow = this.windowFactory.createRewardResultWindow(
			undefined,
			undefined,
			RewardResultWindowBgType.SUMMON,
		);

		rewardResultWindow.init(rewardModels);
		this.windowSystem.showWindow(rewardResultWindow);
	}
}
