import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70004View } from '@views/characters/events/event7/EventCharacter70004View';
import { EventCharacter70005View } from '@views/characters/events/event7/EventCharacter70005View';
import { EventCharacter70006View } from '@views/characters/events/event7/EventCharacter70006View';

export class EventBusiness70002View extends BusinessBaseView {
	constructor() {
		const eventCharacter70004View = new EventCharacter70004View();
		const eventCharacter70005View = new EventCharacter70005View();
		const eventCharacter70006View = new EventCharacter70006View();

		super('business70002', [eventCharacter70004View, eventCharacter70005View, eventCharacter70006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b2_decor1']).setTransform(-83, -10, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(157, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor4']).setTransform(-265, -55, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor3']).setTransform(295, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor2']).setTransform(224, -36, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b2_decor5']).setTransform(-224, -17, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b2_decor6']).setTransform(-270, 28, 0.5, 0.5) as PIXI.Sprite,
			...this.decorations,
			eventCharacter70004View as PIXI.DisplayObject,
			eventCharacter70005View,
			eventCharacter70006View,
		);
	}
}
