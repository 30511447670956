import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { BusinessBaseViewSetter } from '@interfaces/ViewSetters';
import { BusinessBaseView, BusinessUIViewType } from '@views/businesses/ui/BusinessBaseView';
import { BusinessAcquiredUIView } from '@views/businesses/ui/acquired/BusinessAcquiredUIView';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export class TutorialStepUnlockTypeBusinessAcquiredModel extends TutorialStepUnlockTypeBaseModel implements BusinessBaseViewSetter {
	private readonly businessKey: string;

	private view: BusinessBaseView;

	constructor(key: string, businessKey: string) {
		super(key, TutorialStepUnlockTypes.BUSINESS_ACQUIRED);

		this.businessKey = businessKey;
	}

	public setBusinessBaseView(view: BusinessBaseView): void {
		this.view = view;
		view.on(BusinessBaseView.EVENT_NEW_UI_VIEW, this.tryUnlock, this);
	}

	private tryUnlock(newUI: BusinessUIViewType): void {
		if (newUI instanceof BusinessAcquiredUIView) {
			this.view.off(BusinessBaseView.EVENT_NEW_UI_VIEW, this.tryUnlock, this);
			this.onUnlocked();
		}
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	public destroy(): void {
		if (this.view && this.view.listeners(BusinessBaseView.EVENT_NEW_UI_VIEW).includes(this.tryUnlock)) {
			this.view.off(BusinessBaseView.EVENT_NEW_UI_VIEW, this.tryUnlock, this);
		}
		super.destroy();
	}
}
