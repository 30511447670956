import { AbstractQuest } from './AbstractQuest';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { QuestType } from '@src/types/QuestTypes';
import { QuestRestoreStateData } from '@src/types/SaveTypes';

export class QuestGetSoftMoneyFromBusiness extends AbstractQuest {
	private readonly softMoneyModel: SoftMoneyModel;

	private targetAmount: SoftMoneyNumber;
	private readonly businessKey: string;

	private currentAmount: SoftMoneyNumber;

	constructor(
		questKey: string,
		questType: QuestType,
		businessKey: string,
		softMoneyModel: SoftMoneyModel,
		lineId: number,
		mainWindowViewParameters: string[],
		amount?: SoftMoneyNumber,
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.softMoneyModel = softMoneyModel;
		this.businessKey = businessKey;

		this.targetAmount = amount;
		this.currentAmount = SoftMoneyNumber.ZERO;

		this.trackProgressStart();
	}

	public getTargetAmount(): SoftMoneyNumber {
		return this.targetAmount;
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = SoftMoneyNumber.createFromString(saveData.progress.toString());

		if (saveData && saveData.completeValue !== undefined) {
			this.targetAmount = SoftMoneyNumber.createFromString(saveData.completeValue);
		}

		if (this.currentAmount.greaterThanOrEqualTo(this.targetAmount)) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.completed ? this.getTotalProgressValue() : this.currentAmount.toString();
	}

	public getCurrentProgressValueAsSoftMoney(): SoftMoneyNumber {
		return this.completed ? this.targetAmount : this.currentAmount;
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : Number(this.currentAmount.divide(this.targetAmount).toRawString(2));
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}

	private onMoneyValueIncreased(deltaIncrease: SoftMoneyNumber, originKey?: string): void {
		if (originKey && originKey === this.businessKey) {
			this.currentAmount = this.currentAmount.add(deltaIncrease);

			if (this.currentAmount.greaterThanOrEqualTo(this.targetAmount)) {
				this.complete();
			} else {
				this.emit(AbstractQuest.EVENT_PROGRESS, this);
			}
		}
	}

	protected trackProgressStop(): void {
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this, false);
	}

	protected trackProgressStart(): void {
		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_INCREASED, this.onMoneyValueIncreased, this);
	}
}
