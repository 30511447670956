import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character25View } from '@views/characters/main/Character25View';
import { Character26View } from '@views/characters/main/Character26View';
import { Character27View } from '@views/characters/main/Character27View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business9View extends BusinessBaseView {
	constructor() {
		const character25 = new Character25View();
		const character26 = new Character26View();
		const character27 = new Character27View();

		super('business9', [character25, character26, character27]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b9_decor3']).setTransform(137, 66.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor5']).setTransform(0, -74, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character27,
			character26 as PIXI.DisplayObject,
			character25,
		);
	}
}
