import { MaxButtonValueData } from '@configs/ConstantsConfig';
import {
	MainWindowElementConfig,
	MainWindowTarget,
	MainWindowUnlockType,
	UnlockTypeEpisodeProgress,
	UnlockTypeEpisodeProgressCustomersOnBusiness,
	UnlockTypeTasksCompleted
} from '@configs/MainWindowConfig';
import { BankModel } from '@models/bank/BankModel';
import { BoostModel } from '@models/BoostModel';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { CheatModel } from '@models/CheatModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { EventLevelModel, EventLevelStateType } from '@models/level/EventLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { SkillModel } from '@models/skills/SkillModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { SoundController } from '@src/main/SoundController';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import * as TWEEN from '@tweenjs/tween.js';
import { ViewportView } from '@views/components/ViewportView';
import { EventLevelButtonClaimRewardsView } from '../eventButton/EventLevelButtonClaimView';
import { EventLevelButtonGoToEventView } from '../eventButton/EventLevelButtonGoToEventView';
import { EventLevelButtonPromoView } from '../eventButton/EventLevelButtonPromoView';
import { GotoNextLevelButtonView } from '../GotoNextLevelButtonView';
import { LevelChallengeView } from '../levelChallenge/LevelChallengeView';
import { LevelTopUIPanelView } from '../topUIPanel/LevelTopUIPanelView';
import { GameUIBaseView } from './GameUIBaseView';
import { HintTypes } from '@src/types/HintTypes';
import { NewsModel } from '@models/NewsModel';
import { ButtonNewsView } from '../ButtonNewsView';
import { DailyRewardsModel } from '@models/DailyRewardsModel';
import { ButtonDailyView } from '../bottomRightPanel/ButtonDailyView';
import { MaintenanceModel } from '@models/MaintenanceModel';

export class LevelGameUIView extends GameUIBaseView {
	public static readonly EVENT_BUTTON_LEVEL_CHALLENGE_CLICK = Symbol();
	public static readonly EVENT_BUTTON_EVENT_LEVEL_GOTO_CLICK = Symbol();
	public static readonly EVENT_BUTTON_EVENT_LEVEL_COLLECT_REWARDS_CLICK = Symbol();
	public static readonly EVENT_BUTTON_GOTO_EVENT_CLICK = Symbol();
	public static readonly EVENT_BUTTON_GOTO_NEXT_LEVEL_SHOWED = Symbol();
	public static readonly EVENT_BUTTON_GOTO_NEXT_LEVEL_CLICK = Symbol();
	public static readonly EVENT_BUTTON_NEWS_CLICK = Symbol();
	public static readonly EVENT_BUTTON_DAILY_CLICK = Symbol();

	private readonly levelModel: LevelModel;
	private readonly cheatModel: CheatModel;
	private readonly businessModels: Map<string, BusinessModel>;
	private readonly levelChallengeModel: LevelChallengeModel;
	private readonly questLinesModel: QuestLinesModel;
	private readonly timedQuestLinesModel: TimedQuestLinesModel;
	private readonly topUIPanel: LevelTopUIPanelView;
	private readonly eventLevelModel: EventLevelModel;
	private readonly newsModel: NewsModel;
	private readonly dailyRewardsModel: DailyRewardsModel;

	private readonly gotoNextLevelButton: GotoNextLevelButtonView;
	private readonly levelChallengeView: LevelChallengeView;
	private readonly eventLevelButtonGoToEvent: EventLevelButtonGoToEventView;
	private readonly eventLevelButtonClaimRewards: EventLevelButtonClaimRewardsView;
	private readonly eventLevelButtonPromo: EventLevelButtonPromoView;
	private readonly buttonNews: ButtonNewsView;
	private readonly dailyButton: ButtonDailyView;

	private readonly mainWindowElementConfigMap: Map<MainWindowTarget, MainWindowElementConfig>;
	private readonly mainWindowTargetUnlockBusinessMap: Map<MainWindowTarget, BusinessModel>;

	private readonly maxAllowedLevel: number;
	private readonly gotoNextLevelContent: PIXI.Container;
	private readonly eventsButtonContainer: PIXI.Container;
	private readonly isNewsEnabled: boolean;

	constructor(
		levelModel: LevelModel,
		eventLevelModel: EventLevelModel,
		viewport: ViewportView,
		serverTime: ServerTimeModel,
		gameProfileModel: GameProfileModel,
		softMoneyModel: SoftMoneyModel,
		hardMoneyModel: HardMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		businessModels: Map<string, BusinessModel>,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		skillModels: Map<string, SkillModel>,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		bankModel: BankModel,
		questLinesModel: QuestLinesModel,
		timedQuestLinesModel: TimedQuestLinesModel,
		levelChallengeModel: LevelChallengeModel,
		epicQuestCollectionsModels: Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>,
		summonModelsPool: SummonModelsPool,
		cheatModel: CheatModel,
		interactionManager: PIXI.interaction.InteractionManager,
		maxButtonValueData: MaxButtonValueData[],
		mainWindowElementConfigs: Map<MainWindowTarget, MainWindowElementConfig>,
		env: string,
		questTargetDuration: number,
		newsModel: NewsModel,
		isNewsEnabled: boolean,
		dailyRewardsModel: DailyRewardsModel,
		maintenanceModel: MaintenanceModel,
		maxAllowedLevel?: number,
		buttonMaxCustomerCurrentValueId?: number,
	) {
		const topUIPanel = new LevelTopUIPanelView(
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			Array.from(businessModels.values()),
			skillModels,
			gameProfileModel,
		);
		super(
			serverTime,
			viewport,
			topUIPanel,
			skillModels,
			characterModels,
			upgradeModels,
			totemModels,
			boostModels,
			farewellBoostModel,
			galleryVideoModels,
			epicQuestCollectionsModels,
			summonModelsPool,
			bankModel,
			interactionManager,
			questTargetDuration,
			maxButtonValueData,
			env,
			maintenanceModel,
			buttonMaxCustomerCurrentValueId,

		);

		this.isNewsEnabled = isNewsEnabled;
		this.maxAllowedLevel = maxAllowedLevel;
		this.topUIPanel = topUIPanel;
		this.levelModel = levelModel;
		this.businessModels = businessModels;
		this.questLinesModel = questLinesModel;
		this.timedQuestLinesModel = timedQuestLinesModel;
		this.levelChallengeModel = levelChallengeModel;
		this.dailyRewardsModel = dailyRewardsModel;

		this.mainWindowTargetUnlockBusinessMap = new Map();
		this.mainWindowElementConfigMap = mainWindowElementConfigs;

		this.cheatModel = cheatModel;
		this.cheatModel.on(
			CheatModel.EVENT_UPDATE_MAIN_WINDOW_CONFIG_ENABLED,
			this.updateMainWindowElementsVisible,
			this,
		);

		this.eventLevelModel = eventLevelModel;
		this.eventLevelModel.on(EventLevelModel.EVENT_INITED, this.onEventModelUpdated, this);

		this.eventLevelButtonPromo = new EventLevelButtonPromoView(
			this.eventLevelModel,
			serverTime,
		);
		this.eventLevelButtonPromo.position.set(1830, 220);

		this.eventLevelButtonGoToEvent = new EventLevelButtonGoToEventView(this.eventLevelModel);
		this.eventLevelButtonGoToEvent.position.set(1830, 220);
		this.eventLevelButtonGoToEvent.on(
			EventLevelButtonGoToEventView.EVENT_CLICK,
			() => this.emit(LevelGameUIView.EVENT_BUTTON_EVENT_LEVEL_GOTO_CLICK),
			this,
		);

		this.eventLevelButtonClaimRewards = new EventLevelButtonClaimRewardsView();
		this.eventLevelButtonClaimRewards.position.set(1830, 220);
		this.eventLevelButtonClaimRewards.on(
			EventLevelButtonClaimRewardsView.EVENT_CLICK,
			() => this.emit(LevelGameUIView.EVENT_BUTTON_EVENT_LEVEL_COLLECT_REWARDS_CLICK),
			this,
		);

		this.levelChallengeView = new LevelChallengeView(this.levelChallengeModel);
		this.levelChallengeView.on(
			LevelChallengeView.EVENT_CLICK,
			() => this.emit(LevelGameUIView.EVENT_BUTTON_LEVEL_CHALLENGE_CLICK),
			this,
		);
		this.levelChallengeView.position.set(192, 120);

		this.gotoNextLevelContent = new PIXI.Container();
		this.gotoNextLevelButton = new GotoNextLevelButtonView();
		this.gotoNextLevelButton.visible = false;
		this.gotoNextLevelButton.on(GotoNextLevelButtonView.EVENT_CLICK, () => {
			this.gotoNextLevelButton.setEnabled(false);
			this.emit(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_CLICK);
		}, this);
		this.gotoNextLevelButton.position.set(170, 115);
		this.gotoNextLevelContent.addChild(this.gotoNextLevelButton);

		topUIPanel.on(
			LevelTopUIPanelView.EVENT_SHOW_HINT_LEVEL_PANEL_VIEW,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(LevelGameUIView.EVENT_SHOW_HINT, HintTypes.LEVEL_PANEL_HINT, { arrowPosLocal, origin });
			},
			this,
		);

		this.buttonNews = new ButtonNewsView();
		this.buttonNews.on(ButtonNewsView.EVENT_CLICK, () => this.emit(LevelGameUIView.EVENT_BUTTON_NEWS_CLICK));
		this.buttonNews.position.set(324, 1020);
		this.buttonNews.visible = false;

		this.dailyButton = new ButtonDailyView(this.dailyRewardsModel);
		this.dailyButton.on(
			ButtonDailyView.EVENT_CLICK,
			() => this.emit(LevelGameUIView.EVENT_BUTTON_DAILY_CLICK),
			this,
		);
		this.dailyButton.position.set(431, 1020);
		this.dailyButton.visible = false;

		this.newsModel = newsModel;
		this.newsModel.on(NewsModel.EVENT_CHANGED, this.updateButtonNewsInfoIcon, this);
		this.newsModel.on(NewsModel.EVENT_NEWS_SEEN, this.updateButtonNewsInfoIcon, this);
		this.newsModel.on(NewsModel.EVENT_NEWS_CLAIMED, this.updateButtonNewsInfoIcon, this);
		this.updateButtonNewsInfoIcon();

		this.eventsButtonContainer = new PIXI.Container();
		this.eventsButtonContainer.addChild(
			this.eventLevelButtonGoToEvent,
			this.eventLevelButtonClaimRewards as PIXI.DisplayObject,
			this.eventLevelButtonPromo,
		);

		this.dailyRewardsModel.once(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED, this.updateDailyRewardButtonVisible, this);

		this.levelModel.on(LevelModel.EVENT_PROGRESS, this.onSomeMainWindowTargetUnlockEpisodeTasksCompleted, this);

		this.mainUI.addChild(
			this.gotoNextLevelContent,
			this.levelChallengeView as PIXI.DisplayObject,
			this.buttonNews,
			this.dailyButton,
			this.gotoNextLevelContent,
			this.levelChallengeView,
			this.eventsButtonContainer,
		);
	}

	public init(): void {
		this.topUIPanel.init(
			this.levelModel.getCurrentLevel(),
			this.levelModel.getCurrentSeason(),
			this.levelModel.getCurrentProgress(),
			this.questLinesModel.getPendingProgress(),
			this.levelModel.getTotalProgress(),
		);
		this.gotoNextLevelButton.setEnabled(true);
		const quests = this.questLinesModel.getCurrentQuests();
		const targetQuests = this.timedQuestLinesModel.hasCurrentActiveQuest()
			? quests.concat(this.timedQuestLinesModel.getCurrentQuest())
			: quests;
		this.questLinesView.init(targetQuests);

		this.levelChallengeView.init();
		if (this.levelChallengeView.visible) {
			this.topUIPanel.showAdditionalBackground();
		}

		if (this.levelModel.isProgressCompleted() && this.levelModel.getCurrentLevel() < this.maxAllowedLevel) {
			this.onProgressCompleted(false);
		} else {
			this.hideGoToNextLevelButton();
		}

		this.updateLevelChallengeViewVisible();
		this.updateMainWindowElementsVisible();
		this.updateEventButtonVisible();

		this.updateQuestLinesPosition();
		this.updateOfferLinesPosition();

		SoundController.getInstance().setSeasonNumberTheme(this.levelModel.getCurrentSeason());
		SoundController.getInstance().playMainBg();

		super.init();
	}

	private onEventModelUpdated(): void {
		this.updateEventButtonVisible();
		this.updateEventButtonIcon();

		this.updateQuestLinesPosition();
		this.updateOfferLinesPosition();
	}

	private updateLevelChallengeViewVisible(): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		this.levelChallengeView.visible = !zoomedLocal && !this.levelModel.isProgressCompleted() && this.levelChallengeModel.hasChallenges();
	}

	private updateGoToNextLevelButtonVisible(): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		this.gotoNextLevelButton.visible = !zoomedLocal && this.levelModel.isProgressCompleted() && this.levelModel.getCurrentLevel() < this.maxAllowedLevel;
	}

	private updateCollectionButtonVisible(): void {
		const showLockedHandler = (unlockValue: number): void => {
			this.collectionButton.visible = true;
			this.collectionFade.visible = true;
			this.collectionButton.showLocked(unlockValue);
			this.collectionButton.setLocked(true);
		};
		const showUnlockedHandler = (): void => {
			this.collectionButton.visible = true;
			this.collectionFade.visible = true;
			this.collectionButton.showUnlocked();
			this.collectionButton.setLocked(false);
		};
		const showHiddenHandler = (): void => {
			this.collectionButton.visible = false;
			this.collectionFade.visible = false;
			this.collectionButton.setLocked(true);
		};
		this.updateMainWindowElementVisible(
			showLockedHandler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.COLLECTION_BUTTON),
		);
	}

	private updateMaxCustomersButtonVisible(): void {
		const showLockedHandler = (): void => {
			this.buttonMaxCustomersView.visible = true;
			this.buttonMaxCustomersView.showLocked();
			this.buttonMaxCustomersView.setLocked(true);
		};
		const showUnlockedHandler = (): void => {
			this.buttonMaxCustomersView.visible = true;
			this.buttonMaxCustomersView.showUnlocked();
			this.buttonMaxCustomersView.setLocked(false);
		};
		const showHiddenHandler = (): void => {
			this.buttonMaxCustomersView.visible = false;
			this.buttonMaxCustomersView.setLocked(true);
		};
		this.updateMainWindowElementVisible(
			showLockedHandler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.MAX_BUTTON),
		);
	}

	protected updateBoostViewVisible(): void {
		const showLockedHanler = (): void => {
			this.boostView.visible = true;
			this.boostView.showLockedFrom();
		};

		const showUnlockedHandler = (): void => {
			this.boostView.visible = true;
			this.boostView.showUnlocked();
		};

		const showHiddenHandler = (): void => {
			this.boostView.visible = false;
		};

		this.updateMainWindowElementVisible(
			showLockedHanler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.BOOST_BUTTON),
		);
	}

	private updateDailyRewardButtonVisible(): void {
		const isAvailable = this.dailyRewardsModel.getIsEnabled()
			&& this.levelModel.getCurrentLevel() >= this.dailyRewardsModel.getMinLevel()
			&& this.dailyRewardsModel.isInited();

		const showLockedHanler = (): void => {
			this.dailyButton.visible = isAvailable;
			this.dailyButton.setLocked(true);
		};

		const showUnlockedHandler = (): void => {
			this.dailyButton.visible = isAvailable;
			this.dailyButton.setLocked(false);
		};

		const showHiddenHandler = (): void => {
			this.dailyButton.visible = false;
		};

		this.updateMainWindowElementVisible(
			showLockedHanler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.DAILY_REWARD_BUTTON),
		);
	}

	private updateNewsButtonVisible(): void {
		const showLockedHanler = (): void => {
			this.buttonNews.visible = this.isNewsEnabled;
			this.buttonNews.setLocked(true);
		};

		const showUnlockedHandler = (): void => {
			this.buttonNews.visible = this.isNewsEnabled;
			this.buttonNews.setLocked(false);
		};

		const showHiddenHandler = (): void => {
			this.buttonNews.visible = false;
		};

		this.updateMainWindowElementVisible(
			showLockedHanler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.NEWS_BUTTON),
		);
	}

	private updateBankViewElementsVisible(): void {
		const showLockedHandler = (unlockValue: number): void => {
			this.bankButton.visible = true;
			this.bankFade.visible = true;
			this.bankButton.showLocked(unlockValue);
			this.bankButton.setLocked(true);
			this.topUIPanelBase.getPrestigeMoneyPanel().setButtonPlusVisible(false);
			this.topUIPanelBase.getHardMoneyPanel().setButtonPlusVisible(false);
		};
		const showUnlockedHandler = (): void => {
			this.bankButton.visible = true;
			this.bankFade.visible = true;
			this.bankButton.showUnlocked();
			this.bankButton.setLocked(false);
			this.topUIPanelBase.getPrestigeMoneyPanel().setButtonPlusVisible(false);
			this.topUIPanelBase.getPrestigeMoneyPanel().setButtonPlusVisible(true);
			this.topUIPanelBase.getHardMoneyPanel().setButtonPlusVisible(true);
		};
		const showHiddenHandler = (): void => {
			this.bankButton.visible = false;
			this.bankFade.visible = false;
			this.bankButton.setLocked(true);
			this.topUIPanelBase.getPrestigeMoneyPanel().setButtonPlusVisible(false);
			this.topUIPanelBase.getHardMoneyPanel().setButtonPlusVisible(false);
		};
		this.updateMainWindowElementVisible(
			showLockedHandler,
			showUnlockedHandler,
			showHiddenHandler,
			this.mainWindowElementConfigMap.get(MainWindowTarget.BANK_BUTTON),
		);
	}

	private updateMainWindowElementsVisible(): void {
		this.mainWindowElementConfigMap.forEach((elementConfig) => {
			this.updateMainWindowTarget(elementConfig.target);
			this.updateSkillsPanelViewVisible();

			if (elementConfig.unlockType === MainWindowUnlockType.CUSTOMERS_ON_BUSINESS) {
				const unlockValue = elementConfig.unlockValue as UnlockTypeEpisodeProgressCustomersOnBusiness;
				const model = this.businessModels.get(unlockValue.businessKey);

				if (unlockValue.level === this.levelModel.getCurrentLevel()
					&& unlockValue.customerCount > model.getCustomerCount()) {
					if (!model.listeners(BusinessModel.EVENT_NEW_CUSTOMERS).includes(this.onSomeMainWindowTargetUnlockBusinessNewCustomers)) {
						this.mainWindowTargetUnlockBusinessMap.set(elementConfig.target, model);
						model.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onSomeMainWindowTargetUnlockBusinessNewCustomers, this);
					}
				}
			}
		});
	}

	private onSomeMainWindowTargetUnlockBusinessNewCustomers(): void {
		this.mainWindowElementConfigMap.forEach((elementConfig) => {
			if (elementConfig.unlockType === MainWindowUnlockType.CUSTOMERS_ON_BUSINESS) {
				this.updateMainWindowTarget(elementConfig.target);
			}
		});
	}

	private onSomeMainWindowTargetUnlockEpisodeTasksCompleted(): void {
		this.mainWindowElementConfigMap.forEach((elementConfig) => {
			if (elementConfig.unlockType === MainWindowUnlockType.EPISODE_TASKS_COMPLETED) {
				this.updateMainWindowTarget(elementConfig.target);
			}
		});
	}

	private updateMainWindowTarget(target: MainWindowTarget): void {
		// eslint-disable-next-line default-case
		switch (target) {
			case MainWindowTarget.BANK_BUTTON: {
				this.updateBankViewElementsVisible();
				break;
			}
			case MainWindowTarget.COLLECTION_BUTTON: {
				this.updateCollectionButtonVisible();
				break;
			}
			case MainWindowTarget.MAX_BUTTON: {
				this.updateMaxCustomersButtonVisible();
				break;
			}
			case MainWindowTarget.BOOST_BUTTON: {
				this.updateBoostViewVisible();
				break;
			}
			case MainWindowTarget.DAILY_REWARD_BUTTON: {
				this.updateDailyRewardButtonVisible();
				break;
			}
			case MainWindowTarget.NEWS_BUTTON: {
				this.updateNewsButtonVisible();
				break;
			}
		}
	}

	private updateMainWindowElementVisible(
		showLockedHandler?: (showLockFrom: number) => void,
		showUnlockedHandler?: () => void,
		showHiddenHandler?: () => void,
		config?: MainWindowElementConfig,
	): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		if (zoomedLocal) {
			showHiddenHandler();
		} else if (config && this.cheatModel.isMainWindowConfigEnabled()) {
			switch (config.unlockType) {
				case MainWindowUnlockType.EPISODE_PROGRESS: {
					const unlockValue = config.unlockValue as UnlockTypeEpisodeProgress;
					if (this.levelModel.getCurrentLevel() < unlockValue.level) {
						if (config.showLockFrom !== undefined) {
							showLockedHandler(config.showLockFrom);
						} else {
							showHiddenHandler();
						}
					} else {
						showUnlockedHandler();
					}
					break;
				}
				case MainWindowUnlockType.CUSTOMERS_ON_BUSINESS: {
					const unlockValue = config.unlockValue as UnlockTypeEpisodeProgressCustomersOnBusiness;
					const model = this.businessModels.get(unlockValue.businessKey);
					if (unlockValue.level < this.levelModel.getCurrentLevel()) {
						showUnlockedHandler();
					} else if (unlockValue.level === this.levelModel.getCurrentLevel()) {
						if (model.getCustomerCount() >= unlockValue.customerCount) {
							showUnlockedHandler();
						} else if (config.showLockFrom !== undefined) {
							showLockedHandler(config.showLockFrom);
						} else {
							showHiddenHandler();
						}
					} else if (config.showLockFrom !== undefined) {
						showLockedHandler(config.showLockFrom);
					} else {
						showHiddenHandler();
					}
					break;
				}
				case MainWindowUnlockType.EPISODE_TASKS_COMPLETED: {
					const unlockValue = config.unlockValue as UnlockTypeTasksCompleted;

					if (this.levelModel.getCurrentLevel() > unlockValue.level
						|| this.levelModel.getCurrentLevel() === unlockValue.level && this.levelModel.getCurrentProgress() >= unlockValue.tasksProgress) {
						showUnlockedHandler();
					} else if (config.showLockFrom !== undefined) {
						showLockedHandler(config.showLockFrom);
					} else {
						showHiddenHandler();
					}
					break;
				}
				default:
					throw new Error(`Unsupported unlockType '${config.unlockType}'`);
			}
		} else {
			showUnlockedHandler();
		}
	}

	private updateQuestLinesPosition(): void {
		if (this.gotoNextLevelButton?.visible) {
			this.questLinesView.y = 233;
		} else if (this.levelChallengeView?.visible) {
			this.questLinesView.y = 233;
		} else {
			this.questLinesView.y = 203;
		}
	}

	private updateEventButtonIcon(): void {
		if (this.eventLevelModel.getState() !== EventLevelStateType.DEFAULT) {
			const key: string = this.eventLevelModel.getKey();

			this.eventLevelButtonGoToEvent.setEventKeyIcon(key);
			this.eventLevelButtonPromo.setEventKeyIcon(key);
			this.eventLevelButtonClaimRewards.setEventKeyIcon(key);
		}
	}

	private updateEventButtonVisible(): void {
		switch (this.eventLevelModel.getState()) {
			case EventLevelStateType.EVENT_AVAILABLE: {
				this.eventLevelButtonGoToEvent.visible = true;
				this.eventLevelButtonPromo.visible = false;
				this.eventLevelButtonClaimRewards.visible = false;
				break;
			}
			case EventLevelStateType.REWARD_COLLECT: {
				this.eventLevelButtonGoToEvent.visible = false;
				this.eventLevelButtonPromo.visible = false;
				this.eventLevelButtonClaimRewards.visible = true;
				break;
			}
			case EventLevelStateType.SHOW_PROMO: {
				this.eventLevelButtonGoToEvent.visible = false;
				this.eventLevelButtonPromo.visible = true;
				this.eventLevelButtonClaimRewards.visible = false;
				break;
			}
			default: {
				this.eventLevelButtonGoToEvent.visible = false;
				this.eventLevelButtonClaimRewards.visible = false;
				this.eventLevelButtonPromo.visible = false;
			}
		}
	}

	private updateOfferLinesPosition(): void {
		const someEventButtonVisible = this.eventLevelButtonGoToEvent?.visible
			|| this.eventLevelButtonClaimRewards?.visible
			|| this.eventLevelButtonPromo?.visible;

		if (someEventButtonVisible) {
			this.bankOfferLinesUIView.y = 430;
		} else {
			this.bankOfferLinesUIView.y = 220;
		}
	}

	protected updateSkillsPanelViewVisible(): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		if (zoomedLocal) {
			this.skillsPanelView.visible = false;
		} else if (
			this.cheatModel.isMainWindowConfigEnabled()
			&& this.mainWindowElementConfigMap.has(MainWindowTarget.SKILLS_PANEL)
			&& this.hasOpenedSkill
		) {
			const config = this.mainWindowElementConfigMap.get(MainWindowTarget.SKILLS_PANEL);
			switch (config.unlockType) {
				case MainWindowUnlockType.EPISODE_PROGRESS: {
					const unlockValue = config.unlockValue as UnlockTypeEpisodeProgress;
					this.skillsPanelView.visible = this.levelModel.getCurrentLevel() >= unlockValue.level;
					break;
				}
				default:
					throw new Error(`Unsupported unlockType ${config.unlockType}`);
			}
		} else {
			this.skillsPanelView.visible = this.hasOpenedSkill;
		}
	}

	private hideGoToNextLevelButton(): void {
		const nextLevelAllowed: boolean = this.levelModel.getCurrentLevel() < this.maxAllowedLevel;
		if (this.levelModel.isProgressCompleted() && !nextLevelAllowed) {
			this.gotoNextLevelButton.setLocked();
			this.gotoNextLevelButton.visible = true;
		}
		this.updateQuestLinesPosition();
		if (!this.levelChallengeView.visible) {
			this.topUIPanel.hideAdditionalBackground();
		}
		if (!this.levelModel.isProgressCompleted()) {
			this.gotoNextLevelButton.visible = false;
		}
	}

	public onProgressCompleted(playSound: boolean = true): void {
		const nextLevelAllowed: boolean = this.levelModel.getCurrentLevel() < this.maxAllowedLevel;
		if (this.levelChallengeView?.visible) {
			this.levelChallengeView.startCompletedAnimation().then(() => {
				this.gotoNextLevelButton.show(this.levelChallengeModel.getCompletedTargetTimeId());
				if (!nextLevelAllowed) {
					this.gotoNextLevelButton.setLocked();
				}
				this.updateQuestLinesPosition();
			});
		} else if (nextLevelAllowed) {
			this.gotoNextLevelButton.show(this.levelChallengeModel.getCompletedTargetTimeId());
			this.topUIPanel.showAdditionalBackground();
			this.updateQuestLinesPosition();
		}

		if (playSound) {
			SoundController.getInstance().playLevelComplete();
		}

		this.emit(LevelGameUIView.EVENT_BUTTON_GOTO_NEXT_LEVEL_SHOWED, this.gotoNextLevelButton);
	}

	public setLevelProgress(
		current: number,
		pending: number,
		playProgressAnimation: boolean,
	): void {
		this.topUIPanel.setLevelProgress(current, pending, playProgressAnimation);
	}

	public updateTimedQuestLine(quest: AbstractQuest): void {
		this.questLinesView.setTimedQuest(quest);
	}

	public hideTimedQuestLine(): void {
		this.questLinesView.hideTimedQuest();
	}

	protected onZoomInStarted(): void {
		super.onZoomInStarted();
		this.topUIPanel.hideAdditionalBackground();

		new TWEEN.Tween({ alpha: 1 }, this.tweenGroup)
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.levelChallengeView.alpha = tweenTarget.alpha;
				this.gotoNextLevelButton.alpha = tweenTarget.alpha;
				this.eventLevelButtonGoToEvent.alpha = tweenTarget.alpha;
				this.eventLevelButtonPromo.alpha = tweenTarget.alpha;
				this.eventLevelButtonClaimRewards.alpha = tweenTarget.alpha;
				this.eventsButtonContainer.alpha = tweenTarget.alpha;
				this.gotoNextLevelContent.alpha = tweenTarget.alpha;
				this.buttonNews.alpha = tweenTarget.alpha;
				this.dailyButton.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.gotoNextLevelButton.visible = false;
				this.levelChallengeView.visible = false;
				this.gotoNextLevelContent.visible = false;
				this.eventsButtonContainer.visible = false;
				this.updateEventButtonVisible();
				this.buttonNews.visible = false;
				this.dailyButton.visible = false;
			})
			.start();
	}

	protected onZoomOutStarted(): void {
		this.topUIPanelBase.onZoomOutStarted();
		this.updateCollectionButtonVisible();
		this.updateMaxCustomersButtonVisible();
		this.updateBankViewElementsVisible();
		this.questLinesView.visible = true;
		this.updateBoostViewVisible();
		this.updateSkillsPanelViewVisible();
		this.updateLevelChallengeViewVisible();
		this.updateGoToNextLevelButtonVisible();
		this.updateMaintanenceTimerVisible();

		this.bankOfferLinesUIView.visible = true;
		this.eventsButtonContainer.visible = true;

		this.updateEventButtonVisible();
		this.updateNewsButtonVisible();
		this.updateDailyRewardButtonVisible();

		if (this.levelChallengeView.visible || this.gotoNextLevelButton.visible) {
			this.topUIPanel.showAdditionalBackground();
		}

		this.gotoNextLevelContent.visible = true;

		new TWEEN.Tween({ alpha: 0 }, this.tweenGroup)
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.collectionButton.alpha = tweenTarget.alpha;
				this.collectionFade.alpha = tweenTarget.alpha;
				this.bankButton.alpha = tweenTarget.alpha;
				this.buttonNews.alpha = tweenTarget.alpha;
				this.dailyButton.alpha = tweenTarget.alpha;
				this.bankFade.alpha = tweenTarget.alpha;
				this.questLinesView.alpha = tweenTarget.alpha;
				this.buttonMaxCustomersView.alpha = tweenTarget.alpha;
				this.skillsPanelView.alpha = tweenTarget.alpha;
				this.boostView.alpha = tweenTarget.alpha;
				this.levelChallengeView.alpha = tweenTarget.alpha;
				this.bankOfferLinesUIView.alpha = tweenTarget.alpha;
				this.gotoNextLevelButton.alpha = tweenTarget.alpha;
				this.eventLevelButtonGoToEvent.alpha = tweenTarget.alpha;
				this.eventLevelButtonPromo.alpha = tweenTarget.alpha;
				this.eventLevelButtonClaimRewards.alpha = tweenTarget.alpha;
				this.eventsButtonContainer.alpha = tweenTarget.alpha;
				this.gotoNextLevelContent.alpha = tweenTarget.alpha;
				this.maintenancePanel.alpha = tweenTarget.alpha;
			})
			.start();
	}

	public getGoToNextLevelButtonContainer(): PIXI.Container {
		return this.gotoNextLevelButton.getInteractiveArea();
	}

	public getLevelProgressBarContainer(): PIXI.Container {
		return this.topUIPanel.getLevelPanel();
	}

	public getGoToEventButtonContainer(): PIXI.Container {
		return this.eventLevelButtonGoToEvent.getInteractiveArea();
	}

	private updateButtonNewsInfoIcon(): void {
		this.buttonNews.setInfoIconVisible(this.newsModel.hasNewNewsItems());
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.cheatModel.off(CheatModel.EVENT_UPDATE_MAIN_WINDOW_CONFIG_ENABLED, this.updateMainWindowElementsVisible, this);

		this.mainWindowTargetUnlockBusinessMap.forEach((model) => {
			model.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onSomeMainWindowTargetUnlockBusinessNewCustomers, this);
		});

		this.eventLevelModel.off(EventLevelModel.EVENT_INITED, this.onEventModelUpdated, this);

		this.newsModel.off(NewsModel.EVENT_CHANGED, this.updateButtonNewsInfoIcon, this);
		this.newsModel.off(NewsModel.EVENT_NEWS_SEEN, this.updateButtonNewsInfoIcon, this);
		this.newsModel.off(NewsModel.EVENT_NEWS_CLAIMED, this.updateButtonNewsInfoIcon, this);

		this.dailyRewardsModel.off(DailyRewardsModel.EVENT_CURRENT_REWARD_UPDATED, this.updateDailyRewardButtonVisible, this);

		this.levelModel.off(LevelModel.EVENT_PROGRESS, this.onSomeMainWindowTargetUnlockEpisodeTasksCompleted, this);

		super.destroy(options);
	}
}
