import { TutorialStepDataBase } from './TutorialStepDataBase';
import { TutorialStepBaseConfig } from '../TutorialStepBaseConfig';

export class TutorialStepBusinessSlotButtonData extends TutorialStepDataBase {
	private readonly targetKey: string;
	private readonly businessKey: string;

	constructor(config: { [key: string]: string }) {
		super(config);
		const params = config[TutorialStepBaseConfig.FIELD_PARAMETER].split(',').map((str: string) => str.trim());
		[this.businessKey, this.targetKey] = params;
	}

	public getTargetKey(): string {
		return this.targetKey;
	}

	public getBusinessKey(): string {
		return this.businessKey;
	}
}
