import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { ModelWithOffer } from '@interfaces/ModelWithOffer';
import { BankPriceResourceTypes } from '@src/types/BankTypes';
import { RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { NumberUtils } from '@src/utils/NumberUtils';
import { ButtonValueTypes } from '@views/components/buttons/ButtonWithOldCostValueView';
import * as cronParser from 'cron-parser';

export class BankBundleModel extends PIXI.utils.EventEmitter implements ModelWithOffer {
	public static readonly DEFAULT_PURCHASE_IMAGE: string = 'bundle_preview';
	public static readonly DEFAULT_PURCHASE_DESCRIPTION: string = '';

	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	private readonly viewKey: string;
	private readonly key: string;
	private readonly nameLocale: string;
	private readonly mostPopular: string;
	private readonly stickerLocaleKey?: string;
	private readonly price: number;
	private readonly oldPrice: number;
	private readonly priceResource: BankPriceResourceTypes;
	private readonly oldPriceResource: BankPriceResourceTypes;
	private readonly buttonPriceResource: ButtonValueTypes;
	private readonly buttonOldPriceResource: ButtonValueTypes;
	private readonly availableOn: number;
	private readonly extraRewardType: RewardTypes;
	private readonly extraRewardId: RewardResourceIdTypes | string;
	private readonly extraRewardCount: number;
	private readonly remainingCount: number;
	private readonly titleLocaleKey: string;
	private readonly descriptionLocaleKey: string;
	private timeleft: number;
	private readonly characterLeftKey: string;
	private readonly characterRightKey: string;
	private readonly bgKey: string;
	private readonly offerKey: string;
	private readonly priority: number;
	private readonly totalBuyTimes: number;
	private readonly bundleGroupKey: string;

	constructor(
		config: BankBundleConfig,
		buttonPriceResource: ButtonValueTypes,
		buttonOldPriceResource: ButtonValueTypes,
		priceConversionCoeff: number,
		acceptedBuyTimes?: number,
		savedAvailableOn?: number,
	) {
		super();

		this.viewKey = config.getViewKey();
		this.key = config.getKey();
		this.nameLocale = config.getNameLocale();
		this.mostPopular = config.getMostPopular();
		this.stickerLocaleKey = config.getStickerLocaleKey();
		this.buttonPriceResource = buttonPriceResource;
		this.buttonOldPriceResource = buttonOldPriceResource;
		this.priceResource = config.getPriceResource();
		this.price = NumberUtils.convertPrice(config.getPrice(), this.priceResource, priceConversionCoeff);
		if (config.hasOldPrice()) {
			this.oldPriceResource = config.getOldPriceResource();
			this.oldPrice = NumberUtils.convertPrice(Number(config.getOldPrice()), this.oldPriceResource, priceConversionCoeff);
		}
		const nextAvailableOn: number = Math.floor(cronParser.parseExpression(config.getRefreshPeriod(), { utc: true }).next().getTime() / 1000);
		if (savedAvailableOn !== undefined && savedAvailableOn > nextAvailableOn) {
			this.availableOn = savedAvailableOn;
		} else {
			this.availableOn = nextAvailableOn;
		}
		this.extraRewardType = config.getExtraRewardType();
		this.extraRewardId = config.getExtraRewardId();
		this.extraRewardCount = config.getExtraRewardCount();

		if (acceptedBuyTimes != null) {
			this.remainingCount = config.getTotalBuyTimes() - acceptedBuyTimes;
		} else {
			this.remainingCount = config.getTotalBuyTimes();
		}

		this.titleLocaleKey = config.getTitleLocaleKey();
		this.descriptionLocaleKey = config.getDescriptionLocaleKey();
		this.timeleft = 0;
		this.characterLeftKey = config.getCharacterLeftKey();
		this.characterRightKey = config.getCharacterRightKey();
		this.bgKey = config.getBgKey();
		if (config.hasPriority()) {
			this.priority = config.getPriority();
		} else {
			this.priority = 0;
		}
		this.totalBuyTimes = config.getTotalBuyTimes();

		if (config.hasOfferKey()) {
			this.offerKey = config.getOfferKey();
		}

		this.bundleGroupKey = config.getBundleGroupKey();
	}

	public getBundleGroupKey(): string {
		return this.bundleGroupKey;
	}

	public hasBundleGroupKey(): boolean {
		return Boolean(this.bundleGroupKey);
	}

	public getTotalBuyTimes(): number {
		return this.totalBuyTimes;
	}

	public getPriority(): number {
		return this.priority;
	}

	public hasOfferKey(): boolean {
		return this.offerKey !== undefined;
	}

	public getOfferKey(): string {
		return this.offerKey;
	}

	public getBgKey(): string {
		return this.bgKey;
	}

	public hasCharacterRightKey(): boolean {
		return Boolean(this.characterRightKey);
	}

	public getCharacterLeftKey(): string {
		return this.characterLeftKey;
	}

	public getCharacterRightKey(): string {
		return this.characterRightKey;
	}

	public getViewKey(): string {
		return this.viewKey;
	}

	public getKey(): string {
		return this.key;
	}

	public getNameLocale(): string {
		return this.nameLocale;
	}

	public getMostPopularLocaleKey(): string {
		return this.mostPopular;
	}

	public hasSticker(): boolean {
		return this.stickerLocaleKey !== undefined;
	}

	public getStickerLocaleKey(): string {
		return this.stickerLocaleKey;
	}

	public getPrice(): number | undefined {
		return this.price;
	}

	public getOldPrice(): number {
		return this.oldPrice;
	}

	public hasOldPrice(): boolean {
		return Boolean(this.oldPrice);
	}

	public isFreePrice(): boolean {
		return !this.price;
	}

	public getOldPriceResource(): BankPriceResourceTypes {
		return this.oldPriceResource;
	}

	public getPriceResource(): BankPriceResourceTypes {
		return this.priceResource;
	}

	public getButtonPriceResource(): ButtonValueTypes {
		return this.buttonPriceResource;
	}

	public getButtonOldPriceResource(): ButtonValueTypes {
		return this.buttonOldPriceResource;
	}

	public getAvailableOn(): number {
		return this.availableOn;
	}

	public getRemainingCount(): number {
		return this.remainingCount;
	}

	public getTitleLocaleKey(): string {
		return this.titleLocaleKey;
	}

	public getDescriptionLocaleKey(): string {
		return this.descriptionLocaleKey;
	}

	public getExtraRewardType(): RewardTypes {
		return this.extraRewardType;
	}

	public getExtraRewardId(): RewardResourceIdTypes | string {
		return this.extraRewardId;
	}

	public getExtraRewardCount(): number {
		return this.extraRewardCount;
	}

	public getTimeLeft(): number {
		return this.timeleft;
	}

	public setTimeLeft(timeleft: number): void {
		this.timeleft = timeleft;
	}

	public timeout(): void {
		this.timeleft = 0;
		this.emit(BankBundleModel.EVENT_TIMEOUT);
	}
}
