export enum SkillTypes {
	PROFIT_IMPROVE = 'ProfitImprove',
	TIME_IMPROVE = 'TimeImprove',
	EFFECTIVE_IMPROVE = 'EffectiveImprove',
	CONSTANT_PROFIT = 'ConstantProfit',
	REDUCE_RELOAD = 'ReduceReload',
	TAP_ADD_MONEY = 'TapAddMoney',
	AUTO_TAP = 'AutoTap',
}

export type GirlPrefabHintOffset = {
	x: number;
	y: number;
}
