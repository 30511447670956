export class PrepartyWindowEffectsConfigs {
	public static readonly SPARKS_LEFT = {
		alpha: {
			start: 1,
			end: 0,
		},
		scale: {
			start: 0.05,
			end: 0.001,
			minimumScaleMultiplier: 10,
		},
		color: {
			start: '#ffa012',
			end: '#ffffff',
		},
		speed: {
			start: 200,
			end: 100,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 150,
			max: 210,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 2,
			max: 3,
		},
		blendMode: 'add',
		frequency: 0.1,
		emitterLifetime: -1,
		maxParticles: 30,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly SPARKS_RIGHT = {
		alpha: {
			start: 1,
			end: 0,
		},
		scale: {
			start: 0.05,
			end: 0.001,
			minimumScaleMultiplier: 10,
		},
		color: {
			start: '#ffa012',
			end: '#ffffff',
		},
		speed: {
			start: 200,
			end: 100,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 330,
			max: 410,
		},
		noRotation: false,
		rotationSpeed: {
			min: 0,
			max: 0,
		},
		lifetime: {
			min: 2,
			max: 3,
		},
		blendMode: 'add',
		frequency: 0.1,
		emitterLifetime: -1,
		maxParticles: 30,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly GLOW = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 8,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ff9d00',
			end: '#ffffff',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -5,
			max: 5,
		},
		lifetime: {
			min: 5,
			max: 8,
		},
		blendMode: 'add',
		frequency: 0.3,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};

	public static readonly GLOW2 = {
		alpha: {
			start: 0,
			end: 0.8,
		},
		scale: {
			start: 9,
			end: 0.001,
			minimumScaleMultiplier: 0.85,
		},
		color: {
			start: '#ffff00',
			end: '#ffff00',
		},
		speed: {
			start: 0,
			end: 0,
			minimumSpeedMultiplier: 1,
		},
		acceleration: {
			x: 0,
			y: 0,
		},
		maxSpeed: 0,
		startRotation: {
			min: 0,
			max: 360,
		},
		noRotation: false,
		rotationSpeed: {
			min: -30,
			max: 30,
		},
		lifetime: {
			min: 3,
			max: 6,
		},
		blendMode: 'add',
		frequency: 2,
		emitterLifetime: -1,
		maxParticles: 2000,
		pos: {
			x: 0,
			y: 0,
		},
		addAtBack: false,
		spawnType: 'point',
	};
}
