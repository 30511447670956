import { CollectionBaseView } from '@views/windows/collection/CollectionBaseView';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { EpicQuestCollectionsProgressView } from '@views/windows/collection/EpicQuestCollectionsProgressView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { ScrollOffset } from '@src/types/ScrollViewTypes';
import { HintTypes, HintDataEpicQuiest } from '@src/types/HintTypes';

export abstract class CollectionWithProgressBaseView extends CollectionBaseView {
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	protected readonly prestigeMoneyModel: PrestigeMoneyModel;
	protected readonly hardMoneyModel: HardMoneyModel;

	private questCollectCardsModel: EpicQuestCollectionsModel;
	private collectionProgress: EpicQuestCollectionsProgressView;

	private readonly progressDefaultLocaleKey: string;

	protected constructor(
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		scrollOffset: ScrollOffset,
		questCollectCardsModel?: EpicQuestCollectionsModel,
		progressDefaultLocaleKey?: string,
	) {
		super(scrollOffset);

		this.questCollectCardsModel = questCollectCardsModel;
		this.hardMoneyModel = hardMoneyModel;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.progressDefaultLocaleKey = progressDefaultLocaleKey;

		if (questCollectCardsModel) {
			this.addEpicQuestCollectionsProgress();
		}
	}

	private addEpicQuestCollectionsProgress(): void {
		this.collectionProgress = new EpicQuestCollectionsProgressView(
			this.questCollectCardsModel,
		);
		this.collectionProgress.on(
			EpicQuestCollectionsProgressView.EVENT_SHOW_HINT,
			(bodyArrowOffset: number, arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				const data: HintDataEpicQuiest = {
					bodyOffset: bodyArrowOffset, origin, arrowPosLocal,
				};
				this.emit(
					CollectionWithProgressBaseView.EVENT_SHOW_HINT,
					HintTypes.EPIC_QUEST,
					data,
				);
			},
		);
		this.collectionProgress.position.set(1080, 70);

		LocalizationStorage.getInstance().on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		this.updateText();

		this.addChild(
			this.collectionProgress,
		);
	}

	public getEpicQuestLootbox(): PIXI.Container {
		return this.collectionProgress.getActiveLootbox();
	}

	private updateText(): void {
		this.collectionProgress.setDefaultText(
			LocalizationStorage.getInstance().getLocalizedString(this.progressDefaultLocaleKey),
		);
	}

	public getCollectionCardsProgressView(): EpicQuestCollectionsProgressView {
		return this.collectionProgress;
	}

	public hasEpicQuestCollectionsProgress(): boolean {
		return Boolean(this.collectionProgress);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		const localizationStorage = LocalizationStorage.getInstance();
		if (localizationStorage.listeners(LocalizationStorage.EVENT_NEW_LANGUAGE).includes(this.updateText)) {
			LocalizationStorage.getInstance().off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		}

		super.destroy(options);
	}
}
