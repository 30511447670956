import { SettingsPanelItemBaseView } from './SettingsPanelItemBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { TextAreaInputView } from '@views/components/input/TextAreaInputView';
import { DualStateButtonBaseView } from '@views/components/buttons/DualStateButtonBaseView';
import { SoundController } from '@main/SoundController';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { GameProfileModel } from '@models/GameProfileModel';
import { StringLengthValidator } from '@src/utils/validators/StringLengthValidator';
import { StringRegExpValidation } from '@src/utils/validators/StringRegExpValidation';

class ButtonToggleInputView extends DualStateButtonBaseView {
	constructor() {
		const buttonBgGreenTexture = AssetsStorage.getAtlas('uiAtlas')['button_green'];
		const buttonBgGreen = new PIXI.mesh.NineSlicePlane(buttonBgGreenTexture, 11, 11, 11, 11);
		buttonBgGreen.width = 56;
		buttonBgGreen.height = 56;
		buttonBgGreen.pivot.set(buttonBgGreen.width / 2, buttonBgGreen.height / 2);

		const buttonBgGoldTexture = AssetsStorage.getAtlas('uiAtlas')['button_gold'];
		const buttonBgGold = new PIXI.mesh.NineSlicePlane(buttonBgGoldTexture, 11, 11, 11, 11);
		buttonBgGold.width = 56;
		buttonBgGold.height = 56;
		buttonBgGold.pivot.set(buttonBgGold.width / 2, buttonBgGold.height / 2);

		const buttonBgEditMode = new PIXI.Container();
		buttonBgEditMode.addChild(
			buttonBgGreen as PIXI.DisplayObject,
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['check_username']),
		);

		const buttonBgDefaultMode = new PIXI.Container();
		buttonBgDefaultMode.addChild(
			buttonBgGold as PIXI.DisplayObject,
			new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['edit_username']),
		);

		super(
			buttonBgDefaultMode,
			buttonBgEditMode,
		);
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playButtonClick();

		this.emit(ButtonBaseView.EVENT_CLICK, this.isMainState());
	}

	public toggleButtonState(): void {
		super.toggleButtonState();
	}
}

export class SettingsPanelItemUsernameView extends SettingsPanelItemBaseView {
	public static readonly EVENT_SUBMIT_INPUT: symbol = Symbol();

	private readonly bgInput: PIXI.mesh.NineSlicePlane;
	private readonly inputUsername: TextAreaInputView;
	private readonly buttonToggleInputMode: ButtonToggleInputView;
	private readonly localeKeyPlaceholder: string;
	private editing: boolean;

	constructor(
		iconOffset: number,
		titleLabelOffset: number,
		buttonOffset: number,
		localeKeyLabelTitle: string,
		localeKeyPlaceholder: string,
		icon: PIXI.Sprite,
		enableEditButton: boolean = false,
	) {
		super(
			iconOffset,
			titleLabelOffset,
			localeKeyLabelTitle,
			icon,
		);

		this.localeKeyPlaceholder = localeKeyPlaceholder;

		this.bgInput = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_switch_language_bg'], 13, 0, 13, 0);
		this.bgInput.width = 319;
		this.bgInput.pivot.set(this.bgInput.width / 2, this.bgInput.height / 2);
		this.bgInput.alpha = 0.15;
		this.bgInput.x = buttonOffset;

		this.inputUsername = new TextAreaInputView({
			bgWidth: 270,
			visibleBg: false,
			invalidInputOffsetX: 150,
			hideInvalidInputRedStroke: true,
			maxLength: 10,
		});
		this.inputUsername.x = buttonOffset - 30;
		this.inputUsername.setPlaceholder(this.localizationStorage.getLocalizedString(this.localeKeyPlaceholder));
		this.inputUsername.setEnabled(false);
		this.inputUsername.setInputStyle('color', 'black');

		const validator = new StringLengthValidator(
			'#settings_invalid_lenght_username',
			GameProfileModel.INPUT_USERNAME_MIN_LENGTH,
			GameProfileModel.INPUT_USERNAME_MAX_LENGTH,
		);
		validator.setNext(new StringRegExpValidation(
			'#settings_invalid_lenght_username',
			GameProfileModel.FORMAT_USERNAME,
		));
		this.inputUsername.setValidator(validator);

		this.buttonToggleInputMode = new ButtonToggleInputView();
		this.buttonToggleInputMode.on(ButtonToggleInputView.EVENT_CLICK, this.onButtonToggleInputClick, this);
		this.buttonToggleInputMode.x = buttonOffset + 132;
		this.buttonToggleInputMode.visible = enableEditButton;

		this.addChild(
			this.bgInput,
			this.inputUsername,
			this.buttonToggleInputMode as PIXI.DisplayObject,
		);
	}

	public setUsername(value: string): void {
		this.inputUsername.setText(value);
	}

	protected updateText(): void {
		super.updateText();
		this.inputUsername.setPlaceholder(this.localizationStorage.getLocalizedString(this.localeKeyPlaceholder));
	}

	private onButtonToggleInputClick(): void {
		const inputUsernameOk = this.inputUsername.isValid();

		if (!inputUsernameOk) {
			this.inputUsername.onInvalidInput();
		}

		if (!this.editing) {
			this.editing = true;
			this.bgInput.alpha = 1;
			this.inputUsername.setInputStyle('color', 'white');
			this.inputUsername.setEnabled(true);
			this.buttonToggleInputMode.toggleButtonState();
		} else if (inputUsernameOk) {
			this.buttonToggleInputMode.toggleButtonState();
			this.onSubmitUsername();
			this.editing = false;
			this.bgInput.alpha = 0.15;
			this.inputUsername.setInputStyle('color', 'black');
			this.inputUsername.setEnabled(false);
		}
	}

	private onSubmitUsername(): void {
		this.emit(
			SettingsPanelItemUsernameView.EVENT_SUBMIT_INPUT,
			this.inputUsername.getText().trim(),
		);
	}
}
