import { AssetsStorage } from '@main/AssetsStorage';
import { UpgradeModel } from '@models/UpgradeModel';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { BaseCardLockView } from '../BaseCardLockView';

export class UpgradeCardLockView extends BaseCardLockView {
	constructor(model: UpgradeModel) {
		super(model);

		const rarity = model.getCardRarity();

		const background = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')[`cards_${rarity}_bg_round`], 3, 3, 3, 3);
		background.width = 237;
		background.height = 340;
		background.pivot.set(background.width / 2, background.height / 2);
		background.y = -20;

		const blackOutline = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['b_solid'], 0, 0, 0, 0);
		blackOutline.width = 237;
		blackOutline.height = 377;
		blackOutline.pivot.set(blackOutline.width / 2, blackOutline.height / 2);
		blackOutline.tint = 0x00000;

		const bgKey = model.isFreeActivation() ? 'lock_bg_interior_card2' : 'lock_bg_upgrade_card2';
		const lockSprite = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[bgKey]);
		lockSprite.setTransform(0, -19, 1.28, 1.3);

		const rarityBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`rarity_color_${rarity}_panel`]);
		rarityBg.setTransform(0, -183, 0.45, 0.45);

		const rarityLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#hint_rarity_${rarity}`),
			93,
			{ font: '15px wendyOneShadowBold', align: 'center' },
		);
		rarityLabel.anchor = 0.5;
		rarityLabel.y = -185;

		this.lockText.y = 169;

		this.addChild(
			blackOutline,
			background,
			lockSprite,
			this.lockText as PIXI.DisplayObject,
			rarityBg,
			rarityLabel,
		);
	}
}
