import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { RewardResourceIdTypes } from '@src/types/RewardTypes';

type CardViewData = {
	title: string;
	bg: PIXI.Sprite;
};

export class ResourceCardView extends PIXI.Container {
	private localizationStorage: LocalizationStorage;
	private quantity: string;

	constructor(resourceId: RewardResourceIdTypes, quantity: string) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.quantity = quantity;

		let cardViewData: CardViewData;
		switch (resourceId) {
			case RewardResourceIdTypes.HARD_MONEY:
				cardViewData = this.getHardMoneyCardData();
				break;
			case RewardResourceIdTypes.SOFT_MONEY:
				cardViewData = this.getSoftMoneyCardData();
				break;
			case RewardResourceIdTypes.PRESTIGE_MONEY:
				cardViewData = this.getPrestigeMoneyCardData();
				break;
			default:
				throw new Error(`Unsupported resource id=${resourceId}`);
		}

		const textQuantity = new SizeableBitmapText(`+${this.quantity}`, 400, { font: '55px wendyOneShadowBold' });
		textQuantity.y = 235;
		textQuantity.anchor = 0.5;

		const textTitle = new PIXI.extras.BitmapText(cardViewData.title, { font: '40px wendyOneShadowBold' });
		textTitle.anchor = 0.5;
		textTitle.y = -239;

		this.addChild(
			cardViewData.bg,
			textTitle as PIXI.DisplayObject,
			textQuantity,
		);
	}

	private getHardMoneyCardData(): CardViewData {
		const title = this.localizationStorage.getLocalizedString('#hard_money_name');
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['gold_card']);
		return { bg, title };
	}

	private getSoftMoneyCardData(): CardViewData {
		const title = this.localizationStorage.getLocalizedString('#soft_money_name');
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['money_card']);
		return { bg, title };
	}

	private getPrestigeMoneyCardData(): CardViewData {
		const title = this.localizationStorage.getLocalizedString('#prestige_money_name');
		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['prestige_card']);
		return { bg, title };
	}
}
