import { RewardFactory } from '@src/initializers/RewardFactory';
import { AbstractReward } from '@interfaces/AbstractReward';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { SummonCollectionViewSetter, SummonResultWindowViewSetter } from '@interfaces/ViewSetters';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { SummonResultWindowView } from '@views/windows/collection/summon/SummonResultWindowView';
import { RewardDescriptionType, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { RewardGroups } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { ModelHelper } from '@models/ModelHelper';
import { SummonLoader } from '@src/loaders/summon/SummonLoader';
import { SummonModel } from '@models/SummonModel';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BankTabTypes } from '@src/types/BankTypes';
import { BankWindowView } from '@views/windows/bank/BankWindowView';
import { SummonSavesModel } from '@models/SummonSavesModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { CollectionWindowView } from '@views/windows/collection/CollectionWindowView';

export class SummonWindowViewController implements SummonCollectionViewSetter, SummonResultWindowViewSetter {
	private summonResultWindowView: SummonResultWindowView;
	private summonCollectionWindowView: SummonCollectionView;

	constructor(
		private readonly windowViewSystem: WindowViewSystem,
		private readonly windowFactory: WindowViewBaseFactory,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly rewardFactory: RewardFactory,
		private readonly hardMoneyModel: HardMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
		private readonly serverTime: ServerTimeModel,
		private readonly summonModelsPool: SummonModelsPool,
		private readonly summonSavesModel: SummonSavesModel,
		private readonly summonLoader: SummonLoader,
		private readonly questTargetDuration: number,
	) {
		this.windowViewSystem.on(
			WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED,
			(window: CollectionWindowView) => {
				window.on(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, () => {
					this.summonLoader.init();
				}, this);
			},
			this,
		);
	}

	public setSummonCollectionView(view: SummonCollectionView): void {
		if (!view.listeners(SummonCollectionView.EVENT_BUTTON_BUY_CLICK).includes(this.onButtonBuyClick)) {
			view.on(SummonCollectionView.EVENT_BUTTON_BUY_CLICK, this.onButtonBuyClick, this);
		}
		if (!view.listeners(SummonCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK).includes(this.onButtonMoneyPlusClick)) {
			view.on(SummonCollectionView.EVENT_BUTTON_MONEY_PLUS_CLICK, this.onButtonMoneyPlusClick, this);
		}

		this.summonCollectionWindowView = view;
	}

	public setSummonResultWindowView(view: SummonResultWindowView): void {
		if (!view.listeners(SummonResultWindowView.EVENT_OPEN_AGAIN_CLICK).includes(this.onButtonBuyClick)) {
			view.on(SummonResultWindowView.EVENT_OPEN_AGAIN_CLICK, this.onButtonBuyClick, this);
		}
		this.summonResultWindowView = view;
	}

	private onButtonBuyClick(modelKey: string, amount: number): void {
		const model = this.summonModelsPool.getModelById(modelKey);
		const price = amount === 10 ? model.getPriceValueX10() : model.getPriceValue();
		const canBuySummon = (model.isHardPrice() && this.hardMoneyModel.getValue() >= price)
			|| (model.isPrestigePrice() && this.prestigeMoneyModel.getValue() >= price)
			|| model.isFreePrice();

		if (canBuySummon) {
			this.summonCollectionWindowView.enableButtons(false);
			this.summonCollectionWindowView.showLoadingLabel(model.getKey());
			this.buySummon(model, amount);
		} else if (this.summonCollectionWindowView.getMoneyPanelsButtonPlusInvisible()) {
			this.summonCollectionWindowView.showFlyTextNotEnoughMoney(modelKey, amount);
			this.summonCollectionWindowView.enableButtons(true);
		} else {
			this.showBankWindowPointAt(model.isHardPrice() ? RewardResourceIdTypes.HARD_MONEY : RewardResourceIdTypes.PRESTIGE_MONEY);
			this.summonCollectionWindowView.enableButtons(true);
		}
	}

	private async buySummon(model: SummonModel, count: number): Promise<void> {
		const result = await this.networkRequestSender.sendApplySummon(model.getKey(), count);
		const price = count === 10 ? model.getPriceValueX10() : model.getPriceValue();
		if (model.isPrestigePrice()) {
			this.prestigeMoneyModel.subtract(price);
		} else if (model.isHardPrice()) {
			this.hardMoneyModel.subtract(price);
		}
		const groups = result.groups as RewardGroups;
		let mergedRewardsDescr: RewardDescriptionType[] = [];
		const mergedGroups: RewardGroups = {};
		let offset = 0;
		Object.values(groups).forEach((countInGroup, index) => {
			const rewardsGroup = result.rewards.slice(offset, offset + countInGroup);
			offset += countInGroup;
			const rewardsGroupMerged = ModelHelper.mergeRewardDescriptions(rewardsGroup);
			mergedRewardsDescr = mergedRewardsDescr.concat(rewardsGroupMerged);
			mergedGroups[index] = rewardsGroupMerged.length;
		});

		const rewardCards: AbstractReward[] = [];
		mergedRewardsDescr.forEach((rewardDescr) => {
			const rewardItem = this.rewardFactory.createReward(rewardDescr);
			rewardCards.push(rewardItem);
		});

		this.summonSavesModel.setApplied(
			model.getKey(),
			this.serverTime.getCalculatedISOTime(),
			model.isFreePrice(),
		);

		if (this.summonResultWindowView === undefined) {
			this.summonResultWindowView = this.windowFactory.createSummonResultWindow(
				model,
				count,
			);
			this.summonResultWindowView.once(SummonResultWindowView.EVENT_WINDOW_CLOSED, () => {
				this.summonResultWindowView = undefined;
			}, this);
		}
		this.summonResultWindowView.init(rewardCards, mergedGroups);

		this.windowViewSystem.showWindow(this.summonResultWindowView);

		model.buy(count);
		await this.summonLoader.init();
	}

	private onButtonMoneyPlusClick(): void {
		this.showBankWindow();
	}

	private showBankWindowPointAt(resourceType: RewardResourceIdTypes): void {
		this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_BANK_SHOWED, (bankWindow: BankWindowView) => {
			bankWindow.pointAtResource(resourceType, this.questTargetDuration);
			bankWindow.on(BankWindowView.EVENT_WINDOW_CLOSED, this.onSubWindowClosed, this);
		});
		this.showBankWindow();
	}

	private showBankWindow(): void {
		const window = this.windowFactory.createBankWindow({ tab: BankTabTypes.GEM_SHOP });
		this.windowViewSystem.showWindow(window);
	}

	private onSubWindowClosed(): void {
		this.summonCollectionWindowView.enableButtons(true);
	}
}
