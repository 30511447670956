import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BusinessModel } from '@models/BusinessModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { EventLevelModel, EventLevelStateType } from '@models/level/EventLevelModel';
import { LevelModel } from '@models/level/LevelModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { SummonSavesModel } from '@models/SummonSavesModel';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { BankPaymentSaveModel } from '@models/bank/BankPaymentSaveModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';

export class UnlockValueChecker {
	private static readonly UNLOCK_TYPE_MIN_LEVEL = 'min_lvl';
	private static readonly UNLOCK_TYPE_MAX_LEVEL = 'max_lvl';
	private static readonly UNLOCK_TYPE_MIN_PAYMENT = 'min_payment';
	private static readonly UNLOCK_TYPE_MAX_PAYMENT = 'max_payment';
	private static readonly UNLOCK_TYPE_MIN_AVERAGE_PAYMENT = 'min_avg_payment';
	private static readonly UNLOCK_TYPE_MAX_AVERAGE_PAYMENT = 'max_avg_payment';
	private static readonly UNLOCK_TYPE_MIN_LAST_PAYMENT = 'min_last_payment';
	private static readonly UNLOCK_TYPE_MAX_LAST_PAYMENT = 'max_last_payment';
	private static readonly UNLOCK_TYPE_MIN_ACCOUNT_LIFETIME = 'min_account_lifetime';
	private static readonly UNLOCK_TYPE_MAX_ACCOUNT_LIFETIME = 'max_account_lifetime';
	private static readonly UNLOCK_TYPE_MIN_PAYMENT_COUNT = 'min_payment_count';
	private static readonly UNLOCK_TYPE_MAX_PAYMENT_COUNT = 'max_payment_count';
	private static readonly UNLOCK_TYPE_MIN_TIME_SINCE_EVENT_START = 'min_time_since_event_start';
	private static readonly UNLOCK_TYPE_MAX_TIME_SINCE_EVENT_START = 'max_time_since_event_start';
	private static readonly UNLOCK_TYPE_MIN_TIME_UNTIL_EVENT_END = 'min_time_until_event_end';
	private static readonly UNLOCK_TYPE_MAX_TIME_UNTIL_EVENT_END = 'max_time_until_event_end';
	private static readonly UNLOCK_TYPE_MIN_LEVEL_PROGRESS = 'min_lvl_progress';
	private static readonly UNLOCK_TYPE_MAX_LEVEL_PROGRESS = 'max_lvl_progress';
	private static readonly UNLOCK_TYPE_LOT_BOUGHT = 'lot_bought';
	private static readonly UNLOCK_TYPE_EVENT_LOT_BOUGHT = 'event_lot_bought';
	private static readonly UNLOCK_TYPE_EVENT_LOT_NOT_BOUGHT = 'event_lot_not_bought';
	private static readonly UNLOCK_TYPE_EVENT = 'event';
	private static readonly UNLOCK_TYPE_TUTORIAL_ID_COMPLTED = 'tutor_id_completed';
	private static readonly UNLOCK_TYPE_TUTORIAL_ID_UNCOMPLTED = 'tutor_id_uncompleted';
	private static readonly UNLOCK_TYPE_BOUGHT_BUNDLE = 'bought_bundle';
	private static readonly UNLOCK_TYPE_BOUGHT_SUMMON = 'bought_summon';
	private static readonly UNLOCK_TYPE_CHALLENGE_STAGE = 'challenge_stage';
	private static readonly UNLOCK_TYPE_SIDE_QUEST_ACTIVE = 'side_quest_active';
	private static readonly UNLOCK_TYPE_QUEST_ACTIVE = 'quest_active';
	private static readonly UNLOCK_TYPE_EVENT_QUEST_ACTIVE = 'event_quest_active';
	private static readonly UNLOCK_TYPE_QUEST_COMPLETED = 'quest_completed';
	private static readonly UNLOCK_TYPE_EVENT_QUEST_COMPLETED = 'event_quest_completed';
	private static readonly UNLOCK_TYPE_BUSINESS_ACQUIRED = 'business_acquired';
	private static readonly UNLOCK_TYPE_BUSINESS_NOT_ACQUIRED = 'business_not_acquired';
	private static readonly UNLOCK_TYPE_EVENT_CURRENT_GOAL = 'event_current_goal';
	private static readonly UNLOCK_TYPE_OFFER_MAX_ACTIVATED_TIMES = 'offer_max_activated_times';
	private static readonly UNLOCK_TYPE_EVENT_MIN_TASKS_COMPLETED = 'event_min_tasks_completed';
	private static readonly UNLOCK_TYPE_EVENT_MAX_TASKS_COMPLETED = 'event_max_tasks_completed';
	private static readonly UNLOCK_TYPE_CHARACTER_NOT_ACTIVE = 'character_not_active';
	private static readonly UNLOCK_TYPE_UPGRADE_NOT_ACTIVE = 'upgrade_not_active';
	private static readonly UNLOCK_TYPE_MIN_BUSINESS_CUSTOMERS = 'min_cust_on_biz';
	private static readonly UNLOCK_TYPE_MAX_BUSINESS_CUSTOMERS = 'max_cust_on_biz';

	private readonly checkerMap: Map<string, (value: string) => boolean>;

	constructor(
		private readonly serverTime: ServerTimeModel,
		private readonly bankSavesModel: BankSavesModel,
		private readonly bankPaymentSaveModel: BankPaymentSaveModel,
		private readonly questLinesModel: QuestLinesModel,
		private readonly timedQuestLinesModel: TimedQuestLinesModel,
		private readonly tutorialStepModels: Map<string, TutorialStepBaseModel>,
		private readonly businessModels: Map<string, BusinessModel>,
		private readonly levelChallengeModel: LevelChallengeModel,
		private readonly gameProfileModel: GameProfileModel,
		private readonly summonSavesModel: SummonSavesModel,
		private readonly levelModel: LevelModel,
		private readonly eventLevelModel: EventLevelModel,
		private readonly characterModels: Map<string, CharacterModel>,
		private readonly upgradeModels: Map<string, UpgradeModel>,
	) {
		this.checkerMap = new Map();

		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_LEVEL,
			this.checkUnlockTypeMinLevel.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_LEVEL,
			this.checkUnlockTypeMaxLevel.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_PAYMENT,
			this.checkUnlockTypeMinPayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_PAYMENT,
			this.checkUnlockTypeMaxPayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_AVERAGE_PAYMENT,
			this.checkUnlockTypeMinAveragePayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_AVERAGE_PAYMENT,
			this.checkUnlockTypeMaxAveragePayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_LAST_PAYMENT,
			this.checkUnlockTypeMinLastPayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_LAST_PAYMENT,
			this.checkUnlockTypeMaxLastPayment.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_ACCOUNT_LIFETIME,
			this.checkUnlockTypeMinAccountLifetime.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_ACCOUNT_LIFETIME,
			this.checkUnlockTypeMaxAccountLifetime.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_PAYMENT_COUNT,
			this.checkUnlockTypeMinPaymentCount.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_PAYMENT_COUNT,
			this.checkUnlockTypeMaxPaymentCount.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_TIME_SINCE_EVENT_START,
			this.checkUnlockTypeMinTimeSinceEventStart.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_TIME_SINCE_EVENT_START,
			this.checkUnlockTypeMaxTimeSinceEventStart.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_TIME_UNTIL_EVENT_END,
			this.checkUnlockTypeMinTimeUntilEventEnd.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_TIME_UNTIL_EVENT_END,
			this.checkUnlockTypeMaxTimeUntilEventEnd.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_TIME_UNTIL_EVENT_END,
			this.checkUnlockTypeMaxTimeUntilEventEnd.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_LEVEL_PROGRESS,
			this.checkUnlockTypeMinLevelProgress.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_LEVEL_PROGRESS,
			this.checkUnlockTypeMaxLevelProgress.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_LOT_BOUGHT,
			this.checkUnlockTypeLotBought.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_LOT_BOUGHT,
			this.checkUnlockTypeLotBought.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_LOT_NOT_BOUGHT,
			this.checkUnlockTypeLotNotBought.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT,
			this.checkUnlockTypeEventLevel.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_TUTORIAL_ID_COMPLTED,
			this.checkUnlockTypeTutorialStepCompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_TUTORIAL_ID_UNCOMPLTED,
			this.checkUnlockTypeTutorialStepUncompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_BOUGHT_BUNDLE,
			this.checkUnlockTypeBoughtBundle.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_BOUGHT_SUMMON,
			this.checkUnlockTypeBoughtSummon.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_CHALLENGE_STAGE,
			this.checkUnlockTypeChallengeStage.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_SIDE_QUEST_ACTIVE,
			this.checkUnlockTypeTimedQuestActive.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_QUEST_ACTIVE,
			this.checkUnlockTypeQuestActive.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_QUEST_ACTIVE,
			this.checkUnlockTypeEventQuestActive.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_QUEST_COMPLETED,
			this.checkUnlockTypeQuestCompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_QUEST_COMPLETED,
			this.checkUnlockTypeEventQuestCompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_BUSINESS_ACQUIRED,
			this.checkUnlockTypeBusinessAcquired.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_BUSINESS_NOT_ACQUIRED,
			this.checkUnlockTypeBusinessNotAcquired.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_CURRENT_GOAL,
			this.checkUnlockTypeEventCurrentGoal.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_OFFER_MAX_ACTIVATED_TIMES,
			this.checkUnlockTypeOfferMaxActivatedTimes.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_MIN_TASKS_COMPLETED,
			this.checkUnlockTypeEventMinTasksCompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_EVENT_MAX_TASKS_COMPLETED,
			this.checkUnlockTypeEventMaxTasksCompleted.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_CHARACTER_NOT_ACTIVE,
			this.checkUnlockTypeCharacterNotActive.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_UPGRADE_NOT_ACTIVE,
			this.checkUnlockTypeUpgradeNotActive.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MIN_BUSINESS_CUSTOMERS,
			this.checkUnlockTypeMinBusinessCustomers.bind(this),
		);
		this.checkerMap.set(
			UnlockValueChecker.UNLOCK_TYPE_MAX_BUSINESS_CUSTOMERS,
			this.checkUnlockTypeMaxBusinessCustomers.bind(this),
		);
	}

	public check(
		unlockType: string,
		unlockValue: string,
	): boolean {
		const isSupported = this.checkerMap.has(unlockType);
		if (!isSupported && MODE_DEBUG) {
			throw new Error(`Unsupported unlock type='${unlockType}'`);
		} else if (!isSupported) {
			return false;
		}

		const checkUnlockType = this.checkerMap.get(unlockType);
		return checkUnlockType(unlockValue);
	}

	private checkUnlockTypeMinLevel(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.levelModel.getCurrentLevel() >= value;
	}

	private checkUnlockTypeMaxLevel(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.levelModel.getCurrentLevel() < value;
	}

	private checkUnlockTypeMinPayment(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getTotalPayment() >= value;
	}

	private checkUnlockTypeMaxPayment(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getTotalPayment() <= value;
	}

	private checkUnlockTypeMinAveragePayment(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getAveragePayment() >= value;
	}

	private checkUnlockTypeMaxAveragePayment(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getAveragePayment() <= value;
	}

	private checkUnlockTypeMinLastPayment(unlockValue: string): boolean {
		let result: boolean;

		if (!this.bankPaymentSaveModel.hasPayment()) {
			result = false;
		} else {
			const value = Number(unlockValue) * 86400;
			const notPayingTime = this.serverTime.getCalculatedISOTime() - this.bankPaymentSaveModel.getLastPaymentTime();
			result = notPayingTime >= value;
		}

		return result;
	}

	private checkUnlockTypeMaxLastPayment(unlockValue: string): boolean {
		const value = Number(unlockValue) * 86400;
		const notPayingTime = this.serverTime.getCalculatedISOTime() - this.bankPaymentSaveModel.getLastPaymentTime();
		return notPayingTime <= value;
	}

	private checkUnlockTypeMinAccountLifetime(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.gameProfileModel.getAccountLifetime() >= value;
	}

	private checkUnlockTypeMaxAccountLifetime(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.gameProfileModel.getAccountLifetime() <= value;
	}

	private checkUnlockTypeMinPaymentCount(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getPaymentCount() >= value;
	}

	private checkUnlockTypeMaxPaymentCount(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.bankPaymentSaveModel.getPaymentCount() <= value;
	}

	private checkUnlockTypeMinTimeSinceEventStart(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE
			&& this.eventLevelModel.getRunningTime() >= value;
	}

	private checkUnlockTypeMaxTimeSinceEventStart(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE
			&& this.eventLevelModel.getRunningTime() <= value;
	}

	private checkUnlockTypeMinTimeUntilEventEnd(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE
			&& this.eventLevelModel.getTimeleft() <= value;
	}

	private checkUnlockTypeMaxTimeUntilEventEnd(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE
			&& this.eventLevelModel.getTimeleft() >= value;
	}

	private checkUnlockTypeMinLevelProgress(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.levelModel.getCurrentProgress() >= value;
	}

	private checkUnlockTypeMaxLevelProgress(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.levelModel.getCurrentProgress() < value;
	}

	private checkUnlockTypeLotBought(unlockValue: string): boolean {
		return this.bankSavesModel.isLotBought(unlockValue);
	}

	private checkUnlockTypeLotNotBought(unlockValue: string): boolean {
		return !this.bankSavesModel.isLotBought(unlockValue);
	}

	private checkUnlockTypeEventLevel(unlockValue: string): boolean {
		return this.eventLevelModel.getKey() === unlockValue;
	}

	private checkUnlockTypeTutorialStepCompleted(unlockValue: string): boolean {
		const model = this.tutorialStepModels.get(unlockValue);
		return model
			? this.tutorialStepModels.get(unlockValue).isCompleted()
			: true;
	}

	private checkUnlockTypeTutorialStepUncompleted(unlockValue: string): boolean {
		const model = this.tutorialStepModels.get(unlockValue);
		return model
			? !this.tutorialStepModels.get(unlockValue).isCompleted()
			: true;
	}

	private checkUnlockTypeBoughtBundle(unlockValue: string): boolean {
		return this.bankSavesModel.getBundleAcceptedBuyTimes(unlockValue, this.serverTime.getCalculatedISOTime()) > 0;
	}

	private checkUnlockTypeBoughtSummon(unlockValue: string): boolean {
		return this.summonSavesModel.isApplied(unlockValue);
	}

	private checkUnlockTypeChallengeStage(unlockValue: string): boolean {
		const value = Number(unlockValue);
		return this.levelChallengeModel.getActiveTargetTimeId() + 1 === value;
	}

	private checkUnlockTypeTimedQuestActive(unlockValue: string): boolean {
		let result: boolean;

		if (unlockValue === 'any') {
			result = this.timedQuestLinesModel.hasCurrentActiveQuest();
		} else {
			const currentQuest = this.timedQuestLinesModel.getCurrentQuest();
			result = currentQuest.getKey() === unlockValue;
		}

		return result;
	}

	private checkUnlockTypeQuestActive(unlockValue: string): boolean {
		const [level, questLineId, lineNumber] = unlockValue.split('_').map(str => Number(str));

		const currentQuests = this.questLinesModel.getCurrentQuests();
		if (this.levelModel.getCurrentLevel() === level) {
			return currentQuests.some(quest => {
				const lineId = quest.getLineId();
				return lineId === questLineId - 1
					&& this.questLinesModel.getLineProgress(lineId) === lineNumber - 1;
			});
		}
		return false;
	}

	private checkUnlockTypeEventQuestActive(unlockValue: string): boolean {
		const [eventLevelKey, questLineId, lineNumber] = unlockValue.split('_');

		const currentQuests = this.questLinesModel.getCurrentQuests();
		if (this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE && this.eventLevelModel.getKey() === eventLevelKey) {
			return currentQuests.some(quest => {
				const lineId = quest.getLineId();
				return lineId === Number(questLineId) - 1
					&& this.questLinesModel.getLineProgress(lineId) === Number(lineNumber) - 1;
			});
		}
		return false;
	}

	private checkUnlockTypeQuestCompleted(unlockValue: string): boolean {
		const [level, questLineId, lineNumber] = unlockValue.split('_').map(str => Number(str));

		const currentLevel = this.levelModel.getCurrentLevel();
		let result: boolean;
		if (currentLevel < level) {
			result = false;
		} else if (currentLevel > level) {
			result = true;
		} else {
			result = this.questLinesModel.getLineProgress(questLineId - 1) > lineNumber - 1;
		}
		return result;
	}

	private checkUnlockTypeEventQuestCompleted(unlockValue: string): boolean {
		const [eventLevelKey, questLineId, lineNumber] = unlockValue.split('_');

		return this.eventLevelModel.getKey() === eventLevelKey
			&& this.questLinesModel.getLineProgress(Number(questLineId) - 1) > Number(lineNumber) - 1;
	}

	private checkUnlockTypeBusinessAcquired(unlockValue: string): boolean {
		return this.businessModels.get(unlockValue)?.isAcquired();
	}

	private checkUnlockTypeBusinessNotAcquired(unlockValue: string): boolean {
		return !this.businessModels.get(unlockValue)?.isAcquired();
	}

	private checkUnlockTypeEventCurrentGoal(unlockValue: string): boolean {
		const [eventLevelKey, targetGoalId] = unlockValue.split('_');
		return this.eventLevelModel.getKey() === eventLevelKey
			&& this.eventLevelModel.getState() === EventLevelStateType.EVENT_AVAILABLE
			&& this.eventLevelModel.getCompletedGoalCount() === Number(targetGoalId);
	}

	private checkUnlockTypeOfferMaxActivatedTimes(unlockValue: string): boolean {
		const [offerKey, count] = unlockValue.split('_');
		return this.bankSavesModel.getOfferActivateCount(offerKey) <= Number(count);
	}

	private checkUnlockTypeEventMinTasksCompleted(unlockValue: string): boolean {
		const completedGoalCount = this.eventLevelModel.getCompletedGoalCount();
		const value = Number(unlockValue);
		return completedGoalCount >= value;
	}

	private checkUnlockTypeEventMaxTasksCompleted(unlockValue: string): boolean {
		const completedGoalCount = this.eventLevelModel.getCompletedGoalCount();
		const value = Number(unlockValue);
		return completedGoalCount < value;
	}

	private checkUnlockTypeCharacterNotActive(unlockValue: string): boolean {
		const character = this.characterModels.get(unlockValue);
		return !character.isActivated();
	}

	private checkUnlockTypeUpgradeNotActive(unlockValue: string): boolean {
		const upgrade = this.upgradeModels.get(unlockValue);
		return !upgrade.isActivated();
	}

	private checkUnlockTypeMinBusinessCustomers(unlockValue: string): boolean {
		const [businessKey, targetCountStr] = unlockValue.split(':');
		const targetCount = Number(targetCountStr);
		const business = this.businessModels.get(businessKey);

		return business.getCustomerCount() >= targetCount;
	}

	private checkUnlockTypeMaxBusinessCustomers(unlockValue: string): boolean {
		const [businessKey, targetCountStr] = unlockValue.split(':');
		const targetCount = Number(targetCountStr);
		const business = this.businessModels.get(businessKey);

		return business.getCustomerCount() < targetCount;
	}
}
