import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';

export abstract class TutorialStepUnlockTypeBaseModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_UNLOCKED: symbol = Symbol();

	protected key: string;
	protected unlocked: boolean;
	protected type: TutorialStepUnlockTypes;

	constructor(key: string, type: TutorialStepUnlockTypes) {
		super();
		this.key = key;
		this.type = type;
	}

	protected onUnlocked(): void {
		this.unlocked = true;
		this.emit(TutorialStepUnlockTypeBaseModel.EVENT_UNLOCKED, this.key);
	}

	public isUnlocked(): boolean {
		return this.unlocked;
	}

	public getKey(): string {
		return this.key;
	}

	public getType(): TutorialStepUnlockTypes {
		return this.type;
	}

	// eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
	public destroy(): void {}
}
