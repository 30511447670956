export class SkillParticleConfig {
	public static getPointSize1(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 0.9,
				end: 0.9,
				minimumScaleMultiplier: 0,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: true,
			rotationSpeed: {
				min: 30,
				max: -30,
			},
			lifetime: {
				min: 1000,
				max: 1000,
			},
			blendMode: 'add',
			frequency: 0.0001,
			emitterLifetime: -1,
			maxParticles: 3,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointRotation1(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 5,
				end: 5,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: -30,
			},
			lifetime: {
				min: 2,
				max: 2,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointSize2(): any {
		return {
			alpha: {
				start: 0.7,
				end: 0.17,
			},
			scale: {
				start: 0.55,
				end: 0.55,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: true,
			rotationSpeed: {
				min: 30,
				max: -30,
			},
			lifetime: {
				min: 1000,
				max: 1000,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 3,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointSize3(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.1,
				end: 1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 2,
			pos: {
				x: -30,
				y: -5,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointRotation2(): any {
		return {
			alpha: {
				start: 1,
				end: 0.17,
			},
			scale: {
				start: 1.5,
				end: 2,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: -30,
			},
			lifetime: {
				min: 0.2,
				max: 0.2,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 1000,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointRotation3(): any {
		return {
			alpha: {
				start: 1,
				end: 0.17,
			},
			scale: {
				start: 0.8,
				end: 0.8,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffb561',
				end: '#ffd447',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: -360,
				max: 360,
			},
			noRotation: false,
			rotationSpeed: {
				min: 30,
				max: -30,
			},
			lifetime: {
				min: 1000,
				max: 1000,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 2,
			pos: {
				x: -18,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}

	public static getPointAlpha1(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 0.82,
				end: 0.82,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1000,
				max: 1000,
			},
			blendMode: 'add',
			frequency: 0.8,
			emitterLifetime: -1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	public static getPointAlpha2(): any {
		return {
			alpha: {
				start: 1,
				end: 1,
			},
			scale: {
				start: 0.8,
				end: 0.8,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.3,
				max: 0.3,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 1,
			pos: {
				x: -135,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getPointAlpha3(): any {
		return {
			alpha: {
				start: 0.8,
				end: 0.0,
			},
			scale: {
				start: 0.7,
				end: 0.7,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.1,
			emitterLifetime: 0.15,
			maxParticles: 1,
			pos: {
				x: 0,
				y: -21,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	public static getButtonStar1(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0.8,
				end: 1,
				minimumScaleMultiplier: 0.5,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: -200,
			},
			maxSpeed: 200,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.01,
			maxParticles: 5,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 40,
			},
		};
	}

	public static getButtonStar2(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1.3,
				end: 0.2,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0,
			},
			acceleration: {
				x: 0,
				y: -60,
			},
			maxSpeed: 60,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.001,
			emitterLifetime: 0.01,
			maxParticles: 3,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'circle',
			spawnCircle: {
				x: 0,
				y: 0,
				r: 35,
			},
		};
	}

	public static getTextSKill(): any {
		return {
			alpha: {
				start: 1,
				end: 0.6,
			},
			scale: {
				start: 2,
				end: 1,
				minimumScaleMultiplier: 0.1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 20,
				end: 20,
				minimumSpeedMultiplier: 0.1,
			},
			acceleration: {
				x: 150,
				y: -500,
			},
			maxSpeed: 500,
			startRotation: {
				min: 45,
				max: 45,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 1,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.8,
			maxParticles: 50,
			pos: {
				x: -100,
				y: -100,
			},
			addAtBack: false,
			spawnType: 'rect',
			spawnRect: {
				x: 0,
				y: 0,
				w: 350,
				h: 500,
			},
		};
	}

	public static getBarFX(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1.4,
				end: 1.4,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffe205',
				end: '#f7a50d',
			},
			speed: {
				start: 200,
				end: 200,
				minimumSpeedMultiplier: 1,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: false,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 1.7,
				max: 1.7,
			},
			blendMode: 'add',
			frequency: 0.2,
			emitterLifetime: -1,
			maxParticles: 1000,
			pos: {
				x: -200,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'burst',
			particlesPerWave: 1,
			particleSpacing: 45,
			angleStart: 0,
		};
	}

	public static getTopGlow1(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: -1,
				end: -1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 600,
				y: 0,
			},
			maxSpeed: 600,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getTopGlow2(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 1,
				end: 1,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: -600,
				y: 0,
			},
			maxSpeed: 600,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.5,
				max: 0.5,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: false,
			spawnType: 'point',
		};
	}

	public static getTopGlow3(): any {
		return {
			alpha: {
				start: 1,
				end: 0,
			},
			scale: {
				start: 0,
				end: 1.2,
				minimumScaleMultiplier: 1,
			},
			color: {
				start: '#ffffff',
				end: '#ffffff',
			},
			speed: {
				start: 0,
				end: 0,
				minimumSpeedMultiplier: 0.001,
			},
			acceleration: {
				x: 0,
				y: 0,
			},
			maxSpeed: 0,
			startRotation: {
				min: 0,
				max: 0,
			},
			noRotation: true,
			rotationSpeed: {
				min: 0,
				max: 0,
			},
			lifetime: {
				min: 0.6,
				max: 0.6,
			},
			blendMode: 'add',
			frequency: 0.01,
			emitterLifetime: 0.1,
			maxParticles: 1,
			pos: {
				x: 0,
				y: 0,
			},
			addAtBack: true,
			spawnType: 'point',
		};
	}
}
