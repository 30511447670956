import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter55004View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55004View';
import { EventCharacter55005View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55005View';
import { EventCharacter55006View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55006View';

export class EventBusiness55002View extends BusinessBaseView {
	constructor() {
		const eventCharacter55004View = new EventCharacter55004View();
		const eventCharacter55005View = new EventCharacter55005View();
		const eventCharacter55006View = new EventCharacter55006View();

		super('business55002', [eventCharacter55004View, eventCharacter55005View, eventCharacter55006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter55006View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter55004View as PIXI.DisplayObject,
			eventCharacter55005View,
		);
	}
}
