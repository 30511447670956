import { CardProgressBarView } from '@views/ui/cards/progressBar/CardProgressBarView';
import { PromotableModel } from '@models/PromotableModel';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { CardRarities } from '@src/types/CardRarities';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import { TextField } from '@views/components/text/TextField';

export type PromotableClickData = {
	model: string;
	skill?: string;
};
export abstract class PromotableBaseCardView<T extends PromotableModel> extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	protected model: T;

	protected readonly progressBar: CardProgressBarView;
	protected readonly nameLabel: SizeableBitmapText;
	protected readonly rarityLabel : SizeableBitmapText;
	protected readonly rarityBg: PIXI.Sprite;
	protected stickerNew: PIXI.mesh.NineSlicePlane;

	protected readonly localizationStorage: LocalizationStorage;
	private readonly autoUpdateProgressBar: boolean;

	protected readonly stickerNewContainer: PIXI.Container;

	private tweenGlowRay: TWEEN.Tween;

	protected constructor(
		model: T,
		autoUpdateProgressBar: boolean = true,
	) {
		super();

		this.model = model;
		this.autoUpdateProgressBar = autoUpdateProgressBar;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		if (autoUpdateProgressBar) {
			this.model.on(PromotableModel.EVENT_PROMOTED, this.updateProgressBar, this);
			this.model.on(PromotableModel.EVENT_CARDS_ADDED, this.updateProgressBar, this);
		}
		this.model.once(PromotableModel.EVENT_OLD, this.onOld, this);

		this.progressBar = new CardProgressBarView();
		this.updateProgressBar();

		this.stickerNewContainer = new PIXI.Container();

		this.stickerNew = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['new_sticker_icon'], 22, 0, 6, 0);
		this.stickerNew.width = 195;
		this.stickerNew.pivot.set(this.stickerNew.width / 2, this.stickerNew.height / 2);
		this.stickerNew.position.set(175, 314);

		const stickerNewText = new TextField(
			this.localizationStorage.getLocalizedString('#video_sticker_new'),
			{ font: '38px wendyOne', align: 'center' },
			60,
			45,
		);
		stickerNewText.anchor = 0.5;
		stickerNewText.position.set(175, 321);

		this.stickerNewContainer.addChild(
			this.stickerNew,
			stickerNewText as PIXI.DisplayObject,
		);

		this.stickerNewContainer.visible = this.model.isNew();

		const cardRarity = model.getCardRarity();
		const rarityColor = CardRarities.getRarityColor(cardRarity);

		this.nameLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#${model.getKey()}_Name`),
			466,
			{ font: '50px wendyOneShadowBold', align: 'center', tint: rarityColor },
		);
		this.nameLabel.anchor = 0.5;
		this.nameLabel.y = -352;

		this.rarityBg = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')[`rarity_color_${model.getCardRarity()}_panel`]);
		this.rarityBg.y = -422;

		this.rarityLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#hint_rarity_${cardRarity}`),
			210,
			{ font: '33px wendyOneShadowBold', align: 'center' },
		);
		this.rarityLabel.anchor = 0.5;
		this.rarityLabel.y = -426;

		this.addChild(
			this.nameLabel,
			this.rarityBg,
			this.rarityLabel,
			this.progressBar as PIXI.DisplayObject,
			this.stickerNewContainer,
		);

		this.interactive = true;
		this.on('pointertap', this.onClick, this);
	}

	protected onLanguageChange(): void {
		this.nameLabel.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);
		this.rarityLabel.text = this.localizationStorage.getLocalizedString(`#hint_rarity_${this.model.getCardRarity()}`);
	}

	protected getOnClickData(): PromotableClickData {
		return { model: this.model.getKey() };
	}

	private onClick(): void {
		this.emit(PromotableBaseCardView.EVENT_CLICK, this.getOnClickData());
	}

	public setPreviousProgressValues(): void {
		this.progressBar.setLevel(this.model.getLevel());
		this.progressBar.setProgress(this.model.getPreviousCardsCount(), this.model.getPromoteCostCards());
	}

	public animateProgressBar(): void {
		this.progressBar.setProgressAnimated(
			this.model.getPreviousCardsCount(),
			this.model.getCardsCount(),
			this.model.getPromoteCostCards(),
		);
	}

	public animateProgressBarWithCount(count: number): void {
		this.progressBar.setProgressAnimated(
			this.model.getPreviousCardsCount(),
			count,
			this.model.getPromoteCostCards(),
		);
	}

	public updateProgressBar(): void {
		this.progressBar.setLevel(this.model.getLevel());
		this.progressBar.setProgress(this.model.getCardsCount(), this.model.getPromoteCostCards());
	}

	public startPromoteAnimation(): void {
		if (this.model.isEnoughCardsForPromote()) {
			this.progressBar.startPromoteAnimationNextPromoteAvailable(
				this.model.getCardsCount(),
				this.model.getPromoteCostCards(),
				this.model.getLevel(),
			);
		} else {
			this.progressBar.startPromoteAnimation(
				this.model.getCardsCount(),
				this.model.getPrevPromoteCostCards(),
				this.model.getPromoteCostCards(),
				this.model.getLevel(),
			);
		}
	}

	private onOld(): void {
		this.stickerNewContainer.visible = false;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		if (this.autoUpdateProgressBar) {
			this.model.off(PromotableModel.EVENT_PROMOTED, this.updateProgressBar, this);
			this.model.off(PromotableModel.EVENT_CARDS_ADDED, this.updateProgressBar, this);
		}
		this.model.off(PromotableModel.EVENT_OLD, this.onOld, this, true);
		this.model = null;

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onLanguageChange, this);

		if (this.tweenGlowRay) {
			this.tweenGlowRay.stop();
			this.tweenGlowRay = null;
		}

		super.destroy(options);
	}
}
