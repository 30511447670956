import { AssetsStorage } from '@main/AssetsStorage';
import { AssetLoader } from './AssetLoader';

export class AssetLoaderUtils {
	public static addCharacterAnimationsToLoad(
		metadata: { [key: string]: string },
		activatedCharacterKeys: string[],
		loader: AssetLoader,
		subloader?: AssetLoader,
	): void {
		Object.keys(metadata).forEach(key => {
			const characterAnimationPath = metadata[key];
			const characterActivated = activatedCharacterKeys.some(activatedKey => key.slice(0, key.indexOf('Animation')) === activatedKey);
			if (characterActivated) {
				loader.add(key, characterAnimationPath);
			} else {
				const targetSubloader = subloader ?? loader;
				targetSubloader.add(key, characterAnimationPath);
			}
		});
	}

	public static addSeasonsToLoad(
		getSeasonMetadataHandler: (season: number) => Record<string, string>,
		seasonCount: number,
		currentSeason: number,
		loader: AssetLoader,
		subloader: AssetLoader,
	): void {
		const currentSeasonMetadata = getSeasonMetadataHandler(currentSeason);

		Object.keys(currentSeasonMetadata).forEach(key => {
			loader.add(key, currentSeasonMetadata[key]);
		});

		for (let i = 1; i <= seasonCount; i++) {
			if (i !== currentSeason) {
				const seasonMetadata = getSeasonMetadataHandler(i);
				Object.keys(seasonMetadata).forEach(key => {
					subloader.add(key, seasonMetadata[key]);
				});
			}
		}
	}

	public static addAssetsStorageCacheToLoaders(
		...loaders: AssetLoader[]
	): void {
		loaders.forEach(loader => loader.use((resource: PIXI.loaders.Resource, next: () => void) => {
			const isAtlasTokenId = resource.name.indexOf('Atlas');
			if (isAtlasTokenId > 0) {
				const baseAtlasName = resource.name.substr(0, isAtlasTokenId + 5);
				AssetsStorage.addResource(baseAtlasName, resource.textures);
			} else {
				AssetsStorage.addResource(resource.name, resource);
			}
			next();
		}));
	}
}
