import { BonusTypes } from '@src/types/BonusTypes';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class BonusBaseModel {
	protected readonly type: BonusTypes;
	protected readonly values: number[];

	protected value: number;
	protected nextValue: number;
	protected ownerLevel: number;

	constructor(type: BonusTypes, ownerLevel: number, values: number[]) {
		this.type = type;
		this.values = values;

		this.ownerLevel = ownerLevel;
		this.value = this.calculate(ownerLevel);
		this.nextValue = this.calculate(ownerLevel + 1);
	}

	public getType(): BonusTypes {
		return this.type;
	}

	public onOwnerLevelUpdated(ownerLevel: number): void {
		this.ownerLevel = ownerLevel;
		this.value = this.calculate(ownerLevel);
		this.nextValue = this.calculate(ownerLevel + 1);
	}

	public getValue(): number {
		return Number(this.value.toFixed(2));
	}

	public getNextValue(): number {
		return Number(this.nextValue.toFixed(2));
	}

	// Used to display big numbers
	public getValueAsSoftMoney(): SoftMoneyNumber {
		return SoftMoneyNumber.createFromNumber(this.getValue());
	}

	// Used to display big numbers
	public getNextValueAsSoftMoney(): SoftMoneyNumber {
		return SoftMoneyNumber.createFromNumber(this.getNextValue());
	}

	protected calculate(ownerLevel: number): number {
		const valueId = Math.min(ownerLevel - 1, this.values.length - 1);
		return this.values[valueId];
	}
}
