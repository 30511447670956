import { QuestWindowViewMode } from '@views/windows/quests/QuestWindowBaseView';

export class CheatModel extends PIXI.utils.EventEmitter {
	private static getItem(key: string): string | null {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			return null;
		}
	}

	private static setItem(key: string, value: string): void {
		try {
			localStorage.setItem(key, value);
		} catch (e) {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}

	private static removeItem(key: string): void {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}

	public static readonly EVENT_UPDATE_TUTORIAL_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_MAIN_WINDOW_CONFIG_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_GENERATE_BUSINESS_CLICK = Symbol();
	public static readonly EVENT_UPDATE_EMITTER_BANK_OFFER_WINDOWS_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_EMITTER_GOTO_EVENT_WINDOWS_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_NOVICE_EVENT_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_NEWS_PUSHING_ENABLED = Symbol();
	public static readonly EVENT_UPDATE_DAILY_REWARD_PUSHING_ENABLED = Symbol();

	private static readonly ITEM_ENV: string = 'env';
	private static readonly ITEM_TUTORIAL_ENABLED: string = 'tutorialEnabled';
	private static readonly ITEM_MAIN_WINDOW_CONFIG_ENABLED: string = 'mainWindowConfigEnabled';
	private static readonly ITEM_QUEST_WINDOW_MODE: string = 'questWindowMode';
	private static readonly ITEM_DUMMY_TRANSACTIONS_ENABLED: string = 'dummyTransactionsEnabled';
	private static readonly ITEM_ERROR_WINDOW_ENABLED: string = 'errorWindowEnabled';
	private static readonly ITEM_NEWS_PUSHING_ENABLED = 'newsPushingEnabled';
	private static readonly ITEM_DAILY_REWARD_PUSHING_ENABLED = 'dailyRewardPushingEnabled';
	private static readonly ITEM_ANALYTICS_ENABLED: string = 'analyticsEnabled';
	private static readonly ITEM_GENERATE_BUSINESS_CLICK: string = 'generateBusinessClick';
	private static readonly ITEM_PRELOADER_LOGO_VISIBLE: string = 'preloaderLogoVisible';
	private static readonly ITEM_LANDING_MODEST: string = 'landingModest';
	private static readonly ITEM_EMITTER_BANK_OFFER_WINDOWS_ENABLED: string = 'emitterBankOfferWindowsEnabled';
	private static readonly ITEM_EMITTER_GO_TO_EVENT_WINDOWS_ENABLED: string = 'emitterGoToEventWindowsEnabled';
	private static readonly ITEM_EMITTER_DIALOG_WINDOWS_ENABLED: string = 'emitterDialogWindowsEnabled';
	private static readonly ITEM_NOVICE_EVENT_ENABLED: string = 'noviceEventEnabled';
	private static readonly ITEM_ASSETS_SERVER = 'assetsServer';

	private questWindowMode: QuestWindowViewMode;
	private tutorialEnabled: boolean;
	private mainWindowConfigEnabled: boolean;
	private env: string;
	private dummyTransactionsEnabled: boolean;
	private errorWindowEnabled: boolean;
	private newsPushingEnabled: boolean;
	private dailyRewardPushingEnabled: boolean;
	private analyticsEnabled: boolean;
	private preloaderLogoVisible: boolean;
	private landingModest: boolean;
	private noviceEventEnabled: boolean;
	private assetsServer?: string;
	private generateBusinessClickKeys: Set<string>;

	private emitterBankOfferWindowsEnabled: boolean;
	private emitterGoToEventWindowsEnabled: boolean;
	private emitterDialogWindowsEnabled: boolean;

	constructor() {
		super();

		const questWindowModeLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_QUEST_WINDOW_MODE)
			: null;
		if (questWindowModeLocalStorage !== null) {
			this.questWindowMode = questWindowModeLocalStorage as QuestWindowViewMode;
		} else {
			this.questWindowMode = QuestWindowViewMode.DEFAULT;
		}

		const tutorialEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_TUTORIAL_ENABLED)
			: null;
		if (tutorialEnabledLocalStorage !== null) {
			this.tutorialEnabled = Boolean(tutorialEnabledLocalStorage === 'true');
		} else {
			this.tutorialEnabled = true;
		}

		const envLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_ENV)
			: null;
		if (envLocalStorage !== null) {
			this.env = envLocalStorage;
		}

		const mainWindowConfigEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_MAIN_WINDOW_CONFIG_ENABLED)
			: null;
		if (mainWindowConfigEnabledLocalStorage !== null) {
			this.mainWindowConfigEnabled = Boolean(mainWindowConfigEnabledLocalStorage === 'true');
		} else {
			this.mainWindowConfigEnabled = true;
		}

		const bankTransactionModeLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_DUMMY_TRANSACTIONS_ENABLED)
			: null;
		if (bankTransactionModeLocalStorage !== null) {
			this.dummyTransactionsEnabled = Boolean(bankTransactionModeLocalStorage === 'true');
		} else {
			this.dummyTransactionsEnabled = false;
		}

		const errorWindowEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_ERROR_WINDOW_ENABLED)
			: null;
		if (errorWindowEnabledLocalStorage !== null) {
			this.errorWindowEnabled = Boolean(errorWindowEnabledLocalStorage === 'true');
		} else {
			this.errorWindowEnabled = true;
		}

		const newsPushingEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_NEWS_PUSHING_ENABLED)
			: undefined;
		if (newsPushingEnabledLocalStorage != null) {
			this.newsPushingEnabled = Boolean(newsPushingEnabledLocalStorage === 'true');
		} else {
			this.newsPushingEnabled = true;
		}

		const dailyRewardPushingEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_DAILY_REWARD_PUSHING_ENABLED)
			: undefined;
		if (dailyRewardPushingEnabledLocalStorage != null) {
			this.dailyRewardPushingEnabled = Boolean(dailyRewardPushingEnabledLocalStorage === 'true');
		} else {
			this.dailyRewardPushingEnabled = true;
		}

		const analyticsEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_ANALYTICS_ENABLED)
			: null;
		if (analyticsEnabledLocalStorage !== null) {
			this.analyticsEnabled = Boolean(analyticsEnabledLocalStorage === 'true');
		} else {
			this.analyticsEnabled = !MODE_DEBUG;
		}

		const landingModestLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_LANDING_MODEST)
			: null;
		if (landingModestLocalStorage !== null) {
			this.landingModest = Boolean(landingModestLocalStorage === 'true');
		} else {
			this.landingModest = false;
		}

		const preloaderLogoEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_PRELOADER_LOGO_VISIBLE)
			: null;
		if (preloaderLogoEnabledLocalStorage !== null) {
			this.preloaderLogoVisible = Boolean(preloaderLogoEnabledLocalStorage === 'true');
		} else {
			this.preloaderLogoVisible = true;
		}

		const emitterBankOfferWindowsEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_EMITTER_BANK_OFFER_WINDOWS_ENABLED)
			: null;
		if (emitterBankOfferWindowsEnabledLocalStorage !== null) {
			this.emitterBankOfferWindowsEnabled = Boolean(emitterBankOfferWindowsEnabledLocalStorage === 'true');
		} else {
			this.emitterBankOfferWindowsEnabled = true;
		}

		const emitterGoToEventWindowsEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_EMITTER_GO_TO_EVENT_WINDOWS_ENABLED)
			: null;
		if (emitterGoToEventWindowsEnabledLocalStorage !== null) {
			this.emitterGoToEventWindowsEnabled = Boolean(emitterGoToEventWindowsEnabledLocalStorage === 'true');
		} else {
			this.emitterGoToEventWindowsEnabled = true;
		}

		const emitterDialogWindowsEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_EMITTER_DIALOG_WINDOWS_ENABLED)
			: null;
		if (emitterDialogWindowsEnabledLocalStorage !== null) {
			this.emitterDialogWindowsEnabled = Boolean(emitterDialogWindowsEnabledLocalStorage === 'true');
		} else {
			this.emitterDialogWindowsEnabled = true;
		}

		const noviceEventEnabledLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_NOVICE_EVENT_ENABLED)
			: null;
		if (noviceEventEnabledLocalStorage !== null) {
			this.noviceEventEnabled = noviceEventEnabledLocalStorage === 'true';
		} else {
			this.noviceEventEnabled = true;
		}

		const assetsServiceLocalStorage = MODE_DEBUG
			? CheatModel.getItem(CheatModel.ITEM_ASSETS_SERVER)
			: null;
		if (assetsServiceLocalStorage !== null) {
			this.assetsServer = assetsServiceLocalStorage;
		}

		this.generateBusinessClickKeys = new Set();
	}

	public setGenerateBusinessClickEnabled(key: string, value: boolean): void {
		if (value) {
			this.generateBusinessClickKeys.add(key);
		} else {
			this.generateBusinessClickKeys.delete(key);
		}
		if (this.generateBusinessClickKeys.size > 0) {
			CheatModel.setItem(CheatModel.ITEM_GENERATE_BUSINESS_CLICK, Array.from(this.generateBusinessClickKeys.values()).join(','));
		} else {
			CheatModel.removeItem(CheatModel.ITEM_GENERATE_BUSINESS_CLICK);
		}

		this.emit(CheatModel.EVENT_UPDATE_GENERATE_BUSINESS_CLICK, key, value);
	}

	public setNoviceEventEnabled(value: boolean): void {
		this.noviceEventEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_NOVICE_EVENT_ENABLED, String(this.noviceEventEnabled));
		this.emit(CheatModel.EVENT_UPDATE_NOVICE_EVENT_ENABLED, value);
	}

	public setErrorWindowEnabled(value: boolean): void {
		this.errorWindowEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_ERROR_WINDOW_ENABLED, String(this.errorWindowEnabled));
	}

	public setAnalyticsEnabled(value: boolean): void {
		this.analyticsEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_ANALYTICS_ENABLED, String(this.analyticsEnabled));
	}

	public setPreloaderLogoVisible(value: boolean): void {
		this.preloaderLogoVisible = value;
		CheatModel.setItem(CheatModel.ITEM_PRELOADER_LOGO_VISIBLE, String(this.preloaderLogoVisible));
	}

	public setLandingModest(value: boolean): void {
		this.landingModest = value;
		CheatModel.setItem(CheatModel.ITEM_LANDING_MODEST, String(this.landingModest));
	}

	public setEmitterGoToEventWindowsEnabled(value: boolean): void {
		this.emitterGoToEventWindowsEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_EMITTER_GO_TO_EVENT_WINDOWS_ENABLED, String(this.emitterGoToEventWindowsEnabled));

		this.emit(CheatModel.EVENT_UPDATE_EMITTER_GOTO_EVENT_WINDOWS_ENABLED);
	}

	public setEmitterBankOfferWindowsEnabled(value: boolean): void {
		this.emitterBankOfferWindowsEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_EMITTER_BANK_OFFER_WINDOWS_ENABLED, String(this.emitterBankOfferWindowsEnabled));

		this.emit(CheatModel.EVENT_UPDATE_EMITTER_BANK_OFFER_WINDOWS_ENABLED);
	}

	public setEmitterDialogsWindowsEnabled(value: boolean): void {
		this.emitterDialogWindowsEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_EMITTER_DIALOG_WINDOWS_ENABLED, String(this.emitterDialogWindowsEnabled));
	}

	public setQuestWindowMode(value: QuestWindowViewMode): void {
		this.questWindowMode = value;
		CheatModel.setItem(CheatModel.ITEM_QUEST_WINDOW_MODE, String(value));
	}

	public setTutorialEnabled(value: boolean): void {
		this.tutorialEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_TUTORIAL_ENABLED, String(this.tutorialEnabled));

		this.emit(CheatModel.EVENT_UPDATE_TUTORIAL_ENABLED, value);
	}

	public setMainWindowConfigEnabled(value: boolean): void {
		this.mainWindowConfigEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_MAIN_WINDOW_CONFIG_ENABLED, String(this.mainWindowConfigEnabled));

		this.emit(CheatModel.EVENT_UPDATE_MAIN_WINDOW_CONFIG_ENABLED, value);
	}

	public setEnv(value: string): void {
		this.env = value;
		CheatModel.setItem(CheatModel.ITEM_ENV, this.env);
	}

	public setDummyTransactionsEnabled(value: boolean): void {
		this.dummyTransactionsEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_DUMMY_TRANSACTIONS_ENABLED, String(this.dummyTransactionsEnabled));
	}

	public isNoviceEventEnabled(): boolean {
		return this.noviceEventEnabled;
	}

	public isTutorialEnabled(): boolean {
		return this.tutorialEnabled;
	}

	public isErrorWindowEnabled(): boolean {
		return this.errorWindowEnabled;
	}

	public isNewsPushingEnabled(): boolean {
		return this.newsPushingEnabled;
	}

	public setNewsPushingEnabled(value: boolean): void {
		this.newsPushingEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_NEWS_PUSHING_ENABLED, String(this.newsPushingEnabled));

		this.emit(CheatModel.EVENT_UPDATE_NEWS_PUSHING_ENABLED);
	}

	public isDailyRewardPushingEnabled(): boolean {
		return this.dailyRewardPushingEnabled;
	}

	public setDailyRewardPushingEnabled(value: boolean): void {
		this.dailyRewardPushingEnabled = value;
		CheatModel.setItem(CheatModel.ITEM_DAILY_REWARD_PUSHING_ENABLED, String(this.dailyRewardPushingEnabled));

		this.emit(CheatModel.EVENT_UPDATE_DAILY_REWARD_PUSHING_ENABLED);
	}

	public isMainWindowConfigEnabled(): boolean {
		return this.mainWindowConfigEnabled;
	}

	public isAnalyticsEnabled(): boolean {
		return this.analyticsEnabled;
	}

	public isDummyTransactionsEnabled(): boolean {
		return this.dummyTransactionsEnabled;
	}

	public getQuestWindowMode(): QuestWindowViewMode {
		return this.questWindowMode;
	}

	public getEnv(): string {
		return this.env;
	}

	public isGenerateBusinessClickEnabled(businessKey: string): boolean {
		return this.generateBusinessClickKeys.has(businessKey);
	}

	public isPreloaderLogoEnabled(): boolean {
		return this.preloaderLogoVisible;
	}

	public isLandingModest(): boolean {
		return this.landingModest;
	}

	public isEmitterGoToEventWindowsEnabled(): boolean {
		return this.emitterGoToEventWindowsEnabled;
	}

	public isEmitterBankOfferWindowsEnabled(): boolean {
		return this.emitterBankOfferWindowsEnabled;
	}

	public isEmitterDialogWindowsEnabled(): boolean {
		return this.emitterDialogWindowsEnabled;
	}

	public getAssetsServer(): string | null {
		return this.assetsServer;
	}
}
