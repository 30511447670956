import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { RewardDescriptionType, RewardTypes } from '@src/types/RewardTypes';
import { CardRarity } from '@src/types/CardRarities';
import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { ModelHelper } from '@models/ModelHelper';

export class FarewellPartyLootboxModel extends LootboxModel {
	private rewardDescriptions: RewardDescriptionType[];
	private readonly rewardFactory: RewardFactory;

	constructor(
		id: string,
		rarity: CardRarity,
		rewards: AbstractReward[],
		rewardDescriptions: RewardDescriptionType[],
		rewardFactory: RewardFactory,
	) {
		super(id, rarity, rewards);
		this.rewardDescriptions = rewardDescriptions;
		this.rewardFactory = rewardFactory;
	}

	public setRewardCount(count: number): void {
		let videoReward;

		// eslint-disable-next-line no-restricted-syntax
		for (const reward of this.rewards) {
			if (reward.getType() === RewardTypes.VIDEO) {
				videoReward = reward;
				break;
			}
		}

		this.rewardDescriptions.splice(count);
		const mergedRewardDescriptions = ModelHelper.mergeRewardDescriptions(this.rewardDescriptions);

		this.rewards = mergedRewardDescriptions.map(description => this.rewardFactory.createReward(description));

		if (videoReward) {
			this.rewards.push(videoReward);
		}
	}
}
