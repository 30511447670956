import { PrepartyWindowViewSetter } from '@interfaces/ViewSetters';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { WindowViewBaseFactory } from '@src/initializers/windows/WindowBaseFactory';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { FarewellPartyWindowView } from '@views/windows/farewellParty/FarewellPartyWindowView';
import { PrepartyWindowView } from '@views/windows/farewellParty/preparty/PrepartyWindowView';
import { EmittersController } from './mainUI/EmittersController';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { ModelHelper } from '@models/ModelHelper';
import { CollectionWindowOpenConfig, CollectionWindowView, CollectionWindowTabs } from '@views/windows/collection/CollectionWindowView';
import { SummonCollectionView } from '@views/windows/collection/summon/SummonCollectionView';
import { ShowQuestTargetView } from '@views/ui/ShowQuestTargetView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';
import { TotemModel } from '@models/TotemModel';

export class PrepartyWindowViewController extends PIXI.utils.EventEmitter
	implements PrepartyWindowViewSetter {
	private readonly windowViewSystem: WindowViewSystem;
	private readonly windowFactory: WindowViewBaseFactory;
	private readonly model: PrepartyStartCharacterModel;
	private readonly summonModelsPool: SummonModelsPool;
	private readonly questTargetDuration: number;
	private readonly totemModels: Map<string, TotemModel>;
	private readonly farewellPartyModels: Map<string, FarewellPartyModel>;
	private readonly emittersController: EmittersController;

	private view: PrepartyWindowView;

	private farewellPartyEverStarted: boolean;

	constructor(
		model: PrepartyStartCharacterModel,
		windowViewSystem: WindowViewSystem,
		windowFactory: WindowViewBaseFactory,
		summonModelsPool: SummonModelsPool,
		farewellPartyModels: Map<string, FarewellPartyModel>,
		totemModels: Map<string, TotemModel>,
		emittersController: EmittersController,
		farewellPartyEverStarted: boolean,
		questTargetDuration: number,
	) {
		super();

		this.model = model;
		this.windowViewSystem = windowViewSystem;
		this.windowFactory = windowFactory;
		this.totemModels = totemModels;
		this.summonModelsPool = summonModelsPool;
		this.farewellPartyModels = farewellPartyModels;
		this.farewellPartyEverStarted = farewellPartyEverStarted;
		this.emittersController = emittersController;
		this.questTargetDuration = questTargetDuration;
	}

	public setPrepartyWindowView(view: PrepartyWindowView): void {
		this.view = view;
		this.view.once(PrepartyWindowView.EVENT_BUTTON_GOTO_PARTY_CLICK, this.onGoToParty, this);
		this.view.on(PrepartyWindowView.EVENT_BUTTON_FIND_CLICK, this.onButtonFindClick, this);
		this.view.on(PrepartyWindowView.EVENT_CARD_CLICK, this.onTotemCardClick, this);
	}

	private onTotemCardClick(data: PromotableClickData): void {
		const totemModel = this.totemModels.get(data.model);
		const window = this.windowFactory.createTotemPromoteWindow(totemModel);
		this.windowViewSystem.showWindow(window);
	}

	private onButtonFindClick(): void {
		const model = ModelHelper.getSummonModelWithMaxCost(this.summonModelsPool.getModelsList());
		let openConfig: CollectionWindowOpenConfig;
		if (model) {
			this.windowViewSystem.once(WindowViewSystem.EVENT_WINDOW_COLLECTIONS_SHOWED, (collectionWindow: CollectionWindowView) => {
				collectionWindow.once(CollectionWindowView.EVENT_COLLECTION_SUMMON_VIEW_SHOWED, (summonCollectionView: SummonCollectionView) => {
					const target = summonCollectionView.getButtonSummonContainer(model.getKey());
					const show = new ShowQuestTargetView(this.questTargetDuration, target);
					target.parent.addChild(show);
				});
			});
			openConfig = {
				tab: CollectionWindowTabs.TAB_SUMMON,
				focusKey: model.getKey(),
			};
		} else {
			openConfig = { tab: CollectionWindowTabs.TAB_SUMMON };
		}
		const window = this.windowFactory.createCollectionWindow(openConfig);
		this.windowViewSystem.showWindow(window);
	}

	private onGoToParty(): void {
		const farewellPartyModel = this.farewellPartyModels.get(this.model.getFarewellPartyKey());
		const farewellPartyWindow = this.windowFactory.createFarewellPartyWindow(
			farewellPartyModel,
			this.farewellPartyEverStarted,
		);
		farewellPartyWindow.once(FarewellPartyWindowView.EVENT_WINDOW_CLOSED, () => {
			this.emittersController.setFarewellPartyOn(false);
		}, this);

		this.emittersController.setFarewellPartyOn(true);

		this.model.disableCharacterView();
		this.model.chooseNextFarewellParty();

		if (!this.farewellPartyEverStarted) {
			this.farewellPartyEverStarted = true;
		}

		this.windowViewSystem.showWindow(farewellPartyWindow);
	}
}
