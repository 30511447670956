import { TextUtils } from '../../../utils/TextUtils';

export class SizeableBitmapText extends PIXI.extras.BitmapText {
	private readonly fitWidth: number;
	private originalSize: number;

	constructor(text: string, fitWidth: number, style?: PIXI.extras.BitmapTextStyle) {
		super(text, style);

		this.fitWidth = fitWidth;
		this.originalSize = (this.font as any).size;

		this._text = TextUtils.replaceSpecSymbols(this._text); // eslint-disable-line no-underscore-dangle

		this.fitText();
	}

	// eslint-disable-next-line
	// @ts-ignore
	public set text(value: string) {
		// eslint-disable-next-line no-param-reassign
		value = value.toString() || ' ';
		if (this._text === value) { // eslint-disable-line no-underscore-dangle
			return;
		}

		this._text = TextUtils.replaceSpecSymbols(value); // eslint-disable-line no-underscore-dangle

		this.fitText();
		this.dirty = true;
	}

	public get text(): string {
		return this._text; // eslint-disable-line no-underscore-dangle
	}

	public setFontSize(size: number): void {
		this.originalSize = size;
		this.fitText();
	}

	protected fitText(): void {
		(this.font as any).size = this.originalSize;
		this.updateText();
		while (this.textWidth > this.fitWidth) {
			(this.font as any).size -= 1;
			this.updateText();
		}
	}
}
