import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character13View } from '@views/characters/main/Character13View';
import { Character14View } from '@views/characters/main/Character14View';
import { Character15View } from '@views/characters/main/Character15View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business5View extends BusinessBaseView {
	constructor() {
		const character13 = new Character13View();
		const character14 = new Character14View();
		const character15 = new Character15View();

		super('business5', [character13, character14, character15]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		const decor2 = new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(-127, 33, -0.5, 0.5) as PIXI.Sprite;

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b5_decor1']).setTransform(-213, 76, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b5_decor2']).setTransform(125, 33, 0.5, 0.5) as PIXI.Sprite,
			decor2,
		];

		this.addChild(...this.decorations);

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b5_decor5']).setTransform(-1.5, 75, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChildAt(character13, this.getChildIndex(decor2) + 1);
		this.addChildAt(character14, this.getChildIndex(decor2) + 1);
		this.addChildAt(character15, this.getChildIndex(decor2) + 1);
	}
}
