import { BusinessModel } from '@models/BusinessModel';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';

export class BackToGameRewardLoader {
	private inited: boolean;

	constructor(
		private readonly busienssModels: Map<string, BusinessModel>,
		private readonly backToGameRewardModel: BackToGameRewardModel,
		private readonly businessesLastUpdate: Map<string, number>,
	) {
		const onIncomeValueChangedLocal = (business: BusinessModel): void => {
			if (business.isAutomated()) {
				this.updateBackToGameIncomePerSec(business);
			}
		};

		this.busienssModels.forEach(model => {
			model.on(BusinessModel.EVENT_AUTOMATED, onIncomeValueChangedLocal, this);
			model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, onIncomeValueChangedLocal, this);
			model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, onIncomeValueChangedLocal, this);
		});
	}

	public init(): void {
		this.busienssModels.forEach(model => {
			if (model.isAutomated()) {
				if (this.businessesLastUpdate.has(model.getKey())) {
					const lastUpdate = this.businessesLastUpdate.get(model.getKey());
					this.backToGameRewardModel.setBusinessLastUpdate(model.getKey(), lastUpdate);
				}

				this.updateBackToGameIncomePerSec(model);
			}
		});

		this.inited = true;
	}

	public isInited(): boolean {
		return this.inited;
	}

	private updateBackToGameIncomePerSec(model: BusinessModel): void {
		const value = model.getBusinessIncomePerSec(false, true, true);
		this.backToGameRewardModel.setBusinessIncomePerSec(model.getKey(), value);
	}
}
