import { DailyRewardConfig } from '@configs/DailyRewardConfig';
import { BaseConfigSetParser } from './BaseConfigSetParser';

export class DailyRewardConfigSetParser extends BaseConfigSetParser<DailyRewardConfig> {
	constructor() {
		super('', DailyRewardConfig);
	}

	public parseConfigSet(configSetJSON: Record<string, string>[]): void {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const ConfigConstructor = this.configConstructorGetter || (() => this.ConfigConstructor);

		configSetJSON.forEach((configJSON) => {
			const config = BaseConfigSetParser.createConfig(
				ConfigConstructor(configJSON),
				configJSON,
			);
			this.configSet.set(config.getKey(), config);
		});
	}
}
