import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import {
	BankTabLayoutTypes,
	BankViewTypes,
	BankTabTypes,
	BankTabElementDataConfigNameTypes
} from '@src/types/BankTypes';
import { BankTabElementConfig } from './BankTabElementConfig';

export class BankTabConfig implements ConfigSetElement {
	public static readonly FIELD_KEY = 'position';
	public static readonly FIELD_ID = 'id';
	public static readonly FIELD_POSITION = 'position';
	public static readonly FIELD_NAME_LOCALE_KEY = 'name';
	public static readonly FIELD_ICON_KEY = 'icon';
	public static readonly FIELD_TAB_LAYOUT_TYPE = 'layout_type';
	public static readonly FIELD_TYPE = 'type';
	public static readonly FIELD_VIEW_KEYS = 'prefab_keys';
	public static readonly FIELD_DATA_CONFIG_NAME = 'config';
	public static readonly FIELD_VIEW_TYPE = 'prefab_type';
	public static readonly FIELD_VIEW_PARAMETERS = 'parameters';

	private readonly key: string;
	private readonly id: number;
	private readonly position: number;
	private readonly nameLocaleKey: string;
	private readonly iconKey: string;
	private readonly tabLayoutType: BankTabLayoutTypes;
	private readonly tabElementConfigs: BankTabElementConfig[];
	private readonly type: BankTabTypes;

	constructor(config: { [key: string]: string }) {
		this.key = config[BankTabConfig.FIELD_KEY].trim();
		this.id = Number(config[BankTabConfig.FIELD_ID]);
		this.position = Number(config[BankTabConfig.FIELD_POSITION].trim());
		this.nameLocaleKey = config[BankTabConfig.FIELD_NAME_LOCALE_KEY].trim();
		this.iconKey = config[BankTabConfig.FIELD_ICON_KEY].trim();
		this.tabLayoutType = config[BankTabConfig.FIELD_TAB_LAYOUT_TYPE].trim() as BankTabLayoutTypes;
		this.type = config[BankTabConfig.FIELD_TYPE].trim() as BankTabTypes;

		const viewKeys = (config[BankTabConfig.FIELD_VIEW_KEYS] as string).split(',').map(str => str.trim());
		const dataConfigNames = (config[BankTabConfig.FIELD_DATA_CONFIG_NAME] as string).split(',').map(str => str.trim());
		const viewsTypes = (config[BankTabConfig.FIELD_VIEW_TYPE] as string).split(',').map(str => str.trim());
		const viewsParameters = (config[BankTabConfig.FIELD_VIEW_PARAMETERS] as string).split(';').map(str => str.trim());
		const viewsParametersFormatted = viewsParameters.map((params => params.split(',').map(str => str.trim())));
		this.tabElementConfigs = viewKeys.map((viewKey, i) => {
			const dataConfigName = dataConfigNames[i] as BankTabElementDataConfigNameTypes;
			const viewType = viewsTypes[i] as BankViewTypes;
			const viewParameters = viewsParametersFormatted[i];
			return new BankTabElementConfig(viewKey, dataConfigName, viewType, viewParameters);
		});
	}

	public getKey(): string {
		return this.key;
	}

	public getId(): number {
		return this.id;
	}

	public getPosition(): number {
		return this.position;
	}

	public getNameLocaleKey(): string {
		return this.nameLocaleKey;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getType(): BankTabTypes {
		return this.type;
	}

	public getTabLayoutType(): BankTabLayoutTypes {
		return this.tabLayoutType;
	}

	public getTabElementConfigs(): BankTabElementConfig[] {
		return this.tabElementConfigs;
	}
}
