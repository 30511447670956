import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { TimedQuestInitData } from '@src/types/SaveTypes';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestFactory } from '@src/initializers/QuestFactory';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { TimedQuestCompleteAction } from '@models/network/actions/quests/TimedQuestCompleteAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { TimedQuestStartAction } from '@models/network/actions/quests/TimedQuestStartAction';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AbstractReward } from '@interfaces/AbstractReward';
import { LevelModel } from '@models/level/LevelModel';

export class TimedQuestsLoader extends PIXI.utils.EventEmitter {
	private static readonly TIMED_QUEST_REQUEST_TICKER_KEY = 'BlankQuest';
	private static readonly MIN_COMPLETED_TIMED_QUEST_TIMEOUT = 10;

	private pendingTimedQuestInitData: TimedQuestInitData;
	private requestTickerEventHandle: number;
	private requestTickerQuestHandle: number;
	private questTimeoutHandle: number;
	private inited: boolean;
	private eventDurationTimeout: boolean;
	private isEnabled: boolean;
	private isQuestUpdatePending: boolean;

	constructor(
		private readonly questConfigs: Map<string, QuestConfigBase>,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly timedQuestLinesModel: TimedQuestLinesModel,
		private readonly questFactory: QuestFactory,
		private readonly lootboxFactory: LootboxFactory,
		private readonly levelModel: LevelModel,
		private readonly serverTimeModel: ServerTimeModel,
		private readonly timedQuestsMinLevel: number,
		private initData?: TimedQuestInitData,
	) {
		super();

		this.levelModel.on(LevelModel.EVENT_LEVEL_CHANGE, this.updateReward, this);
	}

	public tryInit(): void {
		if (this.levelModel.getCurrentLevel() >= this.timedQuestsMinLevel) {
			if (this.initData) {
				this.parseInitData(this.initData);
			} else {
				this.updateQuestData();
			}
		}
	}

	public isInited(): boolean {
		return this.inited;
	}

	public finishQuest(quest: AbstractQuest): void {
		this.timedQuestLinesModel.finishCurrentQuest();
		this.onQuestCompleteAction(quest.getKey(), quest.getLootbox().getRewards());
	}

	public tryCreateNextQuest(): void {
		if (this.eventDurationTimeout) {
			this.eventDurationTimeout = false;
			this.updateQuestData();
		}
	}

	public createPendingQuest(): void {
		if (this.pendingTimedQuestInitData) {
			this.createQuest(this.pendingTimedQuestInitData);
			this.onQuestStartAction(this.pendingTimedQuestInitData.questKey);

			// Additional request on timeout to load quests completed on server but not on client
			this.setQuestTimeout(this.pendingTimedQuestInitData.info.questDuration * 1000);

			this.setRequestTickerEventTimeout(this.pendingTimedQuestInitData.info.eventDuration * 1000);

			this.pendingTimedQuestInitData = null;
		}
	}

	public setEnabled(value: boolean): void {
		this.isEnabled = value;

		if (this.isEnabled && this.isQuestUpdatePending) {
			this.isQuestUpdatePending = false;
			this.updateQuestData();
		}
	}

	private async updateReward(): Promise<void> {
		if (this.timedQuestLinesModel.hasCurrentActiveQuest()) {
			const lootboxConfig = await this.networkRequestSender.sendGetTimedQuestRewards();
			const lootbox = this.lootboxFactory.createQuestLootbox(lootboxConfig);
			const currentQuest = this.timedQuestLinesModel.getCurrentQuest();

			currentQuest.setLootbox(lootbox);
		}
	}

	private parseInitData(data: TimedQuestInitData): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		const isRequestTickerQuest = data.questKey === TimedQuestsLoader.TIMED_QUEST_REQUEST_TICKER_KEY;

		if (data.startedOn != null) {
			let requestEventTimeout: number = data.info.eventDuration - (currentTime - data.startedOn);

			if (!isRequestTickerQuest && !data.completed) {
				const isTimeout = data.startedOn + data.info.questDuration <= currentTime;
				const isProgressComplete = SoftMoneyNumber.createFromString(data.progress)
					.greaterThanOrEqualTo(SoftMoneyNumber.createFromString(data.completeValue));

				if (isTimeout && isProgressComplete) {
					requestEventTimeout = 0;
					this.createQuest(data);
				} else if (!isTimeout) {
					requestEventTimeout = 0;
					this.createQuest(data);
				} else if (requestEventTimeout <= 0) {
					requestEventTimeout = TimedQuestsLoader.MIN_COMPLETED_TIMED_QUEST_TIMEOUT;
				}
			} else if (requestEventTimeout <= 0) {
				requestEventTimeout = TimedQuestsLoader.MIN_COMPLETED_TIMED_QUEST_TIMEOUT;
			}

			if (data.startedOn + data.info.eventDuration <= currentTime) {
				this.eventDurationTimeout = true;
			}

			if (requestEventTimeout > 0) {
				this.setRequestTickerEventTimeout(requestEventTimeout * 1000);
			}
		} else if (isRequestTickerQuest) {
			const requestEventTimeout = data.info.eventDuration * 1000;
			this.setRequestTickerEventTimeout(requestEventTimeout);

			const requestQuestTimeout = data.info.questDuration * 1000;
			this.setRequestTickerQuestTimeout(requestQuestTimeout, data.questKey);

			this.onQuestStartAction(data.questKey);
		} else {
			this.pendingTimedQuestInitData = data;
			const dialogs = this.questConfigs.get(data.questKey).getDialogKeys();
			const randomDialogId: number = Math.floor(Math.random() * dialogs.length);
			const dialogKey = dialogs[randomDialogId];

			this.timedQuestLinesModel.setPending({
				key: data.questKey,
				time: data.info.questDuration,
				completeValue: SoftMoneyNumber.createFromString(data.completeValue).toString(),
				dialogKey,
			});
		}

		if (!this.inited) {
			this.inited = true;
		}
	}

	private setRequestTickerQuestTimeout(timeoutMS: number, questId: string): void {
		if (this.requestTickerQuestHandle) {
			window.clearTimeout(this.requestTickerQuestHandle);
		}
		this.requestTickerQuestHandle = window.setTimeout(
			() => {
				this.requestTickerQuestHandle = null;
				this.onQuestCompleteAction(questId, []);
			},
			timeoutMS,
		);
	}

	private setQuestTimeout(questDurationMS: number): void {
		if (this.questTimeoutHandle) {
			window.clearTimeout(this.questTimeoutHandle);
		}

		this.questTimeoutHandle = window.setTimeout(
			() => {
				this.questTimeoutHandle = null;

				if (this.isEnabled) {
					this.updateQuestData();
				}
			},
			questDurationMS,
		);
	}

	private setRequestTickerEventTimeout(eventDurationMS: number): void {
		if (this.requestTickerEventHandle) {
			window.clearTimeout(this.requestTickerEventHandle);
		}

		this.requestTickerEventHandle = window.setTimeout(
			() => {
				this.requestTickerEventHandle = null;

				if (this.isEnabled) {
					this.updateQuestData();
				} else {
					this.isQuestUpdatePending = true;
				}
			},
			eventDurationMS,
		);
	}

	private async updateQuestData(): Promise<void> {
		const timestamp = Math.ceil(Date.now() / 1000);
		const data = await this.networkRequestSender.sendGetTimedQuest(timestamp);

		if (data) {
			const initData: TimedQuestInitData = {
				questKey: data.id,
				progress: data.progress,
				startedOn: data.started_on,
				info: {
					paramsOnLevel: data.info.params_on_level,
					eventDuration: data.info.event_duration,
					questDuration: data.info.quest_duration,
				},
				completed: data.completed,
				completeValue: data.complete_value,
			};
			this.parseInitData(initData);
		}
	}

	private onQuestCompleteAction(questId: string, rewards: AbstractReward[]): void {
		const rewardsData = rewards.map((reward) => reward.getRewardDescription());
		const action = new TimedQuestCompleteAction(
			questId,
			rewardsData,
		);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private onQuestStartAction(questId: string): void {
		const action = new TimedQuestStartAction(questId);
		this.emit(BaseAction.EVENT_ACTION_CREATED, action);
	}

	private createQuest(save: TimedQuestInitData): void {
		const key = save.questKey;
		const config = this.questConfigs.get(key);

		if (save.info.paramsOnLevel) {
			config.setParamsOnLevel(save.info.paramsOnLevel);
		}

		const quest = this.questFactory.createQuest(
			config,
			save.completeValue,
			undefined,
			save.info.questDuration,
		);
		quest.restoreSavedState(save);

		if (save.startedOn != null) {
			const currentTime = this.serverTimeModel.getCalculatedISOTime();
			quest.setTimeleft(quest.getTime() - (currentTime - save.startedOn));
		}

		this.timedQuestLinesModel.setCurrentQuest(quest);

		this.networkRequestSender.sendGetTimedQuestRewards().then((result) => {
			quest.setLootbox(this.lootboxFactory.createQuestLootbox(result));
		});
	}
}
