import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character1View extends CharacterMainBaseView {
	constructor() {
		super('character1');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-45, 51);
		this.setAnimation(0, 'animation2', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-90, -18),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(-70, -21),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
