import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110007View } from '@views/characters/events/event11/EventCharacter110007View';
import { EventCharacter110008View } from '@views/characters/events/event11/EventCharacter110008View';
import { EventCharacter110009View } from '@views/characters/events/event11/EventCharacter110009View';

export class EventBusiness110003View extends BusinessBaseView {
	constructor() {
		const eventCharacter110007View = new EventCharacter110007View();
		const eventCharacter110008View = new EventCharacter110008View();
		const eventCharacter110009View = new EventCharacter110009View();

		super('business110003', [eventCharacter110007View, eventCharacter110008View, eventCharacter110009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b6_decor2']).setTransform(-2, 0, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(-229, -20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(229, -20, -0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b6_decor3']).setTransform(252, -37, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChild(
			eventCharacter110007View as PIXI.DisplayObject,
			eventCharacter110008View,
			eventCharacter110009View,
			...this.decorations,
		);
	}
}
