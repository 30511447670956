export type QuestType = string;

export enum QuestTypes {
	ACQUIRE_BUSINESS = 'acquireBusiness',
	ACQUIRE_BUSINESSES = 'acquireBusinesses',
	GET_CARDS = 'getCards',
	GET_CUSTOMERS_ALL = 'getCustomersAll',
	GET_CUSTOMERS_TARGET = 'getCustomersTarget',
	GET_PRESTIGE_MONEY = 'getPrestigeMoney',
	GET_HARD_MONEY = 'getHardMoney',
	GET_SOFT_MONEY = 'getSoftMoney',
	GET_SOFT_MONEY_FROM_BUSINESS = 'getSoftMoneyFromBusiness',
	GET_SOFT_MONEY_TUTORIAL = 'getSoftMoneyTutorial',
	GET_SOFT_MONEY_FROM_BUSINESS_TUTORIAL = 'getSoftMoneyTutorial',
	GET_CUSTOMERS_ON_SOME = 'getCustomersOnSome',
	GET_CUSTOMERS_MULTIPLIER = 'getCustomersMultiplier',
	GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO = 'getCustomersMultiplierTargetFromZero',
	GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT = 'getCustomersMultiplierTargetFromCurrent',
	PROMOTE_CHARACTER = 'promoteCharacter',
	PROMOTE_CHARACTERS = 'promoteCharacters',
	PROMOTE_UPGRADE = 'promoteUpgrade',
	PROMOTE_UPGRADES = 'promoteUpgrades',
	PROMOTE_CHARACTERS_OR_UPGRADES = 'promoteCharactersOrUpgrades',
	PROMOTE_ALL = 'promoteAll',
	ACTIVATE_UPGRADE = 'activateUpgrade',
	ACTIVATE_UPGRADES = 'activateUpgrades',
	ACTIVATE_CHARACTER = 'activateCharacter',
	ACTIVATE_CHARACTERS = 'activateCharacters',
	ACTIVATE_PARTY = 'party',
	SPEND_PRESTIGE_MONEY = 'spendPrestigeMoney',
	SPEND_HARD_MONEY = 'spendHardMoney',
	AUTOMATE_BUSINESS = 'automateBusiness',
	AUTOMATE_BUSINESSES = 'automateBusinesses',
	SUMMON_ALL = 'summonAll',
	SUMMON_HARD = 'summonHard',
	SUMMON_PRESTIGE = 'summonPrestige',
	SUMMON_PAY = 'summonPay',
}
