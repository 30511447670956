import { TutorialStepBaseConfig } from '../TutorialStepBaseConfig';

export class TutorialStepDataBase {
	private static readonly FORMAT_TAP_OFFSET: RegExp = /.*\(\s*(-?\d+)\s*,\s*(-?\d+)\s*\)/;

	private readonly tapOffsetX?: number;
	private readonly tapOffsetY?: number;

	private readonly stringParameter: string;

	constructor(config: { [key: string]: string }) {
		const param: string = config[TutorialStepBaseConfig.FIELD_PARAMETER];
		if (param) {
			if (TutorialStepDataBase.FORMAT_TAP_OFFSET.test(param)) {
				const regexResult = param.match(TutorialStepDataBase.FORMAT_TAP_OFFSET);

				this.tapOffsetX = Number(regexResult[1]);
				this.tapOffsetY = Number(regexResult[2]);

				this.stringParameter = param.substr(0, param.indexOf(regexResult[1]) - 2).trim();
			} else {
				this.stringParameter = param.trim();
			}
		}
	}

	public hasTapOffset(): boolean {
		return (this.tapOffsetX !== undefined) && (this.tapOffsetY !== undefined);
	}

	public getTapOffsetX(): number {
		return this.tapOffsetX;
	}

	public getTapOffsetY(): number {
		return this.tapOffsetY;
	}

	public getStringParameter(): string {
		return this.stringParameter;
	}
}
