import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter10010View extends CharacterMainBaseView {
	constructor() {
		super('character10010');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(92, 34);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-198, -28),
			arrowRotation: -Math.PI / 180,
			bgPosition: new PIXI.Point(-192, -20),
			invertBg: false,
		});
		this.initQuickPhrases(this.key);
	}
}
