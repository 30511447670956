import { ModelsBaseFactory } from '@src/initializers/models/ModelsBaseFactory';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { BoostModel } from '@models/BoostModel';
import { TimeskipModel } from '@models/TimeskipModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { BankModel } from '@models/bank/BankModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { DialogsFactory } from '@src/initializers/DialogsFactory';
import { BusinessModel } from '@models/BusinessModel';
import { NewCustomersMutiplierModel } from '@models/NewCustomersMultiplierModel';
import { CharacterQuickPhraseEmitter } from '@models/quickPhrases/CharacterQuickPhraseEmitter';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { TotemModel } from '@models/TotemModel';
import { TimedQuestLinesModel } from '@models/quests/timed/TimedQuestLinesModel';
import { FarewellPartyModel } from '@models/FarewellPartyModel';
import { LevelChallengeModel } from '@models/LevelChallengeModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';
import { BackToGameRewardModel } from '@models/BackToGameRewardModel';
import { MaintenanceModel } from '@models/MaintenanceModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { PrepartyStartCharacterModel } from '@models/PrepartyStartCharacterModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { LevelBaseModel } from '@models/level/LevelBaseModel';
import { SummonSavesModel } from '@models/SummonSavesModel';

export abstract class AbstractModelInstance {
	protected readonly prestigeMoneyModel: PrestigeMoneyModel;
	protected readonly softMoneyModel: SoftMoneyModel;
	protected readonly newCustomerMultiplierModels: Map<string, NewCustomersMutiplierModel>;
	protected readonly characterModels: Map<string, CharacterModel>;
	protected readonly skillModels: Map<string, SkillModel>;
	protected readonly upgradeModels: Map<string, UpgradeModel>;
	protected readonly bankModel: BankModel;
	protected readonly bankSavesModel: BankSavesModel;
	protected readonly boostModels: Map<string, BoostModel>;
	protected readonly timeskipModels: Map<string, TimeskipModel>;
	protected readonly summonModelsPool: SummonModelsPool;
	protected readonly questLinesModel: QuestLinesModel;
	protected readonly backToGameRewardModel: BackToGameRewardModel;
	protected readonly farewellBoostModel: FarewellBoostModel;
	protected readonly maintenanceModel: MaintenanceModel;
	protected readonly totemModels: Map<string, TotemModel>;
	protected readonly galleryVideoModels: Map<string, GalleryVideoModel>;

	protected readonly dialogsFactory: DialogsFactory;

	constructor(
		modelsFactory: ModelsBaseFactory,
	) {
		this.softMoneyModel = modelsFactory.createSoftMoneyModel();
		this.prestigeMoneyModel = modelsFactory.createPrestigeMoneyModel();

		this.newCustomerMultiplierModels = modelsFactory.getNewCustomerMultipliersFactory().createModels();

		this.characterModels = modelsFactory.getCharactersFactory().createModels();

		this.skillModels = modelsFactory.getSkillsFactory().createModels();

		this.upgradeModels = modelsFactory.getUpgradesFactory().createModels();

		this.boostModels = modelsFactory.getBoostsFactory().createModels();

		this.timeskipModels = modelsFactory.getTimeskipsFactory().createModels();

		this.bankModel = modelsFactory.createBankModel();

		this.bankSavesModel = modelsFactory.createBankSavesModel();

		this.questLinesModel = modelsFactory.createQuestLinesModel();

		this.summonModelsPool = modelsFactory.createSummonModelsPool();

		this.dialogsFactory = modelsFactory.getDialogsFactory();

		this.backToGameRewardModel = modelsFactory.createBackToGameRewardModel(this.boostModels);

		this.farewellBoostModel = modelsFactory.getBoostsFactory().createFarewellBoostModel();
		this.maintenanceModel = modelsFactory.createMaintenanceModel();

		const totemsFactory = modelsFactory.getTotemsFactory();
		this.totemModels = totemsFactory != null ? totemsFactory.createModels() : new Map();

		const galleryVideosFactory = modelsFactory.getGalleryVideosFactory();
		this.galleryVideoModels = galleryVideosFactory != null ? galleryVideosFactory.createModels() : new Map();
	}

	public abstract getCharacterQuickPhraseEmitter(): CharacterQuickPhraseEmitter;
	public abstract getBusinessModels(): Map<string, BusinessModel>;
	public abstract getTutorialStepModels(): Map<string, TutorialStepBaseModel>;
	public abstract getFarewellPartyModels(): Map<string, FarewellPartyModel>;
	public abstract getPrepartyStartCharacterModel(): PrepartyStartCharacterModel;
	public abstract getTimedQuestLinesModel(): TimedQuestLinesModel;
	public abstract getLevelChallengeModel(): LevelChallengeModel;
	public abstract getEpicQuestCollectionsModels(): Map<EpicQuestCollectionsTypes, EpicQuestCollectionsModel>;
	public abstract getLootboxFactory(): LootboxFactory;
	public abstract getHardMoneyModel(): HardMoneyModel;
	public abstract getRewardFactory(): RewardFactory;
	public abstract getLevelModel(): LevelBaseModel;
	public abstract getSummonSavesModel(): SummonSavesModel;

	public getBackToGameRewardModel(): BackToGameRewardModel {
		return this.backToGameRewardModel;
	}

	public getSoftMoneyModel(): SoftMoneyModel {
		return this.softMoneyModel;
	}

	public getPrestigeMoneyModel(): PrestigeMoneyModel {
		return this.prestigeMoneyModel;
	}

	public getNewCustomerMultiplierModels(): Map<string, NewCustomersMutiplierModel> {
		return this.newCustomerMultiplierModels;
	}

	public getCharacterModels(): Map<string, CharacterModel> {
		return this.characterModels;
	}

	public getUpgradeModels(): Map<string, UpgradeModel> {
		return this.upgradeModels;
	}

	public getSkillModels(): Map<string, SkillModel> {
		return this.skillModels;
	}

	public getSummonModelsPool(): SummonModelsPool {
		return this.summonModelsPool;
	}

	public getBankModel(): BankModel {
		return this.bankModel;
	}

	public getBankSavesModel(): BankSavesModel {
		return this.bankSavesModel;
	}

	public getBoostModels(): Map<string, BoostModel> {
		return this.boostModels;
	}

	public getTimeskipModels(): Map<string, TimeskipModel> {
		return this.timeskipModels;
	}

	public getQuestLinesModel(): QuestLinesModel {
		return this.questLinesModel;
	}

	public getDialogsFactory(): DialogsFactory {
		return this.dialogsFactory;
	}

	public getFarewellBoostModel(): FarewellBoostModel {
		return this.farewellBoostModel;
	}

	public getMaintenanceModel(): MaintenanceModel {
		return this.maintenanceModel;
	}

	public getTotemModels(): Map<string, TotemModel> {
		return this.totemModels;
	}

	public getGalleryVideoModels(): Map<string, GalleryVideoModel> {
		return this.galleryVideoModels;
	}
}
