export enum TutorialStepUnlockTypes {
	LEVEL_START = 'levelStart',
	EVENT_START = 'eventStart',
	GET_QUEST = 'getQuest',
	QUEST_COMPLETE = 'questComplete',
	TUTORIAL_STEP = 'tutorialStep',
	PRESTIGE_BONUS = 'prestige_bonus',
	WINDOW_APPEAR_PREPARTY = 'gotoPartyWindowAppear',
	WINDOW_APPEAR_COLLECTIONS = 'collectionsWindowAppear',
	WINDOW_APPEAR_PARTY = 'partyWindowAppear',
	WINDOW_APPEAR_QUEST = 'questWindowAppear',
	WINDOW_APPEAR_EVENT_REWARDS = 'eventRewardsWindowAppear',
	WINDOW_APPEAR_BANK_BOOST_CONFIRM = 'bankBoostConfirmWindowAppear',
	INCOME_MONEY_APPEAR = 'incomeMoneyAppear',
	BUSINESS_ACQUIRED = 'businessAcquired',
	SUMMON_RESULT_CARDS_APPEARED = 'summonResultCardsAppeared',
	SUMMON_RESULT_CARDS_OPENED = 'summonResultCardsOpened',
	CUSTOMERS_ON_BUSINESS = 'customersOnBusiness',
	BUTTON_APPEAR_GO_TO_NEXT_LEVEL = 'buttonGoToNextLevelAppear',
	BUTTON_APPEAR_PREPARTY_START_CHARACTER = 'prepartyStrartCharacterAppear',
	GOAL_COMPLETED = 'taskCompleted',
	CHARACTER_ACTIVATED = 'hireGirl',
}
