import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { QuestType } from '@src/types/QuestTypes';

export abstract class QuestConfigBase implements ConfigSetElement {
	public static readonly FORMAT_PARAMETER_ID_KEY_PAIR: RegExp = /^\s*id\s*:\s*(\w*)$/;
	public static readonly FORMAT_PARAMETER_ALL: RegExp = /^\s*all\s*$/;
	public static readonly FORMAT_HOURS: RegExp = /([\s\S]+)h$/;

	public static readonly TYPE_ACQUIRE: string = 'Acquire';
	public static readonly TYPE_GET: string = 'Get';
	public static readonly TYPE_FIND_CARD: string = 'FindCard';
	public static readonly TYPE_PROMOTE: string = 'Promote';
	public static readonly TYPE_ACTIVATE: string = 'Activate';
	public static readonly TYPE_SPEND: string = 'Spend';
	public static readonly TYPE_AUTOMATE: string = 'Automate';
	public static readonly TYPE_SUMMON: string = 'Summon';

	public static readonly SUB_TYPE_BUSINESS: string = 'business';
	public static readonly SUB_TYPE_SOFT: string = 'soft';
	public static readonly SUB_TYPE_SOFT_AUTO: string = 'soft_auto';
	public static readonly SUB_TYPE_SOFT_TUTORIAL: string = 'soft_tutorial';
	public static readonly SUB_TYPE_PRESTIGE: string = 'prestige';
	public static readonly SUB_TYPE_CUSTOMERS: string = 'customers';
	public static readonly SUB_TYPE_CUSTOMERS_MULTIPLIER: string = 'customers_multiplier';
	public static readonly SUB_TYPE_CUSTOMERS_ON_SOME: string = 'customers_on_some';
	public static readonly SUB_TYPE_ALL: string = 'all';
	public static readonly SUB_TYPE_GIRLS: string = 'girls';
	public static readonly SUB_TYPE_UPGRADE: string = 'upgrade';
	public static readonly SUB_TYPE_GIRLS_UPGRADES: string = 'girls_upgrades';
	public static readonly SUB_TYPE_HARD: string = 'hard';
	public static readonly SUB_TYPE_PARTY: string = 'party';
	public static readonly SUB_TYPE_PAY = 'pay';

	public static readonly FIELD_KEY: string = 'id';
	public static readonly FIELD_TYPE: string = 'type';
	public static readonly FIELD_SUB_TYPE: string = 'sub_type';
	public static readonly FIELD_PARAMETERS: string = 'parameters';
	public static readonly FIELD_BASE_AMOUNT: string = 'base_amount';
	public static readonly FIELD_REWARD: string = 'reward';
	public static readonly FIELD_DIALOG_KEY: string = 'dialog_id';
	public static readonly FIELD_MAIN_WINDOW_VIEW_PARAMS: string = 'mainwindow_view_parameter';

	protected key: string;
	protected lootboxRewardKey: string;
	protected dialogKeys: string[];
	protected mainWindowViewParameters: string[];

	constructor(config: { [key: string]: string }) {
		this.key = config[QuestConfigBase.FIELD_KEY].trim();
		this.lootboxRewardKey = config[QuestConfigBase.FIELD_REWARD];

		if (config[QuestConfigBase.FIELD_DIALOG_KEY]) {
			this.dialogKeys = config[QuestConfigBase.FIELD_DIALOG_KEY].trim().split(',').map(x => x.trim());
		}

		if (config[QuestConfigBase.FIELD_MAIN_WINDOW_VIEW_PARAMS]) {
			const mainWindowViewParametersRaw = config[QuestConfigBase.FIELD_MAIN_WINDOW_VIEW_PARAMS].trim();
			this.mainWindowViewParameters = mainWindowViewParametersRaw.split(',').map((str: string) => str.trim());
		} else {
			this.mainWindowViewParameters = [];
		}
	}

	public static parseParameterIdKeyPair(config: { [key: string]: string }): string {
		const params: string = config[QuestConfigBase.FIELD_PARAMETERS];
		return params.match(QuestConfigBase.FORMAT_PARAMETER_ID_KEY_PAIR)[1];
	}

	public abstract getType(): QuestType;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
	public setParamsOnLevel(params: string): void {
		throw new Error('Not implemented');
	}

	// eslint-disable-next-line class-methods-use-this
	public canSetParamsOnLevel(): boolean {
		return true;
	}

	public getKey(): string {
		return this.key;
	}

	public getLootboxRewardKey(): string {
		return this.lootboxRewardKey;
	}

	public hasDialogKey(): boolean {
		return Boolean(this.dialogKeys);
	}

	public getMainWindowViewParameters(): string[] {
		return this.mainWindowViewParameters;
	}

	public getDialogKeys(): string[] {
		return this.dialogKeys;
	}
}
