import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter51010View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51010View';
import { EventCharacter51011View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51011View';
import { EventCharacter51012View } from '@views/characters/events/eventLevelWeekDay/EventCharacter51012View';

export class EventBusiness51004View extends BusinessBaseView {
	constructor() {
		const eventCharacter51010View = new EventCharacter51010View();
		const eventCharacter51011View = new EventCharacter51011View();
		const eventCharacter51012View = new EventCharacter51012View();

		super('business51004', [eventCharacter51010View, eventCharacter51011View, eventCharacter51012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter51012View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter51010View as PIXI.DisplayObject,
			eventCharacter51011View,
		);
	}
}
