import { TotemModel } from '@models/TotemModel';
import { PrepartyTotemGalleryItemView } from '@views/windows/farewellParty/preparty/PrepartyTotemGalleryItemView';
import { CardRarities, CardRarity } from '@src/types/CardRarities';
import { ScrollAxis } from '@src/types/ScrollViewTypes';
import { ScrollView } from '@views/components/ScrollView';
import * as TWEEN from '@tweenjs/tween.js';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export class PrepartyTotemsGalleryView extends PIXI.Container {
	private static sortByRarity(a: CardRarity, b: CardRarity): number {
		const aPriority = CardRarities.getRarityPriority(a);
		const bPriority = CardRarities.getRarityPriority(b);

		return aPriority - bPriority;
	}

	private readonly totemKeyCardMap: Map<string, PrepartyTotemGalleryItemView>;
	private readonly currentLevel: number;
	private readonly scrollView: ScrollView;
	private readonly contentContainer: PIXI.Container;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	public static readonly EVENT_BUTTON_FIND_CLICK: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();

	constructor(
		totemModels: Map<string, TotemModel>,
		currentLevel: number,
	) {
		super();
		this.currentLevel = currentLevel;

		this.totemKeyCardMap = new Map();

		this.tweenGroup = new TWEEN.Group();

		this.contentContainer = new PIXI.Container();

		const sorted = new Map([...totemModels].sort((a, b) => this.sortCards(a, b)));

		let i = 0;
		sorted.forEach((model) => {
			const x = 277 * i + 135;
			const y = 250;
			const item = new PrepartyTotemGalleryItemView(
				model,
				currentLevel,
			);
			item.position.set(x, y);
			item.on(PrepartyTotemGalleryItemView.EVENT_BUTTON_FIND_CLICK, this.onButtonFindClick, this);
			item.on(PrepartyTotemGalleryItemView.EVENT_CLICK, (data: PromotableClickData) => {
				this.emit(PrepartyTotemsGalleryView.EVENT_CARD_CLICK, data);
			}, this);
			this.contentContainer.addChild(item);

			this.totemKeyCardMap.set(model.getKey(), item);
			i += 1;
		});

		this.scrollView = new ScrollView(
			1922,
			450,
			ScrollAxis.HORIZONTAL,
			{ begin: 0, end: 50 },
		);
		this.scrollView
			.setContent(this.contentContainer)
			.setWheelScrollDirection(-1);
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.addChild(this.scrollView);
	}

	private onButtonFindClick(key: string): void {
		this.emit(PrepartyTotemsGalleryView.EVENT_BUTTON_FIND_CLICK, key);
	}

	private sortCards(mapA: [string, TotemModel], mapB: [string, TotemModel]): number {
		const a = mapA[1];
		const b = mapB[1];
		const level = this.currentLevel;

		// one closed - other opened
		if (a.isOpened() && !b.isOpened()) return -1;
		if (!a.isOpened() && b.isOpened()) return 1;

		// both closed
		if (!a.isOpened() && !b.isOpened()) {
			if (a.isUnlockableByMinLevel() && !b.isUnlockableByMinLevel()) return -1;
			if (!a.isUnlockableByMinLevel() && b.isUnlockableByMinLevel()) return 1;

			if (a.canUnlockInSummon(level) && !b.canUnlockInSummon(level)) return -1;
			if (!a.canUnlockInSummon(level) && b.canUnlockInSummon(level)) return 1;

			return 0;
		}

		// both opened
		if (a.isEnoughCardsForPromote() && !b.isEnoughCardsForPromote()) return -1;
		if (!a.isEnoughCardsForPromote() && b.isEnoughCardsForPromote()) return 1;

		// sort by level while rarity the same
		if (a.getCardRarity() === b.getCardRarity()) {
			if (a.getLevel() < b.getLevel()) return -1;
			if (a.getLevel() > b.getLevel()) return 1;

			return 0;
		}

		// sort by rarity
		return PrepartyTotemsGalleryView.sortByRarity(a.getCardRarity(), b.getCardRarity());
	}

	public startOpenAnimation(): void {
		this.contentContainer.children.forEach((child, i) => {
			const moveDelta = 50;
			const duration = 150 + i * 30;

			child.y += moveDelta;
			new TWEEN.Tween(child, this.tweenGroup)
				.to({ y: `-${moveDelta}` }, duration)
				.easing(TWEEN.Easing.Sinusoidal.Out)
				.start();
		});
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public enableScroll(): void {
		this.scrollView.enable();
	}

	public disableScroll(): void {
		this.scrollView.disable();
	}

	public resetScrollValue(): void {
		this.scrollView.jumpTo(0);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.ticker.remove(this.update, this);
		super.destroy(options);
	}
}
