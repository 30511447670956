import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter251013View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251013View';
import { EventCharacter251014View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251014View';
import { EventCharacter251015View } from '@views/characters/events/eventLevelWeekDay6/EventCharacter251015View';

export class EventBusiness251005View extends BusinessBaseView {
	constructor() {
		const eventCharacter251013View = new EventCharacter251013View();
		const eventCharacter251014View = new EventCharacter251014View();
		const eventCharacter251015View = new EventCharacter251015View();

		super('business251005', [eventCharacter251013View, eventCharacter251014View, eventCharacter251015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter251013View as PIXI.DisplayObject,
			eventCharacter251014View,
			eventCharacter251015View,
		);
	}
}
