import SoftMoneyModel from '@models/money/SoftMoneyModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';
import { AbstractReward } from '../../interfaces/AbstractReward';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';

export class RewardSoftMoney extends AbstractReward {
	private readonly softMoneyModel: SoftMoneyModel;
	private readonly quantity: SoftMoneyNumber;

	constructor(softMoneyModel: SoftMoneyModel, quantity: string) {
		super(RewardTypes.RESOURCE, RewardResourceIdTypes.SOFT_MONEY);

		this.softMoneyModel = softMoneyModel;
		this.quantity = SoftMoneyNumber.createFromString(quantity);
	}

	public collect(): void {
		this.softMoneyModel.add(this.quantity);
	}

	public getQuantity(): number {
		return this.quantity.toNumber();
	}

	public getQuantityString(): string {
		return this.quantity.toString();
	}

	public getQuantitySoft(): SoftMoneyNumber {
		return this.quantity;
	}
}
