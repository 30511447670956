import { DeviceUtils } from '@src/utils/DeviceUtils';
import { NetworkUtils } from '@src/utils/NetworkUtils';

export class NetworkRequestHeaders {
	public static readonly PLAYER_ID_KEY: string = 'PNK-Player-ID';

	private static readonly SESSION_ID_KEY: string = 'PNK-Session-Id';
	private static readonly DEVICE_ID_KEY: string = 'PNK-Device-ID';
	private static readonly EVENT_ID_KEY: string = 'Event-ID';
	private static readonly NUTAKU_ID_KEY = 'nutaku-id';
	private static readonly NUTAKU_TOKEN_KEY = 'oauth-token';
	private static readonly NUTAKU_TOKEN_SECRET_KEY = 'oauth-token-secret';
	private static readonly NUTAKU_ID_PARAM_KEY = 'opensocial_viewer_id';
	private static readonly NUTAKU_TOKEN_PARAM_KEY = 'oauth_token';
	private static readonly NUTAKU_TOKEN_PARAM_SECRET_KEY = 'oauth_token_secret';
	private static readonly SESSION_STORAGE_KEY_REFERRER = 'referrer';
	private static readonly PLAYER_ID_DEFAULT_VALUE: string = '';

	private playerId: string;
	private eventId?: string;
	private sessionId: string;
	private referrer: string;
	private nutakuId?: string;
	private token?: string;
	private tokenSecret?: string;

	constructor(
		isNutaku: boolean,
		private readonly version: string,
	) {
		let playerId: string | null;

		try {
			playerId = localStorage.getItem(NetworkRequestHeaders.PLAYER_ID_KEY);
		} catch (e) {
			playerId = null;
		}

		if (playerId === null) {
			this.playerId = NetworkRequestHeaders.PLAYER_ID_DEFAULT_VALUE;
		} else {
			this.playerId = playerId;
		}

		// sessionStorage unavailable in incognito
		try {
			if (sessionStorage.getItem(NetworkRequestHeaders.SESSION_STORAGE_KEY_REFERRER) != null) {
				this.referrer = sessionStorage.getItem(NetworkRequestHeaders.SESSION_STORAGE_KEY_REFERRER);
			} else {
				this.referrer = document?.referrer || '';

				if (this.referrer !== '') {
					sessionStorage.setItem(NetworkRequestHeaders.SESSION_STORAGE_KEY_REFERRER, this.referrer);
				}
			}
		} catch (e) {
			this.referrer = document?.referrer || '';
		}

		const urlParams = new URLSearchParams(NetworkUtils.getURLParameters());

		this.nutakuId = urlParams.get(NetworkRequestHeaders.NUTAKU_ID_PARAM_KEY);
		this.token = urlParams.get(NetworkRequestHeaders.NUTAKU_TOKEN_PARAM_KEY);
		this.tokenSecret = urlParams.get(NetworkRequestHeaders.NUTAKU_TOKEN_PARAM_SECRET_KEY);

		// Save nutaku info on SP version
		if (isNutaku && (DeviceUtils.isAndroid() || DeviceUtils.isIos())) {
			if (this.nutakuId != null) {
				localStorage.setItem(NetworkRequestHeaders.NUTAKU_ID_PARAM_KEY, this.nutakuId);
			} else {
				this.nutakuId = localStorage.getItem(NetworkRequestHeaders.NUTAKU_ID_PARAM_KEY);
			}

			if (this.tokenSecret != null) {
				localStorage.setItem(NetworkRequestHeaders.NUTAKU_TOKEN_PARAM_SECRET_KEY, this.tokenSecret);
			} else {
				this.tokenSecret = localStorage.getItem(NetworkRequestHeaders.NUTAKU_TOKEN_PARAM_SECRET_KEY);
			}
		}
	}

	public resetPlayerId(): void {
		this.playerId = NetworkRequestHeaders.PLAYER_ID_DEFAULT_VALUE;

		try {
			if (localStorage.getItem(NetworkRequestHeaders.PLAYER_ID_KEY) !== null) {
				localStorage.removeItem(NetworkRequestHeaders.PLAYER_ID_KEY);
			}
		} catch (e) {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}

	public getHeaders(useSessionId: boolean): Record<string, string> {
		// Remove version prefix
		const versionParts = this.version.split('_');
		const versionTimestamp = versionParts.length > 1 ? versionParts[1] : versionParts[0];

		const headers: Record<string, string> = {
			/* eslint-disable @typescript-eslint/naming-convention */
			'Content-Type': 'application/json',
			'PNK-env': '',
			'PNK-Referer': this.referrer,
			'PNK-version': versionTimestamp,
			/* eslint-enable @typescript-eslint/naming-convention */
		};

		headers[NetworkRequestHeaders.DEVICE_ID_KEY] = DeviceUtils.getDeviceId();
		headers[NetworkRequestHeaders.PLAYER_ID_KEY] = this.playerId;

		if (this.eventId != null) {
			headers[NetworkRequestHeaders.EVENT_ID_KEY] = this.eventId;
		}

		if (useSessionId) {
			headers[NetworkRequestHeaders.SESSION_ID_KEY] = this.sessionId;
		}

		if (this.nutakuId != null) {
			headers[NetworkRequestHeaders.NUTAKU_ID_KEY] = this.nutakuId;
		}

		if (this.token != null) {
			headers[NetworkRequestHeaders.NUTAKU_TOKEN_KEY] = this.token;
		}

		if (this.tokenSecret != null) {
			headers[NetworkRequestHeaders.NUTAKU_TOKEN_SECRET_KEY] = this.tokenSecret;
		}

		return headers;
	}

	public setNutakuId(value: string): void {
		this.nutakuId = value;
	}

	public setEventLevelId(value: string | undefined): void {
		this.eventId = value;
	}

	public setPlayerId(value: string): void {
		this.playerId = value;

		try {
			if (value != null && value !== '') {
				localStorage.setItem(NetworkRequestHeaders.PLAYER_ID_KEY, value);
			}
		} catch (e) {
			if (MODE_DEBUG) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}

	public setSessionId(value: string): void {
		this.sessionId = value;
	}

	public hasPlayerId(): boolean {
		return this.playerId !== NetworkRequestHeaders.PLAYER_ID_DEFAULT_VALUE;
	}

	public getPlayerId(): string {
		return this.playerId;
	}

	public getSessionId(): string {
		return this.sessionId;
	}
}
