import { RewardResourceIdTypes } from '@src/types/RewardTypes';
import { AssetsStorage } from '@main/AssetsStorage';

export class ResourceMiniCardView extends PIXI.Container {
	constructor(resourceId: RewardResourceIdTypes) {
		super();

		const miniCardsBase = AssetsStorage.getAtlas('miniCardsBaseAtlas');

		const bg = new PIXI.Sprite(miniCardsBase[`mini_card_${resourceId}_bg`]);
		const icon = new PIXI.Sprite(miniCardsBase[`${resourceId}_mini_card_icon`]);

		this.addChild(
			bg,
			icon,
		);
	}
}
