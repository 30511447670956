import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankOfferLocalizationKeysType } from '@configs/bank/BankOfferConfig';
import { BankOfferWindowPackBodyView } from './BankOfferWindowPackBodyView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';

export class BankOfferWindowPackBody1View extends BankOfferWindowPackBodyView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly timerLabel: PIXI.extras.BitmapText;

	constructor(
		bankTabElementWithOffer: BankTabElementWithOfferType,
		localizationKeys: BankOfferLocalizationKeysType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super(bankTabElementWithOffer);

		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();

		const bg = new PIXI.Sprite(AssetsStorage.getResource('popup_offer_bg').texture);
		bg.interactive = true;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.position.set(-20, -40);

		const titleText = new TextField(
			this.localizationStorage.getLocalizedString(localizationKeys.title),
			{ font: '74px wendyOneGold', align: 'center' },
			874,
			100,
		);
		titleText.anchor = 0.5;
		titleText.position.set(133, -337);

		const subtitleText = new MultiColoredTextField(
			{ font: '42px wendyOneShadowBold', align: 'center' },
			733,
			60,
		);
		subtitleText.text = this.localizationStorage.getLocalizedString(localizationKeys.subtitle1);
		subtitleText.anchor = 0.5;
		subtitleText.position.set(133, -232);

		const subtitleValue = new MultiColoredTextField(
			{ font: '52px wendyOneShadowBold', align: 'center', tint: 0xffc423 },
			722,
			79,
		);
		const priceCostResource = bankTabElementWithOffer.getButtonPriceResource();
		let subTitleValueString = this.localizationStorage.getLocalizedString(localizationKeys.subtitle2);
		subTitleValueString = subTitleValueString.replace('{{value}}', `${priceCostResource}${bankTabElementWithOffer.getPrice()}`);
		subtitleValue.text = subTitleValueString;
		subtitleValue.anchor = 0.5;
		subtitleValue.position.set(133, -176);


		const timerBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['black_panel'], 20, 0, 20, 0);
		timerBg.scale.set(0.8);
		timerBg.width = 340;
		timerBg.pivot.set(timerBg.width / 2, timerBg.height / 2);

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '44px wendyOneShadowBold', tint: 0xffc900,
		});
		this.timerLabel.anchor = 0.5;

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(20, -220);
		animationsContainer.scale.y = 0.7;
		animationsContainer.scale.x = 1.5;

		const glowLightsEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const timerContainer = new PIXI.Container();
		timerContainer.position.set(70, -410);
		timerContainer.addChild(
			timerBg,
			this.timerLabel as PIXI.DisplayObject,
		);

		const buyButton = getBuyButtonHandler(
			366,
			115,
			bankTabElementWithOffer,
		);
		buyButton.position.set(140, 207);

		const rewardsContainer = getRewardMiniCardsContainerHandler(25, bankTabElementWithOffer, 0.75);
		rewardsContainer.position.set(140, 0);

		this.addChild(
			animationsContainer,
			bg,
			titleText as PIXI.DisplayObject,
			subtitleText,
			buyButton,
			subtitleValue,
			timerContainer,
			rewardsContainer,
		);
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = text;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
