import { AssetsStorage } from '@main/AssetsStorage';
import { SoundController } from '@src/main/SoundController';
import { ButtonValueTypes, ButtonWithCostValueView } from '@views/components/buttons/ButtonWithCostValueView';
import * as TWEEN from '@tweenjs/tween.js';

export enum ButtonBuyCustomersMode {
	ENABLED,
	DISABLED,
	MAX,
}

export class ButtonBuyCustomersView extends ButtonWithCostValueView {
	private disabledBg: PIXI.mesh.NineSlicePlane;

	private ticker: PIXI.ticker.Ticker;
	private tweenGroup: TWEEN.Group;

	constructor() {
		const bgWidth = 220;

		const buttonBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_green'], 6, 6, 6, 6);
		buttonBg.width = bgWidth;
		buttonBg.height = 49;
		buttonBg.pivot.set(buttonBg.width / 2, buttonBg.height / 2);

		super({
			type: ButtonValueTypes.SOFT_MONEY,
			buttonBg,
			maxWidth: 164,
			fontStyle: { font: '26px wendyOneShadowBold', align: 'center' },
		});

		this.tweenGroup = new TWEEN.Group();

		this.buttonBg = buttonBg;

		this.costText.position.set(-25, -2);

		this.disabledBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_yellow'], 6, 6, 6, 6);
		this.disabledBg.width = bgWidth;
		this.disabledBg.height = 48;
		this.disabledBg.pivot.set(this.disabledBg.width / 2, this.disabledBg.height / 2);
		this.disabledBg.tint = 0xeaeaea;
		this.disabledBg.visible = false;

		this.addChildAt(this.disabledBg, 0);

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public setEnabled(value: boolean): void {
		this.enabled = value;

		this.buttonBg.visible = value;
		this.disabledBg.visible = !value;
		this.setCostValueEnabled(value);
	}

	public setMaxMode(value: number): void {
		(this.buttonBg as PIXI.Sprite).texture = AssetsStorage.getAtlas('uiAtlas')['buy_customers_btn_gray'];
		this.buttonBg.width = 249;
		this.costText.text = `[9eb0bb]${value} MAX LEVEL[-]`;
		this.costText.x = 15;
	}

	protected onPointerTap(): void {
		SoundController.getInstance().playButtonBuyNewCustomers();

		this.emit(ButtonBuyCustomersView.EVENT_CLICK, this);
	}

	public onZoomIn(): void {
		new TWEEN.Tween(this.costText, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
		new TWEEN.Tween(this.disabledBg, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
		new TWEEN.Tween(this.buttonBg, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();

		this.interactive = false;
	}

	public onZoomOut(): void {
		new TWEEN.Tween(this.costText, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
		new TWEEN.Tween(this.disabledBg, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
		new TWEEN.Tween(this.buttonBg, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();

		this.interactive = true;
	}

	public destroy(): void {
		this.tweenGroup.removeAll();
		super.destroy();
	}
}
