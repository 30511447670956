import { UpgradeModel } from '@models/UpgradeModel';
import { UpgradeCollectionsCardView } from '@views/windows/collection/upgrades/UpgradeCollectionsCardView';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export class UpgradeGroupCollectionView extends PIXI.Container {
	public static readonly EVENT_POINTER_DOWN: symbol = Symbol();
	public static readonly EVENT_POINTER_MOVE: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK: symbol = Symbol();

	private readonly localizationStorage: LocalizationStorage;

	private readonly businessKey: string;

	private upgradeKeyCardMap: Map<string, UpgradeCollectionsCardView>;

	private textTitle: PIXI.extras.BitmapText;
	private extraOffset: number;

	constructor(
		businessKey: string,
		upgrades: UpgradeModel[],
		extraOffset: number = 0,
	) {
		super();

		this.upgradeKeyCardMap = new Map();

		this.businessKey = businessKey;
		this.extraOffset = extraOffset;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);

		const bgHeight = 340;

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['u_window_bg_1'], 13, 13, 13, 13);
		bg.width = 1420;
		bg.height = 470;
		bg.tint = 0x000000;
		bg.alpha = 0.2;

		this.textTitle = new PIXI.extras.BitmapText(
			LocalizationStorage.getInstance().getLocalizedString(`#${this.businessKey}_Name`),
			{ font: '30px wendyOneShadowBold', tint: 0Xcbc29c },
		);
		this.textTitle.anchor = 0.5;
		this.textTitle.position.set(710, 35);

		const upgradesContainer = new PIXI.Container();

		upgrades.forEach((model, i) => {
			const card = new UpgradeCollectionsCardView(
				model,
			);
			card.on(UpgradeCollectionsCardView.EVENT_POINTER_DOWN, (y) => { this.emit(UpgradeGroupCollectionView.EVENT_POINTER_DOWN, y); }, this);
			card.on(UpgradeCollectionsCardView.EVENT_POINTER_MOVE, (event) => { this.emit(UpgradeGroupCollectionView.EVENT_POINTER_MOVE, event); }, this);
			card.on(UpgradeCollectionsCardView.EVENT_CLICK, (data: PromotableClickData) => this.emit(UpgradeGroupCollectionView.EVENT_CARD_CLICK, data), this);
			card.position.set((280 + this.extraOffset) * i, 0);
			upgradesContainer.addChild(card);

			this.upgradeKeyCardMap.set(model.getKey(), card);
		});

		upgradesContainer.position.set(118 + bg.width / 2 - upgradesContainer.width / 2, bgHeight * 0.5 + 100);

		this.addChild(
			bg,
			this.textTitle as PIXI.DisplayObject,
			upgradesContainer,
		);
	}

	private updateText(): void {
		this.textTitle.text = this.localizationStorage.getLocalizedString(`#${this.businessKey}_Name`);
	}

	public getOpenedItemContainer(key: string): PIXI.Container | undefined {
		return this.upgradeKeyCardMap.get(key).getOpenedItemContainer();
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.updateText, this);
		super.destroy(options);
	}
}
