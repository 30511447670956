import { EventGameUIViewSetter } from '@interfaces/ViewSetters';
import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepWindowViewTargetBaseDispatcher } from './TutorialStepWindowViewTargetBaseDispatcher';
import { EventLevelGameUIView } from '@views/ui/main/EventLevelGameUIView';
import { TutorialStepBaseModel } from '@models/tutorialSteps/TutorialStepBaseModel';

export class TutorialStepWindowViewTargetEventLevelDispatcher
	extends TutorialStepWindowViewTargetBaseDispatcher implements EventGameUIViewSetter {
	private gameUI: EventLevelGameUIView;

	public setEventGameUIView(view: EventLevelGameUIView): void {
		this.gameUI = view;
	}

	public dispatchViewTarget(tutorialStep: TutorialStepBaseModel): PIXI.Container {
		const targetType = tutorialStep.getTargetType();
		switch (targetType) {
			case TutorialStepTargetTypes.EVENT_GOAL_REWARD_ICON: {
				return this.dispatchTargetEventRewardIcon();
			}
			case TutorialStepTargetTypes.EVENT_INCOME_PROGRESS_BAR: {
				return this.dispatchTargetEventIncomeProgressBar();
			}
			case TutorialStepTargetTypes.EVENT_BUTTON_CLOSE_REWARDS_WINDOW: {
				return this.dispatchTargetCloseEventRewardsWindow();
			}
			case TutorialStepTargetTypes.EVENT_BUTTON_RANKING_WINDOW: {
				return this.dispatchTargetButtonRankingWindow();
			}
			default:
				return super.dispatchViewTarget(tutorialStep);
		}
	}

	private dispatchTargetButtonRankingWindow(): PIXI.Container {
		const targetButton = this.gameUI.getButtonRankingWindowContainer();
		return targetButton;
	}

	private dispatchTargetEventRewardIcon(): PIXI.Container {
		const targetButton = this.gameUI.getEventGoalRewardIconContainer();
		return targetButton;
	}

	private dispatchTargetEventIncomeProgressBar(): PIXI.Container {
		const targetButton = this.gameUI.getEventIncomeProgressBarContainer();
		return targetButton;
	}

	private dispatchTargetCloseEventRewardsWindow(): PIXI.Container {
		const targetButton = this.windowViewSystem.getEventRewardsWindow().getButtonCloseContainer();
		return targetButton;
	}
}
