import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter55010View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55010View';
import { EventCharacter55011View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55011View';
import { EventCharacter55012View } from '@views/characters/events/eventLevelWeekDay5/EventCharacter55012View';

export class EventBusiness55004View extends BusinessBaseView {
	constructor() {
		const eventCharacter55010View = new EventCharacter55010View();
		const eventCharacter55011View = new EventCharacter55011View();
		const eventCharacter55012View = new EventCharacter55012View();

		super('business55004', [eventCharacter55010View, eventCharacter55011View, eventCharacter55012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter55010View as PIXI.DisplayObject,
			eventCharacter55011View,
			eventCharacter55012View,
		);
	}
}
