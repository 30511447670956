import { BankBundleGroupConfig, BundleGroupCharacterViewType } from '@configs/bank/BankBundleGroupConfig';
import { ModelWithOffer } from '@interfaces/ModelWithOffer';
import { BankBundleModel } from './BankBundleModel';

export class BankBundleGroupModel implements ModelWithOffer {
	private readonly key: string;
	private readonly bundles: BankBundleModel[];
	private readonly characterViewType: BundleGroupCharacterViewType;
	private readonly offerKey: string;

	constructor(config: BankBundleGroupConfig) {
		this.key = config.getKey();
		this.characterViewType = config.getCharacterViewType();
		this.bundles = [];
		this.offerKey = config.getOfferKey();
	}

	public getOfferKey(): string {
		return this.offerKey;
	}

	public hasOfferKey(): boolean {
		return Boolean(this.offerKey);
	}

	public addBundle(child: BankBundleModel): void {
		this.bundles.push(child);
	}

	public getBundles(): BankBundleModel[] {
		return this.bundles;
	}

	public getCharacterViewType(): BundleGroupCharacterViewType {
		return this.characterViewType;
	}

	public getKey(): string {
		return this.key;
	}
}
