import { BankRechargeConfig } from '@configs/bank/BankRechargeConfig';
import { AbstractReward } from '@interfaces/AbstractReward';
import { BankBundleGroupModel } from '@models/bank/BankBundleGroupModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankModel } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BankRechargeModel } from '@models/bank/BankRechargeModel';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';
import { ModelHelper } from '@models/ModelHelper';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { BankBundleGroupsFactory } from '@src/initializers/bank/BankBundleGroupsFactory';
import { BankBundlesFactory } from '@src/initializers/bank/BankBundlesFactory';
import { BankOffersFactory } from '@src/initializers/bank/BankOffersFactory';
import { BankRechargesFactory } from '@src/initializers/bank/BankRechargesFactory';
import { BankSubscribesFactory } from '@src/initializers/bank/BankSubscribesFactory';
import { RewardFactory } from '@src/initializers/RewardFactory';
import { BankBundleConfigsFilter } from '@src/loaders/bank/configFilters/BankBundleConfigsFilter';
import { BankGemShopConfigsFilter } from '@src/loaders/bank/configFilters/BankGemShopConfigsFilter';
import { BankOfferConfigsFilter } from '@src/loaders/bank/configFilters/BankOfferConfigsFilter';
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';
import { RewardDescriptionType } from '@src/types/RewardTypes';
import { RewardGroups } from '@views/windows/rewardResultWindow/RewardResultWindowBaseView';
import { BankConfigsLoaderBase } from './BankConfigsLoaderBase';
import { UnlockValueChecker } from '../UnlockValueChecker';
import { BankBundleGroupConfigsFilter } from './configFilters/BankBundleGroupConfigsFilter';
import { BankRechargeConfigsFilter } from './configFilters/BankRechargeConfigsFilter';
import { ActivateOfferAction } from '@models/network/actions/ActivateOfferAction';
import { BaseAction } from '@models/network/actions/BaseAction';
import { BankElementIdPrefix } from '@src/viewControllers/BankWindowViewController';
import { ApplyPurchaseAction } from '@models/network/actions/bank/ApplyPurchaseAction';
import { TransactionCreator } from '@interfaces/TransactionCreator';
import { TransactionSaveData } from '@src/types/SaveTypes';
import { TransactionResult } from '@src/types/TransactionTypes';
import { BankGemShopItemsFactory } from '@src/initializers/bank/BankGemShopItemsFactory';
import { TutorialStepsEmitter } from '@src/emitters/TutorialStepsEmitter';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { LevelBaseModel } from '@models/level/LevelBaseModel';
import { BankLoaderConstants } from '@configs/ConstantsConfig';

export type BankConfigsMapType = Map<string, BankTabElementDataConfigNameTypes>;

export class BankLoader extends PIXI.utils.EventEmitter {
	private readonly bundleConfigsFilter: BankBundleConfigsFilter;
	private readonly bundleGroupConfigsFilter: BankBundleGroupConfigsFilter;
	private readonly gemShopConfigsFilter: BankGemShopConfigsFilter;
	private readonly offersConfigsFilter: BankOfferConfigsFilter;
	private readonly rechargeConfigsFilter: BankRechargeConfigsFilter;

	private rechargeModels: Map<string, BankRechargeModel>;
	private bundleModels: Map<string, BankBundleModel>;
	private bundleGroupModels: Map<string, BankBundleGroupModel>;
	private subscribeModels: Map<string, BankSubscribeModel>;
	private offerModels: Map<string, BankOfferModel>;
	private bankConfigsLoaded: boolean;

	private initPendingCounter: number;
	private initInProgress: boolean;
	private lastUpdateTimestamp?: number;
	private updatesStarted: boolean;
	private isUpdatesEnabled: boolean;

	constructor(
		private readonly configsLoader: BankConfigsLoaderBase,
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly bankSavesModel: BankSavesModel,
		private readonly bankModel: BankModel,
		private readonly networkRequestSender: NetworkRequestSender,
		private readonly transactionCreator: TransactionCreator,
		private readonly serverTime: ServerTimeModel,
		private readonly bundlesFactory: BankBundlesFactory,
		private readonly gemShopItemsFactory: BankGemShopItemsFactory,
		private readonly rewardFactory: RewardFactory,
		private notCompletedTransactions: TransactionSaveData[],
		private readonly priceConversionCoeff: number,
		private readonly tutorialStepsEmitter: TutorialStepsEmitter,
		private readonly businessModels: Map<string, BusinessModel>,
		private readonly characterModels: Map<string, CharacterModel>,
		private readonly upgradeModels: Map<string, UpgradeModel>,
		private readonly levelModel: LevelBaseModel,
		private readonly questLinesModel: QuestLinesModel,
		private readonly loaderConstants: BankLoaderConstants,
	) {
		super();

		this.isUpdatesEnabled = true;

		this.bundleConfigsFilter = new BankBundleConfigsFilter(
			this.unlockValueChecker,
			this.bankSavesModel,
		);
		this.bundleGroupConfigsFilter = new BankBundleGroupConfigsFilter(
			this.unlockValueChecker,
			this.bankSavesModel,
		);
		this.offersConfigsFilter = new BankOfferConfigsFilter(
			this.unlockValueChecker,
			this.bankSavesModel,
		);
		this.gemShopConfigsFilter = new BankGemShopConfigsFilter(
			this.unlockValueChecker,
			this.bankSavesModel,
		);
		this.rechargeConfigsFilter = new BankRechargeConfigsFilter(
			this.bankSavesModel,
			this.unlockValueChecker,
		);

		this.initPendingCounter = 0;

		this.tutorialStepsEmitter.on(TutorialStepsEmitter.EVENT_BASE_STEP_UNLOCKED, this.tryUpdate, this);

		this.businessModels.forEach((model) => {
			model.on(BusinessModel.EVENT_ACQUIRED, this.tryUpdate, this);
			model.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.tryUpdate, this);
		});

		this.characterModels.forEach((model) => {
			model.on(CharacterModel.EVENT_ACTIVATED, this.tryUpdate, this);
		});

		this.upgradeModels.forEach((model) => {
			model.on(UpgradeModel.EVENT_ACTIVATED, this.tryUpdate, this);
		});

		this.levelModel.on(LevelBaseModel.EVENT_PROGRESS, this.tryUpdate, this);

		// quest events should always update bank
		this.questLinesModel.on(QuestLinesModel.EVENT_INITED, this.init, this);
		this.questLinesModel.on(QuestLinesModel.EVENT_LINE_PROGRESS, this.init, this);
	}

	public async checkNotCompletedTransactions(): Promise<{ rewards?: AbstractReward[], groups?: RewardGroups }> {
		let result = {};

		if (this.notCompletedTransactions.length > 0) {
			const rewards: AbstractReward[] = [];
			const groups: RewardGroups = {};
			const transactionResults: TransactionResult[] = await Promise.all(this.notCompletedTransactions.map((x) => this.transactionCreator.check(x)));

			transactionResults.forEach((x, index) => {
				const action = new ApplyPurchaseAction(x.notCommitedTransactionId, x.rewards);
				this.emit(BaseAction.EVENT_ACTION_CREATED, action);
				const rewardsDescr = ModelHelper.mergeRewardDescriptions(x.rewards);
				rewardsDescr.forEach((rewardDescr) => {
					const reward = this.rewardFactory.createReward(rewardDescr);
					rewards.push(reward);
				});
				groups[index] = rewardsDescr.length;
			});
			result = { rewards, groups };

			this.notCompletedTransactions = [];
		}

		return result;
	}

	public async init(): Promise<void> {
		if (this.initInProgress) {
			this.initPendingCounter += 1;
			return;
		}

		this.initInProgress = true;

		this.tryClearTimeoutListeners();

		if (!this.bankConfigsLoaded) {
			await this.configsLoader.loadConfigs();
			this.bankConfigsLoaded = true;
		}

		const bundleConfigs = this.configsLoader.getBundlesConfigs();

		const currentTime = this.serverTime.getCalculatedISOTime();

		const offerConfigsFiltered = this.offersConfigsFilter.filter(
			this.configsLoader.getOffersConfigs(),
			currentTime,
		);

		// Offers
		if (offerConfigsFiltered.size > 0) {
			this.offerModels = BankOffersFactory.createModels(
				offerConfigsFiltered,
				this.bankSavesModel,
				this.serverTime.getCalculatedISOTime(),
				this.offerModels,
			);
			this.offerModels.forEach((model) => {
				model.once(BankOfferModel.EVENT_TIMEOUT, this.init, this);
				const key: string = model.getKey();
				const endTime: number = model.getEndTime();

				if (this.bankSavesModel.getOfferEndTime(key) <= currentTime) {
					this.bankSavesModel.updateOfferData(key, endTime);
					const action = new ActivateOfferAction(key, endTime);
					this.emit(BaseAction.EVENT_ACTION_CREATED, action);
				}
			});
		} else {
			this.offerModels = new Map();
		}

		const bundleGroupConfigsFiltered = this.bundleGroupConfigsFilter.filter(
			this.configsLoader.getBundleGroupsConfigs(),
			bundleConfigs,
			offerConfigsFiltered,
			currentTime,
		);

		const rechargeConfigsFiltered = this.rechargeConfigsFilter.filter(
			this.configsLoader.getRechargeConfigs(),
		);
		const gemShopConfigsFiltered = this.gemShopConfigsFilter.filter(
			this.configsLoader.getGemShopConfigs(),
			offerConfigsFiltered,
		);
		const bundleConfigsFiltered = this.bundleConfigsFilter.filter(
			bundleConfigs,
			offerConfigsFiltered,
			bundleGroupConfigsFiltered,
			currentTime,
		);

		// Recharge
		const notClaimedRechargeConfigs: Map<string, BankRechargeConfig> = new Map();
		this.configsLoader.getRechargeConfigs().forEach((config, key) => {
			if (!this.bankSavesModel.isRechargeClaimed(config.getKey())) {
				notClaimedRechargeConfigs.set(key, config);
			}
		});
		const claimableRechargeKeys = Array.from(rechargeConfigsFiltered.values())
			.filter(configFiltered => notClaimedRechargeConfigs.has(configFiltered.getKey()))
			.map(config => config.getKey());
		this.rechargeModels = BankRechargesFactory.createModels(
			notClaimedRechargeConfigs,
			claimableRechargeKeys,
		);

		// GemShopItems
		const gemShopModels = this.gemShopItemsFactory.createModels(
			gemShopConfigsFiltered,
			this.priceConversionCoeff,
		);

		// Bundles
		this.bundleModels = this.bundlesFactory.createModels(
			bundleConfigsFiltered,
			this.bankSavesModel,
			currentTime,
			this.priceConversionCoeff,
		);

		const modelsWithoutRewards: Map<string, BankBundleModel | BankRechargeModel> = new Map();
		this.bundleModels.forEach((model) => {
			model.once(BankBundleModel.EVENT_TIMEOUT, this.init, this);
			if (!this.bankSavesModel.hasBundleRewards(model.getKey(), currentTime)) {
				modelsWithoutRewards.set(BankElementIdPrefix.BUNDLES + model.getKey(), model);
			}
		});
		this.rechargeModels.forEach((model) => {
			if (!this.bankSavesModel.hasRechargeRewards(model.getKey())) {
				modelsWithoutRewards.set(BankElementIdPrefix.RECHARGE + model.getKey(), model);
			}
		});

		if (modelsWithoutRewards.size > 0) {
			const response = await this.networkRequestSender.sendBankGetData(
				Array.from(modelsWithoutRewards.keys()),
				this.serverTime.getCalculatedISOTime(),
			);

			modelsWithoutRewards.forEach((model, key) => {
				const rewards: RewardDescriptionType[] = Object.values(response[key]?.rewards || []);

				if (model instanceof BankBundleModel) {
					const expiresOn: number = response[key]['expires_on'];
					const acceptedBuyTimes: number = response[key]['accepted_times'];

					this.bankSavesModel.updateBundleSaveData(model.getKey(), {
						rewards,
						expiresOn,
						acceptedBuyTimes,
					});
				} else if (model instanceof BankRechargeModel) {
					this.bankSavesModel.updateRechargeRewards(model.getKey(), rewards);
				}
			});
		}

		// Subscribes
		this.subscribeModels = BankSubscribesFactory.createModels(
			this.configsLoader.getSubscribesConfigs(),
			currentTime,
			this.priceConversionCoeff,
		);
		this.subscribeModels.forEach((model) => {
			model.once(BankSubscribeModel.EVENT_TIMEOUT, this.init, this);
		});

		// BundleGroups
		this.bundleGroupModels = BankBundleGroupsFactory.createModels(
			bundleGroupConfigsFiltered,
			bundleConfigsFiltered,
			this.bundleModels,
		);

		this.bankModel.init(
			Array.from(this.configsLoader.getBankTabsConfigs().values()),
			gemShopModels,
			this.bundleModels,
			this.subscribeModels,
			this.offerModels,
			this.bundleGroupModels,
			this.rechargeModels,
		);

		this.initInProgress = false;
		this.lastUpdateTimestamp = Date.now();

		if (this.initPendingCounter > 0) {
			this.initPendingCounter -= 1;
			this.init();
		} else if (!this.updatesStarted) {
			this.startUpdates();
		}
	}

	public setUpdatesEnabled(value: boolean): void {
		this.isUpdatesEnabled = value;
	}

	private async tryUpdate(): Promise<void> {
		if (this.isUpdatesEnabled && this.loaderConstants.updateEnabled) {
			this.init();
		}
	}

	private startUpdates(): void {
		this.updatesStarted = true;

		setInterval(() => {
			if (this.isTimeForAnUpdate()) {
				this.tryUpdate();
			}
		}, this.loaderConstants.updateInterval * 1000);
	}

	private isTimeForAnUpdate(): boolean {
		const lastInterval = Date.now() - this.lastUpdateTimestamp;
		return lastInterval >= this.loaderConstants.updateInterval * 1000;
	}

	private tryClearTimeoutListeners(): void {
		// eslint-disable-next-line no-unused-expressions
		this.bundleModels?.forEach(listener => {
			if (listener.listeners(BankBundleModel.EVENT_TIMEOUT).includes(this.init)) {
				listener.off(BankBundleModel.EVENT_TIMEOUT, this.init, true);
			}
		});
		// eslint-disable-next-line no-unused-expressions
		this.offerModels?.forEach(listener => {
			if (listener.listeners(BankOfferModel.EVENT_TIMEOUT).includes(this.init)) {
				listener.off(BankOfferModel.EVENT_TIMEOUT, this.init, true);
			}
		});
		// eslint-disable-next-line no-unused-expressions
		this.subscribeModels?.forEach(listener => {
			if (listener.listeners(BankSubscribeModel.EVENT_TIMEOUT).includes(this.init)) {
				listener.off(BankSubscribeModel.EVENT_TIMEOUT, this.init, true);
			}
		});
	}
}
