import { LevelConfigNames } from '@src/types/LevelConfigNames';
import { BankConfigsLoaderBase } from './BankConfigsLoaderBase';

export class BankLevelConfigsLoader extends BankConfigsLoaderBase {
	private static get CONFIGS_TO_LOAD(): string[] {
		return [
			LevelConfigNames.CONFIG_BANK_BUNDLES,
			LevelConfigNames.CONFIG_BANK_BUNDLE_GROUPS,
			LevelConfigNames.CONFIG_BANK_GEM_SHOP,
			LevelConfigNames.CONFIG_BANK_OFFERS,
			LevelConfigNames.CONFIG_BANK_RECHARGE,
			LevelConfigNames.CONFIG_BANK_SUBSCRIBE,
			LevelConfigNames.CONFIG_BANK_TABS,
		];
	}

	public async loadConfigs(): Promise<void> {
		const bankConfigs = await this.networkRequestSender.sendGetConfigs([
			...BankLevelConfigsLoader.CONFIGS_TO_LOAD,
		]);

		this.bankConfigsParser.parseBankConfigs(
			bankConfigs[LevelConfigNames.CONFIG_BANK_TABS],
			bankConfigs[LevelConfigNames.CONFIG_BANK_GEM_SHOP],
			bankConfigs[LevelConfigNames.CONFIG_BANK_RECHARGE],
			bankConfigs[LevelConfigNames.CONFIG_BANK_BUNDLES],
			bankConfigs[LevelConfigNames.CONFIG_BANK_SUBSCRIBE],
			bankConfigs[LevelConfigNames.CONFIG_BANK_OFFERS],
			bankConfigs[LevelConfigNames.CONFIG_BANK_BUNDLE_GROUPS],
		);
	}
}
