import { CharacterMainBaseView } from '../CharacterMainBaseView';

export class Character18View extends CharacterMainBaseView {
	constructor() {
		super('character18');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-1, 38);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(35, -55),
			bgPosition: new PIXI.Point(100, -59),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
