import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60016View } from '@views/characters/events/event6/EventCharacter60016View';
import { EventCharacter60017View } from '@views/characters/events/event6/EventCharacter60017View';
import { EventCharacter60018View } from '@views/characters/events/event6/EventCharacter60018View';

export class EventBusiness60006View extends BusinessBaseView {
	constructor() {
		const eventCharacter60016View = new EventCharacter60016View();
		const eventCharacter60017View = new EventCharacter60017View();
		const eventCharacter60018View = new EventCharacter60018View();

		super('business60006', [eventCharacter60016View, eventCharacter60017View, eventCharacter60018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor1']).setTransform(-102, 63, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter60018View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter60016View as PIXI.DisplayObject,
			eventCharacter60017View,
		);
	}
}
