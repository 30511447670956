import { TimeskipModel } from '@models/TimeskipModel';
import { BusinessModel } from '@models/BusinessModel';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class ModelWriterBusinessIncomePerSec {
	private readonly businessModels: BusinessModel[];
	private readonly timeskipModels: TimeskipModel[];

	constructor(
		businessModels: Map<string, BusinessModel>,
		timeskipModels: Map<string, TimeskipModel>,
	) {
		this.timeskipModels = Array.from(timeskipModels.values());
		const onUpdateIncomePerSec = (model: BusinessModel): void => {
			if (model.isAutomated()) {
				this.updateIncomePerSec();
			}
		};

		this.businessModels = Array.from(businessModels.values());
		this.businessModels.forEach(model => {
			model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, onUpdateIncomePerSec, this);
			model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, onUpdateIncomePerSec, this);
			model.on(BusinessModel.EVENT_AUTOMATED, this.updateIncomePerSec, this);
		});
	}

	public init(): void {
		this.updateIncomePerSec();
	}

	protected updateIncomePerSec(): void {
		const incomeWithBoostAndSkill = this.getIncomePerSec(false, false);

		this.timeskipModels.forEach((x) => {
			x.setIncomePerWithBoostSec(incomeWithBoostAndSkill);
		});
	}

	protected getIncomePerSec(noSkills: boolean, noBoosts: boolean): SoftMoneyNumber {
		const businessModelsAutomated = this.businessModels.filter(model => model.isAutomated());
		let incomePerSec: SoftMoneyNumber;

		if (businessModelsAutomated.length > 0) {
			incomePerSec = businessModelsAutomated
				.map(model => model.getBusinessIncomePerSec(false, noSkills, noBoosts))
				.reduce((prev, next) => prev.add(next));
		} else {
			incomePerSec = SoftMoneyNumber.ZERO;
		}

		return incomePerSec;
	}
}
