import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter80013View } from '@views/characters/events/event8/EventCharacter80013View';
import { EventCharacter80014View } from '@views/characters/events/event8/EventCharacter80014View';
import { EventCharacter80015View } from '@views/characters/events/event8/EventCharacter80015View';

export class EventBusiness80005View extends BusinessBaseView {
	constructor() {
		const eventCharacter80013View = new EventCharacter80013View();
		const eventCharacter80014View = new EventCharacter80014View();
		const eventCharacter80015View = new EventCharacter80015View();


		super('business80005', [eventCharacter80013View, eventCharacter80014View, eventCharacter80015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter80013View,
			eventCharacter80015View,
			eventCharacter80014View as PIXI.DisplayObject,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
		);
	}
}
