import { SkillModel } from '@models/skills/SkillModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { SkillTypes } from '@src/types/SkillTypes';
import { SkillAutoTapModel } from '@models/skills/SkillAutoTapModel';

export class SkillsTicker extends PIXI.utils.EventEmitter {
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly serverTimeModel: ServerTimeModel;
	private readonly skillModels: Map<string, SkillModel>;

	private readonly skillsOnTimer: string[];
	private readonly skillsAutoTapKeyTickerMap: Map<string, number>;

	constructor(skillModels: Map<string, SkillModel>, serverTime: ServerTimeModel) {
		super();

		this.serverTimeModel = serverTime;
		this.skillModels = skillModels;
		this.skillsOnTimer = [];
		this.skillsAutoTapKeyTickerMap = new Map();

		this.ticker = PIXI.ticker.shared;

		this.skillModels.forEach((skillModel) => {
			const active = skillModel.isActive();
			const type = skillModel.getType();
			if ((active && type !== SkillTypes.EFFECTIVE_IMPROVE) || skillModel.isReloading()) {
				this.skillsOnTimer.push(skillModel.getKey());
			}
			if (active && type === SkillTypes.AUTO_TAP) {
				this.skillsAutoTapKeyTickerMap.set(skillModel.getKey(), NaN);
			}

			skillModel.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this);
			skillModel.on(SkillModel.EVENT_SKILL_DEACTIVATED, this.onSkillDeactivated, this);
		});
	}

	public setEnabled(value: boolean): void {
		if (value) {
			this.ticker.add(this.onTimerUpdate, this);

			Array.from(this.skillsAutoTapKeyTickerMap.keys()).forEach(skillKey => {
				const ticker = this.createAutoTapTicker(skillKey);
				this.skillsAutoTapKeyTickerMap.set(skillKey, ticker);
			});
		} else {
			this.ticker.remove(this.onTimerUpdate, this);

			this.skillsAutoTapKeyTickerMap.forEach(autoTapTicker => {
				window.clearInterval(autoTapTicker);
			});
		}
	}

	private createAutoTapTicker(skillKey: string): number {
		const skillModel = this.skillModels.get(skillKey) as SkillAutoTapModel;
		const generateTime = Math.floor(1000 / skillModel.getMultiplier());

		const ticker = window.setInterval(
			() => { skillModel.generateTap(); },
			generateTime,
		);
		return ticker;
	}

	private onTimerUpdate(): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTimeMS();
		this.skillsOnTimer.forEach((skillKey, index) => {
			const skillModel = this.skillModels.get(skillKey);
			if (skillModel.isActive()) {
				const skillActiveEndTime: number = (skillModel.getActivatedOn() + skillModel.getActiveTime()) * 1000;
				if (skillActiveEndTime <= currentTime) {
					skillModel.deactivate();
				} else {
					const timeleft = Math.min(
						skillActiveEndTime - currentTime,
						skillModel.getActiveTime() * 1000,
					);
					skillModel.setTimeLeftMS(timeleft);
				}
			} else if (skillModel.isReloading()) {
				const reloadEndTime: number = (skillModel.getActivatedOn() + skillModel.getActiveTime() + skillModel.getReloadTime()) * 1000;
				if (reloadEndTime <= currentTime) {
					skillModel.onReloadingComplete();
					delete this.skillsOnTimer[index];
				} else {
					const timeleft = Math.min(
						reloadEndTime - currentTime,
						skillModel.getReloadTime() * 1000,
					);
					skillModel.setTimeLeftMS(timeleft);
				}
			}
		});
	}

	private onSkillActivated(skillModel: SkillModel): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();

		skillModel.setActivatedOn(currentTime);

		switch (skillModel.getType()) {
			case SkillTypes.CONSTANT_PROFIT:
				skillModel.setIsActive(false);
				skillModel.setIsReloading(true);
				this.skillsOnTimer.push(skillModel.getKey());
				break;

			case SkillTypes.EFFECTIVE_IMPROVE:
			case SkillTypes.REDUCE_RELOAD:
				break;

			case SkillTypes.AUTO_TAP: {
				const ticker = this.createAutoTapTicker(skillModel.getKey());
				this.skillsAutoTapKeyTickerMap.set(skillModel.getKey(), ticker);

				this.skillsOnTimer.push(skillModel.getKey());
				break;
			}

			default:
				this.skillsOnTimer.push(skillModel.getKey());
		}
	}

	private onSkillDeactivated(skillModel: SkillModel): void {
		const skillKey = skillModel.getKey();

		this.skillsOnTimer.push(skillKey);

		if (this.skillsAutoTapKeyTickerMap.has(skillKey)) {
			const autoTapTicker = this.skillsAutoTapKeyTickerMap.get(skillKey);
			window.clearInterval(autoTapTicker);
			this.skillsAutoTapKeyTickerMap.delete(skillKey);
		}
	}
}
