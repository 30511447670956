import { BankBundleGroupConfig } from '@configs/bank/BankBundleGroupConfig';
import { UnlockValueChecker } from '../../UnlockValueChecker';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';

export class BankBundleGroupConfigsFilter {
	constructor(
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly bankSavesModel: BankSavesModel
	) { }

	public filter(
		source: Map<string, BankBundleGroupConfig>,
		bundleConfigs: Map<string, BankBundleConfig>,
		offers: Map<string, BankOfferConfig>,
		currentTime: number,
	): Map<string, BankBundleGroupConfig> {
		const filteredMap: Map<string, BankBundleGroupConfig> = new Map();
		const bundles = Array.from(bundleConfigs.values());

		source.forEach((config: BankBundleGroupConfig, key: string) => {
			let result: boolean = true;
			if (config.hasOfferKey()) {
				result = offers.has(config.getOfferKey());
			}
			if (config.hasSomeUnlockTypeValue()) {
				const unlockTypes = config.getUnlockTypes();
				const unlockValues = config.getUnlockValues();

				for (let i: number = 0; i < unlockTypes.length && result; i++) {
					result &&= this.unlockValueChecker.check(unlockTypes[i], unlockValues[i]);
				}
			}
			const currentBundles = bundles.filter(x => x.getBundleGroupKey() === config.getKey());
			let totalBuyTimes = 0;
			currentBundles.forEach(x => {
				totalBuyTimes += this.bankSavesModel.getBundleAcceptedBuyTimes(x.getKey(), currentTime);
			});
			result &&= totalBuyTimes < config.getBuyTimes();

			if (result) {
				filteredMap.set(key, config);
			}
		});
		return filteredMap;
	}
}
