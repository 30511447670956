import { CheatModel } from '@models/CheatModel';
import { CheatWindowViewSetter } from '@interfaces/ViewSetters';
import { CheatWindowView } from '@views/windows/cheatWindow/CheatWindowView';

export class CheatWindowPoupsViewController implements CheatWindowViewSetter {
	constructor(
		private readonly cheatModel: CheatModel,
	) {}

	public setCheatWindowView(view: CheatWindowView): void {
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_BANK_OFFERS, this.onPopupToggleBankOffers, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_DIALOGS, this.onPopupToggleDialogs, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_ERROR, this.onPopupToggleError, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_GO_TO_EVENT, this.onPopupToggleGoToEvent, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_TUTORAIL, this.onPopupToggleTutorial, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_NEWS, this.onPopupToggleNews, this);
		view.on(CheatWindowView.EVENT_POPUP_TOGGLE_DAILY_REWARD, this.onPopupToggleDailyReward, this);
	}

	private onPopupToggleBankOffers(): void {
		this.cheatModel.setEmitterBankOfferWindowsEnabled(!this.cheatModel.isEmitterBankOfferWindowsEnabled());
	}

	private onPopupToggleGoToEvent(): void {
		this.cheatModel.setEmitterGoToEventWindowsEnabled(!this.cheatModel.isEmitterGoToEventWindowsEnabled());
	}

	private onPopupToggleTutorial(): void {
		this.cheatModel.setTutorialEnabled(!this.cheatModel.isTutorialEnabled());
	}

	private onPopupToggleDialogs(): void {
		this.cheatModel.setEmitterDialogsWindowsEnabled(!this.cheatModel.isEmitterDialogWindowsEnabled());
	}

	private onPopupToggleError(): void {
		this.cheatModel.setErrorWindowEnabled(!this.cheatModel.isErrorWindowEnabled());
	}

	private onPopupToggleNews(): void {
		this.cheatModel.setNewsPushingEnabled(!this.cheatModel.isNewsPushingEnabled());
	}

	private onPopupToggleDailyReward(): void {
		this.cheatModel.setDailyRewardPushingEnabled(!this.cheatModel.isDailyRewardPushingEnabled());
	}
}
