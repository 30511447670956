import HardMoneyModel from '@models/money/HardMoneyModel';
import { RewardTypes, RewardResourceIdTypes } from '@src/types/RewardTypes';
import { AbstractReward } from '@interfaces/AbstractReward';
import SoftMoneyNumber from '@src/utils/SoftMoneyNumber';

export class RewardHardMoney extends AbstractReward {
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly quantity: number;

	constructor(hardMoneyModel: HardMoneyModel, quantity: number) {
		super(RewardTypes.RESOURCE, RewardResourceIdTypes.HARD_MONEY);

		this.hardMoneyModel = hardMoneyModel;
		this.quantity = quantity;
	}

	public collect(): void {
		this.hardMoneyModel.add(this.quantity);
	}

	public getQuantity(): number {
		return this.quantity;
	}

	public getQuantityString(): string {
		return SoftMoneyNumber.createFromNumber(this.quantity).toString();
	}
}
