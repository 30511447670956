import { LevelBackground, LevelBaseView, LevelBaseViewArgs } from '@views/levels/LevelBaseView';
import { EventLevelWeekdayView } from './EventLevelWeekDayView';
import { EventLevelWeekday2View } from './EventLevelWeekDay2View';
import { EventLevelWeekday3View } from './EventLevelWeekDay3View';
import { EventLevelWeekday4View } from './EventLevelWeekDay4View';
import { EventLevelWeekday5View } from './EventLevelWeekDay5View';
import { EventLevelWeekday6View } from './EventLevelWeekDay6View';
import { EventLevelWeekday7View } from './EventLevelWeekDay7View';
import { EventLevelWeekday8View } from './EventLevelWeekDay8View';
import { EventLevelWeekday9View } from './EventLevelWeekDay9View';
import { EventLevelWeekday10View } from './EventLevelWeekDay10View';
import { EventLevel1View } from './EventLevel1View';
import { EventLevel2View } from './EventLevel2View';
import { EventLevel3View } from './EventLevel3View';
import { EventLevel4View } from './EventLevel4View';
import { EventLevel6View } from './EventLevel6View';
import { EventLevel7View } from './EventLevel7View';
import { EventLevel8View } from './EventLevel8View';
import { EventLevel9View } from './EventLevel9View';
import { EventLevel10View } from './EventLevel10View';
import { EventLevel11View } from './EventLevel11View';
import { EventLevel12View } from './EventLevel12View';

export interface EventLevelViewConstructor {
	new(args: LevelBaseViewArgs): LevelBaseView;

	createBackground(background: string): LevelBackground;
}

export function GetEventLevelViewByKey(eventKey: string): EventLevelViewConstructor {
	switch (eventKey) {
		case '1':
			return EventLevel1View;

		case '2':
			return EventLevel2View;

		case '3':
			return EventLevel3View;

		case '4':
			return EventLevel4View;

		case '51':
			return EventLevelWeekdayView;

		case '52':
			return EventLevelWeekday2View;

		case '53':
			return EventLevelWeekday3View;

		case '54':
			return EventLevelWeekday4View;

		case '55':
			return EventLevelWeekday5View;

		case '251':
			return EventLevelWeekday6View;

		case '252':
			return EventLevelWeekday7View;

		case '253':
			return EventLevelWeekday8View;

		case '254':
			return EventLevelWeekday9View;

		case '255':
			return EventLevelWeekday10View;

		case '6':
			return EventLevel6View;

		case '7':
			return EventLevel7View;

		case '8':
			return EventLevel8View;

		case '9':
			return EventLevel9View;

		case '10':
			return EventLevel10View;

		case '11':
			return EventLevel11View;

		case '12':
			return EventLevel12View;

		default:
			throw new Error(`Unsupported event key: '${eventKey}'`);
	}
}
