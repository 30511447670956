import { DeviceUtils } from '@src/utils/DeviceUtils';
import { GameConstants } from '@src/utils/GameConstants';
import { AndroidFullScreenController } from './AnroidFullScreenController';

export class ResizeController {
	private static readonly NUTAKU_IFRAME_HEIGHT = 1000;

	constructor(
		private readonly isNutaku: boolean,
		private readonly canvas: HTMLCanvasElement,
		private readonly isAdsBannerVisible: boolean,
	) {
		if (this.isNutaku && !(DeviceUtils.isAndroid() || DeviceUtils.isIos())) {
			window.gadgets.window.adjustHeight(ResizeController.NUTAKU_IFRAME_HEIGHT);
		} else if (DeviceUtils.isAndroid()) {
			// eslint-disable-next-line no-new
			const ctrl = new AndroidFullScreenController();

			this.onResize();

			window.addEventListener('resize', () => {
				// Detect when soft keyboard is shown
				const isInputActive = document.activeElement.tagName.toLowerCase() === 'input';

				this.onResize(isInputActive && window.screen.availHeight < ctrl.getFullscreenAvailHeight());
			});
		} else {
			if (DeviceUtils.isIos()) {
				const $gameContainer = document.getElementById('game-container');

				// Fix 100vh bug on Safari
				$gameContainer.style.height = 'calc(var(--vh, 1vh) * 100)';
			}

			this.onResize();

			window.addEventListener('resize', () => {
				this.onResize();
			});
		}
	}

	private onResize(isSoftKeyboardVisible = false): void {
		// vh unit is 1% of viewport height
		const vh = window.innerHeight * 0.01;
		// setting the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		const bannerHeight = 100;
		const footerTextHeight = 18;
		const innerHeight = this.isAdsBannerVisible ? window.innerHeight - (bannerHeight + footerTextHeight) : window.innerHeight;

		const windowRatio = Number((window.innerWidth / innerHeight).toFixed(2));
		const gameRatio = Number((GameConstants.GAME_WIDTH / GameConstants.GAME_HEIGHT).toFixed(2));

		if (windowRatio <= gameRatio || isSoftKeyboardVisible) {
			this.canvas.style.width = '100%';
			this.canvas.style.height = 'initial';
		} else {
			this.canvas.style.width = 'initial';
			this.canvas.style.height = '100%';
		}
	}
}
