import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import * as TWEEN from '@tweenjs/tween.js';

export type BankHintViewData = {
	text: string;
	bodyArrowOffset: number;
}

export class BankHintView extends PIXI.Container {
	private readonly text: TextField;
	private readonly arrow: PIXI.Sprite;
	private readonly bg: PIXI.mesh.NineSlicePlane;

	constructor() {
		super();

		const shopAtlas = AssetsStorage.getAtlas('bankAtlas');

		this.bg = new PIXI.mesh.NineSlicePlane(shopAtlas['bank_info_hint'], 13, 9, 13, 17);
		this.bg.width = 200;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.text = new TextField(
			'',
			{ font: { size: 23, name: 'wendyOne' } },
			this.bg.width - 50,
		);
		this.text.anchor = new PIXI.Point(0.5, 0);
		this.text.position.set(this.bg.width / 2 - 12, this.bg.x + 10);

		this.arrow = new PIXI.Sprite(shopAtlas['bank_info_hint_tail']);
		this.arrow.position.set(0, -33);

		this.bg.addChild(
			this.text,
		);

		this.addChild(
			this.bg,
			this.arrow as PIXI.DisplayObject,
		);
	}

	public init(data: BankHintViewData): void {
		this.text.text = data.text;

		this.bg.height = this.text.height + 40;
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		this.setBgArrowOffset(data.bodyArrowOffset);
	}

	public startOpenAnimation(): void {
		const savedScale: number = this.scale.x;
		this.scale.set(1, 0.3);

		new TWEEN.Tween(this.scale)
			.to({ y: savedScale }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();

		this.y -= 40;
		new TWEEN.Tween(this)
			.to({ y: '+40' }, 230)
			.easing(TWEEN.Easing.Back.Out)
			.start();
	}

	/**
	 * 0 offsets the bg to the right, 1 to the left
	 * @param value - number from 0 to 1
	 */
	private setBgArrowOffset(value: number): void {
		this.bg.x = this.bg.width / 2 - value * this.bg.width;
	}
}
