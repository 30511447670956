import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter100010View } from '@views/characters/events/event10/EventCharacter100010View';
import { EventCharacter100011View } from '@views/characters/events/event10/EventCharacter100011View';
import { EventCharacter100012View } from '@views/characters/events/event10/EventCharacter100012View';

export class EventBusiness100004View extends BusinessBaseView {
	constructor() {
		const eventCharacter100010View = new EventCharacter100010View();
		const eventCharacter100011View = new EventCharacter100011View();
		const eventCharacter100012View = new EventCharacter100012View();

		super('business100004', [eventCharacter100010View, eventCharacter100011View, eventCharacter100012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b6_decor2']).setTransform(-2, 0, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(-229, -20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(229, -20, -0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b6_decor3']).setTransform(252, -37, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChild(
			eventCharacter100010View as PIXI.DisplayObject,
			eventCharacter100011View,
			eventCharacter100012View,
			...this.decorations,
		);
	}
}
