import { AbstractQuest } from '@models/quests/AbstractQuest';
import { BusinessModel } from '@models/BusinessModel';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { QuestTypes } from '@src/types/QuestTypes';

export class QuestGetCustomersAll extends AbstractQuest {
	private readonly businesses: BusinessModel[];
	private readonly targetAmount: number;

	private currentAmount: number;

	constructor(
		questKey: string,
		businesses: BusinessModel[],
		targetAmount: number,
		lineId: number,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			QuestTypes.GET_CUSTOMERS_ALL,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.businesses = businesses;

		this.targetAmount = targetAmount;

		const acquiredBusinesses = businesses.filter(business => business.isAcquired());
		if (acquiredBusinesses.length > 0) {
			this.currentAmount = acquiredBusinesses.map(business => business.getCustomerCount()).reduce((a, b) => a + b);
		} else {
			this.currentAmount = 0;
		}

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.getTargetAmount().toString();
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.getTargetAmount();
	}

	private onNewCustomers(model: BusinessModel, amount: number): void {
		this.currentAmount += amount;

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	private onSomeBusinessAcquired(): void {
		this.onNewCustomers(null, 1);
	}

	protected trackProgressStop(): void {
		this.businesses.forEach(business => business.off(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this));
		this.businesses.forEach(business => business.off(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this));
	}

	protected trackProgressStart(): void {
		this.businesses.forEach(business => business.on(BusinessModel.EVENT_NEW_CUSTOMERS, this.onNewCustomers, this));
		this.businesses.forEach(business => business.on(BusinessModel.EVENT_ACQUIRED, this.onSomeBusinessAcquired, this));
	}
}
