import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import { IconEntityView } from './IconEntityView';
import { SoundController } from '@src/main/SoundController';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { ModelHelper } from '@models/ModelHelper';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';

export class BusinessIconView extends PIXI.Container {
	public static readonly EVENT_CLICK: symbol = Symbol();

	private readonly iconLens: PIXI.Sprite;
	private readonly iconEntity: IconEntityView;

	private iconLensAnimationOver: TWEEN.Tween;
	private iconLensAnimationOut: TWEEN.Tween;

	private iconLensScale: number;
	private zoomAnimation: TWEEN.Tween;

	private isZoomedIn: boolean;

	constructor(
		private readonly characters: CharacterModel[],
		private readonly upgrades: UpgradeModel[],
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly prestigeMoneyModel: PrestigeMoneyModel,
	) {
		super();

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_business_zoom_btn']);

		this.iconLens = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['the_loopa']);
		this.iconLens.interactive = true;
		this.iconLens.on('pointerover', this.onIconLensOver, this);
		this.iconLens.on('pointerout', this.onIconLensOut, this);
		// Fix double tap when hint is shown
		this.iconLens.on('pointertap', (event: PIXI.interaction.InteractionEvent) => {
			event.stopPropagation();
			this.emit('pointertap');
		}, this);

		this.iconEntity = new IconEntityView();
		this.iconEntity.visible = false;
		// Fix double tap when hint is shown
		this.iconEntity.on('pointertap', (event: PIXI.interaction.InteractionEvent) => {
			event.stopPropagation();
			this.emit('pointertap');
		}, this);

		this.iconLensScale = this.iconLens.scale.x;

		this.interactive = true;
		this.on('pointertap', () => this.emit(BusinessIconView.EVENT_CLICK));
		this.on('pointerdown', (event: PIXI.interaction.InteractionEvent) => event.stopPropagation());

		this.upgrades.forEach((upgrade) => {
			upgrade.on(UpgradeModel.EVENT_UNLOCKED, this.tryUpdateIcon, this);
			upgrade.on(UpgradeModel.EVENT_ACTIVATED, this.tryUpdateIcon, this);
			upgrade.on(UpgradeModel.EVENT_CARDS_ADDED, this.tryUpdateIcon, this);
		});
		this.characters.forEach((character) => {
			character.on(CharacterModel.EVENT_OPENED, this.tryUpdateIcon, this);
			character.on(CharacterModel.EVENT_ACTIVATED, this.tryUpdateIcon, this);
			character.on(CharacterModel.EVENT_CARDS_ADDED, this.tryUpdateIcon, this);
		});
		this.softMoneyModel.on(SoftMoneyModel.EVENT_VALUE_CHANGED, this.tryUpdateIcon, this);
		this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.tryUpdateIcon, this);

		this.tryUpdateIcon();

		this.addChild(
			bg,
			this.iconLens,
			this.iconEntity as PIXI.Container,
		);
	}

	private onIconLensOver(): void {
		this.moveIconHoverAnimationToEnd();
		this.iconLensAnimationOver = new TWEEN.Tween(this.iconLens.scale)
			.to({ x: this.iconLensScale + 0.2, y: this.iconLensScale + 0.2 }, 100)
			.onComplete(() => {
				this.iconLensAnimationOver = null;
			})
			.start();

		SoundController.getInstance().playButtonPointerOver();
	}

	private onIconLensOut(): void {
		this.moveIconHoverAnimationToEnd();
		this.iconLensAnimationOut = new TWEEN.Tween(this.iconLens.scale)
			.to({ x: this.iconLensScale, y: this.iconLensScale }, 100)
			.onComplete(() => {
				this.iconLensAnimationOut = null;
			})
			.start();
	}

	private moveIconHoverAnimationToEnd(): void {
		if (this.iconLensAnimationOver) {
			this.iconLensAnimationOver.stop();
		}

		if (this.iconLensAnimationOut) {
			this.iconLensAnimationOut.stop();
		}
	}

	private tryUpdateIcon(): void {
		let model: CharacterModel | UpgradeModel;
		let isAnimated = false;
		let canActivateUpgrade = false;
		let canActivateCharacter = false;

		for (let i = this.upgrades.length - 1; i >= 0; i--) {
			const upgrade = this.upgrades[i];

			if (upgrade.isOpened()) {
				if (!upgrade.isActivated()
					&& ModelHelper.isEnoughSoftMoneyForActivate(upgrade, this.softMoneyModel)
					&& upgrade.isCustomersReached()) {
					model = upgrade;
					isAnimated = true;
					canActivateUpgrade = true;
				} else if (upgrade.isEnoughCardsForPromote()
					&& this.prestigeMoneyModel.getValue() >= upgrade.getPromoteCostPrestige()
					&& !canActivateUpgrade) {
					model = upgrade;
					isAnimated = upgrade.isActivated();
				}
			}
		}

		for (let i = this.characters.length - 1; i >= 0; i--) {
			const character = this.characters[i];

			if (character.isOpened()) {
				if (!character.isActivated()
					&& ModelHelper.isEnoughSoftMoneyForActivate(character, this.softMoneyModel)
					&& character.isActivateLevelReached()) {
					model = character;
					isAnimated = true;
					canActivateCharacter = true;
				} else if (character.isEnoughCardsForPromote()
					&& this.prestigeMoneyModel.getValue() >= character.getPromoteCostPrestige()
					&& !canActivateCharacter
					&& !canActivateUpgrade) {
					model = character;
					isAnimated = character.isActivated();
				}
			}
		}

		if (model && !this.isZoomedIn) {
			if (model instanceof CharacterModel) {
				this.iconEntity.setCharacterIcon(model.getKey());
				this.iconEntity.setCharacterBg(model.getCardRarity());
			} else {
				this.iconEntity.setUpgradeIcon(model.getKey());
				this.iconEntity.setUpgradeBg(model.getCardRarity());
			}

			this.iconEntity.show(isAnimated);
		} else {
			this.iconEntity.hide();
		}
	}

	public onZoomIn(): void {
		this.isZoomedIn = true;
		this.visible = false;
	}

	public onZoomOut(): void {
		this.visible = true;
		this.isZoomedIn = false;
		this.tryUpdateIcon();
		this.iconLens.texture = AssetsStorage.getAtlas('uiAtlas')['the_loopa'];
		this.scale.set(1);
		this.y = 133;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.upgrades.forEach((upgrade) => {
			upgrade.off(UpgradeModel.EVENT_UNLOCKED, this.tryUpdateIcon, this);
			upgrade.off(UpgradeModel.EVENT_ACTIVATED, this.tryUpdateIcon, this);
			upgrade.off(UpgradeModel.EVENT_CARDS_ADDED, this.tryUpdateIcon, this);
		});
		this.characters.forEach((character) => {
			character.off(CharacterModel.EVENT_OPENED, this.tryUpdateIcon, this);
			character.off(CharacterModel.EVENT_ACTIVATED, this.tryUpdateIcon, this);
			character.off(CharacterModel.EVENT_CARDS_ADDED, this.tryUpdateIcon, this);
		});
		this.softMoneyModel.off(SoftMoneyModel.EVENT_VALUE_CHANGED, this.tryUpdateIcon, this);
		this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.tryUpdateIcon, this);

		if (this.zoomAnimation) {
			this.zoomAnimation.stop();
		}

		super.destroy(options);
	}
}
