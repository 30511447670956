import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class ButtonSettingsView extends ButtonBaseView {
	private readonly infoIcon: PIXI.Sprite;

	constructor() {
		const buttonSettingsBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['setting_button']);
		super(buttonSettingsBg);

		this.infoIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['can_promote_icon']);
		this.infoIcon.setTransform(25, -18, 0.5, 0.5);
		this.infoIcon.visible = false;

		this.addChild(
			this.infoIcon,
		);
	}

	public setInfoIconVisible(value: boolean): void {
		this.infoIcon.visible = value;
	}
}
