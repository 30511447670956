import { BankBundleConfig } from '@configs/bank/BankBundleConfig';
import { UnlockValueChecker } from '@src/loaders/UnlockValueChecker';
import { BankElementIdPrefix } from '@src/viewControllers/BankWindowViewController';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';
import { BankBundleGroupConfig } from '@configs/bank/BankBundleGroupConfig';

export class BankBundleConfigsFilter {
	constructor(
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly bankSavesModel: BankSavesModel,
	) { }

	public filter(
		source: Map<string, BankBundleConfig>,
		offers: Map<string, BankOfferConfig>,
		bundleGroups: Map<string, BankBundleGroupConfig>,
		currentTime: number,
	): Map<string, BankBundleConfig> {
		const filteredMap: Map<string, BankBundleConfig> = new Map();
		const missedBundleGroup: Map<string, boolean> = new Map();

		source.forEach((config: BankBundleConfig) => {
			let result = this.checkBundleGroup(config, missedBundleGroup, bundleGroups);
			if (config.hasOfferKey()) {
				result &&= this.checkOffer(config, filteredMap, offers);
			} else {
				result &&= this.checkIfNoOffer(config, filteredMap, currentTime);
			}

			if (result) {
				filteredMap.set(config.getKey(), config);
				missedBundleGroup.delete(config.getBundleGroupKey());
			}
		});
		filteredMap.forEach((x) => {
			if (missedBundleGroup.has(x.getBundleGroupKey())) {
				filteredMap.delete(x.getKey());
			}
		});
		return filteredMap;
	}

	private checkUnlockTypes(config: BankBundleConfig): boolean {
		let result: boolean = true;
		if (config.hasSomeUnlockTypeValue()) {
			const unlockTypes = config.getUnlockTypes();
			const unlockValues = config.getUnlockValues();
			for (let i = 0; i < unlockTypes.length && result; i++) {
				const unlockType = unlockTypes[i];
				const unlockValue = unlockValues[i];
				result = this.unlockValueChecker.check(unlockType, unlockValue);
			}
		}
		return result;
	}

	// eslint-disable-next-line class-methods-use-this
	private checkBundleGroup(
		config: BankBundleConfig,
		missedBundleGroups: Map<string, boolean>,
		bundleGroups: Map<string, BankBundleGroupConfig>,
	): boolean {
		const result = !config.hasBundleGroupKey()
			|| bundleGroups.has(config.getBundleGroupKey())
			&& !missedBundleGroups.has(config.getBundleGroupKey());
		if (result && config.hasBundleGroupKey()) {
			missedBundleGroups.set(config.getBundleGroupKey(), true);
		}
		return result;
	}

	private checkOffer(
		config: BankBundleConfig,
		filteredMap: Map<string, BankBundleConfig>,
		offers: Map<string, BankOfferConfig>,
	): boolean {
		let result = offers.has(config.getOfferKey());
		const otherItem: BankBundleConfig | undefined = filteredMap.get(config.getKey());
		result &&= !otherItem?.hasOfferKey();
		result &&= this.checkBuyTimes(config);
		result &&= this.checkUnlockTypes(config);
		return result;
	}

	private checkIfNoOffer(
		config: BankBundleConfig,
		filteredMap: Map<string, BankBundleConfig>,
		currentTime: number,
	): boolean {
		const result: boolean = !filteredMap.has(config.getKey())
			&& this.checkUnlockTypes(config) && this.checkAcceptedBuyTimes(config, currentTime);
		return result;
	}

	private checkBuyTimes(config: BankBundleConfig): boolean {
		const lotStringId: string = BankElementIdPrefix.BUNDLES + config.getKey();
		return this.bankSavesModel.getPurchaseCount(lotStringId) < config.getTotalBuyTimes();
	}

	private checkAcceptedBuyTimes(config: BankBundleConfig, currentTime: number): boolean {
		const lotStringId: string = config.getKey();
		const buyTimes: number = config.getTotalBuyTimes();
		const accepted: number | undefined = this.bankSavesModel.getBundleAcceptedBuyTimes(lotStringId, currentTime);

		return buyTimes === 0 || accepted <= buyTimes;
	}
}
