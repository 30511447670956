import { LootboxModel } from '@models/lootboxes/LootboxModel';
import { EpicQuestCollectionsTypes } from '@src/types/EpicQuestCollectionsTypes';
import { CardRarity } from '@src/types/CardRarities';

export class EpicQuestCollectionsModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_REWARD_REACHED: symbol = Symbol();
	public static readonly EVENT_REWARD_COLLECTED: symbol = Symbol();
	public static readonly EVENT_PROGRESS: symbol = Symbol();

	private readonly targetProgresses: number[];
	private readonly type: EpicQuestCollectionsTypes;
	private readonly targetProgressQuestKeyMap: Map<number, string>;
	private readonly questKeyRarityMap: Map<string, CardRarity>;

	private collectedRewardProgresses: Set<number>;

	private currentProgress: number;

	private nextProgressId: number;

	constructor(
		type: EpicQuestCollectionsTypes,
		cardsCountRewardKeyMap: Map<number, string>,
		questKeyRarityMap: Map<string, CardRarity>,
		currentProgress: number,
	) {
		super();

		this.type = type;
		this.currentProgress = currentProgress;
		this.targetProgressQuestKeyMap = cardsCountRewardKeyMap;
		this.questKeyRarityMap = questKeyRarityMap;

		this.collectedRewardProgresses = new Set();

		this.targetProgresses = Array.from(cardsCountRewardKeyMap.keys()).sort((a, b) => ((a > b) ? a : b));

		this.nextProgressId = this.targetProgresses.findIndex(targetProgress => currentProgress < targetProgress);
	}

	public setFromSaveData(saveData: number[]): void {
		saveData.forEach((targetProgresses) => {
			this.collectedRewardProgresses.add(targetProgresses);
		});
	}

	public onSomeRewardTargetCollected(
		lootbox: LootboxModel,
		targetProgress: number,
	): void {
		this.collectedRewardProgresses.add(targetProgress);

		this.emit(
			EpicQuestCollectionsModel.EVENT_REWARD_COLLECTED,
			lootbox,
			targetProgress,
			this,
		);
	}

	public updateProgress(): void {
		this.currentProgress += 1;

		if (this.nextProgressId >= 0 && this.nextProgressId < this.targetProgresses.length) {
			const nextQuestTargetProgress = this.targetProgresses[this.nextProgressId];
			if (this.currentProgress >= nextQuestTargetProgress) {
				this.nextProgressId += 1;
				this.emit(EpicQuestCollectionsModel.EVENT_REWARD_REACHED, nextQuestTargetProgress, this);
			}
		}

		this.emit(EpicQuestCollectionsModel.EVENT_PROGRESS);
	}

	public hasNextTargetProgress(): boolean {
		return this.nextProgressId >= 0 && this.nextProgressId < this.targetProgresses.length;
	}

	public getCurrentProgress(): number {
		return this.currentProgress;
	}

	public getCurrentTargetProgress(): number {
		const resultId = this.nextProgressId - 1;
		return resultId >= 0 ? this.targetProgresses[resultId] : 0;
	}

	public isRewardCollected(targetProgress: number): boolean {
		return this.collectedRewardProgresses.has(targetProgress);
	}

	public hasAnyCollectableRewards(): boolean {
		const completedTargetProgresses = this.targetProgresses.filter(progress => progress <= this.currentProgress);
		return completedTargetProgresses.some(completedTargetProgress => !this.isRewardCollected(completedTargetProgress));
	}

	public getNextTargetProgress(): number {
		return this.targetProgresses[this.nextProgressId];
	}

	public getTotalTargetProgress(): number {
		return this.targetProgresses[this.targetProgresses.length - 1];
	}

	public getTargetProgresses(): number[] {
		return this.targetProgresses;
	}

	public getQuestKey(targetProgress: number): string {
		return this.targetProgressQuestKeyMap.get(targetProgress);
	}

	public getRewardRarity(targetProgress: number): CardRarity {
		const key = this.targetProgressQuestKeyMap.get(targetProgress);
		return this.questKeyRarityMap.get(key);
	}

	public getType(): EpicQuestCollectionsTypes {
		return this.type;
	}
}
