import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter52012View extends CharacterMainBaseView {
	constructor() {
		super('character52012');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-168, 50);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(0, -27),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(50, -30),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
