import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter51002View extends CharacterMainBaseView {
	constructor() {
		super('character51002');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(178, 15);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(144, -55),
			arrowRotation: Math.PI,
			bgPosition: new PIXI.Point(138, -40),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
