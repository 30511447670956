export class BaseAction {
	public static readonly EVENT_ACTION_CREATED: symbol = Symbol();

	protected action: string;
	protected data: { [key: string]: any };
	protected info: { [key: string]: any };
	protected sync: { [key: string]: any };

	// eslint-disable-next-line @typescript-eslint/naming-convention
	private event_id: string;

	constructor(action: string) {
		this.action = action;
		this.data = Object.create(null);
		this.info = Object.create(null);
		this.sync = Object.create(null);
	}

	public setTimestamp(timestamp: number): void {
		this.data.timestamp = timestamp;
	}

	public setEventLevelId(value: string): void {
		this.event_id = value;
	}
}
