import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70005View extends CharacterMainBaseView {
	constructor() {
		super('character70005');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(228, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(144, -55),
			arrowRotation: Math.PI,
			bgPosition: new PIXI.Point(138, -40),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
