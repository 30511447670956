import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter251014View extends CharacterMainBaseView {
	constructor() {
		super('character251014');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-80, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-55, -57),
			bgPosition: new PIXI.Point(10, -61),
			arrowRotation: -Math.PI / 2,
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
