import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter20018View extends CharacterMainBaseView {
	constructor() {
		super('character20018');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-32, 21);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-210, 8),
			arrowRotation: Math.PI,
			bgPosition: new PIXI.Point(-216, 40),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
