import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter90004View } from '@views/characters/events/event9/EventCharacter90004View';
import { EventCharacter90005View } from '@views/characters/events/event9/EventCharacter90005View';
import { EventCharacter90006View } from '@views/characters/events/event9/EventCharacter90006View';

export class EventBusiness90002View extends BusinessBaseView {
	constructor() {
		const eventCharacter90004View = new EventCharacter90004View();
		const eventCharacter90005View = new EventCharacter90005View();
		const eventCharacter90006View = new EventCharacter90006View();

		super('business90002', [eventCharacter90004View, eventCharacter90005View, eventCharacter90006View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b10_decor1']).setTransform(3.5, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor5']).setTransform(6, -6.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor2']).setTransform(-270.5, -12, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b10_decor4']).setTransform(-198, -15, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b10_decor3']).setTransform(180, 50, 0.5, 0.5),
			...this.decorations,
			eventCharacter90004View as PIXI.DisplayObject,
			eventCharacter90005View,
			eventCharacter90006View,
			new PIXI.Sprite(decorationAtlas['b10_decor6']).setTransform(7, -5.5, 0.5, 0.5),
		);
	}
}
