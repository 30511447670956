import { CharacterModel } from '@models/CharacterModel';
import { AbstractRewardCards } from '@interfaces/AbstractRewardCards';
import { SkillModel } from '@models/skills/SkillModel';
import { RewardTypes } from '@src/types/RewardTypes';

export class RewardCharacterCards extends AbstractRewardCards<CharacterModel> {
	private skillModel: SkillModel;

	private relatedBusinessKey: string;

	constructor(
		character: CharacterModel,
		quantity: number,
		skill?: SkillModel,
	) {
		super(character, RewardTypes.CHARACTER, quantity);

		this.relatedBusinessKey = character.getRelatedBusinessKey();
		this.skillModel = skill;
	}

	public collect(): void {
		if (!this.model.isOpened()) {
			this.model.open();
		}

		this.model.addCards(this.quantity);
	}

	public getCharacterSkill(): SkillModel {
		return this.skillModel;
	}

	public getRelatedBusinessKey(): string {
		return this.relatedBusinessKey;
	}
}
