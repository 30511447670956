import { BaseAction } from '../BaseAction';
import { RewardDescriptionType } from '@src/types/RewardTypes';

export class ApplyPurchaseAction extends BaseAction {
	constructor(nonCommittedTransactionId: number, rewards: RewardDescriptionType[]) {
		super('ApplyPurchase');

		this.data.nctxnid = nonCommittedTransactionId;
		this.data.rewards = rewards;
	}
}
