import { TutorialStepDataBase } from './TutorialStepDataBase';
import { TutorialStepBaseConfig } from '../TutorialStepBaseConfig';

export class TutorialStepButtonQuestData extends TutorialStepDataBase {
	private readonly questLineId: number;

	constructor(config: { [key: string]: string }) {
		super(config);

		const param: string[] = config[TutorialStepBaseConfig.FIELD_PARAMETER].split(':').map((str: string) => str.trim());
		this.questLineId = Number(param[1]) - 1;
	}

	public getQuestLineId(): number {
		return this.questLineId;
	}
}
