import { SkillModel } from '@models/skills/SkillModel';
import { SkillGirlView } from '@views/ui/skillsPanel/SkillGirlView';
import { Emitter } from 'pixi-particles';
import { SkillParticleConfig } from '@views/ui/skillsPanel/SkillParticleConfig';
import { AssetsStorage } from '@main/AssetsStorage';
import { GameConstants } from '@src/utils/GameConstants';
import { SkillTypes } from '@src/types/SkillTypes';
import { SkillConstantProfitModel } from '@models/skills/SkillConstantProfitModel';

export class GameUISkillGirlsView extends PIXI.Container {
	private skillGirlsQueue: SkillGirlView[];
	private readonly ticker: PIXI.ticker.Ticker;
	private readonly skillModels: Map<string, SkillModel>;
	private animationEmitters: Emitter[];
	private girlsContainer: PIXI.Container;
	private animationsContainer: PIXI.Container;

	private animationPlaying: boolean;

	constructor(skillModels: Map<string, SkillModel>) {
		super();

		this.animationPlaying = false;
		this.skillGirlsQueue = [];
		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);
		this.skillModels = skillModels;
		this.animationEmitters = [];

		this.girlsContainer = new PIXI.Container();
		this.animationsContainer = new PIXI.Container();
		this.animationsContainer.position.set(0, GameConstants.GAME_HEIGHT);
		this.addChild(
			this.girlsContainer,
			this.animationsContainer,
		);

		Array.from(this.skillModels.values()).forEach((model) => {
			model.on(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this);
		});
	}

	private update(): void {
		if (this.skillGirlsQueue.length > 0 && !this.animationPlaying) {
			const skillGirl = this.skillGirlsQueue.shift();
			this.girlsContainer.addChild(skillGirl);
			skillGirl.on(SkillGirlView.EVENT_ANIMATION_END, () => {
				this.girlsContainer.removeChild(skillGirl);
				this.animationPlaying = false;
			});
			skillGirl.play();
			this.animationPlaying = true;

			const textSkillEmitter = new Emitter(
				this.animationsContainer,
				[AssetsStorage.getAtlas('fxAtlas')['skill_character_star']],
				SkillParticleConfig.getTextSKill(),
			);
			this.animationEmitters.push(textSkillEmitter);
			textSkillEmitter.playOnceAndDestroy();
		}
	}

	private onSkillActivated(skillModel: SkillModel): void {
		let skillMultiplier = null;
		switch (skillModel.getType()) {
			case SkillTypes.CONSTANT_PROFIT:
				skillMultiplier = Math.round((skillModel as SkillConstantProfitModel).getAdditionalPart() * 100);
				break;
			case SkillTypes.TAP_ADD_MONEY:
			case SkillTypes.REDUCE_RELOAD:
				skillMultiplier = Math.round(skillModel.getMultiplier() * 100);
				break;
			default:
				skillMultiplier = skillModel.getMultiplier();
		}

		this.skillGirlsQueue.push(
			new SkillGirlView(skillModel.getGirlPrefabName(), skillModel.getSkillDescriptionLocale(), skillMultiplier),
		);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		Array.from(this.skillModels.values()).forEach((model) => {
			model.off(SkillModel.EVENT_SKILL_ACTIVATED, this.onSkillActivated, this);
		});
		super.destroy(options);
	}
}
