import { BankModel } from '@models/bank/BankModel';
import { BankTabElementDataConfigNameTypes } from '@src/types/BankTypes';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { BankSubscribeModel } from '@models/bank/BankSubscribeModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';

export class BankTicker extends PIXI.utils.EventEmitter {
	private readonly ticker: PIXI.ticker.Ticker;

	private readonly bankModel: BankModel;
	private readonly serverTimeModel: ServerTimeModel;

	private bundles: BankBundleModel[];
	private subscribesClaim: BankSubscribeModel[];
	private subscribesEnd: BankSubscribeModel[];
	private offers: BankOfferModel[];

	constructor(
		bankModel: BankModel,
		serverTime: ServerTimeModel,
	) {
		super();

		this.ticker = PIXI.ticker.shared;

		this.bankModel = bankModel;
		this.bankModel.on(BankModel.EVENT_UPDATED, this.onBankModelUpdated, this);

		this.serverTimeModel = serverTime;

		this.bundles = [];
		this.subscribesClaim = [];
		this.subscribesEnd = [];
		this.offers = [];
	}

	public setEnabled(value: boolean): void {
		if (value) {
			this.ticker.add(this.onTimerUpdate, this);
		} else {
			this.ticker.remove(this.onTimerUpdate, this);
		}
	}

	private onBankModelUpdated(): void {
		// Bundles
		this.bundles = [];
		const bankElements = this.bankModel.getTabElementConfigNameDataMap();
		const currentTime = this.serverTimeModel.getCalculatedISOTime();
		bankElements.get(BankTabElementDataConfigNameTypes.BUNDLES).forEach((model) => {
			const bundleModel = model as BankBundleModel;
			if (bundleModel.getAvailableOn() > currentTime) {
				this.bundles.push(bundleModel);
			}
		});

		// Subscribes
		this.subscribesClaim = [];
		this.subscribesEnd = [];
		bankElements.get(BankTabElementDataConfigNameTypes.SUBSCRIBE).forEach((model) => {
			const subscribeModel = model as BankSubscribeModel;
			if (subscribeModel.getIsActive()) {
				this.subscribesEnd.push(subscribeModel);
				if (subscribeModel.getNextClaimIn() > 0) {
					this.subscribesClaim.push(subscribeModel);
				}
			}
		});

		// Offers
		this.offers = [];
		bankElements.get(BankTabElementDataConfigNameTypes.OFFERS).forEach((model) => {
			const offerModel = model as BankOfferModel;
			this.offers.push(offerModel);
		});
	}

	private onTimerUpdate(): void {
		const currentTime = this.serverTimeModel.getCalculatedISOTime();

		// Bundles
		this.bundles.forEach((bundleModel, index) => {
			if (bundleModel.getAvailableOn() > currentTime) {
				bundleModel.setTimeLeft(bundleModel.getAvailableOn() - currentTime);
			} else {
				bundleModel.timeout();
				delete this.bundles[index];
			}
		});

		// Subscribes
		this.subscribesClaim.forEach((subscribeModel, index) => {
			if (subscribeModel.getClaimableOn() > currentTime) {
				subscribeModel.setNextClaimIn(subscribeModel.getClaimableOn() - currentTime);
			} else {
				subscribeModel.timeout();
				delete this.subscribesClaim[index];
			}
		});
		this.subscribesEnd.forEach((subscribeModel, index) => {
			if (subscribeModel.getTimeLeft() > 0) {
				subscribeModel.setTimeLeft(subscribeModel.getExpireOn() - currentTime);
			} else {
				subscribeModel.timeout();
				delete this.subscribesClaim[index];
				delete this.subscribesEnd[index];
			}
		});

		// Offers
		this.offers.forEach((offerModel, index) => {
			if (offerModel.getTimeleft() > 0) {
				offerModel.updateTimeleft(currentTime);
			} else {
				offerModel.timeout();
				delete this.offers[index];
			}
		});
	}
}
