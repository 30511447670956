import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AnalyticsPlatform, AnalyticsTypes } from '@src/types/AnalyticsTypes';
import { AnalyticSourceLevelPreloaderView } from '../sources/AnalyticSourceLevelPreloaderView';
import {
	AnalyticSourceBankViews,
	EventBankOfferWindowShowEventArgs,
	EventBankGoToBankFromOfferEventArgs,
	EventBankOfferLinesUIClickEventArgs,
} from '../sources/AnalyticSourceBankViews';
import { BankOfferWindowOriginType, BankOfferWindowOriginTypes } from '@src/types/BankTypes';
import {
	EventBankOfferBuyFromBankWindowEventArgs,
	EventBankOfferBuyFromOfferWindowEventArgs,
	EventBankOfferBuyFromBankAfterOfferEventArgs,
	AnalyticSourceModels,
	EventEventLevelGoalProgressEventArgs,
} from '../sources/AnalyticSourceModels';
import { AnalyticSourceFarewellPartyWindowView, EventFarewellPartyProgressEventArgs } from '../sources/AnalyticSourceFarewellPartyWindowView';
import { DeviceUtils } from '@src/utils/DeviceUtils';

enum OfferWindowShowAction {
	AUTO_PUSH = 'autopush',
	CLICK_PUSH = 'clickpush',
}

export class AnalyticTargetClickhouse {
	private static readonly CLICKHOUSE_SERVER_URL_PROD: string = 'https://analytic-client.cuntempire.com/';
	private static readonly CLICKHOUSE_SERVER_URL_DEV: string = 'https://dev.analytic-client.playful-fairies.com:5000/';

	private static convertOfferWindowOriginToAction(origin: BankOfferWindowOriginType): string {
		switch (origin) {
			case BankOfferWindowOriginTypes.BANK_OFFER_LINES_UI:
				return OfferWindowShowAction.CLICK_PUSH;

			case BankOfferWindowOriginTypes.BANK_OFFER_WINDOWS_EMITTER:
				return OfferWindowShowAction.AUTO_PUSH;

			default:
				return origin;
		}
	}

	public static readonly CLICKHOUSE_SERVER_URL: string = MODE_DEBUG
		? AnalyticTargetClickhouse.CLICKHOUSE_SERVER_URL_DEV
		: AnalyticTargetClickhouse.CLICKHOUSE_SERVER_URL_PROD;

	private trackingEventProgress: boolean;

	private clickhouseSessionId: string;
	private clickHouseRankProgressTimer: number;

	private startButtonClickTimestamp: string;

	private postponedOfferViewStats: [string, string][];

	private sourceLevelModels: AnalyticSourceModels;
	private sourceEventLevelModels: AnalyticSourceModels;

	constructor(
		private readonly serverTimeModel: ServerTimeModel,
		private sessionId: string,
		private playerId: string,
		private deviceId: string,
		private platform: AnalyticsPlatform,
		private readonly clickHouseSessionPingInterval: number,
		private readonly clickHouseEventRankProgressInterval: number,
		private readonly version: string,
	) {
		this.postponedOfferViewStats = [];
		this.clickhouseSessionId = '';
		this.startButtonClickTimestamp = '';
	}

	public setGameProfileData(
		sessionId: string,
		playerId: string,
		deviceId: string,
		platform: AnalyticsPlatform,
	): void {
		this.sessionId = sessionId;
		this.playerId = playerId;
		this.deviceId = deviceId;
		this.platform = platform;
	}

	public addSourceLevelPreloaderView(source: AnalyticSourceLevelPreloaderView): void {
		source.once(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_COMPLETED, () => {
			this.startButtonClickTimestamp = this.serverTimeModel
				.getCalculatedISOTime()
				.toFixed()
				.toString();

			this.eventClickhouseSessionPing();
			this.eventClickhouse('actions/clicker/start_button_click', {
				statistics: [{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					user_agent: DeviceUtils.getUserAgent(),
					timestamp: this.startButtonClickTimestamp,
				}],
			});
		}, this);

		source.on(AnalyticSourceLevelPreloaderView.EVENT_PRELOADER_PROGRESS, (progress: number) => {
			this.eventClickhouse('actions/clicker/load_progress', {
				statistics: [{
					timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
					progress,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					user_agent: DeviceUtils.getUserAgent(),
				}],
			});
		}, this);
	}

	public addSourceFarewellPartyView(source: AnalyticSourceFarewellPartyWindowView): void {
		source.on(AnalyticSourceFarewellPartyWindowView.EVENT_FAREWELL_PARTY_PROGRESS, (args: EventFarewellPartyProgressEventArgs) => {
			this.eventClickhouseFarewellParty(
				args.startedOn,
				args.fuckpower,
				args.fucktime,
				args.rewardBonusTime,
				args.rewardBonusMultiplier,
				args.barsCompleted,
				args.totems,
			);
		});
	}

	public addSourceBankViews(source: AnalyticSourceBankViews): void {
		source.on(AnalyticSourceBankViews.EVENT_BANK_OFFER_WINDOW_SHOW, (args: EventBankOfferWindowShowEventArgs) => {
			this.eventClickhouseBankOfferUI(
				AnalyticsTypes.BANK_SHOW_OFFER_WINDOW,
				args.offerKey,
			);
			this.eventClickhouseOfferViewStatistics(
				args.offerKey,
				AnalyticTargetClickhouse.convertOfferWindowOriginToAction(args.origin),
			);
		}, this);
		source.on(AnalyticSourceBankViews.EVENT_BANK_GO_TO_BANK_FROM_OFFER, (args: EventBankGoToBankFromOfferEventArgs) => {
			this.eventClickhouseBankOfferUI(
				AnalyticsTypes.BANK_GO_TO_BANK_WINDOW_FROM_BANK_OFFER_WINDOW,
				args.offerKey,
			);
		}, this);
		source.on(AnalyticSourceBankViews.EVENT_BANK_OFFER_LINES_UI_CLICK, (args: EventBankOfferLinesUIClickEventArgs) => {
			this.eventClickhouseBankOfferUI(
				AnalyticsTypes.BANK_OFFER_LINES_UI_CLICK,
				args.offerKey,
			);
		}, this);
	}

	public addSourceEventLevelModels(source: AnalyticSourceModels): void {
		this.sourceEventLevelModels = source
			.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_WINDOW, (args: EventBankOfferBuyFromBankWindowEventArgs) => {
				this.eventClickhouseEventBankOfferPurchase(
					args.key,
					args.transactionId,
					args.source,
				);
			}, this);
		source.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_OFFER_WINDOW, (args: EventBankOfferBuyFromOfferWindowEventArgs) => {
			this.eventClickhouseEventBankOfferPurchase(
				args.key,
				args.transactionId,
				args.source,
			);
		}, this);
		source.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_AFTER_OFFER, (args: EventBankOfferBuyFromBankAfterOfferEventArgs) => {
			this.eventClickhouseEventBankOfferPurchase(
				args.key,
				args.transactionId,
				args.source,
			);
		}, this);

		const handleEventGoalProgress = (args: EventEventLevelGoalProgressEventArgs): void => {
			this.eventClickhouse('actions/clicker/event_goals', {
				statistics: [{
					timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
					// eslint-disable-next-line @typescript-eslint/naming-convention
					event_id: args.eventLevelKey,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					current_goal: args.completedGoalCount + 1,
				}],
			});
			if (args.goalProgressCompleted) {
				this.eventClickhouse('actions/clicker/event_info', {
					statistics: [{
						timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
						// eslint-disable-next-line @typescript-eslint/naming-convention
						event_id: args.eventLevelKey,
						action: 'complete',
					}],
				});
			}
		};
		source.on(AnalyticSourceModels.EVENT_EVENT_LEVEL_GOAL_PROGRESS_NEW_GOAL, handleEventGoalProgress, this);
		source.on(AnalyticSourceModels.EVENT_EVENT_LEVEL_GOAL_PROGRESS_COMPLETED, handleEventGoalProgress, this);
	}

	public addSourceLevelModels(source: AnalyticSourceModels): void {
		this.sourceLevelModels = source.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_WINDOW, (args: EventBankOfferBuyFromBankWindowEventArgs) => {
			this.eventClickhouseBankOfferPurchase(
				args.key,
				args.transactionId,
				args.source,
			);
		}, this);
		source.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_OFFER_WINDOW, (args: EventBankOfferBuyFromOfferWindowEventArgs) => {
			this.eventClickhouseBankOfferPurchase(
				args.key,
				args.transactionId,
				args.source,
			);
		}, this);
		source.on(AnalyticSourceModels.EVENT_BANK_OFFER_BUY_FROM_BANK_AFTER_OFFER, (args: EventBankOfferBuyFromBankAfterOfferEventArgs) => {
			this.eventClickhouseBankOfferPurchase(
				args.key,
				args.transactionId,
				args.source,
			);
		}, this);
	}

	public sendEventClickhouseEventFirstEnter(): void {
		const data = this.sourceEventLevelModels.getDataEventLevelModel();
		this.eventClickhouse('actions/clicker/event_info', {
			statistics: [{
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				event_id: data.key,
				action: 'start',
			}],
		});
	}

	public startTrackEventRankProgress(): void {
		this.trackingEventProgress = true;
		this.eventClickhouseEventRankProgress();
	}

	public stopTrackEventRankProgress(): void {
		this.trackingEventProgress = false;
		window.clearTimeout(this.clickHouseRankProgressTimer);
		this.clickHouseRankProgressTimer = null;
	}

	private startEventRankProgressTicker(): void {
		this.clickHouseRankProgressTimer = window.setTimeout(
			this.eventClickhouseEventRankProgress.bind(this),
			this.clickHouseEventRankProgressInterval * 1000,
		);
	}

	private async eventClickhouseSessionPing(): Promise<void> {
		const response = this.eventClickhouse('profiles/clicker/session_ping', {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			is_force_session: false,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			start_datetime: this.startButtonClickTimestamp,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			session_id: this.clickhouseSessionId,
		});
		response
			.then(success => {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				this.setClickhouseSessionId(success?.session_id || '');
			})
			.finally(() => {
				this.startClickhouseSessionPingTicker();
			});
	}

	private setClickhouseSessionId(value: string): void {
		if (value !== this.clickhouseSessionId) {
			this.clickhouseSessionId = value;
			this.sendPostponedOfferViews();
		}
	}

	private sendPostponedOfferViews(): void {
		while (this.postponedOfferViewStats.length > 0) {
			const [offerKey, action] = this.postponedOfferViewStats.pop();
			this.eventClickhouseOfferViewStatistics(offerKey, action);
		}
	}

	private startClickhouseSessionPingTicker(): void {
		window.setTimeout(
			this.eventClickhouseSessionPing.bind(this),
			this.clickHouseSessionPingInterval * 1000,
		);
	}

	private eventClickhouseEventRankProgress(): void {
		const data = this.sourceEventLevelModels.getDataEventLevelModel();
		const response = this.eventClickhouse('actions/clicker/event_dynamics', {
			statistics: [{
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				active_event_id: data.key,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				cur_place: data.playerPlace,
				params: JSON.stringify({
					income: data.incomePerSec.toString(),
					progress: data.currentGoalProgress.toString(),
				}),
			}],
		});
		response
			.finally(() => {
				this.startEventRankProgressTicker();
			});
	}

	private eventClickhouseOfferViewStatistics(offerKey: string, action: string): void {
		if (this.clickhouseSessionId === '') {
			this.postponedOfferViewStats.push([offerKey, action]);
		} else {
			this.eventClickhouse('/actions/clicker/offers_view_statistics', {
				statistics: [{
					timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
					// eslint-disable-next-line @typescript-eslint/naming-convention
					offer_id: offerKey,
					level: this.sourceLevelModels.getCurrentLevel(),
					// eslint-disable-next-line @typescript-eslint/naming-convention
					session_id: this.clickhouseSessionId,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					active_event_id: this.trackingEventProgress ? this.sourceEventLevelModels?.getDataEventLevelModel().key : '',
					action,
				}],
			});
		}
	}

	private eventClickhouseEventBankOfferPurchase(bankOfferKey: string, transactionId: string, source: string): void {
		const data = this.sourceEventLevelModels.getDataEventLevelModel();
		this.eventClickhouse('actions/clicker/event_offers', {
			statistics: [{
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				offer_id: bankOfferKey,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				transaction_id: transactionId,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				event_id: data.key,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				current_goal: data.completedGoalCount + 1,
				source,
			}],
		});
	}

	private eventClickhouseBankOfferPurchase(bankOfferKey: string, transactionId: string, source: string): void {
		this.eventClickhouse('actions/clicker/offers', {
			statistics: [{
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				offer_id: bankOfferKey,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				transaction_id: transactionId,
				source,
			}],
		});
	}

	private eventClickhouseBankOfferUI(analyticsEventType: AnalyticsTypes, bankOfferKey: string): void {
		this.eventClickhouse('profiles/clicker/profile_dynamics', {
			statistics: [{
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				action: analyticsEventType,
				value: bankOfferKey,
			}],
		});
	}

	private eventClickhouseFarewellParty(
		startedOn: number,
		fuckpower: number,
		fucktime: number,
		rewardBonusTime: number,
		rewardBonusMultiplier: number,
		barsComplete: number,
		totems: string,
	): void {
		this.eventClickhouse('actions/clicker/farewell_party_updates', {
			statistics: [{
				/* eslint-disable @typescript-eslint/naming-convention */
				timestamp: this.serverTimeModel.getCalculatedISOTime().toFixed().toString(),
				session_id: this.clickhouseSessionId,
				level: this.sourceLevelModels.getCurrentLevel(),
				active_event_id: this.trackingEventProgress ? this.sourceEventLevelModels.getDataEventLevelModel().key : 'season',
				started_on: startedOn,
				fuck_power: fuckpower,
				fuck_time: fucktime,
				time_bonus: rewardBonusTime,
				multiplier_bonus: rewardBonusMultiplier,
				bars_completed: barsComplete,
				totems,
				/* eslint-enable @typescript-eslint/naming-convention */
			}],
		});
	}

	private async eventClickhouse(url: string, data: any): Promise<any> {
		const response = await fetch(AnalyticTargetClickhouse.CLICKHOUSE_SERVER_URL + url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				/* eslint-disable @typescript-eslint/naming-convention */
				'Content-Type': 'application/json',
				'pnk-env': 'site',
				'Pnk-Platform': this.platform,
				'pnk-session-id': this.sessionId,
				'pnk-player-id': this.playerId,
				'pnk-device-id': this.deviceId,
				'pnk-version': this.version,
				/* eslint-enable @typescript-eslint/naming-convention */
			},
		});
		const responseData = await response.json();

		return Promise.resolve(responseData?.result);
	}
}

