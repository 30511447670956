import { EventLevelConfigNames } from '@src/types/EventLevelConfigNames';
import { BankConfigsLoaderBase } from './BankConfigsLoaderBase';

export class BankEventLevelConfigsLoader extends BankConfigsLoaderBase {
	private static get CONFIGS_TO_LOAD(): string[] {
		return [
			EventLevelConfigNames.CONFIG_BANK_BUNDLES,
			EventLevelConfigNames.CONFIG_BANK_BUNDLE_GROUPS,
			EventLevelConfigNames.CONFIG_BANK_GEM_SHOP,
			EventLevelConfigNames.CONFIG_BANK_OFFERS,
			EventLevelConfigNames.CONFIG_BANK_TABS,
		];
	}

	public async loadConfigs(): Promise<void> {
		const bankConfigs = await this.networkRequestSender.sendGetConfigs([
			...BankEventLevelConfigsLoader.CONFIGS_TO_LOAD,
		]);

		this.bankConfigsParser.parseBankConfigs(
			bankConfigs[EventLevelConfigNames.CONFIG_BANK_TABS],
			bankConfigs[EventLevelConfigNames.CONFIG_BANK_GEM_SHOP],
			[],
			bankConfigs[EventLevelConfigNames.CONFIG_BANK_BUNDLES],
			[],
			bankConfigs[EventLevelConfigNames.CONFIG_BANK_OFFERS],
			bankConfigs[EventLevelConfigNames.CONFIG_BANK_BUNDLE_GROUPS],
		);
	}
}
