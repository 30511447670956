import { ConfigSetElement } from '@interfaces/ConfigSetElement';
import { BankBundleGroupConfigRaw } from '@src/types/BankTypes';

export type BundleGroupCharacterViewType = {
	characters: string[];
	other: string[];
}

type BandleGroupConfigKey = keyof BankBundleGroupConfigRaw;
export class BankBundleGroupConfig implements ConfigSetElement {
	private static readonly CHARACTERS_KEY: string = 'characters';

	public static readonly FIELD_KEY: BandleGroupConfigKey = 'id';
	public static readonly FIELD_OFFER_ID: BandleGroupConfigKey = 'offer_id';
	public static readonly FIELD_CHARACTER_VIEW_TYPE: BandleGroupConfigKey = 'character_view_type';
	public static readonly FILED_BUY_TIMES: BandleGroupConfigKey = 'buy_times';
	public static readonly FIELD_UNLOCK_TYPE: BandleGroupConfigKey = 'unlock_type';
	public static readonly FIELD_UNLOCK_VALUE: BandleGroupConfigKey = 'unlock_value';

	private readonly characterViewType: BundleGroupCharacterViewType;
	private readonly key: string;
	private readonly offerKey: string;
	private readonly unlockTypes: string[];
	private readonly unlockValues: string[];
	private readonly buyTimes: number;

	constructor(config: BankBundleGroupConfigRaw | { [key: string]: string }) {
		this.key = config[BankBundleGroupConfig.FIELD_KEY].trim();

		const characterViewTypeRaw = (config[BankBundleGroupConfig.FIELD_CHARACTER_VIEW_TYPE] as string).split(';').map(str => str.trim());
		const charactersIndex: number = characterViewTypeRaw.findIndex(x => x.includes(BankBundleGroupConfig.CHARACTERS_KEY));
		const characters = characterViewTypeRaw.splice(charactersIndex, 1)[0].split(':')[1].split(',');
		this.characterViewType = { characters, other: characterViewTypeRaw };

		if (config[BankBundleGroupConfig.FIELD_OFFER_ID]) {
			this.offerKey = config[BankBundleGroupConfig.FIELD_OFFER_ID].trim();
		}

		if (config[BankBundleGroupConfig.FIELD_UNLOCK_TYPE]) {
			const unlockTypesUnformatted = config[BankBundleGroupConfig.FIELD_UNLOCK_TYPE] as string;
			this.unlockTypes = unlockTypesUnformatted.split(',').map(str => str.trim());

			const unlockValuesUnformatted = config[BankBundleGroupConfig.FIELD_UNLOCK_VALUE] as string;
			this.unlockValues = unlockValuesUnformatted.split(',').map(str => str.trim());
		}

		this.buyTimes = Number(config[BankBundleGroupConfig.FILED_BUY_TIMES]);
	}

	public getKey(): string {
		return this.key;
	}

	public getCharacterViewType(): BundleGroupCharacterViewType {
		return this.characterViewType;
	}

	public hasOfferKey(): boolean {
		return Boolean(this.offerKey);
	}

	public getOfferKey(): string {
		return this.offerKey;
	}

	public hasSomeUnlockTypeValue(): boolean {
		return Boolean(this.unlockTypes);
	}

	public getUnlockTypes(): string[] {
		return this.unlockTypes;
	}

	public getUnlockValues(): string[] {
		return this.unlockValues;
	}

	public getBuyTimes(): number {
		return this.buyTimes;
	}
}
