import { Season1LevelDefaultView } from '@views/levels/Season1LevelDefaultView';
import { Season2LevelDefaultView } from '@views/levels/Season2LevelDefaultView';
import { Season3LevelDefaultView } from '@views/levels/Season3LevelDefaultView';
import { Season4LevelDefaultView } from '@views/levels/Season4LevelDefaultView';
import { LevelBackground, LevelBaseView } from './LevelBaseView';
import { LevelDefaultViewArgs } from './LevelDefaultView';

export interface LevelViewConstructor {
	new (args: LevelDefaultViewArgs): LevelBaseView;

	createBackground(background: string): LevelBackground;
}

export function GetLevelViewById(seasonId: number): LevelViewConstructor {
	switch (seasonId) {
		case 1:
			return Season1LevelDefaultView;

		case 2:
			return Season2LevelDefaultView;

		case 3:
			return Season3LevelDefaultView;

		case 4:
			return Season4LevelDefaultView;

		default:
			throw new Error(`Unsupported season: ${seasonId}`);
	}
}
