import { SizeableMultiColoredBitmapText } from '@views/components/text/SizeableMultiColoredBitmapText';
import * as TWEEN from '@tweenjs/tween.js';
import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';

export class CustomersProgressView extends PIXI.Container {
	private readonly customerCount: SizeableMultiColoredBitmapText;
	private readonly addCustomerCount: SizeableMultiColoredBitmapText;
	private readonly levelString: SizeableMultiColoredBitmapText;
	private readonly progressBg: PIXI.Sprite;

	private readonly specialBar: PIXI.Sprite;
	private readonly specialGlow: PIXI.Sprite;

	private readonly contentContainer: PIXI.Container;

	private readonly progressBar: PIXI.Sprite;
	private readonly progressBarMask: PIXI.Graphics;
	private readonly progressBarNext: PIXI.Sprite;
	private readonly progressBarNextMask: PIXI.Graphics;

	private readonly fxBarSprite: PIXI.Sprite;
	private readonly fxNewMultiplierSprite: PIXI.Sprite;
	private readonly fxNewMultiplierSprite2: PIXI.Sprite;

	private readonly localizationStorage: LocalizationStorage;

	private addCustomerCountValue: number;

	private scaleTextNewCustomerCountTween: TWEEN.Tween;
	private scaleTextCustomerCountTween: TWEEN.Tween;
	private scaleGlowToUpdateTween: TWEEN.Tween;
	private fullBarTween?: TWEEN.Tween;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	constructor(
		private relatedCharacterActivated: boolean,
	) {
		super();

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslate, this);

		this.tweenGroup = new TWEEN.Group();

		this.addCustomerCount = new SizeableMultiColoredBitmapText(55, { font: '18px wendyOneShadowBold' });
		this.addCustomerCount.anchor = 0.5;
		this.addCustomerCount.position.set(0, -18);

		this.customerCount = new SizeableMultiColoredBitmapText(70, { font: '25px wendyOneShadowBold', tint: 0xffdd74 });
		this.customerCount.anchor = 0.5;
		this.customerCount.position.set(0, 1);

		this.levelString = new SizeableMultiColoredBitmapText(55, { font: '17px wendyOne', tint: 0x7f8081 });
		this.levelString.anchor = 0.5;
		this.levelString.text = this.localizationStorage.getLocalizedString('#business_level_label');
		this.levelString.position.set(0, 19);

		this.progressBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['customers_bg_bar']);
		this.progressBg.anchor.set(0.5);
		this.progressBg.y = 1;

		this.progressBar = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['customers_bar']);
		this.progressBar.anchor.set(0.5);

		this.progressBarMask = new PIXI.Graphics();
		this.progressBar.mask = this.progressBarMask;

		this.progressBarNext = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['customers_bar_next']);
		this.progressBarNext.anchor.set(0.5);

		this.progressBarNextMask = new PIXI.Graphics();
		this.progressBarNext.mask = this.progressBarNextMask;

		this.fxBarSprite = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['customers_bar_glow']);
		this.fxBarSprite.pivot = new PIXI.Point(11, 25.5);
		this.fxBarSprite.alpha = 0;
		this.fxBarSprite.blendMode = PIXI.BLEND_MODES.SCREEN;
		this.fxBarSprite.mask = this.progressBarMask;

		this.fxNewMultiplierSprite = new PIXI.Sprite(AssetsStorage.getAtlas('fxAtlas')['skill_activ_glow3']);
		this.fxNewMultiplierSprite.scale.set(0.76);
		this.fxNewMultiplierSprite.visible = false;
		this.fxNewMultiplierSprite.alpha = 0;
		this.fxNewMultiplierSprite.blendMode = PIXI.BLEND_MODES.SCREEN;

		this.fxNewMultiplierSprite2 = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['customers_bar']);
		this.fxNewMultiplierSprite2.alpha = 0;

		this.specialBar = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_business_upgrade_slot_bar']);
		this.specialGlow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['u_business_upgrade_slot_glow']);
		this.specialGlow.scale.set(0.5);
		this.specialGlow.interactive = false;

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.update, this);

		this.contentContainer = new PIXI.Container();
		this.contentContainer.addChild(
			this.specialGlow,
			this.progressBg,
			this.progressBarNextMask,
			this.progressBarNext,
			this.progressBarMask,
			this.progressBar,
			this.specialBar,
			this.fxNewMultiplierSprite2,
			this.fxBarSprite,
			this.fxNewMultiplierSprite,
			this.customerCount as PIXI.DisplayObject,
			this.addCustomerCount,
			this.levelString,
		);
		this.addChild(this.contentContainer);
	}

	public setProgress(current: number, next: number, total: number): void {
		const percent = current / total;
		const nextPercent = next / total;
		const fxBarRotation = Math.PI / 180 * ((Math.min(360, 360 * percent) - 6));

		this.fxBarSprite.rotation = fxBarRotation;

		this.applyProgressMask(
			this.progressBar,
			this.progressBarMask,
			percent,
		);
		this.applyProgressMask(
			this.progressBarNext,
			this.progressBarNextMask,
			nextPercent,
		);

		const fullProgressAvailable = next === total;

		if (fullProgressAvailable && this.relatedCharacterActivated) {
			if (this.fullBarTween == null) {
				this.createFullBarAnimation();
			}
		} else {
			this.destroyFullBarAnimation();
		}
	}

	private createFullBarAnimation(): void {
		this.fullBarTween = new TWEEN.Tween(this.specialGlow.scale)
			.to({ x: this.specialGlow.scale.x + 0.5, y: this.specialGlow.scale.x + 0.5 }, 600)
			.repeat(Infinity)
			.yoyo(true)
			.easing(TWEEN.Easing.Cubic.InOut)
			.start();
	}

	private destroyFullBarAnimation(): void {
		if (this.fullBarTween) {
			this.fullBarTween.stop();
			this.fullBarTween = undefined;
		}

		this.specialGlow.scale.set(0.5);
	}

	private applyProgressMask(
		progressBar: PIXI.Sprite,
		progressBarMask: PIXI.Graphics,
		value: number,
	): void {
		const deg = -Math.min(360, 360 * value);

		progressBarMask.clear();

		progressBarMask.beginFill(0);
		progressBarMask.lineStyle(2);
		progressBarMask.arc(
			progressBar.x,
			progressBar.y,
			progressBar.width,
			-0.0174533 * 90,
			-0.0174533 * (deg + 90),
		);

		if (deg !== 0) {
			progressBarMask.lineTo(0, 0);
		}

		progressBarMask.endFill();

		if (deg === -360) {
			this.specialBar.alpha = 1;
			this.specialGlow.alpha = 1;
		} else {
			this.specialBar.alpha = 0;
			this.specialGlow.alpha = 0;
		}
	}

	public setCharacterActivated(value: boolean): void {
		this.relatedCharacterActivated = value;
	}

	public setSpecialBarVisible(value: boolean): void {
		this.specialBar.visible = value;
		this.specialGlow.visible = value;
	}

	public glowBarAnimation(): void {
		const alphaUp = new TWEEN.Tween(this.fxBarSprite, this.tweenGroup)
			.to({ alpha: 1 }, 1)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
		const alphaDown = new TWEEN.Tween(this.fxBarSprite, this.tweenGroup)
			.to({ alpha: 0 }, 1000)
			.start();
		alphaUp.chain(alphaDown);
	}

	public glowNewMultiplierAnimation(): void {
		this.fxNewMultiplierSprite.visible = true;
		this.fxNewMultiplierSprite.alpha = 1;
		this.fxNewMultiplierSprite2.alpha = 1;

		new TWEEN.Tween(this.fxNewMultiplierSprite, this.tweenGroup)
			.to({ alpha: 0 }, 1000)
			.onComplete(() => {
				this.fxNewMultiplierSprite2.visible = false;
			})
			.start();

		new TWEEN.Tween(this.fxNewMultiplierSprite2, this.tweenGroup)
			.to({ alpha: 0 }, 1000)
			.start();
	}

	public getCustomerCount(): number {
		return Number(this.addCustomerCountValue);
	}

	public setCustomerCount(value: number, animateChange: boolean = true): void {
		this.customerCount.text = `${value}`;

		if (!this.scaleTextNewCustomerCountTween && animateChange) {
			if (this.scaleTextCustomerCountTween) {
				this.scaleTextCustomerCountTween.stop();
			}

			this.scaleTextNewCustomerCountTween = new TWEEN.Tween(this.customerCount.scale)
				.to({ x: this.customerCount.scale.x + 0.2, y: this.customerCount.scale.y + 0.2 }, 100)
				.repeat(1)
				.yoyo(true)
				.onComplete(() => { this.scaleTextNewCustomerCountTween = null; })
				.start();
		}
	}

	public setAddCustomerCount(value: number): void {
		this.addCustomerCountValue = value;
		this.addCustomerCount.text = `+${this.addCustomerCountValue}`;
	}

	public setMaxMode(): void {
		new TWEEN.Tween(this.contentContainer, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.onComplete(() => {
				this.removeChild(this.contentContainer);
			})
			.start();
	}

	public onZoomIn(): void {
		new TWEEN.Tween(this.contentContainer, this.tweenGroup)
			.to({ alpha: 0 }, 200)
			.start();
	}

	public onZoomOut(): void {
		new TWEEN.Tween(this.contentContainer, this.tweenGroup)
			.to({ alpha: 1 }, 200)
			.start();
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	private onTranslate(): void {
		this.levelString.text = this.localizationStorage.getLocalizedString('#business_level_label');
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update, this);

		if (this.scaleTextCustomerCountTween) {
			this.scaleTextCustomerCountTween.stop();
		}

		if (this.scaleTextNewCustomerCountTween) {
			this.scaleTextNewCustomerCountTween.stop();
		}

		if (this.scaleGlowToUpdateTween) {
			this.scaleGlowToUpdateTween.stop();
		}

		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslate, this);

		super.destroy(options);
	}
}
