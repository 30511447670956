import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter60016View extends CharacterMainBaseView {
	constructor() {
		super('character60016');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-140, 2);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(-54, -31),
			arrowRotation: Math.PI / 180,
			bgPosition: new PIXI.Point(130, -13),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
