import { BankGemShopItemConfig } from '@configs/bank/BankGemShopItemConfig';
import { BankSavesModel } from '@models/bank/BankSavesModel';
import { UnlockValueChecker } from '@src/loaders/UnlockValueChecker';
import { BankElementIdPrefix } from '@src/viewControllers/BankWindowViewController';
import { BankOfferConfig } from '@configs/bank/BankOfferConfig';

export class BankGemShopConfigsFilter {
	constructor(
		private readonly unlockValueChecker: UnlockValueChecker,
		private readonly bankSavesModel: BankSavesModel,
	) {}

	public filter(
		source: Map<string, BankGemShopItemConfig>,
		offersMap: Map<string, BankOfferConfig>,
	): Map<string, BankGemShopItemConfig> {
		const filteredMap: Map<string, BankGemShopItemConfig> = new Map();

		source.forEach((config: BankGemShopItemConfig) => {
			let result: boolean;
			if (config.hasOfferKey()) {
				result = this.checkOffer(config, filteredMap, offersMap);
			} else {
				result = !filteredMap.has(config.getKey());
			}

			if (result && config.hasSomeUnlockTypeValue()) {
				const unlockTypes = config.getUnlockTypes();
				const unlockValues = config.getUnlockValues();
				for (let i = 0; i < unlockTypes.length && result; i++) {
					const unlockType = unlockTypes[i];
					const unlockValue = unlockValues[i];
					result = this.unlockValueChecker.check(unlockType, unlockValue);
				}
			}

			result &&= this.canBuy(config.getBuyTimes(), BankElementIdPrefix.GEM_SHOP + config.getKey());

			if (result) {
				filteredMap.set(config.getKey(), config);
			}
		});
		return filteredMap;
	}

	private canBuy(buyTimes: number, lotId: string): boolean {
		return buyTimes === 0 || buyTimes > this.bankSavesModel.getPurchaseCount(lotId);
	}

	// eslint-disable-next-line class-methods-use-this
	private checkOffer(
		config: BankGemShopItemConfig,
		filteredMap: Map<string, BankGemShopItemConfig>,
		offersMap: Map<string, BankOfferConfig>,
	): boolean {
		let result: boolean = offersMap.has(config.getOfferKey());
		const otherItem: BankGemShopItemConfig | undefined = filteredMap.get(config.getKey());
		result &&= !otherItem?.hasOfferKey();
		return result;
	}
}
