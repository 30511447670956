export enum RewardTypes {
	RESOURCE = 'resource',
	CHARACTER = 'character',
	UPGRADE = 'upgrade',
	TOTEM = 'totem',
	VIDEO = 'video',
	TIMESKIP = 'timeskip',
	BOOST = 'boost',
}

export type RewardDescriptionType = {
	reward_type: string;
	reward_id: string;
	reward_qty: string | number;
}

export enum RewardResourceIdTypes {
	SOFT_MONEY = 'soft_money',
	PRESTIGE_MONEY = 'prestige_money',
	HARD_MONEY = 'hard_money',
}
export enum RewardDisplayTypes {
	CARDS = 'cards',
	VIDEO = 'video',
	TIMESKIP = 'timeskip',
	BOOST = 'boost',
	SOFT_MONEY = 'soft_money',
	PRESTIGE_MONEY = 'prestige_money',
	HARD_MONEY = 'hard_money',
}

export type RewardDisplayValueType = string;

export type RewardDescriptionsWithTransactionIdType = {
	rewards: RewardDescriptionType[];
	transactionId: string;
}
