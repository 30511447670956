import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter255010View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255010View';
import { EventCharacter255011View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255011View';
import { EventCharacter255012View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255012View';

export class EventBusiness255004View extends BusinessBaseView {
	constructor() {
		const eventCharacter255010View = new EventCharacter255010View();
		const eventCharacter255011View = new EventCharacter255011View();
		const eventCharacter255012View = new EventCharacter255012View();

		super('business255004', [eventCharacter255010View, eventCharacter255011View, eventCharacter255012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter255010View as PIXI.DisplayObject,
			eventCharacter255011View,
			eventCharacter255012View,
		);
	}
}
