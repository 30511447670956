import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness120001View } from '@views/businesses/event12Businesses/eventBuilding1/EventBusiness120001View';
import { EventBusiness120002View } from '@views/businesses/event12Businesses/eventBuilding1/EventBusiness120002View';
import { EventBusiness120003View } from '@views/businesses/event12Businesses/eventBuilding1/EventBusiness120003View';
import { EventBusiness120004View } from '@views/businesses/event12Businesses/eventBuilding2/EventBusiness120004View';
import { EventBusiness120005View } from '@views/businesses/event12Businesses/eventBuilding2/EventBusiness120005View';
import { EventBusiness120006View } from '@views/businesses/event12Businesses/eventBuilding2/EventBusiness120006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevel12View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.NIGHT:
				this.createNightLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.NIGHT:
				return this.createNightBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	private static createNightBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('canadaNightAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['canada_night/sky']).setTransform(2535, 512, 2600);

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/house2']).setTransform(132, 621),
			new PIXI.Sprite(levelBgAtlas['canada_night/house5']).setTransform(459, 601),
			new PIXI.Sprite(levelBgAtlas['canada_night/house4']).setTransform(604, 687),
			new PIXI.Sprite(levelBgAtlas['canada_night/house6']).setTransform(951, 436),
			new PIXI.Sprite(levelBgAtlas['canada_night/house3']).setTransform(1279, 713),
			new PIXI.Sprite(levelBgAtlas['canada_night/house1']).setTransform(1652, 744),
			new PIXI.Sprite(levelBgAtlas['canada_night/house2']).setTransform(2184, 621),
			new PIXI.Sprite(levelBgAtlas['canada_night/house4']).setTransform(2384, 687),
			new PIXI.Sprite(levelBgAtlas['canada_night/house3']).setTransform(2754, 713),
		);

		backgroundContainer.addChild(
			sky,

			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/road'], EventLevel12View.LEVEL_WIDTH, 217)
				.setTransform(EventLevel12View.LEVEL_WIDTH * 0.5, 1051),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(845, 546),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(2535, 546),
			new PIXI.Sprite(levelBgAtlas['canada_night/city']).setTransform(3380, 546),
			back,
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/river'], EventLevel12View.LEVEL_WIDTH, 109)
				.setTransform(EventLevel12View.LEVEL_WIDTH * 0.5, 894, 1, 0.9),
			new PIXI.extras.TilingSprite(levelBgAtlas['canada_night/decor'], EventLevel12View.LEVEL_WIDTH, 55)
				.setTransform(EventLevel12View.LEVEL_WIDTH * 0.5, 928, 1, 0.9),
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createNightLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('canadaNightAtlas');

		this.background = EventLevel12View.createNightBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness120001View(),
			new EventBusiness120002View(),
			new EventBusiness120003View(),
			new EventBusiness120004View(),
			new EventBusiness120005View(),
			new EventBusiness120006View(),
		];
		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right1']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left']).setTransform(312, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_stairs']).setTransform(1085, 300.5),

			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['canada_night/building_right2']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_top2']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['canada_night/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['canada_night/building_left2']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['canada_night/logo_wet_pussy']).setTransform(1190, 500),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);

		this.initLevel(EventLevel12View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(0, 0);
	}
}
