import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter253007View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253007View';
import { EventCharacter253008View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253008View';
import { EventCharacter253009View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253009View';

export class EventBusiness253003View extends BusinessBaseView {
	constructor() {
		const eventCharacter253007View = new EventCharacter253007View();
		const eventCharacter253008View = new EventCharacter253008View();
		const eventCharacter253009View = new EventCharacter253009View();

		super('business253003', [eventCharacter253007View, eventCharacter253008View, eventCharacter253009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b4_decor3']).setTransform(0, -80, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(145, -100, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor4']).setTransform(-145, -100, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(-239, -14, -0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor2']).setTransform(239, -14, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b4_decor1']).setTransform(14, 110, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter253007View as PIXI.DisplayObject,
			eventCharacter253008View,
			eventCharacter253009View,
		);
	}
}
