import {
	TutorialStepUnlockTypeBaseConfig,
	TutorialStepUnlockTypeGetQuestConfig,
	TutorialStepUnlockTypeQuestCompleteConfig,
	TutorialStepUnlockTypeTutorialStepConfig,
	TutorialStepUnlockTypeIncomeMoneyAppearConfig,
	TutorialStepUnlockTypePrestigeBonusConfig,
	TutorialStepUnlockTypeBusinessAcquiredConfig,
	TutorialStepUnlockTypeCustomersOnBusinessConfig,
	TutorialStepUnlockTypeSummonResultCardsAppearConfig,
	TutorialStepUnlockTypeCharacterActivatedConfig,
} from '@configs/tutorialSteps/TutorialStepUnlockTypeConfigs';
import { TutorialStepUnlockTypes } from '@src/types/TutorialStepUnlockTypes';
import { TutorialStepUnlockTypeGetQuestModel } from './TutorialStepUnlockTypeGetQuestModel';
import { TutorialStepUnlockTypeBaseModel } from './TutorialStepUnlockTypeBaseModel';
import { TutorialStepUnlockTypeQuestCompleteModel } from './TutorialStepUnlockTypeQuestCompleteModel';
import { TutorialStepUnlockTypeTutorialStepModel } from './TutorialStepUnlockTypeTutorialStepModel';
import { TutorialStepUnlockTypePrestigeBonusModel } from './TutorialStepUnlockTypePrestigeBonusModel';
import {
	TutorialStepUnlockTypeQuestWindowAppearModel,
	TutorialStepUnlockTypePrepartyWindowAppearModel,
	TutorialStepUnlockTypeCollectionsWindowAppearModel,
	TutorialStepUnlockTypePartyWindowAppearModel,
	TutorialStepUnlockTypeBankBoostConfirmWindowAppearModel,
} from './TutorialStepUnlockTypeWindowAppearModels';
import { TutorialStepUnlockTypeIncomeMoneyAppearModel } from './TutorialStepUnlockTypeIncomeMoneyAppearModel';
import { TutorialStepUnlockTypeBusinessAcquiredModel } from './TutorialStepUnlockTypeBusinessAcquiredModel';
import { TutorialStepUnlockTypeSummonResultCardsAppearedModel } from './TutorialStepUnlockTypeSummonResultCardsAppeared';
import { TutorialStepUnlockTypeSummonCardsOpenedModel } from './TutorialStepUnlockTypeSummonCardsOpened';
import { TutorialStepUnlockTypeCustomersOnBusinessModel } from './TutorialStepUnlockTypeCustomersOnBusinessModel';
import {
	TutorialStepUnlockTypeGoToNextLevelButtonAppearModel,
	TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel,
} from '@models/tutorialSteps/unlockTypes/TutorialStepUnlockTypeButtonAppearModels';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { TutorialStepBaseModel } from '../TutorialStepBaseModel';
import { WindowViewSystem } from '@views/windows/WindowViewSystem';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { TutorialStepUnlockTypeCharacterActivatedModel } from './TutorialStepUnlockTypeCharacterActivatedModel';

export abstract class TutorialStepUnlockTypeBaseFactory {
	constructor(
		protected readonly windowViewSystem: WindowViewSystem,
		protected readonly tutorialStepModels: Map<string, TutorialStepBaseModel>,
		protected readonly businessModels: Map<string, BusinessModel>,
		protected readonly questLinesModel: QuestLinesModel,
		private readonly characterModels: Map<string, CharacterModel>,
	) {}

	protected createModel(config: TutorialStepUnlockTypeBaseConfig): TutorialStepUnlockTypeBaseModel {
		switch (config.getType()) {
			case TutorialStepUnlockTypes.GET_QUEST: {
				const configGetQuest = config as TutorialStepUnlockTypeGetQuestConfig;
				return new TutorialStepUnlockTypeGetQuestModel(config.getKey(), configGetQuest.getQuestKey(), this.questLinesModel);
			}

			case TutorialStepUnlockTypes.QUEST_COMPLETE: {
				const configQuestComplete = config as TutorialStepUnlockTypeQuestCompleteConfig;
				return new TutorialStepUnlockTypeQuestCompleteModel(config.getKey(), configQuestComplete.getQuestKey(), this.questLinesModel);
			}

			case TutorialStepUnlockTypes.TUTORIAL_STEP: {
				const configTutorialStep = config as TutorialStepUnlockTypeTutorialStepConfig;
				const targetTutorialStep = this.tutorialStepModels.get(configTutorialStep.getTutorialStepKey());
				return new TutorialStepUnlockTypeTutorialStepModel(config.getKey(), targetTutorialStep);
			}

			case TutorialStepUnlockTypes.PRESTIGE_BONUS: {
				const configPrestigeBonus = config as TutorialStepUnlockTypePrestigeBonusConfig;
				return new TutorialStepUnlockTypePrestigeBonusModel(config.getKey(), configPrestigeBonus.getBusinessKey());
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_QUEST: {
				return new TutorialStepUnlockTypeQuestWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_PREPARTY: {
				return new TutorialStepUnlockTypePrepartyWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_PARTY: {
				return new TutorialStepUnlockTypePartyWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_COLLECTIONS: {
				return new TutorialStepUnlockTypeCollectionsWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.WINDOW_APPEAR_BANK_BOOST_CONFIRM: {
				return new TutorialStepUnlockTypeBankBoostConfirmWindowAppearModel(
					this.windowViewSystem,
					config.getKey(),
				);
			}

			case TutorialStepUnlockTypes.BUTTON_APPEAR_GO_TO_NEXT_LEVEL: {
				return new TutorialStepUnlockTypeGoToNextLevelButtonAppearModel(config.getKey());
			}

			case TutorialStepUnlockTypes.BUTTON_APPEAR_PREPARTY_START_CHARACTER: {
				return new TutorialStepUnlockTypePrepartyStartCharacterButtonAppearModel(config.getKey());
			}

			case TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_APPEARED: {
				const configSummonCardsAppear = config as TutorialStepUnlockTypeSummonResultCardsAppearConfig;
				return new TutorialStepUnlockTypeSummonResultCardsAppearedModel(config.getKey(), configSummonCardsAppear.getSummonKey());
			}

			case TutorialStepUnlockTypes.INCOME_MONEY_APPEAR: {
				const configIncomeMoneyAppear = config as TutorialStepUnlockTypeIncomeMoneyAppearConfig;
				return new TutorialStepUnlockTypeIncomeMoneyAppearModel(config.getKey(), configIncomeMoneyAppear.getBusinessKey());
			}

			case TutorialStepUnlockTypes.BUSINESS_ACQUIRED: {
				const configBusinessAcquired = config as TutorialStepUnlockTypeBusinessAcquiredConfig;
				return new TutorialStepUnlockTypeBusinessAcquiredModel(config.getKey(), configBusinessAcquired.getBusinessKey());
			}

			case TutorialStepUnlockTypes.SUMMON_RESULT_CARDS_OPENED: {
				const configSummonCardsOpened = config as TutorialStepUnlockTypeSummonResultCardsAppearConfig;
				return new TutorialStepUnlockTypeSummonCardsOpenedModel(config.getKey(), configSummonCardsOpened.getSummonKey());
			}

			case TutorialStepUnlockTypes.CUSTOMERS_ON_BUSINESS: {
				const configCustomersOnBusiness = config as TutorialStepUnlockTypeCustomersOnBusinessConfig;
				const business = this.businessModels.get(configCustomersOnBusiness.getBusinessKey());
				return new TutorialStepUnlockTypeCustomersOnBusinessModel(
					config.getKey(),
					business,
					configCustomersOnBusiness.getClaimCount(),
				);
			}

			case TutorialStepUnlockTypes.CHARACTER_ACTIVATED: {
				const configCharacterActivated = config as TutorialStepUnlockTypeCharacterActivatedConfig;
				const characterModel = this.characterModels.get(configCharacterActivated.getCharacterKey());

				return new TutorialStepUnlockTypeCharacterActivatedModel(
					config.getKey(),
					characterModel,
				);
			}

			default:
				throw new Error(`Unsupported unlock type '${config.getType()}'`);
		}
	}
}
