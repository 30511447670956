import { TrackingData } from '@src/types/AnalyticsTypes';
import { AnalyticSourceLevelStartWindowView, EventButtonStartClickEventArgs } from '../sources/AnalyticSourceLevelStartWindowView';

export class AnalyticTargetPropellerAds extends PIXI.utils.EventEmitter {
	public static readonly EVENT_RESPONSE: symbol = Symbol();

	private static readonly UTM_SOURCE: string = 'pan_pa';

	private static readonly BASE_URL: string = 'https://ad.propellerads.com/conversion.php?aid=673253&pid=&tid=66632';

	private marketingTrackingParam?: string;
	private permitted: boolean;

	constructor(trackingData?: TrackingData) {
		super();

		this.init(trackingData);
	}

	public setGameProfileData(trackingData?: TrackingData): void {
		this.init(trackingData);
	}

	private init(trackingData?: TrackingData): void {
		if (trackingData !== undefined
			&& trackingData.utmSource === AnalyticTargetPropellerAds.UTM_SOURCE) {
			this.permitted = true;
			this.marketingTrackingParam = trackingData.affSub;
		} else {
			this.permitted = false;
		}
	}

	public addSourceLevelStartWindowView(source: AnalyticSourceLevelStartWindowView): void {
		const tryEmitEventTutorialEnd = async (args: EventButtonStartClickEventArgs): Promise<void> => {
			if (this.permitted) {
				if (args.currentLevel === 4) {
					const url = `${AnalyticTargetPropellerAds.BASE_URL}&visitor_id=${this.marketingTrackingParam}`;
					const response = await fetch(url);
					this.emit(AnalyticTargetPropellerAds.EVENT_RESPONSE, response);
				} else {
					source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
				}
			}
		};
		source.once(AnalyticSourceLevelStartWindowView.EVENT_BUTTON_START_CLICK, tryEmitEventTutorialEnd, this);
	}
}
