import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter253013View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253013View';
import { EventCharacter253014View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253014View';
import { EventCharacter253015View } from '@views/characters/events/eventLevelWeekDay8/EventCharacter253015View';

export class EventBusiness253005View extends BusinessBaseView {
	constructor() {
		const eventCharacter253013View = new EventCharacter253013View();
		const eventCharacter253014View = new EventCharacter253014View();
		const eventCharacter253015View = new EventCharacter253015View();

		super('business253005', [eventCharacter253013View, eventCharacter253014View, eventCharacter253015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter253013View as PIXI.DisplayObject,
			eventCharacter253014View,
			eventCharacter253015View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
