import { SkillModel } from '@models/skills/SkillModel';
import { SkillBaseView } from '@views/ui/skillsPanel/SkillBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SkillTypes } from '@src/types/SkillTypes';
import { SkillEffectiveImproveView } from '@views/ui/skillsPanel/SkillEffectiveImproveView';
import { CharacterModel } from '@models/CharacterModel';
import { SkillConstantProfitView } from './SkillConstantProfitView';
import { SkillReduceReloadView } from './SkillReduceReloadView';

export class SkillsPanelView extends PIXI.Container {
	public static EVENT_SHOW_HINT_SKILL: symbol = Symbol();

	private static createSkillView(model: SkillModel): SkillBaseView {
		let view;
		switch (model.getType()) {
			case SkillTypes.CONSTANT_PROFIT:
				view = new SkillConstantProfitView(model);
				break;
			case SkillTypes.REDUCE_RELOAD:
				view = new SkillReduceReloadView(model);
				break;
			case SkillTypes.EFFECTIVE_IMPROVE:
				view = new SkillEffectiveImproveView(model);
				break;
			default:
				view = new SkillBaseView(model);
		}
		return view;
	}

	private views: SkillBaseView[];

	constructor(
		skillModels: Map<string, SkillModel>,
		charactersModels: Map<string, CharacterModel>,
	) {
		super();

		this.views = [];

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['skill_panel_bg'], 27, 0, 27, 0);
		const bgWidth = 670;
		const bgHeight = 50;
		bg.width = bgWidth;
		bg.height = bgHeight;
		bg.pivot.set(bgWidth / 2, bgHeight);

		this.addChild(bg);

		const skillsArray = Array.from(skillModels.values());
		skillsArray.sort((modelA, modelB) => (modelA.getOrder() < modelB.getOrder() ? -1 : 1));
		skillsArray.forEach((model, i) => {
			const character = charactersModels.get(model.getCharacterKey());

			const skillContainer = new PIXI.Container();
			const view = SkillsPanelView.createSkillView(model);
			view.on(SkillBaseView.EVENT_CLICK, () => this.onSomeSkillClick(view, model, character), this);
			skillContainer.addChild(
				view,
			);
			skillContainer.x = -270 + 90 * i;
			skillContainer.y = -45;

			this.views.push(view);
			this.addChild(skillContainer);
		});
	}

	public getSkillViews(): SkillBaseView[] {
		return this.views;
	}

	public getSkillView(key: string): PIXI.Container {
		return this.views.find(view => view.getKey() === key);
	}

	private onSomeSkillClick(skillView: SkillBaseView, skillModel: SkillModel, characterModel: CharacterModel): void {
		const canActivateSkill = skillModel.isOpened() && !skillModel.isActive() && !skillModel.isReloading();
		if (!canActivateSkill) {
			const arrowPosLocal = new PIXI.Point(0, -40);
			this.emit(
				SkillsPanelView.EVENT_SHOW_HINT_SKILL,
				skillModel,
				characterModel,
				arrowPosLocal,
				skillView,
			);
		}
	}
}
