import { BankGemShopItemConfig } from '@configs/bank/BankGemShopItemConfig';
import { BankGemShopItemModel } from '@models/bank/BankGemShopItemModel';
import { BankUIViewHelper } from '@views/windows/bank/BankUIViewHelper';

export class BankGemShopItemsFactory {
	constructor(
		private readonly isNutaku: boolean,
	) {}

	public createModels(
		bankGemShopItemConfigs: Map<string, BankGemShopItemConfig>,
		priceConversionCoeff: number,
	): Map<string, BankGemShopItemModel> {
		const models = new Map();
		bankGemShopItemConfigs.forEach((config: BankGemShopItemConfig, key: string) => {
			const buttonPriceResource = BankUIViewHelper.resolveButtonValueTypeByPriceResource(
				config.getPriceResource(),
				this.isNutaku,
			);
			const buttonOldPriceResource = BankUIViewHelper.resolveButtonValueTypeByPriceResource(
				config.getOldPriceResource(),
				this.isNutaku,
			);
			const itemModel = new BankGemShopItemModel(
				config,
				priceConversionCoeff,
				buttonPriceResource,
				buttonOldPriceResource,
			);
			models.set(key, itemModel);
		});
		return models;
	}
}
