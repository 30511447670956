import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter110013View } from '@views/characters/events/event11/EventCharacter110013View';
import { EventCharacter110014View } from '@views/characters/events/event11/EventCharacter110014View';
import { EventCharacter110015View } from '@views/characters/events/event11/EventCharacter110015View';

export class EventBusiness110005View extends BusinessBaseView {
	constructor() {
		const eventCharacter110013View = new EventCharacter110013View();
		const eventCharacter110014View = new EventCharacter110014View();
		const eventCharacter110015View = new EventCharacter110015View();

		super('business110005', [eventCharacter110013View, eventCharacter110014View, eventCharacter110015View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter110013View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter110014View as PIXI.DisplayObject,
			eventCharacter110015View,
		);
	}
}
