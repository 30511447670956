import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter70002View extends CharacterMainBaseView {
	constructor() {
		super('character70002');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(-80, 1);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(250, -17),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(280, -20),
			invertBg: true,
		});
		this.initQuickPhrases(this.key);
	}
}
