import { MaxButtonValueData } from '@configs/ConstantsConfig';
import { BankModel } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BaseBoostModel } from '@models/BaseBoostModel';
import { BoostModel } from '@models/BoostModel';
import { CharacterModel } from '@models/CharacterModel';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { GalleryVideoModel } from '@models/GalleryVideoModel';
import { ModelHelper } from '@models/ModelHelper';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';
import { EpicQuestCollectionsModel } from '@models/quests/epic/EpicQuestCollectionsModel';
import { SkillModel } from '@models/skills/SkillModel';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { TotemModel } from '@models/TotemModel';
import { UpgradeModel } from '@models/UpgradeModel';
import * as TWEEN from '@tweenjs/tween.js';
import { ViewportView } from '@views/components/ViewportView';
import { ButtonCheatView } from '@views/windows/cheatWindow/ButtonCheatView';
import { BankOfferLinesUIView } from '../bankOffers/BankOfferLinesUIView';
import { ButtonBankView } from '../bottomRightPanel/ButtonBankView';
import { ButtonCollectionView } from '../bottomRightPanel/ButtonCollectionView';
import { ButtonMaxCustomersView } from '../ButtonMaxCustomersView';
import { GameUIBoostView } from '../GameUIBoostView';
import { GameUISkillGirlsView } from '../GameUISkillGirlsView';
import { HardMoneyIncomeAnimationView } from '../incomeMoney/HardMoneyIncomeAnimationView';
import { PrestigeMoneyIncomeAnimationView } from '../incomeMoney/PrestigeMoneyIncomeAnimationView';
import { PrestigeMoneyPanelView } from '../moneyPanel/PrestigeMoneyPanelView';
import { SoftMoneyPanelView } from '../moneyPanel/SoftMoneyPanelView';
import { QuestLinesUIView } from '../quests/QuestLinesUIView';
import { SkillsPanelView } from '../skillsPanel/SkillsPanelView';
import { LevelTopUIPanelView } from '../topUIPanel/LevelTopUIPanelView';
import { TopUIPanelBaseView } from '../topUIPanel/TopUIPanelBaseView';
import { HintTypes } from '@src/types/HintTypes';
import { ButtonFadeView } from '../bottomRightPanel/ButtonFadeView';
import { MaintenanceTimerView } from '../MaintenanceTimerView';
import { MaintenanceModel } from '@models/MaintenanceModel';

export abstract class GameUIBaseView extends PIXI.Container {
	public static readonly EVENT_INIT: symbol = Symbol();

	public static readonly EVENT_BUTTON_SETTINGS_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_COLLECTION_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BANK_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CHEAT_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_QUEST_LINE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BANK_OFFER_LINE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_BOOST_CLICK: symbol = Symbol();
	public static readonly EVENT_SHOW_HINT: symbol = Symbol();

	protected readonly viewport: ViewportView;
	protected readonly summonModelsPool: SummonModelsPool;
	protected readonly bankModel: BankModel;
	protected readonly questTargetDuration: number;
	protected readonly interactionManager: PIXI.interaction.InteractionManager;

	protected readonly skillModels: Map<string, SkillModel>;
	protected readonly characterModels: Map<string, CharacterModel>;
	protected readonly upgradeModels: Map<string, UpgradeModel>;
	protected readonly totemModels: Map<string, TotemModel>;
	protected readonly boostModels: Map<string, BoostModel>;
	protected readonly farewellBoostModel: FarewellBoostModel;
	protected readonly galleryVideoModels: Map<string, GalleryVideoModel>;
	protected readonly epicQuestCollectionsModels: Map<string, EpicQuestCollectionsModel>;

	protected readonly mainUI: PIXI.Container;
	protected readonly topUIPanelBase: TopUIPanelBaseView;
	protected readonly incomeAnimationContainer: PIXI.Container;
	protected readonly collectionButton: ButtonCollectionView;
	protected readonly collectionFade: ButtonFadeView;
	protected readonly bankButton: ButtonBankView;
	protected readonly bankFade: ButtonFadeView;
	protected readonly buttonMaxCustomersView: ButtonMaxCustomersView;
	protected readonly skillsPanelView: SkillsPanelView;
	protected readonly bankOfferLinesUIView: BankOfferLinesUIView;
	protected readonly questLinesView: QuestLinesUIView;
	protected readonly boostView: GameUIBoostView;
	protected readonly maintenancePanel: MaintenanceTimerView;

	protected hasOpenedSkill: boolean;
	protected boostEverBought: boolean;

	protected readonly tweenGroup: TWEEN.Group;
	private readonly tweenTicker: PIXI.ticker.Ticker;

	constructor(
		serverTime: ServerTimeModel,
		viewport: ViewportView,
		topUIPanel: TopUIPanelBaseView,
		skillModels: Map<string, SkillModel>,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		totemModels: Map<string, TotemModel>,
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		galleryVideoModels: Map<string, GalleryVideoModel>,
		epicQuestCollectionsModels: Map<string, EpicQuestCollectionsModel>,
		summonModelsPool: SummonModelsPool,
		bankModel: BankModel,
		interactionManager: PIXI.interaction.InteractionManager,
		questTargetDuration: number,
		maxButtonValueData: MaxButtonValueData[],
		env: string,
		private readonly maintenanceModel: MaintenanceModel,
		buttonMaxCustomerCurrentValueId?: number,
	) {
		super();

		this.viewport = viewport;
		this.summonModelsPool = summonModelsPool;
		this.questTargetDuration = questTargetDuration;
		this.bankModel = bankModel;
		this.interactionManager = interactionManager;
		this.characterModels = characterModels;
		this.upgradeModels = upgradeModels;
		this.totemModels = totemModels;
		this.skillModels = skillModels;
		this.boostModels = boostModels;
		this.farewellBoostModel = farewellBoostModel;
		this.galleryVideoModels = galleryVideoModels;
		this.epicQuestCollectionsModels = epicQuestCollectionsModels;

		this.viewport.on(ViewportView.EVENT_ZOOM_IN_STARTED, this.onZoomInStarted, this);
		this.viewport.on(ViewportView.EVENT_ZOOM_OUT_STARTED, this.onZoomOutStarted, this);

		this.incomeAnimationContainer = new PIXI.Container();

		this.topUIPanelBase = topUIPanel;
		this.topUIPanelBase.on(LevelTopUIPanelView.EVENT_SHOW_HINT_SOFT_MONEY_PANEL_VIEW, (arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
			this.emit(GameUIBaseView.EVENT_SHOW_HINT, HintTypes.SOFT_MONEY_PANEL, { arrowPosLocal, origin });
		}, this);
		this.topUIPanelBase.on(
			LevelTopUIPanelView.EVENT_SHOW_HINT_PRESTIGE_MONEY_PANEL_VIEW,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(GameUIBaseView.EVENT_SHOW_HINT, HintTypes.PRESTIGE_MONEY_PANEL, { arrowPosLocal, origin });
			},
			this,
		);
		this.topUIPanelBase.on(
			LevelTopUIPanelView.EVENT_SHOW_HINT_HARD_MONEY_PANEL_VIEW,
			(arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(GameUIBaseView.EVENT_SHOW_HINT, HintTypes.HARD_MONEY_PANEL, { arrowPosLocal, origin });
			},
			this,
		);
		this.topUIPanelBase.on(
			LevelTopUIPanelView.EVENT_BUTTON_SETTINGS_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_SETTINGS_CLICK),
			this,
		);
		this.topUIPanelBase.on(
			LevelTopUIPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);

		this.collectionButton = new ButtonCollectionView(
			Array.from(this.galleryVideoModels.values()),
			Array.from(this.epicQuestCollectionsModels.values()),
			this.summonModelsPool,
		);
		this.collectionButton.position.set(147, 937);
		this.collectionButton.on(
			ButtonCollectionView.EVENT_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_COLLECTION_CLICK),
			this,
		);

		this.collectionFade = new ButtonFadeView();
		this.collectionFade.setTransform(this.collectionButton.x - 70, 880);

		this.bankButton = new ButtonBankView(
			bankModel,
			serverTime,
		);
		this.bankButton.position.set(1780, 937);
		this.bankButton.on(
			ButtonBankView.EVENT_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_BANK_CLICK),
			this,
		);

		this.bankFade = new ButtonFadeView();
		this.bankFade.setTransform(this.bankButton.x + 70, 880);

		this.buttonMaxCustomersView = new ButtonMaxCustomersView(
			maxButtonValueData,
			buttonMaxCustomerCurrentValueId,
		);
		this.buttonMaxCustomersView.position.set(1721, 108);

		this.maintenancePanel = new MaintenanceTimerView(this.maintenanceModel);
		this.maintenancePanel.position.set(940, 118);
		this.maintenancePanel.visible = false;

		this.maintenanceModel.on(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING, this.showMaintenanceTimer, this);
		this.maintenanceModel.on(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING_END, this.hideMaintenanceTimer, this);

		this.hasOpenedSkill = Array.from(this.skillModels.values()).some(model => model.isOpened());
		if (!this.hasOpenedSkill) {
			this.skillModels.forEach(model => model.once(SkillModel.EVENT_SKILL_OPENED, this.onSomeSkillOpened, this));
		}

		this.skillsPanelView = new SkillsPanelView(
			this.skillModels,
			this.characterModels,
		);
		this.skillsPanelView.position.set(960, 1080);
		this.skillsPanelView.on(
			SkillsPanelView.EVENT_SHOW_HINT_SKILL,
			(skill: SkillModel, character: CharacterModel, arrowPosLocal: PIXI.Point, origin: PIXI.DisplayObject) => {
				this.emit(GameUIBaseView.EVENT_SHOW_HINT, HintTypes.SKILL_HINT, {
					skill: skill.getKey(),
					character: character.getKey(),
					arrowPosLocal,
					origin,
				});
			},
			this,
		);

		const skillGirls = new GameUISkillGirlsView(this.skillModels);

		this.bankOfferLinesUIView = new BankOfferLinesUIView();
		this.bankOfferLinesUIView.position.set(1845, 0);
		this.bankOfferLinesUIView.on(
			BankOfferLinesUIView.EVENT_CLICK,
			(model: BankOfferModel) => this.emit(GameUIBaseView.EVENT_BUTTON_BANK_OFFER_LINE_CLICK, model),
			this,
		);

		this.questLinesView = new QuestLinesUIView();
		this.questLinesView.x = 140;
		this.questLinesView.on(
			QuestLinesUIView.EVENT_CLICK,
			(quest: AbstractQuest) => this.emit(GameUIBaseView.EVENT_BUTTON_QUEST_LINE_CLICK, quest),
			this,
		);

		const baseBoostModels: BaseBoostModel[] = [];
		baseBoostModels.push(...this.boostModels.values(), this.farewellBoostModel);

		this.boostView = new GameUIBoostView(baseBoostModels);
		this.boostView.on(
			GameUIBoostView.EVENT_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_BOOST_CLICK),
			this,
		);
		this.boostView.position.set(1588, 987);

		const buttonCheat = new ButtonCheatView(env, MODE_DEBUG);
		buttonCheat.position.set(1260, 38);
		buttonCheat.on(
			ButtonCheatView.EVENT_CLICK,
			() => this.emit(GameUIBaseView.EVENT_BUTTON_CHEAT_CLICK),
			this,
		);

		this.tweenGroup = new TWEEN.Group();
		this.tweenTicker = PIXI.ticker.shared;
		this.tweenTicker.add(this.updateTweenGroup, this);

		this.bankModel.on(BankModel.EVENT_UPDATED, this.onBankModelUpdated, this);

		this.mainUI = new PIXI.Container();
		this.mainUI.addChild(
			this.skillsPanelView as PIXI.DisplayObject,
			this.questLinesView,
			this.buttonMaxCustomersView,
			this.maintenancePanel,
			this.topUIPanelBase,
			this.bankFade,
			this.bankButton,
			this.collectionFade,
			this.collectionButton,
			this.boostView,
			this.bankOfferLinesUIView,
			skillGirls,
			this.incomeAnimationContainer,
		);

		this.addChild(
			this.mainUI,
		);

		if (MODE_DEBUG) {
			this.addChild(
				buttonCheat,
			);
		}
	}

	public init(): void {
		this.topUIPanelBase.initMoneyPanels();

		this.updateSkillsPanelViewVisible();
		this.updateBoostViewVisible();

		this.emit(GameUIBaseView.EVENT_INIT);
	}

	protected updateSkillsPanelViewVisible(): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		if (zoomedLocal) {
			this.skillsPanelView.visible = false;
		} else {
			this.skillsPanelView.visible = this.hasOpenedSkill;
		}
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.tweenTicker.lastTime);
	}

	private onSomeSkillOpened(): void {
		this.hasOpenedSkill = true;
		this.updateSkillsPanelViewVisible();
		this.skillModels.forEach(model => model.off(SkillModel.EVENT_SKILL_OPENED, this.onSomeSkillOpened, this, true));
	}

	private onBankModelUpdated(bankModel: BankModel): void {
		const bankOfferModelsWithActiveBankTabElement = bankModel.getBankOfferModelsWithActiveBankTabElement();
		const notExpiredBankOfferModels = ModelHelper.filterBankOfferModelsByTimeleft(bankOfferModelsWithActiveBankTabElement);
		const showOnMainMenuBankOfferModels = notExpiredBankOfferModels.filter(offerModel => offerModel.isShowOnMainWindow());
		this.bankOfferLinesUIView.updateBankOfferLines(showOnMainMenuBankOfferModels);
	}

	private onSomeBoostModelAcquired(): void {
		const boostModels = Array.from(this.boostModels.values());
		boostModels.forEach((model) => {
			model.off(BaseBoostModel.EVENT_ACQUIRED, this.onSomeBoostModelAcquired, this);
		});

		this.updateBoostViewVisible();
	}

	protected updateBoostViewVisible(): void {
		const zoomedLocal = this.viewport.isZooming()
			? !this.viewport.isZoomed()
			: this.viewport.isZoomed();

		this.boostView.visible = !zoomedLocal;
	}

	public setQuestLineInteractive(lineId: number, value: boolean): void {
		this.questLinesView.setLineInteractive(lineId, value);
	}

	public updateQuestLines(quests: AbstractQuest[]): void {
		const currentViewQuests = this.questLinesView.getCurrentQuests();
		for (let i = 0; i < currentViewQuests.length; i++) {
			const currentViewQuest = currentViewQuests[i];
			if (!quests.some(quest => quest === currentViewQuest)) {
				if (quests.length < currentViewQuests.length) {
					this.questLinesView.setLineCompleted(currentViewQuest.getLineId());
				} else {
					const newQuest = quests.filter(quest => quest.getLineId() === currentViewQuest.getLineId())[0];
					this.questLinesView.setLineQuest(newQuest);
				}
			}
		}
	}

	public showBoostLockedFlyText(): void {
		this.boostView.showLockedFlyText();
	}

	public playPrestigeMoneyIncomeAnimation(position: PIXI.Point, animationParent?: PIXI.Container): Promise<void> {
		return new Promise((resolve) => {
			const prestigeMoneyPanel = this.topUIPanelBase.getPrestigeMoneyPanel();
			const flyMoneyPosLocal = this.toLocal(prestigeMoneyPanel.getIconMoneyPosition(), prestigeMoneyPanel);
			const incomeMoneyAnimation = new PrestigeMoneyIncomeAnimationView(flyMoneyPosLocal);
			incomeMoneyAnimation.on(PrestigeMoneyIncomeAnimationView.EVENT_ANIMATION_COMPLETED, () => {
				prestigeMoneyPanel.pulse();
				prestigeMoneyPanel.setCanUpdate(true);

				resolve();
			}, this);

			const incomeAnimationContainer = animationParent || this.incomeAnimationContainer;
			incomeAnimationContainer.addChild(incomeMoneyAnimation);
			prestigeMoneyPanel.setCanUpdate(false);
			incomeMoneyAnimation.playAnimation(position);
		});
	}

	public playHardMoneyIncomeAnimation(position: PIXI.Point, animationParent?: PIXI.Container): Promise<void> {
		return new Promise((resolve) => {
			const moneyPanel = this.topUIPanelBase.getHardMoneyPanel();
			const flyMoneyPosLocal = this.toLocal(moneyPanel.getIconMoneyPosition(), moneyPanel);
			const incomeMoneyAnimation = new HardMoneyIncomeAnimationView(flyMoneyPosLocal);
			incomeMoneyAnimation.on(HardMoneyIncomeAnimationView.EVENT_ANIMATION_COMPLETED, () => {
				moneyPanel.pulse();
				moneyPanel.setCanUpdate(true);

				resolve();
			}, this);

			const incomeAnimationContainer = animationParent || this.incomeAnimationContainer;
			incomeAnimationContainer.addChild(incomeMoneyAnimation);
			moneyPanel.setCanUpdate(false);
			incomeMoneyAnimation.playAnimation(position);
		});
	}

	public playSoftMoneyIncomeAnimation(position: PIXI.Point, animationParent?: PIXI.Container): Promise<void> {
		const incomeAnimationContainer = animationParent || this.incomeAnimationContainer;
		const softMoneyPanel = this.topUIPanelBase.getSoftMoneyPanel();

		return softMoneyPanel.playSoftMoneyIncomeAnimation(position, this, incomeAnimationContainer);
	}

	public getIncomeAnimationContainer(): PIXI.Container {
		return this.incomeAnimationContainer;
	}

	protected onZoomInStarted(): void {
		this.topUIPanelBase.onZoomInStarted();

		new TWEEN.Tween({ alpha: 1 }, this.tweenGroup)
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.collectionButton.alpha = tweenTarget.alpha;
				this.collectionFade.alpha = tweenTarget.alpha;
				this.buttonMaxCustomersView.alpha = tweenTarget.alpha;
				this.bankButton.alpha = tweenTarget.alpha;
				this.bankFade.alpha = tweenTarget.alpha;
				this.questLinesView.alpha = tweenTarget.alpha;
				this.skillsPanelView.alpha = tweenTarget.alpha;
				this.boostView.alpha = tweenTarget.alpha;
				this.bankOfferLinesUIView.alpha = tweenTarget.alpha;
				this.maintenancePanel.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.collectionButton.visible = false;
				this.collectionFade.visible = false;
				this.buttonMaxCustomersView.visible = false;
				this.bankButton.visible = false;
				this.bankFade.visible = false;
				this.questLinesView.visible = false;
				this.skillsPanelView.visible = false;
				this.boostView.visible = false;
				this.bankOfferLinesUIView.visible = false;
				this.maintenancePanel.visible = false;
			})
			.start();
	}

	protected onZoomOutStarted(): void {
		this.topUIPanelBase.onZoomOutStarted();

		this.collectionButton.visible = true;
		this.collectionFade.visible = true;
		this.buttonMaxCustomersView.visible = true;
		this.bankButton.visible = true;
		this.bankFade.visible = true;
		this.questLinesView.visible = true;
		this.updateSkillsPanelViewVisible();
		this.updateBoostViewVisible();
		this.bankOfferLinesUIView.visible = true;
		this.updateMaintanenceTimerVisible();

		new TWEEN.Tween({ alpha: 0 }, this.tweenGroup)
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.collectionButton.alpha = tweenTarget.alpha;
				this.collectionFade.alpha = tweenTarget.alpha;
				this.buttonMaxCustomersView.alpha = tweenTarget.alpha;
				this.bankButton.alpha = tweenTarget.alpha;
				this.bankFade.alpha = tweenTarget.alpha;
				this.questLinesView.alpha = tweenTarget.alpha;
				this.skillsPanelView.alpha = tweenTarget.alpha;
				this.boostView.alpha = tweenTarget.alpha;
				this.bankOfferLinesUIView.alpha = tweenTarget.alpha;
				this.maintenancePanel.alpha = tweenTarget.alpha;
			})
			.start();
	}

	public showMaintenanceTimer(): void {
		this.maintenancePanel.startAppearingAnimation();
	}

	public hideMaintenanceTimer(): void {
		this.maintenancePanel.startHidingAnimation();
	}

	protected updateMaintanenceTimerVisible(): void {
		this.maintenancePanel.visible = this.maintenanceModel.isUpcomingMaintenance();
	}

	public hideMainUI(duration: number): void {
		new TWEEN.Tween(this.mainUI, this.tweenGroup)
			.to({ alpha: 0 }, duration)
			.start();
	}

	public showMainUI(duration: number): void {
		this.mainUI.interactive = false;
		new TWEEN.Tween(this.mainUI, this.tweenGroup)
			.to({ alpha: 1 }, duration)
			.start();
	}

	public getPrestigeMoneyPanel(): PrestigeMoneyPanelView {
		return this.topUIPanelBase.getPrestigeMoneyPanel();
	}

	public getSoftMoneyPanel(): SoftMoneyPanelView {
		return this.topUIPanelBase.getSoftMoneyPanel();
	}

	public getButtonCollectionContainer(): PIXI.Container {
		return this.collectionButton;
	}

	public getButtonBankContainer(): PIXI.Container {
		return this.bankButton;
	}

	public getButtonBoostContainer(): PIXI.Container {
		return this.boostView;
	}

	public getButtonMaxCustomersView(): ButtonMaxCustomersView {
		return this.buttonMaxCustomersView;
	}

	public isButtonMoneyPanelsPlusVisible(): boolean {
		return this.topUIPanelBase.getPrestigeMoneyPanel().isButtonPlusVisible();
	}

	public isCollectionUnlocked(): boolean {
		return this.collectionButton.isUnlocked();
	}

	public isBankUnlocked(): boolean {
		return this.bankButton.isUnlocked();
	}

	public isBankButtonVisible(): boolean {
		return this.bankButton.visible;
	}

	public getSkillsPanelView(): SkillsPanelView {
		return this.skillsPanelView;
	}

	public getBankOfferLinesUIView(): BankOfferLinesUIView {
		return this.bankOfferLinesUIView;
	}

	public getQuestLines(): QuestLinesUIView {
		return this.questLinesView;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.viewport.off(ViewportView.EVENT_ZOOM_IN_STARTED, this.onZoomInStarted, this);
		this.viewport.off(ViewportView.EVENT_ZOOM_OUT_STARTED, this.onZoomOutStarted, this);

		if (!this.hasOpenedSkill) {
			this.skillModels.forEach(model => model.off(SkillModel.EVENT_SKILL_OPENED, this.onSomeSkillOpened, this, true));
		}

		this.bankModel.off(BankModel.EVENT_UPDATED, this.onBankModelUpdated, this);

		this.maintenanceModel.off(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING, this.showMaintenanceTimer, this);
		this.maintenanceModel.off(MaintenanceModel.EVENT_MAINTENANCE_UPCOMING_END, this.hideMaintenanceTimer, this);

		this.tweenTicker.remove(this.updateTweenGroup, this);
		this.tweenGroup.removeAll();

		super.destroy(options);
	}
}
