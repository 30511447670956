import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class VideoCardPlayButtonView extends ButtonBaseView {
	constructor() {
		const playIcon = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['play_collection_btn']);
		playIcon.scale.set(1.5);

		super(playIcon);
	}

	public interruptAnimations(): void {
		this.interruptPointerUpAnimation();
		this.scale.set(1);
	}
}
