import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class ShowQuestTargetView extends PIXI.Container {
	private tapAnimation: PIXI.extras.AnimatedSprite;
	private glowAnimation: PIXI.Sprite;

	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;

	private showDuration: number;

	private target: PIXI.Container;

	constructor(duration: number, target: PIXI.Container) {
		super();

		this.target = target;

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;

		this.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

		this.tapAnimation = new PIXI.extras.AnimatedSprite(AssetsStorage.getResource('tutorialTapAnimation')
			.spritesheet
			.animations['tap']);
		this.tapAnimation.loop = true;
		this.tapAnimation.animationSpeed = 0.4;
		this.tapAnimation.anchor.set(0, 0.2);


		this.glowAnimation = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['tutorial_ring']);

		this.glowAnimation.alpha = 1;
		new TWEEN.Tween(this.glowAnimation, this.tweenGroup)
			.to({ alpha: 0 }, 750)
			.repeat(Infinity)
			.start();

		this.glowAnimation.scale.set(0);
		new TWEEN.Tween(this.glowAnimation.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 750)
			.repeat(Infinity)
			.start();

		this.tapAnimation.play();

		this.addChild(
			this.tapAnimation,
			this.glowAnimation,
		);

		this.once('added', this.pointToTarget, this);
		this.show(duration);
	}

	private pointToTarget(): void {
		const pos = this.parent.toLocal(this.target.position, this.target.parent);
		this.position.set(pos.x, pos.y);
	}

	private show(showDuration: number): void {
		this.showDuration = showDuration;
		this.ticker.add(this.update, this);
	}

	private update(): void {
		this.showDuration -= this.ticker.elapsedMS / 1000;
		if (this.showDuration <= 0) {
			this.destroy({ children: true });
		} else {
			this.tweenGroup.update(PIXI.ticker.shared.lastTime);
			this.pointToTarget();
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.tweenGroup.removeAll();
		this.ticker.remove(this.update, this);
		super.destroy(options);
	}
}
