import { LevelGameUIViewSetter } from '@interfaces/ViewSetters';
import { LevelGameUIView } from '@views/ui/main/LevelGameUIView';
import { TutorialStepLevelModel } from '@models/tutorialSteps/TutorialStepLevelModel';
import { TutorialStepTargetTypes } from '@src/types/TutorialStepTargetTypes';
import { TutorialStepWindowViewTargetBaseDispatcher } from './TutorialStepWindowViewTargetBaseDispatcher';

export class TutorialStepWindowViewTargetLevelDispatcher
	extends TutorialStepWindowViewTargetBaseDispatcher implements LevelGameUIViewSetter {
	private gameUI: LevelGameUIView;

	public setLevelGameUIView(view: LevelGameUIView): void {
		this.gameUI = view;
	}

	public dispatchViewTarget(tutorialStep: TutorialStepLevelModel): PIXI.Container {
		const targetType = tutorialStep.getTargetType();
		switch (targetType) {
			case TutorialStepTargetTypes.BUTTON_GO_TO_NEXT_LEVEL: {
				return this.dispatchTargetButtonGoToNextLevel();
			}
			case TutorialStepTargetTypes.LEVEL_PROGRESS_BAR: {
				return this.dispatchTargetLevelProgressBar();
			}

			case TutorialStepTargetTypes.BUTTON_EVENT: {
				return this.dispathTargetButtonEvent();
			}

			default:
				return super.dispatchViewTarget(tutorialStep);
		}
	}

	private dispatchTargetButtonGoToNextLevel(): PIXI.Container {
		const targetButton = this.gameUI.getGoToNextLevelButtonContainer();
		return targetButton;
	}

	private dispatchTargetLevelProgressBar(): PIXI.Container {
		const targetButton = this.gameUI.getLevelProgressBarContainer();
		return targetButton;
	}

	private dispathTargetButtonEvent(): PIXI.Container {
		const targetButton = this.gameUI.getGoToEventButtonContainer();
		return targetButton;
	}
}
