import { EventLevelModel, EventLevelStateType } from '@models/level/EventLevelModel';
import { SummonSavesModel } from '@models/SummonSavesModel';

export class ModelWriterEventSummonSavesModel {
	constructor(
		private readonly eventLevelModel: EventLevelModel,
		private readonly summonSavesModel: SummonSavesModel,
	) {
		this.eventLevelModel.on(EventLevelModel.EVENT_INITED, this.onEventInited, this);
	}

	private onEventInited(): void {
		// Reset local save data for the next event
		if (this.eventLevelModel.getState() === EventLevelStateType.DEFAULT) {
			this.summonSavesModel.resetLastApplyFreePriceTimestamp();
		}
	}
}
