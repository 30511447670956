import { SummonSaveData } from '@src/types/SaveTypes';

export class SummonSavesModel extends PIXI.utils.EventEmitter {
	private lastApplyTimes: Map<string, number>;
	private applyTimes: Map<string, number>;
	private lastApplyFreePriceTime: number;

	constructor(saveData: SummonSaveData) {
		super();

		this.lastApplyFreePriceTime = saveData.lastApplyFreePriceTime || 0;

		this.lastApplyTimes = new Map();
		Object.keys(saveData.lastApplyTimes || []).forEach(key => {
			const value = saveData.lastApplyTimes[key] || 0;
			this.lastApplyTimes.set(key, value);
		});

		this.applyTimes = new Map();
		Object.keys(saveData.applyTimes || []).forEach(key => {
			const value = saveData.applyTimes[key];
			this.applyTimes.set(key, value);
		});
	}

	public setApplied(
		key: string,
		timestamp: number,
		isFreePrice: boolean,
	): void {
		this.applyTimes.set(
			key,
			(this.applyTimes.get(key) || 0) + 1,
		);

		if (isFreePrice) {
			this.lastApplyFreePriceTime = timestamp;
		} else {
			this.lastApplyTimes.set(
				key,
				timestamp,
			);
		}
	}

	public getLastApplyFreePriceTimestamp(): number {
		return this.lastApplyFreePriceTime;
	}

	public resetLastApplyFreePriceTimestamp(): void {
		this.lastApplyFreePriceTime = 0;
	}

	public getLastApplyTimestamp(key: string): number {
		return this.lastApplyTimes.get(key);
	}

	public getApplyTimes(key: string): number {
		return this.applyTimes.get(key) || 0;
	}

	public isApplied(key: string): boolean {
		return this.applyTimes?.get(key) > 0;
	}
}
