import { AssetsStorage } from '@main/AssetsStorage';
import * as TWEEN from '@tweenjs/tween.js';

export class SummonFlashAnimationView extends PIXI.Container {
	private readonly wave: PIXI.Sprite;
	private tweenGroup: TWEEN.Group;
	private ticker: PIXI.ticker.Ticker;
	private readonly scaleTo: number;
	private readonly duration: number;

	constructor(spriteNumber: number = 1, color: number = 0xffffff, scaleTo: number = 5, duration: number = 400) {
		super();

		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		this.scaleTo = scaleTo;
		this.duration = duration;
		this.tweenGroup = new TWEEN.Group();
		this.ticker = PIXI.ticker.shared;

		this.wave = new PIXI.Sprite(fxAtlas[`summon_flash${spriteNumber}`]);
		this.wave.blendMode = PIXI.BLEND_MODES.ADD;
		this.wave.tint = color;
		this.wave.scale.set(0);
		this.wave.alpha = 1;

		this.addChild(
			this.wave,
		);

		this.x = this.width / 2;
		this.y = this.height / 2;
	}

	private update(): void {
		this.tweenGroup.update(PIXI.ticker.shared.lastTime);
	}

	public start(): void {
		new TWEEN.Tween(this.wave.scale, this.tweenGroup)
			.to({ x: this.scaleTo, y: this.scaleTo }, this.duration)
			.onComplete(() => {
				this.destroy();
			})
			.start();
		new TWEEN.Tween(this.wave, this.tweenGroup)
			.to({ alpha: 0 }, this.duration)
			.onComplete(() => {
				this.tweenGroup.removeAll();
				this.destroy();
			})
			.start();
		this.ticker.add(this.update, this);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.ticker.remove(this.update);
		super.destroy(options);
	}
}
