import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter60007View } from '@views/characters/events/event6/EventCharacter60007View';
import { EventCharacter60008View } from '@views/characters/events/event6/EventCharacter60008View';
import { EventCharacter60009View } from '@views/characters/events/event6/EventCharacter60009View';

export class EventBusiness60003View extends BusinessBaseView {
	constructor() {
		const eventCharacter60007View = new EventCharacter60007View();
		const eventCharacter60008View = new EventCharacter60008View();
		const eventCharacter60009View = new EventCharacter60009View();

		super('business60003', [eventCharacter60007View, eventCharacter60008View, eventCharacter60009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b6_decor2']).setTransform(-2, 0, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(-229, -20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b6_decor1']).setTransform(229, -20, -0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			new PIXI.Sprite(decorationAtlas['b6_decor3']).setTransform(252, -37, 0.5, 0.5) as PIXI.Sprite,
		);

		this.addChild(
			eventCharacter60007View,
			eventCharacter60008View as PIXI.DisplayObject,
			eventCharacter60009View,
			...this.decorations,
		);
	}
}
