import { BaseAction } from '../BaseAction';

export class EventLevelStartedAction extends BaseAction {
	constructor(key: string) {
		super('EventStart');

		// eslint-disable-next-line @typescript-eslint/naming-convention
		this.data.event_id = key;
	}
}
