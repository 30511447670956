import { AbstractQuest } from '@models/quests/AbstractQuest';
import { QuestRestoreStateData } from '@src/types/SaveTypes';
import { BusinessModel } from '@models/BusinessModel';
import { QuestTypes } from '@src/types/QuestTypes';

export type QuestGetCustomersMultiplierType =
	QuestTypes.GET_CUSTOMERS_MULTIPLIER |
	QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_ZERO |
	QuestTypes.GET_CUSTOMERS_MULTIPLIER_TARGET_FROM_CURRENT;

export class QuestGetCustomersMultiplier extends AbstractQuest {
	private readonly businessModels: BusinessModel[];
	private readonly targetAmount: number;

	private currentAmount: number;

	constructor(
		questKey: string,
		businessModels: BusinessModel[],
		amount: number,
		questType: QuestGetCustomersMultiplierType,
		lineId: number,
		trackProgressFromZero: boolean = true,
		mainWindowViewParameters: string[],
		time?: number,
	) {
		super(
			questKey,
			questType,
			lineId,
			mainWindowViewParameters,
			time,
		);

		this.businessModels = businessModels;

		if (trackProgressFromZero) {
			this.currentAmount = 0;
		} else {
			this.currentAmount = this.businessModels
				.map(m => m.getCustomerMultiplierIndex())
				.reduce((a, b) => a + b);
		}

		this.targetAmount = amount;

		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.trackProgressStart();
		}
	}

	public restoreSavedState(saveData: QuestRestoreStateData): void {
		this.currentAmount = Number(saveData.progress);
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		}
	}

	public getCurrentProgressValue(): string {
		return this.isCompleted() ? this.getTotalProgressValue() : this.currentAmount.toString();
	}

	public getTotalProgressValue(): string {
		return this.targetAmount.toString();
	}

	public getTargetAmount(): number {
		return this.targetAmount;
	}

	public getProgressRatio(): number {
		return this.completed ? 1 : this.currentAmount / this.targetAmount;
	}

	public getTargetKeys(): string[] {
		return this.businessModels.map(m => m.getKey());
	}

	private advanceProgress(): void {
		this.currentAmount += 1;
		if (this.currentAmount >= this.targetAmount) {
			this.complete();
		} else {
			this.emit(AbstractQuest.EVENT_PROGRESS, this);
		}
	}

	protected trackProgressStop(): void {
		this.businessModels.forEach(model => {
			model.off(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.advanceProgress, this, false);
		});
	}

	protected trackProgressStart(): void {
		this.businessModels.forEach(model => {
			model.on(BusinessModel.EVENT_NEW_CUSTOMERS_MULTIPLIER, this.advanceProgress, this);
		});
	}
}
