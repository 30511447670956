import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import { Emitter } from 'pixi-particles';
import { BankParticleConfig } from '../../animations/BankParticleConfig';
import { BankTabElementWithOfferType } from '@models/bank/BankModel';
import { BankOfferModel } from '@models/bank/BankOfferModel';
import { BLEND_MODES } from 'pixi.js';
import LocalizationStorage from '@main/LocalizationStorage';
import { BankBundleModel } from '@models/bank/BankBundleModel';
import { TextUtils } from '@src/utils/TextUtils';
import { SpriteHighlightAnimationView } from '@views/components/SpriteHighlightAnimationView';
import { BankOfferWindowPackBodyView } from './BankOfferWindowPackBodyView';
import { BankOfferButtonType, BankOfferRewardsContainerType } from '../BankOfferWindowBodyView';

export class BankOfferWindowPackBody4View extends BankOfferWindowPackBodyView {
	private readonly localizationStorage: LocalizationStorage;

	private readonly animationEmitters: Emitter[];
	private readonly fxAtlas: PIXI.loaders.TextureDictionary;
	private readonly timerLabel: PIXI.extras.BitmapText;

	constructor(
		bankOfferModel: BankOfferModel,
		bankTabElementWithOffer: BankTabElementWithOfferType,
		getBuyButtonHandler: BankOfferButtonType,
		getRewardMiniCardsContainerHandler: BankOfferRewardsContainerType,
	) {
		super(bankTabElementWithOffer);

		this.animationEmitters = [];

		this.fxAtlas = AssetsStorage.getAtlas('fxAtlas');
		const characterAtlas = AssetsStorage.getAtlas('characterCardAtlas');

		this.localizationStorage = LocalizationStorage.getInstance();
		const localizationKeys = bankOfferModel.getLocalizationKeys();


		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['offer_gold_bg'], 4, 4, 4, 4);
		bg.width = 1392;
		bg.height = 666;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.position.set(-3, 4);
		bg.interactive = true;

		const bgTint = new PIXI.Graphics();
		bgTint.beginFill(0x000000);
		bgTint.drawRect(241, 240, 895, 185);
		bgTint.endFill();
		bgTint.pivot.set(bgTint.width / 2, bgTint.height / 2);
		bgTint.alpha = 0.3;

		const cardMask = new PIXI.Graphics();
		cardMask.beginFill(0x000000);
		cardMask.drawRect(-451, 4, 486, 658);
		cardMask.endFill();
		cardMask.pivot.set(cardMask.width / 2, cardMask.height / 2);
		cardMask.alpha = 0.3;

		const cardGirl = new PIXI.Sprite(characterAtlas[`${bankOfferModel.getCharacterLeftKey()}_card1`]);
		cardGirl.position.set(-451, 4);
		cardGirl.mask = cardMask;

		const titleText = new TextField(
			this.localizationStorage.getLocalizedString(localizationKeys.title),
			{ font: '68px wendyOneGold', align: 'center' },
			700,
			200,
		);
		titleText.anchor = 0.5;
		titleText.position.set(242, -253);

		const timerBg = new PIXI.Graphics();
		timerBg.beginFill(0x000000);
		timerBg.drawPolygon([
			new PIXI.Point(-154, -27),
			new PIXI.Point(154, -27),
			new PIXI.Point(165, 27),
			new PIXI.Point(-165, 27),
		]);

		const timerBgGradient = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['red_panel'], 14, 0, 14, 0);
		timerBgGradient.width = 360;
		timerBgGradient.height = 60;
		timerBgGradient.pivot.set(timerBgGradient.width / 2, timerBgGradient.height / 2);
		timerBgGradient.mask = timerBg;

		this.timerLabel = new PIXI.extras.BitmapText('', {
			font: '40px wendyOneGold',
		});
		this.timerLabel.anchor = 0.5;

		const timerContainer = new PIXI.Container();
		timerContainer.position.set(-451, 306);

		const animationsContainer = new PIXI.Container();
		animationsContainer.position.set(20, -100);
		animationsContainer.scale.y = 0.7;
		animationsContainer.scale.x = 1.5;

		const glowLightsEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['party_button_glow4']],
			BankParticleConfig.getRecharcgeGlow2(),
		);
		glowLightsEmitter.autoUpdate = true;
		this.animationEmitters.push(glowLightsEmitter);

		const glowEmitter = new Emitter(
			animationsContainer,
			[this.fxAtlas['skill_activ_glow1']],
			BankParticleConfig.getRecharcgeGlow(),
		);
		glowEmitter.autoUpdate = true;
		this.animationEmitters.push(glowEmitter);

		const glowDecor = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		glowDecor.width = 1390;
		glowDecor.pivot.set(glowDecor.width / 2, glowDecor.height / 2);
		glowDecor.position.set(0, 334);
		glowDecor.blendMode = BLEND_MODES.ADD;

		const glowDecor2 = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		glowDecor2.width = 760;
		glowDecor2.pivot.set(glowDecor2.width / 2, glowDecor2.height / 2);
		glowDecor2.position.set(400, -330);
		glowDecor2.blendMode = BLEND_MODES.ADD;

		const glowDecor3 = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line_vertical'], 0, 0, 0, 0);
		glowDecor3.height = 680;
		glowDecor3.pivot.set(glowDecor3.width / 2, glowDecor3.height / 2);
		glowDecor3.position.set(-211, 10);
		glowDecor3.blendMode = BLEND_MODES.ADD;
		glowDecor3.alpha = 0.5;

		const glowDecor4 = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['glow_line'], 0, 0, 0, 0);
		glowDecor4.width = 760;
		glowDecor4.pivot.set(glowDecor4.width / 2, glowDecor4.height / 2);
		glowDecor4.position.set(-400, -330);
		glowDecor4.blendMode = BLEND_MODES.ADD;
		glowDecor4.alpha = 0.8;

		const buyButton = getBuyButtonHandler(
			449,
			103,
			bankTabElementWithOffer,
		);
		buyButton.position.set(242, 239);

		const rewardsContainer = getRewardMiniCardsContainerHandler(20, bankTabElementWithOffer, 0.95);
		rewardsContainer.position.set(242, -91);

		let rewardCount: number = 0;
		let rewardId: string = '';
		if (bankTabElementWithOffer instanceof BankBundleModel) {
			rewardCount = bankTabElementWithOffer.getExtraRewardCount();
			rewardId = bankTabElementWithOffer.getExtraRewardId();
		}

		timerContainer.addChild(
			timerBg,
			timerBgGradient,
			this.timerLabel as PIXI.DisplayObject,
		);

		this.addChild(
			animationsContainer,
			bg,
			bgTint,
			cardGirl,
			cardMask,
			titleText as PIXI.DisplayObject,
			buyButton,
			timerContainer,
			rewardsContainer,
			glowDecor,
			glowDecor2,
			glowDecor3,
			glowDecor4,
		);

		if (rewardCount > 0) {
			const specialBonusContainer: PIXI.Container = this.createSpecialBonus(rewardCount, rewardId);
			this.addChild(specialBonusContainer);
			rewardsContainer.position.set(242, -81);
		} else {
			rewardsContainer.position.set(242, -41);
		}

		if (bankTabElementWithOffer.getMostPopularLocaleKey()) {
			const stickerPopular = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopular.anchor.set(0.5);
			stickerPopular.position.set(670, -305);
			stickerPopular.scale.set(1.3);
			const stickerPopularText = new TextField(
				this.localizationStorage.getLocalizedString(bankTabElementWithOffer.getMostPopularLocaleKey()),
				{ font: '28px wendyOne', align: 'center', tint: 0xfff78f },
				120,
				80,
			);
			stickerPopularText.anchor = 0.5;
			stickerPopularText.rotation = 0.47;
			stickerPopularText.position.set(stickerPopular.x, stickerPopular.y);

			const stickerPopularMask = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['limited_icon']);
			stickerPopularMask.anchor.set(0.5);
			stickerPopularMask.scale.set(1.3);
			stickerPopularMask.tint = 0xffffff;
			const stickerPopularHightlight = new SpriteHighlightAnimationView(stickerPopularMask, 500, false);
			stickerPopularHightlight.position.set(stickerPopular.x, stickerPopular.y);

			this.addChild(
				stickerPopular,
				stickerPopularHightlight,
				stickerPopularText as PIXI.DisplayObject,
			);
			stickerPopularHightlight.start();
		}
	}

	public setTimeLabelText(text: string): void {
		this.timerLabel.text = text;
	}

	private createSpecialBonus(rewardCount: number, rewardId: string): PIXI.Container {
		const specialBonusContainer = new PIXI.Container();
		specialBonusContainer.position.set(251, 67);

		const specialBonusBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['beige_panel'], 14, 0, 14, 0);
		specialBonusBg.width = 770;
		specialBonusBg.pivot.set(specialBonusBg.width / 2, specialBonusBg.height / 2);

		const specialBonusIcon = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')[`i_${rewardId}_big`]);
		specialBonusIcon.x = -357;
		specialBonusIcon.scale.set(0.75);

		let specialBonusString: string = this.localizationStorage.getLocalizedString(this.getSpecialBonusLocalizationKey())
			.replace('{{count}}', rewardCount.toString());

		specialBonusString = TextUtils.getWordEnding(this.localizationStorage.getLanguage(), rewardCount, specialBonusString);

		const specialBonusText = new TextField(
			`+${specialBonusString}`,
			{ font: '39px wendyOne', tint: 0x000000 },
			590,
			50,
		);
		specialBonusText.anchor = 0.5;
		specialBonusText.x = -9;

		specialBonusContainer.addChild(
			specialBonusBg,
			specialBonusIcon as PIXI.DisplayObject,
			specialBonusText,
		);

		return specialBonusContainer;
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.animationEmitters.forEach((emitter) => {
			emitter.destroy();
		});

		super.destroy(options);
	}
}
