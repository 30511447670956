import { BusinessBaseView } from '../ui/BusinessBaseView';
import { Character1View } from '@views/characters/main/Character1View';
import { Character2View } from '@views/characters/main/Character2View';
import { Character3View } from '@views/characters/main/Character3View';
import { AssetsStorage } from '@main/AssetsStorage';

export class Business1View extends BusinessBaseView {
	constructor() {
		const character1 = new Character1View();
		const character2 = new Character2View();
		const character3 = new Character3View();
		character3.once(Character3View.EVENT_SHOWN, () => character1.destroy());

		super('business1', [character1, character2, character3]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.furniture.set('furniture1', new PIXI.Sprite(decorationAtlas['b1_furniture1']).setTransform(-218, -28, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture2', new PIXI.Sprite(decorationAtlas['b1_furniture2']).setTransform(-314, 53, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture3', new PIXI.Sprite(decorationAtlas['b1_furniture3']).setTransform(174, -33, 0.5, 0.5) as PIXI.Sprite);
		this.furniture.set('furniture4', new PIXI.Sprite(decorationAtlas['b1_furniture4']).setTransform(-142, -3, 0.5, 0.5) as PIXI.Sprite);

		this.addChild(
			character1 as PIXI.DisplayObject,
			character2 as PIXI.DisplayObject,
			character3,
		);
	}
}
