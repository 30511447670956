
import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { QuestConfigBase } from '@configs/quests/QuestConfigBase';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { QuestFactory } from '@src/initializers/QuestFactory';
import { LootboxFactory } from '@src/initializers/LootboxFactory';
import { LevelModel } from '@models/level/LevelModel';
import { QuestsLoaderBase } from './QuestsLoaderBase';
import { QuestLineModel } from '@models/quests/QuestLineModel';
import { AbstractQuest } from '@models/quests/AbstractQuest';

export class QuestsLoader extends QuestsLoaderBase {
	constructor(
		configs: Map<string, QuestConfigBase>,
		networkRequestSender: NetworkRequestSender,
		questFactory: QuestFactory,
		lootboxFactory: LootboxFactory,
		questLinesModel: QuestLinesModel,
		private readonly levelModel: LevelModel,
	) {
		super(
			configs,
			networkRequestSender,
			questFactory,
			lootboxFactory,
			questLinesModel,
		);
	}

	public finishQuest(quest: AbstractQuest): void {
		super.finishQuest(quest);
		this.levelModel.updateProgress();
	}

	protected canCreateNextQuest(
		line: QuestLineModel,
	): boolean {
		return super.canCreateNextQuest(line) && !this.levelModel.isProgressCompleted();
	}
}
