import { EventLevelConfigNames } from '@src/types/EventLevelConfigNames';
import { SummonConfigsLoaderBase } from './SummonConfigsLoaderBase';

export class SummonEventLevelConfigsLoader extends SummonConfigsLoaderBase {
	public async loadConfigs(): Promise<void> {
		const result = await this.networkRequestSender.sendGetConfigs([
			EventLevelConfigNames.CONFIG_SUMMON,
		]);

		this.summonConfigsParser.parseConfigSet(
			result[EventLevelConfigNames.CONFIG_SUMMON],
		);
	}
}
