import { TextAreaInputView, TextAreaInputParams } from './TextAreaInputView';
import { EmailAtSignReguireValidator } from '@src/utils/validators/email/EmailAtSignRequiredValidator';
import { EmailNamePartMustNotBeNullValidator } from '@src/utils/validators/email/EmailNamePartMustBeNotNullValidator';
import { EmailNamePartBadSymbolsValidator } from '@src/utils/validators/email/EmailNamePartBadSymbolsValidator';
import { EmailDomainPartMustBeNotNullValidator } from '@src/utils/validators/email/EmailDomainPartMustBeNotNullValidator';
import { EmailDomainPartBadSymbolsValidator } from '@src/utils/validators/email/EmailDomainPartBadySymbolsValudator';
import { StringRegExpValidation } from '@src/utils/validators/StringRegExpValidation';

export class EmailAreaInputView extends TextAreaInputView {
	// eslint-disable-next-line max-len
	private static readonly EMAIL_REG_EXP_VALIDATION = /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/;

	constructor(params: TextAreaInputParams) {
		super(params);

		const validator = new EmailAtSignReguireValidator('#email_validation_at_sign');

		validator
			.setNext(new EmailNamePartMustNotBeNullValidator('#email_validation_name_part_not_null'))
			.setNext(new EmailNamePartBadSymbolsValidator('#email_validation_name_bad_symbol'))
			.setNext(new EmailDomainPartMustBeNotNullValidator('#email_validation_domain_part_not_null'))
			.setNext(new EmailDomainPartBadSymbolsValidator('#email_validation_domain_part_bad_symbol'))
			.setNext(new StringRegExpValidation('#email_validation_error', EmailAreaInputView.EMAIL_REG_EXP_VALIDATION));

		this.setValidator(validator);
		this.on(TextAreaInputView.EVENT_INPUT, this.onInput, this);
	}

	private onInput(): void {
		this.setText(this.getText().toLowerCase());
		this.invalidInput.visible = false;
		this.redStroke.visible = !this.isValid();
	}

	public isValid(): boolean {
		const message = this.validator.validate(this.getText());

		if (message) {
			this.setTextInvalidInput(message);
			this.onInvalidInput();
		}

		return message === undefined;
	}
}
