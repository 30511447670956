import { LevelBackground, LevelBaseView } from '../levels/LevelBaseView';
import { EventBusiness30001View } from '@views/businesses/event3Businesses/eventBuilding1/EventBusiness30001View';
import { EventBusiness30002View } from '@views/businesses/event3Businesses/eventBuilding1/EventBusiness30002View';
import { EventBusiness30003View } from '@views/businesses/event3Businesses/eventBuilding1/EventBusiness30003View';
import { EventBusiness30004View } from '@views/businesses/event3Businesses/eventBuilding2/EventBusiness30004View';
import { EventBusiness30005View } from '@views/businesses/event3Businesses/eventBuilding2/EventBusiness30005View';
import { EventBusiness30006View } from '@views/businesses/event3Businesses/eventBuilding2/EventBusiness30006View';
import { TimesOfDayTypes } from '@src/types/TimesOfDayTypes';
import { GameConstants } from '@src/utils/GameConstants';
import { AssetsStorage } from '@src/main/AssetsStorage';

export class EventLevel3View extends LevelBaseView {
	private static readonly LEVEL_WIDTH: number = 2380;

	protected create(background: string): void {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				this.createEveningLevel();
				break;
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createBackground(background: string): LevelBackground {
		switch (background) {
			case TimesOfDayTypes.EVENING:
				return this.createEveningBackground();
			default:
				throw new Error(`Unsupported background: ${background}`);
		}
	}

	public static createEveningBackground(): LevelBackground {
		const backgroundContainer = new LevelBackground();

		const levelBgAtlas = AssetsStorage.getAtlas('italyEveningAtlas');

		const sky = new PIXI.Sprite(levelBgAtlas['italy_evening/sky']);
		sky.anchor.set(0, 1);
		sky.setTransform(0, GameConstants.GAME_HEIGHT, 1200);

		const road = new PIXI.extras.TilingSprite(levelBgAtlas['italy_evening/road'], EventLevel3View.LEVEL_WIDTH, 155);
		road.anchor.set(0, 1);
		road.y = GameConstants.GAME_HEIGHT;

		const back = new PIXI.Container();
		back.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/house5']).setTransform(-55, 656),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house4']).setTransform(919, 660),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(191, 764),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house1']).setTransform(1513, 618),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house2']).setTransform(2197, 622),
			new PIXI.Sprite(levelBgAtlas['italy_evening/house1']).setTransform(2740, 618),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor1']).setTransform(2080, 764),
		);

		backgroundContainer.addChild(
			sky,
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(512, 666),
			new PIXI.Sprite(levelBgAtlas['italy_evening/city1']).setTransform(1112, 666),
			road,
			back,
		);

		backgroundContainer.setParallaxTarget(back);

		return backgroundContainer;
	}

	private createEveningLevel(): void {
		const levelBgAtlas = AssetsStorage.getAtlas('italyEveningAtlas');

		this.background = EventLevel3View.createEveningBackground();
		this.addChild(this.background);

		const businesses = [
			new EventBusiness30001View(),
			new EventBusiness30002View(),
			new EventBusiness30003View(),
			new EventBusiness30004View(),
			new EventBusiness30005View(),
			new EventBusiness30006View(),
		];

		// BUILDING 1
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(1066, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(687, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(257, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(312, 539),
			businesses[0].setTransform(700, 807),
			businesses[1].setTransform(700, 522),
			businesses[2].setTransform(700, 237),
		);

		// BUILDING 2
		this.addChild(
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_right']).setTransform(1986, 546.5),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_top']).setTransform(1607, 102),
			new PIXI.Sprite(levelBgAtlas['italy_evening/shadow']).setTransform(1177, 965),
			new PIXI.Sprite(levelBgAtlas['italy_evening/building_left']).setTransform(1232, 539),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor4']).setTransform(1200, 400),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor3']).setTransform(1190, 545),
			new PIXI.Sprite(levelBgAtlas['italy_evening/decor2']).setTransform(1143, 945),
			businesses[3].setTransform(1620, 807),
			businesses[4].setTransform(1620, 522),
			businesses[5].setTransform(1620, 237),
		);
		this.initLevel(EventLevel3View.LEVEL_WIDTH, this.background.getParallaxTarget(), businesses);
	}

	// eslint-disable-next-line class-methods-use-this
	protected getPrePartyCharacterPosition(): PIXI.Point {
		return new PIXI.Point(1100, 853);
	}
}
