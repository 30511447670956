import { GameUIBaseView } from './GameUIBaseView';
import { ViewportView } from '@views/components/ViewportView';
import { SummonModelsPool } from '@models/SummonModelsPool';
import { BankModel } from '@models/bank/BankModel';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import HardMoneyModel from '@models/money/HardMoneyModel';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { SkillModel } from '@models/skills/SkillModel';
import { GameProfileModel } from '@models/GameProfileModel';
import { ServerTimeModel } from '@models/network/ServerTimeModel';
import { MaxButtonValueData } from '@configs/ConstantsConfig';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { BoostModel } from '@models/BoostModel';
import { EventLevelTopUIPanelView } from '../topUIPanel/EventLevelTopUIPanelView';
import { ReturnFromEventButtonView } from '../ReturnFromEventButtonView';
import * as TWEEN from '@tweenjs/tween.js';
import { QuestLinesModel } from '@models/quests/QuestLinesModel';
import { CardMiniViewFactory } from '../cardsMini/CardMiniViewFactory';
import { MainGameModels } from '@main/EventLevelInstance';
import { SandboxOperation } from '@src/utils/SandboxOperation';
import { AbstractReward } from '@interfaces/AbstractReward';
import { FarewellBoostModel } from '@models/FarewellBoostModel';
import { MaintenanceModel } from '@models/MaintenanceModel';

export class EventLevelGameUIView extends GameUIBaseView {
	public static readonly EVENT_BUTTON_RETURN_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CUP_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_GOAL_REWARD_CLICK: symbol = Symbol();

	private readonly questLinesModel: QuestLinesModel;
	private readonly eventTopUIPanel: EventLevelTopUIPanelView;

	private readonly returnFromEventButton: ReturnFromEventButtonView;

	constructor(
		viewport: ViewportView,
		serverTime: ServerTimeModel,
		eventLevelModel: EventLevelModel,
		gameProfileModel: GameProfileModel,
		questLinesModel: QuestLinesModel,
		softMoneyModel: SoftMoneyModel,
		hardMoneyModel: HardMoneyModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		summonModelsPool: SummonModelsPool,
		businessModels: Map<string, BusinessModel>,
		characterModels: Map<string, CharacterModel>,
		upgradeModels: Map<string, UpgradeModel>,
		skillModels: Map<string, SkillModel>,
		boostModels: Map<string, BoostModel>,
		farewellBoostModel: FarewellBoostModel,
		bankModel: BankModel,
		mainGameModels: MainGameModels,
		interactionManager: PIXI.interaction.InteractionManager,
		questTargetDuration: number,
		maxButtonValueData: MaxButtonValueData[],
		env: string,
		runInEpisodeAssetsSandbox: SandboxOperation,
		maintenanceModel: MaintenanceModel,
		buttonMaxCustomerCurrentValueId?: number,
	) {
		const mainGameCardMiniViewFactory = new CardMiniViewFactory(
			mainGameModels.characterModels,
			mainGameModels.upgradeModels,
			mainGameModels.totemModels,
			mainGameModels.boostModels,
			mainGameModels.timeskipModels,
		);
		const topUIPanel = new EventLevelTopUIPanelView(
			softMoneyModel,
			prestigeMoneyModel,
			hardMoneyModel,
			Array.from(businessModels.values()),
			skillModels,
			gameProfileModel,
			eventLevelModel,
			mainGameCardMiniViewFactory,
			runInEpisodeAssetsSandbox,
		);
		super(
			serverTime,
			viewport,
			topUIPanel,
			skillModels,
			characterModels,
			upgradeModels,
			new Map(),
			boostModels,
			farewellBoostModel,
			new Map(),
			new Map(),
			summonModelsPool,
			bankModel,
			interactionManager,
			questTargetDuration,
			maxButtonValueData,
			env,
			maintenanceModel,
			buttonMaxCustomerCurrentValueId,
		);

		this.eventTopUIPanel = topUIPanel;
		this.questLinesModel = questLinesModel;

		this.returnFromEventButton = new ReturnFromEventButtonView();
		this.returnFromEventButton.position.set(1840, 220);
		this.returnFromEventButton.on(
			ReturnFromEventButtonView.EVENT_CLICK,
			() => this.emit(EventLevelGameUIView.EVENT_BUTTON_RETURN_CLICK),
			this,
		);

		this.eventTopUIPanel.on(
			EventLevelTopUIPanelView.EVENT_BUTTON_CUP_CLICK,
			() => this.emit(EventLevelGameUIView.EVENT_BUTTON_CUP_CLICK),
			this,
		);
		this.eventTopUIPanel.on(
			EventLevelTopUIPanelView.EVENT_BUTTON_GOAL_REWARD_CLICK,
			() => this.emit(EventLevelGameUIView.EVENT_BUTTON_GOAL_REWARD_CLICK),
			this,
		);

		this.questLinesView.y = 203;
		this.bankOfferLinesUIView.y = 408;

		this.mainUI.addChild(
			this.returnFromEventButton,
		);
	}

	public init(): void {
		const quests = this.questLinesModel.getCurrentQuests();
		this.questLinesView.init(quests);

		super.init();
	}

	public setNewGoalRewardWithAnimation(
		reward: AbstractReward,
	): Promise<void> {
		return this.eventTopUIPanel.setNewGoalRewardWithAnimation(
			reward,
		);
	}

	protected onZoomOutStarted(): void {
		super.onZoomOutStarted();

		this.returnFromEventButton.visible = true;
		new TWEEN.Tween({ alpha: 0 }, this.tweenGroup)
			.to({ alpha: 1 }, 100)
			.onUpdate((tweenTarget) => {
				this.returnFromEventButton.alpha = tweenTarget.alpha;
			})
			.start();
	}

	protected onZoomInStarted(): void {
		super.onZoomInStarted();

		new TWEEN.Tween({ alpha: 1 }, this.tweenGroup)
			.to({ alpha: 0 }, 100)
			.onUpdate((tweenTarget) => {
				this.returnFromEventButton.alpha = tweenTarget.alpha;
			})
			.onComplete(() => {
				this.returnFromEventButton.visible = false;
			})
			.start();
	}

	public getButtonRankingWindowContainer(): PIXI.Container {
		return this.eventTopUIPanel.getButtonRankingWindowContainer();
	}

	public getEventGoalRewardIconContainer(): PIXI.Container {
		return this.eventTopUIPanel.getEventGoalRewardIconContainer();
	}

	public getEventIncomeProgressBarContainer(): PIXI.Container {
		return this.eventTopUIPanel.getEventIncomeProgressBarContainer();
	}
}
