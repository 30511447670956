import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter252010View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252010View';
import { EventCharacter252011View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252011View';
import { EventCharacter252012View } from '@views/characters/events/eventLevelWeekDay7/EventCharacter252012View';

export class EventBusiness252004View extends BusinessBaseView {
	constructor() {
		const eventCharacter252010View = new EventCharacter252010View();
		const eventCharacter252011View = new EventCharacter252011View();
		const eventCharacter252012View = new EventCharacter252012View();

		super('business252004', [eventCharacter252010View, eventCharacter252011View, eventCharacter252012View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b15_decor1']).setTransform(-100, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor2']).setTransform(-270, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor4']).setTransform(260, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor5']).setTransform(-3, -43.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b15_decor3']).setTransform(0, -30, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter252010View as PIXI.DisplayObject,
			eventCharacter252011View,
			eventCharacter252012View,
			new PIXI.Sprite(decorationAtlas['b15_decor6']).setTransform(190, 20, 0.5, 0.5) as PIXI.Sprite,
		);
	}
}
