import { CharacterModel } from '@models/CharacterModel';
import { SkillModel } from '@models/skills/SkillModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { UpgradeSlotView } from './UpgradeSlotView';
import { CharacterSlotView } from './CharacterSlotView';
import { CharacterSkillPairType } from '@src/types/CharacterSkillPairType';
import * as TWEEN from '@tweenjs/tween.js';
import SoftMoneyModel from '@models/money/SoftMoneyModel';
import { ModelHelper } from '@models/ModelHelper';
import { SlotView } from '@views/components/SlotView';
import { PromotableModel } from '@models/PromotableModel';
import { ScrollView } from '@views/components/ScrollView';
import { ScrollAxis } from '@src/types/ScrollViewTypes';

export class BusinessSlotsView extends PIXI.Container {
	public static readonly EVENT_SHOW_PROMOTE_UPGRADE: symbol = Symbol();
	public static readonly EVENT_SHOW_PROMOTE_CHARACTER: symbol = Symbol();
	public static readonly EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK: symbol = Symbol();

	private static readonly SLOT_WIDTH_SPACE = 64;

	private readonly slotViewsData: Map<string, SlotView<PromotableModel>>;
	private readonly upgradesContainer: PIXI.Container;

	constructor(
		private characterSkillPairs: CharacterSkillPairType[],
		private readonly upgrades: UpgradeModel[],
		private readonly softMoneyModel: SoftMoneyModel,
		private readonly currentLevel?: number,
	) {
		super();

		this.slotViewsData = new Map();
		this.upgradesContainer = this.initUpgradeSlots();

		const scrollView = this.createUpgradesScrollView();

		this.addChild(scrollView);

		this.initCharactersSlots();
	}

	public getSlot(key: string): SlotView<PromotableModel> {
		const slotViewData = this.slotViewsData.get(key);
		return slotViewData;
	}

	private initUpgradeSlots(): PIXI.Container {
		const upgradesContainer = new PIXI.Container();

		this.upgrades.forEach((upgrade, i) => {
			const slotView = new UpgradeSlotView(
				upgrade,
				this.softMoneyModel,
				this.currentLevel,
			);
			slotView.position.x = BusinessSlotsView.SLOT_WIDTH_SPACE * i;
			slotView.on(UpgradeSlotView.EVENT_ON_UPGRADE_CART_CLICK, () => this.onUpgradeSlotClick(slotView, upgrade), this);
			slotView.on(UpgradeSlotView.EVENT_BUTTON_BUY_CLICK, () => this.onUpgradeButtonBuyClick(slotView, upgrade), this);

			this.slotViewsData.set(upgrade.getKey(), slotView);
			upgradesContainer.addChild(slotView);
		});

		return upgradesContainer;
	}

	private initCharactersSlots(): void {
		for (let i = this.characterSkillPairs.length - 1; i >= 0; i--) {
			const { character, skill } = this.characterSkillPairs[i];
			const slotView = new CharacterSlotView(
				character,
				this.softMoneyModel,
				this.currentLevel,
				skill,
			);
			slotView.position.x = -35 + BusinessSlotsView.SLOT_WIDTH_SPACE * i;
			slotView.position.y = -32;
			slotView.on(CharacterSlotView.EVENT_ON_CHARACTER_CART_CLICK, () => this.onCharacterSlotClick(slotView, character, skill), this);
			slotView.on(CharacterSlotView.EVENT_BUTTON_BUY_CLICK, () => this.onCharacterButtonBuyClick(slotView, character), this);

			this.slotViewsData.set(character.getKey(), slotView);
			this.addChild(slotView);
		}
	}

	private onUpgradeButtonBuyClick(slotView: UpgradeSlotView, upgrade: UpgradeModel): void {
		if (!ModelHelper.isEnoughSoftMoneyForActivate(upgrade, this.softMoneyModel)) {
			slotView.showNotEnoughtMoneyFlyText();
		} else {
			this.emit(BusinessSlotsView.EVENT_BUTTON_UPGRADE_ACTIVATE_CLICK, upgrade);
		}
	}

	private onCharacterButtonBuyClick(slotView: CharacterSlotView, character: CharacterModel): void {
		if (!ModelHelper.isEnoughSoftMoneyForActivate(character, this.softMoneyModel)) {
			slotView.showNotEnoughtMoneyFlyText();
		} else {
			this.emit(BusinessSlotsView.EVENT_BUTTON_CHARACTER_ACTIVATE_CLICK, character);
		}
	}

	private onUpgradeSlotClick(slotView: UpgradeSlotView, upgrade: UpgradeModel): void {
		this.emit(
			BusinessSlotsView.EVENT_SHOW_PROMOTE_UPGRADE,
			slotView,
			upgrade.getKey(),
		);
	}

	private onCharacterSlotClick(slotView: CharacterSlotView, character: CharacterModel, skill?: SkillModel): void {
		this.emit(
			BusinessSlotsView.EVENT_SHOW_PROMOTE_CHARACTER,
			slotView,
			character.getKey(),
			skill?.getKey(),
		);
	}

	public startAppearingAnimation(): void {
		const tweenSlot = (slotKey: string, slotIndex: number): void => {
			const slot: PIXI.Container = this.slotViewsData.get(slotKey);
			slot.y += 150;
			new TWEEN.Tween(slot)
				.to({ y: '-150' }, 400)
				.delay(slotIndex * 50)
				.easing(TWEEN.Easing.Quartic.Out)
				.start();
		};

		let index = 0;
		this.characterSkillPairs.forEach((characterSkillPair) => {
			const slotKey = characterSkillPair.character.getKey();
			if (this.slotViewsData.has(slotKey)) {
				tweenSlot(slotKey, index);
				index += 1;
			}
		});

		this.upgrades.forEach((upgrade) => {
			const slotKey = upgrade.getKey();
			if (this.slotViewsData.has(slotKey)) {
				tweenSlot(slotKey, index);
				index += 1;
			}
		});
	}

	private createUpgradesScrollView(): ScrollView {
		const upgradeArrowOffset = 28;
		const maxSlotsDisplayed = 7.5;

		const scrollView = new ScrollView(
			BusinessSlotsView.SLOT_WIDTH_SPACE * maxSlotsDisplayed,
			this.upgradesContainer.height + upgradeArrowOffset,
			ScrollAxis.HORIZONTAL,
			{ begin: 32, end: -24 },
		);

		this.upgradesContainer.position.set(32, 32 + upgradeArrowOffset);

		scrollView.setContent(this.upgradesContainer);
		scrollView.position.set(164 - this.upgradesContainer.x, -32 - this.upgradesContainer.y);

		return scrollView;
	}
}
