export enum ServerErrorCode {
	BANK_INVALID_TRANSACTION_CARDPAY_1 = 19000,
	BANK_INVALID_TRANSACTION_CARDPAY_2 = 19001,
	BANK_INVALID_EMAIL_CARDPAY = 19002,

	BANK_INVALID_TRANSACTION = 16001,
	ACCOUNT_EMAIL_ALREADY_EXISTS = 1004,
	ACCOUNT_EMAIL_LOGIN_NOT_FOUND = 1006,
	ACCOUNT_WRONG_CONFIRMATION_CODE = 1007,
	ACCOUNT_EMAIL_NOT_CONFIRMED = 1008,
	ACCOUNT_WRONG_PASSWORD = 1009,
	ACCOUNT_NOT_FOUND_SESSION_ID = 402,
	ACCOUNT_INVALID_SESSION = 401,
	ACCOUNT_WRONG_SESSION_ID = 403,
	MAINTENANCE = 503,
	OUTDATED_VERSION = 106,
}
