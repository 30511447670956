export class PrepartyStartCharacterModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY: symbol = Symbol();
	public static readonly EVENT_ACTIVATE: symbol = Symbol();
	public static readonly EVENT_NEXT_FAREWELL_PARTY_CHOOSEN: symbol = Symbol();

	private timeLeft: number;
	private canActiveTimer: boolean;
	private isViewVisible: boolean;

	constructor(
		private readonly farewellPartyKeys: string[],
		private nextFarewellPartyId?: string,
	) {
		super();

		this.timeLeft = Number.MAX_SAFE_INTEGER;
		this.isViewVisible = false;
		this.canActiveTimer = false;
	}

	public respawn(seconds: number): void {
		this.timeLeft = seconds;
		this.canActiveTimer = true;
		this.emit(PrepartyStartCharacterModel.EVENT_ACTIVATE);
	}

	public getFarewellPartyKey(): string {
		return this.nextFarewellPartyId;
	}

	public canActivateTimer(): boolean {
		return this.canActiveTimer;
	}

	public canShowView(): boolean {
		return this.isViewVisible;
	}

	public disableCharacterView(): void {
		this.isViewVisible = false;
		this.emit(PrepartyStartCharacterModel.EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY, this.isViewVisible);
	}

	public setTimeLeft(value: number): void {
		if (value <= 0) {
			this.timeLeft = 0;
			this.canActiveTimer = false;

			if (this.nextFarewellPartyId === undefined) {
				this.chooseNextFarewellParty();
			}

			this.emitShowEvent();
		} else {
			this.timeLeft = value;
		}
	}

	public getTimeLeft(): number {
		return this.timeLeft;
	}

	private emitShowEvent(): void {
		this.timeLeft = Number.MAX_SAFE_INTEGER;
		this.isViewVisible = true;
		this.emit(PrepartyStartCharacterModel.EVENT_CHANGE_PREPARTY_CHARACTER_VISIBILITY, this.isViewVisible);
	}

	public chooseNextFarewellParty(): void {
		const randomId: number = Math.floor(this.farewellPartyKeys.length * Math.random());
		this.nextFarewellPartyId = this.farewellPartyKeys[randomId];
		this.emit(PrepartyStartCharacterModel.EVENT_NEXT_FAREWELL_PARTY_CHOOSEN, this.nextFarewellPartyId);
	}
}
