import { SettingsPanelItemBaseView } from './SettingsPanelItemBaseView';
import { AssetsStorage } from '@main/AssetsStorage';

export class SettingsPanelItemWithSliderView extends SettingsPanelItemBaseView {
	public static readonly EVENT_SLIDER_DRAG: symbol = Symbol();
	public static readonly EVENT_SLIDER_DRAG_END: symbol = Symbol();

	private sliderMask: PIXI.Graphics;

	private sliderToggle: PIXI.Sprite;

	private sliderToggleMinX: number;
	private sliderToggleMaxX: number;

	constructor(
		iconOffset: number,
		labelOffset: number,
		sliderOffset: number,
		labelLocaleKey: string,
		icon: PIXI.Sprite,
	) {
		super(
			iconOffset,
			labelOffset,
			labelLocaleKey,
			icon,
		);

		const sliderContainer = new PIXI.Container();

		const bgSlider = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_bar_bg'], 8, 14, 8, 14);
		bgSlider.width = 317;
		bgSlider.pivot.set(bgSlider.width / 2, bgSlider.height / 2);

		const bgSliderGreen = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['setting_green_bar'], 6, 11, 6, 11);
		bgSliderGreen.width = 317;
		bgSliderGreen.pivot.set(bgSliderGreen.width / 2, bgSliderGreen.height / 2);

		this.sliderMask = new PIXI.Graphics();
		this.sliderMask = new PIXI.Graphics()
			.beginFill(0x000000)
			.drawRect(0, 0, bgSliderGreen.width - 2, bgSliderGreen.height)
			.endFill();
		this.sliderMask.position.set(-bgSliderGreen.width / 2 + 1, -bgSliderGreen.height / 2);

		bgSliderGreen.mask = this.sliderMask;

		sliderContainer.addChild(
			bgSlider,
			bgSliderGreen,
			this.sliderMask as PIXI.DisplayObject,
		);
		sliderContainer.x += sliderOffset;

		sliderContainer.interactive = true;
		sliderContainer.on('pointertap', this.onSliderContainerPointerTap, this);

		this.sliderToggle = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['rounded_rectangle']);
		this.sliderToggleMinX = bgSliderGreen.x + 44;
		this.sliderToggleMaxX = bgSliderGreen.width + 23;
		this.sliderToggle.x = this.sliderToggleMaxX;

		this.sliderToggle.interactive = true;
		this.sliderToggle.on('pointerdown', this.onSliderPointerDown, this);
		this.sliderToggle.on('pointerup', this.onSliderPointerUp, this);
		this.sliderToggle.on('pointerupoutside', this.onSliderPointerUp, this);

		this.addChild(
			sliderContainer,
			this.sliderToggle,
		);
	}

	public setToggle(value: number): void {
		this.sliderToggle.x = this.sliderToggleMinX + (this.sliderToggleMaxX - this.sliderToggleMinX) * value;
		this.sliderMask.width = this.sliderToggle.x - 40;
	}

	private onSliderPointerDown(): void {
		this.sliderToggle.on('pointermove', this.onSliderPointerMove, this);
	}

	private onSliderPointerMove(e: PIXI.interaction.InteractionEvent): void {
		const pos = this.toLocal(e.data.global);
		const x = Math.max(this.sliderToggleMinX, Math.min(pos.x, this.sliderToggleMaxX));
		this.sliderToggle.x = x;

		this.sliderMask.width = x - 40;

		// value between [0..1]
		const currentSliderValue = (x - this.sliderToggleMinX) / (this.sliderToggleMaxX - this.sliderToggleMinX);

		this.emit(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG, currentSliderValue);
	}

	private onSliderContainerPointerTap(e: PIXI.interaction.InteractionEvent): void {
		this.onSliderPointerMove(e);
		this.onSliderDragEnded();
	}

	private onSliderDragEnded(): void {
		const currentSliderValue = (this.sliderToggle.x - this.sliderToggleMinX) / (this.sliderToggleMaxX - this.sliderToggleMinX);
		this.emit(SettingsPanelItemWithSliderView.EVENT_SLIDER_DRAG_END, currentSliderValue);
	}

	private onSliderPointerUp(): void {
		if (this.sliderToggle.listeners('pointermove').includes(this.onSliderPointerMove)) {
			this.sliderToggle.off('pointermove', this.onSliderPointerMove, this);
			this.onSliderDragEnded();
		}
	}
}
