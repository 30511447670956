import { AssetsStorage } from '@main/AssetsStorage';
import LocalizationStorage from '@main/LocalizationStorage';
import { BusinessModel } from '@models/BusinessModel';
import { CharacterModel } from '@models/CharacterModel';
import { UpgradeModel } from '@models/UpgradeModel';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import { BusinessPropertiesView } from '@views/windows/businessInfo/BusinessPropertiesView';
import { BusinessInfoBarView } from '../../BusinessInfoBarView';

export class BusinessTopUiInfo extends PIXI.Container {
	public static readonly EVENT_BUTTON_BACK_CLICK: symbol = Symbol();
	public static readonly EVENT_PROPERTY_CLICK = Symbol();

	private topUiBg: PIXI.mesh.NineSlicePlane;

	private localizationStorage: LocalizationStorage;

	private textName: PIXI.extras.BitmapText;

	private model: BusinessModel;

	private infoView: BusinessInfoBarView;
	private backButton: ButtonBaseView;

	constructor(
		model: BusinessModel,
		character: CharacterModel[],
		upgrade: UpgradeModel[],
	) {
		super();

		this.model = model;

		this.localizationStorage = LocalizationStorage.getInstance();
		this.localizationStorage.on(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.model.on(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.updateIncomePerSec, this);
		this.model.on(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.updateIncomePerSec, this);

		this.topUiBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('uiAtlas')['business_info_panel'], 5, 1, 5, 9);
		const uiBgWidth = 704;
		const uiBgHeight = 80;
		this.topUiBg.width = uiBgWidth;
		this.topUiBg.height = uiBgHeight;
		this.topUiBg.pivot.set(this.topUiBg.width / 2, this.topUiBg.height / 2);
		this.topUiBg.interactive = true;

		this.textName = new PIXI.extras.BitmapText('', { font: '16px wendyOneGold' });
		this.textName.pivot = new PIXI.Point(0, this.textName.height / 2);
		this.textName.position.set(-305, -5);

		this.infoView = new BusinessInfoBarView();
		this.infoView.setTransform(-297, 21);
		this.updateIncomePerSec();

		const propertiesView = new BusinessPropertiesView(
			150,
			model,
			upgrade,
			character,
		);
		propertiesView.setTransform(285, 7, 0.24, 0.24);

		propertiesView.on(BusinessPropertiesView.EVENT_PROPERTY_CLICK, (data, origin) => {
			this.emit(BusinessTopUiInfo.EVENT_PROPERTY_CLICK, data, origin);
		});

		const backButtonBg = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['the_loopa']);
		backButtonBg.scale.set(-0.45, 0.45);
		this.backButton = new ButtonBaseView(backButtonBg);
		this.backButton.on(ButtonBaseView.EVENT_CLICK, this.onBackIconClick, this);
		this.backButton.position.set(-327, 13);


		this.onTranslateText();

		this.addChild(
			this.topUiBg,
			this.textName as PIXI.DisplayObject,
			this.infoView,
			this.backButton,
			propertiesView,
		);
	}

	private onBackIconClick(): void {
		this.emit(BusinessTopUiInfo.EVENT_BUTTON_BACK_CLICK);
	}

	private updateIncomePerSec(): void {
		this.infoView.setIncomePerSec(this.model.getBusinessIncomePerSec().toString());
	}

	private onTranslateText(): void {
		this.textName.text = this.localizationStorage.getLocalizedString(`#${this.model.getKey()}_Name`);
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		this.localizationStorage.off(LocalizationStorage.EVENT_NEW_LANGUAGE, this.onTranslateText, this);

		this.model.off(BusinessModel.EVENT_INCOME_VALUE_CHANGED, this.updateIncomePerSec, this);
		this.model.off(BusinessModel.EVENT_INCOME_TIME_INTERVAL_CHANGED, this.updateIncomePerSec, this);
		super.destroy(options);
	}
}
