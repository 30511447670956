import { BankOfferConfig, BankOfferLocalizationKeysType } from '@configs/bank/BankOfferConfig';
import { BankOfferMoveFromOfferWindowType, BankOfferWindowViewType } from '@src/types/BankTypes';

export class BankOfferModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_TIMEOUT: symbol = Symbol();

	private readonly endTime: number;
	private readonly key: string;
	private readonly mainWindowStickerDiscountKey: string;
	private readonly windowViewType: BankOfferWindowViewType;
	private readonly moveFromOfferWindow: BankOfferMoveFromOfferWindowType;
	private readonly showOnMainWindow: boolean;
	private readonly localizationKeys: BankOfferLocalizationKeysType;
	private readonly priority: number;

	private readonly characterLeftKey: string;
	private readonly characterRightKey: string;
	private readonly iconKey: string;

	private timeleft: number;
	private offerWindowShowed: boolean;

	constructor(config: BankOfferConfig, currentTime: number, saveEndTime?: number) {
		super();

		this.timeleft = 0;
		this.offerWindowShowed = false;

		if (saveEndTime >= currentTime) {
			this.endTime = saveEndTime;
		} else if (config.getDuration() != null) {
			const durationTimestamp = currentTime + config.getDuration();

			if (config.hasDates()) {
				const endTimestamp = config.getEndTimestamp();
				this.endTime = Math.min(durationTimestamp, endTimestamp);
			} else {
				this.endTime = durationTimestamp;
			}
		} else {
			// if duration is missing, start and end date should be present
			this.endTime = config.getEndTimestamp();
		}

		this.key = config.getKey();
		this.mainWindowStickerDiscountKey = config.getMainWindowStickerDiscountKey();
		this.windowViewType = config.getWindowViewType();
		this.moveFromOfferWindow = config.getMoveFromOfferWindowType();
		this.showOnMainWindow = config.isShowOnMainWindow();
		this.localizationKeys = config.getLocalizationKeys();
		this.characterLeftKey = config.getCharacterLeftKey();
		this.characterRightKey = config.getCharacterRightKey();
		this.iconKey = config.getIconKey();
		this.priority = config.getPriority();
	}

	public getPriority(): number {
		return this.priority;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public updateTimeleft(currentTime: number): void {
		this.timeleft = this.endTime - currentTime;
	}

	public setOfferWindowShowed(value: boolean): void {
		this.offerWindowShowed = value;
	}

	public timeout(): void {
		this.timeleft = 0;
		this.emit(BankOfferModel.EVENT_TIMEOUT, this);
	}

	public hasCharacterRightKey(): boolean {
		return Boolean(this.characterRightKey);
	}

	public hasMainWindowStickerDiscountKey(): boolean {
		return Boolean(this.mainWindowStickerDiscountKey);
	}

	public getKey(): string {
		return this.key;
	}

	public getEndTime(): number {
		return this.endTime;
	}

	public getTimeleft(): number {
		return this.timeleft;
	}

	public getMainWindowStickerDiscountKey(): string {
		return this.mainWindowStickerDiscountKey;
	}

	public getMoveFromOfferWindowType(): BankOfferMoveFromOfferWindowType {
		return this.moveFromOfferWindow;
	}

	public getWindowViewType(): BankOfferWindowViewType {
		return this.windowViewType;
	}

	public isShowOnMainWindow(): boolean {
		return this.showOnMainWindow;
	}

	public getLocalizationKeys(): BankOfferLocalizationKeysType {
		return this.localizationKeys;
	}

	public isOfferWindowShowed(): boolean {
		return this.offerWindowShowed;
	}

	public getCharacterLeftKey(): string {
		return this.characterLeftKey;
	}

	public getCharacterRightKey(): string {
		return this.characterRightKey;
	}
}
