import { NetworkRequestSender } from '@src/network/NetworkRequestSender';

export class AssetUrlsLoader extends PIXI.utils.EventEmitter {
	public static readonly EVENT_ASSET_URLS_LOADED = Symbol();

	private assetUrls?: Map<string, string>;

	constructor(
		private readonly networkRequestSender: NetworkRequestSender,
	) {
		super();
	}

	public async loadUrls(): Promise<void> {
		const result = await this.networkRequestSender.sendGetAssets([]);
		let data;

		if (result.error) {
			data = await this.networkRequestSender.sendGetAssetsBackup([]);
		} else {
			data = result.data;
		}

		this.assetUrls = new Map();

		data.assets.forEach((assetObj: Record<string, string>) => {
			this.assetUrls.set(assetObj.name, assetObj.url);
		});

		this.emit(AssetUrlsLoader.EVENT_ASSET_URLS_LOADED);
	}

	public async loadBackupUrl(filename: string): Promise<string | undefined> {
		const data = await this.networkRequestSender.sendGetAssetsBackup([filename]);
		return data.assets[0]?.url;
	}

	public getAssetUrls(): Map<string, string> | undefined {
		return this.assetUrls;
	}
}
