import * as TWEEN from '@tweenjs/tween.js';
import { SizeableMultiColoredBitmapText } from './SizeableMultiColoredBitmapText';

export class FlyBitmapText extends PIXI.Container {
	public static readonly EVENT_HIDDEN: symbol = Symbol();

	private readonly label: PIXI.extras.BitmapText;

	private tweenTranslate: TWEEN.Tween;
	private tweenAlpha: TWEEN.Tween;

	constructor(
		text: string,
		anchor?: PIXI.Point,
		style?: PIXI.extras.BitmapTextStyle,
		maxWight? : number,
	) {
		super();

		this.label = new SizeableMultiColoredBitmapText(maxWight, style);
		this.label.text = text;
		if (anchor) {
			this.label.anchor = anchor.clone();
		}

		this.tweenTranslate = new TWEEN.Tween(this.label)
			.to({ y: '-50' }, 800)
			.onComplete(() => {
				this.tweenTranslate = null;
				this.tryCompleteAnimation();
			})
			.start();

		this.tweenAlpha = new TWEEN.Tween(this.label)
			.to({ alpha: 0 }, 800)
			.onComplete(() => {
				this.tweenAlpha = null;
				this.tryCompleteAnimation();
			})
			.easing(TWEEN.Easing.Cubic.In)
			.start();

		this.addChild(this.label);
	}

	private tryCompleteAnimation(): void {
		if (!this.tweenTranslate && !this.tweenAlpha) {
			this.emit(FlyBitmapText.EVENT_HIDDEN);
			this.destroy({ children: true });
		}
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		if (this.tweenTranslate) {
			this.tweenTranslate.stop();
		}
		if (this.tweenAlpha) {
			this.tweenAlpha.stop();
		}
		super.destroy(options);
	}
}
