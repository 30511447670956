import { VideoConfig } from '@configs/VideoConfig';

export class GalleryVideoModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_OPENED: symbol = Symbol();
	public static readonly EVENT_OLD: symbol = Symbol();
	public static readonly EVENT_PLAY: symbol = Symbol();
	public static readonly EVENT_CLOSE: symbol = Symbol();

	private readonly key: string;
	private readonly levelId: number;
	private readonly duration: number;
	private videoUrl: string;

	private opened: boolean;
	private newItem: boolean;

	constructor(config: VideoConfig) {
		super();

		this.key = config.getKey();
		this.levelId = config.getLevelId();
		this.duration = config.getDuration();
		this.videoUrl = config.getVideoUrl();

		this.opened = false;
		this.newItem = false;
	}

	public play(): void {
		this.emit(GalleryVideoModel.EVENT_PLAY, this.key);
	}

	public close(): void {
		this.emit(GalleryVideoModel.EVENT_CLOSE, this.key);
	}

	public getKey(): string {
		return this.key;
	}

	public setOpened(value: boolean): void {
		this.opened = value;
	}

	public getLevelId(): number {
		return this.levelId;
	}

	public isOpened(): boolean {
		return this.opened;
	}

	public getVideoUrl(): string {
		return this.videoUrl;
	}

	public setVideoUrl(value: string): void {
		this.videoUrl = value;
	}

	public open(): void {
		this.opened = true;
		this.emit(GalleryVideoModel.EVENT_OPENED, this);
		this.newItem = true;
	}

	public isNew(): boolean {
		return this.newItem;
	}

	public setOld(): void {
		this.newItem = false;
		this.emit(GalleryVideoModel.EVENT_OLD, this);
	}

	public getDuration(): number {
		return this.duration;
	}
}
