import { TabSlotView } from './BankTabViewFactory';
import { ScrollView } from '@views/components/ScrollView';
import { ScrollAxis } from '@src/types/ScrollViewTypes';

export class BankTabScrollSlots extends PIXI.Container {
	private static readonly OFFSET_ITEM_HORIZONTAL: number = 440;
	private static readonly OFFSET_ITEM_VERTICAL: number = 30;

	protected contentContainer: PIXI.Container;
	private scrollView: ScrollView;

	public init(slots: TabSlotView[]): void {
		if (MODE_DEBUG && slots.length === 0) {
			throw new Error('Invalid input. Content count must be more than 0');
		}

		const contentContainer = new PIXI.Container();
		contentContainer.height = 900;
		contentContainer.position.set(30, 0);
		this.contentContainer = contentContainer;

		slots.forEach((slot: TabSlotView, index: number) => {
			slot.view.position.set(
				index * BankTabScrollSlots.OFFSET_ITEM_HORIZONTAL + slot.view.width / 2,
				BankTabScrollSlots.OFFSET_ITEM_VERTICAL + slot.view.height / 2,
			);
			this.contentContainer.addChild(slot.view);
		});

		this.scrollView = new ScrollView(1560, 1900, ScrollAxis.HORIZONTAL, { begin: 0, end: 0 })
			.setContent(contentContainer);
		this.addChild(this.scrollView);
	}

	public onShown(): void {
		this.scrollView.resetScrollValue();
	}
}
