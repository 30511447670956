import { CharacterMainBaseView } from '../../CharacterMainBaseView';

export class EventCharacter60005View extends CharacterMainBaseView {
	constructor() {
		super('character60005');
	}

	protected onShow(): void {
		this.animationsContainer.position.set(228, -17);
		this.setAnimation(0, 'animation', true);

		this.quickPhraseView.setView({
			arrowPosition: new PIXI.Point(215, -64),
			arrowRotation: -Math.PI / 2,
			bgPosition: new PIXI.Point(230, -67),
			invertBg: true,
		});

		this.initQuickPhrases(this.key);
	}
}
