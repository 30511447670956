import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { ButtonCheatWindowView } from './ButtonCheatWindowView';
import { CheatModel } from '@models/CheatModel';
import { QuestWindowViewMode } from '../quests/QuestWindowBaseView';

type CheatButtonToggleParams = {
	currentState: boolean;
	onClickCallback: () => void;
};

type CheatButtonParams = CheatButtonToggleParams | (() => void);

type TabElement = {
	elements: PIXI.Container;
	button: PIXI.Container;
}

/* eslint-disable no-console */
export class CheatWindowView extends PopupWindowBaseView {
	public static readonly EVENT_RESOURCE_ADD_SOFT_MONEY: symbol = Symbol();
	public static readonly EVENT_RESOURCE_ADD_PRESTIGE_MONEY: symbol = Symbol();
	public static readonly EVENT_RESOURCE_ADD_HARD_MONEY: symbol = Symbol();
	public static readonly EVENT_RESOURCE_RESET: symbol = Symbol();
	public static readonly EVENT_RESOURCE_ADD_ALL: symbol = Symbol();

	public static readonly EVENT_BUSINESS_GENERATE_CLICK: symbol = Symbol();
	public static readonly EVENT_BUSINESS_GENERATE_CLICK_ALL: symbol = Symbol();
	public static readonly EVENT_BUSINESS_ACQUIRE_ALL: symbol = Symbol();

	public static readonly EVENT_UPGRADE_OPEN: symbol = Symbol();
	public static readonly EVENT_UPGRADE_OPEN_ALL: symbol = Symbol();
	public static readonly EVENT_UPGRADE_ADD_CARDS_TO_OPENED: symbol = Symbol();
	public static readonly EVENT_UPGRADE_ACTIVATE: symbol = Symbol();
	public static readonly EVENT_UPGRADE_ACTIVATE_ALL: symbol = Symbol();

	public static readonly EVENT_CHARACTER_OPEN: symbol = Symbol();
	public static readonly EVENT_CHARACTER_OPEN_ALL: symbol = Symbol();
	public static readonly EVENT_CHARACTER_ADD_CARDS_TO_OPENED: symbol = Symbol();
	public static readonly EVENT_CHARACTER_ACTIVATE: symbol = Symbol();
	public static readonly EVENT_CHARACTER_ACTIVATE_ALL: symbol = Symbol();

	public static readonly EVENT_SKILL_RESET: symbol = Symbol();
	public static readonly EVENT_SKILL_RESET_COOLDOWN: symbol = Symbol();

	public static readonly EVENT_BOOST_ACQUIRE: symbol = Symbol();
	public static readonly EVENT_BOOST_ACQUIRE_ALL: symbol = Symbol();
	public static readonly EVENT_BOOST_ACTIVATE: symbol = Symbol();
	public static readonly EVENT_BOOSY_ACTIVATE_ALL: symbol = Symbol();

	public static readonly EVENT_TOTEM_OPEN: symbol = Symbol();
	public static readonly EVENT_TOTEM_OPEN_ALL: symbol = Symbol();
	public static readonly EVENT_TOTEM_ADD_CARDS_TO_OPENED: symbol = Symbol();

	public static readonly EVENT_MEDIA_START_DIALOG: symbol = Symbol();
	public static readonly EVENT_MEDIA_OPEN_ALL_VIDEOS: symbol = Symbol();

	public static readonly EVENT_POPUP_TOGGLE_BANK_OFFERS = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_GO_TO_EVENT = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_TUTORAIL = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_DIALOGS = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_ERROR = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_NEWS = Symbol();
	public static readonly EVENT_POPUP_TOGGLE_DAILY_REWARD = Symbol();

	public static readonly EVENT_SYSTEM_GO_TO_PARTY: symbol = Symbol();
	public static readonly EVENT_SYSTEM_PRESET_PROFILE: symbol = Symbol();
	public static readonly EVENT_SYSTEM_SET_LEVEL_START_TIME: symbol = Symbol();
	public static readonly EVENT_SYSTEM_RESET_FAREWELL_PARTY: symbol = Symbol();
	public static readonly EVENT_SYSTEM_CLEAR_LOCAL_STORAGE: symbol = Symbol();
	public static readonly EVENT_SYSTEM_CLEAR_LOGIN_ID: symbol = Symbol();
	public static readonly EVENT_SYSTEM_UNLINK_EMAIL: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_MAIN_WINDOW_CONFIG: symbol = Symbol();
	public static readonly EVENT_SYSTEM_SET_ENV: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_LOCALE_KEYS: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_QUEST_ALWAYS_COLLECT: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_ANALYTICS: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_PRELOADER_LOGO: symbol = Symbol();
	public static readonly EVENT_SYSTEM_TOGGLE_MODEST_LANDING: symbol = Symbol();
	public static readonly EVENT_SYSTEM_RESET_CARDS: symbol = Symbol();
	public static readonly EVENT_SYSTEM_SKIP_NOVICE_EVENT: symbol = Symbol();
	public static readonly EVENT_SYSTEM_REFRESH_FREE_SUMMON = Symbol();
	public static readonly EVENT_SYSTEM_REFRESH_DAILY_REWARD = Symbol();

	public static readonly EVENT_BANK_TOGGLE_DUMMY_TRANSACTION: symbol = Symbol();
	public static readonly EVENT_BANK_RESET_BOOSTS: symbol = Symbol();
	public static readonly EVENT_BANK_RESET_SUBSCRIPTIONS: symbol = Symbol();
	public static readonly EVENT_BANK_RESET: symbol = Symbol();
	public static readonly EVENT_BANK_PRINT_OFFERS: symbol = Symbol();
	public static readonly EVENT_BANK_PRINT_OFFERS_WITH_ACTIVE_ELEMENT: symbol = Symbol();

	private static readonly LAYOUT_COLUMN_OFFSET = 180;
	private static readonly TAB_X_OFFSET = -250;
	private static readonly TAB_Y_OFFSET = -300;
	private static readonly BUTTON_ROW_OFFSET = 90;

	private static positionLayoutHorizontal(targets: PIXI.Container[], offset: number): void {
		for (let i = 1; i < targets.length; i++) {
			targets[i].y += offset * i;
		}
	}

	private textInput: PIXI.Text;
	private bg: PIXI.Graphics;
	private tabs: Map<string, TabElement>;

	constructor(
		private readonly cheatModel: CheatModel,
	) {
		super(0.5, undefined, undefined, false);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);

		this.bg = new PIXI.Graphics();
		this.bg.lineStyle(2, 0x000000, 1);
		this.bg.beginFill(0x567889);
		this.bg.drawRoundedRect(0, 0, 1700, 1000, 8);
		this.bg.endFill();
		this.bg.pivot.set(this.bg.width / 2, this.bg.height / 2);

		const labelInput = new PIXI.Text('Input:', { fontFamily: 'Arial', fontSize: 30 });
		labelInput.anchor.set(0.5, 0.5);
		labelInput.position.set(-this.bg.width / 2 + 60, -this.bg.height / 2 + 40);

		this.textInput = new PIXI.Text('', { fontFamily: 'Arial', fontSize: 30 });
		this.textInput.anchor.set(0, 0.5);
		this.textInput.position.set(-this.bg.width / 2 + 150, -this.bg.height / 2 + 40);

		const cheatButtonsAddMoney = this.createCheatButtonsRow(
			'Money',
			['Add soft', 'Add prestige', 'Add hard', 'Reset resources server', 'Навали бабла server'],
			[
				() => this.emit(CheatWindowView.EVENT_RESOURCE_ADD_SOFT_MONEY, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_RESOURCE_ADD_PRESTIGE_MONEY, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_RESOURCE_ADD_HARD_MONEY, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_RESOURCE_RESET),
				() => this.emit(CheatWindowView.EVENT_RESOURCE_ADD_ALL),
			],
		);
		const cheatButtonsBusinesses = this.createCheatButtonsRow(
			'Businesses',
			['Generate click', 'Generate click all', 'Acquire all server'],
			[
				() => this.emit(CheatWindowView.EVENT_BUSINESS_GENERATE_CLICK, this.textInput.text.trim()),
				() => this.emit(CheatWindowView.EVENT_BUSINESS_GENERATE_CLICK_ALL),
				() => this.emit(CheatWindowView.EVENT_BUSINESS_ACQUIRE_ALL),
			],
		);
		const cheatButtonsOpenUpgrade = this.createCheatButtonsRow(
			'Upgrades',
			['Open upgrade server', 'Open all server', 'Cards to opened upgrades server'],
			[
				() => this.emit(CheatWindowView.EVENT_UPGRADE_OPEN, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_UPGRADE_OPEN_ALL),
				() => this.emit(CheatWindowView.EVENT_UPGRADE_ADD_CARDS_TO_OPENED, this.textInput.text),
			],
		);
		const cheatButtonsActivateUpgrade = this.createCheatButtonsRow(
			'',
			['Activate upgrade', 'Activate all'],
			[
				() => this.emit(CheatWindowView.EVENT_UPGRADE_ACTIVATE, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_UPGRADE_ACTIVATE_ALL),
			],
		);
		const cheatButtonsOpenCharacter = this.createCheatButtonsRow(
			'Characters',
			['Open character server', 'Open all server', 'Cards to opened chars server'],
			[
				() => this.emit(CheatWindowView.EVENT_CHARACTER_OPEN, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_CHARACTER_OPEN_ALL),
				() => this.emit(CheatWindowView.EVENT_CHARACTER_ADD_CARDS_TO_OPENED, this.textInput.text),
			],
		);
		const cheatButtonsActivateCharacter = this.createCheatButtonsRow(
			'',
			['Activate character', 'Activate all'],
			[
				() => this.emit(CheatWindowView.EVENT_CHARACTER_ACTIVATE, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_CHARACTER_ACTIVATE_ALL),
			],
		);
		const chetButtonsSkill = this.createCheatButtonsRow(
			'Skills',
			['Reset skills server', 'Reset skills cd server'],
			[
				() => this.emit(CheatWindowView.EVENT_SKILL_RESET),
				() => this.emit(CheatWindowView.EVENT_SKILL_RESET_COOLDOWN),
			],
		);
		const cheatButtonsOpenTotem = this.createCheatButtonsRow(
			'Totems',
			['Open totem server', 'Open all server', 'Cards to opened totems server'],
			[
				() => this.emit(CheatWindowView.EVENT_TOTEM_OPEN, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_TOTEM_OPEN_ALL),
				() => this.emit(CheatWindowView.EVENT_TOTEM_ADD_CARDS_TO_OPENED, this.textInput.text),
			],
		);
		const cheatButtonsOpenBoost = this.createCheatButtonsRow(
			'Boosts',
			['Buy boost', 'Buy all', 'Activate boost', 'Activate all'],
			[
				() => this.emit(CheatWindowView.EVENT_BOOST_ACQUIRE, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_BOOST_ACQUIRE_ALL),
				() => this.emit(CheatWindowView.EVENT_BOOST_ACTIVATE, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_BOOSY_ACTIVATE_ALL),
			],
		);
		const cheatButtonStartDialog = this.createCheatButtonsRow(
			'Media',
			['Start dialog', 'Open all videos'],
			[
				() => this.emit(CheatWindowView.EVENT_MEDIA_START_DIALOG, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_MEDIA_OPEN_ALL_VIDEOS),
			],
		);
		const cheatButtonsEmittersRow1 = this.createCheatButtonsRow(
			'Popups',
			['Bank offers', 'Go to event level', 'Tutorial steps', 'Dialogs', 'Error window', 'News'],
			[
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_BANK_OFFERS),
					currentState: this.cheatModel.isEmitterBankOfferWindowsEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_GO_TO_EVENT),
					currentState: this.cheatModel.isEmitterGoToEventWindowsEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_TUTORAIL),
					currentState: this.cheatModel.isTutorialEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_DIALOGS),
					currentState: this.cheatModel.isEmitterDialogWindowsEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_ERROR),
					currentState: this.cheatModel.isErrorWindowEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_NEWS),
					currentState: this.cheatModel.isNewsPushingEnabled(),
				},
			],
		);
		const cheatButtonsEmittersRow2 = this.createCheatButtonsRow(
			'',
			['Daily reward'],
			[
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_POPUP_TOGGLE_DAILY_REWARD),
					currentState: this.cheatModel.isDailyRewardPushingEnabled(),
				},
			],
		);
		const cheatButtonsSystemRow1 = this.createCheatButtonsRow(
			'System',
			['Goto party server', 'Set preset profile server', 'Set level start time server', 'Reset farewell party server'],
			[
				async () => this.emit(CheatWindowView.EVENT_SYSTEM_GO_TO_PARTY),
				async () => this.emit(CheatWindowView.EVENT_SYSTEM_PRESET_PROFILE, this.textInput.text),
				async () => this.emit(CheatWindowView.EVENT_SYSTEM_SET_LEVEL_START_TIME, this.textInput.text),
				async () => this.emit(CheatWindowView.EVENT_SYSTEM_RESET_FAREWELL_PARTY),
			],
		);
		const cheatButtonsSystemRow2 = this.createCheatButtonsRow(
			'',
			['Clear localStorage', 'Clear loginId', 'Unlink email server', 'MainWindow config'],
			[
				() => this.emit(CheatWindowView.EVENT_SYSTEM_CLEAR_LOCAL_STORAGE),
				() => this.emit(CheatWindowView.EVENT_SYSTEM_CLEAR_LOGIN_ID),
				() => this.emit(CheatWindowView.EVENT_SYSTEM_UNLINK_EMAIL),
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_MAIN_WINDOW_CONFIG),
					currentState: this.cheatModel.isMainWindowConfigEnabled(),
				},
			],
		);
		const cheatButtonsSystemRow3 = this.createCheatButtonsRow(
			'',
			['Set env', 'Toggle locale keys', 'Quest always collect', 'Analytics'],
			[
				() => this.emit(CheatWindowView.EVENT_SYSTEM_SET_ENV, this.textInput.text),
				() => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_LOCALE_KEYS),
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_QUEST_ALWAYS_COLLECT),
					currentState: this.cheatModel.getQuestWindowMode() === QuestWindowViewMode.ALWAYS_COLLECT,
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_ANALYTICS),
					currentState: this.cheatModel.isAnalyticsEnabled(),
				},
			],
		);
		const cheatButtonsSystemRow4 = this.createCheatButtonsRow(
			'',
			['Preloader logo', 'Modest landing', 'Reset cards server', 'Novice event'],
			[
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_PRELOADER_LOGO),
					currentState: this.cheatModel.isPreloaderLogoEnabled(),
				},
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_TOGGLE_MODEST_LANDING),
					currentState: this.cheatModel.isLandingModest(),
				},
				() => this.emit(CheatWindowView.EVENT_SYSTEM_RESET_CARDS),
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_SYSTEM_SKIP_NOVICE_EVENT),
					currentState: this.cheatModel.isNoviceEventEnabled(),
				},
			],
		);
		const cheatButtonsSystemRow5 = this.createCheatButtonsRow(
			'',
			['Refresh free summon', 'Refresh daily reward'],
			[
				() => this.emit(CheatWindowView.EVENT_SYSTEM_REFRESH_FREE_SUMMON),
				() => this.emit(CheatWindowView.EVENT_SYSTEM_REFRESH_DAILY_REWARD),
			],
		);
		const cheatButtonBankRow1 = this.createCheatButtonsRow(
			'Bank',
			[
				'Dummy transaction', 'Reset boosts server', 'Reset subscriptions server',
				'Reset bank server', 'Print offers', 'Print offers w active bank elmnt',
			],
			[
				{
					onClickCallback: () => this.emit(CheatWindowView.EVENT_BANK_TOGGLE_DUMMY_TRANSACTION),
					currentState: this.cheatModel.isDummyTransactionsEnabled(),
				},
				() => this.emit(CheatWindowView.EVENT_BANK_RESET_BOOSTS),
				() => this.emit(CheatWindowView.EVENT_BANK_RESET_SUBSCRIPTIONS),
				() => this.emit(CheatWindowView.EVENT_BANK_RESET),
				() => this.emit(CheatWindowView.EVENT_BANK_PRINT_OFFERS),
				() => this.emit(CheatWindowView.EVENT_BANK_PRINT_OFFERS_WITH_ACTIVE_ELEMENT),
			],
		);

		const systemTabLayout: PIXI.Container[] = [
			cheatButtonStartDialog,
			cheatButtonBankRow1,
			cheatButtonsEmittersRow1,
			cheatButtonsEmittersRow2,
			cheatButtonsSystemRow1,
			cheatButtonsSystemRow2,
			cheatButtonsSystemRow3,
			cheatButtonsSystemRow4,
			cheatButtonsSystemRow5,
		];

		const gameTabLayout: PIXI.Container[] = [
			cheatButtonsAddMoney,
			cheatButtonsBusinesses,
			cheatButtonsOpenUpgrade,
			cheatButtonsActivateUpgrade,
			cheatButtonsOpenCharacter,
			cheatButtonsActivateCharacter,
			chetButtonsSkill,
			cheatButtonsOpenBoost,
			cheatButtonsOpenTotem,
		];

		const tabsElements = this.initTabs([
			{ title: 'System/Other', elems: systemTabLayout },
			{ title: 'Game', elems: gameTabLayout },
		]);

		window.addEventListener('keypress', this.onKeyDown);
		window.addEventListener('keyup', this.onKeyUp);

		this.mainContainer.addChild(
			this.bg,
			labelInput as PIXI.DisplayObject,
			this.textInput,
			...tabsElements,
		);
	}

	private onKeyDown(e: KeyboardEvent): void {
		if (e.key === 'Escape' || e.key === 'Esc') {
			this.onClose();
		} else {
			this.textInput.text += e.key;
			this.textInput.text = this.textInput.text.replace('_Shift', '_');
			this.textInput.text = this.textInput.text.replace('#Shift', '#');
			this.textInput.text = this.textInput.text.replace('Enter', '');

			const upperCaseMatch = this.textInput.text.match(/(\w)Shift/);

			if (upperCaseMatch) {
				upperCaseMatch.forEach((letter) => {
					this.textInput.text.replace(`${letter}Shift`, letter.toUpperCase());
				});
			}

			this.textInput.text = this.textInput.text.trim();
		}
	}

	private onKeyUp(e: KeyboardEvent): void {
		if (e.key === 'Backspace') {
			this.textInput.text = '';
		} else if (e.key === 'Escape' || e.key === 'Esc') {
			this.onClose();
		}
	}

	private createCheatButtonsRow(
		title: string,
		labels: string[],
		params: CheatButtonParams[],
	): PIXI.Container {
		const textTitle = new PIXI.Text(title, { fontFamily: 'Arial', fontSize: 39 });
		const buttons = new PIXI.Container();

		textTitle.anchor.set(1, 0.5);
		textTitle.x = -95;

		labels.forEach((label, i) => {
			let button: ButtonCheatWindowView;
			const param = params[i];

			if (param instanceof Function) {
				button = new ButtonCheatWindowView(label);
				button.on(ButtonCheatWindowView.EVENT_CLICK, () => {
					param();
					this.textInput.text = '';
				});
			} else {
				button = new ButtonCheatWindowView(label);
				button.setToggleMode(param.currentState);
				button.on(ButtonCheatWindowView.EVENT_CLICK, () => {
					param.onClickCallback();
					this.textInput.text = '';
				});
			}

			button.x += CheatWindowView.LAYOUT_COLUMN_OFFSET * i;
			buttons.addChild(button);
		});

		buttons.position.set(CheatWindowView.TAB_X_OFFSET, CheatWindowView.TAB_Y_OFFSET);
		buttons.addChild(textTitle);

		return buttons;
	}

	private createTab(title: string, elements: PIXI.Container[]): PIXI.Container[] {
		const tabButton = new ButtonCheatWindowView(title);
		const tabElements = new PIXI.Container();

		CheatWindowView.positionLayoutHorizontal(elements, CheatWindowView.BUTTON_ROW_OFFSET);

		tabElements.addChild(...elements);

		tabButton.on(ButtonCheatWindowView.EVENT_CLICK, () => {
			this.showTab(title);
		});

		tabButton.y = CheatWindowView.TAB_Y_OFFSET - CheatWindowView.BUTTON_ROW_OFFSET;
		tabButton.x = CheatWindowView.TAB_X_OFFSET + CheatWindowView.LAYOUT_COLUMN_OFFSET * this.tabs.size;

		this.tabs.set(title, {
			elements: tabElements,
			button: tabButton,
		});

		return [tabButton, tabElements];
	}

	private showTab(title: string): void {
		this.tabs.forEach((tab, tabTitle) => {
			if (tabTitle === title) {
				tab.elements.visible = true;
				tab.button.alpha = 0.5;
				tab.button.interactive = false;
			} else {
				tab.elements.visible = false;
				tab.button.alpha = 1;
				tab.button.interactive = true;
			}
		});
	}

	private initTabs(tabsData: { title: string, elems: PIXI.Container[] }[]): PIXI.Container[] {
		this.tabs = new Map();

		const elements: PIXI.Container[] = [];

		tabsData.forEach((item) => {
			const tabItems = this.createTab(item.title, item.elems);
			elements.push(...tabItems);
		});

		this.showTab(tabsData[0].title);

		return elements;
	}

	public destroy(options?: boolean | PIXI.DestroyOptions): void {
		window.removeEventListener('keypress', this.onKeyDown);
		window.removeEventListener('keyup', this.onKeyUp);
		super.destroy(options);
	}
}

/* eslint-enable no-console */
