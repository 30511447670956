import { TextField } from './TextField';
import { TextUtils } from '../../../utils/TextUtils';

export class MultiColoredTextField extends TextField {
	private textColorMap: number[];

	constructor(
		style?: PIXI.extras.BitmapTextStyle,
		maxWidth?: number,
		maxHeight?: number,
	) {
		super('', style, maxWidth, maxHeight);
	}

	public set text(value: string) {
		// eslint-disable-next-line no-param-reassign
		value = value.toString() || ' ';
		if (this._text === value) { // eslint-disable-line no-underscore-dangle
			return;
		}

		const replacedValue = TextUtils.replaceSpecSymbols(value);
		this.tryColorText(replacedValue);

		if (this.maxHeight !== undefined) {
			this.fitText();
		}
	}

	public get text(): string {
		return this._text; // eslint-disable-line no-underscore-dangle
	}

	private tryColorText(origStr: string): void {
		const pattern = /(\[(?:[0-9a-fA-F]{6})][\s\S]*?\[-])/m;

		const arr = origStr.split(pattern).filter(str => str.length > 0);
		let textOnly;
		const arrLength = arr.length;

		if (arrLength > 0) {
			const textColorMap = [];
			let prevIndex = 0;
			textOnly = '';

			for (let i = 0; i < arrLength; i++) {
				let text = arr[i];
				let color = this.tint;

				if (pattern.test(text)) {
					color = parseInt(text.substr(1, 6), 16);
					text = text.slice(8, -3);
				}

				const textLength = text.length;
				for (let j = 0; j < textLength; j++) {
					const currentChar = text[j];
					if (TextUtils.CUSTOM_SYMBOLS.includes(currentChar)) {
						textColorMap[prevIndex + j] = 0xFFFFFF;
					} else {
						textColorMap[prevIndex + j] = color;
					}
				}
				prevIndex += textLength;
				textOnly += text;
			}
			this.textColorMap = textColorMap;
		}

		this._text = textOnly; // eslint-disable-line no-underscore-dangle
		this.dirty = true;
	}

	/* eslint-disable */
	protected updateText(): void {
		const data = PIXI.extras.BitmapText.fonts[(this._font as any).name];
		const scale = (this._font as any).size / data.size;
		const pos = new PIXI.Point();
		const chars = [];
		const lineWidths = [];
		const text = this.text.replace(/(?:\r\n|\r)/g, '\n');
		const textLength = text.length;
		const maxWidth = this._maxWidth * data.size / (this._font as any).size;
		const tint = this.tint;
		const textColorMap = this.textColorMap;

		let prevCharCode = null;
		let lastLineWidth = 0;
		let maxLineWidth = 0;
		let line = 0;
		let lastBreakPos = -1;
		let lastBreakWidth = 0;
		let spacesRemoved = 0;
		let maxLineHeight = 0;

		for (let i = 0; i < textLength; i++) {
			const charCode = text.charCodeAt(i);
			const char = text.charAt(i);

			if (/(?:\s)/.test(char)) {
				lastBreakPos = i;
				lastBreakWidth = lastLineWidth;
			}

			if (char === '\r' || char === '\n') {
				lineWidths.push(lastLineWidth);
				maxLineWidth = Math.max(maxLineWidth, lastLineWidth);
				line += 1;
				spacesRemoved += 1;

				pos.x = 0;
				pos.y += data.lineHeight;
				prevCharCode = null;
				continue;
			}

			const charData = data.chars[charCode];

			if (!charData) {
				continue;
			}

			if (prevCharCode && charData.kerning[prevCharCode]) {
				pos.x += charData.kerning[prevCharCode];
			}

			chars.push({
				line,
				charCode,
				color: textColorMap ? textColorMap[i] : tint,
				texture: charData.texture,
				position: new PIXI.Point(pos.x + charData.xOffset + (this._letterSpacing / 2), pos.y + charData.yOffset),
			});
			pos.x += charData.xAdvance + this._letterSpacing;
			lastLineWidth = pos.x;
			maxLineHeight = Math.max(maxLineHeight, (charData.yOffset + charData.texture.height));
			prevCharCode = charCode;

			if (lastBreakPos !== -1 && maxWidth > 0 && pos.x > maxWidth) {
				spacesRemoved += 1;
				PIXI.utils.removeItems(chars, 1 + lastBreakPos - spacesRemoved, 1 + i - lastBreakPos);
				i = lastBreakPos;
				lastBreakPos = -1;

				lineWidths.push(lastBreakWidth);
				maxLineWidth = Math.max(maxLineWidth, lastBreakWidth);
				line += 1;

				pos.x = 0;
				pos.y += data.lineHeight;
				prevCharCode = null;
			}
		}

		const lastChar = text.charAt(text.length - 1);

		if (lastChar !== '\r' && lastChar !== '\n') {
			if (/(?:\s)/.test(lastChar)) {
				lastLineWidth = lastBreakWidth;
			}

			lineWidths.push(lastLineWidth);
			maxLineWidth = Math.max(maxLineWidth, lastLineWidth);
		}

		const lineAlignOffsets = [];

		for (let i = 0; i <= line; i++) {
			let alignOffset = 0;

			if ((this._font as any).align === 'right') {
				alignOffset = maxLineWidth - lineWidths[i];
			} else if ((this._font as any).align === 'center') {
				alignOffset = (maxLineWidth - lineWidths[i]) / 2;
			}

			lineAlignOffsets.push(alignOffset);
		}

		const lenChars = chars.length;

		for (let i = 0; i < lenChars; i++) {
			let c = this._glyphs[i]; // get the next glyph sprite

			if (c) {
				c.texture = chars[i].texture;
			} else {
				c = new PIXI.Sprite(chars[i].texture);
				this._glyphs.push(c);
			}

			c.position.x = (chars[i].position.x + lineAlignOffsets[chars[i].line]) * scale;
			c.position.y = chars[i].position.y * scale;
			c.scale.x = c.scale.y = scale;

			c.tint = chars[i].color;

			if (!c.parent) {
				this.addChild(c);
			}
		}

		// remove unnecessary children.
		for (let i = lenChars; i < this._glyphs.length; ++i) {
			this.removeChild(this._glyphs[i]);
		}

		this._textWidth = maxLineWidth * scale;
		this._textHeight = (pos.y + data.lineHeight) * scale;

		// apply anchor
		if ((this.anchor as any).x !== 0 || (this.anchor as any).y !== 0) {
			for (let i = 0; i < lenChars; i++) {
				this._glyphs[i].x -= this._textWidth * (this.anchor as any).x;
				this._glyphs[i].y -= this._textHeight * (this.anchor as any).y;
			}
		}
		this._maxLineHeight = maxLineHeight * scale;
	}
	/* eslint-enable */
}
