import { BusinessModel } from '@models/BusinessModel';
import { EventLevelModel } from '@models/level/EventLevelModel';
import { ModelWriterBusinessIncomePerSec } from './ModelWriterBusinessIncomePerSec';
import { TimeskipModel } from '@models/TimeskipModel';

export class ModelWriterEventLevelBusinessIncomePerSec extends ModelWriterBusinessIncomePerSec {
	private readonly eventLevelModel: EventLevelModel;

	constructor(
		businessModels: Map<string, BusinessModel>,
		timeskipModels: Map<string, TimeskipModel>,
		eventLevelModel: EventLevelModel,
	) {
		super(businessModels, timeskipModels);

		this.eventLevelModel = eventLevelModel;
	}

	protected updateIncomePerSec(): void {
		super.updateIncomePerSec();

		this.eventLevelModel?.setIncomePerSec(this.getIncomePerSec(true, true));
	}
}
