import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter10016View } from '@views/characters/events/event1/EventCharacter10016View';
import { EventCharacter10017View } from '@views/characters/events/event1/EventCharacter10017View';
import { EventCharacter10018View } from '@views/characters/events/event1/EventCharacter10018View';


export class EventBusiness10006View extends BusinessBaseView {
	constructor() {
		const character10016 = new EventCharacter10016View();
		const character10017 = new EventCharacter10017View();
		const character10018 = new EventCharacter10018View();

		super('business10006', [character10016, character10017, character10018]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b9_decor1']).setTransform(71.5, -31, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor2']).setTransform(-78.5, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor3']).setTransform(207, 66.5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b9_decor5']).setTransform(0, -74, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			character10016 as PIXI.DisplayObject,
			character10017,
			character10018,
		);
	}
}
