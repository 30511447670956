import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { ItemPromoteButtonView } from '../ItemPromoteButtonView';
import { AssetsStorage } from '@main/AssetsStorage';
import { SizeableBitmapText } from '@views/components/text/SizeableBitmapText';
import { TextField } from '@views/components/text/TextField';
import { PopupWindowBaseView } from '@views/components/PopupWindowBaseView';
import { MultiColoredTextField } from '@views/components/text/MultiColoredTextField';
import { UpgradeModel } from '@models/UpgradeModel';
import { TextDescriptionHelperUpgrade } from './TextDescriptionHelperUpgrade';
import { UpgradeCardView } from '@views/ui/cards/UpgradeCardView';
import { SoundController } from '@src/main/SoundController';
import { PromoteAnimationProvider } from '@views/ui/cards/PromoteAnimationProvider';
import { ButtonBaseView } from '@views/components/buttons/ButtonBaseView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import LocalizationStorage from '@main/LocalizationStorage';
import { FlyBitmapText } from '@views/components/text/FlyBitmapText';
import { CloseButtonView } from '@views/components/buttons/CloseButtonView';

export class UpgradePromoteWindowView extends PopupWindowBaseView {
	public static readonly EVENT_BUTTON_PROMOTE: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();

	private readonly closeButton: ButtonBaseView;
	private readonly model: UpgradeModel;
	private readonly prestigeMoneyModel: PrestigeMoneyModel;
	private readonly hardMoneyModel: HardMoneyModel;
	private readonly profitDesc: MultiColoredTextField;
	private readonly buttonPromote: ItemPromoteButtonView;
	private readonly card: UpgradeCardView;
	private readonly promoteAnimation: PromoteAnimationProvider;
	private readonly localizationStorage: LocalizationStorage;
	private flyTextLabel: FlyBitmapText;

	private readonly moneyPanelsButtonPlusInvisible: boolean;

	constructor(
		model: UpgradeModel,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		moneyPanelsButtonPlusInvisible: boolean,
	) {
		super(0.8, true);

		this.model = model;
		this.prestigeMoneyModel = prestigeMoneyModel;
		this.hardMoneyModel = hardMoneyModel;

		this.moneyPanelsButtonPlusInvisible = moneyPanelsButtonPlusInvisible;

		this.localizationStorage = LocalizationStorage.getInstance();

		const modelKey = model.getKey();
		const fxAtlas = AssetsStorage.getAtlas('fxAtlas');

		const bg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['upgrades_info_bg'], 43, 0, 43, 0);
		bg.width = 1340;
		bg.height = 886;
		bg.pivot.set(bg.width / 2, bg.height / 2);
		bg.interactive = true;

		const descText = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_Desc`),
			{ font: { size: 30, name: 'wendyOne' }, tint: 0xbee1fe },
			600,
			130,
		);
		descText.position.set(15, -340);
		descText.anchor = new PIXI.Point(0, 0.5);

		const param1Name = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_1_name`),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		param1Name.position.set(15, -250);

		const param1Value = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_1_value`),
			{ font: { size: 28, name: 'wendyOne' } },
			370,
			90,
		);
		param1Value.position.set(210, -250);

		const param2Name = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_2_name`),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		param2Name.position.set(15, -150);

		const param2Value = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_parameter_2_value`),
			{ font: { size: 28, name: 'wendyOne' } },
			370,
			90,
		);
		param2Value.position.set(210, -150);

		const marcLabel = new SizeableBitmapText(
			this.localizationStorage.getLocalizedString('#upgrade_desrc_marc'),
			165,
			{ font: { size: 28, name: 'wendyOne' }, tint: 0x223546 },
		);
		marcLabel.position.set(15, -50);

		const marcValue = new TextField(
			this.localizationStorage.getLocalizedString(`#${modelKey}_Marc`),
			{ font: { size: 28, name: 'wendyOne' } },
			370,
			90,
		);
		marcValue.position.set(210, -50);

		const profitDescStr = TextDescriptionHelperUpgrade.getTextDescriptionPromoteWindow(model);
		this.card = new UpgradeCardView(model, false);
		this.card.position.set(-304, -36);

		const profitBg = new PIXI.mesh.NineSlicePlane(AssetsStorage.getAtlas('collectionsAtlas')['rarity_info_panel'], 22, 23, 22, 23);
		profitBg.width = 620;
		profitBg.height = 170;
		profitBg.pivot.set(profitBg.width / 2, profitBg.height / 2);
		profitBg.position.set(300, 140);

		const profitDesc = new MultiColoredTextField({ font: '40px wendyOneShadowBold', align: 'center' }, 600, 140);
		profitDesc.text = profitDescStr;
		profitDesc.anchor = 0.5;
		profitDesc.position.set(300, 140);
		this.profitDesc = profitDesc;

		const promoteParticlesContainer = new PIXI.Container();
		promoteParticlesContainer.position.set(300, 140);
		promoteParticlesContainer.visible = false;

		const promoteGlow = new PIXI.Sprite(fxAtlas['promote_glow1']);
		promoteGlow.visible = false;
		promoteGlow.alpha = 0.8;
		promoteGlow.position.set(300, 140);
		promoteGlow.blendMode = PIXI.BLEND_MODES.ADD;
		promoteGlow.scale.set(0);

		this.promoteAnimation = new PromoteAnimationProvider(
			profitDesc,
			promoteParticlesContainer,
			promoteGlow,
		);
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_PROFIT_SCALE_DOWN_COMPLETED, () => { this.updateProfitDesc(); });
		this.promoteAnimation.on(PromoteAnimationProvider.EVENT_ANIMATION_COMPLETED, this.onPromoteAnimationCompleted, this);

		this.closeButton = new CloseButtonView();
		this.closeButton.position.set(900, -500);
		this.closeButton.alpha = 0.7;
		this.closeButton.on(ButtonBaseView.EVENT_CLICK, this.onClose, this);

		this.buttonPromote = new ItemPromoteButtonView(
			model.isEnoughCardsForPromote(),
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
		);
		this.buttonPromote.on(ItemPromoteButtonView.EVENT_CLICK, this.onPromoteButtonClick, this);
		this.buttonPromote.position.set(285, 328);
		this.updateCost();
		this.updateButtonPromoteCostValueEnabled();

		const hardMoneyPanel = new HardMoneyPanelView(this.hardMoneyModel);
		hardMoneyPanel.position.set(670, -500);

		const prestigeMoneyPanel = new PrestigeMoneyPanelView(this.prestigeMoneyModel);
		prestigeMoneyPanel.position.set(410, -500);

		if (this.moneyPanelsButtonPlusInvisible) {
			prestigeMoneyPanel.setButtonPlusVisible(false);
			hardMoneyPanel.setButtonPlusVisible(false);
		}

		prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(UpgradePromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);
		hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(UpgradePromoteWindowView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);

		this.model.on(UpgradeModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);
		this.model.on(UpgradeModel.EVENT_PROMOTED, this.startPromoteAnimation, this);

		this.mainContainer.addChild(
			bg,
			profitBg,
			param1Name,
			param1Value,
			param2Name,
			param2Value,
			marcLabel,
			marcValue,
			descText as PIXI.DisplayObject,
			promoteParticlesContainer,
			promoteGlow,
			profitDesc,
			this.card,
			this.buttonPromote,
			this.closeButton,
			hardMoneyPanel,
			prestigeMoneyPanel,
		);

		this.prestigeMoneyModel.on(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this);
	}

	public getKey(): string {
		return this.model.getKey();
	}

	public getPromoteButton(): PIXI.Container {
		return this.buttonPromote;
	}

	public getCloseButtonContainer(): PIXI.Container {
		return this.closeButton;
	}

	private startPromoteAnimation(): void {
		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
		this.buttonPromote.setCost(this.model.getPromoteCostPrestige().toString());
		this.buttonPromote.setEnabled(false);
		this.buttonPromote.alpha = 0.5;
		this.card.startPromoteAnimation();

		this.promoteAnimation.start();

		SoundController.getInstance().playEntityPromoted();
		this.updateButtonPromoteCostValueEnabled();
	}

	private onPromoteButtonClick(): void {
		this.emit(UpgradePromoteWindowView.EVENT_BUTTON_PROMOTE, this.model);
	}

	private updateCost(): void {
		this.buttonPromote.setCost(this.model.getPromoteCostPrestige().toString());
	}

	private updateButtonPromoteCostValueEnabled(): void {
		const isEnoughPrestigeMoneyForPromote = this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige();
		const inEnoughCardsForPromote = this.model.isEnoughCardsForPromote();
		this.buttonPromote.setCostValueEnabled(isEnoughPrestigeMoneyForPromote);
		this.buttonPromote.setButtonBgEnabled(inEnoughCardsForPromote && isEnoughPrestigeMoneyForPromote);
	}

	private updateProfitDesc(): void {
		this.profitDesc.text = TextDescriptionHelperUpgrade.getTextDescriptionPromoteWindow(this.model);
	}

	private onPromoteAnimationCompleted(): void {
		this.buttonPromote.setEnabled(true);
		this.updateButtonPromoteCostValueEnabled();
		this.buttonPromote.alpha = 1;
	}

	private onCardsAdded(): void {
		this.card.updateProgressBar();

		this.buttonPromote.updateEnoughResources(
			this.prestigeMoneyModel.getValue() >= this.model.getPromoteCostPrestige(),
			this.model.isEnoughCardsForPromote(),
		);
	}

	public showFlyTextOnPromoteButton(message: string): void {
		if (!this.flyTextLabel) {
			const stringLabel = this.localizationStorage.getLocalizedString(message);
			this.flyTextLabel = new FlyBitmapText(stringLabel, new PIXI.Point(0.5, 0.5), { font: '30px wendyOneShadowBold' });
			this.flyTextLabel.once(FlyBitmapText.EVENT_HIDDEN, () => {
				this.flyTextLabel = null;
			});
			this.flyTextLabel.position.set(this.buttonPromote.x, this.buttonPromote.y - 90);
			this.mainContainer.addChild(this.flyTextLabel);
		}
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.prestigeMoneyModel.off(PrestigeMoneyModel.EVENT_VALUE_CHANGED, this.updateButtonPromoteCostValueEnabled, this, false);

		this.model.off(UpgradeModel.EVENT_PROMOTED, this.startPromoteAnimation, this);
		this.model.off(UpgradeModel.EVENT_CARDS_ADDED, this.onCardsAdded, this);

		this.promoteAnimation.destroy();

		super.destroy(options);
	}
}
