import { SettingsWindowViewController } from './SettingsWindowViewController';
import { EventRankingWindowViewSetter } from '@interfaces/ViewSetters';
import { EventLevelRankingWindowView } from '@views/windows/rankingEventWindow/EventLevelRankingWindowView';

export class EventLevelSettingsWindowViewController extends SettingsWindowViewController
	implements EventRankingWindowViewSetter {
	public static readonly EVENT_LOCALIZATION_EVENT_CHANGE: symbol = Symbol();

	public setEventRankingWindowView(view: EventLevelRankingWindowView): void {
		view.on(EventLevelRankingWindowView.EVENT_CHANGE_USERNAME, this.onChangeUsername, this);
	}

	protected async onChangeLanguage(language: string): Promise<void> {
		this.emit(
			EventLevelSettingsWindowViewController.EVENT_LOCALIZATION_EVENT_CHANGE,
			language,
		);
	}
}
