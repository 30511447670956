import { SkillConfig } from '@src/configs/SkillConfig';
import { GirlPrefabHintOffset, SkillTypes } from '@src/types/SkillTypes';

export class SkillModel extends PIXI.utils.EventEmitter {
	public static readonly EVENT_SKILL_OPENED: symbol = Symbol();
	public static readonly EVENT_SKILL_BEFORE_ACTIVATE: symbol = Symbol();
	public static readonly EVENT_SKILL_ACTIVATED: symbol = Symbol();
	public static readonly EVENT_SKILL_DEACTIVATED: symbol = Symbol();
	public static readonly EVENT_SKILL_RELOADED: symbol = Symbol();

	protected activeTimeInSec: number;
	protected activeTimesInSec: number[];
	protected reloadTimeInSec: number;
	protected reloadReduceMultiplier: number;
	protected effectiveImproveMultiplier: number;
	protected active: boolean;
	protected reloading: boolean;
	protected opened: boolean;
	private readonly id: string;
	private readonly type: string;
	private characterKey: string;
	protected characterLevel: number;
	private timeLeftMS: number;
	private activatedOn: number;
	private readonly order: number;
	private readonly multipliers: number[];
	private readonly iconKey: string;

	protected activationCount: number;

	private readonly girlPrefabName: string;
	private readonly girlPrefabHintOffset: GirlPrefabHintOffset;

	constructor(config: SkillConfig) {
		super();

		this.id = config.getKey();
		this.order = config.getOrder();
		this.reloadTimeInSec = config.getReloadTime();
		this.activeTimesInSec = config.getActiveTimes() || [0];
		this.activeTimeInSec = config.getActiveTimes()?.[0] || 0;
		this.multipliers = config.getMultipliers();
		this.active = false;
		this.reloading = false;
		this.opened = false;
		this.type = config.getType();
		this.girlPrefabName = config.getGirlPrefab();
		this.girlPrefabHintOffset = config.getGirlPrefabHintOffset();
		this.iconKey = config.getIconKey();

		this.reloadReduceMultiplier = 0;
		this.effectiveImproveMultiplier = 1;
		this.characterLevel = 1;
		this.timeLeftMS = 0;
		this.activatedOn = 0;
		this.activationCount = 0;
	}

	public setOpened(): void {
		this.opened = true;
	}

	public open(): void {
		this.opened = true;

		this.emit(SkillModel.EVENT_SKILL_OPENED, this);
	}

	public isOpened(): boolean {
		return this.opened;
	}

	public getReloadTime(): number {
		return Math.floor(this.reloadTimeInSec * (1 - this.reloadReduceMultiplier));
	}

	public getFullReloadTime(): number {
		return this.reloadTimeInSec;
	}

	public getActiveTime(): number {
		return this.getActivateTimeByCharacterLevel(this.characterLevel);
	}

	public getNextActiveTime(): number {
		return this.getActivateTimeByCharacterLevel(this.characterLevel + 1);
	}

	public activate(): void {
		this.emit(SkillModel.EVENT_SKILL_BEFORE_ACTIVATE, this);

		this.active = true;
		this.emit(SkillModel.EVENT_SKILL_ACTIVATED, this);
	}

	public deactivate(): void {
		this.effectiveImproveMultiplier = 1;
		this.active = false;
		this.reloading = true;

		this.emit(SkillModel.EVENT_SKILL_DEACTIVATED, this);
	}

	public onReloadingComplete(): void {
		this.emit(SkillModel.EVENT_SKILL_RELOADED, this);
		this.reloading = false;
		this.reloadReduceMultiplier = 0;
	}

	public isActive(): boolean {
		return this.active;
	}

	public isReloading(): boolean {
		return this.reloading;
	}

	public getKey(): string {
		return this.id;
	}

	public getType(): string {
		return this.type;
	}

	public setEffectiveImproveMultiplier(value: number): void {
		this.effectiveImproveMultiplier = value;
	}

	public getMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel) * this.effectiveImproveMultiplier;
	}

	public getNextMultiplier(): number {
		return this.getMultiplierByCharacterLevel(this.characterLevel + 1) * this.effectiveImproveMultiplier;
	}

	public setCharacterKey(characterKey: string): void {
		this.characterKey = characterKey;
	}

	public getCharacterKey(): string {
		return this.characterKey;
	}

	public setCharacterLevel(characterLevel: number): void {
		this.characterLevel = characterLevel;
	}

	public getIconKey(): string {
		return this.iconKey;
	}

	public getCharacterLevel(): number {
		return this.characterLevel;
	}

	public setTimeLeftMS(time: number): void {
		this.timeLeftMS = time;
	}

	public getTimeLeftMS(): number {
		return this.timeLeftMS;
	}

	public setActivatedOn(activatedOn: number): void {
		this.activatedOn = activatedOn;
	}

	public getActivatedOn(): number {
		return this.activatedOn;
	}

	public getOrder(): number {
		return this.order;
	}

	public getGirlPrefabName(): string {
		return this.girlPrefabName;
	}

	public getGirlPrefabHintOffset(): GirlPrefabHintOffset {
		return this.girlPrefabHintOffset;
	}

	public getSkillDescriptionLocale(): string {
		switch (this.type) {
			case SkillTypes.AUTO_TAP:
				return '#skill_use_auto_tap';
			case SkillTypes.PROFIT_IMPROVE:
				return '#skill_use_profit_improve';
			case SkillTypes.TIME_IMPROVE:
				return '#skill_use_time_improve';
			case SkillTypes.EFFECTIVE_IMPROVE:
				return '#skill_use_effective_improve';
			case SkillTypes.CONSTANT_PROFIT:
				return '#skill_use_constant_profit';
			case SkillTypes.REDUCE_RELOAD:
				return '#skill_use_reduce_reload';
			case SkillTypes.TAP_ADD_MONEY:
				return '#skill_use_tap_add_money';
			default:
				// eslint-disable-next-line no-console
				console.error(`Not found skill description for ${this.type}`);
				return '';
		}
	}

	public getActivationCount(): number {
		return this.activationCount;
	}

	public setIsActive(isActive: boolean): void {
		this.active = isActive;
	}

	public setIsReloading(isReloading: boolean): void {
		this.reloading = isReloading;
	}

	public setReduceReloadMultiplier(reloadMultiplier: number): void {
		this.reloadReduceMultiplier = reloadMultiplier;
	}

	protected getMultiplierByCharacterLevel(characterLevel: number): number {
		const multiplierIndex = Math.min(characterLevel - 1, this.multipliers.length - 1);
		return this.multipliers[multiplierIndex];
	}

	protected getActivateTimeByCharacterLevel(characterLevel: number): number {
		const activateTimeIndex = Math.min(characterLevel - 1, this.activeTimesInSec.length - 1);
		return this.activeTimesInSec[activateTimeIndex];
	}
}
