import { TotemConfig } from '@configs/TotemConfig';
import { TotemSaveData } from '@src/types/SaveTypes';
import { TotemModel } from '@models/TotemModel';
import { AbstractPromotePatternsConfig } from '@configs/AbstractPromotePatternsConfig';

export class TotemsFactory {
	private readonly configs: Map<string, TotemConfig>;
	private readonly savesData: Map<string, TotemSaveData>;

	constructor(
		configs: Map<string, TotemConfig>,
		private readonly promotePatternsConfig: AbstractPromotePatternsConfig,
		savesData: Map<string, TotemSaveData>,
	) {
		this.configs = configs;
		this.promotePatternsConfig = promotePatternsConfig;
		this.savesData = savesData;
	}

	public createModels(): Map<string, TotemModel> {
		const models: Map<string, TotemModel> = new Map();

		this.configs.forEach((config, key) => {
			const model: TotemModel = new TotemModel(config, this.promotePatternsConfig);

			const totemSaveData: TotemSaveData = this.savesData.get(config.getKey());
			if (totemSaveData) {
				model.setFromSaveData(totemSaveData);
			}

			models.set(key, model);
		});
		return models;
	}
}
