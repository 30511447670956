import { WindowBaseView } from '@views/components/WindowBaseView';
import { AbstractReward } from '@interfaces/AbstractReward';
import { AssetsStorage } from '@main/AssetsStorage';
import PrestigeMoneyModel from '@models/money/PrestigeMoneyModel';
import { GameConstants } from '@src/utils/GameConstants';
import * as TWEEN from '@tweenjs/tween.js';
import { RewardResourceIdTypes, RewardTypes } from '@src/types/RewardTypes';
import { HardMoneyPanelView } from '@views/ui/moneyPanel/HardMoneyPanelView';
import HardMoneyModel from '@models/money/HardMoneyModel';
import { PrestigeMoneyPanelView } from '@views/ui/moneyPanel/PrestigeMoneyPanelView';
import { AbstractRewardCards } from '@interfaces/AbstractRewardCards';
import { CardRarities, CardRarity } from '@src/types/CardRarities';
import { SkillModel } from '@models/skills/SkillModel';
import { CardViewFactory } from '@views/ui/cards/CardViewFactory';
import { RewardResultCardAnimations } from '@views/windows/rewardResultWindow/RewardResultCardAnimations';
import LocalizationStorage from '@main/LocalizationStorage';
import { PromotableClickData } from '@views/ui/cards/PromotableBaseCardView';

export type RewardGroups = {
	[key: number]: number;
};

type OpenedReward = {
	card: PIXI.Container;
	reward: AbstractReward;
};

export enum RewardResultWindowBgType {
	FADE,
	SUMMON,
}

export enum RewardResultWindowRewardStringType {
	REWARD_SUMMARY = '#lootbox_Label_Reward_Summary',
	REWARD_COMPENSATION = '#compensation_reward',
}

export abstract class RewardResultWindowBaseView extends WindowBaseView {
	public static readonly EVENT_GO_TO_SHOP_BUTTON_CLICK: symbol = Symbol();
	public static readonly EVENT_REWARD_CARDS_APPEARED: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK_GALLERY_VIDEO: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK_CHARACTER: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK_TOTEM: symbol = Symbol();
	public static readonly EVENT_CARD_CLICK_UPGRADE: symbol = Symbol();
	public static readonly EVENT_REWARD_OPENED: symbol = Symbol();
	public static readonly EVENT_PLAY_RESOURCE_ANIMATION: symbol = Symbol();
	public static readonly EVENT_BUTTON_MONEY_PLUS_CLICK: symbol = Symbol();

	public static readonly MAX_CARDS_IN_GROUP: number = 8;
	public static readonly Y_CENTER_OFFSET = -35;

	public readonly glow: PIXI.Sprite;
	public readonly glowTween: TWEEN.Tween;
	public readonly textTitle: PIXI.extras.BitmapText;

	private static readonly MARGIN_X: number = 70;
	private static readonly MARGIN_Y: number = 70;

	private readonly prestigeMoneyPanel: PrestigeMoneyPanelView;
	private readonly hardMoneyPanel: HardMoneyPanelView;

	private allCards: AbstractReward[];
	private openedRewards: OpenedReward[];
	private cardBacks: PIXI.Sprite[];
	private mouseOver: Map<number, boolean>;

	protected ticker: PIXI.ticker.Ticker;
	protected tweenGroup: TWEEN.Group;
	protected localizationStorage: LocalizationStorage;
	protected cardViewFactory: CardViewFactory;
	protected cardsContainer: PIXI.Container;
	protected cards: PIXI.Container[];
	protected cardsToOpen: number;
	protected groups: RewardGroups;
	protected currentGroup: number;
	protected cardAnimations: RewardResultCardAnimations;
	protected bg: PIXI.Sprite | PIXI.Graphics;
	protected autoOpen: boolean;

	constructor(
		protected readonly skillModels: Map<string, SkillModel>,
		prestigeMoneyModel: PrestigeMoneyModel,
		hardMoneyModel: HardMoneyModel,
		bgType: RewardResultWindowBgType,
		rewardString?: RewardResultWindowRewardStringType,
		moneyPanelsButtonPlusInvisible?: boolean,
		hideMoneyPanels?: boolean,
	) {
		super(0, false);

		this.localizationStorage = LocalizationStorage.getInstance();

		this.tweenGroup = new TWEEN.Group();

		this.ticker = PIXI.ticker.shared;
		this.ticker.add(this.updateTweenGroup, this);

		this.autoOpen = true;
		this.cardsToOpen = 0;
		this.currentGroup = 0;
		this.groups = {};
		this.cardBacks = [];
		this.cardAnimations = new RewardResultCardAnimations();
		this.cardsContainer = new PIXI.Container();

		this.cardViewFactory = new CardViewFactory(
			skillModels,
		);
		this.cardViewFactory.on(CardViewFactory.EVENT_CLICK_CARD_VIDEO, this.onClickCardVdeio, this);
		this.cardViewFactory.on(CardViewFactory.EVENT_CLICK_CARD_CHARACTER, this.onClickCardCharacter, this);
		this.cardViewFactory.on(CardViewFactory.EVENT_CLICK_CARD_TOTEM, this.onClickCardTotem, this);
		this.cardViewFactory.on(CardViewFactory.EVENT_CLICK_CARD_UPGRADE, this.onClickCardUpgrade, this);

		if (bgType === RewardResultWindowBgType.SUMMON) {
			this.bg = PIXI.Sprite.fromImage('summonBg');
		} else {
			this.bg = new PIXI.Graphics()
				.beginFill(0, 0.9)
				.drawRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT)
				.endFill();
		}

		this.hardMoneyPanel = new HardMoneyPanelView(hardMoneyModel);
		this.hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => {
				this.emit(RewardResultWindowBaseView.EVENT_GO_TO_SHOP_BUTTON_CLICK);
			},
		);
		this.hardMoneyPanel.position.set(1630, 40);

		this.prestigeMoneyPanel = new PrestigeMoneyPanelView(prestigeMoneyModel);
		this.prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => {
				this.emit(RewardResultWindowBaseView.EVENT_GO_TO_SHOP_BUTTON_CLICK);
			},
		);
		this.prestigeMoneyPanel.position.set(1370, 40);

		if (moneyPanelsButtonPlusInvisible) {
			this.prestigeMoneyPanel.setButtonPlusVisible(false);
			this.hardMoneyPanel.setButtonPlusVisible(false);
		}

		this.prestigeMoneyPanel.on(
			PrestigeMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(RewardResultWindowBaseView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);
		this.hardMoneyPanel.on(
			HardMoneyPanelView.EVENT_BUTTON_PLUS_CLICK,
			() => this.emit(RewardResultWindowBaseView.EVENT_BUTTON_MONEY_PLUS_CLICK),
			this,
		);

		this.addChild(
			this.bg,
			this.cardsContainer,
		);

		if (!hideMoneyPanels) {
			this.addChild(
				this.hardMoneyPanel as PIXI.DisplayObject,
				this.prestigeMoneyPanel,
			);
		}

		if (rewardString) {
			this.glow = new PIXI.Sprite(AssetsStorage.getAtlas('uiAtlas')['b_glow']);
			this.glow.scale.x = 26;
			this.glow.scale.y = 8;
			this.glow.alpha = 0;
			this.glowTween = new TWEEN.Tween(this.glow, this.tweenGroup)
				.to({ alpha: 0.7 }, 600)
				.delay(1400)
				.start();
			this.glow.position.set(GameConstants.GAME_WIDTH / 2, 700);

			this.textTitle = new PIXI.extras.BitmapText(
				this.localizationStorage.getLocalizedString('#lootbox_Label_Reward_Summary'),
				{ font: '48px wendyOneGold' },
			);
			this.textTitle.anchor = 0.5;
			this.textTitle.position.set(GameConstants.GAME_CENTER_X, GameConstants.GAME_CENTER_Y - 330);

			this.textTitle.scale.set(0, 0);
			new TWEEN.Tween(this.textTitle.scale, this.tweenGroup)
				.to({ x: 1, y: 1 }, 200)
				.easing(TWEEN.Easing.Cubic.InOut)
				.delay(1400)
				.start();

			this.addChild(
				this.textTitle,
			);

			const cardsContainerId = this.getChildIndex(this.cardsContainer);
			this.addChildAt(this.glow, cardsContainerId);
		}
	}

	public init(cards: AbstractReward[], groups?: RewardGroups, lootboxRarity?: CardRarity, noAnimation?: boolean): void {
		this.allCards = cards;
		this.mouseOver = new Map();
		this.cardBacks = [];

		if (!groups) {
			let cardsNum = cards.length;
			this.groups = {};
			let groupIndex = 0;

			while (cardsNum > 0) {
				this.groups[groupIndex] = Math.min(cardsNum, RewardResultWindowBaseView.MAX_CARDS_IN_GROUP);
				cardsNum -= RewardResultWindowBaseView.MAX_CARDS_IN_GROUP;
				groupIndex += 1;
			}
		} else {
			this.groups = groups;
		}

		this.currentGroup = 0;
		this.redraw(noAnimation);
	}

	protected redraw(noAnimation = false, speedUpValue = 1): void {
		const cardsGroup = this.allCards.splice(0, this.groups[this.currentGroup]);
		this.cardsToOpen = cardsGroup.length;
		this.openedRewards = [];
		this.cardAnimations.clear();

		let hasRewardVideo = false;

		cardsGroup.forEach((reward) => {
			const rewardType = reward.getType();
			if (rewardType === RewardTypes.VIDEO) {
				hasRewardVideo = true;
			}
		});

		// Calculate rows size
		let firstRowCount = cardsGroup.length >= 4 ? Math.ceil(cardsGroup.length / 2) : cardsGroup.length;
		let secondRowCount = cardsGroup.length >= 4 ? cardsGroup.length - firstRowCount : 0;

		if (hasRewardVideo && cardsGroup.length >= 4 && cardsGroup.length <= 6) {
			firstRowCount = cardsGroup.length >= 6 ? Math.ceil(cardsGroup.length / 2) : cardsGroup.length;
			secondRowCount = cardsGroup.length >= 6 ? cardsGroup.length - firstRowCount : 0;
		}

		// Default card back has needed width and height
		const cardBack = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['cards_back']);
		const cardWidth = cardBack.width;
		const cardHeight = cardBack.height;

		// Calculate rows width
		const firstRowWidth = (firstRowCount * cardWidth + ((firstRowCount - 1) * RewardResultWindowBaseView.MARGIN_X));
		const secondRowWidth = cardsGroup.length >= 4
			? (secondRowCount * cardWidth + (secondRowCount - 1) * RewardResultWindowBaseView.MARGIN_X)
			: 0;

		this.cardsContainer.removeChildren();
		this.cardsContainer.visible = true;
		this.cardsContainer.alpha = 1;

		this.cards = cardsGroup.map((reward, index) => {
			// Create card and set width/height same as card back
			const cardContainer = new PIXI.Container();
			const cardObj = this.cardViewFactory.createRewardCard(reward);

			const rewardType = reward.getType();
			if (rewardType === RewardTypes.RESOURCE) {
				cardObj.scale.set(0.58);
			} else if (rewardType === RewardTypes.BOOST || rewardType === RewardTypes.TIMESKIP) {
				cardObj.scale.set(0.59);
			} else if (rewardType === RewardTypes.VIDEO) {
				cardObj.scale.set(0.54);
				cardObj.y = 15;
			} else if (
				rewardType === RewardTypes.CHARACTER
				|| rewardType === RewardTypes.UPGRADE
				|| rewardType === RewardTypes.TOTEM) {
				cardObj.scale.set(0.43);
				cardObj.y = 15;
			}
			cardObj.visible = false;
			const cardRarity = reward instanceof AbstractRewardCards
				? reward.getCardRarity()
				: '';

			const currentCardBack = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['cards_back']);
			this.cardBacks.push(currentCardBack);

			const rarityBgSprite = new PIXI.Sprite(AssetsStorage.getAtlas('collectionsAtlas')['summon_rarity_bg']);
			rarityBgSprite.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
			rarityBgSprite.tint = cardRarity
				? CardRarities.getRarityColor(cardRarity)
				: CardRarities.getRarityColor(CardRarity.COMMON);
			rarityBgSprite.alpha = 0;
			rarityBgSprite.blendMode = PIXI.BLEND_MODES.ADD;

			cardContainer.addChild(
				rarityBgSprite,
				cardObj,
				currentCardBack,
			);
			cardContainer.interactive = true;
			cardContainer.on('pointerover', () => this.onCardMouseOver(cardContainer, index), this);
			cardContainer.on('pointerout', () => this.onCardMouseOut(cardContainer, index), this);

			const resourceAnimation: boolean = reward.getType() === RewardTypes.RESOURCE && reward.getKey() !== RewardResourceIdTypes.SOFT_MONEY;

			// Once we click on card, scale it's width to 0, remove back side and scale width back to 1 (looks like rotate)
			// On x1 open, start opening right away, after they move to their's positions
			let curTime: number;
			if (noAnimation) {
				curTime = 0.1;
			} else {
				curTime = (300 + index * 400);
			}

			if (this.autoOpen || (this.currentGroup > 0 || Object.keys(this.groups).length > 1)) {
				setTimeout(() => {
					cardContainer.interactive = false;
					this.cardAnimations.animateCard(
						cardContainer,
						cardRarity,
						this.afterCardOpen.bind(this, reward, index, resourceAnimation),
						noAnimation,
						speedUpValue,
					);
				}, curTime / speedUpValue);
			} else {
				currentCardBack.interactive = true;
				currentCardBack.once('pointertap', () => {
					cardContainer.interactive = false;
					new TWEEN.Tween(cardContainer.scale, this.tweenGroup)
						.to({ x: 1, y: 1 }, 100)
						.easing(TWEEN.Easing.Cubic.In)
						.onComplete(() => {
							this.cardAnimations.animateCard(
								cardContainer,
								cardRarity,
								this.afterCardOpen.bind(this, reward, index, resourceAnimation),
								noAnimation,
								speedUpValue,
							);
						})
						.start();
				}, this);
			}

			// Move cards from bottom to their positions
			cardContainer.x = GameConstants.GAME_CENTER_X;
			cardContainer.y = GameConstants.GAME_HEIGHT + cardContainer.height / 2;
			this.cardsContainer.addChild(cardContainer as PIXI.DisplayObject);

			const offsetInRow = index + 1 > firstRowCount ? index - firstRowCount : index;

			let toX = GameConstants.GAME_CENTER_X
				- (index + 1 <= firstRowCount ? firstRowWidth / 2 : secondRowWidth / 2)
				+ (offsetInRow * cardWidth)
				+ (offsetInRow * RewardResultWindowBaseView.MARGIN_X)
				+ (cardWidth / 2);

			if (cardsGroup.length === 1) {
				if (rewardType === RewardTypes.VIDEO) {
					toX -= 7;
				} else if (rewardType === RewardTypes.CHARACTER
					|| rewardType === RewardTypes.UPGRADE
					|| rewardType === RewardTypes.TOTEM) {
					toX -= 5;
				}
			}

			let toY = GameConstants.GAME_CENTER_Y;
			if (secondRowCount > 0) {
				toY = index + 1 <= firstRowCount
					? GameConstants.GAME_CENTER_Y
					+ RewardResultWindowBaseView.Y_CENTER_OFFSET
					- RewardResultWindowBaseView.MARGIN_Y / 2
					- cardHeight / 2
					: GameConstants.GAME_CENTER_Y
					+ RewardResultWindowBaseView.Y_CENTER_OFFSET
					+ RewardResultWindowBaseView.MARGIN_Y / 2
					+ cardHeight / 2;
			}

			if (noAnimation) {
				cardContainer.position.set(toX, toY);
				this.cardsContainer.alpha = 0;
				new TWEEN.Tween(this.cardsContainer, this.tweenGroup)
					.to({ alpha: 1 }, 1000)
					.easing(TWEEN.Easing.Quartic.Out)
					.start();
			} else {
				new TWEEN.Tween(cardContainer, this.tweenGroup)
					.to({ x: toX, y: toY }, 350)
					.delay(index * (150 / speedUpValue))
					.easing(TWEEN.Easing.Quartic.Out)
					.start();
			}

			return cardContainer;
		});

		this.emit(RewardResultWindowBaseView.EVENT_REWARD_CARDS_APPEARED);
	}

	private onCardMouseOver(card: PIXI.Container, index: number): void {
		if (this.mouseOver.get(index)) {
			return;
		}
		this.mouseOver.set(index, true);
		new TWEEN.Tween(card.scale, this.tweenGroup)
			.to({ x: 1.07, y: 1.07 }, 100)
			.easing(TWEEN.Easing.Cubic.In)
			.start();
	}

	private onCardMouseOut(card: PIXI.Container, index: number): void {
		if (!this.mouseOver.get(index)) {
			return;
		}
		this.mouseOver.set(index, false);
		new TWEEN.Tween(card.scale, this.tweenGroup)
			.to({ x: 1, y: 1 }, 100)
			.easing(TWEEN.Easing.Cubic.Out)
			.start();
	}

	public getFirstCardBackContainer(): PIXI.Container | undefined {
		if (this.cardBacks.length > 0) {
			return this.cardBacks[0];
		}
		return undefined;
	}

	protected afterCardOpen(
		reward: AbstractReward,
		index: number,
		resourceAnimation: boolean,
	): void {
		this.cards[index].interactive = true;

		if (resourceAnimation) {
			this.afterResourceCardOpen(reward, index);
		} else {
			this.afterOtherCardOpen(reward, index);
		}
	}

	protected afterResourceCardOpen(
		reward: AbstractReward,
		index: number,
	): void {
		this.emit(
			RewardResultWindowBaseView.EVENT_PLAY_RESOURCE_ANIMATION,
			reward,
			index,
			this.cards[index].getGlobalPosition(),
		);
	}

	protected afterOtherCardOpen(
		reward: AbstractReward,
		index: number,
	): void {
		this.emit(RewardResultWindowBaseView.EVENT_REWARD_OPENED, reward);
		const card = this.cards[index].getChildAt(1);
		CardViewFactory.trySetPreviousCardProgress(card);
		this.afterCardAnimation(reward, index);
	}

	public afterResourceAnimation(reward: AbstractReward, index: number): void {
		if (reward.getKey() === RewardResourceIdTypes.HARD_MONEY) {
			this.hardMoneyPanel.pulse();
		}
		if (reward.getKey() === RewardResourceIdTypes.PRESTIGE_MONEY) {
			this.prestigeMoneyPanel.pulse();
		}
		this.afterCardAnimation(reward, index);
	}

	protected afterCardAnimation(reward: AbstractReward, index: number): void {
		const currentCard = {
			reward,
			card: this.cards[index].getChildAt(1),
		};

		this.openedRewards.push(currentCard);

		CardViewFactory.tryAnimateCardProgress(currentCard.card);

		this.cardsToOpen -= 1;

		if (this.cardsToOpen === 0) {
			this.afterAllCardsOpened();
		}
	}

	protected abstract afterAllCardsOpened(): void;

	private onClickCardVdeio(data: PromotableClickData): void {
		this.emit(RewardResultWindowBaseView.EVENT_CARD_CLICK_GALLERY_VIDEO, data);
	}

	private onClickCardCharacter(data: PromotableClickData): void {
		this.emit(RewardResultWindowBaseView.EVENT_CARD_CLICK_CHARACTER, data);
	}

	private onClickCardTotem(data: PromotableClickData): void {
		this.emit(RewardResultWindowBaseView.EVENT_CARD_CLICK_TOTEM, data);
	}

	private onClickCardUpgrade(data: PromotableClickData): void {
		this.emit(RewardResultWindowBaseView.EVENT_CARD_CLICK_UPGRADE, data);
	}

	private updateTweenGroup(): void {
		this.tweenGroup.update(this.ticker.lastTime);
	}

	public destroy(options?: PIXI.DestroyOptions | boolean): void {
		this.cardAnimations.destroy();

		this.tweenGroup.removeAll();
		this.ticker.remove(this.updateTweenGroup, this);

		super.destroy(options);
	}
}
