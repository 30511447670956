import { AssetsStorage } from '@main/AssetsStorage';
import { TextField } from '@views/components/text/TextField';
import LocalizationStorage from '@main/LocalizationStorage';
import { TimeskipModel } from '@models/TimeskipModel';

export class TimeskipCardView extends PIXI.Container {
	constructor(
		model: TimeskipModel,
	) {
		super();

		const collectionAtlas = AssetsStorage.getAtlas('collectionsAtlas');
		const localizationStorage = LocalizationStorage.getInstance();

		const icon = new PIXI.Sprite(collectionAtlas[model.getIconKey()]);
		icon.x = -10;
		icon.scale.set(1.1);

		const bg = new PIXI.Sprite(AssetsStorage.getAtlas('lootboxAtlas')['time_scip_card']);

		const title = new TextField(
			localizationStorage.getLocalizedString('#bank_time_skip'),
			{ font: '38px wendyOneShadowBold', align: 'center' },
			400,
			100,
		);
		title.anchor = 0.5;
		title.y = -229;

		const hours = Math.floor(model.getValue() / 3600);
		const textQuantity = new PIXI.extras.BitmapText(`${hours}h`, { font: '100px wendyOneGold', align: 'center' });
		textQuantity.position.set(50, 100);
		textQuantity.anchor = 0.5;

		this.addChild(
			bg,
			icon,
			title as PIXI.DisplayObject,
			textQuantity,
		);
	}
}
