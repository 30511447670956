import { NetworkRequestSender } from '@src/network/NetworkRequestSender';
import { GameProfileModel } from '@models/GameProfileModel';
import { NetworkProfileLoader } from '@src/loaders/NetworkProfileLoader';
import { GameProfileBaseViewController } from './GameProfileBaseViewController';
import { SavesConfig } from '@configs/saves/SavesConfig';
import { LoginWindowViewSetter } from '@interfaces/ViewSetters';
import { LoginWindowView } from '@views/windows/account/LoginWindowView';
import { ServerErrorCode } from '@src/types/NetworkTypes';
import { NetworkRequestTransport } from '@src/network/NetworkRequestTransport';

export class GameProfilePreloaderViewController extends GameProfileBaseViewController implements LoginWindowViewSetter {
	private readonly networkLoader: NetworkProfileLoader;
	private readonly networkRequestTransport: NetworkRequestTransport;

	private loginWindow: LoginWindowView;

	constructor(
		networkRequestSender: NetworkRequestSender,
		networkRequestTransport: NetworkRequestTransport,
		networkLoader: NetworkProfileLoader,
		gameProfileModel: GameProfileModel,
		savesConfig: SavesConfig,
		resendEmailConfirmationTimeout: number,
	) {
		super(networkRequestSender, gameProfileModel, savesConfig, resendEmailConfirmationTimeout);

		this.networkRequestTransport = networkRequestTransport;
		this.networkLoader = networkLoader;
	}

	public setLoginWindowView(view: LoginWindowView): void {
		this.loginWindow = view;
		this.loginWindow.on(LoginWindowView.EVENT_BUTTON_LOGIN_CLICK, this.onLoginButtonClick, this);
		this.loginWindow.on(LoginWindowView.EVENT_BUTTON_EMAIL_CODE_CONFIRM_CLICK, this.onButtonEmailConfirmClick, this);
		this.loginWindow.on(LoginWindowView.EVENT_BUTTON_EMAIL_CODE_RESEND_CLICK, this.onButtonEmailResendCodeClick, this);
		this.loginWindow.on(LoginWindowView.EVENT_BUTTON_RECOVER_CLICK, this.onButtonRecoverPasswordClick, this);
	}

	private async onLoginButtonClick(email: string, password: string): Promise<void> {
		const result: any = await this.networkRequestSender.sendAccountEmailLogin(email, password);
		if (result.error) {
			// eslint-disable-next-line default-case
			switch (result.error as number) {
				case ServerErrorCode.ACCOUNT_EMAIL_LOGIN_NOT_FOUND:
					this.gameProfileModel.onLoginLoginNotFound();
					break;
				case ServerErrorCode.ACCOUNT_WRONG_PASSWORD:
					this.gameProfileModel.onLoginWrongPassword();
					break;
				case ServerErrorCode.ACCOUNT_EMAIL_NOT_CONFIRMED:
					this.gameProfileModel.onLoginEmailNotConfirmed(email);
					break;
			}
		} else {
			await this.loadNewProfile(result.profile_id);
			this.gameProfileModel.onLogin(email);
		}
	}

	private async loadNewProfile(profileId: string): Promise<void> {
		this.networkRequestTransport.setPlayerId(profileId);

		await this.networkLoader.loadProfile();
	}

	private async onButtonRecoverPasswordClick(email: string): Promise<void> {
		const result = await this.networkRequestSender.sendAccountChangePassword(email);
		if (result.error) {
			// eslint-disable-next-line default-case
			switch (result.error as number) {
				case ServerErrorCode.ACCOUNT_EMAIL_LOGIN_NOT_FOUND:
					this.gameProfileModel.onPasswordRecoverLoginNotFound();
					break;
			}
		} else {
			this.gameProfileModel.onPasswordRecover();
		}
	}
}
