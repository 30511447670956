import { SkillModel } from '@models/skills/SkillModel';

export class SkillReduceReloadModel extends SkillModel {
	private otherSkills: SkillModel[];

	public setInfluencedModels(skills: SkillModel[]): void {
		this.otherSkills = skills.filter(otherSkill => otherSkill.getKey() !== this.getKey());
	}

	public activate(): void {
		this.emit(SkillModel.EVENT_SKILL_BEFORE_ACTIVATE, this);

		let lastUsedSkill: SkillModel = null;
		this.otherSkills.forEach((otherSkill) => {
			if (!lastUsedSkill || otherSkill.getActivatedOn() > lastUsedSkill.getActivatedOn()) {
				lastUsedSkill = otherSkill;
			}
		});
		if (lastUsedSkill) {
			lastUsedSkill.setReduceReloadMultiplier(this.getMultiplier());
		}

		this.emit(SkillModel.EVENT_SKILL_ACTIVATED, this);
		this.deactivate();
	}
}
