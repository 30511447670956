import { QuickPhaseGeneratorBase } from './QuickPhraseGeneratorBase';
import { CharacterQuickPhraseTypes } from '@src/types/CharacterQuickPhraseTypes';
import { CharacterModel } from '@models/CharacterModel';
import { CharacterQuickPhraseConstants } from '@configs/ConstantsConfig';
import { BusinessModel } from '@models/BusinessModel';

export class QuickPhraseActivateAnotherCharacterGenerator extends QuickPhaseGeneratorBase {
	private businesses: Map<string, BusinessModel>;

	constructor(
		config: CharacterQuickPhraseConstants,
		characters: CharacterModel[],
		businesses: Map<string, BusinessModel>,
	) {
		super(CharacterQuickPhraseTypes.ACTIVATE_ANOTHER_CHARACTER, config);

		characters.forEach(character => character.on(CharacterModel.EVENT_ACTIVATED, this.onCharacterActivated, this));
		this.businesses = businesses;
	}

	private onCharacterActivated(model: CharacterModel): void {
		const relatedBusiness = this.businesses.get(model.getRelatedBusinessKey());
		relatedBusiness.getCharacterKeys().forEach(characterKey => characterKey !== model.getKey() && this.tryGeneratePhrase(characterKey));
	}
}
