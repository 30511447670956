import { AbstractReward } from '@interfaces/AbstractReward';
import { RewardTypes } from '@src/types/RewardTypes';
import { BoostModel } from '@models/BoostModel';

export class RewardBoost extends AbstractReward {
	private readonly boostModel: BoostModel;
	private readonly quantity: number;

	constructor(
		boostModel: BoostModel,
		quantity: number,
	) {
		super(RewardTypes.BOOST, boostModel.getKey());

		this.boostModel = boostModel;
		this.quantity = quantity;
	}

	public collect(): void {
		this.boostModel.acquire(this.quantity);
	}

	public getQuantity(): number {
		return this.quantity;
	}

	public getModel(): BoostModel {
		return this.boostModel;
	}
}
