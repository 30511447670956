import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter255007View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255007View';
import { EventCharacter255008View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255008View';
import { EventCharacter255009View } from '@views/characters/events/eventLevelWeekDay10/EventCharacter255009View';

export class EventBusiness255003View extends BusinessBaseView {
	constructor() {
		const eventCharacter255007View = new EventCharacter255007View();
		const eventCharacter255008View = new EventCharacter255008View();
		const eventCharacter255009View = new EventCharacter255009View();

		super('business255003', [eventCharacter255007View, eventCharacter255008View, eventCharacter255009View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b14_decor1']).setTransform(-65, -65, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor2']).setTransform(255, -5, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor3']).setTransform(-20, -28, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor4']).setTransform(-182, 20, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b14_decor5']).setTransform(-225, -5, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter255007View as PIXI.DisplayObject,
			eventCharacter255008View,
			eventCharacter255009View,
		);
	}
}
