import { BusinessBaseView } from '@views/businesses/ui/BusinessBaseView';
import { AssetsStorage } from '@main/AssetsStorage';
import { EventCharacter70016View } from '@views/characters/events/event7/EventCharacter70016View';
import { EventCharacter70017View } from '@views/characters/events/event7/EventCharacter70017View';
import { EventCharacter70018View } from '@views/characters/events/event7/EventCharacter70018View';

export class EventBusiness70006View extends BusinessBaseView {
	constructor() {
		const eventCharacter70016View = new EventCharacter70016View();
		const eventCharacter70017View = new EventCharacter70017View();
		const eventCharacter70018View = new EventCharacter70018View();


		super('business70006', [eventCharacter70016View, eventCharacter70017View, eventCharacter70018View]);

		const decorationAtlas = AssetsStorage.getAtlas('businessDecorationAtlas');

		this.decorations = [
			new PIXI.Sprite(decorationAtlas['b11_decor2']).setTransform(178, -57, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor3']).setTransform(-112, -68, 0.5, 0.5) as PIXI.Sprite,
			new PIXI.Sprite(decorationAtlas['b11_decor4']).setTransform(-120, -40, 0.5, 0.5) as PIXI.Sprite,
		];

		this.addChild(
			...this.decorations,
			eventCharacter70018View,
			new PIXI.Sprite(decorationAtlas['b11_decor5']).setTransform(-69, 72, 0.5, 0.5),
			eventCharacter70016View as PIXI.DisplayObject,
			eventCharacter70017View,
		);
	}
}
